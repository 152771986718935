/* @flow */

import { gql } from '@apollo/client';

export const indexAttributeMutation = gql`
    mutation indexAttributeMutation($name: String, $description: String, $entityType: String!, $primaryAttribute: String!, $unique: Boolean) {
        result: indexEntityPrimaryAttribute(name: $name, description: $description, entityType: $entityType, primaryAttribute: $primaryAttribute, operation: create, unique: $unique) {
            id
            name
            type
        }
    }
`;

export const removeIndexAttributeMutation = gql`
    mutation removeIndexAttributeMutation($name: String, $description: String, $entityType: String!, $primaryAttribute: String!, $unique: Boolean) {
        result: indexEntityPrimaryAttribute(name: $name, description: $description, entityType: $entityType, primaryAttribute: $primaryAttribute, operation: delete, unique: $unique) {
            id
            name
            type
        }
    }
`;
