/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, IconButton, MdiIcon,Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mic3/platform-ui';

import DraggableElement from 'app/components/molecules/Dnd/DraggableElement';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import RowTarget from 'app/components/molecules/Dnd/RowTarget';
import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import { bind, memoize, debounce } from 'app/utils/decorators/decoratorUtils';
import { get, set } from 'app/utils/lo/lo';
import { shallowEquals, isDefined, isObject } from 'app/utils/utils';

const TableCellStyled = styled(TableCell)`
    vertical-align: top !important; 
`;
const StyledButton = styled(Button)`
    margin-right: 20px !important;
`;


const DivStyled = styled.div`
    display: flex !important;
    align-items: start;
    justify-content: center;
`;

class AbstractTableDnd extends PureComponent<Object, Object> {

    formRef: Object = React.createRef();

    static propTypes = {
        value: PropTypes.arrayOf(PropTypes.object),
        uuid: PropTypes.string.isRequired,
        onSave: PropTypes.func.isRequired,
    };

    static defaultProps = {
        root: true,
    }

    state = {
        editModalValue: null,
        tableKey: 1,
        childData: null,
        pressedButton: ''
    }

    componentDidUpdate(prevProps) {
        const { value } = this.props;
        const { editModalValue: prevEditModalValue } = this.state;
        if(value !== prevProps.value && prevEditModalValue) {
            const editModalValue = value.find(val => prevEditModalValue.uuid === val.uuid);
            if(!shallowEquals(editModalValue,prevEditModalValue)) {
                this.setState({ editModalValue, childData: editModalValue, tableKey: this.state.tableKey + 1 });
            }
        }
    }

   
    @bind
    onDrop(item: Object, index: number) {
        this.props.onDrop(item, this.props.uuid, index);
    }

    @bind
    deleteValue(event: Object) {
        const index = Number(event.target.dataset.index);
        this.props.deleteValue(this.props.value[index]);
    }

    @bind
    editModalValueClose() {
        this.setState({ editModalValue: null, childData: null });
    }

    @bind
    async createValue() {
        return new Promise(async (resolve) => {
            const { data: dataForm, errors } = await this.formRef.current.isValidForm();
            const data = {...dataForm};
            if(errors) {
                return;
            }
            if(data.type && data.script && data.fields) {
                data.script = data.fields.find(f => f.name === 'script')?.value || '';
            }
            if (!data.uuid) {
                if(isObject(data.entityType) && data.entityType.value ){
                    data.entityType = data.entityType.value;
                }
                await this.props.addValue(
                    data,
                    this.props.uuid,
                    this.props.value.length
                );
            } else {
                await this.props.onChange(data);
            }
            resolve();
            this.setState({ editModalValue: null });
        });
    }

    @bind
    @debounce()
    async saveAndClose(arg){
        const { onSave, saveAndClose, toggleShowLoader }= this.props;
        toggleShowLoader(!!arg);
        await this.createValue();
        if(onSave) {
            await onSave(2);
        }         
        if(saveAndClose) {
            await saveAndClose(false);
        }
    }

    @bind
    editValue(event: Object) {
        const index = Number(event.target.dataset.index);
        this.setState({ editModalValue: this.props.value[index], pressedButton: 'edit'});
    }

    @bind
    addValue() {
        const value = this.props.fields.reduce((accum, field) => set(accum, field.properties.name, isDefined(field.properties.defaultValue) ? field.properties.defaultValue : ''), {});
        this.setState({ editModalValue: value,  pressedButton: 'add' });
    }

    @bind
    async editModalValue(editModalValue) {
        const { data } = await this.formRef.current.isValidForm();
        this.setState({ editModalValue: { ...data, ...editModalValue } });
    }

    @bind
    @memoize()
    buildColumns(fields: Array<Object>) {
        return [...fields.filter(f => f.table !== false).map((field, index) => {
            return <TableCell
                key={`${field.type}${index}`}
                size="small"
            >
                {field.properties.label}
            </TableCell>;
        }), (<TableCell align="center" key="action" >Action</TableCell>)];
    }
    @bind
    @memoize()
    buildDropArea(values: Object, fields: Array<Object>) {
        const listItems = [...values.map((val, i) => {
            const { uuid } = val;
            const target = [...fields.filter(f => f.table !== false).map((field) => {
                const value = get(val, field.properties.name);
                return (
                    <TableCell align="left" key={field.properties.name} {...(field.tableCellProps || {})}>
                        <RowTarget
                            textRising
                            align="left"
                            index={i}
                            onDrop={this.onDrop}
                            label={'label'}
                            element={val}
                            {...(field.tableCellValueProps || {})}
                        >

                            {value}
                        </RowTarget>
                    </TableCell>
                );
            })];
            target.push((
                <TableCellStyled key={'delete'} align="center">
                    <DivStyled>
                        <Tooltip title="Edit">
                            <IconButton aria-label="Edit" data-index={i} onClick={this.editValue}>
                                <MdiIcon data-index={i} name="pencil" size={24}  />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <IconButton aria-label="Delete" data-index={i} onClick={this.deleteValue}>
                                <MdiIcon name="close-circle" data-index={i} size={24} />
                            </IconButton>
                        </Tooltip>
                    </DivStyled>
                </TableCellStyled>
            ));
            return (
                <DraggableElement align="left" hover role="checkbox" tabIndex={-1} Component={TableRow} key={`${uuid}${i}`} index={i} element={val}>
                    {target}
                </DraggableElement>
            );
        })];

        return(
            <Table stickyHeader size={'small'}>
                <TableHead>
                    <TableRow>
                        {this.buildColumns(fields)}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {listItems}
                    <TableRow>
                        <TableCell colSpan={values.length}>
                            <Tooltip title="Add">
                                <IconButton onClick={this.addValue}><MdiIcon name="plus" /></IconButton>
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        );
    };

    @bind
    @debounce()
    handleOnChange(data) {
        if(data.type && data.uuid) {
            this.props.onChange(data);
        } else {
            this.setState({ tableKey: this.state.tableKey + 1,  childData: data});
        }
    }
    @bind
    stabTitle(titleValue){
        const { pressedButton } = this.state;
        let title = titleValue;
        if(title){
            switch (pressedButton) {
                case 'add':  title = `Add New ${title.slice(0,-1)}`;
                    break;
                case 'edit':  title = `Edit ${title.slice(0,-1)}`;
                    break;
            }
        }
        else{
            switch (pressedButton) {
                case 'add': title = `Add New Field`;
                    break;
                case 'edit': title = `Edit Field`;
                    break;
            }
        }
        return title;
    }

    render() {
        const { fields, value, modalAfterContent, title, closeModal, component } = this.props;
        const { editModalValue, childData, tableKey } = this.state;
        return <>
            {this.buildDropArea(value, fields)}
            {editModalValue && (
                <ModalDialog onClose={this.editModalValueClose} 
                    title = {this.stabTitle(title)}
                    closeModal={closeModal}
                    actions={(
                        <>
                            {(component !== 'AbstractDefinitionsBuilder') &&  
                            <Button variant="text" onClick={this.saveAndClose}>
                                save and close
                            </Button>
                            }
                            <StyledButton onClick={this.createValue}>
                                save
                            </StyledButton>
                        </>
                    )}>
                    <FormGenerator
                        ref={this.formRef}
                        components={fields}
                        data={editModalValue}
                        root={false}
                        onChange={this.handleOnChange}
                    />
                    {modalAfterContent && modalAfterContent(childData || editModalValue, this.editModalValue, tableKey, this.saveAndClose)}
                </ModalDialog>
            )}
        </>;
    }
};

export default AbstractTableDnd;
