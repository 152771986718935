/* @flow */

import { gql } from '@apollo/client';
import { entityReferences } from 'graphql/entities/relationships/relationsQuery';

export default gql`
    mutation createRelationColumnToBoardMutation($entity: EntityReferenceInput!, $relatedEntity: EntityReferenceInput!, $relationDefinition: ID!, $attributes: JSON) {
        data: entityRelationAdd(entity: $entity, relatedEntity: $relatedEntity, relationDefinition: $relationDefinition, attributes: $attributes) {
            relation {
                id
                attributes
            }
            column: entity {
                ${entityReferences}
            }
        }
    }
`;
