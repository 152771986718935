/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import { muiTheme } from 'app/themes/materialUi';
import { Chip, Avatar, Divider, Button, Typography, Grid, IconButton, Checkbox, Tooltip, TextField, MdiIcon } from '@mic3/platform-ui';
import { isMobile } from 'react-device-detect';

import { isEmpty, getStr } from 'app/utils/utils';
import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { removeWorkspaceUsers, updateWorkspace, leaveWorkspace } from 'store/actions/admin/workspacesActions';
import ExpansionPanel from 'app/components/Designer/ExpansionPanel';
import HeaderBar from 'app/components/molecules/HeaderBar/HeaderBar';
import { PUBLIC_TEAM_ID, settingsEntityType } from 'app/config/config';
import Ellipsis from 'app/components/molecules/Ellipsis/Ellipsis';
import { getAttachmentUrl } from 'app/utils/attachments/attachmentsUtils';
import Icon from 'app/components/atoms/Icon/Icon';
import MainSection from 'app/components/organisms/EntitySections/MainSection';
import ListItem from 'app/components/molecules/List/ListItem';
import EntityLink from 'app/components/atoms/Link/EntityLink';
import { displayByKind, fromNow } from 'app/utils/date/date';
import Loader from 'app/components/atoms/Loader/Loader';
import history from 'store/History';
import { setDocumentTitle, showToastr } from 'store/actions/app/appActions';
import { closeSidebar } from 'store/actions/sidebar/sidebarActions';
import ConfirmationModalButton from 'app/components/atoms/Designer/Button';


const ExpansionPanelStyled = styled(ExpansionPanel)`
    margin: 0 !important;
    margin-bottom: ${({marginBottom}) => marginBottom }!important;
`;
const MoreChipStyled = styled(Chip)`
    background:  ${({ theme }) => theme.material.colors.background.fields} !important;
    & .MuiChip-label {
        max-width: 140px;
    }
    margin-right: 8px !important;
`;
const AvatarStyled = styled(Avatar)`
    width: 30px !important;
    height: 30px !important;
    margin-right: 8px;
    font-size: 1rem !important;
    cursor: pointer;
`;
const Flex = styled.div`
    position: ${({position})=> position};
    top: ${({top})=> top};
    width: ${({width})=> width ?? '100%'};
    display: flex;
    gap: ${({gap})=> gap ?? 24}px;
    flex-direction: row;
    align-items: ${({alignItems})=> alignItems ?? 'center'};
    justify-content: ${({justifyContent})=> justifyContent ?? 'end'};
    padding: ${({padding})=> padding};
    background-color: ${({ theme }) => theme.material.colors.background.default} !important;
    z-index: 100;
`;
const DividerVerticalStyled = styled(Divider)`
    margin-right: 10px !important;
    height: 24px !important;
    align-self: center !important;
    width: 1px;
    background-color: ${({ theme }) => theme.material.colors.border.main} !important;
`;
const StyledContentArea = styled(ContentArea)`
    position: relative;
    padding: 0 16px !important;
`;
const StyledMainSection = styled(MainSection)`
`;
const PanelsWrapper = styled(Grid)`
    max-width: 960px !important;
    margin: auto !important;
`;
const PanelName = styled(Typography)`
    color:  ${({theme})=> theme.material.colors.text.primary} !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 28px !important;
    letter-spacing: 0.09px !important;
    margin-top: ${({ marginTop}) => marginTop} !important;
`;
const PanelSubtitle = styled(Typography)`
    color: ${({theme})=> theme.material.colors.text.secondary};
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 16px !important;
    letter-spacing: 0.4px !important;
    margin-top: 34px !important;

`;
const TeamChipStyled = styled(Chip)`
    background:  ${({ theme }) => theme.material.colors.background.fields} !important;
`;
const TypographyStyled = styled(Typography)`
    color: ${ ( { theme } ) => theme.material.colors.text.primary };
`;
const GridHalfStyled = styled(Grid)`
    width: calc(50% - 17px);
    margin-bottom: 24px !important;
`;
const DividerStyled = styled(Divider)`
    margin: 12px 0 16px 0 !important;
`;

const sharedButtonStyles = css`
    font-size: 12px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    padding: 3px 8px !important;
    height: 36.5px !important;
    letter-spacing: 0.42px;
    width: ${({ width }) => width} !important;
    min-width: ${({ minWidth }) => minWidth} !important;
`;

const ButtonStyled = styled(Button)`
    ${sharedButtonStyles}
`;
const ConfirmationModalButtonStyled = styled(ConfirmationModalButton)`
    ${sharedButtonStyles};
`;
const MdiIconStyled = styled(MdiIcon)`
    margin-right: 8px;
    font-size: 1.3rem !important;
    color: ${({theme})=> theme.material.colors.text.secondary} !important;
`;

const AboutGridWrapper = styled(Grid)`
    padding-top: 15px;
`;

const IconButtonStyled = styled(IconButton)`
    margin: -1.5px 12px 0 0 !important;
`;
const CheckboxStyled = styled(Checkbox)`
    margin: -2.5px 0 0 -5px !important;
`;
const IconStyled = styled(Icon)`
    &::before{
        font-size: ${({fontSize})=> fontSize} !important;
    }
`;

const BuildEllipsisList = ({title, data, dataItemType, onClick, teams, teamItemType='chip', disabled}) => {
    const isList = data?.length || !!teams?.length;
    const listItem = (item, displayType) => {
        if (displayType === 'chip') {
            return <TeamChipStyled label={item?.entity?.name || item?.name} onClick={onClick} />;
        }
        // Default to rendering as avatar if displayType is not provided or invalid
        const avatarImg = item.image ? getAttachmentUrl(item.id, 'user', item.image) : null;
        return <AvatarStyled initials={item.name} src={avatarImg} onClick={onClick} />;
    };
    return (
        <>
            <PanelSubtitle >{title}</PanelSubtitle>
            <Grid container alignItems="center">
                <IconButtonStyled disabled={disabled} onClick={onClick} ><Icon hexColor={muiTheme.colors.secondary.main} name="plus"/></IconButtonStyled> 
                {!isList  && <Button variant='text' disabled={disabled} onClick={onClick}>Add New</Button>}
                {isList && !!data?.length && <Ellipsis
                    data={data}
                    displaySize={isMobile ? 3 : 6}
                    renderCount={count => (
                        <MoreChipStyled
                            variant="default"
                            label={`+${count}`}
                            size="small"
                            onClick={onClick}
                            disabled={disabled}
                        />
                    )}
                    renderItem={(item)=>listItem(item, dataItemType)}
                />}
                {isList && !!teams?.length && !!data?.length && <DividerVerticalStyled orientation="vertical" flexItem />}
                {isList && !!teams?.length && <Ellipsis
                    data={teams}
                    displaySize={isMobile ? 2 : 5}
                    renderCount={count => (
                        <MoreChipStyled
                            variant="default"
                            label={`+${count}`}
                            size="small"
                            onClick={onClick}
                            disabled={disabled}
                        />
                    )}
                    renderItem={(item)=>listItem(item, teamItemType)}
                />}
            </Grid>
        </>
    );
};

/**
 * Renders the view to display the classification.
 */
class WorkspaceAboutTab extends PureComponent<Object, Object> {
    static propTypes = {
        isLoading: PropTypes.bool,
        addWorkspaceMembersTaskLoading: PropTypes.bool,
        details: PropTypes.object, // task or process details
        searchValue: PropTypes.string
    };

    defaultState = this.state;

    constructor(props) {
        super(props);
        const {details} = props;
        this.state = {
            isUnCollapsed: true,
            category: details?.category || '',
            canLeave: details?.canLeave || false,
            leaveWorkspaceLoading: false,
        };
    };

    componentDidMount(){
        const { details, isSidebar } = this.props || {};
        const { name } = details || {};
        if(!isSidebar && name ) {
            this.props.setDocumentTitle(name);
        }
    }

    @bind
    setDefaultState() {
        this.setState(this.defaultState);
    }

    @bind
    @memoize()
    getUsers(teams: Array<Object>) {
        if (!teams || isEmpty(teams)) {
            return [];
        }
        const obj = teams.find(t => t.team.isSystem);
        if (!obj || isEmpty(obj.team.users)) {
            return [];
        }
        return  obj.team.users.map(({ user, role }) => ({ ...user, role }));
    }

    @bind
    @memoize()
    getTeams(teams: Array<Object>) {
        if (!teams || isEmpty(teams)) {
            return [];
        }
        const nonPrivateTeams = teams.filter(t => !t.team.isSystem || t.team.id === PUBLIC_TEAM_ID);
        if (!nonPrivateTeams.length) {
            return [];
        }
        return nonPrivateTeams.map(({ team, role }) => ({ ...team, role }));
    }

    @bind
    removeWorkspaceUsers(selectedUsersListIds) {
        const id = getStr(this.props, 'details.id', '');
        if (!id || isEmpty(selectedUsersListIds)) {
            return;
        }
        return this.props.removeWorkspaceUsers(id, selectedUsersListIds).then(this.setDefaultState);
    }

    @bind
    async onLeaveClick() {
        const { showToastr, details, isSidebar, closeSidebar, reloadList } = this.props;
        const { id, teams } = details || {};
        const isUserExists = this.isUserExists(teams);
        if (isUserExists) {
            this.setState({ leaveWorkspaceLoading: true});
            const res = await this.props.leaveWorkspace(id);
            this.setState({ leaveWorkspaceLoading: false});
            if(!(res instanceof Error)){
                showToastr({ severity: 'success', detail: 'Successfully left the workspace.' });
                if(isSidebar){
                    closeSidebar();
                    reloadList();
                    return;
                };
                history.push('/workspaces');
            }

        }
    }

    @bind
    @memoize()
    isUserExists(teams: Array<Object>) {
        const { profile } = this.props;
        const users = this.getUsers(teams);
        return !!users.find(({ id }) => id === profile.id);
    }

    @bind
    onArchiveClick() {
        const { id, active } = this.props.details || {};
        this.props.updateWorkspace({ id, active: !active }, `Workspace have been ${active ? 'archived' : 'activated'}.`);
    }

    @bind
    @memoize()
    canEdit(owners){
        const { id: userId, isAdmin } = this.props.profile;
        if (isAdmin) {
            return true;
        };
        return !!(owners || []).find(({ id }) => id === userId);
    }
    @bind
    TogglePanelsView(){
        return this.setState({isUnCollapsed: !this.state.isUnCollapsed});
    }
    @bind
    @memoize()
    headerBarRightContent(owners, sidebarActions){
        return (
            <>
                <Flex>
                    <Ellipsis
                        data={[...(owners || []) ]}
                        spaces={-14}
                        displaySize={isMobile ? 3 : 5}
                        renderCount={count => (
                            <MoreChipStyled
                                variant="default"
                                label={`+${count}`}
                                size="small"
                                onClick={() =>this.openSidebar('Admin')}
                            />
                        )}
                        renderItem={
                            ({ name, image, id }: Object) => (
                                <AvatarStyled
                                    onClick={() => this.openSidebar('Admin')} 
                                    src={image ? getAttachmentUrl(id, 'user', image): null}
                                    initials={name}
                                />
                            )
                        }
                    />
                    <DividerVerticalStyled />
                    <Icon name="account-multiple" type='mdi' onClick={() => this.openSidebar('Members')}  />
                    {/* <Icon name="messenger" type='af' onClick={() => this.openSidebar('Sharing')} /> */}
                    {/* <Icon name="shield-account" type='mdi' /> */}
                </Flex>
                {sidebarActions}
            </>
        );
    }
    @bind
    renderWorkspaceActions(isUnCollapsed, disabled, isMember) {
        const { canLeave } = this.props.details || {};
        const padding = (isMobile || this.props?.isSidebar) ? '18px 2px 10px 2px' : '18px 16px 10px 16px';
        return (
            <Flex gap={12} justifyContent="end" padding={padding} position="sticky" top={0}>
                <ButtonStyled type="submit" width="67.82px" disabled={disabled} onClick={this.onSavButtonClick}>Save</ButtonStyled>
                <ConfirmationModalButtonStyled
                    variant="outlined"
                    width="177px"
                    onClick={this.onLeaveClick}
                    disabled={!canLeave || !isMember}
                    confirmationModalProps={{
                        message: 'Are you sure you want to leave the workspace?',
                        declineButtonText: 'Cancel',
                        confirmButtonText: 'Leave',
                    }}
                    withConfirmation
                    modalWrapperProps={{
                        position: 'absolute',
                    }}
                    modalWithWrapper
                >
                    Leave workspace
                </ConfirmationModalButtonStyled>
                <ButtonStyled variant="outlined" minWidth="38px" size="small" onClick={this.TogglePanelsView}>
                    <IconStyled
                        size='sm'
                        hexColor='#7391D0'
                        fontSize="20px"
                        name={isUnCollapsed ? 'unfold-less-horizontal' : 'unfold-more-horizontal'}
                        title={`Click here to ${isUnCollapsed ? 'hide' : 'show'} the workspace panels`}
                    />
                </ButtonStyled>
            </Flex>
        );
    }

    @bind
    renderWorkspaceInfoTitle(iconName, iconType, name){
        return (
            <Grid container wrap="nowrap" alignItems="center">
                {iconName && (
                    <MdiIconStyled name={iconName} type={iconType || 'mdi'} />
                )}
                <PanelName>{name}</PanelName>
            </Grid>
        );
    }
    @bind
    workspaceInfo(id, details){
        const name = 'Workspace';
        const iconType = 'mdi';
        const icon = 'set-left-center';
        const _details = {...details, type:'workspace', primaryClass:{
            name,
            iconType,
            icon,
        }};
        return (
            <StyledMainSection
                details={_details}
                collapsible={false}
                expanded
                renderTitle={()=>this.renderWorkspaceInfoTitle(icon, iconType, name)}
            />
        );
    }

    @bind
    @memoize()
    renderPanelName(name){
        return <PanelName marginTop="-10px">{name}</PanelName>;
    }
    @bind
    renderWorkspaceMembers(users, teams, isUnCollapsed, isDisabled){
        return (
            <ExpansionPanelStyled marginBottom="18px" header={this.renderPanelName('Members')} expanded={isUnCollapsed} collapsible>
                <BuildEllipsisList
                    title="Members"
                    data={users}
                    teams={teams}
                    onClick={() => this.openSidebar('Members')}
                    disabled={isDisabled}
                />
                <ListItem
                    component={<CheckboxStyled
                        name="checkbox"
                        value={this.state.canLeave}
                        onClick={(value) => this.setState({canLeave: !this.state.canLeave})}
                    />}
                    title="Allow users to leave workspace"
                    disabled={isDisabled}
                />
            </ExpansionPanelStyled>
        );
    }
    @bind
    openSidebar(title){
        const { id, openWorkspaceSidebar } = this.props;
        const settings = {title, id, openSidebar:openWorkspaceSidebar};
        return openWorkspaceSidebar(settings);
    }
    @bind
    onSavButtonClick(){
        const { id } = this.props?.details;
        const { canLeave, category } = this.state;
        // passing category as null because if we pass empty string backend throws error of minimum three characters
        return this.props.updateWorkspace({ id, canLeave, category: !!category ? category : null });
    }
    @bind
    isMember(users){
        const { id:userId } = this.props?.profile;
        if(!users?.length){
            return false;
        }
        return !!(users || []).find(({ id }) => id === userId);
    }
    render() {
        const { details, breadcrumbLine, sidebarActions, isSidebar } = this.props;
        const { teams, owners, id, createdDate, modifiedDate, modifiedBy, createdBy, entityResources, classes } = details || {};
        const { isUnCollapsed, category, leaveWorkspaceLoading } = this.state;
        const workspaceTeams = this.getTeams(teams);
        const workspaceUsers = this.getUsers(teams);
        const isDisabled = !this.canEdit(owners);
        const isMember = this.isMember(workspaceUsers);
        const permissions = entityResources?.filter(ent => ent?.entity?.type === 'permission' || ent?.entity?.type === settingsEntityType);
        const resources = entityResources?.filter(ent => ent?.entity?.type !== 'permission' && ent?.entity?.type !== settingsEntityType);
        return (
            <>
                {leaveWorkspaceLoading && <Loader absolute backdrop />}
                <HeaderBar left={breadcrumbLine} right={this.headerBarRightContent(owners, sidebarActions)} />
                <StyledContentArea withHeader isSidebar={isSidebar}>
                    {this.renderWorkspaceActions(isUnCollapsed, isDisabled, isMember)}
                    <PanelsWrapper container >
                        {this.workspaceInfo(id, details, isUnCollapsed)}
                        {this.renderWorkspaceMembers(workspaceUsers, workspaceTeams, isUnCollapsed, isDisabled)}
                        {/* About panel */}
                        <ExpansionPanelStyled header={this.renderPanelName('About')} expanded={isUnCollapsed} collapsible>
                            <AboutGridWrapper>
                                <Grid container justify="space-between">
                                    {modifiedBy && (
                                        <>
                                            <GridHalfStyled>
                                                <TypographyStyled variant="caption">Last modified</TypographyStyled>
                                                <Tooltip title={displayByKind('datetime', modifiedDate)} placement="left">
                                                    <TypographyStyled>
                                                        {fromNow(modifiedDate)}
                                                        {' '}by{' '}
                                                        <EntityLink id={modifiedBy.id} type="user" noDecoration>{modifiedBy.name}</EntityLink>
                                                    </TypographyStyled>
                                                </Tooltip>
                                            </GridHalfStyled>
                                            <DividerVerticalStyled orientation="vertical" flexItem />
                                        </>
                                    )}
                                    {createdDate && (
                                        <GridHalfStyled>
                                            <TypographyStyled variant="caption">Created</TypographyStyled>
                                            <Tooltip title={displayByKind('datetime', createdDate)} placement="left">
                                                <TypographyStyled>
                                                    {fromNow(createdDate)}
                                                    {' '}by{' '}
                                                    <EntityLink id={createdBy.id} type="user" noDecoration>{createdBy.name}</EntityLink>
                                                </TypographyStyled>
                                            </Tooltip>
                                        </GridHalfStyled>
                                    )}
                                </Grid>
                                <TextField
                                    label="Category"
                                    onChange={({target})=>this.setState({category:target?.value})}
                                    value={category}
                                    name="category"
                                    disabled={isDisabled}
                                />
                       
                                <DividerStyled />
                                {/* Admin */}
                                <BuildEllipsisList
                                    title="Admin"
                                    data={owners}
                                    onClick={() => this.openSidebar('Admin')}
                                    disabled={isDisabled}
                                />
                                <DividerStyled />
                                {/* Permissions */}
                                <BuildEllipsisList
                                    title="Permissions"
                                    data={permissions}
                                    dataItemType='chip'
                                    onClick={() => this.openSidebar('Permissions')}
                                    disabled={isDisabled}
                                />
                                <DividerStyled />
                                {/* Resources */}
                                <BuildEllipsisList
                                    title="Resources"
                                    data={resources}
                                    dataItemType='chip'
                                    onClick={() => this.openSidebar('Entity Resources')}
                                    disabled={isDisabled}
                                />
                                <DividerStyled />
                                {/* Classes */}
                                <BuildEllipsisList
                                    title="Classes"
                                    data={classes}
                                    dataItemType='chip'
                                    onClick={() => this.openSidebar('Classes')}
                                    disabled={isDisabled}
                                />
                                {/* <DividerStyled /> */}
                            </AboutGridWrapper>
                        </ExpansionPanelStyled>
                    </PanelsWrapper>
                </StyledContentArea>
            </>
        );
    }
}

export default connect(
    (state, props) => ({
        profile: state.user.profile,
        id: getStr(props, 'details.id'),
        details: state.admin.workspaces.details.data
    }),
    { 
        updateWorkspace,
        removeWorkspaceUsers,
        leaveWorkspace,
        showToastr,
        closeSidebar,
        setDocumentTitle
    }
)(WorkspaceAboutTab);
