/* @flow */

import React from 'react';
import PropTypes from 'prop-types';
import RelationDefinitionLink from 'app/components/atoms/Link/RelationDefinitionLink';
import TaskLink from 'app/components/atoms/Link/TaskLink';
import ProcessLink from 'app/components/atoms/Link/ProcessLink';
import WorkspaceLink from 'app/components/atoms/Link/WorkspaceLink';
import TeamLink from 'app/components/atoms/Link/TeamLink';
import ClassLink from 'app/components/atoms/Link/ClassLink';
import UserLink from 'app/components/atoms/Link/UserLink';
import MapLink from 'app/components/atoms/Link/MapLink';
import FormDefinitionLink from './FormDefinitionLink';
import ProcessDefinitionLink from './ProcessDefinitionLink';
import PipelineLink from './PipelineLink';
import ScriptLink from './ScriptLink';
import Link from 'app/components/atoms/Link/Link';

const PrintTemplateLink = ((props: Object) => {
    const { id, children, path, ...restProps } = props;
    const tab = path ? `/${path}` : '';
    return <Link to={`/print-templates/${id}${tab}`} {...restProps}>{children}</Link>;
});

export const PrimaryClassesEntitiesLink = ((props: Object) => {
    const { id, children, path, type, ...restProps } = props;
    const tab = path ? `/${path}` : '';
    return <Link to={`/entities/${type}/${id}${tab}`} {...restProps}>{children}</Link>;
});

const EventTypeLink = ((props: Object) => {
    const { id, children, path, type, ...restProps } = props;
    const tab = path ? `/${path}` : '';
    return <Link to={`/events/event-types/${id}${tab}`} {...restProps}>{children}</Link>;
});
const ConnectorLink = ((props: Object) => {
    const { id, children, path, type, ...restProps } = props;
    const tab = path ? `/${path}` : '';
    return <Link to={`/designer/connectors/${id}${tab}`} {...restProps}>{children}</Link>;
});
const ModuleLink = ((props: Object) => {
    const { id, children, path, type, ...restProps } = props;
    const tab = path ? `/${path}` : '';
    return <Link to={`/designer/smart-modules/${id}${tab}`} {...restProps}>{children}</Link>;
});

const EventLink = ((props: Object) => {
    const { id, time, children, type, ...restProps } = props;
    return <Link to={`/events/${id}/${time}`} {...restProps}>{children}</Link>;
});

const BackgroundJobLink = ((props: Object) => {
    const { id, children, path, type, adminBackgroundJobs, ...restProps } = props;
    const tab = path ? `/${path}` : '';
    return <Link to={adminBackgroundJobs ? `/background-job/${id}${tab}` : `/abox/background-job/${id}${tab}`} {...restProps}>{children}</Link>;
});

const getLinkComponent = (type) => {
    switch (type) {
        case 'user': return UserLink;
        case 'print-template': return PrintTemplateLink;
        case 'relationdefinition': return RelationDefinitionLink;
        case 'formdefinition': return FormDefinitionLink;
        case 'processdefinition': return ProcessDefinitionLink;
        case 'iot_pipeline': return PipelineLink;
        case 'script': return ScriptLink;
        case 'task': case 'opentask': case 'closedtask': return TaskLink;
        case 'process': case 'openprocess': case 'closedprocess': return ProcessLink;
        case 'team': return TeamLink;
        case 'workspace': return WorkspaceLink;
        case 'system_map': return MapLink;
        case 'class': return ClassLink;
        case 'eventtype': return EventTypeLink;
        case 'backgroundjob': return BackgroundJobLink;
        case 'iot_connector': return ConnectorLink;
        case 'iot_module': return ModuleLink;
        case 'event': return EventLink; // Needs to discuss if we need event about tab or not
        default: return PrimaryClassesEntitiesLink;
    }
};

const EntityLink = (props: Object) => {
    const { type, entityType, pipelineMonitor, ...restProps } = props;
    const Link = getLinkComponent(type);
    if (!Link) {
        return props.children;
    }
    return <Link type={type} entityType={entityType} pipelineMonitor={pipelineMonitor} {...restProps} />;
};

EntityLink.propTypes = {
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
};

export default EntityLink;
