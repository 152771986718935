/* @flow */

import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@mic3/platform-ui';

import EntityLink from 'app/components/atoms/Link/EntityLink';
import Loader from 'app/components/atoms/Loader/Loader';
import {
    loadProcessDefinition, loadProcessAndTasks,
} from 'store/actions/common/digitalTwinActions';
import { startProcess } from 'store/actions/abox/processActions';
import { isEmpty } from 'app/utils/utils';
import { get } from 'app/utils/lo/lo';

class ActionStartProcess extends PureComponent<Object, Object> {

    static propTypes = {
        processDefinitionId: PropTypes.string.isRequired,
        data: PropTypes.object,
        /* redux */
        loadProcessDefinition: PropTypes.func.isRequired,
        isLoading: PropTypes.bool,
        processDefinition: PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string,
        }),
        process: PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string,
        }),
        tasks: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string,
        })),
    }

    state = { error: null };

    componentDidMount() {
        const { isStarting, started, failure } = this.props;
        if (!isStarting && !started && !failure) {
            this.props.loadProcessDefinition(this.props.processDefinitionId);
        }
    }

    componentDidUpdate(prevProps) {
        const { processDefinition, started } = this.props;
        if (!prevProps.processDefinition && processDefinition) {
            this.triggerStartProcess();
        }
        if (!prevProps.started && get(started, 'process.id')) {
            this.props.loadProcessAndTasks(started.process.id);
        }
    }

    async triggerStartProcess(id) {
        try {
            const {
                isStarting, started, failure, processDefinition, data
            } = this.props;
            if (isStarting || started || failure) {
                return;
            }
            const id = processDefinition.id;
            try {
                await this.props.startProcess(id, data, 'started');
            } catch (error) {
                this.setState({ error });
            }
        } catch (error) {
            this.setState({ error });
        }
    }

    render() {
        const {
            processDefinitionId,
            isLoading, processDefinition,
            isStarting, started, failure,
            isLoadingProcessAndTask, process, tasks,
        } = this.props;
        const { error } = this.state;
        const { id, name } = processDefinition || {};
        if (isLoading) {
            return <Loader absolute backdrop />;
        } else if (!processDefinition) {
            return (
                <>
                    Error: Process not found.<br />
                    The process with the id "{processDefinitionId}" does not exist
                    or you have no permissions to access it.
                </>
            );
        } else if (isStarting || isLoadingProcessAndTask) {
            return (
                <>
                    Starting process {name} (#{id})
                    <Loader absolute backdrop />
                </>
            );
        } else if (error) {
            return (
                <>
                    An error occured preparing the data to start the process "{name}" (#{id}).
                    <br /> <br />
                    {String(error)}
                </>
            );
        } else if (failure) {
            return (
                <>
                    An error occured trying to start the process "{name}" (#{id}).
                    <br /> <br />
                    {failure instanceof 'object' ? JSON.stringify(failure) : String(failure)}
                </>
            );
        } else if (!started) {
            return (
                <>
                    An error occured trying to start the process "{name}" (#{id}).
                    <br /> <br />
                    Please try later. If the error persist contact the system administrator.
                </>
            );
        }
        return (
            <>
                <span style={{ paddingLeft: '8px' }}>
                    Process started sucessfully.
                </span>
                { process && (
                    <>
                        <br /> <br />
                        <EntityLink id={process.id} type="process" noDecoration>
                            <Button variant="text">Open the process "{process.name}"</Button>
                        </EntityLink>
                    </>
                )}
                { !isEmpty(tasks) && (
                    tasks.map(({ id, name }) => (
                        <Fragment key={id}>
                            <br /> <br />
                            <EntityLink id={id} type="task" noDecoration>
                                <Button variant="text">Open the task "{name}"</Button>
                            </EntityLink>
                        </Fragment>
                    ))
                )}
            </>
        );
    }
}

export default connect(
    state => ({
        isLoading: state.common.digitalTwin.processDefinition.isLoading,
        processDefinition: state.common.digitalTwin.processDefinition.data,
        isStarting: state.abox.process.start.isLoading,
        started: state.abox.process.start.data,
        failure: state.abox.process.start.failure,
        isLoadingProcessAndTask: state.common.digitalTwin.processAndTasks.isLoading,
        process: get(state.common.digitalTwin.processAndTasks.data, 'process'),
        tasks: get(state.common.digitalTwin.processAndTasks.data, 'tasks')
    }),
    { loadProcessDefinition, startProcess, loadProcessAndTasks },
)(ActionStartProcess);
