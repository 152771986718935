/* @flow */
import { loadData, mutateData } from 'app/utils/redux/action-utils';
import OptionsBuilder from 'app/utils/api/OptionsBuilder';
import boardColumnsQuery from 'graphql/abox/kanban/boardColumnsQuery';
import tasksByBoardColumnQuery from 'graphql/abox/kanban/tasksByBoardColumnQuery';
import boardTaskQuery from 'graphql/abox/kanban/boardTaskQuery';
import createRelationTaskToColumnMutation from 'graphql/abox/kanban/createRelationTaskToColumnMutation';
import deleteRelationMutation from 'graphql/entities/relationships/deleteRelationMutation';
import addRelationshipsQuery from 'graphql/entities/relationships/addRelationshipsQuery';
import relationDefinitionAutocompleteQuery from 'graphql/entities/relationships/relationDefinitionAutocompleteQuery';
import createRelationColumnToBoardMutation from 'graphql/abox/kanban/createRelationColumnToBoardMutation';
import updateRelationMutation from 'graphql/entities/relationships/updateRelationMutation';
import { get } from 'app/utils/lo/lo';

export const LOAD_CUSTOM_ENTITIES_BOARD_LABELS_STARTED: string = '@@affectli/abox/LOAD_CUSTOM_ENTITIES_BOARD_LABELS_STARTED';
export const LOAD_CUSTOM_ENTITIES_BOARD_LABELS_DONE: string = '@@affectli/abox/LOAD_CUSTOM_ENTITIES_BOARD_LABELS_DONE';

export const LOAD_CUSTOM_ENTITIES_BOARD_LABEL_TASKS_STARTED: string = '@@affectli/abox/LOAD_CUSTOM_ENTITIES_BOARD_LABEL_TASKS_STARTED';
export const LOAD_CUSTOM_ENTITIES_BOARD_LABEL_TASKS_DONE: string = '@@affectli/abox/LOAD_CUSTOM_ENTITIES_BOARD_LABEL_TASKS_DONE';

export const LOAD_CUSTOM_ENTITIES_BOARD_LABEL_TASK_STARTED: string = '@@affectli/abox/';
export const LOAD_CUSTOM_ENTITIES_BOARD_LABEL_TASK_DONE: string = '@@affectli/abox/LOAD_CUSTOM_ENTITIES_BOARD_LABEL_TASK_DONE';

export const CREATE_RELATIONSHIP_ENTITY_TO_TASK_STARTED: string = '@@affectli/abox/CREATE_RELATIONSHIP_ENTITY_TO_TASK_STARTED';
export const CREATE_RELATIONSHIP_ENTITY_TO_TASK_DONE: string = '@@affectli/abox/CREATE_RELATIONSHIP_ENTITY_TO_TASK_DONE';

export const REMOVE_TASK_RELATIONSHIP_FROM_COLUMN_STARTED: string = '@@affectli/abox/REMOVE_TASK_RELATIONSHIP_FROM_COLUMN_STARTED';
export const REMOVE_TASK_RELATIONSHIP_FROM_COLUMN_DONE: string = '@@affectli/abox/REMOVE_TASK_RELATIONSHIP_FROM_COLUMN_DONE';

export const REMOVE_COLUMN_RELATIONSHIP_FROM_BOARD_STARTED: string = '@@affectli/abox/REMOVE_COLUMN_RELATIONSHIP_FROM_BOARD_STARTED';
export const REMOVE_COLUMN_RELATIONSHIP_FROM_BOARD_DONE: string = '@@affectli/abox/REMOVE_COLUMN_RELATIONSHIP_FROM_BOARD_DONE';

export const LOAD_RELATION_DEFINITION_TASK_STARTED: string = '@@affectli/abox/LOAD_RELATION_DEFINITION_TASK_STARTED';
export const LOAD_RELATION_DEFINITION_TASK_DONE: string = '@@affectli/abox/LOAD_RELATION_DEFINITION_TASK_DONE';

export const LOAD_BOARD_TASKS_STARTED = '@@affectli/task/LOAD_BOARD_TASKS_STARTED';
export const LOAD_BOARD_TASKS = '@@affectli/task/LOAD_BOARD_TASKS';

export const SET_SELECTED_BOARD: string = '@@affectli/abox/SET_SELECTED_BOARD';
export const SET_BOARD_COLUMN_STATE: string = '@@affectli/abox/SET_BOARD_COLUMN_STATE';
export const SET_BOARD_COLUMN_LOADING: string = '@@affectli/abox/SET_BOARD_COLUMN_LOADING';
export const MOVE_TASK_TO_OTHER_COLUMN: string = '@@affectli/abox/MOVE_TASK_TO_OTHER_COLUMN';
export const MOVE_TASK_TO_OTHER_COLUMN_DONE: string = '@@affectli/abox/MOVE_TASK_TO_OTHER_COLUMN_DONE';
export const ADD_NEW_BOARD_LABEL_TO_COLUMN: string = '@@affectli/abox/ADD_NEW_BOARD_LABEL_TO_COLUMN';
export const CLEAR_BOARD_TASKS: string = '@@affectli/abox/CLEAR_BOARD_TASKS';
export const ADD_TASK_TO_COLUMN: string = '@@affectli/abox/ADD_TASK_TO_COLUMN';
export const REMOVE_TASK_FROM_COLUMN: string = '@@affectli/abox/REMOVE_TASK_TO_COLUMN';
export const REMOVE_COLUMN_FROM_BOARD: string = '@@affectli/abox/REMOVE_COLUMN_FROM_BOARD';
export const REORDER_COLUMN_POSITION: string = '@@affectli/abox/REORDER_COLUMN_POSITION';
export const RESET_BOARDS: string = '@@affectli/abox/RESET_BOARDS';

export const LOAD_COLUMNS_STARTED = '@@affectli/task/LOAD_COLUMNS_STARTED';
export const LOAD_COLUMNS_DONE = '@@affectli/task/LOAD_COLUMNS_DONE';

export const CREATE_RELATIONSHIP_COLUMN_TO_BOARD_STARTED: string = '@@affectli/abox/CREATE_RELATIONSHIP_COLUMN_TO_BOARD_STARTED';
export const CREATE_RELATIONSHIP_COLUMN_TO_BOARD_DONE: string = '@@affectli/abox/CREATE_RELATIONSHIP_COLUMN_TO_BOARD_DONE';

export const UPDATE_RELATIONSHIP_COLUMN_TO_BOARD_STARTED: string = '@@affectli/abox/UPDATE_RELATIONSHIP_COLUMN_TO_BOARD_STARTED';
export const UPDATE_RELATIONSHIP_COLUMN_TO_BOARD_DONE: string = '@@affectli/abox/UPDATE_RELATIONSHIP_COLUMN_TO_BOARD_DONE';

export const SORT_BOARD_TASKS: string = '@@affectli/abox/boards/SORT_BOARD_TASKS';

/**
 * Load custom entities where classification is equals 'board_label
 * @param options filterBy & orderBy query options
 */
export const loadCustomEntityBoardLabels = (options: Object = {}) => {
    const variables = new OptionsBuilder(options)
        .filter({ field: 'relation.relationDefinition.description', op: '=', value: 'parent of' })
        .build();
    return loadData(
        LOAD_CUSTOM_ENTITIES_BOARD_LABELS_STARTED,
        LOAD_CUSTOM_ENTITIES_BOARD_LABELS_DONE,
        boardColumnsQuery
    )(variables);
};

/**
 * Load tasks per board-labels
 * Get tasks filter by relationship custom entity id
 * @param entityId the entity ID
 * @param options startIndex, stopIndex, filterBy & orderBy query options
 */
const getFilterValue = (options) => {
    const relatedFilter = (options.filterBy || []).filter(({ field }) => field === 'relations.relatedEntity.id');
    const filterValue = get(relatedFilter, '[0].value', null);
    if (!filterValue) {
        return [];
    }
    if (Array.isArray(filterValue)) {
        return filterValue;
    }
    return [filterValue];
};

export const loadCustomEntityBoardLabelTasks = (entityId: string, options: Object = {}) => {
    const filterBy = (options.filterBy || []).filter(({ field }) => field !== 'relations.relatedEntity.id');
    const variables = new OptionsBuilder({ ...options, filterBy })
        .filter({ field: 'relations.relatedEntity.id', op: '=', value: entityId })
        .filter({ field: 'relations.relation.relationDefinition.relatedDescription', op: '=', value: 'Has Task' })
        .defaultOrder({ field: 'modifiedDate', asc: false })
        .build();
    const modifyPayload = (payload) => {
        const filterValues = getFilterValue(options);
        if (filterValues?.length) {
            const tasks = payload.tasks.filter(task => task.relations.find(rel => filterValues.includes(rel?.relatedEntity?.id)));
            return { entityId, ...payload, tasks };
        }
        return { entityId, ...payload };
    };
    return loadData(
        LOAD_CUSTOM_ENTITIES_BOARD_LABEL_TASKS_STARTED,
        LOAD_CUSTOM_ENTITIES_BOARD_LABEL_TASKS_DONE,
        tasksByBoardColumnQuery,
        modifyPayload
    )(variables);
};

/**
 * Load task
 * Get the latest updated task
 * @param taskId the task id
 */
export const loadCustomEntityBoardLabelTask = (taskId: string, columnId: string) => {
    const variables = { id: taskId };
    const modifyPayload = (payload) => {
        return { columnId, ...payload };
    };
    return loadData(
        LOAD_CUSTOM_ENTITIES_BOARD_LABEL_TASK_STARTED,
        LOAD_CUSTOM_ENTITIES_BOARD_LABEL_TASK_DONE,
        boardTaskQuery,
        modifyPayload
    )(variables);
};

/**
 * Loads tasks that will be selected when adding new tasks to column
 */
export const loadBoardTasks = (options: Object = {}) => {
    const variables = new OptionsBuilder(options)
        .defaultStartStopIndexs(0, 30).build();

    return loadData(
        LOAD_BOARD_TASKS_STARTED,
        LOAD_BOARD_TASKS,
        addRelationshipsQuery('task', 'tasks')
    )({ ...variables, startIndex: options.startIndex });
};

/**
 * Load the relation definition with the value of "Task Status"
 */
export const loadRelationDefinitionTaskStatus = () => {
    const variables = new OptionsBuilder({})
        .filter({ field: 'relatedDescription', op: '=', value: 'Has Task' })
        .build();

    return loadData(
        LOAD_RELATION_DEFINITION_TASK_STARTED,
        LOAD_RELATION_DEFINITION_TASK_DONE,
        relationDefinitionAutocompleteQuery,
    )({ ...variables });
};

/** Delete the relation between task and custom-entity with classification value of board.
 * @param relation the relation ID.
 */
export const deleteTaskRelationFromColumn = (relation: string) => {
    return mutateData(
        REMOVE_TASK_RELATIONSHIP_FROM_COLUMN_STARTED,
        REMOVE_TASK_RELATIONSHIP_FROM_COLUMN_DONE,
        deleteRelationMutation,
        '',
    )({ relation });
};

/** Delete the relation between custom-entity with classification value of board_label & board.
 * @param relation the relation ID.
 */
export const deleteCoRelationColumnFromBoard = (relation: string) => {
    return mutateData(
        REMOVE_COLUMN_RELATIONSHIP_FROM_BOARD_STARTED,
        REMOVE_COLUMN_RELATIONSHIP_FROM_BOARD_DONE,
        deleteRelationMutation,
        '',
    )({ relation });
};

/**
 * Save a relationship between two entities.
 * @param record
 * {
 *  "relationDefinitionId": (relationshipType.id)
 *  "nodeId1": (task.id)
 *  "nodeId2": (customEntity.id)
 * }
 */
export const createRelationEntityToTask = (record: Object) => {
    return mutateData(
        CREATE_RELATIONSHIP_ENTITY_TO_TASK_STARTED,
        CREATE_RELATIONSHIP_ENTITY_TO_TASK_DONE,
        createRelationTaskToColumnMutation,
        '',
    )(record);
};


/**
 * Move task to another column
 * @param columns the board children
 */
export const moveTaskToOtherColumn = (
    sourceColumnId: string,
    destColumnId: string,
    taskId: string,
    destIndex: number,
    sourceIndex: number) => (dispatch: Function) => {
    dispatch({ type: MOVE_TASK_TO_OTHER_COLUMN, payload: {
        sourceColumnId: sourceColumnId,
        destColumnId: destColumnId,
        taskId: taskId,
        destIndex: destIndex,
        sourceIndex: sourceIndex
    } });
};

/**
 * Move task to another column done
 * @param columnId
 * @param taskId
 */
export const moveTaskToOtherColumnDone = (destColumnId: string, taskId: string) => (dispatch: Function) => {
    dispatch({ type: MOVE_TASK_TO_OTHER_COLUMN_DONE, payload: { destColumnId, taskId }
    });
};

export const sortBoardTasks = (sortBy: string) => (dispatch: Function) => {
    sortBy && sortBy !== 'none' && dispatch({ type: SORT_BOARD_TASKS, payload: sortBy });
};

/**
 * Add task to a column
 * @param columnId the column id to add the task
 * @param task the task to be added
 */
export const addTaskToColumn = (columnId: string, task: Object) => (dispatch: Function) => {
    dispatch({ type: ADD_TASK_TO_COLUMN, payload: { columnId: columnId, task: task } });
};

/**
 * Remove task from a column
 * @param columnId the column id to add the task
 * @param taskIndex the index of the task to be removed
 */
export const removeTaskfromColumn = (
    columnId: string, taskIndex: number, destColumnId: string
) => (dispatch: Function) => {
    dispatch({ type: REMOVE_TASK_FROM_COLUMN, payload: { columnId, taskIndex, destColumnId } });
};

/**
 * Set the selected Board
 * @param board the board object
 */
export const setSelectedBoard = (board: Object) => (dispatch: Function) => {
    dispatch({ type: SET_SELECTED_BOARD, payload: board });
};

/**
 * Set the selected Board Column State
 * @param columns the board children
 */
export const setBoardColumnState = (columns: ?Object[] ) => (dispatch: Function) => {
    dispatch({ type: SET_BOARD_COLUMN_STATE, payload: columns });
};

/**
 * Set the loading state for each column
 * @param columns the board children
 */
export const setBoardColumnLoading = (columnId: string, isLoading: boolean) => (dispatch: Function) => {
    dispatch({ type: SET_BOARD_COLUMN_LOADING, payload: { columnId: columnId, isLoading: isLoading } });
};

/**
 * Add the new board label as a column to the state
 * @param column the column data
 */
export const addNewBoardLabelToColumn = (column: Object) => (dispatch: Function) => {
    dispatch({ type: ADD_NEW_BOARD_LABEL_TO_COLUMN, payload: column });
};

/**
 * Clear the board tasks data
 */
export const clearBoardTasks = () => (dispatch: Function) => {
    dispatch({ type: CLEAR_BOARD_TASKS });
};

/**
 * Reset boards data to initial state
 */
export const resetBoards = () => (dispatch: Function) => {
    dispatch({ type: RESET_BOARDS });
};

/**
 * Loads columns that will be selected when adding new column to board
 */
export const loadBoardColumns = (options: Object = {}) => {
    const variables = new OptionsBuilder(options)
        .filter({ field: 'classes.uri', op: '=', value: 'board_label' })
        .filter({ field: 'active', op: '=', value: true })
        .defaultStartStopIndexs(0, 30).build();
    return loadData(
        LOAD_COLUMNS_STARTED,
        LOAD_COLUMNS_DONE,
        addRelationshipsQuery('customEntity', 'customEntities')
    )({ ...variables, startIndex: options.startIndex });
};

/**
 * Save a relationship between two entities.
 * @param record
 * {
 *   "relationDefinition": (string),
 *   entity: { "type": (entityType), id: (entityId/board_label) },
 *   relatedEntity: { "type": (entityType), id:(entityId/board) },
 *   attributes: {
 *      'board-board-label/order': (index/orderNumber)
 *   }
 */
export const createRelationColumnToBoard = (record: Object) => {
    return mutateData(
        CREATE_RELATIONSHIP_COLUMN_TO_BOARD_STARTED,
        CREATE_RELATIONSHIP_COLUMN_TO_BOARD_DONE,
        createRelationColumnToBoardMutation,
        '',
    )(record);
};

/**
 * Remove column from a board
 * @param relationId relation id of column in selected board.
 */
export const removeColumnFromBoard = (relationId: number, id: string) => (dispatch: Function) => {
    dispatch({ type: REMOVE_COLUMN_FROM_BOARD, payload: { relationId, id } });
};

/**
 * Update a relationship between two entities.
 * @param record
 * {
 *  "relation": (relation.id)
 *  "attributes": {
 *      "board-board-label/order": (index/orderNumber)
 *  }
 * }
 */
export const updateRelationColumnToBoard = (record: Object) => {
    return mutateData(
        UPDATE_RELATIONSHIP_COLUMN_TO_BOARD_STARTED,
        UPDATE_RELATIONSHIP_COLUMN_TO_BOARD_DONE,
        updateRelationMutation,
        '',
    )(record);
};

/**
 * Move column to another column position
 * @param columns reordered column position
 */
export const reorderColumnPosition = (columns: Array<Object>) => (dispatch: Function) => {
    dispatch({ type: REORDER_COLUMN_POSITION, payload: columns });
};
