/* @flow */

import { combineReducers } from 'redux';

import { dataTableReducer, loadDataReducer, keepDataAtStart } from 'app/utils/redux/reducer-utils';
import {
    LOAD_BACKGROUND_JOB_LIST_STARTED, LOAD_BACKGROUND_JOB_LIST,
    LOAD_BACKGROUND_JOB_DETAILS_STARTED, LOAD_BACKGROUND_JOB_DETAILS,
    LOAD_BACKGROUND_JOB_LOGS_STARTED, LOAD_BACKGROUND_JOB_LOGS
} from 'store/actions/abox/backgroundJobActions';

const backgroundJobReducer = combineReducers<Object, Object>({
    list: dataTableReducer(LOAD_BACKGROUND_JOB_LIST_STARTED, LOAD_BACKGROUND_JOB_LIST, keepDataAtStart),
    details: loadDataReducer(LOAD_BACKGROUND_JOB_DETAILS_STARTED, LOAD_BACKGROUND_JOB_DETAILS, keepDataAtStart),
    logs: loadDataReducer(LOAD_BACKGROUND_JOB_LOGS_STARTED, LOAD_BACKGROUND_JOB_LOGS, keepDataAtStart),
});

export default backgroundJobReducer;
