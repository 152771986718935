/* @flow */
import React from 'react';

import Help from 'app/utils/designer/form/settings/common/Help';
import { isVisible } from 'app/utils/designer/form/settings/common/commonEvents';

const panels = settingValues => [
    {
        header: 'General Information',
        expanded: true, 
        children: [
            {
                type: 'text',
                properties: {
                    label: 'Component name',
                    name: 'properties.componentName',
                    help: <Help message="Set the component name." />,
                },
                constraints: {
                    required: true
                },
            },
            {
                type: 'text',
                properties: {
                    label: 'Component uri',
                    name: 'properties.attributeUri',
                    help: <Help message="Set the component uri." />,
                },
                constraints: {
                    required: true
                },
            },
            {
                type: 'text',
                properties: {
                    label: 'Helper text',
                    name: 'properties.helperText',
                    help: <Help message="Enter the helper text." />,
                },
            },
            {
                type: 'checkbox',
                properties: {
                    label: 'Use legacy data source',
                    name: 'properties.legactDataSource',
                    help: <Help message="Choose to use legacy data source." />,
                }
            },
            {
                type: 'text',
                properties: {
                    label: 'Iot join column',
                    name: 'properties.iotJoinColumn',
                    help: <Help message="Set the IoT join column" />,
                },
                constraints: {
                    required: true
                },
            },
            {
                type: 'text',
                properties: {
                    label: 'Entity join column',
                    name: 'properties.entityJoinField',
                    help: <Help message="Set the entity join column." />,
                },
                constraints: {
                    required: true
                },
            },
            {
                type: 'textarea',
                properties: {
                    parseAs: 'sql',
                    modal: true,
                    title: 'Query*',
                    label: 'Query',
                    name: 'properties.query',
                    useCodeEditor: true,
                },
                constraints: {
                    required: true
                }  
            },

        ],
    },
    {
        header: 'Appearance',
        children: [
            isVisible,
            {
                type: 'checkbox',
                properties: {
                    label: 'Show header',
                    name: 'properties.showHeader',
                },
                onChange: (e) => {
                    return !(e.target.value);
                }
            },
            {
                type: 'checkbox',
                properties: {
                    label: 'Can minimize and expand',
                    name: 'properties.collapsible',
                },
            },
        ].filter(Boolean)
    },
];

export default {
    defaults: { children: [], collapsible: true, showHeader: true },
    panels: settingValues => panels(settingValues),
};
