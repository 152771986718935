/* @flow */

import { gql } from '@apollo/client';

export default gql`
    mutation createGraphicMutation($record: GraphicCreateInput!) {
        result: createGraphic(record: $record){
            id
        }
    }
`;
