/* @flow */

import { gql } from '@apollo/client';

import { tasksFields } from 'graphql/abox/task/tasksEntityQuery';

export default gql`
query processTasksQuery($startIndex: Int, $stopIndex: Int, $filterBy: [JSON], $orderBy: [JSON], $excludeBy: [JSON]) {
  count: count(dataType: "task", filterBy: $filterBy, excludeBy: $excludeBy)
  records: tasks(startIndex: $startIndex, stopIndex: $stopIndex, filterBy: $filterBy, orderBy: $orderBy, excludeBy: $excludeBy) {
    ${tasksFields}
    modifiedDate
    assignee {
        id
        name
        image
    }
  }
}
`;
