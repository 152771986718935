import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Typography } from '@mic3/platform-ui';

import { loadTerms, setDocumentTitle, updateTerms } from 'store/actions/app/appActions';

import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { get } from 'app/utils/lo/lo';

import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import PageTemplate from 'app/components/templates/PageTemplate';
import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import HeaderBar from 'app/components/molecules/HeaderBar/HeaderBar';
import Container from 'app/components/atoms/Container/Container';
import PageNotAllowed from 'app/containers/ErrorPages/PageNotAllowed';
import Button from 'app/components/atoms/Designer/Button';
import { getPermissions } from 'app/config/rolesConfig';
import Loader from 'app/components/atoms/Loader/Loader';
import { modulesAndPageTitles } from 'app/config/typesConfig';

const FormGeneratorStyled = styled(FormGenerator)`
    & .MuiDivider-root {
        margin: 0 !important;
    }

    & .LabelBox {
        background: none !important;
    }

    & .actionDivider {
        margin-bottom: 24px;
    }
`;

const ButtonStyled = styled(Button)`
    margin-right: 8px !important;
`;

const HeaderBarStyled = styled(HeaderBar)`
    & .FormField-root {
        display: none;
    }
`;


class Terms extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            termsForm: props.terms || {},
            changed: false,
        };
        props.loadTerms(true);
    }

    formRef = React.createRef();

    componentDidMount() {
        this.props.setDocumentTitle(modulesAndPageTitles.adminConsole.site.terms);
    }

    componentDidUpdate(prevProps) {
        const { terms } = this.props;
        if (terms !== prevProps.terms) {
            this.setState({ termsForm: terms });
        }
    }

    @bind
    handleChange(data) {
        this.setState({ termsForm: data, changed: true });
    }

    @bind
    restoreChanges() {
        this.setState({ termsForm: this.props.terms, changed: false });
    }

    @bind
    onFormSubmit(event: Event, updateStartDate) {
        event.preventDefault();

        if (event.target.innerHTML.includes('Yes') && !updateStartDate) return;

        this.formRef.current.isValidForm().then(({ data, errors }) => {
            if (!errors) {
                const newData = { ...data };

                if (updateStartDate && newData.enabled) {
                    newData.startDate = new Date().toISOString();
                }

                this.props.updateTerms(newData).then(() => {
                    this.setState({ changed: false });
                });
            }
        });
    }

    @bind
    @memoize()
    fieldDefinitions() {
        return [
            {
                field: 'panel',
                type: 'panel',
                properties: {
                    header: 'Terms & Conditions',
                    expanded: true,
                },
                children: [
                    {
                        type: 'checkbox',
                        properties: {
                            label: 'Show terms and conditions',
                            name: 'enabled',
                        },
                    },
                    {
                        type: 'textEditor',
                        properties: {
                            label: 'Terms and Conditions',
                            name: 'content',
                        },
                    },
                ],
            },
        ];
    }

    render() {
        const { profile, terms, isTermsLoaded } = this.props;
        const termsEntityPermissions = getPermissions(terms?.role);
        const { termsForm, changed } = this.state;
        const { permissions, isAdmin } = profile;
        const permissionsSet = new Set(permissions);
        const canView =
            termsEntityPermissions?.canView || isAdmin || permissionsSet.has('admin.settings') || permissionsSet.has('admin.terms');
        
        if (!isTermsLoaded) return <Loader absolute />;
        if (isTermsLoaded && (!canView || !terms)) {
            return <PageNotAllowed title='Terms & Conditions' />;
        }
        return (
            <PageTemplate title='Terms & Conditions' overflowHidden>
                <HeaderBarStyled
                    left={<Typography variant='h6'>Terms & Conditions</Typography>}
                    right={
                        changed && (
                            <>
                                <ButtonStyled color='default' variant='text' onClick={this.restoreChanges}>
                                    Cancel
                                </ButtonStyled>
                                <Button
                                    onClick={(e) => this.onFormSubmit(e, true)}
                                    onClose={(e) => this.onFormSubmit(e, false)}
                                    confirmationModalProps={{
                                        header: 'Users must accept new terms & conditions?',
                                        message:
                                            'All users will be asked to accept the new terms and conditions before they can access the site.',
                                        declineButtonText: 'No',
                                        confirmButtonText: 'Yes',
                                    }}
                                    withConfirmation={termsForm.enabled}
                                >
                                    Save
                                </Button>
                            </>
                        )
                    }
                />
                <ContentArea withHeader>
                    <Container width='1024'>
                        <FormGeneratorStyled
                            components={this.fieldDefinitions()}
                            data={termsForm}
                            disabled={!termsEntityPermissions?.canEdit}
                            ref={this.formRef}
                            onChange={this.handleChange}
                        />
                    </Container>
                </ContentArea>
            </PageTemplate>
        );
    }
}

export default connect(
    (state) => ({
        profile: state.user.profile,
        terms: get(state.app, 'terms', {}),
        isTermsLoaded: state.app.isTermsLoaded,
    }),
    { updateTerms, loadTerms, setDocumentTitle }
)(Terms);
