/* @flow */

import { loadData, mutateData } from 'app/utils/redux/action-utils';
import OptionsBuilder from 'app/utils/api/OptionsBuilder';
import { getAvatar } from 'app/utils/avatar/avatar';
import isUuid from 'uuid-validate';

import pipelineAutocompleteQuery from 'graphql/designer/pipelineAutocompleteQuery';
import designerPipelineListQuery from 'graphql/designer/designerPipelineListQuery';
import pipelineQuery from 'graphql/designer/pipelineQuery';
import pipelineStartedQuery from 'graphql/designer/pipelineStartedQuery';
import createPipelineMutation from 'graphql/designer/createPipelineMutation';
import updatePipelineMutation from 'graphql/designer/updatePipelineMutation__';
import startPipelineMutation from 'graphql/designer/startPipelineMutation';
import connectorStartPipelineMutation from 'graphql/designer/connectorStartPipelineMutation';
import stopPipelineMutation from 'graphql/designer/stopPipelineMutation';
import runtimeStatusPipelineQuery from 'graphql/designer/runtimeStatusPipelineQuery';
import topicTailPipelineQuery from 'graphql/designer/topicTailPipelineQuery';
import connectorLogTailPipelineQuery from 'graphql/designer/connectorLogTailPipelineQuery';
import connectorRestartPipelineMutation from 'graphql/designer/connectorRestartPipelineMutation';
import connectorStatusPipelineQuery from 'graphql/designer/connectorStatusPipelineQuery';
import connectorStopPipelineMutation from 'graphql/designer/connectorStopPipelineMutation';
import loadAvatarQuery from 'graphql/designer/loadAvatarQuery';

export const LOAD_AVATAR_STARTED = '@@affectli/designer/pipelines/LOAD_AVATAR_STARTED';
export const LOAD_AVATAR = '@@affectli/designer/pipelines/LOAD_AVATAR';

export const LOAD_PIPELINE_STARTED: string = '@@affectli/designer/LOAD_PIPELINE_STARTED';
export const LOAD_PIPELINE: string = '@@affectli/designer/LOAD_PIPELINE';

export const LOAD_PIPELINE_STARTED_STARTED_: string = '@@affectli/designer/LOAD_PIPELINE_STARTED_STARTED_';
export const LOAD_PIPELINE_STARTED_: string = '@@affectli/designer/LOAD_PIPELINE_STARTED_';

export const CREATE_PIPELINE_STARTED: string = '@@affectli/designer/CREATE_PIPELINE_STARTED';
export const CREATE_PIPELINE: string = '@@affectli/designer/CREATE_PIPELINE';

export const CONNECTOR_STATUS_PIPELINE_STARTED: string = '@@affectli/designer/CONNECTOR_STATUS_PIPELINE_STARTED';
export const CONNECTOR_STATUS_PIPELINE: string = '@@affectli/designer/CONNECTOR_STATUS_PIPELINE';

export const RUNTIME_STATUS_PIPELINE_STARTED: string = '@@affectli/designer/RUNTIME_STATUS_PIPELINE_STARTED';
export const RUNTIME_STATUS_PIPELINE: string = '@@affectli/designer/RUNTIME_STATUS_PIPELINE';

export const TOPIC_TAIL_PIPELINE_STARTED: string = '@@affectli/designer/TOPIC_TAIL_PIPELINE_STARTED';
export const TOPIC_TAIL_PIPELINE: string = '@@affectli/designer/TOPIC_TAIL_PIPELINE';

export const CONNECTOR_LOG_TAIL_PIPELINE_STARTED: string = '@@affectli/designer/CONNECTOR_LOG_TAIL_PIPELINE_STARTED';
export const CONNECTOR_LOG_TAIL_PIPELINE: string = '@@affectli/designer/CONNECTOR_LOG_TAIL_PIPELINE';

export const LOAD_PIPELINE_MONITOR_STARTED: string = '@@affectli/designer/LOAD_PIPELINE_MONITOR_STARTED';
export const LOAD_PIPELINE_MONITOR: string = '@@affectli/designer/LOAD_PIPELINE_MONITOR';

export const LOAD_PIPELINE_AUTOCOMPLETE_STARTED: string = '@@affectli/designer/LOAD_PIPELINE_AUTOCOMPLETE_STARTED';
export const LOAD_PIPELINE_AUTOCOMPLETE: string = '@@affectli/designer/LOAD_PIPELINE_AUTOCOMPLETE';

export const loadPipelineAutocomplete = loadData(LOAD_PIPELINE_AUTOCOMPLETE_STARTED, LOAD_PIPELINE_AUTOCOMPLETE, pipelineAutocompleteQuery);

export const loadAvatar = id => (dispatch: Function, getState: Function) => {
    const avatar = getAvatar(getState(), 'iot_pipeline', id);
    if (avatar && (avatar.isLoading || avatar.loadedAt > Date.now() - 60 * 60 * 1000)) {
        // if we are loding the avatar or it is already loaded and it is stored less then 1 hour ago
        // we return the data in the state without calling the service
        return Promise.resolve(avatar);
    }
    if(!isUuid(id)) {
        return Promise.resolve(null);
    }
    return loadData(LOAD_AVATAR_STARTED, LOAD_AVATAR, loadAvatarQuery)({ id })(dispatch, getState);
};

/**
 * Loads the pipelines
 */
export const loadPipelineMonitor = (data: Object = {}) => {
    const variables = new OptionsBuilder(data.options)
        .defaultStartStopIndexs(0, 30)
        // FIXME: remove commenting line when we could start pipeline
        .filter({ field: 'primary.iot_pipeline/runtime_started', op: 'is not null'})
        .build();
    return loadData(
        LOAD_PIPELINE_MONITOR_STARTED,
        LOAD_PIPELINE_MONITOR,
        designerPipelineListQuery
    )({ ...variables, startIndex: data.options.startIndex });
};

/**
 * Creates a new the pipeline
 */
export const createPipeline = (record: Object) => mutateData(
    CREATE_PIPELINE_STARTED,
    CREATE_PIPELINE,
    createPipelineMutation,
    'Pipeline created successfully.'
)({ record });

/**
 * Loads the pipeline with the given id
 */
export const loadPipeline = (id: string) => loadData(
    LOAD_PIPELINE_STARTED,
    LOAD_PIPELINE,
    pipelineQuery
)({ id });

/**
 * Loads the pipeline with the given id
 */
export const loadPipelineStarted = (id: string, version: string) => {
    const variables = new OptionsBuilder({ type: 'iot_pipeline_version' })
        .defaultStartStopIndexs(0, 1)    
        .filter({ field: 'primary.iot_pipeline_version/resource_id', op: '=', value: id })
        .filter({ field: 'primary.iot_pipeline_version/version', op: '=', value: String(version) })
        .build();
    return loadData(
        LOAD_PIPELINE_STARTED_STARTED_,
        LOAD_PIPELINE_STARTED_,
        pipelineStartedQuery,
        (payload) => {
            const { records: pipelines } = payload || {};
            return pipelines[0] || null;
        }
    )(variables);
};

/**
 * Updates a pipeline
 */
export const updatePipeline = (record: Object, disableMessage) => mutateData(
    LOAD_PIPELINE_STARTED,
    LOAD_PIPELINE,
    updatePipelineMutation,
    !disableMessage ? 'Pipeline updated successfully.' : null
)({ record });

/*
 * Start an IoT pipeline.
 */
export const startPipeline = (id, version) => mutateData(
    LOAD_PIPELINE_STARTED,
    LOAD_PIPELINE,
    startPipelineMutation,
    'Pipeline started successfully.'
)({ id, version });

/*
 * Stops an IoT pipeline.
 */
export const stopPipeline = (id) => mutateData(
    LOAD_PIPELINE_STARTED,
    LOAD_PIPELINE,
    stopPipelineMutation,
    'Pipeline stoped successfully.'
)({ id });

/*
 * Restarts the specified pipeline's smart connector.
 */
export const connectorRestartPipeline = (id, connectorLabel) => mutateData(
    CONNECTOR_STATUS_PIPELINE_STARTED,
    CONNECTOR_STATUS_PIPELINE,
    connectorRestartPipelineMutation,
    `Pipeline connector ${connectorLabel && `"${connectorLabel}" `}re-started successfully.`
)({ id, connectorLabel });

/*
 * Start the specified pipeline's smart connector.
 */
export const connectorStartPipeline = (id, connectorLabel) => mutateData(
    LOAD_PIPELINE_STARTED,
    LOAD_PIPELINE,
    connectorStartPipelineMutation,
    `Pipeline connector ${connectorLabel && `"${connectorLabel}" `}started successfully.`
)({ id, connectorLabel });

/*
 * Stop the specified pipeline's smart connector.
 */
export const connectorStopPipeline = (id, connectorLabel) => mutateData(
    LOAD_PIPELINE_STARTED,
    LOAD_PIPELINE,
    connectorStopPipelineMutation,
    `Pipeline connector ${connectorLabel && `"${connectorLabel}" `}stoped successfully.`
)({ id, connectorLabel });

/*
 * Returns the runtime status of the specified pipeline's smart connector.
 */
export const connectorStatusPipeline = (id, connectorLabel) => loadData(
    CONNECTOR_STATUS_PIPELINE_STARTED,
    CONNECTOR_STATUS_PIPELINE,
    connectorStatusPipelineQuery
)({ id, connectorLabel });

/*
 * Returns the runtime status of the pipeline's topics and smart connectors.
 */
export const runtimeStatusPipeline = (id) => loadData(
    RUNTIME_STATUS_PIPELINE_STARTED,
    RUNTIME_STATUS_PIPELINE,
    runtimeStatusPipelineQuery
)({ id });

/*
 * Returns the last 10 messages of the specified pipeline's topic.
 */
export const topicTailPipeline = (id, topicLabel) => loadData(
    TOPIC_TAIL_PIPELINE_STARTED,
    TOPIC_TAIL_PIPELINE,
    topicTailPipelineQuery
)({ id, topicLabel });

/*
 * Returns the log of the specified pipeline's connector.
 */
export const connectorLogTailPipeline = loadData(
    CONNECTOR_LOG_TAIL_PIPELINE_STARTED,
    CONNECTOR_LOG_TAIL_PIPELINE,
    connectorLogTailPipelineQuery
);

