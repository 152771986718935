
import React from 'react';
import { Grid, Divider, ListItem, Avatar, ListItemAvatar, ListItemText, IconButton, Tooltip } from '@mic3/platform-ui';
import Icon from 'app/components/atoms/Icon/Icon';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { muiTheme } from 'app/themes/materialUi';

import { getAttachmentUrl } from 'app/utils/attachments/attachmentsUtils';

const GridWrapper = styled(Grid)`
    padding: 16px;
    position: relative;
`;
const OrgImage = styled(Avatar)`
    max-width: 128px;
    max-height: 64px;
    width: auto !important;
    height: auto !important;
`;
const ListItemStyled = styled(ListItem)`
    max-width: 500px;
`;
const ListItemTextStyled = styled(ListItemText)`
   color: ${({ theme }) => theme.base.textColor} !important;
`;
const DividerStyled = styled(Divider)`
    margin: 8px 16px !important;
`;
const ReloadIconWrapper = styled.div`
    position: absolute;
    top: 28px;
    right: 2px;
`;

const TextStyled = styled.span`
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    padding-right: 16px;
`;

export const NameStyled = styled(TextStyled)`
    margin-bottom: -6.5px;
`;

export const EmailStyled = styled(TextStyled)`
    margin-bottom: -5.7px;
`;

const LandingPageHeader = ({reload}) => {
    const organisation = useSelector(
        state => state.app.organisation,
    );
    const organisationImage = useSelector(
        state => state.app.organisationImage,
    );
    const isMobile = useSelector(
        state => state.global.isMobile,
    );
    const profile = useSelector(
        state => state.user.profile,
    );

    const profileImage = profile.image && getAttachmentUrl(profile.id, 'user', profile.image);
    return (
        <GridWrapper container alignItems="center">
            {!isMobile && (
                <>
                    {organisationImage ? <OrgImage variant="square"  src={organisationImage} alt={organisation.name}/>
                        :  <Avatar variant="square"  initials={organisation.name} alt={organisation.name}/>}
                    <DividerStyled flexItem orientation="vertical" />
                </>
            )}
            <ListItemStyled>
                <ListItemAvatar>
                    <Avatar data-tut="reactour__logo" src={profileImage} initials={profile.name}/>
                </ListItemAvatar>
                <ListItemTextStyled primary={<NameStyled>Hello {profile.name}!</NameStyled>} secondary={<EmailStyled>{profile.primary.email}</EmailStyled>} />
            </ListItemStyled>
            <ReloadIconWrapper>
                <Tooltip title="Refresh">
                    <IconButton onClick={reload}>
                        <Icon name="refresh" hexColor={muiTheme.colors.appHeader.icon}/>
                    </IconButton>
                </Tooltip>
            </ReloadIconWrapper>
        </GridWrapper>
    );
};

export default LandingPageHeader;
