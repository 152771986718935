/* @flow */
import React from 'react';

import { name, label } from 'app/utils/designer/form/settings/common/commonBase';
import { onLoad, onChange, isVisible, isDisabled } from 'app/utils/designer/form/settings/common/commonEvents';
import { help } from 'app/utils/designer/form/settings/common/commonHelpers';
import { readOnly } from 'app/utils/designer/form/settings/common/commonValidation';
import { flexGrow } from 'app/utils/designer/form/settings/common/commonStyles';
import Help from 'app/utils/designer/form/settings/common/Help';
import { validateMinMax } from 'app/utils/designer/form/settings/metadata/inputSettings';

const minField = { type: 'number', properties: { label: 'Min', name: 'properties.min' } };
const maxField = { type: 'number', properties: { label: 'Max', name: 'properties.max' } };

const _getMinMaxProperties = (settingsValues) => validateMinMax(minField, maxField, 'properties.min', 'properties.max');

const panels = settingsValues => [
    {
        header: 'Base',
        children: [
            name,
            label,
            { type: 'number', properties: { label: 'Default Value', name: 'properties.defaultValue' } },
            { type: 'number', properties: { label: 'Step', name: 'properties.step' } },
            ..._getMinMaxProperties(settingsValues),
            { type: 'boolean', properties: { label: 'Marks', name: 'properties.marks' } },
            {
                type: 'typeahead',
                properties: {
                    label: 'Value label display',
                    name: 'properties.valueLabelDisplay',
                    valueField:'value',
                    clearable: false,
                    options: [
                        { value: 'auto' , label: 'Auto' },
                        { value: 'on' , label: 'On' },
                        { value: 'off' , label: 'Off' },
                    ],
                }
            },
            {
                type: 'textarea',
                properties: {
                    label: 'Value template',
                    name: 'properties.valueTemplate',
                    help: <Help message={'The template for the value using `{{value}}` as placeholder.\n\nExample:\n\n```\n{{value}}%\n```\n'} />,
                }
            },

        ],
    },
    {
        header: 'Events',
        children: [
            onLoad,
            onChange,
            isVisible,
            settingsValues?.viewType !== 'class' && isDisabled
        ].filter(Boolean)
    },
    settingsValues?.viewType === 'class' && {
        header: 'Validation',
        children: [
            readOnly,
        ]
    },
    {
        header: 'Styles',
        children: [ flexGrow ]
    },
    {
        header: 'Helpers',
        children: [ help ]
    },
].filter(Boolean);

export default {
    defaults: { valueLabelDisplay: 'auto', min: 0, max: 100, step: 5, defaultValue: 0, orientation: 'horizontal', marks: false, withDefault: true, },
    panels: settingValues => panels(settingValues),
};
