/* @flow */

import { gql } from '@apollo/client';

export default gql`
query loadProcessTypeaheadQuery($orderBy: [JSON], $filterBy: [JSON], $startIndex: Int, $stopIndex: Int, $excludeBy: [JSON]) {
  result: processes(orderBy: $orderBy, filterBy: $filterBy, startIndex: $startIndex, stopIndex: $stopIndex, excludeBy: $excludeBy) {
    id
    name
    type
    _geoJson
    __typename
  }
}
`;
