/* @flow */

import { gql } from '@apollo/client';

export default gql`
query widgetTasksCountByDueDateQuery(
    $overdue: [JSON], $overdueExclude: [JSON]
    $today: [JSON], $todayExclude: [JSON],
    $upcoming: [JSON], $upcomingExclude: [JSON],
    $notSet: [JSON], $notSetExclude: [JSON]
) {
    overdue: count(dataType: "task", filterBy: $overdue, excludeBy: $overdueExclude)
    today: count(dataType: "task", filterBy: $today, excludeBy: $todayExclude)
    upcoming: count(dataType: "task", filterBy: $upcoming, excludeBy: $upcomingExclude)
    notSet: count(dataType: "task", filterBy: $notSet, excludeBy: $notSetExclude)
}
`;
