/* @flow */

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { set } from 'app/utils/immutable/Immutable';
import { get } from 'app/utils/lo/lo';
import { ContactInfo } from 'app/utils/types/interfaces';
import { LocationForm } from '../LocationForm/LocationForm';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { getFieldByType } from 'app/utils/designer/form/fieldUtils';


/**
 * Collapsable address form
 */
export class CollapsableAddressForm extends Component<Object, Object> {

    static propTypes = {
        value: PropTypes.object,
        name: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        contactInfoIndex: PropTypes.number,
        onRemove: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            ...(props.value || {})
        };
    }

    @bind
    remove(e: Object) {
        e.preventDefault();
        this.props.onRemove(this.props.contactInfoIndex);
    };

    @bind
    @memoize()
    renderAddressTitle(contactInfo: Object) {
        const city = String(get(contactInfo, 'address.city') || '');
        const shortAddressName = city ? ` - ${city}` : '';
        return `${contactInfo.sub_type || '' } Address${shortAddressName}`;
    }

    @bind
    handleChange(evt: Object) {
        const value = this.state;
        let event = evt;
        if (evt.name === 'is_primary') {
            event = { target: evt };
        } else {
            event = { target: { name: 'address', value: {
                ...(this.props.value || {}),
                ...evt.target.value.address,
            }}};
        }
        this.setState(
            set(value, event.target.name, event.target.value),
            () => {
                this.props.onChange(event);
            });
    }

    render() {
        const contactInfo: ContactInfo = this.state || {};
        return (
            <>
                <LocationForm name="address" value={contactInfo} onChange={this.handleChange} addressOnlyFields iconInfo={{ name: 'pin', color: '#c62828'}} onRemove={this.remove}/>
                {getFieldByType('checkbox', {
                    label: 'Primary',
                    name: 'is_primary',
                    value: contactInfo.is_primary,
                    changeData: this.handleChange
                })}
                {getFieldByType('button', {
                    label: 'Delete',
                    name: '',
                    variant: 'text',
                    onClick: this.props.onRemove,
                    fullWidth: true
                })}
            </>
        );
    }
}
