import React, { memo, useCallback } from 'react';
import styled from 'styled-components';
import { Paper, List, ListSubheader, ListItem, Typography } from '@mic3/platform-ui';

import { getUserStatus } from 'app/utils/chat/chatUtils';

const MentionsPopover = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-y: auto;

    & .MuiListSubheader-root {
        line-height: 42px;
        height: 32px;
    }

    & .MuiTypography-root {
        font-size: 14px;
    }
`;

const ListItemStyled = styled(ListItem)`
    padding-top: 6px !important;
    padding-bottom: 6px !important;
`;

const UserListItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const TextWrap = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const TextWrapNoEllipsis = styled.div`
    white-space: nowrap;
    margin-right: 4px;
`;

const ChatMentions = ({
    onSelectUser,
    users,
    selectedUserIndex,
}) => {
    const handleSelectMentionedUser = useCallback((username) => {
        onSelectUser(username);
    }, [onSelectUser]);

    const renderListItem = ({ username, name, isSuggested, outside }) => {
        if (username === 'People') {
            return <Typography variant="overline" gutterBottom>People</Typography>;
        } else if (username === 'all') { 
            return (
                <TextWrap>
                    <Typography variant="subtitle1" component="span" style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>
                        {`${username} `}
                    </Typography>
                    <Typography variant="caption">Notify all in this room</Typography>
                </TextWrap>
            );
        } else if (username === 'here') {
            return (
                <TextWrap>
                    <Typography variant="subtitle1" component="span" style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>
                        {`${username} `}
                    </Typography> 
                    <Typography variant="caption">Notify active users in this room</Typography>
                </TextWrap>
            );
        } else {
            return (
                <UserListItem>
                    <TextWrapNoEllipsis>
                        <Typography variant="subtitle1" component="span" style={{ fontWeight: 'bold'}}>
                            {`${username} `}
                        </Typography> 
                        <Typography variant="caption">{name}</Typography>
                    </TextWrapNoEllipsis>
                    <TextWrap>
                        <Typography variant="caption">{getUserStatus(isSuggested, outside)}</Typography>
                    </TextWrap>
                </UserListItem>
            );
        }
    };

    return (
        <MentionsPopover>
            <Paper>
                <ListSubheader component="div">
                    People
                </ListSubheader>
                <List>
                    {(users || [])
                        .map((user, index) => (
                            <ListItemStyled
                                key={index}
                                button
                                selected={index === selectedUserIndex}
                                onClick={() => handleSelectMentionedUser(user?.username)}
                            >
                                {renderListItem(user)}
                            </ListItemStyled>
                        ))
                    }
                </List>
            </Paper>
        </MentionsPopover>
    );
};

export default memo(ChatMentions);