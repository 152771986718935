/* @flow */

import { gql } from '@apollo/client';

export default gql`
mutation publishProcessDefinitionMutation($id: ID!){
  result: publishProcessDefinition(id: $id) {
    id
  }
}
`;
