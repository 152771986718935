/* @flow */

import { gql } from '@apollo/client';

export default gql`
mutation createBroadcastMutation($record: BroadcastCreateInput!) {
    result: createBroadcast(record: $record) {
      id
    }
}
`;
