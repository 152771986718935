/* @flow */

import Immutable from 'app/utils/immutable/Immutable';
import {
    GET_MAPED_RELATIONS_STARTED,
    GET_MAPED_RELATIONS,
    SAVE_RELATION_DEFINITIONS_STARTED,
    SAVE_RELATION_DEFINITIONS,
    ADD_DEFINITION_TO_CACHE
} from 'store/actions/entities/relationshipsActions';

/**
 * Generic Entity reducer.
 *
 * @param state - the current Redux state
 * @param action - the dispatched action
 * @returns the new Redux state
 */
const initialState = Immutable({
    relationDefinitionsMap: {
        isLoading: false
    },
});

const reducer = (state: Object = initialState, action: Object): Object => {
    const { type, meta, payload, error } = action;
    switch (type) {
        case SAVE_RELATION_DEFINITIONS_STARTED: {
            return Immutable({ ...state,  relationDefinitionsMap: { ...state.relationDefinitionsMap, isLoading: true }});
        };
        case SAVE_RELATION_DEFINITIONS: {
            const { type, definitions } = payload || {};
            if (type) {
                const defs = [...(state.relationDefinitionsMap?.[type] || [])];
                definitions.forEach((definition) => {
                    const result = defs.find((def) => definition?.id === def?.id);
                    if (!result) defs.push(definition);
                });
                return Immutable({
                    ...state,
                    relationDefinitionsMap: { ...state.relationDefinitionsMap, [type]: defs, isLoading: false },
                });
            }
            return Immutable({
                ...state,
                relationDefinitionsMap: { ...state.relationDefinitionsMap, isLoading: false },
            });
        }
        case ADD_DEFINITION_TO_CACHE:
            if (payload?.type) {
                const defs = [...(state.relationDefinitionsMap?.[payload.type] || [])];
                defs.push(payload);
                return Immutable({
                    ...state,
                    relationDefinitionsMap: { ...state.relationDefinitionsMap, [payload?.type]: defs },
                });
            }
            return Immutable({
                ...state,
            });
        case GET_MAPED_RELATIONS_STARTED:
            return Immutable({
                ...state,
                [meta.entityId]: { ...(state[meta.entityId] || {}), isLoading: true },
            });

        case GET_MAPED_RELATIONS:
            if (error) {
                return Immutable({
                    ...state,
                    [meta.entityId]: { isLoading: false },
                });
            }
            return Immutable({
                ...state,
                [meta.entityId]: { ...(state[meta.entityId] || {}), isLoading: false, loadedAt: Date.now(), relations: payload.relations },
            });

        default:
            return state;
    }
};

export default reducer;
