/* @flow */

import { PureComponent } from 'react';
import { connect } from 'react-redux';

import Geocode from 'app/utils/maps/geocodeUtils';
import { showToastr } from 'store/actions/app/appActions';
import { bind } from 'app/utils/decorators/decoratorUtils';
import { get } from 'app/utils/lo/lo';

class GeotagPosition  extends PureComponent {

    componentDidMount() {
        this.updateCoords();
    }

    @bind
    updateCoords() {
        const { name, onChange } = this.props;
        Geocode.getCurrentLocation((response: Object) => {
            onChange({ target: { value: {
                accuracy: get(response, 'coords.accuracy', null),
                latitude: get(response, 'coords.latitude', null),
                longitude: get(response, 'coords.longitude', null)
            }, name }});
        }, (err: Object) => {
            if (err && err.code === 1) {
                this.props.showToastr({ severity: 'error', detail: `Please enable your location sharing.` });
            }
        });
    }

    render() {
        return null;
    }
};

export default connect(
    state => ({
        profile: state.user.profile
    }),
    { showToastr }
)(GeotagPosition);
