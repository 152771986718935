/* @flow */

const analyticsMenu = (isAdmin, permissions) => {
    const permissionsSet = new Set(permissions);
    const canSeeGrafana = isAdmin || permissionsSet.has('analytics.grafana');
    const canSeeReports = isAdmin || permissionsSet.has('entity.entity');
    const canSeeSA = isAdmin || permissionsSet.has('analytics.superAnalytics');
    return [
        canSeeReports && {
            name: 'Reports',
            icon: 'printer',
            to: '/print-reports'
        },
        canSeeSA && {
            name: 'Superset',
            icon: 'poll-box',
            to: '/super-analytics'
        },
        canSeeGrafana && {
            name: 'Grafana',
            icon: 'chart-bar-stacked',
            to: '/grafana'
        }
    ].filter(Boolean);
};

export default analyticsMenu;
