// @flow

import { gql } from '@apollo/client';

import { detailsFields } from './taskQuery';
import { digitalTwinQueryFields } from 'graphql/entities/classifications/classificationDetailQuery';

export default gql`
    query loadTaskSidebarQuery($id: ID!) {
        result: task(id: $id) {
            ${detailsFields}
            primaryClass {
              ${digitalTwinQueryFields}
            }
        }
    }
`;
