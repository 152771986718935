/* @flow */

import { gql } from '@apollo/client';

export default gql`
mutation updateFormDefinitionMutation($record: FormDefinitionUpdateInput!){
  result: updateFormDefinition(record: $record) {
    id
  }
}
`;
