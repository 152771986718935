/* @flow */

import { gql } from '@apollo/client';
import { tasksFields } from './tasksEntityQuery';
import { digitalTwinShortQueryFields } from 'graphql/entities/classifications/classificationDetailQuery';
import { entityReferences } from 'graphql/entities/relationships/relationsQuery';


export default gql`
query tasksQuery($startIndex: Int, $stopIndex: Int, $filterBy: [JSON], $orderBy: [JSON], $excludeBy: [JSON]) {
    count: count(dataType: "task", filterBy: $filterBy, excludeBy: $excludeBy)
    records: tasks(startIndex: $startIndex, stopIndex: $stopIndex, filterBy: $filterBy, orderBy: $orderBy, excludeBy: $excludeBy) {
        ${tasksFields}
        primaryClass {
            ${digitalTwinShortQueryFields}
        }
            _wkt
    iconName
    iconColor
        process {
            id
            name
            type
            processDefinition {
                id
                name
                type
                image
            }
            processDefinitionVersion {
                id
                name
             }
          variables(fields:["INITIATOR"])
        }
        classes {
            id
            name
            uri
        }
        relations {
          relation {
              id
              relationDefinition {
                  id
              }
          }
          relatedEntity {
            ${entityReferences}
          }
        }
        workspaces {
            isSystem
            team { 
                name
                id 
            }
            user { 
                id
                name
                image
            }
        }
        createdDate
        modifiedDate
        assignee {
            id
            name
            image
        }
        createdBy {
            id
            name
            image
        }
        modifiedBy {
            id
            name
            image
        }
    }
}
`;
