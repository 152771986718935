/* @flow */

import React from 'react';

import ConnectorStatus from 'app/containers/Designer/Pipeline/Components/ConnectorStatus';

const panels = (settingValues) => {
    return [
        {
            type: 'custom',
            properties: {
                cid: 'statusPanel',
                name: 'data',
                pipelineId: settingValues.details.id,
                handleConnectorStatus: settingValues.handleConnectorStatus,
                details: settingValues.details, 
                pipelineMonitor: settingValues.pipelineMonitor, 
                pipelineStatus: settingValues.pipelineStatus,
                getCurrentConnectorStatus: settingValues.getCurrentConnectorStatus,
                noBackground: true,
                Component: props => <ConnectorStatus {...props}/>,
            },
        },
    ].filter(Boolean);
};

export default {
    defaults: {},
    panels: settingValues => panels(settingValues),
};
