import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, InputBase } from '@mic3/platform-ui';

import { loadRelatedEntiyRelationDefinitions } from 'store/actions/entities/relatedEntitiesActions';
import { loadPrimaryClasses } from 'store/actions/entities/entitiesActions';
import { bind, memoize, debounce } from 'app/utils/decorators/decoratorUtils';

import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import VirtualList from 'app/components/molecules/VirtualList/VirtualList';
import Icon from 'app/components/atoms/Icon/Icon';
import Loader from 'app/components/atoms/Loader/Loader';
import Alert from 'app/components/molecules/Alert/Alert';
import RelationDefinitionListItem from './RelationDefinitionListItem';

const ButtonStyled = styled(Button)`
    margin-right: 12px !important;
`;

const SearchField = styled(InputBase)`
    padding: 7px;
    margin: 12px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.24);
    border-bottom: 1px solid rgba(255, 255, 255, 0.24);
`;

const SearchIcon = styled(Icon)`
    margin-right: 12px;
`;

const RelationDefinitionList = styled.div`
    div[class*='Container__ContainerStyle'] {
        background-color: transparent;
        margin-bottom: 0;
    }
    div[class*='VirtualList__TinyVirtual'] {
        padding-top: 0 !important;
        margin-top: 0.2em;
    }
    div[class*='Loader__LoadWrapper'] {
        width: 40px;
        right: calc(50% - 40px);
    }
    height: ${isMobile ? '60vh' : '50vh'} !important;
    padding-bottom: 36px;
    .MuiListItem-root.Mui-selected, 
    .MuiListItem-root.Mui-selected:hover {
        background: rgba(255, 255, 255, 0.04);
    }
`;

const inputProps = { disableUnderline: true };

const initalState = {
    selected: null,
    startIndex: 0,
    stopIndex: 30,
    total: 0,
    list: [],
    search: '',
    filterBy: [],
    excludeBy: [],
    orderBy: [],
    firstLoading: true,
    searchLoading: false
};

class AddRelationDefinitionTreeModal extends PureComponent {
    static propTypes = {
        isOpen: PropTypes.bool,
        closeModal: PropTypes.func.isRequired,
        filterBy: PropTypes.array.isRequired,
        excludeBy: PropTypes.array.isRequired,
        modalTitle: PropTypes.string,
        selectedClass: PropTypes.any,
        selectedType: PropTypes.string,
        customExcludeBy: PropTypes.array
    }

    constructor(props) {
        super(props);
        this.state = { ...initalState };
        props.loadPrimaryClasses((payload) => {
            const { records: classes } = payload || {};
            return classes?.filter(cls => cls?.active);
        });
    }

    virtualListRef = React.createRef();

    componentDidUpdate(prevProps, prevState) {
        const { filterBy, excludeBy } = this.props;
        
        if ((filterBy !== prevProps.filterBy) && filterBy.length > 0) {
            this.setState({ filterBy, excludeBy }, () => {
                const { startIndex, stopIndex } = this.state;
                this.loadData({ startIndex, stopIndex, filterBy, excludeBy });
            });
        }
        
        if (
            prevProps.selectedClass !== this.props.selectedClass ||
            prevProps.relatedEntities !== this.props.relatedEntities
        ) {
            this.setSelected();
            this.addReverseRecords(this.props.relatedEntities);
        }
    }

    @bind
    setSelected() {
        const { relatedEntities, selectedClass } = this.props;

        const relEnt = relatedEntities.find(cls => cls.id === selectedClass);

        if (!relEnt) {
            this.setState({ selected: this.state.selected ?? null });
            return;
        }

        const newRelEnt = { ...relEnt, reverse: false };

        if (newRelEnt.type === newRelEnt.relatedType) {
            newRelEnt.reverse = true;
        }

        this.setState({ selected: newRelEnt }, () => {
            this.updateList();
        });
    }

    @bind
    updateList() {
        if (this.virtualListRef && this.virtualListRef.current) {
            this.virtualListRef.current.refreshList();
        }
    }

    @bind
    closeModal() {
        this.props.closeModal();
        this.resetState();
    }

    @bind
    onSubmit() {
        this.props.onSubmit(this.state.selected);
        this.resetState();
    }

    @bind
    resetState() {
        this.setState({ ...initalState });
    }

    @bind 
    handleSelect(selected) {
        this.setState(((prevState) => {
            if (prevState.selected?.id === selected?.id && prevState.selected?.reverse === selected?.reverse) {
                return {
                    ...prevState,
                    selected: null
                };
            }
            return {
                ...prevState,
                selected
            };
        }), () => this.updateList());
    }

    @bind
    handleSearch(e) {
        if (e.persist) {
            e.persist();
        }

        const filterBy = [
            {
                or: [
                    { field: 'description', op: 'contains', value: e.target.value || ''  },
                    { field: 'relatedDescription', op: 'contains', value: e.target.value || '' }
                ]
            },
            ...this.props.filterBy
        ];

        const { excludeBy } = this.state;

        this.setState({
            search: e.target.value,
            filterBy,
            list: [],
            total: 0,
            startIndex: 0,
            stopIndex: 30,
            searchLoading: true
        });

        this.loadData({ startIndex: 0, stopIndex: 30, filterBy, excludeBy });
    } 

    @bind
    checkIfDuplicateSibling(selected) {
        return (this.props.customExcludeBy || []).find(r => r.id === selected.id && r.reverse === selected.reverse);
    }

    @bind
    @debounce(300)
    loadData(options) {
        return this.props.loadRelatedEntiyRelationDefinitions(options).then(() => {
            this.setState({ searchLoading: false });
        });
    }

    @bind
    loadMore({ startIndex, stopIndex }) {
        if (stopIndex >= this.state.stopIndex) {
            this.setState({ 
                startIndex: this.state.stopIndex + 1,
                stopIndex: this.state.stopIndex + 30,
            }, () => {
                const { startIndex, stopIndex, filterBy, excludeBy } = this.state;
                this.loadData({ startIndex, stopIndex, filterBy, excludeBy });
            });
        }
    }

    @bind
    renderComponent({ style, index, data, selected }) {
        const { description, relatedDescription, type, relatedType, id, reverse } = data;
        const title = reverse ? relatedDescription : description;
        const uri = reverse ? type : relatedType;
        const isRelatedClassSelected = this.checkIfDuplicateSibling(data);

        return (
            <div style={style} key={index}>
                <RelationDefinitionListItem
                    data={data}
                    title={title}
                    isRelClassSelected={isRelatedClassSelected}
                    isSelected={selected?.id === id && selected?.reverse === reverse}
                    onItemClick={this.handleSelect}
                    uri={uri}
                />
            </div>
        );
    }

    @bind
    @memoize()
    mapEntityTypeToRecords(records, entityTypes, selectedType) {
        return records.map((record) => {
            const { type, relatedType } = record || {};
            const reverse = record['reverse'] !== undefined ? record['reverse'] : type === selectedType ? false : true;
            const uri = reverse ? type : relatedType;
            const classData = entityTypes.find(entityType => entityType.uri === uri);
            const { icon, iconType, name, color } = classData || {};

            return {
                ...record,
                reverse,
                entityType: {
                    icon,
                    iconType,
                    name,
                    color
                }
            };
        });
    }

    @bind
    @memoize()
    addReverseRecords(relEntities) {
        const reversedRelEntities = relEntities.reduce((prev, cur) => {
            if (cur.type === cur.relatedType) {
                prev.push({ ...cur, reverse: true  });
            }

            return prev;
        }, []);
       
        let duplicateCount = 0;
        const list = [...this.state.list, ...relEntities, ...reversedRelEntities].reduce((prev, cur) => {
            const duplicateRecordIndex = prev.findIndex(d => d.id === cur.id && d.reverse === cur.reverse);

            if (duplicateRecordIndex !== -1) {
                duplicateCount++;
                return prev;
            }

            prev.push(cur);

            return prev;
        }, []);

        this.setState((prevState) => ({
            firstLoading: false,
            total: prevState.total + relEntities.length + reversedRelEntities.length - duplicateCount,
            list
        }));
    }

    @bind
    showNoResult() {
        const { firstLoading, searchLoading } = this.state;

        if (!firstLoading && !searchLoading && !this.props.isLoading && this.state.total === 0) {
            return <Alert type='background' margin={16}>No results</Alert>;
        }

        return null;
    };

    render() {
        const { isOpen, modalTitle, entityTypes, selectedType } = this.props;
        const { search, list, total, selected, firstLoading, searchLoading } = this.state;
        const newList = this.mapEntityTypeToRecords(list, entityTypes, selectedType);
        
        return (
            isOpen && (
                <ModalDialog
                    title={modalTitle}
                    onClose={this.closeModal}
                    actions={
                        <>
                            <ButtonStyled onClick={this.closeModal} color="primary" variant="text">
                                Cancel
                            </ButtonStyled>
                            <Button onClick={this.onSubmit} color="primary" disabled={!selected}>
                                Submit
                            </Button>
                        </>
                    }
                > 
                    <SearchField
                        onChange={this.handleSearch}
                        value={search}
                        fullWidth
                        margin="none"
                        placeholder="Search..."
                        InputProps={inputProps}
                        startAdornment={
                            <SearchIcon name="magnify" hexColor="#dadada" />
                        }
                    />
                    <RelationDefinitionList>
                        {(firstLoading || searchLoading) && <Loader absolute />}
                        {this.showNoResult()}
                        <VirtualList
                            ref={this.virtualListRef}
                            renderItem={({ index, style, resize }) => {
                                return newList[index]
                                    ? this.renderComponent({ style, index, data: newList[index], selected })
                                    : <div style={style} key={index} />;
                            }}
                            itemSize={84}
                            itemCount={total}
                            onItemsRendered={this.loadMore}
                            width='100%'
                        />
                    </RelationDefinitionList>
                </ModalDialog>
            )
        );
    }
}

export default connect(
    state => ({
        isLoading: state.entities.relatedEntities.list.isLoading,
        relatedEntities: state.entities.relatedEntities.list.records,
        startIndex: state.entities.relatedEntities.list.startIndex,
        stopIndex: state.entities.relatedEntities.list.stopIndex,
        totalRelatedEntities: state.entities.relatedEntities.list.count,
        entityTypes : state.app.allPrimaryClasses.records || []
    }),
    {
        loadRelatedEntiyRelationDefinitions,
        loadPrimaryClasses
    }
)(AddRelationDefinitionTreeModal);
