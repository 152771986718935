/* @flow */

import { combineReducers } from 'redux';

import { dataTableReducer, loadDataReducer, compareId, keepDataAtStart } from 'app/utils/redux/reducer-utils';
import {
    LOAD_TASKS_STARTED, LOAD_TASKS,
    LOAD_CALENDAR_TASKS_STARTED, LOAD_CALENDAR_TASKS,
    LOAD_TIMELINE_TASKS_STARTED, LOAD_TIMELINE_TASKS,
    LOAD_TASK_HISTORY_STARTED, LOAD_TASK_HISTORY,
    LOAD_TASK_SIDEBAR_DETAILS_STARTED, LOAD_TASK_SIDEBAR_DETAILS,
    LOAD_TASK_SIDEBAR_DETAILS_INTERNAL_STARTED, LOAD_TASK_SIDEBAR_DETAILS_INTERNAL
} from 'store/actions/abox/taskActions';
import avatar from './avatar/tasksAvatarReducer';

const taskReducer = combineReducers<Object, Object>({
    timeline: dataTableReducer(LOAD_TIMELINE_TASKS_STARTED, LOAD_TIMELINE_TASKS, keepDataAtStart),
    calendar: dataTableReducer(LOAD_CALENDAR_TASKS_STARTED, LOAD_CALENDAR_TASKS, keepDataAtStart),
    history: dataTableReducer(LOAD_TASK_HISTORY_STARTED, LOAD_TASK_HISTORY, keepDataAtStart),
    sidebarDetails: loadDataReducer(LOAD_TASK_SIDEBAR_DETAILS_STARTED, LOAD_TASK_SIDEBAR_DETAILS, compareId),
    sidebarDetailsInternal: loadDataReducer(LOAD_TASK_SIDEBAR_DETAILS_INTERNAL_STARTED, LOAD_TASK_SIDEBAR_DETAILS_INTERNAL, compareId),
    list: dataTableReducer(LOAD_TASKS_STARTED, LOAD_TASKS, keepDataAtStart),
    avatar
});

export default taskReducer;
