/* @flow */

import { gql } from '@apollo/client';

import { defaultPipelineQueryFields } from './pipelineQuery';

export default gql`
	mutation stopPipelineMutation($id: ID!){
		result: iotPipelineStop(id: $id) {
			${defaultPipelineQueryFields}
		}
	}
`;
