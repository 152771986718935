/* @flow */

import { gql } from '@apollo/client';

export default gql`
query loadAvatarQuery($id: ID!) {
    result: broadcast(id: $id) {
        id
        message
    }
}
`;
