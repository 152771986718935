/* @flow */

import { gql } from '@apollo/client';
import { isMobile } from 'react-device-detect';

import { loadData, mutateData } from 'app/utils/redux/action-utils';
import Immutable, { set } from 'app/utils/immutable/Immutable';
import { get } from 'app/utils/lo/lo';

import { graphql } from 'graphql/client';
import profileQuery from 'graphql/users/profileQuery';
import loadLoginActionsQuery from 'graphql/users/loadLoginActionsQuery';
import integrationsQuery from 'graphql/users/integrationsQuery';
import userUpdatePositionQuery from 'graphql/users/userUpdatePositionQuery';
import userAutocompleteQuery from 'graphql/users/userAutocompleteQuery';
import userChatCredentialsQuery from 'graphql/users/userChatCredentialsQuery';
import userUpdateTermsMutation from 'graphql/users/userUpdateTermsMutation';
import userUpdateUserPasswordMutation from 'graphql/users/userUpdateUserPasswordMutation';
import updateLoginActionsMutation from 'graphql/users/updateLoginActionsMutation';
import { uploadGQLImage } from 'store/actions/common/graphicLibraryActions';
import { getGlobalAccountUserProfile } from 'app/utils/globalAccount/httpUserApi';
import { addRoleToList } from 'app/config/rolesConfig';

import integration from 'app/config/integrationConfig';
import settingsPermissionsQuery from 'graphql/app/settingsPermissionsQuery';
import { isDefined } from 'app/utils/utils';

export const LOAD_USER_PREFERENCES_STARTED = '@@affectli/users/LOAD_USER_PREFERENCES_STARTED';
export const LOAD_USER_PREFERENCES = '@@affectli/users/LOAD_USER_PREFERENCES';

export const LOAD_INTEGRATIONS_STARTED = '@@affectli/users/LOAD_INTEGRATIONS_STARTED';
export const LOAD_INTEGRATIONS = '@@affectli/users/LOAD_INTEGRATIONS';

export const SAVE_USER_PREFERENCES_STARTED = '@@affectli/users/SAVE_USER_PREFERENCES_STARTED';
export const SAVE_USER_PREFERENCES = '@@affectli/users/SAVE_USER_PREFERENCES';

export const SAVE_BACKGROUND_PREFERENCES_STARTED  = '@@affectli/users/SAVE_BACKGROUND_PREFERENCES_STARTED';
export const SAVE_BACKGROUND_PREFERENCES  = '@@affectli/users/SAVE_BACKGROUND_PREFERENCES';

export const LOAD_USER_PROFILE_STARTED = '@@affectli/users/LOAD_USER_PROFILE_STARTED';
export const LOAD_USER_PROFILE = '@@affectli/users/LOAD_USER_PROFILE';
export const UPDATE_USER_PROFILE_PERMISSIONS = '@@affectli/users/UPDATE_USER_PROFILE_PERMISSIONS';

export const SAVE_USER_POSITION_STARTED = '@@affectli/users/SAVE_USER_POSITION_STARTED';
export const SAVE_USER_POSITION = '@@affectli/users/SAVE_USER_POSITION';

export const UPLOAD_PROFILE_IMAGE_STARTED = '@@affectli/users/UPLOAD_PROFILE_IMAGE_STARTED';
export const UPLOAD_PROFILE_IMAGE = '@@affectli/users/UPLOAD_PROFILE_IMAGE';

export const LOAD_USER_REFERENCES_STARTED = '@@affectli/users/LOAD_USER_REFERENCES_STARTED';
export const LOAD_USER_REFERENCES = '@@affectli/users/LOAD_USER_REFERENCES';

export const SAVE_FILTERS_PREFERENCES_STARTED = '@@affectli/users/SAVE_FILTERS_PREFERENCES_STARTED';
export const SAVE_FILTERS_PREFERENCES = '@@affectli/users/SAVE_FILTERS_PREFERENCES';

export const SAVE_DASHBOARD_PREFERENCES_STARTED = '@@affectli/users/SAVE_DASHBOARD_PREFERENCES_STARTED';
export const SAVE_DASHBOARD_PREFERENCES = '@@affectli/users/SAVE_DASHBOARD_PREFERENCES';

export const SAVE_TIMELINE_PREFERENECES_STARTED = '@@affectli/users/SAVE_TIMELINE_PREFERENECES_STARTED';
export const SAVE_TIMELINE_PREFERENECES = '@@affectli/users/SAVE_TIMELINE_PREFERENECES';

export const SAVE_BOARD_PREFERENECES_STARTED = '@@affectli/users/SAVE_BOARD_PREFERENECES_STARTED';
export const SAVE_BOARD_PREFERENECES = '@@affectli/users/SAVE_BOARD_PREFERENECES';

export const LOAD_CHAT_CREDENTIALS_STARTED = '@@affectli/users/LOAD_CHAT_CREDENTIALS_STARTED';
export const LOAD_CHAT_CREDENTIALS = '@@affectli/users/LOAD_CHAT_CREDENTIALS';

export const LOAD_AVATAR_REFERENCES_STARTED = '@@affectli/entities/LOAD_AVATAR_REFERENCES_STARTED';
export const LOAD_AVATAR_REFERENCES = '@@affectli/entities/LOAD_AVATAR_REFERENCES';

export const LOAD_USER_PROFILE_GA_STARTED = '@@affectli/entities/LOAD_USER_PROFILE_GA_STARTED';
export const LOAD_USER_PROFILE_GA = '@@affectli/entities/LOAD_USER_PROFILE_GA';

export const UPDATE_USER_TERMS_STARTED = '@@affectli/users/UPDATE_USER_TERMS_STARTED';
export const UPDATE_USER_TERMS = '@@affectli/users/UPDATE_USER_TERMS';

export const UPDATE_USER_PASSWORD_STARTED = '@@affectli/users/UPDATE_USER_PASSWORD_STARTED';
export const UPDATE_USER_PASSWORD = '@@affectli/users/UPDATE_USER_PASSWORD';

export const LOAD_LOGIN_ACTIONS_STARTED = '@@affectli/users/LOAD_LOGIN_ACTIONS_STARTED';
export const LOAD_LOGIN_ACTIONS = '@@affectli/users/LOAD_LOGIN_ACTIONS';

export const UPDATE_LOGIN_ACTIONS_STARTED = '@@affectli/users/UPDATE_LOGIN_ACTIONS_STARTED';
export const UPDATE_LOGIN_ACTIONS = '@@affectli/users/UPDATE_LOGIN_ACTIONS';

export const loadChatCredentials = loadData(
    LOAD_CHAT_CREDENTIALS_STARTED,
    LOAD_CHAT_CREDENTIALS,
    userChatCredentialsQuery,
);

export const loadUserReferences = () => loadData(LOAD_USER_REFERENCES_STARTED, LOAD_USER_REFERENCES, userAutocompleteQuery)();

/**
 * Load the user profile
 */
export const loadUserProfile = () => (dispatch: Function, getState: Function) => {
    dispatch({ type: LOAD_USER_PROFILE_STARTED });
    graphql.query({
        query: profileQuery,
        fetchPolicy: 'no-cache'
    }).then( (response: Object) => {
        dispatch({ type: LOAD_USER_PROFILE, payload: Immutable(get(response, 'data.user')) });
    }).catch((error) => {
        dispatch({ type: LOAD_USER_PROFILE, payload: error, error: true });
    });
};

export const loadSettingsPermissions = () => (dispatch, getState) => {
    graphql.query({ query: settingsPermissionsQuery, fetchPolicy: 'no-cache', })
        .then(async (response: Object) => {
            const { entities } = response?.data || {};
            if (!entities?.length) return;
            const payload = entities.map(({ name }) => name).filter(Boolean);
            dispatch({ type: UPDATE_USER_PROFILE_PERMISSIONS, payload });
        })
        .catch((error) => {
            console.error('usersActions ~ loadSettingsPermissions ~ error:', error) // eslint-disable-line
        });
};

/**
 * Check if the user profile is linked to the global account
 */
export const findGlobalAccountUserProfile = () => async (dispatch: Function, getState: Function) => {
    dispatch({ type: LOAD_USER_PROFILE_GA_STARTED });
    try {
        const data = await getGlobalAccountUserProfile();
        dispatch({ type: LOAD_USER_PROFILE_GA, payload: data });
    } catch (e) {
        const error = 'An error occurred getting user profile';
        return dispatch({ type: LOAD_USER_PROFILE_GA, error: true, meta: Immutable({ error }) });
    }
};

/*
 * The issue is that in the events monitor, the "status" and "severity" fields were previously single-select.
 * However, these fields have now been updated to support multi-select functionality. 
 * As a result, users with previously saved preferences in the single-select format encounter query failures 
 * due to the new multi-select field definition.
 * 
 * To prevent this, we need to normalize the old preferences data by converting the single-select values 
 * into arrays, ensuring compatibility with the new multi-select structure.
 * 
 * For further details, refer to ticket number 0e904e6c.
 */
const normalizePreferences = (preferences) => {
    if (!preferences?.filters) {
        return preferences;
    }

    try {
        const filtersData = { ...preferences?.filters };
        const normalizedFilters = {};

        for (const key in filtersData) {
            if (key.startsWith('EventsListView')) {
                const filterSet = { ...filtersData[key].filters };

                if (filterSet) {
                    const status = filterSet.status;
                    const severity = filterSet.severity;

                    if (isDefined(status) && !Array.isArray(status)) {
                        filterSet.status = [status];
                    }
                    if (isDefined(severity) && !Array.isArray(severity)) {
                        filterSet.severity = [severity];
                    }
                }
                normalizedFilters[key] = { ...filtersData[key], filters: filterSet };
            } else {
                normalizedFilters[key] = { ...filtersData[key] };
            }
        }

        return { ...preferences, filters: normalizedFilters };
    } catch (error) {
        return preferences;
    }
};


export const _fetchPreferences = async () => {
    const result = await graphql.query({
        query: gql`query profile {
          profile {
            primary(fields: ["preferences"])
          }
        }`,
        fetchPolicy: 'no-cache'
    });
    const preferences = get(result, 'data.profile.primary.preferences');
    const nromalizedPreferences = normalizePreferences(preferences);
    return nromalizedPreferences;
};

export const _savePreferences = async (preferences: Object) => {
    const result = await graphql.mutate({
        mutation: gql`mutation savePreferences($preferences: JSON!) {
          savePreferences(preferences: $preferences) {
            primary(fields: ["preferences"])
          }
        }`,
        variables: { preferences }
    });
    return get(result, 'data.profile.primary.preferences');
};

export const userUpdatePosition = (latitude: String, longitude: String) => (dispatch: Function, getState: Function) => {
    dispatch({ type: SAVE_USER_POSITION_STARTED });
    return graphql.mutate({
        mutation: userUpdatePositionQuery,
        variables: {latitude, longitude},
    }).then( (response: Object) => {
        dispatch({ type: SAVE_USER_POSITION });
    }).catch((error) => {
        dispatch({ type: SAVE_USER_POSITION, payload: error, error: true });
    });
};

/**
 * Load the user preferences
 */
export const loadUserPreferences = () => (dispatch: Function, getState: Function) => {
    dispatch({ type: LOAD_USER_PREFERENCES_STARTED });
    _fetchPreferences().then((data) => {
        dispatch({ type: LOAD_USER_PREFERENCES, payload: Immutable(data) });
    }).catch((error) => {
        dispatch({ type: LOAD_USER_PREFERENCES, error: true, payload: Immutable(error) });
    });
};


/**
 * Saves the user preferences
 */
export const saveUserPreferences = (preferences: Object) => (dispatch: Function, getState: Function) => {
    dispatch({ type: SAVE_USER_PREFERENCES_STARTED });
    _savePreferences(preferences).then(() => {
        dispatch({
            type: SAVE_USER_PREFERENCES,
            payload: Immutable(preferences),
            meta: Immutable({ successMessage: 'User\'s preferences correctly saved.' }),
        });
    }).catch((error) => {
        dispatch({
            type: SAVE_USER_PREFERENCES,
            error: true,
            payload: error,
            meta: Immutable({ errorMessage: 'An error occured saving the user\'s preferences.' }),
        });
    });
};
export const saveColumnPreferences = (preferences: Object) => (dispatch: Function, getState: Function) => {
    dispatch({ type: SAVE_BACKGROUND_PREFERENCES_STARTED });
    _savePreferences(preferences).then(() => {
        dispatch({
            type: SAVE_BACKGROUND_PREFERENCES,
            payload: Immutable(preferences),
            meta: Immutable({ successMessage: 'User\'s preferences correctly saved.' }),
        });
    }).catch((error) => {
        dispatch({
            type: SAVE_BACKGROUND_PREFERENCES,
            error: true,
            payload: error,
            meta: Immutable({ errorMessage: 'An error occured saving the user\'s preferences.' }),
        });
    });
};

/**
 * Saves the data table status in the user preferences.
 *
 * @param dataTableId the data table's ID.
 * @param status the data table's status.
 */
export const saveFiltersPreferences = (id: string, status: Object, successMessage) => async (dispatch: Function, getState: Function) => {
    dispatch({ type: SAVE_FILTERS_PREFERENCES_STARTED });
    try {
        let preferences = getState().user.preferences;
        if(!preferences) {
            preferences = await _fetchPreferences();
        }
        preferences = set(preferences, `filters.${id}`, status);
        await _savePreferences(preferences);
        dispatch({
            type: SAVE_FILTERS_PREFERENCES,
            payload: Immutable(preferences),
            meta: Immutable({ successMessage: successMessage || 'Filters saved successfully.' }),
        });
    } catch (error) {
        dispatch({
            type: SAVE_FILTERS_PREFERENCES,
            error: true,
            payload: Immutable(error),
            meta: Immutable({ errorMessage: 'An error occured saving the preferences.' }),
        });
    }
};

/**
 * Upload the user's profile image.
 *
 * @param image - the image to attach (required)
 */
export const uploadProfileImage = (image: File) => (dispatch: Function, getState: Function): Promise<Object> => {
    dispatch({ type: UPLOAD_PROFILE_IMAGE_STARTED });
    let id = '';
    try {
        if (!image) {
            throw new Error('The image parameter is required');
        }
        const state = getState();
        id = state.user.profile.id;
        if (!id) {
            throw new Error('The profile.id is required!');
        }

        // if the file is not an image return an error
        if ( image.type.indexOf('image/') !== 0 ) {
            const error = new Error(`The file "${image.name}" is not an image.`);
            return Promise.reject(error);
        }
    } catch (error) {
        dispatch({ type: UPLOAD_PROFILE_IMAGE, payload: Immutable(error), error: true, meta: Immutable({ errorMessage: error.message }) });
        return Promise.reject(error);
    }

    const info = { id, image: '' };
    return uploadGQLImage({ id, type: 'user', image })
        .then((response: Object) => {
            dispatch({ type: UPLOAD_PROFILE_IMAGE, payload: Immutable(info), meta: Immutable({ successMessage: 'Profile image uploaded.' }) });
            return info;
        }).catch((error) => {
            dispatch({ type: UPLOAD_PROFILE_IMAGE, payload: error, error: true, meta: Immutable({ errorMessage: 'Profile image upload failed.' }) });
            return error;
        });
};

/**
 * Saves the user dashboard preferences
 */
export const saveUserDashboardPreferences = (isReset: boolean = false, preferences: Object) => (dispatch: Function, getState: Function) => {
    dispatch({ type: SAVE_DASHBOARD_PREFERENCES_STARTED });
    _savePreferences(preferences).then((data) => {
        dispatch({
            type: SAVE_DASHBOARD_PREFERENCES,
            payload: Immutable(preferences),
            meta: Immutable({ successMessage: `User's dashboard preferences correctly ${!isReset ? 'saved' : 'restored'}.` }),
        });
    }).catch((error) => {
        dispatch({
            type: SAVE_DASHBOARD_PREFERENCES,
            error: true,
            payload: error,
            meta: Immutable({ errorMessage: `An error occured on ${!isReset ? 'saving' : 'restoring'} the user's dashboard preferences.` }),
        });
    });
};

/**
 * Save the timeline preferences
 */
export const saveTimelinePreferences = (preferences: Object) => (dispatch: Function, getState: Function) => {
    dispatch({ type: SAVE_TIMELINE_PREFERENECES_STARTED });
    _savePreferences(preferences).then((data) => {
        dispatch({
            type: SAVE_TIMELINE_PREFERENECES,
            payload: Immutable(preferences),
            meta: Immutable({ successMessage: `Timeline preferences successfully saved.` }),
        });
    }).catch((error) => {
        dispatch({
            type: SAVE_TIMELINE_PREFERENECES,
            error: true,
            payload: error,
            meta: Immutable({ errorMessage: `An error occured on restoring the user's timeline preferences.` }),
        });
    });
};

/**
 * Save the board preferences
 */
export const saveBoardPreferences = (preferences: Object) => (dispatch: Function, getState: Function) => {
    dispatch({ type: SAVE_BOARD_PREFERENECES_STARTED });
    _savePreferences(preferences).then((data) => {
        dispatch({
            type: SAVE_BOARD_PREFERENECES,
            payload: Immutable(preferences),
            meta: Immutable({ successMessage: `Board preferences successfully saved.` }),
        });
    }).catch((error) => {
        dispatch({
            type: SAVE_BOARD_PREFERENECES,
            error: true,
            payload: error,
            meta: Immutable({ errorMessage: `An error occured on restoring the user's board preferences.` }),
        });
    });
};

export const loadIntegrations = () => async (dispatch, getState) => {
    dispatch({ type: LOAD_INTEGRATIONS_STARTED });
    try {
        const profile = getState().user.profile;
        const { permissions, isAdmin } = profile || {};
        const permissionsSet = new Set(permissions || []);
        const canView = isAdmin || permissionsSet.has(integration.permissions.view);
        if (!canView) return dispatch({ type: LOAD_INTEGRATIONS });
        const activeFilter = { field: 'active', op: '=', value: true };
        const orderBy =  [{field: 'name', direction: 'asc nulls last'}];
        const filterBy = isMobile ? [activeFilter, { field: 'primary.integration/visible_on_small_screen', op: '=', value: 'true' }] : [activeFilter]; // Only mobile view load only those integrations which has visible on small screen attribute set to true
        graphql.query({
            query: integrationsQuery,
            variables: { type: integration.type, filterBy, orderBy },
            fetchPolicy: 'no-cache'
        }).then(async (response: Object) => {
            const records = get(response, 'data.records');
            const data = await addRoleToList({ records });
            dispatch({ type: LOAD_INTEGRATIONS, payload: Immutable(data?.records) });
        }).catch((error) => {
            dispatch({ type: LOAD_INTEGRATIONS, error: true });
        });
    } catch (error) {
        return dispatch({ type: LOAD_INTEGRATIONS, error: true }); 
    }
};

/**
 * Update profile terms and agreement
 */
export const updateUserTerms = () => (dispatch: Function, getState: Function) => {
    dispatch({ type: UPDATE_USER_TERMS_STARTED });
    return graphql.mutate({
        mutation: userUpdateTermsMutation
    }).then((response: Object) => {
        dispatch({ type: UPDATE_USER_TERMS });
    }).catch((error) => {
        dispatch({ type: UPDATE_USER_TERMS, payload: error, error: true });
    });
};

/**
 * Update user password
 */
export const updateUserPassword = (username, password) =>
    mutateData(
        UPDATE_USER_PASSWORD_STARTED,
        UPDATE_USER_PASSWORD,
        userUpdateUserPasswordMutation,
        'Password changed successfully.',
    )({ username, password });


export const updateLoginActions = (username, actions) =>
    mutateData(
        UPDATE_LOGIN_ACTIONS_STARTED,
        UPDATE_LOGIN_ACTIONS,
        updateLoginActionsMutation(actions),
        'Actions changed successfully.',
    )({ username, actions });


export const loadLoginActions = loadData(
    LOAD_LOGIN_ACTIONS_STARTED,
    LOAD_LOGIN_ACTIONS,
    loadLoginActionsQuery,
);