// @flow

import React, { memo, useEffect, useState } from 'react';
import styled from 'styled-components';
import { muiTheme } from 'app/themes/materialUi';
import {
    Box,
    Grid,
    Typography,
    Divider,
    Link,
    ListItem,
    ListItemText,
    InputBase,
    Avatar,
    Popper,
    Fade,
    Paper,
    ClickAwayListener,
    InputAdornment,
    IconButton
} from '@mic3/platform-ui';
import Icon from 'app/components/atoms/Icon/Icon';

const PopperBase = styled(Popper)`
    z-index: 1201;
`;

const AppsContent = styled(Box)`
    max-height: calc(100vh - 300px);
    width: 360px;
    overflow: auto;
    padding: 8px;

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px ${({ theme }) => theme.material.colors.background.paper};
        border-radius: 10px;
        background-color: ${({ theme }) => theme.material.colors.background.paper} !important;
    }
    &::-webkit-scrollbar {
        width: 4px;
        background-color: ${({ theme }) => theme.material.colors.background.paper} !important;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background: ${({ theme }) => theme.material.colors.background.scrollbar};
    }
`;

const AppsTitle = styled(Typography)`
    margin-bottom: 12px !important;
    padding-left: 8px;
    padding-right: 8px;
    display: inline-block;
    font-size: 10px;
`;

const TypographyStyled = styled(Typography)`
    font-size: 12px !important;
    text-align: center !important;
    margin-top: 8px !important;
    margin-bottom: 8px !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    width: 98% !important;
`;

const AppLink = styled(Link)`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none !important;
    padding: 12px 0;

    & .MuiTypography-root {
        text-transform: none;
        color: ${({ theme }) => theme.base.textColor };
    }
`;

const AppAvatar = styled(Avatar)`
    background: ${({ className }) => className } !important;
`;

const AppsDivider = styled(Divider)`
    margin-left: 8px !important;
    margin-right: 8px !important;
`;

const AppsLauncher = ({
    anchorEl,
    onClose,
    apps,
    modules,
    placement,
    ...otherProps
}: Object) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [searchItems, setSearchItems] = useState([]);
    const handleClearInput = () => setSearchQuery('');
    const handleClose = () => onClose();
    const handleSearch = (e: Object) => {
        const { value }= e.target;
        if (value) {
            setSearchQuery(value);
        } else {
            setSearchQuery('');
        }
    };

    useEffect(() => {
        const items = [...apps, ...modules];
        const results = items.filter(item => item.name.toLowerCase().includes((searchQuery || '').toLowerCase()));
        setSearchItems(results);
    }, [searchQuery, apps, modules]);

    return (
        <PopperBase
            {...otherProps}
            placement={placement}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            modifiers={{ computeStyle: { gpuAcceleration: false } }}
            transition
        >
            {({ TransitionProps }) => (
                <ClickAwayListener onClickAway={onClose}>
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper>
                            <ListItem component="div" ContainerComponent="div">
                                <ListItemText primary={(
                                    <InputBase
                                        value={searchQuery}
                                        placeholder="Search..."
                                        onChange={handleSearch}
                                        fullWidth
                                        endAdornment={
                                            <>
                                                {searchQuery && (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={handleClearInput}>
                                                            <Icon name="close" />
                                                        </IconButton>
                                                    </InputAdornment>
                                                )}
                                            </>
                                        }
                                    />
                                )} />
                            </ListItem>
                            <AppsDivider />
                            <AppsContent>
                                <Box height="16px" />
                                { !searchQuery ? (
                                    <>
                                        <AppsTitle variant="caption">MODULES</AppsTitle>
                                        <Grid container>
                                            {modules.map((item, index) => (
                                                <Grid item xs={3} key={index}>
                                                    <AppLink to={item.to} onClick={handleClose}>
                                                        <AppAvatar className={item.hexColor} >
                                                            <Icon name={item.icon} type={item.iconType || 'mdi'} hexColor={item.color} />
                                                        </AppAvatar>
                                                        <TypographyStyled variant="subtitle1">{item.name}</TypographyStyled>
                                                    </AppLink>
                                                </Grid>
                                            ))}
                                        </Grid>
                                        <Divider />
                                        <Box height="24px" />
                                        <AppsTitle variant="caption">APPS</AppsTitle>
                                        <Grid container>
                                            {apps.length > 0 ? apps.map((item, index) => (
                                                <Grid item xs={3} key={index}>
                                                    <AppLink to={`/abox/process-start/${item.id}`} onClick={handleClose}>
                                                        <AppAvatar className={item.iconColor} variant="rounded">
                                                            <Icon type={item.iconType || 'mdi'} name={item.iconName || 'asterisk'} hexColor={muiTheme.colors.text.button}/>
                                                        </AppAvatar>
                                                        <TypographyStyled variant="subtitle1">{item.name}</TypographyStyled>
                                                    </AppLink>
                                                </Grid>
                                            )) : (
                                                <Box m={2}>
                                                    <Typography variant="caption">You have no Apps...</Typography>
                                                </Box>
                                            )}
                                        </Grid>
                                    </>
                                ) : (
                                    <>
                                        <AppsTitle variant="caption">SEARCH RESULTS</AppsTitle>
                                        { searchItems.length > 0 ? (
                                            <Grid container>
                                                {searchItems.map((item, index) => (
                                                    <Grid item xs={3} key={index}>
                                                        {!item.id ? (
                                                            <AppLink to={item.to} onClick={handleClose}>
                                                                <AppAvatar className={item.hexColor} >
                                                                    <Icon name={item.icon} type={item.iconType} hexColor={item.color} />
                                                                </AppAvatar>
                                                                <TypographyStyled variant="subtitle1">{item.name}</TypographyStyled>
                                                            </AppLink>
                                                        ) : (
                                                            <AppLink to={`/abox/process-start/${item.id}`} onClick={handleClose}>
                                                                <AppAvatar className={item.iconColor} variant="rounded">
                                                                    <Icon type={item.iconType || 'mdi'} name={item.iconName || 'asterisk'} />
                                                                </AppAvatar>
                                                                <TypographyStyled variant="subtitle1">{item.name}</TypographyStyled>
                                                            </AppLink>
                                                        )}
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        ) : (
                                            <Box component={Typography} variant="body2" p={2}>No results matched...</Box>
                                        )}
                                    </>
                                )}
                            </AppsContent>
                            <AppsDivider />
                            <Box paddingTop={4} paddingBottom={4} display="flex" justifyContent="center">
                                <Typography variant="body2">
                                    <Link to="/abox/processes-new" onClick={handleClose}>SHOW MORE APPS</Link>
                                </Typography>
                            </Box>
                        </Paper>
                    </Fade>
                </ClickAwayListener>
            )}
        </PopperBase>
    );
};

AppsLauncher.defaultProps = {
    placement: 'bottom-end',
    apps: [],
    modules: []
};

export default memo(AppsLauncher);
