/* @flow */

import { gql } from '@apollo/client';

import { defaultPipelineQueryFields } from './pipelineQuery';

export default gql`
	mutation startPipelineMutation($id: ID!, $version: Int){
		result: iotPipelineStart(id: $id, version: $version) {
			${defaultPipelineQueryFields}
		}
	}
`;
