/* @flow */

import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress } from '@mic3/platform-ui';
import { connect } from 'react-redux';

import { updateClassificationReport } from 'store/actions/classifications/classificationsActions';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import BaseModal from 'app/components/Designer/Modals/BaseModal';
import Container from 'app/components/atoms/Container/Container';
import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import DotMenu from 'app/components/molecules/DotMenu/DotMenu';
import MainSection from 'app/components/organisms/EntitySections/MainSection';
import AboutSection from 'app/components/organisms/EntitySections/AboutSection';
import ClassDetailSection from 'app/components/organisms/EntitySections/ClassDetailSection';
import EntityDetailSection from 'app/components/organisms/EntitySections/EntityDetailSection';
import EntityTabsSection from 'app/components/organisms/EntitySections/EntityTabsSection';
import EntityAppearanceSection from 'app/components/organisms/EntitySections/EntityAppearanceSection';
import Immutable from 'app/utils/immutable/Immutable';
import HeaderBar from 'app/components/molecules/HeaderBar/HeaderBar';
import { get, set } from 'app/utils/lo/lo';
import { safeToJS } from 'app/utils/trasformer/transformer';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { updateClassChildrens, loadClassChildrens } from 'store/actions/classifications/classificationsActions';
import EntitySvgSectionMap from 'app/components/organisms/EntitySections/EntitySvgSectionMap';
import { getOnlyUpdatedData } from 'app/utils/app/appUtils';
import { copyToClipboard, modifyFieldDefinitionsURI } from 'app/utils/classification/classificationUtils';
import EntityMapSection from 'app/components/organisms/EntitySections/EntityMapSection';
import DigitalTwinConfigSection from 'app/components/organisms/EntitySections/DigitalTwinConfigSection';
import { updateEntity3dModel, deleteEntity3dModel } from 'store/actions/maps/situationalAwarenessActions';
import { rocketHost } from 'app/utils/env';
import { getSvgSource, clickFile, imgToBlob } from 'app/utils/maps/layer/layerUtils';
import { showToastr } from 'store/actions/app/appActions';


/**
 * General tab in classifications view.
 * Todo: We probably should extract the form in it's own component, however
 * nearly the only code here is form related.
 */
class AbstractClassificationPrimaryAbout extends PureComponent<Object, Object> {
    static propTypes: Object = {
        classification: PropTypes.object.isRequired,
        updateClassification: PropTypes.func,
        canEdit: PropTypes.bool,
        entityType: PropTypes.bool,
        updateClassificationReport: PropTypes.func
    };

    colors: Array<string>;
    refForms: Object = {
        classDetailForm: React.createRef(),
        classMapRef: React.createRef()

    };
    renameRef: Object = React.createRef();

    /**
     *
     * @param props
     */
    constructor(props: Object) {
        super(props);
        this.state = Immutable({
            classificationForm: {
                ...(props.classification || {}), type: 'class',
                iconState: { value: props.classification?.icon, label: props.classification?.icon, type: props.classification?.iconType },
                entityModelScaling:  props.classification?.entityModelScaling || 1,
                entityModelHeading: props.classification?.entityModelHeading || 50
            },
            isSvgMap: false,
            svgRotation: null,
            errors: null,
            renameActive: false,
            isApplied: false,
            openReportingModal: false,
            openUriChangeWarningModal: false,
        });
    }

    componentDidUpdate(prevsProp: Object) {
        if (prevsProp.classification !== this.props.classification) {
            this.setState({ classificationForm: {
                ...(this.props.classification || {}),
                type: 'class',
                iconState: { value: this.props.classification.icon, label: this.props.classification.icon, type: this.props.classification.iconType },
            } });
        }
    }

    @bind
    openSidebar() {
        console.error('Extend class from this abstract should have `openSidebar` method'); // eslint-disable-line no-console
    }

    /**
     * Handle a form change
     * @param event
     */
    @bind
    handleChange(e, data) {
        const {
            target: { name, value }
        } = e;        
        const { classificationForm } = this.state;
        const { id } = classificationForm;
        if(name === '3dModel' && value && id) {
            this.props.updateEntity3dModel('',id,value ,true);
        }
        const nextState = { classificationForm: set(this.state.classificationForm, name, value) };
        if(name === 'iconState') {
            nextState.classificationForm = {
                ...nextState.classificationForm,
                icon: value?.value || null,
                iconType: value?.type || null,
            };
        }

        this.setState(state => nextState, async () => {
            const results = await Promise.all(Object.entries(this.refForms).map(([key, ref]) => ref.current?.isValidForm()));
            const errors = results?.some((r) => !!r?.errors);
            this.setState({ errors });
        });
    }

    @bind
    _normalizeValue(value: any) {
        return value.toLowerCase();
    }

    /**
     * Save the form.
     */
    @bind
    async onFormSubmit() {
        const { childrens: prevChildrens, updateClassChildrens, loadClassChildrens, showToastr, entityType }  = this.props;
        const { svgRotation, classificationForm } = this.state;
        const {
            id,
            name,
            childrens: newChildrens,
            uri,
            abstract: cAbstract,
            active,
            entityTabs,
            entityGraphic,
            entitySvg,
            entitySvgVisibleZoom,
            entityModelScaling,
            entityModelHeading,
            color,
            applicableOn,
            parents,
            icon,
            iconType,
            description,
            hidden,
            openInModule,
            primary,
            entityAppearance
        } = classificationForm || {};

        const parentsIds = (parents || []).map(parent => get(parent, 'id')).filter(Boolean);
        if (newChildrens !== prevChildrens && newChildrens) {
            const addedPromises = newChildrens.map(async (ch) => {
                if (!prevChildrens.find(c => c.id === ch.id)) {
                    await updateClassChildrens({ id: ch.id, parents: [...(ch.parents || []), classificationForm].map(c => c.id) });
                }
            }, []);
            const missed = prevChildrens.filter(ch => !newChildrens.find(c => ch.id === c.id));
            const missedPromises = missed.map(async (ch) => {
                await updateClassChildrens({ id: ch.id, parents: (ch.parents || []).filter(c => c.id === classificationForm.id) });
            });

            await Promise.all(addedPromises);
            await Promise.all(missedPromises);
            await loadClassChildrens(classificationForm.id);
        }

        const updatedData = getOnlyUpdatedData(this.props.classification, {
            applicableOn: primary ? null : applicableOn,
            abstract: cAbstract,
            parents: parentsIds?.length ? parentsIds : null,
            openInModule: openInModule || null,
            id,
            name,
            uri,
            hidden,
            active,
            color,
            icon,
            iconType,
            description,
            primary,
            entityTabs,
            entityAppearance,
            entitySvg: entitySvg || null,
            entityGraphic: entityGraphic?.id || null,
            entitySvgVisibleZoom: entitySvgVisibleZoom || 4,
            entitySvgTransformation: svgRotation,
            entityModelHeading: parseFloat(entityModelHeading),
            entityModelScaling
        });

        if (updatedData?.uri) {
            const { classification, updateClassification, entityType }= this.props;
            const { reportingEnabled }= classification;
            const updatedProperties = JSON.parse(JSON.stringify(classificationForm));
            const modifiedFields = modifyFieldDefinitionsURI(updatedData?.uri, updatedProperties?.formDefinition?.fields);
            const response = await updateClassification({ ...updatedData, formDefinition: { fields: modifiedFields } }, entityType);
            if (!(response instanceof Error) && !reportingEnabled && entityType) {
                showToastr({ severity: 'warning', detail: 'If the entity type was previously published for reports, the table on the report DB needs to be recreated with the correct "Class URI".' });
            }
        } else {
            this.props.updateClassification(updatedData, entityType);
        }
    }

    @bind
    async onDisableSubmit() {
        const { active, id } = this.state.classificationForm;
        this.props.updateClassification({ id, active: !active }, this.props.entityType);
    }

    @bind
    copyLinkToClipBoard() {
        const { showToastr, classification } = this.props;
        const { id, primary } = classification;

        copyToClipboard(`${window.location.origin}/#/${primary ? 'entity-types' : 'classifications'}/${id}/about`)
            .then(() => {
                showToastr({ severity: 'success', detail: 'Link copied to clipboard' });
            })
            .catch(() => {
                showToastr({ severity: 'error', detail: 'Link could not copied to clipboard' });
            });
    }

    @bind
    copyIDToClipBoard() {
        const { showToastr, classification } = this.props;
        copyToClipboard(classification.id)
            .then(() => {
                showToastr({ severity: 'success', detail: 'ID copied to clipboard' });
            })
            .catch(() => {
                showToastr({ severity: 'error', detail: 'ID could not copied to clipboard' });
            });
    }

    @bind
    onDotMenuClick(title) {
        if(title === 'Rename') {
            this.setState({ renameActive: true });
            return;
        }
        if(title === 'Disable' || title === 'Enable') {
            this.onDisableSubmit();
            return;
        }
        if(title === 'Copy link') {
            this.copyLinkToClipBoard();
            return;
        }
        if(title === 'Copy ID') {
            this.copyIDToClipBoard();
            return;
        }
        if(title ==='Enable reporting' || title ==='Disable reporting' ){
            this.setState({ openReportingModal: true });
            return;
        }

        this.props.openSidebar(title);
    }

    @bind
    @memoize()
    dotMenu(details, canEdit) {
        // TODO:  uncomment the below line and enable reporting menu when Enable reporting feature is completed
        // const {entityType}= this.props; // eslint-disable-line
        return (
            <DotMenu
                key={13}
                onItemClick={this.onDotMenuClick}
                items={[
                    { name: 'Copy link', icon: 'link' },
                    {name: 'Copy ID', icon: 'content-copy'},
                    { name: 'Sharing', icon: 'account-plus' },
                    { name: 'divider' },
                    canEdit && { name: 'Rename', icon: 'pencil' },
                    canEdit && { name: details.active ? 'Disable' : 'Enable', icon: details.active ? 'close' : 'check' },
                    // canEdit && { name: 'divider' }, // eslint-disable-line
                    // entityType && {name: details.reportingEnabled ? 'Disable reporting' : 'Enable reporting' , icon:  'chart-line' } // eslint-disable-line

                ].filter(Boolean)}
            />
        );
    }
    @bind
    onSave(event){
        const {entityType, classification}= this.props;
        if(classification?.uri !== this.state?.classificationForm?.uri && !entityType) {
            return this.toggleUriChangeWarningModal();
        }
        this.onFormSubmit(event);
    }
    @bind
    toggleUriChangeWarningModal(){
        this.setState(prevProps=>({openUriChangeWarningModal:!prevProps.openUriChangeWarningModal}));
    }

    @bind
    handleUriChangeConfirmation(){
        this.toggleUriChangeWarningModal();
        this.onFormSubmit();
    }

    @bind
    renderSaveButton(errors) {
        const { canEdit, isLoading } = this.props;
        const { isApplied } = this.state;
        if (!canEdit) {
            return null;
        }
        return isLoading ? (
            <CircularProgress key={113} size={24} color="primary" />
        ) : (
            <Button key={113} disabled={isApplied || !!errors} onClick={this.onSave} color="primary" form="form" type="submit">
                Save
            </Button>
        );
    }

    @bind
    renderSvgLeftHeader() {
        return (
            <>
                <Button key={113} onClick={this.toggleSvgMap} color="primary" variant="text">
                    X
                </Button>
                <label>Scale Svg</label>
            </>
        );
    }

    @bind
    toggleSvgMap() {
        this.setState(prevState => ({ isSvgMap: !prevState.isSvgMap }));
    }

    @bind
    changeApplied(isApplied) {
        this.setState({ isApplied });
    }

    @bind
    switchToSvgMap() {
        const details = safeToJS(this.state.classificationForm);
        return <EntitySvgSectionMap
            zoomLevel={details?.entitySvgVisibleZoom}
            handleChange={this.handleChange}
            details={details}
            changeApplied={this.changeApplied}
        />;
    }

    @bind
    setSvgImage(img) {
        this.setState({ classificationForm: set(this.state.classificationForm, 'entitySvg', img) });
        this.setState({ classificationForm: set(this.state.classificationForm, 'entityGraphic', img) });
    }


    @bind
    deleteFile(fileDetails) {
        const { classificationForm } = this.state;
        const { id, uri } = classificationForm || {};
        if(fileDetails?.fileType === 'svgFile') {
            const updatedData = {
                id,
                entitySvg: null,
                entityGraphic: null,
    
            };
            this.props.updateClassification(updatedData, this.props.entityType);
        }
        else {
            const data = { id, type: uri, isClass: true } ;
            this.props.deleteEntity3dModel(data);
        }
    }

    @bind
    downloadFileAction(fileDetails) {
        const { classificationForm } = this.state;
        const { id, name, entitySvg, entityGraphic } = classificationForm || {};
        const host = rocketHost.split('/chat')[0];
        let url = '';
        if(fileDetails.fileType === 'is3d') { 
            url =  `/media/class/${id}/entityModel3d`;  
            const fileURL = `https://${host}${url}`;
            // const fileURL = `http://localhost/:3000${url}`;
            window.open(fileURL, '_blank');          
        } else if(fileDetails?.fileType === 'svgFile') {
            const imageSrc = getSvgSource({ svg: entitySvg, svgGraphic: entityGraphic });
            if(imageSrc?.startsWith('/')) {
                const fileURL = `https://${host}` +  imageSrc.split('?')[0];
                // const fileURL = `http://localhost:3000` +  imageSrc.split('?')[0];
                fetch(fileURL)
                    .then(response => response.blob())
                    .then(imageBlob => {
                        clickFile(imageBlob, `${name}.svg`);
                    });
                
            } else {
                const blob = imgToBlob(imageSrc);
                blob && clickFile(blob, `${name}.svg`);
            }
        }
    }
    
    @bind
    async handleConfirmRename() {
        const result = await this.renameRef.current.isValidForm();
        if(!result.errors) {
            this.setState(({ classificationForm }) => ({
                classificationForm: { ...classificationForm, name: result.data.name.trim() },
                renameActive: false
            }), this.onFormSubmit);
        }
    }

    @bind
    handleCloseRename() {
        this.setState({ renameActive: false });
    }

    @bind
    closeEnableReporting(){
        this.setState({openReportingModal: false});
    }
    @bind
    async confirmEnableReporting(){
        const { updateClassificationReport, classification } = this.props;
        const {uri, reportingEnabled} = classification;
        const data={
            entityType: uri,
            enabled: !reportingEnabled       
        };

        await updateClassificationReport(data); 
        this.setState({ openReportingModal: false });            
    }
        
    

    render() {
        const { openSidebar, canEdit, entityType, classification, isSidebar } = this.props;
        const { renameActive, isSvgMap, classificationForm: details, errors, openReportingModal, openUriChangeWarningModal } = this.state;
        const { reportingEnabled } = classification;
        const disabled = !canEdit;
        if (!details) {
            return null;
        }
        return (
            <Fragment>
                <HeaderBar
                    left={!isSvgMap ? this.props.breadcrumbLine : this.renderSvgLeftHeader()}

                    right={!isSvgMap ? [this.renderSaveButton(errors), ...(this.props.sidebarActions || [])] :  [this.renderSaveButton(errors)]}
                />
                <ContentArea withHeader isSidebar={isSidebar}>
                    {isSvgMap ? (
                        <>{this.switchToSvgMap()} </>
                    ) : (
                        <Container width="1024">
                            <MainSection details={details} actions={this.dotMenu(details, canEdit)} entityType={entityType} />
                            <AboutSection disabled={disabled}  openSidebar={openSidebar} type="class" details={details} handleChange={this.handleChange} expanded />
                            <ClassDetailSection
                                forwardRef={this.refForms.classDetailForm}
                                type="class"
                                details={details}
                                handleChange={this.handleChange}
                                openSidebar={openSidebar}
                                entityType={entityType}
                                disabled={disabled}
                            />
                            {details?.primary ? <EntityDetailSection disabled={disabled} type="class" details={details} handleChange={this.handleChange} entityType={entityType} /> : null}
                            {details?.primary ? <EntityTabsSection disabled={disabled} type="class" details={details} handleChange={this.handleChange} /> : null}
                            {details?.primary ? <EntityAppearanceSection disabled={disabled} type="class" details={details} handleChange={this.handleChange} /> : null}
                            {details?.primary ? (
                                <DigitalTwinConfigSection classification={classification} />
                            ) : null }
                            { details?.primary ? (
                                <EntityMapSection
                                    closeClassSidebar={this.props.closeClassSidebar}
                                    type="class"
                                    forwardRef={this.refForms.classMapRef}
                                    classificationForm={this.state.classificationForm}
                                    toggleSvgMap={this.toggleSvgMap}
                                    setSvgImage={this.setSvgImage}
                                    deleteFile={this.deleteFile}
                                    downloadFileAction={this.downloadFileAction}
                                    details={details}
                                    handleChange={this.handleChange}
                                    disabled={disabled}
                                />
                            ) : null }
                        </Container>
                    )}
                </ContentArea>
                <BaseModal
                    open={renameActive}
                    onClose={this.handleCloseRename}
                    onConfirm={this.handleConfirmRename}
                    header="Rename"
                    maxWidth='sm'
                    fullWidth
                    content={(
                        <FormGenerator
                            root={false}
                            data={details}
                            components={[{
                                type: 'text',
                                properties: {
                                    name: 'name',
                                    label: `${entityType ? 'Entity type':'Class'} name`
                                },
                                constraints: { required: true },
                            }]}
                            ref={this.renameRef}
                        />
                    )}
                    declineButtonText="Cancel"
                    confirmButtonText="Save"
                />
                <BaseModal
                    open={openReportingModal}
                    onClose={this.closeEnableReporting}
                    onConfirm={this.confirmEnableReporting}
                    header= {reportingEnabled ? 'Disable reporting':'Enable reporting' }
                    maxWidth='sm'
                    fullWidth
                    content={(
                        `Do you want to ${reportingEnabled ? 'disable' : 'enable'} reporting?`
                    )}
                    declineButtonText="Cancel"
                    confirmButtonText={reportingEnabled ? 'DISABLE' : 'ENABLE' }
                />
                <BaseModal
                    open={openUriChangeWarningModal}
                    onClose={this.toggleUriChangeWarningModal}
                    onConfirm={this.handleUriChangeConfirmation}
                    header="Warning"
                    maxWidth='sm'
                    fullWidth
                    content={(
                        'Changing the URI will lose the associated values for entities of this class.'
                    )}
                    declineButtonText="Cancel"
                    confirmButtonText="save changes"
                    confirmationButtonProps={{
                        variant:'text',
                        color: '#FF8A91'
                    }}
                />
            </Fragment>
        );
    }
}

export default connect(
    state => ({
        childrens: state.classifications.childrens.records
    }),
    {
        updateClassChildrens,
        loadClassChildrens,
        updateEntity3dModel,
        deleteEntity3dModel,
        showToastr,
        updateClassificationReport,
    }
)(AbstractClassificationPrimaryAbout);
