
import { gql } from '@apollo/client';

export default gql`
query loadBoardPrimaryClassQuery($filterBy: [JSON]) {
  result: classes(filterBy: $filterBy) {
    id
    name
    uri
    formDefinition
  }
}
`;
