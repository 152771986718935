/* @flow */

const designerList = (isAdmin, permissions) => {
    const permissionsSet = new Set(permissions);
    return [
        (isAdmin || permissionsSet.has('designer.process')) && {
            name: 'Process Designer',
            icon: 'process-builder',
            iconType: 'af',
            to: '/designer/processes',
        },
        (isAdmin || permissionsSet.has('designer.form')) && {
            name: 'Form Designer',
            icon: 'form-builder',
            iconType: 'af',
            to: '/designer/forms',
        },
        (isAdmin || permissionsSet.has('designer.script')) && {
            name: 'Scripts',
            icon: 'script-builder',
            iconType: 'af',
            to: '/designer/scripts',
        },
        (isAdmin || permissionsSet.has('designer.pipeline')) && {
            name: 'IoT Pipelines',
            icon: 'pipe',
            children: [
                (isAdmin || permissionsSet.has('designer.pipeline')) && {
                    name: 'Pipeline Designer',
                    to: '/designer/pipelines',
                },
                (isAdmin || permissionsSet.has('designer.pipeline')) && {
                    name: 'Connectors',
                    to: '/designer/connectors',
                },
                (isAdmin || permissionsSet.has('designer.pipeline')) && {
                    name: 'Smart Modules',
                    to: '/designer/smart-modules',
                },
            ].filter(Boolean),
        },
    ].filter(Boolean);
};

export default designerList;
