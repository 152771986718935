/* @flow */
import { combineReducers } from 'redux';

import { CREATE_GRAPHIC_STARTED, CREATE_GRAPHIC, LOAD_GRAPHIC_LIBRARY_STARTED, LOAD_GRAPHIC_LIBRARY, LOAD_GRAPHIC_DETAILS_STARTED, LOAD_GRAPHIC_DETAILS } from 'store/actions/common/graphicLibraryActions';
import { dataTableReducer, loadDataReducer, keepDataAtStart } from 'app/utils/redux/reducer-utils';

export default combineReducers<Object, Object>({
    list: dataTableReducer(LOAD_GRAPHIC_LIBRARY_STARTED, LOAD_GRAPHIC_LIBRARY, keepDataAtStart),
    create: loadDataReducer(CREATE_GRAPHIC_STARTED, CREATE_GRAPHIC),
    details: loadDataReducer(LOAD_GRAPHIC_DETAILS_STARTED, LOAD_GRAPHIC_DETAILS)
});
