// @flow

export const baseRoutes = {
    class: '/classifications',
    custom: '/custom-entities',
    organisation: '/organisations',
    person: '/people',
    process: '/abox/process',
    relationdefinition: '/relation-definitions',
    formdefinition: '/designer/forms',
    processdefinition: '/designer/processes',
    script: '/designer/scripts',
    iot_pipeline: '/designer/pipelines',
    'print-template': '/print-templates',
    task: '/tasks',
    team: '/teams',
    thing: '/things',
    user: '/user-management',
    workspace: '/workspaces',
    organisationSettings: '/organisation-settings',
    license: '/licence',
    terms: '/terms',
    broadcastCalendar: '/broadcasts/calendar',
    eventtype: '/events/event-types',
    map: '/maps'
};