import React, { PureComponent } from 'react';
import { ThemeProvider as MuiThemeProvider  } from '@material-ui/core/styles';
import { muiTheme } from 'app/themes/materialUi';
import styled, { ThemeProvider } from 'styled-components';
import theme from 'app/themes/theme.default';

const AnnotationStyled = styled('div')`
    background-color: ${({ backgroundColor }) => backgroundColor || 'black'};
    color: ${({ color }) => color || 'white'};
    font-size: ${({ size }) => size || 16}px;
    padding: 5px 10px;
    min-width: 200px;
    max-width: 250px;
    text-align: center;
`;

React.useLayoutEffect = React.useEffect; 

export default class PipelineWidgetAnnotation extends PureComponent {
    render() {
        const { node } = this.props; 
        const { title, size, color, backgroundColor } = node; 
        return (
            <MuiThemeProvider theme={muiTheme}>
                <ThemeProvider theme={theme}>
                    <AnnotationStyled size={size} color={color} backgroundColor={backgroundColor}>
                        {title}  
                    </AnnotationStyled>
                </ThemeProvider>
            </MuiThemeProvider>
        );
    }
}
