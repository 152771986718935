/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { TextField } from '@mic3/platform-ui';

import VirtualListManaged from 'app/components/molecules/VirtualList/VirtualListManaged';
import { loadClassificationEntities } from 'store/actions/classifications/classificationsActions';
import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { isMobile } from 'react-device-detect';
import EntitiesListItem from 'app/containers/Entities/EntitiesList/EntitiesListItem';
import { isValiduuid, canBeuuid } from 'app/utils/string/string-utils';
import { buildDotMenu } from 'app/utils/entity/entityUtils';
import { loadPrimaryClasses } from 'store/actions/entities/entitiesActions';
import { filterRecordsOnFE } from 'app/utils/filter/filterUtils';
import { setOnBack } from 'store/actions/sidebar/sidebarActions';

const Search = styled(TextField)`
    margin-bottom: 12px !important;
    .MuiFilledInput-input {
        padding: 10px 12px !important;
    }
`;

const ClassificationsList = styled.div`
    div[class*='Container__ContainerStyle'] {
        background-color: transparent;
        margin-bottom: 0;
    }
    div[class*='VirtualList__TinyVirtual'] {
        padding-top: 0 !important;
        margin-top: 0.2em;
    }
    height: ${isMobile ? '95vh' : '85vh'} !important;
    padding-bottom: 36px;
`;

const inputProps: Object = { disableUnderline: true };


class ClassEntitiesList extends PureComponent<Object, Object> {
    static propTypes = {
        loadClassificationEntities: PropTypes.func.isRequired,
        isLoading: PropTypes.bool,
        records: PropTypes.array,
        totalRecords: PropTypes.number,
        startIndex: PropTypes.number
    };

    virtualListRef = React.createRef();

    constructor(props: Object) {
        super(props);
        this.state = {
            search: '',
            filterBy: [],
            orderBy: [],
            details: props.details || {},
            listFilters: []
        };
        const { primary } = props.details || {};
        if (!primary) {
            props.loadPrimaryClasses();
        }
    }

    @bind
    onChangeSearch(e: Object) {
        if (e.persist) {
            e.persist();
        }
        this.setState({ search: e.target.value }, () => {
            const { search } = this.state;
            const value = search || '';
            const searchFilter = [];
            if (isValiduuid(search)) {
                searchFilter.push({ field: 'id', op: '=', value });
            } else if (canBeuuid(search)) {
                searchFilter.push({ field: 'id', op: 'startsWith', value, cast: 'text' });
            } else if (search) {
                searchFilter.push({ field: 'name', op: 'contains', value });
            }
            this.setState(prevState => ({ filterBy: [...searchFilter, ...prevState.listFilters] }));
        });
    }

    @bind
    onSelect(details) {
        const listFilters = [{ field: 'classes', op: 'contains', value: [this.props.details?.id] }];
        this.setState({ details, listFilters, search: '', filterBy: listFilters }, () => {
            this.updateList();
            this.props.setOnBack(() => {
                this.setState({ details: this.props.details, listFilters: [], filterBy: [] }, () => this.props.setOnBack(null));
            });
            this.loadData({ filterBy: listFilters });
        });
    }

    @bind
    renderComponent(props: Object) {
        const { uri, primary } = this.state.details || {};
        return (
            <EntitiesListItem
                key={props.index}
                {...props}
                type={primary ? uri : 'class'}
                dotMenu={primary && buildDotMenu({ details: props.data, entityType: uri, indexView: true })}
                onSelect={this.onSelect}
                listView={!primary}
                entitiesView={!!primary}
                entityType={!!props?.data?.primary}
            />
        );
    }

    @bind
    updateList() {
        if (this.virtualListRef && this.virtualListRef.current) {
            this.virtualListRef.current.forceUpdate();
        }
    }

    @bind
    loadData(options) {
        const { details } = this.state;
        if (!details?.primary) {
            return Promise.resolve();
        }
        return this.props.loadClassificationEntities(details?.uri, options);
    }

    @bind
    @memoize()
    filterEntityTypes(entityTypes, filterBy) {
        const { details } = this.state;
        const { applicableOn } = details || {};
        const filteredTypes = entityTypes?.filter(rec => (applicableOn || []).includes(rec.uri)) || [];
        return filterRecordsOnFE(filteredTypes, filterBy);
    }

    /**
     * @override
     */
    render() {
        const { isLoading, totalRecords: total, startIndex, entityTypes } = this.props;
        const { filterBy, orderBy, search, details } = this.state;
        const { primary } = details || {};
        const records = primary ? this.props.records : this.filterEntityTypes(entityTypes, filterBy);
        const totalRecords = primary ? total : records?.length;
        const sIndex = primary && startIndex ? startIndex : 0; 
        return (
            <ContentArea>
                <Search onChange={this.onChangeSearch} value={search} fullWidth margin="none" placeholder="Search..." InputProps={inputProps} />
                <ClassificationsList>
                    <VirtualListManaged
                        ref={this.virtualListRef}
                        renderComponent={this.renderComponent}
                        itemSize={84}
                        title={`${totalRecords >= 1000 ? '999+' : totalRecords} ${primary ? 'Entities' : 'Entity types'}`}
                        itemCount={totalRecords || 0}
                        loadData={this.loadData}
                        isLoading={isLoading}
                        startIndex={sIndex}
                        filterBy={filterBy}
                        orderBy={orderBy}
                        list={records}
                        maxWidth="320"
                    />
                </ClassificationsList>
            </ContentArea>
        );
    }
}

export default connect(
    state => ({
        isLoading: state.classifications.entities.isLoading || state.entities.primaryClasses.isLoading,
        records: state.classifications.entities.records || [],
        totalRecords: state.classifications.entities.count,
        startIndex: state.classifications.entities.startIndex,
        entityTypes: state.app.allPrimaryClasses.records || []
    }),
    {
        loadClassificationEntities,
        loadPrimaryClasses,
        setOnBack
    }
)(ClassEntitiesList);
