/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@mic3/platform-ui';
import styled from 'styled-components';
import uuidv1 from 'uuid/v1';

import PageTemplate from 'app/components/templates/PageTemplate';
import { loadPrintTemplatesListView, loadReportsTemplatesListView } from 'store/actions/entities/printTemplateActions';
import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import EntitiesList from 'app/containers/Entities/EntitiesList/EntitiesList';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { typeTitlesMultiple } from 'app/config/typesConfig';
import withPermissions from 'app/utils/withPermissions';
import AddPrintTemplate from 'app/containers/Entities/PrintTemplates/AddPrintTemplate';
import Icon from 'app/components/atoms/Icon/Icon';
import history from 'store/History';
import Alert from 'app/components/molecules/Alert/Alert';
import { commonEntitiesListFilters } from 'app/utils/entity/entityUtils';
import { modulesAndPageTitles } from 'app/config/typesConfig';
import { setDocumentTitle } from 'store/actions/app/appActions';

const listType = 'print-template';

const ContentAreaStyled = styled(ContentArea)`
    ${({ noRecords }) => (noRecords ? 'display: flex;' : '')}
    ${({ noRecords }) => (noRecords ? 'align-items: center;' : '')}
    ${({ noRecords }) => (noRecords ? 'justify-content: center;' : '')}
`;

const NoRecordsTemplate = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 60px;
    font-weight: 200;
    color: rgba(255, 255, 255, 0.38);
    @media (max-width:481px){
        font-size: 24px;
    }
`;

const StyledButton = styled(Button)`
    padding: 12px !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.75px;
    text-transform: uppercase;
`;

const PageTemplateWrapper = styled('div')`
    height: 100%;
    & .page-content {
        height: 100%;
    }
`;

const IconStyled = styled(Icon)`
    margin-right: 30px;
    @media (max-width:481px){
        margin-right: 12px;
    }
    &:before {
        font-size: 85px !important;
        color: rgba(255, 255, 255, 0.24);
        @media (max-width:481px){
            font-size: 48px !important;
        }
    }
`;

class PrintTemplatesListView extends PureComponent<Object, Object> {
    static propTypes = {
        loadPrintTemplatesListView: PropTypes.func.isRequired,
        records: PropTypes.array,
        isLoading: PropTypes.bool,
        totalRecords: PropTypes.number
    };

    static defaultProps = {
        isLoading: false,
        VirtualListProps: {},
        FiltersProps: {}
    };

    constructor(props){
        super(props);
        this.firstLoading = true;
        this.prevTotal = 0;
        this.state = {
            isAddModalOpen: false,
            listKey: uuidv1()
        };
    }

    searchBar = ['id', 'description', 'name'];
    defaultOrder = [{ field: 'createdDate', direction: 'desc nulls last' }];

    filterDefinitions = [
        {
            field: 'name',
            type: 'text',
            properties: {
                label: 'Name',
                name: 'name',
                opSelector: true
            }
        },
        {
            field: 'description',
            type: 'text',
            properties: {
                label: 'Description',
                name: 'description',
                opSelector: true
            }
        },
        {
            field: 'id',
            type: 'uuid',
            properties: {
                label: 'ID',
                name: 'id',
                opSelector: true
            },
            condition: '='
        },
        ...commonEntitiesListFilters
    ];

    columnDefinitions = [
        {
            text: 'Template Name',
            field: 'name'
        },
        {
            text: 'Description',
            field: 'description'
        },
        {
            text: 'Template ID',
            field: 'id',
            onClickDisabled: true
        },
        {
            text: 'Last Updated',
            field: 'modifiedDate'
        },
        {
            text: 'Modified By',
            field: 'modifiedBy'
        },
        {
            text: 'Created Date',
            field: 'createdDate'
        },
        {
            text: 'Created by',
            field: 'createdBy'
        }
    ];

    componentDidUpdate(prevProps) {
        const { totalRecords, isPrintReports } = this.props;
        if(prevProps.totalRecords !== totalRecords) {
            this.prevTotal = prevProps.totalRecords;
        }
        if (prevProps.isPrintReports !== isPrintReports) {
            this.setState({ listKey: uuidv1() });
        }
    }

    componentDidMount(){
        const { isPrintReports, setDocumentTitle } = this.props;
        const printTemplates = modulesAndPageTitles.entities.printTemplates;
        const reports = modulesAndPageTitles.analytics.reports;
        this.firstLoading = false;
        setDocumentTitle(isPrintReports ? reports : printTemplates);
    }

    @bind
    toggleAddModal() {
        this.setState(state => ({ isAddModalOpen: !state.isAddModalOpen }));
    }

    @bind
    gotToPrintTemplates() {
        history.push('/print-templates');
    }

    @bind
    loadData(data) {
        const { loadPrintTemplatesListView, loadReportsTemplatesListView, isPrintReports } = this.props;
        const nextData = {...data, options: {
            ...(data?.options ?? {}),
            filterBy: [
                ...(data?.options?.filterBy ?? []),
                isPrintReports && {
                    'field': 'primary.print-template/script',
                    'op': 'is not null'
                }
            ].filter(Boolean)
        }};
        return (isPrintReports ? loadReportsTemplatesListView : loadPrintTemplatesListView)(nextData).then(result => {
            this.firstLoading = false;
            return result;
        });
    }

    @bind
    @memoize()
    renderAddModal(isAddModalOpen: boolean) {
        if (!isAddModalOpen) {
            return null;
        }

        return <AddPrintTemplate onClose={this.toggleAddModal} />;
    }

    @bind
    renderNoResultComponent(filterBy: Object) {
        const { totalRecords, isPrintReports } = this.props;
        const { isAddModalOpen } = this.state;
        const { permissions } = this.props.userProfile;
        const permissionsSet = new Set(permissions || []);
        const headerTitle = isPrintReports ? 'Reports' : typeTitlesMultiple[listType];
        if (filterBy && filterBy.length > 0 || this.prevTotal > 0) {
            return (
                <Alert type='background' margin={16}>
                    No results
                </Alert>
            );
        }
        return (
            <PageTemplateWrapper title={headerTitle}>
                <PageTemplate title={headerTitle}>
                    <ContentAreaStyled noRecords={totalRecords === 0}>
                        <div style={{textAlign: 'center'}}>
                            <NoRecordsTemplate>
                                <IconStyled name={isPrintReports ? 'printer': 'plus'} type='mdi' />
                                {isPrintReports ? ' No reports available' : 'Let\'s get started'} 
                            </NoRecordsTemplate>
                            {!isPrintReports && permissionsSet.has('entity.entity.add') && <StyledButton variant='text' color='secondary' onClick={this.toggleAddModal}>
                                Create New
                            </StyledButton>}
                            {isPrintReports && (
                                <>
                                    <span>Go to Report Templates to create new reports, or get access to reports from an administrator.</span>
                                    <br />
                                    <StyledButton variant='text' color='secondary' onClick={this.gotToPrintTemplates}>
                                    report templates
                                    </StyledButton>
                                </>
                            )}
                        </div>
                    </ContentAreaStyled>
                    {this.renderAddModal(isAddModalOpen)}
                </PageTemplate>
            </PageTemplateWrapper>
        );
    }


    render() {
        const { startIndex, isLoading, records, totalRecords, isPrintReports, viewId } = this.props;
        const { isAddModalOpen } = this.state;
        const headerTitle = isPrintReports ? 'Reports' : typeTitlesMultiple[listType];
        return (
            <PageTemplate title={headerTitle}>
                <ContentAreaStyled noRecords={totalRecords === 0}>
                    <EntitiesList
                        key={this.state.listKey}
                        isPrintReports={isPrintReports}
                        id={viewId}
                        type={listType}
                        headerTitle={headerTitle}
                        records={records}
                        totalRecords={totalRecords}
                        startIndex={startIndex}
                        isLoading={isLoading}
                        loadData={this.loadData}
                        filterDefinitions={this.filterDefinitions}
                        defaultOrder={this.defaultOrder}
                        searchBar={this.searchBar}
                        columnDefinitions={this.columnDefinitions}
                        VirtualListCardsProps={{
                            renderNoResultComponent: this.renderNoResultComponent,
                        }}
                        VirtualListProps={{
                            renderNoResultComponent: this.renderNoResultComponent,
                        }}
                        
                    />
                </ContentAreaStyled>
                {this.renderAddModal(isAddModalOpen)}
            </PageTemplate>
        );
    }
}

export default connect(
    (state: Object, props: Object) => props.isPrintReports ? ({
        records: state.entities.printTemplates.listReports.records,
        totalRecords: state.entities.printTemplates.listReports.count,
        isLoading: state.entities.printTemplates.listReports.isLoading,
        startIndex: state.entities.printTemplates.listReports.startIndex,
        userProfile: state.user.profile,
        viewId: 'PrintReportsListView',

    }) : ({
        records: state.entities.printTemplates.list.records,
        totalRecords: state.entities.printTemplates.list.count,
        isLoading: state.entities.printTemplates.list.isLoading,
        startIndex: state.entities.printTemplates.list.startIndex,
        userProfile: state.user.profile,
        viewId: 'PrintTemplatesListView',
    }),
    { loadPrintTemplatesListView, loadReportsTemplatesListView, setDocumentTitle }
)(withPermissions(PrintTemplatesListView, listType));