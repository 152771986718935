/* @flow */

import { gql } from '@apollo/client';

export default gql`
query loadTaskTypeaheadQuery($orderBy: [JSON], $filterBy: [JSON], $startIndex: Int, $stopIndex: Int, $excludeBy: [JSON]) {
  result: tasks(orderBy: $orderBy, filterBy: $filterBy, startIndex: $startIndex, stopIndex: $stopIndex, excludeBy: $excludeBy) {
    id
    name
    type
    primary(fields: ["closedDate", "progress", "priority"])
    assignee {
      id
      name
      image
    }
    __typename
  }
}
`;
