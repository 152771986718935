/* @flow */
const profileMenu = (isAdmin, permissions) => {
    return [
        {
            name: 'About Me',
            icon: 'card-account-details',
            to:'/about-me'
        },
        {
            name: 'Go To Account Security',
            icon: 'shield-half-full',
            onClick: () => {
                window.open('/iam/auth/realms/Affectli/account/#/security/signingin', '_blank');
            }
        },
        {
            name: 'User Preferences',
            icon: 'cog',
            to:'/user-preferences'
        },
        // canSeeProfile && { // Hiding faeture of offline
        //     name: 'Api Keys',
        //     icon: 'lock',
        //     to: '/api-key'
        // }
    ].filter(Boolean);
};

export default profileMenu;
