import React, { useState } from 'react';
import { MdiIcon, IconButton } from '@mic3/platform-ui';
import styled from 'styled-components';

const CarouselWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
`;


const DrawingCarousal = ({ features }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const nextImage = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % features.length);
    };

    const prevImage = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? features.length - 1 : prevIndex - 1
        );
    };

    return (
        <CarouselWrapper>
            <div>
                <img
                    src={features[currentIndex]?.values_?.image || 
                        features?.[currentIndex]?.properties?.attributes?.image ||
                        features?.[currentIndex]?.properties?.image || ''}
                    alt='Drawing thumbnail'
                    style={{ width: '312px', height: '164px' }}
                />
            </div>
            <div>
                <IconButton onClick={prevImage}>
                    <MdiIcon name='menu-left' />
                </IconButton>
                <span>{`${currentIndex + 1} of ${features.length}`}</span>
                <IconButton onClick={nextImage}>
                    <MdiIcon name='menu-right' />
                </IconButton>
            </div>
        </CarouselWrapper>
    );
};

export default DrawingCarousal;
