import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { MenuItem, Grow } from '@mic3/platform-ui';
import { TimelineToolbarSelect, TimelineToolbarSection } from './style';
import { BryntumButton } from '@bryntum/schedulerpro-react';
import { DateHelper } from '@bryntum/schedulerpro';

const DURATION = 700;
const ADD_FACTOR = 300;

const TimelineToolbar = (props) => {
    const { 
        range, forwardRef, onAction, onPrevious, 
        onNext, onSetRange, onChangeRange, checked
    } = props;
    return (
        <Fragment>
            <Grow in={checked} appear mountOnEnter unmountOnExit timeout={DURATION}>
                <TimelineToolbarSection>
                    <Grow in timeout={DURATION + ADD_FACTOR}>
                        <TimelineToolbarSelect
                            onChange={onChangeRange}
                            disableUnderline={true}
                            value={range}
                            inputProps={{
                                name: 'range',
                                id: 'range'
                            }}
                        >
                            <MenuItem value={'days'}>Day</MenuItem>
                            <MenuItem value={'weeks'}>Week</MenuItem>
                            <MenuItem value={'months'}>Month</MenuItem>
                            <MenuItem value={'years'}>Year</MenuItem>
                        </TimelineToolbarSelect>
                    </Grow>
                    <Grow in timeout={DURATION + ADD_FACTOR * 2}>
                        <BryntumButton
                            icon="mdi-chevron-left mdi"
                            tooltip="Previous"
                            onClick={() => onPrevious() }
                        />
                    </Grow>
                    <Grow in timeout={DURATION + ADD_FACTOR * 3}>
                        <BryntumButton
                            icon="mdi-chevron-right mdi"
                            tooltip="Next"
                            onClick={() => onNext() }
                        />
                    </Grow>
                    <Grow in timeout={DURATION + ADD_FACTOR * 4}>
                        <BryntumButton
                            dataset={{ action: 'zoomOut' }}
                            icon="mdi mdi-minus-circle"
                            tooltip="Zoom out"
                            onAction={onAction}
                        />
                    </Grow>
                    <Grow in timeout={DURATION + ADD_FACTOR * 5}>
                        <BryntumButton
                            dataset={{ action: 'zoomIn' }}
                            icon="mdi mdi-plus-circle"
                            tooltip="Zoom in"
                            onAction={onAction}
                        />
                    </Grow>
                    <Grow in timeout={DURATION + ADD_FACTOR * 6}>
                        <BryntumButton
                            icon="mdi-contrast-circle mdi"
                            tooltip="Reset Zoom"
                            onClick={() => {
                                switch (range){
                                    case 'start':
                                    case 'years':
                                    case 'weeks':
                                        forwardRef.current.instance.zoomTo('weekAndMonth');
                                        break;
                                    case 'months':
                                        forwardRef.current.instance.zoomTo('monthAndYear');
                                        break;
                                    default:
                                        forwardRef.current.instance.zoomTo('hourAndDay');
                                        break;
                                }
                            }}
                        />
                    </Grow>
                    <Grow in timeout={DURATION + ADD_FACTOR * 7}>
                        <BryntumButton
                            icon="mdi-calendar-range mdi"
                            tooltip="Today"
                            onClick={() => {
                                onSetRange('days');
                                const today = DateHelper.clearTime(new Date());
                                today.setHours(5);
                                forwardRef.current.instance.setTimeSpan(new Date(), DateHelper.add(today, 18, 'hour'));
                            }}
                        />
                    </Grow>
                </TimelineToolbarSection>
            </Grow>
        </Fragment>
    );
};

TimelineToolbar.propTypes = {
    range: PropTypes.string,
    totalRecords: PropTypes.number
};

TimelineToolbar.defaultProps = {
    range: 'weeks',
    totalRecords: 0
};

export default TimelineToolbar;
