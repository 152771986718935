/* @flow */

// $FlowFixMe
import React, { Fragment, useCallback, useState, useEffect, useMemo, useRef } from 'react';
import { Button, Grid, CircularProgress } from '@mic3/platform-ui';
import styled from 'styled-components';
import uuidv1 from 'uuid/v1';

import { removeSpecialCharacters } from 'app/utils/string/string-utils';
import { isEmpty } from 'app/utils/utils';

import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import AttachmentIcon from 'app/containers/Common/Attachments/AttachmentIcon';

const ModalFooter = styled.footer`
    display: flex;
    justify-content: space-between;
    align-items: center;
   padding: 1rem 0 .5rem 0;
`;

const Image = styled.img`
    max-width: 300px;
    margin: 0 auto;
    display: block;
`;

const StyledGrid = styled(Grid)`
    padding: 0 16px;
`;

const changeFileName = (file) => {
    const blob = file.slice(0, file.size, file.type);
    let name = file.name.match(/[a-zA-Z0-9.]+/g) || [];
    if(name.length === 1) {
        name = [uuidv1(), ...name];
    }
    return new File([blob], name.join(''), { type: file.type });
};

const FileForm = ({
    isLoading, close, file, isPaste, withoutMessage,
    uploadFile, uploadLabel = 'Attach file',
    alternativeUpload, alternativeUploadLabel = 'Upload',
    fileReference, fileIndex, formFields
}: Object) => {
    const formRef: Object = useRef(null);
    const [imgSrc, setImgSrc] = useState(null);
    const defaultValue = isEmpty(formFields)
        ? !withoutMessage
            ? { message: removeSpecialCharacters(file.name), description: '' }
            : { description: '' }
        : { filename: removeSpecialCharacters(file.name), description: '' };
    const [value, setValue] = useState(defaultValue);

    const onUpload = useCallback((event) => {
        event.preventDefault();
        formRef.current && formRef.current.isValidForm().then(({data, errors}) => {
            if (!errors) {
                uploadFile({ ...data, file: changeFileName(file), reference: fileReference, index: fileIndex });
            }
        });
    }, [file, fileIndex, fileReference, uploadFile]);

    const onAlternativeUpload = useCallback((event) => {
        event.preventDefault();
        formRef.current && formRef.current.isValidForm().then(({data, errors}) => {
            if (!errors) {
                alternativeUpload({ ...data, file: changeFileName(file), reference: fileReference, index: fileIndex });
            }
        });
    }, [alternativeUpload, file, fileIndex, fileReference]);

    useEffect(() => {
        if(file.type.startsWith('image/')) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImgSrc(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            setImgSrc(null);
        }
    }, [file]);

    useEffect(() => {
        setValue({ filename: removeSpecialCharacters(file.name), description: '' });
    }, [file]);
    
    const formDefinition = useMemo(
        () => (
            !isEmpty(formFields) ? formFields : [
                !withoutMessage && {
                    field: 'message',
                    type: 'text',
                    properties: {
                        name: 'message',
                        label: 'Message'
                    },
                    constraints: { required: true }
                },
                {
                    field: 'description',
                    type: 'text',
                    properties: {
                        name: 'description',
                        label: 'Description'
                    }
                }
            ].filter(Boolean)
        ), [withoutMessage, formFields]
    );

    return (
        <Fragment>
            <StyledGrid container justify="center">
                {imgSrc ? <Image alt={file.name} src={imgSrc} /> : <AttachmentIcon file={file} />}
            </StyledGrid>
            <FormGenerator
                components={formDefinition}
                ref={formRef}
                data={value}
                onChange={data => setValue(data)}
            />
            <ModalFooter>
                <Button variant="text" onClick={close}>Cancel</Button>
                <div>
                    {uploadFile &&
                    <Button disabled={isLoading} onClick={onUpload} autoFocus>
                        {isLoading
                            ? <CircularProgress size={24} color="inherit" />
                            : uploadLabel
                        }
                    </Button>
                    }
                    {uploadFile && alternativeUpload && (
                        <span>&nbsp; &nbsp; &nbsp;</span>
                    )}
                    {alternativeUpload && (
                        <Button disabled={isLoading} onClick={onAlternativeUpload}>
                            {isLoading
                                ? <CircularProgress size={24} color="inherit" />
                                : alternativeUploadLabel
                            }
                        </Button>
                    )}
                </div>
            </ModalFooter>
        </Fragment>
    );
};

export default FileForm;
