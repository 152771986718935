import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { muiTheme } from 'app/themes/materialUi';
import PropTypes from 'prop-types';
import produce from 'immer';
import { Tooltip, Button, IconButton, ExpansionPanel, ExpansionPanelSummary, 
    ExpansionPanelDetails, Divider, Avatar } from '@mic3/platform-ui';

import ExpansionCard from 'app/components/molecules/ExpansionCard/ExpansionCard';
import VirtualList from 'app/components/molecules/VirtualList/VirtualList';
import RolesSelect from 'app/components/molecules/RolesSelect/RolesSelect';
import AddResourcesModal from 'app/containers/Common/AddResourcesModal';
import ListItem from 'app/components/molecules/List/ListItem';
import Alert from 'app/components/molecules/Alert/Alert';
import Icon from 'app/components/atoms/Icon/Icon';

import { getAttachmentUrl } from 'app/utils/attachments/attachmentsUtils';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { toUniqueUUID } from 'app/utils/string/string-utils';
import Immutable from 'app/utils/immutable/Immutable';
import { isEmpty, getStr } from 'app/utils/utils';
import { get, sortBy } from 'app/utils/lo/lo';
import { typeMap } from 'app/config/typesConfig';

import CheckBoxBlank from 'assets/images/icons/check_box_outline_blank.png';
import CheckBox from 'assets/images/icons/check_box.png';
import { CardDivider } from 'app/containers/Entities/EntitiesList/EntitiesListItem';
import TypeIcon from 'app/components/atoms/TypeIcon/TypeIcon';
import ResizableListItem from 'app/components/molecules/VirtualList/ResizableListItem';

const StyledExpansionCard = styled(ExpansionCard)`
    background: ${({theme})=> theme.material.colors.background.default} !important;
    box-shadow: none !important;
    transition: none !important;
`;

const ListItemStyled = styled(ListItem)`
    ${({ disabled }) => (!disabled ? 'cursor: pointer;' : '')}
    width: 100%;
    max-width: 1024px;
    box-shadow: none;
    background: ${({ selected, theme }) => (selected ? theme.material.colors.background.active : theme.material.colors.background.paper)};
    &:hover {
        background: ${({ selected, theme }) => (selected ? theme.material.colors.background.active : theme.material.colors.background.hover)};
    }
    & h3 {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
`;

const ListItemChildStyled = styled(ListItemStyled)`
    background: none !important;
`;

const StyledAvatar = styled(Avatar)`
    height: 40px;
    width: 40px;
    && {
        ${({ selected }) => selected ? `
            background-color: gray !important;
            color: transparent
        ` : `
            ${ListItemStyled}:hover & {
                color: transparent;
            }
        `};
    }
`;

const StyledAvatarOverlay = styled.div`
    display: none;
    width: 24px;
    height: 24px;
    position: absolute;
    z-index: 1;
    left: 16px;
    top: 18px;
    && {
        ${({ selected }) => selected ? `
            display: block;
            background-image: url(${CheckBox});
            background-repeat: no-repeat;
            background-position: center center;
        ` : `
            ${ListItemStyled}:hover & {
                display: block;
                background-image: url(${CheckBoxBlank});
                background-repeat: no-repeat;
                background-position: center center;
            }
        `};
    }
`;

const StyledAvatarChild = styled(Avatar)`
    height: 40px;
    width: 40px;
`;

const ButtonWrapper = styled.div`
    & button {
        display: flex;
        width: 100%;
        & i:before {
            font-size: 18px !important;
        }
    }
    margin: 12px 0;
`;

const ListStyled = styled.div`
    ${({ items, itemSize }) => `max-height: ${(items * itemSize) + 70}px !important;` }
    ${({ items, itemSize, maxHeight }) => {
    const height = (items * itemSize) + 70;
    return `height: ${height > maxHeight ? maxHeight : height}px !important;`;
}}
`;

const ExpansionListItem = styled(ExpansionPanel)`
    background-color: ${({ selected }) => (selected ? 'rgba(0, 81, 255, 0.2)' : 'rgba(255, 255, 255, 0.04)')} !important;
    width: 100%;
    border-radius: 0;
    && {
        background-color: transparent;
        box-shadow: none;

        &::before {
            display: none !important;
        }
    }
`;

const ExpansionSummary = styled(ExpansionPanelSummary)`
    padding: 0 !important;

    & .MuiExpansionPanelSummary-content {
        margin: 0 !important;
        width: 100%;

        & > div {
            background: transparent !important;
            margin-bottom: 0 !important;
        }

        & > div > div > div:nth-child(2) {
            padding-right: 48px;
        }
    }

    & .MuiExpansionPanelSummary-expandIcon {
        padding: 0 4px;
        margin-right: 15px;
        position: absolute;
        right: 46px;
    }
`;

const ExpansionPanelDetailsStyled = styled(ExpansionPanelDetails)`
    width: 100%;
    flex-direction: column;
    & .MuiDivider-root {
        margin-bottom: 12px;
    };
    ${({ itemCount, itemSize}) => {
    const height = itemSize * itemCount + 105;
    return `height: ${height}px;`;       
}}
`;

/**
 * Renders the view to display the classification.
 */
class SelectionList extends PureComponent<Object, Object> {
    static propTypes = {
        type: PropTypes.string.isRequired,
        typeChild: PropTypes.string,
        isLoading: PropTypes.bool,
        disabled: PropTypes.bool,
        disabledAdd: PropTypes.bool,
        list: PropTypes.array.isRequired,
        filteredList: PropTypes.array,
        onAdd: PropTypes.func.isRequired,
        onRemove: PropTypes.func.isRequired,
        listIcon: PropTypes.string,
        expansionDetailCaption: PropTypes.string
    };

    static defaultProps = {
        disabled: false,
        showRoles: false
    };

    state = Immutable({
        selectedListIds: [],
        isAddOpen: false,
    });

    defaultState = this.state;

    listRef = React.createRef();

    componentDidUpdate(prevProps) {
        if (prevProps.list !== this.props.list) {
            this.refreshList();
        }
    }

    @bind
    refreshList() {
        if(this.listRef.current) {
            this.listRef.current.refreshList();
        }
    }

    @bind
    setDefaultState() {
        this.setState(this.defaultState, this.refreshList);
    }

    @bind
    openAddModal(evnt) {
        evnt.stopPropagation();
        this.setState({ isAddOpen: true });
    }

    @bind
    closeAddModal() {
        this.setState({ isAddOpen: false });
    }

    @bind
    removeTeam(evnt: Object) {
        evnt.stopPropagation();
        const { selectedListIds } = this.state;
        if (isEmpty(selectedListIds)) {
            return;
        }
        return this.props.onRemove(selectedListIds).then(this.setDefaultState);
    }

    @bind
    removeOne(event: Object, userId) {
        event.preventDefault();
        if (!userId) {
            return;
        }
        return this.props.onRemove([userId]).then(() => {
            const selected = this.getSelected(userId);
            if (selected) {
                this.setState(prevState => ({
                    selectedListIds: prevState.selectedListIds.filter(id => id !== userId)
                }), this.refreshList);
            }
        });
    }

    @bind
    @memoize()
    getSubTitle(data: Object){
        const { type } = this.props;
        switch (type) {
            case 'class':
                return getStr(data, 'uri', '');
            case 'user':
                return `@${getStr(data, 'username', '')}`;
            default:
                return `#${toUniqueUUID(getStr(data, 'id', ''))}`;
        }
    }

    @bind
    getSelected(userId: string) {
        if (!userId) {
            return false;
        }
        const { selectedListIds } = this.state;
        return selectedListIds.includes(userId);
    }

    @bind
    onSelect(event: Object, userId: string) {
        event.preventDefault();
        if (!userId) {
            return;
        }
        const selected = this.getSelected(userId);
        if (!selected) {
            this.setState(
                produce((draft) => {
                    draft.selectedListIds.push(userId);
                }), this.refreshList
            );
        } else {
            this.setState(prevState => ({
                selectedListIds: prevState.selectedListIds.filter(id => id !== userId)
            }), this.refreshList);
        }
    }

    @bind
    onSubmit(list: Array<Object>) {
        const { valueField } = this.props;
        if (isEmpty(list)) {
            return;
        }
        return this.props.onAdd(valueField ? list.map(item => item[valueField]): list).then(this.setDefaultState);
    }

    @bind
    @memoize()
    renderListItemActions(data){
        const { disabled, showRoles } = this.props;
        return (
            <>
                {showRoles && <RolesSelect disabled defaulValue={data.role} />}
                <Tooltip title={`Remove`} arrow>
                    <IconButton disabled={disabled} size="small" onClick={e => !disabled && this.removeOne(e, get(data, 'id'))}>
                        <Icon type="mdi" name="close" size="md"/>
                    </IconButton>
                </Tooltip>
            </>
        );
    }

    @bind
    renderComponent({ style, index, data, resize, selected }) {
        const { disabled, type, expansionDetailCaption, noSubTitle } = this.props;
        const { id, name, image } = data;
        const hasChildren = get(data, 'children', []).length > 0;
        let avatar = null;

        if (type === 'user') {
            avatar = image && getAttachmentUrl(id, 'user', image);
        } else if (!['workspace', 'team'].includes(type)) {
            avatar = image;
        }

        if (hasChildren) {
            const itemSize = 80;
            const itemsCount = data?.children?.length || 0;
            const handleResize = (resizeRow) => {
                setTimeout(() => {
                    resizeRow();
                }, 200);
            };

            return (
                <ResizableListItem style={style} key={id} index={index} resize={resize} padding={3}>
                    {resizeRow => (
                        <ExpansionListItem
                            defaultExpanded={false}
                            TransitionProps={{ timeout: 0 }}
                            onChange={() => handleResize(resizeRow)}
                            selected={selected}
                            square
                        >
                            <ExpansionSummary
                                expandIcon={<Icon name="chevron-down" />}
                                IconButtonProps={{ edge: false }}
                            >
                                <ListItemStyled
                                    disabled={disabled}
                                    component={
                                        <div>
                                            <StyledAvatar
                                                src={avatar}
                                                initials={name}
                                                size="lg"
                                            />
                                            <StyledAvatarOverlay selected={selected} />
                                        </div>
                                    }
                                    title={
                                        <>
                                            <TypeIcon type={type} size="sm" />
                                            <CardDivider flexItem orientation="vertical" />
                                            {get(data, 'name')}
                                        </>
                                    }
                                    subTitle={noSubTitle ? '' : this.getSubTitle(data)}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        !disabled && this.onSelect(e, get(data, 'id'));
                                    }}
                                    actions={this.renderListItemActions(data)}
                                    raised
                                />
                            </ExpansionSummary>
                            <ExpansionPanelDetailsStyled itemSize={itemSize} itemCount={itemsCount}>
                                <Divider />
                                {expansionDetailCaption}
                                <VirtualList
                                    itemSize={itemSize}
                                    itemCount={itemsCount}
                                    width='100%'
                                    renderItem={({ index, style }) => {
                                        return data.children[index]
                                            ? this.renderComponentChild({ style, index, data: data.children[index] })
                                            : <div style={style} key={index} />;
                                    }}
                                />
                            </ExpansionPanelDetailsStyled>
                        </ExpansionListItem>
                    )}
                </ResizableListItem>
            );
        }

        return (
            <div style={style} key={id}>
                <ListItemStyled
                    disabled={disabled}
                    component={
                        <div>
                            <StyledAvatar
                                selected={selected}
                                src={avatar}
                                initials={name}
                                size="lg"
                            />
                            <StyledAvatarOverlay selected={selected}/>
                        </div>
                    }
                    title={
                        <>
                            <TypeIcon type={type} size="sm" />
                            <CardDivider flexItem orientation="vertical" />
                            {get(data, 'name')}
                        </>
                    }
                    subTitle={noSubTitle ? '' : this.getSubTitle(data)}
                    onClick={e => !disabled && this.onSelect(e, get(data, 'id'))}
                    selected={selected}
                    actions={this.renderListItemActions(data)}
                    raised
                />
            </div>
        );
    }

    @bind
    renderComponentChild({ style, index, data }) {
        const { type, typeChild, showChildRoles, noSubTitle } = this.props;
        const { id, name, image } = data;
        let avatar = null;

        if (type === 'user') {
            avatar = image && getAttachmentUrl(id, 'user', image);
        } else if (!['workspace', 'team'].includes(type)) {
            avatar = image;
        }

        return (
            <div style={style} key={id}>
                <ListItemChildStyled
                    component={
                        <StyledAvatarChild
                            src={avatar}
                            initials={name}
                            size="lg"
                        />
                    }
                    title={
                        <>
                            <TypeIcon type={typeChild} size="sm" />
                            <CardDivider flexItem orientation="vertical" />
                            {get(data, 'name')}
                        </>
                    }
                    subTitle={noSubTitle ? '' : this.getSubTitle(data)}
                    actions={
                        <>
                            {showChildRoles && <RolesSelect disabled defaulValue={data.role} />}
                        </>
                    }
                    raised
                />
            </div>
        );
    }

    @bind
    buildList(list: Array<Object>, selectedListIds) {
        const { buildList, type, maxHeight } = this.props;
        if(buildList) {
            return buildList(list, selectedListIds);
        }
        list = sortBy(list, 'name', { caseInsensitive: true });
        return (
            <ListStyled items={list.length} itemSize={121} maxHeight={maxHeight || 500}>
                <VirtualList
                    ref={this.listRef}
                    renderItem={({ index, style, resize }) => {
                        const selected = this.getSelected(get(list[index], 'id'));
                        return list[index]
                            ? this.renderComponent({ style, index, resize, data: list[index], selected })
                            : <div style={style} key={index} />;
                    }}
                    itemSize={80}
                    itemCount={list.length || 0}
                    width="1024"
                    title={`${list.length >= 1000 ? '999+' : list.length } ${typeMap[type]}`}
                />
            </ListStyled>
        );
    }

    render() {
        const { list, type, title, filteredList, expanded, disabled, disabledAdd } = this.props;
        const { selectedListIds } = this.state;
        return (
            <>
                <StyledExpansionCard
                    expanded={!!expanded}
                    title={title}
                >
                    {isEmpty(this.state.selectedListIds) ? (
                        (!disabledAdd && !disabled) && <Tooltip arrow title="Click to add">
                            <ButtonWrapper>
                                <Button onClick={this.props.onAddClick || this.openAddModal}>
                                    <Icon type="mdi" name="plus" size="md" hexColor={muiTheme.colors.text.button} /> ADD NEW
                                </Button>
                            </ButtonWrapper>
                        </Tooltip>
                    ) : (
                        !disabled && <Tooltip arrow title={`Click to remove selected list`}>
                            <ButtonWrapper>
                                <Button variant="text" onClick={this.removeTeam}>
                                    <Icon hexColor={muiTheme.colors.secondary.main} type="mdi" name="delete" size="md" /> REMOVE
                                </Button>
                            </ButtonWrapper>
                        </Tooltip>
                    )}
                    {!isEmpty(filteredList || list) ? this.buildList(filteredList || list, selectedListIds) : <Alert type="background">
                        <p>No records found.</p>
                    </Alert>}
                </StyledExpansionCard>
                <AddResourcesModal
                    type={type}
                    isBulkSelect={true}
                    addedResourcesList={list}
                    isOpen={this.state.isAddOpen}
                    onSubmit={this.onSubmit}
                    closeModal={this.closeAddModal}
                    applicableOn={this.props.applicableOn}
                    filterBy={this.props.filterBy}
                    showRoles={this.props.showRoles}
                    userRole={this.props.userRole}
                    withoutClose
                />
            </>
        );
    }
}

export default SelectionList;
