/* @flow */

import { gql } from '@apollo/client';

export default gql`
    mutation publishEntityMutation($type: String!, $id: ID!) {
        result: publishEntity(type: $type, id: $id) {
            id
        }
    }
`;
