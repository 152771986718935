import { loadOfflineDataFromGQL } from './IDBActions';

let isDataLoading = false;
const channel = new BroadcastChannel('data-loading');
export const offlineDataLoadKey = 'data-load-status';

const loadDataIfNeeded = async () => {
    if (isDataLoading) return;
    isDataLoading = true;

    const currentStatus = localStorage.getItem(offlineDataLoadKey);

    if (currentStatus !== 'loaded') {
        localStorage.setItem(offlineDataLoadKey, 'loading');
        channel.postMessage({ status: 'loading' });

        await loadOfflineDataFromGQL();

        localStorage.setItem(offlineDataLoadKey, 'loaded');
        channel.postMessage({ status: 'loaded' });
    }

    isDataLoading = false;
};

channel.onmessage = (message) => {
    if (message.data.status === 'loading') {
        isDataLoading = true;
    } else if (message.data.status === 'loaded') {
        isDataLoading = false;
    }
};

let offlineDataLoadTimeout;
const loadDataForOfflineCache = async () => {
    const isOfflineEnabled = localStorage.getItem('offlineEnabled') === 'true';
    const _isLoading = localStorage.getItem(offlineDataLoadKey) === 'loading';
    if (isOfflineEnabled && !_isLoading && !offlineDataLoadTimeout) {
        offlineDataLoadTimeout = setTimeout(async () => { // As loading data for offline cache is halting the initial loading of application so adding a timeout
            await loadDataIfNeeded();
            offlineDataLoadTimeout = null; // Reset the timeout reference after execution
        }, 30 * 1000);
    }
};

window.addEventListener('beforeunload', () => {
    localStorage.removeItem(offlineDataLoadKey);
});

export { loadDataForOfflineCache };
