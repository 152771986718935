import { Control } from 'ol/control';
import React from 'react';
import ReactDOM from 'react-dom';
import ControlButton from 'app/containers/Maps/Components/ControlButton';

const NorthControl = /*@__PURE__*/ (function (Control) {
    let options = {};

    class NorthControl extends Control {
        constructor(opt_options) {
            super({
                element: document.createElement('div'),
                target: opt_options.target,
            });

            options = opt_options || {};

            const innerButton = (
                <ControlButton key={12} title="North" iconName="compass-outline" />
            );

            this.element.onclick = this.handleRotateNorth;
            this.element.className = 'northControl ol-control';
            ReactDOM.render(innerButton, this.element);
        }

        handleRotateNorth() {
            options.olMapVM.getMap().getView().setRotation(0);
        }
    }

    return NorthControl;
})(Control);

export default NorthControl;
