/* @flow */
import { gql } from '@apollo/client';

export default (id, type, userIds) => {
    const isClass = type === 'class' ? true : false;
    const mutation = isClass ? 'classChatSubscribe': 'entityChatSubscribe';
    const mutations = userIds.map((userId, index) => {
        return `sub${index}ChatId: ${mutation}(id: "${id}", userId: "${userId}"${isClass ? '' : `, type: "${type}"`})`;
    });

    return gql`
        mutation subscribeChatMutation {
            ${mutations.join('\n')}
        }
    `;
};