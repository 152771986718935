/* @flow */

import { gql } from '@apollo/client';

export default gql`
mutation processDefinitionMutation($id: ID!) {
  result: openProcess(processDefinitionId: $id) {
    id
    name
    svg
    primary(fields: ["definition", "variables"])
    lastVersion {
      _startForm {
        definition
      }
    }
  }
}
`;
