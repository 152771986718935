// @flow

import { connect } from 'react-redux';
import { Menu, MenuItem, Divider } from '@mic3/platform-ui';
import memoize from 'memoize-one';
import React, { Fragment, PureComponent } from 'react';
import styled, { withTheme } from 'styled-components';
import PropTypes from 'prop-types';

import { getFieldByType } from 'app/utils/designer/form/fieldUtils';
import { closeTask } from 'store/actions/abox/taskActions';
import { formatDate } from 'app/utils/date/date';
import { get } from 'app/utils/lo/lo';
import ButtonIcon from 'app/components/molecules/ButtonIcon/ButtonIcon';
import Flex from 'app/components/atoms/Flex/Flex';
import history from 'store/History';
import ListItem from 'app/components/molecules/List/ListItem';
import TaskLink from 'app/components/atoms/Link/TaskLink';
import { mediaBreakpoints } from 'app/themes/breakpoints';
import { bind } from 'app/utils/decorators/decoratorUtils';
import { openTaskSidebar } from 'store/actions/abox/taskSidebarActions';
import { getAttachmentUrl } from 'app/utils/attachments/attachmentsUtils';

const ListItemStyled = styled(ListItem)`
width: 100%;
max-width: 1024px;
margin: 0 auto;
@media (max-width: 1100px ) {
    padding-right: 2rem;
}
${({ disabled }) => disabled ? 'opacity: 0.1;' : '' }
`;

const DesktopItems = styled(Flex)`
@media (min-width: ${({ theme }) => theme.media.md}) {
    min-width: 78px;
}
`;

const ButtonIconStyled = styled(ButtonIcon)`
padding: 0.3rem;
& *, & i:before {
    color: rgba(255, 255, 255, 0.6) !important;
}
& span {
    font-size: 0.8rem !important;
    margin-left: 0.6rem;
}
`;

const Badge = styled.span`
color: rgba(255,255,255,0.5);
`;

const ResponsiveLabelStyled = ({ count, label, icon, type, onClick, xs }: Object) => !xs ? (
    <ButtonIconStyled type={type} icon={icon} label={count} onClick={onClick} />
) : (
    <Flex onClick={onClick}>
        <ButtonIconStyled type={type} icon={icon} label={label} />
        <Badge>{count}</Badge>
    </Flex>
);

/**
 * A single task item
 */
class TaskListItem extends PureComponent<Object, Object> {

    static propTypes = {
        data: PropTypes.object,
        isDeleted: PropTypes.bool,
        windowWidth: PropTypes.number,
        profile: PropTypes.object,
        openTaskSlidebar: PropTypes.func,
    };
    // $FlowFixMe
    anchorDotsEl = React.createRef();
    state = { isMenuOpen: false };

    toggleMenu = () => this.setState(state => ({ isMenuOpen: !state.isMenuOpen }));
    closeMenu = () => this.setState({ isMenuOpen: false });

    @bind
    onInfoClick(title) {
        const { openTaskSidebar, data } = this.props;
        if (title === 'Form') {
            history.push(`/abox/task/${data.id}/form`);
            return;
        }
        openTaskSidebar({ title, id: data.id });
    }

    buildDesktopMenuItems = memoize(id => [
        <ResponsiveLabelStyled key={2} onClick={() => this.onInfoClick('A-Live')} type="af" icon={'messenger'} label={'A-Live'} />,
        <ResponsiveLabelStyled key={3} icon="paperclip" label={'Add attachments'} onClick={() => this.onInfoClick('Attachments')} />
    ])

    buildMobileMenuItems = memoize(id => [
        <MenuItem key={2} onClick={() => this.onInfoClick('A-Live')}>
            <ResponsiveLabelStyled type="af" icon={'messenger'} label={'A-Live'} xs />
        </MenuItem>,
        <MenuItem key={3} onClick={() => this.onInfoClick('Attachments')}>
            <ResponsiveLabelStyled icon="paperclip" label={'Add attachments'} xs />
        </MenuItem>
    ])

    render() {
        const {
            data, isDeleted, windowWidth, noActions
        } = this.props;
        const { name, id, primary, assignee } = data;
        const { priority, dueDate, progress, closedDate } = primary || {};
        const { isMenuOpen } = this.state;
        const isXs = windowWidth < mediaBreakpoints.sm;
        const title = name || 'No Name';
        const assigneeImage = assignee?.image && getAttachmentUrl(get(assignee, 'id'), 'user', assignee?.image);

        return (
            <ListItemStyled
                disabled={isDeleted}
                component={
                    getFieldByType('avatar', {
                        CircularProgressStaticProps: {
                            value: progress || 0,
                            priority,
                            disabled:!!closedDate,
                        },
                        initials: get(assignee, 'name', title),
                        src: assigneeImage
                    })}
                title={<TaskLink id={id}>{title}</TaskLink>}
                subTitle={<Fragment><TaskLink id={id}>#{id}</TaskLink>{dueDate && `, Due ${formatDate(dueDate)}`}</Fragment>}
                actions={
                    !noActions && (
                        <Flex>
                            {!isXs && (
                                <DesktopItems spaceBetween>
                                    {this.buildDesktopMenuItems(id)}
                                </DesktopItems>
                            )}
                            <span ref={this.anchorDotsEl}><ButtonIconStyled icon="dots-vertical" onClick={this.toggleMenu} /></span>
                            <Menu open={isMenuOpen} anchorEl={this.anchorDotsEl.current} onClose={this.toggleMenu} >
                                <MenuItem onClick={() => this.onInfoClick('About')}>
                                    <ButtonIconStyled icon="information" label="Open About" />
                                </MenuItem>
                                <MenuItem onClick={() => this.onInfoClick('Form')}>
                                    <ButtonIconStyled icon="login-variant" label="Go to task" />
                                </MenuItem>
                                {isXs && (
                                    <Fragment>
                                        <Divider />
                                        {this.buildMobileMenuItems(id)}
                                    </Fragment>
                                )}
                            </Menu>
                        </Flex>
                    )}
                raised
                itemPadding={'.2rem'}
            />
        );
    }
};

export default connect(
    state => ({
        profile: state.user.profile,
        windowWidth: state.global.window.width,
        taskClosedReasons: state.app.affectliSettings.bpmn.taskClosedReasons,
    }),
    {
        closeTask,
        openTaskSidebar,
    }
)(withTheme(TaskListItem));
