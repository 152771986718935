/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid } from '@mic3/platform-ui';

import { bind } from 'app/utils/decorators/decoratorUtils';
import { get, set } from 'app/utils/lo/lo';
import Loader from 'app/components/atoms/Loader/Loader';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import EntityLayerStyle, { getLayerStylingData } from 'app/containers/Maps/LayerAbout/EntityLayer/EntityLayerStyle';
import EntityLayerFilters from 'app/containers/Maps/LayerAbout/EntityLayer/EntityLayerFilters';
import { commonAbout, commonConstraints } from 'app/containers/Maps/LayerAbout/CommonAbout';
import { shallowEquals } from 'app/utils/utils';
import ExpansionPanel from 'app/components/Designer/ExpansionPanel';

import { _addEntityClasses, _removeEntityClass } from 'store/actions/entities/entitiesActions';
import { loadEventTypes } from 'store/actions/stream/eventsActions';
import { setLayerEntitiesHidden, updateMapLayer } from 'store/actions/maps/situationalAwarenessActions';
import { showToastr } from 'store/actions/app/appActions';
import EntityLayerRelations, { getLayerRelationFormData } from './EntityLayerRelations';
import EventLayerFilters from './EventLayerFilters';
import EntityDataVisualization from './EntityDataVisualization';
import { saveFilterOperators } from 'store/actions/filterbar/filterbarActions';
import { getPermissions } from 'app/config/rolesConfig';
import { disableFieldDefintions } from 'app/containers/Maps/LayerAbout/EntityLayer/MapsLayerFilterDefinitions';
import { isEmpty } from 'app/utils/utils';
import { TASKLAYERS, PROCESSLAYERS, LAYERS, __getMapLayers } from 'app/utils/maps/layer/layerUtils';
import styled from 'styled-components';

const GridStyled = styled(Grid)`
    padding: 0 16px;
    width: 100% !important;
    & > div {
        margin: 16px auto !important;
    }
    h6 {
        font-size: 0.9rem;
        font-weight: 500;
        color: rgb(124 129 142);
        margin-bottom: 12px;
    }
`;

const Container = styled.div`
    max-width: 960px;
    margin: auto;
`;

class EntityLayerAbout extends PureComponent<Object, Object> {
    static propTypes = {
        title: PropTypes.string,
        id: PropTypes.string.isRequired,
        map: PropTypes.object.isRequired,
        mapId: PropTypes.string.isRequired,
        layer: PropTypes.object.isRequired,
        details: PropTypes.object,
        _addEntityClasses: PropTypes.func.isRequired,
        _removeEntityClass: PropTypes.func.isRequired,
        showToastr: PropTypes.func.isRequired,
        loadEventTypes: PropTypes.func.isRequired,
        saveFilterOperators: PropTypes.func.isRequired,
    };

    formRef = React.createRef();
    layerFiltersRef = React.createRef();
    eventLayerFiltersRef = React.createRef();
    layerStylesRef = React.createRef();
    layerRelationsRef = React.createRef();
    layerHeatMapRef = React.createRef();

    constructor(props: Object) {
        super(props);
        this.state = {
            layerForm: { ...(props.details || {}) },
            classesRequired: false,
            formChanged: false,
            ckey: 0,
        };
        this.setOps();
    }

    componentDidMount() {
        const { title, details } = this.props;
        if (title !== 'Event Layer')
            this.subFormReferences = [
                this.formRef,
                this.layerStylesRef,
                this.layerFiltersRef,
                this.layerRelationsRef,
                this.layerHeatMapRef,
            ];
        else this.subFormReferences = [this.formRef, this.layerStylesRef, this.eventLayerFiltersRef];
        const layerStyling = getLayerStylingData(details) ;
        const filters = get(this.layerFiltersRef, 'current.formRef.current.props.data');
        const eventFilters = get(this.eventLayerFiltersRef, 'current.formRef.current.props.data');
        const layerRelations = getLayerRelationFormData(details);
        const dataVisualisation = details?.heatmap || {};

        if (title === 'Event Layer' && layerStyling && eventFilters) {
            const dataVisualisation = details?.primary?.['system_eventlayer/styles'] || {};
            const layerForm = { ...this.state.layerForm, eventFilters, layerStyling: { ...layerStyling, ...dataVisualisation }};
            this.setState({ layerForm, initialForm: layerForm });
        } else {
            const layerForm = { ...this.state.layerForm, filters, layerStyling, layerRelations, dataVisualisation };
            this.setState({ layerForm, initialForm: layerForm });
        }
        this.props.setActions();
    }

    @bind
    async setOps() {
        const { details } = this.props;
        const { operatorsMap } =  details?.filter_by || {};
        operatorsMap && (await this.props.saveFilterOperators(operatorsMap));
        
    }

    componentDidUpdate(prevProps: Object) {
        const { operatorsMap, details, setStatus } = this.props;
        if (!shallowEquals(operatorsMap, prevProps.operatorsMap) && !shallowEquals(operatorsMap, details?.filter_by?.operatorsMap)) {
            setStatus(true, () => this.props.setActions(this.onFormSubmit));
        }
        if (details?.id &&!shallowEquals(details, prevProps.details))
            this.setState((prevState) => ({ layerForm: { ...prevState.layerForm, ...details } }));
    }

    @bind
    setClassesRequired(value: boolean) {
        if (value !== this.state.classesRequired) {
            this.setState({ classesRequired: value, ckey: this.state.ckey + 1 });
        }
    }

    @bind
    buildFilterComponent(props: Object, classesRequired: boolean) {
        const { classes, attributes } = this.props.details;
        const { type } = this.props;
        return (
            <EntityLayerFilters
                {...props}
                name='filters'
                ref={this.layerFiltersRef}
                classes={classes}
                attributes={attributes}
                classesRequired={classesRequired}
                type={type}
            />
        );
    }

    @bind
    buildEventFilterComponent(props: Object) {
        const { entityTypes } = this.props;
        if(props?.data?.event_type)
            return <EventLayerFilters {...props} name='eventFilters' entityTypes={entityTypes} ref={this.eventLayerFiltersRef} />;
        return null;
    }

    @bind
    onStylingChange(layerStyling, target) {
        const { details } = this.props;
        const { name, value } = target || {};
        let { layerForm } = this.state;
        let { dataVisualisation } = layerForm || {};
        if (name === 'pinStyle' && value === 'hideEntities') {
            dataVisualisation = set(dataVisualisation, 'dataVisualisation', true);
            layerForm = set(layerForm, 'layerRelations.relations.relatedEntities', false);
            this.props.setLayerEntitiesHidden(details?.id, true);
        } else if (name === 'pinStyle') {
            this.props.setLayerEntitiesHidden(details?.id, false);
        }
        layerForm = { ...layerForm, layerStyling, dataVisualisation };
        this.handleChange(layerForm, target);
    }

    @bind
    buildStylingComponent() {
        const { classes, attributes } = this.props.details;
        return (
            <ExpansionPanel header='Entity Styling' collapsible>
                <EntityLayerStyle
                    name='layerStyling'
                    data={this.props.details}
                    ref={this.layerStylesRef}
                    onFormChange={this.onStylingChange}
                    formData={this.state.layerForm.layerStyling}
                    classes={classes}
                    attributes={attributes}
                    setClassesRequired={this.setClassesRequired}
                />
            </ExpansionPanel>
        );
    }

    @bind
    onLayerRelChange(layerRelations, target) {
        let { layerForm } = this.state;
        layerForm = { ...layerForm, layerRelations };
        this.handleChange(layerForm, target);
    }

    @bind
    buildRelationComponent() {
        const { classes, attributes } = this.props.details;
        return (
            <ExpansionPanel collapsible header='Related Entities'>
                <EntityLayerRelations
                    data={this.props.details}
                    ref={this.layerRelationsRef}
                    name='layerRelations'
                    onFormChange={this.onLayerRelChange}
                    classes={classes}
                    attributes={attributes}
                    formData={this.state.layerForm.layerRelations || {}}
                />
            </ExpansionPanel>
        );
    }

    @bind
    onVisualisationChange(dataVisualisation, target) {
        const { type } = this.props.details || {};
        let { layerForm } = this.state;
        if(type === 'system_eventlayer') {
            layerForm = set(layerForm, 'layerStyling', dataVisualisation);
        } else {
            layerForm = { ...layerForm, dataVisualisation };
        }
        this.handleChange(layerForm, target);
    }

    @bind
    buildDataVisualisationComponent() {
        const { classes, attributes, type } = this.props.details || {};
        const { layerForm } = this.state;
        const formData = layerForm.dataVisualisation || {};
        return (
            <ExpansionPanel header='Data Visualisation' collapsible>
                <EntityDataVisualization
                    name='dataVisualisation'
                    data={this.props.details}
                    ref={this.layerHeatMapRef}
                    onFormChange={this.onVisualisationChange}
                    formData={formData}
                    classes={classes}
                    attributes={attributes}
                    type={type}
                />
            </ExpansionPanel>
        );
    }

    @bind
    buildFormDefinition(data: Object, classesRequired: boolean) {
        const { title, setStatus, map, details, isChanged, mapUserRole } = this.props;
        let { filter_by } = details || {};
        const { entity_type } = details || {};
        const { canEdit } = getPermissions(mapUserRole);
        const { uri } = entity_type || '';
        const initialFilters = map.getEntityLayer(data?.id)?.getInitialFilters(); // these are default filters which are not saved yet.
        // eslint-disabl=e-next-line prefer-const
        let { operatorsMap } = filter_by || {};
        if (isEmpty(filter_by)) { // // if layer is not saved yet
            filter_by = data?.filters || data?.eventFilters; 
            operatorsMap = { ...(this.props.operatorsMap || {})};
        }
        if (isEmpty(filter_by)) {
            if (TASKLAYERS.includes(uri)) filter_by = { statusType: uri, ...initialFilters };
            else if (PROCESSLAYERS.includes(uri)) {
                let processStatus = null;
                if (uri === 'openprocess') {
                    processStatus = 'is null';
                } else if (uri === 'closedprocess') {
                    processStatus = 'is not null';
                }
                filter_by = { status: processStatus, ...initialFilters };
            }
        }
        const fields = [
            ...commonAbout({
                ...data,
                title,
                setStatus,
                filters: filter_by,
                operatorsMap,
                isChanged,
                uri            }),
            title !== 'Event Layer' && {
                field: 'panel',
                type: 'panel',
                properties: {
                    header: 'Entity Filter',
                    expanded: false,
                },
                children: [
                    {
                        field: 'filters',
                        type: 'custom',
                        properties: {
                            name: 'filters',
                            ckey: this.state.ckey,
                            Component: (props) => this.buildFilterComponent(props, classesRequired),
                        },
                        constraints: commonConstraints('Entity Filters', this.layerFiltersRef),
                    },
                ],
            },
            title === 'Event Layer' && {
                field: 'panel',
                type: 'panel',
                properties: {
                    header: 'Event Filters',
                    expanded: false,
                },
                children: [
                    {
                        field: 'eventFilters',
                        type: 'custom',
                        properties: {
                            name: 'eventFilters',
                            ckey: this.state.ckey,
                            Component: (props) => this.buildEventFilterComponent(props),
                        },
                    },
                ],
            },
        ].filter(Boolean);

        if (!canEdit) {
            return disableFieldDefintions(fields);
        }
        return fields;
    }

    @bind
    handleChange(data, { name, value }) {
        const { layerForm } = this.state;
        const prevValue = layerForm?.[name];
        const formChanged = value !== prevValue;

        const isProbName = ['iconName', 'valLineColour', 'valFillColour'].includes(name);
        const isProbValue = ['#00BCD4', 'Attribute-class'].includes(value);
        if(isProbName && !prevValue && isProbValue) return  this.setState({ layerForm: data });

        this.setState({ layerForm: data, formChanged }, async () => {
            const { formChanged } = this.state;
            if (formChanged) {
                await this.props.setStatus(true);
                this.props.setActions(this.onFormSubmit);
            } else {
                this.props.setActions(null);
                await this.props.setStatus(false);
            }
        });
    }

    @bind
    async setLayerSource({ styleType, type, primary, layerStyling, name }: Object) {
        const { map, id, selectedLayer } = this.props;
        const mapSelected = map.findLayerById(id);
        const isHighlight = mapSelected?.values_?.selectedLayer;
        if (isHighlight) {
            map.removeHightLightFeatures();
        }
        await map.updateEntity(id, { type, styleType, primary, layerStyling, selectedLayer, isHighlight, name });
        if (isHighlight) {
            await map.highLightFeatures(selectedLayer?.id);
        }
    }

    @bind
    getErrorMessage(formWithError: Object) {
        const { name, form } = formWithError || {};
        let message = '';
        if (name) {
            switch (name) {
                case 'filters':
                    message = 'Entity Filters form is not valid';
                    break;
                case 'layerRelations':
                    message = 'Layer Relations form is not valid';
                    break;
                case 'layerStyling':
                    message = 'Layer Styling is not valid';
                    break;
                default:
                    return;
            }
            return message;
        }
        const { errors } = form || {};
        return Object.values(errors || {})[0][0];
    }

    @bind
    async updateEntityData(primaryClass, layerType, layerForm, layerStyling, description, layerData, filters, relations, heatmap) {
        const { allMapLayers, allPrimaryClasses } = this.props;
        const { classes, id, type, styles  } = layerForm;
        const { pinStyle } = styles || {};
        const foundLayer = allMapLayers.find(layer => layer.id === id);
        const layerClass =  type  !== 'event' ? 'entity_type' : 'event_type';
        const eventType = layerForm?.eventFilters?.event_type || layerForm?.event_type;
        const updatedLayerData = { // WIP: This data needs to be fixed
            id,
            name: foundLayer?.name,
            type: LAYERS[type],
            description,
            [layerClass]: type  !== 'event' ? layerForm?.filters?.entity_type : eventType,
            styles: { pinStyle: 'cluster' },
            time_range: filters?.time_range,
            hidden: foundLayer?.hidden || false,
            opacity: foundLayer?.opacity || 100,
            filter_by: filters,
            heatmap,
            related_entities: relations || {},
            styles: layerStyling,
            layerTitle: type  === 'event' ? 'Event Layer' : 'Entity Layer',
            visible: foundLayer?.visible,
        };
        if(layerClass === 'entity_type') {
            updatedLayerData.classData = allPrimaryClasses.find(({ uri }) => uri === foundLayer?.entity_type?.uri);
        }
        this.props.updateMapLayer(updatedLayerData);
        this.setLayerSource({ type: layerType, classes, styleType: pinStyle, primary: updatedLayerData, layerStyling, name: foundLayer?.name }); // Setting info of layers
    }

    @bind
    async onFormSubmit() {
        const validations = await Promise.all(
            this.subFormReferences.map(async (reference) => {
                const form = reference?.current?.formRef ? get(reference.current.formRef, 'current') : reference?.current;
                return { name: get(form, 'props.name', ''), form: form && (await form.isValidForm()) };
            })
        );
        const formWithError = validations.find(({ form }) => form && form.errors);
        if (formWithError) {
            return this.props.showToastr({ severity: 'error', detail: this.getErrorMessage(formWithError) });
        }
        let { layerForm } = this.state;
        const { operatorsMap, setStatus } = this.props;
        validations.forEach(({ name, form }) => {
            if (name) {
                layerForm = set(layerForm, name, form.data);
            }
        });
        const { layerStyling, filters, layerRelations, dataVisualisation, description, eventFilters, time_range, event_type, filter_by } = layerForm;
        const updatedFilters = layerForm?.type === 'event'  ? { ...filter_by, event_type, time_range, ...eventFilters, operatorsMap } :  { ...filters, operatorsMap };
        const { primaryClass } = updatedFilters || {};
        const layerData = { 
            ...(this.props.details || {}),
            relatated_entities: layerRelations || {},
            filter_by: updatedFilters || {},
            progress: { progress: layerStyling?.['entity-layer/entityprogress'] }, // WIP: Progress inside progress? Should be ( progress: layerStyling?.progress ) but if legacy data has progress inside progress then keep the structure similar to legacy data
            counter: { counter: layerStyling?.['entity-layer/entitycounter'] }, // WIP: Counter inside counter? Should be (counter: layerStyling?.counter ) but if ~ same as above
            heatmap: dataVisualisation || {},
            styles: layerStyling || {}
        };

        try {
            await this.updateEntityData(primaryClass, primaryClass?.uri, layerForm, layerStyling, description, layerData,
                updatedFilters, layerRelations, dataVisualisation);
            await this.setState({ initialForm: layerForm, formChanged: false });
            this.props.setActions(null);
            setStatus(false);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log('Error while submitting form : ', error);
            await this.setState({ initialForm: {}, formChanged: false });
            this.props.setActions(null);
        }
    }

    render() {
        const { isLoading, title, details } = this.props;
        const { layerForm, classesRequired } = this.state;
        return (
            <Container>
                {isLoading && details && <Loader absolute backdrop />}
                <FormGenerator
                    data={layerForm}
                    components={this.buildFormDefinition(layerForm, classesRequired)}
                    onChange={this.handleChange}
                    ref={this.formRef}
                />
                <GridStyled>
                    { title !== 'Event Layer' && this.buildRelationComponent()}
                    {this.buildStylingComponent()}
                    {this.buildDataVisualisationComponent()}
                </GridStyled>
            </Container>
        );
    }
}

export default connect(
    (state: Object, ownProps: Object) => ({
        mapUserRole: get(state.maps, 'situationalAwareness.map.data.role', ''),
        isLoading: state.entities.classification.isLoading || state.classifications.details.isLoading,
        selectedLayer: state.maps.situationalAwareness.layer.selectedLayer,
        operatorsMap: state.filterbar.operatorsMap,
        entityTypes: state.app.allPrimaryClasses.records || [],
        allMapLayers: __getMapLayers(state.maps.situationalAwareness.map.data),
        mapData: get(state.maps,'situationalAwareness.map.data',{}),
        allPrimaryClasses: state.app.allPrimaryClasses.records || [],

    }),
    {
        _addEntityClasses,
        _removeEntityClass,
        showToastr,
        loadEventTypes,
        saveFilterOperators,
        setLayerEntitiesHidden,
        updateMapLayer
    }
)(EntityLayerAbout);
