import React, { memo, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import PropType from 'prop-types';
import { Typography, IconButton, Box } from '@mic3/platform-ui';
import { TreeItem, TreeView } from '@material-ui/lab';

import Icon from 'app/components/atoms/Icon/Icon';
import DotMenu from 'app/components/molecules/DotMenu/DotMenu';

const Wrapper = styled.div`
    margin: 16px 0 8px;
`;

const TreeViewStyled = styled(TreeView)`
    margin: 16px 0 !important;

    & .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label, 
    & .MuiTreeItem-label {
        background-color: transparent !important;
    }

    & .MuiTreeItem-group {
        margin-left: 48px !important;
    }

    & .MuiTreeItem-content {
        cursor: initial;
    }
`;

const ActionsWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const Title = styled(Typography)`
    && {
        font-size: 10px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
    }
`;

const SubTitle = styled(Typography)`
    && {
        font-size: 12px;
    }
`;

const TreeItemStyled = styled(TreeItem)`
    margin: 12px 0 !important;
    
    & .MuiTreeItem-iconContainer {
        margin-right: 16px;
        cursor: pointer;
    }
`;

const NodeName = styled(Typography)`
    font-size: 14px !important;
`;

const NodeSub = styled(Typography)`
    font-size: 12px !important;
`;

const NodeError = styled(Typography)`
    font-size: 12px !important;
    color: #e57f75;
`;

const TreeItemContent = styled.div``;

const iconMap = {
    edit: 'pencil',
    delete: 'close'
};

const RelatedEntityTreeList = ({
    label,
    helperText,
    value,
    treeViewProps,
    onAddNode,
    onEditNode,
    onDeleteNode,
    actions,
    actionsRoot,
    ...otherProps
}) => {
    const { disabled } = otherProps;
    const expandNodes = useMemo(() => {
        if (!value) return [];
        const pushChild = (arr, result) => {
            arr.forEach((d, i) => {
                result.push(d.nodeId);
                if (d.children) {
                    pushChild(d.children, result);
                }
            });
            return result;
        };
        let result = [];
        result.push(value.nodeId);
        if (value.children) {
            result = pushChild(value.children, result);
        }
        return result;
    }, [value]);

    const handleAddClick = useCallback((node, treeNodeId) => {
        if(disabled) return;
        onAddNode && onAddNode(node, treeNodeId);
    }, [onAddNode, disabled]);

    const handleDotMenuClick = useCallback((title, node, treeNodeId) => {
        if (title === 'Open related entities') {
            onEditNode && onEditNode(node, treeNodeId);
        }  else if (title === 'Delete') {
            onDeleteNode && onDeleteNode(node, treeNodeId);
        }
    }, [onEditNode, onDeleteNode]);

    const buildAction = useCallback((actions, node, treeNodeId) => {
        if (!actions) return;

        if (actions.length === 1) {
            const isOpenRelEntity = actions[0] === 'open-rel-entities';

            if (isOpenRelEntity) {
                return (
                    <ActionsWrapper>
                        {!node.listBy && <Icon name="alert-circle" hexColor="#d32f2f" onClick={() => handleDotMenuClick('Open related entities', node, treeNodeId)} />}
                        <DotMenu
                            onItemClick={title => handleDotMenuClick(title, node, treeNodeId)}
                            items={[
                                { name: 'Open related entities', icon: 'pencil' },
                            ]}
                        />
                    </ActionsWrapper>
                );
            }
        
            return (
                <IconButton disabled={disabled} onClick={() => handleDotMenuClick('Delete', node, treeNodeId) }>
                    <Icon name={iconMap[actions[0]]} hexColor="#dadada" />
                </IconButton>
            );
        }

        return (
            <ActionsWrapper>
                {!node.listBy && <Icon name="alert-circle" hexColor="#d32f2f" onClick={() => handleDotMenuClick('Open related entities', node, treeNodeId)}  />}
                <DotMenu
                    onItemClick={title => handleDotMenuClick(title, node, treeNodeId)}
                    items={[
                        { name: 'Open related entities', icon: 'pencil' },
                        { name: 'Delete', icon: 'close' }
                    ]}
                />
            </ActionsWrapper>
        );
    }, [handleDotMenuClick, disabled]);

    const renderTree = useCallback((node, root) => {
        const treeNodeId = node.nodeId;

        return (
            <TreeItemStyled 
                key={treeNodeId}
                nodeId={treeNodeId}
                label={
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <TreeItemContent>
                            <NodeName>{node.name}</NodeName>
                            <NodeSub color="textSecondary">
                                {node.sub}
                            </NodeSub>
                            {node.extra && (
                                <NodeSub color="textSecondary">
                                    {node.extra}
                                </NodeSub>
                            )}
                            {(actions.length > 1 && !node.listBy) && <NodeError>No relationship selected</NodeError>}
                        </TreeItemContent>
                        {root ? buildAction(actionsRoot, node, treeNodeId) : buildAction(actions, node, treeNodeId)}
                    </Box>
                }
                onIconClick={() => handleAddClick(node, treeNodeId)}
            >
                {Array.isArray(node.children) ? node.children.map(node => renderTree(node)) : null}
            </TreeItemStyled>
        );
    }, [handleAddClick, buildAction, actions, actionsRoot]);

    return (
        <Wrapper>
            <Title variant="h6">{label}</Title>
            {helperText && <SubTitle color="textSecondary">{helperText}</SubTitle>}
            <TreeViewStyled
                {...treeViewProps} 
                expanded={expandNodes}
                defaultEndIcon={<Icon disabled={disabled} name="plus" hexColor="#7391D0" />}
                defaultParentIcon={<Icon disabled={disabled} name="plus" hexColor="#7391D0" />}
            >
                {value && renderTree(value, true)}
            </TreeViewStyled>
        </Wrapper>
    );
};

RelatedEntityTreeList.propTypes = {
    label: PropType.string.isRequired,
    helperText: PropType.string,
    value: PropType.shape({
        id: PropType.string.isRequired,
        name: PropType.string.isRequired,
        children: PropType.arrayOf(PropType.shape({
            id: PropType.string.isRequired,
            name: PropType.string.isRequired,
            sub: PropType.string,
            extra: PropType.string
        }))
    }),
    onAddNode: PropType.func,
    onEditNode: PropType.func,
    onDeleteNode: PropType.func,
    actions: PropType.arrayOf(PropType.oneOf(['delete', 'open-rel-entities'])),
    actionsRoot: PropType.arrayOf(PropType.oneOf(['delete', 'open-rel-entities']))
};

export default memo(RelatedEntityTreeList);