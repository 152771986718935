// @flow
// $FlowFixMe
import React, { useState, useCallback, PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { loadAvatar as loadEntitiesAvatar } from 'store/actions/entities/entitiesActions';
import { loadAvatar as loadClassesAvatar } from 'store/actions/classifications/classificationsActions';
import { Grid, Avatar, ClickAwayListener, Tooltip, Chip } from '@mic3/platform-ui';
import { withStyles } from '@material-ui/core/styles';
import { get, set } from 'app/utils/lo/lo';
import { getStr, isDefined, shallowEquals } from 'app/utils/utils';
import { getPriorityColor } from 'app/config/aboxConfig';
import { bind } from 'app/utils/decorators/decoratorUtils';
import { loadTypeaheadProcessDefinitions } from 'store/actions/abox/myAppsActions';
import { getAttachmentUrl } from 'app/utils/attachments/attachmentsUtils';
import { loadUserReferences } from 'store/actions/admin/usersActions';
import { buildDateTimaRangeStringValue } from 'app/utils/designer/form/fieldUtils';

const AvatarStyled = styled(Avatar)`
    margin-left: 0 !important;
`;

const GridStyled = styled(Grid)`
    margin-right: ${({isMobile})=> isMobile ? '0px':'8px'} !important;
`;


const ChipStyled = styled(Chip)`
    margin: 1px;
    height: 24px;
    color: ${({ theme }) => theme.material.palette.text.secondary};
    ${({theme, priority}) => priority && `background: linear-gradient(45deg, ${theme.priorityGradients[priority][0]}, ${theme.priorityGradients[priority][1]}) !important;`}
`;

const ChipTooltip = ({ tooltip, ...restProps }) => {
    const [isOpen, setTooltip] = useState(false);
    const close = useCallback(() => {
        setTooltip(false);
    }, [setTooltip]);
    const open = useCallback(() => {
        setTooltip(true);
    }, [setTooltip]);
    return (
        <ClickAwayListener onClickAway={close}>
            <Tooltip
                PopperProps={{ disablePortal: true }}
                onClose={close}
                open={isOpen}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={tooltip}
            >
                <ChipStyled {...restProps} onClick={open}/>
            </Tooltip>
        </ClickAwayListener>
    );
};

class FiltersChips extends PureComponent<Object, Object> {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        onChange: PropTypes.func.isRequired,
        filters: PropTypes.object.isRequired,
        filterDefinitions: PropTypes.array.isRequired,
    }

    @bind
    getEntityId(definition, filters, name){
        const valueField = getStr(definition, 'properties.valueField', '');
        if (valueField && valueField !== 'id') { // if valueField is not ID then we can not get the values from our redux maps neither we can fetch data
            return null;
        }
        return get(filters, name, '');
    }

    @bind
    getChipData(definition, filters, name, type){
        const entityId = this.getEntityId(definition, filters, name);
        switch (type) {
            case 'userTypeahead': {
                const { userReferences, userReferencesLoading } = this.props;
                const user = (userReferences || []).find(usr => usr.id === get(entityId, 'id') || usr.id === entityId);
                if(!user && !userReferencesLoading) {
                    this.props.loadUserReferences();
                }
                return user;
            }
            case 'classificationTypeahead': {
                const { avatarClassesMap } = this.props;
                const classification = avatarClassesMap[entityId];
                if (!classification?.id) {
                    this.props.loadClassesAvatar(entityId);
                }
                return classification;
            }
            case 'processTypeTypeahead': {
                const { processDefsLoading, processDefinitions } = this.props;
                const definition = (processDefinitions || []).find(def => def && def.id === entityId);
                if (!processDefsLoading && !processDefinitions?.length) {
                    this.props.loadTypeaheadProcessDefinitions(); // As its a static dropdown so if definitions are already loaded then there is no need to load them again
                }
                return definition;
            }
            default:
        }
        switch (name) {
            case 'relatedEntities': {
                const { avatarEntitesMap } = this.props;
                const entity = avatarEntitesMap[get(entityId, 'id') || entityId];
                if(!entity) {
                    this.props.loadEntitiesAvatar(entityId.id, entityId.type);
                }
                return entity;
            }
            default:
        }
        return;
    }

    @bind
    buildOnDelete(name, index){
        return () => {
            let value = get(this.props.filters, name) || [];
            if(isDefined(index)) {
                value = value.filter((val, ind) => index !== ind);
            }
            this.props.onChange(set(this.props.filters, name, isDefined(index) ? value : null));
        };
    }

    @bind
    parseTooltip(definition, filters, name, value) {
        let tooltip = value && get(value, 'exclude') ? value.exclude : value;
        tooltip = tooltip === '__isNull' ? 'empty' : tooltip;
        // take Label from options if exist
        (get(definition, 'properties.options') || []).forEach((opt) => {
            // $FlowFixMe
            if(opt.value === value || shallowEquals(get(opt, 'value.exclude'), value)) {
                tooltip = opt.label;
            }
        });
        // define label by type
        const tooltipData = this.getChipData(definition, filters, name, definition.type);
        switch(definition.type) {
            case 'processTypeTypeahead': {
                const processTypeName = getStr(tooltipData, 'name', '');
                tooltip = `${processTypeName}`;
                break;
            }
            case 'userTypeahead': {
                const userName = getStr(tooltipData, 'name', '');
                tooltip = `${userName}`;
                break;
            }
            case 'processTypeahead':
            case 'taskTypeahead': {
                const userName = getStr(filters, `${String(name)}.name`);
                tooltip = `${userName || ''} (${getStr(filters, `${String(name)}.id`) || ''})`;
                break;
            }
            case 'dateTimeRange': {
                tooltip = buildDateTimaRangeStringValue(filters[name]);
                break;
            }
            case 'classificationTypeahead': {
                tooltip = `${getStr(tooltipData, 'name', '')}`;
                break;
            }
            default:
        }
        switch(name) {
            case 'relatedEntities': {
                const userName = getStr(filters, `${String(name)}.name`);
                tooltip = `${userName || ''} (${getStr(filters, `${String(name)}.type`) || ''})`;
                break;
            }
            default:
        }
        return tooltip;
    }

    @bind
    parseAvatar(definition, filters, name) {
        const { classes } = this.props;
        const avatar =  null;
        const chipData = this.getChipData(definition, filters, name, definition.type);
        const id = getStr(chipData, 'id', '');
        if(!chipData || chipData.isLoading) {
            return null;
        }
        switch(definition.type) {
            case 'userTypeahead': {
                const imageId = getStr(chipData, 'image');
                const userName = getStr(chipData, 'name');
                const image = imageId && getAttachmentUrl(id, 'user', imageId);
                return <AvatarStyled src={image} initials={userName} className={classes.avatar} />;
            }
            case 'taskTypeahead': {
                const imageId = get(filters, `${String(name)}.assignee.image`);
                const userName = getStr(filters, `${String(name)}.name`) || 'No Name';
                const image = imageId && getAttachmentUrl(id, 'user', imageId);
                return <AvatarStyled src={image} initials={userName} className={classes.avatar} />;
            }
            default:
        }
        switch(name) {
            case 'relatedEntities': {
                const imageId = get(filters, `${String(name)}.image`);
                const userName = getStr(filters, `${String(name)}.name`);
                const image = imageId && getAttachmentUrl(id, get(chipData, 'type'), get(chipData, 'image'));
                return <AvatarStyled src={image} initials={userName} className={classes.avatar} />;
            }
            default:
        }
        return avatar;
    }

    @bind
    buildChips(filters, filterDefinitions, index) {
        const { classes } = this.props;
        return Object.keys(filters || [])
            .filter(name => (filters[name] || filters[name] === false) && name !== 'searchBar')
            .map((name) => {
                const value = filters[name];

                const definition = filterDefinitions.find(def => name === get(def, 'properties.name'));
                if(!definition) {
                    return null;
                }
                if(Array.isArray(value) && get(definition, 'type') !== 'dateTimeRange') {
                    return value.map((val, index) => this.buildChips({ [name]: val }, filterDefinitions, index));
                }
                const tooltip = this.parseTooltip(definition, filters, name, value);
                const avatar = this.parseAvatar(definition, filters, name);

                const extraProps = {};
                if(definition.field === 'priority') {
                    extraProps.priority = getPriorityColor(value);
                }
                if(!definition?.constraints?.required){
                    extraProps.onDelete = this.buildOnDelete(name, index);
                }
                return (
                    <ChipTooltip
                        key={String(name)}
                        avatar={avatar}
                        label={`${get(definition, 'properties.label', '')}: ${tooltip}`}
                        className={classes.chip}
                        tooltip={tooltip}
                        variant="outlined"
                        {...extraProps}
                    />
                );
            });
    }

    render() {
        const { classes, filters, filterDefinitions, isMobile } = this.props;
        const chipComponents = this.buildChips(filters, filterDefinitions);
        if (!chipComponents || chipComponents.length <= 0) {
            return null;
        }
        return (
            <GridStyled isMobile={isMobile} item xs={12} md={9} className={classes.appBar} container alignItems="center">
                {chipComponents}
            </GridStyled>
        );
    }
};

const styles = theme => ({
    appBar: {
        width: '100%',
        margin: '4px 0 4px 0',
        flexGrow: 1,
        overflow: 'auto',
        maxHeight: '67px',
    },
    chip: {
        height: '24px',
        margin: '0 4px 8px',
    },
    avatar: {
        width: '24px',
        height: '24px',
    }
});
export default connect(state => ({
    avatarEntitesMap: state.entities.avatar,
    avatarClassesMap: state.classifications.avatar,
    processDefinitions: get(state.abox.app.typeaheadProcessDefinitions.data, 'records', []),
    processDefsLoading: get(state.abox.app.typeaheadProcessDefinitions, 'isLoading', false),
    userReferences: state.admin.users.references.data,
    userReferencesLoading: state.admin.users.references.isLoading,
    isMobile: state.global.isMobile,
}), { loadUserReferences, loadEntitiesAvatar, loadClassesAvatar, loadTypeaheadProcessDefinitions })(withStyles(styles)(FiltersChips));
