

export const parseProperties = (text) => {
    if (!text) {
        return {};
    }
    const rows = text.split('\n').map(row => row.trim()).filter(row => row.includes('='));
    return rows.reduce((acc, row) => {
        const i = row.indexOf('=');
        acc[row.substring(0, i).trim()] = row.substring(i + 1).trim();
        return acc;
    }, {});
};
