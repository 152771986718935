/* @flow */

import { gql } from '@apollo/client';

export default (type) => {
    let operation = 'entityChatPostMessage';

    if (type === 'class') {
        operation = 'classChatPostMessage';
    } else if (type === 'team') {
        operation = 'teamChatPostMessage';
    }

    return gql`
        mutation entityChatPostMessage($id: ID!, $message: String!) {
            data: ${operation}(id: $id, message: $message${!['class', 'team'].includes(type) ? `, type: "${type}"` : ''}) {
                id
            }
        }
    `;
};