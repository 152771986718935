import { gql } from '@apollo/client';
import { entityReferences } from 'graphql/entities/relationships/relationsQuery';

export default gql`query relationsQuery($entityType: String!, $entityId: ID!, $filterBy: [JSON], $orderBy: [JSON]) {
    relations: entityRelations(entityType: $entityType, entityId: $entityId, filterBy: $filterBy, orderBy: $orderBy) {
        relatedEntity {
          ${entityReferences}
        }
        relation {
          relationDefinition {
            description
          }
        }
    }
}`;
