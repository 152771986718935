/* @flow */

import { gql } from '@apollo/client';

export const workspace = `
    id
    name
    isSystem
    _role
    team {
      id
      name
      isSystem
    }
    user {
      id
      name
      image
      username
    }
`;

export const workspaceFields = `
id
type
workspaces {
  ${workspace}
}
`;

export const classWorkspaceFields = `
id
workspaces {
  ${workspace}
}
`;
export default (type, id) => {
    switch (type) {
        case 'class': {
            return gql`
                query loadEntityWorkspacesQuery($id: ID!){
                  result: class(id: $id) {
                      id
                      name
                      workspaces{
                          ${workspace}
                      }
                  }
                }
      `;
        }
        default:
            return gql`
        query loadEntityWorkspacesQuery($type: String!, $id: ID!){
          result: entity(type: $type, id: $id) {
              ${workspaceFields}
          }
        }
    `;
    }
};
