/* @flow */

// $FlowFixMe
import React, { useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';
import { markdown } from '@mic3/platform-ui';
import { compile, render } from 'app/utils/template/template';
import worker from 'app/utils/worker/worker';

const FullWidthStyled = styled.div`
    width: 100%;
    overflow-wrap: break-word;
    color: ${({ disabled }) => !disabled ? 'inherit' : 'rgb(135, 135, 135)'};
    ${({ styledRules }) => styledRules}
`;

const DisplayText = (props: Object) => {
    const { text, data, memoizeFunction, onRefresh, refreshInterval, context, ...divProps } = props;
    const [state, setState] = useState({});

    const compiled = useMemo(() => compile(text || ''), [text]);

    const html = useMemo(
        () => markdown(render(compiled, { ...(data || {}), properties: props, context, data, state })
        ).replace('<img','<img style="width: 100%;"' ),
        [compiled, data, state, context, props]
    );
    
    useEffect(() => {
        let interval = null;
        if (refreshInterval && onRefresh) {
            interval = setInterval(() => {
                (async () => {
                    const newState = await worker(onRefresh, {...data}, {...state});
                    setState(newState);
                })();
            }, refreshInterval * 1000);
        }
        return () => interval && clearInterval(interval);
    }, [data, state, setState, refreshInterval, onRefresh]);

    return (
        <FullWidthStyled {...divProps} dangerouslySetInnerHTML={{__html: html}} />
    );
};

export default DisplayText;
