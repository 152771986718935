/* @flow */

import { gql } from '@apollo/client';

export default gql`
query classificationsIdsQuery($filterBy: [JSON], $orderBy: [JSON]) {
  records: classes(filterBy: $filterBy, orderBy: $orderBy) {
    id
  }
}
`;
