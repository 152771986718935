/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { get } from 'app/utils/lo/lo';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { isEmpty } from 'app/utils/utils';
import moment from 'moment';

import { loadBoardTasks, createRelationEntityToTask, addTaskToColumn } from 'store/actions/abox/old_boardsActions';

import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import Button from 'app/components/atoms/Button/Button';
import VirtualListManaged from 'app/components/molecules/VirtualList/VirtualListManaged';
import { StyledEntityListItem, BoardsModalList, BoardsModalSearch } from 'app/components/ABox/OldBoards/style';
import Loader from 'app/components/atoms/Loader/Loader';

const inputProps: Object = { disableUnderline: true };


/**
 * A modal for search and add team members
 */
class BoardsAddTaskModal extends PureComponent<Object, Object> {
    static propTypes = {
        userProfile: PropTypes.object,
        closeModal: PropTypes.func.isRequired,
        column: PropTypes.object.isRequired,
        relationDefinitionId: PropTypes.string,
        isLoadingTasks: PropTypes.bool,
        isLoading: PropTypes.bool,
        startIndex: PropTypes.number,
        records: PropTypes.array,
        totalRecords: PropTypes.number,
        createRelationEntityToTask: PropTypes.func.isRequired,
        addTaskToColumn: PropTypes.func.isRequired,
        loadBoardTasks: PropTypes.func.isRequired,
        loadBoardTasksSingle: PropTypes.func.isRequired,
        boardTasksFilters: PropTypes.array
    }

    state = {
        search: '',
        filterBy: [],
        orderBy: [{
            field: 'name',
            direction: 'asc nulls last'
        }],
        selectedTasks: [],
    }

    // preserve the initial state in a new object.
    defaultState = this.state;
    virtualListRef = React.createRef();

    @bind
    @memoize()
    loadTasks(options: Object) {
        const { relationDefinitionId, boardTasksFilters } = this.props;
        const excludeBy = [...(options.excludeBy || [])];
        const filterBy = [];
        options.filterBy = [...boardTasksFilters, ...options.filterBy];

        if (!isEmpty(options.filterBy)) {
            let searchFilters = [];
            for (const filter of options.filterBy) {
                const { or, field, value } = filter;
                const searchFields = ['name', 'id'];

                // check if (or) is not empty or (field) is a search filter value.
                if (!isEmpty(or) || searchFields.includes(field)) {
                    if (!isEmpty(or)) {
                        // handling for modal search filter input.
                        // check if first index of (or) include search filter value
                        if (searchFields.includes(or[0].field)) {
                            // overwrite (searchFilter) because (or) value is from modal search filter input.
                            searchFilters = or;
                        } else {
                            filterBy.push(filter);
                        }
                    } else {
                        // handling for board tasks search by name & id filters
                        if (!isEmpty(searchFilters)) {
                            for (const [i, val] of searchFilters.entries()) {
                                if (val.field !== field) {
                                    searchFilters.push(filter);
                                    break;
                                } else {
                                    searchFilters[i].value = value;
                                }
                            }
                        } else {
                            searchFilters.push(filter);
                        }
                    }
                } else {
                    filterBy.push(filter);
                }
            }
            // if (searchFilters.or) is not empty, push value to (filterBy)
            if (!isEmpty(searchFilters)) filterBy.push({ or: searchFilters });
        } else {
            filterBy.push({ or: [
                { field: 'primary.closedDate', op: '>', value: moment().subtract(2, 'months').toISOString() },
                { field: 'primary.closedDate', op: 'is null' },
            ] });
        }

        excludeBy.push({
            field: 'relations.relation.relationDefinition.id',
            op: '=',
            value: relationDefinitionId
        });

        return this.props.loadBoardTasks({ ...options, filterBy, excludeBy });
    }

    @bind
    renderComponent({ style, index, data }: { style: Object, index: number, data: Object }) {
        const { selectedTasks } = this.state;

        return (
            <div style={style} key={index}>
                <StyledEntityListItem
                    data={data}
                    title="Click to select"
                    type={'task'}
                    onClick={e => this.onSelectTask(e, data)}
                    selected={selectedTasks.find(selectedTask => selectedTask.id === data.id)}
                />
            </div>
        );
    }

    @bind
    onSelectTask(event: Object, data: Object){
        event.preventDefault();
        const selectedTasks = [...this.state.selectedTasks ];
        const foundIndex = selectedTasks.findIndex(selectedTask => selectedTask.id === data.id);
        if (foundIndex === -1 && selectedTasks.length === 15) {
            return;
        }
        if (foundIndex > -1) {
            selectedTasks.splice(foundIndex, 1);
        } else {
            selectedTasks.push(data);
        }
        this.setState({ selectedTasks });
        this.updateList();
    }

    @bind
    updateList() {
        if (this.virtualListRef && this.virtualListRef.current) {
            this.virtualListRef.current.forceUpdate();
        }
    }

    @bind
    onSubmit() {
        const { column, relationDefinitionId, createRelationEntityToTask, loadBoardTasksSingle } = this.props;
        const { selectedTasks } = this.state;

        if (selectedTasks.length) {
            selectedTasks.forEach(async (selectedTask, idx) => {
                const { primary } = selectedTask;
                const relationData = {
                    relationDefinition: relationDefinitionId,
                    entity: { type: get(primary, 'closedDate') ? 'closedtask' : 'opentask', id: selectedTask.id },
                    relatedEntity: { type: 'custom', id: column.id }
                };

                await createRelationEntityToTask(relationData);
                try {
                    if (idx === selectedTasks.length - 1) {
                        this.closeModal();
                        loadBoardTasksSingle(column);
                    }
                } catch {
                    return;
                }
            });
        }
    }

    @bind
    onChangeSearch(e: Object) {
        if (e.persist) {
            e.persist();
        }

        this.setState({ search: e.target.value }, () => {
            const { search } = this.state;
            const searchFilter = {
                or: [
                    { field: 'name', op: 'contains', value: !search ? '' : search },
                    { field: 'id', op: 'startsWith', value: !search ? '' : search, cast: 'text' }
                ]
            };
            const newFilterBy = [ searchFilter ];

            this.setState({
                filterBy: newFilterBy
            });
        });
    }

    @bind
    closeModal() {
        this.setState(this.defaultState, this.props.closeModal);
    }

    render() {
        const { column, records, isLoadingTasks, isLoading, totalRecords, startIndex } = this.props;
        const { selectedTasks, filterBy, orderBy, search } = this.state;
        return (
            <ModalDialog
                dialogProps={{
                    overflow: 'hidden',
                }}
                title={`Add Task to ${column.name} ${selectedTasks.length ? `(${selectedTasks.length})` : ''}`}
                subtitle={`${selectedTasks.length === 15 ? '(Maximum task limit)' : ''}`}
                onClose={this.closeModal}
                actions={<Button disabled={selectedTasks.length === 0} color="primary" onClick={this.onSubmit}>
                    Submit
                </Button>}
            >
                {isLoading && <Loader absolute backdrop />}
                <BoardsModalSearch
                    onChange={this.onChangeSearch}
                    value={search}
                    fullWidth
                    margin="none"
                    placeholder="Search..."
                    InputProps={inputProps}
                />
                <BoardsModalList>
                    <VirtualListManaged
                        ref={this.virtualListRef}
                        renderComponent={this.renderComponent}
                        itemSize={84}
                        title={`${totalRecords >= 1000 ? '999+' : totalRecords } Tasks`}
                        itemCount={totalRecords || 0}
                        loadData={this.loadTasks}
                        isLoading={isLoadingTasks}
                        startIndex={startIndex || 0}
                        filterBy={filterBy}
                        orderBy={orderBy}
                        list={records}
                        maxWidth="1024"
                    />
                </BoardsModalList>
            </ModalDialog>
        );
    }
}

export default connect(state => ({
    relationDefinitionId: state.abox.kanban.relationDefinition.task.relationTypeId,
    isLoadingTasks: state.abox.kanban.tasks.isLoading,
    isLoading: state.abox.kanban.boards.isLoading,
    startIndex: state.abox.kanban.tasks.startIndex,
    records: state.abox.kanban.tasks.records,
    totalRecords: state.abox.kanban.tasks.count
}), {
    loadBoardTasks,
    createRelationEntityToTask,
    addTaskToColumn
})(BoardsAddTaskModal);
