/* @flow */

import { help } from 'app/utils/designer/form/settings/common/commonHelpers';
import { name, label, clearable, dateFormatFields } from 'app/utils/designer/form/settings/common/commonBase';
import { onLoad, onChange, isVisible, isDisabled } from 'app/utils/designer/form/settings/common/commonEvents';
import { required, classRequired, readOnly, staticAttribute } from 'app/utils/designer/form/settings/common/commonValidation';
import { flexGrow } from 'app/utils/designer/form/settings/common/commonStyles';


const panels = settingsValues => [
    {
        header: 'Base',
        children: [
            name,
            label,
            clearable,
            ...dateFormatFields(),
        ],
    },
    {
        header: 'Events',
        children: [
            onLoad,
            onChange,
            isVisible,
            settingsValues?.viewType !== 'class' && isDisabled
        ].filter(Boolean)
    },
    {
        header: 'Validation',
        children: [
            settingsValues?.viewType !== 'class' ? required : classRequired,
            settingsValues?.viewType === 'class' && readOnly,
            settingsValues?.viewType === 'class' && staticAttribute(settingsValues)
        ].filter(Boolean)
    },
    {
        header: 'Styles',
        children: [ flexGrow ]
    },
    {
        header: 'Helpers',
        children: [ help ]
    },
];

export default {
    defaults: { fullWidth: true, variant: 'filled', serialize: true, clearable: true, withDefault: true, },
    panels: settingValues => panels(settingValues),
};

