/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Typography } from '@mic3/platform-ui';
import { muiTheme } from 'app/themes/materialUi';
import Link from 'app/components/atoms/Link/Link';
import Icon from 'app/components/atoms/Icon/Icon';
import styled from 'styled-components';
import isValidUuid from 'uuid-validate';

import history from 'store/History';
import { loadLeftPanelDefinitions, loadLeftPanelEventTypes, loadLeftPanelPrimaryClasses,
    loadLeftPanelRecordChildrenCount, setLeftPanelSortBy } from 'store/actions/leftPanel/leftPanelActions';
import { setActions, setSubTitle, closeLeftPanel, setTitle, setFilterBy } from 'store/actions/leftPanel/leftPanelActions';
import { shrinkLeftPanel, expandLeftPanel } from 'store/actions/leftPanel/leftPanelActions';
import { loadEntityClassesAppliesToLeftPanel, loadClassChildrenLeftPanel } from 'store/actions/classifications/classificationsActions';
import { saveUserPreferences } from 'store/actions/admin/usersActions';

import { bind, memoize, debounce } from 'app/utils/decorators/decoratorUtils';
import { redirectTypes } from 'app/config/typesConfig';
import { leftPanelMenu } from 'app/config/leftPanelConfig';
import { get } from 'app/utils/lo/lo';
import { shallowEquals, sortAscending, sortDescending } from 'app/utils/utils';

import GlobalLeftPanelHeader from 'app/components/organisms/GlobalLeftPanel/GlobalLeftPanelHeader';
import TreeList from 'app/components/molecules/TreeList/TreeList';
import LeftPanelSearch from 'app/components/molecules/LeftPanelSearch/LeftPanelSearch';
import NestedMenu from 'app/components/molecules/NestedMenu/NestedMenu';
import Loader from 'app/components/atoms/Loader/Loader';
import { parseView } from 'app/components/organisms/GlobalLeftPanel/GlobalLeftPanel';

const Wrapper = styled.div`
 ${({ type, theme }) =>
    type === 'tasks' ? `
        position: sticky;
        z-index: 1;
        top: 0px;           
        display: flex;
        flex-direction: column;
        height: 100px;
        background: ${theme.material.colors.background.default};
    ` : `
        position: sticky;
        z-index: 1;
        top: 0px;           
        display: flex;
        flex-direction: column;
        background: ${theme.material.colors.background.default};
    `}
`;

const StyledLink = styled(Link)`
 ${({ theme, selected }) =>`   
        display: flex;
        align-items: center;
        text-decoration: none !important;
        margin: 0 17px !important;
        padding-left: 21px !important;
        cursor: pointer;
        background: ${theme.material.colors.background.default};
         ${ selected === 'allItems' ? `background: ${theme.material.colors.leftSideBar.itemActive} !important;`: null }   
        
    ` 
}`;


const TypographyStyled = styled(Typography)`
margin: 15px 16px  !important;
text-decoration: none !important;

&.MuiTypography-body1 {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    color: ${({theme})=> theme.material.colors.text.primary} !important;
    
}

`;


export const getFilterBy = (id, uri, view) => {
    if (!id || !uri || !isValidUuid(id)) {
        return [];
    }
    if(view === 'classEntities') {
        return [
            { field: 'classes', op: 'contains', value: [id] },
        ];
    }
    switch (uri) {
        case 'task': {
            return [{ field: 'process.processDefinitionVersion.primary.processDefinition', op: '=', value: id }]; }
        case 'event':
            return [{ field: 'eventType.id', op: '=', value: id }];
        default:
            return [id ? { field: 'classes', op: 'contains', value: [id] } : { field: 'type', op: '=', value: uri }].filter(Boolean);
    }
};

export const buildClassChildrens = (classes, entityClasses = {}, showClasses) => {
    const nextClasses = classes.filter(c => c.primary).map(c => ({...c, children: []}));
    return nextClasses.map((clss) => {
        if (clss.parents) {
            clss.parents.filter(c => c.primary).forEach((cl) => {
                const parent = nextClasses.find(c => c.id === cl.id);
                if (parent && !parent.children) {
                    parent.children = [clss];
                } else if (parent && !parent.children.find(c => c === cl.id)) {
                    parent.children.push(clss);
                }
            });
        }
        if (Object.keys(entityClasses).length > 0 && showClasses) {
            const hasEntityClassChildren = Object.keys(entityClasses).findIndex(entityUri => entityUri === clss.uri);
            if (hasEntityClassChildren !== -1) {
                const entityClassChildren = [...entityClasses[clss.uri].children];
                clss.children = entityClassChildren.concat(clss.children);
            }
        }
        
        return clss;
    });
};

const allItemsLinkConfig = {
    tasks: {
        path: '/abox/tasks',
        iconName: 'checkbox-multiple-marked-circle-outline',
        label: 'All Tasks',
    },
    events: {
        path: '/events',
        iconName: 'event-monitor',
        label: 'All Events',
        iconType: 'af'
    }
};

class EntityLeftPanel extends PureComponent<Object, Object> {
    static propTypes = {
        id: PropTypes.string,
        type: PropTypes.string,
        title: PropTypes.string,
        isOpen: PropTypes.bool,
        isLoading: PropTypes.bool
    };

    constructor(props: Object) {
        super(props);
        const { leftPanelSortBy } = props;
        this.state = {
            isLoadingNodeId: null,
            loadedNodeIds: [],
            settingAnchorEl: null,
            searchValue: '',
            entityClasses: {},
            selected: props.id || 'allItems',
            orderBy: leftPanelSortBy?.length ? leftPanelSortBy : [
                { field: 'name', direction: 'asc nulls last' }
            ],
            showClasses: get(props.preferences, 'leftPanel.showClasses') !== false ? true : false
        };
        this.setActions(props.entityType, this.state.searchValue);
        this.initData();
    };

    componentDidUpdate(prevProps, prevState) {
        const { entityType, location, leftPanelSortBy, id } = this.props;
        const isModuleChanged = prevProps.location?.pathname?.split('/')?.[1] !== location?.pathname?.split('/')?.[1];
        const isContentChanged = prevProps.content !== this.props.content;
        let skipFilters = false;

        if (
            !shallowEquals(prevState.entityClasses, this.state.entityClasses) ||
            !shallowEquals(prevState.orderBy, this.state.orderBy) ||
            prevState.settingAnchorEl !== this.state.settingAnchorEl ||
            prevProps.records !== this.props.records ||
            prevProps.isLoading !== this.props.isLoading || 
            prevProps.preferences !== this.props.preferences
        ) {
            skipFilters = true;
        }

        this.initData(prevProps, skipFilters);
        
        if (isContentChanged || this.state.searchValue !== prevState.searchValue) {
            this.setActions(entityType, this.state.searchValue);
        }

        if (isModuleChanged) {
            this.setState({ searchValue: '' });
        }
        if (!shallowEquals(prevProps.leftPanelSortBy, leftPanelSortBy)) {
            this.setState({ orderBy: leftPanelSortBy?.length ? leftPanelSortBy : [
                { field: 'name', direction: 'asc nulls last' }
            ] });
        }
        if (!shallowEquals(prevState.orderBy, this.state.orderBy)) {
            this.setEntityClassOrder();
        }
        if(!id && id !== prevProps.id) {
            this.setState({ selected: 'allItems' });
        }
    }

    initData(prevProps = {}, skipFilters) {
        const { entityType, id, isOpen } = this.props;
        const { searchValue, orderBy } = this.state;
        if (!isOpen) return;
        if (!skipFilters) this.setFilterBy(id, entityType);
        this.reloadList(entityType, searchValue, orderBy);
    }

    @bind
    setEntityClassOrder() {
        this.setState((prevState) => {
            const entityClassesClone = {...prevState.entityClasses};

            Object.keys(entityClassesClone).map((key) => {
                const dir = get(this.state, 'orderBy.[0].direction');
                const children = dir.includes('desc') ? sortDescending(entityClassesClone[key].children, 'name') :       sortAscending(entityClassesClone[key].children, 'name');
                return entityClassesClone[key].children = children;
            });

            return {
                ...prevState,
                entityClasses: entityClassesClone
            };
        });
    }

    @bind
    @memoize()
    reloadList(entityType, searchValue, orderBy) {
        const options = [];
        if(searchValue && entityType !== 'entity') {
            options.push({ field: 'name', op: 'contains', value: searchValue });
        }
        this._reloadList(entityType, options, orderBy);
    }

    @bind
    @debounce()
    _reloadList(entityType, filterBy, orderBy) {
        const { loadLeftPanelDefinitions, loadLeftPanelEventTypes, loadLeftPanelPrimaryClasses } = this.props;
        switch (entityType) {
            case 'task':
                return loadLeftPanelDefinitions(filterBy, orderBy);
            case 'event':
                return loadLeftPanelEventTypes(filterBy, orderBy);
            case 'entity':
                return loadLeftPanelPrimaryClasses(filterBy);
            default:
                return null;
        }
    }

    @bind
    @memoize()
    getSearchPlaceHolder(type){
        switch (type) {
            case 'task':
                return 'Search process definition';
            case 'event':
                return 'Search event type';
            default:
                return 'Search...';
        }
    }

    @bind
    handleSearch(searchValue) {
        this.setState({ searchValue });
    }

    @bind
    setActions(type, searchValue) {
        this.props.setActions(
            <GlobalLeftPanelHeader
                key={type}
                onHide={this.props.closeLeftPanel}
                onClickSettings={this.openSetting}
            />
        );
    }

    @bind
    setFilterBy(id, entityType, classUri) {
        const { setFilterBy, view } = this.props;
        return setFilterBy(getFilterBy(id, entityType, view), view);
    }

    @bind
    setEntityClassChildren(entityUri, data) {
        this.setState((state) => {
            return {
                ...state,
                entityClasses: {
                    ...state.entityClasses,
                    [entityUri]: {
                        ...state.entityClasses[entityUri],
                        ...data
                    }
                }
            };
        });
    }

    @bind
    setClassificationChildren(classUri, data, parentEntityUri) {
        const findClassByUri = (classes, entityUri) => {
            classes.map(cls => {
                if ((cls.children ?? []).length > 0) {
                    findClassByUri(cls.children, entityUri);
                }

                if (cls.uri === classUri && parentEntityUri === entityUri) {
                    cls.children = data;
                }
            });
        };

        this.setState((state) => {
            const entityClasses = JSON.parse(JSON.stringify(state.entityClasses));

            Object.keys(entityClasses).forEach(entityUri => {
                findClassByUri(entityClasses[entityUri].children, entityUri);
            });

            return {
                ...state,
                entityClasses
            };
        });
    }

    @bind
    onSelectItem(data, nodeId, parentUri) {
        const { entityType, isMobile, view, classification } = this.props;
        const { id, uri, applicableOn } = data;
        switch (entityType) {
            case 'event':
                history.push(`/events/eventType/panel/${id}`);
                break;
            case 'entity':
                if(view === 'classEntities') {
                    history.push(`/classifications/${classification.id}/entities/${uri}`);
                } else if (!!applicableOn) {
                    history.push(`/entities/${parentUri}/classification/${id}`);
                } else {
                    history.push(`/entities/${uri}`);
                }
                break;
            case 'task':
                history.push(`/abox/tasks/panel/processDefinition/${id}`);
                break;
            default:
                history.push(`/${redirectTypes[entityType]}/panel/class/${id}`);
        }
        if(isMobile) {
            this.closeLeftPanel();
        }
        this.setState({ selected: nodeId });
    }

    @bind
    async onExpandChild(data, nodeId, parentEntityUri) {
        if (this.state.loadedNodeIds.includes(nodeId)) return;

        this.setState({ isLoadingNodeId: nodeId });

        // load child classes of classification if it has applicableOn else load applicableOn entity types class
        if (!!data.applicableOn) {
            const response = await this.props.loadClassChildrenLeftPanel(data.id, parentEntityUri);
            if (response instanceof Error) return;
            this.setClassificationChildren(data.uri, response?.records || [], parentEntityUri);
        } else {
            const response = await this.props.loadEntityClassesAppliesToLeftPanel(data.uri);
            if (response instanceof Error) return;
            this.setEntityClassChildren(data.uri, { children: response?.records || [] });
        }

        this.setState((state) => {
            const loadedNodeIds = [...state.loadedNodeIds];
            loadedNodeIds.push(nodeId);

            return {
                ...state,
                isLoadingNodeId: null,
                loadedNodeIds
            };
        });
    }

    @bind
    @debounce()
    closeLeftPanel() {
        this.props.closeLeftPanel();
    }

    @bind
    openSetting(e) {
        this.setState({ settingAnchorEl: e.currentTarget });
    }

    @bind
    closeSetting() {
        this.setState({ settingAnchorEl: null });
    }

    @bind
    setDirection(direction) {
        if (direction.includes('asc')) {
            return [{ field: 'name', direction: 'desc nulls last' }];
        }
        return [{ field: 'name', direction: 'asc nulls last' }];
    }

    @bind
    onSettingMenuClick(title, showClasses) {
        const { preferences, saveUserPreferences, setLeftPanelSortBy, location } = this.props;

        switch (title) {
            case 'Sort by name (A-Z)':
                this.setState({
                    orderBy: this.setDirection(get(this.state, 'orderBy.[0].direction'))
                });
                setLeftPanelSortBy(this.setDirection(get(this.state, 'orderBy.[0].direction')) , parseView(location.pathname));
                break;
            case 'Show classes':
                this.setState({ showClasses });
                saveUserPreferences({ ...preferences, leftPanel: { showClasses } });
                break;
            default:
                break;
        }
    }

    @bind
    @memoize()
    filterRecords(records, searchValue, view, classification, orderBy) {
        let nextRecords = [...(records || [])];
        if(view === 'classEntities') {
            const { applicableOn } = classification || {};
            nextRecords = nextRecords.filter(rec => (applicableOn || []).includes(rec.uri));
        }

        nextRecords = nextRecords.sort((a, b) => a.name - b.name);
        if(orderBy[0].direction.includes('desc')) {
            nextRecords = nextRecords.reverse();
        }

        if(!searchValue) return nextRecords;

        return (nextRecords || []).filter(record => get(record, 'name', '').toLowerCase().includes(searchValue.toLowerCase()));
    }

    @bind
    @memoize()
    buildTree(id, entityType, records, pathname, isMobile, entityClasses, selected, showClasses, isLoadingNodeId, loadedNodeIds, view) {
        let nextRecords = records, type;
        const isEntity = entityType === 'entity' && view !== 'classEntities';

        if (isEntity) {
            nextRecords = buildClassChildrens(nextRecords, entityClasses, showClasses);
        }

        switch (entityType) {
            case 'thing':
            case 'person':
            case 'organisation':
            case 'custom':
            case 'entity':
                type = 'entityType';
                break;
            case 'task':
                type = 'process';
                break;
            case 'event':
                type = entityType;
                break;
            default:
                type = 'entityType';
                break;
        }

        return (
            <TreeList
                selected={selected}
                items={nextRecords}
                type={type}
                onItemLabelClick={this.onSelectItem}
                onItemIconClick={showClasses && isEntity && this.onExpandChild}
                isLoadingNodeId={isLoadingNodeId}
                loadedNodeIds={loadedNodeIds}
            />
        );
    }

    @bind
    selectedAllTask(isMobile){
        this.setState({ selected: 'allItems' });
        if(isMobile) {
            this.closeLeftPanel();
        }
    }

    @bind
    renderShowAllItemsLink(path, iconName, iconType, label, isMobile, selected){
        return (
            <StyledLink to={path} onClick={() => this.selectedAllTask(isMobile)} selected={selected}>
                <Icon name={iconName} type={iconType} hexColor={muiTheme.colors.leftSideBar.iconActive}/>
                <TypographyStyled>{label}</TypographyStyled>
            </StyledLink>
        );
    };
    

    render() {
        const { isLoading, records, isOpen, id, entityType, location, isMobile, view, classification, type } = this.props;
        const { searchValue, entityClasses, settingAnchorEl, selected, orderBy, showClasses, isLoadingNodeId, loadedNodeIds } = this.state;
        const filteredRecords = entityType === 'entity' ? this.filterRecords(records, searchValue, view, classification, orderBy) : records;
        const showClassToggle = entityType === 'entity' && view !== 'classEntities';
        const allItemsLink = allItemsLinkConfig?.[type];
        const { path, iconName, iconType, label,} = allItemsLink || {};

        return isOpen && (
            <>
                <Wrapper type={type}>
                    <LeftPanelSearch
                        onSearch={this.handleSearch}
                        searchValue={searchValue}
                    />
                    {allItemsLink && this.renderShowAllItemsLink(path, iconName, iconType, label, isMobile, selected)}              
                </Wrapper>
                {isLoading && <Loader absolute/>}
                {records && this.buildTree(
                    id,
                    entityType,
                    filteredRecords,
                    location.pathname,
                    isMobile,
                    entityClasses,
                    selected,
                    showClasses,
                    isLoadingNodeId,
                    loadedNodeIds,
                    view
                )}
                <NestedMenu
                    items={leftPanelMenu(showClassToggle)}
                    open={Boolean(settingAnchorEl)}
                    onItemClick={this.onSettingMenuClick}
                    anchorEl={settingAnchorEl}
                    onClose={this.closeSetting}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                    }}
                    isSwitchToggled={showClasses}
                />
            </>
        );
    }
}

export default withRouter(connect(
    (state: Object, ownProps: Object) => {
        let isLoading = false;
        let records = [];

        if (ownProps?.entityType === 'entity') {
            isLoading = state.leftPanel.entityTypes.isLoading;
            records = state.leftPanel.entityTypes.data?.records || [];
        } else if (ownProps?.entityType === 'event'){
            isLoading = state.leftPanel.eventTypes.isLoading;
            records = state.leftPanel.eventTypes.data?.records || [];
        }else if(ownProps?.entityType === 'task'){
            isLoading = state.leftPanel.processDefinitions.isLoading;
            records = state.leftPanel.processDefinitions?.data?.records || [];
        }

        return ({
            isLoading,
            records,
            isOpen: state.leftPanel.state.isOpen,
            expanded: state.leftPanel.state.expanded,
            windowWidth: state.global.window.width,
            title: state.leftPanel.state.title,
            actions: state.leftPanel.state.actions,
            content: state.leftPanel.state.content,
            isMobile: state.global.isMobile,
            classification: state.classifications.details.data,
            preferences: state.user.preferences,
            leftPanelSortBy: state.leftPanel.state.sortBy?.[parseView(ownProps?.location?.pathname)]
        });
    },
    {
        saveUserPreferences,
        closeLeftPanel,
        loadLeftPanelDefinitions,
        loadLeftPanelEventTypes,
        loadClassChildrenLeftPanel,
        loadEntityClassesAppliesToLeftPanel,
        loadLeftPanelRecordChildrenCount,
        loadLeftPanelPrimaryClasses,
        setFilterBy,
        shrinkLeftPanel,
        expandLeftPanel,
        setActions,
        setSubTitle,
        setTitle,
        setLeftPanelSortBy,
    },
)(EntityLeftPanel));
