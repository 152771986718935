/* @flow */

import { loadData } from 'app/utils/redux/action-utils';
import { filterActiveClasses } from 'app/utils/classification/classificationUtils';

import classificationsLeftPanelChildCountQuery from 'graphql/entities/classifications/classificationsLeftPanelChildCountQuery';
import definitionssLeftPanelListQuery from 'graphql/designer/definitionssLeftPanelListQuery';
import primaryClassesLeftPanelQuery from 'graphql/entities/classifications/primaryClassesLeftPanelQuery';
import { eventTypesListShortQuery } from 'graphql/stream/event/eventTypeQueries';
import loadBoardsQuery from 'graphql/abox/boards/loadBoardsQuery';
import loadCustomBoardsQuery from 'graphql/abox/boards/loadCustomBoardsQuery';

export const BUILD = '@@affectli/leftpanelActions/BUILD';
export const OPEN = '@@affectli/leftpanelActions/OPEN';
export const CLOSE = '@@affectli/leftpanelActions/CLOSE';
export const EXPAND = '@@affectli/leftpanelActions/EXPAND';
export const SHRINK = '@@affectli/leftpanelActions/SHRINK';

export const SET_TITLE = '@@affectli/leftpanelActions/SET_TITLE';
export const SET_SUBTITLE = '@@affectli/leftpanelActions/SET_SUBTITLE';
export const SET_ACTIONS = '@@affectli/leftpanelActions/SET_ACTIONS';
export const SET_CONTENT = '@@affectli/leftpanelActions/SET_CONTENT';
export const SET_FILTERBY = '@@affectli/leftpanelActions/SET_FILTERBY';
export const SET_SHOW_BACK = '@@affectli/leftpanelActions/SET_SHOW_BACK';
export const RESIZING = '@@affectli/leftpanelActions/RESIZING';

export const LOAD_LEFT_PANEL_CHILD_COUNT_STARTED = '@@affectli/classifications/LOAD_LEFT_PANEL_CHILD_COUNT_STARTED';
export const LOAD_LEFT_PANEL_CHILD_COUNT = '@@affectli/classifications/LOAD_LEFT_PANEL_CHILD_COUNT';

export const LOAD_LEFT_PANEL_BOARDS_STARTED = '@@affectli/classifications/LOAD_LEFT_PANEL_BOARDS_STARTED';
export const LOAD_LEFT_PANEL_BOARDS = '@@affectli/classifications/LOAD_LEFT_PANEL_BOARDS';

export const LOAD_LEFT_PANEL_BETA_BOARDS_STARTED = '@@affectli/classifications/LOAD_LEFT_PANEL_BETA_BOARDS_STARTED';
export const LOAD_LEFT_PANEL_BETA_BOARDS = '@@affectli/classifications/LOAD_LEFT_PANEL_BETA_BOARDS';

export const LOAD_LEFT_PANEL_ENTITY_TYPES_STARTED = '@@affectli/classifications/LOAD_LEFT_PANEL_ENTITY_TYPES_STARTED';
export const LOAD_LEFT_PANEL_ENTITY_TYPES = '@@affectli/classifications/LOAD_LEFT_PANEL_ENTITY_TYPES';

export const LOAD_LEFT_PANEL_PROCESS_DEFINITIONS_STARTED = '@@affectli/classifications/LOAD_LEFT_PANEL_PROCESS_DEFINITIONS_STARTED';
export const LOAD_LEFT_PANEL_PROCESS_DEFINITIONS = '@@affectli/classifications/LOAD_LEFT_PANEL_PROCESS_DEFINITIONS';

export const LOAD_LEFT_PANEL_EVENT_TYPES_STARTED = '@@affectli/classifications/LOAD_LEFT_PANEL_EVENT_TYPES_STARTED';
export const LOAD_LEFT_PANEL_EVENT_TYPES = '@@affectli/classifications/LOAD_LEFT_PANEL_EVENT_TYPES';

export const SET_LEFT_PANEL_SORT_BY = '@@affectli/classifications/SET_LEFT_PANEL_SORT_BY';

// when we open the leftpanelActions must be "not expanded"
export const buildLeftPanel = ({ view, title = '', subTitle = '', actions, content, isOpen, filterBy = [] }) => (dispatch, getState) => {
    // for excluding undefined props
    const payload = { title, subTitle, actions, content, isOpen, filterBy: {
        [view]: filterBy
    }};
    dispatch({ type: BUILD, payload: {...payload} });
};


export const openLeftPanel = () => (dispatch, getState) => {
    dispatch({ type: OPEN });
};

export const setShowBack = showBack => (dispatch, getState) => {
    dispatch({ type: SET_SHOW_BACK, payload: showBack });
};

export const closeLeftPanel = () => (dispatch, getState) => {
    dispatch({ type: CLOSE });
};

export const expandLeftPanel = () => (dispatch, getState) => {
    dispatch({ type: EXPAND });
};

export const shrinkLeftPanel = () => (dispatch, getState) => {
    dispatch({ type: SHRINK });
};

export const setTitle = (title: string) => (dispatch, getState) => {
    dispatch({ type: SET_TITLE, payload: title });
};

export const setSubTitle = (subTitle: string) => (dispatch, getState) => {
    dispatch({ type: SET_SUBTITLE, payload: subTitle });
};

export const setActions = (actions: any) => (dispatch, getState) => {
    dispatch({ type: SET_ACTIONS, payload: actions });
};

export const setContent = (content: any) => (dispatch, getState) => {
    dispatch({ type: SET_CONTENT, payload: content });
};

export const setFilterBy = (filterBy: any, view: string) => (dispatch, getState) => {
    dispatch({ type: SET_FILTERBY, payload: {
        [view]: filterBy
    } });
};

export const resizeLeftPanel = (isResizing: boolean) => (dispatch, getState) => {
    dispatch({ type: RESIZING, payload: isResizing });
};

/**
 * Load classes for DataTable
 *
 * @param options the options ({ page, pageSize, countMax, where, orderBy, download })
 */
export const loadLeftPanelDefinitions = (options = [], orderBy = []) =>
    loadData(LOAD_LEFT_PANEL_PROCESS_DEFINITIONS_STARTED, LOAD_LEFT_PANEL_PROCESS_DEFINITIONS, definitionssLeftPanelListQuery)({
        filterBy: [
            { field: 'active', op: '=', value: true },
            { field: 'primary.lastVersion', op: 'is not null' },
            { or: [
                { field: 'primary.hidden', op: 'is null' },
                { field: 'primary.hidden', op: '=', value: 'false' }
            ]},
            ...options,
        ],
        orderBy
    });

export const loadLeftPanelEventTypes = (options = [], orderBy = [{ field: 'name', direction: 'asc' }]) => {
    return loadData(LOAD_LEFT_PANEL_EVENT_TYPES_STARTED, LOAD_LEFT_PANEL_EVENT_TYPES, eventTypesListShortQuery)({
        filterBy: [
            ...options,
        ],
        orderBy
    });
};

export const loadLeftPanelPrimaryClasses = loadData(LOAD_LEFT_PANEL_ENTITY_TYPES_STARTED,
    LOAD_LEFT_PANEL_ENTITY_TYPES, primaryClassesLeftPanelQuery, filterActiveClasses);

export const loadLeftPanelRecordChildrenCount = (classId) => {
    const modifyPayload = payload => ({
        parentId: classId,
        count: payload.count
    });
    return loadData(
        LOAD_LEFT_PANEL_CHILD_COUNT_STARTED,
        LOAD_LEFT_PANEL_CHILD_COUNT,
        classificationsLeftPanelChildCountQuery,
        modifyPayload
    )({
        filterBy: [{ field: 'parents.id', op: 'in', value: [classId] }],
    });
};

export const loadLeftPanelBoards = (options = []) => 
    loadData(LOAD_LEFT_PANEL_BOARDS_STARTED, LOAD_LEFT_PANEL_BOARDS, loadCustomBoardsQuery)({
        filterBy: [
            { field: 'active', op: '=', value: true },
            { field: 'classes.uri', op: '=', value: 'board' },
            ...options,
        ].filter(Boolean)
    });

export const loadLeftPanelBetaBoards = (options = []) => 
    loadData(LOAD_LEFT_PANEL_BETA_BOARDS_STARTED, LOAD_LEFT_PANEL_BETA_BOARDS,loadBoardsQuery )({
        filterBy: [
            { field: 'active', op: '=', value: true },
            ...options,
        ].filter(Boolean)
    });

export const setLeftPanelSortBy = (sortBy: String, view: String) => (dispatch, getState) => {
    dispatch({ type: SET_LEFT_PANEL_SORT_BY, payload: { [ view ] : sortBy } });
};
