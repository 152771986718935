/* @flow */

import { gql } from '@apollo/client';
import commonProcessDetailsQueryFields from 'graphql/abox/process/commonProcessDetailsQueryFields';

export default gql`
    mutation updateProcessMutation($record: ProcessUpdateInput) {
        result: updateProcess(record: $record) {
          ${commonProcessDetailsQueryFields}
        }
    }
`;
