import { getToken } from 'firebase/messaging';
import { pushNotificationAddToken, pushNotificationDelToken } from 'graphql/app/pushNotificationQueries';
import { graphql } from 'graphql/client';

export const setNotificationToken = (token) => {
    localStorage.setItem('notificationToken', token);
};

export const removeNotificationToken = () => {
    localStorage.removeItem('notificationToken');
};


export const getNotificationToken = () => {
    const token = localStorage.getItem('notificationToken');
    return token;
};

export const subscribeNotifications = async (token) => {
    if(!token) return;
    try {
        const res = await graphql.mutate({
            mutation:pushNotificationAddToken,
            variables:{ token }
        });
        setNotificationToken(token);
        return res;
    }catch(e) {
        // eslint-disable-next-line
        console.log('$$$ ~ subscribeNotifications ~ e:', e);
        return e;
    }
};

export const unsubscribeNotifications = async () => {
    const token = getNotificationToken();
    if(!token) return;
    try {
        const res = await graphql.mutate({
            mutation:pushNotificationDelToken,
            variables:{ token }
        });
        removeNotificationToken();
        return res;
    }catch(e) {
        // eslint-disable-next-line
        console.log('$$$ ~ unsubscribeNotifications ~ e:', e);
        return e;
    }
};

export const unregisterServiceWorker = () => {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations().then(registrations => {
            registrations.forEach(registration => {
                registration.unregister().then(success => {
                    if (success) {
                        console.log('Service Worker unregistered successfully.'); // eslint-disable-line
                    } else {
                        console.log('Service Worker failed to unregister.'); // eslint-disable-line
                    }
                }).catch(error => {
                    console.error('Error unregistering service worker:', error); // eslint-disable-line
                });
            });
        }).catch(error => {
            console.error('Error getting service worker registrations:', error); // eslint-disable-line
        });
    } else {
        console.log('Service Workers are not supported in this browser.'); // eslint-disable-line
    }
};

export  const requestNotificationPermission = async (messaging, vapidKey) => {
    if (!('Notification' in window)) {
        //eslint-disable-next-line
        console.log('This browser does not support notifications.');
        return Promise.resolve();
    }

    // Check the current permission status
    let permission = Notification.permission;

    // If permission is 'default', request permission
    if (permission === 'default') {
        permission = await Notification.requestPermission();
    }

    if (permission === 'granted') {
        const serviceWorkerRegistration = await navigator.serviceWorker.getRegistration('sw.js');
        if(serviceWorkerRegistration) {
            const token = await getToken(messaging, { vapidKey, serviceWorkerRegistration });
            // Subscribe or handle token as needed
            const res = await subscribeNotifications(token);
            const status = res?.data?.pushNotificationAddToken;
            if (typeof status === 'boolean') {
                if (status) {
                console.log('Push notifications subscribed successfully'); // eslint-disable-line
                } else {
                console.log('Push notifications already subscribed'); // eslint-disable-line
                }
            }
        } else {
            console.log('push-notification: No active service worker found') // eslint-disable-line
        }
    }

    if (permission === 'denied') {
        console.log('🚀 ~ requestNotificationPermission ~ permission:', permission); // eslint-disable-line
    }
    return permission; // this will return the string `granted` or `denied`

};
