/* @flow */

import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import { RouterMatchPropTypeBuilder } from 'app/utils/propTypes/common';
import RelationDefinitionDetails from 'app/containers/Entities/RelationDefinitions/RelationDefinitionDetails';
import RelationDefinitionsListView from 'app/containers/Entities/RelationDefinitions/RelationDefinitionsListView';
/**
 * Defines the routes for the Relation Definitions
 */
class RelationDefinitionsRoutes extends PureComponent<Object, Object> {
    static propTypes = {
        match: RouterMatchPropTypeBuilder({ id: PropTypes.string }),
        userProfile: PropTypes.object
    };

    /**
     * @override
     * Always make sure you use PageTemplate as PageContainer
     */
    render() {
        const { match } = this.props;
        return (
            <Fragment>
                <Switch>
                    <Route path={`${match.url}`} exact component={RelationDefinitionsListView} />
                    <Route path={`${match.url}/:id`} component={RelationDefinitionDetails} />
                </Switch>
            </Fragment>
        );
    }
}

export default connect(state => ({
    userProfile: state.user.profile
}))(RelationDefinitionsRoutes);
