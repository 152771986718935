/* @flow */

import mustache from 'mustache';

import { DATETIME_FORMAT, DATE_FORMAT, TIME_FORMAT, formatDate } from 'app/utils/date/date';

mustache.escape = (value) => {
    if (value === null || value === undefined) {
        return '';
    } else if (typeof value === 'object') {
        return JSON.stringify(value);
    }
    return String(value);
};

const fixLegacyTemplate = (template: string = '') => {
    let updatedTemplate = template;
    (template.match(/\{\{([^{}]*)\}\}/g) || [])
        .forEach((placeholder) => {
            const token = placeholder.substring(2, placeholder.length - 2);
            if(token.includes(':')) {
                const [path, ...modifiers] = token.split(':');
                if(modifiers.length === 1) {
                    const modifier = modifiers[0].toUpperCase();
                    updatedTemplate = updatedTemplate.replaceAll(placeholder, `{{#fn.${modifier}}}{{${path}}}{{/fn.${modifier}}}`);
                } else if(modifiers.length > 1) {
                    updatedTemplate = updatedTemplate.replaceAll(
                        placeholder,
                        modifiers.reduce((accum, modifierLower, indx) => {
                            const modifier = modifierLower.toUpperCase();
                            if(indx === 0) {
                                accum = `{{#fn.${modifier}}}{{${path}}}{{/fn.${modifier}}}`;
                            } else {
                                accum = `{{#fn.${modifier}}}${accum}{{/fn.${modifier}}}`;
                            }
                            return accum;
                        }, '')

                    );
                }
            }
        });
    return updatedTemplate;
};


export const compile = (template: string = '') => {
    return fixLegacyTemplate(template);
};

export const render = (
    compiled,
    context: Object
) => {
    return mustache.render(compiled, {
        ...context,
        fn: {
            UPPER: function () {
                return function (value, render) {
                    return value ? render(value).toUpperCase() : '';
                };
            },
            LOWER: function () {
                return function (value, render) {
                    return value ? render(value).toLowerCase() : '';
                };
            },
            CAPITAL: function () {
                return function (value, render) {
                    const text = render(value);
                    return text ? text.length > 0
                        ? `${text[0].toUpperCase()}${text.substring(1)}`
                        : text : '';
                };
            },
            DATETIME: function () {
                return function (value, render) {
                    const text = render(value);
                    return text && formatDate(text, DATETIME_FORMAT);
                };
            },
            DATE: function () {
                return function (value, render) {
                    const text = render(value);
                    return text && formatDate(text, DATE_FORMAT);
                };
            },
            TIME: function () {
                return function (value, render) {
                    const text = render(value);
                    return text && formatDate(text, TIME_FORMAT);
                };
            },

        }
    });
};
