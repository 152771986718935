// @flow

import React from 'react';
import clsx from 'clsx';
import {  MdiIcon } from '@mic3/platform-ui';
import IconBase from 'app/components/atoms/Icon/Icon';

import { useIconStyles } from './AppNavIcon.style';

type Props = {
    icon: string,
    hexColor?: string,
    className?: Object,
    iconType: "af" | "mdi"
};

const AppNavIcon = ({ icon, iconType, hexColor, className, ...otherProps }: Props) => {
    const classes = useIconStyles();
    const iconClass = clsx(classes.root, className, `${iconType} ${icon}`);
    const mdiIconClass = clsx(classes.root, classes.mdiRoot, classes.mdiIcon);
    return (
        <>
            {iconType === 'af' ? (
                <IconBase 
                    {...otherProps} 
                    name={icon} 
                    type={iconType} 
                    className={iconClass}
                    hexColor={hexColor} 
                />
            ) : (
                <MdiIcon
                    {...otherProps}
                    name={icon}
                    className={mdiIconClass}
                    color={hexColor}
                />
            )}
        </>
    );
};

AppNavIcon.defaultProps = {
    iconType: 'mdi'
};

export default AppNavIcon;
