// @flow
import { gql } from '@apollo/client';
import { graphql } from 'graphql/client';
import { loadData, mutateData, queryData } from 'app/utils/redux/action-utils';
import loadPinDetailsQuery from 'graphql/maps/loadPinDetailsQuery';
import loadPinRelationshipsQuery from 'graphql/maps/loadPinRelationshipsQuery';
import loadMapDetailQuery from 'graphql/maps/loadMapDetailQuery';
import loadEntityLayerPinsQuery from 'graphql/maps/loadEntityLayerPinsQuery';
import loadEntitiesForRelationshipsQuery from 'graphql/maps/loadEntitiesForRelationshipsQuery';
import loadClassDefinitionQuery from 'graphql/maps/loadClassDefinitionQuery';
import entityLocationHistoryQuery from 'graphql/maps/entityLocationHistoryQuery';
import tasksQuery from 'graphql/abox/task/tasksQuery';
import loadProcessesQuery from 'graphql/maps/loadProcessesQuery';
import deleteEntity3dModelMutation from '/graphql/maps/deleteEntity3dModelMutation';
import deleteClass3dModelMutation from '/graphql/maps/deleteClass3dModelMutation';
import { transform } from 'ol/proj.js';
import greenlet from 'greenlet';
import { enrichContext } from 'app/utils/designer/form/formUtils';
import { __getMapLayers, getLayersAndStyles } from 'app/utils/maps/layer/layerUtils';
import { addRoleToData } from 'app/config/rolesConfig';
import updateEntityMutation from  'graphql/entities/entities/updateEntityMutation';
import { get } from 'app/utils/lo/lo';
import eventsQuery from 'graphql/maps/loadMapsEventsQuery';
import projectionListQuery from 'graphql/maps/loadProjectionListQuery';
import loadEntityQuery from 'graphql/entities/entities/loadEntityQuery';
import cesiumLayerConfig from 'app/config/cesiumLayerConfig';
import Immutable from 'app/utils/immutable/Immutable';
import { classDetailsFields } from 'graphql/entities/classifications/classificationDetailQuery';
import { isBoolean } from 'app/utils/utils';
import { fetchMapRelations } from 'store/actions/entities/relationshipsActions';
import { isValidGltf, modifyGeoserverUrl } from 'app/utils/maps/layer/layerUtils';
import  { LOAD_CLASSIFICATION_STARTED, LOAD_CLASSIFICATION }  from 'store/actions/classifications/classificationsActions.js';
import { workspaceFields } from 'graphql/entities/entities/loadEntityWorkspacesQuery';
import { set } from 'app/utils/immutable/Immutable';
import { typeMap } from 'app/config/typesConfig';


export const ADD_FEATURE_TO_HISTORY: string = '@@affectli/maps/ADD_FEATURE_TO_HISTORY';
export const REMOVE_FEATURE_FROM_HISTORY: string = '@@affectli/maps/REMOVE_FEATURE_FROM_HISTORY';
export const SET_STROKE_WIDTH: string = '@@affectli/maps/SET_STROKE_WIDTH';
export const SET_STROKE_COLOR: string = '@@affectli/maps/SET_STROKE_COLOR';
export const PIN_DETAILS_STARETD: string = '@@affectli/maps/PIN_DETAILS_STARETD';
export const PIN_DETAILS: string = '@@affectli/maps/PIN_DETAILS';
export const PIN_ATTACHMENTS_STARETD: string = '@@affectli/maps/PIN_ATTACHMENTS_STARETD';
export const PIN_ATTACHMENTS: string = '@@affectli/maps/PIN_ATTACHMENTS';
export const PIN_RELATIONSHIPS_STARETD: string = '@@affectli/maps/PIN_RELATIONSHIPS_STARETD';
export const PIN_RELATIONSHIPS: string = '@@affectli/maps/PIN_RELATIONSHIPS';
export const RESET_MAP_DATA: string = '@@affectli/maps/RESET_MAP_DATA';
export const ADD_NEW_LAYER: string = '@@affectli/maps/ADD_NEW_LAYER';
export const UPDATE_MAP_LAYER: string = '@@affectli/maps/UPDATE_MAP_LAYER';
export const REMOVE_LAYER: string = '@@affectli/maps/REMOVE_LAYER';
export const TOGGLE_LAYER_FLITERS: string = '@@affectli/maps/TOGGLE_LAYER_FLITERS';
export const RESET_ALL_LAYERS: string = '@@affectli/maps/RESET_ALL_LAYERS';
export const DISABLE_MAP_SEARCH: string = '@@affectli/maps/DISABLE_MAP_SEARCH';
export const ENABLE_MAP_SEARCH: string = '@@affectli/maps/ENABLE_MAP_SEARCH';
export const LOAD_MAP_LAYER_FILTERS_MENU: string = '@@affectli/maps/LOAD_MAP_LAYER_FILTERS_MENU';
export const SET_LAYER_TYPES: string = '@@affectli/maps/SET_LAYER_TYPES';
export const LOAD_MAP_DATA_STARTED: string = '@@affectli/maps/LOAD_MAP_DATA_STARTED';
export const LOAD_MAP_DATA: string = '@@affectli/maps/LOAD_MAP_DATA';
export const SET_SELECTED_LAYER: string = '@@affectli/maps/SET_SELECTED_LAYER';
export const SET_MAP_HAS_DRAWING: string = '@@affectli/maps/SET_MAP_HAS_DRAWING';
export const UPDATE_LAYER_ATTRIBUTE: string = '@@affectli/maps/UPDATE_LAYER_ATTRIBUTE';
export const UPDATE_BASE_LAYER: string = '@@affectli/maps/UPDATE_BASE_LAYER';
export const TOGGLE_FULLSCREEN_MAP: string = '@@affectli/maps/TOGGLE_FULLSCREEN_MAP';
export const REFRESH_MAP_SIDEBAR_LAYERS: string = '@@affectli/maps/REFRESH_MAP_SIDEBAR_LAYERS';
export const REFRESH_MAP_LAYERS_DRAWER: string = '@@affectli/maps/REFRESH_MAP_LAYERS_DRAWER';
export const UPLOAD_3D_MODEL_FILE_STARTED = '@@affectli/entities/UPLOAD_3D_MODEL_FILE_STARTED';
export const UPLOAD_3D_MODEL_FILE = '@@affectli/entities/UPLOAD_3D_MODEL';
export const UPLOAD_GEOMETRY_FILE_STARTED = '@@affectli/maps/UPLOAD_GEOMETRY_FILE_STARTED';
export const UPLOAD_GEOMETRY_FILE = '@@affectli/maps/UPLOAD_GEOMETRY_FILE';
export const SET_ADD_PIN_MODAL: string = '@@affectli/maps/SET_ADD_PIN_MODAL';
export const DELETE_3D_FILE_STARTED = '@@affectli/maps/DELETE_3D_FILE_STARTED';
export const DELETE_3D_FILE = '@@affectli/maps/DELETE_3D_FILE';
export const ENABLE_RELATION_COUNTER = '@@affectli/maps/ENABLE_RELATION_COUNTER';
export const DISABLE_RELATION_COUNTER = '@@affectli/maps/DISABLE_RELATION_COUNTER';
export const TOGGLE_ADD_PIN_BUTTON = '@@affectli/maps/TOGGLE_ADD_PIN_BUTTON';
export const SET_LAYER_LOADING = '@@affectli/maps/SET_LAYER_LOADING';
export const SET_ALL_LIST_ITEM_LOADING = '@@affectli/maps/SET_ALL_LIST_ITEM_LOADING';
export const SET_LAYER_ENTITIES_HIDDEN = '@@affectli/maps/SET_LAYER_ENTITIES_HIDDEN';
export const SAVE_LAYER_FEATURES = '@@affectli/maps/SAVE_LAYER_FEATURES';
export const SHOW_REPLAY_LAYER_COMPONENT = 'SHOW_REPLAY_LAYER_COMPONENT';

export const loadEntitiesPins = (type: string, variables: Object = {}, fields: Array<string>) => {
    return queryData(loadEntityLayerPinsQuery(type, fields), variables);
};

export const loadTasks = (variables: Object = []) => {
    return queryData(tasksQuery, variables);
};

export const loadProcesses = (variables: Object = []) => {
    return queryData(loadProcessesQuery, variables);
};

export const loadEventLayerPins = (variables: Object = {}) => {
    return queryData(eventsQuery, variables);
};

export const loadEntitiesRelationsPins = (variables: Object = {}) => {
    return queryData(loadEntitiesForRelationshipsQuery, variables);
};

export const loadClassFormDefinition = (id: Number) => queryData(loadClassDefinitionQuery, { id });

const to4326 = (coordinate: Array<number>) => {
    return transform([parseFloat(coordinate[0]), parseFloat(coordinate[1])], 'EPSG:3857', 'EPSG:4326');
};

export const getGeoserverInfo = async (url: string) => {
    try {
        const updatedUrl = modifyGeoserverUrl(url);
        if (updatedUrl) {
            const response = await fetch(updatedUrl);
            if (response.ok) {
                const text = await response.text();
                const parser = new DOMParser();
                const xmlData = parser.parseFromString(text, 'application/xml');
                const data = getLayersAndStyles(xmlData);
                return data;
            } else {
                return { status: response.status, msg: response.statusText }; // Return status and error message
            }
        } else {
            throw new Error('Please enter a valid url.');
        }
    } catch (error) {
        return { msg: error.message };
    }
};

export const getAddressFromCoords = (coords: Array<number>) => {
    return fetch('http://nominatim.openstreetmap.org/reverse?format=json&lon=' + coords[0] + '&lat=' + coords[1])
        .then(response => response.json());
};

export const getNearest = (coordinate: Array<number>): Promise<?any> => {
    const coord4326 = to4326(coordinate);
    return new Promise((resolve, reject) => {
        //make sure the coord is on street
        const response = fetch(`//router.project-osrm.org/nearest/v1/driving/${coord4326.join()}`);
        response
            .then(response => response.json())
            .then((json) => {
                if (json.code === 'Ok') resolve(json.waypoints[0].location);
                else reject();
            });
        response.catch(() => reject());
    });
};

export const getRoute = (start: any, end: any) => {
    return fetch(`//router.project-osrm.org/route/v1/driving/${start};${end}`);
};

export const loadPinDetails = (type: ?string, id: string | string) => {
    if (type) {
        id = String(id);
    }
    const variables = { id };
    return loadData(PIN_DETAILS_STARETD, PIN_DETAILS, loadPinDetailsQuery(type))(variables);
};

export const loadPinRelationships = (entityType: string, entityId: number | string) => {
    const variables = { entityType, entityId, startIndex: 0, stopIndex: 10 };
    return loadData(PIN_RELATIONSHIPS_STARETD, PIN_RELATIONSHIPS, loadPinRelationshipsQuery)(variables);
};

export const updateLayerAttribute = (layer: string, keyToUpdate, value = 'toggleValue') => (dispatch: Function, getState: Function) => {
    const { id, name, title } = layer || {};
    try {
        const mapData = getState().maps.situationalAwareness.map.data;
        if(!mapData) return;
        const layers = __getMapLayers(mapData);
        if (!layers?.length) return;
        let updatedLayer = {};
        const updatedLayers = layers.map(lyr => {
            if (lyr?.id === id || lyr?.name === name || lyr?.name === title) {
                updatedLayer = set(lyr, keyToUpdate, value === 'toggleValue' ? !lyr[keyToUpdate]: value);
                return updatedLayer;
            }
            return { ...lyr };
        });
        dispatch({ type: UPDATE_LAYER_ATTRIBUTE, payload: updatedLayers });
        return updatedLayer;
    } catch (error) {
        console.error('Maps: Error while updating layer attribute ', error) // eslint-disable-line
    }
    
};

export const updateBaseLayer = (title) => (dispatch: Function, getState: Function) => {
    dispatch({ type: UPDATE_BASE_LAYER, payload: title });
};

export const setMapHasDrawing = (hasDrawing: boolean) => (dispatch: Function) => {
    dispatch({ type: SET_MAP_HAS_DRAWING, payload: hasDrawing });
};

export const setSelectedLayer = (layer: object) => (dispatch: Function) => {
    dispatch({ type: SET_SELECTED_LAYER, payload: layer });
};

export const setStrokeColor = (strokeColor: Object) => (dispatch: Function) => {
    dispatch({ type: SET_STROKE_COLOR, payload: strokeColor });
};

export const addFeaturesToHistory = (feature: Object) => (dispatch: Function) => {
    dispatch({ type: ADD_FEATURE_TO_HISTORY, payload: feature });
};

export const removeFeaturesFromHistory = () => (dispatch: Function) => {
    dispatch({ type: REMOVE_FEATURE_FROM_HISTORY });
};

export const setStrokeWidth = (feature: Object) =>  (dispatch: Function) => {
    dispatch({ type: SET_STROKE_WIDTH, payload: feature });
};

export const resetAllLayers = () => (dispatch: Function) => {
    dispatch({ type: RESET_ALL_LAYERS });
};

export const toggleLayerFilter = (layer: Object) => (dispatch: Function) => {
    dispatch({ type: TOGGLE_LAYER_FLITERS, payload: layer });
};

export const toggleFullScreenMap = (value: boolean) => (dispatch: Function) => {
    dispatch({ type: TOGGLE_FULLSCREEN_MAP, payload: value });
};

export const layerService = (serviceDefinition, options) => {
    return greenlet(`(context, options) => {
        context.graphql = eval(context.graphql);
        try {
          return (${serviceDefinition})(context, options);
        } catch(e) {
          console.error('an error occured executing the user defined map service function', ${serviceDefinition}, e);
          throw new Error('an error occured executing the user defined map service function.');
        }
    }`)(enrichContext(), options);
};

export const getLayerFiltersMenu = ({ title, filtersMenuDefinition, options = {} }: Object) => (dispatch: Function) => {
    greenlet(`(context, options) => {
        context.graphql = eval(context.graphql);
        try {
          return (${filtersMenuDefinition})(context, options);
        } catch(e) {
          console.error('an error occured executing the user defined map service function', ${filtersMenuDefinition}, e);
          throw new Error('an error occured executing the user defined map service function.');
        }
    }`)(enrichContext(), options).then((data) => {
        dispatch(loadLayerFiltersMenu({ title, data }));
    });
};


export const loadLayerFiltersMenu = ({ title, data }: Object) => (dispatch: Function) => {
    dispatch({ type: LOAD_MAP_LAYER_FILTERS_MENU, payload: { title, data }});
};


export const toggleMapSearch = (isDisabled: boolean) => (dispatch: Function) => {
    if (isDisabled) {
        dispatch(disableMapSearch());
    } else {
        dispatch(enableMapSearch());
    }
};

export const enableMapSearch = () => (dispatch: Function) => {
    dispatch({ type: ENABLE_MAP_SEARCH });
};

export const disableMapSearch = () => (dispatch: Function) => {
    dispatch({ type: DISABLE_MAP_SEARCH });
};

export const resetMapData = () => (dispatch: Function) => {
    dispatch({ type: RESET_MAP_DATA });
};

export const addNewLayer = (layer) => (dispatch: Function) => {
    dispatch({ type: ADD_NEW_LAYER, payload: layer });
};

export const updateMapLayer = (layer) => (dispatch: Function) => {
    dispatch({ type: UPDATE_MAP_LAYER, payload: layer });
};

export const removeLayer = (layer) => (dispatch: Function) => {
    dispatch({ type: REMOVE_LAYER, payload: layer });
};

export const loadMapData = (id: string, type: string) => {
    return loadData(LOAD_MAP_DATA_STARTED, LOAD_MAP_DATA, loadMapDetailQuery, addRoleToData)({ id, type });
};

export const updateMapData = (payload: Object, refreshCache = false) => {
    const { id } = payload;
    let record = { id, type: typeMap['map'] };
    const keysToCheck = ['name', 'primary', 'svgTransformation', 'description', 'active'];
    record = keysToCheck.reduce((acc, key) => {
        if (key in payload) {
            acc[key] = payload[key];
        }
        return acc;
    }, record);

    return mutateData(
        refreshCache ? LOAD_MAP_DATA_STARTED : '@@Maps/UPDATE_MAP_DATA_STARTED',
        refreshCache ? LOAD_MAP_DATA : '@@Maps/UPDATE_MAP_DATA',
        updateEntityMutation,
        'Map has been updated.',
        addRoleToData
    )({ record });
};

export const refreshMapSideBarLayers = (value: boolean) => (dispatch: Function) => {
    dispatch({ type: REFRESH_MAP_SIDEBAR_LAYERS, payload: value });
};

export const loadEntityLocationHistory = (variables: Object)  =>  {
    return queryData(entityLocationHistoryQuery,variables);
};

export const loadProjectionList = () => queryData(projectionListQuery);

export const loadEntityDetails = async (id: string, type: String) => {
    try {
        const data = await queryData(loadEntityQuery, ({ id, type }));
        return data?.data?.result;
    } catch (error) {
        return {};
    }
};

export const refreshMapLayersDrawer = () => (dispatch: Function) => {
    dispatch({ type: REFRESH_MAP_LAYERS_DRAWER });
};

export const loadEntity = async (id: string) => {
    const response = await graphql.query({
        query: gql`query loadCesiumTokenData($id: ID!) {
                result: entity(type: "${cesiumLayerConfig?.cesiumAccount?.type || cesiumLayerConfig?.cesiumAccount?.oldType}", id: $id) { id name }
            }`,
        variables: { id },
        fetchPolicy: 'no-cache'
    });
    if (!(response instanceof Error)) {
        return response;
    }

};

export const updateEntity3dModelMutation = (data: Object)  => {
    const { id, name, model, type, isClass } = data;
    const variables = { id, name, model3d: model, type };
    let query = '';
    if(isClass) {
        query = `mutation ($id: ID!, $model3d: Upload!) { result: updateClassEntity3dModel(id: $id, model3d: $model3d) { ${classDetailsFields} }}`;
    } else {
        query = 'mutation ($type: String!, $id: ID!, $model3d: Upload!) { result: updateEntity3dModel(type: $type, id: $id, model3d: $model3d) { id }}';
    }
    return graphql
        .upload({ query, variables, file: model, map: 'variables.model3d' });
};

export const updateEntity3dModel = (entityType: string, entityId: string, model: File, 
    isClass: boolean = false) => async (dispatch: Function): Promise<Object> => {
    if (!entityId  || !model) {
        throw new Error(`Cannot upload the model: a required parameter is missing: id=${entityId}, type=${entityType}, model=${String(model)}`);
    }
    const startAction = isClass ? LOAD_CLASSIFICATION_STARTED : UPLOAD_3D_MODEL_FILE_STARTED;
    const endAction =  isClass ? LOAD_CLASSIFICATION : UPLOAD_3D_MODEL_FILE;
    const splittedArray = model?.name?.split('.');
    const modelExt = splittedArray[splittedArray.length - 1];

    const checkModel = await isValidGltf(model, modelExt);
    if(checkModel?.severity === 'error') {
        return dispatch({ type: endAction, payload: checkModel?.detail, error: true, meta: Immutable({ errorMessage: checkModel?.detail}) });
    }

    if (!['gltf','glb'].includes(modelExt)) {
        dispatch({
            type: UPLOAD_3D_MODEL_FILE,
            payload: new Error('Upload failed: the specified file is not a model.'),
            error: true,
            meta: Immutable({
                errorTitle: 'Uploading model failed',
                errorMessage: `The file "${model.name}" is not a model.`
            }),
        });
        const error = new Error(`The file "${model.name}" is not a model.`);
        return Promise.reject(error);
    }
    
    dispatch({ type: startAction, meta: Immutable({ entityId, entityType }) });
    const data = { id: entityId, model, type: entityType, isClass };
    return updateEntity3dModelMutation(data)
        .then(async (response: Object) => {
            const data = await addRoleToData({...(response?.data?.result || {}), type: 'class'});
            dispatch({
                type: endAction,
                payload: Immutable(data),
                meta: Immutable({ entityId, entityType, successMessage: 'Model uploaded.' }),
            });
        }).catch((error) => {
            dispatch({ type: endAction, payload: error, error: true, meta: Immutable({ entityId, entityType, errorMessage: 'Model upload failed.' }) });
            return error;
        });
};

export const uploadGeometryFile = (type: string, id: string, file: File, srid: int) => (dispatch: Function) => {
    dispatch({ type: UPLOAD_GEOMETRY_FILE_STARTED });
    const query = `
    mutation ($type: String!, $id: ID!, $file: Upload!, $srid: Int) {
      uploadEntityGeometry(type: $type, id: $id, file: $file, srid: $srid) { id }
    }`;
    const variables = { type, id, file: null, srid };
    return graphql.upload({ query, variables, file, map: 'variables.file' })
        .then((response: Object) => {
            dispatch({
                type: UPLOAD_GEOMETRY_FILE,
                payload: Immutable(response),
                meta: Immutable({ successMessage: 'Geometry updated.' }),
            });
            return response;
        })
        .catch((error: Object) => {
            dispatch({ type: UPLOAD_GEOMETRY_FILE, payload: error, error: true, meta: Immutable({ errorMessage: 'Geometry update failed.' }) });
        });
};

export const setAddPinModal = (show) => (dispatch: Function, getState) => {
    const payload = typeof show === 'boolean' ? show : !getState().maps.situationalAwareness.layer.showAddPinModal;
    dispatch({ type: SET_ADD_PIN_MODAL, payload });
};

export const toggleAddPinBtn = (show, isErase=false) => (dispatch: Function, getState) => {
    let payload;
    if (isBoolean(show)) {
        payload = show;
    } else {
        payload = !getState().maps.situationalAwareness.layer.showAddPinBtn;
    }
    dispatch({ type: TOGGLE_ADD_PIN_BUTTON, payload });
    !payload && !isErase && dispatch(removeCreatePinInteraction()); // if add pin button is hidden and drawing layer is not selected with erase icon click then remove the cursor from maps
};

export const removeCreatePinInteraction = () => (dispatch: Function) => { // It will reset the map cursor and hide the add pin modal
    dispatch(setAddPinModal(false));
    const mapDiv = document.getElementById('map');
    if(mapDiv) mapDiv.style.cursor = null;
};

export const deleteEntity3dModel = (data: Object) => {
    const { id, type, isClass } = data;
    if(isClass) {
        return mutateData(LOAD_CLASSIFICATION_STARTED, LOAD_CLASSIFICATION, deleteClass3dModelMutation, '3D  model removed successfully', payload => addRoleToData({ ...payload, type: 'class'}))({ id });  
    }
    return mutateData(DELETE_3D_FILE_STARTED, DELETE_3D_FILE, deleteEntity3dModelMutation, '3D  model removed successfully')({ type, id });
};

export const enableRelationCunter = () => (dispatch: Function) => {
    dispatch({ type: ENABLE_RELATION_COUNTER });
};

export const disableRelationCunter = () => (dispatch: Function) => {
    dispatch({ type: DISABLE_RELATION_COUNTER });
};


export const shareMapLayers = async (mapId, shareToData, shareWith) => {
    const response = await fetchMapRelations({ entityType: 'map', entityId: mapId });
    if (response instanceof Error) return Promise.resolve();
    const { data } = response || {};
    const relations = get(data, 'relations', []);
    if(!relations.length) return Promise.resolve();
    let query = '';
    switch (shareWith) {
        case 'users':
            query = `mutation shareEntityWithUsersMutation($id: ID!, $shareToData: [UserMemberInput]!, $type: String!) {
                result: shareEntityWithUsers(id: $id, userMembers: $shareToData, type: $type) {
                    id
                    type
                }
            }`;
            break;
        case 'teams': 
            query = `mutation shareEntityWithTeamsMutation($id: ID!, $shareToData: [TeamMemberInput]!, $type: String!) {
                result: shareEntityWithTeams(id: $id, teamMembers: $shareToData, type: $type) {
                    type
                    id
                }
            }`;
            break;
        case 'workspaces': 
            query = `mutation shareEntityWithWorkspacesMutation($id: ID!, $shareToData: [ID]!, $type: String!) {
                result: shareEntityWithWorkspaces(id: $id, workspaces: $shareToData, type: $type) {
                    id
                    type
                }
            }`;
            break;
        default:
            break;
    }
    const promises = relations.map((lyr) => {
        const { id, type } = lyr?.relatedEntity || {};
        if(id && type) {
            return graphql.query({
                query: gql`${query}`,
                variables: { id, type, shareToData },
                fetchPolicy: 'no-cache'
            });
        }
        return null;
    }).filter(Boolean);
    return Promise.all(promises);
};



export const unShareMapLayers = async (mapId, shareToData, shareWith) => {
    const response = await fetchMapRelations({ entityType: 'map', entityId: mapId });
    if (response instanceof Error) return Promise.resolve();
    const { data } = response || {};
    const relations = get(data, 'relations', []);
    if(!relations.length) return Promise.resolve();
    let query = '';
    switch (shareWith) {
        case 'users':
            query = `mutation unshareEntityWithUsersMutation($id: ID!, $shareToData: [ID]!, $type: String!) {
                result: unshareEntityWithUsers(id: $id, users: $shareToData, type: $type) {
                    ${ workspaceFields }
                }
            }`;
            break;
        case 'teams': 
            query = `mutation unshareEntityWithTeamsMutation($id: ID!, $shareToData: [ID]!, $type: String!) {
                result: unshareEntityWithTeams(id: $id, teams: $shareToData, type: $type) {
                  ${ workspaceFields }
                }
            }`;
            break;
        case 'workspaces': 
            query = `mutation unshareEntityWithWorkspacesMutation($id: ID!, $shareToData: [ID]!, $type: String!) {
                result: unshareEntityWithWorkspaces(id: $id, workspaces: $shareToData, type: $type) {
                  ${ workspaceFields }
                }
            }`;
            break;
        default:
            break;
    }
    const promises = relations.map((lyr) => {
        const { id, type } = lyr?.relatedEntity || {};
        if(id && type) {
            return graphql.query({
                query: gql`${query}`,
                variables: { id, type, shareToData },
                fetchPolicy: 'no-cache'
            });
        }
        return null;
    }).filter(Boolean);
    return Promise.all(promises);
};

export const setAllLayersLoading = () => (dispatch: Function) => {
    dispatch({ type: SET_ALL_LIST_ITEM_LOADING });
};

export const setLayerLoading = (id) => (dispatch: Function) => {
    if(!id) return;
    dispatch({ type: SET_LAYER_LOADING, payload: id });
};

export const setLayerEntitiesHidden = (id, status) => (dispatch: Function) => {
    dispatch({ type: SET_LAYER_ENTITIES_HIDDEN, payload: { id, status } });
};

export const saveLayerFeatures = (id, features) => (dispatch: Function) => {
    if(!id) return;
    dispatch({ type: SAVE_LAYER_FEATURES, payload: { id, features } });
};

export const enableReplayLayerComponent = (payload) => ({
    type: SHOW_REPLAY_LAYER_COMPONENT,
    payload,
});