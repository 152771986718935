/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import AbstractClassificationPrimaryAbout from 'app/components/Classifications/ClassificationDetailAbout/AbstractClassificationPrimaryAbout';

/**
 * General tab in classifications view.
 * Todo: We probably should extract the form in it's own component, however
 * nearly the only code here is form related.
 */
class ClassificationPrimaryAboutSidebar extends PureComponent<Object, Object> {

    static propTypes: Object = {
        classification: PropTypes.object,
        entityType: PropTypes.bool,
    };

    render() {
        const {entityType} = this.props;
        return (
            <AbstractClassificationPrimaryAbout
                {...this.props}
                openSidebar={this.props.openSidebar}
                closeClassSidebar={this.props.closeClassSidebar}
                entityType={entityType}
                isSidebar
            />
        );
    }
}

export default ClassificationPrimaryAboutSidebar;
