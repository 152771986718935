import React, { PureComponent } from 'react';
import styled from 'styled-components';
import {
    MdiIcon, ListItem, ListItemAvatar, ListItemText, IconButton,
    List, ListItemSecondaryAction, ListItemIcon, Typography,
    Button, Menu, MenuItem } from '@mic3/platform-ui';

import ExpansionCard from 'app/components/molecules/ExpansionCard/ExpansionCard';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import DragNDropContext from 'app/containers/DragNDropContext/DragNDropContext';
import DraggableElement from 'app/components/molecules/Dnd/DraggableElement';
import RowTarget, { EmptyRowTarget } from 'app/components/molecules/Dnd/RowTarget';
import Icon from 'app/components/atoms/Icon/Icon';
import { entityTabsDefault } from 'app/utils/entity/entityUtils';

const ListItemTextStyled = styled(ListItemText)`
    .MuiListItemText-primary {
        font-size: 12px !important;
        color: ${ ( { theme } ) => theme.material.colors.text.primary };
    }
    .MuiListItemText-secondary {
        font-size: 16px !important;
    }
    .MuiTypography-body2 {
        text-align: left;
    }
`;

const MenuStyled = styled(Menu)`
    & *, & .Icon:before {
      color: ${ ( { theme } ) => theme.material.colors.text.primary } !important;
    }
`;
const MenuLabel = styled(Typography)`
    min-width: 150px;
`;

function DefaultMenu({ tabId, tabs, onChange, disabled }) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleChange = (tabId, tab) => {
        handleClose();
        onChange(tabId, tab);
    };
    return (
        <div>
            <Button disabled={disabled} variant="text" startIcon={<MdiIcon name={'check'} />} onClick={handleClick}>
                Default
            </Button>
            <MenuStyled
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {tabs.map(tab => (
                    <MenuItem key={tab.id} onClick={() => handleChange(tabId, tab)}>
                        <ListItemIcon>
                            <Icon name={tab.icon} type={tab.iconType}  />
                        </ListItemIcon>
                        <MenuLabel variant="inherit">{tab.name}</MenuLabel>
                        <ListItemSecondaryAction>
                            <MdiIcon name={Number(tab.id) === tabId ? 'radiobox-marked' : 'radiobox-blank'}  />
                        </ListItemSecondaryAction>
                    </MenuItem>
                ))}
            </MenuStyled>
        </div>
    );
}

class EntityTabsSection extends PureComponent {

    constructor(props) {
        super(props);
        this.state = { 
            entityTabs: this.normalizeTabs(props.details.entityTabs) };
    }

    componentDidUpdate(prevProps){
        const { details } = this.props;
        if(prevProps.details.id !== details.id) {
            this.setState({ entityTabs: this.normalizeTabs(details.entityTabs) });
        }
    }


    @bind
    onDrop(item: Object, index: number) {
        const nextEntityTabs = this.state.entityTabs;
        const [removed] = nextEntityTabs.splice(item.order, 1);
        if(index === 0) {
            removed.checked = true;
        }
        nextEntityTabs.splice(index, 0, removed);
        this.setState({ entityTabs: nextEntityTabs.map((tab, i) => ({ ...tab, order: i })) }, this.updateChanges);
    }

    @bind
    updateChanges() {
        this.props.handleChange({ target: { name:'entityTabs', value: this.deNormalizeTabs(this.state.entityTabs) }});
    }

    @bind
    @memoize()
    normalizeTabs(entityTabs) {
        let list = Object.values(entityTabsDefault);
        let excluded = [];
        if(Array.isArray(entityTabs)) {
            list = entityTabs;
        }
        if(entityTabs?.list !== undefined) {
            list = entityTabs?.list.map(tab => ({
                ...entityTabsDefault[tab],
            }));
            excluded = Object.values(entityTabsDefault).map(tab => entityTabs?.list.includes(tab.id) ? null : ({
                ...tab,
                checked: false,
            })).filter(Boolean);
        }
        return [...list, ...excluded].map((tab, i) => {
            let nextTab = {...entityTabsDefault[tab.id]};
            if(entityTabs?.list !== undefined) {
                nextTab.checked = entityTabs.list.includes(tab.id);
            } else if(Array.isArray(entityTabs)) {
                nextTab = { ...nextTab, ...tab, };
            }
            return {
                ...nextTab,
                order: i,
            };
        });
    }

    @bind
    deNormalizeTabs(entityTabs) {
        return (entityTabs || Object.values(entityTabsDefault)).reduce((accum, tab, i) => {
            if(tab.checked) {
                accum.list.push(tab.id);
            }
            return accum;
        }, { list: [] });
    }

    @bind
    handleCheckbox(event) {
        const index = Number(event.target.dataset.index);
        this.setState({ entityTabs: this.state.entityTabs.map((tab) => {
            if(tab.order === index) {
                tab.checked = !tab.checked;
            }
            return tab;
        })}, this.updateChanges);
    }

    @bind
    handleChangeDefault(prevId, nextTab) {
        this.setState({ entityTabs: this.normalizeTabs(this.state.entityTabs.reduce((accum, tab) => {
            if(nextTab.id === tab.id) {
                tab.checked = true;
                accum = [tab, ...accum];
            } else {
                accum.push(tab);
            }
            return accum;
        }, []))}, this.updateChanges);
    }

    @bind
    @memoize()
    buildDropArea(entityTabs: Object) {
        const { disabled } = this.props;
        const tabs = this.normalizeTabs(entityTabs);

        const listItems = [...tabs.map((tab, i) => {
            const { uuid, checked, name, icon, iconType, id } = tab;
            const content = (
                <>
                    <ListItemAvatar>
                        <Icon name={icon} type={iconType} />
                    </ListItemAvatar>
                    <ListItemTextStyled
                        secondary={name}
                    />
                    <ListItemSecondaryAction>
                        {i === 0 ? (
                            <DefaultMenu disabled={disabled} onChange={this.handleChangeDefault} tabs={tabs} tabId={id} />
                        ) : (
                            <IconButton disabled={disabled} data-index={i} aria-label="Check" onClick={this.handleCheckbox}>
                                <MdiIcon data-index={i} name={checked ? 'checkbox-marked' : 'checkbox-blank-outline'} size={24}  />
                            </IconButton>
                        )}
                    </ListItemSecondaryAction>
                </>
            );
           
            return (
                <DraggableElement key={`${uuid}${i}`} index={i} element={tab} >
                    <RowTarget
                        Component={ListItem}
                        index={i}
                        onDrop={this.onDrop}
                        label={'label'}
                        element={tab}
                        disabled={disabled}
                    >
                        {content}
                    </RowTarget>
                </DraggableElement>
            );
        }), <EmptyRowTarget key={'-1'} index={tabs.length} onDrop={this.onDrop} />];

        return <List>{listItems}</List>;
    };

    render() {
        const { entityTabs } = this.state;
        return (
            <ExpansionCard
                expanded
                title="Entity Tabs"
            >
                <DragNDropContext>
                    {this.buildDropArea(entityTabs)}
                </DragNDropContext>
            </ExpansionCard>
        );
    };
}

export default EntityTabsSection;
