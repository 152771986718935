/* @flow */

import React, { PureComponent, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { muiTheme } from 'app/themes/materialUi';
import {
    Collapse,
    Divider,
    Grid,
    Avatar,
    ListItem,
    ListItemText,
    ListItemAvatar,
    ListItemSecondaryAction,
    MdiIcon,
    IconButton,
    Menu,
    MenuItem,
} from '@mic3/platform-ui';

import TypeIcon from 'app/components/atoms/TypeIcon/TypeIcon';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { isEmpty } from 'app/utils/utils';
import { get } from 'app/utils/lo/lo';
import { getAttachmentUrl } from 'app/utils/attachments/attachmentsUtils';
import TinyVirtualList from 'react-tiny-virtual-list';
import Icon from 'app/components/atoms/Icon/Icon';
import GridEntityId from 'app/components/organisms/GridView/GridRenderers/GridEntityId';
import { EntityPopup, MouseOverPopover } from 'app/components/organisms/InfoPopup/InfoPopup';
import { addRoleToData, getPermissions } from 'app/config/rolesConfig';
import Loader from 'app/components/atoms/Loader/Loader';
import { getEntityUrl } from 'app/utils/entity/entityUtils';
import { formatDate } from 'app/utils/date/date';

const LabelContainer = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    p {
        margin: 0;
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        line-height: 16px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
    }
`;

export const CardDivider = styled(Divider)`
    && {
        margin: 0 12px;
        height: 16px;
        position: relative;
        top: 3px;
        background-color: ${({theme})=> theme.material.colors.background.divider}
    }
`;

const StyledMenuIcon = styled(MdiIcon)`
    margin-right: 0.7rem;
`;

const ListItemStyled = styled(ListItem)`
    margin-bottom: 8px;
`;

const ItemTitle = styled.span`
    padding-left: 0.1rem;
`;

const ListDivider = styled.hr`
    border: none;
    height: 2px;
    flex-shrink: 0;
    background-color: ${({theme})=> theme.material.colors.background.divider};
`;

const Container = styled.div`
    & .MuiListItem-container {
        list-style-type: none;
    }
`;
const StyledAvatar = styled(Avatar)`
    && {
        margin-right: 16px;
        height: 24px;
        width: 24px;
        font-size: 14px;
    }
`;
const StyledName = styled.div`
    font-size: 14px;
`;
const StyledDate = styled.div`
    font-size: 12px;
`;
const MobileMenu = (props: Object) => {
    const [isMenuOpen, toggleMenu] = useState(false);
    const [relEntityRole, setRelEntityRole] = useState('noRole');
    const [relEntityRoleLoading, setRelEntityRoleLoader] = useState(false);
    const anchorDotsEl = useRef(null);
    const { canEdit, reloadList, entityPermissions, deleteRelation, openRelationSidebar, viewType } = props;
    const { relation, relatedEntity, entity } = props.data || {};
    const { type } = relatedEntity || {};
    const { relationDefinition } = relation;
    const { classes } = relationDefinition;
    const { id:relId, createdDate, createdBy } = relation || {};
    const { id, name = 'No name', image } = createdBy || {};

    const relEntityPermissions = getPermissions(relEntityRole);

    useEffect(() => {
        const fetchRole = async () => {
            await setRelEntityRoleLoader(true);
            try {
                const data = await addRoleToData(relatedEntity);
                setRelEntityRole(data?.role);
                await setRelEntityRoleLoader(false);
            } catch (error) {
                await setRelEntityRoleLoader(false);
            }
        };
        if (isMenuOpen && !entityPermissions.canEdit && !relEntityRoleLoading && relEntityRole === 'noRole') {
            fetchRole(); // Fetch role of related entity if user does not have edit permission on entity A
        }
    }, [entityPermissions, isMenuOpen, relEntityRoleLoading, setRelEntityRoleLoader, relatedEntity, relEntityRole, setRelEntityRole]);
         
    const isRemove = (relatedEntityType, entityType) => {
        const allowedTypes = ['team', 'workspace', 'user'];
        return !(allowedTypes.includes(relatedEntityType) && allowedTypes.includes(entityType));
    };
    const renderMenuItems = () => {
        if (relEntityRoleLoading) {
            return [
                <MenuItem key={1}>{relEntityRoleLoading ? 'Loading actions...' : 'No actions available'}</MenuItem>,
                relEntityRoleLoading && <Loader key={2} style={{ marginTop: '10px' }} size={20} />,
            ].filter(Boolean);
        }
        return [
            !isEmpty(classes) && (
                <MenuItem
                    onClick={() =>
                        openRelationSidebar({
                            title: 'Attributes',
                            relation,
                            reloadList,
                            relEntityPermissions,
                            canEdit,
                            relatedEntity,
                            entity,
                            viewType
                        })
                    }
                >
                    <StyledMenuIcon name='pencil-box-outline' /> Relationship
                    attributes
                </MenuItem>
            ),
            ((isEmpty(classes) && !canEdit && !relEntityPermissions?.canEdit) || !isRemove(type, entity.type)) && (
                <MenuItem>No actions available</MenuItem>
            ),
            ((canEdit || relEntityPermissions?.canEdit)) && isRemove(type, entity.type) && (
                <MenuItem
                    onClick={() => {
                        deleteRelation({
                            relId,
                            relEntityName: relatedEntity?.name,
                            relationDefinitionId: relationDefinition?.id,
                            relatedEntity,
                            entity
                        });
                        toggleMenu(false);
                    }}
                >
                    <StyledMenuIcon name='close' /> Remove
                </MenuItem>
            ),
            !!createdBy && (
                <>
                    <Divider />
                    <MenuItem>
                        <StyledAvatar
                            src={image && getAttachmentUrl(id, 'user', image)}
                            initials={name}
                        />
                        <ListItemText
                            primary={
                                <StyledName>
                                    Added by <b>{name}</b>
                                </StyledName>
                            }
                            secondary={
                                <StyledDate>
                                    {formatDate(createdDate, 'MMM DD YYYY')}
                                </StyledDate>
                            }
                        />
                    </MenuItem>
                </>
            )
        ].filter(Boolean);
    };

    return (
        <>
            <span ref={anchorDotsEl}>
                <IconButton onClick={() => toggleMenu(!isMenuOpen)}>
                    <MdiIcon name='dots-vertical' color={muiTheme.colors.text.secondary} />
                </IconButton>
            </span>
            <Menu open={isMenuOpen} anchorEl={anchorDotsEl.current} onClose={() => toggleMenu(!isMenuOpen)}>
                {renderMenuItems()}
            </Menu>
        </>
    );
};

/**
 * Renders the single relation item
 */
class RelationItem extends PureComponent<Object, Object> {
    static propTypes = {
        relationData: PropTypes.array.isRequired,
        deleteRelation: PropTypes.func.isRequired,
    };

    state = {
        isPanelOpen: false,
    };

    @bind
    togglePanel(event: Object) {
        this.setState((prevState) => ({ isPanelOpen: !prevState.isPanelOpen }));
    }

    @bind
    @memoize()
    renderListItem(style, data, canEdit, sidebarTitle, entityPermissions) {
        const { relatedEntity, relation, isReverseRelation } = data;
        const { id, type, name = 'No name', time, image, assignee } = relatedEntity || {};
        const {id: assigneeId, type: assigneeType, image: assigneeImage, name: assigneeName} = assignee || {};
        const src = type === 'user' || (!relatedEntity.hasOwnProperty('assignee')) ? image && getAttachmentUrl(id, type, image) 
            : assigneeImage && getAttachmentUrl(assigneeId, assigneeType, assigneeImage);
        const avatarName = type === 'user' || (!relatedEntity.hasOwnProperty('assignee')) ? name : assigneeName;
        return (
            <div style={style} key={`${data.entity.id}${data.isReverseRelation}${data.relatedEntity.id}`}>
                <ListItemStyled
                    key={`${type}_${id}`}
                    dense
                    button
                    onClick={() => this.props.openEntityAbout(sidebarTitle || 'About', relatedEntity)}
                >
                    <MouseOverPopover
                        content={(setAnchorElement)=>{
                            return <EntityPopup
                                onClose={() =>setAnchorElement(null)}
                                onOpenAction={(title, data)=>{
                                    this.props.openEntityAbout(title, data);
                                    setAnchorElement(null);
                                }}
                                data={data.relatedEntity}
                                type={data.relatedEntity.type}
                            />;
                        }}
                    >
                        <ListItemAvatar>
                            <Avatar src={src} initials={avatarName} />
                        </ListItemAvatar>
                    </MouseOverPopover>
                    <ListItemText
                        primary={
                            type ? (
                                <Grid container>
                                    <TypeIcon type={type} />
                                    <CardDivider flexItem orientation='vertical' />
                                    <ItemTitle>{name}</ItemTitle>
                                </Grid>
                            ) : null
                        }
                        secondary={id ? <GridEntityId label={id} value={getEntityUrl(id, type, { time })} valueId={id}/> : null}
                    />
                    <ListItemSecondaryAction>
                        <IconButton
                            onClick={() =>
                                this.props.goToEntityRelations({
                                    ...data.relatedEntity,
                                    subTitle: isReverseRelation
                                        ? relation.relationDefinition.relatedDescription
                                        : relation.relationDefinition.description,
                                })
                            }
                        >
                            <Icon name='arrow-right' />
                        </IconButton>
                        <MobileMenu data={data} {...this.props} canEdit={this.props.canEdit} entityPermissions={entityPermissions} />
                    </ListItemSecondaryAction>
                </ListItemStyled>
            </div>
        );
    }
    /**
     * @override
     */
    render() {
        const { relationData, canEdit, sidebarTitle, entityPermissions } = this.props;
        const { isPanelOpen } = this.state;
        const { isReverseRelation, relation: { relationDefinition } = {} } = get(relationData, '[0]', {});
        const { description, relatedDescription } = relationDefinition || {};

        const total = relationData.length;
        const itemSize = 72;
        const height = total >= 15 ? itemSize * 15 : itemSize * total;
        return (
            <Container>
                <LabelContainer onClick={this.togglePanel}>
                    <Icon name={isPanelOpen ? 'chevron-down' : 'chevron-right'} hexColor={'#7391D0'} />
                    <p>{`${isReverseRelation ? relatedDescription : description} ${`(${total})`}`}</p>
                </LabelContainer>
                <Collapse in={isPanelOpen} unmountOnExit>
                    <TinyVirtualList
                        width='100%'
                        height={height}
                        itemCount={total}
                        itemSize={itemSize}
                        renderItem={({ index, style }) =>
                            this.renderListItem(style, relationData[index], canEdit, sidebarTitle, entityPermissions)
                        }
                    />
                </Collapse>
                <ListDivider />
            </Container>
        );
    }
}

export default RelationItem;
