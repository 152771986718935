import { Control } from 'ol/control';
import React from 'react';
import ReactDOM from 'react-dom';

const ScaleControl = /*@__PURE__*/ (function (Control) {
    class ScaleControl extends Control {
        constructor(opt_options) {
            super({
                element: document.createElement('div'),
                target: opt_options.target,
            });

            const scaleDiv = <div />;

            this.element.onclick = this.handleRotateNorth;
            this.element.className = 'scaleUnitClass ol-control';
            ReactDOM.render(scaleDiv, this.element);
        }

        handleRotateNorth() {
            // Your code for handling the click event goes here
            // You can add the necessary logic for the click event
        }
    }

    return ScaleControl;
})(Control);

export default ScaleControl;
