/* @flow */
import React, { PureComponent } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Typography } from '@mic3/platform-ui';

import { loadTasksAssigned } from 'store/actions/dashboard/dashboardActions';
import { bind } from 'app/utils/decorators/decoratorUtils';
import Widget from 'app/components/atoms/Widget/Widget';
import LinkList from 'app/containers/LandingPage/Widgets/LinkList';
import WidgetFooter from 'app/containers/LandingPage/Widgets/WidgetFooter';
import { get } from 'app/utils/lo/lo';

const StyledTypography = styled(Typography)`
    font-weight: 400 !important;
    font-style: 12px !important;
    line-height: 16px !important;
    letter-spacing: 0.4px !important;
    color: ${({ theme }) => theme.material.colors.text.secondary} !important;
`;

/**
 * Widget for displaying tasks assigned to the active user
 */
class TasksByCreatedDateWidget extends PureComponent<Object, Object> {
    static propTypes = {
        loadTasksAssigned: PropTypes.func,
        isLoading: PropTypes.bool,
        records: PropTypes.array,
        user: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);
        props.loadTasksAssigned(props.user, { linkOnly: true });
    }

    @bind
    renderFooterContent(){
        return <WidgetFooter />;
    }
    @bind
    renderAdditionalInfo(props){
        const createdDate =get(props, 'createdDate');
        let relativeTime;
        if (createdDate){
            relativeTime = moment(createdDate).fromNow();
        }
        return <StyledTypography value={0} >{relativeTime || ''}</StyledTypography>;
    }
    render() {
        const { isLoading, records, item } = this.props;
        return (
            <Widget
                title="New Tasks"
                content={!isLoading && !records?.length ? 'No Results': <LinkList
                    list={records}
                    type="task"
                    renderAdditionalInfo={this.renderAdditionalInfo}
                />}
                loading={isLoading}
                footerContent={this.renderFooterContent}
                {...item}
            />
        );
    }
}

const mapStateToProps: Function = ( state ) => {
    return {
        user: state.user.profile,
        isLoading: state.dashboard.tasksAssigned.isLoading,
        records: state.dashboard.tasksAssigned.records,
    };
};

export default connect(mapStateToProps, { loadTasksAssigned })(TasksByCreatedDateWidget);
