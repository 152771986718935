/* @flow */
import { combineReducers } from 'redux';

import { dataTableReducer, loadDataReducer, keepDataAtStart } from 'app/utils/redux/reducer-utils';
import {
    CREATE_TEAM_STARTED, CREATE_TEAM,
    LOAD_TEAMS_STARTED, LOAD_TEAMS,
    LOAD_TEAM_STARTED, LOAD_TEAM,
    UPDATE_TEAM_STARTED, UPDATE_TEAM,
    LOAD_TEAM_HISTORY_STARTED, LOAD_TEAM_HISTORY,
    LOAD_RELATED_WORKSPACES_STARTED, LOAD_RELATED_WORKSPACES,
    LOAD_TEAMS_TYPEAHED_STARTED, LOAD_TEAMS_TYPEAHED
} from 'store/actions/admin/teamsActions';

import avatar from './avatar/teamsAvatarReducer';

export default combineReducers<Object, Object>({
    list: dataTableReducer(LOAD_TEAMS_STARTED, LOAD_TEAMS, keepDataAtStart),
    autocomplete: dataTableReducer(LOAD_TEAMS_TYPEAHED_STARTED, LOAD_TEAMS_TYPEAHED, keepDataAtStart),
    relatedWorkspaces: dataTableReducer(LOAD_RELATED_WORKSPACES_STARTED, LOAD_RELATED_WORKSPACES, keepDataAtStart),
    details: loadDataReducer(LOAD_TEAM_STARTED, LOAD_TEAM),
    create: loadDataReducer(CREATE_TEAM_STARTED, CREATE_TEAM),
    update: loadDataReducer(UPDATE_TEAM_STARTED, UPDATE_TEAM),
    history: dataTableReducer(LOAD_TEAM_HISTORY_STARTED, LOAD_TEAM_HISTORY, keepDataAtStart),
    avatar
});
