
/* @flow */
import { combineReducers } from 'redux';

import { dataTableReducer, loadDataReducer, compareId, keepDataAtStart } from 'app/utils/redux/reducer-utils';
import {
    LOAD_USER_STARTED, LOAD_USER,
    LOAD_USER_HISTORY_STARTED, LOAD_USER_HISTORY,
    LOAD_USER_WORKSPACES_STARTED, LOAD_USER_WORKSPACES,
} from 'store/actions/admin/userManagementAction';
import user from './user/userReducer';
import { LOAD_USER_REFERENCES_STARTED, LOAD_USER_REFERENCES } from 'store/actions/admin/usersActions';

export default combineReducers<Object, Object>({
    user,
    details: loadDataReducer(LOAD_USER_STARTED, LOAD_USER, compareId),
    changelog: loadDataReducer(LOAD_USER_HISTORY_STARTED, LOAD_USER_HISTORY, compareId),
    workspaces: dataTableReducer(LOAD_USER_WORKSPACES_STARTED, LOAD_USER_WORKSPACES, keepDataAtStart),
    references: loadDataReducer(LOAD_USER_REFERENCES_STARTED, LOAD_USER_REFERENCES, keepDataAtStart),
});
