// @flow

import { bind } from 'app/utils/decorators/decoratorUtils';
import EntityLayer from './EntityLayer';
import { get } from 'app/utils/lo/lo';
import { Heatmap as HeatmapLayer } from 'ol/layer.js';
import { isDefined } from 'app/utils/utils';

class HeatMapLayer extends EntityLayer {
    parentLayer: EntityLayer;
    layer: any;
    olMap: any;

    constructor(info, olMap, layer) {
        super(info);
        this.parentLayer = layer;
        this.layerId = get(info,'layerId' || '');
        this.olMap =  olMap;
        this.createHeatmapLayer();
        this.parentLayer.addHeatMapLayer(this);

    }

    @bind
    createHeatmapLayer() {
        const { id, heatmap, visible, opacity } = this.info;
        const { valBlur, valRadius }  = heatmap || {};
        const isheatMapLayer = this.olMap?.findLayerByType(`${id}`, 'heatmap') || this.olMap?.findLayer('allLayers', 'heat map layer');
        if(isheatMapLayer)
            this.olMap?.getMap()?.removeLayer(isheatMapLayer);
        this.layer = new HeatmapLayer({
            title: 'heat map layer',
            visible,
            opacity: opacity > 1 ? 1 : opacity,
            attributes: {
                id: this.info?.id,
                type: 'heatmap',
                subtitle: 'entities',
                layerType: 'entity-layer',
                iconName: 'things',
                iconType: 'af',
                noLocationPins: []
            },
            source: this.getDataSource(),
            blur: isDefined(valBlur) ? valBlur : 40,
            radius: isDefined (valRadius) ? valRadius : 40
        });
        this.olMap.getMap().getLayers().insertAt(1, this.layer);
    }

    @bind
    clearHeatMapSource() {
        const dataSource = this.getDataSource();
        dataSource.clear();
        if(dataSource?.getSource()) {
            dataSource?.getSource().clear();
        }
    }

    @bind
    getHeatMapLayer() {
        return this.layer;
    }


}

export default HeatMapLayer;
