/* @flow */

import { gql } from '@apollo/client';

export const defaultPipelineQueryFields = `
    id
    name
    image
    type
    description
    active
    primary
    iconName
    iconColor
    iconType
    attributes
    createdDate
    modifiedDate
    primaryClass {
        id
        name
        icon
        iconType
        color
        entityAppearance
        openInModule
        entityTabs
        formDefinition
        parents {
            name
            color
            icon
            iconType
            formDefinition
        }
    }
    classes {
        id
        uri
        name
        formDefinition
        icon
        iconType
        color
        parents {
            uri
        }   
    }
    createdBy {
        id
        name
        image
    }
    modifiedBy {
        id
        name
        image
    }
    chatId
`;

// lastVersion {
//     id
//     primary(fields: ["iot_pipeline_version/version"])
// }
export default gql`
    query pipelineQuery($id: ID!) {
        result: entity(type: "iot_pipeline", id: $id) {
            ${defaultPipelineQueryFields}
        }
        role: resourcePermission(type: "iot_pipeline", id: $id)
    }
`;
