import { loadData, mutateData } from 'app/utils/redux/action-utils';
import apiKeysQuery from 'graphql/apiKeys/apiKeyQuery';
import offlineSessionIdleTimeoutQuery from 'graphql/apiKeys/offlineSessionIdleTimeoutQuery';
import revokeApiKeyMutation from 'graphql/apiKeys/revokeApiKeyMutation';


export const LOAD_API_KEYS_STARTED = '@@affectli/apiKeys/LOAD_API_KEYS_STARTED';
export const LOAD_API_KEYS = '@@affectli/apiKeys/LOAD_API_KEYS';

export const LOAD_OFFLINE_SESSION_IDLE_TIMEOUT_STARTED = '@@affectli/apiKeys/LOAD_OFFLINE_SESSION_IDLE_TIMEOUT_STARTED';
export const LOAD_OFFLINE_SESSION_IDLE_TIMEOUT = '@@affectli/apiKeys/LOAD_OFFLINE_SESSION_IDLE_TIMEOUT';

export const DELETE_API_KEYS_STARTED = '@@affectli/apiKeys/DELETE_API_KEYS_STARTED';
export const DELETE_API_KEYS = '@@affectli/apiKeys/DELETE_API_KEYS';

export const loadApiKeys = loadData(LOAD_API_KEYS_STARTED, LOAD_API_KEYS, apiKeysQuery);

export const loadIdleTimeout = loadData(LOAD_OFFLINE_SESSION_IDLE_TIMEOUT_STARTED, LOAD_OFFLINE_SESSION_IDLE_TIMEOUT, offlineSessionIdleTimeoutQuery);

export const deleteApiKeys = () => (dispatch, getState) => {
    return mutateData(DELETE_API_KEYS_STARTED, DELETE_API_KEYS, revokeApiKeyMutation)()(dispatch, getState).then((response) => {
        if (!(response instanceof Error)) {
            response && dispatch(loadApiKeys());
        }
    });
};
