/* @flow */

import { gql } from '@apollo/client';

export default gql`
	query topicTailPipelineQuery($id: ID!, $topicLabel: String){
		result: iotPipelineTopicTail(id: $id, topicLabel: $topicLabel) {
			key
			value {
			  isBinary
			  dataAsUtf8
			  data
			}
		}
	}
`;
