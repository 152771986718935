/* @flow */

// $FlowFixMe
import React, { Fragment, PureComponent, memo } from 'react';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
import styled from 'styled-components';
import { Button, Typography, Grid, IconButton, ListItemText, List, ListItem, ListItemSecondaryAction, MdiIcon, Tooltip } from '@mic3/platform-ui';
import { isMobile } from 'react-device-detect';

import DraggableElement from 'app/components/molecules/Dnd/DraggableElement';
import RowTarget, { EmptyRowTarget } from 'app/components/molecules/Dnd/RowTarget';
import ListGroup from 'app/components/molecules/List/ListGroup';
import { addExtraSpace } from 'app/utils/designer/form/fieldUtils';
import { formFieldTypes, designerFormFieldTypes } from 'app/utils/designer/form/settings/formFieldSettingsUtils';
import { muiTheme } from 'app/themes/materialUi';
import { bind } from 'app/utils/decorators/decoratorUtils';

const OverlayWrapperLabelStyled = styled(Grid)`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    transition: .2s;
    background: ${({ isselected, theme }) => isselected && theme.material.colors.primary.main};
    color: ${({ isselected, theme }) => isselected ? theme.material.colors.text.button : theme.material.colors.text.primary };
    padding: 0 10px 0;
    border-radius: 2px 0 0 2px;
    min-height: 48px;
    width: 100%;
    text-align: left;
`;

const ListItemStyled = styled(ListItem)`
    padding: 0 !important;
    margin: 0;
    &.MuiListItem-root.Mui-selected, &.MuiListItem-root.Mui-selected:hover {
      background-color: ${({ theme }) => theme.material.colors.formBuilder.selectedField};
    }
    & .MuiPaper-root, & .FormField-root {
      text-align: left;
      .MuiFormControlLabel-root {
        margin-left: 0 !important;
      }
    }
    & .MuiListItemSecondaryAction-root {
      top: ${({ isgroup, ispanel, selected }) => isgroup || ispanel ?  `${isgroup ? '24px' : '35px'} !important` : '50% !important'};
      z-index: 1;
      .MuiIconButton-root .MuiIcon-root.mdi {
        &:before {
            color: ${({ selected, theme }) => selected 
    ? `${theme.material.colors.appNav.linkActiveIconColor} !important;` 
    : `${theme.material.colors.text.button} !important;` }
        }
        
    }
    }
    .MuiListItemText-root {
      padding: 0 !important;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
    .MuiFormControl-root {
      padding: 0 !important;
      margin-top: 4px !important;
      margin-bottom: 4px !important;
    }
`;

const ButtonStyled = styled(Button)`
    margin-top: 4px !important;
    margin-left: 16px !important;
    & .MuiIcon-root {
      line-height: 15px !important;
    }
`;
const AddComponentsLabel = styled(Typography)`
    margin-left: 16px !important;
    color: ${({ theme }) => theme.material.colors.disabled.color} !important;
`;

const ClassificationLabel = styled(Typography)`
    text-align: left !important;
    margin-left: 10px !important;
    width: 100%;
    display: block;
    min-height: 70px;
    color: ${({ theme }) => theme.material.colors.text.label} !important;
`;

const FieldName = styled.div`
    display: block;
    width: 100%;
    height: 56px;
    margin: 4px 0 4px 10px;
    font-size: 1rem;
    font-weight: 400;
    color: ${({theme})=> theme.material.colors.text.secondary};
    line-height: 56px;
    text-align: left;
`;

const FormDndWrapper = styled.div`
    ${({ root }) => root ? `padding: 0 ${isMobile ? '8px' : '16px'}` : ''}
`;

const StyledMdiIcon = styled(MdiIcon)`
        &:before{
            color: ${({theme})=> theme.material.colors.text.button}
        }
`;

const Error = memo(({ children }) => <div style={{ fontSize: '0.6em', color: '#C22525', textAlign: 'left', marginLeft: '14px', position: 'relative', zIndex: 1 }}>{children}</div>);

const getLabel = (type, properties) => {
    const { name, header, label } = properties || {};
    let itemLabel = name || header || label;
    itemLabel = itemLabel ? `${itemLabel} (#${type})` : `${type}`;
    return itemLabel;
};

/*
 * Form DnD Context
 */
class FormDnd extends PureComponent<Object, Object> {

    static propTypes = {
        renderContent: PropTypes.func.isRequired,
        elements: PropTypes.arrayOf(PropTypes.object),
        selectedElement: PropTypes.object,
        uuid: PropTypes.string.isRequired,
        add: PropTypes.func.isRequired,
        move: PropTypes.func.isRequired,
        remove: PropTypes.func.isRequired,
        onSelectElement: PropTypes.func.isRequired,
    };

    static defaultProps = {
        root: true,
    }

    state: Object;

    constructor(props: Object) {
        super(props);
        this.state = {
            elements: (this.props.elements || []).filter(({type}) => type),
            draggableElements: this.renderDraggableElements(),
        };
    }

    renderDraggableElements = () => {
        const { type } = this.props;
        let fields = formFieldTypes;
        if (type !== 'class') {
            fields = designerFormFieldTypes;
        }
        return fields
            .map((type, i) => ({
                type,
            }));
    }

    componentDidUpdate(prevProps: Object) {
        if(this.props.disabled && prevProps.disabled !== this.props.disabled) {
            this.props.onSelectElement(null);
        }
        if (prevProps.elements !== this.props.elements) {
            this.setState({ elements: (this.props.elements || []).filter(({type}) => type) });
        }
    }

    /**
     * Called by RowTarget when the drop ends.
     */
    onDrop = (item: Object, index: number, target) => {
        if(this.props.root && !['iotPanel', 'panel'].includes(item.type)) {
            return false;
        }

        if (item?.uuid) {
            const { isContainer } = this.analyzeTargetType(target);
            this.props.move(item, isContainer ? target.uuid : this.props.uuid, index);
        } else {
            this.props.add(item, this.props.uuid, index);
        }
    }

    deleteElement = (event: Object) => {
        const index = Number(event.target.dataset.index);
        this.props.remove(this.state.elements[index]);
    }

    onSelectElement = (index: number) => {
        const { disabled } = this.props;
        if(disabled) return;

        const { elements } = this.state;
        this.props.onSelectElement({
            ...elements[index],
        });
    }

    @bind
    buildTarget(element, i, errors, isSelected) {
        const { isPanel, isIotPanel, isGroup, isContainer } = this.analyzeTargetType(element);
        const { type, properties } = element;
        const errorMessages = ((errors && errors[element.uuid]) || []).map((message, i) => <Error key={`${element.uuid}${i}`}>{message}</Error>);
        if(type.includes('geotag') || ['classification', 'chip', 'dropzone', 'displayText', 'relationsTypeahead', 'dateTimeRange'].includes(type)) {
            return (
                <>
                    <ClassificationLabel variant="subtitle1">{properties?.label || type}</ClassificationLabel>
                    {errorMessages}
                </>
            );
        }
        if(['hyperlink', 'header'].includes(type)) {
            return (
                <>
                    <ClassificationLabel variant="caption">{properties?.text || properties?.label || type}</ClassificationLabel>
                    {errorMessages}
                </>
            );
        }

        return (
            <Fragment>
                {isContainer && addExtraSpace(type)}
                {!isContainer && <FieldName>{properties?.label || type}</FieldName>}
                {isGroup && (
                    <OverlayWrapperLabelStyled isselected={isSelected?1:0}>
                        <MdiIcon name="reorder-vertical" /><Typography variant="button">{getLabel(type, properties)}</Typography>
                    </OverlayWrapperLabelStyled>
                )}
                {(isPanel || isIotPanel) && (
                    <OverlayWrapperLabelStyled isselected={isSelected?1:0}>
                        <ListGroup
                            withoutCapitalize
                            name={element?.properties?.header}
                            iconName={element?.properties?.iconName}
                            iconType={element?.properties?.iconType}
                            isSelected={isSelected}
                        />
                    </OverlayWrapperLabelStyled>
                )}
                {errorMessages}
            </Fragment>
        );
    }

    @bind
    analyzeTargetType(element) {
        const isPanel = element.type === 'panel';
        const isIotPanel = element.type === 'iotPanel';
        const isGroup = element.type === 'group' || element.type === 'groupRepeat';
        const isContainer = isPanel || isGroup || isIotPanel;
        return { isPanel, isIotPanel, isGroup, isContainer  };
    } 

    buildDropArea = memoize((elements, selectedElement, errors, isRoot, uuid, disabled) => {
        const { entityPrimaryIndexes, primary } = this.props.form || {};
        const listItems = [
            <EmptyRowTarget key={`${uuid}${0}`} index={0} onDrop={this.onDrop} element={{ isRoot, uuid: this.props.uuid, type: 'group' }} />,
            ...elements.map((element, i) => {
                const { type, properties, uuid } = element;
                const { isPanel, isIotPanel, isGroup, isContainer } = this.analyzeTargetType(element);
        
                const isSelected = selectedElement && (selectedElement.uuid === uuid);
                const target = this.buildTarget(element, i, errors, isSelected);
                const indexed = entityPrimaryIndexes?.find(t => t?.primaryAttribute === properties?.name)?.state === 'created';
                let childrenElements = null;
                if (isContainer) {
                    childrenElements = (
                        <FormDnd
                            key={uuid + 'form-13'}
                            disabled={disabled}
                            uuid={element.uuid}
                            add={this.props.add}
                            move={this.props.move}
                            remove={this.props.remove}
                            elements={element.children}
                            duplicate={this.props.duplicate}
                            selectedElement={selectedElement}
                            toggleElementsModal={this.props.toggleElementsModal}
                            renderContent={({ dropArea }) => dropArea}
                            onSelectElement={this.props.onSelectElement}
                            form={this.props.form}
                            errors={errors}
                            root={false}
                            isGroup={isGroup}
                            isPanel={isPanel}
                            isIotPanel={isIotPanel}
                        />
                    );
                }
                let extraDraggableStyle = {
                    cursor: 'move',
                };
                if(isPanel || isIotPanel) {
                    extraDraggableStyle = {
                        ...extraDraggableStyle,
                        zIndex: 1,
                        height: '48px',
                    };
                }

                return (
                    <>
                        <ListItemStyled
                            key={`${uuid}-list-${-i}`}
                            selected={isGroup || isPanel || isIotPanel ? false : isSelected}
                            isgroup={isGroup?1:0}
                            ispanel={(isPanel || isIotPanel)?1:0}
                        >
                            <Fragment>
                                <ListItemText
                                    primary={
                                        <div
                                            style={isGroup || isPanel || isIotPanel ? {
                                                borderRadius: '4px',
                                                border: `1px solid ${muiTheme.colors.formBuilder.wrapperBorderColor}`,
                                                margin: (isGroup || isPanel || isIotPanel) && !this.props.root ? '0 16px' : '8px 4px'
                                            } : {}}
                                        >
                                            <DraggableElement disabled={disabled} style={extraDraggableStyle} index={i} element={element}>
                                                <RowTarget
                                                    index={i}
                                                    onDrop={this.onDrop}
                                                    label={getLabel(type, properties)}
                                                    element={element}
                                                    onClick={() => this.onSelectElement(i)}
                                                >
                                                    {target}
                                                </RowTarget>
                                            </DraggableElement>
                                            {childrenElements}
                                        </div>
                                    }
                                />
                                
                                {!element.toRemove && isSelected && (
                                    <ListItemSecondaryAction>
                                        <IconButton onClick={() => this.props.toggleElementsModal({
                                            element,
                                            index: isPanel || isGroup || isIotPanel ? -1 : i,
                                            parentUuid: isPanel || isGroup || isIotPanel ? element.uuid : this.props.uuid,
                                        })}><StyledMdiIcon name="plus" />
                                        </IconButton>
                                        <IconButton onClick={() => this.props.duplicate(element.uuid)}>
                                            <StyledMdiIcon name="content-copy" />
                                        </IconButton>
                                        {
                                            primary && indexed ?
                                                <Tooltip title="You need to remove the index to delete the attribute." placement="top" arrow>
                                                    <span>
                                                        <IconButton aria-label="Delete" disabled>
                                                            <StyledMdiIcon data-index={i} name="delete" size={24} />
                                                        </IconButton>
                                                    </span>
                                                </Tooltip> : 
                                                <IconButton data-index={i} aria-label="Delete" onClick={this.deleteElement}>
                                                    <StyledMdiIcon data-index={i} name="delete" size={24} />
                                                </IconButton>
                                        }
                                   
                                    </ListItemSecondaryAction>
                                )}
                            </Fragment>
                        </ListItemStyled>
                        <EmptyRowTarget key={`${uuid}${-i}`} index={i+1} onDrop={this.onDrop} element={{ uuid: this.props.uuid, type: 'group' }} />
                    </>
                );
            }), <EmptyRowTarget key={`${uuid}${-1}`} index={elements.length} onDrop={this.onDrop} element={{ uuid: this.props.uuid, type: 'group' }} />].filter(Boolean);
        return <List>
            {(this.props.isPanel || this.props.isIotPanel) && elements.length === 0 ? (
                <EmptyRowTarget key={this.props.uuid + '-0'} element={{ uuid: this.props.uuid, type: 'group' }} index={elements.length} onDrop={this.onDrop}>
                    <AddComponentsLabel variant="h6">Add components</AddComponentsLabel>
                </EmptyRowTarget>
            ) : null}
            {(this.props.isGroup || this.props.isPanel || this.props.isIotPanel) && (
                <ButtonStyled key={this.props.uuid + '-1'} disabled={disabled} startIcon={<MdiIcon name="plus" size={18} />}
                    variant={this.props.isGroup ? 'text' : 'contained' }
                    onClick={() => this.props.toggleElementsModal({
                        element: elements[0] || {},
                        index: -1,
                        parentUuid: this.props.uuid,
                    })}
                >
                Add
                </ButtonStyled>
            )}
            {listItems}
            {isRoot ? (
                <Button
                    key={this.props.uuid + '-2'}
                    disabled={disabled}
                    startIcon={<MdiIcon name="plus" size={18} />}
                    onClick={() => this.props.toggleElementsModal({ parentUuid: this.props.uuid, index: -1 })}
                    variant="outlined"
                    fullWidth
                >NEW CARD</Button>
            ) : null}
        </List>;
    });

    render() {
        const { selectedElement, errors, root, uuid, disabled } = this.props;
        const { elements } = this.state;
        return (
            <FormDndWrapper root={root}>
                {this.props.renderContent({
                    dropArea: this.buildDropArea(elements, selectedElement, errors, root, uuid, disabled),
                    draggableElements: this.state.draggableElements
                })}
            </FormDndWrapper>
        );
    }
};

export default FormDnd;
