/* @flow */

// $FlowFixMe
import React, { useCallback, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Collapse as PuiCollapse, Typography, Divider } from '@mic3/platform-ui';

const CollapsibleSubHeaderStyled = styled(PuiCollapse)`
    max-height: inherit;
    width: 100%;
    margin: .5rem 0 !important;
    background-color: ${({ theme, transparent }) => transparent ? 'transparent' : theme.material.colors.background.paper};
`;

const Content = styled.div`
    flex-direction: column;
    padding-top: 5px;
    margin: 0 8px !important;
`;

const TypographyStyled = styled(Typography)`
    cursor: pointer;
    display: block !important;
    width: 100% !important;
    padding: 8px 8px 3px;
`;

const CollapsibleSubHeader = ({ transparent, withoutHeaderDivider, expanded: defaultExpanded, header, children, ...restProp }: Object) => {
    const [expanded, toggleExpanded] = useState(defaultExpanded);
    useEffect(() => {
        toggleExpanded(defaultExpanded);
    }, [defaultExpanded]);
    const onClick = useCallback((event) => {
        if(event.stopPropagation) {
            event.stopPropagation();
        }
        toggleExpanded(!expanded);
    }, [expanded]);

    return (

        <CollapsibleSubHeaderStyled collapsedHeight="30px" {...restProp} in={expanded} transparent={transparent?1:0} >
            <TypographyStyled variant="caption" onClick={onClick}>{header}</TypographyStyled>
            {!withoutHeaderDivider && <Divider variant="middle" />}
            <Content>{children}</Content>
        </CollapsibleSubHeaderStyled>
    );};

export default CollapsibleSubHeader;
