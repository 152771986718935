/* @flow */

import { gql } from '@apollo/client';
import relationDefinitionsCommonQueryFields from 'graphql/entities/relationships/relationDefinitionsCommonQueryFields';

export default gql`
    mutation updateRelationDefinitionMutation($record:  RelationDefinitionUpdateInput!) {
        result: updateRelationDefinition(record: $record) {
            ${relationDefinitionsCommonQueryFields}
        }
    }
`;
