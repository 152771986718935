import React, { PureComponent } from 'react';

import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import { Button, CardMedia } from '@mic3/platform-ui';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import { createEvent } from 'app/utils/http/event';
import UploadButton from 'app/components/molecules/UploadButton/UploadButton';
import Image from 'app/components/atoms/Image/Image';
import { getAttachmentUrl } from 'app/utils/attachments/attachmentsUtils';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { bind } from 'app/utils/decorators/decoratorUtils';
import styled from 'styled-components';
import Icon from 'app/components/atoms/Icon/Icon';
import { getSvgSource } from 'app/utils/maps/layer/layerUtils';
import Download3DSvgGeom from 'app/containers/Maps/EntityAboutMap/Download3DSvgGeom.js';

const CardImage = styled(CardMedia)`
    && {
        width: 200px;
        height: 200px;
    }
`;

const SvgContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const StyledIcon = styled(Icon)`
    padding-right: 2px;
    vertical-align: middle;
`;

class PrimaryClassAboutSvgUpload extends PureComponent<Object, Object> {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            toggleModal: false,
        };
    }

    @bind
    toggleModal() {
        this.setState((prevState) => ({ showModal: !prevState.showModal }));
    }

    render() {
        const { onChange, details, editable, disabled } = this.props;
        const { showModal } = this.state;
        const { entitySvg, entityGraphic } = details;
        const libGraphic = entityGraphic?.image && getAttachmentUrl(entityGraphic.id, 'graphic', entityGraphic.image);
        const imageSrc = getSvgSource({ svg: entitySvg , svgGraphic: entityGraphic });

        return (
            <div>
                {entitySvg || libGraphic ? (
                    <>
                        <SvgContainer>
                            <ListItemAvatar key='svgMenu'>
                                {imageSrc ? (
                                    <Image src={imageSrc} alt='SVG' height='200px' />
                                ) : (
                                    libGraphic && <CardImage image={libGraphic} />
                                )}
                            </ListItemAvatar>
                            <Download3DSvgGeom {...this.props} fileType='svgFile' 
                                deleteFile={this.props.deleteFile}
                                downloadFileAction={this.props.downloadFileAction}
                            />
                        </SvgContainer>
                    </>
                ) : (
                    <div>
                        <Button variant='text' disabled={disabled || !editable} onClick={this.toggleModal}>
                            <StyledIcon name='image-multiple' size='sm' hexColor='#7391d0' />
                           IMAGE LIBRARY
                        </Button>
                        <UploadButton
                            label=''
                            icon='image-multiple'
                            size='lg'
                            accept={['.svg']}
                            disabled={disabled || !editable}
                            onSelect={(file) => {
                                const event = createEvent('change', { name: 'svg', value: file });
                                onChange(event);
                            }}
                        >
                            {(onClick) => (
                                <Button variant='text' disabled={disabled || !editable} onClick={onClick}>
                                    <StyledIcon name='upload' size='sm' hexColor='#7391d0' />
                                    Upload
                                </Button>
                            )}
                        </UploadButton>
                    </div>
                )}
                {showModal && <SelectSvg onClose={this.toggleModal} onChange={onChange} />}
            </div>
        );
    }
}

export default PrimaryClassAboutSvgUpload;

const SelectSvg = ({ onClose, onChange }: Object) => (
    <ModalDialog onClose={onClose}>
        <FormGenerator
            components={[
                {
                    field: 'graphic',
                    type: 'graphicTypeahead',
                    properties: {
                        label: 'SVG',
                        name: 'svg',
                    },
                },
            ]}
            data={{}}
            onChange={(event) => {
                onChange(event);
                onClose();
            }}
        />
    </ModalDialog>
);
