// @flow
import { gql } from '@apollo/client';
import store from 'store/Store';
import { set, get } from 'app/utils/lo/lo';
import resourcePermissionQuery from 'graphql/common/resourcePermissionQuery';
import { graphql } from 'graphql/client';
import { _getData } from 'app/utils/redux/action-utils';
import { loadUserProfile } from 'store/actions/admin/usersActions';
import { loadTerms } from 'store/actions/app/appActions';

export const allRoles = ['viewer', 'commenter', 'editor'];

export const defaultRole = 'editor';

export const addRoleToData = async (payload) => {
    const { id, type } = payload || {};
    try {
        if (id && type) {
            const roleResponse = await graphql.query({
                query: resourcePermissionQuery,
                variables: { id, type },
                fetchPolicy: 'no-cache'
            });
            if (roleResponse instanceof Error) {
                return payload;
            }
            const role = _getData(roleResponse);
            return set(payload, 'role', role);
        }
        console.error('Both ID and type is required to fetch the role on the entity. Please check your data :: ', { id, type }); // eslint-disable-line
    } catch (e) {
        const termsError = String(e).includes('You need to accept the terms');
        if(termsError){
            store.dispatch(loadUserProfile());
            store.dispatch(loadTerms());
        }
    }
    return payload;
};

export const getPermissions = (role: string) => {
    const isAdmin = get(store.getState(), 'user.profile.isAdmin', false); // We will always have profile as app will not load till profile is loaded. For more information please check AppRoute.js
    const permissions = { canView: isAdmin, canComment: isAdmin, canEdit: isAdmin };

    if (isAdmin) {
        return permissions;
    }
    if (!role) {
        return permissions;
    }
    switch (role) {
        case 'viewer':
            permissions.canView = true;
            break;
        case 'commenter':
            permissions.canView = true;
            permissions.canComment = true;
            break;
        case 'editor':
            permissions.canView = true;
            permissions.canComment = true;
            permissions.canEdit = true;
            break;
        default:
    }
    return permissions;
};

const getUniqueKey = (key) => {
    return `query_${key.replaceAll('-', '_')}`;
};
export const addRoleToList = (payload, meta) => {
    const { records } = payload || {};
    if (!records?.length) {
        return payload;
    }
    try {
        const queriesArray = records.map(({ id, type }) => `${getUniqueKey(id)}: resourcePermission(type: "${type}", id: "${id}")`);
        const query = gql`query listResourcePermissions {
            ${queriesArray.join('\n')}
        }`;
        return graphql
            .query({ query, variables: {}, fetchPolicy: 'no-cache' })
            .then((response: Object) => {
                const { data: rolesMap } = response || {};
                const recordsWithRole = records.map(data => ({ ...data, role: rolesMap?.[getUniqueKey(data?.id)] }));
                return { ...payload, records: recordsWithRole };
            })
            .catch((error) => {
                return payload;
            });
    } catch (e) {
        return payload;
    }
};
