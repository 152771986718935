// @flow

import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';
import {
    Typography,
    Avatar,
    Collapse,
    MdiIcon,
    IconButton
} from '@mic3/platform-ui';
import { marked } from 'marked';
import DOMPurify from 'dompurify';

import { chooseIcon } from 'app/utils/attachments/attachmentsUtils';
import { getAttachmentUrl } from 'app/utils/attachments/attachmentsUtils';
import { parseMessage } from 'app/utils/chat/chatUtils';
import { loadAvatar } from 'store/actions/app/appActions';
import { useToggle } from 'app/utils/hook/hooks';
import { rocketHost } from 'app/utils/env';
import { isEmpty } from 'app/utils/utils';
import { get } from 'app/utils/lo/lo';

import DotMenu from 'app/components/molecules/DotMenu/DotMenu';
import ListItem from 'app/components/molecules/List/ListItem';
import Tooltip from 'app/components/atoms/Tooltip/Tooltip';
import Image from 'app/components/atoms/Image/Image';
import Icon from 'app/components/atoms/Icon/Icon';
import Flex from 'app/components/atoms/Flex/Flex';

const Link = styled.a`
    text-decoration: none;
`;

const MessageItemStyle = styled.div`
    display: flex;
    clear: both;
    max-width: 100%;
    margin-bottom: ${({ type }) => !type ? '24px' : '8px'};
`;

const MessageContent = styled.div`
    position: relative;
    font-size: .9em;
    display: block;
    word-break: break-word;
    color: ${({theme})=> theme.material.colors.text.primary};
    p { margin: 0; }
    width: calc(100% - 50px);

    .checkboxes {
        list-style-type: none;
        padding-inline-start: 4px;
    }

    & ul {
        padding-left: 25px;
    }

    img {
      max-width: 100%;
    }

    code {
        white-space: break-spaces;
    }

    & p {
        white-space: pre-wrap;
    }

    & .mention-link-group {
        pointer-events: none;
        cursor: default;
        transition: opacity 0.3s, background-color 0.3s, color 0.3s;
        font-weight: 700;
        padding: 0 2px 2px;
        border-radius: 4px;
        background-color: #fde8d7; 
        color: #f38c39;
    }

    & .mention-link-me {
        pointer-events: none;
        cursor: default;
        transition: opacity 0.3s, background-color 0.3s, color 0.3s;
        font-weight: 700;
        padding: 0 2px 2px;
        border-radius: 4px;
        background-color: #f5455c;
        color: #ffe9ec;
    }
        
    & .mention-link-user {
        pointer-events: none;
        cursor: default;
        transition: opacity 0.3s, background-color 0.3s, color 0.3s;
        font-weight: 700;
        padding: 0 2px 2px;
        border-radius: 4px;
        background-color: #fff6d6; 
        color: #b68d00;
    }
`;

const MessageText = styled.div`
    background: ${({ editActive, theme }) => editActive ? theme.material.colors.background.active : theme.material.colors.background.paper};
    border-radius: 6px;
    ${({ padding }) => padding && `padding: ${padding};`}
    & ol {
        padding-left: 23px;
    }

    & .MuiTypography-caption {
        display: block;
    }
    & p {
        color: ${({theme})=>theme.material.colors.text.primary}
    }
`;

const SysMsgBodyWrapper = styled.div`
    ${({ editActive }) => editActive && `background: ${theme.material.colors.background.active};`}
    display: flex;
    align-items: center;
    & .system-msg {
        font-style: italic;
        font-size: 12px; 
        color: ${({theme})=> theme.material.colors.text.primary};
    }    
    & .system-msg em {
        font-weight: 600;
    }
    @media (max-width:961px)  {
        display: block;
    }
`;

const AvatarWrap = styled(Tooltip)`
    margin: ${({ type }) => !type ? '12px .5rem 0' : '0 .5rem'};
    @media (max-width:961px)  {
        margin: 12px .5rem 0;
    }
`;

const AvatarSmall = styled(Avatar)`
    width: ${({ type }) => !type ? '32px !important' : '20px !important'};
    height: ${({ type }) => !type ? '32px !important' : '20px !important'};
    && {
        ${({ type }) => type ? `font-size: 12px; font-style: normal; font-weight: 500;` : ''};
    }
`;

const MessageBodyHeaderWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const MessageBodyHeader = styled(Typography)`
    color: ${({ theme }) => theme.material.palette.text.disabled};
    && {
        font-size: 12px;
        margin-bottom: ${({ type }) => type ? '0px' : '4px' };
        color: ${({ theme }) => theme.material.colors.text.caption};
    }
`;

const AttachmentImage = styled(Image)`
    max-width: 100%;
    cursor: pointer;
`;

const IconButtonStyled = styled(IconButton)`
    padding: 6px !important;
`;

const ListItemStyled = styled(ListItem)`
    margin: 0 auto;
    padding: 0;
    box-shadow: none;
    border-radius: 6px;
`;

// const AttachmentText = styled.span`
//     ${({ deleted }) => deleted ? 'text-decoration: line-through;' : ''}
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     max-width: 100%;
// `;

// const MenuWrapper = styled.div`
//     position: absolute;
//     top: 0;
//     right: 0;
// `;

const AttachmentLink = memo((props: Object) => (
    <Link target="_blank" href={props.href}>
        {props.children}
    </Link>
));

const AttachmentIcon = memo((props: Object) => {
    const { mimeType, href, filename } = props;
    const iconName = chooseIcon(mimeType, filename);
    return (
        <AttachmentLink href={href}>
            <Icon name={iconName} size="lg" />
        </AttachmentLink>
    );
});

const FlexStyled = styled(Flex)`
    flex-wrap: wrap;
    margin-top: 10px;
`;

const FileTitle = styled(Typography)`
    flex: 1;
`;

const ItemHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    & .MuiIconButton-root {
        padding: 4px;
    }
`;

const AliveChatItem = ({
    userChatId,
    message,
    loadAvatar,
    avatar,
    userId,
    openImagePreview,
    entityType,
    profile,
    editActive,
    onEdit
}: Object) => {
    const [ isExpanded, toggleExpansion ] = useToggle(true);
    const [ text, setText ] = useState('');
    const [ messageBody, setMessageBody ] = useState(<></>);
    const {
        file,
        createDate,
        editedDate,
        editedBy: { username: userEditor } = {},
        user: { name, username },
        type: messageType,
        mentions
    } = message;

    const onDotMenuClick = (title) => {
        switch (title) {
            case 'Edit':
                onEdit(true, message);
                break;
            default:
                break;
        }
    };

    const renderItemHeader = () => { 
        const { primary } = profile || {};
        const isAuthor = username === primary.username;
        return (
            <ItemHeader>
                {(!isAuthor || !isEmpty(file)) ? (
                    <MessageBodyHeaderWrapper>
                        <MessageBodyHeader variant="body1">
                            {name}{' '}{moment(createDate).format(`HH:mm A, DD MMM 'YY`)}{'   '}
                        </MessageBodyHeader>
                        {editedDate && (
                            <Tooltip title={`edited at ${moment(editedDate).format(`HH:mm A, DD MMM 'YY`)} by ${userEditor}`}>
                                <Icon name="pencil" size="sm" />
                            </Tooltip>
                        )}
                    </MessageBodyHeaderWrapper>
                    
                ) : (
                    <>
                        <MessageBodyHeaderWrapper>
                            <MessageBodyHeader variant="body1">
                                {name}{' '}{moment(createDate).format(`HH:mm A, DD MMM 'YY`)}{'   '}
                            </MessageBodyHeader>
                            {editedDate && (
                                <Tooltip title={`edited at ${moment(editedDate).format(`HH:mm A, DD MMM 'YY`)} by ${userEditor}`}>
                                    <Icon name="pencil" size="sm" />
                                </Tooltip>
                            )}
                        </MessageBodyHeaderWrapper>
                        <DotMenu
                            onItemClick={onDotMenuClick}
                            items={[{ name: 'Edit', icon: 'pencil' }].filter(Boolean)}
                            tooltipTitle='More Options'
                        />
                    </>
                )}
            </ItemHeader>
        );
    };

    useEffect(() => {
        const { primary } = profile || {};
        parseMessage(message?.text, mentions, primary, (newMessage) => {
            setText(newMessage);
        });
    }, []); // eslint-disable-line

    useEffect(() => {
        if (text && text !== message.text) {
            const { primary } = profile || {};
            parseMessage(message?.text, mentions, primary, (newMessage) => {
                setText(newMessage);
            });
        }
    }, [message, mentions, profile, text]);

    useEffect(() => {
        if (isEmpty(avatar)) {
            loadAvatar(username, 'user');
        }
    }, [entityType, loadAvatar, username]); // eslint-disable-line

    useEffect(() => {
        if (text || file) {
            // add extra class for styling checkboxes in message
            let markedText = marked(text, { sanitize: true });
            if(text?.includes('mention-link-group') || text?.includes('mention-link-user') || text?.includes('mention-link-me')){
                markedText = marked(text);
            }
            //Setting up DOMPurify. Allowing the 'target' attribute
            DOMPurify.setConfig({
                ADD_ATTR: ['target']
            });
            const updatedText = DOMPurify.sanitize(markedText)
                .replace(
                    /(<ul>)(\n<li><input (checked="" )?disabled="" type="checkbox")/g,
                    '<ul class="checkboxes">$2'
                )
                .replace(/<(a href)(=".*")/g, '<a target="_blank" href$2');
            setMessageBody(
                <>
                    {!messageType && !updatedText && (
                        <MessageText padding="8px 16px" editActive={editActive}>
                            {renderItemHeader()}
                            <p>{text}</p>
                        </MessageText>
                    )}
                    {!messageType && !!updatedText && (
                        <MessageText padding="8px 16px" editActive={editActive}>
                            {renderItemHeader()}
                            <div dangerouslySetInnerHTML={{__html: updatedText }} />
                        </MessageText>
                    )}
                    {messageType && (
                        <SysMsgBodyWrapper>
                            <MessageBodyHeader type={messageType} variant="body1">
                                {`${username} ${moment(createDate).format(`HH:mm A, DD MMM 'YY`)} `}
                            </MessageBodyHeader>
                            <div className="system-msg" dangerouslySetInnerHTML={{__html: text }} />
                        </SysMsgBodyWrapper>
                    )}
                </>
            );

            if (file) {
                const { id: fileId, name: fileName, type, description } = file || {};
                const fileUrl = `${document.location.protocol}//${rocketHost}/file-upload/${fileId}/${fileName}?download`;
                const src = `/chat/file-upload/${fileId}/${fileName}`;
                switch (type) {
                    case 'image/svg+xml':
                    case 'image/gif':
                    case 'image/jpeg':
                    case 'image/jpg':
                    case 'image/png': {
                        setMessageBody(
                            <MessageText padding="8px 16px" editActive={editActive}>
                                {renderItemHeader()}
                                <Collapse in={isExpanded} collapsedHeight="40px">
                                    <FlexStyled spaceBetween alignItems="center">
                                        <FileTitle variant="subtitle2" noWrap>
                                            <AttachmentLink href={fileUrl}>
                                                {fileName}
                                            </AttachmentLink>
                                        </FileTitle>
                                        <Flex alignItems="center">
                                            <AttachmentLink href={fileUrl}>
                                                <IconButtonStyled>
                                                    <MdiIcon name="download" fontSize="small"/>
                                                </IconButtonStyled>
                                            </AttachmentLink>
                                            <IconButtonStyled onClick={toggleExpansion}>
                                                <MdiIcon name={isExpanded ? 'menu-up' : 'menu-down'} fontSize="small" />
                                            </IconButtonStyled>
                                        </Flex>
                                    </FlexStyled>
                                    <div>
                                        <AttachmentImage alt={text} src={src} onClick={() => openImagePreview(fileId)} />
                                        {description && <Typography variant="caption">{description}</Typography>}
                                    </div>
                                </Collapse>
                            </MessageText>
                        );
                        break;
                    }
                    default: {
                        setMessageBody(
                            <MessageText padding="8px 16px" editActive={editActive}>
                                {renderItemHeader()}
                                <ListItemStyled
                                    component={<AttachmentIcon filename={fileName} mimeType={type} href={fileUrl} />}
                                    title={
                                        <FlexStyled spaceBetween alignItems="center">
                                            <FileTitle variant="subtitle2" noWrap>
                                                <AttachmentLink href={fileUrl}>
                                                    {fileName}
                                                </AttachmentLink>
                                            </FileTitle>
                                            <Flex alignItems="center">
                                                <AttachmentLink href={fileUrl}>
                                                    <IconButtonStyled>
                                                        <MdiIcon name="download" fontSize="small"/>
                                                    </IconButtonStyled>
                                                </AttachmentLink>
                                            </Flex>
                                        </FlexStyled>
                                    }
                                    subTitle={<Typography variant="caption">{description}</Typography>}
                                    raised
                                    rowWrap
                                />
                            </MessageText>
                        );
                    }
                }
            }
        }
    }, [text, file, isExpanded, editActive]); // eslint-disable-line

    const { name: aName, image, id } = avatar || {};
    const imageSource = image ? getAttachmentUrl(id, 'user', image) : null;
    return (
        <MessageItemStyle type={messageType} editActive={editActive}>
            <AvatarWrap type={messageType} alt={aName} x={10} y={-50}>
                <span alt={aName}>
                    <AvatarSmall type={messageType} initials={aName} src={imageSource} />
                </span>
            </AvatarWrap>
            <MessageContent>
                {messageBody}
            </MessageContent>
        </MessageItemStyle>
    );
};

AliveChatItem.propTypes = {
    message: PropTypes.shape({
        id: PropTypes.string.isRequired,
        type: PropTypes.string,
        text: PropTypes.string,
        createDate: PropTypes.instanceOf(Date),
        file: PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
            description: PropTypes.string,
        }),
        user: PropTypes.shape({
            id: PropTypes.string.isRequired,
            username: PropTypes.string.isRequired,
            name: PropTypes.string,
        }).isRequired
    }).isRequired,
    openImagePreview: PropTypes.func.isRequired,
    profile: PropTypes.object.isRequired,
};

export default connect(
    (state, props) => ({
        profile: state.user.profile,
        userChatId: state.user.chat.id,
        avatar: get(state.admin.users.references, 'data', []).find(usr => usr.username === props.message.user.username),
    }),
    { loadAvatar }
)(AliveChatItem);
