/* @flow */

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Button, CircularProgress } from '@mic3/platform-ui';

import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import Help from 'app/utils/designer/form/settings/common/Help';
import { set } from 'app/utils/lo/lo';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { loadSecurityGeneral, ssoAddIdentityProvider } from 'store/actions/app/appActions';
import { showToastr } from 'store/actions/app/appActions';
import history from 'store/History';

export const providerDefinitions = (isCreated) => ({
    oidc: [
        [
            {
                type: 'text',
                properties: { 
                    label: 'Redirect URI', 
                    name: 'generatedRedirectUri', 
                    disabled: true,
                    help: <Help message="The redirect uri to use when configuring the identity provider." />,
                },
                constraints: { required: true }
            },
            {
                type: 'text',
                properties: { 
                    label: 'Alias', 
                    name: 'alias',
                    disabled: isCreated,
                    help: <Help message="The alias uniquely identifies an identity provider and it is also used to build the redirect uri." />,
                },
                constraints: { required: true }
            },
            {
                type: 'text',
                properties: { 
                    label: 'Display name', 
                    name: 'displayName',
                    help: <Help message="Friendly name for Identity Providers." />,
                },
            },
            {
                type: 'number',
                properties: { 
                    label: 'Display order', name: 'config.guiOrder',
                    help: <Help message="Number defining the order of the providers in GUI (for example, on the Login page). The lowest number will be applied first." />,
                },
                constraints: { required: true, onlyInteger: true, min: 0 }
            },
        ],
        [
            { type: 'divider' },                      
            {
                type: 'header',
                properties: { 
                    text: 'OpenID Connect Settings', variant: 'h6',
                },
            },
        ],
        [
            {
                type: 'text',
                properties: { 
                    label: 'Authorization URL', name: 'config.authorizationUrl',
                },
                constraints: { required: true }
            },
            {
                type: 'text',
                properties: { 
                    label: 'Token URL', name: 'config.tokenUrl',
                },
                constraints: { required: true }
            },
            {
                type: 'text',
                properties: { 
                    label: 'Logout URL', name: 'config.logoutUrl',
                    help: <Help message="End session endpoint to use to logout user from external IDP." />,
                },
            },
            {
                type: 'text',
                properties: { 
                    label: 'User Info URL', name: 'config.userInfoUrl',
                    help: <Help message="The User Info Url. This is optional." />,
                },
            },
            {
                type: 'text',
                properties: { 
                    label: 'Issuer', name: 'config.issuer',
                    help: <Help message="The issuer identifier for the issuer of the response. If not provided, no validation will be performed." />,
                },
            },
            // Validate signatures start
            {
                type: 'boolean',
                properties: { 
                    label: 'Validate signatures', name: 'config.validateSignature',
                    help: <Help message="Enable/disable signature validation of external IDP signatures." />,
                },
            },
            {
                type: 'boolean',
                properties: { 
                    label: 'Use JWKS URL', 
                    name: 'config.useJwksUrl',
                    isVisible: (data) => data?.config?.validateSignature,
                    help: <Help message="If the switch is on, identity provider public keys will be downloaded from given JWKS URL. This allows great flexibility because new keys will be always re-downloaded again when identity provider generates new keypair. If the switch is off, public key (or certificate) from the Keycloak DB is used, so when the identity provider keypair changes, you always need to import the new key to the Keycloak DB as well." />,
                },
            },
            {
                type: 'text',
                properties: { 
                    label: 'Validating public key',
                    name: 'config.publicKeySignatureVerifier',
                    isVisible: (data) => data?.config?.validateSignature && !data?.config?.useJwksUrl,
                    help: <Help message="The public key in PEM format that must be used to verify external IDP signatures." />,
                },
            },
            {
                type: 'text',
                properties: { 
                    label: 'Validating public key id', 
                    name: 'config.publicKeySignatureVerifierKeyId',
                    isVisible: (data) => data?.config?.validateSignature && !data?.config?.useJwksUrl,
                    help: <Help message="Explicit ID of the validating public key given above if the key ID. Leave blank if the key above should be used always, regardless of key ID specified by external IDP; set it if the key should only be used for verifying if the key ID from external IDP matches." />,
                },
            },
            {
                type: 'text',
                properties: { 
                    label: 'JWKS URL', 
                    name: 'config.jwksUrl',
                    isVisible: (data) => data?.config?.validateSignature && data?.config?.useJwksUrl,
                    help: <Help message="URL where identity provider keys in JWK format are stored. See JWK specification for more details. If you use external Keycloak identity provider, you can use URL like 'http://broker-keycloak:8180/realms/test/protocol/openid-connect/certs' assuming your brokered Keycloak is running on 'http://broker-keycloak:8180' and its realm is 'test'." />,
                },
            },
            // Validate signatures end
            {
                type: 'boolean',
                properties: { 
                    label: 'Use PKCE', name: 'config.pkceEnabled',
                    help: <Help message="Use PKCE (Proof of Key-code exchange) for IdP Brokering" />,
                },
            },
            {
                type: 'typeahead',
                properties: {
                    name: 'config.pkceMethod',
                    label: 'PKCE Method', 
                    options: [
                        { value: 'plain', label: 'plain' },
                        { value: 'S256', label: 'S256' },
                    ],
                    clearable: false,
                    isVisible: (data) => data?.config?.pkceEnabled,
                    valueField: 'value',
                    help: <Help message="PKCE Method to use" />,
                    defaultValue: 'plain'
                },
                constraints: { required: true }
            },              
            { type: 'divider' },                        
            {
                type: 'typeahead',
                properties: {
                    name: 'config.clientAuthMethod',
                    label: 'Client authentication', 
                    options: [
                        { value: 'client_secret_post', label: 'Client secret sent as post' },
                        { value: 'client_secret_basic', label: 'Client secret sent as basic auth' },
                        { value: 'client_secret_jwt', label: 'JWT signed with client secret' },
                        { value: 'private_key_jwt', label: 'JWT signed with private key' },
                    ],
                    valueField: 'value',
                    clearable: false,
                    help: <Help message="The client authentication method (cfr. https://openid.net/specs/openid-connect-core-1_0.html#ClientAuthentication). In case of JWT signed with private key, the realm private key is used." />,
                },
            },                                
            {
                type: 'text',
                properties: { 
                    label: 'Client ID', name: 'config.clientId',
                    help: <Help message="The client identifier registered with the identity provider." />,
                },
                constraints: { required: true }
            },                      
            {
                type: 'text',
                properties: { 
                    label: 'Client secret', name: 'config.clientSecret',
                    help: <Help message="The client secret registered with the identity provider. This field is able to obtain its value from vault, use ${vault.ID} format." />,
                },
                constraints: { required: true }
            },                      
            {
                type: 'typeahead',
                properties: {
                    name: 'config.clientAssertionSigningAlg',
                    label: 'Client assertion signature algorithm', 
                    options: [
                        { value: 'ES256', label: 'ES256' },
                        { value: 'ES384', label: 'ES384' },
                        { value: 'ES512', label: 'ES512' },
                        { value: 'HS256', label: 'HS256' },
                        { value: 'HS384', label: 'HS384' },
                        { value: 'HS512', label: 'HS512' },
                        { value: 'PS256', label: 'PS256' },
                        { value: 'PS384', label: 'PS384' },
                        { value: 'PS512', label: 'PS512' },
                        { value: 'RS256', label: 'RS256' },
                        { value: 'RS384', label: 'RS384' },
                        { value: 'RS512', label: 'RS512' },
                    ],
                    valueField: 'value',
                    clearable: false,
                    help: <Help message="Signature algorithm to create JWT assertion as client authentication. In the case of JWT signed with private key or JWT signed with client secret, it is required. If no algorithm is specified, the following algorithm is adapted. RS256 is adapted in the case of JWT signed with private key. HS256 is adapted in the case of JWT signed with client secret." />,
                    constraints: { required: true }
                },
            }, 
        ]                             
    ]
});

class AddIdentityProvider extends PureComponent<Object, Object> {

    formRef = React.createRef();

    constructor(props) {
        super(props);
        
        this.state = { 
            activeClass: this.props.defaultSelected || null, 
            data: { 
                providerId: 'oidc',
                alias: 'oidc', 
                generatedRedirectUri: `https://${window.location.host}/iam/auth/realms/Affectli/broker/oidc/endpoint`,
                config: {
                    validateSignature: false,
                    pkceEnabled: false,
                    clientAuthMethod: 'client_secret_post'
                }
            } 
    
        };

    }

    @bind
    @memoize()
    buildComponents(providerName = 'oidc') {
        const components = [
            ...providerDefinitions()[providerName][0],
            ...providerDefinitions()[providerName][1],
            ...providerDefinitions()[providerName][2]
        ];
        return components;
    }

    @bind
    onFormSubmit(event: Event) {
        event.preventDefault();
        const { loadSecurityGeneral, ssoAddIdentityProvider, loadConfigurations } = this.props;
        this.formRef.current.isValidForm().then(({ data, errors }) => {
            if (!errors) {
                const { generatedRedirectUri, config, ...provider} = data;
                ssoAddIdentityProvider({
                    ...provider,
                    config,
                })
                    .then(async (result) => {
                        if(!(result instanceof Error)) {
                            await loadSecurityGeneral();
                            await loadConfigurations();
                            history.push(`/admin/settings/identity-providers/${provider.alias}`);
                        }
                    });
            }
        });
    }

    @bind
    onChange(data, { name, value }) {
        let updatedData = { ...data };
        if(name === 'alias') {
            updatedData = set(updatedData, 'generatedRedirectUri', `https://${window.location.host}/iam/auth/realms/Affectli/broker/${value}/endpoint`);
        }
        this.setState({ data: updatedData });
    }

    render(): Object {
        const { onClose, isLoading, canEdit } = this.props;
        const { data } = this.state;
        return (
            <>
                <ModalDialog
                    title={`Add OpenID Connect Provider`}
                    onClose={onClose}
                    actions={isLoading ? <CircularProgress size={24} color="primary" /> : <Button onClick={this.onFormSubmit}>Save</Button>}
                >
                    <FormGenerator 
                        components={this.buildComponents(undefined, canEdit)} 
                        ref={this.formRef} 
                        data={data}
                        onChange={this.onChange}
                        disabled={!canEdit}
                    />
                </ModalDialog>
            </>
        );
    }
}

export default connect(
    state => ({
        isLoading: state.entities.createEntity.isLoading,
        primaryClasses: state.app.allPrimaryClasses.records,
    }),
    {
        loadSecurityGeneral,
        ssoAddIdentityProvider,
        showToastr
    }
)(AddIdentityProvider);
