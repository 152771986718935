/* @flow */

import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Typography, Tooltip } from '@mic3/platform-ui';

import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { isDefined } from 'app/utils/utils';


const DarkLabel = styled(Typography)`
    color: ${({ theme }) => theme.material.colors.text.secondary} !important;
    font-size: 14px !important;
    cursor: pointer;
`;

class GridEntitySharing extends PureComponent<Object, Object> {

    inputField = React.createRef();
  
    @bind
    @memoize()
    openSharingSidebar(e, params, entityType){
        e.stopPropagation();
        this.props.openSidebar({...params, entityType});
    }

    render() {
        const { data, entityType, reloadList, type } = this.props;
        const { workspaces } = data || {};
        const params = { id: data.id, type, title: 'Sharing', reloadList };
        let counterUser = 0;
        let counterTeam = 0;

        workspaces.forEach((item)=> {if(isDefined(item.team)){counterTeam = counterTeam + 1;}
            if(isDefined(item.user)){
                counterUser = counterUser + 1 ;
            } } );

        const userTitle = counterUser === 1 ? 'user' : 'users'; 
        const teamTitle = counterTeam === 1 ? 'team' : 'teams';

        return (     
            ( counterUser !== 0 || counterTeam !== 0 ) ?
                <Tooltip title = 'Open sharing'>
                    <DarkLabel onClick={(e)=> this.openSharingSidebar(e, params, entityType )}>  
                        {counterTeam !==0 && `${counterTeam} ${teamTitle}, `}
                        {counterUser !==0 && `${counterUser} ${userTitle}`}
                    </DarkLabel>
                </Tooltip>
                : null
        );
    };
}
export default (GridEntitySharing);