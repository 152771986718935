/* @flow */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Title from 'app/components/atoms/Title/Title';
import ItemColumn from './ItemColumn';
import ListItemBase from './ListItemBase';
import ItemRow from './ItemRow';
import Loader from 'app/components/atoms/Loader/Loader';

// UI IMPORTS

// STYLE IMPORTS
const SubTitle = styled(Title)`
    font-size: 0.8rem !important;
`;

const ListText = styled.div`
    font-size: 0.8em;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${({ theme }) => theme.base.textColor || ''};
`;

const ImageColumn = styled.div`
    background: url('${({ src }) => src}') center center;
    background-size: 100% 100%;;
    width: 150px;
    height: 100px;
`;
const StyledDiv = styled.div`
    background-color: #1C263B;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 99;
    opacity: 0.5;
    cursor: not-allowed;
`;

const ItemRowStyled = styled(ItemRow)`
    position: relative;
`;

const ListItem = (props: Object) => {
    const {
        component, title, subTitle, text, actions, small, shadow, raised, beforeTitle,
        className, rowWrap, image, isLoading, itemPadding, disabled, ...rest
    } = props;
    return (
        <ListItemBase {...rest} small={small} raised={shadow || raised} className={className}>
            <ItemRowStyled className="ItemRow" wrap={rowWrap}>
                { disabled ? <StyledDiv /> : null }
                {image && <ImageColumn src={image} />}
                {component && <ItemColumn className="ItemColumn Component" itemPadding={itemPadding}>{component}</ItemColumn>}
                <ItemColumn grow itemPadding={itemPadding}>
                    <SubTitle forwardedAs="h4" className="beforeTitle">{beforeTitle}</SubTitle>
                    <Title as="h3">{title}</Title>
                    <SubTitle forwardedAs="h4">{subTitle}</SubTitle>
                    <ListText>{text}</ListText>
                </ItemColumn>
                {actions && <ItemColumn itemPadding={itemPadding}>{actions}</ItemColumn>}
                {isLoading && <Loader absolute size="20"/>}
            </ItemRowStyled>
        </ListItemBase>
    );
};

ListItem.propTypes = {
    shadow: PropTypes.bool,
    raised: PropTypes.bool,
    small: PropTypes.bool,
    component: PropTypes.node,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    login: PropTypes.string,
    subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    actions: PropTypes.node,
    itemPadding: PropTypes.string
};

export default ListItem;
