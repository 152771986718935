import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Typography, Box, Button, Link, Fab, Tooltip, ListItemAvatar, ListItemText, Avatar, ListItem  } from '@mic3/platform-ui';

import history from 'store/History';

import affectliSso from 'app/auth/affectliSso';
import { bind } from 'app/utils/decorators/decoratorUtils';
import { getAttachmentUrl } from 'app/utils/attachments/attachmentsUtils';
import { ROCKET_CHAT_URL, SITE_URL } from 'app/config/config';

import Flex from 'app/components/atoms/Flex/Flex';
import Icon from 'app/components/atoms/Icon/Icon';

const Title = styled(Typography)`
    color: #fff;
    font-size: 24px !important;
    margin-bottom: 4px !important;
`;

const SubTitle = styled(Typography)`
    color: #fff;
    font-size: 16px !important;
    margin-bottom: 20px !important;
`;

const SignInText = styled(Typography)`
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 2px !important;
    font-weight: 300 !important;
`;

const Actions = styled(Box)`
    margin-bottom: 40px;
`;

const ButtonStyled = styled(Button)`
    margin: 0 4px !important;
    margin-bottom: ${({ $isMobile }) => $isMobile ? '8px !important' : '0'};
`;

const Wrapper = styled(Flex)`
    height: 100vh;
    justify-content: center;
    flex-direction: column;

    & .Icon {
        &:before {
            font-size: 110px !important;
        }
    }
`;

const ProfileItem = styled(ListItem)`
    & .MuiListItemText-primary {
        color: #fff;
    }
`;

const ChatButton = styled(Fab)`
    right: 12px;
    bottom: 12px;
    border-radius: 6px !important;
    position: absolute !important;

    & .Icon:before {
        font-size: 32px !important;
    }
`;

class LicenseErrorPage extends Component {
    @bind
    handleLogout() {
        affectliSso.logout();
    }

    @bind
    handleLicenseClick() {
        history.push(`/licence`);
    }

    @bind
    handleChatClick() {
        window.open(ROCKET_CHAT_URL, 'chat');
    }

    @bind
    handleSiteClick() {
        window.open(SITE_URL, 'af');
    }

    render() {
        const { profile, isMobile, message } = this.props;
        const { permissions, isAdmin } = profile;
        const permissionsSet = new Set(permissions);
        const canView = isAdmin || permissionsSet.has('admin.license');
        const profileImage = profile.image && getAttachmentUrl(profile.id, 'user', profile.image);
        const rootUrl = window.location.origin.replace(/^https?:\/\//, '');
        const title = message.includes('is not allowed by the Affectli license') ? `Oops! The domain "${rootUrl}" is not allowed by the Affectli licence.` : `Oops! The licence for "${rootUrl}" has expired.`;

        return (
            <>
                <Wrapper grow>
                    <Icon name="cancel" type="mdi" hexColor="#dadada" />
                    <Title variant="h3" align="center">{title}</Title>
                    <SubTitle variant="body1">Please update your licence key.</SubTitle>
                    <Actions flexDirection={isMobile ? 'column' : 'row'}>
                        {canView && (
                            <ButtonStyled onClick={this.handleLicenseClick} fullWidth={isMobile} $isMobile={isMobile}>
                                Manage Licence
                            </ButtonStyled>
                        )}
                        <ButtonStyled variant="outlined" onClick={this.handleSiteClick} fullWidth={isMobile}>
                            Go to affectli.com
                        </ButtonStyled>
                    </Actions>
                    <SignInText variant="caption">You are signed as</SignInText>
                    <Box>
                        <ProfileItem component="div">
                            <ListItemAvatar>
                                <Avatar src={profileImage} size="lg" initials={profile.name} />
                            </ListItemAvatar>
                            <ListItemText primary={profile.name} secondary={profile?.primary?.email} />
                        </ProfileItem>
                    </Box>
                    <Link onClick={this.handleLogout}>
                        <Typography variant="body2">Logout to switch user...</Typography>
                    </Link>
                </Wrapper>
                <Tooltip title="Open Support Chat">
                    <ChatButton color="primary" onClick={this.handleChatClick} >
                        <Icon name="messenger" type="af" />
                    </ChatButton>
                </Tooltip>
            </>
        );
    }
}

export default connect(state => ({
    isMobile: state.global.isMobile,
    profile: state.user.profile,
}), null)(LicenseErrorPage);
