/* @flow */

import { isDefined } from 'app/utils/utils';

export const operationOptions = [

    {label: 'is null', value: 'is null'},
    {label: 'is not null', value: 'is not null'},

    {label: 'equal', value: '='},
    {label: 'not equal', value: '<>'},
    {label: 'greater than', value: '>'},
    {label: 'greater than equal', value: '>='},
    {label: 'less than', value: '<'},
    {label: 'less than equal', value: '<='},
    {label: 'contains', value: 'contains'},
    {label: 'not contains', value: 'not contains'},
    {label: 'starts with', value: 'starts with'},
    {label: 'not starts with', value: 'not starts with'},
    {label: 'ends with', value: 'ends with'},
    {label: 'not ends with', value: 'not ends with'},

    {label: 'in', value: 'in'},
    {label: 'not in', value: 'not in'},
    {label: 'array contains', value: '@>'},
    {label: 'array is contained by', value: '<@'},
    {label: 'array overlaps', value: '&&'},


    {label: 'between', value: 'between'},
    {label: 'not between', value: 'not between'},

];

export const getNumberOfValues = (op: string) => {
    const numberOfValues = {
        'is null': 0,
        'is not null': 0,

        'between': 2,
        'not between': 2,

        // -1 means arryay type
        'in': 99,
        'not in': 99,
        '@>': 99,
        '<@': 99,
        '&&': 99,
    }[op];
    return isDefined(numberOfValues) ? numberOfValues : 1;
};
