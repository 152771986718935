/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Immutable from 'app/utils/immutable/Immutable';
import { ContactInfo } from 'app/utils/types/interfaces';
import { createEvent } from 'app/utils/http/event';
import { groupBy } from 'app/utils/lo/lo';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import { CollapsableAddressForm } from '../CollapsableAddressForm/CollapsableAddressForm';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { Button, Menu, MenuItem } from '@mic3/platform-ui';
import { getContactInfoFieldProps, getContactInfoSubTypes, ContactInfoMap } from 'app/components/molecules/ContactDetails/ContactDetails';

type ContactInfoFormPropTypes = {
    name: ?string,
    value: ContactInfo[],
    onChange: Function,
    menuAnchorEl: ?Object,
}

export class ContactInfoListForm extends PureComponent<Object, ContactInfoFormPropTypes> {

    static propTypes: Object = {
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        value: PropTypes.array,
    };

    formRef: Object = React.createRef()
    state: Object = { menuAnchorEl: null };

    get contactInfo(): ContactInfo[] {
        return this.props.value || Immutable([]);
    }

    componentDidMount(){
        const { pushSubFormReferences } = this.props;
        if(pushSubFormReferences) {
            pushSubFormReferences(this.formRef);
        }
    }

    @bind
    @memoize()
    buildMenuItems(menuAnchorEl: ?Object): Array<Object> {
        return Object.keys(ContactInfoMap).map(key => (
            <MenuItem key={ContactInfoMap[key]} onClick={() => this.addContactInfo(key)}>
                {ContactInfoMap[key]}
            </MenuItem>
        ));
    }

    @bind
    openMenu(event: Object) {
        this.setState({ menuAnchorEl: event.currentTarget });
    }

    @bind
    closeMenu() {
        this.setState({ menuAnchorEl: null });
    }

    @bind
    handleChangeContactInfo(data: object, variables: Object) {
        const index = Number(variables.name.match(/[0-9]+/)[0]);
        this.handleChange(this.contactInfo.map((contact: Object, i: number) => {
            const path = variables.name.split('.')[1];
            if (i === index) {
                return Immutable({ ...contact, [path]: variables.value });
            }
            if(i !== index && path === 'is_primary') {
                return Immutable({ ...contact, [path]: false });
            }
            return contact;
        }));
    }

    @bind
    handleChange(contactInfoList: ContactInfo[]) {
        this.closeMenu();
        this.props.onChange(createEvent('change',
            { name: this.props.name, value: contactInfoList }
        ));
    }

    @bind
    addContactInfo(type: string) {
        this.handleChange(Immutable([...this.contactInfo, {
            address: undefined,
            type,
            is_primary: false,
            sub_type: '',
            identifier: ''
        }]));
    }

    @bind
    removeContactInfo(key: number) {
        this.handleChange(this.contactInfo.filter((item, index) => index !== key));
    };

    @bind
    @memoize()
    renderContactInfoForm(props, index) {
        const { value: contactInfo } = props;
        const contactProps = getContactInfoFieldProps(contactInfo);
        let sub_types = getContactInfoSubTypes(contactInfo.type);
        if (contactInfo.sub_type && !sub_types.find(sub_type => sub_type === contactInfo.sub_type)) {
            sub_types = [...sub_types, contactInfo.sub_type];
        }
        const selectOptions = sub_types.map(value => ({ value, label: value }));
        const label = contactProps.label || ContactInfoMap[contactInfo.type];
        const selectedType = ContactInfoMap[contactInfo.type];
        const type = {'Social Media Profile': 'url', 'Email': 'email', 'Website': 'url'};
        return [
            {
                type: 'text',
                properties: {
                    label,
                    name: `identifier`,
                    type: type[selectedType] || 'text',
                },
                constraints: {
                    ...{
                        email: { email: true },
                        url: { url: true },
                    }[type[selectedType]],
                    required: true,
                }
            },
            {
                type: 'typeahead',
                properties: {
                    label: 'Type',
                    name: `sub_type`,
                    options: selectOptions,
                },
                constraints: {
                    required: true,
                }
            },
            {
                type: 'checkbox',
                properties: {
                    label: 'Primary',
                    name: `is_primary`,
                }
            },
            {
                type: 'outcome',
                properties: {
                    label: 'Delete',
                    name: '',
                    variant: 'text',
                    onClick: props.onRemove,
                    fullWidth: true,
                }
            }
        ];
    }

    @bind
    renderContactInfoField(contact: Object, index: number) {
        const isAddress = contact.type === 'address';
        const formProps = {
            value: contact,
            onRemove: () => this.removeContactInfo(index),
        };
        return {
            type: 'group',
            properties: { name: `[${index}]`},
            children: isAddress ? [{
                type: 'custom',
                properties: {
                    onRemove: formProps.onRemove,
                    // name: `address`,
                    cid: 'address' + index,
                    Component: props => <CollapsableAddressForm {...props} key={index} {...formProps} />
                },
            } ]: this.renderContactInfoForm(formProps, index)
        };
    }


    @bind
    renderContactInfoGroup(groupName: string, group: Object) {
        return {
            type: 'panel',
            properties: { header: `Contact Details - ${groupName}`},
            children: group.map((contact) => {
                let index = 0;
                this.contactInfo.forEach((c, i) => {
                    if(c === contact) {
                        index = i;
                    }
                });
                return this.renderContactInfoField(contact, index);
            })};
    }

    @bind
    @memoize()
    renderContactInfoCards(contactInfo: Array<Object>) {
        const grouped = groupBy(contactInfo, 'type');
        const components = Object.keys(grouped).map(groupName =>
            this.renderContactInfoGroup(groupName, grouped[groupName])
        );
        return (
            <FormGenerator
                name="contactInfo"
                ref={this.formRef}
                components={components}
                data={contactInfo}
                onChange={this.handleChangeContactInfo}
            />
        );
    }

    render() {
        const { disabled } = this.props;
        const { menuAnchorEl } = this.state;
        return (
            <div style={{ width: '100%' }}>
                {this.renderContactInfoCards(this.contactInfo)}
                <Button
                    name='addContactInfo'
                    onClick={this.openMenu}
                    fullWidth
                    disabled={disabled}
                    aria-owns={menuAnchorEl ? 'contact-menu' : undefined}
                >Add Contact Info</Button>
                <Menu
                    id='contact-menu'
                    name='menu'
                    anchorEl={menuAnchorEl}
                    open={Boolean(menuAnchorEl)}
                    onClose={this.closeMenu}
                    style={{ width: '100%'}}
                >
                    {this.buildMenuItems(menuAnchorEl)}
                </Menu>
            </div>
        );

    }
}
