/* @flow */
import { Circle as CircleStyle, Fill, Stroke, Style, Text, Icon } from 'ol/style';
import mdiMapping from 'app/components/molecules/Map/EntityPin/entitiesPinMap.json';
import customIconSet from 'app/components/molecules/Map/EntityPin/customEntityPin.js';
import { getPriorityColor } from 'app/config/aboxConfig';
import theme from 'app/themes/theme.default';
import { LAYERS, PROCESSLAYERS, encodeSvgTobase64 } from 'app/utils/maps/layer/layerUtils';
import Chart from 'app/utils/maps/layer/ol-ext/Chart';
import { getAttachmentUrl } from 'app/utils/attachments/attachmentsUtils';
import { isDefined } from 'app/utils/utils';


export const getClusterStyleParams = (featureCount, selected) => {
    const clusterColors = {
        '5': [72, 244, 66, 1],
        '15': [209, 244, 66, 1],
        '30': [244, 241, 66, 1],
        '50': [244, 178, 65, 1],
        '100': [244, 91, 65, 1]
    };
    return {
        color: clusterColors[Object.keys(clusterColors).find(key => featureCount < Number(key)) || '100'],
        strokeColor: selected ? '#000' : '#fff',
        strokeWidth: selected ? 2 : 1,
        radius: 16 + featureCount / 400,
        fontSize: 13 + featureCount / 1000
    };
};

export const clusterStyle = (features, selected) => {
    const { color, strokeColor, strokeWidth, radius, fontSize } = getClusterStyleParams(features.length, selected);
    try {
        return new Style({
            image: new CircleStyle({
                radius: radius,
                stroke: new Stroke({
                    color: strokeColor,
                    width: strokeWidth
                }),
                fill: new Fill({
                    color: color
                })
            }),
            text: new Text({
                text: features.length.toString(),
                font: fontSize + 'px sans-serif',
                fill: new Fill({
                    color: '#fff'
                }),
                stroke: new Stroke({
                    color: 'rgb(0, 0, 0)',
                    width: 3
                })
            }),
        });
    }
    catch {
        // eslint-disable-next-line no-console
        console.log('Error in implementing feature style in ', features);
    }
    
};

export const entityPinStyle = (feature: Object, map: Object, selected: boolean = false) => {
    const features = feature.get('features');
    if (features && features.length > 1) {
        return clusterStyle(features, selected);
    } else if (features && features.length === 1) {
        return singleFeatureStyle(features[0], map, selected);
    }
    return singleFeatureStyle(feature, map, selected);
};

// eslint-disable-next-line max-len
export const singleFeatureStyle = (feature: Object, map: Object, selected: boolean = false, progress: number, counter: number, layerType: string, progressDefaultVal: number, 
    counterDefaultVal: number, is3DEnabled: boolean, style: Object, progressConstraints: any, showCounters: boolean) => {
    const featureType = feature.getGeometry().getType();
    const attributes = feature.get('attributes') ? feature.get('attributes') : feature?.values_?.features?.[0].values_.attributes;
    const { relationCounter } = feature?.values_?.features?.[0]?.values_ || {};
    const { iconName, iconColor, graphic, variables, size, svg, svgGraphic, svgTransformation, classAbout, related, primaryClass } = attributes || {};
    const { entitySvg, entityGraphic } = primaryClass || {};
    const zoomLevel = primaryClass?.entitySvgVisibleZoom || 4;
    const uploadedSvg = svg || entitySvg;
    const galleryGraphicSvg = svgGraphic || entityGraphic;
    if (!is3DEnabled && (featureType === 'Point' || featureType === 'MultiPoint')) {
        const { _url } = graphic || {};
        const name = style?.pinStyle === 'cluster' ? iconName || 'map-marker' : style?.['iconName'] || iconName || 'map-marker';
        let color =  style?.pinStyle === 'cluster' ? iconColor ||  '#00BCD4':  style?.['valFillColour'] || iconColor  || '#00BCD4';
        //default diameter is 32 and radius is 16.
        const pointSize = style?.pinStyle === 'cluster' ? 16 : (style?.['valPointSize'] / 2) || 16;
        const mapTarget = map?.values_?.target || 'map';
        if (variables) {
            color = theme.priorityGradients[getPriorityColor(variables.priority || 3)][0];
        }
        if (_url && map.getView().getZoom() > 14) {
            return getWfsStyle(_url, 100, map);
        }
        if ((svg || svgGraphic) && (mapTarget === 'aboutMap' || mapTarget === 'svgMap')) {
            return createSvgStyle(svg, entitySvg, svgGraphic, entityGraphic, svgTransformation, map, classAbout, mapTarget);
        }
        if ((uploadedSvg || galleryGraphicSvg) && mapTarget === 'map' && map?.getView().getZoom() > zoomLevel) {
            return createSvgStyle(svg, entitySvg, svgGraphic, entityGraphic, svgTransformation, map, classAbout, mapTarget);
        }
        return createMaterialIcon(name, color, selected, size, feature, progress, counter, layerType, related || null,
            progressDefaultVal, counterDefaultVal, pointSize, progressConstraints, relationCounter, showCounters);
    }
    if (featureType === 'GeometryCollection') {
        return setGeomCollectionStyle(feature);
    }
    if (featureType === 'LineString' || featureType === 'MultiLineString') {
        const properties = feature.get('properties');
        const { lineColor, lineWidth, lineDashed } = properties || {};
        if(lineDashed)
            return new Style({
                stroke: new Stroke({
                    color: lineColor || 'blue',
                    width: lineWidth || 4,
                    lineDash: [40,40]
                })
            });
        return new Style({
            stroke: new Stroke({
                color: lineColor || 'blue',
                width: lineWidth || 4,
            })
        });
    }
    if (featureType === 'Polygon' || featureType === 'MultiPolygon') {
        return new Style({
            stroke: new Stroke({
                color: iconColor || '#00BCD4',
                width: 3
            }),
            fill: new Fill({
                color: iconColor || '#00BCD4'
            })
        });
    }
};

export const createSvgStyle = (svg: any, entitySvg: any, svgGraphic: Object, entityGraphic: Object,
    svgTransformation: Object, map, classAbout: boolean, mapTarget) => {
    const entityLocationSvg = svg || svgGraphic;
    const classSvg = entitySvg || entityGraphic; 
    const finalSvg = entityLocationSvg || classSvg;
    let src = null;
    
    if (finalSvg?.id) {
        src = getAttachmentUrl(finalSvg.id, 'graphic');
    }
    else if (finalSvg) {
        src = encodeSvgTobase64(finalSvg);
    }

    const imgSize = [100,100];
    const requiredImgSize =  mapTarget === 'aboutMap' ? [64,64] : [128,128];
    const scale = [requiredImgSize[0]/imgSize[0], requiredImgSize[1]/imgSize[1]];
    const img = getImageCanvas(src, 100, map);
    const { width, height } = img;

    return new Style({
        image: new Icon({
            img: img,
            scale,
            imgSize: [width, height]
        })
    });
};

// eslint-disable-next-line max-len
export const createMaterialIcon = (name: string, color: string, selected: boolean, relatedSize: number = 16, feature, layerProgress: number, layerCounter: number, layerType: string, related: boolean,
    progressDefaultVal: number, counterDefaultVal: number, pointSize: number, progressConstraints: any, relationCounter: number, showCounters: boolean) => {
    const taskProgress = feature?.values_?.features?.[0]?.values_?.attributes?.primary?.['progress'];
    const entityProgress = feature?.values_?.attributes?.primary?.[layerProgress] ||
     feature?.values_?.features?.[0]?.values_?.attributes?.primary?.[layerProgress];
    const currentProgress = taskProgress || entityProgress || progressDefaultVal || 0;
    const minProgress = progressConstraints?.min;
    const maxProgress = progressConstraints?.max;
    let progress = 0;
    if(taskProgress > 0) 
        progress = taskProgress;
    else if(isDefined(minProgress) && isDefined(maxProgress)) {
        if (minProgress !== maxProgress && currentProgress > minProgress) { 
            progress = Math.abs((currentProgress - minProgress) / (maxProgress - minProgress) * 100);
        }
    }
    else {
        progress = progressDefaultVal;
    }

    const counter = feature?.values_?.attributes?.primary?.[layerCounter] ||
     feature?.values_?.features?.[0]?.values_?.attributes?.primary?.[layerCounter] || counterDefaultVal || 0;
    const size = relatedSize / 2; 
    const markerPoint = mdiMapping?.[name] || customIconSet?.[name] || mdiMapping['map-marker'];
    const font = `normal ${pointSize * 2 * 0.75}px affectli, "Material Design Icons"`;

    try {
        const fontSize = 11 < pointSize ? new Text({
            font: font,
            text: 'drawing-layer' === layerType ? stringDivider = (str, 60, spaceReplacer, font) : String.fromCodePoint(markerPoint),
            fill:  new Fill({color: 'white'}) 
        }) : null;

        const relatedFontSize = 11 < size ? new Text({
            font: `normal ${size * 2 * 0.75}px affectli, "Material Design Icons"`,
            text: String.fromCodePoint(markerPoint),
            fill:  new Fill({color: 'white'}) 
        }) : null;

        const defaultStyle = new Style({
            image: new CircleStyle({
                radius: pointSize || size,
                fill: new Fill({ color }),
                stroke: new Stroke({
                    color: 'white',
                    width: 0
                }),
            }),
            text: fontSize
        });

        const relatedDefaultStyle = new Style({
            image: new CircleStyle({
                radius: size,
                fill: new Fill({ color }),
                stroke: new Stroke({
                    color: 'white',
                    width: 0
                }),
            }),
            text: relatedFontSize
        });
        const numberCounterStyle = new Style({
            image: new CircleStyle({
                radius: counter || relationCounter > 0 ? 10 : 0,
                fill: new Fill({ color }),
                displacement: [21,21]
            }),
            text: new Text({
                font: 'normal 14px',
                textAlign: 'center',
                text: relationCounter ? `${relationCounter}` : `${counter > 99 ? '99+' : (counter > 0 ? counter.toString() : '')}`,
                offsetY: -21,
                offsetX: counter > 99 ? 22 : 21,
                fill: new Fill({color: 'white'})
            })
        });
        const progressStyle = new Style({
            image: new Chart({
                type: 'donut',
                radius: 35,
                max: 5,
                colors: [color, 'grey'],
                data: [progress,100 - progress],
                stroke: new Stroke({
                    color: '#fff',
                    width: 0
                })
            })
        });
        if(related)
            return [relatedDefaultStyle];
        if(selected)
            return [defaultStyle];
        if((progress || currentProgress) && (showCounters || counter)) {
            return [defaultStyle, progressStyle, numberCounterStyle];
        }
        if(progress || currentProgress) {
            return [defaultStyle, progressStyle];
        }
        if(layerType === LAYERS.event || PROCESSLAYERS.includes(layerType)) {
            return [defaultStyle];
        }
        if(showCounters || counter) {
            return [defaultStyle, numberCounterStyle];
        }
        return [defaultStyle];
    }
    catch {
        // eslint-disable-next-line no-console
        console.log('Error in implementing feature style in ', feature);
    }

};

export const createBubble = (radius) => {
    return new Style({
        image: new CircleStyle({
            radius,
            fill: new Fill({ color: [0, 81, 255, 0.3] })
        })
    });
};

const getWfsStyle = (src: string, size: any, map: Object) => {
    const img = getImageCanvas(src, size, map);
    const style = new Style({
        image: new Icon({
            img: img,
            imgSize: [size, size]
        })
    });
    return style;
};

export const getImageCanvas = (src: string, size: any, map: Object) => {
    const canvas = document.createElement('canvas');
    canvas.setAttribute('width', size);
    canvas.setAttribute('height', size);
    canvas.setAttribute('class', 'svgPins');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.onload = function() {
        const { width, height } = img;
        const aspectRatio = width / height;
        let imgWidth = size;
        let imgHeight = size / aspectRatio;
        if (aspectRatio < 1) { 
            imgWidth = size * aspectRatio;
            imgHeight = size;
        }
        const xOffset = (size - imgWidth) / 2;
        const yOffset = (size - imgHeight) / 2;
        ctx.drawImage(img, xOffset, yOffset, imgWidth, imgHeight);
        map.render();
    };
    img.src = src;
    return canvas;
};


export const stringDivider = (str: String, widthInPixels: number = 100, spaceReplacer: String, font: String) => {
    if(!str?.length) return;
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = font;
    let lineWidth = 0;
    let result = '';
    for (let i = 0; i < str.length; i++) {
        const char = str[i];
        const charWidth = context.measureText(char).width;
        if (lineWidth + charWidth <= widthInPixels) {
            result += char;
            lineWidth += charWidth;
        } else {
            result += spaceReplacer + char;
            lineWidth = charWidth;
        }
    }
    return result;
};

const setGeomCollectionStyle = (feature) => {
    const geometries = feature?.getGeometry()?.getGeometries();
    const properties = feature?.values_?.attributes;
    const { iconColor } = properties || {};
    const styles = geometries && geometries.map((geometry) => {
        const type = geometry.getType();
        if (type === 'Polygon' || type === 'MultiPolygon') {
            return new Style({
                stroke: new Stroke({
                    color: iconColor || '#00BCD4',
                    width: 3,
                }),
                fill: new Fill({
                    color: iconColor || '#00BCD4',
                }),
            });
        } else if (type === 'LineString' || type === 'MultiLineString') {
            return new Style({
                stroke: new Stroke({
                    color: iconColor || '#FF5722',
                    width: 2,
                }),
            });
        } else if(featureType === 'Point' || featureType === 'MultiPoint') {
            new Style({
                image: new CircleStyle({
                    radius: 10,
                    fill: new Fill({ iconColor }),
                    stroke: new Stroke({
                        color: 'blue',
                        width: 0
                    }),
                })
            });
        }
    });
    return styles;
};