/* @flow */

import { DOMParser } from '@xmldom/xmldom';

import AbstractDefinitionsBuilder from 'app/components/Designer/Builders/AbstractDefinitionsBuilder/AbstractDefinitionsBuilder';
import { bind } from 'app/utils/decorators/decoratorUtils';
import { prettifyXml, removeSpaces, getExecutionEvents, getEntityTypeOptions } from 'app/utils/designer/process/processDesignerUtils';

const cellStyle = {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '160px',
    whiteSpace: 'nowrap',
};

class EventListenersBuilder extends AbstractDefinitionsBuilder {

    static defaultProps = {
        fields: [
            { type: 'typeahead', properties: {
                label: 'Event', name: 'events', multiple: true,
                valueField: 'value', clearable: false,
                options: getExecutionEvents().map(ev => ({ label: ev, value: ev })),
                defaultValue: [],
            }, constraints: { required: true }, tableCellValueProps: { style: cellStyle}},
            { type: 'boolean', properties: {
                label: 'Rethrow Event',
                name: 'rethrowEvent',
                defaultValue: false,
                onChange: (evnt) => {
                    return evnt.target.value ?
                        [evnt.target, { name: 'implementationType', value: null }, { name: 'entityType', value: null, }, { name: 'implementation', value: '' } ] :
                        [evnt.target, { name: 'implementationType', value: 'class'}, { name: 'implementation', value: '' }, { name: 'throwEvent', value: null }, { name: 'entityType', value: 'task' }];
                }
            }, table: false },

            { type: 'typeahead', properties: {
                label: 'Entity Type', name: 'entityType',
                valueField: 'value', clearable: false,
                options: getEntityTypeOptions(),
                defaultValue: getEntityTypeOptions()[0],
                isVisible: data => !data.rethrowEvent,
            }, constraints: { required: true }},
            { type: 'typeahead', properties: {
                label: 'Implementation Type', name: 'implementationType',
                valueField: 'value', clearable: false,
                options: [{ label: 'Class', value: 'class'}, { label: 'Delegate Expression', value: 'delegateExpression'}],
                defaultValue: 'class',
                isVisible: data => !data.rethrowEvent,
            }, constraints: { required: true }},

            { type: 'typeahead', properties: {
                label: 'Rethrow Event Type', name: 'throwEvent',
                valueField: 'value', clearable: false,
                options: [{ label: 'Error', value: 'error'}, { label: 'Message', value: 'message'}, { label: 'Signal', value: 'signal'}, { label: 'Global Signal', value: 'globalSignal'}],
                defaultValue: 'error',
                isVisible: data => data.rethrowEvent,
            }, constraints: { required: true }},
            { type: 'text', properties: { label: 'Value', name: 'implementation', clearable: false, }, constraints: { required: true }},
        ]
    }

    @bind
    deNormalizeValues(definition: Array<Object>): Array<Object> {
        const doc = new DOMParser().parseFromString(removeSpaces(definition));
        const process =  doc.getElementsByTagName('process')[0] || doc.getElementsByTagName('bpmn:process')[0];
        let eventListeners = null;
        Array.from(process.childNodes).forEach((child) => {
            if(child.localName === 'extensionElements') {
                eventListeners =  child.getElementsByTagName('flowable:eventListener');
            }
        });
        return Array.from(eventListeners || []).map((elem) => {
            const value = { events: elem.getAttribute('events').split(','), rethrowEvent: false };
            if(elem.getAttribute('class')) {
                value.implementationType = 'class';
            }
            if(elem.getAttribute('delegateExpression')) {
                value.implementationType = 'delegateExpression';
            }
            if(value.implementationType) {
                value.implementation = elem.getAttribute(value.implementationType);
                value.entityType = elem.getAttribute('entityType');
                return value;
            }

            value.throwEvent = elem.getAttribute('throwEvent');
            value.rethrowEvent = true;
            switch (value.throwEvent) {
                case 'error':
                    value.implementation = elem.getAttribute('errorCode');
                    break;
                case 'message':
                    value.implementation = elem.getAttribute('messageName');
                    break;
                case 'signal': case 'globalSignal':
                    value.implementation = elem.getAttribute('signalName');
                    break;
                default:
            }
            return value;
        });
    }

    @bind
    normalizeValues(value: Array<Object>): Array<any> {
        const { value: definition } = this.props;
        let doc = new DOMParser().parseFromString(definition);
        const process =  doc.getElementsByTagName('process')[0] || doc.getElementsByTagName('bpmn:process')[0];
        let eventListeners = null;
        let extensionElements = null;
        Array.from(process.childNodes).forEach((child) => {
            if(child.localName === 'extensionElements') {
                extensionElements = child;
                eventListeners =  child.getElementsByTagName('flowable:eventListener');
            }
        });

        const eventListenersNew = value.map(({ events, entityType, implementation, implementationType, rethrowEvent, throwEvent }) => {
            if(rethrowEvent) {
                let prop = '';
                switch (throwEvent) {
                    case 'error':
                        prop = `errorCode="${implementation}"`;
                        break;
                    case 'message':
                        prop = `messageName="${implementation}"`;
                        break;
                    case 'signal': case 'globalSignal':
                        prop = `signalName="${implementation}"`;
                        break;
                    default:
                }
                return `<flowable:eventListener events="${events.join(',')}" throwEvent="${throwEvent}" ${prop} />`;
            }
            return `<flowable:eventListener events="${events.join(',')}" entityType="${entityType}" ${implementationType}="${implementation}" />`;
        }).join('');

        if(!extensionElements) {
            const process =  doc.getElementsByTagName('process')[0] || doc.getElementsByTagName('bpmn:process')[0];
            const startEvent =  doc.getElementsByTagName('startEvent')[0];
            
            extensionElements = new DOMParser().parseFromString(`<extensionElements></<extensionElements>`);
            extensionElements = extensionElements.getElementsByTagName('extensionElements')[0];
            
            process.insertBefore(extensionElements, startEvent);

            doc = new DOMParser().parseFromString(String(doc));
            Array.from(process.childNodes).forEach((child) => {
                if(child.localName === 'extensionElements') {
                    extensionElements = child;
                }
            });
        }

        Array.from(eventListeners || []).forEach((elem) => {
            extensionElements.removeChild(elem);
        });

        if(eventListenersNew) {
            extensionElements.appendChild(new DOMParser().parseFromString(eventListenersNew));
        }

        return prettifyXml(String(doc));
    }

};

export default EventListenersBuilder;
