/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Typography, Grid, Popover, IconButton, TextField, Divider, Checkbox } from '@mic3/platform-ui';
import styled from 'styled-components';
import { muiTheme } from 'app/themes/materialUi';
import uuidv1 from 'uuid/v1';

import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { updateEventType, loadEntityPrimaryIndexes, removeEventDataAttribute, loadEventTypeDetails } from 'store/actions/stream/eventsActions';
import { normalizeEventTypeData, buildEventTypeComponents } from 'app/containers/Stream/Events/EventTypes/eventTypesUtils';
import Container from 'app/components/atoms/Container/Container';
import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import HeaderBar from 'app/components/molecules/HeaderBar/HeaderBar';
import ExpansionCard from 'app/components/molecules/ExpansionCard/ExpansionCard';
import Icon from 'app/components/atoms/Icon/Icon';
import Menu from 'app/components/molecules/Menu/Menu';
import MenuItem from 'app/components/molecules/Menu/MenuItem';
import { set } from 'app/utils/immutable/Immutable';
import { arrayObjectEquals } from 'app/utils/utils';
import { capitalizeFirstLetter } from 'app/utils/utils';
import { getFieldByType } from 'app/utils/designer/form/fieldUtils';
import { createEvent } from 'app/utils/http/event';
import AddEventTypeAttribute from './AddEventTypeAttribute';
import DotMenu from 'app/components/molecules/DotMenu/DotMenu';
import { setDocumentTitle } from 'store/actions/app/appActions';



const IconStyled = styled(Icon)`
    &:before {
        color: #9fa3ab;
    }
`;

const DotMenuWrapper = styled.div`
    margin-top: 15px !important;
`;

const actionMenu = {
    processDefinitionId: {
        label: 'Start Process',
        fieldLabel: 'Process Definition ID',
        fieldType: 'processTypeTypeahead',
        icon: 'processes',
        iconType: 'af',
        fieldProps: { valueField: 'id', filterBy: [{ field: 'active', op: '=', value: true }] }
    },
    scriptId: {
        label: 'Run Script',
        fieldLabel: 'Script ID',
        fieldType: 'scriptTypeahead',
        icon: 'script',
        fieldProps: {
            valueField: 'id',
            filterBy: [
                { field: 'active', op: '=', value: true },
                { field: 'lastVersion.id', op: 'is not null' }
            ]
        }
    },
    startMessage: { label: 'Send Process Start Message', fieldLabel: 'Message ID', fieldType: 'text', icon: 'email-outline' }
};

const StyledTypography = styled(Typography)`
    color: ${({theme})=> theme.material.colors.text.secondary};
`;

const StyledExpansionCard = styled(ExpansionCard)`
    width: 96% !important;
`;

const addKeys = (arr) => {
    if (!arr?.length) {
        return [];
    }
    return arr.map(att => ({ ...att, key: att?.key || uuidv1() }));
};

const getMetaAttribute = (data, key) => {
    if (!data) {
        return null;
    }
    let metadata = null;
    if (typeof data?.metadata === 'object') {
        metadata = data?.metadata;
    } else {
        try {
            metadata = JSON.parse(data.metadata);
        } catch (e) {}
    }

    if (metadata && metadata[key] && Array.isArray(metadata[key])) {
        return metadata[key];
    }
    return [];
};

const addNameOfActions = (actions) => {
    if (!actions?.length) {
        return [];
    }
    return actions?.map((data) => {
        const { processDefinitionId, startMessage, scriptId } = data || {};
        let name = '';
        if (processDefinitionId) {
            name = 'processDefinitionId';
        } else if (startMessage) {
            name = 'startMessage';
        } else if (scriptId) {
            name = 'scriptId';
        }
        return { ...data, name };
    });
};

class EventTypeAbout extends PureComponent<Object, Object> {
    static propTypes = {
        updateEventType: PropTypes.func.isRequired
    };

    state = {
        noActionOnDuplicate: !!this.props.details?.noActionOnDuplicate,
        actions: addNameOfActions(addKeys(getMetaAttribute(this.props.details, 'actions'))),
        actionAnchorEl: null,
        formData: this.props.details,
        openAttributeModal: false,
        selectedAttribute: null,
    };

    constructor(props){
        super(props);
        this.loadOptions(props.details);
    }

    formRef = React.createRef();

    componentDidMount() {
        const { details, setDocumentTitle, isSidebar } = this.props;
        const name = details?.name;
        if(name && !isSidebar) {
            setDocumentTitle(name);
        }
    }

    componentDidUpdate(prevProps) {
        const { details, isSidebar, setDocumentTitle } = this.props;
        const { name } = details || {};
        if(!isSidebar && name){
            setDocumentTitle(name);
        }
        const prevAct = getMetaAttribute(prevProps.details, 'actions');
        const newAct = getMetaAttribute(this.props.details, 'actions');
        if (newAct?.length && !arrayObjectEquals(prevAct, newAct)) {
            this.setState({ actions: addNameOfActions(addKeys(newAct)) });
        }
        if (details?.id && prevProps.details?.id !== details.id) {
            const { noActionOnDuplicate } = details;
            this.setState({ noActionOnDuplicate, formData: details });
            this.loadOptions(details);
        }
    }

    @bind
    @memoize()
    loadOptions(details){
        if(!details) return;
        const { entityType, descriptorType } = details || {};
        entityType?.id && this.loadEntityData(entityType.id, 'lookupOptions');
        descriptorType?.id && this.loadEntityData(descriptorType.id, 'descriptorLookupOptions');
    }

    @bind
    @memoize()
    loadEntityData(entityId, name) {
        if(!name) return;
        if (!entityId) {
            return this.setState({ [name]: [] });
        }
        loadEntityPrimaryIndexes(entityId).then((selectedEntity) => {
            const { entityPrimaryIndexes } = selectedEntity || {};
            const options = (entityPrimaryIndexes || [])
                .filter(({ isUnique, state }) => isUnique && state === 'created')
                .map(({ primaryAttribute }) => ({ value: primaryAttribute, label: primaryAttribute }));
            this.setState({ [name]: options });
        });
    }

    @bind
    @memoize()
    buildComponents(lookupOptions, descriptorLookupOptions) {
        return [
            {
                field: 'panel',
                type: 'panel',
                properties: {
                    header: 'Event Type Details',
                    expanded: true
                },
                children: [
                    ...buildEventTypeComponents(lookupOptions, descriptorLookupOptions, this.loadEntityData),
                    {
                        type: 'boolean',
                        properties: {
                            label: 'Active',
                            name: 'active'
                        }
                    }
                ]
            }
        ];
    }

    @bind
    onFormSubmit(event: Event) {
        event.preventDefault();
        const { actions, noActionOnDuplicate } = this.state;
        this.formRef.current.isValidForm().then(({ data, errors }) => {
            const isDataValid = this.isMetaDataValid();
            if (!errors && isDataValid) {
                let normalizedData = normalizeEventTypeData(data);
                normalizedData = set(normalizedData, 'metadata.actions', actions);
                normalizedData = set(normalizedData, 'noActionOnDuplicate', noActionOnDuplicate);
                this.props.updateEventType(normalizedData).then((res) => {
                    if (this.props.reloadList) {
                        this.props.reloadList(res);
                    }
                });
            }
        });
    }

    @bind
    renderSaveButton() {
        return (
            <Button key={113} onClick={this.onFormSubmit} color="primary" form="form" type="submit">
                Save
            </Button>
        );
    }

    @bind
    handleAddAttributeClick(event: Object, menuKey) {
        if (menuKey === 'attributes') {
            this.setState({ openAttributeModal: true });
        } else if ('actions') {
            this.setState({ actionAnchorEl: event.currentTarget });
        }
    }

    @bind
    handleAttributeClose() {
        this.setState({ actionAnchorEl: null });
    }

    handleAttributesItemClick = (selectedValue: string, menuKey) => () => {
        const dataArray = [...this.state[menuKey]];
        dataArray.push({ key: uuidv1(), name: selectedValue, label: actionMenu[selectedValue]?.fieldLabel });
        this.setState({ actions: dataArray, actionAnchorEl: null });
    };

    @bind
    isMetaDataValid() {
        return this.isActionsValid();
    }

    @bind
    isActionsValid() {
        const { actions } = this.state;
        return !actions.some((data) => {
            const { label, name } = data || {};
            const value = data[name];
            return !value || !label;
        });
    }

    @bind
    renderPopoverMenu(menuKey, anchorEl, closeAction, itemClickAction) {
        const open = Boolean(anchorEl);
        return (
            <div key={menuKey}>
                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={() => closeAction()}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'left'
                    }}
                >
                    <Menu>
                        {Object.entries(actionMenu)?.map(([key, data]) => (
                            <MenuItem
                                key={key}
                                icon={data?.icon}
                                iconType={data?.iconType}
                                style={{ width: '180px' }}
                                onClick={itemClickAction(key, menuKey)}
                                name={data?.label}
                            />
                        ))}
                    </Menu>
                </Popover>
            </div>
        );
    }


    @bind
    handleChange(event, index, key) {
        const { name, value, checked } = event.target || {};
        const dataArray = [...this.state[key]];
        let data = { ...(dataArray[index] || {}) };
        if (name === 'indexed') {
            data = set(data, name, checked);
        } else {
            data = set(data, name, value);
        }
        dataArray[index] = data;
        if (key === 'attributes') {
            this.setState({ attributes: dataArray });
        } else if (key === 'actions') {
            this.setState({ actions: dataArray });
        }
    }

    @bind
    removeAttribute(data, section) {
        const eventTypeId = this.props.details.id;
        const { key, id } = data || {};
        if (section === 'attributes') {
            if(!id) return;
            this.props.removeEventDataAttribute(id).then(res => {
                if(!(res instanceof Error)){
                    this.props.loadEventTypeDetails(eventTypeId);
                }
            });
        } else if (section === 'actions') {
            const actions = this.state.actions.filter(data => data.key !== key);
            this.setState({ actions });
        }
    }

    @bind
    renderAttributesCard(data) {
        const { label, attribute, format } = data || {};
        const labelError = Boolean(!label && attribute);
        const attributeError = Boolean(label && !attribute);
        let attributeFormat = format;
        if(attributeFormat === 'none'){
            attributeFormat = 'text';
        }
        return (
            <StyledExpansionCard
                key={data?.key}
                collapsible={false}
                title={label}
            >
                <Grid container alignItems="center" justify="space-around" wrap="nowrap">
                    <div style={{ height: '4.5rem', width: '100%', marginRight: '5px' }}>
                        <TextField
                            error={labelError}
                            helperText={labelError ? 'Label is required' : ''}
                            label="Label"
                            value={data?.label}
                            name="label"
                            readonly
                            clearable={false}
                        />
                    </div>
                    <div style={{ height: '4.5rem', width: '100%' }}>
                        <TextField
                            error={attributeError}
                            helperText={attributeError ? 'Value is required' : ''}
                            label="Value"
                            value={data?.attribute}
                            name="value"
                            readonly
                            clearable={false}
                        />
                    </div>
                    {this.attributeItemMenu(data)}
                </Grid>
                <Grid container>
                    <Grid item xs={6}>
                        <TextField label="Type" value={capitalizeFirstLetter(attributeFormat || 'text')} disabled name="type" style={{ marginRight: '5px' }} />
                    </Grid>
                    {/* <Grid item container xs={6} alignItems="center">
                        <Checkbox
                            label="Indexed"
                            value={Boolean(data?.indexed)}
                            onChange={e => this.handleChange(e, index, 'attributes')}
                            name="indexed"
                            color="primary"
                            style={{ marginLeft: '15px' }}
                        />
                    </Grid> */}
                </Grid>
                <Button
                    style={{ width: '178px', height: '36px' }}
                    size="small"
                    variant="text"
                    startIcon={<Icon name="delete" />}
                    onClick={() => this.removeAttribute(data, 'attributes')}
                >
                    Remove Attribute
                </Button>
            </StyledExpansionCard>
        );
    }

    @bind
    renderActionsCard() {
        const { actions } = this.state;
        if (!actions?.length) {
            return null;
        }
        return (
            <div>
                <br />
                <Divider />
                <br />
                {actions?.map((data, index) => {
                    const { label, name } = data || {};
                    const value = data[name];
                    const labelError = Boolean(!label && value);
                    const valueError = Boolean(label && !value);
                    const { fieldLabel, fieldType, fieldProps } = actionMenu[name] || {};
                    return (
                        <div key={data?.key} style={{ width: '100%', paddingBottom: '1.5rem' }}>
                            <Grid container alignItems="center" justify="space-between" wrap="nowrap">
                                <Typography variant="caption">{actionMenu[name]?.label?.toUpperCase()}</Typography>
                                <IconButton  onClick={() => this.removeAttribute(data, 'actions')}>
                                    <IconStyled name="close" />
                                </IconButton>
                            </Grid>
                            <Grid container alignItems="center" justify="space-around" wrap="nowrap">
                                <div style={{ height: '4.5rem', width: '100%', marginRight: '5px' }}>
                                    <TextField
                                        error={labelError}
                                        helperText={labelError ? 'Label is required' : ''}
                                        label="Label"
                                        onChange={e => this.handleChange(e, index, 'actions')}
                                        value={data?.label}
                                        name="label"
                                    />
                                </div>

                                <div style={{ height: '4.5rem', width: '100%' }}>
                                    {getFieldByType(fieldType, {
                                        error: valueError,
                                        helperText: valueError ? 'Field is required' : '',
                                        label: fieldLabel,
                                        value: data[name],
                                        changeData: e => this.handleChange(createEvent('change', { name: e?.name, value: e?.value }), index, 'actions'),
                                        name,
                                        ...(fieldProps || {})
                                    })}
                                </div>
                            </Grid>
                            {actions?.length - 1 !== index ? (
                                <>
                                    <br />
                                    <br />
                                    <Divider />
                                    <br />
                                </>
                            ) : null}
                        </div>
                    );
                })}
            </div>
        );
    }

    @bind
    renderAttributes(section, attributes) {
        const { actionAnchorEl } = this.state;
        const anchorEl = actionAnchorEl;
        const { noActionOnDuplicate } = this.state;
        return (
            <div key={section}>
                <StyledExpansionCard
                    collapsible={false}
                >
                    <Typography variant="h6">{capitalizeFirstLetter(section)}</Typography>
                    <StyledTypography variant="caption">
                        {section === 'attributes'
                            ? 'Add attributes that match the event data recieved in order to display this data in the Events Monitor.'
                            : 'Add actions so that can be triggered automatically or by users on the event.'}
                    </StyledTypography>
                    <br />
                    {section === 'actions' ? (
                        <Grid>
                            <Checkbox
                                value={noActionOnDuplicate}
                                onChange={this.handleNoActionOnDuplicate}
                                name="noActionOnDuplicate"
                                label="No action on duplicate"
                            />
                        </Grid>
                    ) : null}
                    <Button
                        style={{ width: '168px', height: '36px' }}
                        size="small"
                        startIcon={<Icon name="plus" hexColor={muiTheme.colors.text.button}/>}
                        onClick={e => this.handleAddAttributeClick(e, section)}
                    >
                        ADD {section === 'actions' ? 'action' : section}
                    </Button>
                    {this.renderPopoverMenu(section, anchorEl, this.handleAttributeClose, this.handleAttributesItemClick)}
                    {section === 'actions' ? this.renderActionsCard() : null}
                </StyledExpansionCard>
                {section === 'attributes' ? attributes?.map((data) => this.renderAttributesCard(data)) : null}
            </div>
        );
    }

    @bind
    handleNoActionOnDuplicate(e) {
        const { name, value } = e.target || {};
        this.setState({ [name]: value });
    }

    @bind
    handleFormChange(formData){
        this.setState({ formData });
    }

    @bind
    closeModal() {
        this.setState({ openAttributeModal: false, selectedAttribute: null });
    }

    @bind
    handleAttributeMenuClick(selectedAttribute){
        this.setState({ selectedAttribute, openAttributeModal: true });
    }

    @bind
    attributeItemMenu(selectedAttribute) {
        return (
            <DotMenuWrapper>
                <DotMenu
                    key={13}
                    onItemClick={() => this.handleAttributeMenuClick(selectedAttribute)}
                    items={[
                        { name: 'Edit', icon: 'pencil' },
                    ].filter(Boolean)}
                />
            </DotMenuWrapper>
        );
    }

    render() {
        const { lookupOptions, descriptorLookupOptions, formData, openAttributeModal, selectedAttribute } = this.state;
        const { details } = this.props;
        const eventTypeId = details?.id;
        const { eventDataAttributes } = details || {};
        return (
            <>
                <HeaderBar left={this.props.breadcrumbLine} right={[this.renderSaveButton(), ...(this.props.sidebarActions || [])]} />
                <ContentArea withHeader isSidebar={this.props?.isSidebar}>
                    <Container width='1024'>
                        <FormGenerator
                            components={this.buildComponents(lookupOptions, descriptorLookupOptions)}
                            data={formData}
                            onChange={this.handleFormChange}
                            ref={this.formRef}
                        />
                        {this.renderAttributes('attributes', eventDataAttributes)}
                        {this.renderAttributes('actions')}
                    </Container>
                </ContentArea>
                {/* Add and Update Attribute Modal */}
                {openAttributeModal && (
                    <AddEventTypeAttribute selectedAttribute={selectedAttribute} eventTypeId={eventTypeId} onClose={this.closeModal}  />
                )}
            </>
        );
    }
}

export default connect(
    state => ({
        isLoading: state.stream.events.typeData.isLoading
    }),
    {
        updateEventType,
        removeEventDataAttribute,
        loadEventTypeDetails,
        setDocumentTitle,
    }
)(EventTypeAbout);
