/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import PageTemplate from 'app/components/templates/PageTemplate';
import { loadClassesListView } from 'store/actions/classifications/classificationsActions';
import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import EntitiesList from 'app/containers/Entities/EntitiesList/EntitiesList';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { openClassSidebar } from 'store/actions/entities/classSidebarActions';
import { commonEntitiesListFilters } from 'app/utils/entity/entityUtils';
import { modulesAndPageTitles } from 'app/config/typesConfig';
import { setDocumentTitle } from 'store/actions/app/appActions';


class ClassesListView extends PureComponent<Object, Object> {
    static propTypes = {
        loadClassesListView: PropTypes.func.isRequired,
        records: PropTypes.array,
        isLoading: PropTypes.bool,
        entityType: PropTypes.bool,
        totalRecords: PropTypes.number
    };

    static defaultProps = {
        entityType: false,
        isLoading: false,
        VirtualListProps: {},
        FiltersProps: {}
    };

    filterDefinitions(entityType) {
        return [
            {
                field: 'name',
                type: 'text',
                properties: {
                    label: 'Name',
                    name: 'name',
                    opSelector: true
                }
            },
            {
                field: 'uri',
                type: 'text',
                properties: {
                    label: 'URI',
                    name: 'uri',
                    opSelector: true
                }
            },
            {
                field: 'id',
                type: 'uuid',
                properties: {
                    label: 'ID',
                    name: 'id',
                    opSelector: true
                },
                condition: '='
            },
            {
                field: 'active',
                type: 'typeahead',
                properties: {
                    label: 'Status',
                    name: 'active',
                    options: [
                        { value: null, label: 'Any' },
                        { value: true, label: 'Active' },
                        { value: false, label: 'Inactive' }
                    ]
                },
                sort: false,
                condition: '='
            },
            {
                field: 'hidden',
                type: 'typeahead',
                properties: {
                    label: 'Hidden',
                    name: 'hidden',
                    options: [
                        { value: null, label: 'Any' },
                        { value: true, label: 'Hidden' },
                        { value: false, label: 'Not Hidden' }
                    ]
                },
                sort: false,
                condition: '='
            },
            {
                field:'isSystem',
                type:'typeahead',
                properties:{
                    label:'System',
                    name:'isSystem',
                    options:[
                        { value: null, label: 'Any' },
                        { value: true, label: 'System' },
                        { value: false, label: 'Non System' }
                    ]
                },
                sort: false,
                condition: '='
            },
            entityType && {
                field: 'abstract',
                type: 'typeahead',
                properties: {
                    label: 'Abstract',
                    name: 'abstract',
                    options: [
                        { value: null, label: 'Any' },
                        { value: true, label: 'Abstract' },
                        { value: false, label: 'Non Abstract' }
                    ]
                },
                condition: '=',
                sort: false,
            },
            ...commonEntitiesListFilters,
            {
                field: 'disabledBy.id',
                type: 'userTypeahead',
                properties: {
                    label: 'Disabled By',
                    name: 'disabledById',
                    valueField: 'id'
                },
                condition: '='
            },
            {
                field: 'disabledDate',
                type: 'dateTimeRange',
                properties: {
                    label: 'Disabled Date',
                    name: 'disabledDate'
                }
            },
        ].filter(Boolean);
    }
    defaultFilters = { active: true }

    componentDidMount() {
        const { entityType, setDocumentTitle } = this.props;
        const { classes, entityTypes } = modulesAndPageTitles.classes;
        const title = entityType ? entityTypes : classes;
        setDocumentTitle(title);
    }

    @bind
    @memoize()
    columnDefinitions(entityType) {
        return [
            {
                text: entityType ? 'Entity Type Name' : 'Class Name',
                field: 'name',
            },
            {
                text: entityType ? 'Entity Type ID ' : 'Class ID',
                field: 'id',
                onClickDisabled: true,
            },
            {
                text: 'Parents (Inherited from)',
                field: 'parents',
                
            },
            {
                text: 'Status',
                field: 'active',
            },
            {
                text: 'Last Updated',
                field: 'modifiedDate',
            },
            {
                text: 'Modified By',
                field: 'modifiedBy'
            },
            {
                text: 'Created Date',
                field: 'createdDate',
            },
            {
                text: 'Created by',
                field: 'createdBy'
            },
        ];
    }


    @bind
    onListItemClick(event: Object, id: string) {
        event.stopPropagation();
        const { entityType } = this.props;
        if(entityType) {
            this.props.history.push(`/entity-types/${id}`);
        } else {
            this.props.history.push(`/classifications/${id}`);
        }
    }

    @bind
    loadData(data) {
        const { entityType, loadClassesListView } = this.props;
        return loadClassesListView({
            ...data,
            options: {
                ...data.options,
                filterBy: [
                    ...(data.options.filterBy || []),
                    [{ field: 'primary', op: '=', value: entityType }]
                ]}
        });
    }

    render() {
        const { startIndex, isLoading, records, totalRecords, entityType } = this.props;
        const VIEW_ID = entityType ? 'EntityTypesListView' : 'ClassesListView'; 
        return (
            <PageTemplate title={entityType ? 'Entity Types' : 'Classes'} >
                <ContentArea>
                    <EntitiesList
                        id={VIEW_ID}
                        type='class'
                        entityType={entityType}
                        headerTitle={entityType ? 'Entity Types' : 'Classes'}
                        records={records}
                        totalRecords={totalRecords}
                        startIndex={startIndex}
                        isLoading={isLoading}
                        loadData={this.loadData}
                        columnDefinitions={this.columnDefinitions(entityType)}
                        filterDefinitions={this.filterDefinitions(entityType)}
                        defaultFilters={this.defaultFilters}
                        downloadable
                        uploadable
                    />
                </ContentArea>
            </PageTemplate>
        );
    }
}

export default connect(
    (state: Object) => ({
        records: state.classifications.list.records,
        totalRecords: state.classifications.list.count,
        isLoading: state.classifications.list.isLoading,
        startIndex: state.classifications.list.startIndex
    }),
    { loadClassesListView, openClassSidebar, setDocumentTitle, }
)(ClassesListView);
