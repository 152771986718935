import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ListItem, ListItemText, ListItemAvatar, Avatar } from '@mic3/platform-ui';

import { bind } from 'app/utils/decorators/decoratorUtils';

import Icon from 'app/components/atoms/Icon/Icon';
import { Typography } from '@material-ui/core';

const AvatarStyled = styled(Avatar)`
    background: ${({ $selected, theme }) => $selected ? theme.material.colors.background.paper : 'transparent'} !important;
`;

const ErrorText = styled(Typography)`
    color: ${({ theme }) => theme.material.colors.danger} !important;
`;

class RelationDefinitionListItem extends PureComponent {
    static propTypes = {
        title: PropTypes.string.isRequired,
        isSelected: PropTypes.bool,
        onItemClick: PropTypes.func.isRequired,
        uri: PropTypes.string.isRequired,
        data: PropTypes.object.isRequired,
        isRelClassSelected: PropTypes.bool
    };

    @bind
    handleItemClick() {
        if (this.props.isRelClassSelected) return;

        const { onItemClick, data } = this.props;
        const { entityType } = data.entityType || {};
        
        onItemClick({ ...data, sub: entityType?.name });
    }

    render() {
        const { title, isSelected, data, isRelClassSelected } = this.props;
        const { icon, iconType, name, color } = data.entityType || {};

        return (
            <ListItem button onClick={this.handleItemClick} selected={isSelected} disabled={isRelClassSelected}>
                <ListItemAvatar>
                    <AvatarStyled $selected={isSelected}>
                        {isSelected ? (
                            <Icon name="checkbox-marked" />
                        ) : (
                            <Icon name={icon || 'null'} type={iconType || 'mdi'} hexColor={color} />
                        )}
                    </AvatarStyled>
                </ListItemAvatar>
                <ListItemText primary={title} secondary={
                    <>
                        <Typography variant="body2">{name || 'No Name'}</Typography>
                        {isRelClassSelected && <ErrorText variant="caption">Related Class is already selected.</ErrorText>}
                    </>
                } />
            </ListItem>
        );
    }
}

export default RelationDefinitionListItem;
