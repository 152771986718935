/* @flow */

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { loadTasksTypeahead } from 'store/actions/abox/taskActions';
import { Avatar, ListItemText } from '@mic3/platform-ui';

import { bind } from 'app/utils/decorators/decoratorUtils';
import { getAttachmentUrl } from 'app/utils/attachments/attachmentsUtils';
import { get } from 'app/utils/lo/lo';
import { TypeaheadChipInitials, TypeaheadAdornmentInitials } from 'app/components/organisms/Form/Typeahead/abstract/TypeaheadChip';
import { TypeaheadListItem } from 'app/components/organisms/Form/Typeahead/abstract/TypeaheadListItem';
import AbstractEntityTypeahead from 'app/components/organisms/Form/Typeahead/abstract/AbstractEntityTypeahead';

const TypeaheadListItemStyled = styled(TypeaheadListItem)`
    & > div {
        margin-right: 12px;
    }
`;

/**
 * Select one or more groups using lazy loading.
 */
class TaskTypeahead extends PureComponent<Object, Object> {

    static propTypes = {
        loadOptions: PropTypes.func.isRequired,
        filterBy: PropTypes.arrayOf(PropTypes.object),
        excludeBy: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.array])),
    }

    @bind
    optionTemplate({ name, id, type, assignee, primary  }: Object) {
        const { progress, priority, closedDate } = primary || {};
        const imageId = get(assignee, 'image');
        const src = imageId && getAttachmentUrl(get(assignee, 'id'), 'user', imageId);
        const initials = get(assignee, 'name', name || 'No Name');
        return ({
            option: (
                <TypeaheadListItemStyled ContainerComponent="div" dense disableGutters>
                    <Avatar
                        CircularProgressStaticProps={{
                            value: progress || 0,
                            priority,
                            disabled:!!closedDate,
                        }}
                        initials={initials}
                        src={src}
                    />
                    <ListItemText primary={name || 'No Name'} secondary={`#${id}`} />
                </TypeaheadListItemStyled>
            ),
            ChipProps: {
                avatar: <TypeaheadChipInitials src={src} initials={initials} />,
            },
            startAdornment: <TypeaheadAdornmentInitials src={src} initials={initials} />,
            label: `${name || 'Name not available'} (${id ? id.slice(0,8) : 'Id not available'})`,
        });
    };

    render() {
        const { excludeBy, loadOptions, filterBy, placeholder, ...typeaheadProps } = this.props; // eslint-disable-line no-unused-vars
        return(
            <AbstractEntityTypeahead
                placeholder="Search for a task..."
                {...typeaheadProps}
                loadOptions={loadOptions}
                optionTemplate={this.optionTemplate}
                filterBy={filterBy}
                excludeBy={excludeBy}
            />
        );
    }
};

export default connect(
    null, { loadOptions: loadTasksTypeahead }
)(TaskTypeahead);
