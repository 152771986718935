/* @flow */

import { gql } from '@apollo/client';

export default gql`
	query connectorLogTailPipelineQuery($id: ID!, $connectorLabel: String!, $start: String!, $end: String!){
		result: iotPipelineConnectorLog(id: $id, connectorLabel: $connectorLabel, start: $start, end: $end) {
			label
			time
			message
		}
	}
`;
