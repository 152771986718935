/* @flow */

import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { muiTheme } from 'app/themes/materialUi';
import { Typography, Grid, MdiIcon, IconButton, InputBase, Switch, Divider, Button } from '@mic3/platform-ui';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import styled, { withTheme } from 'styled-components';

import Icon from 'app/components/atoms/Icon/Icon';
import Container from 'app/components/atoms/Container/Container';
import HeaderBar from 'app/components/molecules/HeaderBar/HeaderBar';
import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import DotMenu from 'app/components/molecules/DotMenu/DotMenu';
import Loader from 'app/components/atoms/Loader/Loader';
import SubscriptionComponent from 'app/containers/Abox/BackgroundJob/SubscriptionComponent';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { copyToClipboard } from 'app/utils/classification/classificationUtils';
import { showToastr } from 'store/actions/app/appActions';
import { formatDate } from 'app/utils/date/date';
import { saveJSON } from 'app/utils/datatable/datatableUtils';
import { loadBackgroundJobLogs } from 'store/actions/abox/backgroundJobActions';

const Search = styled(Grid)`
    flex-grow: 1;
`;

const StyledTypography = styled(Typography)`
    color: ${({theme})=> theme.material.colors.text.primary};
`;

const StyledTypographySecondary = styled(Typography)`
    color: ${({theme})=> theme.material.colors.text.secondary};
`;

const StyledContentArea = styled(ContentArea )`
    width: 100%;             
    max-width: 100vw;         
    overflow-x: hidden;       
    white-space: normal;      
    overflow-wrap: break-word;
    word-break: break-word; 

`;

const SearchToolbar = styled(Grid)`
    padding: 0 1rem;
    border-bottom: 2px solid ${({ theme }) => theme.material.colors.background.divider};
`;

const ColumnLeftStyled = styled(Grid)`
    width: 90px;
    text-align: left;
    margin: 10px 0 5px 0 !important;
`;
const ColumnRightStyled = styled(Grid)`
    color: ${({ theme }) => theme.material.colors.white};
    margin: 10px 0 5px 0 !important;
`;

const DividerStyled = styled(Divider)`
    height: 56% !important;
    background-color: ${({theme})=> theme.material.colors.background.divider};

`;
const TreeViewStyled = styled(TreeView)`
    height: 100%;
`;
const HeaderBarStyled = styled(HeaderBar)`
    position: fixed;
    bottom: ${({ fromsidebar }) => fromsidebar ? '0px' : '5px'};
    border-top: 1px solid ${({theme})=> theme.material.colors.background.divider};
    width: calc(100% - 30px);
    margin: ${({ fromsidebar }) => fromsidebar ? '0px' : '0 15px'};
    padding-top: 8px;
    padding-bottom: 4px;
`;

class BackgroundJobLogs extends PureComponent<Object, Object> {
    static propTypes: Object = {
        id: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        details: PropTypes.object.isRequired,
        loadBackgroundJobLogs: PropTypes.func.isRequired,
        isLoading: PropTypes.bool,
        canEdit: PropTypes.bool,
        fromSidebar: PropTypes.bool,
        userProfile: PropTypes.object,
    };


    constructor(props) {
        super(props);
        this.state = { filter: '', ...this.updatedLogsData(true, []) };
        
        this.loadDetails();
    }

    componentDidUpdate(prevProps) {
        const { details, logs: propsLogs } = this.props;
        const { live } = this.state;
        if(details?.id !== prevProps.details?.id) {
            this.loadDetails();
        }
        if ((details && details !== prevProps.details) || (propsLogs && prevProps.logs !== propsLogs)) {
            this.setState(this.updatedLogsData(live, []));
        }
    }

    @bind
    updatedLogsData(live, logs) {
        const { logs: detailsLog } = this.props;
        return [
            ...(detailsLog || []),
            ...logs,
        ].reduce((nextState, log, i) => {
            if(live && (log.msg === 'job completed.' || log.msg === 'job failed.')){
                nextState.live = false
            }
            
            if(nextState.logs.find(l => l.time === log.time)) return nextState;
            
            nextState.logs.push(log);
            return nextState;
        }, { logs: [], live });
    }

    @bind
    loadDetails() {
        const { id, loadBackgroundJobLogs } = this.props;
        return loadBackgroundJobLogs(id);
    }

    @bind
    onSearch(event) {
        this.setState({ filter: event.target.value.trimLeft() });
    }

    @bind
    handleLive(event) {
        this.setState({ live: event.target.value });
    }

    @bind
    downloadLogs() {
        const { details } = this.props;
        const { logs } = this.state;
        const { startDate, endDate, inputParameters, instance, status, type } = details.primary;
        saveJSON(details.name, { logs, startDate, endDate, inputParameters, instance, status, type });
    }

    @bind
    copyIdToClipBoard() {
        const { showToastr, id, type } = this.props;
        copyToClipboard(`${window.location.origin}/#/entities/${type}/${id}`)
            .then(() => {
                showToastr({ severity: 'success', detail: 'Link copied to clipboard' });
            })
            .catch(() => {
                showToastr({ severity: 'error', detail: 'Link could not copied to clipboard' });
            });
    }

    @bind
    onDotMenuClick(title) {
        if(title === 'Rename') {
            this.setState({ renameActive: true });
            return;
        }
        if(title === 'Disable' || title === 'Enable') {
            this.onDisableSubmit();
            return;
        }
        if(title === 'Copy link') {
            this.copyIdToClipBoard();
            return;
        }

        this.props.openSidebar(title);
    }

    @bind
    populateLogs(log) {
        this.setState({ logs: [
            ...this.state.logs,
            log,
        ]});
    }

    @bind
    @memoize()
    buildTreeElements(logs, filter) {
        const { theme } = this.props;
        const filteredLogs = logs?.filter(l => l.msg.toLowerCase().includes(filter?.toLowerCase()));
        if(!filteredLogs?.length || !logs) {
            return (
                <Typography>No results.</Typography>
            );
        }
        return (
            <>
                {filteredLogs.map((log, indx) => (
                    <TreeItem
                        nodeId={indx + 10}
                        key={indx + 10}
                        label={
                            <Grid container spacing={4}>
                                <Grid item>
                                    <Icon name="circle" hexColor={theme.statusColors[log.level]} size="sm"  />
                                </Grid>
                                <Grid item>
                                    <StyledTypography variant="caption" color="inherit">
                                        {formatDate(new Date(log.time))}
                                    </StyledTypography>
                                </Grid>
                                <Grid item>
                                    <StyledTypography variant="caption" color="inherit">
                                        {log.msg}
                                    </StyledTypography>
                                </Grid>
                            </Grid>
                        }
                    >
                        <TreeItem nodeId={indx * 100 + 1} label={(
                            <Grid container spacing={4}>
                                <ColumnLeftStyled item><StyledTypographySecondary variant="body1" >Time</StyledTypographySecondary></ColumnLeftStyled>
                                <ColumnRightStyled item><StyledTypography variant="body1">{formatDate(new Date(log.time))}</StyledTypography></ColumnRightStyled>
                            </Grid>
                        )} />
                        <TreeItem nodeId={indx * 100 + 2} label={(
                            <Grid container spacing={4}>
                                <ColumnLeftStyled item><StyledTypographySecondary variant="body1" >Message</StyledTypographySecondary></ColumnLeftStyled>
                                <ColumnRightStyled item><StyledTypography variant="body1">{log.msg}</StyledTypography></ColumnRightStyled>
                            </Grid>
                        )} />
                        <TreeItem nodeId={indx * 100 + 3} label={(
                            <Grid container spacing={4}>
                                <ColumnLeftStyled item><StyledTypographySecondary variant="body1" >Level</StyledTypographySecondary></ColumnLeftStyled>
                                <ColumnRightStyled item><StyledTypography variant="body1">{log.level}</StyledTypography></ColumnRightStyled>
                            </Grid>
                        )} />
                    </TreeItem>
                ))}
            </>
        );
    }

    @bind
    @memoize()
    dotMenu(entityForm, canEdit) {
        return (
            <DotMenu
                key={13}
                onItemClick={this.onDotMenuClick}
                items={[
                    { name: 'Copy link', icon: 'link' },
                    { name: 'Sharing', icon: 'account-plus' },
                    { name: 'divider' },
                    canEdit && { name: 'Rename', icon: 'pencil' },
                    canEdit && { name: entityForm.active ? 'Disable' : 'Enable', icon: entityForm.active ? 'close' : 'check' },
                    canEdit && { name: 'divider' },

                ].filter(Boolean)}
            />
        );
    }

    render(): Object {
        const { isLoading, breadcrumbLine, fromSidebar, id, sidebar, sidebarActions } = this.props;
        const { filter, live, logs }= this.state;

        if(isLoading) {
            return <Loader absolute backdrop/>
        }
        
        return (
            <Fragment>
                <SubscriptionComponent id={id} populateLogs={this.populateLogs} />
                {(breadcrumbLine || sidebarActions)  && 
                <div>
                    <HeaderBar 
                        left={breadcrumbLine} 
                        right={[...(sidebarActions || [])]} 
                    />
                </div>
                }
                <SearchToolbar container alignItems="center">
                    <Search item>
                        <InputBase value={filter} onChange={this.onSearch} fullWidth margin="none" placeholder="Search..." />
                    </Search>
                    <Switch edge="end" value={live} onChange={this.handleLive} label="Live" disabled={!live} />
                    <DividerStyled edge="end" orientation="vertical" />
                    <IconButton edge="end" onClick={this.loadDetails}>
                        <MdiIcon name="refresh" color={muiTheme.colors.text.secondary} />
                    </IconButton>
                </SearchToolbar>
                <StyledContentArea heightOffset = {sidebar?.isOpen && sidebar?.title === 'Log' ? '110' : '62'} >
                    <Container width="1024">
                        <TreeViewStyled
                            defaultCollapseIcon={<Icon name="chevron-down" hexColor={muiTheme.colors.secondary.main} />}
                            defaultExpandIcon={<Icon name="chevron-right" hexColor={muiTheme.colors.secondary.main} />}
                        >
                            {this.buildTreeElements(logs, filter)}
                        </TreeViewStyled>
                    </Container>
                </StyledContentArea >
                <HeaderBarStyled fromsidebar={fromSidebar?1:0} withoutDivider left={(
                    <Button startIcon={(
                        <Icon name="download"  size="sm" hexColor={muiTheme.colors.text.button} />
                    )} onClick={this.downloadLogs} >Download log</Button>
                )}/>
            </Fragment>
        );
    }
}

export default connect(
    (state: Object) => ({
        userProfile: state.user.profile,
        isLoading: state.abox.backgroundJob.logs.isLoading,
        logs: state.abox.backgroundJob.logs.data?.logs,
        sidebar: state.sidebar
    }),
    { loadBackgroundJobLogs, showToastr }
)(withTheme(BackgroundJobLogs));
