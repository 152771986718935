/* @flow */

import React from 'react';

import { help } from 'app/utils/designer/form/settings/common/commonHelpers';
import { label, name } from 'app/utils/designer/form/settings/common/commonBase';
import { onLoad, onClick, isVisible, isDisabled } from 'app/utils/designer/form/settings/common/commonEvents';
import { flexGrow } from 'app/utils/designer/form/settings/common/commonStyles';
import Help from 'app/utils/designer/form/settings/common/Help';

const panels = settingsValues => [
    {
        header: 'Base',
        children: [
            {
                type: 'boolean',
                properties: { label: 'By reference', name: 'properties.byReference', help: <Help message={`Defines how to set icon name by reference or manual.`} /> }
            },
            { ...name, properties: { ...name.properties, isVisible: ({ properties }) => !!(properties || {}).byReference },
                constraints: { required: ({ properties }) => !!(properties || {}).byReference },
            },
            {
                type: 'iconSelect',
                properties: {
                    label: 'Select icon',
                    name: 'properties.selectedIcon',
                    clearable: false,
                    isVisible: ({ properties }) => !(properties || {}).byReference,
                    onChange: (e) => {
                        return [e.target, { name: 'properties.name', value: e.target.value?.value || null }, { name: 'properties.iconType', value: e.target.value?.type }];
                    }
                },
                constraints: { required: ({ properties }) => !(properties || {}).byReference },
            },
            label,
            {
                type: 'colorPicker',
                properties: { label: 'Choose color', name: 'properties.color' }
            },
            {
                type: 'number',
                properties: { label: 'Size', name: 'properties.size', help: <Help message={`Defines the size of the icon in px.`} />}},
            {
                type: 'boolean',
                properties: { label: 'Is Button', name: 'properties.isButton' }
            },

        ],
    },
    {
        header: 'Events',
        children: [
            onLoad,
            onClick,
            isVisible,
            isDisabled
        ]
    },
    {
        header: 'Styles',
        children: [ flexGrow ]
    },
    {
        header: 'Helpers',
        children: [ help ]
    },
];

export default {
    defaults: { isButton: false, size: 24, color: '#00BCD4', boxProps: { noBorder: true }, formFieldProps: { flexGrow: 0 } },
    panels: settingValues => panels(settingValues),
};


