import React, { memo, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TimePicker as TimePickerBase } from '@mic3/platform-ui';
import { TIME_FORMAT, formatDate, parseTimeToDate } from 'app/utils/date/date';

const TimePicker = ({ value, name, type, onChange, ...rest }) => {
    const [dateValue, setDateValue] = useState(parseTimeToDate(value));

    useEffect(() => {
        setDateValue(parseTimeToDate(value));
    }, [value]);

    const handleChange = useCallback(({ target: { value }}) => {
        setDateValue(value);
        const parsedTime = formatDate(value, TIME_FORMAT);
        onChange({ target: { name, value: parsedTime, type }});
    }, [name, onChange, type]);

    return (
        <TimePickerBase
            {...rest}
            value={dateValue}
            onChange={handleChange}
        />
    );
};

TimePicker.propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    value: PropTypes.string,
};

export default memo(TimePicker);
