// @flow

import VectorSource from 'ol/source/Vector';
import Draw, { createBox } from 'ol/interaction/Draw';
import Style from 'ol/style/Style';
import Fill from 'ol/style/Fill';
import Text from 'ol/style/Text';
import Stroke from 'ol/style/Stroke';
import CircleStyle from 'ol/style/Circle';
import Overlay from 'ol/Overlay';
import Polygon from 'ol/geom/Polygon';
import { circular } from 'ol/geom/Polygon';
import LineString from 'ol/geom/LineString';
import { unByKey } from 'ol/Observable';
import { bind } from 'app/utils/decorators/decoratorUtils';
import VectorLayer from 'ol/layer/Vector';
import { toLonLat } from 'ol/proj';

class DrawTool {
    draw: Object = {};
    drawSource: Object = new VectorSource();
    drawLayer = null;
    drawTypes: Array<string> = ['Point', 'LineString', 'Polygon', 'Circle', 'None', 'Box', 'Line'];
    olMap: Object = {};
    drawToolOverlays: Array<Object> = [];
    drawType: string;
    drawTypeIndex: number;
    helpTooltipElement: HTMLDivElement;
    helpTooltip: Object = {};
    sketch: null;
    listener: Object = {};

    /**
     * constructor for draw tool
     * @param olMapVM
     */
    constructor(olMapVM: Object) {
        this.olMap = olMapVM;
    }

    /**
     * Initializing draw layer to view draw feature on map
     * @param title
     * @param displayInLayerSwitcher
     * @param style
     * @returns {*}
     */
    initDrawLayer(title: string = 'Drawing Layer', displayInLayerSwitcher: boolean = false) {
        if (!this.drawLayer) {
            this.drawLayer = new VectorLayer({
                imageRatio: 2,
                title: title,
                visible: true,
                expanded: false,
                source: this.getDrawSource(),
                style: this.styleFunction
            });
            return this.drawLayer;
        }
        return null;
    }

    /**
     * Change Draw Type
     */
    changeDrawType(drawType: number) {
        this.initDrawTool(drawType);
    }

    /**
     * Initializing draw tool to draw feature on map
     * @param drawTypeIndex -- type of geometry to draw
     * @param freehand
     * @returns {*}
     */
    initDrawTool(drawTypeIndex: number = 1, freehand = false) {
        this.drawTypeIndex = drawTypeIndex;
        this.drawType = this.drawTypes[drawTypeIndex];
        // if (!this.draw) {
        if (drawTypeIndex === 3) {
            this.draw = new Draw({
                source: this.getDrawSource(),
                type: this.drawType,
                geometryFunction: function (coordinates, geometry) {
                    if (!geometry) {
                        geometry = new Polygon([]);
                    }
                    const center = coordinates[0];
                    const last = coordinates[1];
                    const dx = center[0] - last[0];
                    const dy = center[1] - last[1];
                    const radius = Math.sqrt(dx * dx + dy * dy);
                    const circle = circular(toLonLat(center), radius);
                    circle.transform('EPSG:4326', 'EPSG:3857');

                    geometry.setCoordinates(circle.getCoordinates());
                    return geometry;
                },
                freehand: true
            });
        } else if (drawTypeIndex === 5) {
            this.draw = new Draw({
                source: this.getDrawSource(),
                type: this.drawType,
                geometryFunction: createBox()
            });
        } else if (drawTypeIndex === 0) {
            this.draw = new Draw({
                source: this.getDrawSource(),
                type: this.drawType,
                style: new Style({
                    fill: new Fill({ color: 'rgba(61, 100, 183, 0.3)' }),
                    image: new CircleStyle({
                        radius: 0,
                        fill: new Fill({ color: 'rgba(61, 100, 183, 0.3)' })
                    })
                })
            });
        } else {
            this.draw = new Draw({
                source: this.getDrawSource(),
                type: this.drawType,
                // clickTolerance:200,
                // type: "Circle",
                style: this.getLayerDefaultStyle,
                freehand: freehand

            });
        }
        this.createHelpTooltip();
        return this.draw;
    }

    removeToolTip() {
        if (this.helpTooltipElement && this.helpTooltipElement.parentNode) {
            this.helpTooltipElement.parentNode.removeChild(this.helpTooltipElement);
        }
    }

    /**
     * Creates a new help tooltip
     */
    createHelpTooltip() {
        if (this.helpTooltipElement && this.helpTooltipElement.parentNode) {
            this.helpTooltipElement.parentNode.removeChild(this.helpTooltipElement);
        }
        this.helpTooltipElement = document.createElement('div');
        this.helpTooltipElement.className = 'ol-tooltip hidden';
        // $FlowFixMe
        this.helpTooltipElement.name = 'helpTooltipElem';
        this.helpTooltipElement.id = 'helpTooltip';

        const helpTooltip = new Overlay({
            element: this.helpTooltipElement,
            offset: [15, 0],
            positioning: 'center-left'
        });
        this.drawToolOverlays.push(helpTooltip);
        this.olMap.getMap().addOverlay(helpTooltip);
        this.helpTooltip = helpTooltip;
    }

    removeAllOverlays() {
        if (this.helpTooltipElement) {
            this.helpTooltipElement.classList.add('hidden');
        }

        const me = this;
        this.drawToolOverlays.forEach(function (overlay) {
            me.olMap.getMap().removeOverlay(overlay);
        });
        this.drawToolOverlays = [];
    }

    getDrawSource() {
        if (!this.drawSource) {
            this.drawSource = new VectorSource();
        }
        return this.drawSource;
    }

    getDraw(freehand) {
        if (this.draw && this.draw.freehand_ !== freehand) {
            this.initDrawTool(this.drawTypeIndex, freehand);
        }
        return this.draw;
    }

    getLayerDefaultStyle() {
        const style = new Style({
            fill: new Fill({
                color: 'rgba(61, 100, 183, 0.3)',
            }),
            stroke: new Stroke({
                color: '#3D64B7',
                width: 3,
                lineDash: [5, 15]
            }),
            image: new CircleStyle({
                radius: 7,
                fill: new Fill({
                    color: 'rgba(61, 100, 183, 0.3)',
                })
            })
        });
        return style;
    }

    @bind
    styleFunction(feature: Object, resolution: number) {
        const properties = feature.getProperties();
        // const textStyle = properties.label ? this.createTextStyle(feature, resolution): null;
        const featureStyle = new Style({
            fill: new Fill({
                color: properties.fillColor ? properties.fillColor : 'rgba(61, 100, 183, 0.3)'
            }),
            stroke: new Stroke({
                color: properties.strokeColor ? properties.strokeColor : '#3D64B7',
                width: properties.strokeWidth ? properties.strokeWidth : 2
            }),
            image: new CircleStyle({
                radius: properties.radius ? properties.radius : 7,
                fill: new Fill({
                    color: properties.fillColor ? properties.fillColor : '#ffcc33'
                }),
            }),
            text: this.createTextStyle(feature, resolution)
        });
        return featureStyle;
    }

    @bind
    createTextStyle(feature: Object, resolution: number) {
        const properties = feature.getProperties();
        if (properties.label) {
            const fontSize = properties.fontSize ? properties.fontSize : '14px';
            const fontFamily = properties.fontFamily ? properties.fontFamily : 'Arial';
            const font = `${fontSize} ${fontFamily}`;
            const fontColor = properties.fontColor ? properties.fontColor : 'white';
            const placement = 'line';
            const textStyle = new Text({
                textAlign: 'center',
                textBaseline: 'top',
                font: font,
                text: properties.label,
                fill: new Fill({color: fontColor}),
                stroke: new Stroke({color: fontColor, width: '2px'}),
                placement: placement,
                overflow: true
            });
            return textStyle;
        } else {
            return new Text({});
        }
    }

    addDrawStartListener(callback: Function) {
        const me = this;

        this.olMap.getMap().on('pointermove', this.pointerMoveHandler.bind(this));
        this.olMap
            .getMap()
            .getViewport()
            .addEventListener('mouseout', function() {
                if (this.helpTooltipElement) {
                    this.helpTooltipElement.classList.add('hidden');
                }
            });
        this.draw.on('drawstart', function(evt) {
            // set sketch
            me.sketch = evt.feature;

            me.listener = me.sketch.getGeometry().on('change', function (evt) {
                // let geom = evt.target;
                callback && callback(evt);
            });
        });
    }

    addDrawEndListener(callback: Function) {
        const me = this;
        this.draw.on('drawend', function (evt) {
            // unset sketch
            me.sketch = null;
            unByKey(me.listener);
            callback && callback(evt.feature);
        });
    }

    /**
     * Handle pointer move.
     * @param {import("../src/ol/MapBrowserEvent").default} evt The event.
     */
    pointerMoveHandler(evt: Object, sketch: Object) {
        if (evt.dragging) {
            return;
        }
        /** @type {string} */
        let helpMsg;
        let continuePolygonMsg;
        let continueLineMsg;

        if (this.drawType === 'Box') {
            helpMsg = 'Click shift with click and start drawing';
            continuePolygonMsg = 'Keep pressing Shift and continue drawing';
            continueLineMsg = 'Press shift and Click to continue drawing the Box';
        } else {
            helpMsg = 'Click to start drawing';
            continuePolygonMsg = 'Click to continue drawing the polygon';
            continueLineMsg = 'Click to continue drawing the line';
        }

        if (this.sketch) {
            const geom = this.sketch.getGeometry();
            if (geom instanceof Polygon) {
                helpMsg = continuePolygonMsg;
            } else if (geom instanceof LineString) {
                helpMsg = continueLineMsg;
            }
        }
        this.helpTooltipElement.innerHTML = helpMsg;
        this.helpTooltipElement.id = 'measure-msg';

        this.helpTooltip.setPosition(evt.coordinate);
        this.helpTooltipElement.classList.remove('hidden');
    }
}

export default DrawTool;
