/* @flow */
import React from 'react';

import {
    buildLeftPanel as buildLeftPanelAction,
    closeLeftPanel as closeLeftPanelAction
} from 'store/actions/leftPanel/leftPanelActions';
import EntityLeftPanel, { getFilterBy } from 'app/containers/LeftPanels/EntityLeftPanel';

export const openEntityLeftPanel = ({ title, id, type, view, entityType }) => {
    return buildLeftPanelAction({
        title,
        view,
        content: (
            <EntityLeftPanel
                title={title}
                id={id}
                entityType={entityType}
                view={view}
                type={type}
            />
        ),
        isOpen: true,
        filterBy: getFilterBy(id, entityType, view)
    });
};

export const closeEntityLeftPanel = closeLeftPanelAction;
