// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import ResizeObserver from 'react-resize-observer';
import styled from 'styled-components';
import { muiTheme } from 'app/themes/materialUi';

import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import olMap from 'app/containers/Maps/SituationalAwareness/olMapController';
import AboutMapToolbar from 'app/containers/Maps/EntityAboutMap/AboutMapToolbar';
import LocationSnackbar from 'app/containers/Maps/EntityAboutMap/LocationSnackbar';
import { bind, memoize, debounce } from 'app/utils/decorators/decoratorUtils';
import 'ol/ol.css';
import { Button, Typography, MdiIcon, CircularProgress } from '@mic3/platform-ui';
import { toLonLat } from 'ol/proj';
import { shallowEquals, isDefined } from 'app/utils/utils';
import { createEvent } from 'app/utils/http/event';
import { getSvgPriority } from 'app/utils/maps/layer/layerUtils';

const StyledMapDiv = styled.div`
    width: 100%;
    height: ${({ isFullScreen, isSidebar }) =>
    isFullScreen
        ? `
        top: 0;
        left: 0;
        height: ${isSidebar ? 'calc(100vh - 219px)' : 'calc(100vh - 200px)'};
    `: '300px'};
`;

const SnacbarHeader = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Footer = styled.div`
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
`;

const IconContainer = styled.div`
    display: flex;
    flex-grow: 1;
    justify-content: center;
    padding: 30px;
    .MuiIcon-root {
        height: 75px !important;
    }
`;

const CircularProgressContent = styled.div`
    padding: 0 1rem;
`;
export const aboutMapMap = new olMap();

class AboutMap extends PureComponent<Object, Object> {
    constructor(props) {
        super(props);
        this.olMap = aboutMapMap;
        const { data } = this.props;
        const { latitude, longitude } = data || {};
        this.snckbarRef = new React.createRef();
        this.state = {
            mapInit: false,
            toggleMove: false,
            pinMoved: false,
            isNewPin: false,
            isClicked: false,
            position: { latitude, longitude },
            initialPosition: { latitude, longitude }
        };
    }

    componentDidMount() {
        this.loadMap();
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        const { isNewPin, position, isLoading } = this.state;
        const { isFullScreen, data } = this.props;
        const { latitude, longitude } = data || {};

        if (isNewPin && prevState.isNewPin !== isNewPin) {
            this.loadMap();
        }
        if (shallowEquals(position, data, ['latitude', 'longitude']) && isLoading) {
            this.setState({ isLoading: false });
        }
        if (prevProps.isFullScreen !== isFullScreen) {
            this.updateMap();
        }
        if (isDefined(latitude) && isDefined(longitude) && !shallowEquals(prevProps.data, data, ['latitude', 'longitude', 'iconName', 'iconColor'])) {
            this.setState({ position: { latitude, longitude } }, this.loadMap);
        }
    }

    @bind
    hasLatLon(lat, lon) {
        return isDefined(lat) && isDefined(lon);
    }

    @bind
    @debounce(200)
    updateMap() {
        this.olMap.getMap().render();
    }

    @bind
    setFirstPin() {
        const { iconName, iconColor, sync_with_position, _geoJson } = this.props.data || {};
        const { latitude, longitude } = this.state.position || {};
        this.olMap.addPinsToAboutMap({ latitude, longitude, iconName, iconColor,_geoJson, sync_with_position });
    }

    @bind
    async loadMap() {
        const { primaryClass, data } = this.props || {};
        const { svgTransformation, type, _geoJson, iconName, iconColor, latitude, longitude, sync_with_position } = data || {}; //geom
        const { svg, svgGraphic } = getSvgPriority(data, primaryClass);
        const { isNewPin, mapInit, pinMoved } = this.state;
        if (type && (isNewPin || _geoJson || this.hasLatLon(latitude, longitude))) {
            if (!mapInit) {
                this.olMap.initEntityAboutMap('aboutMap', 'locationMap');
                this.olMap.getMap().on('click', (evt) => {
                    if (isNewPin) {
                        this.setState({ isClicked: true });
                        const coordinate = evt.coordinate;
                        const LongLat = toLonLat(coordinate);
                        const longitude = parseFloat(LongLat[0].toFixed(6));
                        const latitude = parseFloat(LongLat[1].toFixed(6));
                        const position = { latitude, longitude };
                        this.olMap.addPinsToAboutMap(
                            { latitude, longitude, iconName, iconColor, svg, svgGraphic, svgTransformation, _geoJson, sync_with_position });
                        this.setState({ position }, () => this.onChange(position));
                    }
                });
                this.setState({ mapInit: true });
            }

            if (type === 'user') {
                if (!pinMoved && _geoJson) {
                    if (_geoJson.type === 'Point') {
                        const geom = _geoJson.coordinates;
                        await this.addUserLocation(geom, { svg, svgGraphic, svgTransformation });
                    } else if (_geoJson.type !== 'Point') {
                        const centroid = await this.olMap.getCentroidofGeom(_geoJson);
                        const centroidGeom = centroid.geometry.coordinates;
                        await this.addUserLocation(centroidGeom, { svg, svgGraphic, svgTransformation });
                    }
                } else if (pinMoved) {
                    const position = { latitude, longitude };
                    this.setState({ position }, () => this.onChange(position));
                }
            } else if (type && this.hasLatLon(latitude, longitude)) {
                const position = { latitude, longitude };
                this.setState({ position }, () => this.onChange(position));
                this.olMap.addPinsToAboutMap({ latitude, longitude, iconName, iconColor, svg, svgGraphic, svgTransformation, _geoJson, sync_with_position });
            } else {
                this.olMap.addPinsToAboutMap({ iconName, iconColor, svg, svgGraphic, svgTransformation, _geoJson, sync_with_position });
            }
        } else if (mapInit) {
            // we need to init the map if we removed the pins and add again.
            this.setState({ mapInit: false });
        }
    }

    @bind
    async addUserLocation(geom, svgData) {
        const { svg, svgGraphic, svgTransformation } = svgData || {};
        const { iconName, iconColor } = this.props.data || {};
        const latitude = geom[1];
        const longitude = geom[0];
        const position = { latitude, longitude };
        await this.setState({ position }, () => this.onChange(position));
        await this.olMap.addPinsToAboutMap({
            latitude,
            longitude,
            iconName,
            iconColor,
            svg,
            svgTransformation,
            svgGraphic
        });
    }

    @bind
    onChange(value: Object) {
        if (this.props.onChange) {
            this.setState({ isLoading: true });
            const event = createEvent('change', { name: 'location', value });
            this.props.onChange(event);
        }
    }

    @bind
    @debounce()
    setMovedPin(coords: Array) {
        const LongLat = toLonLat(coords);
        const longitude = parseFloat(LongLat[0].toFixed(6));
        const latitude = parseFloat(LongLat[1].toFixed(6));
        const position = { latitude, longitude };
        this.setState({ position, pinMoved: true }, () => this.onChange(position));
    }

    @bind
    showSnackbar(value: boolean) {
        const { position } = this.state;
        const { _geoJson } = this.props.data || {};
        if ((position.latitude && position.longitude) || _geoJson) {
            this.olMap.toggleMovePins(value, this.setMovedPin);
            this.setState({ toggleMove: value });
            this.snckbarRef.current.handleOpen(true);
            return;
        }
        this.setState({ isNewPin: value });
    }

    @bind
    getHeader() {
        const { isNewPin, toggleMove, pinMoved } = this.state;
        if (isNewPin) {
            return 'Add a location';
        } else if (pinMoved) {
            return 'Moved to';
        } else if (toggleMove) {
            return 'Drag the pin to start';
        }
        return 'Add a location';
    }

    @bind
    @memoize()
    renderMessage({ latitude, longitude }, pinMoved, isNewPin, toggleMove) {
        const header = this.getHeader();
        return (
            <>
                <SnacbarHeader>
                    <Typography variant="body1">{header}</Typography>
                    <MdiIcon name="close" onClick={this.onClose} size={'small'} />
                </SnacbarHeader>
                <Typography variant="caption">
                    {(pinMoved || isNewPin) && latitude && longitude
                        ? `Latitude: ${latitude}, Longitude: ${longitude}`
                        : toggleMove
                            ? ''
                            : 'Search for place or click anywhere on the map to get started.'}
                </Typography>
            </>
        );
    }

    @bind
    onClose() {
        const { pinMoved, isNewPin, initialPosition } = this.state;
        if (isNewPin) {
            this.olMap.removePinFromAboutMap();
        }
        if (pinMoved) {
            const { iconName, iconColor } = this.props.data || {};
            const { latitude, longitude } = initialPosition || {};
            this.olMap.addPinsToAboutMap({ latitude, longitude, iconName, iconColor });
            this.olMap.toggleMovePins(false, this.setMovedPin);
        }
        this.setState({ isNewPin: false, toggleMove: false, pinMoved: false, position: initialPosition }, () => this.onChange(initialPosition));
    }

    @bind
    saveLocationOnMap(event) {
        const { onFormSubmit } = this.props;
        this.setState({ toggleMove: false, pinMoved: false, isNewPin: false });
        onFormSubmit && onFormSubmit(event);
    }

    @bind
    cancelPinMove() {
        const { cancelMove } = this.props;
        this.snckbarRef.current.handleOpen(false);
        cancelMove();
    }

    @bind
    @memoize()
    renderActions({ latitude, longitude }, pinMoved, isNewPin, isLoading, isClicked) {
        const saveLocationOnMap = this.saveLocationOnMap;
        return pinMoved || isNewPin ? (
            <Footer>
                <Button variant="text" onClick={this.cancelPinMove}>
                    Cancel
                </Button>
                {isLoading ? (
                    <CircularProgressContent>
                        <CircularProgress key={113} size={24} color="primary" />
                    </CircularProgressContent>
                ) : (
                    <Button onClick={saveLocationOnMap}>Save</Button>
                )}
            </Footer>
        ) : null;
    }

    @bind
    renderLocationSnackbar() {
        const { isNewPin, toggleMove, position, isLoading, pinMoved, isClicked } = this.state;
        const message = this.renderMessage(position, pinMoved, isNewPin, toggleMove);
        const action = this.renderActions(position, pinMoved, isNewPin, isLoading, isClicked);
        this.snckbarRef.current.addAction(action);
        this.snckbarRef.current.addMessage(message);
    }

    @bind
    renderMapToolbar() {
        const { id, data } = this.props;
        const { type, svgTransformation } = data || {};
        return (
            <AboutMapToolbar
                id={id}
                type={type}
                olMap={this.olMap}
                setFirstPin={this.setFirstPin}
                snackbarRef={this.snckbarRef}
                svgTransformation={svgTransformation}
            />
        );
    }

    render() {
        const { isFullScreen, isSidebar, data } = this.props;
        const { isNewPin, toggleMove, position } = this.state;
        const { latitude, longitude } = position;
        const { _geoJson } = data || {}; //geom
        const hasPin = (!isNewPin && this.hasLatLon(latitude, longitude)) || _geoJson;
        const showMap = _geoJson || isNewPin || this.hasLatLon(latitude, longitude);
        if (isNewPin || toggleMove) {
            const mapDiv = document.getElementById('aboutMap');
            if (mapDiv) {
                mapDiv.style.cursor = 'crosshair';
            }
        }
        return (
            <ContentArea>
                {showMap ? (
                    <>
                        {this.renderMapToolbar()}
                        <StyledMapDiv id="aboutMap" isFullScreen={isFullScreen} isSidebar={isSidebar}>
                            <ResizeObserver onResize={() => this.olMap.updateMap()} />
                            {(isNewPin || toggleMove) && this.renderLocationSnackbar()}
                        </StyledMapDiv>
                    </>
                ) : (
                    <IconContainer>
                        <MdiIcon name="map-marker-plus" size={70} color={muiTheme.colors.text.caption}/>
                    </IconContainer>
                )}
                <Button
                    size="small"
                    startIcon={hasPin ? <MdiIcon name="pencil" title="Move a pin" /> 
                        : <MdiIcon name="plus" title="Add Pin" />}
                    onClick={() => this.showSnackbar(true)}
                >
                    {hasPin ? 'Move on Map' : 'Position On Map'}
                </Button>
                <LocationSnackbar ref={this.snckbarRef} />
            </ContentArea>
        );
    }
}

export default connect(state => ({
    isFullScreen: state.maps.situationalAwareness.map.mapFullScreen,
    isSidebar: state.sidebar.isOpen
}))(AboutMap);
