/* @flow */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import produce from 'immer';
import { CircularProgress, TextField, Avatar, Divider, Box, FormControl, Button} from '@mic3/platform-ui';

import VirtualListManaged from 'app/components/molecules/VirtualList/VirtualListManaged';
import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import TypeIcon from 'app/components/atoms/TypeIcon/TypeIcon';
import ListItem from 'app/components/molecules/List/ListItem';

import Icon from 'app/components/atoms/Icon/Icon';

import CheckBoxBlank from 'assets/images/icons/check_box_outline_blank.png';
import CheckBox from 'assets/images/icons/check_box.png';

import { loadResourceList, clearResourcesList } from 'store/actions/admin/workspacesActions';

import { bind } from 'app/utils/decorators/decoratorUtils';
import { typeTitlesMultiple } from 'app/config/typesConfig';
import Immutable from 'app/utils/immutable/Immutable';
import { isEmpty, getStr, focusInput } from 'app/utils/utils';
import RadioButtonsGroup from 'app/components/atoms/RadioButtonsGroup/RadioButtonsGroup';
import { getAttachmentUrl } from 'app/utils/attachments/attachmentsUtils';

const inputProps: Object = { disableUnderline: true };

const ListItemStyled = styled(ListItem)`
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    cursor: pointer;
    box-shadow: none;
    background: ${({ selected, theme }) => (selected ? theme.material.colors.background.active : theme.material.colors.background.paper)};
    &:hover {
        background: ${({ selected, theme }) => (selected ? theme.material.colors.background.active : theme.material.colors.background.hover)};
    }
    @media (max-width: 1100px) {
        padding-right: 2rem;
    }
`;
const StyledAvatar = styled(Avatar)`
    && {
        ${({ selected }) => selected ? `
        background-color: gray !important;
        color: transparent
        ` : `
        ${ListItemStyled}:hover & {
            color: transparent;
        }
        `};
    }
`;
const StyledTypeIcon = styled(TypeIcon)`
    && {
        ${({ selected }) => selected ? `
        background-color: gray !important;
        color: transparent
        ` : `
        ${ListItemStyled}:hover & {
            color: transparent;
        }
        `};
    }
`;
const StyledAvatarOverlay = styled.div`
    display: none;
    width: 24px;
    height: 24px;
    position: absolute;
    z-index: 1;
    top: ${({ isTypeIcon }) => (isTypeIcon ? '20px' : '16px')};
    left: ${({ isTypeIcon }) => (isTypeIcon ? '12px' : '16px')};
    && {
        ${({ selected }) => selected ? `
        display: block;
        background-image: url(${CheckBox});
        background-repeat: no-repeat;
        background-position: center center;
        ` : `
        ${ListItemStyled}:hover & {
            display: block;
            background-image: url(${CheckBoxBlank});
            background-repeat: no-repeat;
            background-position: center center;
        }
        `};
    }
`;
const ResourcesList = styled.div`
    div[class*='Container__ContainerStyle'] {
        background-color: transparent;
        margin-bottom: 0;
    }
    div[class*='VirtualList__TinyVirtual'] {
        padding-top: 0 !important;
        margin-top: 0.2em;
    }
    height: ${isMobile ? '60vh' : '50vh'} !important;
    padding-bottom: 36px;
`;
const StyledDivider = styled(Divider)`
    margin: 5px 0 !important;
`;
const SearchWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 16px;
`;
const StyledBox = styled(Box)`
    display: flex;
    align-items: center;
    width: 100%;
`;
const StyledTextField = styled(TextField)`
    margin-left: 5px !important;
`;
const StyledLabel = styled.div`
    margin: ${({ margin }) => (margin ? `${margin} !important` : '0')};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: ${({theme})=> theme.material.colors.text.primary};
    mix-blend-mode: normal;
`;
class AddNewResourcesModal extends PureComponent<Object, Object> {
    static propTypes = {
        closeModal: PropTypes.func.isRequired,
        type: PropTypes.string.isRequired,
        isOpen: PropTypes.bool,
        isLoading: PropTypes.bool,
        startIndex: PropTypes.number,
        records: PropTypes.array,
        totalRecords: PropTypes.number,
        loadResourceList: PropTypes.func.isRequired,
        isBulkSelect: PropTypes.bool,
        modalTitle: PropTypes.string,
        submitBtnLabel: PropTypes.string,
        addedResourcesList: PropTypes.array,
        onSubmit: PropTypes.func.isRequired,
        selectedItems: PropTypes.array,
        showRoles: PropTypes.bool,
    };

    state = Immutable({
        search: '',
        filterBy: [],
        orderBy: [],
        selectedItem: {},
        selectedItems: [],
        selectRoleActive: false,
        permissionFormData: {
            role: 'commenter',
            subscribe: true
        },
        isFilterRecord: false
    });

    defaultState: Object = this.state; // preserve the initial state in a new object.

    virtualListRef = React.createRef();

    componentDidMount() {
        const { filterBy, orderBy, type } = this.props;
        if (!isEmpty(filterBy)) this.setState({ filterBy });
        if (!isEmpty(orderBy)) this.setState({ orderBy });
        if (['user', 'primaryClass'].includes(type)) this.setState({ isFilterRecord: true });
    }

    componentDidUpdate(prevProps) {
        const { isOpen, isBulkSelect, selectedItems, type } = this.props;
        if (['user', 'primaryClass'].includes(type)) this.setState({ isFilterRecord: true });
        if (prevProps.isOpen !== isOpen && isOpen) {
            if (isBulkSelect && !isEmpty(selectedItems)) {
                this.setState({ selectedItems });
            }
        }
    }

    @bind
    renderComponent({ style, index, data }: { style: Object, index: number, data: Object }) {
        const { id, name, type: entityType, image } = data;
        const { type, isBulkSelect } = this.props;
        const { selectedItem: { id: selectedItemId } = {} } = this.state;
        const selected = !isBulkSelect ? selectedItemId === id : !isEmpty(this.getSelectedListItem(id));
        const profileImage = image && getAttachmentUrl(id, 'user', image);
        let subTitle = `#${id}`;
        let selectedData = { id, name, type: entityType || type };
        if (type === 'user') {
            subTitle = getStr(data, '', '');
            selectedData = { ...selectedData, username: data.username };
        } else if (type === 'primaryClass') {
            const { uri, color, icon } = data || {};
            selectedData = { ...selectedData, uri, color, icon };
        }

        return (
            <div style={style} key={index}>
                <ListItemStyled
                    component={
                        ['primaryClass'].includes(type) ? (
                            <div>
                                <StyledTypeIcon 
                                    type={'class'} 
                                    color={data.color} 
                                    size={'lg'} 
                                    selected={selected}
                                />
                                <StyledAvatarOverlay selected={selected} isTypeIcon={true} />
                            </div>
                        ) : (
                            <div>
                                <StyledAvatar
                                    selected={selected}
                                    initials={name}
                                    src={profileImage}
                                    
                                />
                                <StyledAvatarOverlay selected={selected} isTypeIcon={false} />
                            </div>
                        )
                    }
                    title={name}
                    subTitle={subTitle}
                    raised
                    onClick={e => this.onSelectListItem(e, selectedData)}
                    selected={selected}
                />
            </div>
        );
    }

    @bind
    onSelectListItem(event: Object, data: Object) {
        event.preventDefault();
        if (!this.props.isBulkSelect) {
            const { selectedItem } = this.state;
            const { id: selectedItemId } = selectedItem || {};
            const selectedValue = !isEmpty(selectedItem) && selectedItemId === data.id ? {} : data;
            this.setState({ selectedItem: selectedValue });
            this.updateList();
        } else {
            const selectedItem = this.getSelectedListItem(data.id);
            if (isEmpty(selectedItem)) {
                this.setState(
                    produce((draft) => {
                        draft.selectedItems.push(data);
                    }),
                    this.updateList
                );
            } else {
                this.setState(
                    prevState => ({
                        selectedItems: prevState.selectedItems.filter(
                            ({ id }) => id !== data.id
                        )
                    }),
                    this.updateList
                );
            }
        }
    }

    @bind
    getSelectedListItem(itemId: number) {
        if (!itemId) {
            return {};
        }
        const { selectedItems } = this.state;
        return selectedItems.find(({ id }) => id === itemId) || {};
    }

    @bind
    updateList() {
        if (this.virtualListRef && this.virtualListRef.current) {
            this.virtualListRef.current.forceUpdate();
        }
    }

    @bind
    onPermissionFormChange(event) {
        const { name, value } = event.target;

        if (value !== 'viewer') {
            this.setState({ permissionFormData: { ...this.state.permissionFormData, [name]: value } });
        } else {
            this.setState({ permissionFormData: { [name]: 'viewer', subscribe: false } });
        }
    }

    @bind
    subscriberChange(event) {
        const { value } = event.target;
        this.setState({permissionFormData: { ...this.state.permissionFormData, subscribe: value }});
    }

    @bind
    async onSubmit() {
        const { showRoles, isBulkSelect, onSubmit } = this.props;
        const { selectedItem, selectedItems, selectRoleActive, permissionFormData } = this.state;
        let selectedValue = null;

        if (showRoles) {
            if (!selectRoleActive) {
                this.setState({ selectRoleActive: !selectRoleActive });
            } else {
                const { role, subscribe } = permissionFormData;
                if (!this.props.isBulkSelect) {
                    selectedValue = { ...selectedItem, role, subscribe };
                } else {
                    selectedValue = selectedItems.map((item) => {
                        return { ...item, role, subscribe };
                    });
                }
                await onSubmit(selectedValue);
                this.closeModal();
            }
        } else {
            selectedValue = !isBulkSelect ? selectedItem : selectedItems;
            await onSubmit(selectedValue);
            this.closeModal();
        }
    }

    @bind
    onChangeSearch(e: Object) {
        if (e.persist) {
            e.persist();
        }
        this.setState({ search: e.target.value }, () => {
            const { search, filterBy } = this.state;
            const filters = !isEmpty(filterBy) ? filterBy.filter(({ field, op }) => (field !== 'name' && op !== 'contains')) : [];
            const searchFilter = { field: 'name', op: 'contains', value: search ? search.trim() : '' };
            this.setState({ filterBy: !isEmpty(filters) ? [ ...filters, searchFilter] : [searchFilter] });
        });
    }

    @bind
    loadData(opts: Object) {
        const options = { ...opts };
        const { isFilterRecord } = this.state;
        const { addedResourcesList, records, type, loadResourceList } = this.props;

        if (!isEmpty(addedResourcesList)) {
            const addedResourcesListIdsList = addedResourcesList.map(({ id }) => id);
            options.filterBy = [...options.filterBy, { field: 'id', op: 'not in', value: addedResourcesListIdsList }];
        }
        if (!records.length || !isFilterRecord) {
            return loadResourceList(options, type, this.props.applicableOn);
        }
        return Promise.resolve();
    }

    @bind
    closeModal() {
        this.props.clearResourcesList();
        this.setState(this.defaultState, this.props.closeModal);
    }

    @bind
    filterRecords(records, search, addedResourcesList) {
        const addedResourceKeys = (addedResourcesList || []).map(resource => resource && resource.id);

        return records.filter((record) => {
            if (addedResourceKeys.includes(record.id)) {
                return false;
            }
            if (search) {
                return record.name?.toLowerCase()?.includes(search.toLowerCase());
            }
            return true;
        });

    }

    render() {
        const {
            isOpen,
            isMobileView,
            isBulkSelect,
            isLoading,
            records,
            startIndex: start = 0,
            totalRecords: count,
            type,
            addedResourcesList,
            modalTitle,
            submitBtnLabel,
        } = this.props;
        const { selectedItem, selectedItems, filterBy, orderBy, search, selectRoleActive, permissionFormData, isFilterRecord } = this.state;
        const selectData = !isBulkSelect ? selectedItem : selectedItems;
        const filteredRecords = isFilterRecord ? this.filterRecords(records, search, addedResourcesList) : records;
        const totalRecords = isFilterRecord ? (filteredRecords || []).length : count;
        const startIndex = isFilterRecord ? 0 : start;
        const defaultTitle = `Add ${typeTitlesMultiple[type]?.toLowerCase() || 'Entities'}`;
        const title = !isEmpty(modalTitle) ? `${modalTitle}` : defaultTitle;
        const isMany = isBulkSelect && selectedItems.length > 1;

        return (
            isOpen && (
                <ModalDialog
                    bgcolor={'rgba(40, 51, 75, 1)'}
                    dialogProps={{ overflow: 'hidden' }}
                    withoutClose={!isMobileView}
                    onClose={this.closeModal}
                    title={!selectRoleActive ? title : 'Set permissions'}
                    actions={
                        isLoading ? (
                            <CircularProgress size={24} color="primary" />
                        ) : (
                            <>
                                {!isMobileView && (<Button onClick={this.closeModal} variant="text" size="small" >Cancel</Button>)}
                                {!selectRoleActive ? (
                                    <Button disabled={isEmpty(selectData)} size="small" onClick={this.onSubmit}>
                                        {submitBtnLabel || 'Add'}
                                    </Button>
                                ) : (
                                    <Button disabled={isEmpty(selectData)} size="small" onClick={this.onSubmit}>
                                        Save
                                    </Button>
                                )}
                            </>
                        )
                    }
                >
                    <StyledDivider />
                    {!selectRoleActive ? (
                        <>
                            <SearchWrapper>
                                <StyledBox>
                                    <Icon name='magnify' />
                                    <StyledTextField
                                        variant='standard'
                                        onChange={this.onChangeSearch}
                                        value={search}
                                        fullWidth
                                        margin='none'
                                        placeholder={`Search ${(typeTitlesMultiple[type] || type || '').toLowerCase()}...`}
                                        InputProps={inputProps}
                                        inputRef={ref => !isMobileView && focusInput(ref)}
                                    />
                                </StyledBox>
                            </SearchWrapper>
                            <StyledDivider />
                            <ResourcesList>
                                <VirtualListManaged
                                    ref={this.virtualListRef}
                                    renderComponent={this.renderComponent}
                                    itemSize={84}
                                    title={`${totalRecords >= 1000 ? '999+' : totalRecords} ${(typeTitlesMultiple[type] || type || '').toLowerCase()}`}
                                    itemCount={totalRecords || 0}
                                    loadData={this.loadData}
                                    isLoading={isLoading}
                                    startIndex={startIndex}
                                    filterBy={filterBy}
                                    orderBy={orderBy}
                                    list={filteredRecords}
                                    maxWidth="1024"
                                />
                            </ResourcesList>
                        </>
                    ) : (
                        <>
                            <FormControl>
                                <StyledLabel margin="12px 0">
                                    {`${selectedItems.length} selected ${!isMany ? 'user' : 'users'} need additional access to be subscribed`}
                                </StyledLabel>
                                <RadioButtonsGroup 
                                    name="role"
                                    value={permissionFormData.role}
                                    onChange={this.onPermissionFormChange}
                                    options={[
                                        { label: 'Commentor', value: 'commenter' },
                                        { label: 'Editor', value: 'editor' },
                                    ]}
                                />
                            </FormControl>
                        </>
                    )}
                </ModalDialog>
            )
        );
    }
}

export default connect(
    state => ({
        isSubmitting: state.admin.workspaces.details.isLoading,
        isLoading: state.admin.workspaces.resourceList.isLoading,
        records: state.admin.workspaces.resourceList.records,
        startIndex: state.admin.workspaces.resourceList.startIndex,
        totalRecords: state.admin.workspaces.resourceList.count,
        isAdmin: state.user?.profile?.isAdmin || false,
        isMobileView: state.global.isMobile
    }),
    {
        loadResourceList,
        clearResourcesList
    }
)(AddNewResourcesModal);
