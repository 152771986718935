import { gql } from '@apollo/client';

export default gql`
query entityLocationHistoryQuery($entityType: String!, $order: AscDesc, $filterBy: [JSON], $excludeBy: [JSON], $startIndex: Int!, $stopIndex: Int!) {
    records: entityChangelogGeoms(entityType: $entityType, startIndex: $startIndex, stopIndex: $stopIndex, order: $order, filterBy: $filterBy, excludeBy: $excludeBy) {
        id
        geom
        _geoJson(fields: null)
        _ewkt
        _wkt
        date
        entity {
            type
            id
            name
        }
    }
}
`;
