/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { DropResult, DragStart, DragDropContext } from 'react-beautiful-dnd';

import ExpansionPanel from 'app/components/Designer/ExpansionPanel';
import List from './List';
import { moveField } from './dragDropUtils';
import { get } from 'app/utils/lo/lo';

export default class DragDropApp extends PureComponent<Object, Object> {

    static propTypes: Object = {
        fields: PropTypes.any,
        removeListItem: PropTypes.func,
        handleChange: PropTypes.func,
        canEdit: PropTypes.bool,
        classId: PropTypes.string,
    };

    state: Object;

    /**
     *
     * @param props
     */
    constructor(props: Object) {
        super(props);
        this.state = {
            fields: this.manipulateInputData(props.fields),
        };
    }

    /**
     * componentDidUpdate - description
     *
     * @param  {type} nextProps description
     * @return {type}           description
     */
    componentDidUpdate(prevProps: Object) {
        if (prevProps.fields !== this.props.fields) {
            this.setState({
                fields: this.manipulateInputData(this.props.fields),
            });
        }
    }


    /**
     * This function will take the input Array
     * and convert it to an object structure with each key
     * represents the group name
     */
    manipulateInputData = (fields: Object) => {
        const matrix = (fields || []).map(arr => arr.map(item => ({ id: item.properties.name, ...item })));
        const obj = {};
        matrix.forEach((data, index) => { obj[get(data, '[0].settings.groupName', 'No Name')] = data; });
        return obj;
    };

    onDragStart = (initial: DragStart) => {
    };

    onDragEnd = (dropResult: DropResult) => {
        if (!dropResult.destination) return;

        const groups = moveField(this.state.fields, dropResult);
        this.props.handleChange(groups);
    };

    /**
     * render - description
     *
     * @return {type}  description
     */
    render() {
        const { fields } = this.state;
        const { canEdit, classId } = this.props;
        return (
            <DragDropContext onDragStart={this.onDragStart} onDragEnd={this.onDragEnd}>
                <div>
                    {/* Here each key will represents the group name */}
                    {Object.keys(fields).map((key) => {
                        return (
                            <ExpansionPanel
                                key={key}
                                header={key}
                                expanded
                            >
                                <List
                                    classId={classId}
                                    key={key}
                                    title={key}
                                    listId={key}
                                    listType="card"
                                    groupData={fields[key]}
                                    canEdit={canEdit}
                                    editListItem={this.props.editListItem}
                                    removeListItem={this.props.removeListItem}
                                />
                            </ExpansionPanel>
                        );
                    }
                    )}
                </div>
            </DragDropContext>
        );
    }
}
