/* @flow */

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import PageTemplate from 'app/components/templates/PageTemplate';
import SupersetFrame from 'app/components/superset/SupersetFrame';
import { get } from 'app/utils/lo/lo';

class SuperAnalytics extends PureComponent<Object, Object> {

    /**
     * @override
     */
    render() {
        return (
            <PageTemplate title="Super Analytics" overflowHidden hasIframe>
                <SupersetFrame
                    title="Super Analytics"
                    src={this.props.supersetURL}
                />
            </PageTemplate>
        );
    }
}
const mapStateToProps = state => ({
    supersetURL: get(state.app.configurations, 'affectli.site.reportingUrl'),
});
export default connect(mapStateToProps)(SuperAnalytics);
