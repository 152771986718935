import React from 'react';
import { IconButton, Tooltip } from '@mic3/platform-ui';

React.useLayoutEffect = React.useEffect; 

const DotMenu = ({ items, componentId, nodeId, ...props }) => {
    return (
        <Tooltip key={1} title={props.tooltipTitle ? props.tooltipTitle : ''}>
            <IconButton key={1}>
                <span class="MuiIconButton-label" dangerouslySetInnerHTML={{
                    __html: `
                            <i onclick="onDotMenuIconClick('${nodeId}')" color="white" class="Icon mdi mdi-dots-vertical"></i>
                        `,
                }} />
            </IconButton>
        </Tooltip>
    );
};

export const buildPipelineMenuItems = ({
    pipelineStatus,
    pipelineMonitor,
    node,
    getCurrentConnectorStatus
}) => {
    let menuItems = [
        { name: 'Edit attributes', icon: 'pencil' },
        { name: 'Remove component', icon: 'delete' },
    ];
    const { group } = node;
    if(pipelineMonitor) {
        const currentConnectorStatus = getCurrentConnectorStatus(pipelineStatus, node);
        menuItems = [
            { name: 'Status', icon: 'monitor-dashboard' },
            { name: 'About', icon: 'information-outline' },
            // { name: 'Log', icon: 'file-outline' },
        ];
        switch(group) {
            case 'connector': {
                const isActvie = currentConnectorStatus?.status === 'active';
                menuItems = [
                    isActvie && { name: 'Stop', icon: 'stop' },
                    isActvie && { name: 'Restart', icon: 'restart' },
                    (!isActvie || !currentConnectorStatus) && { name: 'Start', icon: 'play-box' },
                    ...menuItems.filter(item => !['View data'].includes(item.name)),
                    { name: 'Log', icon: 'file-outline' },
                ].filter(Boolean);
                break;    
            }
            case 'module': 
            case 'external': 
            case 'annotation': {
                menuItems = menuItems.filter(item => ['About'].includes(item.name));
                break;
            }
            case 'topic': {
                menuItems = menuItems.filter(item => !['Log'].includes(item.name));
                break;
            }
            default: 
        }
    }
    return menuItems;
}; 

const PipelineWidgetDotMenu = ({ 
    pipelineStatus, getCurrentConnectorStatus, node, onDotMenuClick, group, 
    nodeId, componentId, pipelineMonitor 
}) => {
    return (
        <DotMenu
            nodeId={nodeId}
            items={buildPipelineMenuItems({
                pipelineStatus,
                pipelineMonitor,
                node,
                getCurrentConnectorStatus
            })}
            componentId={componentId}
        />
    );
};

export default PipelineWidgetDotMenu;