/* @flow */

import React, { useMemo, useCallback } from 'react';
import { Autocomplete } from '@mic3/platform-ui';
import { DOMParser } from '@xmldom/xmldom';

const MessageTypeahead = ({ value: definition, data, onChange, ...restProps }: Object) => {
    const options = useMemo(() => {
        const doc = new DOMParser().parseFromString(definition);
        const messages =  doc.getElementsByTagName('message');
        return Array.from(messages).map((elem) => {
            return {
                value: elem.getAttribute('id'),
                label: elem.getAttribute('name'),
            };
        });
    }, [definition]);

    const handleOnChange = useCallback(({ target: { value, name }}: Object) => {
        const doc = new DOMParser().parseFromString(definition);
        const parenElement = doc.getElementById(data.id);
        const messageEvent = parenElement.getElementsByTagName('messageEventDefinition')[0];
        if(!value) {
            messageEvent.removeAttribute('messageRef');
        } else {
            messageEvent.setAttribute('messageRef', value);
        }
        onChange({ target: { value: String(doc), name }});
    }, [data.id, definition, onChange]);

    const value = useMemo(() => {
        const doc = new DOMParser().parseFromString(definition);
        const parenElement = doc?.getElementById(data.id);
        const messageEvent = parenElement?.getElementsByTagName('messageEventDefinition')[0];
        if(!messageEvent) {
            return null;
        }
        return messageEvent.getAttribute('messageRef') || null;
    }, [data.id, definition]);

    return (
        <Autocomplete
            {...restProps}
            value={value}
            onChange={handleOnChange}
            options={options}
            valueField="value"
        />
    );
};

export default MessageTypeahead;
