import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Ui
import PageNotAllowed from 'app/containers/ErrorPages/PageNotAllowed';
import BroadcastForm from './BroadcastForm';
import { setDocumentTitle } from 'store/actions/app/appActions';
import { modulesAndPageTitles } from 'app/config/typesConfig';

/**
 * Create Broadcast
 */
class CreateBroadcast extends PureComponent {

    static propTypes = {
        userProfile: PropTypes.object
    };

    componentDidMount() {
        const { match, setDocumentTitle } = this.props;
        if(match?.path?.includes('add')){
            setDocumentTitle(modulesAndPageTitles.adminConsole.createBroadcast);
        }
    }

    /**
     * Render our create broadcast form
     */
    render() {
        const { permissions, isAdmin } = this.props.userProfile;
        const permissionsSet = new Set(permissions || []);
        const canAdd = isAdmin || permissionsSet.has('admin.broadcast.add');
        if (!canAdd) {
            return <PageNotAllowed title="Broadcast" />;
        }

        return (
            <BroadcastForm {...this.props} title="Create a new Broadcast" />
        );
    }
}

export default connect(
    state => ({
        userProfile: state.user.profile
    }), { setDocumentTitle })(CreateBroadcast);
