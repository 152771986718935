/* @flow */

import { gql } from '@apollo/client';

export default gql`
query userAutocompleteQuery {
  result: userReferences {
    id
    name
    image
    username
  }
}
`;
