import React from 'react';
import { Checkbox,Table, TableBody, TableCell, TableHead, TableRow, } from '@mic3/platform-ui';
import styled from 'styled-components';

import HeaderBar from 'app/components/molecules/HeaderBar/HeaderBar';
import Tabs from 'app/components/organisms/Tabs/Tabs';
import { BadgeComponent } from 'app/containers/Designer/Form/components/FormField';

const TableStyled = styled(Table)`
    padding: 0 1rem;

    & thead .MuiTableCell-stickyHeader:nth-child(1){
        width: 156px;
    }
    & thead .MuiTableCell-stickyHeader:nth-child(2){
        width: 110px;
    }
    & thead .MuiTableCell-stickyHeader{
        height: 50px;
    }
    & thead .MuiTableRow-root td{
        border-bottom: 1px solid ${({ theme }) => theme.material.colors.secondary.main};
        height: 48px;
    }
    & tbody .MuiTableRow-root td{
        border-bottom: 1px solid ${({ theme }) => theme.material.colors.secondary.main}30;
    }

`;
const BadgeComponentStyled = styled(BadgeComponent)`
    width: 108px;

    & .MuiBadge-badge {
        top: 0.65rem !important;
    }
`;

const cellLabels = {
    CONFIGURE_TOTP: 'Configure OTP',
    UPDATE_PASSWORD: 'Update password',
    UPDATE_PROFILE: 'Update profile',
    VERIFY_EMAIL: 'Verify email',
};

const AuthentificationRequiredActions = ({ tabs, ssoSettings, onChange, canEdit }) => {
    return (
        <>
            <HeaderBar left={<Tabs tabs={tabs}/>} />            
            {ssoSettings?.requiredActions && (
                <TableStyled stickyHeader size={'small'}>
                    <TableHead>
                        <TableRow>
                            {['Required Action', 'Enabled', 'Default Action'].map((cell, i) => (
                                <TableCell align={'left'} key="action" >
                                    {cell === 'Default Action' ? (
                                        <BadgeComponentStyled help="If enabled, any new user will have this required action assigned to it.">{cell}</BadgeComponentStyled>
                                    ) : cell}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {ssoSettings?.requiredActions.map(sett => (
                            <TableRow width="100px" key={sett.name}>
                                <TableCell align="left" key="action" >{cellLabels[sett.name]}</TableCell>
                                <TableCell align="left" key="action" ><Checkbox onChange={onChange} name={`${sett.name}.isEnabled`} value={sett.isEnabled} disabled={!canEdit} /></TableCell>
                                <TableCell align="left" key="action" ><Checkbox onChange={onChange} name={`${sett.name}.isDefaultAction`} value={sett.isDefaultAction} disabled={!sett.isEnabled || !canEdit} /></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </TableStyled>
            )}
        </>
    );
};

export default AuthentificationRequiredActions;