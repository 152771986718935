/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { TextField, Typography, Box, Divider, Grid } from '@mic3/platform-ui';

import SelectionList from 'app/containers/Common/SelectionList/SelectionList';
import Loader from 'app/components/atoms/Loader/Loader';

import { loadTeams, addTeamUsers, removeTeamUsers } from 'store/actions/admin/teamsActions';

import { bind } from 'app/utils/decorators/decoratorUtils';
import { getPermissions } from 'app/config/rolesConfig';
import Immutable from 'app/utils/immutable/Immutable';
import { isEmpty, getStr } from 'app/utils/utils';

const SearchField = styled(TextField)`
    padding: 8px 16px !important;
`;
const StyledDivider = styled(Divider)`
    margin-bottom: 15px !important;
`;
const StyledGrid = styled(Grid)`
    margin: 0 8px;
    position: sticky !important;
    top: 0;
    background: ${({theme})=> theme.material.colors.background.default} !important;
    z-index:999;
`;
const SelectionListWrapper = styled(Box)`
    .MuiExpansionPanelSummary-root.Mui-expanded{
        min-height: 0 !important;
    }
    .MuiExpansionPanel-root.Mui-expanded{
        margin-top: 0 !important;
    }
    .SelectionList__ListStyled-sc-1ocdplt-7{
        height: 100% !important;
    }
    .VirtualList__TinyVirtualListStyled-sc-kz3hth-0{
        overflow: hidden !important;
        height: calc(100% + 30px) !important;
    }
    .MuiExpansionPanelSummary-content{
        margin-top: 0 !important;
    }
`;

class UserTeams extends PureComponent<Object, Object> {
    static propTypes = {
        user: PropTypes.object, // user details
        isLoading: PropTypes.bool,
        loadTeams: PropTypes.func,
        addTeamUsers: PropTypes.func,
        removeTeamUsers: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = Immutable({
            searchValue: ''
        });
        this.defaultState = this.state;
        this.loadUserTeams();
    }

    @bind
    async loadUserTeams() {
        const { user } = this.props;
        await this.props.loadTeams({ options: { filterBy: [{ field: 'users.user.id', op: '=', value: user?.id }] }, withWorkspaces: true });
    }

    componentDidUpdate(prevProps: Object) {
        const { user: { id: prevId } = {} } = prevProps || {};
        const { user: { id: newId } = {} } = this.props || {};
        if (prevId !== newId) {
            this.loadUserTeams();
        }
    }

    @bind
    setDefaultState() {
        this.setState(this.defaultState);
    }

    @bind
    onSearch(event) {
        this.setState({
            searchValue: event.target.value
        });
    }

    @bind
    searchForFrom(searchQuery, data) {
        if (!data || !searchQuery) return false;
        return ['name', 'username', 'id'].some((field) => {
            let value = getStr(data, field, '');
            if (!value) return false;
            value = value.toLowerCase();
            const query = searchQuery.toLowerCase();
            return value.includes(query);
        });
    }

    @bind
    filterSearchedData(searchQuery: string, teams) {
        return {
            teamsFiltered: searchQuery ? (teams || []).filter(item => this.searchForFrom(searchQuery, item)) : teams || []
        };
    }

    @bind
    async onSubmitTeams(newTeams: Array<Object>) {
        const { user: { id: userId } = {}, teams: existingTeams, addTeamUsers } = this.props || {};
        if (!userId || isEmpty(newTeams)) {
            return;
        }
        const addedPromises = newTeams.map(async (team) => {
            if (!existingTeams.find(t => t.id === team.id)) {
                await addTeamUsers(team.id, [userId]);
            }
        }, []);
        await Promise.all(addedPromises).then(() => {
            this.loadUserTeams();
            this.setDefaultState();
        });
    }

    @bind
    async removeTeams(selectedTeamsListIds: Array<string>) {
        const { user: { id: userId } = {}, removeTeamUsers } = this.props || {};
        if (!userId || isEmpty(selectedTeamsListIds)) {
            return;
        }
        const removePromises = selectedTeamsListIds.map(async (teamId) => {
            await removeTeamUsers(teamId, [userId]);
        });
        await Promise.all(removePromises).then(() => {
            this.loadUserTeams();
            this.setDefaultState();
        });
    }

    render() {
        const { user, teams, isLoading } = this.props || {};
        const { searchValue } = this.state;
        const { canEdit } = getPermissions(user.role);
        const { teamsFiltered } = this.filterSearchedData(searchValue, teams);
        const filterBy = [{ field: 'active', op: '=', value: true }];

        return (
            <>
                {isLoading && <Loader absolute backdrop />}
                <StyledGrid>
                    <SearchField
                        fullWidth
                        variant='standard'
                        margin='none'
                        placeholder='Search...'
                        InputProps={{ disableUnderline: true }}
                        value={searchValue}
                        onChange={this.onSearch}
                    />
                    {[...(this.props.sidebarActions || [])]}
                    <StyledDivider />
                </StyledGrid>
                <SelectionListWrapper>
                    <SelectionList
                        filteredList={teamsFiltered}
                        list={teams}
                        type='team'
                        typeChild='workspace'
                        onAdd={this.onSubmitTeams}
                        onRemove={this.removeTeams}
                        isLoading={isLoading}
                        userRole={user.role}
                        disabled={!canEdit}
                        showChildRoles
                        filterBy={filterBy}
                        expansionDetailCaption={
                            <Box p={2}>
                                <Typography variant="body2" color="textSecondary">User has access to the following workspaces and permissions through the team.</Typography>
                            </Box>
                        }
                    />
                </SelectionListWrapper>
            </>
        );
    }
}

export default connect(
    state => ({
        isLoading: state.admin.teams.list.isLoading,
        teams: state.admin.teams.list.records,
    }),
    {
        loadTeams,
        addTeamUsers,
        removeTeamUsers,
    }
)(UserTeams);
