/* @flow */

import React, { Fragment, PureComponent, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { Button, Typography, Grid, Tooltip } from '@mic3/platform-ui';

import {
    deleteChatAttachment,
    deleteChatRoomAttachment, 
    getMessageByFileId, 
    loadRoomMessages,
    uploadRoomFile, 
    uploadRcRoomFile, 
    resetRoomFiles, 
    loadRoomFiles, 
    subscribe, 
} from 'store/actions/chat/chatActions';
import { showToastr } from 'store/actions/app/appActions';

import { AttachmentFile, formatBytes, getExtension, getDownloadAllUrl } from 'app/utils/attachments/attachmentsUtils';
import { getSubscriptionByRel, getChatRequestType } from 'app/utils/chat/chatUtils';
import { bind, memoize, debounce } from 'app/utils/decorators/decoratorUtils';
import { removeSpecialCharacters } from 'app/utils/string/string-utils';
import { deepEquals, isEmpty } from 'app/utils/utils';
import { get } from 'app/utils/lo/lo';

import ResponsiveActions from 'app/components/molecules/ResponsiveActions/ResponsiveActions';
import VirtualListManaged from 'app/components/molecules/VirtualList/VirtualListManaged';
import DropzoneWrapper from 'app/components/molecules/Dropzone/DropzoneWrapper';
import AttachmentIcon from 'app/containers/Common/Attachments/AttachmentIcon';
import UploadButton from 'app/components/molecules/UploadButton/UploadButton';
import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import Filters from 'app/components/organisms/Filters/Filters';
import FileForm from 'app/components/organisms/Chat/FileForm';
import Loader from 'app/components/atoms/Loader/Loader';
import Icon from 'app/components/atoms/Icon/Icon';
import Alert from 'app/components/molecules/Alert/Alert';

const FiltersStyled = styled(Filters)`
    overflow-x: hidden;
`;

const ListItemStyled = styled.div`
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    padding: 1rem;
    ${({ raised, theme }) => (theme && raised ? `box-shadow: ${theme.shadow.boxShadow2DP}; background: ${theme.material.colors.background.paper}; margin-bottom: 1rem; ` : 'padding: .5rem 0;')};
`;

const DropzoneWrapperFull = styled(DropzoneWrapper)`
    height: calc(100vh - 240px);
`;

const GridGrow = styled(Grid)`
    flex-grow: 1;
`;

const ResponsiveActionsStyled = styled(ResponsiveActions)`
    display: flex;
    flex-wrap: no-wrap;
    margin-right: .5rem;
`;

const DownloadAllLinkStyled = styled.a`
    margin: 0 6px 0 16px;
`;

const TypographyCaptionkStyled = styled(Typography)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
`;

const SubscribeOnchatWrapper = styled.div`
    position: absolute;
    top: 70px;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 99;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    flex-shrink: 0;
    justify-content: center;
    margin: 1rem;
    gap: 0.625rem;
`;

const NoAttachmentsWrapper = styled.div`
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
`;

const IconStyled = styled(Icon)`
    &:before {
        font-size: 85px !important;
        color: ${({theme})=> theme.material.colors.text.caption};
        @media (max-width:481px){
            font-size: 48px !important;
        }
    }
`;

const LabelStyled = styled.p`
    font-weight: 200;
    color: ${({theme})=> theme.material.colors.text.caption};
    margin: 0;
`;

const ButtonStyled = styled(Button)`
    padding: 12px !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.75px;
    text-transform: uppercase;
`;

const DescriptionStyled = styled(Typography)`
    width: 100%;
    overflow-wrap: break-word;
`;
const StyledTypography = styled(Typography)`
    opacity: 70%;
`;

const AttachmentListItem = (props: Object) => {
    const [isDisabled, toggleDisable] = useState(false);
    const {
        getMessageByFileId,
        deleteChatAttachment,
        subscription,
        entityType,
        loadRoomMessages,
        deleteChatRoomAttachment
    } = props;

    const deleteFile = useCallback(async (data: string) => {
        toggleDisable(true);
        const { rid: roomId, _id: fileId } = data;
        const { id, type } = subscription.rel;
        const isNotEntityChat = ['channel', 'direct', 'group', 'live'].includes(type); 
        
        if (!isNotEntityChat) {
            const message = await getMessageByFileId({ roomId, fileId });
            if (message && message._id) {
                deleteChatAttachment(id, entityType || type, message._id).then((resp) => {
                    loadRoomMessages(subscription);
                    resp?.error && toggleDisable(false); // If query gets failed we will make the button available again
                });
            } else {
                toggleDisable(false); // If getMessageByFileId call failed we will make the button available again
            }
        } else {
            deleteChatRoomAttachment(fileId).then((resp) => {
                loadRoomMessages(subscription);
                resp?.error && toggleDisable(false); 
            });
        }
        
    }, [getMessageByFileId, deleteChatAttachment, entityType, subscription, loadRoomMessages, deleteChatRoomAttachment]);

    const { data, canEdit } = props;
    const { type, name, path, size, uploadedAt, description } = data || {};
    const url = path.startsWith('/') ? path : `/${path}`;

    return (
        <ListItemStyled raised>
            <Grid container wrap="nowrap" spacing={4} alignItems="center">
                <Grid item>
                    <AttachmentIcon file={data} />
                </Grid>
                <GridGrow item container direction="column" zeroMinWidth>
                    <AttachmentFile href={url}>{name}</AttachmentFile>
                    <DescriptionStyled key={1}>{description}</DescriptionStyled>
                    <TypographyCaptionkStyled
                        variant="caption"
                        key={2}
                    >{`${getExtension(type)} - ${formatBytes(size)} ${moment(
                        uploadedAt
                    ).from(moment())}`}</TypographyCaptionkStyled>
                </GridGrow>
                <ResponsiveActionsStyled
                    actions={[
                        <AttachmentFile href={url} key="1">
                            <Icon name="download" />
                        </AttachmentFile>,
                        canEdit && (
                            <Icon
                                name="delete"
                                disabled={isDisabled}
                                onClick={() => deleteFile(data)}
                                key="2"
                            />
                        )
                    ]}
                />
            </Grid>
        </ListItemStyled>
    );
};
/**
 * Renders the view to display the classification.
 */
class AttachmentsView extends PureComponent<Object, Object> {

    static propTypes = {
        id: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        isLoading: PropTypes.bool,
        records: PropTypes.array,
        permissions: PropTypes.object.isRequired,
        totalRecords: PropTypes.number,
        loadRoomFiles: PropTypes.func.isRequired,
        loadRoomMessages: PropTypes.func.isRequired,
        showToastr: PropTypes.func.isRequired,
        subscription: PropTypes.shape({
            unread: PropTypes.number,
            rid: PropTypes.string,
            name: PropTypes.string,
            updatedAt: PropTypes.string,
            rel: PropTypes.shape({
                id: PropTypes.string,
                type: PropTypes.string,
            })
        }),
    };

    // $FlowFixMe
    virtualListRef = React.createRef();

    filterDefinitions = [
        {
            field: 'name',
            type: 'text',
            properties: {
                label: 'Name',
                name: 'name'
            },
        },
        {
            field: 'type',
            type: 'typeahead',
            properties: {
                label: 'Mime Type',
                name: 'type',
                options: [
                    { value: 'image', label:'Image' },
                    { value: 'application', label: 'File' },
                    { value: 'text', label: 'Text' },
                    { value: 'application/octet-stream', label: 'Audio' },
                    { value: 'pdf', label: 'Pdf' },
                ],
            },
            sort: false,
        },
        {
            field: 'uploadedAt',
            type: 'dateTimeRange',
            properties: {
                label: 'Uploaded date',
                name: 'uploadedAt',
            },
        },
    ];

    searchBar = ['name'];
    defaultOrder = [{ field: 'uploadedAt', direction: 'desc nulls last' }];

    constructor(props) {
        super(props);
        this.state = { files: [] };
        this.refresh();
        get(props, 'subscription.rid') && props.resetRoomFiles(props.subscription.rid);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.files[0] && !this.state.files[0]) {
            this.refresh();
        }
        if(prevProps.id !== this.props.id || !deepEquals(prevProps.messages, this.props.messages)) {
            this.refresh();
        }
    }

    @bind
    @memoize()
    isCanEdit(type, profile) {
        const { permissions } = this.props;
        const { isAdmin } = profile;
        const { canEdit, canComment } = permissions || {};
        const isNotEntityChat = ['channel', 'direct', 'group', 'live', 'team'].includes(type); 
        return !type || isAdmin || canEdit || canComment || type === 'task' || type === 'process' || isNotEntityChat;
    }

    @bind
    @debounce()
    refresh() {
        this.virtualListRef.current && this.virtualListRef.current.resetView();
    };

    @bind
    async uploadFile({ file, filename, description }: Object) {
        let parsedFilename = removeSpecialCharacters(filename);
        const fileExtension = file.name.split('.').pop();
        if (fileExtension !== parsedFilename.split('.').pop()) {
            parsedFilename = `${parsedFilename}.${fileExtension}`;
        }
        file = new File([file], parsedFilename, { type: file.type });

        const {
            entityType,
            type,
            id,
            subscription,
            uploadRoomFile,
            uploadRcRoomFile,
            loadRoomFiles,
            loadRoomMessages
        } = this.props;
        const isNotEntityChat = ['channel', 'direct', 'group', 'live'].includes(type); 
        const reqType = getChatRequestType(type);
        if (!isNotEntityChat) {
            try {
                uploadRoomFile({
                    id,
                    type: entityType || type,
                    file,
                    description
                }).then(() => {
                    loadRoomFiles(subscription.rid, reqType);
                    loadRoomMessages(subscription);
                });
            } catch (err) {}
        } else {
            try {
                await uploadRcRoomFile({
                    type,
                    id,
                    file,
                    filename: parsedFilename,
                    description
                }).then(() => {
                    loadRoomFiles(subscription.rid, reqType);
                    loadRoomMessages(subscription);
                });
            } catch(err) {}
        }
        const files = [...this.state.files];
        files.shift();
        this.setState({ files });
    }

    @bind
    uploadRoomFileDropzone(files) {
        const { disabledDropzone } = this.props;
        if(disabledDropzone) {
            return;
        }
        return this.uploadRoomFile(files);
    }

    @bind
    uploadRoomFile(files) {
        let nextFiles = files;
        if (files instanceof File) {
            nextFiles = [files];
        }
        nextFiles = Array.isArray(files) ? files : Object.values(files);
        // sometimes when we upload multiple files with different types the function is called seperately for each type
        // that is why we need to iclude the previous files.
        return this.setState(prevState => ({ files: [...prevState.files, ...nextFiles.map((file) => {
            // $FlowFixMe
            const nextFile = new File([file], removeSpecialCharacters(file.name), { type: file.type });
            return nextFile;
        })] }));
    }


    @bind
    renderComponent(props: Object) {
        const { _id } = props.data || {};
        const { 
            subscription, 
            entityType, 
            getMessageByFileId, 
            deleteChatAttachment,
            deleteChatRoomAttachment, 
            loadRoomMessages 
        } = this.props;
        return (
            <AttachmentListItem
                key={_id}
                {...props}
                entityType={entityType}
                subscription={subscription}
                canEdit={this.canEdit}
                getMessageByFileId={getMessageByFileId}
                deleteChatAttachment={deleteChatAttachment}
                deleteChatRoomAttachment={deleteChatRoomAttachment}
                loadRoomMessages={loadRoomMessages}
            />
        );
    };

    @bind
    loadAttachments(options) {
        const { subscription, loadRoomFiles }= this.props;
        let queries = {};
        if (options.filterBy.length) {
            const searchFields = options.filterBy.filter(({ field }) => field === 'name');
            if(searchFields?.length > 1){
                const nonSearchFields = options.filterBy.filter(({ field }) => field !== 'name');
                options.filterBy = nonSearchFields;
                queries = { $and: searchFields.map(f => ({ [f?.field]: {'$regex':f.value, '$options' : 'i'}}))};
            }
            queries = options.filterBy.reduce((q, query) => {
                if(['name', 'type'].includes(query.field)) {
                    q[query.field] = {'$regex':query.value, '$options' : 'i'};
                }
                if(query.field === 'uploadedAt') {
                    const [ fromD, toD ] = query.value;
                    q.uploadedAt = {
                        '$gte': { '$date': fromD },
                        '$lte': { '$date': toD }
                    };
                }
                return q;
            }, queries);
        }
        const order = options.orderBy[0];
        const sort = { [order.field]: order.direction === 'asc' ? 1 : -1};
        const { rel: { type } = {} } = subscription || {};
        const reqType = getChatRequestType(type);
        return loadRoomFiles(subscription.rid, reqType, queries, sort);
    }

    @bind
    downloadAll() {
        const { id, type } = this.props;
        window.open(getDownloadAllUrl(id, type));
    }

    @bind
    closeFileForm() { this.setState({ files: [] }, this.refresh); }

    @bind
    addToChatMembers() {
        const { id, subscribe, type, entityType, profile }= this.props;
        const chatType = entityType || type;
        subscribe(id, chatType, [profile.id])
            .then(this.reloadDetails)
            .then(this.refresh);
    }

    @bind
    @debounce()
    reloadDetails() {
        const { id } = this.props;
        return this.props.reloadDetails(id);
    }

    @bind
    @memoize()
    rightToolbar(totalRecords, subscription, type, id){
        return (
            <>
                {
                    this.canEdit
                  && (
                      <Tooltip title="Upload files">
                          <span><UploadButton loading={false} onSelect={this.uploadRoomFile} multiple /></span>
                      </Tooltip>
                  )
                }
                {
                    totalRecords > 0
                  && (
                      <Tooltip title="Download all">
                          <DownloadAllLinkStyled href={`/media/chat/${subscription.rid}/zip`} download={`${type}_${id}_attachments.zip`}>
                              <Icon name="download-multiple" />
                          </DownloadAllLinkStyled>
                      </Tooltip>
                  )
                }
            </>
        );
    }

    @bind
    @memoize()
    renderUploadBtnChild(onClickFn) {
        return (
            <ButtonStyled variant='text' color='secondary' onClick={onClickFn}>
                UPLOAD ATTACHMENT
            </ButtonStyled>
        );
    }

    @bind
    renderNoResultComponent(filterBy: Object) {
        const { type, profile, records,  } = this.props;
        this.canEdit = this.isCanEdit(type, profile);
        if (filterBy?.length) {
            return (
                <Alert type='background' margin={16}>
                    No results
                </Alert>
            );
        }else if(!filterBy?.length && !records.length ){
            return (
                <NoAttachmentsWrapper>
                    <div style={{textAlign: 'center'}}>
                        <IconStyled name="paperclip" />
                        <LabelStyled>Let's Get Started</LabelStyled>
                        {this.canEdit && (
                            <UploadButton 
                                loading={false} 
                                onSelect={this.uploadRoomFile}
                                children={this.renderUploadBtnChild} 
                                multiple
                            />
                        )}
                    </div>
                </NoAttachmentsWrapper>
            );
        }
        return null;
    }

    /**
     * @override
     */
    render() {
        const {
            records, subscription, isSubscribing, isLoading, totalRecords = 0, id, isFileUploading, type, profile
        } = this.props;
        const { files } = this.state;
        if (isSubscribing) {
            return <Loader />;
        }
        if (!subscription) {
            return (
                <SubscribeOnchatWrapper>
                    <StyledTypography>Subscribe to see the attachments.</StyledTypography>
                    <Button onClick={this.addToChatMembers}>SUBSCRIBE</Button>
                </SubscribeOnchatWrapper>
            );
        }
        this.canEdit = this.isCanEdit(type, profile);
        return (
            <Fragment>
                <FiltersStyled
                    id={`AttachmentsView.${id}`}
                    filterDefinitions={this.filterDefinitions}
                    defaultOrder={this.defaultOrder}
                    searchBar={this.searchBar}
                    heightOffset={260}
                    rightToolbar={this.rightToolbar(totalRecords, subscription, type, id)}
                >
                    {(filterBy, orderBy) => (
                        <DropzoneWrapperFull
                            onDropRejected={this.uploadRoomFileDropzone}
                            onDropAccepted={this.uploadRoomFileDropzone}
                        >
                            <VirtualListManaged
                                ref={this.virtualListRef}
                                renderComponent={this.renderComponent}
                                itemSize={100}
                                itemCount={totalRecords}
                                title={totalRecords ? `${totalRecords >= 1000 ? '999+' : totalRecords } attachments` : ''}
                                loadData={this.loadAttachments}
                                isLoading={isLoading}
                                startIndex={0}
                                filterBy={filterBy}
                                orderBy={orderBy}
                                list={records}
                                maxWidth="1024"
                                itemKey="_id"
                                noResult={()=> this.renderNoResultComponent(filterBy)}
                            />
                        </DropzoneWrapperFull>
                    )}
                </FiltersStyled>
                {!!files[0] && (
                    <ModalDialog onClose={this.closeFileForm} title="Upload file?">
                        <FileForm 
                            uploadFile={this.uploadFile} 
                            isLoading={isFileUploading} 
                            close={this.closeFileForm} 
                            file={files[0]} 
                            formFields={[
                                {
                                    field: 'filename',
                                    type: 'text',
                                    properties: {
                                        name: 'filename',
                                        label: 'File Name'
                                    },
                                    constraints: { required: true }
                                },
                                {
                                    field: 'description',
                                    type: 'text',
                                    properties: {
                                        name: 'description',
                                        label: 'File Description'
                                    }
                                }
                            ]}
                        />
                    </ModalDialog>
                )}
            </Fragment>
        );
    }
}

export default connect(
    (state, props) => {
        const { type, id } = props;
        const subscriptions = state.chat.subscriptions.data;
        const subscription = getSubscriptionByRel(subscriptions, { type, id });
        const messages = !isEmpty(subscription) ? state.chat.room.messages[subscription.rid] || [] : [];
        return {
            messages,
            subscription,
            profile: state.user.profile,
            records: state.chat.files.data,
            isLoading: state.chat.files.isLoading,
            totalRecords: state.chat.files.data.length,
            isSubscribing: state.chat.room.isSubscribing,
            isFileUploading: state.chat.room.isFileUploading,
        };
    },
    {
        showToastr,
        uploadRoomFile,
        uploadRcRoomFile,
        loadRoomFiles,
        getMessageByFileId,
        deleteChatAttachment,
        deleteChatRoomAttachment,
        subscribe,
        resetRoomFiles,
        loadRoomMessages
    }
)(AttachmentsView);
