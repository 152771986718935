// @flow

import Map from 'ol/Map';
import View from 'ol/View';
import { fromLonLat } from 'ol/proj';
import TileLayer from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';
import { Vector as VectorSource } from 'ol/source';
import { defaults as defaultControls, FullScreen, ScaleLine } from 'ol/control';
import OSM from 'ol/source/OSM';
import Bing from 'ol/source/BingMaps';
import GeoJSON from 'ol/format/GeoJSON';
import VectorLayer from 'ol/layer/Vector';
import { Group as LayerGroup } from 'ol/layer';
import Overlay from 'ol/Overlay';
import { get } from 'app/utils/lo/lo';
import { Point } from 'ol/geom';
import Feature from 'ol/Feature';
import MeasureTool from 'app/utils/maps/tools/measureToolUtils';
import DrawingTool from 'app/utils/maps/tools/mapDrawingToolsUtils';
import RouteHelperTool from 'app/utils/maps/tools/mapRouteUtils';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { Circle as CircleStyle, Fill, Stroke, Style, Text } from 'ol/style';
import { isDefined } from 'app/utils/utils';
import mdiMapping from 'app/components/molecules/Map/EntityPin/entitiesPinMap.json';
import NorthControl from 'app/utils/maps/controller/NorthControl';
import ScaleControl from 'app/utils/maps/controller/ScaleControl';
import GpsControl from 'app/utils/maps/controller/GpsControl';
import OLCesium from 'olcs/OLCesium';
import DoubleClickZoom from 'ol/interaction/DoubleClickZoom';
import ClusterLayer from 'app/containers/Maps/EntityLayerStyling/ClusterLayer';
import EntityLayer from 'app/containers/Maps/EntityLayerStyling/EntityLayer';
import 'style/maps/olToolStyles.css';
import { entityPinStyle, singleFeatureStyle } from 'app/components/molecules/Map/EntityPin/entityPinStyle';
import Modify from 'ol/interaction/Modify';
import Collection from 'ol/Collection';
import LineString from 'ol/geom/LineString';
import Select from 'ol/interaction/Select';
import RelatedEntityLayer from '../EntityLayerStyling/RelatedEntityLayer'; //module;
import HeatMapLayer from '../EntityLayerStyling/HeatMapLayer'; 
import * as turf from '@turf/turf';
import DrawingLayer from 'app/containers/Maps/EntityLayerStyling/DrawingLayer';
import ThreeDLayer from '../EntityLayerStyling/ThreeDLayer';
import Geocode from 'app/utils/maps/geocodeUtils';
import cesiumLayerConfig from 'app/config/cesiumLayerConfig';
import { click } from 'ol/events/condition';
import customIconSet from 'app/components/molecules/Map/EntityPin/customEntityPin.js';
import { create3DIconCanvas } from 'app/utils/maps/layer/layerUtils';
import { getAttachmentUrl } from 'app/utils/attachments/attachmentsUtils';
import { rocketHost } from 'app/utils/env';
import { Heatmap } from 'ol/layer.js';
import * as Cesium from 'cesium';
import ReplayLayer from 'app/containers/Maps/LayerAbout/ReplayLayer/ReplayLayer';

import 'cesium/Source/Widgets/widgets.css';
window.Cesium = Cesium; // expose Cesium to the OL-Cesium library

class olMap {
    overlayMap: Array<Object> = [];
    map: Object = {};
    view: Object = {};
    baseLayers: Array<Object> = [];
    overlayLayers: Array<Object> = [];
    customInteractions: Object = {};
    lastInteractionType: any = null;
    routeHelperTool: Object = {};
    _ol3d: any;
    is3DVisible = false;
    enable3D: boolean = false;
    defaultPreferences: Object = {};
    entityLayers: Object = {};
    IamHereLayer: null;
    dragDropFeatures: null;
    targetID: null;
    select: null;
    eraserStatus: false;
    isHeatMap: any = [];
    isRelatedMap: any = [];

    constructor() {
        this.customInteractions = {
            measurement: new MeasureTool(this),
            drawing: new DrawingTool(this)
        };
        this.routeHelperTool = new RouteHelperTool(this);
    }

    @bind
    initMapPanel(targetId: string, title) {
        this.targetID = targetId;
        const controls = [
            new FullScreen(),
            new NorthControl({ olMapVM: this }),
            new ScaleControl({ olMapVM: this }),
            new GpsControl({ olMapVM: this }),
            new ScaleLine({
                className: 'custom_scale',
                units: 'metric',
                bar: false
            })
        ];
        this.setMap(targetId, controls, title);

        for (const key in this.customInteractions) {
            this.customInteractions[key].initTool();
        }
        const dblClickZoom = this.getMap()
            .getInteractions()
            .getArray()
            .find(interaction => interaction instanceof DoubleClickZoom);
        if (dblClickZoom) {
            this.getMap().removeInteraction(dblClickZoom);
        }
        this.addHighlightLayer();
    }

    @bind
    initEntityAboutMap(targetId: string, locationMap) {
        this.setMap(targetId, [], locationMap);
    }

    @bind
    initPrimaryAboutSvgMap(targetId: string, locationMap) {
        this.setMap(targetId, [], locationMap);
    }

    @bind
    setOpacity(opacity: any) {
        return isDefined(opacity) ? opacity : 1;
    }

    @bind
    setDefaultPreferences(pref: Object) {
        const { name, layers = [] } = pref || {};
        const baseLayers = {
            layers: [
                {
                    title: 'Bing (Aerial)',
                    visible: false,
                    opacity: 1
                },
                {
                    title: 'Bing (Roads)',
                    visible: false,
                    opacity: 1
                },
                {
                    title: 'OSM',
                    visible: false,
                    opacity: 1
                }
            ]
        };

        baseLayers.layers = name && baseLayers.layers.map(lyr => ({ ...lyr, visible: lyr.title === name }));
        this.defaultPreferences = {
            baseLayers,
            allLayers: {
                layers
            }
        };
    }

    @bind
    addWMSPreviewLayer({ url, layer, extent }: Object) {
        const updatedUrl = url.split('?')[0];
        const previewWMSLayer = this.getMap()
            .getLayers()
            .array_.find(lyr => lyr.values_.title === 'Preview WMS Layer');
        if (previewWMSLayer) {
            this.getMap().removeLayer(previewWMSLayer);
        }

        this.getMap().addLayer(
            new TileLayer({
                title: 'Preview WMS Layer',
                source: new TileWMS({
                    url: updatedUrl,
                    params: {
                        LAYERS: layer,
                        TILED: true
                    },
                    crossOrigin: 'anonymous'
                })
            })
        );

        this.zoomToSavedMapExtent(extent);
        this.updateMap();
    }

    @bind
    updateMap() {
        this.getMap().updateSize && this.getMap().render();
    }

    @bind
    getDefaultPreferences() {
        return this.defaultPreferences;
    }

    @bind
    loadPreferences(preferences: Object) {
        if (preferences) {
            const { baseLayers, allLayers } = preferences;
            this.getMap()
                .getLayers()
                .array_.forEach((layerGroup) => {
                    if (layerGroup.values_.title === 'baseLayers' && baseLayers) {
                        layerGroup.getLayers().array_.forEach((layer, index) => {
                            const visible = get(baseLayers, `layers[${index}].visible`);
                            layer.values_.visible = isDefined(visible) ? visible : index === 0;
                            layer.values_.opacity = this.setOpacity(get(baseLayers, `layers[${index}].opacity`));
                        });
                    } else if (layerGroup.values_.title === 'allLayers' && allLayers) {
                        this.orderLayers(layerGroup.getLayers().array_, allLayers.layers);
                    }
                });
            this.getMap().render();
        }
    }

    @bind
    orderLayers(array: Array<Object>, order: Array<Object>) {
        if (array && order && order.length) {
            array
                .sort((a, b) => order.findIndex(item => item.title === a.values_.title) - order.findIndex(item => item.title === b.values_.title));
        }
        this.getMap().render();
    }

    @bind
    setPinCenter(longitude: number, latitude: number, accuracy: number = 20, userLocation: boolean = false) {
        const long = parseFloat(longitude);
        const lat = parseFloat(latitude);

        this.IamHereLayer = this.getMap()
            .getLayers()
            .array_.find(layer => layer.values_.title === 'IamHere Layer');

        if (this.IamHereLayer) {
            this.IamHereLayer.getSource().clear();
            this.map.removeLayer(this.IamHereLayer);
            this.IamHereLayer = null;
        }

        const marker = new Feature({
            geometry: new Point(fromLonLat([long, lat]))
        });

        if (userLocation) {
            const minAccuracy = 200;
            const radius = accuracy < minAccuracy ? accuracy : minAccuracy;

            const vectorSource = new VectorSource({
                features: [marker]
            });
            this.IamHereLayer = new VectorLayer({
                source: vectorSource,
                style: (feature, resolution) => {
                    const scaledRadius = (radius * 0.6) / resolution;
                    return new Style({
                        image: new CircleStyle({
                            radius: scaledRadius,
                            fill: new Fill({
                                color: 'rgba(0, 81, 255, 0.3)'
                            })
                        }),
                        text: new Text({
                            font: 'normal normal normal 24px/4 "Material Design Icons"',
                            text: String.fromCodePoint(mdiMapping['map-marker'], 16),
                            fill: new Fill({ color: '#3D64B7' })
                        })
                    });
                },
                title: 'IamHere Layer'
            });
            this.getMap().addLayer(this.IamHereLayer);
        }

        this.flyTo(marker, accuracy);
    }

    @bind
    flyTo(marker: Object, accuracy: number, isDrawing) {
        const duration = 2500;
        let zoom = this.getView().getZoom();
        const currentZoom = 4;
        if (zoom > 4) {
            zoom = currentZoom;
        }
        let parts = 2;
        let called = false;

        const onComplete = (complete) => {
            --parts;
            if (called) {
                return;
            }
            if (parts === 0 || !complete) {
                called = true;
            }
        };

        this.getView().animate(
            {
                center: marker.getGeometry().getCoordinates(),
                duration: duration
            },
            onComplete
        );
        this.getView().animate(
            {
                zoom: zoom - 1,
                duration: duration / 2
            },
            {
                zoom: isDrawing ? 7 : 18,
                duration: duration / 2
            },
            onComplete
        );
    }

    @bind
    addCustomInteractions(type: string, drawingType: number, callback: Function) {
        this.removeAllCustomInteractions();
        this.customInteractions[type].addInteractionOnMap(drawingType, callback);
    }

    @bind
    removeAllCustomInteractions() {
        try {
            for (const key in this.customInteractions) {
                this.customInteractions[key].removeInteractionFromMap();
            }
        } catch (e) {}
    }

    @bind
    getLastInteraction() {
        return this.lastInteractionType;
    }

    @bind
    toggleMapLayer(item: Object, isBaseLayer: boolean, visible = null, threeDVisible) {
        if (isBaseLayer) {
            const baseLayers = this.getLayersGroup('baseLayers');
            baseLayers.getLayers().array_.forEach((layer) => {
                layer.values_.visible = layer.values_.title === get(item, 'values_.title');
            });
            if (this.enable3D) {
                this.setCesiumBaseLayer(this.cesiumBaseLayers[item.values_.title]);
            }
        } else if (this.enable3D) {
            const {name} = item.values_;
            const entityLayer = this.getEntityLayer(name);
            if (name) {
                const cesiumScene = this._ol3d.getCesiumScene();
                const primitives = cesiumScene?.primitives;
                for (let i = 0; i < primitives?.length; i++) {
                    const primitive = primitives.get(i);
                    if (primitive && primitive instanceof Cesium?.Cesium3DTileset) {
                        if (primitive.featureIdLabel === name) {
                            primitive.show = threeDVisible;
                        }
                    }
                }
                if (entityLayer?.dataSource3D) {
                    const visiblity = entityLayer.getLayer().getVisible(); //entityLayer.getVisibility();
                    entityLayer.dataSource3D.show = visiblity;
                    entityLayer.setVisibility(visiblity);
                }
                if(visible) {
                    entityLayer?.refresh3DMapLayers(this.getCesiumObj(), this.getOl3d(), this.enable3D);
                }
            }
           
        } else {
            const { title } = item.values_;
            item.setVisible(visible);
            if (isDefined(this.lastInteractionType)) {
                if (visible) {
                    if (title === 'Measure Layer') {
                        this.customInteractions['measurement'].hideMeasureOverlays();
                        return this.addCustomInteractions('measurement', -1);
                    }
                    return this.removeAllCustomInteractions();
                }
                if (title === 'Measure Layer') {
                    this.customInteractions['measurement'].showMeasureOverlays();
                }
                if (this.lastInteractionType[1] !== 100) {
                    this.addCustomInteractions(this.lastInteractionType[0], this.lastInteractionType[1]);
                }
            }
        }
        this.getMap().render();
    }

    @bind
    refreshMapLayers(is3d) {
        Object.keys(this.entityLayers).forEach((key) => {
            this.entityLayers[key].isFeatureLoaded = false;
        });
        const mapLayers = this.getMap().getLayers();
        (mapLayers || []).forEach((layer) => {
            if (layer instanceof LayerGroup) {
                const layers = layer.getLayers();
                (layers || []).forEach((lyr) => {
                    if(lyr?.values_?.attributes?.type !== 'drawing-layer') {
                        if (lyr instanceof VectorLayer) { //&& lyr.getVisible()
                            this.refreshLayer(lyr);
                        }
                    }
                });
            }
        });
        if(!is3d)
            this._ol3d = null;
        this.getMap().render();
    }

    @bind
    refreshLayer(lyr: Object) {
        try {
            if (lyr instanceof VectorLayer) {
                const entityLayer = this.entityLayers[lyr?.values_.attributes?.id];
                const relatedEntities = entityLayer?.relatedEntities.length && entityLayer.relatedEntities[0];
                const relatedLayer = relatedEntities && relatedEntities.getLayer();
                const isHeatmap = entityLayer?.heatMapLayers?.[0];
                const heatmapLayer = isHeatmap?.getLayer();
                heatmapLayer && this.refreshLayerSource(heatmapLayer);
                this.refreshLayerSource(lyr);
                this.refreshLayerSource(entityLayer.layer); 
                relatedLayer && this.refreshLayerSource(relatedLayer);
                this.map && this.map.updateSize();   
            }
        } catch (e) {}
    }

    @bind
    refreshLayerSource(layer) {
        if(!layer) return;
        const source = layer.getSource();
        if (source) {
            source.refresh();
            if (source?.getSource) {
                const innerSource = source.getSource();
                if (innerSource) {
                    innerSource.refresh();
                }
            }
        }
    }

    @bind
    @memoize()
    hasFeature(item: Object) {
        if (item.getSource() instanceof TileLayer || item.getSource() instanceof TileWMS) {
            return false;
        } else {
            const extent = item.getSource().getExtent();
            return !isNaN(extent[0]);
        }
    }

    @bind
    async zoomToMapLayer(item: Object, zoom: Number) {
        if (item?.getSource() instanceof TileLayer || item?.getSource() instanceof TileWMS) {
            return;
        }
        const extent = await item?.getSource().getExtent();
        if (!isNaN(parseInt(extent?.[0]))) {
            // Because someties array does have Infinity
            const size = await this.getMap().getSize();
            const mapView = await this.getMap().getView();
            await mapView.fit(extent, { size, maxZoom: zoom ? zoom : 16 });
        }
    }

    @bind
    @memoize()
    findLayer(layerGroupName, layerTitle) {
        const layersGroup = this.getLayersGroup(layerGroupName);
        return layersGroup && layersGroup.getLayers ? layersGroup.getLayers().array_.find(({ values_ }) => values_.title === layerTitle) : layersGroup;
    }

    @bind
    findLayerById(id: number) {
        const layersGroup = this.getLayersGroup('allLayers');
        return layersGroup.getLayers().array_.find(({ values_ }) => values_.attributes.id === id);
    }

    @bind
    findLayerByTitle(title) {
        const layersGroup = this.getLayersGroup('allLayers');
        return layersGroup.getLayers().array_.find(({ values_ }) => values_.title === title);
    }

    @bind
    findLayerByType(id, type) {
        const layers = this.map?.getLayers()?.array_;
        if(!layers?.length) return null;
        return layers.find((lyr) => {
            const atr = lyr.values_?.attributes || {};
            return atr?.id === id && atr?.type === type;
        });
    }


    @bind
    async removeLayerById(id: number) {
        const layersGroup = this.getLayersGroup('allLayers');
        const layer = layersGroup.getLayers().array_.find(({ values_ }) => values_.attributes.id === id);
        await this.getMap().removeLayer(layer);
    }

    @bind
    onOpacityChange(item: Object, value: number) {
        item.setOpacity(value);
        this.getMap().render();
    }

    @memoize()
    createCircleOutOverlay(position: any, count: number, selectedLayer: boolean) {
        const elem = document.createElement('div');
        if(selectedLayer)
            elem.setAttribute('class', 'pulse-static');
        else
            elem.setAttribute('class', 'pulse');
        const size = parseInt(38 + count / 200);
        elem.style.width = elem.style.height = `${size}px`;

        return new Overlay({
            id: 'hoverOverlay',
            title: 'hoverOverlay',
            element: elem,
            position: position,
            positioning: 'center-center',
            stopEvent: false
        });
    }

    @bind
    closeOverlay(overlay: Object) {
        if (overlay instanceof Overlay) {
            overlay.setPosition(undefined);
        }
    }

    @bind
    createOverlay(element: Element) {
        return new Overlay({
            element: element,
            autoPan: true,
            autoPanAnimation: {
                duration: 250
            }
        });
    }

    @bind
    findHighLightLayer() {
        const allLayers = this.getMap()?.getLayers()?.array_;
        return allLayers.find(lyr => !(lyr instanceof LayerGroup) && lyr.values_?.attributes?.id === '9b1caeb6-243f-11ed-8f33-0242ac130009');
    }

    @bind
    async highlightFeature(coord: any, count: ?number = 1, selectedLayer: boolean) {
        const overlay = this.createCircleOutOverlay(coord, count, selectedLayer);
        this.overlayMap.push(overlay);
        this.getMap() && this.getMap().addOverlay(overlay);
    }

    @bind
    highLightFeatures(layerId: string, isVisible) {
        try {
            if (!this.is3dEnabled()) {
                const lyr = this.findHighLightLayer();
                if (lyr) {
                    lyr.getSource().clear();
                    lyr.setZIndex(0);
                }
                const layersGroup = this.getMap()
                    .getLayers()
                    .array_.find(layer => layer.values_.title === 'allLayers');
                if (!layersGroup) {
                    throw new Error('Layers group "allLayers" not found');
                }
                const layer = layersGroup.getLayers().array_.find(
                    layer => layer.values_.attributes.id === layerId && (layer.values_.visible || isVisible)
                );
                if (!layer) {
                    throw new Error(`Layer with id ${layerId} not found or not visible`);
                }
                layer.setZIndex(1);
                const layerFeatures = layer.getSource?.().getFeatures();
                if (layerFeatures && layerFeatures.length && lyr) {
                    lyr.getSource().addFeatures(layerFeatures);
                }
                this.map.render();
            }
        } catch (error) {
            console.error('Error in highLightFeatures:', error.message); // eslint-disable-line
        }
    }


    /**
     currently it is implemented like this because on top level baseLayers and layers (processes, entities ...) are layersGroup
     but draw and measure are simple layers.
     */
    @bind
    @memoize()
    getLayersGroup(title: string) {
        const layers = this.getMap && this.getMap().getLayers && this.getMap().getLayers();
        if (layers && title) {
            return layers.array_.find(layerGroup => layerGroup.values_.title === title);
        }
        return null;
    }

    @bind
    reorderLayers(array: Array<Object>, startIndex: number, endIndex: number) {
        const layersGroup = this.getLayersGroup('allLayers');
        if (layersGroup) {
            const layersArray = layersGroup.getLayers().array_;
            const [removedItem] = array.splice(startIndex, 1);
            const [removedLayer] = layersArray.splice(startIndex, 1);
            removedLayer && layersArray.splice(endIndex, 0, removedLayer);
            removedItem && array.splice(endIndex, 0, removedItem);
            let zI = 10;
            layersArray.forEach((lyr) => {
                const id = lyr.values_.attributes.id;
                const entityLayer = this.entityLayers[id];
                const relatedLyer = entityLayer && entityLayer.relatedEntities[0];
                relatedLyer && relatedLyer.getLayer().setZIndex(zI);
                zI++;
            });
            this.getMap().render();
        }
    }

    @bind
    setMap(targetId: string, controls, title) {
        this.setView();
        this.map = new Map({
            controls: defaultControls({ attribution: false }).extend(controls),
            target: targetId,
            view: this.view
        });
        const allBaseLayers = [
            this.getBingLayer(2, 'Bing (Aerial)', false),
            this.getBingLayer(0, 'Bing (Roads)', false),
            this.getOSMLayer(false)
        ];
        const olBaseLayers = new LayerGroup({
            title: 'baseLayers',
            layers: allBaseLayers
        });

        const olLayers = new LayerGroup({
            title: 'allLayers',
            layers: []
        });

        const olRelatedLayers = new LayerGroup({
            title: 'allRelatedLayers',
            layers: []
        });

        for (const lyr of allBaseLayers) {
            const baseLayer = get(lyr, 'values_.title');
            if (title === baseLayer || title === 'locationMap') {
                lyr.setVisible(true);
                break;
            }
        }
        this.map.addLayer(olBaseLayers);
        this.map.addLayer(olLayers);
        this.map.addLayer(olRelatedLayers);
    }

    @bind
    getMap() {
        return this.map;
    }

    @bind
    setView() {
        this.view = new View({
            projection: 'EPSG:3857',
            center: fromLonLat([0, 0]),
            zoom: 4,
            maxZoom: 21,
            minZoom: 2
        });
    }

    @bind
    getView() {
        return this.view;
    }

    @bind
    addLayer(layer: Object) {
        this.map.addLayer(layer);
    }

    @bind
    async getLayerType(info: Object) {
        const { styles } = info || {};
        const style = styles?.pinStyle;

        switch ((style || '').toLowerCase()) {
            case 'cluster':
                return new ClusterLayer(info, this);
            case 'related':
                return new RelatedEntityLayer(info.parentLayer, info.entities, info, this);
            case 'heatmap':
                return new HeatMapLayer(info, this, info.parentLayer);    
            case 'drawing':
                const drawingLayer = await new DrawingLayer(info,this);
                return drawingLayer;
            case cesiumLayerConfig?.cesiumLayer?.type: case cesiumLayerConfig?.cesiumLayer?.oldType:
                return new ThreeDLayer(info, this);
            case 'replay':
                const replayLayer  = await new ReplayLayer(info, this);
                return replayLayer;
            default:
                return new EntityLayer(info, this);
        }
    }

    @bind
    async updateEntity(id: string, attributes: Object) {

        //    console.log('OL MAP UPDATEENTITY', id, attributes);
        const { layerStyling, type, showCounters, primary } = attributes || {};
        const { heatmap, related_entities, styles } = primary || {};
        const { pinStyle } =  styles;
        let entityLayer = this.entityLayers[id];
        const mapLayer = this.findLayerById(id);
        mapLayer?.getSource().clear();
        await this.refreshLayer(mapLayer);
        this.removeMapLayer(mapLayer, entityLayer);
        await entityLayer?.updateInfo(primary);
        this.entityLayers[id] = await this.getLayerType(primary);
        entityLayer = this.entityLayers[id];
        const { dataVisualisation } = heatmap || {};
        this.removeHeatmapLayer(id);
        this.removeRelatedmapLayer(id);

        if(dataVisualisation) {         
            entityLayer?.setHeatMapLayer(entityLayer?.info, null, false);

            if(pinStyle !== 'hideEntities') await entityLayer?.setRelatedEntities(primary);
        } 

        if(entityLayer instanceof ClusterLayer && layerStyling?.pinStyle === 'cluster'){
            await entityLayer?.updateClusterSource(layerStyling['distance']);
        }

        if(type !== 'system_eventlayer'){
            if (related_entities && pinStyle !== 'hideEntities' && (related_entities?.relatedEntities || showCounters)) {
                await entityLayer?.setRelatedEntities(primary);
            }
        }

        this.getMap().render();
    }

    @bind
    removeHeatmapLayer(id) {
        const findHeatmapLayer = this.map?.getLayers()?.array_?.find(lyr => {
            return lyr instanceof Heatmap && lyr?.values_?.attributes?.id?.split('-heatmap')?.[0] === id;
        });     
        findHeatmapLayer && this.map?.removeLayer(findHeatmapLayer);
    }

    @bind
    removeRelatedmapLayer(id) {
        this.map?.getLayers()?.array_?.find(lyr => {
            if(lyr?.values_?.attributes?.id?.split('-r0')?.[0] === id) {
                this.map?.removeLayer(lyr);
            }
        });     
    }

    @bind
    async addEntityLayer(info) {
        const entityLayer: EntityLayer = await this.getLayerType(info);
        this.entityLayers[entityLayer.layerId || entityLayer?.info?.id] = entityLayer;
        const { heatmap, related_entities } = info;
        const { dataVisualisation } = heatmap || {};
        const { relatedEntities, showCounters } =  related_entities || {};

        if (dataVisualisation && !this.isHeatMap.includes(entityLayer?.info?.id)) {
            entityLayer?.relatedEntities.length && entityLayer.relatedEntities?.forEach(lyr => this.getMap()?.removeLayer(lyr));
            entityLayer.setHeatMapLayer(info);
            this.isHeatMap.push(entityLayer?.info?.id);
            
            if(relatedEntities) {
                this.isRelatedMap.push(entityLayer?.info?.id); // to set relatedEntities when selecting dataVisualisation checkbox
                entityLayer.setRelatedEntities(info); // needed for the first render when relatedEntities and dataVisualisation enabled
            }
        }

        if ((relatedEntities || showCounters ) && !this.isRelatedMap.includes(entityLayer?.info?.id) && typeof (entityLayer.setRelatedEntities) === 'function') {
            entityLayer.setRelatedEntities(info); 
            this.isRelatedMap.push(entityLayer?.info?.id);
        }

        return entityLayer.layerId;
    }

    @bind
    getEntityLayer(id) {
        return this.entityLayers[id];
    }

    @bind
    @memoize()
    getOverlayLayer(key: number) {
        return this.overlayLayers[key];
    }

    /*******************************
     * Base Layer Sections
     */
    @bind
    @memoize()
    getBingLayer(imagerySetID: number = 2, imageryTitle: string = 'Bing', isVisible: boolean = true) {
        const imagerySet = ['RoadOnDemand', 'Aerial', 'AerialWithLabelsOnDemand', 'CanvasDark', 'OrdnanceSurvey'];
        return new TileLayer({
            title: imageryTitle,
            baseLayer: true,
            visible: isVisible,
            source: new Bing({
                key: 'AlLccSQ-txfa4gfzC0XxrNaFanQ_jpD0toWcG-VnLEEwF5M3_mCmg_TVrPADz_pe',
                imagerySet: imagerySet[imagerySetID]
            })
        });
    }

    @bind  
    removeMapLayer(layer, entityLayer) {
        const src = layer?.getSource();
        src && src.clear();
        this.getMap().removeLayer(layer); 
        // if(entityLayer && entityLayer instanceof HeatMapLayer) {
        //     this.map.removeLayer(entityLayer.getLayer());
        // }
        // entityLayer?.heatMapLayers?.length && entityLayer.heatMapLayers.forEach(lyr => this.getMap().removeLayer(lyr));

    }

    @bind
    getOSMLayer(isVisible: boolean) {
        return new TileLayer({
            title: 'OSM',
            preload: 4,
            baseLayer: true,
            visible: isVisible,
            source: new OSM()
        });
    }

    @bind
    removeIamHereOverlay() {
        if (this.IamHereLayer) {
            this.IamHereLayer.getSource().clear();
            this.map.removeLayer(this.IamHereLayer);
            this.IamHereLayer = null;
        }
    }

    cesiumBaseLayers = {
        'Bing (Aerial)': 1,
        'Bing (Roads)': 2,
        OSM: 4
    };

    @bind
    setCesiumBaseLayer(selectedIdx: number) {
        (get(this._ol3d, 'globe_._imageryLayerCollection._layers') || []).forEach((lyr, idx) => {
            if (idx > 0 && idx !== selectedIdx) {
                lyr.show = 0;
                return;
            }
            lyr.show = 1;
        });
    }

    @bind
    setOLCesium(showToastr) {
        try {
            this.removeHightLightFeatures();
            this._ol3d = new OLCesium({ map: this.map, sceneOptions: {
                mapProjection: new Cesium.WebMercatorProjection(),
            } });
            const selectedBaseLayer = this.getLayersGroup('baseLayers')
                .getLayers()
                .array_.find(l => l.values_.visible);
            this.setCesiumBaseLayer(this.cesiumBaseLayers[selectedBaseLayer.values_.title]);
        } catch (e) {
            showToastr({ severity: 'error', detail:'Something went wrong. Map is refreshing.'});
            setTimeout(() => {
                window.location.reload(true);
            }, 3000);
            // eslint-disable-next-line no-console
            console.error(e);
        }
    }

    @bind
    checkWebglSupport() {
        try {
            var canvas = document.createElement('canvas');
            return !!window.WebGLRenderingContext && (canvas.getContext('webgl') || canvas.getContext('experimental-webgl'));
        } catch (e) {
            return false;
        }
    }

    @bind
    toggleOLCesium(showToastr) {
        this.enable3D = !this.enable3D;
        if (!this._ol3d && this.enable3D) {
            /**
             * Run OLCesium if selected
             * @fix Cesium crashes because of the right-hand rule for MutliPolygon (Open layers works fine).
             */
            
            this.setOLCesium(showToastr);
        }
        if (this.enable3D) {
            const isWebGL = this.checkWebglSupport();
            if (isWebGL) {

                for (const id in this.entityLayers) {
                    const entityLayer = this.entityLayers[id];
                    if(typeof entityLayer.toggleTo3DStyling === 'function') {
                        entityLayer.toggleTo3DStyling(Cesium, this._ol3d, this.enable3D, this);
                    }
                }
                this._ol3d && this._ol3d.setEnabled(true);

            } else {
                this.enable3D = false;
            }
        } else {
            for (const id in this.entityLayers) {
                const entityLayer = this.entityLayers[id];
                if(typeof entityLayer.toggleTo3DStyling === 'function')
                    entityLayer.toggleTo3DStyling(Cesium, this._ol3d, this.enable3D, this);
            }
            this._ol3d && this._ol3d.setEnabled(false);
        }        
    };


    @bind
    convertGeoJsonToFeature(geoJsonFeatures: Object) {
        const geoJson = new GeoJSON();
        return geoJson.readFeatures(geoJsonFeatures);
    }

    @bind
    convertFeatureToGeoJson(feature: Object) {
        const geoJson = new GeoJSON();
        return geoJson.writeFeature(feature);
    }

    @bind
    convertFeaturesToGeoJson(features: Array<Object>) {
        const geoJson = new GeoJSON();
        return geoJson.writeFeatures(features);
    }

    @bind
    convertFeaturesToGeoJsonFeatures(features: Array<Object>) {
        return  this.convertFeaturesToGeoJson(features);
    }


    @bind
    getLayerByTitle(layerName) {
        const layers = this.getMap().getLayers && this.getMap().getLayers();
        return get(layers, 'array_', []).find(VectorLayer => VectorLayer.values_.title === layerName);
    }

    @bind
    takeScreenShoot(width = -1, height = -1) {
        const map = this.getMap();
        const convertMapToImage = this.convertMapToImage;
        const resizeImage = this.resizeBase64Img;
        const downloadImage = (mapCanvas) => {
            if (navigator.msSaveBlob) {
                navigator.msSaveBlob(mapCanvas.msToBlob(), 'map.png');
            } else {
                const link = document.createElement('a');
                document.body.appendChild(link);
                link.href = mapCanvas.toDataURL();
                link.download = 'map.png';
                link.click();
                document.body.removeChild(link);
            }
        };
        map.once('rendercomplete', () => {
            const mapCanvas = document.createElement('canvas');
            const size = map.getSize();
            mapCanvas.width = size[0] > 700 ? 700 : size[0];
            mapCanvas.height = size[0] > 700 ? (700 * size[1]) / size[0] : size[1];
            convertMapToImage(mapCanvas);
            if (width > 0 && height > 0) {
                resizeImage(mapCanvas.toDataURL(), width, height).then((canvas) => {
                    downloadImage(canvas);
                });
            } else {
                downloadImage(mapCanvas);
            }
        });
        map.renderSync();
    }

    @bind
    async convertMapToImage(mapCanvas) {
        const mapContext = mapCanvas.getContext('2d');
        const olCanvasArr = document.querySelectorAll('.ol-layer canvas');
        const canvasPromises = Array.from(olCanvasArr).map((canvas) => {
            if (canvas.width > 0) {
                const opacity = canvas.parentNode.style.opacity;
                mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
                const transform = canvas.style.transform;
                // Get the transform parameters from the style's transform matrix
                const matrix = transform
                    .match(/^matrix\(([^(]*)\)$/)[1]
                    .split(',')
                    .map(Number);
                // Apply the transform to the export map context
                CanvasRenderingContext2D.prototype.setTransform.apply(mapContext, matrix);
                return mapContext.drawImage(canvas, 0, 0, mapCanvas.width, mapCanvas.height);
            }
            return Promise.resolve();
        });
        await Promise.all(canvasPromises);
    }

    @bind
    resizeBase64Img(base64, newWidth, newHeight = null) {
        return new Promise((resolve, reject) => {
            const img = document.createElement('img');
            img.src = base64;
            img.onload = () => {
                const scaleX = parseFloat(newWidth) / parseFloat(img.width);
                const scaleY = parseFloat(newHeight) / parseFloat(img.height);
                const scale = scaleX >= scaleY ? scaleX : scaleY;
                const calcWidth = parseInt(img.width * scale);
                const calcHeight = parseInt(img.height * scale);
                const canvas = document.createElement('canvas');
                canvas.width = calcWidth;
                canvas.height = calcHeight;
                const context = canvas.getContext('2d');
                context.drawImage(img, 0, 0, calcWidth, calcHeight);
                resolve(canvas);
            };
        });
    }

    @bind
    zoomToSavedMapExtent(extent) {
        if (extent && extent.length) {
            this.getMap()
                .getView()
                .fit(extent);
        }
    }

    @bind
    async getMapView() {
        const map = this.getMap();
        const mapCanvas = document.createElement('canvas');
        mapCanvas.width = 200;
        mapCanvas.height = 200;
        await this.convertMapToImage(mapCanvas);
        const extent = map.getView().calculateExtent(map.getSize());
        return { extent: extent, thumbnail: mapCanvas.toDataURL('image/jpeg') };
    }

    @bind
    removePinFromAboutMap() {
        const aboutMapLayer = this.getMap()
            .getLayers()
            .array_.find(layer => layer.values_.title === 'About Map layer');
        aboutMapLayer && this.getMap().removeLayer(aboutMapLayer);
    }

    @bind
    toggleMovePins(value: boolean, callback: func) {
        if (value) {
            const aboutMapLayer = this.getMap()
                .getLayers()
                .array_.find(layer => layer.values_.title === 'About Map layer');
            if (aboutMapLayer) {
                const source = aboutMapLayer.getSource();
                const features = source.getFeatures();
                const addedFeature = features[0];

                addedFeature.on('change', () => callback(addedFeature.getGeometry().getCoordinates()), addedFeature);
                this.dragDropFeatures = new Modify({
                    features: new Collection(features)
                });
                this.map.addInteraction(this.dragDropFeatures);
            }
            return;
        }

        this.dragDropFeatures && this.map.removeInteraction(this.dragDropFeatures);
    }

    @bind
    makeFeature(props, long, lat) {
        const { iconName, iconColor, svg, svgGraphic, svgTransformation } = props || {};
        const aboutPin = new Feature({
            geometry: new Point(fromLonLat([long, lat]))
        });
        aboutPin.set('attributes', { iconName, iconColor, svg, svgGraphic, svgTransformation });
        return aboutPin;
    }

    @bind
    addPinsToAboutMap(props: Object) {
        const { latitude, longitude, _geoJson, sync_with_position, iconName, iconColor, svg, svgGraphic, svgTransformation } = props || {};

        let aboutPin = null;
        const geoJson = new GeoJSON({ featureProjection: 'EPSG:3857' });
        if(_geoJson && (!isDefined(latitude) || !isDefined(longitude))) {
            if(sync_with_position) {
                const aboutPinCenter = this.getCentroidofGeom(_geoJson);
                aboutPin = this.makeFeature(props, aboutPinCenter?.geometry?.coordinates?.[0], aboutPinCenter?.geometry?.coordinates?.[1]);
            }
            else {
                aboutPin = geoJson.readFeatures(_geoJson)[0];
                aboutPin && aboutPin.set('attributes', { iconName, iconColor, svg, svgGraphic, svgTransformation });
            }
        }
        else {
            aboutPin = this.makeFeature(props, longitude, latitude);
        }


        const mapLayers = this.getMap() && this.getMap().getLayers();
        if (mapLayers) {
            let aboutMapLayer = this.getMap()
                .getLayers()
                .array_.find(layer => layer.values_.title === 'About Map layer');

            if (aboutMapLayer) {
                this.getMap().removeLayer(aboutMapLayer);
            }
            const vectorSource = new VectorSource({
                features: [aboutPin]
            });

            aboutMapLayer = new VectorLayer({
                source: vectorSource,
                title: 'About Map layer',
                style: this.createDefaultStyle(aboutPin, true)
            });
            this.getMap().addLayer(aboutMapLayer);
            this.zoomToMapLayer(aboutMapLayer,14);
            this.getMap().render();
        }
    }

    @bind
    addHistoryFeaturesToMap(historyFeatures) {
        const aboutMapLayer = this.getMap()
            .getLayers()
            .array_.find(layer => layer.values_.title === 'About Map layer');
        aboutMapLayer.getSource().clear();
        historyFeatures.forEach((feature) => {

            const { date } = feature.get('attributes') || {};
            const newStyle = new Style({
                image: new CircleStyle({
                    radius: 5,
                    fill: new Fill({ color: '#3D64B7' }),
                    stroke: new Stroke({
                        color: 'rgba(255, 255, 255, 0.7)',
                        width: 1
                    })
                }),
                text: new Text({
                    text: date,
                    backgroundFill: new Fill({ color: 'rgba(0, 16, 51, 0.67)' }),
                    fill: new Fill({ color: 'white' }),
                    offsetX: -20,
                    offsetY: 20
                })
            });
            feature.setStyle(newStyle);
            aboutMapLayer.getSource().addFeature(feature);
        });

        for (let i = 0; i < historyFeatures.length; i++) {
            const startingCoords = historyFeatures[i].getGeometry().getCoordinates();
            const    endingCoords = historyFeatures[i + 1].getGeometry().getCoordinates();
            if(endingCoords.length && endingCoords.length) {
                const points = [
                    [startingCoords[0], startingCoords[1]],
                    [endingCoords[0], endingCoords[1]]
                ];
                const lineFeature = new Feature({
                    geometry: new LineString(points)
                });
                const lineStyle = new Style({
                    stroke: new Stroke({ color: 'blue', width: 3 })
                });
                lineFeature.setStyle(lineStyle);
                aboutMapLayer.getSource().addFeature(lineFeature);

            }
        }

        this.getMap().render();
    }

    @bind
    createDefaultStyle(feature, isProgress: boolean = false) {
        return singleFeatureStyle(feature, this.map, isProgress);
    }

    @bind
    getLayerFeatures(layer) {
        const features = layer?.getSource() && layer.getSource().getFeatures();
        if(features?.length)
            return features;

    }

    @bind
    removeLayerSelection() {
        const map = this.getMap();
        if(this.select) {
            map.removeInteraction(this.select);
        }
    }

    @bind
    clearRemovedFeatures() {
        return this.featuresRemoved = [];
    }

    @bind
    setEraserStatus(status: boolean) {
        this.eraserStatus = status;
    }

    @bind
    getEraserStatus() {
        return this.eraserStatus;
    }

    @bind
    removeDrawingFeature(layerId: string, drawingLayer, savedDrawingLayer, addFeaturesToHistory) {
        const map = this.getMap();
        this.select = new Select({
            condition: (mapBrowserEvent) => {
                return click(mapBrowserEvent);
            },
            layers: [savedDrawingLayer, drawingLayer],
            hitTolerance: 50,
        });
        if (this.select !== null) {
            map.addInteraction(this.select);
            this.select.on('select', async (evt) => {
                const features = evt.selected;
                const deselectedFeatures = evt.deselected;
                if(features.length) {
                    features.forEach((f) => {
                        const currentFeatures = this.getLayerFeatures(drawingLayer);
                        try {
                            currentFeatures && drawingLayer.getSource().removeFeature(f);
                            const convertoGeoJson = JSON.parse(this.convertFeatureToGeoJson(f));
                            convertoGeoJson && addFeaturesToHistory(convertoGeoJson);
                        } catch(e){}
                    });
                    features.forEach((f) => {
                        const savedFeatures = this.getLayerFeatures(savedDrawingLayer);
                        try {
                            savedFeatures && savedDrawingLayer.getSource().removeFeature(f);
                        } catch(e){}
                    });
                    deselectedFeatures.forEach((feature) => {
                        if (feature.getGeometry() instanceof LineString) {
                            feature.setStyle(null);
                        }
                    });
                    this.map.render();   
                }
            });
        }
    }

    @bind
    refReplacer() {
        const m = new Map(),
            v = new Map();
        let init = null;

        return (field, value) => {
            const p = m.get(this) + (Array.isArray(this) ? `[${field}]` : '.' + field);
            const isComplex = value === Object(value);

            if (isComplex) m.set(value, p);

            const pp = v.get(value) || '';
            const path = p.replace(/undefined\.\.?/, '');
            let val = pp ? `#REF:${pp[0] === '[' ? '$' : '$.'}${pp}` : value;

            // eslint-disable-next-line no-unused-expressions
            !init ? (init = value) : val === init ? (val = '#REF:$') : 0;
            if (!pp && isComplex) v.set(value, path);

            return val;
        };
    }

    @bind
    addPinToClassAboutMap(props: Object) {
        const { iconColor, latitude, longitude, svg, svgTransformation } = props || {};
        const aboutPin = new Feature({
            geometry: new Point(fromLonLat([longitude, latitude]))
        });

        const classAbout = true;
        aboutPin.set('attributes', { iconName: null, iconColor, svg, svgTransformation, classAbout });

        const mapLayers = this.getMap() && this.getMap().getLayers();
        if (mapLayers) {
            let aboutMapLayer = this.getMap()
                .getLayers()
                .array_.find(layer => layer.values_.title === 'Class About Map layer');

            if (aboutMapLayer) {
                this.getMap().removeLayer(aboutMapLayer);
            }
            const vectorSource = new VectorSource({
                features: [aboutPin]
            });

            aboutMapLayer = new VectorLayer({
                source: vectorSource,
                title: 'Class About Map layer',
                style: this.createDefaultStyle(aboutPin, true)
            });
            this.getMap().addLayer(aboutMapLayer);
            this.getMap().render();
        }
    }

    @bind
    getCentroidofGeom(_geoJson: Object) {
        return turf.centroid(_geoJson);
    }

    @bind
    removeHightLightFeatures() {
        const highLightLayer = this.findHighLightLayer();
        highLightLayer && highLightLayer.getSource().clear();
    }

    @bind
    resetLayerStyle(layer: Object) {
        if(layer?.getSource()) {
            if (layer.getSource() instanceof TileLayer || layer.getSource() instanceof TileWMS) {
                return null;
            }
            const layerFeatures = layer.getSource().getFeatures();
            if (layerFeatures && layerFeatures.length) {
                layerFeatures.forEach((feature) => {
                    feature.setStyle(entityPinStyle(feature, this.map));
                });
            }
            this.map.render();
        }

    }

    @bind
    checkLayerVisibility(layer: Object) {
        return layer?.getVisible();
    }

    @bind
    waitForLayer(ms: number) {
        return new Promise(resp => setTimeout(resp, ms));
    }

    @bind
    addHighlightLayer() {
        const highlightSource = new VectorSource();
        const highlightLayer = new VectorLayer({
            source: highlightSource,
            title: 'Highlight layer',
            attributes: {
                id: '9b1caeb6-243f-11ed-8f33-0242ac130009'
            },
            name: '9b1caeb6-243f-11ed-8f33-0242ac130009',
            style: new Style({
                image: new CircleStyle({
                    radius: 20,
                    stroke:  new Stroke({ color: 'rgba(0, 81, 255, 0.3)', width: 25 }),
                }),
            })
        });
        highlightLayer.setZIndex(0);
        this.getMap().addLayer(highlightLayer);
    }

    @bind
    checkSelectedLayer() {
        const layersGroup = this.getMap()
            .getLayers()
            .array_.find(layer => layer.values_.title === 'allLayers');
        // eslint-disable-next-line array-callback-return
        return layersGroup?.getLayers()?.array_?.filter((lyr) => {
            const attributes = get(lyr, 'values_');
            const selectedLayer = attributes?.selectedLayer;
            if (selectedLayer) {
                return lyr;
            }
        });
    }

    @bind
    getAssets(token: string) {
        const cesiumHeader = new Headers();
        cesiumHeader.append('Authorization', token);

        const requestOptions = {
            method: 'GET',
            headers: cesiumHeader,
            redirect: 'follow'
        };

        return fetch('https://api.cesium.com/v1/assets', requestOptions)
            .then(response => response.text())
            .then((result) => {
                const parsedResult = JSON.parse(result);
                const { items } = parsedResult;
                if(items)
                    return items;
            })
            // eslint-disable-next-line no-console
            .catch(error => console.log('error', error));
    }

    @bind
    getOl3d() {
        return this._ol3d;
    }

    @bind
    get3DVisibility() {
        return this.enable3D;
    }

    @bind
    getEntityLayers() {
        return this.entityLayers;
    }

    @bind
    getCesiumObj() {
        return Cesium;
    }

    @bind
    is3dEnabled() {
        return this.enable3D;
    }

    @bind
    async getAddressLocation(lat, long) {
        const response = await Geocode.fromLatLong(lat, long);
        if(response) {
            const gAddress = get(response, 'data.result.address') || [];
            const address = Geocode.getAddress(gAddress);
            return {longitude: long, latitude: lat, 'address': {add_type: 'Physical', ...address}};
        } else {
            // eslint-disable-next-line no-console
            console.log('Could not find address for this location $$', response);
            return { longitude: long, latitude: lat };
        }

    }

    @bind
    getSelectedBaseLayer() {
        const allBaseLayers = this.getLayersGroup('baseLayers');
        return allBaseLayers && allBaseLayers.getLayers()?.array_?.find(({ values_ }) => values_.visible === true);
    }

    @bind
    updateBillboardStyle() {
        const zoomLevel = this.map.getView().getZoom();
        const dataSourceCollection =  this._ol3d?.getDataSources();
        this.findGeoJsonDataSource(dataSourceCollection, zoomLevel);
    }

    @bind
    findGeoJsonDataSource(dataSourceCollection, zoomLevel) {
        dataSourceCollection._dataSources.forEach(dataSource => {
            if (dataSource instanceof Cesium.GeoJsonDataSource) {
                const layerId = dataSource?._name;
                const mapLayer = layerId && this.findLayerById(layerId);
                const twoDOpacity = mapLayer?.getOpacity();
                this.updateStyleBasedOnZoom(dataSource, zoomLevel, twoDOpacity);

            }
        });
    }

    @bind
    updateStyleBasedOnZoom(dataSource, zoomLevel, twoDOpacity) {
        dataSource.entities.values.forEach( async (entity) => {
            const attributes = entity._properties?._attributes?._value;
            const { id, type, model3d, modelHeading, primaryClass, modelScaling } = attributes;
            const entityModel3d = primaryClass?.entityModel3d || null;
            const threeDModel = model3d || entityModel3d;
            const heading = Cesium.Math.toRadians(modelHeading || 135);
            const pitch = 0;
            const roll = 0;
            if (threeDModel && zoomLevel >= 10) {
                const hpr = new Cesium.HeadingPitchRoll(heading, pitch, roll);
                entity.orientation = Cesium.Transforms.headingPitchRollQuaternion(
                    entity.position._value,
                    hpr
                );
                entity.billboard = undefined; // Remove the existing billboard style
                const imageUrl = getAttachmentUrl(id, type, model3d, true);
                const host = rocketHost.split('/chat')[0];
                const url = `https://${host}` +  imageUrl.split('?')[0];
                const modelColor = Cesium.Color.fromCssColorString(attributes?.iconColor);
                // const url = `http://localhost:3000/${imageUrl.split('?')[0]}`;
                const response = await fetch(url);
                const result = await response.text();
                  
                if(result) {
                    entity.model = new Cesium.ModelGraphics({
                        uri: url,
                        color: modelColor,
                        colorBlendMode: Cesium.ColorBlendMode.MIX,
                        colorBlendAmount: 0.5,
                        minimumPixelSize: 128,
                        maximumScale: 20000,
                        scale: (modelScaling && modelScaling * 3) || 1
                    });
                    entity.billboard.image = null;
                }
            } else {
                entity.model = undefined; 
                const { iconColor, iconName } = attributes;
                const icon = iconName || 'map-marker';
                const name = mdiMapping?.[icon] || customIconSet?.[icon];
                const text = String.fromCodePoint(name);
                entity.billboard = new Cesium.BillboardGraphics({
                    image: create3DIconCanvas(text, {
                        strokeColor: '#000000',
                        fontSize: '20',
                        fontFamily: 'Material Design Icons',
                        fillColor: iconColor || '#00BCD4',
                        strokeColor: '#000000',
                        strokeWidth: 0.5,
                        twoAlpha: twoDOpacity
                    }),
                    verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                });
                entity.billboard.color = new Cesium.Color(1.0,1.0,1.0, twoDOpacity === 100 ? 1 : twoDOpacity);

            }
        });
    }

    @bind 
    async refresh3DMapLayers() {
        for (const id in this.entityLayers) {
            const entityLayer = this.entityLayers[id];
            await entityLayer.refresh3DMapLayers(this.getCesiumObj(), this.getOl3d(), this.enable3D);
        }
       
    }
    
}

export default olMap;
