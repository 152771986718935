// @flow
import { get } from 'app/utils/lo/lo';
import { graphql } from 'graphql/client';

import processesDefinitionsTypeaheadQuery from 'graphql/abox/processesDefinitionsTypeaheadQuery';
import processesDefinitionsQuery from 'graphql/abox/processesDefinitionsQuery';
import processDefinitionMutation from 'graphql/abox/processDefinitionMutation';
import { _fetchPreferences, _savePreferences } from 'store/actions/admin/usersActions';
import Immutable, { set } from 'app/utils/immutable/Immutable';
import { loadData, mutateData } from 'app/utils/redux/action-utils';
import OptionsBuilder from 'app/utils/api/OptionsBuilder';
import { isEmpty } from 'app/utils/utils';


export const LOAD_APPS_STARTED: string = '@@affectli/abox/myapps/LOAD_APPS_STARTED';
export const LOAD_APPS: string = '@@affectli/abox/myapps/LOAD_APPS';

export const LOAD_APPS_GROUPS_STARTED: string = '@@affectli/abox/myapps/LOAD_APPS_GROUPS_STARTED';
export const LOAD_APPS_GROUPS: string = '@@affectli/abox/myapps/LOAD_APPS_GROUPS';

export const SAVE_APPS_GROUPS_STARTED: string = '@@affectli/abox/myapps/SAVE_APPS_GROUPS_STARTED';
export const SAVE_APPS_GROUPS: string = '@@affectli/abox/myapps/SAVE_APPS_GROUPS';

export const LOAD_APPS_LAUNCHER_STARTED: string = '@@affectli/abox/myapps/LOAD_APPS_LAUNCHER_STARTED';
export const LOAD_APPS_LAUNCHER: string = '@@affectli/abox/myapps/LOAD_APPS_LAUNCHER';

export const LOAD_ABOX_PROCESS_DEFINITION_STARTED: string = '@@affectli/abox/myapps/LOAD_ABOX_PROCESS_DEFINITION_STARTED';
export const LOAD_ABOX_PROCESS_DEFINITION: string = '@@affectli/abox/myapps/LOAD_ABOX_PROCESS_DEFINITION';

export const LOAD_TYPEAHEAD_PROCESS_DEFINITIONS_STARTED: string = '@@affectli/abox/myapps/LOAD_TYPEAHEAD_PROCESS_DEFINITIONS_STARTED';
export const LOAD_TYPEAHEAD_PROCESS_DEFINITIONS: string = '@@affectli/abox/myapps/LOAD_TYPEAHEAD_PROCESS_DEFINITIONS';

/**
 * Load the Abox App List
 */
export const loadAboxMyApps = (options: Object) => (dispatch: Function, getState: Function) => {
    dispatch({ type: LOAD_APPS_STARTED });
    const variables = new OptionsBuilder(options).defaultStartStopIndexs(0, 200)
        .filter({ field: 'primary.lastVersion', op: 'is not null' })
        .filter({ field: 'active', op: '=', value: true })
        .filter({ or: [
            { field: 'primary.hidden', op: 'is null' },
            { field: 'primary.hidden', op: '=', value: 'false' }
        ]})
        .build();
    graphql.query({
        query: processesDefinitionsQuery,
        variables,
        fetchPolicy: 'no-cache'
    }).then((response: Object): void => {
        dispatch({ type: LOAD_APPS, payload: get(response, 'data.records') || [] });
    }).catch((error) => {
        dispatch({ type: LOAD_APPS, payload: error, error: true });
    });
};

export const loadTypeaheadProcessDefinitions =
    () => loadData(LOAD_TYPEAHEAD_PROCESS_DEFINITIONS_STARTED, LOAD_TYPEAHEAD_PROCESS_DEFINITIONS, processesDefinitionsTypeaheadQuery)({});

/**
 * Load the Abox App List
 */
export const saveAboxMyAppsGroups = (groupedApps: Object) => async (dispatch: Function, getState: Function) => {
    dispatch({ type: SAVE_APPS_GROUPS_STARTED });
    try {
        let preferences = await _fetchPreferences();
        const groups = groupedApps.children.reduce((groups, group, i) => {
            if (!isEmpty(group.children)) {
                const groupId = i + 1;
                const children = group.children.map(app => app.id);
                const name = group.name || 'No Name';
                groups[groupId] = { groupId, name, children };
            }
            return groups;
        }, {});

        preferences = set(preferences, 'myApp.groups', groups);
        await _savePreferences(preferences);
        dispatch({
            type: SAVE_APPS_GROUPS,
            payload: Immutable(preferences),
            meta: Immutable({ successMessage: `Changes saved successfully.` }),
        });
    } catch (error) {
        dispatch({
            type: SAVE_APPS_GROUPS,
            error: true,
            payload: Immutable(error),
            meta: Immutable({ errorMessage: 'An error occurred saving.' }),
        });
    }
};

/**
  * Load the user preferences
  */
export const loadAboxMyAppsGroups = () => (dispatch: Function, getState: Function) => {
    dispatch({ type: LOAD_APPS_GROUPS_STARTED });
    _fetchPreferences().then((data) => {
        dispatch({ type: LOAD_APPS_GROUPS, payload: Immutable(data) });
    }).catch((error) => {
        dispatch({ type: LOAD_APPS_GROUPS, error: true, payload: Immutable(error) });
    });
};

/**
 * Load the Abox App List
 */
export const loadProcessDefinition = (id: string) =>
    mutateData(LOAD_ABOX_PROCESS_DEFINITION_STARTED, LOAD_ABOX_PROCESS_DEFINITION, processDefinitionMutation)({ id });


/**
 * Load the App List for the App Launcher
 */
export const loadAppsLauncher = (options: Object) => (dispatch: Function, getState: Function) => {
    dispatch({ type: LOAD_APPS_LAUNCHER_STARTED });
    const variables = new OptionsBuilder(options).defaultStartStopIndexs(0, 12)
        .filter({ field: 'primary.lastVersion', op: 'is not null' })
        .filter({ field: 'active', op: '=', value: true })
        .filter({ or: [
            { field: 'primary.hidden', op: 'is null' },
            { field: 'primary.hidden', op: '=', value: 'false' }
        ]})
        .build();
    graphql.query({
        query: processesDefinitionsQuery,
        variables,
        fetchPolicy: 'no-cache'
    }).then((response: Object): void => {
        dispatch({ type: LOAD_APPS_LAUNCHER, payload: get(response, 'data.records') || [] });
    }).catch((error) => {
        dispatch({ type: LOAD_APPS_LAUNCHER, payload: error, error: true });
    });
};
