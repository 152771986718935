/* @flow */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { clearResourcesList } from 'store/actions/admin/workspacesActions';
import { bind } from 'app/utils/decorators/decoratorUtils';
import Immutable from 'app/utils/immutable/Immutable';
import AddResourcesModal from 'app/containers/Common/AddResourcesModal';
import AddEventsByEventTypeModal from 'app/containers/Common/AddEventsByEventTypeModal';
import { createEventToEventRelationship } from 'store/actions/entities/relationshipsActions';
import uuidv1 from 'uuid/v1';


class AddEventsRelationsModal extends PureComponent<Object, Object> {
    static propTypes = {};

    state = Immutable({
        modalType: 'relationDefinition',
        key: uuidv1(),
        relationDefinition: null,
    });

    defaultState: Object = { ...this.state }; // preserve the initial state in a new object.

    @bind
    async onSubmit() {
        const { selectedItem, selectedItems, isBulkSelect } = this.state;
        const selectedValue = !isBulkSelect ? selectedItem : selectedItems;
        await this.props.onSubmit(selectedValue);
        this.closeModal();
    }

    @bind
    closeModal() {
        this.props.clearResourcesList();
        this.setState(this.defaultState, this.props.closeModal);
    }

    @bind
    async backToEventTypes() {
        const { clearResourcesList, loadResourceList } = this.props;
        await clearResourcesList();
        this.setState(this.defaultState, async () => {
            const options = { filterBy: [], orderBy: [] };
            await loadResourceList(options, 'eventtype');
        });
    }

    // @bind
    // async onClickNext() {
    //     // const { isBulkSelect } = this.props;
    //     // const { selectedItem } = this.state;
    //     // const filterBy = [{ field: 'eventType.id', op: '=', value: selectedItem.id }];
    //     // const orderBy = [{ field: 'time', direction: 'desc nulls last' }];
    //     // this.setState({
    //     //     ...this.defaultState,
    //     //     filterBy,
    //     //     orderBy,
    //     //     isBulkSelect: !!isBulkSelect,
    //     //     type: 'event'
    //     // });
    //     let { modalType } = this.state;
    //     if (modalType === 'primaryClass') {
    //         modalType = 'relationDefinition';
    //     } else if (modalType === 'relationDefinition') {
    //         modalType = 'eventtype';
    //     }
    //     this.setState({ modalType });
    // }

    @bind
    async openPrimaryClassModal() {
        await this.props.clearResourcesList();
        this.setState(prevState => ({ modalType: 'primaryClass', key: uuidv1() }));
    }
    @bind
    async openRelationDefModal() {
        await this.props.clearResourcesList();
        this.setState(prevState => ({ modalType: 'relationDefinition', key: uuidv1() }));

    }
    @bind
    async openEventTypesModal(relationDefinition) {
        await this.props.clearResourcesList();
        this.setState(prevState => ({ modalType: 'eventtype', key: uuidv1(), relationDefinition }));
    }

    @bind
    async submitEvent(relEvent){
        const { details, createEventToEventRelationship } = this.props;
        const { id, time } = details || {};
        const { relationDefinition: relDef } = this.state;
        if (id && time && relEvent?.id && relEvent?.time && relDef?.id) {
            const event = { id, time };
            const relatedEvent = { id: relEvent.id, time: relEvent.time };
            const relationDefinition = relDef.id;
            const response = await createEventToEventRelationship({ event, relatedEvent, relationDefinition });
            this.closeModal();
            if(!(response instanceof Error)) return this.props.onSubmit();
        }
    }

    render() {
        const { isOpen } = this.props;
        const { modalType, key } = this.state;
        if (!isOpen) {
            return null;
        }
        // if (modalType === 'primaryClass') {
        //     return (
        //         <AddResourcesModal
        //             key={`modalType-${key}`}
        //             type={modalType}
        //             isOpen={true}
        //             // addedResourcesList={resourceList}
        //             submitBtnLabel='Next'
        //             // closeIcon="close"
        //             onSubmit={this.openRelationDefModal}
        //             closeModal={this.closeModal}
        //             // hideCancelBtn
        //         />
        //     );
        // }
        if (modalType === 'relationDefinition') {
            return (
                <AddResourcesModal
                    key={`modalType-${key}`}
                    type={modalType}
                    searchBy={['description', 'relatedDescription']}
                    isOpen={true}
                    modalTitle="Select relation definition"
                    submitBtnLabel='Next'
                    // selectedItems={[relationDefinition].filter(Boolean)}
                    withoutClose
                    // closeIcon='arrow-left'
                    onSubmit={this.openEventTypesModal}
                    closeModal={this.closeModal}
                    filterBy={[{field: 'type', op: '=', value: 'event'}, {field: 'relatedType', op: '=', value: 'event'}]}
                />
            );
        }

        if (modalType === 'eventtype') {
            return (
                <AddEventsByEventTypeModal
                    key={`modalType-${key}`}
                    type={modalType}
                    isOpen={true}
                    modalTitle="Select event type"
                    // addedResourcesList={resourceList}
                    // withoutClose
                    closeIcon='arrow-left'
                    onSubmit={this.submitEvent}
                    closeModal={this.closeModal}
                    openRelationDefModal={this.openRelationDefModal}
                />
            );
        }
        return null;
    }
}

export default connect(null,
    {
        clearResourcesList,
        createEventToEventRelationship
    }
)(AddEventsRelationsModal);
