/* @flow */

import { gql } from '@apollo/client';

export default gql`
	mutation updateScriptMutation($record: ScriptUpdateInput!){
		result: updateScript(record: $record) {
			id
		}
	}
`;
