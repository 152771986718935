import greenlet from 'greenlet';

import worker from 'app/utils/worker/worker';
import { compile, render } from 'app/utils/template/template';
import { get } from 'app/utils/lo/lo';
import { reduce } from 'app/utils/promise/promise';

export const _parse = (data) => {
    if (!data || Array.isArray(data) || typeof data !== 'object') {
        return {};
    }
    const { type, ...rest } = data;
    const [key] = Object.keys(rest);
    const value = data[key];
    if (!key || !value) {
        return {};
    }
    return { key, value };
};

const buildEvaluate = (functions, valueString) => {
    const allFunctions = Object.entries(functions)
        .map(([name, def]) => `const ${name} = ${def}`)
        .join('\n');
    return `(context) => {
      ${allFunctions}
      return (${valueString})(context)
    }
    `;
};
export const evaluateMapping = (mapping, context, label, functions) => {
    return reduce(
        Object.entries(mapping || []),
        async (acc, [key, valueString]) => {
            const getter = valueString.trim();
            if (getter.startsWith('function ') || getter.startsWith('(')) {
                try {
                    let value = null;
                    if (functions) {
                        const evaluate = await greenlet(eval(buildEvaluate(functions, valueString)));
                        value = await evaluate(context);
                    } else {
                        value = await worker(getter, context);
                    }
                    acc.map[key] = value;
                } catch (e) {
                    acc.warnings.push(`An error occured evaluating the "${label}" with key "${key}": ${e}`);
                }
            } else {
                acc.map[key] = get(context, getter) || null;
            }
            return acc;
        },
        { map: {}, warnings: [] }
    );
};

const evaluateAction = (action, context) => {
    if (!action) {
        return null;
    }
    const value = action.trim();
    const link = value.match(/link\((.*)\)/);
    const navigate = value.match(/navigate\((.*)\)/);
    const startProcess = value.match(/startProcess\((.*)\)/);
    const openForm = value.match(/openForm\((.*)\)/);
    const showMessage = value.match(/showMessage\((.*),(.*)\)/);
    if (link) {
        const href = render(compile(link[1]), context);
        return () => ({ action: 'link', options: { href } });
    } else if (navigate) {
        const href = render(compile(navigate[1]), context);
        return () => ({ action: 'navigate', options: { href } });
    } else if (startProcess) {
        const index = startProcess[1].indexOf(',');
        if (index === -1) {
            const key = render(compile(startProcess[1]), context);
            return () => ({
                action: 'startProcess',
                options: { key },
            });
        }
        const key = render(compile(startProcess[1].substring(0, index)), context);
        const variables = JSON.parse(render(compile(startProcess[1].substring(index + 1)), context));
        return () => ({
            action: 'startProcess',
            options: { key, variables },
        });
    } else if (openForm) {
        const index = openForm[1].indexOf(',');
        if (index === -1) {
            const id = render(compile(openForm[1]), context);
            return () => ({
                action: 'openForm',
                options: { id },
            });
        }
        const id = render(compile(openForm[1].substring(0, index)), context);
        const data = JSON.parse(render(compile(openForm[1].substring(index + 1)), context));
        return () => ({
            action: 'openForm',
            options: { id, data },
        });
    } else if (showMessage) {
        const type = render(compile(showMessage[1]), context);
        const message = render(compile(showMessage[2]), context);
        return () => ({
            action: 'showMessage',
            options: { type, message },
        });
    } else {
        return () => worker(value, context);
    }
};

export const evaluateActionMapping = (actionMapping, context) => {
    return reduce(
        Object.entries(actionMapping || {}),
        async (acc, [key, action]) => {
            try {
                acc.map[key] = {
                    click: await evaluateAction(action.click, context),
                    dblclick: await evaluateAction(action.dblclick, context),
                };
            } catch (e) {
                acc.warnings.push(`An error occured evaluating the "action mapping" with key "${key}": ${e}`);
            }
            return acc;
        },
        { map: {}, warnings: [] }
    );
};

export const getAssetsMetadata = (uuidMap, assets) => {
    let assetsByUuid = assets.reduce((map, asset) => {
        const uuid = get(asset, 'attributes.asset/uuid');
        if (uuid) {
            map[uuid] = asset;
        }
        return map;
    }, {});
    const idsData = Object.values(uuidMap).filter((uuid) => typeof uuid !== 'string');
    assetsByUuid = idsData.reduce((map, idData) => {
        const { key, value } = _parse(idData);
        if (key && value) {
            const asset = assets.find((asset) => get(asset, key, '')?.includes(value));
            if (asset) {
                map[value] = asset;
            }
        }
        return map;
    }, assetsByUuid);
    let hidden = new Set();
    const assetMap = {};
    let missingAssets = new Set();

    Object.entries(uuidMap).forEach(([svgId, id]) => {
        let uuid = id;
        if (typeof uuid !== 'string') {
            const { value } = _parse(uuid);
            if (value) uuid = value;
        }

        if (!uuid || uuid === 'hide') {
            hidden.add(svgId);
        } else if (assetsByUuid[uuid]) {
            assetMap[svgId] = assetsByUuid[uuid];
        } else {
            missingAssets.add(uuid);
        }
    }, []);

    hidden = Array.from(hidden);
    missingAssets = Array.from(missingAssets);

    return { hidden, assetMap, missingAssets };
};
