import { pick, get } from 'app/utils/lo/lo';

export const label = (disabled) => ({
    type: 'text',
    properties: {
        label: 'Label',
        name: 'data.title',
        disabled
    },
    settings: { forModal: true },
    constraints: {
        required: true,
    }
});

export const componentPipelines = {
    type: 'typeahead',
    properties: {
        label: 'Type',
        name: 'data.definitionType',
        clearable: false,
        options: [{ value: 'mqqt', label: 'MQQT' }]
    },
    // constraints: {
    //     required: true,
    // }
};

export const relatedEntityToComponent = (entityType, { filterBy }, disabled) => ({
    type: 'entityTypeahead',
    properties: {
        label: 'Type',
        name: 'data.relatedEntity',
        entityType,
        extraInfo: true,
        clearable: false,
        disabled,
        filterBy: filterBy || [],
        onChange: (evnt) => {
            const { value, name } = evnt.target;
            const configurationDefinitionId = get(value, 'primary.iot_connector/configuration.id', null) || get(value, 'primary.iot_module/configuration.id', null);
            return [
                { name, value: value ? pick(value, ['id', 'name', 'type']) : value },
                { name: 'data.configuration', value: { id: configurationDefinitionId } },
            ];
        }
    },
    settings: { forModal: true },
    constraints: {
        required: true,
    }
});

export const configurationDefinitionForm = (disabled) => ({
    type: 'formDefinition',
    properties: {
        label: 'Configuration',
        name: 'data.configuration',
        isVisible: (data) => !!data.data?.configuration?.id,
        disabled
    },
    settings: { forModal: true },
});