import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { muiTheme } from 'app/themes/materialUi';
import { Checkbox, Typography, Container, Box, Divider, Button } from '@mic3/platform-ui';

import { loadUserProfile, updateUserTerms } from 'store/actions/admin/usersActions';

import { bind } from 'app/utils/decorators/decoratorUtils';
import affectliSso from 'app/auth/affectliSso';
import { TERMS_EULA } from 'app/config/termsUelaConfig';

import DisplayText from 'app/components/atoms/Designer/DisplayText';

const SubTitle = styled(Typography)`
    color: ${({ theme }) => theme.material.colors.text.secondary};
    align-self: center;
    font-size: 28px !important;
    margin-bottom: 20px !important;
`;

const ButtonStyledPrimary = styled(Button)`
    height: 44px !important;
    margin-top: 20px !important;
    border: none;
    background: linear-gradient(284.04deg, #6F2C91 0%, #1248BD 57.16%, #00AEEF 100%);
`;

const ButtonStyledLogout = styled(Button)`
    height: 44px !important;
    color: #7391D1 !important;
    margin-top: 20px !important;
    background: transparent !important;
    border: 1px solid #7B9EEA !important;
    border-radius: 4px;
`;

const CheckboxStyled = styled(Checkbox)`
    & .MuiCheckbox-colorPrimary.Mui-disabled {
        color: #93979e !important;
    }
`;

const DividerStyled = styled(Divider)`
    margin-bottom: 12px !important;
    width: 100%;
`;

const DividerLargeStyled = styled(Divider)`
    margin: 32px 0 24px !important;
    width: 100%;
`;

const Logo = styled.img`
    align-self: center;
    height: 60px;
    margin-bottom 12px;
`;

const Wrapper = styled(Box)`
    height: 100vh;
    overflow-y: auto;
    color: ${({ theme }) => theme.material.colors.text.primary};
`;

const Content = styled(Box)`
    padding: ${({ $isMobile }) => $isMobile ?  '40px 0 120px' : '40px 0'};
`;

const DisplayTextStyled = styled(DisplayText)`
    word-break: break-word !important;
    
    a {
        color: #60a8e7 !important;
    }

    ${({ $isEula }) => $isEula && `
        ol, ul {
            list-style-position: inside;
            padding-left: 0;
        }
    `}
`;

class TermsDetail extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { 
            agree: false,
        };
    }
    
    @bind
    handleChange(e) {
        const { value } = e.target;
        this.setState({ agree: value });
    }
    
    @bind
    handleSubmit() {
        if (this.state.agree) {
            this.props.updateUserTerms().then((resp) => {
                if (!(resp instanceof Error)) {
                    this.props.loadUserProfile();
                }
            });
        }
    }

    @bind
    handleLogout() {
        affectliSso.logout();
    }

    render() {
        const { agree } = this.state;
        const { terms } = this.props;

        return (
            <Wrapper minHeight="100vh">
                <Content
                    $isMobile={this.props.isMobile}
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="flex-start"
                >
                    <Container maxWidth="sm">
                        <Box
                            display="flex"
                            flexDirection="column"
                        >
                            <Logo src={muiTheme.logo.termsLogo} className="logo" alt="logo" />
                            <SubTitle variant="body1">Affectli Terms & Conditions</SubTitle>
                        </Box>
                        <Box>
                            <DisplayTextStyled $isEula text={TERMS_EULA}/>
                        </Box>
                        {terms?.content && (
                            <>
                                <DividerLargeStyled />
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                >
                                    <SubTitle variant="body1">Organisation Terms & Conditions</SubTitle>
                                </Box>
                                <Box>
                                    <DisplayTextStyled text={terms?.content || ''}/>
                                </Box>
                            </>
                        )}
                        
                        <DividerStyled />
                        <CheckboxStyled
                            onChange={this.handleChange}
                            label="I agree to the Affectli and organisation terms and conditions"
                            value={agree}
                        />
                        <ButtonStyledPrimary
                            disabled={!agree}
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={this.handleSubmit}
                        >
                            Continue
                        </ButtonStyledPrimary>
                        <ButtonStyledLogout
                            onClick={this.handleLogout}
                            variant="contained"
                            color="primary"
                            fullWidth
                        >
                            Logout
                        </ButtonStyledLogout>

                    </Container>
                </Content>
            </Wrapper>
        );
    }
}

export default connect(
    (state) => ({
        isMobile: state.global.isMobile
    }),
    {
        updateUserTerms,
        loadUserProfile
    }
)(TermsDetail);