/* @flow */

import { gql } from '@apollo/client';

export const eventTypeCommonQuery = `
    id
    name
    metadata
    preScript {
        id
    }
    script {
        id
    }
    active
    noActionOnDuplicate
    bpmnSignal
    lookupAttribute
    entityType{
      id
      name
      uri
    }
    descriptorType {
        id
        name
        uri
    }
    descriptorLookupAttribute
    createdBy {
        id
        name
        username
    }
    createdDate
    modifiedBy {
        id
        name
        username
    }
    modifiedDate
    eventDataAttributes {
                id
                attribute
                label
                format
            }
`;

export const loadEventTypeDetailsQuery = gql`
    query loadEventTypeDetailsQuery($id: ID!) {
        result: eventType(id: $id) {
            ${eventTypeCommonQuery}
        }
    }
`;

export const eventTypesListQuery = gql`
    query eventTypesListQuery($startIndex: Int, $stopIndex: Int, $filterBy: [JSON], $orderBy: [JSON], $countMax: Int, $excludeBy: [JSON]) {
        count: count(dataType: "eventType", filterBy: $filterBy, max: $countMax)
        records: eventTypes(startIndex: $startIndex, stopIndex: $stopIndex, filterBy: $filterBy, orderBy: $orderBy, excludeBy: $excludeBy) {
            ${eventTypeCommonQuery}
        }
    }
`;

export const eventTypesListShortQuery = gql`
    query eventTypesListShortQuery($filterBy: [JSON], $orderBy: [JSON], $excludeBy: [JSON]) {
        records: eventTypes(filterBy: $filterBy, orderBy: $orderBy, excludeBy: $excludeBy) {
            id
            name
        }
    }
`;
