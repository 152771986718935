import EntityLayer from './EntityLayer';
import { bind } from  'app/utils/decorators/decoratorUtils';
import { Vector as VectorLayer } from 'ol/layer';
import GeoJSON from 'ol/format/GeoJSON';
import GeometryCollection from 'ol/geom/GeometryCollection';
import { Fill, Stroke, Style, Text } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import Point from 'ol/geom/Point';
import { stringDivider } from 'app/components/molecules/Map/EntityPin/entityPinStyle';
import { convertToSingleFeature } from 'app/components/molecules/Map/DrawShapes/DrawDrawingLayer';
import { isEmpty } from 'app/utils/utils';


class DrawingLayer extends EntityLayer {

    constructor(info, map){
        super(info,map);
        const {  id } = info || {};
        const features = info?.drawing_feature || {};
        let _features = (!isEmpty(features) && JSON.parse(features)) || [];
        if(_features?.type === 'FeatureCollection')
            _features = _features?.features;
        _features.length && this.addDrawingToLayer(id, _features, map);
    }

    async setLayer() {
        const { name, filters, type, id, visible, opacity } = this.info || {};
        this.layer = new VectorLayer({
            name: id,
            title: name,
            visible,
            opacity,
            type,
            attributes: {
                id,
                type,
                subtitle: 'drawings',
                layerType: 'Drawing Layer',
                iconName: 'gesture',
                filters,
                noLocationPins: []
            },
            source: this.getDataSource(),
            style: this.styleFunction
        });
        await this.addLayerToMap();
    }

    @bind
    loader(extent, resolution, projection) {
        // this. getFeatureUnderExtent(extent)
    }

    styleFunction(feature, resolution) {
        const { fillColor, strokeColor, strokeWidth, geometry, properties } = feature.values_;
        if (geometry instanceof GeometryCollection) {
            const { geometries_ } = geometry;
            return geometries_.map((g, i) => {
                const { fillColor, strokeColor, strokeWidth } = properties[i] || {};
                return new Style({
                    geometry: g,
                    fill: new Fill({
                        color: fillColor || 'rgba(61, 100, 183, 0.3)'
                    }),
                    stroke: new Stroke({
                        color: strokeColor || 'green',
                        width: strokeWidth || 2
                    })
                });
            });
        }
        if(geometry instanceof Point) {
            const { fontSizeText, fontTypeText, drawingText, color, textBoxWidth } = feature?.values_;
            const font = `normal ${fontSizeText}px ${fontTypeText}`;
            return new Style({
                image: new CircleStyle({
                    radius: 0,
                    stroke: new Stroke({color: 'red', width: 1}),
                }),
                text: new Text({
                    font: `normal ${fontSizeText}px ${fontTypeText}`,
                    textAlign: 'center',
                    text: stringDivider(drawingText, textBoxWidth || 100, '\n', font) || '',
                    fill: new Fill({color: color || 'red'})
                })
            });
        } 
        return new Style({
            fill: new Fill({
                color: fillColor || 'rgba(61, 100, 183, 0.3)'
            }),
            stroke: new Stroke({
                color: strokeColor || 'green',
                width: strokeWidth || 2
            })
        });   
        
    }

   

    addDrawingToLayer(id: strinng, features: Array<Object>, map: Object) {
        features.length && features.forEach((item) => {
            // in case of geometry collection, error will be handled by try catch.
            try {
                let feature = new GeoJSON().readFeatures(item);
                if (feature.length > 1) {
                    feature = convertToSingleFeature(feature);
                }
                const layerSource = this.layer?.getSource(id, map);
                layerSource && feature && layerSource.addFeature(feature?.[0]);
            }
            catch (e) {
                // eslint-disable-next-line no-console
                console.error('error in geom',e);
            }
        });
        map.getMap().render();

    };

    getSource(id, map) {
        const foundLayer = id && map.findLayerById(this.layerId);
        foundLayer && foundLayer.getSource().clear();
        return foundLayer && foundLayer.getSource() && foundLayer.getSource();
    };

}

export default DrawingLayer;
