/* @flow */

import { gql } from '@apollo/client';

import { classDetailsFields } from 'graphql/entities/classifications/classificationDetailQuery';

export default gql`
    mutation updateClassificationMutation($classification: ClassUpdateInput!) {
        result: updateClass(record: $classification) {
            ${classDetailsFields}
        }
    }
`;
