/* @flow */

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { muiTheme } from 'app/themes/materialUi';
import uuidv1 from 'uuid/v1';
import { TreeItem, TreeView } from '@material-ui/lab';

import { loadTreeEntities, loadTreeEntityRelations, setSidebarTreeTemplate, setSidebarTreeTemplateExpand } from 'store/actions/entities/relatedEntitiesActions';
import { openEntitySidebar } from 'store/actions/entities/entitySidebarActions';
import history from 'store/History';

import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { get, sortBy } from 'app/utils/lo/lo';
import { searchTreeById } from 'app/utils/tree/tree';
import { treeType } from 'app/config/treeTemplateConfig';

import Loader from 'app/components/atoms/Loader/Loader';
import Icon from 'app/components/atoms/Icon/Icon';
import RelatedEntityTreeListViewItem from './RelatedEntityTreeListViewItem';

const TreeViewStyled = styled(TreeView)`
    margin: 0 16px !important;

    & .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label, .MuiTreeItem-label {
        background-color: transparent !important;
        color: ${({theme})=> theme.material.colors.text.primary};
    }

    & .MuiTreeItem-iconContainer {
        margin-left: 12px;
        margin-right: 16px;
    }
    
    & .MuiTreeItem-group {
        margin-left: 0 !important;
    }

    & .indent > .MuiTreeItem-group { 
        margin-left: 38px !important;
    }
    
    & .no-indent > .MuiTreeItem-group {
        margin-left: 0 !important;
    }

    & .MuiTreeItem-content {
        min-height: 52px;
    }
`;

const TreeItemRoot = styled(TreeItem)`
    & .MuiTreeItem-label {
        font-size: 10px;
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 1.5px;
    }
`;

const LoaderStyled = styled(Loader)`
    display: inline-block;
    position: relative;
    top: 3px;
    margin-left: 4px;
`;

const TreeItemStyled = styled(TreeItem)`
    & > .MuiTreeItem-content{
        padding-right: 36px;
        background: ${({ theme, $selected }) => $selected ? theme.material.colors.itemActive : ''};
    }
`;

class RelatedEntityTreeListView extends PureComponent<Object, Object> {
    static propTypes = {
        template: PropTypes.object.isRequired,
        treeEntity: PropTypes.object,
        search: PropTypes.string,
        hideInactive: PropTypes.bool,
        sort: PropTypes.string,
        expand: PropTypes.bool
    }

    constructor(props: Object) {
        super(props);
        this.state = {
            forceUpdateKey: 0,
            expanded: props.sidebarTreeTemplateExpanded || [],
            selectedNode: null,
            tree: JSON.parse(JSON.stringify(props.sidebarTreeTemplate)) || {
                isLoading: true,
                id: props.template?.root?.id,
                name: props.template?.root?.name,
                uri:  props.template?.root?.uri,
                listBy: props.template?.root?.listBy,
                entity: null,
                children: [],
                nodeId: uuidv1(),
                visible: true
            }
        };
    };
    
    componentDidMount() {
        this.props.loadTreeEntities({ options: {}, type: this.state.tree?.uri }).then((response) => {
            if (response instanceof Error) return;
            const { records } = response;
            const groupIcon = this.props.template?.root?.groupIcon;
            const groupIconType = this.props.template?.root?.groupIconType;
            const children = this.buildChildren(
                records, 
                this.props.template?.root?.children, 
                this.state.tree?.uri, 
                this.props.template?.root?.listBy, 
                groupIcon, 
                groupIconType
            );
            this.setState(prevState => ({
                ...prevState,
                tree: {
                    ...prevState.tree,
                    children,
                    isLoading: false,
                }
            }), () => this.props.setSidebarTreeTemplate(JSON.parse(JSON.stringify(this.state.tree))));
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.expand !== this.props.expand) {
            this.expandNodes(this.state.tree);
        }
        if (prevProps.search !== this.props.search || prevProps.hideInactive !== this.props.hideInactive) {
            this.resetNodeValue(this.state.tree, 'visible', true);
            this.setState({ forceUpdateKey: this.state.forceUpdateKey + 1 });
        }
        if (prevState.expanded !== this.state.expanded) {
            this.setState({ forceUpdateKey: this.state.forceUpdateKey + 1 });
        }
    }

    @bind
    buildChildren(children, template, type, listBy, groupIcon, groupIconType) {
        return children.map(child => ({
            id: child.id,
            name: child.name,
            entity: child,
            groupIcon,
            groupIconType,
            children: this.buildChildFromTemplate(template, child.id, child.type),
            type,
            template,
            listBy,
            nodeId: uuidv1(),
            visible: true
        }));
    }

    @bind
    buildChildFromTemplate(children, entityId, entityType) {
        return (children || []).map(child => ({
            id: child.id,
            name: child.name,
            entity: null,
            type: child.uri,
            children: [],
            reverse: child.reverse,
            listBy: child.listBy,
            parent: {
                entityId,
                entityType
            },
            groupIcon: child.groupIcon,
            groupIconType: child.groupiconType,
            template: child.children,
            isLoading: false,
            childrenLoaded: false,
            nodeId: uuidv1(),
            visible: true
        }));
    }


    @bind
    @memoize()
    sortNodes(children, sort) {
        const sortChildren = (children) => {
            const data = sortBy(children, 'entity.name');
            if (sort === 'desc') {
                data.reverse();
            }
            return (data || []).map((d) => {
                d.children = sortChildren(d.children);
                return d;
            });
        };
        return sortChildren(children);        
    }

    @bind
    @memoize()
    hideInactiveNodes(children, hideInactive) {
        const filterChildren = (children) => {
            return (children || []).map((d) => {
                if (hideInactive && !get(d, 'entity.active') && get(d, 'entity')) {
                    d.visible = false;
                }
                if (d.children.length > 0) {
                    d.children = filterChildren(d.children);
                }
                return d;
            });
        };
        return filterChildren(children);     
    }

    @bind
    @memoize()
    filterSearchNodes(children, search) {
        const filterChildren = (children) => {
            return (children || []).map((d) => {
                if (!get(d, 'entity.name', '').toLowerCase().includes(search.toLowerCase()) && d.entity) {
                    d.visible = false;
                }
                if (d.children.length > 0) {
                    d.children = filterChildren(d.children);
                }
                return d;
            });
        };
        return filterChildren(children);        
    }

    @bind
    handleToggle(e, nodeIds) {
        this.setState({ expanded: nodeIds });
        this.props.setSidebarTreeTemplateExpand(nodeIds);
    }

    @bind
    expandNodes(node) {
        const getNodeIds = (result, node) => {
            if (node.children.length > 0) {
                result.push(node.nodeId);
                const children = node.children.reduce(getNodeIds, []);
                if (children.length) result.push(...children);
                return result;
            }
            return result;
        };
        const nodeIds = node.children.reduce(getNodeIds, []);
        nodeIds.push(node.nodeId);
        this.setState({ expanded: nodeIds });
        const tree = JSON.parse(JSON.stringify(this.state.tree));
        this.loadChildrenNodes(node, tree);
        this.props.setSidebarTreeTemplateExpand(nodeIds);
    }

    @bind
    loadChildrenNodes(node, tree) {
        if (node.childrenLoaded === false) {
            this.getEntityRelations(node, tree);
        } else if (node.children.length > 0) {
            node.children.forEach((child) => {
                this.loadChildrenNodes(child, tree);
            });
        }
    }

    @bind
    resetNodeValue(tree, key, value) {
        const setValue = (tree) => {
            tree[key] = value;
            tree.children.map((d) => {
                return setValue(d);
            });
            return tree;
        };
        const t = setValue(tree);
        return t;
    }

    @bind
    getEntityRelations(nodes, tree) {
        const node = searchTreeById(tree, nodes.nodeId, 'nodeId');
        node.isLoading = true;
        this.setState({ tree });
        const entityId = get(nodes, 'parent.entityId');
        const entityType = get(nodes, 'parent.entityType');
        const listBy = get(nodes, 'listBy');
        if (!entityId) {
            return;
        }
        const filterBy = [
            // { field: 'relatedEntity.type', op: '=', value: nodes.type },
            { field: 'relation.relationDefinition.id', op: '=', value: nodes.id },
            { field: 'isReverseRelation', op: '=', value: nodes.reverse }
        ];
        this.props.loadTreeEntityRelations({ filterBy }, entityType, entityId ).then((response) => {
            if (response instanceof Error) return;
            const entityIds = (response?.relations || []).map((rel) => {
                return rel.relatedEntity?.id;
            });
            if (entityIds.length <= 0) {
                node.isLoaded = true;
                node.isLoading = false;
                const newTree = { ...tree };
                this.setState({ tree: newTree });
                return;
            }
            const filterBy = [{ field: 'id', op: 'in', value: entityIds }];
            this.props.loadTreeEntities({ options: { filterBy }, type: nodes.type }).then((response) => {
                if (response instanceof Error) return;
                const { records } = response;
                const children = this.buildChildren(records, nodes.template, nodes.type, listBy, nodes.groupIcon, nodes.groupIconType);
                node.children = [...children];
                node.isLoaded = true;
                node.isLoading = false;
                node.childrenLoaded = true;
                const newTree = { ...tree };
                this.setState(prevState => ({ 
                    ...prevState,
                    tree: newTree,
                    expanded: [...prevState.expanded, node.nodeId]
                }), () => this.props.setSidebarTreeTemplate(JSON.parse(JSON.stringify(this.state.tree))));
            });
        });
    }

    @bind
    handleIconClick(e, nodes) {
        e.preventDefault();

        if (nodes.children.length <= 0) {
            const tree = JSON.parse(JSON.stringify(this.state.tree));
            this.getEntityRelations(nodes, tree);
        }
    };

    @bind
    openContent(id, type, title, defaultTab) {
        let newTitle = title;

        if (defaultTab.length > 0 && newTitle === '') {
            newTitle = defaultTab[0];
        }

        const routeMap = {
            'About' : 'about',
            'Sharing': 'sharing',
            'Share': 'sharing',
            'A-Live': 'chat',
            'Relations': 'relationships',
            'Classes': 'classifications',
            'Location': 'locations',
            'Attachments': 'attachments',
            'Digital Twin': 'digital-twin',
            'History': 'history',
        }

        const route = routeMap[newTitle] || 'about'
        
        history.push(`/related-entities/${type}/${id}/${route}`);
    }

    handleLabelClick(e, data, title, nodes) {
        if (e) e.preventDefault();

        this.setState({ selectedNode: nodes.nodeId });
        const type = get(this.props.treeEntity, 'primary.treeType');
        const defaultTab = get(data, 'primaryClass.entityTabs.list', []) || [];

        if ([treeType.OPEN_DETAILS, treeType.OPEN_MAP].includes(type) ) {
            this.openContent(data.id, data.type, title, defaultTab);
        } else if (treeType.OPEN_RELATED_ENTITIES === type) {
            if (title) {
                this.openContent(data.id, data.type, title, []);
                return;
            }
            const relEntitiesType = get(this.props.template, 'listEntityType');
            const relationDefinitionId = get(nodes, 'listBy.id');
            if (!relationDefinitionId) return;
            history.push(`/related-entities/${relEntitiesType}/entities/${relationDefinitionId}/${nodes.entity?.id}`);
        }
    };
    
    @bind
    buildTree(nodes) {
        if (!nodes.visible) {
            return;
        }

        const isSelected = nodes.nodeId === this.state.selectedNode;
        const children  = nodes.children || [];
        const count = children.filter((c) => c.visible).length;
        
        if (children <= 0) {
            if (!nodes.entity) {
                return (
                    <TreeItemRoot 
                        className="no-indent"
                        key={nodes.nodeId} 
                        nodeId={nodes.nodeId}
                        onIconClick={e => nodes.isLoaded ? null : this.handleIconClick(e, nodes)}
                        endIcon={nodes.isLoaded && count <= 0 ? <Icon name="circle-small" hexColor={muiTheme.colors.secondary.main} /> 
                            : <Icon name="chevron-right" hexColor={muiTheme.colors.secondary.main} />}
                        label={
                            <>
                                {nodes.name}
                                {nodes.isLoading && <LoaderStyled size={12} />}
                            </>
                        } 
                    />
                );
            } else {
                return (
                    <TreeItemStyled
                        $selected={isSelected}
                        className="indent"
                        key={nodes.nodeId} 
                        nodeId={nodes.nodeId}
                        onIconClick={e => this.handleIconClick(e, nodes)}
                        onLabelClick={e => this.handleLabelClick(e, nodes.entity, '', nodes)}
                        endIcon={<Icon name="circle-small" hexColor={muiTheme.colors.secondary.main} />}
                        label={
                            <RelatedEntityTreeListViewItem
                                onActionClick={(title) => this.handleLabelClick(null, nodes.entity, title, nodes)}
                                data={nodes.entity}
                                type="treetemplate"
                                childrenCount={0}
                                groupIcon={nodes.groupIcon}
                                groupIconType={nodes.groupIconType}
                            />
                        }
                    />
                );
            }
        } else {
            if (!nodes.entity) {
                return (
                    <TreeItemRoot 
                        className="no-indent"
                        key={nodes.nodeId} 
                        nodeId={nodes.nodeId} 
                        label={(
                            <>{`${nodes.name} ${count > 0 ? `(${count})` : ''}`}</>
                        )}
                    >
                        {children.map(child => this.buildTree(child))}
                    </TreeItemRoot>
                );
            } else {
                return (
                    <TreeItemStyled
                        $selected={isSelected}
                        className="indent"
                        key={nodes.nodeId} 
                        nodeId={nodes.nodeId}
                        onLabelClick={e => this.handleLabelClick(e, nodes.entity, '', nodes)}
                        label={
                            <RelatedEntityTreeListViewItem
                                onActionClick={(title) => this.handleLabelClick(null, nodes.entity, title, nodes)}
                                data={nodes.entity}
                                type="treetemplate"
                                childrenCount={count}
                                groupIcon={nodes.groupIcon}
                                groupIconType={nodes.groupIconType}
                            />
                        }
                    >
                        {children.map(child => this.buildTree(child))}
                    </TreeItemStyled>
                );
            }
        }
    }
    
    render() {
        const { tree, expanded } = this.state;
        const { search, sort, hideInactive } = this.props;
        let children = this.filterSearchNodes(tree.children, search);
        children = this.hideInactiveNodes(children, hideInactive);
        children = this.sortNodes(children, sort);
        tree.children = children || [];

        return (
            <TreeViewStyled
                expanded={expanded}
                onNodeToggle={this.handleToggle}
                defaultCollapseIcon={<Icon name="chevron-down" hexColor={muiTheme.colors.secondary.main} />}
                defaultExpandIcon={<Icon name="chevron-right" hexColor={muiTheme.colors.secondary.main} />}
                defaultEndIcon={<Icon name="chevron-right" hexColor={muiTheme.colors.secondary.main} />}
            >
                {this.buildTree(tree)}
            </TreeViewStyled>
        );
    }
}

export default connect(
    state => ({
        sidebarOpts: state.sidebar,
        sidebarTreeTemplate: state.entities.relatedEntities.tree.sidebarTreeTemplate,
        sidebarTreeTemplateExpanded: state.entities.relatedEntities.tree.sidebarTreeTemplateExpanded,
    }), 
    {
        setSidebarTreeTemplateExpand,
        setSidebarTreeTemplate,
        loadTreeEntities, 
        loadTreeEntityRelations,
        openEntitySidebar
    }
)(RelatedEntityTreeListView);
