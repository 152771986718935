/* @flow */

import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { muiTheme } from 'app/themes/materialUi';
import { connect } from 'react-redux';
import {
    // Typography, Grid, MdiIcon, IconButton, InputBase, Switch, Divider,
    Button } from '@mic3/platform-ui';
import { withTheme } from 'styled-components';
import { Switch, Route, Redirect } from 'react-router-dom';

import Tabs from 'app/components/organisms/Tabs/Tabs';
import PrintTemplateLayoutEditor from 'app/containers/Entities/PrintTemplates/PrintTemplateLayoutEditor';
import PrintTemplateLayoutPreview from 'app/containers/Entities/PrintTemplates/PrintTemplateLayoutPreview';
import PrintPreviewSettings from 'app/containers/Entities/PrintTemplates/PrintPreviewSettings';
import Icon from 'app/components/atoms/Icon/Icon';
import HeaderBar from 'app/components/molecules/HeaderBar/HeaderBar';
import DotMenu from 'app/components/molecules/DotMenu/DotMenu';
import { updateEntity, uploadImage } from 'store/actions/entities/entitiesActions';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { get } from 'app/utils/lo/lo';
import { copyToClipboard } from 'app/utils/classification/classificationUtils';
import { showToastr } from 'store/actions/app/appActions';
import { getPermissions } from 'app/config/rolesConfig';

class PrintTemplateLayout extends PureComponent<Object, Object> {
    static propTypes: Object = {
        details: PropTypes.object.isRequired,
        uploadImage: PropTypes.func,
        isLoading: PropTypes.bool,
        canEdit: PropTypes.bool,
        fromSidebar: PropTypes.bool,
        imageData: PropTypes.object,
        userProfile: PropTypes.object,
        reloadList: PropTypes.func
    };

    constructor(props) {
        super(props);
        this.state = { context: null, showPreviwSettings: false, changed: false, text: props.details?.primary['print-template/template'] || '' };
    }

    componentDidUpdate(prevProps) {
        const { details } = this.props;
        if(details !== prevProps.details) {
            this.setState({ text: details?.primary['print-template/template'] || '', changed: false });
        }
    }

    @bind
    async onFormSubmit() {
        const { details }= this.props;
        const { id, type } = details;
        const { text } = this.state;
        this.props.updateEntity({
            id, type,
            primary: {
                'print-template/template': text,
            }
        }).then(async (resp) => {
            if (!(resp instanceof Error)) {
                this.setState({ changed: false });
                this.props.reloadDetails();
            }
        });
    }

    @bind
    handleOnChangeText(event) {
        const { details } = this.props;
        this.setState({ text: event.target.value, changed: event.target.value !== get(details, 'primary.print-template/template', '') });
    }

    @bind
    handleChangeContext({ target: { value }}) {
        this.setState({ context: value });
    }

    @bind
    handleChangeFormData({ target: { value }}) {
        this.setState({ formData: value });
    }

    @bind
    onFormCancel() {
        const { details } = this.props;
        this.setState({ text: details.primary['print-template/template'] || '', changed: false });
    }

    @bind
    handleShowPreviwSettings() {
        this.setState({ showPreviwSettings: !this.state.showPreviwSettings });
    }

    @bind
    copyIdToClipBoard() {
        const { showToastr, id, type } = this.props;
        copyToClipboard(`${window.location.origin}/#/entities/${type}/${id}`)
            .then(() => {
                showToastr({ severity: 'success', detail: 'Link copied to clipboard' });
            })
            .catch(() => {
                showToastr({ severity: 'error', detail: 'Link could not copied to clipboard' });
            });
    }

    @bind
    onDotMenuClick(title) {
        if(title === 'Rename') {
            this.setState({ renameActive: true });
            return;
        }
        if(title === 'Disable' || title === 'Enable') {
            this.onDisableSubmit();
            return;
        }
        if(title === 'Copy link') {
            this.copyIdToClipBoard();
            return;
        }

        this.props.openSidebar(title);
    }

    @bind
    @memoize()
    dotMenu(entityForm, canEdit) {
        return (
            <DotMenu
                key={13}
                onItemClick={this.onDotMenuClick}
                items={[
                    { name: 'Copy link', icon: 'link' },
                    { name: 'Sharing', icon: 'account-plus' },
                    { name: 'divider' },
                    canEdit && { name: 'Rename', icon: 'pencil' },
                    canEdit && { name: entityForm.active ? 'Disable' : 'Enable', icon: entityForm.active ? 'close' : 'check' },
                    canEdit && { name: 'divider' },

                ].filter(Boolean)}
            />
        );
    }

    @bind
    @memoize()
    buildTabs(match, location, matchUrl, canEdit) {
        return [
            canEdit && { active: location?.pathname?.includes('/editor'), label: 'Print Layout', link: `${matchUrl}/editor` },
            { active: location?.pathname?.includes('/preview'), label: 'Preview', link: `${matchUrl}/preview` },
        ].filter(Boolean);
    }

    @bind
    handlePrint() {
        const { context } = this.state;
        if(!context) {
            this.setState({ showPreviwSettings: !this.state.showPreviwSettings });
        } else {
            window.print();
        }
    }

    render(): Object {
        const { breadcrumbLine, match, location, details } = this.props;
        const { text, changed, showPreviwSettings, context, formData } = this.state;
        const matchUrl = `${match.url}/layout`;

        const permissions = getPermissions(details?.role);
        const { canEdit } = permissions;

        return (
            <Fragment>
                <div><HeaderBar className="no-print" left={breadcrumbLine} right={changed ? [(
                    <Button key={12} variant="text" onClick={this.onFormCancel}>
                      cancel
                    </Button>
                ),(
                    <Button disabled={!canEdit} key={13} onClick={this.onFormSubmit}>
                    Save
                    </Button>
                )] :
                    this.props.sidebarActions} /></div>
                <HeaderBar className="no-print"
                    left={(
                        <Tabs tabs={this.buildTabs(match, location, matchUrl, canEdit)}/>
                    )}
                    right={(
                        <>
                            <Button size="small" startIcon={(
                                <Icon name="printer"  size="sm" hexColor={muiTheme.colors.text.button} />
                            )} onClick={this.handlePrint} >Print</Button>
                        </>
                    )}
                />
                <Switch>
                    <Route path={matchUrl} exact render={() =>
                        <Redirect to={canEdit ? `${matchUrl}/editor` : `${matchUrl}/preview`}/>}
                    />
                    <Route path={`${matchUrl}/editor`} render={() => canEdit ?
                        <PrintTemplateLayoutEditor
                            text={text}
                            context={context}
                            handleOnChangeText={this.handleOnChangeText}
                        />
                        : null}
                    />
                    {<Route path={`${matchUrl}/preview`} render={() =>
                        <PrintTemplateLayoutPreview
                            text={text}
                            context={context}
                            handleShowPreviwSettings={this.handleShowPreviwSettings}
                            handleChangeContext={this.handleChangeContext}
                        />
                    }
                    />}
                </Switch>
                {showPreviwSettings && <PrintPreviewSettings
                    context={context}
                    formData={formData}
                    printTemplate={details}
                    handleChangeContext={this.handleChangeContext}
                    handleChangeFormData={this.handleChangeFormData}
                    onClose={this.handleShowPreviwSettings}
                    canEdit={canEdit}
                />}
            </Fragment>
        );
    }
}

const mapStateToProps = (state: Object) => ({
    isLoading: state.entities.printTemplates.details.isLoading,
    userProfile: state.user.profile
});

export default connect(
    mapStateToProps,
    { updateEntity, uploadImage, showToastr }
)(withTheme(PrintTemplateLayout));
