/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Button, Typography } from '@mic3/platform-ui';

import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import Textarea from 'app/containers/Designer/Form/components/Textarea';
import Layout from 'app/components/Designer/Layout';
import Loader from 'app/components/atoms/Loader/Loader';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { expandClassesAndDefaults } from 'app/utils/form/formGenerator';
import { loadClassificationsByIds } from 'store/actions/classifications/classificationsActions';
import { get } from 'app/utils/lo/lo';
import { openSidebarByParams } from 'app/utils/app/appUtils';

import { openEntitySidebar } from 'store/actions/entities/entitySidebarActions';
import { openProcessSidebar } from 'store/actions/abox/processSidebarActions';
import { openClassSidebar } from 'store/actions/entities/classSidebarActions';
import { openTeamSidebar } from 'store/actions/entities/teamSidebarActions';
import { openTaskSidebar } from 'store/actions/abox/taskSidebarActions';
import { openWorkspaceSidebar } from 'store/actions/entities/workspaceSidebarActions';
import { openEventSidebar } from 'store/actions/stream/eventsSidebarActions';
import { openEventsActionsSidebar } from 'store/actions/stream/eventsActionsSidebarActions';
import { openEventTypeSidebar } from 'store/actions/entities/eventTypeSidebarActions';

const StyledForm = styled(FormGenerator)`
max-width: 960px;
margin: 16px auto !important;
`;

const SidebarContent = styled.div`
padding: 16px;
`;

class FormDesignerPreview extends PureComponent<Object, Object> {

    static propTypes = {
        profile: PropTypes.object,
        designerState: PropTypes.object.isRequired,
        savePreviewState: PropTypes.func.isRequired,
        loadClassificationsByIds: PropTypes.func,
    };

    contentAreaRef: Object = React.createRef();
    formRef: Object = React.createRef();

    state = { components: [], variables: {}, data:{} }

    componentDidUpdate(prevProps: Object) {
        const { designerState } = this.props;
        const { editor, preview } = designerState || {};
        const { fields: formComponents } = editor || {};
        const { data } = preview || {};
        if(formComponents !== get(prevProps, 'designerState.editor.fields')) {
            this.expandClassesComponents(formComponents, data);
        }
    }

    componentDidMount() {
        const { designerState } = this.props;
        const { editor, preview } = designerState || {};
        const { fields: formComponents } = editor || {};
        const { data } = preview || {};
        this.expandClassesComponents(formComponents, data);
    }

    @bind
    onDataChange({ target: { value } }: Object) {
        this.setState({ data: value }, () => this.props.savePreviewState({ data: value }));
    };

    @bind
    onContextChange({ target: { value } }: Object) {
        this.props.savePreviewState({ context: value });
    };

    @bind
    async validate() {
        return this.formRef.current.isValidForm();
    }

    @bind
    savePreviewData(data) {
        this.setState({ data }, () => this.props.savePreviewState({ data }));
    }

    @bind
    @memoize()
    async expandClassesComponents(formComponents, variables) {
        const { components, data } = await expandClassesAndDefaults(formComponents, variables, this.props.loadClassificationsByIds);
        this.setState({ components, data }, () => this.props.savePreviewState({ data }));
    }

    @bind
    @memoize()
    buildFormDefinitionFields(components) {
        return this._buildFormDefinitionFields(components);
    }

    @bind
    _buildFormDefinitionFields(normalized, path, parent) {
        return (normalized || []).map((field) => {
            path = parent ? '' : path;
            const enrichedField = {...field};
            if (enrichedField.children) {
                if(enrichedField.properties.name) {
                    path = path ? `${path}.${enrichedField.properties.name}` : `${enrichedField.properties.name}`;
                }
                enrichedField.children = this._buildFormDefinitionFields(enrichedField.children, path, false);
                return enrichedField;
            }

            switch(enrichedField.type) {
                case 'chip': {
                    enrichedField.properties = {
                        ...enrichedField.properties,
                        openSidebar: (params) => openSidebarByParams({ ...this.props, ...params, }) // FIXME: should opensidebars
                    };
                    break;
                }
                case 'file': {
                    enrichedField.properties = {
                        ...enrichedField.properties,
                        preview: true
                    };
                    break;
                }
                default:
            }

            return enrichedField;
        });
    }

    @bind
    @memoize()
    rightSightbarProp(data, context) {
        return {
            title: 'Properties',
            content: (
                <SidebarContent>
                    <Typography variant="subtitle1">
                  Data (JSON format)
                    </Typography>
                    <Textarea
                        parseAs={'JSON'}
                        name="json"
                        value={data}
                        onChange={this.onDataChange}
                        clearable={false}
                    />
                    <Typography variant="subtitle1">
                  Context (JSON format)
                    </Typography>
                    <Textarea
                        parseAs={'JSON'}
                        name="json"
                        value={context}
                        onChange={this.onContextChange}
                        clearable={false}
                    />
                    <Button fullWidth onClick={this.validate}>
                    Validate
                    </Button>
                </SidebarContent>
            )
        };
    }

    render() {
        const { designerState, isLoading, tabs, secondaryActions, disabled } = this.props;
        const { preview } = designerState || {};
        const { context } = preview || {};
        const { data } = this.state;
        const components = this.buildFormDefinitionFields(this.state.components);
        return (
            <>
                <Layout
                    tabs={tabs}
                    height={'calc(100vh - 110px)'}
                    content={
                        isLoading ? <Loader absolute backdrop /> : (
                            <StyledForm
                                ref={this.formRef}
                                components={components}
                                data={data}
                                onChange={this.savePreviewData}
                                context={context}
                                disabled={disabled}
                            />
                        )
                    }
                    ToolbarContent={this.props.actions}
                    afterRightButton={this.props.afterRightButton}
                    leftToolbarContent={this.props.breadcrumbLine}
                    RightSidebarProps={this.rightSightbarProp(data, context)}
                    TabsToolbarContent={secondaryActions}
                />
            </>
        );
    }
}

export default connect(
    state => ({
        entity: state.entities.classification.data,
        isLoading: state.classifications.classificationsByIds.isLoading,
    }),
    { 
        loadClassificationsByIds,
        openEntitySidebar,
        openClassSidebar,
        openTaskSidebar,
        openProcessSidebar,
        openTeamSidebar,
        openWorkspaceSidebar,
        openEventTypeSidebar,
        openEventSidebar,
        openEventsActionsSidebar,
    },
)(FormDesignerPreview);
