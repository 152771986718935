// @flow

export default `
    id
    name
    owners{
        id
        name
        username
        image
    }
    users {
      role
      team {
        id
        name

      }
      user {
        id
        name
        username
        image
      }
    }
    active
`;
