/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { bind } from 'app/utils/decorators/decoratorUtils';
import { PRIORITY_FILTER_OPTIONS } from 'app/config/aboxConfig';
import { set, get } from 'app/utils/lo/lo';
import Filters from 'app/components/organisms/Filters/Filters';
import VirtualListManaged from 'app/components/molecules/VirtualList/VirtualListManaged';
import { formatOptionsForTask } from 'app/utils/formatter/graphql-formatter';
import TasksViewItem from './TasksViewItem';

const FiltersStyled = styled(Filters)`
    padding: 0 8px;
`;

export const filterDefinitions = [
    {
        field: 'primary.assignee',
        type: 'userTypeahead',
        properties: {
            label: 'Assignee',
            name: 'assigneeId',
            multiple: true,
            disabled: data => get(data, 'assigneeIdNull') !== null,
            valueField: 'id',
        },
        condition: 'in'
    },
    {
        field: 'primary.assignee',
        type: 'typeahead',
        properties: {
            label: 'Is assigned?',
            name: 'assigneeIdNull',
            options: [
                { value: null, label: 'Any' },
                { value: 'is not null', label: 'Yes' },
                { value: 'is null', label: 'No' },
            ],
            onChange: function onChange(event) {
                const value = event.target.value;
                return [
                    { name: 'assigneeIdNull', value },
                    { name: 'assigneeId', value: null }
                ];
            },
        },
        sort: false,
    },
    {
        field: 'name',
        type: 'text',
        properties: {
            label: 'Name',
            name: 'name',
            opSelector: true
        },
    },
    {
        field: 'process.name',
        type: 'text',
        properties: {
            label: 'Process name',
            name: 'process.name',
            opSelector: true
        },
        sort: false, 
        filters: false
    },
    {
        field: 'id',
        type: 'uuid',
        properties: {
            label: 'ID',
            name: 'id',
            opSelector: true
        },
        condition: '='
    },
    {
        field: 'classes.id',
        type: 'classificationTypeahead',
        properties: {
            label: 'Classes',
            name: 'classesUri',
            filterBy: [
                { field: 'active', op: '=', value: true },
                { field: 'abstract', op: '=', value: false },
                { field: 'primary', op: '=', value: false },
                { field: 'applicableOn', op: 'overlaps', 'value': ['entity', 'opentask', 'closedtask', 'system_task'] },
            ],
            multiple: true,
            valueField: 'id'
        },
        condition: 'in',
        sort: false
    },
    {
        field: 'relations.relatedEntity.id',
        type: 'relatedEntities',
        properties: {
            label: 'Related Entities',
            name: 'relatedEntities',
        },
        condition: 'in',
        sort: false,
    },
    {
        field: 'type',
        type: 'typeahead',
        properties: {
            label: 'Task Status',
            name: 'statusType',
            options: [
                { value: null, label: 'Any' },
                { value: 'opentask', label: 'Open' },
                { value: 'closedtask', label: 'Closed' }
            ],
            onChange: function onChange(event, ...args) {
                const value = event.target.value;
                if(value !== 'closedtask') {
                    return [
                        event.target,
                        { name: 'taskClosedDate', value: null }
                    ];
                }
                return event.target;
            },
        },
        sort: false,
        condition: '='
    },
    {
        field: 'primary.priority',
        type: 'typeahead',
        properties: {
            label: 'Priority',
            name: 'priority',
            options: PRIORITY_FILTER_OPTIONS,
            valueField: 'value',
        },
        condition: '='
    },
    {
        field: 'process.processDefinition.id',
        type: 'processTypeTypeahead',
        properties: {
            label: 'Process Types',
            name: 'processDefinitionName',
            multiple: true,
            filterBy: [{ field: 'active', op: '=', value: true }],
        },
        condition: 'in'
    },
    {
        field: 'process.primary.variables.INITIATOR',
        type: 'userTypeahead',
        properties: {
            label: 'Process started by',
            name: 'processInitiator',
            multiple: true,
            valueField: 'id'
        },
        sort:false,
        condition: 'in'
    },
    {
        field: 'primary.startDate',
        type: 'typeahead',
        properties: {
            label: 'Is start date set?',
            name: 'primaryStartDate',
            options: [
                { value: null, label: 'Any' },
                { value: 'is not null', label: 'Yes' },
                { value: 'is null', label: 'No' },
            ],
            valueField: 'value',
            onChange: (event, ...args) => {
                const value = event.target.value;
                if(value && value === 'is null') {
                    return [
                        { name: 'primaryStartDate', value },
                        { name: 'primaryStartDateTime', value: null }
                    ];
                } else {
                    return { name: 'primaryStartDate', value };
                }
            },
        },
        sort: false,
    },
    {
        field: 'primary.startDate',
        type: 'dateTimeRange',
        properties: {
            label: 'Start date',
            name: 'primaryStartDateTime',
            disabled: data => get(data, 'primaryStartDate.exclude')
        }
    },
    {
        field: 'primary.dueDate',
        type: 'typeahead',
        properties: {
            label: 'Is due date set?',
            name: 'dueDateNull',
            options: [
                { value: null, label: 'Any' },
                { value: 'is not null', label: 'Yes' },
                { value: 'is null', label: 'No' },
            ],
            valueField: 'value',
            onChange: function onChange(event, ...args) {
                const value = event.target.value;
                if(value && value === 'is null') {
                    return [
                        { name: 'dueDateNull', value },
                        { name: 'dueDate', value: null }
                    ];
                } else {
                    return { name: 'dueDateNull', value };
                }
            },
        },
        sort: false,
    },
    {
        field: 'primary.dueDate',
        type: 'dateTimeRange',
        properties: {
            label: 'Due date',
            name: 'dueDate',
            disabled: data => get(data, 'dueDateNull') === 'is null'
        }
    },
    { field: 'modifiedBy',
        type: 'userTypeahead',
        properties: { 
            label: 'Modified by',
            name: 'modifiedById', 
            multiple: true, 
            valueField: 'id' 
        }, 
        condition: 'in',
        filters: false
    },
    { field: 'createdBy',
        type: 'userTypeahead',
        properties: { 
            label: 'Created by', 
            name: 'createdById', 
            multiple: true, 
            valueField: 'id' 
        }, 
        condition: 'in',
        filters: false
    },
    {
        field: 'modifiedDate',
        type: 'dateTimeRange',
        properties: {
            label: 'Last updated',
            name: 'taskStatusLastUpdate'
        }
    },
    {
        field: 'createdDate',
        type: 'dateTimeRange',
        properties: {
            label: 'Created date',
            name: 'createdDate'
        }
    },
    {
        field: 'primary.closedDate',
        type: 'dateTimeRange',
        properties: {
            label: 'Closed date',
            name: 'taskClosedDate',
            onChange: function onChange(event, ...args) {
                return [event.target, { name: 'statusType', value: 'closedtask' }];
            },
        }
    }

];

export const defaultFilters = { statusType: 'opentask', active: true, dueDateNull: null, primaryStartDate: null, assigneeIdNull: null, priority: null };

export const defaultOrder = [{ field: 'modifiedDate', direction: 'desc nulls last' }];
/**
 * View to display assigned task list
 */
class TasksView extends PureComponent<Object, Object> {

    /**
     * @const propTypes - describes the properties of the component
     * @const defaultProps - define the defaults values of the properties
     * @const filterDefinitions -definition for columns that we need to display in our grid
     */
    static propTypes = {
        isLoading: PropTypes.bool,
        records: PropTypes.array,
        startIndex: PropTypes.number,
        totalRecords: PropTypes.number,
        userProfile: PropTypes.object,
        details: PropTypes.object,
        detailsLoading: PropTypes.bool,
    };

    static defaultProps = {
        isLoading: false,
        VirtualListProps: {},
        FiltersProps: {},
        defaultView: 'view-list',
        views: ['view-list', 'view-cards']
    };

    state = { deleted: {}, feFilters: [], isSidebarOpen: false, key: 1 };

    virtualListRef = React.createRef();

    breadcrumb = [{ title: 'Tasks' }];
    searchBar = ['name', 'id'];
    defaultFilters = defaultFilters;
    defaultOrder = defaultOrder;

    filterDefinitions = filterDefinitions

    @bind
    openSlidebar() {
        this.setState({ isSidebarOpen: true });
    };

    @bind
    closeSlidebar() {
        this.setState({ isSidebarOpen: false });
    };

    @bind
    handleDelete(id) {
        this.setState({ deleted : set(this.state.deleted, id, true) }, this.forceUpdateGrid);
    };

    @bind
    renderComponent({ style, index, data }: Object) {
        const { deleted } = this.state;
        const { noActions } = this.props;
        const id = data && data.id;
        return (
            <div style={style} key={index}>
                <TasksViewItem
                    data={data}
                    noActions={noActions}
                    resetView={this.resetView}
                    isDeleted={id && deleted[id]}
                    handleDelete={this.handleDelete}
                    openSlidebar={this.openSlidebar}
                />
            </div>
        );
    };

    @bind
    resetView() {
        this.virtualListRef.current && this.virtualListRef.current.resetView();
    };

    @bind
    forceUpdateGrid() {
        this.virtualListRef.current && this.virtualListRef.current.forceUpdate();
    };

    @bind
    loadData(options) {
        const formattedOptions = formatOptionsForTask(options, this.props.userProfile);
        return this.props.loadData(formattedOptions);
    }

    @bind
    reloadList() {
        this.setState(prevState => ({ key: prevState.key + 1 }));
    }

    render() {
        const {
            renderComponent, FiltersProps, records, isLoading, startIndex,
            VirtualListProps, className, totalRecords, title
        } = this.props;
        const { key } = this.state;
        return (
            <>
                <FiltersStyled
                    filterDefinitions={this.filterDefinitions}
                    searchBar={this.searchBar}
                    defaultFilters={this.defaultFilters}
                    defaultOrder={this.defaultOrder}
                    className={className}
                    breadcrumb={this.breadcrumb}
                    {...FiltersProps}
                >
                    {(filterBy, orderBy, excludeBy) => (
                        <VirtualListManaged
                            key={key}
                            itemCount={totalRecords}
                            itemSize={80}
                            isLoading={isLoading}
                            startIndex={startIndex || 0}
                            list={records}
                            maxWidth="1024"
                            loadData={this.loadData}
                            filterBy={filterBy}
                            excludeBy={excludeBy}
                            orderBy={orderBy}
                            title={`${totalRecords >= 1000 ? '999+' : totalRecords } ${title || 'Tasks'}`}
                            renderComponent={renderComponent || this.renderComponent}
                            ref={this.virtualListRef}
                            {...VirtualListProps}
                        />
                    )}
                </FiltersStyled>
            </>
        );
    }
}

export default connect((state, ownProps) => ({
    userProfile: state.user.profile
}))(TasksView);
