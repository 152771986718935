import React from 'react';

import Help from 'app/utils/designer/form/settings/common/Help';
import { dateFormats } from 'app/utils/date/date';


export const name = {
    type: 'text',
    properties: {
        label: 'Reference',
        name: 'properties.name',
        help: <Help message="Unique reference of the field" />,
    },
    constraints: { required: true },
};

export const label = {
    type: 'text',
    properties: {
        label: 'Label',
        name: 'properties.label',
        help: <Help message={
            `Defines the label of the component.
To define it you can use a simple string or an expression.
The expression must be in the form: \`{{path:modifier}}\`.

The path can starts with:
* **properties**, to serach the value in the properties
* **data**, to serach the value in the data
* **context**, to search the value in the context

The modifier is optional and you can choose one of the following:
* **upper**, to convert the value to uppercase
* **lower**, to convert the value to lowercase
* **capital**, to capitalize the first letter of the value`} />,
    },
    constraints: { required: true }
};

export const description = {
    type: 'text',
    properties: {
        label: 'Description',
        name: 'properties.description',
        help: <Help message={
            `Defines the description of the field.
To define it you can use a simple string or an expression.
The expression must be in the form: \`{{path:modifier}}\`.

The path can starts with:
* **properties**, to serach the value in the properties
* **data**, to serach the value in the data
* **context**, to search the value in the context

The modifier is optional and you can choose one of the following:
* **upper**, to convert the value to uppercase
* **lower**, to convert the value to lowercase
* **capital**, to capitalize the first letter of the value`} />,
    },
};

export const header = {
    type: 'text',
    properties: {
        label: 'Header',
        name: 'properties.header',
        help: <Help message={
            `Defines the header of the component.
To define it you can use a simple string or an expression.
The expression must be in the form: \`{{path:modifier}}\`.

The path can starts with:
* **properties**, to serach the value in the properties
* **data**, to serach the value in the data
* **context**, to search the value in the context

The modifier is optional and you can choose one of the following:
* **upper**, to convert the value to uppercase
* **lower**, to convert the value to lowercase
* **capital**, to capitalize the first letter of the value`} />
    }
};

export const placeholder = {
    type: 'text',
    properties: {
        label: 'Placeholder',
        name: 'properties.placeholder',
        help: <Help message="Placeholder for the field" />
    }
};

export const clearable = {
    type: 'boolean',
    properties: {
        label: 'Clearable',
        name: 'properties.clearable',
        help: <Help message="Clearable or not property for the field" />
    }
};

export const randomized = {
    type: 'boolean',
    properties: {
        label: 'Randomized',
        name: 'properties.randomized',
        help: <Help message="Randomized or not value on load of component." />
    }
};

const formatOptions = withTime => dateFormats().map(f => ({ value: withTime ? `${f} HH:mm` : f, label: withTime ? `${f} HH:mm` : f }));
export const dateFormatFields = (withTime = true) => [
    {
        type: 'typeahead',
        properties: {
            label: 'Format predefined',
            name: 'properties.formatType',
            options: [{ value: 'custom', label: 'Custom' }, ...formatOptions(withTime)],
            onChange: evnt => [
                evnt.target,
                { name: 'properties.format', value: evnt.target.value === 'custom' ? null : evnt.target.value }
            ],
            disabled: data => data.properties.format && !formatOptions(withTime).find(op => op.value === data.properties.format),
        },
    },
    {
        type: 'text',
        properties: {
            label: 'Format manual',
            name: 'properties.format',
            isVisible: ({ properties }) =>
                properties && properties.formatType === 'custom',
            help: <Help message={`|     | Token | Output |
| --- | --- | --- |
| **Month** | M   | 1 2 ... 11 12 |
|     | MM  | 01 02 ... 11 12 |
|     | MMM | Jan Feb ... Nov Dec |
|     | MMMM | January February ... November December |
| **Year** | YY  | 70 71 ... 29 30 |
|     | YYYY | 1970 1971 ... 2029 2030 |
| **Hour** | H   | 0 1 ... 22 23 |
|     | HH  | 00 01 ... 22 23 |
|     | h   | 1 2 ... 11 12 |
|     | hh  | 01 02 ... 11 12 |
|     | k   | 1 2 ... 23 24 |
|     | kk  | 01 02 ... 23 24 |
| **Minute** | m   | 0 1 ... 58 59 |
|     | mm  | 00 01 ... 58 59 |
| **Second** | s   | 0 1 ... 58 59 |
|     | ss  | 00 01 ... 58 59 |
| **Day** | D   | 1 2 ... 30 31 |
|     | Do  | 1st 2nd ... 30th 31st |
|     | DD  | 01 02 ... 11 12 |
|     | DDD  | 111 112 ... 365 (day of the year) |`} />,

        },
    }
];
