// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { bind } from 'app/utils/decorators/decoratorUtils';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import { createEvent } from 'app/utils/http/event';
import { Checkbox } from '@mic3/platform-ui';
import { loadClassFormDefinition, enableRelationCunter, disableRelationCunter } from 'store/actions/maps/situationalAwarenessActions';
import { getLayerType, getOptions } from 'app/utils/maps/layer/layerUtils';
import { loadRelationDefinitionDetails } from 'store/actions/entities/relationshipsActions';
import { buildClassChildrens } from 'app/containers/LeftPanels/EntityLeftPanel';
import { get, set } from 'app/utils/lo/lo';
import styled from 'styled-components';
import { isEmpty } from 'app/utils/utils';


const StyledCheckbox = styled(Checkbox)`
    & .MuiTypography-body1 {
        font-size: 12px !important;
        } 
     margin-left: 3px !important;
`;


const getStraightArray = (arr, straightArray) => {
    return arr.forEach((cls) => {
        straightArray.push({...cls, children: null });
        if(cls?.children?.length){
            return  getStraightArray(cls?.children, straightArray);
        }
    });
};

export const getLayerRelationFormData = (details) => {
    const relations  = details?.related_entities || {};
    return { relations }; 
};

class EntityLayerRelations extends PureComponent<Object, Object> {

    static propTypes: Object = {
        data: PropTypes.object,
        loadRelationDefinitionDetails: PropTypes.func.isRequired,
    };

    constructor(props: Object) {
        super(props);
        
        this.state = {
            subTypeOptions: [],
            numberAttrOptions: [],
            colorAttrOptions: [],
            relatedNumOptions: [],
            relatedColOptions:[],
        };
        this.setOptions(this.getClassId(), false);
    }

    formRef = React.createRef();

    async setOptions(classId, isLine) {
        if(isEmpty(classId)) {
            return [];
        }

        const classFields = await this.getClassFields(classId);
        const numberAttrOptions = getOptions(classFields, 'number');
        const colorAttrOptions = getOptions(classFields, 'colorPicker');
        if(isLine)
            this.setState({ 
                numberAttrOptions : [...this.state.numberAttrOptions, ...numberAttrOptions], 
                colorAttrOptions: [...this.state.colorAttrOptions, ...colorAttrOptions ]
            });
        else
            this.setState({ relatedNumOptions: numberAttrOptions, relatedColOptions: colorAttrOptions });

    }

    getClassId(){
        const { related_entities } = this.props?.data;
        if (isEmpty(related_entities)) return {};
        const { related_entities_th } = this.props?.formData?.relations || false;
        const classId = related_entities_th?.classes?.[0]?.id || related_entities?.related_entities_th?.classes?.[0]?.id;
        if(classId)
            return classId;
    }

    @bind
    async getClassFields(classId) {

        if(!classId) {
            return [];
        }
        try {
            const { data } = await loadClassFormDefinition(classId);
            return get(data, 'result.formDefinition.fields', []);
        }
        catch(e) {
            // eslint-disable-next-line no-console
            console.log('Unable to load class data', e);
        }
    }

    async componentDidUpdate(prevProps: Object) {
        const { formData } = this.props;
        const { relations } = formData || {};
        const { relatedType, related_entities_th } = relations || {};
        const classData = this.props.entityTypes.find(cls => cls.uri === relatedType);

        const previousId = prevProps.formData?.relations?.related_entities_th?.id;
        if (relatedType && related_entities_th?.id) {
            if (previousId !== related_entities_th?.id) {
                const relationLineClass = await this.props.loadRelationDefinitionDetails(related_entities_th?.id);
                this.setState({ numberAttrOptions: [], colorAttrOptions: []});
                relationLineClass.classes.forEach((cls) =>{
                    const relationshipClassId = cls?.id;
                    relationshipClassId &&  this.setOptions(relationshipClassId, true);
                });
                this.setOptions(classData?.id, false);
            }
        }
        if (relatedType && prevProps.formData?.relations?.relatedType !== relatedType) {
            this.getClasses(relatedType);
        }
    }

    @bind
    async getClasses(relatedType) {
        if(relatedType){
            const { entityTypes } = this.props;
            const classesChildren = await buildClassChildrens(entityTypes);
            const straightArray = [];
            const relatedClass = classesChildren.find(cls => cls.uri === relatedType);
            relatedClass?.children?.length && await getStraightArray(relatedClass.children, straightArray);
            const options = straightArray?.map((ch) => {
                return { 'value' : ch.uri, 'label': ch.name };
            });
            this.setState({ subTypeOptions: options });
        }
    }

    @bind
    buildFormDefinition(relations) {
        const { data, isLayerEntitiesHidden } = this.props;
        const layerType = getLayerType(data);
        const type = data?.[layerType]?.uri;
        const { relatedEntities, relatedType, showCounters } = relations || {};

        return [
            {
                field: `relatedEntities`,
                type: 'custom',
                properties: {
                    label: '',
                    name: `relatedEntities`,
                    disabled: isLayerEntitiesHidden,
                    Component: props => (<StyledCheckbox
                        {...props}
                        value={props.value}
                        onChange={props.onChange}
                        name="relatedEntities"
                        label="Draw connections to related entities"
                    />)
                }
            },
            {
                type: 'entityTypesTypeahead',
                properties: {
                    label: 'Select related entity type', 
                    name: 'relatedType',
                    valueField: 'uri',
                    fromType: type,
                    filterBy: [
                        {field: 'active', op: '=', value: true},
                    ],
                    disabled: ( !relatedEntities && !showCounters) || isLayerEntitiesHidden
                },
                constraints: { required: relatedEntities && !isLayerEntitiesHidden }
            },
            {
                type: 'relationDefinitionTypeahead',
                properties: {
                    label: 'Select relationship',
                    name: 'related_entities_th',
                    fromType: type,
                    toType: relatedType,
                    disabled: data => ( !data.relatedEntities && !showCounters ) || !data.relatedType
                },
                constraints: { required: relatedEntities }
            },
            {
                type: 'typeahead',
                properties: {
                    label: 'Select entity child type',
                    name: 'subType',
                    options:  this.state.subTypeOptions,
                    filterBy: [
                        {field: 'active', op: '=', value: true}
                    ],
                    disabled: data => !data.relatedEntities || !data.relatedType  || !data.related_entities_th,
                },
            },
            {
                type: 'panel',
                properties: {
                    header: 'Line Width',
                    expanded: true
                },
                children: [
                    {
                        type: 'typeahead',
                        properties: {
                            label: 'Line Width',
                            name: 'lineWidth',
                            options: [
                                {value: 'fixedLineWidth', label: 'Fixed'},
                                {value: 'selectLineWidthByAttribute', label: 'By Attribute'}
                            ],
                            disabled: !relatedEntities,
                            defaultValue: 'fixedLineWidth'
                        },
                    },
                    {
                        type: 'slider',
                        properties: {
                            label: 'Fixed line width',
                            name: 'valLineWidth',
                            fillColor: '#7391D0',
                            min: 1,
                            step: 1,
                            marks: true,
                            max: 4,
                            defaultValue: 2,
                            isVisible: data => data.lineWidth === 'fixedLineWidth',
                            disabled: !relatedEntities
                        },
                    },
                    {
                        type: 'typeahead',
                        properties: {
                            label: 'Line width by attribute',
                            name: 'valLineWidth',
                            options: this.state.numberAttrOptions,
                            isVisible: data => data.lineWidth && data.lineWidth !== 'fixedLineWidth'
                        },
                    },
                ]
            },
            {
                type: 'panel',
                properties: {
                    header: 'Line Colour',
                    expanded: true
                },
                children: [
                    {
                        type: 'typeahead',
                        properties: {
                            label: 'Line colour',
                            name: 'lineColour',
                            options: [
                                {value: 'fixedLineColour', label: 'Fixed'},
                                {value: 'selectLineColourByAttribute', label: 'By Attribute'}
                            ],
                            defaultValue: 'fixedLineColour',
                            disabled: !relatedEntities
                        },
                    },
                    {
                        field: 'iconColor',
                        type: 'colorPicker',
                        properties: {
                            label: 'Fixed Line Color',
                            name: 'valLineColour',
                            isVisible: data => data.lineColour === 'fixedLineColour',
                            disabled: !relatedEntities
                        }
                    },
                    {
                        type: 'typeahead',
                        properties: {
                            label: 'Colour by attribute',
                            name: 'valLineColour',
                            options: this.state.colorAttrOptions,
                            isVisible:  data => data.lineColour && data.lineColour  !== 'fixedLineColour',
                            disabled: !relatedEntities
                        },
                    }
                ]
            },
            {
                type: 'panel',
                properties: {
                    header: 'Line Style',
                    expanded: true
                },
                children: [
                    {
                        type: 'typeahead',
                        properties: {
                            label: 'Line style',
                            name: 'valLineStyle',
                            options: [
                                {value: 'dashed', label: 'Dashed Line'},
                                {value: 'solid', label: 'Solid Line'}
                            ],
                            defaultValue: 'solid',
                            disabled: !relatedEntities
                        },
                    }
                ]
            },
            {
                type: 'panel',
                properties: {
                    header: 'Point Size',
                    expanded: true
                },
                children: [
                    {
                        type: 'typeahead',
                        properties: {
                            label: 'Point size',
                            name: 'pointSize',
                            options: [
                                { value: 'fixedPointSize', label: 'Fixed'},
                                { value: 'selectPointSizeByAttribute', label: 'By Attribute'}
                            ],
                            defaultValue: 'fixedPointSize',
                            disabled: !relatedEntities
                        },
                    },
                    {
                        type: 'slider',
                        properties: {
                            label: 'Fixed point size',
                            name: 'valPointSize',
                            fillColor: '#7391D0',
                            min: 8,
                            step: 4,
                            marks: true,
                            max: 96,
                            defaultValue: 32,
                            isVisible:  data => data.pointSize && data.pointSize  === 'fixedPointSize',
                            disabled: !relatedEntities
                        },
                    },
                    {
                        type: 'typeahead',
                        properties: {
                            label: 'Point size by attribute',
                            name: 'valPointSize',
                            options: this.state.relatedNumOptions,
                            isVisible: data => data.pointSize && data.pointSize  !== 'fixedPointSize',
                            disabled: !relatedEntities
                        },
                    },
                ]
            },
            {
                field: 'iconName',
                type: 'iconSelect',
                properties: {
                    label: 'Entity Icon',
                    name: 'iconName',
                    valueField: 'value',
                    disabled: !relatedEntities
                }
            },
            {
                type: 'panel',
                properties: {
                    header: 'Fill Colour',
                    expanded: true
                },
                children: [
                    {
                        type: 'typeahead',
                        properties: {
                            label: 'Colour',
                            name: 'fillColour',
                            options: [
                                {value: 'fixedFillColour', label: 'Fixed'},
                                {value: 'selectFillColourByAttribute', label: 'By Attribute'}
                            ],
                            defaultValue: 'fixedFillColour',
                            disabled: !relatedEntities
                        },
                    },
                    {
                        field: 'iconColor',
                        type: 'colorPicker',
                        properties: {
                            label: 'Fixed colour',
                            name: 'valFillColour',
                            isVisible: data => data.fillColour  === 'fixedFillColour',
                            disabled: !relatedEntities
                        }
                    },
                    {
                        type: 'typeahead',
                        properties: {
                            label: 'Colour by Attribute',
                            name: 'valFillColour',
                            options: this.state.relatedColOptions,
                            isVisible: data => data.fillColour && data.fillColour  !== 'fixedFillColour',
                            disabled: !relatedEntities
                        },
                    }
                ]
            }
        ];
    }

    @bind
    async validateForm() {
        if (this.formRef && this.formRef.current) {
            const { errors } = await this.formRef.current.isValidForm();
            return { errors };
        } else {
            return { errors: null };
        }
    }

    @bind
    hasErrors() {
        return this.errors;
    }

    @bind
    handleChange(formData: Object, { name, value }: Object) {
        let data = { ...formData };    
        if(value && ['selectPointSizeByAttribute', 'selectFillColourByAttribute', 'relatedType'].includes(name)){
            if(name === 'relatedType') {
                data = set(data,'related_entities_th', null);
                data = set(data,'subType', null);
            }
            const classData = this.props.entityTypes.find(cls => cls.uri === value);
            classData?.id && this.setOptions(classData.id, false);
        }
        if(name === 'showCounters' && value) {
            this.props.enableRelationCunter();
        }
        if(name === 'showCounters' && !value) {
            this.props.disableRelationCunter();
        }
        if(data && !data?.relatedType) {
            data = set(data,'related_entities_th', null);
        }
        if (this.props.onChange) {
            const name = this.props.name;
            const event = createEvent('change', { name, value: data });
            this.props.onChange(event);
        }
        this.props.onFormChange({ relations : {...data} }, { name, value});

    }

    render() {
        const { formData } = this.props;
        const { relations } = formData || {};
        if(!relations) return null;

        return (
            <FormGenerator
                data={relations}
                name={this.props.name}
                onChange={this.handleChange}
                components={this.buildFormDefinition(relations)}
                ref={this.formRef}
            />
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    details: state.entities.sidebar.details.data || {},
    entityTypes: state.app.allPrimaryClasses.records || [],
    styleHideLayers: state.maps.situationalAwareness.layer.styleHideLayers,
    isLayerEntitiesHidden: state.maps.situationalAwareness.map.layerEntitiesHidden[ownProps?.data?.id] || false,


});

export default connect(mapStateToProps, {
    loadRelationDefinitionDetails,
    enableRelationCunter,
    disableRelationCunter,
}, null, { forwardRef: true })(EntityLayerRelations);
