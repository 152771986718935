import { gql } from '@apollo/client';

export default (type, fields) => {
    const primary = fields?.length ? `primary(fields: [${fields.map(field => `"${field}"`).join(', ')}])` : ''; // Only those primary attributes that are needed would be fetched from backend
    return gql`
        query entituesPinsQuery($type: String!,$filterBy: [JSON]) {
            records: entities(type: $type, filterBy: $filterBy) {
                id # for opening pin detail, we need id and type
                name # when user hover on pin it shows name and description
                type # for opening pin detail, we need id and type
                description # when user hover on pin it shows name and description
                iconName # name of the icon for pin or default icon name marker
                iconColor # color of pin 
                iconType # icon type which is mdi or af
                ${primary}
                primaryClass { # we need data from primary class of each pin
                    entitySvg # if svg is uploaded on class we display is by default for a pin
                    entityGraphic { # if svg is selected from gallery on class we display is by default for a pin
                        id type  # we find svg from gallery using its id and type
                    }
                    entitySvgVisibleZoom # we can set zoom level on class on which pin will be changed svg
                    entityModel3d # any 3D model uploaded on a class will be shown when user switch from 2D to 3D
                    entityModelHeading # 3D model direction
                    entityModelScaling # model scale which user can set
                } 
                svg # svg uploaded on entity from entity location
                svgGraphic { # svg selected from gallery via entity locationn section
                    id type 
                }
                model3d # 3D model uploaded on entity from entity location section
                modelHeading # model heading set by user from entity location section 
                modelScaling # model scale set by user from entity location section
                _wkt # geometry 
            }
        }
    `;
};