/* @flow */

import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Menu, MenuItem, Grid, MdiIcon, IconButton, Tooltip, Divider, List, ListSubheader, Typography } from '@mic3/platform-ui';
import styled from 'styled-components';
import { muiTheme } from 'app/themes/materialUi';
import withRouterAndRef from 'app/utils/hoc/withRouterRef';
import Icon from 'app/components/atoms/Icon/Icon';
import Filters from 'app/components/organisms/Filters/Filters';
import VirtualListManaged from 'app/components/molecules/VirtualList/VirtualListManaged';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { set, get } from 'app/utils/lo/lo';
import ButtonIcon from 'app/components/molecules/ButtonIcon/ButtonIcon';
import GridView from 'app/components/organisms//GridView/GridView';
import EntitiesListCardsItem from './EntitiesListCardsItem';
import EntitiesListItem from './EntitiesListItem';
import TypeIcon from 'app/components/atoms/TypeIcon/TypeIcon';
import { typeTitlesSingle } from 'app/config/typesConfig';
import Immutable from 'app/utils/immutable/Immutable';
import AddRelationDefinition from 'app/containers/Entities/RelationDefinitions/AddRelationDefinition';
import CreateBroadcast from 'app/containers/Broadcasts/CreateBroadcast';
import AddEntity from 'app/containers/Entities/EntitiesList/AddEntity'; // WIP: Testing add entity
import AddClassification from 'app/containers/Classifications/AddClassification';
import AddPipeline from 'app/components/Designer/Modals/AddPipeline';
import AddBackgroundJob from 'app/components/Designer/Modals/AddBackgroundJob';
import AddConnector from 'app/components/Designer/Modals/AddConnector';
import AddModule from 'app/components/Designer/Modals/AddModule';
import UserAdd from 'app/containers/Admin/UserManagement/UserAdd';
import AddGraphic from 'app/containers/Entities/GraphicsLibrary/AddGraphic';
import AddWorkspace from 'app/components/Admin/Modals/AddWorkspace';
import AddTeam from 'app/components/Admin/Modals/AddTeam';
import AddPrintTemplate from 'app/containers/Entities/PrintTemplates/AddPrintTemplate';
import AddEventType from 'app/containers/Stream/Events/EventTypes/AddEventType';
import DotMenu from 'app/components/molecules/DotMenu/DotMenu';
import DownloadList from 'app/containers/DownloadList/DownloadList';
import UploadDataModal from 'app/containers/UploadDataModal/UploadDataModal';
import StartPipeline from 'app/components/Designer/Modals/StartPipeline';
import { saveComponentState } from 'store/actions/component/componentActions';
import { saveUserPreferences } from 'store/actions/admin/usersActions';
import { addPermissions } from 'app/utils/withPermissions';
import { buildDotMenu } from 'app/utils/entity/entityUtils';
import { findAttribute } from 'app/utils/classification/classificationUtils';
import { buildCustomDefinitions } from 'app/utils/classification/classificationUtils';
import { getCustomFields } from 'app/utils/classification/classificationUtils';
import { loadEntityPrintTemplates } from 'store/actions/entities/printTemplateActions';
import { loadClassificationTaskAndProcess } from 'store/actions/classifications/classificationsActions';
import { loadPrintTemplatesListView } from 'store/actions/entities/printTemplateActions';
import history from 'store/History';
import { openTableColumnsSidebar } from 'store/actions/entities/common/tableColumnsSidebarActions';
import { getSelectedPrimaryClass } from 'app/utils/classification/classificationUtils';
import Loader from 'app/components/atoms/Loader/Loader';

const FiltersStyled = styled(Filters)`
    padding: 0 8px;
    & .MuiTypography-colorTextPrimary{
        color: ${({ theme }) => theme.material.colors.text.primary};
    }
`;

const SpanStyled = styled.span`
    line-height: 18px;
`;
const MenuStyled = styled(Menu)`
    & .MuiPaper-root {
        background: ${({ theme }) => theme.material.colors.background.menu};
    }
`;
const TypographyStyled = styled(Typography)`
    font-weight: bold;
`;

class EntitiesList extends PureComponent<Object, Object> {
    static propTypes = {
        pageViewId: PropTypes.string,
        id: PropTypes.string.isRequired,
        loadData: PropTypes.func.isRequired,
        records: PropTypes.array,
        isLoading: PropTypes.bool,
        isPrintReports: PropTypes.bool,
        entityType: PropTypes.bool,
        totalRecords: PropTypes.number,
        disableSettings: PropTypes.bool,
        enableCountdown: PropTypes.bool,
        canAdd: PropTypes.bool,
        entitiesView: PropTypes.bool, // we would remove it when we finalized primary classes
        goToDetails: PropTypes.func
    };

    static defaultProps = {
        isLoading: false,
        canAdd: false,
        entityType: false,
        disableSettings: false,
        VirtualListProps: {},
        VirtualListCardsProps: {},
        FiltersProps: {},
        defaultView: 'view-list',
        downloadable: false,
        views: ['view-list', 'view-cards', 'view-table', 'view-board']
    };

    columnSidebarKey = 1;

    state = Immutable({
        updateKey: 1,
        isSettingsMenuOpen: false,
        isAddModalOpen: false,
        selectedItem: null,
        selectedClass: getSelectedPrimaryClass(this.props.type, this.props.primaryClasses),
        isDownloadModalOpen: false,
        anchorEl: null,
        isOpen: false,
        entityName: null,
        entityId: null,
        isOnLoadSave: false,
        storedType: null
    });

    printTemplatesMap = null;
    menuSettingsRef = React.createRef();
    listRef = React.createRef();
    filtersRef = React.createRef();
    searchBar = this.props.searchBar || ['id', 'name'];
    defaultFilters = this.props.defaultFilters || { active: null };
    defaultOrder = this.props.defaultOrder || [{ field: 'modifiedDate', direction: 'desc nulls last' }];

    constructor(props) {
        super(props);
        const selectedClass = getSelectedPrimaryClass(props?.type, props?.primaryClasses);
        if(selectedClass && props.type !== 'print-template'){
            props.loadEntityPrintTemplates(selectedClass?.id);
        }

        if(props.filterViewState?.columns) {
            const nextViewState = set(props.filterViewState, 'columns', null);
            props.saveComponentState(props.id, nextViewState);
        }

    }

    componentDidUpdate(prevProps, prevState) {
        const { sidebarIsOpen, primaryClasses, type, tasksAndProcesses } = this.props;

        if(type.includes('task') || type.includes('process')){
            if(type !== this.state.storedType){
                this.props.loadClassificationTaskAndProcess();
                this.props.loadPrintTemplatesListView();
                this.setState({
                    storedType: type
                });
            }
        }

        if(type === 'task' || type === 'process') {
            this.buildPrintTemplateMap(tasksAndProcesses || []);
        }

        if (sidebarIsOpen !== prevProps.sidebarIsOpen && !sidebarIsOpen) {
            this.setState({ selectedItem: null });
        }
        if (type && primaryClasses?.length) {
            const selectedClass = getSelectedPrimaryClass(type, primaryClasses);
            if (selectedClass?.id && this.state.selectedClass?.id !== selectedClass?.id) {
                this.setState({ selectedClass });
                this.props.loadEntityPrintTemplates(selectedClass?.id);
            }
        }
    }

    @bind
    @memoize()
    buildPrintTemplateMap(tasksAndProcesses) {
        const { type } = this.props;
        if (!tasksAndProcesses?.records?.length) return null;
        const templateClasses = tasksAndProcesses.records.filter(clss => clss?.uri?.includes(type));
        if (!templateClasses?.length) return null;
        this.printTemplatesMap = templateClasses?.reduce((accum, clss) => {
            accum[clss.uri] = clss.id;
            return accum;
        }, {});
    }

    @bind
    @memoize()
    getCustomFields(classData) {
        return getCustomFields(classData);
    }

    @bind
    @memoize()
    buildCustomColumns(classData) {
        const fields = this.getCustomFields(classData);
        return fields.map(({ primaryAttribute, type }) => {
            const text = (findAttribute(classData?.formDefinition, primaryAttribute) || {}).properties?.label;
            return { text, field: `primary.${primaryAttribute}`, dataType: type };
        });
    }

    @bind
    @memoize()
    buildCustomDefinitions(classData) {
        return buildCustomDefinitions(classData);
    }

    @bind
    resetView() {
        const { resetView } = this.listRef.current || {};
        resetView && resetView();
    }

    @bind
    toggleMenu(event) {
        this.setState({ anchorEl: event.currentTarget });
    };

    @bind
    handleClose() {
        this.setState({ anchorEl: null });
    };

    @bind
    @memoize()
    buildDotMenu(entityType, details, moreActionItems, entityTemplates, entityTypePage, printTemplateRecords, printTemplatesMap, isPrintReports, userId) {
        return buildDotMenu({
            isPrintReports,
            details,
            entityType,
            moreActionItems,
            indexView: true,
            entityTemplates,
            entityTypePage,
            printTemplateRecords,
            printTemplatesMap,
            userId });
    }

    @bind
    toggleSettingsMenu() {
        this.setState(state => ({ isSettingsMenuOpen: !state.isSettingsMenuOpen }));
    }

    @bind
    toggleColumns() {
        const { id, columnDefinitions } = this.props;
        const { selectedClass } = this.state;
        const key = this.columnSidebarKey + 1;
        this.columnSidebarKey = key;
        const props = {
            title: 'Columns',
            id,
            columnDefinitions,
            customColumns: this.props.customColumns || this.buildCustomColumns(selectedClass),
            key
        };
        this.toggleSettingsMenu();
        this.props.openTableColumnsSidebar(props);
    }

    @bind
    toggleAddModal() {
        this.setState(state => ({ isAddModalOpen: !state.isAddModalOpen }));
    }

    @bind
    toggleStartApp(id) {
        return () => history.push(`/abox/process-start/${id}`);
    }
    @bind
    toggleAllApps(id) {
        return () => history.push(`/abox/processes-new`);
    }

    @bind
    toggleUploadDataModal() {
        this.setState(state => ({ isUploadDataOpen: !state.isUploadDataOpen }));
    }

    @bind
    async handleAddClick(title) {
        const { downloadable } = this.props;
        if (downloadable && title === 'Download data') {
            return this.setState({ isDownloadModalOpen: true });
        }
        this.toggleAddModal();
    }

    @bind
    loadData(isShort = false) {
        const { loadData } = this.props;
        return (options) => {
            return loadData({ isShort, options });
        };
    }

    @bind
    menuSettingsChange(settingsView) {
        return () => {
            const { saveUserPreferences, userPreferences, id: VIEW_ID, filterViewState, pageViewId } = this.props;
            const nextUserPreferences = set(userPreferences, pageViewId || `pageView.${VIEW_ID}`, settingsView);
            const alreadySavedView = get(userPreferences, pageViewId || `pageView.${VIEW_ID}`, this.props.defaultView);
            this.setState({ isSettingsMenuOpen: false }, async () => {
                if (settingsView === alreadySavedView) {
                    return;
                }
                await saveUserPreferences(nextUserPreferences);
                this.setState({ updateKey: this.state.updateKey + 1, selectedItem: null }, () => this.props.saveComponentState(this.props.id, filterViewState));
            });
        };
    }

    @bind
    @memoize()
    buildMobileMenu(isMenuOpen, view, views) {
        return (
            <MenuStyled open={isMenuOpen} anchorEl={this.menuSettingsRef.current} onClose={this.toggleSettingsMenu}>
                <List
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                            Views
                        </ListSubheader>
                    }
                >
                    {views.includes('view-cards') && (
                        <MenuItem key={3} onClick={this.menuSettingsChange('view-cards')}>
                            <Grid container justify="space-between">
                                <ButtonIcon icon="view-agenda" label="Cards" />
                                <ButtonIcon icon={view === 'view-cards' ? 'radiobox-marked' : 'radiobox-blank'} />
                            </Grid>
                        </MenuItem>
                    )}
                    {views.includes('view-list') && (
                        <MenuItem key={2} onClick={this.menuSettingsChange('view-list')}>
                            <Grid container justify="space-between">
                                <ButtonIcon icon="view-headline" label="List" />
                                <ButtonIcon icon={view === 'view-list' ? 'radiobox-marked' : 'radiobox-blank'} />
                            </Grid>
                        </MenuItem>
                    )}
                    {views.includes('view-table') && (
                        <MenuItem key={1} onClick={this.menuSettingsChange('view-table')}>
                            <Grid container justify="space-between">
                                <ButtonIcon icon="view-comfy" label="Table" />
                                <ButtonIcon icon={view === 'view-table' ? 'radiobox-marked' : 'radiobox-blank'} />
                            </Grid>
                        </MenuItem>
                    )}
                </List>
                <Divider />
                {view === 'view-table' && (
                    <List
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        subheader={
                            <ListSubheader component="div" id="nested-list-subheader">
                                Table options
                            </ListSubheader>
                        }
                    >
                        <MenuItem key={1} onClick={this.toggleColumns}>
                            <Grid container justify="space-between">
                                <ButtonIcon icon="view-column" label="Show & hide columns..." />
                            </Grid>
                        </MenuItem>
                    </List>
                )}
            </MenuStyled>
        );
    }

    @bind
    handleSelectedItem(data) {
        const { idField } = this.props;
        this.setState({ selectedItem: data?.[idField] || data?.id });
    }

    @bind
    renderListComponent(props: Object) {
        return (
            <EntitiesListItem
                userId={this.props.userProfile?.id}
                isPrintReports={this.props.isPrintReports}
                entityType={this.props.entityType}
                pipelineMonitor={this.props.pipelineMonitor}
                sidebarTitle={this.props.sidebarTitle}
                key={props.index}
                idField={this.props.idField}
                type={this.props.type}
                selectedItem={this.state.selectedItem}
                onSelect={this.handleSelectedItem}
                reloadList={this.resetView}
                moreActionItems={this.props.moreActionItems}
                entitiesView={this.props.entitiesView}
                goToDetails={this.props.goToDetails}
                adminBackgroundJobs={this.props.adminBackgroundJobs}
                dotMenu={this.buildDotMenu(
                    this.props.type,
                    props.data,
                    this.props.moreActionItems,
                    this.props.entityTemplates,
                    this.props.entityType,
                    this.props.printTemplateRecords,
                    this.printTemplatesMap,
                    this.props.isPrintReports,
                    this.props.userProfile?.id)}
                {...props}
            />
        );
    }

    @bind
    onCloseDownloadModal() {
        this.setState({ isDownloadModalOpen: false });
    }
    @bind
    renderCardsComponent(props: Object) {
        return (
            <EntitiesListCardsItem
                userId={this.props.userProfile?.id}
                isPrintReports={this.props.isPrintReports}
                entityType={this.props.entityType}
                pipelineMonitor={this.props.pipelineMonitor}
                sidebarTitle={this.props.sidebarTitle}
                key={props.index}
                type={this.props.type}
                selectedItem={this.state.selectedItem}
                onSelect={this.handleSelectedItem}
                reloadList={this.resetView}
                moreActionItems={this.props.moreActionItems}
                entitiesView={this.props.entitiesView}
                goToDetails={this.props.goToDetails}
                module={this.props.module}
                adminBackgroundJobs={this.props.adminBackgroundJobs}
                dotMenu={this.buildDotMenu(
                    this.props.type,
                    props.data,
                    this.props.moreActionItems,
                    this.props.entityTemplates,
                    this.props.entityType,
                    this.props.printTemplateRecords,
                    this.printTemplatesMap,
                    this.props.isPrintReports,
                    this.props.userProfile?.id)}
                {...props}
            />
        );
    }

    @bind
    @memoize()
    buildDownloadModal(isDownloadModalOpen, type, filterBy, orderBy, entityType) {
        if (entityType && type === 'class') {
            type = 'entityType';
        }
        return isDownloadModalOpen && <DownloadList onClose={this.onCloseDownloadModal} type={type} filterBy={filterBy} orderBy={orderBy} />;
    }

    @bind
    @memoize()
    parseIdAndNameOfDefinition(processDefinitionId, leftPanelRecords) {
        const { id: VIEW_ID } = this.props;
        if(VIEW_ID !== 'TaskList') {
            return {};
        }
        const selectedDef = (leftPanelRecords || []).find(def => def.id === processDefinitionId);
        return selectedDef || {};
    }

    @bind
    @memoize()
    renderAddModal(isAddModalOpen: boolean) {
        if (!isAddModalOpen) {
            return null;
        }
        const { type, entitiesView, primaryClasses, entityType, id } = this.props;
        if (entitiesView) {
            const selectedPrimaryClass = primaryClasses?.find(cls => cls?.uri === type);
            return <AddEntity onClose={this.toggleAddModal} defaultSelected={selectedPrimaryClass} />;
        }
        switch (type) {
            case 'relationdefinition':
                return <AddRelationDefinition onClose={this.toggleAddModal} />;
            case 'broadcast':
                return <CreateBroadcast onClose={this.toggleAddModal} reloadList={this.resetView} />;
            case 'class':
                return <AddClassification entityType={entityType} onClose={this.toggleAddModal} />;
            case 'user':
                return <UserAdd onClose={this.toggleAddModal} />;
            case 'graphic':
                return <AddGraphic reloadList={this.resetView} onClose={this.toggleAddModal} />;
            case 'workspace':
                return <AddWorkspace onClose={this.toggleAddModal} />;
            case 'team':
                return <AddTeam onClose={this.toggleAddModal} />;
            case 'eventtype':
                return <AddEventType reloadList={this.resetView} onClose={this.toggleAddModal} />;
            case 'print-template':
                return <AddPrintTemplate onClose={this.toggleAddModal} />;
            case 'iot_connector':
                return <AddConnector onClose={this.toggleAddModal} />;
            case 'iot_module':
                return <AddModule onClose={this.toggleAddModal} />;
            case 'iot_pipeline':
                if(id === 'PipelineMonitor') {
                    return <StartPipeline onClose={this.toggleAddModal} reloadList={this.resetView} />;
                }
                return <AddPipeline onClose={this.toggleAddModal} />;
            case 'backgroundjob':
                return <AddBackgroundJob onClose={this.toggleAddModal} />;
            default:
        }
    }

    @bind
    @memoize()
    renderUploadDataModal(isUploadDataOpen: boolean) {
        let { type } = this.props;
        const { entityType } = this.props;
        if (!isUploadDataOpen) {
            return null;
        }
        if (entityType && type === 'class') {
            type = 'entityType';
        }
        return <UploadDataModal onClose={this.toggleUploadDataModal} type={type} reloadList={this.resetView} />;
    }

    @bind
    @memoize()
    buildStartNewAppMenu(parsedDef, type) {
        const { id: defId, name: defName, iconName, iconType } = parsedDef;
        return <DotMenu
            icon="plus"
            tooltipTitle="Create new"
            key={13}
            padded
            items={[
                { name: 'header', text: 'Create new' },
                {
                    name: <> Start app <TypographyStyled variant="body">{defName}</TypographyStyled></>,
                    iconComponent: iconName 
                        ? <Icon type={iconType} name={iconName} size="sm" /> 
                        : <TypeIcon type={type} />,
                    onItemClick: this.toggleStartApp(defId)
                },
                {
                    name: `My apps`,
                    iconComponent: 
                        <Icon name={'dots-grid'} size="sm" /> ,
                    onItemClick: this.toggleAllApps()
                },
            ].filter(Boolean)}
        />;
    }

    @bind
    @memoize()
    buildBreadcrumb(headerTitle, type, location, classification) {
        const splitedPath = location?.pathname?.split('/') || [];
        if (splitedPath[1] === 'classifications' || splitedPath[1] === 'entity-types') {
            return {
                breadcrumb: [
                    classification && splitedPath[2] && { link: `/${splitedPath[1]}/${splitedPath[2]}`, title: classification?.name },
                    { title: headerTitle || typeTitlesSingle[type] }
                ].filter(Boolean)
            };
        }
        return {
            breadcrumb: [{ title: headerTitle || typeTitlesSingle[type] }]
        };
    }

    @bind
    buildView(id, view, filterBy, orderBy, VirtualListProps, VirtualListCardsProps,
        startIndex, isLoading, records, totalRecords, selectedItem, filters, entityType, pipelineMonitor) {
        const { headerTitle, type, columnDefinitions, isPrintReports, loadingPreferences } = this.props;
        if(loadingPreferences){
            return <Loader absolute />;
        }
        const virtualDefaultProps = {
            itemCount: totalRecords || 0,
            startIndex: startIndex || 0,
            list: records,
            loadData: this.loadData(),
            isLoading,
            filterBy,
            orderBy
        };
        switch (view) {
            case 'view-list':
                return (
                    <VirtualListManaged
                        {...virtualDefaultProps}
                        itemSize={71}
                        maxWidth="1024"
                        ref={this.listRef}
                        entityType={entityType}
                        pipelineMonitor={pipelineMonitor}
                        title={`${totalRecords >= 1000 ? '999+' : totalRecords} ${headerTitle}`}
                        renderComponent={this.props.renderListComponent || this.renderListComponent}
                        {...VirtualListProps}
                    />
                );
            case 'view-cards':
                return (
                    <VirtualListManaged
                        {...virtualDefaultProps}
                        entityType={entityType}
                        pipelineMonitor={pipelineMonitor}
                        itemSize={161}
                        ref={this.listRef}
                        maxWidth="1024"
                        title={`${totalRecords >= 1000 ? '999+' : totalRecords} ${headerTitle}`}
                        renderComponent={this.props.renderCardsComponent || this.renderCardsComponent}
                        {...VirtualListCardsProps}
                    />
                );
            case 'view-table':
                const filterColumns = (filters.columns || columnDefinitions).map(clmn => ({
                    ...columnDefinitions.find(clm => clm.field === clmn.field),
                    ...clmn
                }));
                const { selectedClass } = this.state;
                const customColumns = this.props.customColumns || this.buildCustomColumns(selectedClass);
                return (
                    <GridView
                        id={id}
                        type={type}
                        entityType={entityType}
                        pipelineMonitor={pipelineMonitor}
                        customColumns={customColumns}
                        columns={filterColumns}
                        orderBy={orderBy}
                        reloadList={this.resetView}
                        sidebarTitle={this.props.sidebarTitle}
                        entitiesView={this.props.entitiesView}
                        printTemplateRecords={this.props.printTemplateRecords}
                        ref={this.listRef}
                        isPrintReports={isPrintReports}
                        printTemplatesMap={this.printTemplatesMap}
                        adminBackgroundJobs={this.props.adminBackgroundJobs}
                        idField={this.props.idField}
                        {...virtualDefaultProps}
                    />
                );
            default:
                return null;
        }
    }

    render() {
        const {
            id: VIEW_ID,
            FiltersProps,
            VirtualListCardsProps,
            filterDefinitions,
            userPreferences,
            defaultView,
            VirtualListProps,
            startIndex,
            isLoading,
            records,
            totalRecords,
            type,
            disableSettings,
            views,
            disableAdd,
            rightToolbar,
            headerTitle,
            enableCountdown,
            location,
            classification,
            pageViewId,
            downloadable,
            entityType,
            uploadable,
            leftPanelRecords,
            match,
            pipelineMonitor,
            id,
            leftSideSearcLine
        } = this.props;
        const { selectedClass, isAddModalOpen, isUploadDataOpen } = this.state;
        const { permissions, isAdmin } = this.props.userProfile;
        const permissionsSet = new Set(permissions || []);
        const { isSettingsMenuOpen, updateKey, selectedItem, isDownloadModalOpen } = this.state;
        const view = get(userPreferences, pageViewId || `pageView.${VIEW_ID}`, defaultView);
        let filters = {};
        filters = get(userPreferences, `filters.${VIEW_ID}`, {});
        if(id === 'BackgroundJobList' || id === 'AdminBackgroundJobList'){
            filters = get(userPreferences, `${VIEW_ID}`, {});
        }
        const canAdd = !disableAdd && (this.props.canAdd || isAdmin || permissionsSet.has(addPermissions[type]));
        const userDefinedFilters = this.buildCustomDefinitions(selectedClass);
        const parsedDef = this.parseIdAndNameOfDefinition(match?.params?.id, leftPanelRecords);
        
        return (
            <>
                <FiltersStyled
                    key={updateKey}
                    id={VIEW_ID}
                    ref={this.filtersRef}
                    filterDefinitions={[...filterDefinitions, ...userDefinedFilters]}
                    userDefinedFilters={userDefinedFilters}
                    defaultOrder={this.defaultOrder}
                    searchBar={[...this.searchBar, type === 'thing' ? 'primary.serialCode': ''].filter(Boolean)}
                    searchHelperText={this.props.searchHelperText}
                    defaultFilters={this.defaultFilters}
                    enableCountdown={enableCountdown}
                    leftSideSearcLine={leftSideSearcLine}
                    {...this.buildBreadcrumb(headerTitle, type, location, classification)}
                    {...FiltersProps}
                    rightToolbar={<>{rightToolbar}</>}
                    moreIcons={[
                        <Fragment key={1}>
                            {canAdd && (
                                <DotMenu
                                    icon="plus"
                                    tooltipTitle="Create new"
                                    key={13}
                                    padded
                                    onItemClick={this.handleAddClick}
                                    items={[
                                        { name: 'header', text: 'Create new' },
                                        {
                                            name: entityType ? 'Entity Type' : selectedClass?.name || typeTitlesSingle[type] || 'Entity',
                                            iconComponent: <TypeIcon type={entityType ? 'entityType' : type} />,
                                            onClick: this.toggleAddModal
                                        },
                                        { name: 'divider'},
                                        uploadable && { name: 'Upload data', iconComponent: <Icon size="sm" name="upload" />, onItemClick: this.toggleUploadDataModal },
                                    ].filter(Boolean)}
                                />
                            )}
                            {downloadable && (
                                <Tooltip title="Download data">
                                    <IconButton onClick={() => this.handleAddClick('Download data')}>
                                        <MdiIcon name="download" color={muiTheme.colors.appHeader.iconColor} />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {parsedDef.id && this.buildStartNewAppMenu(parsedDef, type)}
                            {!disableSettings && (
                                <Tooltip title="View Settings">
                                    <IconButton onClick={this.toggleSettingsMenu}>
                                        <SpanStyled ref={this.menuSettingsRef}>
                                            <MdiIcon name="cog" color={muiTheme.colors.appHeader.iconColor} />
                                        </SpanStyled>
                                    </IconButton>
                                </Tooltip>
                            )}
                            {this.buildMobileMenu(isSettingsMenuOpen, view, views)}
                        </Fragment>
                    ]}
                >
                    {(filterBy, orderBy) => (
                        <>
                            {this.buildView(
                                VIEW_ID,
                                view,
                                filterBy,
                                orderBy,
                                VirtualListProps,
                                VirtualListCardsProps,
                                startIndex,
                                isLoading,
                                records,
                                totalRecords,
                                selectedItem,
                                filters,
                                entityType,
                                pipelineMonitor
                            )}
                            {this.buildDownloadModal(isDownloadModalOpen, type, filterBy, orderBy, entityType)}
                        </>
                    )}
                </FiltersStyled>
                {this.renderAddModal(isAddModalOpen)}
                {this.renderUploadDataModal(isUploadDataOpen)}
            </>
        );
    }
}

export default connect(
    (state: Object, props: Object) => ({
        printTemplateRecords: state.entities.printTemplates.list.records,
        userPreferences: state.user.preferences,
        loadingPreferences: state.user.loadingPreferences,
        filterViewState: get(state, `component.state.${props.id}`),
        userProfile: state.user.profile,
        sidebarIsOpen: state.sidebar.isOpen,
        sidebarTitle: state.sidebar.title,
        classification: state.classifications.details.data,
        primaryClasses: state.app.allPrimaryClasses.records || [],
        entityTemplates: state.entities.printTemplates.entity.records,
        tasksAndProcesses: state.classifications.classificationTasksAndProcesses.data,
        leftPanelRecords: state.leftPanel.processDefinitions?.data?.records,
    }),
    {   saveUserPreferences,
        saveComponentState,
        loadEntityPrintTemplates,
        loadClassificationTaskAndProcess,
        loadPrintTemplatesListView,
        openTableColumnsSidebar },
    null,
    { forwardRef: true }
)(withRouterAndRef(EntitiesList));
