/* @flow */

import { gql } from '@apollo/client';

export default gql`
query processesDefinitionsQuery($filterBy: [JSON], $orderBy: [JSON], $excludeBy: [JSON]) {
  records: processDefinitions(filterBy: $filterBy, orderBy: $orderBy, excludeBy: $excludeBy) {
    id
    name
    description
    primary(fields: ["lastVersion"])
    iconName
    iconColor
    iconType
  }
}
`;
