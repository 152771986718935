/* @flow */

import { loadData , mutateData} from 'app/utils/redux/action-utils';
import OptionsBuilder from 'app/utils/api/OptionsBuilder';
import { get } from 'app/utils/lo/lo';

import createRelationTaskToColumnMutation from 'graphql/abox/kanban/createRelationTaskToColumnMutation';
import createRelationColumnToBoardMutation from 'graphql/abox/kanban/createRelationColumnToBoardMutation';
import deleteRelationMutation from 'graphql/entities/relationships/deleteRelationMutation';
import loadAllBoardColumnsQuery from 'graphql/abox/boards/loadAllBoardColumnsQuery';
import loadColumnTasksQuery from 'graphql/abox/boards/loadColumnTasksQuery';
import loadBoardColumnsQuery from 'graphql/abox/boards/loadBoardColumnsQuery';
import loadBoardPrimaryClassQuery from 'graphql/abox/boards/loadBoardPrimaryClassQuery';
import updateRelationMutation from 'graphql/entities/relationships/updateRelationMutation';
import loadBoardRecordsQuery from 'graphql/abox/boards/loadBoardRecordsQuery';

export const LOAD_COLUMNS_TASKS_STARTED: string = '@@affectli/abox/LOAD_COLUMNS_TASKS_STARTED';
export const LOAD_COLUMNS_TASKS: string = '@@affectli/abox/LOAD_COLUMNS_TASKS';
export const LOAD_ALL_COLUMNS_STARTED: string = '@@affectli/abox/LOAD_ALL_COLUMNS_STARTED';
export const LOAD_ALL_COLUMNS: string = '@@affectli/abox/LOAD_ALL_COLUMNS';


export const LOAD_BOARD_COLUMNS_STARTED: string = '@@affectli/abox/LOAD_BOARD_COLUMNS_STARTED';
export const LOAD_BOARD_COLUMNS: string = '@@affectli/abox/LOAD_BOARD_COLUMNS';

export const CREATE_RELATIONSHIP_ENTITY_TO_TASK_STARTED: string = '@@affectli/abox/CREATE_NEW_RELATIONSHIP_ENTITY_TO_TASK_STARTED';
export const CREATE_RELATIONSHIP_ENTITY_TO_TASK: string = '@@affectli/abox/CREATE_NEW_RELATIONSHIP_ENTITY_TO_TASK_DONE';

export const DELETE_RELATION_ENTITY_FROM_TASK_STARTED: string = '@@affectli/abox/REMOVE_TASK_RELATIONSHIP_FROM_COLUMN_STARTED';
export const DELETE_RELATION_ENTITY_FROM_TASK: string = '@@affectli/abox/REMOVE_TASK_RELATIONSHIP_FROM_COLUMN_DONE';

export const LOAD_BOARD_PRIMARY_CLASS_STARTED: string = '@@affectli/abox/LOAD_BOARD_PRIMARY_CLASS_STARTED';
export const LOAD_BOARD_PRIMARY_CLASS: string = '@@affectli/abox/LOAD_BOARD_PRIMARY_CLASS';

export const SHOW_NEW_COLUMN_TO_BOARD: string = '@@affectli/abox/ADD_NEW_BOARD_LABEL_TO_COLUMN';
export const HIDE_COLUMN_FROM_BOARD: string = '@@affectli/abox/HIDE_COLUMN_FROM_BOARD';

export const LOAD_BOARD_RECORDS_STARTED: string = '@@affectli/abox/LOAD_BOARD_RECORDS_STARTED';
export const LOAD_BOARD_RECORDS: string = '@@affectli/abox/LOAD_BOARD_RECORDS';

export const LOAD_BOARD_RECORDS_ALL_STARTED: string = '@@affectli/abox/LOAD_BOARD_RECORDS_ALL_STARTED';
export const LOAD_BOARD_RECORDS_ALL: string = '@@affectli/abox/LOAD_BOARD_RECORDS_ALL';

export const CREATE_RELATIONSHIP_ATTRIBUTE_TO_BOARD_STARTED: string = '@@affectli/abox/CREATE_RELATIONSHIP_COLUMN_TO_BOARD_STARTED';
export const CREATE_RELATIONSHIP_ATTRIBUTE_TO_BOARD_DONE: string = '@@affectli/abox/CREATE_RELATIONSHIP_COLUMN_TO_BOARD_DONE';

export const REMOVE_ATTRIBUTE_RELATIONSHIP_FROM_ENTITY_STARTED: string = '@@affectli/abox/LOAD_BOARD_PRIMARY_CLASS_STARTED';
export const REMOVE_ATTRIBUTE_RELATIONSHIP_FROM_ENTITY_DONE: string = '@@affectli/abox/LOAD_BOARD_PRIMARY_CLASS';

export const ADD_COLUMN_TO_BOARD: string = '@@affectli/abox/ADD_COLUMN_TO_BOARD';
export const SET_SELECTED_BOARD: string = '@@affectli/abox/SET_SELECTED_BOARD';
export const COLUMN_ORDER_POSITION: string = '@@affectli/abox/COLUMN_ORDER_POSITION';

export const UPDATE_COLUMN_TO_BOARD_RELATIONSHIP_STARTED: string = '@@affectli/abox/UPDATE_COLUMN_TO_BOARD_RELATIONSHIP_STARTED';
export const UPDATE_COLUMN_TO_BOARD_RELATIONSHIP_DONE: string = '@@affectli/abox/UPDATE_COLUMN_TO_BOARD_RELATIONSHIP_DONE';

/**
 * Load tasks per board-labels
 * Get tasks filter by relationship custom entity id
 * @param columnIds the entity ID
 * @param options startIndex, stopIndex, filterBy & orderBy query options
 */
const getFilterValue = (options) => {
    const relatedFilter = (options.filterBy || []).filter(({ field }) => field === 'relations.relatedEntity.id');
    const filterValue = get(relatedFilter, '[0].value', null);
    if (!filterValue) {
        return [];
    }
    if (Array.isArray(filterValue)) {
        return filterValue;
    }
    return [filterValue];
};

export const loadColumnsTasks = (columnId: string, options: Object = {}) => {
    const { filterBy: columnFilterBy } = new OptionsBuilder()
        .filter({ field: 'relations.relatedEntity.id', op: 'in', value: columnId })
        .filter({ field: 'relations.relation.relationDefinition.relatedDescription', op: '=', value: 'Has Task' })
        .build();
    const variables = new OptionsBuilder(options)
        .defaultOrder({ field: 'startDate', asc: false })
        .build();
    const modifyPayload = (payload) => {
        const filterValues = getFilterValue(options);
        if (filterValues?.length) {
            const records = payload.records.filter(record => record.relations.find(rel => filterValues.some(value => value === rel?.relatedEntity?.id)));
            return { columnId, ...payload, records };
        }
        return { columnId, ...payload };
    };
    return loadData(
        LOAD_COLUMNS_TASKS_STARTED,
        LOAD_COLUMNS_TASKS,
        loadColumnTasksQuery,
        modifyPayload
    )({ ...variables, filterBy: [...columnFilterBy, ...variables.filterBy]});
};

export const loadBoardPrimaryClass = () => {
    const variables = new OptionsBuilder({})
        .filter({ field: 'uri', op: '=', value: 'system_board' })
        .build();
    return loadData(
        LOAD_BOARD_PRIMARY_CLASS_STARTED,
        LOAD_BOARD_PRIMARY_CLASS,
        loadBoardPrimaryClassQuery,
        (result) => {
            return result[0];
        }
    )(variables);
};


export const loadBoardRecords = (options: Object = {}) => loadData(
    LOAD_BOARD_RECORDS_STARTED,
    LOAD_BOARD_RECORDS,
    loadBoardRecordsQuery,
)(options);

export const loadBoardRecordsAll = (options: Object = {}) => loadData(
    LOAD_BOARD_RECORDS_ALL_STARTED,
    LOAD_BOARD_RECORDS_ALL,
    loadBoardRecordsQuery,
)(options);

export const loadBoardColumns = (options: Object = {}) => {
    const variables = new OptionsBuilder(options)
        .filter({ field: 'relation.relationDefinition.description', op: '=', value: 'parent of' })
        .build();
    return loadData(
        LOAD_BOARD_COLUMNS_STARTED,
        LOAD_BOARD_COLUMNS,
        loadBoardColumnsQuery,
    )(variables);
};

export const loadAllBoardColumns = () => {
    const variables = new OptionsBuilder({})
        .filter({ field: 'classes.uri', op: '=', value: 'board_label' })
        .filter({ field: 'active', op: '=', value: true })
        .defaultOrder({ field: 'name', asc: true })
        .build();
    return loadData(
        LOAD_ALL_COLUMNS_STARTED,
        LOAD_ALL_COLUMNS,
        loadAllBoardColumnsQuery
    )(variables);
};

export const createRelationEntityToTask = (record: Object) => {
    return mutateData(
        CREATE_RELATIONSHIP_ENTITY_TO_TASK_STARTED,
        CREATE_RELATIONSHIP_ENTITY_TO_TASK,
        createRelationTaskToColumnMutation,
    )(record);
};

export const deleteRelationEntityFromTask = (relation: string) => {
    return mutateData(
        DELETE_RELATION_ENTITY_FROM_TASK_STARTED,
        DELETE_RELATION_ENTITY_FROM_TASK,
        deleteRelationMutation,
    )({ relation });
};


export const showNewBoardColumn = (column: Object) => (dispatch: Function) => {
    dispatch({ type: SHOW_NEW_COLUMN_TO_BOARD, payload: column });
};

export const createRelationAttributeToEntity = (record: Object) => {
    return mutateData(
        CREATE_RELATIONSHIP_ATTRIBUTE_TO_BOARD_STARTED,
        CREATE_RELATIONSHIP_ATTRIBUTE_TO_BOARD_DONE,
        createRelationColumnToBoardMutation,
        '',
    )(record);
};

export const deleteRelationAttributeFromEntity = (relation: string) => {
    return mutateData(
        REMOVE_ATTRIBUTE_RELATIONSHIP_FROM_ENTITY_STARTED,
        REMOVE_ATTRIBUTE_RELATIONSHIP_FROM_ENTITY_DONE,
        deleteRelationMutation,
        '',
    )({ relation });
};

/**
 * Update a relationship between two entities.
 * @param record
 * {
 *  "relation": (relation.id)
 *  "attributes": {
 *      "board-board-label/order": (index/orderNumber)
 *  }
 * }
 */
export const updateRelationColumnToBoard = (record: Object) => {
    return mutateData(
        UPDATE_COLUMN_TO_BOARD_RELATIONSHIP_STARTED,
        UPDATE_COLUMN_TO_BOARD_RELATIONSHIP_DONE,
        updateRelationMutation,
        '',
    )(record);
};

/**
 * Move column to another column position
 * @param columns reordered column position
 */
export const setColumnPosition = (columns: Array<Object>) => (dispatch: Function) => {
    dispatch({ type: COLUMN_ORDER_POSITION, payload: columns });
};


export const setSelectedBoard = (board: Object) => (dispatch: Function) => {
    dispatch({ type: SET_SELECTED_BOARD, payload: board });
};

export const cleanBoardColumns = (data: Array) => (dispatch: Function) =>{
    dispatch({ type: LOAD_BOARD_COLUMNS, payload: data });
};
