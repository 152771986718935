/* @flow */

import React from 'react';
import { Route, Switch } from 'react-router-dom';

import PageNotAllowed from 'app/containers/ErrorPages/PageNotAllowed';
import EventsListView from 'app/containers/Stream/Events/EventsListView/EventsListView';
import EventTypesListView from 'app/containers/Stream/Events/EventTypes/EventTypesListView';
import EventTypeDetails from 'app/containers/Stream/Events/EventTypes/EventTypeDetails';
import { baseRoutes } from 'app/config/routesConfig';
import withPermissions from 'app/utils/withPermissions';
import EventDetail from 'app/containers/Entities/EntityAbout/EventDetail';

/**
 * Define the routes for the Event's views.
 */
const EventsRoute = (props) => {
    const { match: { url }, canView } = props;
    if (!canView) {
        return <PageNotAllowed title="Events" />;
    }
    return (
        <Switch>
            <Route path={`${url}`} exact component={EventsListView} />
            <Route path={`${url}/eventType/panel/:id`} exact component={EventsListView} />
            <Route path={baseRoutes['eventtype']} exact component={EventTypesListView} />
            <Route path={`${baseRoutes['eventtype']}/:id`} component={EventTypeDetails} />
            <Route path={`${url}/:id/:time`} component={EventDetail} />
        </Switch>
    );
};

export default withPermissions(EventsRoute, 'event');
