const sizes = {
    xs: {
        image: '12px',
        icon: '12px',
        height: '12px',
        borderRadius: '.1rem',
        fontSize: '.7rem',
        lineHeight: '1',
        paddingTB: '.5rem',
        paddingLR: '.5rem'
    },
    sm: {
        image: '16px',
        icon: '16px',
        height: '22px',
        borderRadius: '.2rem',
        fontSize: '.8rem',
        lineHeight: '1.2',
        paddingTB: '.6rem',
        paddingLR: '.3rem'
    },
    sml: {
        image: '18px',
        icon: '18px',
        height: '24px',
        borderRadius: '.2rem',
        fontSize: '.8rem',
        lineHeight: '1.2',
        paddingTB: '.6rem',
        paddingLR: '.3rem'
    },
    md: {
        image: '24px',
        icon: '24px',
        height: '39px',
        borderRadius: '.3rem',
        fontSize: '1rem',
        lineHeight: '1.5',
        paddingTB: '.6rem',
        paddingLR: '.6rem'
    },
    lg: {
        image: '32px',
        icon: '32px',
        height: '32px',
        borderRadius: '.4rem',
        fontSize: '1.3rem',
        lineHeight: '1.7',
        paddingTB: '.6rem',
        paddingLR: '.6rem'
    },
    xl: {
        image: '48px',
        icon: '48px',
        height: '48px',
        borderRadius: '.5rem',
        fontSize: '1.5rem',
        lineHeight: '1.8',
        paddingTB: '1rem',
        paddingLR: '1rem'
    },
    xxl: {
        image: '92px',
        icon: '92px',
        height: '92px',
        borderRadius: '.5rem',
        fontSize: '92px',
        lineHeight: '1.8',
        paddingTB: '1rem',
        paddingLR: '1rem'
    },
};

export default sizes;
