import { gql } from '@apollo/client';

import entityDetailsCommonQuery from 'graphql/entities/common/entityDetailsCommonQuery';

export default gql`
    query loadEntityQuery($type: String!, $id: ID!) {
        result: entity(type: $type, id: $id) {
            ${entityDetailsCommonQuery}
        }
    }
`;
