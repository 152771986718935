/* @flow */

import React from 'react';

import Link from 'app/components/atoms/Link/Link';

const UserEntityLink = ((props: Object) => {
    const { id, children, title, path, ...restProps } = props;
    if (id) {
        const tab = path ? `/${path}` : '';
        return <Link title={title} to={`/entities/user/${id}${tab}`} {...restProps}>{children}</Link>;
    }
    return <Link title={title} {...restProps}>{children}</Link>;
});

export default UserEntityLink;
