/* @flow */

import { gql } from '@apollo/client';

export default aditionalFields => gql`
    query classesListShortQuery($startIndex: Int, $stopIndex: Int, $filterBy: [JSON], $orderBy: [JSON], $excludeBy: [JSON]) {
        count: count(dataType: "class", filterBy: $filterBy, excludeBy: $excludeBy)
        records: classes(startIndex: $startIndex, stopIndex: $stopIndex, filterBy: $filterBy, orderBy: $orderBy, excludeBy: $excludeBy) {
            id
            name
            uri
            color
            createdDate
            applicableOn
            primary
            icon
            iconType
            ${aditionalFields || ''}
        }
    }
`;
