/* @flow */

import { gql } from '@apollo/client';
import entityDetailsCommonQuery from 'graphql/entities/common/entityDetailsCommonQuery';

export default gql`
    mutation entityAddClassesMutation($id: ID!, $type: String!, $classes: [ID]!, $attributes: JSON) {
        entityAddClasses(id: $id, type: $type, classes: $classes, attributes: $attributes){
            ${entityDetailsCommonQuery}
        }
    }
`;
