/* @flow */

import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ClassificationDetailAttributes from 'app/components/Classifications/ClassificationDetailAttributes/ClassificationDetailAttributes';
import ClassificationPrimaryAboutSidebar from 'app/components/Classifications/ClassificationDetailAbout/ClassificationPrimaryAboutSidebar';
import ClassEntitiesList from 'app/containers/Classifications/ClassEntitiesList';
import ParentsChildrenAppliesToSidebar from 'app/containers/Classifications/ParentsChildrenAppliesToSidebar';
import ChatSharing from 'app/components/organisms/Chat/ChatSharing';
import DotMenu from 'app/components/molecules/DotMenu/DotMenu';
import Loader from 'app/components/atoms/Loader/Loader';
import Chat from 'app/components/organisms/Chat/Chat';

import { loadClassification, updateClassification } from 'store/actions/classifications/classificationsActions';
import { setActions, setSubTitle, setTitle } from 'store/actions/sidebar/sidebarActions';
import { shrinkSidebar, expandSidebar } from 'store/actions/sidebar/sidebarActions';
import { closeSidebar } from 'store/actions/sidebar/sidebarActions';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { getSubscriptionByRel } from 'app/utils/chat/chatUtils';
import { toUniqueUUID } from 'app/utils/string/string-utils';
import { mediaBreakpoints } from 'app/themes/breakpoints';
import { getPermissions } from 'app/config/rolesConfig';
import history from 'store/History';
import { buildDotMenu } from 'app/utils/entity/entityUtils';

const otherTabs = ['Parents', 'Children', 'Applies To'];

class ClassSidebar extends PureComponent<Object, Object> {
    static propTypes = {
        id: PropTypes.string,
        type: PropTypes.string,
        title: PropTypes.string,
        isOpen: PropTypes.bool,
        // from redux
        isLoading: PropTypes.bool,
        details: PropTypes.object
    };

    constructor(props: Object) {
        super(props);
        this.state = { entityType: props.details?.primary };
        this.initData({});
    }

    componentDidUpdate(prevProps) {
        this.initData(prevProps);
        const { details } = this.props;
        if(prevProps.details?.id !== details?.id && details) {
            this.setState({ entityType: details.primary });
        } 
    }

    @bind
    setActions(expanded, sidebarTitle) {
        const { details } = this.props;
        this.props.setActions(this.dotMenu(details, expanded, sidebarTitle));
    }

    initData(prevProps) {
        const { id, isOpen, isLoading, details, expanded, title } = this.props;
        if (!isOpen) {
            return;
        }
        this.setActions(expanded, title);
        if (isLoading) {
            return;
        }
        const loaded = details || {};
        if (loaded.id === id) {
            if (title === 'Sharing') {
                const subTitle = `${details.name} #${toUniqueUUID(details.id)}`;
                this.props.setSubTitle(subTitle);
            } else {
                this.props.setSubTitle(details.name);
            }
            if(details && !buildDotMenu({ details, entityType: 'class' }).find(m => m.name === title) && !otherTabs.includes(title)) {
                this.props.setTitle('About');
            }
            return;
        }
        if(prevProps.id !== id) {
            this.props.loadClassification(id);
        }
    }

    @bind
    onDotMenuClick(title) {
        const { entityType } = this.state;
        if (['Collapse', 'Expand'].includes(title)) {
            const { expanded, expandSidebar, shrinkSidebar } = this.props;
            const action = expanded ? shrinkSidebar : expandSidebar;
            return action();
        }
        if (title === 'Go to details') {
            this.goToDetails();
        }
        if (title === 'Attributes') {

            return entityType ? history.push(`/entity-types/${this.props.details.id}/attributes/editor`) :  
                history.push(`/classifications/${this.props.details.id}/attributes/editor`);
        }
        this.props.setTitle(title);
    }

    goToDetails() {
        const { windowWidth, id, shrinkSidebar, closeSidebar } = this.props;
        const { entityType } = this.state;
        if(entityType){
            history.push(`/entity-types/${id}`);
        }else{
            history.push(`/classifications/${id}`);
        }
        if (!windowWidth < mediaBreakpoints.sm) {
            shrinkSidebar();
        } else {
            closeSidebar();
        }
    }

    @bind
    @memoize()
    dotMenu(details: Object, expanded: boolean, title: string) {
        return (
            <DotMenu
                key={13}
                onItemClick={this.onDotMenuClick}
                items={buildDotMenu({ details, expanded, title, entityType: 'class' })}
            />
        );
    }

    render() {
        const { isLoading, title, details, isOpen, updateClassification, subscription } = this.props;
        const { entityType } = this.state;

        const isLoaded = details;
        const permissions = getPermissions(details && details.role);
        const { canEdit } = permissions || {};
        const { isSystem } = details || {};
        const { rel, rid } = subscription || {};
        return (
            isOpen && (
                <>
                    {isLoading && <Loader absolute backdrop />}
                    {isLoaded && title === 'About' && (
                        <ClassificationPrimaryAboutSidebar
                            classification={details}
                            updateClassification={updateClassification}
                            canEdit={!isSystem && canEdit}
                            openSidebar={this.onDotMenuClick}
                            closeClassSidebar={this.props.closeClassSidebar}
                            entityType={entityType}
                        />
                    )}
                    {isLoaded && title === 'Attributes' && (
                        <ClassificationDetailAttributes classification={details} updateClassification={updateClassification} canEdit={!isSystem && canEdit} />
                    )}
                    {isLoaded && title === 'A-Live' && <Chat id={details.id} type="class" details={details} />}
                    {isLoaded && title === 'Sharing' && (
                        <ChatSharing 
                            rid={rid} 
                            rel={rel} 
                            canEdit={canEdit} 
                            role={details.role} 
                            id={details.id}
                            type={'class'}
                            selectedSidebar={'share'}
                            entityType={entityType}
                        />
                    )}
                    {isLoaded && title === 'Entities' && <ClassEntitiesList details={details} />}
                    {isLoaded && (title === 'Parents' || title === 'Children' || title === 'Applies To') && <ParentsChildrenAppliesToSidebar details={details} />}

                </>
            )
        );
    }
}

export default connect(
    (state: Object, ownProps: Object) => {
        const details = state.classifications.details.data;
        const { id } = details || {};
        const subscriptions = state.chat.subscriptions.data;
        const subscription = getSubscriptionByRel(subscriptions, { id, type: 'class' });

        return {
            subscription,
            details,
            isLoading: state.classifications.details.isLoading,
            isOpen: state.sidebar.isOpen,
            expanded: state.sidebar.expanded,
            title: state.sidebar.title,
            windowWidth: state.global.window.width,
            actions: state.sidebar.actions,
        };
    },
    { shrinkSidebar, expandSidebar, updateClassification, loadClassification, closeSidebar, setActions, setSubTitle, setTitle }
)(withRouter(ClassSidebar));
