import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Dialog, Grid, IconButton, MdiIcon, Typography, Divider } from '@mic3/platform-ui';

import Loader from 'app/components/atoms/Loader/Loader';

const DialogStyled = styled(Dialog)`
    & .MuiDialog-paper {
        box-sizing: border-box;
        padding: 12px 20px;
        background-color: ${({ bgcolor }) => (bgcolor ? bgcolor : '')};
    }
    *::-webkit-scrollbar {
        width: 7px;
        height: 7px;
    }
    *::-webkit-scrollbar-thumb {
        border-radius: 5px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: rgba(255, 255, 255, 0.2);
        min-height: 65px;
    }
    *::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 0;
        background-color: transparent;
    }
`;

const Content = styled(Grid)`
    overflow: ${({ overflow }) => (overflow ? overflow : 'auto')};
    min-height: 60px;
`;

const StyledSmall = styled.small`
    padding-top: 10px;
`;

const DialogFooter = styled.div`
    margin-top: 6px;
`;

const DialogFooterButton = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const StyledDivider = styled(Divider)`
    margin: 0.6rem 0 !important;
`;
const CloseIconButton = styled(IconButton)`
    padding: 6px !important;
`;

const ModalHeader = styled(Grid)`
    margin-bottom: 5px;
`;


const ModalDialog = ({
    open,
    footerButton,
    title,
    subtitle,
    children,
    actions,
    withoutClose,
    onClose,
    closeIcon,
    dialogProps = {},
    isLoading,
    fullScreen,
    bgcolor,
    footer,
    withoutFooterDivider,
    maxWidth,
    showDividerAfterTitle,
    className
}) => {
    const { overflow } = dialogProps;
    return (
        <DialogStyled
            fullScreen={fullScreen}
            open={open !== undefined ? open : true} 
            onClose={() => {
                /* We don't want to close modals when we click outside of modal */
            }}
            fullWidth
            maxWidth={maxWidth || 'md'}
            bgcolor={bgcolor}
            className={className}
            {...dialogProps}
        >
            <ModalHeader container justify='space-between' alignItems='center'>
                <Grid item container xs={8} alignItems='center' wrap='nowrap'>
                    {!withoutClose && (
                        <CloseIconButton aria-label='close' onClick={onClose}>
                            <MdiIcon name={closeIcon ? closeIcon : 'close'} />
                        </CloseIconButton>
                    )}
                    <Typography variant='h6'>{title}</Typography>
                    <StyledSmall>{subtitle}</StyledSmall>
                </Grid>
                <Grid item container xs={4} justify='flex-end' alignItems='center'>
                    {actions}
                </Grid>
            </ModalHeader>
            <ModalHeader>
                {showDividerAfterTitle && <StyledDivider />}
            </ModalHeader>
            <Content overflow={overflow}>{isLoading ? <Loader /> : children}</Content>
            {footer && (
                <DialogFooter>
                    {!withoutFooterDivider && <Divider />}
                    {footer}
                </DialogFooter>
            )}
            {footerButton && (
                <DialogFooterButton>
                    {footerButton}
                </DialogFooterButton>
            )}
        </DialogStyled>
    );
};

ModalDialog.propTypes = {
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    subtitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    children: PropTypes.node,
    action: PropTypes.node,
    dialogProps: PropTypes.object,
    onClose: PropTypes.func,
    fullScreen: PropTypes.bool,
    withoutClose: PropTypes.bool,
    footer: PropTypes.node,
    withoutFooterDivider: PropTypes.bool,
    maxWidth: PropTypes.string,
};

export default ModalDialog;
