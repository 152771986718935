//@flow
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import olMap from 'app/containers/Maps/SituationalAwareness/olMapController';
import { get } from 'app/utils/lo/lo';
import { getWMSLayer } from 'app/utils/maps/layer/layerUtils';

const MapStyled = styled.div`
    height: 164px;
    cursor: all-scroll; 
    background-color: white;  
    background-image: linear-gradient(45deg, #b0b0b0 25%, transparent 25%), linear-gradient(-45deg, #b0b0b0 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #b0b0b0 75%), linear-gradient(-45deg, transparent 75%, #b0b0b0 75%);
    background-size: 20px 20px;
    background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
`;

const MapPreview = ({ data }: Object) => {
    const layer = get(data, 'layer');
    const style = get(data, 'style');
    const url = get(data, 'layer_url');
    const { extent } = getWMSLayer(data);

    const [map, setMap] = useState();
    const [initMap, setInitMap] = useState(false);

    useEffect(() => {
        if (!map) {
            const previewMap = new olMap();
            setMap(previewMap);
        } else if (map && !initMap) {
            setInitMap(true);
            map.initEntityAboutMap('mapPreview');
        }
        if (map && layer && url && extent) {
            map.addWMSPreviewLayer && map.addWMSPreviewLayer({ layer, style, url, extent });
        }
    }, [map, layer, style, initMap, url, extent]);


    return (
        <MapStyled id="mapPreview" />
    );
};

export const additionalFields = (name: string, data: Object) => {
    switch(name) {
        case 'WMS Layer': {
            const wmsLayer = getWMSLayer(data);
            return [
                {
                    field: 'Layer',
                    type: 'typeahead',
                    properties: {
                        label: 'Layer',
                        name: 'layer',
                        options: get(data, 'allWMSLayers', []),
                        isVisible: (data: Object) => data.name && data['layer_url'],
                    },
                    constraints: { required: true }
                },
                {
                    field: 'style',
                    type: 'typeahead',
                    properties: {
                        label: 'Style',
                        name: 'style',
                        options: get(wmsLayer, 'styles', []),
                        isVisible: (data: Object) => wmsLayer?.styles?.length && data.name && data['layer'],
                    },
                    constraints: { required: true }
                },
                {
                    type: 'custom',
                    properties: {
                        label: 'Layer Preview',
                        name: 'MapPreview',
                        Component: props => <MapPreview {...props} />,
                        isVisible: (data: Object) =>  (!wmsLayer?.styles?.length && !data?.style && data?.layer) || (data['style'] && data['layer']),
                    }
                }
            ];
        }
        default:
            return [];
    }
};
