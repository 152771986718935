/* @flow */

import React, { useState, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { Autocomplete } from '@mic3/platform-ui';

import { get } from 'app/utils/lo/lo';

import EntityTypeahead from 'app/components/organisms/Form/Typeahead/EntityTypeahead';

const RelatedEntitiesDefault = (props) => {
    const { entityTypes, disabled } = props;

    const [type, setType] = useState(get(props, 'value[0].type') || entityTypes[0]?.uri);
    let allEntityTypes = [];
    
    const onChangeType = useCallback(({ target: { value } }) => {
        setType(value);
    }, [setType]);
    
    allEntityTypes = useMemo(() => entityTypes.map((item) => 
        ({ value: item.uri, label: item.name })
    ),[entityTypes]); 

    return (
        <div>
            <Autocomplete
                disabled={disabled}
                label="Related Entity Type"
                options={allEntityTypes}
                valueField="value"
                value={type}
                clearable={false}
                onChange={onChangeType}
            />
            <EntityTypeahead
                disabled={disabled}
                label="Related Entities"
                entityType={type}
                name="relatedEntities"
                onChange={props.onChange}
                value={props.value}
                multiple
            />
        </div>
    );
};

const RelatedEntitiesMultiple = (props) => {
    const { entityTypes, onChange: onChangeProp, disabled } = props;

    const [types, setTypes] = useState(props.value ? [...new Set(props.value.map(val => val.relatedEntityType))] : []);
    let allEntityTypes = [];
    
    const onChangeType = useCallback(({ target: { value } }) => {
        setTypes(value);
        const newVal = (props?.value || []).filter(val => value.includes(val.relatedEntityType) );
        onChangeProp({ target: { value: newVal, name: 'relatedEntities' } });        
    }, [setTypes, onChangeProp, props.value]);
    
    const onChange = useCallback(({ target: { value, name } }, type) => {
        const newVal = (props?.value || []).filter(val => val.relatedEntityType !== type);
        onChangeProp({ target: { 
            value: [...newVal, ...((value || []).map(v => ({ ...v, relatedEntityType: type })))], 
            name} });
    }, [onChangeProp, props.value]);
    
    allEntityTypes = useMemo(() => entityTypes.map((item) => 
        ({ value: item.uri, label: item.name })
    ),[entityTypes]); 
   
    return (
        <div>
            <Autocomplete
                disabled={disabled}
                label="Related Entity Type"
                options={allEntityTypes}
                valueField="value"
                value={types}
                clearable={false}
                onChange={onChangeType}
                multiple
            />
            {(types || []).map(type => (
                <EntityTypeahead
                    disabled={disabled}
                    key={type}
                    label={type}
                    entityType={type}
                    name="relatedEntities"
                    onChange={(evnt) => onChange(evnt, type)}
                    value={(props?.value || []).filter(val => val.relatedEntityType === type)}
                    multiple
                />
            ))}
            
        </div>
    );
};

const RelatedEntities = ({ multiple, ...restProps }) => {
    if(multiple) {
        return <RelatedEntitiesMultiple {...restProps} />;
    }
    return <RelatedEntitiesDefault {...restProps} />;
};

export default connect(
    (state) => ({
        entityTypes: state.app.allPrimaryClasses.records || [],
    }),
    {}
)(RelatedEntities);
