/* @flow */

import { baseRoutes } from 'app/config/routesConfig';
import { typeIcon } from 'app/config/typesConfig';

const etitiesList = (isAdmin, permissions) => {
    const permissionsSet = new Set(permissions);
    return [
        (isAdmin || permissionsSet.has('entity.entity')) && { name: 'Entities', iconType: 'af', icon: 'things', to: '/entities' },
        (isAdmin || permissionsSet.has('entity.entity')) && { name: 'Related Entities', icon: 'file-tree', to: '/related-entities' },
        (isAdmin || permissionsSet.has('entity.relationdefinition')) && { name: 'Relation Definitions', icon: typeIcon['relationdefinition'].name, to: baseRoutes['relationdefinition']},
        (isAdmin || permissionsSet.has('entity.entity')) && { name: 'Print Templates (Beta)', icon: typeIcon['print-template'].name, to: baseRoutes['print-template'] },
        (isAdmin || permissionsSet.has('entity.graphic')) && { name: 'Graphics Library', icon: 'folder-multiple-image', to: '/graphics-library' },
    ].filter(Boolean);
};

export default etitiesList;
