/* @flow */

export default class Cache {

    constructor({ ttl } = {}) {
        this.ttl = ttl || 2 * 60 * 1000; // 2 minutes
        this.cache = new Map();
        this.onExpire = null;
    }

    set(key, value, ttl) {
        if (this.cache.has(key)) {
            const { timeoutId } = this.cache.get(key);
            clearTimeout(timeoutId);
        }

        const timeoutId = setTimeout(() => {
            const { value } = this.cache.get(key);
            this.cache.delete(key);
            this.onExpire && this.onExpire(key, value);
        }, ttl || this.ttl);

        this.cache.set(key, { value, timeoutId });
    }

    has(key) {
        return this.cache.has(key);
    }

    get(key) {
        if (this.cache.has(key)) {
            const { value } = this.cache.get(key);
            return value;
        }
        return undefined;
    }

    del(key) {
        if (this.cache.has(key)) {
            const { value, timeoutId } = this.cache.get(key);
            clearTimeout(timeoutId);
            this.cache.delete(key);
            return value;
        }
        return undefined;
    }

    keys() {
        return Array.from(this.cache.keys());
    }

    values() {
        return Array.from(this.cache.values())
            .map(({ value }) => value);
    }

    entries() {
        return Array.from(this.cache.values())
            .map(([ key, { value }]) => [key, value]);
    }

    clearAllTimeout() {
        for (const key of this.cache.keys()) {
            this.del(key);
        }
    }
}
