/* @flow */

import { gql } from '@apollo/client';

export default gql`
    query countriesListQuery {
        result: entities(type: "system_country") {
            id
            name
            image
            iconName
            iconColor
            iconType
            type
            active
            primary
        }
    }
`;