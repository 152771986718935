import { PRIORITY_FILTER_OPTIONS } from 'app/config/aboxConfig';
import { commonEntitiesListFilters } from 'app/utils/entity/entityUtils';
export default  [
    {
        field: 'name',
        type: 'text',
        properties: {
            label: 'Name',
            name: 'name',
            opSelector: true
        },
    },
    {
        field: 'id',
        type: 'uuid',
        properties: {
            label: 'ID',
            name: 'id',
            opSelector: true
        },
        condition: '=',
    },
    {
        field: 'classes.id',
        type: 'classificationTypeahead',
        properties: {
            label: 'Classes',
            name: 'classesUri',
            filterBy: [
                { field: 'active', op: '=', value: true },
                { field: 'abstract', op: '=', value: false },
                { field: 'primary', op: '=', value: false },
                { field: 'applicableOn', op: 'overlaps', 'value': ['entity', 'openprocess', 'closedprocess', 'system_process'] },
            ],
            multiple: true,
            valueField: 'id'
        },
        condition: 'in',
        sort: false
    },
    {
        field: 'relations.relatedEntity.id',
        type: 'relatedEntities',
        properties: {
            label: 'Related Entities',
            name: 'relatedEntities',
        },
        condition: 'in',
        sort: false,
    },
    {
        field: 'primary.closedDate',
        type: 'typeahead',
        properties: {
            label: 'Process Status',
            name: 'status',
            options: [
                { value: null, label: 'Any' },
                { value: 'is null', label: 'Open' },
                { value: 'is not null', label: 'Closed' },
            ],
        },
        sort: false
    },
    {
        field: 'primary.priority',
        type: 'typeahead',
        properties: {
            label: 'Priority',
            name: 'priority',
            options: PRIORITY_FILTER_OPTIONS,
            valueField: 'value',
        },
        condition: '='
    },
    ...commonEntitiesListFilters,
    {
        field: 'processDefinition.id',
        type: 'processTypeTypeahead',
        properties: {
            label: 'Process Type',
            name: 'processDefinitionId',
            multiple: true,
            valueField: 'id',
        },
        condition: 'in'
    },
    {
        field: 'primary.variables.INITIATOR',
        type: 'userTypeahead',
        properties: {
            label: 'Started By',
            name: 'startedById',
            multiple: true,
            valueField: 'id',
        },
        condition: 'in',
        sort: false,
    },
];
