/* @flow */

import React, { PureComponent } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { MenuItem, Select, Typography } from '@mic3/platform-ui';

import { saveComponentState } from 'store/actions/component/componentActions';
import { loadBackgroundJobs } from 'store/actions/abox/backgroundJobActions';
import { bind } from 'app/utils/decorators/decoratorUtils';
import { get } from 'app/utils/lo/lo';
import { entitiesDefaultFilterDefinitions } from 'app/utils/entity/entityUtils';
import history from 'store/History';

import PageTemplate from 'app/components/templates/PageTemplate';
import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import PageNotAllowed from 'app/containers/ErrorPages/PageNotAllowed';
import EntitiesList from 'app/containers/Entities/EntitiesList/EntitiesList';

import { statusIconsName } from 'app/components/organisms/EntitySections/JobStatusSection';
import { setDocumentTitle } from 'store/actions/app/appActions';
import { modulesAndPageTitles } from 'app/config/typesConfig';

const SelectStyled = styled(Select)`
   margin-right: 16px;
   & .MuiSelect-select.MuiSelect-select {
    min-width: 140px !important;
    padding-left: 5px;
    background: none;
   }
`;


const TypographyStyled = styled(Typography)`
    text-transform: capitalize;
    color: ${({ color, theme }) => color || theme.material.colors.disabled.color};
`;



const columnDefinitions = [
    {
        text: 'Entity Name',
        field: 'name'
    },
    {
        text: 'Script ID',
        field: 'id',
        onClickDisabled: true
    },
    {
        text: 'Job Status',
        field: 'primary.status',
        renderer:  ({ value, record, ...rest }) => {
            return value ? <TypographyStyled color={statusIconsName[value].color}>{value}</TypographyStyled> : '';
        },
    },
    {
        text: 'Job Type',
        field: 'primary.type',
        renderer:  ({ value, record, ...rest }) => {
            const text = value ? value.split('_') : '';
            return value ? <TypographyStyled>{text.join(' ')}</TypographyStyled> : '';
        },
        width: 200,
    },
    {
        text: 'Start',
        field: 'primary.startDate',
        align: 'center',
        width: 150,
    },
    {
        text: 'Ended',
        field: 'primary.endDate',
        align: 'center',
        width: 150,
    },
    {
        text: 'Last Modification',
        field: 'modifiedBy'
    },
    {
        text: 'Last Updated',
        field: 'modifiedDate',
        width: 150,
    },
    {
        text: 'Created Date',
        field: 'createdDate',
        width: 150,
    },
    {
        text: 'Created by',
        field: 'createdBy'
    },

];

const jobTypeOptions = [
    {value: null, label: 'Any'},
    {value: 'bulk_action', label: 'Bulk Action'},
    {value: 'export_classes', label: 'Export Classes'},
    {value: 'export_entity_types', label: 'Export Entity Types'},
    {value: 'export_entity_version', label: 'Export Entity Version'},
    {value: 'export_entities', label: 'Export Entities'},
    {value: 'export_entities_relations', label: 'Export Entities Relations'},
    {value: 'export_entities_sharing', label: 'Export Entities Sharing'},
    {value: 'export_teams', label: 'Export Teams'},
    {value: 'export_workspaces', label: 'Export Workspaces'},
    {value: 'import_classes', label: 'Import Classes'},
    {value: 'import_entity_types', label: 'Import Entity Types'},
    {value: 'import_entities', label: 'Import Entities'},
    {value: 'import_entities_relations', label: 'Import Entities Relations'},
    {value: 'import_entities_sharing', label: 'Import Entities Sharing'},
    {value: 'import_teams', label: 'Import Teams'},
    {value: 'import_workspaces', label: 'Import Workspaces'},
    {value: 'index_primary_attribute', label: 'Index Primary Attribute'},
    {value: 'recovery', label: 'Recovery'},
    {value: 'run_script', label: 'Run Script'},
];


const defaultFilters = { primary: null };

class BackgroundJobList extends PureComponent<Object, Object> {
    static propTypes = {
        loadBackgroundJobs: PropTypes.func,
        records: PropTypes.array,
        isLoading: PropTypes.bool,
        totalRecords: PropTypes.number,
        module: PropTypes.oneOf(['abox', 'admin']).isRequired
    };

    static defaultProps = {
        views: ['view-list', 'view-cards', 'view-table', 'view-board'],
        defaultView: 'view-list',
        isLoading: false,
        type: 'backgroundjob',
        columnDefinitions,
        defaultFilters
    };

    state = {
        view: 'view-list',
        isSettingsMenuOpen: false,
        updateKey: 0,
    };

    menuSettingsRef = React.createRef();
    listRef = React.createRef();

    defaultOptionsState = { startIndex: 0, stopIndex: 9, orderBy: { field: 'modifiedDate', direction: 'desc nulls last' } };
    loadedData = {};

    componentDidMount() {
        const { setDocumentTitle, module } = this.props;
        const { adminConsole, abox } = modulesAndPageTitles;
        const title = module === 'abox' ? abox.backgroundJobs : adminConsole.backgroundJobs;
        setDocumentTitle(title);
    }

    componentDidUpdate(prevProps) {
        const { match } = this.props;        
        if(prevProps.match.params?.status !== match.params?.status ) {
            this.loadData(this.loadedData);
        }
    }

    @bind
    creatFilterDefinitions(){
        let filterDefinitions = entitiesDefaultFilterDefinitions(['backgroundjob']);
        filterDefinitions = filterDefinitions.map((filter) => filter.field === 'classes.id' ? 
            {...filter, properties:  {...filter.properties, 
                filterBy:[...filter.properties.filterBy, {'field': 'abstract','op': '=','value': false}]}}
            : filter );
        filterDefinitions = filterDefinitions.filter((item)=> item.field !== 'active');
        filterDefinitions.splice(3, 0, {
            field: 'relations.relatedEntity.id',
            type: 'relatedEntities',
            properties: {
                label: 'Related Entities',
                name: 'relatedEntities',
            },
            condition: 'in',
            sort: false,
        });
        filterDefinitions.splice(6, 0, 
            {
                field: 'primary.type',
                type: 'typeahead',
                properties: {
                    name: 'primary',
                    label: 'Job type',
                    options: jobTypeOptions,
                    valueField: 'value'
                },
                condition: '='
            },
            {field: 'primary.status',
                type: 'text',
                properties: {
                    name: 'primary.status',
                    label: 'Job Status',
                },
                filters: false
            }
        );
        filterDefinitions.splice(9, 0, 
            {
                field: 'primary.startDate',
                type: 'dateTimeRange',
                properties: {
                    label: 'Start',
                    name: 'primaryStartDateTime',
                    disabled: data => get(data, 'primaryStartDate.exclude')
                },

            },
            {
                field: 'primary.endDate',
                type: 'dateTimeRange',
                properties: {
                    label: 'Ended',
                    name: 'primaryClosedDateTime',
                }
            },
        );
       
        return filterDefinitions;
    }

    @bind
    selectHandleChange(e){
        const { adminBackgroundJobs } = this.props;
        const status = e.target.value;
        const tabs = [
            { active: status === 'all', label: 'All', link: adminBackgroundJobs ? '/background-jobs' : '/abox/background-jobs' },
            { active: status === 'running', label: 'Running', link: adminBackgroundJobs ? '/background-jobs/running' : '/abox/background-jobs/running' },
            { active: status === 'waiting', label: 'Waiting', link: adminBackgroundJobs ? '/background-jobs/waiting' : '/abox/background-jobs/waiting' },
            { active: status === 'failed', label: 'Failed', link: adminBackgroundJobs ? '/background-jobs/failed' : '/abox/background-jobs/failed' },
            { active: status === 'completed', label: 'Completed', link:  adminBackgroundJobs ? '/background-jobs/completed' : '/abox/background-jobs/completed' }
        ];

        tabs.forEach((item)=>{ 
            item.active && history.push(item.link);
        });
    }
    
    @bind
    loadData(data) {
        const { module, userPreferences, viewId } = this.props;
        const view = get(userPreferences, `pageView.${viewId}`, 'view-list');
        
        this.loadedData = data || this.loadedData;
        const { options } = data;
        let { filterBy } =  this.loadedData.options;

        // Normalize relations filters
        filterBy = filterBy?.map((item) => (item.field === 'relations.relatedEntity.id' && Array.isArray(item.value)) ? 
            { ...item, value: item.value.map(v => v.id) } : 
            item
        );

        // Adding filters depend on status of page
        const workspacesIds = get(this.props, `profile.workspaces`, []).map((item) => item?.id).filter(Boolean);

        const status = this.props.match.params?.status;

        if(module === 'abox') {
            filterBy.push(... 
            [
                { field: 'workspaces.workspace.active', op: '=', value: true },
                { field: 'workspaces.workspace.id', op: 'in', value: workspacesIds }
            ]);
        }
        if(status !== 'all') {
            filterBy.push({ field: 'primary.status', op: '=', value: status });
        }

        // Loading list
        return this.props.loadBackgroundJobs({
            view,
            ...options,
            filterBy: filterBy
        });
    }

    render() {
        const { startIndex, records, totalRecords, isLoading, module, viewId, type, match, 
            adminBackgroundJobs } = this.props;
        const { permissions, isAdmin } = this.props.profile;
        const permissionsSet = new Set(permissions || []);
        const status = match.params?.status;
        const canView = isAdmin || permissionsSet.has(`${module}.backgroundjob`);
        if (!canView) {
            return <PageNotAllowed title={ module === 'abox' ? 'My Background Jobs' : 'Background Jobs' } />;
        }
        
        return (
            <>
                <PageTemplate title={ module === 'abox' ? 'My Background Jobs' : 'Background Jobs' }>
                    <ContentArea>
                        <EntitiesList
                            id={viewId}
                            type={type}
                            headerTitle={ module === 'abox' ? 'My Background Jobs' : 'Background Jobs' }
                            records={records || []}
                            totalRecords={totalRecords}
                            startIndex={startIndex}
                            isLoading={isLoading}
                            loadData={this.loadData}
                            columnDefinitions={columnDefinitions}
                            filterDefinitions={this.creatFilterDefinitions()}
                            defaultFilters={defaultFilters}
                            adminBackgroundJobs={adminBackgroundJobs}
                            leftSideSearcLine={(
                                <SelectStyled 
                                    disableUnderline
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'left'
                                        },
                                        transformOrigin: {
                                            vertical: 'top',
                                            horizontal: 'left'
                                        },
                                        getContentAnchorEl: null
                                    }}
                                    value={status}
                                    onChange={this.selectHandleChange}
                                >
                                    <MenuItem value={'all'}>All</MenuItem>
                                    <MenuItem value={'running'}>Running</MenuItem>
                                    <MenuItem value={'waiting'}>Waiting</MenuItem>
                                    <MenuItem value={'failed'}>Failed</MenuItem>
                                    <MenuItem value={'completed'}>Completed</MenuItem>
                                </SelectStyled>
                            )}
                        />
                    </ContentArea>
                </PageTemplate>
            </>
    
        );
    }
}

export default connect(
    (state: Object, props: Object) => {
        const viewId = props.adminBackgroundJobs ? 'AdminBackgroundJobList' : 'BackgroundJobList';
        return ({
            viewId,
            filterViewState: get(state, `component.state.${viewId}`, {}),
            userPreferences: state.user.preferences,
            isLoading: state.abox.backgroundJob.list.isLoading,
            startIndex: state.abox.backgroundJob.list.startIndex,
            records: state.abox.backgroundJob.list.records,
            totalRecords: state.abox.backgroundJob.list.count,
            profile: state.user.profile,
            sidebarTitle: state.sidebar.title,
            sidebarIsOpen: state.sidebar.isOpen,
        });
    },
    { loadBackgroundJobs,
        saveComponentState, 
        setDocumentTitle,
    },
)(BackgroundJobList);

