import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { connect } from 'react-redux';
import { Box, Typography, Grid, MdiIcon, IconButton, InputBase, Divider } from '@mic3/platform-ui';

import Loader from 'app/components/atoms/Loader/Loader';
import { connectorLogTailPipeline } from 'store/actions/designer/designerPipelineActions';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';

const Search = styled(Grid)`
    flex-grow: 1;
`;

const SearchToolbar = styled(Grid)`
    padding: 0 1rem;
    border-bottom: 2px solid ${({ theme }) => theme.material.colors.border.main};
`;

const Wrapper = styled(Grid)`
    margin: 16px 0px;
    padding: ${({ noPadding }) => !noPadding ? '16px' : '0'};
    ${({ disabled, theme, noBackground }) => `background-color: ${theme.material.colors.background.fields};`}
`;

const NoMessageTypography = styled(Typography)`
    padding: 8px 0;
`;

const DividerStyled = styled(Divider)`
    height: 21px !important;
`;

const PreBoxStyled = styled(Box)`
    white-space: pre-wrap;
    word-break: break-word;
    & ul {
        list-style: none;
        padding-left: 8px;
    }
`;

class ConnectorLog extends React.PureComponent {
    
    constructor(props) {
        super(props);
        this.state = { filter: '' };
        this.loadConnectorLogTail();
    }
    
    componentDidUpdate(prevProps) {
        const { value } = this.props;
        if(value !== prevProps.value) {
            this.loadConnectorLogTail();
        }

    }

    @bind
    onSearch(event) {
        this.setState({ filter: event.target.value.trimLeft() });
    }

    @bind
    handleLive(event) {
        this.setState({ live: event.target.value }, this.handleLiveInterval);
    }    

    @bind
    loadConnectorLogTail() {
        const { value, pipelineId, connectorLogTailPipeline } = this.props;
        if(value.group !== 'connector') {
            return;
        }
        return connectorLogTailPipeline({ 
            id: pipelineId, 
            connectorLabel: value.title,
            start: '1718960000',
            end: '1718965390'
        });
    }

    @bind
    @memoize()
    getConnectorLogTaile(pipelineStatus, value){
        const topic = (pipelineStatus?.topics || []).find(t => t.label === value.title); 
        return topic?.tail || [];
    }

    @bind
    @memoize()
    filterTail(tail, filter){
        if(!filter) return tail;
        return tail.filter(t => t?.message.toLowerCase().includes(filter.toLowerCase()));
    }

    @bind
    @memoize()
    buildMessage(msg){
        return (<ul>
            {Object.keys(msg).map((key, i) => {
                if(key === 'time') {
                    return (
                        <li key={`${key}${i}`}>
                            <b>{key}</b>: {moment(msg[key]).fromNow()}
                        </li>
                    ); 
                }
                return (
                    <li key={`${key}${i}`}>
                        <b>{key}</b>: {typeof msg[key] === 'string' ? msg[key] : this.buildMessage(msg[key])}
                    </li>
                );
            })}
        </ul>);
    }

    render() {
        const { tail, isLoading } = this.props;
        const { filter }= this.state;
        const filteredTail = this.filterTail(tail || [], filter);
        return (
            <>
                {isLoading && <Loader absolute backdrop />}
                <SearchToolbar container alignItems="center" wrap="nowrap">
                    <Search item>
                        <InputBase value={filter} onChange={this.onSearch} fullWidth margin="none" placeholder="Filter..." />
                    </Search>
                    <DividerStyled edge="end" orientation="vertical" />
                    <IconButton edge="end" onClick={this.loadConnectorLogTail}>
                        <MdiIcon name="refresh" />
                    </IconButton>
                </SearchToolbar>            
                {filteredTail.length ? (
                    filteredTail.map((t, i) => (
                        <Wrapper key={i} container>
                            <Grid item>
                                <PreBoxStyled>
                                    {this.buildMessage(JSON.parse(t.message))}
                                </PreBoxStyled>
                            </Grid>
                        </Wrapper>
                    ))
                ) : (
                    <NoMessageTypography>No messages</NoMessageTypography>
                )}
            </>
        );
    }
}

export default connect(
    (state, props) => ({
        isLoading: state.designer.pipelineConnectorLogTail.isLoading,
        tail: state.designer.pipelineConnectorLogTail.data,
    }),
    { connectorLogTailPipeline },
    null,
    { forwardRef: true }
)(ConnectorLog);