/* @flow */

import { gql } from '@apollo/client';

export default gql`
    query loadBackgroundJobLogsQuery($id: ID!) {
        result: backgroundJob(id: $id) {
            id
            logs {
              id
              backgroundJob
              msg
              time
              level
              stack
            }
        }
    }
`;
