/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, CircularProgress } from '@mic3/platform-ui';

import Immutable from 'app/utils/immutable/Immutable';
import history from 'store/History';
import { createEntity, updateEntity } from 'store/actions/entities/entitiesActions';
import PageNotAllowed from 'app/containers/ErrorPages/PageNotAllowed';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import { set, get } from 'app/utils/lo/lo';
import { setDocumentTitle } from 'store/actions/app/appActions';
import { modulesAndPageTitles } from 'app/config/typesConfig';

/**
 * Renders the view to add User Management.
 */
class UserAdd extends PureComponent<Object, Object> {
    static propTypes = {
        isLoading: PropTypes.bool,
        createEntity: PropTypes.func.isRequired,
        userProfile: PropTypes.object.isRequired
    };

    state: Object = {
        userData: Immutable(this.props.data)
    };

    formRef: Object = React.createRef();

    componentDidMount() {
        const { match, setDocumentTitle } = this.props;
        if(match?.path?.includes('add')){
            setDocumentTitle(modulesAndPageTitles.adminConsole.addUser);
        }
    }

    @bind
    @memoize()
    fieldDefinitions(isAdmin) {
        const { rename } = this.props;
        return [
            !rename && {
                type: 'text',
                properties: { label: 'Username', name: 'primary.username' },
                constraints: { required: true, minLength: 3, maxLength: 60, noWhiteSpace: true }
            },
            {
                field: 'name',
                type: 'text',
                properties: { label: 'Name', name: 'name' },
                constraints: { required: true, minLength: 3, maxLength: 60 }
            },
            !rename && {
                type: 'text',
                properties: { label: 'Email', name: 'primary.email' },
                constraints: { required: true, email: true }
            }
        ].filter(Boolean);
    }

    @bind
    onFormSubmit(event: Object) {
        event.preventDefault();
        const { reloadList } = this.props;
        this.formRef.current.isValidForm().then(async ({ data: formData, errors }) => {
            const { createEntity, updateEntity, data, onClose } = this.props;
            if (!errors) {
                if (data) {
                    const { id, name } = this.state.userData;
                    const resp = await updateEntity({ id, name: name.trim(), type: 'user' });
                    if (resp instanceof Error) return;
                    reloadList && reloadList();
                    return onClose();
                }
                const user = await createEntity({ ...this.state.userData, type: 'user', name: this.state.userData.name.trim() });
                if (user instanceof Error) return;
                history.push(`/user-management/${user.id}`);
            }
        });
    }

    @bind
    handleChange(data: Object) {
        const username = get(data, 'primary.username', '');
        if (username) {
            data = set(data, 'primary.username', this._normalizeValue(username));
        }
        this.setState({ userData: data });
    }

    @bind
    _normalizeValue(value: string) {
        if (value !== '') {
            return value.toLowerCase();
        }
    }

    @bind
    onClose() {
        const { onClose } = this.props;
        if (onClose) {
            return this.props.onClose();
        }
        history.pushBack();
    }

    render(): Object {
        const { isLoading, isLoadingUpdate, title, rename } = this.props;
        const { permissions, isAdmin } = this.props.userProfile;
        const permissionsSet = new Set(permissions || []);
        const canAdd = isAdmin || (rename ? permissionsSet.has('admin.user') :permissionsSet.has('admin.user.add'));
        if (!canAdd) {
            return <PageNotAllowed title="User" />;
        }

        const { userData } = this.state;
        return (
            <ModalDialog
                title={title || 'Create New User'}
                onClose={this.onClose}
                actions={isLoading || isLoadingUpdate ? <CircularProgress size={24} color="primary" /> : <Button onClick={this.onFormSubmit}>Save</Button>}
            >
                <FormGenerator components={this.fieldDefinitions(isAdmin)} ref={this.formRef} data={userData} onChange={this.handleChange} />
            </ModalDialog>
        );
    }
}

export default connect(
    (state: Object, ownProps: Object): Object => ({
        isLoading: state.admin.users.user.isLoading,
        isLoadingUpdate: state.entities.sidebar.details.isLoading,
        userProfile: state.user.profile
    }),
    { createEntity, updateEntity, setDocumentTitle }
)(UserAdd);
