/* @flow */

import {
    SET_SELECTED_LAYER,
    REFRESH_MAP_SIDEBAR_LAYERS,
    REFRESH_MAP_LAYERS_DRAWER,
    SET_ADD_PIN_MODAL,
    ENABLE_RELATION_COUNTER,
    DISABLE_RELATION_COUNTER,
    TOGGLE_ADD_PIN_BUTTON,
    SHOW_REPLAY_LAYER_COMPONENT
} from 'store/actions/maps/situationalAwarenessActions';
import Immutable from 'app/utils/immutable/Immutable';

/**
 * The initial state on our reducer
 */
const initialState = {
    refreshLayerSwitcher: false,
    isLayerSidebar: false,
    layerDrawerKey: 0,
    isDisabled: true,
    showAddPinBtn: false,
    showAddPinModal: false,
    loadingStatus: {},
    enableReplayLayerComponent: false, 
    selectedLayer: {},
};

/**
 * @param state - the current Redux state
 * @param action - the dispatched action
 * @returns the new Redux state
 */
const reducer = (state: Object = Immutable(initialState), action: Object) => {
    const { payload, type } = action;
    switch (type) {
        case SET_SELECTED_LAYER: {
            return Immutable({ ...state, selectedLayer: payload });
        }
        case REFRESH_MAP_SIDEBAR_LAYERS: {
            return Immutable({ ...state, refreshLayerSwitcher: payload});
        }
        case REFRESH_MAP_LAYERS_DRAWER: {
            return Immutable({...state, layerDrawerKey: state.layerDrawerKey + 1 });
        }
        case SET_ADD_PIN_MODAL: {
            return Immutable({ ...state, showAddPinModal: payload });
        }
        case TOGGLE_ADD_PIN_BUTTON: {
            return Immutable({ ...state, showAddPinBtn: payload });
        }
        case DISABLE_RELATION_COUNTER:
            return Immutable({ ...state, isDisabled: true });
        case ENABLE_RELATION_COUNTER:
            return Immutable({ ...state, isDisabled: false });
        case SHOW_REPLAY_LAYER_COMPONENT: {
            return Immutable({ ...state, enableReplayLayerComponent: payload });
        }
        default:
            return state;
    }
};

export default reducer;
