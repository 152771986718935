// @flow

import { bind } from 'app/utils/decorators/decoratorUtils';
import { Vector as VectorSource } from 'ol/source';
import VectorLayer from 'ol/layer/Vector';
import { Style} from 'ol/style';
import WKT from 'ol/format/WKT';
import { get } from 'app/utils/lo/lo';
import { rocketHost } from 'app/utils/env';
import { isDefined } from 'app/utils/utils';
import store from 'store/Store';
import moment from 'moment';
import { singleFeatureStyle } from 'app/components/molecules/Map/EntityPin/entityPinStyle';
import { loadEntitiesPins, loadTasks, loadProcesses, loadEventLayerPins, saveLayerFeatures } from 'store/actions/maps/situationalAwarenessActions';
import AffectliVectorLayer from 'app/containers/Maps/EntityLayerStyling/AffectliVectorLayer';
import mdiMapping from 'app/components/molecules/Map/EntityPin/entitiesPinMap.json';
import customIconSet from 'app/components/molecules/Map/EntityPin/customEntityPin.js';
import { getAttachmentUrl } from 'app/utils/attachments/attachmentsUtils';
import { getLayerFilterDefinitions, setDefaultFilters } from 'app/containers/Maps/LayerAbout/EntityLayer/MapsLayerFilterDefinitions';
import { addCustomOperators, buildCustomDefinitions } from 'app/utils/classification/classificationUtils';
import { LAYERS, PROCESSLAYERS, TASKLAYERS, create3DIconCanvas, getLayerType } from 'app/utils/maps/layer/layerUtils';
import { formatByDefinitions } from 'app/utils/filter/filterUtils';
import uuidv1 from 'uuid/v1';

const _getAttributesNamesByKeys = (data) => {
    if (!data) return {};
    const { styles } = data || {};
    const { counter, progress } = styles || {};
    if (!counter && !progress) return {};
    const valuesPath = { counter, progress };
    try {
        return Object.keys(valuesPath)
            .filter(key => valuesPath[key] && Object.keys(valuesPath[key]).length > 0)
            .map(key => styles[key])
            .filter(Boolean);
    } catch (error) {
        return {};
    }
};

class EntityLayer extends AffectliVectorLayer {
    dataSource: any;
    layer: any;
    layerId: string = null;
    style: Style = [];
    relatedEntities = [];
    dataSource3D: any = null;
    visible = null;
    filters = [];
    heatMapLayers = [];

    constructor(info: Object, map: Object) {
        super(info, map);
        this.layerId = get(info?.entity_type, 'id');
        this.setLayer();
        this.setFeatureStatus(false);
        this.setInitialFilters();
        this.olMap && this.olMap.getMap().render();
    }

    setVisibility(value) {
        this.visible = value;
    }

    getVisibility() {
        if (!this.visible) {
            this.visible = this.layer.get('visible');
        } else if (!this.getMapController().enable3D) {
            this.visible = this.layer.get('visible');
        }
        return this.visible;
    }


    @bind
    setLayer() {
        const { name, entityType, filters, entity_type, layerTitle, classData, isSelected, opacity, visible } = this.info || {};
        const layerId = uuidv1();
        let icon = '';
        if(layerTitle === 'Entity Layer')
            icon = 'things';
        else if(layerTitle === 'Event Layer')
            icon = 'event-monitor';
        else
            icon = 'globe';
        this.layer = new VectorLayer({
            name: this.info?.id || layerId,
            title: name,
            visible,
            opacity,
            selectedLayer: isSelected,
            attributes: {
                id: this.info?.id || layerId,
                type: entityType,
                subtitle: icon === 'globe' ? '' : 'entities',
                entityType: entity_type?.uri,
                layerType: layerTitle,
                iconName: icon,
                iconType: 'af',
                layerTitle,
                filters,
                visible,
                classData,
                noLocationPins: []
            },
            source: this.getDataSource(),
            style: this.styleFunction
        });
        this.addLayerToMap();
    }

    addLayerToMap() {
        const index = this.isLayerExistsInMap();
        if (isDefined(index) && index !== -1) {
            this.removeLayerFromMap(index);
        }
        const layersGroup = this.olMap?.getLayersGroup('allLayers');
        layersGroup && layersGroup.getLayers().array_.push(this.layer);

    }

    isLayerExistsInMap() {
        const layersGroup = this.olMap?.getLayersGroup('allLayers');
        const index = layersGroup?.getLayers()?.array_?.findIndex(lyr => lyr.values_?.attributes.id === this.info.id);
        return index;
    }

    removeLayerFromMap(index) {
        const layersGroup = this.olMap.getLayersGroup('allLayers');
        layersGroup.getLayers().array_.splice(index, 1);
    }

    @bind
    getLayer() {
        return this.layer;
    }

    @bind
    styleFunction(feature, resolution) {
        const pinStyle =  this.getLayerStyle();
        if(pinStyle && pinStyle !== 'hideEntities')
            return this.createDefaultStyle(feature);
    }

    @bind
    getLayerStyle() {
        const { styles } = this.info || {};
        const pinStyle = styles?.pinStyle || 'cluster';
        return pinStyle;
    }

    @bind
    createDefaultStyle(feature) {
        const is3DEnabled = this.olMap.is3dEnabled();
        const { classData, styles, related_entities } = this.info || {};
        const { progress, counter } = styles || {};
        const style = this.getStyleAttributes() || {};
        const { showCounters } = related_entities || {};
        const progressDefaultValue = this.getItemClassDefaultValues(classData, progress, false);
        const progressConstraints = this.getItemClassDefaultValues(classData, progress, true);
        const counterDefaultValue = this.getItemClassDefaultValues(classData, counter, false);
        const layerType = this.getLayerMainType();
        const pinStyle =  this.getLayerStyle();
        if(pinStyle && pinStyle !== 'hideEntities')
            return singleFeatureStyle(feature, this.olMap.getMap(), false, progress, counter, 
                layerType, progressDefaultValue, counterDefaultValue, is3DEnabled, style, progressConstraints, showCounters);
    }

    @bind
    getStyleAttributes() {
        const { styles } = this.info || {};
        return styles;
    }

    @bind
    getItemClassDefaultValues(item, selectedAttribute, progress) {
        const op = item?.formDefinition?.fields?.flat().map(op => op?.children?.filter(ch => ch?.properties?.name === selectedAttribute));
        let defaultVal = null;
        if(progress) { 
            defaultVal = op?.flat()?.[0]?.constraints;
        } else {
            defaultVal = op?.flat()?.[0]?.properties?.defaultValue;
        }
        if(isDefined(defaultVal))
            return defaultVal;
        return 0;
    }

    @bind    
    async getGQLResult() {
        const mainType = this.getLayerMainType();
        const { classData, entity_type } = this.info;
        const layerType = getLayerType(this.info);
        const filters = this.info?.filter_by || layerType?.filter_by || {};
        const fields = _getAttributesNamesByKeys(this.info) || {};
        const opMap = this.info ? this.info?.filter_by?.operatorsMap : {};
        const cachedOperators = get(store.getState(), 'filterbar.operatorsMap');
        const subType = entity_type?.uri;
        const entityType = mainType === 'entity' ? subType : mainType;
        const def = getLayerFilterDefinitions(entityType, classData, opMap);
        def.push({
            field: 'relations.relatedEntity.id',
            type: 'relatedEntities',
            properties: {
                label: 'Related Entities',
                name: 'relatedEntities',
            },
            condition: 'in',
            sort: false,
        });
        let filterBy = formatByDefinitions(filters || {}, def );
        filterBy = filterBy?.map((item) => (item.field === 'relations.relatedEntity.id' && Array.isArray(item.value)) ? 
            { ...item, value: item.value.map(v => v.id) } : 
            item
        );
        const filtersOps =  filters?.operatorsMap || cachedOperators || {};
        const userDefinedFilters = buildCustomDefinitions(classData, filtersOps);
        filterBy = addCustomOperators(def, filterBy, filtersOps, userDefinedFilters);

        if(mainType !== LAYERS.event) {
            filterBy.push(...[
                { field: 'geom', op: 'is not null' },
                { field: 'enableGis', op: '=', value: true }
            ]);
            if (this.filters.hasOwnProperty('active')) {
                const hasActiveField = filterBy.some(item => item.field === 'active');
                const value = this.filters?.active;
                if(!hasActiveField && value && value !== null )
                    filterBy.push({ field: 'active', op: '=', value: true });
                else if(!hasActiveField && !value && value !== null )
                    filterBy.push({ field: 'active', op: '=', value: false });
            }
            if(filters?.noLocation) {
                filterBy = filterBy.filter(item => item.field !== 'geom' && item.field !== 'enableGis');
            }
        }
    
        const typeFilter = { field: 'type', op: '=', value: mainType };

        if (TASKLAYERS.includes(mainType)) {
            const processDefs = this.filters?.processDefinitionName;
            if (processDefs?.length)
                filterBy.push([{ field: 'process.processDefinition.id', op: 'in', value: processDefs }]);
            if (mainType !== 'system_task')
                filterBy.push(typeFilter);
            return await loadTasks({filterBy});
        }
        if (PROCESSLAYERS.includes(mainType)) {
            if (mainType !== 'system_process'){
                filterBy.push(typeFilter);
            }
            return await loadProcesses({filterBy});
        }
        if (mainType === LAYERS.event) {
            try {
                const { event_type, time_range, filter_by } = this.info;
                const entityId = event_type?.id || event_type?.eventtype?.id;
                let eventTime = time_range || event_type?.timerange || filter_by?.time_range;
                if(!Array.isArray(eventTime)) {
                    const currentDate = new Date();
                    const calculatedDate = moment(currentDate)[eventTime.range](eventTime.amount, eventTime.unit).toDate();
                    eventTime = [eventTime.range === 'add' ? currentDate : calculatedDate, eventTime.range === 'add' ? calculatedDate: currentDate];

                }
                const eventFilterBy = [
                    { field: 'eventType.id', op: '=', value: entityId },
                    { field: 'device.geom', op: 'is not null' },
                    { field: 'time', op: 'between', value: eventTime }
                ];
                const variables = {}; 
                variables.filterBy = [ ...filterBy, ...eventFilterBy ].map((filter) => {
                    const { field, op, value } = filter;
                    if(['severity', 'status'].includes(field) && op === 'in' && !Array.isArray(value)){
                        return { ...filter, value: [value]};
                    }
                    return filter;
                });
                variables.orderBy = [{ field: 'createdDate', direction: 'desc nulls last' }];   
                return await loadEventLayerPins(variables);

            } catch(e) {
                console.log('getGQLResult fetching events ~ e:', e); // eslint-disable-line
            };
            
        }
        if (!entity_type) return null;
        return await loadEntitiesPins(entity_type?.uri, { type: entity_type?.uri, filterBy }, fields);
    }

    @bind
    async loader(extent, resolution, projection) {
        const mainType = this.getLayerMainType();
        const { styles } = this?.info;
        const style = styles?.pinStyle || 'cluster';
        try {
            const result = await this.getGQLResult();
            const { data } = result || {};
            const { records } = data || {};
            const clusterFeatures = [];
            const pointFeatures = [];
            const noLocationPins = [];
           
            const wktFormat = new WKT();
            records.length && records.forEach((feature) => {
                let eventWktString =  null;
                if(mainType === 'event') {
                    const eventEWkt = feature?.device?._ewkt;
                    eventWktString = eventEWkt.replace(/^SRID=\d+;/, '');
                    eventWktString = eventWktString.replace(/(\s+\d+(\.\d+)?)(\))$/, '$3');
                }
                const wkt = feature?._wkt || eventWktString; 
                if(style === 'cluster' && wkt) {
                    const geometry = wktFormat.readGeometry(wkt);         
                    if(geometry.getType() === 'Point') {
                        clusterFeatures.push(feature);
                    }   
                } else if(style === 'point' && wkt) {
                    pointFeatures.push(feature);
                }
                else if(!wkt) {
                    noLocationPins.push(feature);
                }
                
            });
            if(style !== 'heatMap') {
                const allFeatures =  style === 'point' || style === 'hideEntities' ? [...noLocationPins,...pointFeatures] : [...noLocationPins, ...clusterFeatures];
                store.dispatch(saveLayerFeatures(this.info.id, allFeatures));
            }
            const attributes = this.layer.get('attributes');
            attributes.noLocationPins = [];
            this.clearFeatures();
            records.forEach((ft) => {
                // eslint-disable-next-line prefer-const
                let { _wkt, id , ...rest } = ft;
                if(!_wkt){
                    const { device } = rest;
                    if(device){
                        const _ewkt  = device?._ewkt;
                        const _wktStr = _ewkt?.split(';')[1];
                        _wkt = _wktStr.replace('POINT', 'POINT Z');
                        rest.name = device.name;
                        rest.deviceId = device.id;
                        rest.entityType = 'system_eventlayer';
                        rest.deviceType = device.type;
                        rest.iconColor = device.iconColor;
                        rest.iconName = device.iconName;
                        rest.device = device;
                    }
                    else
                        attributes.noLocationPins?.push({...ft, type: mainType});
                }
                if (_wkt) {
                    const projection = rest?.primary?.locationInfo?.projection;
                    const format = new WKT();
                    let dataProj = null;
                    let isExtentInEPSG4326Bounds = null;
                    const geometry = format?.readGeometry(_wkt);
                    const extent = geometry?.getExtent();
                    if(extent) {
                        isExtentInEPSG4326Bounds =
                        extent[0] >= -180 && 
                        extent[1] >= -90 &&  
                        extent[2] <= 180 &&  
                        extent[3] <= 90;
                    }
                    if(projection?.srid) {
                        dataProj = `ESPG:${projection?.srid}`;
                    }
                    else if (isExtentInEPSG4326Bounds) {
                        dataProj = 'EPSG:4326';
                    } 
                    else {
                        dataProj = 'EPSG:3857';
                    }
                    const feature = format.readFeature(_wkt, {
                        dataProjection: dataProj,
                        featureProjection: 'EPSG:3857'
                    });
                    if (!feature.getGeometry().getExtent().includes(Infinity)) {
                        feature.setId(id);
                        feature.set('attributes', {
                            id,
                            type: mainType,
                            layerId: this.info?.id,
                            entityType: ft?.type,
                            _wkt,
                            ...rest
                        });
                        this.addFeature(feature);
                    }
                }
            });
            this.setFeatureStatus(true);
        } catch (e) {
            this.isLoadingError = true;
        }
        this.showFeatures(extent, false);
        this.olMap && this.olMap.getMap().render();
    }

    @bind
    async showFeatures(extent, ol3D) {
        let features = [];
        if(ol3D)
            features = this.features;
        else
            features = this.getFeatureUnderExtent(extent);

        this.dataSource.addFeatures(features);
        const selectedLayer = await this.olMap.checkSelectedLayer();
        const layerVisibility = selectedLayer?.length && this.olMap.checkLayerVisibility(selectedLayer?.[0]);
      
        if (layerVisibility && this.layer?.values_.selectedLayer) {
            this.olMap.highLightFeatures(this.layer?.values_?.attributes?.id);
        }
    }

    @bind
    getDataSource() {
        if (!this.dataSource) {
            this.dataSource = new VectorSource({
                loader: this.loader,
            });
        }
        return this.dataSource;
    }

    @bind
    clearDataSource(dataSource) {
        if (!dataSource) {
            this.clearFeatures();
            return;
        }
        dataSource && dataSource.clear(true);
        dataSource = typeof dataSource.getSource !== 'undefined' ? dataSource.getSource() : null;
        this.clearDataSource(dataSource);
    }

    @bind
    calculateFieldRange(fieldName): Array {
        const features = this.dataSource.getFeatures();
        let minVal = null, maxVal = null;
        features.forEach((feature) => {
            const attributes = feature.get('attributes')?.attributes;
            if (attributes && attributes.hasOwnProperty(fieldName)) {
                const val = attributes[fieldName];
                if (!minVal || minVal > val) minVal = val;
                if (!maxVal || maxVal < val) maxVal = val;
            }
        });
        return [minVal, maxVal];
    }

    @bind
    async setRelatedEntities(primary) {
        const type = this.info?.type;
        const layerId = this.info?.id + '-r' + this.relatedEntities.length;
        const info = {
            id: layerId,
            primary,
            parentLayer: this,
            styles: {...primary.styles,  pinStyle: 'related' },
            related_entities: primary.related_entities,
            visible: primary.visible,
            type: type
        };
        this.olMap.addEntityLayer(info);
    }

    @bind
    toggleTo3DStyling(Cesium, ol3D, enable3D, map) {
        const dataSourceCollection = ol3D?.getDataSources();
        const zoomLevel = map?.getMap()?.getView().getZoom();
        if (enable3D) {
            const twoLayersVisibility = this.layer?.getVisible();
            this.layer.setProperties({ visible3d: twoLayersVisibility });
            const twoDOpacity = this.layer?.getOpacity();
            this.layer.setVisible(false);
            if (this.dataSource3D || this.dataSource) {
                this.dataSource3D?.entities?.removeAll();
                dataSourceCollection && dataSourceCollection.remove(this.dataSource3D);
                this.dataSource3D = null;
                this.layer.setVisible(twoLayersVisibility);
                this.olMap && this.olMap.getMap().render();
            }
            if (!this.dataSource3D) {
                this.dataSource3D = new Cesium.GeoJsonDataSource(this.layerId);
            }
            const threedData = this.dataSource3D.load(this.toGeoJSON(), {}); 
            dataSourceCollection && dataSourceCollection.add(threedData).then((dataSource) => {
                dataSource.entities.values.forEach(async (entity, index) => {
                    const attributes = entity._properties?._attributes?._value;
                    const { id, type, model3d, modelHeading, primaryClass, modelScaling } = attributes;
                    const entityModel3d = primaryClass?.entityModel3d || null;
                    const threeDModel = model3d || entityModel3d;
                    const heading = Cesium.Math.toRadians(modelHeading || 135);
                    const pitch = 0;
                    const roll = 0;
                    const hpr = new Cesium.HeadingPitchRoll(heading, pitch, roll);
                    entity.orientation = Cesium.Transforms.headingPitchRollQuaternion(
                        entity.position._value,
                        hpr
                    );
                    if(threeDModel && zoomLevel > 10) {
                        const isModel = true;
                        const imageUrl = getAttachmentUrl(id, type, '', isModel);
                        // const protocol = document.location.protocol;
                        const host = rocketHost.split('/chat')[0];
                        const url = `https://${host}` +  imageUrl.split('?')[0];
                        const modelColor = Cesium.Color.fromCssColorString(attributes?.iconColor);
                        // const url = `http://localhost:3000/${imageUrl.split('?')[0]}`;
                        await fetch(url)
                            .then(response => response.text())
                            .then((result) => {
                                if(result) {
                                    entity.model = new Cesium.ModelGraphics({
                                        uri: url,
                                        color: modelColor,
                                        colorBlendMode: Cesium.ColorBlendMode.MIX,
                                        colorBlendAmount: 0.5,
                                        minimumPixelSize: 128,
                                        maximumScale: 20000,
                                        scale: (modelScaling && modelScaling * 3) || 1
                                    });
                                    entity.billboard.image = null;
                                }
                                
                            })
                            // eslint-disable-next-line no-console
                            .catch(error => console.log('error', error));
                    } else {
                        const { iconColor, iconName } = attributes;
                        const icon = iconName || 'map-marker';
                        const name = mdiMapping?.[icon] || customIconSet?.[icon];
                        const text = String.fromCodePoint(name);
                        entity.billboard.image = null;
                        entity.billboard.image = create3DIconCanvas(text, {
                            fontSize: '20',
                            fontFamily: 'Material Design Icons',
                            fillColor: iconColor || '#00BCD4',
                            strokeColor: '#000000',
                            strokeWidth: 0.5,
                            twoAlpha: twoDOpacity
                        });
                        entity.billboard.color = new Cesium.Color(1.0,1.0,1.0, twoDOpacity === 100 ? 1 : twoDOpacity);
                        dataSource.show = twoLayersVisibility;
                    }
                });
            });
            const scene = ol3D?.getCesiumScene();
            if(scene?.requestRender)
                scene.requestRender();
        } else {
            this.dataSource && dataSourceCollection && dataSourceCollection.remove(this.dataSource3D);
            this.layer.setVisible(this.layer?.values_?.visible);
            this.olMap && this.olMap.getMap().render();
        }

    }

    @bind
    addRelatedEntities(el: EntityLayer) {
        this.relatedEntities.push(el);
    }

    @bind
    updateRelatedEntityLayer(relatedEntities: Array) {
        this.relatedEntities.length = 0;
        relatedEntities?.forEach((rl) => {
            this.relatedEntities.push(rl);
            rl.parentLayer = this;
        });
    }

    @bind
    setFilters(filters) {
        this.filters = filters;
    }

    @bind
    updateInfo(newData: any){
        if(!newData) return;
        this.info = newData;
        this.setFeatureStatus(false);
    }

    setInitialFilters(){
        const { type, filter_by } = this.info;
        this.filters = setDefaultFilters(filter_by, type);
    }

    @bind 
    getInitialFilters() {
        return this.filters;
    }
    
    @bind
    addRecordsToLayer(records) {
        if(!records?.length) {
            return null;
        }
        const mainType = this.getLayerMainType();
        const attributes = this.layer.get('attributes');
        attributes.noLocationPins = [];
        records.forEach((ft) => {
            // eslint-disable-next-line prefer-const
            let { _wkt, id , ...rest } = ft;
            if(!_wkt){
                const { device } = rest;
                if(device){
                    const _ewkt  = device?._ewkt;
                    const _wktStr = _ewkt?.split(';')[1];
                    _wkt = _wktStr.replace('POINT', 'POINT Z');
                    rest.name = device.name;
                    rest.deviceId = device.id;
                    rest.entityType = 'system_eventlayer';
                    rest.deviceType = device.type;
                    rest.iconColor = device.iconColor;
                    rest.iconName = device.iconName;
                    rest.device = device;
                }
                else
                    attributes.noLocationPins.push({...ft, type: mainType});
            }
            if (_wkt) {
                const projection = rest?.primary?.locationInfo?.projection;
                const format = new WKT();
                const feature = format.readFeature(_wkt, {
                    dataProjection: projection?.srid ? `ESPG:${projection?.srid}` : 'EPSG:4326',
                    featureProjection: 'EPSG:3857'
                });
                if (!feature.getGeometry().getExtent().includes(Infinity)) {
                    feature.setId(id);
                    feature.set('attributes', {
                        id,
                        type: mainType,
                        entityType: ft?.type,
                        _wkt,
                        ...rest
                    });
                    this.addFeature(feature);
                }
            }
        });
    }

    @bind
    async refresh3DMapLayers(Cesium, ol3D, enable3D) {
        const dataSourceCollection = ol3D?.getDataSources();
        const twoDVisibile = this.layer?.getVisible();
        const is3DVisible = this.layer.getProperties().visible3d;  
        if ((twoDVisibile || (is3DVisible && ol3D)) ) {
            if (this.dataSource3D) {
                this.dataSource3D?.entities?.removeAll();
                dataSourceCollection && dataSourceCollection.remove(this.dataSource3D);
                this.dataSource3D = null;
                this.layer.setVisible(true);
                this.olMap && this.olMap.getMap().render();
            }
            this.clearDataSource();
            this.features = [];
            this.allFeatures = [];
            const result = await this.getGQLResult();
            const { data } = result || {};
            const { records } = data || {};
            await this.addRecordsToLayer(records);
            store.dispatch(saveLayerFeatures(this.info.id, records));
            const extent = this.olMap.getMap().getView().calculateExtent(this.olMap.getMap().getSize());
            this.showFeatures(extent, ol3D);
            if(ol3D && is3DVisible)
                this.toggleTo3DStyling(Cesium, ol3D, enable3D);
        }
    }

    @bind
    addHeatMapLayer(el: EntityLayer) {
        this.heatMapLayers.push(el);
    }

    @bind
    async setHeatMapLayer(primary) {
        const type = this.info?.type;
        const layerId = `${this.info?.id}-heatmap`;
        const updatedPrimary = { ...primary, styles: {  ...primary.styles,  pinStyle: 'heatMap' }};
        const info = {
            id: layerId,
            ...updatedPrimary,
            parentLayer: this,
            type: type
        };
        this.olMap.addEntityLayer(info);
    }

    @bind
    isHeatMapLayer() {
        return this.isHeatMap;
    }
}

export default EntityLayer;
