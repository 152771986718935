/* @flow */

import React, { memo } from 'react';
import styled from 'styled-components';
import {  ListItem, ListItemText } from '@mic3/platform-ui';


export const ListItemStyled = styled(ListItem)`
    ${({ inlinedivider }) => inlinedivider ? 'border-right: 1px solid rgba(255, 255, 255, 0.12) !important;': ''}
    & .MuiListItemAvatar-root {
        min-width: 40px;
    }
`;

export const ListItemTextStyled = styled(ListItemText)`
    .MuiListItemText-primary {
        font-size: 12px !important;
        color: ${ ( { theme } ) => theme.material.colors.text.primary };
    }
    .MuiListItemText-secondary {
        font-size: 16px !important;
    }
`;


const ComponentStyleWrapper = React.forwardRef(({ editablePanel, editable, view, Component, ...rest }, ref) => {
    if(!editablePanel) {
        return (
            <Component {...rest} ref={ref} />
        );
    }
    return !editable ? view : (
        <Component {...rest} ref={ref} />
    );
});

export default memo(ComponentStyleWrapper);
