export const GROUPS_DATA_TABLE = '@dataTable/admin/groups';
export const GROUP_ENTITIES_DATA_TABLE = '@dataTable/admin/group/entities';
export const GROUP_ENTITIES_ADD_DATA_TABLE = '@dataTable/admin/group/entities/add';
export const GROUP_USERS_DATA_TABLE = '@dataTable/admin/group/users';
export const GROUP_USERS_ADD_DATA_TABLE = '@dataTable/admin/group/users/add';
export const GROUP_CLASSIFICATIONS_DATA_TABLE = '@dataTable/admin/group/classifications';
export const GROUP_CLASSIFICATIONS_ADD_DATA_TABLE = '@dataTable/admin/group/classifications/add';

export const USERS_DATA_TABLE = '@dataTable/admin/users';

export const ADD_CLASSIFICATIONS_DATA_TABLE = '@dataTable/add/classifications';

export const CLASSIFICATIONS_DATA_TABLE = '@dataTable/entities/classifications';
export const CLASSIFICATION_ENTITIES_DATA_TABLE = '@dataTable/entities/classifications/entities';

export const ORGANISATIONS_DATA_TABLE = '@dataTable/entities/organisations';
export const ORGANISATION_ATTACHMENTS_DATA_TABLE = '@dataTable/entities/organisation/attachments';

export const PEOPLE_DATA_TABLE = '@dataTable/entities/people';
export const PERSON_ATTACHMENTS_DATA_TABLE = '@dataTable/entities/person/attachments';

export const THINGS_DATA_TABLE = '@dataTable/entities/things';
export const THING_ATTACHMENTS_DATA_TABLE = '@dataTable/entities/thing/attachments';

export const EVENTS_DATA_TABLE = '@dataTable/stream/events';

export const BROADCASTS_DATA_TABLE = '@dataTable/broadcasts';

export const ABOX_PROCESSES_DATA_TABLE = '@dataTable/abox/processes';

export const CUSTOM_ENTITIES_TABLE = '@dataTable/entities/customEntities';
export const CUSTOM_ENTITIES_ATTACHMENTS_DATA_TABLE = '@dataTable/entities/customEntities/attachments';
