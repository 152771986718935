// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled, { withTheme } from 'styled-components';

import { Button, IconButton, MdiIcon, Tooltip, Typography } from '@mic3/platform-ui';
import Switch from '@material-ui/core/Switch';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import ToolBar from 'app/components/molecules/ToolBar/ToolBar';

import { openMapSidebar, expandMapSidebar, shrinkMapSidebar } from 'store/actions/maps/mapSidebarActions';
import { toggleFullScreenMap, updateMapData } from 'store/actions/maps/situationalAwarenessActions';
import { loadEntityLocationHistory } from 'store/actions/maps/situationalAwarenessActions';
import DotMenu from 'app/components/molecules/DotMenu/DotMenu';
import WKT from 'ol/format/WKT';
import { get } from 'app/utils/lo/lo';

const FloatingToolbar = styled(ToolBar)`
    position: absolute;
    top: 10px;
    z-index: 20;
    right: ${({ move }) => (move ? '380px' : '10px')};
    border-radius: 40px;
    background: ${({ theme }) => theme.material.colors.background.default};
`;

const SnacbarHeader = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Footer = styled.div`
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
`;

class AboutMapToolbar extends PureComponent<Object, Object> {
    constructor(props: Object) {
        super(props);
        this.state = {
            fullscreen: false,
            isHistory: false,
        };
        this.snr = null;
    }

    componentWillUnmount() {
        this.props.toggleFullScreenMap(false);
    }

    @bind
    searchMapLocation(searchType) {
        const { olMap, entitySvg, snackbarRef, svgTransformation, id, type } = this.props;
        this.handleScaleNRotate({ olMap, entitySvg, snackbarRef, svgTransformation });
        this.props.openMapSidebar({ title: searchType, map: olMap, svgSource: entitySvg, snackbarRef, type, id });
    }

    @bind
    changeBaseMap(props) {
        const { olMap } = this.props;
        const allBaseLayers = olMap.getMap().getLayers()?.array_?.[0]?.values_?.layers?.array_;
        for (const lyr of allBaseLayers) {
            const baseLayer = get(lyr, 'values_.title');
            if (props.name !== baseLayer) {
                lyr.setVisible(false);
            }
            else {
                lyr.setVisible(true);
            }
        }
    }

    @bind
    handleChangeHistory() {
        this.setState({ isHistory: !this.state.isHistory }, () => {
            const { isHistory } = this.state;
            if (isHistory) {
                this.getLocationHistory();
            } else {
                this.props.setFirstPin();
            }
        });
    }

    @bind
    toggleFullScreen() {
        const { toggleFullScreenMap, expandMapSidebar, shrinkMapSidebar, isSidebar } = this.props;
        this.setState({ fullscreen: !this.state.fullscreen }, () => {
            toggleFullScreenMap(this.state.fullscreen);
            if (!isSidebar) {
                return;
            }
            if (this.state.fullscreen) {
                return expandMapSidebar();
            }
            shrinkMapSidebar();
        });
    }

    @bind
    async getLocationHistory() {
        const { id, type, olMap } = this.props;
        const variables = {
            'startIndex': 0,
            'stopIndex': 9,
            entityType: type,
            'filterBy': [
                {
                    'field': 'entity.id',
                    'op': '=',
                    'value': id
                }
            ]
        };
        const { data } = await loadEntityLocationHistory(variables);
        const { records } = data || {};
        const historyFeatures = [];
        records && records.forEach((item) => {
            const { _wkt } = item;
            if (_wkt) {
                const format = new WKT();
                const historyPoint = format.readFeature(_wkt, {
                    dataProjection: 'EPSG:4326',
                    featureProjection: 'EPSG:3857'
                });
                historyFeatures.push(historyPoint);
                // }
                const map = olMap.getMap();
                const currentZoom = map && map.getView().getZoom();
                if (currentZoom > 4) {
                    map.getView().setZoom(4);
                }
            }
        });
        historyFeatures?.length && olMap.addHistoryFeaturesToMap(historyFeatures);
    }

    @bind
    handleScaleNRotate(props) {
        const { olMap, snackbarRef } = this.props;
        const map = olMap.getMap();

        const clickListener = (evt) => {
            const feature = map.forEachFeatureAtPixel(evt.pixel, (feature) => {
                return feature;
            });
            if (feature) {
                // Scale and rotate functionality is broken which is affecting svg click on both class map and entity location map.
                // commenting it until its properly fixed.
                // this.snr = new ScaleNRotate(olMap, feature, this.props.svgTransformation);
                const message = this.renderSvgMessage();
                const action = this.renderSvgActions();
                snackbarRef.current.addAction(action);
                snackbarRef.current.addMessage(message);
                snackbarRef.current.handleOpen(true);
                map.un('click', clickListener);
            }
        };
        map.on('click', clickListener);
    }

    @bind
    renderSvgMessage() {
        const header = 'Scale and Rotate Svg';
        return (
            <>
                <SnacbarHeader>
                    <Typography variant="body1">{header}</Typography>
                    <MdiIcon name="close" onClick={this.onClose} size={'small'} />
                </SnacbarHeader>
                <Typography variant="caption">Drag corner points to scale svg.</Typography>
            </>
        );
    }

    @bind
    renderSvgActions() {
        const { snackbarRef } = this.props;
        return (
            <Footer>
                <Button variant="text" onClick={() => snackbarRef.current.handleOpen(false)}>
                    Cancel
                </Button>
                <Button onClick={this.saveSvgScale}>Save</Button>
            </Footer>
        );
    }

    @bind
    saveSvgScale() {
        const { id, type } = this.props;
        const scaleAngele = this.snr.getScaleAndRotateParams();
        const data = {
            id,
            svgTransformation: {
                scale: scaleAngele.scale,
                rotation: scaleAngele.rotate
            },
            type
        };
        this.props.updateMapData(data);
    }

    @bind
    @memoize()
    renderDotMenu(isHistory) {
        const moreMenu = [
            {
                name: 'Hide Location History',
                icon: 'history',
                component: <Switch checked={isHistory} name="history" onClick={this.handleChangeHistory} />
            },
            {
                name: 'Scale and Rotate SVG',
                icon: 'globe',
                onItemClick: props => this.handleScaleNRotate(props)
            }
        ];
        return <DotMenu items={moreMenu} />;
    }

    @bind
    renderBasemapMenu() {
        const moreMenu = [
            {
                name: 'Bing (Aerial)',
                icon: 'globe',
                onItemClick: props => this.changeBaseMap(props)
            },
            {
                name: 'Bing (Roads)',
                icon: 'globe',
                onItemClick: props => this.changeBaseMap(props)
            },
            {
                name: 'OSM',
                icon: 'globe',
                onItemClick: props => this.changeBaseMap(props)
            }
        ];
        return <DotMenu items={moreMenu} icon="web" />;
    }

    render() {
        const { isHistory } = this.state;
        const { isSidebar, isFullScreen, svgMap } = this.props;
        if (isSidebar && !isFullScreen && !isHistory) {
            return null;
        }

        return (
            <FloatingToolbar
                children={
                    <>
                        {svgMap ? (
                            <>
                                <Tooltip title="Search Svg" placement="bottom" arrow>
                                    <IconButton onClick={() => this.searchMapLocation('SvgMapSearch')}>
                                        <MdiIcon name="magnify" />
                                    </IconButton>
                                </Tooltip>
                                {this.renderBasemapMenu()}
                            </>
                        ) : (
                            <>
                                <Tooltip title="Full Screen Map" placement="bottom" arrow>
                                    <IconButton onClick={this.toggleFullScreen}>
                                        <MdiIcon name="fullscreen" />
                                    </IconButton>
                                </Tooltip>
                                {this.props.isFullScreen && (
                                    <>
                                        <Tooltip title="Search Location" placement="bottom" arrow>
                                            <IconButton onClick={() => this.searchMapLocation('Search')}>
                                                <MdiIcon name="magnify" />
                                            </IconButton>
                                        </Tooltip>
                                        {this.renderDotMenu(isHistory)}
                                    </>
                                )}
                            </>
                        )}
                    </>
                }
            />
        );
    }
}

export default connect(
    state => ({
        isFullScreen: state.maps.situationalAwareness.map.mapFullScreen,
        history: state.entities.common.history,
        isSidebar: state.sidebar.isOpen
    }),
    {
        openMapSidebar,
        expandMapSidebar,
        shrinkMapSidebar,
        toggleFullScreenMap,
        loadEntityLocationHistory,
        updateMapData
    }
)(withTheme(AboutMapToolbar));
