// @flow
import { graphql } from 'graphql/client';

import OptionsBuilder from 'app/utils/api/OptionsBuilder';
import { loadData, mutateData } from 'app/utils/redux/action-utils';
import { backgroundJobsQuery } from 'graphql/abox/backgroundJob/backgroundJobsQuery';
import loadBackgroundJobDetailsQuery from 'graphql/abox/backgroundJob/loadBackgroundJobDetailsQuery';
import runExportEntityQuery from 'graphql/abox/backgroundJob/runExportEntityQuery';
import loadBackgroundJobLogsQuery from 'graphql/abox/backgroundJob/loadBackgroundJobLogsQuery';
import runScriptMutation, { runScriptMutationQuery } from 'graphql/abox/backgroundJob/runScriptMutation';
import { addRoleToList, addRoleToData } from 'app/config/rolesConfig';

export const LOAD_BACKGROUND_JOB_LIST_STARTED = '@@affectli/backgroundjob/LOAD_BACKGROUND_JOB_LIST_STARTED';
export const LOAD_BACKGROUND_JOB_LIST = '@@affectli/backgroundjob/LOAD_BACKGROUND_JOB_LIST';

export const LOAD_BACKGROUND_JOB_DETAILS_STARTED = '@@affectli/backgroundjob/LOAD_BACKGROUND_JOB_DETAILS_STARTED';
export const LOAD_BACKGROUND_JOB_DETAILS = '@@affectli/backgroundjob/LOAD_BACKGROUND_JOB_DETAILS';

export const LOAD_BACKGROUND_JOB_LOGS_STARTED = '@@affectli/backgroundjob/LOAD_BACKGROUND_JOB_LOGS_STARTED';
export const LOAD_BACKGROUND_JOB_LOGS = '@@affectli/backgroundjob/LOAD_BACKGROUND_JOB_LOGS';

export const RUN_EXPORT_ENTITY_VERSION_STARTED = '@@affectli/backgroundjob/RUN_EXPORT_ENTITY_VERSION_STARTED';
export const RUN_EXPORT_ENTITY_VERSION = '@@affectli/backgroundjob/RUN_EXPORT_ENTITY_VERSION';

export const RUN_SCRIPT_STARTED = '@@affectli/backgroundjob/RUN_SCRIPT_STARTED';
export const RUN_SCRIPT = '@@affectli/backgroundjob/RUN_SCRIPT';

/**
 * Loads the assigned tasks.
 */
export const loadBackgroundJobs = ({ view, ...options }: Object = {}) => {
    const variables = new OptionsBuilder(options).defaultStartStopIndexs(0, 30).build();

    return loadData(
        LOAD_BACKGROUND_JOB_LIST_STARTED,
        LOAD_BACKGROUND_JOB_LIST,
        backgroundJobsQuery(view),
        addRoleToList
    )({ ...variables, startIndex: options.startIndex });
};

export const loadBackgroundJobDetails = (id: string) =>
    loadData(LOAD_BACKGROUND_JOB_DETAILS_STARTED, LOAD_BACKGROUND_JOB_DETAILS, loadBackgroundJobDetailsQuery, addRoleToData)({ id, type: 'backgroundjob' });

export const loadBackgroundJobLogs = (id: string) =>
    loadData(LOAD_BACKGROUND_JOB_LOGS_STARTED, LOAD_BACKGROUND_JOB_LOGS, loadBackgroundJobLogsQuery)({ id });


export const runExportEntityVersion = (type, id, extraOptions) => {
    return mutateData(
        RUN_EXPORT_ENTITY_VERSION_STARTED,
        RUN_EXPORT_ENTITY_VERSION,
        runExportEntityQuery,
    )({ id, type, ...extraOptions });
};

export const runScript = ({ file, ...variables}) => (dispatch, state) => {

    if(file) {
        return graphql.upload({ 
            query: runScriptMutationQuery, 
            variables: { file, ...variables }, 
            file, map: 'variables.file'
        });
    }

    return mutateData(
        RUN_SCRIPT_STARTED,
        RUN_SCRIPT,
        runScriptMutation,
    )(variables)(dispatch, state);
};