/* @flow */
import { get } from 'app/utils/lo/lo';

/**
 *  This function will take two array and returns those values that are common in both arrays
 */
const findIntersection = (arr1: $ReadOnlyArray<number | string>, arr2: $ReadOnlyArray<number | string>) => {
    return ((arr1 || []).filter((value) => -1 !== (arr2 || []).indexOf(value)): Array<string | number>);
};

/**
 * This function will take and array and returns an other array with no duplicate values
 */
const getUnique = (arr: Array<number | string>) => {
    return [...new Set(arr)];
};

/**
 * This function will take and array of objects and a unique key which is by default id, and remove duplicates according to the unique key
 */
const removeDuplicates = (arr: Array<Object>, uniqueKey: string = 'id') => {
    return arr.filter((item, index, self) => self.findIndex((t) => t?.[uniqueKey] === item?.[uniqueKey]) === index);
};

/**
 * This function will take and array of objects and a unique key which is by default id, and returns the distinct and unique elements
 */
const getDistinctArrayOfObjects = (arr1: Array<Object>, arr2: Array<Object>, uniqueKey: string = 'id') => {
    return arr1.filter((item) => arr2.findIndex((x) => get(x, uniqueKey) === get(item, uniqueKey)) === -1);
};

/**
 * For each wait for all the promises to finish.
 */
const asyncForEach = async (array: any, callback: function) => {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
};

/**
 * This funtion will take two number of string array and returns true if any common element exists between those array or else false
 */
const commonElementExists = (arr1: $ReadOnlyArray<number | string>, arr2: $ReadOnlyArray<number | string>) => {
    for (const val1 of arr1) {
        for (const val2 of arr2) {
            if (val1 === val2) return true;
        }
    }
    return false;
};

const hasDuplicates = (arr: Array<number | string>) => {
    return new Set(arr).size !== arr.length;
};

export const deepCloneArray = (arr) => {
    if (!Array.isArray(arr)) {
        return arr;
    }
    return arr.map((item) => {
        if (item === null || typeof item !== 'object') {
            // If the array contains primitive values or null, return them directly
            return item;
        } else if (Array.isArray(item)) {
            // If the array element is itself an array, recursively deep clone it
            return deepCloneArray(item);
        } else {
            // If the array element is an object, recursively deep clone it
            return deepCloneObject(item);
        }
    });
};

export const deepCloneObject = (obj) => {
    if (obj === null || typeof obj !== 'object') {
        return obj;
    }
    // Create a new object and deep clone each property
    const clonedObj = {};
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            clonedObj[key] = deepCloneObject(obj[key]);
        }
    }
    return clonedObj;
};
/**
 * This function will receive an array of objects as a first argument
 * and the key as second argument by which we want to sort the array
 * For example we have the array which contains objects like
 * [{ name: "User", createdBy: { name: "Admin"}, department: { name: "Mi-C3", buildBy: { name: "Glen Scott" }}};
 *  { name: "Affectli", createdBy: { name: "Rachel"}, department: { name: "Mi-C3", buildBy: { name: "Stefan Badenhorst" }}}];
 * User can sort array by any key like "name" || "createdBy.name" || "department.buildBy.name"
 */
const sortByField = (arr: Array<Object>, sortByKey: string) => {
    if (!sortByKey) {
        return arr;
    }
    return arr.sort((a, b) => {
        const keys = sortByKey.split('.');
        const len = keys.length;
        let i = 0;
        while (i < len) {
            a = (a && a[keys[i]]) || '';
            b = (b && b[keys[i]]) || '';
            i++;
        }
        return a > b ? 1 : b > a ? -1 : 0;
    });
};

export const acceptedFileTypes = [
    '.jpg',
    '.jpeg',
    '.png',
    '.gif',
    '.bmp',
    '.webp',
    '.svg',
    '.csv',
    '.tiff',
    '.ico',
    '.psd',
    '.heic',
    '.doc',
    '.docx',
    '.ppt',
    '.pptx',
    '.xls',
    '.xlsx',
    '.pdf',
    '.rtf',
    '.zip',
    '.tar',
    '.7z',
    '.rar',
    '.gzip',
    '.bz2',
    '.torrent',
    '.iso',
    '.dmg',
    '.swf',
    '.mp3',
    '.ogg',
    '.wav',
    '.m4a',
    '.flac',
    '.aac',
    '.wma',
    '.mp4',
    '.mov',
    '.avi',
    '.flv',
    '.webm',
    '.mpeg',
    '.3gp',
    '.3g2',
    '.mkv',
    '.rv',
    '.txt',
    '.html',
    '.css',
    '.js',
    '.json',
    '.xml',
    '.xhtml',
];

export {
    findIntersection,
    getUnique,
    asyncForEach,
    commonElementExists,
    hasDuplicates,
    sortByField,
    removeDuplicates,
    getDistinctArrayOfObjects,
};
