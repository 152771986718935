import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { muiTheme } from 'app/themes/materialUi';

import { BryntumTaskBoard } from '@bryntum/taskboard-react';
import { StringHelper } from '@bryntum/taskboard/taskboard.module.js';
import Loader from 'app/components/atoms/Loader/Loader';
import { taskboardConfig } from './BetaTaskBoardConfig';

import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { toUniqueUUID } from 'app/utils/string/string-utils';
import { get, set, sortBy } from 'app/utils/lo/lo';
import { isDefined } from 'app/utils/utils';
import { copyToClipboard } from 'app/utils/classification/classificationUtils';
import { updateEntity, loadEntity } from 'store/actions/entities/entitiesActions';
import { saveBoardPreferences } from 'store/actions/admin/usersActions';
import { setSelectedBoard } from 'store/actions/abox/boardActions';

import { showToastr } from 'store/actions/app/appActions';
import { saveComponentState } from 'store/actions/component/componentActions';
import { setColumnPosition, updateRelationColumnToBoard } from 'store/actions/abox/boardActions';
import { createRelationship, deleteRelationship } from 'store/actions/entities/relationshipsActions';

import '@bryntum/taskboard/taskboard.classic-dark.css';

const BoardWrapper = styled.div`
    width: 100%;
    height: 100%;

    .b-taskboard-body {
       overflow: auto !important;
    }

    .b-taskboardbase{
        --taskboard-color: ${({theme})=> theme.material.colors.text.primary} !important;
    }
    & .b-taskboardbase.b-taskdrag, & .b-panel .b-panel-body-wrap {
        background: transparent !important;
        --taskboard-taskdrag-drop-background: transparent !important;
        --taskboard-taskdrag-drop-border: 1px solid ${({theme})=> theme.material.colors.background.divider} !important;
    }
    .b-taskboard {
        height: 100%;
        --taskboard-background: transparent;
        --taskboard-body-padding: 0em;
        --taskboard-column-gap: 2em;
        --taskboard-gap: 10px;
        --taskboard-column-background: transparent;
        --taskboard-column-border-radius: 3px;
        --taskboard-column-header-background:  ${({ theme }) => theme.material.colors.background.default};
        --taskboard-column-header-icon-color: ${({ theme }) => theme.material.colors.text.secondary};
        --taskboard-swimlane-header-background:transparent;
        --taskboard-swimlane-header-font-size: 14px;
        --taskboard-column-header-font-size:14px;
        --taskboard-column-header-color: ${({ theme }) => theme.material.colors.text.secondary};
        --taskboard-column-header-collapsed-title-top: 3rem;
        --taskboard-progressitem-color : ${({ theme }) => theme.material.colors.background.progress};
        --taskboard-column-min-width: 350px;
        --taskboard-card-footer-padding: 10px;
        --taskboard-card-footer-color: ${({ theme }) => theme.material.colors.text.caption} !important;
        --taskboard-swimlane-header-icon-color: ${({theme})=> theme.material.colors.secondary.main}
    }

    & .b-taskboard-progress-outline {
        background: ${({ theme }) => theme.material.colors.background.progressLine} !important;
    }
    & .b-taskboard-body.b-horizontal-overflow .b-taskboard-column-headers{
        background: ${({ theme }) => theme.material.colors.background.default} !important;
    }
    & .b-taskboard-swimlane-header{
        background: ${({ theme }) => theme.material.colors.background.default};
    }
    & .b-taskboard-body.b-horizontal-overflow .b-taskboard-column-headers{
        background: ${({ theme }) => theme.material.colors.background.default};
    }

    & .b-taskboard-card-footer{
        color: #ffffff61 !important;
        font-size: 12px !important;
    }
    & .footer-icon{
        font-size: 16px !important;
        position: relative;
        top: 2px;
    }
    & .b-taskboard-column-expander, .b-taskboard-swimlane-expander {
      transform:none;
    }
    & .b-taskboard-swimlane.b-collapsed .b-taskboard-swimlane-expander {
      transform: rotate(270deg);
    }
    & .b-taskboard-swimlane-title{
        letter-spacing:1px;
        color: ${({theme})=> theme.material.colors.text.primary};
    }
    & .b-taskboard-swimlane {
        border-bottom: 0.5px solid ${({theme})=> theme.material.colors.background.divider} !important;
    }
    & .b-taskboard-swimlane-count {
        color: ${({ theme }) => theme.material.colors.text.secondary} !important;
    }
    & .b-taskboard-column-headers{
        width:auto !important;
        min-width: 100%;
        margin-bottom:5px;
        border-bottom: 0.5px solid ${({theme})=> theme.material.colors.background.divider}  !important;
    }
    & .b-taskboard-swimlane-header {
        border-bottom: 0.5px solid ${({theme})=> theme.material.colors.background.divider} !important;
        border-top: 0.5px solid ${({theme})=> theme.material.colors.background.divider}  !important;
    }
    & .b-taskboard-card {
        background :${({ theme }) => theme.material.colors.background.paper};
        min-height: 80px !important;
        padding: 0.5rem !important;
        &:hover{
            background: ${({ theme }) => theme.material.colors.background.hover};
        }
    }
    & .b-taskboard-swimlane-header i{
        color:  ${({ theme }) => theme.base.linkColor}
    }
    & .b-taskboard-resource-avatars {
        flex:1;
    }
    & .b-taskboard-taskitem {
        order: 1;
    }
    & .b-taskboard-task-menu{
        transform: rotate(90deg) !important;
        cursor: pointer;
        height: 30px;
        width: 30px;
        cursor: pointer;
        &.b-widget .b-menuitem .b-box-item{
            color: #fff !important;
        }
        &:hover {
            background-color: ${({ theme }) => theme.material.colors.background.hover};
            border-radius: 50%;
        }
        &:before {
            font-size: 19px !important;
            color: ${({ theme }) => theme.material.colors.text.secondary} !important;  
        }
    }

   & .title-icon{
        order: 0;
        color: ${({ theme }) => theme.material.colors.text.secondary};
        border-right:1px solid ${({theme})=> theme.material.colors.background.divider} ;
        padding-right:10px;
        margin-right:5px;
    }
    & .b-taskboard-card-header {
        font-size: 14px;
        letter-spacing: 0.25px;
        font-weight: 400;
    }
    .highest {
        border-left: 2px solid ${({ theme }) => get(theme.material.colors.priorityTaskColors, `1.1`)};
    }
    .high {
        border-left: 2px solid ${({ theme }) => get(theme.material.colors.priorityTaskColors, `2.1`)};
    }
    .medium {
        border-left: 2px solid  ${({ theme }) => get(theme.material.colors.priorityTaskColors, `3.1`)};
    }
    .low {
        border-left: 2px solid ${({ theme }) => get(theme.material.colors.priorityTaskColors, `4.1`)};
    }
    .lowest {
        border-left: 2px solid ${({ theme }) => get(theme.material.colors.priorityTaskColors, `5.1`)};
    }
    .closed-task {
        border-left: 2px solid grey !important;
        background-color:rgba(255, 255, 255, 0.08);
        --taskboard-progressitem-color : grey !important;
   }
`;

const reOrdering = (columns) => (columns || []).map((column, index) => {
    const newColumn = { ...column };
    newColumn.order = index;
    return newColumn;
});

class BetaBoardView extends PureComponent {
    static propTypes = {
        columns: PropTypes.arrayOf(PropTypes.object),
        list: PropTypes.array,
        taskItems: PropTypes.array,
        openSidebar: PropTypes.func.isRequired,
        groupBy: PropTypes.string,
        setColumnPosition: PropTypes.func,
        updateRelationColumnToBoard: PropTypes.func,
        userProfile: PropTypes.object,
    }

    static defaultProps = {
        columns: [],
        taskItems : [],
        swimlanes:[],
    };

    boardRef = React.createRef()
    boardRefLoaded = false;
    boardKey = 1;

    constructor(props) {
        super(props);
        this.state = {
            boardTasks: null,
            boardColumns: null,
            isLoading: false,
            boardKey: 0
        };
        const { filterBy, excludeBy, orderBy, selectedBoard, reloadColumns } = this.props;

        if(selectedBoard) {
            reloadColumns(filterBy, excludeBy, orderBy);
        }
    }

    componentDidUpdate(prevProps) {
        const { reloadColumns, tasks, groupBy, selectedBoard, boardRecords, boardRecordsAll, filterBy, excludeBy, orderBy } = this.props;
        if (
            boardRecordsAll !== prevProps.boardRecordsAll
          || prevProps.groupBy !== groupBy
          || tasks !== prevProps.tasks
        ) {
            this.setState({ isLoading: true });
            this.setState(this.normalizeEntities(selectedBoard, boardRecords, boardRecordsAll, groupBy), this.updateStateKey);
        }
        if(
            (selectedBoard && selectedBoard !== prevProps.selectedBoard) 
        || filterBy !== prevProps.filterBy 
        || orderBy !== prevProps.orderBy 
        || excludeBy !== prevProps.excludeBy
        ) {
            reloadColumns(filterBy, excludeBy, orderBy);
        }
    }

    @bind
    @memoize()
    buildColumns(selectedBoard){
        const columns = selectedBoard.primary['system_board/showcolumns'];
        const boardColumns = [{
            text: 'All',
            id: 'All',
        }, ...sortBy(columns.map((clmn, index) => {
            if(isDefined(clmn.isChecked) && !clmn.isChecked) {
                return null;
            }            
            return {
                text: clmn.name ?? clmn.text ?? clmn.label,
                id: clmn.id ?? clmn.value,
                relationId: clmn.relationId,
                order: clmn.order ?? index
            };
        }).filter(Boolean), ['order'])];
        return boardColumns;
    }

    @bind
    updateStateKey(newBoardKey) {
        const { viewState, saveComponentState } = this.props;
        const options = {
            ...viewState,
            boardKey: newBoardKey || viewState.boardKey + 1,
        };
        saveComponentState(this.props.id, options);
    }

    @bind
    buildSwimlanes(){
        const { tasks, groupBy } = this.props;
        if(!groupBy || !tasks) return;
        const swimlanes = [];
        switch (groupBy.toLowerCase()) {
            case 'assignee':
                var assignedTasks =  tasks.filter(task => task.assignee);
                assignedTasks.forEach((t) => {
                    if(!swimlanes.some( x => x.id === t.assignee.id)){
                        swimlanes.push({
                            id:t.assignee.id,
                            text:t.assignee.name.toUpperCase(),
                            collapsed:true
                        });
                    };
                });
                if(tasks.some(x => !x.assignee)){
                    swimlanes.push({
                        id:0,
                        text:'UNASSIGNED',
                        collapsed:true
                    });
                }
                break;
            case 'priority':
                tasks.forEach((t) => {
                    var priority = +t.primary.priority === 50 ? 3 : +t.primary.priority;
                    if(!swimlanes.some( x => x.id === priority)){
                        swimlanes.push({
                            id: priority,
                            text: priority === 1 ? 'HIGHEST' : priority === 2 ? 'HIGH' :
                                priority === 3 ? 'MEDIUM' : priority === 4 ? 'LOW'
                                    : 'LOWEST',
                            collapsed:true
                        });
                    }
                });
                break;
            default:
                break;
        }
        return swimlanes.sort((a,b) => a.id - b.id);;
    }

    @bind
    groupTaksToSwimlanes(task, groupBy){
        switch (groupBy) {
            case 'assignee':
                return task.assignee?.id ?? 0;
            case 'priority':
                return task.primary.priority;
            default:
                break;
        }
    }

    @bind
    @memoize()
    normalizeEntities(selectedBoard, boardRecords, boardRecordsAll, groupBy){
        const boardColumns = this.buildColumns(selectedBoard);
        const entityItems = [];
        const getUpdatedEntity = (entity, columnIds) => columnIds.map(columnId => ({
            ...entity,
            //uniqueId - since task can be on multiple columns
            id: `${entity.id}-${columnId}`,
            taskId: entity.id,
            columnId,
            progress: entity?.primary?.progress ?? 0,
            prio: this.groupTaksToSwimlanes(entity, groupBy?.toLowerCase())
        }));

        const tasksDataAll = boardRecordsAll.map((entity) => {
            const updatedEntity = getUpdatedEntity(entity, ['All']);
            entityItems.push(updatedEntity);
            return updatedEntity;
        }).flat();

        const tasksData = boardRecords.map((entity) => {
            const columnIds = [];
            boardColumns.forEach((clmn) => {
                const { columnField, columnDefinitionId, columnEntityMultiple } = this.props.parseColumnField(selectedBoard.primary['system_board/columnsetbyattribute']);
                const isRelatedToColumn =  get(entity?.primary, columnField) === clmn.id;
                const boardRelEntity = get(entity, `primary.${columnField}`);               
                const isRelatedToColumnEnt = entity.relations.filter((rel)=>rel.relatedEntity.id === clmn.id);
               
                if(isRelatedToColumn && !columnIds.includes(clmn.id)) {
                    columnIds.push(clmn.id);
                }
                if(columnDefinitionId && isRelatedToColumnEnt.length > 0 && !columnIds.includes(clmn.id)){
                    columnIds.push(clmn.id);
                }
                if(columnEntityMultiple && boardRelEntity){ 
                    boardRelEntity.forEach((ent)=>{
                        if(ent?.id === clmn?.id || ent === clmn?.id ){
                            columnIds.push(clmn.id);
                        }
                    });
                }
               
            });
            const updatedEntity = getUpdatedEntity(entity, columnIds);
            entityItems.push(updatedEntity);
            return updatedEntity;
        }).flat();

        return {
            boardColumns,
            boardTasks : {
                tasksData: [...tasksDataAll, ...tasksData],

                resourcesData: boardRecords.map((/*entity, index*/) => {
                    // const { assignee } = entity.primary;
                    // if(!resourcesIds.includes(assignee?.id) && assignee) {
                    //     resourcesIds.push(assignee.id);
                    //     return {
                    //         image: getAttachmentUrl(assignee.id, 'person'),
                    //         name: assignee.name,
                    //         id: assignee.id
                    //     };
                    // }
                    return null;
                }).filter(Boolean),

                assignmentsData: entityItems?.flat().map((entity, index) => {
                    const { id, primary } = entity;
                    return {
                        id: index,
                        event: id,
                        resource: primary?.assignee ?? null
                    };
                })
            },
            isLoading: false
        };
    };

    @bind
    copyLinkToClipBoard(e, data) {
        e.stopPropagation();
        e.preventDefault();
        copyToClipboard(`${window.location.origin}/#/entities/${data.type}/${data.taskId}`)
            .then(() => {
                this.props.showToastr({ severity: 'success', detail: 'Link copied to clipboard' });
            })
            .catch(() => {
                this.props.showToastr({ severity: 'error', detail: 'Link could not copied to clipboard' });
            });
    };
    @bind
    copyIdToClipBoard(e, id: string) {
        e.stopPropagation();
        e.preventDefault();
        copyToClipboard(id)
            .then(() => {
                this.props.showToastr({ severity: 'success', detail: 'ID copied to clipboard' });
            })
            .catch(() => {
                this.props.showToastr({ severity: 'error', detail: 'ID could not copied to clipboard' });
            });
    };

    @bind
    buildFooterItems() {
        return {
            'id > resourceAvatars' :{
                type : 'template',
                template:({ taskRecord }) => StringHelper.xss`<span id='UniqueUUID' title="Copy ID to clipboard">#${toUniqueUUID(taskRecord.taskId)}</span> <i class="mdi mdi-link footer-icon" title="Copy link to clipboard"></i>`
            }
        };
    };

    @bind
    buildListeners() {
        return {
            //if task is closed , cancel operation
            beforeTaskDrag({ taskRecords }) {
                return !taskRecords[0]?.data?.primary?.closedDate;
            },
            beforeColumnDrag({ columnRecord }){
                if(columnRecord.data.id === 'All'){
                    return false;
                }
            },
    
            columnDrag({ beforeColumn }){
                if(beforeColumn?.data?.parentIndex === 0){
                    return false;
                }
            },
               

            // taskDrop is triggered after a successful drop
            taskDrop: async ({ taskRecords, targetColumn, ...rest }) => {
                const { updateEntity, selectedBoard, loadEntity, primaryClasses,
                    createRelationship, deleteRelationship, filterBy, excludeBy, orderBy } = this.props;
                const { isLoading } = this.state;
                let { columnField } = this.props.parseColumnField(selectedBoard.primary['system_board/columnsetbyattribute']);
                const { columnDefinitionId, columnEntityType, columnEntityPath, columnEntityMultiple, isReverseDefinition, boardType, columnEntity } = this.props.parseColumnField(selectedBoard.primary['system_board/columnsetbyattribute']);
                columnField = `primary.${columnField}`;

                const entities = taskRecords.map((t) => {
                    return {
                        data: t.data,
                        originaldata: t.originalData,
                    };
                });

                if(entities[0].data.columnId ===  entities[0].originaldata.columnId) return;
                // Remove the task relationship from source column on the backend
                if(!isLoading) await this.setState({ isLoading: true });
                
                const isDestinationAll = entities[0].data.columnId === 'All';
                let isDestinationAllValue = null;

                const primaryEntityClass = primaryClasses.find(cls => cls.uri === selectedBoard?.primary['system_board/entitytype']?.uri);
                const parseAllValue = (list) => list.forEach(field => {
                    if(field.children) {
                        parseAllValue(field.children);
                    }
        
                    if(
                        field?.properties?.name === selectedBoard.primary['system_board/columnsetbyattribute']
                        && field?.properties?.name === 'priority'
                    ) {
                        isDestinationAllValue = 3;
                    }
        
                });
                parseAllValue(primaryEntityClass.formDefinition.fields);


                const updatedPromises = entities.map(async entity => {
                    let data = {
                        id: entity.data.taskId,
                        type: entity.data.type,
                    };
                    data = isDestinationAll ? 
                        set(data, columnField, isDestinationAllValue) 
                        : set(data, columnField, targetColumn.data.id);

                    const setData = (id, name, image, type)=> {
                        return set(data, columnEntityPath, columnEntityMultiple 
                            ? [{ id, name, image, type }] : { id, name, image, type });
                    }; 

                    if(boardType === 'entityTypeahead' && targetColumn.data.id !== 'All' ){
                        const column = columnEntity.find(col=> col.id === targetColumn.data.id);
                        const { id, name, image, type } = await loadEntity(column?.type, targetColumn.data.id);
                        data = setData(id, name, image, type);
                          
                    }

                    if(columnEntityType && !columnDefinitionId && boardType !== 'taskTypeahead' && boardType !== 'processTypeahead' && boardType !=='entityTypeahead') {
                        if(isDestinationAll) {
                            data = set(data, columnEntityPath, isDestinationAllValue); 
                        } else {
                            const { id, name, image, type } = await loadEntity(columnEntityType, targetColumn.data.id);
                            data = setData(id, name, image, type);
                        }
                    }
                    const sourceColumnId = entity.originaldata?.columnId;
                    const relatedEntityRelation = entity.data.relations.find(rel => rel.relatedEntity.id === sourceColumnId);
                    const columnSetAtrr = selectedBoard.primary['system_board/columnsetbyattribute'];
                    const primaryShowColumns = get(selectedBoard, 'primary.system_board/showcolumns');
                    let primaryTargetColumn = null;
                    
                    switch (boardType) { //data stabilization for different boards type
                        case 'taskTypeahead':
                        case 'processTypeahead':
                        case 'workspaceTypeahead':
                        case 'teamTypeahead':   
                            primaryTargetColumn = primaryShowColumns.find(col=> col.id === targetColumn.data.id); 
                            if(targetColumn.data.id === 'All'){
                                data = set(data,`primary.${columnSetAtrr}`, null); 
                            }else{
                                const { id, name, type } =  primaryTargetColumn; 
                                data = set(data,`primary.${columnSetAtrr}`, columnEntityMultiple ? [{id, name, type }] : {id, name, type}); 
                            }
                            return updateEntity(data);

                        case 'classificationTypeahead':
                        case 'userTypeahead':
                            primaryTargetColumn = primaryShowColumns.find(col=> col.id === targetColumn.data.id);   
                            data = set(data,`primary.${columnSetAtrr}`, columnEntityMultiple ? [primaryTargetColumn] : primaryTargetColumn); 
                            if(targetColumn.data.id === 'All'){
                                data = set(data,`primary.${columnSetAtrr}`, null); 
                            }
                            return updateEntity(data);

                        case 'typeahead':  
                            primaryTargetColumn = primaryShowColumns.find(col=> col.value === targetColumn.data.id);
                            if(columnEntityMultiple ){
                                data = set(data,`primary.${columnSetAtrr}`, [primaryTargetColumn?.value]); 
                            }
                            if(targetColumn.data.id === 'All'){
                                data = set(data,`primary.${columnSetAtrr}`, null); 
                            }
                            return updateEntity(data);

                        case 'entityTypeahead':
                            if(targetColumn.data.id === 'All'){
                                data = set(data,`primary.${columnSetAtrr}`, null); 
                            }else {
                                const column = columnEntity.find(col=> col.id === targetColumn.data.id);
                                const { id, name, image, type } = await loadEntity(column?.type, targetColumn.data.id);
                                data = setData(id, name, image, type);
                            }
                            return updateEntity(data);
                    }
                    

                    if(!columnDefinitionId) {
                        return updateEntity(data);
                    }

                    if(targetColumn.data.id === 'All'){
                        return await deleteRelationship(relatedEntityRelation.relation.id);
                    }
                    
                    const columns = get(selectedBoard, 'primary.system_board/showcolumns', null);
                    if(!columns?.length) return;
                    const columnType = columns.find(col => col.id === data.primary[columnDefinitionId])?.type;
                    if(columnType){
                        const rel = {
                            entity: { type: data.type, id: data.id },
                            relationDefinition: columnDefinitionId,
                            relatedEntity: { type: columnType, id: data.primary[columnDefinitionId] }
                        };
                        const relReverse = { ...rel, entity: rel.relatedEntity, relatedEntity: rel.entity };
                        if(sourceColumnId === 'All'){
                            return await createRelationship(isReverseDefinition ? relReverse : rel);
                        }
                        await deleteRelationship(relatedEntityRelation.relation.id);
                        return await createRelationship(isReverseDefinition ? relReverse : rel);
                    }
                });
                await Promise.all(updatedPromises);
                await this.props.reloadColumns(filterBy, excludeBy, orderBy);
            },

            columnDragEnd: async ({ beforeColumn, columnRecord }) => {
                const { isLoading, boardColumns: boardColumnsState } = this.state;
                if(beforeColumn === undefined ) return;
                if(boardColumnsState.length < 2) return;
                
                const boardColumns = reOrdering(boardColumnsState);

                const oldIndex = boardColumns.find( col => col.id === columnRecord.originalData.id).order;
                
                let newIndex;
                if(oldIndex === boardColumns.length) {
                    newIndex = boardColumns.find( col => col.id === beforeColumn.originalData.id).order - 1;
                } else if(!beforeColumn) {
                    newIndex = boardColumns.length - 1;
                } else{
                    var index = boardColumns.find( col => col.id === beforeColumn.originalData.id).order;
                    newIndex = index >= 0 ? oldIndex > index ? index : index -1 : 0;
                }

                if(oldIndex === newIndex) return;
                if(!isLoading) await this.setState({ isLoading: true });

                const reorderedBoardColumns = reOrdering(this.reorderColumns(boardColumns, oldIndex, newIndex));
                this.saveColumnsOrder(reorderedBoardColumns.filter(col => col.id !== null));
            }
        };
    };

    reorderColumns(list: any[], startIndex: number, endIndex: number){
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex < 0 ? 0 : endIndex, 0, removed);
        return result;
    };

    @bind
    async saveColumnsOrder(updatedColumns){
        const { setColumnPosition, updateEntity, selectedBoard, preferences, saveBoardPreferences, setSelectedBoard } = this.props;
        setColumnPosition(updatedColumns);
        const columns = selectedBoard.primary['system_board/showcolumns'];
        const orderColumns = updatedColumns.filter((item)=> item.id !=='All').map(item=>item.id);
        const stabColumns = [...columns].sort((a, b) => orderColumns.indexOf(a.id) - orderColumns.indexOf(b.id));
        const board = await updateEntity({
            id: selectedBoard.id,
            type: selectedBoard.type,
            primary: {
                'system_board/showcolumns': stabColumns.map(column => ({
                    ...column,
                    order: updatedColumns.find(clmn => clmn.id === (column.id ?? column.value))?.order
                }))
            }
        });
        await saveBoardPreferences({ ...preferences, betaBoards: {...preferences.betaBoards, [board.id]: board } });
        await setSelectedBoard(board);
        this.setState({ isLoading: false });
    }

    taskRenderer({ taskRecord, cardConfig }) {
        const headerContent = cardConfig.children.header.children;
        headerContent.icon = {
            tag   : 'i',
            class : 'mdi mdi-checkbox-marked-circle-outline',
            style : `color: ${muiTheme.colors.text.secondary} ;`
        };
        var priority = +taskRecord?.primary?.priority === 50 ? 3 : +taskRecord?.primary?.priority;
        cardConfig.class[ priority === 1 ? 'highest' : priority === 2 ? 'high' : priority === 3? 'medium' : priority === 4 ? 'low' : 'lowest' ] = !!!taskRecord?.primary?.closedDate;
        cardConfig.class['closed-task'] = !!taskRecord?.primary?.closedDate;
    }

    @bind
    onTaskClick(task) {
        const { taskRecord }= task;
        const { data }= taskRecord;
        if (task.event.target.className.includes('mdi-link footer-icon')){
            this.copyLinkToClipBoard(task.event, data);
        }
        if (task.event.target.id.includes('UniqueUUID')){
            this.copyIdToClipBoard(task.event, data.taskId);
        }
        else if(!task.event.target.className.includes('b-taskboard-task-menu')
        && !task.event.target.className.includes('mdi-link footer-icon')) {
            const { openSidebar, sidebarTitle } = this.props;
            const { filterBy, excludeBy, orderBy } = this.props;
            openSidebar && openSidebar(task.taskRecord.data, sidebarTitle || 'About', filterBy, excludeBy, orderBy);
        }
    };

    @bind
    // @memoize()
    updateKey(prevKey) {
        if(this.boardKey !== prevKey) {
            this.boardKey = this.boardKey + (prevKey || 0) + 1;
            this.updateStateKey(this.boardKey);    
        }
    }

    render(){
        const { viewState, isLoading: isLoadingProp, groupBy } = this.props;
        const { boardKey } = viewState || {};
        const { isLoading, boardTasks, boardColumns } = this.state;
        this.updateKey(boardKey);
        return(
            <>
                {(isLoading || isLoadingProp) && <Loader absolute backdrop />}
                {boardTasks && <BoardWrapper>
                    <BryntumTaskBoard
                        {...taskboardConfig()}
                        ref={this.boardRef}
                        key={this.boardKey}
                        columns={boardColumns}
                        swimlanes={this.buildSwimlanes(boardTasks || [], groupBy)}
                        project={boardTasks}
                        listeners={this.buildListeners()}
                        footerItems={this.buildFooterItems()}
                        taskRenderer={this.taskRenderer}
                        processItems= {this.processItems}
                        onTaskClick={(taskRecord) => {this.onTaskClick(taskRecord);}}
                    />
                </BoardWrapper>}
            </>
        );
    };
}

export default connect((state, props) => ({
    viewState: get(state, `component.state.${props.id}`),
    boardRecords:state.abox.boards.boardRecords.records,
    boardRecordsAll:state.abox.boards.boardRecordsAll.records,
    sidebarTitle: state.sidebar.title,
    userProfile: state.user.profile,
    preferences: state.user.preferences,
    primaryClasses: state.app.allPrimaryClasses.records,
}), {
    saveComponentState,
    updateRelationColumnToBoard,
    setColumnPosition,
    showToastr,
    updateEntity,
    saveBoardPreferences,
    setSelectedBoard,
    loadEntity,
    createRelationship, deleteRelationship
}, null, { forwardRef: true })(BetaBoardView);
