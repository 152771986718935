/* @flow */

import { gql } from '@apollo/client';

export default gql`
mutation exportEntitiesRelationsMutation(
    $name: String
    $description: String
    $type: String!
    $filterBy: [JSON]
    $excludeBy: [JSON]
    $orderBy: [JSON]
    $startIndex: Int
    $stopIndex: Int
    $relationDefinition: ID!
    $attributes: [String]
) {
  result: exportEntitiesRelations(
    name: $name
    description: $description
    type: $type
    filterBy: $filterBy
    excludeBy: $excludeBy
    orderBy: $orderBy
    startIndex: $startIndex
    stopIndex: $stopIndex
    relationDefinition: $relationDefinition
    attributes: $attributes
  ) {
    id
    type
    name
  }
}
`;
