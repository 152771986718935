/* @flow */
import { graphql } from 'graphql/client';
import digitalTwinQuery from 'graphql/common/digitalTwinQuery';
import digitalTwinAssetsQuery from 'graphql/common/digitalTwinAssetsQuery';
import digitalTwinProcessDefinitionQuery from 'graphql/common/digitalTwinProcessDefinitionQuery';
import digitalTwinProcessAndTasksQuery from 'graphql/common/digitalTwinProcessAndTasksQuery';
import Immutable from 'app/utils/immutable/Immutable';
import { loadData } from 'app/utils/redux/action-utils';
import { get } from 'app/utils/lo/lo';
import { _parse } from 'app/utils/digitalTwin/digitalTwin';

export const LOAD_DIGITAL_TWIN_STARTED = '@@affectli/common/digitalTwin/LOAD_DIGITAL_TWIN_STARTED';
export const LOAD_DIGITAL_TWIN = '@@affectli/common/digitalTwin/LOAD_DIGITAL_TWIN';

const _normalizeScriptTemplate = (data) => {
    let dtData = { ...data };
    if (!data?.warnings) { // identifier to check if its script or not it its script warnings would be null
        dtData = {
            ...data,
            componentMapping: data?.entity,
            valueMapping: data?.text, // WIP: colorMapping actionMapping
            colorMapping: data?.style,
            actionMapping: data?.action,
            isScriptTemplate: true
        };
    }
    delete dtData.entity;
    // delete dtData.hide;
    delete dtData.style;
    delete dtData.text;
    delete dtData.action;
    return dtData;
};

export const loadDigitalTwin = (id: string, type: string, digitalTwinId: string, getLegacyDigitalTwin: boolean = false) => {
    const variables = { id, type, getLegacyDigitalTwin };
    if (!getLegacyDigitalTwin) {
        variables.digitalTwinId = digitalTwinId;
    }
    return loadData(
        LOAD_DIGITAL_TWIN_STARTED,
        LOAD_DIGITAL_TWIN,
        digitalTwinQuery,
        _normalizeScriptTemplate
    )(variables);
};

export const LOAD_ASSETS_STARTED = '@@affectli/common/digitalTwin/LOAD_ASSETS_STARTED';
export const LOAD_ASSETS = '@@affectli/common/digitalTwin/LOAD_ASSETS';

export const loadAssets = (uuids: Array<string>, entityType: string) => async (dispatch) => {
    const ids = uuids.filter(uuid => typeof uuid === 'string' && uuid !== 'hide');
    const idsData = uuids.filter(uuid => typeof uuid !== 'string').filter(Boolean);
    const filtersMap = {};
    idsData.forEach(({ type = 'thing' }) => filtersMap[type] = []);
    idsData.forEach((data) => {
        const { type = 'thing' } = data || {};
        const { key: field, value } = _parse(data);
        filtersMap[type].push({ field, op: '=', value });
    });

    Object.keys(filtersMap).forEach((type) => {
        if (filtersMap?.[type]?.length > 1) {
            filtersMap[type] = [{ or: filtersMap[type] }];
        }
    });

    const orderBy = [{ field: 'name', direction: 'asc nulls last' }];
    if (ids?.length) {
        const idsFilter = { field: 'attributes.asset/uuid', op: 'in', value: ids };
        if (filtersMap.thing) {
            const thingOrCondition = [...filtersMap.thing];
            thingOrCondition.push(idsFilter);
            filtersMap.thing = [{ or: thingOrCondition}];
        } else {
            filtersMap.thing = [idsFilter];
        }

    }

    dispatch({ type: LOAD_ASSETS_STARTED });
    const promises = Object.keys(filtersMap).map(type => graphql.query({ query: digitalTwinAssetsQuery(type), variables: { type, filterBy: filtersMap[type], orderBy}, fetchPolicy: 'no-cache' }));
    await Promise.all(promises).then((response) => {
        const records = [];
        response.forEach(data => records.push(...get(data, 'data.result', [])));
        dispatch({ type: LOAD_ASSETS, payload: records });
    }).catch((error) => {
        dispatch({ type: LOAD_ASSETS, error: true });
    });
};

export const FETCH_TEMPLATE_STARTED: string = '@@affectli/common/digitalTwin/FETCH_TEMPLATE_STARTED';
export const FETCH_TEMPLATE: string = '@@affectli/common/digitalTwin/FETCH_TEMPLATE';

export const fetchTemplate = (url: string) => async (dispatch, getState) => {
    if(!url) return;
    const storedTemplateUrl = get(getState(), 'common.digitalTwin.template.url');
    if (storedTemplateUrl === url) {
        return;
    }
    dispatch({ type: FETCH_TEMPLATE_STARTED, meta: { url } });
    const response = await fetch(url);
    if (response.ok) {
        const payload = await response.text();
        dispatch({ type: FETCH_TEMPLATE, payload, meta: { url } });
        return payload;
    } else {
        const errorMessage = `Cannot fetch the template "${url}": ${response.status} ${response.statusText}`;
        dispatch({
            type: FETCH_TEMPLATE,
            error: true,
            meta: Immutable({ url, errorMessage }),
        });
    }
};

export const LOAD_PROCESS_DEFINITION_STARTED = '@@affectli/common/digitalTwin/LOAD_PROCESS_DEFINITION_STARTED';
export const LOAD_PROCESS_DEFINITION = '@@affectli/common/digitalTwin/LOAD_PROCESS_DEFINITION';

export const loadProcessDefinition = (id: string) =>
    loadData(
        LOAD_PROCESS_DEFINITION_STARTED,
        LOAD_PROCESS_DEFINITION,
        digitalTwinProcessDefinitionQuery,
        payload => payload && payload[0],
    )({
        filterBy: [ { field: 'id', op: '=', value: id } ],
    });


export const LOAD_PROCESS_AND_TASKS_STARTED = '@@affectli/common/digitalTwin/LOAD_PROCESS_AND_TASKS_STARTED';
export const LOAD_PROCESS_AND_TASKS = '@@affectli/common/digitalTwin/LOAD_PROCESS_AND_TASKS';

export const loadProcessAndTasks = (processId: string) =>
    loadData(
        LOAD_PROCESS_AND_TASKS_STARTED,
        LOAD_PROCESS_AND_TASKS,
        digitalTwinProcessAndTasksQuery,
    )({
        processId,
        filterBy: [
            { field: 'process.id', op: '=', value: processId }
        ]
    });

export const clearAssetsAndTemplate = () => (dispatch: Function) => {
    dispatch({ type: LOAD_ASSETS });
    dispatch({ type: FETCH_TEMPLATE });
};
