/* @flow */

import { gql } from '@apollo/client';

export const backgroundJobsQuery = (view) => ({
    'view-list': gql`
      query backgroundJobsQuery($startIndex: Int, $stopIndex: Int, $filterBy: [JSON], $orderBy: [JSON], $excludeBy: [JSON]) {
          count: count(dataType: "backgroundjob", filterBy: $filterBy, excludeBy: $excludeBy)
          records: backgroundJobs(startIndex: $startIndex, stopIndex: $stopIndex, filterBy: $filterBy, orderBy: $orderBy, excludeBy: $excludeBy) {
            id
            name
            type
            primaryClass {
                entityAppearance
                openInModule
                entityTabs
                formDefinition
                parents {
                    formDefinition
                }
            }            
          }
      }
  `,
    'view-cards': gql`
      query backgroundJobsQuery($startIndex: Int, $stopIndex: Int, $filterBy: [JSON], $orderBy: [JSON], $excludeBy: [JSON]) {
          count: count(dataType: "backgroundjob", filterBy: $filterBy, excludeBy: $excludeBy)
          records: backgroundJobs(startIndex: $startIndex, stopIndex: $stopIndex, filterBy: $filterBy, orderBy: $orderBy, excludeBy: $excludeBy) {
            id
            name
            type
            createdDate
            relations {
              relation{
                  id
              }
              isReverseRelation
            }
            classes {
              id
              uri
              name
            }
            workspaces {
              isSystem
            }              
            primaryClass {
                entityAppearance
                openInModule
                entityTabs
                formDefinition
                parents {
                    formDefinition
                }
            }
            active
            modifiedDate
            modifiedBy {
                id
                name
                image
            }
            createdBy {
                id
                name
                image
            }
            createdDate
          }
      } 
  `,
    'view-table': gql`
      query backgroundJobsQuery($startIndex: Int, $stopIndex: Int, $filterBy: [JSON], $orderBy: [JSON], $excludeBy: [JSON]) {
          count: count(dataType: "backgroundjob", filterBy: $filterBy, excludeBy: $excludeBy)
          records: backgroundJobs(startIndex: $startIndex, stopIndex: $stopIndex, filterBy: $filterBy, orderBy: $orderBy, excludeBy: $excludeBy) {
            id
            name
            type
            createdDate
            primary(fields:["endDate", "startDate", "status", "type"])
            active
            modifiedDate
            modifiedBy {
                id
                name
                image
            }
            createdBy {
                id
                name
                image
            }
            createdDate
          }
      } 
  `
})[view]; 
