/* @flow */

import React, { PureComponent, Fragment } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Tooltip, IconButton, Divider } from '@mic3/platform-ui';

import { cut } from 'app/utils/string/string-utils';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { get } from 'app/utils/lo/lo';

import { BoardsColumnInner, AddTaskButton, BoardsTaskListDroppable } from './style';
import Icon from 'app/components/atoms/Icon/Icon';
import Loader from 'app/components/atoms/Loader/Loader';
import VirtualList from 'app/components/molecules/VirtualList/VirtualList';
import BoardsTaskItem from './BoardsTaskItem';
import { groupByOpts } from 'app/containers/Abox/OldBoards/Boards';

const BoardsGroupLabel = styled.h5`
    margin: 12px;
`;

class BoardsColumn extends PureComponent<Object, Object> {
    static propTypes = {
        disableAddTask: PropTypes.bool,
        column: PropTypes.object.isRequired,
        openTaskModal: PropTypes.func.isRequired,
        deleteColumn: PropTypes.func.isRequired,
        isLoading: PropTypes.bool,
        openSidebar: PropTypes.func.isRequired,
        groupBy: PropTypes.string
    };

    static defaultProps = {
        isLoading: false,
        disableAddTask: false
    };

    state = {
        forceUpdateKey: 0
    }

    componentDidUpdate(prevProps) {
        if (prevProps.groupBy !== this.props.groupBy) {
            this.setState({ forceUpdateKey: this.state.forceUpdateKey + 1 });
        }
    }

    @bind
    openTaskModal() {
        this.props.openTaskModal(this.props.column);
    }

    @bind
    deleteColumn() {
        this.props.deleteColumn(this.props.column.relationId, this.props.column.id);
    }

    @bind
    @memoize()
    buildTasksList(tasks: Array<Object>) {
        const { children } = this.props;
        // $FlowFixMe
        return tasks && tasks.length > 0 && tasks.map((task, index) => children(task, index));
    }

    render() {
        const { column, isLoading, tasks, disableAddTask, index, openSidebar, groupBy } = this.props;
        const { forceUpdateKey } = this.state;
        return (
            <Draggable draggableId={String(column.id)} key={column} index={index}>
                {(provided) => {
                    return (
                        <BoardsColumnInner
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                        >
                            { !disableAddTask && <AddTaskButton >
                                <Tooltip title={column.name} placement="top" arrow>
                                    <div className="column-label">{ isMobile ? cut(column.name, 20) : cut(column.name, 40) }</div>
                                </Tooltip>
                                <div className="action-buttons">
                                    <Tooltip title={`Delete ${column.name} column`} arrow>
                                        <IconButton size="small" onClick={this.deleteColumn}>
                                            <Icon
                                                type="mdi" name="delete" size="md"
                                                color="rgba(255, 255, 255, 0.24)"
                                            />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={`Add Task to ${column.name}`} arrow>
                                        <IconButton size="small" onClick={this.openTaskModal}>
                                            <Icon
                                                type="mdi" name="plus" size="md"
                                                color="rgba(255, 255, 255, 0.24)"
                                            />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </AddTaskButton> }

                            { isLoading && <Loader /> }
                            <Droppable 
                                droppableId={String(column.id)}
                                mode="virtual"
                                type='TASK'
                                renderClone={(provided, snapshot, rubric) => {
                                    return (
                                        <div
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            ref={provided.innerRef}
                                        >
                                            <BoardsTaskItem 
                                                task={tasks[rubric.source.index]} 
                                                columnId={String(column.id)} 
                                                openSidebar={openSidebar}
                                            />
                                        </div>
                                    );
                                }}
                            >
                                {(provided, snapshot) => {
                                    const itemCount = snapshot.isUsingPlaceholder
                                        ? tasks.length + 1
                                        : tasks.length;

                                    return (
                                        <BoardsTaskListDroppable
                                            ref={ provided.innerRef }
                                        >
                                            <VirtualList
                                                forceupdate={forceUpdateKey}
                                                itemSize={(index) => {
                                                    if (groupBy) {
                                                        const value = get(tasks[index], groupBy, '');
                                                        const prevVal = get(tasks, `[${index-1}].${groupBy}`, '');
                                                        return (!index || value !== prevVal) ? 127 : 84; 
                                                    }
                                                    return 84; 
                                                }}
                                                itemCount={itemCount}
                                                renderItem={({ index, style, resize }) => {
                                                    const task = tasks[index];
                                                    if (!task) {
                                                        return null;
                                                    }
                                                    
                                                    return (
                                                        <Draggable 
                                                            draggableId={`${String(column.id)}-${task.id}`} 
                                                            index={index} 
                                                            key={task.id}>
                                                            {(provided, snapshot) => {
                                                                const boardItem = (
                                                                    <BoardsTaskItem 
                                                                        task={task} 
                                                                        columnId={String(column.id)} 
                                                                        openSidebar={openSidebar}
                                                                    />
                                                                );
                                                                let groupItem;

                                                                if (groupBy) {
                                                                    const value = get(task, groupBy, '');
                                                                    const prevVal = get(tasks, `[${index-1}].${groupBy}`, '');
                                                                    const label = groupByOpts[groupBy] || '';
                                                                    groupItem = (
                                                                        <Fragment key={task.id}>
                                                                            {!index || value !== prevVal ?
                                                                                <Fragment>
                                                                                    { index ? <Divider /> : null }
                                                                                    <BoardsGroupLabel>{label}: {value || `No ${label}`}</BoardsGroupLabel>
                                                                                </Fragment>
                                                                                : null}
                                                                            {boardItem}
                                                                        </Fragment>
                                                                    );
                                                                }
                                                                
                                                                return (
                                                                    <div
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        ref={provided.innerRef}
                                                                        style={{...provided.draggableProps.style, ...style, cursor: 'pointer' }}
                                                                    >
                                                                        {!groupBy ? boardItem : groupItem}
                                                                    </div>
                                                                );
                                                            }}
                                                        </Draggable>
                                                    );
                                                }}
                                            />
                                        </BoardsTaskListDroppable>
                                    );
                                }}
                            </Droppable>
                        </BoardsColumnInner>
                    );
                }}
            </Draggable>
        );
    }
}

export default BoardsColumn;