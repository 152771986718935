/* @flow */

import { gql } from '@apollo/client';

export default gql`
mutation deleteFormDefinitionMutation($id: ID!) {
  result: deleteForm(id: $id)
}

`;
