/* @flow */

import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Link from 'app/components/atoms/Link/Link';
import { baseRoutes } from 'app/config/routesConfig';

const MapLink = ((props: Object) => {
    const { id, children, ...restProps } = props;
    return <Link to={`${baseRoutes.map}/${id}`} {...restProps}>{children}</Link>;
});

MapLink.propTypes = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default memo<Object>(MapLink);
