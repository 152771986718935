// @flow

export default `
        id
        type
        name
        active
        description
        primary(fields:["variables", "closedDate", "closureReason" "assignee", "priority"])
        classes{
          id
          name
          uri
        }
        createdBy {
          id
          name
          image
          username
        }
        createdDate
        modifiedBy {
          id
          name
          image
          username
        }
        modifiedDate
        tasks {
          id
          name
          image
          assignee {
            id
          }
          owner {
            id
          }
        }
        processDefinitionVersion {
          id
          name
          description
          primary(fields: ["definition"])
          iconName
          iconColor
          createdBy {
            id
            name
            image
            username
          }
        }
`;
