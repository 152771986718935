/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { bind } from 'app/utils/decorators/decoratorUtils';
import EntityLayerAbout from 'app/containers/Maps/LayerAbout/EntityLayer/EntityLayerAbout';
import WMSLayerAbout from 'app/containers/Maps/LayerAbout/WMSLayer/WMSLayerAbout';
import DrawingLayerAbout from 'app/containers/Maps/LayerAbout/DrawingLayer/DrawingLayerAbout';
import { Button } from '@mic3/platform-ui';
import { setActions, setOnBack, setTitle } from 'store/actions/sidebar/sidebarActions';

import DotMenu from 'app/components/molecules/DotMenu/DotMenu';
import RemoveLayerModal from 'app/containers/Maps/Drawers/RemoveLayerModal';
import BaseModal from 'app/components/Designer/Modals/BaseModal';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import CesiumIonLayerAbout from 'app/containers/Maps/LayerAbout/CesiumIonLayerAbout/CesiumIonLayerAbout';
import { getPermissions } from 'app/config/rolesConfig';
import MapSidebar from 'app/containers/Sidebars/MapSidebar';
import { layerTypes, __findMapLayer, getLayerNameField } from 'app/utils/maps/layer/layerUtils';
import { updateMapLayer } from 'store/actions/maps/situationalAwarenessActions';
import { get } from 'app/utils/lo/lo';
import ReplayLayerAbout from 'app/containers/Maps/LayerAbout/ReplayLayer/ReplayLayerAbout'; 

class LayerSidebar extends PureComponent<Object, Object> {
    static propTypes = {
        title: PropTypes.string,
        id: PropTypes.string.isRequired,
        map: PropTypes.object.isRequired,
        mapId: PropTypes.string.isRequired,
        layer: PropTypes.object.isRequired,
        details: PropTypes.object,
        isOpen: PropTypes.bool
    };

    renameRef: Object = React.createRef();

    constructor(props: Object) {
        super(props);
        this.state = {
            onSave: null,
            isRename: false,
            layer: null,
            isChanged: false,
        }; 
        this.setOnBack();
    }

    componentDidMount() {
        this.setActions();
    }

    componentDidUpdate(prevProps: Object) {
        const { title } = this.props;
        if (title !== prevProps.title && title !== 'Layers') {
            this.setOnBack();
        }
    }

    @bind
    onLayerRemove() {
        const { id, title } = this.props;
        this.openModal({ id, title });
    }

    @bind
    renderLayerActionMenu() {
        const moreMenu = [
            { name: 'Rename', icon: 'pencil', onItemClick: () => this.setState({ isRenameModelOpen: true }) },
            { name: 'Delete', icon: 'delete', onItemClick: this.onLayerRemove }
        ];
        return <DotMenu items={moreMenu} />;
    }

    @bind
    setStatus(value, callback) {
        return this.setState({ isChanged: value }, () => callback && callback());
    }

    @bind
    setOnBack() {
        this.props.setOnBack(() => {
            this.props.setTitle('Layers');
        });
    }

    @bind
    setActions(onSave: any) {
        const { role } = this.props?.details || {};
        const { canEdit } = getPermissions(role);
        if(!canEdit) {
            return;
        }
        this.setState({ onSave });
        return this.props.setActions(
            <>
                <Button
                    style={{ marginTop: '4px' }}
                    disabled={!onSave || !this.state.isChanged}
                    variant='text'
                    onClick={async () => {
                        typeof onSave === 'function' && await onSave();
                        this.setState({ onSave: null });
                    }}
                >
                    Apply
                </Button>
                {this.renderLayerActionMenu()}
            </>
        );
    }

    @bind
    onCancel() {
        this.setState({ isChanged: false });
        if (this.state.goToLayers) {
            this.props.setTitle('Layers');
        }
    }

    @bind
    async onSave() {
        const { onSave } = this.state;
        if (typeof onSave === 'function') {
            await onSave();
        }
        this.onCancel();
    }

    @bind
    renderContent() {
        const { title, id, layer, map, mapId, entityTypes, mapData, data, layerName } = this.props;
        const { isChanged } = this.state;
        const layerDetails = __findMapLayer(mapData, { id, name: title });
        const layerType = 'entity-layer';
        const layerTitle =  layerTypes.find(lyr => lyr.uri === layerDetails?.type)?.name || ''; 
        const props = { layerName, id, layer, map, mapId, title: layerTitle, setActions: this.setActions, 
            entityTypes, setStatus: this.setStatus, isChanged, details: data || layerDetails || {} };
      
        switch (title || layerTitle) {
            case 'Layers': 
                return <MapSidebar map={map} id={mapId} mapId={mapId} type="map" title={title} layerName={layerName} />;
            case 'Entity Layer':
            case 'Event Layer':
                return <EntityLayerAbout {...props} type={layerType} />;
            case 'WMS Layer':
                return <WMSLayerAbout {...props} />;
            case 'Drawing Layer':
                return <DrawingLayerAbout {...props} />;
            case 'Cesium Ion Layer':
                return <CesiumIonLayerAbout {...props} />;
            case 'Replay Layer':
                return <ReplayLayerAbout {...props} />;
            default:
                return null;
        }
    }

    @bind
    openModal(layer) {
        this.setState({ layer });
    }

    @bind
    closeModal() {
        this.setState({ layer: null });
    }

    @bind
    async handleConfirmRename() {
        const result = await this.renameRef.current.isValidForm();
        if(!result.errors) {
            const { data } = result;
            const { id, updateMapLayer } = this.props;
            updateMapLayer({ id, name: data?.name });
            this.setState({ isRenameModelOpen: false });   
        }
    }

    render() {
        const { isOpen, map, mapId, mapData, layerName, id, title } = this.props;
        const layerDetails = __findMapLayer(mapData, { id, name: this.props.layer?.values_?.title || layerName, title});
        const { isRenameModelOpen, layer } = this.state;

        return (
            isOpen && (
                <>
                    {isRenameModelOpen && (
                        <BaseModal
                            open={isRenameModelOpen}
                            onClose={() => this.setState({ isRenameModelOpen: false })}
                            onConfirm={this.handleConfirmRename}
                            header='Rename Layer'
                            maxWidth='sm'
                            fullWidth
                            content={
                                <FormGenerator
                                    root={false}
                                    data={layerDetails}
                                    components={[getLayerNameField(mapData)]}
                                    ref={this.renameRef}
                                />
                            }
                            declineButtonText='Cancel'
                            confirmButtonText='Apply'
                        />
                    )}
                    {this.renderContent()}
                    {layer && 
                        <RemoveLayerModal
                            map={map}
                            mapId={mapId}
                            layer={layerDetails}
                            closeModal={this.closeModal}
                        />}
                </>
            )
        );
    }
}

export default connect(
    (state: Object, ownProps: Object) => ({
        isOpen: state.sidebar.isOpen,
        title: state.sidebar.title,
        isLoading: state.maps.situationalAwareness.map.data.isLoading,
        details: get(state.maps,'situationalAwareness.map.data',{}),
        entityTypes: state.app.allPrimaryClasses.records,
        mapData: get(state.maps,'situationalAwareness.map.data',{}),
    }),
    {
        setActions,
        setOnBack,
        setTitle,
        updateMapLayer
    }
)(withRouter(LayerSidebar));
