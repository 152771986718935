/* @flow */

export default `
        id
        name
        primary
        type
        image
        createdDate
        iconName
        iconColor
        iconType
        primaryClass {
            uri
            applicableOn
            entityAppearance
            openInModule
            entityTabs
            formDefinition
            parents {
                formDefinition
            }
            digitalTwins {
                name
            }
        }
        primary
`;
