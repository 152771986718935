/* @flow */

import Immutable from 'app/utils/immutable/Immutable';
import { sortBy } from 'app/utils/lo/lo';
import { shallowEquals } from 'app/utils/utils';
import { isEmpty } from 'app/utils/utils';
import { get } from 'app/utils/lo/lo';

import {
    // Other
    OPEN_CHAT,
    TOGGLE_CHAT,
    RESET_ROOM_INITIAL_REL_STATE,
    SEND_CHAT_MESSAGE,
    SUBSCRIBE_CHAT_STARTED, SUBSCRIBE_CHAT,
    REMOVE_CHAT_MEMBERS,
    UPDATE_CHAT_MESSAGE,
    // Room
    LOAD_ROOMS_STARTED, LOAD_ROOMS,
    ADD_ROOM_MESSAGES,
    EDIT_ROOM_MESSAGES,
    LOAD_ROOM_MESSAGES_STARTED, LOAD_ROOM_MESSAGES,
    LOAD_ROOM_MEMBERS_STARTED, LOAD_ROOM_MEMBERS,
    UPLOAD_CHAT_ROOM_FILE_STARTED, UPLOAD_CHAT_ROOM_FILE,
    LOAD_GROUP_ROLES_STARTED, LOAD_GROUP_ROLES,
    LOAD_ROOM_HISTORY_BY_TYPE,
    // DM
    ADD_DIRECT_MESSAGE_STARTED, ADD_DIRECT_MESSAGE,
    // Channel
    CREATE_CHANNEL_STARTED, CREATE_CHANNEL,
    JOIN_PUBLIC_CHANNEL_STARTED, JOIN_PUBLIC_CHANNEL,
    LOAD_CHANNEL_MEMBERS_STARTED, LOAD_CHANNEL_MEMBERS,
    LOAD_CHANNEL_ROLES_STARTED, LOAD_CHANNEL_ROLES,
} from 'store/actions/chat/chatActions';
const initialState = {
    isOpen: false,
    isSubscribing: false,
    isFileUploading: false,
    rel: null, // the chat room's related entity/task/process ID
    data: [], // the chat rooms
    isLoading: false,
    messages: {}, // the chat rooms messages
    isLoadingMessages: false,
    members: [],
    isLoadingMembers: false,
    roles: [],
    isLoadingRoles: false,
    allMessagesLoaded: false
};

export default (state: Object = initialState, action: Function) => {
    const { type, error, payload, meta } = action;
    switch (type) {
        case OPEN_CHAT: {
            const { rel } = payload;
            if (shallowEquals(state.rel, rel)) {
                return Immutable({ ...state, isOpen: true });
            }
            return Immutable({ ...initialState, rel, isOpen: true });
        }
        
        case TOGGLE_CHAT:
            if (!state.rel) {
                return state;
            }
            return Immutable({ ...state, isOpen: !state.isOpen });
        
        case LOAD_ROOMS_STARTED:
            return Immutable({ ...state, isLoading: true });

        case LOAD_ROOMS:
            if (error) {
                return Immutable({ ...state, error, meta, isLoading: false });
            }
            return Immutable({
                ...state,
                isLoading: false,
                data: payload
            });

        case LOAD_ROOM_MESSAGES_STARTED:
            return Immutable({
                ...state,
                rel: meta.rel,
                isLoadingMessages: true
            });

        case LOAD_ROOM_MESSAGES: {
            const { rid, messages, concatMessages, messageLimit } = payload || {};
            if (error) {
                return  Immutable({ ...state, error, meta, isLoadingMessages: false });
            }
            if (!rid) return Immutable({ ...state, isLoadingMessages: false });
            const data = sortBy(messages, 'createDate');
            return Immutable({ 
                ...state, 
                isLoadingMessages: false,
                allMessagesLoaded: messages.length !== messageLimit ? true : false,
                messages: {...state.messages, [`${rid}`]: concatMessages ? [...data, ...(state.messages[`${rid}`] || [])] : data}
            });
        }
        
        case LOAD_ROOM_HISTORY_BY_TYPE: {
            const { rid, messages } = payload || {};
            if (error) {
                return  Immutable({ ...state, error, meta, isLoadingMessages: false });
            }
            if (!rid || !isEmpty(state.messages[`${rid}`])) {
                return Immutable({ ...state, isLoadingMessages: false });
            }

            const data = sortBy(messages, 'createDate');
            return Immutable({ 
                ...state, 
                isLoadingMessages: false,
                messages: {...state.messages, [`${rid}`]: data}
            });
        }

        case ADD_ROOM_MESSAGES:
        case SEND_CHAT_MESSAGE: {
            const { rid, message } = payload || {};
            const selectedMessages = get(state.messages, `${rid}`, []);
            const messages = [...selectedMessages];

            if (error) {
                return Immutable({ ...state, error, meta });
            }
            if (!rid) return Immutable({ ...state, isLoadingMessages: false });
            const index = messages.findIndex(mgs => mgs.id === message.id);
            if (index >= 0) {
                return Immutable({ ...state, isLoadingMessages: false });
            } else {
                const data = sortBy([...messages, message], 'createDate');
                return Immutable({
                    ...state,
                    messages: {...state.messages, [`${rid}`]: data}
                });

            }
        }

        case EDIT_ROOM_MESSAGES: {
            const { rid, message } = payload || {};
            const selectedMessages = get(state.messages, `${rid}`, []);
            const messages = [...selectedMessages];

            if (error) {
                return Immutable({ ...state, error, meta });
            }
            if (!rid) return Immutable({ ...state, isLoadingMessages: false });
            const index = messages.findIndex(mgs => mgs.id === message.id);
            if (index < 0) {
                return Immutable({ ...state, isLoadingMessages: false });
            } else {
                messages[index] = message;
                const data = sortBy(([...messages]), 'createDate');
                return Immutable({
                    ...state,
                    messages: {...state.messages, [`${rid}`]: data}
                });
            }
        }

        case LOAD_CHANNEL_MEMBERS_STARTED:
        case LOAD_ROOM_MEMBERS_STARTED: {
            const { rel } = meta;
            if (error || !shallowEquals(rel, state.rel)) {
                return Immutable({ ...state, error, meta });
            }
            return Immutable({ ...state, isLoadingMembers: true });
        }

        case LOAD_CHANNEL_MEMBERS:
        case LOAD_ROOM_MEMBERS: {
            const { members } = payload || {};
            if (error) {
                return state;
            }
            return Immutable({ ...state, isLoadingMembers: false, members });
        }
        
        case REMOVE_CHAT_MEMBERS: {
            const { relId, username } = payload || {};
            const { id: stateRelId } = state.rel || {};
            const members = state.members;
            if (!username || relId !== stateRelId) {
                return state;
            }
            const updatedMembers = members.filter(({ rel }) => rel.username !== username);
            return Immutable({ ...state, members: updatedMembers });
        }

        case UPLOAD_CHAT_ROOM_FILE_STARTED:
            return Immutable({ ...state, isFileUploading: true });

        case UPLOAD_CHAT_ROOM_FILE:
            return Immutable({ ...state, isFileUploading: false });

        case JOIN_PUBLIC_CHANNEL_STARTED:
        case ADD_DIRECT_MESSAGE_STARTED:
        case CREATE_CHANNEL_STARTED:
        case SUBSCRIBE_CHAT_STARTED:
            return Immutable({ ...state, isSubscribing: true });

        case JOIN_PUBLIC_CHANNEL:
        case SUBSCRIBE_CHAT:
        case ADD_DIRECT_MESSAGE:
        case CREATE_CHANNEL:
            if (error) {
                return Immutable({ ...state, isSubscribing: false });
            }
            return Immutable({ ...state, isSubscribing: false });

        case LOAD_GROUP_ROLES_STARTED:
        case LOAD_CHANNEL_ROLES_STARTED: {
            if (error) {
                return state;
            }
            return Immutable({ ...state, isLoadingRoles: true });
        }

        case LOAD_GROUP_ROLES:
        case LOAD_CHANNEL_ROLES: {
            if (error) {
                return state;
            }
            return Immutable({
                ...state,
                roles: payload,
                isLoadingRoles: false
            });
        }
        
        case UPDATE_CHAT_MESSAGE: {
            const { _id, msg, rid, editedBy } = payload || {};
            if (error && !rid) {
                return state;
            }
            const selectedMessages = [...get(state.messages, `${rid}`, [])];
            const index = selectedMessages.findIndex(mgs => mgs.id === _id);
            if (index < 0) {
                return state;
            } else {
                selectedMessages[index] = {
                    ...selectedMessages[index],
                    text: msg,
                    editedDate: new Date().toISOString(),
                    editedBy
                };
                const data = sortBy(([...selectedMessages]), 'createDate');
                return Immutable({
                    ...state,
                    messages: {...state.messages, [`${rid}`]: data}
                });
            } 
        }

        case RESET_ROOM_INITIAL_REL_STATE:
            return Immutable({ ...state, rel: null });

        default:
            return state;
    }
};
