/* @flow */

import React, { PureComponent, Fragment } from 'react';
import memoizeOne from 'memoize-one';
import uuidv1 from 'uuid/v1';
import styled from 'styled-components';
import { Dialog, DialogTitle, DialogContent, MdiIcon, Box, IconButton } from '@mic3/platform-ui';

import { bind, debounce } from 'app/utils/decorators/decoratorUtils';
import Forest from 'app/utils/dataStructure/Forest';
import AppGroupDnd from './AppGroupDnd';
import TextFieldDynamic from 'app/containers/Designer/Form/components/TextFieldDynamic';

const DialogStyled = styled(Dialog)`
    & .MuiPaper-root {
        width: 100%;
    }

    & .MuiDialogTitle-root, .MuiDialogContent-root {
        padding: 24px;
    }

    & .MuiDialogContent-root {
        padding-top: 0;
    }
`;

const TextFieldDynamicStyled = styled(TextFieldDynamic)`
    flex-grow: 1;
`;

const Modal = ({ selectedGroup, toggleModal, children, onChangeGroupName }) => {
    return (
        <DialogStyled
            maxWidth="sm"
            open={!!selectedGroup}
            onClose={toggleModal}
        >
            <DialogTitle id="alert-dialog-title">
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <TextFieldDynamicStyled name={selectedGroup.groupId} value={selectedGroup.name} onChange={onChangeGroupName} withPencil={true} />
                    <IconButton onClick={toggleModal}>
                        <MdiIcon name="close"/>
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
        </DialogStyled>
    );
};

class AppGroup extends PureComponent<Object, Object> {

    state: Object
    uuid: string = uuidv1();
    scrolling: any = null;
    constructor(props: Object) {
        super(props);
        const denormalizedApps = this.deNormalizeApps(props.apps || []);
        this.state = {
            selectedGroup: null,
            apps: {
                ...denormalizedApps,
                uuid: props.uuid || this.uuid,
                // $FlowFixMe
                children: this.forest(denormalizedApps).nodes
            },
        };
        const { filterBy, orderBy, excludeBy, loadApps } = this.props;
        loadApps({ filterBy, orderBy, excludeBy });
    }

    componentDidUpdate(prevProps: Object) {
        const { apps, uuid, filterBy, orderBy, excludeBy, loadApps } = this.props;
        // $FlowFixMe
        if (prevProps.apps !== apps) {
            const denormalizedApps = this.deNormalizeApps(apps || []);
            this.setState({ apps: {
                ...denormalizedApps,
                uuid: uuid || this.uuid,
                // $FlowFixMe
                children: this.forest(denormalizedApps).nodes
            }

            });
        }
        if(filterBy !== prevProps.filterBy || orderBy !== prevProps.orderBy) {
            loadApps({ filterBy, orderBy, excludeBy });
        }
    }

    _normalizeChildren = (children: Array<Object>): Array<Object> => {
        return children.map((child: Object) => {
            const { uuid, type, ...app } = child;
            return app;
        });
    }

    normalizeApps = (apps: Object, parent: boolean = true): Array<Object> => {
        return this._normalizeChildren(apps.children);
    }

    deNormalizeApps = (apps: Array<Object>, parent: boolean = true) => {
        return apps.reduce((accum, app) => {
            const child = { ...app, type: app.type || 'child' };
            accum.children.push(child);
            return accum;
        }, { type: 'group', children: []});
    }

    _forest = memoizeOne((apps: Object) => {
        return new Forest(apps.children, this.uuid);
    });

    forest = (apps: ?Object) => {
        return this._forest(apps || this.state.apps);
    };

    onDrop = (item: Object, parentUuid: string, index: number) => {
        const { apps } = this.state;
        let hoveredItem = apps.children[index];
        if(item.id === hoveredItem.id) {
            return;
        }
        const nextState = { apps: { ...apps }};
        if(hoveredItem.type === 'child'){
            const groupId = nextState.apps.children.filter(g => g.type==='group').length + 1;
            item.groupId = groupId;
            hoveredItem = {
                ...hoveredItem,
                groupId,
            };
            nextState.apps.children = this.forest().remove(hoveredItem).nodes;
            nextState.apps.children = this.forest().remove(item).nodes;
            nextState.apps.children = [
                { uuid: uuidv1(), type: 'group', groupId, children: [item, hoveredItem]},
                ...nextState.apps.children
            ];
        } else {
            nextState.apps.children = this.forest(apps).move(item, hoveredItem.uuid, index).nodes;
        }
        this.setState(nextState, () => this.props.savePreferences(nextState.apps));
    }
    onDelete = (item: Object) => {
        const { apps, selectedGroup } = this.state;
        let nextState = { apps: { ...apps }, selectedGroup: {
            ...selectedGroup,
            children: selectedGroup.children.filter(it => it.uuid !== item.uuid)
        }};
        if (nextState.selectedGroup.children.length === 0) {
            nextState = { ...nextState, selectedGroup: null };
        }
        nextState.apps.children = this.forest().remove(item).nodes;
        this.setState(nextState, () => this.props.savePreferences(nextState.apps));
    }

    @bind
    openGroup(selectedGroup: Object) {
        return () => {
            this.setState(state => ({ showModal: true, selectedGroup }));
        };
    }

    @bind
    closeGroup() {
        this.setState(state => ({ showModal: false, selectedGroup: null}));
    }

    @bind
    onChangeGroupName({ target: { name, value: groupName }}: Object) {
        const { selectedGroup, apps } = this.state;
        const nextState = {
            apps: {
                ...apps,
                children: apps.children.map((it) => {
                    if(it.groupId === name) {
                        it.name = groupName;
                        return {...it, name: groupName};
                    }
                    return it;
                })
            },
            selectedGroup: {
                ...selectedGroup,
                name: groupName
            }};
        this.setState(nextState, () => this.props.savePreferences(nextState.apps));
    }

    @bind
    @debounce(100)
    scrollTop() {
        this.scrolling = setInterval(() => {
            var contentArea = document.getElementsByClassName('content-area')[0];
            contentArea.scrollTop = contentArea.scrollTop - 5;
        }, 300);
    }

    @bind
    @debounce(100)
    scrollStop() {
        if(this.scrolling) {
            clearInterval(this.scrolling);
        }
    }

    render() {
        const { apps, selectedGroup } = this.state;
        return (
            <Fragment>
                {/*<ScrollTarget onScroll={this.scrollTop} onScrollStop={this.scrollStop} />*/}
                <AppGroupDnd
                    uuid={this.uuid}
                    onDrop={this.onDrop}
                    onDelete={this.onDelete}
                    openGroup={this.openGroup}
                    closeGroup={this.closeGroup}
                    apps={apps}
                />
                {selectedGroup && (
                    <Modal onChangeGroupName={this.onChangeGroupName} selectedGroup={selectedGroup} toggleModal={this.closeGroup} >
                        <AppGroupDnd
                            key={selectedGroup.uuid}
                            uuid={selectedGroup.uuid}
                            root={false}
                            apps={selectedGroup}
                            onDrop={this.onDrop}
                            onDelete={this.onDelete}
                        />
                    </Modal>
                )}
            </Fragment>
        );
    }
};

export default AppGroup;
