import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Button } from '@mic3/platform-ui';

import history from 'store/History';
import {
    unsubscribeEntityChat,
    unsubscribeGroupChat,
    unsubscribeChannelChat,
} from 'store/actions/chat/chatActions';

import { bind } from 'app/utils/decorators/decoratorUtils';
import { isEmpty } from 'app/utils/utils';
import { get } from 'app/utils/lo/lo';

import Icon from 'app/components/atoms/Icon/Icon';

const SubscribeOnChatWrapper = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 99;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    flex-shrink: 0;
    justify-content: center;
    background: ${({ theme }) => theme.material.colors.background.default};
`;

const IconStyled = styled(Icon)`
    &:before {
        font-size: 95px !important;
        color: rgba(255,255,255,0.24);
        @media (max-width:481px){
            font-size: 48px !important;
        }
    }
`;

const SecondaryTextStyled = styled.p`
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.15000000596046448px;
    margin: 0 0 10px 0;
`;

const ButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    & button {
        margin: 0 5px;
    }
`;

const PrimaryTextStyled = styled.p`
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: -0.015em;
    margin: 0 0 10px 0;
`;

class ChatAccessDenied extends PureComponent {
    @bind
    async unsubscribeChat() {
        const { id, type, profile, isEntityChat, unsubscribeEntityChat, unsubscribeGroupChat, 
            unsubscribeChannelChat, rid } = this.props;

        let res = null;

        if (isEntityChat) {
            res = await unsubscribeEntityChat({ id, type, userId: profile.id, rid });
        } else if (type === 'group') {
            res =  await unsubscribeGroupChat(id);
        } else if (type === 'channel') {
            res =  await unsubscribeChannelChat(id);
        }  

        const isError = get(res, 'error', false);
        if (!isError) history.push(`/abox/alive`);
    }

    @bind
    goBack() {
        history.push(`/abox/alive`);
    }

    render() {
        const { subscription, type, isEntityChat, id } = this.props;
        const uniqueKey = (isEntityChat && id) ? id.split('-')[0] : '';
        const parsedType = type !== 'user' ? type : 'person';

        return (
            <SubscribeOnChatWrapper>
                <IconStyled name='lock'/>
                <PrimaryTextStyled>
                    {`Uh-oh! You need permission to access ${isEntityChat ? `${parsedType} #${uniqueKey}.`: 'this chat.'}`}
                </PrimaryTextStyled>
                <SecondaryTextStyled>Ask for access or switch to an account with access.</SecondaryTextStyled>
                <ButtonsWrapper>
                    {!isEmpty(subscription) && <Button variant='contained' color='primary' onClick={this.unsubscribeChat}>UNSUBSCRIBE</Button>}
                    <Button variant='outlined' color='secondary' onClick={this.goBack}>GO HOME</Button>
                </ButtonsWrapper>
            </SubscribeOnChatWrapper>
        );
    }
}

export default connect(
    null,
    {
        unsubscribeEntityChat,
        unsubscribeGroupChat,
        unsubscribeChannelChat,
    }
)(ChatAccessDenied);