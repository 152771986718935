/* @flow */

// $FlowFixMe
import React, { useCallback, useState, useEffect } from 'react';
import styled from 'styled-components';
import {
    ExpansionPanel as MuiExpansionPanel, ExpansionPanelDetails,
    ExpansionPanelSummary, Typography, MdiIcon
} from '@mic3/platform-ui';

const ExpansionPanelHeader = styled(ExpansionPanelSummary)`
  padding: 0 16px !important;
  min-height: 64px !important;
  & h6 {
    line-height: 1;
  }
  ${({ collapsible }) => !collapsible ? `
    .MuiExpansionPanelSummary-expandIcon {
      display: none !important;
    }
  `: ''}

  & .MuiExpansionPanelSummary-content {
      margin: 8px 0 !important;
  }
`;

const SubHeading = styled.span`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: rgba(255, 255, 255, 0.7);
    mix-blend-mode: normal;
    flex: none;
    order: 0;
    flex-grow: 1;
`;

const ExpansionPanelStyled = styled(MuiExpansionPanel)`
    width: 100%;
    margin: 0.5rem 0 !important;

    ${({ background }) => background && `background-color: ${background} !important`};
    ${({ plain }) => plain && `
        box-shadow: none !important;
        margin: 0 !important;
        &:before {
          opacity: 0 !important;
        }
    `};

    &.MuiExpansionPanel-rounded {
        border-radius: 2px !important;
    }
`;

const ExpansionPanelDetailsColumn = styled(ExpansionPanelDetails)`
    padding: 0 16px 12px 16px !important;
    margin-top: -12px !important;
    flex-direction: column;
`;

const Subtitle = styled(Typography)`
    padding-top: 0.4rem;
    font-size: 0.6rem;
`;

const ExpansionPanelHOC = (props: Object) => {
    const [expanded, toggleExpanded] = useState(!!props.expanded);
    useEffect(() => {
        toggleExpanded(!!props.expanded);
    }, [props.expanded]);

    const onChange = useCallback(() => {
        if(props.collapsible) {
            toggleExpanded(!expanded);
        }
        if (props.onChange) {
            props.onChange({ target: { value: expanded, name: props.name } });
        }
    }, [expanded, props]);

    return <ExpansionPanelStyled {...props} expanded={expanded} onChange={onChange} />;
};

const ExpansionPanel = (props: Object) => {
    const { 
        background, plain, collapsible, showHeader, style, 
        expanded, header, help, buildHelperWrapper, headerComponent, 
        subtitle, children, className, id, onChange, name, disabled 
    } = props;

    const buildedHeader = (
        <ExpansionPanelHeader disabled={disabled} collapsible={collapsible?1:0} expandIcon={collapsible ? <MdiIcon name="chevron-down" /> : null}>
            {(showHeader !== false) && (headerComponent || (
                <div style={{ marginTop: '12px'}}>
                    <Typography variant="subtitle1">{header}</Typography>
                    <SubHeading>{subtitle}</SubHeading>
                </div>
            ))}
        </ExpansionPanelHeader>
    );

    return (
        <ExpansionPanelHOC
            collapsible={collapsible?1:0}
            style={style}
            name={name}
            expanded={!collapsible || expanded}
            className={className}
            id={id}
            onChange={!collapsible ? () => {} : onChange}
            plain={plain?1:0}
            background={background}
        >
            {buildHelperWrapper ? buildHelperWrapper(null, help, buildedHeader) : buildedHeader}
            <ExpansionPanelDetailsColumn>{children || <span />}</ExpansionPanelDetailsColumn>
        </ExpansionPanelHOC>
    );
};


export const StatelessPanel = (props: Object) => {
    const { style, expanded, header, headerComponent, subtitle, children, className, id, onChange, name } = props;
    return (
        <ExpansionPanelStyled style={style} name={name} expanded={expanded} className={className} id={id} onChange={onChange}>
            <ExpansionPanelHeader expandIcon={<MdiIcon name="chevron-down" />}>
                {headerComponent || (
                    <div>
                        <Typography variant="subtitle1">{header}</Typography>
                        <Subtitle component="div" variant="caption">{subtitle}</Subtitle>
                    </div>
                )}
            </ExpansionPanelHeader>
            <ExpansionPanelDetailsColumn>{children || <span />}</ExpansionPanelDetailsColumn>
        </ExpansionPanelStyled>
    );
};

export default ExpansionPanel;
