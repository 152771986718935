import React from 'react';

import Help from 'app/utils/designer/form/settings/common/Help';

const onEventCodeExample = `
// Example:

/**
 * The function must returns the actions that have to be executed.
 *
 * @param event, the event
 * @param data, the data accessible to the component
 * @param context, the global context
 * @param inherited, the inherited data (only for components that are inside of a group)
 */
(event, data, context, inherited) => {
  return [
    // the following will set the component property 'color'
    // { action: 'setProperty', name: 'color', value: 'secondary' },
    // the following will set the data 'x'
    // { action: 'setVariable', name: 'x', value: null },
    // the action 'setVariable' is the default action and can be omitted
    // the following will save the data 'y'
    // { name: 'y', value: null },
    // because the event target contains the name and value of the component,
    // the following will save the component value
    event.target,

    // the following item will open a modal window that will render the form with
    // the specified ID using the specified data
    // parameter
    // in a modal window
    {
      action: 'openForm',
      options: { id: '47289', data:{} }
    },

    // the following action will start the process with the specified process key
    // using the specified variables
    {
      action: 'startProcess',
      options: { key: 'anyprocesskey', variables: {} }
    },

    // the following action open the specified href in the current window/tab
    {
      action: 'navigate',
      options: { href: ‘/#/abox/tasks’ }
    },

    // the following action will open the specified href in a new window/tab.
    {
      action: 'link',
      options: { href: ‘/#/abox/tasks’ }
    },

    // the following item will show an info in a toaster
    {
      action: 'showMessage',
      options: {
        type: 'info', // available types are: 'info', 'warn', 'error' and 'success'
        message: 'Here we are :)',
      }
    },

    // the following item will set the component property 'color'
    { action: 'setProperty', name: 'color', value: 'secondary' },

    // the following item will set the variables 'x'
    { action: 'setVariable', name: 'x', value: null },

    // the action 'setVariable' is the default action and can be omitted
    // the following item will save the variables 'y'
    { name: 'y', value: null },

    // because the event target contains the name and value of the component,
    // the following item will save the component value
    event.target,

    // if the component is inside a group or a groupRepeat the following item
    // will set the variables ‘z’ in the inherited data
    { action: 'setInheritedData', name: 'z', value: 'something' },

    // This action can be used just in Before action of button click
    // for interrupting
    { action: 'reject' },

  ];
}

/*
The global context will contains the following fields:
- user, the data related to the user profile
- process, the data related to the process (if any)
- task, the data related to the task (if any)
- graphql, a function to call our main GQL API
- location, a JSON containing the origin and the href of this form
- sso, a JSON containing the SSO token

The graphql function has 2 parameters:
- body, the query and the data
- options, the HTTP request options (header, ...)

Example:
async (event, data, context, inherited) => {
  const value = event.target.value
  const query = \`
  query findPeople($filterBy:[JSON]) {
    people(page: 1, filterBy: $filterBy) {
      name
    }
  }
  \`
  const vars = {
    "filterBy": [{ field: "name", op: "contains", value }]
  }
  const response = await context.graphql({ query, data: vars })
  return [
    { name: 'list', value: response.people }
  ]
}

If a component is definied inside of a group or a group repeat, the data
parameter will contains only the data corresponding to the group or to the
related item in case of a group repeat.
In this case, the fourth parameter will contains the inherited fields:
- data, it will contains the process data
- path, it is the path of the reference used by the group
- index, the index of the item related to the component in a group repeat
To set a variable outside of the group/"related item" we need to specify the
action 'setInheritedData', e.g.:
{ action: 'setInheritedData', name: 'z', value: null }
*/
`;

export const onChange = {
    type: 'textarea',
    properties: {
        parseAs: 'javascript',
        modal: true,
        title: 'On change',
        label: 'onChange',
        name: 'properties.onChange',
        help: <Help message="This function triggers when the value is changed." />,
        template: onEventCodeExample,
        useCodeEditor: true,
    }
};

export const onClick = {
    type: 'textarea',
    properties: {
        parseAs: 'javascript',
        modal: true,
        title: 'On click',
        label: 'onClick',
        name: 'properties.onClick',
        help: <Help message="This function triggers when the component is clicked." />,
        template: onEventCodeExample,
        useCodeEditor: true,
    }
};

const onLoadCodeExample = `
// Example:

/**
 * The function must returns the actions that have to be executed.
 *
 * @param data, the data accessible to the component
 * @param context, the global context
 * @param inherited, the inherited data (only for components that are inside of a group)
 */
(data, context, inherited) => {
  return [
    { action: 'setProperty', name: 'color', value: 'green'},
  ];
}

/*
The global context will contains the following fields:
- user, the data related to the user profile
- process, the data related to the process (if any)
- task, the data related to the task (if any)
- graphql, a function to call our main GQL API
- location, a JSON containing the origin and the href of this form
- sso, a JSON containing the SSO token

The graphql function has 2 parameters:
- body, the query and the data
- options, the HTTP request options (header, ...)

Example:
async (data, context, inherited) => {
  const value = data.name
  const query = \`
  query findPeople($filterBy:[JSON]) {
    people(page: 1, filterBy: $filterBy) {
      name
    }
  }
  \`
  const vars = {
    "filterBy": [{ field: "name", op: "contains", value }]
  }
  const response = await context.graphql({ query, data: vars })
  return [
    { name: 'list', value: response.people }
  ]
}

If a component is definied inside of a group or a group repeat, the data
parameter will contains only the data corresponding to the group or to the
related item in case of a group repeat.
In this case, the fourth parameter will contains the inherited fields:
- data, it will contains the process data
- path, it is the path of the reference used by the group
- index, the index of the item related to the component in a group repeat
To set a variable outside of the group/"related item" we need to specify the
action 'setInheritedData', e.g.:
{ action: 'setInheritedData', name: 'z', value: null }
*/
`;

export const onLoad = {
    type: 'textarea',
    properties: {
        parseAs: 'javascript',
        modal: true,
        title: 'On Load',
        label: 'onLoad',
        name: 'properties.onLoad',
        help: <Help message="This function triggers when the component is loaded." />,
        template: onLoadCodeExample,
        useCodeEditor: true,
    }
};

const onRefreshCodeExample = `
// Example:

/**
 * The function must returns the actions that have to be executed.
 *
 * @param data, the data accessible to the component
 * @param state, current state of component be updated every second from "interval" property value
 */
(data, state) => {
  const mttr = parseInt(((new Date(data.endDate).getTime()) - Date.now()) / 1000);
  return { mttrInSeconds: mttr };
}
`;

export const onRefresh = {
    type: 'textarea',
    properties: {
        parseAs: 'javascript',
        modal: true,
        title: 'On Refresh',
        label: 'onRefresh',
        name: 'properties.onRefresh',
        help: <Help message="This function triggers when the component is loaded." />,
        template: onRefreshCodeExample,
        useCodeEditor: true,
    }
};

export const refreshInterval = {
    type: 'number',
    properties: {
        parseAs: 'javascript',
        modal: true,
        label: 'Refresh Interval for onRefresh',
        name: 'properties.refreshInterval',
        help: <Help message="This value define interval in seconds for refreshing component state. Value has to be more then 0." />,
    }
};

const isVisibleCodeExample = `
// Example:

/**
 * The function will handle the component visibility and it must return a
 * boolean value. If the returned value is true the component will be visible.
 *
 * @param data, the data accessible to the component
 * @param context, the global context
 * @param inherited, the inherited data (only for components that are inside of a group)
 */
(data, context, inherited) => !context.task


/*
The global context will contains the following fields:
- user, the data related to the user profile
- process, the data related to the process (if any)
- task, the data related to the task (if any)
- graphql, a fuction to call our main GQL API
- location, a JSON containing the origin and the href of this form
- sso, a JSON containing the SSO token

The graphql function has 2 paramers:
- body, the query and the data
- options, the HTTP request options (header, ...)

Example:
async (data, context, inherited) => {
  const value = data.name
  const query = \`
  query findPeople($filterBy:[JSON]) {
    people(page: 1, filterBy: $filterBy) {
      name
    }
  }
  \`
  const vars = {
    "filterBy": [{ field: "name", op: "contains", value }]
  }
  const response = await context.graphql({ query, data: vars })
  return response.people.length > 0
}

If a component is definied inside of a group or a group repeat, the data
parameter will contains only the data corresponding to the group or to the
related item in case of a group repeat.
In this case, the fourth parameter will contains the inherited fields:
- data, it will contains the process data
- path, it is the path of the reference used by the group
- index, the index of the item related to the component in a group repeat
*/
`;

export const isVisible = {
    type: 'textarea',
    properties: {
        parseAs: 'javascript',
        label: 'isVisible',
        name: 'properties.isVisible',
        help: <Help message="This function defines the visibility of the component." />,
        title: 'Visibility',
        modal: true,
        template: isVisibleCodeExample,
        useCodeEditor: true,
    }
};

const isDisabledCodeExample = `
// Example:

/**
 * The function will handle the component visibility and it must return a
 * boolean value. If the returned value is true the component will be visible.
 *
 * @param data, the data accessible to the component
 * @param context, the global context
 * @param inherited, the inherited data (only for components that are inside of a group)
 */
(data, context, inherited) => !context.task


/*
The global context will contains the following fields:
- user, the data related to the user profile
- process, the data related to the process (if any)
- task, the data related to the task (if any)
- graphql, a fuction to call our main GQL API
- location, a JSON containing the origin and the href of this form
- sso, a JSON containing the SSO token

The graphql function has 2 paramers:
- body, the query and the data
- options, the HTTP request options (header, ...)

Example:
async (data, context, inherited) => {
  const value = data.name
  const query = \`
  query findPeople($filterBy:[JSON]) {
    people(page: 1, filterBy: $filterBy) {
      name
    }
  }
  \`
  const vars = {
    "filterBy": [{ field: "name", op: "contains", value }]
  }
  const response = await context.graphql({ query, data: vars })
  return response.people.length > 0
}

If a component is definied inside of a group or a group repeat, the data
parameter will contains only the data corresponding to the group or to the
related item in case of a group repeat.
In this case, the fourth parameter will contains the inherited fields:
- data, it will contains the process data
- path, it is the path of the reference used by the group
- index, the index of the item related to the component in a group repeat
*/
`;

export const isDisabled = {
    type: 'textarea',
    properties: {
        parseAs: 'javascript',
        label: 'isDisabled',
        name: 'properties.disabled',
        help: <Help message="This function defines the disabling of the component." />,
        title: 'Disabled',
        modal: true,
        template: isDisabledCodeExample,
        useCodeEditor: true,
    }
};

const onCalculationCodeExample = `
// Example:

/**
 * The function must returns the actions that have to be executed on saving.
 *
 * @param data, the data accessible to the component
 * @param context, the global context
 * @param inherited, the inherited data (only for components that are inside of a group)

 * To learn more about the calculated attributes, check out the docs folder: https://domain_name/docs/application/entity_types_and_classes_special_attributes.html
 */
(data, context, inherited) => {
  return data['calculated-fields/number1'] + data['calculated-fields/number2'];
};
`;


export const onCalculation = {
    header: 'Calculation Formula',
    children: [
        {
            type: 'textarea',
            properties: {
                parseAs: 'javascript',
                modal: true,
                title: 'Calculation Formula',
                label: 'Script',
                labelDialogField: 'Script',
                name: 'properties.calculation',
                help: <Help message="Define the value of the attribute by using other attributes and a formula written in Java Script." />,
                template: onCalculationCodeExample,
                useCodeEditor: true,
                onChange: (e) => {
                    if(e.target.value) {
                        return [
                            e.target, 
                            { name: 'constraints', value: { required: false }},
                            { name: 'properties.disabled', value: true }, 
                            // { name: 'properties.readOnly', value: false },
                            { name: 'properties.defaultValue', value: undefined } 
                        ];
                    }
                    return [e.target, { name: 'properties.disabled', value: false }];
                }                
            }
        }
    ].filter(Boolean)
};