/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Avatar, Grid, Chip, CircularProgress, FormLabel, FormHelperText, Tooltip } from '@mic3/platform-ui';

import TaskTypeaheadStd from 'app/components/organisms/Form/Typeahead/TaskTypeahead';
import ProcessTypeaheadStd from 'app/components/organisms/Form/Typeahead/ProcessTypeahead';
import PersonTypeaheadStd from 'app/components/organisms/Form/Typeahead/PersonTypeahead';
import OrganisationTypeaheadStd from 'app/components/organisms/Form/Typeahead/OrganisationTypeahead';
import Icon from 'app/components/atoms/Icon/Icon';
import EntityLink from 'app/components/atoms/Link/EntityLink';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { isEmpty, shallowEquals } from 'app/utils/utils';
import { get } from 'app/utils/lo/lo';
import { loadRelationsTypeahead, createRelationship, deleteRelationship } from 'store/actions/entities/relationshipsActions';
import EntityTypeahead from 'app/components/organisms/Form/Typeahead/EntityTypeahead';

const FormLabelStyled = styled(FormLabel)`
    padding-left: 8px !important;
    font-size: 12px !important;
`;

const AvatarIcon = styled(Avatar)`
    margin-left: 0 !important;
    background: transparent !important;
`;

const ProgressBox = styled.div`
    padding-top: 8px;
`;

const PersonTypeahead = styled(PersonTypeaheadStd)`
    margin-top: 0px !important;
    input {
        padding: 8px !important;
    }
`;

const OrganisationTypeahead = styled(OrganisationTypeaheadStd)`
    margin-top: 0px !important;
    input {
        padding: 8px !important;
    }
`;

const TaskTypeahead = styled(TaskTypeaheadStd)`
    margin-top: 0px !important;
    input {
        padding: 8px !important;
    }
`;

const ProcessTypeahead = styled(ProcessTypeaheadStd)`
    margin-top: 0px !important;
    input {
        padding: 8px !important;
    }
`;

const ChipStyled = styled(Chip)`
    margin: 8px 8px 0px 8px !important;
    height: 24px;
    color: ${({ theme }) => theme.material.palette.text.secondary};
    a {
        text-decoration: none;
        color: ${({ theme }) => theme.material.colors.text.primary};
    }
`;

const FieldBox = styled.div`
    margin: 12px 0px;
`;

const Box = styled.div`
    border-radius: 4px 4px 0px 0px;
    width: 100%;
    ${({ disabled, theme }) => (!disabled ? `background: ${theme.material.colors.background.fields};` : '')}
    padding-top: 4px 0px 0px 0px;

    & :focus-within .field-label {
        color: ${({ error }) => (error ? 'red' : '#4BB9D9')};
    }
    & .MuiFilledInput-root, & .MuiFilledInput-root:hover {
      background-color: transparent !important;
    }
`;

const FormHelperTextStyled = styled(FormHelperText)`
    padding-left: 8px !important;
`;

/**
 * Select one or more groups using lazy loading.
 */
class RelationsTypeahead extends PureComponent<Object, Object> {
    static defaultProps = {
        reverse: false
    };

    static propTypes = {
        fromId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        fromType: PropTypes.string,
        toType: PropTypes.string,
        definitionId: PropTypes.number,
        reverse: PropTypes.bool,
        loadRelationsTypeahead: PropTypes.func.isRequired,
        createRelationship: PropTypes.func.isRequired,
        deleteRelationship: PropTypes.func.isRequired,
        options: PropTypes.arrayOf(PropTypes.object),
        filterBy: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.array])),
        excludeBy: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.array]))
    };

    constructor(props) {
        super(props);
        this.state = { isLoading: false, relations: [] };
    }

    componentDidMount() {
        const { fromId, fromType } = this.props;
        if (fromId && fromType) {
            this.fetchRelations({ fromId, fromType });
        }
    }

    componentDidUpdate(prevProps) {
        if (!shallowEquals(this.props, prevProps, ['fromId', 'fromType'])) {
            const { fromId, fromType } = this.props;
            this.fetchRelations({ fromId, fromType });
        }
    }

    delete(relationId) {
        this.setState({ isLoading: true }, async () => {
            await this.props.deleteRelationship(relationId);
            const { fromId, fromType } = this.props;
            this.fetchRelations({ fromId, fromType });
        });
    }

    @bind
    async onSelect(event) {
        const toId = get(event, 'target.value.id');
        const relatedEntityType = get(event, 'target.value.type');

        if (!toId || !relatedEntityType) {
            return;
        }
        const { fromId, fromType: ftype, definition, reverse } = this.props;
        const fromType = ftype === 'task' ? 'opentask' : ftype; // Handling the legacy data
        this.setState({ isLoading: true }, async () => {
            const relationDefinition = get(definition, 'id', '');
            let record = {
                entity: { type: fromType, id: fromId },
                relationDefinition,
                relatedEntity: { type: relatedEntityType, id: toId }
            };
            if (reverse || definition?.reverse) {
                record = {
                    relatedEntity: { type: fromType, id: fromId },
                    relationDefinition,
                    entity: { type: relatedEntityType, id: toId },
                };
            }

            await this.props.createRelationship(record);
            this.fetchRelations({ fromId, fromType });
        });
    }

    @bind
    fetchRelations({ fromId, fromType }) {
        const { definition } = this.props;
        if (!fromId || !fromType || !definition) {
            return;
        }
        const onChange = relations => this.props.onChange({ target: { value: relations, name: this.props.name } });
        this.setState({ isLoading: true }, () => {
            const fType = fromType === 'task' ? 'opentask' : fromType; // Handling the legacy data
            const filterBy = [{ field: 'relation.relationDefinition.id', op: '=', value: definition?.id }];
            this.props
                .loadRelationsTypeahead({ entityType: fType, entityId: fromId, filterBy })
                .then((result) => {
                    const relations = get(result, 'relations', []);
                    this.setState({ relations, isLoading: false }, () => onChange(relations));
                })
                .catch(error => this.setState({ relations: [], isLoading: false }, () => onChange([])));
        });
    }

    @bind
    @memoize()
    buildProps(props, relations) {
        const {
            fromId,
            fromType,
            definitionId,
            toType,
            reverse,
            filterBy,
            label,
            loadRelationsTypeahead,
            createRelationship,
            deleteRelationship,
            ...typeaheadProps
        } = props;
        let filters = filterBy || [];
        if (!isEmpty(relations.filter(({ relatedEntity }) => relatedEntity))) {
            const ids = relations.map(({ relatedEntity }) => get(relatedEntity, 'id'));
            filters = [...(filters || []), { field: 'id', op: 'not in', value: ids }];
        }
        if (fromType === toType) {
            const id = fromType === 'process' || fromType === 'task' ? String(fromId) : fromId;
            filters = [...(filters || []), { field: 'id', op: '<>', value: id }];
        }
        if (['opentask', 'closedtask', 'openprocess', 'closedprocess'].includes(toType)) {
            filters?.push({ field: 'type', op: '=', value: toType });
        }
        return {
            ...typeaheadProps,
            value: null,
            onChange: this.onSelect,
            filterBy: filters
        };
    }

    @bind
    @memoize()
    getTypeahead(props, relations) {
        const typeaheadProps = this.buildProps(props, relations);
        switch (props.toType) {
            case 'task':
            case 'system_task':
            case 'opentask':
                return <TaskTypeahead {...typeaheadProps} />;
            case 'process':
            case 'system_process':
            case 'openprocess':
                return <ProcessTypeahead {...typeaheadProps} />;
            case 'person':
                return <PersonTypeahead {...typeaheadProps} />;
            case 'organisation':
                return <OrganisationTypeahead {...typeaheadProps} />;
            default:
                return <EntityTypeahead {...typeaheadProps} entityType={props.toType} />;
        }
    }

    @bind
    @memoize()
    getIcon(type) {
        switch (type) {
            case 'thing':
                return (
                    <AvatarIcon>
                        <Icon name="things" type="af" size="sm" />
                    </AvatarIcon>
                );
            case 'person':
                return (
                    <AvatarIcon>
                        <Icon name="people" type="af" size="sm" />
                    </AvatarIcon>
                );
            case 'organisation':
                return (
                    <AvatarIcon>
                        <Icon name="organisations" type="af" size="sm" />
                    </AvatarIcon>
                );
            case 'custom':
                return (
                    <AvatarIcon>
                        <Icon name="shape-circle-plus" size="sm" />
                    </AvatarIcon>
                );
            case 'task':
                return (
                    <AvatarIcon>
                        <Icon name="task" type="af" size="sm" />
                    </AvatarIcon>
                );
            case 'process':
                return (
                    <AvatarIcon>
                        <Icon name="processes" type="af" size="sm" />
                    </AvatarIcon>
                );
            default:
                return null;
        }
    }

    @bind
    @memoize()
    getChips(relations, disabled, definition) {
        const filteredRelations = relations
            .filter(({ relation }) => get(relation, 'relationDefinition.id') === get(definition, 'id'))
            .map((relation) => {
                const { id, type, name } = relation.relatedEntity;
                const relationId = get(relation, 'relation.id', '');
                return (
                    <>
                        <Tooltip title={name}>
                            <ChipStyled
                                key={relationId}
                                label={
                                    <EntityLink id={id} type={type}>
                                        {name}
                                    </EntityLink>
                                }
                                onDelete={() => this.delete(relationId)}
                                avatar={this.getIcon(relation.relatedEntity.type)}
                                disabled={disabled}
                                variant="outlined"
                            />
                        </Tooltip>
                    </>
                );
            });
        if (isEmpty(filteredRelations)) {
            return '';
        }
        return filteredRelations;
    }

    render() {
        const { editablePanel, editable, fromId, fromType, toType, definition, label, disabled, required, error } = this.props;
        const isDisabled = (editablePanel && !editable) || disabled || !fromId || !fromType || !toType || !definition;
        const { isLoading, relations } = this.state;
        const { helperText, ...restProps } = this.props;
        const typeaheadProps = { ...restProps, disabled: isDisabled };
        return (
            <FieldBox>
                <Box disabled={isDisabled ? 1 : 0}>
                    {label && (
                        <FormLabelStyled className={'field-label'} disabled={isDisabled} required={required} error={error}>
                            {label}
                        </FormLabelStyled>
                    )}
                    <Grid container>
                        {this.getChips(relations, isDisabled, definition)}
                        {isLoading && (
                            <ProgressBox>
                                <CircularProgress size={14} />
                            </ProgressBox>
                        )}
                    </Grid>
                    {!isDisabled ? this.getTypeahead(typeaheadProps, relations) : null}
                </Box>
                {helperText && (
                    <FormHelperTextStyled disabled={isDisabled} required={required} error={error}>
                        {helperText}
                    </FormHelperTextStyled>
                )}
            </FieldBox>
        );
    }
}

export default connect(null, { loadRelationsTypeahead, createRelationship, deleteRelationship })(RelationsTypeahead);
