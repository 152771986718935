/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import uuidv1 from 'uuid/v1';

import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import { bind } from 'app/utils/decorators/decoratorUtils';
import { Button } from '@mic3/platform-ui';
import { updateRelationDefinition, removeRelationDefinitionClasses, addRelationDefinitionClasses } from 'store/actions/entities/relationshipsActions';
import Container from 'app/components/atoms/Container/Container';
import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import HeaderBar from 'app/components/molecules/HeaderBar/HeaderBar';
import SelectionList from 'app/containers/Common/SelectionList/SelectionList';
import { get } from 'app/utils/lo/lo';
import { isEmpty, getStr } from 'app/utils/utils';

const extraOptions = [{ id: uuidv1(), name: 'Event', uri: 'event'}];

export const relationDefinitionsComponent = [
    {
        type: 'entityTypesTypeahead',
        properties: {
            label: 'Type',
            name: 'type',
            valueField: 'uri',
            extraOptions,
            filterBy: [
                { field: 'active', op: '=', value: true },
            ]
        },
        constraints: { required: true }
    },
    {
        type: 'entityTypesTypeahead',
        properties: {
            label: 'Related Type',
            name: 'relatedType',
            valueField: 'uri',
            extraOptions,
            filterBy: [
                { field: 'active', op: '=', value: true },
            ]
        },
        constraints: { required: true }
    },
    {
        type: 'text',
        properties: { label: 'Description', name: 'description' },
        constraints: { required: true }
    },
    {
        type: 'text',
        properties: { label: 'Related Description', name: 'relatedDescription' },
        constraints: { required: true }
    }
];

class RelationDefinitionAbout extends PureComponent<Object, Object> {
    static propTypes = {
        updateRelationDefinition: PropTypes.func.isRequired
    };

    formRef = React.createRef();

    components = [
        {
            field: 'panel',
            type: 'panel',
            properties: {
                header: 'Relation Definition Details',
                expanded: true
            },
            children: relationDefinitionsComponent
        }
    ];

    @bind
    onFormSubmit(event: Event) {
        event.preventDefault();
        this.formRef.current.isValidForm().then(({ data, errors }) => {
            if (!errors) {
                this.props.updateRelationDefinition(data);
            }
        });
    }

    @bind
    renderSaveButton() {
        return (
            <Button key={113} onClick={this.onFormSubmit} color="primary" form="form" type="submit">
                Save
            </Button>
        );
    }

    @bind
    onAddClasses(classesIds: Array<string>) {
        const id = getStr(this.props, 'details.id', '');
        if (!id || isEmpty(classesIds)) {
            return;
        }
        return this.props.addRelationDefinitionClasses(id, classesIds);
    }

    @bind
    removeClasses(classesIds) {
        const id = getStr(this.props, 'details.id', '');
        if (!id || isEmpty(classesIds)) {
            return;
        }
        return this.props.removeRelationDefinitionClasses(id, classesIds);
    }

    render() {
        return (
            <>
                <HeaderBar left={this.props.breadcrumbLine} right={[this.renderSaveButton(), ...(this.props.sidebarActions || [])]} />
                <ContentArea withHeader>
                    <Container width="1024">
                        <FormGenerator components={this.components} data={this.props.details} ref={this.formRef} />
                        <SelectionList
                            expanded
                            list={get(this.props, 'details.classes', [])}
                            type="class"
                            title="Classes"
                            onAdd={this.onAddClasses}
                            onRemove={this.removeClasses}
                            applicableOn={['relationdefinition']}
                            valueField="id"
                        />
                    </Container>
                </ContentArea>
            </>
        );
    }
}

export default connect(null, {
    updateRelationDefinition,
    removeRelationDefinitionClasses,
    addRelationDefinitionClasses
})(RelationDefinitionAbout);
