/* @flow */

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Avatar, Grid } from '@mic3/platform-ui';
import styled from 'styled-components';

import EntityLink from 'app/components/atoms/Link/EntityLink';

import { getAttachmentUrl } from 'app/utils/attachments/attachmentsUtils';
import { get } from 'app/utils/lo/lo';


export const EntityLinkStyled = styled(EntityLink)`
    && {
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    font-size: 14px !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
    color: ${({ theme }) => theme.material.palette.text.primary} !important;
`;

const AvatarStyled = styled(Avatar)`
    width: 24px !important;
    height: 24px !important;
    font-size: 1rem !important;
    margin-right: 16px;
`;

class GridProcessStarted extends PureComponent<Object, Object> {

    render() {
        const { processInitiator } = this.props;
        const { id, name, image: src } = processInitiator || {};
        const image = src ? getAttachmentUrl(id, 'user', src): null;

        return (
            <Grid container wrap="nowrap" alignItems="center">
                <AvatarStyled src={image} initials={name} />
                <EntityLinkStyled variant="body2" id={id} type={'user'} >
                    {name || 'No Name'}
                </EntityLinkStyled>
            </Grid>
        );
    }
}


export default connect(
    (state, props) => ({
        processInitiator: get(state.admin.users, 'references.data', []).find(
            user => user.id === get(props.data, 'primary.variables.INITIATOR') || user.id === get(props.data, 'process.variables.INITIATOR')
        )
    }),
    null
)(GridProcessStarted);
