/* @flow */

// $FlowFixMe
import React, { PureComponent } from 'react';
import { Avatar, Box, Divider, Tooltip, IconButton, MdiIcon, Typography, markdown } from '@mic3/platform-ui';
import styled from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AvatarGroup from 'app/components/molecules/Avatar/AvatarGroup';
import DotMenu from 'app/components/molecules/DotMenu/DotMenu';
import TypeIcon from 'app/components/atoms/TypeIcon/TypeIcon';
import Icon from 'app/components/atoms/Icon/Icon';

import { openWorkspaceSidebar } from 'store/actions/entities/workspaceSidebarActions';
import { openEventTypeSidebar } from 'store/actions/entities/eventTypeSidebarActions';
import { openEntitySidebar } from 'store/actions/entities/entitySidebarActions';
import { openProcessSidebar } from 'store/actions/abox/processSidebarActions';
import { openClassSidebar } from 'store/actions/entities/classSidebarActions';
import { openLeftPanel } from 'store/actions/leftPanel/leftPanelActions';
import { openTaskSidebar } from 'store/actions/abox/taskSidebarActions';
import { openChatSidebar } from 'store/actions/chat/chatSidebarActions';
import {
    loadRoomMembers,
    loadChannelMembers,
    unsubscribeEntityChat,
    unsubscribeGroupChat,
    unsubscribeChannelChat,
    setRoomAsFavoriteUnfavorite
} from 'store/actions/chat/chatActions';
import history from 'store/History';

import { getAttachmentUrl } from 'app/utils/attachments/attachmentsUtils';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { get } from 'app/utils/lo/lo';

const StyledBoxWrapper = styled(Box)`
    display: flex;
    align-items: center;
    & button {
        padding: 12px;
        margin: 0;
    }
`;
const StyledAvatarGroup = styled(AvatarGroup)`
    margin: 12px !important;
`;
const StyledAvatar = styled(Avatar)`
    width: 24px !important;
    height: 24px !important;
    font-size: 12px !important;
    color: #ffffff !important;
`;
const StyledDivider = styled(Divider)`
    margin: 12px !important;
`;
const MdiIconStyled = styled(MdiIcon)`
    font-size: 20px !important;
    height: 20px !important;
    width: 20px !important;
`;
const FlexWrapper = styled.div`
    display: flex;
    align-items: center;
    border-bottom: ${({ withBorder, theme }) => withBorder ? `1px solid ${theme.material.colors.background.divider};` : 'none'};
`;
const ChatNameWrapper = styled.div`
    flex: 1;
    overflow: hidden;
    margin-inline: 0.5rem;
    padding: 0.5rem 0;
    p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-inline: 0.25rem;
    }
    
    ${({ $isMobile }) => $isMobile && `
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
    `}
`;
const Topic = styled.div`
    p {
        margin: 0;
    }
    font-size: 14px;
`;
const IconButtonStyled = styled(IconButton)`
    padding: 4px !important;
    & i:before {
        font-size: 16px !important;
    }
`;
const TypographyStyled = styled(Typography)`
    color: ${({theme})=> theme.material.colors.text.primary};
`;

const ChatMember = connect(
    null,
    { openChatSidebar }
)(({ key, user, rel, rid, role, avatar, isMobile, isEntityChat, openChatSidebar }: Object) => {
    const imageId = get(user, 'image');
    const imageSource = imageId ? getAttachmentUrl(user?.id, 'user', imageId) : null;

    return (
        <Tooltip title={user.name} alt={user.name}>
            <span alt={user.name}>
                <StyledAvatar
                    onClick={() => {
                        openChatSidebar({
                            rid,
                            rel,
                            title: (isEntityChat && rel.type !== 'team') ? 'Sharing' : 'Members',
                            selectedSidebar: 'subscribers',
                            role
                        });
                    }}
                    key={key}
                    max={!isMobile ? 4 : 1}
                    initials={user.name}
                    src={imageSource}
                />
            </span>
        </Tooltip>
    );
});

class ChatHeader extends PureComponent<Object, Object> {
    static propTypes = {
        rid: PropTypes.string.isRequired,
        readOnly: PropTypes.bool,
        rel: PropTypes.shape({
            id: PropTypes.string,
            type: PropTypes.string,
            name: PropTypes.string
        }),
        topic: PropTypes.string,
        role: PropTypes.string.isRequired,
        favorite: PropTypes.bool,
        members: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
                rel: PropTypes.shape({
                    id: PropTypes.string,
                    name: PropTypes.string
                })
            })
        ),
        isLeftPanelOpen: PropTypes.bool,
        openEventTypeSidebar: PropTypes.func.isRequired,
        openWorkspaceSidebar: PropTypes.func.isRequired,
        openProcessSidebar: PropTypes.func.isRequired,
        loadChannelMembers: PropTypes.func.isRequired,
        openEntitySidebar: PropTypes.func.isRequired,
        openClassSidebar: PropTypes.func.isRequired,
        loadRoomMembers: PropTypes.func.isRequired,
        openTaskSidebar: PropTypes.func.isRequired,
        unsubscribeChannelChat: PropTypes.func.isRequired,
        unsubscribeEntityChat: PropTypes.func.isRequired,
        unsubscribeGroupChat: PropTypes.func.isRequired,
        openLeftPanel: PropTypes.func.isRequired,
        setRoomAsFavoriteUnfavorite: PropTypes.func.isRequired,
    };

    constructor(props: Object) {
        super(props);
        const { rid, rel } = props;
        if (rid && rel) this.initData();
    }

    async initData() {
        const { rid, rel, loadRoomMembers, loadChannelMembers } = this.props;
        const isEntityChat = !['channel', 'direct', 'group', 'live'].includes(rel.type);
        if (isEntityChat || rel.type === 'group') {
            loadRoomMembers(rel, rid);
        } else if (rel.type === 'channel') {
            loadChannelMembers(rel, rid);
        }
    }

    componentDidUpdate(prevProps) {
        const { rid, rel } = this.props;
        if (rel.type !== 'direct') {
            if (prevProps.rid !== rid) {
                this.initData();
            }
        }
    }

    @bind
    openSidebar(title) {
        const {
            rel,
            openEventTypeSidebar,
            openWorkspaceSidebar,
            openProcessSidebar,
            openEntitySidebar,
            openClassSidebar,
            openTaskSidebar
        } = this.props;
        const params = { title, entityType : rel.type, ...rel };
        
        const type = rel.type !== 'user' ? rel.type : 'person';

        switch (type) {
            case 'class':
                return openClassSidebar(params);
            case 'opentask': case 'closedtask':
                return openTaskSidebar(params);
            case 'openprocess': case 'closedprocess':
                return openProcessSidebar(params);
            case 'workspace':
                return openWorkspaceSidebar(params);
            case 'user':
                return openEntitySidebar({ ...params, userAbout: true });
            case 'eventtype':
                return openEventTypeSidebar(params);
            default:
                return openEntitySidebar(params);
        }
    }

    @bind
    onDotMenuClick(title) {
        const { openChatSidebar, rel, rid, role } = this.props;
        switch (title) {
            case 'About':
                this.openSidebar(title);
                break;
            case 'Sharing':
                openChatSidebar({ rid, rel, title, role, selectedSidebar: 'share' });
                break;
            case 'Members':
                openChatSidebar({ rid, rel, title, role, selectedSidebar: 'subscribers' });
                break;
            case 'Attachments':
                openChatSidebar({ rid, rel, title, role });
                break;
            case 'Unsubscribe from chat':
            case 'Leave from chat':
                this.unsubscribe(rid, rel);
                break;
            default:
                break;
        }
    }

    @bind
    unsubscribe(rid, rel) {
        const {
            unsubscribeEntityChat,
            unsubscribeGroupChat,
            unsubscribeChannelChat,
            profile
        } = this.props;
        const isEntityChat = !['channel', 'direct', 'group', 'live'].includes(rel.type);

        if (isEntityChat) {
            unsubscribeEntityChat({
                id: rel.id,
                userId: profile.id,
                type: rel.type,
                rid
            }).then((res) => {
                const isError = get(res, 'error', false);
                if (!isError) {
                    history.push(`/abox/alive`);
                }
            });
        } else if (rel.type === 'group') {
            unsubscribeGroupChat(rid).then((res) => {
                const isError = get(res, 'error', false);
                if (!isError) {
                    history.push(`/abox/alive`);
                }
            });
        } else if (rel.type === 'channel') {
            unsubscribeChannelChat(rid).then((res) => {
                const isError = get(res, 'error', false);
                if (!isError) {
                    history.push(`/abox/alive`);
                }
            });
        }
    }

    @bind
    @memoize()
    renderRightActions(members, rel, rid, role, isMobile, readOnly) {
        const isEntityChat = !['channel', 'direct', 'group', 'live'].includes(rel.type);
        const isDm = rel.type === 'direct';
        let options = [];
        if (!isEntityChat) {
            options = [
                { name: 'Members', icon: 'account-multiple' },
                readOnly ? null : { name: 'Attachments', icon: 'paperclip' },
                { name: 'divider' },
                { name: 'Leave from chat', icon: 'logout-variant' },
            ].filter(Boolean);
        } else {
            options = [
                (rel.type !== 'team') && { name: 'About', icon: 'information-outline' },
                (rel.type !== 'team') && { name: 'Sharing', icon: 'share-variant' },
                readOnly ? null : { name: 'Attachments', icon: 'paperclip' },
                (rel.type !== 'team') && { name: 'divider' },
                (rel.type !== 'team') && { name: 'Unsubscribe from chat', icon: 'close' },
            ].filter(Boolean);
        }

        return (
            <StyledBoxWrapper>
                {!isMobile ? (
                    <>
                        {!isDm && (
                            <StyledAvatarGroup
                                max={4}
                                extraAvatarsOnClick={() => {
                                    this.props.openChatSidebar({
                                        rid,
                                        rel,
                                        title: isEntityChat ? 'Sharing' : 'Members',
                                        selectedSidebar: 'subscribers',
                                        role
                                    });
                                }}
                            >
                                {(members || []).map((member, index) => {
                                    return (
                                        <ChatMember
                                            key={index}
                                            user={member.rel}
                                            rel={rel}
                                            rid={rid}
                                            role={role}
                                            isMobile={isMobile}
                                            isEntityChat={isEntityChat}
                                        />
                                    );
                                })}
                            </StyledAvatarGroup>
                        )}
                        {isEntityChat ? (
                            <>
                                <StyledDivider orientation='vertical' flexItem />

                                {(rel.type !== 'team') && (
                                    <>
                                        <Tooltip title='Sharing' aria-label='sharing'>
                                            <IconButton
                                                onClick={() => {
                                                    this.props.openChatSidebar({
                                                        rid,
                                                        rel,
                                                        title: 'Sharing',
                                                        role,
                                                        selectedSidebar: 'share'
                                                    });
                                                }}
                                            >
                                                <Icon name='share-variant' size='md' />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title='About' aria-label='about'>
                                            <IconButton onClick={() => {this.openSidebar('About');}}>
                                                <Icon name='information-outline' size='md' />
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                )}
                            </>
                        ) : (
                            <>
                                {!isDm && (
                                    <>
                                        <StyledDivider orientation='vertical' flexItem />
                                        <Tooltip title='Members' aria-label='members'>
                                            <IconButton
                                                onClick={() => {
                                                    this.props.openChatSidebar({
                                                        rid,
                                                        rel,
                                                        title: 'Members',
                                                        role,
                                                        selectedSidebar: 'subscribers'
                                                    });
                                                }}
                                            >
                                                <Icon name='account-multiple' size='md' />
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                )}
                            </>
                        )}
                        {!isDm && (
                            <DotMenu
                                key={13}
                                onItemClick={this.onDotMenuClick}
                                items={options.filter(Boolean)}
                            />
                        )}
                        {isDm && (
                            <DotMenu
                                key={13}
                                onItemClick={this.onDotMenuClick}
                                items={[{ name: 'Attachments', icon: 'paperclip' }].filter(Boolean)}
                            />
                        )}
                    </>
                ) : (
                    <>
                        {!isDm && (
                            <DotMenu
                                key={13}
                                onItemClick={this.onDotMenuClick}
                                items={options.filter(Boolean)}
                            />
                        )}
                    </>
                )}
            </StyledBoxWrapper>
        );
    }

    render() {
        const {
            members,
            rel,
            rid,
            role,
            topic,
            isMobile,
            isLeftPanelOpen,
            openLeftPanel,
            favorite,
            setRoomAsFavoriteUnfavorite,
            readOnly
        } = this.props;
        const isEntityChat = !['channel', 'direct', 'group', 'live'].includes(rel.type);
        const updatedTopic = markdown(topic)
            .replace(/(<ul>)(\n<li><input (checked="" )?disabled="" type="checkbox")/g, '<ul class="checkboxes">$2')
            .replace(/<(a href)(=".*")/g, '<a target="_blank" href$2');

        return (
            <FlexWrapper withBorder>
                {isMobile && (
                    <Box mr={2}>
                        <Tooltip title='Back' aria-label='go-back'>
                            <IconButton title="back" onClick={openLeftPanel}>
                                <MdiIconStyled name="arrow-left" />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
                {(!isMobile && !isLeftPanelOpen) && (
                    <Box mr={2}>
                        <Tooltip title='Show sidebar' aria-label='show-sidebar'>
                            <IconButton onClick={openLeftPanel} >
                                <Icon name='chevron-double-right' />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
                {isEntityChat ? <TypeIcon type={'group'} /> : <TypeIcon type={rel.type} />}
                <ChatNameWrapper $isMobile={isMobile}>
                    <FlexWrapper>
                        <Tooltip title={rel.name}>
                            <TypographyStyled>{rel.name}</TypographyStyled>
                        </Tooltip>
                        <Tooltip title={!favorite ? 'Favourite' : 'Unfavourite'}>
                            <IconButtonStyled onClick={()=>{setRoomAsFavoriteUnfavorite(rid, !favorite);}} >
                                <Icon name={!favorite ? 'star-outline' : 'star'} />
                            </IconButtonStyled>
                        </Tooltip>
                    </FlexWrapper>
                    {topic && <Topic dangerouslySetInnerHTML={{__html: updatedTopic }} />}
                </ChatNameWrapper>

                {(rel.type !== 'live') && this.renderRightActions(members, rel, rid, role, isMobile, readOnly)}
            </FlexWrapper>
        );
    }
}

export default connect(
    state => ({
        members: state.chat.room.members,
        isMobile: state.global.isMobile,
        profile: state.user.profile,
        isLeftPanelOpen: state.leftPanel.state.isOpen
    }),
    {
        openEventTypeSidebar,
        openWorkspaceSidebar,
        openProcessSidebar,
        loadChannelMembers,
        openEntitySidebar,
        openClassSidebar,
        openTaskSidebar,
        openChatSidebar,
        loadRoomMembers,
        unsubscribeEntityChat,
        unsubscribeGroupChat,
        unsubscribeChannelChat,
        openLeftPanel,
        setRoomAsFavoriteUnfavorite
    }
)(ChatHeader);
