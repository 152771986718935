/* @flow */
import { gql } from '@apollo/client';
import { entityReferences } from 'graphql/entities/relationships/relationsQuery';

export default gql`
query loadBoardRecordsQuery($type: String!, $filterBy: [JSON], $excludeBy: [JSON], $orderBy: [JSON]) {
    records: entities(type: $type, filterBy: $filterBy, excludeBy: $excludeBy, orderBy: $orderBy) {
      id
      name
      type
      primary
      relations{
        entity{
          ${entityReferences}
        }
        relatedEntity{
          ${entityReferences}
        }
        relation{
          id
        }
        isReverseRelation
      }
    }
}
`;
