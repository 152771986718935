/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, CircularProgress, ListItem, Avatar, ListItemText } from '@mic3/platform-ui';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { withTheme } from 'styled-components';

import UserEntityLink from 'app/components/atoms/Link/UserEntityLink';
import Container from 'app/components/atoms/Container/Container';
import Loader from 'app/components/atoms/Loader/Loader';
import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import ProcessDiagramCard from 'app/components/ABox/Cards/ProcessDiagramCard';
import { loadProcessSidebarDetails, updateProcess } from 'store/actions/abox/processActions';
import HeaderBar from 'app/components/molecules/HeaderBar/HeaderBar';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';

import { getNum, isEmpty } from 'app/utils/utils';
import { PRIORITY_OPTIONS, getPriorityColor } from 'app/config/aboxConfig';
import { get } from 'app/utils/lo/lo';
import { getAttachmentUrl } from 'app/utils/attachments/attachmentsUtils';
import { loadUserReferences } from 'store/actions/admin/usersActions';

class ProcessAboutTab extends PureComponent<Object, Object> {
    static propTypes = {
        details: PropTypes.object.isRequired,
        reloadList: PropTypes.func
    };

    formRef: Object = React.createRef();

    componentDidUpdate(prevProps){
        const { userReferencesLoading, processInitiator } = this.props;
        if (!userReferencesLoading && !processInitiator) {
            this.props.loadUserReferences();
        }
    }

    @bind
    @memoize()
    formDefinition(details) {
        const { canEdit, theme } = this.props;
        const { primary, processDefinitionVersion } = details;
        const { closureReason, closedDate, priority } = primary;
        const { createdBy } = processDefinitionVersion;
        const disabled = !canEdit || !!closedDate;
        const priorityColor = !!closedDate ? 'disabled' : getPriorityColor(priority);
        const imageId = get(createdBy, 'image');
        const avatarImg = imageId ? getAttachmentUrl(get(createdBy, 'id'), 'user', imageId) : null;
        return [
            {
                type: 'panel',
                properties: {
                    header: 'About',
                    expanded: true,
                    style: { borderTop: `2px solid ${theme.priorityGradients[priorityColor][0]}` }
                },
                children: [
                    closureReason && {
                        type: 'text',
                        properties: {
                            label: 'Reason for cancelling process',
                            name: 'primary.closureReason',
                            disabled: true
                        }
                    },
                    {
                        type: 'text',
                        properties: {
                            label: 'Description',
                            name: 'processDefinitionVersion.description',
                            disabled: true
                        }
                    },
                    {
                        type: 'custom',
                        properties: {
                            label: 'Application Owner',
                            name: 'processDefinitionVersion.createdBy.name',
                            disabled: true,
                            Component: () => (
                                <ListItem ContainerComponent="div" dense disableGutters>
                                    <Avatar src={avatarImg} initials={get(createdBy, 'name', '')} style={{ marginRight: 8 }} />
                                    <ListItemText
                                        primary={<UserEntityLink id={get(createdBy, 'id')}>{get(createdBy, 'name') || 'No Name'}</UserEntityLink>}
                                        secondary={<em>#{get(createdBy, 'id')}</em>}
                                    />
                                </ListItem>
                            )
                        }
                    },
                    {
                        type: 'text',
                        properties: {
                            label: 'Started By',
                            name: 'initiatorName',
                            disabled: true
                        }
                    },
                    {
                        type: 'text',
                        properties: {
                            label: 'Application Name',
                            name: 'name',
                            disabled: true
                        }
                    },
                    {
                        type: 'text',
                        properties: {
                            label: 'Definition Name',
                            name: 'processDefinitionVersion.name',
                            disabled: true
                        }
                    },
                    {
                        type: 'userTypeahead',
                        properties: { name: 'primary.assignee', label: 'Assignee', valueField: 'id', disabled }
                    },
                    {
                        type: 'typeahead',
                        properties: {
                            label: 'Priority',
                            name: 'primary.priority',
                            valueField: 'value',
                            options: PRIORITY_OPTIONS,
                            clearable: false,
                            defaultValue: priority === 50 || !priority ? 3 : Number(priority),
                            disabled,
                        }
                    }
                ].filter(Boolean)
            },
            {
                type: 'panel',
                properties: { header: 'Properties', expanded: true },
                children: [
                    {
                        type: 'dateTime',
                        properties: {
                            label: 'Created Date',
                            name: 'createdDate',
                            disabled: true
                        }
                    },
                    {
                        type: 'dateTime',
                        properties: {
                            label: 'Last Updated',
                            name: 'modifiedDate',
                            disabled: true
                        }
                    },
                    {
                        type: 'text',
                        properties: {
                            label: 'Status',
                            name: 'status13',
                            defaultValue: closedDate ? 'Closed' : 'Open',
                            disabled: true
                        }
                    }
                ]
            },
            {
                type: 'panel',
                properties: { header: 'Process Diagram', expanded: true },
                children: [
                    {
                        type: 'custom',
                        properties: {
                            name: 'processDefinitionVersion.primary.definition',
                            Component: props => <ProcessDiagramCard {...(props || {})} />
                        }
                    }
                ]
            }
        ];
    }

    @bind
    loadDetails() {
        const {
            details: { id },
            loadProcessSidebarDetails
        } = this.props;
        return loadProcessSidebarDetails(id);
    }

    @bind
    async onFormSubmit(event) {
        event.preventDefault();
        const { reloadList, updateProcess, internal } = this.props;
        const { errors, data } = await this.formRef.current.isValidForm();
        if (!errors) {
            const { id, primary } = data;
            const { assignee, priority } = primary || {};
            await updateProcess({
                id,
                primary: { assignee, priority }
            }, internal);
            reloadList && (await reloadList());
        }
    }

    @bind
    @memoize()
    normalizeForSave(process, processInitiator) {
        return {
            ...process,
            primary: {
                ...get(process, 'primary', {}),
                priority: getNum(process, 'primary.priority', 3)
            },
            initiatorName: get(processInitiator, 'name', '')
        };
    }

    @bind
    @memoize()
    renderRightActions(sidebarActions, isLoading, type) {
        const { canEdit } = this.props;
        if (type === 'closedprocess' || !canEdit) {
            return sidebarActions;
        }
        return (
            <>
                {isLoading ? (
                    <CircularProgress key={113} size={24} color="primary" />
                ) : (
                    <Button key={113} onClick={this.onFormSubmit} color="primary" form="form" type="submit">
                        Save
                    </Button>
                )}
                {sidebarActions}
            </>
        );
    }

    /**
     * @override
     */
    render(): Object {
        const { details, processInitiator, isLoading, sidebarActions, breadcrumbLine, isSidebar } = this.props;
        const process = this.normalizeForSave(details, processInitiator);
        if (isEmpty(details)) {
            return <Loader absolute />;
        }
        return (
            <>
                <HeaderBar right={this.renderRightActions(sidebarActions, isLoading, get(process, 'type'))} left={breadcrumbLine} />
                <ContentArea withHeader isSidebar={isSidebar}>
                    <Container>
                        <FormGenerator ref={this.formRef} components={this.formDefinition(process)} data={process} />
                    </Container>
                </ContentArea>
            </>
        );
    }
}

export default connect(
    (state, props) => ({
        processInitiator: (state.admin.users.references.data || []).find(usr => usr.id === get(props.details, 'primary.variables.INITIATOR')),
        userReferencesLoading: state.admin.users.references.isLoading
    }),
    {
        loadProcessSidebarDetails,
        updateProcess,
        loadUserReferences
    }
)(withTheme(ProcessAboutTab));
