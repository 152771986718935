/* @flow */

import { gql } from '@apollo/client';

const broadcastFields = `
id
active
message
startDate
metadata
recipientTeams { id name }
recipientUsers { id name image }
`;

export default gql`
query broadcastQuery($id: ID!) {
    result: broadcast(id: $id) {
      ${broadcastFields}
    }
}
`;
