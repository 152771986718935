/* @flow */
import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { withRouter } from 'react-router';
import { Select, MenuItem } from '@mic3/platform-ui';

import { loadTimelineTasks, updateTask } from 'store/actions/abox/taskActions';
import { setDocumentTitle, showToastr } from 'store/actions/app/appActions';
import { saveTimelinePreferences } from 'store/actions/admin/usersActions';
import { openTaskSidebar, closeTaskSidebar } from 'store/actions/abox/taskSidebarActions';
import { isEmpty } from 'app/utils/utils';
import TimelineToolbar from 'app/components/ABox/Timeline/TimelineToolbar';
import Loader from 'app/components/atoms/Loader/Loader';
import PageTemplate from 'app/components/templates/PageTemplate';
import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import { FiltersTimeline, StyledButton } from 'app/components/ABox/Timeline/style';
import TimelineView from 'app/components/organisms/TimelineView/TimelineView';
import { formatOptionsForTask } from 'app/utils/formatter/graphql-formatter';
import { get } from 'app/utils/lo/lo';
import { IconButton, Tooltip } from '@mic3/platform-ui';
import TimelineAddTaskModal from 'app/containers/Abox/Timeline/TimelineAddTaskModal';
import Icon from 'app/components/atoms/Icon/Icon';
import { getDateRanges } from 'app/utils/date/date';
import { filterDefinitions, defaultFilters, defaultOrder} from 'app/components/organisms/TasksView/TasksView';
import PageNotAllowed from 'app/containers/ErrorPages/PageNotAllowed';
import { modulesAndPageTitles } from 'app/config/typesConfig';
import { createRelationship, deleteRelationship } from 'store/actions/entities/relationshipsActions';
import { successorTaskRelDefId, predecessorTaskRelDefId } from 'app/config/config';

const SelectStyled = styled(Select)`
   margin-right: 16px;
   & .MuiSelect-select.MuiSelect-select {
    min-width: 140px !important;
    padding-left: 5px;
    background: none;
   }
`;

class Timeline extends PureComponent<Object, Object> {
    static propTypes = {
        preferences: PropTypes.object,
        isLoading: PropTypes.bool,
        records: PropTypes.array,
        totalRecords: PropTypes.number,
        userProfile: PropTypes.object,
        loadTimelineTasks: PropTypes.func.isRequired,
        updateTask: PropTypes.func.isRequired,
    };

    static defaultProps = {
        isLoading: false,
        records: [],
        totalRecords: 0
    };

    constructor(props){
        super(props);
        this.state = {
            isLoadingUpdateTask: false,
            selectedTaskId: null,
            disableCountdown: false,
            countdownSeconds: 180,
            isAddTaskModalOpen: false,
            isToolbarHidden: true,
            startDate:null,
            dueDate: null,
            range: 'weeks',
            groupBy: props?.preferences?.timeline?.groupBy || 'assignee',
        };
    }

    breadcrumb = [{ title: 'Timeline' }];
    searchBar = ['name', 'id', 'process.name'];
    defaultFilters = defaultFilters;
    defaultOrder = defaultOrder;
    filterDefinitions = filterDefinitions;
    anchorEl: Object = React.createRef();
    timelineRef: Object = React.createRef();
    timelineFiltersRef: Object = React.createRef();


    componentDidMount(){
        this.onFetchTasksByRange();
        this.props.setDocumentTitle(modulesAndPageTitles.abox.timeline);
    }

    @bind
    @memoize()
    findTask(tasks: Object, taskId: number) {
        return tasks.find(task => task.id === taskId) || {};
    }

    @bind
    async onChangeGroupBy(e) {
        const groupBy = e.target.value; 
        await this.onSaveRangeToPreference('groupBy', groupBy);
        this.setState({ groupBy }, this.rerfreshList);
    }

    @bind
    async onChangeRange(e) {
        const range = e === 'days'? e : e.target.value;
        await this.onSaveRangeToPreference('range', range);
        this.onFetchTasksByRange(range);
    }

    @bind
    onFetchTasksByRange(range) {
        if(!range) range = get(this.props.preferences, 'timeline.range', 'weeks');
        let [startDate, dueDate] = getDateRanges(range);
        startDate = startDate.toISOString();
        dueDate = dueDate.toISOString();
        this.setState({
            startDate: startDate,
            dueDate: dueDate,
            range : range
        }, this.rerfreshList);
    }

    @bind
    rerfreshList() {
         this.timelineFiltersRef?.current?.forceUpdate(); // eslint-disable-line
    }

    @bind
    onTimelineToolbarAction({ source }) {
        const { dataset: { action } } = source;
        this.timelineRef.current.instance[action]();
    }

    @bind
    viewPreviousDates() {
        const {startDate, dueDate} = this.state;

        let previousStartDate = new Date(startDate);
        let previousDueDate = new Date(dueDate);

        switch(this.state.range){
            case 'years':
                previousStartDate =  new Date(previousStartDate.setFullYear(previousStartDate.getFullYear()-1));
                previousDueDate = new Date(previousDueDate.setFullYear(previousDueDate.getFullYear()-1));
                break;

            case 'months':
                previousStartDate =  new Date(previousStartDate.setMonth(previousStartDate.getMonth()-1));
                previousDueDate = new Date(previousDueDate.setMonth(previousDueDate.getMonth()-1));
                break;

            case 'weeks':
                previousStartDate =  new Date(previousStartDate.setDate(previousStartDate.getDate()-7));
                previousDueDate = new Date(previousDueDate.setDate(previousDueDate.getDate()-7));
                break;

            case 'days':
                previousStartDate =  new Date(previousStartDate.setDate(previousStartDate.getDate()-1));
                previousDueDate = new Date(previousDueDate.setDate(previousDueDate.getDate()-1));
                break;
            default:
                return;
        }

        this.setState({
            startDate: previousStartDate.toISOString(),
            dueDate: previousDueDate.toISOString(),
        }, this.rerfreshList);
    }


    @bind
    viewNextDates() {
        const {startDate, dueDate} = this.state;

        let nextStartDate = new Date(startDate);
        let nextDueDate = new Date(dueDate);

        switch(this.state.range){
            case 'years':
                nextStartDate =  new Date(nextStartDate.setFullYear(nextStartDate.getFullYear()+1));
                nextDueDate = new Date(nextDueDate.setFullYear(nextDueDate.getFullYear()+1));
                break;

            case 'months':
                nextStartDate =  new Date(nextStartDate.setMonth(nextStartDate.getMonth()+1));
                nextDueDate = new Date(nextDueDate.setMonth(nextDueDate.getMonth()+1));
                break;

            case 'weeks':
                nextStartDate =  new Date(nextStartDate.setDate(nextStartDate.getDate()+7));
                nextDueDate = new Date(nextDueDate.setDate(nextDueDate.getDate()+7));
                break;

            case 'days':
                nextStartDate =  new Date(nextStartDate.setDate(nextStartDate.getDate()+1));
                nextDueDate = new Date(nextDueDate.setDate(nextDueDate.getDate()+1));
                break;
            default:
                return;
        }

        this.setState({
            startDate: nextStartDate.toISOString(),
            dueDate: nextDueDate.toISOString(),
        });
    }

    @bind
    onResetZoom() {
        this.timelineRef.current.resetZoom();
    }

    @bind
    @memoize()
    filterRecord(task) {
        const {
            primary: { startDate, dueDate, progress, priority },
            description,
            id,
            name
        } = task;
        return { primary: { startDate, dueDate, progress, priority }, description, id, name };
    }

    @bind
    @memoize()
    loadTasks(options, start: Date, due: Date) {
        const formattedOptions = formatOptionsForTask(options, this.props.userProfile);
        let useFilterOverRange = false;
        (options.filterBy || []).forEach((filter) => {
            const { field, or } = filter;
            if (or) return;
            if (field.startsWith('primary')) {
                const name = field.split('.')[1];
                // if start date is filtered prioritize this filter over range
                if (name === 'startDate') {
                    useFilterOverRange = true;
                }
            } else {
                // if due date is filtered prioritize this filter over range
                if (field === 'dueDate') {
                    useFilterOverRange = true;
                }
            }
        });
        // set default range filter to null and use date filters instead
        if (useFilterOverRange) {
            return this.props.loadTimelineTasks(formattedOptions);
        } else {
            return this.props.loadTimelineTasks(formattedOptions, start, due);
        }
    }

    @bind
    toggleAddTaskModal() {
        this.setState(prevState => ({ isAddTaskModalOpen: !prevState.isAddTaskModalOpen }));
    }

    @bind
    toggleToolbarVisibility() {
        this.setState(prevState => ({ isToolbarHidden: !prevState.isToolbarHidden }));
    }

    @bind
    addTasksToTimeline(tasks: Array<Object>) {
        if (isEmpty(tasks)) return;
        const { startDate, dueDate  } = this.state;
        if (!startDate || !dueDate) return;
        Promise.all(
            tasks.map(({ id }) => {
                return this.props.updateTask({ id, primary: { startDate, dueDate } });
            })
        ).then(this.rerfreshList());
    }

    @bind
    async addTasksDependency({ source, target, fromSide, toSide, ...rest } = {}) {
        if (isEmpty(source) || isEmpty(target)) return;
        if(fromSide === toSide) return false;

        const { createRelationship, updateTask } = this.props; 

        const { _data: { id, type }, meta: { modified } } = target;
        const { _data: { id: sourceId, type: sourceType } } = source;

        await createRelationship({
            entity: { type: sourceType, id: sourceId },
            relationDefinition: fromSide === 'end' ? successorTaskRelDefId : predecessorTaskRelDefId,
            relatedEntity: { type, id },
        });
        return updateTask({ 
            id, primary: { 
                startDate: modified.startDate, 
                dueDate: modified.endDate,
            },
        });
    }

    @bind
    onSaveRangeToPreference(name, value){
        const { preferences, saveTimelinePreferences } = this.props;
        const userPreferences = {
            ...preferences,
            timeline: {
                ...(preferences?.timeline || {}),
                [name]: value
            }
        };
        return saveTimelinePreferences(userPreferences);
    }

    @bind
    openTaskDrawer(id, title) {
        if (!id) return;
        if(title === 'Task') this.props.history.push(`/abox/task/${id}`);
        else this.props.openTaskSidebar({ id: id, title: title, reloadList: () => this.rerfreshList()});
    }

    @bind
    navigateToGantt(){
        this.props.history.push(`/abox/legacytimeline`);
    }

    render() {
        const { permissions, isAdmin } = this.props.userProfile;
        const permissionsSet = new Set(permissions || []);
        const canView = isAdmin || permissionsSet.has('abox.timeline');
        if (!canView) {
            return <PageNotAllowed title="Timeline" />;
        }
        const { records, isLoading, totalRecords, sidebar } = this.props;
        const { isLoadingUpdateTask, isAddTaskModalOpen, isToolbarHidden, startDate, dueDate, range, groupBy } = this.state;

        return (
            <Fragment>
                <TimelineToolbar
                    forwardRef={this.timelineRef}
                    onChangeRange={this.onChangeRange}
                    onChangeGroupBy={this.onChangeGroupBy}
                    onPrevious= {this.viewPreviousDates}
                    onNext = {this.viewNextDates}
                    onAction={this.onTimelineToolbarAction}
                    onResetZoom={this.onResetZoom}
                    onSetRange={this.onChangeRange}
                    checked={!isToolbarHidden}
                    range={range}
                    groupBy={groupBy}
                    totalRecords={totalRecords}
                />
                <FiltersTimeline
                    id="TimeLineFilters"
                    ref={this.timelineFiltersRef}
                    contentOffset={0}
                    filterDefinitions={this.filterDefinitions}
                    searchBar={this.searchBar}
                    defaultFilters={this.defaultFilters}
                    defaultOrder={this.defaultOrder}
                    breadcrumb={this.breadcrumb}
                    hideDivider
                    rightToolbar={
                        <Fragment>
                            <StyledButton variant='text' color='secondary' onClick={this.navigateToGantt} children={'Legacy Version'}/>
                            <Tooltip title="Add Tasks to Timeline" arrow>
                                <IconButton onClick={this.toggleAddTaskModal}>
                                    <Icon name="plus-circle" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={isToolbarHidden ? 'Show Toolbar' : 'Hide Toolbar'} arrow placement="top">
                                <IconButton onClick={this.toggleToolbarVisibility}>
                                    <Icon name={isToolbarHidden ? 'arrow-down-drop-circle' : 'arrow-up-drop-circle'} />
                                </IconButton>
                            </Tooltip>
                        </Fragment>
                    }
                    leftSideSearcLine={(
                        <SelectStyled 
                            disableUnderline
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left'
                                },
                                transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'left'
                                },
                                getContentAnchorEl: null
                            }}
                            value={groupBy}
                            onChange={this.onChangeGroupBy}
                        >
                            <MenuItem value={'assignee'}>Assignee</MenuItem>
                            <MenuItem value={'process'}>Process</MenuItem>
                        </SelectStyled>
                    )}
                >
                    {(filterBy, orderBy, excludeBy) => {
                        return (
                            <PageTemplate title="Timeline">
                                <ContentArea>
                                    {(isLoading || isLoadingUpdateTask) && <Loader absolute />}
                                    <TimelineView
                                        loadTimelineTasks={this.loadTasks}
                                        records={records}
                                        filterBy={filterBy}
                                        orderBy={orderBy}
                                        excludeBy={excludeBy}
                                        openSidebar={this.openTaskDrawer}
                                        sidebarTitle = {sidebar.title}
                                        startDate={startDate}
                                        dueDate={dueDate}
                                        range={range}
                                        groupBy={groupBy}
                                        forwardRef={this.timelineRef}
                                        addTasksDependency={this.addTasksDependency}
                                    />
                                </ContentArea>
                            </PageTemplate>
                        );
                    }}
                </FiltersTimeline>
                {isAddTaskModalOpen && (
                    <TimelineAddTaskModal
                        startDate={new Date(startDate)}
                        dueDate={new Date(dueDate)}
                        isOpen={isAddTaskModalOpen}
                        onSubmit={this.addTasksToTimeline}
                        closeModal={this.toggleAddTaskModal}
                    />
                )}
            </Fragment>
        );
    }
}

export default connect(
    state => ({
        isLoading: state.abox.task.timeline.isLoading,
        records: state.abox.task.timeline.records,
        totalRecords: state.abox.task.timeline.count,
        userProfile: state.user.profile,
        isNavOpen: state.app.isNavOpen,
        preferences: state.user.preferences,
        sidebar: state.sidebar
    }),
    {
        loadTimelineTasks,
        updateTask,
        showToastr,
        saveTimelinePreferences,
        openTaskSidebar,
        closeTaskSidebar,
        setDocumentTitle,
        createRelationship,
        deleteRelationship
    }
)(withRouter(Timeline));
