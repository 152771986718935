/* @flow */

import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Link from 'app/components/atoms/Link/Link';

const ClassLink = ((props: Object) => {
    const { id, children, entityType, primary, ...restProps } = props;

    return <Link to={(entityType || primary) ? `/entity-types/${id}` : `/classifications/${id}`} {...restProps}>{children}</Link>;
});

ClassLink.propTypes = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default memo<Object>(ClassLink);
