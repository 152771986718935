/* @flow */

import Immutable from 'app/utils/immutable/Immutable';
import { SET_SELECTED_ELEMENT } from 'store/actions/form/formActions';

import {} from 'store/actions/app/appActions';

const initialState = Immutable({
    selectedElement: null,
});
/**
 * Reducer to handle form actions
 * @param state
 * @param action
 * @returns {*}
 */
export default (state: Object = initialState, action: Object) => {
    const { type, payload } = action;
    switch (type) {
        case SET_SELECTED_ELEMENT:
            return { ...state, selectedElement: payload };
        default:
            return state;
    }
};
