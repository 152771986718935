import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { loadOrganisationAutocomplete } from 'store/actions/entities/organisationsActions';

import AbstractEntityTypeahead from 'app/components/organisms/Form/Typeahead/abstract/AbstractEntityTypeahead';
import ResolveAvatarTypeaheadWrapper from 'app/components/organisms/Form/Typeahead/abstract/ResolveAvatarTypeaheadWrapper';

/**
 * Select one or more groups using lazy loading.
 */
class OrganisationTypeahead extends PureComponent<Object, Object> {

    static propTypes = {
        loadOrganisationAutocomplete: PropTypes.func.isRequired,
        filterBy: PropTypes.arrayOf(PropTypes.object),
    }

    render() {
        // remove the properties that we do not have to pass to the AbstractEntityAutocomplete
        const { loadOrganisationAutocomplete, ...abstractEntityAutocompleteProps } = this.props;
        return (
            <AbstractEntityTypeahead
                placeholder="Search for an organisation..."
                {...abstractEntityAutocompleteProps}
                loadOptions={loadOrganisationAutocomplete}
            />
        );
    }
};

const OrganisationTypeaheadWrapped = (props) => {
    return <ResolveAvatarTypeaheadWrapper {...props} Typeahead={OrganisationTypeahead} entityType="organisation" />;
};

export default connect(
    null,
    { loadOrganisationAutocomplete }
)(OrganisationTypeaheadWrapped);
