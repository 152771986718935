/* @flow */

import React, { useCallback, useState } from 'react';
import { Switch as PuiSwitch, ConfirmationModal } from '@mic3/platform-ui';
import { useToggle } from 'app/utils/hook/hooks';

import FormField from 'app/containers/Designer/Form/components/FormField';

const fieldify = (Component: Object) => (props: Object) => <FormField {...props} Component={Component} />;

const ConfirmationModalFildifyed = fieldify(ConfirmationModal);
// const SwitchComponent = fieldify(PuiSwitch);

const Switch = ({
    withConfirmation, confirmationModalProps, onChange,
    ...switchProps
}: Object) => {
    const [openConfirmationModal, setConfirmationModal] = useToggle();
    const [draftEvent, setDraftEvent] = useState({});

    const toggleConfirmationModal = useCallback((e) => {
        setConfirmationModal();
        if(!openConfirmationModal) {
            setDraftEvent({ target: { ...e.target }});
        }
    }, [openConfirmationModal, setConfirmationModal, setDraftEvent]);

    const onChangeWithConfirm = withConfirmation ? toggleConfirmationModal : onChange;
    return (
        <>
            <PuiSwitch {...switchProps} onChange={onChangeWithConfirm} />
            {withConfirmation && confirmationModalProps && (
                <ConfirmationModalFildifyed
                    {...confirmationModalProps}
                    onClose={toggleConfirmationModal}
                    open={openConfirmationModal}
                    onConfirm={onChange}
                    draftEvent={draftEvent}
                />
            )}
        </>
    );
};

export default Switch;
