/* @flow */
import { isMobile } from 'react-device-detect';

const buildHomeMenuList = (isAdmin, permissions, url) => {
    const permissionsSet = new Set(permissions);
    return [
        url && {
            icon: 'star',
            name: 'My Favorite',
            to: url
        },
        {
            icon: 'home',
            name: 'Home',
            to: '/abox',
            tourName: !isMobile? 'reactour__home' : ''
        },
        (isAdmin || permissionsSet.has('alive.alive')) && {
            iconType: 'af',
            icon: 'messenger',
            name: 'A-Live',
            to: '/abox/alive',
            tourName:'reactour__alive'
        }
    ].filter(Boolean);
};

export default buildHomeMenuList;
