/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Button, TextField, Tooltip, Avatar, Grid, Divider } from '@mic3/platform-ui';
import produce from 'immer';

import { isEmpty, getStr } from 'app/utils/utils';
import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import ListItem from 'app/components/molecules/List/ListItem';
import Alert from 'app/components/molecules/Alert/Alert';
import Immutable from 'app/utils/immutable/Immutable';
import AddResourcesModal from 'app/containers/Common/AddResourcesModal';
import AddEntityByPrimaryClassModal from 'app/containers/Common/AddEntityByPrimaryClassModal';
import { addWorkspaceResources, removeWorkspaceResources, clearResourcesList, addWorkspaceOwners, removeWorkspaceOwners } from 'store/actions/admin/workspacesActions';

export const getResourcesListAsPerType = (list, resourceType) => {
    const entityList = (list || []).map(({ entity }) => entity).filter(Boolean);
    switch (resourceType) {
        case 'class' : case 'user':
            return list;
        case 'primaryClass':
            return entityList.filter(({type}) => type !== 'permission' && type !== 'system_setting');
        case 'permission':
            return entityList.filter(({type}) => type === 'permission' || type === 'system_setting');
        case 'person':
            return (entityList || []).filter(({ type }) => ['user', 'person'].includes(type));
        case 'task':
            return entityList.filter(({ type }) => taskTypes.includes(type));
        case 'process':
            return entityList.filter(({ type }) => processTypes.includes(type));    
        default:
            return entityList.filter(({ type }) => type === resourceType);
    }
};

const ListItemStyled = styled(ListItem)`
    width: 95%;
    max-width: 1024px;
    margin: 0 auto;
    ${({ selected }) => (selected ? 'background: rgba(30, 168, 207, 0.2)' : '')};
    ${({ disabled }) => (!disabled ? 'cursor: pointer;' : '')}
    margin-top: 10px;
`;
const SearchField = styled(TextField)`
    .MuiFilledInput-input {
        padding: 10px 12px 10px 0 !important;
        background: ${({ theme }) => theme.base.background};
    }
`;


const StyledAvatar = styled(Avatar)`
    && {
        ${({ selected }) => (selected ? 'background-color: gray !important' : '')};
    }
`;
const StyledGrid = styled(Grid)`
    margin: 0 8px;
    position: sticky !important;
    top: 0;
    background: ${({theme})=> theme.material.colors.background.default} !important;
    z-index:999;
`;

const StyledDivider = styled(Divider)`
    margin-bottom: 15px !important;
`;


const inputProps: Object = { disableUnderline: true };

const taskTypes = ['opentask', 'closedtask'];
const processTypes = ['openprocess', 'closedprocess'];

/**
 * Container that is used to display the Entities tab of the Groups & Permissions details view.
 */
class WorkspaceResourcesList extends PureComponent<Object, Object> {
    static propTypes = {
        disabled: PropTypes.bool,
    };

    static defaultProps = {
        disabled: false
    };

    state = Immutable({
        serachValue: '',
        selectedListItems: [],
        isModalOpen: false,
    });

    defaultState = { ...this.state };

    @bind
    setDefaultState() {
        this.setState(this.defaultState);
    }

    @bind
    onChangeSearch(event: Object) {
        this.setState({ searchValue: event.target.value });
    }

    @bind
    @memoize()
    getListAsPerType(list, resourceType) {
        return getResourcesListAsPerType(list, resourceType);
    }

    @bind
    searchForFrom(searchQuery, data) {
        if (!data || !searchQuery) return false;
        return ['name', 'id', 'username', 'primary.username'].some((field) => {
            let value = getStr(data, field, '');
            if (!value) return false;
            value = value.toLowerCase();
            const query = searchQuery.toLowerCase();
            return value.includes(query);
        });
    }

    @bind
    @memoize()
    filterSearchedData(searchQuery: string, data: Array<Object>) {
        return searchQuery ? (data || []).filter(item => this.searchForFrom(searchQuery, item)) : data || [];
    }

    @bind
    removeWorkspaceResources() {
        const { id, type, removeWorkspaceOwners } = this.props;
        const { selectedListItems } = this.state;
        if (!id || isEmpty(selectedListItems)) {
            return;
        }
        if(type === 'user'){
            const ownerIds = selectedListItems.map(item => item.id);
            return removeWorkspaceOwners(id, ownerIds).then(this.setDefaultState);
        }
        this.props.removeWorkspaceResources(id, selectedListItems, type).then(this.setDefaultState);
    }

    @bind
    onSubmitResources(submittedList: Array<Object>) {
        const {id, type, addWorkspaceResources, addWorkspaceOwners} = this.props;
        if (!id || !submittedList || isEmpty(submittedList)) {
            return;
        }
        if(type === 'user'){
            const ownerIds = submittedList.map(item => item.id);
            return addWorkspaceOwners(id, ownerIds).then(this.setDefaultState);
        }
        addWorkspaceResources(id, submittedList, type).then(this.setDefaultState);
    }

    @bind
    buildList(list: Array<Object>) {
        const { disabled } = this.props;
        return (list || []).map((data) => {
            const { id, name } = data;
            const selected = !isEmpty(this.getSelectedListItem(id));
            return (
                <ListItemStyled
                    key={id}
                    component={<StyledAvatar selected={selected} initials={name} />}
                    title={name}
                    subTitle={`#${id}`}
                    onClick={e => !disabled && this.onSelectListItem(e, data)}
                    selected={selected}
                    raised
                    disabled={disabled}
                />
            );
        });
    }

    @bind
    onSelectListItem(event: Object, data: Object) {
        event.preventDefault();
        const selectedItem = this.getSelectedListItem(data.id);
        if (isEmpty(selectedItem)) {
            this.setState(
                produce((draft) => {
                    draft.selectedListItems.push(data);
                })
            );
        } else {
            this.setState(prevState => ({ selectedListItems: prevState.selectedListItems.filter(({ id }) => id !== data.id) }));
        }
    }

    @bind
    getSelectedListItem(itemId: string) {
        if (!itemId) {
            return {};
        }
        const { selectedListItems } = this.state;
        return selectedListItems.find(({ id }) => id === itemId) || {};
    }

    @bind
    openModal() {
        this.setState({ isModalOpen: true });
    }

    @bind
    closeModal() {
        this.props.clearResourcesList();
        this.setDefaultState();
    }

    /**
     * @override
     */
    render() {
        const { title, list, type, disabled } = this.props;
        const resourceList = this.getListAsPerType(list, type);
        const filteredList = this.filterSearchedData(this.state.searchValue, resourceList);
        return (
            <ContentArea>

                <StyledGrid>
                    <SearchField
                        onChange={this.onChangeSearch}
                        value={this.state.searchValue}
                        fullWidth
                        margin="none"
                        placeholder="Search..."
                        InputProps={inputProps}
                    />

                    <StyledDivider />

                    {
                        !disabled && isEmpty(this.state.selectedListItems) ? (
                            <Tooltip arrow title={`Click to add ${(title || '').toLowerCase()} to this workspace`}>
                                <div>
                                    <Button  fullWidth onClick={this.openModal}>
                                        + Add new
                                    </Button>
                                </div>
                            </Tooltip>
                        ) : (
                            !disabled && <Tooltip arrow title={`Click to remove ${(title || '').toLowerCase()} to this workspace`}>
                                <div>
                                    <Button  fullWidth onClick={this.removeWorkspaceResources}>
                                        REMOVE
                                    </Button>
                                </div>
                            </Tooltip>
                        )
                    }
                </StyledGrid>
                
                {!isEmpty(filteredList) ? (
                    this.buildList(filteredList)
                ) : (
                    <Alert type="background">
                        No workspace {(title || '').toLowerCase()} found
                    </Alert>
                )}
                {type === 'primaryClass' ?
                    <AddEntityByPrimaryClassModal
                        key="primaryClass"
                        isOpen={this.state.isModalOpen}
                        isBulkSelect
                        showActiveClasses={true}
                        addedResourcesList={resourceList}
                        onSubmit={this.onSubmitResources}
                        closeModal={this.closeModal}
                        resetOnMount
                    /> :
                    <AddResourcesModal
                        key={type}
                        type={type}
                        isBulkSelect={true}
                        isOpen={this.state.isModalOpen}
                        addedResourcesList={resourceList}
                        onSubmit={this.onSubmitResources}
                        closeModal={this.closeModal}
                    />}
            </ContentArea>
        );
    }
}

export default connect(null, {
    addWorkspaceResources,
    removeWorkspaceResources,
    clearResourcesList,
    addWorkspaceOwners,
    removeWorkspaceOwners,
})(WorkspaceResourcesList);
