import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Avatar, Divider, Popover, Typography, Grid } from '@mic3/platform-ui';
import ButtonIcon from 'app/components/molecules/ButtonIcon/ButtonIcon';
import Icon from 'app/components/atoms/Icon/Icon';
import styled from 'styled-components';
import { muiTheme } from 'app/themes/materialUi';

import { LinkIcon } from 'app/containers/Entities/EntitiesList/EntitiesListItem';
import { copyToClipboard } from 'app/utils/classification/classificationUtils';
import { toUniqueUUID } from 'app/utils/string/string-utils';
import { showToastr } from 'store/actions/app/appActions';

const FlexWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const EntityLinkStyledSubTitle = styled(Typography)`
    && {
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    display: block;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.material.colors.text.caption} !important;
`;

const AvatarPopoverStyled = styled(Avatar)`
    width: 40px !important;
    height: 40px !important;
    cursor:pointer;
`;

const AvatarStyled = styled(Avatar)`
    width: 24px !important;
    height: 24px !important;
    font-size: 1rem !important;
`;

const CardDivider = styled(Divider)`
    margin: 12px !important;
    height: 16px;
    position: relative;
    top: 3px;
    background-color: ${({theme})=> theme.material.colors.background.divider};
`;

const ColumWrapper = styled(FlexWrapper)`
    width: -webkit-fill-available;
    justify-content: space-between;
`;

const AvatarWrapper = styled(FlexWrapper)`
    margin: 10px 10px 10px 0;
    flex:1;
`;

const PopoverWrapper = styled.div`
    width: 280px;
    min-height: 148px;
    padding:16px;
`;

const TitleStyled = styled(Typography)`
    font-size: 10px;
    letter-spacing: 1.5px;
    color: ${({theme})=> theme.material.colors.text.secondary};
    padding-left:11px;
`;

const NameStyled = styled(Typography)`
    font-size: 16px;
    letter-spacing: 0.25px;
    font-weight:500;
    color: ${({theme})=> theme.material.colors.text.primary};
`;


const UserInfoWrapper = styled.div`
    padding-left:11px;
    margin:5px;
    flex:1;
    flex-direction:column;
`;

const UserPoperoverMenu = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { closeAction, anchorEl, data , type } = props;
    const { image , name, iconName, id } =  data || {};

    const copyIdToClipBoard = useCallback(() => {
        copyToClipboard(id)
            .then(() => {
                dispatch(showToastr({ severity: 'success', detail: 'User id copied to clipboard' }));
            })
            .catch(() => {
                dispatch(showToastr({ severity: 'error', detail: 'User id could not copied to clipboard' }));
            });
    }, [id, dispatch]);

    const copyLinkToClipBoard = useCallback(() => {
        copyToClipboard(`${window.location.origin}/#/user-management/${id}/about`)
            .then(() => {
                dispatch(showToastr({ severity: 'success', detail: 'User link copied to clipboard' }));
            })
            .catch(() => {
                dispatch(showToastr({ severity: 'error', detail: 'User link could not copied to clipboard' }));
            });
    }, [id, dispatch]);

    return (
        <Popover
            open={Boolean(anchorEl)}
            onClose={closeAction}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
        >
            <PopoverWrapper>
                <FlexWrapper>
                    <Icon name={iconName} hexColor={muiTheme.colors.text.secondary} />
                    <TitleStyled>{type}</TitleStyled>
                </FlexWrapper>
                <AvatarWrapper>
                    <AvatarPopoverStyled src={image} initials={name} onClick={() => history.push(`/user-management/${id}`)}/>
                    <UserInfoWrapper>
                        <NameStyled variant="h6">{name}</NameStyled>
                        <Grid container>
                            <EntityLinkStyledSubTitle
                                color="secondary"
                                variant="caption"
                                noWrap
                                title="Copy id to clipboard"
                                onClick={copyIdToClipBoard}
                            >
                                #{toUniqueUUID(id)}
                            </EntityLinkStyledSubTitle>
                            <LinkIcon onClick={copyLinkToClipBoard} title="Copy link to clipboard" name="link" size="sm"/>
                        </Grid>
                    </UserInfoWrapper>
                </AvatarWrapper>
            </PopoverWrapper>
        </Popover>
    );
};

const EntityColumn = ({ data, type, groupBy }) => {
    const { image , name, iconName } =  data || {};
    const [anchorEl, setAnchor] = useState(null);

    const closeAction = useCallback((e) => {
        setAnchor(null);
    }, []);

    return (
        <>
            <ColumWrapper>
                <FlexWrapper>
                    <AvatarWrapper>
                        <AvatarStyled src={image} initials={name} />
                    </AvatarWrapper>
                    <Icon name={iconName} hexColor={muiTheme.colors.text.secondary} />
                    <CardDivider flexItem orientation="vertical" />
                    {name}
                </FlexWrapper>
                {groupBy === 'assignee' && <ButtonIcon type={'mdi'} icon={'dots-vertical'} onClick={ e => setAnchor(e.currentTarget)}/>}
            </ColumWrapper>
            <UserPoperoverMenu closeAction={closeAction} anchorEl={anchorEl} data={data} type={type} />
        </>
    );
};

export default EntityColumn;
