/* @flow */

import { gql } from '@apollo/client';

export const runScriptMutationQuery = `
mutation runScriptMutation($description: String, $script: ID!, $name: String, $version: Int, $input: JSON, $file: Upload) {
    result: runScript(script: $script, description: $description, name: $name, version: $version, input: $input, file: $file) {
        id
        name
    }
}
`;

export default gql`${runScriptMutationQuery}`;