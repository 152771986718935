/* @flow */

import React, { memo, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Breadcrumbs as MuiBreadcrumbs, Typography, Link, IconButton, MdiIcon, Grid, Chip } from '@mic3/platform-ui';

import history from 'store/History';
import { openLeftPanel, setShowBack } from 'store/actions/leftPanel/leftPanelActions';

const BreadcrumbsStyled = styled(MuiBreadcrumbs)`
    flex-grow: 1;

    & a, & .MuiTypography-root {
      font-size: 20px !important;
      font-weight: 500 !important;
      padding-top: 1px !important;
    }

    & li:last-child {
        flex-grow: 1;
        width: 1px;
        & .MuiTypography-root {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            flex: 1 1 auto;
        }
    }
`;
const ArrowStyled = styled(MdiIcon)`
`;

const BreadcrumbGridStyled = styled(Grid)`
    margin: 0 !important;
    padding: 0 !important;
    flex-grow: 1;
`;
const TypographyStyled = styled(Typography)`
    && {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    white-space: pre !important;
`;

const ChipStyled = styled(Chip)`
    background-color: ${({ theme }) => theme.material.colors.background.activeElement} !important;
    color:  ${({ theme }) => theme.material.colors.text.button} !important;
    text-transform: uppercase;
    font-size: 8px !important;
    margin-left: 10px !important;
    
    &&:hover {
        background-color: ${({ theme }) => theme.material.colors.background.activeElement} !important;
    }
`;

const Breadcrumbs = ({ list, withGoBack, className, goBack, labelAtEnd }) => {
    const dispatch = useDispatch();
    const leftPanelBack = useSelector(
        state => state.leftPanel.state.showBack,
    );

    const breadcrumbsList = useMemo(() => (list || []).reduce((accum, brcrmb, i) => {
        let extraLabel = null;

        if(list.length === (i + 1) && labelAtEnd) {
            extraLabel = <ChipStyled size="small" label={labelAtEnd} />;
        }

        accum.push(brcrmb.link ? (
            <Link key={i} color="inherit" to={brcrmb.link} >
                {brcrmb.title}
            </Link>
        ) : (
            <TypographyStyled key={i} color="textPrimary">{brcrmb.title}{extraLabel}</TypographyStyled>
        ));

        if(list.length === (i + 1) && labelAtEnd && brcrmb.link) {
            accum.push(extraLabel);
        }

        return accum;
    }, []), [list, labelAtEnd]);

    if(labelAtEnd) {}

    const handleGoBack  = useCallback(() => {
        if(goBack) {
            return goBack();
        }
        
        history.goBack();
    }, [goBack]);

    const handlOpenLeftPanel  = useCallback(() => {
        dispatch(openLeftPanel());
        dispatch(setShowBack(false));
    }, [dispatch]);

    return (
        <BreadcrumbGridStyled alignItems="center" wrap="nowrap" container>
            {(withGoBack && !leftPanelBack) && (
                <IconButton title="back" onClick={handleGoBack}>
                    <ArrowStyled name="arrow-left" />
                </IconButton>
            )}
            {leftPanelBack && (
                <IconButton title="back" onClick={handlOpenLeftPanel}>
                    <ArrowStyled name="chevron-right" />
                </IconButton>
            )}
            <BreadcrumbsStyled className={className}>
                {breadcrumbsList}
            </BreadcrumbsStyled>
        </BreadcrumbGridStyled>
    );
};

export default memo(Breadcrumbs);
