/* @flow */

import { name } from 'app/utils/designer/form/settings/common/commonBase';
import { onLoad, isVisible } from 'app/utils/designer/form/settings/common/commonEvents';
import { justifyContent } from 'app/utils/designer/form/settings/common/commonStyles';

const panels = [
    {
        header: 'Base',
        children: [
            name
        ],
    },
    {
        header: 'Events',
        children: [
            onLoad,
            isVisible,
        ]
    },
    {
        header: 'Styles',
        children: [
            {
                type: 'boolean',
                properties: { label: 'Enable dividers', name: 'properties.addDividers'  }
            },
            {
                type: 'boolean',
                properties: { label: 'Row direction', name: 'properties.rowDirection' }
            },
            justifyContent,
        ]
    },
];

export default {
    defaults: { children: [], serialize: true },
    panels: settingValues => panels
};
