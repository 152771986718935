
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { bind } from 'app/utils/decorators/decoratorUtils';

const ResizeBase = styled.div`
    ${({ theme, isMobile }) => !isMobile && `
        border-left: 1px solid ${theme.material.colors.background.divider};
        border-right: 1px solid ${theme.material.colors.background.divider};
    `};
    height: 100%;
    width: 6px;
    position: absolute;
    ${({ left }) => left ? 'right: 0;' : 'left: 0;'}
    top: 0;
    cursor: col-resize;
`;

export default class Resizer extends PureComponent {
    static propTypes = {
        size: PropTypes.number,
        isMobile: PropTypes.bool,
        isResizing: PropTypes.func.isRequired,
        onResize: PropTypes.func.isRequired,
        resizeElem: PropTypes.any,
        left: PropTypes.bool
    }

    static defaultProps = {
        size: 0
    };

    clickTimeout = null;

    @bind
    mouseMove(e) {
        const { size, onResize, isResizing, resizeElem, left } = this.props;
        let offset;
        
        if (!left) {
            const newSize = e.clientX - resizeElem.current.offsetLeft;
            offset = newSize > 0 ? -newSize : Math.abs(newSize);
            offset += size;
        } else {
            offset = e.clientX;
        }
        
        isResizing(true);
        onResize(offset);
    }

    @bind
    mouseUp() {
        this.props.isResizing(false);
        document.removeEventListener('mousemove', this.mouseMove);
        document.removeEventListener('touchmove', this.mouseMove);
        document.removeEventListener('mouseup', this.mouseUp);
        document.removeEventListener('touchend', this.mouseUp);
    }

    @bind
    handleResize(e) {
        document.addEventListener('mousemove', this.mouseMove);
        document.addEventListener('touchmove', this.mouseMove);
        document.addEventListener('mouseup', this.mouseUp);
        document.addEventListener('touchend', this.mouseUp);
    }
    //Handle the double click resize 
    @bind
    handleClick() {
        const { onResize, minSidebarWidth } = this.props;
        if (this.clickTimeout !== null) {
            clearTimeout(this.clickTimeout);
            this.clickTimeout = null;
            onResize(minSidebarWidth);
            return;
        }
        this.clickTimeout = setTimeout(() => {
            this.clickTimeout = null;
        }, 300);
    }

    render() {
        return <ResizeBase 
            isMobile={this.props.isMobile} 
            onClick={this.handleClick} 
            left={this.props.left} 
            onMouseDown={this.handleResize}
            onTouchStart={this.handleResize}
            title="Double click to reset size" 
        />;
    }
};
