import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ListItem, ListItemText, ListItemAvatar, Typography, Tooltip } from '@mic3/platform-ui';

import { getFieldByType } from 'app/utils/designer/form/fieldUtils';
import { getAttachmentUrl } from 'app/utils/attachments/attachmentsUtils';
import { displayByKind, fromNow } from 'app/utils/date/date';
import { get } from 'app/utils/lo/lo';

import { EntityLinkStyled } from './ChangelogItem';

const ListItemStyled = styled(ListItem)`
    padding: 16px !important;
    align-items: flex-start !important;
`;

const ListItemTextStyled = styled(ListItemText)`
    position: relative;
    flex: 1;

    & .MuiListItemText-primary {
        display: flex;
        align-items: center;
    }

    & .MuiListItemText-secondary {
        font-size: 12px !important;
        display: flex;
    }
`;

const ListText = styled(Typography)`
    && {
        font-size: 14px;
        color: ${({ theme }) => theme.material.colors.text.primary};
        word-break: break-word;
    }
`;

const ListItemAvatarStyled = styled(ListItemAvatar)`
    margin-top: 8px;
`;

const ChangelogSingleItem = ({ user, date, info }) => {
    return (
        <ListItemStyled component="div" ContainerComponent="div">
            <ListItemAvatarStyled>
                {
                    getFieldByType('avatar', {
                        initials: user.name || 'No Name',
                        src: user.image && getAttachmentUrl(user.id, 'user')
                    })
                }
            </ListItemAvatarStyled>
            <ListItemTextStyled
                primary={
                    <ListText component="div">
                        <EntityLinkStyled id={user.id} type="user">{get(user, 'name', 'No Name')}</EntityLinkStyled>
                        {' '}
                        {info}
                    </ListText>
                }
                secondary={
                    <Tooltip title={displayByKind('datetime', date)} placement="bottom">
                        <span>{fromNow(date)}</span>
                    </Tooltip>
                }
            />
        </ListItemStyled>
    );
};

ChangelogSingleItem.propTypes = {
    user: PropTypes.object,
    date: PropTypes.string,
    info: PropTypes.node.isRequired
};

export default memo(ChangelogSingleItem);