/* @flow */

import { gql } from '@apollo/client';

export default gql`
query loadVersionQuery {
  version {
    graphql
    database
    bpmn
  }
}
`;
