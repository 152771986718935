/* @flow */

import React, { PureComponent } from 'react';
import { Autocomplete } from '@mic3/platform-ui';
import { connect } from 'react-redux';

import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { filterRecordsOnFE } from 'app/utils/filter/filterUtils';
import { debounce } from 'app/utils/utils';
import { loadAllPrimaryClasses } from 'store/actions/app/appActions';
import { loadAllRelationDefinitionsOfType } from 'store/actions/entities/relationshipsActions';
import { getUnique } from 'app/utils/array/array-utils';

class EntityTypesTypeahead extends PureComponent<Object, Object> {
    state = { isLoading: true, options: [], ancestors: [] };

    constructor(props) {
        super(props);
        this.loadRelDefs();
        props
            .loadAllPrimaryClasses(true)
            .then(({ records }) => {
                this.setState({
                    isLoading: false,
                    options: this.getOptions(records, this.modifyFilterBy(props.filterBy)),
                });
            })
            .catch(() => {
                this.setState({ isLoading: false });
            });
    }

    @bind
    optionTemplate(data: any) {
        const { optionTemplate, entityTypes, valueField, filterBy } = this.props;
        if (optionTemplate) {
            return optionTemplate(data);
        }
        if (!entityTypes.length && data) {
            return {};
        }
        if (valueField && typeof data === 'string') {
            const { id, name } =
                this.getOptions(entityTypes, this.modifyFilterBy(filterBy)).find((eType) => eType[valueField] === data) || {};
            return {
                label: `${name || 'Name not available'} (${id ? id.slice(0, 8) : 'ID not available'})`,
            };
        }
        const { id, name } = data;
        return {
            label: `${name || 'Name not available'} (${id ? id.slice(0, 8) : 'ID not available'})`,
        };
    }

    @bind
    modifyFilterBy(filters) {
        if (!this.props.fromType) {
            return filters;
        }
        const { relationDefinitions } = this.props;
        const filterBy = [...(filters || [])];
        const { ancestors } = this.state;
        const relatedTypes = this.getRelatedTypes(relationDefinitions, ancestors);
        if (relatedTypes?.length) {
            filterBy.push({ field: 'uri', op: 'in', value: relatedTypes });
        }
        return filterBy;
    }

    @bind
    @memoize()
    getOptions(records, filterBy) {
        const _filteredRecords =  [...filterRecordsOnFE(records, filterBy), ...(this.props.extraOptions || [])];
        if(this.props.filterResults){
            return this.props.filterResults(_filteredRecords);
        }
        return _filteredRecords;
    }

    @bind
    @debounce()
    suggest(event: Object) {
        const { entityTypes, filterBy } = this.props;
        const query = event.target.value;
        let options = this.getOptions(entityTypes, this.modifyFilterBy(filterBy));
        options = this.filterOptions(query, options);
        this.setState({ options });
    }

    @bind
    filterOptions(query, list) {
        return (list || []).filter(({ id, name }) => id === query || name.toLowerCase().includes(query.toLowerCase()));
    }

    @bind
    async loadRelDefs() {
        const { fromType } = this.props;
        if (!fromType) return;
        try {
            const { ancestors } = await this.props.loadAllRelationDefinitionsOfType(fromType);
            this.setState({ ancestors });
        } catch (error) {}
    }

    @bind
    @memoize()
    getRelatedTypes(definitions, ancestors) {
        // get all the related types where relation definitions exist
        const { fromType } = this.props;
        const entityTypes = (definitions || [])
            .map(({ type, relatedType }) => {
                if (ancestors?.includes(type) && ancestors?.includes(relatedType)) {
                    if (type !== relatedType && fromType === type) {
                        return relatedType;
                    }
                    return type; // if relation definition for same type to same type exists
                }
                if (ancestors?.includes(type)) return relatedType;
                if (ancestors?.includes(relatedType)) return type;
                return null;
            })
            .filter(Boolean);
        return getUnique(entityTypes);
    }

    render() {
        const {
            entityTypes,
            filterBy,
            orderBy,
            excludeBy,
            loadAllPrimaryClasses,
            filterOptions,
            extraOptions,
            defsLoading,
            loadAllRelationDefinitionsOfType,
            fromType,
            relationDefinitions,
            ...typeaheadProps
        } = this.props; // eslint-disable-line no-unused-vars
        const { options, isLoading } = this.state;
        return (
            <Autocomplete
                {...typeaheadProps}
                options={options}
                isLoading={isLoading || defsLoading}
                optionTemplate={this.optionTemplate}
                suggest={this.suggest}
            />
        );
    }
}

export default connect(
    (state, ownProps) => ({
        entityTypes: state.app.allPrimaryClasses.records || [],
        relationDefinitions: state.entities.mapedRelations.relationDefinitionsMap[ownProps?.fromType] || [],
        defsLoading: state.entities.mapedRelations.relationDefinitionsMap.isLoading,
    }),
    {
        loadAllPrimaryClasses,
        loadAllRelationDefinitionsOfType,
    }
)(EntityTypesTypeahead);
