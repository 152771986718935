/* @flow */

import React, { useCallback } from 'react';
import { MenuItem as PuiMenuItem, ConfirmationModal } from '@mic3/platform-ui';
import styled from 'styled-components';
import { useToggle } from 'app/utils/hook/hooks';

import FormField from 'app/containers/Designer/Form/components/FormField';

const fieldify = (Component: Object) => (props: Object) => <FormField {...props} Component={Component} />;

const PuiMenuItemStyled = styled(PuiMenuItem)`
`;

const ConfirmationModalFildifyed = fieldify(ConfirmationModal);

const MenuItem = React.forwardRef(({
    withConfirmation, confirmationModalProps, to, onClick,
    afterAction, afterActionType, afterActionTo, afterActionHref, afterActionFunction, actionType,
    validateBefore, isValidForm, href, target,
    ...menuItemProps
}: Object, ref) => {
    const [openConfirmationModal, toggleConfirmationModal] = useToggle();
    const onClickWithConfirm = withConfirmation ? toggleConfirmationModal : onClick;
    const validateOnClick = useCallback(async (event) => {
        if(event && event.persist) {
            event.persist();
        }
        const { errors } = await isValidForm();
        if(!errors) {
            if(!href) {
                onClickWithConfirm(event);
            } else {
                window.open(href, target);
            }
        }
    }, [href, isValidForm, onClickWithConfirm, target]);
    const onClickAction = validateBefore ? validateOnClick : onClickWithConfirm;
    return (
        <>
            <PuiMenuItemStyled {...menuItemProps} onClick={onClickAction} ref={ref} />
            {withConfirmation && confirmationModalProps && (
                <ConfirmationModalFildifyed
                    {...confirmationModalProps}
                    onClose={toggleConfirmationModal}
                    open={openConfirmationModal}
                    onConfirm={onClick}
                />
            )}
        </>
    );
});

export default MenuItem;
