/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { uploadImage } from 'store/actions/entities/entitiesActions';
import { get } from 'app/utils/lo/lo';
import PageNotAllowed from 'app/containers/ErrorPages/PageNotAllowed';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { loadOrganisationSettings, showToastr, refreshOrganisationImage, updateOrganisationSettings, setDocumentTitle } from 'store/actions/app/appActions';
import Container from 'app/components/atoms/Container/Container';
import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import { Button, CircularProgress, Typography } from '@mic3/platform-ui';
import HeaderBar from 'app/components/molecules/HeaderBar/HeaderBar';
import PageTemplate from 'app/components/templates/PageTemplate';
import { getOnlyUpdatedData } from 'app/utils/app/appUtils';
import { organistationEntityId, settingsEntityType } from 'app/config/config';
import Loader from 'app/components/atoms/Loader/Loader';
import { getPermissions } from 'app/config/rolesConfig';
import { modulesAndPageTitles } from 'app/config/typesConfig';


class OrganisationSettings extends PureComponent<Object, Object> {
    static propTypes = {
        profile: PropTypes.object,
        updateOrganisationSettings: PropTypes.func.isRequired,
        uploadImage: PropTypes.func.isRequired,
        loadOrganisationSettings: PropTypes.func.isRequired
    };

    formRef: Object = React.createRef();

    constructor(props: Object) {
        super(props);
        props.loadOrganisationSettings();
    }

    componentDidMount() {
        this.props.setDocumentTitle(modulesAndPageTitles.adminConsole.site.general);
    }

    @bind
    @memoize()
    fieldDefinitions(organisation, isAdmin) {
        const { name, image } = organisation;
        return [
            {
                field: 'panel',
                type: 'panel',
                properties: {
                    header: 'Organisation Details',
                    expanded: true,
                },
                children: [
                    {
                        field: '',
                        type: 'avatarEditor',
                        properties: {
                            name: 'image',
                            label: 'Profile Image',
                            initials: name,
                            image,
                        }
                    },
                    {
                        field: 'name',
                        type: 'text',
                        properties: {
                            label: 'Name',
                            name: 'name',
                        },
                        constraints: {
                            required: true,
                            maxLength: 60,
                            minLength: 3
                        }
                    },
                    {
                        field: 'fullName',
                        type: 'text',
                        properties: {
                            label: 'Organisation Full Name',
                            name: 'fullName',
                        },
                        constraints: { minLength: 1 }
                    },
                    {
                        field: 'contact',
                        type: 'personTypeahead',
                        properties: {
                            label: 'Contact Person',
                            name: 'contact',
                            filterBy: isAdmin ? null : [{ field: 'active', op: '=', value: true }]
                        }
                    }
                ]
            },

            {
                field: 'panel',
                type: 'panel',
                properties: {
                    header: 'Superset Configuration',
                    expanded: true,
                },
                children: [
                    {
                        type: 'text',
                        properties: {
                            label: 'URL',
                            name: 'reportingUrl',
                        }
                    },
                    {
                        type: 'text',
                        properties: {
                            label: 'Superset Token',
                            name: 'reportingToken',
                            
                        }
                    }
                ]
            },
            {
                field: 'panel',
                type: 'panel',
                properties: {
                    header: 'Learning Platform Configuration',
                    expanded: true,
                },
                children: [
                    {
                        type: 'text',
                        properties: {
                            label: 'URL',
                            name: 'learningUrl',
                        }
                    },
                ]
            }
        ];
    }

    @bind
    uploadImage(image) {
        this.props.uploadImage(organistationEntityId, settingsEntityType, image).then((resp) => {
            if (!(resp instanceof Error)) {
                this.props.refreshOrganisationImage();
            }
        });
    }

    @bind
    handleChange(data: Object, { name, value }: Object) {
        if (name === 'image') {
            return this.uploadImage(value);
        }
    }

    @bind
    onFormSubmit(event: Event) {
        event.preventDefault();
        this.formRef.current.isValidForm().then(({ data: formData, errors }) => {
            if (!errors) {
                const { organisation } = this.props;
                const { name, fullName, contact, learningUrl, reportingUrl, reportingToken } = formData || {};
                const entity = getOnlyUpdatedData(organisation, { name, fullName, contact, learningUrl, reportingUrl, reportingToken });
                entity && Object.keys(entity)?.length && this.props.updateOrganisationSettings(entity);
            }
        });
    }

    @bind
    renderSaveButton(canEdit) {
        const { isLoading } = this.props;
        if(!canEdit) return null;
        return isLoading ? (
            <CircularProgress key={113} size={24} color="primary" />
        ) : (
            <Button key={113} onClick={this.onFormSubmit} color="primary" form="form" type="submit">
                Save
            </Button>
        );
    }

    render() {
        const { organisation, configurations, profile, organisationImage, isLoading } = this.props;
        const { isAdmin } = profile || {};
        const permissionsSet = new Set(this.props.permissions);
        const canSeeSettings = isAdmin || permissionsSet.has('admin.settings') || permissionsSet.has('admin.site');
        const siteEntityPermissions = getPermissions(organisation?.role);
        const data = { ...organisation, config: configurations, image: organisationImage };
        const canEdit = siteEntityPermissions?.canEdit;
        if (!canSeeSettings || !organisation) {
            return <PageNotAllowed title="Organisation Settings" />;
        }
        return (
            <PageTemplate title={organisation.name} overflowHidden>
                <HeaderBar right={[this.renderSaveButton(canEdit)]} left={<Typography variant="h6">General</Typography>} />
                <ContentArea withHeader>
                    {isLoading && <Loader absolute backdrop />}
                    <Container width="1024">
                        <FormGenerator
                            components={this.fieldDefinitions(data, isAdmin)}
                            data={data}
                            ref={this.formRef}
                            disabled={!canEdit}
                            onChange={this.handleChange}
                        />
                    </Container>
                </ContentArea>
            </PageTemplate>
        );
    }
}

export default connect(
    state => ({
        profile: state.user.profile,
        id: get(state.app, 'organisation.id', ''),
        type: 'organisation',
        isLoading: state.app.organisationSettings.isLoading,
        organisation: get(state.app, 'organisationSettings.data', {}),
        organisationImage: state.app.organisationImage,
        permissions: state.user.profile.permissions,
        configurations: get(state.app, 'configurations', {}),
    }),
    {
        loadOrganisationSettings,
        uploadImage,
        showToastr,
        refreshOrganisationImage,
        updateOrganisationSettings,
        setDocumentTitle
    }
)(OrganisationSettings);
