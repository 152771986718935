/* @flow */

import { gql } from '@apollo/client';
import { classDetailsFields } from 'graphql/entities/classifications/classificationDetailQuery';


export default gql`
    mutation createClassificationMutation($classification: ClassCreateInput!) {
        result: createClass(record: $classification) {
            ${classDetailsFields}
        }
    }
`;
