import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import styled from 'styled-components';
import { muiTheme } from 'app/themes/materialUi';
import { Typography } from '@mic3/platform-ui';
import { withStyles } from '@material-ui/core/styles';
import { CardContent, Divider } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import { bind } from 'app/utils/decorators/decoratorUtils';



const useStyles = withStyles({
    headerSpacing: {
        marginTop: '1rem',
        color: muiTheme.colors.text.primary, 
        fontSize: '24px',
        paddingLeft: '14px',
    },
});


const ContentHeading = styled.div`
    font-size: 20px !important;
    font-weight: 500 !important;
    padding: 7px 14px 8px 14px;
    color: ${({ theme }) => theme.base.textColor};
    p {
        margin: 0;
        padding: 4px 4px 8px 8px;
    }
`;


const CardContentWrapper = styled(CardContent)`
    width: calc(100% - 20rem);
    @media (max-width: 921px) {
        width: 100%;
    }
    margin: 0 auto;
    div {
        background-color: ${({ theme })=> theme.color.paper};
    }
    .MuiFormControl-root {
        width: 100%; 
        margin-bottom: 20px !important; 
    }
    .MuiFormControlLabel-labelPlacementStart {
        display: flex;
        justify-content: space-between;
    }
    .MuiSwitch-root {
        margin-right: 2rem;
    }
`;

const Container = styled.div`
      box-shadow: ${({theme})=> theme.shadow.boxShadow1DP} !important;
`;

const StyledText = styled(Typography)`
    color: ${({ theme }) => theme.base.textColor};
    padding: 15px 5px 15px 20px;
    font-size: 18px !important;
    font-weight: 500 !important;
`;
const Scroller = styled.div`
    overflow-y: scroll;
    height: 95vh;
`;
const StyledFormLabel = styled(FormLabel)`
    font-size: 12px !important;
    font-weight: 600 !important;
`;

const WrapperFormControl = styled.div`
    margin: 20px 20px !important; 
`;


class UserPreferencesAppearances extends PureComponent<Object, Object> {

    constructor(props){
        super(props);
        this.state = {
            theme: this.getTheme(),
        };
    }

    
    @bind
    handleChange(event){
        localStorage.setItem('theme', JSON.stringify(event.target.value));
        this.setState({theme: event.target.value});
        window.location.reload();
    };

    @bind
    getTheme(){
        const theme = JSON.parse(localStorage.getItem('theme')) || 'darkTheme';
        return theme;
    }
  

    render()  {
        const { theme } = this.state;
        return (
            <Scroller>
                <ContentHeading>
                    <p>User Preferences</p>
                    <Divider />
                </ContentHeading>
                <CardContentWrapper>
                    <Container>
                        <StyledText>Appearance</StyledText>
                        <WrapperFormControl>
                            <FormControl >
                                <StyledFormLabel>Theme</StyledFormLabel>
                                <RadioGroup  value={theme} onChange={(e)=> this.handleChange(e)}>
                                    <FormControlLabel value="darkTheme" control={<Radio />} label="Dark" />
                                    <FormControlLabel value="lightTheme" control={<Radio />} label="Light (Beta)" />
                                    {/* <FormControlLabel value="highContrast" control={<Radio />} label="High contrast"/> */}
                                </RadioGroup>   
                            </FormControl>
                        </WrapperFormControl>
                    </Container>
                </CardContentWrapper>
            </Scroller>
        );
    }
}

export default connect((state) => ({
    userTheme: state.global.userTheme
}),
{ }
)(useStyles(UserPreferencesAppearances)); // eslint-disable-line


