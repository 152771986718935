/* @flow */

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { isBrowser } from 'react-device-detect';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, IconButton, Tooltip } from '@mic3/platform-ui';
import Icon from 'app/components/atoms/Icon/Icon';
import { setSelectedElement } from 'store/actions/form/formActions';


import { buildSidebar, openSidebar, closeSidebar } from 'store/actions/sidebar/sidebarActions';

import HeaderBar from 'app/components/molecules/HeaderBar/HeaderBar';
import Tabs from 'app/components/organisms/Tabs/Tabs';

const drawerWidth = 290;

const GridWrapper = styled(Grid)`
    height: 100%;
`;

const GridContentStyled = styled(Grid)`
    flex-grow: 1;
    transition: padding 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    ${({ height }) => height ? `height: ${height}; overflow-x: auto;` : ''}
    ${({ isleftnavopen }) => isBrowser && isleftnavopen ? `padding-left: ${drawerWidth}px;transition: padding 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;` : ''}
`;

/**
 * Create our layout component to handle more strict functional inner layouts
 */
class Layout extends PureComponent<Object, Object> {

    static defaultProps = {
        RightSidebarProps: {},
        LeftSidebarProps: {},
        disableRightMenu: false,
    };

    /**
     * Define our initial state
     */
    constructor(props: Object) {
        super(props);
        this.state = {
            isLeftNavOpen: props.LeftSidebarProps.open || false,
        };
    }

    componentDidUpdate(prevProps: Object) {
        const { LeftSidebarProps, RightSidebarProps, isToggled, buildSidebar, sidebarIsOpen } = this.props;

        if (prevProps.LeftSidebarProps.open !== LeftSidebarProps.open) {
            this.setState({ isLeftNavOpen: LeftSidebarProps.open });
        }
        if (prevProps.RightSidebarProps !== RightSidebarProps) {
            buildSidebar({
                isOpen: RightSidebarProps.isOpen || sidebarIsOpen,
                title: RightSidebarProps.title,
                subTitle: RightSidebarProps.subTitle,
                subTitleRight: RightSidebarProps.subTitleRight,
                iconName: RightSidebarProps.iconName,
                iconType: RightSidebarProps.iconType,
                content: RightSidebarProps.content,
                actions: RightSidebarProps.actions,
                afterCloseSidebar: RightSidebarProps.afterCloseSidebar,
            });
        }
        if (prevProps.isToggled !== isToggled) {
            this.toggleLeftNav();
        }
    }

    /**
     * Toggle the state of the left navigation
     */
    toggleLeftNav = () => {
        const { onClose } = this.props.LeftSidebarProps || {};
        if (onClose) {
            onClose();
        } else {
            this.setState({
                isLeftNavOpen: !this.state.isLeftNavOpen,
            });
        }
    };

    /**
     * Toggle the state of the right navigation
     */
    toggleRightNav = () => {
        const { onClose } = this.props.RightSidebarProps || {};
        const { buildSidebar, RightSidebarProps, sidebarIsOpen } = this.props;
        if (sidebarIsOpen && onClose) {
            onClose();
        } else {
            buildSidebar({
                isOpen: true,
                title: RightSidebarProps.title,
                subTitle: RightSidebarProps.subTitle,
                subTitleRight: RightSidebarProps.subTitleRight,
                iconName: RightSidebarProps.iconName,
                iconType: RightSidebarProps.iconType,
                content: RightSidebarProps.content,
                actions: RightSidebarProps.actions,
                afterCloseSidebar: RightSidebarProps.afterCloseSidebar,
            });
        }
    };

    render() {
        const {
            afterRightButton, TabsToolbarContent, tabs, height, className, content, children,
            leftToolbarContent, ToolbarContent, RightMenuIcon, disableRightMenu
        } = this.props;
        const { isLeftNavOpen } = this.state;
        const toggleRightButton = <Tooltip title='Properties'>
            <IconButton onClick={this.toggleRightNav}>
                {RightMenuIcon || <Icon name="classification-editor" type="af" />}
            </IconButton>
        </Tooltip>;
        const rightButton = toggleRightButton;
        return (
            <GridWrapper container direction="column" className={className} wrap="nowrap">
                {(rightButton || ToolbarContent) && (
                    <HeaderBar
                        left={leftToolbarContent}
                        right={(
                            <>
                                {ToolbarContent}
                                {!disableRightMenu && rightButton}
                                {afterRightButton}
                            </>
                        )}
                    />
                )}
                {(tabs || TabsToolbarContent) && (
                    <HeaderBar
                        left={tabs && (
                            <Tabs tabs={tabs}/>
                        )}
                        right={(
                            <>
                                {TabsToolbarContent}
                            </>
                        )}
                    />
                )}
                <GridContentStyled height={height} item container direction="column" isleftnavopen={isLeftNavOpen?1:0}>
                    {content}
                    {children}
                </GridContentStyled>
            </GridWrapper>
        );
    }
}

Layout.propTypes = {
    disableRightMenu: PropTypes.bool,
    leftNavOpen: PropTypes.bool,
    content: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    rightSidebar: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
};

export default connect(state => ({
    sidebarIsOpen: state.sidebar.isOpen,
    sidebarTitle: state.sidebar.title,
    isMobile: state.global.isMobile,
}), { openSidebar, closeSidebar, buildSidebar, setSelectedElement })(Layout);
