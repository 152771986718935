/* @flow */

import { gql } from '@apollo/client';

export default withParents => gql`
    query loadClassificationsListQuery($startIndex: Int, $stopIndex: Int, $filterBy: [JSON], $orderBy: [JSON], $countMax: Int) {
        count: count(dataType: "class", filterBy: $filterBy, max: $countMax)
        records: classes(filterBy: $filterBy, orderBy: $orderBy, startIndex: $startIndex, stopIndex: $stopIndex) {
            id
            name
            uri
            color
            formDefinition
            icon
            iconType
            ${withParents ? 'parents { id uri name color formDefinition }' : ''}
        }
    }
`;
