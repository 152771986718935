/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Grid, Chip } from '@mic3/platform-ui';

import ExpansionCard from 'app/components/molecules/ExpansionCard/ExpansionCard';
import Ellipsis from 'app/components/molecules/Ellipsis/Ellipsis';
import { isEmpty, getStr } from 'app/utils/utils';
import { getEntityUrl } from 'app/utils/entity/entityUtils';
import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import { addTeamUsers, removeTeamUsers, addTeamOwners, removeTeamOwners, loadRelatedWorkspaces } from 'store/actions/admin/teamsActions';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import SelectionList from 'app/containers/Common/SelectionList/SelectionList';
import HeaderBar from 'app/components/molecules/HeaderBar/HeaderBar';
import history from 'store/History';
import { PUBLIC_TEAM_ID, SUPER_USERS_TEAM_ID } from 'app/config/config';
import { setDocumentTitle } from 'store/actions/app/appActions';

const MoreChipStyled = styled(Chip)`
    background: ${({ theme }) => theme.material.colors.background.fields} !important;
    & .MuiChip-label {
        max-width: 140px;
    }
`;

const TeamChipStyled = styled(Chip)`
    background: ${({ theme }) => theme.material.colors.background.fields} !important;
`;

const GridWrapper = styled(Grid)`
    padding: 10px 16px 32px 16px;
`;

/**
 * Renders the view to display the classification.
 */
class TeamAboutTab extends PureComponent<Object, Object> {
    static propTypes = {
        isLoading: PropTypes.bool,
        addTeamMembersTaskLoading: PropTypes.bool,
        loadRelatedWorkspaces: PropTypes.func.isRequired,
        details: PropTypes.object, // task or process details
        searchValue: PropTypes.string
    };

    defaultState = this.state;

    /**
     * @override
     */
    componentDidMount() {
        const { details, isSidebar } = this.props || {};
        const { name } = details || {};
        if(!isSidebar && name ) {
            this.props.setDocumentTitle(name);
        }
        this.props.loadRelatedWorkspaces(this.props.details.id);
    }

    @bind
    setDefaultState() {
        this.setState(this.defaultState);
    }

    @bind
    removeTeamUsers(selectedUsersListIds: Object) {
        const id = getStr(this.props, 'details.id', '');
        if (!id || isEmpty(selectedUsersListIds)) {
            return;
        }
        return this.props.removeTeamUsers(id, selectedUsersListIds).then(this.setDefaultState);
    }

    @bind
    onSubmitUsers(users: Array<Object>) {
        const id = getStr(this.props, 'details.id', '');
        if (!id || isEmpty(users)) {
            return;
        }
        return this.props.addTeamUsers(id, users).then(this.setDefaultState);
    }

    @bind
    onSubmitOwners(ownersIds: Array<Object>) {
        const id = getStr(this.props, 'details.id', '');
        if (!id || isEmpty(ownersIds)) {
            return;
        }
        return this.props.addTeamOwners(id, ownersIds).then(this.setDefaultState);
    }

    @bind
    removeTeamOwners(ownersIds) {
        const id = getStr(this.props, 'details.id', '');
        if (!id || isEmpty(ownersIds)) {
            return;
        }
        return this.props.removeTeamOwners(id, ownersIds).then(this.setDefaultState);
    }

    @bind
    @memoize()
    normalizeUsers(users) {
        return (users || []).map(usr => usr.user).filter(Boolean);
    }

    @bind
    @memoize()
    canEdit(owners) {
        const { id: userId, isAdmin } = this.props.profile;
        if (isAdmin) {
            return true;
        }
        return !!(owners || []).find(({ id }) => id === userId);
    }

    render() {
        const { details, isLoading, breadcrumbLine, relatedWorkspaces, isSidebar } = this.props;
        const { users, owners } = details || {};
        const canEdit = this.canEdit(owners);
        const disabled = !canEdit || details?.id === PUBLIC_TEAM_ID;
        const normalizeUsers = this.normalizeUsers(users);
        return (
            <>
                <HeaderBar left={breadcrumbLine} right={[...(this.props.sidebarActions || [])]} />
                <ContentArea withHeader isSidebar={isSidebar}>
                    <SelectionList
                        filteredList={normalizeUsers}
                        list={normalizeUsers}
                        type="user"
                        title="Team users"
                        onAdd={this.onSubmitUsers}
                        onRemove={this.removeTeamUsers}
                        isLoading={isLoading}
                        expanded
                        disabled={disabled }
                        valueField="id"
                    />
                    {details?.id !== SUPER_USERS_TEAM_ID ? (
                        <SelectionList
                            list={owners}
                            type="user"
                            title="Team Owners"
                            onAdd={this.onSubmitOwners}
                            onRemove={this.removeTeamOwners}
                            isLoading={isLoading}
                            disabled={disabled}
                            valueField="id"
                        />
                    ) : null}
                    <GridWrapper>
                        <ExpansionCard expanded title="Related workspaces">
                            <Grid container alignItems="center">
                                <Ellipsis
                                    data={relatedWorkspaces || []}
                                    renderCount={count => (
                                        <MoreChipStyled
                                            variant="default"
                                            label={`+${(relatedWorkspaces || []).length}`}
                                            size="small"
                                            onClick={this.handleChildrensModal}
                                        />
                                    )}
                                    renderItem={item => (
                                        <TeamChipStyled
                                            onClick={() => {
                                                history.push(getEntityUrl(item?.id, 'workspace'));
                                            }}
                                            label={item?.name}
                                        />
                                    )}
                                />
                            </Grid>
                        </ExpansionCard>
                    </GridWrapper>
                </ContentArea>
            </>
        );
    }
}

export default connect(
    state => ({
        profile: state.user.profile,
        isLoading: state.admin.teams.details.isLoading,
        relatedWorkspaces: state.admin.teams.relatedWorkspaces.records,
    }),
    { addTeamUsers, removeTeamUsers, addTeamOwners, removeTeamOwners, loadRelatedWorkspaces, setDocumentTitle }
)(TeamAboutTab);
