/* @flow */

import { gql } from '@apollo/client';

export default gql`
    mutation createEntityMutation($record: EntityCreateInput!) {
        result: createEntity(record: $record) {
            id
            type
            name
            primary
        }
    }
`;
