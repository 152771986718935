/* @flow */

import { gql } from '@apollo/client';

export default gql`
query getProcessDefinition($id: ID!) {
  result: processDefinition(id: $id) {
    id
    name
    description
    primary
    iconName
    iconColor
    iconType
    active
  }
  role: resourcePermission(type: "processdefinition", id: $id)
}
`;
