import { baseRoutes } from 'app/config/routesConfig';
import integration from 'app/config/integrationConfig';

const getPermission = (permission, permissionsSet) => {
    for (let i = 0; i < permission.length; i++) {
        if (permissionsSet.has(permission[i].canView)) {
            return permission[i];
        }
    }
    return false;
};

export const appModules = (isAdmin, permissions) => {
    const permissionsSet = new Set(permissions);
    const intergrationPermissions = [
        { canView: integration.permissions.view, link: integration.baseRoute },
    ];
    const miStreamPermissions = [
        { canView: 'mistream.event', link: '/events' },
        { canView: 'mistream.eventType', link: baseRoutes['eventtype'] },
    ];
    const aboxPermissions = [
        { canView: 'abox.kanbanboards', link: '/abox/kanbanboards' },
        { canView: 'abox.task', link: '/abox/tasks' },
        { canView: 'abox.process', link: '/abox/processes' },
        { canView: 'abox.calendar', link: '/abox/calendar' },
        { canView: 'abox.timeline', link: '/abox/timeline' },
        { canView: 'abox.board', link: '/abox/boards' },
        { canView: 'abox.backgroundjob', link: '/abox/background-jobs' },
    ];
    const designerPermission = [
        { canView: 'designer.process', link: '/designer/processes' },
        { canView: 'designer.form', link: '/designer/form' },
        { canView: 'designer.script', link: '/designer/scripts' },
        { canView: 'designer.pipeline', link: '/designer/mi-stream' },
    ];
    const entitiyPermission = [
        { canView: 'entity.entity', link: '/entities' },
        { canView: 'entity.relationdefinition', link: '/relation-definitions' },
        { canView: 'entity.graphic', link: '/graphics-library' }
    ];
    const adminPermission = [
        { canView: 'admin.site', link: baseRoutes['organisationSettings'] }, // admin.site added on front end dynamically
        { canView: 'admin.settings', link: baseRoutes['organisationSettings'] },
        { canView: 'admin.license', link: baseRoutes['license'] },
        { canView: 'admin.terms', link: '/terms' }, // admin.terms added on front end dynamically
        { canView: 'admin.broadcast', link: '/broadcasts' },
        { canView: 'admin.broadcastCalendar', link: baseRoutes['broadcastCalendar'] },
        { canView: 'admin.user', link: '/user-management' },
        { canView: 'admin.team', link: '/teams' },
        { canView: 'admin.workspace', link: '/workspaces' },
        { canView: 'admin.backgroundjob', link: '/background-jobs' },
        { canView: 'admin.sso', link: '/admin/settings/general' }, // admin.sso added on front end dynamically
    ];
    const analyticsPermission = [
        { canView: 'entity.entity', link: '/print-reports' },
        { canView: 'analytics.superAnalytics', link: '/super-analytics' },
        { canView: 'analytics.grafana', link: '/grafana' },
    ];
    const designer = getPermission(designerPermission, permissionsSet);
    const entity = getPermission(entitiyPermission, permissionsSet);
    const admin = getPermission(adminPermission, permissionsSet);
    const abox = getPermission(aboxPermissions, permissionsSet);
    const miStream = getPermission(miStreamPermissions, permissionsSet);
    const showIntegration = getPermission(intergrationPermissions, permissionsSet);
    const analytics = getPermission(analyticsPermission, permissionsSet);
    return [
        (isAdmin || abox) && {
            icon: 'checkbox-multiple-marked-circle-outline',
            name: 'A-Box',
            to: '/abox',
            hexColor: '#1EA8CF',
            color: '#fff'
        },
        (isAdmin || permissionsSet.has('alive.alive')) && {
            icon: 'messenger',
            iconType: 'af',
            name: 'A-Live',
            to: '/abox/alive',
            hexColor: '#673AB7',
            color: '#fff'
        },
        (isAdmin || entity) && {
            icon: 'things',
            iconType: 'af',
            name: 'Entities',
            to: entity ? entity.link : '/things',
            hexColor: '#0640B1',
            color: '#fff'
        },
        (isAdmin || permissionsSet.has('class.class')) && {
            name: 'Classes',
            icon: 'Attribute-class',
            iconType: 'af',
            to: '/entity-types',
            hexColor: '#F57F17',
            color: '#fff'
        },
        (isAdmin || permissionsSet.has('maps.sa')) && {
            icon: 'maps',
            iconType: 'af',
            name: 'Maps',
            to: baseRoutes.map,
            hexColor: '#00BCD4',
            color: '#fff'
        },
        (isAdmin || miStream) && {
            icon: 'stream',
            iconType: 'af',
            name: 'Mi-Stream',
            to: miStream ? miStream.link : '/events',
            hexColor: '#D81B60',
            color: '#fff'
        },
        (isAdmin || analytics) &&  {
            icon: 'charts',
            iconType: 'af',
            name: 'Analytics',
            to: analytics && analytics.link,
            hexColor: '#4CAF50',
            color: '#fff'
        },
        (isAdmin || designer) && {
            icon: 'designer',
            iconType: 'af',
            name: 'Designer',
            to: designer && designer.link,
            hexColor: '#8BC34A',
            color: '#fff'
        },
        (isAdmin || showIntegration) && {
            icon: integration.iconName,
            name: integration.moduleName,
            to: integration.baseRoute,
            hexColor: integration.iconColor,
            color: '#fff'
        },
        (isAdmin || admin) && {
            icon: 'admin',
            iconType: 'af',
            name: 'Admin Console',
            to: `${isAdmin ? '/organisation-settings' : admin && admin.link}`,
            hexColor: '#F4511E',
            color: '#fff'
        }
    ].filter(Boolean);
};
