/* @flow */

import { gql } from '@apollo/client';

export default gql`
query formDefinitionQuery($id: ID!) {
    result: formDefinition(id: $id) {
    id
    name
    description
    primary
    modifiedDate
    modifiedBy {
      id
      name
    }
  }
  role: resourcePermission(type: "formdefinition", id: $id)
}
`;
