/* @flow */

import { gql } from '@apollo/client';
import loadWorkspaceDetailsCommonQuery from 'graphql/workspaces/loadWorkspaceDetailsCommonQuery';

export default gql`
    mutation removeWorkspaceUsersMutation($id: ID!, $users: [ID]!) {
        result: workspaceRemoveUsers(id: $id, users: $users) {
            ${loadWorkspaceDetailsCommonQuery}
        }
    }
`;
