/* @flow */
import React from 'react';

import ClassSidebar from 'app/containers/Sidebars/ClassSidebar';

import {
    buildSidebar as buildSidebarAction,
    closeSidebar as closeSidebarAction
} from 'store/actions/sidebar/sidebarActions';

export const closeClassSidebar = closeSidebarAction;

export const openClassSidebar = ({ id, title, entityType, ...rest }) => async (dispatch, getState) => {
    return buildSidebarAction({
        title,
        content: <ClassSidebar closeClassSidebar={closeClassSidebar} id={id} title={title} entityType={entityType} {...rest} />,
        isOpen: true,
    })(dispatch, getState);
};
