/* @flow */

import ExecutionListenersBuilder from 'app/components/Designer/Builders/ExecutionListenersBuilder/ExecutionListenersBuilder';

class TaskListenersBuilder extends ExecutionListenersBuilder {

    static defaultProps = {
        tagName: 'flowable:taskListener',
        fields: [
            { type: 'typeahead', properties: {
                label: 'Event', name: 'event',
                valueField: 'value', clearable: false,
                options: [{ label: 'Create', value: 'create'}, { label: 'Assignment', value: 'assignment'}, { label: 'Complete', value: 'complete'}, { label: 'Delete', value: 'delete'}],
                defaultValue: 'create'
            }, constraints: { required: true }},
            { type: 'typeahead', properties: {
                label: 'Implementation Type', name: 'implementationType',
                valueField: 'value', clearable: false,
                options: [{ label: 'Class', value: 'class'}, { label: 'Expression', value: 'expression'}, { label: 'Delegate Expression', value: 'delegateExpression'}],
                defaultValue: 'class'
            }, constraints: { required: true }},
            { type: 'text', properties: {
                label: 'Value',
                name: 'implementation',
                clearable: false,
                isVisible: data => data.implementationType !== 'class',
            }, constraints: { required: true }},

            { type: 'typeahead', properties: {
                label: 'Type',
                options: [
                    { label: 'BPMN Script Task Listener', value: 'bpmn'},
                    { label: 'Node.js Script Task Listener', value: 'nodeJs' },
                ],
                name: 'type',
                valueField: 'value',
                clearable: false,
                onChange: ({ target }) => {
                    const response = [target, { name: 'script', value: null }];
                    if(target.value === 'bpmn') {
                        response.push({ name: 'implementation', value: 'org.flowable.engine.impl.bpmn.listener.ScriptTaskListener'});
                        response.push({
                            name: 'fields',
                            value: [{
                                fieldType: 'string',
                                name: 'language',
                                value: 'javascript',
                            }, 
                            {
                                fieldType: 'string',
                                name: 'script',
                                value: '// TODO implement the script',
                            }
                            ]
                        }
                        , 
                        {
                            value: '// TODO implement the script',
                            name: 'script'
                        }
                        );
                    }
                    if(target.value === 'nodeJs') {
                        response.push({ name: 'implementation', value: 'mic3.affectli.flowable.bpmn.listener.GqlScriptTaskListener'});
                        response.push({
                            name: 'fields',
                            value: []
                        });

                    }
                    return response;
                },
                isVisible: data => data.implementationType === 'class'
            }, constraints: { required: true }},
            {
                type: 'scriptTypeahead',
                properties: {
                    label: 'Script reference',
                    name: 'script',
                    valueField: 'id',
                    onChange: ({ target }) => {
                        return [
                            target, {
                                name: 'fields',
                                value: [{
                                    fieldType: 'string',
                                    name: 'script',
                                    value: target.value,
                                }]
                            }
                        ];
                    },
                    isVisible: data => data.implementationType === 'class' && data.type === 'nodeJs'
                },
                constraints: { required: true }
            }
        ]
    }
};

export default TaskListenersBuilder;
