import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import {
    Typography, Divider, Grid, MdiIcon, TextField, Chip, MdiIconSelect, Tooltip, AvatarEditor,
    IconButton, Avatar, ColorPicker, CardMedia
} from '@mic3/platform-ui';

import EntityLink from 'app/components/atoms/Link/EntityLink';
import ExpansionCard from 'app/components/molecules/ExpansionCard/ExpansionCard';
import Ellipsis from 'app/components/molecules/Ellipsis/Ellipsis';
import { fromNow, displayByKind } from 'app/utils/date/date';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { loadEntityWorkspaces } from 'store/actions/entities/entitiesActions';
import { getAttachmentUrl } from 'app/utils/attachments/attachmentsUtils';
import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import DotMenu from 'app/components/molecules/DotMenu/DotMenu';
import AddClassificationModal from 'app/containers/Common/ClassificationsTab/AddClassificationModal';
import { loadAllPrimaryClasses } from 'store/actions/app/appActions';


const CardImage = styled(CardMedia)`
    && {
        width: 200px;
        height: 200px;
    }
    ${({ type }) => ['user', 'person'].includes(type) && 'border-radius: 50%;'}
`;

const DividerStyled = styled(Divider)`
    margin: 12px 0 32px 0 !important;
`;
const DividerverticalStyled = styled(Divider)`
    margin: 4px 16px !important;
    height: 40px !important;
    align-self: center !important;
`;

const StyledColorPicker = styled(ColorPicker)`
    background-color: ${({theme})=> theme.material.colors.background.fields};
    .swatches-picker > div div:nth-child(2) {
        background: ${({theme})=> theme.material.colors.background.paper} !important;
    }
`;

const TypographyStyled = styled(Typography)`
    color: ${ ( { theme } ) => theme.material.colors.text.primary };
`;
const GridHalfStyled = styled(Grid)`
    width: calc(50% - 17px);
    margin-bottom: 24px !important;
`;
const MoreChipStyled = styled(Chip)`
    background:  ${({ theme }) => theme.material.colors.background.fields} !important;
    & .MuiChip-label {
        max-width: 140px;
    }
`;
const TeamChipStyled = styled(Chip)`
    background:  ${({ theme }) => theme.material.colors.background.fields} !important;
`;
const AvatarStyled = styled(Avatar)`
    width: 30px !important;
    height: 30px !important;
    margin-right: 8px;
    font-size: 1rem !important;
`;

const AvatarEditorWrapper = styled('div')`
    & .material-icons.MuiIcon-root {
        line-height: 8px !important;
    }
`;

const hasAttribute = (details, path) => details[path] !== undefined;

const GraphicLibraryModal = ({ onClose, onChange }: Object) => (
    <ModalDialog onClose={onClose}>
        <FormGenerator
            components={[
                {
                    field: 'graphic',
                    type: 'graphicTypeahead',
                    properties: {
                        label: 'SVG',
                        name: 'svg'
                    }
                }
            ]}
            data={{}}
            onChange={(event) => {
                onChange(event);
                onClose();
            }}
        />
    </ModalDialog>
);

class AboutSection extends PureComponent {

    state = { showModal: false, isAppliesToModalOpen: false }

    componentDidUpdate(prevProps) {

        const { id } = this.props.details || {};
       
        if( id && id !== prevProps.details.id ) {
            this.reloadSharing();
        }
    }

    componentDidMount() {
        this.reloadSharing();
        this.loadAllPrimaryClasses();
    }
    
    @bind
    loadAllPrimaryClasses(){
        const { type } = this.props.details || {};
        if (type === 'print-template') {
            this.props.loadAllPrimaryClasses();
        }
    }
    
    @bind
    reloadSharing() {
        const { loadEntityWorkspaces, details } = this.props;
        loadEntityWorkspaces(details.type, details.id);
    }

    @bind
    toggleModal() {
        this.setState(prevState => ({ showModal: !prevState.showModal }));
    }
    
    @bind
    handleAppliesToModal(e) {
        if(e) {
            e.preventDefault();
            e.stopPropagation();
        }
        this.setState(state => ({ isAppliesToModalOpen: !state.isAppliesToModalOpen }));
    }
    
    @bind
    onChangeAppliesTo(applicableOn) {
        this.props.handleChange({ target: {
            name: 'primary.print-template/applicable_on',
            value: applicableOn?.map(({ id }) => id).filter(Boolean),
        }});
    }
    
    @bind
    renderDotMenu() {
        const moreMenu = [
            {
                name: 'Download',
                icon: '',
                onItemClick: props => this.props.setImage(null) // WIP
            },
            {
                name: 'Delete ',
                icon: '',
                onItemClick: props => this.props.setImage(null) // WIP
            }
        ];
        return <DotMenu items={moreMenu} />;
    }
    
    @bind
    openAppliesToSidebar(){
        this.props.openSidebar('Applies To');
    }
    
    @bind
    @memoize()
    buildSelectedClasses(details, entityTypes){
        return details?.primary?.['print-template/applicable_on']?.map(id => entityTypes?.find(cls => cls?.id === id)).filter(Boolean);
    }
    
    @bind
    @memoize()
    getExcluded(details) {
        return [...(details?.primary['print-template/applicable_on'] || [])]?.map(id => id);
    }

    
    render() {
        const { sharingDetails, handleChange, details, openSidebar, entityTypes, expanded, primaryClass, disabled, entityType } = this.props;
        const { id, type, image, description, iconColor, modifiedBy, modifiedDate, createdBy, createdDate, imagePrev } = details || {};
        const { users = [], teams = [] } = sharingDetails || {};
        const { showModal, isAppliesToModalOpen } = this.state;
        const isClass = details.type === 'class';
        const isUser = ['user', 'person'].includes(details.type);
        const applicableCount = (details?.primary?.applicable_on || []).length - isMobile ? 3 : 6;
        const applicableOn = this.buildSelectedClasses(details, entityTypes);
        const listEntityTabs = primaryClass?.entityTabs?.list;
        return (
            <ExpansionCard
                expanded={expanded}
                collapsible
                title="About"
            >
                {!['backgroundjob'].includes(type) ? (
                    <>
                        <Grid container justify="space-between">
                            {modifiedBy && (
                                <>
                                    <GridHalfStyled>
                                        <TypographyStyled variant="caption">Last modified</TypographyStyled>
                                        <Tooltip title={displayByKind('datetime', modifiedDate)} placement="left">
                                            <TypographyStyled>
                                                {fromNow(modifiedDate)}
                                                {' '}by{' '}
                                                <EntityLink id={modifiedBy.id} type="user" noDecoration>{modifiedBy.name}</EntityLink>
                                            </TypographyStyled>
                                        </Tooltip>
                                    </GridHalfStyled>
                                    <DividerverticalStyled orientation="vertical" flexItem />
                                </>
                            )}
                            {createdDate && (
                                <GridHalfStyled>
                                    <TypographyStyled variant="caption">Created</TypographyStyled>
                                    <Tooltip title={displayByKind('datetime', createdDate)} placement="left">
                                        <TypographyStyled>
                                            {fromNow(createdDate)}
                                            {' '}by{' '}
                                            <EntityLink id={createdBy.id} type="user" noDecoration>{createdBy.name}</EntityLink>
                                        </TypographyStyled>
                                    </Tooltip>
                                </GridHalfStyled>
                            )}
                        </Grid>
                        <DividerStyled />
                    </>
                ): null}
    

                {hasAttribute(details, 'description') ? (
                    <>
                       
                        <TextField
                            label="Description"
                            onChange={handleChange}
                            value={description}
                            name="description"
                            rows="5"
                            multiline
                            disabled={disabled || type === 'backgroundjob'}
                        />
                       
                        <DividerStyled />
                    </>
                ) : null}
                {!['backgroundjob', 'print-template', 'iot_connector', 'iot_module', 'formdefinition'].includes(type) ? (
                    <>
                        <Typography variant="button">Appearance</Typography>
                        <Grid container alignItems="center" justify="space-between" wrap="nowrap">
                            
                            <MdiIconSelect
                                value={details?.iconName || isClass || entityType ? details.iconState: null}
                                onChange={handleChange}
                                name={'iconState'}
                                label="Icon"
                                clearable={!isClass}
                                disabled={disabled}
                                placeholder={`Icon inherited from entity type ${primaryClass?.name}`}
                            />
                         
                            <DividerverticalStyled orientation="vertical" flexItem />
                            
                            <StyledColorPicker
                                onChange={handleChange}
                                value={isClass ? details.color : iconColor}
                                name={isClass ? 'color' : 'iconColor'}
                                label="Icon color"
                                disabled={disabled}
                            />
                            
                        </Grid>
                    </>
                ) : null}
                {
                    !['class', 'backgroundjob', 'print-template', 'formdefinition'].includes(type) ?
                        (
                            <>
                                <Grid container alignItems="center" justify="space-between" wrap="nowrap">
                                    <div>
                                        {image ?
                                            <>
                                                <Typography variant="caption">Image</Typography>
                                                <CardImage type={type} image={imagePrev ? imagePrev : getAttachmentUrl(id, type, image)} />
                                            </>
                                            : null }

                                        <>
                                            <br/>
                                            {/*<Button variant="text" disabled={!editable} onClick={this.toggleModal}>
                                        <MdiIcon name="google-photos" />
                                        ADD FROM LIBRARY
                                    </Button>*/}
                                            <AvatarEditorWrapper>
                                                <AvatarEditor
                                                    isButton
                                                    label="Upload"
                                                    name="image"
                                                    onChange={handleChange}
                                                    disabled={disabled}
                                                    ReactAvatarEditorProps={isUser ? { border: 1, borderRadius: 100, } : {}}
                                                    EditButtonProps={{ className: '', disabled: disabled }}
                                                    EditorProps={{ uploadLabel: 'CROP' }}

                                                />
                                            </AvatarEditorWrapper>
                                        </>
                                        {showModal && <GraphicLibraryModal onClose={this.toggleModal} onChange={handleChange} />}
                                    </div>
                                </Grid>
                                <DividerStyled />
                            </>
                        ) : null
                }
                { (listEntityTabs?.includes('Share') || type === 'class') || (!listEntityTabs && type !== 'class')  ? // (!listEntityTabs && type !== 'class') for newly created entity types as their entityTabs are "null"
                    <> 
                        <Typography variant="button">Sharing</Typography>
                        <Grid container alignItems="center">
                            <IconButton disabled={disabled} onClick={() => openSidebar('Sharing')}><MdiIcon name="plus"/></IconButton>
                            <Ellipsis
                                data={users}
                                displaySize={isMobile ? 3 : 6}
                                renderCount={count => (
                                    <MoreChipStyled
                                        variant="default"
                                        label={`+${count}`}
                                        size="small"
                                        onClick={() => openSidebar('Sharing')}
                                        disabled={disabled}
                                    />
                                )}
                                renderItem={
                                    ({ name, id, image }: Object) => {
                                        const avatarImg = image ? getAttachmentUrl(id, 'user', image): null;
                                        return (
                                            <AvatarStyled disabled={disabled} initials={name} src={avatarImg}/>
                                        );
                                    }
                                }
                            />
                            <DividerverticalStyled orientation="vertical" flexItem />
                            <Ellipsis
                                data={teams}
                                displaySize={isMobile ? 2 : 5}
                                renderCount={count => (
                                    <MoreChipStyled
                                        variant="default"
                                        label={`+${count}`}
                                        size="small"
                                        onClick={() => openSidebar('Sharing')}
                                        disabled={disabled}
                                    />
                                )}
                                renderItem={
                                    ({ name }: Object) => (
                                        <TeamChipStyled disabled={disabled} label={name} />
                                    )
                                }
                            />
                        </Grid>
                        <DividerStyled />
                    </> 
                    : null}
                {['print-template'].includes(type) ? (
                    <>
                        <Typography variant="button">Applies to</Typography>
                        <Grid container alignItems="center">
                            <IconButton disabled={disabled} onClick={this.openAppliesToSidebar}><MdiIcon name="plus"/></IconButton>
                            <Ellipsis
                                data={applicableOn}
                                displaySize={isMobile ? 3 : 6}
                                renderCount={count => (
                                    <MoreChipStyled
                                        variant="default"
                                        label={`+${applicableCount}`}
                                        size="small"
                                        disabled={disabled}
                                        onClick={this.openAppliesToSidebar}
                                    />
                                )}
                                renderItem={
                                    (data: Object) => (
                                        <TeamChipStyled disabled={disabled} onClick={this.openAppliesToSidebar} label={data?.name} />
                                    )
                                }
                            />
                            <AddClassificationModal
                                title="Applies to"
                                entityType="entityType"
                                selectedClasses={applicableOn}
                                excluded={this.getExcluded(details)}
                                filterBy={[{ field: 'primary', op: '=', value: true}]}
                                isOpen={isAppliesToModalOpen}
                                onSubmit={this.onChangeAppliesTo}
                                closeModal={this.handleAppliesToModal}
                                // extraRecords={[{ uri: 'workspace', name: 'Workspaces' }, { uri: 'relationdefinition', name: 'Relation Definitions' }]}
                            />
                        </Grid>
                        <DividerStyled />
                    </>
                ) : null}

            </ExpansionCard>
        );
    };
}

export default connect(
    state => ({
        sharingDetails: state.entities.sidebar.workspaces.data,
        sharingisLoading: state.entities.sidebar.workspaces.isLoading,
        entityTypes: state.app.allPrimaryClasses.records
    }),
    {
        loadEntityWorkspaces,
        loadAllPrimaryClasses
    }
)(AboutSection);
