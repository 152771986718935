// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';

import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import Container from 'app/components/atoms/Container/Container';
import LayerList from 'app/components/molecules/Map/LayerList/LayerList';
import LayerListItem from 'app/components/molecules/Map/LayerList/LayerListItem';
import LayerListItemActions from 'app/components/molecules/Map/LayerList/LayerListItemActions';
import LayersActions from 'app/components/organisms/Maps/Actions/LayersActions';
import ListItem from 'app/components/molecules/List/ListItem';
import { bind } from 'app/utils/decorators/decoratorUtils';
import { Autocomplete, MdiIcon } from '@mic3/platform-ui';
import { get } from 'app/utils/lo/lo';
import { updateDrawingLayer } from 'app/components/molecules/Map/DrawShapes/DrawDrawingLayer';

import { closeSidebar, setActions } from 'store/actions/sidebar/sidebarActions';
import { deleteRelationship } from 'store/actions/entities/relationshipsActions';
import { setMapHasDrawing, setSelectedLayer, toggleAddPinBtn, updateMapData, updateLayerAttribute, updateBaseLayer, setLayerLoading } from 'store/actions/maps/situationalAwarenessActions';
import { openLayerSidebar } from 'store/actions/maps/layerSidebarActions.js';
import { loadEntitiesTypeahead } from 'store/actions/entities/entitiesActions';
import { getStr } from 'app/utils/utils';
import RemoveLayerModal from 'app/containers/Maps/Drawers/RemoveLayerModal';
import { getPermissions } from 'app/config/rolesConfig';
import { __getMapLayers, BASELAYER_PRIMARY_KEY, removeExtentThumbnail } from 'app/utils/maps/layer/layerUtils';

const BASE_LAYERS = [{ label: 'Bing (Aerial)' }, { label: 'Bing (Roads)' }, { label: 'OSM' }];

const BaseLayerDropdown = styled(Autocomplete)`
    .MuiInputLabel-filled.MuiInputLabel-shrink {
        transform: translate(0px, 5px) scale(0.75) !important;
    }

    .MuiFilledInput-input {
        padding-left: 0px !important;
    }

    .MuiFilledInput-root {
        background: inherit !important;
    }

    &.MuiFormControl-marginNormal {
        margin: 0px !important;
    }
`;

const BaseLayerListItem = styled(ListItem)`
    padding: 0px;
    margin: 1rem 0;
    background: ${({ theme }) => theme.material.colors.background.navigation};
`;

class MapLayersList extends PureComponent<Object, Object> {
    static propTypes = {
        map: PropTypes.object.isRequired,
        mapId: PropTypes.string.isRequired
    };

    defaultActions = (
        <LayersActions onSave={this.saveMapData} />
    );

    layersList = [];

    constructor(props: Object) {
        super(props);

        this.state = {
            layer: null,
            expandedItems: {}
        };
        const { mapUserRole } = this.props || {};
        const { canEdit } = getPermissions(mapUserRole);
        if(canEdit)
            props.setActions(this.defaultActions);
    }

    componentDidUpdate(prevProps) {
        const { selectedLayer, map, hasDrawing } = this.props;
        // const { role } = mapData || {};
        // const { canEdit } = getPermissions(role);

        const isErase = map?.getEraserStatus();
        if (selectedLayer?.type === 'Entity Layer') 
            this.props.toggleAddPinBtn(true, false);
        else if(selectedLayer) 
            this.props.toggleAddPinBtn(false, isErase);   

        if (prevProps.hasDrawing !== hasDrawing || prevProps.selectedLayer !== selectedLayer) {
            if (hasDrawing && selectedLayer?.type === 'Drawing Layer') 
                this.props.setActions(this.drawingActions());
            else
                this.props.setActions(this.defaultActions);
        }

        // if (canEdit && get(selectedLayer, 'type') !== 'Drawing Layer' || (prevProps.hasDrawing !== hasDrawing && !hasDrawing)) {
        //     setActions(this.defaultActions);
        // }
    }

    @bind
    drawingActions() {
        return ( <LayersActions onSave={this.saveMapData} /> );
    }

    @bind
    async updateDrawing(data) {
        const { map, selectedLayer } = this.props;
        const { primary, ..._attributes } = data || {};
        await map.addEntityLayer({
            attributes:_attributes,
            id: selectedLayer?.id,
            type: 'drawing-layer',
            primary,
            styleType: 'drawing'
        });
        return Promise.resolve();
    }

    @bind
    async saveMapData() {
        const { updateMapData, hasDrawing, selectedLayer, setMapHasDrawing, allMapLayers, mapId, mapData } = this.props;
        if(hasDrawing && selectedLayer?.type === 'Drawing Layer') {
            await updateDrawingLayer({ map: this.props.map, selectedLayer, updateMapData, allMapLayers, mapId });
            setMapHasDrawing(false);
        } else {
            this.props.updateMapData(removeExtentThumbnail(mapData), true);
        }
    }
    
    @bind
    onOpacityChange(layerId, event: Event) {
        const { value } = event.target;
        const { selectedLayer: sidebarLayer, mapData, map } = this.props;
        this.props.toggleAddPinBtn(false);
        const item = map.findLayerById(layerId);
        const layers = __getMapLayers(mapData);
        if (layers?.length) {
            layers.forEach((lyr) => {
                if (lyr.id === item?.values_?.attributes?.id) {
                    const entityLayer = map.getEntityLayer(lyr?.id);
                    if (entityLayer?.dataSource3D) {
                        const entities = entityLayer?.dataSource3D?.entities?._entities?._array;
                        entities?.length && entities.forEach((bil) => {
                            const Cesium = map.getCesiumObj();
                            bil.billboard.color = new Cesium.Color(1.0,1.0,1.0, value);
                        });
                    }
                    entityLayer && entityLayer.relatedEntities.length && entityLayer.relatedEntities[0].getLayer().setOpacity(value);
                    entityLayer?.heatMapLayers?.length > 0 &&  entityLayer?.heatMapLayers?.forEach(lyr => lyr.getLayer().setOpacity(value));

                    if(sidebarLayer?.id  === item?.values_?.attributes?.id) {
                        const highlightLayer = map.findHighLightLayer();
                        highlightLayer && highlightLayer.setOpacity(value);
                    }
                    entityLayer?.getLayer()?.setOpacity(value);
                }
                return lyr;
            });
        }
        this.props.updateLayerAttribute({ id: layerId }, 'opacity', value);
        map.onOpacityChange(item, value);
    }

    @bind
    async toggleMapLayer(event: Event, layerGroupName: string, title: string, visible: boolean, name: string, id: string) {
        event && event.stopPropagation();
        const { map } = this.props;
        if (layerGroupName === 'baseLayers' && title) {
            const item = map?.findLayer(layerGroupName, title);
            map && map.toggleMapLayer(item, true);
            this.props.updateBaseLayer(title);
            return;
        }
        const item = map?.findLayerById(id);
        const updatedLayer = this.props.updateLayerAttribute({ id, title }, 'visible');
        const entityLayer = map.getEntityLayer(id);
        if(entityLayer?.heatMapLayers?.length) entityLayer?.heatMapLayers?.forEach(lyr => lyr.getLayer()?.setVisible(visible));
        if(entityLayer?.relatedEntities?.length) entityLayer?.relatedEntities?.forEach(lyr => lyr.getLayer()?.setVisible(visible));
        entityLayer?.getLayer()?.setVisible(visible);
        if(updatedLayer?.visible && !['drawing', 'drawing-layer', 'replay-layer', 'replay', 'wms', 'system_cesium_layer', 'cesium'].includes(updatedLayer?.type)){
            this.props.setLayerLoading(updatedLayer?.id || id);
        }
        const findRelatedMapLayer = map.getMap()?.getLayers()?.array_?.find(lyr => {
            return lyr?.values_?.attributes?.id?.split('-r0')?.[0] === id;
        });   
        findRelatedMapLayer?.setVisible(visible);
        map && updatedLayer && map.toggleMapLayer(item, false, updatedLayer.visible, visible);
    }

    @bind
    handleListItemExpansion(event: Event, layerGroupName: string, layerTitle: string) {
        event.stopPropagation();
        this.setState(prevState => ({ expandedItems: { ...prevState.expandedItems, [layerTitle]: !prevState.expandedItems[layerTitle] }}));
    }

    @bind
    openLayerSidebar(props: Object) {
        this.props.openLayerSidebar({ ...props });
    }

    @bind
    renderLayerListItemActions(layer: Object, item: Object) {

        const { map, mapId, mapUserRole } = this.props;
        const { canEdit } = getPermissions(mapUserRole);

        return (
            <LayerListItemActions
                map={map}
                item={item}
                mapId={mapId}
                layer={layer}
                canEdit={canEdit}
                onOpacityChange={this.onOpacityChange}
                onLayerRemove={this.openModal}
                toggleMapLayer={this.toggleMapLayer}
                openLayerSidebar={this.openLayerSidebar}
                entityTypes={this.props.entityTypes}
            />
        );
    }

    @bind
    openModal(layer) {
        this.setState({ layer });
    }

    @bind
    closeModal() {
        this.setState({ layer: null });
    }

    render() {
        const { map, mapId, layerDrawerKey, mapData } = this.props;
        const { layer, expandedItems } = this.state;
        const baseLayer = getStr(mapData, BASELAYER_PRIMARY_KEY);
        const baseLayeLabel = baseLayer === 'Toner' ? 'Bing (Aerial)' : baseLayer;
                        
        return (
            <ContentArea>
                <Container key={layerDrawerKey}>
                    <LayerList
                        map={map}
                        mapId={mapId}
                        mapData={mapData}
                        allMapLayers={this.props.allMapLayers}
                        expandedItems={expandedItems}
                    >
                        {(item, index, filteredFeaturesMap) => (
                            <LayerListItem
                                item={item}
                                mapId={mapId}
                                index={index}
                                key={index}
                                map={map}
                                features={filteredFeaturesMap[item?.id] || []}
                                handleListItemExpansion={this.handleListItemExpansion}
                                actions={this.renderLayerListItemActions}
                            />
                        )}
                    </LayerList>
                    <BaseLayerListItem
                        component={<MdiIcon name="earth" />}
                        title={
                            <BaseLayerDropdown
                                label="BASE LAYER"
                                options={BASE_LAYERS}
                                value={{ label: baseLayeLabel }}
                                clearable={false}
                                InputProps={{
                                    disableUnderline: true
                                }}
                                onChange={({ target: { value } }) => this.toggleMapLayer(null,'baseLayers', value.label)}
                            />
                        }
                    />
                    {layer && (
                        <RemoveLayerModal
                            map={map}
                            mapId={mapId}
                            layer={layer}
                            closeModal={this.closeModal}
                        />
                    )}
                </Container>
            </ContentArea>
        );
    }
}

export default connect(
    (state, props) => ({
        preferences: state.user.preferences,
        mapUserRole: get(state.maps, 'situationalAwareness.map.data.role', ''),
        hasDrawing: state.maps.situationalAwareness.drawing.hasDrawing,
        selectedLayer: state.maps.situationalAwareness.layer.selectedLayer,
        allMapLayers: __getMapLayers(state.maps.situationalAwareness.map.data),
        mapData: get(state.maps, 'situationalAwareness.map.data', {}),
        entityTypes: state.app.allPrimaryClasses.records || [],
        layerDrawerKey: state.maps.situationalAwareness.layer.layerDrawerKey
    }),
    {
        setActions,
        closeSidebar,
        deleteRelationship,
        openLayerSidebar,
        updateMapData,
        setSelectedLayer,
        setMapHasDrawing,
        loadEntitiesTypeahead,
        toggleAddPinBtn,
        updateLayerAttribute,
        updateBaseLayer,
        setLayerLoading
    }
)(withTheme(MapLayersList));
