import React, { useEffect, useState } from 'react';
import { Menu, MenuItem } from '@mic3/platform-ui';

import { useToggle } from 'app/utils/hook/hooks';

export default function PipelineContextMenu({ target }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, toggle] = useToggle();

    useEffect(() => {
        if(target) {
            setAnchorEl(target);
            toggle();
        }
    }, [target]); // eslint-disable-line

    const handleClose = () => {
        toggle();
    };

    return (
        <Menu
            open={open}
            anchorEl={anchorEl}
            onClose={toggle}
            hideBackdrop
            disableScrollLock
        >
            <MenuItem onClick={handleClose}>Option 1</MenuItem>
            <MenuItem onClick={handleClose}>Option 2</MenuItem>
            <MenuItem onClick={handleClose}>Option 3</MenuItem>
        </Menu>
    );
}
