import styled from 'styled-components';
import Filters from 'app/components/organisms/Filters/Filters';
import { Button } from '@mic3/platform-ui';
import Icon from 'app/components/atoms/Icon/Icon';
import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import Text from 'app/components/atoms/Text/Text';

export const StyledContentArea = styled(ContentArea)`
    margin-top: 5px;
`;

export const NoSelectedBoard = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 60px;
    font-weight: 200;
    color:  ${({theme})=> theme.material.colors.text.caption};
    @media (max-width:481px){
        font-size: 24px;
    }
`;

export const IconStyled = styled(Icon)`
    margin-right: 30px;
    @media (max-width:481px){
        margin-right: 12px;
    }
    &:before {
        font-size: 85px !important;
        color: ${({theme})=> theme.material.colors.text.caption};
        opacity: 0.5px;
        @media (max-width:481px){
            font-size: 48px !important;
        }
    }
`;

export const StyledButton = styled(Button)`
    padding: 12px !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    min-width:200px !important;
`;


export const StyledInfo = styled.div`
     color:  ${({theme})=> theme.material.colors.text.caption};
     flex-direction:row !important;
     alignItems:center;
     letter-spacing: 0.75px;
     font-weight: 500;
     font-size: 14px;
     text-transform: uppercase;
`;

export const InfoIcon = styled(Icon)`
  &:before {
    color: ${({theme})=> theme.material.colors.text.caption};
    opacity: 0.5px;
  }
    margin-right:5px;
`;


export const FiltersBoard = styled(Filters)`
    min-height: 0;
    padding: 0 8px;

    .filter-toolbar {
        > .MuiGrid-grid-xs-true,
        > .MuiIconButton-root {
            margin-top: 8px;
        }
    }

    .page-content {
        height: 100%;
    }
    max-height:100% important!;
`;


export const BoardsLandingSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    ${Text} {
        font-size: 14px;
        color: #818282;
    }
`;
