/* @flow */

import { gql } from '@apollo/client';

export default gql`
    mutation createRelationMutation($entity: EntityReferenceInput!, $relatedEntity: EntityReferenceInput!, $relationDefinition: ID!, $attributes: JSON) {
        payload: entityRelationAdd(entity: $entity, relatedEntity: $relatedEntity, relationDefinition: $relationDefinition, attributes: $attributes) {
            relation {
                id
            }
        }
    }
`;
