/* @flow */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';
import { connect } from 'react-redux';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { loadAvatar as loadEntitiesAvatar } from 'store/actions/entities/entitiesActions';
import { loadAvatar as loadTeamsAvatar } from 'store/actions/admin/teamsActions';
import { loadAvatar as loadWorkspacesAvatar } from 'store/actions/admin/workspacesActions';
import { loadAvatar as loadBroadcastsAvatar } from 'store/actions/broadcasts/broadcastsActions';
import { loadAvatar as loadClassesAvatar } from 'store/actions/classifications/classificationsActions';
import { loadAvatar as loadScriptsAvatar } from 'store/actions/designer/designerScriptActions';
import { loadUserReferences } from 'store/actions/admin/usersActions';
import { get, set } from 'app/utils/lo/lo';
import { shallowEquals } from 'app/utils/utils';

/**
 * Select one or more groups using lazy loading.
 */
class ResolveAvatarTypeaheadWrapper extends PureComponent<Object, Object> {

    static propTypes = {
        Typeahead: PropTypes.elementType.isRequired,
        entityType: PropTypes.string.isRequired,
    }

    constructor(props) {
        super(props);
        this.onChangeNameCache = {};
    }

    @bind
    getAvatarValue(value) {
        if (this.onChangeNameCache[value]) {
            return { id: value, ...(this.onChangeNameCache[value] || {}) };
        }
        const {
            avatarScriptsMap, avatarEntitesMap, loadBroadcastsAvatar, loadEntitiesAvatar, loadTeamsAvatar,
            loadScriptsAvatar, entityType, avatarTeamsMap, loadWorkspacesAvatar, avatarWorkspacesMap, avatarBroadcastsMap,
            loadClassesAvatar, avatarClassesMap, loadUserReferences, userReferences
        } = this.props;
        let avatar = null;
        if(entityType === 'team') {
            loadTeamsAvatar(value);
            avatar = avatarTeamsMap[value];
        }
        else if(entityType === 'workspace') {
            loadWorkspacesAvatar(value);
            avatar = avatarWorkspacesMap[value];
        }
        else if(entityType === 'broadcast') {
            loadBroadcastsAvatar(value);
            avatar = avatarBroadcastsMap[value];
        } else if(entityType === 'class') {
            loadClassesAvatar(value);
            avatar = avatarClassesMap[value];
        } else if(entityType === 'script') {
            loadScriptsAvatar(value);
            avatar = avatarScriptsMap[value];
        } else if(entityType === 'user') {
            avatar = (userReferences || []).find(usr => usr.id === value);
            if(userReferences?.length > 0 && !avatar){
                return {id: value, name: 'Not found'};
            }
            if(!avatar) {
                loadUserReferences();
            }
        } else {
            avatar = avatarEntitesMap[value];
            if(!avatar) {
                loadEntitiesAvatar(value, entityType);
            }
        }
        if (avatar && avatar.id) {
            const { id, name, image, message } = avatar;
            return { id, name, image, message };
        }
        return { id: value, name: 'loading...' };
    }

    @bind
    @memoize(shallowEquals)
    normalizeValueAndValueField(props) {
        const { value, valueField } = this.props;
        if (valueField === 'id') {
            if (!value) {
                return { value, valueField: null };
            } if (Array.isArray(value)) {
                return { value: value.map(this.getAvatarValue), valueField: null };
            } else {
                return { value: this.getAvatarValue(value), valueField: null };
            }
        }
        return { value, valueField };
    }

    @bind
    onChange(event) {
        const { onChange, valueField } = this.props;
        if (valueField === 'id') {
            const value = event.target.value;
            let v;
            if (Array.isArray(value)) {
                v = value.map(({ id }) => id);
                value.forEach((val) => {
                    this.onChangeNameCache[val.id] = val;
                });
            } else {
                v = get(value, 'id', null);
                this.onChangeNameCache[get(value, 'id')] = value;
            }
            const e = set(event, 'target.value', v);
            onChange && onChange(e);
        } else {
            onChange && onChange(event);
        }
    }


    render() {
        const {
            loadEntitiesAvatar, avatarEntitesMap, avatarTeamsMap, Typeahead, loadTeamsAvatar, avatarBroadcastsMap,
            entityType, loadWorkspacesAvatar, avatarWorkspacesMap, populateType, loadBroadcastsAvatar,
            avatarClassesMap, loadClassesAvatar, loadScriptsAvatar, avatarScriptsMap, userReferences, loadUserReferences,
            ...typeaheadProps
        } = this.props;
        if(populateType) {
            typeaheadProps.entityType = entityType;
        }
        return (
            <Typeahead
                {...typeaheadProps}
                {...this.normalizeValueAndValueField(this.props)}
                onChange={this.onChange}
            />
        );
    }
};

const recordsToMap = memoizeOne((records) => records.reduce((accum, rec, i) => {
    if(rec.id) {
        accum[rec.id] = rec;
    } 
    return accum; 
}, {}));

export default connect(
    state => ({
        avatarEntitesMap: state.entities.avatar,
        avatarTeamsMap: recordsToMap(state.admin.teams.autocomplete.records),
        avatarWorkspacesMap: state.admin.workspaces.avatar,
        avatarBroadcastsMap: state.broadcasts.avatar,
        avatarClassesMap: state.classifications.avatar,
        avatarScriptsMap: state.designer.scriptAvatar,
        userReferences: state.admin.users.references.data,
    }),
    { loadUserReferences, loadScriptsAvatar, loadEntitiesAvatar, loadBroadcastsAvatar, loadTeamsAvatar, loadWorkspacesAvatar, loadClassesAvatar }
)(ResolveAvatarTypeaheadWrapper);
