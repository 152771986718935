/* @flow */

import React from 'react';
import { Button as PuiButton } from '@mic3/platform-ui';
import styled from 'styled-components';

import history from 'store/History';

const PuiButtonStyled = styled(PuiButton)`
    margin: 8px 0 !important;
    text-align: center;
    text-decoration: none;
    &:hover {
        text-decoration: none !important;
    }
    &:before {
        color: ${({theme})=> theme.material.colors.text.button};
    }
`;


const PrintButton = ({
    entityId,
    entity,
    templateId,
    ...buttonProps
}: Object) => {
    const handleClick = () => {
        history.push(`/print/${templateId}/${entity?.uri || entity?.type}/${entityId || entity?.id}`);
    };
    return <PuiButtonStyled {...buttonProps} onClick={handleClick} />;
};

export default PrintButton;
