/* @flow */
import { set, get } from 'app/utils/lo/lo';
import { isIsoDate } from 'app/utils/date/date';

const classificaitonAttributeFields = {
    hyperlink: 'Hyperlink',
    typeahead: 'Enumeration',
    thingTypeahead: 'Things',
    personTypeahead: 'People',
    customEntityTypeahead: 'Custom Entity',
    organisationTypeahead: 'Organisations',
    classificationTypeahead: 'Classes',
    graphicTypeahead: 'Graphic',
    userTypeahead: 'User',
    relationsTypeahead: 'Relation',
    teamTypeahead: 'Team',
    workspaceTypeahead: 'Workspace',
    iconSelect: 'Icon',
    uploadFile: 'Upload',
};

const bmpnVariablesToObject = (bpmnVariables: ?Array<Object>, flatNames = true, expandLongString = false): Object => {
    if (!bpmnVariables || !Array.isArray(bpmnVariables)) {
        return {};
    }
    const setValue = flatNames
        ? (variables, name, value) => {
            variables[name] = value;
            return variables;
        }
        : (variables, name, value) => set(variables, name, value);

    return bpmnVariables.reduce((variables, variable) => {
        if (!variable) {
            return variables;
        }
        const { type, name, text, long, double, bytearrayId, _text } = variable;
        switch (type) {
            case 'boolean':
                variables = setValue(variables, name, long && !!long);
                break;
            case 'date':
                variables = setValue(variables, name, long && new Date(long));
                break;
            case 'double':
                variables = setValue(variables, name, double);
                break;
            case 'integer':
            case 'long':
                variables = setValue(variables, name, long);
                break;
            case 'json':
                variables = setValue(variables, name, text && JSON.parse(text));
                break;
            case 'longString':
                variables = setValue(variables, name, expandLongString ? _text : bytearrayId);
                break;
            case 'serializable':
                variables = setValue(variables, name, bytearrayId);
                break;
            case 'string':
                variables = setValue(variables, name, text);
                break;
            default:
                variables = setValue(variables, name, null);
        }
        return variables;
    }, {});
};


const _inferType = (name, value) => {
    if (value === null) {
        return { name, value };
    }
    const type = typeof value;
    switch (type) {
        case 'boolean': return { name, value, type };
        case 'number': {
            if (Number.isInteger(value)) {
                return { name, value, type: 'long' };
            }
            return { name, value, type: 'double' };
        }
        case 'object': {
            throw new Error(`You need to serialize the data of the variable "${name}".`);
        }
        default: return { name, value };
    }
};

const toBpmnVariables = (data: ?Object): ?Array<Object> => {
    if (!data) {
        return null;
    }
    return Object.entries(data).map(([name, value]) => _inferType(name, value));
};

const desctructChildrens = fields => fields.map((field) => {
    if(field.children && !get(field, 'properties.serialize')) {
        return desctructChildrens(field.children);
    }
    return field;
}, []).flat(Infinity);

const getSerilizedFields = (fields) => {
    return desctructChildrens(fields)
        .filter(({ properties, type }) => {
            const { name, valueField, useFetch, serialize, multiple, parseAs, avoidSerialize } = properties || {};
            if(parseAs === 'JSON' && !avoidSerialize) {
                return true;
            }

            if (type === 'typeahead' && !valueField && !useFetch && !multiple) {
                return false;
            }
            if (type === 'time') {
                return false;
            }
            return classificaitonAttributeFields[type] || (serialize && name);
        })
        .map(({ properties }) => properties.name)
        .filter(Boolean);
};

const getSerilizedAttributes = (fields) => {
    return desctructChildrens(fields)
        .filter(({ properties, type }) => {
            const { parseAs, avoidSerialize } = properties || {};
            if(parseAs === 'JSON' && !avoidSerialize) {
                return true;
            }
            return false;
        })
        .map(({ properties }) => properties.name)
        .filter(Boolean);
};

const serializeAttributes = (fields, variables) => {
    return serializeVariables(fields, variables, true);
};

const deserializeAttributes = (fields, variables) => {
    return deserializeVariables(fields, variables, true);
};

const serializeVariables = (fields, variables, isClass = false) => {
    let vars = { ...variables };
    const parseFunc = isClass ? getSerilizedAttributes : getSerilizedFields; 
    parseFunc(fields)
        .forEach((field) => {
            const value = get(vars, field);
            if (value && typeof value === 'object') {
                vars = set(vars, field, JSON.stringify(value));
            }
            if (value && typeof value === 'string') {
                vars = set(vars, field, `${value}`);
            }
        });
    return vars;
};

const deserializeVariables = (fields, variables, isClass = false) => {
    const vars = { ...variables };
    const parseFunc = isClass ? getSerilizedAttributes : getSerilizedFields; 
    parseFunc(fields)
        .forEach((field) => {
            const value = vars[field];
            if (value) {
                if (isIsoDate(value)) {
                    vars[field] = new Date(value);
                }
                else {
                    try {
                        vars[field] = typeof value === 'object' ? value : JSON.parse(value);
                    } catch (e) {
                        // eslint-disable-next-line no-console
                        console.warn(`The variable "${field}" contains an invalid value:`, value);
                        delete vars[field];
                    }
                }
            }
        });
    return vars;
};

export { bmpnVariablesToObject, toBpmnVariables, serializeVariables, deserializeVariables, deserializeAttributes, serializeAttributes,  };
