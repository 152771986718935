import { Control } from 'ol/control';
import React from 'react';
import ReactDOM from 'react-dom';
import MapSnackbar from 'app/containers/Maps/Components/MapSnackbar';
import Geocode from 'app/utils/maps/geocodeUtils';
import ControlButton from 'app/containers/Maps/Components/ControlButton';

const GpsControl = /*@__PURE__*/ (function (Control) {
    let options = {};

    class GpsControl extends Control {
        constructor(opt_options) {
            super({
                element: document.createElement('div'),
                target: opt_options.target,
            });

            options = opt_options || {};

            const innerButton = (
                <ControlButton key={13} title="Zoom to your location" iconName="crosshairs-gps" />
            );

            this.element.onclick = this.handleGpsControl;
            this.element.className = 'gpsControl ol-control';
            ReactDOM.render(innerButton, this.element);
        }

        handleGpsControl() {
            Geocode.getCurrentLocation(
                ({ coords }) => {
                    const { latitude, longitude, accuracy } = coords || {};
                    options.olMapVM.setPinCenter(longitude, latitude, accuracy, true);
                },
                () => {
                    const divElem = document.createElement('div');
                    divElem.setAttribute('id', 'errorDiv');
                    ReactDOM.render(
                        <MapSnackbar
                            message={`Please enable browser location.`}
                        />,
                        divElem
                    );
                    document.body.appendChild(divElem);
                }
            );
        }
    }

    return GpsControl;
})(Control);

export default GpsControl;
