/* @flow */

/**
 * Each folder in this directory structure /reducers represents a slice
 * of the application state. Navigating this directory structure should be
 * the equivalent of navigating the app state.
 */
import { combineReducers } from 'redux';

import abox from './abox/aboxReducers';
import apiKey from './apiKeys/apiKeyReducer';
import admin from './admin/adminReducer';
import app from './app/appReducer';
import broadcasts from './broadcasts/broadcastReducer';
import classifications from './classifications/classificationsReducer';
import common from './common/commonReducer';
import component from './component/componentReducer';
import dashboard from './dashboard/dashboardReducer';
import designer from './designer/designerReducer';
import entities from './entities/entitiesReducer';
import global from './global/globalReducer';
import history from './history/historyReducer';
import maps from './maps/mapsReducer';
import routing from './routing/routerHistoryReducer';
import stream from './stream/streamReducer';
import user from './user/userReducer';
import legacy from './legacy/legacyReducer';
import chat from './chat/chatReducer';
import sidebar from './sidebar/sidebarReducer';
import filterbar from './filterbar/filterbarReducer';
import leftPanel from './leftPanel/leftPanelReducer';
import form from 'store/reducers/form/formReducer';
import filteractions from './filterbar/filteractionsReduscer';

const rootReducer = combineReducers<Object, Object>({
    abox,
    admin,
    app,
    apiKey,
    broadcasts,
    chat,
    form,
    classifications,
    common,
    component,
    dashboard,
    designer,
    entities,
    global,
    history,
    maps,
    routing,
    stream,
    user,
    legacy,
    sidebar,
    filterbar,
    leftPanel,
    filteractions
});

export default rootReducer;
