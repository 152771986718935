
export const entityLink = (id, type, path) => {
    return `/#${entityRouteLink(id, type, path)}`;
};

export const entityRouteLink = (id, type, path) => {
    const detailPath = {
        'opentask': 'abox/task',
        'closedtask': 'abox/task',
        'openprocess': 'abox/process',
        'closedprocess': 'abox/process',
        'workspace': 'workspaces',
    }[type];
    const tab = path ? `/${path}` : '';
    if (detailPath) {
        return `/${detailPath}/${id}${tab}`;
    }
    return `/entities/${type}/${id}${tab}`;
};
