/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { TextField, Divider, Grid, Box } from '@mic3/platform-ui';

import SelectionList from 'app/containers/Common/SelectionList/SelectionList';
import Loader from 'app/components/atoms/Loader/Loader';

import { addWorkspaceUsers, removeWorkspaceUsers } from 'store/actions/admin/workspacesActions';
import { loadUserWorkspaces } from 'store/actions/admin/userManagementAction';

import { bind } from 'app/utils/decorators/decoratorUtils';
import { getPermissions } from 'app/config/rolesConfig';
import Immutable from 'app/utils/immutable/Immutable';
import { isEmpty, getStr } from 'app/utils/utils';

const SearchField = styled(TextField)`
    padding: 8px 16px !important;
`;
const StyledDivider = styled(Divider)`
    margin-bottom: 15px !important;
`;
const StyledGrid = styled(Grid)`
    margin: 0 8px;
    position: sticky !important;
    top: 0;
    background: ${({theme})=> theme.material.colors.background.default} !important;
    z-index:999;
`;
const SelectionListWrapper = styled(Box)`
    .MuiExpansionPanelSummary-root.Mui-expanded{
        min-height: 0 !important;
    }
    .MuiExpansionPanel-root.Mui-expanded{
        margin-top: 0 !important;
    }
    .SelectionList__ListStyled-sc-1ocdplt-7{
        height: 100% !important;
    }
    .VirtualList__TinyVirtualListStyled-sc-kz3hth-0{
        overflow: hidden !important;
        height: calc(100% + 30px) !important;
    }
    .MuiExpansionPanelSummary-content{
        margin-top: 0 !important;
    }
`;

class UserWorkspaces extends PureComponent<Object, Object> {
    static propTypes = {
        user: PropTypes.object, // user details
        isLoading: PropTypes.bool,
        loadUserWorkspaces: PropTypes.func,
        addWorkspaceUsers: PropTypes.func,
        removeWorkspaceUsers: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = Immutable({
            searchValue: ''
        });
        this.defaultState = this.state;
    }

    @bind
    async loadUserWorkspaces() {
        const { user } = this.props;
        await this.props.loadUserWorkspaces(user?.id);
    }

    componentDidUpdate(prevProps: Object) {
        const { user: { id: prevId } = {} } = prevProps || {};
        const { user: { id: newId } = {} } = this.props || {};
        if (prevId !== newId) {
            this.loadUserWorkspaces();
        }
    }

    @bind
    setDefaultState() {
        this.setState(this.defaultState);
    }

    @bind
    onSearch(event) {
        this.setState({
            searchValue: event.target.value
        });
    }

    @bind
    searchForFrom(searchQuery, data) {
        if (!data || !searchQuery) return false;
        return ['name', 'username', 'id'].some((field) => {
            let value = getStr(data, field, '');
            if (!value) return false;
            value = value.toLowerCase();
            const query = searchQuery.toLowerCase();
            return value.includes(query);
        });
    }

    @bind
    filterSearchedData(searchQuery: string, workspaces) {
        return {
            workspacesFiltered: searchQuery ? (workspaces || []).filter(item => this.searchForFrom(searchQuery, item)) : workspaces || []
        };
    }

    @bind
    async onSubmitWorkspaces(newWorkspaces: Array<Object>) {
        const { user: { id: userId } = {}, workspaces: existingWorkspaces, addWorkspaceUsers } = this.props || {};
        if (!userId || isEmpty(newWorkspaces)) {
            return;
        }
        const addedPromises = newWorkspaces.map(async (workspace) => {
            if (!existingWorkspaces.find(t => t.id === workspace.id)) {
                await addWorkspaceUsers(workspace.id, [{ userId, role: workspace.role }]);
            }
        }, []);
        await Promise.all(addedPromises).then(() => {
            this.loadUserWorkspaces();
            this.setDefaultState();
        });
    }

    @bind
    async removeWorkspaces(selectedWorkspaceListIds: Array<Object>) {
        const { user: { id: userId } = {}, removeWorkspaceUsers } = this.props || {};
        if (!userId || isEmpty(selectedWorkspaceListIds)) {
            return;
        }
        const removePromises = selectedWorkspaceListIds.map(async (workspaceId) => {
            await removeWorkspaceUsers(workspaceId, [userId]);
        });
        await Promise.all(removePromises).then(() => {
            this.loadUserWorkspaces();
            this.setDefaultState();
        });
    }

    render() {
        const { user, workspaces, isLoading } = this.props || {};
        const { searchValue } = this.state;
        const { canEdit } = getPermissions(user.role);
        const { workspacesFiltered } = this.filterSearchedData(searchValue, workspaces);
        const filterBy=[{ field: 'active', op: '=', value: true }];

        return (
            <>
                {isLoading && <Loader absolute backdrop />}
                <StyledGrid>
                    <SearchField
                        fullWidth
                        variant='standard'
                        margin='none'
                        placeholder='Search...'
                        InputProps={{ disableUnderline: true }}
                        value={searchValue}
                        onChange={this.onSearch}
                    />
                    {[...(this.props.sidebarActions || [])]}
                    <StyledDivider />
                </StyledGrid>
                <SelectionListWrapper>
                    <SelectionList
                        filteredList={workspacesFiltered}
                        list={workspaces}
                        type='workspace'
                        onAdd={this.onSubmitWorkspaces}
                        onRemove={this.removeWorkspaces}
                        isLoading={isLoading}
                        showRoles
                        userRole={user.role}
                        disabled={!canEdit}
                        filterBy={filterBy}
                    />
                </SelectionListWrapper>
            </>
        );
    }
}

export default connect(
    state => ({
        isLoading: state.admin.users.workspaces.isLoading,
        workspaces: state.admin.users.workspaces.records,
    }),
    {
        loadUserWorkspaces,
        addWorkspaceUsers,
        removeWorkspaceUsers,
    }
)(UserWorkspaces);
