/* @flow */

import { gql } from '@apollo/client';

import { classDetailsFields } from 'graphql/entities/classifications/classificationDetailQuery';

export const classDigitalTwinAddMutation = gql`
    mutation classDigitalTwinAddMutation($id: ID!, $classDigitalTwin: ClassDigitalTwinCreateInput!) {
        result: classDigitalTwinAdd(id: $id, classDigitalTwin: $classDigitalTwin) {
            ${classDetailsFields}
        }
    }
`;

export const classDigitalTwinUpdateMutation = gql`
    mutation classDigitalTwinUpdateMutation($id: ID!, $classDigitalTwin: ClassDigitalTwinUpdateInput!) {
        result: classDigitalTwinUpdate(id: $id, classDigitalTwin: $classDigitalTwin) {
            ${classDetailsFields}
        }
    }
`;

export const classDigitalTwinRemoveMutation = gql`
    mutation classDigitalTwinRemoveMutation($id: ID!, $classDigitalTwinId: ID!) {
        result: classDigitalTwinRemove(id: $id, classDigitalTwinId: $classDigitalTwinId) {
            ${classDetailsFields}
        }
    }
`;
