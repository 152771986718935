/* @flow */

import { gql } from '@apollo/client';
import loadWorkspaceDetailsCommonQuery from 'graphql/workspaces/loadWorkspaceDetailsCommonQuery';

export default gql`
    mutation removeWorkspaceClassResourcesMutation($id: ID!, $classes: [ID]!) {
        result: workspaceRemoveClassResources(id: $id, classes: $classes) {
            ${loadWorkspaceDetailsCommonQuery}
        }
    }
`;
