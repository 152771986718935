// @flow
import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { lighten } from 'polished';
import { deepEquals } from 'app/utils/utils';
import Icon from 'app/components/atoms/Icon/Icon';
import { getCustomAction, isNewWindowNeeded } from 'app/utils/notification/notificationUtils';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';

// $FlowFixMe
import audioSrc from 'media/sounds/notification.mp3';

// $FlowFixMe
const audio = new Audio(audioSrc);

const ScrollBarStyle = css`
    &::-webkit-scrollbar
    {
      width: 0px !important;
      height: 0px !important;
    }
`;

const BarDefaults = css`
    background: #DF4C0C;
    font-size: 14px;
    color: white;
    display: flex;
    align-items: center;
    overflow: hidden;
    transition: background 0.3s ease-in-out;
    &:hover {
        background: ${lighten(0.03, '#DF4C0C')};
    }
`;

const NotificationsComponent = styled.div`
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 70%;
    @media (min-width: 240px) {
        width: 100%;
    }
    margin: 0 auto;
    z-index: 1400;
`;

const NotificationBar = styled.div`
    ${BarDefaults};
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
    background: ${({background}) =>  background};
    &:hover {
        background: ${({background})=> background ? lighten(0.03, background): lighten(0.03, '#DF4C0C')};
    }
`;

const NotificationIcon = styled(Icon)`
    padding: 0.6rem 1rem ;
    &:before{
        color: ${({theme})=> theme.material.colors.text.button}
    }
`;

const IconsWrapper = styled.div`
    margin-left: auto;
`;

const NotificationText = styled.div`
    padding: 0.5rem 0.5rem 0.5rem 0;
    overflow: hidden;
    text-overflow: ellipsis;
`;
const NotificationTextTitle = styled.div`
    padding: 0.5rem 0.5rem 0.5rem 0;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
    font-weight: 500;
`;

const NotificationToggler = styled(NotificationIcon)`
    transition: 0.5s ease-in-out;
    ${({ isOpen }) => (isOpen ? 'transform: rotate(180deg);' : 'transform: rotate(0deg);')};
`;

const NotificationListIcon = styled(NotificationIcon)`
    padding: 0.5rem;
    margin-left: auto;
`;

const NotificationsWrapper = styled.div`
    position: relative;
`;

const NotificationsListContainer = styled.div`
    ${ScrollBarStyle};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    max-height: calc(100vh - 52px);
    overflow: auto;
    z-index: 99;
    display: none;
    ${({ isOpen }) => (isOpen ? 'display:block' : 'display:none')};
`;

const NotificationListItem = styled.div`
    ${BarDefaults};
`;

const StyledStatus = styled.p`
    margin: 0 0 0.3rem 0;
    font-size: 14px;
    font-weight: 400;
`;
const StyledNotificationTextTitle = styled.div`
    padding: 0.5rem 0.5rem 0 0;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
    font-weight: 500;
`;
class NotificationsBar extends PureComponent<Object, Object> {
    static propTypes = {
        messages: PropTypes.array,
        notificationRead: PropTypes.func
    };

    state = { isNotificationsOpen: false, graphqlUpgradeFailed:false };

    componentDidMount() {
        const { messages, version, profile } = this.props;
        const { permissions } = profile || {};
        const status = version?.graphql?.upgrade?.status;
        messages > 0 && this.playAudio();
        if(status && status !== 'COMPLETED SUCCESSFULLY' && (profile?.isAdmin || permissions?.includes('admin.settings')) ){
            this.setState({ graphqlUpgradeFailed: true });
        }
    }

    componentDidUpdate(prevProps: Object) {
        if (!deepEquals(this.props.messages.map(({ id }) => id), prevProps.messages.map(({ id }) => id))) {
            this.playAudio();
        }
    }

    @bind
    toggleNotificationsList() {
        this.setState({ isNotificationsOpen: !this.state.isNotificationsOpen });
    }

    @bind
    markAsRead(event: Object, id: String) {
        event.stopPropagation();
        if (this.props.notificationRead) {
            this.props.notificationRead(id);
        }
    };

    @bind
    playAudio() {
        audio.currentTime = 0;
        audio.play().catch((error) => {
            if (error.code !== 0) {
                throw error;
            }
        });
    };

    @bind
    @memoize()
    buildNotificationHeaderBar(messages: Array<Object>) {
        if (messages && messages.length > 1) {
            return (
                <NotificationBar>
                    <NotificationIcon name="alert-circle" />
                    <NotificationTextTitle> Broadcasts ({messages.length})</NotificationTextTitle>
                    <IconsWrapper>
                        <NotificationToggler name="arrow-down" onClick={this.toggleNotificationsList} isOpen={this.state.isNotificationsOpen} />
                    </IconsWrapper>
                </NotificationBar>
            );
        }
        if (messages && messages.length === 1) {
            return (
                <NotificationBar onClick={this.handleBroadcastNavigation(messages[0])}>
                    <NotificationIcon name="alert-circle" />
                    <NotificationTextTitle>{messages[0].text}</NotificationTextTitle>
                    <IconsWrapper>
                        <NotificationListIcon name="check" onClick={event => this.markAsRead(event, messages[0].id)} />
                    </IconsWrapper>
                </NotificationBar>
            );
        }
        return null;
    }

    @bind
    handleBroadcastNavigation({ actionData, actionType }: Object) {
        if(!actionData || !actionType) {
            return () => {};
        }
        return () => {
            const url = getCustomAction(actionData, actionType);
            const isNewWindow = isNewWindowNeeded(actionType);
            if (url) {
                this.toggleNotificationsList();
                if (isNewWindow) {
                    window.open(url);
                } else {
                    window.open(url, '_self');
                }
            }
        };
    };

    @bind
    @memoize()
    buildNotificationsList(messages: Array<Object>): Array<Object> {
        return messages &&
        messages.map((message, index) => {
            return (
                <NotificationListItem
                    key={message.id}
                    isOpen={this.state.isNotificationsOpen}
                    className={setTimeout(() => 'visible', index * 100)}
                    index={index}
                    onClick={this.handleBroadcastNavigation(message)}
                >
                    <NotificationIcon name="alert-circle" />
                    <NotificationText>{message.text}</NotificationText>
                    <NotificationListIcon name="check" onClick={event => this.markAsRead(event, message.id)} />
                </NotificationListItem>
            );
        });
    }

    @bind
    @memoize()
    showGraphqlErrorBroadcast(){
        const {version} = this.props;
        return (
            <NotificationBar  background='#c62828'>
                <NotificationIcon name="alert-circle" />
                <div>
                    <StyledNotificationTextTitle>The Affectli upgrade failed ({version?.graphql?.upgrade?.currentVersion})</StyledNotificationTextTitle>
                    <StyledStatus>{version?.graphql?.upgrade?.error || `the gql-v1 upgrade status is "${version?.graphql?.upgrade?.status || 'INCONSISTENT'}"`}</StyledStatus>
                </div>
                <IconsWrapper>
                    <NotificationListIcon name="check" onClick={()=>this.setState({graphqlUpgradeFailed:false})} />
                </IconsWrapper>
            </NotificationBar>
        );
    }

    render() {
        const messages = this.props.messages;
        return (
            messages && (
                <NotificationsComponent>
                    {this.state.graphqlUpgradeFailed && this.showGraphqlErrorBroadcast()}
                    {this.buildNotificationHeaderBar(messages)}
                    {messages.length > 1 && (
                        <Fragment>
                            <NotificationsWrapper>
                                <NotificationsListContainer isOpen={this.state.isNotificationsOpen}>
                                    {this.buildNotificationsList(messages)}
                                </NotificationsListContainer>
                            </NotificationsWrapper>
                        </Fragment>
                    )}
                </NotificationsComponent>
            )
        );
    }
}

export default NotificationsBar;
