/* @flow */

import React, { Component } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@mic3/platform-ui';

import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { dateFormats } from 'app/utils/date/date';
import { get, set } from 'app/utils/lo/lo';
import { hasDuplicates } from 'app/utils/array/array-utils';
import { applySettings } from 'app/utils/classification/classificationForm';
import { safeToJS, safeToJsArray } from 'app/utils/trasformer/transformer';
import { showToastr } from 'store/actions/app/appActions';
import { startsWith } from 'app/utils/string/string-utils';
import attributeTypes from 'app/containers/Classifications/attributeTypes';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import Immutable from 'app/utils/immutable/Immutable';
import Loader from 'app/components/atoms/Loader/Loader';
import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import Help from 'app/utils/designer/form/settings/common/Help';
import { getStr } from 'app/utils/utils';
import { relationTypeaheadOptions, RELATION_TYPES } from 'app/containers/Entities/RelationDefinitions/AddRelationDefinition';
import { findIntersection } from 'app/utils/array/array-utils';
import { loadPrimaryClasses } from 'store/actions/entities/entitiesActions';

/**
 * Attribute detail modal
 */
class AttributeDetailModal extends Component<Object, Object> {
    static propTypes: Object = {
        classification: PropTypes.object,
        updateClassification: PropTypes.func,
        showToastr: PropTypes.func,
    };

    state: Object;
    attributeId: number = -1;

    formGeneratorRef: Object = React.createRef();

    constructor(props: Object) {
        super(props);
        this.state = this.resetForm(props);
        this.props.loadPrimaryClasses();
    }

    @bind
    @memoize()
    buildFormDefinitionData(classificationForm: Object) {
        const parentUri = `${classificationForm.uri}/`;
        let data = this.getFieldValue(classificationForm);
        if (data && !get(data, 'properties.name')) {
            data = set(data, 'properties.name', parentUri);
        }
        return data;
    }

    getAllFUris(classificationForm) {
        const fields = safeToJsArray(get(classificationForm, 'formDefinition.fields', [])) || [];
        fields.splice(this.attributeId, 1); // remove the current attribute URI from exclusion
        return fields.reduce((data, field) => {
            const name = get(field, 'properties.name');
            data[name] = name;
            return data;
        }, {});
    }

    @bind
    buildMessage(type) {
        switch(type) {
            case 'hyperlink': {
                return `
  Default value for of attribute.
  This value will automatically be shown if no other value was set.
  Use {{context.value}} (where "value" is the name of the data field) as runtime replacement data.
  For class attributes use {{data.attribute-uri}}
                `;
            }
            default:
                return 'Default value of the attribute. This value will be automatically shown in the field of this attribute.';
        }
    }

    @bind
    @memoize()
    getDefaultField(formData){
        const defaultComponentProps = formData.type && formData.type !== 'relationsTypeahead' ? formData : null;
        if (!defaultComponentProps) {
            return null;
        }

        let defaultComponent = {
            ...defaultComponentProps,
            properties: {
                ...defaultComponentProps.properties,
                name: 'properties.defaultValue',
                label: 'Default value',
                title: 'Default value',
                disabled: false,
                isVisible: data => !!data.type,
                help: <Help message={this.buildMessage(formData.type)} />,
            },
            constraints: null,
        };
        if(defaultComponent.type === 'entityTypeahead') {
            const { entityType } = defaultComponent?.properties || {};
            if (!entityType) {
                return null;
            }
            defaultComponent = set(defaultComponent, 'properties.isVisible', !!entityType);
            defaultComponent = set(defaultComponent, 'properties.entityType', entityType?.uri);
        }
        if(!defaultComponent.type) {
            defaultComponent.type =  'text';
        }
        return defaultComponent;
    }

    @bind
    buildFormDefinition(classificationForm: Object, data: Object = {}) {
        const formData = applySettings(data);
        const { settings } = formData;

        const defaultComponent = this.getDefaultField(formData);
        const exclusion = this.getAllFUris(classificationForm);
        const applicableOnDefinitions = (findIntersection(classificationForm?.applicableOn, RELATION_TYPES) || []).map(entity => ({
            type: 'relationDefinitionTypeahead',
            properties: {
                label: `Relation Definition from '${entity}' to '${get(formData, 'properties.toType')}'`,
                name: `relation_definition_${entity}`,
                fromType: entity,
                toType: get(formData, 'properties.toType'),
                isVisible: data => ['relationsTypeahead'].includes(data.type) && RELATION_TYPES.includes(get(data, 'properties.toType')),
            },
            constraints: {
                required: true
            },
        }));

        const formDefinition = [
            {
                type: 'text',
                properties: {
                    label: 'Label',
                    name: 'properties.label',
                    placeholder: 'Label',
                    help: <Help message="Name of the Attribute. This name will show as the field name when you apply this attribute to an Entity, User, Workspaces, etc." />
                },
                constraints: {
                    minLength: 3,
                    maxLength: 60,
                    required: true,
                },
            },
            {
                type: 'text',
                properties: {
                    label: 'Attribute URI',
                    name: 'properties.name',
                    placeholder: 'uri',
                },
                constraints: {
                    required: true,
                    exclusion: {
                        within: exclusion,
                        message: '{label} needs to be unique.'
                    },
                    custom: (data) => {
                        const noStartFromNumbers = { format: {
                            pattern: /somefaketest/,
                            message: 'Only the following characters are allowed: Lowercase letters, Numbers, Dashes, Underscores, Slashes.'
                        }};
                        if (!/^[0-9a-z\-_/]+$/g.test(data.properties.name)) {
                            return noStartFromNumbers;
                        }
                        return {};
                    }
                },
            },
            {
                type: 'text',
                properties: {
                    label: 'Group Name',
                    name: 'settings.groupName',
                    placeholder: 'Name',
                },
            },
            {
                type: 'boolean',
                properties: {
                    label: 'Read Only',
                    name: 'properties.disabled',
                    disabled: !!get(formData, 'constraints.required', false),
                    onChange:  function onChange(event, ...args) {
                        return [
                            { name: 'properties.disabled', value: event.target.value },
                            { name: 'constraints.required', value: false }
                        ];
                    }
                },
            },
            {
                type: 'boolean',
                properties: {
                    label: 'Required',
                    name: 'constraints.required',
                    disabled: !!get(formData, 'properties.disabled'),
                    isVisible: data => data.type !== 'boolean',
                    onChange:  function onChange(event, ...args) {
                        return [
                            { name: 'properties.disabled', value: false },
                            { name: 'constraints.required', value: event.target.value }
                        ];
                    }
                },
            },
            {
                type: 'typeahead',
                properties: {
                    label: 'Attribute Type',
                    name: 'type',
                    options: attributeTypes,
                },
                constraints: {
                    required: true,
                }
            },
            {
                type: 'boolean',
                properties: {
                    label: 'Use code editor',
                    name: 'properties.useCodeEditor',
                    isVisible: data => data.type === 'textarea',
                },
            },
            {
                type: 'boolean',
                properties: {
                    label: 'Use summary and modal',
                    name: 'properties.modal',
                    isVisible: data => data.type === 'textarea' && get(data, 'properties.useCodeEditor'),
                },
            },
            {
                type: 'boolean',
                properties: {
                    label: 'Multi Select',
                    name: 'properties.multiple',
                    isVisible: data =>
                        ['entityTypeahead', 'thingTypeahead', 'personTypeahead', 'organisationTypeahead', 'customEntityTypeahead', 'userTypeahead', 'typeahead', 'classificationTypeahead', 'customEntityTypeahead'].includes(data.type),
                    onChange: function onChange(event) {
                        const isMulti = event.target.value;
                        const defaultValue = get(data, 'properties.defaultValue');
                        let newDefault;
                        if (!isMulti) {
                            newDefault = Array.isArray(defaultValue) ? defaultValue[0] : defaultValue;
                        } else {
                            newDefault = Array.isArray(defaultValue) ? defaultValue : [defaultValue];
                            newDefault = newDefault[0] === undefined ? [] : newDefault;
                        }
                        return [
                            { name: 'properties.multiple', value: isMulti },
                            { name: 'properties.defaultValue', value: newDefault }
                        ];
                    },
                },
            },
            {
                type: 'enum',
                properties: {
                    name: 'properties.options',
                    label: 'Enumeration Options',
                    isVisible: data => data.type === 'typeahead',
                    valueField: 'value',
                },
                constraints: {
                    custom: (data) => {
                        const isWrongValidationValue = !!get(data, 'properties.options', []).filter(
                            ({ value, label }) => !value || (value && value.length > 50) || !label || (label && label.length > 50)
                        ).length;
                        const isEmptyValue = !get(data, 'properties.options', []).length;
                        const values = get(data, 'properties.options', []).map(({ value }) => value);
                        const hasDuplicateKeys = hasDuplicates(values);
                        const message = hasDuplicateKeys ? { format: { pattern: /somefaketest/, message: 'Enumeration values can\'t be duplicated' }} : {};
                        return isWrongValidationValue || isEmptyValue ? { format: { pattern: /somefaketest/, message: `Enumeration fields can't be empty or more than 50 characters${hasDuplicateKeys ? ' and values can not be duplicated' : '.'}` }} : message;
                    }
                },
            },
            {
                type: 'text',
                properties: {
                    label: 'Extension Type',
                    name: 'properties.directoryType',
                    isVisible: data => data.type === 'customEntityTypeahead',
                },
            },
            {
                type: 'typeahead',
                properties: {
                    label: 'Type',
                    name: 'properties.entityType',
                    isVisible: data => data.type === 'entityTypeahead',
                    options: this.props.entityTypes.map(cls => ({ label: cls.name, value: cls })),
                },

                constraints: { required: true }
            },
            {
                type: 'typeahead',
                properties: {
                    label: 'To Type',
                    name: 'properties.toType',
                    options: relationTypeaheadOptions,
                    isVisible: data => ['relationsTypeahead'].includes(data.type),
                },
                constraints: {
                    required: true
                },
            },
            ...applicableOnDefinitions,
            {
                type: 'text',
                properties: {
                    label: 'Filter Expression',
                    name: 'settings.filterExpression',
                    isVisible: data => ['entityTypeahead', 'thingTypeahead', 'personTypeahead', 'organisationTypeahead', 'customEntityTypeahead', 'classificationTypeahead', 'graphicTypeahead', 'userTypeahead', 'relationsTypeahead'].includes(data.type),
                },
            },
            {
                type: 'typeahead',
                properties: {
                    label: 'Format',
                    name: 'properties.format',
                    options: dateFormats(formData.type === 'dateTime').map(f => ({ value: f, label: f })),
                    isVisible: data => data.type === 'date' || data.type === 'dateTime',
                    help: <Help message="Date/Time format of the attribute. This format will be set when you apply it to Entity, Users, Workspaces, etc." />,
                },
            },
            {
                type: 'classificationTypeahead',
                properties: {
                    label: 'Class',
                    name: 'settings.class',
                    isVisible: data => data.type === 'customEntityTypeahead',
                    filterBy: [
                        { field: 'applicableOn', op: '=', value: 'custom' }
                    ],
                },
            },
            {
                type: 'text',
                properties: {
                    label: 'Extension Text',
                    placeholder: '$',
                    name: 'settings.textExt',
                    isVisible: data => ['text', 'number'].includes(data.type),
                },
                constraints: {
                    maxLength: 12,
                },
            },
            {
                type: 'typeahead',
                properties: {
                    label: 'Display',
                    name: 'settings.textExtPosition',
                    placeholder: 'Display text...',
                    options: [{ value: 'before', label: 'Before Value' }, { value: 'after', label: 'After Value' }],
                    isVisible: data => ['text', 'number'].includes(data.type),
                },
                constraints: {
                    required: !!getStr(settings, 'textExt'),
                },
            },
            {
                type: 'typeahead',
                properties: {
                    label: 'Text style',
                    name: 'properties.mode',
                    defaultValue: null,
                    valueField: 'value',
                    options: [
                        { value: 'javascript', label: 'Javascript' },
                        { value: 'markdown', label: 'Markdown' },
                        { value: 'xml', label: 'XML' },
                        { value: null, label: 'Plain text' }
                    ],
                    isVisible: data => data.type === 'textarea' && get(data, 'properties.useCodeEditor'),
                },
            },
            defaultComponent,
        ].filter(Boolean);
        return formDefinition;
    }

    componentDidUpdate(prevProps) {
        const { id } = this.props.classification || {};
        if (id && this.props.classification !== prevProps.classification) {
            this.setState({
                ...this.resetForm(this.props)
            });
        }
    }

    @bind
    handleChange(data: Object, formGeneratorVariables: ?Object) {
        const { name, value } = formGeneratorVariables || {};
        switch (name) {
            case 'properties.name': {
                const parentUri = `${this.props.classification.uri}/`;
                let modifiedValue = parentUri;
                if (startsWith(value, parentUri)) {
                    const uri = value.replace(parentUri, '').toLowerCase();
                    modifiedValue = `${parentUri}${uri}`;
                }
                return this.setState({ formDefinitionData: set(data, name, modifiedValue) });
            }
            case 'properties.label': {
                const fUri = this._buildFuri(value);
                const uri = get(this.getFieldValue(this.props.classification), 'properties.name');
                if (!uri || !value || startsWith(uri, fUri) || startsWith(fUri, uri)) {
                    data = set(data, 'properties.name', fUri);
                }
                return this.setState({ formDefinitionData: data });
            }
            case 'type': {
                let formData = { ...data, defaultValue: null, text_ext: null, properties: {...(data?.properties || {}), defaultValue: null} };
                formData = set(formData, 'properties.format', null);
                if (['entityTypeahead', 'thingTypeahead', 'personTypeahead', 'organisationTypeahead', 'customEntityTypeahead', 'classificationTypeahead', 'userTypeahead'].includes(value)) {
                    formData = { ...formData, settings: { ...formData.settings, filterExpression: JSON.stringify([{ field: 'active', op: '=', value: true }])} };
                }
                return this.setState({ formDefinitionData: formData });
            }
            case 'settings.textExt': {
                let formData = { ...data };
                if(!value) {
                    formData = set(data, 'properties.InputProps', {});
                }
                return this.setState({ formDefinitionData: formData });
            }
            case 'properties.defaultValue': {
                let formData = { ...data };
                let newVal = value;
                if(Array.isArray(value)) {
                    newVal = value.filter(Boolean);
                    formData = set(data, name, newVal);
                }
                return this.setState({ formDefinitionData: formData });
            }
            case 'settings.class':
                if (data.type === 'customEntityTypeahead') {
                    let formData = { ...data, defaultValue: null, text_ext: null, properties: {...(data?.properties || {}), filterBy: [], defaultValue: null} };
                    formData = { ...formData, settings: { ...formData.settings, filterExpression: JSON.stringify([{ field: 'active', op: '=', value: true }])} };
                    return this.setState({ formDefinitionData: formData });
                }
                return this.setState({ formDefinitionData: data });
            default: {
                if (this.attributeId === -1) {
                    return this.setState(this.resetForm(this.props));
                }
                return this.setState({ formDefinitionData: data });
            }
        }
    }

    @bind
    @memoize()
    _buildFuri(name: string){
        const parentUri = `${this.props.classification.uri}/`;
        const newUri = (name || '').replace(/[^\w\d]+/g, '').toLowerCase();
        return `${parentUri}${newUri}`;
    }

    /**
     * Resets form state.
     */
    resetForm({ classification }: Object) {
        // $FlowFixMe
        let fields = safeToJsArray(get(classification, 'formDefinition.fields'));
        if (fields && fields.length) {
            fields.forEach((element, index) => {
                if (get(element, 'properties.name') === this.props.attributeFieldUri) {
                    this.attributeId = index;
                }
            });
        }
        let formDefinitionData = this.buildFormDefinitionData(classification) || {};
        if (this.attributeId === -1 && classification && classification.id) {
            const emptyAttribute = { properties: { name: '' }, settings: { groupName: classification.name }};
            fields = Immutable([...(fields || []), emptyAttribute]);
            this.attributeId = fields.length - 1;
            const classificationEmpty = set(classification, 'formDefinition.fields', fields);
            formDefinitionData = this.buildFormDefinitionData(classificationEmpty);
            this.buildFormDefinition(classificationEmpty, formDefinitionData);
        }
        return { formDefinitionData };
    }

    /**
     * Save the form.
     */
    @bind
    onFormSubmit(event: Event) {
        event.preventDefault();
        const { name: classificationName } = this.props.classification;
        const classification = this.updatedClassificationData();
        this.formGeneratorRef.current.isValidForm().then(({errors}) => {
            if (!errors) {
                let field = this.getFieldValue(classification);
                const groupName = get(field, 'settings.groupName');
                field = set(field, 'settings.groupName', groupName || classificationName);
                const f_uri = get(field, 'properties.name');
                const parentUri = `${classification.uri}/`;
                if (f_uri === parentUri) {  // if user removed the name from attribute URI then we will re-embed it
                    const name = get(field, 'properties.label');
                    const fUri = this._buildFuri(name);
                    field = set(field, 'properties.name', fUri);
                }

                field = applySettings(field);
                const { id, formDefinition } = set(classification, `formDefinition.fields[${this.attributeId}]`, field) || {};
                return this.setState({ isLoading: true }, () => {
                    this.props.updateClassification({ id, formDefinition})
                        .then(() => {
                            this.setState({ ...this.resetForm(this.props), isLoading: false });
                            this.attributeId = -1;
                            this.props.onClose();
                        })
                        .catch((e) => {
                            this.setState({ isLoading: false });
                            this.props.onClose();
                        });
                });
            }
        });
    }

    @bind
    updatedClassificationData(){
        const { formDefinitionData } = this.state;
        let classification = this.props.classification;
        Object.entries(formDefinitionData).forEach(([name, value ]) => {
            classification = set(classification, `formDefinition.fields[${this.attributeId}].${name}`, value);
        });
        return classification;
    }

    /**
     * Returns the classificationForm field value.
     *
     * @param classificationForm the classification form
     * @param fieldName the field name
     * @param defaultValue the default value (optional)
     * @returns the field value.
     */
    getFieldValue(classificationForm: Object): any {
        return safeToJS(get(
            classificationForm,
            `formDefinition.fields[${this.attributeId}]`,
        ));
    }

    /**
     * @override
     */
    render() {
        const { formDefinitionData, isLoading } = this.state;
        const { classification } = this.props;
        if (!classification && !isLoading) {
            return null;
        }
        if (isLoading) {
            return (<Loader absolute backdrop/>);
        }
        const name = get(this.getFieldValue(this.props.classification), 'properties.label');
        const title = name ? `Edit Attribute: ${name}` : 'Add Attribute';
        return (
            <ModalDialog
                title={title}
                onClose={this.props.onClose}
                open
                actions={(
                    <Button onClick={this.onFormSubmit}>
                      Save
                    </Button>
                )}
            >
                <FormGenerator
                    components={this.buildFormDefinition(classification, formDefinitionData)}
                    data={formDefinitionData}
                    ref={this.formGeneratorRef}
                    onChange={this.handleChange}
                />
            </ModalDialog>
        );
    }
}

export default connect(state =>
    ({ entityTypes : state.app.allPrimaryClasses.records || [] }),
{ showToastr, loadPrimaryClasses })(withRouter(AttributeDetailModal));
