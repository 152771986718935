
import React, { useMemo } from 'react';
import { muiTheme } from 'app/themes/materialUi';
import { Grid } from '@mic3/platform-ui';
import { useSelector } from 'react-redux';
import styled from 'styled-components';


import Carousel from 'app/components/atoms/Carousel/Carousel';
import MyAppIcon from 'app/components/ABox/MyApps/MyAppIcon';
import history from 'store/History';
import Loader from 'app/components/atoms/Loader/Loader';


const StyledLoader = styled(Loader)`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 164px !important;
    width: 100% !important;
`;
const GridStyled = styled(Grid)`
    padding-bottom: ${({pb}) => pb};
`;

const LandingPageApps = () => {
    const isMobile = useSelector(
        state => state.global.isMobile,
    );
    const apps = useSelector(state => state.dashboard?.lastUsedProcesses?.records);
    const isLoading = useSelector(state => state.dashboard?.lastUsedProcesses?.isLoading);
    const appsList = useMemo(() => {
        const allAppsButton = {
            name:'All Apps',
            iconName:'multiple-apps',
            iconColor: muiTheme.colors.background.fields,
            iconType:'af',
            id:'show-all-apps'
        };
        const _apps = [allAppsButton, ...(apps || []).slice(0,10)];
        return _apps?.map((app, i) => (
            <MyAppIcon onClick={() => {
                const path = app.id === 'show-all-apps' ? `/abox/processes-new`:`/abox/process-start/${app.id}`;
                history.push(path);
            }} key={app.id} appName={app.name} iconName={app.iconName} iconColor={app.iconColor} iconType={app.iconType} />
        ));
    }, [apps]);
    let disableDotsControls = false;
    if((appsList.length < 8 && !isMobile) || (appsList.length <= 4 && isMobile)) {
        disableDotsControls = true;
    }
    if(isLoading) {
        return <StyledLoader />;
    }
    return (
        <GridStyled
            data-tut='reactour__app'
            container
            alignItems="flex-start"
            justify="center"
            pb={disableDotsControls ? '20px': ''}
        >
            {disableDotsControls ? appsList : <Carousel
                disableButtonsControls
                dotsDisabled={disableDotsControls}
                responsive={{ 0: { items: 4 }, 600: { items: 6 }, 1080:{items: 8}, 1440:{items: 10}}}
                items={appsList}
                infinite={false}
            /> }
        </GridStyled>
    );
};

export default LandingPageApps;
