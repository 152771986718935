/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress } from '@mic3/platform-ui';
import { connect } from 'react-redux';

import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import { bind } from 'app/utils/decorators/decoratorUtils';
import { createTeam, updateTeam } from 'store/actions/admin/teamsActions';
import { saveText } from 'app/utils/datatable/datatableUtils';
import { exportAndDownloadImage, exportSvg, printElement } from 'app/utils/designer/process/processDesignerUtils';

class DownloadProcess extends PureComponent<Object, Object> {
    static propTypes = {
        createTeam: PropTypes.func.isRequired,
        isLoading: PropTypes.bool,
        isLoadingUpdate: PropTypes.bool,
        data: PropTypes.object,
        title: PropTypes.string
    };

    static defaultProps = {
        title: 'Create New Team'
    };

    formRef = React.createRef();
    components = [
        {
            type: 'typeahead',
            properties: { 
                label: 'Download format', 
                name: 'format', 
                clearable: false,
                defaultValue: 'XML',
                valueField: 'value',
                options: this.props.pathname.includes('xml') 
                    ? ['XML'].map(type => ({ value: type, label: type }))
                    : ['XML', 'PNG', 'SVG', 'Document'].map(type => ({ value: type, label: type })) 
            },
        }
    ];

    @bind
    async onDownload(event: Event) {
        event.preventDefault();
        const { data } = await this.formRef.current.isValidForm();
        const { processDesignerState, onClose } = this.props;
        const { editor: process } = processDesignerState || {};
        const { name, primary } = process || {};
        switch (data.format) {
            case 'XML':
                saveText(name, primary?.definition || {}, '.xml');
                break;
            case 'SVG':
                const svg = exportSvg();
                saveText(name, svg || {}, '.svg');
                break;
            case 'PNG':
                exportAndDownloadImage(name);
                break;        
            case 'Document':
                onClose();
                printElement('js-canvas', name);
                break;        
            default:
                break;
        }
        onClose();
    }

    render(): Object {
        const { isLoading, isLoadingUpdate, onClose, data } = this.props;
        return (
            <ModalDialog
                title={'Download Process Defintion'}
                onClose={onClose}
                actions={
                    (isLoading || isLoadingUpdate) ? (
                        <CircularProgress size={24} color="primary" />
                    ) : (
                        <>
                            <Button onClick={onClose} variant="text" >
                            Cancel
                            </Button>
                            <Button onClick={this.onDownload}>
                            Download
                            </Button>
                        </>
                    )
                }
            >
                <FormGenerator 
                    components={this.components} 
                    ref={this.formRef}
                    data={data}
                />
            </ModalDialog>
        );
    }
}

export default connect(
    state => ({
        isLoading: state.admin.teams.create.isLoading,
        isLoadingUpdate: state.admin.teams.update.isLoading
    }),
    {
        createTeam,
        updateTeam
    }
)(DownloadProcess);
