
import { gql } from '@apollo/client';

export default gql`
query relationTeamToUserQuery($id: ID!) {
  result: team(id: $id) {
    users {
      user {
        id
        username
      }
    }
  }
}
`;