/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Autocomplete } from '@mic3/platform-ui';
import { loadEventTypeTypeahead } from 'store/actions/stream/eventsActions';
import { bind } from 'app/utils/decorators/decoratorUtils';

/**
 * Select one or more groups using lazy loading.
 */
class EventTypeTypeahead extends PureComponent<Object, Object> {

    static propTypes = {
        loadOptions: PropTypes.func.isRequired,
        filterBy: PropTypes.arrayOf(PropTypes.object),
    }

    constructor(props) {
        super(props);
        this.state= {
            options: [],
            isLoading: true,
        };
        this.props.loadOptions({
            orderBy: [{ field: 'createdDate', direction: 'desc nulls last' }]
        }).then((options) => {
            this.setState({ options, isLoading: false });
        });
    }

    @bind
    optionTemplate(data: any) {
        if (this.props.optionTemplate) {
            return this.props.optionTemplate(data);
        }
        const { id, name } = data;
        return ({
            label: `${name || 'Name not available'} (${id ? id.slice(0,8) : 'ID not available'})`,
        });
    };

    render() {
        const { loadOptions, filterBy, excludeBy, orderBy, placeholder, ...typeaheadProps } = this.props; // eslint-disable-line no-unused-vars
        const { options, isLoading } = this.state;
        return <Autocomplete
            {...typeaheadProps}
            options={options}
            optionTemplate={this.optionTemplate}
            isLoading={isLoading}
            placeholder={placeholder || 'Search for an event type...'}
        />;
    }
};

export default connect(
    null, { loadOptions: loadEventTypeTypeahead }
)(EventTypeTypeahead);
