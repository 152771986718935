/* @flow */

import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import { HashRouter as Router, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { JssProvider } from 'react-jss';
import { ThemeProvider } from 'styled-components';
import MomentUtils from '@date-io/moment';
import { ThemeProvider as MuiThemeProvider  } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import ReactPWAInstallProvider from 'app/containers/Pwa/PwaIndex';

import GlobalStyle from 'app/components/atoms/GlobalStyle/GlobalStyle';
import DragNDropContext from 'app/containers/DragNDropContext/DragNDropContext';
import FatalErrorPage from 'app/containers/ErrorPages/FatalErrorPage';
import Loader from 'app/components/atoms/Loader/Loader';
import AppRoute from 'app/containers/App/AppRoute';
import LicenseRoute from 'app/containers/Admin/License/LicenseRoute';

import { client } from 'graphql/client';
import store from 'store/Store';
import { loadAppOrganisation, loadConfigurations, loadVersion, loadAllPrimaryClasses, loadLicense, loadTerms } from 'store/actions/app/appActions';
import { loadCountriesList } from 'store/actions/entities/entitiesActions';
import { loadUserProfile } from 'store/actions/admin/usersActions';
import { setWindowSize } from 'store/actions/global/globalActions';
import TermsDetail from 'app/containers/Admin/Terms/TermsDetail';

import { debounce } from 'app/utils/utils';
import { muiTheme } from 'app/themes/materialUi';
import theme from 'app/themes/theme.default';

import 'style/index.css';
import '@mic3/platform-ui/build/index.css';
import { generateClassName } from 'app/config/themeConfig';
import AppSubscriptions from 'app/containers/App/AppSubscriptions';
import { getLastUsedProcesses } from 'store/actions/dashboard/dashboardActions';
import ErrorBoundary from 'app/components/atoms/ErrorBoundary/ErrorBoundary';
import PushNotifications from 'app/containers/Notifications/PushNotifications';

const App = connect(
    state => ({
        profile: state.user.profile,
        organisation: state.app.organisation,
        isOrganisationLoaded: state.app.isOrganisationLoaded,
        isOrganisationLoadFailed: state.app.isOrganisationLoadFailed,
        organisationLoadError: state.app.organisationLoadError,
        isConfigurationsLoaded: state.app.isConfigurationsLoaded,
        isConfigurationsLoadFailed: state.app.isConfigurationsLoadFailed,
        configurationsLoadError: state.app.configurationsLoadError,
        isLicenseLoadFailed: state.app.isLicenseLoadFailed,
        isLicenseLoaded: state.app.isLicenseLoaded,
        licenseLoadError: state.app.licenseLoadError,
        terms: state.app.terms,       
        termsLoadError: state.app.termsLoadError,
        isTermsLoadFailed: state.app.isTermsLoadFailed,
        isTermsLoaded: state.app.isTermsLoaded
    }),
    { 
        loadAppOrganisation,
        loadUserProfile,
        loadConfigurations,
        setWindowSize, 
        loadVersion,
        loadAllPrimaryClasses,
        loadLicense,
        loadTerms,
        loadCountriesList,
        getLastUsedProcesses
    }
)(({
    loadUserProfile,
    loadAppOrganisation,
    loadConfigurations,
    setWindowSize,
    organisation,
    profile,
    terms,       
    termsLoadError,
    isTermsLoadFailed,
    isTermsLoaded,
    isOrganisationLoaded,
    isOrganisationLoadFailed,
    organisationLoadError,
    isConfigurationsLoaded,
    isConfigurationsLoadFailed,
    configurationsLoadError,
    isLicenseLoaded,
    isLicenseLoadFailed,
    licenseLoadError,
    loadVersion,
    loadAllPrimaryClasses,
    loadLicense,
    loadTerms,
    loadCountriesList,
    getLastUsedProcesses
}) => {
    const [dependencyLoaded, setDependencyLoaded] = useState(false);

    useEffect(() => {
        const onResize = debounce(() => {
            const { width, height } = window.document.body.getBoundingClientRect();
            setWindowSize({ width, height });
        }, 1000);
        window.addEventListener('resize', onResize);
        onResize();
    }, [setWindowSize]);

    useEffect(() => {
        loadUserProfile();
        loadLicense();
        loadTerms();
    }, [loadUserProfile, loadLicense, loadTerms]);

    useEffect(() => {
        if (dependencyLoaded) return;

        if (!isTermsLoaded) return;

        if (profile && !profile?.mustAcceptTerms && !termsLoadError) {
            loadAppOrganisation();
            loadVersion();
            loadConfigurations();
            loadAllPrimaryClasses();
            loadCountriesList();
            getLastUsedProcesses();
            setDependencyLoaded(true);
        }
    }, [
        organisation,
        isOrganisationLoaded,
        loadConfigurations,
        loadVersion,
        loadAllPrimaryClasses,
        loadCountriesList,
        loadAppOrganisation,
        profile,
        dependencyLoaded,
        termsLoadError,
        isTermsLoaded,
        getLastUsedProcesses
    ]);

    if (terms?.enabled && profile?.mustAcceptTerms) {
        return (
            <Router>
                <Route path="/" render={(props) => <TermsDetail {...props} terms={terms} />} />
            </Router>
        );
    }

    if (
        (
            (isOrganisationLoadFailed && organisationLoadError) ||
            (isTermsLoadFailed && termsLoadError) ||
            (isConfigurationsLoadFailed && configurationsLoadError) ||
            (isLicenseLoadFailed && licenseLoadError)
        ) && profile
    ) {
        if (
            termsLoadError?.message.includes('license is expired') || 
            termsLoadError?.message.includes('is not allowed by the Affectli license')
        ) {
            return (
                <Router>
                    <Route path="/" render={(props) => <LicenseRoute {...props} isExpired message={termsLoadError?.message} />} />
                </Router>
            );
        }

        return (
            (organisationLoadError?.httpCode === 500 && organisationLoadError.message) ||
            (configurationsLoadError?.httpCode === 500 && configurationsLoadError.message) ||
            (licenseLoadError?.httpCode === 500 && licenseLoadError.message)
        )
            ? <FatalErrorPage message={organisationLoadError.message || configurationsLoadError.message || licenseLoadError.message} />
            : <FatalErrorPage message="The application service is not available." />;
    }

    if (!isOrganisationLoaded || !isConfigurationsLoaded || !isLicenseLoaded || !profile) {
        return <Loader absolute />;
    }

    return (
        < >
            <AppSubscriptions />
            <PushNotifications />
            <Router>
                <ErrorBoundary>
                    <Route path="/" component={AppRoute} />
                </ErrorBoundary>
            </Router>
        </>
    );
});

export const MainApp = () => (
    <MuiThemeProvider theme={muiTheme}>
        <JssProvider generateClassName={generateClassName}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <ThemeProvider theme={theme}>
                    <GlobalStyle />
                    <ApolloProvider client={client}>
                        <Provider store={store}>
                            <ReactPWAInstallProvider enableLogging={false}>
                                <div className="app">
                                    <DragNDropContext>
                                        <App />
                                    </DragNDropContext>
                                </div>
                            </ReactPWAInstallProvider>
                        </Provider>
                    </ApolloProvider>
                </ThemeProvider>
            </MuiPickersUtilsProvider>
        </JssProvider>
    </MuiThemeProvider>
);
