/* @flow */

import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';


import BackgroundJobList from '/app/containers/Abox/BackgroundJob/BackgroundJobList';
import BackgroundJobDetails from 'app/containers/Abox/BackgroundJob/BackgroundJobDetails';

/**
 * Define the routes for the Event's views.
 */
const AdminBackgroundJobRoute = ({ match: { url } }: Object) => {
    const backgroundJobMatch = '/background-jobs';
    return (
        <Switch>
            <Route path={`${backgroundJobMatch}/:status`} exact render={props => <BackgroundJobList {...props} adminBackgroundJobs  module={'admin'} />} />  
            <Route path={`${backgroundJobMatch}`} exact component={() => <Redirect to={`${backgroundJobMatch}/all`} />} />
            <Route path={`/background-job/:id`} exact render={props => <BackgroundJobDetails {...props} adminBackgroundJobs  module={'admin'} />}  />
            <Route path={`/background-job/:id/:tab`} exact render={props => <BackgroundJobDetails {...props} adminBackgroundJobs  module={'admin'} />} />
          
        </Switch>
    );};

export default AdminBackgroundJobRoute;
