import React, { memo, useCallback, useState } from 'react';
import styled from 'styled-components';
import { muiTheme } from 'app/themes/materialUi';
import PropTypes from 'prop-types';
import { MdiIcon, IconButton, Tooltip } from '@mic3/platform-ui';

import NestedMenu from '../molecules/NestedMenu/NestedMenu';

const NestedMenuStyled = styled(NestedMenu)`
    & .MuiListItemIcon-root {
        padding-right: 18px;   
    }

    & .MuiDivider-root {
        margin: 0 8px;
    }

    & .Mui-disabled .MuiTypography-root {
        font-weight: 500;
    }

    & .Icon {
        &::before {
            color: ${({theme})=> theme.material.colors.appHeader.iconColor};
        }
    }
`;

const RelatedEntityMenu = ({
    tooltipLabel,
    iconName,
    items,
    onItemClick
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    
    const handleOpen = useCallback(e => setAnchorEl(e.currentTarget), []);
    const handleClose = useCallback(() => setAnchorEl(null), []);

    return (
        <>
            <Tooltip title={tooltipLabel}>
                <IconButton onClick={handleOpen}>
                    <MdiIcon name={iconName} color={muiTheme.colors.appHeader.iconColor} />
                </IconButton>
            </Tooltip>
            <NestedMenuStyled
                items={items}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                onItemClick={onItemClick}
            />
        </>
    );
};

RelatedEntityMenu.propTypes = {
    tooltipLabel: PropTypes.string,
    iconName: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    onItemClick: PropTypes.func.isRequired,
};

export default memo(RelatedEntityMenu);