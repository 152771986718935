/* @flow */

import { gql } from '@apollo/client';

export default (mutation) => gql`
mutation ${mutation}Mutation(
    $name: String
    $description: String
    $filterBy: [JSON]
    $excludeBy: [JSON]
    $orderBy: [JSON]
    $startIndex: Int
    $stopIndex: Int
) {
  result: ${mutation}(
    name: $name
    description: $description
    filterBy: $filterBy
    excludeBy: $excludeBy
    orderBy: $orderBy
    startIndex: $startIndex
    stopIndex: $stopIndex
  ) {
    id
    type
    name
  }
}
`;
