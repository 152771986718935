/* @flow */
import { combineReducers } from 'redux';

import {
    RELATION_DEFINITIONS_LIST_STARTED,
    RELATION_DEFINITIONS_LIST,
    LOAD_RELATION_DEFINITION_DETAILS_STARTED,
    LOAD_RELATION_DEFINITION_DETAILS,
} from 'store/actions/entities/relationshipsActions';
import { dataTableReducer, loadDataReducer, keepDataAtStart } from 'app/utils/redux/reducer-utils';

import avatar from './relationDefinitionsAvatarReducer';

export default combineReducers<Object, Object>({
    list: dataTableReducer(RELATION_DEFINITIONS_LIST_STARTED, RELATION_DEFINITIONS_LIST, keepDataAtStart),
    details: loadDataReducer(LOAD_RELATION_DEFINITION_DETAILS_STARTED, LOAD_RELATION_DEFINITION_DETAILS),
    avatar
});
