import React, { useState, useRef, useEffect, createContext, useContext } from 'react';
import { useSelector } from 'react-redux';

import { platforms, getPlatform } from './Platforms';
import InstallDialog from './InstallDialog';

export const ReactPWAInstallContext = createContext(Promise.reject);

export const useReactPWAInstall = () => useContext(ReactPWAInstallContext);

const platform = getPlatform();

export const ReactPWAInstallProvider = ({ children, enableLogging }) => {
    const userProfile = useSelector((state) => state.user.profile);
    const awaitingPromiseRef = useRef();
    const deferredprompt = useRef(window.beforeInstallPromptAffectli);
    const [dialogState, setDialogState] = useState(null);
    const [contextValue, setContextValue] = useState({
        supported: supported,
        isInstalled: isInstalled,
        pwaInstall: openDialog,
    });

    const handleSavePreferences = () => {
        localStorage.setItem(`pwaInstallClose_${userProfile?.id}`, 'true');
    };

    function handleBeforeInstallPromptEvent(event) {
        event.preventDefault();
        deferredprompt.current = event;
        logger('beforeinstallprompt event fired and captured');
        setContextValue({
            supported: supported,
            isInstalled: isInstalled,
            pwaInstall: openDialog,
        });
    }

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPromptEvent);

    useEffect(() => {
        return function cleanup() {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPromptEvent);
        };
    }, []); //eslint-disable-line react-hooks/exhaustive-deps 

    function logger(message) {
        if (enableLogging) {
            console.log(message); //eslint-disable-line no-console
        }
    }

    function isInstalled() {
        if (window.navigator.standalone === true || window.matchMedia('(display-mode: standalone)').matches) {
            logger('isInstalled: true. Already in standalone mode');
            return true;
        }
        logger('isInstalled: false.');
        return false;
    }

    function supported() {
        if (deferredprompt.current != null && platform === platforms.NATIVE) {
            logger('supported: true - native platform');
            return true;
        }
        if (platform !== platforms.NATIVE && platform !== platforms.OTHER) {
            logger('supported: true - manual support');
            return false;
        }
        logger('supported: false');
        return false;
    }


    function openDialog(options) {
        setDialogState(options);
        return new Promise((resolve, reject) => {
            awaitingPromiseRef.current = { resolve, reject };
        });
    }

    function handleClose() {
        setDialogState(null);
        handleSavePreferences();
        if (awaitingPromiseRef.current) {
            awaitingPromiseRef.current.reject();
        }
    }

    function handleInstall() {
        logger('handleInstall called');
        setDialogState(null);
        if (deferredprompt.current != null) {
            return deferredprompt.current
                .prompt()
                .then((event) => deferredprompt.current.userChoice)
                .then((choiceResult) => {
                    if (choiceResult.outcome === 'accepted') {
                        logger('PWA native installation succesful');
                        if (awaitingPromiseRef.current) {
                            awaitingPromiseRef.current.resolve();
                        }
                    } else {
                        logger('User opted out by cancelling native installation');
                        handleSavePreferences();
                        if (awaitingPromiseRef.current) {
                            awaitingPromiseRef.current.reject();
                        }
                    }
                })
                .catch((err) => {
                    if (awaitingPromiseRef.current) {
                        awaitingPromiseRef.current.resolve();
                    }
                    logger('Error occurred in the installing process: ', err);
                });
        } else {
            if (awaitingPromiseRef.current) {
                awaitingPromiseRef.current.resolve();
            }
        }
    }

    return (
        <>
            <ReactPWAInstallContext.Provider value={contextValue} children={children} />

            <InstallDialog
                open={Boolean(dialogState)}
                onSubmit={handleInstall}
                onClose={handleClose}
                platform={platform}
                {...dialogState}
            />
        </>
    );
};

export default ReactPWAInstallProvider;