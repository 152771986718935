import { gql } from '@apollo/client';

export default gql`
    query projectionListQuery {
        srids {
            srid
            description
            text
        }   
    }
`;
