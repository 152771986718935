/* @flow */

import { isDev } from 'app/utils/env';
import { get } from 'app/utils/lo/lo';

const json = (response) => {
    try {
        return response.json();
    } catch (e) {
        return {};
    }
};

const setHeaders = ({ userId, token }, additionalHeaders = {}) => {
    return { 'X-User-Id': userId, 'X-Auth-Token': token, ...additionalHeaders };
};

const chatFetch = async ({ url, method, headers, errorMsg, returnDataCallback, body }) => {
    const response =  await fetch(url, { method, headers, body });
    const data = await json(response);
    if (!response.ok || !data.success) {
        throw new Error(data.error || errorMsg);
    }
    return (returnDataCallback && typeof returnDataCallback === 'function') ? returnDataCallback(data) : data;
};

export const fetchSubscriptions = async (userIdToken) => {
    isDev && console.debug('[dev] chat fetchSubscriptions'); // eslint-disable-line no-console

    return await chatFetch({
        url: '/chat/api/v1/subscriptions.get',
        method: 'GET',
        errorMsg: 'An error occurred getting the chat subscriptions',
        headers: setHeaders(userIdToken)
    });
};

export const setSubscriptionMessagesAsRead = async ({ rid, query, sort }, userIdToken) => {
    isDev && console.debug('[dev] chat setSubscriptionMessagesAsRead', rid); // eslint-disable-line no-console

    return await chatFetch({
        url: '/chat/api/v1/subscriptions.read',
        method: 'POST',
        headers: setHeaders(userIdToken, {
            'content-type': 'application/json',
        }),
        body: JSON.stringify({ rid }),
        errorMsg: 'An error occurred setting the room messages as read'
    });
};

export const fetchRooms = async (userIdToken) => {
    isDev && console.debug('[dev] chat fetchRooms'); // eslint-disable-line no-console

    return await chatFetch({
        url: '/chat/api/v1/rooms.get',
        method: 'GET',
        errorMsg: 'An error occurred getting the rooms',
        headers: setHeaders(userIdToken),
        returnDataCallback: (data) => get(data, 'update') || []
    });
};

export const favoriteUnfavoriteRoom = async ({ roomId, favorite }, userIdToken) => {
    isDev && console.debug('[dev] chat favoriteUnfavoriteRoom', roomId); // eslint-disable-line no-console
    if (!roomId) {
        throw new Error('the roomId and favourite is required');
    }

    return await chatFetch({
        url: '/chat/api/v1/rooms.favorite',
        method: 'POST',
        errorMsg: `An error occurred on ${favorite ? 'favourite' : 'unfavourite'} room.`,
        headers: setHeaders(userIdToken, {
            'content-type': 'application/json',
        }),
        body: JSON.stringify({ roomId, favorite }),
    });
};

export const roomFileUpload = async ({ file, id, filename, description }, userIdToken) => {
    isDev && console.debug('[dev] chat roomFileUpload'); // eslint-disable-line no-console
    
    if (!file && !id) {
        throw new Error('the File and ID is required');
    }

    const body = new FormData();
    const { name: fileName } = file || {};
    filename = filename || fileName;

    if (description) body.append('description', description);
    body.append('file', file, filename);

    return await chatFetch({
        url: `/chat/api/v1/rooms.upload/${id}`,
        method: 'POST',
        errorMsg: 'An error occurred on uploading attachment.',
        headers: setHeaders(userIdToken),
        body,
        returnDataCallback: (data) => get(data, 'message') || null
    });
};

export const fetchGroups = async (userIdToken) => {
    isDev && console.debug('[dev] chat fetchGroups'); // eslint-disable-line no-console

    const response = await fetch(`/chat/api/v1/groups.list?count=0`, {
        method: 'GET',
        headers: { 'X-User-Id': userId, 'X-Auth-Token': token }
    });

    const data = await json(response);
    if (!response.ok || !data.success) {
        throw new Error(data.error || 'An error occurred getting the room files');
    }
    return data;
};

export const fetchGroupMembers = async ({ rid }, userIdToken) => {
    isDev && console.debug('[dev] chat fetchGroupMembers', rid); // eslint-disable-line no-console

    return await chatFetch({
        url: `/chat/api/v1/groups.members?roomId=${rid}`,
        method: 'GET',
        errorMsg: 'An error occurred getting room members',
        headers: setHeaders(userIdToken)
    });
};
 
export const fetchGroupMessageByFileId = async ({ roomId, fileId }, userIdToken) => {
    isDev && console.debug('[dev] chat fetchGroupMessageByFileId', roomId); // eslint-disable-line no-console

    if (!fileId) {
        throw new Error('the fileId is required');
    }

    return await chatFetch({
        url: `/chat/api/v1/groups.messages?roomId=${roomId}&query={"file._id": {"$eq": "${fileId}"}}`,
        method: 'GET',
        errorMsg: 'An error occurred getting the message by file ID',
        headers: setHeaders(userIdToken),
        returnDataCallback: (data) => get(data, 'messages[0]') || null
    });
};

export const createGroup = async (data, userIdToken) => {
    isDev && console.debug('[dev] chat createGroup'); // eslint-disable-line no-console
    
    if (!data.name) {
        throw new Error('the group name is required');
    }

    return await chatFetch({
        url: `/chat/api/v1/groups.create`,
        method: 'POST',
        errorMsg: 'An error occurred on group',
        headers: setHeaders(userIdToken, {
            'content-type': 'application/json'
        }),
        body: JSON.stringify(data),
        returnDataCallback: (data) => get(data, 'group') || null
    });
};

export const leaveGroup = async (roomId, userIdToken) => {
    isDev && console.debug('[dev] chat leaveGroup', roomId); // eslint-disable-line no-console

    if (!roomId) {
        throw new Error('Room ID is required');
    }

    return await chatFetch({
        url: `/chat/api/v1/groups.leave`,
        method: 'POST',
        errorMsg: 'An error occurred on unsubscribe group',
        headers: setHeaders(userIdToken, {
            'content-type': 'application/json'
        }),
        body: JSON.stringify({ roomId }),
        returnDataCallback: (data) => get(data, 'group') || null
    });
};

export const kickFromGroup = async (payload, userIdToken) => {
    isDev && console.debug('[dev] chat kickFromGroup'); // eslint-disable-line no-console

    if (!payload.roomId && payload.username) {
        throw new Error('Room ID and User ID is required');
    }

    return await chatFetch({
        url: `/chat/api/v1/groups.kick`,
        method: 'POST',
        errorMsg: 'An error occurred on kick user from group',
        headers: setHeaders(userIdToken, {
            'content-type': 'application/json'
        }),
        body: JSON.stringify(payload),
        returnDataCallback: (data) => get(data, 'group') || null
    });
};

export const fetchGroupRoles = async (rid, userIdToken) => {
    isDev && console.debug('[dev] chat fetchGroupRoles', rid); // eslint-disable-line no-console
    
    if (!rid) {
        throw new Error('Room ID is required');
    }
    
    return await chatFetch({
        url: `/chat/api/v1/groups.roles?roomId=${rid}`,
        method: 'GET',
        errorMsg: 'An error occurred getting group roles',
        headers: setHeaders(userIdToken),
        returnDataCallback: (data) => get(data, 'roles') || null
    });
};

export const fetchChannelList = async (userIdToken) => {
    isDev && console.debug('[dev] chat fetchChannelList'); // eslint-disable-line no-console

    return await chatFetch({
        url: `/chat/api/v1/channels.list`,
        method: 'GET',
        errorMsg: 'An error occurred getting channel list',
        headers: setHeaders(userIdToken),
        returnDataCallback: (data) => get(data, 'channels') || null
    });
};

export const createChannel = async (data, userIdToken) => {
    isDev && console.debug('[dev] chat createChannel'); // eslint-disable-line no-console

    if (!data.name) {
        throw new Error('the channel name is required');
    }

    return await chatFetch({
        url: `/chat/api/v1/channels.create`,
        method: 'POST',
        errorMsg: 'An error occurred on create channel',
        headers: setHeaders(userIdToken, {
            'content-type': 'application/json'
        }),
        body: JSON.stringify(data),
        returnDataCallback: (data) => get(data, 'channel') || null
    });
};

export const fetchChannelMembers = async ({ rid }, userIdToken) => {
    isDev && console.debug('[dev] chat fetchChannelMembers', rid); // eslint-disable-line no-console
    
    return await chatFetch({
        url: `/chat/api/v1/channels.members?roomId=${rid}`,
        method: 'GET',
        errorMsg: 'An error occurred getting channels members',
        headers: setHeaders(userIdToken),
    });
};

export const fetchChannelRoles = async (rid, userIdToken) => {
    isDev && console.debug('[dev] chat fetchChannelRoles', rid); // eslint-disable-line no-console

    if (!rid) {
        throw new Error('Room ID is required');
    }
    
    return await chatFetch({
        url: `/chat/api/v1/channels.roles?roomId=${rid}`,
        method: 'GET',
        errorMsg: 'An error occurred getting channel roles',
        headers: setHeaders(userIdToken),
        returnDataCallback: (data) => get(data, 'roles') || null
    });
};

export const joinChannel = async (roomId, userIdToken) => {
    isDev && console.debug('[dev] chat joinChannel', roomId); // eslint-disable-line no-console

    if (!roomId) {
        throw new Error('Channel Room ID is required');
    }

    return await chatFetch({
        url: `/chat/api/v1/channels.join`,
        method: 'POST',
        errorMsg: 'An error occurred on joining channel',
        headers: setHeaders(userIdToken, {
            'content-type': 'application/json'
        }),
        body: JSON.stringify({ roomId }),
        returnDataCallback: (data) => get(data, 'channel') || null
    });
};

export const leaveChannel = async (roomId, userIdToken) => {
    isDev && console.debug('[dev] chat leaveChannel', roomId); // eslint-disable-line no-console
    
    if (!roomId) {
        throw new Error('Room ID is required');
    }

    return await chatFetch({
        url: `/chat/api/v1/channels.leave`,
        method: 'POST',
        errorMsg: 'An error occurred on unsubscribe channel',
        headers: setHeaders(userIdToken, {
            'content-type': 'application/json'
        }),
        body: JSON.stringify({ roomId }),
        returnDataCallback: (data) => get(data, 'channel') || null
    });
};

export const kickFromChannel = async (payload, userIdToken) => {
    isDev && console.debug('[dev] chat kickFromChannel'); // eslint-disable-line no-console

    if (!payload.roomId && payload.userId) {
        throw new Error('Room ID and User ID is required');
    }

    return await chatFetch({
        url: `/chat/api/v1/channels.kick`,
        method: 'POST',
        errorMsg: 'An error occurred on kick user from channel',
        headers: setHeaders(userIdToken, {
            'content-type': 'application/json'
        }),
        body: JSON.stringify(payload),
        returnDataCallback: (data) => get(data, 'channel') || null
    });
};

export const createDirectMessage = async ({ username }, userIdToken) => {
    isDev && console.debug('[dev] chat createDirectMessage'); // eslint-disable-line no-console

    if (!username) {
        throw new Error('the username is required');
    }

    return await chatFetch({
        url: `/chat/api/v1/im.create`,
        method: 'POST',
        errorMsg: 'An error occurred on create a direct message with another user',
        headers: setHeaders(userIdToken, {
            'content-type': 'application/json'
        }),
        body: JSON.stringify({ username }),
        returnDataCallback: (data) => get(data, 'room') || null
    });
};

export const fetchChatMessageById = async ({ messageId }, userIdToken) => {
    isDev && console.debug('[dev] chat createDirectMessage'); // eslint-disable-line no-console

    if (!messageId) {
        throw new Error('the messageId is required');
    }

    return await chatFetch({
        url: `/chat/api/v1/chat.getMessage?msgId=${messageId}`,
        method: 'GET',
        errorMsg: 'An error occurred getting the chat message',
        headers: setHeaders(userIdToken),
        returnDataCallback: (data) => get(data, 'message') || null
    });
};

export const fetchMentionedMessagesByRoomId = async ({ roomId }, userIdToken) => {
    isDev && console.debug('[dev] chat fetchMentionedMessagesByRoomId', roomId); // eslint-disable-line no-console

    if (!roomId) {
        throw new Error('the roomId is required');
    }

    return await chatFetch({
        url: `/chat/api/v1/chat.getMentionedMessages?roomId=${roomId}`,
        method: 'GET',
        errorMsg: 'An error occurred getting the chat mentioned messages',
        headers: setHeaders(userIdToken),
        returnDataCallback: (data) => get(data, 'messages') || null
    });
};

export const postChatMessage = async (message, userIdToken) => {
    isDev && console.debug('[dev] chat postChatMessage'); // eslint-disable-line no-console

    if (!message) {
        throw new Error('the message is required');
    }

    return await chatFetch({
        url: `/chat/api/v1/chat.sendMessage`,
        method: 'POST',
        errorMsg: 'An error occurred sending chat message',
        headers: setHeaders(userIdToken, {
            'content-type': 'application/json'
        }),
        body: JSON.stringify(message),
        returnDataCallback: (data) => get(data, 'message') || null
    });
};

export const postUpdateChatMessage = async (message, userIdToken) => {
    isDev && console.debug('[dev] chat postUpdateChatMessage'); // eslint-disable-line no-console
    
    if (!message) {
        throw new Error('the message is required');
    }

    return await chatFetch({
        url: `/chat/api/v1/chat.update`,
        method: 'POST',
        errorMsg: 'An error occurred sending chat message',
        headers: setHeaders(userIdToken, {
            'content-type': 'application/json'
        }),
        body: JSON.stringify(message),
        returnDataCallback: (data) => get(data, 'message') || null
    });
};

export const fetchFiles = async ({ rid, reqType, query, sort, offset, count }, userIdToken) => {
    isDev && console.debug('[dev] chat fetchFiles', rid); // eslint-disable-line no-console

    const pSort = JSON.stringify(sort || {});
    const pQuery = JSON.stringify(query || {});
    const pOffset = offset || 0;
    const pCount = count || 0;
    let url = '';

    switch (reqType) {
        case 'groups':
            url = `/chat/api/v1/groups.files?roomId=${rid}&count=${pCount}&offset=${pOffset}&sort=${pSort}&query=${pQuery}`;
            break;

        case 'im':
            url = `/chat/api/v1/im.files?roomId=${rid}&count=${pCount}&offset=${pOffset}&sort=${pSort}&query=${pQuery}`;
            break;

        case 'channels':
            url = `/chat/api/v1/channels.files?roomId=${rid}&count=${pCount}&offset=${pOffset}&sort=${pSort}&query=${pQuery}`;
            break;
        default:
            break;
    }

    return await chatFetch({
        url,
        method: 'GET',
        errorMsg: 'An error occurred getting the room files',
        headers: setHeaders(userIdToken),
    });
};

export const fetchUsers = async ({ sort, offset, count }, userIdToken) => {
    isDev && console.debug('[dev] chat fetchUsers'); // eslint-disable-line no-console

    const pSort = JSON.stringify(sort || {});
    const pOffset = offset || 0;
    const pCount = count || 0;

    return await chatFetch({
        url: `/chat/api/v1/users.list/?count=${pCount}&offset=${pOffset}&sort=${pSort}`,
        method: 'GET',
        errorMsg: 'An error occurred getting user list',
        headers: setHeaders(userIdToken),
        returnDataCallback: (data) => get(data, 'users') || null
    });
};

export const fetchChatHistory = async ({ rid, reqType, sort, offset, count }, userIdToken) => {
    isDev && console.debug('[dev] chat fetchChatHistory', rid); // eslint-disable-line no-console

    const pSort = JSON.stringify(sort || {});
    const pOffset = offset || 0;
    const pCount = count || 0;
    let url = '';

    switch (reqType) {
        case 'groups':
            url =  `/chat/api/v1/groups.history?roomId=${rid}&count=${pCount}&offset=${pOffset}&sort=${pSort}`;
            break;
        case 'im':
            url = `/chat/api/v1/im.history?roomId=${rid}&count=${pCount}&offset=${pOffset}&sort=${pSort}`;
            break;
        case 'channels':
            url = `/chat/api/v1/channels.history?roomId=${rid}&count=${pCount}&offset=${pOffset}&sort=${pSort}`;
            break;
        default:
            break;
    }

    return await chatFetch({
        url,
        method: 'GET',
        errorMsg: 'An error occurred getting the room message history',
        headers: setHeaders(userIdToken),
    });
};