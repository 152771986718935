import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
    MdiIcon, Divider, Input, Typography, Avatar, IconButton,
    List, ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction
} from '@mic3/platform-ui';

import { PrimaryClassesEntitiesLink } from 'app/components/atoms/Link/EntityLink';
import { useOnChange } from 'app/utils/hook/hooks';
import { get } from 'app/utils/lo/lo';

const Title = styled(Typography)`
padding-top: 12px;
padding-left: 15px;
`;

const Search = styled(Input)`
margin-top: 12px;
padding-left: 15px;
`;

const ChildIcon = styled(MdiIcon)`
padding-right: 4px;
`;

const DigitalTwinAssetListItem = ({ asset, entityId, setSelected }) => {
    const { id, type, name, image, parent, classes } = asset;
    const isChild = parent && String(parent.id) === String(entityId);
    const isDigitalTwin = classes && classes.find(c => c && c.uri === 'digital-twin');
    return (
        <ListItem>
            <ListItemAvatar>
                <Avatar src={image} initials={name} />
            </ListItemAvatar>
            <ListItemText
                primary={
                    <PrimaryClassesEntitiesLink
                        id={id}
                        type={type}
                        path={isDigitalTwin ? 'digital-twin' : ''}
                    >
                        {isChild && <ChildIcon size={12} name="sitemap" />}
                        {name}
                    </PrimaryClassesEntitiesLink>
                }
                secondary={`#${id}`}
            />
            <ListItemSecondaryAction>
                <IconButton
                    edge="end"
                    aria-label="highlight"
                    onClick={() => setSelected(get(asset, 'id'))}
                >
                    <MdiIcon name="marker" />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    );
};

const DigitalTwinAssets = (props) => {
    const { assets, missingAssets, setSelected } = props;
    const [search, onSearch] = useOnChange('');
    const assetList = useMemo(() => {
        if (!search) {
            return assets || [];
        }
        const pattern = search.toLowerCase();
        return (assets || []).filter(({ id, name }) => {
            if (Number.isInteger(Number(pattern))) {
                return String(id) === pattern && name.toLowerCase().includes(pattern);
            } else {
                return name.toLowerCase().includes(pattern);
            }
        });
    }, [assets, search]);
    const missingList = useMemo(() => {
        if (!search) {
            return missingAssets || [];
        }
        const pattern = search.toLowerCase();
        return (missingAssets || []).filter(uuid => uuid.includes(pattern));
    }, [missingAssets, search]);
    return (
        <>
            <Search placeholder="Search ..." fullWidth onChange={onSearch} value={search} />
            <List dense>
                {assetList.map(asset =>
                    <DigitalTwinAssetListItem
                        key={asset.id}
                        asset={asset}
                        entityId={props.id}
                        setSelected={setSelected} />
                )}
            </List>
            {
                missingList.length > 0 &&
                <>
                    <Divider />
                    <Title variant="h6">Unmatched Entities</Title>
                    <List dense>
                        {missingList.map(uuid =>
                            <ListItem key={uuid}>
                                <ListItemAvatar>
                                    <Avatar initials={'Entity not found'} />
                                </ListItemAvatar>
                                <ListItemText primary={'Entity not found'} secondary={uuid} />
                                <ListItemSecondaryAction>
                                    <IconButton
                                        edge="end"
                                        aria-label="highlight"
                                        onClick={() => setSelected(uuid)}
                                    >
                                        <MdiIcon name="marker" />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        )}
                    </List>
                </>
            }
        </>
    );
};

DigitalTwinAssets.propTypes = {
    id: PropTypes.string.isRequired,
    assets: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        image: PropTypes.string,
        classes: PropTypes.arrayOf(PropTypes.object),
    })),
    missingAssets: PropTypes.arrayOf(PropTypes.string),
    setSelected: PropTypes.func,
};


export default DigitalTwinAssets;
