/* @flow */
import { gql } from '@apollo/client';

import Immutable from 'app/utils/immutable/Immutable';
import OptionsBuilder from 'app/utils/api/OptionsBuilder';
import { loadData, mutateData, dispatchData, queryData, _getData } from 'app/utils/redux/action-utils';
import { get, keyBy } from 'app/utils/lo/lo';
import { getStr, isEmpty, isObject } from 'app/utils/utils';
import { showToastr } from 'store/actions/app/appActions';

import { graphql } from 'graphql/client';
import { getAvatar } from  'app/utils/avatar/avatar';
import loadEntityVersionsProcessQuery from 'graphql/entities/entities/loadEntityVersionsProcessQuery';
import loadEntityVersionsFormDefinitionQuery from 'graphql/entities/entities/loadEntityVersionsFormDefinitionQuery';
import loadEntityVersionsScriptQuery from 'graphql/entities/entities/loadEntityVersionsScriptQuery';
import loadEntityVersionsQuery from 'graphql/entities/entities/loadEntityVersionsQuery';
import entityHistoryQuery from 'graphql/entities/entities/entityHistoryQuery';
import entityClassificationQuery from 'graphql/entities/entities/entityClassificationQuery';
import getEntityTypeQuery from 'graphql/entities/common/getEntityTypeQuery';
import loadEntityQuery from 'graphql/entities/entities/loadEntityQuery';
import loadEntitiesTypeaheadQuery from 'graphql/entities/entities/loadEntitiesTypeaheadQuery';
import loadEntitiesGeotagButtonQuery from 'graphql/entities/entities/loadEntitiesGeotagButtonQuery';
import loadAvatarQuery from 'graphql/entities/entities/loadAvatarQuery';
import loadEntityWorkspacesQuery from 'graphql/entities/entities/loadEntityWorkspacesQuery';
import updateEntityMutation from 'graphql/entities/entities/updateEntityMutation';
import publishEntityMutation from 'graphql/entities/entities/publishEntityMutation';
import entityAddClassesMutation from 'graphql/entities/entities/entityAddClassesMutation';
import entityRemoveClassMutation from 'graphql/entities/entities/entityRemoveClassMutation';
import loadClassificationsListQuery from 'graphql/entities/common/classifications/loadClassificationsListQuery';
import workspaceClassificationsQuery from 'graphql/workspaces/workspaceClassificationsQuery';
import exportEntitiesMutation from 'graphql/entities/entities/exportEntitiesMutation';
import exportClassesMutation from 'graphql/entities/classifications/exportClassesMutation';
import exportEntitiesRelationsMutation from 'graphql/entities/entities/exportEntitiesRelationsMutation';
import exportEntitiesSharingMutation from 'graphql/entities/entities/exportEntitiesSharingMutation';
import exportTeamsWorkspacesMutation from 'graphql/teams/exportTeamsWorkspacesMutation';
import shareEntityWithTeamsMutation from 'graphql/entities/share/shareEntityWithTeamsMutation';
import shareEntityWithUsersMutation from 'graphql/entities/share/shareEntityWithUsersMutation';
import shareEntityWithWorkspacesMutation from 'graphql/entities/share/shareEntityWithWorkspacesMutation';
import unshareEntityWithTeamsMutation from 'graphql/entities/share/unshareEntityWithTeamsMutation';
import unshareEntityWithUsersMutation from 'graphql/entities/share/unshareEntityWithUsersMutation';
import unshareEntityWithWorkspacesMutation from 'graphql/entities/share/unshareEntityWithWorkspacesMutation';
import deleteEntityFileAttributeMutation from 'graphql/entities/entities/deleteEntityFileAttributeMutation';
import { updateWorkspace, addWorkspaceClasses, removeWorkspaceClasses } from 'store/actions/admin/workspacesActions';
import { uploadGQLImage } from 'store/actions/common/graphicLibraryActions';
import { addRoleToData, addRoleToList } from 'app/config/rolesConfig';
import { entitiesListShortQuery, entitiesListRaisedQuery, entitiesListRaisedTypeaheadQuery } from 'graphql/entities/entities/entitesListQueries';
import primaryClassesQuery from 'graphql/entities/classifications/primaryClassesQuery';
import createEntityMutation from 'graphql/entities/entities/createEntityMutation';
import classAncestorsQuery from 'graphql/entities/classifications/classAncestorsQuery';
import classificationIdsQuery from 'graphql/entities/classifications/classificationIdsQuery';
import { shareMapLayers, unShareMapLayers } from 'store/actions/maps/situationalAwarenessActions';
import countriesListQuery from 'graphql/entities/entities/countriesListQuery';
import { filterRecordsOnFE } from 'app/utils/filter/filterUtils';
import entityDetailsCommonQuery from 'graphql/entities/common/entityDetailsCommonQuery';
import loadEntityImageQuery from 'graphql/entities/entities/loadEntityImageQuery';


export const UPLOAD_IMAGE_STARTED = '@@affectli/entities/UPLOAD_IMAGE_STARTED';
export const UPLOAD_IMAGE = '@@affectli/entities/UPLOAD_IMAGE';

export const LOAD_ENTITY_ACTIVITIES_STARTED = '@@affectli/entities/LOAD_ENTITY_ACTIVITIES_STARTED';
export const LOAD_ENTITY_ACTIVITIES = '@@affectli/entities/LOAD_ENTITY_ACTIVITIES';

export const ADD_ENTITY_CLASS_STARTED = '@@affectli/entities/ADD_ENTITY_CLASS_STARTED';
export const ADD_ENTITY_CLASS = '@@affectli/entities/ADD_ENTITY_CLASS';

export const REMOVE_ENTITY_CLASS_STARTED = '@@affectli/entities/REMOVE_ENTITY_CLASS_STARTED';
export const REMOVE_ENTITY_CLASS = '@@affectli/entities/REMOVE_ENTITY_CLASS';

export const LOAD_ENTITY_HISTORY_STARTED = '@@affectli/entities/LOAD_ENTITY_HISTORY_STARTED';
export const LOAD_ENTITY_HISTORY = '@@affectli/entities/LOAD_ENTITY_HISTORY';

export const GET_ENTITY_TYPE_STARTED = '@@affectli/entities/GET_ENTITY_TYPE_STARTED';
export const GET_ENTITY_TYPE = '@@affectli/entities/GET_ENTITY_TYPE';

export const LOAD_ENTITIES_TYPEAHEAD_STARTED = '@@affectli/entities/LOAD_ENTITIES_TYPEAHEAD_STARTED';
export const LOAD_ENTITIES_TYPEAHEAD = '@@affectli/entities/LOAD_ENTITIES_TYPEAHEAD';

export const OUTDATE_ENTITY_DETAILS = '@@affectli/entities/OUTDATE_ENTITY_DETAILS';

export const LOAD_ENTITIES_DROPDOWN = '@@affectli/entities/LOAD_ENTITIES_DROPDOWN';

export const LOAD_AVATAR_STARTED = '@@affectli/entities/LOAD_AVATAR_STARTED';
export const LOAD_AVATAR = '@@affectli/entities/LOAD_AVATAR';

export const LOAD_ENTITY_CLASSIFICATION_STARTED = '@@affectli/entities/LOAD_ENTITY_CLASSIFICATION_STARTED';
export const LOAD_ENTITY_CLASSIFICATION = '@@affectli/entities/LOAD_ENTITY_CLASSIFICATION';

export const LOAD_ENTITIES_GEOTAGBUTTON_STARTED = '@@affectli/entities/LOAD_ENTITIES_GEOTAGBUTTON_STARTED';
export const LOAD_ENTITIES_GEOTAGBUTTON = '@@affectli/entities/LOAD_ENTITIES_GEOTAGBUTTON';

export const LOAD_ENTITY_STARTED = '@@affectli/entities/LOAD_ENTITY_STARTED';
export const LOAD_ENTITY = '@@affectli/entities/LOAD_ENTITY';

export const UPDATE_ENTITY_STARTED = '@@affectli/entities/UPDATE_ENTITY_STARTED';
export const UPDATE_ENTITY = '@@affectli/entities/UPDATE_ENTITY';

export const PUBLISH_ENTITY_STARTED = '@@affectli/entities/PUBLISH_ENTITY_STARTED';
export const PUBLISH_ENTITY = '@@affectli/entities/PUBLISH_ENTITY';

export const LOAD_CLASSIFICATIONS_LIST_STARTED: string = '@@affectli/entities/LOAD_CLASSIFICATIONS_LIST_STARTED';
export const LOAD_CLASSIFICATIONS_LIST: string = '@@affectli/entities/LOAD_CLASSIFICATIONS_LIST';

export const LOAD_ENTITY_WORKSPACES_STARTED: string = '@@affectli/entities/LOAD_ENTITY_WORKSPACES_STARTED';
export const LOAD_ENTITY_WORKSPACES: string = '@@affectli/entities/LOAD_ENTITY_WORKSPACES';

export const SHARE_ENTITY_WITH_TEAMS_STARTED: string = '@@affectli/entities/SHARE_ENTITY_WITH_TEAMS_STARTED';
export const SHARE_ENTITY_WITH_TEAMS: string = '@@affectli/entities/SHARE_ENTITY_WITH_TEAMS';

export const LOAD_ENTITIES_LIST_STARTED = '@@affectli/entities/LOAD_ENTITIES_LIST_STARTED';
export const LOAD_ENTITIES_LIST = '@@affectli/entities/LOAD_ENTITIES_LIST';

export const LOAD_COUNTRIES_LIST_STARTED = '@@affectli/entities/LOAD_COUNTRIES_LIST_STARTED';
export const LOAD_COUNTRIES_LIST = '@@affectli/entities/LOAD_COUNTRIES_LIST';

export const LOAD_PRIMARY_CLASSES_STARTED = '@@affectli/entities/LOAD_PRIMARY_CLASSES_STARTED';
export const LOAD_PRIMARY_CLASSES = '@@affectli/entities/LOAD_PRIMARY_CLASSES';

export const LOAD_ENTITY_TYPES_TYPEAHEAD_STARTED = '@@affectli/entities/LOAD_ENTITY_TYPES_TYPEAHEAD_STARTED';
export const LOAD_ENTITY_TYPES_TYPEAHEAD = '@@affectli/entities/LOAD_ENTITY_TYPES_TYPEAHEAD';

export const CREATE_ENTITY_STARTED = '@@affectli/entities/CREATE_ENTITY_STARTED';
export const CREATE_ENTITY = '@@affectli/entities/CREATE_ENTITY';

export const LOAD_ENTITY_SIDEBAR_STARTED = '@@affectli/entities/LOAD_ENTITY_SIDEBAR_STARTED';
export const LOAD_ENTITY_SIDEBAR = '@@affectli/entities/LOAD_ENTITY_SIDEBAR';

export const EXPORT_ENTITIES_STARTED = '@@affectli/entities/EXPORT_ENTITIES_STARTED';
export const EXPORT_ENTITIES = '@@affectli/entities/EXPORT_ENTITIES';

export const LOAD_ENTITY_VERSIONS_STARTED = '@@affectli/entities/LOAD_ENTITY_VERSIONS_STARTED';
export const LOAD_ENTITY_VERSIONS = '@@affectli/entities/LOAD_ENTITY_VERSIONS';

export const SET_ENTITY_DRAFT_VERSION = '@@affectli/entities/SET_ENTITY_DRAFT_VERSION';
export const SET_ENTITY_PREVIEW_VERSION = '@@affectli/entities/SET_ENTITY_PREVIEW_VERSION';

export const LOAD_ENTITY_IMAGE_STARTED = '@@affectli/entities/LOAD_ENTITY_IMAGE_STARTED';
export const LOAD_ENTITY_IMAGE = '@@affectli/entities/LOAD_ENTITY_IMAGE';


export const loadEntitiesListView = ({ isShort, options, type }: Object = { isShort: false }) => {
    if(['person', 'user'].includes(type)){
        options?.filterBy?.push({ field: 'isSystem', op: '=', value: false });
    }
    const variables = new OptionsBuilder(options)
        .build();
    return loadData(
        LOAD_ENTITIES_LIST_STARTED,
        LOAD_ENTITIES_LIST,
        isShort ? entitiesListShortQuery : entitiesListRaisedQuery,
        addRoleToList
    )({ ...variables, type });
};

export const loadCountriesList = () => (dispatch, getState) => {
    const list = getState().entities?.countries?.data || [];
    if (list?.length) return Promise.resolve(list); // Do not load countries if its already loaded
    return loadData(LOAD_COUNTRIES_LIST_STARTED, LOAD_COUNTRIES_LIST, countriesListQuery)()(dispatch, getState);
};

const _filterClasses = (payload) => {
    const { records: classes } = payload || {};
    return classes?.filter(cls => cls?.active && !cls.hidden && !cls.abstract);
};

export const loadPrimaryClasses = filterFn =>
    loadData(LOAD_PRIMARY_CLASSES_STARTED, LOAD_PRIMARY_CLASSES, primaryClassesQuery(), filterFn || _filterClasses)();

export const exportEntities = (type, options) => {
    let mutation = exportEntitiesMutation;
    switch (type) {
        case 'relations':
            mutation = exportEntitiesRelationsMutation;
            break;
        case 'workspace':
            mutation = exportTeamsWorkspacesMutation('exportWorkspaces');
            break;
        case 'team':
            mutation = exportTeamsWorkspacesMutation('exportTeams');
            break;
        case 'sharing':
            mutation = exportEntitiesSharingMutation;
            break;
        case 'class': case 'entityType':
            mutation = exportClassesMutation(type);
            break;
        default:
    }

    return mutateData(EXPORT_ENTITIES_STARTED, EXPORT_ENTITIES, mutation)(options);
};

export const loadEntityTypesTypeahead = loadData(LOAD_ENTITY_TYPES_TYPEAHEAD_STARTED, LOAD_ENTITY_TYPES_TYPEAHEAD, primaryClassesQuery());

export const createEntity = (record: Object, normalize = true) => {
    if (normalize)
        record = normalizeAttributesData(record);
    return mutateData(
        CREATE_ENTITY_STARTED,
        CREATE_ENTITY,
        createEntityMutation,
        'Entity created.',
    )({ record });
};

export const publishEntity = (id, type, successMessage) =>
    mutateData(
        PUBLISH_ENTITY_STARTED,
        PUBLISH_ENTITY,
        publishEntityMutation,
        successMessage || 'Entity published.',
    )({ id, type });


export const loadAvatar = (id, type) => (dispatch: Function, getState: Function) => {
    if (!id) {
        console.error('the parameter "id" is required'); // eslint-disable-line
        return;
    }
    const avatar = getAvatar(getState(), type, id);
    if (avatar && (avatar.isLoading || avatar.loadedAt > Date.now() - (60*60*1000))) {
        // if we are loding the avatar or it is already loaded and it is stored less then 1 hour ago
        // we return the data in the state without calling the service
        return Promise.resolve(avatar);
    }
    return loadData(LOAD_AVATAR_STARTED, LOAD_AVATAR, loadAvatarQuery)({ id, type })(dispatch, getState);
};


export const loadEntitiesTypeahead = ({ isShort, ...options }) => (dispatch, getState) => {
    let variables = { ...options };
    if(options.type === 'system_country') {
        const countries = getState().entities?.countries?.data || [];
        if (countries?.length) { // return the cached data if its already loaded
            if (options?.filterBy?.length) {
                return Promise.resolve(filterRecordsOnFE(countries, options?.filterBy)); // filter cached data
            }
            return Promise.resolve(countries);
        }
    }
    if(options.type === 'script') {
        variables = new OptionsBuilder(options)
            .filter({ field: 'lastVersion.id', op: 'is not null' })
            .build();
    }
    return loadData(LOAD_ENTITIES_TYPEAHEAD_STARTED, LOAD_ENTITIES_TYPEAHEAD, isShort ? 
        loadEntitiesTypeaheadQuery 
        : entitiesListRaisedTypeaheadQuery
    )(variables)(dispatch, getState);
};

export const loadEntityGeotagButton = (id, type) =>
    loadData(LOAD_ENTITIES_GEOTAGBUTTON_STARTED, LOAD_ENTITIES_GEOTAGBUTTON, loadEntitiesGeotagButtonQuery)({ id, type });

export const loadEntityClassifications = (id, type) => {
    return dispatchData(LOAD_ENTITY_CLASSIFICATION_STARTED, LOAD_ENTITY_CLASSIFICATION, null, async () => {
        const query = type === 'workspace' ? workspaceClassificationsQuery : entityClassificationQuery;
        const queryVars = type === 'workspace' ? { id } : { id, type };
        const response = await queryData(query, queryVars);
        const entityData = _getData(response);

        if (isEmpty(entityData)) return entityData;
        const classificationIds = get(entityData, 'classes', []).map(({ id }) => id);
        if (isEmpty(classificationIds)) return entityData;

        const variables = new OptionsBuilder()
            .filter({ field: 'id', op: 'in', value: classificationIds })
            .build();

        const filteredClassResponse = await queryData(classificationIdsQuery, variables);
        const filteredClassifications = _getData(filteredClassResponse);
        const filteredClassificationIds = filteredClassifications.records.map(({ id }) => id);
        if (isEmpty(filteredClassificationIds)) {
            entityData.classes = [];
            return entityData;
        }

        const classAncestorMap = filteredClassificationIds.map((classId) => {
            return queryData(classAncestorsQuery, { id: classId, onlyActive: true });
        });

        await Promise.all(classAncestorMap).then((response) => {
            const classesResponse = response.map(resp => _getData(resp));
            const classes = classesResponse.flat();
            const uniqueClasses = keyBy(classes, 'id');
            const classesArr = Object.keys(uniqueClasses).map((key => uniqueClasses[key]));
            entityData.classes = (classesArr || [])?.map(cls=> !classificationIds?.includes(cls.id) ? {...cls, inherited: true} : cls);
        });

        return entityData;
    });
};

/**
 * Add the given class to the specified entity
 *
 * @param id the Entity ID.
 * @param classes the Array of classes Ids.
 */
export const _addEntityClasses = (
    id: string,
    type: string,
    classes: Array<string>,
    attributes: Object
) => async (dispatch: Function, getState: Function) =>
    mutateData(
        ADD_ENTITY_CLASS_STARTED,
        ADD_ENTITY_CLASS,
        entityAddClassesMutation,
        classes.length > 1 ? 'Classes added.' : 'Class added.'
    )({ id, type, classes, attributes })(dispatch, getState).then(resp => {
        
        if (!(resp instanceof Error)) {
            dispatch(findAndReplaceEntity(resp?.entityAddClasses));
        }
    });

export const addEntityClasses = (id: string, type: string, classes: Array<string>, attributes: Object = {}) =>
    async (dispatch: Function, getState: Function) => {
        attributes = normalizeAttributesData(attributes, 'addEntityClasses' );
        if (type === 'workspace') {
            await dispatch(updateWorkspace({id, attributes }));
            return await dispatch(addWorkspaceClasses(id, classes));
        }
        return dispatch(_addEntityClasses(id, type, classes, attributes));
    };

/**
 * Remove the specified class from the specified entity.
 *
 * @param id the Entity ID.
 * @param classes the Array of classes Ids.
 */
export const _removeEntityClass = (
    id: number,
    type: string,
    classes: Array<string>,
    successMessage: string = 'Class removed.'
) => async (dispatch: Function, getState: Function) =>
    mutateData(
        REMOVE_ENTITY_CLASS_STARTED,
        REMOVE_ENTITY_CLASS,
        entityRemoveClassMutation,
        successMessage
    )({ id, type, classes })(dispatch, getState).then(resp => {
        if (!(resp instanceof Error)) {
            dispatch(findAndReplaceEntity(resp?.entityRemoveClasses));
        }
    });


export const removeEntityClass = (id: number, type: string, classes: Array<string>) => (dispatch: Function, getState: Function) => {
    if (type === 'workspace') {
        return dispatch(removeWorkspaceClasses(id, classes));
    }
    return dispatch(_removeEntityClass(id, type, classes));
};

/**
 * Upload the image for the specified Entity.
 *
 * @param entityId - the Entity ID (required)
 * @param entityType - the Entity type (required)
 * @param image - the image to attach (required)
 */
export const uploadImage = (entityId: string, entityType: string, image: File) => (dispatch: Function): Promise<Object> => {
    if (!entityId || !entityType || !image) {
        throw new Error(`Cannot upload the image: a required parameter is missing: id=${entityId}, type=${entityType}, image=${String(image)}`);
    }

    // if the file is not an image return an error
    if ( image.type.indexOf('image/') !== 0 ) {
        dispatch({
            type: UPLOAD_IMAGE,
            payload: new Error('Upload failed: the specified file is not an image.'),
            error: true,
            meta: Immutable({
                errorTitle: 'Upload failed',
                errorMessage: `The file "${image.name}" is not an image.`
            }),
        });
        const error = new Error(`The file "${image.name}" is not an image.`);
        return Promise.reject(error);
    }
    dispatch({ type: UPLOAD_IMAGE_STARTED, meta: Immutable({ entityId, entityType }) });
    const data = { id: entityId, image, type: entityType };
    const info = { entityId, image: '' };
    return uploadGQLImage(data)
        .then((response: Object) => {
            dispatch({
                type: UPLOAD_IMAGE,
                payload: Immutable(info),
                meta: Immutable({ entityId, entityType, successMessage: 'Image uploaded.' }),
            });
            return response;
        }).catch((error) => {
            dispatch({ type: UPLOAD_IMAGE, payload: error, error: true, meta: Immutable({ entityId, entityType, errorMessage: 'Image upload failed.' }) });
            return error;
        });
};

export const importEntities = (data: Object, mutationName: string = 'importEntities') => {
    const { name, description, file } = data;
    const variables = { name, description, file };
    const query = `mutation ($name: String, $description: String, $file: Upload!) { result: ${mutationName}(name: $name, description: $description, file: $file) { id name }}`;
    return graphql
        .upload({ query, variables, file, map: 'variables.file' });
};

export const getEntityType = (id: number) => loadData(GET_ENTITY_TYPE_STARTED, GET_ENTITY_TYPE, getEntityTypeQuery)({ id });

export const getPersonType = async (id: number, entityType: string) => {
    const response = await graphql.query({
        query: gql`query getPersonType($id: ID!){ person(id: $id) { type } }`,
        variables: { id },
        fetchPolicy: 'no-cache'
    });
    if (!(response instanceof Error)) {
        return getStr(response, 'data.person.type', entityType);
    }
    return entityType;
};

export const loadBackgroundJobStatus = async (id: number) => {
    const response = await graphql.query({
        query: gql`query backgroundJobQuery($id: ID!){ entity(type: "backgroundjob", id: $id){ id name primary(fields: ["status"])}}`,
        variables: { id },
        fetchPolicy: 'no-cache'
    });
    if (!(response instanceof Error)) {
        return get(response, 'data.entity');
    }
    return Promise.resolve();
};

export const loadEntity = (type: string, id: string) =>
    loadData(LOAD_ENTITY_STARTED, LOAD_ENTITY, loadEntityQuery, addRoleToData)({ id, type });

export const loadEntityImage = (type: string, id: string) =>
    loadData(LOAD_ENTITY_IMAGE_STARTED, LOAD_ENTITY_IMAGE, loadEntityImageQuery)({ id, type });

export const loadEntityInternal = (type: string, id: string) =>
    loadData(LOAD_ENTITY_SIDEBAR_STARTED, LOAD_ENTITY_SIDEBAR, loadEntityQuery, addRoleToData)({ id, type });

export const fetchMapEntity = (type: string, id: string) => queryData(loadEntityQuery, {type, id});

const _updateBaseEntity = (record: Object) =>
    mutateData(
        LOAD_ENTITY_STARTED,
        LOAD_ENTITY,
        updateEntityMutation,
        'Entity updated.',
        addRoleToData
    )({ record });



export const updateEntityFileAttribute = (options) => (dispatch: Function) => {
    const { type, id, file, attributeName, isPrimary, internal } = options;
    const query = `
        mutation updateEntityFileAttributeMutation(
            $type: String!,
            $id: ID!,
            $attributeName: String!,
            $isPrimary: Boolean!, 
            $file: Upload!) {
            result: addEntityFileAttribute(
                type: $type,
                id: $id,
                attributeName: $attributeName,
                isPrimary: $isPrimary,
                file: $file){
                type
                ${entityDetailsCommonQuery}
            }
        }
    `;
    const variables = { type, id, file: null, attributeName, isPrimary };
    return graphql.upload({ query, variables, file, map: 'variables.file' })
        .then(async (response: Object) => {
            if (response instanceof Error) return response;
            dispatch(showToastr({ severity: 'success', detail: 'File uploaded successfully.' }));
            try {
                let payload = response?.data?.result;
                if (payload) {
                    if (isPrimary) {
                        payload = await addRoleToData(payload);
                        internal ? dispatch({ type: LOAD_ENTITY_SIDEBAR, payload }) : 
                            dispatch({ type: LOAD_ENTITY, payload });
                    } else {
                        !isPrimary && dispatch(loadEntityClassifications(id, type));
                    }
                }
            } catch (error) {}
            return response;
        })
        .catch((error: Object) => {
            const detail = error?.toString()?.includes('exceeds the 104857600 byte size limit')
                ? 'File size is too large.'
                : 'File upload failed.';
            dispatch(showToastr({ severity: 'error', detail }));
        });
};

export const deleteEntityFileAttribute = (options, refresh = true) => (dispatch, getState) => 
    mutateData(
        'DELETE_FILE_ATTRIBUTE_STARTED',
        'DELETE_FILE_ATTRIBUTE',
        deleteEntityFileAttributeMutation,
        refresh && 'File deleted.',
        addRoleToData
    )(options)(dispatch, getState).then(async (response) => {
        if (response instanceof Error || !refresh) return response;
        const { id, type, isPrimary, internal } = options || {};
        if (id && type) {
            !isPrimary && dispatch(loadEntityClassifications(id, type)); // 
            if(isPrimary) {
                let payload = { ...(response || {})};
                payload = await addRoleToData(payload);
                internal ?  dispatch({ type: LOAD_ENTITY_SIDEBAR, payload }) : 
                    dispatch({ type: LOAD_ENTITY, payload });
            }
        }
        return response;
    });



const _updateBaseEntityInternal = (record: Object) =>
    mutateData(
        LOAD_ENTITY_SIDEBAR_STARTED,
        LOAD_ENTITY_SIDEBAR,
        updateEntityMutation,
        'Entity updated.',
        addRoleToData
    )({ record });


const _normalizeData = (item) => {
    if(!isObject(item)) return item;
    const { id, name, type, username, isRelations, uri, src, image, color } = item || {};
    if(!id && Object.keys(item)?.length){
        return processAttributes(item);
    }
    let att = { id, name };
    if (username) att.username = username;
    if (type) att.type = type;
    if (uri) att.uri = uri;
    if (isRelations) att.isRelations = isRelations;
    if (src) att = item;
    if(image) att.image = image;
    if(color) att.color = color;
    return att;
};

const processSingleAttribute = (item) => {
    if(!isObject(item)) return item;
    if(item.id) {
        return _normalizeData(item);
    }
    Object.keys(item).forEach(key => { // In case of group repeat
        const value = item[key];
        if(Array.isArray(value)){
            item[key] = value.map(processSingleAttribute);
        } else {
            item[key] = _normalizeData(value);
        }
    });
    return item;
};
const processAttributes = (obj) => {
    if (!obj) return;
    const _obj = {...obj};
    Object.keys(_obj).forEach(key => {
        if(isObject(_obj[key]) && isEmpty(_obj[key])){
            _obj[key] = null;
        } else {
            const item = _obj[key] || null;
            if ( item && Array.isArray(item) && item?.length ) {
                _obj[key] = item.map(processSingleAttribute).filter(Boolean);
            } else if(item) {
                _obj[key] = processSingleAttribute(item);
            }
        }
    });
    delete _obj.modifiedDate;
    return _obj;
};
export const normalizeAttributesData = (entity, moduleName) => {
    if (!entity) return {};
    
    let data = JSON.parse(JSON.stringify(entity));
    if (data.attributes) {
        data.attributes = processAttributes(data.attributes);
    }
    if (data.primary) {
        data.primary = processAttributes(data.primary);
    }
    if(moduleName === 'addEntityClasses'){
        data = processAttributes(data);
    }
    delete data.modifiedDate;
    delete data.modifiedBy;
    return data;
};

    
export const updateEntity = (entity: Object, internal: boolean, refreshMainSection: boolean, normalize = true) => (dispatch, getState) => {
    let data = { ...entity };
    delete data.chatId;
    if (normalize)
        data = normalizeAttributesData(data);
    const { geom, ...record } = data;
    if (record.type === 'workspace') {
        const { id, attributes } = record;
        return dispatch(updateWorkspace({ id, attributes }));
    }
    return dispatch(internal ? _updateBaseEntityInternal(record) : _updateBaseEntity(record)).then((payload) => {
        const originalDetails = getState().entities.sidebar?.details?.data || {};
        if (refreshMainSection && originalDetails.id === payload.id) {
            if (internal) {
                return dispatch({ type: LOAD_ENTITY, payload });
            }
            return dispatch({type: LOAD_ENTITY_SIDEBAR, payload });
        }
        return Promise.resolve(payload);
    });
};

export const loadClassificationsList = (options: Object = {}, entityType: string, withParents = false) => {
    const variables = new OptionsBuilder(options)
        .filter({ field: 'active', op: '=', value: true })
        .defaultStartStopIndexs(0, 30);
    if (entityType === 'entityType') {
        return loadData(LOAD_CLASSIFICATIONS_LIST_STARTED, LOAD_CLASSIFICATIONS_LIST, primaryClassesQuery())();
    }
    if(entityType !== 'class') {
        if (entityType === 'workspace') {
            variables.filter({field: 'applicableOn', op: 'contains', value: ['workspace']});
        } else {
            variables.filter({field: 'applicableOn', op: 'overlaps', value: ['entity', entityType]});
        }
    }

    return loadData(LOAD_CLASSIFICATIONS_LIST_STARTED, LOAD_CLASSIFICATIONS_LIST, loadClassificationsListQuery(withParents))({
        ...variables.build(),
        startIndex: options.startIndex
    });
};

export const clearClassesList = () => (dispatch: Function) =>
    dispatch({ type: LOAD_CLASSIFICATIONS_LIST });

const addUsersAndTeamsRoles = (payload, meta) => {
    const { workspaces: records } = payload || {};
    const workspaces = [], users = [], teams = [];
    if (records?.length) {
        records.forEach(({ user, team, _role: role, ...workspace }) => {
            if (!team && !user) {
                workspaces.push(workspace);
            } else if (user) {
                users.push({ ...user, role });
            } else if (team) {
                teams.push({ ...team, role });
            }
        });
    }
    return { ...payload, workspaces, users, teams };
};

export const loadEntityWorkspaces = (type: string, id: string) =>
    loadData(LOAD_ENTITY_WORKSPACES_STARTED, LOAD_ENTITY_WORKSPACES, loadEntityWorkspacesQuery(type, id), addUsersAndTeamsRoles)({ id, type });

const withSorNot = (arr) => {
    return arr.length > 1 ? 's' : '';
};

export const shareEntityWithUsers = (type: string, id: string, userMembers: Array<string>, entityType: boolean) => (dispatch, getState) => {
    return mutateData(
        LOAD_ENTITY_WORKSPACES_STARTED,
        LOAD_ENTITY_WORKSPACES,
        shareEntityWithUsersMutation(type),
        `${(entityType) ? 'Entity type' : (type === 'class' && 'Class') || 'Entity'} shared with user${withSorNot(userMembers)}.`,
        addUsersAndTeamsRoles
    )(type !== 'class' ? { id, type, userMembers } : { id, userMembers })(dispatch, getState).then(async (response) => {
        if(response instanceof Error) return response;
        if(type === 'map') await shareMapLayers(id, userMembers, 'users');
        return response;
    });
};
    

export const shareEntityWithTeams = (type: string, id: string, teamMembers: Array<Object>, entityType: boolean) => (dispatch, getState) => {
    return mutateData(
        LOAD_ENTITY_WORKSPACES_STARTED,
        LOAD_ENTITY_WORKSPACES,
        shareEntityWithTeamsMutation(type),
        `${(entityType) ? 'Entity type' : (type === 'class' && 'Class') || 'Entity'} shared with team${withSorNot(teamMembers)}.`,
        addUsersAndTeamsRoles
    )(type !== 'class' ? { id, type, teamMembers } : { id, teamMembers })(dispatch, getState).then(async (response) => {
        if(response instanceof Error) return response;
        if(type === 'map') await shareMapLayers(id, teamMembers, 'teams');
        return response;
    });
};

export const shareEntityWithWorkspaces = (type: string, entityType: boolean, id: string, workspaces: Array<string>) => (dispatch, getState) => {
    return mutateData(
        LOAD_ENTITY_WORKSPACES_STARTED,
        LOAD_ENTITY_WORKSPACES,
        shareEntityWithWorkspacesMutation(type),
        `${(entityType) ? 'Entity type' : (type === 'class' && 'Class') || 'Entity'} shared with workspace${withSorNot(workspaces)}.`,
        addUsersAndTeamsRoles
    )(type !== 'class' ? { id, type, workspaces } : { id, workspaces })(dispatch, getState).then(async (response) => {
        if(response instanceof Error) return response;
        if(type === 'map') await shareMapLayers(id, workspaces, 'workspaces');
        return response;
    });
};

export const unshareEntityWithUsers = (type: string, id: string, entityType: boolean, users: Array<string>) =>  (dispatch, getState) => {
    return mutateData(
        LOAD_ENTITY_WORKSPACES_STARTED,
        LOAD_ENTITY_WORKSPACES,
        unshareEntityWithUsersMutation(type),
        `${(entityType) ? 'Entity type' : (type === 'class' && 'Class') || 'Entity'} removed from user${withSorNot(users)}.`,
        addUsersAndTeamsRoles
    )(type !== 'class' ? { id, type, users } : { id, users })(dispatch, getState).then(async (response) => {
        if(response instanceof Error) return response;
        if(type === 'map') await unShareMapLayers(id, users, 'users');
        return response;
    });
};

export const unshareEntityWithTeams = (type: string, id: string, entityType: boolean, teams: Array<string>) =>  (dispatch, getState) => {
    return mutateData(
        LOAD_ENTITY_WORKSPACES_STARTED,
        LOAD_ENTITY_WORKSPACES,
        unshareEntityWithTeamsMutation(type),
        `${(entityType) ? 'Entity type' : (type === 'class' && 'Class') || 'Entity'} removed from team${withSorNot(teams)}.`,
        addUsersAndTeamsRoles
    )(type !== 'class' ? { id, type, teams } : { id, teams })(dispatch, getState).then(async (response) => {
        if(response instanceof Error) return response;
        if(type === 'map') await unShareMapLayers(id, teams, 'teams');
        return response;
    });
};

export const unshareEntityWithWorkspaces = (type: string, id: string, entityType: boolean, workspaces: Array<string>) => (dispatch, getState) => {
    return mutateData(
        LOAD_ENTITY_WORKSPACES_STARTED,
        LOAD_ENTITY_WORKSPACES,
        unshareEntityWithWorkspacesMutation(type),
        `${(entityType) ? 'Entity type' : (type === 'class' && 'Class') || 'Entity'} removed from workspace${withSorNot(workspaces)}.`,
        addUsersAndTeamsRoles
    )(type !== 'class' ? { id, type, workspaces } : { id, workspaces })(dispatch, getState).then(async (response) => {
        if(response instanceof Error) return response;
        if(type === 'map') await unShareMapLayers(id, workspaces, 'workspaces');
        return response;
    });
};

export const loadEntityHistory = (id: string, options: Object) => (dispatch, getState) => {
    const variables = new OptionsBuilder(options)
        .filter({ field: 'entity.id', op: '=', value: id })
        .filter({ field: 'entity.type', op: '=', value: options.entityType })
        .build();
    return loadData(LOAD_ENTITY_HISTORY_STARTED, LOAD_ENTITY_HISTORY, entityHistoryQuery)(variables)(dispatch, getState);
};

export const loadEntityVersions = (id, type, options: Object) => (dispatch, getState) => {
    const variables = new OptionsBuilder(options)
        .defaultOrder({ field: 'createdDate', direction: 'desc nulls last' });
    let query = loadEntityVersionsQuery;
    switch (type) {
        case 'processdefinition':
            variables.filter({ field: 'primary.processDefinition', op: '=', value: id });
            query = loadEntityVersionsProcessQuery;
            break;
        case 'formdefinition':
            variables.filter({ field: 'primary.formDefinition', op: '=', value: id });
            query = loadEntityVersionsFormDefinitionQuery;
            break;
        case 'script':
            variables.filter({ field: 'primary.script', op: '=', value: id });
            query = loadEntityVersionsScriptQuery;
            break;
        default:
            variables.filter({ field: `primary.${type}_version/resource_id`, op: '=', value: id });
            variables.populate({ type: `${type}_version` });
    }
    return loadData(LOAD_ENTITY_VERSIONS_STARTED, LOAD_ENTITY_VERSIONS, query)({
        ...variables.build(), 
    })(dispatch, getState);
};

export const setEntityDraftVersion = (type, details) => (dispatch, getState) => {
    dispatch({
        type: SET_ENTITY_DRAFT_VERSION,
        payload: { type, details },
    });
};

export const setEntityPreviewVersion = (type, details) => (dispatch, getState) => {
    dispatch({
        type: SET_ENTITY_PREVIEW_VERSION,
        payload: { type, details },
    });
};

export const findAndReplaceEntity = (entity) => async (dispatch, getState) => {
    const { id, type } = entity || {};
    if(!type || !id) return;
    const { records:_records, count} = getState().entities.entities;
    if (!_records?.length) return;
    const records = _records?.map(record => record?.id === id ? {...record, ...entity} : record);
    return dispatch({ type: LOAD_ENTITIES_LIST, payload: { records, count }});
};
