import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Tooltip, IconButton, MdiIcon, Badge } from '@mic3/platform-ui';
import Icon from 'app/components/atoms/Icon/Icon';
import styled from 'styled-components';
import { muiTheme } from 'app/themes/materialUi';
import { withRouter } from 'react-router';

import { isEmpty } from 'app/utils/utils';
import { get } from 'app/utils/lo/lo';
import { bind, memoize, debounce} from 'app/utils/decorators/decoratorUtils';
import { NoSelectedBoard, IconStyled, FiltersBoard, StyledContentArea, BoardsLandingSection, StyledButton, StyledInfo, InfoIcon } from './styles';
import { addRoleToData, getPermissions } from 'app/config/rolesConfig';
import { isVisibleTab } from 'app/utils/entity/entityUtils';

import { filterDefinitions, defaultFilters, defaultOrder } from 'app/components/organisms/TasksView/TasksView';
import BoardView from 'app/components/organisms/BoardView/BoardView';
import PageTemplate from 'app/components/templates/PageTemplate';
import AddNewBoardModal from 'app/containers/Abox/Boards/AddNewBoardModal';

import withPermissions from 'app/utils/withPermissions';
import { loadBoardColumns, loadColumnsTasks, deleteRelationAttributeFromEntity, createRelationAttributeToEntity, loadAllBoardColumns, setSelectedBoard} from 'store/actions/abox/boardActions';
import { openTaskSidebar } from 'store/actions/abox/taskSidebarActions';
import { openEntitySidebar, closeEntitySidebar } from 'store/actions/entities/entitySidebarActions';
import { saveBoardPreferences } from 'store/actions/admin/usersActions';
import { openMenuSidebar } from 'store/actions/entities/menuSidebarActions';
import { loadEntity } from 'store/actions/entities/entitiesActions';

import { BOARD_RELATION_DEFINITION_ID } from 'app/config/config';
import { setDocumentTitle } from 'store/actions/app/appActions';

export const ContentAreaStyled = styled(StyledContentArea)`
    ${({ selectedBoard }) => (!selectedBoard ? 'display: flex;' : '')}
    ${({ selectedBoard }) => (!selectedBoard ? 'align-items: center;' : '')}
    ${({ selectedBoard }) => (!selectedBoard ? 'justify-content: center;' : '')}
`;


const VIEW_ID = 'DefaultBoards';

class DefaultBoards extends PureComponent<Object, Object> {
    static propTypes = {
        userProfile: PropTypes.object,
        setBoardColumnState: PropTypes.func,
        setBoardColumnLoading: PropTypes.func,
        setSelectedBoard: PropTypes.func,
        selectedBoard: PropTypes.object,
        loadBoardColumns: PropTypes.func,
        loadAllBoardColumns: PropTypes.func,
        loadCustomEntityBoardLabelTasks: PropTypes.func,
        boardColumns: PropTypes.array,
        deleteRelationAttributeFromEntity: PropTypes.func,
        showRelationEntityToTask: PropTypes.func,
        allBoardColumns:PropTypes.array,
        preferences: PropTypes.object,
        saveBoardPreferences: PropTypes.func
    };

    static defaultProps = {
        boardColumns: null,
        boardColumnState: null,
        boardsLeftPanel: null,
        boardTasks: null,
        isLoadingBoardColumns: false,
        selectedBoard: null,
        isLoading:false,
    };

    state = {
        feFilters: [],
        disableAddColumn: false,
        groupBy: null,
        menuDefinitions: null,
        permissions: {},
        isVisibleAlive: false,
        projectMenu: [],
    };

    searchBar = ['name', 'id'];
    defaultFilters = defaultFilters;
    defaultOrder = defaultOrder;
    filterDefinitions = filterDefinitions.map(filter => {
        if(filter.properties.name === 'relatedEntities') {
            return { ...filter, 
                properties: { ...filter.properties },
                joinGroup: 'relateddef'
            };
        }
        return filter;
    });

    componentDidUpdate(prevProps, prevState) {
        const { selectedBoard, boardColumns,isSidebarOpen, closeEntitySidebar, setDocumentTitle } = this.props;
        const { projectMenu } = this.state;
        const { id, type, name } = selectedBoard || {};
        if(name){
            setDocumentTitle(name);
        }
        if(selectedBoard !== prevProps.selectedBoard) {
            this.setState({ groupBy: 'none'});
            id && type && this.loadBoardPermissions(id, type);
            this.reloadColumns();
            if(isSidebarOpen) closeEntitySidebar();
        }
        if(boardColumns !== prevProps.boardColumns) {
            this.buildBoardMenuItems();
        }
        if( projectMenu && projectMenu !== prevState.projectMenu){
            this.buildBoardMenuItems();
        }
    }

    componentDidMount() {
        const { preferences, setSelectedBoard } = this.props;
        const board = get(preferences, 'boards.board');
        if (board) {
            const { id, type } = board;
            id && type && this.loadBoardPermissions(id, type);
            setSelectedBoard(board);
            this.reloadColumns();
        }
    }
    componentWillUnmount(){
        const { setSelectedBoard } = this.props;
        setSelectedBoard(null);
    }

    @bind
    openMenu() {
        const { selectedBoard, openMenuSidebar } = this.props;
        openMenuSidebar({
            menuDefinitions: this.state.menuDefinitions,
            hideDotMenu: true,
            id: selectedBoard.id,
            type: selectedBoard.type,
            title: 'View Settings',
            reloadList: () => {}
        });
    }

    @bind
    @debounce()
    async reloadColumns(refreshColumns = true, buildMenuItems = true) {
        const { selectedBoard, loadBoardColumns } = this.props;
        if(selectedBoard) {
            await loadBoardColumns({ entityId: selectedBoard.id });
        }
        if(refreshColumns) {
            await this.loadAllColumns();
        }
        this.buildBoardMenuItems();
    }

    @bind
    async loadAllColumns() {
        const { loadAllBoardColumns } = this.props;
        await loadAllBoardColumns();
    }

    @bind
    handleChangeColumns(column) {
        const { boardColumns } = this.props;
        if(column.isChecked){
            var relationId = boardColumns.find(c =>c.id === column.id).relationId;
            if(relationId) this.onHideColumn(relationId);
        }
        else this.onShowColumns(column.id);
    }

    @bind
    handleChangeSwimlanes(groupBy) {
        const{ preferences, saveBoardPreferences} = this.props;
        const swimlane = groupBy?.primaryText?.toLowerCase();
        this.setState({ groupBy: swimlane !== 'none' ? swimlane : null});
        saveBoardPreferences({...preferences, boards: {...preferences.boards, groupBy: swimlane }});
    }

    @bind
    @memoize()
    buildBreadcrumbs(name) {
        return [{ title: 'Board' }, name && { title: name }].filter(Boolean);
    }

    @bind
    projectMenu(tasks){
        let description = tasks.flatMap((item)=>
            item.relations
                .filter(rel=> rel.relation.relationDefinition.relatedType.includes('task'))
                .filter(rel=> !rel.relation.relationDefinition.type.includes('task'))
                .map(rel=> rel.relation.relationDefinition.description)
        );
        description = [...new Set(description)];
        const menu = description.map((item, key)=> {
            return {id: key + 4, primaryText: item, type: 'text', name: 'swimlanes', onClick: this.handleChangeSwimlanes}; 
        });
        this.setState({ projectMenu: menu });
        this.buildBoardMenuItems();
    }

    @bind
    buildBoardMenuItems() {
        const { allBoardColumns, boardColumns } = this.props;
        const { projectMenu } = this.state;
        this.setState({
            menuDefinitions: {
                menu: [
                    { primaryText: 'Show & hide columns', iconName: 'eye', menu: allBoardColumns.map((clmn, index) => {
                        const column = boardColumns.find(c => c?.id === clmn.id);
                        const relationId = column?.relationId;
                        return {
                            id: clmn.id,
                            relationId,
                            primaryText: clmn.name,
                            type: 'checkbox',
                            isChecked: !!column,
                            name: 'columns',
                            onClick: this.handleChangeColumns
                        };
                    })
                    },
                    { primaryText: 'Set Swimlane by status', iconName: 'reorder-horizontal' , menu: [
                        {id:0, primaryText: 'None', type: 'text', name: 'swimlanes', onClick: this.handleChangeSwimlanes },
                        {id:1, primaryText: 'Assignee', type: 'text', name: 'swimlanes', onClick: this.handleChangeSwimlanes },
                        {id:2, primaryText: 'Priority', type: 'text', name: 'swimlanes', onClick: this.handleChangeSwimlanes },
                        {id:3, primaryText: 'Process', type: 'text', name: 'swimlanes', onClick: this.handleChangeSwimlanes },
                        ...projectMenu
                    ]},
                ]
            }
        });
    }

    @bind
    onCloseModal() {
        this.setState({ isModalOpen: false });
    }

    @bind
    onOpenModal() {
        this.setState({ isModalOpen: true });
    }

    @bind
    openSidebar(id: string, title: string, columnId: string) {
        return id && this.props.openTaskSidebar({ title, id, reloadList: () => this.reloadColumns(true,false)});
    };

    @bind
    async onHideColumn(relationId) {
        const { deleteRelationAttributeFromEntity } = this.props;
        const response = await deleteRelationAttributeFromEntity(relationId);
        if (response instanceof Error) return;

        this.reloadColumns(false);
    }

    @bind
    async onShowColumns(columnId) {
        const { selectedBoard, boardColumns, createRelationAttributeToEntity } = this.props;
        const records = boardColumns;
        const columnLastIndexOrder = !isEmpty(records || records.slice(-1)[0].order) ? records.slice(-1)[0].order : records.length;

        const response = await createRelationAttributeToEntity({
            relationDefinition: BOARD_RELATION_DEFINITION_ID,
            entity: { type: 'custom', id: columnId },
            relatedEntity: { type: 'custom', id: selectedBoard?.id },
            attributes: {
                'board-board-label/order': columnLastIndexOrder + 1
            }
        });
        if (response instanceof Error) return;

        this.reloadColumns(false);
    }

    @bind
    openChatSidebar(e, title){
        e.stopPropagation();
        const { selectedBoard, openEntitySidebar } = this.props;
        const { id, type } = selectedBoard;
        const params = { id, type, title };
        openEntitySidebar(params);
    }


    @bind
    renderBoardOptions(selectedBoard, menuDefinitions){
        return (
            <>
                <Tooltip title="Options">
                    <IconButton onClick={this.openMenu}>
                        <MdiIcon name='tune' color={muiTheme.colors.text.secondary} />
                    </IconButton>
                </Tooltip>
            </>
        );
    }

    @bind
    renderBoardAlive(){
        return (
            <>
                <Tooltip title="A-Live">
                    <IconButton 
                        onClick={e=> this.openChatSidebar(e, 'A-Live') }
                    >
                        <Badge color="error" >
                            <Icon name="messenger" type="af" />
                        </Badge>
                    </IconButton>
                </Tooltip>
            </>
        );
    }

    @bind
    async navigateToBetaBoards(){
        const { setSelectedBoard } = this.props;
        await setSelectedBoard(null);
        this.props.history.push(`/abox/beta-kanbanboards`);
    }

    @bind
    @memoize()
    async loadBoardPermissions(id, type) {
        if(id && type){
            const { role } = await addRoleToData({ id, type });
            const selectEntity = await this.props.loadEntity(type, id);
            const { primaryClass } = selectEntity;
            const isVisibleAlive =  isVisibleTab('A-Live', primaryClass);
            const permissions = getPermissions(role);
            this.setState({ permissions, isVisibleAlive });
        }
    }

    render() {
        const { selectedBoard, boardColumns, preferences, boardsLeftPanel } = this.props;
        const { isModalOpen, groupBy, menuDefinitions, permissions, isVisibleAlive } = this.state;
        const { canComment, canEdit } = permissions;
        return (
            <>
                <FiltersBoard
                    id={VIEW_ID}
                    filterDefinitions={this.filterDefinitions}
                    searchBar={this.searchBar}
                    defaultFilters={this.defaultFilters}
                    defaultOrder={this.defaultOrder}
                    breadcrumb={this.buildBreadcrumbs(get(preferences, 'boards.board.name'))}
                    rightToolbar={
                        <>
                            <StyledButton variant='text' color='secondary' onClick={this.navigateToBetaBoards}>
                                Beta Version
                            </StyledButton>

                            { selectedBoard && this.renderBoardOptions(selectedBoard, menuDefinitions) }
                            { isVisibleAlive && (canComment || canEdit) && selectedBoard && this.renderBoardAlive()}
                        </>
                    }>
                    {(filterBy, orderBy, excludeBy) => {
                        return(
                            <PageTemplate title="Boards (Beta)">
                                {!selectedBoard &&
                                   <ContentAreaStyled heightOffset={5}>
                                       <BoardsLandingSection>
                                           <div style={{textAlign: 'center'}}>
                                               <NoSelectedBoard>
                                                   <IconStyled type={'af'} name={'Kanban'} />
                                                    Let's Get Started<br/>
                                                   {boardsLeftPanel?.length > 0 &&
                                                        <StyledInfo>
                                                            <InfoIcon name={'information-outline'} hexColor={'#6b717e'}/> Select a board from the left panel
                                                        </StyledInfo>
                                                   }
                                               </NoSelectedBoard>
                                           </div>
                                       </BoardsLandingSection>
                                   </ContentAreaStyled>
                                }
                                {selectedBoard && boardColumns?.length === 0 &&
                                   <ContentAreaStyled heightOffset={5}>
                                       <BoardsLandingSection>
                                           <div style={{textAlign: 'center'}}>
                                               <NoSelectedBoard>
                                                   <IconStyled  type={'af'} name={'Kanban'} />
                                                    Let's Get Started
                                                   <br/>
                                                   {
                                                       <StyledButton variant='text' color='secondary' onClick={this.openMenu}> Add columns on the board </StyledButton>
                                                   }
                                               </NoSelectedBoard>
                                           </div>
                                       </BoardsLandingSection>
                                   </ContentAreaStyled>
                                }
                                {selectedBoard && boardColumns.length > 0 &&
                                    <StyledContentArea heightOffset={5}>
                                        <BoardView
                                            id={VIEW_ID}
                                            selectedBoard={selectedBoard}
                                            columns={boardColumns}
                                            filterBy={filterBy}
                                            orderBy={orderBy}
                                            excludeBy={excludeBy}
                                            groupBy={get(preferences, 'boards.groupBy') ?? groupBy}
                                            openSidebar={this.openSidebar}
                                            projectMenu={this.projectMenu}
                                        />
                                    </StyledContentArea>
                                }
                            </PageTemplate>
                        );
                    }}
                </FiltersBoard>
                {isModalOpen && <AddNewBoardModal onCloseModal={this.onCloseModal} />}
            </>
        );
    }
}
export default connect(state =>({
    selectedBoard: state.abox.boards.boardsOption.selectedBoard,
    boardColumns: state.abox.boards.boardsOption.boardColumns.records,
    boardsLeftPanel: state.leftPanel.boards?.data?.records || [],
    userProfile: state.user.profile,
    preferences: state.user.preferences,
    allBoardColumns:state.abox.boards.allColumns.records,
    isSidebarOpen: state.sidebar.isOpen,
}), {
    loadBoardColumns,
    loadAllBoardColumns,
    loadColumnsTasks,
    openTaskSidebar,
    closeEntitySidebar,
    openEntitySidebar,
    deleteRelationAttributeFromEntity,
    createRelationAttributeToEntity,
    setSelectedBoard,
    saveBoardPreferences,
    openMenuSidebar,
    loadEntity,
    setDocumentTitle,
},
)(withRouter(withPermissions(DefaultBoards, 'task')));
