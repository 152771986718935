/* @flow */

import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { IconButton, Badge } from '@mic3/platform-ui';

import history from 'store/History';
import { openEntitySidebar } from 'store/actions/entities/entitySidebarActions';
import { openTeamSidebar } from 'store/actions/entities/teamSidebarActions';
import { openWorkspaceSidebar } from 'store/actions/entities/workspaceSidebarActions';
import { openTaskSidebar } from 'store/actions/abox/taskSidebarActions';
import { openProcessSidebar } from 'store/actions/abox/processSidebarActions';
import { openRelationSidebar } from 'store/actions/entities/relationSidebarActions';
import { loadEntity } from 'store/actions/entities/entitiesActions';
import { closeLeftPanel, setShowBack } from 'store/actions/leftPanel/leftPanelActions';
import { loadEntityPrintTemplates } from 'store/actions/entities/printTemplateActions';
import { setTreeEntity } from 'store/actions/entities/relatedEntitiesActions';
import { setDocumentTitle } from 'store/actions/app/appActions';

import { get } from 'app/utils/lo/lo';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { getStr, isEmpty } from 'app/utils/utils';
import { getUnreadMessages } from 'app/utils/entity/entityUtils';
import { getPermissions } from 'app/config/rolesConfig';
import { capitalizeFirstLetter } from 'app/utils/utils';
import { isVisibleTab, buildDotMenu } from 'app/utils/entity/entityUtils';
import { getSelectedPrimaryClass } from 'app/utils/classification/classificationUtils';
import { getSubscriptionByRel } from 'app/utils/chat/chatUtils';

import AttachmentsView from 'app/containers/Common/Attachments/AttachmentsView';
import ClassificationsTab from 'app/containers/Common/ClassificationsTab/ClassificationsTab';
import EntityLocation from 'app/containers/Entities/EntityLocation/EntityLocation';
import EntityDigitalTwin from 'app/containers/Entities/DigitalTwin/EntityDigitalTwin';
import EntityTimeline from 'app/containers/Entities/Timeline/EntityTimeline';
import PageNotAllowed from 'app/containers/ErrorPages/PageNotAllowed';
import Relations from 'app/containers/Entities/Relationships/Relations';
import EntityPrimaryAbout from 'app/containers/Entities/EntityAbout/EntityPrimaryAbout';

import DotMenu from 'app/components/molecules/DotMenu/DotMenu';
import Icon from 'app/components/atoms/Icon/Icon';
import Loader from 'app/components/atoms/Loader/Loader';
import PageTemplate from 'app/components/templates/PageTemplate';
import Breadcrumbs from 'app/components/organisms/Breadcrumbs/Breadcrumbs';
import ChatSharing from 'app/components/organisms/Chat/ChatSharing';
import Chat from 'app/components/organisms/Chat/Chat';

class EntitiesDetail extends PureComponent<Object, Object> {
    static propTypes = {
        id: PropTypes.string.isRequired,
        loadEntity: PropTypes.func.isRequired,
        details: PropTypes.object,
        isLoading: PropTypes.bool
    };
    state = {
        entityType: ''
    };

    constructor(props: Object) {
        super(props);
        const entityType = getStr(props, 'match.params.type');
        const currentPath = getStr(this.props, 'match.path');
        const isRelatedEntity = currentPath.includes('related-entities');
        this.state = { entityType, isRelatedEntity };
        props.loadEntity(entityType, props.id);
        this.loadPrintTempaltes();
        this.mounted = false;
    }

    componentDidUpdate(prevProps) {
        const { id, leftPanelOpts = {}, closeLeftPanel, setShowBack, setDocumentTitle, details } = this.props;
        const entityType = getStr(this.props, 'match.params.type');
        const entityName = details?.name;
        if(entityName){
            setDocumentTitle(entityName);
        }
        const prevEntityType = getStr(prevProps, 'match.params.type');
        if (id !== prevProps.id || entityType !== prevEntityType) {
            this.loadPrintTempaltes();
            this.setState({ entityType }, () => this.props.loadEntity(entityType, id));
        }
        if (leftPanelOpts.isOpen && !this.state.isRelatedEntity) {
            closeLeftPanel();
            setShowBack(false);
        }
    }

    componentDidMount() { this.mounted = true; }

    @bind
    loadPrintTempaltes() {
        const entityType = getStr(this.props, 'match.params.type');
        const selectedClass = getSelectedPrimaryClass(entityType, this.props.primaryClasses);
        if(selectedClass) {
            this.props.loadEntityPrintTemplates(selectedClass.id);
        }
    }

    @bind
    reloadDetails() {
        const { id } = this.props;
        return this.props.loadEntity(this.state.entityType, id);
    }

    @bind
    @memoize()
    renderActions(unreadMessages, permissions, details) {
        if (!permissions?.canComment) {
            return null;
        }
        const { primaryClass } = details || {};
        return (isVisibleTab('A-Live', primaryClass)) ? (
            <IconButton key={66} title="Open messenger" onClick={() => this.onDotMenuClick('A-Live')}>
                <Badge color="error" badgeContent={unreadMessages} max={99}>
                    <Icon name="messenger" type="af" />
                </Badge>
            </IconButton>
        ) : null;
    }

    @bind
    onDotMenuClick(title, params) {
        const { id, openEntitySidebar } = this.props;
        const { entityType } = this.state;
        if (title === 'Digital Twin') {
            history.push(`/entities/${entityType}/${id}/digital-twin`);
            return;
        }
        if (title === 'Relations') {
            history.push(`/entities/${entityType}/${id}/relationships`);
            return;
        }
        if (title === 'Go to Related Entities') {
            const { id, name, image, type, modifiedDate, primary } = { ...this.props.details };
            this.props.setTreeEntity({ id, name, image, type, modifiedDate, primary });
            history.push(`/related-entities`);
            return;
        }

        const settings = { title, id, type: entityType, internal: true, params };
        openEntitySidebar(settings);
    }

    @bind
    @memoize()
    buildDotMenu(title, details, pathname, entityTemplates) {
        return buildDotMenu({ details, title, pathname, entityTemplates });
    }

    @bind
    @memoize()
    dotMenu(sidebarTitle: string, details: Object, pathname: string = '', entityTemplates) {
        return <DotMenu key={13} onItemClick={this.onDotMenuClick} items={this.buildDotMenu(sidebarTitle, details, pathname, entityTemplates)} />;
    }

    @bind
    buildBreadcrumbs(name, pathTitle, entityTypeName, entityType) {
        const classUri = entityTypeName || capitalizeFirstLetter(entityType);
        const breadcrumbs = pathTitle ? [{ title: name, link: `/entities/${entityType}/${this.props.id}` }, { title: pathTitle }] : [{ title: name }];
        let createdLink ='';
        
        switch(entityType){
            case 'print-template': createdLink = `/print-templates`;
                break;  
            case 'backgroundjob': createdLink =  `/abox/background-jobs/all`;
                break; 
            default: createdLink = `/entities/${entityType}`;
        }
        
        return <Breadcrumbs list={[{ link: createdLink, title: classUri }, ...breadcrumbs ]} withGoBack />;
    }

    /**
     * @override
     */
    render() {
        const {
            openRelationSidebar, openEntitySidebar,
            id, isLoading, details, sidebarTitle, subscriptions, subscription,
            match, openTaskSidebar, openProcessSidebar, entityTemplates, openTeamSidebar, openWorkspaceSidebar
        } = this.props;
        const { entityType } = this.state;
        const permissions = getPermissions(details && details.role);
        if ((!isLoading || !entityType) && (isEmpty(details) || !permissions.canView)) {
            return <PageNotAllowed permissionError={true} title={`Entities (ID:${id})`} />;
        }
        const { canEdit } = permissions || {};
        const { rel, rid } = subscription || {};
        const { pathname } = this.props.location;
        const unreadMessages = getUnreadMessages(id, subscriptions, entityType);
        const showDetails = this.mounted && details && details.id === id;
        const breadcrumbLine = this.buildBreadcrumbs(details.name, null, details.primaryClass?.name, entityType);
        const sidebarActions = [
            this.renderActions(unreadMessages, permissions, details), 
            this.dotMenu(sidebarTitle, details, pathname, entityTemplates)
        ];

        return (
            <Fragment>
                {isLoading && <Loader absolute backdrop />}
                {showDetails && (
                    <PageTemplate title={details.name} subTitle={`#${details.id}`} overflowHidden>
                        <Switch>
                            <Route path={`${match.url}`} exact component={() => <Redirect to={`${match.url}/about`} />} />
                            <Route path={`${match.url}/about`}>
                                <EntityPrimaryAbout
                                    openEntitySidebar={openEntitySidebar}
                                    openTeamSidebar = {openTeamSidebar}
                                    openWorkspaceSidebar={openWorkspaceSidebar}
                                    openTaskSidebar={openTaskSidebar}
                                    openProcessSidebar={openProcessSidebar}
                                    type={entityType}
                                    id={id}
                                    details={details}
                                    sidebarActions={sidebarActions}
                                    breadcrumbLine={breadcrumbLine}
                                    canEdit={canEdit}
                                    openSidebar={this.onDotMenuClick}
                                />
                            </Route>
                            <Route path={`${match.url}/sharing`}>
                                <>
                                    {breadcrumbLine}
                                    <ChatSharing
                                        details={details}
                                        type={entityType}
                                        id={id}
                                        name={details.name}
                                        canEdit={canEdit}
                                        rid={rid} 
                                        rel={rel} 
                                        role={details?.role}
                                        selectedSidebar="share" 
                                    />
                                </>
                            </Route>
                            <Route path={`${match.url}/chat`}>
                                <>
                                    {breadcrumbLine}
                                    <Chat
                                        details={details}
                                        type={entityType}
                                        id={id}
                                        name={details.name}
                                        isMainContent
                                    />
                                </>
                            </Route>
                            <Route path={`${match.url}/classifications`}>
                                <>
                                    {breadcrumbLine}
                                    <ClassificationsTab
                                        details={details}
                                        type={entityType}
                                        id={id}
                                        name={details.name}
                                        canEdit={canEdit}
                                        reloadDetails={this.reloadDetails}
                                    />
                                </>
                            </Route>
                            <Route path={`${match.url}/locations`}>
                                <EntityLocation
                                    details={details}
                                    type={entityType}
                                    id={id}
                                    name={details.name}
                                    canEdit={canEdit}
                                    reloadDetails={this.reloadDetails}
                                />
                            </Route>
                            <Route path={`${match.url}/relationships`} render={() => (
                                <Relations
                                    openEntitySidebar={openEntitySidebar}
                                    openTeamSidebar={openTeamSidebar}
                                    openWorkspaceSidebar={openWorkspaceSidebar}
                                    openTaskSidebar={openTaskSidebar}
                                    openProcessSidebar={openProcessSidebar}
                                    openRelationSidebar={openRelationSidebar}
                                    fromType={entityType}
                                    fromId={id}
                                    canEdit={canEdit}
                                    details={details}
                                    sidebarActions={sidebarActions}
                                />
                            )} />
                            <Route path={`${match.url}/attachments`}>
                                <>
                                    {breadcrumbLine}
                                    <AttachmentsView
                                        id={id}
                                        type="entity"
                                        entityType={entityType}
                                        details={details}
                                        reloadDetails={this.reloadDetails}
                                        permissions={permissions}
                                    />
                                </>
                            </Route>
                            <Route path={`${match.url}/digital-twin`}>
                                <EntityDigitalTwin
                                    type={entityType}
                                    entity={details}
                                    sidebarActions={sidebarActions}
                                    breadcrumbLine={breadcrumbLine}
                                />
                            </Route>
                            <Route path={`${match.url}/history`}>
                                <>
                                    {breadcrumbLine}
                                    <EntityTimeline
                                        entityId={id}
                                        entityType={entityType}
                                        details={details}
                                        reloadDetails={this.reloadDetails}
                                    />
                                </>
                            </Route>
                        </Switch>
                    </PageTemplate>
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = (state: Object, ownProps: Object) => {
    const subscriptions = state.chat.subscriptions.data;
    const details = get(state.entities.sidebar, 'details.data', {});
    const { id, type } = details || {};
    const { id: propsId, type: propsType } = ownProps;
    const subscription = getSubscriptionByRel(subscriptions, { type: propsType || type, id: propsId || id });

    return {
        id: ownProps.match.params.id,
        isLoading: state.entities.sidebar.details.isLoading,
        details: details,
        sidebarTitle: state.sidebar.title,
        leftPanelOpts: state.leftPanel.state,
        subscriptions: subscriptions,
        subscription: subscription,
        entityTemplates: state.entities.printTemplates.entity.records,
        primaryClasses: state.app.allPrimaryClasses.records || [],
    };
};

export default connect(mapStateToProps, {
    openRelationSidebar, openTaskSidebar, openProcessSidebar,
    loadEntity, openEntitySidebar, closeLeftPanel, setShowBack, loadEntityPrintTemplates, setTreeEntity, openWorkspaceSidebar,
    openTeamSidebar, setDocumentTitle,
})(withRouter(EntitiesDetail));
