// @flow

import React, { Fragment, useMemo, useCallback } from 'react';
import {
    Divider, MdiIcon, Button
} from '@mic3/platform-ui';
import styled from 'styled-components';

import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';

import FormField from 'app/containers/Designer/Form/components/FormField';
import { connect } from 'react-redux';

const fieldify = (Component: Object) => (props: Object) => <FormField {...props} Component={Component} />;

const MdiIconStyled = styled(MdiIcon)`
line-height: 24px;
`;

const GroupWrapperStyled = styled.div`
width: 100%;
position: relative;
`;

const GroupRepeatRemoveButtonStyled = styled(MdiIcon)`
position: absolute !important;
right: 5px;
top: 0px;
z-index: 1;
line-height: 16px !important;
height: 24px !important
width: 27px !important;
cursor: pointer;
`;

const DividerStyled = styled(Divider)`
margin-top: 8px !important;
margin-bottom: 8px !important;
`;

const GroupRepeaItem = React.forwardRef((props: Object, reference) => {
    const {
        rowDirection, name, index, removeRepeated, component, onFormTouched, onStartChanges, onEndChanges,
        newData, context, changeData, inherited, addButtons, disabled, editablePanel, editable, isMobile,
        avoidValidation
    } = props;
    const showRemoveButton = addButtons && !disabled;
    const inheritedUpdated = useMemo(() => ({
        ...inherited,
        index
    }), [index, inherited]);
    return (
        <Fragment>
            <GroupWrapperStyled>
                {
                    showRemoveButton && (
                        <GroupRepeatRemoveButtonStyled
                            onClick={removeRepeated(name, index)}
                            size={20}
                            name="minus-circle" />
                    )
                }
                <FormGenerator
                    avoidValidation={avoidValidation}
                    onStartChanges={onStartChanges}
                    onEndChanges={onEndChanges}
                    ref={reference}
                    name={`${name}[${index}]`}
                    components={component.children}
                    data={newData}
                    context={context}
                    rowDirection={!isMobile && !!rowDirection}
                    onChange={(data, change) => {
                        if (!change.name) {
                            return Promise.resolve();
                        }
                        return changeData({
                            name: `${name}[${index}].${change.name}`,
                            value: change.value,
                        });
                    }}
                    onFormTouched={onFormTouched}
                    root={false}
                    inherited={inheritedUpdated}
                    disabled={disabled}
                    editablePanel={editablePanel}
                    editable={editable}
                />
            </GroupWrapperStyled>
        </Fragment>
    );
});

const GroupWrapper = styled.div`
width: 100%;
`;

const GroupRepeat = ({
    name, addButtons, addDividers, disabled, dataList,
    changeData, pushSubFormReferences, data, ...restProps
}: Object) => {
    const GroupWrapperFieldify = useMemo(() => fieldify(GroupWrapper), []);
    const addRepeated = useCallback((name: string) => {
        return () => {
            if (name) {
                changeData({ name, value: [...dataList, {}] });
            }
        };
    }, [changeData, dataList]);

    const removeRepeated = useCallback((name: string, index: number) => {
        return () => {
            const value = [...dataList];
            if (value.splice && value[index]) {
                value.splice(index,1);
            }
            changeData({ name, value });
        };
    }, [changeData, dataList]);

    const buildList = useMemo(() => (dataList || []).map((newData, index) => {
        const reference = React.createRef();
        pushSubFormReferences(reference);
        return (
            <GroupRepeaItem {...restProps}
                ref={reference}
                newData={newData}
                key={index}
                addRepeated={addRepeated}
                removeRepeated={removeRepeated}
                changeData={changeData}
                disabled={disabled}
                name={name}
                index={index}
                addButtons={addButtons}
            />
        );
    }), [addButtons, addRepeated, changeData, dataList, disabled, name, pushSubFormReferences, removeRepeated, restProps]);
    const showAddButton = addButtons && !disabled;
    return (
        <GroupWrapperFieldify data={data}>
            {buildList}
            {showAddButton && (
                <Button
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    onClick={addRepeated(name)}
                >
                    Add <MdiIconStyled name="plus" />
                </Button>
            )}
            {addDividers && <DividerStyled />}
            
        </GroupWrapperFieldify>
    );
};

export default connect( state => ({ isMobile: state.global.isMobile }),{}) (GroupRepeat);
