/* @flow */

import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Typography } from '@mic3/platform-ui';

import { closeLeftPanel } from 'store/actions/leftPanel/leftPanelActions';
import { closeSidebar } from 'store/actions/sidebar/sidebarActions';
import {
    loadRoomMessages,
    setMessagesAsRead,
    subscribe,
    uploadRoomFile,
    sendRoomMessage,
    sendChatMessage,
    uploadRcRoomFile,
    joinPublicChannel,
    unsubscribeEntityChat,
    unsubscribeGroupChat,
    unsubscribeChannelChat,
    subscribeStreamRoomMessages,
    unsubscribeStreamRoomMessages,
    updateChatMessage,
    getMentionsSpotlight
} from 'store/actions/chat/chatActions';

import { getSubscriptionByRel, getRoomImages, KEYS, filterMentionedUsers, focusChatInput, getCurrentWord, getInitialRoomMembers,
    getSelectedMessage, setInputTextMention, 
    setChatInputCursorToLast} from 'app/utils/chat/chatUtils';
import { bind, debounce, memoize } from 'app/utils/decorators/decoratorUtils';
import { DATE_FORMAT, formatDate, isSameDay } from 'app/utils/date/date';
import { removeSpecialCharacters } from 'app/utils/string/string-utils';
import { isEmpty, deepEquals } from 'app/utils/utils';
import { get } from 'app/utils/lo/lo';
import { getPermissions } from 'app/config/rolesConfig';

import DropzoneWrapper from 'app/components/molecules/Dropzone/DropzoneWrapper';
import AliveChatItem from 'app/components/organisms/Chat/AliveChatItem';
import ChatHeader from 'app/components/organisms/Chat/ChatHeader';
import Loader from 'app/components/atoms/Loader/Loader';
import ChatFilePreview from 'app/components/organisms/Chat/ChatFilePreview';
import ChatFileUploadModal from 'app/components/organisms/Chat/ChatFileUploadModal';
import ChatMentions from './ChatMentions';
import ChatFooter from './ChatFooter';
import ChatAccessDenied from './ChatAccessDenied';
import ChatValidAccessPermission from './ChatValidAccessPermission';

const ChatContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    & > ul.MuiList-root {
        display: none;
    }
`;

const ChatGrid = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const ChatMainBody = styled.div`
    position: relative;
    flex-grow: 1;
    min-height: 0;
    padding: 8px 0;
`;

const ChatBodyStyled = styled.div`
    ${({ messages }) => (!messages ? 'display: flex; align-items: center; justify-content: center;' : '')}
    height: 100%;
    padding: 0 8px;
    flex-grow: 1;
    overflow: auto;
`;

const NoMessage = styled.div`
    font-size: 24px;
    font-weight: 200;
    color: ${({theme})=> theme.material.colors.text.caption};
    @media (max-width:481px){
        font-size: 18px;
    }
`;

const ChatNewDayTitle = styled(Typography)`
    && {
        font-size: 10px;
        font-weight: 500;
        padding: 0 0 24px 8px;
        color: ${({ theme }) => theme.material.palette.text.secondary};
    }
`;

const DropzoneWrapperStyled = styled(DropzoneWrapper)`
    height: 100%;
`;

const SubscribeOnChatWrapper = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 99;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    flex-shrink: 0;
    justify-content: center;
    background: ${({ theme }) => theme.material.colors.background.default};
`;

const SecondaryTextStyled = styled.p`
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.15000000596046448px;
    margin: 0 0 10px 0;
`;

const AliveItemWrapper = styled.div``;

class AliveChat extends PureComponent<Object, Object> {
    static propTypes = {
        id: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        role: PropTypes.string,
        subscription: PropTypes.shape({
            unread: PropTypes.number,
            rid: PropTypes.string,
            name: PropTypes.string,
            updatedAt: PropTypes.string,
            rel: PropTypes.shape({
                id: PropTypes.string.isRequired,
                type: PropTypes.string.isRequired,
                name: PropTypes.string,
                image: PropTypes.string,
            })
        }),
        messages: PropTypes.array,
        images: PropTypes.array,
        uploadRoomFile: PropTypes.func.isRequired,
        uploadRcRoomFile: PropTypes.func.isRequired,
        joinPublicChannel: PropTypes.func.isRequired,
        unsubscribeEntityChat: PropTypes.func,
        unsubscribeGroupChat: PropTypes.func,
        unsubscribeChannelChat: PropTypes.func,
        subscribeStreamRoomMessages: PropTypes.func,
        unsubscribeStreamRoomMessages: PropTypes.func,
        isOpenLeftPanel: PropTypes.bool,
        isLoadingMessages: PropTypes.bool,
        closeLeftPanel: PropTypes.func,
        updateChatMessage: PropTypes.func,
    };

    messageRef: Object = React.createRef();
    messageBodyRef: Object = React.createRef();
    chatInputRef = React.createRef();

    state = {
        inputValue: '',
        inputPreviousValue: '',
        editActive: false,
        editMsgId: '',
        files: [],
        selectedFileId: null,
        firstLoad: true,
        singleMessageAdded: false,
        firstRowMessage: null,
        justEdited: false,
        mentionUsers: [],
        spotlightUsers: [],
        isMentionsOpen: false,
        mentionsUserListIndex: -1
    };

    formFields = [
        {
            field: 'filename',
            type: 'text',
            properties: {
                name: 'filename',
                label: 'File Name'
            },
            constraints: { required: true }
        },
        {
            field: 'description',
            type: 'text',
            properties: {
                name: 'description',
                label: 'File Description'
            }
        }
    ];

    constructor(props: Object) {
        super(props);
        this.init(this.props);
    }

    componentDidMount() {
        document.addEventListener('visibilitychange', this.setScrollToBottom);

        if (this.messageRef.current) {
            this.messageRef.current.addEventListener('paste', this.attachPasted, false);
        }

        this.handleLeftPanelBehavior();
    }

    componentWillUnmount() {
        document.removeEventListener('visibilitychange', this.setScrollToBottom);
        
        if (this.messageRef.current) {
            this.messageRef.current.removeEventListener('paste', this.attachPasted);
        }
    }

    componentDidUpdate(prevProps: Object, prevState: Object) {
        const { messages, subscription, subscribeStreamRoomMessages, unsubscribeStreamRoomMessages, loadRoomMessages } = this.props;
        const rid = get(subscription, 'rid');
        const unread = get(subscription, 'unread', 0);

        const prevRid = get(prevProps, 'subscription.rid');
        const prevUnread = get(prevProps, 'subscription.unread', 0);
        
        if (rid && rid !== prevRid) {
            this.handleLeftPanelBehavior();
            this.resetState();
            this.readUnreadMessages(this.props);
            loadRoomMessages(subscription);
            subscribeStreamRoomMessages(rid);
            if (prevRid) unsubscribeStreamRoomMessages(prevRid);
            return;
        }

        if (unread > prevUnread) {
            this.readUnreadMessages(this.props);
            return;
        } 

        const { singleMessageAdded, firstLoad, firstRowMessage, justEdited } = this.state;

        if (messages !== prevProps.messages) {
            this.readUnreadMessages(this.props);

            if (justEdited) {
                this.setState({ justEdited: false });
                return;
            }
            
            if (firstLoad) {
                this.scrollToBottom();

                if (!document.hidden) {
                    setTimeout(() => {
                        this.setState({ firstLoad: false });
                    }, 1000);
                }
            } else {
                let offsetIndex = messages.length - 1;
                
                if (firstRowMessage && firstRowMessage.id !== messages[0]?.id) {
                    const firstRowMessageIndex = messages.findIndex(msg => msg.id === firstRowMessage.id);
                    offsetIndex = firstRowMessageIndex;
                }
    
                this.scrollToOffset(offsetIndex);
            }

            if (messages.length > 0) {
                this.setState({ firstRowMessage: messages[0] });
            }

            return;
        }

        if (singleMessageAdded) {
            this.scrollToBottom();
            this.resetState();
        }
    }

    @bind
    init(props) {
        const { subscription, loadRoomMessages, subscribeStreamRoomMessages } = props;
        
        if (subscription && subscription.rid) {
            this.readUnreadMessages(props);
            loadRoomMessages(subscription);
            subscribeStreamRoomMessages(subscription.rid);
        }
    }

    @bind
    resetState() {
        this.setState({ 
            firstRowMessage: null,
            firstLoad: true, 
            firstRowMessage: null,
            singleMessageAdded: false,
            editActive: false,
            editMsgId: '',
            inputValue: '',
            inputPreviousValue: ''
        });
    }

    @bind
    scrollToOffset(offsetIndex) {
        if (!this.messageBodyRef || !this.messageBodyRef.current) return;
        setTimeout(() => {
            const parentDiv = this.messageBodyRef.current;
            if (parentDiv) {
                const childDivs = parentDiv.querySelectorAll('.alive-item');
                const alignOffset = 60;

                if (childDivs[offsetIndex]) {
                    parentDiv.scrollTop = childDivs[offsetIndex].offsetTop - alignOffset;
                }
            }
        }, 200);
    }

    @bind
    scrollToBottom(timer = 1000) {
        if (!this.messageBodyRef || !this.messageBodyRef.current) return;
        setTimeout(() => {
            const parentDiv = this.messageBodyRef.current;
            if (parentDiv) {
                parentDiv.scrollTop = parentDiv.scrollHeight;
            }
        }, timer);
    }

    @bind
    @debounce()
    closeLeftPanel() {
        this.props.closeLeftPanel();
    }

    @bind
    handleLeftPanelBehavior() {
        const { isMobile, isOpenLeftPanel } = this.props;
        
        if (isMobile && isOpenLeftPanel) {
            this.closeLeftPanel();
        }
    }

    @debounce(500)
    readUnreadMessages(props) {
        const { subscription } = props;
        
        if (subscription && subscription.unread) {
            this.props.setMessagesAsRead(subscription.rid);
        }
    }
    
    @bind
    closeFileForm() { 
        const { loadRoomMessages, subscription } = this.props;

        this.setState({ files: [], singleMessageAdded: true }, () => {
            loadRoomMessages(subscription);
        });
    }

    @bind
    setScrollToBottom() {
        if (!document.hidden && this.state.firstLoad) {
            this.setState({ firstLoad: false });
            this.scrollToBottom(500);
        }
    }

    @bind
    attachPasted(event) {
        const items = event.clipboardData && event.clipboardData.items;
        const size = (items && items.length) || 0;

        for (let i = 0; i < size; ++i) {
            const item = items[i];

            if (item.type.startsWith('image')) {
                const file = item.getAsFile();
                this.uploadFiles(file);
            }
        }
    }

    @bind
    uploadFiles(files) {
        if (files instanceof File) {
            this.setState(prevState => ({ 
                files: [
                    ...prevState.files, 
                    new File([files], removeSpecialCharacters(files.name), { type: files.type })
                ]
            }));
        }
        
        const fileArray = Array.isArray(files) ? files : Object.values(files);
        
        this.setState(prevState => ({ 
            files: [
                ...prevState.files,
                ...fileArray.map(file => new File([file], removeSpecialCharacters(file.name), { type: file.type }))
            ] 
        }));
    }

    @bind
    async uploadFile({ file, filename, description }) {
        const { id, type, uploadRoomFile, loadRoomMessages, uploadRcRoomFile, subscription } = this.props;
        const { rel: { type: relType } = {}, customFields } = subscription || {};
        const isEntityChat = !isEmpty(customFields);
        
        if (!relType) throw new Error('Invalid request, chat type is undefined.');

        let parsedFilename = removeSpecialCharacters(filename);

        try {
            if (isEntityChat) {
                const fileExtension = file.name.split('.').pop();

                if (fileExtension !== parsedFilename.split('.').pop()) {
                    parsedFilename = `${parsedFilename}.${fileExtension}`;
                }
                
                file = new File([file], parsedFilename, { type: file.type });

                await uploadRoomFile({ type, id, file, description });
            } else {
                await uploadRcRoomFile({ type, id, file, filename: parsedFilename, description });
            }
            
            this.setState({ singleMessageAdded: true });
            loadRoomMessages(subscription); 
        } catch (error) {}

        const files = [...this.state.files];
        files.shift();
        this.setState({ files });
    }

    @bind
    closeChat() {
        this.props.closeSidebar();
    }

    @bind
    openImagePreview(fileId) {
        this.setState({ selectedFileId: fileId });
    }

    @bind
    closeImagePreview() {
        this.setState({ selectedFileId: null });
    }

    @bind
    loadMoreMessages() {
        const { firstLoad } = this.state;
        const offset = this.messageBodyRef.current.scrollTop;

        if (offset === 0 && !firstLoad && !this.props.allMessagesLoaded) {
            const { messages, subscription } = this.props;
            const startTimestamp = formatDate(messages[0].createDate, 'x');
            const endTimestamp = formatDate(messages[messages.length - 1].createDate, 'x');
            this.props.loadRoomMessages(subscription, startTimestamp, endTimestamp, null, true);
        }
    }

    @bind
    chatInputChange(event) {
        const { value } = event.target;
        if (!this.state.editActive) {
            this.setState({ inputPreviousValue: value  });
        }
        this.setState({ inputValue: value });
        this.openMentionsPopup();
    }

    @bind
    chatInputPress(event) {
        const { isMentionsOpen, mentionsUserListIndex, mentionUsers, editActive, inputValue } = this.state;
        const key = event.key;

        if (this.props.isMobile) return;

        if (key === KEYS.ENTER && !event.shiftKey) {
            event.stopPropagation();
            event.preventDefault();

            if (!isMentionsOpen) {
                this.sendMessage();
                return;
            }

            const { username } = mentionUsers[mentionsUserListIndex] || {};
            const { newText: newMessage, cursorPosition } = setInputTextMention(this.chatInputRef, username);
            if (!editActive) {
                this.setState({ inputPreviousValue: newMessage });
            }
            this.setState({ inputValue: newMessage });
            setTimeout(() => this.chatInputRef.current.setSelectionRange(cursorPosition, cursorPosition), 1);
            this.closeMentions();
        }
        
        if (isMentionsOpen) {
            let newUserIndex = -1;

            switch (key) {
                case KEYS.ESCAPE:
                    this.closeMentions();
                    break;
                case KEYS.DOWN:
                    event.preventDefault();
                    newUserIndex = (mentionsUserListIndex === mentionUsers.length - 1) ? 0 : Math.min(mentionsUserListIndex + 1, mentionUsers.length - 1);
                    this.setState({ mentionsUserListIndex: newUserIndex });
                    break;
                case KEYS.UP:
                    event.preventDefault();
                    newUserIndex = (mentionsUserListIndex === 0) ? mentionUsers.length - 1 : Math.max(mentionsUserListIndex - 1, -1);
                    this.setState({ mentionsUserListIndex: newUserIndex });
                    break;
                case KEYS.LEFT:
                    this.openMentionsPopup();
                    break;
                case KEYS.RIGHT:
                    this.openMentionsPopup();
                    break;
                default:
                    break;
            }
        } else {
            const cursorLocation = this.chatInputRef.current.selectionStart;
            
            switch (key) {
                case KEYS.UP:
                    if (cursorLocation === 0) {
                        event.preventDefault();
                        this.handleOnEdit(true, null, 'up');
                    }
                    break;
                case KEYS.DOWN:
                    if (inputValue.length === cursorLocation) {
                        this.handleOnEdit(true, null, 'down');
                        if (editActive) {
                            setTimeout(() => this.chatInputRef.current.setSelectionRange(0, 0), 1);
                        }
                    }
                    break;
                case KEYS.ESCAPE:
                    if (editActive) this.handleOnEdit(false);
                    break;
                case KEYS.LEFT:
                    this.openMentionsPopup();
                    break;
                case KEYS.RIGHT:
                    this.openMentionsPopup();
                    break;
                default:
                    break;
            }
        }
    }

    @bind
    closeMentions() {
        this.setState({ isMentionsOpen: false, mentionsUserListIndex: -1, spotlightUsers: [] });
        this.setMentionUsers(this.props.messages, []);
    }

    @bind
    setMentionUsers(messages, users) {
        const initialUsers = getInitialRoomMembers(messages);
        const mentionUsers = filterMentionedUsers(this.chatInputRef, initialUsers, users);
        this.setState({ mentionUsers });
    }

    @bind
    selectMentionUser(username) {
        const { newText: newMessage, cursorPosition } = setInputTextMention(this.chatInputRef, username);
        if (!this.state.editActive) {
            this.setState({ inputPreviousValue: newMessage });
        }
        this.setState({ inputValue: newMessage });
        setTimeout(() => this.chatInputRef.current.setSelectionRange(cursorPosition, cursorPosition), 1);
        this.closeMentions();
        focusChatInput(this.chatInputRef);
    }

    @bind
    handleOnEdit(continueEditing, message = {}, direction) {
        const { editMsgId, inputPreviousValue } = this.state;
        const { messages, profile: { primary } = {} } = this.props || {};

        if (!continueEditing) {
            this.setState({ editActive: false, editMsgId: '', inputValue: inputPreviousValue || '' });
            return;
        }

        if (!isEmpty(message)) {
            this.setState({
                editActive: true,
                editMsgId: message.id,
                inputValue: message.text
            }, () => setChatInputCursorToLast(this.chatInputRef));
            
            return;
        }

        const editableMsg = getSelectedMessage(messages, editMsgId, primary?.username, direction);

        if (!isEmpty(editableMsg)) {
            this.setState({
                editActive: true,
                editMsgId: editableMsg.id,
                inputValue: editableMsg.text
            }, () => setChatInputCursorToLast(this.chatInputRef));
            
            return;
        }
        
        this.setState({ editActive: false, editMsgId: '', inputValue: inputPreviousValue || '' });
    }

    @bind
    async sendMessage() {
        const { type, subscription, sendChatMessage, sendRoomMessage, updateChatMessage } = this.props;
        const { rid, rel, customFields } = subscription || {};
        const { inputValue, inputPreviousValue, editActive, editMsgId } = this.state;
        const isEntityChat = !isEmpty(customFields);

        if (!inputValue) return;
        
        if (editActive) {
            updateChatMessage({ _id: editMsgId, rid, msg: inputValue });
            this.setState({ 
                justEdited: true,
                editActive: false, 
                editMsgId: '',
                inputValue: inputPreviousValue || '',
                inputPreviousValue: inputPreviousValue || ''
            });
        } else {
            if (!isEntityChat) {
                sendChatMessage({ message: { rid, msg: inputValue } }, rel, rid);
            } else {
                sendRoomMessage({ message: inputValue, rid, entityType: type, rel });
            }
            this.setState({ inputValue: '', inputPreviousValue: '', singleMessageAdded: true });
        }

        this.closeMentions();
        focusChatInput(this.chatInputRef);
    }

    @bind
    @debounce(200)
    async getMentions(currentWord) {
        const { subscription, messages, getMentionsSpotlight } = this.props;
        let addUsers = [];

        if (currentWord.length > 1) {
            const initialUsers = getInitialRoomMembers(messages);
            const initialMemberUsernames = initialUsers.map((user) => user.username);
            const spotlightUsers = await getMentionsSpotlight(get(subscription, 'rid'), currentWord, initialMemberUsernames);

            if (spotlightUsers.length > 0) {
                addUsers = spotlightUsers;
            } else {
                const countFilteredUsers = filterMentionedUsers(this.chatInputRef, initialUsers, []);

                if (countFilteredUsers.length <= 0) {
                    this.closeMentions();
                    return;
                }
            }
        }
        
        const newCurrentWord = getCurrentWord(this.chatInputRef);
        
        if (newCurrentWord.startsWith('@')) {
            this.setState({ spotlightUsers: addUsers });
            this.setMentionUsers(messages, addUsers);
            this.setState({ isMentionsOpen: true, mentionsUserListIndex: 0 });
        } else {
            this.closeMentions();
        }
    }

    @bind
    @debounce(1)
    openMentionsPopup() {
        const currentWord = getCurrentWord(this.chatInputRef);
        
        if (currentWord.startsWith('@')) {
            this.getMentions(currentWord);
        } else {
            this.closeMentions();
        }
    }

    @bind
    @memoize(deepEquals)
    buildMessages(userId, messages, editMsgId, editActive, isLoadingMessages) {
        return (
            <Fragment>
                {isLoadingMessages && <Loader />}
                {messages.map((message, index) => {
                    const prevMessageDate = messages[index !== 0 ? index - 1 : index].createDate;
                    const messageDate = message.createDate;
                    const sameDay = isSameDay(prevMessageDate, messageDate);

                    return (
                        <AliveItemWrapper key={message.id} className="alive-item">
                            {!sameDay && <ChatNewDayTitle>{formatDate(messageDate, DATE_FORMAT)}</ChatNewDayTitle>}
                            <AliveChatItem
                                entityType={this.props.type}
                                message={message}
                                openImagePreview={this.openImagePreview}
                                userId={userId}
                                editActive={(message.id === editMsgId && editActive)}
                                onEdit={this.handleOnEdit}
                            />
                        </AliveItemWrapper>   
                    );
                })}
            </Fragment>
        );
    }

    @bind
    @memoize()
    getSubscribeComponent(isEntityChat, id, type, permission, rid, profile, subscription) {
        const isChannel = type === 'channel';
        let component;

        if ((isEntityChat && permission) || (!isEntityChat && isChannel)) {
            component = (
                <ChatValidAccessPermission 
                    id={id} 
                    type={type} 
                    profile={profile} 
                    isEntityChat={isEntityChat}
                />
            );
        } else if ((isEntityChat && !permission) || (!isEntityChat && !isChannel)) {
            component = (
                <ChatAccessDenied 
                    id={id} 
                    type={type} 
                    profile={profile} 
                    rid={rid}
                    isEntityChat={isEntityChat}
                    subscription={subscription}
                />
            );
        }

        return (
            <SubscribeOnChatWrapper>
                {component}
            </SubscribeOnChatWrapper>
        );
    }

    render() {
        const { isLoading, isSubscribing, isFileUploading, subscription, profile, messages,
            role, id, type, isLoadingMessages, images, isMobile } = this.props;
        const { customFields, ro: readOnly, roles: subRoles, f, rid, rel, topic } = subscription || {};
        const { inputValue, inputPreviousValue, files, editActive, editMsgId, selectedFileId,
            isMentionsOpen, mentionUsers, mentionsUserListIndex
        } = this.state;
        const isEntityChat = !isEmpty(subscription)
            ? !isEmpty(customFields) && !Boolean(get(customFields, 'teamId'))
            : !['channel', 'direct', 'group', 'live'].includes(type);
            
        const permissions = isEntityChat ? getPermissions(role) : {};
        const { canEdit, canComment } = permissions || {};
        const canAccess = canEdit || canComment;
        const isReadOnly = readOnly ? (subRoles || []).includes('owner') ? false : true : false;

        return (
            <ChatContainer ref={this.messageRef}>
                <DropzoneWrapperStyled
                    onDropRejected={this.uploadFiles}
                    onDropAccepted={this.uploadFiles}
                    showPreviews
                >
                    <ChatGrid>
                        {(isLoading || isSubscribing) && <Loader absolute backdrop />}
                        {(isEntityChat && !canAccess) ? (
                            <ChatAccessDenied
                                id={id} 
                                type={type} 
                                profile={profile} 
                                isEntityChat={isEntityChat}
                                rid={rid}
                            />
                        ) : (
                            <>
                                {isEmpty(subscription) && !isSubscribing && 
                                    this.getSubscribeComponent(isEntityChat, id, type, canAccess, rid, profile, subscription)}
                                {!isEmpty(subscription) && (
                                    <>
                                        <ChatHeader 
                                            rid={rid} 
                                            rel={rel} 
                                            readOnly={isReadOnly}
                                            topic={topic || ''}
                                            role={role}
                                            favorite={!f ? false : true}
                                        />
                                        <ChatMainBody>
                                            <ChatBodyStyled 
                                                messages={Boolean(messages.length)} 
                                                ref={this.messageBodyRef}
                                                onScroll={this.loadMoreMessages}
                                            >
                                                {Boolean(messages.length) ? (
                                                    this.buildMessages(profile.id, messages, editMsgId, editActive, isLoadingMessages)
                                                ) : (
                                                    <NoMessage>No messages</NoMessage>
                                                )}
                                            </ChatBodyStyled>
                                            {isMentionsOpen && (
                                                <ChatMentions 
                                                    users={mentionUsers} 
                                                    selectedUserIndex={mentionsUserListIndex} 
                                                    onSelectUser={this.selectMentionUser}
                                                />
                                            )}
                                        </ChatMainBody>
                                        {!isReadOnly ? (
                                            <ChatFooter
                                                inputRef={this.chatInputRef}
                                                rid={rid}
                                                message={inputValue}
                                                previousMessage={inputPreviousValue}
                                                editActive={editActive}
                                                isMobile={isMobile}
                                                uploadFiles={this.uploadFiles}
                                                onInputChange={this.chatInputChange}
                                                onSendMessage={this.sendMessage}
                                                onInputKeyPress={this.chatInputPress}
                                            />
                                        ) : (
                                            <SecondaryTextStyled>This room is read only.</SecondaryTextStyled>
                                        )}
                                        {!!files[0] && (
                                            <ChatFileUploadModal
                                                file={files[0]}
                                                isUploading={isFileUploading}
                                                uploadFile={this.uploadFile}
                                                closeFileForm={this.closeFileForm}
                                            />
                                        )}
                                        {selectedFileId && (
                                            <ChatFilePreview
                                                images={images}
                                                fileId={selectedFileId}
                                                onClose={this.closeImagePreview}
                                            />
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </ChatGrid>
                </DropzoneWrapperStyled>
            </ChatContainer>
        );
    }
}

export default connect(
    (state, props) => {
        const { type, id } = props;
        const subscriptions = state.chat.subscriptions.data;
        const subscription = getSubscriptionByRel(subscriptions, { type, id });
        const messages = !isEmpty(subscription) ? get(state.chat.room.messages, `${subscription.rid}`, []) : [];
        const images = getRoomImages(messages);

        return {
            id,
            type,
            messages,
            images,
            subscription,
            profile: state.user.profile,
            isLoading: state.chat.room.isLoading,
            isLoadingMessages: state.chat.room.isLoadingMessages,
            isSubscribing: state.chat.room.isSubscribing,
            isFileUploading: state.chat.room.isFileUploading,
            isMobile: state.global.isMobile,
            isOpenLeftPanel: state.leftPanel.state.isOpen,
            allMessagesLoaded: state.chat.room.allMessagesLoaded,
        };
    },
    {
        loadRoomMessages,
        setMessagesAsRead,
        closeSidebar,
        sendRoomMessage,
        uploadRoomFile,
        uploadRcRoomFile,
        joinPublicChannel,
        subscribe,
        sendChatMessage,
        unsubscribeEntityChat,
        unsubscribeGroupChat,
        unsubscribeChannelChat,
        subscribeStreamRoomMessages,
        unsubscribeStreamRoomMessages,
        closeLeftPanel,
        updateChatMessage,
        getMentionsSpotlight
    }
)(AliveChat);
