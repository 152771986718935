// @flow
import React, { PureComponent } from 'react';
import styled from 'styled-components';

import ErrorBoundary from 'app/components/atoms/ErrorBoundary/ErrorBoundary';

const ContentStyle = styled.section`
    grid-area: gContent;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-areas: "content";
    height: ${({height, theme}) =>  `calc(${height}px - ${theme.header.height})`};
    overflow: hidden;
    color: ${( { theme } ) => theme.base.textColor };
    background: ${( { theme } ) => theme.base.background };
    transition: .3s ease-in-out;
    /*Only on desktop if left is open*/
    @media(min-width: ${ ( { theme } ) => theme.media.md }) {
      ${({ theme, isNavOpen, isLeftPanelOpen }) => {
    if(isLeftPanelOpen) {
        return 'width: 100%;';
    }
    return isNavOpen ? `
        width: calc(100% - ${theme.navigation.width});
        transform: translateX(${theme.navigation.width})
        ` : `
        width: calc(100% - ${theme.navigation.apps.width});
        transform: translateX(${theme.navigation.apps.width})
        `;
}
}
`;
/**
 * The app content container
 */
class AppContent extends PureComponent<Object, Object> {

    height: number = 0;

    componentDidMount() {
        this.setHeight();
        window.addEventListener('resize', this.setHeight);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setHeight);
    }

    setHeight = () => {
        this.height = window.document.body.getBoundingClientRect().height;
        this.forceUpdate();
    };

    render() {
        const { children, isNavOpen, isLeftPanelOpen, id } = this.props;
        return (
            <ErrorBoundary>
                <ContentStyle id={id} className={'app-content'} isNavOpen={isNavOpen} isLeftPanelOpen={isLeftPanelOpen} height={this.height}>
                    {children}
                </ContentStyle>
            </ErrorBoundary>
        );
    }
};

export default AppContent;
