// @flow

import React, { useState, memo, useMemo, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText,
    Divider,
    Chip,
    Switch
} from '@mic3/platform-ui';
import Icon from 'app/components/atoms/Icon/Icon';

import { isEmpty } from 'app/utils/utils';

const MenuDivider = styled(Divider)`
    margin: 12px;
`;

const MenuBase = styled(Menu)`
    .MuiPaper-root {
        margin-top: 3px;
        min-width: 224px;
        box-shadow: ${({ theme, noShadow }) => !noShadow ? theme.shadow.boxShadow2DP : 'none'};
    }
    .MuiTypography-root {
        font-size: 14px;
    }
`;

const SwitchStyled = styled(Switch)`
    margin-right: 0 !important;
`;

const NestedMenu = ({
    items,
    onClose,
    onItemClick,
    className,
    anchorOrigin,
    transformOrigin,
    isSwitchToggled,
    ...otherProps
}: Object) => {
    const [currentMenu, setCurrentMenu] = useState(items);
    const [switchToggled, setSwitchToggled] = useState(isSwitchToggled);

    const handleClick = useCallback(({ children, onClick, label, useSwitch }: MenuType) => {
        if (!isEmpty(children) && children) {
            setCurrentMenu(children);
            return;
        }

        if (useSwitch) {
            setSwitchToggled(!switchToggled);
            onItemClick && onItemClick(label, !switchToggled);
            onClick && onClick();
            return;
        }

        onItemClick && onItemClick(label);
        onClick && onClick();
        onClose();
        
    }, [onClose, onItemClick, switchToggled]);

    useEffect(() => {
        setCurrentMenu(items);
    }, [window.location.href, items]); // eslint-disable-line

    const menuItems = useMemo(() => currentMenu.map((menuItem, index) => {
        return [
            <MenuItem
                disableRipple={menuItem.useSwitch}
                key={index}
                component={menuItem.to ? Link : 'div'}
                to={menuItem.to}
                disabled={menuItem.disabled}
                onClick={menuItem.useSwitch ? () => {} : () => handleClick(menuItem)}
            >
                {menuItem.icon && (
                    <ListItemIcon>
                        <Icon name={menuItem.icon} type={menuItem.iconType}/>
                    </ListItemIcon>
                )}
                <ListItemText primary={menuItem.label} />
                {menuItem.secondaryIcon && (
                    <Icon name={menuItem.icsecondaryIconon} type={menuItem.secondaryIconType}/>
                )}
                {menuItem.count && (
                    <Chip
                        variant="default"
                        label={!menuItem.count ? 0 : menuItem.count}
                        color="default"
                        size="small"
                        type="class"
                    />
                )}
                {menuItem.useSwitch && (
                    <SwitchStyled value={switchToggled} onChange={() => handleClick(menuItem)} />
                )}
            </MenuItem>,
            menuItem.hasDivider && <MenuDivider />
        ];
    }), [currentMenu, switchToggled, handleClick]);

    return (
        <MenuBase
            {...otherProps}
            className={className}
            onClose={onClose}
            onExited={() => setCurrentMenu(items)}
            getContentAnchorEl={null}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
        >
            {menuItems}
        </MenuBase>
    );
};

NestedMenu.defaultProps = {
    items: [],
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'right'
    },
    isSwitchToggled: false
};

export default memo(NestedMenu);
