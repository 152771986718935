/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { IconButton, MdiIcon, Typography, Badge } from '@mic3/platform-ui';
import { isEmpty, isObject } from 'app/utils/utils';

import Alert from 'app/components/molecules/Alert/Alert';
import DigitalTwin from 'app/components/organisms/DigitalTwin/DigitalTwin';
import DigitalTwinAssets from 'app/components/organisms/DigitalTwin/DigitalTwinAssets';
import ActionOpenForm from 'app/components/organisms/Actions/ActionOpenForm';
import ActionStartProcess from 'app/components/organisms/Actions/ActionStartProcess';
import HeaderBar from 'app/components/molecules/HeaderBar/HeaderBar';
import Layout from 'app/components/Designer/Layout';
import Loader from 'app/components/atoms/Loader/Loader';
import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import ReloadCountdown from 'app/components/molecules/ReloadCountdown/ReloadCountdown';
import {
    loadDigitalTwin, fetchTemplate, loadAssets, clearAssetsAndTemplate
} from 'store/actions/common/digitalTwinActions';
import { startProcessReset } from 'store/actions/abox/processActions';
import { get } from 'app/utils/lo/lo';
import { deepEquals, shallowEquals } from 'app/utils/utils';
import { entityLink } from 'app/utils/http/link';
import {
    evaluateMapping, evaluateActionMapping, getAssetsMetadata, _parse
} from 'app/utils/digitalTwin/digitalTwin';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { showToastr } from 'store/actions/app/appActions';
import history from 'store/History';
import affectliSso from 'app/auth/affectliSso';
import { enrichContext } from 'app/utils/designer/form/formUtils';
import { set } from 'app/utils/immutable/Immutable';
import { render, compile } from 'app/utils/template/template';
import IFrame from 'app/components/atoms/IFrame/IFrame';


const StyledFrame = styled(IFrame)`
    background-color: white;
    height: ${({inSidebar, expanded}) => inSidebar && expanded ? 'calc(100vh - 120px)': inSidebar && !expanded ? 'calc(100vh - 176px)' : 'calc(100vh - 105px)'};
    ${({ isSidebarResizing }) => isSidebarResizing && 'pointer-events: none'}
`;

const StyledTextField = styled(TextField)`
  && {
    input {
      color: #7391D0;
    }
    label {
      color: #7391D0;
    }
    .MuiInput-underline:before {
      border-bottom: 2px solid #7391D0;
    }
    &:hover .MuiInput-underline:before {
        border-bottom-color: #3a68c9;
    }
  }
`;

const StyledAutocomplete = styled(Autocomplete)`
  && {
    .MuiSvgIcon-root {
      fill: #7391D0;
    }
  }
`;
const WarningList = styled.div`
padding: 16px;
div {
  padding-bottom: 16px;
  font-size: 15px;
}
`;

const ZoomLabel = styled(Typography)`
font-size: 0.8rem !important;
line-height: 3 !important;
`;

const MenuBadge = styled(Badge)`
& .MuiBadge-anchorOriginTopRightRectangle {
  top: -7px !important;
  padding: 3px !important;
  min-width: 0px !important;
  height: 14px !important;
}
`;

const AlertContainer = styled.div`
margin: 20px;
`;

/**
 * Render the Thing's changelog tab.
 */
class EntityDigitalTwin extends PureComponent<Object, Object> {

    static propTypes = {
        entity: PropTypes.object.isRequired,
        digitalTwin: PropTypes.shape({
            template: PropTypes.string.isRequired, // the template SVG URL
            context: PropTypes.object, // the context data
            componentMapping: PropTypes.object, // associate a SVG ID (of a group) with a Druid's column
            valueMapping: PropTypes.object, // associate a SVG ID (of a text) with a Druid's column
            colorMapping: PropTypes.object, // associate a SVG ID (of an element) with a Druid's column
        }),
        template: PropTypes.string, // the template SVG content
        fetchTemplate: PropTypes.func.isRequired,
        assets: PropTypes.arrayOf(PropTypes.object),
        loadAssets: PropTypes.func.isRequired,
        isLoadingAssets: PropTypes.bool,
        loadDigitalTwin: PropTypes.func.isRequired,
        isRefreshing: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        const digitalTwins = props.entity?.primaryClass?.digitalTwins;
        const dtValue = digitalTwins?.find(d => d?.isDefault) || digitalTwins?.[0];
        const legacyDT = props.entity?.attributes?.['digital-twin/configuration'];

        this.state = {
            hidden: [],
            assetMap: {},
            values: {},
            colors: {},
            dark: true,
            zoom: 100,
            highlight: [],
            dtValue: dtValue || legacyDT || {}
        };
        this.loadTemplate(get(props, 'digitalTwin.template'));
    }

    componentDidMount() {
        this.loadDigitalTwin();
    }

    componentDidUpdate(prevProps) {
        const { digitalTwin, assets, isLoadingAssets, isRefreshing } = this.props;
        const {
            context,
            componentMapping, valueMapping, colorMapping, actionMapping,
            warnings, hide, isScriptTemplate, functions } = digitalTwin || {};
        if (isLoadingAssets) {
            return null;
        }
        if (!isRefreshing && (!digitalTwin || isEmpty(digitalTwin))) {
            this.props.clearAssetsAndTemplate();
        }
        const template = get(digitalTwin, 'template');
        if (this.shouldLoad()) {
            this.collectSvgMetadata({
                templateUrl: template,
                context,
                componentMapping,
                valueMapping,
                colorMapping,
                actionMapping,
                assets,
                warnings,
                hide,
                isScriptTemplate,
                functions
            });
        }
    }

    @bind
    @memoize()
    buildContext(entity, userProfile) {
        let context = enrichContext({ user: userProfile });
        context = set(context, 'sso.token', affectliSso.getToken());
        context = set(context, 'sso.bearerToken', affectliSso.getBearerToken());
        context = set(context, 'entity', entity);
        return context;
    }

    @bind
    @memoize()
    buildUrl(context, dtValue) {
        if (!dtValue) {
            return '';
        }
        return render(compile(dtValue?.src), context);
    }

    @bind
    @memoize(shallowEquals)
    async collectSvgMetadata(
        {
            templateUrl, context,
            componentMapping, valueMapping, colorMapping, actionMapping,
            assets, warnings, hide, isScriptTemplate,
            functions
        }
    ) {
        let values = valueMapping, 
            valueMappingWarnings,
            uuidMap = componentMapping,
            componentMappingWarnings,
            colors = colorMapping,
            colorMappingWarnings,
            actions = actionMapping,
            actionMappingWarnings,
            hidden = hide;
        await this.loadTemplate(templateUrl);
       
        if (!isScriptTemplate) {
            const { map: compMap, warnings: compWarnings } = await this.evaluateComponentMapping(componentMapping, context);
            uuidMap = compMap;
            componentMappingWarnings = compWarnings;

            const { map: valMap, warnings: valWarnings } = await this.evaluateValueMapping(valueMapping, context, functions);
            values = valMap;
            valueMappingWarnings = valWarnings;

            const { map: colorsMap, warnings: colWarnings } = await this.evaluateColorMapping(colorMapping, context);
            colors = colorsMap;
            colorMappingWarnings = colWarnings;
            
            const { map: actionsMap } = await this.evaluateActionMapping(actionMapping, context);
            actions = actionsMap;
            actionMappingWarnings = actionMappingWarnings;

        }

        await this.loadMappedAssets(uuidMap);
        const compMeta = this.getComponentMetadata(uuidMap || {}, assets || []);
        const { assetMap, missingAssets } = compMeta;
        if (!isScriptTemplate) {
            hidden = compMeta?.hidden;
        }
       
        this.setState({
            hidden,
            assetMap,
            missingAssets,
            uuidMap,
            values,
            colors,
            actions,
            warnings: this.mergeWarnings(
                warnings,
                componentMappingWarnings,
                valueMappingWarnings,
                colorMappingWarnings,
                actionMappingWarnings,
            ),
        });
    }

    @bind
    @memoize()
    loadTemplate(templateUrl) {
        if (this.shouldLoad()) {
            this.props.fetchTemplate(templateUrl);
        }
    }

    @bind
    @memoize(deepEquals)
    loadMappedAssets(uuidMap) {
        let uuids = Object.values(uuidMap || {}).filter(Boolean);
        uuids = Array.from(new Set(uuids));
        if (uuids.length) {
            this.props.loadAssets(uuids, this.props.type);
        }
    }

    @bind
    @memoize()
    getComponentMetadata(uuidMap, assets) {
        return getAssetsMetadata(uuidMap, assets);
    }

    @bind
    @memoize()
    async evaluateComponentMapping(componentMapping, context) {
        return evaluateMapping(componentMapping, context, 'component mapping');
    }

    @bind
    @memoize()
    async evaluateValueMapping(valueMapping, context, functions) {
        return evaluateMapping(valueMapping, context, 'value mapping', functions);
    }

    @bind
    @memoize()
    async evaluateColorMapping(colorMapping, context) {
        return evaluateMapping(colorMapping, context, 'color mapping');
    }

    @bind
    async onAction(response) {
        const { action, options } = await response || {};
        switch (action) {
            case 'showMessage': this.toggleShowMessage(options); break;
            case 'startProcess': this.toggleStartProcess(options); break;
            case 'openForm': this.toggleShowForm(options); break;
            case 'link': window.open(options.href); break;
            case 'navigate': window.location.href = options.href; break;
            default:
        }
    }

    @bind
    @memoize()
    async evaluateActionMapping(actionMapping, context) {
        return evaluateActionMapping(actionMapping, context);
    }

    @bind
    toggleDark() {
        this.setState({ dark: !this.state.dark });
    }

    @bind
    scaleUp() {
        this.setState({ zoom: this.state.zoom + 20 });
    }

    @bind
    scaleDown() {
        if (this.state.zoom > 30) {
            this.setState({ zoom: this.state.zoom - 20 });
        }
    }

    @bind
    toggleWarnings() {
        const { warnings, showWarnings } = this.state;
        this.setState({ showWarnings: !showWarnings && warnings });
    }

    @bind
    shouldLoad() {
        const { dtValue } = this.state;
        return dtValue?.type === 'custom' || dtValue?.type === 'script'; // By custom type we assume that its a legacy digital twin so we would query
    }

    @bind
    loadDigitalTwin() {
        const { type, entity: { id } = {} } = this.props;
        const { dtValue } = this.state;
        if (this.shouldLoad()) {
            this.props.loadDigitalTwin(id, type, dtValue?.id, dtValue?.type === 'custom');
        }
    }

    getToolbar() {
        const { warnings, dark, zoom } = this.state;
        const { isLoadingAssets, isRefreshing } = this.props;
        return (
            <>
                {this.state.zoom > 30 && (
                    <IconButton onClick={this.scaleDown}>
                        <MdiIcon name='magnify-minus-outline' />
                    </IconButton>
                )}
                <ZoomLabel>{zoom}%</ZoomLabel>
                <IconButton onClick={this.scaleUp}>
                    <MdiIcon name='magnify-plus-outline' />
                </IconButton>
                <IconButton onClick={this.toggleDark}>
                    {dark && <MdiIcon name='lightbulb' />}
                    {!dark && <MdiIcon name='lightbulb-outline' />}
                </IconButton>
                <ReloadCountdown
                    disableCountdown={isRefreshing || isLoadingAssets}
                    seconds={180}
                    format='minutes'
                    action={this.loadDigitalTwin}
                />
                {warnings && warnings.length > 0 && (
                    <IconButton onClick={this.toggleWarnings}>
                        <MdiIcon name='alert-octagon' />
                    </IconButton>
                )}
                {this.props.sidebarActions}
            </>
        );
    }

    @bind
    @memoize()
    mergeWarnings(warnings, componentMappingWarnings, valueMappingWarnings, colorMappingWarnings, actionMappingWarnings) {
        return [
            ...warnings || [],
            ...componentMappingWarnings || [],
            ...valueMappingWarnings || [],
            ...colorMappingWarnings || [],
            ...actionMappingWarnings || [],
        ];
    }

    @bind
    @memoize()
    buildOpenAssetActions(assetMap) {
        return Object.entries(assetMap || {}).reduce((actions, [svgId, asset]) => {
            const { id, type, classes } = asset;
            const isDigitalTwin = classes && classes.find(c => c && c.uri === 'digital-twin');
            const href = entityLink(id, type, isDigitalTwin ? 'digital-twin' : null);
            actions[svgId] = { click: () => document.location = href };
            return actions;
        }, {});
    }

    @bind
    @memoize()
    mergeActions(actions, assetMap) {
        return Object.entries(actions || {}).reduce((map, [key, action]) => {
            if (!map[key]) {
                map[key] = action;
            } else {
                if (action.click) {
                    map[key].click = action.click;
                }
                if (action.dblclick) {
                    map[key].dblclick = action.dblclick;
                }
            }
            return map;
        }, this.buildOpenAssetActions(assetMap));
    }

    renderDigitalTwin() {
        const {
            hidden, assetMap, values, colors, actions, dark, zoom, highlight,
        } = this.state;
        const { template, digitalTwin } = this.props;
        const { isScriptTemplate, functions } = digitalTwin || {};
        return (
            template &&
            <DigitalTwin
                template={template}
                functions={functions}
                isScriptTemplate={isScriptTemplate}
                hiddenList={hidden}
                values={values}
                colors={colors}
                actions={this.mergeActions(actions, assetMap)}
                onAction={this.onAction}
                backgroundColor={dark ? null : 'white'}
                zoom={zoom}
                highlight={highlight}
            />
        );
    }

    @bind
    setHighlight(uuid) {
        if (!uuid || uuid === this.state.selected) {
            this.setState({ selected: null, highlight: [] });
        } else {
            const svgIds = Object.entries(this.state.uuidMap)
                .filter(([key, val]) => {
                    let value = val;
                    if (isObject(val)) {
                        value = _parse(val)?.value;
                    }
                    return value && (value === uuid || uuid.startsWith(value));
                })
                .map(([key, value]) => key);
            this.setState({ selected: uuid, highlight: svgIds });
        }
    }

    @bind
    toggleShowForm({ id, data } = {}) {
        this.setState({
            showForm: !this.state.showForm,
            formId: id,
            data,
        });
    }

    @bind
    async toggleStartProcess({ key, variables } = {}) {
        this.props.startProcessReset();
        this.setState({
            showStartProcess: !this.state.showStartProcess,
            processKey: key,
            variables,
        });
    }

    @bind
    toggleShowMessage({ type, message }: Object) {
        const { showToastr } = this.props;
        const MessageTypes = new Set(['info', 'success', 'warning', 'error']);
        showToastr({ severity: MessageTypes.has(type) ? type : 'success', detail: message });
    }

    @bind
    @memoize()
    buildSidebar(entity, assets, missingAssets, isLoadingAssets) {
        return {
            isOpen: false,
            title: 'Entities',
            content: (
                isLoadingAssets
                    ? <Loader absolute />
                    : <>
                        <DigitalTwinAssets
                            id={entity.id}
                            assets={assets}
                            missingAssets={missingAssets}
                            setSelected={this.setHighlight}
                        />
                    </>
            )
        };
    }

    /**
     * @override
     */
    render() {
        const {
            assets,
            isLoadingAssets,
            entity,
            digitalTwin,
            digitalTwinError,
            isRefreshing,
            inSidebar,
            isSidebarResizing,
            userProfile,
            breadcrumbLine,
            expanded
        } = this.props;
        const { dtValue } = this.state;
        const legacyDT = entity?.attributes?.['digital-twin/configuration'];
        if (digitalTwinError) {
            return (
                <>
                    <HeaderBar right={[
                        ...(this.props.sidebarActions || []),
                    ]} />
                    <AlertContainer>
                        <Alert type="error">
                            <div>{String(digitalTwinError)}</div>
                        </Alert>
                    </AlertContainer>
                </>
            );
        }

        const leftToolbarContent = 
                    <>
                        <IconButton onClick={() => history.goBack()}>
                            <MdiIcon name='arrow-left' />
                        </IconButton>{' '}
                        <div style={{ width: '200px', margin: '0 0 0 10px' }}>
                            <StyledAutocomplete
                                value={this.state.dtValue}
                                options={[...(this.props.entity?.primaryClass?.digitalTwins || []), legacyDT].filter(Boolean)}
                                disableClearable
                                onChange={(e, dtValue) => this.setState({ dtValue }, this.loadDigitalTwin)}
                                renderInput={(params) => <StyledTextField {...params} margin='normal' />}
                                getOptionLabel={(option) => option.name}
                                fullWidth
                                
                            />
                        </div>
                    </>;
        const { missingAssets, showWarnings, warnings, showForm, formId, data, showStartProcess, processKey, variables } = this.state;
        const missingCount = !missingAssets || isLoadingAssets ? 0 : missingAssets.length;
        if (dtValue && dtValue?.type === 'iframe') {
            const context = this.buildContext(entity, userProfile);
            const src = this.buildUrl(context, dtValue);
            return (
                <div>
                    <HeaderBar left={leftToolbarContent} right={[...(this.props.sidebarActions || [])]} />
                    <StyledFrame inSidebar={inSidebar} expanded={expanded} title={dtValue.name} src={src} isSidebarResizing={isSidebarResizing} />
                </div>
            );
        }
        return (
            <>
                {(isLoadingAssets || isRefreshing) && <Loader absolute backdrop />}
                <Layout
                    leftToolbarContent={entity?.primaryClass?.digitalTwins?.length ? leftToolbarContent : breadcrumbLine}
                    content={this.renderDigitalTwin()}
                    height={'calc(100vh - 6rem)'}
                    ToolbarContent={this.getToolbar()}
                    RightMenuIcon={(
                        <>
                            <MdiIcon name="menu" />
                            <MenuBadge color="error" badgeContent={missingCount} max={99} />
                        </>
                    )}
                    RightSidebarProps={!inSidebar && this.buildSidebar(entity, assets, missingAssets, isLoadingAssets)}
                    disableRightMenu={inSidebar ? true : false}
                />
                {
                    showWarnings &&
                    <ModalDialog open onClose={this.toggleWarnings} title="Warning">
                        <WarningList>
                            {warnings.map((warn, i) => <div key={i}>{warn}</div>)}
                        </WarningList>
                    </ModalDialog>
                }
                {
                    digitalTwin && showForm &&
                    <ModalDialog open onClose={this.toggleShowForm}>
                        <ActionOpenForm formId={formId} data={data} context={digitalTwin.context} />
                    </ModalDialog>
                }
                {
                    digitalTwin && showStartProcess &&
                    <ModalDialog open onClose={this.toggleStartProcess} title="Start Process">
                        <ActionStartProcess processKey={processKey} variables={variables} />
                    </ModalDialog>
                }
            </>
        );
    }
}

export default connect(
    state => ({
        isRefreshing: state.common.digitalTwin.details.isLoading,
        digitalTwin: get(state.common.digitalTwin, 'details.data'),
        template: state.common.digitalTwin.template.content,
        assets: state.common.digitalTwin.assets.data,
        isLoadingAssets: state.common.digitalTwin.assets.isLoading,
        digitalTwinError: state.common.digitalTwin.details.failure,
        userProfile: state.user.profile,
        isSidebarResizing: state.sidebar.isResizing,
    }),
    { loadDigitalTwin, fetchTemplate, loadAssets, startProcessReset, showToastr, clearAssetsAndTemplate }
)(EntityDigitalTwin);
