/* @flow */

import { DOMParser } from '@xmldom/xmldom';

import { bind } from 'app/utils/decorators/decoratorUtils';
import AbstractDefinitionsBuilder from './AbstractDefinitionsBuilder/AbstractDefinitionsBuilder';
import { prettifyXml } from 'app/utils/designer/process/processDesignerUtils';

class DataObjectBuilder extends AbstractDefinitionsBuilder {

    @bind
    deNormalizeValues(definition: Array<Object>): Array<Object> {
        const doc = new DOMParser().parseFromString(definition);
        const dataObjects =  doc.getElementsByTagName('dataObject');
        return Array.from(dataObjects).map((elem) => {
            const defaultValueElement = elem.getElementsByTagName('flowable:value')[0];
            let defaultValue = '';
            if(defaultValueElement) {
                defaultValue = String(defaultValueElement.firstChild);
            }
            const type = elem.getAttribute('affectli:itemSubjectRef').split(':')[1];
            if(!type) {
                console.error(`The 'itemSubjectRef' is required in 'dataObject' element.`); // eslint-disable-line no-console
            }
            return {
                id: elem.getAttribute('id'),
                name: elem.getAttribute('name'),
                type, defaultValue
            };
        });
    }

    @bind
    normalizeValues(value: Array<Object>): Array<any> {
        const { value: definition } = this.props;
        const doc = new DOMParser().parseFromString(definition);
        const dataObjects =  doc.getElementsByTagName('dataObject');
        const process =  doc.getElementsByTagName('process')[0] || doc.getElementsByTagName('bpmn:process')[0];
        const startEvent =  doc.getElementsByTagName('startEvent')[0];
        const dataObjectsNew = Array.from(value).map((elem) => {
            const element = new DOMParser().parseFromString(`<dataObject id="${elem.id}" name="${elem.name}" affectli:itemSubjectRef="xsd:${elem.type}">${elem.defaultValue ? `
                    <extensionElements>
                        <flowable:value>${elem.defaultValue}</flowable:value>
                    </extensionElements>
              ` : ''}</dataObject>`, 'image/svg+xml');
            return element.getElementsByTagName('dataObject')[0];
        });
        Array.from(dataObjects).forEach((elem) => {
            process.removeChild(elem);
        });
        dataObjectsNew.forEach((elem) => {
            process.insertBefore(elem, startEvent);
        });
        return prettifyXml(String(doc));
    }
};

export default DataObjectBuilder;
