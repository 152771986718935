/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { muiTheme } from 'app/themes/materialUi';
import { Tooltip, MdiIcon, Grid, TextField } from '@mic3/platform-ui';
import ReloadCountdown from 'app/components/molecules/ReloadCountdown/ReloadCountdown';
import { connect } from 'react-redux';

import { filterSearchFields } from 'app/utils/filter/filterUtils';
import FiltersChips from 'app/components/organisms/Filters/FiltersChips';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';

const GridWrapper = styled(Grid)`
    height: auto;
    align-items: start !important;
    padding-top: 8px !important;
    padding-left: 8px !important;
    padding-bottom: ${({ isMobile }) => isMobile ? '0px !important' : '8px !important' };
    padding-right: ${({ isMobile }) => isMobile ? '0px !important' : '8px !important' };
`;

const NotFullWidthGrid = styled(Grid)`
    display: flex;
    align-items: center;
    ${({ mobile }) => !mobile && 'width: auto !important;'}
`;

const TextFieldStyled = styled(TextField)`
    flex-grow: 1;
    margin: 4px 0 4px 19px !important;
    max-width: ${({ mobile }) => (mobile ? '100%' : '215px')};
    &.MuiFormControl-fullWidth {
        min-width: ${({ mobile }) => (!mobile && '125px !important;')};
    }
`;
const ReloadCountdownStyled = styled(ReloadCountdown)`
    ${({ isMobile }) => isMobile && 'display: none;'}
    & > button {
        padding: 3px 0 0;
    }
`;

const SearchIcon = styled(MdiIcon)`
    line-height: 24px !important;
`;

const classesChips = { appBar: 'filter-chips' };

class FiltersSearchLine extends PureComponent<Object, Object> {
    static propTypes = {
        filters: PropTypes.object,
        filterDefinitions: PropTypes.array,
        setEditedFilterByWithUpdate: PropTypes.func,
        searchPlaceHolder: PropTypes.func,
        searchValue: PropTypes.string,
        enableCountdown: PropTypes.bool,
        searchHelperText: PropTypes.string,
        onHeightChange: PropTypes.func,
    };

    static defaultProps = {
        countdownSeconds: 180,
        searchHelperText: 'Please enter either complete or first 8 digits of UUID.'
    };

    searchInputRef = React.createRef();
    filtersSearchLineRef = React.createRef();
    resizeObserver = null;

    state = { searchValue: this.props.searchValue || '' };

    componentDidMount() {
        const onHeightChange = this.props?.onHeightChange;
        if (!this.props.isMobile) {
            this.searchInputRef && this.searchInputRef.current.focus();
        }
        if (onHeightChange) {
            // Initial height 
            this.handleResize();
            this.resizeObserver = new ResizeObserver(this.handleResize);
            if (this.filtersSearchLineRef.current) {
                this.resizeObserver.observe(this.filtersSearchLineRef.current);
            }
        }
    }
    
    componentDidUpdate(prevProps) {
        const { searchValue } = this.props;
        if (prevProps.searchValue !== searchValue) {
            this.setState({ searchValue });
        }
    }

    componentWillUnmount() {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
    }

    @bind
    handleResize(){
        const onHeightChange = this.props?.onHeightChange;
        if (this.filtersSearchLineRef.current) {
            const height = this.filtersSearchLineRef.current.clientHeight;
            onHeightChange(height);
        }
    };



    @bind
    handleSearch(event: string) {
        const { value: searchValue } = event.target;
        this.setState({ searchValue }, () => {
            if (!this.isInvalidSearch()) {
                this.props.updateSearchBarFilter(searchValue);
            }
        });
    }

    @bind
    @memoize()
    isValidSearch(searchValue) {
        if (!searchValue) {
            // To handle the intial load of the component where searchValue is undefined at that time.
            return false;
        }
        const { filterDefinitions, searchFields } = this.props;
        return !!filterSearchFields(filterDefinitions, searchFields, searchValue).length;
    }

    @bind
    isInvalidSearch() {
        const { searchValue } = this.state;
        return searchValue && !this.isValidSearch(searchValue) ? true : false;
    }

    @bind
    getHelperText() {
        const { searchHelperText } = this.props;
        if (this.isInvalidSearch()) {
            return searchHelperText;
        }
        return null;
    }

    render() {
        const {
            isMobile,
            forceUpdate,
            filters,
            filterDefinitions,
            setEditedFilterByWithUpdate,
            searchPlaceHolder,
            countdownSeconds,
            enableCountdown,
            leftSideSearcLine
        } = this.props;
        const { searchValue } = this.state;
        const error = this.isInvalidSearch();
        return (
            <GridWrapper ref={this.filtersSearchLineRef} container isMobile={isMobile} wrap={isMobile ? 'wrap' : 'nowrap'} justify="space-between">
                <NotFullWidthGrid mobile={isMobile ? 1 : 0} container wrap="nowrap">       
                    {leftSideSearcLine}
                    <Tooltip title="Open Search Bar">
                        <span>
                            <SearchIcon name="magnify" color={muiTheme.colors.appHeader.iconColor} />
                        </span>
                    </Tooltip>
                    <TextFieldStyled
                        error={error}
                        helperText={this.getHelperText()}
                        onChange={this.handleSearch}
                        value={searchValue}
                        variant="standard"
                        margin="none"
                        inputRef={this.searchInputRef}
                        InputProps={{ disableUnderline: !error }}
                        placeholder={searchPlaceHolder || 'Search...'}
                        mobile={isMobile ? 1 : 0}
                    />
                </NotFullWidthGrid>
                <FiltersChips filters={filters} filterDefinitions={filterDefinitions} onChange={setEditedFilterByWithUpdate} classes={classesChips} />
                <ReloadCountdownStyled
                    isMobile={isMobile}
                    seconds={countdownSeconds}
                    format="minutes"
                    action={forceUpdate}
                    disableCountdown={isMobile || !enableCountdown}
                />
            </GridWrapper>
        );
    }
}

export default connect(state => ({ isMobile: state.global.isMobile }))(FiltersSearchLine);
