import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bind } from 'app/utils/decorators/decoratorUtils';
import styled from 'styled-components';
import { Divider, Typography, Button } from '@mic3/platform-ui';
import { connect } from 'react-redux';

import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import { get, pick } from 'app/utils/lo/lo';
import { createEntity } from 'store/actions/entities/entitiesActions';
import { setSelectedBoard } from 'store/actions/abox/boardActions';
import { loadLeftPanelBetaBoards } from 'store/actions/leftPanel/leftPanelActions';
import { capitalizeFirstLetter } from 'app/utils/utils';
import { saveBoardPreferences } from 'store/actions/admin/usersActions';

const StyledDivider = styled(Divider)`
    margin: 18px 0 24px 0 !important;
`;

class AddNewBoardModal extends PureComponent<Object, Object> {
    static propTypes = {
        onCloseModal: PropTypes.func.isRequired,
        primaryClass: PropTypes.object.isRequired,
    };

    defaultState: Object = this.state; // preserve the initial state in a new object.
    formRef = React.createRef();

    state = {
        data: {}
    }

    @bind
    handleChanges(data) {
        this.setState({ data });
    }

    @bind
    getAttributeChildren(attributeClass){
        let atributeClassChildren = [];
        attributeClass?.formDefinition?.fields?.forEach((item) => 
            atributeClassChildren = [...atributeClassChildren, ...item.children]);
        return atributeClassChildren;
    }

    @bind
    buildBoardcolumnsDefinition(attributes, attributeClass, fieldName) {
        const definition = {
            type: 'typeahead',
            properties: {
                label: capitalizeFirstLetter(attributes[2]?.properties.label),
                name: attributes[2]?.properties.name,
                options: [],
                disabled: true,
                multiple: true,
            },
            constraints: { required: true }
        };
        let field = this.getAttributeChildren(attributeClass).find(
            attr => attr.properties.name === fieldName || attr.properties?.definition?.id === fieldName);     
        const columnAttribute = get(this.state.data, 'system_board/columnsetbyattribute');

        if(field?.type === 'typeahead' && field?.type !== 'relationsTypeahead' && columnAttribute) {
            definition.properties.options = field.properties.options;
            definition.properties.disabled = false;
        }

        if(field?.type === 'relationsTypeahead') {
            return {
                type: 'entityTypeahead',
                properties: {
                    onChange:(evnt) => {
                        return [{
                            value: evnt.target.value ? evnt.target.value.map(vl => ({
                                ...vl,
                                isRelations: true,
                            })) : null,
                            name: evnt.target.name
                        }];
                    },
                    entityType:  field.properties.toType,
                    label: capitalizeFirstLetter(attributes[2].properties.label),
                    name: attributes[2].properties.name,
                    multiple: true,
                    valueField: null,
                    disabled: columnAttribute ? false : true,
                    placeholder: columnAttribute && 'Search for an entity...'
                },
                constraints: { required: true }
            };
        }
        
        if(field?.type?.toLowerCase().includes('typeahead') && field.properties.label !== 'Priority') {
            const placeholder = `Search for a ${field?.properties?.label?.split(' ')[0].toLowerCase()}...`;
         
            if(field?.type?.includes('userTypeahead') && field?.properties?.filterBy?.length){
                field = {
                    ...field,
                    properties: {
                        ...field.properties,
                        filterBy: field.properties.filterBy.filter(filter => {
                            return !(filter.op === '=' && filter.field === 'active' && filter.value === true);
                        })
                    }
                };
            }
            return {
                type: field.type,
                properties: {
                    ...field.properties,
                    label: capitalizeFirstLetter(attributes[2].properties.label),
                    name: attributes[2].properties.name,
                    multiple: true,
                    valueField: null,
                    disabled: columnAttribute ? false : true,
                    placeholder: columnAttribute && placeholder
                },
                constraints: { required: true }
            };
        }

        return definition;
    }

    @bind
    buildBoardAttributesList(attributeClass) {
        return  this.getAttributeChildren(attributeClass).map((attr) => {
          
            if(attr.type === 'relationsTypeahead') {
                return { value: attr.properties.definition.id, label: attr.properties.label };
            }
            if(attr.type?.toLowerCase().includes('typeahead')) {
                return { value: attr.properties.name, label: attr.properties.label };
            }
            return null;
        }).filter(Boolean);
    }

    @bind
    fieldDefinitions(data, primaryClass) {
        const attributes = get(primaryClass, 'formDefinition.fields[0].children', []);
        return [
            {
                type: 'text',
                properties: { label: 'Board Name', name: 'name' },
                constraints: { required: true, minLength: 2, maxLength: 60 }
            },
            {
                type: 'entityTypesTypeahead',
                properties: {
                    ...attributes[0]?.properties,
                    label: capitalizeFirstLetter(attributes[0]?.properties?.label),
                    filterBy: [{ field: 'primary', op: '=', value: true }],
                    extraQueryFields: [`formDefinition`],
                    placeholder: 'Search for an entity type...'
                },
                constraints: { required: true }
            },
            {
                type: 'typeahead',
                properties: {
                    label: capitalizeFirstLetter(attributes[1]?.properties.label),
                    name: attributes[1]?.properties.name,
                    options: this.buildBoardAttributesList(data[attributes[0]?.properties.name]),
                    valueField: 'value',
                    disabled: () => !data[attributes[0]?.properties.name],
                    onChange: (evnt) => {
                        return [evnt.target, { name: attributes[2]?.properties.name, value: null }];
                    }
                },
                constraints: { required: true }
            },

            this.buildBoardcolumnsDefinition(attributes, data[attributes[0]?.properties.name], data[attributes[1]?.properties.name]),
        ];
    }

    @bind
    async onFormSubmit() {
        const { primaryClass, preferences }= this.props;
        const { data, errors } = await this.formRef.current.isValidForm();
        if (!errors) {
            const { name, ...primaryPros }= data;

            const attributes = get(primaryClass, 'formDefinition.fields[0].children', []);
            const columnDefinition = this.buildBoardcolumnsDefinition(attributes, data[attributes[0]?.properties.name], data[attributes[1]?.properties.name]);
            const normalizedColumns = primaryPros['system_board/showcolumns'].map(column => {
                if(typeof column !== 'object' && columnDefinition.properties.options) {
                    const op = columnDefinition.properties.options.find(op => get(op, columnDefinition.properties.valueField || 'value') === column);
                    return op;
                }
                return column;
            });
            const newBoardEntity = await this.props.createEntity({
                name: name,
                type: 'system_board',
                primary: {
                    ...primaryPros,
                    'system_board/entitytype': pick(primaryPros['system_board/entitytype'], ['id', 'name', 'uri', 'color']),
                    'system_board/showcolumns': normalizedColumns
                }
            });

            if(newBoardEntity instanceof Error) return;

            const primary = this.state.data;
            const subTitle = newBoardEntity.id;
            const board = {...primary, ...newBoardEntity, subTitle };
            await this.props.setSelectedBoard(board);
            await this.props.saveBoardPreferences({ ...preferences, betaBoards: { ...preferences.betaBoards, [board.id]: board, lastUsedBoardId: board.id} });
            this.props.loadLeftPanelBetaBoards([]);
            this.props.onCloseModal();
        }
    }

    render() {
        const{ onCloseModal, primaryClass } = this.props;
        const { data } = this.state;
        return (
            <ModalDialog
                title="New Board"
                onClose={onCloseModal}
                actions={
                    <>
                        <Button onClick={onCloseModal} variant="text"> Cancel </Button>
                        <Button onClick={this.onFormSubmit} color="primary" type="submit"> Save</Button>
                    </>
                }
            >
                <FormGenerator data={data} onChange={this.handleChanges} components={this.fieldDefinitions(data, primaryClass)} ref={this.formRef} />
                <StyledDivider />
                <Typography variant="caption">* Required Fields </Typography>
            </ModalDialog>
        );
    }
}

export default connect(null, {
    createEntity,
    loadLeftPanelBetaBoards,
    setSelectedBoard,
    saveBoardPreferences
},
)(AddNewBoardModal);
