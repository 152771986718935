const _reduce = async (list, handler, accumulator, index) => {
    if (!list || !list.length || list.length <= index) {
        return accumulator;
    }
    accumulator = await handler(accumulator, list[index]); // eslint-disable-line no-param-reassign
    return _reduce(list, handler, accumulator, index + 1);
};

export const reduce = async (list, handler, accumulator) => {
    return _reduce(list, handler, accumulator, 0);
};
