/* @flow */

import { gql } from '@apollo/client';
import entityDetailsCommonQuery from 'graphql/entities/common/entityDetailsCommonQuery';

export default gql`
    query loadBackgroundJobDetailsQuery($id: ID!) {
        result: backgroundJob(id: $id) {
            ${entityDetailsCommonQuery}
        }
    }
`;
