/* @flow */

import { gql } from '@apollo/client';

export default gql`
query eventTypeTypeaheadQuery($orderBy: [JSON], $filterBy: [JSON], $excludeBy: [JSON], $startIndex: Int, $stopIndex: Int) {
  result: eventTypes(filterBy: $filterBy, orderBy: $orderBy, excludeBy: $excludeBy, startIndex: $startIndex, stopIndex: $stopIndex) {
    id
    name
  }
}
`;
