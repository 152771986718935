import { gql } from '@apollo/client';

export default gql`
    query userByReferenceQuery($id: ID!) {
        result: user(id: $id) {
            id
            type
            primary(fields: ["username", "email", "lastProfileRequest", "locationInfo"])
            image
            description
            createdDate
            modifiedDate
            name
            active
            createdBy {
                id
                name
            }
        }
        role: resourcePermission(type: "user", id: $id)
    }
`;
