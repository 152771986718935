import { commonEntitiesListFilters } from 'app/utils/entity/entityUtils';
import moment from 'moment';

export const severityOptions = { 1: 'Highest', 2: 'High', 3: 'Medium', 4: 'Low', 0: 'Clear' };
export default [
    {
        field: 'device.name',
        type: 'text',
        properties: {
            label: 'Entity Name',
            name: 'deviceName',
            opSelector: true
        }
    },
    {
        field: 'device.id',
        type: 'uuid',
        properties: {
            label: 'Entity ID',
            name: 'deviceId',
            opSelector: true
        },
        sort: false
    },
    {
        field: 'alarmCode',
        type: 'text',
        properties: {
            label: 'Device Original IOT Signal Code',
            name: 'alarmCode',
            opSelector: true
        }
    },
    {
        field: 'id',
        type: 'uuid',
        properties: {
            label: 'Event ID',
            name: 'id',
            opSelector: true
        }
    },
    {
        field: 'status',
        type: 'typeahead',
        properties: {
            label: 'Event Status',
            name: 'status',
            multiple: true,
            placeholder: 'Search for event status...',
            options: ['ACK', 'CLE', 'DIS', 'DUP', 'ERR', 'PRO', 'UNA'].reduce(
                (accum, value) => {
                    accum.push({ label: value, value });
                    return accum;
                },
                []
            )
        },
        condition: 'in'
    },
    {
        field: 'time',
        type: 'dateTimeRange',
        properties: {
            label: 'Event Time',
            name: 'time',
            clearable: false,
        },
        constraints: { 
            required: true,
            custom: (data) => {
                try {
                    const error = {
                        format: {
                            pattern: /somefaketest/,
                            message: 'You are not allowed to enter an event time range of more than 6 months.',
                        },
                    };
                    const value = data?.time;
                    if (value && !value?.relative && value?.length === 2) { // if its an absolute date
                        const d1 = moment(value[0]);
                        const d2 = moment(value[1]);
                        const monthDiff = d2.diff(d1, 'months', true);
                        return monthDiff > 6 ? error : {};
                    }
                    const currentDate = moment();
                    const relativeDate = value && value?.relative && moment(currentDate)[value.range](value.amount, value.unit);
                    const sixMonthsOld = currentDate.clone().subtract(6, 'months');
                    const sixMonthsLater = currentDate.clone().add(6, 'months');
                    if (relativeDate && !relativeDate.isValid()) {
                        return { format: { pattern: /somefaketest/, message: 'Invalid date format.' } };
                    }
                    if (relativeDate && (relativeDate.isBefore(sixMonthsOld) || relativeDate.isAfter(sixMonthsLater))) {
                        return error;
                    }
                } catch (e) {}
                return {};
            }
        }
    },
    {
        field: 'streamTime',
        type: 'dateTimeRange',
        properties: {
            label: 'Event Mi-Stream Time',
            name: 'streamTime'
        }
    },
    {
        field: 'description',
        type: 'text',
        properties: {
            label: 'Event Description',
            name: 'description',
            opSelector: true
        }
    },
    {
        field: 'data.type',
        type: 'text',
        properties: {
            label: 'Event Translated Type',
            name: 'dataType',
            opSelector: true
        },
    },
    {
        field: 'data.impact',
        type: 'text',
        properties: {
            label: 'Event Translated Impact',
            name: 'dataImpact',
            opSelector: true
        }
    },
    {
        field: 'severity',
        type: 'typeahead',
        properties: {
            label: 'Event Severity',
            name: 'severity',
            multiple: true,
            placeholder:'Search for event severity...',
            options: Object.entries(severityOptions).reduce(
                (accum, [value, label]) => {
                    accum.push({ label, value });
                    return accum;
                },
                []
            )
        },
        condition: 'in'
    },
    ...commonEntitiesListFilters
];
