import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { saveComponentState } from 'store/actions/component/componentActions';
import history from 'store/History';
import ButtonIcon from 'app/components/molecules/ButtonIcon/ButtonIcon';



const Flex = styled.div`
    display: flex;
    flex-direction: row;
    width: fit-content;
    cursor: pointer;
    color: ${({ theme }) => theme.material.colors.link };
`;

const WidgetFooter = (props) => {
    const VIEW_ID = 'TaskList';
    const userId = useSelector(state=>state.user?.profile?.id);
    const filterViewState = useSelector(state=>state.component?.state?.[VIEW_ID]);
    const dispatch = useDispatch();

    const filters = useMemo(()=>{
        let filters = { assigneeId: [userId], statusType: 'opentask', assigneeIdNull: null };
        if(props?.filters){
            filters = props.filters;
        }
        return filters;
    },[props?.filters, userId]);

    const orderBy = useMemo(()=>{
        let orderBy = { field: 'createdDate', direction: 'desc nulls last' };
        if(props?.orderBy){
            orderBy = props.orderBy;
        }
        return orderBy;
    },[props?.orderBy]);

    const goToClick = async () => {
        await dispatch(saveComponentState(VIEW_ID, {...filterViewState, filters, isPreferenceLoaded: true, orderBy}));
        history.push('/abox/tasks');
    };

    return (
        <Flex onClick={goToClick} >
            <ButtonIcon icon="arrow-right" size="sm" />VIEW ALL
        </Flex>
    );
};

export default WidgetFooter;
