/* @flow */

// $FlowFixMe
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';

import { loadProcessTasks } from 'store/actions/abox/taskActions';
import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import styled from 'styled-components';
import TasksView from 'app/components/organisms/TasksView/TasksView';
import { usePrevious }from 'app/utils/hook/hooks';

const ContentAreaStyled = styled(ContentArea)`
    overflow: hidden;
    height: 100%;
`;

const ProcessTasksTab = ({ loadProcessTasks, id, totalRecords, records, isLoading, startIndex }: Object) => {
    const [key, setKey] = useState(1);
    const handleLoadProcessTasks = useCallback((options) => {
        if(id) {
            return loadProcessTasks(options, id);
        } else {
            return Promise.resolve();
        }
    }, [id, loadProcessTasks]);

    const prevIdProp = usePrevious(id);
    useEffect(() => {
        if (id !== prevIdProp) {
            setKey(key+1);
        }
    }, [id, key, prevIdProp]);

    const buildFiltersProps = useMemo(() => ({
        id: `ProcessTasksTab.${id || 'null'}`,
        defaultOrder: [{ field: 'modifiedDate', direction: 'desc nulls last' }],
        defaultFilters: {
            statusType: null, involvement: null, dueDateNull: null,
            primaryStartDate: null, assigneeIdNull: null, priority: null, processDefinitionNameNull: null },
    }), [id]);

    const listProps = { totalRecords, records, isLoading, startIndex };
    return (
        <ContentAreaStyled>
            <TasksView
                key={key}
                loadData={handleLoadProcessTasks}
                FiltersProps={buildFiltersProps}
                noActions
                {...listProps}
            />
        </ContentAreaStyled>
    );
};

export default connect((state, props) => ({
    isLoading: state.abox.process.tasks.isLoading,
    startIndex: state.abox.process.tasks.startIndex,
    records: state.abox.process.tasks.records,
    totalRecords: state.abox.process.tasks.count,
}), { loadProcessTasks })(ProcessTasksTab);
