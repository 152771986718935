/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import debouncePromise from 'p-debounce';
import { connect } from 'react-redux';

import Geocode from 'app/utils/maps/geocodeUtils';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import Loader from 'app/components/atoms/Loader/Loader';
import { LocationInfo } from 'app/utils/types/interfaces';
import { set } from 'app/utils/immutable/Immutable';
import { get } from 'app/utils/lo/lo';
import { bind, memoize, debounce } from 'app/utils/decorators/decoratorUtils';
import { loadAffectliConfiguration } from 'store/actions/app/appActions';

const WAIT_INTERVAL = 1000;

class GeotagLocation extends PureComponent<Object, Object> {

    static propTypes: Object = {
        value: PropTypes.object,
        iconInfo: PropTypes.object,
        name: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        onMouseDown: PropTypes.func,
        googleApiKey: PropTypes.string,
        isConfigLoading: PropTypes.bool,
    };

    state: Object = {
        addressLoading: false
    };
    formRef: Object = React.createRef();
    errors: boolean = false;

    @bind
    @memoize()
    fieldDefinitions(
        iconInfo: Object,
        googleApiKey: string
    ) {
        return [
            googleApiKey && {
                type: 'location',
                properties: {
                    name: 'location',
                    MarkerProps: {
                        name: get(iconInfo, 'name', 'map-marker'),
                        color: get(iconInfo, 'color')
                    },
                    googleApiKey,
                }
            },
            {
                type: 'text',
                properties: {
                    label: 'Name',
                    name: 'name',
                }
            },
            {
                type: 'panel',
                properties: {
                    header: 'GPS co-ordinates',
                    expanded: false
                },
                children: [
                    {
                        type: 'typeahead',
                        properties: {
                            label: 'Location Type',
                            name: 'field',
                            options: [{ label: 'DegDec', value: 'DegDec' }, { label: 'DMS', value: 'DMS' }, { label: 'MinDec', value: 'MinDec' }]
                        }
                    },
                    {
                        type: 'number',
                        properties: {
                            label: 'Latitude',
                            name: 'latitude',
                            isVisible: (data: Object) => data.field === 'DegDec'
                        },
                        constraints: {
                            numericality: {
                                greaterThan: -90,
                                lessThanOrEqualTo: 90
                            }
                        }
                    },
                    {
                        type: 'number',
                        properties: {
                            label: 'Longitude',
                            name: 'longitude',
                            isVisible: (data: Object) => data.field === 'DegDec'
                        },
                        constraints: {
                            numericality: {
                                greaterThan: -180,
                                lessThanOrEqualTo: 180
                            }
                        }
                    },
                    {
                        type: 'number',
                        properties: {
                            label: 'Latitude Degrees °',
                            name: 'lat.degrees',
                            isVisible: (data: Object) => (data.field === 'DMS' || data.field === 'MinDec')
                        },
                        constraints: {
                            numericality: {
                                greaterThan: -90,
                                lessThanOrEqualTo: 90
                            }
                        }
                    },
                    {
                        type: 'number',
                        properties: {
                            label: 'Latitude Minutes \'',
                            name: 'lat.minutes',
                            isVisible: (data: Object) => (data.field === 'DMS' || data.field === 'MinDec')
                        },
                        constraints: {
                            numericality: {
                                greaterThanOrEqualTo: 0,
                                lessThanOrEqualTo: 60
                            }
                        }
                    },
                    {
                        type: 'number',
                        properties: {
                            label: 'Latitude Seconds "',
                            name: 'lat.seconds',
                            isVisible: (data: Object) => data.field === 'DMS'
                        },
                        constraints: {
                            numericality: {
                                greaterThanOrEqualTo: 0,
                                lessThanOrEqualTo: 60
                            }
                        }
                    },
                    {
                        type: 'typeahead',
                        properties: {
                            label: 'Latitude direction (N/S)',
                            name: 'lat.direction',
                            isVisible: (data: Object) => (data.field === 'DMS' || data.field === 'MinDec'),
                            options: [{ label: 'N', value: 'N' }, { label: 'S', value: 'S' }]
                        }
                    },
                    {
                        type: 'number',
                        properties: {
                            label: 'Longitude Degrees °',
                            name: 'long.degrees',
                            isVisible: (data: Object) => (data.field === 'DMS' || data.field === 'MinDec')
                        },
                        constraints: {
                            numericality: {
                                greaterThan: -180,
                                lessThanOrEqualTo: 180
                            }
                        }
                    },
                    {
                        type: 'number',
                        properties: {
                            label: 'Longitude Minutes \'',
                            name: 'long.minutes',
                            isVisible: (data: Object) => (data.field === 'DMS' || data.field === 'MinDec')
                        },
                        constraints: {
                            numericality: {
                                greaterThanOrEqualTo: 0,
                                lessThanOrEqualTo: 60
                            }
                        }
                    },
                    {
                        type: 'number',
                        properties: {
                            label: 'Longitude Seconds "',
                            name: 'long.seconds',
                            isVisible: (data: Object) => data.field === 'DMS'
                        },
                        constraints: {
                            numericality: {
                                greaterThanOrEqualTo: 0,
                                lessThanOrEqualTo: 60
                            }
                        }
                    },
                    {
                        type: 'typeahead',
                        properties: {
                            label: 'Longitude Direction (E/W)',
                            name: 'long.direction',
                            isVisible: (data: Object) => (data.field === 'DMS' || data.field === 'MinDec'),
                            options: [{ label: 'E', value: 'E' }, { label: 'W', value: 'W' }]
                        }
                    }
                ]
            },
            {
                type: 'panel',
                properties: {
                    header: 'Address',
                    extended: false
                },
                children: [
                    {
                        type: 'checkbox',
                        properties: {
                            label: 'Custom Address',
                            name: 'is_manual',
                        }
                    },
                    {
                        type: 'text',
                        properties: {
                            label: 'Address Line 1',
                            name: 'address.line1',
                        }
                    },
                    {
                        type: 'text',
                        properties: {
                            label: 'Address Line 2',
                            name: 'address.line2'
                        }
                    },
                    {
                        type: 'text',
                        properties: {
                            label: 'City',
                            name: 'address.city'
                        }
                    },
                    {
                        type: 'text',
                        properties: {
                            label: 'State/Province',
                            name: 'address.province'
                        }
                    },
                    {
                        type: 'text',
                        properties: {
                            label: 'Post/Zip Code',
                            name: 'address.code'
                        }
                    },
                    {
                        field: 'address.country',
                        type: 'entityTypeahead',
                        properties: {
                            label: 'Country',
                            valueField: 'name',
                            name: 'address.country',
                            showType: false,
                            placeholder: 'Select a country...',
                            entityType: 'system_country',
                        }
                    }
                ]
            }
        ].filter(Boolean);
    }

    componentDidMount() {
        this.props.loadAffectliConfiguration();
    }

    @bind
    @memoize()
    setDDToDMS(locationInfo: Object) {
        const { latitude, longitude } = locationInfo;
        const lat = Geocode.convertDDtoDMS('latitude', latitude);
        const long = Geocode.convertDDtoDMS('longitude', longitude);
        locationInfo = set(locationInfo, 'lat', lat);
        locationInfo = set(locationInfo, 'long', long);
        return locationInfo;
    }

    @bind
    onChange(locationInfo: LocationInfo) {
        const value = locationInfo;
        this.props.onChange({ target: { name: this.props.name, value }});
    }

    @bind
    async validateForm() {
        if (this.formRef && this.formRef.current) {
            const { errors } = await this.formRef.current.isValidForm();
            return { errors };
        } else {
            return { errors: null };
        }
    }

    @bind
    @debounce()
    async handleFormValidation() {
        const result = await this.validateForm();
        this.errors = !!result.errors;
        return !result.errors;
    }


    /**
     * used in parent (Thing, Person, Organisation, Custom Entity) About to validate.
     */
    @bind
    hasErrors() {
        return this.errors;
    }

    @bind
    @memoize()
    async handleChange(locationInfo: Object, variableData: Object) {
        await this.handleFormValidation();
        const { name, value } = variableData;
        const { is_manual, location, ...rest } = locationInfo;
        let updatedLocationInfo = { is_manual, ...rest };
        if (updatedLocationInfo.geom) {
            delete updatedLocationInfo.geom;
        }

        if (name === 'address.country' && !value) {
            const address = { add_type: 'Physical', city: '', code: '', country: '', line1: '', line2: '', province: '' };
            updatedLocationInfo = { ...updatedLocationInfo, latitude: null, longitude: null, address };
            this.onChange(updatedLocationInfo);
            return;
        }

        if (name === 'location') {
            updatedLocationInfo = this.setDDToDMS(value);
        } else if (name === 'latitude' || name === 'longitude') {
            updatedLocationInfo = set(updatedLocationInfo, name, value);
        } else if (name.includes('lat.')) {
            const { lat } = locationInfo;
            const latitude = Geocode.convertDMSToDD(lat.degrees, lat.minutes, lat.seconds, lat.direction);
            updatedLocationInfo = set(updatedLocationInfo, 'latitude', latitude);
        } else if (name.includes('long.')) {
            const { long } = locationInfo;
            const longitude = Geocode.convertDMSToDD(long.degrees, long.minutes, long.seconds, long.direction);
            updatedLocationInfo = set(updatedLocationInfo, 'longitude', longitude);
        } else if (name === 'field') {
            updatedLocationInfo = this.setDDToDMS(locationInfo);
        }

        if (name === 'latitude' || name === 'longitude' ||
            (['location', 'lat.', 'long.'].find(s => name.includes(s)) && !is_manual)) {
            this.handleLatLongChange(updatedLocationInfo);
        } else if (name.includes('address')) {
            this.handleAddressChange(updatedLocationInfo);
        }
        this.onChange(updatedLocationInfo);
    }

    handleLatLongChange = debouncePromise((locationInfo: Object) => {
        const lat = Number(get(locationInfo, 'latitude'));
        const long = Number(get(locationInfo, 'longitude'));
        Geocode.fromLatLong(lat, long).then(
            (response) => {
                const gAddress = get(response, 'data.result.address') || [];
                const address = Geocode.getAddress(gAddress);
                locationInfo = set(locationInfo, 'address', { add_type: 'Physical', ...address });
                this.onChange({ ...locationInfo, isLoading: false });
            },
            (error) => {
                const isValidLat = lat ? Geocode.isValidLatitute(lat) : true;
                const isValidLong = long ? Geocode.isValidLongitute(long) : true;
                if (isValidLat && isValidLong) {
                    this.onChange({ ...locationInfo, isLoading: false });
                }
            }
        );
    }, WAIT_INTERVAL)

    handleAddressChange = debouncePromise((locationInfo: Object) => {
        const adresString = this.getAddressInput(locationInfo);
        Geocode.fromAddress(adresString).then((response) => {
            const { lat, lng } = get(response, 'data.result.address[0].geometry.location', {}) || {};
            locationInfo = set(this.props.value, 'latitude', lat);
            locationInfo = set(locationInfo, 'longitude', lng);
            this.onChange({ ...locationInfo, isLoading: false });
        });
    }, WAIT_INTERVAL)

    /**
     *
     */
    @bind
    @memoize()
    getAddressInput(locationInfo: Object) {
        const line1 = locationInfo.address['line1'];
        const line2 = locationInfo.address['line2'];
        const code = locationInfo.address['code'];
        const city = locationInfo.address['city'];
        const province = locationInfo.address['province'];
        const country = locationInfo.address['country'];
        return `${line1} ${line2} ${code} ${city} ${province} ${country}`;
    }

    /**
     * Lifecycle hook.
     * @returns {XML}
     */
    render(): Object {
        const {
            value, iconInfo, googleApiKey, isConfigLoading, disabled, onMouseDown
        } = this.props;
        const locationInfo = value || { field: 'DegDec' };
        const { longitude, latitude } = locationInfo;
        const components = this.fieldDefinitions(iconInfo, googleApiKey);
        return (
            <div style={{ width: '100%', margin: '10px 0' }} onMouseDown={onMouseDown}>
                {isConfigLoading && <Loader absolute backdrop />}
                <FormGenerator
                    components={components}
                    data={{
                        ...locationInfo,
                        field: locationInfo.field || 'DegDec',
                        location: { latitude, longitude }
                    }}
                    onChange={this.handleChange}
                    ref={this.formRef}
                    root={false}
                    disabled={disabled}
                />
            </div>
        );
    }
}

export default connect(
    state => ({
        isConfigLoading: state.app.isConfigLoading,
        googleApiKey: state.app.config.googleApiKey,
    }),
    { loadAffectliConfiguration }
)(GeotagLocation);
