/* @flow */

import { gql } from '@apollo/client';
import relationDefinitionsCommonQueryFields from 'graphql/entities/relationships/relationDefinitionsCommonQueryFields';

export default gql`
    query relationDefinitionDetailsQuery($id: ID!) {
        result: relationDefinition(id: $id) {
            ${relationDefinitionsCommonQueryFields}
        }
    }
`;
