/* @flow */

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ConfirmationModal } from '@mic3/platform-ui';
import Icon from 'app/components/atoms/Icon/Icon';
import IconProps from 'app/components/atoms/Icon/IconProps';
import RoundedIndicator from 'app/components/atoms/RoundedIndicator/RoundedIndicator';
import history from 'store/History';

import { useToggle } from 'app/utils/hook/hooks';

const TextIconContainer = styled.button`
  background: none; border: none 0; outline: none;
  position: relative;
  display: inline-block;
  text-align: center;
  cursor: ${({ disabled }) => disabled ? '': 'pointer'};
  margin: ${({ margin }) => margin ? '1rem': '0'};
  line-height: 0.8;
  ${({ padding }) => padding ? `padding: ${padding}px`: ''};
  text-decoration: none;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
`;

const IconStyled = styled(Icon)`
    &:before {
        ${({ hexColor }) => hexColor ? `color: ${hexColor } `: '#9fa3ab'}
    }
`;

const TextIconLabel = styled.div`
  color: #888;
  font-size: 0.8rem;
  font-weight: 500;
  text-decoration: none;
`;

const TextIconIndicator = styled(RoundedIndicator)`
  position: absolute;
  right: 0;
  width: 0.8rem;
  height: 0.8rem;
  font-size: 0.5rem;

`;

const TextIcon = (props: Object) => {
    const {
        confirmationModalProps, withConfirmation, onClick, hexColor,
        icon, iconType, color, size, label, count, to, type, form,
        disabled, margin, loading, ...rest
    } = props;

    const [openConfirmationModal, toggleConfirmationModal] = useToggle();
    const handleOnClick = useMemo(() => {
        if(to) {
            return () => {history.push(to);};
        }
        return onClick;
    }, [to, onClick]);
    const onClickWithConfirm = withConfirmation ? toggleConfirmationModal : handleOnClick;

    return (
        <>
            <TextIconContainer
                loading={String(!!loading || false)}
                margin={margin?1:0}
                type={type}
                form={form}
                disabled={disabled}
                onClick={onClickWithConfirm}
                padding={props.padding}
                {...rest}
            >
                { count ? <TextIconIndicator count={count} color="warning" /> : null }
                <IconStyled disabled={disabled} name={icon} type={iconType} color={color} size={size} hexColor={hexColor} />
                {label && <TextIconLabel>{label}</TextIconLabel>}
            </TextIconContainer>
            {withConfirmation && confirmationModalProps && (
                <ConfirmationModal
                    {...confirmationModalProps}
                    onClose={toggleConfirmationModal}
                    open={openConfirmationModal}
                    onConfirm={handleOnClick}
                />
            )}
        </>
    );
};

TextIcon.propTypes = {
    ...IconProps,
    type: PropTypes.string,
    label: PropTypes.string,
    loading: PropTypes.bool,
    withConfirmation: PropTypes.bool,
    confirmationModalProps: PropTypes.object,
};

export default TextIcon;
