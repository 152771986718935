/* @flow */

import React from 'react';
import PropTypes from 'prop-types';

import Link from './Link';


/**
 * Renders a hyper link
 *
 * @param {Object} props - the Component's properties
 * @return {ReactDOM} - return a JSX Element
 */
const HyperLink = ({ text, url, internal }: Object) => {
    if (!text) {
        return null;
    }
    return internal ?
        (<Link to={url}>{text}</Link>)
        :
        (<a href={url} target="_blank" rel='noreferrer noopener'>{text}</a>);
};

HyperLink.propTypes = {
    text: PropTypes.string,
    url: PropTypes.string,
    internal: PropTypes.bool,
};

export default HyperLink;
