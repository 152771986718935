/* @flow */

import React, { memo } from 'react';
import Viewer from 'react-viewer';

/*
 * @param visible (boolean)
 * @param images (Array[{ src: 'image-url'}])
 * @param activeIndex (number) - array index of selected/active image from images list.
 * @param onClose (function)
 * @library https://github.com/infeng/react-viewer
 */
const ImageViewer = ({
    visible,
    images,
    activeIndex,
    onClose,
    ...otherProps
}: Object) => {
    return (
        <Viewer
            zIndex='1200'
            visible={visible}
            onClose={onClose}
            zoomSpeed={0.2}
            images={images}
            activeIndex={activeIndex}
            {...otherProps}
        />
    );
};

export default memo(ImageViewer);
