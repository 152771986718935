/* @flow */

import Immutable from 'app/utils/immutable/Immutable';

import {
    LOAD_CHAT_ROOM_FILES_STARTED, LOAD_CHAT_ROOM_FILES,
    DELETE_CHAT_ATTACHMENT, LOAD_CHAT_ROOM_FILES_RESET, LOAD_CHAT_RECENT_ROOM_FILES_STARTED, LOAD_CHAT_RECENT_ROOM_FILES
} from 'store/actions/chat/chatActions';
import { get } from 'app/utils/lo/lo';

const initialState = {
    isLoading: false,
    data: [],
    isRecentFilesLoading: false,
    recentFiles: [],
};

export default (state: Object = initialState, action: Function) => {
    const { type, meta, error, payload } = action;
    switch (type) {
        case LOAD_CHAT_ROOM_FILES_RESET:
            if (get(meta, 'id') !== get(state, 'meta.id')) {
                return Immutable({ ...state, data: [], meta, isLoading: true });
            }
            return Immutable({ ...state, isLoading: true });
        case LOAD_CHAT_ROOM_FILES_STARTED:
            return Immutable({ ...state, isLoading: true });
        case LOAD_CHAT_ROOM_FILES:
            if (error) {
                return Immutable({ ...state, error, meta, isLoading: false });
            }
            return Immutable({ ...state, isLoading: false, data: payload });

        case DELETE_CHAT_ATTACHMENT: {
            if (!error && state.data.find(({_id}) => _id === payload.fileId)) {
                const data = state.data.filter(({_id}) => _id !== payload.fileId);
                return Immutable({ ...state, isLoading: false, data });
            }
            return state;
        }
        case LOAD_CHAT_RECENT_ROOM_FILES_STARTED:
            return Immutable({ ...state, isRecentFilesLoading: true });

        case LOAD_CHAT_RECENT_ROOM_FILES:
            if (error) {
                return Immutable({ ...state, isRecentFilesLoading: false });
            }
            return Immutable({ ...state, isRecentFilesLoading: false, recentFiles: payload });

        default:
            return state;
    }
};
