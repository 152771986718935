import React from 'react';
import styled from 'styled-components';
import { markdown } from '@mic3/platform-ui';

const Text = styled.div`
& * { margin: 0 !important; }
& p { margin: 0.2em !important; font-size: 12px; }
& ul { padding-inline-start: 30px; }
& li { font-size: 12px; }
& div { font-size: 13px; }
& pre {
  margin: 0.4em !important;
  font-size: 11px;
}
`;

export default ({ message }) => (
    <Text dangerouslySetInnerHTML={{__html: markdown(message) }} />
);
