import React, { memo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { muiTheme } from 'app/themes/materialUi';
import { TreeItem as TreeItemWrapper, TreeView } from '@material-ui/lab';

import Icon from 'app/components/atoms/Icon/Icon';
import TreeListItem from './TreeListItem';
import { get } from 'app/utils/lo/lo';

const TreeViewStyled = styled(TreeView)`
    padding: 0 16px 0 16px !important;
    & .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label, .MuiTreeItem-label {
        background-color: transparent !important;
    }
    
    ${({ type }) => type === 'entityType' ? `width: calculate(100% - 15px)`: null};
    ${({ type }) => type === 'event' ? `width: calculate(100% - 15px)`: null};

    ${({ type }) =>
  ` & .MuiTreeItem-iconContainer {
    ${ type === 'process' ? 
    `display: none;` 
      : `margin-left: 20px;
         margin-right: 16px;` 
    }}
    & .MuiListItemText-primary {
    ${type === 'process' ? 
    `padding-left: 19px;`
        : null};
    `}


    & .MuiTreeItem-group {
        margin-left: 36px !important;
    }
`;

const TreeItemWrapperStyled = styled(TreeItemWrapper)`
    margin-right: 8px !important;
    & > .MuiTreeItem-content {
    ${({ $selected, theme }) => {
    if ($selected) {
        return `background: ${theme.material.colors.itemActive} !important;`;
    }
}}
    }
    & .MuiTreeItem-label{
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        padding-right: 5px;
    }
`;

const TreeList = ({
    items,
    type,
    selected,
    onItemIconClick,
    onItemLabelClick,
    isLoadingNodeId,
    loadedNodeIds,
    ...otherProps
}) => {
    const [expanded, setExpanded] = useState([]);

    const addExpandedNodes = useCallback((nodeId) => {
        setExpanded((prevNodes) => {
            const nodeExpanded = prevNodes.find(prevNode => prevNode === nodeId);
            if (!nodeExpanded) {
                return [...prevNodes, nodeId];
            }
            return [...prevNodes.filter(prevNode => prevNode !== nodeId)];
        });
    }, []);

    const buildEndIcon = useCallback((children, nodeId) => {
        // if show classes on entity view
        if (onItemIconClick && !loadedNodeIds.includes(nodeId)) {
            return <Icon name="chevron-right" hexColor={muiTheme.colors.link} />;
        }
        if( type === 'process'){
            return null;
        }
        return children.length > 0 ? <Icon name="chevron-right" hexColor={muiTheme.colors.link} /> : <Icon name="circle-small" hexColor={muiTheme.colors.link} />;

    }, [onItemIconClick, loadedNodeIds, type]);

    const buildTreeItem = useCallback((data, parentId, parentUri, parentEntityTypeUri) => {
        const nodeId = parentId ? `${parentId}-${data.id}` : data.id;
        const children = get(data, 'children') || [];
        const isLoading = nodeId === isLoadingNodeId;
        const isEntityClassChildren = !!data.applicableOn;
        const getNewParentUri = isEntityClassChildren ? parentEntityTypeUri : data?.uri;

        const handleLabelClick = (e, data, nodeId) => {
            e.preventDefault();
            onItemLabelClick(data, nodeId, parentEntityTypeUri);
        };

        const handleIconClick = (e, data, nodeId) => {
            e.preventDefault();

            if (children.length > 0) {
                ((onItemIconClick && loadedNodeIds.includes(nodeId)) || !onItemIconClick) && addExpandedNodes(nodeId);
            }

            onItemIconClick && onItemIconClick(data, nodeId, getNewParentUri);
        };

        if (children.length <= 0) {
            return (
                <TreeItemWrapperStyled
                    $selected={selected === nodeId ? true : false}
                    key={data.id}
                    nodeId={nodeId}
                    onIconClick={e => handleIconClick(e, data, nodeId)}
                    onLabelClick={e => handleLabelClick(e, data, nodeId)}
                    endIcon={buildEndIcon(children, nodeId)}
                    label={
                        <TreeListItem
                            isLoading={isLoading}
                            data={data}
                            type={type}
                            isEntityClassChildren={isEntityClassChildren}
                        />
                    }
                />
            );
        } else {
            return (
                <TreeItemWrapperStyled
                    $selected={selected === nodeId ? true : false}
                    key={data.id} 
                    nodeId={nodeId}
                    onIconClick={e => handleIconClick(e, data, nodeId)}
                    onLabelClick={e => handleLabelClick(e, data, nodeId)}
                    endIcon={buildEndIcon(children, nodeId)}
                    label={
                        <TreeListItem
                            isLoading={isLoading}
                            data={data}
                            type={type}
                            childrenCount={children.length}
                            isEntityClassChildren={isEntityClassChildren}
                        />
                    }
                >
                    {children.map(child => buildTreeItem(child, nodeId, data?.uri, getNewParentUri))}
                </TreeItemWrapperStyled>      
            );
        }
    }, [onItemIconClick, onItemLabelClick, selected, type, isLoadingNodeId, loadedNodeIds, buildEndIcon, addExpandedNodes]);

    useEffect(() => {
        if (loadedNodeIds.length > 0) {
            const loadedNodeIdsCopy = [...loadedNodeIds];
            const lastAddedNode = loadedNodeIdsCopy.pop();
            addExpandedNodes(lastAddedNode);
            
        }
    }, [loadedNodeIds, addExpandedNodes]);
    
    return (
        <TreeViewStyled
            expanded={expanded}
            defaultCollapseIcon={<Icon name="chevron-down" hexColor={muiTheme.colors.link} />}
            defaultExpandIcon={<Icon name="chevron-right" hexColor={muiTheme.colors.link} />}
            defaultEndIcon={buildEndIcon(items)}
            type={type}
        >
            {items.map((data, index) => buildTreeItem(data, null, data?.uri, data?.uri))}
        </TreeViewStyled>
    );
};

TreeList.defaultProps = {
    items: [],
};

TreeList.propTypes = {
    selected: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.object),
    type: PropTypes.string.isRequired,
    onItemLabelClick: PropTypes.func.isRequired,
    onItemIconClick: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.bool
    ]),
    isLoadingNodeId: PropTypes.string,
    loadedNodeIds: PropTypes.arrayOf(PropTypes.string)
};

export default memo(TreeList);
