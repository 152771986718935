// serviceWorkerRegistration.js

export function register(config) {
    if ('serviceWorker' in navigator) {
        window.addEventListener('load', () => {
            navigator.serviceWorker
                .register('/sw.js')
                .then((registration) => {
                    registration.onupdatefound = () => {
                        const installingWorker = registration.installing;
                        if (installingWorker) {
                            installingWorker.onstatechange = () => {
                                if (installingWorker.state === 'installed') {
                                    if (navigator.serviceWorker.controller) {
                                        if (config && config.onUpdate) {
                                            config.onUpdate(registration);
                                        }
                                    } else {
                                        if (config && config.onSuccess) {
                                            config.onSuccess(registration);
                                        }
                                    }
                                }
                            };
                        }
                    };
                })
                .catch((error) => {
                    console.error('Service worker registration failed:', error); // eslint-disable-line
                });
        });
    }
}

export function unregister() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready
            .then((registration) => {
                registration.unregister();
            })
            .catch((error) => {
                console.error('Service worker unregistration failed:', error); // eslint-disable-line
            });
    }
}
