/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Autocomplete } from '@mic3/platform-ui';

import { loadProcessTypeahead } from 'store/actions/abox/processActions';
import { Avatar, ListItemText } from '@mic3/platform-ui';

import { bind, debounce } from 'app/utils/decorators/decoratorUtils';
import { TypeaheadChipInitials, TypeaheadAdornmentInitials } from 'app/components/organisms/Form/Typeahead/abstract/TypeaheadChip';
import { TypeaheadListItem } from 'app/components/organisms/Form/Typeahead/abstract/TypeaheadListItem';
import { shallowEquals } from 'app/utils/utils';
import { isValiduuid } from 'app/utils/string/string-utils';

/**
 * Select one or more groups using lazy loading.
 */
class ProcessTypeahead extends PureComponent<Object, Object> {

    static propTypes = {
        loadOptions: PropTypes.func.isRequired,
        options: PropTypes.arrayOf(PropTypes.object),
        isLoading: PropTypes.bool,
        filterBy: PropTypes.arrayOf(PropTypes.object),
    }

    state = { options: [], isLoading: false };

    componentDidUpdate(prevProps) {
        if (!shallowEquals(prevProps, this.props)) {
            this.setState({ options: [] });
        }
    }

    @bind
    optionTemplate({ name, id }: Object) {
        return ({
            option: (
                <TypeaheadListItem ContainerComponent="div" dense disableGutters>
                    <Avatar initials={name} />
                    <ListItemText primary={name} secondary={`#${id}`} />
                </TypeaheadListItem>
            ),
            ChipProps: {
                avatar: <TypeaheadChipInitials initials={name} />,
            },
            startAdornment: <TypeaheadAdornmentInitials initials={name} />,
            label: `${name || 'Name not available'} (${id ? id.slice(0,8) : 'Id not available'})`,
        });
    };

    @bind
    @debounce()
    suggest(event: Object) {
        this.setState({ isLoading: true });
        const { valueField, excludeBy, value, loadOptions, filterBy, orderBy = [{ field: 'name', direction: 'asc nulls last' }] } = this.props;
        let excludes = [...(excludeBy || [])];
        const filters = [];
        const query = event.target.value;
        if (query) {
            if (isValiduuid(query)) {
                filters.push({ or: [
                    { field: 'id', op: '=', value: query },
                    { field: 'name', op: 'contains', value: query },
                ] });
            } else {
                filters.push({ field: 'name', op: 'contains', value: query });
            }
        }
        if (filterBy) {
            if (typeof filterBy === 'function') {
                filters.push(...filterBy(value));
            } else if (filterBy.length) {
                filters.push(...filterBy);
            }
        }
        if (value) {
            const val = Array.isArray(value) ? value : [value];
            let orVal = [];
            if(valueField) {
                orVal = val.map(fieldVal => ({ field: valueField, op: '=', value: fieldVal }));
            } else {
                const selectedIds = val.map(({ id }) =>  id).filter(Boolean) || [];
                const excludedIdsFilter = [{ field: 'id', op: 'in', value: [...selectedIds, ...(this.props.selectedIds || [])] }];
                if(selectedIds?.length || this.props.selectedIds?.length){
                    excludes = [{ or: [excludedIdsFilter, ...excludes] }];
                }
            }
            if(orVal.length) {
                excludes.push({ or: orVal });
            }
        }

        loadOptions({
            startIndex: 0,
            stopIndex: 50,
            filterBy: filters,
            excludeBy: excludes,
            orderBy: orderBy,
        }).then((options) => {
            this.setState({ options, isLoading: false });
        }).catch((error) => {
            this.setState({ options: [], isLoading: false });
        });
    };

    render() {
        const { excludeBy, loadOptions, filterBy, placeholder, ...typeaheadProps } = this.props; // eslint-disable-line no-unused-vars
        const { options, isLoading } = this.state;
        return <Autocomplete
            {...typeaheadProps}
            VirtualListProps={{
                itemSize: 60,
            }}
            options={options}
            isLoading={isLoading}
            optionTemplate={this.optionTemplate}
            suggest={this.suggest}
            placeholder={placeholder || 'Search for a process...'}
        />;
    }
};

export default connect(
    null, { loadOptions: loadProcessTypeahead }
)(ProcessTypeahead);
