/* @flow */

import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Typography, Tooltip } from '@mic3/platform-ui';

import { isDefined } from 'app/utils/utils';

const DarkLabel = styled(Typography)`
    color: ${({ theme }) => theme.material.colors.text.secondary} !important;
    font-size: 14px !important;
    cursor: pointer;
`;

class GridEntityClasses extends PureComponent<Object, Object> {
  
  

    render() {
        const { data } = this.props;
        const { classes, parents } = data || {};
        const counter = classes ? classes?.length : parents?.length;
        const  title = 'Classes';
        const classTitle =  counter === 1  ? `class`: `classes` ; 
        return (     
            (isDefined(data.classes || data.parents) && counter !== 0 ) ?
                <Tooltip title = 'Open classes'>
                    <DarkLabel onClick={()=> this.props.openSidebar(title, data)} >  
                        {counter} {classTitle}
                    </DarkLabel>
                </Tooltip>
                : null
        );
    };
}
export default (GridEntityClasses);