/* @flow */
import { gql } from '@apollo/client';

export default gql`
query relationTeamToWorkspaceQuery($filterBy: [JSON]) {
    result: workspaces(filterBy: $filterBy) {
             id
             name
             isSystem
             __typename
    }
}
`;