/* @flow */
import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';

import LayerListHeader from 'app/components/molecules/Map/LayerList/LayerListHeader';
import { bind } from 'app/utils/decorators/decoratorUtils';
import { get } from 'app/utils/lo/lo';
import { getType } from 'app/config/typesConfig';
import VectorLayer from 'ol/layer/Vector';
import { __getMapLayers, getLayerType } from 'app/utils/maps/layer/layerUtils';

const searchFeatures = (entityLayer, features, layerSearch) => {
    const entityType = getLayerType(entityLayer?.info || entityLayer?.values_?.attributes);
    if (!layerSearch) return features;
    const lowerCaseSearch = layerSearch.toLowerCase();

    if (entityType === 'event_type' || entityType === 'entity_type') {
        return features.filter(({ name, device }) => {
            const featureName = name || device?.name;
            return featureName?.toLowerCase()?.includes(lowerCaseSearch);
        });
    }
    return [];
};

class LayerList extends PureComponent<Object, Object> {
    static propTypes = {
        mapId: PropTypes.string.isRequired,
        map: PropTypes.object.isRequired,
    };

    state = {
        layerModalOpen: false,
        layersSearch: '',
    };

    @bind
    toggleMapLayer(title: string, islayerVisible: boolean) {
        this.props.toggleMapLayer(this.props.name, title, islayerVisible);
    }

    @bind
    onLayersSearch(event) {
        const { value } = event.target || {};
        this.setState({ layersSearch: value || '' });
    }

    @bind
    getLayerFilters(entityType: string) {
        const layers = get(this.props, 'layers');
        if (!entityType) {
            return [];
        }
        const layer = (layers || []).find(({ title }) => getType(title) === getType(entityType));
        return (layer && layer.classifications) || [];
    }

    @bind
    getLayerFeatures(layer: Object) {
        if (layer && layer instanceof VectorLayer) {
            return layer.getSource().getSource ? layer.getSource().getSource().getFeatures() : layer.getSource().getFeatures();
        }
        return [];
    }

    @bind
    renderLayersList(layersSearch: string) {
        const { children, map, mapData, expandedItems, featuresMap } = this.props;
        const mapLayers = __getMapLayers(mapData);
        let filteredLayers = [...mapLayers];
        const filteredFeaturesMap = {};
        filteredLayers = mapLayers.filter(layer => layer.name.toLowerCase().includes(layersSearch.toLowerCase()));
        mapLayers.forEach((info) => {
            const entityLayer = map.getEntityLayer(info?.id) || map.findLayerById(info?.id);
            const features = featuresMap[info?.id] || [];
            const filteredFeature = entityLayer ? searchFeatures(entityLayer, features, layersSearch) : features;
            filteredFeaturesMap[info?.id] = filteredFeature;
            const index = filteredLayers.findIndex((item) => item.id === info.id);
            const newInfo = { ...info, filteredFeature };
            if (index === -1 && filteredFeature?.length) {
                filteredLayers.push(newInfo);
            } else {
                filteredLayers[index] = newInfo;
            }
        });
        return filteredLayers.length 
            ? filteredLayers.slice(0).reverse().map((item, index) => {
                const modifiedItem = {
                    ...item,
                    type: item.type === 'cesium' ? 'system_cesium_layer' : item.type,
                    iconName: item.type === 'cesium' ? 'globe': item?.iconName,
                    iconType: item.type === 'cesium' ? 'af' : item?.iconType,
                    title: item.type === 'cesium' ? item?.name : item?.title,
                    expanded: !!expandedItems[item?.name]
                };
                return children(modifiedItem, index, filteredFeaturesMap);
            }) 
            : 'No Results';

    }

    render() {
        const { layersSearch } = this.state;
        const { map, mapId, layersLoading, isLayerSidebar, mapData, entityTypes } = this.props;
        const mapLayers = __getMapLayers(mapData);
        return (
            <Fragment>
                <LayerListHeader
                    onLayersSearch={this.onLayersSearch}
                    value={layersSearch}
                    map={map}
                    mapId={mapId}
                    mapData={mapData}
                    layersLoading={layersLoading}
                    isLayerSidebar={isLayerSidebar}
                    classes={entityTypes}
                />
                {mapLayers && mapLayers.length > 0 && (
                    <div onScroll={this.handleScroll}>
                        {this.renderLayersList(layersSearch)}
                    </div>
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    isLayerSidebar: state.sidebar.title === 'Layers',
    entityTypes: state.app.allPrimaryClasses.records || [],
    featuresMap: state.maps.situationalAwareness.map.featuresMap,
});

export default connect(mapStateToProps)(withTheme(LayerList));
