/* @flow */

import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { muiTheme } from 'app/themes/materialUi';
import { MdiIcon, IconButton, Tooltip } from '@mic3/platform-ui';

import VirtualListItem from 'app/components/molecules/VirtualList/VirtualListItem';
import VirtualListManaged from 'app/components/molecules/VirtualList/VirtualListManaged';
import PageTemplate from 'app/components/templates/PageTemplate';
import Filters from 'app/components/organisms/Filters/Filters';
import AddProcess from 'app/components/Designer/Modals/AddProcess';
import Layout from 'app/components/molecules/Layout/Layout';
import { loadDesignerProcesses, createProcessDefinition } from 'store/actions/designer/designerProcessActions';
import { get } from 'app/utils/lo/lo';
import withPermissions from 'app/utils/withPermissions';
import ProcessItem from 'app/containers/Designer/Processes/Components/ProcessItem';
import { commonEntitiesListFilters } from 'app/utils/entity/entityUtils';
import { getSelectedPrimaryClass } from 'app/utils/classification/classificationUtils';
import { setDocumentTitle } from 'store/actions/app/appActions';
import { modulesAndPageTitles } from 'app/config/typesConfig';

class Processes extends PureComponent<Object, Object> {

    virtualListRef = React.createRef();

    /**
     * @const propTypes - describes the properties of the component
     * @const defaultProps - define the defaults values of the properties
     * @const filterDefinitions -definition for columns that we need to display in our grid
     */
    static propTypes = {
        isLoading: PropTypes.bool,
        records: PropTypes.array,
        startIndex: PropTypes.number,
        totalRecords: PropTypes.number,
        loadDesignerProcesses: PropTypes.func.isRequired,
        createProcessDefinition: PropTypes.func.isRequired,
    };

    static defaultProps = {
        isLoading: false
    };

    state = { isAddProcessOpen: false };

    breadcrumb = [{ title: 'Process Designer' }]
    filterDefinitions: Array<Object> = [
        { field: 'name', type: 'text', properties: { label: 'Name', name: 'name', opSelector: true }},
        { field: 'id', type: 'uuid', properties: { label: 'ID', name: 'id', opSelector: true }},
        ...commonEntitiesListFilters,
        {
            field: 'active',
            type: 'typeahead',
            properties: {
                label: 'Status',
                name: 'active',
                valueField: 'value',
                options: [
                    { value: null, label: 'Any' },
                    { value: true, label: 'Active' },
                    { value: false, label: 'Inactive' }
                ]
            },
            sort: false,
            condition: '='
        }
    ];

    searchBar = ['name', 'id'];
    defaultOrder = [{ field: 'createdDate', direction: 'desc nulls last' }];
    defaultFilters = { active: true }

    componentDidMount() {
        this.props.setDocumentTitle(modulesAndPageTitles.designer.processes);
    }
    
    renderComponent = ({ style, index, data, resize }) => {
        const selectedClass = getSelectedPrimaryClass('processdefinition', this.props.primaryClasses);
        return (
            <VirtualListItem style={style} key={index} index={index} resize={resize} padding={15}>
                <ProcessItem canAdd={this.props.canAdd} process={data} primaryClass={selectedClass} onActionComplete={get(this.virtualListRef, 'current.resetView')} />
            </VirtualListItem>
        );
    }

    toggleAddProcess = () => this.setState({ isAddProcessOpen: !this.state.isAddProcessOpen });

    render() {
        const { canAdd, totalRecords, records, isLoading, startIndex, loadDesignerProcesses, createProcessDefinition } = this.props;
        const { isAddProcessOpen } = this.state;
        return (
            <PageTemplate title="Processes">
                <Layout>
                    <Filters
                        id="DesignerProcesses"
                        filterDefinitions={this.filterDefinitions}
                        defaultOrder={this.defaultOrder}
                        searchBar={this.searchBar}
                        breadcrumb={this.breadcrumb}
                        defaultFilters={this.defaultFilters}
                        rightToolbar={canAdd && (
                            <>
                                <Tooltip title="Add process">
                                    <IconButton onClick={this.toggleAddProcess}>
                                        <MdiIcon name="plus" color={muiTheme.colors.text.secondary}/>
                                    </IconButton>
                                </Tooltip>
                            </>
                        )}
                    >
                        {(filterBy, orderBy) => (
                            <VirtualListManaged
                                ref={this.virtualListRef}
                                renderComponent={this.renderComponent}
                                itemSize={121}
                                itemCount={totalRecords || 0}
                                loadData={loadDesignerProcesses}
                                isLoading={isLoading}
                                startIndex={startIndex || 0}
                                filterBy={filterBy}
                                orderBy={orderBy}
                                list={records}
                                maxWidth="1024"
                                title={`${totalRecords >= 1000 ? '999+' : totalRecords } Processes`}
                            />
                        )}
                    </Filters>
                </Layout>
                {isAddProcessOpen && <AddProcess onClose={this.toggleAddProcess} addProcess={createProcessDefinition} />}
            </PageTemplate>
        );
    }
}

export default connect(state => ({
    isLoading: state.designer.processes.isLoading,
    startIndex: state.designer.processes.startIndex,
    records: state.designer.processes.records,
    totalRecords: state.designer.processes.count,
    primaryClasses: state.app.allPrimaryClasses.records || [],
}), { loadDesignerProcesses, createProcessDefinition, setDocumentTitle })(withPermissions(Processes, 'processdefinition'));
