import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { bind } from 'app/utils/decorators/decoratorUtils';
import { get } from 'app/utils/lo/lo';
import { deepEquals } from 'app/utils/utils';

import Filters from 'app/components/organisms/Filters/Filters';
import VirtualListManaged from 'app/components/molecules/VirtualList/VirtualListManaged';
import ResizableListItem from 'app/components/molecules/VirtualList/ResizableListItem';
import ChangelogItem from './ChangelogItem';

const FiltersStyled = styled(Filters)`
    width: 99% !important;
    ${({ $isMainContent }) => $isMainContent && 'height: calc(100vh - 104px) !important;' }
`;

class Changelog extends PureComponent {
    static propTypes = {
        type: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        loadData: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired,
        startIndex: PropTypes.number.isRequired,
        records: PropTypes.arrayOf(PropTypes.object),
        totalRecords: PropTypes.number
    }

    static defaultProps = {
        isLoading: false,
        startIndex: 0,
        totalRecords: 0,
        records: []
    }

    state = {
        filterListRendered: false
    }

    listRef: Object = React.createRef();

    searchBar = ['id', 'user.name'];
    defaultOrder = [{ field: 'date', direction: 'desc nulls last' }];
    
    filterDefinitions = [
        {
            field: 'date',
            type: 'dateTimeRange',
            properties: {
                label: 'Created date',
                name: 'date',
            },
        },
    ];

    componentDidUpdate(prevProps) {
        if (!deepEquals(prevProps.records, this.props.records)) {
            const ref = this.listRef.current;

            if (!this.state.filterListRendered) {
                ref && ref.resetView();
                this.setState({ filterListRendered: true });
            }

            ref && ref.forceUpdate();
        }

        if (this.props.type !== prevProps.type) {
            this.setState({ filterListRendered: false });
        }
    }

    @bind
    refreshFilter() {
        this.setState({ filterListRendered: false });
    }

    @bind
    renderComponent({ index, data, resize, style }: Object) {
        return (
            <ResizableListItem style={style} key={index} index={index} resize={resize} padding={4}>
                {resizeRow =>
                    <ChangelogItem
                        index={index}
                        data={data}
                        type={this.props.type}
                        resizeRow={resizeRow}
                        updateHeight={this.listRef.current && this.listRef.current.updateHeight}
                    />
                }
            </ResizableListItem>
        );
    }

    @bind
    loadChangelogs(options) {
        const { id, type, loadData } = this.props;
        
        if (['team', 'workspace'].includes(type)) {
            return loadData({ id, ...options, entityType: type });
        } else {
            const orderDir = get(options, 'orderBy.[0].direction');
            const order = orderDir.includes('desc') ? 'desc' : 'asc';
            const { orderBy, ...otherOpts } = options;
            return loadData({ id, ...otherOpts, order, entityType: type });
        }
    };

    render() {
        const { type, id, totalRecords, isLoading, records, startIndex, isMainContent } = this.props;
        return (
            <FiltersStyled
                id={`Changelog.${type}.${id}`}
                filterDefinitions={this.filterDefinitions}
                searchBar={this.searchBar}
                defaultOrder={this.defaultOrder}
                heightOffset={250}
                disableLeftPanelFilters
                onReload={this.refreshFilter}
                $isMainContent={isMainContent}
                
            >
                {(filterBy, orderBy) => (
                    <>
                        <VirtualListManaged
                            ref={this.listRef}
                            renderComponent={this.renderComponent}
                            loadData={this.loadChangelogs}
                            itemSize={65}
                            maxWidth="1024"
                            itemCount={totalRecords}
                            isLoading={isLoading}
                            filterBy={filterBy}
                            orderBy={orderBy}
                            list={records}
                            startIndex={startIndex}
                            title={`${totalRecords >= 1000 ? '999+' : totalRecords } history`}
                        />
                    </>
                )}
            </FiltersStyled>
        );
    };
}

export default Changelog;