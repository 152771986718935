/* @flow */

import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import IFrame from 'app/components/atoms/IFrame/IFrame';

export const ScaledFrame = styled(IFrame)`
${
    ({ parentWidth }) => (parentWidth < 0 || parentWidth > 1000)
        ? ''
        : `
          width: 1000px;
          height: ${100 * 1000 / parentWidth}%;
          transform: scale(${parentWidth / 1000});
          transform-origin: 0 0;`
}
`;

const SupersetFrame = (props) => {
    const frame = useRef(null);
    const [parentWidth, setParentWidth] = useState(-1);
    const updateWidth = () => {
        if (frame.current && frame.current.parentNode) {
            const parentWidth = frame.current.parentNode.getBoundingClientRect().width;
            setParentWidth(parentWidth);
        }
    };
    useEffect(() => {
        updateWidth();
        window.addEventListener('resize', updateWidth);
        return function cleanup () {
            window.removeEventListener('resize', updateWidth);
        };
    });
    return <ScaledFrame ref={frame} {...props} parentWidth={parentWidth} />;
};

export default SupersetFrame;
