/* @flow */

import { gql } from '@apollo/client';

export default gql`
    mutation entityRelationUpdateAttributes($relation: ID!, $attributes: JSON!) {
        payload: entityRelationUpdateAttributes(relation: $relation, attributes: $attributes) {
            id
        }
    }
`;
