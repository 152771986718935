import { combineReducers } from 'redux';

import { dataTableReducer, keepDataAtStart, loadDataReducer } from 'app/utils/redux/reducer-utils';

import avatar from './relatedEntitiesAvatarReducer';
import tree from './relatedEntitiesTreeReducer';

import { 
    TREE_TEMPLATE_CREATE, TREE_TEMPLATE_CREATE_STARTED,
    TREE_TEMPLATE_RELATION_LIST, TREE_TEMPLATE_RELATION_LIST_STARTED,
} from 'store/actions/entities/relatedEntitiesActions';

export default combineReducers({
    create: loadDataReducer(TREE_TEMPLATE_CREATE_STARTED, TREE_TEMPLATE_CREATE),
    list: dataTableReducer(TREE_TEMPLATE_RELATION_LIST_STARTED, TREE_TEMPLATE_RELATION_LIST, keepDataAtStart),
    avatar,
    tree
});
