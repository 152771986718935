/* @flow */

import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import AttributeDetailModal from 'app/containers/Classifications/AttributeDetailModal/AttributeDetailModal';
import { safeToJsArray } from 'app/utils/trasformer/transformer';
import Container from 'app/components/atoms/Container/Container';
import TextIcon from 'app/components/molecules/TextIcon/TextIcon';
import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import Immutable, { set } from 'app/utils/immutable/Immutable';
import HeaderBar from 'app/components/molecules/HeaderBar/HeaderBar';
import { get, map, groupBy, sortBy } from 'app/utils/lo/lo';
import { bind } from 'app/utils/decorators/decoratorUtils';

import DragDropApp from './dragdrop/DragDropApp';
import './ClassificationDetailAttributes.css';

/**
 * General tab in classifications view.
 * Todo: We probably should extract the form in it's own component, however
 * nearly the only code here is form related.
 */
class ClassificationDetailAttributes extends Component<Object, Object> {
    static propTypes: Object = {
        classification: PropTypes.object,
        updateClassification: PropTypes.func,
        addAttribute: PropTypes.func,
        match: PropTypes.object,
    };

    state: Object;

    /**
     *
     * @param props
     */
    constructor(props: Object) {
        super(props);
        this.state = {
            classificationForm: props.classification || {},
            isAttributeModalOpen: false,
            attributeFieldUri: '',
        };
        (this: Object).handleChange = this.handleChange.bind(this);
        (this: Object).removeAttribute = this.removeAttribute.bind(this);
    }

    /**
     * @override
     */
    componentDidUpdate(prevProps: Object) {
        if (prevProps.classification !== this.props.classification) {
            this.setClassificationState(this.props);
        }
    }

    @bind
    setClassificationState(data) {
        this.setState({
            classificationForm: data.classification || null
        });
    }

    /**
     * Update order of items after each sort event.
     * @param fields
     */
    @bind
    updateOrder(fields: Object) {
        const myArray = [];
        Object.keys(fields).forEach((key) => {
            myArray.push(fields[key].map((item, i) => ({ ...item, settings: { ...(item.settings || {}), orderNo: i.toString() } })));
        });
        const allFieldsUpdated = [];
        myArray.forEach((arr) => {
            arr.forEach((item) => {
                allFieldsUpdated.push(item);
            });
        });
        const setForm = set(this.state, 'classificationForm.formDefinition.fields', Immutable(allFieldsUpdated));
        this.setState(setForm);
        const classificationForm = get(setForm, 'classificationForm');
        const { id, formDefinition } = classificationForm || {};
        this.props.updateClassification({ id, formDefinition });
    }

    /**
     * Handle a form change
     * @param event
     */
    @bind
    handleChange(event: Object) {
        const { name, value } = event.target;
        // Set a nested state based on input name
        this.setState({
            classificationForm: set(this.state.classificationForm, name, value)
        });
    }

    @bind
    closeAttributeDetailModal() {
        this.setState({ isAttributeModalOpen: false, attributeFieldUri: '' });
    }

    @bind
    openAttributeDetailModal() {
        this.setState({ isAttributeModalOpen: true });
    }

    /**
     * Remove Attribute
     * @param uri
     */
    @bind
    removeAttribute(uri) {
        let fields = this.props.classification.formDefinition.fields;
        fields = fields.filter((field) => {
            return field.properties.name !== uri;
        });
        this.setState({ classificationForm: set(this.state.classificationForm, 'formDefinition.fields', fields) }, () => {
            const { id, formDefinition } = this.state.classificationForm || {};
            this.props.updateClassification({ id, formDefinition });
        });
    }

    @bind
    editAttribute(uri: string){
        this.setState({ attributeFieldUri: uri, isAttributeModalOpen: true });
    }

    /**
     * Lifecycle hook.
     * @returns {XML}
     */
    render() {
        const { classification, canEdit } = this.props;
        const { isAttributeModalOpen } = this.state;
        const classificationFormFields = safeToJsArray(get(this.state, 'classificationForm.formDefinition.fields'));
        const groupedFields = groupBy(classificationFormFields, 'settings.groupName');

        const sortedFields = map(groupedFields, fields => sortBy(fields, field => Number(get(field, 'settings.orderNo'))));

        return (
            <Fragment>
                <HeaderBar
                    right={canEdit ? (
                        <TextIcon icon="plus" onClick={this.openAttributeDetailModal} />
                    ): null}
                />
                <ContentArea withHeader>
                    <Container width="1024">
                        {sortedFields.length > 0 && (
                            <DragDropApp
                                classId={classification.id}
                                fields={sortedFields}
                                editListItem={this.editAttribute}
                                removeListItem={this.removeAttribute}
                                handleChange={this.updateOrder}
                                canEdit={canEdit}
                            />
                        )}
                        {canEdit && isAttributeModalOpen && (
                            <AttributeDetailModal
                                attributeFieldUri={this.state.attributeFieldUri}
                                classification={this.props.classification}
                                updateClassification={this.props.updateClassification}
                                onClose={this.closeAttributeDetailModal}
                            />
                        )}
                    </Container>
                </ContentArea>
            </Fragment>
        );
    }
}

export default withRouter(ClassificationDetailAttributes);
