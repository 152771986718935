/* @flow */

import { gql } from '@apollo/client';

export default gql`
query widgetTasksCountByInvolvementQuery(
  $assignee: [JSON], $assigneeExclude: [JSON],
  $owner: [JSON], $ownerExclude: [JSON],
  $teamMember: [JSON], $teamMemberExclude: [JSON]
) {
  assignee: count(dataType: "task", filterBy: $assignee, excludeBy: $assigneeExclude)
  owner: count(dataType: "task", filterBy: $owner, excludeBy: $ownerExclude)
  teamMember: count(dataType: "task", filterBy: $teamMember, excludeBy: $teamMemberExclude)
}
`;
