/* @flow */

import { label } from 'app/utils/designer/form/settings/common/commonBase';
import { confirmationBuilder } from 'app/utils/designer/form/settings/common/commonGeneral';
import { onLoad, onClick, isVisible, isDisabled } from 'app/utils/designer/form/settings/common/commonEvents';
import { help } from 'app/utils/designer/form/settings/common/commonHelpers';
import { flexGrow } from 'app/utils/designer/form/settings/common/commonStyles';

const panels = settingsValues => [
    {
        header: 'Base',
        children: [ label ],
    },
    {
        header: 'Events',
        expanded: true,
        children: [
            { type: 'boolean', properties: { label: 'Validate form before action', name: 'properties.validateBefore' } },
            {
                type: 'typeahead',
                properties: {
                    label: 'On click action type',
                    name: 'properties.actionType',
                    valueField:'value',
                    clearable: false,
                    options: [
                        { value: 'function' , label: 'Function' },
                        { value: 'internal' , label: 'Internal link' },
                        { value: 'external' , label: 'External link' },
                    ],
                    onChange: String(function (event) {
                        const { value, name } = event.target;
                        if(value === 'internal') {
                            return [
                                { name: 'properties.href', value: null },
                                { name, value }
                            ];
                        }
                        if(value === 'external') {
                            return [
                                { name: 'properties.to', value: null },
                                { name, value }
                            ];
                        }
                        return [
                            { name: 'properties.href', value: null },
                            { name: 'properties.to', value: null },
                            { name, value }
                        ];
                    }),
                }
            },
            ...confirmationBuilder,
            {
                type: 'text',
                properties: {
                    label: 'Link',
                    name: 'properties.to',
                    isVisible: ({ properties }) =>
                        properties && properties.actionType === 'internal',
                    // the onChange function must be a string to run in the service worker
                    onChange: String(function (event) {
                        const {value, name} = event.target;
                        return [
                            { name: 'properties.href', value: null },
                            { name, value }
                        ];
                    }),
                },
                constraints: {
                    required: ({ properties }) =>
                        properties?.actionType === 'internal'
                },
            },
            {
                type: 'text',
                properties: {
                    label: 'Link',
                    name: 'properties.href',
                    isVisible: ({ properties }) =>
                        properties && properties.actionType === 'external',
                    // the onChange function must be a string to run in the service worker
                    onChange: String(function (event) {
                        const {value, name} = event.target;
                        return [
                            { name: 'properties.to', value: null },
                            { name, value }
                        ];
                    }),
                },
                constraints: {
                    required: ({ properties }) => properties?.actionType === 'external'
                },
            },
            {
                type: 'typeahead',
                properties: {
                    label: 'Link target',
                    name: 'properties.target',
                    valueField:'value',
                    clearable: false,
                    options: [
                        { value: '_blank' , label: 'New tab' },
                        { value: '_self' , label: 'Same tab' },
                    ],
                    isVisible: ({ properties }) =>
                        properties && properties.actionType === 'external',
                }
            },
            onLoad,
            onClick,
            isVisible,
            settingsValues?.viewType !== 'class' && isDisabled
        ].filter(Boolean)
    },
    {
        header: 'Styles',
        children: [
            {
                type: 'typeahead',
                properties: {
                    label: 'Color',
                    name: 'properties.color',
                    valueField:'value',
                    clearable: false,
                    options: [
                        { value: 'primary' , label: 'primary' },
                        { value: 'secondary' , label: 'secondary' },
                    ]
                }
            },
            {
                type: 'typeahead',
                properties: {
                    label: 'Variant',
                    name: 'properties.variant',
                    valueField:'value',
                    clearable: false,
                    options: [
                        { value: 'text' , label: 'Text' },
                        { value: 'outlined' , label: 'Outlined' },
                        { value: 'contained' , label: 'Contained' },
                    ]
                }
            },
            { type: 'boolean', properties: { label: 'Full width', name: 'properties.fullWidth' } },
            {...flexGrow}
        ]
    },
    {
        header: 'Helpers',
        children: [ help ]
    },
];

export default {
    defaults: {
        actionType: 'function',
        fullWidth: true,
        variant: 'contained',
        color: 'primary',
        formFieldProps: { flexGrow: 1 }
    },
    panels: settingValues => panels(settingValues)
};
