/* @flow */

import React from 'react';
import uuidv1 from 'uuid/v1';

import { id, name, documentationPanel } from 'app/utils/designer/process/settings/common/commonImportant';
import { executionListener } from 'app/utils/designer/process/settings/common/commonListeners';
import ModalFormGenearator from 'app/components/organisms/ModalFormGenearator/ModalFormGenearator';

const panels = [
    {
        header: 'Important',
        expanded: true,
        children: [
            id,
            name,
            {
                type: 'custom',
                properties: {
                    cid: uuidv1(),
                    title: 'Flow condition',
                    name: 'flowCondition',
                    Component: props => (
                        <ModalFormGenearator
                            {...props}
                            previewRender={value => value && value.flowCondition && value.flowCondition.slice(0,30)}
                            components={[
                                {
                                    type: 'textarea',
                                    properties: {
                                        useCodeEditor: true,
                                        parseAs: 'javascript',
                                        title: 'Sequence flow condition',
                                        name: 'flowCondition',
                                        fullJs: true,
                                        noValidation: true,
                                    },
                                },
                            ]}
                        />
                    ),
                },
                constraints: { required: true }
            }
        ],
    },
    documentationPanel,
    {
        header: 'Listeners',
        children: [executionListener]
    },
];

export default {
    defaults: {},
    panels: settingValues => panels,
};
