import { gql } from '@apollo/client';

export default gql`
    query loadTermsQuery {
        terms {
            enabled
            content
            startDate
        }
    }
`;
