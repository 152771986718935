import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Tooltip, IconButton, MdiIcon, Badge } from '@mic3/platform-ui';
import Icon from 'app/components/atoms/Icon/Icon';
import styled from 'styled-components';
import { muiTheme } from 'app/themes/materialUi';

import { withRouter } from 'react-router';
import isUuid from 'uuid-validate';

import { isDefined, arrayObjectEquals } from 'app/utils/utils';
import { get, sortBy, groupBy } from 'app/utils/lo/lo';
import { bind, memoize} from 'app/utils/decorators/decoratorUtils';
import { NoSelectedBoard, IconStyled, FiltersBoard, StyledContentArea, BoardsLandingSection, StyledButton } from './styles';
import { loadEntity, updateEntity } from 'store/actions/entities/entitiesActions';
import { addRoleToData, getPermissions } from 'app/config/rolesConfig';

import { filterDefinitions, defaultFilters, defaultOrder } from 'app/components/organisms/TasksView/TasksView';
import { entitiesDefaultFilterDefinitions } from 'app/utils/entity/entityUtils';
import processFilterDefinitions from 'app/containers/Abox/ProcessesList/ProcessFilters';
import BetaBoardView from 'app/components/organisms/BoardView/BetaBoardView';
import PageTemplate from 'app/components/templates/PageTemplate';
import AddNewBoardModal from 'app/containers/Abox/Boards/AddNewBoardModal';
import BetaBoardColumnAction from 'app/components/organisms/BoardView/BetaBoardColumnAction';

import withPermissions from 'app/utils/withPermissions';
import { loadBoardRecords, loadBoardRecordsAll, setSelectedBoard, loadBoardPrimaryClass, cleanBoardColumns } from 'store/actions/abox/boardActions';
import { openTaskSidebar } from 'store/actions/abox/taskSidebarActions';
import { openEntitySidebar, closeEntitySidebar } from 'store/actions/entities/entitySidebarActions';
import { saveBoardPreferences } from 'store/actions/admin/usersActions';
import { saveComponentState } from 'store/actions/component/componentActions';
import { openMenuSidebar, closeMenuSidebar } from 'store/actions/entities/menuSidebarActions';
import { openSidebarByParams } from 'app/utils/app/appUtils';
import { openProcessSidebar } from 'store/actions/abox/processSidebarActions';
import { openClassSidebar } from 'store/actions/entities/classSidebarActions';
import { openTeamSidebar } from 'store/actions/entities/teamSidebarActions';
import { openWorkspaceSidebar } from 'store/actions/entities/workspaceSidebarActions';
import { openEventSidebar } from 'store/actions/stream/eventsSidebarActions';
import { openEventsActionsSidebar } from 'store/actions/stream/eventsActionsSidebarActions';
import { openEventTypeSidebar } from 'store/actions/entities/eventTypeSidebarActions';
import { closeFilterbar } from 'store/actions/filterbar/filterbarActions';
import { buildCustomDefinitions, getSelectedPrimaryClass } from 'app/utils/classification/classificationUtils';
import { setDocumentTitle, showToastr } from 'store/actions/app/appActions';

export const ContentAreaStyled = styled(StyledContentArea)`
    ${({ selectedBoard }) => (!selectedBoard ? 'display: flex;' : '')}
    ${({ selectedBoard }) => (!selectedBoard ? 'align-items: center;' : '')}
    ${({ selectedBoard }) => (!selectedBoard ? 'justify-content: center;' : '')}
`;


const VIEW_ID = 'BetaTaskBoards';

class BetaTaskBoards extends PureComponent<Object, Object> {
    static propTypes = {
        setBoardColumnState: PropTypes.func,
        setBoardColumnLoading: PropTypes.func,
        setSelectedBoard: PropTypes.func,
        selectedBoard: PropTypes.object,
        loadBoardRecords: PropTypes.func,
        loadCustomEntityBoardLabelTasks: PropTypes.func,
        boardColumns: PropTypes.array,
        deleteRelationAttributeFromEntity: PropTypes.func,
        showRelationEntityToTask: PropTypes.func,
        allBoardColumns:PropTypes.array,
        preferences: PropTypes.object,
        saveBoardPreferences: PropTypes.func
    };

    static defaultProps = {
        boardColumns: null,
        boardColumnState: null,
        boardsLeftPanel: null,
        boardTasks: null,
        isLoadingBoardColumns: false,
        selectedBoard: null,
        isLoading:false,
    };

    state = {
        disableAddColumn: false,
        groupBy: null,
        menuDefinitions: null,
        permissions: {},
        checkedColumns: this.props?.viewState.columns,
        clickApply: false,
        isActiveEntity: false,
    };

    searchBar = ['name', 'id'];
    defaultFilters = defaultFilters;
    defaultOrder = defaultOrder;

    constructor(props) {
        super(props);
        props.loadBoardPrimaryClass();
    }

    componentDidMount() {
        const { preferences } = this.props;
        const { betaBoards } = preferences;
        const board = get(preferences, `betaBoards.${betaBoards?.lastUsedBoardId}`);
        if (board) {
            const updatedBoard = get(preferences, `betaBoards.${board.id}`);
            this.isActiveEntity(board.type, board.id);
            this.loadBoardPermissions( updatedBoard ? updatedBoard : board);
        }
    }

    componentDidUpdate(prevProps ,prevState) {
        const {
            isSidebarOpen, closeMenuSidebar, filterIsOpen, closeFilterbar, selectedBoard, viewState, setSelectedBoard, preferences, setDocumentTitle
        } = this.props;
        const { betaBoards } = preferences;
        const { permissions, isActiveEntity } = this.state;
        const {canEdit,  canComment, canView} = permissions || {};
        if(selectedBoard?.id !== prevProps.selectedBoard?.id && 
            (filterIsOpen || isSidebarOpen))
        {
            closeFilterbar();
            closeMenuSidebar();
        }
        const board = get(preferences, `betaBoards.${betaBoards?.lastUsedBoardId}`);
        if( !selectedBoard && board && (canEdit || canComment || canView) && isActiveEntity){
            const updatedBoard = get(preferences, `betaBoards.${board.id}`); 
            setSelectedBoard(updatedBoard ? updatedBoard : board);
        }
        if( selectedBoard && selectedBoard !== prevProps.selectedBoard){
            const name = selectedBoard?.name;
            if(name){
                setDocumentTitle(name);
            }
            this.loadBoardPermissions(selectedBoard);
            this.isActiveEntity(selectedBoard.type, selectedBoard.id);
        }
        if (viewState?.columns && !arrayObjectEquals(viewState?.columns, prevProps.viewState?.columns)) {
            this.setState({ checkedColumns: viewState?.columns });
        }
    }

    componentWillUnmount(){
        const { cleanBoardColumns } = this.props;
        cleanBoardColumns([]);
    }

    @bind
    async isActiveEntity (type, id){
        if(type, id){
            const { active } = await this.props.loadEntity(type, id);
            this.setState({ isActiveEntity: active });
        }
    }

    @bind
    getUserDefinedFilters(selectedBoard){
        const boardType = get(selectedBoard, 'primary.system_board/entitytype.uri');
        const selectedClass = getSelectedPrimaryClass(boardType, this.props.primaryClasses);
        return buildCustomDefinitions(selectedClass);
    }

    @bind
    filterDefinitions(selectedBoard) {
        const entityType = selectedBoard?.primary?.['system_board/entitytype']?.uri;
        let userDefinedFilters = [];
        if( entityType !== 'closedtask' && entityType !== 'openprocess'){
            userDefinedFilters = this.getUserDefinedFilters(selectedBoard);
        }
        if(!selectedBoard?.primary || selectedBoard?.type !== 'system_board') {
            return entitiesDefaultFilterDefinitions();
        }
        let filters = [];
        switch(selectedBoard.primary['system_board/entitytype'].uri) {
            case 'opentask':
            case 'closedtask':
                filters = [...filterDefinitions];
                break;
            case 'openprocess':
            case 'closedprocess':
                filters = [...processFilterDefinitions];
                break;
            default: {
                filters = entitiesDefaultFilterDefinitions([selectedBoard.primary['system_board/entitytype'].uri]);
            }        
        }

        const isRelatedEntities = filters.find(item=> item.field === 'relations.relatedEntity.id' );

        if(!isRelatedEntities) {  
            filters.splice(3, 0, {
                field: 'relations.relatedEntity.id',
                type: 'relatedEntities',
                properties: {
                    label: 'Related Entities',
                    name: 'relatedEntities'
                },
                condition: 'in',
                sort: false,
            });
        }
        
        filters = [...filters, ...userDefinedFilters];
        return [...filters];

    }

    @bind
    async saveColumns(columns) {
        const { viewState, selectedBoard, saveComponentState } = this.props;
        this.setState({clickApply: true});
        await saveComponentState(`BetaTaskBoards.${selectedBoard?.id}`, { ...viewState, columns: columns,  boardKey: viewState.boardKey + 1 || 2,  });
    }

    @bind
    async resetBoardColumn(){
        const { selectedBoard, setSelectedBoard } = this.props;
        const { checkedColumns } = this.state;
        const resetCheckedColumns = checkedColumns.map((item)=> {
            const newItem = { ...item };
            newItem.isChecked = true;
            return newItem;
        } );
        this.saveColumns(resetCheckedColumns);
        await setSelectedBoard({...selectedBoard, primary:{...selectedBoard.primary,['system_board/showcolumns']: resetCheckedColumns }});
        showToastr({ severity: 'success', detail: 'Columns reset to default.' });
        this.buildBoardMenuItems();
        this.openMenu();
    }

        @bind
    async loadSavedBoardColumn(){
        const { showToastr, preferences, selectedBoard, setSelectedBoard } = this.props;
        const { id } = selectedBoard;
        const lastSavedBoard = get(preferences, `betaBoards.${id}`);
        const columnPreferences = get(preferences, `betaBoards.${id}.primary.system_board/showcolumns`);
        this.saveColumns(columnPreferences);
        await setSelectedBoard(lastSavedBoard);
        showToastr({ severity: 'success', detail: 'Saved columns applied successfully.' });
        this.buildBoardMenuItems();
        this.openMenu();
    }


    @bind
        async applyBoardColumn(){
            const { selectedBoard, setSelectedBoard } = this.props;
            const { checkedColumns } = this.state;
            this.saveColumns(checkedColumns);
            await setSelectedBoard({...selectedBoard, primary:{...selectedBoard.primary,['system_board/showcolumns']: checkedColumns }});
            this.props.showToastr({ severity: 'success', detail: 'Columns applied successfully.' });
            this.buildBoardMenuItems();
            this.openMenu();
        }

    @bind
    async saveBoardColumn(){
        const { updateEntity, selectedBoard, saveBoardPreferences, setSelectedBoard, preferences } = this.props;
        const { checkedColumns } = this.state;
        const board = await updateEntity({
            id: selectedBoard.id,
            type: selectedBoard.type,
            primary: {'system_board/showcolumns': checkedColumns}
        }, false, false, false);
        const { id } = board;
        this.setState({clickApply: false});
        await saveBoardPreferences({ ...preferences, betaBoards: {...preferences.betaBoards,  [id]: board }});
        await setSelectedBoard(board);
        this.buildBoardMenuItems();
        this.openMenu();
    }


    @bind
    headerActions(){
        return (
            <BetaBoardColumnAction
                onApply={this.applyBoardColumn}
                onSave={this.saveBoardColumn}
                onReset={this.resetBoardColumn}
                onLoadSaved={this.loadSavedBoardColumn}
            />
        );
    }

    @bind
    openMenu() {
        const { selectedBoard, openMenuSidebar } = this.props;
        openMenuSidebar({
            menuDefinitions: this.state.menuDefinitions,
            hideDotMenu: true,
            id: selectedBoard?.id,
            type: selectedBoard?.type,
            title: 'View Settings',
            reloadList: () => {}
        });
    }

    @bind
    parseColumnField(columnAttribute) {
        const { selectedBoard, primaryClasses } = this.props;
        const primaryEntityClass = primaryClasses.find(cls => cls.uri === selectedBoard?.primary['system_board/entitytype']?.uri);
        const { uri } = primaryEntityClass;
        let columnEntityType = null;
        let columnEntityPath = null;
        let  columnEntityMultiple = null;
        let  columnDefinitionId = null;
        let isReverseDefinition = false;
        let boardType = null;
        let columnEntity = null;

        const parseChildrens = (list) => list.forEach(field => {
            if(field.children) {
                parseChildrens(field.children);
               
            }
            if(field.properties.name === columnAttribute && field.type === 'typeahead' ){
                columnEntityMultiple = field?.properties?.multiple;
                boardType = 'typeahead';
            }

            if(field?.properties?.definition?.id === columnAttribute && field.type.includes('relationsTypeahead')) {
                const { definition } = field.properties;
                columnDefinitionId = definition.id;
                isReverseDefinition = !!definition?.reverse;
                columnEntityType = get(selectedBoard, 'primary.system_board/showcolumns.[0].type', null);
                boardType = 'relationsTypeahead';
            }
          
            if(field?.properties?.name === columnAttribute && field.type.includes('Typeahead') 
                && uri !== 'opentask' && uri !== 'openprocess') {
                const { valueField } = field.properties;
                if(!valueField) {
                    columnEntityType = get(selectedBoard, 'primary.system_board/showcolumns.[0].type', null);
                    columnEntityMultiple = field?.properties?.multiple;
                    columnEntityPath = `primary.${columnAttribute}`;
                    columnAttribute = columnEntityMultiple ? columnAttribute : `${columnAttribute}.id`;

                }
                switch(field.type){
                    case 'taskTypeahead':
                    case 'processTypeahead':
                    case 'userTypeahead':
                    case 'classificationTypeahead' :
                    case 'workspaceTypeahead':
                    case 'teamTypeahead':                   
                        boardType = field.type;
                        break;
                    case 'entityTypeahead':
                        boardType = field.type;
                        columnEntity = get(selectedBoard, 'primary.system_board/showcolumns', null);
                        break;

                }
            }
           

        });
        parseChildrens(primaryEntityClass?.formDefinition?.fields || []);
        return { 
            columnDefinitionId, columnField: columnAttribute, columnEntityType, 
            columnEntityPath, columnEntityMultiple, isReverseDefinition, boardType, columnEntity
        };
    }

    @bind
    async reloadColumns(filterBy, excludeBy, orderBy) {
        const { selectedBoard, loadBoardRecords, loadBoardRecordsAll } = this.props;
        if(selectedBoard && get(selectedBoard, 'primary.system_board/columnsetbyattribute', false)) {
            this.setState({ isLoading: true, });
            const { columnField, columnDefinitionId, columnEntityMultiple, boardType  } = this.parseColumnField(selectedBoard.primary['system_board/columnsetbyattribute']);
            const columnOr = { or:
                selectedBoard.primary['system_board/showcolumns'].map(column => {
                    const value = isUuid(column) ? column : column.id ?? column.value;
                    if(isDefined(column.isRelations) && !column.isChecked) {
                        return ({ field: `relations.relatedEntity.id`, op: '=', value: isDefined(value) ? String(value) : value });
                    }
                    return ({ field: `primary.${columnField}`, op: '=', value: isDefined(value) ? String(value) : value  });
                }).filter(Boolean)
            };         
            const filterByColumns = columnEntityMultiple ? 
                {or:
                selectedBoard.primary['system_board/showcolumns'].map(column => {
                    if(boardType === 'typeahead'){
                        return ({ field: `primary.${columnField}`, op: 'contains' , value:  String(column.value)});
                    }
                    return ({ field: `primary.${columnField}`, op: 'contains properties' , value:  [{id: column.id }]});
                }).filter(Boolean)
                }
                : columnOr;
            const filterByUpdated = [
                ...(filterBy || []),
              
            ].map((item) => {
                if(item.field === 'relations.relatedEntity.id') {
                    const relationGroups = groupBy(item.value, 'relatedEntityType');
                    return Object.keys(relationGroups).map(t => ({ ...item, value: relationGroups[t].map(v => v.id)}));
                }
                return item;
            }).flat(Infinity);

            if(columnDefinitionId && boardType !== 'relationsTypeahead') {
                filterByUpdated.push({ field: `relations.relation.relationDefinition.id`, op: '=', value: columnDefinitionId });
            }

            const entityType = selectedBoard.primary['system_board/entitytype'].uri;
            await loadBoardRecords({
                type: entityType,
                filterBy: [...filterByUpdated, (filterByColumns || [])],
                excludeBy,
                orderBy
            });
            await loadBoardRecordsAll({
                type: entityType,
                filterBy: filterByUpdated || [],
                excludeBy: [ (filterByColumns || []), ...(excludeBy || [])],
            });
            this.buildBoardMenuItems();
        };
    }

    @bind
    handleToggle(column) {
        const { checkedColumns: columns } = this.state;
        const checkedColumns = columns.map(clmn => {
            if((clmn.id ?? clmn.value) === column.id) {
                return { ...clmn, isChecked: !column.isChecked };
            }
            return clmn;
        });
        this.setState({ checkedColumns });
    }

    @bind
    handleChangeSwimlanes(groupBy) {
        const{ preferences, saveBoardPreferences } = this.props;
        const swimlane = groupBy.primaryText.toLowerCase();
        this.setState({ groupBy: swimlane !== 'none'? swimlane : null });
        saveBoardPreferences({...preferences, betaBoards: {...preferences.betaBoards, groupBy: swimlane }});
    }

    @bind
    @memoize()
    buildBreadcrumbs(name, selectedBoard) {
        return [{ title: 'Board' }, ( name || selectedBoard ) && { title: name ? name : selectedBoard?.name }  ].filter(Boolean);
    }

    @bind
    buildBoardMenuItems() {
        const { selectedBoard } = this.props;
        const columns = selectedBoard?.primary['system_board/showcolumns'] ?? [];
        const boardColumns = sortBy(columns.map((clmn, index) => {
            return {
                order: clmn.order ?? index,
                id: clmn.id ?? clmn.value,
                primaryText: clmn.name ?? clmn.text ?? clmn.label,
                type: 'checkbox',
                isChecked: isDefined(clmn.isChecked) ? clmn.isChecked : true,
                name: 'columns',
                onClick: this.handleToggle
            };
        }), ['order']);   
        this.setState({
            menuDefinitions:{
                menu:[ 
                    {
                        actions: this.headerActions(),
                        primaryText: 'Show & hide columns',
                        iconName: 'eye',
                        menu: boardColumns,
                    },
                ] 
            },
            checkedColumns: get(selectedBoard, 'primary.system_board/showcolumns')
        });
        setTimeout(() => {
            this.setState({isLoading: false});
        }, 500);
    }

    @bind
    onCloseModal() {
        this.setState({ isModalOpen: false });
    }

    @bind
    onOpenModal() {
        this.setState({ isModalOpen: true });
    }

    @bind
    openSidebar(data, title: string, filterBy, excludeBy, orderBy) {
        const { taskId, type } = data;
        return taskId && openSidebarByParams({ 
            ...this.props,
            type,
            title, 
            id: taskId, 
            internal: true,
            reloadList: () => this.reloadColumns(filterBy, excludeBy, orderBy)}
        );
    };

    @bind
    async navigateToBoards(){
        const { setSelectedBoard, cleanBoardColumns } = this.props;
        await setSelectedBoard(null);
        await cleanBoardColumns([]);
        this.props.history.push(`/abox/kanbanboards`);
    }
    @bind
    @memoize()
    getPrimaryEntityClass(selectedBoard, primaryClasses){     
        const primaryEntityClass =  primaryClasses.find(cls => cls.uri === selectedBoard?.primary?.['system_board/entitytype']?.uri); 
        return primaryEntityClass;
    } 

    @bind
    openChatSidebar(e, title){
        e.stopPropagation();
        const { selectedBoard, openEntitySidebar } = this.props;
        const { id, type } = selectedBoard;
        const params = { id, type, title };
        openEntitySidebar(params);
    }

    @bind
    @memoize()
    async loadBoardPermissions(selectedBoard) {
        const { id, type } = selectedBoard || {};
        if(id && type){
            const { role } = await addRoleToData({ id, type });
            const permissions = getPermissions(role);
            this.setState({ permissions });
        }
    }

    render() {
        const { selectedBoard, preferences, boardsLeftPanel, primaryClass, primaryClasses, profile
        } = this.props;
        const { isModalOpen, groupBy, isLoading, permissions } = this.state;
        const isColumnsLength = !!get(selectedBoard, 'primary.system_board/showcolumns.length', 0);
        const userDefinedFilters = this.getUserDefinedFilters(selectedBoard);
        const primaryEntityClass =  this.getPrimaryEntityClass(selectedBoard, primaryClasses);   
        const { canComment, canEdit } = permissions;
        const {isAdmin, permissions: profilePermissions } = profile;
        const canAdd = isAdmin || profilePermissions.includes('entity.entity.add');
        return (
            <>
                <FiltersBoard
                    id={`${VIEW_ID}.${selectedBoard?.id}`}
                    key={selectedBoard?.id}
                    filterDefinitions={this.filterDefinitions(selectedBoard)}
                    searchBar={this.searchBar}
                    defaultFilters={this.defaultFilters}
                    defaultOrder={this.defaultOrder}
                    userDefinedFilters={userDefinedFilters}
                    breadcrumb={this.buildBreadcrumbs(get(preferences, `betaBoards.${selectedBoard?.id}.name`), selectedBoard )}
                    rightToolbar={
                        <>
                            <StyledButton variant='text' color='secondary' onClick={this.navigateToBoards}>
                                Boards
                            </StyledButton>
                            {canAdd && 
                            (<Tooltip title="Add new board">
                                <IconButton onClick={this.onOpenModal}>
                                    <MdiIcon name='plus' color={muiTheme.colors.text.secondary}/>
                                </IconButton>
                            </Tooltip>)}
                            <Tooltip title="Options">
                                <IconButton onClick={this.openMenu}>
                                    <MdiIcon name='tune' color={muiTheme.colors.text.secondary}/>
                                </IconButton>
                            </Tooltip>
                            { (canComment || canEdit) && 
                            (<Tooltip title="A-Live">
                                <IconButton 
                                    onClick={e=> this.openChatSidebar(e, 'A-Live') }
                                >
                                    <Badge color="error" >
                                        <Icon name="messenger" type="af" />
                                    </Badge>
                                </IconButton>
                            </Tooltip>) 
                            }

                        </>
                    }>
                    {(filterBy, orderBy, excludeBy) => {
                        return(
                            <PageTemplate title="Boards (Beta)">
                                {(!selectedBoard || selectedBoard?.type !== 'system_board' || !primaryEntityClass) &&
                                    <ContentAreaStyled heightOffset={5}>
                                        <BoardsLandingSection>
                                            <div style={{textAlign: 'center'}}>
                                                <NoSelectedBoard>
                                                    <IconStyled type={'af'} name={'Kanban'} />
                                                    {`Let's Get Started`}<br/>
                                                    {((!boardsLeftPanel?.length || !selectedBoard || !primaryEntityClass) && canAdd) && 
                                                    <StyledButton variant='text' color='secondary' onClick={this.onOpenModal}>
                                                        Create New
                                                    </StyledButton>}
                                                </NoSelectedBoard>
                                            </div>
                                        </BoardsLandingSection>
                                    </ContentAreaStyled>
                                }
                                {selectedBoard && !isColumnsLength && selectedBoard.type === 'system_board' &&
                                   <ContentAreaStyled heightOffset={5}>
                                       <BoardsLandingSection>
                                           <div style={{textAlign: 'center'}}>
                                               <NoSelectedBoard>
                                                   <IconStyled  type={'af'} name={'Kanban'} />
                                                   {`Let's Get Started`}
                                                   <br/>
                                                   {
                                                       <StyledButton variant='text' color='secondary' onClick={this.openMenu}> Add columns on the board </StyledButton>
                                                   }
                                               </NoSelectedBoard>
                                           </div>
                                       </BoardsLandingSection>
                                   </ContentAreaStyled>
                                }
                                {selectedBoard && isColumnsLength && primaryEntityClass &&
                                    <StyledContentArea heightOffset={5}>
                                        <BetaBoardView
                                            id={VIEW_ID}
                                            isLoading={isLoading}
                                            selectedBoard={selectedBoard}
                                            reloadColumns={this.reloadColumns}
                                            parseColumnField={this.parseColumnField}
                                            filterBy={filterBy}
                                            orderBy={orderBy}
                                            excludeBy={excludeBy}
                                            groupBy={get(preferences, 'betaBoards.groupBy') ?? groupBy}
                                            openSidebar={this.openSidebar}
                                        />
                                    </StyledContentArea>
                                }
                            </PageTemplate>
                        );
                    }}
                </FiltersBoard>
                {isModalOpen && <AddNewBoardModal onCloseModal={this.onCloseModal} primaryClass={primaryClass} preferences={this.props.preferences} />}
            </>
        );
    }
}
export default connect(((state) => {
    const selectedBoard = state.abox.boards.boardsOption.selectedBoard;
    return {
        selectedBoard: state.abox.boards.boardsOption.selectedBoard,
        boardsLeftPanel: state.leftPanel.betaBoards?.data?.records || [],
        preferences: state.user.preferences,
        profile: state.user.profile,
        isSidebarOpen: state.sidebar.isOpen,
        primaryClass: state.abox.boards.primaryClass.data,
        primaryClasses: state.app.allPrimaryClasses.records,
        filterIsOpen: state.filterbar.isOpen,
        viewState: get(state, `component.state.BetaTaskBoards.${selectedBoard?.id}`) || {},
    };
}), {
    loadBoardRecords,
    closeEntitySidebar,
    setSelectedBoard,
    saveBoardPreferences,
    openMenuSidebar,
    loadBoardPrimaryClass,
    updateEntity,
    loadBoardRecordsAll,
    openEntitySidebar,
    openClassSidebar,
    openTaskSidebar,
    openProcessSidebar,
    openTeamSidebar,
    openWorkspaceSidebar,
    openEventTypeSidebar,
    openEventSidebar,
    openEventsActionsSidebar,
    closeFilterbar,
    closeMenuSidebar,
    saveComponentState,
    showToastr,
    loadEntity,
    setDocumentTitle,
    cleanBoardColumns
},
)(withRouter(withPermissions(BetaTaskBoards, 'task')));
