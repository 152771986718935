import React from 'react';

import {
    buildLeftPanel as buildLeftPanelAction,
    closeLeftPanel as closeLeftPanelAction
} from 'store/actions/leftPanel/leftPanelActions';
import RelatedEntityLeftPanel from 'app/containers/LeftPanels/RelatedEntityLeftPanel';

export const openRelatedEntityPanel = ({ title, id, type, view }) => {
    return buildLeftPanelAction({
        title,
        view,
        content: (
            <RelatedEntityLeftPanel
                title={title}
                id={id}
                view={view}
                type={type}
            />
        ),
        isOpen: true
    });
};

export const closeEntityLeftPanel = closeLeftPanelAction;
