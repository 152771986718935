import { get } from 'app/utils/lo/lo';
import WMSCapabilities from 'ol/format/WMSCapabilities';
import { transformExtent } from 'ol/proj';
import WKT from 'ol/format/WKT';
import { getAttachmentUrl } from 'app/utils/attachments/attachmentsUtils';
import { loadEntityDetails } from 'store/actions/maps/situationalAwarenessActions';
import cesiumLayerConfig from 'app/config/cesiumLayerConfig';
import { isDefined } from 'app/utils/utils';
import { getArray } from 'app/utils/utils';
import genUuidv4 from 'uuid/v4';


const parser = new WMSCapabilities();

export const MAP_BASELAYER = 'system_map/base_layer';
export const BASELAYER_PRIMARY_KEY = `primary.${MAP_BASELAYER}`;
export const MAP_LAYERS = 'system_map/layers';
export const LAYERS_PRIMARY_KEY = `primary.${MAP_LAYERS}`;

export const removeExtentThumbnail = (mapData) => { // Because extent and thumnail immediately gets saved when we click on button unlink other data which gets saved only when you click on save button
    if(!mapData) return null;
    const { primary } = mapData;
    return {
        ...mapData,
        primary: {
            [MAP_BASELAYER]: primary[MAP_BASELAYER],
            [MAP_LAYERS]: primary[MAP_LAYERS],
        },
    };
};

export const __getMapLayers = (mapData) => getArray(mapData, LAYERS_PRIMARY_KEY, []);

export const __findMapLayer = (mapData, layer) => {
    if(!mapData || !layer) return null;
    const allMapLayers =__getMapLayers(mapData);
    if(!allMapLayers?.length) return null;
    const { name, id, title } = layer;
    const attributes = [name, title, id].filter(Boolean);
    return allMapLayers.find(lyr =>  attributes.includes(lyr.name) || attributes.includes(lyr.title) || attributes.includes(lyr.id));
};

export const __updateMapLayer = (mapData, layer) => {
    if (!layer?.name || !mapData) {
        return null;
    }
    const { name, id } = layer;
    const layers = __getMapLayers(mapData);
    if(!layers?.length) return null;
    const updatedLayers = layers.map(lyr => {
        if (lyr.name === name || lyr.title === name || lyr.id === id) {
            return __extractRelevantLayerInfo({ ...lyr, ...layer });
        }
        return lyr;
    }).filter(Boolean);
    return updatedLayers;
};


export const __addNewLayer = (layer, mapData, normalize = false) => {
    if(!layer || !mapData) return null;
    return [...__getMapLayers(mapData), normalize ? __buildNewLayerData(layer): layer];
};

const _getClassReference = (entityType) => {
    if (!entityType) {
        return {};
    }
    const { id, uri, name, abstract, active, color } = entityType;
    return { id, uri, name, abstract, active, color };
};

export const __buildNewLayerData = (layer) => {
    const id = genUuidv4(); // WIP: we need to remove it and just compare layers by using layer name as we now have unique layer names
    const reference = {
        id,
        type: layer?.uri,
        name: layer?.data?.name,
    };
    const twoDLayers = {
        ...reference,
        visible: true,
        opacity: 1,
    };
    const { time_range } = layer?.data || {};

    switch (layer?.uri) {
        case LAYERS.drawing:
            return twoDLayers;
        case LAYERS.wms:
            const { layer: layerName, layer_url, style } = layer?.data || {};
            return { ...twoDLayers, layer: layerName, layer_url, style };
        case LAYERS.entity:
            return {
                ...twoDLayers,
                entity_type: _getClassReference(layer?.data?.entitytype),
                filter_by: [],
                heatmap: {},
                related_entities: {},
                styles: { pinStyle: 'cluster' },
                counter: {},
                progress: {},
            };
        case LAYERS.event:
            const { event_type } = layer?.data || {};
            return {
                ...twoDLayers,
                event_type,
                time_range,
                filter_by: [],
                styles: { pinStyle: 'cluster' },
            };
        case LAYERS.cesium:
            const { account, asset } = layer?.data || {};
            return { ...reference, account, asset, visible: true };
        case LAYERS.replay:
            return { ...twoDLayers, time_range };
        default:
            return {};
    }
};

export const __extractRelevantLayerInfo = (data) => {
    if(!data) return {};
    const commonKeys = ['id', 'name','type'];
    const keysByType = {
        [LAYERS.cesium]: [...commonKeys, 'account', 'visible', 'asset', 'description'],
        [LAYERS.drawing]: [...commonKeys, 'thumbnail', 'drawing_feature', 'opacity', 'visible', 'description'],
        [LAYERS.entity]: [...commonKeys, 'entity_type', 'filter_by', 'related_entities', 'opacity', 'visible', 'counter', 'progress', 'heatmap', 'styles', 'description'],
        [LAYERS.event]: [...commonKeys, 'event_type', 'entity_type', 'description', 'time_range', 'filter_by', 'opacity', 'visible', 'styles', 'heatmap'],
        [LAYERS.wms]: [...commonKeys, 'layer', 'layer_url', 'style', 'opacity', 'visible', 'description'],
        [LAYERS.replay]: [...commonKeys, 'time_range', 'entitytype', 'relatedEntities', 'opacity', 'visible', 'description'], // Add relevant keys for Replay Layer
    };
    const { type } = data;
    if (!keysByType[type]) {
        console.error('No key exists againt layer type.'); // eslint-disable-line
        return {};
    }
    return keysByType[type].reduce((acc, key) => {
        if (data.hasOwnProperty(key)) {
            acc[key] = data[key];
        }
        return acc;
    }, {});
};

export const BASELAYERS_OPTIONS = [
    { value: 'Bing (Aerial)', label: 'Bing (Aerial)' },
    { value: 'Bing (Roads)', label: 'Bing (Roads)' },
    { value: 'OSM', label: 'OSM' }
];

export const LAYERS = {
    wms: 'wms',
    drawing: 'drawing',
    entity: 'entity',
    event: 'event',
    cesium: cesiumLayerConfig?.cesiumLayer?.type,
    replay: 'replay'
};

export const ENTITYLAYERS = ['system_task', 'opentask', 'closedtask', 'system_process', 'openprocess','closedprocess', 'system_event'];

export const TASKLAYERS = ['opentask', 'closedtask', 'system_task'];

export const PROCESSLAYERS = ['system_process', 'openprocess','closedprocess'];


const normalizeAttributes = (classes: Array<Object>, attributes: any): Object => {
    const allAttributes = (classes || []).reduce((data, { formDefinition }) => {
        get(formDefinition, 'fields', []).forEach(({ properties: { name, defaultValue } }) => {
            const value = data[name] || defaultValue;
            if (value) {
                data[name] = value;
            }
        });
        return data;
    }, { ...(attributes || {}) });

    return Object.entries(allAttributes || {}).reduce((data, [key, value]) => {
        data[key.replace(/\S+\//g, '')] = value;
        return data;
    }, {});
};

export const getLayerSideBarType = (title, type) => {
    switch (title) {
        case 'Drawing Layer':
            return 'drawing-layer';
        case 'Entity Layer':
            return 'entity-layer';
        case 'Event Layer':
            return 'system_eventlayer';
        case 'WMS Layer':
            return 'wms';
        case 'replay':
        case 'Replay Layer': 
            return 'replay-laye';
        case 'Cesium Ion Layer':
            return type === 'cesium-layer' ? cesiumLayerConfig?.cesiumLayer?.oldType : cesiumLayerConfig?.cesiumLayer?.type;
        default:
            return null;
    }
};
const cesiumType = cesiumLayerConfig?.cesiumLayer?.type || cesiumLayerConfig?.cesiumLayer?.oldType;
const layerTypes = [
    {
        name: 'Entity Layer',
        icon: 'things',
        type: 'af',
        uri: 'entity',
    },
    {
        name: 'Event Layer',
        icon: 'event-monitor',
        type: 'af',
        uri: 'event',
    },
    {
        name: 'Drawing Layer',
        icon: 'gesture',
        uri: 'drawing'
    },
    {
        name: 'WMS Layer',
        icon: 'layers',
        uri: 'wms'
    },
    {
        name: 'Cesium Ion Layer',
        icon: 'globe',
        type: 'af',
        uri: cesiumType
    },
    {
        name: 'Replay Layer', 
        icon: 'play',
        uri: 'replay'
    }
];

const getLayersAndStyles = (xml) => {
    const json = parser.read(xml);
    const allWMSLayers = [];

    const removeDuplicatesByLabel = (array) => {
        const map = new Map();
        array.forEach((item) => {
            if (!map.has(item.label)) {
                map.set(item.label, item);
            }
        });
        return Array.from(map.values());
    };

    const parseLayers = (layers, ancestors = []) => {
        layers.forEach(({ Name, Title, BoundingBox, Style, Layer }) => {
            let extent = [];
            const styles = [];
            (Style || []).forEach(({ Name, Title }) => {
                if (Name && Title) {
                    styles.push({ value: Name, label: Title });
                }
            });
            if (BoundingBox) {
                BoundingBox.forEach((box) => {
                    const { extent: boxExtent, crs } = box || {};
                    if (crs === 'CRS:84') {
                        extent = transformExtent(boxExtent, 'EPSG:4326', 'EPSG:3857');
                    }
                });
            }
            if (Name && styles.length > 0) {
                allWMSLayers.push({ value: Name, label: Title, extent, styles });

                ancestors.forEach(({ Name: ancestorName, Title: ancestorTitle, BoundingBox: ancestorBoundingBox, styles: ancestorStyles }) => {
                    if (ancestorName) {
                        let ancestorExtent = [];
                        if (ancestorBoundingBox) {
                            ancestorBoundingBox.forEach((box) => {
                                const { extent: boxExtent, crs } = box || {};
                                if (crs === 'CRS:84') {
                                    ancestorExtent = transformExtent(boxExtent, 'EPSG:4326', 'EPSG:3857');
                                }
                            });
                        }
                        allWMSLayers.push({
                            value: ancestorName,
                            label: ancestorTitle,
                            extent: ancestorExtent,
                            styles: ancestorStyles,
                        });
                    }
                });
            }

            if (Layer) {
                parseLayers(Layer, [...ancestors, { Name, Title, BoundingBox, styles }]);
            }
        });
    };

    const rootLayers = get(json, 'Capability.Layer.Layer', []);
    parseLayers(rootLayers);
    return { allWMSLayers: removeDuplicatesByLabel(allWMSLayers) };
};

const getPrimaryClass = (classes: Object, entityTypes: Array) => {
    return classes.find(obj => obj.uri === entityTypes.find(e => e === obj.uri));
};

const getPinStylingClass = (classes: Object) => {
    return classes.find(cls => cls.parents?.find(({ uri }) => uri === 'layer-styling'));
};

const getWMSLayer = (data: Object) => {
    const allWMSLayers = get(data, 'allWMSLayers', []);
    const layer = get(data, 'layer');
    return allWMSLayers.find(lyr => lyr.value === layer) || {};
};

const readAsDataURL = (file: File) => {
    return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onerror = reject;
        fr.onload = function() {
            if(fr?.result?.includes(['data:image/svg+xml;base64']))
                resolve(atob(fr.result.replace(/data:image\/svg\+xml;base64,/, '')));
            else
                resolve('Invalid SVG document.');
        };
        fr.readAsDataURL(file);
    });
};

export const isValidGltf = (file: File, modelExt: String) => {
    if(modelExt === 'gltf') {
        return new Promise((resolve, reject) => {
            const fr = new FileReader();
            fr.onerror = reject;
            fr.onload = function() {
                const base64Data = fr?.result.split(',')[1]; 
                const binaryData = atob(base64Data);

                const uint8Array = new Uint8Array(binaryData.length);
                for (let i = 0; i < binaryData.length; i++) {
                    uint8Array[i] = binaryData.charCodeAt(i);
                }

                const jsonString = new TextDecoder().decode(uint8Array); 
                let jsonObj;
                try {
                    jsonObj = JSON.parse(jsonString); 
                } catch (error) {
                // eslint-disable-next-line no-console
                    console.error('Error parsing JSON:', error);
                    return;
                }

                const uri = jsonObj?.buffers?.[0]?.uri;
                if (uri?.includes('.bin')) {
                    resolve({ severity: 'error', detail:`We only support GLB format`});
                }
                else
                    resolve('The gltf model is fine.');
            };
            fr.readAsDataURL(file);
        });
    }
};

export const encodeSvgTobase64 = (svg) => {
    if (!svg) return '';
    const prefix = 'data:image/svg+xml;base64,';
    try {
        if (!isLatin1(svg)) {          
            return `${prefix}${btoa(unescape(encodeURIComponent(svg)))}`;
        }
        return `${prefix}${btoa(svg)}`;
    } catch (error) {}
    return '';
};

export const getSvgSource = ({ svg, svgGraphic }) => {
    if(!svg && !svgGraphic) return '';
    if (svg) {
        return encodeSvgTobase64(svg);
    }
    if (svgGraphic?.id) {
        return getAttachmentUrl(svgGraphic.id, 'graphic', svgGraphic.image);
    }
    return '';
};

// to check if svg is uft8  or contains characters outside of the Latin-1/ISO-8859-1 range,
export const isLatin1 = (str) => {
    for (let i = 0; i < str.length; i++) {
        if (str.charCodeAt(i) > 255) {
            return false;
        }
    }
    return true;
};

export const getSvgPriority = (data: Object, primaryClass: Object) => {
    const isEntitySvg = !!data?.svg || !! data?.svgGraphic;
    if(isEntitySvg) {
        return { svg: data?.svg, svgGraphic: data?.svgGraphic };
    }
    return { svg: primaryClass?.entitySvg, svgGraphic: primaryClass?.entityGraphic };
};


export const getOptions = (fields: array, attributeType: string)  => {
    if(fields) {
        if(!attributeType || !fields.length)
            return [];
        const optionsArr = fields.map((item) => {
            // eslint-disable-next-line array-callback-return
            return item?.children?.filter(({type}) => {
                if(type === attributeType) {
                    return item.children.map(({properties: {name, label, defaultValue }}) => ({label, value: name, defaultValue}));
                }
            });
        });
        return optionsArr.flat().filter(({type}) => type === attributeType)
            .map(({properties: {name, label}}) => ({label, value: name}));
    }
};

export const shadeColor = (color, percent) => {

    let R = parseInt(color.substring(1,3),16);
    let G = parseInt(color.substring(3,5),16);
    let B = parseInt(color.substring(5,7),16);

    R = parseInt(R * (100 + percent) / 100);
    G = parseInt(G * (100 + percent) / 100);
    B = parseInt(B * (100 + percent) / 100);

    R = (R<255)?R:255;
    G = (G<255)?G:255;
    B = (B<255)?B:255;

    const RR = ((R.toString(16).length===1) ? '0'+R.toString(16) : R.toString(16));
    const GG = ((G.toString(16).length===1) ? '0'+G.toString(16) : G.toString(16));
    const BB = ((B.toString(16).length===1) ? '0'+B.toString(16) : B.toString(16));

    return '#'+RR+GG+BB;
};

export const getEntityAssets = async (value: Object, map: Object) => {
    const result = await loadEntityDetails(value?.id, value?.type);
    const token = cesiumLayerConfig?.cesiumAccount?.getCesiumToken(result) || cesiumLayerConfig?.cesiumAccount?.getOldCesiumToken(result);
    const bearerToken = `Bearer ${token}`;
    if(!token)
        return;
    const assets = await map.getAssets(bearerToken);
    const options = assets.length && assets.map((item) => {
        return { label: item.name, value: `${item.id},${item.type}` };
    });
    if(options?.length) {
        return options;
    }
};

export const isModelUploaded = (data: Object, primaryClass: Object) => {
    return !!(data?.model3d || primaryClass?.entityModel3d);
};

export const clickFile = (file, name) => {
    const a = document.createElement('a');
    a.href = URL.createObjectURL(file);
    a.download = name;
    a.click();
};

export const getNumericAttributes = (fields: array, withConstraints)  => {
    if(!fields.length) return [];
    let filteredArr = [];
    try {
        if(withConstraints) {
            fields.forEach((item) => {
                const numFields = item.children.filter(obj => {
                    const hasMax = isDefined(obj.constraints?.max);
                    const hasMin = isDefined(obj.constraints?.min);
                    const isNumberType = obj.type === 'number';
                    return isNumberType && (hasMax && hasMin);
                });
                numFields?.length && filteredArr.push(...numFields);
            });
        }
        else {
            filteredArr = fields.flatMap((item) => item.children).filter((child) => child.type === 'number');
        }
        return filteredArr.map(({ properties: { name, label, defaultValue }}) => ({ label, value: name, defaultValue }));
    } catch (error) {
        return filteredArr;
    }
};

export const imgToBlob = (imageSrc)  => {
    if(!imageSrc) return;
    const decodedData = atob(imageSrc.substring(imageSrc?.indexOf(',') + 1));
    return decodedData && new Blob([decodedData], { type: 'application/octet-stream' });
};

const modifyGeoserverUrl = (serverUrl) => {
    let url = serverUrl?.trim();
    if (
        url &&
        url.match(/((http|https):\/\/)(www.){0,1}[a-zA-Z0-9@:%._/+~#?&/=]{2,256}(\.[a-z]{2,6}\b){0,1}([-a-zA-Z0-9@:%._\\+~#?&/=]*)/)
    ) {
        const param = 'request=getcapabilities';
        if (!url?.includes(param)) {
            if (url?.includes('?')) {
                url = `${url}&${param}`;
            } else {
                url = `${url}?${param}`;
            }
        }
    }
    return url;
};

export const create3DIconCanvas = (text: string, textStyle: object = {}) => {
    const { fontSize, fillColor, strokeColor, strokeWidth } = textStyle;
    const c = document.createElement('canvas');
    const ctx = c.getContext('2d');
    const canvasSize = 40;
    c.width = canvasSize;
    c.height = canvasSize;
    const center = [c.width / 2, c.height / 2];
    const radius = canvasSize / 2;

    ctx.beginPath();
    ctx.arc(center[0], center[1], radius, 0, 2 * Math.PI, true);
    ctx.fillStyle = fillColor;
    ctx.fill();
    ctx.lineWidth = 2;
    ctx.strokeStyle = '#ffffff';
    ctx.stroke();
    // ctx.font = `normal normal normal ${fontSize ? fontSize : canvasSize / 2}px/4 "${fontFamily}"`;
    ctx.font = `normal 24px affectli, "Material Design Icons"`;
    ctx.fillStyle = '#ffffff';
    // ctx.textBaseline = 'top';
    ctx.textAlign = 'center';
    ctx.fillText(text, center[0], center[1] + fontSize / 2);
    if (strokeWidth) ctx.lineWidth = strokeWidth;
    if (strokeColor) ctx.strokeStyle = '#ffffff';
    ctx.strokeText(text, center[0], center[1] + fontSize / 2);
    return c.toDataURL();
};

export const headScaleDefinition = (disabled) => {
    return [

        {
            type: 'number',
            properties: {
                label: 'Heading',
                name: 'entityModelHeading',
                disabled,
            },
            constraints: { min: 0, max: 360 }
        },
        {
            type: 'number',
            properties: {
                label: 'Model Scale',
                name: 'entityModelScaling',
                disabled,
            },
            constraints: { min: 0, max: 100 }
        }
    ];
};

export const getMapLayer = (mapData, layerId) => {
    const layers = mapData.primary[MAP_LAYERS];
    if(!layers?.length) return null;
    const layer = layers?.find(layer => layer.id === layerId || layer.type === layerId || (layer.entity_type && layer.entity_type?.id === layerId));
    return layer || null; 
};

export const getLayerType = (layer) => {
    if (!layer) return null;
    if (layer?.eventtype || layer?.event_type || layer?.uri === 'event' || layer?.type === 'event') {
        return 'event_type';
    }
    if (layer?.uri === 'system_cesium_layer' || layer?.type === 'system_cesium_layer') {
        return 'system_cesium_layer';
    }
    if (['drawing', 'wms'].includes(layer?.type) || ['drawing', 'wms'].includes(layer?.uri)) {
        return 'type';
    }
    if (layer?.entitytype || layer?.entity_type || layer?.uri === 'entity' || layer?.type === 'entity') {
        return 'entity_type';
    }
    if (layer?.type === 'replay-layer') {
        return 'replay-layer';
    }
    return null;
};

export const getMapLayerByName = (mapData, name) => {
    if(!mapData || !name) return null;
    const layers = __getMapLayers(mapData);
    if(!layers?.length) return null;
    return layers?.find(layer => layer.name === name);
};

export const getLayerNameField = (mapData) => {
    return {
        field: 'name',
        type: 'text',
        properties: { label: 'Layer name', name: 'name' },
        constraints: {
            required: true,
            minLength: 3,
            maxLength: 80,
            custom: (data) => {
                try {
                    const name = data?.name?.trim()?.toLowerCase();
                    const mapLayers = __getMapLayers(mapData)
                        .map((lyr) => lyr?.name?.trim()?.toLowerCase())
                        .filter(Boolean);
                    if (name && mapLayers?.includes(name)) {
                        return {
                            format: {
                                pattern: /somefaketest/,
                                message: 'This name is already used. Please choose a different layer name.',
                            },
                        };
                    }
                } catch (error) {}
                return {};
            },
        },
    };
};

export const getFeatureFromWKT = (featureWkt: Object, dataProjection = 'EPSG:4326', featureProjection = 'EPSG:3857') => {
    if (!featureWkt) {
        return null;
    }
    let cleanWkt = featureWkt; 
    if(featureWkt.includes('SRID')){
        cleanWkt = cleanWkt.split(';')[1]?.trim();
        cleanWkt = cleanWkt.replace(
            /POINT\(([-+]?\d*\.?\d+)\s([-+]?\d*\.?\d+)\s[-+]?\d*\.?\d+\)/,
            'POINT($1 $2)'
        );
    }
    const format = new WKT();
    return format.readFeature(cleanWkt, {
        dataProjection,
        featureProjection
    });
};


export {
    normalizeAttributes,
    layerTypes,
    getLayersAndStyles,
    getPrimaryClass,
    getPinStylingClass,
    getWMSLayer,
    readAsDataURL,
    modifyGeoserverUrl
};
