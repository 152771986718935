/* @flow */

import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import EntitiesListView from 'app/containers/Entities/EntitiesList/EntitiesListView';
import EntitiesDetail from 'app/containers/Entities/EntityAbout/EntitiesDetail';
import { RouterMatchPropTypeBuilder } from 'app/utils/propTypes/common';
import PageNotAllowed from 'app/containers/ErrorPages/PageNotAllowed';
import AddEntity from 'app/containers/Entities/EntitiesList/AddEntity'; // WIP: Testing add entity
import history from 'store/History';

/**
 * Defines the routes for the Entities views
 */
class EntitiesRoute extends PureComponent<Object, Object> {
    static propTypes = {
        match: RouterMatchPropTypeBuilder({ id: PropTypes.string }),
        userProfile: PropTypes.object
    };

    /**
     * @override
     */
    render() {
        const { match } = this.props;
        const { permissions, isAdmin } = this.props.userProfile;
        const permissionsSet = new Set(permissions || []);
        const canView = isAdmin || permissionsSet.has('entity.entity');
        if (!canView) {
            return <PageNotAllowed title="Entities" />;
        }
        return (
            <Fragment>
                <Switch>
                    <Route path={`${match.url}/`} exact component={EntitiesListView} />
                    <Route path={`${match.url}/add/new`} exact render={props => <AddEntity {...props} onClose={() => {history.goBack();}} />} />,
                    <Route path={`${match.url}/:type/`} exact component={EntitiesListView} />
                    <Route path={`${match.url}/:type/classification/:classId`} exact component={EntitiesListView} />
                    <Route path={`${match.url}/:type/:id`} component={EntitiesDetail} />,
                </Switch>
            </Fragment>
        );
    }
}

export default connect((state: Object): Object => ({
    userProfile: state.user.profile
}))(EntitiesRoute);
