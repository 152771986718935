/* @flow */

// $FlowFixMe
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Avatar } from '@mic3/platform-ui';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
    loadEntityGraphicLibrary,
    deleteEntityGraphicLibrary,
    uploadEntityGraphicLibrary,
    updateEntityGraphicLibrary
} from 'store/actions/common/graphicLibraryActions';
import { formatBytes } from 'app/utils/attachments/attachmentsUtils';
import ListItem from 'app/components/molecules/List/ListItem';
import { setDocumentTitle, showToastr } from 'store/actions/app/appActions';
import Icon from 'app/components/atoms/Icon/Icon';
import ResponsiveActions from 'app/components/molecules/ResponsiveActions/ResponsiveActions';
import { bind } from 'app/utils/decorators/decoratorUtils';
import PageTemplate from 'app/components/templates/PageTemplate';
import EntitiesList from 'app/containers/Entities/EntitiesList/EntitiesList';
import { cut } from 'app/utils/string/string-utils';
import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import { openGraphicSidebar } from 'store/actions/entities/GraphicSidebarActions';
import { getAttachmentUrl } from 'app/utils/attachments/attachmentsUtils';
import PageNotAllowed from 'app/containers/ErrorPages/PageNotAllowed';
import ResizableListItem from 'app/components/molecules/VirtualList/ResizableListItem';
import { modulesAndPageTitles } from 'app/config/typesConfig';

const VIEW_ID = 'GraphicsListView';

const ListItemStyled = styled(ListItem)`
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    cursor: pointer;
`;

const Link = styled.a`
    text-decoration: none;
`;

/**
 * Renders the view to display the entity attachments.
 */
class EntityGraphicsLibrary extends PureComponent<Object, Object> {
    static propTypes = {
        isLoading: PropTypes.bool,
        records: PropTypes.array,
        startIndex: PropTypes.number,
        totalRecords: PropTypes.number,
        loadEntityGraphicLibrary: PropTypes.func.isRequired,
        deleteEntityGraphicLibrary: PropTypes.func.isRequired,
        uploadEntityGraphicLibrary: PropTypes.func.isRequired,
        updateEntityGraphicLibrary: PropTypes.func.isRequired,
        showToastr: PropTypes.func.isRequired
    };

    canEdit: boolean = false;
    graphicListRef = React.createRef();

    filterDefinitions = [
        {
            field: 'id',
            type: 'uuid',
            properties: {
                label: 'ID',
                name: 'id',
                opSelector: true
            },
            condition: '='
        },
        {
            field: 'name',
            type: 'text',
            properties: {
                label: 'Name',
                name: 'name',
                opSelector: true
            }
        },
        {
            field: 'modifiedDate',
            type: 'dateTimeRange',
            properties: {
                label: 'Last Updated',
                name: 'modifiedDate',
            },
        },
        {
            field: 'modifiedBy.id',
            type: 'userTypeahead',
            properties: {
                label: 'Modified By',
                name: 'modifiedById',
                multiple: true,
                valueField: 'id',
            },
            condition: 'in'
        },
        {
            field: 'createdBy.id',
            type: 'userTypeahead',
            properties: {
                label: 'Created By',
                name: 'createdById',
                multiple: true,
                valueField: 'id',
            },
            condition: 'in'
        },
        {
            field: 'createdDate',
            type: 'dateTimeRange',
            properties: {
                label: 'Created Date',
                name: 'createdDate',
            },
        },
    ];

    columnDefinitions = [
        {
            header: 'Graphic Name',
            field: 'name'
        },
        {
            header: 'Description',
            field: 'description',
            style: { minWidth: '220px' },
            format: ({ value }) => cut(String(value || ''), 25)
        }
    ];

    constructor(props: Object) {
        super(props);
        const {
            userProfile: { permissions, isAdmin }
        } = props;
        const permissionsSet = new Set(permissions || []);
        this.canView = isAdmin || permissionsSet.has('entity.graphic');
        this.canEdit = isAdmin || permissionsSet.has('entity.graphic.add');
    }

    componentDidMount() {
        this.props.setDocumentTitle(modulesAndPageTitles.entities.graphics);
    }

    @bind
    reloadList() {
        const { current } = this.graphicListRef || {};
        current && current.resetView();
    }

    @bind
    removeAttachment({ id, name }: Object) {
        return (event: Object) => {
            event.stopPropagation();
            this.props.deleteEntityGraphicLibrary({ id, name, type: 'graphic' }).then(this.reloadList);
        };
    }

    @bind
    onDownloadClick(event: Object) {
        event.stopPropagation(); // Preventing opening of side bar
    }

    @bind
    openSidebar(data) {
        this.props.openGraphicSidebar(data, this.canEdit, this.reloadList);
    }

    @bind
    renderComponent(props: Object) {
        const { data, style, resize, index } = props;
        const { id, image, name, primary } = data || {};
        const { size } = primary || {};
        const imageSource = image ? getAttachmentUrl(id, 'graphic', image) : null;
        return (
            <ResizableListItem key={id} style={style} index={index} resize={resize}>
                {resizeRow => (
                    <ListItemStyled
                        component={<Avatar src={imageSource} initials={name} />}
                        title={name}
                        subTitle={size && formatBytes(size || 0)}
                        onClick={() => this.openSidebar(data)}
                        actions={
                            <ResponsiveActions
                                actions={[
                                    <Link key="1" target="_blank" rel="noopener noreferrer" download href={imageSource} onClick={this.onDownloadClick}>
                                        <Icon name="download" />
                                    </Link>,
                                    this.canEdit ? <Icon name="delete" onClick={this.removeAttachment(data)} key="2" /> : null
                                ]}
                            />
                        }
                        raised
                    />
                )}
            </ResizableListItem>
        );
    }

    /**
     * @override
     */
    render() {
        const { records, isLoading, totalRecords, startIndex, loadEntityGraphicLibrary } = this.props;
        if (!this.canView) {
            return <PageNotAllowed title="Graphics" />;
        }
        return (
            <PageTemplate title={'Graphics library'}>
                <ContentArea>
                    <EntitiesList
                        id={VIEW_ID}
                        ref={this.graphicListRef}
                        type="graphic"
                        headerTitle="Graphics"
                        records={records}
                        totalRecords={totalRecords || 0}
                        startIndex={startIndex}
                        isLoading={isLoading}
                        renderListComponent={this.renderComponent}
                        loadData={loadEntityGraphicLibrary}
                        columnDefinitions={this.columnDefinitions}
                        filterDefinitions={this.filterDefinitions}
                        disableSettings
                    />
                </ContentArea>
            </PageTemplate>
        );
    }
}

export default connect(
    state => ({
        userProfile: state.user.profile,
        isLoading: state.entities.graphics.list.isLoading,
        records: state.entities.graphics.list.records,
        totalRecords: state.entities.graphics.list.count,
        startIndex: state.entities.graphics.list.startIndex
    }),
    {
        openGraphicSidebar,
        showToastr,
        loadEntityGraphicLibrary,
        deleteEntityGraphicLibrary,
        uploadEntityGraphicLibrary,
        updateEntityGraphicLibrary,
        setDocumentTitle,
    }
)(EntityGraphicsLibrary);
