// @flow

import React, { Fragment, memo, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { muiTheme } from 'app/themes/materialUi';
import {
    Popper,
    Fade,
    Paper,
    ClickAwayListener,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Avatar,
    Typography,
    Box,
    Divider,
    List,
    ListItemIcon,
    Button,
    ListItemSecondaryAction,
    IconButton,
    ListSubheader,
    Switch,
} from '@mic3/platform-ui';
import Icon from 'app/components/atoms/Icon/Icon';

import history from 'store/History';
import affectliSso from 'app/auth/affectliSso';
import sso from 'app/auth/affectliSso';
import { getUTCString } from 'app/utils/date/date';
import { get } from 'app/utils/lo/lo';
import { getAttachmentUrl } from 'app/utils/attachments/attachmentsUtils';
import { GLOBAL_ACCOUNT_URL } from 'app/config/config';
import Geocode from 'app/utils/maps/geocodeUtils';
import Tooltip from 'app/components/atoms/Tooltip/Tooltip';
import { CircularProgress } from '@material-ui/core';
import { loadDataForOfflineCache } from 'offlineMode/singleTabDataLoader';

const PopperBase = styled(Popper)`
    margin-top: 8px;
    z-index: 1201;
    width: 360px;

    & .MuiPaper-root {
        padding: 8px;
    }
`;

const LogoutButton = styled(Button)`
    && {
        padding: 0;
        margin: 4px 0;
    }
`;

const ProfileAvatar = styled(Avatar)`
    && {
        margin-right: 16px;
        height: 64px;
        width: 64px;
    }
`;

const SubTitle = styled(Typography)`
    && {
        color: ${({ theme }) => theme.material.colors.text.secondary} !important;
        font-size: 10px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        font-weight: 500;
        padding: 16px;
    }
`;

const AccountListItem = styled(ListItem)`
    & .MuiAvatar-root {
        height: 24px;
        width: 24px;
        font-size: 12px;
    }
`;

const AccountsList = styled(List)`
    max-height: 200px;
    overflow: auto;

    & .MuiListSubheader-root {
        margin: 0 16px;
        font-size: 10px;
        text-transform: uppercase;
        letter-spacing: 1.5px;
    }

    & .MuiListItemAvatar-root {
        min-width: 40px;
    }
`;

const StatusIcon = styled(Icon)`
    && {
        width: 22px;
        text-align: center;
    }

    &:before {
        font-size: 12px;
        color: ${({ theme, $online }) => {
    const prio = theme.material.colors.priority;
    return $online ? prio.success : prio.danger;
}};
    }
`;

const StyledSwitch = styled(Switch)`
    margin-right: 0 !important;
`;

const EmailStyledBox = styled(Box)`
    display: inline-block;
    width: 100%;
    text-overflow: ellipsis !important;
    white-space: nowrap;
    overflow: hidden;
`;
const NameStyled = styled.span`
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
`;

const AccountPopup = ({
    anchorEl,
    onClose,
    onClickFavorites,
    placement,
    profile,
    hasGlobalAccount,
    userOrganisations,
    online,
    sites,
    showToastr,
    ...otherProps
}: Object) => {
    const tokenType = get(sso.getRefreshTokenParsed(), 'typ');
    const logoutLabel = hasGlobalAccount ? 'Sign out of all sites' : 'Logout';
    const isOfflineMode = tokenType === 'Offline';
    const unAttendedMode = isOfflineMode && localStorage.getItem('unAttendedMode') === 'true';
    const handleLogout = useCallback(() => affectliSso.logout(), []);
    const handleClose = useCallback(() => onClose(), [onClose]);
    const handleOpenFavoritesModal = () => onClickFavorites();
    const { id, image } = profile || {};
    const profileImage = image && getAttachmentUrl(id, 'user', image);
    const userPositionTrackingEnabled = localStorage.getItem('userPositionTrackingEnabled') === 'true' || false;
    const [locationEnabled, setLocationEnabled] = useState(userPositionTrackingEnabled);
    const [isOfflineEnabled, setOfflineSwitch] = useState(localStorage.getItem('offlineEnabled') === 'true');
    const [isOfflineDataLoading, setIsOfflineDataLoading] = useState(false);

    const handleOpenSite = (url) => window.open(`https://${url}`, '_self');

    const handleOpenProfile = useCallback(() => {
        if (!hasGlobalAccount) {
            history.push('/about-me');
            handleClose();
            return;
        }
        window.location.href = `${GLOBAL_ACCOUNT_URL}/#/about-me`;
    }, [hasGlobalAccount, handleClose]);

    const handleToggleLocation = useCallback(() => setLocationEnabled((val) => !val), []);

    useEffect(() => {
        const name = 'userPositionTrackingEnabled';
        if (locationEnabled) {
            localStorage.setItem(name, 'true');
            Geocode.updateUserLocation((response: Object) => {
                if (response && response.code === 1) {
                    showToastr && showToastr({ severity: 'error', detail: `Please enable your location sharing.` });
                    localStorage.setItem(name, 'false');
                    setTimeout(() => setLocationEnabled(false), 300);
                }
            });
        } else {
            localStorage.setItem(name, 'false');
        }
    }, [locationEnabled, showToastr]);

    useEffect(() => {
        localStorage.setItem('offlineEnabled', isOfflineEnabled ? 'true' : 'false');
        const loadData = async () => {
            if(!isOfflineEnabled) return;
            setIsOfflineDataLoading(true);
            await loadDataForOfflineCache();
            setIsOfflineDataLoading(false);
        };
        loadData();
    }, [isOfflineEnabled, setIsOfflineDataLoading]);

    const handleOfflineSwitch = useCallback(() => {
        setOfflineSwitch((prevState) => !prevState);
    }, [setOfflineSwitch]);

    return (
        <PopperBase
            {...otherProps}
            placement={placement}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            modifiers={{ computeStyle: { gpuAcceleration: false } }}
            transition
        >
            {({ TransitionProps }) => (
                <ClickAwayListener onClickAway={onClose}>
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper>
                            <SubTitle>My Profile</SubTitle>
                            <ListItem alignItems='flex-start' ContainerComponent='div'>
                                <ListItemAvatar>
                                    <ProfileAvatar src={profileImage} initials={profile.name} />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={<NameStyled>{profile.name}</NameStyled>}
                                    secondaryTypographyProps={{
                                        component: 'div',
                                    }}
                                    secondary={
                                        <>
                                            <Tooltip title={profile.primary.email}>
                                                <EmailStyledBox component='typography' variant='body2' mb={1}>
                                                    {profile.primary.email}
                                                </EmailStyledBox>
                                            </Tooltip>
                                            <Typography variant='body2' component='div'>
                                                {getUTCString()}
                                            </Typography>
                                        </>
                                    }
                                />
                                <ListItemSecondaryAction>
                                    <IconButton edge='end' onClick={handleOpenProfile}>
                                        <Icon name='open-in-new' hexColor={muiTheme.colors.appNav.linkIconColor} />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Box my={2}>
                                <Divider />
                            </Box>
                            <List disablePadding>
                                <ListItem>
                                    <ListItemIcon>
                                        <StatusIcon name='circle' $online={online} />
                                    </ListItemIcon>
                                    <ListItemText primary={online ? 'Online' : 'Offline'} />
                                </ListItem>
                                <ListItem onClick={handleOpenFavoritesModal}>
                                    <ListItemIcon>
                                        <Icon name='map-marker-radius' />
                                    </ListItemIcon>
                                    <ListItemText primary={`Location sharing is ${locationEnabled ? 'ON' : 'OFF'}`} />
                                    <ListItemSecondaryAction>
                                        <StyledSwitch name='location' value={locationEnabled} onClick={handleToggleLocation} />
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <Icon name='map-marker-radius' />
                                    </ListItemIcon>
                                    <ListItemText primary={`Offline mode is ${isOfflineEnabled ? 'ON' : 'OFF'}`} />
                                    <ListItemSecondaryAction>
                                        {isOfflineDataLoading ? (
                                            <CircularProgress size={24} color='primary' />
                                        ) : (
                                            <StyledSwitch name='location' value={isOfflineEnabled} onClick={handleOfflineSwitch} />
                                        )}
                                    </ListItemSecondaryAction>
                                </ListItem>
                                {unAttendedMode ? (
                                    <ListItem>
                                        <ListItemIcon>
                                            <Icon name='presentation' />
                                        </ListItemIcon>
                                        <ListItemText primary='Unattended mode active' />
                                    </ListItem>
                                ) : null}

                                <ListItem button onClick={handleOpenFavoritesModal}>
                                    <ListItemIcon>
                                        <Icon name='star' />
                                    </ListItemIcon>
                                    <ListItemText primary='Set current page as my favorite' />
                                </ListItem>
                            </List>
                            <Box my={2}>
                                <Divider />
                            </Box>
                            {hasGlobalAccount && (
                                <>
                                    <AccountsList
                                        subheader={
                                            <ListSubheader component='div' disableSticky disableGutters>
                                                Switch sites
                                            </ListSubheader>
                                        }
                                    >
                                        {(userOrganisations || []).map(({ node }, index) => {
                                            const {
                                                organisationAccount: { name: orgName, thumbnail: orgImage },
                                                instances: { edges: instances },
                                            } = node;
                                            return (
                                                <Fragment key={index}>
                                                    {(instances || []).map(({ node }, instanceIndex) => {
                                                        const {
                                                            instance: { instanceName, url },
                                                        } = node;
                                                        return (
                                                            <AccountListItem button key={instanceIndex} onClick={() => handleOpenSite(url)}>
                                                                <ListItemAvatar>
                                                                    <Avatar
                                                                        variant='rounded'
                                                                        initials={orgName}
                                                                        src={orgImage}
                                                                        alt={orgName}
                                                                    />
                                                                </ListItemAvatar>
                                                                <ListItemText primary={instanceName} />
                                                            </AccountListItem>
                                                        );
                                                    })}
                                                </Fragment>
                                            );
                                        })}
                                    </AccountsList>
                                    <Box mt={2} mb={4}>
                                        <Divider />
                                    </Box>
                                </>
                            )}
                            <LogoutButton
                                onClick={handleLogout}
                                variant='contained'
                                color='primary'
                                fullWidth
                                startIcon={<Icon name='logout' hexColor={muiTheme.colors.text.darkChip} />}
                                style={{ color: muiTheme.colors.text.darkChip }}
                            >
                                {logoutLabel}
                            </LogoutButton>
                        </Paper>
                    </Fade>
                </ClickAwayListener>
            )}
        </PopperBase>
    );
};

AccountPopup.defaultProps = {
    placement: 'bottom-end',
};

export default memo(AccountPopup);
