/* @flow */

import { gql } from '@apollo/client';

export default gql`
	query runtimeStatusPipelineQuery($id: ID!){
		result: iotPipelineRuntimeStatus(id: $id) {
			topics {
				label
				status
				error
				tail {
				  key
				  value { data isBinary dataAsUtf8 }
				}
			}
			connectors {
				label
				status
				error
				execution {
					pid
					name
					status
					exePath
					cwdPath
					cmd
					memoryBytes
					virtualMemoryBytes
					cpuUsage
					readBytes
					writtenBytes
					runTimeSecs
					startTimeSecs
					userId
					groupId
					parentPid
				}
			}
		}
	}
`;
