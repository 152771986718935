/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Layout from 'app/components/Designer/Layout';
import Textarea from 'app/containers/Designer/Form/components/Textarea';
import { get } from 'app/utils/lo/lo';
import { bind } from 'app/utils/decorators/decoratorUtils';

class ScriptEditorForm extends PureComponent<Object, Object> {
    static propTypes = {
        script: PropTypes.object.isRequired,
        saveEditorState: PropTypes.func.isRequired,
        onValidate: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            code: get(props, 'script.primary.definition', '')
        };
    }

    componentDidUpdate(prevProps) {
        const { definition: code } = get(this.props.script, 'primary', {});
        const { definition: prevCode } = get(prevProps.script, 'primary', {});
        if(code !== prevCode) {
            this.setState({ code });
        }
    }

    @bind
    handleOnChange(event) {
        const { value: definition } = event.target;
        this.props.saveEditorState({ primary: { definition }});
    }

    @bind
    handleCheckValid(hasError) {
        this.props.onValidate(hasError);
    }

    render() {
        const { sidebarActions, breadcrumbLine, canEdit, isPreviewVersion, tabs, actions } = this.props;
        const { code } = this.state;

        return (
            <Layout 
                tabs={tabs}
                TabsToolbarContent={sidebarActions} 
                leftToolbarContent={breadcrumbLine}
                ToolbarContent={actions}
                disableRightMenu
                height="calc(100vh - 180px)"
                content={
                    <Textarea
                        disabled={!canEdit || isPreviewVersion}
                        value={code}
                        onChange={this.handleOnChange}
                        onValid={this.handleCheckValid}
                        useCodeEditor
                        parseAs="javascript"
                        fullJs
                        noValidation
                    />
                }
            />
        );
    }
}

export default ScriptEditorForm;