/* @flow */

import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Terms from './Terms';

class TermsRoute extends Component<Object, Object> {
    static propTypes = {
        userProfile: PropTypes.object
    };

    render() {
        return (
            <Switch>
                <Route path="/" component={Terms} />
            </Switch>
        );
    }
}

export default connect(
    (state: Object): Object => ({
        userProfile: state.user.profile,
    }))(TermsRoute);
