import React from 'react';
import{closeNav,openNav} from 'store/actions/app/appActions';
import { isMobile } from 'react-device-detect';

//#region React Tour Steps
export const Tutorial_Pages = ['abox'];

export const getLandingPageConfig = (dispatch) =>{
    return  [
        {
            selector: '[data-tut="reactour__home"]',
            content:(
                <p>
                    This is your landing page - <b>Home</b>.
                    Here you will find all your available apps and a dashboard summarising active tasks and processes in one single view.
                </p>
            ),
            position: 'top',
        },
        {
            selector: '[data-tut="reactour__profile"]',
            content:(
                <p>
                    Go to your <b>Profile</b> to switch between your different accounts, set your user information, preferences and more.
                </p>
            ),
            position: 'bottom',
        },
        {
            selector: '[data-tut="reactour__notification"]',
            content:(
                <p><b>Notification</b> alerting you to unread messages.</p>
            ),
            position: 'bottom',
        },
        {
            selector: '[data-tut="reactour__launcher"]',
            content:(
                <p>
                    Use the <b>Launcher</b> to launch apps, and navigate between different modules like A-Box, Maps, Mi-Stream and more.
                </p>
            ),
            position: 'bottom',
        },
        {
            selector: '[data-tut="reactour__app"]',
            content:(
                <p>
                    To start working click on an <b>app</b>. Apps are created in the designer module.
                    <br/> <br/>
                    Some apps start processes. A process is a customised workflow which controls the sequence of tasks.
                </p>
            ),
            position: 'bottom',
            action: (node) => {
                if(isMobile){
                    node.focus();
                    dispatch(closeNav());
                }
            }
        },
        {
            selector: '[data-tut="reactour__navbar"]',
            content:(
                <p>
                    Expand the <b>navigation bar</b> to further explore task management in the A-Box.
                </p>
            ),
            position: 'bottom',
            action: (node) => {
                if(isMobile){
                    node.focus();
                    dispatch(openNav());
                }
            }
        },
        {
            selector: '[data-tut="reactour__magicplus"]',
            content:(
                <p>
                    Click on the <b>Magic Plus</b> button to create new entities.
                    <br/>
                    Entities are customisable, and can be anything from physical assets, people and organisations, to work schedules, contracts and more.
                </p>
            ),

        },
        {
            selector: '[data-tut="reactour__alive"]',
            content:(
                <p>
                    Go to <b>A-Live</b> to chat to users, and collaborate directly on tasks, entities and teams.
                </p>
            )
        },
        {
            selector: '[data-tut="reactour__help"]',
            content:() =>(
                <p>
                    You're done. If you need help, or would like to go through the tutorial again, click on <b>Help</b>.
                    
                </p>
            ),
            position: 'bottom',
        },
    ];
};



//#endregion
