/* @flow */

import { gql } from '@apollo/client';
import eventDetailCommonQueryFields from 'graphql/stream/event/eventDetailCommonQueryFields';

export default gql`
    query eventsQuery($startIndex: Int, $stopIndex: Int, $filterBy: [JSON], $orderBy: [JSON], $excludeBy: [JSON]) {
        count: count(dataType: "event", filterBy: $filterBy, excludeBy: $excludeBy)
        records: events(startIndex: $startIndex, stopIndex: $stopIndex, filterBy: $filterBy, orderBy: $orderBy, excludeBy: $excludeBy) {
            ${eventDetailCommonQueryFields}
        }
    }
`;
