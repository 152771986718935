/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { bind } from 'app/utils/decorators/decoratorUtils';
import { get } from 'app/utils/lo/lo';

import Sidebar from 'app/components/templates/Sidebar';
import { closeLeftPanel, resizeLeftPanel, setShowBack, setFilterBy } from 'store/actions/leftPanel/leftPanelActions';
import { typeTitlesMultiple } from 'app/config/typesConfig';
import { openEntityLeftPanel } from 'store/actions/entities/entityLeftPanelActions';
import { openChatLeftPanel } from 'store/actions/chat/chatLeftPanelActions';
import { openBoardsLeftPanel } from 'store/actions/boards/boardsLeftPanelActions';
import { openRelatedEntityPanel } from 'store/actions/entities/relatedEntityLeftPanelActions';
import { DEFAULT_LEFT_SIDEBAR_WIDTH, MIN_LEFT_SIDEBAR_WIDTH } from 'app/config/config';

const SidebarStyled = styled(Sidebar)`
  ${({ isMobile }) => isMobile || 'padding-left: 60px;'}
`;

const leftPanelDefinitions = {
    'events': {
        title: typeTitlesMultiple['eventtype'],
        entityType: 'event',
    },
    'kanbanboards': {
        title: typeTitlesMultiple['kanbanboards'],
        entityType: 'kanbanboards',
    },
    'beta-kanbanboards': {
        title: typeTitlesMultiple['kanbanboards'],
        entityType: 'kanbanboards',
        betaBoards: true
    },
    'tasks': {
        title: 'Process Definitions',
        entityType: 'task',
    },
    'entities': {
        title: 'Entity Types',
        entityType: 'entity',
    },
    'classEntities': {
        title: 'Class Entities',
        entityType: 'entity',
    },
    'relatedEntities': {
        title: 'Related Entities',
        entityType: 'relatedEntity',
    },
    'alive': {
        title: typeTitlesMultiple['alive'],
        entityType: 'alive',
    },
};

export const parseView = (currentPath) => {
    const splitedPath = currentPath.split('/') || [];
    const startPath = `/${splitedPath[1]}/${splitedPath[2] || ''}`;
    if (startPath.startsWith('/events/') && !startPath.startsWith('/events/event-types')) {
        return 'events';
    }
    if (startPath.startsWith('/entities/')) {
        return 'entities';
    }
    if (startPath.startsWith('/related-entities/')) {
        return 'relatedEntities';
    }
    if (splitedPath[1] === 'classifications' && splitedPath[3] === 'entities') {
        return 'classEntities';
    }
    if (['/abox/tasks', '/abox/tasks/panel'].includes(startPath)) {
        return 'tasks';
    }
    if (['/abox/kanbanboards'].includes(startPath)) {
        return 'kanbanboards';
    }
    if (['/abox/beta-kanbanboards'].includes(startPath)) {
        return 'beta-kanbanboards';
    }
    if (['/abox/alive'].includes(startPath)) {
        return 'alive';
    }

    return null;
};

class GlobalLeftPanel extends PureComponent<Object, Object> {
    static propTypes = {
        title: PropTypes.string,
        subTitle: PropTypes.string,
        actions: PropTypes.any,
        content: PropTypes.any,
        isOpen: PropTypes.bool,
        expanded: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        this.state = {
            leftPanelWidth: DEFAULT_LEFT_SIDEBAR_WIDTH,
        };
        this.openPanel();
    }

    componentDidUpdate(prevProps) {
        const prevPath = get(prevProps, 'location.pathname', '');
        const currentPath = get(this.props, 'location.pathname', '');
        const { closeLeftPanel, setShowBack } = this.props;
        if(prevPath === currentPath) {
            return;
        }
        if (!parseView(currentPath))  {
            closeLeftPanel();
            setShowBack(false);
        } else {
            this.openPanel();
        }
    }

    @bind
    openPanel() {
        const { setShowBack, openEntityLeftPanel, openChatLeftPanel, openBoardsLeftPanel, openRelatedEntityPanel, isMobile } = this.props;
        setShowBack(false);
        const currentPath = get(this.props, 'location.pathname', '');
        const splitedPath = currentPath.split('/') || [];
        const view = parseView(currentPath);
        const panelDefinition = leftPanelDefinitions[view];

        if (panelDefinition) {
            switch (view) {
                case 'classEntities':
                    openEntityLeftPanel({ ...panelDefinition, id: splitedPath[2], type: splitedPath[4], view });
                    break;
                case 'relatedEntities':
                    openRelatedEntityPanel({ ...panelDefinition, type: view, view });
                    break;
                case 'entities':
                    openEntityLeftPanel({ ...panelDefinition, type: splitedPath[2], id: splitedPath[4] ||  splitedPath[2], view });
                    break;
                case 'events':
                    openEntityLeftPanel({ ...panelDefinition, type: view, id: splitedPath[4], view });
                    break;
                case 'tasks':
                    openEntityLeftPanel({ ...panelDefinition, type: view, id: splitedPath[5], view });
                    break;
                case 'alive':
                    openChatLeftPanel({ ...panelDefinition, type: view, id: '', view, isOpen: !isMobile });
                    break;
                case 'kanbanboards': case 'beta-kanbanboards':
                    openBoardsLeftPanel({ ...panelDefinition, type: view, id: '', view });
                    break;
                default:
                    break;
            }
        }
    }

    @bind
    handleResize(width: number) {
        this.setState(state => ({
            leftPanelWidth: width
        }));
    }

    @bind
    renderLeftPanel(options: Object, closeLeftPanel: func, resizeLeftPanel: func) {
        const {
            title, subTitle, actions, content, isOpen, expanded
        } = options;
        const { leftPanelWidth } = this.state;
        return isOpen && (
            <SidebarStyled
                isMobile={this.props.isMobile}
                fullScreen={expanded}
                onClose={closeLeftPanel}
                isResizing={resizeLeftPanel}
                sidebarWidth={leftPanelWidth}
                onResize={this.handleResize}
                title={title}
                subTitle={subTitle}
                className="global-template-leftPanel"
                actions={actions}
                left
                hideClose
                minSidebarWidth={MIN_LEFT_SIDEBAR_WIDTH}
            >
                {content}
            </SidebarStyled>
        );
    }

    render() {
        const {
            leftPanelOpts, closeLeftPanel, resizeLeftPanel
        } = this.props;
        return (
            <section id="global-left-panel">
                {this.renderLeftPanel(leftPanelOpts, closeLeftPanel, resizeLeftPanel)}
            </section>
        );
    }
}

export default connect(
    (state: Object, ownProps: Object) => ({
        leftPanelOpts: state.leftPanel.state,
        isMobile: state.global.isMobile,
    }),
    { 
        closeLeftPanel,
        resizeLeftPanel,
        openEntityLeftPanel,
        setShowBack,
        setFilterBy,
        openChatLeftPanel,
        openBoardsLeftPanel,
        openRelatedEntityPanel
    },
)(withRouter(GlobalLeftPanel));
