/* @flow */

import React, { useEffect, useRef, isValidElement } from 'react';
import { connect } from 'react-redux';
import { muiTheme } from 'app/themes/materialUi';
import { IconButton, MdiIcon } from '@mic3/platform-ui';
import { useSnackbar, SnackbarProvider } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    success: { backgroundColor: `${theme.colors.success.main} !important`},
    error: { backgroundColor: `${theme.colors.error.main} !important`},
    warning: { backgroundColor: `${theme.colors.warning.main} !important`},
    info: { backgroundColor: `${theme.colors.info.main} !important` },
    containerRoot: {alignItems: 'end', flexDirection: 'column' }
}));

const Toastr = connect(
    (state: Object): Object => ({
        options: state.app.toastrOptions,
    }),
    null
)(({ options, children }) => {
    const { enqueueSnackbar } = useSnackbar();
    useEffect(() => {
        const { detail } = options || {};
        const snackbarOpts = { variant: options.severity, autoHideDuration: 5000 };
        if(typeof detail === 'string') {
            const modifiedWords = (detail || '').split(' ').map((word) => {
                if (word.length > 25) {
                    return `${word.substring(0, 12)}...${word.slice(-10)}`;
                }
                return word;
            });
            enqueueSnackbar((modifiedWords || []).join(' '), snackbarOpts);
        } else if(isValidElement(detail)) {
            enqueueSnackbar(detail, snackbarOpts);
        }
    }, [enqueueSnackbar, options]);

    return null;
});

const ToastrContainer = ({ children }) => {
    const notistackRef = useRef(null);
    const onClickDismiss = key => () => {
        notistackRef.current.closeSnackbar(key);
    };
    const classes = useStyles();
    return (
        <SnackbarProvider
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            classes={{
                variantSuccess: classes.success,
                variantError: classes.error,
                variantWarning: classes.warning,
                variantInfo: classes.info,
                containerRoot: classes.containerRoot
            }}

            maxSnack={3}
            ref={notistackRef}
            action={key => (
                <IconButton onClick={onClickDismiss(key)}>
                    <MdiIcon name="close" color={muiTheme.colors.text.button}/>
                </IconButton>
            )}
        >
            <Toastr />
            {children}
        </SnackbarProvider>
    );
};

export default ToastrContainer;
