/* @flow */

import { graphql } from 'graphql/client';
import OptionsBuilder from 'app/utils/api/OptionsBuilder';
import { loadData, mutateData } from 'app/utils/redux/action-utils';
import { get } from 'app/utils/lo/lo';
import { addRoleToData } from 'app/config/rolesConfig';

import userByReferenceQuery from 'graphql/app/userByReferenceQuery';
import userChangelogQuery from 'graphql/users/userChangelogQuery';
import setUserDefaultIdentityProviderMutation from 'graphql/users/setUserDefaultIdentityProviderMutation';
import loadUserWorkspacesQuery from 'graphql/workspaces/loadUserWorkspacesQuery';

export const LOAD_USER_STARTED: string = '@@affectli/admin/users/LOAD_USER_STARTED';
export const LOAD_USER: string = '@@affectli/admin/users/LOAD_USER';

export const LOAD_ROLE_STARTED: string = '@@affectli/admin/users/LOAD_ROLE_STARTED';
export const LOAD_ROLE_OF: string = '@@affectli/admin/users/LOAD_ROLE_OF';

export const LOAD_USER_HISTORY_STARTED = '@@affectli/admin/users/LOAD_USER_HISTORY_STARTED';
export const LOAD_USER_HISTORY = '@@affectli/admin/users/LOAD_USER_HISTORY';

export const LOAD_USER_WORKSPACES_STARTED = '@@affectli/admin/users/LOAD_USER_WORKSPACES_STARTED';
export const LOAD_USER_WORKSPACES = '@@affectli/admin/users/LOAD_USER_WORKSPACES';

export const SET_USER_DEFAULT_IDENTITY_PROVIDER_STARTED = '@@affectli/admin/users/SET_USER_DEFAULT_IDENTITY_PROVIDER_STARTED';
export const SET_USER_DEFAULT_IDENTITY_PROVIDER = '@@affectli/admin/users/SET_USER_DEFAULT_IDENTITY_PROVIDER';

/**
 * Load the detail of the specified User
 *
 * @param user the name of the User to load
 */
export const loadUser = (id: string) => loadData(LOAD_USER_STARTED, LOAD_USER, userByReferenceQuery, addRoleToData)({ id });

/**
 * Fetch the user changelog.
 *
 * @param login the entity login.
 * @param id the entity ID.
 * @param options the filter options.
 */
export const loadUserChangelog = (login: string, id: string, options: Object) => (dispatch: Function) => {
    dispatch({ type: LOAD_USER_HISTORY_STARTED });
    return graphql.query({
        query: userChangelogQuery,
        variables: { reference: { login }, id: String(id), ...options },
        fetchPolicy: 'no-cache',
    }).then((response: Object): void => {
        const payload = {
            changes: get(response, 'data.userByReference.changelog'),
            startIndex: options.startIndex,
            count: get(response, 'data.count'),
        };
        dispatch({ type: LOAD_USER_HISTORY, payload });
    }).catch((error) => {
        dispatch({ type: LOAD_USER_HISTORY, payload: error, error: true });
    });
};

export const loadUserWorkspaces = (userId) => {
    const variables = new OptionsBuilder({})
        .filter({ field: 'isSystem', op: '=', value: false })
        .filter({ field: 'teams.team.users.user.id', op: '=', value: userId })
        .filter({ field: 'teams.team.isSystem', op: '=', value: true })
        .defaultStartStopIndexs(0, 1000)
        .build();
    return loadData(LOAD_USER_WORKSPACES_STARTED, LOAD_USER_WORKSPACES, loadUserWorkspacesQuery, (payload) => {
        const records = payload.records?.map((wspace) => {
            const users = [];
            wspace.teams.forEach(team => team.team?.isSystem && users.push(...(team?.team?.users || [])));
            const result = (users || []).find(usr => usr && usr?.user?.id === userId) || {};
            return {...wspace, role: result?.role };
        });
        return { ...payload, records };
    })(variables);
};

export const setUserDefaultIdentityProvider = (userId, identityProvider) => mutateData(
    SET_USER_DEFAULT_IDENTITY_PROVIDER_STARTED, 
    SET_USER_DEFAULT_IDENTITY_PROVIDER, 
    setUserDefaultIdentityProviderMutation, 
    // 'Default identity provider updated.'
)({ user: userId, identityProvider });
