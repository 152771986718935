import { gql } from '@apollo/client';

export default gql`
query loadBoardsQuery($startIndex: Int, $stopIndex: Int, $filterBy: [JSON], $orderBy: [JSON], $excludeBy: [JSON]) {
  records: entities(type: "system_board", startIndex: $startIndex, stopIndex: $stopIndex, filterBy: $filterBy, orderBy: $orderBy, excludeBy: $excludeBy) {
    id
    name
    type
    iconName
    iconColor
    iconType
    primary
  }
}
`;
