/* @flow */

import { label, placeholder } from 'app/utils/designer/form/settings/common/commonBase';
import { filterBy, excludeBy, orderBy } from 'app/utils/designer/form/settings/common/commonGeneral';
import { onLoad, onChange, isVisible, isDisabled } from 'app/utils/designer/form/settings/common/commonEvents';
import { help } from 'app/utils/designer/form/settings/common/commonHelpers';
import { get } from 'app/utils/lo/lo';
import { flexGrow } from 'app/utils/designer/form/settings/common/commonStyles';
import { readOnly } from 'app/utils/designer/form/settings/common/commonValidation';

const setFromId = `async (data, context) => {
    if (context?.entity?.id) {
        return [
            { action: 'setProperty', name: 'fromId', value: String(context.entity.id) },
            { action: 'setProperty', name: 'fromType', value: String(context.entity.type) },
        ];
    }
    if (context?.task?.id) {
        return [
            { action: 'setProperty', name: 'fromId', value: String(context.task.id) },
        ];
    }
    return null;
}`;

export default {
    defaults: {
        serialize: true,
        fromType: 'opentask',
        toType: null,
        onLoad: setFromId,
        onContextChange: setFromId,
    },
    panels: (settingsValues) => {
        const { viewType, classification } = settingsValues || {};
        const { toType, fromType } = get(settingsValues, 'properties', {});
        let applicableOnDefinitions = [{
            type: 'relationDefinitionTypeahead',
            properties: {
                label: 'Relation Definition ID',
                name: 'properties.definition',
                fromType: classification?.uri || fromType,
                toType,
                onChange: event => [
                    event.target,
                    { name: 'properties.definitionId', value: get(event, 'target.value.relationDefinition.Id') },
                    { name: 'properties.reverse', value: get(event, 'target.value.reverse') }
                ],
                isVisible: data => data.properties && data.properties.toType,
            },
            constraints: { required: true },
        }];
        if(viewType === 'class' && !classification.primary && toType) {
            applicableOnDefinitions = (classification?.applicableOn || []).filter(appl => !['workspace', 'relationdefinition'].includes(appl)).map(entity => ({
                type: 'relationDefinitionTypeahead',
                properties: {
                    label: `Relation Definition from '${entity}' to '${toType}'`,
                    name: `relation_definition_${entity}`,
                    fromType: entity,
                    toType: toType,
                    isVisible: data => data.properties && data.properties.toType,
                },
                constraints: { required: true },
            }));
        }
        return [
            {
                header: 'Base',
                children: [
                    label,
                    placeholder,
                    {
                        type: 'entityTypesTypeahead',
                        properties: {
                            label: 'To Type',
                            name: 'properties.toType',
                            valueField: 'uri',
                            clearable: false,
                            fromType: classification?.uri || fromType,
                            onChange: (event, data, context) => {
                                let nullApplicableFields = [];
                                if (applicableOnDefinitions?.length) {
                                    nullApplicableFields = (classification?.applicableOn || [])
                                        .filter((appl) => !['workspace', 'relationdefinition'].includes(appl))
                                        .map((entity) => ({ name: `relation_definition_${entity}`, value: null }));
                                }
                                return [event.target, { name: 'properties.definition', value: null }, ...nullApplicableFields];
                            },
                        },
                        constraints: { required: true },
                    },
                    ...applicableOnDefinitions,
                ],
            },
            {
                header: 'General',
                children: [
                    filterBy,
                    excludeBy,
                    orderBy,
                ]
            },
            {
                header: 'Events',
                children: [
                    onLoad,
                    onChange,
                    isVisible,
                    settingsValues?.viewType !== 'class' && isDisabled
                ].filter(Boolean)
            },
            settingsValues?.viewType === 'class' && {
                header: 'Validation',
                children: [
                    readOnly,
                ]
            },
            {
                header: 'Styles',
                children: [ flexGrow ]
            },
            {
                header: 'Helpers',
                children: [ help ]
            },
        ].filter(Boolean);
    },
};
