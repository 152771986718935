/* @flow */

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Chip, List, ListItem, ListItemText, ListItemSecondaryAction } from '@mic3/platform-ui';

import { EntityLinkStyledSubTitle, LinkIcon } from 'app/containers/Entities/EntitiesList/EntitiesListItem';
import { loadEntityVersions, setEntityDraftVersion, setEntityPreviewVersion } from 'store/actions/entities/entitiesActions';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { fromNow } from 'app/utils/date/date';

import Icon from 'app/components/atoms/Icon/Icon';
import Loader from 'app/components/atoms/Loader/Loader';
import DotMenu from 'app/components/molecules/DotMenu/DotMenu';
import PageTemplate from 'app/components/templates/PageTemplate';
import { commonEntitiesListFilters } from 'app/utils/entity/entityUtils';
import { showToastr } from 'store/actions/app/appActions';
import { copyToClipboard } from 'app/utils/classification/classificationUtils';
import { getEntityUrl } from 'app/utils/entity/entityUtils';
import { runExportEntityVersion } from 'store/actions/abox/backgroundJobActions';
import { ExportBJStarted } from 'app/containers/DownloadList/DownloadList';
import { get } from 'app/utils/lo/lo';

const ChipStyled = styled(Chip)`
    background-color: ${({ theme }) => theme.material.colors.background.activeElement} !important;
    text-transform: uppercase;
    font-size: 8px !important;
    color: ${({ theme }) => theme.material.colors.text.button} !important;
`;

const ListStyled = styled(List)`
    .MuiListItem-container:nth-child(1) .MuiListItem-root .MuiTypography-colorTextSecondary {
        width: calc(100% - 59px);
    }
    .MuiListItem-container:nth-child(1) .MuiListItem-root .MuiTypography-caption {
        max-width: calc(100% - 25px);
        display: block;
    }
`;

const ListItemStyled = styled(ListItem)`
    &.Mui-selected {
        background-color: ${({ theme }) => theme.material.colors.background.listItemSelected} !important;
    }
`;

const IconStyled = styled(Icon)`
    &:before {
        color: rgba(255, 255, 255, 0.24);
        font-size: 6rem !important;
        font-weight: 100 !important;
        line-height: 7rem;        
        @media (max-width: 480px) {
            margin-left: 1rem;
        }
    }
`;

const Contaienr = styled.div`
    display: flex;
    justify-content: space-around;
    height: 90vh;
`;

const Flex = styled.div`
    margin-top: -3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const SubTitle = styled.div`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.7);
    mix-blend-mode: normal;
    padding: 0 16px; 
    text-align: center;   
`;

const EntityLinkWrapper = styled.span`
    & .MuiTypography-caption {
        float: left;
    }
`;

const StyledSpan = styled.span`
    color: '#FFFFFF';
    opacity: 70%;
    font-size: 16px;
`;

const moduleNameList = {
    'iot_pipeline_version': 'Pipeline Designer',
    'iot_connector_version': 'Connector',
    'iot_module_version': 'Smart Module',
    'processdefinitionversion': 'Process Designer',
    'formdefinitionversion': 'Form Designer',
    'scriptversion': 'Script Designer',
};

class EntityVersions extends PureComponent<Object, Object> {
    virtualListRef = React.createRef();

    static propTypes = {
        isLoading: PropTypes.bool,
        records: PropTypes.array,
        startIndex: PropTypes.number,
        totalRecords: PropTypes.number,
        loadEntityVersions: PropTypes.func.isRequired,
    };

    state = { isAddScriptOpen: false, showExportNotification: false };

    breadcrumb = [{ title: 'Script Builder' }];
    searchBar = ['name', 'id'];

    filterDefinitions: Array<Object> = [
        { field: 'name', type: 'text', properties: { label: 'Name', name: 'name', opSelector: true }},
        { field: 'id', type: 'uuid', properties: { label: 'ID', name: 'id', opSelector: true }},
        ...commonEntitiesListFilters
    ];
    defaultOrder = [{ field: 'createdDate', direction: 'desc nulls last' }];

    constructor(props) {
        super(props);
        props.loadEntityVersions(props.details.id, props.details.type);
    }

    @bind
    toggleAddScript() {
        this.setState({ isAddScriptOpen: !this.state.isAddScriptOpen });
    };
    @bind
    closeExportNotification() {
        this.setState({ showExportNotification: false });
    };

    @bind
    parseVersion(details) {
        const { type, primary } = details;
        switch (type) {
            case 'iot_pipeline_version':
                return primary['iot_pipeline_version/version'];
            default:
                return primary.version;
        }
    }

    @bind
    async onDotMenuClick(name, versionedDetails) {
        const { setEntityDraftVersion, setEntityPreviewVersion, details, runExportEntityVersion } = this.props;
        const { type, id } = details;
        if(name === 'Export version') {
            let result = {};
            try {
                result = await runExportEntityVersion(type, id, {
                    version: this.parseVersion(versionedDetails),
                    name: `Export ${type} "${details.name}" version "${versionedDetails.primary.version}"`
                });
            } catch (error) {}
            if (!(result instanceof Error)) {
                return this.setState({ showExportNotification: true, jobId: result?.id || null });
            }

        }
        if(name === 'Restore to draft') {
            await setEntityDraftVersion(type, {...versionedDetails});
            await setEntityPreviewVersion(type, null);
        }
        if(name === 'Preview version') {
            setEntityPreviewVersion(type, versionedDetails);
        }
    };

    @bind
    isSelected(type, previewVersion, details, primary) {
        const prevPrimary = previewVersion?.primary || {};
        return (
            prevPrimary?.processDefinition === details.id 
        || prevPrimary?.formDefinition === details.id
        || prevPrimary?.script === details.id
        ) && prevPrimary?.version === primary?.version
        || (
            prevPrimary[`${type}/resource_id`] === details.id 
            && prevPrimary[`${type}/version`] === primary[`${type}/version`]
        );
    }

    @bind
    copyIdToClipBoard(e, id: string) {
        e.stopPropagation();

        copyToClipboard(id)
            .then(() => {
                this.props.showToastr({ severity: 'success', detail: 'ID copied to clipboard' });
            })
            .catch(() => {
                this.props.showToastr({ severity: 'error', detail: 'ID could not copied to clipboard' });
            });
    }    

    @bind
    copyLinkToClipBoard(e, id, type) {
        e.stopPropagation();
        const origin = window.location.origin;
        copyToClipboard(`${origin}/#${getEntityUrl(id, type)}`)
            .then(() => {
                this.props.showToastr({ severity: 'success', detail: 'Link copied to clipboard' });
            })
            .catch(() => {
                this.props.showToastr({ severity: 'error', detail: 'Link could not copied to clipboard' });
            });
    }

    @bind
    renderComponent({ data, index, previewVersion }) {
        const { details, disabled } = this.props;
        const { id, createdDate, createdBy, primary, type } = data;
        const isSelected = this.isSelected(type, previewVersion, details, primary);

        const versionFromPrimary = ['processdefinitionversion', 'formdefinitionversion', 'scriptversion'].includes(type);
        const version = versionFromPrimary ? primary.version : get(primary, `${type}/version`);
        const moduleName = moduleNameList[type];
        

        return (
            <ListItemStyled key={data.id} button selected={isSelected} onClick={() => this.onDotMenuClick('Preview version', data)}>
                <ListItemText 
                    primary={`Version ${primary.version || primary[`${type}/version`]}`} 
                    secondary={(
                        <EntityLinkWrapper>
                            <EntityLinkStyledSubTitle
                                color="secondary"
                                variant="caption"
                                component="span"
                                noWrap
                                title="Copy ID to clipboard"
                                onClick={e => this.copyIdToClipBoard(e, id)}
                            >
                                {`#${id}`}
                            </EntityLinkStyledSubTitle>
                            <LinkIcon title="Copy link to clipboard" name="link" size="sm" onClick={e => this.copyLinkToClipBoard(e, id, type)}/>
                            <br/>{`${fromNow(createdDate)} by ${createdBy.name}`}
                        </EntityLinkWrapper>
                    )}
                />
                <ListItemSecondaryAction>
                    {index === 0 && <ChipStyled size="small" label="Latest" />}
                    <DotMenu
                        key={13}
                        onItemClick={this.onDotMenuClick}
                        items={[                         
                            {
                                name: 'Restore to draft',
                                confirmationModalProps:{
                                    header: 'Overwrite draft?', 
                                    message: <StyledSpan>Are you sure you want to restore Version {version} to draft?
                                     This will overwrite any unpublished changes made to the {moduleName}.</StyledSpan>,
                                    declineButtonText: 'Cancel',
                                    confirmButtonText: 'Restore',
                                },
                                withConfirmation: true,
                                onItemClick: () =>  this.onDotMenuClick('Restore to draft', data),
                                disabled,
                            },
                            {
                                name: 'Export version',
                                confirmationModalProps:{
                                    message: `Are you sure you want export version - ${primary.version}?`,
                                    declineButtonText: 'Cancel',
                                    confirmButtonText: 'Export',
                                },
                                withConfirmation: true,
                                onItemClick: () =>  this.onDotMenuClick('Export version', data),
                            }                            
                        ].filter(Boolean)}
                    />
                </ListItemSecondaryAction>
            </ListItemStyled>
        );
    };

    @bind
    @memoize()
    buildList(records, previewVersion) {
        if(!records?.length) {
            return (
                <Contaienr>
                    <Flex>
                        <IconStyled name="content-save-all" />
                        <SubTitle>Save and publish changes <br />to create a new version</SubTitle>
                    </Flex>
                </Contaienr>
            );
        }
        return (records || []).map((rec, i) => this.renderComponent({ previewVersion, data: rec, index: i }));        
    }

    render() {
        const { records, previewVersion, isLoading } = this.props;
        const { showExportNotification, jobId } = this.state;
        return (
            <>
                {isLoading && <Loader absolute backdrop />}
                <PageTemplate title="Versions">
                    <ListStyled>
                        {this.buildList(records, previewVersion)}
                    </ListStyled>
                </PageTemplate>
                {showExportNotification && (
                    <ExportBJStarted jobId={jobId} onClose={this.closeExportNotification} />
                )}
            </>
        );
    }
};

export default connect((state, ownProps) => ({
    startIndex: state.entities.versions.startIndex,
    records: state.entities.versions.records,
    totalRecords: state.entities.versions.count,
    isLoading: state.entities.versions.isLoading,
    previewVersion: state.entities.common.previewVersion[ownProps?.details?.type],
}), {
    loadEntityVersions, setEntityDraftVersion, setEntityPreviewVersion, 
    showToastr, runExportEntityVersion
})(EntityVersions);
