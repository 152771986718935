/* @flow */

import React, { PureComponent } from 'react';
import styled, { withTheme } from 'styled-components';
import { bind } from 'app/utils/decorators/decoratorUtils';

import {  Slide, Snackbar } from '@mic3/platform-ui';


const StyledSnackbar = styled(Snackbar)`
    .MuiSnackbarContent-root {
        background-color:  ${({ theme }) => theme.material.colors.background.default};
        color: ${({ theme }) => theme.material.colors.text.primary};
        display: block;
    }

    &.MuiSnackbar-anchorOriginBottomCenter {
       bottom: 50px !important;
       position: absolute !important;
   }

   &.MuiSnackbarContent-action {
       float: right !important;
   }
`;


class LocationSnackbar extends PureComponent<Object, Object> {

    constructor(props){
        super(props);
        this.state = {
            open: false,
            message: '',
            action: null
        };
    }

    @bind
    handleOpen(open: boolean) {
        this.setState({ open });
    }

    @bind
    addAction(action: any){
        this.setState({action:  action});
    }

    @bind
    addMessage(message: string){
        this.setState({message: message});
    }

    render(): Object {
        const { open, message, action } = this.state;
        return (
            open && <StyledSnackbar
                open={open}
                TransitionComponent={props => <Slide {...props} direction="up" />}
                message={message}
                action={action}
            />
        );
    }
}

export default withTheme(LocationSnackbar);
