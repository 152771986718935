/* eslint-disable array-callback-return */
/* @flow */

import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { TextField, IconButton, Badge } from '@mic3/platform-ui';

import Relations from 'app/containers/Entities/Relationships/Relations';
import Breadcrumbs from 'app/components/organisms/Breadcrumbs/Breadcrumbs';
import DotMenu from 'app/components/molecules/DotMenu/DotMenu';
import Loader from 'app/components/atoms/Loader/Loader';
import PageTemplate from 'app/components/templates/PageTemplate';
import TaskFormTab from 'app/containers/Abox/TaskView/TaskFormTab';
import PageNotAllowed from 'app/containers/ErrorPages/PageNotAllowed';
import TaskProcessMapTab from 'app/containers/Abox/TaskView/TaskProcessMapTab';
import MenuItem from 'app/components/molecules/Menu/MenuItem';
import TaskAbout from 'app/containers/Abox/TaskView/TaskAbout';
import history from 'store/History';
import { set, get } from 'app/utils/lo/lo';
import { loadTaskSidebarDetails, closeTask, updateTask } from 'store/actions/abox/taskActions';
import { openChat } from 'store/actions/chat/chatActions';
import { getStr } from 'app/utils/utils';
import { bind, debounce, memoize } from 'app/utils/decorators/decoratorUtils';
import Icon from 'app/components/atoms/Icon/Icon';
import EditableText from 'app/components/molecules/EditableText/EditableText';
import { openEntitySidebar } from 'store/actions/entities/entitySidebarActions';
import { mediaBreakpoints } from 'app/themes/breakpoints';
import { openTaskSidebar } from 'store/actions/abox/taskSidebarActions';
import { getUnreadMessages, buildPrintTemplates } from 'app/utils/entity/entityUtils';
import { getPermissions } from 'app/config/rolesConfig';
import { isEmpty } from 'app/utils/utils';
import EntityDigitalTwin from 'app/containers/Entities/DigitalTwin/EntityDigitalTwin';
import { openProcessSidebar } from 'store/actions/abox/processSidebarActions';
import { openRelationSidebar } from 'store/actions/entities/relationSidebarActions';
import { loadClassificationTaskAndProcess } from 'store/actions/classifications/classificationsActions';
import { loadPrintTemplatesListView } from 'store/actions/entities/printTemplateActions';
import { setDocumentTitle } from 'store/actions/app/appActions';

/**
 *
 */
class TaskRoute extends PureComponent<Object, Object> {
    static propTypes = {
        details: PropTypes.object,
        isLoading: PropTypes.bool,
        loadTaskSidebarDetails: PropTypes.func,
        updateTask: PropTypes.func,
        setTaskAssignee: PropTypes.func,
        setTaskOwner: PropTypes.func,
        setTaskPriority: PropTypes.func,
        openTaskSidebar: PropTypes.func
    };

    /**
     *
     */
    constructor(props: Object) {
        super(props);
        const { id } = props;
        this.props.loadTaskSidebarDetails(id);
        this.props.loadClassificationTaskAndProcess();
        this.props.loadPrintTemplatesListView();
    }

    componentDidUpdate(prevProps) {
        const { details, setDocumentTitle } = this.props;
        const name = details?.name;
        if(name) {
            setDocumentTitle(name);
        }
    }

    @bind
    saveTaskChanges(changes: Object) {
        this.props.updateTask({ id: this.props.details.id, ...changes });
    }

    @bind
    onSaveName(event) {
        this.saveTaskChanges({ name: event.target.value });
    }

    @bind
    reloadDetails() {
        const id = get(this.props, 'details.id');
        if (this.props.online && id) {
            this.props.loadTaskSidebarDetails(id);
        }
    }

    @bind
    @debounce()
    updateField(event, updatedVariables) {
        const { id } = this.props;
        const { name, value } = event.target || updatedVariables || event;
        let task = { id };
        task = set(task, name, typeof value === 'string' ? value.trim() : value);
        this.props.updateTask(task);
    }

    @bind
    handleCloseTask() {
        const { id } = this.props;
        return this.props.closeTask(id);
    }

    @bind
    buildTaskFormTab(pathname, unreadMessages, hasForm, permissions) {
        const { details, profile, sidebarTitle } = this.props;
        return (
            <TaskFormTab
                canEdit={permissions.canEdit}
                profile={profile}
                details={details}
                reloadDetails={this.reloadDetails}
                breadcrumbLine={this.buildBreadcrumbs(details.name)}
                sidebarActions={
                    <>
                        {this.renderActions(unreadMessages, permissions)}
                        {this.dotMenu(sidebarTitle, pathname, hasForm, details?.classes)}
                    </>
                }
            />
        );
    }

    @bind
    @memoize()
    buildMenuItems(isXs, hasForm, isOpen) {
        const items = [];
        if (isXs) {
            items.push(<MenuItem key="openInfo" name="Open About" icon="information" onClick={() => this.onDotMenuClick('About')} />);
            items.push(<MenuItem key="openChat" name="Open Chat" icon="messenger" iconType="af" onClick={() => this.onDotMenuClick('A-Live')} />);
        }
        if (!hasForm && isOpen) {
            items.push(<MenuItem key="closeTask" name="Close task" icon="check-circle" onClick={this.handleCloseTask} />);
        }
        return items.length ? items : null;
    }

    @bind
    renderActions(unreadMessages, permissions = {}) {
        return (
            <>
                <IconButton icon="information" title="Edit task" onClick={() => this.onDotMenuClick('About')}>
                    <Icon name="information" />
                </IconButton>
                {permissions.canComment && (
                    <IconButton title="Open A-live" onClick={() => this.onDotMenuClick('A-Live')}>
                        <Badge color="error" badgeContent={unreadMessages} max={99}>
                            <Icon name="messenger" type="af" />
                        </Badge>
                    </IconButton>
                )}
            </>
        );
    }

    @bind
    onDotMenuClick(title) {
        const { id, openTaskSidebar } = this.props;
        if (title === 'Form') {
            history.push(`/abox/task/${id}/form`);
            return;
        }
        if (title === 'Relations') {
            history.push(`/abox/task/${id}/relationships`);
            return;
        }
        if (title === 'Process Map') {
            history.push(`/abox/task/${id}/process-map`);
            return;
        }
        if (title === 'Digital Twin') {
            return history.push(`/abox/task/${id}/digital-twin`);
        }
        openTaskSidebar({ title, id, internal: true });
    }

    @bind
    @memoize()
    dotMenu(sidebarTitle: string, pathname: string = '', hasForm: boolean, classes: Array<Object>) {
        const { id, details, printTemplateRecords, tasksAndProcesses } = this.props;
        const isDigitalTwin = !!(classes || []).find(cls => cls?.uri === 'digital-twin') || details?.primaryClass?.digitalTwins?.length;
        const isRelations = pathname.endsWith(`${id}/relationships`);
        return (
            <DotMenu
                key={13}
                onItemClick={this.onDotMenuClick}
                items={[
                    hasForm &&
                        !pathname.endsWith(`${id}/form`) && !pathname.endsWith(`${id}/about`) &&
                        sidebarTitle !== 'Form' && { name: 'Form', icon: 'information' },
                    sidebarTitle !== 'Sharing' && { name: 'Sharing', icon: 'share-variant' },
                    sidebarTitle !== 'Classes' && { name: 'Classes', icon: 'Attribute-class', iconType: 'af' },
                    (sidebarTitle !== 'Relations' && !isRelations) && { name: 'Relations', icon: 'relationships', iconType: 'af' },
                    sidebarTitle !== 'Attachments' && { name: 'Attachments', icon: 'attachment' },
                    sidebarTitle !== 'History' && { name: 'History', icon: 'history' },
                    isDigitalTwin && { name: 'Digital Twin', icon: 'hexagon' },
                    !pathname.endsWith(`${id}/process-map`) && sidebarTitle !== 'Process Map' && { name: 'Process Map', icon: 'sitemap' },
                    ...buildPrintTemplates({tasksAndProcesses, printTemplateRecords, type: 'task', details})
                ].filter(Boolean)}
            />
        );
    }

    @bind
    @memoize()
    buildBreadcrumbs(name, pathTitle) {
        const { id } = this.props;
        let breadcrumbs = [{ title: name || 'No Name' }];
        if (pathTitle) {
            breadcrumbs = [{ title: name || 'No Name', link: `/abox/tasks/${id}` }, { title: pathTitle }];
        }
        return <Breadcrumbs list={[{ link: '/abox/tasks', title: 'Tasks' }, ...breadcrumbs]} withGoBack />;
    }

    render(): Object {
        const {
            id, details, isLoading, match, sidebarTitle, windowWidth, subscriptions,
            openEntitySidebar, openProcessSidebar, openTaskSidebar, openRelationSidebar
        } = this.props;
        const { pathname } = this.props.location;
        const permissions = getPermissions(details && details.role);
        const { canEdit } = permissions || {};
        if (!isLoading && (isEmpty(details) || !permissions.canView)) {
            return <PageNotAllowed permissionError={true} title={`Task. (ID:${id})`} />;
        }

        const areDetailsWrong = id !== getStr(details, 'id');
        const loading = isLoading || areDetailsWrong;
        const { name, primary, formDefinitionVersion } = details || {};
        const { closedDate } = primary || {};
        const hasForm = formDefinitionVersion && formDefinitionVersion.definition;
        const isXs = windowWidth < mediaBreakpoints.sm;
        const unreadMessages = getUnreadMessages(id, subscriptions, 'task');
        const isOpen = !closedDate;
        const deleteStatus = isOpen ? null : 'closed';
        return (
            <Fragment>
                {loading && <Loader backdrop absolute />}
                {!areDetailsWrong && details && (
                    <PageTemplate
                        title={
                            deleteStatus ? (
                                `${name || 'No Name'} (${deleteStatus})`
                            ) : (
                                <EditableText
                                    displayText={name || 'No Name'}
                                    name="name"
                                    value={name}
                                    onSave={this.updateField}
                                    EditComponent={TextField}
                                    EditComponentProps={{ margin: 'none', clearable: false, variant: 'standard', fullWidth: false }}
                                />
                            )
                        }
                        menuItems={this.buildMenuItems(isXs, hasForm, isOpen)}
                        overflowHidden={true}
                    >
                        <Switch>
                            <Route path={`${match.url}/`} exact render={() => <Redirect to={`${match.url}/${hasForm ? 'form' : 'about'}`} />} />
                            <Route
                                path={'/abox/task/:id/form'}
                                render={() =>
                                    hasForm ? this.buildTaskFormTab(pathname, unreadMessages, hasForm, permissions) : <Redirect to={`${match.url}/about`} />
                                }
                            />
                            <Route path={'/abox/task/:id/relationships'}>
                                <Relations
                                    fromType={details.type}
                                    fromId={id}
                                    canEdit={canEdit}
                                    details={details}
                                    openEntitySidebar={openEntitySidebar}
                                    openTaskSidebar={openTaskSidebar}
                                    openProcessSidebar={openProcessSidebar}
                                    openRelationSidebar={openRelationSidebar}
                                    breadcrumbLine={this.buildBreadcrumbs(details?.name, 'Relationships')}
                                    sidebarActions={
                                        <>
                                            {this.renderActions(unreadMessages, permissions)}
                                            {this.dotMenu(sidebarTitle, pathname, hasForm, details?.classes)}
                                        </>
                                    }
                                />
                            </Route>
                            <Route
                                path={'/abox/task/:id/about'}
                                render={() => (
                                    <TaskAbout
                                        id={id}
                                        canEdit={canEdit}
                                        breadcrumbLine={this.buildBreadcrumbs(get(details, 'name'))}
                                        sidebarActions={
                                            <>
                                                {this.renderActions(unreadMessages, permissions)}
                                                {this.dotMenu(sidebarTitle, pathname, hasForm, details?.classes)}
                                            </>
                                        }
                                    />
                                )}
                            />
                            <Route
                                path={'/abox/task/:id/process-map'}
                                render={() => (
                                    <TaskProcessMapTab
                                        details={details}
                                        id={id}
                                        breadcrumbLine={this.buildBreadcrumbs(get(details, 'name'))}
                                        sidebarActions={
                                            <>
                                                {this.renderActions(unreadMessages, permissions)}
                                                {this.dotMenu(sidebarTitle, pathname, hasForm, details?.classes)}
                                            </>
                                        }
                                    />
                                )}
                            />
                            <Route path={`${match.url}/digital-twin`}>
                                <EntityDigitalTwin
                                    type={details?.type}
                                    entity={details}
                                    sidebarActions={[
                                        this.renderActions(unreadMessages, permissions, details),
                                        this.dotMenu(sidebarTitle, pathname, hasForm, details?.classes)
                                    ]}
                                    breadcrumbLine={this.buildBreadcrumbs(get(details, 'name'))}
                                />
                            </Route>
                        </Switch>
                    </PageTemplate>
                )}
            </Fragment>
        );
    }
}

export default connect(
    (state, ownProps) => ({
        id: getStr(ownProps, 'match.params.id', ''),
        isLoading: state.abox.task.sidebarDetails.isLoading,
        details: state.abox.task.sidebarDetails.data,
        profile: state.user.profile,
        windowWidth: state.global.window.width,
        subscriptions: state.chat.subscriptions.data,
        online: state.app.online,
        taskClosedReasons: state.app.affectliSettings.bpmn.taskClosedReasons,
        sidebarTitle: state.sidebar.title,
        printTemplateRecords: state.entities.printTemplates.list.records,
        tasksAndProcesses: state.classifications.classificationTasksAndProcesses.data || []
    }),
    {
        loadTaskSidebarDetails,
        closeTask,
        updateTask,
        openChat,
        openTaskSidebar,
        openEntitySidebar,
        openProcessSidebar,
        openRelationSidebar,
        loadClassificationTaskAndProcess,
        loadPrintTemplatesListView,
        setDocumentTitle
    }
)(withTheme(TaskRoute));
