/* @flow */

import { gql } from '@apollo/client';

export default gql`
    mutation createScriptMutation($record: ScriptCreateInput!){
        result: createScript(record: $record) {
           id
        }
    }
`;
