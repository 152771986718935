import React, { PureComponent } from 'react';
import { MdiIcon } from '@mic3/platform-ui';
import { ThemeProvider as MuiThemeProvider  } from '@material-ui/core/styles';
import styled, { ThemeProvider } from 'styled-components';
import uuidv1 from 'uuid/v1';

import { muiTheme } from 'app/themes/materialUi';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import theme from 'app/themes/theme.default';
import ListItem from 'app/components/molecules/List/ListItem';

import PipelineWidgetDotMenu from './PipelineWidgetDotMenu';

const ListItemStyled = styled(ListItem)`
`;

React.useLayoutEffect = React.useEffect; 

export const parseTailStatuses = (isError, node, details, pipelineMonitor, pipelineStatus, getCurrentConnectorStatus) => {
    const { group } = node;
    let isErrored = isError;
    let errorMessage = undefined;
    let status = null;
    if(pipelineMonitor && group === 'topic' && details?.primary['iot_pipeline/runtime_status'] && details.primary['iot_pipeline/runtime_status'].includes('startup error')) {
        isErrored = true;
        errorMessage = details?.primary['iot_pipeline/runtime_status'];
    }
    if(pipelineMonitor && group === 'connector' && pipelineStatus) {
        const currConnStatus = getCurrentConnectorStatus(pipelineStatus, node);
        if(currConnStatus) {
            isErrored = currConnStatus.status === 'deleted' 
            || currConnStatus?.execution?.status === 'Zombie'
            || (currConnStatus?.status === 'active' && !currConnStatus.execution);
            status = currConnStatus?.execution?.status || 'Stopped';
            errorMessage = currConnStatus?.error;                
        }
    }
    if(pipelineMonitor && !pipelineStatus && ['connector', 'topic'].includes(group)) {
        isErrored = true;
        errorMessage = group === 'connector' ? 'Stopped' : '';
        status = group === 'connector' ? 'Stopped' : '';
    }
    return { isErrored, errorMessage, status };
};

export default class PipelineWidget extends PureComponent {

    componentId = uuidv1();

    @bind
    @memoize()
    getCurrentConnectorStatus(pipelineStatus, node) {
        if(node.group === 'connector') {
            return pipelineStatus?.connectors?.find(conn => conn.label === node.title);
        }
        return null;
    }

    render() {
        const { node, onDotMenuClick, nodeId, details, pipelineMonitor, pipelineStatus } = this.props; 
        const { iconName, iconColor, iconType, title, group, isError, type } = node;
        const { isErrored, status } = parseTailStatuses(isError, node, details, pipelineMonitor, pipelineStatus, this.getCurrentConnectorStatus);

        return (
            <MuiThemeProvider theme={muiTheme}>
                <ThemeProvider theme={theme}>
                    <ListItemStyled
                        className={isErrored ? 'errored' : ''}
                        beforeTitle={status}
                        id={`${nodeId}`}
                        component={<MdiIcon name={iconName} type={iconType} color={iconColor} />}
                        actions={(
                            <PipelineWidgetDotMenu 
                                pipelineMonitor={pipelineMonitor}
                                pipelineStatus={pipelineStatus}
                                onDotMenuClick={onDotMenuClick} 
                                nodeId={nodeId} 
                                group={group}
                                componentId={this.componentId} 
                                node={node}
                                getCurrentConnectorStatus={this.getCurrentConnectorStatus}
                            />
                        )}
                        title={title}
                        subTitle={type}
                        raised
                    />
                </ThemeProvider>
            </MuiThemeProvider>
        );
    }
}
