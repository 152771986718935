/**
 *  Every time when there is an update for affectli icon set
 *  we need to generate its code for maps accordingly
 */
// First convert style.css into jss by using any online tools
// variable converted is a jss object
// map would be the set of icon codes
// const map = {};
// Object.entries(converted).forEach(([key, value]) => {
//     if(key?.startsWith('.af')){
//         const name = key?.split(':')[0]?.replace('.af-', '');
//         const c1 = value?.content?.replace('\\', '')?.replaceAll(/"/g,"");
//         const code = c1 ? parseInt(c1,16) : null;
//         if(code){
//             map[name] = code;
//         }
//     }
//
// })

export default {
    'Attribute-class': 59648,
    globe: 59649,
    Discussion: 59650,
    'Direct-message': 59651,
    'public-channel': 59652,
    'private-channel': 59653,
    thread: 59654,
    Grafana: 59655,
    gitlab: 59656,
    paypal: 59657,
    bitbucket: 59658,
    'sort-group-descending': 59659,
    'sort-group-ascending': 59660,
    'sort-descending': 59661,
    'sort-ascending': 59662,
    track: 59663,
    'brush-shape-circle': 59664,
    divider: 59665,
    'Workspaces-ven-diagram': 59666,
    enumeration: 59667,
    relationships: 59668,
    'relationship-one2many-B-1': 59669,
    'relationship-one2one-B': 59670,
    'relationship-many2one-B': 59671,
    'relationship-one2many-B': 59672,
    'Parent-x': 59673,
    Sibling: 59674,
    'primary-class': 59675,
    class: 59676,
    tower: 59677,
    'Search-entities': 59678,
    'map-search': 59679,
    'Map-Columbus': 59680,
    'Map-3D': 59681,
    google: 59682,
    Kanban: 59683,
    script: 59684,
    designer: 59685,
    marketplace: 59686,
    logo: 59687,
    messenger: 59688,
    analytics: 59689,
    'app-builder': 59690,
    charts: 59691,
    'classification-editor': 59692,
    'classification-tags': 59693,
    'config-builder': 59694,
    'config-builder-edit': 59695,
    'config-builder2': 59696,
    dashboard: 59697,
    'data-source-builder': 59698,
    'data-source': 59699,
    'duplicate-app': 59700,
    'event-dashboard': 59701,
    'event-monitor': 59702,
    events: 59703,
    'file-builder': 59704,
    'form-builder': 59705,
    'form-edit': 59706,
    form: 59707,
    hr: 59708,
    'job-profiling': 59709,
    maps: 59710,
    stream: 59711,
    'multiple-apps': 59712,
    'process-builder': 59713,
    processes: 59714,
    projects: 59715,
    'stream-file': 59716,
    'script-builder': 59717,
    'awareness-drawing': 59718,
    'situational-awareness': 59719,
    'situational-intelligence': 59720,
    table: 59721,
    tagngo: 59722,
    'task-list': 59723,
    task: 59724,
    teams: 59725,
    things: 59726,
    'user-management': 59727,
    'widget-builder': 59728,
    settings: 59729,
    admin: 59730,
    affectli: 59731,
    baidu: 59732,
    apps: 59733,
    uniE936: 59734,
    entities: 59735,
    'events-dashboard': 59736,
    'event-monitor-card': 59737,
    file: 59738,
    hcm: 59739,
    'hmc-briefcase': 59740,
    'intelligent-property': 59741,
    maybery: 59742,
    gab: 59743,
    organisations: 59744,
    people: 59745,
    Things: 59746,
    reports: 59747,
    'situational-awareness2': 59748,
    'situational-intelligence2': 59749,
    'situation-awareness-drawing': 59750,
    'tag-n-go': 59751,
    widgets: 59752,
    'process-type-script': 59777
};
