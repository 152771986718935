/* @flow */

import jsonexport from 'jsonexport';
import moment from 'moment';
import FileSaver from 'file-saver';
import { set } from 'app/utils/lo/lo';

/**
 * download list of user
 * @param name is the filename to download
 * @param rows is the data object that download
 */
const saveCsv = (name: string, rows: Array<Object>) => {
    if (rows && rows.length) {
        jsonexport(rows, { headers: Object.keys(rows[0]) }, (error, csv) => {
            if(error) {
                // eslint-disable-next-line no-console
                return console.log('[ExportCSV]', error);
            };
            const filename = `${ name } ${moment().format('DD.MM.YYYY HH.mm.ss')}.csv`;
            const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
            FileSaver.saveAs(blob, filename);
        });
    }
};

/**
 * download a text file
 * @param name is the filename to download
 * @param body is the body of the text file
 * @param ext is the extensions supported
 */
const saveText = (name: string, body: string, ext: '.txt' | '.ics' | '.xml' | '.js') => {
    if (body) {
        const filename = `${ name } ${moment().format('DD.MM.YYYY HH.mm.ss')}${ext}`;
        const blob = new Blob([body], { type: 'text/plain;charset=utf-8' });
        FileSaver.saveAs(blob, filename);
    }
};

/**
 * download a json file
 * @param name is the filename to download
 * @param body is the body of the text file
 */
const saveJSON = (name: string, json: Object) => {
    if (json) {
        const filename = `${ name } ${moment().format('DD.MM.YYYY HH.mm.ss')}.json`;
        const blob = new Blob([JSON.stringify(json, null, 2)], { type: 'application/json;charset=utf-8' });
        FileSaver.saveAs(blob, filename);
    }
};

/**
 * download a text file
 * @param name is the filename to download
 * @param body is the body of the text file
 * @param ext is the extensions supported
 */
const saveBlob = (name: string, body: string, ext: string, type: string) => {
    if (body) {
        const filename = `${ name } ${moment().format('DD.MM.YYYY HH.mm.ss')}${ext}`;
        const blob = new Blob([body], { type: type || 'text/plain;charset=utf-8' });
        FileSaver.saveAs(blob, filename);
    }
};

function readTextFile(file) {
    return new Promise((resolve) => {

        const reader = new FileReader();
        reader.onloadend = () => {

            var rawFile = new XMLHttpRequest();
            rawFile.open('GET', reader.result, false);
            rawFile.onreadystatechange = function ()
            {
                if(rawFile.readyState === 4)
                {
                    if(rawFile.status === 200 || rawFile.status === 0)
                    {
                        var allText = rawFile.responseText;
                        resolve(allText);
                    }
                }
            };
            rawFile.send(null);
        };
        reader.readAsDataURL(file);

    });
}

function readCSVFile(file) {
    return new Promise(async (resolve) => {
        const str = await readTextFile(file);
        const arr = [];
        let quote = false;  // 'true' means we're inside a quoted field

        // Iterate over each character, keep track of current row and column (of the returned array)
        for (let row = 0, col = 0, c = 0; c < str.length; c++) {
            const cc = str[c], nc = str[c+1];        // Current character, next character
            arr[row] = arr[row] || [];             // Create a new row if necessary
            arr[row][col] = arr[row][col] || '';   // Create a new column (start with empty string) if necessary

            // If the current character is a quotation mark, and we're inside a
            // quoted field, and the next character is also a quotation mark,
            // add a quotation mark to the current column and skip the next character
            if (cc == '"' && quote && nc == '"') { arr[row][col] += cc; ++c; continue; }

            // If it's just one quotation mark, begin/end quoted field
            if (cc == '"') { quote = !quote; continue; }

            // If it's a comma and we're not in a quoted field, move on to the next column
            if (cc == ',' && !quote) { ++col; continue; }

            // If it's a newline (CRLF) and we're not in a quoted field, skip the next character
            // and move on to the next row and move to column 0 of that new row
            if (cc == '\r' && nc == '\n' && !quote) { ++row; col = 0; ++c; continue; }

            // If it's a newline (LF or CR) and we're not in a quoted field,
            // move on to the next row and move to column 0 of that new row
            if (cc == '\n' && !quote) { ++row; col = 0; continue; }
            if (cc == '\r' && !quote) { ++row; col = 0; continue; }

            // Otherwise, append the current character to the current column
            arr[row][col] += cc;
        }

        // converting to array of objects
        const columns = arr.shift();
        const arrOfObjects = [];
        columns.forEach((column, cindex) => {
            arr.forEach((data, dindex) => {
                if(!arrOfObjects[dindex]) { arrOfObjects.push({});}

                try {
                    const jsonedData = JSON.parse(data[cindex]);
                    arrOfObjects[dindex] = set(arrOfObjects[dindex], column, jsonedData);
                } catch(err) {
                    arrOfObjects[dindex] = set(arrOfObjects[dindex], column, data[cindex]);
                }

            });
        });
        return resolve(arrOfObjects);
    });

}

export {
    saveCsv,
    saveText,
    saveBlob,
    saveJSON,
    readTextFile,
    readCSVFile,
};
