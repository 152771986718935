/* @flow */
export default (type) => {
    let operation = 'entityChatPostFile';
    
    if (type === 'class') {
        operation = 'classChatPostFile';
    } else if (type === 'team') {
        operation = 'teamChatPostFile';
    }

    return `
        mutation attachmentChatMutation($id: ID!, $message: String, $file: Upload!, $description: String) {
            data: ${operation}(id: $id, message: $message, file: $file, description: $description${!['class', 'team'].includes(type) ? `, type: "${type}"` : ''}) {
                id
                file {
                    id
                    name
                    type
                }
            }
        }
    `;
};