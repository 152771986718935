/* @flow */

import React, { useState, useEffect, useCallback } from 'react';
import { MdiIcon, IconButton } from '@mic3/platform-ui';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import ExpansionCard from 'app/components/molecules/ExpansionCard/ExpansionCard';
import { isDefined } from 'app/utils/utils';

import FormField from 'app/containers/Designer/Form/components/FormField';

const fieldify = (Component: Object) => (props: Object) => <FormField {...props} Component={Component} />;

const Panel = fieldify(ExpansionCard);

const FormPanel = React.forwardRef((props, ref) => {
    const {
        isValidForm, addDividers, editablePanel, components, data, onEndChanges, avoidValidation, 
        onStartChanges, inherited, onChange, onFormTouched, expanded, disabled, type, ...panelProps
    } = props;
    const [open, setOpen] = useState(!!expanded);
    const [editable, setEditable] = useState( type === 'panel' ? true : false);

    useEffect(() => {
        isDefined(expanded) && setOpen(expanded);
    }, [expanded]);
    const handleEditMode = useCallback((evnt) => {
        evnt.preventDefault();
        evnt.stopPropagation();
        setEditable(!editable);
    }, [editable]);
    const toggleOpen = useCallback(() => {
        setOpen(!open);
    }, [open]);
    const handleOpen = useCallback(() => {
        setOpen(true);
    }, []);
    return (
        <Panel
            {...panelProps}
            type={type}
            inherited={inherited}
            data={data}
            onChange={toggleOpen}
            expanded={open}
            actions={(editablePanel && type !== 'panel')
             && (
                 <IconButton onClick={handleEditMode}>
                     <MdiIcon name="pencil" />
                 </IconButton>
             )}
        >
            <FormGenerator
                isValidForm={isValidForm}
                onStartChanges={onStartChanges}
                onEndChanges={onEndChanges}
                ref={ref}
                addDividers={addDividers}
                editablePanel={editablePanel}
                editable={editable}
                components={components}
                data={data}
                context={props.context}
                onChange={onChange}
                onFormTouched={onFormTouched}
                onError={handleOpen}
                root={false}
                inherited={inherited}
                disabled={disabled}
                avoidValidation={avoidValidation}
            />
        </Panel>
    );
});

export default FormPanel;
