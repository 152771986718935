/* @flow */

import { gql } from '@apollo/client';

export default gql`
query organisationProfileQuery {
  companyProfile {
    name
    fullName
    contact {
      type
      id
      name
      image
    }
  }
}
`;
