import React, { useState, useCallback, useRef, useEffect } from 'react';
import uuidv1 from 'uuid/v1';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Typography, CircularProgress, Checkbox,  Link as MuiLink } from '@mic3/platform-ui';
import { showToastr } from 'store/actions/app/appActions';
import { saveComponentState } from 'store/actions/component/componentActions';

import { makeAttributeIndexed, removeIndexAttribute } from 'store/actions/classifications/classificationsActions';
import { loadClassification } from 'store/actions/classifications/classificationsActions';
import { setSelectedElement } from 'store/actions/form/formActions';
import { loadBackgroundJobStatus } from 'store/actions/entities/entitiesActions';
import { findAttribute } from 'app/utils/classification/classificationUtils';



const WAIT_INTERVAL = 5000;

const RemoveButton = styled(Button)`
    background-color: red !important;
`;

const IndexAttribute = (props) => {
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);
    const [isUnique, setUnique] = useState(false);
    const [bgId, setJobId] = useState(null);
    const [isJobFailed, setFailed] = useState(false);
    const timerRef = useRef(null);

    const { properties, disabled } = props;
    const { name: primaryAttribute } = properties || {};
    const classification = useSelector(state => state.classifications.details.data || {});
    const formDesignerState = useSelector(state => state.component.state.FormDesigner || {});
    const selectedElement = useSelector(state=> state.form.selectedElement || {});
    const { uri: entityType, entityPrimaryIndexes } = classification;
    const alreadyIndexed = entityPrimaryIndexes?.find(t => t?.state === 'created' && t?.primaryAttribute === properties?.name);


    useEffect(() => {
        // Clear the interval when the component unmounts
        return () => clearTimeout(timerRef.current);
    }, []);

    useEffect(() => {
        setUnique(!!alreadyIndexed?.isUnique);
    }, [alreadyIndexed?.isUnique]);

    const reloadClassData = useCallback(
        (jobId) => {
            if (jobId) {
                setJobId(jobId);
                timerRef.current = setTimeout(async () => {
                    const bgResult = await loadBackgroundJobStatus(jobId);
                    if(bgResult instanceof Error) return setLoading(false);
                    const { status } = bgResult?.primary || {};
                    if (status === 'completed') {
                        await dispatch(loadClassification(classification?.id));
                        setLoading(false);
                    } else if (status === 'failed') {
                        dispatch(showToastr({ severity: 'error', detail: 'There is some error in executing the job. Please try again' }));
                        await setLoading(false);
                        await setFailed(true);
                    } else {
                        reloadClassData(jobId);
                    }
                    await  dispatch(setSelectedElement(selectedElement));
                    await dispatch(saveComponentState('FormDesigner', { ...formDesignerState, isLoading: false }));
                 
                }, WAIT_INTERVAL);
            }
        },
        [dispatch, classification, formDesignerState, selectedElement]
    );

    const handleIndexAttribute = useCallback(async (makeIndex = true) => {
        await dispatch(saveComponentState('FormDesigner', { ...formDesignerState, isLoading: true }));
        setLoading(true);
        setJobId(null);
        setFailed(false);
        const options = { entityType, primaryAttribute, unique: isUnique };
        const result = makeIndex ? await dispatch(makeAttributeIndexed(options)) : await dispatch(removeIndexAttribute(options));
        if(result instanceof Error) {
            await dispatch(saveComponentState('FormDesigner', { ...formDesignerState, isLoading: false }));
            return setLoading(false);
        } 
        await reloadClassData(result?.id);
    }, [dispatch, primaryAttribute, entityType, reloadClassData, setJobId, isUnique, formDesignerState]);

    const removeIndex = useCallback(async () => {
        await handleIndexAttribute(false);
    }, [handleIndexAttribute]);

    const toggleCheckbox = useCallback(() => {
        setUnique(!isUnique);
    }, [setUnique, isUnique]);

    if (alreadyIndexed?.state === 'created') {
        return (
            <>
                <Checkbox label='Unique Index' value={isUnique} disabled />
                <br />
                <RemoveButton onClick={removeIndex}>{isLoading ? <CircularProgress size={24} /> : 'Remove Indexing'}</RemoveButton>
                {isLoading && bgId ? (
                    <Typography color='textSecondary'>
                        Attribute indexing is being removed. Go to <Link to={`/abox/background-job/${bgId}`}>Background job</Link> to see
                        the progress of this item.
                    </Typography>
                ) : (
                    isJobFailed && (
                        <Typography color='textSecondary'>
                            The <Link to={`/abox/background-job/${bgId}`}>Background job</Link> is failed. Go to its details to see the
                            error logs.
                        </Typography>
                    )
                )}
            </>
        );
    }

    return (
        <>
            {isLoading ? <CircularProgress size={24} /> : 
                <>
                    <Checkbox disabled={disabled} label="Unique Index" value={isUnique} onChange={toggleCheckbox} /><br />
                    <Button disabled={disabled} onClick={handleIndexAttribute}>Index Attribute</Button>
                </>
            }
            <br />
            <br />
            {isLoading && bgId ? 
                <Typography color="textSecondary">
                    Attribute is being indexed. Go to <Link to={`/abox/background-job/${bgId}`}>Background job</Link> to see the progress of this item.
                </Typography> :
                <Typography color="textSecondary">
                    Indexing enables filters on attributes. Note that too many indexed fields decreases performance.&nbsp;
                    <MuiLink  href = {`https://support.affectli.com/en/classattributes`} target="_blank" >
                    Learn more
                    </MuiLink> 
                    .
                </Typography>
            }
        </>
    );
};

export const buildIndexAttributeDefinition = (settingsValues) => {
    if (settingsValues?.viewType !== 'class' || !settingsValues?.classification?.primary) {
        return null;
    }
    const attributeDefinition = findAttribute(settingsValues?.classification?.formDefinition, settingsValues?.properties?.name);
    return {
        header: 'Indexing',
        children: [
            {
                type: 'custom',
                properties: {
                    label: '',
                    cid: uuidv1(),
                    Component: props =>
                        attributeDefinition ? (
                            <IndexAttribute
                                {...settingsValues}
                                {...props}
                                disabled={settingsValues.properties.static || attributeDefinition?.properties?.static}
                            />
                        ) : (
                            <Typography color='textSecondary'>
                                You need to save this component to the form definition to index this attribute. Please click on save button.
                            </Typography>
                        ),
                    noBackground: 1,
                },
            },
        ],
    };
};
    

export default IndexAttribute;
