/* @flow */

import { gql } from '@apollo/client';

export default gql`
query widgetTasksCountByStatusQuery(
  $open: [JSON], $openExclude: [JSON],
  $closed: [JSON], $closedExclude: [JSON]
) {
  open: count(dataType: "task", filterBy: $open, excludeBy: $openExclude)
  closed: count(dataType: "task", filterBy: $closed, excludeBy: $closedExclude)
}
`;
