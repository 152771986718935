/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mic3/platform-ui';
import { connect } from 'react-redux';

import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { runScript } from 'store/actions/abox/backgroundJobActions';
import history from 'store/History';
import { _getData } from 'app/utils/redux/action-utils';

class AddPipeline extends PureComponent<Object, Object> {

    static propTypes = {
        runScript: PropTypes.func.isRequired,
        isLoading: PropTypes.bool,
    };

    formRef = React.createRef();
    state = { data: { version: null }};

    @bind
    @memoize()
    components(scriptId) {
        return [
            {
                type: 'text',
                properties: { label: 'Job name', name: 'name'},
                constraints: { required: true }
            },
            {
                type: 'textarea',
                properties: { label: 'Description', name: 'description'},
            },
            {
                type: 'scriptTypeahead',
                properties: { label: 'Script', name: 'script', valueField: 'id' },
                constraints: { required: true }
            },
            {
                type: 'scriptVersionTypeahead',
                properties: { 
                    label: 'Version number', 
                    name: 'version',
                    scriptId,
                    valueField: 'value'
                },
            },
            {
                type: 'textarea',
                properties: {
                    parseAs: 'javascript',
                    modal: true,
                    title: 'Input parameters',
                    label: 'Input parameters',
                    name: 'properties.onChange',
                    useCodeEditor: true,
                }
            },
            {
                type: 'uploadFile',
                properties: { label: 'Input file', name: 'file' },
            }, 
        
        ];}

    @bind
    onChange(data) {
        this.setState({ data });
    }

    @bind
    async onFormSubmit(event: Event) {
        event.preventDefault();
        const { data, errors } = await this.formRef.current.isValidForm();

        if (!errors) {
            const result = await this.props.runScript({
                ...data, 
                name:data.name.trim()
            });
            
            const { id, errors } = _getData(result)?.id ? _getData(result) : result;
            if (id) {
                history.push(`/background-job/${id}/logs`);
            } else if (errors) {
                console.error(get(errors, '[0].message') || ''); // eslint-disable-line no-console
            }

        }
    };

    render(): Object {
        const { isLoading, onClose } = this.props;
        const { data } = this.state;
        return (
            <ModalDialog
                bgcolor={'rgba(40, 51, 75, 1)'}
                showDividerAfterTitle
                withoutClose
                title="Create New Background Job"
                onClose={onClose}
                isLoading={isLoading}
                actions={(
                    <>
                        <Button variant="text" onClick={onClose}>
                      cancel
                        </Button>
                        <Button onClick={this.onFormSubmit}>
                      Create
                        </Button>
                    </>
                )}
            >
                <FormGenerator
                    data={data}
                    onChange={this.onChange}
                    components={this.components(data?.script)}
                    ref={this.formRef}
                />
            </ModalDialog>
        );
    }
}

export default connect(state => ({
    isLoading: state.entities.entities.isLoading,
}), {
    runScript
})(AddPipeline);
