/* @flow */

import { loadData, mutateData, queryData } from 'app/utils/redux/action-utils';

import updateTeamMutation from 'graphql/teams/updateTeamMutation';
import createTeamMutation from 'graphql/teams/createTeamMutation';
import teamAddUsersMutation from 'graphql/teams/teamAddUsersMutation';
import teamRemoveUsersMutation from 'graphql/teams/teamRemoveUsersMutation';
import loadTeamsQuery from 'graphql/teams/loadTeamsQuery';
import loadTeamQuery from 'graphql/teams/loadTeamQuery';
import loadAvatarQuery from 'graphql/teams/loadAvatarQuery';
import loadTeamsTypeaheadQuery from 'graphql/teams/loadTeamsTypeaheadQuery';
import loadTeamsTypeaheadPermissionsQuery from 'graphql/teams/loadTeamsTypeaheadPermissionsQuery';
import loadRelatedWorkspacesQuery from 'graphql/workspaces/loadRelatedWorkspacesQuery';
import { addTeamOwnersMutation, removeTeamOwnersMutation } from 'graphql/teams/teamOwnerMutations';
import teamHistoryQuery from 'graphql/teams/teamHistoryQuery';
import { getAvatar } from 'app/utils/avatar/avatar';
import OptionsBuilder from 'app/utils/api/OptionsBuilder';
import { PUBLIC_TEAM_ID, SUPER_USERS_TEAM_ID } from 'app/config/config';
import { get } from 'app/utils/lo/lo';
import loadRelatedWorkspacesTeamQuery from 'graphql/workspaces/loadRelatedWorkspacesTeamQuery';

export const LOAD_TEAMS_STARTED = '@@affectli/admin/teams/LOAD_TEAMS_STARTED';
export const LOAD_TEAMS = '@@affectli/admin/teams/LOAD_TEAMS';

export const LOAD_TEAM_STARTED = '@@affectli/admin/teams/LOAD_TEAM_STARTED';
export const LOAD_TEAM = '@@affectli/admin/teams/LOAD_TEAM';

export const UPDATE_TEAM_STARTED = '@@affectli/admin/teams/UPDATE_TEAM_STARTED';
export const UPDATE_TEAM = '@@affectli/admin/teams/UPDATE_TEAM';

export const CREATE_TEAM_STARTED = '@@affectli/admin/teams/CREATE_TEAM_STARTED';
export const CREATE_TEAM = '@@affectli/admin/teams/CREATE_TEAM';

export const LOAD_AVATAR_STARTED = '@@affectli/admin/teams/LOAD_AVATAR_STARTED';
export const LOAD_AVATAR = '@@affectli/admin/teams/LOAD_AVATAR';

export const LOAD_TEAMS_TYPEAHEAD_STARTED = '@@affectli/admin/teams/LOAD_TEAMS_TYPEAHEAD_STARTED';
export const LOAD_TEAMS_TYPEAHEAD = '@@affectli/admin/teams/LOAD_TEAMS_TYPEAHEAD';

export const LOAD_TEAM_HISTORY_STARTED = '@@affectli/admin/teams/LOAD_TEAM_HISTORY_STARTED';
export const LOAD_TEAM_HISTORY = '@@affectli/admin/teams/LOAD_TEAM_HISTORY';

export const LOAD_RELATED_WORKSPACES_STARTED = '@@affectli/admin/teams/LOAD_RELATED_WORKSPACES_STARTED';
export const LOAD_RELATED_WORKSPACES = '@@affectli/admin/teams/LOAD_RELATED_WORKSPACES';

export const LOAD_TEAMS_TYPEAHED_STARTED = '@@affectli/admin/teams/LOAD_TEAMS_TYPEAHED_STARTED';
export const LOAD_TEAMS_TYPEAHED = '@@affectli/admin/teams/LOAD_TEAMS_TYPEAHED';

export const loadTeams = ({ options, withWorkspaces }: Object) => (dispatch, getState) => {
    const profile = getState().user.profile;
    const filter = profile.isAdmin
        ? { or: [
            { field: 'isSystem', op: '=', value: false },
            { field: 'id', op: '=', value: SUPER_USERS_TEAM_ID },
            { field: 'id', op: '=', value: PUBLIC_TEAM_ID }
        ]}
        : {  or: [
            { field: 'id', op: '=', value: PUBLIC_TEAM_ID },
            { field: 'isSystem', op: '=', value: false }]};

    const variables = new OptionsBuilder(options)
        .filter(filter)
        .defaultStartStopIndexs(0, 30)
        .build();

    const modifiedPayload = async (payload) => {
        if (!withWorkspaces) {
            return payload;
        }
        
        const teams = payload.records || [];
        const teamsWorkspaces = [];
        
        const teamWorkspacesMap = teams.map((team) => {
            teamsWorkspaces.push({ ...team });
            const variables = new OptionsBuilder()
                .filter({ field: 'teams.team.id', op:'=', value: team.id })
                .filter({ field: 'active', op:'=', value: true })
                .filter({ field: 'isSystem', op:'=', value: false })
                .defaultStartStopIndexs(0, 999)
                .build();
            return queryData(loadRelatedWorkspacesTeamQuery, variables);
        });

        await Promise.all(teamWorkspacesMap).then((response) => {
            teamsWorkspaces.map((team, i) => {
                const resp = response[i];
                const records = get(resp, 'data.records', []);
                team.children = records.map((workspace) => {
                    const teamFound = workspace.teams.find(workspaceTeam => workspaceTeam.team.id === team.id);
                    return { ...workspace, role: teamFound.role };
                });
                return team;
            });
        });

        return { ...payload, records: teamsWorkspaces };
    };

    return loadData(LOAD_TEAMS_STARTED, LOAD_TEAMS, loadTeamsQuery, modifiedPayload)(variables)(dispatch, getState);
};

export const loadTeam = (id: string) => loadData(LOAD_TEAM_STARTED, LOAD_TEAM, loadTeamQuery)({ id });

export const createTeam = (record: Object) => mutateData(CREATE_TEAM_STARTED, CREATE_TEAM, createTeamMutation, 'Team added.')({ record });

export const updateTeam = (record: Object) => (dispatch: Function, getState: Function) => {
    return mutateData(
        UPDATE_TEAM_STARTED,
        UPDATE_TEAM,
        updateTeamMutation,
        'Team updated.'
    )({ record })(dispatch, getState).then((result) => {
        if (result instanceof Error) return;
        result && result.id && dispatch({ type: LOAD_TEAM, payload: result });
    });
};

export const addTeamUsers = (id: String, users: Array<String>) => {
    return mutateData(
        LOAD_TEAM_STARTED,
        LOAD_TEAM,
        teamAddUsersMutation,
        `User${users && users.length > 1 ? 's' : ''} added to team.`
    )({ id, users });
};

export const removeTeamUsers = (id: String, users: Array<String>) => {
    return mutateData(
        LOAD_TEAM_STARTED,
        LOAD_TEAM,
        teamRemoveUsersMutation,
        `User${users && users.length > 1 ? 's' : ''} removed from team.`
    )({ id, users });
};

export const addTeamOwners = (id: Object, owners: Array<string>) => {
    return mutateData(
        LOAD_TEAM_STARTED,
        LOAD_TEAM,
        addTeamOwnersMutation,
        `Owner${owners && owners.length > 1 ? 's' : ''} added to team.`
    )({ id, owners });
};

export const removeTeamOwners = (id: Object, owners: Array<string>) => {
    return mutateData(
        LOAD_TEAM_STARTED,
        LOAD_TEAM,
        removeTeamOwnersMutation,
        `Owner${owners && owners.length > 1 ? 's' : ''} removed from team.`
    )({ id, owners });
};

export const loadAvatar = id => (dispatch: Function, getState: Function) => {
    const avatar = getAvatar(getState(), 'team', id);
    if (avatar && (avatar.isLoading || avatar.loadedAt > Date.now() - 60 * 60 * 1000)) {
        // if we are loding the avatar or it is already loaded and it is stored less then 1 hour ago
        // we return the data in the state without calling the service
        return Promise.resolve(avatar);
    }
    return loadData(LOAD_AVATAR_STARTED, LOAD_AVATAR, loadAvatarQuery)({ id })(dispatch, getState);
};

export const loadTeamsTypeahead = loadData(LOAD_TEAMS_TYPEAHED_STARTED, LOAD_TEAMS_TYPEAHED, loadTeamsTypeaheadQuery);
export const loadTeamsTypeaheadPermissions = loadData(LOAD_TEAMS_TYPEAHED_STARTED, LOAD_TEAMS_TYPEAHED, loadTeamsTypeaheadPermissionsQuery);

export const loadTeamHistory = (id: string, options: Object) => (dispatch, getState) => {
    const variables = new OptionsBuilder(options)
        .filter({ field: 'team.id', op: '=', value: id })
        .build();
    return loadData(LOAD_TEAM_HISTORY_STARTED, LOAD_TEAM_HISTORY, teamHistoryQuery)(variables)(dispatch, getState);
};

export const loadRelatedWorkspaces = (id: String) => {
    const variables = new OptionsBuilder()
        .filter({ field: 'teams.team.id', op:'=', value: id })
        .filter({ field: 'active', op:'=', value: true })
        .filter({ field: 'isSystem', op:'=', value: false })
        .defaultStartStopIndexs(0, 999)
        .build();
    return loadData(LOAD_RELATED_WORKSPACES_STARTED, LOAD_RELATED_WORKSPACES, loadRelatedWorkspacesQuery)(variables);
};
