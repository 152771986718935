/* @flow */

import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {  Popover, Tooltip, IconButton, MdiIcon } from '@mic3/platform-ui';
import { withRouter } from 'react-router';

import { get } from 'app/utils/lo/lo';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { getRelationshipIdFromTask } from 'app/utils/boards/utils';
import { formatOptionsForTask } from 'app/utils/formatter/graphql-formatter';
import {
    setSelectedBoard,
    setBoardColumnState,
    setBoardColumnLoading,
    loadCustomEntityBoardLabels,
    loadCustomEntityBoardLabelTasks,
    loadCustomEntityBoardLabelTask,
    moveTaskToOtherColumn,
    moveTaskToOtherColumnDone,
    deleteTaskRelationFromColumn,
    createRelationEntityToTask,
    clearBoardTasks,
    loadRelationDefinitionTaskStatus,
    removeTaskfromColumn,
    deleteCoRelationColumnFromBoard,
    removeColumnFromBoard,
    reorderColumnPosition,
    updateRelationColumnToBoard,
    resetBoards,
    sortBoardTasks
} from 'store/actions/abox/old_boardsActions';
import { saveBoardPreferences } from 'store/actions/admin/usersActions';
import { openTaskSidebar } from 'store/actions/abox/taskSidebarActions';

import { FiltersBoard, BoardsLandingSection } from 'app/components/ABox/OldBoards/style';
import PageTemplate from 'app/components/templates/PageTemplate';
import Loader from 'app/components/atoms/Loader/Loader';
import BoardsContent from 'app/components/ABox/OldBoards/BoardsContent';
import BoardsColumn from 'app/components/ABox/OldBoards/BoardsColumn';
import BoardsAddTaskModal from './BoardsAddTaskModal';
import Icon from 'app/components/atoms/Icon/Icon';
import Text from 'app/components/atoms/Text/Text';
import BoardsAddColumnModal from './BoardsAddColumnModal';
import Menu from 'app/components/molecules/Menu/Menu';
import MenuItem from 'app/components/molecules/Menu/MenuItem';
import { FormGeneratorStyled, StyledContentArea, StyledButton } from 'app/components/ABox/OldBoards/style';
import { filterDefinitions, defaultFilters, defaultOrder } from 'app/components/organisms/TasksView/TasksView';
import PageNotAllowed from 'app/containers/ErrorPages/PageNotAllowed';
import { setDocumentTitle } from 'store/actions/app/appActions';

export const groupByOpts = {
    'assignee.name': 'Assignee',
    'priority': 'Priority',
    'none': 'None'
};

class Boards extends PureComponent<Object, Object> {
    static propTypes = {
        setSelectedBoard: PropTypes.func,
        setBoardColumnState: PropTypes.func,
        setBoardColumnLoading: PropTypes.func,
        loadCustomEntityBoardLabels: PropTypes.func,
        loadCustomEntityBoardLabelTasks: PropTypes.func,
        loadCustomEntityBoardLabelTask: PropTypes.func,
        deleteTaskRelationFromColumn: PropTypes.func,
        createRelationEntityToTask: PropTypes.func,
        clearBoardTasks: PropTypes.func,
        loadRelationDefinitionTaskStatus: PropTypes.func,
        moveTaskToOtherColumn: PropTypes.func,
        moveTaskToOtherColumnDone: PropTypes.func,
        removeTaskfromColumn: PropTypes.func,
        userProfile: PropTypes.object,
        preferences: PropTypes.object,
        boardColumns: PropTypes.array,
        boardColumnTasks: PropTypes.object,
        boardColumnState: PropTypes.object,
        selectedBoard: PropTypes.object,
        isLoadingBoardColumns: PropTypes.bool,
        isLoading: PropTypes.bool,
        deleteCoRelationColumnFromBoard: PropTypes.func,
        saveBoardPreferences: PropTypes.func,
        removeColumnFromBoard: PropTypes.func,
        reorderColumnPosition: PropTypes.func,
        updateRelationColumnToBoard: PropTypes.func,
        sortBoardTasks: PropTypes.func.isRequired,
        details: PropTypes.object,
    };

    static defaultProps = {
        boardColumns: [],
        boardColumnState: null,
        boardColumnTasks: null,
        isLoadingBoardColumns: false,
        isLoading:false,
        selectedBoard: null
    };

    state = {
        options: {},
        isTaskModalOpen: false,
        selectedColumn: null,
        maxColumnOrder: 0,
        disableAddTask: false,
        groupBy: null,
        isColumnModalOpen: false,
        disableAddColumn: false,
        feFilters: [],
        groupByAnchorEl: null,
    };

    searchBar = ['name', 'id'];
    defaultFilters = defaultFilters;
    defaultOrder = defaultOrder;
    filterDefinitions = filterDefinitions;

    componentDidUpdate(prevProps){
        const { selectedBoard, setDocumentTitle, } = this.props;
        const name = selectedBoard?.name;
        const preName = prevProps.selectedBoard?.name;
        if(name && name !== preName){
            setDocumentTitle(name);
        }
    }

    @bind
    onChangeBoards(data: Object) {
        const {
            setSelectedBoard,
            loadCustomEntityBoardLabels,
            setBoardColumnState,
            clearBoardTasks,
            preferences,
            saveBoardPreferences,
            resetBoards
        } = this.props;
        const board = data ? data.board : null;

        setSelectedBoard(board);

        if (!board) {
            resetBoards();
        } else {
            setBoardColumnState(null);
            clearBoardTasks();
            loadCustomEntityBoardLabels({ entityId: board.id });
        }

        const userPreferences = {
            ...preferences,
            boards: {
                board
            }
        };

        saveBoardPreferences(userPreferences);
    }

    @bind
    loadBoardTasks(columns: Object[], isFilterUpdated: boolean) {
        const {
            setBoardColumnState,
            isReorderedBoardColumns,
            isAddedBoardColumns,
            isRemovedBoardColumns
        } = this.props;
        if ((isReorderedBoardColumns || isRemovedBoardColumns) && !isFilterUpdated) {
            return;
        }

        if (columns.length > 0) {
            setBoardColumnState(columns);
        }

        for (let i = 0; i < columns.length; i++) {
            if (((isAddedBoardColumns && columns[i].isAdded) || !isAddedBoardColumns) || isFilterUpdated) {
                this.loadBoardTasksSingle(columns[i]);
            }
        }
    }

    @bind
    loadBoardTasksSingle(column: Object) {
        const { loadCustomEntityBoardLabelTasks, setBoardColumnLoading, userProfile } = this.props;
        const { options } = this.state;
        const formattedOptions = formatOptionsForTask(options, userProfile);
        setBoardColumnLoading(column.id, true);
        loadCustomEntityBoardLabelTasks(column.id, formattedOptions).then((response: Object) => {
            if (response instanceof Error) return;
            const { entityId } = response;
            if (entityId) {
                setBoardColumnLoading(entityId, false);
            }
        });
    }

    @bind
    closeTaskModal() {
        this.setState({
            isTaskModalOpen: !this.state.isTaskModalOpen
        });
    }

    @bind
    openTaskModal(column: Object) {
        this.setState({
            isTaskModalOpen: !this.state.isTaskModalOpen,
            selectedColumn: column
        });
    }

    @bind
    setOptions(options: Object) {
        this.setState({ options: options });
    }

    @bind
    setMaxColumnOrder(order: number) {
        this.setState({ maxColumnOrder: order });
    }

    @bind
    onDragEnd(result: Object) {
        if (result.type === 'TASK') {
            this.moveTask(result);
        } else if (result.type === 'COLUMN') {
            this.moveColumn(result);
        }
    }

    async moveTask(result: Object) {
        const {
            moveTaskToOtherColumn,
            deleteTaskRelationFromColumn,
            createRelationEntityToTask,
            boardColumnTasks,
            removeTaskfromColumn,
            moveTaskToOtherColumnDone,
            loadCustomEntityBoardLabelTask
        } = this.props;
        const { destination, source, draggableId } = result;
        const tasks = { ...boardColumnTasks };
        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId) {
            return;
        }

        const newColumnTasks = [ ...tasks[`column${source.droppableId}`] ];
        const droppableTasks = [ ...tasks[`column${destination.droppableId}`] ];
        const task = newColumnTasks.splice(source.index, 1);
        const taskId = get(task[0], 'id');
        const relationship = getRelationshipIdFromTask(task[0].relations, source.droppableId);
        const relationshipDefId = get(relationship, 'relation.relationDefinition.id');

        const taskAlreadyInColumn = droppableTasks.find((task) => {
            return task.id === taskId;
        });

        //If task is already in target column, just remove the task from source column
        if (taskAlreadyInColumn) {
            removeTaskfromColumn(source.droppableId, source.index, destination.droppableId);
        } else {
            moveTaskToOtherColumn(source.droppableId.toString(), destination.droppableId.toString(), draggableId, destination.index, source.index);
        }

        // Remove the task relationship from source column on the backend
        const response = await deleteTaskRelationFromColumn(get(relationship, 'relation.id'));
        if (response instanceof Error) return;
        if (taskAlreadyInColumn) {
            moveTaskToOtherColumnDone(destination.droppableId, taskId);
            return;
        }

        // Create task relationship to destination column on the backend
        const { primary } = task[0];
        const createResponse = await createRelationEntityToTask({
            relationDefinition: relationshipDefId,
            entity: { type: get(primary, 'closedDate') ? 'closedtask' : 'opentask', id: taskId },
            relatedEntity: { type: 'custom', id: destination.droppableId }
        });
        if (createResponse instanceof Error) return;

        // Update task data
        await loadCustomEntityBoardLabelTask(taskId, destination.droppableId);
    }

    moveColumn(result: Object) {
        const { destination, source } = result;
        if (!destination) {
            return;
        }

        if (destination.index === source.index) {
            return;
        }

        const { boardColumns, reorderColumnPosition, updateRelationColumnToBoard } = this.props;
        const reorderedBoardColumns = this.reorderData(boardColumns, source.index, destination.index).map((column, index) => {
            const newColumn = { ...column };
            newColumn.order = index;
            return newColumn;
        });

        reorderColumnPosition(reorderedBoardColumns);

        // update ordering of board columns on backend
        const updateBoardLabelOrderPromise = reorderedBoardColumns.map(column => updateRelationColumnToBoard({
            relation: column.relationId,
            attributes: {
                'board-board-label/order': column.order
            }
        }));

        Promise.all(updateBoardLabelOrderPromise).then((response) => {
            if (response instanceof Error) return;
        });
    }

    /**
     * Reorder list of array
     */
    @memoize()
    reorderData(list: any[], startIndex: number, endIndex: number){
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };


    @bind
    closeColumnModal() {
        this.setState({
            isColumnModalOpen: !this.state.isColumnModalOpen
        });
    }

    @bind
    openColumnModal() {
        if (!this.props.isLoading && !this.state.disableAddColumn) {
            this.setState({
                isColumnModalOpen: !this.state.isColumnModalOpen,
            });
        }
    }

    @bind
    deleteColumn(relationId: number, id: string) {
        const { deleteCoRelationColumnFromBoard, removeColumnFromBoard } = this.props;

        deleteCoRelationColumnFromBoard(relationId).then((response) => {
            if (response instanceof Error) return;
            removeColumnFromBoard(relationId, id);
        });
    }

    componentDidMount() {
        const { preferences, loadRelationDefinitionTaskStatus, setSelectedBoard, loadCustomEntityBoardLabels } = this.props;
        const board = get(preferences, 'boards.board');

        loadRelationDefinitionTaskStatus().then((response) => {
            if (response instanceof Error) {
                // Disable adding of task if relationDefinition of "Has Task" is not loaded
                this.setState({ disableAddTask: true });
            }
        });

        if (board) {
            setSelectedBoard(board);
            loadCustomEntityBoardLabels({ entityId: board.id });
        }
    }

    @bind
    handleGroupIconClick(event: Object){
        this.setState({ groupByAnchorEl: event.currentTarget });
    }

    @bind
    handleGroupIconClose(){
        this.setState({ groupByAnchorEl: null });
    }

    handleGroupByItemClick = (groupBy: string) => () => {
        this.setState({ groupByAnchorEl: null, groupBy: groupBy !== 'none' ? groupBy : null }, () => {
            this.props.sortBoardTasks(groupBy);
        });
    }

    @bind
    openSidebar(id: string, title: string, columnId: string) {
        return id && this.props.openTaskSidebar({ title, id, reloadList: () => this.loadBoardTasksSingle({ id: columnId }) });
    };

    @bind
    renderGroupByIcon(){
        const open = Boolean(this.state.groupByAnchorEl);
        return (<div key="1">
            <Tooltip title="Group By">
                <IconButton onClick={this.handleGroupIconClick}>
                    <MdiIcon name="arrange-bring-forward" color="inherit" />
                </IconButton>
            </Tooltip>
            <Popover
                open={open}
                anchorEl={this.state.groupByAnchorEl}
                onClose={this.handleGroupIconClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Menu>
                    {
                        Object.entries(groupByOpts).map(([ key, label ]) =>
                            <MenuItem key={label} onClick={this.handleGroupByItemClick(key)} name={label} />)
                    }
                </Menu>
            </Popover>
        </div>);
    }

    @bind
    renderAddColumnIcon() {
        return (
            <div key="2">
                <Tooltip title="Add Column">
                    <IconButton onClick={this.openColumnModal}>
                        <MdiIcon name="plus" color="inherit" />
                    </IconButton>
                </Tooltip>
            </div>
        );
    }

    @bind
    @memoize()
    buildBreadcrumbs(name) {
        return [{ title: 'Board' }, name && { title: name }].filter(Boolean);
    }

    @bind
    reloadBoard() {
        this.props.boardColumns.map(column => this.loadBoardTasksSingle(column));
    }

    @bind
    navigateToBeta(){
        this.props.history.push(`/abox/kanbanboards`);
    }

    render() {
        const { permissions, isAdmin } = this.props.userProfile;
        const permissionsSet = new Set(permissions || []);
        const canView = isAdmin || permissionsSet.has('abox.board');
        if (!canView) {
            return <PageNotAllowed title="Boards" />;
        }
        const {
            selectedBoard,
            isLoadingBoardColumns,
            boardColumns,
            boardColumnState,
            boardColumnTasks,
            userProfile,
        } = this.props;
        const { selectedColumn, isTaskModalOpen, disableAddTask, isColumnModalOpen, options, groupBy } = this.state;
        const formattedOptions = formatOptionsForTask(options, userProfile);
        return (
            <Fragment>
                <FiltersBoard
                    id="BoardFilters"
                    filterDefinitions={this.filterDefinitions}
                    searchBar={this.searchBar}
                    defaultFilters={this.defaultFilters}
                    defaultOrder={this.defaultOrder}
                    breadcrumb={this.buildBreadcrumbs(get(selectedBoard, 'name'))}
                    rightToolbar={
                        <StyledButton variant='text' color='secondary' onClick={this.navigateToBeta}>
                            Boards
                        </StyledButton>
                    }
                    moreIcons={[
                        selectedBoard && this.renderAddColumnIcon(),
                        this.renderGroupByIcon()
                    ]}
                    onReload={this.reloadBoard}
                >
                    {(filterBy, orderBy, excludeBy) => {
                        return (
                            <PageTemplate title="Boards (Beta)">
                                <FormGeneratorStyled
                                    components={[
                                        {
                                            field: 'board',
                                            type: 'customEntityTypeahead',
                                            properties: {
                                                placeholder: 'Choose a Board',
                                                label: 'Board',
                                                name: 'board',
                                                filterBy: [
                                                    { field: 'active', op: '=', value: true },
                                                    { field: 'classes.uri', op: '=', value: 'board' }
                                                ]
                                            }
                                        }
                                    ]}
                                    data={{ board: selectedBoard }}
                                    onChange={this.onChangeBoards}
                                />
                                <StyledContentArea heightOffset={5}>
                                    {(isLoadingBoardColumns) && <Loader absolute />}

                                    {!selectedBoard && <BoardsLandingSection>
                                        <div>
                                            <Icon type="mdi" name="select" size="xl" hexColor="#818282" />
                                            <Text>Please choose a board</Text>
                                        </div>
                                    </BoardsLandingSection>}

                                    {((boardColumns.length <= 0 && selectedBoard) && !isLoadingBoardColumns)  && <BoardsLandingSection>
                                        <div>
                                            <Icon type="mdi" name="format-columns" size="xl" hexColor="#818282" />
                                            <Text>Please add a column</Text>
                                        </div>
                                    </BoardsLandingSection>}

                                    {selectedBoard && <BoardsContent
                                        className={'kanban-board'}
                                        columns={boardColumns}
                                        filterBy={filterBy}
                                        orderBy={orderBy}
                                        excludeBy={excludeBy}
                                        loadBoardTasks={this.loadBoardTasks}
                                        setOptions={this.setOptions}
                                        setMaxColumnOrder={this.setMaxColumnOrder}
                                        onDragEnd={this.onDragEnd}
                                    >
                                        {(column, index) => {
                                            return (
                                                <BoardsColumn
                                                    disableAddTask={disableAddTask}
                                                    key={column.id}
                                                    index={index}
                                                    column={column}
                                                    openTaskModal={this.openTaskModal}
                                                    deleteColumn={this.deleteColumn}
                                                    isLoading={boardColumnState && boardColumnState[column.id].isLoading}
                                                    tasks={get(boardColumnTasks, `column${column.id}`, [])}
                                                    renderTaskItem={this.renderBoardTaskItem}
                                                    openSidebar={this.openSidebar}
                                                    groupBy={groupBy}
                                                />
                                            );
                                        }}
                                    </BoardsContent>}
                                </StyledContentArea>
                            </PageTemplate>
                        );
                    }}
                </FiltersBoard>

                {selectedColumn && isTaskModalOpen &&  <BoardsAddTaskModal
                    column={selectedColumn}
                    closeModal={this.closeTaskModal}
                    loadBoardTasksSingle={this.loadBoardTasksSingle}
                    boardTasksFilters={formattedOptions.filterBy}
                />}
                {selectedBoard && isColumnModalOpen && <BoardsAddColumnModal
                    board={selectedBoard}
                    closeModal={this.closeColumnModal}
                    boardColumns={boardColumns}
                />}
            </Fragment>
        );
    }
}

export default connect(state => ({
    isLoading: state.abox.kanban.boards.isLoading,
    boardColumnState: state.abox.kanban.boards.boardColumnState,
    boardColumns: state.abox.kanban.boards.boardColumns.records,
    boardColumnTasks: state.abox.kanban.boards.boardColumnTasks.records,
    selectedBoard: state.abox.kanban.boards.selectedBoard,
    isLoadingBoardColumns: state.abox.kanban.boards.boardColumns.isLoading,
    isReorderedBoardColumns: state.abox.kanban.boards.boardColumns.isReordered,
    isAddedBoardColumns: state.abox.kanban.boards.boardColumns.isAdded,
    isRemovedBoardColumns: state.abox.kanban.boards.boardColumns.isRemoved,
    userProfile: state.user.profile,
    preferences: state.user.preferences,
    kanban: state.abox.kanban
}), {
    setSelectedBoard,
    setBoardColumnState,
    setBoardColumnLoading,
    loadCustomEntityBoardLabels,
    loadCustomEntityBoardLabelTasks,
    loadCustomEntityBoardLabelTask,
    moveTaskToOtherColumn,
    moveTaskToOtherColumnDone,
    deleteTaskRelationFromColumn,
    createRelationEntityToTask,
    clearBoardTasks,
    loadRelationDefinitionTaskStatus,
    removeTaskfromColumn,
    deleteCoRelationColumnFromBoard,
    removeColumnFromBoard,
    reorderColumnPosition,
    updateRelationColumnToBoard,
    saveBoardPreferences,
    resetBoards,
    sortBoardTasks,
    openTaskSidebar,
    setDocumentTitle,
})(withRouter(Boards));
