/* @flow */

import { gql } from '@apollo/client';
import graphicCommonQueryFields from 'graphql/common/graphicCommonQueryFields';

export default gql`
    query graphicLibrarysQuery($startIndex: Int, $stopIndex: Int, $filterBy: [JSON], $orderBy: [JSON], $excludeBy: [JSON]) {
        count: count(dataType: "graphic", filterBy: $filterBy)
        records: graphics(startIndex: $startIndex, stopIndex: $stopIndex, filterBy: $filterBy, orderBy: $orderBy, excludeBy: $excludeBy) {
            ${graphicCommonQueryFields}
        }
    }
`;
