/*@flow*/

import HttpFetch from 'app/utils/http/HttpFetch';
import { ApolloClient, InMemoryCache, HttpLink, ApolloLink, concat, split } from '@apollo/client';

import affectliSso from 'app/auth/affectliSso';
import { get } from 'app/utils/lo/lo';
import { isDev } from 'app/utils/env';

import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';

const { host } = window.location || {};
const websocketUrl = `ws${host?.includes('localhost')? '': 's'}://${host}/graphql`;

const httpLink = new HttpLink({ uri: '/graphql', credentials: 'same-origin' });

const socketClient = createClient({
    url: websocketUrl,
    credentials: 'same-origin',
});

const wsLink = new GraphQLWsLink(socketClient);

const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        );
    },
    wsLink,
    httpLink,
);

/*
 * Intercepting and modifying the orderBy field in GraphQL queries if it contains createdBy or modifiedBy.
 * 
 * The reason for this is that in filters, createdBy/modifiedBy is a typeahead with a multiselect option,
 * and to show the name and user avatar, we have to fetch the complete createdBy/modifiedBy object.
 * To filter records, we need to pass the valueField = Id to filter records for createdBy/modifiedBy.
 * 
 * Additionally, the column definitions of the grid also have createdBy/modifiedBy. We get the whole object 
 * and show the user avatar as well as their name by retrieving values from that object.
 * 
 * The correct sort filter for createdBy and modifiedBy is createdBy.name and modifiedBy.name, but 
 * due to the above two reasons, we cannot pass the createdBy.name / modifiedBy.name fields. Otherwise, 
 * grid columns will not have any values and filters won't work for these fields.
 * 
 * There were two solutions:
 * 1. Have multiple definitions, one for filtering and another for sorting, like:
 *    { field: 'createdBy', type: 'userTypeahead', sort: false, properties: { label: 'Created by', name: 'createdById', multiple: true, valueField: 'id' }, condition: 'in' },
 *    { field: 'createdBy', type: 'userTypeahead', filters: false, properties: { label: 'Created by', name: 'createdBySort' } },
 * 
 *    This fixes the issue, but another issue arises: if you apply sorting by clicking on the 
 *    column header of the createdBy column on the grid, the sorting field inside the filter sidebar 
 *    would be empty because the values are not the same.
 * 
 * 2. Disable the sorting on "Created By" and "Modified By" only for the grid columns, but as the client is already using it
 *    and it is directly reported from the client end, instead of disabling the already deployed feature,
 * 
 * The only solution, in my opinion, is to intercept the GraphQL orderBy using middleware and override it.
 */
const orderByInterceptor = new ApolloLink((operation, forward) => {
    const variables = operation?.variables;
    if (variables?.orderBy?.length) {
        variables.orderBy = variables.orderBy.map(order => {
            if (order.field && ['createdBy', 'modifiedBy'].includes(order.field)) {
                return { ...order, field: `${order.field}.name` };
            }
            return order;
        });
    }
    return forward(operation);
});

// add the authorization to the headers
const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
        headers: {
            ...headers,
            Authorization: affectliSso.getBearerToken(),
        }
    }));
    return forward(operation);
});

const client = new ApolloClient({
    cache: new InMemoryCache({ addTypename: false }),
    link: concat(orderByInterceptor, concat(authMiddleware, splitLink)),
});

class HttpError extends Error {
    constructor(message, { httpCode, data } = {}) {
        super(message);
        this.httpCode = httpCode || 500; // Default to 500 if not provided
        this.data = data || null; // Default to null if not provided
        this.name = 'httpError'; // Custom error name
    }
}




/**
 * The application GraphQL client wrapper.
 */
class GraphQlClient {

    client: Object;

    /**
     *
     */
    constructor(apolloClient: Object) {
        this.client = apolloClient;
    }


    handleResponse = (response: Object) => {
        if (response.errors) {
            const message = get(response, 'errors[0].message') || 'Server error.';
            throw new Error(message);
        }
        return response;
    }

    handleError = (error: Object, options: Object, data) => {
        let definitions = get(options, 'query.definitions') || get(options, 'mutation.definitions');
        if (Array.isArray(definitions)) {
            definitions.map(def => def.name && def.name.value).join(' ');
        } else {
            definitions = get(options, 'query') || 'no query';
        }

        // eslint-disable-next-line no-console
        console.log(
            '[GraphQlClient] an error occured executing', definitions,
            ', variables:', get(options, 'variables'),
            ', error:', error
        );
        let message = get(error, 'networkError.result.errors[0].message');
        message = message || get(error, 'networkError.result.message');
        const httpCode = get(error, 'networkError.result.statusCode');
        if (message) {
            throw new HttpError(message, { httpCode, data });
        }
        if (Array.isArray(error.graphQLErrors)) {
            throw new HttpError(error.graphQLErrors.map(({ message }) => message).join('\n'), {httpCode, data});
        }
        if (typeof error.message === 'string') {
            if (error.message.startsWith('Network error: Unexpected token')) {
                throw new HttpError('Service error.', {httpCode, data});
            }
            throw new HttpError(error.message, {httpCode, data});
        }
        throw error;
    }

    /**
     * Wraps the ApolloClient to fail in case of errors.
     */
    async query(options: Object) {
        if (!window.navigator.onLine) {
            // eslint-disable-next-line no-console
            console.warn('Cannot query GQL when offline', options);
            throw new Error('Cannot query data when offline');
        }

        // eslint-disable-next-line no-console
        isDev && console.debug('[dev] graphql executing query', options.query.definitions.map(def => def.name && def.name.value).join(' '));
        try {
            await affectliSso.updateToken();
            const response = await this.client.query(options);
            return this.handleResponse(response);
        } catch (error) {
            const data = error?.networkError?.result?.data;
            return this.handleError(error, options, data);
        }
    }

    async mutate(options: Object) {
        if (!window.navigator.onLine) {
            // eslint-disable-next-line no-console
            console.warn('Cannot mutate data on GQL when offline', options);
            throw new Error('Cannot mutate data when offline');
        }

        // eslint-disable-next-line no-console
        isDev && console.debug('[dev] graphql executing mutation', options.mutation.definitions.map(def => def.name && def.name.value).join(' '));
        try {
            await affectliSso.updateToken();
            const response = await this.client.mutate(options);
            return this.handleResponse(response);
        } catch (error) {
            return this.handleError(error, options);
        }
    }

    async upload(options: { query: string, variables: Object, file: File, map: string }) {
        if (!window.navigator.onLine) {
            // eslint-disable-next-line no-console
            console.warn('Cannot upload data on GQL when offline', options);
            throw new Error('Cannot upload data when offline');
        }
        const { query, variables, file, map } = options;
        if (!map) {
            // eslint-disable-next-line no-console
            console.warn('File map is not defined. It would be either "variables.file" or "variables.image"');
            throw new Error('Cannot upload data when offline');
        }

        return HttpFetch.graphqlUpload('graphql', { query, variables }, file, map)
            .then(this.handleResponse)
            .catch(error => this.handleError(error, options));
    }
}

const graphql = new GraphQlClient(client);

export { client, graphql };
