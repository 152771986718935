/* @flow */

import { gql } from '@apollo/client';
import Immutable from 'app/utils/immutable/Immutable';
import { loadData, mutateData } from 'app/utils/redux/action-utils';
import { graphql } from 'graphql/client';
import OptionsBuilder from 'app/utils/api/OptionsBuilder';

import graphicLibrarysAutocompleteQuery from 'graphql/common/graphicLibrarysAutocompleteQuery';
import graphicLibrarysQuery from 'graphql/common/graphicLibrarysQuery';
import graphicLibraryMutation from 'graphql/common/graphicLibraryMutation';
import createGraphicMutation from 'graphql/common/createGraphicMutation';
import graphicDetailQuery from 'graphql/common/graphicDetailQuery';

export const LOAD_GRAPHIC_LIBRARY_STARTED: string = '@@affectli/entities/things/graphicLibrarys/LOAD_GRAPHIC_LIBRARY_STARTED';
export const LOAD_GRAPHIC_LIBRARY: string = '@@affectli/entities/things/graphicLibrarys/LOAD_GRAPHIC_LIBRARY';
export const LOAD_GRAPHIC_AUTOCOMPLETE_STARTED: string = '@@affectli/entities/things/graphicLibrarys/LOAD_GRAPHIC_AUTOCOMPLETE_STARTED';
export const LOAD_GRAPHIC_AUTOCOMPLETE: string = '@@affectli/entities/things/graphicLibrarys/LOAD_GRAPHIC_AUTOCOMPLETE';
export const UPLOAD_GRAPHIC_LIBRARY_STARTED: string = '@@affectli/entities/things/graphicLibrarys/UPLOAD_GRAPHIC_LIBRARY_STARTED';
export const UPLOAD_GRAPHIC_LIBRARY: string = '@@affectli/entities/things/graphicLibrarys/UPLOAD_GRAPHIC_LIBRARY';
export const UPDATE_GRAPHIC_LIBRARY_STARTED: string = '@@affectli/entities/things/graphicLibrarys/UPDATE_GRAPHIC_LIBRARY_STARTED';
export const UPDATE_GRAPHIC_LIBRARY: string = '@@affectli/entities/things/graphicLibrarys/UPDATE_GRAPHIC_LIBRARY';
export const DELETE_GRAPHIC_LIBRARY_STARTED: string = '@@affectli/entities/things/graphicLibrarys/DELETE_GRAPHIC_LIBRARY_STARTED';
export const DELETE_GRAPHIC_LIBRARY: string = '@@affectli/entities/things/graphicLibrarys/DELETE_GRAPHIC_LIBRARY';
export const CREATE_GRAPHIC_STARTED: string = '@@affectli/entities/things/graphicLibrarys/CREATE_GRAPHIC_STARTED';
export const CREATE_GRAPHIC: string = '@@affectli/entities/things/graphicLibrarys/CREATE_GRAPHIC';
export const LOAD_GRAPHIC_DETAILS_STARTED: string = '@@affectli/entities/things/graphicLibrarys/LOAD_GRAPHIC_DETAILS_STARTED';
export const LOAD_GRAPHIC_DETAILS: string = '@@affectli/entities/things/graphicLibrarys/LOAD_GRAPHIC_DETAILS';

export const createGraphic = (record: Object) => mutateData(CREATE_GRAPHIC_STARTED, CREATE_GRAPHIC, createGraphicMutation, 'Graphic created.')({ record });

export const loadGraphicDetails = (id: string) => loadData(LOAD_GRAPHIC_DETAILS_STARTED, LOAD_GRAPHIC_DETAILS, graphicDetailQuery)({ id });

export const loadEntityGraphicLibrary = ({ options }: Object) => {
    const variables = new OptionsBuilder(options).defaultStartStopIndexs(0, 30).build();
    return loadData(LOAD_GRAPHIC_LIBRARY_STARTED, LOAD_GRAPHIC_LIBRARY, graphicLibrarysQuery)({ ...variables });
};

export const loadEntityGraphicAutocomplete = loadData(LOAD_GRAPHIC_AUTOCOMPLETE_STARTED, LOAD_GRAPHIC_AUTOCOMPLETE, graphicLibrarysAutocompleteQuery);

export const updateEntityGraphicLibrary = (data: Object) => {
    const { id, name, active, description, primary, attributes, enableGis, geom, iconName, iconColor } = data;
    const record = { id, name, active, description, primary, attributes, enableGis, geom, iconName, iconColor };
    return mutateData(UPDATE_GRAPHIC_LIBRARY_STARTED, UPDATE_GRAPHIC_LIBRARY, graphicLibraryMutation, 'Graphic updated.')({ record });
};

export const uploadGQLImage = (data: Object) => {
    const { id, name, image, type } = data;
    const variables = { id, name, image, type };
    const query = 'mutation ($type: String!, $id: ID!, $image: Upload!) { updateEntityImage(type: $type, id: $id, image: $image) { image }}';
    return graphql
        .upload({ query, variables, file: image, map: 'variables.image' });
};

export const uploadEntityGraphicLibrary = (data: Object) => (dispatch: Function) => {
    const { name } = data;
    dispatch({ type: UPLOAD_GRAPHIC_LIBRARY_STARTED });
    let errorMessage = 'Graphics upload failed.';
    return uploadGQLImage(data)
        .then((response: Object) => {
            dispatch({
                type: UPLOAD_GRAPHIC_LIBRARY,
                payload: Immutable(response),
                meta: Immutable({ successMessage: 'Graphic uploaded.' })
            });
            return response;
        })
        .catch((error: Object) => {
            if ((error.message || '').includes('exceeds the size limit')) {
                errorMessage = `The size of the file "${name}" exceeds the limit allowed and cannot be saved.`;
            }
            dispatch({ type: UPLOAD_GRAPHIC_LIBRARY, payload: error, error: true, meta: Immutable({ errorMessage }) });
        });
};

export const deleteEntityGraphicLibrary = ({ id, name, type }: Object) => (dispatch: Function, getState: Function) => {
    dispatch({ type: DELETE_GRAPHIC_LIBRARY_STARTED, meta: Immutable({ id }) });
    return graphql
        .mutate({
            mutation: gql`
                mutation($type: String!, $id: ID!) {
                    deleteEntityImage(type: $type, id: $id) {
                        image
                    }
                }
            `,
            variables: { id, type }
        })
        .then((response: Object): void => {
            dispatch({
                type: DELETE_GRAPHIC_LIBRARY,
                payload: null,
                meta: Immutable({ id, successMessage: `Graphic '${name}' successfully removed.` })
            });
        })
        .catch((error) => {
            dispatch({ type: DELETE_GRAPHIC_LIBRARY, payload: Immutable(error), error: true, meta: Immutable({ id }) });
        });
};
