// @flow

export const buildEventTypeComponents = (lookupOptions, descriptorLookupOptions, loadEntityData) => {
    return [
        {
            type: 'text',
            properties: { label: 'Name', name: 'name' },
            constraints: { required: true },
        },
        {
            type: 'scriptTypeahead',
            properties: {
                label: 'Pre-Script',
                name: 'preScript.id',
                valueField: 'id',
                filterBy: [
                    { field: 'active', op: '=', value: true },
                    { field: 'lastVersion.id', op: 'is not null' },
                ],
            },
        },
        {
            type: 'scriptTypeahead',
            properties: {
                label: 'Script',
                name: 'script.id',
                valueField: 'id',
                filterBy: [
                    { field: 'active', op: '=', value: true },
                    { field: 'lastVersion.id', op: 'is not null' },
                ],
            },
        },
        {
            type: 'text',
            properties: { label: 'BPMN Signal', name: 'bpmnSignal' },
        },
        {
            type: 'entityTypesTypeahead',
            properties: {
                label: 'Device Type',
                name: 'entityType',
                onChange: async (evnt) => {
                    const data = evnt.target.value;
                    loadEntityData(data?.id, 'lookupOptions');
                    return [evnt.target];
                },
                filterBy: [{ field: 'active', op: '=', value: true }],
                filterResults: (records) => records.filter(record =>
                    record.entityPrimaryIndexes && record.entityPrimaryIndexes.some(index => index.isUnique)),
            },
        },
        {
            type: 'typeahead',
            properties: {
                label: 'Default lookup attribute',
                name: 'lookupAttribute',
                options: lookupOptions,
                isVisible: (data) => data.entityType && !!lookupOptions?.length,
            },
        },
        {
            type: 'entityTypesTypeahead',
            properties: {
                label: 'Descriptor Type',
                name: 'descriptorType',
                onChange: async (evnt) => {
                    const data = evnt.target.value;
                    loadEntityData(data?.id, 'descriptorLookupOptions');
                    return [evnt.target];
                },
                filterBy: [{ field: 'active', op: '=', value: true }],
                filterResults: (records) => records.filter(record =>
                    record.entityPrimaryIndexes && record.entityPrimaryIndexes.some(index => index.isUnique)),
            },
        },
        {
            type: 'typeahead',
            properties: {
                label: 'Descriptor lookup attribute',
                name: 'descriptorLookupAttribute',
                options: descriptorLookupOptions,
                isVisible: (data) => data.descriptorType && !!descriptorLookupOptions?.length,
            },
        },
    ];
};
export const normalizeEventTypeData = (data) => {
    if (!data) {
        return null;
    }
    const { preScript, script, entityType, descriptorType } = data;
    let metadata = null;
    try {
        metadata = JSON.parse(data.metadata);
    } catch (e) {}
    return {
        ...data,
        script: script?.id,
        preScript: preScript?.id,
        metadata,
        entityType: entityType?.uri || null,
        descriptorType: descriptorType?.uri || null,
    };
};
