/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import ListItem from 'app/components/molecules/List/ListItem';
import { LocationInfo } from 'app/utils/types/interfaces';
import { createEvent } from 'app/utils/http/event';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { Checkbox } from '@mic3/platform-ui';


const addressDefinitions = (addressOnlyFields: boolean) => {
    return [
        {
            field: `sync_address`,
            type: 'custom',
            properties: {
                label: '',
                name: `sync_with_address`,
                Component: props => (<ListItem
                    component={<Checkbox name={props.name} value={props.value} onClick={props.onChange} />}
                    title={'Synchronise address and GPS position'}
                    subTitle={'Changes made to address will be reflected on the entity\'s GPS position'} />),
                isVisible: !addressOnlyFields
            }
        },
        {
            field: 'address.line1',
            type: 'text',
            properties: {
                label: 'Address Line 1',
                name: 'address.line1',
            }
        },
        {
            field: 'address.line2',
            type: 'text',
            properties: {
                label: 'Address Line 2',
                name: 'address.line2'
            }
        },
        {
            field: 'address.city',
            type: 'text',
            properties: {
                label: 'City',
                name: 'address.city'
            }
        },
        {
            field: 'address.province',
            type: 'text',
            properties: {
                label: 'State/Province',
                name: 'address.province'
            }
        },
        {
            field: 'address.code',
            type: 'text',
            properties: {
                label: 'Post/Zip Code',
                name: 'address.code'
            }
        },
        {
            field: 'address.country',
            type: 'entityTypeahead',
            properties: {
                label: 'Country',
                valueField: 'name',
                name: 'address.country',
                showType: false,
                placeholder: 'Select a country...',
                entityType: 'system_country',
            }
        }
    ];
};

class LocationForm extends PureComponent<Object, Object> {

    static propTypes: Object = {
        value: PropTypes.object,
        name: PropTypes.string,
        onChange: PropTypes.func,
        addressOnlyFields: PropTypes.bool
    };

    static defaultProps = {
        addressOnlyFields: false
    };

    @bind
    @memoize()
    fieldDefinitions(addressOnlyFields) {
        return addressDefinitions(addressOnlyFields);
    }

    @bind
    onChange(locationInfo: LocationInfo) {
        if (this.props.onChange) {
            const name = this.props.name;
            const value = locationInfo;
            const event = createEvent('change', { name, value });
            this.props.onChange(event);
        }
    }

    /**
     * Emit a change to parent.
     * @param locationInfo
     */
    @bind
    @memoize()
    handleChange(locationInfo: Object, variableData: Object) {
        this.onChange(locationInfo);
    }

    /**
     * Lifecycle hook.
     * @returns {XML}
     */
    render(): Object {
        const {
            value, addressOnlyFields
        } = this.props;
        const locationInfo = value || {};
        const components = this.fieldDefinitions(addressOnlyFields);
        return (
            <div style={{ width: '100%' }} ref={this.locationRef}>
                <FormGenerator
                    components={components}
                    data={locationInfo}
                    onChange={this.handleChange}
                    ref={this.formRef}
                />
            </div>
        );
    }
}

export {
    LocationForm,
    addressDefinitions
};
