/* @flow */

import { gql } from '@apollo/client';

export default gql`
    query organisationSettingsQuery {
        site: systemSettings(section: site)
    }
`;

export const updateOrgSettingsMutation = gql`
    mutation updateOrgSettingsMutation($settings: JSON) {
        site: updateSystemSettings(section: site, settings: $settings)
    }
`;
