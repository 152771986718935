/* @flow */
import React from 'react';

import ProcessSidebar from 'app/containers/Sidebars/ProcessSidebar_';

import {
    buildSidebar as buildSidebarAction,
    closeSidebar as closeSidebarAction
} from 'store/actions/sidebar/sidebarActions';

export const openProcessSidebar = ({ id, title, reloadList, internal }) => async (dispatch, getState) => {
    return buildSidebarAction({
        title,
        content: <ProcessSidebar id={id} title={title} reloadList={reloadList} internal={internal} />,
        isOpen: true,
    })(dispatch, getState);
};

export const closeProcessSidebar = closeSidebarAction;
