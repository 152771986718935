/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Loader from 'app/components/atoms/Loader/Loader';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import { loadActionFormDefinition } from 'store/actions/designer/designerFormActions';
import { normalizeFields } from 'app/utils/designer/form/formUtils';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';

class ActionOpenForm extends PureComponent<Object, Object> {

    static propTypes = {
        formId: PropTypes.string.isRequired,
        context: PropTypes.object.isRequired,
        data: PropTypes.object,
    }

    componentDidMount() {
        this.props.loadActionFormDefinition(this.props.formId);
    }

    @bind
    @memoize()
    getComponents(form) {
        return normalizeFields(form.primary.definition);
    }

    render() {
        const { formId, data, context, isLoading, form } = this.props;
        if (isLoading) {
            return <Loader absolute backdrop />;
        }
        if (!isLoading && !form) {
            return (
                <>
                    Error: form #{formId} not found.<br />
                    <br />
                    The form does not exist or you have no permission to open it.
                </>
            );
        }
        return (
            <FormGenerator
                components={this.getComponents(form)}
                data={data}
                indent={true}
                context={context}
            />
        );
    }
}

export default connect(
    state => ({
        isLoading: state.designer.actionForm.isLoading,
        form: state.designer.actionForm.data,
    }),
    { loadActionFormDefinition }
)(ActionOpenForm);
