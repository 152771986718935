import React, { PureComponent } from 'react';
import uuidv1 from 'uuid/v1';

import { bind } from 'app/utils/decorators/decoratorUtils';
import { get } from 'app/utils/lo/lo';
import { deleteTree, searchTree } from 'app/utils/tree/tree';

import AddRelationDefinitionTreeModal from './AddRelationDefinitionTreeModal';
import RelatedEntityTreeList from './RelatedEntityTreeList';

class RelatedEntityTree extends PureComponent {
    defaultState = {};

    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
            selectedNodeIndex: null,
            filterBy: [],
            excludeBy: [],
            customExcludeBy: [],
            value: this.props.value,
            isEditing: false,
            modalTitle: 'Add related classes',
            selectedClass: null,
            selectedType: null
        };
        this.defaultState = this.state;
    }

    componentDidUpdate(prevProps) {
        const { value } = this.props;
        if (prevProps.value !== value) {
            this.setState({ ...this.defaultState, value });
        }
    }

    @bind
    toggleClassesModal(){
        this.setState(prevState => ({ isModalOpen: !prevState.isModalOpen }));
    }

    @bind
    onAddNode(node, treeNodeId) {
        const filterBy = [
            {
                'or': [
                    { field: 'type', op: '=', value: node.uri },
                    { field: 'relatedType', op: '=', value: node.uri }
                ]
            }
        ];
        const excludeTypes = ['event', 'system_event'];
        const excludeBy = [
            {
                'or': [
                    { field: 'type', op: 'in', value: excludeTypes },
                    { field: 'relatedType', op: 'in', value: excludeTypes },
                ].filter(Boolean)
            }
        ];
        const customExcludeBy = (node.children || []).map(child => ({
            id: child.id,
            reverse: child.reverse
        }));
        
        this.setState({ 
            selectedNodeIndex: treeNodeId,
            filterBy,
            excludeBy,
            customExcludeBy,
            modalTitle: 'Add related classes',
            isEditing: false,
            selectedClass: null,
            selectedType: node.uri
        }, this.toggleClassesModal);
    }

    @bind
    onEditNode(node, treeNodeId) {
        const filterBy = [
            {
                'or': [
                    [
                        { field: 'relatedType', op: '=', value: get(this.state.value, 'relatedUri') },
                        { field: 'type', op: '=', value: node?.uri },
                    ],
                    [
                        { field: 'type', op: '=', value: get(this.state.value, 'relatedUri') },
                        { field: 'relatedType', op: '=', value: node?.uri },
                    ]
                ]
            }
        ];

        this.setState({
            selectedNodeIndex: treeNodeId,
            filterBy,
            modalTitle: 'Open related entities',
            isEditing: true,
            selectedClass: node?.listBy?.id || null,
            selectedType: node.uri
        }, this.toggleClassesModal);
    }

    @bind
    onDeleteNode(node) {
        const value = JSON.parse(JSON.stringify(this.state.value));
        const finalValue = deleteTree(value, node.nodeId);
        this.setState({ value: finalValue }, this.onChange);
    }

    @bind
    onClose() {
        this.toggleClassesModal();
        this.setState({ selectedNodeIndex: null });
    }

    @bind
    onSubmit(relationDefinition) {
        const value = JSON.parse(JSON.stringify(this.state.value));
        const node = searchTree(value, this.state.selectedNodeIndex);

        if (!relationDefinition) {
            if (this.state.isEditing) {
                node.listBy = null;
                node.extra = null;
            }
            this.setState({ value, selectedNodeIndex: null }, this.toggleClassesModal);
            return;
        } 

        const { id, relatedType, type, relatedDescription, description, reverse, sub } = relationDefinition;
        const name = reverse ? relatedDescription : description;
       
        if (!this.state.isEditing) {
            const uri = reverse ? type : relatedType;
            node.children = [...node.children, {
                id,
                name,
                uri,
                sub,
                children: [],
                reverse,
                nodeId: uuidv1()
            }];
        } else {
            node.extra = name;
            node.listBy = {
                id,
                reverse
            };
        }

        this.setState({ value, selectedNodeIndex: null }, () => {
            this.toggleClassesModal();
            this.onChange();
        });
    }

    @bind
    onChange() {
        const { name } = this.props;
        const { value } = this.state;
        this.props.onChange({ target: { name, value } });
    }

    render() {
        const { isModalOpen, value, filterBy, excludeBy, modalTitle, selectedClass, selectedType, customExcludeBy } = this.state;
        const { ...treeFieldProps } = this.props;
        return (
            <>
                <RelatedEntityTreeList 
                    {...treeFieldProps}
                    value={value}
                    onAddNode={this.onAddNode}
                    onEditNode={this.onEditNode}
                    onDeleteNode={this.onDeleteNode}
                />
                <AddRelationDefinitionTreeModal
                    modalTitle={modalTitle}
                    filterBy={filterBy}
                    excludeBy={excludeBy}
                    customExcludeBy={customExcludeBy}
                    isOpen={isModalOpen}
                    onSubmit={this.onSubmit}
                    closeModal={this.onClose}
                    selectedClass={selectedClass}
                    selectedType={selectedType}
                />
            </>
        );
    }
};

export default RelatedEntityTree;
