import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { loadTeamHistory } from 'store/actions/admin/teamsActions';

import Changelog from 'app/components/organisms/Changelog/Changelog';

class TeamHistoryTab extends PureComponent<Object, Object> {
    static propTypes = {
        id: PropTypes.string,
        isLoading: PropTypes.bool,
        records: PropTypes.arrayOf(PropTypes.object),
        startIndex: PropTypes.number,
        totalRecords: PropTypes.number
    }

    loadData = ({ id, ...options }) => {
        return this.props.loadTeamHistory(id, options);
    }

    render() {
        const { id, isLoading, records, startIndex, totalRecords } = this.props;
        return (
            <Changelog
                type="team"
                id={id}
                isLoading={isLoading}
                startIndex={startIndex}
                records={records}
                loadData={this.loadData}
                totalRecords={totalRecords}
            />
        );
    }
}

export default connect(
    state => ({
        records: state.admin.teams.history.records,
        totalRecords: state.admin.teams.history.count,
        isLoading: state.admin.teams.history.isLoading,
        startIndex: state.admin.teams.history.startIndex,
    }),
    { loadTeamHistory }
)(TeamHistoryTab);
