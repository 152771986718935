/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { loadScriptsTypeahead } from 'store/actions/designer/designerScriptActions';

import { bind } from 'app/utils/decorators/decoratorUtils';

import AbstractEntityTypeahead from 'app/components/organisms/Form/Typeahead/abstract/AbstractEntityTypeahead';
import ResolveAvatarTypeaheadWrapper from 'app/components/organisms/Form/Typeahead/abstract/ResolveAvatarTypeaheadWrapper';

/**
 * Select one or more groups using lazy loading.
 */
class ScriptTypeahead extends PureComponent<Object, Object> {

    static propTypes = {
        loadScriptsTypeahead: PropTypes.func.isRequired,
        filterBy: PropTypes.arrayOf(PropTypes.object),
    }

    @bind
    optionTemplate({ name, id }: Object) {
        return ({
            label: `${name || 'Name not available'} (${id ? id.slice(0,8) : 'Id not available'})`,
        });};


    render() {
        const { loadScriptsTypeahead, ...abstractEntityAutocompleteProps } = this.props;
        return (
            <AbstractEntityTypeahead
                placeholder="Search for a Script..."
                {...abstractEntityAutocompleteProps}
                loadOptions={loadScriptsTypeahead}
                optionTemplate={this.optionTemplate}
            />
        );
    }
};

const ScriptTypeaheadWrapped = (props) => {
    return <ResolveAvatarTypeaheadWrapper {...props} Typeahead={ScriptTypeahead} entityType="script" />;
};


export default connect(
    null, { loadScriptsTypeahead }
)(ScriptTypeaheadWrapped);
