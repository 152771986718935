/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Autocomplete, Avatar, ListItemText } from '@mic3/platform-ui';

import { loadEntityGraphicAutocomplete } from 'store/actions/common/graphicLibraryActions';
import { bind, debounce } from 'app/utils/decorators/decoratorUtils';
import { TypeaheadChipInitials, TypeaheadAdornmentInitials } from 'app/components/organisms/Form/Typeahead/abstract/TypeaheadChip';
import { TypeaheadListItem } from 'app/components/organisms/Form/Typeahead/abstract/TypeaheadListItem';
import { getAttachmentUrl } from 'app/utils/attachments/attachmentsUtils';
import { isValiduuid } from 'app/utils/string/string-utils';

/**
 * Select one or more groups using lazy loading.
 */
class GraphicTypeahead extends PureComponent<Object, Object> {
    static propTypes = {
        loadOptions: PropTypes.func.isRequired,
        processDefinitions: PropTypes.arrayOf(PropTypes.object),
        filterBy: PropTypes.arrayOf(PropTypes.object),
    }

    state = { options: [], isLoading: false };

    @bind
    optionTemplate({ name, id, image }: Object) {
        const src = getAttachmentUrl(id, 'graphic', image);
        return ({
            option: (
                <TypeaheadListItem ContainerComponent="div" dense disableGutters>
                    <Avatar src={src} />
                    <ListItemText primary={name} secondary={<small>#{id}</small>} />
                </TypeaheadListItem>
            ),
            ChipProps: {
                avatar: <TypeaheadChipInitials src={src} />,
            },
            startAdornment: <TypeaheadAdornmentInitials src={src} />,
            label: `${name} (${id})`,
        });};

    @bind
    @debounce()
    suggest(event: Object) {
        this.setState({ isLoading: true });
        const { excludeBy, value, filterBy, loadOptions } = this.props;
        const query = event.target.value;
        const filters = filterBy || [];
        if (query) {
            if (isValiduuid(query)) {
                filters.push({ or: [
                    { field: 'id', op: '=', value: query },
                    { field: 'name', op: 'contains', value: query },
                ] });
            } else {
                filters.push({ field: 'name', op: 'contains', value: query });
            }
        }
        if (value) {
            const selected = Array.isArray(value) ? value : [value];
            filters.push(
                ...selected.map(({ id }) => ({ field: 'id', op: '<>', value: id }))
            );
        }

        filters.push({ field: 'image', op: 'is not null' }); // Elimintating those graphic entities which don't have image uploaded

        loadOptions({
            startIndex: 0,
            stopIndex: 50,
            filterBy: filters,
            excludeBy: excludeBy,
            orderBy: [ { field: 'name', direction: 'asc nulls last' } ],
        }).then((options) => {
            this.setState({ options: options.records, isLoading: false });
        }).catch((error) => {
            this.setState({ options: [], isLoading: false });
        });
    };

    render() {
        // remove the properties that we do not have to pass to the AbstractEntityAutocomplete
        const { excludeBy, filterBy, orderBy, loadOptions, processDefinitions, ...entityGraphicTypeaheadProps } = this.props;
        const { options, isLoading } = this.state;
        return (
            <Autocomplete
                placeholder="Search for a graphic..."
                {...entityGraphicTypeaheadProps}
                VirtualListProps={{
                    itemSize: 60,
                }}
                options={options}
                isLoading={isLoading}
                optionTemplate={this.optionTemplate}
                suggest={this.suggest}
            />
        );
    }
};

export default connect(
    null,
    { loadOptions: loadEntityGraphicAutocomplete }
)(GraphicTypeahead);
