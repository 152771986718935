/* @flow */

import { gql } from '@apollo/client';

export default gql`
query formDefinitionVersionTypeaheadQuery($filterBy: [JSON], $orderBy: [JSON]) {
    result: formDefinitionVersions(filterBy: $filterBy, orderBy: $orderBy) {
      primary(fields: ["version"])
    }
}
`;
