/* @flow */

import React, { memo } from 'react';
import { AttachmentFile, chooseIcon } from 'app/utils/attachments/attachmentsUtils';
import Icon from 'app/components/atoms/Icon/Icon';


const AttachmentIcon = ({ file }: Object) => {
    const { type, url, name } = file;
    const iconName = chooseIcon(type, name);
    return (
        <AttachmentFile href={url}>
            <Icon name={iconName} size="lg" />
        </AttachmentFile>
    );
};

export default memo(AttachmentIcon);
