/* @flow */

// $FlowFixMe
import React, { useState, useCallback, memo, Fragment } from 'react';
import styled from 'styled-components';
import uuidv1 from 'uuid/v1';

import { TextField, ListItem, Divider, Typography } from '@mic3/platform-ui';
import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import { getFieldDefinition } from 'app/utils/designer/form/settings/formFieldSettingsUtils';
import { focusInput } from 'app/utils/utils';

const SearchFieldWrapper = styled.div`
    padding: 0 16px;
    position: sticky;
    top: -16px;
    z-index: 99;
    background: ${({ theme }) => theme.material.colors.background.paper};
`;

const ListItemStyled = styled(ListItem)`
    cursor: pointer;
`;

const FieldsModal = ({ elements, modal, showModal, toggleModal, onAdd, toggleIotModal, isMobile }: Object) => {

    const [search, setSearch] = useState('');
    const onSearch = useCallback((event) => {
        setSearch(event.target.value);
    }, [setSearch]);

    const onChange = type => () => {
        const { index, parentUuid, isRoot } = showModal;
        if(type === 'iotPanel'){
            toggleModal();
            toggleIotModal();
            return;
        }
        const el = getFieldDefinition(type);
        onAdd(el, parentUuid, index + 1, isRoot);
        toggleModal();
    };

    return !!showModal && (
        <ModalDialog
            title="Form elements"
            onClose={() => toggleModal()}
            dialogProps={{ maxWidth:'sm' }}
        >
            <SearchFieldWrapper>
                <TextField
                    placeholder="Search..."
                    value={search}
                    onChange={onSearch}
                    variant="standard"
                    inputRef={ref => !isMobile && focusInput(ref)}
                />
            </SearchFieldWrapper>
            {
                elements
                    .filter(({ type }) => !search || type.toLowerCase().includes(search.toLowerCase()))
                    .map(({ type }, index) => {
                        return (
                            <Fragment key={uuidv1()}>
                                <ListItemStyled dense ContainerComponent="div" onClick={onChange(type)}>
                                    <Typography variant="h6" style={{ flexGrow: 1, marginTop: 3 }}>
                                        {type}
                                    </Typography>
                                </ListItemStyled>
                                <Divider/>
                            </Fragment>
                        );
                    })
            }
        </ModalDialog>
    );
};

export default memo<Object>(FieldsModal);
