/* @flow */

import OptionsBuilder from 'app/utils/api/OptionsBuilder';
import { loadData } from 'app/utils/redux/action-utils';
import { graphql } from 'graphql/client';
import dashboardMapsQuery from 'graphql/dashboard/dashboardMapsQuery';
import dashboardTasksQuery from 'graphql/dashboard/dashboardTasksQuery';
import lastUsedAppsQuery from 'graphql/dashboard/lastUsedProcessesQuery';
import loadEntitiesQuery from 'graphql/dashboard/loadEntitiesQuery';

export const LOAD_TASKS_ASSIGNED_STARTED: string = '@@affectli/dashboard/LOAD_TASKS_ASSIGNED_STARTED';
export const LOAD_TASKS_ASSIGNED: string = '@@affectli/dashboard/LOAD_TASKS_ASSIGNED';

export const LOAD_TASKS_BY_MODIFIED_DATE_STARTED: string = '@@affectli/dashboard/LOAD_TASKS_BY_MODIFIED_DATE_STARTED';
export const LOAD_TASKS_BY_MODIFIED_DATE: string = '@@affectli/dashboard/LOAD_TASKS_BY_MODIFIED_DATE';

export const LOAD_TASKS_BY_PRIORITY_STARTED: string = '@@affectli/dashboard/LOAD_TASKS_BY_PRIORITY_STARTED';
export const LOAD_TASKS_BY_PRIORITY: string = '@@affectli/dashboard/LOAD_TASKS_BY_PRIORITY';

export const LOAD_TASKS_BY_DUE_DATE_STARTED: string = '@@affectli/dashboard/LOAD_TASKS_BY_DUE_DATE_STARTED';
export const LOAD_TASKS_BY_DUE_DATE: string = '@@affectli/dashboard/LOAD_TASKS_BY_DUE_DATE';

export const LOAD_MAPS_BY_MODIFIED_DATE_STARTED: string = '@@affectli/dashboard/LOAD_MAPS_BY_MODIFIED_DATE_STARTED';
export const LOAD_MAPS_BY_MODIFIED_DATE: string = '@@affectli/dashboard/LOAD_MAPS_BY_MODIFIED_DATE';

export const LOAD_LAST_USED_PROCESSES_STARTED: string = '@@affectli/dashboard/LOAD_LAST_USED_PROCESSES_STARTED';
export const LOAD_LAST_USED_PROCESSES: string = '@@affectli/dashboard/LOAD_LAST_USED_PROCESSES';

/**
 * Loads the assigned tasks.
 */
export const loadTasksAssigned = (user: Object, options: Object = {}) => {
    const { ...opts } = options;
    const variables = new OptionsBuilder(opts)
        .defaultStartStopIndexs(0, 30)
        .filter({ field: 'type', op: '=', value: 'opentask' })
        .filter({ field: 'primary.assignee', op: '=', value: user.id })
        .defaultOrder({ field: 'createdDate', direction: 'desc nulls last' })
        .build();
    return loadData(LOAD_TASKS_ASSIGNED_STARTED, LOAD_TASKS_ASSIGNED, dashboardTasksQuery)(variables);
};

/**
 * Loads the tasks by modified date.
 */
export const loadTasksByModifiedDate = (user: Object, options: Object = {}) => {
    const { linkOnly, ...opts } = options;
    const variables = new OptionsBuilder(opts)
        .defaultStartStopIndexs(0, 30)
        .filter({ field: 'type', op: '=', value: 'opentask' })
        .filter({ field: 'primary.assignee', op: '=', value: user.id })
        .defaultOrder({ field: 'modifiedDate', direction: 'desc nulls last' })
        .build();
    return loadData(LOAD_TASKS_BY_MODIFIED_DATE_STARTED, LOAD_TASKS_BY_MODIFIED_DATE, dashboardTasksQuery)(variables);
};

/**
 * Loads the tasks by priority.
 */
export const loadTasksByPriority = (user: Object, options: Object = {}) => {
    const { linkOnly, ...opts } = options;
    const variables = new OptionsBuilder(opts)
        .defaultStartStopIndexs(0, 30)
        .filter({ field: 'type', op: '=', value: 'opentask' })
        .filter({ field: 'primary.assignee', op: '=', value: user.id })
        .defaultOrder({ field: 'primary.priority', direction: 'asc nulls last' })
        .build();
    return loadData(LOAD_TASKS_BY_PRIORITY_STARTED, LOAD_TASKS_BY_PRIORITY, dashboardTasksQuery)(variables);
};

/**
 * Loads the assigned tasks by due date.
 */
export const loadTasksByDueDate = (user: Object, options: Object = {}) => {
    const { linkOnly, ...opts } = options;
    const variables = new OptionsBuilder(opts)
        .defaultStartStopIndexs(0, 30)
        .filter({ field: 'type', op: '=', value: 'opentask' })
        .filter({ field: 'primary.assignee', op: '=', value: user.id })
        .defaultOrder({ field: 'primary.dueDate', direction: 'asc nulls last' })
        .build();
    return loadData(LOAD_TASKS_BY_DUE_DATE_STARTED, LOAD_TASKS_BY_DUE_DATE, dashboardTasksQuery)(variables);
};

/**
 * Loads the maps by modified date.
 */
export const loadMapsByModifiedDate = (user: Object, options: Object = {}) => {
    const variables = new OptionsBuilder(options)
        .filter({field: 'active', op: '=', value: true})
        .defaultOrder({ field: 'modifiedDate', direction: 'desc nulls last' })
        .build();
    return loadData(LOAD_MAPS_BY_MODIFIED_DATE_STARTED, LOAD_MAPS_BY_MODIFIED_DATE, dashboardMapsQuery)(variables);
};

export const loadLastUsedProcessesInitially = () => {
    return graphql.query({
        query: lastUsedAppsQuery,
        fetchPolicy: 'no-cache'
    }).then( (response: Object) => {
        if(response instanceof Error) return {};
        const { lastUsedProcesses } = response.data.profile.primary;
        return lastUsedProcesses?.length ? lastUsedProcesses : [];
    }).catch((error) => {
        // eslint-disable-next-line
        console.log('loadLastUsedProcesses ~ error:', error);
    });
};

export const getLastUsedAppsData = (processes) => {
    const ids = processes.map((process) => process.id);
    if(!ids?.length) return Promise.resolve();
    const variables = {
        type:'processdefinition',
        filterBy:[
            {field:'id', op:'in', value:ids}
        ],
    };
    return graphql.query({
        query: loadEntitiesQuery,
        fetchPolicy: 'no-cache',
        variables
    });
};

export const getLastUsedProcesses = (processes) => async (dispatch) => {
    dispatch({ type: LOAD_LAST_USED_PROCESSES_STARTED });
    let _processes = processes || [];
    try {
        if(!_processes?.length) {
            const lastUsedProcesses = await loadLastUsedProcessesInitially();
            _processes = lastUsedProcesses;
        }
        if(_processes?.length) {
            const { data: { records }} = await getLastUsedAppsData(_processes);
            _processes = _processes?.map(item => {
                const match = records.find(data => data.id === item.id);
                return match ? { ...item, ...match } : null;
            }).filter(Boolean);
        }
    } catch(error){
        //eslint-disable-next-line 
        console.log('lastUsedApps error while updating data', {error});
    }
    dispatch({ type: LOAD_LAST_USED_PROCESSES, payload: _processes });

};