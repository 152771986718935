// @flow

import DrawTool from 'app/utils/maps/tools/mapDrawToolsUtils';
import Draw from 'ol/interaction/Draw';

class DrawingTool {
    drawTool: Object;
    olMap: Object;
    drawingType: number;

    /**
     * instansiation of DrawingTool
     * @param olMapVM
     */
    constructor(olMapVM: Object) {
        this.olMap = olMapVM;
    }

    /**
     * initialization of tool
     */
    initTool(initDrawType: number = 1) {
        this.drawTool = new DrawTool(this.olMap);
        const layer = this.drawTool.initDrawLayer('Drawing Layer', true);
        this.olMap.addLayer(layer);
        this.drawTool.initDrawTool(initDrawType, true);
    }

    /**
     * for drawing on maping using draw tool
     */
    addInteractionOnMap(drawingType: number, callback) {
        if (drawingType !== -1) {
            this.drawingType = drawingType;
            this.drawTool.changeDrawType(drawingType);
            const draw = this.drawTool.getDraw(true);
            this.olMap.getMap().addInteraction(draw);
            this.drawTool.createHelpTooltip();
            this.drawTool.addDrawEndListener(callback);
        }
        else {
            this.removeInteractionFromMap();
        }
    }


    /**
     * to removing interaction so user can use other interaction
     */
    removeInteractionFromMap() {
        const draw = this.drawTool.getDraw(true);
        this.olMap.getMap().removeInteraction(draw);
        const interactions = draw && this.olMap.getMap().getInteractions();
        const pointInteration = interactions?.array_?.filter(pt => pt instanceof Draw);
        if(pointInteration) {
            pointInteration.map(pt => this.olMap.getMap().removeInteraction(pt));
        }
        this.drawTool.removeToolTip();
    }
}

export default DrawingTool;
