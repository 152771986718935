// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import { Button } from '@mic3/platform-ui';
import { get } from 'app/utils/lo/lo';
import { createShapes } from 'app/components/molecules/Map/DrawShapes/DrawShapes';
import { removeCreatePinInteraction, getGeoserverInfo, addNewLayer } from 'store/actions/maps/situationalAwarenessActions';
import { openLayerSidebar } from 'store/actions/maps/layerSidebarActions.js';
import { additionalFields } from 'app/containers/Maps/AddLayerModal/AdditionalFields';
import { getEntityAssets, LAYERS, getLayerType, __getMapLayers, __buildNewLayerData, getLayerNameField } from 'app/utils/maps/layer/layerUtils';
import cesiumLayerConfig from 'app/config/cesiumLayerConfig';



class AddLayerModal extends PureComponent<Object, Object> {
    static propTypes: Object = {
        isLoading: PropTypes.bool,
        records: PropTypes.array,
        layer: PropTypes.object,
        openLayerSidebar: PropTypes.func.isRequired
    };

    formRef = React.createRef();
    token: '';

    constructor(props: Object) {
        super(props);
        this.state = {
            layerForm: {},
            isLoading: false,
            errorMsg: null,
            assetOptions: [],
        };
        this.defaultState = { ...this.state };
    }

    @bind
    @memoize()
    getOptions(value) {
        return {
            isShort: true,
            options: {
                startIndex: 0,
                stopIndex: 1,
                filterBy: [{ field: 'uri', op: '=', value }]
            },
            aditionalFields: 'formDefinition'
        };
    }

    @bind
    fieldDefinitions(uri, additionalFields) {
        const { mapData } = this.props;
        const { errorMsg } = this.state;
        const nameField = getLayerNameField(mapData);
        switch (uri) {
            case LAYERS.drawing:
                return [nameField];
            case LAYERS.wms:
                return [
                    nameField,
                    {
                        field: 'Layer URL',
                        type: 'text',
                        properties: {
                            label: 'Layer URL',
                            name: 'layer_url',
                            error: errorMsg
                        },
                        constraints: { required: true, minLength: 3, maxLength: 300 }
                    },
                    ...additionalFields
                ];
            case LAYERS.entity:
                return [
                    nameField,
                    {
                        type: 'entityTypesTypeahead',
                        properties: {
                            label: 'Entity Type',
                            name: 'entitytype',
                            filterBy: [
                                {
                                    or: [
                                        { field: 'entityTabs', op: 'is null' },
                                        { field: 'entityTabs.list', op: 'contains', value: 'Location' }
                                    ]
                                }
                            ]
                        },
                        constraints: { required: true }
                    }
                ];
            case LAYERS.event:
                return [
                    nameField,
                    {
                        type: 'dateTimeRange',
                        properties: {
                            label: 'Event Time',
                            name: 'time_range'
                        },
                        constraints: { required: true }
                    },
                    {
                        type: 'eventTypeTypeahead',
                        properties: {
                            label: 'Event Type',
                            name: 'event_type'
                        },
                        constraints: { required: true }
                    }
                ];
            case LAYERS.cesium:
                return [
                    nameField,
                    {
                        field: 'cesium-entities',
                        type: 'entityTypeahead',
                        properties: {
                            entityType: cesiumLayerConfig?.cesiumAccount?.type,
                            label: 'Cesium Ion account entity',
                            name: cesiumLayerConfig?.cesiumLayer?.accountAttr,
                            placeholder: 'Choose a Cesium Ion account'
                        },
                        constraints: { required: true }
                    },
                    {
                        type: 'typeahead',
                        properties: {
                            label: 'Cesium Ion asset',
                            name: cesiumLayerConfig?.cesiumLayer?.assetAttr,
                            placeholder: 'Choose an Asset',
                            options: this.state.assetOptions,
                            isVisible: data => data && data?.[cesiumLayerConfig?.cesiumLayer?.accountAttr]
                        },
                        constraints: { required: true }
                    }
                ];
            case LAYERS.replay:
                return [
                    nameField,
                    {
                        type: 'dateTimeRange',
                        properties: {
                            label: 'Event Time',
                            name: 'time_range'
                        },
                        constraints: { required: true }
                    },
                    ...additionalFields
                ];
            default:
        }
    }

    @bind
    async importLayerToMap(id: string, name: string, layerType: string, data, entity) {
        const { map, entityTypes } = this.props;
        const layersGroup = map?.getLayersGroup('allLayers');
        const layers = layersGroup?.getLayers()?.array_ || [];
        layers?.length && layers.forEach(lyr => map.getMap()?.removeLayer(lyr));
        await createShapes([entity], map, entityTypes);
    }

    @bind
    async createLayer(data: Object) {
        const { layer, map, mapId, allMapLayers, mapData } = this.props;
        const { name: title, uri } = layer || {};
        const layerType = getLayerType(layer);
        const newLayerData = __buildNewLayerData({ uri, data });
        this.props.addNewLayer(newLayerData);
        const allLayers = __getMapLayers(mapData);

        if(newLayerData) {
            const id = newLayerData.id;
            await this.importLayerToMap(id, data?.name, layerType, allLayers, newLayerData);
            map?.getMap()?.render();
            const layer = id && map?.findLayerByTitle(data?.name);
            const entityLayers = map?.getEntityLayers();
            if(entityLayers) {
                for (const id in entityLayers) {
                    const cesium = map.getCesiumObj();
                    const entityLayer = entityLayers[id];
                    const ol3d = map.getOl3d();
                    const is3dEnabled = map.is3dEnabled();
                    if(is3dEnabled && typeof entityLayer.toggleTo3DStyling === 'function') {
                        entityLayer.toggleTo3DStyling(cesium, ol3d, is3dEnabled);
                    }
                }
            }
            await this.closeModal();
            this.props.removeCreatePinInteraction();
            allMapLayers?.map((layer) => {
                const mapLayer = map?.findLayerById(layer?.id);
                mapLayer?.setVisible(layer.visible);
            });
            await this.props.openLayerSidebar({ id: newLayerData?.id, title, layer, map, mapId, layerType, data: newLayerData });
        };
    }

    @bind
    onFormSubmit(e: Event) {
        e.preventDefault();
        this.formRef.current.isValidForm().then(({ data, errors }) => {
            if (!errors) {
                this.closeModal();
                this.createLayer(data);
            }
        });
    }

    @bind
    async handleChange(data: Object, {name, value}: Object) {
        if (name === cesiumLayerConfig?.cesiumLayer?.accountAttr && value) {
            const assetOptions = await getEntityAssets(value, this.props.map);
            this.setState({ assetOptions });
        }
        if (name === cesiumLayerConfig?.cesiumLayer?.accountAttr && !value) {
            return this.setState({layerForm: {...data, 'system_cesium_layer/asset': null}});
        }
        if (name === 'layer_url') {
            this.setState({ isLoading: true }, async () => {
                const resp = await getGeoserverInfo(value);
                const { allWMSLayers = []} = resp || {};
                return this.setState({
                    isLoading: false,
                    layerForm: {
                        ...data,
                        allWMSLayers,
                        'layer': null,
                        'style': null
                    }
                });
            });
        } else if (name === 'layer') {
            return this.setState({ layerForm: {...data, 'style': null} });
        }
        this.setState({layerForm: data});
    }

    @bind
    closeModal() {
        this.setState(this.defaultState, this.props.closeModal);
    }

    render() {
        const { layerForm } = this.state;
        const { layer } = this.props;
        const { name, uri } = layer || {};
        const _additionalFields = additionalFields(name, layerForm);
        return (
            layer && (
                <ModalDialog
                    title={`New ${name}`}
                    onClose={this.closeModal}
                    actions={<Button onClick={this.onFormSubmit}>Add</Button>}
                >
                    {!uri ? (
                        'Not ready yet'
                    ) : (
                        <FormGenerator
                            data={layerForm}
                            onChange={this.handleChange}
                            components={this.fieldDefinitions(uri, _additionalFields)}
                            ref={this.formRef}
                        />
                    )}
                </ModalDialog>
            )
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    records: state.classifications.list.records,
    isLoading: state.classifications.list.isLoading || state.entities.primaryClasses.isLoading,
    entityTypes: state.app.allPrimaryClasses.records,
    allMapLayers: __getMapLayers(state.maps.situationalAwareness.map.data),
    mapData: get(state.maps,'situationalAwareness.map.data',{}),

});

export default connect(mapStateToProps, {
    openLayerSidebar,
    removeCreatePinInteraction,
    addNewLayer
})(AddLayerModal);
