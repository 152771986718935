/* @flow */

import { gql } from '@apollo/client';
import eventDetailCommonQueryFields from 'graphql/stream/event/eventDetailCommonQueryFields';

export default gql`
    query loadSidebarEventDetailsQuery($id: ID!, $time: Date!) {
        result: event(id: $id, time: $time) {
            ${eventDetailCommonQueryFields}
        }
    }
`;
