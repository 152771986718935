import React, { memo, useRef, useMemo, useCallback, useState, forwardRef } from 'react';
import { useToggle } from 'app/utils/hook/hooks';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';

import AddLayerModal from 'app/containers/Maps/AddLayerModal/AddLayerModal';
import WidgetHeader from 'app/components/atoms/WidgetHeader/WidgetHeader';
import MenuItem from 'app/components/molecules/Menu/MenuItem';
import { layerTypes } from 'app/utils/maps/layer/layerUtils';
import { Menu, IconButton, MdiIcon, TextField, Tooltip, Grid } from '@mic3/platform-ui';
import { getPermissions } from 'app/config/rolesConfig';


const SearchField = styled(TextField)`
    .MuiFormControl-root {
        margin: 0px !important;
        padding: 9px 0 !important;
        border-bottom: 1px solid ${({ theme }) => theme.material.colors.background.divider} !important;
        border-top: 1px solid ${({ theme }) => theme.material.colors.background.divider} !important;
    }
    &.MuiFormControl-marginNormal {
        margin: 0px !important;
    }
`;

const GridWrapper = styled(Grid)`
    min-height: 48px;
    padding: 8px 8px 0 8px !important;
    display:flex;
`;


const SearchIcon = styled(MdiIcon)`
    line-height: 24px !important;
    color : ${({theme})=> theme.material.colors.text.secondary};
`;

const StyledMdiIcon = styled(MdiIcon)`
     & :before{
        color : ${({theme})=> theme.material.colors.text.secondary};
     }
`;

const Header = styled(WidgetHeader)`
    padding-right: 8px;
    border-bottom: 1px solid  ${({theme})=> theme.material.colors.background.divider};
    border-top: 1px solid ${({theme})=> theme.material.colors.background.divider};
    grid-template-columns: 1fr auto;
`;

const ForwardedMenuItem = forwardRef((props, ref) => <MenuItem {...props} ref={ref} />);

const LayerListHeader = ({ value, onLayersSearch, layersLoading, map, mapId, isLayerSidebar, classes, mapData }) => {
    const anchorEl = useRef(null);
    const [isOpen, toggleMenu] = useToggle(false);
    const [layer, setLayer] = useState();
    const { canEdit } = getPermissions(mapData && mapData.role);
    const onChange = useCallback((value) => {
        onLayersSearch(value);
    }, [onLayersSearch]);

    const toggleModal = useCallback((layer) => {
        setLayer(layer);
    }, []);

    const menuList = useMemo(() => layerTypes.map(({ name, icon, type, uri }, index) =>
        <ForwardedMenuItem key={`${index}-${name}`} onClick={() => toggleModal({ name, uri })} name={name} icon={icon} iconType={type || 'mdi'} />
    ), [toggleModal]);

    return (
        <Header>
            <GridWrapper>
                <SearchIcon name="magnify" />
                <SearchField
                    placeholder="Search..."
                    value={value}
                    onChange={onChange}
                    InputProps={{
                        disableUnderline: true
                    }}
                    variant="standard"
                    autoFocus={isLayerSidebar}
                />
            </GridWrapper>

            {!layersLoading && canEdit && <Tooltip arrow title={'Add layer to the map'} placement="top-start">
                <span>
                    <IconButton buttonRef={anchorEl} onClick={toggleMenu}><StyledMdiIcon name="plus" /></IconButton>
                </span>
            </Tooltip>}
            <Menu open={isOpen} anchorEl={anchorEl.current} onClose={toggleMenu} >
                {menuList}
            </Menu>
            {layer && (
                <AddLayerModal
                    layer={layer}
                    map={map}
                    mapId={mapId}
                    closeModal={toggleModal}
                    classes={classes}
                />
            )}
        </Header>
    );
};

LayerListHeader.propTypes = {
    onLayersSearch: PropTypes.func.isRequired,
    map: PropTypes.object,
    mapId: PropTypes.string,
    layersLoading: PropTypes.bool,
    value: PropTypes.string,
};

export default memo(withTheme(LayerListHeader));
