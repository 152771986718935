/* @flow */

import { gql } from '@apollo/client';

export default gql`
query customEntityAutocompleteQuery($startIndex: Int, $stopIndex: Int, $filterBy: [JSON], $orderBy: [JSON], $excludeBy: [JSON]) {
  result: entiites(type: "custom", startIndex: $startIndex, stopIndex: $stopIndex, filterBy: $filterBy, orderBy: $orderBy, excludeBy: $excludeBy) {
    id
    type
    name
    image
  }
}
`;
