/* @flow */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import produce from 'immer';
import { CircularProgress, TextField, Avatar, Divider, Box } from '@mic3/platform-ui';

import { loadResourceList, clearResourcesList } from 'store/actions/admin/workspacesActions';

import { bind } from 'app/utils/decorators/decoratorUtils';
import { typeTitlesMultiple } from 'app/config/typesConfig';
import { defaultRole } from 'app/config/rolesConfig';
import { isEmpty, getStr, focusInput } from 'app/utils/utils';
import { getAttachmentUrl } from 'app/utils/attachments/attachmentsUtils';
import Immutable from 'app/utils/immutable/Immutable';

import VirtualListManaged from 'app/components/molecules/VirtualList/VirtualListManaged';
import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import RolesSelect from 'app/components/molecules/RolesSelect/RolesSelect';
import TypeIcon from 'app/components/atoms/TypeIcon/TypeIcon';
import ListItem from 'app/components/molecules/List/ListItem';
import Button from 'app/components/atoms/Button/Button';
import Icon from 'app/components/atoms/Icon/Icon';

import CheckBoxBlank from 'assets/images/icons/check_box_outline_blank.png';
import CheckBox from 'assets/images/icons/check_box.png';

const inputProps: Object = { disableUnderline: true };

const ListItemStyled = styled(ListItem)`
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    cursor: pointer;
    box-shadow: none;
    background: ${({ selected, theme }) => (selected ? theme.material.colors.background.active : theme.material.colors.background.paper)};
    &:hover {
        background: ${({ selected, theme }) => (selected ? theme.material.colors.background.active : theme.material.colors.background.hover)};
    }
    @media (max-width: 1100px) {
        padding-right: 2rem;
    }
`;

const StyledAvatar = styled(Avatar)`
    && {
        ${({ selected }) => selected ? `
        background-color: gray !important;
        color: transparent
        ` : `
        ${ListItemStyled}:hover & {
            color: transparent;
        }
        `};
    }
`;

const StyledTypeIcon = styled(TypeIcon)`
    && {
        ${({ selected }) => selected ? `
        background-color: gray !important;
        color: transparent
        ` : `
        ${ListItemStyled}:hover & {
            color: transparent;
        }
        `};
    }
`;

const StyledAvatarOverlay = styled.div`
    display: none;
    width: 24px;
    height: 24px;
    position: absolute;
    z-index: 1;
    top: ${({ showRoles, isTypeIcon }) => ((showRoles || isTypeIcon) ? '20px' : '16px')};
    left: ${({ isTypeIcon }) => (isTypeIcon ? '12px' : '16px')};
    && {
        ${({ selected }) => selected ? `
        display: block;
        background-image: url(${CheckBox});
        background-repeat: no-repeat;
        background-position: center center;
        ` : `
        ${ListItemStyled}:hover & {
            display: block;
            background-image: url(${CheckBoxBlank});
            background-repeat: no-repeat;
            background-position: center center;
        }
        `};
    }
`;

const ResourcesList = styled.div`
    div[class*='Container__ContainerStyle'] {
        background-color: transparent;
        margin-bottom: 0;
    }
    div[class*='VirtualList__TinyVirtual'] {
        padding-top: 0 !important;
        margin-top: 0.2em;
    }
    height: ${isMobile ? '60vh' : '50vh'} !important;
    padding-bottom: 36px;
`;

const StyledButton = styled(Button)`
    margin: 0;
    color: #7391D0;
`;

const StyledDivider = styled(Divider)`
    margin: 5px 0 !important;
`;

const SearchWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding 5px 16px;
`;

const StyledBox = styled(Box)`
    display: flex;
    align-items: center;
    width: 100%;
`;

const StyledTextField = styled(TextField)`
    margin-left: 5px !important;
`;

// const StyledLink = styled(Link)`
//     white-space: nowrap;
//     text-decoration: none !important;
// `;

class AddResourcesModal extends PureComponent<Object, Object> {
    static propTypes = {
        closeModal: PropTypes.func.isRequired,
        type: PropTypes.string.isRequired,
        isOpen: PropTypes.bool,
        isLoading: PropTypes.bool,
        startIndex: PropTypes.number,
        records: PropTypes.array,
        totalRecords: PropTypes.number,
        loadResourceList: PropTypes.func.isRequired,
        isBulkSelect: PropTypes.bool,
        modalTitle: PropTypes.string,
        submitBtnLabel: PropTypes.string,
        addedResourcesList: PropTypes.array,
        onSubmit: PropTypes.func.isRequired,
        selectedItems: PropTypes.array,
    };

    state = Immutable({
        search: '',
        filterBy: [],
        orderBy: [],
        selectedItem: {},
        selectedItems: []
    });

    defaultState: Object = this.state; // preserve the initial state in a new object.

    virtualListRef = React.createRef();
    rolesMap = {};

    constructor(props) {
        super(props);
        props.clearResourcesList();
    }

    @bind
    renderListItemActions(data){
        const { id } = data;
        const { userRole, showRoles, isAdmin } = this.props;
        if (showRoles) {
            return (
                <RolesSelect
                    key={id}
                    defaulValue={isAdmin ? defaultRole : userRole || defaultRole}
                    setRole={role => this.setRoleMap(id, role)}
                    selectedRole={this.rolesMap[id]}
                />
            );
        }
        return null;
    }

    componentDidUpdate(prevProps) {
        const { isOpen, isBulkSelect, selectedItems } = this.props;
        if (prevProps.isOpen !== isOpen && isOpen) {
            if (isBulkSelect && !isEmpty(selectedItems)) {
                this.setState({ selectedItems });
            }
        }
    }

    @bind
    setRoleMap(id, role){
        if (role) {
            this.rolesMap[id] = role;
        }
    }

    @bind
    renderComponent({ style, index, data }: { style: Object, index: number, data: Object }) {
        const { id, name, type: entityType, image } = data;
        const { type, isBulkSelect, showRoles } = this.props;
        const { selectedItem: { id: selectedItemId } = {} } = this.state;
        const selected = !isBulkSelect ? selectedItemId === id : !isEmpty(this.getSelectedListItem(id));
        const avatarImg = image ? getAttachmentUrl(id, type, image) : null;
        let subTitle = `#${id}`;
        let selectedData = { id, name, type: entityType || type };
        if (type === 'user') {
            subTitle = getStr(data, '', '');
            selectedData = { ...selectedData, username: data.username };
        } else if (type === 'primaryClass') {
            const { uri, color, icon } = data || {};
            selectedData = { ...selectedData, uri, color, icon };
        } else if(type === 'relationDefinition'){
            selectedData = { ...selectedData, ...data, name: `${data?.description} - ${data?.relatedDescription}`  };
        }

        return (
            <div style={style} key={index}>
                <ListItemStyled
                    component={
                        ['primaryClass'].includes(type) ? (
                            <div>
                                <StyledTypeIcon 
                                    type={'class'} 
                                    color={data.color} 
                                    size={'lg'} 
                                    selected={selected}
                                />
                                <StyledAvatarOverlay selected={selected} showRoles={showRoles} isTypeIcon={true} />
                            </div>
                        ) : (
                            <div>
                                <StyledAvatar
                                    selected={selected}
                                    initials={selectedData?.name}
                                    src={avatarImg}
                                />
                                <StyledAvatarOverlay selected={selected} showRoles={showRoles} isTypeIcon={false} />
                            </div>
                        )
                    }
                    title={selectedData?.name}
                    subTitle={subTitle}
                    raised
                    onClick={e => this.onSelectListItem(e, selectedData)}
                    selected={selected}
                    actions={this.renderListItemActions(data)}
                />
            </div>
        );
    }

    @bind
    onSelectListItem(event: Object, data: Object) {
        event.preventDefault();
        if (!this.props.isBulkSelect) {
            const { selectedItem } = this.state;
            const { id: selectedItemId } = selectedItem || {};
            const selectedValue = !isEmpty(selectedItem) && selectedItemId === data.id ? {} : data;
            this.setState({ selectedItem: selectedValue });
            this.updateList();
        } else {
            const selectedItem = this.getSelectedListItem(data.id);
            if (isEmpty(selectedItem)) {
                this.setState(
                    produce((draft) => {
                        draft.selectedItems.push(data);
                    }),
                    this.updateList
                );
            } else {
                this.setState(
                    prevState => ({
                        selectedItems: prevState.selectedItems.filter(
                            ({ id }) => id !== data.id
                        )
                    }),
                    this.updateList
                );
            }
        }
    }

    @bind
    getSelectedListItem(itemId: number) {
        if (!itemId) {
            return {};
        }
        const { selectedItems } = this.state;
        return selectedItems.find(({ id }) => id === itemId) || {};
    }

    @bind
    updateList() {
        if (this.virtualListRef && this.virtualListRef.current) {
            this.virtualListRef.current.forceUpdate();
        }
    }

    @bind
    async onSubmit() {
        const { selectedItem, selectedItems } = this.state;
        let selectedValue = null;
        if (!this.props.isBulkSelect) {
            const role = this.rolesMap[selectedItem.id];
            selectedValue = !isEmpty(role) ? { ...selectedItem, role } : selectedItem;
        } else {
            selectedValue = selectedItems.map((item) => {
                const role = this.rolesMap[item.id];
                return !isEmpty(role) ? { ...item, role } : item;
            });
        }
        await this.props.onSubmit(selectedValue);
        this.closeModal();
    }

    @bind
    onChangeSearch(e: Object) {
        if (e.persist) {
            e.persist();
        }
        this.setState({ search: e.target.value }, () => {
            const { search } = this.state;
            const { searchBy } = this.props;
            const value = !isEmpty(search) ? search.trim() : '';
            if (searchBy && Array.isArray(searchBy)) {
                const fields = searchBy.map(field => ({ field, op: 'contains', value }));
                return this.setState({ filterBy: [{ or: fields }] });
            }
            const searchFilter = { field: searchBy || 'name', op: 'contains', value };

            this.setState({
                filterBy: [searchFilter]
            });
        });
    }

    @bind
    loadData(opts: Object) {
        const { addedResourcesList, records, type, filterBy, loadResourceList } = this.props;
        const isClientSide = ['user', 'primaryClass', 'permission'].includes(type);
        const options = { ...opts };
        options.filterBy = [...opts.filterBy, ...(filterBy || [])];
        if (!isEmpty(addedResourcesList)) {
            const addedResourcesListIdsList = addedResourcesList.map(({ id }) => id);
            options.filterBy = [...options.filterBy, { field: 'id', op: 'not in', value: addedResourcesListIdsList }];
        }
        if (type && (!isClientSide || (isClientSide && !records.length))) {
            return loadResourceList(options, type, this.props.applicableOn);
        }
        return Promise.resolve();
    }

    @bind
    closeModal() {
        this.props.clearResourcesList();
        this.rolesMap = {};
        this.setState(this.defaultState, this.props.closeModal);
    }

    @bind
    filterRecords(records, search, addedResourcesList) {
        const addedResourceKeys = (addedResourcesList || []).map(resource => resource && resource.id);

        return records.filter((record) => {
            if (addedResourceKeys.includes(record.id)) {
                return false;
            }
            if (search) {
                return record.name?.toLowerCase()?.includes(search.toLowerCase());
            }
            return true;
        });

    }

    render() {
        const {
            isOpen,
            isMobileView,
            isBulkSelect,
            isLoading,
            records,
            startIndex: start = 0,
            totalRecords: count,
            type,
            addedResourcesList,
            modalTitle,
            submitBtnLabel,
            withoutClose,
            closeIcon,
            hideCancelBtn
        } = this.props;
        const { selectedItem, selectedItems, filterBy, orderBy, search } = this.state;
        const selectData = !isBulkSelect ? selectedItem : selectedItems;
        const isClientSide = ['user', 'primaryClass', 'permission'].includes(type);
        const filteredRecords = isClientSide ? this.filterRecords(records, search, addedResourcesList) : records;
        const totalRecords = isClientSide ? (filteredRecords || []).length : count;
        const startIndex = isClientSide ? 0 : start;
        const defaultTitle = `Add ${typeTitlesMultiple[type]?.toLowerCase() || 'Entities'}`;
        const title = !isEmpty(modalTitle) ? `${modalTitle}` : defaultTitle;

        return (
            isOpen && (
                <ModalDialog
                    bgcolor={'rgba(40, 51, 75, 1)'}
                    dialogProps={{ overflow: 'hidden' }}
                    withoutClose={withoutClose}
                    onClose={this.closeModal}
                    closeIcon={closeIcon}
                    title={title}
                    actions={
                        isLoading ? (
                            <CircularProgress size={24} color="primary" />
                        ) : (
                            <>
                                {!hideCancelBtn && (<StyledButton onClick={this.closeModal}>Cancel</StyledButton>)}
                                <Button disabled={isEmpty(selectData)} color='primary' onClick={this.onSubmit} >
                                    {!isEmpty(submitBtnLabel) ? submitBtnLabel : 'Add'}
                                </Button>
                            </>
                        )
                    }
                >
                    <StyledDivider />
                    <SearchWrapper>
                        <StyledBox>
                            <Icon name='magnify' />
                            <StyledTextField
                                variant='standard'
                                onChange={this.onChangeSearch}
                                value={search}
                                fullWidth
                                margin='none'
                                placeholder={`Search ${(typeTitlesMultiple[type] || type || '').toLowerCase()}...`}
                                InputProps={inputProps}
                                inputRef={ref => !isMobileView && focusInput(ref)}
                            />
                        </StyledBox>
                        {/* <StyledLink onClick={() => {}}>
                            Advance search
                        </StyledLink> */}
                    </SearchWrapper>
                    <StyledDivider />
                    <ResourcesList>
                        <VirtualListManaged
                            ref={this.virtualListRef}
                            renderComponent={this.renderComponent}
                            itemSize={84}
                            title={`${totalRecords >= 1000 ? '999+' : totalRecords} ${(typeTitlesMultiple[type] || type || '').toLowerCase()}`}
                            itemCount={totalRecords || 0}
                            loadData={this.loadData}
                            isLoading={isLoading}
                            startIndex={startIndex}
                            filterBy={filterBy}
                            orderBy={orderBy}
                            list={filteredRecords}
                            maxWidth="1024"
                        />
                    </ResourcesList>
                </ModalDialog>
            )
        );
    }
}

export default connect(
    state => ({
        isSubmitting: state.admin.workspaces.details.isLoading,
        isLoading: state.admin.workspaces.resourceList.isLoading,
        records: state.admin.workspaces.resourceList.records,
        startIndex: state.admin.workspaces.resourceList.startIndex,
        totalRecords: state.admin.workspaces.resourceList.count,
        isAdmin: state.user?.profile?.isAdmin || false,
        isMobileView: state.global.isMobile
    }),
    {
        loadResourceList,
        clearResourcesList
    }
)(AddResourcesModal);
