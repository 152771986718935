/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import DotMenu from 'app/components/molecules/DotMenu/DotMenu';
import ScriptAbout from '../Designer/Script/Tabs/ScriptAbout';
import Loader from 'app/components/atoms/Loader/Loader';
import history from 'store/History';
import ChatSharing from 'app/components/organisms/Chat/ChatSharing';
import EntityVersions from 'app/containers/Entities/EntityVersions/EntityVersions';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { loadEntity } from 'store/actions/entities/entitiesActions';
import { mediaBreakpoints } from 'app/themes/breakpoints';
import { getEntityUrl } from 'app/utils/entity/entityUtils';
import { setActions, setSubTitle, closeSidebar, setTitle } from 'store/actions/sidebar/sidebarActions';
import { shrinkSidebar, expandSidebar } from 'store/actions/sidebar/sidebarActions';
import { getPermissions } from 'app/config/rolesConfig';

class ScriptSidebar extends PureComponent<Object, Object> {

    static propTypes = {
        id: PropTypes.string,
        type: PropTypes.string,
        title: PropTypes.string,
        isOpen: PropTypes.bool,
        // from redux
        isLoading: PropTypes.bool,
        details: PropTypes.object,
    };

    constructor(props: Object) {
        super(props);
        this.initData();
    };

    componentDidUpdate(prevProps) {
        this.initData();
    }

    @bind
    setActions(expanded, sidebarTitle) {
        this.props.setActions(this.dotMenu(expanded, sidebarTitle));
    }

    @bind
    @memoize()
    setSubTitle(name) {
        this.props.setSubTitle(name);
    }

    initData() {
        const { type, id, isOpen, isLoading, details, expanded, title } = this.props;
        if (!isOpen) {
            return;
        }
        this.setActions(expanded, title);
        if (isLoading) {
            return;
        }
        const loaded = details || {};
        if (loaded.id === id) {
            this.setSubTitle(details.name);
            return;
        }
        this.props.loadEntity(type, id);
    }

    @bind
    onDotMenuClick(title) {
        if(['Collapse', 'Expand'].includes(title)) {
            const { expanded, expandSidebar, shrinkSidebar } = this.props;
            const action = expanded ? shrinkSidebar : expandSidebar;
            action();
            return;
        }
        if(title === 'Go to details') {
            this.goToDetails();
        }
        this.props.setTitle(title);
    }

    goToDetails() {
        const { windowWidth, id, type, shrinkSidebar, closeSidebar } = this.props;
        history.push(getEntityUrl(id, type));
        if (!windowWidth < mediaBreakpoints.sm) {
            shrinkSidebar();
        } else {
            closeSidebar();
        }
    }

    @bind
    @memoize()
    dotMenu(expanded: boolean, sidebarTitle) {
        return (
            <DotMenu
                key={13}
                onItemClick={this.onDotMenuClick}
                items={[
                    sidebarTitle !== 'About' && { name: 'About', icon: 'information-outline' },
                    sidebarTitle !== 'Sharing' && { name: 'Sharing', icon: 'share-variant' },
                    { name: 'divider' },
                    /*
                     *  If you are changing the `name` check methog `onDotMenuClick` for changes
                     */
                    { name: expanded ? 'Collapse' : 'Expand', icon: expanded ? 'arrow-collapse' : 'open-in-new' },
                ].filter(Boolean)}
            />
        );
    }

    render() {
        const { isLoading, id, type, title, details, isOpen } = this.props;
        const role = details?.role;
        const { canEdit } = getPermissions(role);
        const isLoaded = details;
        return (
            isOpen && (
                <>
                    {isLoading && <Loader absolute backdrop />}
                    {isLoaded && title === 'About' && (
                        <ScriptAbout
                            id={id}
                            type={type}
                            details={details}
                            canEdit={canEdit}
                            isSidebar
                        />
                    )}
                    {isLoaded && title === 'Sharing' && (
                        <ChatSharing
                            rid={null}
                            rel={null}
                            canEdit={canEdit}
                            role={role}
                            id={details.id}
                            type={details.type}
                            selectedSidebar={'share'}
                        />
                    )}
                    {isLoaded && title === 'Versions' && (
                        <EntityVersions disabled={!canEdit} details={details} />
                    )}
                </>
            )
        );
    }
}

export default connect(
    (state: Object, ownProps: Object) =>  {
        const { id: propsId } = ownProps;
        const details = state.entities.sidebar.details.data;
        const { id } = details || {};
        return { 
            isLoading: state.entities.sidebar.details.isLoading,
            details: propsId !== id ? null : details,
            isOpen: state.sidebar.isOpen,
            expanded: state.sidebar.expanded,
            windowWidth: state.global.window.width,
            title: state.sidebar.title,
            actions: state.sidebar.actions};
    },
    { shrinkSidebar, expandSidebar, loadEntity, closeSidebar, setActions, setSubTitle, setTitle },
)(withRouter(ScriptSidebar));
