/* @flow */

import { gql } from '@apollo/client';
import loadTeamDetailCommonQuery from 'graphql/teams/loadTeamDetailCommonQuery';

export const addTeamOwnersMutation =  gql`
    mutation addTeamOwnersMutation($id: ID!, $owners: [ID]!) {
        result: teamAddOwners(id: $id, owners: $owners) {
            ${loadTeamDetailCommonQuery}
        }
    }
`;


export const removeTeamOwnersMutation =  gql`
    mutation removeTeamOwnersMutation($id: ID!, $owners: [ID]!) {
        result: teamRemoveOwners(id: $id, owners: $owners) {
            ${loadTeamDetailCommonQuery}
        }
    }
`;
