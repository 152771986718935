/* @flow */

import { gql } from '@apollo/client';

export default gql`
mutation createTeamMutation($record: TeamCreateInput!) {
    createTeam(record: $record) {
        id
    }
}
`;
