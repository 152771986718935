/* @flow */

import { gql } from '@apollo/client';

export default gql`
    query relationDefinitionQuery($id: Int) {
        result: relationDefinition(id: $id) {
            id
            classification {
                active
                _extendedFormFields
            }
        }
    }
`;
