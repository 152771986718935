/* @flow */

import { gql } from '@apollo/client';
import entitiesListShortCommonQuery from 'graphql/entities/common/entitiesListShortCommonQuery';

export default gql`
    query printTemplatesListShortQuery($startIndex: Int, $stopIndex: Int, $filterBy: [JSON], $orderBy: [JSON], $excludeBy: [JSON]) {
        count: count(dataType: "print-template", filterBy: $filterBy, excludeBy: $excludeBy)
        records: printTemplates(startIndex: $startIndex, stopIndex: $stopIndex, filterBy: $filterBy, orderBy: $orderBy, excludeBy: $excludeBy) {
            ${entitiesListShortCommonQuery}
        }
    }
`;
