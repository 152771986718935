/* @flow */

import { gql } from '@apollo/client';

export default gql`
query widgetTasksCountByStartDateQuery(
  $passed: [JSON], $passedExclude: [JSON],
  $today: [JSON], $todayExclude: [JSON],
  $upcoming: [JSON], $upcomingExclude: [JSON],
  $notSet: [JSON], $notSetExclude: [JSON]
) {
  passed: count(dataType: "task", filterBy: $passed, excludeBy: $passedExclude )
  today: count(dataType: "task", filterBy: $today, excludeBy: $todayExclude)
  upcoming: count(dataType: "task", filterBy: $upcoming, excludeBy: $upcomingExclude)
  notSet: count(dataType: "task", filterBy: $notSet, excludeBy: $notSetExclude)
}
`;
