import Immutable from 'app/utils/immutable/Immutable';
import { SET_WINDOW_SIZE } from 'store/actions/global/globalActions';
import { mediaBreakpoints } from 'app/themes/breakpoints';

/**
 * Global reducer.
 *
 * @param state - the current Redux state
 * @param action - the dispatched action
 * @returns the new Redux state
 */
const reducer = (state = Immutable({
    lastActionType: null, lastActionError: false, lastActionMeta: null,
    window: { width: 0,  height: 0 }, isMobile: false
}), action) => {
    const { type, error, payload, meta} = action;
    let next = {
        ...state,
        lastActionType: type,
        lastActionError: !!error,
        lastActionMeta: meta,
        lastActionPayload: payload,
    };

    switch (type) {
        case SET_WINDOW_SIZE:
            const isMobile = payload.width < mediaBreakpoints.md;
            next = { ...next, window: payload, isMobile };
            break;
        default:
    }

    return Immutable(next);
};

export default reducer;
