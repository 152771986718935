/* @flow */

import Immutable from 'app/utils/immutable/Immutable';

import {
    DISABLE_FILTER_BUTTON,
    ENABLE_FILTER_BUTTON

} from 'store/actions/filterbar/filterbarActions';

const initialState = {
    disabledButton: false
};

export default (state: Object = initialState, action: Function) => {
    const { type } = action;
    switch (type) {
        case DISABLE_FILTER_BUTTON:
            return Immutable({ ...state, disabledButton: true });
        case ENABLE_FILTER_BUTTON:
            return Immutable({ ...state, disabledButton: false });
    
        default:
            return state;
    }
};