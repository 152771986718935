import React, { Component } from 'react';
import styled from 'styled-components';
import { Typography, Tooltip } from '@mic3/platform-ui';

import { isDefined } from 'app/utils/utils';

const Label = styled(Typography)`
    color: ${({ theme }) => theme.material.colors.text.secondary} !important;
    font-size: 14px !important;
    padding: 5px 20px;
    cursor: pointer;
`;

class GridTasks extends Component {
  
    render() {
        const { data, openSidebar } = this.props;
        const { tasks } = data;
        const counter = tasks.length;
        const title = 'Tasks';
        return (
            (isDefined(tasks) && counter !== 0 ) ?
                <Tooltip title='Open Tasks'>
                    <Label  onClick={()=> openSidebar(title, data)} >
                        {counter}
                    </Label>
                </Tooltip>
                : null
        );
    }
}
export default (GridTasks);