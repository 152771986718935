/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { bind } from 'app/utils/decorators/decoratorUtils';
import { get } from 'app/utils/lo/lo';

import Sidebar from 'app/components/templates/Sidebar';
import { closeSidebar, resizeSidebar, setOnBack } from 'store/actions/sidebar/sidebarActions';
import { closeFilterbar } from 'store/actions/filterbar/filterbarActions';
import { DEFAULT_RIGHT_SIDEBAR_WIDTH, MIN_RIGHT_SIDEBAR_WIDTH } from 'app/config/config';

class GlobalSidebar extends PureComponent<Object, Object> {
    static propTypes = {
        title: PropTypes.string,
        subTitle: PropTypes.string,
        actions: PropTypes.any,
        content: PropTypes.any,
        isOpen: PropTypes.bool,
        expanded: PropTypes.bool,
        isMobile: PropTypes.bool
    };

    constructor(props) {
        super(props);
        this.state = {
            sidebarWidth: DEFAULT_RIGHT_SIDEBAR_WIDTH,
        };
    }

    componentDidUpdate(prevProps) {
        const prevPath = get(prevProps, 'location.pathname', '');
        const currentPath = get(this.props, 'location.pathname', '');
        const { 
            closeFilterbar, 
            closeSidebar, 
            setOnBack, 
            sidebarOpts: { onBack, title, isOpen: sidebarIsOpen }, 
            filterbarOpts: { isOpen: filterIsOpen } 
        } = this.props;

        if (onBack && prevProps.sidebarOpts.title !== title) {
            setOnBack(null);
        }

        if (prevPath !== currentPath) {
            closeFilterbar();
            closeSidebar();
        }
        if (!prevProps.sidebarOpts.isOpen && sidebarIsOpen && filterIsOpen) {
            closeFilterbar();
        } else if (!prevProps.filterbarOpts.isOpen && filterIsOpen && sidebarIsOpen) {
            closeSidebar();
        }
    }

    @bind
    handleResize(width: number) {
        this.setState(state => ({
            sidebarWidth: width
        }));
    }

    @bind
    renderSidebar(options: Object, closeSidebar: func, resizeSidebar: func, isMobile, type, activeSidebar) {
        const {
            title, subTitle, subTitleRight, actions, content, isOpen, expanded, onBack, afterCloseSidebar, iconName, iconType
        } = options;
        const { sidebarWidth } = this.state;

        return isOpen && (
            <Sidebar
                iconName={iconName}
                iconType={iconType}
                handleHorizonalScoll={this.props.handleHorizonalScoll}
                activeSidebar={activeSidebar}
                isMobile={isMobile}
                fullScreen={expanded}
                onClose={closeSidebar}
                afterCloseSidebar={afterCloseSidebar}
                onBack={onBack}
                isResizing={resizeSidebar}
                sidebarWidth={sidebarWidth}
                onResize={this.handleResize}
                title={title}
                subTitle={subTitle}
                subTitleRight={subTitleRight}
                className={`global-template-sidebar sidebar-type-${type}`}
                actions={actions}
                minSidebarWidth={MIN_RIGHT_SIDEBAR_WIDTH}
            >
                {content}
            </Sidebar>
        );
    }

    render() {
        const {
            sidebarOpts, filterbarOpts, closeSidebar, closeFilterbar, resizeSidebar, isMobile, activeSidebar
        } = this.props;
        return (
            <section id="global-right-panel">
                {this.renderSidebar(sidebarOpts, closeSidebar, resizeSidebar, isMobile, 'common', activeSidebar)}
                {this.renderSidebar(filterbarOpts, closeFilterbar, resizeSidebar, isMobile, 'filter', activeSidebar)}
            </section>
        );
    }
}

export default connect(
    (state: Object, ownProps: Object) => {
        return ({
            sidebarOpts: state.sidebar,
            filterbarOpts: state.filterbar,
            isMobile: state.global.isMobile,
            activeSidebar: state.app.activeSidebar,
            handleHorizonalScoll: state.sidebar.handleHorizonalScoll
        });
    },
    { closeSidebar, closeFilterbar, resizeSidebar, setOnBack },
)(withRouter(GlobalSidebar));
