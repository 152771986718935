/* @flow */

import { gql } from '@apollo/client';

export default (actions) => gql`
  mutation updateLoginActionsMutation($username: String!) {
    updateUserLoginActions(
      username: $username, 
      actions: [
        ${actions.CONFIGURE_TOTP ? 'CONFIGURE_TOTP,' : ''}
        ${actions.UPDATE_PASSWORD ? 'UPDATE_PASSWORD,' : ''}
        ${actions.VERIFY_EMAIL ? 'VERIFY_EMAIL,' : ''}
      ]
    )
  }
`;
