/* @flow */

import { id, name, formReference, formVersionReference, documentationPanel } from 'app/utils/designer/process/settings/common/commonImportant';
import { executionListener } from 'app/utils/designer/process/settings/common/commonListeners';

const panels = [
    {
        header: 'Important',
        expanded: true,
        children: [
            id,
            name,
            {
                type: 'text',
                properties: {
                    label: 'Initiator',
                    name: 'flowable:initiator',
                },
            },
            formReference,
            formVersionReference
        ],
    },
    documentationPanel,
    {
        header: 'Listeners',
        children: [executionListener]
    },
];

export default {
    defaults: {},
    panels: settingValues => panels,
};
