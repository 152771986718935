/* @flow */
import memoize from 'memoize-one';

import { isEmpty } from 'app/utils/utils';
import { rocketHost } from 'app/utils/env';
import { get } from 'app/utils/lo/lo';

export const KEYS = {
    ESCAPE: 'Escape',
    UP: 'ArrowUp',
    DOWN: 'ArrowDown',
    LEFT: 'ArrowLeft',
    RIGHT: 'ArrowRight',
    ENTER: 'Enter'
};

export const validTypes = new Set(['entity', 'process', 'task']);

export const oneMinute = 60000;
export const refreshRate = 10 * oneMinute;

const isDateValid = (date) => {
    return date instanceof Date && !isNaN(date);
};


export const getSubscriptionByRel = memoize(
    (subscriptions: Array<Object>, rel: Object) => {
        if (!rel || !rel.id || !rel.type) {
            return null;
        }
        const { id } = rel;
        const subscription = (subscriptions || []).find(
            subscription => subscription.rel.id === id
        );

        return subscription ? subscription : null;
    }
);

export const getSubscriptionByRid = memoize(
    (subscriptions: Array<Object>, rid: string) => {
        if (isEmpty(subscriptions) || isEmpty(rid)) {
            return null;
        }
        const subscription = (subscriptions || []).find(
            subscription => subscription.rid === rid
        );

        return subscription ? subscription : null;
    }
);

export const getSubscriptionByUsername = memoize(
    (subscriptions: Array<Object>, username: string) => {
        if (isEmpty(subscriptions) || isEmpty(username)) {
            return null;
        }
        const subscription = (subscriptions || []).find(
            subscription => subscription.name === username
        );

        return subscription ? subscription : null;
    }
);

// search chats by name || id
export const searchChats = (keyword: string, chats: Array) => {
    const query = !isEmpty(keyword) ? keyword.trim().toLowerCase() : '';
    let result = [];

    function searchByNameOrId(chat) {
        const { rel, customFields } = chat || {};
        const isEntityChat = !isEmpty(customFields);
        let isFound = false;
        if (isEmpty(rel)) return isFound; 
        const { id, name } = rel;

        if (!isEntityChat) {
            if (!name) return isFound;
            const qName = name.toLowerCase();
            isFound = qName.indexOf(query) >= 0;
            return isFound;
        } else {
            if (name) {
                const relName = name.toLowerCase();
                isFound = relName.indexOf(query) >= 0;
                if (isFound) return true;
            }
            if (id) {
                const relId = id.toLowerCase();
                isFound = relId.indexOf(query) >= 0;
                if (isFound) return true;
            }
            return isFound;
        }
    }
    result = (chats || []).filter(searchByNameOrId);

    return result;
};

export const sortChats = (sortBy: string, chats: Array) => {
    const parsedData = [...chats];
    switch (sortBy) {
        case 'name':
            parsedData.sort((data1, data2) => {
                const relName1 = data1.rel.name.toUpperCase();
                const relName2 = data2.rel.name.toUpperCase();
                return relName1 < relName2 ? -1 : relName1 > relName2 ? 1 : 0;
            });
            break;
        case 'activity':
            parsedData.sort((data1, data2) => {
                const { lastMessage: lastMessage1 } = data1;
                const { lastMessage: lastMessage2 } = data2;
                const date1 = !isEmpty(lastMessage1) ? new Date(lastMessage1.createDate) : new Date(data1.updatedAt);
                const date2 = !isEmpty(lastMessage2) ? new Date(lastMessage2.createDate) : new Date(data2.updatedAt);
                return date2 - date1;
            });
            break;
        default:
            return chats;
    }
    return parsedData;
};


export const getChatRequestType = (type: string) => {
    let chatType = '';
    const isEntityChat = !['channel', 'direct', 'group', 'live'].includes(type);
    if (isEntityChat || type === 'group') {
        chatType = 'groups';
    } else {
        switch (type) {
            case 'direct':
                chatType = 'im';
                break;
            case 'channel':
                chatType = 'channels';
                break;
            default:
                chatType = type;
                break;
        }
    }
    return chatType;
};

export const replaceAll = (str, find, replace) => {
    const escapedFind = find.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1'); // eslint-disable-line
    return str.replace(new RegExp(escapedFind, 'g'), replace);
};

export const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
};

export const checkIfUserIsMember = (userId, data) => {
    if (!data) return false;
    const user = (data?.users || []).find(d => d.user?.id === userId);
    return Boolean(user);
};


export const getRoomImages = (messages) => {
    const imageTypes = ['image/svg+xml', 'image/gif', 'image/jpeg', 'image/jpg', 'image/png'];

    return !isEmpty(messages) ? 
        (messages || [])
            .filter(({ file }) => !isEmpty(file) && imageTypes.includes(file?.type))
            .map(({ file }) => {
                const { id: fileId, name: fileName } = file || {};
                const downloadUrl = `${document.location.protocol}//${rocketHost}/file-upload/${fileId}/${fileName}?download`;
                const src = `/chat/file-upload/${fileId}/${fileName}`;
                return { id: file.id, src, downloadUrl };
            }) : 
        [];
};

export const lastEditableMessage = (messages, currentUsername) => {
    for (let i = messages.length - 1; i >= 0; i--) {
        if (isMessageEditable(messages[i], currentUsername)) {
            return i;
        }
    }
    return -1;
};

export const getNextEditableMessage = (messages, messageIndex, direction, currentUsername) => {
    if (direction === 'up') {
        for (let i = messageIndex - 1; i >= 0; i--) {
            if (isMessageEditable(messages[i], currentUsername)) {
                return i;
            }
        }
    } else if (direction === 'down') {
        for (let i = messageIndex + 1; i < messages.length; i++) {
            if (isMessageEditable(messages[i], currentUsername)) {
                return i;
            }
        }
    }
    return -1;
};

export const isMessageEditable = (message, currentUsername) => {
    const { user, type, file } = message || {};
    if (
        !type &&
        isEmpty(file) &&
        user.username === currentUsername
    ) {
        return true;
    }
    return false;
};

export const getSelectedMessage = (messages, editMsgId, currentUsername, direction) => {
    const currentEditedMesssageIndex = messages.findIndex((msg) => msg.id === editMsgId);
    let nextMessageIndex = 0;

    if (direction === 'up') {
        nextMessageIndex = currentEditedMesssageIndex !== 0 ? 
            getNextEditableMessage(messages, currentEditedMesssageIndex, 'up', currentUsername) : 
            lastEditableMessage(messages, currentUsername);
        nextMessageIndex = nextMessageIndex !== -1 ? nextMessageIndex : lastEditableMessage(messages, currentUsername);
    } else if (direction === 'down') {
        if (currentEditedMesssageIndex !== -1) {
            nextMessageIndex = currentEditedMesssageIndex === messages.length - 1 ? 
                -1 : 
                getNextEditableMessage(messages, currentEditedMesssageIndex, 'down', currentUsername);
        } else {
            nextMessageIndex = -1;
        }
    }
    
    return messages.find((message) => isMessageEditable(message, currentUsername) && messages[nextMessageIndex]?.id === message?.id);
};

export  const getInitialRoomMembers = memoize(
    (messages) => {
        const roomMemberKey = [];
        const roomMembers = [];
        
        for (let i = messages.length - 1; i >= 0; i--) {
            const { username, name } = get(messages[i], 'user');

            if (username !== 'affectli_rocket_admin' && !roomMemberKey.includes(username) && roomMemberKey.length <= 5) {
                roomMemberKey.push(username);
                roomMembers.push({
                    username,
                    name,
                    isSuggested: true
                });
            }

            if (roomMemberKey.length === 5) {
                break;
            }
        }

        return roomMembers;
    }
);

export const getCurrentWord = (inputRef) => {
    if (!inputRef?.current) return '';

    const input = inputRef.current;
    const start = input.selectionStart;
    const value = input.value;

    let text = '';
    
    for (let i = start - 1; i >= 0; i--) {
        if (value[i] === ' ' || value[i] === '' || value[i] === '\n') break;
        text = value[i] + text;
    }

    return text;
};

export const findPreviousAtSymbol = (text, position) => {
    let index = position;
    while (index >= 0 && (text[index] !== '@' || (text[index] === '@' && (text[index-1] !== ' ' && text[index-1] !== '' && text[index-1] !== '\n')))) {
        index--;
    }
    return index >= 0 ? index : 0;
};


export const filterMentionedUsers = (inputRef, users, mentionedUsers) => {
    const predefinedUsers = [{ _id: 'all', username: 'all'}, { _id: 'all', username: 'here'}];
    const searchInputValue = getCurrentWord(inputRef).substring(1);
    const query = !isEmpty(searchInputValue) ? searchInputValue.trim().toLowerCase() : '';

    const filterUsername = (users) => {
        return (users || []).filter((user) => {
            const { username, name } = user || {};
            let isFound = false;
            if (isEmpty(user)) return isFound; 
            
            if (username) {
                const parsedUsername = username.toLowerCase();
                isFound = parsedUsername.indexOf(query) >= 0;
                if (isFound) return true;
            }
    
            if (name) {
                const parsedName = name.toLowerCase();
                isFound = parsedName.indexOf(query) >= 0;
                if (isFound) return true;
            }
    
            return isFound;
        });
    };
    
    const withMentionedUsers = [
        ...filterUsername(users),
        ...mentionedUsers,
        ...filterUsername(predefinedUsers),
    ];

    const removedDuplicateUsers = withMentionedUsers.filter((user, index, arr) =>
        index === arr.findIndex((u) => u.username === user.username)
    ).slice(-6);

    return removedDuplicateUsers;
};

export const getUserStatus = (isSuggested, outside) => {
    if (isSuggested) {
        return 'Suggestion from recent messages';
    }
    if (outside) {
        return 'Not in channel';
    }
    return '';
};

export const setInputTextMention = (inputRef, username) => {
    const { selectionStart: currentPosition, value: inputValue } = inputRef.current;
    const startPosition = findPreviousAtSymbol(inputValue, currentPosition);
    const newText = inputValue.substring(0, startPosition) + `@${username} ` + inputValue.substring(currentPosition);
    return { newText, cursorPosition: startPosition + username.length + 2 };
};

export const parseMessage = (text, mentions, primary, callback) => {
    let parsedTextValue = text;
    if (!isEmpty(mentions)) {
        mentions.forEach(({ username }) => {
            let linkClassName = '';
            const isMentionTypeUser = !['all', 'here'].includes(username);
            if (isMentionTypeUser) {
                const isCurrentUser = primary.username === username;
                linkClassName = isCurrentUser ? 'mention-link-me' : 'mention-link-user';
            } else {
                linkClassName = 'mention-link-group';
            }
            parsedTextValue = replaceAll(parsedTextValue, `@${username}`, `<a class='${linkClassName}'>${username}</a>`);
            callback(parsedTextValue);
        });
    } else {
        callback(parsedTextValue);
    }
};

export const focusChatInput = (inputRef) => {
    if (inputRef.current) {
        inputRef.current.focus();
    }
};

export const setChatInputCursorToLast = (inputRef) => {
    inputRef.current.scrollTop = inputRef.current.scrollHeight;
    const value = inputRef.current.value || '';
    const inputLength = value.length;
    inputRef.current.setSelectionRange(inputLength, inputLength);
};

export const getCredentials = (state) => {
    const token = get(state, 'user.chat.token');
    const userId = get(state, 'user.chat.id');
    if (!token || !userId) {
        throw new Error('the chat credentials are not available in the profile.');
    }
    return { userId, token };
};

export const isChatExist = (state, msgId, roomId) => {
    const chatRoomMessages = get(state, `chat.room.messages`, {});
    const chatRoomData = get(state, `chat.room.data`, []);

    if (!isEmpty(chatRoomMessages[roomId])) {
        const selectedRoomMsg = chatRoomMessages[roomId].find(({ id }) => id === msgId);
        return !isEmpty(selectedRoomMsg) ? selectedRoomMsg.text : false;
    } else {
        const selectedChatRoom =  chatRoomData.find(({ _id }) => _id === roomId);
        const { lastMessage: { _id: lastMsgId, text } = {} } = selectedChatRoom || {};
        return lastMsgId === msgId ? text : false;
    }
};

export const normalizeMessage = ((message) => {
    const { editedBy } = message || {};
    const ts = new Date(get(message, 'ts', ''));
    const tsDate = new Date(get(message, 'ts.$date', ''));
    const editedAt = new Date(get(message, 'editedAt.$date', ''));
    const createDate = isDateValid(tsDate) ? tsDate : ts;
    const editedDate = isDateValid(editedAt) ? editedAt : null;

    return  {
        id: message._id,
        type: message.t,
        text: message.msg,
        file: message.file && {
            id: message.file._id,
            name: message.file.name,
            type: message.file.type,
            description: get(message, 'attachments[0].description'),
        },
        md: message.md || [],
        mentions: message.mentions || [],
        createDate,
        editedDate,
        editedBy,
        user: {
            id: get(message, 'u._id'),
            username: get(message, 'u.username'),
            name: get(message, 'u.name'),
        },
    };
});

export const getUserMeta = (state, username) => {
    const userList = get(state, `admin.users.references.data`, []);
    if (!Boolean(userList.length) && !username) return false;
    const { id, image } = userList.find(u => u.username === username) || {};
    return !id ? false : { id, image };
};