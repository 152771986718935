// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { bind } from 'app/utils/decorators/decoratorUtils';
import { commonAbout } from 'app/containers/Maps/LayerAbout/CommonAbout';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import { shallowEquals, isObject } from 'app/utils/utils';
import { getEntityAssets } from 'app/utils/maps/layer/layerUtils';
import cesiumLayerConfig from 'app/config/cesiumLayerConfig';
import styled from 'styled-components';
import { updateMapLayer } from 'store/actions/maps/situationalAwarenessActions';
import { __getMapLayers } from 'app/utils/maps/layer/layerUtils';

const StyledDiv = styled.div`
    font-size: 0.9rem;
    color: red;
    padding: 15px;
`;

class CesiumIonLayerAbout extends PureComponent<Object, Object> {

    static propTypes: Object = {
        map: PropTypes.object.isRequired,
        details: PropTypes.object,
    };

    constructor(props: Object) {
        super(props);
        const { details } = props || {};
        const layerForm = details;
        const assetId =  details[cesiumLayerConfig?.cesiumLayer?.accountAttr]?.id;
        const assetType =  'system_cesium_account';
        const assetInfo = details?.[cesiumLayerConfig?.cesiumLayer?.assetAttr];
        const options = assetInfo && [{label: assetInfo.split(',')[0], value: assetInfo}];
        this.getAssets(assetId , assetType);
        this.state = {
            layerForm,
            initialForm: layerForm,
            formChanged: false,
            assetOptions: options || []
        };
    }

    formRef = React.createRef();

    componentDidMount() {
        this.props.setActions(this.onFormSubmit);
    }

    componentDidUpdate() {
        const { setToDefault, setActions } = this.props;
        if (setToDefault) {
            this.setState({ layerForm: this.state.initialForm, formChanged: false });
            setActions && setActions(null);
        }
    }

    @bind
    async getAssets(id, type) {
        const assetOptions = await getEntityAssets({id, type}, this.props.map);
        this.setState({ assetOptions });


    }

    @bind
    buildFormDefinition(data: Object) {
        const { id, name } = this.props?.details || {};
        const cmLayer = cesiumLayerConfig?.cesiumLayer;
        const accAttr = cmLayer?.oldAccAttr;
        const isOld = this.props.details?.primary?.hasOwnProperty(accAttr);
        const cesiumType = cesiumLayerConfig?.cesiumLayer?.type || cesiumLayerConfig?.cesiumLayer?.oldType;
        const cesiumAcc = cesiumLayerConfig?.cesiumAccount;
        return [
            isOld && {
                type: 'custom',
                properties: {
                    label: '',
                    name: 'message',
                    Component: () => (
                        <StyledDiv> The layer is outdated, please recreate the layer. </StyledDiv>
                    )
                }
            },
            ...commonAbout({ ...data, title: this.props.title, id, name, type: cesiumType, isOld }),
            {
                field: 'panel',
                type: 'panel',
                properties: {
                    header: 'Layer Details',
                    expanded: false,
                },
                children: [
                    {
                        field: 'cesium-entities',
                        type: 'entityTypeahead',
                        properties: {
                            entityType: isOld ? cesiumAcc?.oldType : cesiumAcc?.type,
                            label: 'Cesium Ion account entity',
                            name: isOld ? cmLayer?.oldAccAttr : cmLayer?.accountAttr,
                            disabled: isOld
                        },
                        constraints: { required: true }
                    },
                    {
                        type: 'typeahead',
                        properties: {
                            label: 'Cesium Ion asset',
                            name: isOld ? cmLayer?.oldAssetAttr : cmLayer?.assetAttr,
                            options: this.state.assetOptions,
                            disabled: isOld
                        },
                        constraints: { required: true }
                    }
                ]
            }
        ].filter(Boolean);
    }

    @bind
    handleChange(data: Object, { name, value }: Object) {
        let layerForm = data;
        const { map } = this.props;
        const isCesiumAcc = cesiumLayerConfig?.cesiumLayer?.isCesiumAccount(name);
        if(isCesiumAcc) {
            layerForm = { ...layerForm, [name]: value, 'system_cesium_layer/asset': null };
            getEntityAssets(value, map);
        }

        this.setState({ layerForm }, async () => {
            const { initialForm, formChanged } = this.state;
            const changedValue = isObject(value) ? value : { value };
            const initValue = isObject(initialForm[name]) ? initialForm[name] : { value: initialForm[name] };
            if (!formChanged && !shallowEquals(changedValue, initValue)) {
                this.setState({ formChanged: true });
                await this.props.setStatus(true);
                this.props.setActions(this.onFormSubmit);
            } else if (formChanged && shallowEquals(changedValue, initValue)) {
                this.setState({ formChanged: false });
                this.props.setActions(null);
            }
        });
    }

    @bind
    onFormSubmit() {
        this.formRef.current.isValidForm().then(async ({ data, errors }) => {
            if (!errors) {
                const { id, allMapLayers } = this.props;
                const { description, ...rest } = data;
                const foundLayer = allMapLayers.find(layer => layer.id === id);
                const cesiumType = cesiumLayerConfig?.cesiumLayer?.type || cesiumLayerConfig?.cesiumLayer?.oldType;
                
                const updatedLayerData = { 
                    id,
                    name: foundLayer?.name,
                    type: cesiumType,
                    description,
                    active: true,
                    hidden: foundLayer?.hidden || false,
                    opacity: foundLayer?.opacity || 100,
                    primary:  { ...rest }
                };

                this.props.updateMapLayer(updatedLayerData);
                this.setState({ initialForm: { data }, formChanged: false });
                this.props.setActions(null);
            }
        });
    }

    render() {
        const { layerForm } = this.state;
        return (
            <FormGenerator
                data={layerForm}
                onChange={this.handleChange}
                components={this.buildFormDefinition(layerForm)}
                ref={this.formRef}
            />
        );
    }
}

export default connect(
    (state: Object, ownProps: Object) => ({
        allMapLayers: __getMapLayers(state.maps.situationalAwareness.map.data),
    }),
    {
        updateMapLayer
    }
)(CesiumIonLayerAbout);
