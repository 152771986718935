/* eslint-disable array-callback-return */
import React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { getSubscriptionByRel } from 'app/utils/chat/chatUtils';
import { get } from 'app/utils/lo/lo';
import history from 'store/History';
import customIconSet from 'app/components/molecules/Map/EntityPin/customEntityPin.js';
import { openLeftPanel } from 'store/actions/leftPanel/leftPanelActions';
import { Tooltip, IconButton } from '@mic3/platform-ui';
import Icon from 'app/components/atoms/Icon/Icon';
import { getPermissions } from 'app/config/rolesConfig';
import { isDefined, isObject } from 'app/utils/utils';
import { flattenFormDefinitions } from '../classification/classificationUtils';
import { getModulePath } from 'app/utils/classification/classificationUtils';
import { isValidURL, appendHttp } from 'app/utils/string/string-utils';
import { render, compile } from 'app/utils/template/template';

export const commonEntitiesListFilters = [
    { field: 'createdBy', type: 'userTypeahead', properties: { label: 'Created by', name: 'createdById', multiple: true, valueField: 'id' }, condition: 'in' },
    { field: 'modifiedBy', type: 'userTypeahead', properties: { label: 'Modified by', name: 'modifiedById', multiple: true, valueField: 'id' }, condition: 'in' },
    { field: 'modifiedDate', type: 'dateTimeRange', properties: { label: 'Last updated', name: 'modified' }},
    { field: 'createdDate', type: 'dateTimeRange', properties: { label: 'Created Date', name: 'createdDate' } }
];

const getEntityUrlToken = (entityType) => {
    return {
        alive: 'abox/alive',
        backgroundjob: 'abox/background-job',
        task: 'abox/tasks',
        opentask: 'abox/tasks',
        closedtask: 'abox/tasks',
        process: 'abox/process',
        openprocess: 'abox/process',
        closedprocess: 'abox/process',
        event: 'events',
        eventtype: 'events/event-types',
        class: 'classifications',
        entitytype: 'entity-types',
        workspace: 'workspaces',
        map: 'maps',
        integration: 'integration',
        iot_pipeline: 'designer/pipelines',
        iot_connector: 'designer/connectors',
        iot_module: 'designer/smart-modules',
    }[entityType];
};

export const getTab = (sidebarTitle: string) => {
    const title = (sidebarTitle || '').toLowerCase();
    switch (title) {
        case 'relations':
            return 'relationships';
        case 'classes':
            return 'classifications';
        case 'location':
            return 'location';
        case 'attachments':
            return 'attachments';
        case 'digital twin':
            return 'digital-twin';
        case 'history':
            return 'history';
        default:
            return '';
    }
};

export const getEntityUrl = (id, type, options , entitiesView) => {
    switch (type) {
        case 'task':
            return `/abox/tasks/${id}`;
        case 'team': 
            return `/teams/${id}/about`;
        case 'process': 
            return `/abox/process/${id}`;
        case 'event':
            return `/events/${id}/${options?.time}`;
        case 'custom': 
            return `/entities/custom/${id}`;
        case 'user':
            if (entitiesView) {
                return `/entities/user/${id}`;
            }
            return `/user-management/${id}`;
        default:
            break;
    }
    if (options?.adminBackgroundJobs && type === 'backgroundjob') {
        return `/background-job/${id}`;
    }
    if(options?.entityType) {
        return `/entity-types/${id}`;
    }
    if(options?.pipelineMonitor) {
        return `/pipeline-monitor/${id}`;
    }
    return `/${getEntityUrlToken(type) || `entities/${type}`}/${id}`;
};

export const getEntityListUrl = (type) => {
    return `/${getEntityUrlToken(type)}`;
};

export const getUnreadMessages = (id, subscriptions, type) => {
    const subscription = getSubscriptionByRel(subscriptions, { id, type });
    return subscription ? subscription.unread : 0;
};

export const entityTabsDefault = {
    About: { name: 'About', icon: 'information-outline', id: 'About', order: 1, checked: true },
    'A-Live': { name: 'A-Live', icon: 'messenger', iconType: 'af', id: 'A-Live', order: 0, checked: true },
    Views: { name: 'Views', icon: 'cube-outline', id: 'Views', order: 2, checked: true },
    Share: { name: 'Sharing', icon: 'share-variant', id: 'Share', order: 3, checked: true },
    Classes: { name: 'Classes', icon: 'class', iconType: 'af', id: 'Classes', order: 4, checked: true },
    Relations: { name: 'Relations', icon: 'relationships', iconType: 'af', id: 'Relations', order: 5, checked: true },
    Location: { name: 'Location', icon: 'pin', id: 'Location', order: 6, checked: true },
    Attachments: { name: 'Attachments', icon: 'attachment', id: 'Attachments', order: 7, checked: true },
    Rules: { name: 'Rules', icon: 'notebook-outline', id: 'Rules', order: 8, checked: true },
    History: { name: 'History', icon: 'history', id: 'History', order: 9, checked: true }
};

export const isVisibleTab = (title, primaryClass) => {
    const list = get(primaryClass, 'entityTabs.list', []);
    if (list?.length) {
        if (title === 'Sharing') {
            return list.includes('Share');
        }
        return list.includes(title);
    }
    return get(entityTabsDefault, `${title === 'Sharing' ? 'Share' : title}.checked`, false);
};

export const getAttribute = (data, name) => {
    switch (name) {
        case 'iconAttribute':
            return getIconAttribute(data);
        case 'colorAttribute':
            return getColorAttribute(data);
        case 'iconTypeAttribute':
            return getIconTypeAttribute(data);
        default:
            return null;
    }
};

const _getAttributeData = (primaryClass, name, defaultValue) => {
    const { entityAppearance, formDefinition } = primaryClass || {};
    const attName = entityAppearance?.[name] || '';
    if (attName) {
        const attData = flattenFormDefinitions(formDefinition?.fields).find(att => att?.properties?.name === attName);
        return attData?.properties?.defaultValue || defaultValue || '';
    }
    return defaultValue || '';
};

// default coult be "diameter-variant"
export const getIconTypeAttribute = (data) => {
    const { iconName, iconType, primaryClass } = data || {};
    return iconType || _getAttributeData(primaryClass, 'iconAttribute', {type:primaryClass?.iconType})?.type || (customIconSet[iconName] && 'af') || null;
};

export const getIconAttribute = (data) => {
    const { iconName, primaryClass } = data || {};
    return iconName || _getAttributeData(primaryClass, 'iconAttribute', {value:primaryClass?.icon})?.value || null;
};

export const getColorAttribute = (data) => {
    const { iconColor, primaryClass } = data || {};
    return iconColor || _getAttributeData(primaryClass, 'colorAttribute', primaryClass?.color || '#00BCD4');
};

const _normalizeTabTitle = (title) => {
    switch (title) {
        case 'Share':
            return 'Sharing';
        default:
            return title;
    };
};

export const buildPrintTemplates = ({tasksAndProcesses, printTemplateRecords, type, details}) => {
    const templateClasses = tasksAndProcesses?.records?.filter(clss => clss.uri.includes(type));
    const systemType = type === 'task' ? 'system_task' : 'system_process';

    const printTemplatesMap = templateClasses?.reduce((accum, clss) => {
        accum[clss.uri] = clss.id;
        return accum;
    }, {});
    const printTasksProcesses = printTemplatesMap && printTemplateRecords?.filter((tmpl) => {
        if(tmpl?.primary['print-template/applicable_on']?.includes(printTemplatesMap[details.type])
             || tmpl?.primary['print-template/applicable_on']?.includes(printTemplatesMap[systemType])) return tmpl;
    });

    return [
        printTasksProcesses?.length && {name: 'divider'},
        ...(printTasksProcesses?.map(template => ({
            name: template.name,
            icon: 'printer',
            onItemClick: () => {
                history.push(`/print/${template.id}/${details.type}/${details.id}`);
            },
        })) || []),
    ];
};

export const addTypeToList = (list: Array<Object>, type) => {
    if (!list?.length || !type) return [];
    return list.map(item => ({ ...item, type }));
};

export const buildDotMenu = (params) => {
    const { isPrintReports, details, entityType, moreActionItems, title, pathname = '', expanded, indexView, hiddenTabs, entityTemplates, entityTypePage, printTemplateRecords, printTemplatesMap, userId } = params || {};
    if (!details) {
        return [];
    }
    const { primaryClass, id, type, role, classes } = details || {};
    const permissions = getPermissions(role);
    const { canComment } = permissions;
    let printTasksProcesses = null;

    const isProcessDesigner = pathname.includes(`designer/processes/${id}`);
    const isRelations = pathname.endsWith(`${id}/relationships`);
    const isRelatedEntities = pathname.includes('related-entities');
    const isPrintTemplateLayout = pathname.endsWith(`${id}/layout/editor`) || pathname.endsWith(`${id}/layout/preview`);
    const isAbout = pathname.endsWith('/about');
    const isPrintTemplates = pathname.includes('/print-templates') || pathname.includes('/print-template') ;
    const isDigitalTwin = pathname.endsWith('/digital-twin');
    const digitalTwinTab = (!!(classes || []).find(cls => cls?.uri === 'digital-twin') || primaryClass?.digitalTwins?.length)  && { name: 'Digital Twin', icon: 'hexagon' };
    const isSidebar = isDefined(expanded);
    const goToItem = indexView ? [{ name: 'Go to details', icon: 'login-variant' },  { name: 'divider' }] : [];
    const commonSiderbarMenus = isSidebar ? [
        { name: 'divider' },
        { name: expanded ? 'Collapse' : 'Expand', icon: expanded ? 'arrow-collapse' : 'open-in-new' },
        { name: 'Go to details', icon: 'login-variant' }] : [];
    if (moreActionItems) {
        return [{ name: 'Go to details', icon: 'login-variant' }, ...((moreActionItems && moreActionItems(details, userId)) || [])];
    }

    if(printTemplatesMap){
        const systemType =  entityType === 'task' ? 'system_task' : 'system_process';
        printTasksProcesses =  printTemplateRecords?.filter((tmpl) => {
            if(tmpl?.primary['print-template/applicable_on']?.includes(printTemplatesMap[details.type])
                 || tmpl?.primary['print-template/applicable_on']?.includes(printTemplatesMap[systemType])) return tmpl;
        });
    }

    const [relationItem] = [
        isVisibleTab('Relations', primaryClass) && {
            name: 'Relations',
            icon: 'relationships',
            iconType: 'af',
            onItemClick: isRelatedEntities ? null : () => {
                const pathname = window.location.hash.toLowerCase();
                if (pathname?.includes('user-management') && details.type === 'user') {
                    return history.push(`/user-management/${id}/relationships`);
                }
                if (pathname?.includes('/entities/user') 
                || ((pathname?.includes('/entities/person') && details.type === 'user' ))
                || ((pathname?.includes('/entity-types') && details.type === 'user' ))
                || ((pathname?.includes('/classifications') && details.type === 'user' ))
                ){
                    return history.push(`/entities/user/${id}/relationships`);
                }
                history.push(`${getEntityUrl(details.id, details.type)}/relationships`);
            }
        }
    ];

    const printMenu = [
        isVisibleTab('Classes', primaryClass) && details?.type && (entityTemplates?.length || printTasksProcesses?.length) && {name: 'divider'},
        ...((isVisibleTab('Classes', primaryClass) && details.type && printTasksProcesses?.length && printTasksProcesses?.map(template => ({
            name: template.name,
            icon: 'printer',
            onItemClick: () => {
                history.push(`/print/${template.id}/${details.type}/${details.id}`);
            },
        }))) || []),
        ...((isVisibleTab('Classes', primaryClass) && details?.type && !printTasksProcesses?.length && entityTemplates?.length && entityTemplates?.map(template => ({
            name: template.name,
            icon: 'printer',
            onItemClick: () => {
                history.push(`/print/${template.id}/${details.type}/${details.id}`);
            },
        }))) || []),
    ].filter(Boolean);

    const printButton = (((title === 'About') && ((entityType === 'print-template') || isPrintTemplates)) || (((entityType === 'print-template') || isPrintTemplates)&& !isPrintTemplateLayout)) && {
        name: 'Print',
        icon: 'printer',
        onItemClick: () => {
            history.push(`/print/${details.id}`);
        }
    };

    if(isPrintReports) {
        return [
            ...goToItem,
            printButton,
        ].filter(Boolean);
    }

    const processDesignerMenu = isProcessDesigner ? [{ name: 'divider' },
        !pathname.includes('diagram') && { name: 'Diagram', icon: 'blur',
            onItemClick: () => {
                history.push(`/designer/processes/${details.id}/diagram`);
            }, },
        !pathname.includes('xml') && { name: 'XML', icon: 'xml',
            onItemClick: () => {
                history.push(`/designer/processes/${details.id}/xml`);
            }, },
        title !== 'Versions' && { name: 'Versions', icon: 'content-save-all' },
        { name: 'divider' }
    ].filter(Boolean) : [];

    if (primaryClass?.entityTabs?.list?.length) {
        let tabs = primaryClass.entityTabs.list;
        if (!canComment) {
            tabs = tabs.filter(tab => tab !== 'A-Live');
        }
        return [
            ...goToItem,
            printButton,
            type === 'backgroundjob' && { name: 'Log', icon: 'coffee' },
            !isPrintTemplateLayout && type === 'print-template' && {
                name: 'Layout',
                icon: 'classification-editor',
                iconType: 'af',
                onItemClick: () => {
                    history.push(`/print-templates/${details.id}/layout`);
                },
            },
            ...tabs.map((tab) => {
                switch (tab) {
                    case 'About':
                        return !isAbout && title !== 'About' && isVisibleTab('About', primaryClass) && { name: 'About', icon: 'information-outline' };
                    case 'Relations':
                        return !isRelations && relationItem;
                    default:
                        return title !== _normalizeTabTitle(tab) && isVisibleTab(tab, primaryClass) && entityTabsDefault[tab];
                }
            }).filter(Boolean),
            ...processDesignerMenu,
            isAbout && type === 'treetemplate' && { name: 'Go to Related Entities', icon: 'file-tree' },
            !isDigitalTwin && digitalTwinTab,
            ...printMenu,
            ...commonSiderbarMenus,
        ].filter(Boolean).filter(({ name }) => !hiddenTabs?.includes(name));
    }

    let commonTabs = [
        ...goToItem,
        !isAbout && title !== 'About' && isVisibleTab('About', primaryClass) && { name: 'About', icon: 'information-outline' },
        title !== 'Sharing' && isVisibleTab('Sharing', primaryClass) && { name: 'Sharing', icon: 'share-variant' },
        canComment && title !== 'A-Live' && isVisibleTab('A-Live', primaryClass) && { name: 'A-Live', icon: 'messenger', iconType: 'af' },
        !isRelations && relationItem,
        title !== 'Classes' && isVisibleTab('Classes', primaryClass) && { name: 'Classes', icon: 'Attribute-class', iconType: 'af' },
        title !== 'Location' && isVisibleTab('Location', primaryClass) && { name: 'Location', icon: 'pin' },
        title !== 'Attachments' && isVisibleTab('Attachments', primaryClass) && { name: 'Attachments', icon: 'attachment' },
        title !== 'History' && isVisibleTab('History', primaryClass) && { name: 'History', icon: 'history' },
        !isDigitalTwin && title !== 'Digital Twin' && digitalTwinTab,
        ...printMenu,
        ...commonSiderbarMenus
    ].filter(Boolean);

    if (hiddenTabs?.length) {
        commonTabs = commonTabs.filter(({ name }) => !hiddenTabs.includes(name));
    }

    switch (entityType || type) {
        case 'iot_pipeline':
        case 'iot_connector': 
        case 'iot_module': { 
            return commonTabs.filter(({ name }) => !['Location'].includes(name));
        } 
        case 'process':
        case 'system_process':
        case 'openprocess':
        case 'closedprocess': {
            const tabs = commonTabs.filter(({ name }) => name !== 'Location'); // As process does not have location tab
            const aliveTabIndex = tabs.findIndex(({ name }) => name === 'A-Live');
            const aboutTabIndex = tabs.findIndex(({ name }) => name === 'About');
            const tasksTabIndex = aliveTabIndex === -1 ? aboutTabIndex + 1 : aliveTabIndex + 1;
            tabs.splice(tasksTabIndex, 0, title !== 'Tasks' && { name: 'Tasks', icon: 'sitemap' }); // Insert Tasks menu item after A-Live if user have permission otherwise after About
            return tabs;
        }
        case 'task':
        case 'system_task':
        case 'opentask':
        case 'closedtask':
            const processMap = !pathname.endsWith(`${id}/process-map`) && title !== 'Process Map' && { name: 'Process Map', icon: 'sitemap' };
            const tabs = commonTabs.filter(({ name }) => name !== 'Location'); // As tasks does not have location
            const historyTabIndex = tabs.findIndex(({ name }) => name === 'History');
            tabs.splice(historyTabIndex + 1, 0, processMap); // Insert Process Map menu item after History
            return tabs.filter(Boolean);
        case 'class':
            return [
                ...goToItem,
                !isAbout && title !== 'About' && { name: 'About', icon: 'information-outline' },
                title !== 'A-Live' && (role && role !== 'viewer') && { name: 'A-Live', icon: 'messenger', iconType: 'af' },
                title !== 'Sharing' && { name: 'Sharing', icon: 'share-variant' },
                title !== 'Attributes' && {
                    name: 'Attributes',
                    icon: 'Attribute-class',
                    iconType: 'af',
                    onItemClick: () => {
                        ( entityTypePage || details.primary ) ? history.push(`/entity-types/${details.id}/attributes/editor`) :
                            history.push(`/classifications/${details.id}/attributes/editor`);
                    }
                },
                title !== 'Entities' && { name: 'Entities', icon: 'things', iconType: 'af' },
                ...commonSiderbarMenus,
            ].filter(Boolean);
        case 'print-template':
            return [
                {
                    name: 'Layout',
                    icon: 'classification-editor',
                    iconType: 'af',
                    onItemClick: () => {
                        history.push(`/print-templates/${details.id}/layout`);
                    },
                },
                ...commonTabs,
            ].filter(Boolean);
        case 'event':
        case 'eventtype':
            return [{ name: 'Go to details', icon: 'login-variant' }, !isAbout && title !== 'About' && { name: 'About', icon: 'information' }].filter(Boolean);
        default:
            return commonTabs;
    }
};

export const getDefaultEntityTab = (data) => {
    if (!isObject(data)) return null;
    return get(data, 'primaryClass.entityTabs.list[0]');
};

export const entitiesDefaultFilterDefinitions = (applicableOn = []) =>
    [
        {
            field: 'name',
            type: 'text',
            properties: {
                label: 'Name',
                name: 'name',
                opSelector: true
            }
        },
        {
            field: 'id',
            type: 'uuid',
            properties: {
                label: 'ID',
                name: 'id',
                opSelector: true
            },
            condition: '='
        },
        {
            field: 'classes.id',
            type: 'classificationTypeahead',
            properties: {
                label: 'Classes',
                name: 'classesUri',
                filterBy: [{ field: 'active', op: '=', value: true }],
                multiple: true,
                applicableOn: [...applicableOn],
                valueField: 'id'
            },
            condition: 'in',
            sort: false
        },
        ...commonEntitiesListFilters,
        {
            field: 'active',
            type: 'typeahead',
            properties: {
                label: 'Status',
                name: 'active',
                options: [
                    { value: null, label: 'Any' },
                    { value: true, label: 'Active' },
                    { value: false, label: 'Inactive' }
                ]
            },
            sort: false,
            condition: '='
        }
    ].filter(Boolean);

/**
 * Button component to show the hidden sidebar
 */
const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
    position: absolute;
    left: 0;
    top: 8px;
    z-index: 1;
    margin: 0 8px;

    @media (max-width:961px){
        top: 1px;
    }
`;

export const OpenLeftSidebarButton = (props) => {
    const dispatch = useDispatch();
    const isLeftPanelOpen = useSelector(state => state.leftPanel.state.isOpen);
    if (isLeftPanelOpen) {
        return null;
    }
    return (
        <ButtonWrapper>
            <Tooltip title="Show sidebar" aria-label="show-sidebar">
                <IconButton onClick={() => dispatch(openLeftPanel())}>
                    <Icon name="chevron-double-right" />
                </IconButton>
            </Tooltip>
        </ButtonWrapper>
    );
};


export const goToEntityDetails = (props) => {
    const { data, data: { id, primaryClass }, entitiesView, entityType, isPrintReports, pipelineMonitor } = props;
    const type = entitiesView ? data?.type || props.type : props.type;

    if (entitiesView) {
        const url = primaryClass?.openInModule;
        if (!url || url === `/entities/${type}`) { // url === `/entities/${type}` to handle the legacy / bad data
            return history.push(`${getModulePath(primaryClass.openInModule, type)}/${id}`);
        }
        if (isValidURL(url)) {
            const validUrl = appendHttp(url);
            const link = render(compile(validUrl), data);
            return window.open(link, '_blank');
        }
        const link = render(compile(url), data);
        return history.push(link);
    }

    history.push(getEntityUrl(id, type, { entityType, pipelineMonitor, isPrintReports }));
};