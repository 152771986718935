import React, { memo } from 'react';
import styled from 'styled-components';
import { Typography, Divider, Grid, Box } from '@mic3/platform-ui';

import { getIconAttribute, getColorAttribute, getIconTypeAttribute } from 'app/utils/entity/entityUtils';
import { getAttachmentUrl } from 'app/utils/attachments/attachmentsUtils';
import { capitalizeFirstLetter } from 'app/utils/utils';
import { getEntityUrl } from 'app/utils/entity/entityUtils';
import Icon from 'app/components/atoms/Icon/Icon';
import ExpansionCard from 'app/components/molecules/ExpansionCard/ExpansionCard';
import GridEntityId from 'app/components/organisms/GridView/GridRenderers/GridEntityId';
import Image from 'app/components/atoms/Image/Image';

const DividerStyled = styled(Divider)`
    margin: 10px 0 32px 0 !important;
`;
const ImageStyled = styled(Image)`
    max-width: 340px !important;
    width: 100%;
    object-fit: contain;
    ${({ type }) => ['user', 'person'].includes(type) && 'border-radius: 50%;'}
`;

const TypographyStyled = styled(Typography)`
    margin: 4px 0 !important;
    && {
        white-space: break-spaces;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

const MainSection = (props) => {
    const { actions, entityType, adminBackgroundJobs, onTitleClick, collapsible, expanded,renderTitle } = props;
    const { iconType, icon, color, name, type, id, image, imagePrev, primaryClass  } = props.details || {};
    let value = getEntityUrl(id, type);

    if ( type ==='user'){
        value = `/entities/user/${id}`;
    }
    if ( type ==='person'){
        value = `/entities/person/${id}`;
    }
    if ( type ==='workspace'){
        value = `/workspaces/${id}/about`;
    }
    if(entityType){
        value = `/entity-types/${id}`;
    }
    const imageSrc = getAttachmentUrl(id, type, image);
    return (
        <ExpansionCard
            actions={actions}
            collapsible={collapsible}
            expanded={expanded}
            title={entityType ? 'Entity Type' : primaryClass?.name || capitalizeFirstLetter(type)}
            iconType={primaryClass?.iconType || 'af'}
            iconName={primaryClass?.icon || (entityType ? 'primary-class': 'Attribute-class')}
            isActionEdge
            onTitleClick={onTitleClick}
            renderTitle={renderTitle}
        >
            <TypographyStyled variant="h5">{name}</TypographyStyled>

            {id  && <GridEntityId fullValue 
                value={value} 
                label={id}
                valueId={id}
                adminBackgroundJobs={adminBackgroundJobs}
            />}
            {!['backgroundjob', 'print-template', 'iot_connector', 'iot_module', 'formdefinition'].includes(type) && (
                <>
                    <DividerStyled />
                    <Grid container justify="center">
                        {image ? (
                            <ImageStyled type={type} src={imagePrev ? imagePrev : imageSrc} />
                        ) : (
                            <Icon
                                size={'xxl'}
                                type={iconType || getIconTypeAttribute(props.details) || 'mdi'}
                                name={icon || getIconAttribute(props.details) || 'null'}
                                hexColor={color || getColorAttribute(props.details) || '#00BCD4'}
                            />
                        )}
                    </Grid>
                    <Box my={4} />
                </>
            )}
        </ExpansionCard>
    );
};

export default memo(MainSection);
