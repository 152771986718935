/* @flow */

import { gql } from '@apollo/client';

export default gql`
    query eventsQuery($startIndex: Int, $stopIndex: Int, $filterBy: [JSON], $orderBy: [JSON], $excludeBy: [JSON]) {
        count: count(dataType: "event", filterBy: $filterBy, excludeBy: $excludeBy)
        records: events(startIndex: $startIndex, stopIndex: $stopIndex, filterBy: $filterBy, orderBy: $orderBy, excludeBy: $excludeBy) {
             id # for opening pin detail, we need id and time
             time # for pin detail
             description # when user hover on pin on map
             device { 
               id
               name # we show entity name
               type # entity type
               _ewkt # geometry
               iconColor # pin color
               iconName # pin name/ icon name
             }
             eventType { # type of event 
               id
               name
             }
             
         }
     }
`;
