/* @flow */

import React, { useMemo } from 'react';
import { useDrag } from 'react-dnd';

const DraggableElement = ({ disabled, Component, children, element, style, onDragEnd, ...restProps }: Object) => {

    const [{ isDragging }, drag] = useDrag({
        item: { type: 'ELEMENT', element },
        end: ({ element }, monitor) => {
            onDragEnd && onDragEnd(element);
        },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const C = useMemo(() => {
        const Div = React.forwardRef((props, ref) => <div {...props} ref={ref} />);
        return Component || Div;
    }, [Component]);

    return (
        <C {...restProps} ref={disabled ? ()=>{} : drag } style={{
            opacity: isDragging ? .2 : 1,
            position: 'relative',
            fontSize: 22,
            fontWeight: 'bold',
            cursor: 'move',
            ...style
        }}>
            {children}
        </C>
    );
};

export default DraggableElement;
