/* @flow */

import { gql } from '@apollo/client';

export default gql`
query scriptVersionTypeaheadQuery($filterBy: [JSON], $orderBy: [JSON]) {
    result: scriptVersions(filterBy: $filterBy, orderBy: $orderBy) {
      primary(fields: ["version"])
    }
}
`;
