// @flow

export default `
            id
            name
            category
            canLeave
            active
            classes {
                id
                name
                uri
                active
                color
                formDefinition
            }
            owners {
                id
                name
                image
                username
            }
            attributes
            user {
                id
                name
            }
            teams {
                role
                    team {
                        id
                        name
                        isSystem
                        users {
                            role
                            user {
                                id
                                name
                                image
                                username
                        }
                    }
                }
            }
            classResources{
                role
                class {
                    id
                    name
                    uri
                }
            }
            entityResources {
                entity {
                    id
                    name
                    type
                }
            }
            createdDate
            createdBy {
                id
                name
            }
            modifiedDate
            modifiedBy {
                id
                name
            }
`;
