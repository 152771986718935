/* @flow */

import { gql } from '@apollo/client';

export default gql`
mutation cloneFormDefinitionMutation($id: ID!, $name: String!, $description: String){
  result: cloneFormDefinition(id: $id, name: $name, description: $description) {
    id
  }
}

`;
