/* @flow */
import { combineReducers } from 'redux';

import {
    LOAD_PRINT_TEMPLATE_LIST_VIEW_STARTED,
    LOAD_PRINT_TEMPLATE_LIST_VIEW,
    LOAD_REPORTS_TEMPLATE_LIST_VIEW_STARTED, LOAD_REPORTS_TEMPLATE_LIST_VIEW,
    LOAD_ENTITY_PRINT_TEMPLATE_STARTED,
    LOAD_ENTITY_PRINT_TEMPLATE,
    LOAD_PRINT_TEMPLATE_DETAILS_STARTED, LOAD_PRINT_TEMPLATE_DETAILS
} from 'store/actions/entities/printTemplateActions';
import { dataTableReducer,
    loadDataReducer,
    keepDataAtStart } from 'app/utils/redux/reducer-utils';

export default combineReducers<Object, Object>({
    list: dataTableReducer(LOAD_PRINT_TEMPLATE_LIST_VIEW_STARTED, LOAD_PRINT_TEMPLATE_LIST_VIEW, keepDataAtStart),
    listReports: dataTableReducer(LOAD_REPORTS_TEMPLATE_LIST_VIEW_STARTED, LOAD_REPORTS_TEMPLATE_LIST_VIEW, keepDataAtStart),
    entity: dataTableReducer(LOAD_ENTITY_PRINT_TEMPLATE_STARTED, LOAD_ENTITY_PRINT_TEMPLATE, keepDataAtStart),
    details: loadDataReducer(LOAD_PRINT_TEMPLATE_DETAILS_STARTED, LOAD_PRINT_TEMPLATE_DETAILS, keepDataAtStart),
});
