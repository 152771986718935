
import { gql } from '@apollo/client';

export default gql`
query loadEntityVersionsQuery($type: String!, $orderBy: [JSON], $filterBy: [JSON], $excludeBy: [JSON], $startIndex: Int, $stopIndex: Int) {
    records: entities(type: $type, startIndex: $startIndex, stopIndex: $stopIndex, orderBy: $orderBy, filterBy: $filterBy, excludeBy: $excludeBy) {
        id
        name
        type
        primary

        description
        image
        attributes
        
        createdDate
        createdBy {
          id
          name
        }        
    }
}
`;
