/* @flow */

import React from 'react';
import { Button } from '@mic3/platform-ui';
import { withStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

const LayersActions = ({ onSave, classes, children }) => {
    const isDataChanged = useSelector((state) => state.maps.situationalAwareness.map.isDataChanged);
    const hasDrawing = useSelector((state) => state.maps.situationalAwareness.drawing.hasDrawing);

    const isSaveEnabled = isDataChanged || hasDrawing;
    
    return children ? (
        children(classes)
    ) : (
        <Button
            className={classes.button}
            variant="text"
            disabled={!isSaveEnabled}
            onClick={(e) => onSave(e)}
        >
            Save
        </Button>
    );
};

const styles = (theme) => ({
    button: {
        marginRight: '13px',
        marginTop: '4px',
    },
});

export default withStyles(styles)(LayersActions);
