/* @flow */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, MdiIcon, Tooltip } from '@mic3/platform-ui';

import Label from '../Label/Label';
import HeaderActions from 'app/components/atoms/HeaderActions/HeaderActions';
import { ChildrenProp } from 'app/utils/propTypes/common';

const ListGroupName = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${({ noPadding }) => (noPadding ? '0' : '1rem')};
    opacity: ${({ opacity }) => opacity };
    ${({ fullWidth }) => (fullWidth ? 'width: 100%;' : '')}
`;

const ListGroupLabel = styled(Label)`
    margin: 0;
    ${({cursor}) => cursor ? `cursor: ${cursor}` : ''}
    color: ${({isSelected, theme})=> isSelected ?  theme.material.colors.text.button : theme.material.colors.text.primary }; ////////////
`;
const MdiIconStyled = styled(MdiIcon)`
    margin-right: 8px;
    font-size: 1.3rem !important;
`;

const TitleStyled = styled('div')`
    display: flex;
    flex-gap: 10px;
    align-items: center;
    cursor: pointer !important;
    width: fit-content;
`;

const SubHeading = styled.span`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: rgba(255, 255, 255, 0.7);
    mix-blend-mode: normal;
    flex: none;
    order: 0;
    flex-grow: 1;
`;

/**
 * If items in a list are grouped. Use this to split by title
*/

export const Title = ({iconName, iconType, withoutCapitalize, onTitleClick, name, isSelected}) => {
    return onTitleClick ? (
        <Tooltip title={`Go to ${name}`} className="pointer">
            <TitleStyled onClick={onTitleClick}>
                {iconName && (
                    <MdiIconStyled
                        name={iconName}
                        type={iconType || 'mdi'}
                    />
                )}
                <ListGroupLabel
                    cursor="pointer"
                    withoutCapitalize={withoutCapitalize ? 1 : 0}
                    isSelected={isSelected ? 1 : 0}
                >
                    {name}
                </ListGroupLabel>
            </TitleStyled>
        </Tooltip>
    ) : (
        <Grid container wrap="nowrap" >
            {iconName && (
                <MdiIconStyled name={iconName} type={iconType || 'mdi'} />
            )}
            <ListGroupLabel 
                withoutCapitalize={withoutCapitalize ? 1 : 0}
                isSelected={isSelected ? 1 : 0}
            >
                {name}
            </ListGroupLabel>
        </Grid>
    );
};

const ListGroup = (props: Object) => {
    const { 
        withoutCapitalize, name, iconName, subtitle, iconType, actions, 
        className, noPadding, fullWidth, headerPadding, isActionEdge, onTitleClick, renderTitle, isSelected,
    } = props;
    return (
        <ListGroupName
            noPadding={noPadding ? 1 : 0}
            fullWidth={fullWidth ? 1 : 0}
            className={className}
            opacity={renderTitle ? 1 : 0.6}
        >
            <Grid container alignItems="flex-start" wrap="nowrap" direction="column">
                {renderTitle ? renderTitle() : (
                    <Title
                        withoutCapitalize={withoutCapitalize}   
                        name={name}
                        iconName={iconName}
                        iconType={iconType}
                        onTitleClick={onTitleClick}
                        isSelected={isSelected}
                    />
                )}
                <SubHeading>{subtitle}</SubHeading>                
            </Grid>
            <HeaderActions
                headerPadding={headerPadding}
                isActionEdge={isActionEdge}
            >
                {actions}
            </HeaderActions>
        </ListGroupName>
    );
};

ListGroup.propTypes = {
    name: PropTypes.string,
    actions: ChildrenProp,
};

export default ListGroup;
