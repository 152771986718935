import React, { PureComponent } from 'react';
import { Button, Divider, Autocomplete, Typography } from '@mic3/platform-ui';
import styled, { withTheme } from 'styled-components';
import { connect } from 'react-redux';

import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import EntityTypeahead from 'app/components/organisms/Form/Typeahead/EntityTypeahead';
import Textarea from 'app/containers/Designer/Form/components/Textarea';
import Loader from 'app/components/atoms/Loader/Loader';

import { get } from 'app/utils/lo/lo';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { loadScript, executeScript, executeScriptForViewer } from 'store/actions/designer/designerScriptActions';
import { deserializeVariables } from 'app/utils/bpmn/bpmnEngineUtils';
import { loadTaskSidebarDetailsInternal } from 'store/actions/abox/taskActions';
import { loadAllPrimaryClasses } from 'store/actions/app/appActions';

const DividerStyled = styled(Divider)`
    margin: 16px 0 !important;
`;

class PrintPreviewSettings extends PureComponent<Object, Object> {

    constructor(props) {
        super(props);
        props.loadAllPrimaryClasses();
        this.state = {
            isLoading: true,
            context: props.context,
            formData: props.formData,
            type: props.context?.type || props?.details?.type || null,
            entity: props.details,
            changesInProgress: false,
        };
    }

    componentDidUpdate(prevProps) {
        const { context, formData } = this.props;
        if(context !== prevProps.context || formData !== prevProps.formData) {
            this.setState({ context }, this.loadScript);
        }
    }
    componentDidMount(prevProps) {
        this.loadScript();
    }

    @bind
    async loadScript() {
        const { printTemplate, formData, executeScriptForViewer } = this.props;
        const nextState = { isLoading: false };

        const formDefinition = get(printTemplate, '_formDefinition.primary.definition', null);
        if(formDefinition) {
            nextState.formDefinition = formDefinition;
        }
        if(formData) {
            nextState.context = await executeScriptForViewer(printTemplate.id, formData);
        }

        this.setState(nextState);

    }

    @bind
    async handleEntity({ target: { value }}) {
        const { loadTaskSidebarDetailsInternal } = this.props;
        const isTask = ['task', 'opentask', 'closedtask'].includes(value?.type);
        let context = value;
        if(value && isTask) {
            const details = await loadTaskSidebarDetailsInternal(value.id);
            const variables = deserializeVariables(details?.formDefinitionVersion?.definition, details?.process?.variables);
            context = {
                ...value,
                process: {
                    ...value.process,
                    variables
                }
            };
        }
        const nextState = { context, entity: value, type: value?.type ?? this.state.type };
        if(this.state.type?.includes('system') && this.state.type !== nextState.type) {
            nextState.type = this.state.type;
        }

        this.setState(nextState);
    }

    @bind
    async handleChangeForm(data, variables) {
        const { printTemplate, executeScriptForViewer } = this.props;
        let context = null;
        try {
            const { name, value } = variables;
            const isChangeEntity = name === 'entity' || name === 'entities';
            if((isChangeEntity && value) || !isChangeEntity) {
                context = await executeScriptForViewer(printTemplate.id, data);
            }
        } catch(e) {}
        this.setState({ context, formData: data });
    }

    @bind
    handleEntityType({ target: { value }}) {
        if(value !== this.state.type) {
            this.setState({ type: value, context: null, formData: null, entity: null });
        }
    }

    @bind
    handleSetPreview() {
        const { handleChangeContext, handleChangeFormData, onClose } = this.props;
        const { context, formData } = this.state;

        handleChangeContext({ target: { value: context }});
        handleChangeFormData({ target: { value: formData }});
        onClose();
    }

    @bind
    @memoize()
    buildOptions(printTemplate, primaryClasses) {
        return get(printTemplate, 'primary.print-template/applicable_on', []).map((id) => {
            const clss = (primaryClasses || []).find(clss => clss.id === id);
            return clss && {
                label: clss.name,
                value: clss.uri,
            };
        }).filter(Boolean);
    }

    @bind
    @memoize()
    buildTypeValue(options, type) {
        if(!type && options.length) {
            return this.setState({ type: options[0].value });
        }
        const isOption = options.find(op => op.value === type);
        const isTask = type?.includes('task');
        const isProcess = type?.includes('process');
        if(!isOption && (isTask || isProcess)) {
            const systemType = isTask ? 'system_task' : 'system_process';
            const systemTypeOp = options.find(op => op.value.includes(systemType))?.value;
            systemTypeOp && this.setState({ type: systemTypeOp });
        }
    }

    @bind
    onChangeFormStarted() {
        this.setState({ changesInProgress: true });
    }

    @bind
    onChangeFormEnd() {
        this.setState({ changesInProgress: false });
    }

    render() {
        const { onClose, theme, printTemplate, external, onCancel, canEdit, primaryClasses } = this.props;
        const { context, formData, type, isLoading, formDefinition, entity, changesInProgress } = this.state;

        if(isLoading) {
            return <Loader backdrop absolute />;
        }

        const options = this.buildOptions(printTemplate, primaryClasses);
        this.buildTypeValue(options, type);
        const isScript = !!printTemplate?.primary['print-template/script']?.id;

        return (
            <ModalDialog
                onClose={onClose}
                title="Print parameters"
                bgcolor={'rgba(40, 51, 75, 1)'}
                actions={
                    <>
                        <Button variant="text" color="primary" onClick={onCancel || onClose}>
                            Cancel
                        </Button>
                        <Button color="primary" disabled={changesInProgress} onClick={this.handleSetPreview}>
                            Preview
                        </Button>
                    </>
                }
            >
                {formDefinition ? (
                    <FormGenerator
                        components={formDefinition}
                        onChange={this.handleChangeForm}
                        data={formData}
                        root={false}
                        onStartChanges={this.onChangeFormStarted}
                        onEndChanges={this.onChangeFormEnd}
                    />
                ) : (
                    <>
                        {options.length > 1 && (
                            <Autocomplete
                                value={type}
                                valueField="value"
                                onChange={this.handleEntityType}
                                options={options}
                                label="Entity type"
                            />
                        )}
                        {!type && !options.length && (
                            <Typography>{isScript ? `Fill "Applies To" or script with a form in about tab.` : `Fill "Applies To" or script in about tab.`}</Typography>
                        )}
                        {type && (
                            <EntityTypeahead
                                value={context || entity}
                                extraInfo
                                onChange={this.handleEntity}
                                label="Preview entity"
                                entityType={type}
                            />
                        )}
                    </>
                )}
                {context && (!external) && canEdit && (
                    <>
                        <DividerStyled />
                        <Textarea
                            label="Preview data"
                            plainTextEditor
                            useCodeEditor
                            parseAs="javascript"
                            disabled
                            backgroundcolor={theme.material.colors.background.fields}
                            value={JSON.stringify(context || {}, null, 2)}
                        />
                    </>
                )}
            </ModalDialog>
        );
    };
}
export default connect((state: Object) => ({
    isLoading: state.entities.sidebar.details.isLoading,
    userProfile: state.user.profile,
    primaryClasses: state.app.allPrimaryClasses.records,
}), {
    loadScript, executeScript, loadTaskSidebarDetailsInternal, loadAllPrimaryClasses, executeScriptForViewer
})(withTheme(PrintPreviewSettings));
