/* @flow */

import React, { memo } from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import PageNotAllowed from 'app/containers/ErrorPages/PageNotAllowed';
import UsersListView from 'app/containers/Admin/UserManagement/UsersListView';
import UserAdd from 'app/containers/Admin/UserManagement/UserAdd';
import UserDetails from 'app/containers/Admin/UserManagement/Details/UserDetails';

const UserManagementRoute = memo((props: Object) => {
    const { permissions, isAdmin } = props.userProfile;
    const permissionsSet = new Set(permissions || []);
    const canView = isAdmin || permissionsSet.has('admin.user');
    if (!canView) {
        return <PageNotAllowed title="Users" />;
    }
    return (
        <Switch>
            <Route path={`/user-management`} exact component={UsersListView} />
            <Route path={`/user-management/add`} exact component={UserAdd} />
            <Route path={`/user-management/:id`} exact component={UserDetails}/>
            <Route path={`/user-management/:id/about`} component={UserDetails}/>
            <Route path={`/user-management/:id/history`} component={UserDetails}/>
            <Route path={`/user-management/:id/relationships`} component={UserDetails}/>
        </Switch>
    );
});

export default connect(
    (state: Object, ownProps: Object): Object => ({
        userProfile: state.user.profile,
    })
)(UserManagementRoute);
