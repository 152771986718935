/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import moment from 'moment';

import AttachmentsView from 'app/containers/Common/Attachments/AttachmentsView';
import ClassificationsTab from 'app/containers/Common/ClassificationsTab/ClassificationsTab';
import Relations from 'app/containers/Entities/Relationships/Relations';
import TaskHistoryTab from 'app/containers/Abox/TaskView/TaskHistoryTab';
import ChatSharing from 'app/components/organisms/Chat/ChatSharing';
import DotMenu from 'app/components/molecules/DotMenu/DotMenu';
import TaskAbout from 'app/containers/Abox/TaskView/TaskAbout';
import Loader from 'app/components/atoms/Loader/Loader';
import Chat from 'app/components/organisms/Chat/Chat';
import history from 'store/History';
import EntityDigitalTwin from 'app/containers/Entities/DigitalTwin/EntityDigitalTwin';
import PageNotAllowed from 'app/containers/ErrorPages/PageNotAllowed';

import { setActions, setSubTitle, closeSidebar, shrinkSidebar, expandSidebar, setTitle } from 'store/actions/sidebar/sidebarActions';
import { mediaBreakpoints } from 'app/themes/breakpoints';

import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { formatIcal } from 'app/utils/formatter/ical.formatter';
import { getSubscriptionByRel } from 'app/utils/chat/chatUtils';
import { saveText } from 'app/utils/datatable/datatableUtils';
import { toUniqueUUID } from 'app/utils/string/string-utils';
import { loadTaskSidebarDetails, loadTaskSidebarDetailsInternal } from 'store/actions/abox/taskActions';
import { getPermissions } from 'app/config/rolesConfig';
import { isDev } from 'app/utils/env';
import { isEmpty } from 'app/utils/utils';
import { buildDotMenu } from 'app/utils/entity/entityUtils';
import { loadClassificationTaskAndProcess } from 'store/actions/classifications/classificationsActions';
import { loadPrintTemplatesListView } from 'store/actions/entities/printTemplateActions';

export const exportIcal = (data) => {
    const {
        id,
        primary: { startDate, dueDate },
        name,
        description,
    } = data || {};
    const dateIcalFormat = 'YYYYMMDDTHHmmss';
    const rootUrl = isDev ? 'https://affectli.dev.mi-c3.com' : 'https://affectli.mi-c3.com';
    const icalData = {
        id: id,
        stamp: moment(new Date()).format(dateIcalFormat),
        start: moment(startDate).format(dateIcalFormat),
        end: moment(dueDate).format(dateIcalFormat),
        summary: name,
        description: description,
        url: `${rootUrl}/#/abox/task/${id}`,
    };
    const formattedIcalData = formatIcal(icalData);
    // download as file
    saveText(name, formattedIcalData, '.ics');
};

class TaskSidebar extends PureComponent<Object, Object> {
    static propTypes = {
        id: PropTypes.string,
        title: PropTypes.string,
        isOpen: PropTypes.bool,
        reloadList: PropTypes.func,
        // from redux
        isLoading: PropTypes.bool,
        details: PropTypes.object,
        // for isolated details
        detailsInternal: PropTypes.object,
        internal: PropTypes.bool,
    };

    state = {
        refreshKey: 0,
    };

    constructor(props: Object) {
        super(props);
        this.initData();
        this.loadPrintTempaltes();
    }

    componentDidUpdate(prevProps) {
        const { isLoading, details, tasksAndProcesses } = this.props;
        if (details?.id && prevProps.details?.id !== details.id) {
            this.loadPrintTempaltes();
            this.setState(prevState => ({ refreshKey: prevState.refreshKey + 1 }));
        }
        if (prevProps.isLoading && !isLoading && !details) {
            return;
        }
        this.buildPrintTemplateMap(tasksAndProcesses);
        this.initData(prevProps);
    }

    @bind
    loadPrintTempaltes(){
        const { loadClassificationTaskAndProcess, loadPrintTemplatesListView, } = this.props;
        loadClassificationTaskAndProcess();
        loadPrintTemplatesListView();
    }

    @bind
    @memoize()
    buildPrintTemplateMap(tasksAndProcesses) {
        const templateClasses = tasksAndProcesses?.records?.filter(clss => clss.uri.includes('task'));
        this.printTemplatesMap = templateClasses?.reduce((accum, clss) => {
            accum[clss.uri] = clss.id;
            return accum;
        }, {});
    }

    @bind
    setActions(expanded, details, title) {
        const { printTemplateRecords } = this.props;
        const { classes } = details || {};
        this.props.setActions(this.dotMenu(expanded, classes, details, title, printTemplateRecords, this.printTemplatesMap));
    }

    @bind
    reloadDetails() {
        const { id, internal } = this.props;
        if (!id) return;
        if(internal) {
            this.props.loadTaskSidebarDetailsInternal(id);
        } else {
            this.props.loadTaskSidebarDetails(id);
        }
    }

    @bind
    initData(prevProps) {
        const { id: prevId } = prevProps || {};
        const { id, isOpen, isLoading, details, expanded, internal, title } = this.props;
        if (!isOpen) {
            return;
        }
        this.setActions(expanded, details, title);
        if (isLoading) {
            return;
        }
        const loaded = details || {};
        if (loaded.id === id) {
            if (title === 'Sharing') {
                const subTitle = `${details.name} #${toUniqueUUID(details.id)}`;
                this.props.setSubTitle(subTitle);
            } else {
                this.props.setSubTitle(details.name);
            }
        }
        if (id && prevId !== id) {
            internal ? this.props.loadTaskSidebarDetailsInternal(id) : this.props.loadTaskSidebarDetails(id);
        }
        if (details && !buildDotMenu({ details }).find(m => m.name === title)) {
            this.props.setTitle('About');
        }
    }

    @bind
    onDotMenuClick(title) {
        const { id } = this.props;
        if (['Collapse', 'Expand'].includes(title)) {
            const { expanded, expandSidebar, shrinkSidebar } = this.props;
            const action = expanded ? shrinkSidebar : expandSidebar;
            action();
            return;
        }
        if (title === 'Go to details') {
            this.goToDetails();
        }
        if (title === 'Relations') {
            return history.push(`/abox/task/${id}/relationships`);
        }
        if (title === 'Process Map') {
            return history.push(`/abox/task/${id}/process-map`);
        }
        if (title.toLowerCase() === 'ical') {
            exportIcal(this.props.details);
        }
        this.props.setTitle(title);
    }

    goToDetails() {
        const { windowWidth, id, shrinkSidebar, closeSidebar } = this.props;
        history.push(`/abox/task/${id}`);
        if (!windowWidth < mediaBreakpoints.sm) {
            shrinkSidebar();
        } else {
            closeSidebar();
        }
    }

    @bind
    @memoize()
    dotMenu(expanded: boolean, classes: Array<Object>, details: Object, title: string, printTemplateRecords, printTemplatesMap) {
        const pathname = window.location.hash.toLowerCase();
        const isTimeline = pathname.includes('timeline');
        const menu = buildDotMenu({ entityType: 'task', details, title, pathname, expanded, printTemplateRecords, printTemplatesMap });
        const dividerIndex = menu.findIndex(({ name }) => name === 'divider');
        menu.splice(dividerIndex, 0, isTimeline && { name: 'iCal', icon: 'file-export' });
        return <DotMenu key={13} onItemClick={this.onDotMenuClick} items={menu.filter(Boolean)} />;
    }

    render() {
        const { isLoading, id, title, details, isOpen, reloadList, subscription, internal, openEntitySidebar } = this.props;
        const { refreshKey } = this.state;
        const isLoaded = details;
        const role = details && details.role;
        const permissions = getPermissions(role);
        const { canEdit, canView } = permissions || {};
        const { rel, rid } = subscription || {};

        if (!isLoading && (isEmpty(details) || !canView)) {
            return <PageNotAllowed padded permissionError={true} title={`Task. (ID:${id})`} />;
        }
        const props = {
            id,
            type: details?.type,
            details,
            internal,
            reloadDetails: this.reloadDetails,
            reloadList,
            canEdit,
            key: refreshKey,
        };
        return (
            isOpen && (
                <>
                    {isLoading && <Loader absolute backdrop />}
                    {isLoaded && title === 'About' && <TaskAbout isSidebar {...props} />}
                    {isLoaded && title === 'Relations' && (
                        <Relations key={refreshKey} fromType={details.type} fromId={id} canEdit={canEdit} />
                    )}
                    {isLoaded && title === 'Classes' && <ClassificationsTab {...props} openEntitySidebar={openEntitySidebar} />}
                    {isLoaded && title === 'Attachments' && (
                        <AttachmentsView
                            key={refreshKey}
                            id={id}
                            type='task'
                            entityType={details.type}
                            details={details}
                            reloadDetails={this.initData}
                            permissions={permissions}
                        />
                    )}
                    {isLoaded && title === 'History' && <TaskHistoryTab id={id} type={details.type} key={refreshKey} />}
                    {isLoaded && title === 'A-Live' && <Chat id={id} type={details.type} details={details} key={refreshKey} />}
                    {isLoaded && title === 'Sharing' && (
                        <ChatSharing
                            rid={rid}
                            rel={rel}
                            canEdit={canEdit}
                            role={role}
                            id={id}
                            type={details.type}
                            selectedSidebar={'share'}
                            key={refreshKey}
                        />
                    )}
                    {isLoaded && title === 'Digital Twin' && (
                        <EntityDigitalTwin type={details?.type} entity={details} inSidebar={true} key={refreshKey} />
                    )}
                </>
            )
        );
    }
}

export default connect(
    (state: Object, ownProps: Object) => {
        const internalData = state.abox.task.sidebarDetailsInternal.data;
        const data = state.abox.task.sidebarDetails.data;
        const details = ownProps.internal ? internalData : data;

        const { id, type } = details || {};
        const subscriptions = state.chat.subscriptions.data;
        const subscription = getSubscriptionByRel(subscriptions, { id, type });

        return {
            subscription,
            details,
            type,
            isLoading: ownProps.internal ? state.abox.task.sidebarDetailsInternal.isLoading : state.abox.task.sidebarDetails.isLoading,
            isOpen: state.sidebar.isOpen,
            expanded: state.sidebar.expanded,
            title: state.sidebar.title,
            windowWidth: state.global.window.width,
            actions: state.sidebar.actions,
            printTemplateRecords: state.entities.printTemplates.list.records,
            tasksAndProcesses: state.classifications.classificationTasksAndProcesses.data
        };
    },
    {
        loadTaskSidebarDetailsInternal,
        loadTaskSidebarDetails,
        closeSidebar,
        setActions,
        setSubTitle,
        shrinkSidebar,
        expandSidebar,
        setTitle,
        loadClassificationTaskAndProcess,
        loadPrintTemplatesListView,
    }
)(withRouter(TaskSidebar));
