const createScript = (url, id, errorCallback) =>  {
    if (!id) throw Error('No script ID specified.');
    if (!url) throw Error('No script url specified.');

    const script = document.createElement('script');
    script.onerror = errorCallback;
    script.src = url;
    script.async = true;
    script.id = id;

    document.body.appendChild(script);
};

const showScriptError = (error) => {
    const textError = typeof error === 'string' ? error : error?.message || 'Internal Server Error';
    const alertElem = document.querySelector('#loader-alert');
    alertElem.innerHTML = `There was an error loading Affectli.</br><b>${textError}</b></br>The service is not available. Please contact your administrator or try again later.`;
    alertElem.style.display = 'block';
};

export {
    showScriptError,
    createScript
};