/* @flow */

import React from 'react';
import PropTypes from 'prop-types';
import Link from 'app/components/atoms/Link/Link';

const UserLink = ((props: Object) => {
    const { id, children, title, path, ...restProps } = props;
    if (id) {
        const tab = path ? `/${path}` : '';
        return <Link title={title} to={`/user-management/${id}${tab}`} {...restProps}>{children}</Link>;
    }
    return <Link title={title} {...restProps}>{children}</Link>;
});

UserLink.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

export default UserLink;
