import { gql } from '@apollo/client';

export default gql`
  query loadEntitiesGeotagButtonQuery($id: ID!, $type: String!) {
    result: entity(id: $id, type: $type) {
      id
      image
      primary(fields: ["locationInfo"])
    }
  }
`;
