import { gql } from '@apollo/client';

export default gql`
    query profileQuery {
        profile {
            id
            primary(fields: ["lastUsedProcesses"])
            name
        }
    }
`;