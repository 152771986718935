/* @flow */
import React from 'react';

import { header } from 'app/utils/designer/form/settings/common/commonBase';
import { onLoad, isVisible } from 'app/utils/designer/form/settings/common/commonEvents';
import { help } from 'app/utils/designer/form/settings/common/commonHelpers';
import Help from 'app/utils/designer/form/settings/common/Help';

const panels = [
    {
        header: 'Base',
        children: [
            {...header, constraints: { ...(header.constraints || {}), required: true }},
            {
                type: 'boolean',
                properties: {
                    label: 'Expanded by default',
                    name: 'properties.expanded',
                    help: <Help message="Set the default behaviour of the panel: expanded or collapsed" />,
                }
            },
            {
                type: 'boolean',
                properties: {
                    label: 'Can collapse and expand',
                    name: 'properties.collapsible',
                }
            },
            {
                type: 'boolean',
                properties: {
                    label: 'Hide panel',
                    name: 'properties.isHiddenPanel',
                    help: <Help message="Set the default behaviour of the panel: Enabling this toggle will hide the panels on the preview and wherever the form is being used i.e. the system will fetch the fields only and will not show the panels (for hide panel enabled panels)." />,
                }
            },
            {
                type: 'iconSelect',
                properties: {
                    label: 'Select header icon',
                    name: 'properties.iconSelectValue',
                    clearable: true,
                    onChange: (e, data) => {
                        return [
                            e.target,
                            { action: 'setProperty', name: 'properties.iconName', value: e.target.value?.value || null },
                            { action: 'setProperty', name: 'properties.iconType', value: e.target.value?.type || null },
                        ];
                    }
                },
            },
        ],
    },
    {
        header: 'Events',
        children: [
            onLoad,
            isVisible,
        ]
    },
    {
        header: 'Helpers',
        isVisible: ({ properties }) =>
            properties && !properties.isHiddenPanel,
        children: [ help ]
    },
];

export default {
    defaults: { children: [], editablePanel: false, collapsible: true },
    panels: settingValues => panels,
};
