/* @flow */

import { gantt } from 'dhtmlx-gantt';
import { formatDate } from '../date/date';
import moment from 'moment';
import { isEmpty } from 'app/utils/utils';

const tasksFormatter = (tasks: Object) => {
    const taskIdArr = tasks.map((task: Object) => task.id);

    return tasks.map((task) => {
        const dateFormat = 'DD-MM-YYYY HH:mm';
        const { name, primary } = task;
        const { dueDate, startDate: start_date, progress, priority, parent  } = primary || {};
        const taskName = name ? name : 'No Name';
        let parentTaskId = parent && parent.id;
        parentTaskId = taskIdArr.includes(parentTaskId) ? parentTaskId : null;
        let startDate = start_date ? formatDate(start_date, dateFormat) : '';
        let endDate = dueDate ? formatDate(dueDate, dateFormat) : '';
        const useDefaultDate = (!startDate && endDate) ? dueDate : (startDate && !endDate) ? primary.startDate : null;

        if (useDefaultDate) {
            startDate = moment(useDefaultDate).startOf('day').format(dateFormat);
            endDate = moment(useDefaultDate).endOf('day').format(dateFormat);
        }

        return {
            ...task,
            text: taskName,
            start_date: startDate,
            end_date: endDate,
            parent: parentTaskId,
            progress: progress,
            priority: priority === 50 ? 3 : priority,
            open: true
        };
    });
};

const linesFormatter = (tasks: Object) => {
    const relationships = [];
    const lines = [];
    const tasksWithPredecessor = [];

    // get the relationship lines to be formatted later
    tasks
        .filter(task => !isEmpty(task.relations))
        .forEach((task) => {
            const newTask = { ...task };

            newTask.relations.forEach((rel) => {
                const { relatedEntity, relation: { id: lineId, relationDefinition } } = rel;
                const { description, relatedDescription } = relationDefinition || {};
                if (description === 'predecessor' && relatedDescription === 'successor') {
                    const { id: targetId, type } = relatedEntity || {};
                    // filter out relationship based on task
                    if ((type || '').includes('task')) {
                        relationships.push({ lineId, sourceId: task.id, targetId });
                    }
                } else if (description === 'successor' && relatedDescription === 'predecessor') {
                    const { id: sourceId, type } = relatedEntity || {};
                    // filter out relationship based on task
                    if ((type || '').includes('task')) {
                        relationships.push({ lineId, targetId: task.id, sourceId });
                    }
                }

            });
        });

    // create lines based on relationship array
    relationships.forEach((rel) => {
        const { sourceId, lineId, targetId } = rel;
        let source, target;

        if (sourceId !== targetId) {
            source = sourceId;
            target = targetId;

            // tasksWithPredecessor.push({ // Work around
            //     sourceId: sourceId,
            //     predecessor: relatedEntity
            // });
            lines.push({
                id: lineId,
                source: source,
                target: target,
                type: gantt.config.links.finish_to_start
            });
        }
    });

    return { lines: lines, tasksWithPredecessor: tasksWithPredecessor };
};

export {
    tasksFormatter,
    linesFormatter,
};
