/* @flow */

import React from 'react';

import Help from 'app/utils/designer/form/settings/common/Help';
import { name, label, placeholder } from 'app/utils/designer/form/settings/common/commonBase';
import { multiple, filterBy, excludeBy, orderBy } from 'app/utils/designer/form/settings/common/commonGeneral';
import { onLoad, onChange, isVisible, isDisabled } from 'app/utils/designer/form/settings/common/commonEvents';
import { help } from 'app/utils/designer/form/settings/common/commonHelpers';
import { required, classRequired, readOnly } from 'app/utils/designer/form/settings/common/commonValidation';
import { flexGrow } from 'app/utils/designer/form/settings/common/commonStyles';

const panels = settingsValues => [
    {
        header: 'Base',
        children: [
            name,
            label,
            {
                type: 'entityTypesTypeahead',
                properties: {
                    label: 'Entity type',
                    name: 'properties.entityType',
                    valueField: 'uri',
                    help: <Help message="Choose entity type for typeahead." />,
                },
                constraints: { required: true },
            },
            placeholder,
        ],
    },
    {
        header: 'General',
        children: [
            multiple,
            filterBy,
            excludeBy,
            orderBy,
        ],
    },
    {
        header: 'Events',
        children: [
            onLoad,
            onChange,
            isVisible,
            settingsValues?.viewType !== 'class' && isDisabled
        ].filter(Boolean)
    },
    {
        header: 'Validation',
        children: [
            settingsValues?.viewType !== 'class' ? required : classRequired,
            settingsValues?.viewType === 'class' && readOnly,
        ].filter(Boolean)
    },
    {
        header: 'Styles',
        children: [ flexGrow ]
    },
    {
        header: 'Helpers',
        children: [ help ]
    },
];

export default {
    defaults: { serialize: true, withDefault: true, filterBy: [{ 'field': 'active', 'op': '=', 'value': true }] },
    panels: settingValues => panels(settingValues),
};
