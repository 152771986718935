/* @flow */

import { baseRoutes } from 'app/config/routesConfig';

export const ssoMenu = [
    {
        name: 'General',
        to: '/admin/settings/general',
    },
    {
        name: 'Authentication',
        to: '/admin/settings/authentication/required-actions',
    },
    {
        name: 'Tokens',
        to: '/admin/settings/tokens',
    },
    {
        name: 'Brute Force Detection',
        to: '/admin/settings/brute-force-detection',
    },
    {
        name: 'Identity Providers',
        to: '/admin/settings/identity-providers',
    },
].filter(Boolean);

const adminList = (isAdmin, permissions) => {
    const permissionsSet = new Set(permissions);
    const canSeeSettings = isAdmin || permissionsSet.has('admin.settings');
    const canSeeUsers = isAdmin || permissionsSet.has('admin.user');
    const canSeeWorkspaces = isAdmin || permissionsSet.has('admin.workspace');
    const canSeeLicense = isAdmin || permissionsSet.has('admin.license');
    const canSeeTeams = isAdmin || permissionsSet.has('admin.team');
    const canSeeBroadcasts = isAdmin || permissionsSet.has('admin.broadcast');
    const canSeeBroadcastsCalendar = isAdmin || permissionsSet.has('admin.broadcastCalendar');
    const canSeeAdminSettings = isAdmin || permissionsSet.has('admin.settings');
    const canSeeSiteSettings = isAdmin || permissionsSet.has('admin.settings') || permissionsSet.has('admin.site');
    const canSeeTerms = isAdmin || permissionsSet.has('admin.settings') || permissionsSet.has('admin.terms');
    const canSeeSSO = isAdmin || permissionsSet.has('admin.settings') || permissionsSet.has('admin.sso');
    return [
        (canSeeSettings || canSeeLicense || canSeeSiteSettings || canSeeTerms) && {
            name: 'Site',
            icon: 'web',
            children: [
                canSeeSiteSettings && {
                    name: 'General',
                    to: baseRoutes['organisationSettings'],
                },
                canSeeLicense && {
                    name: 'Licence',
                    to: baseRoutes['license'],
                },
                canSeeTerms && {
                    name: 'Terms & Conditions',
                    to: baseRoutes['terms'],
                },
            ].filter(Boolean),
        },
        canSeeBroadcasts && {
            name: 'Broadcast',
            icon: 'bullhorn',
            to: '/broadcasts',
        },
        canSeeBroadcastsCalendar && {
            name: 'Broadcast calendar',
            icon: 'calendar',
            to: '/broadcasts/calendar',
            divider: true,
        },
        canSeeUsers && {
            name: 'Users',
            icon: 'user-management',
            iconType: 'af',
            to: '/user-management',
        },
        canSeeTeams && {
            name: 'Teams',
            icon: 'account-group',
            to: '/teams',
        },
        canSeeWorkspaces && {
            name: 'Workspaces',
            icon: 'set-left-center',
            to: '/workspaces',
        },
        (isAdmin || permissionsSet.has('admin.backgroundjob')) && {
            name: 'Background Jobs (Beta)',
            icon: 'coffee-maker',
            to: '/background-jobs',
        },
        (canSeeAdminSettings || canSeeSSO) && {
            name: 'Security',
            icon: 'lock',
            baseRoute: '/admin/settings',
            children: canSeeSSO ? ssoMenu : [],
        },
    ].filter(Boolean);
};

export default adminList;
