/* @flow */

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import { muiTheme } from 'app/themes/materialUi';
import { Button, CircularProgress, Tooltip, ListItem, ListItemText, Typography, ListItemAvatar, Avatar } from '@mic3/platform-ui';

import { loadTaskSidebarDetails, loadTaskSidebarDetailsInternal, updateTask } from 'store/actions/abox/taskActions';

import { PRIORITY_OPTIONS, getPriorityColor } from 'app/config/aboxConfig';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { get } from 'app/utils/lo/lo';
import { shallowEquals, getDate, datefy, getNum } from 'app/utils/utils';
import { setSeconds } from 'app/utils/date/date';
import { getOnlyUpdatedData } from 'app/utils/app/appUtils';
import { getFieldByType } from 'app/utils/designer/form/fieldUtils';
import { iconsSet } from 'app/utils/styles/mdi';

import ProcessLink from 'app/components/atoms/Link/ProcessLink';
import HeaderBar from 'app/components/molecules/HeaderBar/HeaderBar';
import Container from 'app/components/atoms/Container/Container';
import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import Icon from 'app/components/atoms/Icon/Icon';
import ChipClosed from 'app/components/atoms/ChipClosed/ChipClosed';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import { setDocumentTitle } from 'store/actions/app/appActions';

const ListItemTextStyled = styled(ListItemText)`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

export const ProcessAvatar = styled(Avatar)`
    & .MuiAvatar-root {
        ${({ iconColor }) => iconColor ? `background: ${iconColor};` : ''}
    }

    & .FormField-root {
        padding: 0;
    }
`;

const FormGeneratorStyled = styled(FormGenerator)`
    & .closedChip .LabelBox {
        background: transparent !important;
    }
`;

class TaskAbout extends PureComponent<Object, Object> {

    static propTypes = {
        id: PropTypes.string,
        details: PropTypes.object,
        theme: PropTypes.object,
        reloadList: PropTypes.func
    };

    constructor(props) {
        super(props);
        if(props.id) {
            props.internal ? props.loadTaskSidebarDetailsInternal(props.id)
                : props.loadTaskSidebarDetails(props.id);
        }
        this.formRef = React.createRef();
        this.state = {
            startDate: get(props, 'details.primary.startDate'),
            dueDate: get(props, 'details.primary.dueDate'),
            data: get(props, 'details'),
        };
    }

    componentDidUpdate(prevProps) {
        const { details, setDocumentTitle, isSidebar } = this.props;
        const name = details?.name;
        if(name && !isSidebar){
            setDocumentTitle(name);
        }
        if(details !== prevProps.details) {
            this.setState({ data: details });
        }
    }

    @bind
    @memoize(shallowEquals)
    fieldDefinitions({ task, theme, startDate, dueDate, canEdit }) {
        const { id, children, process, primary, type } = task || {};
        const { priority, closedDate } = primary;
        const disabled = !canEdit || !!closedDate;
        const hasSubtasks = !!(children && children.length);
        const processProgress = Math.floor(Number(get(process, 'variables.progress', 0)));
        const processPriority = getNum(process, 'priority', 3);
        const priorityColor = !!closedDate ? 'disabled' : getPriorityColor(priority);
        const processIcon = get(process, 'processDefinitionVersion.iconName');
        const processIconColor = get(process, 'processDefinitionVersion.iconColor');
        return [
            {
                field: 'panel',
                type: 'panel',
                properties: { header: 'General', expanded: true, style: { borderTop: `2px solid ${theme.priorityGradients[priorityColor][0]}` }  },
                children: [
                    {
                        field: `closedChip`,
                        type: 'custom',
                        properties: {
                            label: '',
                            name: `closedChip`,
                            formFieldProps: {
                                className: 'closedChip'
                            },
                            Component: () => type === 'closedtask' && <ChipClosed />
                        }
                    },
                    {
                        type: 'text',
                        properties: { name: 'name', label: 'Name', disabled },
                        constraints: { required: true },
                    },
                    {
                        type: 'userTypeahead',
                        properties: {
                            name: 'primary.assignee',
                            label: 'Assignee',
                            valueField: 'id',
                            disabled
                        },
                    },
                    {
                        type: 'typeahead',
                        properties: {
                            label: 'Priority',
                            name: 'primary.priority',
                            options: PRIORITY_OPTIONS,
                            clearable: false,
                            disabled,
                        },
                    },
                    {
                        type: 'slider',
                        properties: {
                            label: 'Progress',
                            name: 'primary.progress',
                            fillColor: disabled ? theme.priorityColors['disabled'] : priority && theme.priorityGradients[priorityColor][0],
                            disabled: disabled || hasSubtasks
                        },
                    },
                    {
                        type: 'custom',
                        properties: {
                            label: 'Application',
                            Component: () =>
                                <ListItem component="div">
                                    <Typography>{get(process, 'processDefinition.name')}</Typography>
                                </ListItem>,
                            isVisible: () => get(process, 'processDefinition.name'),
                        },
                    },
                    {
                        type: 'custom',
                        properties: {
                            label: 'Parent Process',
                            cid: id,
                            ckey: `${processProgress}${processPriority}${get(process, 'closedDate')}`,
                            Component: () => process ? (
                                <ListItem component="div">
                                    <ListItemAvatar>
                                        <ProcessAvatar iconColor={processIconColor}>
                                            {getFieldByType('avatar', {
                                                children: <Icon name={iconsSet.has([processIcon]) ? processIcon : 'asterisk'} hexColor={muiTheme.colors.text.button} />
                                            })}
                                        </ProcessAvatar>
                                    </ListItemAvatar>
                                    <ListItemTextStyled
                                        primary={(
                                            <ProcessLink id={get(process, 'id')}>
                                                <Tooltip arrow title={get(process, 'name', 'No Name')} placement="top-start">
                                                    <span>{get(process, 'name') || 'No Name'}</span>
                                                </Tooltip>
                                            </ProcessLink>
                                        )}
                                        secondary={<em>#{get(process, 'id')}</em>}
                                    />
                                </ListItem>
                            ) : null,
                        },
                    },
                ],
            },
            {
                field: 'panel',
                type: 'panel',
                properties: { header: 'Schedule', expanded: true },
                children: [
                    { type: 'dateTime', properties: { name: 'primary.startDate', label: 'Start Date', disabled, maxDate: dueDate || undefined } },
                    { type: 'dateTime', properties: { name: 'primary.dueDate', label: 'Due Date', disabled, minDate: startDate || undefined } },
                ],
            },
            {
                field: 'panel',
                type: 'panel',
                properties: { header: 'Details', expanded: true },
                children: [
                    {
                        type: 'userTypeahead',
                        properties: {
                            name: 'process.variables.INITIATOR',
                            label: 'Process started by',
                            valueField: 'id',
                            disabled: true
                        },
                    },
                    { type: 'dateTime', properties: { name: 'createdDate', label: 'Created date', disabled: true } },
                    { type: 'dateTime', properties: { name: 'modifiedDate', label: 'Last Modified', disabled: true } },
                    (!!closedDate && { type: 'dateTime', properties: { name: 'primary.closedDate', label: 'End Date', disabled: true } }),
                ].filter(Boolean),
            },
        ];
    }

    @bind
    @memoize()
    normalizeForSave(task, startDate, dueDate) {
        return {
            ...task,
            primary: {
                ...get(task, 'primary', {}),
                startDate, dueDate,
                priority: get(task, 'primary.priority') === 50 ? 3 : getNum(task, 'primary.priority'),
            },
        };
    }

    @bind
    async onFormSubmit(event) {
        event.preventDefault();
        const { reloadList, details, internal } = this.props;

        const { errors, data } = await this.formRef.current.isValidForm();
        if(!errors) {
            const record = getOnlyUpdatedData(details, {...data, name: data.name.trim()});
            this.props.updateTask(record, internal).then(async(resp) => {
                if (!(resp instanceof Error)) {
                    reloadList && await reloadList();
                }
            });
        }

    }

    @bind
    handleChangeForm(data, { name, value }) {
        switch (name) {
            case 'primary.startDate':
                const start = setSeconds(datefy(value), 0, 0);
                let dueDate = setSeconds(getDate(this.state, 'dueDate'), 0, 0);
                if (start && dueDate && dueDate <= start) {
                    dueDate = new Date(start.getTime() + 3600000);
                }
                this.setState({ startDate: start, dueDate, data });
                break;
            case 'primary.dueDate':
                let startDate = setSeconds(getDate(this.state, 'startDate'), 0, 0);
                let dueDateNew = setSeconds(datefy(value), 0, 0);
                dueDateNew = dueDateNew && new Date(dueDateNew);
                dueDateNew && dueDateNew.setSeconds(0, 0);
                if (startDate && dueDateNew && startDate >= dueDateNew) {
                    startDate = new Date(dueDateNew.getTime() - 3600000);
                }
                this.setState({ dueDate: value, startDate, data });
                break;
            default:

        }
    }

    @bind
    @memoize()
    renderRightActions(sidebarActions, isLoading) {
        const { canEdit } = this.props;
        if (!canEdit) {
            return sidebarActions;
        }
        return (
            <>
                {isLoading
                    ? <CircularProgress key={113} size={24} color="primary" />
                    : <Button key={113} onClick={this.onFormSubmit} color="primary" form="form" type="submit">Save</Button>
                }
                {sidebarActions}
            </>
        );
    }

    render() {
        const { theme, details, sidebarActions, breadcrumbLine, isLoading, canEdit, isSidebar } = this.props;
        const { startDate, dueDate, data } = this.state;

        const task = this.normalizeForSave(data, startDate, dueDate);
        const components = this.fieldDefinitions({ task, theme, startDate, dueDate, canEdit });

        return (
            <>
                {get(details, 'type') !== 'closedtask' && (
                    <HeaderBar right={this.renderRightActions(sidebarActions, isLoading)} left={breadcrumbLine} />
                )}
                <ContentArea withHeader isSidebar={isSidebar}>
                    <Container>
                        <FormGeneratorStyled onChange={this.handleChangeForm} ref={this.formRef} components={components} data={task} disabled={!canEdit} />
                    </Container>
                </ContentArea>
            </>
        );
    }
}

export default connect((state, props) => ({
    id: props.id || get(props, 'details.id'),
    details: props.internal ? state.abox.task.sidebarDetailsInternal.data : state.abox.task.sidebarDetails.data,
}), {
    loadTaskSidebarDetails,
    loadTaskSidebarDetailsInternal,
    updateTask,
    setDocumentTitle,
})(withTheme(TaskAbout));
