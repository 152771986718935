/* @flow */

const classesList = (isAdmin, permissions) => {
    const permissionsSet = new Set(permissions);
    return [
        (isAdmin || permissionsSet.has('class.class')) && { name: 'Entity Types', icon: 'primary-class', iconType: 'af', to: '/entity-types' },
        (isAdmin || permissionsSet.has('class.class')) && { name: 'Classes', icon: 'Attribute-class', iconType: 'af', to: '/classifications' },
    ].filter(Boolean);
};

export default classesList;
