/* @flow */

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Typography, ConfirmationModal } from '@mic3/platform-ui';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

import { setDocumentTitle, showToastr } from 'store/actions/app/appActions';
import { loadUserProfile, uploadProfileImage, userUpdatePosition } from 'store/actions/admin/usersActions';

import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import Geocode from 'app/utils/maps/geocodeUtils';
import { getAttachmentUrl } from 'app/utils/attachments/attachmentsUtils';
import affectliSso from 'app/auth/affectliSso';
import { modulesAndPageTitles } from 'app/config/typesConfig';
import { muiTheme } from 'app/themes/materialUi';

import Icon from 'app/components/atoms/Icon/Icon';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import { CardContent, Divider } from '@material-ui/core';
import { loadEntityImage } from 'store/actions/entities/entitiesActions';

const useStyles = withStyles({
    avatar: {
        width: '200px',
        height: '200px',
    },
    headerSpacing: {
        marginTop: '1rem',
        color: 'white',
        fontSize: '24px',
        paddingLeft: '14px',
    },
});

const Scroller = styled.div`
    overflow-y: scroll;
    height: 95vh;
`;

const ContentHeading = styled.div`
    font-size: 20px !important;
    font-weight: 500 !important;
    padding: 7px 14px 8px 14px;
    color: white;
    p {
        margin: 0;
        padding: 4px 4px 8px 8px;
    }
`;

const CardContentWrapper = styled(CardContent)`
    width: calc(100% - 36rem);
    @media (max-width: 921px) {
        width: 100%;
        margin-bottom: 4rem;
    }
    margin: 0 auto;
    div {
        background-color: ${({ theme }) => theme.material.colors.background.paper}; 
        @media (max-width: 921px) {
            padding-bottom: 0px;
            margin-top: 0vh;
        }
    }
    .MuiFormControl-root {
        width: 100%; 
    }
    .MuiFormControlLabel-labelPlacementStart {
        display: flex;
        justify-content: space-between;
    }
    .MuiSwitch-root {
        margin-right: 2rem;
    }
`;

const StyledText = styled(Typography)`
    color: ${({ theme }) => theme.material.colors.text.primary};
    padding: 15px 5px 15px 20px;
    font-size: 24px !important;
`;
const StyledTitle = styled.p`
    color: ${({ theme }) => theme.material.colors.text.primary};
`;

const StyledIcon = styled(Icon)`
    padding-top: 15px;
    padding-right: 4px;
`;

const LocationLabelStyle = styled.span`
    margin-left: -5px;
    span {
        padding-left: 3px;
    }
`;

/**
 * Renders the view to show user profile.
 */
class ShowUser extends PureComponent<Object, Object> {
    static propTypes = {
        loadUserProfile: PropTypes.func.isRequired,
        uploadProfileImage: PropTypes.func.isRequired,
        profile: PropTypes.object.isRequired,
        showToastr: PropTypes.func.isRequired,
    };
    
    timer;
    state = {
        showConfirmModal: false,
        profileImage: this.props.profile.image,
    };

    componentDidMount() {
        this.props.setDocumentTitle(modulesAndPageTitles.profile);
        
    }

    @bind
    @memoize()
    fieldDefinitions(profile: Object, image) {
        const { id, name } = profile;
        return [
            {
                field: '',
                type: 'avatarEditor',
                properties: {
                    name: 'image',
                    initials: name,
                    image: image && getAttachmentUrl(id, 'user', image),
                    ReactAvatarEditorProps: {
                        border: 1,
                        borderRadius: 100,
                    },
                    EditButtonProps: {
                        label: 'Edit Profile Image',
                    },
                    GridProps: {
                        alignItems: 'center',
                    },
                    AvatarGridProps: {
                        direction: 'column',
                        justify: 'space-around',
                    },
                    AvatarProps: {
                        className: this.props.classes.avatar,
                    },
                },
            },
            {
                field: 'userPositionTrackingEnabled',
                type: 'boolean',
                properties: {
                    name: 'userPositionTrackingEnabled',
                    labelPlacement: 'start',
                    label: (
                        <LocationLabelStyle>
                            <Icon name='map-marker-radius' />
                            <span>Location Sharing</span>
                        </LocationLabelStyle>
                    ),
                    help: (
                        <Typography>
                            Your location will be shared and visible to other users on Affectli. The precision of location is based on your
                            device's location settings.
                        </Typography>
                    ),
                },
            },
            {
                field: 'unAttendedMode',
                type: 'boolean',
                properties: {
                    name: 'unAttendedMode',
                    labelPlacement: 'start',
                    fullWidth: true,
                    label: (
                        <LocationLabelStyle style={{ paddingRight: '2.3rem' }}>
                            <Icon name='presentation' />
                            <span>Unattended mode</span>
                        </LocationLabelStyle>
                    ),
                    help: (
                        <Typography>
                            Use unattended mode when you need to stay logged in for extended periods of time. For example, when you want to
                            display maps or charts on an overhead display and there will not be a person accessing the computer used for
                            displaying these.
                        </Typography>
                    ),
                },
            },
            {
                field: 'name',
                type: 'text',
                properties: {
                    name: 'name',
                    InputProps: { startAdornment: <StyledIcon name='affectli' type='af' /> },
                    disabled: true,
                    margin: 'none',
                },
            },
            {
                field: 'primary.username',
                type: 'text',
                properties: {
                    name: 'primary.username',
                    InputProps: { startAdornment: <StyledIcon name='account' /> },
                    disabled: true,
                    margin: 'none',
                },
            },
            {
                field: 'primary.email',
                type: 'text',
                properties: {
                    name: 'primary.email',
                    InputProps: { startAdornment: <StyledIcon name='email' /> },
                    disabled: true,
                    margin: 'none',
                },
            },
            {
                field: '',
                type: 'displayText',
                properties: { text: 'About', variant: 'h6', className: this.props.classes.headerSpacing,  style:{color: muiTheme.colors.text.primary}},
            },
            {
                field: 'createdDate',
                type: 'dateTime',
                properties: { name: 'createdDate', label: 'Created date', format: 'LLL', disabled: true, margin: 'none' },
            },
        ];
    }

    @bind
    uploadCroppedImage(image) {
        const { profile, uploadProfileImage, loadEntityImage } = this.props;

        uploadProfileImage(image).then(async (resp) => {
            if(!(resp instanceof Error)) {
                const response = await loadEntityImage('user', profile.id)
                if (response?.image) {
                    this.setState({ profileImage: response?.image })
                }
            }
        });
    }

    @bind
    handleChange(event: Object, { name, value }: Object) {
        if (name === 'userPositionTrackingEnabled') {
            if (value) {
                localStorage.setItem(name, 'true');
                Geocode.updateUserLocation((response: Object) => {
                    if (response && response.code === 1) {
                        this.props.showToastr({ severity: 'error', detail: `Please enable your location sharing.` });
                        localStorage.setItem(name, 'false');
                        this.forceUpdate();
                    }
                });
            } else {
                localStorage.setItem(name, 'false');
            }
        } else if (name === 'image') {
            this.uploadCroppedImage(value);
        } else if (name === 'unAttendedMode') {
            value && affectliSso.generateKey('unAttendedMode');
            value && localStorage.setItem(name, 'true');
            !value && this.showModal();
        }
    }

    @bind
    showModal(){
        this.setState({ showConfirmModal: true });
    }

    @bind
    onCloseModal(){
        this.setState(prevState => ({ showConfirmModal: !prevState.showConfirmModal }));
    }

    @bind
    onConfirm(){
        localStorage.setItem('unAttendedMode', 'false');
        affectliSso.logout();
    }

    render(): Object {
        const { name, id } = this.props.profile;
        const { showConfirmModal, profileImage } = this.state;
        const title = `${name || ''} (${id.slice(0, 8)})`;
        const userPositionTrackingEnabled = localStorage.getItem('userPositionTrackingEnabled') === 'true' || false;
        const unAttendedMode = localStorage.getItem('unAttendedMode') === 'true' || false;
        return (
            <Scroller>
                <ContentHeading>
                    <StyledTitle> About Me </StyledTitle>
                    <Divider />
                </ContentHeading>
                <CardContentWrapper>
                    <div>
                        <StyledText>{title}</StyledText>
                        <FormGenerator
                            components={this.fieldDefinitions(this.props.profile, profileImage)}
                            data={{ ...this.props.profile, userPositionTrackingEnabled, unAttendedMode }}
                            onChange={this.handleChange}
                        />
                    </div>
                </CardContentWrapper>
                {showConfirmModal && (
                    <ConfirmationModal
                        header='Are you sure?'
                        message='Disabling unattended mode will log you out. Are you sure you want to continue?'
                        onClose={this.onCloseModal}
                        open={showConfirmModal}
                        onConfirm={this.onConfirm}
                        confirmButtonText='Continue'
                        declineButtonText='Cancel'
                    />
                )}
            </Scroller>
        );
    }
}


export default connect(
    (state) => ({
        profile: state.user.profile,
    }),
    { uploadProfileImage, loadUserProfile, userUpdatePosition, showToastr, setDocumentTitle, loadEntityImage}
)(useStyles(ShowUser)); // eslint-disable-line
