import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import styled from 'styled-components';

import { saveBoardPreferences } from 'store/actions/admin/usersActions';
import { loadLeftPanelBoards, loadLeftPanelBetaBoards, closeLeftPanel, setActions, setLeftPanelSortBy } from 'store/actions/leftPanel/leftPanelActions';
import { loadClassesListView } from 'store/actions/classifications/classificationsActions';
import AddNewBoardModal from 'app/containers/Abox/Boards/AddNewBoardModal';
import { StyledButton } from 'app/containers/Abox/Boards/styles';

import { bind, memoize, debounce } from 'app/utils/decorators/decoratorUtils';
import { get } from 'app/utils/lo/lo';
import { shallowEquals } from 'app/utils/utils';

import BoardItems from 'app/containers/Abox/Boards/BoardItems';
import LeftPanelSearch from 'app/components/molecules/LeftPanelSearch/LeftPanelSearch';

import { leftPanelMenu } from 'app/config/leftPanelConfig';
import Icon from 'app/components/atoms/Icon/Icon';
import Loader from 'app/components/atoms/Loader/Loader';
import NestedMenu from 'app/components/molecules/NestedMenu/NestedMenu';
import GlobalLeftPanelHeader from 'app/components/organisms/GlobalLeftPanel/GlobalLeftPanelHeader';
import { parseView } from 'app/components/organisms/GlobalLeftPanel/GlobalLeftPanel';

const SidePanelWrapper = styled.div`
    ${({records}) => (records.length === 0 ? 'overflow: hidden;' : 'overflow: auto;')}
    ${({ isMobile }) => (!isMobile ? 'height: calc(100vh - 108px);' : null)}
    margin-right: 6px;
`;


const NoBoardsCreated = styled.div`
    display: flex;
    flex: 1;
    height: 100%;
    flex-direction: column;
    ${({ records }) => (!records ? 'align-items: center;' : '')}
    ${({ records }) => (!records ? 'justify-content: center;' : '')}
`;

const NoSelectedText = styled.div`
    font-size: 24px;
    font-weight: 200;
    color: rgba(255, 255, 255, 0.38);
    @media (max-width: 481px) {
        font-size: 24px;
    }
`;

const IconStyled = styled(Icon)`
    @media (max-width: 481px) {
        margin-right: 12px;
    }
    &:before {
        font-size: 85px !important;
        color: rgba(255, 255, 255, 0.24);
        @media (max-width: 481px) {
            font-size: 48px !important;
        }
    }
`;

class BoardsLeftPanel extends PureComponent<Object, Object> {
    static propTypes = {
        id: PropTypes.string,
        type: PropTypes.string,
        title: PropTypes.string,
        isOpen: PropTypes.bool,
        loadCustomEntityBoardLabels: PropTypes.func,
        setActions: PropTypes.func,
        saveBoardPreferences: PropTypes.func,
        closeLeftPanel: PropTypes.func,
    };

    static defaultProps = {
        records: null,
    };

    formRef: Object = React.createRef();

    constructor(props: Object) {
        super(props);
        const { leftPanelSortBy } = props;
        this.state = {
            isModalOpen: false,
            searchValue: '',
            searchResults: [],
            entityClasses: {},
            settingAnchorEl: null,
            orderBy: leftPanelSortBy?.length ? leftPanelSortBy : [
                { field: 'name', direction: 'asc nulls last' }
            ]
        };
        this.setActions(props.entityType, this.state.searchValue);
        this.initData();
    }

    componentDidUpdate(prevProps, prevState) {
        this.initData(prevProps);
        const { content, entityType, leftPanelSortBy, betaBoards, location } = this.props;
        const isContentChanged = prevProps.content !== content;
        const pathChanged = location?.pathname !== prevProps?.location?.pathname;

        if (isContentChanged || this.state.searchValue !== prevState.searchValue || prevProps.betaBoards !== betaBoards) {
            this.setActions(entityType, this.state.searchValue);
        }
        if (isContentChanged || pathChanged) {
            this.setState({ searchValue: '' });
        }
        if (!shallowEquals(prevProps.leftPanelSortBy, leftPanelSortBy)) {
            this.setState({ orderBy: leftPanelSortBy?.length ? leftPanelSortBy : [
                { field: 'name', direction: 'asc nulls last' }
            ] });
        }
        
    }

    @bind
    setActions(type) {
        this.props.setActions(
            <GlobalLeftPanelHeader
                key={type}
                onHide={this.props.closeLeftPanel}
                onClickSettings={this.openSetting}
            />
        );
    }

    @bind
    onSettingMenuClick(title) {
        const { setLeftPanelSortBy, location } = this.props;
        switch (title) {
            case 'Sort by name (A-Z)':
                this.setState({
                    orderBy: this.setDirection(get(this.state, 'orderBy.[0].direction'))
                });
                setLeftPanelSortBy(this.setDirection(get(this.state, 'orderBy.[0].direction')) , parseView(location.pathname));
                break;
            default:
                break;
        }
    }

    @bind
    handleSearch(searchValue) {
        this.setState({ searchValue });
    }

    @bind
    @memoize()
    reloadList(searchValue) {
        const { loadLeftPanelBoards, loadLeftPanelBetaBoards,  betaBoards } = this.props;
        const options = [];
        if (searchValue) {
            options.push({ field: 'name', op: 'contains', value: searchValue });
        }
        return betaBoards ? loadLeftPanelBetaBoards(options) : loadLeftPanelBoards(options);
    }

    initData(prevProps = {}) {
        const { isOpen, view } = this.props;
        const { searchValue } = this.state;
        if (!isOpen) {
            return;
        }
        this.reloadList(searchValue, view);
    }

    buildTree(id, records) {
        const { betaBoards } = this.props;
        return (
            <div>
                <BoardItems
                    items={
                        betaBoards
                            ? [
                                {
                                    iconType: 'af',
                                    iconName: 'process-builder',
                                },
                                ...records
                                    .filter((item) => item.type === 'system_board')
                                    .map((item) => ({
                                        ...item,
                                        subTitle: item.id,
                                    })),
                            ]
                            : [
                                {
                                    iconType: 'af',
                                },
                                ...records.map((item) => ({
                                    ...item,
                                    subTitle: item.id,
                                })),
                            ]
                    }
                    betaBoards={betaBoards}
                />
            </div>
        );
    }

    @bind
    @memoize()
    filterRecords(records, searchValue, view, classification, orderBy) {
        let nextRecords = [...(records || [])];
        nextRecords.sort((a, b) => a.name > b.name ? 1 : -1);
        if(orderBy[0].direction.includes('desc')) {
            nextRecords = nextRecords.reverse();
        }
        if(!searchValue) return nextRecords;
        return (nextRecords || []).filter(record => get(record, 'name', '').toLowerCase().includes(searchValue.toLowerCase()));
    }


    @bind
    @debounce()
    closeLeftPanel() {
        this.props.closeLeftPanel();
    }
    
    @bind
    closeSetting() {
        this.setState({ settingAnchorEl: null });
    }

    @bind
    openSetting(e) {
        this.setState({ settingAnchorEl: e.currentTarget });
    }

    @bind
    setDirection(direction) {
        if (direction.includes('asc')) {
            return [{ field: 'name', direction: 'desc nulls last' }];
        }
        return [{ field: 'name', direction: 'asc nulls last' }];
    }

    @bind
    onCloseModal() {
        this.setState({ isModalOpen: false });
    }

    @bind
    onOpenModal() {
        this.setState({ isModalOpen: true });
    }

    render() {
        const { id, records, isLoading, view, classification, entityType, type, isMobile, primaryClass, betaBoards, profile } = this.props;
        const { settingAnchorEl, orderBy, searchValue, isModalOpen } = this.state;
        const filteredRecords = entityType === 'kanbanboards' ? this.filterRecords(records, searchValue, view, classification, orderBy) : records;
        const {isAdmin, permissions: profilePermissions } = profile;
        const canAdd = isAdmin || profilePermissions.includes('entity.entity.add');
        return (
            <>
                <SidePanelWrapper isMobile={isMobile} records={records}>
                    <LeftPanelSearch onSearch={this.handleSearch} searchValue={searchValue} type={type} />
                    {records?.length === 0 && (
                        <NoBoardsCreated>
                            <IconStyled type={'af'} name={'Kanban'} />
                            <NoSelectedText>Let's Get Started</NoSelectedText>
                            { betaBoards && canAdd && <StyledButton variant='text' color='secondary' onClick={this.onOpenModal}> Create New </StyledButton>}
                        </NoBoardsCreated>
                    )}
                    {isLoading && <Loader absolute/>}
                    {(!isLoading && records?.length) ? this.buildTree(id, filteredRecords) : null}

                </SidePanelWrapper>
                <NestedMenu
                    items={leftPanelMenu()}
                    open={Boolean(settingAnchorEl)}
                    onItemClick={this.onSettingMenuClick}
                    anchorEl={settingAnchorEl}
                    onClose={this.closeSetting}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                    }}
                />
                {(isModalOpen && betaBoards) && (
                    <AddNewBoardModal
                        onCloseModal={this.onCloseModal}
                        primaryClass={primaryClass}
                        preferences={this.props.preferences}
                    />
                )}
            </>
        );
    }
}
export default connect(
    (state: Object, ownProps: Object) =>{
        let isLoading = false;
        let records = [];
        if(ownProps?.betaBoards){
            isLoading = state.leftPanel.betaBoards.isLoading;
            records = state.leftPanel.betaBoards?.data?.records || [];
        }else{
            isLoading = state.leftPanel.boards.isLoading;
            records = state.leftPanel.boards?.data?.records || [];
        }
        return {
            isLoading,
            records,
            isOpen: state.leftPanel.state.isOpen,
            profile: state.user.profile,
            preferences: state.user.preferences,
            leftPanelSortBy: state.leftPanel.state.sortBy?.[parseView(window?.location?.hash)],
            isMobile: state.global.isMobile,
            primaryClass: state.abox.boards.primaryClass.data,
        };
    },
    {
        closeLeftPanel,
        setActions,
        saveBoardPreferences,
        loadLeftPanelBoards,
        loadLeftPanelBetaBoards,
        loadClassesListView,
        setLeftPanelSortBy,
    }
)(withRouter(BoardsLeftPanel));
