/* @flow */

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

import integration from 'app/config/integrationConfig';
import { openTour, setHeader } from 'store/actions/app/appActions';
import { get } from 'app/utils/lo/lo';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import aboxList from 'app/components/organisms/AppNavigation/menus/aboxList';
import adminList from 'app/components/organisms/AppNavigation/menus/adminList';
import analyticsList from 'app/components/organisms/AppNavigation/menus/analyticsList';
import integrationMenus from 'app/components/organisms/AppNavigation/menus/integrationMenus';
import designerList from 'app/components/organisms/AppNavigation/menus/designerList';
import entitiesList from 'app/components/organisms/AppNavigation/menus/entitiesList';
import classesList from 'app/components/organisms/AppNavigation/menus/classesList';
import mapsList from 'app/components/organisms/AppNavigation/menus/mapsList';
import miStreamList from 'app/components/organisms/AppNavigation/menus/miStreamList';
import helpList from 'app/components/organisms/AppNavigation/menus/helpList';
import profileList from 'app/components/organisms/AppNavigation/menus/profileList';
import { ReactPWAInstallContext } from 'app/containers/Pwa/PwaIndex';

import AppNav from './AppNavigation/AppNav';
import { Tutorial_Pages } from 'app/config/tourConfig';

/**
 * The main application navigation
 */
class AppNavigation extends PureComponent<Object, Object> {

    static propTypes = {
        toggleMenu: PropTypes.func,
        openMenu: PropTypes.func,
        closeMenu: PropTypes.func,
        isLeftOpen: PropTypes.bool,
        isAdmin: PropTypes.bool,
        permissions: PropTypes.array,
        isMobile: PropTypes.bool,
        expanded: PropTypes.bool
    };

    static defaultProps = { isAdmin: false, permissions: [] };
    static contextType = ReactPWAInstallContext;
    /**
     * @param props
     */
    constructor(props) {
        super(props);
        const { location, version, isMobile, openTour, integrations } = props;
        const tutorialPage = this.getTutorialPages();
        const currentMenu = this.getCurrentMenu(location, version, isMobile, openTour, tutorialPage, integrations);
        this.state = { menu: currentMenu };
        if(currentMenu) {
            const title = currentMenu.title;
            this.props.setHeader({ title });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { menu } = this.state;
        const { location, version, isMobile, openTour, integrations } = this.props;
        const tutorialPage = this.getTutorialPages();
        const currentMenu = this.getCurrentMenu(location, version, isMobile, openTour, tutorialPage, integrations);
        const title = currentMenu?.title;
        const isMenuChanged = currentMenu && currentMenu.key !== get(menu, 'key');
        
        if (isMenuChanged) {
            this.setState({ menu: currentMenu }, () => {
                this.props.setHeader({ title });
            });
        }
        if (prevProps.isMobile !== this.props.isMobile) {
            this.setState({ menu: currentMenu }, () => {
                this.props.setHeader({ title });
            });
        }
    }

    @bind
    getTutorialPages() {
        return Tutorial_Pages.find( page => page === window.location.hash.replace('#/','').trim());
    }

    @bind
    getMenu(){
        const { isAdmin, permissions, integrations } = this.props;
        return [
            { key: 'aboxAlive', title: 'A-Live', pathes: ['abox/alive'], content: aboxList(isAdmin, permissions) },
            { key: 'abox', title: 'A-Box', pathes: ['abox'], content: aboxList(isAdmin, permissions) },
            {
                key: 'admin',
                title: 'Admin Console',
                pathes: [
                    'organisation-settings',
                    'broadcasts',
                    'user-management',
                    'logs',
                    'teams',
                    'workspaces',
                    'licence',
                    'background-jobs',
                    'background-job',
                    'terms',
                    'admin',
                ],
                content: adminList(isAdmin, permissions),
            },
            { key: 'profile', title: 'Profile', pathes: ['about-me', 'api-key','user-preferences'], content: profileList(isAdmin) },
            {
                key: 'analytics',
                title: 'Analytics',
                pathes: ['print-reports', 'super-analytics', 'grafana', 'analytics'],
                content: analyticsList(isAdmin, permissions),
            },
            { key: 'designer', title: 'Designer', pathes: ['designer', 'mi-stream'], content: designerList(isAdmin, permissions) },
            {
                key: 'entities',
                title: 'Entities',
                pathes: [
                    'entities',
                    'things',
                    'people',
                    'organisations',
                    'custom-entities',
                    'print-templates',
                    'relation-definitions',
                    'graphics-library',
                    'related-entities',
                ],
                content: entitiesList(isAdmin, permissions),
            },
            { key: 'classes', title: 'Classes', pathes: ['classifications', 'entity-types'], content: classesList(isAdmin, permissions) },
            { key: 'maps', title: 'Maps', pathes: ['maps'], content: mapsList(isAdmin, permissions) },
            {
                key: integration.type,
                title: integration.moduleName,
                pathes: [integration.path],
                content: integrationMenus(isAdmin, permissions, integrations),
            },
            { key: 'miStream', title: 'Mi-Stream', pathes: ['events', 'pipeline-monitor'], content: miStreamList(isAdmin, permissions) },
        ];
    }

    @bind
    addTourAction(menu, tourAction, page){
        return menu.map((menu) => {
            if(menu.icon === 'web') {
                return {
                    ...menu,
                    label: `${menu.label} ${page === 'abox'? 'A-Box' : page}`,
                    onClick: () => tourAction()
                };
            }
            return menu;
        });
    };

    @bind
    addInstallPwa(helpMenu){
        setTimeout(() => {
            const { pwaInstall, supported, isInstalled } = this.context;
            const handleClick = () =>{   
                pwaInstall({
                    title: 'Add to homescreen',
                })
                    .then(() => alert('App installed successfully or instructions for install shown'))
                    .catch(() => alert('User opted out from installing'));
            };
    
            if(!isInstalled() && supported()){
                const indexPWA = helpMenu.length - 1;
                helpMenu.splice(indexPWA,0,{
                    label: 'Install Affectli to device',
                    icon: 'download',
                    onClick: () => handleClick()
                });
            }
        }, 500);
        return  helpMenu;
    }

    @bind
    @memoize()
    getCurrentMenu(location, version, isMobile, openTour, tutorialPage, integrations) {
        const { pathname } = location || { pathname: '' };
        let basePath = pathname.split('/')[1];
        const subPath = pathname.split('/')[2] || '';

        if (subPath === 'alive' || !basePath) {
            basePath = 'abox/alive';
        }

        const help = helpList(version)[0];
        help.children = tutorialPage ? this.addTourAction(help.children, openTour, tutorialPage) : help.children.filter(page => !page.label.includes('tour'));
        help.children = this.addInstallPwa(help.children);
        const currentMenu = {...(this.getMenu().find(m => m.pathes.includes(basePath)) || {})};
        if (currentMenu && Array.isArray(currentMenu.content)) {
            currentMenu.content = [ ...(currentMenu.content || []), isMobile && help ].filter(Boolean);
        }
        return currentMenu;
    }

    @bind
    openMenu() {
        !this.props.isLeftOpen && this.props.openMenu();
    };

    render() {
        const { menu } = this.state;
        const { expanded } = this.props;
        return (
            <AppNav {...this.props} isExpanded={expanded} menuItems={get(menu, 'content', [])} />
        );
    }
}

const mapStateToProps = (state: Object) => ({
    permissions: state.user.profile.permissions,
    isAdmin: state.user.profile.isAdmin,
    online: state.app.online,
    windowWidth: state.global.window.width,
    isMobile: state.global.isMobile,
    expanded: state.sidebar.expanded,
    version: state.app.version,
    integrations: state.user.integrations.records,
    // documentTitle: state.app.documentTitle,
});
export default withRouter<Object>(connect<Object, Object>(mapStateToProps, { setHeader, openTour })(AppNavigation));
