/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, CircularProgress } from '@mic3/platform-ui';

import history from 'store/History';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import { bind } from 'app/utils/decorators/decoratorUtils';
import { createEntity } from 'store/actions/entities/entitiesActions';
import { groupFields } from 'app/utils/classification/classificationForm';
import { getClassAncestors } from 'app/utils/classification/classificationUtils';

class AddModule extends PureComponent<Object, Object> {
    static propTypes = {
        createEntity: PropTypes.func.isRequired,
        title: PropTypes.string,
    };

    state = { activeClass: this.props.defaultSelected || null };

    formRef = React.createRef();


    @bind
    buildComponents() {
        const { primaryClasses } = this.props;
        const selectedPrimaryClass = primaryClasses?.find(cls => cls?.uri === 'iot_module');
        const classAncestors =  getClassAncestors(selectedPrimaryClass?.id, primaryClasses);
        return [
            {
                type: 'text',
                properties: { label: 'Name', name: 'name' },
                constraints: { required: true }
            },
            ...(classAncestors || []).map(cls => ({
                type: 'group',
                properties: { name: 'attributes' },
                children: groupFields(cls?.formDefinition?.fields, { classData: cls })
            }))
        ].filter(Boolean);
    }

    @bind
    onFormSubmit(event: Event) {
        event.preventDefault();
        const { reloadList } = this.props;
        this.formRef.current.isValidForm().then(({ data, errors }) => {
            if (!errors) {
                this.props
                    .createEntity({
                        name: data?.name?.trim(),
                        type: 'iot_module',
                        primary: data.attributes
                    })
                    .then(async (result) => {
                        const { id } = result || {};
                        if(!(result instanceof Error) && result.id) {
                            if (reloadList) {
                                reloadList();
                            } else {
                                history.push(`/designer/smart-modules/${id}`);
                            }
                        }
                    });
            }
        });
    }

    render(): Object {
        const { onClose, isLoading } = this.props;
        return (
            <>
                <ModalDialog
                    title={`Create a new module`}
                    onClose={onClose}
                    actions={isLoading ? <CircularProgress size={24} color="primary" /> : <Button onClick={this.onFormSubmit}>Save</Button>}
                >
                    <FormGenerator components={this.buildComponents()} ref={this.formRef} />
                </ModalDialog>
            </>
        );
    }
}

export default connect(
    state => ({
        isLoading: state.entities.createEntity.isLoading,
        primaryClasses: state.app.allPrimaryClasses.records,
    }),
    {
        createEntity,
    }
)(AddModule);
