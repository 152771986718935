/* eslint-disable indent */
import React from 'react';
import DotMenu from 'app/components/molecules/DotMenu/DotMenu';
import styled from 'styled-components';

const StyledDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const Download3DSvgGeom = (props: Object) => {
    const { fileType, disabled } = props || {};
    let message = '';

    switch (fileType) {
        case 'is3d':
            message = '3D Model is uploaded';
            break;
        case 'svgFile':
            message = 'SVG is uploaded';
            break;
        case 'geomFile':
            message = 'Geometry uploaded';
            break;
        default:
            return '';
    }

    const renderDotMenu = (deleteFile, downloadFileAction, fileType) => {
        const moreMenu = [
            {
                name: 'Download',
                icon: '',
                fileType,
                onItemClick: downloadFileAction,
                disabled,
            },
            {
                name: 'Delete ',
                icon: '',
                fileType,
                onItemClick: deleteFile,
                disabled
            },
        ];
        return <DotMenu items={moreMenu} />;
    };
    return (
        <StyledDiv>
            <div style={{ marginLeft: '10px' }} key='svgDownloadMenu'>{fileType ? <div>{message}</div> : ''}</div>
            <div>{renderDotMenu(props.deleteFile, props.downloadFileAction, fileType)}</div>
        </StyledDiv>
    );
};

export default Download3DSvgGeom;
