/* @flow */

const aboxMenu = (isAdmin, permissions) => {
    const permissionsSet = new Set(permissions);
    return [

        (isAdmin || permissionsSet.has('abox.task')) && {
            icon: 'checkbox-marked-circle-outline',
            name: 'Tasks',
            to: '/abox/tasks',
            relatedTo: '/abox/task'
        },
        (isAdmin || permissionsSet.has('abox.process')) && {
            iconType: 'af',
            icon: 'processes',
            name: 'Processes',
            to: '/abox/processes',
            relatedTo: '/abox/process',
        },
        (isAdmin || permissionsSet.has('entity.entity')) && {
            name: 'Go to Entities',
            iconType: 'af',
            icon: 'things',
            to: '/entities'
        },
        (isAdmin || permissionsSet.has('entity.entity')) && {
            name: 'Go to Related Entities',
            icon: 'file-tree',
            to: '/related-entities',
            divider: true,
        },
        (isAdmin || permissionsSet.has('abox.board')) && {
            name: 'Boards',
            icon: 'Kanban',
            iconType: 'af',
            to: '/abox/kanbanboards'
        },
        (isAdmin || permissionsSet.has('abox.calendar')) && {
            name: 'Calendar',
            icon: 'calendar-blank',
            to: '/abox/calendar'
        },
        (isAdmin || permissionsSet.has('abox.timeline')) && {
            name: 'Timeline',
            icon: 'chart-gantt',
            to: '/abox/timeline'
        },
        (isAdmin || permissionsSet.has('abox.backgroundjob')) && {
            name: 'Background Jobs (Beta)',
            icon: 'coffee',
            to: '/abox/background-jobs'
        }

    ].filter(Boolean);
};
export default aboxMenu;
