/* @flow */

import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import PageNotAllowed from 'app/containers/ErrorPages/PageNotAllowed';
import { RouterMatchPropTypeBuilder } from 'app/utils/propTypes/common';
import ClassesListView from 'app/containers/Classifications/ClassificationList/ClassesListView';
import ClassificationDetail from 'app/containers/Classifications/ClassificationDetail/ClassificationDetail';
import AddClassification from 'app/containers/Classifications/AddClassification';

/**
 * Defines the routes for the Classification views
 */
class EntityTypesRoute extends PureComponent<Object, Object> {

    static propTypes = {
        location: PropTypes.object,
        match: RouterMatchPropTypeBuilder({ id: PropTypes.string }),
        previousLocation: PropTypes.object,
        userProfile: PropTypes.object,
    };

    /**
     * @override
     */
    render() {
        const { match } = this.props;
        const { permissions, isAdmin } = this.props.userProfile;
        const permissionsSet = new Set(permissions || []);
        const canView = isAdmin || permissionsSet.has('class.class');
        if (!canView) {
            return <PageNotAllowed title="Entity Types" />;
        }

        return (
            <Fragment>
                <Switch>
                    <Route path={`${ match.url }`} exact render={props => (
                        <ClassesListView {...props} entityType />
                    )} />
                    <Route path={`${ match.url }/add`} exact render={props => (
                        <AddClassification {...props} entityType />
                    )}
                    />
                    <Route path={`${ match.url }/:id`} render={props => (
                        <ClassificationDetail {...props} entityType />
                    )} />
                </Switch>
            </Fragment>
        );
    }
}

export default connect(
    (state: Object): Object => ({
        userProfile: state.user.profile,
    })
)(EntityTypesRoute);
