/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ListItem, ListItemText, Typography, ListItemAvatar } from '@mic3/platform-ui';

import Container from 'app/components/atoms/Container/Container';
import Loader from 'app/components/atoms/Loader/Loader';

import { get } from 'app/utils/lo/lo';
import { isEmpty } from 'app/utils/utils';
import { iconsSet } from 'app/utils/styles/mdi';
import { getFieldByType } from 'app/utils/designer/form/fieldUtils';
import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import Tooltip from 'app/components/atoms/Tooltip/Tooltip';
import ProcessDiagramCard from 'app/components/ABox/Cards/ProcessDiagramCard';
import ProcessLink from 'app/components/atoms/Link/ProcessLink';
import ExpansionPanel from 'app/components/Designer/ExpansionPanel';
import HeaderBar from 'app/components/molecules/HeaderBar/HeaderBar';
import Icon from 'app/components/atoms/Icon/Icon';
import { ProcessAvatar } from './TaskAbout';

const ListItemTextStyled = styled(ListItemText)`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

const PropertiesCard = ({ process }: Object) => {
    const { id, name } = process || {};
    const processIcon = get(process, 'processDefinitionVersion.iconName');
    const processIconColor = get(process, 'processDefinitionVersion.iconColor');
    return (
        <Tooltip x={25} y={0}>
            <Typography variant="subtitle1">Parent Process</Typography>
            <ListItem component="div" disableGutters>
                <ListItemAvatar>
                    <ProcessAvatar iconColor={processIconColor}>
                        {getFieldByType('avatar', {
                            children: <Icon name={iconsSet.has([processIcon]) ? processIcon : 'asterisk'} />
                        })}
                    </ProcessAvatar>
                </ListItemAvatar>
                <ListItemTextStyled
                    primary={(
                        <ProcessLink id={id}>
                            <Tooltip arrow title={name || 'No Name'} placement="top-start">
                                <span>{name || 'No Name'}</span>
                            </Tooltip>
                        </ProcessLink>
                    )}
                    secondary={<em>#{id || 'No ID'}</em>}
                />
            </ListItem>
        </Tooltip>
    );
};

/**
 *
 */
class TaskProcessMapTab extends PureComponent<Object, Object> {
    static propTypes = {
        details: PropTypes.object,
        isLoading: PropTypes.bool,
    };

    /**
     * @override
     */
    render(): Object {
        const { isLoading, details, sidebarActions, breadcrumbLine } = this.props;
        if (isLoading || isEmpty(details)) {
            return <Loader absolute />;
        }
        const { process } = details;
        return (
            <ContentArea>
                {sidebarActions && <HeaderBar right={sidebarActions} left={breadcrumbLine} />}
                <Container width="1024">
                    {process && <PropertiesCard
                        process={process}
                    />}
                    <ExpansionPanel
                        expanded
                        header="Process Diagram"
                    >
                        <ProcessDiagramCard value={get(process, 'processDefinitionVersion.primary.definition')} />
                    </ExpansionPanel>
                </Container>
            </ContentArea>
        );
    }
}

export default TaskProcessMapTab;
