// @flow
import integration from 'app/config/integrationConfig';

const createList: Array<{
    key: string,
    label: string
}> = (isAdmin, permissions) => {
    const permissionsSet = new Set(permissions);
    return [
        { to: '/abox/processes-new', name: 'My Apps' },
        (isAdmin || permissionsSet.has('entity.entity.add')) && { to: '/entities/add/new', name: 'Add a new Entity' },
        (isAdmin || permissionsSet.has('admin.user.add')) && { to: '/user-management/add', name: 'Add a user' },
        (isAdmin || permissionsSet.has('class.class.add')) && { to: '/classifications/add', name: 'Add a classification' },
        (isAdmin || permissionsSet.has('admin.broadcast.add')) && { to: '/broadcasts/add', name: 'Add a broadcast' },
        (isAdmin || permissionsSet.has(integration.permissions.add)) && { to: `${integration.baseRoute}/add`, name: 'Add a new integration' }
    ].filter(Boolean);
};

export default createList;
