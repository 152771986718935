/* @flow */

import { gql } from '@apollo/client';

export default gql`
  mutation ssoAddIdentityProviderMutation($provider: IdentityProviderCreateInput!) {
    result: ssoAddIdentityProvider(identityProvider: $provider) {
      id
      name
      type
    }
  }
`;
