/* @flow */

import React, { memo, PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { MdiIcon, IconButton, Tooltip } from '@mic3/platform-ui';

import { loadScripts, createScript } from 'store/actions/designer/designerScriptActions';
import { bind } from 'app/utils/decorators/decoratorUtils';
import { get } from 'app/utils/lo/lo';
import { fromNow } from 'app/utils/date/date';
import withPermissions from 'app/utils/withPermissions';

import VirtualListManaged from 'app/components/molecules/VirtualList/VirtualListManaged';
import VirtualListItem from 'app/components/molecules/VirtualList/VirtualListItem';
import AddScript from 'app/components/Designer/Modals/AddScript';
import PageTemplate from 'app/components/templates/PageTemplate';
import Filters from 'app/components/organisms/Filters/Filters';
import Layout from 'app/components/molecules/Layout/Layout';
import ListItem from 'app/components/molecules/List/ListItem';
import ScriptLink from 'app/components/atoms/Link/ScriptLink';
import Icon from 'app/components/atoms/Icon/Icon';
import UserEntityLink from 'app/components/atoms/Link/UserEntityLink';
import { commonEntitiesListFilters } from 'app/utils/entity/entityUtils';
import { modulesAndPageTitles } from 'app/config/typesConfig';
import { setDocumentTitle } from 'store/actions/app/appActions';

const ListItemStyled = styled(ListItem)`
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
`;

const ScriptItem = memo(({ script, onActionComplete }) => {
    const { name, id, modifiedBy, modifiedDate } = script;

    return (
        <ListItemStyled
            component={<Icon name="ballot" size="lg" />}
            title={<ScriptLink id={id}>{name}</ScriptLink>}
            subTitle={
                <>
                    <ScriptLink id={id}>#{id}</ScriptLink>
                    {
                        modifiedBy &&
                        <div>
                            Updated By <UserEntityLink id={modifiedBy.id}>{modifiedBy.name}</UserEntityLink>,
                            {' '}{fromNow(modifiedDate)}
                        </div>
                    }
                </>
            }
            raised
        />
    );
});

class Scripts extends PureComponent<Object, Object> {
    virtualListRef = React.createRef();

    static propTypes = {
        isLoading: PropTypes.bool,
        records: PropTypes.array,
        startIndex: PropTypes.number,
        totalRecords: PropTypes.number,
        loadScripts: PropTypes.func.isRequired,
        createScript: PropTypes.func.isRequired,
    };

    state = { isAddScriptOpen: false };

    breadcrumb = [{ title: 'Script Builder' }];
    searchBar = ['name', 'id'];

    filterDefinitions: Array<Object> = [
        { field: 'name', type: 'text', properties: { label: 'Name', name: 'name', opSelector: true }},
        { field: 'id', type: 'uuid', properties: { label: 'ID', name: 'id', opSelector: true }},
        ...commonEntitiesListFilters
    ];
    defaultOrder = [{ field: 'createdDate', direction: 'desc nulls last' }];

    componentDidMount() {
        this.props.setDocumentTitle(modulesAndPageTitles.designer.scripts);
    }

    @bind
    toggleAddScript() {
        this.setState({ isAddScriptOpen: !this.state.isAddScriptOpen });
    };

    @bind
    renderComponent({ style, index, data, resize }) {
        return (
            <VirtualListItem style={style} key={index} index={index} resize={resize} padding={15}>
                <ScriptItem script={data} onActionComplete={get(this.virtualListRef, 'current.resetView')} />
            </VirtualListItem>
        );
    };

    render() {
        const { totalRecords, records, isLoading, startIndex, loadScripts, createScript, canAdd } = this.props;
        const { isAddScriptOpen } = this.state;
        return (
            <PageTemplate title="Scripts">
                <Layout>
                    <Filters
                        id="DesignerScripts"
                        filterDefinitions={this.filterDefinitions}
                        defaultOrder={this.defaultOrder}
                        searchBar={this.searchBar}
                        breadcrumb={this.breadcrumb}
                        rightToolbar={canAdd && (
                            <>
                                <Tooltip title="Add script">
                                    <IconButton onClick={this.toggleAddScript}>
                                        <MdiIcon name="plus" />
                                    </IconButton>
                                </Tooltip>
                            </>
                        )}
                    >
                        {(filterBy, orderBy) => (
                            <VirtualListManaged
                                ref={this.virtualListRef}
                                renderComponent={this.renderComponent}
                                itemSize={121}
                                itemCount={totalRecords}
                                loadData={loadScripts}
                                isLoading={isLoading}
                                startIndex={startIndex || 0}
                                filterBy={filterBy}
                                orderBy={orderBy}
                                list={records}
                                maxWidth="1024"
                                title={`${totalRecords >= 1000 ? '999+' : totalRecords } Scripts`}
                            />
                        )}
                    </Filters>
                </Layout>
                {isAddScriptOpen && <AddScript onClose={this.toggleAddScript} addScript={createScript} />}
            </PageTemplate>
        );
    }
};

export default connect(state => ({
    isLoading: state.designer.scripts.isLoading,
    startIndex: state.designer.scripts.startIndex,
    records: state.designer.scripts.records,
    totalRecords: state.designer.scripts.count,
}), {
    loadScripts,
    createScript,
    setDocumentTitle,
})(withPermissions(Scripts, 'script'));
