/* @flow */

import React, { memo } from 'react';
import styled from 'styled-components';

import ExpansionPanel from 'app/components/Designer/ExpansionPanel';
import ListGroup from 'app/components/molecules/List/ListGroup';


const ExpansionPanelStyled = styled(ExpansionPanel)`
    max-width: 960px;
    margin: 16px auto !important;
`;

const ExpansionCard = (props) => {
    const { 
        help, buildHelperWrapper, children, title, actions, iconName, subtitle, 
        iconType, isActionEdge, onTitleClick, renderTitle,  ...restProps 
    } = props;
    return (
        <ExpansionPanelStyled
            {...restProps}
            help={help}
            buildHelperWrapper={buildHelperWrapper}
            headerComponent={(
                <ListGroup
                    noPadding
                    fullWidth
                    withoutCapitalize
                    name={title}
                    subtitle={subtitle}
                    iconName={iconName}
                    iconType={iconType}
                    actions={actions}
                    headerPadding={true}
                    isActionEdge={isActionEdge}
                    onTitleClick={onTitleClick}
                    renderTitle={renderTitle}
                />
            )}
        >
            {children}
        </ExpansionPanelStyled>
    );
};

export default memo(ExpansionCard);
