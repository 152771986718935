/* @flow */

import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { IconButton, Badge } from '@mic3/platform-ui';

import DotMenu from 'app/components/molecules/DotMenu/DotMenu';
import Icon from 'app/components/atoms/Icon/Icon';
import Loader from 'app/components/atoms/Loader/Loader';
import PageNotAllowed from 'app/containers/ErrorPages/PageNotAllowed';
import PageTemplate from 'app/components/templates/PageTemplate';
import Relations from 'app/containers/Entities/Relationships/Relations';
import EntityPrimaryAbout from 'app/containers/Entities/EntityAbout/EntityPrimaryAbout';
import Breadcrumbs from 'app/components/organisms/Breadcrumbs/Breadcrumbs';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { get } from 'app/utils/lo/lo';
import { loadEntity } from 'store/actions/entities/entitiesActions';
import { isEmpty } from 'app/utils/utils';
import { getUnreadMessages } from 'app/utils/entity/entityUtils';
import { getPermissions } from 'app/config/rolesConfig';
import { closeLeftPanel, setShowBack } from 'store/actions/leftPanel/leftPanelActions';
import { isVisibleTab, buildDotMenu } from 'app/utils/entity/entityUtils';
import { setDocumentTitle } from 'store/actions/app/appActions';

import { openEntitySidebar } from 'store/actions/entities/entitySidebarActions';
import { openTeamSidebar } from 'store/actions/entities/teamSidebarActions';
import { openWorkspaceSidebar } from 'store/actions/entities/workspaceSidebarActions';
import { openTaskSidebar } from 'store/actions/abox/taskSidebarActions';
import { openProcessSidebar } from 'store/actions/abox/processSidebarActions';
import { openRelationSidebar } from 'store/actions/entities/relationSidebarActions';

class ConnectoModuleDetail extends PureComponent<Object, Object> {
    static propTypes = {
        id: PropTypes.string.isRequired,
        loadEntity: PropTypes.func.isRequired,
        details: PropTypes.object,
        isLoading: PropTypes.bool
    };
    state = {
        entityType: '',
    };

    constructor(props: Object) {
        super(props);
        this.reloadDetails();
    }

    componentDidUpdate(prevProps) {
        const { id, leftPanelOpts = {}, closeLeftPanel, setShowBack, type, details, setDocumentTitle } = this.props;
        const name = details?.name;
        const prevName = prevProps?.details?.name;
        if(name && name !== prevName){
            setDocumentTitle(name);
        }
        if (id !== prevProps.id || type !== prevProps.type) {
            this.reloadDetails();
        }
        if (leftPanelOpts?.isOpen) {
            closeLeftPanel();
            setShowBack(false);
        }
    }

    @bind
    reloadDetails() {
        const { id, type, loadEntity } = this.props;
        return loadEntity(type, id);
    }

    @bind
    @memoize()
    renderActions(unreadMessages, permissions, details) {
        if (!permissions?.canComment) {
            return null;
        }
        const { primaryClass } = details || {};
        return (isVisibleTab('A-Live', primaryClass)) ? (
            <IconButton key={66} title="Open messenger" onClick={() => this.onDotMenuClick('A-Live')}>
                <Badge color="error" badgeContent={unreadMessages} max={99}>
                    <Icon name="messenger" type="af" />
                </Badge>
            </IconButton>
        ) : null;
    }

    @bind
    onDotMenuClick(title, params) {
        const { id, openEntitySidebar, type } = this.props;

        const settings = { title, id, type, internal: true, params };
        openEntitySidebar(settings);
    }

    @bind
    @memoize()
    dotMenu(sidebarTitle: string, details: Object, pathname: string = '') {
        const menu = buildDotMenu({ details, title: sidebarTitle, pathname });
        const moreMenus = [
            { name: 'divider' },
            { name: 'Versions', icon: 'content-save-all' },
        ].filter(Boolean);

        return (
            <DotMenu 
                key={13} 
                tooltipTitle='More Options'
                onItemClick={this.onDotMenuClick} 
                items={[...menu, ...moreMenus]} 
            />
        );
    }

    @bind
    buildBreadcrumbs(name, entityTypeName, entityType) {
        const createdLink = entityType === 'iot_connector' ? `/designer/connectors` : `/designer/smart-modules`;        
        return <Breadcrumbs list={[{ link: createdLink, title: entityType === 'iot_connector' ? 'Connectors' : 'Modules' }, { title: name } ]} withGoBack />;
    }

    /**
     * @override
     */
    render() {
        const {
            openEntitySidebar, type: entityType, id, isLoading, details, sidebarTitle, subscriptions,
            match, openTeamSidebar, openWorkspaceSidebar, openTaskSidebar, openProcessSidebar, openRelationSidebar
        } = this.props;
        const { pathname } = this.props.location;
        const permissions = getPermissions(details && details.role);
        const { canEdit } = permissions || {};
        if ((!isLoading || !entityType) && (isEmpty(details) || !permissions.canView)) {
            return <PageNotAllowed permissionError={true} title={`Entities (ID:${id})`} />;
        }
        const unreadMessages = getUnreadMessages(id, subscriptions, entityType);
        const showDetails = details && details.id === id;
        
        return (
            <Fragment>
                {isLoading && <Loader absolute backdrop />}
                {showDetails && (
                    <PageTemplate title={details.name} subTitle={`#${details.id}`} overflowHidden>
                        <Switch>
                            <Route exact path={`/designer/pipelines/:id/relationships`} render={() => (
                                <Relations
                                    openEntitySidebar={openEntitySidebar}
                                    openTeamSidebar={openTeamSidebar}
                                    openWorkspaceSidebar={openWorkspaceSidebar}
                                    openTaskSidebar={openTaskSidebar}
                                    openProcessSidebar={openProcessSidebar}
                                    openRelationSidebar={openRelationSidebar}
                                    fromType={entityType}
                                    fromId={id}
                                    canEdit={canEdit}
                                    details={details}
                                    sidebarActions={[
                                        this.renderActions(unreadMessages, permissions, details), this.dotMenu(sidebarTitle, details, pathname)
                                    ]}
                                />
                            )} />
                            <Route path={`${match.url}`} exact component={() => <Redirect to={`${match.url}/about`} />} />
                            <Route path={`${match.url}/about`}>
                                <EntityPrimaryAbout
                                    openEntitySidebar={openEntitySidebar}
                                    type={entityType}
                                    id={id}
                                    details={details}
                                    sidebarActions={[
                                        this.renderActions(unreadMessages, permissions, details), this.dotMenu(sidebarTitle, details, pathname)
                                    ]}
                                    breadcrumbLine={this.buildBreadcrumbs(details.name, details.primaryClass?.name, entityType)}
                                    canEdit={canEdit}
                                    openSidebar={this.onDotMenuClick}
                                    isPublishedEntity
                                />
                            </Route>
                            <Route path={`${match.url}/relationships`} render={() => (
                                <Relations
                                    openEntitySidebar={openEntitySidebar}
                                    openTeamSidebar={openTeamSidebar}
                                    openWorkspaceSidebar={openWorkspaceSidebar}
                                    openTaskSidebar={openTaskSidebar}
                                    openProcessSidebar={openProcessSidebar}
                                    openRelationSidebar={openRelationSidebar}
                                    fromType={entityType}
                                    fromId={id}
                                    canEdit={canEdit}
                                    details={details}
                                    sidebarActions={[
                                        this.renderActions(unreadMessages, permissions, details), this.dotMenu(sidebarTitle, details, pathname)
                                    ]}
                                />
                            )} />
                            
                        </Switch>
                    </PageTemplate>
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = (state: Object, ownProps: Object) => ({
    id: ownProps.match.params.id,
    isLoading: state.entities.sidebar.details.isLoading,
    details: get(state.entities.sidebar, 'details.data', {}),
    sidebarTitle: state.sidebar.title,
    leftPanelOpts: state.leftPanel.state,
    subscriptions: state.chat.subscriptions.data,
    primaryClasses: state.app.allPrimaryClasses.records || [],
});

export default connect(mapStateToProps, {
    loadEntity, openEntitySidebar, closeLeftPanel, setShowBack, setDocumentTitle,
    openTeamSidebar, openWorkspaceSidebar, openTaskSidebar, openProcessSidebar, openRelationSidebar
})(withRouter(ConnectoModuleDetail));
