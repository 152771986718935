import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { renderToString } from 'react-dom/server';
import Drawflow from 'drawflow';

import RowTarget from 'app/components/molecules/Dnd/RowTarget';
import { uuidv4 } from 'app/utils/dataStructure/Forest';
import { bind } from 'app/utils/decorators/decoratorUtils';
import { get } from 'app/utils/lo/lo';

import PipelineWidget from './Components/PipelineWidget';
import PipelineWidgetAnnotation from './Components/PipelineWidgetAnnotation';
import PipelineContextMenu from 'app/containers/Designer/Pipeline/Components/PipelineContextMenu';

import 'drawflow/dist/drawflow.min.css';

const Pipeline = styled('div')`
width: 100%;
height: calc(100% - 40px);
position: relative;

& .drawflow .drawflow-node {
    background: transparent;
    color: inherit;
    width: auto;
    min-height: auto;
    padding: 0px;
    border: 0;
    border: 2px solid #4682b4;
}
.drawflow .drawflow-node .ItemColumn.Component {
    border-radius: 50%;
    background-color: #4682b4;
}
& .drawflow .drawflow-node.selected {
    filter: brightness(1.2);
}
& .drawflow .drawflow-node:has(div.errored) {
    border: 2px solid ${({ theme }) => theme.material.colors.text.error};
    padding: 0px;
}
& .drawflow .drawflow-node:has(div.errored) .beforeTitle {
    color: ${({ theme }) => theme.material.colors.text.error};
}
& .drawflow .drawflow-node:has(div.errored) .ItemColumn.Component {
    border-radius: 50%;
    background-color: ${({ theme }) => theme.material.colors.text.error};
}

& .drawflow .drawflow-node .inputs .input, & .drawflow .drawflow-node .outputs .output {
    background: #ffffff21;
    border-radius: 0;
    border: 0;
    width: 5px;
    height: 20px;
}
& .drawflow .drawflow-node .inputs .input {
    left: 0;
}
& .drawflow .drawflow-node.topic .inputs .input.input_2 {
    right: 0;
}
& .drawflow .drawflow-node .outputs .output {
    right: 5px;
}
& .drawflow .drawflow-node .drawflow_content_node > div {
    margin: 0 !important;
}
& .drawflow .drawflow-delete {
    display: none;
}
`;

const RowTargetStyled = styled(RowTarget)`
    width: 100%;
    height: 100%;
    position: absolute !important;
`;

export default class PipelineEditorDesigner extends PureComponent {

    state = {
        contextRefTarget: null
    }

    diagramRef = React.createRef();

    componentDidUpdate(prevProps) {
        const { details, pipelineStatus, graphSchema, isPreviewVersion } = this.props;

        if (
            graphSchema !== prevProps.graphSchema
            || (details?.id && pipelineStatus !== prevProps.pipelineStatus)
        ) {
            const initialSchema = this.normalizeSchema({ drawflow: graphSchema || { Home: { data: {} }}});
            this.editor.import(initialSchema);
            this.props.onChangeDiagramState(this.editor.export());
        }

        if(isPreviewVersion !== prevProps.isPreviewVersion) {
            this.editor.editor_mode = isPreviewVersion ? 'view' : 'edit';
        }
    }

    componentDidMount() {
        const { pipelineMonitor, graphSchema }= this.props;

        this.editor = new Drawflow(this.diagramRef.current);
        
        this.editor.editor_mode = 'edit'; // 'view'

        this.editor.start();

        this.editor.on('nodeSelected', this.props.onSelectElement);
        this.editor.on('nodeUnselected', this.props.onSelectElement);  
        this.editor.on('connectionCreated', this.onConnectionCreated);  

        if(pipelineMonitor) {
            this.editor.on('nodeRemoved', this.disableEvent);  
            this.editor.on('removeReroute', this.disableEvent);  
            this.editor.on('connectionCreated', this.disableEvent);  
            this.editor.on('connectionCancel', this.disableEvent);  
            this.editor.on('connectionRemoved', this.disableEvent);  
            this.editor.on('moduleRemoved', this.disableEvent);  
        }

        this.editor.selectNode = (id) => {
            var evt = new MouseEvent('mousedown', {
                view: window,
                bubbles: true,
                cancelable: false,
            });
            var evt2 = new MouseEvent('mouseup', {
                view: window,
                bubbles: true,
                cancelable: false,
            });
            document.querySelector(`#node-${id} .drawflow_content_node`)?.dispatchEvent(evt);
            document.querySelector(`#node-${id} .drawflow_content_node`)?.dispatchEvent(evt2);
        };

        const initialSchema = this.normalizeSchema({ drawflow: graphSchema || { Home: { data: {} }}});
        this.editor.import(initialSchema);
        this.props.onChangeDiagramState(this.editor.export());
    
    }

    @bind
    disableEvent() {
        const { graphSchema }= this.props;
        const initialSchema = this.normalizeSchema({ drawflow: graphSchema || { Home: { data: {} }}});
        this.editor.import(initialSchema);
        return;
    }

    @bind
    onConnectionCreated({ output_id, input_id, output_class, input_class }) {
        const from = this.editor.drawflow.drawflow.Home.data[output_id];
        const to = this.editor.drawflow.drawflow.Home.data[input_id];

        if(get(from, 'outputs.output_1.connections.length', 1) !== 1) {
            this.props.showToastr({ severity: 'warning', detail: `Only one output can be set on a component` });
            this.editor.removeSingleConnection(output_id, input_id, output_class, input_class);
        }

        if(to.data.group === 'module' && get(to, 'inputs.input_1.connections.length', 1) !== 1) {
            this.props.showToastr({ severity: 'warning', detail: `You can only link a module with one input component` });
            this.editor.removeSingleConnection(output_id, input_id, output_class, input_class);
        }

        if(from.data.group === 'connector' && !['module', 'topic'].includes(to.data.group)) {
            this.props.showToastr({ severity: 'warning', detail: `Connector can only be linked to a Module or Topic` });
            this.editor.removeSingleConnection(output_id, input_id, output_class, input_class);
        }

        if(to.data.group === 'connector' && to.data.type !== 'sink' && !['external'].includes(from.data.group)) {
            this.props.showToastr({ severity: 'warning', detail: `Only "Extrernal Data" can be linked to a Connector` });
            this.editor.removeSingleConnection(output_id, input_id, output_class, input_class);
        }
        if(from.data.group === 'module' && (
            !['module', 'topic', 'connector'].includes(to.data.group)
            && to.data.type !== 'sink'
        )) {
            this.props.showToastr({ severity: 'warning', detail: `Module can only be linked to a Module, Topic and Sink Connector` });
            this.editor.removeSingleConnection(output_id, input_id, output_class, input_class);
        }
        if(to.data.group === 'topic' && !['connector', 'module'].includes(from.data.group)) {
            this.props.showToastr({ severity: 'warning', detail: `Topic can only be linked with a Module or Connector` });
            this.editor.removeSingleConnection(output_id, input_id, output_class, input_class);
        }
        if(from.data.group === 'topic' && (to.data.type !== 'sink' && !['module'].includes(to.data.group))) {
            this.props.showToastr({ severity: 'warning', detail: `Topic can only be linked with a Module or Sink Connector` });
            this.editor.removeSingleConnection(output_id, input_id, output_class, input_class);
        }

    }

    @bind 
    getNodeComponent(nodeData) {
        switch(nodeData.group) {
            case 'annotation': {
                return PipelineWidgetAnnotation;
            }
            default: 
        }
        return PipelineWidget;
    }

    @bind
    normalizeSchema(schema, nodeId, nodeData) {
        const { details, pipelineMonitor, pipelineStatus } = this.props;
        const updatedData = Object.entries(schema.drawflow.Home.data).reduce((accum, [id, node]) => {
            const ComponentWidget = this.getNodeComponent(node.data);
            if(Number(id) === Number(nodeId)) {
                accum[id] = {
                    ...node,
                    data: {
                        ...nodeData,
                        id
                    },
                    html: renderToString(
                        <ComponentWidget pipelineStatus={pipelineStatus} pipelineMonitor={pipelineMonitor} details={details} nodeId={id} node={nodeData} />
                    )
                };
            } else if (!nodeId) {
                accum[id] = {
                    ...node,
                    data: {
                        ...node.data,
                        id
                    },
                    html: renderToString(
                        <ComponentWidget pipelineStatus={pipelineStatus} pipelineMonitor={pipelineMonitor} details={details} nodeId={id} node={node.data} />
                    )
                };  
            } else {
                accum[id] = {
                    ...node,
                    data : {
                        ...node.data,
                        id
                    }
                };

            }
            return accum;
        }, {});
        return { drawflow: { Home: { data: updatedData } }};
    }

    @bind
    addNode(nodeData) {
        const { details, pipelineMonitor, pipelineStatus } = this.props;
        const ComponentWidget = this.getNodeComponent(nodeData);
        this.editor.addNode(
            nodeData.id, // name of module
            nodeData.group === 'annotation' || nodeData.disableInputs ? 0: 1, // inputs 
            nodeData.group === 'annotation' || nodeData.disableOutputs ? 0: 1, // outputs
            nodeData.pos_x,
            nodeData.pos_y,
            `PipelineNode ${nodeData.group}`, // class
            nodeData, // data
            renderToString(
                <ComponentWidget pipelineStatus={pipelineStatus} pipelineMonitor={pipelineMonitor} details={details} nodeId={nodeData.id} node={nodeData} />
            ),
            // true // Default false, true for Object HTML, vue for vue
        );
        const updatedSchema = this.normalizeSchema(this.editor.drawflow);
        this.editor.import(updatedSchema);        
    }

    @bind
    updateNode(nodeId, nodeData) {
        const schema = this.editor.export();  
        const updatedSchema = this.normalizeSchema(schema, nodeId, nodeData);
        this.editor.import(updatedSchema);
        // this.editor.selectNode(nodeId);
        this.props.onChangeDiagramState(this.editor.export());
    }

    @bind
    removeNode(nodeId) {
        this.editor.removeNodeId(`node-${nodeId}`);
        this.props.onChangeDiagramState(this.editor.export());
    }

    @bind
    onDrop(data) {
        const id = uuidv4();
        const nodeData = {
            id, subTitle: data.group,
            ...data
        };
        this.props.handleOpenAddComponentModal(nodeData);
    }

    render() {
        // eslint-disable-next-line 
        this.editor && console.log('$$$ [schema', { editor: this.editor.drawflow });
        return (
            <>
                <RowTargetStyled
                    index={1}
                    onDrop={this.onDrop}
                    label={'label'}
                    element={{}}
                />
                <Pipeline id="drawflow" ref={this.diagramRef}  />
                <PipelineContextMenu target={this.state.contextRefTarget} />
            </>
        );
    }
};