/* @flow */

import { gql } from '@apollo/client';

export default gql`
query classificationsByIdsQuery($filterBy: [JSON], $orderBy: [JSON]) {
  records: classes(filterBy: $filterBy, orderBy: $orderBy) {
    id
    name
    formDefinition
    icon
    iconType
  }
}
`;
