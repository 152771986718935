
import { Tile as TileLayer } from 'ol/layer';
import { TileWMS } from 'ol/source';
import uuidv1 from 'uuid/v1';

export const drawWMSLayer = (attributes: Object) => {
    const { _attributes } = attributes;
    const { name, id } = _attributes;
    const layer = _attributes?.['layer'];
    const layer_url = _attributes?.['layer_url'];

    const updatedUrl = layer_url?.split('?')[0];
    const layerToAdd = new TileLayer({
        title: name || uuidv1(),
        opacity: _attributes?.opacity || 1,
        visible: _attributes?.visible,
        attributes: { id, type: 'wms', iconName: 'layers', layerType: 'WMS Layer' },
        source: new TileWMS({
            url: updatedUrl,
            params: {
                LAYERS: layer,
                TILED: true
            },
            crossOrigin: 'anonymous'
        })
    });

    return layerToAdd;
};
