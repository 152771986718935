/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';

import TextEditor from 'app/components/organisms/TextEditor/TextEditor';
import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import { compile, render } from 'app/utils/template/template';


const ContentAreaStyled = styled(ContentArea)`
    height: calc(100vh - 154px);
`;

class PrintTemplateLayoutEditor extends PureComponent<Object, Object> {
    static propTypes: Object = {
        details: PropTypes.object.isRequired,
        uploadImage: PropTypes.func,
        isLoading: PropTypes.bool,
        canEdit: PropTypes.bool,
        fromSidebar: PropTypes.bool,
        imageData: PropTypes.object,
        userProfile: PropTypes.object,
        reloadList: PropTypes.func
    };

    parseTemplate(text, context) {
        try {
            return render(compile(text), context);
        } catch (err) {
            return err;
        }
    }

    render(): Object {
        const { text, handleOnChangeText } = this.props;
        return (
            <ContentAreaStyled >
                <TextEditor
                    value={text}
                    onChange={handleOnChangeText}
                    plainTextEditor
                    MenuBarProps={{
                        backgroundColor: 'transparent',
                    }}
                    EditorProps={{
                        backgroundColor: 'transparent',
                    }}
                    // useCodeEditor
                    // mode="xml"
                />
            </ContentAreaStyled>
        );
    }
}


export default withTheme(PrintTemplateLayoutEditor);
