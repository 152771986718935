/* @flow */

import { gql } from '@apollo/client';

export default gql`
mutation updateBroadcastMutation($record: BroadcastUpdateInput!) {
    result: updateBroadcast(record: $record) {
      id
    }
}
`;
