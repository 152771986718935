/* @flow */

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Grid, TextField } from '@mic3/platform-ui';
import { parseDuration, durationInMillis } from 'app/utils/date/date';
import { isDefined, isObject } from 'app/utils/utils';

const Integer = ({ value, onChange, min, max, show, ...TextFieldProps }) => {
    const [text, setText] = useState(null);

    useEffect(() => {
        setText(value);
    }, [value]);

    const { name } = TextFieldProps;
    const onTextChange = ({ target: { value, type }}) => {
        let units = value;
        if (isDefined(units)) {
            units = units.replace(/[^\d]/g, '').trim();
        }
        units = (isDefined(units) && units !== '') ? Math.trunc(Number(units)) : null;
        if (isDefined(units)) {
            if (isDefined(min)) {
                units = Math.max(units, min);
            }
            if (isDefined(max)) {
                units = Math.min(units, max);
            }
            setText(String(units));
        } else {
            setText(null);
        }
        onChange({ target: { name, value: units, type }});
    };
    return show && (
        <TextField
            {...TextFieldProps}
            value={text}
            onChange={onTextChange}
            type="number"
        />
    );
};

const IntegerStyled = styled(Integer)`
padding-right: 4px !important;
&:last-child {
  padding-right: 0px !important;
}
`;

const DurationField = ({
    name, value, onChange, onMouseDown, hideDays, hideHours, hideMinutes, hideSeconds, hideMilliseconds, disabled
}) => {

    const onUnitChange = (event) => {
        const { target: { name: field, value: units }} = event;
        const duration = parseDuration((isObject(value) ? value?.value : value) || 0);
        duration[field] = units;
        onChange({ target: { name, value: {
            isDuration: true,
            value: durationInMillis(duration)
        } } });
    };

    const { days, hours, minutes, seconds, milliseconds } = isObject(value) ? parseDuration(value?.value) : parseDuration(value);

    const commonProps = {
        disabled,
        clearable: false,
        onChange: onUnitChange,
        onMouseDown,
        min: 0,
        InputProps:{ disableUnderline: true },
    };

    return (
        <Grid container wrap="nowrap">
            <IntegerStyled
                show={!hideDays}
                name="days"
                value={days}
                label="days"
                {...commonProps}
            />
            <IntegerStyled
                show={!hideHours}
                name="hours"
                value={hours}
                label="hours"
                {...commonProps}
                max={23}
            />
            <IntegerStyled
                show={!hideMinutes}
                name="minutes"
                value={minutes}
                label="minutes"
                {...commonProps}
                max={59}
            />
            <IntegerStyled
                show={!hideSeconds}
                name="seconds"
                value={seconds}
                label="seconds"
                {...commonProps}
                max={59}
            />
            <IntegerStyled
                show={!hideMilliseconds}
                name="milliseconds"
                value={milliseconds}
                label="ms"
                {...commonProps}
                max={999}
            />
        </Grid>
    );
};

export default DurationField;
