/* @flow */

import React, { useState, useCallback } from 'react';
import { Button, List, ListItem, ListItemText, ListItemSecondaryAction } from '@mic3/platform-ui';
import { useToggle } from 'app/utils/hook/hooks';
import ModalFormGenearator from 'app/components/organisms/ModalFormGenearator/ModalFormGenearator';
import DotMenu from 'app/components/molecules/DotMenu/DotMenu';


const ArrayStringEditor = ({
    value, onChange, name, noDuplicates, disabled
}: Object) => {
    const [formState, setFormState] = useState({});
    const [openAddModal, toggleAddModal] = useToggle();

    const handleOnChange = useCallback(({ target: { value: data }}) => {
        let newValue = [...(value || []), data.value];
        if(data.prevValue) {
            newValue = [...(value || []).filter(val => val !== data.prevValue), data.value];
        }
        if(noDuplicates) {
            newValue = [...(new Set(newValue))];
        }
        onChange({ target: { value: newValue, name }});
    }, [onChange, name, value, noDuplicates]);

    const handleToggleAddModal = useCallback((val) => {
        setFormState({ value: val || '', prevValue: val });
        toggleAddModal();
    }, [setFormState, toggleAddModal]);

    const handleDelete = useCallback((deletedVal) => {
        onChange({ target: { value: (value || []).filter(val => val !== deletedVal), name }});
    }, [value, onChange, name]);

    return (
        <>
            <br />
            <Button variant="text" disabled={!!disabled} onClick={() => handleToggleAddModal()}>+ Add new</Button>
            <List>
                {(value || []).map(val => (
                    <ListItem key={val} dense>
                        <ListItemText primary={val} />
                        <ListItemSecondaryAction>
                            <DotMenu
                                items={[
                                    { 
                                        name: 'Edit', 
                                        icon: 'pencil', 
                                        onItemClick: () => {handleToggleAddModal(val);} 
                                    },
                                    { 
                                        withConfirmation: true, 
                                        confirmationModalProps: {
                                            message: 'Are you sure you want to delete?',
                                            declineButtonText: 'No',
                                            confirmButtonText: 'Yes',
                                        },
                                        name: 'Delete', 
                                        icon: 'delete', 
                                        onItemClick: () => {handleDelete(val);} 
                                    },
                                ]}
                            />          
                        </ListItemSecondaryAction>             
                    </ListItem>
                ))}
            </List>
            {openAddModal && <ModalFormGenearator
                withoutPreview
                isOpen
                onClose={toggleAddModal}
                value={formState}
                onChange={handleOnChange}
                components={[
                    {
                        type: 'text',
                        properties: {
                            label: 'Value',
                            name: 'value',
                        },
                    },
                ]}
            />}
        </>
    );
};

export default ArrayStringEditor;
