/* @flow */
import integration from 'app/config/integrationConfig';

const integrationMenus = (isAdmin, permissions, integrations) => {
    const permissionsSet = new Set(permissions);
    const canSee = isAdmin || permissionsSet.has(integration.permissions.view);
    if (!canSee || !integrations?.length) {
        return [];
    }
    return integrations.map(({ id, name, iconName, iconType = 'mdi' }) => ({
        name,
        icon: iconName || integration.getMenuIcon(name),
        to: `${integration.baseRoute}/${id}`,
        iconType
    })).filter(Boolean);
};

export default integrationMenus;
