import React, { PureComponent, Fragment, useRef, useCallback } from 'react';
import { Menu, MenuItem, MdiIcon, IconButton, Button, CircularProgress, FormHelperText, FormControl, Tooltip } from '@mic3/platform-ui';

import { connect } from 'react-redux';

import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import styled from 'styled-components';
import Icon from 'app/components/atoms/Icon/Icon';
import { useToggle } from 'app/utils/hook/hooks';
import { updateEntityFileAttribute, deleteEntityFileAttribute } from 'store/actions/entities/entitiesActions';
import { showToastr } from 'store/actions/app/appActions';
import { isObject } from 'app/utils/utils';

const StyledIcon = styled(Icon)`
    padding-right: 1rem;
    vertical-align: middle;
`;

const kilobytesToBytes = (megabytes) => megabytes * 1024;

const getFileExtension = (fileName) => {
    const lastDotIndex = fileName.lastIndexOf('.');
    if (lastDotIndex === -1) {
        return null; // No extension found
    }
    const extension = fileName.substring(lastDotIndex + 1);
    return `.${extension.toLowerCase()}`; // Return the extension in lowercase
};

const formatFileSize = (bytes) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
};

const textStyle = `
    display: inline-block;
    width: 100%;
    text-overflow: ellipsis !important;
    white-space: nowrap;
    overflow: hidden;
    padding-right: 25px;
`;

const UploadingText = styled.div`
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    text-align: left;
    color: ${({theme})=> theme.material.colors.text.secondary};
    margin-left: 1.5rem;
    margin-top: 4px;
`;
export const ListItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
`;
const Container = styled.div`
    margin: 1rem 0;
`;

const Details = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
    width: 100%;

`;

export const Name = styled.div`
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.15000000596046448px;
    text-align: left;
    color: ${({theme})=> theme.material.colors.text.primary};
    ${textStyle}
`;

export const Description = styled.div`
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4000000059604645px;
    text-align: left;
    color: ${({theme})=> theme.material.colors.text.secondary};
    ${textStyle}
`;

const ThreeDotMenu = (props) => {
    const { onDelete, multiple, onUpload, onDownload, disabled } = props;
    const anchorMenuIcon = useRef(null);
    const [isMenuOpen, toggleMenu] = useToggle();
    const handleAction = useCallback(
        (action) => {
            toggleMenu();
            switch (action) {
                case 'upload':
                    return onUpload();
                case 'download':
                    return onDownload();
                case 'delete':
                    return onDelete();
                default:
                    break;
            }
        },
        [onDelete, onUpload, onDownload, toggleMenu]
    );

    return (
        <Fragment>
            <IconButton buttonRef={anchorMenuIcon} onClick={toggleMenu} edge='end'>
                <MdiIcon name='dots-vertical' />
            </IconButton>
            <Menu open={isMenuOpen} anchorEl={anchorMenuIcon.current} onClose={toggleMenu}>
                {(!multiple && !disabled ) ? (
                    <MenuItem onClick={() => handleAction('upload')}>
                        <StyledIcon name='upload' /> Upload
                    </MenuItem>
                ) : null}
                <MenuItem onClick={() => handleAction('download')}>
                    <StyledIcon name='download' /> Download
                </MenuItem>
                {!disabled && <MenuItem onClick={() => handleAction('delete')}>
                    <StyledIcon name='delete' /> Delete
                </MenuItem>}
            </Menu>
        </Fragment>
    );
};

class UploadFileField extends PureComponent {
    state = {
        isLoading: false,
    };

    inputRef = React.createRef();

    @bind
    async uploadFile(event) {
        const { name: attributeName, entityId: id, entityType: type, isPrimary, maxFileSize, 
            acceptedExtensions, multiple, internal } = this.props;
        const file = event.target.files[0];
        if (!id || !type || !attributeName || !file) return;
        let invalidFile = false;
        if (maxFileSize && file?.size > kilobytesToBytes(maxFileSize)) {
            invalidFile = true;
            this.props.showToastr({
                severity: 'error',
                detail: `File upload failed due to size exceeds ${maxFileSize} KB`,
            });
        }
        if (acceptedExtensions?.length && !acceptedExtensions.includes(getFileExtension(file.name))) {
            invalidFile = true;
            this.props.showToastr({
                severity: 'error',
                detail: `Invalid file, you are allowed to upload a file of types ${acceptedExtensions.join(' ')}`,
            });
        }
        if (invalidFile){
            this.resetFileInput();
            return;
        }
        this.setState({ isLoading: true });
        const value = this.getValue(this.props.value);
        if (value?.length && !multiple) {
            // If user is restricted to upload single file and user has already uploaded a file or multiple files then
            // we need to first delete those uploaded files and then we will upload a new one
            const result = await Promise.all(value.map((val, i) => this.onDelete(i, false)));
            const error = !!result.find((r) => r instanceof Error); // If a single mutation got failed we will not allow user to add a new file
            if (error) return this.setState({ isLoading: false });
        }
        await this.props.updateEntityFileAttribute({
            id,
            type,
            attributeName,
            isPrimary,
            file,
            internal
        });
        this.setState({ isLoading: false });
        this.resetFileInput();
    }

    @bind
    resetFileInput(){
        try {
            this.inputRef.current.value = '';
        } catch (error) {
            console.error('Error while resetting file input : ', error); // eslint-disable-line
        }
    }

    @bind
    openFileDialog() {
        const { preview } = this.props;
        !preview && this.inputRef?.current && this.inputRef.current.click();
    }

    @bind
    async onDelete(index = 0, refresh = true) {
        const { name: attributeName, entityId: id, entityType: type, isPrimary, internal } = this.props;
        const value = (this.getValue(this.props.value)).slice().reverse();
        const fileId = value && value[index]?.fileId;
        if (!fileId) {
            return Promise.resolve();
        }
        return this.props.deleteEntityFileAttribute(
            {
                id,
                type,
                attributeName,
                isPrimary,
                fileId,
                internal
            },
            refresh
        );
    }

    @bind
    onDownload(index) {
        const { name, entityId: id, entityType: type, isPrimary } = this.props;
        const value = (this.getValue(this.props.value)).slice().reverse();
        const fileId = value && value[index]?.fileId;
        if (!fileId) return;
        try {
            window.open(
                `${window.location.origin}/media/entity/${type}/${id}/${isPrimary ? 'primary' : 'attributes'}/${encodeURIComponent(
                    name
                )}/${fileId}`
            );
        } catch (error) {
            console.log('An Error occured while downloading the file : ', e); // eslint-disable-line
        }
    }

    @bind
    @memoize()
    getUserData(userId, userReferences) {
        return userReferences?.find(({ id }) => id === userId);
    }

    @bind
    @memoize()
    getValue(value) {
        return isObject(value) ? [value] : value;
    }

    render() {
        const { multiple, error, required, acceptedExtensions, maxFiles, userReferences, entityId, entityType, disabled, preview } = this.props;
        if(!preview && (!entityId || !entityType || entityType === 'workspace')) return null;
        const { isLoading } = this.state;
        const value = this.getValue(this.props.value);
        return (
            <Container>
                <div>
                    <input
                        type='file'
                        ref={this.inputRef}
                        style={{ display: 'none' }}
                        accept={acceptedExtensions}
                        onChange={this.uploadFile}
                    />
                    {(multiple && !maxFiles) || (multiple && value && maxFiles && value.length < maxFiles) || !value?.length ? (
                        <Button
                            startIcon={<Icon size='sm' name='upload' hexColor={isLoading ? '#666': '#7391D' } />}
                            size='small'
                            variant='text'
                            color='primary'
                            component='span'
                            onClick={this.openFileDialog}
                            disabled={isLoading || disabled}
                        >
                            Upload
                        </Button>
                    ) : null}

                    {value?.length && value.slice().reverse().map((f, index) => {
                        const user = this.getUserData(f?.uploadedBy, userReferences);
                        const fileName = f?.fileName;
                        const description = `${formatFileSize(f?.fileSize)} - Uploaded by ${user?.name} at ${new Date(
                            f?.uploadedDate
                        )?.toDateString()}`;
                        return (
                            <ListItem key={`${fileName}-${f?.fileSize}-${f?.uploadedDate}`}>
                                <div style={{ display: 'flex', width: '90%' }}>
                                    <Icon name='file' hexColor='#DADADA' />
                                    <Details>
                                        <Tooltip title={fileName} placement='top-start'>
                                            <Name>{fileName}</Name>
                                        </Tooltip>
                                        <Tooltip title={description} placement='top-start'>
                                            <Description>{description}</Description>
                                        </Tooltip>
                                    </Details>
                                </div>
                                <ThreeDotMenu
                                    multiple={multiple}
                                    onDelete={() => this.onDelete(index)}
                                    onUpload={this.openFileDialog}
                                    onDownload={() => this.onDownload(index)}
                                    disabled={disabled}
                                />
                            </ListItem>
                        );
                    })}
                    {isLoading ? (
                        <ListItem style={{ justifyContent: 'start' }}>
                            <CircularProgress size={18} color='secondary' />
                            <UploadingText>Uploading file...</UploadingText>
                        </ListItem>
                    ) : null}
                </div>
                {error && required && !value?.length && (
                    <FormControl error={error}>
                        <FormHelperText>Field is required</FormHelperText>
                    </FormControl>
                )}
            </Container>
        );
    }
}

export default connect(
    (state, ownProps) => {
        let { acceptedExtensions } = ownProps;
        if (acceptedExtensions?.length) {
            acceptedExtensions = acceptedExtensions.map(v => !v?.includes('.') ? `.${v?.toLowerCase()}` : v?.toLowerCase()); // Normalizing extensions data
        }
        return ({
            userReferences: state.admin.users.references.data,
            acceptedExtensions
        });
    },
    {
        updateEntityFileAttribute,
        deleteEntityFileAttribute,
        showToastr,
    }
)(UploadFileField);
