/* @flow */

import { DISABLE_MAP_SEARCH, ENABLE_MAP_SEARCH } from 'store/actions/maps/situationalAwarenessActions';
import Immutable from 'app/utils/immutable/Immutable';

/**
 * The initial state on our reducer
 */
const initialState = {
    isDisabled: false
};

/**
 * @param state - the current Redux state
 * @param action - the dispatched action
 * @returns the new Redux state
 */
const reducer = (state: Object = Immutable(initialState), action: Object) => {
    switch (action.type) {
        case DISABLE_MAP_SEARCH:
            return Immutable({ ...state, isDisabled: true });
        case ENABLE_MAP_SEARCH:
            return Immutable({ ...state, isDisabled: false });
        default:
            return state;
    }
};

export default reducer;
