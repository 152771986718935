/* @flow */

import { gql } from '@apollo/client';

export default gql`
mutation publishFormDefinitionMutation($id: ID!){
  result: publishFormDefinition(id: $id) {
    id
  }
}
`;
