/* @flow */

import Immutable from 'app/utils/immutable/Immutable';
import { dataTableReducer, keepDataAtStart, loadDataReducer } from 'app/utils/redux/reducer-utils';
import {
    LOAD_COLUMNS_TASKS_STARTED,
    LOAD_COLUMNS_TASKS,
    LOAD_BOARD_COLUMNS_STARTED,
    LOAD_BOARD_COLUMNS,
    LOAD_ALL_COLUMNS_STARTED,
    LOAD_ALL_COLUMNS,
    SET_SELECTED_BOARD,
    COLUMN_ORDER_POSITION,
    LOAD_BOARD_PRIMARY_CLASS_STARTED,
    LOAD_BOARD_PRIMARY_CLASS,
    LOAD_BOARD_RECORDS_STARTED, LOAD_BOARD_RECORDS,
    LOAD_BOARD_RECORDS_ALL_STARTED, LOAD_BOARD_RECORDS_ALL
} from 'store/actions/abox/boardActions';
import { combineReducers } from 'redux';
import { isEmpty } from 'app/utils/utils';
import { get } from 'app/utils/lo/lo';

const initialState = {
    boardColumns: {
        records: [],
        isReordered: false,
        isAdded: false,
        isRemoved: false,
        isLoading: false
    },
    selectedBoard: null
};

const boardsReducerMain = (state: Object = initialState,action: Object) => {
    const { type, payload } = action || {};
    switch (type) {
        case SET_SELECTED_BOARD:
            return Immutable({ ...state, selectedBoard: payload });

        case LOAD_BOARD_COLUMNS_STARTED:
            return Immutable({ ...state, boardColumns: {
                ...state.boardColumns,
                isReordered: false,
                isRemoved: false,
                isAdded: false,
                isLoading: true
            }});

        case LOAD_BOARD_COLUMNS:
            const parsedPayload = {
                ...state.boardColumns,
                ...payload,
                records: !payload.records ? [] : payload.records.map((record, index) => {
                    const attributes = get(record, 'relation.attributes');
                    const order = !isEmpty(attributes) ? attributes['board-board-label/order'] : index + 1;
                    return {
                        relationId: record.relation.id,
                        isAdded: false,
                        order: order,
                        ...record.relatedEntity
                    };
                }).sort((a, b) => a.order - b.order)
            };
            return Immutable({ ...state, boardColumns: { ...parsedPayload, isLoading: false } });

        case COLUMN_ORDER_POSITION:
            return Immutable({
                ...state,
                boardColumns: {
                    ...state.boardColumns,
                    isReordered: true,
                    isAdded: false,
                    isRemoved: false,
                    records: payload
                }
            });
        default:
            return state;
    }
};

const taskReducer = combineReducers<Object, Object>({
    boardRecords: dataTableReducer(LOAD_BOARD_RECORDS_STARTED, LOAD_BOARD_RECORDS, keepDataAtStart),
    boardRecordsAll: dataTableReducer(LOAD_BOARD_RECORDS_ALL_STARTED, LOAD_BOARD_RECORDS_ALL, keepDataAtStart),
    boardsOption: boardsReducerMain,
    primaryClass: loadDataReducer(LOAD_BOARD_PRIMARY_CLASS_STARTED, LOAD_BOARD_PRIMARY_CLASS, keepDataAtStart),
    tasks: dataTableReducer(LOAD_COLUMNS_TASKS_STARTED, LOAD_COLUMNS_TASKS, keepDataAtStart),
    //columns: dataTableReducer(LOAD_BOARD_COLUMNS_STARTED, LOAD_BOARD_COLUMNS, keepDataAtStart),
    allColumns: dataTableReducer(LOAD_ALL_COLUMNS_STARTED, LOAD_ALL_COLUMNS, keepDataAtStart),
    boardsOption: boardsReducerMain
    //primaryClass: loadDataReducer(LOAD_BOARD_PRIMARY_CLASS_STARTED, LOAD_BOARD_PRIMARY_CLASS, keepDataAtStart),
});
export default taskReducer;
