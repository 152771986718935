/* @flow */

import { baseRoutes } from 'app/config/routesConfig';

const mapsList =  () => [
    {
        name: 'Situational Awareness',
        icon: 'situational-awareness',
        iconType: 'af',
        to: baseRoutes.map
    }

];

export default mapsList;
