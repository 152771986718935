/* @flow */
import { combineReducers } from 'redux';

import { dataTableReducer, keepDataAtStart } from 'app/utils/redux/reducer-utils';
import {
    LOAD_TASKS_ASSIGNED_STARTED, LOAD_TASKS_ASSIGNED,
    LOAD_TASKS_BY_MODIFIED_DATE_STARTED, LOAD_TASKS_BY_MODIFIED_DATE,
    LOAD_TASKS_BY_PRIORITY_STARTED, LOAD_TASKS_BY_PRIORITY,
    LOAD_TASKS_BY_DUE_DATE_STARTED, LOAD_TASKS_BY_DUE_DATE,
    LOAD_MAPS_BY_MODIFIED_DATE_STARTED,
    LOAD_MAPS_BY_MODIFIED_DATE,
    LOAD_LAST_USED_PROCESSES,
    LOAD_LAST_USED_PROCESSES_STARTED,
} from 'store/actions/dashboard/dashboardActions';

const initialState = {
    isLoading: false,
    records: []
};

const lastUsedProcess = (state=initialState, action) => {
    switch (action.type) {
        case LOAD_LAST_USED_PROCESSES_STARTED: 
            return { ...state, isLoading: true };
        case LOAD_LAST_USED_PROCESSES: {
            if(action?.error) return { ...state, isLoading: false };
            return {...state, records: action.payload, isLoading: false};
        }
        default:
            return state;
    }
};

export default combineReducers<Object, Object>({
    tasksAssigned: dataTableReducer(LOAD_TASKS_ASSIGNED_STARTED, LOAD_TASKS_ASSIGNED, keepDataAtStart),
    tasksByModifiedDate: dataTableReducer(LOAD_TASKS_BY_MODIFIED_DATE_STARTED, LOAD_TASKS_BY_MODIFIED_DATE, keepDataAtStart),
    tasksByPriority: dataTableReducer(LOAD_TASKS_BY_PRIORITY_STARTED, LOAD_TASKS_BY_PRIORITY, keepDataAtStart),
    tasksByDueDate: dataTableReducer(LOAD_TASKS_BY_DUE_DATE_STARTED, LOAD_TASKS_BY_DUE_DATE, keepDataAtStart),
    maps: dataTableReducer(LOAD_MAPS_BY_MODIFIED_DATE_STARTED, LOAD_MAPS_BY_MODIFIED_DATE, keepDataAtStart),
    lastUsedProcesses: lastUsedProcess,
});
