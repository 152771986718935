import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled, { withTheme } from 'styled-components';
import { muiTheme } from 'app/themes/materialUi';

import { bind } from 'app/utils/decorators/decoratorUtils';
import ListItem from 'app/components/molecules/List/ListItem';
import ListItemPins from 'app/components/molecules/Map/LayerList/ListItemPins';
import Icon from 'app/components/atoms/Icon/Icon';
import { isDefined, isEmpty } from 'app/utils/utils';
import { Divider, Tooltip, CircularProgress } from '@mic3/platform-ui';

import { getPermissions } from 'app/config/rolesConfig';
import {
    setSelectedLayer,
    refreshMapSideBarLayers,
    removeCreatePinInteraction,
} from 'store/actions/maps/situationalAwarenessActions';
import cesiumLayerConfig from 'app/config/cesiumLayerConfig';

const StyledExpansionIcon = styled(Icon)`
    margin-top: -0.5rem;
    height: 1rem;
`;

const ListItemContainer = styled.div`
    background: ${({ theme }) => theme.material.colors.background.paper};
    .LayerListItem > div[class^='ItemRow__'] {
        flex-wrap: wrap;
        span {
            flex: 1;
        }
    }
    margin: 1rem 0;
`;

const StyledListItem = styled(ListItem)`
    ${({ theme, selected }) => selected && `background-color: ${theme.material.colors.itemActive} !important;`}
`;

const StyledTitle = styled.span`
    width: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${({theme})=> theme.material.colors.text.primary};
`;
class LayerListItem extends PureComponent {
    layerId = null;

    @bind
    renderLayerIcon(props: Object) {
        const { attributes, noAccess, isLayerLoading, visible } = props;
        const { item } = this.props;
        if (isEmpty(attributes) && item?.type !== 'system_cesium_layer') return null;
        const { iconName, iconType } = isEmpty(attributes) ? item : attributes || {};
        if (isLayerLoading && visible) return <CircularProgress size={24} color='primary' />;
        return (
            <Icon
                name={noAccess ? 'information-outline' : iconName}
                hexColor={noAccess ? '#FF8A91' : ''}
                type={noAccess ? 'mdi' : iconType || 'mdi'}
            />
        );
    }

    @bind
    setLayerSelected(type: string, title: string, visible: boolean, previousLayer: Object) {
        const { map, selectedLayer, item } = this.props;
        const id = item?.id;
        const prevLayer = previousLayer && map.findLayerById(previousLayer?.id);
        if (prevLayer) prevLayer.setProperties({ selectedLayer: false });

        const layer = id ? map.findLayerById(id) : null;
        if (!layer || !id) return;
        const { classData } = layer.values_?.attributes || {};
        const { uri: subType, abstract } = classData || {};

        this.props.removeCreatePinInteraction();

        const isSelected = selectedLayer?.id === id;
        const highlightLayer = map.findHighLightLayer();

        switch (type) {
            case 'Entity Layer':
            case 'Event Layer':
                if (isSelected) {
                    this.props.setSelectedLayer(null);
                    map.removeHightLightFeatures();
                    layer.setProperties({ selectedLayer: false });
                } else {
                    this.props.setSelectedLayer({ type, id, subType, abstract });
                    map.highLightFeatures(id);
                    const layerOpacity = layer.getOpacity();
                    layer.setProperties({ selectedLayer: true });
                    if (isDefined(layerOpacity) && highlightLayer) {
                        highlightLayer.setOpacity(layerOpacity);
                    }
                }
                this.layerId = selectedLayer?.id;
                break;

            case 'WMS Layer':
            case 'Drawing Layer':
            case 'Replay Layer':
                layer.setProperties({ selectedLayer: false });
                map.removeHightLightFeatures();
                this.props.setSelectedLayer(isSelected ? null : { type, id });
                break;

            default:
                return;
        }
    }

    render() {
        const { item, map, handleListItemExpansion, actions, selectedLayer, isLayerLoading, mapId, features: layerFeatures } = this.props;
        const { name: title, expanded, noAccess, role, visible } = item || {};
        const { canEdit } = getPermissions(role);
        const mapLayer = map.findLayerById(item?.id); // We can not use findLayerByTitle because when layer name would be changed it would break
        const attributes = (mapLayer && mapLayer.get('attributes')) || {};
        const { id, layerType, type, subtitle } = attributes || {};

        const features = layerFeatures;
        const previousLayer = selectedLayer || '';
        const { id: selectedId, featureIndex } = selectedLayer || item || {};
        const isSelected = selectedLayer && selectedId === id && !isDefined(featureIndex);
        const disabled =
            (type === cesiumLayerConfig?.cesiumLayer?.oldType || type === cesiumLayerConfig?.cesiumLayer?.type) && !map.get3DVisibility();
        let subTitle = '';
        if (visible) {
            subTitle = features?.length ? `${features.length} ${subtitle || ''}` : '';
        }
        if (noAccess) {
            subTitle = 'Error! Cesium Ion account not found, or no access permission.';
        }

        return (
            <>
                <ListItemContainer>
                    <StyledListItem
                        selected={isSelected}
                        disabled={disabled || !!noAccess}
                        onClick={() => this.setLayerSelected(layerType, title, visible, previousLayer)}
                        className={'LayerListItem'}
                        component={this.renderLayerIcon({ attributes, noAccess, isLayerLoading, visible })}
                        title={
                            <div style={{ display: 'flex' }}>
                                <StyledTitle title={title}>{title || 'No Title'}</StyledTitle>
                                {visible && features?.length > 0 ? (
                                    <Tooltip arrow title={`Click to ${expanded ? 'un-expand' : 'expand'} layer`}>
                                        <span>
                                            <StyledExpansionIcon
                                                name={expanded ? 'chevron-up' : 'chevron-down'}
                                                onClick={(event) => handleListItemExpansion(event, 'allLayers', title)}
                                            />
                                        </span>
                                    </Tooltip>
                                ) : null}
                            </div>
                        }
                        subTitle={
                            <div style={{ whiteSpace: 'normal', color: noAccess ? '#FF8A91' : muiTheme.colors.text.secondary , width: '185px' }}>
                                {' '}
                                {isLayerLoading && visible ? 'Loading...' : subTitle || ''}
                            </div>
                        }
                        actions={actions(mapLayer, item, canEdit)}
                    />
                    {visible ? (
                        <ListItemPins
                            map={map}
                            layerId={id}
                            itemId={item?.id}
                            expanded={expanded}
                            features={features}
                            layerType={layerType}
                            mapId={mapId}
                        />
                    ) : null}
                </ListItemContainer>
                <Divider />
            </>
        );
    }
}

export default connect(
    (state, ownProps) => ({
        selectedLayer: state.maps.situationalAwareness.layer.selectedLayer,
        mapData: state.maps.situationalAwareness.map.data,
        hasDrawing: state.maps.situationalAwareness.drawing.hasDrawing,
        refreshLayerSwitcher: state.maps.situationalAwareness.layer.refreshLayerSwitcher,
        showAddPinModal: state.maps.situationalAwareness.layer.showAddPinModal,
        isLayerLoading: state.maps.situationalAwareness.map.featuresLoading[ownProps.item?.id] || false,
    }),
    {
        setSelectedLayer,
        refreshMapSideBarLayers,
        removeCreatePinInteraction,
    }
)(withTheme(LayerListItem));
