/* @flow */

import React from 'react';
import PropTypes from 'prop-types';
import { baseRoutes } from 'app/config/routesConfig';

import Link from 'app/components/atoms/Link/Link';

const ProcessDefinitionLink = ((props: Object) => {
    const { id, children, path, ...restProps } = props;
    const tab = path ? `/${path}` : '';
    return <Link to={`${baseRoutes['processdefinition']}/${id}${tab}`} {...restProps}>{children}</Link>;
});

ProcessDefinitionLink.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

export default ProcessDefinitionLink;
