/* @flow */

import Immutable from 'app/utils/immutable/Immutable';

import {
    CHAT_SOCKET,
    INIT_CHAT_SOCKET,
    SUB_STREAM_ROOM_MESSAGES,
    UNSUB_STREAM_ROOM_MESSAGES,
} from 'store/actions/chat/chatActions';

const initialState = {
    isReady: false,
    isFailed: false,
    isClosed: false,
    streamRoomsMessages: {}
};

export default (state: Object = initialState, action: Function) => {
    const { type, error, meta, payload } = action;

    switch (type) {
        case INIT_CHAT_SOCKET:
            if (error) {
                return Immutable({ ...state, isFailed: true });
            }
            return Immutable({ ...state, isReady: true, isClosed: false, isFailed: false });
        case CHAT_SOCKET:
            if (payload?.error) {
                return Immutable({ ...state, isClosed: true });
            }
            return state;
        case SUB_STREAM_ROOM_MESSAGES:
            if (error) {
                return Immutable({ ...state });
            }
            if (meta.rid && meta.id) {
                return Immutable({ ...state, streamRoomsMessages: {...state.streamRoomsMessages, [`${meta.rid}`]: meta } });
            }
            return state;
        case UNSUB_STREAM_ROOM_MESSAGES:
            if (error) {
                return Immutable({ ...state });
            }
            const { rid } = meta;
            const streamRooms = {...state.streamRoomsMessages};
            if (rid && streamRooms.hasOwnProperty(rid)) {
                const { [rid]: remove, ...otherProps } = streamRooms;
                return Immutable({ ...state, streamRoomsMessages: otherProps });
            }
            return state;
        default:
            return state;
    }
};
