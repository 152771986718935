// @flow

export default {
    cesiumLayer : {
        classId: '044bf8c8-6b12-11ed-b068-3f185bf3c28a',
        name: 'Cesium Ion Layer',
        type: 'system_cesium_layer',
        oldType: 'cesium-layer',
        accountAttr: 'account',
        oldAccAttr: 'cesium-layer/cesium-account',
        assetAttr: 'asset',
        oldAssetAttr: 'cesium-layer/cesium-asset',
        getAccount: data => data?.account,
        getOldAccount: data => data?.['cesium-layer/cesium-account'],
        getAsset: data => data?.asset,
        getOldAsset: data => data?.['cesium-layer/cesium-asset'],
        getCesiumAccount(data) {
            if(!data) {
                return;
            }
            if(data.type === 'cesium-layer') {
                return data?.account;
            } else {
                return  data?.['system_cesium_layer/account'];
            }
        },
        isCesiumLayer(layerType) {
            if(!layerType) {
                return;
            }
            return layerType === 'system_cesium_layer' || layerType ===  'cesium-layer';
        },
        isCesiumAccount(accAttr) {
            if(!accAttr) {
                return;
            }
            return accAttr === 'system_cesium_layer/account' || accAttr === 'cesium-layer/cesium-account';
        }
    },
    cesiumAccount: {
        classId: '7ef09a00-6a3d-11ed-acad-876216edfd91',
        name: 'Cesium Ion Account',
        type: 'system_cesium_account',
        oldType: 'cesium-account',
        getCesiumToken: data => data?.primary?.['system_cesium_account/token'],
        getOldCesiumToken: data => data?.primary?.['cesium-account/cesium-token'],
        getToken(data) {
            if(!data) {
                return;
            }
            if(data?.type === 'cesium-layer') {
                return data?.primary?.['cesium-account/cesium-token'];
            } else {
                return  data?.primary?.['system_cesium_account/token'];
            }
        }
    }
};
