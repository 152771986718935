
import { gql } from '@apollo/client';

export default gql`
query teamHistoryQuery($orderBy: [JSON], $filterBy: [JSON], $excludeBy: [JSON], $startIndex: Int, $stopIndex: Int) {
    count: count(dataType: "teamChangelog", filterBy: $filterBy, excludeBy: $excludeBy)
    records: teamChangelogs(startIndex: $startIndex, stopIndex: $stopIndex, orderBy: $orderBy, filterBy: $filterBy, excludeBy: $excludeBy) {
        id
        team {
            id
            name
        }
        change
        user {
            id
            name
            image
        }
        date
    }
}
`;
