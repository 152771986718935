/* @flow */

import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Grid, IconButton, MdiIcon } from '@mic3/platform-ui';

const EditableText = (props: Object) => {
    const {
        onSave, EditComponent, EditComponentProps, displayValue, name, value
    } = props;
    const [isEditing, setEditing] = useState(false);
    const [nextValue, setValue] = useState(value);

    const onChange = useCallback((event) => {
        setValue(event.target.value);
    }, [setValue]);

    const open = useCallback(() => setEditing(true), [setEditing]);
    const close = useCallback(() => setEditing(false), [setEditing]);

    const save = useCallback(() => {
        close();
        return onSave({ target: { name, value: nextValue } });
    }, [close, name, nextValue, onSave]);


    return isEditing
        ? (
            <Grid container wrap="nowrap">
                <EditComponent {...EditComponentProps} name={name} value={nextValue} onChange={onChange} />
                <IconButton size="small" onClick={save}>
                    <MdiIcon size={20} name="content-save" />
                </IconButton>
                <IconButton size="small" onClick={close}>
                    <MdiIcon size={20} name="close" />
                </IconButton>
            </Grid>
        )
        : (
            <div onClick={open}>
                {displayValue || value} <MdiIcon size={14} name="pencil" />
            </div>
        );
};

EditableText.propTypes = {
    displayValue: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.any,
    EditComponent: PropTypes.elementType.isRequired,
    EditComponentProps: PropTypes.object,
    onSave: PropTypes.func.isRequired,
};

export default EditableText;
