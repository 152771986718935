/* @flow */

// $FlowFixMe
import React, { PureComponent } from 'react';
import { Paper, Typography, Dialog, DialogTitle, DialogActions, DialogContent, Button } from '@mic3/platform-ui';
import memoizeOne from 'memoize-one';
import styled from 'styled-components';

import { getType } from 'app/utils/utils';
import Forest from 'app/utils/dataStructure/Forest';
import uuidv1 from 'uuid/v1';
import OrderBuilderDnd from './OrderBuilderDnd';
import DragNDropContext from 'app/containers/DragNDropContext/DragNDropContext';

const TypographyPadded = styled(Typography)`
    padding: 12px 0 0 0;
`;

const PaperStyled = styled(Paper)`
    padding: 12px;
    margin: 12px 0;
    cursor: pointer;
    background: ${({ theme }) => theme.material.colors.background.default} !important;
`;

class OrderBuilder extends PureComponent<Object, Object> {

    state: Object
    uuid: string = uuidv1();

    constructor(props: Object) {
        super(props);
        const value = props.value || [];
        const denormalizedValues = this.deNormalizeValues(value || []);
        this.state = {
            activeSave: true,
            showModal: false,
            value: this.forest(denormalizedValues).nodes,
            fieldType: getType(value[0]),
        };
    }

    componentDidUpdate(prevProps: Object) {
        const { value } = this.props;
        if (prevProps.value !== value) {
            const val = value || [];
            const denormalizedValues = this.deNormalizeValues(val);
            this.setState({ value: this.forest(denormalizedValues).nodes, fieldType: getType(val) });
        }
    }

    deNormalizeValues = (value: Array<Object>): Array<Object> => {
        return value.map(val => ({ ...val, type: 'child' }));
    }

    normalizeValues = (value: Array<Object>): Array<any> => {
        return value.map(({ direction, field }) => ({ direction, field }));
    }

    buildFormula = memoizeOne((value: Array<Object>) => {
        return value.map((val, index, values) => {
            return <Typography key={`${val.field}${val.uuid}`} variant="body1">{val.direction.toLowerCase()} by {val.field}</Typography>;
        });
    })

    toggleModal = (e: ?Object) => {
        if(e) {
            e.stopPropagation();
        }
        this.setState(state => ({ showModal: !state.showModal}));
    }

    _forest = memoizeOne((value: Array<Object>) => {
        return new Forest(value, this.uuid);
    });

    forest = (value: ?Array<Object>) => {
        return this._forest(value || this.state.value);
    };

    onDrop = (item: Object, parentUuid: string, index: number) => {
        this.setState({ value: this.forest().move(item, parentUuid, index).nodes });
    }

    deleteValue = (value: Object) => {
        this.setState({ value: this.forest().remove(value).nodes }, this.validation);
    }

    addValue = (value: Object, parentUuid: string, index: number) => {
        this.setState({ value: this.forest().add(value, parentUuid, index).nodes }, this.validation);
    }

    handleOnChange = (e: Object) => {
        const { value, name }= e.target;
        const [type, queryUuid] = name.split('.');
        const query = this.forest().get(queryUuid);

        const nextQuery = {
            ...query,
            [type]: value,
        };

        this.setState({ value: this.forest().update(nextQuery).nodes }, this.validation);
    }

    validation = () => {
        const { value } = this.state;
        this.setState({ activeSave: this.isValidValues(value) });
    }

    isValidValues = (value: Object) => {
        let isValid = true;
        value.forEach((val) => {
            if(!isValid) {
                return;
            }
            if(!val.field || !val.direction) {
                isValid = false;
            }
        });
        return isValid;
    }

    onSave = () => {
        const { value, fieldType } = this.state;
        const { name, onChange, changeVariable } = this.props;
        const normalizedValue = this.normalizeValues(value);

        this.toggleModal();
        const nextTarget = { value: normalizedValue, name, type: fieldType };
        if(onChange) {
            onChange({ target: nextTarget});
        }
        if(changeVariable) {
            changeVariable(nextTarget);
        }
    }

    render() {
        const { showModal, value, activeSave, fieldType } = this.state;
        const { label } = this.props;
        return (
            <DragNDropContext>
                <PaperStyled onClick={this.toggleModal}>
                    <Typography variant="caption">{label}</Typography>
                    {this.buildFormula(value)}
                </PaperStyled>
                <Dialog
                    open={showModal}
                    onClose={this.toggleModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Array values builder</DialogTitle>
                    <DialogContent>
                        <Typography variant="caption">Formula</Typography>
                        {this.buildFormula(value)}
                        <TypographyPadded variant="caption">Dragable list</TypographyPadded>
                        <OrderBuilderDnd
                            uuid={this.uuid}
                            onDrop={this.onDrop}
                            addValue={this.addValue}
                            deleteValue={this.deleteValue}
                            value={value}
                            onChange={this.handleOnChange}
                            fieldType={fieldType}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.toggleModal} color="primary" variant="text">
                      Close
                        </Button>
                        <Button disabled={!activeSave} onClick={this.onSave} color="primary" autoFocus>
                      Apply
                        </Button>
                    </DialogActions>
                </Dialog>
            </DragNDropContext>
        );
    }
};

export default OrderBuilder;
