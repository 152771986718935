import uuidv1 from 'uuid/v1';
import { get, set } from '../lo/lo';

/**
 * Get all values in a nested array based on the specified key
 * 
 * @param {array} records the records to iterate
 * @param {string} key the key value of the object
 * @returns an array containing the values
 */
const getValuesFromKey = (records, key) => {
    if (!key) {
        throw new Error('key parameters is invalid.');
    }
    return (records || []).reduce((prev, cur) => {
        prev.push(get(cur, key));
        if (cur.children) {
            prev.push(...getValuesFromKey(cur.children, key));
            return prev;
        }
        return prev;
    }, []).filter(Boolean);
};

/**
 * Adds a new key value pair to an object inside a nested array with children
 * 
 * @param {array} records the records to iterate 
 * @param {array} compareRecords the records that contains other data
 * @param {string} key the new key that will be added in the array of objects
 * @param {string} compareKey the key to be used match the records and the compareRecords
 * @param {string} compareRecordsKey the key based on the records array to be used match the records and the compareRecords
 * @param {function} getterFn a getter function to fetch the correct value and set it to the new object key
 * @returns 
 */
const addKeyValueToObjectArray = (records, compareRecords, key, compareKey, compareRecordsKey, getterFn) => {
    if (!key) {
        throw new Error('key parameters is invalid.');
    }
    if (!compareKey) {
        throw new Error('compareKey parameters is invalid.');
    }
    if (!typeof getterFn === 'function') {
        throw new Error('getterFn must be a function.');
    }
    return (records || []).map((node) => {
        const matchedRecord = (compareRecords || []).find(record => get(record, compareKey) === get(node, compareRecordsKey));
        node = set(node, key, getterFn(node, matchedRecord || {}));
        if (node.children) {
            node.children = addKeyValueToObjectArray(node.children, compareRecords, key, compareKey, compareRecordsKey, getterFn);
            return node;
        }
        return node;
    });
};

/**
 * Add Children to Tree Nodes
 * @param {array} records - add default children object to tree nodes
 * @returns the records with the children object
 */
const addChildrenToNodes = (records = []) => {
    return records.map((node) => {
        return {
            ...node,
            nodeId: uuidv1(),
            children: Array.isArray(node.children) ? addChildrenToNodes(node.children) : []
        };
    });
};

/**
 * Build the Tree Entity Object
 * @param {array} children the children to modi
 * @returns the new children array containing the modified values
 */
const buildTreeEntityObject = (children = []) => {
    return children.map(({ id, reverse, children, listBy }) => {
        const childObj = (children || []).length > 0 ? {
            children: buildTreeEntityObject(children)
        } : {};
        const listByObj = listBy ? { listBy } : {};
        return { id, reverse, ...listByObj, ...childObj };
    });
};

/**
 * Check if the nested array of objects has a missing key
 * @param {*} children the array to traverse
 * @param {*} childrenArr default value
 * @returns an array containing of true and false values. Pushes a falsy value if a key is not found in the current array object [false, true, ...etc]
 */
const checkForMissingKeys = (children, childrenArr, key) => {
    if (!key) {
        throw new Error('key parameters is not found.');
    }

    return children.reduce((acc, child) => {
        if (!child[key]) {
            acc.push(false);
            return acc;
        }
        
        if (child.children.length > 0) {
            return checkForMissingKeys(child.children, acc, key);
        }

        acc.push(true);

        return acc;
    }, childrenArr);
};

/**
 * Check if the tree template form is valid
 * @param {obj} data the primary.relationTree object from tree template
 * @returns true if the form is valid
 */
const validateTreeTemplateForm = (data) => {
    if (data?.relatedEntityClass && !get(data, 'relationTree.listBy', null))
        return false;
    
    if (data?.relatedEntityClass) {
        const hasListByArr = checkForMissingKeys(get(data, 'relationTree.children', []), [], 'listBy');

        if (hasListByArr.length > 0 && hasListByArr.findIndex(d => d === false) !== -1) {
            return false;
        }
    }

    return true;
};

export {
    getValuesFromKey,
    addKeyValueToObjectArray,
    addChildrenToNodes,
    buildTreeEntityObject,
    checkForMissingKeys,
    validateTreeTemplateForm
};