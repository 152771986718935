/* @flow */
import React from 'react';

import Help from 'app/utils/designer/form/settings/common/Help';
import { get } from 'app/utils/lo/lo';
import {
    name,
    label,
    placeholder
} from 'app/utils/designer/form/settings/common/commonBase';
import { multiple } from 'app/utils/designer/form/settings/common/commonGeneral';
import {
    onLoad,
    onChange,
    isVisible,
    isDisabled
} from 'app/utils/designer/form/settings/common/commonEvents';
import { help } from 'app/utils/designer/form/settings/common/commonHelpers';
import {
    required,
    readOnly,
    classRequired,
    staticAttribute
} from 'app/utils/designer/form/settings/common/commonValidation';
import { flexGrow } from 'app/utils/designer/form/settings/common/commonStyles';

const fetchFunctionCodeExample = `
async (searchText, vars, context) => {
    let store = [
        { value: 'op1', label: 'Op #1'},
        { value: 'op2', label: 'Op #2'}
    ];
    if (searchText) {
        store = store.filter(
            ({ label }) =>
                label.toLowerCase().includes(searchText.toLowerCase())
        );
    }
    return store;
}`;

const panels = settingsValues => [
    {
        header: 'Base',
        children: [name, label, placeholder]
    },
    {
        header: 'General',
        children: [
            multiple,
            {
                type: 'boolean',
                properties: {
                    label: 'Fetch options',
                    name: 'properties.useFetch'
                }
            },
            {
                type: 'textarea',
                properties: {
                    label: 'Static options',
                    name: 'settings.staticOptions',
                    rows: 5,
                    // the onChange function must be a string to run in the service worker
                    onChange: String(function(event) {
                        const value = event.target.value;
                        const options =
                            value &&
                            value.split(/\r\n|\r|\n/g).map((line, index) => {
                                const splitedLine = line.split(',');
                                return {
                                    value: !isNaN(Number(splitedLine[0])) ? Number(splitedLine[0]): splitedLine[0],
                                    label: splitedLine[1]
                                };
                            });
                        return [
                            { name: 'settings.staticOptions', value },
                            { name: 'properties.options', value: options }
                        ];
                    }),
                    isVisible: ({ properties }) =>
                        !properties || !properties.useFetch,
                    help: (
                        <Help
                            message={`Enter your options on seperate lines, with key and value (CSV)

Example:
\`\`\`
1,High
2,Medium
3,Low
\`\`\`
`}
                        />
                    )
                }
            },
            {
                type: 'textarea',
                properties: {
                    label: 'Fetch function',
                    name: 'properties.fetchFunction',
                    parseAs: 'javascript',
                    modal: true,
                    title: 'Fetch function',
                    isVisible: ({ properties }) =>
                        properties && properties.useFetch,
                    helperText: 'The function has to return a Promise.',
                    template: `/*\n${fetchFunctionCodeExample}\n*/\n`,
                    useCodeEditor: true
                }
            },
            {
                type: 'text',
                properties: {
                    label: 'Value field',
                    name: 'properties.valueField',
                    isVisible: ({ properties }) => get(properties, 'useFetch'),
                    help: (
                        <Help
                            message={`
Defines what field of the option will be used to define the value.
If no field will be spacified the whole option object will be used as value.`}
                        />
                    )
                }
            }
        ]
    },
    {
        header: 'Events',
        children: [
            onLoad,
            onChange,
            isVisible,
            settingsValues?.viewType !== 'class' && isDisabled
        ].filter(Boolean)
    },
    {
        header: 'Validation',
        children: [
            settingsValues?.viewType !== 'class' ? required : classRequired,
            settingsValues?.viewType === 'class' && readOnly,
            settingsValues?.viewType === 'class' && staticAttribute(settingsValues)
        ].filter(Boolean)
    },
    {
        header: 'Styles',
        children: [flexGrow]
    },
    {
        header: 'Helpers',
        children: [help]
    }
];

export default {
    defaults: { serialize: true, useFetch: false, withDefault: true },
    panels: settingValues => panels(settingValues)
};
