// @flow
import { isDefined } from 'app/utils/utils';
import { graphql } from 'graphql/client';
import locationGetAddressMutation from 'graphql/common/locationGetAddressMutation';
import locationGetCoordinatesMutation from 'graphql/common/locationGetCoordinatesMutation';
import userUpdatePositionQuery from 'graphql/users/userUpdatePositionQuery';
import store from 'store/Store';


const LOCATION_TRACKING_TIME = 30000;

/**
 *
 */
class GeoCoding {

    locationTimer: IntervalID;
    /**
     * fromLatLng - description
     *
     * @param  {type} lat    description
     * @param  {type} lng    description
     * @return {type}        description
     */
    fromLatLong = (lat: number, lng: number) => {
        if (!isDefined(lat) || !isDefined(lng) || isNaN(lat) || isNaN(lng) || !this.isValidLatitute(lat) || !this.isValidLongitute(lng)) {
            return Promise.reject(new Error('Provided coordinates are invalid'));
        }
        return graphql.mutate({
            mutation: locationGetAddressMutation,
            variables: { coordinates: { lat, lng } },
            fetchPolicy: 'no-cache'
        });
    };

    /**
     * async fromAddress - description
     *
     * @param  {type} address description
     * @return {type}         description
     */
    fromAddress = (address: string) => {
        if (!address) {
            return Promise.reject(new Error('Provided address is invalid'));
        }
        return graphql.mutate({
            mutation: locationGetCoordinatesMutation,
            variables: { record: { address } },
            fetchPolicy: 'no-cache'
        });
    };

    options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
    };

    /**
     * show the user current position
     */
    getCurrentLocation = (onSuccess: Function, onError: Function) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(onSuccess, onError, this.options);
        }
    };

    /**
     * This function will be used to
     * convert Degree Minutes Seconds
     * to decimal format like 24.44
     */
    convertDMSToDD = (degrees: number = 0, minutes: number = 0, seconds: number = 0, direction: string) => {
        let dd = (degrees || 0) + (minutes || 0) / 60 + (seconds || 0) / (60 * 60);
        if (direction === 'S' || direction === 'W') {
            dd = dd * -1;
        }
        return dd;
    };

    /**
     * This function will be used to convert
     * decimal format to degree minutes and
     * secods format
     */
    convertDDtoDMS = (name: string, dd: number) => {
        if (isNaN(dd)) {
            return { degrees: undefined, minutes: undefined, seconds: undefined };
        }
        const degrees = parseInt(dd, 10);
        const minutes = parseInt((dd - degrees) * 60, 10);
        // const seconds = parseInt((dd - degrees - minutes / 60) * 3600, 10);
        const seconds = (dd - degrees - minutes / 60) * 3600;
        let direction = '';
        if (name === 'latitude') direction = dd > 0 ? 'N' : 'S';
        if (name === 'longitude') direction = dd > 0 ? 'E' : 'W';
        return { degrees: Math.abs(degrees) || undefined, minutes: Math.abs(minutes) || undefined, seconds: Math.abs(seconds) || undefined, direction };
    };

    /**
     * It will take the address_components from
     * geo code response and convert it to the
     * Address object with all required fields
     */
    getAddress = (gAddress: Array<Object> = []) => {
        let countriesList = [];
        try {
            countriesList = store.getState().entities.countries?.data || [];
        } catch (error) {}
        const address = { province: '', city: '', country: '', code: '', line1: '', line2: '' };
        if (!gAddress.length) return address;
        let _line1 = '';
        let _line2 = '';
        gAddress.forEach(({ address_components }) => {
            if (address_components?.length) {
                address_components.forEach(({ types, long_name, short_name }) => {
                    if (types.includes('administrative_area_level_1')) address.province = long_name;
                    if (types.includes('administrative_area_level_2')) address.city = long_name;
                    if (types.includes('country')) {
                        let countryData = null;
                        if (short_name?.length === 2 && countriesList?.length) {
                            countryData = countriesList.find(({ primary }) => primary['system_country/iso'] === short_name);
                        }
                        address.country = countryData ? countryData.name : long_name.toUpperCase();
                    };
                    if (types.includes('postal_code')) address.code = long_name;
                    if (types.includes('sublocality') || types.includes('locality')) {
                        if(long_name.length > _line2.length) {
                            _line2 = long_name;                            
                        }
                    }
                    if (types.includes('street_number') || types.includes('route')){
                        if(long_name.length > _line1.length) {
                            _line1 = long_name;                            
                        }
                    }
                });
            }
        });
        address.line2 = _line2.trim();
        address.line1 = _line1.trim();
        return address;
    };

    /**
     * This function will check whether the latitude is vlaid or not
     */
    isValidLatitute = (latitude: number | string) => {
        const patt = new RegExp('^(\\+|-)?(?:90(?:(?:\\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\\.[0-9]{1,50})?))$');
        return patt.test(latitude.toString());
    };

    /**
     * This function will check whether the longitude is vlaid or not
     */
    isValidLongitute = (longitude: number | string) => {
        const patt = new RegExp(/^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,50})?))$/);
        return patt.test(String(longitude));
    };

    isLocationSharingOn = () => {
        // $FlowFixMe
        return localStorage.getItem('userPositionTrackingEnabled') === 'true';
    };

    updateUserLocation = (callback: Function = () => {}) => {
        if (this.isLocationSharingOn()) {
            // Call very first time
            this.getCurrentLocation(
                (coordinates) => {
                    this.onSuccessPosition(coordinates, callback);
                },
                (error) => {
                    this.onPositionError(error, callback);
                }
            );
            // Set an interval as per LOCATION_TRACKING_TIME
            this.locationTimer = setInterval(() => {
                return this.getCurrentLocation(
                    (coordinates) => {
                        this.onSuccessPosition(coordinates, callback);
                    },
                    (error) => {
                        this.onPositionError(error, callback);
                    }
                );
            }, LOCATION_TRACKING_TIME);
        }
    };

    onSuccessPosition = (position: Object, callback: Function) => {
        const latitude = position.coords.latitude + '';
        const longitude = position.coords.longitude + '';
        const lastUpdate = parseInt(localStorage.getItem('userPositionTrackingLastUpdate'));
        const shouldUpdate = Date.now() - lastUpdate >= LOCATION_TRACKING_TIME;
        if (this.isLocationSharingOn()) {
            if (!lastUpdate || shouldUpdate) {
                // $FlowFixMe
                localStorage.setItem('userPositionTrackingLastUpdate', Date.now());
                if (isDefined(latitude) && isDefined(longitude)) {
                    graphql
                        .mutate({
                            mutation: userUpdatePositionQuery,
                            variables: { latitude, longitude },
                            fetchPolicy: 'no-cache'
                        })
                        .then(callback);
                }
            }
        } else {
            clearInterval(this.locationTimer);
        }
    };

    onPositionError = (error: Object, callback: Function) => {
        clearInterval(this.locationTimer);
        callback(error);
    };

    calcDistance = (mk1, mk2) => {
        if(!mk1 || !mk2) {
            return null;
        }
        const R = 6371e3;
        var rlat1 = mk1.lat * (Math.PI/180); // Convert degrees to radians
        var rlat2 = mk2.lat * (Math.PI/180); // Convert degrees to radians
        var difflat = rlat2-rlat1; // Radian difference (latitudes)
        var difflon = (mk2.lng-mk1.lng) * (Math.PI/180); // Radian difference (longitudes)

        var d = 2 * R * Math.asin(Math.sqrt(Math.sin(difflat/2)*Math.sin(difflat/2)+Math.cos(rlat1)*Math.cos(rlat2)*Math.sin(difflon/2)*Math.sin(difflon/2)));
        return Number(Number(d/1000).toFixed(2));
    };
}

const Geocode = new GeoCoding();

export default Geocode;
