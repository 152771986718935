/* @flow */

import React, { memo, useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { muiTheme } from 'app/themes/materialUi';
import { IconButton, Tooltip } from '@mic3/platform-ui';

import Icon from 'app/components/atoms/Icon/Icon';

const Wrapper = styled.div`
    & .Icon:before {
        color: ${({ theme }) => theme.material.colors.appHeader.iconColor} !important;
    }
`;

const GlobalLeftPanelHeader = ({ 
    onHide, 
    onClickSettings,
    hideSettings
}) => {
    const onSettingClick = useCallback((e) => {
        onClickSettings(e);
    }, [onClickSettings]);

    const onDotMenuClick = useCallback(() => {
        onHide();
    }, [onHide]);

    return (
        <Wrapper>
            {!hideSettings && 
                <Tooltip title='Settings' aria-label='settings'>
                    <IconButton onClick={onSettingClick} >
                        <Icon name='cog-outline' style={{ color: muiTheme.colors.appHeader.iconColor }} />
                    </IconButton>
                </Tooltip>
            }
            <Tooltip title='Hide Sidebar' aria-label='hide-sidebar'>
                <IconButton onClick={onDotMenuClick} >
                    <Icon name='chevron-double-left' />
                </IconButton>
            </Tooltip>
        </Wrapper>
    );
};

GlobalLeftPanelHeader.propTypes = {
    onHide: PropTypes.func.isRequired,
    onClickSettings: PropTypes.func,
    hideSettings: PropTypes.bool
};

export default memo(GlobalLeftPanelHeader);
