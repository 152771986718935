/* @flow */

import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Avatar, CardContent, Box, Typography, Grid, CardHeader, Card, Link, Tooltip } from '@mic3/platform-ui';
import { makeStyles } from '@material-ui/core/styles';

import AvatarGroup from 'app/components/molecules/Avatar/AvatarGroup';
import ResizableListItem from 'app/components/molecules/VirtualList/ResizableListItem';
import { fromNow } from 'app/utils/date/date';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { getAttachmentUrl } from 'app/utils/attachments/attachmentsUtils';

const CardContainer = styled.div`
    width: 98%;
    max-width: 1000px;
    margin: 0 auto;
`;

const CardHeaderStyled = styled(CardHeader)`
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
`;

const SubheaderStyled = styled.span`
    font-size: 0.6em;
    color: rgb(128, 128, 128);
    font-weight: normal;
    line-height: 1.1;
`;
const GridItem = styled.span`
    flex-grow: 1;
`;

const useCardInfoStyles = makeStyles(theme => ({
    root: {
        marginRight: (props: Object) => (props.display === 'inline' ? 'margin-right: 16px;' : 'margin-right: 0; margin-bottom: 16px'),
        fontSize: '12px',
        position: 'relative',
        '& .MuiIcon-root': {
            fontSize: '14px !important',
            height: '14px !important',
            width: '14px !important',
            lineHeight: '14px !important'
        },
        '& .MuiTypography-root': {
            fontSize: '12px'
        }
    },
    body: {
        '& span': {
            fontWeight: '500'
        }
    },
    divider: {
        position: 'relative',
        top: '1px',
        right: '-8px',
        marginRight: '18px',
        display: 'inline-block',
        width: '1px',
        height: '10px',
        background: 'rgba(255, 255, 255, 0.12)'
    }
}));
const ListCardInfo = ({ children, display, noDivider }: Props) => {
    const classes = useCardInfoStyles({ display });
    return (
        <Box className={classes.root} component="div" display={display}>
            <Typography className={classes.body} variant="body1" display="inline">
                {children}
            </Typography>
            {display === 'inline' && !noDivider && <span className={classes.divider} />}
        </Box>
    );
};

ListCardInfo.defaultProps = {
    display: 'inline'
};

class BroadcastListCard extends PureComponent<Object, Object> {
    @bind
    @memoize()
    buildRecipientTeams(recipientTeams) {
        return (recipientTeams || []).map((team, i) => <Avatar key={i} max={4} initials={team.name} />);
    }

    @bind
    @memoize()
    buildRecipientUsers(recipientUsers) {
        return (recipientUsers || []).map((user, i) => {
            const profileImage = user?.image && getAttachmentUrl(user?.id, 'user', user.image);
            return <Avatar key={i} max={4} initials={user?.name} src={profileImage} />;
        });
    }

    render() {
        const { data, index, resize, style } = this.props;
        const { message, id, parent, startDate, createdDate, createdBy, modifiedBy, modifiedDate, active, expireDate, recipientUsers, recipientTeams } = data;
        return (
            <ResizableListItem style={style} key={index} index={index} resize={resize} padding={15}>
                {resizeRow => (
                    <CardContainer>
                        <Card>
                            <Grid container alignItems="center" wrap="nowrap">
                                <GridItem>
                                    <CardHeaderStyled
                                        disableTypography
                                        title={
                                            <Grid container spacing={2}>
                                                <Link to={`/broadcasts/edit/${parent || id}`}>
                                                    <Typography>{message || 'No Name'}</Typography>
                                                </Link>
                                            </Grid>
                                        }
                                        subheader={
                                            <SubheaderStyled>
                                                {`#${id}`} {parent ? `(#${parent})` : ''}
                                            </SubheaderStyled>
                                        }
                                        action={
                                            <Box display="flex">
                                                <AvatarGroup max={4}>{this.buildRecipientUsers(recipientUsers)}</AvatarGroup>
                                                <AvatarGroup max={4}>{this.buildRecipientTeams(recipientTeams)}</AvatarGroup>
                                            </Box>
                                        }
                                    />
                                    <CardContent>
                                        <Box paddingBottom={4}>
                                            <ListCardInfo>Starts {fromNow(startDate)}</ListCardInfo>
                                            <ListCardInfo>Expires {fromNow(expireDate)}</ListCardInfo>
                                            <ListCardInfo>Active {active ? 'True' : 'False'}</ListCardInfo>
                                            {modifiedBy ? (
                                                <ListCardInfo>
                                                    Last modified{' '}
                                                    <Tooltip title={new Date(modifiedDate).toUTCString()} placement="bottom">
                                                        <span>{`${fromNow(modifiedDate)} `} </span>
                                                    </Tooltip>
                                                    <span>{modifiedBy.name}</span>
                                                </ListCardInfo>
                                            ) : null}
                                            <ListCardInfo noDivider>
                                                Created{' '}
                                                <Tooltip title={new Date(createdDate).toUTCString()} placement="bottom">
                                                    <span>{`${fromNow(createdDate)}`}</span>
                                                </Tooltip>
                                                {createdBy ? (
                                                    <>
                                                        {' '}by <span> {createdBy.name}</span>{' '}
                                                    </>
                                                ) : null}
                                            </ListCardInfo>
                                        </Box>
                                    </CardContent>
                                </GridItem>
                            </Grid>
                        </Card>
                    </CardContainer>
                )}
            </ResizableListItem>
        );
    }
}

export default BroadcastListCard;
