/* @flow */

import { gql } from '@apollo/client';

export default gql`
    query scriptQuery($id: ID!) {
        result: script(id: $id) {
            id
            name
            description
            primary
            modifiedDate
            createdDate
            formDefinition {
              id
              name
              attributes
              primary
            }
            createdBy {
                id
                name
            }
            modifiedBy {
                id
                name
            }
        }
        role: resourcePermission(type: "script", id: $id)
    }
`;
