//@flow

import { memoize } from 'app/utils/decorators/decoratorUtils';
import { getNearest, getRoute } from 'store/actions/maps/situationalAwarenessActions';
import { entityPinStyle } from 'app/components/molecules/Map/EntityPin/entityPinStyle';
import { createShapes } from 'app/components/molecules/Map/DrawShapes/DrawShapes';
import { Stroke, Style } from 'ol/style';
import Overlay from 'ol/Overlay';
import { isDefined } from 'app/utils/utils';

class RouteHelperTool {
    olMap: Object;
    routeHelper: any;
    allRoutes: Object = {};
    styledPins: Array<any> = [];
    routeHelper: any;
    minRoute: any;
    lastRouteHeplerTooltip: any;
    highLightRouteStyle: Object = new Style({
        stroke: new Stroke({
            color: '#172282',
            width: 5
        }),
        zIndex: 100
    });
    minRouteStyle: Array<Object> = [
        new Style({
            stroke: new Stroke({
                color: '#19629e',
                width: 7,
            })
        }),
        new Style({
            stroke: new Stroke({
                color: '#1ea8a8',
                width: 4,
                zIndex: 50
            })
        })
    ];
    medRouteStyle: Array<Object> = [
        new Style({
            stroke: new Stroke({
                color: '#a0aeba',
                width: 7,
            })
        }),
        new Style({
            stroke: new Stroke({
                color: '#343c42',
                width: 4,
                zIndex: 50
            })
        })
    ];

    constructor(olMapVM: Object) {
        this.olMap = olMapVM;
    }

    @memoize()
    createRouteHelperTooltip(coordinate: ?Array<number>) {
        const elem = document.createElement('div');
        elem.className = 'overlay-route-helper';
        if (!this.routeHelper) {
            this.routeHelper = new Overlay({
                element: elem,
                positioning: 'center-center',
                offset: [0, -30]
            });
            this.olMap.getMap().addOverlay(this.routeHelper);
        }
        this.routeHelper.setPosition(coordinate);
    }

    setRouteHelperTooltipText(text: string) {
        this.lastRouteHeplerTooltip = text;
        if (this.routeHelper) {
            this.routeHelper.getElement().innerHTML = text;
        }
    }

    removePinStyle(index: number) {
        const pin = this.styledPins[index];
        if (pin) {
            pin.setStyle(null);
            this.styledPins.splice(index, 1);
        }
    }

    removeAllRoutes(removeHelper: boolean = true) {
        this.styledPins.forEach(pin => pin.setStyle(null));
        this.styledPins = [];
        Object.keys(this.allRoutes).forEach((key) => {
            const item = this.allRoutes[key];
            this.olMap.getMap().removeOverlay(item.overlay);
        });
        if (removeHelper) {
            this.olMap.getMap().removeOverlay(this.routeHelper);
            this.routeHelper = null;
        }
        this.allRoutes = {};
        const measureLayer = this.olMap.getLayersGroup('Measure Layer');
        this.minRoute = null;
        if (measureLayer) {
            measureLayer.getSource().clear();
        }
    }

    removeRoute(index: number, distance: number) {
        if (index > 0) {
            const routeToRemove = this.allRoutes[distance];
            this.removePinStyle(index);
            if (routeToRemove) {
                const measureLayer = this.olMap.getLayersGroup('Measure Layer');
                if (measureLayer) {
                    // $FlowFixMe
                    measureLayer.getSource().removeFeature(routeToRemove.feature);
                }
                delete this.allRoutes[distance];
                if (!this.allRoutes.length) {
                    this.setRouteHelperTooltipText('Select end point.');
                }
                const array = Object.keys(this.allRoutes).map(x => Number(x));
                this.minRoute = null;
                this.calculateFastest(array);
            }
            return;
        }
        this.removePinStyle(0);
        this.setRouteHelperTooltipText('Select a start point.');
    }

    @memoize()
    calculateDistanceAndDuration(distance: number, duration: number) {
        const days = Math.floor((duration % 31536000) / 86400);
        const hours = Math.floor(((duration % 31536000) % 86400) / 3600);
        const minutes = Math.floor((((duration % 31536000) % 86400) % 3600) / 60);
        const humanFriendlyDuration = `${days ? days + 'd ' : ''}
            ${hours ? hours + 'h ' : ''}${minutes ? minutes + 'm ' : ''}`;
        return {
            humanFriendlyDistance: (distance / 1000).toFixed(2),
            humanFriendlyDuration
        };
    }

    createRouteOverlay(
        coordinate: Array<number>,
        distance: number,
        duration: number
    ) {
        const elem = document.createElement('div');
        elem.className = 'overlay-route-medium';
        const {
            humanFriendlyDistance,
            humanFriendlyDuration
        } = this.calculateDistanceAndDuration(distance, duration);
        elem.innerHTML = `Distance: ${humanFriendlyDistance} km <br /> Duration: ${humanFriendlyDuration}`;
        return new Overlay({
            element: elem,
            position: coordinate,
            positioning: 'center-center',
            offset: [0, -30]
        });
    }

    calculateFastest(array: Array<number>) {
        if (array.length === 0) return;
        // $FlowFixMe
        const min = Math.min(...array);
        if (!isDefined(this.minRoute) || this.minRoute > min) {
            if (isDefined(this.minRoute)) {
                this.allRoutes[this.minRoute].feature.setStyle(this.medRouteStyle);
            }
            this.minRoute = min;
            this.allRoutes[this.minRoute].feature.setStyle(this.minRouteStyle);
        }
    }

    createFastestRoute(feature: Object, distance: number, measureLayer: Object) {
        feature.setStyle(this.medRouteStyle);
        this.allRoutes[distance] = { feature };
        const array = Object.keys(this.allRoutes).map(x => Number(x));
        this.calculateFastest(array);
        measureLayer.getSource().addFeature(this.allRoutes[distance].feature);
    }

    createMultipleRoutes(
        feature: Object,
        distance: number,
        measureLayer: Object
    ) {
        this.allRoutes[distance] = { feature };
        feature.setStyle(this.minRouteStyle);
        measureLayer.getSource().addFeature(feature);
    }

    createRoute(routes: any, coordinate: any, routeType: Object) {
        if (routes) {
            const polyline = routes[0].geometry;
            const distance = routes[0].distance;
            //const duration = routes[0].duration;
            const feature = createShapes({ type: 'Polyline', polyline, distance });
            const measureLayer = this.olMap.getLayersGroup('Measure Layer');
            if (measureLayer && feature) {
                if (routeType.value === 0) {
                    return this.createMultipleRoutes(feature, distance, measureLayer);
                }
                this.createFastestRoute(feature, distance, measureLayer);
            }
        }
    }

    centerAroundFeature(coord: Array<number>) {
        this.olMap.getMap()
            .getView()
            .setCenter(coord);
    }

    highLightRoute(distance: number) {
        const route = this.allRoutes[distance];
        if (route) {
            const { feature } = route;
            if (feature) {
                const style = feature.getStyle();
                feature.setStyle(this.highLightRouteStyle);
                return new Promise((resolve) => {
                    setTimeout(() => {
                        feature.setStyle(style);
                        resolve();
                    }, 1000);
                });
            }
        }
    }

    highLightRouteFeature(point: any) {
        const { feature } = point;
        if (feature) {
            const coord = feature.getGeometry().getCoordinates();
            this.centerAroundFeature(coord);
            this.olMap.highlightFeature(coord);
            return new Promise((resolve) => {
                setTimeout(() => {
                    if (this.olMap.overlayMap.length) {
                        this.olMap.overlayMap.forEach((overlay) => {
                            this.olMap.getMap().removeOverlay(overlay);
                        });
                    }
                    resolve();
                }, 1000);
            });

        }
    }

    setPinStyle(pin: Object) {
        if (pin) {
            this.styledPins.push(pin);
            const style = entityPinStyle(pin, this.olMap.getMap(), true);
            pin.setStyle(style);
        }
    }


    /**
     * toggleRouteHelper
     * @param toggleFromLayer - if toggleFromLayer is true that means only show/hide layer.
     * otherwise it is active and inactive.
     */
    toggleRouteHelper(value: boolean, toggleFromLayer: boolean) {
        if (toggleFromLayer) {
            if (value) {
                this.createRouteHelperTooltip(null);
                this.setRouteHelperTooltipText(this.lastRouteHeplerTooltip);
                this.toggleAllPinsStyle(value);
                return;
            }
            this.toggleAllPinsStyle(value);
            return;

        }
        if (value) {
            this.createRouteHelperTooltip(null);
            this.setRouteHelperTooltipText('Select a start point.');
            return;
        }
        this.removeAllRoutes();
    }

    toggleAllPinsStyle(value) {
        this.styledPins.forEach(pin =>
            pin.setStyle(value ? null : entityPinStyle(pin, this.olMap.getMap(), true))
        );
    }


    getRoute({ routePoints, routeType, coordinate, feature, pin }: Object) {
        return getNearest(coordinate)
            .then(async (coord_street) => {
                if (routePoints.length < 1) {
                    this.setPinStyle(pin);
                    this.setRouteHelperTooltipText('Successfuly added start point, now select end point.');
                    return { coord_street, feature };
                }
                const index = routeType.value === 0 ? routePoints.length - 1 : 0;
                const fromPoint = routePoints[index].coord_street;
                const toPoint = coord_street;
                // $FlowFixMe
                const found = routePoints.find(route => route.coord_street[0] === toPoint[0] && route.coord_street[1] === toPoint[1]);
                if (found) {
                    this.setRouteHelperTooltipText('Route already added. Try different one');
                    return null;
                }
                return getRoute(fromPoint, toPoint)
                    .then(resp => resp.json())
                    .then((json) => {
                        if (json.code !== 'Ok') {
                            return null;
                        }
                        this.setPinStyle(pin);
                        this.createRoute(json.routes, coordinate, routeType);
                        this.setRouteHelperTooltipText('Successfuly added route. Try adding another one.');
                        return { coord_street, feature, route: json.routes };
                    }).catch(() => null);
            })
            .catch(() => {
                this.setRouteHelperTooltipText('Try adding again.');
                return null;
            });
    }


}

export default RouteHelperTool;
