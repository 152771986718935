/* @flow */

import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import Loader from 'app/components/atoms/Loader/Loader';
import { loadEventTypeDetails } from 'store/actions/stream/eventsActions';
import { openEventTypeSidebar } from 'store/actions/entities/eventTypeSidebarActions';
import PageTemplate from 'app/components/templates/PageTemplate';
import EventTypeAbout from 'app/containers/Stream/Events/EventTypes/EventTypeAbout';
import PageNotAllowed from 'app/containers/ErrorPages/PageNotAllowed';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import DotMenu from 'app/components/molecules/DotMenu/DotMenu';
import { baseRoutes } from 'app/config/routesConfig';
import Breadcrumbs from 'app/components/organisms/Breadcrumbs/Breadcrumbs';
import { typeTitlesMultiple } from 'app/config/typesConfig';
import { setDocumentTitle } from 'store/actions/app/appActions';

const dataType = 'eventtype';
/**
 * Main container to display the details view of the Event Type Details
 */
class EventTypeDetails extends PureComponent<Object, Object> {
    static propTypes = {
        id: PropTypes.string.isRequired,
        details: PropTypes.object,
        loadEventTypeDetails: PropTypes.func.isRequired,
        openEventTypeSidebar: PropTypes.func.isRequired
    };

    /**
     * @override
     */
    componentDidMount() {
        const id = this.props.id;
        this.props.loadEventTypeDetails(id);
    }

    componentDidUpdate(prevProps) {
        const { details, setDocumentTitle } = this.props;
        const name = details?.name;
        const prevName = prevProps?.details?.name;
        if(name && name !== prevName){
            setDocumentTitle(name);
        }
        if (prevProps.id !== this.props.id) {
            this.props.loadEventTypeDetails(this.props.id);
        }
    }

    @bind
    onDotMenuClick(title) {
        const { id, openEventTypeSidebar } = this.props;
        const settings = { title, id };
        openEventTypeSidebar(settings);
    }

    @bind
    @memoize()
    dotMenu(sidebarTitle: string, pathname: string = '') {
        return (
            <DotMenu
                key={13}
                onItemClick={this.onDotMenuClick}
                items={[
                    !pathname.endsWith('/about') && sidebarTitle !== 'About' && { name: 'About', icon: 'information-outline' },
                ].filter(Boolean)}
            />
        );
    }

    @bind
    @memoize()
    buildBreadcrumbs(name) {
        return <Breadcrumbs list={[{ link: baseRoutes[dataType], title: typeTitlesMultiple[dataType] }, { title: name }]} withGoBack />;
    }

    /**
     * @override
     */
    render() {
        const { details, isLoading, id, sidebarTitle } = this.props;
        if (!isLoading && !details) {
            return <PageNotAllowed permissionError={true} title={`Event Type. (ID:${id})`} />;
        }
        const { pathname } = this.props.location;
        const { name } = details || {};

        return (
            <Fragment>
                {isLoading && <Loader absolute backdrop />}
                <PageTemplate title={name} subTitle={`#${id}`}>
                    <Switch>
                        <Route path={`${baseRoutes[dataType]}/:id`} exact component={() => <Redirect to={`${baseRoutes[dataType]}/${id}/about`} />} />
                        <Route path={`${baseRoutes[dataType]}/:id/about`} exact>
                            <EventTypeAbout
                                details={details}
                                sidebarActions={[this.dotMenu(sidebarTitle, pathname)]}
                                breadcrumbLine={this.buildBreadcrumbs(name)}
                            />
                        </Route>
                    </Switch>
                </PageTemplate>
            </Fragment>
        );
    }
}

export default connect(
    (state: Object, ownProps: Object): Object => ({
        details: state.stream.events.typeData.data,
        isLoading: state.stream.events.typeData.isLoading,
        id: ownProps.match.params.id,
        sidebarTitle: state.sidebar.title
    }),
    { loadEventTypeDetails, openEventTypeSidebar, setDocumentTitle }
)(EventTypeDetails);
