/* @flow */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import { isMobile } from 'react-device-detect';
import produce from 'immer';
import isUuid from 'uuid-validate';

import { TextField, Button} from '@mic3/platform-ui';
import Immutable from 'app/utils/immutable/Immutable';
import { focusInput, isEmpty } from 'app/utils/utils';
import { bind } from 'app/utils/decorators/decoratorUtils';
import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import VirtualListManaged from 'app/components/molecules/VirtualList/VirtualListManaged';
import { loadTasks } from 'store/actions/abox/taskActions';
import EntityListItem from 'app/containers/Entities/common/EntityListItem';

const inputProps: Object = { disableUnderline: true };

const SyledModalDialog = styled(ModalDialog)`
    .MuiGrid-justify-xs-space-between{
        margin-bottom: 10px;
    }
`;
const StyledEntityListItem = styled(EntityListItem)`
    cursor: pointer;
    background: ${({ selected, theme }) => (selected ? 'rgba(30, 168, 207, 0.2)' : theme.material.colors.background.default)};
`;

const TasksSearch = styled(TextField)`
    margin-bottom: 12px !important;
    .MuiFilledInput-input {
        padding: 10px 12px !important;
    }
    .MuiFilledInput-root {
        background-color: ${({ theme }) => theme.material.colors.background.default} !important;
    }
`;

const TasksListContainer = styled.div`
    div[class*='Container__ContainerStyle'] {
        background-color: transparent;
        margin-bottom: 0;
    }
    div[class*='VirtualList__TinyVirtual'] {
        padding-top: 0 !important;
        margin-top: 0.2em;
    }
    height: ${isMobile ? '60vh' : '50vh'} !important;
    padding-bottom: 36px;
`;

class TimelineAddTaskModal extends PureComponent<Object, Object> {
    static propTypes = {
        closeModal: PropTypes.func.isRequired,
        isOpen: PropTypes.bool,
        isLoading: PropTypes.bool,
        startIndex: PropTypes.number,
        records: PropTypes.array,
        totalRecords: PropTypes.number,
        loadTasks: PropTypes.func.isRequired
    };

    defaultFilters = Immutable([
        { field: 'type', op: '=', value: 'opentask' },
        { field: 'primary.assignee', op: 'is not null' },
        {
            or: [
                { field: 'primary.startDate', op: 'is null' },
                { field: 'primary.startDate', op: 'not between', value: [this.props.startDate, this.props.dueDate] },
            ]
        },
        {
            or: [
                { field: 'primary.dueDate', op: 'is null' },
                { field: 'primary.dueDate', op: 'not between', value: [this.props.startDate, this.props.dueDate] },
            ]
        }
    ]);

    state = {
        search: '',
        filterBy: this.defaultFilters,
        orderBy: [],
        selectedTasks: []
    };

    defaultState: Object = this.state;
    virtualListRef = React.createRef();

    @bind
    renderComponent({ style, index, data }: { style: Object, index: number, data: Object }) {
        const selectedTask = this.getSelectedTask(data.id);
        return (
            <div style={style} key={index}>
                <StyledEntityListItem
                    data={data}
                    title="Click to select"
                    type="task"
                    onClick={e => this.onTasksSelection(e, data)}
                    selected={!isEmpty(selectedTask)}
                />
            </div>
        );
    }

    @bind
    onTasksSelection(event: Object, data: Object) {
        event.preventDefault();
        const selectedTasks = [...this.state.selectedTasks ];
        const foundIndex = selectedTasks.findIndex(selectedTask => selectedTask.id === data.id);
        if (foundIndex === -1 && selectedTasks.length === 15) {
            return;
        }
        const selectedTask = this.getSelectedTask(data.id);
        if (isEmpty(selectedTask)) {
            this.setState(
                produce((draft) => {
                    draft.selectedTasks.push(data);
                }),
                this.updateList
            );
        } else {
            this.setState(prevState => ({ selectedTasks: prevState.selectedTasks.filter(({ id }) => id !== data.id) }), this.updateList);
        }
    }

    @bind
    getSelectedTask(taskId: number) {
        if (!taskId) {
            return {};
        }
        const { selectedTasks } = this.state;
        return (selectedTasks || []).find(({ id }) => id === taskId) || {};
    }

    @bind
    updateList() {
        if (this.virtualListRef && this.virtualListRef.current) {
            this.virtualListRef.current.forceUpdate();
        }
    }

    @bind
    onSubmit() {
        this.props.onSubmit(this.state.selectedTasks);
        this.closeModal();
    }

    @bind
    onChangeSearch(e: Object) {
        const value = e.target.value || '';
        const searchFilters = {
            or: [
                { field: 'name', op: 'contains', value },
                isUuid(value) && { field: 'id', op: '=', value }
            ].filter(Boolean)
        };
        const filterBy = value ? [...this.defaultFilters, searchFilters] : this.defaultFilters;
        this.setState({ search: value, filterBy });
    }

    @bind
    loadData(options: Object) {
        return this.props.loadTasks(options);
    }

    @bind
    closeModal() {
        this.setState(this.defaultState, this.props.closeModal);
    }

    render() {
        const { isOpen, isLoading, records, startIndex, totalRecords, isMobileView } = this.props;
        const { selectedTasks, filterBy, orderBy, search } = this.state;
        return (
            isOpen && (
                <SyledModalDialog
                    dialogProps={{
                        overflow: 'hidden',
                    }}
                    title={`Add Task(s) to Timeline ${selectedTasks.length ? `(${selectedTasks.length})` : ''}`}
                    subtitle={`${selectedTasks.length === 15 ? ' (Maximum tasks limit)' : ''}`}
                    onClose={this.closeModal}
                    actions={
                        <Button disabled={isEmpty(selectedTasks)} size="small" onClick={this.onSubmit}>
                            Submit
                        </Button>
                    }
                >
                    <TasksSearch
                        onChange={this.onChangeSearch}
                        value={search}
                        fullWidth margin="none"
                        placeholder="Search..."
                        InputProps={inputProps}
                        inputRef={ref => !isMobileView && focusInput(ref)}
                    />
                    <TasksListContainer>
                        <VirtualListManaged
                            ref={this.virtualListRef}
                            renderComponent={this.renderComponent}
                            itemSize={84}
                            title={`${totalRecords >= 1000 ? '999+' : totalRecords} Tasks`}
                            itemCount={totalRecords || 0}
                            loadData={this.loadData}
                            isLoading={isLoading}
                            startIndex={startIndex || 0}
                            filterBy={filterBy}
                            orderBy={orderBy}
                            list={records}
                            maxWidth="1024"
                        />
                    </TasksListContainer>
                </SyledModalDialog>
            )
        );
    }
}

export default withTheme(connect(
    state => ({
        userProfile: state.user.profile,
        isLoading: state.abox.task.list.isLoading,
        startIndex: state.abox.task.list.startIndex,
        records: state.abox.task.list.records,
        totalRecords: state.abox.task.list.count,
        isMobileView: state.global.isMobile,
    }),
    {
        loadTasks
    }
)(TimelineAddTaskModal));
