/* @flow */

import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { TextField } from '@mic3/platform-ui';
import styled from 'styled-components';

import EditableText from 'app/components/molecules/EditableText/EditableText';
import { loadWorkspace, updateWorkspace } from 'store/actions/admin/workspacesActions';
import Loader from 'app/components/atoms/Loader/Loader';
import PageTemplate from 'app/components/templates/PageTemplate';
import WorkspaceAboutTab from 'app/containers/Admin/Workspaces/Details/Tabs/WorkspaceAboutTab';
import PageNotAllowed from 'app/containers/ErrorPages/PageNotAllowed';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import DotMenu from 'app/components/molecules/DotMenu/DotMenu';
import { openWorkspaceSidebar } from 'store/actions/entities/workspaceSidebarActions';
import Breadcrumbs from 'app/components/organisms/Breadcrumbs/Breadcrumbs';
import { typeTitlesMultiple, redirectTypes } from 'app/config/typesConfig';
import AddWorkspace from 'app/components/Admin/Modals/AddWorkspace';
import ChipDisabled from 'app/components/atoms/ChipDisabled/ChipDisabled';

const StyledDisabledChip = styled(ChipDisabled)`
    margin-left: 10px;
`;


/**
 * Main container to display the details view of the Workspaces & Permissions
 */
class WorkspaceDetails extends PureComponent<Object, Object> {
    static propTypes = {
        id: PropTypes.string.isRequired,
        workspace: PropTypes.object,
        loadWorkspace: PropTypes.func.isRequired,
        openWorkspaceSidebar: PropTypes.func.isRequired,
        lastActionType: PropTypes.string,
        lastActionError: PropTypes.bool
    };

    state = {
        isModalOpen: false
    };

    /**
     * @override
     */
    componentDidMount() {
        const id = this.props.id;
        this.props.loadWorkspace(id);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.id !== this.props.id) {
            this.props.loadWorkspace(this.props.id);
        }
    }

    @bind
    toggleModal() {
        this.setState({ isModalOpen: !this.state.isModalOpen});
    }

    @bind
    enableDisableWorkspace(active) {
        const { id } = this.props.workspace;
        this.props.updateWorkspace({ id, active });
    }

    @bind
    updateWorkspace(event: Object) {
        const { name, value } = event.target || {};
        const { id } = this.props.workspace;
        this.props.updateWorkspace({ id, [name]: value });
    }

    @bind
    onDotMenuClick(title) {
        const { id, openWorkspaceSidebar } = this.props;
        const settings = { title, id };

        switch (title) {
            case 'Rename':
                this.toggleModal();
                return;
            case 'Enable':
                this.enableDisableWorkspace(true);
                return;
            case 'Disable':
                this.enableDisableWorkspace(false);
                return;
            default:
                break;
        }

        openWorkspaceSidebar(settings);
    }

    @bind
    @memoize()
    dotMenu(sidebarTitle: string, pathname: string = '', isActive) {
        return (
            <DotMenu
                key={13}
                onItemClick={this.onDotMenuClick}
                items={[
                    { name: 'Rename', icon: 'pencil' },
                    !isActive && { name: 'Enable', icon: 'check'},
                    isActive && { name: 'Disable', icon: 'close'},
                    !pathname.endsWith('/about') && sidebarTitle !== 'About' && { name: 'About', icon: 'information-outline' },
                    sidebarTitle !== 'Classes' && { name: 'Classes', icon: 'Attribute-class', iconType: 'af' },
                    sidebarTitle !== 'Class Resources' && { name: 'Class Resources', icon: 'classification-tags', iconType: 'af' },
                    sidebarTitle !== 'Entity Resources' && { name: 'Entity Resources', iconType: 'af', icon: 'things', },
                    sidebarTitle !== 'Task Resources' && { name: 'Task Resources', icon: 'checkbox-marked-circle-outline' },
                    sidebarTitle !== 'Process Resources' && { name: 'Process Resources', icon: 'processes', iconType: 'af' },
                    sidebarTitle !== 'Process Definitions Resources' && { name: 'Process Definitions Resources', icon: 'processes', iconType: 'af' },
                    sidebarTitle !== 'Form Definitions Resources' && { name: 'Form Definitions Resources', icon: 'form', iconType: 'af' },
                    sidebarTitle !== 'Permissions' && { name: 'Permissions', icon: 'do-not-disturb' },
                    sidebarTitle !== 'Scripts' && { name: 'Scripts', icon: 'script-builder', iconType: 'af' },
                    sidebarTitle !== 'History' && { name: 'History', icon: 'history' }
                ].filter(Boolean)}
            />
        );
    }

    @bind
    @memoize()
    buildBreadcrumbs(name, active) {
        return <Breadcrumbs list={[
            { link: `/${redirectTypes['workspace']}`, title: typeTitlesMultiple['workspace'] },
            { title: (
                <>
                    {name}{name && <StyledDisabledChip active={active} />}
                </>
            ) }
        ]} withGoBack />;
    }

    /**
     * @override
     */
    render() {
        const { workspace, isLoading, id, sidebarTitle, openWorkspaceSidebar } = this.props;
        const { pathname } = this.props.location;
        if (!isLoading && !workspace) {
            return <PageNotAllowed permissionError={true} title={`Workspace. (ID:${id})`} />;
        }
        const { name, active } = workspace || {};

        return (
            <Fragment>
                {isLoading && <Loader absolute backdrop />}
                <PageTemplate
                    title={
                        <EditableText
                            displayText={name || 'No Name'}
                            name="name"
                            value={name}
                            onSave={this.updateWorkspace}
                            EditComponent={TextField}
                            EditComponentProps={{ margin: 'none', clearable: false, variant: 'standard', fullWidth: false }}
                        />
                    }
                    subTitle={`#${id}`}
                >
                    <Switch>
                        <Route path={`/workspaces/:id`} exact component={() => <Redirect to={`/workspaces/${id}/about`} />} />
                        <Route path={`/workspaces/:id/about`} exact>
                            {workspace?.id && <WorkspaceAboutTab
                                details={workspace}
                                sidebarActions={[this.dotMenu(sidebarTitle, pathname, active)]}
                                breadcrumbLine={this.buildBreadcrumbs(name, active)}
                                openWorkspaceSidebar={openWorkspaceSidebar}
                            />}
                        </Route>

                        <Route path={'/workspaces/:id/history'} render={() => <h3>Work in progress...</h3>} />
                    </Switch>
                </PageTemplate>
                {this.state.isModalOpen && <AddWorkspace onClose={this.toggleModal} title="Rename" data={workspace} />}
            </Fragment>
        );
    }
}

export default connect(
    (state: Object, ownProps: Object): Object => ({
        workspace: state.admin.workspaces.details.data,
        isLoading: state.admin.workspaces.details.isLoading,
        id: ownProps.match.params.id,
        online: state.app.online,
        sidebarTitle: state.sidebar.title
    }),
    { loadWorkspace, updateWorkspace, openWorkspaceSidebar }
)(WorkspaceDetails);
