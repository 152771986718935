/* @flow */

import { combineReducers } from 'redux';

import { dataTableReducer, loadDataReducer, keepDataAtStart } from 'app/utils/redux/reducer-utils';
import { GET_BROADCAST_STARTED, GET_BROADCAST } from 'store/actions/broadcasts/broadcastsActions';
import { GET_BROADCASTS_STARTED, GET_BROADCASTS } from 'store/actions/broadcasts/broadcastsActions';
import { GET_BROADCASTS_CALENDAR_STARTED, GET_BROADCASTS_CALENDAR } from 'store/actions/broadcasts/broadcastsActions';
import { SAVE_BROADCAST_STARTED, SAVE_BROADCAST } from 'store/actions/broadcasts/broadcastsActions';

import active from './active/activeBroadcastsReducer';
import avatar from './avatar/broadcastsAvatarReducer';

export default combineReducers<Object, Object>({
    active,
    calendar: loadDataReducer(GET_BROADCASTS_CALENDAR_STARTED, GET_BROADCASTS_CALENDAR, keepDataAtStart),
    detail: loadDataReducer(GET_BROADCAST_STARTED, GET_BROADCAST),
    list: dataTableReducer(GET_BROADCASTS_STARTED, GET_BROADCASTS, keepDataAtStart),
    save: loadDataReducer(SAVE_BROADCAST_STARTED, SAVE_BROADCAST),
    avatar,
});
