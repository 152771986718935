
export const pipelinsComponents = [
    { group: 'connector', type: 'source', iconName: 'water-pump', title: 'Source', iconType: 'mdi' },
    { group: 'connector', type: 'sink', iconName: 'dots-hexagon', title: 'Sink', iconType: 'mdi' },
    { group: 'module', type: 'filter', iconName: 'filter-outline', title: 'Filter', iconType: 'mdi' },
    { group: 'module', type: 'map', iconName: 'shuffle-variant', title: 'Map', iconType: 'mdi' },
    { group: 'module', type: 'filter-map', iconName: 'air-filter', title: 'Filter-Map', iconType: 'mdi' },
    { group: 'module', type: 'array-map', iconName: 'table-large', title: 'Array-Map', iconType: 'mdi' },
    { group: 'module', type: 'aggregate', iconName: 'sigma', title: 'Aggregate', iconType: 'mdi' },
    { group: 'topic', type: 'topic', iconName: 'waves', title: 'Topic', iconType: 'mdi' },
    { group: 'annotation', type: 'annotation', iconName: 'format-quote-open', title: 'Text', iconType: 'mdi', size: 16, color: '#FFFFFF', backgroundColor: '#000000' },
    { group: 'external', type: 'external data', disableInputs: true, iconName: 'water', title: 'External Data', iconType: 'mdi' },
];
