/* @flow */
import React from 'react';

import RelationDefinitionSidebar from 'app/containers/Sidebars/RelationDefinitionSidebar';

import {
    buildSidebar as buildSidebarAction,
    closeSidebar as closeSidebarAction
} from 'store/actions/sidebar/sidebarActions';

export const openRelationDefinitionSidebar = ({ id, title }) => async (dispatch, getState) => {
    return buildSidebarAction({
        title,
        content: <RelationDefinitionSidebar id={id} title={title} />,
        isOpen: true,
    })(dispatch, getState);
};

export const closeRelationDefinitionSidebar = closeSidebarAction;
