// @flow
import { isObject, deepEquals, isEmpty } from 'app/utils/utils';
import { get } from 'app/utils/lo/lo';
import history from 'store/History';
import version from 'app/config/version';
import { findAttribute } from '../classification/classificationUtils';

console.log('platform version', version); // eslint-disable-line no-console

const _parseVersionLabel = (versions) => {
    if (!versions) {
        return 'Version unavailable';
    }
    const versionUI = (version.build.version || version.version).split('.').map(Number);
    const bpmn = (versions['bpmn']?.version || '').split('.').map(Number);
    const graphql = (versions['graphql']?.version || '').split('.').map(Number);
    const database = (versions['database']?.version || '').split('.').map(Number);
    return `Version 1.${bpmn[1] + graphql[1] + database[1] + versionUI[1]}.${bpmn[2] + graphql[2] + database[2] + versionUI[2]}`;
};

export const parseVersionLabel = (menu, versions) => {
    return menu.map((menu) => {
        if(menu.icon === 'information-variant') {
            return {
                ...menu,
                label: _parseVersionLabel(versions)
            };
        }
        return menu;
    });
};

// TODO: move these two functions to date utils
// TODO: change isDate name to isDateObject
export const isDate = (value) => {
    return value && typeof value.getMonth === 'function';
};

export const convertTISOString = (value) => {
    if (isDate(value)) {
        return value.toISOString();
    }
    return value;
};

const _isEqual = (v1, v2) => {
    if (isDate(v1) || isDate(v2)) {   // From GQL we receive the ISO format of date but when change the date
        const d1 = convertTISOString(v1); // from date field it becomes a date object
        const d2 = convertTISOString(v2);
        return d1 === d2;
    }
    if((Array.isArray(v1) && !Array.isArray(v2)) || (Array.isArray(v2) && !Array.isArray(v1))){
        return false;
    }
    if (typeof(v1) !== typeof(v2)) {
        return false;
    }
    if (isEmpty(v1) === !isEmpty(v2) || !isEmpty(v1) === isEmpty(v2)) {
        return false;
    }
    if (typeof(v1) === 'string' || typeof(v1) === 'number' || typeof(v1) === 'boolean') {
        return v1 === v2;
    }
    if (isObject(v1) && isObject(v2)) {
        return deepEquals(v1, v2);
    }
    if (Array.isArray(v1) && Array.isArray(v2)) {
        if (v1.length !== v2.length) {
            return false;
        }
        return !v1.map((val, i) => _isEqual(val, v2[i])).includes(false);
    }

    return true;
};

export const getOnlyUpdatedData = (data, formData) => {
    let record = {};
    const primary = {};
    Object.entries(formData).forEach(([key, value]) => {
        if (key === 'id') {
            record[key] = value; // Always keeping the ID
        } else if (key === 'primary' && !isEmpty(value)) {
            Object.entries(value).forEach(([pKey, pValue]) => {
                if (!_isEqual(pValue, get(data, `primary.${pKey}`))) {
                    primary[pKey] = pValue;
                }
            });
        } else if (!_isEqual(value, data[key])) {
            record[key] = value;
        }
    });
    if (!isEmpty(primary)) {
        record = { ...record, primary };
    }
    return record;
};

export const getOnlyUpdatedObjectData = (data, formData) => {
    const record = {};
    Object.entries(formData).forEach(([key, value]) => {
        if (!_isEqual(value, data[key])) {
            record[key] = value;
        }
    });
    return record;
};

export const filterAttributesAsPerDefinitions = (definitions, attributes) => {
    if (Array.isArray(attributes)) {
        return attributes.map(att => _filterAttributesAsPerDefinitions(definitions, att));
    } else {
        return _filterAttributesAsPerDefinitions(definitions, attributes);
    }
};

const _filterAttributesAsPerDefinitions = (definitions, attributes) => {
    if (!isObject(attributes)) {
        return null;
    }
    return Object.keys(attributes).reduce((data, attName) => {
        const attValue = attributes[attName];
        const attribute = findAttribute(definitions, attName);
        if(!attribute) return data;
        if (attribute?.type === 'group' && isObject(attValue)) {
            const nestedAttributes = filterAttributesAsPerDefinitions(definitions, attValue);
            data[attName] = nestedAttributes;
        } else if (attribute?.type === 'groupRepeat' && Array.isArray(attValue)) {
            const nestedArray = attValue.map(item => filterAttributesAsPerDefinitions(definitions, item));
            data[attName] = nestedArray;
        } else if(Array.isArray(attValue)) {
            data[attName] = attValue.filter(Boolean);
        } else {
            // checking isEmpty only when the type of attribute value is object so it do not interfere with numbers, strings or booleans
            data[attName] = (typeof attValue === 'object' && isEmpty(attValue)) ? null : attValue;
        }
        return data;
    }, {});
};

export const openSidebarByParams = (props) => {
    const { id, type, title, internal, openClassSidebar, reloadList, openTaskSidebar, openEntitySidebar, openProcessSidebar,
        openWorkspaceSidebar } = props;
    const params = { id, type, title, reloadList, internal: !!internal };

    switch (type) {
        case 'class':
            openClassSidebar(params);
            break;
        case 'task':
        case 'opentask':
        case 'closedtask':
            if (title === 'Form') {
                history.push(`/abox/task/${id}/form`);
                return;
            }
            if (title === 'Relations') {
                history.push(`/abox/task/${id}/relationships`);
                return;
            }
            if (title === 'Process Map') {
                history.push(`/abox/task/${id}/process-map`);
                return;
            }            
            openTaskSidebar(params);
            break;
        case 'process':
        case 'openprocess':
        case 'closedprocess':
            if (title === 'Relations') {
                history.push(`/abox/process/${id}/relationships`);
                return;
            }            
            openProcessSidebar(params);
            break;
        case 'workspace':
            openWorkspaceSidebar(params);
            break;
        case 'user':
            if (title === 'Relations') {
                history.push(`/user-management/${type}/${id}/relationships`);
                return;
            }            
            openEntitySidebar({ ...params, userAbout: true });
            break;
        case 'eventtype':
            this.props.openEventTypeSidebar(params);
            break;
        case 'event':
            this.props.openEventSidebar({...params, time: entity.time });
            break;
        case 'team':
            this.props.openTeamSidebar(params);
            break;   
        default:
            if (title === 'Relations') {
                history.push(`/entities/${type}/${id}/relationships`);
                return;
            } 
            openEntitySidebar(params);
            break;
    }
    return;
};
