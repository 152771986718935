/* @flow */

import { gql } from '@apollo/client';

const classesFields = `
        id
        name
        uri
        color
        icon
        hidden
        active
        abstract
        primary
        parents{
          id
          uri
          name
          primary
        }
        formDefinition
        entityTabs
        isSystem
        icon
        iconType
        entityPrimaryIndexes {
            entityType {
                id
            }
            primaryAttribute
            isUnique
            type
            state
        }
`;
export default withParents => gql`
    query primaryClassesQuery {
        records: entityTypes {
            ${classesFields}
            ${
    withParents
        ? `parents {
                ${classesFields}
            }`
        : ''
}
        }
    }
`;
