/* @flow */

import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AttachmentsView from 'app/containers/Common/Attachments/AttachmentsView';
import ChatSharing from 'app/components/organisms/Chat/ChatSharing';
import DotMenu from 'app/components/molecules/DotMenu/DotMenu';

import {
    shrinkSidebar,
    expandSidebar,
    closeSidebar,
    setSubTitle,
    setActions,
    setTitle,
} from 'store/actions/sidebar/sidebarActions';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { getPermissions } from 'app/config/rolesConfig';

class ChatSidebar extends PureComponent<Object, Object> {
    static propTypes = {
        rid: PropTypes.string,
        rel: PropTypes.shape({
            id: PropTypes.string,
            type: PropTypes.string,
            name: PropTypes.string,
        }),
        title: PropTypes.string,
        role: PropTypes.string.isRequired,
        isOpen: PropTypes.bool,
        selectedSidebar: PropTypes.string,
    };

    @bind
    setActions() {
        const dotMenu = this.dotMenu();
        this.props.setActions(dotMenu);
    }

    @bind
    onDotMenuClick(title) {
        if (['Collapse', 'Expand'].includes(title)) {
            const { expanded, expandSidebar, shrinkSidebar } = this.props;
            const action = expanded ? shrinkSidebar : expandSidebar;
            action();
            return;
        }
        this.props.setTitle(title);
    }

    @bind
    @memoize()
    dotMenu() {
        return (
            <DotMenu
                key={13}
                onItemClick={this.onDotMenuClick}
                items={[].filter(Boolean)}
            />
        );
    }

    render() {
        const { rid, rel, isOpen, title, role, selectedSidebar  } = this.props;
        const permissions = getPermissions(role);
        const { canEdit } = permissions || {};

        return (
            isOpen && (
                <>
                    {['Sharing', 'Members'].includes(title) && (
                        <ChatSharing 
                            rid={rid} 
                            rel={rel} 
                            canEdit={canEdit} 
                            role={role} 
                            selectedSidebar={selectedSidebar} 
                        />
                    )}
                    {title === 'Attachments' && (
                        <AttachmentsView
                            id={rel.id}
                            type={rel.type}
                            entityType={rel.type}
                            permissions={permissions}
                            disabledDropzone
                        />
                    )}
                </>
            )
        );
    }
}

export default connect(
    (state: Object, ownProps: Object) => ({
        isOpen: state.sidebar.isOpen,
        expanded: state.sidebar.expanded,
        title: state.sidebar.title
    }),
    { shrinkSidebar, expandSidebar, closeSidebar, setActions, setTitle, setSubTitle}
)(withRouter(ChatSidebar));