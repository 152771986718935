import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import uuidv1 from 'uuid/v1';

import history from 'store/History';
import { 
    loadClassificationById,
    loadClassificationsByUri,
    loadRelationDefinitionsFromIds,
    setTreeEntity,
    setTreeTemplate,
    setSidebarTreeTemplate,
    setSidebarTreeTemplateExpand,
} from 'store/actions/entities/relatedEntitiesActions';
import { showToastr } from 'store/actions/app/appActions';

import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { get } from 'app/utils/lo/lo';
import { addKeyValueToObjectArray, getValuesFromKey, addChildrenToNodes } from 'app/utils/relatedEntity/relatedEntityUtils';

import { FormGeneratorStyled, treeTemplateFieldDefintions } from 'app/containers/Entities/RelatedEntities/AddTreeTemplate';
import ExpansionCard from 'app/components/molecules/ExpansionCard/ExpansionCard';
import Loader from 'app/components/atoms/Loader/Loader';

class TreeSection extends PureComponent {
    static propTypes = {
        details: PropTypes.object,
        formName: PropTypes.string.isRequired
    }

    constructor(props: Object) {
        super(props);
        
        this.state = {
            isLoading: true,
            data: {
                treeType: get(props.details, 'primary.treeType')
            }
        };
    };
    
    componentDidMount() {
        this.loadTreeRelations(this.props.details);
    }

    @bind
    handleError(obj, errorMessage) {
        this.props.showToastr({ severity: 'error', detail: errorMessage });
        setRelationTree(obj);
    }

    @bind
    setRelationTree({ rootClass, relatedEntityClass, listBy, rootExtra = {} }) {
        this.setState(prevState => ({ 
            isLoading: false, 
            data: {
                ...prevState.data,
                rootClass,
                relatedEntityClass,
                relationTree: {
                    id: rootClass?.id,
                    uri: rootClass?.uri,
                    name: rootClass?.name,
                    listBy,
                    relatedUri: relatedEntityClass?.uri,
                    relatedId: relatedEntityClass?.id,
                    children: [],
                    reverse: true,
                    nodeId: uuidv1(),
                    ...rootExtra
                }
            } 
        }));
    }

    @bind
    @memoize()
    async loadTreeRelations(treeEntity) {
        const { root, listEntityType } = get(treeEntity, 'primary.relationTree') || {};

        let relatedEntityClass = null;
        let rootExtra = null;
        let children = JSON.parse(JSON.stringify(root?.children || []));

        const rootClass = await this.props.loadClassificationById(root?.id);

        if (rootClass instanceof Error) {
            this.handleError({
                rootClass,
                listBy: root?.listBy,
            }, 'Unable to load root class in relationTree');
            return;
        };

        if (listEntityType) {
            relatedEntityClass = await this.props.loadClassificationById(listEntityType);

            if (relatedEntityClass instanceof Error) {
                this.handleError({
                    rootClass,
                    listBy: root?.listBy,
                }, 'Unable to load related entity class in relationTree');
                return;
            };
            
            const relEntityIds = getValuesFromKey(root?.children, 'listBy.id');
            const relatedEntityDefinition = await this.props.loadRelationDefinitionsFromIds([...relEntityIds, root?.listBy?.id].filter(Boolean));

            if (relatedEntityDefinition instanceof Error) {
                this.handleError({
                    rootClass,
                    listBy: root?.listBy
                }, 'Unable to load related entity definition of root class in relationTree');
                return;
            };
            
            // set relation description to children object
            const setRelEntityName = (current, matched) => get(current, 'listBy.reverse', false) ? matched.relatedDescription : matched.description;
            children = addKeyValueToObjectArray(children, relatedEntityDefinition?.records, 'extra', 'id', 'listBy.id', setRelEntityName);

            const relDefListBy = relatedEntityDefinition?.records.find(relDef => relDef.id === root?.listBy?.id);
            rootExtra = relDefListBy && { extra: get(root, 'listBy.reverse', false) ? relDefListBy.relatedDescription : relDefListBy.description };
        }

        const relIds = getValuesFromKey(root?.children, 'id');
        if (relIds.length <= 0) {
            this.setRelationTree({ rootClass, relatedEntityClass, rootExtra, listBy: root?.listBy });
            return;
        }
        
        const response = await this.props.loadRelationDefinitionsFromIds(relIds);
        
        if (response instanceof Error) {
            this.handleError({
                rootClass,
                relatedEntityClass,
                rootExtra,
                listBy: root?.listBy
            }, 'Unable to load relation definitions in relationTree');
            return;
        };

        // set uri value to children object
        const setUri = (current, matched) => !current.reverse ? matched.relatedType : matched.type;
        children = addKeyValueToObjectArray(children, response?.records, 'uri', 'id', 'id', setUri);

        // set name value to children object
        const setName = (current, matched) => current.reverse ? matched.relatedDescription : matched.description;
        children = addKeyValueToObjectArray(children, response?.records, 'name', 'id', 'id', setName);

        const uris = getValuesFromKey(children, 'uri');
        const classes = await this.props.loadClassificationsByUri(uris);

        if (classes instanceof Error) {
            this.handleError({
                rootClass, 
                relatedEntityClass,
                rootExtra,
                listBy: root?.listBy
            }, 'Unable to load child node classes in relationTree');
            return;
        };
        
        // set class name to children object
        const setUriName = (current, matched) => matched?.name;
        children = addKeyValueToObjectArray(children, classes, 'sub', 'uri', 'uri', setUriName);
        
        // insert empty [] to null children
        children = addChildrenToNodes(children);
        
        this.setState(prevState => ({
            isLoading: false,
            data: {
                ...prevState.data,
                rootClass,
                relatedEntityClass,
                relationTree: {
                    id: rootClass?.id,
                    uri: rootClass?.uri,
                    name: rootClass?.name,
                    listBy: root?.listBy,
                    relatedUri: relatedEntityClass?.uri,
                    relatedId: relatedEntityClass?.id,
                    children,
                    reverse: true,
                    nodeId: uuidv1(),
                    ...rootExtra
                }
            }
        }));
    }

    @bind
    handleChange(data) {
        this.setState({ data });
    }

    @bind
    openRelatedEntityPage() {
        const { details, setTreeTemplate, setTreeEntity, setSidebarTreeTemplate, setSidebarTreeTemplateExpand } = this.props;
        const { id, name, image, type, modifiedDate, primary } = { ...details };
        
        setSidebarTreeTemplate(null);
        setTreeTemplate(null);
        setSidebarTreeTemplateExpand([]);
        setTreeEntity({ id, name, image, type, modifiedDate, primary });
        
        history.push(`/related-entities`);
    }

    render() {
        const { data, isLoading } = this.state;
        const { formRef, formName, disabled } = this.props;
        return (
            <ExpansionCard
                expanded
                collapsible
                title="Tree"
            >
                {isLoading ? <Loader /> : (
                    <FormGeneratorStyled
                        onChange={this.handleChange}
                        components={treeTemplateFieldDefintions(data, true, this.openRelatedEntityPage)}
                        ref={formRef}
                        data={data}
                        name={formName}
                        disabled={disabled}
                    />
                )}
            </ExpansionCard>
        );
    }
}

export default connect(
    null,
    {
        loadRelationDefinitionsFromIds,
        loadClassificationsByUri,
        loadClassificationById,
        setTreeEntity,
        setTreeTemplate,
        setSidebarTreeTemplateExpand,
        setSidebarTreeTemplate,
        showToastr
    }
)(TreeSection);