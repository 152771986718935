/* @flow */

import { label } from 'app/utils/designer/pipeline/settings/common/commonImportant';

const panels = (settingValues) => [
    {
        type: 'panel',
        properties: {
            header: 'External Data',
            iconName: settingValues.data.iconName,
            iconType: settingValues.data.iconType,
            expanded: true,
            collapsible: false,
        },
        children: [
            {
                type: 'header',
                properties: {
                    variant: 'h6',
                    text: settingValues.data.title
                }
            },
        ],    
    },
    {
        type: 'panel',
        properties: {
            header: 'General Information',
            expanded: true,
        },
        children: [
            label(),
            {
                type: 'textarea',
                properties: {
                    label: 'Text',
                    name: 'data.description',
                    parseAs: 'text'
                }
            },
        ],    
    },
];

export default {
    defaults: {},
    panels: settingValues => panels(settingValues),
};
