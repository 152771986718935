import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Chip } from '@mic3/platform-ui';

const ChipStyled = styled(Chip)`
    && {
        background-color: ${({ theme }) => theme.material.colors.disabled.backgroundColor};
        font-weight: 500;
        text-transform: uppercase;
        border-radius: 2px;
        font-size: 9px;
        height: 20px;
    }
`;

const ChipDisabled = ({
    active,
    ...otherProps
}) => {
    return (
        <>
            {!active && (
                <ChipStyled {...otherProps} label="Disabled" size="small" />
            )}
        </>
    );
};

ChipDisabled.propTypes = {
    active: PropTypes.bool
};

export default memo(ChipDisabled);