import React from 'react';

import Help from 'app/utils/designer/form/settings/common/Help';

export const flexGrow = {
    type: 'typeahead',
    properties: {
        label: 'Flex grow',
        defaultValue: 1,
        options: [{ value: 1, label: '1' }, { value: 0, label: '0' }],
        name: 'properties.formFieldProps.flexGrow',
        help: <Help message="Each field in the form it's a flex item." />,
    }
};

export const justifyContent = {
    type: 'typeahead',
    properties: {
        label: 'Justify content',
        defaultValue: 1,
        options: [
            { value: 'center', label: 'center' },
            { value: 'flex-end', label: 'flex-end' },
            { value: 'flex-start', label: 'flex-start' },
            { value: 'space-between', label: 'space-between' },
            { value: 'space-evenly', label: 'space-evenly' },
        ],
        name: 'properties.gridProps.style.justifyContent',
        help: <Help message="Each field in the form it's a flex item." />,
    }
};
