/**
 *  For DEV Environment target = "https://dev.affectli.com"
 *  For QA Environment target = "https://qa.affectli.com"
 *  For Staging Environment target = "https://staging.affectli.com"
 */

const target = 'http://10.170.12.205';

module.exports = [
    {
        context: ['/iam', '/media', '/chat', '/ufs', '/graphql', '/graphiql', '/affectli', '/pentaho', '/kylo', '/config', '/super-analytics', '/nifi', '/druid', '/grafana'],
        target,
        changeOrigin: true,
        secure: false,
        ws: (context) => context === '/graphql' ? true : false,  // Enable WebSocket for `/graphql` only
    }
]