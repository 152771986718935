/* @flow */

import { gql } from '@apollo/client';

export default gql`
    query loadTeamsQuery($startIndex: Int, $stopIndex: Int, $filterBy: [JSON], $orderBy: [JSON], $excludeBy: [JSON], $countMax: Int) {
        count: count(dataType: "team", filterBy: $filterBy, max: $countMax)
        records: teams(startIndex: $startIndex, stopIndex: $stopIndex, filterBy: $filterBy, orderBy: $orderBy, excludeBy: $excludeBy) {
            id
            name
            modifiedDate
            modifiedBy{
                id
                name
                image
            }
            createdDate
            createdBy{
                id
                name
                image
            }
            owners{
                id
                name
                image
            }
            users{
                user{
                    name
                    id
                    image
                }
            }
        }
    }
`;
