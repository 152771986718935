/* @flow */

import React, { PureComponent } from 'react';
import { muiTheme } from 'app/themes/materialUi';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@mic3/platform-ui';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import StatusRenderer from 'app/components/molecules/Grid/Renderers/Status/StatusRenderer';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { getStr } from 'app/utils/utils';
import { openEntitySidebar } from 'store/actions/entities/entitySidebarActions';

class EventAbout extends PureComponent<Object, Object> {
    static propTypes: Object = {
        details: PropTypes.object.isRequired
    };

    fieldDefinitions = [
        {
            field: 'panel',
            type: 'panel',
            properties: {
                header: 'Event Info',
                expanded: true
            },
            children: [
                {
                    type: 'text',
                    properties: {
                        label: 'Event ID',
                        name: 'id',
                        disabled: true
                    }
                },
                {
                    field: 'time',
                    type: 'date',
                    properties: {
                        label: 'Event Time',
                        name: 'time',
                        disabled: true,
                        format:'DD MMM YYYY, HH:mm'
                    }
                },
                {
                    type: 'custom',
                    properties: {
                        label: 'Status',
                        name: 'status',
                        Component: StatusRenderer,
                        disabled: true
                    }
                },
                {
                    type: 'text',
                    properties: {
                        label: 'Alarm ID',
                        name: 'alarmId',
                        disabled: true
                    }
                },
                {
                    type: 'text',
                    properties: {
                        label: 'Description',
                        name: 'description',
                        disabled: true
                    }
                },
                {
                    type: 'textarea',
                    properties: {
                        title: 'IoT Data',
                        name: 'data',
                        modal: true,
                        useCodeEditor: true,
                        disabled: true,
                        mode: 'JSON'
                    }
                }
            ]
        },
        {
            field: 'panel',
            type: 'panel',
            properties: {
                header: 'Device Info',
                expanded: true
            },
            children: [
                {
                    type: 'displayText',
                    properties: { 
                        text: 'No device found.',
                        isVisible: (data) => !data?.device?.id,
                        style:{
                            color: muiTheme.colors.text.secondary
                        }
                    },
                },
                {
                    type: 'uuid',
                    properties: {
                        label: 'Entity ID',
                        name: 'device.id',
                        disabled: true,
                        isVisible: (data) => !!data?.device?.id
                    }
                },
                {
                    type: 'custom',
                    properties: {
                        label: 'Entity Name',
                        name: 'device.name',
                        isVisible: (data) => !!data?.device?.id,
                        Component: ({ data }) => {
                            const name = getStr(data, 'device.name', '');
                            const id = getStr(data, 'device.id', '');
                            const type = getStr(data, 'device.type', '');
                            return (
                                <Button variant="text" onClick={() => this.props.openEntitySidebar({ title: 'About', id, type })}>
                                    {name}
                                </Button>
                            );
                        },
                        disabled: true
                    }
                }
            ]
        },
        {
            field: 'panel',
            type: 'panel',
            properties: {
                header: 'Event Type',
                expanded: false
            },
            children: [
                {
                    type: 'text',
                    properties: {
                        label: 'Event Type ID',
                        name: 'eventType.id',
                        disabled: true
                    }
                },
                {
                    field: 'time',
                    type: 'text',
                    properties: {
                        label: 'Event Type Name',
                        name: 'eventType.name',
                        disabled: true
                    }
                }
            ]
        },
        {
            field: 'panel',
            type: 'panel',
            properties: {
                header: 'Common',
                expanded: false
            },
            children: [
                {
                    type: 'number',
                    properties: {
                        label: 'Event Translated Severity',
                        name: 'severity',
                        disabled: true
                    }
                },
                {
                    type: 'text',
                    properties: {
                        label: 'Device Original IOT Signal Code',
                        name: 'alarmCode',
                        disabled: true
                    }
                },
                {
                    type: 'text',
                    properties: {
                        label: 'Device Original IOT Signal Type',
                        name: 'alarmType',
                        disabled: true
                    }
                },
                {
                    type: 'date',
                    properties: {
                        label: 'Mi-Stream Time',
                        name: 'streamTime',
                        disabled: true,
                        format:'DD MMM YYYY, HH:mm'
                    }
                },
                {
                    type: 'date',
                    properties: {
                        label: 'Clear Time',
                        name: 'clearTime',
                        disabled: true
                    }
                }
            ]
        },
        {
            field: 'panel',
            type: 'panel',
            properties: {
                header: 'Info',
                expanded: false
            },
            children: [
                {
                    type: 'dateTime',
                    properties: {
                        label: 'Last Modified Date',
                        name: 'modifiedDate',
                        disabled: true
                    }
                },
                {
                    type: 'userTypeahead',
                    properties: {
                        label: 'Last Modified by',
                        name: 'modifiedBy.id',
                        valueField: 'id',
                        disabled: true
                    }
                },
                {
                    type: 'dateTime',
                    properties: {
                        label: 'Created Date',
                        name: 'createdDate',
                        disabled: true
                    }
                },
                {
                    type: 'userTypeahead',
                    properties: {
                        label: 'Created by',
                        name: 'createdBy.id',
                        valueField: 'id',
                        disabled: true
                    }
                }
            ]
        }
    ];

    @bind
    @memoize()
    buildDetails(details) {
        const dataParsed = JSON.stringify(details.data || {}, null, 2);
        return {
            ...details,
            data: dataParsed
        };
    }

    render(): Object {
        const { details } = this.props;
        return <FormGenerator components={this.fieldDefinitions} data={this.buildDetails(details)} />;
    }
}

export default connect(null, { openEntitySidebar })(EventAbout);
