import React, { Fragment, useCallback, useRef } from 'react';
import { muiTheme } from 'app/themes/materialUi';

import { Button, MdiIcon, IconButton } from '@mic3/platform-ui';
import { Menu, MenuItem } from '@material-ui/core';
import { useToggle } from 'app/utils/hook/hooks';

const EntitiesColumnAction = ({ onApply, onSave, onReset, onLoadSaved, onToggleMenu, open, onClose, classes }) => {
    const anchorEl = useRef();
    const [isOpen, toggleMenu] = useToggle();

    const handleOnReset = useCallback(
        (e) => {
            onReset(e);
            toggleMenu();
        },
        [onReset, toggleMenu]
    );

    const handleOnLoadSaved = useCallback(
        (e) => {
            onLoadSaved(e);
            toggleMenu();
        },
        [onLoadSaved, toggleMenu]
    );

    return (
        <Fragment>
            <Button onClick={onApply} variant='text'>
                {' '}
                Apply{' '}
            </Button>
            <Button onClick={onSave} variant='text'>
                {' '}
                Save{' '}
            </Button>
            <IconButton buttonRef={anchorEl} onClick={toggleMenu}>
                <MdiIcon name='dots-vertical' />
            </IconButton>

            <Menu anchorEl={anchorEl.current} open={isOpen} onClose={toggleMenu}>
                <MenuItem onClick={handleOnLoadSaved}>
                    <MdiIcon className={classes.menuIcon} name='content-save' color={muiTheme.colors.text.secondary} />
                    Load saved
                </MenuItem>
                <MenuItem onClick={handleOnReset}>
                    <MdiIcon className={classes.menuIcon} name='refresh' color={muiTheme.colors.text.secondary} />
                    Reset to default
                </MenuItem>
            </Menu>
        </Fragment>
    );
};

export default EntitiesColumnAction;
