/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { loadTasks } from 'store/actions/abox/taskActions';
import { formatOptionsForTask } from 'app/utils/formatter/graphql-formatter';
import { bind } from 'app/utils/decorators/decoratorUtils';

import PageTemplate from 'app/components/templates/PageTemplate';
import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import EntitiesList from 'app/containers/Entities/EntitiesList/EntitiesList';
import { filterDefinitions, defaultFilters, defaultOrder } from 'app/components/organisms/TasksView/TasksView';
import withPermissions from 'app/utils/withPermissions';
import { setDocumentTitle } from 'store/actions/app/appActions';
import { modulesAndPageTitles } from 'app/config/typesConfig';


const VIEW_ID = 'TaskList';

class TaskList extends PureComponent<Object, Object> {
    static propTypes = {
        loadTasks: PropTypes.func.isRequired,
        records: PropTypes.array,
        isLoading: PropTypes.bool,
        totalRecords: PropTypes.number,
    };

    static defaultProps = {
        isLoading: false,
        VirtualListProps: {},
        FiltersProps: {},
    };

    state = { feFilters: [] };
    defaultFilters = defaultFilters;
    defaultOrder = defaultOrder;
    filterDefinitions = filterDefinitions

    columnDefinitions = [
        {
            text: 'Task Name',
            field: 'name',
        },
        {
            text: 'Task ID',
            field: 'id',
        },
        {
            text: 'Due Date',
            field: 'primary.dueDate',
            width: '150px',
        },
        {
            text: 'Assignee',
            field: 'primary.assignee',
            width: '100px',
        },
        {
            text: 'Priority',
            field: 'primary.priority',
        },
        {
            text: 'Progress',
            field: 'primary.progress', 
            align: 'left',
            sortable : false,
        },
        {
            text: 'Status',
            field: 'type',
            renderer:  ({ value }) => (value === 'closedtask' ? 'Closed' : 'Open'),
            align: 'center',
            sortable : false,
        },
        {
            text: 'Classes',
            field: 'classes',
            align: 'center',
            onClickDisabled: true
        },
        {
            text: 'Last modified date',
            field: 'modifiedDate',
        },
        {
            text: 'Modified By',
            field: 'modifiedBy',
        },
        {
            text: 'Created date',
            field: 'createdDate',
        },
        {
            text: 'Created by',
            field: 'createdBy',
        },
        {
            text: 'Process started by',
            field:'primary.variables.INITIATOR',
            width: '150px',
            onClickDisabled: true
        },
        {
            text: 'Relations',
            field: 'relations',
            align: 'center',
            onClickDisabled: true
        },
        {
            text: 'Sharing',
            field: 'sharing',
            width: '120px',
            onClickDisabled: true
        },
        {
            field: 'Actions',
            align: 'right',
            width: '115px',
            onClickDisabled: true  
        }       
    ];

    componentDidMount() {
        this.props.setDocumentTitle(modulesAndPageTitles.abox.tasks);
    }


    @bind
    loadTasks(options) {
        const formattedOptions = formatOptionsForTask(options.options, this.props.userProfile);
        return this.props.loadTasks({ ...formattedOptions });
    }

    render() {
        const { startIndex, isLoading, records, totalRecords, } = this.props;
        return (
            <PageTemplate title="Tasks">
                <ContentArea>
                    <EntitiesList
                        id={VIEW_ID}
                        type="task"
                        headerTitle="Tasks"
                        records={records || []}
                        totalRecords={totalRecords}
                        startIndex={startIndex}
                        isLoading={isLoading}
                        loadData={this.loadTasks}
                        columnDefinitions={this.columnDefinitions}
                        filterDefinitions={this.filterDefinitions}
                        defaultFilters={this.defaultFilters}
                        disableAdd
                    />
                </ContentArea>
            </PageTemplate>
        );
    }
}

export default connect(
    (state: Object) => ({
        isLoading: state.abox.task.list.isLoading,
        startIndex: state.abox.task.list.startIndex,
        records: state.abox.task.list.records,
        totalRecords: state.abox.task.list.count,
        userProfile: state.user.profile
    }),
    { loadTasks, setDocumentTitle },
)(withPermissions(TaskList, 'task'));
