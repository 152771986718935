import { useEffect } from 'react';
import { useSubscription } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';

import { LAST_USED_APPS_SUBSCRIPTION, PROCESS_ASSIGNED_SUBSCRIPTION, TASK_ASSIGNED_SUBSCRIPTION } from 'graphql/app/appSubscriptions';
import { cut } from 'app/utils/string/string-utils';
import { notify } from 'app/utils/notification/notification';
import { getLastUsedProcesses } from 'store/actions/dashboard/dashboardActions';

const AppSubscriptions = () => {
    const id = useSelector(state => state.user?.profile?.id);
    const dispatch = useDispatch();
    const { data: newTask }  = useSubscription(TASK_ASSIGNED_SUBSCRIPTION);
    const { data: newProcess }  = useSubscription(PROCESS_ASSIGNED_SUBSCRIPTION);
    const { data: lastUsedProcesses }  = useSubscription(LAST_USED_APPS_SUBSCRIPTION,{
        variables:{
            id
        }
    });

    useEffect(() => {
        const _lastUsedProcesses = lastUsedProcesses?.onEntityChange?.primary?.lastUsedProcesses;
        if(_lastUsedProcesses?.length) {
            dispatch(getLastUsedProcesses(_lastUsedProcesses));
        }
    },[lastUsedProcesses, dispatch]);

    useEffect(() => {
        const task = newTask?.onAssignedTask || {};
        if (task?.id) {
            notify('New Task Assigned', {
                body: cut(task.name, 30),
                data: { link: `/#/abox/task/${task.id}` },
            });
        }
    }, [newTask]);

    useEffect(() => {
        const process = newProcess?.onAssignedProcess || {};
        if (process?.id) {
            notify('New Process Assigned', {
                body: cut(process.name, 30),
                data: { link: `/#/abox/process/${process.id}` },
            });
        }
    }, [newProcess]);

    return null;
};

export default AppSubscriptions;
