/* @flow */

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Grid, Tooltip } from '@mic3/platform-ui';
import { toUniqueUUID } from 'app/utils/string/string-utils';
import { copyToClipboard } from 'app/utils/classification/classificationUtils';
import { showToastr } from 'store/actions/app/appActions';
import { bind } from 'app/utils/decorators/decoratorUtils';
import { EntityLinkStyledSubTitle, LinkIcon } from 'app/containers/Entities/EntitiesList/EntitiesListItem';


class GridEntityId extends PureComponent<Object, Object> {
    @bind
    copyLinkToClipBoard(e, id: string) {
        e.stopPropagation();
        const {adminBackgroundJobs, valueId} = this.props;
        copyToClipboard(adminBackgroundJobs ? `${window.location.origin}/#/background-job/${valueId}` : id)
            .then(() => {
                this.props.showToastr({ severity: 'success', detail: 'Link copied to clipboard' });
            })
            .catch(() => {
                this.props.showToastr({ severity: 'error', detail: 'Link could not copied to clipboard' });
            });
    }

    @bind
    copyIdToClipBoard(e, id: string) {
        e.stopPropagation();
        copyToClipboard(id)
            .then(() => {
                this.props.showToastr({ severity: 'success', detail: 'ID copied to clipboard' });
            })
            .catch(() => {
                this.props.showToastr({ severity: 'error', detail: 'ID could not copied to clipboard' });
            });
    }


    render() {
        const { value, valueId, fullValue, onClick, label, readOnly, entitiesView, record } = this.props;     

        if(!value || !valueId) {
            return null;
        }
        
        return (
            <Grid container onClick={onClick}>
                <Tooltip  title='Copy ID to clipboard'>
                    <EntityLinkStyledSubTitle
                        variant="caption"
                        noWrap
                        onClick={e => this.copyIdToClipBoard(e, valueId)}
                    >
                        {`#${fullValue || readOnly ? label : toUniqueUUID(label || '')}`}
                    </EntityLinkStyledSubTitle>
                </Tooltip>
                {readOnly ? null :  
                    <Tooltip title='Copy link to clipboard'>
                        <div>
                            <LinkIcon  name="link" size="sm" 
                                onClick={e => this.copyLinkToClipBoard(e, `${window.location.origin}/#${
                    (((record?.data?.type === 'user' || record?.data?.type === 'person') && entitiesView)) 
                        ? `/entities/${record?.data?.type}/${valueId}` : value}`)}/> 
                        </div>
                    </Tooltip>}
            </Grid>
        );
    }
};

export default connect(
    null,
    {
        showToastr
    }
)(GridEntityId);
