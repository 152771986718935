import { gql } from '@apollo/client';

export default gql`
    query settingsPermissionsQuery {
        entities(type: "system_setting") {
            id
            name
        }
    }
`;
