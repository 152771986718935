/* @flow */
import { baseRoutes } from 'app/config/routesConfig';

/**
 * Abox Navigation Menu
 */
const miStreamList = (isAdmin, permissions) => {
    const permissionsSet = new Set(permissions);
    return [
        (isAdmin || permissionsSet.has('mistream.event')) && { name: 'Events Monitor', iconType: 'af', icon: 'event-monitor', to: '/events' },
        (isAdmin || permissionsSet.has('mistream.eventType')) && { name: 'Event Types', iconType: 'af', icon: 'data-source-builder', to: baseRoutes['eventtype'] },
        (isAdmin || permissionsSet.has('designer.pipeline')) && { name: 'IoT Pipeline Monitor', icon: 'pipe', to: '/pipeline-monitor' },
        // (isAdmin || permissionsSet.has('mistream.main.view')) && { name: 'Mi-Stream', iconType: 'af', icon: 'stream', to: "/events/mi-stream" }
        // { isAdmin  &&data-source-builder
        //     <Fragment>
        //         <MenuItem onClick={onClick} name: "Dashboards" iconType='af' icon: "dashboard" to: "/events/dashboards"/>
        //         <Fragment>
        //             <Hr />
        //
        //             <MenuItem onClick={onClick} name: "Edge Manager" icon: "water" to: "/nifi" />
        //             <MenuItem onClick={onClick} name: "Big Data Studio" icon: "lightbulb-on"  to: "/events/cdap" />
        //         </Fragment>
        //     </Fragment>
        // }
    ];
};

export default miStreamList;
