
/* @flow */

import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';
import { muiTheme } from 'app/themes/materialUi';
import { Chip, Divider, Avatar, IconButton, Tooltip }from '@mic3/platform-ui';



import DotMenu from 'app/components/molecules/DotMenu/DotMenu';
import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import Button from 'app/components/atoms/Designer/Button';
import FormDesignerEditor from 'app/containers/Designer/Form/Tabs/FormDesignerEditor/FormDesignerEditor';
import FormDesignerPreview from 'app/containers/Designer/Form/Tabs/FormDesignerPreview/FormDesignerPreview';
import PageTemplate from 'app/components/templates/PageTemplate';
import { loadEntityWorkspaces } from 'store/actions/entities/entitiesActions';
import { getFieldDefinition } from 'app/utils/designer/form/settings/formFieldSettingsUtils';
import { getArray, getStr, isEmpty } from 'app/utils/utils';
import { get } from 'app/utils/lo/lo';
import { normalizeFields, denormalizeFields } from 'app/utils/designer/form/formUtils';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { openEntitySidebar } from 'store/actions/entities/entitySidebarActions';
import { setSelectedElement } from 'store/actions/form/formActions';
import PageNotAllowed from 'app/containers/ErrorPages/PageNotAllowed';
import Breadcrumbs from 'app/components/organisms/Breadcrumbs/Breadcrumbs';
import { redirectTypes } from 'app/config/typesConfig';
import { saveJSON } from 'app/utils/datatable/datatableUtils';
import { setDocumentTitle, showToastr } from 'store/actions/app/appActions';
import { getPermissions } from 'app/config/rolesConfig';
import { groupFields } from 'app/utils/classification/classificationForm';
import Ellipsis from 'app/components/molecules/Ellipsis/Ellipsis';
import Icon from 'app/components/atoms/Icon/Icon';
import { getAttachmentUrl } from 'app/utils/attachments/attachmentsUtils';
import { setTitle } from 'store/actions/sidebar/sidebarActions';
import { openClassSidebar } from 'store/actions/entities/classSidebarActions';



const MoreChipStyled = styled(Chip)`
    background:  ${({ theme }) => theme.material.colors.background.fields} !important;
    & .MuiChip-label {
        max-width: 140px;
    }
`;

const AvatarStyled = styled(Avatar)`
    width: 30px !important;
    height: 30px !important;
    margin-right: 8px;
    font-size: 1rem !important;
`;

const DividerVerticalStyled = styled(Divider)`
    margin: 4px 16px !important;
    height: 24px !important;
    align-self: center !important;
    width: 1px;
    background-color: ${({ theme }) => theme.material.colors.background.divider} !important;
    
`;


class FormDesigner extends PureComponent<Object, Object> {

    static propTypes = {
        match: PropTypes.object.isRequired,
        form: PropTypes.object,
        formDesignerState: PropTypes.object,
    };
    
    state = {
        errors: null,
    };

    formDesignerRef: Object = React.createRef();


    constructor(props) {
        super(props);
        if (props.classification) {
            this.normalizeFields(props);
        }
    }

    componentDidUpdate(prevProps) {
        const { classification, editorState, setDocumentTitle } = this.props;
        const name = classification?.name;
        const prevName = prevProps?.classification?.name;
        if(name && name !== prevName){
            setDocumentTitle(name);
        }
        if (classification?.id && editorState?.id !== classification?.id) {
            this.normalizeFields(this.props);
        }
        
    }
    @bind
    componentWillUnmount(){
        this.props.setSelectedElement(null);
    }

    @bind
    normalizeFields(props) {
        const { classification, saveEditorState } = props;
        if (!classification?.id) {
            return;
        }
        const { id, name } = classification;
        let fields = getArray(classification, 'formDefinition.fields') || [getFieldDefinition('panel')];
        const isLegacy = !!fields[0]?.settings;
        if(isLegacy) {
            fields = normalizeFields(groupFields(getArray(classification, 'formDefinition.fields')));
        }
        saveEditorState({ id, name, fields });

    }

    @bind
    async onSave() {
        const { editorState, isSidebarOpen, sidebarTitle, entityType } = this.props;
        const { id, fields } = editorState || {};
        await this.props.updateClassification({
            id,
            formDefinition: {
                fields: denormalizeFields(fields),
            },
        }, entityType);
        if (isSidebarOpen && sidebarTitle === 'Properties') {
            this.formDesignerRef?.current && this.formDesignerRef.current.refreshPropertiesSidebar(); 
        }
    };

    @bind
    onDotMenuClick(title) {
        const { openEntitySidebar, editorState, id, classification, openClassSidebar, entityType,
            showToastr } = this.props;
        if (title === 'Download') {
            const { name } = classification || {};
            const {fields} = editorState || {};
            const exportedFields = denormalizeFields(fields);
            if(isEmpty(exportedFields)){
                showToastr({ severity: 'error', detail: 'Empty file could not be Downloaded' });
            }else{
                saveJSON(name, denormalizeFields(fields) || []);
            }
            return;
        }
        if (title === 'Sharing' || title === 'About') { 
            const settings = { title, id, entityType };
            return openClassSidebar(settings);
        }
        openEntitySidebar({ title, id, type: 'formdefinition' });
    }

    @bind
    @memoize()
    renderSecondaryActions(errors, canEdit, editorState) {
        let noChildrenIotPanel = false;
        const iotData = editorState?.fields?.filter(field => field?.type === 'iotPanel');
        if(iotData?.length && !iotData?.[0]?.children?.length){
            noChildrenIotPanel = true;
        }
        return (
            <>
                {canEdit && (
                    <Button
                        key={14}
                        disabled={!!errors || noChildrenIotPanel}
                        color="primary"
                        onClick={this.onSave}
                        confirmationModalProps={{
                            message: 'Are you sure you want to save this version?',
                            declineButtonText: 'Cancel',
                            confirmButtonText: 'Save',
                        }}
                        withConfirmation
                    >
                    Save
                    </Button>
                )}
            </>
        );
    }

    @bind
    @memoize()
    buildBreadcrumbs(name, id, entityType) {
        return (
            <Breadcrumbs
                list={[
                    { link: entityType ? `/${redirectTypes['entity']}` : `/${redirectTypes['class']}`, title: entityType ? 'Entity Type' : 'Classes' },
                    { link: entityType ? `/${redirectTypes['entity']}/${id}/about` : `/${redirectTypes['class']}/${id}/about`, title: name },
                    { title: 'Attributes' }
                ]}
            />
        );
    }

    @bind
    @memoize()
    dotMenu(sidebarTitle: string, canEdit) {
        return (
            <DotMenu
                key={13}
                onItemClick={this.onDotMenuClick}
                tooltipTitle='More Options'
                items={[
                    { name: 'Download', icon: 'download' },
                    canEdit && { name: 'file', label: 'Upload', onSelect: this.props.uploadDefinition },

                ].filter(Boolean)}
            />
        );
    }

    @bind
    @memoize()
    renderActions(sidebarTitle, canEdit, sharingDetails) {
        const { users = [], teams = [] } = sharingDetails || {};
        return (
            <>
                <Ellipsis
                    data={[...users, ...teams]}
                    spaces={-14}
                    displaySize={this.props.isMobile ? 3 : 5}
                    renderCount={count => (
                        <MoreChipStyled
                            variant="default"
                            label={`+${count}`}
                            size="small"
                            onClick={() => this.onDotMenuClick('Sharing')}
                        />
                    )}
                    renderItem={
                        ({ name, image, id }: Object) => (
                            <AvatarStyled onClick={() => this.onDotMenuClick('Sharing')} src={image ? getAttachmentUrl(id, 'user', image): null} initials={name} />
                        )
                    }
                />
                <DividerVerticalStyled />
                <Tooltip title="Sharing">
                    <IconButton onClick={() => this.onDotMenuClick('Sharing')}><Icon name="share-variant" /></IconButton>
                </Tooltip>
                <Tooltip title="About">
                    <IconButton onClick={() => this.onDotMenuClick('About')}><Icon hexColor={muiTheme.colors.text.secondary} name="information-outline" /></IconButton>
                </Tooltip>
            </>
        );
    }

    render() {
        const {
            formProperties, isFDStateLoading, saveEditorState, editorState, savePreviewState, designerState, sidebarTitle,
            tabs, classification, isLoading, type, errors, normalizeFieldSettingsPanelComponents, entityType, sharingDetails
        } = this.props;
        const id = getStr(this.props, 'match.params.id') || '';
               
        const permissions = getPermissions(classification && classification.role);
        const canEdit = permissions?.canEdit;
        if (!isLoading && (isEmpty(classification) || !permissions.canView)) {
            return <PageNotAllowed permissionError={true} title={`Class attributes (ID:${id})`}/>;
        }
        const { name  }= classification || {};
        return (
            <PageTemplate title={name} subTitle={`#${id}`}>
                <ContentArea withHeader>
                    <Fragment>
                        {classification ?
                            <Switch>
                                <Route path={entityType ? '/entity-types/:id/attributes'  : '/classifications/:id/attributes'} exact render={() =>
                                    <Redirect to={canEdit ? entityType ? `/entity-types/${id}/attributes/editor` : `/classifications/${id}/attributes/editor` : entityType ? `/entity-types/${id}/preview` :  `/classifications/${id}/preview`}/>}
                                />
                                <Route path={entityType ? '/entity-types/:id/attributes/editor' : '/classifications/:id/attributes/editor'} render={() =>
                                    <FormDesignerEditor
                                        isLoading={isFDStateLoading || isLoading}
                                        ref={this.formDesignerRef}
                                        form={classification}
                                        saveEditorState={saveEditorState}
                                        editorState={editorState}
                                        errors={errors}
                                        onSave={this.onSave}
                                        actions={this.renderActions(sidebarTitle, canEdit, sharingDetails)}
                                        secondaryActions={this.renderSecondaryActions(errors, canEdit, editorState)}
                                        breadcrumbLine={this.buildBreadcrumbs(classification.name, classification.id, entityType)}
                                        tabs={tabs}
                                        afterRightButton={this.dotMenu(sidebarTitle, canEdit)}
                                        formProperties={formProperties}
                                        type={type}
                                        normalizeFieldSettingsPanelComponents={normalizeFieldSettingsPanelComponents}
                                        entityType={entityType}
                                        disabled={!canEdit}
                                    />
                                }
                                />
                                <Route path={entityType ? '/entity-types/:id/attributes/preview' :  '/classifications/:id/attributes/preview'} render={() =>
                                    <FormDesignerPreview
                                        designerState={designerState}
                                        savePreviewState={savePreviewState}
                                        secondaryActions={this.renderSecondaryActions(errors, canEdit)}
                                        breadcrumbLine={this.buildBreadcrumbs(classification.name, classification.id, entityType)}
                                        tabs={tabs}
                                        afterRightButton={this.dotMenu(sidebarTitle, canEdit)}
                                        entityType={entityType}
                                        disabled={!canEdit}
                                    />
                                }
                                />
                            </Switch>
                            : <h2 style={{padding: '10px 20px'}}>Form not found.</h2> }
                    </Fragment>
                </ContentArea>
            </PageTemplate>
        );
    }

}

export default connect(
    (state, props) => ({
        isFDStateLoading: get(state.component.state.FormDesigner, 'isLoading', false),
        isLoading: state.classifications.details.isLoading,
        classification: state.classifications.details.data || {},
        sidebarTitle: state.sidebar.title,
        isSidebarOpen: state.sidebar.isOpen,
        profile: state.user.profile,
        id: getStr(props, 'match.params.id'),
        isMobile:state.global.isMobile,
        sharingDetails: state.entities.sidebar.workspaces.data,

    }),
    {
        openEntitySidebar,
        showToastr,
        loadEntityWorkspaces,
        setSelectedElement,
        setTitle,
        openClassSidebar,
        setDocumentTitle,
    }
)(withTheme(FormDesigner, 'formdefinition'));
