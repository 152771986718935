/* @flow */
import React from 'react';

import {
    buildLeftPanel as buildLeftPanelAction,
    closeLeftPanel as closeLeftPanelAction
} from 'store/actions/leftPanel/leftPanelActions';
import ChatLeftPanel from 'app/containers/LeftPanels/ChatLeftPanel';

export const openChatLeftPanel = ({ title, id, type, view, entityType, isOpen }) => {

    return buildLeftPanelAction({
        title,
        content: (
            <ChatLeftPanel
                title={title}
                id={id}
                entityType={entityType}
                view={view}
                type={type}
            />
        ),
        isOpen
    });
};

export const closeChatLeftPanel = closeLeftPanelAction;
