/* @flow */

import React from 'react';
import { connect } from 'react-redux';

import { loadUserReferences } from 'store/actions/admin/usersActions';
import AbstractUserTypeahead from 'app/components/organisms/Form/Typeahead/abstract/AbstractUserTypeahead';
import ResolveAvatarTypeaheadWrapper from 'app/components/organisms/Form/Typeahead/abstract/ResolveAvatarTypeaheadWrapper';


const UserTypeaheadWrapped = (props) => {
    return <ResolveAvatarTypeaheadWrapper {...props} Typeahead={AbstractUserTypeahead} entityType="user" />;
};


export default connect(
    state => ({
        // users: state.common.autocomplete.users.data
    }), { loadOptions: loadUserReferences }
)(UserTypeaheadWrapped);
