/* @flow */
import React from 'react';
import Alert from 'app/components/molecules/Alert/Alert';
import history from 'store/History';
import { Button } from '@mic3/platform-ui';

export default props => (
    <div style={{ margin: '20px' }}>
        <Alert type="error">
            <div>This Form seems to be out of date, due to other updates done. Please refresh My Apps view.</div>
            <Button color="secondary" variant="text" onClick={() => history.push('/abox/processes-new')}>
                    go to "My Apps"
            </Button>
        </Alert>
    </div>
);
