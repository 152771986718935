/* @flow */

import {
    LOAD_USER_PREFERENCES_STARTED, LOAD_USER_PREFERENCES,
    SAVE_USER_PREFERENCES,
    SAVE_BACKGROUND_PREFERENCES,
    LOAD_USER_PROFILE_STARTED, LOAD_USER_PROFILE,
    UPDATE_USER_PROFILE_PERMISSIONS,
    SAVE_FILTERS_PREFERENCES_STARTED, SAVE_FILTERS_PREFERENCES,
    SAVE_DASHBOARD_PREFERENCES,
    SAVE_TIMELINE_PREFERENECES,
    LOAD_CHAT_CREDENTIALS,
    SAVE_BOARD_PREFERENECES,
    LOAD_USER_PROFILE_GA_STARTED, LOAD_USER_PROFILE_GA,
    LOAD_INTEGRATIONS_STARTED, LOAD_INTEGRATIONS, SAVE_USER_PREFERENCES_STARTED,
    LOAD_LOGIN_ACTIONS_STARTED, LOAD_LOGIN_ACTIONS
} from 'store/actions/admin/usersActions';
import Immutable from 'app/utils/immutable/Immutable';
import { get } from 'app/utils/lo/lo';
// import profileJson from  'offlineMode/profile.json'; // offline mode testing


/**
 * User Preferences reducer.
 *
 * @param state - the current Redux state
 * @param action - the dispatched action
 * @returns the new Redux state
 */
const reducer = (state: Object = Immutable({
    loadingPreferences: false,
    loadingSaveFilterPref: false,
    preferences: null,
    loadingProfile: false,
    profile: null,
    hasGlobalAccount: null,
    userOrganisations: [],
    integrations: {
        isLoading: false,
        records: []
    },
    loginActions: [],
}), action: Object) => {

    const { type, payload, error } = action || {};

    switch ( type ) {
        case LOAD_USER_PREFERENCES_STARTED: case SAVE_USER_PREFERENCES_STARTED:
            return Immutable({ ...state, loadingPreferences: true });

        case SAVE_FILTERS_PREFERENCES_STARTED:
            return Immutable({ ...state, loadingSaveFilterPref: true });

        case LOAD_INTEGRATIONS_STARTED:
            return Immutable({ ...state, integrations: { ...state.integrations, isLoading: true } });

        case LOAD_INTEGRATIONS:
            if (error) {
                return Immutable({ ...state, integrations: { ...state.integrations, isLoading: false } });
            }
            return Immutable({ ...state, integrations: { ...state.integrations, records: payload || [], isLoading: false } });
        case LOAD_USER_PREFERENCES:
            return Immutable({ ...state, preferences: payload || {}, loadingPreferences: false });

        case SAVE_USER_PREFERENCES:
        case SAVE_FILTERS_PREFERENCES:
        case SAVE_DASHBOARD_PREFERENCES:
        case SAVE_TIMELINE_PREFERENECES:
            return Immutable({
                ...state,
                preferences: payload,
                loadingPreferences: false,
                loadingSaveFilterPref: false
            });

        case SAVE_BACKGROUND_PREFERENCES:
        case SAVE_BOARD_PREFERENECES:
            return Immutable({
                ...state,
                preferences: {
                    ...state.preferences,
                    ...payload,
                },
                loadingPreferences: false,
                loadingSaveFilterPref: false
            });

        case LOAD_USER_PROFILE_STARTED:
        case LOAD_USER_PROFILE_GA_STARTED:
            return Immutable({ ...state, loadingProfile: true });

        case LOAD_USER_PROFILE: {
            const profile = get(payload, 'primary.username', null) ? payload : { invalid: true };
            return Immutable({ ...state, profile, loadingProfile: false });
        }
        case UPDATE_USER_PROFILE_PERMISSIONS: {
            if(!payload?.length || !state.profile) return { ...state };
            const permissions = [...get(state, 'profile.permissions', [])];
            permissions.push(...payload);
            const profile = { ...(state.profile || {}), permissions };
            return Immutable({ ...state, profile });
        }

        case LOAD_USER_PROFILE_GA: {
            const { userId, userOrganisations } = payload;
            return Immutable({ ...state, hasGlobalAccount: Boolean(userId), userOrganisations });
        }

        case LOAD_CHAT_CREDENTIALS: {
            const { chatUserId, chatToken } = payload;
            if (error) {
                return state;
            }
            return Immutable({ ...state, chat: { id: chatUserId, token: chatToken } });
        }
        case LOAD_LOGIN_ACTIONS_STARTED: {
            return Immutable({ ...state, isLoading: true });
        }
        case LOAD_LOGIN_ACTIONS: {
            const { records } = payload;
            if (error) return Immutable({ ...state, loginActions: [], isLoading: false });
            return Immutable({ ...state, isLoading: false, loginActions: records });
        }

        default:
            return state;
    }
};

export default reducer;
