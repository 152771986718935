/* @flow */

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { IconButton, Badge, Button } from '@mic3/platform-ui';

import PageTemplate from 'app/components/templates/PageTemplate';
import integration from 'app/config/integrationConfig';
import { loadEntitiesListView } from 'store/actions/entities/entitiesActions';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { toUniqueUUID } from 'app/utils/string/string-utils';
import { setDocumentTitle, showToastr } from 'store/actions/app/appActions';
import { openEntitySidebar } from 'store/actions/entities/entitySidebarActions';
import history from 'store/History';
import { enrichContext } from 'app/utils/designer/form/formUtils';

// import SupersetFrame from 'app/components/superset/SupersetFrame';
import IFrame from 'app/components/atoms/IFrame/IFrame';
import Icon from 'app/components/atoms/Icon/Icon';
import HeaderBar from 'app/components/molecules/HeaderBar/HeaderBar';
import Title from 'app/components/atoms/Title/Title';
import { copyToClipboard } from 'app/utils/classification/classificationUtils';
import { EntityLinkStyledSubTitle, LinkIcon } from 'app/containers/Entities/EntitiesList/EntitiesListItem';
import { getEntityUrl } from 'app/utils/entity/entityUtils';
import { getUnreadMessages } from 'app/utils/entity/entityUtils';
import { getPermissions } from 'app/config/rolesConfig';
import DotMenu from 'app/components/molecules/DotMenu/DotMenu';
import { loadIntegrations } from 'store/actions/admin/usersActions';
import { render, compile } from 'app/utils/template/template';
import affectliSso from 'app/auth/affectliSso';
import { set, get } from 'app/utils/lo/lo';
import { modulesAndPageTitles } from 'app/config/typesConfig';
const { moduleName } = integration;

const StyledTitle = styled(Title)`
    color: ${({theme})=> theme.material.colors.text.primary};
`;

const SyledFrame = styled(IFrame)`
    background-color: white;
    ${({ isSidebarResizing }) => isSidebarResizing && 'pointer-events: none'}
`;

const Contaienr = styled.div`
    display: flex;
    justify-content: space-around;
    height: 90vh;
`;

const TitleContainer = styled.div`
    display: flex;
    margin-left: 2rem;
`;

const Flex = styled.div`
    display: flex;
    align-items: center;
    text-align: center;
`;
const StartedText = styled.div`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 60px;
    line-height: 68px;
    // letter-spacing: -0.5px;
    color: ${({theme})=> theme.material.colors.text.caption};
    margin-left: 2rem;
`;

const IconStyled = styled(Icon)`
    &:before {
        color: ${({theme})=> theme.material.colors.text.caption};
        font-size: 6rem !important;
        font-weight: 100 !important;
        @media (max-width: 480px) {
            margin-left: 1rem;
        }
    }
`;

const SubTitle = styled.div`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 20px;
    color: ${({theme})=> theme.material.colors.text.secondary};
    mix-blend-mode: normal;
    position: absolute;
    margin-top: 1rem;
`;

class IntegrationDetails extends PureComponent<Object, Object> {

    componentDidMount(){
        const name= this.props?.details?.name;
        this.updateDocumentTitle(name);
    }

    componentDidUpdate(prevProps){
        const name =  this.props?.details?.name;
        if(name !== prevProps.details?.name){
            this.updateDocumentTitle(name);
        }
    }

    @bind
    @memoize()
    updateDocumentTitle(name){
        const { setDocumentTitle } = this.props;
        let title = modulesAndPageTitles.integrations;
        if(name){
            title = title + ' - ' + name;
        }
        setDocumentTitle(title);
    }

    @bind
    @memoize()
    buildContext(userProfile) {
        let context = enrichContext({ user: userProfile });
        context = set(context, 'sso.token', affectliSso.getToken());
        context = set(context, 'sso.bearerToken', affectliSso.getBearerToken());
        return context;
    }

    @bind
    @memoize()
    buildUrl(details, context) {
        const src = integration.getUrl(details);
        const attributes = integration.getAttributes(details);
        if (!attributes?.length) {
            return src;
        }
        const urlContext = {};
        attributes.forEach(({ name, value }) => {
            if (name && value) {
                urlContext[name] = get({ context }, value);
            }
        });
        return render(compile(src), urlContext);
    }

    @bind
    copyLinkToClipBoard(e, id: string, type: string) {
        e.stopPropagation();

        copyToClipboard(`${window.location.origin}/#${getEntityUrl(id, type)}`)
            .then(() => {
                this.props.showToastr({ severity: 'success', detail: 'Link copied to clipboard' });
            })
            .catch(() => {
                this.props.showToastr({ severity: 'error', detail: 'Link could not copied to clipboard' });
            });
    }

    @bind
    copyIdToClipBoard(e, id: string) {
        e.stopPropagation();

        copyToClipboard(id)
            .then(() => {
                this.props.showToastr({ severity: 'success', detail: 'ID copied to clipboard' });
            })
            .catch(() => {
                this.props.showToastr({ severity: 'error', detail: 'ID could not copied to clipboard' });
            });
    }

    @bind
    onDotMenuClick(title) {
        const { id, openEntitySidebar, loadIntegrations: reloadList } = this.props;
        const hiddenTabs = ['Classes', 'Relations', 'Location'];
        openEntitySidebar({ title, id, type: integration.type, internal: true, reloadList, hiddenTabs });
    }

    @bind
    renderActions(unreadMessages, permissions = {}) {
        return (
            <>
                <IconButton icon='information' title='Edit task' onClick={() => this.onDotMenuClick('About')}>
                    <Icon name='information' />
                </IconButton>
                {permissions.canComment && (
                    <IconButton title='Open messenger' onClick={() => this.onDotMenuClick('A-Live')}>
                        <Badge color='error' badgeContent={unreadMessages} max={99}>
                            <Icon name='messenger' type='af' />
                        </Badge>
                    </IconButton>
                )}
            </>
        );
    }

    @bind
    @memoize()
    dotMenu(sidebarTitle: string, pathname: string = '') {
        return (
            <DotMenu
                key={13}
                onItemClick={this.onDotMenuClick}
                items={[
                    sidebarTitle !== 'Sharing' && { name: 'Sharing', icon: 'share-variant' },
                    sidebarTitle !== 'Attachments' && { name: 'Attachments', icon: 'attachment' },
                    sidebarTitle !== 'History' && { name: 'History', icon: 'history' },
                ].filter(Boolean)}
            />
        );
    }

    /**
     * @override
     */
    render() {
        const {
            details,
            isMobile,
            subscriptions,
            sidebarTitle,
            location: { pathname },
            userProfile,
            isSidebarResizing
        } = this.props;
        const { id, name, type } = details || {};
        const { permissions, isAdmin } = userProfile;
        const permissionsSet = new Set(permissions || []);
        const userPermissions = getPermissions(details && details.role);
        const context = this.buildContext(userProfile);
        const src = this.buildUrl(details, context);
        const canAdd = isAdmin || permissionsSet.has(integration.permissions.add);

        if (src && isMobile && !integration.isVisibleOnSmallScreen(details)) {
            return (
                <PageTemplate title={moduleName} overflowHidden hasIframe>
                    <Contaienr>
                        <Flex>
                            <StartedText style={{ fontSize: '30px' }}>
                                This site does not support mobile view, please toggle to desktop view
                            </StartedText>
                        </Flex>
                    </Contaienr>
                </PageTemplate>
            );
        }

        const unreadMessages = getUnreadMessages(id, subscriptions, integration.type);

        const Header = id ? (
            <HeaderBar
                left={
                    <TitleContainer>
                        <StyledTitle style={{ paddingRight: '10px' }} as='h2'>
                            {name || 'No Name'}
                        </StyledTitle>
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '1px' }}>
                            <EntityLinkStyledSubTitle 
                                color='secondary' 
                                variant='caption' 
                                component='span' 
                                noWrap
                                title='Copy ID to clipboard'
                                onClick={e => this.copyIdToClipBoard(e, id)}
                            >
                                {`#${toUniqueUUID(id)}`}
                            </EntityLinkStyledSubTitle>
                            <LinkIcon
                                title='Copy link to clipboard'
                                name='link'
                                size='sm'
                                onClick={e => this.copyLinkToClipBoard(e, id, type)}
                            />
                        </div>
                    </TitleContainer>
                }
                right={
                    <>
                        {this.renderActions(unreadMessages, userPermissions)}
                        {this.dotMenu(sidebarTitle, pathname)}
                    </>
                }
            />
        ) : (
            ''
        );
        return (
            <PageTemplate title={moduleName} overflowHidden hasIframe>
                {src ? (
                    <>
                        {Header}
                        <SyledFrame title='System Logs' src={src} isSidebarResizing={isSidebarResizing}></SyledFrame>

                    </>
                ) : (
                    <>
                        {Header}
                        <Contaienr>
                            {id ? (
                                <Flex style={{ flexDirection: 'column', justifyContent: 'center' }}>
                                    <SubTitle style={{ position: 'relative', margin: '0px' }}>
                                        This integration does not have URL, please open its details and add a URL.
                                    </SubTitle>
                                    <Button variant='text' onClick={() => this.onDotMenuClick('About')}>
                                        Click to open details
                                    </Button>
                                </Flex>
                            ) : (
                                <Flex>
                                    <div style={{ display: 'flex', textAlign: 'initial' }}>
                                        <IconStyled style={{ lineHeight: '70px' }} name='plus' />
                                        <StartedText>
                                            Let's get started
                                            <br />
                                            {canAdd && (
                                                <Button variant='text' onClick={() => history.push(`${integration.baseRoute}/add`)}>
                                                    create new integration
                                                </Button>
                                            )}
                                        </StartedText>
                                    </div>
                                </Flex>
                            )}
                        </Contaienr>
                    </>
                )}
                {/* <SupersetFrame
                    title={data?.name}
                    src={url}
                    style={{ backgroundColor: 'white'}}
                /> */}
            </PageTemplate>
        );
    }
}

export default connect(
    (state, ownProps) => {
        const details = state.user.integrations.records.find(({ id }) => id === ownProps?.match?.params?.id);
        return {
            details,
            id: ownProps.match.params.id,
            isMobile: state.global.isMobile,
            subscriptions: state.chat.subscriptions.data,
            sidebarTitle: state.sidebar.title,
            userProfile: state.user.profile,
            isSidebarResizing: state.sidebar.isResizing,
        };
    },
    {
        loadEntitiesListView,
        showToastr,
        openEntitySidebar,
        loadIntegrations,
        setDocumentTitle,
    }
)(IntegrationDetails);
