/* @flow */

import React, { memo } from 'react';
import { muiTheme } from 'app/themes/materialUi';

import EventStartProcess from 'app/containers/Stream/Events/EventActions/EventStartProcess';
import EventUpdateStatus from 'app/containers/Stream/Events/EventActions/EventUpdateStatus';
import ProcessRenderer from 'app/components/molecules/Grid/Renderers/Process/ProcessRenderer';
import { get } from 'app/utils/lo/lo';

/**
 * @public
 * Renders buttons to update the event status
 *
 * @param {Object} props - the Component's properties
 * @return {ReactDOM} - return a JSX Element
 */
const EventActionRenderer = ({ data, color, canEdit, refresh, disableHoverEffect, noStartProcess }: Object) => {
    if (!data) {
        return null;
    }
    const { id, processInstances, time } = data;
    const processDefinitions = get(data, 'eventType.metadata.actions') || [];
    const props = { hexColor: muiTheme.colors.text.secondary, postAction: refresh, disableHoverEffect, data };
    const element = (
        <>
            {canEdit && <EventUpdateStatus id={id} time={time} status="ACK" {...props} />}
            {canEdit && <EventUpdateStatus id={id} time={time} status="DIS" {...props} />}
            {canEdit && !noStartProcess && processDefinitions.length > 0 && (
                <EventStartProcess id={id} processDefinitions={processDefinitions} color={color} disableHoverEffect={disableHoverEffect} data={data} />
            )}
            {(processInstances || []).length > 0 && (
                <ProcessRenderer id={id} processInstances={processInstances} color={color} disableHoverEffect={disableHoverEffect} />
            )}
        </>
    );
    return element;
};

export default memo(EventActionRenderer);
