/* @flow */

import { combineReducers } from 'redux';

import autocomplete from 'store/reducers/common/autocomplete/autocompleteReducer';
import calendar from 'store/reducers/common/calendar/calendarReducer';
import digitalTwin from 'store/reducers/common/digitalTwin/digitalTwinReducer';

export default combineReducers<Object, Object>({
    autocomplete,
    calendar,
    digitalTwin,
});
