import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, CircularProgress, Divider, Typography } from '@mic3/platform-ui';

import { saveUserPreferences } from 'store/actions/admin/usersActions';
import { bind } from 'app/utils/decorators/decoratorUtils';
import { get } from 'app/utils/lo/lo';

import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';

const ButtonStyled = styled(Button)`
    margin-right: 12px !important;
`;

const DividerStyled = styled(Divider)`
    margin: 12px 0 16px !important;
`;

const FormGeneratorStyled = styled(FormGenerator)`
    padding: 0 !important;
`;

class LandingPagePreference extends PureComponent<Object, Object> {
    static propTypes = {
        isLoading: PropTypes.bool,
        saveUserPreferences: PropTypes.func,
        preferences: PropTypes.object
    };

    formRef = React.createRef();

    components: Array<Object> = [
        {
            type: 'text',
            properties: { label: 'URL', name: 'url', defaultValue: get(this.props.location, 'pathname') },
            constraints: { required: true }
        }
    ];

    @bind
    onFormSubmit(event: Event) {
        event.preventDefault();
        this.formRef.current.isValidForm().then(async({ data, errors }) => {
            if (!errors) {
                const { saveUserPreferences, preferences } = this.props;
                await saveUserPreferences({ ...preferences, common: { ...preferences.common, favoriteUrl: data.url } });
                this.props.onClose();
            }
        });
    }

    render(): Object {
        const { isLoading, onClose } = this.props;
        return (
            <ModalDialog
                title="Set current page as my favorite"
                onClose={onClose}
                withoutClose
                actions={
                    isLoading ? (
                        <CircularProgress size={24} color="primary" />
                    ) : (
                        <>
                            <ButtonStyled variant="text" onClick={onClose}>
                                Cancel
                            </ButtonStyled>
                            <Button onClick={this.onFormSubmit}>
                                Save
                            </Button>
                        </>
                    )
                }
            >
                <DividerStyled />
                <Typography variant="body2">Your favorite is open by default when you log in, and opens whenever you click on the “My Favorite” in the navigation menu.</Typography>
                <FormGeneratorStyled components={this.components} ref={this.formRef} />
            </ModalDialog>
        );
    }
}

export default withRouter(connect(
    state => ({
        preferences: state.user.preferences
    }), {
        saveUserPreferences
    }
)(LandingPagePreference));
