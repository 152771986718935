/* @flow */

import ExecutionListenersBuilder from 'app/components/Designer/Builders/ExecutionListenersBuilder/ExecutionListenersBuilder';
import uuidv1 from 'uuid/v1';

export const executionListener = {
    type: 'custom',
    properties: {
        cid: uuidv1(),
        title: 'Execution Listeners',
        name: 'process.primary.definition',
        Component: ExecutionListenersBuilder,
    },
};
