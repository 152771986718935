/* @flow */

import React, { memo, useMemo } from 'react';
import styled from 'styled-components';
import { Breadcrumbs as MuiBreadcrumbs, Typography, Link, Grid } from '@mic3/platform-ui';

const BreadcrumbsStyled = styled(MuiBreadcrumbs)`
    flex-grow: 1;
`;

const BreadcrumbGridStyled = styled(Grid)`
    margin: 0 !important;
    padding: 0 !important;
    flex-grow: 1;

    & li:last-child {
        flex-grow: 1;
        width: 1px;
        & .MuiTypography-root {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            flex: 1 1 auto;
        }
    }
`;

const Title = styled(Typography)`
    color: ${({ theme }) => theme.material.colors.text.secondary};
    flex-grow: 0 !important;
`;
const SubTitle = styled(Typography)`
      margin-right: 4px !important;
      color: ${({ theme }) => theme.material.colors.text.primary};
      flex-grow: 0 !important;
`;

const LinkStyled = styled(Link)`
      margin-left: 4px !important;
      flex-grow: 0 !important;
      color: ${({ theme }) => theme.material.colors.text.label} !important;
`;

const BreadcrumbsV2 = ({ list, withGoBack, className, goBack }) => {
    const breadcrumbsList = useMemo(() => (list || []).map((brcrmb, i) => (
        <Grid key={i} container wrap="nowrap" item >
            {brcrmb.subTitle && <SubTitle variant="subtitle1">{brcrmb.subTitle}</SubTitle>}
            <Title variant="subtitle1">{brcrmb.title}</Title>
            {brcrmb.link && <LinkStyled to={brcrmb.link} >
                <Typography variant="subtitle1">{brcrmb.linkTitle}</Typography>
            </LinkStyled>}
            {brcrmb.onClick && <LinkStyled onClick={brcrmb.onClick} >
                <Typography variant="subtitle1">{brcrmb.linkTitle}</Typography>
            </LinkStyled>}
        </Grid>
    )), [list]);

    return (
        <BreadcrumbGridStyled alignItems="center" wrap="nowrap" container>
            <BreadcrumbsStyled className={className}>
                {breadcrumbsList}
            </BreadcrumbsStyled>
        </BreadcrumbGridStyled>
    );
};

export default memo(BreadcrumbsV2);
