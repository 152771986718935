/* @flow */

export default `
    id
    name
    type
    image
    createdDate
    iconName
    iconColor
    iconType
    primary
    description
    primaryClass {
        entityAppearance
        openInModule
        entityTabs
        formDefinition
        parents {
            formDefinition
        }
        digitalTwins {
            name
        }
    }
    primary
    classes {
        id
        name
        uri
        color
    }
    attributes
    relations {
        isReverseRelation
    }
    workspaces {
      isSystem
      team { id }
      user { id }
    }
    active
    modifiedDate
    modifiedBy {
        id
        name
        image
    }
    createdBy {
        id
        name
        image
    }
    createdDate
`;
