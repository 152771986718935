/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { AutocompleteLazy, ListItemText } from '@mic3/platform-ui';

import { bind } from 'app/utils/decorators/decoratorUtils';
import Icon from 'app/components/atoms/Icon/Icon';
import { TypeaheadListItem } from 'app/components/organisms/Form/Typeahead/abstract/TypeaheadListItem';
import { searchPlaces } from 'app/utils/maps/geo';
import styled from 'styled-components';

const StyledListItemText = styled(ListItemText)`
        span,p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
`;

/**
 * Search a location
 */
class LocationTypeahead extends PureComponent<Object, Object> {
    static propTypes = {
        /* eslint-disable-next-line react/forbid-foreign-prop-types */
        ...AutocompleteLazy.propTypes,
        options: PropTypes.array
    };

    constructor(props: Object) {
        super(props);
        this.state = {
            isLoading: false
        };
    }

    @bind
    optionTemplate({ display_name, address }: Object) {
        const { state = '', country_code = '', country } = address || {};
        const adrs = state && country ? `${state}, ${country}` : country && country_code && `${country}, ${country_code}`;
        return {
            option: (
                <TypeaheadListItem ContainerComponent="div" dense disableGutters>
                    <Icon name="map-marker" />
                    <StyledListItemText primary={display_name} secondary={adrs ? adrs : country || ''} />
                </TypeaheadListItem>
            ),
            label: display_name
        };
    }

    @bind
    async fetchData(searchText: any) {
        this.setState({ isLoading: true });
        const response = await searchPlaces(searchText);
        this.setState({ isLoading: false });
        if (response.result) {
            return response.result;
        }
    }

    render() {
        const { placeholder, ...rest } = this.props;
        return (
            <AutocompleteLazy
                {...rest}
                VirtualListProps={{
                    itemSize: 60,
                }}
                isLoading={this.state.isLoading}
                optionTemplate={this.optionTemplate}
                placeholder={placeholder || 'Enter a location...'}
                fetchData={this.fetchData}
            />
        );
    }
}

export default LocationTypeahead;
