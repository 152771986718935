/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import history from 'store/History';
import { loadEntitiesListView } from 'store/actions/entities/entitiesActions';

import { entitiesDefaultFilterDefinitions } from 'app/utils/entity/entityUtils';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';

import PageTemplate from 'app/components/templates/PageTemplate';
import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import EntitiesList from 'app/containers/Entities/EntitiesList/EntitiesList';
import { modulesAndPageTitles } from 'app/config/typesConfig';
import { setDocumentTitle } from 'store/actions/app/appActions';

const VIEW_ID = 'UsersListView';

class UsersListView extends PureComponent<Object, Object> {
    static propTypes = {
        loadEntitiesListView: PropTypes.func.isRequired,
        records: PropTypes.array,
        isLoading: PropTypes.bool,
        totalRecords: PropTypes.number
    };

    static defaultProps = {
        isLoading: false,
        VirtualListProps: {},
        FiltersProps: {}
    };

    defaultFilters = { active: true }

    columnDefinitions = [
        {
            text: 'Name',
            field: 'name',
        },
        {
            text: 'User ID',
            field: 'id',
        },
        {
            text: 'Username',
            field: 'primary.username',
            style: { width: '160px' },
        },
        {
            text: 'Email',
            field: 'primary.email',
            style: { width: '150px' }
        },
        {
            text: 'Description',
            field: 'description',
        },
        {
            text: 'Icon',
            field: 'iconName',
        },
        {
            text: 'Status',
            field: 'active',
        },
        {
            text: 'Last Updated',
            field: 'modifiedDate',
        },
        {
            text: 'Modified By',
            field: 'modifiedBy'
        },
        {
            text: 'Created Date',
            field: 'createdDate',
        },
        {
            text: 'Created by',
            field: 'createdBy'
        },
    ];

    componentDidMount() {
        this.props.setDocumentTitle(modulesAndPageTitles.adminConsole.users);
    }

    @bind
    @memoize()
    goToDetails(id) {
        history.push(`user-management/${id}`);
        return true;
    }

    render() {
        const { startIndex, isLoading, loadEntitiesListView, records, totalRecords } = this.props;
        return (
            <PageTemplate title="Users">
                <ContentArea>
                    <EntitiesList
                        id={VIEW_ID}
                        type="user"
                        headerTitle="Users"
                        records={records}
                        totalRecords={totalRecords}
                        startIndex={startIndex}
                        isLoading={isLoading}
                        loadData={opts => loadEntitiesListView({...opts, type: 'user'})}
                        columnDefinitions={this.columnDefinitions}
                        filterDefinitions={entitiesDefaultFilterDefinitions(['user'])}
                        defaultFilters={this.defaultFilters}
                        goToDetails={this.goToDetails}
                        module={'user-management'}
                        downloadable
                        uploadable
                    />
                </ContentArea>
            </PageTemplate>
        );
    }
}

export default connect(
    (state: Object) => ({
        records: state.entities.entities.records,
        totalRecords: state.entities.entities.count,
        isLoading: state.entities.entities.isLoading,
        startIndex: state.entities.entities.startIndex,
    }),
    { loadEntitiesListView, setDocumentTitle }
)(UsersListView);
