/* @flow */

import React, { PureComponent, Fragment, useCallback, useState, useRef, memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styledComponents from 'styled-components';
import { Menu, MenuItem, MdiIcon, IconButton, Tooltip } from '@mic3/platform-ui';

import UserEntityLink from 'app/components/atoms/Link/UserEntityLink';
import VirtualListItem from 'app/components/molecules/VirtualList/VirtualListItem';
import VirtualListManaged from 'app/components/molecules/VirtualList/VirtualListManaged';
import PageTemplate from 'app/components/templates/PageTemplate';
import Filters from 'app/components/organisms/Filters/Filters';
import ListItem from 'app/components/molecules/List/ListItem';
import Icon from 'app/components/atoms/Icon/Icon';
import AddForm from 'app/components/Designer/Modals/AddForm';
import DuplicateForm from 'app/components/Designer/Modals/DuplicateForm';
import FormLink from 'app/components/atoms/Link/FormLink';
import Layout from 'app/components/molecules/Layout/Layout';
import { loadDesignerForms, createFormDefinition } from 'store/actions/designer/designerFormActions';
import { fromNow } from 'app/utils/date/date';
import { useToggle } from 'app/utils/hook/hooks';
import { get } from 'app/utils/lo/lo';
import withPermissions from 'app/utils/withPermissions';
import { commonEntitiesListFilters } from 'app/utils/entity/entityUtils';
import { modulesAndPageTitles } from 'app/config/typesConfig';
import { setDocumentTitle } from 'store/actions/app/appActions';

const ListItemStyled = styledComponents(ListItem)`
width: 100%;
max-width: 1000px;
margin: 0 auto;
`;

const useToggleMenu = (showMenu) => {
    const [isOpen, show] = useState(false);
    const toggle = useCallback(() => {
        showMenu(false);
        show(!isOpen);
    }, [isOpen, showMenu, show]);
    return [isOpen, toggle];
};

const FormItem = memo(({ form, onActionComplete, canAdd }) => {
    const { name, id, modifiedBy, modifiedDate } = form;
    const anchorEl = useRef(null);
    const [isMenuOpen, toggleMenu, showMenu] = useToggle();
    const [isDuplicateFormOpen, toggleDuplicateForm] = useToggleMenu(showMenu);
    return (
        <ListItemStyled
            component={<Icon name="ballot" size="lg" />}
            title={<FormLink id={id}>{name}</FormLink>}
            subTitle={
                <Fragment>
                    #{id}
                    {
                        modifiedBy &&
                        <div>
                            Updated By <UserEntityLink id={modifiedBy.id}>{modifiedBy.name}</UserEntityLink>,
                            {' '}{fromNow(modifiedDate)}
                        </div>
                    }
                </Fragment>
            }
            actions={
                canAdd && (
                    <Fragment>
                        <IconButton buttonRef={anchorEl} onClick={toggleMenu}><MdiIcon name="dots-vertical" /></IconButton>
                        <Menu open={isMenuOpen} anchorEl={anchorEl.current} onClose={toggleMenu} >
                            <MenuItem onClick={toggleDuplicateForm}>Duplicate form</MenuItem>
                        </Menu>
                        { isDuplicateFormOpen && <DuplicateForm form={form} onClose={toggleDuplicateForm} onDuplicate={onActionComplete} /> }
                    </Fragment>
                )
            }

            raised
        />
    );
});

/**
 * View to display assigned task list
 */
class Forms extends PureComponent<Object, Object> {

    virtualListRef = React.createRef();

    /**
     * @const propTypes - describes the properties of the component
     * @const defaultProps - define the defaults values of the properties
     * @const filterDefinitions -definition for columns that we need to display in our grid
     */
    static propTypes = {
        isLoading: PropTypes.bool,
        records: PropTypes.array,
        startIndex: PropTypes.number,
        totalRecords: PropTypes.number,
        loadDesignerForms: PropTypes.func.isRequired,
        createFormDefinition: PropTypes.func.isRequired,
    };

    static defaultProps = {
        isLoading: false
    };

    state = { isAddFormOpen: false };

    breadcrumb = [{ title: 'Form Designer' }]
    filterDefinitions: Array<Object> = [
        { field: 'name', type: 'text', properties: { label: 'Name', name: 'name', opSelector: true }},
        { field: 'id', type: 'uuid', properties: { label: 'ID', name: 'id', opSelector: true }},
        ...commonEntitiesListFilters
    ];
    searchBar = ['name', 'id'];
    defaultOrder = [{ field: 'createdDate', direction: 'desc nulls last' }];

    componentDidMount() {
        this.props.setDocumentTitle(modulesAndPageTitles.designer.forms);
    }

    renderComponent = ({ style, index, data, resize }) => {
        return (
            <VirtualListItem style={style} key={index} index={index} resize={resize} padding={15}>
                <FormItem canAdd={this.props.canAdd} form={data} onActionComplete={get(this.virtualListRef, 'current.resetView')} />
            </VirtualListItem>
        );
    }

    toggleAddForm = () => this.setState({ isAddFormOpen: !this.state.isAddFormOpen });

    render() {
        const { canAdd, totalRecords, records, isLoading, startIndex, loadDesignerForms, createFormDefinition } = this.props;
        const { isAddFormOpen } = this.state;
        const total = totalRecords || records.length || 0;
        return (
            <PageTemplate title="Forms">
                <Layout>
                    <Filters
                        id="DesignerForms"
                        filterDefinitions={this.filterDefinitions}
                        defaultOrder={this.defaultOrder}
                        searchBar={this.searchBar}
                        breadcrumb={this.breadcrumb}
                        rightToolbar={canAdd && (
                            <>
                                <Tooltip title="Add form">
                                    <IconButton onClick={this.toggleAddForm}>
                                        <MdiIcon name="plus" />
                                    </IconButton>
                                </Tooltip>
                            </>
                        )}
                    >
                        {(filterBy, orderBy) => (
                            <VirtualListManaged
                                ref={this.virtualListRef}
                                renderComponent={this.renderComponent}
                                itemSize={121}
                                itemCount={total}
                                loadData={loadDesignerForms}
                                isLoading={isLoading}
                                startIndex={startIndex || 0}
                                filterBy={filterBy}
                                orderBy={orderBy}
                                list={records}
                                maxWidth="1024"
                                title={`${total >= 1000 ? '999+' : total } Forms`}
                            />
                        )}
                    </Filters>
                </Layout>
                {isAddFormOpen && <AddForm onClose={this.toggleAddForm} addForm={createFormDefinition} />}
            </PageTemplate>
        );
    }
}

export default connect(state => ({
    isLoading: state.designer.forms.isLoading,
    startIndex: state.designer.forms.startIndex,
    records: state.designer.forms.records,
    totalRecords: state.designer.forms.count,
}), {
    loadDesignerForms, createFormDefinition, setDocumentTitle,
})(withPermissions(Forms, 'formdefinition'));
