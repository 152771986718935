import React, { useCallback, useRef, useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { muiTheme } from 'app/themes/materialUi';
import { TextField } from '@mic3/platform-ui';

import { debounce as debounce2 } from 'app/utils/utils';

import Icon from 'app/components/atoms/Icon/Icon';

const SearchWrapper = styled.div`
    position: sticky;
    z-index: 1;
    top: 0px;           
    height: 48px;
    min-height: 48px;
    display: flex;
    align-items: center;
    ${({ type }) => (type === 'kanbanboards' || type === 'beta-kanbanboards' ? 'pading: 0 16px !important;' : 'margin: 0 16px;')}
    background: ${({ theme }) => theme.material.colors.background.default};
    border-top: 1px solid ${({ theme }) => theme.material.colors.background.divider};
    border-bottom: 1px solid ${({ theme }) => theme.material.colors.background.divider};
`;

const StyledIcon = styled(Icon)`
    ${({ entityType }) => (entityType === 'kanbanboards' || entityType === 'beta-kanbanboards' ? 'margin-left: 16px !important;' : '')}
`;

const StyledTextField = styled(TextField)`
    margin-left: 5px !important;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    .MuiInputBase-input {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        mix-blend-mode: normal;
      
    }
    .MuiInputAdornment-root{
        .mdi-close::before {
            color: ${({theme})=> theme.material.colors.text.secondary} !important;
        }
    }
`;

const LeftPanelSearch = ({ searchValue, placeholder, onSearch, type }) => {
    const searchInput = useRef();
    const [value, setValue] = useState(searchValue);
    
    const onChangeSearch = useCallback( // eslint-disable-line
        debounce2((value) => {
            onSearch(value);
        }, 300),
        [onSearch]
    );

    const handleChange = useCallback((event) => {
        setValue(event.target.value);   
    }, []);

    useEffect(() => {
        onChangeSearch(value);
    }, [value, onChangeSearch]);

    useEffect(() => {
        setValue(searchValue);
    }, [searchValue]);

    return (
        <SearchWrapper type={type}>
            <>
                <StyledIcon hexColor={muiTheme.colors.appNav.linkIconColor} name='magnify' entityType={type} />
                <StyledTextField
                    onChange={handleChange}
                    value={value}
                    variant='standard'
                    margin='none'
                    placeholder={placeholder}
                    InputProps={{ disableUnderline: true }}
                    inputRef={searchInput}
                />
            </>
        </SearchWrapper>
    );
};

LeftPanelSearch.propTypes = {
    searchValue: PropTypes.string,
    onSearch: PropTypes.func.isRequired,
    placeholder: PropTypes.string
};

LeftPanelSearch.defaultProps = {
    placeholder: 'Search...'
};

export default memo(LeftPanelSearch);