/* @flow */
import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { IconButton, Tooltip, Badge, Grid, Avatar } from '@mic3/platform-ui';
import { muiTheme } from 'app/themes/materialUi';

import history from 'store/History';
import { toggleChat, toggleNav, toggleNotifications,openTour, showToastr } from 'store/actions/app/appActions';
import { loadAppsLauncher } from 'store/actions/abox/myAppsActions';

import Title from 'app/components/atoms/Title/Title';
import PopupMenu from 'app/components/molecules/PopupMenu/PopupMenu';
import Icon from 'app/components/atoms/Icon/Icon';
import AppsLauncher from 'app/components/organisms/AppLauncher/AppLauncher';
import NestedMenu from 'app/components/molecules/NestedMenu/NestedMenu';
import AccountPopup from '../AccountPopup/AccountPopup';

import { isIframe } from 'app/utils/env';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { deepEquals, getStr } from 'app/utils/utils';
import { appModules } from 'app/config/appLauncherConfig';
import getHelpMenu from 'app/config/helpConfig';
import { Tutorial_Pages } from 'app/config/tourConfig';
import { getAttachmentUrl } from 'app/utils/attachments/attachmentsUtils';
import { parseVersionLabel } from 'app/utils/app/appUtils';
import LandingPagePreference from './Modals/LandingPagePreference';
import { ReactPWAInstallContext } from 'app/containers/Pwa/PwaIndex';

const HeaderStyle = styled.header`
    display: ${({ $expanded }) => $expanded ? 'none' : 'block'};
    grid-area: gTopbar;
    width: 100%;
    max-width: 100%;
    z-index: 1100;
    height: ${( { height, theme } ) => theme && height ? height : theme.header.height };
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08), 0px 5.5px 3px rgba(0, 0, 0, 0.26);
    & .inner {
        display: grid;
        grid-template-columns: auto 1fr auto;
        align-items: center;
        position: relative;
        max-width: 100%;
        color: ${( { textColor, theme } ) => theme && textColor ? theme.color[ textColor ] : theme.header.textColor };
        font-size: inherit;
        min-height: ${( { height, theme } ) => theme && height ? height : theme.header.height };
        padding: 0 .8rem;
        background: ${( { color, theme } ) => color || theme.material.colors.background.navigation };
        z-index: 5;
    }
`;

const TitleContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    flex-direction: column;
    align-items: left;
    white-space: nowrap;
    position: relative;
    cursor: ${ props => (props.headerInfo || []).length ? 'pointer' : 'auto' };
    text-overflow: ellipsis;
    overflow: hidden;
`;

const HeaderTitle = styled(Title)`
    white-space: nowrap;
    overflow: hidden;
    margin: 0;
    color: ${( { theme } ) => theme.header.textColor };
`;

const HeaderInfoArrow = styled(Icon)`
    position: absolute;
    bottom: -11px;
    display: none;
    &::before {
      color: ${( { theme } ) => theme.header.textColor };
      z-index: 10;
    }
`;

const HeaderColumnMenu = styled(Grid)`
    margin-left: -8px;
    padding-right: 4px;
`;

const ProfileBox = styled.div`
    border: 2px solid ${( { theme } ) => theme.header.borderAvatar };
    display: flex;
    border-radius: 32px;
    margin-left: 12px;
    padding: 4px;
    cursor: pointer;
    justify-content: space-between;
`;

const ProfileAvatar = styled(Avatar)`
    && {
        height: 32px;
        width: 32px;
    }
`;

const OrgAvatar = styled(Avatar)`
    && {
        width: 64px;
        height: 32px;
        border-radius: 32px;
        margin-right: 8px;
    }
`;

const IconStyled = styled(Icon)`
    background: ${({ theme }) => theme.material.colors.gradients.headerIcons};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding: 0 8px 0 0;
`;

/**
 * The main application header
 */
class AppHeader extends PureComponent<Object, Object> {
    static propTypes = {
        app: PropTypes.object,
        title: PropTypes.string,
        subTitle: PropTypes.string,
        headerInfo: PropTypes.object,
        toggleNav: PropTypes.func,
        loadAppsLauncher: PropTypes.func.isRequired,
        profile: PropTypes.object.isRequired,
        recordsLauncher: PropTypes.array.isRequired,
        subscriptions: PropTypes.arrayOf(PropTypes.shape({
            unread: PropTypes.number,
            rid: PropTypes.string,
            name: PropTypes.string,
            updatedAt: PropTypes.string,
            rel: PropTypes.shape({
                id: PropTypes.string,
                type: PropTypes.string,
            })
        })),
        unreadCount: PropTypes.number
    };

    static contextType = ReactPWAInstallContext;

    canSeeAlive = false;

    constructor(props) {
        super(props);
        this.state = {
            showInfo: false,
            appsAnchorEl: null,
            helpAnchorEl: null,
            accountAnchorEl: null,
            unreadCount: 0,
            showFavoritesModal: false,
            helpMenu:[],
        };
        const { permissions, isAdmin } = props.profile;
        const permissionsSet = new Set(permissions || []);
        this.canSeeAlive = isAdmin || permissionsSet.has('alive.alive');
        this.props.loadAppsLauncher();
    }

    componentDidMount() {
        const { subscriptions, currentRoomId } = this.props;
        this.setUnreadCount(subscriptions, currentRoomId);
    }

    componentDidUpdate(prevProps){
        const { subscriptions, currentRoomId } = this.props;
        const { subTitle } = this.props.app.headers;
        const prevsSubTitle = prevProps.app.headers.subTitle;

        if (prevsSubTitle !== subTitle && this.state.showInfo){
            this.setState({ showInfo: false });
        }

        if (!deepEquals(subscriptions, prevProps.subscriptions)) {
            this.setUnreadCount(subscriptions, currentRoomId);
        }
    }

    @bind
    toggleInfo() {
        this.setState({ showInfo: !this.state.showInfo });
    };

    @bind
    setUnreadCount(subscriptions: Array<Object>, currentRoomId) {
        if (this.canSeeAlive) {
            const unreadCount = subscriptions
                .filter(({ unread, rel }) => unread && rel?.id !== currentRoomId)
                .reduce((accum, subscription) => {
                    return accum + subscription.unread;
                }, 0);
            this.setState({ unreadCount: unreadCount });
        }
    }

    @bind
    openAppsLauncher(e) {
        this.setState({ appsAnchorEl: e.currentTarget });
    };

    @bind
    closeAppsLauncher() {
        this.setState({ appsAnchorEl: null });
    };

    @bind
    openAccount(e) {
        this.setState({ accountAnchorEl: e.currentTarget });
    };

    @bind
    closeAccount() {
        this.setState({ accountAnchorEl: null });
    };

    @bind
    openHelp(e) {
        this.setState({ helpAnchorEl: e.currentTarget });
    };

    @bind
    closeHelp() {
        this.setState({ helpAnchorEl: null });
    };

    @bind
    openAlive() {
        history.push('/abox/alive');
    }

    @bind
    @memoize()
    getCurrentModule(title) {
        return appModules(true).find(mod => mod.name === title) || {};
    }

    @bind
    handleOpenFavoritesModal() {
        this.setState({ showFavoritesModal: true });
        this.closeAccount();
    }

    @bind
    handleCloseFavoritesModal() {
        this.setState({ showFavoritesModal: false });
    }

    @bind
    addTourAction(tourAction, page){
        const {learningUrl} = this.props;
        return getHelpMenu(learningUrl).map((menu) => {
            if(menu.icon === 'web') {
                return {
                    ...menu,
                    label: `${menu.label} ${page === 'abox'? 'A-Box' : page}`,
                    onClick: () => tourAction()
                };
            }
            return menu;
        });
    };

    @bind
    addInstallPwa(helpMenu){
        const { pwaInstall, supported, isInstalled } = this.context;

        const handleClick = ()=>{   
            pwaInstall({
                title: 'Add to homescreen',
            })
                .then(() => alert('App installed successfully or instructions for install shown'))
                .catch(() => alert('User opted out from installing'));
        };

        if(!isInstalled() && supported()){
            const indexPWA = helpMenu.length - 1;
            helpMenu.splice(indexPWA,0,{
                label: 'Install Affectli to device',
                icon: 'download',
                onClick: () => handleClick()
            });
        }
        return  helpMenu;
    }

    render() {
        const { unreadCount, showFavoritesModal } = this.state;
        const { version, app: { headers }, organisation, recordsLauncher, profile, organisationImage,
            isMobile, permissions, className, openTour, hasGlobalAccount, userOrganisations, online, showToastr, expanded, learningUrl } = this.props;
        const { title, headerInfo, actions, menuItems } = headers;
        const profileImage = profile.image && getAttachmentUrl(profile.id, 'user', profile.image);
        const currentModule = this.getCurrentModule(title);
        const tutorialPage = Tutorial_Pages.find( page => page === window.location.hash.replace('#/','').trim());
        let helpMenu =  tutorialPage? this.addTourAction(openTour, tutorialPage) : getHelpMenu(learningUrl).filter( page => !page.label.includes('tour'));
        helpMenu = this.addInstallPwa(helpMenu);
      
        return (
            <HeaderStyle className={className} $expanded={expanded}>
                <div className="inner">
                    <HeaderColumnMenu  data-tut='reactour__navbar'>
                        { !isIframe &&
                            <Tooltip title="Main Menu">
                                <IconButton onClick={this.props.toggleNav} className="app-nav-menu">
                                    <Icon name="menu"  hexColor={muiTheme.colors.appHeader.iconColor} className="app-nav-menu"/>
                                </IconButton>
                            </Tooltip>
                        }
                    </HeaderColumnMenu>
                    <TitleContainer  onClick={this.toggleInfo} headerInfo={headerInfo}>
                        { title && title ? (
                            <Grid container alignItems="center">
                                <IconStyled size="lg" type={currentModule.iconType} name={currentModule.icon} />
                                {!isMobile && <HeaderTitle forwardedAs="h1">{title}</HeaderTitle>}
                            </Grid>
                        ) : <Icon type="af" name="logo" size="lg" /> }
                        { (headerInfo || []).length > 0 && <HeaderInfoArrow name="arrow-down-bold" size="xs" /> }
                    </TitleContainer>
                    <Grid container alignItems="center">
                        { actions }
                        {
                            menuItems &&
                            <PopupMenu right content={menuItems}>
                                <IconButton>
                                    <Icon name="dots-vertical" />
                                </IconButton>
                            </PopupMenu>
                        }
                        {
                            online ? (
                                <>
                                    {!isMobile && (
                                        <Tooltip title="Help">
                                            <IconButton  data-tut='reactour__help' onClick={this.openHelp}>
                                                <Icon name="help-circle-outline" hexColor={muiTheme.colors.appHeader.iconColor} />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    <Tooltip title="Launcher">
                                        <IconButton data-tut='reactour__launcher' onClick={this.openAppsLauncher}>
                                            <Icon name="dots-grid" hexColor={muiTheme.colors.appHeader.iconColor} />
                                        </IconButton>
                                    </Tooltip>
                                    {
                                        this.canSeeAlive &&
                                            <Tooltip title={`${unreadCount > 0 ? 'View' : 'No'} Unread Messages`}>
                                                <IconButton data-tut='reactour__notification' onClick={this.openAlive}>
                                                    {unreadCount > 0 ? (
                                                        <Badge badgeContent={unreadCount} color="primary">
                                                            <Icon name="bell-ring-outline" hexColor={muiTheme.colors.appHeader.iconColor} />
                                                        </Badge>
                                                    ) : (
                                                        <Icon name="bell-ring-outline" hexColor={muiTheme.colors.appHeader.iconColor} />
                                                    )}
                                                </IconButton>
                                            </Tooltip>
                                    }
                                    <Tooltip title={`${profile.name}  is on ${organisation.name}`}>
                                        <ProfileBox data-tut='reactour__profile' onClick={this.openAccount}>
                                            {!isMobile && <OrgAvatar src={organisationImage} size="lg" initials={organisation.name} />}
                                            <ProfileAvatar src={isMobile ? organisationImage : profileImage} size="lg" initials={profile.name} />
                                        </ProfileBox>
                                    </Tooltip>
                                </>
                            ) : (
                                <Tooltip title="You are offline">
                                    <IconButton>
                                        <Icon name="signal-off" />
                                    </IconButton>
                                </Tooltip>
                            )
                        }
                    </Grid>
                </div>
                <NestedMenu
                    items={parseVersionLabel(helpMenu, version)}
                    open={Boolean(this.state.helpAnchorEl)}
                    anchorEl={this.state.helpAnchorEl}
                    onClose={this.closeHelp}
                />
                <AppsLauncher
                    modules={appModules(profile.isAdmin, permissions)}
                    apps={recordsLauncher}
                    anchorEl={this.state.appsAnchorEl}
                    onClose={this.closeAppsLauncher}
                />
                <AccountPopup
                    showToastr={showToastr}
                    online={online}
                    hasGlobalAccount={hasGlobalAccount}
                    userOrganisations={userOrganisations}
                    profile={profile}
                    anchorEl={this.state.accountAnchorEl}
                    onClose={this.closeAccount}
                    onClickFavorites={this.handleOpenFavoritesModal}
                />
                {showFavoritesModal && <LandingPagePreference onClose={this.handleCloseFavoritesModal} />}
            </HeaderStyle>
        );
    }
}

const mapStateToProps = state => ({
    app: state.app,
    permissions: state.user.profile.permissions,
    profile: state.user.profile,
    online: state.app.online,
    organisation: state.app.organisation,
    organisationImage: state.app.organisationImage,
    version: state.app.version,
    recordsLauncher: state.abox.app.list.recordsLauncher,
    subscriptions: state.chat.subscriptions.data,
    isMobile: state.global.isMobile,
    hasGlobalAccount: state.user.hasGlobalAccount,
    userOrganisations: state.user.userOrganisations,
    expanded: state.sidebar.expanded,
    learningUrl: state.app.configurations?.affectli?.site?.learningUrl,
    currentRoomId: getStr(state, 'chat.room.rel.id', null)
});

const mapDispatchToProps = (dispatch: Object) => ({
    showToastr: bindActionCreators(showToastr, dispatch),
    toggleNav: bindActionCreators(toggleNav, dispatch),
    toggleChat: bindActionCreators(toggleChat, dispatch),
    loadAppsLauncher: bindActionCreators(loadAppsLauncher, dispatch),
    toggleNotifications: bindActionCreators(toggleNotifications, dispatch),
    openTour: bindActionCreators(openTour, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader);
