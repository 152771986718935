/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress } from '@mic3/platform-ui';
import { connect } from 'react-redux';

import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import { bind } from 'app/utils/decorators/decoratorUtils';
import history from 'store/History';
import { createTeam, updateTeam } from 'store/actions/admin/teamsActions';

class AddTeam extends PureComponent<Object, Object> {
    static propTypes = {
        createTeam: PropTypes.func.isRequired,
        isLoading: PropTypes.bool,
        isLoadingUpdate: PropTypes.bool,
        data: PropTypes.object,
        title: PropTypes.string
    };

    static defaultProps = {
        title: 'Create New Team'
    };

    formRef = React.createRef();
    components = [
        {
            type: 'text',
            properties: { label: 'Team Name', name: 'name' },
            constraints: { required: true }
        }
    ];

    @bind
    onFormSubmit(event: Event) {
        event.preventDefault();
        this.formRef.current.isValidForm().then(({ data: formData, errors }) => {
            const { createTeam, updateTeam, data, onClose } = this.props;
            if (!errors) {
                if (data) {
                    const { id, name } = formData;
                    updateTeam({ id, name: name.trim() }).then((response) => {
                        if (response instanceof Error) return;
                        onClose();
                    });
                    return;
                }
                createTeam({ ...formData, name: formData.name.trim() }).then((result) => {
                    const { createTeam: { id } } = result;
                    if (id) history.push(`/teams/${id}`);
                });
            }
        });
    }

    render(): Object {
        const { isLoading, isLoadingUpdate, onClose, data, title } = this.props;
        return (
            <ModalDialog
                title={title}
                onClose={onClose}
                actions={
                    (isLoading || isLoadingUpdate) ? (
                        <CircularProgress size={24} color="primary" />
                    ) : (
                        <Button onClick={this.onFormSubmit}>
                            Save
                        </Button>
                    )
                }
            >
                <FormGenerator 
                    components={this.components} 
                    ref={this.formRef}
                    data={data}
                />
            </ModalDialog>
        );
    }
}

export default connect(
    state => ({
        isLoading: state.admin.teams.create.isLoading,
        isLoadingUpdate: state.admin.teams.update.isLoading
    }),
    {
        createTeam,
        updateTeam
    }
)(AddTeam);
