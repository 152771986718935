import { gql } from '@apollo/client';

export default gql`query loadEntitiesForRelationshipsQuery($type: String!, $filterBy: [JSON]){
  entities(type: $type, filterBy: $filterBy){
    id
    name
    primary
    type
    attributes
  }
}`;
