/* @flow */

import { combineReducers } from 'redux';

import {
    LOAD_EVENTS_STARTED,
    LOAD_EVENTS,
    LOAD_SIDEBAR_EVENT_DETAILS_STARTED,
    LOAD_SIDEBAR_EVENT_DETAILS,
    LOAD_EVENT_DETAILS_STARTED,
    LOAD_EVENT_DETAILS,
    LOAD_EVENT_TYPES_STARTED,
    LOAD_EVENT_TYPES,
    LOAD_EVENT_TYPE_DETAILS_STARTED,
    LOAD_EVENT_TYPE_DETAILS,
} from 'store/actions/stream/eventsActions';
import { dataTableReducer, loadDataReducer, keepDataAtStart } from 'app/utils/redux/reducer-utils';

import processes from 'store/reducers/stream/events/processes/eventProcessesReducer';

export default combineReducers<Object, Object>({
    processes,
    list: dataTableReducer(LOAD_EVENTS_STARTED, LOAD_EVENTS, keepDataAtStart),
    types: dataTableReducer(LOAD_EVENT_TYPES_STARTED, LOAD_EVENT_TYPES, keepDataAtStart),
    typeData: loadDataReducer(LOAD_EVENT_TYPE_DETAILS_STARTED, LOAD_EVENT_TYPE_DETAILS),
    details: loadDataReducer(LOAD_EVENT_DETAILS_STARTED, LOAD_EVENT_DETAILS),
    sidebar: combineReducers({
        details: loadDataReducer(LOAD_SIDEBAR_EVENT_DETAILS_STARTED, LOAD_SIDEBAR_EVENT_DETAILS),
    })
});
