import React, { memo } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Typography, Divider, Grid, MdiIcon, Chip, IconButton } from '@mic3/platform-ui';

import ExpansionCard from 'app/components/molecules/ExpansionCard/ExpansionCard';
import Ellipsis from 'app/components/molecules/Ellipsis/Ellipsis';

const DividerStyled = styled(Divider)`
    margin: 10px 0 32px 0 !important;
`;
const MoreChipStyled = styled(Chip)`
    background:  ${({ theme }) => theme.material.colors.background.fields} !important;
    & .MuiChip-label {
        max-width: 140px;
    }
`;
const TeamChipStyled = styled(Chip)`
    background:  ${({ theme }) => theme.material.colors.background.fields} !important;
`;

const UserAboutSection = connect(
    (state, props) => ({
        isMobile: state.global.isMobile
    }),
    {}
)(({ isMobile, data, openSidebar }: Object) => {
    const { teams, workspaces } = data || {};

    return (
        <>
            <ExpansionCard
                expanded
                title="User Access"
            >
                <div style={{ margin: '1rem 0' }}>
                    <Typography variant="button">Teams</Typography>
                    <Grid container alignItems="center">
                        <IconButton
                            onClick={() => openSidebar('Teams')}
                        >
                            <MdiIcon name="plus"/>
                        </IconButton>
                        <Ellipsis
                            data={teams}
                            displaySize={isMobile ? 2 : 5}
                            renderCount={count => (
                                <MoreChipStyled
                                    variant="default"
                                    label={`+${count}`}
                                    size="small"
                                    onClick={() => openSidebar('Teams')}
                                />
                            )}
                            renderItem={
                                ({ name }: Object) => (
                                    <TeamChipStyled label={name} onClick={() => openSidebar('Teams')} />
                                )
                            }
                        />
                    </Grid>
                    <DividerStyled />
                    <Typography variant="button">Workspaces</Typography>
                    <Grid container alignItems="center">
                        <IconButton
                            onClick={() => openSidebar('Workspaces')}
                        >
                            <MdiIcon name="plus"/>
                        </IconButton>
                        <Ellipsis
                            data={workspaces}
                            displaySize={isMobile ? 2 : 5}
                            renderCount={count => (
                                <MoreChipStyled
                                    variant="default"
                                    label={`+${count}`}
                                    size="small"
                                    onClick={() => openSidebar('Workspaces')}
                                />
                            )}
                            renderItem={
                                ({ name }: Object) => (
                                    <TeamChipStyled label={name} onClick={() => openSidebar('Workspaces')} />
                                )
                            }
                        />
                    </Grid>
                </div>
            </ExpansionCard>
        </>
    );
});

export default memo(UserAboutSection);
