/* @flow */

import { gql } from '@apollo/client';
import { eventToEventRelationCommonFields } from 'graphql/entities/relationships/eventToEventRelationsQuery';


export default gql`
    mutation createEventToEventRelationMutation(
        $event: EventReferenceInput!
        $relatedEvent: EventReferenceInput!
        $relationDefinition: ID!
    ) {
        payload: eventRelationAdd(event: $event, relatedEvent: $relatedEvent, relationDefinition: $relationDefinition) {
            ${eventToEventRelationCommonFields}
        }
    }
`;
