/* @flow */

import { gql } from '@apollo/client';

export default gql`
    query getEntityTypeQuery($id: ID!){
        entity(id: $id) {
            type
        }
    }
`;
