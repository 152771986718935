import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { muiTheme } from 'app/themes/materialUi';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { BryntumTaskBoard} from '@bryntum/taskboard-react';
import { StringHelper } from '@bryntum/taskboard/taskboard.module.js';
import Loader from 'app/components/atoms/Loader/Loader';
import { taskboardConfig } from './TaskBoardConfig';
import '@bryntum/taskboard/taskboard.classic-dark.css';

import { bind, memoize} from 'app/utils/decorators/decoratorUtils';
import { toUniqueUUID } from 'app/utils/string/string-utils';
import { getAttachmentUrl } from 'app/utils/attachments/attachmentsUtils';
import { getRelationshipIdFromTask } from 'app/utils/boards/utils';
import { get } from 'app/utils/lo/lo';
import { formatOptionsForTask } from 'app/utils/formatter/graphql-formatter';
import { copyToClipboard } from 'app/utils/classification/classificationUtils';

import { showToastr } from 'store/actions/app/appActions';
import { saveComponentState } from 'store/actions/component/componentActions';
import { loadColumnsTasks, deleteRelationEntityFromTask, createRelationEntityToTask, setColumnPosition, updateRelationColumnToBoard } from 'store/actions/abox/boardActions';

const BoardWrapper = styled.div`
    width: 100%;
    height: 100%;
    .b-taskboardbase{
        --taskboard-color: ${({theme})=> theme.material.colors.text.primary} !important;
    }
    & .b-taskboardbase.b-taskdrag, & .b-panel .b-panel-body-wrap {
        background: transparent !important;
        --taskboard-taskdrag-drop-background: transparent !important;
        --taskboard-taskdrag-drop-border: 1px solid ${({theme})=> theme.material.colors.background.divider} !important;
    }
    .b-taskboard {
        height: 100%;
        --taskboard-background: transparent;
        --taskboard-body-padding: 0em;
        --taskboard-column-gap: 2em;
        --taskboard-gap: 10px;
        --taskboard-column-background: transparent;
        --taskboard-column-border-radius: 3px;
        --taskboard-column-header-background: ${({ theme }) => theme.material.colors.background.default};
        --taskboard-column-header-icon-color: ${({ theme }) => theme.material.colors.text.secondary};
        --taskboard-swimlane-header-background:transparent;
        --taskboard-swimlane-header-font-size: 14px;
        --taskboard-column-header-font-size:14px;
        --taskboard-column-header-color: ${({ theme }) => theme.material.colors.text.secondary};
        --taskboard-column-header-collapsed-title-top: 3rem;
        --taskboard-progressitem-color : ${({ theme }) => theme.material.colors.background.progress};
        --taskboard-column-min-width: 350px;
        --taskboard-card-footer-padding: 10px;
        --taskboard-card-footer-color: ${({ theme }) => theme.material.colors.text.caption} !important;
        --taskboard-swimlane-header-icon-color: ${({theme})=> theme.material.colors.secondary.main}
    }

    & .b-taskboard-progress-outline {
        background: ${({ theme }) => theme.material.colors.background.progressLine} !important;
    }
    & .b-taskboard-body.b-horizontal-overflow .b-taskboard-column-headers{
        background: ${({ theme }) => theme.material.colors.background.default} !important;
    }
    & .b-taskboard-swimlane-header{
        background: ${({ theme }) => theme.material.colors.background.default};
    }
    & .b-taskboard-card-footer{
        color: #ffffff61 !important;
        font-size: 12px !important;
    }
    & .footer-icon{
        font-size: 16px !important;
    }
    & .b-taskboard-column-expander, .b-taskboard-swimlane-expander {
      transform:none;
    }
    & .b-taskboard-swimlane.b-collapsed .b-taskboard-swimlane-expander {
      transform: rotate(270deg);
    }
    & .b-taskboard-swimlane-title{
        letter-spacing:1px;
        color: ${({theme})=> theme.material.colors.text.primary};
    }
    & .b-taskboard-swimlane {
        border-bottom: 0.5px solid ${({theme})=> theme.material.colors.background.divider} !important;
    }
    & .b-taskboard-swimlane-count {
        color: ${({ theme }) => theme.material.colors.text.secondary} !important;
    }
    & .b-taskboard-column-headers{
        width:auto !important;
        min-width: 100%;
        margin-bottom:5px;
        border-bottom: 0.5px solid ${({theme})=> theme.material.colors.background.divider}  !important;
    }
    & .b-taskboard-swimlane-header {
        border-bottom: 0.5px solid ${({theme})=> theme.material.colors.background.divider} !important;
        border-top: 0.5px solid ${({theme})=> theme.material.colors.background.divider}  !important;
    }
    & .b-taskboard-card {
        background : ${({ theme }) => theme.material.colors.background.paper};
        min-height: 80px !important;
        padding: 0.5rem !important;
        &:hover{
            background: ${({ theme }) => theme.material.colors.background.hover};
        }
    }
    & .b-taskboard-swimlane-header i{
        color:  ${({ theme }) => theme.base.linkColor};
    }
    & .b-taskboard-resource-avatars {
        flex:1;
    }
    & .b-taskboard-taskitem {
        order: 1;
    }
  
    & .b-taskboard-task-menu{
        transform: rotate(90deg) !important;
        cursor: pointer;
        height: 30px;
        width: 30px;
        cursor: pointer;
        &.b-widget .b-menuitem .b-box-item{
            color: #fff !important;
        }
        &:hover {
            background-color: ${({ theme }) => theme.material.colors.background.hover};
            border-radius: 50%;
        }
        &:before {
            font-size: 19px !important;
            color: ${({ theme }) => theme.material.colors.text.secondary} !important;  
        }
    }
    & .title-icon{
        order: 0;
        color: ${({ theme }) => theme.material.colors.text.secondary};
        border-right:1px solid ${({theme})=> theme.material.colors.background.divider} ;
        padding-right:10px;
        margin-right:5px;
    }
    & .b-taskboard-card-header {
        font-size: 14px;
        letter-spacing: 0.25px;
        font-weight: 400;
    }
    .highest {
        border-left: 2px solid ${({ theme }) => get(theme.material.colors.priorityTaskColors, `1.1`)};
    }
    .high {
        border-left: 2px solid ${({ theme }) => get(theme.material.colors.priorityTaskColors, `2.1`)};
    }
    .medium {
        border-left: 2px solid  ${({ theme }) => get(theme.material.colors.priorityTaskColors, `3.1`)};
    }
    .low {
        border-left: 2px solid ${({ theme }) => get(theme.material.colors.priorityTaskColors, `4.1`)};
    }
    .lowest {
        border-left: 2px solid ${({ theme }) => get(theme.material.colors.priorityTaskColors, `5.1`)};
    }
    .closed-task {
        border-left: 2px solid grey !important;
        background-color:rgba(255, 255, 255, 0.08);
        --taskboard-progressitem-color : grey !important;
   }
`;



class BoardView extends PureComponent{
    static propTypes = {
        columns: PropTypes.arrayOf(PropTypes.object),
        list: PropTypes.array,
        taskItems: PropTypes.array,
        openSidebar: PropTypes.func.isRequired,
        groupBy: PropTypes.string,
        setColumnPosition: PropTypes.func,
        updateRelationColumnToBoard: PropTypes.func,
        userProfile: PropTypes.object,
    }

    static defaultProps = {
        columns: [],
        taskItems : [],
        swimlanes:[],
    };

    state ={
        boardTasks: null,
        boardColumns: null,
        isLoading: true
    }

    boardRef = React.createRef()
    boardRefLoaded = false;
    boardKey = 1;


    componentDidMount() {
        this.loadTasks();
        this.reBuildColumns();
    }

    componentDidUpdate(prevProps) {
        const { columns, tasks, orderBy, filterBy, groupBy, excludeBy, projectMenu} = this.props;
        if(
            columns !== prevProps.columns ||
            orderBy !== prevProps.orderBy ||
            filterBy !== prevProps.filterBy ||
            excludeBy !== prevProps.excludeBy
        ) {
            this.loadTasks();
        }
        if (columns !== prevProps.columns || prevProps.groupBy !== this.props.groupBy || tasks !== prevProps.tasks) {
            this.reBuildColumns();
            this.normalizeTasks(columns,tasks, groupBy);
        }
        if(tasks && tasks !== prevProps.tasks){
            projectMenu(tasks);
        }
    }

    @bind
    loadTasks() {
        const { columns, loadColumnsTasks, filterBy, excludeBy, orderBy, userProfile } = this.props;
        const { isLoading} = this.state;
        if(columns?.length){
            const formattedOptions = formatOptionsForTask({ filterBy , orderBy, excludeBy }, userProfile);
            loadColumnsTasks(columns.map(clmn => clmn.id),formattedOptions).then(
                () => {
                    if(isLoading) this.setState({ isLoading: false });
                    //this.buildColumns(columns);
                });
        }
    }

    @bind
    @memoize()
    buildColumns(columns) {
        const boardColumns = columns.map((clmn, index) => {
            return {
                text: clmn.name ?? clmn.text,
                id: clmn.id,
                relationId: clmn.relationId,
                order:index
            };
        });
        this.setState({boardColumns: boardColumns});
        return boardColumns;
    }

    @bind
    reBuildColumns() {
        const { viewState, saveComponentState, columns } = this.props;
        const { boardKey } = viewState || {};
        const options = {
            ...viewState,
            boardKey: boardKey ? boardKey + 1 : 1,
            columns
        };
        saveComponentState(this.props.id, options);
    }

    @bind
    buildSwimlanes() {
        const { tasks, groupBy } = this.props;
        if(!groupBy) return;
        const swimlanes = [];
        switch (groupBy.toLowerCase()) {
            case 'none':
                break;
            case 'assignee':
                var assignedTasks =  tasks.filter(task => task.assignee);
                assignedTasks.forEach((t, i) => {
                    if(!swimlanes.some( x => x.id === t.assignee.id)){
                        swimlanes.push({
                            id:t.assignee.id,
                            text:t.assignee.name.toUpperCase(),
                            collapsed:true
                        });
                    };
                });
                if(tasks.some(x => !x.assignee)){
                    swimlanes.push({
                        id:0,
                        text:'UNASSIGNED',
                        collapsed:true
                    });
                }
                break;
            case 'priority':
                tasks.forEach((t, i) => {
                    var priority = +t.primary.priority === 50 ? 3 : +t.primary.priority;
                    if(!swimlanes.some( x => x.id === priority)){
                        swimlanes.push({
                            id: priority,
                            text: priority === 1 ? 'HIGHEST' : priority === 2 ? 'HIGH' :
                                priority === 3 ? 'MEDIUM' : priority === 4 ? 'LOW'
                                    : 'LOWEST',
                            collapsed:true
                        });
                    }
                });
                break;
            case 'process':
                tasks.forEach((t, i) => {
                    if(!swimlanes.some( x => x.id === t.process.id)){
                        swimlanes.push({
                            id:t.process.id,
                            text:t.process.name.toUpperCase(),
                            collapsed:true
                        });
                    }
                });
                break;
            default:
                tasks.forEach((task)=>{
                    task.relations.forEach((rel)=>{
                        if(rel.relation?.relationDefinition?.description?.toLowerCase() === groupBy){
                            if(!swimlanes.some( x => x.id === rel.relatedEntity.id)){
                                swimlanes.push({
                                    id:rel.relatedEntity.id,
                                    text:rel.relatedEntity.name.toUpperCase(),
                                    collapsed:true
                                });
                            }
                        }
                    });
                });
                break;
        }
        return swimlanes.sort((a,b) => a.id - b.id);
    }

    @bind
    groupTaksToSwimlanes(task, groupBy) {
        switch (groupBy) {
            case 'none':
                break;
            case 'assignee':
                return task.assignee?.id ?? 0;
            case 'priority':
                return task.primary.priority;
            case 'process':
                return task.primary.process;
            default:
                let id =[];
                task.relations.forEach((rel)=>{
                    if(rel.relation?.relationDefinition?.description?.toLowerCase() === groupBy){
                        id = [...id, rel.relatedEntity.id];
                    }
                });
                return id;
        }
    }

    @bind
    @memoize()
    normalizeTasks(columns,tasks, groupBy){
        const boardColumns = this.buildColumns(columns);
        const resourcesIds = [];
        const taskItems = [];
        this.setState({
            boardTasks : {
                tasksData: tasks.map((task, index) => {
                    const columnIds = [];
                    boardColumns.forEach((clmn, i) => {
                        if(task.relations.find(rel => rel.relatedEntity.id === clmn.id)) {
                            if(!columnIds.includes(clmn.id)){
                                columnIds.push(clmn.id);
                            }
                        }
                    });
                    switch (groupBy){
                        case 'none':
                        case 'assignee':
                        case 'priority':
                        case 'process':{
                            const _task = columnIds.map(columnId => ({
                                ...task,
                                //uniqueId - since task can be on multiple columns
                                id: `${task.id}-${columnId}`,
                                taskId: task.id,
                                columnId,
                                progress: task.primary.progress ?? 0,
                                prio: this.groupTaksToSwimlanes(task, groupBy?.toLowerCase())
                            }));
                            taskItems.push(_task);
                            return _task;
                        }
                        default:{
                            const groupToSwimlanes = this.groupTaksToSwimlanes(task, groupBy?.toLowerCase());
                            const _task = groupToSwimlanes.flatMap((id)=>{
                                return columnIds.map(columnId => ({
                                    ...task,
                                    //uniqueId - since task can be on multiple columns
                                    id: `${task.id}-${columnId}-${id}`,
                                    taskId: task.id,
                                    columnId,
                                    progress: task.primary.progress ?? 0,
                                    prio: id
                                }));
                            });
                            taskItems.push(_task);
                            return _task;
                        }
                    }
                }).flat(),

                resourcesData: tasks.map((task, index) => {
                    const { assignee } = task;
                    if(!resourcesIds.includes(assignee?.id) && assignee) {
                        resourcesIds.push(assignee.id);
                        return {
                            image: assignee.image ? getAttachmentUrl(assignee.id, 'user') : null,
                            name: assignee.name,
                            id: assignee.id
                        };
                    }
                    return null;
                }).filter(Boolean),

                assignmentsData: taskItems?.flat().map((task, index) => {
                    const { id, assignee } = task;
                    return {
                        id: index,
                        event: id,
                        resource: assignee?.id ?? null
                    };
                })
            }
        });
    };

    @bind
    copyLinkToClipBoard(e, id: string) {
        e.stopPropagation();
        e.preventDefault();
        copyToClipboard(`${window.location.origin}/#/abox/task/${id}`)
            .then(() => {
                this.props.showToastr({ severity: 'success', detail: 'Link copied to clipboard' });
            })
            .catch(() => {
                this.props.showToastr({ severity: 'error', detail: 'Link could not copied to clipboard' });
            });
    };

    @bind
    copyIdToClipBoard(e, id: string) {
        e.stopPropagation();
        e.preventDefault();
        copyToClipboard(id)
            .then(() => {
                this.props.showToastr({ severity: 'success', detail: 'ID copied to clipboard' });
            })
            .catch(() => {
                this.props.showToastr({ severity: 'error', detail: 'ID could not copied to clipboard' });
            });
    };

    @bind
    buildFooterItems() {
        return {
            'id > resourceAvatars' :{
                type : 'template',
                template:({ taskRecord }) => StringHelper.xss`<span id='UniqueUUID' title="Copy ID to clipboard">#${toUniqueUUID(taskRecord.taskId)}</span> <i class="mdi mdi-link footer-icon" title="Copy link to clipboard"></i>`
            }
        };
    };

    @bind
    buildListeners() {
        return {
            //if task is closed , cancel operation
            beforeTaskDrag({ taskRecords }) {
                return !taskRecords[0].data.primary.closedDate;
            },

            // taskDrop is triggered after a successful drop
            taskDrop: async ({ taskRecords, targetColumn, ...rest }) => {
                const { deleteRelationEntityFromTask, createRelationEntityToTask } = this.props;
                const { isLoading } = this.state;
                const task = taskRecords.map((t) => {
                    return {
                        data: t.data,
                        originaldata: t.originalData,
                    };
                });

                if(task[0].data.columnId ===  task[0].originaldata.columnId) return;
                // Remove the task relationship from source column on the backend
                if(!isLoading) await this.setState({ isLoading: true });

                const relationship = getRelationshipIdFromTask(task[0].data.relations, task[0].originaldata.columnId);
                const response = await deleteRelationEntityFromTask(get(relationship, 'relation.id'));
                if (response instanceof Error) return;

                //Create task relationship to destination column on the backend
                const relationshipDefId = get(relationship, 'relation.relationDefinition.id');
                const createResponse = await createRelationEntityToTask({
                    relationDefinition: relationshipDefId,
                    entity: { type: get(task[0].data, 'closedDate') ? 'closedtask' : 'opentask', id: task[0].data.taskId },
                    relatedEntity: { type: 'custom', id: task[0].data.columnId }
                });
                if (createResponse instanceof Error) return;
                await this.loadTasks();

            },

            columnDragEnd: async ({ beforeColumn, columnRecord }) => {
                const { isLoading, boardColumns } = this.state;

                if(boardColumns.length < 2) return;
                const oldIndex = boardColumns.find( col => col.id === columnRecord.originalData.id).order;
                let newIndex;

                if(oldIndex === boardColumns.length) newIndex = boardColumns.find( col => col.id === beforeColumn.originalData.id).order - 1;
                else if(!beforeColumn) newIndex = boardColumns.length - 1;
                else{
                    var index = boardColumns.find( col => col.id === beforeColumn.originalData.id).order;
                    newIndex = index >= 0 ? oldIndex > index ? index : index -1 : 0;
                }

                if(oldIndex === newIndex) return;
                if(!isLoading) await this.setState({ isLoading: true });

                const reorderedBoardColumns = this.reorderColumns(boardColumns, oldIndex, newIndex).map((column, index) => {
                    const newColumn = { ...column };
                    newColumn.order = index;
                    return newColumn;
                });
                this.saveColumnsOrder(reorderedBoardColumns.filter(col => col.id !== null));
            }
        };
    };


    @bind
    saveColumnsOrder(columns){
        const {setColumnPosition, updateRelationColumnToBoard } = this.props;
        setColumnPosition(columns);
        const updateBoardLabelOrderPromise = columns.map(column => updateRelationColumnToBoard({
            relation: column.relationId,
            attributes: {
                'board-board-label/order': column.order
            }
        }));

        Promise.all(updateBoardLabelOrderPromise).then((response) => {
            if(this.state.isLoading) this.setState({ isLoading: false });
            if (response instanceof Error) return;
        });
    }

    reorderColumns(list: any[], startIndex: number, endIndex: number){
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    taskRenderer({ taskRecord, cardConfig }) {
        const headerContent = cardConfig.children.header.children;
        headerContent.icon = {
            tag   : 'i',
            class : 'mdi mdi-checkbox-marked-circle-outline',
            style : `color: ${muiTheme.colors.text.secondary} ;`
        };
        var priority = +taskRecord.primary.priority === 50 ? 3 : +taskRecord.primary.priority;
        cardConfig.class[ priority === 1 ? 'highest' : priority === 2 ? 'high' : priority === 3? 'medium' : priority === 4 ? 'low' : 'lowest' ] = !!!taskRecord.primary.closedDate;
        cardConfig.class['closed-task'] = !!taskRecord.primary.closedDate;
    }

    @bind
    onTaskClick(task) {
        if (task.event.target.id.includes('UniqueUUID')){
            this.copyIdToClipBoard(task.event, task.taskRecord.data.taskId);
        }
        if (task.event.target.className.includes('mdi-link footer-icon')){
            this.copyLinkToClipBoard(task.event, task.taskRecord.data.taskId);
        }
        else if(!task.event.target.className.includes('b-taskboard-task-menu')
        && !task.event.target.id.includes('UniqueUUID')) {
            const {openSidebar, sidebarTitle } = this.props;
            openSidebar && openSidebar(task.taskRecord.data.taskId, sidebarTitle || 'About',task.columnRecord.data.id);
        }
    };

    @bind
    updateKey(prevKey) {
        this.boardKey = this.boardKey + (prevKey || 0) + 1;
    }

    render(){
        const { viewState, groupBy, openSidebar } = this.props;
        const { boardKey } = viewState || {};
        const {isLoading, boardTasks, boardColumns } = this.state;
        this.updateKey(boardKey);
        return(
            <>
                {isLoading && <Loader absolute backdrop />}
                {boardTasks && <BoardWrapper>
                    <BryntumTaskBoard
                        {...taskboardConfig(openSidebar)}
                        key={this.boardKey}
                        columns={boardColumns}
                        swimlanes={this.buildSwimlanes(boardTasks || [], groupBy)}
                        project={boardTasks}
                        listeners={this.buildListeners()}
                        footerItems={this.buildFooterItems()}
                        taskRenderer={this.taskRenderer}
                        processItems= {this.processItems}
                        onTaskClick={(taskRecord) => {this.onTaskClick(taskRecord);}}
                    />
                </BoardWrapper>}
            </>
        );
    };
}

export default connect((state, props) => ({
    viewState: get(state, `component.state.${props.id}`),
    tasks: state.abox.boards.tasks.records,
    sidebarTitle: state.sidebar.title,
    userProfile: state.user.profile,
}), {
    saveComponentState,
    loadColumnsTasks,
    deleteRelationEntityFromTask,
    createRelationEntityToTask,
    updateRelationColumnToBoard,
    setColumnPosition,
    showToastr
}, null, {forwardRef: true})(BoardView);
