import React from 'react';

import Icon from 'app/components/atoms/Icon/Icon';
import { connect } from 'react-redux';

const TypeIcon = ({ type, size, color, primaryClasses }) => {
    const iconSize = size || 'sm';
    
    switch (type) {
        case 'iot_pipeline': return <Icon name="pipe" size={iconSize} />;
        case 'graphic': return <Icon name="folder-multiple-image" size={iconSize} />;
        case 'broadcast': return <Icon name="bullhorn" size={iconSize} />;
        case 'relationdefinition': return <Icon name="link" size={iconSize} />;
        case 'thing': return <Icon name="Things" type="af" size={iconSize} />;
        case 'person': return <Icon name="account-outline" type="mdi" size={iconSize} />;
        case 'workspace': return <Icon name="set-left-center" size={iconSize} />;
        case 'user': return <Icon name="account" size={iconSize} />;
        case 'team': return <Icon name="account-group" size={iconSize} />;
        case 'organisation': return <Icon name="organisations" type="af" size={iconSize} />;
        case 'custom': return <Icon name="shape-circle-plus" size={iconSize} />;
        case 'class': return <Icon hexColor={color} name="Attribute-class" type="af" size={iconSize} />;
        case 'entityType': return <Icon hexColor={color} name="primary-class" type="af" size={iconSize} />;
        case 'customClassEntity': return <Icon name="things" type="af" size={iconSize} />;
        case 'relation': return <Icon name="link" size={iconSize} />;
        case 'task': case 'opentask': case 'closedtask': return <Icon name="checkbox-marked-circle-outline" size={iconSize} />;
        case 'process': case 'openprocess': case 'closedprocess':  return <Icon name="processes" type="af" size={iconSize} />;
        case 'processDefinition': return <Icon name="process-builder" type="af" size={iconSize} />;
        case 'event': return <Icon name="event-monitor" type="af" size={iconSize} />;
        case 'eventtype': return <Icon name="data-source-builder" type="af" size={iconSize} />;
        case 'channel': return <Icon name="public-channel" type="af" size={iconSize} />;
        case 'group': return <Icon name="private-channel" type="af" size={iconSize} />;
        case 'direct': return <Icon name="Direct-message" type="af" size={iconSize} />;
        case 'boards': return <Icon name="Kanban" type="af" size={iconSize} />;
        case 'live': return <Icon name="headset" size={iconSize} />;
        case 'backgroundjob': return <Icon name="coffee" size={iconSize} />;
        case 'print-template': return <Icon name="printer" size={iconSize} />;
        default: {
            const primaryClass = primaryClasses.find(pcls => pcls.uri === type);
            if(primaryClass)
                return <Icon name={primaryClass.icon} type={primaryClass.iconType} size={iconSize} />;
            return null;
        };
    }
};

export default connect(state =>
    ({
        primaryClasses: state.app.allPrimaryClasses.records || [],
    }),{})(TypeIcon);
