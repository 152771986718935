import React, { PureComponent } from 'react';

import styled from 'styled-components';
import { Grid } from '@mic3/platform-ui';
import moment from 'moment';
import { Typography } from '@mic3/platform-ui';
import {isDefined } from 'app/utils/utils';
import { bind } from 'app/utils/decorators/decoratorUtils';
import { get } from 'app/utils/lo/lo';

const DarkLabel = styled(Typography)`
    color: ${({ theme }) => theme.material.colors.text.secondary} !important;
    font-size: 14px !important;
    font-weight: 400 !important;
`;
const RedLabel = styled(Typography)`
    color: ${({ theme }) =>  get(theme.material.colors.priorityTaskColors, `1.0`)};
    font-size: 14px !important;
    font-weight: 400 !important;
`;

const OrangLabel = styled(Typography)`
    color: ${({ theme }) =>  get(theme.material.colors.priorityTaskColors, `3.0`)};
    font-size: 14px !important;
    font-weight: 400 !important;
`;

class GridDueDate extends PureComponent<Object, Object> {
    
   
    @bind
    getText(type, dataType, numEl){
        let text; 
        let typeOfData = dataType.replace('few','')
            .replace('ago','')
            .replace('in ',' ')
            .replace('a ',' ')
            .replace('an ','');
        if(typeOfData.trim() === 'seconds' && isNaN(numEl)){
            typeOfData = 'second';
        } 
        switch (type) {
            case 'Overdue':
                text = numEl ? <RedLabel>Overdue by {numEl} {typeOfData}</RedLabel> : <RedLabel>Overdue by 1 {typeOfData}</RedLabel>;
                break;
            case 'Today':
                text = <OrangLabel>Today</OrangLabel>;
                break;
            case 'Tomorrow':
                text = <OrangLabel>Tomorrow</OrangLabel>;
                break;
            case 'from now' :
                text = numEl ? <DarkLabel>{numEl} {typeOfData} from now</DarkLabel> : <DarkLabel>1 {typeOfData} from now</DarkLabel>;
                break;
        }
        return text;
    }

    @bind
    getDeadline(data){
        let deadline = '';
        if(isDefined(data.primary.dueDate)){

            deadline = moment(data.primary.dueDate).fromNow();
            const numEl = parseInt(deadline.match(/\d+/));
            const dataType = deadline
                .replace(numEl,'')
                .replace('  ',' ')
                .trim();
                
            switch (dataType) {
                case 'a few seconds ago':
                case 'seconds ago':
                case 'a minute ago':
                case 'minutes ago':
                case 'an hour ago':
                case 'hours ago':
                case 'a day ago':
                case 'days ago':
                case 'a month ago':
                case 'months ago':
                case 'a year ago': 
                case 'years ago':
                    return deadline = this.getText('Overdue', dataType, numEl);
                case 'in a few seconds':
                case 'in a minute':
                case 'in minutes':
                case 'in an hour':
                case 'in hours':
                    return deadline = this.getText('Today', dataType, numEl);
                case 'in a day':
                    return deadline = this.getText('Tomorrow', dataType, numEl);
                case 'in days':
                case 'in a month':
                case 'in months':
                case 'in a year':
                case 'in years':
                    return deadline = this.getText('from now', dataType, numEl);

            }
        }
        return  deadline; 
    }


    render() {
        const { record } = this.props;
        const { data } = record;
        const deadline = this.getDeadline(data);

        return (
            <Grid container wrap="nowrap" alignItems="center">
                {deadline}
            </Grid>
        );
    }
};

export default GridDueDate;
