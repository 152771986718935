/* @flow */
import React from 'react';

import EventSidebar from 'app/containers/Sidebars/EventSidebar';

import {
    buildSidebar as buildSidebarAction,
    closeSidebar as closeSidebarAction
} from 'store/actions/sidebar/sidebarActions';

export const openEventSidebar = ({ id, time, title, mapPin }) => async (dispatch, getState) => {
    return buildSidebarAction({
        title,
        content: <EventSidebar id={id} time={time} title={title} mapPin={mapPin}/>,
        isOpen: true,
    })(dispatch, getState);
};

export const closeEventSidebar = closeSidebarAction;
