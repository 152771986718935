import React from 'react';
import { muiTheme } from 'app/themes/materialUi';

import { ListItemSecondaryAction, ListItem, ListItemText, ListItemIcon } from '@mic3/platform-ui';
import Icon from 'app/components/atoms/Icon/Icon';
import DotMenu from 'app/components/molecules/DotMenu/DotMenu';
import { buildPipelineMenuItems } from 'app/containers/Designer/Pipeline/Components/PipelineWidgetDotMenu';

const PipelineLeftSidebarItem = ({ 
    onDotMenuClick,  pipelineStatus, node, getCurrentConnectorStatus,
    onClick, selected, index, nodeId, pipelineMonitor, onSelectNode
}) => {
    const { iconType, iconName, title, subTitle } = node;
    return (
        <ListItem 
            button 
            onClick={() => onSelectNode(nodeId)}
            selected={selected}
        >
            <ListItemIcon>
                <Icon type={iconType} name={iconName} hexColor={muiTheme.colors.text.secondary} />
            </ListItemIcon>
            <ListItemText primary={title} secondary={subTitle} />
            <ListItemSecondaryAction>
                <DotMenu 
                    items={buildPipelineMenuItems({
                        pipelineStatus,
                        pipelineMonitor,
                        node,
                        getCurrentConnectorStatus
                    })}
                    onItemClick={(title) => onDotMenuClick(title, nodeId)}
                />
            </ListItemSecondaryAction>            
        </ListItem>
    );
};

export default PipelineLeftSidebarItem;
