/* @flow */

import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import ALive from 'app/containers/Abox/ALive/ALive';
import BackgroundJobList from 'app/containers/Abox/BackgroundJob/BackgroundJobList';
import BackgroundJobDetails from 'app/containers/Abox/BackgroundJob/BackgroundJobDetails';
import ProcessesList from 'app/containers/Abox/ProcessesList/ProcessesListView';
import MyApps from 'app/containers/Abox/MyApps/MyApps';
import StartProcess from 'app/containers/Abox/MyApps/StartProcess';
import ProcessStarted from 'app/containers/Abox/MyApps/ProcessStarted';
import ProcessViewRoute from 'app/containers/Abox/ProcessView/ProcessViewRoute';
import TaskViewRoute from 'app/containers/Abox/TaskView/TaskViewRoute';
import AboxCalendar from 'app/containers/Abox/AboxCalendar/AboxCalendar';
import BetaTaskBoards from 'app/containers/Abox/Boards/BetaTaskBoards';
import DefaultBoards from 'app/containers/Abox/Boards/DefaultBoards';
import TaskList from 'app/containers/Abox/TaskList/TaskList';
import Timeline from 'app/containers/Abox/Timeline/Timeline';
import Gantt from 'app/containers/Abox/GanttChart/GanttChart';
import Boards from 'app/containers/Abox/OldBoards/Boards';
import LandingPageRoute from 'app/containers/LandingPage/LandingPageRoute';

/**
 * Define the routes for the Event's views.
 */
const AboxRoute = ({ match: { url } }: Object) => {
    const backgroundJobMatch = '/abox/background-jobs';
    return (
        <Switch>
            <Route path={`${url}/alive`} exact component={ALive} />
            <Route path={`${url}/alive/:type/:id`} exact component={ALive} />

            <Route path={backgroundJobMatch} exact component={() => <Redirect to={`${backgroundJobMatch}/all`} />} />
            <Route path={`${url}/background-jobs/:status`} exact render={ props => <BackgroundJobList {...props} module={'abox'} />} />
            <Route path={`${url}/background-job/:id`} exact component={BackgroundJobDetails} />
            <Route path={`${url}/background-job/:id/:tab`} exact component={BackgroundJobDetails} />

            <Route path={`${url}/processes`} exact component={ProcessesList} />
            <Route path={`${url}/processes-new`} exact component={MyApps} />
            <Route path={`${url}/process`} component={ProcessViewRoute} />
            <Route path={`${url}/process-start/:appId`} exact component={StartProcess} />
            <Route path={`${url}/process-started/:processId`} exact component={ProcessStarted} />

            <Route path={`${url}/tasks/panel/:type/:id`} component={TaskList} />
            <Route path={`${url}/tasks`} exact component={TaskList} />

            <Route
                path={`${url}/tasks/:id`}
                exact
                component={
                // redirect for broken links affectli-project/affectli-support-issues#8270
                    ({
                        match: {
                            params: { id }
                        }
                    }) => <Redirect to={`${url}/task/${id}`} />
                }
            />
            <Route
                path={`${url}/processes/:id`}
                exact
                component={
                // redirect for broken links affectli-project/affectli-support-issues#8270
                    ({
                        match: {
                            params: { id }
                        }
                    }) => <Redirect to={`${url}/process/${id}`} />
                }
            />
            <Route
                path={`${url}/tasks/:id/:tab`}
                exact
                component={
                // redirect for broken links affectli-project/affectli-support-issues#8270
                    ({
                        match: {
                            params: { id, tab }
                        }
                    }) => <Redirect to={`${url}/task/${id}/${tab}`} />
                }
            />

            <Route path={`${url}/calendar`} component={AboxCalendar} />
            <Route path={`${url}/task`} component={TaskViewRoute} />
            <Route path={`${url}/timeline`} exact component={Timeline} />
            <Route path={`${url}/legacytimeline`} exact component={Gantt} />
            <Route path={`${url}/kanbanboards`} component={DefaultBoards} />
            <Route path={`${url}/beta-kanbanboards`} component={BetaTaskBoards} />
            <Route path={`${url}/boards`} component={Boards} />
            <Route
                path={`${url}/boards/:id`}
                exact
                component={({
                    match: {
                        params: { id }
                    }
                }) => <Redirect to={`${url}/boards/${id}`} />}
            />
            <Route path={`${url}`} component={LandingPageRoute} />
        </Switch>
    );};

export default AboxRoute;
