/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';

import { Divider, Box, Chip, Tooltip } from '@mic3/platform-ui';

import { get } from 'app/utils/lo/lo';
import { displayByKind, fromNow } from 'app/utils/date/date';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import history from 'store/History';
import ResizableListItem from 'app/components/molecules/VirtualList/ResizableListItem';
import Link from 'app/components/atoms/Link/Link';
import CardInfo from 'app/components/molecules/CardInfo/CardInfo';
import CardIconButton from 'app/components/molecules/CardIconButton/CardIconButton';
import {
    CardContainer,
    CardStyled,
    CardDividerTitle,
    CardHeaderStyled,
    CardImage,
    CardContentStyled
} from 'app/containers/Entities/EntitiesList/EntitiesListCardsItem';
import { RenderId,  RenderName } from './MapsListItem';
import { showToastr } from 'store/actions/app/appActions';
import { getPermissions } from 'app/config/rolesConfig';
import { baseRoutes } from 'app/config/routesConfig';
import { typeMap } from 'app/config/typesConfig';

const LinkStyled = styled(Link)`
    cursor: pointer;
    text-decoration: none;
    color: ${({ theme }) => theme.material.palette.text.primary} !important;
`;

/**
 *
 */
class MapsCardItem extends PureComponent<Object, Object> {
    /**
     * Define our prop-types for Maps card
     */
    static propTypes = {
        data: PropTypes.object,
        index: PropTypes.number,
        resetView: PropTypes.func,
        forceUpdateGrid: PropTypes.func,
        profile: PropTypes.object
    };

    constructor(props: Object) {
        super(props);

        this.inputField = React.createRef();
    }

    @bind
    goToClassification(id) {
        return () => history.push(`/classifications/${id}`);
    }

    @bind
    @memoize()
    buildClasses(classes) {
        return classes.map(({ name, id }) => <Chip key={name} variant="default" label={name} size="small" onClick={this.goToClassification(id)} />);
    }

    @bind
    renderIcon(id, permissions) {
        const { canComment } = permissions || {};
        let iconButtons = [
            {
                label: 'Layers',
                name: 'layers',
                type: 'mdi'
            },
            {
                label: 'About',
                name: 'information-outline',
                type: 'mdi'
            },
            {
                label: 'A-Live',
                name: 'messenger',
                type: 'af'
            },
            {
                label: 'Sharing',
                name: 'share-variant',
                type: 'mdi'
            }
        ];

        if (!canComment) {
            iconButtons = iconButtons.filter(({ label }) => label !== 'A-Live');
        }

        return (
            <Box display="flex">
                {iconButtons.map(({ label, name, type, value, count }, index) => {
                    return (
                        <Tooltip title={label} key={`${index}-${value}`}>
                            <LinkStyled
                                to={{
                                    pathname: `${baseRoutes.map}/${id}`,
                                    state: {
                                        title: label
                                    }
                                }}
                            >
                                <CardIconButton
                                    edge={index === 0 ? 'start' : false}
                                    count={count}
                                    ref={this.inputField}
                                    iconProps={{
                                        name: name,
                                        type: type
                                    }}
                                />
                            </LinkStyled>
                        </Tooltip>
                    );
                })}
            </Box>
        );
    }

    /**
     * Render maps card
     */
    render() {
        const { data, style, index, resize, children } = this.props;
        if (!data) {
            return null;
        }
        const { type, id, createdBy, createdDate, modifiedDate, modifiedBy, classes, primary, role } = data || {};
        const permissions = getPermissions(role && role);
        const thumbnail = get(primary, `${typeMap['map']}/thumbnail`);
        return (
            <ResizableListItem style={style} key={index} index={index} resize={resize} padding={15}>
                {resizeRow => (
                    <CardContainer>
                        <CardStyled>
                            <Box display="flex" flexGrow={1} flexDirection={isMobile ? 'column' : 'row'}>
                                {thumbnail && (
                                    <Box display="flex" pt={3} pb={3} pl={3}>
                                        <CardImage image={thumbnail} className={isMobile ? 'mobile' : ''} />
                                        <Divider orientation="vertical" />
                                    </Box>
                                )}
                                <Box flexGrow={1}>
                                    <CardHeaderStyled
                                        title={<RenderName data={data} />}
                                        subheader={<RenderId type={type} id={id} />}
                                        action={children}
                                    />
                                    <CardDividerTitle />
                                    <CardContentStyled>
                                        <Box mb={2}>{this.renderIcon(id, permissions)}</Box>
                                        <Box>
                                            <CardInfo>
                                                Last modified{' '}
                                                <Tooltip title={displayByKind('datetime', modifiedDate)} placement="bottom">
                                                    <span>{fromNow(modifiedDate)} </span>
                                                </Tooltip>
                                                {' by '}
                                                <span>{modifiedBy ? modifiedBy.name : 'none'}</span>
                                            </CardInfo>
                                            <CardInfo>
                                                Data Owner <span>{createdBy.name}</span>
                                            </CardInfo>
                                            <CardInfo noDivider>
                                                Created{' '}
                                                <Tooltip title={displayByKind('datetime', createdDate)} placement="bottom">
                                                    <span>{fromNow(createdDate)}</span>
                                                </Tooltip>
                                            </CardInfo>
                                        </Box>
                                        {(classes || []).length > 0 && <Box mt={4}>{this.buildClasses(classes)}</Box>}
                                    </CardContentStyled>
                                </Box>
                            </Box>
                        </CardStyled>
                    </CardContainer>
                )}
            </ResizableListItem>
        );
    }
}

export default connect(null, {
    showToastr
})(withTheme(MapsCardItem));
