/* @flow */

export default `
    id
    time
    data
    severity
    alarmId
    description
    alarmCode
    alarmType
    clearTime
    streamTime
    device {
      id
      name
      type
    }
    descriptor {
      id
      name
      type
    }
    status
    eventType {
      id
      name
      metadata
      script {
        id
        type
        name
      }
      bpmnSignal
    }
    modifiedDate
    modifiedBy {
      id
      name
      image
    }
    createdDate
    createdBy {
      id
      name
      image
    }
`;
