/* @flow */

import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ListSubheader, Grid, Divider } from '@mic3/platform-ui';
import { connect } from 'react-redux';

import ActionOpenForm from 'app/components/organisms/Actions/ActionOpenForm';
import ActionStartProcess from 'app/components/organisms/Actions/ActionStartProcess';
import FormPanel from 'app/containers/Designer/Form/components/FormPanel';
import FormValidator from 'app/utils/validator/FormValidator';
import GroupRepeat from 'app/containers/Designer/Form/components/GroupRepeat';
import Immutable from 'app/utils/immutable/Immutable';
import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { deepEquals, isEmpty, arrayObjectEquals } from 'app/utils/utils';
import { evaluateProperty } from 'app/utils/designer/form/fieldUtils';
import { get, set } from 'app/utils/lo/lo';
import { getFieldByType, fillPropertiesByType } from 'app/utils/designer/form/fieldUtils';
import { showToastr } from 'store/actions/app/appActions';
import { startProcessReset } from 'store/actions/abox/processActions';
import { addDefaultValues } from 'app/utils/form/formGenerator';
import { enrichContext } from 'app/utils/designer/form/formUtils';
import ErrorBoundary from 'app/components/atoms/ErrorBoundary/ErrorBoundary';

const DividerverticalStyled = styled(Divider)`
    margin: 4px 16px !important;
    height: 40px !important;
    align-self: center !important;
`;

const DividerStyled = styled(Divider)`
    margin: 10px 0 32px 0 !important;
`;

const GridStyled = styled(Grid)`
    padding: ${({ root }) => root ? '0 16px' : '0'};
    width: 100% !important;
    & > div {
       margin: auto 0;
    }
    ${({ direction }) => {
    if (direction !== 'row') {
        return '';
    }
    return `
        & > :first-child {
           padding-left: 0px;
        }
        & > :last-child {
           padding-right: 0px;
        }
        `;
}}
`;

const ListSubheaderStyled = styled(ListSubheader)`
    line-height: 15px !important;
    margin-top: 15px !important;
`;

export const availableActions = Immutable(new Set(['showMessage', 'openForm', 'startProcess', 'link', 'navigate']));

class FormGeneratorComponent extends PureComponent<Object, Object> {

    static propTypes = {
        components: PropTypes.arrayOf(PropTypes.object).isRequired,
        data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
        context: PropTypes.object,
        onChange: PropTypes.func,
        onValidate: PropTypes.func,
        rowDirection: PropTypes.bool,
        addDividers: PropTypes.bool,
        disabled: PropTypes.bool,
        isValidateOnChange: PropTypes.bool,
        /*
         * Normalizing onchange argument to { target: { value, name, data } }
        */
        normalizeOnChange: PropTypes.bool,
        /*
         * Came from cards
         * If @editablePanel it's true we will render extra designed view for components
        */
        editablePanel: PropTypes.bool,
        editable: PropTypes.bool,

        avoidValidation: PropTypes.bool,
        /*
         the name to use to nest errors in nested FormGenerator
         that are using just a subset of the data
         */
        name: PropTypes.string,
        /*
         indicates if this is the root form
         (to apply the correct style)
         */
        root: PropTypes.bool,
        /*
         the "inherited" prop contains information hinerited by the parent form
         if any and if this form is using a subset of the data, otherwise it is null
         */
        inherited: PropTypes.shape({
            // the data inherited from the root form
            data: PropTypes.object,
            // the path of the data used by this form
            path: PropTypes.string,
            // the "index" if this form is part of a "group repeat" item, -1 otherwise
            index: PropTypes.number,
            // the "changeData" function of the root form
            changeData: PropTypes.func,
        }),
    };

    static defaultProps = {
        editablePanel: false,
        components: [],
        data: {},
        action: '#',
        root: true,
        inherited: null,
        avoidValidation: false,
    }

    validator: Object;
    customComponentCache: Object;
    subForms: Array<any>;

    constructor(props: Object) {
        super(props);
        const { components, name: path } = props;
        this.validator = new FormValidator(components, path);
        this.customComponentCache = {};
        this.state = {
            /*
             * Constains the list of datas changed using the method changeData
             */
            dataChanges: new Set(),
            /*
             * if true, after the validation, all the fields with errors will shown it,
             * otherwise only the fields in dataChanges will show the error
             */
            validateAll: false,
            data: addDefaultValues(components, props.data || {}),
            errors: null,
            formTouched: false
        };
    }

    componentDidUpdate(prevProps: Object) {
        const { components, data, name: path, isValidForm } = this.props;
        if (!arrayObjectEquals(components, prevProps.components)) {
            this.validator = new FormValidator(components, path);
            if(this.state.errors) {
                isValidForm ? isValidForm() : this.isValidForm();
            }
            this.setState({ data: addDefaultValues(components, data || {}), });
        }
        if (data !== prevProps.data) {
            if (!deepEquals(this.state.data, data)) {
                this.setState({ data: addDefaultValues(components, data || {}) });
            }
        }
    }

    async setValidateAll(validateAll: boolean) {
        const setThis = new Promise((resolve) => {
            this.setState({ validateAll }, resolve);
        });
        return Promise.all([
            setThis,
            ...(this.subForms || []).map(ref => ref.current && ref.current.setValidateAll(validateAll))
        ]);
    }

    @bind
    onChange(data: Object) {
        const { avoidValidation } = this.props;
        const { name, value } = data;
        const handleOnChange = () => {
            const { onChange, normalizeOnChange } = this.props;
            if ((avoidValidation || !get(this.state.errors, name) || this.props.isValidateOnChange) && onChange ) {
                if(normalizeOnChange) {
                    onChange({ target: { name, value, data: this.state.data }});
                } else {
                    onChange(this.state.data, data);
                }
            }
        };
        if (this.props.isValidateOnChange || !avoidValidation && get(this.state.errors, name)) {
            this.validate(handleOnChange);
        } else {
            handleOnChange();
        }
    }

    @bind
    actionOpenForm(actionOpenForm = {}) {
        this.setState({
            actionOpenForm: actionOpenForm.id ? actionOpenForm : null,
        });
    }

    @bind
    actionStartProcess(actionStartProcess = {}) {
        this.props.startProcessReset();
        this.setState({ actionStartProcess: actionStartProcess.id ? actionStartProcess : null});
    }

    @bind
    actionShowMessage({ type, message }: Object) {
        const { showToastr } = this.props;
        const MessageTypes = new Set(['info', 'success', 'warning', 'error']);
        showToastr({ severity: MessageTypes.has(type) ? type : 'success', detail: message });
    }

    @bind
    performActions(action, options) {
        if (!availableActions.has(action)) {
            console.warn(`invalid execution of "${action}" action .`); // eslint-disable-line no-console
            return;
        }
        switch (action) {
            case 'showMessage': this.actionShowMessage(options); break;
            case 'openForm': this.actionOpenForm(options); break;
            case 'startProcess': this.actionStartProcess(options); break;
            case 'link': window.open(options.href); break;
            case 'navigate': window.location.href = options.href; break;
            default:
        }
    }

    @bind
    handleFormTouchField() {
        const { root, onFormTouched } = this.props;
        
        if (!root) {
            onFormTouched && onFormTouched();
        } else {
            this.handleFormTouchParent();
        }
    }

    @bind
    handleFormTouchParent() {
        if (this.props.root) {
            this.setState({ formTouched: true });
        }
    }

    @bind
    changeData({ name, value }) {
        if(!name || get(this.state.data, name || '') === value) {
            return Promise.resolve();
        }
        return new Promise<Object>((resolve) => {
            const data = set(this.state.data, name, value);
            let dataChanges = this.state.dataChanges;
            if (!dataChanges.has(name)) {
                dataChanges = new Set([ ...dataChanges.values(), name]);
            }
            this.setState({ data, dataChanges }, () => {
                this.onChange({ name, value });
                resolve(this.state.data);
            });
        });
    }

    @bind
    triggerOnError(error) {
        this.props.onError && this.props.onError(error);
    }

    @bind
    onStartChanges() {
        if(this.props.onStartChanges) {
            return this.props.onStartChanges();
        }
    }
    @bind
    onEndChanges() {
        if(this.props.onEndChanges) {
            return this.props.onEndChanges();
        }
    }

    @bind
    async isValidForm() {
        await this.setValidateAll(true);
        return new Promise((resolve) => {
            this.validate(() => {
                const { errors, data } = this.state;
                resolve({ data, errors });
            });
        });
    }

    @bind
    pushSubFormReferences(ref) {
        this.subForms.push(ref);
        return this.subForms;
    }

    @bind
    async validate(fn: Function = () => {}) {
        const { context, inherited } = this.props;
        let errors = await this.validator.getErrors(this.state.data, enrichContext(context), inherited);
        const ranValidations = this.subForms.map(async (reference) => {
            const form = reference.current;
            const err = form && await form.validate();
            return Object.entries(err || {}).forEach(([key, error]) => {
                if (form.props.name) {
                    errors = set(errors, `${form.props.name}.${key}`, error);
                } else {
                    errors = set(errors, key, error);
                }
            });
        });
        await Promise.all(ranValidations);
        this.setState({ errors }, fn);
        this.props.onValidate && this.props.onValidate(errors);
        if (!isEmpty(errors)) {
            this.triggerOnError(errors);
        }
        return errors;
    }

    // helped function for keeping buildComponents memoized
    @bind
    @memoize()
    buildComponents(
        components: Array<Object>, data: Object, context: Object, inherited: Object,
        validator: Object, errors: Object, disabled: boolean, editablePanel: boolean, editable: boolean, addDividers: boolean
    ): Array<Object> {
        this.subForms = [];
        return components.map((component, index) =>
            this._buildComponent(
                component, index, data, context, inherited,
                validator, errors, disabled, editablePanel, editable, addDividers
            )
        );
    }

    @bind
    _buildComponent(
        component: Object, index: number, data: Object, context: Object, inherited: Object,
        validator: Object, errors: Object, disabled: boolean, editablePanel: boolean, editable: boolean, addDividers: boolean
    ) {
        const { dataChanges, validateAll } = this.state;
        const { isMobile, isValidForm } = this.props;
        const { changeData, performActions } = this;
        const { type, constraints } = component;
        const { required } = constraints || {};
        const {
            addButtons, rowDirection, gridProps, ...restProperties
        } = component.properties || {};
        const stateData = Immutable(this.state.data);

        // set default properties
        const properties = fillPropertiesByType(type, restProperties);

        // add on touch event
        properties.onFormTouched = this.handleFormTouchField;

        // if disabled property is function
        if (properties.disabled && typeof properties.disabled === 'function') {
            properties.disabled = properties.disabled(data);
        }
        if(disabled) {
            properties.disabled = true;
        }

        if (type === 'subheader') {
            return (
                <ListSubheaderStyled key={index}>
                    {properties.label}
                </ListSubheaderStyled>
            );
        }

        // getting the field value
        let value = null;
        const name = properties.name;
        if (name) {
            try {
                value = get(data, name);
            } catch (e) {
                console.error(`${name} - name of the field has to be valid`); // eslint-disable-line no-console
            }
        }
        if(!value && type === 'hyperlink') {
            value = properties.value;
        }

        // Render error propertires
        let errorProps = {};
        if (validateAll || dataChanges.has(name)) {
            const messages = validator.formatMessages(name, component);
            errorProps = messages
                ? { error: errors && true, helperText: messages.join('\n') }
                : {};
        }

        // build component
        let builtComponent = null;
        let children = null;
        if (component.children) {
            if (type === 'group' || type === 'groupRepeat') {
                inherited = {
                    data: inherited ? inherited.data : data,
                    path: inherited ? `${inherited.path}.${name}` : name,
                    changeData: inherited ? inherited.changeData : changeData,
                    performActions: inherited ? inherited.performActions : performActions,
                    index: inherited ? inherited.index : -1,
                };
            }
            let props = {
                ...properties,
                ...errorProps,
                name,
                type,
                changeData: () => {},
                performActions,
                data: stateData,
                context,
                inherited
            };
            const onFormTouched = this.props.root ? this.handleFormTouchParent : this.props.onFormTouched;

            const getGroupComponent = () => {
                const isNameDefined = !!name;
                const newData = isNameDefined ? get(stateData, name) : data;
                const reference = React.createRef();
                this.subForms.push(reference);
                return (
                    <FormGenerator
                        avoidValidation
                        isValidForm={isValidForm || this.isValidForm}
                        onStartChanges={this.onStartChanges}
                        onEndChanges={this.onEndChanges}
                        addDividers={props.addDividers}
                        ref={reference}
                        name={name}
                        components={component.children}
                        data={newData}
                        context={context}
                        rowDirection={!isMobile &&!!rowDirection}
                        gridProps={gridProps}
                        onChange={(data, change) => {
                            if (!change.name) {
                                return Promise.resolve();
                            }
                            return this.changeData({
                                name: isNameDefined ? `${name}.${change.name}` : change.name,
                                value: change.value,
                            });
                        }}
                        onFormTouched={onFormTouched}
                        root={false}
                        inherited={inherited}
                        disabled={disabled}
                        editablePanel={editablePanel}
                        editable={editable}
                    />
                );
            };

            switch (type) {
                case 'group': {
                    children = getGroupComponent();
                    builtComponent = getFieldByType(type, props, children, this.customComponentCache);
                    break;
                }
                case 'groupRepeat': {
                    if (!name) {
                        showToastr({ severity: 'error', detail: 'The group repeat "name" is required.' });
                    }
                    let dataList = get(stateData, name || '', []);
                    if (!Array.isArray(dataList)) {
                        console.error('[GroupRepeat] Reference to data is wrong, not an array.'); // eslint-disable-line no-console
                        dataList = [];
                    }
                    builtComponent = (
                        <GroupRepeat
                            onStartChanges={this.onStartChanges}
                            onEndChanges={this.onEndChanges}
                            data={stateData}
                            dataList={dataList}
                            pushSubFormReferences={this.pushSubFormReferences}
                            rowDirection={!isMobile && !!rowDirection}
                            gridProps={gridProps}
                            addDividers={addDividers}
                            name={name}
                            index={index}
                            changeData={this.changeData}
                            component={component}
                            context={context}
                            addButtons={addButtons}
                            inherited={{ ...inherited, index }}
                            disabled={disabled || restProperties?.disabled}
                            editablePanel={editablePanel}
                            editable={editable}
                            onFormTouched={onFormTouched}
                            avoidValidation
                        />
                    );
                    break;
                }
                case 'panel': case 'iotPanel': {
                    const reference = React.createRef();
                    this.subForms.push(reference);
                    props = evaluateProperty(props, 'header');
                    const isHiddenPanel = props.isHiddenPanel;
                    if(isHiddenPanel) {
                        children = getGroupComponent();
                        builtComponent = getFieldByType('group', props, children, this.customComponentCache);
    
                    } else {
                        builtComponent= (
                            <FormPanel
                                {...props}
                                type={type}
                                isValidForm={isValidForm || this.isValidForm}
                                changeData={(evnt) => this.changeData(evnt || {})}
                                onStartChanges={this.onStartChanges}
                                onEndChanges={this.onEndChanges}
                                title={props.header}
                                ref={reference}
                                components={component.children}
                                data={data}
                                context={context}
                                onChange={(data, change) => this.changeData(change)}
                                onFormTouched={onFormTouched}
                                inherited={inherited}
                                disabled={type === 'iotPanel' ? true : disabled}
                                avoidValidation
                            />
                        );
                    }
                    break;
                }
                default: {
                    const reference = React.createRef();
                    this.subForms.push(reference);
                    children = (
                        <FormGenerator
                            isValidForm={isValidForm || this.isValidForm}
                            onStartChanges={this.onStartChanges}
                            onEndChanges={this.onEndChanges}
                            ref={reference}
                            components={component.children}
                            data={data}
                            context={context}
                            onChange={(data, change) => this.changeData(change)}
                            onFormTouched={onFormTouched}
                            root={false}
                            inherited={inherited}
                            disabled={disabled}
                            editablePanel={editablePanel}
                            editable={editable}
                            addDividers={props.addDividers}
                            avoidValidation
                        />
                    );
                    builtComponent = getFieldByType(type, props, children, this.customComponentCache);
                    break;
                }
            }
        } else {
            let componentProperties = {
                ...properties, ...errorProps,
                name, type, value, required, changeData,
                data, context, inherited, performActions,
                onStartChanges: this.onStartChanges,
                onEndChanges: this.onEndChanges,
                pushSubFormReferences: this.pushSubFormReferences
            };
            let componentType = type;
            if(type === ('panel' || 'iotPanel')) {
                componentProperties = { ...componentProperties, editablePanel, editable };
                if(properties.isHiddenPanel){
                    componentType = 'group';
                }
            }
            if(['button', 'outcome', 'openAppButton'].includes(type)) {
                componentProperties.isValidForm = isValidForm || this.isValidForm;
            }
            builtComponent = getFieldByType(componentType, componentProperties, null, this.customComponentCache);
        }

        return (
            <Fragment key={index}>
                {this.buildDivider(index, addDividers, !isMobile && rowDirection)}
                {builtComponent}
            </Fragment>
        );
    }
    

    @bind
    buildDivider(index, addDividers, rowDirection) {
        const isRenderDivider = addDividers && index !== 0 && index % 2;
        if(!isRenderDivider) {
            return null;
        }
        if(rowDirection) {
            return (
                <DividerverticalStyled orientation="vertical" flexItem />
            );
        }
        return (
            <DividerStyled />
        );
    }

    render() {
        const {
            addDividers, components, context, editablePanel, editable, rowDirection, root, inherited, className, gridProps, disabled, isMobile 
        } = this.props;
        const { data, errors, actionOpenForm, actionStartProcess } = this.state;
        let extraGridProps = gridProps || {};
        if(!isMobile && !!rowDirection) {
            extraGridProps = {
                ...extraGridProps,
                wrap: 'nowrap',
                direction: 'row',
                container: true,
            };
        }
        return (
            <ErrorBoundary>
                <GridStyled root={root?1:0} className={className} {...extraGridProps} >
                    {this.buildComponents(
                        components || [], data, enrichContext(context), inherited,
                        this.validator, errors, disabled, editablePanel, editable, addDividers
                    )}
                </GridStyled>
                {
                    actionOpenForm &&
                    <ModalDialog open onClose={this.actionOpenForm}>
                        <ActionOpenForm formId={actionOpenForm.id} data={actionOpenForm.data} context={context} />
                    </ModalDialog>
                }
                {
                    actionStartProcess &&
                    <ModalDialog open onClose={this.actionStartProcess} title="Start Process">
                        <ActionStartProcess processDefinitionId={actionStartProcess.id} data={actionStartProcess.data} />
                    </ModalDialog>
                }
            </ErrorBoundary>
        );
    }
}


const FormGenerator = connect(
    (state) => ({
        isMobile: state.global.isMobile 
    }),
    { showToastr, startProcessReset  },
    null,
    { forwardRef: true }
)(FormGeneratorComponent);

export default FormGenerator;
