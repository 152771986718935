/* @flow */

import { gql } from '@apollo/client';

export default gql`
query loadTeamsTypeaheadQuery {
    records: teamReferences{
        id
        name
        isSystem
        category
        workspace{
            id
            name
  	    }
        __typename
    }
}
`;
