//@flow

import WKT from 'ol/format/WKT';
import { fromExtent } from 'ol/geom/Polygon';
import { containsExtent } from 'ol/extent';
import olMap from 'app/containers/Maps/SituationalAwareness/olMapController';
import { toLonLat } from 'ol/proj';
import { isDefined } from 'app/utils/utils';

class AffectliVectorLayer {
    info: any;
    olMap: olMap;
    features: [];
    isFeatureLoaded: boolean;
    isLoadingError: boolean;
    geojson: Object;

    constructor(info, map) {
        this.info = info;
        this.olMap = map;
        this.features = [];
        this.isFeatureLoaded = false;
        this.isLoadingError = false;
        this.allFeatures = [];
    }

    getFeatureStatus() {
        return this.isFeatureLoaded;
    }

    setFeatureStatus(isFeatureLoaded) {
        if (!isFeatureLoaded) this.allFeatures.length = 0;
        this.isFeatureLoaded = isFeatureLoaded;
    }

    getLayerInfo() {
        return this.info;
    }

    getLayerMainType() {
        const { type, entityType } = this.info || {};
        return type || entityType;
    }

    getBBoxWKTFromExtent(extent) {
        const wktFormat = new WKT();
        const bbox = wktFormat.writeGeometry(fromExtent(extent), {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857'
        });
        return bbox;
    }

    getMapController() {
        return this.olMap;
    }

    addFeature(feature) {
        try {
            if(feature?.getGeometry()?.getType() !== 'GeometryCollection') {
                const geom = feature?.getGeometry();
                const coords = geom?.getCoordinates();
                // to avoid divided by zero error in 3d due to Z value.
                if (coords?.length === 3) {
                    const newCoords = [coords[0], coords[1]];
                    feature.getGeometry().setCoordinates(newCoords);
                }
                this.allFeatures.push(feature);
                this.features.push(feature);
            }
            else {
                this.allFeatures.push(feature);
                this.features.push(feature);
                
            }
        }
        catch(e) {
            // eslint-disable-next-line no-console
            console.log('Error in adding feature, ', feature);
        }
      
    }

    addFeatures(features) {
        this.features = features;
    }

    removeAllFilters() {
        this.features = [];
        this.allFeatures.forEach(f => this.features.push(f));
    }

    getFeatureById(id) {
        const filterFeature = this.features.find(feature => feature.getProperties().attributes.id === id);
        return filterFeature;
    }

    filteredFeaturesByName(layerSearch) {
        const filteredFeature = this.features.filter((feature) => {
            const { name } =  feature && (feature.attributes || feature?.values_?.attributes);
            return name && name.toLowerCase().includes(layerSearch.toLowerCase());
        });
        return filteredFeature;
    }

    getFeatures() {
        const promise = new Promise((resolve, reject) => {
            const interval = setInterval(() => {
                if (this.isFeatureLoaded || this.isLoadingError) {
                    window.clearInterval(interval);
                    resolve(this.features);
                }
            }, 1000);
        });

        return promise;
    }

    getFeatureUnderExtent(extent) {
        const features = this.features;
        const pointLayers = ['bubble', 'cluster'];
        const styleType = this.info?.styles?.pinStyle || 'cluster';
        // eslint-disable-next-line array-callback-return
        return features.filter((feature) => {
            try {
                if (typeof feature.getGeometry === 'function' && feature.getGeometry()) {
                    const geomType = feature.getGeometry().getType();
                    if (pointLayers.includes(styleType)) {
                        if (extent && geomType === 'Point') {
                            return containsExtent(extent, feature.getGeometry().getExtent());
                        }
                    } else {
                        if (geomType === 'GeometryCollection') {
                            const geometries = feature?.getGeometry()?.getGeometries();
                            if (geometries) {
                                return geometries.some(geometry => containsExtent(extent, geometry.getExtent()));
                            }
                        } else {
                            return containsExtent(extent, feature.getGeometry().getExtent());
                        }
                    }
                }
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error('Bad feature under extent', e);
            }
        });
    }

    clearFeatures() {
        this.allFeatures.length = 0;
        this.features.length = 0;
    }

    toGeoJSON() {
        this.geojson = null;
        const features = [];
        this.features.forEach((feature, index) => {
            if (isDefined(feature?.values_?.geometry) && feature?.getGeometry().getType() === 'Point') {
                const coordinates = toLonLat(feature?.getGeometry().getCoordinates());
                if (coordinates.length >= 3) {
                    coordinates.pop();
                }
                const properties = feature?.getProperties();
                delete properties['geometry'];
                features.push({
                    type: 'feature',
                    geometry: {
                        type: 'Point',
                        coordinates: coordinates
                    },
                    properties: properties
                });
            }
        });
        this.geojson = {
            type: 'FeatureCollection',
            features: features
        };
        return this.geojson;
    }
}

export default AffectliVectorLayer;
