/* @flow */

import { combineReducers } from 'redux';

import { dataTableReducer, loadDataReducer, keepDataAtStart } from 'app/utils/redux/reducer-utils';
import {
    CREATE_ORGANISATION_STARTED,
    CREATE_ORGANISATION,
    LOAD_ORGANISATIONS_LIST_VIEW_STARTED,
    LOAD_ORGANISATIONS_LIST_VIEW
} from 'store/actions/entities/organisationsActions';

export default combineReducers<Object, Object>({
    list: dataTableReducer(LOAD_ORGANISATIONS_LIST_VIEW_STARTED, LOAD_ORGANISATIONS_LIST_VIEW, keepDataAtStart),
    create: loadDataReducer(CREATE_ORGANISATION_STARTED, CREATE_ORGANISATION)
});
