/* @flow */

import { combineReducers } from 'redux';

import {
    LOAD_ASSETS_STARTED, LOAD_ASSETS,
    LOAD_DIGITAL_TWIN_STARTED, LOAD_DIGITAL_TWIN,
    LOAD_PROCESS_DEFINITION_STARTED, LOAD_PROCESS_DEFINITION,
    LOAD_PROCESS_AND_TASKS_STARTED, LOAD_PROCESS_AND_TASKS,
} from 'store/actions/common/digitalTwinActions';
import { loadDataReducer } from 'app/utils/redux/reducer-utils';
import template from 'store/reducers/common/digitalTwin/template/digitalTwinTemplateReducer';

export default combineReducers({
    template,
    assets: loadDataReducer(LOAD_ASSETS_STARTED, LOAD_ASSETS),
    details: loadDataReducer(LOAD_DIGITAL_TWIN_STARTED, LOAD_DIGITAL_TWIN),
    processDefinition: loadDataReducer(LOAD_PROCESS_DEFINITION_STARTED, LOAD_PROCESS_DEFINITION),
    processAndTasks: loadDataReducer(LOAD_PROCESS_AND_TASKS_STARTED, LOAD_PROCESS_AND_TASKS),
});
