// @flow

import React, { memo } from 'react';
import { Tooltip, ListItem, Box } from '@mic3/platform-ui';
import { muiTheme } from 'app/themes/materialUi';
import { Divider } from '@mic3/platform-ui';

type Props = {
    navOpen?: boolean,
};

const AppNavFooter = ({ navOpen, ...otherProps }: Props) => {
    const imageUrl = navOpen ? muiTheme.logo.appNavFooterLogo : './images/logo-32x32.png';
    return (
        <div style={{ position: 'fixed', bottom: 0, width: navOpen ? '260px' : '56px', backgroundColor: muiTheme.colors.background.side, paddingTop: '5px' }}>
            <Divider />
            <div {...otherProps}>
                <Tooltip title='Go to affectli.com'>
                    <ListItem {...otherProps} component='div'>
                        <Box paddingTop={1}>
                            <a href='https://affectli.com' target='_blank' rel='noreferrer noopener'>
                                <img className={!navOpen ? 'icon' : ''} src={imageUrl} alt='logo' />
                            </a>
                        </Box>
                    </ListItem>
                </Tooltip>
            </div>
        </div>
    );
};

export default memo<Props>(AppNavFooter);
