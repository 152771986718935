/* @flow */

import { gql } from '@apollo/client';

import { workspaceFields, classWorkspaceFields } from 'graphql/entities/entities/loadEntityWorkspacesQuery';

export default type => gql`
    mutation shareEntityWithWorkspacesMutation($id: ID!, $workspaces: [ID]!) {
        result: ${type === 'class' ? 'shareClassWithWorkspaces': 'shareEntityWithWorkspaces'}(id: $id, workspaces: $workspaces${type === 'class' ? '' : `, type: "${type}"`}) {
            ${type === 'class' ? classWorkspaceFields : workspaceFields}
        }
    }
`;
