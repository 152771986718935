// @flow
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Card, CardMedia, Divider, CardHeader, Typography, Button } from '@mic3/platform-ui';
import Icon from 'app/components/atoms/Icon/Icon';
import uuidv1 from 'uuid/v1';
import { getAttachmentUrl } from 'app/utils/attachments/attachmentsUtils';
import DrawingCarousal from 'app/components/molecules/Map/LayerList/DrawingCarousal';
import { layerTypes, TASKLAYERS, PROCESSLAYERS, __findMapLayer } from 'app/utils/maps/layer/layerUtils';
import cesiumLayerConfig from 'app/config/cesiumLayerConfig';
import { useSelector, useDispatch } from 'react-redux';
import { saveComponentState } from 'store/actions/component/componentActions';
import { get } from 'app/utils/lo/lo';
import history from 'store/History';

const HeaderTitle = styled.span`
    font-size: 0.9rem;
    align-items: center;
    display: inline-flex;
`;

const CardImage = styled(CardMedia)`
    && {
        width: 95%;
        height: 170px;
        margin: auto;
        margin-top: 10px;
        margin-bottom: 15px;
    }
`;

const StyledDiv = styled.div`
        margin: 10px 0 0 20px;
`;
const StyledButtonDiv = styled.div`
        display: flex;
        justify-content: right;
        margin-right: 12px;
`;

const AboutComponent = (data: Object) => {
    const { image, id, title, type, isOld, operatorsMap, filters, uri, event_type, features, isChanged } = data || {};
    const [initialLoad, setInitialLoad] = useState(true);
    const mapData = useSelector((state) => state.maps.situationalAwareness.map.data);
    const name = useMemo(() => __findMapLayer(mapData, { id })?.name, [mapData, id]);
    useEffect(() => {
        setInitialLoad(false);
    }, []);

    const isButtonDisabled = !initialLoad && isChanged;
    const { icon, type: iconType } = layerTypes.find(lyr => lyr.name === title) || {};
    const cesiumOldType = cesiumLayerConfig?.cesiumLayer?.oldType;
    const cesiumType = isOld ? cesiumOldType : type;
    let viewId = '';
    if(event_type)
        viewId = `EventsListView_${event_type?.id}`;
    else if(TASKLAYERS.includes(uri)) 
        viewId = `TaskList`;
    else if(PROCESSLAYERS.includes(uri))
        viewId = `ProcessesListView`;
    else 
        viewId = `EntitiesListView.${uri}`;

    const dispatch = useDispatch();
    const filterViewState = useSelector(
        state => get(state, `component.state.${viewId}`),
    );
    const onGoToClick = useCallback(
        async () => {
            const { type, uri, event_type } = data;
            if (event_type) {
                const orderBy = { field: 'time', direction: 'desc nulls last' };
                const { time_range } = filters || {};
                await dispatch(
                    saveComponentState(viewId, {
                        ...filterViewState,
                        filters: { ...(filters || {}), time: time_range },
                        operatorsMap,
                        isPreferenceLoaded: true,
                        orderBy,
                    })
                );
                return history.push(`/events/eventType/panel/${event_type?.id}`);
            }
            const  entityOrderBy = { field: 'modifiedDate', direction: 'desc nulls last' };
            await dispatch(saveComponentState(viewId, { ...filterViewState, filters, operatorsMap, isPreferenceLoaded: true, orderBy: entityOrderBy }));
            if (TASKLAYERS.includes(uri)) {
                return history.push(`/abox/tasks`);
            }
            if (PROCESSLAYERS.includes(uri)) {
                return history.push(`/abox/processes`);
            }
            if(type === 'entity') {
                return history.push(`/entities/${uri}`);
            }
            
        },
        [dispatch, viewId, filters, operatorsMap, filterViewState, data],
    );

    return (<Card>
        <CardHeader
            title={
                <HeaderTitle>
                    <Icon name={icon} type={iconType || 'mdi'} />
                    <Typography style={{ paddingLeft: '10px' }}>{title}</Typography>
                </HeaderTitle>
            }
        />
        <StyledDiv>
            <Typography variant="h5">{ name }</Typography>
            {/* <GridEntityId fullValue value={getEntityUrl(id, cesiumType )} label={id} valueId={id}/> */}
            {image && <CardImage image={getAttachmentUrl(id, cesiumType, image)} />}
        </StyledDiv>
        <Divider />
        {type && !['drawing','wms', 'system_cesium_layer'].includes(type) && <StyledButtonDiv><Button disabled={isButtonDisabled} variant='text' onClick={onGoToClick}>GO TO DATA</Button></StyledButtonDiv>}
        {features?.length > 0 && type === 'drawing' && <DrawingCarousal features={features} /> }
    </Card>);
};

const commonConstraints = (name: string, ref) => ({
    custom: () => {
        if (ref && ref.current && ref.current.hasErrors()) {
            return { format: { message: `${name} form is not valid` } };
        }
        return {};
    }
});

const commonAbout = (data: Object) => [
    {
        field: '',
        type: 'custom',
        properties: {
            label: '',
            cid: uuidv1(),
            disabled: true,
            Component: () => <AboutComponent { ...data } />
        }
    },
    {
        field: 'panel',
        type: 'panel',
        properties: {
            header: 'Description',
            expanded: false,
        },
        children: [
            {
                field: 'description',
                type: 'textarea',
                properties: {
                    label: 'Description',
                    name: 'description',
                    disabled: data?.isOld
                }
            }
        ]
    }
];

export {
    commonAbout,
    commonConstraints
};
