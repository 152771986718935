/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { AutocompleteLazy, ListItemText } from '@mic3/platform-ui';

import { bind } from 'app/utils/decorators/decoratorUtils';
import { TypeaheadListItem } from 'app/components/organisms/Form/Typeahead/abstract/TypeaheadListItem';

import { loadProjectionList } from 'store/actions/maps/situationalAwarenessActions';
import { connect } from 'react-redux';

/**
 * Search a location
 */
class ProjectionTypeahead extends PureComponent<Object, Object> {
    static propTypes = {
        /* eslint-disable-next-line react/forbid-foreign-prop-types */
        ...AutocompleteLazy.propTypes,
        options: PropTypes.array
    };

    constructor(props: Object) {
        super(props);
        this.state = {
            isLoading: false
        };
    }

    @bind
    optionTemplate({ srid, description, text }: Object) {
        return {
            option: (
                <TypeaheadListItem ContainerComponent="div" dense disableGutters>
                    <ListItemText primary={srid || ''} secondary={description || ''} />
                </TypeaheadListItem>
            ),
            label: description
        };
    }

    @bind
    async fetchData() {
        this.setState({ isLoading: true });
        const {data} = await loadProjectionList();
        this.setState({ isLoading: false });
        if (data.srids) {
            return data.srids;
        }
    }

    render() {
        return (
            <AutocompleteLazy
                {...this.props}
                VirtualListProps={{
                    itemSize: 100,
                }}
                isLoading={this.state.isLoading}
                optionTemplate={this.optionTemplate}
                placeholder={'Select Projection'}
                fetchData={this.fetchData}
            />
        );
    }
}

export default connect(
    null,
    {
        loadProjectionList
    }
)(ProjectionTypeahead);
