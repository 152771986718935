/* @flow */

// $FlowFixMe
import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { TextField, Autocomplete, Grid, IconButton, ListItemText, List, ListItem, ListItemSecondaryAction, MdiIcon } from '@mic3/platform-ui';
import DraggableElement from 'app/components/molecules/Dnd/DraggableElement';
import RowTarget, { EmptyRowTarget } from 'app/components/molecules/Dnd/RowTarget';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';

const DropHereStyled = styled.div`
    height: 128px;
    border: 2px dashed white;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const RowStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const directionOptions = [
    {label: 'ASC', value: 'asc'},
    {label: 'DESC', value: 'desc'},
];


class ArrayValuesPickerDnd extends PureComponent<Object, Object> {

    static propTypes = {
        value: PropTypes.arrayOf(PropTypes.object),
        uuid: PropTypes.string.isRequired,
    };

    static defaultProps = {
        root: true,
    }

    @bind
    onDrop(item: Object, index: number) {
        this.props.onDrop(item, this.props.uuid, index);
    }

    @bind
    deleteValue(event: Object) {
        const index = Number(event.target.dataset.index);
        this.props.deleteValue(this.props.value[index]);
    }

    @bind
    addValue() {
        this.props.addValue(
            { type: 'child', field: '', direction: 'asc nulls last' },
            this.props.uuid,
            this.props.value.length
        );
    }

    @bind
    @memoize()
    buildDropArea(values: Object, fieldType: string) {
        if (values.length === 0) {
            return (
                <RowTarget key={'empty'} index={0} onDrop={this.onDrop}>
                    <DropHereStyled>
                        <IconButton onClick={this.addValue}><MdiIcon name="plus" /></IconButton>
                    </DropHereStyled>
                </RowTarget>
            );
        }
        const listItems = [...values.map((val, i) => {
            const { uuid, field, direction } = val;
            const isLastValue = values.length === i+1;

            const target = (
                <RowStyled>
                    <TextField error={!field} onChange={this.props.onChange} variant="outlined" value={field} name={`field.${uuid}`} />
                    <Autocomplete onChange={this.props.onChange} clearable={false} variant="outlined" value={direction} name={`direction.${uuid}`} valueField="value" options={directionOptions} />
                </RowStyled>
            );
            return (
                <Fragment key={`${uuid}${i}`}>
                    <ListItem>
                        <ListItemText
                            primary={
                                <Fragment>
                                    <DraggableElement key={i} index={i} element={val}>
                                        <RowTarget
                                            index={i}
                                            onDrop={this.onDrop}
                                            label={'label'}
                                            element={val}
                                        >
                                            {target}
                                        </RowTarget>
                                    </DraggableElement>
                                </Fragment>
                            }
                        />
                        <ListItemSecondaryAction>
                            <IconButton aria-label="Delete" onClick={this.deleteValue}>
                                <MdiIcon data-index={i} name="close-circle" size={24}  />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                    {isLastValue && (
                        <Grid key={`${uuid}${i}uniq`} container justify="center">
                            <IconButton onClick={this.addValue}><MdiIcon name="plus" /></IconButton>
                        </Grid>
                    )}
                </Fragment>
            );
        }), <EmptyRowTarget key={'-1'} index={values.length} onDrop={this.onDrop} />];

        return <List>{listItems}</List>;
    };

    render() {
        const { fieldType, value } = this.props;
        return this.buildDropArea(value, fieldType);
    }
};

export default ArrayValuesPickerDnd;
