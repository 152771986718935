/* @flow */

import React, { Fragment, memo, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { muiTheme } from 'app/themes/materialUi';
import {  Menu, ClickAwayListener, MdiIcon, IconButton, Grid, Tooltip, Button } from '@mic3/platform-ui';
import { useToggle } from 'app/utils/hook/hooks';
import Breadcrumbs from 'app/components/organisms/Breadcrumbs/Breadcrumbs';

const IconsContainer = styled.div`
    display: flex;
`;
const ButtonContainer = styled.div`
    display: flex;
    padding: 8px 0px;
`;

const BreadcrumbsStyled = styled(Breadcrumbs)`
    flex-grow: 1;
`;

const GridWrapperStyled = styled(Grid)`
    padding: 0 8px;
`;

const inputProps: Object = { disableUnderline: true };

const FiltersToolbar  = ({
    moreMenuItems, classes, toggleDrawer, leftToolbar, rightToolbar, breadcrumbWithGoBack,
    isAnyDefinitions, moreIcons, breadcrumb, changed, cancelSaving, saveFilter
}: Object) => {
    inputProps.className = classes.searchInput;
    const anchorMenuIcon = useRef(null);
    const [isMenuOpen, toggleMenu] = useToggle();
    return (
        <GridWrapperStyled container alignItems="center" className={classes.searchBar}>
            <Grid container wrap="nowrap" alignItems="center" justify="flex-end">
                {breadcrumb && (
                    <BreadcrumbsStyled list={breadcrumb} withGoBack={breadcrumbWithGoBack} />
                )}
                {rightToolbar}
                {changed ? (
                    <ButtonContainer>
                        <Button variant="text" onClick={cancelSaving}>
                            Cancel
                        </Button>
                        <Button onClick={() => saveFilter('Columns saved successfully.')}>
                            Save
                        </Button>
                    </ButtonContainer>
                ) : (
                    <IconsContainer>
                        {moreIcons && moreIcons.length && moreIcons}
                        {isAnyDefinitions && (
                            <Tooltip title="Filters">
                                <IconButton onClick={toggleDrawer} edge={!moreMenuItems && 'end'}>
                                    <MdiIcon name="filter-variant" color={muiTheme.colors.appHeader.iconColor} />
                                </IconButton>
                            </Tooltip>
                        )}
                    </IconsContainer>
                )}
                {moreMenuItems && (
                    <Fragment>
                        <IconButton buttonRef={anchorMenuIcon} onClick={toggleMenu} edge="end"><MdiIcon name="dots-vertical" color={muiTheme.colors.appHeader.iconColor}/></IconButton>
                        <ClickAwayListener onClickAway={toggleMenu} mouseEvent="onClick">
                            <Menu open={isMenuOpen} anchorEl={anchorMenuIcon.current} onClose={toggleMenu} >
                                {moreMenuItems}
                            </Menu>
                        </ClickAwayListener>
                    </Fragment>
                )}
            </Grid>
            <Grid container wrap="nowrap" alignItems="center">
                {leftToolbar}
            </Grid>
        </GridWrapperStyled>
    );
};

FiltersToolbar.propTypes = {
    classes: PropTypes.object.isRequired,
    toggleDrawer: PropTypes.func.isRequired,
    isAnyDefinitions: PropTypes.bool.isRequired,
    leftToolbar: PropTypes.node,
    rightToolbar: PropTypes.node,
    moreMenuItems: PropTypes.array,
    moreIcons: PropTypes.arrayOf(PropTypes.element),
};


export default memo<Object>(FiltersToolbar);
