/* @flow */

import { gql } from '@apollo/client';

export default gql`
  mutation userUpdateTermsMutation {
    acceptTerms {
      mustAcceptTerms
    }
  }
`;
