import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { muiTheme } from 'app/themes/materialUi';
import uuidv1 from 'uuid/v1';

import { StringHelper } from '@bryntum/schedulerpro';
import { BryntumSchedulerPro } from '@bryntum/schedulerpro-react';

import EntityColumn from './TimelineRenderers/EntityColumn';
import { timelineConfig } from './TimelineConfig';

import { get } from 'app/utils/lo/lo';
import { bind, memoize, debounce } from 'app/utils/decorators/decoratorUtils';
import { getAttachmentUrl } from 'app/utils/attachments/attachmentsUtils';
import { arrayObjectEquals } from 'app/utils/utils';
import { successorTaskRelDefId, predecessorTaskRelDefId } from 'app/config/config';
import '@bryntum/schedulerpro/schedulerpro.classic-dark.css';


const TimelineWrapper = styled.div`
    width: 100%;
    height: calc(100% - 4px);
    overflow: auto;
    & .b-sch-event{
        background-color: ${({theme})=> theme.material.colors.background.paper} !important;
    }
    &
    .b-grid-header-container,
    .b-grid-header,
    .b-sch-timeaxiscolumn,
    .b-grid-panel-body,
    .b-grid-header-container .b-sch-timeaxiscolumn.b-depth-0:hover,
    .b-grid-header-container .b-sch-timeaxiscolumn .b-sch-header-timeaxis-cell:hover {
        background-color:${({theme})=> theme.material.colors.background.default} !important;
    }
    &
    .b-grid-header-text-content{
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: ${({theme})=> theme.material.colors.text.active }
    }
    &
    .b-sch-header-text{
        color: ${({theme})=> theme.material.colors.text.secondary};
    }
    &
    .b-grid-row.b-selected,
    .b-grid-row.b-hover,
    .b-grid-cell:hover,
    .b-gridbase:not(.b-moving-splitter) .b-grid-row:not( .b-group-row).b-hover .b-grid-cell:not(.b-focused){
        background:${({theme})=> theme.material.colors.background.hover} !important;
    }
    &
    .b-grid-row{
        border-bottom: 1px solid ${({theme})=> theme.material.colors.background.divider} !important;
    }
    & .b-gridbase.b-split .b-grid-splitter{
        background: ${({theme})=> theme.material.colors.background.divider} !important;
    }
    & .b-column-line {
        background:transparent !important;
    }
    & .b-grid-panel-body{
        border-top: 2px solid ${({theme})=> theme.material.colors.background.divider};
    }
    & .b-grid-header-container  {
        border-bottom: 2px solid ${({theme})=> theme.material.colors.background.divider};;
    }
    & .b-horizontaltimeaxis .b-sch-header-timeaxis-cell,
      .b-sch-header-timeaxis-cell{
        border: none !important;
    }
    & .b-horizontaltimeaxis .b-sch-header-row {
        flex: 1 0 45px !important;
    }
    & .b-column-line{
        border-color: transparent;
    }
    & .b-column-line-major{
        border-color: ${({theme})=> theme.material.colors.background.divider};
    }
    & .b-react-portal-container{
        width: -webkit-fill-available;
    }
    & .b-grid-cell{
        color: ${({theme})=> theme.material.colors.text.primary};
    }
    & .b-grid-header .b-sch-timerange.b-sch-current-time{
        background-color: ${({theme})=> theme.material.colors.background.activeElement} !important;
        border-radius: 0px 4px 4px 0px;
    }
    & .b-timeline-subgrid .b-sch-current-time {
        border-left-color: ${({theme})=> theme.material.colors.background.activeElement} !important;
    }

    & .b-task-percent-bar{
        background-color: ${({theme})=> theme.material.colors.background.fields};
    }

    .b-grid-cell.b-focused:after{
        border:1.5px solid ${({theme})=> theme.material.colors.background.active} !important;
    }
    & .b-sch-terminal{
        background-color: ${({theme})=> theme.material.colors.background.progress} !important;
    }
    & .b-timeline-subgrid{
        margin-left: 10px;
    }
    & .b-auto-container{
        gap:0 !important;
    }
    & .b-grid-header-container{
        gap:0 !important;
        padding-right: 10px !important;
    }
    .b-gridbase.b-split .b-grid-splitter:not(.b-disabled).b-hover .b-grid-splitter-inner{
        left: -4px !important;
    }
    .avatar{
        height: 18px;
        width: 18px;
        border-radius: 100%;
    }
    .spacing{
        margin:5px 10px;
    }
    .highest {
        border-top: 2px solid ${({ theme }) => get(theme.material.colors.priorityTaskColors, `1.1`)} !important;
    }
    .high {
        border-top: 2px solid ${({ theme }) => get(theme.material.colors.priorityTaskColors, `2.1`)} !important;
    }
    .medium {
        border-top: 2px solid ${({ theme }) => get(theme.material.colors.priorityTaskColors, `3.1`)} !important;
    }
    .low {
        border-top: 2px solid ${({ theme }) => get(theme.material.colors.priorityTaskColors, `4.1`)} !important;
    }
    .lowest {
        border-top: 2px solid ${({ theme }) => get(theme.material.colors.priorityTaskColors, `5.1`)} !important;
    }

    .closed-task{
        border-top:2px solid grey !important;
    }
    .b-sort-icon::before{
        display: none;
    }
    .b-gridbase:not(.b-masked).b-grid-empty .b-empty-text{
        background-color: transparent !important;
    }
    .b-gridbase.b-split .b-grid-splitter:not(.b-disabled) .b-grid-splitter-inner{
        background-color: inherit !important;
    }
    .b-gridbase.b-split .b-grid-splitter:not(.b-disabled) .b-grid-splitter-button-collapse, .b-gridbase.b-split .b-grid-splitter:not(.b-disabled) .b-grid-splitter-button-expand{
        font-size: 14px !important;
        padding: 0 !important;
        background-color: ${({theme})=> theme.material.colors.background.activeElement};
    }
    .b-grid-splitter-buttons{
        display: flex;
        flex-direction: row !important;
        border-radius: 50%;
        height: 28px !important;
        width: 28px !important;
        background-color: ${({theme})=> theme.material.colors.background.activeElement}; 
    }
    .b-gridbase.b-split .b-grid-splitter:not(.b-disabled) .b-grid-splitter-button-icon {
        fill: ${({theme})=> theme.material.colors.text.buttonActive} !important;
    }
    .b-grid-splitter:not(.b-disabled) .b-grid-splitter-button-expand:hover .b-grid-splitter-button-icon{
        fill:  ${({theme})=> theme.material.colors.text.button} !important;
    }
    .b-grid-splitter:not(.b-disabled) .b-grid-splitter-button-collapse:hover .b-grid-splitter-button-icon{
        fill: ${({theme})=> theme.material.colors.text.button} !important;
    }
`;

class TimelineView extends PureComponent<Object, Object>{
    static propTypes = {
        records: PropTypes.array,
        loadTimelineTasks: PropTypes.func.isRequired,
        openSidebar: PropTypes.func.isRequired,
        filterBy: PropTypes.arrayOf(PropTypes.object),
        orderBy: PropTypes.arrayOf(PropTypes.object),
        excludeBy: PropTypes.arrayOf(PropTypes.object),
    };

    static defaultProps = {
        records: [],
    };

    state ={
        events: [],
        resources: [],
        dependencies: [],
        key: uuidv1()
    }

    componentDidMount(){
        const { startDate , dueDate } = this.props;  
        startDate && dueDate && this.loadData(startDate, dueDate);
    }

    componentDidUpdate(prevProps: Object) {
        const { records , startDate , dueDate, filterBy, excludeBy, groupBy  } = this.props;
        if( filterBy !== prevProps.filterBy ||
            excludeBy !== prevProps.excludeBy ||
            startDate  !== prevProps.startDate ||
            dueDate !== prevProps.dueDate) {
            this.loadData(startDate, dueDate);
        }

        if(!arrayObjectEquals(records, prevProps.records) || groupBy !== prevProps.groupBy){
            const _records = groupBy === 'assignee' ? records.filter(rcd => rcd.assignee?.name) : records;
            this.createTimelineEvents(_records, groupBy);
        }
    }

    @bind
    @debounce(400)
    loadData(start: Date, due: Date) {
        const { filterBy, orderBy, excludeBy, loadTimelineTasks } = this.props;

        const promise = loadTimelineTasks({ filterBy, orderBy, excludeBy }, start, due);
        const isPromise = promise instanceof Promise;

        if (!isPromise) {
            throw new Error('The loadData function MUST return a Promise.');
        }

        return promise;
    };

    @bind
    createTimelineEvents(records, groupBy){
        const resourcesIds = [];
        this.setState({
            key: uuidv1(),
            events: records.map((rcd, index) => {
                var priority = +rcd.primary.priority === 50 ? 3 : +rcd.primary.priority;
                return {
                    id: rcd.id,
                    resourceId: groupBy === 'assignee' ? rcd.assignee?.id : rcd.process.id,
                    startDate: rcd.primary.startDate ? new Date(rcd.primary.startDate) : new Date(new Date(rcd.primary.dueDate).setHours(0,0,0,0)),
                    endDate: rcd.primary.dueDate ? new Date(rcd.primary.dueDate) : new Date(new Date(rcd.primary.startDate).setHours(23,59,59,999)),
                    name: rcd.name,
                    type: rcd.type,
                    assignee: rcd.assignee,
                    process: rcd.process,
                    percentDone:rcd.primary.progress ?? 0,
                    priority: priority === 1 ? 'HIGHEST' : priority === 2 ? 'HIGH' :
                        priority === 3 ? 'MEDIUM' : priority === 4 ? 'LOW'
                            : 'LOWEST' ,
                    isOpenTask : rcd.type === 'opentask'

                };
            }),
            dependencies: records.map((rcd, index) => {
                return rcd.relations.map((rel, i) => {
                    if(rel.isReverseRelation) return;
                    if(![successorTaskRelDefId, predecessorTaskRelDefId].includes(rel.relation.relationDefinition.id)) return;

                    return rel.relation.relationDefinition.id === successorTaskRelDefId ? { 
                        id: uuidv1(), 
                        from: rcd.id, 
                        to: rel.relatedEntity.id, 
                        lag : 1, lagUnit : 'hour'
                    } : { 
                        id: uuidv1(), 
                        from: rcd.id, 
                        to : rel.relatedEntity.id, 
                        lag : 1, lagUnit : 'hour',
                        fromSide: 'start', toSide: 'end'
                    };
                }).filter(Boolean);
            }).flat(Infinity).filter(Boolean),
            resources : records.map((rcd, index) => {
                switch (groupBy) {
                    case 'assignee': {
                        if(!resourcesIds.includes(rcd.assignee?.id) && rcd.assignee) {
                            resourcesIds.push(rcd.assignee.id);
                            return {
                                id: rcd.assignee.id,
                                name: rcd.assignee.name,
                                image: rcd.assignee.image ? getAttachmentUrl(rcd.assignee.id, 'user') : null,
                                iconName: 'account'
                            };
                        }
                        break;
                    }
                    case 'process': {
                        if(!resourcesIds.includes(rcd.process?.id)) {
                            resourcesIds.push(rcd.process.id);
                            return {
                                id: rcd.process.id,
                                name: rcd.process.name,
                                iconName: 'account'
                            };    
                        }
                        break;
                    }
                        
                    default:
                        return null;
                }
            }).filter(Boolean)
        });
    }

    @memoize()
    eventRenderer({ eventRecord, renderData }) {
        renderData.cls[eventRecord.originalData.priority?.toLowerCase()] = eventRecord.originalData.isOpenTask;
        renderData.cls['closed-task'] = !eventRecord.originalData.isOpenTask;
        return [
            {
                html: StringHelper.xss `<i class="mdi mdi-checkbox-marked-circle-outline" style="color: ${muiTheme.colors.text.secondary};"></i> <span style="margin:0 8px; color: ${muiTheme.colors.text.secondary};"> | </span> <span style="color: ${muiTheme.colors.text.primary};">${eventRecord.name}</span>`,
                class: 'spacing',
            },
        ];
    }

    @bind
    @memoize()
    buildPresetView(range){
        switch (range){
            case 'start':
            case 'years':
                return{
                    base: 'weekAndMonth',
                    displayDateFormat: 'ddd D/M HH:mm',
                    headers : [
                        {
                            unit       : 'month',
                            align      : 'center',
                            dateFormat : 'MMM YYYY' //Jan 2017
                        },
                        {
                            unit       : 'week',
                            align      : 'center',
                            dateFormat : 'wW'
                        }
                    ]
                };
            case 'months':
                return{
                    base: 'monthAndYear',
                    displayDateFormat: 'ddd D/M HH:mm',
                    headers : [
                        {
                            unit       : 'month',
                            align      : 'center',
                            dateFormat : 'MMMM YYYY' //Jan 2017
                        },
                        {
                            unit       : 'day',
                            align      : 'center',
                            dateFormat : '<b>ddd</b> <br/> DD'
                        }
                    ]
                };
            case 'weeks':
                return{
                    base: 'weekAndMonth',
                    displayDateFormat : 'ddd D/M HH:mm',
                    headers : [
                        {
                            unit: 'day',
                            align: 'center',
                            increment: 1,
                            dateFormat: 'DD MMM YYYY, ddd' //Jan 2017
                        },
                        {
                            unit: 'h',
                            align: 'center',
                            increment: 3,
                            dateFormat: 'HH A'
                        }
                    ]
                };
            default:
                return{
                    base: 'hourAndDay',
                    displayDateFormat : 'll HH:mm',
                    headers : [
                        {
                            unit: 'd',
                            align: 'center',
                            dateFormat: 'ddd DD MMM YYYY'
                        },
                        {
                            unit: 'h',
                            align: 'center',
                            dateFormat: 'HH A'
                        }
                    ]
                };
        }
    }

    @bind
    @memoize()
    buildColumns(groupBy){
        const columns =
        [
            {
                text: groupBy === 'assignee' ? 'Assignee' : 'Process',
                field: 'name',
                width: 432,
                showEventCount: false,
                renderer:  ({ value, record }) => value ? <EntityColumn groupBy={groupBy} data={record.data} type={'User'} /> : '',
            }
        ];
        return columns;
    }

    @bind
    onEventClick(event, title){
        const { openSidebar } = this.props;
        openSidebar(event.eventRecord.originalData.id, title);
    }

    render(){
        const { openSidebar, range, startDate, dueDate, forwardRef, sidebarTitle, groupBy, addTasksDependency } = this.props;
        const { resources, events, key, dependencies } = this.state;
        
        return (
            <TimelineWrapper>
                <BryntumSchedulerPro
                    key={key ?? 1}
                    {...timelineConfig(openSidebar)}
                    ref={forwardRef}
                    events={events}
                    dependencies={dependencies}
                    resources={resources}
                    columns={this.buildColumns(groupBy)}
                    startDate={startDate}
                    endDate={dueDate}
                    eventRenderer={this.eventRenderer}
                    viewPreset={this.buildPresetView(range)}
                    onEventClick={(event) => {this.onEventClick( event, sidebarTitle || 'About' );}}
                    onAfterDependencyCreateDrop={addTasksDependency} 
                    onBeforeDependencyCreateFinalize={({ fromSide, toSide, ...evnt }) => {
                        if(fromSide === toSide) return false;
                    }} 
                />
            </TimelineWrapper>
        );
    };
}

export default TimelineView;