// @flow

export const generateColor = (colors: Array<mixed>, name: ?string) => {
    if (!name) {
        return colors[0];
    }
    let sum = 0;
    for (let i = 0; i < name.length; ++i) {
        sum += name.charCodeAt(i);
    }
    return colors[sum % colors.length];
};

export const createInitials = (name: string) => {
    const cleanName = (name || '').trim();
    if (!cleanName) {
        return 'NN';
    }
    let chunks = cleanName.split(' ');
    if (chunks.length === 1) {
        chunks = cleanName.split('_');
    }
    if (chunks.length === 1) {
        return cleanName.substring(0, 1).toUpperCase();
    }
    return cleanName.substring(0, 1).toUpperCase() +
        chunks.pop().substring(0, 1).toUpperCase();
};

export const getAvatar = (state, type, id) => {
    if (!type || !id) {
        return null;
    }
    switch (type) {
        case 'workspace': return state.admin.workspaces.avatar[id];
        case 'team': return state.admin.teams.avatar[id];
        case 'openprocess':
        case 'closedprocess':
        case 'process': return state.abox.process.avatar[id];
        case 'opentask':
        case 'closedtask':
        case 'task': return state.abox.task.avatar[id];
        case 'broadcast': return state.broadcasts.avatar[id];
        case 'class': return state.classifications.avatar[id];
        case 'tree': return state.entities.relatedEntities.avatar[id];
        case 'relationDefinition': return state.entities.relationDefinitions.avatar[id];
        case 'script': return state.designer.scriptAvatar[id];
        default:
            return state.entities.avatar[id];
    }
};
