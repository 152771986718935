/* @flow */

import { gql } from '@apollo/client';
import { entityReferences } from './relationsQuery';

export default gql`
    query relationsTypeaheadQuery($entityType: String!, $entityId: ID!, $filterBy: [JSON], $orderBy: [JSON]) {
        relations: entityRelations(entityType: $entityType, entityId: $entityId, filterBy: $filterBy, orderBy: $orderBy) {
            relation {
                id
                relationDefinition {
                    id
                }
            }
            relatedEntity {
                ${entityReferences}
            }
            isReverseRelation
        }
    }
`;
