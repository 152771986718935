
export const mediaBreakpoints = {
    xs: 370,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
};

export default Object.keys(mediaBreakpoints).reduce(
    (breakpointMap, size) => {
        breakpointMap[size] = `${mediaBreakpoints[size]}px`;
        return breakpointMap;
    },
    {});
