/* @flow */

import genUuidv4 from 'uuid/v4';
import { get } from 'app/utils/lo/lo';
export const uuidv4 = () => genUuidv4().slice(0,8);

export default class Forest {

    nodes: Array<Object>;
    uuid: string;
    options: Object;

    constructor(nodes: Array<Object>, uuid: ?string, options: ?Object = {}) {
        this.options = {
            filterByType: true,
            ...options,
        };
        this.nodes = this._addUuid(nodes);
        this.uuid = uuid || uuidv4();
    };

    /**
     * Adds an uuid to all the nodes in a forest (a forest is a collection of independent tree).
     *
     * @param forest a collection of trees.
     */
    _addUuid(forest: Array<Object>): Array<Object> {
        let nodes = (forest || []);
        if(this.options.filterByType) {
            nodes = (nodes || []).filter(item => item && item.type);
        }
        return nodes.map((node: Object) => {
            if (!node.children) {
                // the node is a leaf
                if (!node.uuid) {
                    return { ...node, uuid: uuidv4() };
                }
                return node;
            }
            const nextChildren = this._addUuid(node.children);
            if (!node.uuid || nextChildren !== node.children) {
                return {
                    ...node,
                    uuid: node.uuid || uuidv4(),
                    children: nextChildren,
                };
            }
            return node;
        });
    };

    /**
     * Removes the uuid from all the nodes in a forest (a forest is a collection of independent tree).
     *
     * @param forest a collection of trees.
     */
    _removeUuid(forest: Array<Object>): Array<Object> {
        let nodes = (forest || []);
        if(this.options.filterByType) {
            nodes = nodes.filter(({ type }: Object) => type);
        }
        return nodes.map((node: Object) => {
            if (!node.children) {
                // the node is a leaf
                // eslint-disable-next-line no-unused-vars
                const { uuid, ...rest } = node;
                return rest;
            }
            const nextChildren = this._removeUuid(node.children);
            // eslint-disable-next-line no-unused-vars
            const { uuid, ...rest } = node;
            return { ...rest, children: nextChildren };
        });
    };

    _add(currentUuid: string, elements: Array<Object>, elementToAdd: Object, parentUuid: string, index: number): Array<Object> {
        if (currentUuid === parentUuid) {
            const next = [ ...elements ];
            next.splice(index, 0, elementToAdd);
            return next;
        }
        return elements.map((element) => {
            const { type, children = [] } = element;
            if (type !== 'group' && type !== 'panel' && type !== 'iotPanel' && type !== 'groupRepeat') {
                return element;
            }
            const nextChildren = this._add(element.uuid, children, elementToAdd, parentUuid, index);
            if (nextChildren === element.children) {
                return element;
            }
            return { ...element, children: nextChildren };
        });
    };

    _remove(elements: Array<Object>, uuid: string): Array<Object> {
        const next = elements.filter(element => element.uuid !== uuid);
        if (next.length !== elements.length) {
            return next;
        }
        return elements.map((element) => {
            if (!element.children) {
                return element;
            }
            const nextChildren = this._remove(element.children, uuid);
            if (nextChildren === element.children) {
                return element;
            }
            return { ...element, children: nextChildren };
        });
    };

    _duplicate(elements: Array<Object>, uuid: string): Array<Object> {
        let index = 0;
        const element = elements.find((el, i) => {
            index = i;
            return el.uuid === uuid;
        });
        if (element) {
            const next = [ ...elements ];
            const udatedUuids = this._addUuid(this._removeUuid([element]));
            next.splice(index, 0, udatedUuids[0]);
            return next;
        }
        return elements.map((element) => {
            if (!element.children) {
                return element;
            }
            const nextChildren = this._duplicate(element.children, uuid);
            if (nextChildren === element.children) {
                return element;
            }
            return { ...element, children: nextChildren };
        });
    };

    _update(elements: Array<Object>, updatedElement: Object): Array<Object> {
        return elements.map((element) => {
            if (updatedElement.uuid === element.uuid) {
                return updatedElement;
            }
            if (!element.children) {
                return element;
            }
            const nextChildren = this._update(element.children, updatedElement);
            if (nextChildren === element.children) {
                return element;
            }
            return { ...element, children: nextChildren };
        });
    };
    _get(elements: Array<Object>, path: string, value): ?Object {
   
        let el = null;
        elements.forEach((element) => {
            if (value === get(element, path)) {
                el = element;
            }
            if (element.children && !el) {
                el = this._get(element.children, path, value);
            }
        });
        return el;
    };

    /**
     * Returns all the nodes of this forest without the UUID.
     * Every time the method is called a new forest will be generated.
     */
    getNodes() {
        return this._removeUuid(this.nodes);
    };

    add(node: Object, parentUuid: string, index: number) {
        this.nodes = this._add(this.uuid, this.nodes, {...node, uuid: node.uuid || uuidv4() }, parentUuid, index);
        return this;
    };

    duplicate = (uuid: string) => {
        this.nodes = this._duplicate(this.nodes, uuid);
        return this;
    };

    remove = (element: Object) => {
        if (element?.uuid) {
            this.nodes = this._remove(this.nodes, element.uuid);
        }
        return this;
    };

    update = (element: Object) => {
        this.nodes = this._update(this.nodes, element);
        return this;
    };

    move = (element: Object, parentUuid: string, index: number) => {
        const nodes = this._remove(this.nodes, element.uuid);
        this.nodes = this._add(this.uuid, nodes, element, parentUuid || this.uuid, index);
        return this;
    };

    get = (uuid: string) => {
        return this._get(this.nodes, 'uuid', uuid);
    };
    getBy = (path: string, value) => {
        return this._get(this.nodes, path, value);
    };
}
