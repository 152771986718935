import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { loadPersonAutocomplete } from 'store/actions/entities/peopleActions';

import AbstractEntityTypeahead from 'app/components/organisms/Form/Typeahead/abstract/AbstractEntityTypeahead';
import ResolveAvatarTypeaheadWrapper from 'app/components/organisms/Form/Typeahead/abstract/ResolveAvatarTypeaheadWrapper';

/**
 * Select one or more groups using lazy loading.
 */
class PersonTypeahead extends PureComponent<Object, Object> {

    static propTypes = {
        loadPersonAutocomplete: PropTypes.func.isRequired,
        filterBy: PropTypes.arrayOf(PropTypes.object),
    }

    render() {
        const { loadPersonAutocomplete, ...abstractEntityAutocompleteProps } = this.props;
        return (
            <AbstractEntityTypeahead
                placeholder="Search for a person..."
                {...abstractEntityAutocompleteProps}
                loadOptions={loadPersonAutocomplete}
            />
        );
    }
};

const PersonTypeaheadWrapped = (props) => {
    return <ResolveAvatarTypeaheadWrapper {...props} Typeahead={PersonTypeahead} entityType="person" />;
};

export default connect(
    null,
    { loadPersonAutocomplete }
)(PersonTypeaheadWrapped);
