/* @flow */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import { RouterMatchPropTypeBuilder } from 'app/utils/propTypes/common';

import SuperAnalytics from './SuperAnalytics';
import PageNotAllowed from '../ErrorPages/PageNotAllowed';
import { setDocumentTitle } from 'store/actions/app/appActions';
import { modulesAndPageTitles } from 'app/config/typesConfig';

class SuperAnalyticsRoute extends Component<Object, Object> {
    static propTypes = {
        match: RouterMatchPropTypeBuilder({ id: PropTypes.string }),
        profile: PropTypes.object,
    };

    componentDidMount() {
        this.props.setDocumentTitle(modulesAndPageTitles.analytics.superset);
    }

    render() {
        const { match } = this.props;
        const { isAdmin, permissions } = this.props.profile;
        const permissionsSet = new Set(permissions);
        const canView = isAdmin || permissionsSet.has('analytics.superAnalytics');
        if (!canView) {
            return <PageNotAllowed title="Super Analytics" />;
        }
        return (
            <Switch>
                <Route path={`${ match.url }`} exact component={SuperAnalytics} />
            </Switch>
        );
    }
}

export default connect(
    (state: Object): Object => ({
        profile: state.user.profile,
    }),
    {
        setDocumentTitle,
    }
)(SuperAnalyticsRoute);
