// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';

import { bind, debounce } from 'app/utils/decorators/decoratorUtils';
import { MdiIcon, Snackbar } from '@mic3/platform-ui';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import { get } from 'app/utils/lo/lo';
import { getNumericAttributes } from 'app/utils/maps/layer/layerUtils';

import { loadClassification } from 'store/actions/classifications/classificationsActions';
import { loadClassFormDefinition, setLayerEntitiesHidden } from 'store/actions/maps/situationalAwarenessActions';
import LayerStylingButtons from 'app/containers/Maps/LayerAbout/EntityLayer/LayerStylingButtons';
import { loadClassesListView } from 'store/actions/classifications/classificationsActions';
import { set } from 'app/utils/lo/lo';

const StyledSnackbar = styled(Snackbar)`
    .MuiSnackbarContent-root {
        background-color:  ${({ theme }) => theme.material.colors.background.default} ;
        color: ${({ theme }) => theme.material.colors.text.primary}  ;
    }
`;

export const getLayerStylingData = (details) => {
    if(!details) return {};
    const { styles } = details || {};
    const { progress,counter }  = styles || {};
    const pinStyle = styles?.pinStyle || 'cluster';
    return { 
        ...styles, pinStyle, progress,counter
    };    
};

class EntityLayerStyle extends PureComponent<Object, Object> {

    static propTypes: Object = {
        data: PropTypes.object,
        styleClass: PropTypes.object,
        loadClassification: PropTypes.func.isRequired
    };

    constructor(props: Object) {
        super(props);
        const { pinStyle } = getLayerStylingData(props.data);
        this.state = {
            classComponents: [],
            showSnackbar: false,
            numAttrWithMinMax: [],
            numAttributes: [],
            selectedStyle: pinStyle || 'cluster',
            styleClasses: [],
        };
    }

    formRef = React.createRef();

    componentDidMount() {
        const { data } = this.props;
        const { entity_type } = data || {};
        this.getStyleClasses();
        const { pinStyle } = data?.styles || {};
        if(pinStyle === 'hideEntities') {
            this.props.setLayerEntitiesHidden(data?.id, true);
        } 
        if(entity_type) {
            this.getClassNumAttributes();
        }

    }

    async getStyleClasses() {
        const styleOptions = [
            { label: 'Individual', value: 'point', description: 'Used for 3D and polygon visualisations', uri: 'point' },
            { label: 'Clustered', value: 'cluster', description: 'Group numbers of entities. 3D and polygon visualisations will not be visible.', uri: 'cluster' },
            { label: 'Hide Entities', value: 'hideEntities', description: 'See data visualisations only. Hides entities and related entity data.',  uri: 'hideEntities' },
        ];
        this.setState({ styleClasses: styleOptions });
    }

    componentDidUpdate(prevProps: Object, prevState: Object) {
        if (this.state.selectedStyle !== prevState.selectedStyle) {
            this.buildPointFields(this.state.selectedStyle);
        }
    }

    @bind
    async getClassNumAttributes() {
        const { entity_type } = this.props?.data;
        if (!entity_type) {
            return [];
        }
        const { data } = await loadClassFormDefinition(entity_type?.id);
        const classFields = get(data, 'result.formDefinition.fields', []);
        const numAttrWithMinMax = getNumericAttributes(classFields, true);
        const numAttributes = getNumericAttributes(classFields, false);
        this.setState({ numAttrWithMinMax, numAttributes });
    }

    @bind
    buildPointFields(uri) {
        if(!uri) return [];
        switch(uri) {
            case 'point':
                return [
                    {
                        type: 'panel',
                        properties: {
                            header: 'Point Size',
                            expanded: true
                        },
                        children: [
                            {
                                type: 'typeahead',
                                properties: {
                                    label: 'Point Size',
                                    name: 'pointSize',
                                    options: [
                                        { value: 'fixedPointSize', label: 'Fixed' }
                                    ],
                                    defaultValue: 'fixedPointSize'
                                },
                            },
                            {
                                type: 'slider',
                                properties: {
                                    label: 'Fixed Point Size',
                                    name: 'valPointSize',
                                    fillColor: '#7391D0',
                                    min: 8,
                                    step: 4,
                                    marks: true,
                                    max: 96,
                                    defaultValue: 32,
                                    isVisible: data => data.pointSize === 'fixedPointSize',
                                },
                            }
                        ]
                    },
                    {
                        type: 'panel',
                        properties: {
                            header: 'Icon',
                            expanded: true
                        },
                        children: [
                            {
                                type: 'typeahead',
                                properties: {
                                    label: 'Entity Icon',
                                    name: 'entityIcon',
                                    options: [
                                        { value: 'iconFromEntity', label: 'Entity Icon' }, 
                                        { value: 'fixedIcon', label: 'Fixed' } 
                                    ],
                                    defaultValue: 'iconFromEntity' // from cluster to point, entity icon should be selected by detault.
                                },
                            },
                            {
                                type: 'iconSelect',
                                properties: {
                                    label: 'Entity Icon',
                                    name: 'iconName',
                                    valueField: 'value',
                                    isVisible: data => data.entityIcon  === 'fixedIcon',
                                }
                            }
                        ]
                    },
                    {
                        type: 'panel',
                        properties: {
                            header: 'Fill Colour',
                            expanded: true
                        },
                        children: [
                            {
                                type: 'typeahead',
                                properties: {
                                    label: 'Colour',
                                    name: 'fillColour',
                                    options: [
                                        { value: 'entityFillColour', label: 'Entity Colour' },
                                        { value: 'fixedFillColour', label: 'Fixed' }
                                    ],
                                    defaultValue: 'entityFillColour',
                                },
                            },
                            {
                                type: 'colorPicker',
                                properties: {
                                    label: 'Fixed colour',
                                    name: 'valFillColour',
                                    isVisible: data => data.fillColour  === 'fixedFillColour',
                                }
                            },
                        ]
    
                    }
                ];
            case 'cluster': 
                return [
                    {
                        'type': 'number',
                        'properties': {
                            'name': 'distance',
                            'label': 'Distance',
                            'defaultValue': 40
                        },
                        'constraints': {
                            'required': true
                        }
                    }
                ];
            default:
                return [];
        }
       
    }

    @bind
    buildFormDefinition(classComponents: Array<Object>, pinStyle: Object) {
        const { related_entities } = this.props.data;
        const { isDisabled } = this.props;
        const isCounterChecked = related_entities?.showCounters;
        return [
            this.state.styleClasses.length && {
                field: 'pinStyle.uri',
                type: 'custom',
                properties: {
                    label:'Entities view',
                    name:'pinStyle.uri',
                    Component: props => 
                        <LayerStylingButtons 
                            name="pinStyle"
                            formData={this.props.formData}
                            value={this.state.selectedStyle}
                            onChange={this.handleChange}
                            options={this.state.styleClasses}
                        />
                }
            },
            ...this.buildPointFields(pinStyle, classComponents),
            {
                type: 'panel',
                properties: {
                    header: 'Progress Ring',
                    expanded: true
                },
                children: [
                    {
                        type: 'typeahead',
                        properties: {
                            label: 'Show progress by attribute',
                            name: 'progress',
                            options: this.state.numAttrWithMinMax,
                        },
                    }]
            },
            (isDisabled && !isCounterChecked) && {
                type: 'panel',
                properties: {
                    header: 'Number Counter',
                    expanded: true
                },
                children: [
                    {
                        type: 'typeahead',
                        properties: {
                            label: 'Show counter of attribute',
                            name: 'counter',
                            options: this.state.numAttributes,
                        },
                    }]
            }
        ].filter(Boolean);
    }

    @bind
    async validateForm() {
        if (this.formRef && this.formRef.current) {
            const { errors } = await this.formRef.current.isValidForm();
            return { errors };
        } else {
            return { errors: null };
        }
    }

    @bind
    @debounce()
    async handleFormValidation() {
        const result = await this.validateForm();
        this.errors = !!result.errors;
        return !result.errors;
    }

    @bind
    hasErrors() {
        return this.errors;
    }

    @bind
    closeSnackbar() {
        this.setState({ showSnackbar: false });
    }

    @bind
    async handleChange(formData: Object, { name, value }: Object) {
        await this.handleFormValidation();
        let data = { ...formData };
        if( name === 'pinStyle') {
            if (value !== 'hideEntities') {
                this.setState({ selectedStyle : value });
                data = set(data, 'pinStyle', value);
            }
            if (value === 'hideEntities') {
                this.setState({ selectedStyle : value });
                data = set(data, 'pinStyle', value);
            }
        }
        if(name === 'entityIcon' && value === 'iconFromEntity') {
            data = set(data,'iconName', null);
        }
        if(name === 'fillColour' && value === 'entityFillColour') {
            data = set(data,'valFillColour', null);
        }
        this.props.onFormChange(data, { name, value });
    }

    render() {
        const { classComponents, showSnackbar } = this.state;
        const { formData } = this.props;
        const { pinStyle } = formData || {};
        return (<>
            <FormGenerator
                data={formData}
                onChange={this.handleChange}
                name={this.props.name}
                components={this.buildFormDefinition(classComponents, pinStyle)}
                ref={this.formRef}
            />
            <StyledSnackbar
                open={showSnackbar}
                message={'Select at least one classification from the Entity Filters'}
                action={<MdiIcon name="close" onClick={this.closeSnackbar} />}
            />
        </>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    styleClass: state.classifications.details.data || {},
    isDisabled: state.maps.situationalAwareness.layer.isDisabled,
});

export default connect(mapStateToProps, {
    loadClassification,
    loadClassesListView,
    setLayerEntitiesHidden
}, null, { forwardRef: true })(withTheme(EntityLayerStyle));
