// @flow

import { gql } from '@apollo/client';

export default (additionalProps = ``) => gql`
query processLoadAvatarQuery($filterBy: [JSON]) {
  result: processes(filterBy: $filterBy) {
    id
    name
    ${additionalProps}
  }
}

`;
