import React, { PureComponent } from 'react';
import { Collapse, Tooltip, IconButton } from '@mic3/platform-ui';
import TinyVirtualList from 'react-tiny-virtual-list';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import { muiTheme } from 'app/themes/materialUi';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import NestedMenu from 'app/components/molecules/NestedMenu/NestedMenu';
import ChatAddMenu from 'app/components/organisms/Chat/ChatAddMenu';
import DotMenu from 'app/components/molecules/DotMenu/DotMenu';
import ALiveItem from 'app/containers/Abox/ALive/ALiveItem';
import Loader from 'app/components/atoms/Loader/Loader';
import Icon from 'app/components/atoms/Icon/Icon';
import LeftPanelSearch from 'app/components/molecules/LeftPanelSearch/LeftPanelSearch';
import BaseModal from 'app/components/Designer/Modals/BaseModal';

import { bind, memoize, debounce } from 'app/utils/decorators/decoratorUtils';
import { searchChats, sortChats } from 'app/utils/chat/chatUtils';
import { getStr, isEmpty } from 'app/utils/utils';
import { SettingsMenu } from 'app/config/chatConfig';
import { get } from 'app/utils/lo/lo';

import { setAllSubscriptionAsRead } from 'store/actions/chat/chatActions';
import { saveUserPreferences } from 'store/actions/admin/usersActions';
import {
    setActions,
    closeLeftPanel,
    shrinkLeftPanel,
    expandLeftPanel
} from 'store/actions/leftPanel/leftPanelActions';

const ChatListContainer = styled.div`
    margin: 0 16px !important;
`;
const LabelContainer = styled.div`
    display: flex;
    align-items: center;
    p {
        margin: 0;
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        line-height: 16px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        color: ${({theme})=> theme.material.colors.text.primary};
    }
`;
const NoDataLabel = styled.div`
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    margin: 12px 0;
`;
const Divider = styled.hr`
    border: none;
    height: 1px;
    flex-shrink: 0;
    background-color: ${({theme})=> theme.material.colors.background.divider};
`;
const SettingNestedMenu = styled(NestedMenu)`
    i.mdi-circle-slice-8:before,
    i.mdi-checkbox-marked:before {
        color: ${({theme})=> theme.material.colors.secondary.main} !important;
    }
`;

const SearchListContainer = styled.div`
    margin: 16px 0;
`;

class ChatLeftPanel extends PureComponent<Object, Object> {
    static propTypes = {
        type: PropTypes.string,
        isOpen: PropTypes.bool,
        isMobile: PropTypes.bool,
        subscriptions: PropTypes.arrayOf(PropTypes.object),
        unreadSubscriptionsIds: PropTypes.arrayOf(PropTypes.string),
        subscriptionsLoading: PropTypes.bool,
        closeLeftPanel: PropTypes.func,
        setActions: PropTypes.func,
    };

    constructor(props: Object) {
        super(props);
        const chatListFilter = get(props.preferences, 'filters.chatList', {});
        let { appearance, sortBy, groupBy } = chatListFilter;
        appearance = !isEmpty(appearance) ? appearance : 'extended'; 
        sortBy = !isEmpty(sortBy) ? sortBy : 'activity'; 
        groupBy = !isEmpty(groupBy) ? groupBy : { unread: true, favorites: true, types: true }; 
        
        this.state = {
            chats: [],
            filters: { appearance, sortBy, groupBy },
            searchValue: '',
            searchResults: [],
            settingAnchorEl: null,
            addNewAnchorEl: null,
            expandUnread: true,
            expandFavorites: true,
            expandConversation: true,
            expandPrivate: true,
            expandPublic: true,
            expandDirect: true,
            expandOmni: true,
            openConfirmModal: false,
            chatListContainerHeight: this.getInitialListHeight()
        };
        props.history.location.pathname.split('/');
        this.setActions();
    }

    componentDidMount() {
        const newChats = sortChats(this.state.filters.sortBy, this.props.subscriptions);
        this.setState({ chats: newChats });
        window.addEventListener('resize', this.handleResize);
    }

    componentDidUpdate(prevProps, prevState) {
        const { chats, searchValue, filters } = this.state;
        const { content, subscriptions, unreadSubscriptionsIds } = this.props;
        const isContentChanged = prevProps.content !== content;
        const isUnreadChatChanged = Boolean(prevProps.unreadSubscriptionsIds.length) !== Boolean(unreadSubscriptionsIds.length);

        if (searchValue && prevState.searchValue !== searchValue) {
            const results = searchChats(searchValue, chats);
            this.setState({ searchResults: results });
        } else {
            if (prevState.searchValue && !searchValue) {
                this.setState({ searchResults: [] });
            }
        }

        if (isContentChanged || isUnreadChatChanged) {
            this.setActions();
            this.setState({ searchValue: '' });
        }

        if (JSON.stringify(prevState.filters) !== JSON.stringify(filters)) {
            if (prevState.filters.sortBy !== filters.sortBy) {
                const newChats = sortChats(filters.sortBy, subscriptions);
                this.setState({ chats: newChats });
            }
            const { saveUserPreferences, preferences } = this.props;
            saveUserPreferences({ ...preferences, filters: { ...preferences.filters, chatList: filters } });
        }

        if (JSON.stringify(prevProps.subscriptions) !== JSON.stringify(subscriptions)) {
            const newChats = sortChats(filters.sortBy, subscriptions);
            this.setState({ chats: newChats });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    @bind
    openSetting(e) {
        this.setState({ settingAnchorEl: e.currentTarget });
    }

    @bind
    closeSetting() {
        this.setState({ settingAnchorEl: null });
    }

    @bind
    openAddNew(e) {
        this.setState({ addNewAnchorEl: e.currentTarget });
    }

    @bind
    closeAddNew() {
        this.setState({ addNewAnchorEl: null });
    }

    @bind
    handleResize() {
        this.setState({ chatListContainerHeight: this.getInitialListHeight() });
    }

    @bind
    getInitialListHeight() {
        return window.innerHeight - 152;
    }

    @bind
    onDotMenuClick(title) {
        switch (title) {
            case 'Mark all chats as read':
                this.setState({ openConfirmModal: true });
                break;
            case 'Hide sidebar': {
                const { isMobile, history } = this.props;
                if (isMobile) {
                    history.push(`/abox/alive`);
                    this.closeLeftPanel();
                } else {
                    this.closeLeftPanel();
                }
                break;
            }
            default:
                break;
        }
    }

    @bind
    onSettingMenuClick(title) {
        switch (title) {
            case 'Extended':
                this.setState(({filters}) => ({filters: {...filters, appearance: 'extended'}}));
                break;
            
            case 'Medium':
                this.setState(({filters}) => ({filters: {...filters, appearance: 'medium'}}));
                break;
            
            case 'Condensed':
                this.setState(({filters}) => ({filters: {...filters, appearance: 'condensed'}}));
                break;
            
            case 'Activity':
                this.setState(({filters}) => ({filters: {...filters, sortBy: 'activity'}}));
                break;

            case 'Name (A-Z)':
                this.setState(({filters}) => ({filters: {...filters, sortBy: 'name'}}));
                break;

            case 'Unread':
                this.setState(({filters}) => ({filters: {...filters, groupBy: {...filters.groupBy, unread: !filters.groupBy.unread}}}));
                break;

            case 'Favourites':
                this.setState(({filters}) => ({filters: {...filters, groupBy: {...filters.groupBy, favorites: !filters.groupBy.favorites}}}));
                break;

            case 'By type':
                this.setState(({filters}) => ({filters: {...filters, groupBy: {...filters.groupBy, types: !filters.groupBy.types}}}));
                break;

            default:
                break;
        }
    }

    @bind
    setActions() {
        this.props.setActions(
            <>
                <Tooltip title='Create New' aria-label='create new'>
                    <IconButton onClick={this.openAddNew} >
                        <Icon name='plus' />
                    </IconButton>
                </Tooltip>
                <Tooltip title='Filters' aria-label='filters'>
                    <IconButton onClick={this.openSetting} >
                        <Icon name='cog-outline' />
                    </IconButton>
                </Tooltip>
                <DotMenu
                    key={13}
                    onItemClick={this.onDotMenuClick}
                    items={[
                        { name: 'Mark all chats as read', icon: 'check-all', disabled: isEmpty(this.props.unreadSubscriptionsIds) },
                        { name: 'Hide sidebar', icon: 'chevron-double-left' }
                    ].filter(Boolean)}
                    tooltipTitle='More Options'
                />
            </>
        );
    }

    @bind
    handleSearch(searchValue) {
        this.setState({ searchValue });
    }

    @bind
    onClickItem() {
        const { isMobile } = this.props;
        if (isMobile) {
            this.closeLeftPanel();
        }
    }

    @bind
    @debounce()
    closeLeftPanel() {
        this.props.closeLeftPanel();
    }

    @bind
    toggleUnreadList() {
        this.setState(state => ({expandUnread: !state.expandUnread}));
    }
    
    @bind
    toggleFavoritesList() {
        this.setState(state => ({expandFavorites: !state.expandFavorites}));
    }
    
    @bind
    toggleOmniList() {
        this.setState(state => ({expandOmni: !state.expandOmni}));
    }

    @bind
    toggleConversationList() {
        this.setState(state => ({expandConversation: !state.expandConversation}));
    }

    @bind
    togglePrivateList() {
        this.setState(state => ({expandPrivate: !state.expandPrivate}));
    }

    @bind
    togglePublicList() {
        this.setState(state => ({expandPublic: !state.expandPublic}));
    }

    @bind
    toggleDirectList() {
        this.setState(state => ({expandDirect: !state.expandDirect}));
    }

    @bind
    getItemSize(appearance) {
        switch (appearance) {
            case 'extended':
                return 74;  
            case 'medium':
                return 66;  
            case 'condensed':
                return 42;
            default:
                return 74;   
        };
    }

    @bind
    @memoize()
    renderChatItem(style, data, appearance) {
        return (
            <div style={style} key={data.rid}>
                <ALiveItem
                    appearance={appearance}
                    data={data}
                    handleClick={this.onClickItem}
                />
            </div>
        );
    }

    @bind
    @memoize()
    renderChatGroup(data, label, toggle, toggleFunc, appearance, dynamicHeight) {
        const itemSize = this.getItemSize(appearance);
        const total = data.length;
        const staticHeight = total >= 15 ? itemSize * 15 : itemSize * total;

        return (
            <>
                <LabelContainer onClick={toggleFunc}>
                    <Icon
                        name={toggle ? 'chevron-down' : 'chevron-right'}
                        hexColor={muiTheme.colors.secondary.main}
                    />
                    <p>{`${label} ${`(${total})`}`}</p>
                </LabelContainer>
                <Collapse in={toggle} unmountOnExit>
                    <TinyVirtualList
                        width='100%'
                        height={dynamicHeight || staticHeight}
                        itemCount={total}
                        itemSize={itemSize}
                        renderItem={({index, style}) => (this.renderChatItem(style, data[index], appearance))}
                    />
                </Collapse>
                <Divider />
            </>
        );
    }

    @bind 
    groupChatItems(chats, { unread, favorites, types }, currentRoomId) {
        let favoritesChats = [];
        let privateChats = [];
        let unreadChats = [];
        let directChats = [];
        let publicChats = [];
        let readChats = [];
        let omniChats = [];

        (chats || []).forEach((chat) => {
            const { unread: chatUnread, f, t: type, rel } = chat || {};
            if (unread && chatUnread !== 0 && currentRoomId !== rel?.id) {
                unreadChats = [...unreadChats, chat];
            } else if (f) {
                favoritesChats = [...favoritesChats, chat];
            } else if (type === 'l') {
                omniChats = [...omniChats, chat];
            } else {
                readChats = [...readChats, chat];
                switch (type) {
                    case 'p':
                        privateChats = [...privateChats, chat];
                        break;
                    case 'c':
                        publicChats = [...publicChats, chat];
                        break;
                    case 'd':
                        directChats = [...directChats, chat];
                        break;
                    default:
                        break;
                }
            }
        });

        return {
            omniChats: {
                key: 'omniChats',
                items: omniChats || [],
                show: Boolean(omniChats.length)
            },
            unreadChats: {
                key: 'unreadChats',
                items: unreadChats || [],
                show: unread && Boolean(unreadChats.length)
            },
            favoritesChats: {
                key: 'favoritesChats',
                items: favoritesChats || [],
                show: favorites && Boolean(favoritesChats.length)
            },
            readChats: {
                key: 'readChats',
                items: readChats || [],
                show: !types && Boolean(readChats.length)
            },
            privateChats: {
                key: 'privateChats',
                items: privateChats || [],
                show: types && Boolean(privateChats.length)
            },
            directChats: {
                key: 'directChats',
                items: directChats || [],
                show: types && Boolean(directChats.length)
            },
            publicChats: {
                key: 'publicChats',
                items: publicChats || [],
                show: types && Boolean(publicChats.length)
            },
        };
    }

    @bind
    getChatListHeight(chatListArr, itemSize, chatListContainerHeight) {
        const listSectionHeaderHeight = 36;
        const mainHeaderHeight = 58;
        const dividerHeight = 18;

        const shownChats = chatListArr.filter(({ show }) => show);

        const totalChatSectionHeight = shownChats
            .reduce((acc, cur) => {
                const maxHeight = cur.items.length > 15 ? 15 : cur.items.length;
                const sectionHeight = (maxHeight * itemSize) + listSectionHeaderHeight;
                return acc + sectionHeight;
            }, 0);

        if (totalChatSectionHeight < chatListContainerHeight) {
            const chatWithLargestHeight = shownChats.find((chat) => chat.items.length > 15);

            if (!chatWithLargestHeight) return null;

            const filterShownChats = shownChats.filter((chat) => chat.key !== chatWithLargestHeight.key);

            const totalStaticChatSectionHeight = filterShownChats
                .reduce((acc, cur) => {
                    const sectionHeight = (cur.items.length * itemSize) + listSectionHeaderHeight;
                    return acc + sectionHeight;
                }, 0);

            const totalDividerHeight = filterShownChats.length * dividerHeight;

            return {
                key: chatWithLargestHeight.key,
                height: chatListContainerHeight - totalStaticChatSectionHeight - mainHeaderHeight - totalDividerHeight
            };
        }

        return null;
    }

    @bind
    getChatHeight(key, chatObj) {
        if (key === chatObj?.key) {
            return chatObj?.height;
        }
        return null;
    }

    @bind
    closeConfirmDialog() {
        this.setState({ openConfirmModal: false });
    }

    @bind
    handleConfirm() {
        this.props.setAllSubscriptionAsRead(this.props.unreadSubscriptionsIds);
        this.closeConfirmDialog();
    }

    @bind
    @memoize()
    renderChats(
        chats: Array<Object>,
        isLoading: Boolean,
        filters: Object,
        expandUnread: Boolean,
        expandFavorites: Boolean,
        expandConversation: Boolean,
        expandPrivate: Boolean,
        expandPublic: Boolean,
        expandDirect: Boolean,
        expandOmni: Boolean,
        currentRoomId,
        chatListContainerHeight
    ) {
        const { appearance, groupBy} = filters;
        const itemSize = this.getItemSize(appearance);
        const {
            omniChats,
            unreadChats,
            favoritesChats,
            readChats,
            privateChats,
            publicChats,
            directChats
        } = this.groupChatItems(chats, groupBy, currentRoomId);
        const chatArr = [
            omniChats, 
            unreadChats, 
            favoritesChats, 
            readChats, 
            privateChats, 
            publicChats, 
            directChats
        ];
        const chatObjWithHeight = this.getChatListHeight(chatArr, itemSize, chatListContainerHeight);

        return (
            <ChatListContainer>
                {omniChats.show &&
                    this.renderChatGroup(
                        omniChats.items,
                        'Omnichannel',
                        expandOmni,
                        this.toggleOmniList,
                        appearance,
                        this.getChatHeight(omniChats.key, chatObjWithHeight)
                    )}
                {unreadChats.show &&
                    this.renderChatGroup(
                        unreadChats.items,
                        'Unread',
                        expandUnread,
                        this.toggleUnreadList,
                        appearance,
                        this.getChatHeight(unreadChats.key, chatObjWithHeight)
                    )}
                {favoritesChats.show &&
                    this.renderChatGroup(
                        favoritesChats.items,
                        'Favourites',
                        expandFavorites,
                        this.toggleFavoritesList,
                        appearance,
                        this.getChatHeight(favoritesChats.key, chatObjWithHeight)
                    )}
                {!groupBy.types && (readChats.items.length > 0 ? 
                    this.renderChatGroup(
                        readChats.items,
                        'Conversation',
                        expandConversation,
                        this.toggleConversationList,
                        appearance,
                        this.getChatHeight(readChats.key, chatObjWithHeight)
                    ) : <NoDataLabel>No available chats</NoDataLabel>
                )}
                {privateChats.show &&
                    this.renderChatGroup(
                        privateChats.items,
                        'Private Channels',
                        expandPrivate,
                        this.togglePrivateList,
                        appearance,
                        this.getChatHeight(privateChats.key, chatObjWithHeight)
                    )}
                {publicChats.show &&
                    this.renderChatGroup(
                        publicChats.items,
                        'Public Channels',
                        expandPublic,
                        this.togglePublicList,
                        appearance,
                        this.getChatHeight(publicChats.key, chatObjWithHeight)
                    )}
                {directChats.show &&
                    this.renderChatGroup(
                        directChats.items,
                        'Direct Messages',
                        expandDirect,
                        this.toggleDirectList,
                        appearance,
                        this.getChatHeight(directChats.key, chatObjWithHeight)
                    )}
            </ChatListContainer>
        );
    }

    render() {
        const { isOpen, subscriptionsLoading, currentRoomId } = this.props;
        const {
            chats,
            searchValue,
            searchResults,
            filters,
            expandUnread,
            expandFavorites,
            expandConversation,
            expandPrivate,
            expandPublic,
            expandDirect,
            expandOmni,
            chatListContainerHeight,
            openConfirmModal
        } = this.state;
        const { appearance } = filters;

        return (
            isOpen && (
                <>
                    <LeftPanelSearch
                        onSearch={this.handleSearch}
                        searchValue={searchValue}
                    />
                    {subscriptionsLoading && <Loader absolute />}
                    {Boolean(searchValue) ? (
                        <ChatListContainer>
                            {Boolean(searchResults.length) ? (
                                <SearchListContainer>
                                    <TinyVirtualList
                                        width='100%'
                                        height={chatListContainerHeight - 36}
                                        itemCount={searchResults.length}
                                        itemSize={this.getItemSize(appearance)}
                                        renderItem={({index, style}) => (this.renderChatItem(style, searchResults[index], appearance))}
                                    />
                                </SearchListContainer>
                            ) : (
                                <NoDataLabel>No search results</NoDataLabel>
                            )}
                        </ChatListContainer>
                    ) : (
                        this.renderChats(
                            chats,
                            subscriptionsLoading,
                            filters,
                            expandUnread,
                            expandFavorites,
                            expandConversation,
                            expandPrivate,
                            expandPublic,
                            expandDirect,
                            expandOmni,
                            currentRoomId,
                            chatListContainerHeight
                        )
                    )}
                    <SettingNestedMenu
                        items={SettingsMenu(filters)}
                        open={Boolean(this.state.settingAnchorEl)}
                        onItemClick={this.onSettingMenuClick}
                        anchorEl={this.state.settingAnchorEl}
                        onClose={this.closeSetting}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left'
                        }}
                    />
                    <ChatAddMenu
                        anchorEl={this.state.addNewAnchorEl}
                        onClose={this.closeAddNew}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left'
                        }}
                    />
                    <BaseModal
                        open={openConfirmModal}
                        onClose={this.closeConfirmDialog}
                        onConfirm={this.handleConfirm}
                        header="Confirmation"
                        maxWidth='xs'
                        fullWidth
                        content={(
                            'Are you sure you would like to mark all chats as read?'
                        )}
                        declineButtonText="No"
                        confirmButtonText="Yes"
                        confirmationButtonProps={{
                            variant:'text',
                            color: '#FF8A91'
                        }}
                    />
                </>
            )
        );
    }
}

export default connect(
    (state: Object, ownProps: Object) => {
        const subscriptions = state.chat.subscriptions.data;
        const currentRoomId = getStr(state, 'chat.room.rel.id');
        const unreadSubscriptionsIds = subscriptions
            .filter(({ unread, rel }) => unread && rel?.id !== currentRoomId)
            .map(({ rid }) => rid);

        return {
            subscriptionsLoading: state.chat.subscriptions.isLoading,
            subscriptions,
            unreadSubscriptionsIds,
            content: state.leftPanel.state.content,
            isMobile: state.global.isMobile,
            isOpen: state.leftPanel.state.isOpen,
            preferences: state.user.preferences,
            currentRoomId: currentRoomId
        };
    },
    {
        closeLeftPanel,
        shrinkLeftPanel,
        expandLeftPanel,
        setActions,
        saveUserPreferences,
        setAllSubscriptionAsRead
    }
)(withRouter(ChatLeftPanel));
