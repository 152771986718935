/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mic3/platform-ui';

import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import { bind } from 'app/utils/decorators/decoratorUtils';
import history from 'store/History';

class AddScript extends PureComponent<Object, Object> {

    static propTypes = {
        addScript: PropTypes.func.isRequired,
        isLoading: PropTypes.bool,
    };

    formRef = React.createRef();
    components = [
        {
            type: 'text',
            properties: { label: 'Name', name: 'name'},
            constraints: { required: true }
        },
        {
            type: 'textarea',
            properties: { label: 'Description', name: 'description'},
        }
    ]

    @bind
    onFormSubmit(event: Event) {
        event.preventDefault();
        this.formRef.current.isValidForm().then(({ data, errors }) => {
            if (!errors) {
                this.props.addScript({...data, name:data.name.trim()}).then((result) => {
                    const { id } = result;
                    if (id) {
                        history.push(`/designer/scripts/${id}`);
                    }
                });
            }
        });
    };

    render(): Object {
        const { isLoading, onClose } = this.props;
        return (
            <ModalDialog
                title="Create Script"
                onClose={onClose}
                isLoading={isLoading}
                actions={
                    <Button onClick={this.onFormSubmit}>
                      Create
                    </Button>
                }
            >
                <FormGenerator
                    components={this.components}
                    ref={this.formRef}
                />
            </ModalDialog>
        );
    }
}

export default AddScript;