/* @flow */

import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';
import { IconButton, Divider, Chip, Avatar } from '@mic3/platform-ui';
import { isMobile } from 'react-device-detect';

import { loadScript, updateScript, publishScript } from 'store/actions/designer/designerScriptActions';
import { openScriptSidebar } from 'store/actions/entities/scriptSidebarActions';
import { saveComponentState } from 'store/actions/component/componentActions';
import { getStr } from 'app/utils/utils';
import { get } from 'app/utils/lo/lo';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { saveText, readTextFile, readCSVFile } from 'app/utils/datatable/datatableUtils';
import { getPermissions } from 'app/config/rolesConfig';
import { isEmpty } from 'app/utils/utils';
import { setEntityPreviewVersion, loadEntityVersions } from 'store/actions/entities/entitiesActions';
import { getAttachmentUrl } from 'app/utils/attachments/attachmentsUtils';
import { loadEntityWorkspaces } from 'store/actions/entities/entitiesActions';
import { setDocumentTitle, showToastr } from 'store/actions/app/appActions';

import PageNotAllowed from 'app/containers/ErrorPages/PageNotAllowed';
import DotMenu from 'app/components/molecules/DotMenu/DotMenu';
import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import Button from 'app/components/atoms/Designer/Button';
import Loader from 'app/components/atoms/Loader/Loader';
import PageTemplate from 'app/components/templates/PageTemplate';
import Breadcrumbs from 'app/components/organisms/Breadcrumbs/Breadcrumbs';
import ScriptEditorForm from './Tabs/ScriptEditorForm';
import Icon from 'app/components/atoms/Icon/Icon';
import Ellipsis from 'app/components/molecules/Ellipsis/Ellipsis';

const AvatarStyled = styled(Avatar)`
    width: 30px !important;
    height: 30px !important;
    margin-right: 8px;
    font-size: 1rem !important;
`;

const ButtonPublishStyled = styled(Button)`
  min-width: 80px !important;
`;

const DividerverticalStyled = styled(Divider)`
    margin: 4px 16px !important;
    height: 24px !important;
    align-self: center !important;
    width: 1px;
    background-color: ${({ theme }) => theme.material.colors.white} !important;
`;

const MoreChipStyled = styled(Chip)`
    background:  ${({ theme }) => theme.material.colors.background.fields} !important;
    & .MuiChip-label {
        max-width: 140px;
    }
`;

class ScriptEditor extends PureComponent<Object, Object> {
    static propTypes = {
        match: PropTypes.object.isRequired,
        script: PropTypes.object,
        scriptState: PropTypes.object,
        loadScript: PropTypes.func.isRequired,
        updateScript: PropTypes.func.isRequired,
        publishScript: PropTypes.func.isRequired,
        saveComponentState: PropTypes.func.isRequired,
    };

    state = {
        isSaving: false,
        isCodeValid: true,
        editorKey: 0,
    };

    constructor(props) {
        super(props);
        const id = get(props, 'match.params.id');
        id && props.loadScript(id);
        this.reloadSharing();
    }

    componentDidUpdate(prevProps) {
        const prevId = get(prevProps, 'match.params.id');
        const id = get(this.props, 'match.params.id');

        if (id !== prevId) {
            id && this.props.loadScript(id);
            this.reloadSharing();
        }
        const { script, draftedDetails, previewVersion, setDocumentTitle } = this.props;
        const name = script?.name;
        const prevName = prevProps?.script?.name;
        if(name && name !== prevName){
            setDocumentTitle(name);
        }

        if (draftedDetails !== prevProps.draftedDetails) {
            this.saveEditorState(draftedDetails);
        }

        if (previewVersion && previewVersion !== prevProps.previewVersion) {
            this.saveEditorState(previewVersion, true);
        }

        if (script && script !== prevProps.script) {
            this.saveScriptState(script);
        }        
    }
    componentWillUnmount() {
        this.props.setEntityPreviewVersion('script', null);
    }

    @bind
    saveScriptState(script) {
        this.props.saveComponentState('Script', { 
            editor: script, 
            drafted: false,
            draftedEditor: null,
            isPreviewVersion: false, 
        });
    };    

    @bind
    onSave(newVersion: ?boolean) {
        this.setState({ isSaving: true }, async () => {
            const { scriptState } = this.props;
            const { ...script } = get(scriptState, 'editor') || {};
            const { id, primary: { definition } } = script || {};
            await this.props.updateScript({ id, primary: { definition: definition || null }}, newVersion);
            if (newVersion) {
                await this.props.publishScript(id);
                await this.props.loadEntityVersions(id, 'script');
            }
            await this.props.loadScript(id);
            this.setState({ isSaving: false });
        });
    };

    @bind
    saveEditorState(stateUpdate: Object, isPreview) {
        const editorState = get(this.props, 'scriptState.editor') || {};
        const draftedEditorState = get(this.props, 'scriptState.draftedEditor', null);
        const editor = {
            ...editorState,
            ...stateUpdate,
        };
        const updatedState = { editor, drafted: true };
        if(isPreview && !draftedEditorState) {
            updatedState.draftedEditor = editorState;
            updatedState.isPreviewVersion = true;
        } else if(!isPreview) {
            updatedState.draftedEditor = null;
            updatedState.isPreviewVersion = false;
            updatedState.editor.id = this.props.script.id;
        }
        this.props.saveComponentState('Script', updatedState);
    };    

    @bind
    async uploadDefinition(file) {
        const definition = await readTextFile(file);
        await this.saveEditorState({ primary: { definition }});
        this.setState(({ editorKey }) => ({ editorKey: editorKey + 1 }));
    }

    @bind
    async uploadVersion(file) {
        const { showToastr } = this.props;
        const versionFile = await readCSVFile(file);

        try {
            const { type, primary: { definition }} = versionFile[0];
            if(type !== 'scriptversion') throw new Error('entity type is wrong');
            
            this.saveEditorState({ primary: { definition }});
        } catch(err) {
            console.error('[ERROR]', err); // eslint-disable-line no-console
            showToastr({ severity: 'error', detail: 'File format is wrong.' });
        }
    }

    @bind
    onDotMenuClick(title) {
        const { openScriptSidebar, id } = this.props;
        if (title === 'Publish') {
            this.onSave(true);
            return;
        }
        if (title === 'Download') {
            const { scriptState } = this.props;
            const { name, primary } = get(scriptState, 'editor') || {};
            saveText(name, get(primary, 'definition', {}), '.js');
            return;
        }
        openScriptSidebar({ title, id, type: 'script' });
    }

    @bind
    @memoize()
    buildDotMenu(canEdit, sidebarTitle, isCodeValid) {
        return [
            sidebarTitle !== 'Versions' && { name: 'Versions', icon: 'content-save-all' },
            { name: 'divider' },
            { name: 'Download', icon: 'download' },
            canEdit && { name: 'file', label: 'Upload', onSelect: this.uploadDefinition },
            { name: 'divider' },
            canEdit && { name: 'file', accept: '.csv', label: 'Import Version', onSelect: this.uploadVersion },
        ].filter(Boolean);
    }

    @bind
    backToSaveDraft() {
        const editor = get(this.props, 'scriptState.draftedEditor') || {};
        const updatedState = { editor, draftedEditor: null, isPreviewVersion: false, };
        this.props.saveComponentState('Script', updatedState);
        this.props.setEntityPreviewVersion('script', null);
    };

    @bind
    @memoize()
    dotMenu(sidebarTitle: string, isCodeValid: boolean, permissions) {
        const { canEdit } = permissions;
        return (
            <DotMenu
                key={13}
                onItemClick={this.onDotMenuClick}
                items={this.buildDotMenu(canEdit, sidebarTitle, isCodeValid)}
            />
        );
    }

    @bind
    @memoize()
    renderActions(sidebarTitle: string, isCodeValid: boolean, permissions: boolean, isPreviewVersion, sharingDetails) {
        const { users = [], teams = [] } = sharingDetails || {};
        return (
            <>
                <Ellipsis
                    data={[...users, ...teams]}
                    spaces={-14}
                    displaySize={isMobile ? 3 : 5}
                    renderCount={count => (
                        <MoreChipStyled
                            variant="default"
                            label={`+${count}`}
                            size="small"
                            onClick={() => this.onDotMenuClick('Sharing')}
                        />
                    )}
                    renderItem={
                        ({ name, image, id }: Object) => (
                            <AvatarStyled onClick={() => this.onDotMenuClick('Sharing')} src={image ? getAttachmentUrl(id, 'user', image): null} initials={name} />
                        )
                    }
                />
                <DividerverticalStyled />
                <IconButton icon="share-variant" title="Sharing" onClick={() => this.onDotMenuClick('Sharing')}>
                    <Icon name="share-variant" />
                </IconButton>
                <IconButton icon="information-outline" title="Edit script" onClick={() => this.onDotMenuClick('About')}>
                    <Icon name="information-outline" />
                </IconButton>
                {this.dotMenu(sidebarTitle, isCodeValid, permissions)}
            </>
        );
    }

    @bind
    @memoize()
    renderSecondaryActions(sidebarTitle: string, isCodeValid: boolean, permissions: boolean, isPreviewVersion) {
        const { canEdit } = permissions;
        return isPreviewVersion ? (
            <Button onClick={this.backToSaveDraft}>back to saved draft</Button>
        ) : (
            <>
                {canEdit && (
                    <>
                        <Button
                            disabled={!isCodeValid}
                            onClick={() => this.onSave(false)}
                            confirmationModalProps={{
                                message: 'Are you sure you want to save this version?',
                                declineButtonText: 'Cancel',
                                confirmButtonText: 'Save',
                            }}
                            withConfirmation
                        >
                            Save
                        </Button>
                        <ButtonPublishStyled
                            disabled={!isCodeValid}
                            key={15}
                            color="primary"
                            onClick={() => this.onSave(true)}
                            confirmationModalProps={{
                                message: 'Are you sure you want to publish this script? This will create a new version',
                                declineButtonText: 'Cancel',
                                confirmButtonText: 'Publish',
                            }}
                            withConfirmation
                        >
                            Publish
                        </ButtonPublishStyled>
                    </>
                )}
            </>
        );
    }

    @bind
    reloadSharing() {
        const { loadEntityWorkspaces } = this.props;
        const id = get(this.props, 'match.params.id');
        loadEntityWorkspaces('script', id);
    }

    @bind
    @memoize()
    buildBreadcrumbs(name, isPreviewVersion, previewVersion, drafted) {
        return (
            <Breadcrumbs
                list={[{ link: '/designer/scripts', title: 'Scripts' }, { title: name }]}
                withGoBack
                labelAtEnd={this.buildChipsVersion(isPreviewVersion, previewVersion, drafted)}
            />
        );
    }

    @bind
    @memoize()
    buildChipsVersion(isPreviewVersion, previewVersion, drafted) {
        if(previewVersion && isPreviewVersion) {
            return `Version ${previewVersion.primary.version} - READ ONLY`;
        }
        if(drafted) {
            return 'draft';

        }
        return null;
    }    

    @bind
    handleValidate(isCodeValid) {
        this.setState({ isCodeValid });
    }

    render() {
        const { scriptState, isLoading, sidebarTitle, previewVersion, sharingDetails } = this.props;
        const { editor: script, drafted, isPreviewVersion } = scriptState || {};
        const { isSaving, isCodeValid } = this.state;
        const { name } = script || {};
        const id = getStr(this.props, 'match.params.id') || '';

        let content = <Loader absolute backdrop />;

        const permissions = getPermissions(script && script.role);
        if (!isLoading && (isEmpty(script) || !permissions.canView)) {
            return <PageNotAllowed permissionError={true} title={`Script (ID:${id})`}/>;
        }

        if (!isLoading) {
            content = (
                <Fragment>
                    {isSaving && <Loader absolute backdrop />}
                    <Switch>
                        <Route path={`/designer/scripts/:id`} exact render={() =>
                            <Redirect to={`/designer/scripts/${id}/editor`}/>}
                        />
                        <Route path={`/designer/scripts/:id/editor`} render={() =>
                            <ScriptEditorForm
                                key={this.state.editorKey}
                                script={script}
                                saveEditorState={this.saveEditorState}
                                breadcrumbLine={this.buildBreadcrumbs(script.name, isPreviewVersion, previewVersion, drafted)}
                                sidebarActions={this.renderSecondaryActions(sidebarTitle, isCodeValid, permissions, isPreviewVersion)}
                                onValidate={this.handleValidate}
                                canEdit={permissions.canEdit}
                                isPreviewVersion={!!isPreviewVersion}
                                tabs={[{label:'SCRIPT'}]}
                                actions={this.renderActions(sidebarTitle, isCodeValid, permissions, isPreviewVersion, sharingDetails)}
                            />
                        }
                        />
                    </Switch>
                </Fragment>
            );
        }
        return (
            <PageTemplate title={name} subTitle={`#${id}`}>
                <ContentArea withHeader>
                    {content}
                </ContentArea>
            </PageTemplate>
        );
    }
}

export default connect(
    (state, props) => ({
        sharingDetails: state.entities.sidebar.workspaces.data,
        sidebarTitle: state.sidebar.title,
        isLoading: state.designer.script.isLoading,
        script: state.designer.script.data,
        scriptState: state.component.state.Script,
        profile: state.user.profile,
        id: getStr(props, 'match.params.id'),
        draftedDetails: state.entities.common.draftedDetails['script'],
        previewVersion: state.entities.common.previewVersion['script'],
    }),
    {
        loadScript,
        updateScript,
        publishScript,
        openScriptSidebar,
        saveComponentState,
        setEntityPreviewVersion,
        loadEntityVersions,
        loadEntityWorkspaces,
        showToastr,
        setDocumentTitle
    }
)(withTheme(ScriptEditor, 'script'));
