class ClickHandler {

    constructor({ click, dblclick } = {}) {
        this.clickCounter = 0;
        this.click = click;
        this.dblclick = dblclick;
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event) {
        ++this.clickCounter;
        if (this.clickCounter === 1) {
            setTimeout(() => {
                if (this.clickCounter === 1) {
                    this.click && this.click(event);
                } else {
                    this.dblclick && this.dblclick(event);
                }
                this.clickCounter = 0;
            }, 500);
        }
    }

}

export default ClickHandler;
