/* @flow */
import React, { PureComponent } from 'react';
import { CircularProgress, TextField, Avatar, Divider, Box } from '@mic3/platform-ui';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import produce from 'immer';

import VirtualListManaged from 'app/components/molecules/VirtualList/VirtualListManaged';
import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import RolesSelect from 'app/components/molecules/RolesSelect/RolesSelect';
import ListItem from 'app/components/molecules/List/ListItem';
import TypeIcon from 'app/components/atoms/TypeIcon/TypeIcon';
import Button from 'app/components/atoms/Button/Button';
import Icon from 'app/components/atoms/Icon/Icon';

import CheckBoxBlank from 'assets/images/icons/check_box_outline_blank.png';
import CheckBox from 'assets/images/icons/check_box.png';

import { loadResourceList, clearResourcesList } from 'store/actions/admin/workspacesActions';

import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import Immutable from 'app/utils/immutable/Immutable';
import { focusInput, isEmpty } from 'app/utils/utils';

const inputProps: Object = { disableUnderline: true };
export const ListItemStyled = styled(ListItem)`
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    cursor: pointer;
    box-shadow: none;
    background: ${({ selected, theme }) => (selected ? theme.material.colors.background.active : theme.material.colors.background.paper)};
    &:hover {
        background: ${({ selected, theme }) => (selected ? theme.material.colors.background.active : theme.material.colors.background.hover)};
    }
    @media (max-width: 1100px) {
        padding-right: 2rem;
    }
`;
export const StyledAvatar = styled(Avatar)`
    && {
        ${({ selected }) => selected ? `
        background-color: gray !important;
        color: transparent
        ` : `
        ${ListItemStyled}:hover & {
            color: transparent;
        }
        `};
    }
`;
export const StyledTypeIcon = styled(TypeIcon)`
    && {
        ${({ selected }) => selected ? `
        background-color: gray !important;
        color: transparent
        ` : `
        ${ListItemStyled}:hover & {
            color: transparent;
        }
        `};
    }
`;
export const StyledAvatarOverlay = styled.div`
    display: none;
    width: 24px;
    height: 24px;
    position: absolute;
    z-index: 1;
    top: ${({ showRoles, isTypeIcon }) => ((showRoles || isTypeIcon) ? '20px' : '16px')};
    left: ${({ isTypeIcon }) => (isTypeIcon ? '12px' : '16px')};
    && {
        ${({ selected }) => selected ? `
        display: block;
        background-image: url(${CheckBox});
        background-repeat: no-repeat;
        background-position: center center;
        ` : `
        ${ListItemStyled}:hover & {
            display: block;
            background-image: url(${CheckBoxBlank});
            background-repeat: no-repeat;
            background-position: center center;
        }
        `};
    }
`;
export const ResourcesList = styled.div`
    div[class*='Container__ContainerStyle'] {
        background-color: transparent;
        margin-bottom: 0;
    }
    div[class*='VirtualList__TinyVirtual'] {
        padding-top: 0 !important;
        margin-top: 0.2em;
    }
    height: ${isMobile ? '60vh' : '50vh'} !important;
    padding-bottom: 36px;
`;
export const StyledButton = styled(Button)`
    margin: 0;
    color: #7391D0;
`;
export const StyledDivider = styled(Divider)`
    margin: 5px 0 !important;
`;
export const SearchWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding 5px 16px;
`;
export const StyledBox = styled(Box)`
    display: flex;
    align-items: center;
    width: 100%;
`;
export const StyledTextField = styled(TextField)`
    margin-left: 5px !important;
`;

class AddEntityByPrimaryClassModal extends PureComponent<Object, Object> {
    static propTypes = {
        isOpen: PropTypes.bool.isRequired,
        isBulkSelect: PropTypes.bool,
        isLoading: PropTypes.bool,
        selectedItems: PropTypes.array,
        startIndex: PropTypes.number,
        records: PropTypes.array,
        totalRecords: PropTypes.number,
        loadResourceList: PropTypes.func.isRequired,
        closeModal: PropTypes.func.isRequired,
        addedResourcesList: PropTypes.array,
        submitBtnLabel: PropTypes.string,
        filterBy: PropTypes.array,
        orderBy: PropTypes.array,
        showRoles: PropTypes.bool,
    };

    state = Immutable({
        search: '',
        filterBy: [],
        orderBy: [],
        selectedItem: {},
        selectedItems: [],
        primaryClassUri: '',
        isEntityQuery: false
    });

    defaultState: Object = { ...this.state }; // preserve the initial state in a new object.

    virtualListRef = React.createRef();
    refMap = {};

    constructor(props){
        super(props);
        const { resetOnMount, clearResourcesList } = props || {};
        if(resetOnMount){
            clearResourcesList();
        }
    }

    @bind
    @memoize()
    renderListItemActions(data) {
        const { id } = data;
        const { userRole, showRoles } = this.props;
        if (showRoles) {
            this.refMap[id] = React.createRef();
            return <RolesSelect ref={this.refMap[id]} defaulValue={userRole} />;
        }
        return null;
    }

    componentDidMount() {
        const { filterBy, orderBy } = this.props;
        if (!isEmpty(filterBy)) this.setState({ filterBy });
        if (!isEmpty(orderBy)) this.setState({ orderBy });
    }

    componentDidUpdate(prevProps, prevState) {
        const { isOpen, isBulkSelect, selectedItems, isMobileView  } = this.props;
        const { isEntityQuery } = this.state;
        if (prevProps.isOpen !== isOpen && isOpen) {
            if ((isBulkSelect && isEntityQuery) && !isEmpty(selectedItems)) {
                this.setState({ selectedItems });
            }
        }
        // to focus when we select entity type and then move next to select the entity
        if(prevState.isEntityQuery !== isEntityQuery && !isMobileView){
            const el = document.getElementById('entity-by-primary-class-search-input');
            el.focus();
        }
    }

    @bind
    renderComponent({ style, index, data }: { style: Object, index: number, data: Object }) {
        const { id, name, type: entityType } = data;
        const { isBulkSelect, showRoles } = this.props;
        const { selectedItem, isEntityQuery } = this.state;
        const selected = (!isBulkSelect || !isEntityQuery) ? selectedItem.id === id : !isEmpty(this.getSelectedListItem(id));
        const subTitle = `#${id}`;
        let selectedData = { id, name, type: !isEntityQuery ? 'primaryClass': entityType };
        if (!isEntityQuery) {
            selectedData = { ...selectedData, uri: data.uri, color: data.color };
        }

        return (
            <div style={style} key={index}>
                <ListItemStyled
                    component={
                        !isEntityQuery ? (
                            <div>
                                <StyledTypeIcon
                                    type={'class'}
                                    color={data.color}
                                    size={'lg'}
                                    selected={selected}
                                />
                                <StyledAvatarOverlay selected={selected} showRoles={showRoles} isTypeIcon={true} />
                            </div>
                        ) : (
                            <div>
                                <StyledAvatar
                                    selected={selected}
                                    initials={name}
                                />
                                <StyledAvatarOverlay selected={selected} showRoles={showRoles} isTypeIcon={false} />
                            </div>
                        )
                    }
                    title={name}
                    subTitle={subTitle}
                    raised
                    onClick={e => this.onSelectListItem(e, selectedData)}
                    selected={selected}
                    actions={this.renderListItemActions(data)}
                />
            </div>
        );
    }

    @bind
    onSelectListItem(event: Object, data: Object) {
        const { isBulkSelect } = this.props;
        const { selectedItem, isEntityQuery } = this.state;
        event.preventDefault();
        if (!isBulkSelect || !isEntityQuery) {
            const selectedValue = !isEmpty(selectedItem) && selectedItem.id === data.id ? {} : data;
            this.setState({ selectedItem: selectedValue });
            this.updateList();
        } else {
            const selectedItem = this.getSelectedListItem(data.id);
            if (isEmpty(selectedItem)) {
                this.setState(
                    produce((draft) => {
                        draft.selectedItems.push(data);
                    }),
                    this.updateList
                );
            } else {
                this.setState(
                    prevState => ({
                        selectedItems: prevState.selectedItems.filter(({ id }) => id !== data.id)
                    }),
                    this.updateList
                );
            }
        }
    }

    @bind
    getSelectedListItem(itemId: number) {
        if (!itemId) {
            return {};
        }
        const { selectedItems } = this.state;
        return selectedItems.find(({ id }) => id === itemId) || {};
    }

    @bind
    updateList() {
        if (this.virtualListRef && this.virtualListRef.current) {
            this.virtualListRef.current.forceUpdate();
        }
    }

    @bind
    async onSubmit() {
        const { isBulkSelect } = this.props;
        const { selectedItem, selectedItems, isEntityQuery } = this.state;
        const selectedValue = (!isBulkSelect || !isEntityQuery) ? selectedItem : selectedItems;
        await this.props.onSubmit(selectedValue);
        this.closeModal();
    }

    @bind
    onChangeSearch(e: Object) {
        if (e.persist) {
            e.persist();
        }
        this.setState({ search: e.target.value }, () => {
            const { search, filterBy } = this.state;
            const filters = !isEmpty(filterBy) ? filterBy.filter(({ field, op }) => (field !== 'name' && op !== 'contains')) : [];
            const searchFilter = { field: 'name', op: 'contains', value: search ? search.trim() : '' };
            this.setState({ filterBy: !isEmpty(filters) ? [ ...filters, searchFilter] : [searchFilter]});
        });
    }

    @bind
    loadData(opts: Object) {
        const { isEntityQuery, primaryClassUri } = this.state;
        const { addedResourcesList, showActiveClasses, records } = this.props;
        const type = !isEntityQuery ? 'primaryClass' : primaryClassUri;
        let options = { ...opts };

        if (isEntityQuery) {
            options = { ...options, type };
        }
        if (!isEmpty(addedResourcesList)) {
            const addedResourcesListIdsList = addedResourcesList.map(({ id }) => id);
            options.filterBy = [...options.filterBy, { field: 'id', op: 'not in', value: addedResourcesListIdsList }];
        }

        if (!records.length || isEntityQuery) {
            return this.props.loadResourceList(options, type, this.props.applicableOn, isEntityQuery, showActiveClasses);
        }

        return Promise.resolve();
    }

    @bind
    closeModal() {
        this.props.clearResourcesList();
        this.setState(this.defaultState, this.props.closeModal);
    }

    @bind
    async backToPrimaryClass() {
        const { clearResourcesList, loadResourceList, applicableOn, showActiveClasses } = this.props;
        await clearResourcesList();
        this.setState(this.defaultState, (async () => {
            const options = { filterBy: [], orderBy: []};
            await loadResourceList(options, 'primaryClass', applicableOn, false, showActiveClasses);
        }));
    }

    @bind
    async onClickNext() {
        const { clearResourcesList, loadResourceList, applicableOn, showActiveClasses } = this.props;
        const { selectedItem } = this.state;
        const copySelectedItem = { ...selectedItem };
        const { uri } = copySelectedItem || {};
        if (uri) {
            const filterBy = [{ field: 'active', op: '=', value: true }];
            const orderBy = [{ field: 'modifiedDate', direction: 'desc nulls last'}];
            const options = { filterBy, orderBy, type: uri };
            await this.setState({
                search: '',
                selectedItem: {},
                isEntityQuery: true,
                primaryClassUri: uri,
                filterBy,
                orderBy
            });
            await clearResourcesList();
            await loadResourceList(options, uri, applicableOn, true, showActiveClasses);
        }
    }

    @bind
    filterRecords(records, search, addedResourcesList) {
        const addedResourceKeys = (addedResourcesList || []).map(resource => resource && resource.id);

        return records.filter((record) => {
            if (addedResourceKeys.includes(record.id)) {
                return false;
            }
            if (search) {
                return record.name?.toLowerCase()?.includes(search.toLowerCase());
            }
            return true;
        });
    }

    render() {
        const {
            isOpen,
            isMobileView,
            isBulkSelect,
            isLoading,
            records,
            startIndex: start = 0,
            totalRecords: count,
            addedResourcesList,
            submitBtnLabel
        } = this.props;
        const { selectedItem, selectedItems, filterBy, orderBy, search, isEntityQuery } = this.state;
        const selectData = (!isBulkSelect || !isEntityQuery) ? selectedItem : selectedItems;
        const filteredRecords = !isEntityQuery ? this.filterRecords(records, search, addedResourcesList) : records;
        const totalRecords = !isEntityQuery ? (filteredRecords || []).length : count;
        const startIndex = !isEntityQuery ? 0 : start;

        return (
            isOpen && (
                <ModalDialog
                    bgcolor={'rgba(40, 51, 75, 1)'}
                    dialogProps={{ overflow: 'hidden' }}
                    withoutClose={!(isMobileView || isEntityQuery)}
                    onClose={!isEntityQuery ? this.closeModal : this.backToPrimaryClass}
                    closeIcon={!isEntityQuery ? 'close' : 'arrow-left'}
                    title={`Find entity by entity type`}
                    actions={
                        isLoading ? (
                            <CircularProgress size={24} color="primary" />
                        ) : (
                            <>
                                {!isMobileView && <StyledButton onClick={this.closeModal}>Cancel</StyledButton>}
                                {!isEntityQuery ? (
                                    <StyledButton disabled={isEmpty(selectData)} onClick={this.onClickNext}>
                                        Next
                                    </StyledButton>
                                ) : (
                                    <Button disabled={isEmpty(selectData)} color="primary" onClick={this.onSubmit}>
                                        {submitBtnLabel || 'Add'}
                                    </Button>
                                )}
                            </>
                        )
                    }
                >
                    <StyledDivider />
                    <SearchWrapper>
                        <StyledBox>
                            <Icon name="magnify" />
                            <StyledTextField
                                id='entity-by-primary-class-search-input'
                                variant="standard"
                                onChange={this.onChangeSearch}
                                value={search}
                                fullWidth
                                margin="none"
                                placeholder={`Search ${!isEntityQuery ? 'entity types' : 'entities'}...`}
                                InputProps={inputProps}
                                inputRef={ref => !isMobileView && focusInput(ref)}
                            />
                        </StyledBox>
                    </SearchWrapper>
                    <StyledDivider />
                    <ResourcesList>
                        <VirtualListManaged
                            ref={this.virtualListRef}
                            renderComponent={this.renderComponent}
                            itemSize={84}
                            title={`${totalRecords >= 1000 ? '999+' : totalRecords} ${!isEntityQuery ? 'entity types' : 'entities'}`}
                            itemCount={totalRecords || 0}
                            loadData={this.loadData}
                            isLoading={isLoading}
                            startIndex={startIndex}
                            filterBy={filterBy}
                            orderBy={orderBy}
                            list={filteredRecords}
                            maxWidth="1024"
                        />
                    </ResourcesList>
                </ModalDialog>
            )
        );
    }
}

export default connect(
    (state, ownProps) => ({
        records: state.admin.workspaces.resourceList.records,
        isLoading: state.admin.workspaces.resourceList.isLoading,
        startIndex: state.admin.workspaces.resourceList.startIndex,
        totalRecords: state.admin.workspaces.resourceList.count,
        isMobileView: state.global.isMobile
    }),
    {
        loadResourceList,
        clearResourcesList
    }
)(AddEntityByPrimaryClassModal);
