/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mic3/platform-ui';
import { connect } from 'react-redux';

import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import { bind } from 'app/utils/decorators/decoratorUtils';
import history from 'store/History';
import { startPipeline } from 'store/actions/designer/designerPipelineActions';

class StartPipeline extends PureComponent<Object, Object> {

    static propTypes = {
        startPipeline: PropTypes.func.isRequired,
        isLoading: PropTypes.bool,
    };

    formRef = React.createRef();
    components = [
        {
            type: 'pipelineTypeahead',
            properties: {
                label: 'Pipeline',
                name: 'pipeline',
                filterBy: [
                    { field: 'active', op: '=', value: true },
                    { field: 'primary.iot_pipeline/runtime_started', op: 'is null'}
                ],
            },
            constraints: { required: true }
        },
    ]

    @bind
    onFormSubmit(event: Event) {
        event.preventDefault();
        this.formRef.current.isValidForm().then(async ({ data, errors }) => {
            if (!errors) {
                const result  = await this.props.startPipeline(
                    data.pipeline.id, 
                    // data.pipeline.lastVersion.primary['iot_pipeline_version/version']
                );
                const { id } = result;
                if (id) {
                    history.push(`/pipeline-monitor/${id}`);
                }
                if(result instanceof Error) {
                    this.props.reloadList();
                    this.props.onClose();
                }
            }
        });
    };

    render(): Object {
        const { isLoading, onClose } = this.props;
        return (
            <ModalDialog
                title="Start New Pipeline"
                onClose={onClose}
                isLoading={isLoading}
                actions={
                    <Button onClick={this.onFormSubmit}>
                      Start
                    </Button>
                }
            >
                <FormGenerator
                    components={this.components}
                    ref={this.formRef}
                />
            </ModalDialog>
        );
    }
}

export default connect(state => ({
    isLoading: state.entities.entities.isLoading,
}), {
    startPipeline
})(StartPipeline);
