/* @flow */

import { gql } from '@apollo/client';

export default extraQueryFields => gql`
query classificationAutocompleteQuery($startIndex: Int, $stopIndex: Int, $filterBy: [JSON], $orderBy: [JSON], $excludeBy: [JSON]) {
  result: classes(startIndex: $startIndex, stopIndex: $stopIndex, filterBy: $filterBy, orderBy: $orderBy, excludeBy: $excludeBy) {
    id
    name
    uri
    color
    ${extraQueryFields || ''}
  }
}
`;
