/* @flow */

import { gql } from '@apollo/client';

export default gql`
query digitalTwinProcessDefinitionQuery($filterBy: [JSON]) {
  result: processDefinitions(filterBy: $filterBy) {
    id
    name
  }
}`;
