/* @flow */

import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { RouterMatchPropTypeBuilder } from 'app/utils/propTypes/common';
import PageNotAllowed from 'app/containers/ErrorPages/PageNotAllowed';
import PageTemplate from 'app/components/templates/PageTemplate';
import DotMenu from 'app/components/molecules/DotMenu/DotMenu';
import Loader from 'app/components/atoms/Loader/Loader';
import UserAbout from 'app/containers/Admin/UserManagement/Details/UserAbout';
import { loadEntity, updateEntity } from 'store/actions/entities/entitiesActions';
import { IconButton, Badge } from '@mic3/platform-ui';

import { get } from 'app/utils/lo/lo';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { openEntitySidebar } from 'store/actions/entities/entitySidebarActions';
import Breadcrumbs from 'app/components/organisms/Breadcrumbs/Breadcrumbs';
import { typeTitlesMultiple, redirectTypes } from 'app/config/typesConfig';
import { getPermissions } from 'app/config/rolesConfig';
import { isEmpty } from 'app/utils/utils';
import UserAdd from 'app/containers/Admin/UserManagement/UserAdd';
import { isVisibleTab, buildDotMenu } from 'app/utils/entity/entityUtils';
import Relations from 'app/containers/Entities/Relationships/Relations';
import { openRelationSidebar } from 'store/actions/entities/relationSidebarActions';
import { openProcessSidebar } from 'store/actions/abox/processSidebarActions';
import { openTaskSidebar } from 'store/actions/abox/taskSidebarActions';
import { openTeamSidebar } from 'store/actions/entities/teamSidebarActions';
import { openWorkspaceSidebar } from 'store/actions/entities/workspaceSidebarActions';
import { getUnreadMessages } from 'app/utils/entity/entityUtils';
import Icon from 'app/components/atoms/Icon/Icon';
import { loadEntityPrintTemplates } from 'store/actions/entities/printTemplateActions';
import { getSelectedPrimaryClass } from 'app/utils/classification/classificationUtils';
import { setDocumentTitle } from 'store/actions/app/appActions';

/**
 * Defines the routes for User Management views
 */
class UserDetail extends PureComponent<Object, Object> {
    static propTypes = {
        match: RouterMatchPropTypeBuilder({}),
        loadEntity: PropTypes.func.isRequired,
        isLoading: PropTypes.bool,
        user: PropTypes.object
    };
    state = {
        isModalOpen: false
    };

    constructor(props: Object) {
        super(props);
        props.id && props.loadEntity('user', props.id);
        this.loadPrintTempaltes();
    }

    componentDidUpdate(prevProps){
        const { id, user, setDocumentTitle } = this.props;
        const name = user?.name;
        if(name){
            setDocumentTitle(name);
        }
        if (id && id !== prevProps.id) {
            this.props.loadEntity('user', id);
            this.loadPrintTempaltes();
        }
    }

    @bind
    loadPrintTempaltes() {
        const selectedClass = getSelectedPrimaryClass('user', this.props.primaryClasses);
        if(selectedClass) {
            this.props.loadEntityPrintTemplates(selectedClass.id);
        }
    }

    @bind
    toggleModal() {
        this.setState(prevState => ({ isModalOpen: !prevState.isModalOpen }));
    }

    @bind
    onDotMenuClick(title) {
        const { id } = this.props;
        switch (title) {
            case 'Rename':
                return this.toggleModal();
            case 'Enable':
                return this.toggleUserStatus(true);
            case 'Disable':
                return this.toggleUserStatus(false);
            default:
                break;
        }
        this.props.openEntitySidebar({ id, title, type: 'user', internal: true });
    }

    @bind
    toggleUserStatus(active) {
        const { id } = this.props.user;
        this.props.updateEntity({ id, active, type: 'user' });
    }

    @bind
    @memoize()
    dotMenu(details: Object, title: string, pathname: string = '', isActive, canEdit, entityTemplates) {
        const menu = buildDotMenu({ details, title, pathname, entityTemplates });
        const moreMenus = canEdit
            ? [
                { name: 'divider' },
                { name: 'Rename', icon: 'pencil' },
                !isActive && { name: 'Enable', icon: 'check' },
                isActive && { name: 'Disable', icon: 'close' }
            ].filter(Boolean)
            : [];
        return <DotMenu key={13} onItemClick={this.onDotMenuClick} items={[...menu, ...moreMenus]} />;
    }

    @bind
    @memoize()
    buildBreadcrumbs(name) {
        return <Breadcrumbs list={[{ link: `/${redirectTypes['user']}`, title: typeTitlesMultiple['user'] }, { title: name }]} withGoBack />;
    }

    @bind
    @memoize()
    renderActions(unreadMessages, permissions, details) {
        if (!permissions?.canComment) {
            return null;
        }
        const { primaryClass } = details || {};
        return isVisibleTab('A-Live', primaryClass) ? (
            <IconButton key={66} title="Open messenger" onClick={() => this.onDotMenuClick('A-Live')}>
                <Badge color="error" badgeContent={unreadMessages} max={99}>
                    <Icon name="messenger" type="af" />
                </Badge>
            </IconButton>
        ) : null;
    }

    render() {
        const { isLoading, user, id, sidebarTitle, openEntitySidebar, subscriptions, entityTemplates } = this.props;
        const { pathname } = this.props.location;
        const permissions = getPermissions(user && user.role);
        const { canEdit } = permissions || {};
        if (!isLoading && (isEmpty(user) || !permissions.canView)) {
            return <PageNotAllowed title="Users" />;
        }
        const unreadMessages = getUnreadMessages(id, subscriptions, 'user');

        return (
            <Fragment>
                {isLoading && <Loader absolute backdrop />}
                {user?.id === id && (
                    <PageTemplate title={get(user, 'name')} subTitle={` #${id} `}>
                        <Switch>
                            <Route path={`/user-management/:id`} exact component={() => <Redirect to={`/user-management/${id}/about`} />} />
                            <Route path={`/user-management/:id/about`}>
                                <UserAbout
                                    user={user}
                                    sidebarActions={[this.dotMenu(user, sidebarTitle, pathname, user?.active, canEdit, entityTemplates)]}
                                    breadcrumbLine={this.buildBreadcrumbs(user.name)}
                                    canEdit={canEdit}
                                    openSidebar={title => openEntitySidebar({ title, id, type: 'user', internal: true })}
                                />
                            </Route>
                            <Route
                                path={`/user-management/:id/relationships`}
                                exact
                                render={() => (
                                    <Relations
                                        openEntitySidebar={this.props.openEntitySidebar}
                                        openTaskSidebar={this.props.openTaskSidebar}
                                        openProcessSidebar={this.props.openProcessSidebar}
                                        openRelationSidebar={this.props.openRelationSidebar}
                                        openTeamSidebar = {this.props.openTeamSidebar}
                                        openWorkspaceSidebar={this.props.openWorkspaceSidebar}
                                        fromType="user"
                                        fromId={user?.id}
                                        canEdit={canEdit}
                                        details={user}
                                        breadcrumbLine={this.buildBreadcrumbs(user.name, 'Relationships')}
                                        sidebarActions={[
                                            this.renderActions(unreadMessages, permissions, user),
                                            this.dotMenu(user, sidebarTitle, pathname, user?.active, canEdit, entityTemplates)
                                        ]}
                                    />
                                )}
                            />
                        </Switch>
                        {this.state.isModalOpen && <UserAdd onClose={this.toggleModal} title="Rename" rename data={user} />}
                    </PageTemplate>
                )}
            </Fragment>
        );
    }
}

export default connect(
    (state: Object, ownProps: Object): Object => ({
        id: ownProps.match.params.id,
        isLoading: state.entities.sidebar.details.isLoading,
        user: get(state.entities.sidebar, 'details.data', {}),
        sidebarTitle: state.sidebar.title,
        subscriptions: state.chat.subscriptions.data,
        entityTemplates: state.entities.printTemplates.entity.records,
        primaryClasses: state.app.allPrimaryClasses.records || [],
    }),
    { 
        openEntitySidebar, 
        loadEntity, 
        updateEntity, 
        openRelationSidebar, 
        openProcessSidebar, 
        openTaskSidebar,
        openTeamSidebar,
        openWorkspaceSidebar,
        loadEntityPrintTemplates,
        setDocumentTitle
    }
)(UserDetail);
