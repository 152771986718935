/* @flow */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import memoizeOne from 'memoize-one';

import Loader from 'app/components/atoms/Loader/Loader';
import ReactEcharts from 'echarts-for-react';
import Widget from 'app/components/atoms/Widget/Widget';

import theme from 'app/themes/theme.default';
import { generateColor } from 'app/utils/avatar/avatar';
import { graphql } from 'graphql/client';
import { isEmpty } from 'app/utils/utils';
import { get } from 'app/utils/lo/lo';
import history from 'store/History';

import tasksCountByStatusQuery from 'graphql/dashboard/widgetTasksCountByStatusQuery';
import tasksCountByDueDateQuery from 'graphql/dashboard/widgetTasksCountByDueDateQuery';
import tasksCountByStartDateQuery from 'graphql/dashboard/widgetTasksCountByStartDateQuery';
import tasksCountByStatusLastUpdateQuery from 'graphql/dashboard/widgetTasksCountByStatusLastUpdateQuery';
import tasksCountByCreatedDateEndDateQuery from 'graphql/dashboard/widgetTasksCountByCreatedDateEndDateQuery';
import tasksCountByInvolvementQuery from 'graphql/dashboard/widgetTasksCountByInvolvementQuery';
import tasksCountByGroupQuery from 'graphql/dashboard/widgetTasksCountByGroupQuery';

import { saveFiltersPreferences } from 'store/actions/admin/usersActions';
import DashboardBreadcrumbs from 'app/components/Dashboards_new/DashboardBreadcrumbs';
import DashboardTaskWidgetList from 'app/components/Dashboards_new/DashboardTaskWidgetList';
import { widgetGroups, widgetOptions } from 'app/config/dashboardWidgetConfig';

class DashboardTaskWidget extends PureComponent<Object, Object> {

    static propTypes = {
        user: PropTypes.object,
        preferences: PropTypes.object,
        widgetGroups: PropTypes.array
    };

    constructor(props) {
        super(props);
        const { onDashboardSettingsChange, widgetIndex } = this.props;
        const { breadcrumbs, selectedGroup } = this.setDefaultWidgetSettings(props.preferences, props.widgetGroups);
        this.state = {
            breadcrumbs: breadcrumbs,
            selectedGroup: selectedGroup,
            isLoading: true,
            data: null,
        };
        this.loadWidgetData(this.state);
        onDashboardSettingsChange(this.state, widgetIndex);
    }

    setDefaultWidgetSettings(preferences, groups) {
        let breadcrumbs = [];
        let selectedGroup = groups[0];
        if (isEmpty(preferences.dashboard)) return { breadcrumbs, selectedGroup };

        const prefBreadcrumbs = preferences.dashboard[this.props.widgetIndex].breadcrumbs;
        const prefSelectedGroup = preferences.dashboard[this.props.widgetIndex].selectedGroup;

        breadcrumbs = prefBreadcrumbs.map((breadcrumb) => {
            let updatedBreadcrumbs;
            for (const group of groups) {
                let newselectedGroup;
                let newSelectedOption;
                if (group.field === breadcrumb.field) {
                    newselectedGroup = group;
                    for(const option of group.options) {
                        if (option.name === breadcrumb.selectedOption.name) {
                            newSelectedOption = option;
                            break;
                        };
                    };
                    if (breadcrumb.field !== 'assigneeId' && breadcrumb.field !== 'processDefinitionName') {
                        updatedBreadcrumbs = {...newselectedGroup, selectedOption: newSelectedOption};
                    } else {
                        updatedBreadcrumbs = {...newselectedGroup, selectedOption: breadcrumb.selectedOption};
                    }
                    break;
                };
            }
            return updatedBreadcrumbs;
        });

        selectedGroup = groups.find((g) => {
            return g.field === prefSelectedGroup.field;
        });

        return { breadcrumbs, selectedGroup };
    }

    loadWidgetData = memoizeOne(({breadcrumbs, selectedGroup}) => {
        const subFilter = { filterBy: [], excludeBy:[] };
        let requestQueryParam;

        if (!isEmpty(breadcrumbs)) {
            for (const group of breadcrumbs) {
                if (!isEmpty(group.selectedOption.filter)) {
                    subFilter.filterBy = [...subFilter.filterBy, ...group.selectedOption.filter];
                } else {
                    subFilter.excludeBy = [...subFilter.excludeBy, ...group.selectedOption.exclude];
                }
            }
        }

        if (!isEmpty(selectedGroup.group)) {
            requestQueryParam = {
                filterBy: subFilter.filterBy,
                excludeBy: subFilter.excludeBy,
                groupBy: selectedGroup.group
            };
            this.fetchTasksCountGroupBy(requestQueryParam, tasksCountByGroupQuery).then((tasks) => {
                this.setState({ data: tasks, isLoading: false });
            });
        } else {
            const field = !isEmpty(selectedGroup.field) ? selectedGroup.field : '';
            switch (field) {
                // dispatch request (count tasks by status)
                case 'status':
                    requestQueryParam = this.parseQueryParameter(selectedGroup, subFilter);
                    this.fetchTasksFilterBy(requestQueryParam, tasksCountByStatusQuery).then((tasks) => {
                        this.setState({ data: tasks, isLoading: false });
                    });
                    break;
                // dispatch request (count tasks by due date)
                case 'dueDate':
                    requestQueryParam = this.parseQueryParameter(selectedGroup, subFilter);
                    this.fetchTasksFilterBy(requestQueryParam, tasksCountByDueDateQuery).then((tasks) => {
                        this.setState({ data: tasks, isLoading: false });
                    });
                    break;
                // dispatch request (count tasks by bpmn variables start date)
                case 'bpmnVariablesStartDate':
                    requestQueryParam = this.parseQueryParameter(selectedGroup, subFilter);
                    this.fetchTasksFilterBy(requestQueryParam, tasksCountByStartDateQuery).then((tasks) => {
                        this.setState({ data: tasks, isLoading: false });
                    });
                    break;
                // dispatch request (count tasks by status last update)
                case 'taskStatusLastUpdate':
                    requestQueryParam = this.parseQueryParameter(selectedGroup, subFilter);
                    this.fetchTasksFilterBy(requestQueryParam, tasksCountByStatusLastUpdateQuery).then((tasks) => {
                        this.setState({ data: tasks, isLoading: false });
                    });
                    break;
                // dispatch request (count tasks start date & end date)
                case 'startDate':
                case 'endDate':
                    requestQueryParam = this.parseQueryParameter(selectedGroup, subFilter);
                    this.fetchTasksFilterBy(requestQueryParam, tasksCountByCreatedDateEndDateQuery).then((tasks) => {
                        this.setState({ data: tasks, isLoading: false });
                    });
                    break;
                // dispatch request (count tasks by involvement)
                case 'involvement':
                    requestQueryParam = this.parseQueryParameter(selectedGroup, subFilter);
                    requestQueryParam.teamMember = [{ or: [...requestQueryParam.teamMember] }];
                    this.fetchTasksFilterBy(requestQueryParam, tasksCountByInvolvementQuery).then((tasks) => {
                        this.setState({ data: tasks, isLoading: false });
                    });
                    break;

                default:
                    break;
            }
        }
    })

    // parse query filter parameter
    parseQueryParameter = (selectedGroup, subFilter) => {
        const group = {...selectedGroup};
        const requestQuery = {};

        // handling for involvement query filters
        if (selectedGroup.field === 'involvement') {
            const { id, groups } = this.props.user;
            let options = [...selectedGroup.options];
            options = options.map((opts) => {
                const option = { ...opts, filter:  JSON.parse(JSON.stringify(opts.filter)) };
                for (const [index, value] of option.filter.entries()) {
                    if (!value.hasOwnProperty('value')) {
                        if (id !== null) {
                            option.filter[index].op = '=';
                            option.filter[index].value = id;
                        }
                    } else {
                        if (option.filter[index].value !== groups)
                            option.filter[index].value = groups;
                    }
                }
                return option;
            });
            group.options = options;
        }
        if (!isEmpty(group.options)) {
            for (const option of group.options) {
                const property = option.label;
                const filterBySubFilter = subFilter.filterBy;
                const excludeBySubFilter = subFilter.excludeBy;

                if (!isEmpty(option.filter)) {
                    requestQuery[property] = !isEmpty(filterBySubFilter) ? [...filterBySubFilter, ...option.filter] : option.filter;
                } else {
                    requestQuery[property] = filterBySubFilter;
                }

                if (!isEmpty(option.exclude)) {
                    requestQuery[`${property}Exclude`] = !isEmpty(excludeBySubFilter) ? [...excludeBySubFilter, ...option.exclude] : option.exclude;
                } else {
                    requestQuery[`${property}Exclude`] = excludeBySubFilter;
                }
            }
        }
        return requestQuery;
    }

    parseTasksData = memoizeOne((data) => {
        if (!isEmpty(data.message)) {
            throw new Error(`${data.message}, contact support.`);
        }
        const { selectedGroup } = this.state;
        const selectedGroupOptions = [];
        const seriesData = [];
        let totalCount = 0;
        if (data) {
            // data handling if request returns an array
            if (Array.isArray(data)) {
                // data handling if widget selected group is equal to priority
                if (selectedGroup.field === 'priority') {
                    const priorityRange = [1, 2, 3, 4, 5];
                    for (const item of data) {
                        if (priorityRange.includes(item.priority)) {
                            const count = Number(item.count);
                            const option = selectedGroup.options && selectedGroup.options.find(option => option.value === item.priority);
                            totalCount = totalCount + count;
                            selectedGroupOptions.push({
                                ...option,
                                count: count,
                                filter: [ { field: 'priority', op: '=', value: item.priority } ]
                            });
                            seriesData.push({
                                name: option.name,
                                value: count,
                                itemStyle: option && option.itemStyle ? option.itemStyle : {
                                    color: generateColor(Object.values(theme.statusColors), option.name)
                                }
                            });
                        }
                    }
                } else {
                    // handles data result by assignee and processDefinitionName
                    for (const result of data) {
                        let name = '';
                        let option;
                        const value = Number(result.count);
                        totalCount = totalCount + value;
                        if (result.hasOwnProperty('name')) {
                            name = !isEmpty(result.name) ? result.name : 'Unassigned';
                            option = {
                                value: !isEmpty(result.id) ? [{ id: result.id, name: result.name, login: result.login, image: result.image }] : 'is null',
                                count: value,
                                filter: !isEmpty(result.id) ? [{ field: 'assignee.id', op: '=', value: result.id }] : [{ field: 'assignee.id', op: 'is null' }]
                            };
                        } else {
                            name = !isEmpty(result.processDefinitionName) ? result.processDefinitionName : 'Process Type N/A';
                            option = {
                                value: !isEmpty(result.processDefinitionName) ? [result.processDefinitionName] : 'is null',
                                count: value,
                                filter: [{ field: 'process.processDefinition.name', op: '=', value: result.processDefinitionName }]
                            };
                        }
                        const seriesOption = {
                            name,
                            value,
                            itemStyle: {
                                color: generateColor(Object.values(theme.statusColors), name)
                            }
                        };
                        selectedGroupOptions.push({...seriesOption, ...option});
                        seriesData.push(seriesOption);
                    }
                }
            } else {
                // data handling if request returns an object
                for (const key in data) {
                    const option = selectedGroup.options && selectedGroup.options.find(option => option.label === key);
                    const value = Number(data[key]);
                    totalCount = totalCount + value;
                    if (!isEmpty(option)) {
                        selectedGroupOptions.push({...option, count: value});
                        seriesData.push({
                            name: option.name,
                            value,
                            itemStyle: option && option.itemStyle ? option.itemStyle : {
                                color: generateColor(Object.values(theme.statusColors), key)
                            }
                        });
                    }
                }
            }
        }
        return { seriesData, totalCount, selectedGroupOptions };
    })

    buildWidget = memoizeOne((widgetOptions, seriesData, totalCount) => {
        const widgetSettings = {...widgetOptions};
        widgetSettings.title.text = totalCount;
        widgetSettings.series[0].data = seriesData;
        return widgetSettings;
    })

    // update breadcrumbs and selectedGroup state base on widget option select
    onWidgetOptionSelect = (option) => {
        if (!option) {
            throw new Error(`${option} selection doesn't exists`);
        }
        const { widgetGroups } = this.props;
        const { breadcrumbs, selectedGroup } = this.state;
        const newBreadcrumbs = [
            ...breadcrumbs, {
                ...selectedGroup,
                selectedOption: option
            }
        ];
        const breadcrumbsGroupsNames = newBreadcrumbs.map(g => g.name);
        this.setState({
            breadcrumbs: newBreadcrumbs,
            selectedGroup: widgetGroups.filter(g => !breadcrumbsGroupsNames.includes(g.name))[0], // TODO: handle when filter removes all groups
        });
    }

    getPrevSelectedGroup = () => {
        const { breadcrumbs } = this.state;
        const breadcrumbsCopy = [...breadcrumbs];
        const selectedGroup = breadcrumbsCopy.length ? breadcrumbsCopy[breadcrumbsCopy.length - 1] : this.props.widgetGroups[0];
        breadcrumbsCopy.pop();
        this.setState({
            breadcrumbs: [...breadcrumbsCopy],
            selectedGroup
        });
    }

    // get available groups base on selected option filter
    getGroupsSequence = memoizeOne((groups, breadcrumbs) => {
        if (!isEmpty(breadcrumbs)) {
            const breadcrumbsCopy = [...breadcrumbs];
            let groupsCopy = [...groups];
            const firstBc = breadcrumbsCopy.shift();
            const nextBc = breadcrumbsCopy;
            groupsCopy = groupsCopy.filter(group => group.name !== firstBc.name);
            return this.getGroupsSequence(groupsCopy, nextBc);
        }
        return groups;
    })

    onWidgetGroupChange = memoizeOne((group) => {
        this.setState({ selectedGroup: group });
    })

    fetchTasksCountGroupBy = (queryParam, gqlQ) => {
        return graphql.query({
            query: gqlQ,
            variables: queryParam,
            fetchPolicy: 'no-cache',
        }).then((response) => {
            const tasks = get(response, 'data.result');
            return tasks || { message: 'Data not available' };
        });
    }

    fetchTasksFilterBy = (queryParam, gqlQ) => {
        return graphql.query({
            query: gqlQ,
            variables: queryParam,
            fetchPolicy: 'no-cache',
        }).then((response) => {
            const tasks = get(response, 'data');
            return tasks || { message: 'Data not available' };
        });
    }

    saveTaskListFilter = (filters) => {
        const orderBy = { field: 'taskStatus.lastUpdate', direction: 'desc nulls last' };
        this.props.saveFiltersPreferences('DashboardFilters',  { filters, orderBy: [orderBy] });
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { toggleReset, widgetIndex, widgetGroups, loadingSaveFilterPref } = this.props;
        const { breadcrumbs, selectedGroup, isLoading } = this.state;

        if (!isLoading) {
            if (prevProps.toggleReset !== toggleReset) {
                this.setState({
                    breadcrumbs: [],
                    selectedGroup: widgetGroups[0]
                });
            }

            if (prevState.breadcrumbs !== breadcrumbs || prevState.selectedGroup !== selectedGroup) {
                this.setState({ isLoading: true });
                this.loadWidgetData(this.state);
                this.props.onDashboardSettingsChange(this.state, widgetIndex);
            }
        }

        if(prevProps.loadingSaveFilterPref !== loadingSaveFilterPref) {
            if(loadingSaveFilterPref) {
                this.setState({ isLoading: true });
            } else {
                history.push('/dashboards_new/tasks');
            }
        }
    }

    render() {
        const { widgetGroups, widgetOptions } = this.props;
        const { breadcrumbs, selectedGroup, data, isLoading } = this.state; // should be set on componentDidUpdate or onOptionSelect
        if (isLoading) {
            return <Loader />;
        }
        const { seriesData, totalCount, selectedGroupOptions } = this.parseTasksData(data);
        const availableGroups = this.getGroupsSequence(widgetGroups, breadcrumbs);

        return (
            <Widget title={'Tasks'} >
                {<ReactEcharts
                    option={this.buildWidget(widgetOptions, seriesData, totalCount)}
                    theme={'dark'}
                />}
                {<DashboardBreadcrumbs data={breadcrumbs} />}
                {<DashboardTaskWidgetList
                    breadcrumbs={breadcrumbs}
                    selectedGroup={selectedGroup}
                    availableGroups={availableGroups}
                    redirectToAboxTask={this.saveTaskListFilter}
                    selectedGroupOptions={selectedGroupOptions}
                    onWidgetGroupChange={this.onWidgetGroupChange}
                    onWidgetOptionSelect={this.onWidgetOptionSelect}
                    getPrevSelectedGroup={this.getPrevSelectedGroup}
                />}
            </Widget>
        );
    }

}

const mapStateToProps: Function = (state) => {
    return {
        user: state.user.profile,
        preferences: state.user.preferences,
        loadingSaveFilterPref: state.user.loadingSaveFilterPref,
        widgetGroups,
        widgetOptions
    };
};

export default connect(mapStateToProps, { saveFiltersPreferences })(DashboardTaskWidget);
