/* @flow */

import { gql } from '@apollo/client';
import loadWorkspaceDetailsCommonQuery from 'graphql/workspaces/loadWorkspaceDetailsCommonQuery';

export default gql`
    mutation addWorkspaceTeamsMutation($id: ID!, $teamMembers:  [TeamMemberInput]!) {
        result: workspaceAddTeams(id: $id, teamMembers: $teamMembers) {
            ${loadWorkspaceDetailsCommonQuery}
        }
    }
`;
