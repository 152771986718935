// @flow

import React, { memo } from 'react';
import { muiTheme } from 'app/themes/materialUi';
import { ListItem, Button,Tooltip } from '@mic3/platform-ui';
import styled from 'styled-components';
import Icon from 'app/components/atoms/Icon/Icon';

type Props = {
    navOpen?: boolean,
    onAddClick: Function,
    closeMenu: Function
}

const IconButtonStyled = styled(Button)`
    width: 32px;
    height: 32px;
    border-radius: 50px !important;
    background: ${( { theme } ) => theme.material.colors.gradients.colorfull };
    & .mdi.mdi-plus {
      line-height: 20px !important;
    }
`;

const ListItemStyled = styled(ListItem)`
    margin: 8px 0px;
    padding: 12px !important;
`;

const AppNavHeader = ({
    hideSubMenu,
    onAddClick,
    navOpen,
    setActiveMenuItem,
    permissions,
    isAdmin,
    closeMenu,
    ...otherProps }: Props) => {
    const { className } = otherProps;

    return (
        <div className={className}>
            {!navOpen && 
                <Tooltip title="Create New">
                    <ListItemStyled data-tut='reactour__magicplus' {...otherProps} component="div">
                        <IconButtonStyled fullWidth onClick={onAddClick}><Icon name="plus" hexColor={muiTheme.colors.text.darkChip} />{navOpen && 'New'}</IconButtonStyled>
                    </ListItemStyled>
                </Tooltip>
            }
            {navOpen && 
                <ListItemStyled data-tut='reactour__magicplus' {...otherProps} component="div">
                    <IconButtonStyled fullWidth onClick={onAddClick}><Icon name="plus" hexColor={muiTheme.colors.text.darkChip} />{navOpen && 'New'}</IconButtonStyled>
                </ListItemStyled>
            }
        </div>
    );
};

export default memo<Props>(AppNavHeader);
