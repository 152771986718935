/* @flow */

import { gql } from '@apollo/client';

export default gql`
    query processesDefinitionsTypeaheadQuery {
        records: processDefinitions {
            id
            name
        }
    }
`;
