/* @flow */

import { gql } from '@apollo/client';

const entityTypeFields = `
    id
    name
    uri
    color
    icon
    hidden
    active
    primary
`;

export default gql`
    query primaryClassesLeftPanelQuery {
        records: entityTypes {
            ${entityTypeFields}
            parents {
                ${entityTypeFields}
            }
        }
    }
`;
