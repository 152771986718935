import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import uuidv1 from 'uuid/v1';
import { Button, Divider, CircularProgress, Typography, Tooltip, IconButton, ListItemText, ListItemIcon } from '@mic3/platform-ui';

import { createEntity } from 'store/actions/entities/entitiesActions';
import history from 'store/History';

import { bind } from 'app/utils/decorators/decoratorUtils';
import { buildTreeEntityObject, validateTreeTemplateForm } from 'app/utils/relatedEntity/relatedEntityUtils';
import { toUniqueUUID } from 'app/utils/string/string-utils';
import { treeType as treeTypeConfig } from 'app/config/treeTemplateConfig';

import PageNotAllowed from 'app/containers/ErrorPages/PageNotAllowed';
import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import { TypeaheadListItem } from 'app/components/organisms/Form/Typeahead/abstract/TypeaheadListItem';
import Icon from 'app/components/atoms/Icon/Icon';

const ButtonStyled = styled(Button)`
    margin-right: 12px !important;
`;

const DividerStyled = styled(Divider)`
    margin-top: 16px !important;
`;

const StyledIcon = styled(Icon)`
    position: relative;
    top: -8px;
`;

export const FormGeneratorStyled = styled(FormGenerator)`
    padding: 0 !important;

    & .dividerNoMargin .MuiDivider-root {
        margin-bottom: 0 !important;
    }

    & .behaviorText {
        .LabelBox {
            background: transparent !important;
        }
    }

    & .typeRadio {
        padding: 0 12px;

        & .MuiIconButton-root {
            margin-right: 8px;
        }
    }

    & .rootClass {
        .MuiInputBase-root {
            > div {
                margin-right: 4px !important;
            }
        }
    }
`;

const RequiredText = styled(Typography)`
    padding: 20px 0 6px;
    font-size: 12px !important;
    color: ${({ theme }) => theme.material.colors.text.label };
`;

export const treeOptionTemplate = (data) => {
    const { id, name, color, icon, iconType } = data;
    return ({
        option: (
            <TypeaheadListItem ContainerComponent="div" dense disableGutters>
                <ListItemIcon>
                    <Icon name={icon} type={iconType || 'mdi'} hexColor={color} />
                </ListItemIcon>
                <ListItemText primary={name || 'No Name'} secondary={`#${toUniqueUUID(id)}`} />
            </TypeaheadListItem>
        ),
        startAdornment: icon && <StyledIcon name={icon} type={iconType || 'mdi'} hexColor={color} />,
        label: `${name || 'Name not available'} (${id ? id.slice(0,8) : 'ID not available'})`,
    });
};

export const treeTemplateFieldDefintions = (data, isEdit, actionCallback) => {
    return [
        !isEdit && {
            field: 'name',
            type: 'text',
            properties: { label: 'Tree Template Name', name: 'name' },
            constraints: { required: true, minLength: 3, maxLength: 60 }
        },
        !isEdit && {
            type: 'divider',
            properties: {
                formFieldProps: {
                    className: 'dividerNoMargin'
                }
            }
        },
        isEdit && {
            type: 'button',
            properties: {
                label: 'Go to Related Entities',
                iconName: 'file-tree',
                onClick: actionCallback || null,
                className: 'relatedEntityBtn'
            }
        },
        {
            type: 'custom',
            properties: {
                name: 'text',
                formFieldProps: {
                    className: 'behaviorText'
                },
                Component: () => (
                    <Typography variant="caption" color="textSecondary">Behaviour in centre panel on selection of the entity in tree</Typography>
                )
            }
        },
        {
            field: 'treeType',
            type: 'radioGroup',
            properties: {
                name: 'treeType',
                formFieldProps: {
                    className: 'typeRadio'
                },
                options: [
                    { value: treeTypeConfig.OPEN_DETAILS, label: 'Open the entity details' },
                    { value: treeTypeConfig.OPEN_RELATED_ENTITIES, label: 'Open an index of related entities' }
                ]
            }
        },
        {
            type: 'divider',
            properties: {
                formFieldProps: {
                    className: 'dividerNoMargin'
                },
            }
        },
        {
            field: 'rootClass',
            type: 'classificationTypeahead',
            properties: {
                label: 'Root Entity Type',
                name: 'rootClass',
                formFieldProps: {
                    className: 'rootClass'
                },
                filterBy: [
                    { field: 'active', op: '=', value: true },
                    { field: 'primary', op: '=', value: true}
                ],
                InputProps: {
                    startAdornment: <Icon name="primary-class" type="af" hexColor="#C2C5CC" />,
                    endAdornment: !data.rootClass && (
                        <Tooltip title="The root node of the tree template" placement="top">
                            <IconButton><Icon name="help-circle-outline" hexColor="#fff9" /></IconButton>
                        </Tooltip>
                    )
                },
                extraQueryFields: 'icon iconType',
                optionTemplate: treeOptionTemplate,
                onChange: (event) => {
                    const value  = event.target.value;
                    if (!value) {
                        return [
                            { name: 'rootClass', value },
                        ];
                    }
                    return [
                        { name: 'rootClass', value },
                        { 
                            name: 'relationTree', 
                            value: { 
                                id: value.id, 
                                name: value.name, 
                                uri: value.uri,
                                relatedUri: data?.relatedEntityClass?.uri,
                                relatedId: data?.relatedEntityClass?.id,
                                children: [],
                                reverse: true,
                                nodeId: uuidv1()
                            } 
                        },
                    ];
                },
            },
            constraints: { required: true },
        },
        {
            field: 'relatedEntityClass',
            type: 'classificationTypeahead',
            properties: {
                label: 'Open related entities of entity type ',
                name: 'relatedEntityClass',
                filterBy: [
                    { field: 'active', op: '=', value: true },
                    { field: 'primary', op: '=', value: true },
                    { field: 'abstract', op: '=', value: false }
                ],
                isVisible: data => data.treeType === treeTypeConfig.OPEN_RELATED_ENTITIES,
                InputProps: { 
                    endAdornment: !data.relatedEntityClass && (
                        <Tooltip title="The related entities to be shown" placement="top">
                            <IconButton><Icon name="help-circle-outline" hexColor="#fff9" /></IconButton>
                        </Tooltip>
                    )
                },
                extraQueryFields: 'icon iconType',
                optionTemplate: treeOptionTemplate,
                onChange: (event) => {
                    const value  = event.target.value;
                    if (!value) {
                        return [
                            { name: 'relatedEntityClass', value },
                            { 
                                name: 'relationTree', 
                                value: {
                                    id: data?.rootClass?.id, 
                                    name: data?.rootClass?.name, 
                                    uri: data?.rootClass?.uri,
                                    relatedUri: value?.uri,
                                    relatedId: value?.id,
                                    children: [],
                                    reverse: true,
                                    nodeId: uuidv1()
                                } 
                            },
                        ];
                    }
                    return [
                        { name: 'relatedEntityClass', value },
                        { 
                            name: 'relationTree', 
                            value: { 
                                id: data?.rootClass?.id, 
                                name: data?.rootClass?.name, 
                                uri: data?.rootClass?.uri,
                                relatedUri: value?.uri,
                                relatedId: value?.id,
                                children: [],
                                reverse: true,
                                nodeId: uuidv1()
                            } 
                        },
                    ];
                },
            },
            constraints: { required: data.treeType === treeTypeConfig.OPEN_RELATED_ENTITIES }
        },
        {
            field: 'relationTree',
            type: 'relatedEntityTree',
            properties: {
                label: 'Entity Type Tree',
                name: 'relationTree',
                helperText: 'Click the plus button to add related entity type.',
                filterBy: [
                    { field: 'primary', op: '=', value: true }
                ],
                isVisible: data => data.treeType === treeTypeConfig.OPEN_RELATED_ENTITIES ? data.rootClass && data.relatedEntityClass : data.rootClass,
                actions: data.treeType === treeTypeConfig.OPEN_RELATED_ENTITIES ? ['delete', 'open-rel-entities'] : ['delete'],
                actionsRoot: data.treeType === treeTypeConfig.OPEN_RELATED_ENTITIES && ['open-rel-entities']
            }
        }
    ].filter(Boolean);
};

class AddTreeTemplate extends PureComponent {
    formRef: Object = React.createRef();

    static propTypes = {
        onClose: PropTypes.func.isRequired,
    };

    state = { 
        data: {
            treeType: treeTypeConfig.OPEN_DETAILS
        },
        formValid: false
    }

    @bind
    onFormSubmit(event: Event) {
        event.preventDefault();
        this.formRef.current.isValidForm().then(({ data, errors }) => {
            if (!errors) {
                const { relationTree, name, treeType } = data;

                const listByRoot = relationTree.listBy ? { listBy: relationTree.listBy } : {};
                
                const entity = {
                    name: name.trim(),
                    type: 'treetemplate',
                    primary: {
                        treeType,
                        relationTree: {
                            listEntityType: relationTree.relatedId,
                            root: {
                                id: relationTree.id,
                                children: buildTreeEntityObject(relationTree.children),
                                ...listByRoot
                            }
                        }
                    }
                };

                this.props
                    .createEntity(entity, false)
                    .then((result) => {
                        const { id, type } = result || {};
                        !(result instanceof Error) && result.id && history.push(`/entities/${type}/${id}`);
                    });
            }
        });
    }

    @bind
    handleClose() {
        this.props.onClose();
    }

    @bind
    handleChange(data) {
        const formValid = validateTreeTemplateForm(data);
        this.setState({ data, formValid });
    }

    render() {
        const { isLoading } = this.props;
        const { data, formValid } = this.state;
        const { permissions, isAdmin } = this.props.userProfile;
        const permissionsSet = new Set(permissions || []);
        const canAdd = isAdmin || permissionsSet.has('entity.entity.add');
        
        if (!canAdd) {
            return <PageNotAllowed title="New Tree Template" />;
        }

        return (
            <ModalDialog
                title="New Tree Template"
                withoutClose
                actions={
                    isLoading ? (
                        <CircularProgress size={24} color="primary" />
                    ) : (
                        <>
                            <ButtonStyled variant="text" onClick={this.handleClose}>
                                Cancel
                            </ButtonStyled>
                            <Button onClick={this.onFormSubmit} disabled={!formValid}>
                                Save
                            </Button>
                        </>
                    )
                }
                footer={
                    <RequiredText variant="body2">* All fields are required </RequiredText>
                }
            >
                <DividerStyled />
                <FormGeneratorStyled
                    onChange={this.handleChange}
                    components={treeTemplateFieldDefintions(data)}
                    ref={this.formRef}
                    data={data}
                />
            </ModalDialog>
        );
    }
}

export default connect(
    state => ({
        userProfile: state.user.profile,
        isLoading: state.entities.createEntity.isLoading
    }),
    { createEntity }
)(AddTreeTemplate);
