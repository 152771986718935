import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { loadTaskHistory } from 'store/actions/abox/taskActions';
import { bind } from 'app/utils/decorators/decoratorUtils';

import Changelog from 'app/components/organisms/Changelog/Changelog';

class TaskHistoryTab extends PureComponent<Object, Object> {
    static propTypes = {
        id: PropTypes.string,
        isLoading: PropTypes.bool,
        records: PropTypes.arrayOf(PropTypes.object),
        startIndex: PropTypes.number,
        totalRecords: PropTypes.number,
        type: PropTypes.string.isRequired
    }

    @bind
    loadData({ id, ...options }) {
        return this.props.loadTaskHistory(id, options);
    }

    render() {
        const { id, isLoading, records, startIndex, totalRecords, type } = this.props;
        return (
            <Changelog
                type={type}
                id={id}
                isLoading={isLoading}
                startIndex={startIndex}
                records={records}
                loadData={this.loadData}
                totalRecords={totalRecords}
            />
        );
    }
}

export default connect(
    state => ({
        records: state.abox.task.history.records,
        totalRecords: state.abox.task.history.count,
        isLoading: state.abox.task.history.isLoading,
        startIndex: state.abox.task.history.startIndex
    }),
    { loadTaskHistory }
)(TaskHistoryTab);
