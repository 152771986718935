/* @flow */

import { help } from 'app/utils/designer/form/settings/common/commonHelpers';
import { name, label } from 'app/utils/designer/form/settings/common/commonBase';
import { onLoad, onChange, isVisible, isDisabled } from 'app/utils/designer/form/settings/common/commonEvents';
import { flexGrow } from 'app/utils/designer/form/settings/common/commonStyles';

const panels = [
    {
        header: 'Base',
        // expanded: true,
        children: [
            name,
            label,
        ],
    },
    {
        header: 'Events',
        children: [
            onLoad,
            onChange,
            isVisible,
            isDisabled
        ]
    },
    {
        header: 'Styles',
        children: [ flexGrow ]
    },
    {
        header: 'Helpers',
        children: [ help ]
    },
];

export default {
    defaults: {},
    panels: settingValues => panels,
};
