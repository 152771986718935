import React, { useState, useEffect, useCallback } from 'react';
import { Typography } from '@mic3/platform-ui';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';

import Icon from 'app/components/atoms/Icon/Icon';
import { MdiIcon } from '@mic3/platform-ui';

import { CardContent, Divider } from '@material-ui/core';
import Button from 'app/components/atoms/Designer/Button';
import moment from 'moment';
import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import { loadApiKeys, deleteApiKeys, loadIdleTimeout } from 'store/actions/apiKeys/apiKeyActions';
import affectliSso from 'app/auth/affectliSso';
import { copyToClipboard } from 'app/utils/classification/classificationUtils';
import { setDocumentTitle, showToastr } from 'store/actions/app/appActions';
import { modulesAndPageTitles } from 'app/config/typesConfig';

const CardContentWrapper = styled(CardContent)`
    width: calc(100% - 36rem);
    @media (max-width: 961px) {
        width: 100%;
    }
    div {
        background-color: #1c263b;
        padding: 16px 24px 35px 24px;
    }
`;

const CardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: scroll;
`;

const SubHeadingSection = styled.div`
    font-size: 20px !important;
    font-weight: 500 !important;
    padding: 7px 14px 8px 14px;
    color: white;
    display: inline-block;
    width: 100%;
    p {
        margin: 0;
        padding: 2px 8px 0px 8px
    }
`;

const StyledDivider = styled(Divider)`
    margin: 10px 10px 10px 0 !important;
`;

const TitleText = styled(Typography)`
    color: white;
    font-size: 24px !important;
    span {
        padding-left: 8px;
        color: #FFFFFF61
    }
`;

const GenerateKeyButton = styled(Button)`
    background-color:#1248BD !important;
    margin-top: 24px !important; 
    padding-left: 10px !important;
`;

const RevokeButton = styled(Button)`
    height: 42px;
    margin: 4vh 0 4vh 0 !important;
`;

const GenerateKeyText = styled(Typography)`
    overflow-wrap: break-word;
    margin: 2vh 0vh !important;
    color: white 
`;

const SubContentTypography = styled(Typography)`
    color: #FFFFFFB2;
`;

const ContentTypography = styled(Typography)`
    color: white;
`;

const StyledIcon = styled(Icon)`
    &:before {
    padding-top: 3px;
    padding-left: 22px;
    font-size: 20px !important;
    color: #ffffff99;
    }
`;

const TooltipStyled = styled.span`
    cursor: pointer;
`;

const Apikey = () => {
    const dispatch = useDispatch();
    const [openGenerated, setOpenGenerated] = useState(false);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const offlineSessions = useSelector(state => state.apiKey.apiKeyList.data || []);
    const idleTimeout = useSelector(state => state.apiKey.idleTimeout.data || []);

    const refreshTokens = affectliSso.getRefreshToken();

    useEffect(() => {
        dispatch(setDocumentTitle(modulesAndPageTitles.apiKey));
        dispatch(loadApiKeys());
        dispatch(loadIdleTimeout()); 
    }, [dispatch]);

    useEffect(() => {
        if (localStorage.getItem('offlineMode') === 'true') {
            setOpenGenerated(true);
        }
    }, [refreshTokens]);

    const dateFormate = (date) => {
        const currentDay = moment();
        const difference = currentDay.diff(date, 'days');
        if (difference === 0) return 'today';
        else if (difference === 1) return 'yesterday';
        else return moment(date, 'YYYYMMDD').fromNow();
    };

    const expiryDate = (date, idleTimeout) => {
        const currentDay = moment();
        const difference = currentDay.diff(date, 'days');
        const expiry = (((idleTimeout.offlineSessionIdleTimeout)/60)/60)/24 - difference;
        return expiry;
    };

    const apiKeyList = useCallback((idleTimeout) => offlineSessions?.map(({ id, start, lastAccess }, index) => {
        return (
            <>
                <ContentTypography variant='h6'>
                    {moment(start).format('h:mm:ss,\xa0D MMM YYYY')}
                </ContentTypography>
                <Typography variant='body2'>
                    Created {dateFormate(start)} &middot; Last used {dateFormate(lastAccess)} &middot; Expires {expiryDate(lastAccess, idleTimeout)}
                    <TooltipStyled arrow placement='left'
                        title={`The expiry date will be reset every time the API key is used.\n If not used the key will expire in ${expiryDate(lastAccess, idleTimeout)} days.`}
                    >
                        <StyledIcon name="help-circle-outline" hexColor="#ffffff99" />
                    </TooltipStyled>
                </Typography>
                {offlineSessions?.length - index > 1 && <StyledDivider />}
            </>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [offlineSessions]);

    const handleRevokeOnClick = useCallback(() => dispatch(deleteApiKeys()), [dispatch]);

    const handleCopyToClipboard = useCallback(() => {
        navigator.clipboard.writeText(refreshTokens);
        copyToClipboard(refreshTokens)
            .then(() => {
                dispatch(showToastr({ severity: 'success', detail: 'Key copied to clipboard' }));
            })
            .catch(() => {
                dispatch(showToastr({ severity: 'error', detail: 'Key could not copied to clipboard' }));
            });
    },
    [refreshTokens, dispatch],
    );

    const handleModalOnClose = useCallback(() => {
        setOpenGenerated(false);
        localStorage.setItem('offlineMode', 'false');
        setOpenConfirmation(true);
    }, [setOpenGenerated]);

    const handleConfirmation = useCallback(() => {
        setOpenConfirmation(false);
    }, [setOpenConfirmation]);

    return (
        <>
            <ModalDialog
                open={openGenerated}
                title={'Save your key'}
                bgcolor='#28334B'
                onClose={handleModalOnClose}
                footerButton={
                    <Button
                        startIcon={<MdiIcon name="content-copy" />}
                        variant="text"
                        onClick={handleCopyToClipboard}>
                        COPY TO CLIPBOARD
                    </Button>
                }
            >
                <SubContentTypography>
                    Save your key because you won’t see it again.
                </SubContentTypography>
                <SubContentTypography>
                    Your new key:
                </SubContentTypography>
                <GenerateKeyText>
                    {refreshTokens}
                </GenerateKeyText>
            </ModalDialog>
            <ModalDialog
                open={openConfirmation}
                withoutClose={true}
                bgcolor='#28334B'
                footerButton={
                    <Button
                        variant="text"
                        onClick={handleConfirmation}>
                        I UNDERSTAND
                    </Button>
                }
            >
                <SubContentTypography>
                The expiry date will be reset every time the API key is used.
                </SubContentTypography>
                <SubContentTypography> If not used the key will expire in 50 days. </SubContentTypography>
            </ModalDialog>
            <CardWrapper>
                <SubHeadingSection>
                    <p> API keys </p>
                    <StyledDivider />
                </SubHeadingSection>
                <CardContentWrapper>
                    <div>
                        <TitleText>
                            Generate Key
                        </TitleText>
                        <Typography variant='body2'>
                            You can generate API keys to access the Affectli API
                        </Typography>
                        <GenerateKeyButton onClick={() => affectliSso.generateKey()}>+ GENERATE KEY</GenerateKeyButton>
                    </div>
                </CardContentWrapper>
                <CardContentWrapper>
                    <div>
                        <TitleText>
                            Active API Keys
                            <span>
                                ({offlineSessions?.length})
                            </span>
                        </TitleText>
                        {offlineSessions?.length > 0 &&
                            <RevokeButton
                                style={{ backgroundColor: 'red' }}
                                variant="contained"
                                startIcon={<Icon name="delete" />}
                                onClick={handleRevokeOnClick}
                                disabled={!offlineSessions?.length >= 1}
                            >
                                REVOKE ALL KEYS
                            </RevokeButton>
                        }
                        {apiKeyList(idleTimeout)}
                    </div>
                </CardContentWrapper>
            </CardWrapper>
        </>
    );
};

export default Apikey;