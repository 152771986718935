/* @flow */

import { gql } from '@apollo/client';

export const eventToEventRelationCommonFields = `
event {
    id
    time
    eventType {
        id
        name
    }
}
relatedEvent {
    id
    time
    eventType {
        id
        name
    }
}
isReverseRelation
relationDefinition {
    id
    type
    relatedType
    description
    relatedDescription
    classes {
        id
        name
        uri
    }
    isSystem
    relationType
    reportingEnabled
}
createdBy {
    id
    name
    image
}
createdDate
`;

export default gql`
    query eventToEventRelationsQuery(
            $eventId: ID!
            $eventTime: Date!
            $startIndex: Int
            $stopIndex: Int
            $filterBy: [JSON]
            $excludeBy: [JSON]
            $orderBy: [JSON]
        ) {
        relations: eventRelations(
            eventId: $eventId
            eventTime: $eventTime
            startIndex: $startIndex
            stopIndex: $stopIndex
            filterBy: $filterBy
            excludeBy: $excludeBy
            orderBy: $orderBy
            ) {
                ${eventToEventRelationCommonFields}
            }
        }
`;
