// @flow

import { bind } from 'app/utils/decorators/decoratorUtils';
import Feature from 'ol/Feature';
import { LineString, Point } from 'ol/geom';

import AffectliVectorLayer from './AffectliVectorLayer';
import EntityLayer from './EntityLayer';

import { loadEntitiesRelationsPins } from 'store/actions/maps/situationalAwarenessActions';
import VectorLayer from 'ol/layer/Vector';
import { Vector as VectorSource } from 'ol/source';
import { fromLonLat } from 'ol/proj';
import { isDefined } from 'app/utils/utils';
import { singleFeatureStyle } from 'app/components/molecules/Map/EntityPin/entityPinStyle';
import { fetchMapRelations } from 'store/actions/entities/relationshipsActions';

class RelatedEntityLayer extends AffectliVectorLayer {
    parentLayer: EntityLayer;
    relatedEntities: String;
    dataSource: any;
    layer: any;
    lineFeatures = [];

    constructor(layer, relatedEntities, info, olMap) {
        super();
        this.parentLayer = layer;
        this.info = info;
        this.layerId = info.id;
        this.olMap = olMap;
        this.relatedEntities = relatedEntities;
        this.createRelatedLayer();
        this.parentLayer.addRelatedEntities(this);
    }

    @bind
    createRelatedLayer() {
        this.layer = new VectorLayer({
            title: 'related entities layer',
            visible: true,
            attributes: {
                id: this.layerId,
                type: 'related',
                subtitle: 'entities',
                layerType: 'entity-layer',
                iconName: 'things',
                iconType: 'af',
                noLocationPins: []
            },
            source: this.getRelatedEntitySource(),
            style: this.styleFunction
        });
        const isLayer = this.olMap.findLayer('allLayers', 'related entities layer') || this.olMap.findLayerByType(this.info.id, 'related');
        if(isLayer) {
            this.olMap?.getMap()?.removeLayer(isLayer);
        }
        if (!isLayer) {
            this.olMap.getMap()?.getLayers()?.insertAt(1, this.layer);
            // this.olMap.getMap().addLayer(this.layer);
            this.parentLayer.getLayer().setZIndex(1);
        }
    }

    @bind
    styleFunction(feature, resolution) {
        return singleFeatureStyle(feature, this.olMap.getMap());
    }


    @bind
    getLayer() {
        return this.layer;
    }

    @bind
    async loader(extent, resolution, projection) {
        try {
            this.lineFeatures.length = 0;
            this.features.length = 0;
            await this.getRelatedFeatures();
            this.dataSource.addFeatures(this.features);
            this.dataSource.addFeatures(this.lineFeatures);
            // this.olMap && this.olMap.getMap().render();
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error('bad features in the data',e);
        }

    }

    @bind
    getRelatedEntitySource() {
        if (!this.dataSource) {
            this.dataSource = new VectorSource({
                loader: this.loader,
            });
        }
        return this.dataSource;
    }

    @bind
    async getRelatedFeatures() {
        const { related_entities } = this.info?.primary || {};
        const relatedEntitiesObj = related_entities || {};
        const relatedTypeAheadId = relatedEntitiesObj?.related_entities_th?.id;
        if(!relatedTypeAheadId) return;
        const entitiesRelations = await fetchMapRelations({
            'entityType': this.info.primary.entity_type.uri,
            'filterBy': {
                'field': 'relation.relationDefinition.id',
                'op': '=',
                'value': relatedTypeAheadId
            }
        });
        const relations = entitiesRelations?.data?.relations;
        const relationArray = [];
        for (const relation of relations) {
            relationArray.push(relation?.relatedEntity?.id);
        }
        const { related_entities_th, subType, relatedEntities } =  related_entities || {};
        const { relatedType } = related_entities_th || {};
        if(relationArray.length) {
            const variables = [
                { field: 'id', op: 'in', 'value': relationArray },
                { field: 'geom', op: 'is not null' }
            ];
            const relatedLayerType =  relatedType;
            const entityRelationLocation = relatedType && await loadEntitiesRelationsPins({'type': relatedLayerType,'filterBy': variables});
            entityRelationLocation && relationArray.push(entityRelationLocation);
            let entities = entityRelationLocation?.data?.entities;
            if(subType)
                entities = entities?.filter(entity => entity.type === subType);
            entities.length > 0 && entities.forEach((relatedEntity) => {
                const filterRelations =  relations.filter(relation => relation.relatedEntity.id === relatedEntity.id);
                let feature = null;
                filterRelations.forEach(async (filter) => {
                    const parentEntity = filter.entity;
                    const lineAttributes = filter?.relation?.attributes || {};
                    const parentEntityFeature = this.parentLayer.getFeatureById(parentEntity.id);
                    const locationInfo = relatedEntity && relatedEntity.primary.locationInfo;
                    const { name } = relatedEntity || '';
                    const { related_entities } = this.info?.primary;
                    const { iconName, valLineStyle, valLineColour, valLineWidth, valFillColour, valPointSize } = related_entities || {};
                    const { latitude, longitude } = locationInfo || {};
                    const relatedEntityGeometry = new Point(fromLonLat([longitude, latitude]));
                    feature = new Feature({
                        geometry: relatedEntityGeometry,
                        properties: {
                            id: relatedEntity.id
                        }
                    });
                    const parentCoords = parentEntityFeature && parentEntityFeature.getGeometry().getCoordinates();
                    const relatedCoords = relatedEntityGeometry.getCoordinates();
                    const relatedAttributes = related_entities || {};

                    if(parentCoords && relatedCoords){
                        const nameArr = [ 'valPointSize','valFillColour' ];

                        const data = nameArr.reduce((data, key) => {
                            const attName = relatedAttributes[key];
                            data[key] = relatedEntity?.primary[attName];
                            return data;
                        }, {});
                        const coordinates = [parentCoords, relatedCoords];
                        const connectedLine = new LineString(coordinates);
                        const lineFeature = new Feature({
                            geometry: connectedLine,
                            properties: {
                                parentId: parentEntity.id,
                                relatedId: relatedEntity.id,
                                lineWidth: lineAttributes[valLineWidth] || valLineWidth || 1,
                                lineColor: lineAttributes[valLineColour] || valLineColour || 'blue',
                                lineDashed: valLineStyle !== 'solid'
                            }
                        });
                        const rgbColorRegex = /^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/;
                        const isValidRgbColor = rgbColorRegex.test(valFillColour);
                        const pointSize =  isDefined(data['valPointSize'])  ? data['valPointSize'] : (valPointSize ? valPointSize: 32);
                        const pointColor = isDefined(data['valFillColour']) ? data['valFillColour'] : (isValidRgbColor ?  valFillColour : 'blue');
                        feature.set('attributes', {
                            name: name,
                            iconName: iconName,
                            iconColor: pointColor || 'blue',
                            size: pointSize || 32,
                            entityType: relatedType,
                            id: relatedEntity?.id,
                            related: true,
                            entityType: relatedEntity?.type
                        });
                        if(relatedEntities && lineFeature && lineFeature.getGeometry() && feature && feature.getGeometry()){
                            this.lineFeatures.push(lineFeature);
                            this.addFeature(feature);
                        }

                    } else
                        // eslint-disable-next-line no-console
                        console.log('parents coords does not exist',parentEntityFeature);

                });
            });
        }
        this.olMap.getMap().render();
    }

    @bind
    clearRelatedSource(info: Object) {
        this.updateInfo(info);
        this.isFeatureLoaded = false;
        this.lineFeatures.length = 0;
        this.features.length = 0;
        this.layer.getSource().clear();
    }

    @bind
    updateInfo(newPrimary: Object){
        const { primary,type, ...rest} = this.info;
        this.info = {
            ...newPrimary,
            type: type,
            ...rest
        };
        this.setFeatureStatus(false);
    }
}

export default RelatedEntityLayer;
