/* @flow */
/* eslint-disable no-console */
// import Keycloak from 'keycloak-js';

import { unregisterServiceWorker, unsubscribeNotifications } from 'app/utils/firebaseUtils';
import { offlineDataLoadKey } from 'offlineMode/singleTabDataLoader';

const pathArray = window.location.href.split('/');
const redirectUri = `${pathArray[0]}//${pathArray[2]}`;
console.log('SSO redirectUri', redirectUri);

let isInvalidSession = false;
let sso;

const loadConfig = () => {
    sso = new window.Keycloak('/config/keycloak.json');
};

const getIsInvalidSession = () => isInvalidSession;

const storeTokens = () => {
    // console.log('(sso) store token', new Date(sso.tokenParsed.exp * 1000));
    window.document.cookie = `token=${sso.token}; path=/`;
    window.document.cookie = `refreshToken=${sso.refreshToken}; path=/`;    
};

const clearTokens = () => {
    console.log('(sso) clear tokens');
    localStorage.removeItem(offlineDataLoadKey);
    localStorage.removeItem('offlineMode');
    localStorage.removeItem('offlineEnabled');
    localStorage.removeItem('unAttendedMode');
    window.document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
    window.document.cookie = `refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
};

const getCookieTokens = () => {
    const token = document.cookie.replace(/(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/, '$1');
    const refreshToken = document.cookie.replace(/(?:(?:^|.*;\s*)refreshToken\s*=\s*([^;]*).*$)|^.*$/, '$1');
    return { token, refreshToken };
};

const getTokens = () => {
    if (sso) {
        const token = sso.token;
        const refreshToken = sso.refreshToken;
        return { token, refreshToken };    
    }
    return getCookieTokens();
};

const redirectToLogin = async () => {
    console.log('(sso) forcing redirect to login screen');
    try {
        const r = await fetch('/config/keycloak.json');
        const config = await r.json();
        const url = `${config['auth-server-url']}/realms/${config['realm']}/protocol/openid-connect/auth?client_id=${config['resource']}&redirect_uri=${redirectUri}&response_mode=fragment&response_type=code`;
        window.location.href = url;
    } catch (error) {
        console.log('(sso) redirect to login failed', error);
    }
};

const autoRefreshToken = () => {
    const refreshTimeout = Math.max(10000, ((sso.tokenParsed.exp * 1000) - Date.now()) - 10000);

    setTimeout(() => {
        sso.updateToken(20)
            .then(() => {
                console.log('(sso) token expires', new Date(sso.tokenParsed.exp * 1000).toISOString());
                storeTokens();
                autoRefreshToken();
            })
            .catch(e => console.log('(sso) refresh error', e));
    }, refreshTimeout);
};

const init = () =>
    new Promise((resolve, reject) => {
        console.log('(sso) init...');
        const options = { checkLoginIframe: false };
        const tokens = getCookieTokens();
        if (tokens.token && tokens.refreshToken) {
            console.log('(sso) set "cookie session"');
            options.token = tokens.token;
            options.refreshToken = tokens.refreshToken;
        }
        sso
            .init(options)
            .then((authenticated) => {
                console.log('(sso) init success', authenticated);
                if (!authenticated) {
                    buildSupersetLogoutFrame();
                    sso
                        .login({ scope: 'openid' })
                        .then(() => {
                            // console.log('SSO init login success ');
                            // resolve();
                        })
                        .catch((error) => {
                            console.log('(sso) init login error.', error);
                            reject(new Error('SSO init login error.'));
                        });
                } else {
                    storeTokens();
                    resolve();
                    autoRefreshToken();
                }
            })
            .catch(async (error) => {
                console.log('(sso) init error', error);
                isInvalidSession = true;
                await logout();
                reject(new Error('SSO init error.'));
            });
        window.DO_NOT_COMMIT_THIS_LINE = sso;
    });


const generateKey = (mode = 'offlineMode') =>
    new Promise((resolve, reject) => {
        const { refreshToken: refresh_token } = getCookieTokens();
        const options = { scope: 'offline_access', redirect_uri: window.location.href };
        if (mode === 'offlineMode') {
            options.refresh_token = refresh_token;
            options.grant_type = 'refresh_token';
        }
        sso
            .login(options)
            .then(() => {
                resolve();
            })
            .catch((error) => {
                console.log('(sso) generate key error.', error);
                reject(new Error('SSO generate refresh key error.'));
            });
        if (mode === 'offlineMode') {
            localStorage.setItem('offlineMode', 'true');
            localStorage.setItem('unAttendedMode', 'false');
        } else if (mode === 'unAttendedMode') {
            localStorage.setItem('offlineMode', 'false');
            localStorage.setItem('unAttendedMode', 'true');
        }
    });

const logout = (options = { redirectUri: redirectUri }) =>
    new Promise(async (resolve, reject) => {
        isInvalidSession = true;
        console.log('(sso) logout...');
        clearTokens(); // if we remove this line user gets stuck in an infinite loop if logged out
        buildSupersetLogoutFrame();
        
        sso
            .logout(options)
            .then(() => {
                console.log('(sso) logout success');
                clearTokens();
                resolve();
            })
            .catch((error) => {
                console.log('(sso) logout error', error);
                redirectToLogin();
                reject(new Error('SSO logout error.'));
            });
    });

const updateToken = () =>
    new Promise((resolve, reject) => {        
        if (isInvalidSession) {
            console.log('(sso) updateToken: the session is invalid');
            logout().finally(() => reject(new Error('(sso) update token error.')));
            return;
        }
        sso
            .updateToken()
            .then(updated => {
                if (updated) {
                    console.log('(sso) token updated', new Date(sso.tokenParsed.exp * 1000).toISOString());
                    storeTokens();
                }
                resolve(sso.token);
            })
            .catch(error => {
                console.log('(sso) updateToken error', error);
                isInvalidSession = true;
                logout().finally(() => reject(new Error('SSO update token error.')));
            });
    });

const initialized = () =>
    new Promise(async (resolve, reject) => {
        try {
            const authenticated = await init();
            if (authenticated) {
                console.log('(sso) authenticated', sso.token);
                storeTokens();
            }
            resolve();
        } catch (error) {
            console.log('(sso) failed to initialize', error);
            reject(new Error('SSO initialization failed.'));
        }
    });

const buildSupersetLogoutFrame = async () => {
    const existingIframe = document.getElementById('superset-iframe');

    if (existingIframe) {
        existingIframe.parentNode.removeChild(existingIframe);
    }

    const iframe = document.createElement('iframe');
    iframe.id = 'superset-iframe';
    iframe.src = `https://superanalytics.${window.location.host}/logout/`;
    iframe.width = '1';
    iframe.height = '1';
    iframe.frameBorder = '0';

    document.body.appendChild(iframe);

    const iframeLoadPromise = () => (
        new Promise(resolve => iframe.addEventListener('load', () => {
            iframe.parentNode.removeChild(iframe);
            resolve();
        }))
    );
    
    await iframeLoadPromise();
};

export default {
    /*
     * synch
     */

    loadConfig,

    getTokenSub: () => sso?.tokenParsed?.sub,

    getUserLogin: () => sso?.tokenParsed?.preferred_username,

    getToken: () => sso.token,
    
    getTokens,

    getRefreshToken: () => sso.refreshToken,

    getBearerToken: () => sso.token && `Bearer ${sso.token}`,

    getRefreshTokenParsed: () => sso.refreshTokenParsed,

    /*
     * async
     */

    initialized,

    logout: async () => {
        await unsubscribeNotifications();
        unregisterServiceWorker();
        return logout();
    },

    generateKey,

    updateToken,

    getIsInvalidSession
};
