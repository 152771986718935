import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { Avatar, Typography, Tooltip } from '@mic3/platform-ui';
import { Grid, IconButton,  MdiIcon } from '@mic3/platform-ui';

const AppWrapperStyled = styled(Grid)`
    max-width: ${({ isMobile }) => !isMobile ? '171px !important' : '80px !important'};
    align-items: center;
    cursor: pointer;
    margin: 0 8px !important;
    flex-grow: 1 !important;
    z-index: 100;
`;
const AvatarStyled = styled(Avatar)`
    border-radius: 8px !important;
    position: relative !important;
    overflow: visible !important;
    padding: 1rem !important;
    width: ${({isMobile})=> isMobile ? '48px' : '80px'} !important;
    height: ${({isMobile})=> isMobile ? '48px' : '80px'} !important;
`;
const AppName = styled(Typography)`
    width: 100%;
    height: 34px;
    max-width: ${({ isMobile }) => !isMobile ? '160px !important' : '65px !important'};
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
    font-size: 12px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-top: 5px !important;
    line-height: 16px !important;
    word-break: break-word !important;
`;

const MdiIconStyled = styled(MdiIcon)`
    line-height: 1.5rem;
    color: white;
`;

const DeleteButtonStyled = styled(IconButton)`
    position: absolute !important;
    top: -11px;
    right: -10px;
`;

const MyAppIcon = ({ iconType, iconColor, iconName, isRoot = true, onClick, index, appName, onDelete }) => {
    const isMobile = useSelector(
        state => state.global.isMobile,
    );
    return (
        <Tooltip title={appName} placement="top">
            <AppWrapperStyled isMobile={isMobile?1:0} onClick={onClick} item container direction="column" justify="center" sm={1} xs={4}>

                <AvatarStyled isMobile={isMobile?1:0} variant="rounded" style={{ background: iconColor }}>
                    <MdiIconStyled type={iconType || 'mdi'} name={iconName || 'asterisk'} size={isMobile ? 20 : 30}/>
                    {!isRoot ? (
                        <DeleteButtonStyled aria-label="Delete" size="small" onClick={onDelete}>
                            <MdiIcon data-index={index} name="close-circle" size={15} />
                        </DeleteButtonStyled>
                    ) : null}
                </AvatarStyled>
                <AppName isMobile={isMobile?1:0} variant="caption">{appName}</AppName>
            </AppWrapperStyled>
        </Tooltip>
    );
};

export default MyAppIcon;
