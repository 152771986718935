import Immutable from 'app/utils/immutable/Immutable';

import { 
    COUNT_TREE_ENTITIES, COUNT_TREE_ENTITIES_STARTED, 
    RESET_TREE, 
    SET_SIDEBAR_TREE_TEMPLATE, 
    SET_SIDEBAR_TREE_TEMPLATE_EXPAND, 
    SET_TREE_ENTITY, 
    SET_TREE_TEMPLATE
} from 'store/actions/entities/relatedEntitiesActions';

const reducer = (
    state = Immutable({
        selectedTreeEntity: null,
        treeTemplate: null,
        sidebarTreeTemplate: null,
        sidebarTreeTemplateExpanded: [],
        isLoading: false,
        count: 0
    }),
    action
) => {
    const { type, payload, error } = action;
    
    switch (type) {
        case SET_TREE_ENTITY:
            return Immutable({ ...state, selectedTreeEntity: payload });
        case SET_TREE_TEMPLATE:
            return Immutable({ ...state, treeTemplate: payload });
        case SET_SIDEBAR_TREE_TEMPLATE:
            return Immutable({ ...state, sidebarTreeTemplate: payload });
        case SET_SIDEBAR_TREE_TEMPLATE_EXPAND:
            return Immutable({ ...state, sidebarTreeTemplateExpanded: payload });
        case COUNT_TREE_ENTITIES_STARTED:
            return Immutable({ ...state, isLoading: true });
        case COUNT_TREE_ENTITIES: {
            if (error) {
                return Immutable({ ...state, count: 0, isLoading: false });
            }
            return Immutable({ ...state, count: payload?.count || 0, isLoading: false });
        }
        case RESET_TREE:
            return Immutable({ 
                ...state,
                outdatedTree : payload
            });
        default:
            return state;
    }
};

export default reducer;