// @flow

import { combineReducers } from 'redux';

import { dataTableReducer, loadDataReducer, keepDataAtStart, compareId } from 'app/utils/redux/reducer-utils';
import { LOAD_PROCESSES_STARTED, LOAD_PROCESSES } from 'store/actions/abox/processActions';

import {
    LOAD_ABOX_PROCESS_DEFINITION_STARTED, LOAD_ABOX_PROCESS_DEFINITION
} from 'store/actions/abox/myAppsActions';

import expanded from './expanded/aboxExpandedProcessReducer';
import process from './process/processReducer';
import app from './app/appReducer';
import task from './task/taskReducer';
import kanban from './boards/Old_boardsReducer';
import boards from './boards/boardsReducer';
import backgroundJob from './backgroundJob/backgroundJobReducer';

const aboxReducer = combineReducers<Object, Object>({
    app,
    list: dataTableReducer(LOAD_PROCESSES_STARTED, LOAD_PROCESSES, keepDataAtStart),
    processDefinition: loadDataReducer(LOAD_ABOX_PROCESS_DEFINITION_STARTED, LOAD_ABOX_PROCESS_DEFINITION, compareId),
    expanded,
    process,
    task,
    kanban,
    boards,
    backgroundJob
});

export default aboxReducer;
