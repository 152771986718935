import { gql } from '@apollo/client';

export const BACKGROUND_JOB_ASSIGNED_SUBSCRIPTION = gql`
    subscription onBackgroundJobLogSubscription($id: ID!) {
     onBackgroundJobLog(id: $id) {
        level
        time
        msg
      }
    }
`;