/* @flow */

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { loadEntityHistory } from 'store/actions/entities/entitiesActions';
import { get } from 'app/utils/lo/lo';

import Changelog from 'app/components/organisms/Changelog/Changelog';

class EntityTimeline extends PureComponent<Object, Object> {
    static propTypes = {
        entityId: PropTypes.string,
        entityType: PropTypes.string,
        isLoading: PropTypes.bool,
        changelog: PropTypes.arrayOf(PropTypes.object),
    };

    loadData = (options: Object) => {
        return this.props.loadEntityHistory(this.props.entityId, options);
    }

    /**
     * @override
     */
    render() {
        const { isLoading, startIndex, count, entityType, entityId, records } = this.props;
        return (
            <Changelog
                id={entityId}
                type={entityType}
                isLoading={isLoading}
                startIndex={startIndex}
                records={records}
                totalRecords={count}
                loadData={this.loadData}
                isMainContent
            />
        );
    }
}

export default connect(
    state => ({
        isLoading: get(state, 'entities.common.history.isLoading') || false,
        records: get(state, 'entities.common.history.records'),
        startIndex: get(state, 'entities.common.history.startIndex') || 0,
        count: get(state, 'entities.common.history.count')
    }),
    { loadEntityHistory }
)(EntityTimeline);
