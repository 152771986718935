/* @flow */

import { gql } from '@apollo/client';

export default gql`
    query integrationsQuery($startIndex: Int, $stopIndex: Int, $type: String!, $filterBy: [JSON], $orderBy: [JSON], $excludeBy: [JSON]) {
        records: entities(type: $type, startIndex: $startIndex, stopIndex: $stopIndex, filterBy: $filterBy, orderBy: $orderBy, excludeBy: $excludeBy) {
        id
        name
        type
        image
        createdDate
        iconName
        iconColor
        iconType
        primary
    }
  }
`;
