/* @flow */

import uuidv1 from 'uuid/v1';

import { id, name, documentationPanel } from 'app/utils/designer/process/settings/common/commonImportant';
import { executionListener } from 'app/utils/designer/process/settings/common/commonListeners';
import TaskListenersBuilder from 'app/components/Designer/Builders/ExecutionListenersBuilder/TaskListenersBuilder';
import Assignments from 'app/components/Designer/Builders/Assignments';
import commonMultiinstance from 'app/utils/designer/process/settings/common/commonMultiinstance';

const panels = [
    {
        header: 'Important',
        expanded: true,
        children: [
            id,
            name,
            {
                type: 'custom',
                properties: {
                    title: 'Assignment',
                    cid: uuidv1(),
                    name: 'process.primary.definition',
                    Component: Assignments
                }
            },
            {
                type: 'typeahead',
                properties: {
                    label: 'Asynch/synch',
                    options: [{ label: 'Asynchronous', value: 'true'}, { label: 'Async/sync', value: 'false' }],
                    name: 'activiti:async',
                    defaultValue: 'false',
                    clearable: false,
                }
            },
        ],
    },
    documentationPanel,    
    {
        header: 'General',
        expanded: false,
        children: [
            {
                type: 'text',
                properties: {
                    label: 'Skip Expression',
                    name: 'flowable:skipExpression'
                }
            },
            {
                type: 'text',
                properties: {
                    label: 'Category',
                    name: 'flowable:category'
                }
            },
            {
                type: 'text',
                properties: {
                    label: 'ID variable',
                    name: 'flowable:taskIdVariableName'
                }
            },        ]
    },
    {
        header: 'Listeners',
        children: [
            executionListener,
            {
                type: 'custom',
                properties: {
                    cid: uuidv1(),
                    title: 'Task Listeners',
                    name: 'process.primary.definition',
                    Component: TaskListenersBuilder,
                },
            },
        ]
    },
    commonMultiinstance,
];

export default {
    defaults: {},
    panels: settingValues => panels,
};
