/* @flow */

import Immutable from 'app/utils/immutable/Immutable';

import {
    BUILD_FILTERBAR,
    OPEN_FILTERBAR,
    CLOSE_FILTERBAR,
    SAVE_FILTER_OPERATORS,
    RESET_FILTER_OPERATORS
} from 'store/actions/filterbar/filterbarActions';

const initialState = {
    isOpen: false,
    id: null,
    operatorsMap: {},
    expanded: false,
    title: '',
    subTitle: '',
    content: null,
    actions: null,
};

export default (state: Object = initialState, action: Function) => {
    const { type, payload } = action;
    switch (type) {
        case BUILD_FILTERBAR:
            return Immutable({ ...initialState, ...state, ...payload });
        case OPEN_FILTERBAR:
            return Immutable({ ...state, isOpen: true });
        case SAVE_FILTER_OPERATORS:
            if (!payload) {
                return Immutable({ ...state, operatorsMap: {} });
            }
            return Immutable({ ...state, operatorsMap: { ...state.operatorsMap, ...payload} });
        case RESET_FILTER_OPERATORS:
            return Immutable({ ...state, operatorsMap: {} });
        case CLOSE_FILTERBAR:
            return Immutable({ ...state, isOpen: false, expanded: false, operatorsMap: {}, id: null });
        default:
            return state;
    }
};
