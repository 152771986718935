/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, CircularProgress } from '@mic3/platform-ui';

import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import { bind } from 'app/utils/decorators/decoratorUtils';
import history from 'store/History';
import { typeMap, typeTitlesSingle } from 'app/config/typesConfig';
import { baseRoutes } from 'app/config/routesConfig';
import { addRelationDefinition } from 'store/actions/entities/relationshipsActions';
import { relationDefinitionsComponent } from 'app/containers/Entities/RelationDefinitions/RelationDefinitionAbout';

export const RELATION_TYPES = [
    'thing',
    'person',
    'organisation',
    'custom',
    'task',
    'process',
    typeMap['map'],
    'entity',
    'wms',
    'drawing',
];

export const relationTypeaheadOptions = RELATION_TYPES.filter((value) => !!typeTitlesSingle[value]).map((value) => ({
    label: typeTitlesSingle[value],
    value,
}));

class AddRelationDefinition extends PureComponent<Object, Object> {
    static propTypes = {
        addRelationDefinition: PropTypes.func.isRequired,
    };

    formRef = React.createRef();
    components = [
        ...relationDefinitionsComponent,
        {
            type: 'classificationTypeahead',
            properties: {
                label: 'Classes',
                name: 'classes',
                filterBy: [{ field: 'active', op: '=', value: true }],
                multiple: true,
                applicableOn: ['relationdefinition'],
            },
        },
    ];

    @bind
    onFormSubmit(event: Event) {
        event.preventDefault();
        this.formRef.current.isValidForm().then(({ data, errors }) => {
            if (!errors) {
                this.props.addRelationDefinition({ ...data, classes: (data.classes || []).map(({ id }) => id) }).then((result) => {
                    !(result instanceof Error) && result.id && history.push(`${baseRoutes['relationdefinition']}/${result.id}`);
                });
            }
        });
    }

    render(): Object {
        const { isLoading, onClose } = this.props;
        return (
            <>
                <ModalDialog
                    title='Create a new Relation Definition'
                    onClose={onClose}
                    actions={isLoading ? <CircularProgress size={24} color='primary' /> : <Button onClick={this.onFormSubmit}>Save</Button>}
                >
                    <FormGenerator components={this.components} ref={this.formRef} />
                </ModalDialog>
            </>
        );
    }
}

export default connect(
    (state) => ({
        isLoading: state.entities.relationDefinitions.details.isLoading,
    }),
    {
        addRelationDefinition,
    }
)(AddRelationDefinition);
