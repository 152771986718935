/* @flow */

import uuidv4  from 'uuid/v4';

import { get } from 'app/utils/lo/lo';
import Cache from 'app/utils/cache/Cache';
import { isDev } from 'app/utils/env';


class ChatSocket {
    socket = {};
    url = '';
    token = '';
    userId = '';
    handleErrorClbk = null;
    subscriptions: Array<Object>;

    constructor(url: string, { userId, token }: Object) {
        if (!url || !userId || !token) {
            throw new Error('wrong arguments.');
        }
        this.url = url;
        this.token = token;
        this.userId = userId;
        this.subscriptions = this._buildSuscribtions();
        this.resultHandlers = new Cache();
        this.resultHandlers.onExpire = (key, handler) => {
            handler.reject(new Error('chat result timeout'));
        };
    }

    _buildSuscribtion(name: string, events: Array<string>): Array<Object> {
        return events.map(event => ({
            id: this.generateUniqueId(),
            msg: 'sub',
            name,
            params: [event, true]
        }));
    }

    _buildSuscribtions(): Array<Object> {
        return [
            ...this._buildSuscribtion('stream-notify-user', [
                `${this.userId}/message`,
                `${this.userId}/otr`,
                `${this.userId}/webrtc`,
                `${this.userId}/notification`,
                `${this.userId}/rooms-changed`,
                `${this.userId}/subscriptions-changed`,
                `${this.userId}/audioNotification`
            ]),
            ...this._buildSuscribtion('stream-notify-logged', [
                'Users:NameChanged',
                'Users:Deleted',
                'deleteEmojiCustom',
                'updateEmojiCustom',
                'updateAvatar',
                'permissions-changed'
            ]),
            ...this._buildSuscribtion('stream-notify-all', [
                'roles-change',
                'updateEmojiCustom',
                'deleteEmojiCustom',
                'updateAvatar',
                'public-settings-changed',
                'permissions-changed',
                'updateCustomSound',
                'deleteCustomSound'
            ])
        ];
    }

    connect = () => {
        // $FlowFixMe
        return new Promise((resolve, reject) => {
            this.socket = new WebSocket(this.url);
            this.socket.onopen = (event) => {
                this.sendMessage({
                    msg: 'connect',
                    version: '1',
                    support: ['1']
                });
                this.onMessage(({ data, originalEvent }) => {
                    if (data.msg === 'connected') {
                        this.sendMessage({
                            msg: 'method',
                            method: 'login',
                            id: 'LOGIN',
                            params: [{ resume: this.token }]
                        });
                    }
                    if (data.id === 'LOGIN') {
                        if (
                            get(data, 'params[0].resume') === this.token ||
                            get(data, 'result.token') === this.token
                        ) {
                            this.subscriptions.forEach((sub) => {
                                this.sendMessage(sub);
                            });
                            console.log('[ChatAPI] connected'); // eslint-disable-line no-console
                            resolve({ data, originalEvent });
                        } else if (data.error) {
                            console.error('[ChatAPI] ERROR', data.error.message); // eslint-disable-line no-console
                            reject(data.error.message);
                        }
                    }
                });
            };
            this.socket.onerror = (err) => {
                console.error('[ChatAPI] CONNECTION ERROR', err); // eslint-disable-line no-console
                reject(err);
            };
            this.socket.onclose = (err) => {
                console.error('[ChatAPI] CONNECTION ERROR', err); // eslint-disable-line no-console
                reject(err);
            };
        });
    };

    sendMessage = async (msg: Object) => {
        if (this.socket.readyState === this.socket.OPEN) {
            await this.socket.send(JSON.stringify(msg));
        } else {
            console.warn(`[ChatAPI] SOCKET IS NOT READY for 'sendMessage'.`); // eslint-disable-line no-console
            // Reconnect handling for "webSocket is already in CLOSING or CLOSED state" error.
            if (!this.socket.CONNECTING && this.socket.readyState) {
                console.warn(`[ChatAPI] reconnect....`); // eslint-disable-line no-console
                this.clearExistingTimeouts();
                setTimeout(this.handleErrorClbk && this.handleErrorClbk(), 1000);
            }
        }
    };

    onMessage = (callback: Function) => {
        this.socket.onmessage = (event: Object) => {
            const data = JSON.parse(event.data);
            const { id, msg, result, error } = data || {};

            if (msg === 'ping') {
                return this.sendMessage({ msg: 'pong' });
            }  
            
            if (msg === 'result') {
                const handler = this.resultHandlers.del(id);
                if (handler) {
                    const { resolve, reject } = handler;
                    if (error) {
                        const message = error.message || 'Request failed.';
                        reject(new Error(message));
                    } else {
                        resolve(result);
                    }
                }
            } 

            return callback({ data, originalEvent: event });
        };
    };

    onError = (callback: Function) => {
        this.socket.onerror = (event: Object) => {
            this.close();
            return callback();
        };
    };

    onClose = (callback: Function) => {
        this.socket.onclose = (event: Object) => {
            this.close();
            return callback();
        };
    };

    close = () => {
        this.clearExistingTimeouts();
        this.socket.close();
        this.socket = {};
    };

    /*
     * @param roomId (number) - The room ID
     * @param max (number) - the message quantity
     */
    getRoomHistory = (roomId: string, rel: Object, max: number, startTimestamp: number, endTimestamp) => {
        isDev && console.debug('[dev] chat getRoomHistory', rel); // eslint-disable-line no-console

        return new Promise((resolve, reject) => {
            const id = this.generateUniqueId('getRoomHistory');
            const startDate = startTimestamp ? { $date: parseInt(startTimestamp) } : null;
            const endDate = { $date: endTimestamp ? parseInt(endTimestamp) : 0 };

            try {
                this.resultHandlers.set(id, { resolve, reject });
                this.sendMessage({
                    msg: 'method',
                    method: 'loadHistory',
                    id,
                    params: [roomId, startDate, max || 0, endDate, false]
                });
            } catch (e) {
                this.resultHandlers.del(id);
                reject(e);
            }
        });
    };

    /*
     * @param rid (string) - Chat Room ID
     */
    addUsersToRoom = (rid: string, users: Array<String>) => {
        return new Promise((resolve, reject) => {
            const id = this.generateUniqueId('addUsersToRoom');
            try {
                this.resultHandlers.set(id, { resolve, reject });
                this.sendMessage({
                    msg: 'method',
                    method: 'addUsersToRoom',
                    id,
                    params: [{ rid, users }]
                });
            } catch (e) {
                this.resultHandlers.del(id);
                reject(e);
            }
        });
    };

    /*
     * get room mentions
     * @param id (string) - Chat Room ID
     * @param searchString (string) - Search String
     * @param exclude (array) - Exclude Usernames
     */
    getRoomSpotlight = (rid, searchString, exclude = []) => {
        const id = this.generateUniqueId('spotlight');
        return new Promise((resolve, reject) => {
            try {
                this.resultHandlers.set(id, { resolve, reject });
                this.sendMessage({
                    msg: 'method',
                    method: 'spotlight',
                    params: [searchString, exclude, {
                        users: true,
                        mentions: true
                    }, rid],
                    id
                });
            } catch (e) {
                this.resultHandlers.del(id);
                reject(e);
            }
        });
    };

    /*
     * get chat subscriptions
     */
    getSubscriptions = () => {
        return new Promise((resolve, reject) => {
            const id = this.generateUniqueId('subscriptions');
            try {
                this.resultHandlers.set(id, { resolve, reject });
                this.sendMessage({
                    msg: 'method',
                    method: 'subscriptions/get',
                    id,
                    params: []
                });
            } catch (e) {
                this.resultHandlers.del(id);
                reject(e);
            }
        });
    };

    /*
     * @param rid (string) - Chat Room ID
     */
    subscribeStreamRoomMessages = (id: string, rid: string) => {
        this.sendMessage({
            msg: 'sub',
            id,
            name: 'stream-room-messages',
            params: [`${rid}`, false]
        });
    };

    /*
     * @param rid (string) - Chat Room ID
     */
    unsubscribeStreamRoomMessages = (id: string, rid: string) => {
        this.sendMessage({
            msg: 'unsub',
            id,
            name: 'stream-room-messages',
            params: [`${rid}`, true]
        });
    };

    generateUniqueId = (shortName: string) => {
        return !shortName ? uuidv4() : `${shortName}.${uuidv4()}`;
    };

    /*
     * @param rid (string) - Chat Room ID
     * @param msg (string) - Message
     */
    sendChatMessage = ({ rid, msg }) => {
        return new Promise((resolve, reject) => {
            const id = this.generateUniqueId('');
            try {
                this.resultHandlers.set(id, { resolve, reject });
                this.sendMessage({
                    id,
                    msg: 'method',
                    method: 'sendMessage',
                    params: [{_id: this.generateUniqueId(), rid, msg }]
                });
            } catch (e) {
                this.resultHandlers.del(id);
                reject(e);
            }
        });
    };

    /*
    * @param fileId (string) - File ID
    */
    deleteRoomAttachment = (fileId: string) => {
        return new Promise((resolve, reject) => {
            const id = this.generateUniqueId('deleteFileMessage');
            try {
                this.resultHandlers.set(id, { resolve, reject });
                this.sendMessage({
                    msg: 'method',
                    method: 'deleteFileMessage',
                    id,
                    params: [fileId]
                });
            } catch (e) {
                this.resultHandlers.del(id);
                reject(e);
            }
        });
    };

    /*
     * @param _id" (string) - Message ID
     * @param rid (string) - Chat Room ID
     * @param msg (string) - Message
     */
    updateChatMessage = (data) => {
        return new Promise((resolve, reject) => {
            const id = this.generateUniqueId('');
            try {
                this.resultHandlers.set(id, { resolve, reject });
                this.sendMessage({
                    id,
                    msg: 'method',
                    method: 'updateMessage',
                    params: [data],
                });
            } catch (e) {
                this.resultHandlers.del(id);
                reject(e);
            }
        });
    };

    clearExistingTimeouts = () => {
        this.resultHandlers.clearAllTimeout();
    }
}

export default ChatSocket;