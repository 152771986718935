/* @flow */

import { name } from 'app/utils/designer/form/settings/common/commonBase';
import { onLoad, onChange } from 'app/utils/designer/form/settings/common/commonEvents';
import { help } from 'app/utils/designer/form/settings/common/commonHelpers';
import { flexGrow } from 'app/utils/designer/form/settings/common/commonStyles';

const panels = [
    {
        header: 'Base',
        children: [
            name,
        ],
    },
    {
        header: 'Events',
        children: [
            onLoad,
            onChange,
        ]
    },
    {
        header: 'Styles',
        children: [ flexGrow ]
    },
    {
        header: 'Helpers',
        children: [ help ]
    },
];

export default {
    defaults: { serialize: true },
    panels: settingValues => panels,
};
