
import { gql } from '@apollo/client';

export default gql`
query loadEntityVersionsFormDefinitionQuery($orderBy: [JSON], $filterBy: [JSON], $excludeBy: [JSON], $startIndex: Int, $stopIndex: Int) {
    records: formDefinitionVersions(startIndex: $startIndex, stopIndex: $stopIndex, orderBy: $orderBy, filterBy: $filterBy, excludeBy: $excludeBy) {
        id
        name
        type
        primary
        createdDate
        createdBy {
          id
          name
        }        
    }
}
`;
