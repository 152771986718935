/* @flow */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { loadTasksByDueDate } from 'store/actions/dashboard/dashboardActions';
import { bind } from 'app/utils/decorators/decoratorUtils';
import Widget from 'app/components/atoms/Widget/Widget';
import LinkList from 'app/containers/LandingPage/Widgets/LinkList';
import WidgetFooter from 'app/containers/LandingPage/Widgets/WidgetFooter';
import GridDueDate from 'app/components/organisms/GridView/GridRenderers/GridDueDate';

const StyledWrapper = styled.div`
    display: flex;
    justify-content: end;
    width: auto;
`;

/**
 * Widget for displaying tasks assigned to the active user
 */
class TasksByDueDateWidget extends PureComponent<Object, Object> {
    static propTypes = {
        loadTasksByDueDate: PropTypes.func,
        isLoading: PropTypes.bool,
        records: PropTypes.array,
        user: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);
        props.loadTasksByDueDate(props.user, { linkOnly: true });
    }

    @bind
    renderFooterContent(){
        const orderBy = { field: 'primary.dueDate', direction: 'asc nulls last' };
        return <WidgetFooter orderBy={orderBy}  />;
    }
    @bind
    renderAdditionalInfo(props){
        const record = {data: { primary: { dueDate: props?.primary?.dueDate}}};
        return  <StyledWrapper><GridDueDate  record={record}/></StyledWrapper>;
    }
    render() {
        const { isLoading, records, item } = this.props;
        const filteredRecords = records.filter((record) => record.primary.dueDate);
        return (
            <Widget
                title="Tasks Due Soon"
                content={!isLoading && !records?.length ? 'No Results': <LinkList
                    list={filteredRecords}
                    type="task"
                    renderAdditionalInfo={this.renderAdditionalInfo}
                />}
                loading={isLoading}
                footerContent={this.renderFooterContent}
                {...item}
            />
        );
    }
}

const mapStateToProps: Function = ( state ) => {
    return {
        user: state.user.profile,
        isLoading: state.dashboard.tasksByDueDate.isLoading,
        records: state.dashboard.tasksByDueDate.records,
    };
};

export default connect(mapStateToProps, { loadTasksByDueDate })(TasksByDueDateWidget);
