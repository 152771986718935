import React, { Fragment } from 'react';
import styled from 'styled-components';

import Widget from 'app/components/atoms/Widget/Widget';
import Avatar from 'app/components/molecules/Avatar/Avatar';

const StyledTypography = styled.p`
    color: ${({ theme }) => theme.material.colors.text.secondary};
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    letter-spacing: 0.25px;
    margin-top: 0;
    overflow: auto;
    padding: 0 20px;
`;

const StyledAvatar = styled(Avatar)`
    margin-right: 10px;
`;

const renderContent = (content) => {
    const lines = content.split('\n');
    return (
        <StyledTypography >
            {
                lines.map((line, index)=>(
                    <Fragment key={index}>
                        {line}
                        <br />
                    </Fragment>
                ))
            }
        </StyledTypography>
    );
};

const renderAppAvatar = (props) => {
    if(!props){
        return null;
    }
    return <StyledAvatar size='lg' {...props} />;

};


const RenderWidget = ({item, avatarProps, userPermissions}) => {
    let url = item.url;
    if(item?.title === 'Analytics'){
        if(userPermissions?.includes('analytics.superAnalytics')){
            url = 'super-analytics';
        }else if(userPermissions?.includes('entity.entity')){
            url = 'print-reports';
        }else{
            url = 'grafana';
        }
    }

    return (
        <Widget
            key={item.title}
            title={item.title}
            content={renderContent(item.description)}
            url={url}
            urlText={item.urlText}
            renderAppAvatar={()=>renderAppAvatar(avatarProps)}
        />
    );
};

export default RenderWidget;