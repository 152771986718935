/* @flow */

// $FlowFixMe
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Avatar, Grid, MdiIcon, Typography, Tooltip } from '@mic3/platform-ui';

import { get } from 'app/utils/lo/lo';
import { connect } from 'react-redux';

const AvatarStyled = styled(Avatar)`
    width: ${({isMobile}) => isMobile ? '23px !important' : '38px !important'};
    height: ${({isMobile}) => isMobile ? '23px !important' : '38px !important'};
`;
const EmptyAvatarStyled = styled.div`
    width: ${({isMobile}) => isMobile ? '23px !important' : '38px !important'};
    height: ${({isMobile}) => isMobile ? '23px !important' : '38px !important'};
`;

const MdiIconStyled = styled(MdiIcon)`
    line-height: ${({isMobile}) => isMobile ? '.75rem !important' : '1.2rem !important'};
    color: white;
`;
const GridStyled = styled(Grid)`
    max-width: ${({isMobile}) => isMobile ? '48px !important' : '80px !important'};
    gap: ${({isMobile}) => isMobile ? '2px' : '4px'} !important;
`;
const GridWrapperStyled = styled(Grid)`
    max-width: ${({isMobile}) => isMobile ? '80px !important' : '171px !important'};
    align-items: center !important;
`;
const AppName = styled(Typography)`
    max-width: ${({isMobile}) => isMobile ? '65px' : '160px'};
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-top: 5px !important;
    line-height: 16px !important;
    font-size: 12px;
    word-break: break-word !important;
`;

const AvatarIcon = ({ app, isMobile }) =>
    app ? (
        <AvatarStyled
            isMobile={isMobile}
            variant="rounded"
            style={{ background: get(app, 'iconColor') }}
        >
            <MdiIconStyled
                isMobile={isMobile}
                type={get(app, 'iconType') || 'mdi'}
                size={isMobile ? 12 : 20}
                name={
                    get(app, 'iconName') ? get(app, 'iconName') : 'asterisk'
                }
            />
        </AvatarStyled>
    ) : (
        <EmptyAvatarStyled isMobile={isMobile} />
    );
class AppGroupAvatars extends PureComponent<Object, Object> {
    render() {
        const { apps, name, isMobile, ...restProps } = this.props;
        return (
            <Tooltip title={name} placement="top">
                <GridWrapperStyled isMobile={isMobile} container direction="column" {...restProps}>
                    <GridStyled isMobile={isMobile} item container justify="center" >
                        <AvatarIcon xs={6} isMobile={isMobile} app={apps[0]}/>
                        <AvatarIcon xs={6} isMobile={isMobile} app={apps[1]}/>
                        <AvatarIcon xs={6} isMobile={isMobile} app={apps[2]}/>
                        <AvatarIcon xs={6} isMobile={isMobile} app={apps[3]}/>
                    </GridStyled>
                    <AppName isMobile={isMobile} variant="caption">{name}</AppName>
                </GridWrapperStyled>
            </Tooltip>
        );
    }
};

export default connect(state=>({
    isMobile: state.global.isMobile
}))(AppGroupAvatars);
