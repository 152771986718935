/* @flow */

import { name } from 'app/utils/designer/form/settings/common/commonBase';
import { onLoad, onChange, isVisible, isDisabled } from 'app/utils/designer/form/settings/common/commonEvents';
import { help } from 'app/utils/designer/form/settings/common/commonHelpers';

const panels = settingsValues => [
    {
        header: 'Base',
        children: [name,
            { type: 'boolean', 
                properties: { label: 'Multiple', 
                    name: 'properties.multiple',
                    onChange: (data) => {
                        const { target } = data;
                        const { value, name } = target;
                        return value ? [{ name, value }] : [{ name, value }, { name: 'properties.filesLimit', value: 1 }];
                    } 
                } 
            },
            {
                type: 'number',
                properties: {
                    label: 'Files limit',
                    name: 'properties.filesLimit',
                    isVisible: data => data.properties && data.properties.multiple
                }
            },
            {
                type: 'text',
                properties: {
                    label: 'Dropzone text',
                    name: 'properties.dropzoneText',
                }
            },
            {
                type: 'text',
                properties: {
                    label: 'Dropzone text hover',
                    name: 'properties.dropzoneTextHover',
                }
            },
        ],
    },
    {
        header: 'Events',
        expanded: true,
        children: [
            onLoad,
            onChange,
            isVisible,
            settingsValues?.viewType !== 'class' && isDisabled
        ].filter(Boolean)
    },
    {
        header: 'Helpers',
        children: [ help ]
    },
];

export default {
    defaults: {
        uploadTo: 'task',
        serialize: true,
        dropzoneTextHover: 'Drop an image here...',
        dropzoneText: 'Drag an image here...',
        filesLimit: 1,
    },
    panels: settingValues => panels(settingValues),
};
