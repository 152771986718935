/* @flow */

import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Link from 'app/components/atoms/Link/Link';

const TeamLink = ((props: Object) => {
    const { id, children, ...restProps } = props;
    return <Link to={`/teams/${id}`} {...restProps}>{children}</Link>;
});

TeamLink.propTypes = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default memo<Object>(TeamLink);
