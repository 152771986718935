/* @flow */

import getHelpMenu from 'app/config/helpConfig';
import { parseVersionLabel } from 'app/utils/app/appUtils';

const helpList  = version => [
    {
        icon: 'help-circle-outline',
        name: 'Help',
        tourName:'reactour__help',
        children: [
            ...parseVersionLabel(getHelpMenu(), version)
        ]
    },
];

export default helpList;
