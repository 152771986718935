// @flow

import { addClassesToIDB, addEntitiesToIDB, addUsersReferencesToIDB } from 'offlineMode/affectliIDB';
import { graphql } from 'graphql/client';
import { classesQueryForIDB, entitiesListIDBQuery, userPreferencesIDBQuery } from 'offlineMode/IDBQueries';

export const loadOfflineDataFromGQL = async () => {
    const isOfflineEnabled = localStorage.getItem('offlineEnabled') === 'true';
    if (!isOfflineEnabled) return;
    console.log(`[OfflineMode] Initiating data loading for offline cache from GraphQL.`); // eslint-disable-line
    let classes = [];
    try {
        classes = await loadAllClasses();
    } catch (error) {
        console.error(`[OfflineMode] ~ Error while fetching classes data ~ error:`, error); // eslint-disable-line
    }
    if (classes?.length) {
        const entityTypes = classes.map(({ primary, uri, abstract }) => (primary && !abstract ? uri : null)).filter(Boolean);
        if (entityTypes?.length) {
            await Promise.all(
                entityTypes.map((type) =>
                    loadEntitiesForIDB(type).catch((error) => {
                        console.error(`[OfflineMode] Error loading entities for type "${type}": ${error.message}`, error);
                    })
                )
            );
        }
    }
    try {
        await loadUserReferencesForIDB();
    } catch (error) {
        console.error(`[OfflineMode] ~ Error while fetching users data ~ error:`, error); // eslint-disable-line
    }
    console.log(`[OfflineMode] Completed loading data for offline cache from GraphQL.`); // eslint-disable-line
    return Promise.resolve();
};

export const loadUserReferencesForIDB = (type) => {
    return graphql
        .query({
            query: userPreferencesIDBQuery,
            variables: { type },
            fetchPolicy: 'no-cache',
        })
        .then((response) => {
            if (response instanceof Error) {
                return Promise.resolve(null);
            }
            const users = response?.data?.records || [];
            addUsersReferencesToIDB(users);
            return users;
        });
};

export const loadEntitiesForIDB = (type) => {
    return graphql
        .query({
            query: entitiesListIDBQuery,
            variables: { type },
            fetchPolicy: 'no-cache',
        })
        .then((response) => {
            if (response instanceof Error) {
                return Promise.resolve(null);
            }
            const entities = response?.data?.records || [];
            addEntitiesToIDB(entities);
            return entities;
        });
};

export const loadAllClasses = () => {
    return graphql
        .query({
            query: classesQueryForIDB,
            variables: {},
            fetchPolicy: 'no-cache',
        })
        .then((response) => {
            if (response instanceof Error) return Promise.resolve();
            const classes = response?.data?.classes || [];
            addClassesToIDB(classes);
            return classes;
        });
};
