import React from 'react';
import styled from 'styled-components';
import { ListItemText, Typography, Grid, MdiIcon, IconButton } from '@mic3/platform-ui';
import Icon from 'app/components/atoms/Icon/Icon';

const Title = styled(Typography)`
    color: ${({ theme }) => theme.material.colors.text.primary};
`;

const ArrowStyled = styled(MdiIcon)`
    font-size: 20px !important;
    height: 20px !important;
    width: 20px !important;
`;

const TitleIcon = styled(Icon)`
    margin-right: 8px;
    padding-top: 3px;
    &:before {
        color: ${({ theme }) => theme.material.colors.text.secondary};
    }
`;

const SubTitle1 = styled(Typography)`
      margin-right: 4px !important;
      color: ${({ theme }) => theme.material.colors.text.secondary};
`;

const SubTitle2 = styled(Typography)`
    color: ${({ theme }) => theme.material.colors.text.caption};
`;

const HeaderView = ({ title, subTitle1, subTitle2, iconName, iconType, handleGoBack }) => {
    return (
        <Grid container>
            {handleGoBack && <IconButton title="back" onClick={handleGoBack}>
                <ArrowStyled name="arrow-left" />
            </IconButton>}
            <ListItemText
                primary={
                    <Grid container>
                        <TitleIcon size={'sm'} name={iconName} type={iconType} />
                        <Title variant="subtitle1">{title}</Title>
                    </Grid>
                }
                secondary={
                    <Grid container>
                        <SubTitle1 variant="caption">{subTitle1}</SubTitle1>
                        {' '}
                        <SubTitle2 variant="caption">{subTitle2}</SubTitle2>
                    </Grid>
                }
            />
        </Grid>
    );
};

export default HeaderView;
