/* @flow */

import { gql } from '@apollo/client';


export const entityReferences = `
    ...on EntityReference{
        id
        name
        image
        type
        __typename
      }
      ... on UserReference {
        id
        name
        username
        image
        type
        __typename
      }
      ...on TaskReference{
        id
        name
        type
        assignee {
          id
          name
          username
          image
          type
        }
        priority
      }
      ...on ProcessReference {
        id
        name
        image
        type
        assignee {
          id
          name
          username
          image
          type
        }
        priority
        closedDate
      }
`;

export default gql`
    query relationsQuery($entityType: String!, $entityId: ID, $filterBy: [JSON], $orderBy: [JSON]) {
        relations: entityRelations(entityType: $entityType, entityId: $entityId, filterBy: $filterBy, orderBy: $orderBy) {
            entity {
              ${entityReferences}
            }
            relatedEntity {
                ${entityReferences}
            }
            isReverseRelation
            relation {
                id
                relationDefinition {
                    id
                    description
                    relatedDescription
                    classes {
                        id
                        name
                        uri
                        formDefinition
                    }
                }
                attributes
                createdBy {
                    id
                    name
                    image
                }
                createdDate
                modifiedBy {
                    id
                    name
                    image
                }
                modifiedDate
            }
        }
    }
`;
