/* @flow */

import { loadData } from 'app/utils/redux/action-utils';
import { addRoleToData, addRoleToList } from 'app/config/rolesConfig';

import printTemplatesListShortQuery from 'graphql/entities/printTemplates/printTemplatesListShortQuery';
import printTemplatesAutocompleteQuery from 'graphql/entities/printTemplates/printTemplatesAutocompleteQuery';
import printTemplatesListRisedQuery from 'graphql/entities/printTemplates/printTemplatesListRisedQuery';
import loadPrintTemplateDetailsQuery from 'graphql/entities/printTemplates/loadPrintTemplateDetailsQuery';

export const LOAD_PRINT_TEMPLATE_DETAILS_STARTED = '@@affectli/entities/print-template/LOAD_PRINT_TEMPLATE_DETAILS_STARTED';
export const LOAD_PRINT_TEMPLATE_DETAILS = '@@affectli/entities/print-template/LOAD_PRINT_TEMPLATE_DETAILS';

export const LOAD_PRINT_TEMPLATE_LIST_VIEW_STARTED = '@@affectli/entities/print-template/LOAD_PRINT_TEMPLATE_LIST_VIEW_STARTED';
export const LOAD_PRINT_TEMPLATE_LIST_VIEW = '@@affectli/entities/print-template/LOAD_PRINT_TEMPLATE_LIST_VIEW';

export const LOAD_REPORTS_TEMPLATE_LIST_VIEW_STARTED = '@@affectli/entities/print-template/LOAD_REPORTS_TEMPLATE_LIST_VIEW_STARTED';
export const LOAD_REPORTS_TEMPLATE_LIST_VIEW = '@@affectli/entities/print-template/LOAD_REPORTS_TEMPLATE_LIST_VIEW';

export const LOAD_ENTITY_PRINT_TEMPLATE_STARTED = '@@affectli/entities/print-template/LOAD_ENTITY_PRINT_TEMPLATE_STARTED';
export const LOAD_ENTITY_PRINT_TEMPLATE = '@@affectli/entities/print-template/LOAD_ENTITY_PRINT_TEMPLATE';

export const loadPrintTemplateDetails = ({ id , type }) =>
    loadData(
        LOAD_PRINT_TEMPLATE_DETAILS_STARTED,
        LOAD_PRINT_TEMPLATE_DETAILS,
        loadPrintTemplateDetailsQuery,
        addRoleToData
    )({ id, type });

export const loadPrintTemplatesListView = ({ isShort, options }: Object = { isShort: false }) =>
    loadData(
        LOAD_PRINT_TEMPLATE_LIST_VIEW_STARTED,
        LOAD_PRINT_TEMPLATE_LIST_VIEW,
        isShort ? printTemplatesListShortQuery : printTemplatesListRisedQuery,
        addRoleToList
    )(options);

export const loadReportsTemplatesListView = ({ isShort, options }: Object = { isShort: false }) =>
    loadData(
        LOAD_REPORTS_TEMPLATE_LIST_VIEW_STARTED,
        LOAD_REPORTS_TEMPLATE_LIST_VIEW,
        isShort ? printTemplatesListShortQuery : printTemplatesListRisedQuery,
        addRoleToList
    )(options);

export const loadEntityPrintTemplates = (id: string) =>
    loadData(
        LOAD_ENTITY_PRINT_TEMPLATE_STARTED,
        LOAD_ENTITY_PRINT_TEMPLATE,
        printTemplatesListShortQuery,
    )({ filterBy: [
        // FIXME: when Luca come back
        { field: 'primary.print-template/applicable_on', op: 'contains', value: id}
    ]});

export const loadPrintTemplateAutocomplete = options =>
    loadData(
        LOAD_ENTITY_PRINT_TEMPLATE_STARTED,
        LOAD_ENTITY_PRINT_TEMPLATE,
        printTemplatesAutocompleteQuery,
    )(options);
