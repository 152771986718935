
import { gql } from '@apollo/client';

export default gql`
query loadAvatarQuery($type: String!, $id: ID!) {
  result: entity(type: $type, id: $id) {
    id
    name
    image
    type
    modifiedDate
  }
}
`;
