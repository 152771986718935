

const taskboardConfig = () => {
    return {
        virtualize: true,
        useDomTransition : true,
        // Url for resource avatar images
        resourceImagePath: window.location.origin,
        // Field used to pair a task to a column
        columnField : 'columnId',
        swimlaneField : 'prio',
        stickyHeaders: true,
        //Items
    
        bodyItems : {
            progress : { type : 'progress', max : 100 },
        },
    
        columnDragFeature:true,
        columnHeaderMenuFeature: false,
        columnToolbarsFeature: false,
        simpleTaskEditFeature: false,
        taskEditFeature:false,
    
    };
};

export { taskboardConfig };
