/* @flow */

import React from 'react';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { DndProvider } from 'react-dnd';
import MultiBackend, { TouchTransition } from 'react-dnd-multi-backend';

const HTML5toTouch = {
    backends: [
        {
            backend: HTML5Backend
        },
        {
            backend: TouchBackend, // Note that you can call your backends with options
            preview: true,
            options: {
                delay: 500,
                scrollAngleRanges: [{ start: 0, end: 90 }],
            },
            transition: TouchTransition,
        }
    ]
};

const DragNDropContext = ({ children }) => (
    <DndProvider backend={MultiBackend} options={HTML5toTouch}>
        {children}
    </DndProvider>
);

export default DragNDropContext;
