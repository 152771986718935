/* @flow */

import React from 'react';

import Help from 'app/utils/designer/form/settings/common/Help';
import { help } from 'app/utils/designer/form/settings/common/commonHelpers';
import { name, label } from 'app/utils/designer/form/settings/common/commonBase';
import { onLoad, onChange, isVisible, isDisabled } from 'app/utils/designer/form/settings/common/commonEvents';
import { required, readOnly, classRequired, staticAttribute } from 'app/utils/designer/form/settings/common/commonValidation';
import { flexGrow } from 'app/utils/designer/form/settings/common/commonStyles';

const panels = settingsValues => [
    {
        header: 'Base',
        children: [
            name,
            label,
            {
                type: 'text',
                properties: {
                    label: 'Title for edit popup',
                    name: 'properties.modalTitle',
                    help: <Help message={`
Defines the title of edit modal.
                    `} />,
                },
            }
        ],
    },
    {
        header: 'Events',
        children: [
            onLoad,
            onChange,
            isVisible,
            settingsValues?.viewType !== 'class' && isDisabled
        ].filter(Boolean)
    },
    {
        header: 'Validation',
        children: [
            settingsValues?.viewType !== 'class' ? required : classRequired,
            settingsValues?.viewType === 'class' && readOnly,
            settingsValues?.viewType === 'class' && staticAttribute(settingsValues)
        ].filter(Boolean)
    },
    {
        header: 'Styles',
        children: [ flexGrow ]
    },
    {
        header: 'Helpers',
        children: [ help ]
    },
];

export default {
    defaults: { withDefault: true },
    panels: settingsValues => panels(settingsValues),
};
