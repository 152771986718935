/* eslint-disable array-callback-return */
/* @flow */

import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { Switch, Route, Redirect } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { IconButton, Badge } from '@mic3/platform-ui';

import history from 'store/History';
import Relations from 'app/containers/Entities/Relationships/Relations';
import Breadcrumbs from 'app/components/organisms/Breadcrumbs/Breadcrumbs';
import DotMenu from 'app/components/molecules/DotMenu/DotMenu';
import PageTemplate from 'app/components/templates/PageTemplate';
import Loader from 'app/components/atoms/Loader/Loader';
import ProcessAboutTab from 'app/containers/Abox/ProcessView/ProcessAboutTab';
import { openEntitySidebar } from 'store/actions/entities/entitySidebarActions';
import { loadProcessSidebarDetails } from 'store/actions/abox/processActions';
import { get } from 'app/utils/lo/lo';
import { isEmpty, getStr } from 'app/utils/utils';
import { getPriorityColor } from 'app/config/aboxConfig';
import PageNotAllowed from 'app/containers/ErrorPages/PageNotAllowed';
import { buildPrintTemplates } from 'app/utils/entity/entityUtils';
import Icon from 'app/components/atoms/Icon/Icon';
import { getSubscriptionByRel } from 'app/utils/chat/chatUtils';
import { openProcessSidebar } from 'store/actions/abox/processSidebarActions';
import { getPermissions } from 'app/config/rolesConfig';
import { openTaskSidebar } from 'store/actions/abox/taskSidebarActions';
import { openRelationSidebar } from 'store/actions/entities/relationSidebarActions';
import EntityDigitalTwin from 'app/containers/Entities/DigitalTwin/EntityDigitalTwin';
import CancelProcessModal from 'app/containers/Abox/ProcessView/CancelProcessModal';
import { loadClassificationTaskAndProcess } from 'store/actions/classifications/classificationsActions';
import { loadPrintTemplatesListView } from 'store/actions/entities/printTemplateActions';
import { setDocumentTitle } from 'store/actions/app/appActions';

class ProcessRoute extends PureComponent<Object, Object> {
    static propTypes = {
        details: PropTypes.object,
        isLoading: PropTypes.bool,
        loadProcessSidebarDetails: PropTypes.func,
        uploadProcessAttachment: PropTypes.func,
    };

    static defaultProps = {
        details: {},
        isLoading: false,
    };

    state = {
        isCancelProcess: false
    };

    /**
     * constructor - description
     */
    constructor(props: Object) {
        super(props);
        this.props.loadProcessSidebarDetails(props.id);
        this.props.loadClassificationTaskAndProcess();
        this.props.loadPrintTemplatesListView();
    }

    /**
     * @override
     */
    componentDidUpdate(prevProps) {
        const { id, details, setDocumentTitle } = this.props;
        const name = details?.name;
        const preName = prevProps?.details?.name;
        if(name && name !== preName) {
            setDocumentTitle(name);
        }
        if (prevProps.id !== id) {
            this.props.loadProcessSidebarDetails(id);
        }
    }

    @bind
    @memoize()
    getBackStyles(headerBackgroundColor) {
        return (
            { background: headerBackgroundColor }
        );
    };

    @bind
    @memoize()
    getUnreadMessages(id, subscriptions) {
        const subscription = getSubscriptionByRel(subscriptions, { id, type: 'process'});
        return subscription ? subscription.unread : 0;
    }

    @bind
    onDotMenuClick(title) {
        const { id, openProcessSidebar } = this.props;
        if (title === 'Cancel process') {
            return this.toggleCancelProcessModal();
        }
        if(title === 'Relations') {
            return history.push(`/abox/process/${id}/relationships`);
        }
        if(title === 'Digital Twin') {
            return history.push(`/abox/process/${id}/digital-twin`);
        }

        openProcessSidebar({ title, id, internal: true  });
    }

    @bind
    @memoize()
    dotMenu(sidebarTitle: string, pathname: string = '', details, canEdit) {
        const { id, printTemplateRecords, tasksAndProcesses } = this.props;
        const { primary: { closedDate } = {}, classes } = details || {};
        const isDigitalTwin = !!(classes || []).find(cls => cls?.uri === 'digital-twin') || details?.primaryClass?.digitalTwins?.length;
        const isRelations = pathname.endsWith(`${id}/relationships`);

        return (
            <DotMenu
                key={13}
                onItemClick={this.onDotMenuClick}
                items={[
                    !pathname.endsWith(`${id}/about`) && sidebarTitle !== 'About' && { name: 'About', icon: 'information' },
                    sidebarTitle !== 'Tasks' && { name: 'Tasks', icon: 'sitemap' },
                    sidebarTitle !== 'Sharing' && { name: 'Sharing', icon: 'share-variant' },
                    sidebarTitle !== 'Classes' && { name: 'Classes', icon: 'Attribute-class', iconType: 'af' },
                    (sidebarTitle !== 'Relations' && !isRelations) && { name: 'Relations', icon: 'relationships', iconType: 'af' },
                    sidebarTitle !== 'Attachments' && { name: 'Attachments', icon: 'attachment' },
                    sidebarTitle !== 'History' && { name: 'History', icon: 'history' },
                    isDigitalTwin && { name: 'Digital Twin', icon: 'hexagon' },
                    canEdit && !closedDate && { name: 'Cancel process', icon: 'window-close' },
                    ...buildPrintTemplates({tasksAndProcesses, printTemplateRecords, type: 'process', details})
                ].filter(Boolean)}
            />
        );
    }

    @bind
    @memoize()
    renderActions(unreadMessages, permissions) {
        if (!permissions.canComment) {
            return null;
        }
        return (
            <IconButton title="Open messenger" onClick={() => this.onDotMenuClick('A-Live')}>
                <Badge color="error" badgeContent={unreadMessages} max={99}>
                    <Icon name="messenger" type="af" />
                </Badge>
            </IconButton>
        );
    }

    @bind
    @memoize()
    buildBreadcrumbs(name, pathTitle) {
        const { id } = this.props;
        let breadcrumbs = [{ title: name }];
        if(pathTitle) {
            breadcrumbs = [{ title: name, link: `/abox/process/${id}` }, { title: pathTitle }];
        }
        return <Breadcrumbs list={[{ link: '/abox/processes', title: 'Processes' }, ...breadcrumbs ]} withGoBack />;
    }

    @bind
    toggleCancelProcessModal(){
        this.setState(prevState => ({ isCancelProcess: !prevState.isCancelProcess }));
    }

    /**
     * @override
     */
    render(): Object {
        const {
            id, match, details, isLoading, theme, sidebarTitle, openTaskSidebar,
            subscriptions, processClosedReasons, openEntitySidebar, openProcessSidebar, openRelationSidebar
        } = this.props;

        const permissions = getPermissions(details && details.role);
        const { canEdit } = permissions || {};
        if (!isLoading && (isEmpty(details) || !permissions.canView)) {
            return <PageNotAllowed permissionError={true} title={`Process (${id})`}/>;
        }
        const { name, primary, } = details || {};

        const { deleteReason, closedDate, variables } = primary || {};
        const { progress, priority } = variables || {};

        const priorityColor = closedDate ? 'disabled' : getPriorityColor(priority);
        const headerBackgroundColor = `linear-gradient(45deg, ${theme.priorityGradients[priorityColor][0]}, ${theme.priorityGradients[priorityColor][1]})`;
        const unreadMessages = this.getUnreadMessages(id, subscriptions);
        let title = name || 'No Name';
        const deleteStatus = !closedDate ? null : processClosedReasons[deleteReason] || 'closed';
        if (deleteStatus) {
            title += ` (${deleteStatus})`;
        }
        const { pathname } = this.props.location;
        return (
            <Fragment>
                { isLoading && <Loader backdrop absolute />}
                { details && <PageTemplate
                    title={title}
                    subTitle={`#${id}`}
                    pillText={`${progress || 0}%`}
                    color={this.getBackStyles(headerBackgroundColor)}
                    overflowHidden={ true }
                >
                    <Switch>
                        <Route path={`${match.url}/`} exact component={() => <Redirect to={`${match.url}/about`}/>}/>
                        <Route path={`/abox/process/:id/about`} exact>
                            <ProcessAboutTab
                                breadcrumbLine={this.buildBreadcrumbs(title)}
                                details={details}
                                canEdit={canEdit}
                                sidebarActions={(
                                    <>
                                        {this.renderActions(unreadMessages, permissions)}
                                        {this.dotMenu(sidebarTitle, pathname, details, canEdit)}
                                    </>
                                )}
                            />
                        </Route>
                        <Route path={'/abox/process/:id/relationships'}>
                            <Relations
                                fromType={details.type}
                                fromId={id}
                                canEdit={canEdit}
                                details={details}
                                openEntitySidebar={openEntitySidebar}
                                openTaskSidebar={openTaskSidebar}
                                openProcessSidebar={openProcessSidebar}
                                openRelationSidebar={openRelationSidebar}
                                breadcrumbLine={this.buildBreadcrumbs(title, 'Relationships')}
                                sidebarActions={
                                    <>
                                        {this.renderActions(unreadMessages, permissions)}
                                        {this.dotMenu(sidebarTitle, pathname, details, canEdit)}
                                    </>
                                }
                            />
                        </Route>
                        <Route path={`${match.url}/digital-twin`}>
                            <EntityDigitalTwin
                                type={details?.type}
                                entity={details}
                                sidebarActions={[
                                    this.renderActions(unreadMessages, permissions),
                                    this.dotMenu(sidebarTitle, pathname, details, canEdit)
                                ]}
                                breadcrumbLine={this.buildBreadcrumbs(get(details, 'name'))}
                            />
                        </Route>
                    </Switch>
                    <CancelProcessModal isOpen={this.state.isCancelProcess} onClose={this.toggleCancelProcessModal} details={details} />
                </PageTemplate>}
            </Fragment>
        );
    }
}

export default connect(
    (state, ownProps) => ({
        id: getStr(ownProps, 'match.params.id', ''),
        isLoading: state.abox.process.sidebar.details.isLoading,
        details: state.abox.process.sidebar.details.data,
        processClosedReasons: state.app.affectliSettings.bpmn.processClosedReasons,
        sidebarTitle: state.sidebar.title,
        subscriptions: state.chat.subscriptions.data,
        printTemplateRecords: state.entities.printTemplates.list.records,
        tasksAndProcesses: state.classifications.classificationTasksAndProcesses.data || []
    }),
    {
        loadProcessSidebarDetails,
        openProcessSidebar,
        openEntitySidebar,
        openTaskSidebar,
        openRelationSidebar,
        loadClassificationTaskAndProcess,
        loadPrintTemplatesListView,
        setDocumentTitle
    },
)(withTheme(ProcessRoute));
