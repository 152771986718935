/**
 * Generate the node path as array ['children', 2, 'children', 4]
 * 
 * @param element the object tree to traverse
 * @param id the identifier on what node to find
 * @param key the object key to match the id
 * @param incrementIndex the looped index 
 * @param path the generated path of the node
 * @returns an array or null
 */
const _getNodePathFromIndex = (element, id, key, path = []) => {
    if (element[key] === id) {
        return path;
    } else if (element.children !== null) {
        let result = null;
        for (let i = 0; result === null && i < element.children.length; i++){
            result = _getNodePathFromIndex(element.children[i], id, key, [...path, 'children', i + 1]);
        }
        return result;
    }
    return null;
};

/**
 * Deletes the node of the tree object and returns the modified tree
 * 
 * @param element the object tree to traverse
 * @param path the path of the node
 * @returns the element excluding the deleted node
 */
const _deleteNode = (element, path) => {
    let node = element;
    for (let i = 0; i <= path.length; i++) {
        if (i !== path.length - 1) {
            const modifiedPath = path[i] === 'children' ? path[i] : path[i] - 1;
            node = node[modifiedPath];    
        } else {
            node.splice(path[i] - 1, 1);
        }
    }
    return element;
};

/**
 * Deletes a node via index
 * 
 * @param tree the tree object
 * @param id the identifier on what node to delete
 * @returns the modified tree object
 */
const deleteTree = (tree, id) => {
    const nodePath = _getNodePathFromIndex(tree, id, 'nodeId');
    const modifiedTree = _deleteNode(tree, nodePath);
    return modifiedTree;
};

/**
 * Searches for the node of a tree object
 * 
 * @param tree the tree object
 * @param treeNodeId the unique tree node id
 * @returns the node reference
 */
const searchTree = (tree, treeNodeId) => {
    if (tree.nodeId === treeNodeId){
        return tree;
    } else if (tree.children !== null){
        let result = null;
        for (let i = 0; result === null && i < tree.children.length; i++){
            result = searchTree(tree.children[i], treeNodeId);
        }
        return result;
    }
    return null;
};

/**
 * Searches for the node of a tree object using an id
 * 
 * @param tree the tree object
 * @param id the node id
 * @param key the key to access the value inside the node object
 * @returns the node reference
 */
const searchTreeById = (tree, id, key) => {
    if (id === tree[key]) {
        return tree;
    }
    else if (Array.isArray(tree.children)) {
        let result = null;
        for (let i = 0; result === null && i < tree.children.length; i++){
            result = searchTreeById(tree.children[i], id, key);
        }
        return result;
    }
    return null;
};

export {
    deleteTree,
    searchTree,
    searchTreeById
};