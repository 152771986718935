/* @flow */

import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Backdrop from 'app/components/atoms/Backdrop/Backdrop';
import { CircularProgress } from '@mic3/platform-ui';

const LoadWrapper = styled.div`
    display: block; text-align: center; margin: 0 auto;
    ${( { absolute, size } ) => absolute ? `position: absolute; bottom: calc(50% - ${(size / 2) || '25px'}); width: 100%; z-index: 10;` : '' };
    ${( { padding } ) => padding ? `padding: ${ padding }` : '' };
`;

/**
 * Our loader/spinner component to add to places requiring a loading state
 */
class Loader extends PureComponent<Object, Object> {
    static propTypes = {
        absolute: PropTypes.bool,
        padding: PropTypes.string,
        size: PropTypes.oneOfType([ //  If using a number, the pixel unit is assumed. If using a string, you need to provide the CSS unit, e.g '3rem'.
            PropTypes.string,
            PropTypes.number
        ]),
        strokeWidth: PropTypes.string,
        color: PropTypes.string,
        className: PropTypes.string,
        style: PropTypes.object
    };

    render() {

        const { absolute, padding, size, strokeWidth, color, className, style, backdrop, ref, forwardRef, ...restProps } = this.props;
        const BackdropComponent = backdrop ? Backdrop : Fragment;
        return (
            <BackdropComponent>
                <LoadWrapper absolute={absolute} padding={padding} size={size} strokeWidth={strokeWidth} color={color} className={`Loader ${className}`} style={style}>
                    <CircularProgress {...restProps} size={size} />
                </LoadWrapper>
            </BackdropComponent>
        );
    }
}

export default Loader;
