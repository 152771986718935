export const relatedEntityViewMore = (sort, isInactive) => [
    {
        label: 'Sort & group',
        disabled: true
    },
    {
        label: 'Edit tree template',
        icon: 'file-tree'
    },
    {
        label: `Sort by name ${sort === '' ? '(Z-A)' : sort === 'asc' ? '(Z-A)' : '(A-Z)'}`,
        icon: sort === '' ? 'sort-descending' : sort === 'asc' ? 'sort-descending' : 'sort-ascending'
    },
    {
        label: 'Expand all',
        icon: 'unfold-more-horizontal',
        hasDivider: true
    },
    {
        label: `${isInactive ? 'Show' : 'Hide'} disabled entities`,
        icon: isInactive ? 'eye-outline' : 'close'
    }
];