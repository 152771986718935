/* @flow */

import { gql } from '@apollo/client';

export default gql`
query processesAutocompleteQuery($page: Int, $pageSize: Int, $filterBy: [JSON], $orderBy: [JSON], $excludeBy: [JSON]) {
  result: processes(page: $page, itemsPerPage: $pageSize, filterBy: $filterBy, orderBy: $orderBy, excludeBy: $excludeBy) {
    id
    name
  }
}
`;
