/* @flow */

import { gql } from '@apollo/client';
import graphicCommonQueryFields from 'graphql/common/graphicCommonQueryFields';

export default gql`
    mutation graphicLibraryMutation($record: GraphicUpdateInput!) {
        result: updateGraphic(record: $record) {
            ${graphicCommonQueryFields}
        }
    }
`;
