import { useCallback, useEffect } from 'react';
import { useQuery, useSubscription } from '@apollo/client';
import { requestNotificationPermission } from 'app/utils/firebaseUtils';
import { notify, playNotificationSound } from 'app/utils/notification/notification';
import { firebaseConfigQuery } from 'graphql/app/pushNotificationQueries';
import { initializeApp } from 'firebase/app';
import { getMessaging, onMessage } from 'firebase/messaging';
import { sendNotificationSubscription } from './Notification';
import { cut } from 'app/utils/string/string-utils';

const pushChannel = new BroadcastChannel('push-notification');

const PushNotifications = () => {
    const { data } = useQuery(firebaseConfigQuery);
    // TODO: Remove the notification implementation after testing and if we want to keep the push notifications implementation
    const { data: notification }  = useSubscription(sendNotificationSubscription);

    useEffect(() => {
        const message = notification?.onSendMessage || '';
        if (message) {
            notify('Notification Received', {
                body: cut(message, 30)
            });
        }
    }, [notification]);

    pushChannel.onmessage = (event) => {
        if (event?.data?.type === 'playSound') {
            playNotificationSound();
        }
    };

    const intiFirebaseApp = useCallback(async (config) => {
        const { vapidKey, ...appConfig } = config;
        const app = initializeApp(appConfig); // Initialize Firebase
        const messaging = getMessaging(app); // Initialize Firebase Cloud Messaging and get a reference to the service
        const permission = await requestNotificationPermission(messaging, vapidKey);
        if (permission === 'granted') {
            onMessage(messaging, payload => {
                const { topic, payload: message } = payload?.data || {};
                // no need to show the notification when user login and subscribe to notifications
                if (topic === 'subscription') return Promise.resolve();
                notify(topic, { body: message });
            });
        }
    }, []);

    useEffect(() => {
        if (data) {
            const config = data?.affectliSettings?.messaging;
            if (config) {
                intiFirebaseApp(config);
            } else {
                console.log('Push-notification: firebase configurations not found'); // eslint-disable-line
            }
        }
    }, [data, intiFirebaseApp]);

    return null;
};

export default PushNotifications;
