// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { bind } from 'app/utils/decorators/decoratorUtils';
import { commonAbout } from 'app/containers/Maps/LayerAbout/CommonAbout';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import { shallowEquals, isObject } from 'app/utils/utils';
import { getGeoserverInfo, updateMapLayer } from 'store/actions/maps/situationalAwarenessActions';
import { LAYERS, __getMapLayers } from 'app/utils/maps/layer/layerUtils';
import { isDefined } from 'app/utils/utils';

class WMSLayerAbout extends PureComponent<Object, Object> {

    static propTypes: Object = {
        id: PropTypes.string.isRequired,
        map: PropTypes.object.isRequired,
        mapId: PropTypes.string.isRequired,
        layer: PropTypes.object.isRequired,
        details: PropTypes.object,
    };

    constructor(props: Object) {
        super(props);
        const layerForm = { ...(props.data || props.details || {}) };
        this.state = {
            layerForm,
            initialForm: layerForm,
            formChanged: false,
            wmsLayers: [],
            styleOptions: []
        };
    }

    formRef = React.createRef();

    componentDidMount() {
        this.props.setActions(this.onFormSubmit);
        this.getWMSLayers();
    }

    componentDidUpdate() {
        const { setToDefault, setActions } = this.props;
        if (setToDefault) {
            this.setState({ layerForm: this.state.initialForm, formChanged: false });
            setActions && setActions(null);
        }
    }

    @bind
    async getWMSLayers() {
        const { layerForm } = this.state;
        const { details, data } = this.props;
        const resp = await getGeoserverInfo(layerForm['layer_url']);
        const { allWMSLayers = [] } = resp || {};
        if(allWMSLayers){
            const wmsLayer = details?.['layer'] || data?.['layer'];
            this.getStyle(allWMSLayers, wmsLayer);
            return this.setState({ wmsLayers: allWMSLayers });
        }
    }

    @bind
    buildFormDefinition(data: Object) {
        const { id, name } = this.props?.details || {};
        return [
            ...commonAbout({ ...data, title: this.props.title, id, name }),
            {
                field: 'panel',
                type: 'panel',
                properties: {
                    header: 'WMS Layer Info',
                    expanded: false,
                },
                children: [
                    {
                        field: 'Layerurl',
                        type: 'text',
                        properties: {
                            name: 'layer_url',
                            label: 'Layer URL',
                        },
                        constraints: {
                            required: true,
                        }
                    },
                    {
                        field: 'Layer',
                        type: 'typeahead',
                        properties: {
                            name: 'layer',
                            label: 'Layer',
                            options: this.state.wmsLayers
                        },
                        constraints: {
                            required: true,
                        }
                    },
                    {
                        field: 'Style',
                        type: 'typeahead',
                        properties: {
                            name: 'style',
                            label: 'Style',
                            options: this.state.styleOptions,
                        },
                        constraints: {
                            required: true
                        }
                    }
                ]
            }
        ].filter(Boolean);
    }

    @bind
    getStyle(wmsLayers, value) {
        if (!wmsLayers?.length) return;
        const newOptions = wmsLayers.filter(e => e.value === value)[0]?.styles;
        const styleOptions = (newOptions || []).map(({ value, label }) => ({ name: value, label: label, value }));
        styleOptions?.length &&  this.setState({ styleOptions });
    }

    @bind
    handleChange(data: Object, { name, value }: Object) {
        let layerForm = data;
        const { wmsLayers } = this.state;
        if (name === 'layer_url' && value) {
            this.setState({ layerForm : { 'layer_url' : value,'layer': null, 'style': null }});
            return getGeoserverInfo(value, (resp) => {
                const { allWMSLayers } = resp || {};
                if(allWMSLayers){
                    return this.setState({ wmsLayers: allWMSLayers });
                }
            });
        }
        if(name === 'layer' && !value) {
            layerForm = { ...layerForm, [name]: value, 'style': null };

        }
        else if(name === 'layer' && value){
            this.getStyle(wmsLayers, value);
            layerForm = { ...layerForm, [name]: value };
        }
        this.setState({ layerForm }, async () => {
            const { initialForm, formChanged } = this.state;
            const changedValue = isObject(value) ? value : { value };
            const initValue = isObject(initialForm[name]) ? initialForm[name] : { value: initialForm[name] };
            if (!formChanged && !shallowEquals(changedValue, initValue)) {
                this.setState({ formChanged: true });
                await this.props.setStatus(true);
                this.props.setActions(this.onFormSubmit);
            } else if (formChanged && shallowEquals(changedValue, initValue)) {
                this.setState({ formChanged: false });
                await this.props.setStatus(true);
                this.props.setActions(null);
            }
        });
    }

    @bind
    onFormSubmit() {
        this.formRef.current.isValidForm().then(async ({ data, errors }) => {
            if (!errors) {
                const { id, details, allMapLayers } = this.props;
                const { description } = data;
                const { type } = details || '';
                const foundLayer = allMapLayers.find(layer => layer.id === id);

                const updatedLayerData = { 
                    ...this.state.layerForm,
                    id,
                    name: foundLayer?.name,
                    type: LAYERS[type],
                    description,
                    active: true,
                    visible: isDefined(foundLayer?.visible) ? foundLayer?.visible : true,
                    opacity: foundLayer?.opacity || 100,
                };

                this.props.updateMapLayer(updatedLayerData);
                this.setState({ initialForm: { data }, formChanged: false });
            
                this.props.setActions(null);
            }
        });
    }

    render() {
        const { layerForm } = this.state;
        return (
            <FormGenerator
                data={layerForm}
                onChange={this.handleChange}
                components={this.buildFormDefinition(layerForm)}
                ref={this.formRef}
            />
        );
    }
}

export default connect(
    (state: Object, ownProps: Object) => ({
        allMapLayers: __getMapLayers(state.maps.situationalAwareness.map.data),
    }),
    {
        updateMapLayer
    })(WMSLayerAbout);
