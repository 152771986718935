/* @flow */

import { gql } from '@apollo/client';

export const processesFields = `
    id
    name
    type
    primary(fields:["closedDate", "variables", "priority"])
    processDefinitionVersion {
        iconName
        iconColor
    }
`;

export default gql`
    query loadProcessesQuery($startIndex: Int, $stopIndex: Int, $filterBy: [JSON], $orderBy: [JSON], $excludeBy: [JSON]) {
        count: count(dataType: "process", filterBy: $filterBy, excludeBy: $excludeBy)
        records: processes(startIndex: $startIndex, stopIndex: $stopIndex, filterBy: $filterBy, orderBy: $orderBy, excludeBy: $excludeBy) {
            ${processesFields}
            processDefinition { id }
            tasks { id }
            _wkt
            classes {
                id
                name
            }
            relations {
                isReverseRelation
            }
            workspaces {
                isSystem
            }
            modifiedDate
            createdDate
            createdBy {
                id
                name
                image
            }
            modifiedBy {
                id
                name
                image
            }
        }
    }
`;
