import { isDefined } from 'app/utils/utils';

const delays = async (fn) => {
    return new Promise((resolve, reject) => {
        try {
            const result = fn();
            setTimeout(() => resolve(result), 1);
        } catch (e) {
            reject(e);
        }
    });
};

const _write = (editor, text) => {
    return delays(() => {
        const doc = editor.doc;
        var cursor = doc.getCursor();
        var pos = { line: cursor.line, ch: cursor.ch };
        return doc.replaceRange(text, pos);
    });
};

export const write = async (editor, text, position) => {
    const doc = editor.doc;
    if (position) {
        doc.cm.execCommand(`go${position}`);
        return _write(editor, text);
    } else {
        return _write(editor, text);
    }
};

export const go = async (editor, position, times = 1) => {
    const doc = editor.doc;
    await delays(() => {
        for (let i = 0; i < times; ++i) {
            doc.cm.execCommand(`go${position}`);
        }
    });
};

export const focus = async (editor) => {
    return delays(() => editor.doc.cm.focus());
};

export const replaceSelection = async (editor, text, select) => {
    const doc = editor.doc;
    return delays(() => doc.replaceSelection(text, select));
};

export const moveCursor = async (editor, position = {}, delta = {}) => {
    delays(() => {
        let { line, ch } = editor.doc.getCursor();
        if (isDefined(position.line)) {
            line = position.line;
        } else if (isDefined(delta.line)) {
            line = line + delta.line;
        }
        if (isDefined(position.ch)) {
            ch = position.ch;
        } else if (isDefined(delta.ch)) {
            ch = ch + delta.ch;
        }
        editor.doc.setCursor({ line, ch });
    });
};

export const getSelection = (editor) => {
    return editor.doc.getSelection();
};

export const clearSelection = (editor, delta = {}) => {
    delays(() => {
        let { line, ch } = editor.doc.getCursor();
        line = delta.line ? line + delta.line : line;
        ch = delta.ch ? ch + delta.ch : ch;
        editor.doc.setCursor({ line, ch });
    });
};

export const setSelection = (editor, count, delta = {}) => {
    delays(() => {
        let { line, ch } = editor.doc.getCursor();
        line = delta.line ? line + delta.line : line;
        ch = delta.ch ? delta.ch < 0 ? ch + delta.ch : delta.ch : ch;
        editor.doc.setSelection({ line, ch }, { line, ch: ch + count });
    });
};

export const countLines = text => (text || '').replace(/[^\n]/g, '').length;
