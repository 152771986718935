/* @flow */

import { gql } from '@apollo/client';

export default gql`
mutation cloneProcessDefinitionMutation($id: ID!, $name: String!, $description: String) {
  result: cloneProcessDefinition(id: $id, name: $name, description: $description) {
    id
  }
}
`;
