/* @flow */

import { gql } from '@apollo/client';
import relationDefinitionsCommonQueryFields from 'graphql/entities/relationships/relationDefinitionsCommonQueryFields';

export default gql`
    mutation removeRelationDefinitionClassesMutation($id: ID!, $classes: [ID]!) {
        result: relationDefinitionRemoveClasses(id: $id, classes: $classes) {
            ${relationDefinitionsCommonQueryFields}
        }
    }
`;
