/* @flow */

import { gql } from '@apollo/client';

export default gql`
query userChatCredentialsQuery {
  result: profile {
    chatUserId
    chatToken
  }
}
`;
