/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Avatar, ListItemText } from '@mic3/platform-ui';

import { loadDesignerFormTypeahead } from 'store/actions/designer/designerFormActions';

import { bind } from 'app/utils/decorators/decoratorUtils';
import { TypeaheadChipInitials, TypeaheadAdornmentInitials } from 'app/components/organisms/Form/Typeahead/abstract/TypeaheadChip';
import { TypeaheadListItem } from 'app/components/organisms/Form/Typeahead/abstract/TypeaheadListItem';

import AbstractEntityTypeahead from 'app/components/organisms/Form/Typeahead/abstract/AbstractEntityTypeahead';
import ResolveAvatarTypeaheadWrapper from 'app/components/organisms/Form/Typeahead/abstract/ResolveAvatarTypeaheadWrapper';

/**
 * Select one or more groups using lazy loading.
 */
class FormDefinitionTypeahead extends PureComponent<Object, Object> {

    static propTypes = {
        loadDesignerFormTypeahead: PropTypes.func.isRequired,
        filterBy: PropTypes.arrayOf(PropTypes.object),
    }

    @bind
    optionTemplate({ image, name, id }: Object) {
        return ({
            option: (
                <TypeaheadListItem ContainerComponent="div" dense disableGutters>
                    <Avatar src={image} initials={name} />
                    <ListItemText primary={name} />
                </TypeaheadListItem>
            ),
            ChipProps: {
                avatar: <TypeaheadChipInitials src={image} initials={name} />,
            },
            startAdornment: <TypeaheadAdornmentInitials src={image} initials={name} />,
            label: `${name || 'Name not available'} (${id ? id.slice(0,8) : 'Id not available'})`,
        });};


    render() {
        const { loadDesignerFormTypeahead, ...abstractEntityAutocompleteProps } = this.props;
        return (
            <AbstractEntityTypeahead
                placeholder="Search for a Form..."
                {...abstractEntityAutocompleteProps}
                VirtualListProps={{
                    itemSize: 60,
                }}
                loadOptions={loadDesignerFormTypeahead}
                optionTemplate={this.optionTemplate}
            />
        );
    }
};

const FormDefinitionTypeaheadWrapped = (props) => {
    return <ResolveAvatarTypeaheadWrapper {...props} Typeahead={FormDefinitionTypeahead} entityType="formdefinition" />;
};


export default connect(
    null, { loadDesignerFormTypeahead }
)(FormDefinitionTypeaheadWrapped);
