/* @flow */

// $FlowFixMe
import React, { memo } from 'react';
import PageTemplate from 'app/components/templates/PageTemplate';
import IFrame from 'app/components/atoms/IFrame/IFrame';

const Grafana = () => (
    <PageTemplate title="Grafana" overflowHidden hasIframe>
        <IFrame title="Grafana" src="/grafana" />
    </PageTemplate>
);

export default memo<Object>(Grafana);
