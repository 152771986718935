/* @flow */

import React, { PureComponent } from 'react';
import GoogleMapReact from 'google-map-react';
import { connect } from 'react-redux';
import { MdiIcon, Typography } from '@mic3/platform-ui';
import styled from 'styled-components';

import { showToastr } from 'store/actions/app/appActions';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { get } from 'app/utils/lo/lo';
import { loadAffectliConfiguration } from 'store/actions/app/appActions';
import LocationTypeahead from 'app/components/organisms/Form/Typeahead/LocationTypeahead';

const MdiIconStyled = styled(MdiIcon)`
    position: absolute;
    top: -36px;
    left: -18px;
`;

const Wrapper = styled.div`
  margin: 10px 0;
`;

const TypographyStyled = styled(Typography)`
  padding: 8px 0;
`;

const Marker = (props) => {
    return <div style={{ position: 'relative' }}><MdiIconStyled name="map-marker" size={36} color="error" /></div>;
};

class GeotagAddress extends PureComponent {

    state = {
        mapKey: 1,
    }

    componentDidUpdate(prevProps) {
        const { value } = this.props;
        if(prevProps.value !== value) {
            this.reload();
        }
    }

    componentDidMount() {
        this.props.loadAffectliConfiguration();
    }

    @bind
    reload() {
        this.setState(state => ({ mapKey: state.mapKey + 1}));
    }

    @bind
    handleLocationChange(event) {
        const { value } = event.target;
        let compiledValue = null;
        if(value) {
            const { address, lat, lon, display_name } = value;
            compiledValue = { address, latitude: Number(lat), longitude: Number(lon), display_name };
        }
        this.props.onChange({ target: { value: compiledValue, name: this.props.name }});
    }

    @bind
    @memoize()
    buildMarker(value) {
        if(!value) {
            return null;
        }
        return { lat: value.latitude, lng: value.longitude };
    }

    render() {
        const { googleApiKey, value, label, withMap, withCoords, placeholder, disabled, onMouseDown } = this.props;
        const marker = this.buildMarker(value);
        return (
            <Wrapper>
                <LocationTypeahead 
                    value={value} 
                    disabled={disabled} 
                    label={label} 
                    placeholder={placeholder} 
                    onChange={this.handleLocationChange}
                    onMouseDown={onMouseDown} 
                />
                {withCoords && marker && <TypographyStyled>Latitude: {get(marker, 'lat')} Longitude: {get(marker, 'lng')}</TypographyStyled>}
                {withMap && marker && googleApiKey && (
                    <div style={{ height: '300px', width: '100%' }}>
                        <GoogleMapReact
                            key={this.state.mapKey}
                            defaultZoom={12}
                            defaultCenter={marker}
                            bootstrapURLKeys={{ key: googleApiKey, libraries: 'places' }}
                        >
                            <Marker {...marker} />
                        </GoogleMapReact>
                    </div>
                )}
            </Wrapper>
        );
    }
};

export default connect(
    state => ({
        googleApiKey: state.app.config.googleApiKey
    }),
    { showToastr, loadAffectliConfiguration }
)(GeotagAddress);
