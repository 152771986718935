// @flow
import {
    FETCH_TEMPLATE_STARTED, FETCH_TEMPLATE
} from 'store/actions/common/digitalTwinActions';
import Immutable from 'app/utils/immutable/Immutable';

const initialState = {
    isLoading: false,
    content: null,
};

export default (state: Object = initialState, action: Function) => {
    const { type, error, payload, meta } = action;

    switch (type) {
        case FETCH_TEMPLATE_STARTED:
            return Immutable({ ...state, isLoading: true, content: null, url: meta?.url });

        case FETCH_TEMPLATE: {
            if (error) return Immutable({ ...state, isLoading: false, content: null, url: null });
            return Immutable({ ...state, isLoading: false, content: payload, url: meta?.url });
        }

        default:
            return state;
    }
};
