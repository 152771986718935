/* @flow */

import { gql } from '@apollo/client';
import eventDetailCommonQueryFields from 'graphql/stream/event/eventDetailCommonQueryFields';


export default gql`
    mutation updateEventMutation($record: EventUpdateInput!) {
        updateEvent(record: $record) {
            ${eventDetailCommonQueryFields}
        }
    }
`;
