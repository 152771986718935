/* @flow */

// $FlowFixMe
import React, { Fragment, PureComponent, useEffect, useCallback } from 'react';
import { TextField, Tooltip, IconButton, Select, MenuItem } from '@mic3/platform-ui';
import styled from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getEntityUrl} from 'app/utils/entity/entityUtils';
import ResponsiveActions from 'app/components/molecules/ResponsiveActions/ResponsiveActions';
import GridEntityId from 'app/components/organisms/GridView/GridRenderers/GridEntityId';
import NewEntitySharing from 'app/containers/Entities/EntitySharing/NewEntitySharing';
import AddNewResourcesModal from 'app/containers/Common/AddNewResourcesModal';
import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import NestedMenu from 'app/components/molecules/NestedMenu/NestedMenu';
import Container from 'app/components/atoms/Container/Container';
import ListItem from 'app/components/molecules/List/ListItem';
import UserEntityLink from 'app/components/atoms/Link/UserEntityLink';
import Avatar from 'app/components/molecules/Avatar/Avatar';
import Icon from 'app/components/atoms/Icon/Icon';

import { getAttachmentUrl } from 'app/utils/attachments/attachmentsUtils';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { ChatMembersFilter, sharingMenu } from 'app/config/chatConfig';
import { createInitials } from 'app/utils/avatar/avatar';
import Immutable from 'app/utils/immutable/Immutable';
import { get, sortBy } from 'app/utils/lo/lo';
import { isEmpty } from 'app/utils/utils';

import {
    loadRoomMembers,
    loadChannelMembers,
    unsubscribeEntityChat,
    unsubscribeGroupChat,
    unsubscribeChannelChat,
    addRoomUsers,
    loadChannelRoles,
    loadGroupRoles,
    kickFromChannelChat,
    kickFromGroupChat,
    subscribe
} from 'store/actions/chat/chatActions';
import { shareEntityWithUsers } from 'store/actions/entities/entitiesActions';
import { closeSidebar as closeSidebarAction, setTitle } from 'store/actions/sidebar/sidebarActions';
import { saveUserPreferences } from 'store/actions/admin/usersActions';
import { loadAvatar } from 'store/actions/entities/entitiesActions';
import history from 'store/History';

const HeaderBar = styled.div`
    padding: 0 1rem;
`;
const HeaderActions = styled.div`
    ${({ isEntityChat }) => !isEntityChat ? `
    flex: 1;
    text-align: end;
    ` : `
    display: flex;
    justify-content: space-between;
    align-items: center;
    `};
`;
const SearchWrapper = styled.div`
    width: 100%;
    position: sticky;
    z-index: 1;
    top: 0px;
    height: 50px;
    display: flex;
    align-items: center;
    background: ${({ theme }) => theme.material.colors.background.default};
`;
const StyledTextField = styled(TextField)`
    margin-left: 5px !important;
    .MuiInputBase-input {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: ${({theme})=> theme.material.colors.text.secondary};
        mix-blend-mode: normal;
    }
`;
const StyledNestedMenu = styled(NestedMenu)`
    i.mdi-circle-slice-8:before,
    i.mdi-checkbox-marked:before {
        color: #7391D0 !important;
    }
`;
const StyledListItem = styled(ListItem)`
    box-shadow: none;
    background: inherit;
`;
const StatusLabel = styled.div`
    margin: 16px 0;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: ${({theme})=> theme.material.colors.text.secondary};
`;
const StyledSelect = styled(Select)`
    width: 50%;
`;
const Divider = styled.hr`
    border: none;
    margin: 0;
    height: 1px;
    flex-shrink: 0;
    background-color: ${({theme})=> theme.material.colors.background.divider};
`;

const TeamListItem =
  connect(
      (state, props) => ({
          avatar: state.entities.avatar[String(props.user.id)],
      }),
      { loadAvatar }
  )(
      ({ key, rid, rel, id, user, avatar, readOnly, unsubscribe, loadAvatar, editor }: Object) => {
          const { type: relType } = rel || {};
          const isNotEntityChat = ['channel', 'group'].includes(relType);
          const image = get(user, 'image');

          useEffect(() => {
              loadAvatar(user.id, 'person');
          }, [user.id, loadAvatar]);

          const onDelete = useCallback(() => unsubscribe(user.id, user.username, id), [user, id, unsubscribe]);
          return (
              <StyledListItem
                  component={
                      <Avatar
                          src={
                              image &&
                              getAttachmentUrl(
                                  user.id,
                                  'user',
                                  image
                              )
                          }
                          name={createInitials(user.name)}
                          size='lg'
                      />
                  }
                  title={<UserEntityLink id={user.id}>{user.name}</UserEntityLink>}
                  subTitle={<GridEntityId label={user.id} value={getEntityUrl(user.id, 'user')} valueId={user.id} />}
                  actions={
                      <Fragment>
                          {((!readOnly || editor) && relType !== 'team') && (
                              <Tooltip
                                  title={
                                      isNotEntityChat ? 'Leave' : 'Unsubscribe'
                                  }
                                  alt={user.name}
                                  placement='top'
                              >
                                  <span alt={user.name}>
                                      <ResponsiveActions
                                          actions={
                                              isNotEntityChat ? (
                                                  <Icon
                                                      name='logout-variant'
                                                      onClick={onDelete}
                                                  />
                                              ) : (
                                                  <Icon
                                                      name='close'
                                                      onClick={onDelete}
                                                  />
                                              )
                                          }
                                      />
                                  </span>
                              </Tooltip>
                          )}
                      </Fragment>
                  }
                  raised
              />
          );
      }
  );


/**
 * Renders the view to display the classification.
 */
class ChatSharing extends PureComponent<Object, Object> {
    static propTypes = {
        id: PropTypes.string,
        type: PropTypes.string,
        details: PropTypes.string,
        rid: PropTypes.string,
        rel: PropTypes.shape({
            id: PropTypes.string,
            type: PropTypes.string,
        }),
        canEdit: PropTypes.bool,
        selectedSidebar: PropTypes.string,
        members: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            rel: PropTypes.shape({
                id: PropTypes.string,
                name: PropTypes.string,
            }),
        })),
        roles: PropTypes.arrayOf(PropTypes.shape({
            _id: PropTypes.string,
            rid: PropTypes.string,
            u: PropTypes.shape({
                _id: PropTypes.string,
                username: PropTypes.string,
                name: PropTypes.string,
            }),
            roles: PropTypes.arrayOf(PropTypes.string)
        })),
        readOnly: PropTypes.bool,
        isLoading: PropTypes.bool,
        unsubscribeChannelChat: PropTypes.func.isRequired,
        unsubscribeEntityChat: PropTypes.func.isRequired,
        unsubscribeGroupChat: PropTypes.func.isRequired,
        loadChannelMembers: PropTypes.func.isRequired,
        loadRoomMembers: PropTypes.func.isRequired,
        addRoomUsers: PropTypes.func.isRequired,
        loadChannelRoles: PropTypes.func.isRequired,
        loadGroupRoles: PropTypes.func.isRequired,
        closeSidebarAction: PropTypes.func.isRequired,
        kickFromChannelChat: PropTypes.func.isRequired,
        kickFromGroupChat: PropTypes.func.isRequired,
        subscribe: PropTypes.func.isRequired,
        shareEntityWithUsers: PropTypes.func.isRequired,
        setTitle: PropTypes.func.isRequired,
    };

    listRef = React.createRef();

    constructor(props: Object) {
        super(props);
        const chatMembersFilter = get(props.preferences, 'filters.chatMembers', {});
        let { sortBy, groupBy } = chatMembersFilter;
        sortBy = !isEmpty(sortBy) ? sortBy : 'name';
        groupBy = !isEmpty(groupBy) ? groupBy : { status: true };
        this.state = Immutable({
            filters: { sortBy, groupBy },
            searchValue: '',
            selectedSidebar: props.selectedSidebar || 'share',
            searchIsActive: false,
            sortAnchorEl: null,
            shareAnchorEl: null,
            openModal: false,
            openShareModal: false,
            shareModalType: ''
        });
        const { rel, rid } = props;
        if (rel && rid) {
            if (rel.type === 'channel') {
                props.loadChannelMembers(rel, rid);
                props.loadChannelRoles(rid);
            } else {
                props.loadRoomMembers(rel, rid);
                props.loadGroupRoles(rid);
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { filters: prevFilters } = prevState;
        const { selectedSidebar: prevSelectedSidebar } = prevProps;
        const { selectedSidebar, saveUserPreferences, preferences } = this.props;
        const { filters } = this.state;
        if (!isEmpty(prevFilters)) {
            if (JSON.stringify(prevFilters) !== JSON.stringify(filters)) {
                saveUserPreferences({ ...preferences, filters: { ...preferences.filters, chatMembers: filters } });
            }
        }

        if (prevSelectedSidebar !== selectedSidebar) {
            if (['share', 'subscribers'].includes(selectedSidebar)) {
                this.setState({ selectedSidebar });
            }
        }
    }

    @bind
    onSearch(event) {
        this.setState({ searchValue: event.target.value });
    }

    @bind
    toggleSearch() {
        this.setState(state => ({
            ...state,
            searchIsActive: !state.searchIsActive
        }));
    }

    @bind
    openShare(e) {
        this.setState({ shareAnchorEl: e.currentTarget });
    }

    @bind
    closeShare() {
        this.setState({ shareAnchorEl: null });
    }

    @bind
    onShareClick(title) {
        switch (title) {
            case 'Users':
                this.setState({ openShareModal: true, shareModalType: title });
                break;

            case 'Teams':
                this.setState({ openShareModal: true, shareModalType: title});
                break;

            case 'Workspaces':
                this.setState({ openShareModal: true, shareModalType: title });
                break;
                
            case 'Subscribers': {
                const { rid, rel } = this.props;
                const selectedSidebar = (rel && rid) ? 'subscribers' : 'share';
                this.setState({ selectedSidebar, openModal: true });
                break;
            }
            default:
                break;
        }
    }

    @bind
    openSortSetting(e) {
        this.setState({ sortAnchorEl: e.currentTarget });
    }

    @bind
    closeSortSetting() {
        this.setState({ sortAnchorEl: null });
    }

    @bind
    onFilterClick(title) {
        switch (title) {
            case 'Name (A-Z)':
                this.setState(({filters}) => ({filters: {...filters, sortBy: 'name'}}));
                break;

            case 'Online':
                this.setState(({filters}) => ({filters: {...filters, sortBy: 'online'}}));
                break;

            case 'Status':
                this.setState(({filters}) => ({filters: {...filters, groupBy: {...filters.groupBy, status: !filters.groupBy.status}}}));
                break;

            default:
                break;
        }
    }

    @bind
    @memoize()
    filter(members, searchValue) {
        if (!searchValue) {
            return members || [];
        }
        const pattern = searchValue.toLowerCase();
        return (members || []).filter(({ rel: { id, name } }) =>
            id === searchValue || name.toLowerCase().includes(pattern)
        );
    }

    @bind
    onChangeSidebar(elem) {
        const { target: { value } = {} } = elem || {};
        this.setState(state => ({
            ...state,
            selectedSidebar: value
        }));
    }

    @bind
    @memoize()
    getItems(members, rid, rel, unsubscribe, filters, editor) {
        const { profile: { id: profileId } } = this.props;
        const { sortBy: sort, groupBy: { status: groupByStatus } = {} } = filters;
        let chatMembers = [...members];
        let online, away, busy, offline;

        if (sort === 'name') {
            chatMembers = sortBy(members, 'rel.name', { caseInsensitive: true });
        }
        if (sort === 'online') {
            chatMembers = [...members.filter(({ status }) => status === 'online'), ...members.filter(({ status }) => status !== 'online')];
        }
        if (groupByStatus) {
            online = chatMembers.filter(member => member.status === 'online');
            away = chatMembers.filter(member => member.status === 'away');
            busy = chatMembers.filter(member => member.status === 'busy');
            offline = chatMembers.filter(member => member.status === 'offline');
        }

        return (groupByStatus ? (
            <>
                {Boolean(online.length) && (
                    <>
                        <StatusLabel>Online</StatusLabel>
                        {online.map((member, index) => (
                            <TeamListItem
                                key={member.id}
                                rid={rid}
                                rel={rel}
                                id={member.id}
                                user={member.rel}
                                readOnly={member.rel.id !== profileId}
                                unsubscribe={unsubscribe}
                                editor={editor}
                            />
                        ))}
                    </>
                )}
                {Boolean(away.length) && (
                    <>
                        <StatusLabel>Away</StatusLabel>
                        {away.map((member, index) => (
                            <TeamListItem
                                key={member.id}
                                rid={rid}
                                rel={rel}
                                id={member.id}
                                user={member.rel}
                                readOnly={member.rel.id !== profileId}
                                unsubscribe={unsubscribe}
                                editor={editor}
                            />
                        ))}
                    </>
                )}
                {Boolean(busy.length) && (
                    <>
                        <StatusLabel>Busy</StatusLabel>
                        {busy.map((member, index) => (
                            <TeamListItem
                                key={member.id}
                                rid={rid}
                                rel={rel}
                                id={member.id}
                                user={member.rel}
                                readOnly={member.rel.id !== profileId}
                                unsubscribe={unsubscribe}
                                editor={editor}
                            />
                        ))}
                    </>
                )}
                {Boolean(offline.length) && (
                    <>
                        <StatusLabel>Offline</StatusLabel>
                        {offline.map((member, index) => (
                            <TeamListItem
                                key={member.id}
                                rid={rid}
                                rel={rel}
                                id={member.id}
                                user={member.rel}
                                readOnly={member.rel.id !== profileId}
                                unsubscribe={unsubscribe}
                                editor={editor}
                            />
                        ))}
                    </>
                )}
            </>
        ) : (
            <>
                {Boolean(chatMembers.length) && (
                    <>
                        {chatMembers.map((member, index) => (
                            <TeamListItem
                                key={member.id}
                                rid={rid}
                                rel={rel}
                                id={member.id}
                                user={member.rel}
                                readOnly={member.rel.id !== profileId}
                                unsubscribe={unsubscribe}
                                editor={editor}
                            />
                        ))}
                    </>
                )}
            </>
        ));
    }

    @bind
    unsubscribe(userId, userName, memberId) {
        const {
            rel,
            rid,
            profile: { id: profileId },
            location,
            unsubscribeEntityChat,
            unsubscribeGroupChat,
            unsubscribeChannelChat,
            loadChannelMembers,
            loadRoomMembers,
            closeSidebarAction,
            kickFromChannelChat,
            kickFromGroupChat,
            setTitle
        } = this.props;
        const { id, type: relType } = rel || {};
        const isEntityChat = !['channel', 'direct', 'group', 'live'].includes(relType);
        const isKick = String(profileId) !== String(userId);
        const redirect = ((res, loadChatMembers) => {
            const isError = get(res, 'error', false);
            if (isKick) {
                if (!isError) {
                    loadChatMembers(rel, rid);
                }
            } else {
                if (!isError) {
                    const { pathname } = location || { pathname: '' };
                    const subPath = pathname.split('/')[2] || '';
                    if (subPath === 'alive') {
                        closeSidebarAction();
                        history.push(`/abox/alive`);
                    } else {
                        setTitle('A-Live');
                    }
                }
            }
        });

        if (isEntityChat) {
            unsubscribeEntityChat({ id, userId, type: relType, rid, isKick }).then((res) => {
                redirect(res, loadRoomMembers);
            });
        } else if (relType === 'group') {
            if (!isKick) {
                unsubscribeGroupChat(rid).then((res) => {
                    redirect(res, loadRoomMembers);
                });
            } else {
                kickFromGroupChat(rid, userName).then((res) => {
                    redirect(res, loadRoomMembers);
                });
            }
        } else if (relType === 'channel') {
            if (!isKick) {
                unsubscribeChannelChat(rid).then((res) => {
                    redirect(res, loadChannelMembers);
                });
            } else {
                kickFromChannelChat(rid, memberId).then((res) => {
                    redirect(res, loadChannelMembers);
                });
            }
        }
    }

    @bind
    handleOnSubmit(data) {
        const {
            rid,
            rel,
            addRoomUsers,
            loadRoomMembers,
            loadChannelMembers,
            shareEntityWithUsers,
            subscribe,
            profile: { id: profileId },
            entityType
        } = this.props;
        const { id: relId, type: relType } = rel || {};
        const isEntityChat = !['channel', 'direct', 'group', 'live'].includes(relType);
        if (!isEntityChat) {
            const users = (data || []).map(({ username }) => username);
            addRoomUsers(rid, users).then((res) => {
                if (relType === 'channel') {
                    loadChannelMembers(rel, rid);
                } else {
                    loadRoomMembers(rel, rid);
                }
            });
        } else {
            if (!relId || !relType || isEmpty(data)) {
                return;
            }
            const userMembers = data.map(({ id, role }) => ({ userId: id, role }));
            const userIds = [];
            shareEntityWithUsers(relType, relId, userMembers, entityType).then(() => {
                data.forEach(({ id: userId, subscribe: subscribeToChat }) => {
                    if (subscribeToChat && String(profileId) !== String(userId)) {
                        userIds.push(userId);
                    }
                });
                if (!isEmpty(userIds)) {
                    subscribe(relId, relType, userIds, true).then(() => {
                        loadRoomMembers(rel, rid);
                    });
                }
            });
        }
    }

    /**
     * @override
     */
    render() {
        const { id, type, rid, rel, profile, canEdit, roles, members: chatMembers, role, entityType } = this.props;
        const { filters, searchIsActive, searchValue, sortAnchorEl, shareAnchorEl, openModal, openShareModal, shareModalType, selectedSidebar } = this.state;
        const { id: relId, type: relType } = rel || {};
        const parsedType = relType !== 'user' ? relType : 'person';
        const isEntityChat = !['channel', 'direct', 'group', 'live'].includes(parsedType || type);
        const isOwner = roles.some((r) => {
            const { u, roles: userRoles } = r;
            const { primary } = profile;
            return u.username === primary.username && userRoles.includes('owner');
        });
        const editor = !isEntityChat ? isOwner : canEdit;
        const members = selectedSidebar !== 'share' ? this.filter(chatMembers, searchValue) : [];
        const chatMemberIds = (chatMembers || []).map(({ rel }) => ({ id: rel.id}));
        const typeOptions = sharingMenu(editor, rel && rid);

        return (
            <>
                <ContentArea>
                    <HeaderBar>
                        <Divider />
                        {searchIsActive ? (
                            <SearchWrapper>
                                <Tooltip title='Close search' aria-label='close-search'>
                                    <IconButton onClick={this.toggleSearch}>
                                        <Icon name='magnify'/>
                                    </IconButton>
                                </Tooltip>
                                <StyledTextField
                                    onChange={this.onSearch}
                                    value={searchValue}
                                    variant='standard'
                                    margin='none'
                                    placeholder='Search...'
                                    InputProps={{ disableUnderline: true }}
                                />
                            </SearchWrapper>
                        ) : (
                            <HeaderActions isEntityChat={isEntityChat && !isEmpty(rid)} >
                                {(isEntityChat && !searchIsActive && (rel && rid)) && (relType !== 'team') ? (
                                    <StyledSelect
                                        name={'selectedSidebar'}
                                        value={selectedSidebar}
                                        onChange={this.onChangeSidebar}
                                        disableUnderline
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem value={'share'}>Shared with</MenuItem>
                                        <MenuItem value={'subscribers'}>Subscribers</MenuItem>
                                    </StyledSelect>
                                ): (<div></div>)}
                                <div>
                                    {relType !== 'team' && (
                                        <>
                                            {((editor && selectedSidebar !== 'share') || (editor && !isEntityChat)) && (
                                                <Tooltip title={`Add ${isEntityChat ? 'subscribers': 'members'}`} aria-label='add-subscriber'>
                                                    <IconButton onClick={() => this.setState({ openModal: true })}>
                                                        <Icon name='plus' size='md' />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                            {selectedSidebar === 'share' && (
                                                <Tooltip title={'Share'} aria-label='share'>
                                                    <IconButton onClick={this.openShare}>
                                                        <Icon name='plus' size='md' />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        </>
                                    )}
                                    <Tooltip title='Search' aria-label='search'>
                                        <IconButton onClick={this.toggleSearch}>
                                            <Icon name='magnify' size='md' />
                                        </IconButton>
                                    </Tooltip>
                                    {selectedSidebar !== 'share' && (
                                        <Tooltip title='Sorting' aria-label='sorting'>
                                            <IconButton onClick={this.openSortSetting}>
                                                <Icon name='tune' size='md' />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    <StyledNestedMenu
                                        items={ChatMembersFilter(filters)}
                                        open={Boolean(sortAnchorEl)}
                                        onItemClick={this.onFilterClick}
                                        anchorEl={sortAnchorEl}
                                        onClose={this.closeSortSetting}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left'
                                        }}
                                    />
                                    <StyledNestedMenu
                                        items={typeOptions}
                                        open={Boolean(shareAnchorEl)}
                                        onItemClick={this.onShareClick}
                                        anchorEl={shareAnchorEl}
                                        onClose={this.closeShare}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left'
                                        }}
                                    />
                                </div>
                            </HeaderActions>
                        )}
                        <Divider />
                    </HeaderBar>
                    <Container width='1024' style={{ height: '100%' }}>
                        {selectedSidebar !== 'share' && (
                            <>
                                {(members.length > 0 ? (
                                    this.getItems(members, rid, rel, this.unsubscribe, filters, editor)
                                ) : (
                                    <div style={{ textAlign: 'center' }}>No Team Members</div>
                                ))}
                            </>
                        )}
                        {selectedSidebar === 'share' && (
                            <NewEntitySharing
                                id={relId || id}
                                type={type || relType}
                                entityType={entityType}
                                role={role}
                                rel={rel}
                                rid={rid}
                                onAddClick={this.onShareClick}
                                openModal={openShareModal}
                                modalType={shareModalType}
                                searchValue={searchValue}
                                resetModalValue={() => {this.setState({ openShareModal: false, shareModalType: '' });}}
                                resetSearchValue={() => {this.setState({ searchValue: '' });}}
                            />
                        )}
                    </Container>
                </ContentArea>
                <AddNewResourcesModal
                    modalTitle={`Add ${isEntityChat ? 'subscribers': 'members'}`}
                    type={'user'}
                    isOpen={openModal}
                    isBulkSelect={true}
                    showRoles={!isEntityChat ? false : true}
                    addedResourcesList={chatMemberIds}
                    onSubmit={this.handleOnSubmit}
                    closeModal={() => this.setState({ openModal: false })}
                />
            </>
        );
    }
}

export default connect(
    state => ({
        profile: state.user.profile,
        members: state.chat.room.members,
        roles: state.chat.room.roles,
        isLoading: state.chat.room.isLoadingMembers,
        preferences: state.user.preferences,
        location: state.routing.location
    }),
    {
        loadRoomMembers,
        loadChannelMembers,
        unsubscribeEntityChat,
        unsubscribeGroupChat,
        unsubscribeChannelChat,
        addRoomUsers,
        loadGroupRoles,
        loadChannelRoles,
        closeSidebarAction,
        saveUserPreferences,
        kickFromChannelChat,
        kickFromGroupChat,
        subscribe,
        shareEntityWithUsers,
        setTitle
    }
)(ChatSharing);
