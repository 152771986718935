/* @flow */

// $FlowFixMe
import React, { PureComponent } from 'react';
import { InputAdornment, IconButton, TextField, Typography, Dialog, DialogTitle, DialogActions, DialogContent, Button, MdiIcon } from '@mic3/platform-ui';
import memoizeOne from 'memoize-one';
import styled from 'styled-components';

import { isDefined, getType, valueToType } from 'app/utils/utils';
import Forest from 'app/utils/dataStructure/Forest';
import uuidv1 from 'uuid/v1';
import ArrayValuesPickerDnd from './ArrayValuesPickerDnd';

const TypographyPadded = styled(Typography)`
    padding: 12px 0 0 0;
`;

class ArrayValuesPicker extends PureComponent<Object, Object> {

    state: Object
    uuid: string = uuidv1();

    constructor(props: Object) {
        super(props);
        const value = props.value || [];
        const denormalizedValues = this.deNormalizeValues(value || []);
        this.state = {
            activeSave: false,
            showModal: false,
            value: this.forest(denormalizedValues).nodes,
            fieldType: getType(value[0]),
        };
    }

    componentDidUpdate(prevProps: Object) {
        const { value } = this.props;
        if (prevProps.value !== value) {
            const denormalizedValues = this.deNormalizeValues(value || []);
            this.setState({ value: this.forest(denormalizedValues).nodes, fieldType: getType(value[0]) });
        }
    }

    deNormalizeValues = (value: Array<any>): Array<Object> => {
        return value.map(val => ({ value: val, type: 'child' }));
    }

    normalizeValues = (value: Array<Object>): Array<any> => {
        return value.map(val => val.value);
    }

    buildFormula = memoizeOne((value: Array<Object>) => {
        let formula = '[';
        value.forEach((val, index) => {
            const valueType = getType(val.value);
            const quote = !['boolean', 'number'].includes(valueType) ? `'` : '';

            let body = `${quote}${val.value}${quote}`;
            if(index > 0) {
                body = `, ${quote}${val.value}${quote}`;
            }
            formula = formula + String(body);
        });
        return formula + ']';
    })

    toggleModal = (e: ?Object) => {
        if(e) {
            e.stopPropagation();
        }
        this.setState(state => ({ showModal: !state.showModal}));
    }

    _forest = memoizeOne((value: Array<Object>) => {
        return new Forest(value, this.uuid);
    });

    forest = (value: ?Array<Object>) => {
        return this._forest(value || this.state.value);
    };

    onDrop = (item: Object, parentUuid: string, index: number) => {
        this.setState({ value: this.forest().move(item, parentUuid, index).nodes });
    }

    deleteValue = (value: Object) => {
        this.setState({ value: this.forest().remove(value).nodes });
    }

    addValue = (value: Object, parentUuid: string, index: number) => {
        this.setState({ value: this.forest().add(value, parentUuid, index).nodes });
    }

    handleOnChange = (e: Object) => {
        const { name: valueUuid, type: fieldType, value }= e.target;
        const valueElement = this.forest().get(valueUuid);

        const nextValue = {
            ...valueElement,
            value,
        };

        if(fieldType) {
            nextValue.fieldType = fieldType;
        }

        let updatedValues = this.forest().update(nextValue).nodes;
        if(fieldType !== this.state.fieldType) {
            updatedValues = updatedValues.map(val => ({
                ...val,
                value: valueToType(val.value, fieldType),
            }));
        }

        this.setState({ value: updatedValues, fieldType }, this.validation);
    }

    validation = () => {
        const { value } = this.state;
        this.setState({ activeSave: this.isValidValues(value) });
    }

    isValidValues = (value: Array<Object>) => {
        let isValid = true;
        value.forEach((val) => {
            if(!isValid) {
                return;
            }
            if(!isDefined(val.value)) {
                isValid = false;
            }
        });
        return isValid;
    }

    onSave = () => {
        const { value, fieldType } = this.state;
        const { name, onChange, changeVariable } = this.props;
        const normalizedValue = this.normalizeValues(value);

        this.toggleModal();
        const nextTarget = { value: normalizedValue, name, type: fieldType };
        if(onChange) {
            onChange({ target: nextTarget});
        }
        if(changeVariable) {
            changeVariable(nextTarget);
        }
    }

    render() {
        const { showModal, value, activeSave, fieldType } = this.state;
        const { label } = this.props;
        return (
            <>
                <TextField
                    error={value.length === 0}
                    onClick={this.toggleModal}
                    label={label}
                    clearable={false}
                    variant="outlined"
                    value={this.buildFormula(value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={this.toggleModal}>
                                    <MdiIcon name="code-array" />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <Dialog
                    open={showModal}
                    onClose={this.toggleModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Array values builder</DialogTitle>
                    <DialogContent>
                        <Typography variant="caption">Formula</Typography>
                        <Typography variant="h6">{this.buildFormula(value)}</Typography>
                        <TypographyPadded variant="caption">Dragable list</TypographyPadded>
                        <ArrayValuesPickerDnd
                            uuid={this.uuid}
                            onDrop={this.onDrop}
                            addValue={this.addValue}
                            deleteValue={this.deleteValue}
                            value={value}
                            onChange={this.handleOnChange}
                            fieldType={fieldType}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.toggleModal} color="primary" variant="text">
                      close
                        </Button>
                        <Button disabled={!activeSave} onClick={this.onSave} color="primary" autoFocus>
                      Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
};

export default ArrayValuesPicker;
