/* @flow */
import React, { PureComponent } from 'react';
import { CircularProgress } from '@mic3/platform-ui';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import produce from 'immer';

import VirtualListManaged from 'app/components/molecules/VirtualList/VirtualListManaged';
import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import Button from 'app/components/atoms/Button/Button';
import Icon from 'app/components/atoms/Icon/Icon';
import { loadResourceList, clearResourcesList } from 'store/actions/admin/workspacesActions';
import { bind } from 'app/utils/decorators/decoratorUtils';
import Immutable from 'app/utils/immutable/Immutable';
import { focusInput, isEmpty } from 'app/utils/utils';

import {
    StyledTextField,
    StyledBox,
    SearchWrapper,
    StyledDivider,
    StyledButton,
    ResourcesList,
    StyledAvatarOverlay,
    StyledAvatar,
    ListItemStyled,
} from 'app/containers/Common/AddEntityByPrimaryClassModal';

const inputProps: Object = { disableUnderline: true };

class AddEventsByEventTypeModal extends PureComponent<Object, Object> {
    static propTypes = {
        isOpen: PropTypes.bool.isRequired,
        isBulkSelect: PropTypes.bool,
        isLoading: PropTypes.bool,
        selectedItems: PropTypes.array,
        startIndex: PropTypes.number,
        records: PropTypes.array,
        totalRecords: PropTypes.number,
        loadResourceList: PropTypes.func.isRequired,
        closeModal: PropTypes.func.isRequired,
        openRelationDefModal: PropTypes.func.isRequired,
        addedResourcesList: PropTypes.array,
        submitBtnLabel: PropTypes.string,
        filterBy: PropTypes.array,
        orderBy: PropTypes.array,
    };

    state = Immutable({
        search: '',
        filterBy: [],
        orderBy: [],
        selectedItem: {},
        selectedItems: [],
        isBulkSelect: false, // For loading of event types bulk select would be disabled
        type: 'eventtype',
        searchBy: '',
    });

    defaultState: Object = { ...this.state }; // preserve the initial state in a new object.

    virtualListRef = React.createRef();

    componentDidUpdate(prevProps) {
        const { isOpen, selectedItems } = this.props;
        if (prevProps.isOpen !== isOpen && isOpen && !isEmpty(selectedItems)) {
            this.setState({ selectedItems });
        }
    }

    @bind
    renderComponent({ style, index, data }: { style: Object, index: number, data: Object }) {
        const { id, name: title, device, time } = data;
        const { showRoles } = this.props;
        const { selectedItem, isBulkSelect } = this.state;
        const selected = !isBulkSelect ? selectedItem.id === id : !isEmpty(this.getSelectedListItem(id));
        const subTitle = `#${id}`;
        const name = title || device?.name || 'No name';
        const selectedData = { id, name, time, device };
        return (
            <div style={style} key={index}>
                <ListItemStyled
                    component={
                        <div>
                            <StyledAvatar selected={selected} initials={name} />
                            <StyledAvatarOverlay selected={selected} showRoles={showRoles} isTypeIcon={false} />
                        </div>
                    }
                    title={name}
                    subTitle={subTitle}
                    raised
                    onClick={(e) => this.onSelectListItem(e, selectedData)}
                    selected={selected}
                />
            </div>
        );
    }

    @bind
    onSelectListItem(event: Object, data: Object) {
        const { selectedItem, isBulkSelect } = this.state;
        event.preventDefault();
        if (!isBulkSelect) {
            const selectedValue = !isEmpty(selectedItem) && selectedItem.id === data.id ? {} : data;
            this.setState({ selectedItem: selectedValue });
            this.updateList();
        } else {
            const selectedItem = this.getSelectedListItem(data.id);
            if (isEmpty(selectedItem)) {
                this.setState(
                    produce((draft) => {
                        draft.selectedItems.push(data);
                    }),
                    this.updateList
                );
            } else {
                this.setState(
                    (prevState) => ({
                        selectedItems: prevState.selectedItems.filter(({ id }) => id !== data.id),
                    }),
                    this.updateList
                );
            }
        }
    }

    @bind
    getSelectedListItem(itemId: number) {
        if (!itemId) {
            return {};
        }
        const { selectedItems } = this.state;
        return selectedItems.find(({ id }) => id === itemId) || {};
    }

    @bind
    updateList() {
        if (this.virtualListRef && this.virtualListRef.current) {
            this.virtualListRef.current.forceUpdate();
        }
    }

    @bind
    async onSubmit() {
        const { selectedItem, selectedItems, isBulkSelect } = this.state;
        const selectedValue = !isBulkSelect ? selectedItem : selectedItems;
        await this.props.onSubmit(selectedValue);
        this.props.closeModal();
    }

    @bind
    onChangeSearch(e: Object) {
        if (e.persist) {
            e.persist();
        }
        this.setState({ search: e.target.value }, () => {
            const { search, filterBy: stateFilterBy, searchBy } = this.state;
            const value = search ? search.trim() : '';
            const filters = !isEmpty(stateFilterBy) ? stateFilterBy.filter(({ field, op }) => field !== 'name' && op !== 'contains') : [];
            const filterBy = !isEmpty(filters) ? filters : [];
            if (searchBy && Array.isArray(searchBy)) {
                const fields = searchBy.map((field) => ({ field, op: 'contains', value }));
                filterBy.push({ or: fields });
            } else {
                filterBy.push({ field: searchBy || 'name', op: 'contains', value });
            }
            this.setState({ filterBy });
        });
    }

    @bind
    loadData(opts: Object) {
        const { type } = this.state;
        const { addedResourcesList } = this.props;
        const options = { ...opts };

        if (!isEmpty(addedResourcesList)) {
            const addedResourcesListIdsList = addedResourcesList.map(({ id }) => id);
            options.filterBy = [...options.filterBy, { field: 'id', op: 'not in', value: addedResourcesListIdsList }];
        }
        return this.props.loadResourceList(options, type);
    }

    @bind
    backToRelDef() {
        this.props.clearResourcesList();
        this.setState(this.defaultState, this.props.openRelationDefModal);
    }

    @bind
    async backToEventTypes() {
        const { clearResourcesList, loadResourceList } = this.props;
        await clearResourcesList();
        this.setState(this.defaultState, async () => {
            const options = { filterBy: [], orderBy: [] };
            await loadResourceList(options, 'eventtype');
        });
    }

    @bind
    async onClickNext() {
        const { isBulkSelect } = this.props;
        const { selectedItem } = this.state;
        const filterBy = [{ field: 'eventType.id', op: '=', value: selectedItem.id }];
        const orderBy = [{ field: 'time', direction: 'desc nulls last' }];
        this.setState({
            ...this.defaultState,
            filterBy,
            orderBy,
            isBulkSelect: !!isBulkSelect,
            type: 'event',
            searchBy: 'device.name',
        });
    }

    render() {
        const { isOpen, isMobileView, isLoading, records, startIndex, totalRecords, submitBtnLabel, closeIcon } = this.props;
        const { selectedItem, selectedItems, filterBy, orderBy, search, isBulkSelect, type } = this.state;
        const selectData = isBulkSelect ? selectedItems : selectedItem;
        const loadEventTypes = type === 'eventtype';
        return (
            isOpen && (
                <ModalDialog
                    bgcolor={'rgba(40, 51, 75, 1)'}
                    dialogProps={{ overflow: 'hidden' }}
                    withoutClose={isMobileView}
                    onClose={loadEventTypes ? this.backToRelDef : this.backToEventTypes}
                    closeIcon={loadEventTypes ? closeIcon || 'close' : 'arrow-left'}
                    title={'Find event by event type'}
                    actions={
                        isLoading ? (
                            <CircularProgress size={24} color='primary' />
                        ) : (
                            <>
                                {!isMobileView && <StyledButton onClick={this.props.closeModal}>Cancel</StyledButton>}
                                {loadEventTypes ? (
                                    <StyledButton disabled={isEmpty(selectData)} onClick={this.onClickNext}>
                                        Next
                                    </StyledButton>
                                ) : (
                                    <Button disabled={isEmpty(selectData)} color='primary' onClick={this.onSubmit}>
                                        {submitBtnLabel || 'Add'}
                                    </Button>
                                )}
                            </>
                        )
                    }
                >
                    <StyledDivider />
                    <SearchWrapper>
                        <StyledBox>
                            <Icon name='magnify' />
                            <StyledTextField
                                variant='standard'
                                onChange={this.onChangeSearch}
                                value={search}
                                fullWidth
                                margin='none'
                                placeholder={`Search ${loadEventTypes ? 'event types' : 'events'}...`}
                                InputProps={inputProps}
                                inputRef={ref => !isMobileView && focusInput(ref)}
                            />
                        </StyledBox>
                    </SearchWrapper>
                    <StyledDivider />
                    <ResourcesList>
                        <VirtualListManaged
                            ref={this.virtualListRef}
                            renderComponent={this.renderComponent}
                            itemSize={84}
                            title={`${totalRecords >= 1000 ? '999+' : totalRecords} ${loadEventTypes ? 'event types' : 'events'}`}
                            itemCount={totalRecords || 0}
                            loadData={this.loadData}
                            isLoading={isLoading}
                            startIndex={startIndex}
                            filterBy={filterBy}
                            orderBy={orderBy}
                            list={records}
                            maxWidth='1024'
                        />
                    </ResourcesList>
                </ModalDialog>
            )
        );
    }
}

export default connect(
    (state, ownProps) => ({
        records: state.admin.workspaces.resourceList.records,
        isLoading: state.admin.workspaces.resourceList.isLoading,
        startIndex: state.admin.workspaces.resourceList.startIndex,
        totalRecords: state.admin.workspaces.resourceList.count,
        isMobileView: state.global.isMobile,
    }),
    {
        loadResourceList,
        clearResourcesList,
    }
)(AddEventsByEventTypeModal);
