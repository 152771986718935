// @flow

import { gql } from '@apollo/client';

export default gql`
query taskGeotagButtonQuery($id: ID!) {
  result: task(id: $id) {
    id
    name
    _geoJson
  }
}
`;
