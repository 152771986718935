import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import Immutable from 'app/utils/immutable/Immutable';
import { bind } from 'app/utils/decorators/decoratorUtils';

import PageTemplate from 'app/components/templates/PageTemplate';
import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import RelatedEntityGetStarted from 'app/components/RelatedEntity/RelatedEntityGetStarted';
import { OpenLeftSidebarButton } from 'app/utils/entity/entityUtils';
import AddTreeTemplate from './AddTreeTemplate';
import { modulesAndPageTitles } from 'app/config/typesConfig';
import { setDocumentTitle } from 'store/actions/app/appActions';

class RelatedEntities extends PureComponent {
    state = Immutable({
        isAddModalOpen: false,
    });

    componentDidMount() {
        this.props.setDocumentTitle(modulesAndPageTitles.entities.relatedEntities);
    }
    
    @bind
    toggleAddModal() {
        this.setState(state => ({ isAddModalOpen: !state.isAddModalOpen }));
    }

    render() {
        return (
            <>
                <PageTemplate title="Related Entities">
                    <OpenLeftSidebarButton />
                    <ContentArea>
                        <RelatedEntityGetStarted large onClickCreate={this.toggleAddModal} />
                    </ContentArea>
                </PageTemplate>
                {this.state.isAddModalOpen && <AddTreeTemplate onClose={this.toggleAddModal} />}
            </>
        );
    }
}

export default connect(null, { setDocumentTitle })(RelatedEntities);
