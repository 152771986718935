/* @flow */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import produce from 'immer';
import { TextField, Avatar, CircularProgress, Button } from '@mic3/platform-ui';

import { isEmpty, getStr, focusInput, } from 'app/utils/utils';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import VirtualListManaged from 'app/components/molecules/VirtualList/VirtualListManaged';
import { loadClassificationsList, clearClassesList } from 'store/actions/entities/entitiesActions';
import { loadClassAncestors } from 'store/actions/classifications/classificationsActions';
import ListItem from 'app/components/molecules/List/ListItem';
import { deepEquals } from 'app/utils/utils';
import { removeDuplicates } from 'app/utils/array/array-utils';
import { groupFields } from 'app/utils/classification/classificationForm';
import { filterRequiredDefinitions } from 'app/containers/Entities/EntitiesList/AddEntity';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import Icon from 'app/components/atoms/Icon/Icon';

const inputProps: Object = { disableUnderline: true };

const ListItemStyled = styled(ListItem)`
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    cursor: pointer;
    background: ${({ selected, theme }) => (selected ? theme.material.colors.background.active : theme.material.colors.background.default)};

    @media (max-width: 1100px) {
        padding-right: 2rem;
    }
`;

const AddClassificationSearch = styled(TextField)`
    margin-bottom: 12px !important;
    .MuiFilledInput-input {
        padding: 10px 12px !important;
    }
`;


const StyledAvatar = styled(Avatar)`
    &.MuiAvatar-colorDefault {
        background-color: ${({ color }) => (color ? color : 'gray')} !important;
    }
`;

const StyledIcon = styled(Icon)`
    margin: 0px 8px;
`;

const ClassificationsList = styled.div`
    div[class*='Container__ContainerStyle'] {
        background-color: transparent;
        margin-bottom: 0;
    }
    div[class*='VirtualList__TinyVirtual'] {
        padding-top: 0 !important;
        margin-top: 0.2em;
    }
    height: ${isMobile ? '60vh' : '50vh'} !important;
    padding-bottom: 36px;
`;



class AddClassificationModal extends PureComponent<Object, Object> {
    static propTypes = {
        closeModal: PropTypes.func.isRequired,
        entityType: PropTypes.string.isRequired,
        isOpen: PropTypes.bool,
        isLoading: PropTypes.bool,
        startIndex: PropTypes.number,
        records: PropTypes.array,
        extraRecords: PropTypes.array,
        filterBy: PropTypes.array,
        excluded: PropTypes.array,
        exclude: PropTypes.bool,
        excludedField: PropTypes.string,
        excludeAbstract: PropTypes.bool,
        totalRecords: PropTypes.number,
        withParents: PropTypes.bool,
        withAttributes: PropTypes.bool,
        loadClassificationsList: PropTypes.func.isRequired
    };

    defaultState: Object = {};
    virtualListRef = React.createRef();
    formRef = React.createRef();

    constructor(props: Object) {
        super(props);
        let selectedClasses = props.selectedClasses || [];
        selectedClasses = props.withAttributes ? selectedClasses.map(({ formDefinition, ...rest }) => rest) : selectedClasses; // Preventing the re-addition of already added classes attributes
        this.state = {
            search: '',
            filterBy: [],
            orderBy: [],
            selectedClasses,
            showAttributes: !props.withAttributes,
            components: [],
            loadingAttributes: false // Show loader when attributes of classes and their ancestors are being fetched
        };
        // preserve the initial state in a new object.
        this.defaultState = { ...this.state };
        props.isEntityType && props.loadClassificationsList({}, this.props.entityType);
    }

    componentDidUpdate(prevProps) {
        const { selectedClasses, withAttributes } = this.props;
        if (!deepEquals(selectedClasses, prevProps.selectedClasses)) {        
            this.setState({ selectedClasses }, async () => {
                if (withAttributes) {
                    const components = await this.buildComponents(this.state.selectedClasses);
                    this.setState({ components });
                }
            });
        }
    }

    @bind
    renderComponent({ style, index, data }: { style: Object, index: number, data: Object }) {
        const { id, name, color, icon, iconType } = data;
        const { selectedClassesPath } = this.props;
        const selectedClass = this.getSelectedClass(data[selectedClassesPath || 'id']);
        return (
            <div style={style} key={index}>
                <ListItemStyled
                    component={ !icon ? 
                        <StyledAvatar initials={name} color={color} /> 
                        : <StyledIcon name={icon} type={iconType || 'mdi'} hexColor={color} />
                    }
                    title={name || 'No Name'}
                    subTitle={id}
                    raised
                    onClick={e => this.onSelectClassification(e, data)}
                    selected={!isEmpty(selectedClass)}
                />
            </div>
        );
    }

    @bind
    onSelectClassification(event: Object, data: Object) {
        event.preventDefault();
        const { selectedClassesPath } = this.props;
        const selectedClass = this.getSelectedClass(data[selectedClassesPath || 'id']);
        if (isEmpty(selectedClass)) {
            this.setState(
                produce((draft) => {
                    draft.selectedClasses.push(data);
                }),
                this.updateList
            );
        } else {
            this.setState(
                prevState => ({
                    selectedClasses: prevState.selectedClasses.filter((cl) => {
                        return cl[selectedClassesPath || 'id'] !== data[selectedClassesPath || 'id'];
                    })
                }),
                this.updateList
            );
        }
    }

    @bind
    getSelectedClass(classId: number) {
        if (!classId) {
            return {};
        }
        const { selectedClasses } = this.state;
        const { selectedClassesPath } = this.props;
        return (
            selectedClasses.find((cl) => {
                return cl[selectedClassesPath || 'id'] === classId;
            }) || {}
        );
    }

    @bind
    updateList() {
        if (this.virtualListRef && this.virtualListRef.current) {
            this.virtualListRef.current.forceUpdate();
        }
    }

    @bind
    onSubmit() {
        const { withAttributes, isEntityType } = this.props;
        if (withAttributes) {
            this.formRef.current.isValidForm().then(({ data, errors }) => {
                if (!errors) {
                    this.props.onSubmit(this.state.selectedClasses, data, isEntityType);
                    this.closeModal();
                }
            });
            return;
        }
        this.props.onSubmit(this.state.selectedClasses);
        this.closeModal();
    }

    @bind
    onChangeSearch(e: Object) {
        if (e.persist) {
            e.persist();
        }
        this.setState({ search: e.target.value }, () => {
            const { search } = this.state;
            const searchFilter = { field: 'name', op: 'contains', value: (search && search) || '' };

            this.setState({
                filterBy: [searchFilter]
            });
        });
    }

    @bind
    loadData(optionsProp: Object) {
        const { selectedClasses, exclude, excluded, excludedField, filterBy, withParents, excludeAbstract, entityType } = this.props;
        if (entityType === 'entityType') {
            return Promise.resolve();
        }
        
        const options = { ...optionsProp, filterBy: [...(optionsProp.filterBy || [])] };
        let excludedList = [...(excluded || [])];
        if (exclude && !isEmpty(selectedClasses)) {
            excludedList = [...selectedClasses.map(({ id }) => id), ...excludedList].filter(Boolean);
        }
        if (excludedList.length) {
            options.filterBy.push({ field: excludedField || 'id', op: 'not in', value: excludedList });
        }
        if (filterBy?.length) {
            options.filterBy.push(...filterBy);
        }
        if(!options.filterBy) {
            options.filterBy = [];
        }
        if(excludeAbstract) {
            options.filterBy.push({ field: 'abstract', op: '=', value: false });
        }
        return this.props.loadClassificationsList(options, this.props.entityType, withParents);
    }

    @bind
    closeModal() {
        this.props.clearClassesList();
        this.setState({...this.defaultState}, this.props.closeModal);
    }

    @bind
    buildeRecordsAndCount(records, totalRecords, extraRecords, search) {
        const { startIndex } = this.props;
        let extra = extraRecords || [];
        if (search && extra.length) {
            extra = extra.filter(clss => clss.name.toLowerCase().includes(search.toLowerCase()) || clss.uri.toLowerCase().includes(search.toLowerCase()));
        }
        const allRecords = !startIndex ? [...extra, ...records] : records; // push extra records only at start otherwise they will get duplicated with every scroll
        return [allRecords, totalRecords + (extra || []).length];
    }

    @bind
    onClickNext() {
        this.setState({ loadingAttributes: true }, async () => {
            const components = await this.buildComponents(this.state.selectedClasses);
            this.setState({ showAttributes: true, components, loadingAttributes: false }, () => {
                if (!components?.length) {
                    this.onSubmit();
                }
            });
        });
    }

    @bind
    @memoize()
    async buildComponents(selectedClasses) {
        const { loadClassAncestors } = this.props;
        if (!selectedClasses?.length) {
            return [];
        }
        const classes = [];
        const incestorsPromises = selectedClasses.filter(cls => cls.id).map(cls => loadClassAncestors({ id: cls.id, onlyActive: true }, true));
        const incestorsClasses = await Promise.all(incestorsPromises);
        const uniqueClasses = removeDuplicates([...selectedClasses, ...incestorsClasses.flat()]);
        uniqueClasses.forEach(cls => {
            const formComponents = [...filterRequiredDefinitions(cls?.formDefinition?.fields)];
            if(formComponents.length){ 
                classes.push({ ...cls, formComponents, });
            }
        });
        const groupedClasses = classes.map(cls => groupFields(cls.formComponents, { classData: cls })).flat();
        return groupedClasses;
    }

    @bind
    searchForFrom(searchQuery, data) {
        if (!data || !searchQuery) return false;
        return ['name', 'uri', 'id'].some((field) => {
            let value = getStr(data, field, '');
            if (!value) return false;
            value = value.toLowerCase();
            const query = searchQuery.toLowerCase();
            return value.includes(query);
        });
    }

    @bind
    excludeSelected(records){
        const { excluded, selectedClasses, selectedClassesPath } = this.props;
        if (excluded && !isEmpty(selectedClasses)) {
            const excludedIds = [...selectedClasses.map(cl => cl[selectedClassesPath || 'id']), ...(excluded || [])].filter(Boolean);
            return (records || []).filter(cls => !excludedIds?.includes(cls[selectedClassesPath || 'id']));
        }
        return records;
    }

    @bind
    filterSearchedData(searchQuery: string, list) {
        const records = this.excludeSelected(list);
        return searchQuery ? (records || []).filter(item => this.searchForFrom(searchQuery, item)) : records || [];
    }

    render() {
        const { isOpen, isLoading, records, startIndex, totalRecords, title, extraRecords, isMobileView, withAttributes, entityType, context } = this.props;
        const { selectedClasses, filterBy, orderBy, search, showAttributes, components, loadingAttributes } = this.state;
        const [list, count] = this.buildeRecordsAndCount(records, totalRecords, extraRecords, search);
        const filteredRecords = entityType === 'entityType' ? this.filterSearchedData(search, list) : list;
        const total = entityType === 'entityType' ? filteredRecords?.length : totalRecords || count;
        return (
            isOpen && (
                <ModalDialog
                    title={title}
                    onClose={this.closeModal}
                    dialogProps={{ overflow: 'hidden' }}
                    actions={
                        <>
                            {!isMobileView && <Button onClick={this.closeModal} variant="text" size="small" >Cancel</Button>}
                            {!showAttributes ? (
                                <Button disabled={isEmpty(selectedClasses)}  onClick={this.onClickNext} size="small">
                                    {loadingAttributes ? <CircularProgress size={14} /> : 'Next'}
                                </Button>
                            ) : (
                                <Button disabled={isEmpty(selectedClasses)}  onClick={this.onSubmit} size="small">
                                    Add
                                </Button>
                            )}
                        </>
                    }
                >
                    {withAttributes && showAttributes ? (
                        <>
                            <FormGenerator components={components} ref={this.formRef} context={context} />
                        </>
                    ) : (
                        <>
                            <AddClassificationSearch
                                onChange={this.onChangeSearch}
                                value={search}
                                fullWidth
                                margin="none"
                                placeholder="Search..."
                                InputProps={inputProps}
                                inputRef={ref => !isMobileView && focusInput(ref)}
                            />
                            <ClassificationsList>
                                <VirtualListManaged
                                    ref={this.virtualListRef}
                                    renderComponent={this.renderComponent}
                                    itemSize={84}
                                    title={`${total >= 1000 ? '999+' : total} Classes`}
                                    itemCount={total}
                                    loadData={this.loadData}
                                    isLoading={isLoading}
                                    startIndex={startIndex || 0}
                                    filterBy={filterBy}
                                    orderBy={orderBy}
                                    list={filteredRecords}
                                    maxWidth="1024"
                                />
                            </ClassificationsList>
                        </>
                    )}
                </ModalDialog>
            )
        );
    }
}

export default connect(
    (state, ownProps) => {
        const { entityType, filterAbstract, title } = ownProps || {};
        const isEntityType = entityType === 'entityType';
        let _records = [...state.entities.classesList.records];
        if(isEntityType && filterAbstract && title === 'Applies to'){
            _records = _records.filter(r => !r?.abstract);
        }
        return ({
            isLoading: state.entities.classesList.isLoading,
            records: _records,
            startIndex: isEntityType ? 0 : state.entities.classesList.startIndex,
            totalRecords: state.entities.classesList.count,
            isMobileView: state.global.isMobile,
            isEntityType
        });
    },
    {
        loadClassificationsList,
        clearClassesList,
        loadClassAncestors
    }
)(AddClassificationModal);
