import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Autocomplete } from '@mic3/platform-ui';

import { bind, debounce } from 'app/utils/decorators/decoratorUtils';
import { isEmpty, shallowEquals } from 'app/utils/utils';
import { loadVRelationDefinitionAutocomplete } from 'store/actions/entities/relationshipsActions';
import { reverseDef } from 'app/components/organisms/Form/Typeahead/RelationDefinitionTypeahead';

/**
 * Select one or more groups using lazy loading.
 */
class RelationDefinitionsTypeahead extends PureComponent<Object, Object> {
    static propTypes = {
        ...Autocomplete.propTypes,
        value: PropTypes.any,
        loadOptions: PropTypes.func.isRequired,
        fromType: PropTypes.string,
        toType: PropTypes.string,
        filterBy: PropTypes.arrayOf(PropTypes.Object),
        excludeBy: PropTypes.arrayOf(PropTypes.Object),
        orderBy: PropTypes.arrayOf(PropTypes.Object),
        isLoading: PropTypes.bool,
        options: PropTypes.arrayOf(PropTypes.object)
    };

    state = { isLoading: false, options: [], lastRequest: 0, relatedUris: [], typeUris: [] };

    componentDidUpdate(prevProps) {
        if (!shallowEquals(this.props, prevProps, ['fromType', 'toType', 'filterBy', 'excludeBy', 'orderBy'])) {
            this.setState({ isLoading: false, options: [], lastRequest: 0, relatedUris: [], typeUris: [] });
        }
    }

    @bind
    getRelationDefinitions(data) {
        const {  fromType } = this.props;
        if (isEmpty(data)) {
            return [];
        }
        return data.map((rel) => {
            const sameType = rel?.type === fromType && rel?.relatedType === fromType;
            if (sameType) { // if the type exists between the same type then we would show
                return [rel, reverseDef(rel)]; // both the direct and reverse relation definitions
            }
            const reverse = rel?.relatedType === fromType;
            return reverse ? reverseDef(rel) : rel;
        }).flat();
    }

    @bind
    @debounce()
    suggest(event: Object) {
        const { fromType } = this.props;
        const requestId = Date.now();
        this.setState({ isLoading: true, lastRequest: requestId }, async () => {
            const { value, loadOptions, filterBy, excludeBy, orderBy } = this.props;
            const filters = filterBy ? [...filterBy] : [];
            const query = event.target.value;
            if(fromType) {
                filters.push(
                    {or: [
                        { field: 'type', op: '=', value: fromType},
                        { field: 'relatedType', op: '=', value: fromType},
                    ]}
                );
            }
            if (query) {
                filters.push({
                    or: [
                        { field: 'description', op: 'contains', value: query },
                        { field: 'relatedDescription', op: 'contains', value: query }
                    ]
                });
            }
            if (value) {
                const idsToExclude = (Array.isArray(value) ? value : [value]).map(({ id }) => id);
                filters.push({ field: 'id', op: 'not in', value: idsToExclude });
            }
            loadOptions({
                filterBy: filters,
                excludeBy: excludeBy,
                orderBy: orderBy
            }, { fromType, fromUris: [fromType], query }).then((data) => {
                // if this is not the last request discard the result
                if (this.state.lastRequest === requestId) {
                    this.setState({ isLoading: false, options: this.getRelationDefinitions(data) });
                }
            });
        });
    }

    @bind
    optionTemplate(data: any) {
        const { id, description } = data;
        const label = `${description || 'Description not available'} (${id ? id.slice(0, 8) : 'Id not available'})`;
        const title = description;
        return {
            option: <span title={title}>{label}</span>,
            label,
        };
    }

    render() {
        const { loadOptions, fromType, toType, filterBy, excludeBy, orderBy, ...typeaheadProps } = this.props; // eslint-disable-line no-unused-vars
        const { isLoading, options } = this.state;
        return <Autocomplete {...typeaheadProps} optionTemplate={this.optionTemplate} suggest={this.suggest} options={options} isLoading={isLoading} />;
    }
}

export default connect(
    null,
    { loadOptions: loadVRelationDefinitionAutocomplete }
)(RelationDefinitionsTypeahead);
