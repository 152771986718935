/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { memoize } from 'app/utils/decorators/decoratorUtils';

import { BoardsContentStyled, BoardsDroppable } from './style';
import { deepEquals } from 'app/utils/utils';

class BoardsContent extends PureComponent<Object, Object> {
    static propTypes = {
        board: PropTypes.object,
        addColumnContent: PropTypes.node,
        columns: PropTypes.arrayOf(PropTypes.object),
        onDragEnd: PropTypes.func.isRequired,
        children: PropTypes.any.isRequired,
        className: PropTypes.string
    };

    static defaultProps = {
        columns: [],
        className: '',
        board: null
    };

    componentDidUpdate(prevProps: Object) {
        const { orderBy, filterBy, excludeBy, columns, loadBoardTasks, setOptions, setMaxColumnOrder } = this.props;
        const isFilterUpdated = orderBy !== prevProps.orderBy || filterBy !== prevProps.filterBy || excludeBy !== prevProps.excludeBy;

        if ((!deepEquals(columns, prevProps.columns) && columns.length > 0) || isFilterUpdated) {
            const _excludeBy = excludeBy[0];
            setOptions({ filterBy, orderBy, excludeBy: _excludeBy });

            setTimeout(() => {
                loadBoardTasks(columns, isFilterUpdated);
            }, 100);

            let order = this.getMaxColumnOrder(columns);
            order = !isNaN(order) ? order + 1 : 0;

            setMaxColumnOrder(order);
        }
    }

    /**
     * Get the highest order of all the columns
     * @param columns the columns to compute the order from
     */
    @memoize()
    getMaxColumnOrder(columns: Object[]){
        return Math.max.apply(Math, columns.map((column) =>  {
            let columnOrder = 0;

            if (column && column.attributes && column.attributes['board_label/order']) {
                columnOrder = column.attributes['board_label/order'];
            }

            return columnOrder;

        }));
    };

    render() {
        const { children, columns, onDragEnd } = this.props;

        return (
            <DragDropContext onDragEnd={onDragEnd}>
                <BoardsContentStyled>
                    <Droppable droppableId="board" type="COLUMN" direction="horizontal">
                        {(provided) => {
                            return (
                                <BoardsDroppable ref={provided.innerRef} {...provided.droppableProps}>
                                    {columns.length > 0 &&
                                        columns.map((column, index) => {
                                            return children(column, index);
                                        })}
                                    {provided.placeholder}
                                </BoardsDroppable>
                            );
                        }}
                    </Droppable>
                </BoardsContentStyled>
            </DragDropContext>
        );
    }
}

export default BoardsContent;
