/* @flow */

import Immutable from 'app/utils/immutable/Immutable';

import baseSettings from 'app/utils/designer/form/settings/metadata/baseSettings';
import jsonSettings from 'app/utils/designer/form/settings/metadata/jsonSettings';
import booleanSettings from 'app/utils/designer/form/settings/metadata/booleanSettings';
import buttonSettings from 'app/utils/designer/form/settings/metadata/buttonSettings';
import appButtonSettings from 'app/utils/designer/form/settings/metadata/appButtonSettings';
import printButtonSettings from 'app/utils/designer/form/settings/metadata/printButtonSettings';
import colorPickerSettings from 'app/utils/designer/form/settings/metadata/colorPickerSettings';
import chipSettings from 'app/utils/designer/form/settings/metadata/chipSettings';
import iconSelectSettings from 'app/utils/designer/form/settings/metadata/iconSelectSettings';
import classificationSettings from 'app/utils/designer/form/settings/metadata/classificationSettings';
import dateTimeRangeSettings from 'app/utils/designer/form/settings/metadata/dateTimeRangeSettings';
import timeSettings from 'app/utils/designer/form/settings/metadata/timeSettings';
import dateSettings from 'app/utils/designer/form/settings/metadata/dateSettings';
import dateTimeSettings from 'app/utils/designer/form/settings/metadata/dateTimeSettings';
import dropzoneSettings from 'app/utils/designer/form/settings/metadata/dropzoneSettings';
import displayTextSettings from 'app/utils/designer/form/settings/metadata/displayTextSettings';
import durationSettings from 'app/utils/designer/form/settings/metadata/durationSettings';
import entitiesTypeaheadSettings from 'app/utils/designer/form/settings/metadata/entitiesTypeaheadSettings';
import teamTypeaheadSettings from 'app/utils/designer/form/settings/metadata/teamTypeaheadSettings';
import userTypeaheadSettings from 'app/utils/designer/form/settings/metadata/userTypeaheadSettings';

import entityTypeaheadSettings from 'app/utils/designer/form/settings/metadata/entityTypeaheadSettings';
import groupRepeatSettings from 'app/utils/designer/form/settings/metadata/groupRepeatSettings';
import groupSettings from 'app/utils/designer/form/settings/metadata/groupSettings';
import headerSettings from 'app/utils/designer/form/settings/metadata/headerSettings';
import hyperlinkSettings from 'app/utils/designer/form/settings/metadata/hyperlinkSettings';
import iconSettings from 'app/utils/designer/form/settings/metadata/iconSettings';
import imageSettings from 'app/utils/designer/form/settings/metadata/imageSettings';
import inputSettings from 'app/utils/designer/form/settings/metadata/inputSettings';
import numberSettings from 'app/utils/designer/form/settings/metadata/numberSettings';
import outcomeSettings from 'app/utils/designer/form/settings/metadata/outcomeSettings';
import panelSettings from 'app/utils/designer/form/settings/metadata/panelSettings';
import relationsTypeaheadSettings from 'app/utils/designer/form/settings/metadata/relationsTypeaheadSettings';
import sliderSettings from 'app/utils/designer/form/settings/metadata/sliderSettings';
import textareaSettings from 'app/utils/designer/form/settings/metadata/textareaSettings';
import textEditorSettings from 'app/utils/designer/form/settings/metadata/textEditorSettings';
import typeaheadSettings from 'app/utils/designer/form/settings/metadata/typeaheadSettings';
import geotagPositionSettings from 'app/utils/designer/form/settings/metadata/geotagPositionSettings';
import geotagButtonSettings from 'app/utils/designer/form/settings/metadata/geotagButtonSettings';
import geotagLocationSettings from 'app/utils/designer/form/settings/metadata/geotagLocationSettings';
import geotagAddressSettings from 'app/utils/designer/form/settings/metadata/geotagAddressSettings';
import iotPanelSettings from 'app/utils/designer/form/settings/metadata/iotPanelSettings';
import uploadFileSettings from 'app/utils/designer/form/settings/metadata/uploadFileSettings';
import { get } from 'app/utils/lo/lo';

const workspaceTypeaheadSettings = {
    ...entitiesTypeaheadSettings,
    defaults: {
        ...entitiesTypeaheadSettings.defaults,
        filterBy: [{ field: 'isSystem', op: '=', value: false }]
    },
};

const updateTeamTypeaheadSettings = {
    ...teamTypeaheadSettings,
    defaults: {
        ...teamTypeaheadSettings.defaults,
        filterBy: [{ field: 'isSystem', op: '=', value: false }]
    },
};

const defaultExpanded = new Set(['Base', 'General']);

export const buildSettingsPanel = (element: Object, values: Object) => {
    const { header, children, expanded, isVisible } = element;
    if(!children) {
        return element;
    }
    const expand = (expanded === true || expanded === false) ? expanded : defaultExpanded.has(header);
    const counter = children.reduce((total, child) => {
        if (child.properties?.name && get(values, child.properties?.name)) {
            total += 1;
        }
        return total;
    }, 0);
    return {
        field: 'tinypanel',
        type: 'tinypanel',
        properties: {
            header: counter ? `${header} (${counter})` : header,
            expanded: expand,
            isVisible,
            onChange: event => null // as default it is saving the data changes
        },
        children: header === 'Base' ? [{
            type:'text',
            properties: {
                label:'Type',
                disabled: true,
                name:'type'
            }
        },...children] : children,
    };
};

const getFieldSettings = (type: string) => {
    if (!type) return null;
    switch (type) {
        case 'json': return jsonSettings;
        case 'chip': return chipSettings;
        case 'hyperlink': return hyperlinkSettings;
        case 'checkbox':
        case 'radio':
        case 'boolean': return booleanSettings;
        case 'printButton': return printButtonSettings;
        case 'openAppButton': return appButtonSettings;
        case 'button': return buttonSettings;
        case 'entityTypeahead': return entityTypeaheadSettings;
        case 'classificationTypeahead':
        case 'graphicTypeahead':
        case 'processTypeahead':
        case 'taskTypeahead':
        case 'relationDefinitionTypeahead': return entitiesTypeaheadSettings;
        case 'userTypeahead': return userTypeaheadSettings;
        case 'workspaceTypeahead': return workspaceTypeaheadSettings;
        case 'teamTypeahead': return updateTeamTypeaheadSettings;
        case 'colorPicker': return colorPickerSettings;
        case 'iconSelect': return iconSelectSettings;
        case 'classification': return classificationSettings;
        case 'date':  return dateSettings;
        case 'time': return timeSettings;
        case 'dateTime': return dateTimeSettings;
        case 'dateTimeRange': return dateTimeRangeSettings;
        case 'displayText': return displayTextSettings;
        case 'duration': return durationSettings;
        case 'group': return groupSettings;
        case 'groupRepeat': return groupRepeatSettings;
        case 'header': return headerSettings;
        case 'icon': return iconSettings;
        case 'image': return imageSettings;
        case 'number': return numberSettings;
        case 'text': return inputSettings;
        case 'outcome': return outcomeSettings;
        case 'panel': return panelSettings;
        case 'relationsTypeahead': return relationsTypeaheadSettings;
        case 'slider': return sliderSettings;
        case 'textarea': return textareaSettings;
        case 'textEditor': return textEditorSettings;
        case 'typeahead': return typeaheadSettings;
        case 'geotagAddress': return geotagAddressSettings;
        case 'geotagPosition': return geotagPositionSettings;
        case 'geotagButton': return geotagButtonSettings;
        case 'geotagLocation': return geotagLocationSettings;
        case 'dropzone': return dropzoneSettings;
        case 'iotPanel': return iotPanelSettings;
        case 'file': return uploadFileSettings;
        default: return baseSettings;
    }
};

export const getFieldSettingPanelComponents = (type: string, settingsValues: Object) => {
    const settings = getFieldSettings(type);
    let panels = settings.panels(settingsValues);
    if(settingsValues && get(settingsValues, 'properties.withDefault')) {
        const { constraints: asconstr, ...settingsValueProps } = settingsValues;
        let constraints = undefined;

        if(settingsValues?.properties?.static) {
            constraints = { required: true };
        }
        
        if(asconstr?.max || asconstr?.min){
            constraints = { 
                max: asconstr.max,
                min: asconstr.min
            };
        }
        
        if(asconstr?.maxLength || asconstr?.minLength){
            constraints = { 
                maxLength: asconstr.maxLength,
                minLength: asconstr.minLength
            };
        }
        const { isVisible, ...resProps } = settingsValueProps.properties;
        panels = [...panels, { header: 'Default value', children: [{
            ...settingsValueProps,
            properties: {
                ...resProps,
                disabled: !!settingsValues.properties.calculation,
                name: 'properties.defaultValue',
                isdesigner: true,
            },
            constraints
        }]}].filter(Boolean);
    }
    return panels.map(panel => buildSettingsPanel(panel, settingsValues));
};

export const isFieldProppertyOwned = (type: string, propertyPath: string) => {
    const settings = getFieldSettingPanelComponents(type);
    let owned = false;
    settings.forEach((s) => {
        if(!s.children) {
            if(!owned && s.properties.name === propertyPath) {
                owned = true;
            }
        } else {
            s.children.forEach((ch) => {
                if(!owned && ch.properties.name === propertyPath) {
                    owned = true;
                }
            });    
        }
    });
    return owned;
};

const defaultsMap = {};
export const getFieldDefaults = (type: string) => {
    if (!defaultsMap[type]) {
        const settings = getFieldSettings(type);
        defaultsMap[type] = get(settings, 'defaults') || {};
    }
    return defaultsMap[type];
};

const fieldDefinitionMap = {};
export const getFieldDefinition = (type) => {
    if (!fieldDefinitionMap[type]) {
        fieldDefinitionMap[type] = { type, defaults: getFieldDefaults(type) };
    }
    return fieldDefinitionMap[type];
};

export const formFieldTypes = Immutable([
    'text',
    'image',
    'chip',
    'textEditor',
    'textarea',
    'json',
    'number',
    'duration',
    'boolean',
    'icon',
    'iotPanel',
    'iconSelect',
    'slider',
    'colorPicker',
    'time',
    'date',
    'dateTime',
    'dateTimeRange',
    'outcome',
    'openAppButton',
    'button',
    'printButton',
    'group',
    'groupRepeat',
    'panel',
    'displayText',
    'header',
    'typeahead',
    'entityTypeahead',
    'graphicTypeahead',
    'userTypeahead',
    'classificationTypeahead',
    'taskTypeahead',
    'processTypeahead',
    'relationsTypeahead',
    'teamTypeahead',
    'workspaceTypeahead',
    'classification',
    'geotagPosition',
    'geotagButton',
    'geotagLocation',
    'geotagAddress',
    'dropzone',
    'hyperlink',
    'file'
]);

export const designerFormFieldTypes = formFieldTypes.filter(type => type !== 'file');
