import GeometryCollection from 'ol/geom/GeometryCollection';
import * as turf from '@turf/turf';
import Feature from 'ol/Feature';
import html2canvas from 'html2canvas';
import GeoJSON from 'ol/format/GeoJSON';
import { MAP_LAYERS, LAYERS } from 'app/utils/maps/layer/layerUtils';
import { typeMap } from 'app/config/typesConfig';

const getSource = (id, map) => {
    const foundLayer = id && map.findLayerById(id);
    return foundLayer && foundLayer.getSource && foundLayer.getSource();
};

export const deleteDrawingFromLayer = async (
    { map, id, index, allMapLayers}: Object,
    callback
) => {
    if (id && index > -1) {
        const source = getSource(id, map);

        const features =
            source &&
            source.getFeatures &&
            source.getFeatures().filter((f, i) => i !== index);
        source.clear();
        source.addFeatures(features);
        const geoJsonFeatures = features.map(
            (f) => f && JSON.parse(map.convertFeatureToGeoJson(f))
        );
        const foundLayer = allMapLayers.find((layer) => layer.id === id);
        if (!foundLayer) return;
        await map.getMap().render();
        
        const newData = { 
            id,
            name : foundLayer?.name,
            type: LAYERS.drawing ,
            'drawing_feature': JSON.stringify(geoJsonFeatures), 
            styles: { pinStyle: 'cluster' }, hidden: foundLayer?.hidden || false, opacity: foundLayer?.opacity || 1 };

        callback(newData);
    }
    return Promise.resolve();
};


export const updateDrawingLayer = async (props: Object, callback) => {
    try {
        const { map, selectedLayer, updateMapData, allMapLayers, mapId } = props;
        const { id, featureIndex } = selectedLayer || {};
        const foundLayer = allMapLayers.find(layer => layer.id === id);
        const source = getSource(id, map);
        const oldFeatures = source?.getFeatures();
        const savedLayer = id && map.findLayerById(id);
        const tempLayer = map.getLayerByTitle('Drawing Layer');
        let convertoGeoJson;
        const isErase = map.getEraserStatus();
        const tempFeatures = tempLayer?.getSource()?.getFeatures();
        if (isErase && !tempFeatures.length) {
            const savedFeatures = savedLayer?.getSource().getFeatures();
            convertoGeoJson = savedFeatures.length && savedFeatures.map(f => f && JSON.parse(map.convertFeatureToGeoJson(f)));
            return updateEntityMutationData(mapId, allMapLayers, id, foundLayer, convertoGeoJson, updateMapData); 
        } else {
            const drawingFeatures = map && map.getLayerByTitle('Drawing Layer').getSource().getFeatures();
            convertoGeoJson = drawingFeatures?.map(f => f && JSON.parse(map.convertFeatureToGeoJson(f)));
            await map.getMap().render();
            if (featureIndex > -1) {
                const twoFeatures = [drawingFeatures?.[0], oldFeatures?.[featureIndex]];
                const singleFeature = twoFeatures.length && convertToSingleFeature(twoFeatures)[0];
                const mapView = map && await map.getMapView();
                singleFeature.values_.properties = {attributes: {image: mapView.thumbnail}};
                oldFeatures[featureIndex] = singleFeature;
                convertoGeoJson = oldFeatures.length && JSON.parse(map.convertFeaturesToGeoJson(oldFeatures));
                // const updatedData = await saveScreenshot(map, mapView, savedLayer,convertoGeoJson,layerCenter, updateMapData, id);
                // await callback(updatedData);
            } else {
                const features = tempLayer.getSource().getFeatures();
                const allFeatures = [];
                const mapContainer = document.getElementById('printMap');
                for (const feature of features) { 
                    await printMap(feature);
                    await html2canvas(mapContainer).then(canvas => {
                        const dataURL = canvas.toDataURL('image/png');
                        let centerCoordinates = null;    
                        const geometry = feature.getGeometry();
                        if(feature.getGeometry().getType() === 'Point')
                            centerCoordinates =  feature?.getGeometry()?.getCoordinates();
                        else if(feature.getGeometry().getType() === 'LineString') {
                            const flatCoordinates = feature?.getGeometry()?.flatCoordinates;
                            if(flatCoordinates?.length){
                                const turfLineString = turf.lineString(
                                    flatCoordinates.reduce((result, coord, index) => {
                                        if (index % 2 === 0) {
                                            result.push([coord, flatCoordinates[index + 1]]);
                                        }
                                        return result;
                                    }, [])
                                );
                                const bbox = turf.bbox(turfLineString);
                                const lineCenter = turf.center(turf.bboxPolygon(bbox));
                                centerCoordinates = lineCenter?.geometry?.coordinates;
                            }
                        }
                        else
                            centerCoordinates = geometry?.getInteriorPoint()?.getCoordinates();
                        const extent = feature.getGeometry().getExtent();
                        feature.setProperties({ image: dataURL, layerCenter: centerCoordinates, extent });
                    });   
                    allFeatures.push(feature);
                }
                allFeatures.length && source.addFeatures(allFeatures);
                const updatedNewFeatures = source.getFeatures();
                const convertoGeoJson = updatedNewFeatures?.map(f => f && JSON.parse(map.convertFeatureToGeoJson(f)));
                if(!foundLayer) return;
                
                await updateEntityMutationData(mapId, allMapLayers, id, foundLayer, convertoGeoJson, updateMapData);
                allFeatures.length = 0;
                tempLayer.getSource().clear();
            }
        }
        return Promise.resolve();
    } catch(e) {
        console.error('error in updateDrawings ', e); //eslint-disable-line
    }
};

const updateEntityMutationData = async (mapId, allMapLayers, id, foundLayer, convertoGeoJson, updateMapData) => {
    const newData = { id, name : foundLayer?.name, type: LAYERS.drawing , 'drawing_feature': JSON.stringify(convertoGeoJson), 
        styles: { pinStyle: 'cluster' }, hidden: foundLayer?.hidden || false, opacity: foundLayer?.opacity || 100 };
    const newArray = updateLayerData(allMapLayers, id, newData);
    const layerPrimary =  { [MAP_LAYERS]: newArray };
    return updateMapData({id: mapId, primary: layerPrimary, type: typeMap['map'] }, true);
};

export const updateLayerData = (existingLayers, id, newData) => {
    if(!existingLayers?.length) return [];
    const layerIndex = existingLayers.findIndex(layer => layer.id === id);
    const eLayers = [...existingLayers];
    if (layerIndex !== -1) {
        switch(newData?.type) {
            case LAYERS.entity:
                eLayers[layerIndex] = { ...eLayers[layerIndex], ...newData, entity_type: newData?.filter_by?.entity_type };
                break;
            case LAYERS.event:
                eLayers[layerIndex] = { ...eLayers[layerIndex], ...newData, event_type: newData?.filter_by?.event_type };
                break;
            default:
                eLayers[layerIndex] = { ...eLayers[layerIndex], ...newData };
                break;
        }
    }
    return eLayers;
};

const addFeature = async (turfFeature, ctx, canvas, olFeature) => {
    const geometryType = turfFeature.geometry.type;
    switch (geometryType) {
        case 'Point':
            await drawPoint(turfFeature, ctx, canvas, olFeature);
            break;
        case 'LineString':
            await drawLineString(turfFeature, ctx, canvas);
            break;
        case 'Polygon':
            await drawPolygon(turfFeature, ctx, canvas);
            break;
        default:
            break;
    }
};

async function printMap(feature) {
    const canvas = document.getElementById('printMap');
    const ctx = canvas.getContext('2d');
    canvas.width = 500;
    canvas.height = 500;

    const features = new GeoJSON().writeFeature(feature, {
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857'
    });
    const parsedFeatures = features && JSON.parse(features);
      
    const turfFeatures = turf.feature(parsedFeatures.geometry, parsedFeatures.properties);
    await addFeature(turfFeatures, ctx, canvas, feature);
   
};

const drawPoint = (feature, ctx, canvas) => {
    const point = turf.getCoord(feature);
    const text = feature?.properties?.drawingText; 
    const fontSize = parseInt(feature?.properties?.fontSizeText) || 12; 
    const fontFamily = feature?.properties?.fontTypeText || 'Arial'; 
    ctx.font = `${fontSize}px ${fontFamily}`; 

    ctx.font = `${fontSize}px Arial`;

    const textWidth = ctx.measureText(text).width;
    const textHeight = fontSize;
    if(textWidth < 100) {
        const canvasPadding = 10; 
        canvas.width = textWidth + canvasPadding * 2;
        canvas.height = textHeight + canvasPadding * 2;

        ctx.fillStyle = feature?.properties?.strokeColor || 'red'; ;
        ctx.fillText(text, canvasPadding, canvas.height / 2 + fontSize / 2);
    } else {

    
        canvas.width = 300;
        canvas.height = 300;  
        const paddingFromTop = 30;
        ctx.fillText(text, point[0], point[1] + paddingFromTop);
    }
};
    
const drawLineString = async (feature, ctx, canvas) => {
    const lineString = turf.getCoords(feature);
    const canvasCoordinates = lineString.map(coord => convertToCanvasCoordinates(coord, canvas, feature, false));
  
    ctx.strokeStyle = feature?.properties?.fillColor || 'rgba(0, 0, 0, 0)';
    ctx.beginPath();
    ctx.moveTo(canvasCoordinates[0][0], canvasCoordinates[0][1]);
    for (let i = 1; i < canvasCoordinates.length; i++) {
        ctx.lineTo(canvasCoordinates[i][0], canvasCoordinates[i][1]);
    }
    ctx.stroke();
};
  
const drawPolygon = async (feature, ctx, canvas) => {
    const polygon = turf.getCoords(feature);
    const canvasCoordinates = polygon[0].map(coord => convertToCanvasCoordinates(coord, canvas, feature, false));

    const transparentBlue = 'rgba(0, 0, 255, 0.3)'; 
    ctx.fillStyle = feature?.properties?.fillColor || transparentBlue;
    ctx.strokeStyle = feature?.properties?.strokeColor || 'red'; 

    ctx.beginPath();
    ctx.moveTo(canvasCoordinates[0][0], canvasCoordinates[0][1]);
    for (let i = 1; i < canvasCoordinates.length; i++) {
        ctx.lineTo(canvasCoordinates[i][0], canvasCoordinates[i][1]);
    }
    ctx.closePath();
    ctx.fill();
};

const convertToCanvasCoordinates =  (coord, canvas, feature, isPoint) => {
    const [lng, lat] = coord;   
    const bbox = getBoundingBox(feature);
    const { width, height } = calculateCanvasSize(bbox, isPoint);
    if(isPoint) {
        const scaleX = canvas.width / width;
        const scaleY = canvas.height / height;

        const canvasX = (lng - bbox[0]) * scaleX;
        const canvasY = (bbox[3] - lat) * scaleY;

        return [canvasX, canvasY];
    }
    canvas.width = width;
    canvas.height = height;

    const canvasX = ((lng - bbox[0]) / (bbox[2] - bbox[0])) * canvas.width;
    const canvasY = ((bbox[3] - lat) / (bbox[3] - bbox[1])) * canvas.height;  
  
    return [canvasX, canvasY];
};

const getBoundingBox = (feature) => {
    return turf.bbox(feature);
};
  
const calculateCanvasSize = (bbox, isPoint) => {
    if (isPoint) {
        const pointSize  = 10;
        return { width: pointSize, height: pointSize };
    } else {
        const [minX, minY, maxX, maxY] = bbox;
        let width = maxX - minX;
        let height = maxY - minY;
        width = Math.max(width, 200);
        height = Math.max(height, 200);
        return { width, height };
    }
};

export const convertToSingleFeature = (features: Array<Object>) => {
    return [new Feature({
        geometry: new GeometryCollection(features.map(feature => feature.getGeometry())),
        properties: features.map(feature => feature.getProperties())
    })];
};

export const mergeFeatures = (drawingFeatures, map) => {
    try {
        const fcStr = map.convertFeaturesToGeoJson(drawingFeatures);
        const fc = JSON.parse(fcStr);
        const drawingFeaturesTurf = turf.featureCollection(fc.features);
        return turf.combine(drawingFeaturesTurf);
    } catch(e) {
        console.error('error in mergeFeatures ', e); //eslint-disable-line
    }
};
