/* @flow */

import { gql } from '@apollo/client';

export default gql`
query digitalTwinProcessAndTasksQuery($processId: ID!, $filterBy: [JSON]) {
  process(id: $processId) {
    id
    name
  }
  tasks(filterBy: $filterBy) {
    id
    name
  }
}`;
