/* @flow */

import { gql } from '@apollo/client';

export default type => gql`
query digitalTwin${type.replaceAll('-', '_')}AssetsQuery($type: String!, $filterBy: [JSON], $orderBy: [JSON]) {
  result: entities(filterBy: $filterBy, orderBy: $orderBy, type: $type) {
    id
    type
    name
    image
    primary
    classes {
      uri
    }
    attributes(fields:["asset/uuid"])
  }
}
`;
