import React from 'react';
import Download3DSvgGeom from 'app/containers/Maps/EntityAboutMap/Download3DSvgGeom.js';
import Geometry from 'app/components/atoms/Maps/Geometry';

const UploadGeometry = (props: Object) => {
    const isGeom = props?.data?.geomFile ;
    return (
        <div>
            {isGeom && <Download3DSvgGeom {...props} fileType='geomFile' 
                downloadFileAction={props.downloadFileAction}
                deleteFile={props.deleteFile}
            />}
            {!isGeom && <Geometry {...props} />}
        </div>
    );
};

export default UploadGeometry;
