/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, CircularProgress } from '@mic3/platform-ui';

import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import { bind } from 'app/utils/decorators/decoratorUtils';
import history from 'store/History';
import { createWorkspace, updateWorkspace } from 'store/actions/admin/workspacesActions';

class AddWorkspace extends PureComponent<Object, Object> {
    static propTypes = {
        createWorkspace: PropTypes.func.isRequired,
        isLoading: PropTypes.bool,
        data: PropTypes.object,
        title: PropTypes.string
    };

    static defaultProps = {
        title: 'Create New Workspace'
    };

    formRef = React.createRef();

    components: Array<Object> = [
        {
            type: 'text',
            properties: { label: 'Name', name: 'name' },
            constraints: { required: true }
        },
        !this.props.data && {
            type: 'text',
            properties: { label: 'Category', name: 'category' }
        }
    ].filter(Boolean);

    @bind
    onFormSubmit(event: Event) {
        event.preventDefault();
        this.formRef.current.isValidForm().then(({ data: formData, errors }) => {
            const { createWorkspace, updateWorkspace, data, onClose } = this.props;
            if (!errors) {
                if (data) {
                    const { id, name } = formData;
                    updateWorkspace({ id, name: name.trim() }).then((response) => {
                        if (response instanceof Error) return;
                        onClose();
                    });
                    return;
                }
                createWorkspace({ ...formData, name: formData.name.trim() }).then((result) => {
                    const { createWorkspace: { id } } = result;
                    if (id) history.push(`/workspaces/${id}`);
                });
            }
        });
    }

    render(): Object {
        const { isLoading, isLoadingUpdate, onClose, title, data } = this.props;
        return (
            <ModalDialog
                title={title}
                onClose={onClose}
                actions={
                    (isLoading || isLoadingUpdate) ? (
                        <CircularProgress size={24} color="primary" />
                    ) : (
                        <Button  onClick={this.onFormSubmit}>
                            Save
                        </Button>
                    )
                }
            >
                <FormGenerator 
                    components={this.components}
                    ref={this.formRef}
                    data={data}
                />
            </ModalDialog>
        );
    }
}

export default connect(
    state => ({
        isLoading: state.admin.workspaces.create.isLoading,
        isLoadingUpdate: state.admin.workspaces.details.isLoading
    }),
    {
        createWorkspace,
        updateWorkspace
    }
)(AddWorkspace);
