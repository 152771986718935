/* @flow */

import { gql } from '@apollo/client';

export default gql`
mutation runExportEntityQuery($type: VersionedEntityType!, $id: ID!, $name: String, $version: Int) {
    result: exportEntityVersion(type: $type, id: $id, name: $name, version: $version) {
        id
    }
}
`;
