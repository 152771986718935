/* @flow */

import React, { PureComponent } from 'react';
import { Autocomplete, Avatar, ListItemText } from '@mic3/platform-ui';
import { connect } from 'react-redux';

import { bind, debounce, memoize } from 'app/utils/decorators/decoratorUtils';
import { filterRecordsOnFE, orderRecordsOnFE } from 'app/utils/filter/filterUtils';
import { TypeaheadChipInitials, TypeaheadAdornmentInitials } from 'app/components/organisms/Form/Typeahead/abstract/TypeaheadChip';
import { TypeaheadListItem } from 'app/components/organisms/Form/Typeahead/abstract/TypeaheadListItem';
import { getAttachmentUrl } from 'app/utils/attachments/attachmentsUtils';

class AbstractUserTypeahead extends PureComponent<Object, Object> {
    static propTypes = {
        /* eslint-disable-next-line react/forbid-foreign-prop-types */
        ...Autocomplete.propTypes,
    };

    state = { options: [] };

    @bind
    @memoize()
    getImage(id, imageId) {
        return getAttachmentUrl(id, 'user', imageId);
    }

    @bind
    optionTemplate({ image, name, username, id }: Object) {
        const { startAdornment } = this.props;
        const src = image && this.getImage(id, image);
        return {
            option: (
                <TypeaheadListItem ContainerComponent='div' dense disableGutters>
                    <Avatar src={src} initials={name} />
                    <ListItemText primary={name} secondary={username && `@${username}`} />
                </TypeaheadListItem>
            ),
            ChipProps: {
                avatar: <TypeaheadChipInitials src={src} initials={name} />,
            },
            startAdornment: startAdornment || <TypeaheadAdornmentInitials src={src} initials={name} />,
            label: `${name || 'Name not available'}${username ? `(${username})` : ''}`,
        };
    }

    @bind
    setOptions(query) {
        const { value, userReferences, filterBy, excludeBy, orderBy } = this.props;
        const filters = [...(filterBy || [])];
        const excludes = [...(excludeBy || [])];
        const orders = [...(orderBy|| [])];
        let options = query
            ? userReferences.filter((usr) => {
                const q = query.toLowerCase();
                return usr.name.toLowerCase().includes(q) || usr.username.toLowerCase().includes(q);
            })
            : userReferences;
            
        if(filters || excludes){
            options = filterRecordsOnFE(options, filters, excludes);  
        }
        options = orderRecordsOnFE(options, orders);

        
        this.setState({
            options: options.filter((usr) => {
                if (value) {
                    const val = Array.isArray(value) ? value : [value];
                    if (val.includes(usr.id)) {
                        return false;
                    }
                }
                return true;
            }),
        });
    }

    @bind
    @debounce()
    suggest(event: Object) {
        const query = event.target.value;
        this.setOptions(query);
    }

    render() {
        const { startAdornment, loadOptions, filterBy, placeholder, taskId, excludeBy, orderBy, isLoading, ...typeaheadProps } = this.props; // eslint-disable-line no-unused-vars
        const { options } = this.state;
        return (
            <Autocomplete
                {...typeaheadProps}
                options={options}
                isLoading={isLoading}
                optionTemplate={this.optionTemplate}
                suggest={this.suggest}
                placeholder={placeholder || 'Search for a user...'}
            />
        );
    }
}

export default connect((state) => ({
    userReferences: state.admin.users.references.data || [],
    isLoading: state.admin.users.references.isLoading,
}))(AbstractUserTypeahead);
