/* @flow */
import { gql } from '@apollo/client';
import Immutable from 'app/utils/immutable/Immutable';
import { graphql } from 'graphql/client';
import eventsQuery from 'graphql/stream/event/eventsQuery';
import eventsShortQuery from 'graphql/stream/event/eventsShortQuery';
import updateEventMutation from 'graphql/stream/event/updateEventMutation';
import loadSidebarEventDetailsQuery from 'graphql/stream/event/loadSidebarEventDetailsQuery';
import eventTypeTypeaheadQuery from 'graphql/stream/event/eventTypeTypeaheadQuery';
import eventProcessesQuery from 'graphql/stream/event/eventProcessesQuery';
import vtrQuery from 'graphql/stream/event/vtrQuery';
import { loadData, mutateData } from 'app/utils/redux/action-utils';
import translationRuleQuery from 'graphql/stream/event/translationRuleQuery';
import { eventTypesListQuery, loadEventTypeDetailsQuery } from 'graphql/stream/event/eventTypeQueries';
import { addEventTypeMutation, eventDataAttributeAddMutation, eventDataAttributeRemoveMutation, eventDataAttributeUpdateMutation, updateEventTypeMutation } from 'graphql/stream/event/eventTypeMutations';
import { addRoleToList } from 'app/config/rolesConfig';

export const LOAD_EVENT_PROCESSES_STARTED = '@@affectli/stream/events/processes/LOAD_PROCESSES_STARTED';
export const LOAD_EVENT_PROCESSES = '@@affectli/stream/processes/LOAD_PROCESSES';

export const LOAD_EVENTS_STARTED = '@@affectli/stream/LOAD_EVENTS_STARTED';
export const LOAD_EVENTS = '@@affectli/stream/LOAD_EVENTS';

export const LOAD_EVENT_TYPES_STARTED = '@@affectli/stream/LOAD_EVENT_TYPES_STARTED';
export const LOAD_EVENT_TYPES = '@@affectli/stream/LOAD_EVENT_TYPES';
//
// export const LOAD_EVENT_TYPES_SIDEBAR_STARTED = '@@affectli/stream/LOAD_EVENT_TYPES_SIDEBAR_STARTED';
// export const LOAD_EVENT_TYPES_SIDEBAR = '@@affectli/stream/LOAD_EVENT_TYPES_SIDEBAR';

export const LOAD_EVENT_TYPE_DETAILS_STARTED = '@@affectli/stream/LOAD_EVENT_TYPE_DETAILS_STARTED';
export const LOAD_EVENT_TYPE_DETAILS = '@@affectli/stream/LOAD_EVENT_TYPE_DETAILS';

export const LOAD_SIDEBAR_EVENT_DETAILS_STARTED = '@@affectli/stream/LOAD_SIDEBAR_EVENT_DETAILS_STARTED';
export const LOAD_SIDEBAR_EVENT_DETAILS = '@@affectli/stream/LOAD_SIDEBAR_EVENT_DETAILS';

export const LOAD_EVENT_DETAILS_STARTED = '@@affectli/stream/LOAD_EVENT_DETAILS_STARTED';
export const LOAD_EVENT_DETAILS = '@@affectli/stream/LOAD_EVENT_DETAILS';

export const LOAD_VTR_STARTED = '@@affectli/stream/events/LOAD_VTR_STARTED';
export const LOAD_VTR = '@@affectli/stream/events/LOAD_VTR';

export const LOAD_TRANSLATION_RULE_STARTED = '@@affectli/stream/events/LOAD_TRANSLATION_RULE_STARTED';
export const LOAD_TRANSLATION_RULE = '@@affectli/stream/translationRule/LOAD_TRANSLATION_RULE';

export const LOAD_EVENT_TYPE_TYPEAHEAD_STARTED = '@@affectli/stream/events/LOAD_EVENT_TYPE_TYPEAHEAD_STARTED';
export const LOAD_EVENT_TYPE_TYPEAHEAD = '@@affectli/stream/translationRule/LOAD_EVENT_TYPE_TYPEAHEAD';

export const loadEventTypeTypeahead = loadData(LOAD_EVENT_TYPE_TYPEAHEAD_STARTED, LOAD_EVENT_TYPE_TYPEAHEAD, eventTypeTypeaheadQuery);

const addRoleToEventsListDevice = async (payload) => {
    const { records } = payload || {};
    if (!records?.length) {
        return payload;
    }
    const devices = records.filter(event => event.device).map(({ device }) => ({ ...device }));
    const { records: devicesWithRoles } = await addRoleToList({ records: devices });
    const events = records.map((event) => {
        const device = devicesWithRoles.find(({ id }) => id === event?.device?.id);
        return ({ ...event, device });
    });
    return { ...payload, records: events };
};

export const loadEvents = data =>
    loadData(LOAD_EVENTS_STARTED, LOAD_EVENTS, data.isShort ? eventsShortQuery : eventsQuery, addRoleToEventsListDevice)({ ...data.options });

export const loadSidebarEventDetails =
    (id, time) => loadData(LOAD_SIDEBAR_EVENT_DETAILS_STARTED, LOAD_SIDEBAR_EVENT_DETAILS, loadSidebarEventDetailsQuery )({ id, time });

export const loadEventDetails =
    (id, time) => loadData(LOAD_EVENT_DETAILS_STARTED, LOAD_EVENT_DETAILS, loadSidebarEventDetailsQuery )({ id, time });

export const loadEventTypes = ({ isShort, options }: Object) =>
    loadData(LOAD_EVENT_TYPES_STARTED, LOAD_EVENT_TYPES, eventTypesListQuery)(options);

// export const loadEventTypesSidebar = loadData(LOAD_EVENT_TYPES_SIDEBAR_STARTED, LOAD_EVENT_TYPES_SIDEBAR, eventTypesListShortQuery);

export const addEventType = (record: Object) => {
    return mutateData(
        LOAD_EVENT_TYPE_DETAILS_STARTED,
        LOAD_EVENT_TYPE_DETAILS,
        addEventTypeMutation,
        'Event type added.',
    )({ record });
};

export const addEventDataAttribute = (record: Object) => {
    return mutateData(
        '@@affectli/events/ADD_EVENT_TYPE_ATTRIBUTE_STARTED',
        '@@affectli/events/ADD_EVENT_TYPE_ATTRIBUTE',
        eventDataAttributeAddMutation,
        'Event Attribute Added.',
    )({ record });
};

export const updateEventDataAttribute = (record: Object) => {
    return mutateData(
        '@@affectli/events/UPDATE_EVENT_TYPE_ATTRIBUTE_STARTED',
        '@@affectli/events/UPDATE_EVENT_TYPE_ATTRIBUTE',
        eventDataAttributeUpdateMutation,
        'Event attribute updated.',
    )({ record });
};

export const removeEventDataAttribute = (id: String) => {
    return mutateData(
        '@@affectli/events/REMVOE_EVENT_TYPE_ATTRIBUTE_STARTED',
        '@@affectli/events/REMVOE_EVENT_TYPE_ATTRIBUTE',
        eventDataAttributeRemoveMutation,
        'Event attribute removed.',
    )({ id });
};

export const loadEventTypeDetails = (id: string) =>
    loadData(LOAD_EVENT_TYPE_DETAILS_STARTED, LOAD_EVENT_TYPE_DETAILS, loadEventTypeDetailsQuery)({ id });

export const updateEventType = ({
    id,
    name,
    metadata,
    script,
    preScript,
    bpmnSignal,
    entityType,
    lookupAttribute,
    active,
    noActionOnDuplicate,
    descriptorType,
    descriptorLookupAttribute,
}: Object) =>
    mutateData(
        LOAD_EVENT_TYPE_DETAILS_STARTED,
        LOAD_EVENT_TYPE_DETAILS,
        updateEventTypeMutation,
        'Event type updated.',
    )({
        record: {
            id,
            name,
            metadata,
            script,
            preScript,
            bpmnSignal,
            entityType,
            lookupAttribute,
            active,
            noActionOnDuplicate,
            descriptorType,
            descriptorLookupAttribute,
        },
    });



/**
 * Events actions
 *
 * @param status the params to attach to the query string
 * @param eventId the query's options
 */
export const updateEventStatus = (record: Object) =>
    mutateData(
        LOAD_SIDEBAR_EVENT_DETAILS_STARTED,
        LOAD_SIDEBAR_EVENT_DETAILS,
        updateEventMutation,
        `Status changed to ${record.status} of event ${record.id}`,
    )({ record });

/**
 * Load a list of processes
 * @param options { Object } contains information for page, itemsPerPage ...
 */
export const loadEventProcesses = (processesIds: Array<String>) => (dispatch: Function) => {
    dispatch({ type: LOAD_EVENT_PROCESSES_STARTED });
    const variables = {
        where: [{field: 'id', op: 'in', value: processesIds}],
    };
    graphql.query({
        query: eventProcessesQuery,
        variables,
        fetchPolicy: 'no-cache'
    }).then( ( response: Object ): void => {
        dispatch({ type: LOAD_EVENT_PROCESSES, payload: Immutable(response && response.data) });
    }).catch((error) => {
        dispatch({ type: LOAD_EVENT_PROCESSES, payload: error, error: true });
    });
};

export const loadEntityPrimaryIndexes = (id: String) => {
    return graphql.query({
        query: gql`query loadEntityPrimaryIndexes($id: ID!) {
          class(id: $id) {
            id
            name
            uri
            entityPrimaryIndexes{
              entityType{
                id
              }
              primaryAttribute
              isUnique
              state
            }
          }
        }`,
        variables: { id },
        fetchPolicy: 'no-cache'
    }).then((resp: Object): void => {
        return resp?.data?.class;
    }).catch((error) => {
    });
};

export const loadVTR = () => loadData(LOAD_VTR_STARTED, LOAD_VTR, vtrQuery)({
    filterBy: [{ field: 'uri', op: 'in', value: ['UMS/Vendor', 'UMS/Tenant', 'region']}]
});

const loadTranslationRule = key => loadData(LOAD_TRANSLATION_RULE_STARTED, LOAD_TRANSLATION_RULE, translationRuleQuery)({
    filterBy: [
        { field: 'classes.uri', op: '=', value: 'translation_rule' },
        { field: 'active', op: '=', value: true },
    ],
    groupBy: [{ field: `attributes.translation_rule/${key}`, alias: 'translation' }],
    orderBy: [{ field: `attributes.translation_rule/${key}`, direction: 'asc nulls last' }],
});

export const loadTranslationRuleDescription = () => loadTranslationRule('description');
