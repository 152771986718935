/* @flow */

import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Avatar } from '@mic3/platform-ui';

import ListItem from 'app/components/molecules/List/ListItem';
import ResizableListItem from 'app/components/molecules/VirtualList/ResizableListItem';
import WorkspaceLink from 'app/components/atoms/Link/WorkspaceLink';

const CardContainer = styled.div`
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
`;

/**
 * A single task item
 */
class WorkspacesListItem extends PureComponent<Object, Object> {

    render() {
        const { data, index, resize, style } = this.props;
        const { name, id } = data;
        return (
            <ResizableListItem style={style} key={index} index={index} resize={resize} padding={15}>
                {resizeRow => (
                    <CardContainer>
                        <ListItem
                            component={<Avatar initials={name} />}
                            title={<WorkspaceLink id={id}>{name || 'No Name'}</WorkspaceLink>}
                            subTitle={`#${id}`}
                            raised
                            actions={this.props.actions(data)}
                        />
                    </CardContainer>
                )}
            </ResizableListItem>
        );
    }
}

export default WorkspacesListItem;
