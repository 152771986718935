/* @flow */

import React, { Fragment, PureComponent } from 'react';
import moment from 'moment';
import { Autocomplete, Menu, MenuItem, TextField, IconButton, MdiIcon, InputAdornment } from '@mic3/platform-ui';
import DateTimePicker from 'app/containers/Designer/Form/components/DateTime';

import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { valueToType } from 'app/utils/utils';

const TypesMap = {
    text: {
        iconName: 'format-text',
        label: 'Text'
    },
    boolean: {
        iconName: 'toggle-switch-off',
        label: 'Boolean'
    },
    number: {
        iconName: 'numeric',
        label: 'Number'
    },
    date: {
        iconName: 'calendar-blank',
        label: 'Date'
    }
};
class DynamicField extends PureComponent<Object, Object> {
    static propTypes = {
        /* eslint-disable-next-line react/forbid-foreign-prop-types */
        ...TextField.propTypes,
    };

    constructor(props: Object) {
        super(props);
        this.state = {
            anchorMenu: null,
        };
    }

    @bind
    handleOnChange(e: Object) {
        let { value }= e.target;
        const { name, type }= e.target;
        const { onChange }= this.props;
        const nextType = type || this.props.type;
        if(nextType === 'date') {
            value = value.toISOString();
        }
        onChange && onChange({ target: { value: valueToType(value, nextType), type: nextType, name }});
    }

    @bind
    setType(type: string) {
        return (event: Object) => {
            const { value } = this.props;
            this.handleOnChange({ target: {
                name: this.props.name,
                value: valueToType(value, type),
                type
            }});
            this.handleClose();
        };};

    @bind
    openTypesMenu(event: Object) {
        event.stopPropagation();
        this.setState({ anchorMenu: event.currentTarget });
    };

    @bind
    handleClose() {
        this.setState({ anchorMenu: null });
    };

    @bind
    @memoize()
    getTypesAdornment(type: string) {
        return (
            <InputAdornment position="end">
                <IconButton onClick={this.openTypesMenu}>
                    <MdiIcon name={TypesMap[type].iconName} />
                </IconButton>
            </InputAdornment>
        );
    }

    @bind
    @memoize()
    buildField(props: Object) {
        const { InputProps, type, ...restProps } = props;
        switch(type){
            case 'date':
                return (
                    <DateTimePicker
                        key={type}
                        {...restProps}
                        value={moment(restProps.value).toDate()}
                        onChange={this.handleOnChange}
                        InputProps={{
                            ...InputProps,
                            endAdornment: this.getTypesAdornment(type),
                        }}
                        showTodayButton
                        ampm={false}
                        format="MMM Do YYYY, HH:mm"
                    />
                );
            case 'boolean':
                return (
                    <Autocomplete
                        key={type}
                        {...restProps}
                        onChange={this.handleOnChange}
                        clearable={false}
                        valueField="value"
                        options={[
                            { label: 'True', value: true },
                            { label: 'False', value: false },
                        ]}
                        InputProps={{
                            ...InputProps,
                            endAdornment: this.getTypesAdornment(type),
                        }}
                    />
                );
            default:
                return (
                    <TextField
                        key={type}
                        type={type}
                        {...restProps}
                        onChange={this.handleOnChange}
                        InputProps={{
                            ...InputProps,
                            endAdornment: this.getTypesAdornment(type),
                        }}
                    />
                );
        }
    }

    @bind
    @memoize()
    buildTypesMenu(): Array<Object> {
        return Object.keys(TypesMap).map((key: string) => (
            <MenuItem key={TypesMap[key].label} onClick={this.setType(key)}>{TypesMap[key].label}</MenuItem>
        ));
    }

    render() {
        const { anchorMenu } = this.state;
        return (
            <Fragment>
                {this.buildField(this.props)}
                <Menu
                    key={1}
                    anchorEl={anchorMenu}
                    open={!!anchorMenu}
                    onClose={this.handleClose}
                >
                    {this.buildTypesMenu()}
                </Menu>
            </Fragment>
        );
    }
}

export default DynamicField; // eslint-disable-line react-hooks/rules-of-hooks
