/* @flow */

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import moment from 'moment';
import styled from 'styled-components';

import Filters from 'app/components/organisms/Filters/Filters';
import Calendar from 'app/components/molecules/Calendar/Calendar';
import { loadCalendarTasks } from 'store/actions/abox/taskActions';
import { datefy, getDate } from 'app/utils/utils';
import { getPriorityColor } from 'app/config/aboxConfig';
import { get } from 'app/utils/lo/lo';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { shallowEquals } from 'app/utils/utils';
import { formatOptionsForTask } from 'app/utils/formatter/graphql-formatter';
import { filterDefinitions, defaultFilters, defaultOrder } from 'app/components/organisms/TasksView/TasksView';
import PageNotAllowed from 'app/containers/ErrorPages/PageNotAllowed';
import { setDocumentTitle } from 'store/actions/app/appActions';
import { modulesAndPageTitles } from 'app/config/typesConfig';

const CalendarWrapper = styled.div`
overflow: auto;
height: 100%;
max-height: calc(100vh - 147px);
@media (max-width: ${( { theme } ) => theme.media.sm} ) {
    max-height: calc(100vh - 200px);
}
`;

/**
 * Renders the view to display the task calendar.
 */
class AboxCalendar extends PureComponent<Object, Object> {

    state = { start: null, end: null, feFilters: [] }

    breadcrumb = [{ title: 'A-Box Calendar' }]
    filterDefinitions = filterDefinitions;
    searchBar = ['name', 'id'];
    defaultFilters = defaultFilters;
    defaultOrder = defaultOrder;

    componentDidMount() {
        this.props.setDocumentTitle(modulesAndPageTitles.abox.calendar);
    }


    @bind
    @memoize(shallowEquals)
    buildEvents(tasks) {
        return tasks.filter(task => task)
            .map((task) => {
                const { primary, name, ...event } = task;
                const { dueDate, priority, closedDate } = primary || {};
                let end = datefy(dueDate);
                let start = getDate(primary, 'startDate');
                if (!start && end) {
                    start = moment(end).startOf('day').toDate();
                    end = moment(end).endOf('day').toDate();
                }
                if (start && !end) {
                    start = moment(start).startOf('day').toDate();
                    end = moment(start).endOf('day').toDate();
                }

                return { ...event, title: name || 'No Name', start, end, priority, closedDate };
            }).filter(({ start, end }) => start && end);
    };

    @bind
    goToTask({ id }) {
        this.props.history.push(`/abox/task/${id}`);
    };

    @bind
    buildEventProp({ priority, closedDate }, start, end, isSelected) {
        const { theme } = this.props;
        const style = {};
        const priorityColor = closedDate ? 'disabled' : getPriorityColor(priority);
        const backgroundColor = String(get(theme, `priorityColors.${priorityColor}`));
        if (backgroundColor) {
            style.backgroundColor = `${backgroundColor}90`;
        }
        return { style };
    }

    @bind
    @memoize()
    resetView(start, end, filters) {
        const { filterBy } = formatOptionsForTask({ filterBy: filters }, this.props.userProfile);
        const { userProfile: { id } } = this.props;
        if(start && end) {
            this.props.loadCalendarTasks(id, start, end, { filterBy });
        }
    }

    @bind
    onDateRangeChange(filterBy) {
        return ({ start, end }) => {
            this.setState({ start, end }, () => this.resetView(start, end, filterBy));
        };
    };

    @bind
    @memoize()
    buildCalendar(events) { 
        return (filterBy) => {
            const { start, end } = this.state;
            this.resetView(start, end, filterBy);

            return (
                <CalendarWrapper>
                    <Calendar
                        calendarId={'abox'}
                        isLoading={this.props.isLoading}
                        events={events}
                        eventPropGetter={this.buildEventProp}
                        onSelectEvent={this.goToTask}
                        onDateRangeChange={this.onDateRangeChange(filterBy)}
                    />
                </CalendarWrapper>
            );
        };
    }

    @bind
    handleReload(filterBy) {
        const { start, end } = this.state;
        this.resetView(start, end, [ ...filterBy ]);
    }

    /**
     * @override
     */
    render(): Object {
        const { permissions, isAdmin } = this.props.userProfile;
        const permissionsSet = new Set(permissions || []);
        const canView = isAdmin || permissionsSet.has('abox.calendar');
        if (!canView) {
            return <PageNotAllowed title="Calendar" />;
        }
        const events = this.buildEvents(this.props.tasks);
        return (
            <Filters
                id="AboxCalendarFilters"
                filterDefinitions={this.filterDefinitions}
                searchBar={this.searchBar}
                defaultFilters={this.defaultFilters}
                defaultOrder={this.defaultOrder}
                breadcrumb={this.breadcrumb}
                onReload={this.handleReload}
            >
                {this.buildCalendar(events)}
            </Filters>
        );
    }
}

export default withTheme(connect(state => ({
    userProfile: state.user.profile,
    tasks: state.abox.task.calendar.records,
    isLoading: state.abox.task.calendar.isLoading,
}), { loadCalendarTasks, setDocumentTitle })(AboxCalendar));
