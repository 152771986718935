/* @flow */
import React, { PureComponent } from 'react';
import { TextField, Avatar, Divider, Box } from '@mic3/platform-ui';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import VirtualListManaged from 'app/components/molecules/VirtualList/VirtualListManaged';
import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import ListItem from 'app/components/molecules/List/ListItem';
import Button from 'app/components/atoms/Button/Button';
import Icon from 'app/components/atoms/Icon/Icon';

import CheckBoxBlank from 'assets/images/icons/check_box_outline_blank.png';
import CheckBox from 'assets/images/icons/check_box.png';

import { loadChannelList, joinPublicChannel } from 'store/actions/chat/chatActions';

import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { getSubscriptionByRel } from 'app/utils/chat/chatUtils';
import Immutable from 'app/utils/immutable/Immutable';
import { focusInput, isEmpty } from 'app/utils/utils';
import history from 'store/History';

const inputProps: Object = { disableUnderline: true };
const ListItemStyled = styled(ListItem)`
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    cursor: pointer;
    box-shadow: none;
    background: ${({ selected, theme }) => (selected ? theme.material.colors.background.active : theme.material.colors.background.paper)};
    &:hover {
        background: ${({ selected, theme }) => (selected ? theme.material.colors.background.active : theme.material.colors.background.hover)};
    }
    @media (max-width: 1100px) {
        padding-right: 2rem;
    }
`;
const StyledAvatar = styled(Avatar)`
    && {
        ${({ selected }) => selected ? `
        background-color: gray !important;
        color: transparent
        ` : `
        ${ListItemStyled}:hover & {
            color: transparent;
        }
        `};
    }
`;
const StyledAvatarOverlay = styled.div`
    display: none;
    width: 24px;
    height: 24px;
    position: absolute;
    z-index: 1;
    top: 16px;
    left: 16px;
    && {
        ${({ selected }) => selected ? `
        display: block;
        background-image: url(${CheckBox});
        background-repeat: no-repeat;
        background-position: center center;
        ` : `
        ${ListItemStyled}:hover & {
            display: block;
            background-image: url(${CheckBoxBlank});
            background-repeat: no-repeat;
            background-position: center center;
        }
        `};
    }
`;
const ResourcesList = styled.div`
    div[class*='Container__ContainerStyle'] {
        background-color: transparent;
        margin-bottom: 0;
    }
    div[class*='VirtualList__TinyVirtual'] {
        padding-top: 0 !important;
        margin-top: 0.2em;
    }
    height: ${isMobile ? '60vh' : '50vh'} !important;
    padding-bottom: 36px;
`;
const StyledDivider = styled(Divider)`
    margin: 5px 0 !important;
`;
const SearchWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding 5px 16px;
`;
const StyledBox = styled(Box)`
    display: flex;
    align-items: center;
    width: 100%;
`;
const StyledTextField = styled(TextField)`
    margin-left: 5px !important;
`;
const CancelButton = styled(Button)`
    margin: 0;
    color: #7391D0;
`;

class ChatChannelsDialog extends PureComponent<Object, Object> {
    static propTypes = {
        isOpen: PropTypes.bool.isRequired,
        isLoading: PropTypes.bool,
        records: PropTypes.array,
        subscriptions: PropTypes.array,
        loadChannelList: PropTypes.func.isRequired,
        joinPublicChannel: PropTypes.func.isRequired,
        closeModal: PropTypes.func.isRequired,
    };

    state = Immutable({
        selectedItem: {},
        subscription: {},
        search: '',
    });

    defaultState: Object = this.state; // preserve the initial state in a new object.
    virtualListRef = React.createRef();
    
    componentDidUpdate(prevProps, prevState) {
        const { subscriptions } = this.props;
        const { selectedItem } = this.state;
        if (prevState.selectedItem !== selectedItem) {
            if (!isEmpty(selectedItem)) {
                const {  id, type } = selectedItem;
                const subscription = getSubscriptionByRel(subscriptions, { type, id });
                !isEmpty(subscription) ? this.setState({ subscription }) : this.setState({ subscription: {} });
            } else {
                this.setState({ subscription: {} });
            }
        }
    }

    @bind
    renderComponent({
        style,
        index,
        data
    }: {
        style: Object,
        index: number,
        data: Object
    }) {
        const { rel: { id, name, type } } = data;
        const { selectedItem } = this.state;
        const isSelected =  selectedItem.id === id;
        const selectedData = {id, name, type};

        return (
            <div style={style} key={index}>
                <ListItemStyled
                    component={
                        <div>
                            <StyledAvatar
                                selected={isSelected}
                                initials={name}
                            />
                            <StyledAvatarOverlay selected={isSelected} />
                        </div>
                    }
                    title={name}
                    subTitle={`#${id}`}
                    raised
                    onClick={e =>
                        this.onSelectListItem(e, selectedData)
                    }
                    selected={isSelected}
                />
            </div>
        );
    }

    @bind
    onSelectListItem(event: Object, data: Object) {
        event.preventDefault();
        const { selectedItem } = this.state;
        const selectedValue = !isEmpty(selectedItem) && selectedItem.id === data.id ? {} : data;
        this.setState({ selectedItem: selectedValue });
        this.updateList();
    }

    @bind
    updateList() {
        if (this.virtualListRef && this.virtualListRef.current) {
            this.virtualListRef.current.forceUpdate();
        }
    }

    @bind
    async onSubmit() {
        const { selectedItem, subscription } = this.state;
        const { id } = selectedItem;
        if(isEmpty(subscription)) {
            this.props.joinPublicChannel(id).then(() => {
                history.push(`/abox/alive/channel/${id}`);
            });
        } else {
            history.push(`/abox/alive/channel/${id}`);
        }
        this.closeModal();
    }

    @bind
    onChangeSearch(e: Object) {
        if (e.persist) {
            e.persist();
        }
        this.setState({ search: e.target.value });
    }

    @bind
    loadData() {
        const { loadChannelList, records } = this.props;
        if (!records.length) {
            return loadChannelList();
        }
        return Promise.resolve();
    }

    @bind
    closeModal() {
        this.setState(this.defaultState, this.props.closeModal);
    }

    @bind
    @memoize()
    filterRecords(records, search) {
        const query = !isEmpty(search) ? search.trim().toLowerCase() : '';
        let result = [];

        function searchByName(chat) {
            const { rel } = chat;
            let isFound = false;
            if (isEmpty(rel)) return isFound; 
            const { name } = rel;

            if (!name) return isFound;
            const qName = name.toLowerCase();
            isFound = qName.indexOf(query) >= 0;
            return isFound;
        }
        result = (records || []).filter(searchByName);

        return result;
    }

    render() {
        const {
            isOpen,
            records,
            isLoading,
            isMobileView
        } = this.props;
        const { selectedItem, search, subscription } = this.state;
        const filteredRecords = this.filterRecords(records, search);
        const totalRecords = filteredRecords.length;
        const startIndex = 0;

        return (
            isOpen && (
                <ModalDialog
                    bgcolor={'rgba(40, 51, 75, 1)'}
                    dialogProps={{ overflow: 'hidden' }}
                    withoutClose={!isMobileView}
                    onClose={this.closeModal}
                    title='Find Channels'
                    actions={
                        <>
                            {!isMobileView && (<CancelButton onClick={this.closeModal}>Cancel</CancelButton>)}
                            <Button
                                disabled={isEmpty(selectedItem)}
                                color='primary'
                                onClick={this.onSubmit}
                            >
                                {isEmpty(subscription) ? 'Join' : 'Open'}
                            </Button>
                        </>
                    }
                >
                    <StyledDivider />
                    <SearchWrapper>
                        <StyledBox>
                            <Icon name='magnify' />
                            <StyledTextField
                                variant='standard'
                                onChange={this.onChangeSearch}
                                value={search}
                                fullWidth
                                margin='none'
                                placeholder='Search channels...'
                                InputProps={inputProps}
                                inputRef={ref => !isMobileView && focusInput(ref)}
                            />
                        </StyledBox>
                    </SearchWrapper>
                    <StyledDivider />
                    <ResourcesList>
                        <VirtualListManaged
                            ref={this.virtualListRef}
                            renderComponent={this.renderComponent}
                            itemSize={84}
                            title={`${totalRecords >= 1000 ? '999+' : totalRecords} Channels`}
                            itemCount={totalRecords || 0}
                            loadData={this.loadData}
                            isLoading={isLoading}
                            startIndex={startIndex}
                            filterBy={[]}
                            orderBy={[]}
                            list={filteredRecords}
                            maxWidth='1024'
                        />
                    </ResourcesList>
                </ModalDialog>
            )
        );
    }
}

export default connect(
    state => ({
        records: state.chat.channel.data,
        isLoading: state.chat.channel.isLoading,
        subscriptions: state.chat.subscriptions.data,
        isMobileView: state.global.isMobile
    }),
    {
        loadChannelList,
        joinPublicChannel
    }
)(ChatChannelsDialog);
