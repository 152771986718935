/* @flow */

import { combineReducers } from 'redux';

import { loadDataReducer } from 'app/utils/redux/reducer-utils';
import { LOAD_RELATION_DEFINITION_AUTOCOMPLETE_STARTED, LOAD_RELATION_DEFINITION_AUTOCOMPLETE } from 'store/actions/entities/relationshipsActions';
import { LOAD_PROCESSES_AUTOCOMPLETE_STARTED, LOAD_PROCESSES_AUTOCOMPLETE } from 'store/actions/entities/processesActions';
import { LOAD_TASKS_AUTOCOMPLETE, LOAD_TASKS_AUTOCOMPLETE_STARTED } from 'store/actions/entities/tasksActions';
import { LOAD_DIRECTORIES_AUTOCOMPLETE_STARTED, LOAD_DIRECTORIES_AUTOCOMPLETE } from 'store/actions/common/DirectoriesActions';

export default combineReducers<Object, Object>({
    relationDefinition: loadDataReducer(LOAD_RELATION_DEFINITION_AUTOCOMPLETE_STARTED, LOAD_RELATION_DEFINITION_AUTOCOMPLETE),
    processes: loadDataReducer(LOAD_PROCESSES_AUTOCOMPLETE_STARTED, LOAD_PROCESSES_AUTOCOMPLETE),
    tasks: loadDataReducer(LOAD_TASKS_AUTOCOMPLETE_STARTED, LOAD_TASKS_AUTOCOMPLETE),
    directories: loadDataReducer(LOAD_DIRECTORIES_AUTOCOMPLETE_STARTED, LOAD_DIRECTORIES_AUTOCOMPLETE),
});
