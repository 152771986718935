/* @flow */

import { gql } from '@apollo/client';

export default gql`
    query entityClassificationQuery($type: String!, $id: ID!) {
        result: entity(type: $type, id: $id) {
            id
            classes {
                id
                name
                uri
                color
            }
            primary
            attributes
        }
    }
`;
