// @flow

import React, { PureComponent } from 'react';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import LocationTypeahead from 'app/components/organisms/Form/Typeahead/LocationTypeahead';
import { Button, MdiIcon, Typography } from '@mic3/platform-ui';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { updateMapData } from 'store/actions/maps/situationalAwarenessActions';

const SnacbarHeader = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Footer = styled.div`
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
`;

class AboutMapSearch extends PureComponent<Object, Object> {
    state = {
        searchTextboxValue: '',
        lat: null,
        long: null,
    };

    @bind
    hanldeMapSearch(event) {
        const { name, value } = event.target;
        const { map, title, svgSource, snackbarRef } = this.props;
        if (name === 'location') {
            return this.setState({ searchTextboxValue: value }, () => {
                if (value) {
                    const { lat, lon } = value || {};
                    map.setPinCenter(lon, lat);
                    if (title && title === 'SvgMapSearch') {
                        map.addPinToClassAboutMap({ svg: svgSource, latitude: lat, longitude: lon });
                    } else {
                        this.setState({ lat: lat, long: lon });
                        map.addPinsToAboutMap({ latitude: lat, longitude: lon });
                        const message = this.renderSvgMessage(lat, lon);
                        const action = this.renderSvgActions();
                        snackbarRef.current.addAction(action);
                        snackbarRef.current.addMessage(message);
                        snackbarRef.current.handleOpen(true);
                    }
                }
            });
        }
    }

    @bind
    renderSvgMessage(lat: any, lon: any) {
        const { snackbarRef } = this.props;
        const header = 'Moved To';
        return (
            <>
                <SnacbarHeader>
                    <Typography variant='body1'>{header}</Typography>
                    <MdiIcon name='close' onClick={() => snackbarRef.current.handleOpen(false)} size={'small'} />
                </SnacbarHeader>
                <Typography variant='caption'>
                    Latitude: {lat}, Longitude:{lon}
                </Typography>
            </>
        );
    }

    @bind
    updateLocation() {
        const { id, updateMapData, type } = this.props;
        const { lat: latitude, long: longitude } = this.state;
        const data = { id, type, primary: { locationInfo: { latitude, longitude } } };
        updateMapData(data);
    }

    @bind
    @memoize()
    renderSvgActions() {
        const { snackbarRef } = this.props;
        return (
            <Footer>
                <Button variant='text' onClick={() => snackbarRef.current.handleOpen(false)}>
                    Cancel
                </Button>
                <Button onClick={this.updateLocation}>Save</Button>
            </Footer>
        );
    }
    render() {
        const typeaheadProps = {
            InputProps: {
                disableUnderline: true,
            },
            value: this.state.searchTextboxValue,
            onChange: this.hanldeMapSearch,
            variant: 'standard',
            clearable: false,
        };
        return (
            <>
                <LocationTypeahead {...typeaheadProps} name='location' />
            </>
        );
    }
}

export default connect(null, {
    updateMapData,
})(AboutMapSearch);
