/* @flow */

import { gql } from '@apollo/client';

export default gql`
        query loadAllBoardColumnsQuery($filterBy: [JSON], $excludeBy: [JSON], $orderBy: [JSON], $countMax: Int, $startIndex: Int, $stopIndex: Int) {
            count: count(dataType: "custom", filterBy: $filterBy, excludeBy: $excludeBy, max: $countMax)
            records: entities(type: "custom", filterBy: $filterBy, excludeBy: $excludeBy, orderBy: $orderBy, startIndex: $startIndex, stopIndex: $stopIndex) {
              id
              name
            }
        }
    `;;
