// @flow
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Icon from 'app/components/atoms/Icon/Icon';
import { Popover, Grid, TextField } from '@mic3/platform-ui';
import { bind } from 'app/utils/decorators/decoratorUtils';
import Menu from 'app/components/molecules/Menu/Menu';
import MenuItem from 'app/components/molecules/Menu/MenuItem';
import { saveFilterOperators } from 'store/actions/filterbar/filterbarActions';
import Immutable from 'app/utils/immutable/Immutable';
import { get } from 'app/utils/lo/lo';
import { deepEquals, isEmpty } from 'app/utils/utils';
import { operatorsMenu } from 'app/utils/filter/operatorsUtils';

const StyledIcon = styled(Icon)`
    cursor: pointer;
    margin-top: 10px;
    &:before {
        color: ${({theme})=> theme.material.colors.secondary.main} !important;
    }
`;

const StyledGrid = styled(Grid)`
    display: flex;
    height: 100%;
    align-items: center;
`;



class OperatorSelect extends PureComponent {
    state = {
        anchorEl: null,
        selectedItem: this.getSelectedOperator()
    };

    componentDidUpdate(prevProps) {
        const { operatorsMap, filterViewState, filterPreferences } = this.props;
        const { operatorsMap: stateOperators } = filterViewState || {};
        if (isEmpty(stateOperators) && isEmpty(operatorsMap)) {
            // in case we reset filters
            this.setState({ selectedItem: null });
        } else if (filterPreferences?.operatorsMap && deepEquals(operatorsMap, filterPreferences?.operatorsMap)) {
            // in case we load preferences
            const selectedItem = this.getSelectedOperator();
            this.setState({ selectedItem });
        }
    }

    @bind
    getSelectedOperator() {
        const { name, filterPreferences, filterViewState, type, opValue, operatorsMap } = this.props;
        const { operatorsMap: prefOperators } = filterPreferences || {};
        const { operatorsMap: stateOperators } = filterViewState || {};
        const operatorValue =  operatorsMap?.[name] || stateOperators?.[name] || prefOperators?.[name] || opValue;
        if (!operatorValue) {
            return null;
        }
        return operatorsMenu?.[type]?.find(op => op?.value === operatorValue);
    }

    @bind
    openMenu(event) {
        this.setState({ anchorEl: event.currentTarget });
    }

    @bind
    closeMenu(event) {
        this.setState({ anchorEl: null });
    }

    onMenuItemClick = (selectedItem: Object) => async () => {
        const { name, saveFilterOperators, operatorsMap, filterPreferences, filterViewState } = this.props;
        const prevOperator = operatorsMap[name] || filterViewState?.operatorsMap?.[name] || filterPreferences?.operatorsMap?.[name];
        await saveFilterOperators({ [name]: selectedItem?.value });
        await this.setState({ selectedItem, anchorEl: null });
        if (selectedItem?.value === 'is null') {
            return this.setValue('__isNull');
        }
        if (prevOperator === 'is null' && selectedItem?.value !== 'is null') {
            return this.setValue('');
        }
    };

    @bind
    setValue(value){
        const { name, type } = this.props;
        this.props.onChange({ target: { type, value, name } });
    }

    @bind
    renderPopoverMenu() {
        const { anchorEl } = this.state;
        const { type } = this.props;
        const open = Boolean(anchorEl);
        return (
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={this.closeMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
            >
                <Menu>
                    {Object.entries(operatorsMenu[type])?.map(([key, data]) => (
                        <MenuItem
                            key={key}
                            icon={data?.icon}
                            iconType={data?.iconType}
                            style={{ width: '180px' }}
                            onClick={this.onMenuItemClick(data)}
                            name={data?.label}
                        />
                    ))}
                </Menu>
            </Popover>
        );
    }

    render() {
        const { selectedItem } = this.state;
        const {
            type,
            opSelector,
            filterViewState,
            filterBarId,
            filterIsOpen,
            operatorsMap,
            saveFilterOperators,
            defaultUnit,
            filterPreferences,
            customField,
            onChange,
            ...props
        } = this.props;
        if (!Object.keys(operatorsMenu).includes(type)) {
            return <TextField {...props} type={type} />;
        }
        const disabled = props.disabled || props.value === '__isNull';
        const val = props.value === '__isNull' ? '' : props.value;
        return (
            <>
                <StyledGrid container >
                    <Grid item xs={1}>
                        <StyledIcon noBackground name={selectedItem?.icon || operatorsMenu?.[type]?.[0]?.icon} onClick={this.openMenu} />
                    </Grid>
                    <Grid item xs={11}>
                        <TextField {...props} type={type} disabled={disabled} value={val} onChange={(e) => onChange(e)} />
                    </Grid>
                </StyledGrid>
                {this.renderPopoverMenu()}
            </>
        );
    }
}

export default connect(
    (state, props) =>
        Immutable({
            filterViewState: get(state, `component.state.${state.filterbar.id}`),
            filterPreferences: get(state, `user.preferences.filters.${state.filterbar.id}`),
            filterBarId: state.filterbar.id,
            filterIsOpen: state.filterbar.isOpen,
            operatorsMap: state.filterbar.operatorsMap
        }),
    { saveFilterOperators }
)(OperatorSelect);
