
const shadows = {
    boxShadow1DP: '0px 0.92px 0.5px 0px rgba(0, 0, 0, 0.26), 0px 0px 0.67px 0px rgba(0, 0, 0, 0.08)',
    boxShadow2DP: '0px 1.83px 1px 0px rgba(0, 0, 0, 0.26), 0px 0px 1.33px 0px rgba(0, 0, 0, 0.08)', //z1
    boxShadow3DP: '0px 2.75px 1.5px 0px rgba(0, 0, 0, 0.26), 0px 0px 2px 0px rgba(0, 0, 0, 0.08)',
    boxShadow4DP: '0px 3.67px 2px 0px rgba(0, 0, 0, 0.26), 0px 0px 2.67px 0px rgba(0, 0, 0, 0.08)',
    boxShadow6DP: '0px 5.5px 3px 0px rgba(0, 0, 0, 0.26), 0px 0px 4px 0px rgba(0, 0, 0, 0.08)',
    boxShadow8DP: '0px 7.33px 4px 0px rgba(0, 0, 0, 0.26), 0px 0px 5.33px 0px rgba(0, 0, 0, 0.08)',
    boxShadow12DP: '0px 11px 6px 0px rgba(0, 0, 0, 0.26), 0px 0px 8px 0px rgba(0, 0, 0, 0.08)', //z3
    boxShadow16DP: '0px 14.67px 8px 0px rgba(0, 0, 0, 0.26), 0px 0px 10.67px 0px rgba(0, 0, 0, 0.08)',
    boxShadow24DP: '0px 22px 12px 0px rgba(0, 0, 0, 0.26), 0px 0px 16px 0px rgba(0, 0, 0, 0.08)',
};

export default shadows;