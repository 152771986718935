import { marked } from 'marked';
import DOMPurify from 'dompurify';

const renderer = new marked.Renderer();
renderer.link = ( href, title, text ) => `<a target="_blank" href="${href || '#'}" title="${title || ''}">${text}</a>`;

marked.use({ renderer: renderer });
const markdownToHtml = (source) => {
    if (!source || typeof(source) !== 'string') {
        return null;
    }
    try {
        const markdown = (source || '')
            .replaceAll(/\[ \]\s/gm, '<i class="mdi mdi-checkbox-blank-outline checkbox"></i> ')
            .replaceAll(/\[x\]\s/gm, '<i class="mdi mdi-checkbox-marked checkbox"></i> ');
        const html = marked.parse(markdown);
        const sanetized = DOMPurify.sanitize(html)
            .replace(/<a /g, '<a target="_blank" ');
        return { __html: sanetized };
    } catch (error) {}
    return null;
};

export {
    markdownToHtml,
    renderer
};

export default marked;
