/* @flow */

// $FlowFixMe
import React, { PureComponent } from 'react';
import { IconButton, MdiIcon, Tooltip, Grid, Paper, Typography, Dialog, Button, ConfirmationModal } from '@mic3/platform-ui';
import styled from 'styled-components';

import { bind } from 'app/utils/decorators/decoratorUtils';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';

const PaperStyled = styled(Paper)`
    padding: 8px;
    margin: 0;
    cursor: pointer;
    background: ${({ theme }) => theme.material.colors.background.default} !important;
`;

const DialogStyled = styled(Dialog)`
  & .MuiDialog-paper {
    padding: 16px;
  }
`;

export const defualtState = {
    header: 'Confirmation',
    message: 'Are you sure?',
    confirmButtonText: 'Yes',
    declineButtonText: 'No',
};

class ConfirmationBuilder extends PureComponent<Object, Object> {

    constructor(props: Object) {
        super(props);
        this.state = {
            showModal: false,
            showPreview: false,
            value: props.value || defualtState
        };
    }

    fieldDefinitions = [
        {
            field: 'header',
            type: 'text',
            properties: {
                label: 'Header',
                name: 'header',
            }
        },
        {
            field: 'message',
            type: 'text',
            properties: {
                label: 'Message',
                name: 'message',
            }
        },
        {
            field: 'confirmButtonText',
            type: 'text',
            properties: {
                label: 'Confirm button text',
                name: 'confirmButtonText',
            }
        },
        {
            field: 'declineButtonText',
            type: 'text',
            properties: {
                label: 'Decline button text',
                name: 'declineButtonText',
            }
        },
    ];

    @bind
    toggleModal(e: ?Object) {
        if(e) {
            e.stopPropagation();
        }
        this.setState(state => ({ showModal: !state.showModal}));
    }

    @bind
    onApply(e: Object) {
        if(e) {
            e.stopPropagation();
        }
        const { value } = this.state;
        const { name, onChange } = this.props;
        this.setState(({ showModal }) => ({ showModal: !showModal }), () => onChange({ target: { name, value }}));
    }

    @bind
    handleChange(value: Object) {
        this.setState({ value });
    }

    @bind
    togglePreview(e: Object) {
        if(e) {
            e.stopPropagation();
        }
        this.setState(({ showPreview }) => ({ showPreview: !showPreview }));
    }

    render() {
        const { showModal, value, showPreview } = this.state;
        const { label } = this.props;
        return (
            <>
                <ConfirmationModal {...value} open={showPreview} onClose={this.togglePreview} onApply={this.togglePreview}/>
                <PaperStyled>
                    <Typography variant="caption">{label}</Typography>
                    <Grid>
                        <Tooltip title="Edit" placement="top">
                            <IconButton onClick={this.toggleModal}>
                                <MdiIcon name="pencil" color="inherit" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Preview" placement="top">
                            <IconButton onClick={this.togglePreview}>
                                <MdiIcon name="eye" color="inherit" />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </PaperStyled>
                <DialogStyled
                    open={showModal}
                    onClose={this.toggleModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item container xs={6} alignItems="center">
                            <IconButton aria-label="close" onClick={this.toggleModal}>
                                <MdiIcon name="close" />
                            </IconButton>
                            <Typography variant="h6">{label}</Typography>
                        </Grid>
                        <Grid item container xs={6} justify="flex-end" alignItems="center">
                            <Tooltip title="Preview" placement="top">
                                <IconButton onClick={this.togglePreview}>
                                    <MdiIcon name="eye" color="inherit" />
                                </IconButton>
                            </Tooltip>
                            <Button onClick={this.onApply} color="primary" autoFocus>
                                Apply
                            </Button>
                        </Grid>
                    </Grid>

                    <FormGenerator
                        components={this.fieldDefinitions}
                        ref={this.formRef}
                        data={value}
                        onChange={this.handleChange}
                    />
                </DialogStyled>
            </>
        );
    }
};

export default ConfirmationBuilder;
