import { getPriorityLabel } from 'app/config/aboxConfig';
import { TRANSLATION_KEYS } from 'app/config/changelogConfig';
import { formatDate, isIsoDate } from '../date/date';
import { get, set } from '../lo/lo';
import { isBoolean, isObject } from '../utils';

const singleActions = ['add', 'create', 'remove'];

const checkIfMultipleUpdates = (data) => {
    const action = Object.keys(data.change)[0];

    if (singleActions.includes(action)) return false;

    const change = data.change;
    const updateObj = get(change, 'update', {});
    const hasUpdateKeys = Object.keys(updateObj).length > 1;
    const hasPrimaryUpdateKeys = Object.keys(get(updateObj, 'primary', {})).length > 1;
    const hasAttributeUpdateKeys = Object.keys(get(updateObj, 'attributes', {})).length > 1;
    const hasRelAttributeUpdateKeys = Object.keys(get(change, 'updateItem.update.attributes', {})).length > 1;
    const isClassUpdateKey = 'classes' in updateObj;
    const isEntityIconNameUpdate = 'iconName' in updateObj && 'iconType' in updateObj && Object.keys(updateObj).length === 2; // if update has iconType consider as a single update
    
    if (!isClassUpdateKey && !isEntityIconNameUpdate && (hasUpdateKeys || hasPrimaryUpdateKeys || hasAttributeUpdateKeys || hasRelAttributeUpdateKeys)) {
        return true;
    }

    return false;
};

const normalizeType = (type) => {
    switch (TRANSLATION_KEYS[type]) {
        case 'owner':
        case 'user':
            return 'entityUser';
        case 'script':
            return 'entityTypeScript';
        default:
            return TRANSLATION_KEYS[type] || type;
    }
};

const enrichValue = (value, key) => {
    if (!value && !isBoolean(value)){
        return '';
    }
    if (isIsoDate(value)) {
        return formatDate(value);
    } 
    if (key === 'priority') {
        return getPriorityLabel(value);
    } 
    if (key === 'progress') {
        return `${value}%`;
    } 
    if (key === 'dropzone' && Array.isArray(value)) {
        return value.map(v => v['path'] || '').join(', ');
    }
    if (isBoolean(value)) {
        return !value ? 'false' : 'true';
    } 
    if (isObject(value)) {
        if (value?.id && value?.name) return value?.name;
        return '';
    }
    return value;
};

const checkEmptyAvatar = (avatar, id, type) => {
    if (avatar?.id) return avatar;

    avatar = set(avatar, 'id', id);
    avatar = set(avatar, 'type', TRANSLATION_KEYS[type]);

    return avatar;
};

export {
    checkIfMultipleUpdates,
    normalizeType,
    enrichValue,
    checkEmptyAvatar
};