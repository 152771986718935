/* @flow */

import { gql } from '@apollo/client';

export default gql`
    query entitiesQuery($startIndex: Int, $stopIndex: Int, $filterBy: [JSON], $orderBy: [JSON], $countMax: Int, $type: String!) {
        count: count(dataType: $type, filterBy: $filterBy, max: $countMax)
        records: entities(startIndex: $startIndex, stopIndex: $stopIndex, filterBy: $filterBy, orderBy: $orderBy, type: $type) {
            id
            name
            image
            type
            active
            primaryClass {
                entityTabs
            }
            classes {
                id
                name
                uri
                color
            }
        }
    }
`;
