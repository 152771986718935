/* @flow */

import { gql } from '@apollo/client';
import loadWorkspaceDetailsCommonQuery from 'graphql/workspaces/loadWorkspaceDetailsCommonQuery';

export default gql`
    mutation removeWorkspaceTeamsMutation($id: ID!, $teams: [ID]!) {
        result: workspaceRemoveTeams(id: $id, teams: $teams) {
            ${loadWorkspaceDetailsCommonQuery}
        }
    }
`;
