import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Card from 'app/components/molecules/Card/Card';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import Icon from 'app/components/atoms/Icon/Icon';
import MenuItem from 'app/components/molecules/Menu/MenuItem';
import PopupMenu from 'app/components/molecules/PopupMenu/PopupMenu';
import { get, set } from 'app/utils/lo/lo';
import { getStr, isEmpty } from 'app/utils/utils';
import { loadClassificationIotData } from 'store/actions/classifications/classificationsActions';

const StyledLink = styled(Link)`
    text-decoration: none;
    color: white;
    display: flex;
`;

const CardTitleContainer = styled.h1`
    display: flex;
    flex-direction: column;
    margin: 0 0 0 1rem;
    width: 100%;
    overflow: hidden;
`;

const CardTitle = styled.span`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: ${({ theme }) => theme.material.colors.text.primary};
`;

const CardSummary = styled(CardTitle)`
    font-size: 0.6em;
    color: ${({ theme }) => theme.material.colors.text.secondary};
    font-weight: normal;
    line-height: 1.1;
`;

const filterBySearch = ({ search, formComponents, name, uri}) => {
    let components = formComponents;
    if (search) {
        // if the card match we display everithing inside it
        if (
            !name.toLowerCase().includes(search)
        && !uri.toLowerCase().includes(search)
        ) {
            // the card does not match
            components = formComponents.map((panel) => {
                // filter components (fields)
                const children = panel.children.map((component) => {
                    if ((component.properties.label || '').toLowerCase().includes(search)) {
                        return component;
                    }
                    return set(component, 'properties.hidden', true);
                });

                // filter panels (groups)
                if ((panel.properties.header || '').toLowerCase().includes(search)) {
                    // if the panel match, we show the panel and all its children
                    return panel;
                } else if (children.find(child => !get(child, 'properties.hidden'))) {
                    // if the panel do not match but some children are maching the search,
                    // we show the panel and only the children that match the search
                    return { ...panel, children };
                }
                // if the panel do not match and none of its children match the search,
                // we hide the panel
                return {
                    ...panel,
                    properties: set(panel.properties, 'hidden', true),
                    children,
                };
            });
        }
    }
    return components;
};


const ClassificationItem = React.forwardRef(({
    classification,
    attributes,
    search,
    canEdit,
    isUnCollapsed,
    removeClass,
    entity,
    onChange,
    location,
    context,
    loadClassificationIotData
}, ref) => {
    const { id, uri, name, color, active, inherited, formComponents } = classification;
    const { type, id: entityId } = entity;
    const [isUnCollapsedPanels, setCollapsedPanels] = useState(!!isUnCollapsed);
    const [iotData, setIotData] = useState({});

    const loadIotData = useCallback(async (type, id, iotClassUri) => {
        const result = await loadClassificationIotData(type, id, iotClassUri);
        let iotValues = {};
        const parseChildrens = key => field => {
            if(field.children) {
                parseChildrens(key)(field.children);
            }
            if(field.properties.name.includes(key)) {
                iotValues = set(iotValues, field.properties.name, result[key]);
            }
        };

        Object.keys(result).forEach(key => {
            formComponents.forEach(panel => {
                panel.children.forEach(parseChildrens(key));
            });
        });
        setIotData(iotValues);
    }, [loadClassificationIotData, setIotData, formComponents]);
    
    useEffect(() => {
        setCollapsedPanels(isUnCollapsed);
    }, [isUnCollapsed]);

    useEffect(()=>{
        formComponents.forEach(item => {
            item.type === 'iotPanel' && loadIotData (type, entityId, uri);
        });
    },[type, entityId, uri, formComponents, loadIotData]);

    const togglePanels = useCallback(() => {
        setCollapsedPanels(!isUnCollapsedPanels);
    }, [isUnCollapsedPanels]);

    let cardStyle = null;
    let components = useMemo(() => {
        const filteredComponents = filterBySearch({ search, formComponents, name, uri }) || [];
        return filteredComponents;
    }, [formComponents, name, search, uri]);
    if (!(components).find(panel => !get(panel, 'properties.hidden')) && !isEmpty(components)) {
        cardStyle = { display: 'none' };
    }

    components = useMemo(() => components.map(panel => ({
        ...panel,
        properties: { ...panel.properties, expanded: isUnCollapsedPanels === null ? panel.properties.expanded : isUnCollapsedPanels}
    })), [components, isUnCollapsedPanels]);

    if(search && !formComponents?.length) {
        const searchValues = [name.toLowerCase(), uri.toLowerCase()];
        if (!searchValues.some(value => value.includes(search.toLowerCase()))) {
            return null;
        };
    }

    return (
        <Card
            key={id}
            style={cardStyle}
            transparent
            headerColor="transparent"
            title={
                <>
                    <Icon name="label" hexColor={color} />
                    <CardTitleContainer>
                        <CardTitle title={name}>{name}</CardTitle>
                        <CardSummary title={uri}>
                            {uri}
                            {!active ? ' (Inactive)' : ''}
                        </CardSummary>
                    </CardTitleContainer>
                </>
            }
            description={(
                <FormGenerator
                    ref={ref}
                    components={components}
                    data={{...attributes, ...iotData}}
                    onChange={onChange}
                    context={context}
                    disabled={!canEdit}
                />
            )}
            headerActions={
                <PopupMenu
                    placement="top right"
                    nowrap
                    content={
                        <>
                            {components.length ? (
                                <MenuItem
                                    name={isUnCollapsedPanels ? 'Minimize groups' : 'Expand groups'}
                                    icon={isUnCollapsedPanels ? 'chevron-up': 'chevron-down'}
                                    onClick={togglePanels}
                                />
                            ) : null}
                            <StyledLink to={{
                                pathname: `/classifications/${id}/attributes/editor`,
                                state: {
                                    navigateBackLink: getStr(location, 'pathname', ''),
                                    navigateBackTitle: entity.name
                                }
                            }}>
                                <MenuItem name="Go to Attributes" icon="exit-to-app" />
                            </StyledLink>
                            {!inherited && canEdit && (
                                <MenuItem name="Remove Class" icon="delete" onClick={() => removeClass(id, name)} />
                            )}
                        </>
                    }
                >
                    <Icon name="dots-vertical" />
                </PopupMenu>
            }
        />
    );
});
ClassificationItem.propTypes = {
    classification: PropTypes.shape({
        id: PropTypes.string.isRequired,
        uri: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        color: PropTypes.string,
        active: PropTypes.bool,
        inherited: PropTypes.bool,
        children: PropTypes.arrayOf(PropTypes.object),
        formComponents: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
    attributes: PropTypes.object.isRequired,
    search: PropTypes.string,
    canEdit: PropTypes.bool.isRequired,
    isUnCollapsed: PropTypes.bool,
    removeClass: PropTypes.func.isRequired,
    entity: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
    }).isRequired,
    onChange: PropTypes.func.isRequired
};


export default connect(
    null,
    {
        loadClassificationIotData,
    },
    null, { forwardRef: true }
)
(ClassificationItem);
