import { toUniqueUUID } from 'app/utils/string/string-utils';
import { formatDate, calculateDuration } from 'app/utils/date/date';
import { muiTheme } from 'app/themes/materialUi';
import { StringHelper } from '@bryntum/schedulerpro';
import { get } from 'app/utils/lo/lo';

const timelineConfig  = (openSidebar) => ({
    resourceImagePath: window.location.origin,
    width:'100%',
    height:'100%',
    features:{
        group:false,
        eventDragCreate: false,
        eventContextMenu: false,
        scheduleMenu: false,
    },
    taskEditFeature:false,
    eventEditFeature:false,
    eventMenuFeature:false,
    eventResizeFeature:false,
    eventDragFeature:false,
    cellEditFeature:false,
    cellMenuFeature:false,
    scheduleTooltipFeature:false,
    timeAxisHeaderMenuFeature:false,

    createEventOnDblClick: false,
    eventDragCreateFeature: false,
    eventContextMenuFeature: false,
    scheduleMenuFeature: false, 
    
    percentBarFeature:{
        showPercentage:true,
        valueField:'percentDone',
        allowResize:false,
    },
    timeRangesFeature : {
        enableResizing: true,
        showCurrentTimeLine: true,
        showHeaderElements: true,
        tooltipTemplate: ({ timeRange }) => timeRange.name
    },
    eventTooltipFeature : {
        // Tooltip configs can be used here
        allowOver: true,
        align : 'l-r', // Align left to right,
        tools : [
            {
                cls: 'mdi mdi-import',
                style: {color: muiTheme.colors.text.secondary},
                handler : function() {
                    openSidebar(this.eventRecord.originalData.id, 'Task');
                }
            },
            {
                cls: 'mdi mdi-chat',
                style: {color: muiTheme.colors.text.secondary},
                handler : function() {
                    openSidebar(this.eventRecord.originalData.id, 'A-Live');
                }
            },
            {
                cls: 'mdi mdi-information-outline',
                style: {color: muiTheme.colors.text.secondary},
                handler : function() {
                    openSidebar(this.eventRecord.originalData.id, 'About');
                }
            }
        ],
        header : {
            titleAlign : 'start'
        },

        onBeforeShow({ source : tooltip }) {
            tooltip.title = StringHelper.xss `<div style="letter-spacing:1.6px;margin-left:10px;color:${muiTheme.colors.text.secondary};">TASK</div>`;
        },
        // A custom HTML template
        template : data =>{
            const priority = data.eventRecord.originalData.priority.toLowerCase();
            const priorityTaskColors = muiTheme.colors.priorityTaskColors;
            return `<div style="border-bottom: 2px solid ${!data.eventRecord.originalData.isOpenTask ? muiTheme.colors.background.divider 
                : priority === 'highest' ? get(priorityTaskColors, `1.1`) 
                    : priority === 'high'? get(priorityTaskColors, `2.1`) 
                        : priority === 'medium'? get(priorityTaskColors, `3.1`) 
                            : priority === 'low' ? get(priorityTaskColors, `4.1`) 
                                : get(priorityTaskColors, `5.1`)};">
                <div style="margin:0px 10px;">
                    <div style="display:flex; align-items:center">
                        <i class="mdi mdi-checkbox-marked-circle-outline" style="font-size:24px; color:${muiTheme.colors.text.secondary};"></i>
                        <div style="display:flex; flex:1; justify-content:center; flex-direction:column; margin-left:10px;">
                            <span style="font-size:16px; letter-spacing:0.25px; font-weight:500px; color:${muiTheme.colors.text.secondary};">${data.eventRecord.originalData.name}</span>
                            <span style="font-size:12px; letter-spacing:0.4px; font-weight:400px; color:${muiTheme.colors.text.caption};">#${toUniqueUUID(data.eventRecord.originalData.id)}</span>
                        </div>
                    </div>
                    <hr stlye="border-top:1px solid ${muiTheme.colors.background.divider}; margin:10px;">
                    <div style="display:flex; flex-direction:column; margin:15px 0px; font-size:12px; letter-spacing:0.4px; font-weight:400px;">
                        <div style="display:flex; flex:1; justify-content:space-between;">
                            <span style="color:${muiTheme.colors.text.secondary};"> Start date </span>
                            <span style="font-size:12px; color:${muiTheme.colors.text.primary};">${formatDate(data.eventRecord.originalData.startDate,'DD MMMM, YYYY')}</span>
                        </div>
                        <div style="display:flex; justify-content:space-between; margin-top:12px;">
                            <span style="color:${muiTheme.colors.text.secondary};"> Due date </span>
                            <span style="font-size:12px; color:${muiTheme.colors.text.primary};">${formatDate(data.eventRecord.originalData.endDate,'DD MMMM, YYYY')}</span>
                        </div>
                        <div style="display:flex; flex:1; justify-content:space-between; margin-top:12px;">
                            <span style="color:${muiTheme.colors.text.secondary};"> Duration </span>
                            <span style="font-size:12px; color:${muiTheme.colors.text.primary};">${data.eventRecord.originalData.endDate ? Math.round(calculateDuration(data.eventRecord.originalData.startDate,data.eventRecord.originalData.endDate,'days')) : '??'} Days</span>
                        </div>
                        <div style="display:flex; flex:1; justify-content:space-between; margin-top:12px;">
                            <span style="color:${muiTheme.colors.text.secondary};"> Completed </span>
                            <span style="font-size:12px; color:${muiTheme.colors.text.primary};">${data.eventRecord.originalData.percentDone}%</span>
                        </div>

                    </div>
                </div>
            </div>`;
        }, 
    }, 
});

export { timelineConfig };
