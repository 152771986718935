/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Autocomplete } from '@mic3/platform-ui';

import { loadDesignerFormVersionTypeahead } from 'store/actions/designer/designerFormActions';

import { bind } from 'app/utils/decorators/decoratorUtils';
import { sortBy } from 'app/utils/lo/lo';


/**
 * Select one or more groups using lazy loading.
 */
class FormDefinitionVersionTypeahead extends PureComponent<Object, Object> {

    static propTypes = {
        loadDesignerFormVersionTypeahead: PropTypes.func.isRequired,
        formDefinitionId: PropTypes.string,
    }

    constructor(props) {
        super(props);
        this.state = { options: [] };
        this.loadOptions();
    }

    componentDidUpdate(prevProps) {
        const { formDefinitionId } = this.props;
        if(formDefinitionId !== prevProps.formDefinitionId) {
            this.loadOptions();
        }
    }

    @bind
    async loadOptions() {
        const { formDefinitionId, loadDesignerFormVersionTypeahead } = this.props;
        if(formDefinitionId) {
            const options = await loadDesignerFormVersionTypeahead(formDefinitionId);
            const versionsList = sortBy(options.map(op => ({ value: op.primary.version, label: `Version ${op.primary.version}` })), 'value').reverse();
            this.setState({ options: [{ value: '', label: 'Always latest version' }, ...versionsList] });
        }

    }


    render() {
        const { loadDesignerFormVersionTypeahead, formDefinitionId, disabled, ...restProps } = this.props;
        const { options } = this.state;
        return (
            <Autocomplete
                placeholder="Search for a version..."
                {...restProps}
                disabled={!formDefinitionId || disabled}
                options={options}
            />
        );
    }
};


export default connect(
    null, { loadDesignerFormVersionTypeahead }
)(FormDefinitionVersionTypeahead);
