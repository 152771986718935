/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, CircularProgress } from '@mic3/platform-ui';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import { bind } from 'app/utils/decorators/decoratorUtils';
import { addEventDataAttribute, loadEventTypeDetails, updateEventDataAttribute } from 'store/actions/stream/eventsActions';


const typesOption = [
    {label: 'Text', value: 'none'},
    {label: 'Link', value: 'link'},
    {label: 'Date', value: 'date'},
    {label: 'JSON', value: 'json'},
];


const formDefinitions = [
    {
        type: 'typeahead',
        properties: {
            label: 'Type',
            name: 'format',
            valueField: 'format',
            options: typesOption,
            clearable: false,
        },
        constraints: { required: true },

    },
    {
        type: 'text',
        properties: { label: 'label', name: 'label' },
        constraints: { required: true },
    },
    {
        type: 'text',
        properties: {
            label: 'Value',
            name: 'attribute',
            valueField: 'attribute',
        },
        constraints: { required: true },
    }
];
class AddEventTypeAttribute extends PureComponent<Object, Object> {
    static propTypes = {
        addEventType: PropTypes.func.isRequired,
    };

    formRef = React.createRef();
    state = {
        isLoading: false,
    }

    @bind
    normalizeEventAttributeData(data) {
        const { eventTypeId, selectedAttribute } = this.props;
        const record = { ...data };
        if (selectedAttribute?.id) {
            record.id = selectedAttribute.id;
        } else {
            record.eventType = eventTypeId;
        }
        return record;
    }

    @bind
    onFormSubmit(event: Event) {
        event.preventDefault();
        const { 
            eventTypeId,
            loadEventTypeDetails,
            addEventDataAttribute,
            onClose,
            selectedAttribute,
            updateEventDataAttribute
        } = this.props;
        this.formRef.current.isValidForm().then(({ data, errors }) => {
            if (!errors) {
                const normalizedData = this.normalizeEventAttributeData(data);
                this.setState({isLoading: true});
                if(selectedAttribute?.id){
                    updateEventDataAttribute(normalizedData).then((result) => {
                        this.setState({isLoading: false});
                        if(!(result instanceof Error) && result.id){
                            loadEventTypeDetails(eventTypeId);
                            onClose && onClose();
                        };
                    });
                } else {
                    addEventDataAttribute(normalizedData).then((result) => {
                        this.setState({isLoading: false});
                        if(!(result instanceof Error) && result.id){
                            loadEventTypeDetails(eventTypeId);
                            onClose && onClose();
                        };
                    });
                }
            }
        });
    }

    @bind
    onClose() {
        const { onClose } = this.props;
        if (onClose) {
            this.props.onClose();
        }
    }

    render(): Object {
        const { onClose, selectedAttribute } = this.props;
        const { isLoading } = this.state;
        return (
            <>
                <ModalDialog
                    title={`${selectedAttribute ? `Edit`:`Add`} Attribute`}
                    onClose={onClose}
                    actions={isLoading ? (
                        <CircularProgress size={24} color='primary' />
                    ) :(
                        <Button onClick={this.onFormSubmit}>Save</Button>
                    )}
                >
                    <FormGenerator
                        components={formDefinitions}
                        ref={this.formRef}
                        data={selectedAttribute}
                    />
                </ModalDialog>
            </>
        );
    }
}

export default connect(
    null,
    {
        addEventDataAttribute,
        loadEventTypeDetails,
        updateEventDataAttribute
    }
)(AddEventTypeAttribute);
