/* @flow */
import Immutable from 'app/utils/immutable/Immutable';
import HttpFetch from 'app/utils/http/HttpFetch';
import OptionsBuilder from 'app/utils/api/OptionsBuilder';
import { loadData, mutateData } from 'app/utils/redux/action-utils';
import { get } from 'app/utils/lo/lo';
import { notifyBroadcasts } from 'app/utils/notification/notify-broadcast';
import { getArray } from 'app/utils/utils';
import { graphql } from 'graphql/client';
import activeBroadcastsQuery from 'graphql/broadcast/activeBroadcastsQuery';
import loadBroadcastTypeaheadQuery from 'graphql/broadcast/loadBroadcastTypeaheadQuery';
import loadAvatarQuery from 'graphql/broadcast/loadAvatarQuery';
import broadcastQuery from 'graphql/broadcast/broadcastQuery';
import broadcastsQuery from 'graphql/broadcast/broadcastsQuery';
import broadcastsCalendarQuery from 'graphql/broadcast/broadcastsCalendarQuery';
import updateBroadcastMutation from 'graphql/broadcast/updateBroadcastMutation';
import createBroadcastMutation from 'graphql/broadcast/createBroadcastMutation';
import readBroadcastMutation from 'graphql/broadcast/readBroadcastMutation';
import { getAvatar } from 'app/utils/avatar/avatar';

export const GET_BROADCASTS_STARTED = '@@affectli/broadcasts/GET_BROADCASTS_STARTED';
export const GET_BROADCASTS = '@@affectli/broadcasts/GET_BROADCASTS';

export const GET_BROADCASTS_CALENDAR_STARTED = '@@affectli/broadcasts/GET_BROADCASTS_CALENDAR_STARTED';
export const GET_BROADCASTS_CALENDAR = '@@affectli/broadcasts/GET_BROADCASTS_CALENDAR';

export const GET_BROADCAST_STARTED = '@@affectli/broadcasts/GET_BROADCAST_STARTED';
export const GET_BROADCAST = '@@affectli/broadcasts/GET_BROADCAST';

export const SAVE_BROADCAST = '@@affectli/broadcasts/SAVE_BROADCAST';
export const SAVE_BROADCAST_STARTED = '@@affectli/broadcasts/SAVE_BROADCAST_STARTED';

export const GET_ACTIVE_BROADCASTS_STARTED = '@@affectli/broadcasts/GET_BROADCASTS_NOTIFICATIONS_STARTED';
export const GET_ACTIVE_BROADCASTS = '@@affectli/broadcasts/GET_BROADCASTS_NOTIFICATIONS';

export const MARK_BROADCAST_READ_STARTED = '@@affectli/broadcasts/MARK_BROADCAST_READ_STARTED';
export const MARK_BROADCAST_READ = '@@affectli/broadcasts/MARK_BROADCAST_READ';

export const LOAD_BROADCAST_TYPEAHEAD_STARTED = '@@affectli/broadcasts/LOAD_BROADCAST_TYPEAHEAD_STARTED';
export const LOAD_BROADCAST_TYPEAHEAD = '@@affectli/broadcasts/LOAD_BROADCAST_TYPEAHEAD';

export const LOAD_AVATAR_STARTED = '@@affectli/broadcasts/LOAD_AVATAR_STARTED';
export const LOAD_AVATAR = '@@affectli/broadcasts/LOAD_AVATAR';

export const loadBroadcastTypeahead = loadData(LOAD_BROADCAST_TYPEAHEAD_STARTED, LOAD_BROADCAST_TYPEAHEAD, loadBroadcastTypeaheadQuery);

export const loadAvatar = id => (dispatch: Function, getState: Function) => {
    const avatar = getAvatar(getState(), 'broadcast', id);
    if (avatar && (avatar.isLoading || avatar.loadedAt > Date.now() - 60 * 60 * 1000)) {
        // if we are loding the avatar or it is already loaded and it is stored less then 1 hour ago
        // we return the data in the state without calling the service
        return Promise.resolve(avatar);
    }
    return loadData(LOAD_AVATAR_STARTED, LOAD_AVATAR, loadAvatarQuery)({ id })(dispatch, getState);
};

/**
 * Loads the broadcasts for the DataTable
 *
 * @param options the options ({ page, pageSize, countMax, where, orderBy, download })
 */
export const fetchBroadcasts =
  (data) => {
      const variables = new OptionsBuilder(data.options)
          .filter({ field: 'parent', op: 'is null' })
          .build();
      return loadData(GET_BROADCASTS_STARTED, GET_BROADCASTS, broadcastsQuery)(variables);
  };

/**
 * Loads the broadcasts for the Calendar
 *
 * @param options the options ({ where, orderBy })
 */
export const fetchBroadcastsCalendar = loadData(GET_BROADCASTS_CALENDAR_STARTED, GET_BROADCASTS_CALENDAR, broadcastsCalendarQuery);

/**
 * Fetch the specified broadcast.
 */
export const fetchBroadcast = loadData(GET_BROADCAST_STARTED, GET_BROADCAST, broadcastQuery);

/**
 * Saves (creates or updates) the specified broadcast.
 */
export const saveBroadcast = (record: Object) => mutateData(SAVE_BROADCAST_STARTED, SAVE_BROADCAST, record.id ? updateBroadcastMutation : createBroadcastMutation, 'Broadcast correctly saved.')({ record });

export const fetchBroadcastNotifications = () => {
    return (dispatch: Function): void => {
        dispatch({ type: GET_ACTIVE_BROADCASTS_STARTED });
        graphql.query({
            query: activeBroadcastsQuery,
            fetchPolicy: 'no-cache',
        }).then((response: Object): void => {
            // show the broadcast notifications
            const broadcasts = getArray(response, 'data.broadcasts') || [];
            notifyBroadcasts(broadcasts);

            dispatch({ type: GET_ACTIVE_BROADCASTS, payload: Immutable(get(response, 'data')) });
        }).catch((error) => {
            dispatch({ type: GET_ACTIVE_BROADCASTS, payload: error, error: true });
        });
    };
};

/**
 * Fetch Single Broadcast Notification
 */
export const fetchBroadcastNotification = ({ page = 1, pageSize = 10, orderBy, where }: Object = {}) => {
    return (dispatch: Function/*, getState: Function*/): void => {
        dispatch({ type: GET_ACTIVE_BROADCASTS_STARTED });

        HttpFetch.postResource('api/jrp/broadcasts/members', {
            kendo: false,
            orderBy,
            where,
        }).then((data) => {
            dispatch({ type: GET_ACTIVE_BROADCASTS, payload: Immutable(data) });
        }).catch((error) => {
            dispatch({ type: GET_ACTIVE_BROADCASTS, payload: error, error: true });
        });
    };
};

/**
 * Save broadcast notification
 */
export const markBroadcastRead = (id: string) => (dispatch: Function) => {
    dispatch({ type: MARK_BROADCAST_READ_STARTED });
    return graphql.mutate({
        mutation: readBroadcastMutation,
        variables: { id },
        fetchPolicy: 'no-cache',
    }).then((response) => {
        dispatch({
            type: MARK_BROADCAST_READ,
            payload: get(response, 'data.result'),
            meta: Immutable({ successMessage: 'Broadcast marked as read.' })
        });
    }).catch((error) => {
        dispatch({ type: MARK_BROADCAST_READ, payload: error, error: true });
        return error;
    });

};
