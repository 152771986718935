import React, { PureComponent, Fragment, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import { Divider, Button, CircularProgress, Menu, MenuItem, MdiIcon, IconButton } from '@mic3/platform-ui';

import styled from 'styled-components';
import { muiTheme } from 'app/themes/materialUi';

import { bind } from 'app/utils/decorators/decoratorUtils';
import ExpansionPanel from 'app/components/Designer/ExpansionPanel';
import { showToastr } from 'store/actions/app/appActions';
import Icon from 'app/components/atoms/Icon/Icon';
import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import { classDigitalTwinAdd, classDigitalTwinUpdate, classDigitalTwinRemove } from 'store/actions/classifications/classificationsActions';
import { ListItem, Name, Description } from 'app/components/molecules/UploadButton/UploadFileField';
import { useToggle } from 'app/utils/hook/hooks';
import { getPermissions } from 'app/config/rolesConfig';

const StyledIcon = styled(Icon)`
    padding-right: 1rem;
    vertical-align: middle;
`;

const ThreeDotMenu = (props) => {
    const { onRemove, isDefault, onEdit, setDefault, disabled } = props;
    const anchorMenuIcon = useRef(null);
    const [isMenuOpen, toggleMenu] = useToggle();
    const handleAction = useCallback(
        (action) => {
            toggleMenu();
            switch (action) {
                case 'edit':
                    return onEdit();
                case 'default':
                    return setDefault();
                case 'remove':
                    return onRemove();
                default:
                    break;
            }
        },
        [onRemove, onEdit, setDefault, toggleMenu]
    );

    return (
        <Fragment>
            <IconButton buttonRef={anchorMenuIcon} onClick={toggleMenu} edge='end'>
                <MdiIcon name='dots-vertical' />
            </IconButton>
            <Menu open={isMenuOpen} anchorEl={anchorMenuIcon.current} onClose={toggleMenu}>
                <MenuItem disabled={disabled} onClick={() => handleAction('edit')}>
                    <StyledIcon name='pencil' /> Edit
                </MenuItem>
                {!isDefault ? (
                    <MenuItem disabled={disabled} onClick={() => handleAction('default')}>
                        <StyledIcon name='star' /> Set as default
                    </MenuItem>
                ) : null}
                <MenuItem disabled={disabled} onClick={() => handleAction('remove')}>
                    <StyledIcon name='close' /> Remove
                </MenuItem>
            </Menu>
        </Fragment>
    );
};

const dtOtions = [
    { label: 'Script', value: 'script' },
    { label: 'iFrame', value: 'iframe' },
];

const DividerStyled = styled(Divider)`
    margin: 20px 0 !important;
`;

const DefaultChip = styled.div`
    font-size: x-small;
    color: ${({theme})=> theme.material.colors.text.button};
    width: 3rem;
    background-color: ${({theme})=> theme.material.colors.background.activeElement};
    height: 18px;
    text-align: center;
    line-height: 1.8;
    border-radius: 14px;
`;
const Actions = styled.div`
    display: flex;
    width: 5rem;
    align-items: center;
    justify-content: space-between;
`;

const StyledExpansionPanel = styled(ExpansionPanel)`
    max-width: 960px;
    margin: 16px auto !important;
`;

export const normalizeDTConfig = (c) => {
    if (!c) {
        return {};
    }
    const config = { ...c };
    if (config.type === 'script') {
        config.src = null;
        config.parameters = null;
    } else {
        config.script = null;
    }
    return config?.script?.id ? { ...config, script: config?.script?.id } : config;
};

class DigitalTwinConfigSection extends PureComponent {
    state = {
        isAddModalOpen: false,
        formData: {},
    };

    defaultState = { ...this.state };

    formRef = React.createRef();

    formDefinitions = [
        {
            type: 'text',
            properties: { label: 'Digital twin name', name: 'name' },
            constraints: { required: true },
        },
        {
            type: 'typeahead',
            properties: {
                label: 'Type',
                name: 'type',
                options: dtOtions,
                valueField: 'value',
            },
            constraints: { required: true },
        },
        {
            type: 'text',
            properties: { label: 'URL', name: 'src', isVisible: (data) => data.type === 'iframe' },
            constraints: { required: true },
        },
        {
            type: 'scriptTypeahead',
            properties: { label: 'Script', name: 'script', isVisible: (data) => data.type === 'script' },
            constraints: { required: true },
        },
    ];

    @bind
    toggleAddDTModal() {
        this.setState((prevState) => ({ isAddModalOpen: !prevState.isAddModalOpen, formData: {} }));
    }

    @bind
    handleChange(formData: Object, { name, value }: Object) {
        const { formData: { name: dtName, id, isDefault } = {} } = this.state;
        if (name === 'type') {
            formData = { name: dtName, [name]: value, id, isDefault }; // Preserve Digital Twin name, id, isDefault and reset all other data
        }
        this.setState({ formData });
    }

    @bind
    onFormSubmit(event: Event) {
        event.preventDefault();
        const { id } = this.props.classification || {};
        if (!id) {
            return;
        }
        this.formRef.current.isValidForm().then(async ({ data: formData, errors }) => {
            const classDigitalTwin = normalizeDTConfig(formData);
            if (!errors) {
                const { id: dtId } = formData;
                if (dtId) {
                    // It means that Digital Twin was already saved we just need to update it
                    const response = await this.props.classDigitalTwinUpdate({ id, classDigitalTwin });
                    if (!(response instanceof Error)) {
                        this.toggleAddDTModal();
                    }
                    return response;
                }
                await this.props.classDigitalTwinAdd({ id, classDigitalTwin });
                this.toggleAddDTModal();
            }
        });
    }

    @bind
    onEdit(formData) {
        this.setState({ isAddModalOpen: true, formData });
    }

    @bind
    setDefault(config) {
        if (!config) {
            return;
        }
        const {
            classification: { id },
        } = this.props;
        return this.props.classDigitalTwinUpdate({ id, classDigitalTwin: { ...normalizeDTConfig(config), isDefault: true } });
    }

    @bind
    onRemove(classDigitalTwinId) {
        const {
            classification: { id },
        } = this.props;
        this.props.classDigitalTwinRemove({ id, classDigitalTwinId });
    }

    @bind
    renderAddDTModal() {
        const { isLoading } = this.props;
        const { isAddModalOpen, formData } = this.state;
        if (!isAddModalOpen) {
            return;
        }
        return (
            <ModalDialog
                title={`${formData?.id ? 'Edit' : 'Add'} Digital twin`}
                onClose={this.toggleAddDTModal}
                actions={
                    isLoading ? (
                        <CircularProgress size={24} color='primary' />
                    ) : (
                        <>
                            <Button variant='text' onClick={this.toggleAddDTModal}>
                                Cancel
                            </Button>
                            <Button onClick={this.onFormSubmit}>Save</Button>
                        </>
                    )
                }
            >
                <FormGenerator components={this.formDefinitions} ref={this.formRef} data={formData} onChange={this.handleChange} />
            </ModalDialog>
        );
    }

    render() {
        const { classification } = this.props;
        const permissions = getPermissions(classification && classification.role);
        const { canEdit } = permissions || {};
        const disabled = !canEdit;
        return (
            <StyledExpansionPanel expanded header='Entity Digital Twin Configuration' collapsible>
                <br />
                <Button
                    startIcon={<Icon size='sm' name='plus' hexColor={muiTheme.colors.text.button} />}
                    size='small'
                    color='primary'
                    onClick={this.toggleAddDTModal}
                    style={{ width: '160px' }}
                    disabled={disabled}
                >
                    ADD DIGITAL TWIN
                </Button>
                <DividerStyled />
                {classification?.digitalTwins?.map((config, index) => {
                    const { id, name, type, isDefault } = config || {};
                    const menu = (
                        <ThreeDotMenu
                            isDefault={isDefault}
                            onRemove={() => this.onRemove(id)}
                            onEdit={() => this.onEdit(config)}
                            setDefault={() => this.setDefault(config)}
                            disabled={disabled}
                        />
                    );
                    return (
                        <ListItem key={id} style={{ margin: '0 5px' }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Name>{name}</Name>
                                <Description>{type}</Description>
                            </div>
                            {isDefault ? (
                                <Actions>
                                    <DefaultChip>Default</DefaultChip>
                                    {menu}
                                </Actions>
                            ) : (
                                menu
                            )}
                        </ListItem>
                    );
                })}

                {this.renderAddDTModal()}
            </StyledExpansionPanel>
        );
    }
}

export default connect(
    (state: Object, ownProps: Object) => ({
        isLoading: state.classifications.details.isLoading,
    }),
    {
        showToastr,
        classDigitalTwinAdd,
        classDigitalTwinUpdate,
        classDigitalTwinRemove,
    },
    null,
    {
        forwardRef: true,
    }
)(DigitalTwinConfigSection);
