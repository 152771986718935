/* @flow */

import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Avatar, Divider, Typography, Grid, ListItemText } from '@mic3/platform-ui';
import { Link } from 'react-router-dom';

import { getAttachmentUrl } from 'app/utils/attachments/attachmentsUtils';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { get } from 'app/utils/lo/lo';
import { iconsSet } from 'app/utils/styles/mdi';

import Icon from 'app/components/atoms/Icon/Icon';
import TypeIcon from 'app/components/atoms/TypeIcon/TypeIcon';
import EntityLink from 'app/components/atoms/Link/EntityLink';
import { EntityTitle } from 'app/containers/Entities/EntitiesList/EntitiesListItem';
import ChipClosed from 'app/components/atoms/ChipClosed/ChipClosed';

const StyledGrid = styled(Grid)`
    ${({ opensidebar }) => (opensidebar ? 'cursor: pointer' : '')}
`;

const AvatarStyled = styled(Avatar)`
    width: 24px !important;
    height: 24px !important;
    font-size: 1rem !important;
`;

export const CardContainer = styled.div`
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    cursor: pointer;

    &:hover {
        background: ${({ theme, selected }) => theme.material.colors.background.hover};
    }
    ${({ theme, selected }) => selected && `background: ${theme.material.colors.itemActive} !important;`}
`;

export const ListItemTextStyled = styled(ListItemText)`
    position: relative;
    flex: 1;

    & .MuiListItemText-primary {
        display: flex;
        align-items: center;
    }

    & .MuiListItemText-secondary {
        display: flex;
    }
`;

export const CardDivider = styled(Divider)`
    && {
        margin: 0 12px;
        height: 16px;
        position: relative;
        top: 12px;
    }
`;

export const EntityLinkStyled = styled(EntityLink)`
    && {
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    font-size: 14px;
    font-weight: 400;
    font-family: Roboto;
    line-height: 20px;
    letter-spacing: 0.25px;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
    color: ${({ theme }) => theme.material.colors.text.primary} !important;
`;

export const EntityLinkStyledSubTitle = styled(Typography)`
    && {
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    display: block;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.material.colors.text.secondary} !important;
`;

export const LinkIcon = styled(Icon)`
    margin-left: 4px;
    cursor: pointer;
    &::before {
        color: ${({ theme }) => theme.material.colors.text.secondary} !important;
    }
`;

export const ProcessAvatar = styled.div`
    & .MuiAvatar-root {
        ${({ iconColor }) => (iconColor ? `background: ${iconColor};` : '')}
    }
`;

export const LinkStyled = styled(Link)`
    && {
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    font-size: 14px;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
    color: ${({ theme }) => theme.material.palette.text.primary} !important;
`;

const AvatarWrapper = styled.div`
    margin-right: 16px;
`;

class GridEntityName extends PureComponent<Object, Object> {
    @bind
    @memoize()
    renderAvatar(type, image, name, assignee, modifiedDate, primary, processDefinitionVersion) {
        switch (type) {
            case 'opentask':
            case 'closedtask': {
                const { priority, progress, closedDate } = primary || {};
                const assigneeImage = assignee?.image && getAttachmentUrl(get(assignee, 'id'), 'user', assignee.image);
                return (
                    <Avatar
                        CircularProgressStaticProps={{
                            value: progress || 0,
                            priority,
                            disabled: !!closedDate
                        }}
                        initials={get(assignee, 'name') || null}
                        src={assigneeImage}
                        title = {assignee?.name}
                    />
                );
            }
            case 'openprocess':
            case 'closedprocess': {
                const { iconName, iconColor } = processDefinitionVersion || {};
                const { closedDate } = primary || {};
                return (
                    <ProcessAvatar iconColor={iconColor}>
                        <Avatar
                            CircularProgressStaticProps={{
                                value: 0,
                                disabled: !!closedDate,
                                priority: get(primary, 'priority')
                            }}
                        >
                            <Icon name={iconsSet.has(iconName) ? iconName : 'asterisk'} />
                        </Avatar>
                    </ProcessAvatar>
                );
            }
            default:
                return <AvatarStyled src={image} initials={name} />;
        }
    }

    @bind
    @memoize()
    renderIcon(type, iconName, iconColor) {
        const iconType = iconsSet.has(iconName) ? 'mdi' : 'af';
        if (iconName) {
            return <Icon name={iconName} size="sm" hexColor={iconColor} type={iconType} />;
        }
        return <TypeIcon type={this.props.entityType ? 'entityType' : type} />;
    }

    render() {
        const { value, data, type, openSidebar, onClick, readOnly, adminBackgroundJobs } = this.props;

        if(!value) {
            return null;
        }

        const { image: src, modifiedDate, name, assignee, id, type: entityType, iconName, iconColor, primary, processDefinitionVersion } =
            data || {};
        const image = src ? getAttachmentUrl(id, type, src) : null;

        return (
            <StyledGrid container wrap="nowrap" alignItems="center" opensidebar={openSidebar ? 1 : 0} onClick={onClick}>
                <AvatarWrapper>{this.renderAvatar(entityType, image, name, assignee, modifiedDate, primary, processDefinitionVersion)}</AvatarWrapper>
                {this.renderIcon(entityType, iconName, iconColor)}
                <CardDivider flexItem orientation="vertical" />
                {openSidebar || readOnly ? (
                    name || 'No Name'
                ) : this.props.entitiesView ? (
                    <EntityTitle data={data} type={type} />
                ) : (
                    <EntityLinkStyled variant="body2" id={id} type={type} entityType={primary} adminBackgroundJobs={adminBackgroundJobs}>
                        {name || 'No Name'}
                    </EntityLinkStyled>
                )}
                { data?.type === 'closedtask' && <ChipClosed />}
            </StyledGrid>
        );
    }
}

export default GridEntityName;
