// @flow

import { connect } from 'react-redux';
import React, { PureComponent } from 'react';
import styled, { withTheme, css } from 'styled-components';
import { muiTheme } from 'app/themes/materialUi';
import { Divider, Tooltip } from '@mic3/platform-ui';
import PropTypes from 'prop-types';

import DotMenu from 'app/components/molecules/DotMenu/DotMenu';
import TypeIcon from 'app/components/atoms/TypeIcon/TypeIcon';
import Avatar from 'app/components/molecules/Avatar/Avatar';
import Icon from 'app/components/atoms/Icon/Icon';

import { getAttachmentUrl } from 'app/utils/attachments/attachmentsUtils';
import { addRoleToData, getPermissions } from 'app/config/rolesConfig';
import { getFieldByType } from 'app/utils/designer/form/fieldUtils';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { getAvatar, createInitials } from 'app/utils/avatar/avatar';
import { iconsSet } from 'app/utils/styles/mdi';
import { fromNow } from 'app/utils/date/date';
import { isEmpty } from 'app/utils/utils';
import { get } from 'app/utils/lo/lo';

import {
    setMessagesAsRead,
    setRoomAsFavoriteUnfavorite,
    unsubscribeEntityChat,
    unsubscribeGroupChat,
    unsubscribeChannelChat,
    getRoomHistoryByType
} from 'store/actions/chat/chatActions';
import { loadAvatar } from 'store/actions/app/appActions';
import history from 'store/History';

const extendedStyles = `
    height: 72px;
    padding: 16px;
`;
const mediumStyles = `
    height: 56px;
    padding: 8px 16px;
`;
const condensedStyles = `
    height: 32px;
    padding: 4px 16px;
`;

const aliveStyles = `
    height: 48px;
    padding: 4px 16px;
    background: ${muiTheme.colors.background.paper};
    &:hover{
        background-color: ${muiTheme.colors.background.hover} !important;
    }
    cursor: pointer;
`;


const ItemContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    max-width: calc(100%-200px);
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    ${({ disabled }) => (disabled ? 'opacity: 0.1;' : '')}
    ${({ isSelected, theme }) => (isSelected ? `background: ${theme.material.colors.background.active};` :` background-color: ${theme.material.colors.background.default};`)}
    ${({ appearance }) => (appearance === 'extended' ? css`${extendedStyles}` : '')}
    ${({ appearance }) => (appearance === 'medium' ? css`${mediumStyles}` : '')}
    ${({ appearance }) => (appearance === 'condensed' ? css`${condensedStyles}` : '')}
    ${({ isAliveWidget }) => (isAliveWidget ? css`${aliveStyles}` : '')}
`;
const ItemTextWrapper = styled.div`
    padding: 0 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex: 1 1 0%;
`;
const ItemSecondaryTextWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;
const PrimaryTextWrapper = styled.div`
    display: flex;
    align-items: center;
`;
const PrimaryTextStyled = styled.p`
    margin: ${({ margin }) => (margin ? `${margin} !important` : '0')};
    ${({ appearance }) => (appearance === 'extended' ? 'font-weight: 400;' : 'font-weight: 500;')}
    cursor: ${({ $isMobile }) => $isMobile ? 'auto' : 'pointer' };
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
    letter-spacing: 0.25px;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: ${({theme})=> theme.material.colors.text.primary};
`;
const SecondaryTextStyled = styled.p`
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: ${({theme})=> theme.material.colors.text.caption};
    margin: 0;
    cursor: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
`;
const StyledDivider = styled(Divider)`
    margin: 4px 8px !important;
    max-height: 16px !important;
`;
const ItemActionWrapper = styled.div`
    display: flex;
    align-items: center;
    & .MuiIconButton-root {
        ${({ appearance }) => (appearance === 'condensed' ? 'padding: 4px !important' : '')}
    }
`;
const UnreadChatDot = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    border-radius: 11px;
    color: ${({ theme }) => theme.material.colors.text.darkChip };
    font-size: 12px;
    line-height: 16px;
    ${({ notifType, theme }) => notifType === 'default' && `background: ${theme.material.colors.primary.main};`}
    ${({ notifType }) => notifType === 'me' && `background: #f5455c;`}
    ${({ notifType, theme }) => notifType === 'user' && `background: ${theme.material.colors.primary.main};`}
    ${({ notifType }) => notifType === 'group' && `background: #f38c39;`}
`;
const ProcessAvatar = styled.div`
    & > div {
        padding: 0 !important;
    }
    & > div > div > svg {
        height: ${({ size }) => `${size}px !important;`};
        width: ${({ size }) => `${size}px !important;`};
    }
    & .MuiAvatar-root {
        ${({ iconColor }) => iconColor ? `background: ${iconColor};` : ''}
    }
`;
const TaskAvatar = styled.div`
    & > div {
        padding: 0 !important;
    }
    & > div > div > svg {
        height: ${({ size }) => `${size}px !important;`};
        width: ${({ size }) => `${size}px !important;`};
    }
`;
const AvatarCondensedStyles = `
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.4px;
    min-width: auto !important;
`;
const AvatarWrapper = styled.div`
    ${({isAliveWidget})=> isAliveWidget ? 'width: 24px !important;':''}
    & .avatar {
        ${({ appearance }) => (appearance === 'condensed' ? css`${AvatarCondensedStyles}` : '')}
    }
`;

const AvatarStyled = styled(Avatar)`
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
`;

/**
 * A single task item
 */
class ALiveItem extends PureComponent<Object, Object> {
    static propTypes = {
        appearance: PropTypes.oneOf(['extended', 'medium', 'condensed']).isRequired,
        data: PropTypes.shape({
            rid: PropTypes.string, // the chat room's ID
            unread: PropTypes.number, // unread messags counter
            rel: PropTypes.shape({
                id: PropTypes.string.isRequired, // the ID of the entity/process/task
                type: PropTypes.string.isRequired
            }),
            updatedAt: PropTypes.string,
            lastMessage: PropTypes.object
        }).isRequired,
        hideMoreOptions: PropTypes.bool, 
        chatRoomId: PropTypes.string, 
        handleClick: PropTypes.func,
        setMessagesAsRead: PropTypes.func,
        setRoomAsFavoriteUnfavorite: PropTypes.func,
        unsubscribeEntityChat: PropTypes.func,
    };

    constructor(props) {
        super(props);
        const { id, type } = get(props.data, 'rel', {});
        this.state = { 
            canAccess: false, 
            notifType: type !== 'direct' ? 'default' : 'me' // default || me || user || group 
        };
        if (['channel', 'direct', 'group', 'live'].includes(type)) return;
        if (isEmpty(props.details)) this.processAdditionalChatData(id, type, props.loadAvatar);
    }

    // $FlowFixMe
    anchorDotsEl = React.createRef();

    processAdditionalChatData(id, type, loadAvatar) {
        const processExtraQueryProps = `
            primary
            processDefinitionVersion {
                iconName 
                iconColor
            }
        `;            
        const taskExtraQueryProps = `
            image
            primary
            assignee {
                id
                name
                image
                username
            }
            modifiedDate
        `;
        const taskTypes = ['task', 'opentask', 'closedtask'];
        const processTypes = ['process', 'openprocess', 'closedprocess'];
        if (taskTypes.includes(type)) {
            loadAvatar(id, type, taskExtraQueryProps);
        } else if (processTypes.includes(type)) {
            loadAvatar(id, type, processExtraQueryProps);
        } else {
            loadAvatar(id, type);
        }
    }
    
    async processChatRole() {
        const { data } = this.props;
        const { customFields } = data || {};
        const { id, type } = data.rel || {};
        const isEntityChat = !isEmpty(customFields) && !Boolean(get(customFields, 'teamId'));

        if (isEntityChat) {
            const { role } = await addRoleToData({ id, type });
            const permissions = getPermissions(role) || {};
            const { canEdit, canComment } = permissions || {};
            this.setState({ canAccess: canEdit || canComment });
        }
    }

    async loadUnreadMessages() {
        const { 
            data: { t, rid, unread } = {}, 
            roomMessages 
        } = this.props || {};
        if (isEmpty(roomMessages) && unread && t !== 'd') {
            let reqType = ''; 
            switch (t) {
                case 'd':
                    reqType = 'im';
                    break;
                case 'c':
                    reqType = 'channels';
                    break;
                case 'p':
                    reqType = 'groups';
                    break;
                default:
                    break;
            }
            const queryParams = { rid, reqType, count: unread };
            await this.props.getRoomHistoryByType(queryParams);
        }
    }

    processNotificationType(unreadMessages) {
        const { notifType } = this.state;
        let copyNotifType = notifType;
        const { primary: { username: primaryUsername } ={} } = this.props.profile || {};
        unreadMessages.forEach(({ mentions }) => {
            if (!isEmpty(mentions)) {
                mentions.forEach(({ username }) => {
                    const isMentionTypeUser = !['all', 'here'].includes(username);
                    if (isMentionTypeUser) {
                        const isCurrentUser = primaryUsername === username;
                        if (isCurrentUser && copyNotifType !== 'me') {
                            this.setState({ notifType: 'me' });
                            copyNotifType = 'me';
                        } else {
                            if (!['me', 'group'].includes(copyNotifType)) {
                                this.setState({ notifType: 'user' });
                                copyNotifType = 'user';
                            }
                        }
                    } else {
                        if (!['me', 'group'].includes(copyNotifType)) {
                            this.setState({ notifType: 'group' });
                            copyNotifType = 'group';
                        }
                    }
                });
            }
        });
    }

    componentDidMount() {
        const { isAliveWidget } = this.props;
        if (!isAliveWidget){
            this.processChatRole();
            this.loadUnreadMessages();
        }
    }

    componentDidUpdate(prevProps: Object) {
        const { roomMessages: prevRoomMessages } = prevProps || {};
        const { data, roomMessages } = this.props || {};
        const { unread, rel } = data || {};
        if (rel.type !== 'direct') {
            if (unread && !isEmpty(roomMessages)) {
                if (prevRoomMessages.length !== roomMessages.length) {
                    if (roomMessages.length >= unread) {
                        const unreadMessages = roomMessages.slice(-unread);
                        this.processNotificationType(unreadMessages);
                    }
                }
            } 
        }
    }

    @bind
    goToChat() {
        const { id, type } = this.props.data.rel;
        this.props?.handleClick && this.props.handleClick();
        history.push(`/abox/alive/${type}/${id}`);
    }

    @bind
    onDotMenuClick(title) {
        const {
            profile,
            data,
            setMessagesAsRead,
            setRoomAsFavoriteUnfavorite,
            unsubscribeEntityChat,
            unsubscribeGroupChat,
            unsubscribeChannelChat
        } = this.props;
        const { unread, rel, rid, customFields } = data || {};
        const { id, type } = rel;
        const isEntityChat = !isEmpty(customFields);

        switch (title) {
            case 'Mark as read':
                if (Boolean(unread)) setMessagesAsRead(rid);
                break;
            case 'Favourite':
                setRoomAsFavoriteUnfavorite(rid, true);
                break;
            case 'Unfavourite':
                setRoomAsFavoriteUnfavorite(rid, false);
                break;
            case 'Mute notification':
                break;
            case 'Leave from chat':
            case 'Unsubscribe from chat':
                if (isEntityChat) {
                    unsubscribeEntityChat({ id, type, userId: profile.id, rid }).then((res) => {
                        const isError = get(res, 'error', false);
                        if (!isError) history.push(`/abox/alive`);
                    });  
                } else if (type === 'group') {
                    unsubscribeGroupChat(rid).then((res) => {
                        const isError = get(res, 'error', false);
                        if (!isError) history.push(`/abox/alive`);
                    });
                } else if (type === 'channel') {
                    unsubscribeChannelChat(rid).then((res) => {
                        const isError = get(res, 'error', false);
                        if (!isError) history.push(`/abox/alive`);
                    });
                } 
                break;
            default:
                break;
        }
    }

    @bind
    @memoize()
    renderAvatar(id, type, isEntityChat, appearance, isAliveWidget) {
        const { details, data } = this.props;
        const { name: relName, image: relSrc } = data.rel || {};
        const { image: src, name, assignee, primary, processDefinitionVersion } = details || {};
        let size = appearance === 'condensed' ? 24 : 40; 
        if(isAliveWidget){
            size = 24;
        }
        let image = null;
        if (isEntityChat && !isEmpty(src)) {
            image = getAttachmentUrl(id, type, src);
        } else if (!isEmpty(relSrc)) {
            image = getAttachmentUrl(id, type, relSrc);
        }

        switch (type) {
            case 'task': 
            case 'opentask': 
            case 'closedtask': {
                const { priority, progress, closedDate } = primary || {};
                return (
                    <TaskAvatar size={size}>
                        {getFieldByType('avatar', {
                            CircularProgressStaticProps: {
                                value: progress || 0,
                                priority,
                                disabled:!!closedDate,
                            },
                            initials: createInitials(get(assignee, 'name')) || (name || 'No Name'),
                            src: assignee && assignee.image && getAttachmentUrl(get(assignee, 'id'), 'user', get(assignee, 'image'))
                        })}
                    </TaskAvatar>
                );
            }
            case 'process': 
            case 'openprocess': 
            case 'closedprocess': {
                const { iconName, iconColor } = processDefinitionVersion || {};
                const { closedDate } = primary || {};
                return (
                    <ProcessAvatar iconColor={iconColor} size={size}>
                        {getFieldByType('avatar', {
                            CircularProgressStaticProps: {
                                value: 0,
                                disabled:!!closedDate,
                                priority: get(primary, 'priority'),
                            },
                            children: <Icon name={iconsSet.has(iconName) ? iconName : 'asterisk'} />
                        })}
                    </ProcessAvatar>
                );
            }
            default:
                return (
                    <AvatarWrapper appearance={appearance} isAliveWidget={isAliveWidget} >
                        <AvatarStyled
                            name={relName} 
                            initials={createInitials(relName)}
                            alt={relName} 
                            src={image} 
                            width={`${size}px`} 
                            height={`${size}px`} 
                            lineHeight={`${size}px`} 
                        />
                    </AvatarWrapper>
                );
        }
    }

    @bind
    @memoize()
    renderPrimaryText(id, name, type, isEntityChat, appearance, isMobile) {
        const parsedType = type !== 'user' ? type : 'person';
        return (
            <>
                {id ? (
                    <PrimaryTextWrapper>
                        {isEntityChat ? <TypeIcon type={'group'} /> : <TypeIcon type={type} />}
                        <StyledDivider orientation='vertical' flexItem />
                        {isEntityChat ? (
                            <>
                                <TypeIcon type={isEntityChat ? parsedType : 'customClassEntity'} />
                                <Tooltip title={name || 'No Name'}>
                                    <PrimaryTextStyled $isMobile={isMobile} className='title' margin='0 0 0 10px'>{name || 'No Name'}</PrimaryTextStyled>
                                </Tooltip>
                            </>
                        ) : (
                            <>
                                {type === 'direct' && <TypeIcon type='user' />}
                                <Tooltip title={name || 'No Name'}>
                                    <PrimaryTextStyled $isMobile={isMobile} className='title' margin={type === 'direct' ? '0 0 0 10px' : '0'}>
                                        {name || 'No Name'}
                                    </PrimaryTextStyled>
                                </Tooltip>
                            </>
                        )}
                    </PrimaryTextWrapper>
                ) : (
                    `You have no more permissions to see this chat`
                )}
            </>
        );
    }

    @bind
    @memoize()
    renderExtendInfo(lastMessage, isEntityChat, type) {
        const { canAccess } = this.state;
        const { primary: { username } = {} } = this.props.profile || {};
        const { user: { username: lastMsgUsername, name } = {}, text, createDate, file: { name: fileName } = {} } = lastMessage || {};

        return (
            <>
                {!isEmpty(lastMessage) ? (
                    <ItemSecondaryTextWrapper>
                        {(isEntityChat && type !== 'team') ? (
                            <>
                                {canAccess ? (
                                    <>
                                        <SecondaryTextStyled>
                                            {`${lastMsgUsername === username ? 'You' : `${name || lastMsgUsername}`}: ${text || fileName}`}
                                        </SecondaryTextStyled>
                                        <SecondaryTextStyled>{fromNow(createDate)}</SecondaryTextStyled>
                                    </>
                                ) : (
                                    <SecondaryTextStyled>You do not have permission to view this message.</SecondaryTextStyled>
                                )}
                            </>
                        ) : (
                            <>
                                <SecondaryTextStyled>
                                    {`${lastMsgUsername === username ? 'You' : `${name || lastMsgUsername}`}: ${text || fileName}`}
                                </SecondaryTextStyled>
                                <SecondaryTextStyled>{fromNow(createDate)}</SecondaryTextStyled>
                            </>
                        )}
                        
                    </ItemSecondaryTextWrapper>
                ) : (
                    <SecondaryTextStyled>No messages yet</SecondaryTextStyled>
                )}
            </>
        );
    }


    render() {
        const { appearance, data, chatRoomId, hideMoreOptions, lastMessage, isMobile, isAliveWidget } = this.props;
        const { canAccess, notifType } = this.state;
        const { unread, t, rel, customFields, f } = data || {};
        const { id, name, type } = rel || {};
        const isActiveRoom = id === chatRoomId;
        const isEntityChat = !isEmpty(customFields);
        const moreOptions = [
            { name: 'Mark as read', icon: 'flag', disabled: !Boolean(unread) || (isEntityChat && !canAccess) },
            { name: !f ? 'Favourite' : 'Unfavourite', icon: !f ? 'star-outline' : 'star' }
        ];
        if (['p', 'c'].includes(t) && !Boolean(get(customFields, 'teamId'))) {
            moreOptions.push({
                name: isEntityChat ? 'Unsubscribe from chat' : 'Leave from chat', 
                icon: isEntityChat ? 'close' : 'logout-variant'
            });
        }

        return (
            <>
                {appearance === 'extended' && (
                    <ItemContainer isAliveWidget={isAliveWidget} appearance={appearance} isSelected={isActiveRoom} disabled={!id} onClick={this.goToChat}>
                        {this.renderAvatar(id, type, isEntityChat, appearance, isAliveWidget)}
                        <ItemTextWrapper>
                            {this.renderPrimaryText(id, name, type, isEntityChat, appearance, isMobile)}
                            {this.renderExtendInfo(lastMessage, isEntityChat, type)}
                        </ItemTextWrapper>
                        <ItemActionWrapper appearance={appearance} >
                            {(isEntityChat && type !== 'team') ? (
                                (Boolean(unread) && !isActiveRoom && canAccess) && <UnreadChatDot notifType={notifType}>{unread}</UnreadChatDot>
                            ) : (
                                Boolean(unread) && !isActiveRoom && <UnreadChatDot notifType={notifType}>{unread}</UnreadChatDot>
                            )}
                            {!hideMoreOptions && <DotMenu
                                key={id}
                                onItemClick={this.onDotMenuClick}
                                items={moreOptions.filter(Boolean)}
                                tooltipTitle='More Options'
                            />}
                        </ItemActionWrapper>
                    </ItemContainer>
                )}
                {appearance === 'medium' && (
                    <ItemContainer isAliveWidget={isAliveWidget} appearance={appearance} isSelected={isActiveRoom} disabled={!id} onClick={this.goToChat}>
                        {this.renderAvatar(id, type, isEntityChat, appearance, isAliveWidget)}
                        <ItemTextWrapper>
                            {this.renderPrimaryText(id, name, type, isEntityChat, appearance, isMobile)}
                        </ItemTextWrapper>
                        <ItemActionWrapper appearance={appearance}>
                            {(isEntityChat && type !== 'team') ? (
                                (Boolean(unread) && !isActiveRoom && (canAccess || isAliveWidget)) && 
                                <UnreadChatDot notifType={notifType}>{unread}</UnreadChatDot>
                            ) : (
                                Boolean(unread) && !isActiveRoom && <UnreadChatDot notifType={notifType}>{unread}</UnreadChatDot>
                            )}
                            {!hideMoreOptions && <DotMenu
                                key={id}
                                onItemClick={this.onDotMenuClick}
                                items={moreOptions.filter(Boolean)}
                                tooltipTitle='More Options'
                            />}
                        </ItemActionWrapper>
                    </ItemContainer>
                )}
                {appearance === 'condensed' && (
                    <ItemContainer isAliveWidget={isAliveWidget} appearance={appearance} isSelected={isActiveRoom} disabled={!id} onClick={this.goToChat}>
                        {this.renderAvatar(id, type, isEntityChat, appearance, isAliveWidget)}
                        <ItemTextWrapper>
                            {this.renderPrimaryText(id, name, type, isEntityChat, appearance, isMobile)}
                        </ItemTextWrapper>
                        <ItemActionWrapper appearance={appearance}>
                            {(isEntityChat && type !== 'team') ? (
                                (Boolean(unread) && canAccess && !isActiveRoom) && <UnreadChatDot notifType={notifType}>{unread}</UnreadChatDot>
                            ) : (
                                Boolean(unread) && !isActiveRoom && <UnreadChatDot notifType={notifType}>{unread}</UnreadChatDot>
                            )}
                            {!hideMoreOptions && <DotMenu
                                key={id}
                                onItemClick={this.onDotMenuClick}
                                items={moreOptions.filter(Boolean)}
                                tooltipTitle='More Options'
                            />}
                        </ItemActionWrapper>
                    </ItemContainer>
                )}
            </>
        );
    }
}

export default connect(
    (state, props) => {
        const { data, appearance } = props;
        const { rid, rel, lastMessage: lMessage, customFields } = data || {};
        const { id, type } = rel || {};
        const chatRoomId = get(state.chat.room, 'rel.id', null);
        const isEntityChat = !isEmpty(customFields) && !Boolean(get(customFields, 'teamId'));
        const details = isEntityChat ? getAvatar(state, type, id) : {};

        const messages = get(state.chat.room, 'messages', {});
        const selectedMessages = get(messages, `${rid}`, []);
        let lastMessage = {};

        if (appearance === 'extended') {
            if (isEmpty(selectedMessages)) {
                if (!isEmpty(lMessage)) {
                    lastMessage = {...lMessage};
                }
            } else {
                const selectedFilteredMessages = [...selectedMessages].filter(({ type }) => isEmpty(type));
                lastMessage = selectedFilteredMessages.pop();
            }
        }

        return {
            chatRoomId,
            details,
            roomMessages: selectedMessages,
            lastMessage,
            profile: state.user.profile, 
            isMobile: state.global.isMobile
        };
    },
    {
        loadAvatar,
        setMessagesAsRead,
        setRoomAsFavoriteUnfavorite,
        unsubscribeEntityChat,
        unsubscribeGroupChat,
        unsubscribeChannelChat,
        getRoomHistoryByType
    }
)(withTheme(ALiveItem));
