// @flow

export default {
    moduleName: 'Integrations',
    type: 'integration',
    classId: 'c0ad2bc9-45f2-4464-a34a-a7445b5057df',
    iconName: 'power-plug',
    iconColor: '#2471A3',
    baseRoute: '/integration',
    path: 'integration',
    permissions: {
        view: 'integration',
        add: 'integration.add'
    },
    getAttributes: data => data?.primary?.['integration/attributes'],
    getUrl: data => data?.primary?.['integration/url'],
    isVisibleOnSmallScreen: data => data?.primary?.['integration/visible_on_small_screen'],
    getMenuIcon(title){
        if (!title) {
            return console.error('IntergrationConfig -> getMenuIcon -> variable title is required'); // eslint-disable-line
        }
        return `alpha-${title.toLowerCase().charAt(0)}-box`;
    },
};