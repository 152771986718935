/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { TablePagination, Typography } from '@mic3/platform-ui';
import { connect } from 'react-redux';
import { BryntumGrid } from '@bryntum/grid-react';
import { Store } from '@bryntum/grid/grid.umd';
import '@bryntum/grid/grid.classic-dark.css';
import { isMobile } from 'react-device-detect';

import { openEntitySidebar } from 'store/actions/entities/entitySidebarActions';
import { openTeamSidebar } from 'store/actions/entities/teamSidebarActions';
import { openClassSidebar } from 'store/actions/entities/classSidebarActions';
import { openProcessSidebar } from 'store/actions/abox/processSidebarActions';
import { openWorkspaceSidebar } from 'store/actions/entities/workspaceSidebarActions';
import { openTaskSidebar } from 'store/actions/abox/taskSidebarActions';
import { openEventSidebar } from 'store/actions/stream/eventsSidebarActions';
import { saveComponentState } from 'store/actions/component/componentActions';
import { saveUserPreferences } from 'store/actions/admin/usersActions';

import { set } from 'app/utils/immutable/Immutable';
import { bind, debounce, memoize } from 'app/utils/decorators/decoratorUtils';
import { get } from 'app/utils/lo/lo';
import { fromNow } from 'app/utils/date/date';
import { isDefined, arrayObjectEquals, deepEquals } from 'app/utils/utils';
import { getEntityUrl } from 'app/utils/entity/entityUtils';
import { removeDuplicates } from 'app/utils/array/array-utils';
import { buildDotMenu } from 'app/utils/entity/entityUtils';
import { getPriorityLabel } from 'app/config/aboxConfig';

import Loader from 'app/components/atoms/Loader/Loader';
import GridEntityName from 'app/components/organisms/GridView/GridRenderers/GridEntityName';
import GridEntityAssignee from 'app/components/organisms/GridView/GridRenderers/GridEntityAssignee';
import GridProcessStarted from 'app/components/organisms/GridView/GridRenderers/GridProcessStarted';
import GridEntityId from 'app/components/organisms/GridView/GridRenderers/GridEntityId';
import GridDueDate from 'app/components/organisms/GridView/GridRenderers/GridDueDate';
import GridEntityUser from 'app/components/organisms/GridView/GridRenderers/GridEntityUser';
import GridEntityClasses from 'app/components/organisms/GridView/GridRenderers/GridEntityClasses';
import GridEntityRelations from 'app/components/organisms/GridView/GridRenderers/GridEntityRelations';
import GridEntitySharing from 'app/components/organisms/GridView/GridRenderers/GridEntitySharing';
import GridEntityActions from 'app/components/organisms/GridView/GridRenderers/GridEntityActions';
import GridEntityPriority from 'app/components/organisms/GridView/GridRenderers/GridEntityPriority';
import GridTasks from 'app/components/organisms/GridView/GridRenderers/GridTasks';
import LocationValue from 'app/components/molecules/Grid/Renderers/Location/LocationValue';
import Icon from 'app/components/atoms/Icon/Icon';

const BryntumGridWrapper = styled.div`
    width: 100%;
    height: ${({ height, isMobile }) => height ? height : `calc(100% - ${isMobile ? '94px' :' 40px'})`};
    & .b-gridbase,
    & .b-grid-header {
        background-color: ${({ theme }) => theme.material.colors.background.default} !important;
    }
    & .b-grid-header-container{
        background-color: ${({ theme }) => theme.material.colors.background.default};
        border-bottom: 2px solid ${({theme})=> theme.material.colors.bryntum.grid.bottom } !important;
    }
    & .b-gridbase:not(.b-column-resizing) .b-grid-header-container:not(.b-dragging-header) .b-depth-0:hover,
    & .b-gridbase:not(.b-column-resizing) .b-grid-header-container:not(.b-dragging-header) .b-depth-0:focus {
        background-color: ${({ theme }) => theme.material.colors.bryntum.grid.hoverHeader} !important;
    }
    & .b-gridbase:not(.b-moving-splitter) .b-grid-row:not(.b-group-row).b-hover .b-grid-cell,
    & .b-grid-row.b-selected,
    & .b-grid-body-container:focus .b-grid-row.b-selected {
      background-color: ${({ theme }) => theme.material.colors.itemActive};
    }
    & .b-gridbase:not(.b-moving-splitter) .b-grid-row:not(.b-group-row).b-hover .b-grid-cell,
    & .b-grid-row.b-hover {
        background-color: ${({ theme }) => theme.material.colors.background.hover} !important;
    }
    & .b-grid-header, & .b-grid-cell {
        border-right: 0;
        color: ${({ theme }) => theme.material.colors.text.secondary};
    }
    .b-grid-cell.b-focused:after {
        border: 2px solid ${({theme})=> theme.material.colors.appNav.linkActiveBgColor} !important;
    }
    & .b-gridbase.b-sort .b-grid-header.b-sort .b-grid-header-text::after {
      color: ${({ theme }) => theme.material.colors.bryntum.grid.titleTextAct};
    }
    & .b-gridbase.b-sort .b-grid-header.b-sort .b-grid-header-text {
        color: ${({ theme }) => theme.material.colors.bryntum.grid.titleTextAct};
    }
    & .b-grid-header-text-content {
        font-weight: 500;
    }
    & .b-grid-header .b-level-0 .b-depth-0 .b-grid-header-resizable .b-sortable{
        color: ${({ theme }) => theme.material.colors.text.secondary};
    }
    & .b-grid-panel-body {
        background-color: ${({ theme }) => theme.material.colors.background.default} !important;
    }
    & .b-grid-row {
        border-bottom: 1px solid ${({theme})=> theme.material.colors.bryntum.grid.bottom };
        & .b-grid-cell {
            font-weight:400;
            font-size: 14px;
        }
    }
    .dropdown-menu {
      background-color: ${({ theme }) => theme.material.colors.background.default};
    }
    .b-overlay-scrollbar .b-virtual-scrollers {
        pointer-events: all !important;
    }
`;

const TablePaginationStyled = styled(TablePagination)`
    background-color: ${({ theme }) => theme.material.colors.background.default};
`;

const Label = styled(Typography)`
    font-size: 14px !important;
    padding-left: 2px;
    font-weight: 400 !important;;
    line-height: 20px !important;;
    letter-spacing: 0.25px !important;;
`;

const getStartStopIndex = (page, pageSize) => {
    const startIndex = page * pageSize;
    // deducting 1 from stop index because indexing start from 0 
    const stopIndex = (startIndex + pageSize) - 1 ;
    return { startIndex, stopIndex };
};

const getPagePageSize = (startIndex, stopIndex) => {
    const pageSize = stopIndex - startIndex + 1;
    return { pageSize, page: (stopIndex + 1) / pageSize -1};
};

const defaultState = { page: 0, pageSize: 10, orderBy: [], columns: [], storeKey: 0 };

class GridView extends PureComponent {
  
    static propTypes = {
        columns: PropTypes.array.isRequired,
        loadData: PropTypes.func,
        list: PropTypes.array,
        isLoading: PropTypes.bool,
        itemCount: PropTypes.number,
        type: PropTypes.string,
        sidebarTitle: PropTypes.string
    };
    

    gridRef = React.createRef()
    gridRefLoaded = false;

    constructor(props) {
        super(props);
        const { loadData, orderBy, filterBy, customColumns, id, userPreferences } = this.props;
        const { startIndex, stopIndex } = userPreferences?.pageSize?.[id] || {};
        this.state = { ...defaultState };
        if(isDefined(startIndex)) {
            this.state = {
                ...this.state,
                ...getPagePageSize(startIndex, stopIndex)
            };
        }
        const { page, pageSize } = this.state;
        loadData && loadData({
            ...getStartStopIndex(page, pageSize),
            filterBy,
            orderBy,
        });
        this.addCustomColumns(customColumns);
    }

    componentDidMount() {
        this.reBuildColumns();
    }

    componentDidUpdate(prevProps, prevState) {
        const { loadData, orderBy, filterBy, viewState, list, type, customColumns, readOnly, isLoading } = this.props;
        if (readOnly) {
            return;
        }
        const { startIndex, stopIndex } = viewState;
        if (type !== prevProps.type) {
            this.reBuildColumns();
        }
        if(
            !deepEquals(orderBy, prevProps.orderBy) ||
            startIndex !== prevProps.viewState?.startIndex ||
            stopIndex !== prevProps.viewState?.stopIndex
        ) {
            loadData && loadData({ filterBy, orderBy, startIndex, stopIndex });
        }
        if(!deepEquals(filterBy, prevProps.filterBy)) {
            this.setState({ page: 0 }, () => {
                loadData && loadData({
                    filterBy, orderBy, startIndex, stopIndex,
                    ...getStartStopIndex(this.state.page, this.state.pageSize),
                });
            });
        }
        if(viewState?.gridKey !== prevProps.viewState?.gridKey) {
            this.gridRef.current.instance.columns.on('change', this.updateColumns);
            this.gridRefLoaded = true;
        }
        if(!viewState?.columns) {
            this.reBuildColumns();
        }
        if(!arrayObjectEquals(list, prevProps.list)) {
            this.resetView({ reloadData: false });
        }
        if (prevProps.isLoading && !isLoading) { // When we reload the data we need to build a new store to fix refreshing issues
            this.setState(prevState => ({ storeKey: prevState.storeKey + 1}));
        }
        this.addCustomColumns(customColumns);
    }

    @bind
    async resetView({ reloadData } = { reloadData: true }) {
        const { loadData, orderBy, filterBy, viewState, saveComponentState, readOnly } = this.props;
        if (readOnly) {
            return;
        }
        const { startIndex, stopIndex } = viewState;
        if(reloadData && loadData) {
            await loadData({ filterBy, orderBy, startIndex, stopIndex });
        }
        await saveComponentState(this.props.id, {
            gridKey: viewState.gridKey ? viewState.gridKey + 1 : 1,
        });
    }

    @bind
    @debounce(100)
    async reBuildColumns() {
        const { viewState, saveComponentState, type, columns } = this.props;
        const { page, pageSize } = this.state ;
        const { gridKey, startIndex, stopIndex, columns: stateColumns } = viewState || {};
        const currentColumns = this.buildColumns(stateColumns || columns, type);
        let options = {
            ...viewState,
            ...getStartStopIndex(page, pageSize),
            gridKey: gridKey ? gridKey + 1 : 1,
            columns: currentColumns.map((clmn, indx) => ({
                ...clmn,
                order: indx,
            }))
        };
        if(isDefined(startIndex)) {
            options = { ...options, startIndex, stopIndex };
        }
        await saveComponentState(this.props.id, options);
    }

    @bind
    @memoize()
    async addCustomColumns(customColumns) {
        if (!customColumns?.length) {
            return;
        }
        const { viewState, saveComponentState, type, columns } = this.props;
        const { gridKey, startIndex, stopIndex, columns: stateColumns } = viewState || {};
        const updatedCustomCols = (stateColumns || columns).map(col => { // If columuns are saved in preferences, then changing the index attribute name 
            let custCol = customColumns?.find(cl => cl?.field === col?.field) || {}; // was not reflecting on grid. It keeps showing the old name saved in preferences
            if(isDefined(col.width)){
                custCol = {...custCol, width: col.width};
            } else {
                custCol = {...custCol, width: custCol?.dataType === 'number' ? 100 : 200};
            }
            return custCol ? { ...col, ...custCol } : col; // So we need to override the saved name with the updated name.
        });
        const cols = removeDuplicates([ ...updatedCustomCols, ...(customColumns || [])], 'field');
        const currentColumns = this.buildColumns(cols, type);
        let options = {
            ...viewState,
            gridKey: gridKey ? gridKey + 1 : 1,
            columns: currentColumns.map((clmn, indx) => ({
                ...clmn,
                order: indx,
            }))
        };
        if(isDefined(startIndex)) {
            options = { ...options, startIndex, stopIndex };
        }
        await saveComponentState(this.props.id, options);
    }

    @bind
    @debounce(150)
    async updateColumns({ changes, action, ...evnt }) {
        if (action !== 'update' && !changes) {
            return;
        }
        const { saveComponentState, viewState, columns, type } = this.props;
        const { columns: stateColumns, gridKey } = viewState || {};
        const currentColumns = this.buildColumns(stateColumns || columns, type);
        if (changes.parentIndex) {
            const movedElemnt = this.buildColumns(stateColumns || columns, type)[changes.parentIndex.oldValue];
            currentColumns.splice(changes.parentIndex.oldValue, 1);
            currentColumns.splice(changes.parentIndex.value, 0, movedElemnt);
            await saveComponentState(this.props.id, {
                ...viewState,
                columns: currentColumns.map((clmn, indx) => ({
                    ...clmn,
                    order: indx,
                })),
                gridKey: gridKey ? gridKey + 1 : 1,
                changed: true,
            });
            return;
        }

        const columnIndex = get(evnt, 'record.data.order');
        if (changes.width)  {
            currentColumns[columnIndex].width = get(changes, 'width.value');
        }
        if (changes.hidden) {
            currentColumns[columnIndex].hidden = get(changes, 'hidden.value');
        }
        await saveComponentState(this.props.id, {
            ...viewState,
            columns: currentColumns,
            changed: true,
        });
    }

    @bind
    handleChangePage(evnt, page) {
        const { saveComponentState, viewState } = this.props;
        const pageSize = get(this.state, 'pageSize');
        this.setState({ page, pageSize }, async () => {
            await saveComponentState(this.props.id, {
                ...viewState,
                ...getStartStopIndex(page, pageSize),
            });
        });
    }

    @bind
    handleChangeRowsPerPage(evnt) {
        const { saveUserPreferences, userPreferences, id } = this.props;
        const pageSize = parseInt(evnt.target.value, 10);
        const page = get(this.state, 'page', 0);
        this.setState({ page, pageSize }, async () => {
            await saveUserPreferences({
                ...userPreferences, 
                pageSize: {...userPreferences.pageSize, [id]:{startIndex: 0, stopIndex: pageSize - 1}}})
        });
    }
    @bind
    async handleSorting({ sorters }) {
        const { saveComponentState, viewState, readOnly } = this.props;
        if (readOnly) {
            return;
        }
        const { page, pageSize } = this.state;
        const orderBy = sorters.map(sort => ({ field: sort.field, direction: sort.ascending ? 'asc nulls last' : 'desc nulls last' }));
        await saveComponentState(this.props.id, {
            ...viewState,
            ...getStartStopIndex(page, pageSize),
            orderBy: orderBy[0],
        });
    }

    @bind
    openSidebar(title, data) {
        const { onSelect, reloadList, entitiesView, readOnly, entityType, adminBackgroundJobs } = this.props;
        const type = entitiesView ? data?.type || this.props.type : this.props.type;
        const params = { id: data.id, type, title, reloadList };

        if (readOnly) {
            return;
        }
        if (data.time) {
            this.props.openEventSidebar({ title: 'About', ...data });
        } else {
            switch (type) {
                case 'class':
                    this.props.openClassSidebar({ ...params, entityType });
                    break;
                case 'task':
                    this.props.openTaskSidebar(params);
                    break;
                case 'process':
                    this.props.openProcessSidebar(params);
                    break;
                case 'workspace':
                    this.props.openWorkspaceSidebar({... params, openWorkspaceSidebar: this.props.openWorkspaceSidebar});
                    break;
                case 'team':
                    this.props.openTeamSidebar(params);
                    break;
                case 'user':
                    if (entitiesView) {
                        return this.props.openEntitySidebar(params);
                    }
                    this.props.openEntitySidebar({ ...params, userAbout: true });
                    break;
                default:
                    this.props.openEntitySidebar({...params, adminBackgroundJobs});
                    break;
            }
        }

        if(onSelect) {
            onSelect(data.id);
        }
    }
    @bind
    @memoize()
    buildDotMenu(entityType, details, moreActionItems, entityTemplates, entityTypePage, printTemplateRecords, printTemplatesMap, isPrintReports, userId) {
        return buildDotMenu({
            isPrintReports,
            details,
            entityType,
            moreActionItems,
            indexView: true,
            entityTemplates,
            entityTypePage,
            printTemplateRecords,
            printTemplatesMap,
            userId });
    }

    @bind
    @memoize()
    buildColumns(columns, type) {
        const { 
            columns: defaultColumns, entitiesView, entityType, pipelineMonitor, 
            readOnly, reloadList, openTaskSidebar, openProcessSidebar 
        } = this.props;

        return columns.map((clmn, index) => {
            const defProps = defaultColumns.find(c => c.field === clmn.field);
            let column = {
                editor: false,
                hidden: !!clmn.hidden,
                order: isDefined(clmn.order) ? clmn.order : index,
                ...clmn,
            };
            if (defProps?.renderer) {
                column = set(column, 'renderer', defProps.renderer);
            }
            switch (column.field) {
                case 'device.id':
                case 'id':
                    return {
                        renderer:  props => props.value ? (
                            <GridEntityId
                                {...props}
                                readOnly={readOnly}
                                label={get(props.record.data, column.field)}
                                value={(!!props.value) && 
                                    getEntityUrl(
                                        get(props.record.data, column.field), props.record.data.type || this.props.type, 
                                        { pipelineMonitor, entityType }
                                    )
                                }
                                valueId={ props.record.data.id }
                                entitiesView={entitiesView}
                                type={type}
                                entityType={entityType}
                                adminBackgroundJobs={this.props.adminBackgroundJobs}
                                // value={entityType ? getEntityUrl(get(props.record.data, column.field), 'entitytype') : getEntityUrl(get(props.record.data, column.field), this.props.type)}
                            />
                        
                        ) : '',
                        width: column.width || 110,
                        sortable : false,
                        ...column,
                    };

                case 'primary.assignee':
                    return {
                        renderer:  ({ value,  record }) => value ? <GridEntityAssignee data={record.data} />: '',
                        width: column.width,
                        ...column,
                    }; 
                case 'name':
                    return {
                        renderer:  ({ value, record, ...rest }) => (
                            <GridEntityName 
                                value={value} readOnly={readOnly} 
                                entitiesView={entitiesView} data={record.data} 
                                type={entitiesView ? record.data?.type  || this.props.type : this.props.type} 
                                entityType={entityType} 
                                adminBackgroundJobs={this.props.adminBackgroundJobs} 
                            />
                        ),
                        width: column.width || 230,
                        ...column,
                    };
                case 'device':
                    return {
                        renderer:  ({ value, record, ...rest }) => <GridEntityName value={value} readOnly={readOnly} data={{...(value || {}), type: 'thing' }} />,
                        width: column.width || 230,
                        ...column,
                    };
                case 'primary.priority':
                    return {
                        renderer:  ({ value }) => value ?  <GridEntityPriority getPriorityLabel={getPriorityLabel} value={value}/> : '',
                        ...column,
                    };
                case 'primary.progress':
                    return {
                        renderer:  ({ value }) =>  <Label>{value ? `${value}%` : `0%`}</Label>,
                        ...column,
                    };
                case 'tasks':
                    return {
                        renderer:  ({ record}) => <GridTasks data={record.data} openSidebar={this.openSidebar}/>,
                        sortable: false,
                        ...column,
                    };
                case 'modifiedDate':
                case 'createdDate':
                case 'primary.startDate':
                case 'primary.endDate':
                    return {
                        renderer:  ({ value }) => <Label>{fromNow(value)}</Label>,
                        ...column,
                    };
                case 'primary.dueDate':
                    return {
                        renderer:  ({record})  => <GridDueDate  record={record}/>,
                        ...column,
                    };
                    
                case 'modifiedBy':
                case 'createdBy':
                    return {
                        width: 160,
                        renderer:  ({ value }) => <GridEntityUser value={value} />,
                        ...column,
                    };
                case 'classes':
                    return {
                        renderer: ({ record }) => {
                            return <GridEntityClasses data={record.data} openSidebar={this.openSidebar}/>;
                        }
                        ,
                        sortable: false,
                        width: column.width || 180,
                        ...column,
                    };
                case 'parents':
                    return {
                        renderer: ({ record }) => <GridEntityClasses  data={record.data} openSidebar={this.openSidebar} />,
                        sortable: false,
                        width: column.width || 180,
                        ...column,
                    };
                case 'description':
                    return {
                        renderer: ({ value }) => value,
                        width: column.width || 180,
                        ...column,
                    };
                case 'primary.locationInfo':
                    return {
                        renderer: LocationValue,
                        width: column.width || 180,
                        sortable: false,
                        ...column,
                    };
                case 'active':
                    return {
                        renderer: ({ value }) =><Label>{value ? 'Active' : 'Inactive'}</Label>,
                        ...column,
                    };
                case 'iconName':
                    return {
                        renderer: ({ record, value }) => value && <Icon type={record.data.iconType} name={value} size={'sm'} hexColor={record.data.iconColor} />,
                        sortable: false,
                        ...column,
                    };
                case 'relations':
                    return {
                        renderer: ({ record }) =>  <GridEntityRelations data={record.data}/>,
                        sortable: false,
                        ...column,
                    };
                case 'primary.variables.INITIATOR':
                    return {
                        renderer:  ({record}) => <GridProcessStarted data={record.data} />,
                        width: 160,
                        sortable: false,
                        ...column,
                    };

                case 'sharing':
                    return {
                        renderer: ({ record }) => {
                            return <GridEntitySharing data={record.data} reloadList={reloadList} entityType={entityType} type={type}
                                openSidebar={type === 'task' ? openTaskSidebar : openProcessSidebar} />;
                        },
                        sortable: false,
                        width: column.width || 180,
                        ...column,
                    };
                case 'Actions':

                    return {
                        renderer: ({ record }) => 
                            <GridEntityActions 
                                data={record.data} 
                                reloadList={reloadList} 
                                entityType={entityType} 
                                type={type}
                                openSidebar={this.openSidebar} 
                                openAliveSidebar={type === 'task' ? openTaskSidebar : openProcessSidebar} 
                                dotMenu={this.buildDotMenu(
                                    this.props.type,
                                    record.data,
                                    this.props.moreActionItems,
                                    this.props.entityTemplates,
                                    this.props.entityType,
                                    this.props.printTemplateRecords,
                                    this.props.printTemplatesMap,
                                    this.props.isPrintReports,
                                    this.props.userProfile?.id)}
                            />,
                        sortable: false,
                        ...column,
                    };

                default:
                    return column;
            }
        });
    }

    listeners = {
        cellMenuBeforeShow: (event) => {
            const { type, list } = this.props;
            const { id }= event;
            const itemEvent = list.find(item => item.id === id);

            let link = `${window.location.href}/${event.id}`;
            switch(type) {
                case 'backgroundjob':
                    link = this.props.id === 'AdminBackgroundJobList' 
                        ? `${window.location.origin}/#/background-job/${id}` 
                        : `${window.location.origin}/#/abox/background-job/${id}`;
                    break;
                case 'event':
                    link = `${window.location.origin}/#/events/${id}/${itemEvent.time}`;
                    break;
                default: 
            }
            event.items = [
                {
                    name: 'OpenInNewTab',
                    text: 'Open link in a new tab',
                    weight: 100,
                    onItem : () => window.open(link,'_blank', 'noreferrer')
                },
                {
                    name: 'OpenInNewWindow',
                    text: 'Open link in a new window',
                    weight: 100,
                    onItem : () => window.open(link, '_blank', 'status=1, fullscrean=1, scrollbars=1, resizable=1' )
                },
            ];
            return true;
        },
        headerMenuBeforeShow: (event) => {
            const { groupAsc, groupDesc, multiSort, ...restMenus } = event.items;
            event.items = restMenus;
        },
        cellclick: (evnt) => {
            if(get(evnt, 'column.data.onClickDisabled') || evnt.column?.data?.field === 'id') {
                return;
            }
            this.openSidebar(this.props.sidebarTitle || 'About', evnt.record.data);
        }
    }

    @bind
    @memoize(deepEquals)
    buildStore({ orderBy, storeKey }) {
        const { readOnly, idField } = this.props;
        const config = {
            sorters: [{
                field :  get(orderBy, 'field'),
                ascending :  !get(orderBy, 'direction', '').includes('desc')
            }],
            listeners:  { sort: this.handleSorting },
            useRawData : !!readOnly,
        };
        if (idField) {
            config.idField = idField;
        }
        return new Store(config);
    }

    render() {
        const { itemCount, list, isLoading, orderBy, viewState, type, columns, height, readOnly } = this.props;
        const { page, pageSize, storeKey } = this.state;
        const { gridKey } = viewState || {};
        const { columns: stateColumns } = viewState || {};

        return list && (
            <>
                {isLoading && <Loader absolute backdrop />}
                <BryntumGridWrapper height={height} isMobile={isMobile}>
                    <BryntumGrid
                        key={gridKey}
                        ref={this.gridRef}
                        data={list || []}
                        columns={this.buildColumns(stateColumns || columns, type)}
                        store={this.buildStore({ orderBy: orderBy?.[0], storeKey })}
                        listeners={this.listeners}
                        features={{group: false}}
                    />
                </BryntumGridWrapper>
                {
                    !readOnly ?
                        <TablePaginationStyled
                            component="div"
                            count={itemCount}
                            rowsPerPage={pageSize || 10}
                            page={page || 0}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        /> : null
                }

            </>
        );
    }
}

export default connect((state, props) => ({
    viewState: get(state, `component.state.${props.id}`, {}),
    userPreferences: state.user.preferences,
}), {
    saveComponentState,
    saveUserPreferences,
    openEntitySidebar,
    openClassSidebar,
    openTeamSidebar,
    openProcessSidebar,
    openWorkspaceSidebar,
    openTaskSidebar,
    openEventSidebar,
}, null, {forwardRef: true})(GridView);
