import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Grid, Typography, MdiIcon } from '@mic3/platform-ui';

import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import { connectorStatusPipeline, connectorRestartPipeline, connectorStartPipeline, connectorStopPipeline } from 'store/actions/designer/designerPipelineActions';
import { bind } from 'app/utils/decorators/decoratorUtils';
import { loadEntity } from 'store/actions/entities/entitiesActions';
import { parseTailStatuses } from 'app/containers/Designer/Pipeline/Components/PipelineWidget';
const Wrapper = styled(Grid)`
    margin: 16px 0px;
    padding: ${({ noPadding }) => !noPadding ? '16px' : '0'};
    ${({ disabled, theme, noBackground }) => `background-color: ${theme.material.colors.background.fields};`}
`;

const StatusIconStyled = styled(MdiIcon)`
    line-height: 75px !important;
    margin: 0 auto 16px auto;
    display: block;
    color: ${({ theme, isErrored }) => isErrored ? theme.material.colors.text.error : theme.material.colors.success.main};
`;
const StatusTypographyStyled = styled(Typography)`
    text-align: center;
    padding: 8px 0 0 0;
`;

class ConnectorStatus extends React.PureComponent {

    @bind
    async handleConnectorRestartPipeline() {
        const { handleConnectorStatus } = this.props;
        handleConnectorStatus('Restart');
    }

    @bind
    async handleConnectorStartPipeline() {
        const { handleConnectorStatus } = this.props;
        handleConnectorStatus('Start');
    }

    @bind
    async handleConnectorStopPipeline() {
        const { handleConnectorStatus } = this.props;
        handleConnectorStatus('Stop');
    }

    render() {
        const { value, details, pipelineStatus, getCurrentConnectorStatus } = this.props;
        
        const { isErrored, errorMessage, status } = parseTailStatuses(value?.isError, value, details, true, pipelineStatus, getCurrentConnectorStatus);
        // console.log('$$$$ [render.CS]', { isErrored, errorMessage, status, value, details, pipelineStatus });
        const connectorStatus = getCurrentConnectorStatus(pipelineStatus, value)?.execution;
        return (
            <>
                <Wrapper noPadding={1}>
                    {(!!status) && <StatusTypographyStyled variant="h5">{status}</StatusTypographyStyled>}
                    {(!!status) && <StatusIconStyled isErrored={isErrored} name={isErrored ? 'alert-circle' : 'check'} size={80} />}
                    <FormGenerator 
                        components={[
                            !connectorStatus && {
                                type: 'button',
                                properties: {
                                    iconName: 'play-box',
                                    label: 'Start',
                                    onClick: this.handleConnectorStartPipeline
                                }
                            },
                            !!connectorStatus && {
                                type: 'button',
                                properties: {
                                    iconName: 'restart',
                                    label: 'Restart',
                                    onClick: this.handleConnectorRestartPipeline
                                }
                            },
                            !!connectorStatus && {
                                type: 'button',
                                properties: {
                                    iconName: 'stop',
                                    label: 'Stop',
                                    onClick: this.handleConnectorStopPipeline
                                }
                            },
                        ].filter(Boolean)}
                    />
                </Wrapper>
                {!!errorMessage && (
                    <Wrapper>
                        <Typography variant="h5">Error</Typography>
                        <Typography >{errorMessage}</Typography>
                    </Wrapper>
                )}
                {connectorStatus && (
                    <Wrapper>
                        <Typography variant="h5">Details</Typography>
                        <Grid container>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Typography variant="caption">Process ID</Typography>
                                    <Typography>{connectorStatus?.pid}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="caption">Read</Typography>
                                    <Typography>{connectorStatus?.readBytes}</Typography>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Typography variant="caption">Memory</Typography>
                                    <Typography>{connectorStatus?.memoryBytes}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="caption">Written</Typography>
                                    <Typography>{connectorStatus?.writtenBytes}</Typography>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Typography variant="caption">Virtual Memory</Typography>
                                    <Typography>{connectorStatus?.virtualMemoryBytes}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="caption">Run Time</Typography>
                                    <Typography>{connectorStatus?.runTimeSecs}</Typography>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Typography variant="caption">CPU usage</Typography>
                                    <Typography>{connectorStatus?.cpuUsage}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="caption">Start</Typography>
                                    <Typography>{connectorStatus?.startTimeSecs}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Wrapper>
                )}
            </>
        );
    }
}

export default connect(
    (state, props) => ({}),
    { 
        connectorStatusPipeline, loadEntity, connectorRestartPipeline,
        connectorStartPipeline, connectorStopPipeline
    
    },
    null,
    { forwardRef: true }
)(ConnectorStatus);