/* @flow */

import React from 'react';
import { Paper, Typography, Button } from '@mic3/platform-ui';
import styled from 'styled-components';
import { DOMParser } from '@xmldom/xmldom';
import isUuid from 'uuid-validate';

import AbstractBuilder from 'app/components/Designer/Builders/AbstractBuilder/AbstractBuilder';
import AbstractTableDnd from 'app/components/Designer/Builders/AbstractBuilder/AbstractTableDnd';
import DragNDropContext from 'app/containers/DragNDropContext/DragNDropContext';
import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import ExecutionListenersFieldsBuilder from './ExecutionListenersFieldsBuilder';
import Loader from 'app/components/atoms/Loader/Loader';
import { bind } from 'app/utils/decorators/decoratorUtils';
import { prettifyXml, parseDomElement } from 'app/utils/designer/process/processDesignerUtils';
import { uuidv4 } from 'app/utils/dataStructure/Forest';

const TypographyPadded = styled(Typography)`
    padding: 12px 0 0 0;
`;

const PaperStyled = styled(Paper)`
    padding: 0 12px;
    margin: 0;
    cursor: pointer;
    background: ${({ theme }) => theme.material.colors.background.default} !important;
`;

class ExecutionListenersBuilder extends AbstractBuilder {

    static defaultProps = {
        fields: [
            { type: 'typeahead', properties: {
                label: 'Event', name: 'event',
                valueField: 'value', clearable: false,
                options: [{ label: 'Start', value: 'start'}, { label: 'Take', value: 'take'}, { label: 'End', value: 'end'}],
                defaultValue: 'start'
            }, constraints: { required: true }},
            { type: 'typeahead', properties: {
                label: 'Implementation Type', name: 'implementationType',
                valueField: 'value', clearable: false,
                options: [{ label: 'Class', value: 'class'}, { label: 'Expression', value: 'expression'}, { label: 'Delegate Expression', value: 'delegateExpression'}],
                defaultValue: 'class'
            }, constraints: { required: true }},
            { type: 'text', properties: {
                label: 'String',
                name: 'implementation',
                clearable: false,
                isVisible: data => data.implementationType !== 'class',
            }, constraints: { required: true }},

            { type: 'typeahead', properties: {
                label: 'Type',
                options: [
                    { label: 'BPMN Script Execution Listener', value: 'bpmn'},
                    { label: 'Node.js Script Execution Listener', value: 'nodeJs' },
                ],
                name: 'type',
                valueField: 'value',
                clearable: false,
                onChange: ({ target }) => {
                    const response = [target, { name: 'script', value: null }];
                    if(target.value === 'bpmn') {
                        response.push({ name: 'implementation', value: 'org.flowable.engine.impl.bpmn.listener.ScriptExecutionListener'});
                        response.push({
                            name: 'fields',
                            value: [{
                                fieldType: 'string',
                                name: 'language',
                                uuid: uuidv4(),
                                value: 'javascript',
                            }, {
                                fieldType: 'string',
                                name: 'script',
                                uuid: uuidv4(),
                                value: '// TODO implement the script',
                            }]
                        }, {
                            value: '// TODO implement the script',
                            name: 'script'
                        });
                    }
                    if(target.value === 'nodeJs') {
                        response.push({ name: 'implementation', value: 'mic3.affectli.flowable.bpmn.listener.GqlScriptExecutionListener'});
                        response.push({
                            name: 'fields',
                            value: []
                        });

                    }
                    return response;
                },
                isVisible: data => data.implementationType === 'class'
            }, constraints: { required: true }},
            {
                type: 'scriptTypeahead',
                properties: {
                    label: 'Script reference',
                    name: 'script',
                    valueField: 'id',
                    onChange: ({ target }) => {
                        return [
                            target, {
                                name: 'fields',
                                value: [{
                                    fieldType: 'string',
                                    name: 'script',
                                    value: target.value,
                                }]
                            }
                        ];
                    },
                    isVisible: data => data.implementationType === 'class' && data.type === 'nodeJs'
                },
                constraints: { required: true }
            }
        ]
    }

    @bind
    deNormalizeValues(definition: Array<Object>): Array<Object> {
        const { data, tagName } = this.props;
        const { id } = data || {};
        if(!id) {
            console.error('Element should has ID for execution listeners.'); // eslint-disable-line no-console
        }

        const doc = new DOMParser().parseFromString(definition);
        const parenElement =  doc.getElementById(id);
        if(!parenElement) {
            return [];
        }
        let executionListeners = null;
        Array.from(parenElement.childNodes || []).forEach((child) => {
            if(child.localName === 'extensionElements') {
                executionListeners =  child.getElementsByTagName(tagName || 'flowable:executionListener');
            }
        });
        return Array.from(executionListeners || []).map((elem) => {
            const flowableFields = elem.getElementsByTagName('flowable:field');
            const value = { event: elem.getAttribute('event') };
            const fields = Array.from(flowableFields).map((fieldElement) => {
                const stringValueElement = fieldElement.getElementsByTagName('flowable:string')[0];
                const expressionValueElement = fieldElement.getElementsByTagName('flowable:expression')[0];
                const field = { name: fieldElement.getAttribute('name') };

                let isBpmn = false;
                let script = null;
                const element = stringValueElement || expressionValueElement;
                if(element) {
                    Array.from(element.childNodes).forEach((ch) => {
                        if(ch.data && !ch.data.match(/^[\n\r\s]+$/)) {
                            script = ch.data.replaceAll(/ {2,}/gm, '');
                        }
                    });
                    isBpmn = !isUuid(script);
                }                
                if(script) {
                    field.value = script;
                    field.fieldType = stringValueElement ? 'string' : 'expression';
                }

                if(stringValueElement && field.name === 'script' && elem.getAttribute('class')) {
                    value.script = script;
                    value.type = isBpmn ? 'bpmn' : 'nodeJs';
                }
                return field;
            });
            value.fields = fields;
            if(elem.getAttribute('delegateExpression')) {
                value.implementationType = 'delegateExpression';
            }
            if(elem.getAttribute('expression')) {
                value.implementationType = 'expression';
            }
            if(elem.getAttribute('class')) {
                value.implementationType = 'class';
            }
            value.implementation = elem.getAttribute(value.implementationType);
            return value;
        });
    }

    @bind
    normalizeValues(value: Array<Object>): Array<any> {
        const { value: definition, data, tagName } = this.props;
        const { id } = data || {};
        if(!id) {
            console.error('Element should has ID for execution listeners.'); // eslint-disable-line no-console
        }

        let doc = new DOMParser().parseFromString(definition);
        let parenElement =  doc.getElementById(id);
        let executionListeners = null;
        let extensionElements = null;
        Array.from(parenElement.childNodes || []).forEach((child) => {
            if(child.localName === 'extensionElements') {
                extensionElements = child;
                executionListeners =  child.getElementsByTagName(tagName || 'flowable:executionListener');
            }
        });

        const executionListenersNew = value.map(({ event, implementation, implementationType, fields }) => {
            const fieldsElements = (fields || []).map(({ name, fieldType, value }) => {
                return `
                    <flowable:field name="${name}">
                      <flowable:${fieldType}><![CDATA[${value}]]></flowable:${fieldType}>
                    </flowable:field>
                `;
            }).join('');
            return `<${tagName || 'flowable:executionListener'} event="${event}" ${implementationType}="${implementation}">${fieldsElements}</${tagName || 'flowable:executionListener'}>`;
        });
        if(!extensionElements) {
            extensionElements = parseDomElement(`<extensionElements></<extensionElements>`, 'extensionElements');

            const startEvent =  parenElement.getElementsByTagName('startEvent')[0];
            const incoming = parenElement.getElementsByTagName('incoming')[0];
            const outgoing = parenElement.getElementsByTagName('outgoing')[0];
            if (startEvent) {
                parenElement.insertBefore(extensionElements, startEvent);
            } else if (incoming || outgoing) {
                parenElement.insertBefore(extensionElements, incoming || outgoing);
            } else {
                parenElement.appendChild(extensionElements);
            }

            doc = new DOMParser().parseFromString(String(doc));
            parenElement =  doc.getElementById(id);
            Array.from(parenElement.childNodes || []).forEach((child) => {
                if(child.localName === 'extensionElements') {
                    extensionElements = child;
                }
            });
        }
        Array.from(executionListeners || []).forEach((elem) => {
            extensionElements.removeChild(elem);
        });
        if(executionListenersNew?.length) {
            executionListenersNew.forEach(elmnt => extensionElements.appendChild(new DOMParser().parseFromString(elmnt)));
        }

        return prettifyXml(String(doc));
    }

    @bind
    handleOnChangeFields(uuid, editModalValue) {
        return (fields) => {
            let value = this.state.value;
            if(uuid) {
                value = this.state.value.map((val) => {
                    const nextVal = { ...val };
                    if(uuid === nextVal.uuid) {
                        nextVal.fields = fields;
                    }
                    return nextVal;
                });
                this.setState({ value });
            } else {
                editModalValue({ fields });
            }
        };
    }

    @bind
    toggleShowLoader(showLoader) {
        this.setState({ showLoader });
    }

    render() {
        const { showModal, value, showLoader } = this.state;
        const { title, fields } = this.props;
        return (
            <DragNDropContext>
                <PaperStyled onClick={this.toggleModal}>
                    <Typography variant="caption">{title}</Typography>
                    <Typography >{(value || []).length} Fields</Typography>
                </PaperStyled>
                {showLoader && (
                    <Loader absolute backdrop />
                )}
                {showModal && (
                    <ModalDialog
                        onClose={this.toggleModal}
                        title={title}
                        actions={(
                            <Button onClick={this.onSave}>
                                Apply
                            </Button>
                        )}
                    >
                        <TypographyPadded variant="caption">Dragable list</TypographyPadded>
                        <AbstractTableDnd
                            title={title}
                            key={value?.length || 0}
                            name="parent"
                            uuid={this.uuid}
                            onDrop={this.onDrop}
                            addValue={this.addValue}
                            deleteValue={this.deleteValue}
                            value={value}
                            onChange={this.handleOnChange}
                            toggleShowLoader={this.toggleShowLoader}
                            fields={fields}
                            onSave={this.onSave}
                            modalAfterContent={(value, editModalValue, key, saveAndClose) => {
                                return value && value.type !== 'nodeJs' && value.type !== '' && (
                                    <ExecutionListenersFieldsBuilder
                                        saveAndClose={saveAndClose}
                                        toggleShowLoader={this.toggleShowLoader}
                                        key={key}
                                        label="Fields"
                                        name="fields"
                                        value={value}
                                        onChange={this.handleOnChangeFields(value.uuid, editModalValue)}
                                    />
                                );
                            }}
                        />
                    </ModalDialog>
                )}
            </DragNDropContext>
        );
    }
};

export default ExecutionListenersBuilder;
