/* @flow */

import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Chip } from '@mic3/platform-ui';

import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import HeaderBar from 'app/components/molecules/HeaderBar/HeaderBar';
import PageTemplate from 'app/components/templates/PageTemplate';
import Loader from 'app/components/atoms/Loader/Loader';
import DotMenu from 'app/components/molecules/DotMenu/DotMenu';
import AddIdentityProvider from 'app/containers/Admin/Settings/IdentityProviders/AddIdentityProvider';

import { get } from 'app/utils/lo/lo';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { loadConfigurations, setDocumentTitle } from 'store/actions/app/appActions';
import { loadSecurityGeneral, updateSecurityGeneral, ssoDeleteIdentityProvider, ssoUpdateIdentityProvider } from 'store/actions/app/appActions';
import { Typography,Table, TableBody, TableCell, TableHead, TableRow } from '@mic3/platform-ui';
import { showToastr } from 'store/actions/app/appActions';
import history from 'store/History';
import { toTitleCase } from 'app/utils/string/string-utils';
import { getPermissions } from 'app/config/rolesConfig';
import { modulesAndPageTitles } from 'app/config/typesConfig';

const TableStyled = styled(Table)`
    padding: 0 1rem;

    & thead .MuiTableCell-stickyHeader:nth-child(1){
        width: 156px;
    }
    & thead .MuiTableCell-stickyHeader:nth-child(2){
        width: 200px;
    }
    & thead .MuiTableCell-stickyHeader{
        height: 50px;
    }
    & thead .MuiTableRow-root td{
        border-bottom: 1px solid ${({ theme }) => theme.material.colors.secondary.main};
        height: 48px;
    }
    & tbody .MuiTableRow-root td{
        border-bottom: 1px solid ${({ theme }) => theme.material.colors.secondary.main}30;
    }

`;

const ChipStyled = styled(Chip)`
    margin-left: 0.5rem;
`;

const StyledTypography = styled(Typography)`
    color: ${({theme})=> theme.material.colors.text.primary};
`;

class IdentityProvidersMain extends PureComponent<Object, Object> {

    formBruteForceDetection: Object = React.createRef();

    state = {
        openAddIdentityProviderModal: null,
    };

    componentDidMount() {
        this.props.setDocumentTitle(modulesAndPageTitles.adminConsole.security.identityProviders);
    }

    @bind
    @memoize()
    buildPlusDotMenu(canEdit) {
        if(!canEdit) return null;
        return (
            <DotMenu
                icon="plus"
                tooltipTitle="Add provider"
                key={13}
                padded
                onItemClick={this.addIdentityProviderModal}
                items={[
                    { name: 'header', text: 'Add provider' },
                    // { name: 'Affectli' },
                    // { name: 'SAML v2.0' },
                    { name: 'OpenID Connect' },
                    // { name: 'Twitter' },
                    // { name: 'LinkedIn OpenID Connect' },
                    // { name: 'Facebook' },
                    // { name: 'Microsoft' },
                ].filter(Boolean)}
            />
        );
    }

    @bind
    @memoize()
    buildThreeDotMenu(provider, canEdit) {
        return (
            <DotMenu
                tooltipTitle="More Options"
                key={13}
                padded
                onItemClick={this.actionMenuClick(provider)}
                items={[
                    { name: 'About', icon: 'information-outline' },
                    canEdit && { 
                        name: !provider.enabled ? 'Enable' : 'Disable', 
                        icon: !provider.enabled ? 'check' : 'close',
                        withConfirmation: true,
                        confirmationModalProps: {
                            header: `${!provider.enabled ? 'Enable' : 'Disable'} provider?`,
                            message: `Are you sure you want to ${!provider.enabled ? 'enable' : 'disable'} the provider '${provider.alias}'`,
                            declineButtonText: 'Cancel',
                            confirmationButtonProps: {
                                className: !provider.enabled ? '' : 'error',
                            },
                            confirmButtonText: !provider.enabled ? 'Enable' : 'Disable',
                        },                          
                    },
                    canEdit && { 
                        name: 'Delete', 
                        icon: 'delete-outline',
                        withConfirmation: true,
                        confirmationModalProps: {
                            header: 'Delete provider?',
                            message: `Are you sure you want to permanently delete the provider '${provider.alias}'?`,
                            declineButtonText: 'Cancel',
                            confirmButtonText: 'Delete',
                            confirmationButtonProps: {
                                className: 'error',
                            },                            
                        },                        
                    },
                ].filter(Boolean)}
            />
        );
    }
    
    @bind
    addIdentityProviderModal(name) {
        this.setState({ openAddIdentityProviderModal: name });
    }
    @bind
    closeIdentityProviderModal() {
        this.setState({ openAddIdentityProviderModal: null });
    }

    @bind
    actionMenuClick(provider) {
        return async (name) => {
            switch (name) {
                case 'About':
                    history.push(`/admin/settings/identity-providers/${provider.alias}`);
                    break;
                case 'Enable':
                case 'Disable': {
                    const { ssoUpdateIdentityProvider, loadSecurityGeneral, loadConfigurations } = this.props; 
                    await ssoUpdateIdentityProvider({
                        alias: provider.alias,
                        enabled: !provider.enabled
                    });
                    await loadSecurityGeneral();
                    await loadConfigurations();
                    break;
                }
                case 'Delete': {
                    const { ssoDeleteIdentityProvider, loadSecurityGeneral, loadConfigurations } = this.props; 
                    await ssoDeleteIdentityProvider(provider);
                    await loadSecurityGeneral();
                    await loadConfigurations();
                    break;
                }
                default:
                    break;
            }
        };
    }

    @bind
    handleChangeRequiredActions({ target: { name, value }}) {
        const { updateSecurityGeneral, ssoSettings } = this.props;
        const [actionName, checkboxName] = name.split('.');
        updateSecurityGeneral({
            requiredActions: ssoSettings.requiredActions.map(action => {
                if(action.name === actionName) {
                    return { ...action, [checkboxName]: value };
                }
                return action;
            })
        });
    }

    @bind
    @memoize()
    buildProvidersList(identityProviders, canEdit) {
        return [...(identityProviders || [])].sort((a, b) => a.config.guiOrder - b.config.guiOrder).map((provider) => {
            const { providerId, alias, displayName, enabled } = provider;
            return providerId === 'oidc' ? (
                <TableRow  key={alias}>
                    <TableCell width="300px" align="left">
                        {displayName || alias}{!enabled && <ChipStyled size="small" label="Disabled"/>}
                    </TableCell>
                    <TableCell align="left">{toTitleCase(providerId)}</TableCell>
                    <TableCell align="right">{this.buildThreeDotMenu(provider, canEdit)}</TableCell>
                </TableRow>
            ) : null;});
    }

    render() {
        const { isLoading, ssoSettings, loadConfigurations } = this.props;
        const { openAddIdentityProviderModal } = this.state;
        const { canEdit } = getPermissions(ssoSettings?.role);

        return (
            <PageTemplate title={'Identity Providers'} overflowHidden>
                <HeaderBar right={this.buildPlusDotMenu(canEdit)} left={<StyledTypography variant="h6">Identity Providers</StyledTypography>} />
                {isLoading && (
                    <Loader absolute backdrop />
                )}
                <ContentArea withHeader>
                    {ssoSettings?.identityProviders && (
                        <TableStyled stickyHeader size={'small'}>
                            <TableHead>
                                <TableRow>
                                    {['Name', 'Provider Details', ''].map((cell, i) => (
                                        <TableCell align={'left'} key={i} >
                                            {cell}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.buildProvidersList(ssoSettings?.identityProviders, canEdit)}
                            </TableBody>
                        </TableStyled>
                    )}
                </ContentArea>
                {openAddIdentityProviderModal && (
                    <AddIdentityProvider 
                        ssoSettings={ssoSettings}
                        onClose={this.closeIdentityProviderModal}
                        isLoading={false}
                        providerName={openAddIdentityProviderModal}
                        canEdit={canEdit}
                        loadConfigurations={loadConfigurations}
                    />
                )}
            </PageTemplate>
        );
    }
}

export default connect(
    state => ({
        isLoading: state.app.ssoSettingsLoading,
        ssoSettings: get(state.app, 'ssoSettings', {}),
    }),
    {
        showToastr,
        loadSecurityGeneral,
        updateSecurityGeneral,
        ssoDeleteIdentityProvider,
        ssoUpdateIdentityProvider,
        loadConfigurations,
        setDocumentTitle
    }
)(IdentityProvidersMain);
