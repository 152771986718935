/* @flow */
import { combineReducers } from 'redux';

import { dataTableReducer, loadDataReducer, keepDataAtStart } from 'app/utils/redux/reducer-utils';
import {
    LOAD_CLASSIFICATION_STARTED, LOAD_CLASSIFICATION,
    LOAD_CLASSIFICATION_ENTITIES_STARTED, LOAD_CLASSIFICATION_ENTITIES,
    CREATE_CLASSIFICATION_STARTED, CREATE_CLASSIFICATION,
    LOAD_CLASS_DEFINITION_STARTED, LOAD_CLASS_DEFINITION,
    LOAD_CLASSIFICATIONS_BY_IDS_STARTED, LOAD_CLASSIFICATIONS_BY_IDS,
    LOAD_CLASSES_LIST_CARD_STARTED, LOAD_CLASSES_LIST_CARD,
    LOAD_CLASS_CHILDRENS_STARTED, LOAD_CLASS_CHILDRENS,
    LOAD_CLASS_ANCESTORS_STARTED, LOAD_CLASS_ANCESTORS,
    INDEX_ATTRIBUTE_STARTED, INDEX_ATTRIBUTE,
    LOAD_CLASSIFICATIONS_TASK_PROCESS_STARTED,
    LOAD_CLASSIFICATIONS_TASK_PROCESS
} from 'store/actions/classifications/classificationsActions';

import avatar from './avatar/classAvatarReducer';

export default combineReducers<Object, Object>({
    details: loadDataReducer(LOAD_CLASSIFICATION_STARTED, LOAD_CLASSIFICATION),
    indexAttribute: loadDataReducer(INDEX_ATTRIBUTE_STARTED, INDEX_ATTRIBUTE),
    childrens: dataTableReducer(LOAD_CLASS_CHILDRENS_STARTED, LOAD_CLASS_CHILDRENS),
    definition: loadDataReducer(LOAD_CLASS_DEFINITION_STARTED, LOAD_CLASS_DEFINITION),
    list: dataTableReducer(LOAD_CLASSES_LIST_CARD_STARTED, LOAD_CLASSES_LIST_CARD, keepDataAtStart),
    entities: dataTableReducer(LOAD_CLASSIFICATION_ENTITIES_STARTED, LOAD_CLASSIFICATION_ENTITIES, keepDataAtStart),
    classAncestors: dataTableReducer(LOAD_CLASS_ANCESTORS_STARTED, LOAD_CLASS_ANCESTORS),
    addedClassification: loadDataReducer(CREATE_CLASSIFICATION_STARTED, CREATE_CLASSIFICATION),
    classificationsByIds: loadDataReducer(LOAD_CLASSIFICATIONS_BY_IDS_STARTED, LOAD_CLASSIFICATIONS_BY_IDS),
    classificationTasksAndProcesses: loadDataReducer(LOAD_CLASSIFICATIONS_TASK_PROCESS_STARTED, LOAD_CLASSIFICATIONS_TASK_PROCESS),
    avatar,
});
