// @flow
import { graphql } from 'graphql/client';
import { gql } from '@apollo/client';

import { get, set } from 'app/utils/lo/lo';
import { loadData } from 'app/utils/redux/action-utils';
import { mutateData } from 'app/utils/redux/action-utils';
import { toBpmnVariables } from 'app/utils/bpmn/bpmnEngineUtils';
import Immutable from 'app/utils/immutable/Immutable';
import { getStr } from 'app/utils/utils';

import { _fetchPreferences, _savePreferences } from 'store/actions/admin/usersActions';
import { addRoleToData, addRoleToList } from 'app/config/rolesConfig';

import processesQuery from 'graphql/abox/process/processesQuery';
import processExpandTasksQuery from 'graphql/abox/process/processExpandTasksQuery';
import processGeotagButtonQuery from 'graphql/abox/process/processGeotagButtonQuery';
import loadProcessSidebarQuery from 'graphql/abox/process/loadProcessSidebarQuery';
import processStartedDetailsQuery from 'graphql/abox/process/processStartedDetailsQuery';
import loadProcessTypeaheadQuery from 'graphql/abox/process/loadProcessTypeaheadQuery';
import processHistoryQuery from 'graphql/abox/process/processHistoryQuery';
import startProcessMutation from 'graphql/abox/process/startProcessMutation';
import startProcessByMessageMutation from 'graphql/abox/process/startProcessByMessageMutation';
import executeScriptMutation from 'graphql/abox/process/executeScriptMutation';
import saveProcessVariablesMutation from 'graphql/abox/process/saveProcessVariablesMutation';
import updateProcessMutation from 'graphql/abox/process/updateProcessMutation';
import cancelProcessMutation from 'graphql/abox/process/cancelProcessMutation';

import { LOAD_USER_PREFERENCES } from 'store/actions/admin/usersActions';
import OptionsBuilder from 'app/utils/api/OptionsBuilder';
import { buildRelatedEntityFilter } from 'app/utils/formatter/graphql-formatter';

export const LOAD_PROCESSES_STARTED = '@@affectli/process/LOAD_PROCESSES_STARTED';
export const LOAD_PROCESSES = '@@affectli/process/LOAD_PROCESSES';

export const LOAD_EXPANDED_PROCESS_STARTED = '@@affectli/process/LOAD_EXPANDED_PROCESS_STARTED';
export const LOAD_EXPANDED_PROCESS = '@@affectli/process/LOAD_EXPANDED_PROCESS';

export const LOAD_PROCESS_GEOTAG_STARTED = '@@affectli/process/LOAD_PROCESS_GEOTAG_STARTED';
export const LOAD_PROCESS_GEOTAG = '@@affectli/process/LOAD_PROCESS_GEOTAG';

export const LOAD_STARTED_PROCESS_DETAILS_STARTED = '@@affectli/process/LOAD_STARTED_PROCESS_DETAILS_STARTED';
export const LOAD_STARTED_PROCESS_DETAILS = '@@affectli/process/LOAD_STARTED_PROCESS_DETAILS';

export const SAVE_PROCESS_PAGE_VIEW_STARTED = '@@affectli/process/SAVE_PROCESS_PAGE_VIEW_STARTED';
export const SAVE_PROCESS_PAGE_VIEW = '@@affectli/process/SAVE_PROCESS_PAGE_VIEW';

export const LOAD_PROCESSES_CARDS_STARTED = '@@affectli/abox/LOAD_PROCESSES_CARDS_STARTED';
export const LOAD_PROCESSES_CARDS = '@@affectli/abox/LOAD_PROCESSES_CARDS';

export const LOAD_PROCESS_HISTORY_STARTED = '@@affectli/abox/process/LOAD_PROCESS_HISTORY_STARTED';
export const LOAD_PROCESS_HISTORY = '@@affectli/abox/process/LOAD_PROCESS_HISTORY';

export const LOAD_PROCESS_TYPEAHEAD_STARTED = '@@affectli/abox/process/LOAD_PROCESS_TYPEAHEAD_STARTED';
export const LOAD_PROCESS_TYPEAHEAD = '@@affectli/abox/process/LOAD_PROCESS_TYPEAHEAD';

export const START_PROCESS_STARTED = '@@affectli/process/START_PROCESS_STARTED';
export const START_PROCESS = '@@affectli/process/START_PROCESS';
export const START_PROCESS_RESET = '@@affectli/process/START_PROCESS_RESET';

export const START_PROCESS_BY_MESSAGE_STARTED = '@@affectli/process/START_PROCESS_BY_MESSAGE_STARTED';
export const START_PROCESS_BY_MESSAGE = '@@affectli/process/START_PROCESS_BY_MESSAGE';

export const EXECUTE_SCRIPT_STARTED = '@@affectli/process/EXECUTE_SCRIPT_STARTED';
export const EXECUTE_SCRIPT = '@@affectli/process/EXECUTE_SCRIPT';

export const SAVE_VARIABLES_STARTED = '@@affectli/process/SAVE_VARIABLES_STARTED';
export const SAVE_VARIABLES = '@@affectli/process/SAVE_VARIABLES';

export const LOAD_PROCESS_SIDEBAR_DETAILS_STARTED = '@@affectli/process/LOAD_PROCESS_SIDEBAR_DETAILS_STARTED';
export const LOAD_PROCESS_SIDEBAR_DETAILS = '@@affectli/process/LOAD_PROCESS_SIDEBAR_DETAILS';

export const LOAD_PROCESS_SIDEBAR_DETAILS_INTERNAL_STARTED = '@@affectli/process/LOAD_PROCESS_SIDEBAR_DETAILS_INTERNAL_STARTED';
export const LOAD_PROCESS_SIDEBAR_DETAILS_INTERNAL = '@@affectli/process/LOAD_PROCESS_SIDEBAR_DETAILS_INTERNAL';

export const UPDATE_PROCESS_STARTED = '@@affectli/process/UPDATE_PROCESS_STARTED';
export const UPDATE_PROCESS = '@@affectli/process/UPDATE_PROCESS';

export const startProcess = (processDefinitionId: string, variables: ?Object, outcome: ?string) =>
    mutateData(
        START_PROCESS_STARTED,
        START_PROCESS,
        startProcessMutation,
        'Process started.',
    )({ processDefinitionId, variables: toBpmnVariables(variables), outcome });

export const startProcessByMessage = (message: string, variables: ?Object, businessKey: ?string) =>
    mutateData(
        START_PROCESS_BY_MESSAGE_STARTED,
        START_PROCESS_BY_MESSAGE,
        startProcessByMessageMutation,
        'Process started.',
    )({ message, variables: toBpmnVariables(variables), businessKey });

export const executeScript = (id: string, version: int, input: JSON) =>
    mutateData(
        EXECUTE_SCRIPT_STARTED,
        EXECUTE_SCRIPT,
        executeScriptMutation,
        'Script executed.',
    )({ id, version, input });

export const startProcessReset = () => dispatch => dispatch({ type: START_PROCESS_RESET });

export const saveProcessVariables = (id: string, variables: ?Object) =>
    mutateData(
        SAVE_VARIABLES_STARTED,
        SAVE_VARIABLES,
        saveProcessVariablesMutation,
        'The form data has been saved.',
    )({ id, variables: toBpmnVariables(variables) });

export const loadProcessTypeahead = loadData(LOAD_PROCESS_TYPEAHEAD_STARTED, LOAD_PROCESS_TYPEAHEAD, loadProcessTypeaheadQuery);

/**
 * Load the Abox processes for the DataTable
 *
 * @param options the options ({ page, pageSize, countMax, where, orderBy, download })
 */
export const loadProcesses = ({ isShort, options }: Object = { isShort: false }) => {
    const filterBy = options.filterBy?.length ? options.filterBy.map(buildRelatedEntityFilter).flat() : [];
    return loadData(LOAD_PROCESSES_STARTED, LOAD_PROCESSES, processesQuery, addRoleToList)({ ...options, filterBy });
};
    

/**
 * Load the Abox Expanded Process List
 */
export const loadExpandedProcess = (processId: string) => (dispatch: Function, getState: Function) => {
    if (!processId) {
        throw new Error('The processId is mandatory.');
    }
    dispatch({ type: LOAD_EXPANDED_PROCESS_STARTED, meta: Immutable({ processId }) });
    graphql.query({
        query: processExpandTasksQuery,
        variables: { filterBy: [{ field: 'process.id', op: '=', value: processId }] },
        fetchPolicy: 'no-cache'
    }).then((response: Object): void => {
        dispatch({ type: LOAD_EXPANDED_PROCESS, payload: Immutable(get(response, 'data.tasks')), meta: Immutable({ processId }) });
    }).catch((error) => {
        dispatch({ type: LOAD_EXPANDED_PROCESS, payload: error, error: true, meta: Immutable({ processId }) });
    });
};

export const loadProcessSidebarDetails = (id: string) => {
    if (!id) {
        throw new Error('The ID is required.');
    }
    return loadData(LOAD_PROCESS_SIDEBAR_DETAILS_STARTED, LOAD_PROCESS_SIDEBAR_DETAILS, loadProcessSidebarQuery, addRoleToData)({ id });
};

export const loadProcessSidebarDetailsInternal = (id: string) => {
    if (!id) {
        throw new Error('The ID is required.');
    }
    return loadData(LOAD_PROCESS_SIDEBAR_DETAILS_INTERNAL_STARTED, LOAD_PROCESS_SIDEBAR_DETAILS_INTERNAL, loadProcessSidebarQuery, addRoleToData)({ id });
};

/**
 * Load the Abox Process Details after process is started
 */
export const loadStartedProcessDetails = (processId: string) => (dispatch: Function, getState: Function): Promise<?Object> => {
    if (!processId) {
        dispatch({ type: LOAD_STARTED_PROCESS_DETAILS_STARTED });
        dispatch({ type: LOAD_STARTED_PROCESS_DETAILS, payload: [] });
        return Promise.resolve(null);
    }
    const queryOptions = { filterBy: [{ field: 'process.id', op: '=', value: processId }] };
    return loadData(LOAD_STARTED_PROCESS_DETAILS_STARTED, LOAD_STARTED_PROCESS_DETAILS, processStartedDetailsQuery)(queryOptions)(dispatch, getState);
};

export const loadProcessGeotagButton = (id: string) =>
    loadData(LOAD_PROCESS_GEOTAG_STARTED, LOAD_PROCESS_GEOTAG, processGeotagButtonQuery)({ id });

/**
 * Change the priority of the process
 */
export const updateProcess = (record: Object, internal) =>
    !internal ? mutateData(
        LOAD_PROCESS_SIDEBAR_DETAILS_STARTED,
        LOAD_PROCESS_SIDEBAR_DETAILS,
        updateProcessMutation,
        `Process updated successfully.`,
        addRoleToData
    )({ record }) :
        mutateData(
            LOAD_PROCESS_SIDEBAR_DETAILS_INTERNAL_STARTED,
            LOAD_PROCESS_SIDEBAR_DETAILS_INTERNAL,
            updateProcessMutation,
            `Process updated successfully.`,
            addRoleToData
        )({ record });


/**
 * Cancel process
 */
export const cancelProcess = (id: string, type: string, reason: string) => {
    let options = { id };
    const closureReason = reason?.trim();
    if (closureReason) {
        options = { id, closureReason };
    }
    return mutateData(
        LOAD_PROCESS_SIDEBAR_DETAILS_STARTED,
        LOAD_PROCESS_SIDEBAR_DETAILS,
        cancelProcessMutation,
        `Process cancelled successfully.`,
        addRoleToData
    )(options);
};

/**
 * Save process page view
 */
export const saveProcessPageView = (state: Object, id: string) => async (dispatch: Function, getState: Function) => {
    dispatch({ type: SAVE_PROCESS_PAGE_VIEW_STARTED });
    try {
        const oldPreferences = await _fetchPreferences();
        const preferences = set(oldPreferences, `pageView.${id}`, state);
        await _savePreferences(preferences);
        dispatch({
            type: LOAD_USER_PREFERENCES,
            payload: Immutable(preferences),
        });
    } catch (error) {
        dispatch({
            type: SAVE_PROCESS_PAGE_VIEW,
            error: true,
            payload: Immutable(error),
            meta: Immutable({ errorMessage: 'An error occured saving the view preferences.' }),
        });
    }
};

export const loadProcessHistory = (id: string, options: Object) => async (dispatch, getState) => {
    const type = await loadProcessType(id);
    const newOptions = set(options, 'entityType', type || options.entityType);
    const variables = new OptionsBuilder(newOptions)
        .filter({ field: 'entity.id', op: '=', value: id })
        .filter({ field: 'entity.type', op: '=', value: type || options.entityType })
        .build();
    return loadData(LOAD_PROCESS_HISTORY_STARTED, LOAD_PROCESS_HISTORY, processHistoryQuery)(variables)(dispatch, getState);
};

/**
 * Get the process type
 */
export const loadProcessType = async (id: string) => {
    const response = await graphql.query({
        query: gql`query getProcessType($id: ID!){ process(id: $id) { type } }`,
        variables: { id },
        fetchPolicy: 'no-cache'
    });
    if (!(response instanceof Error)) {
        return getStr(response, 'data.process.type');
    }
    return null;
};
