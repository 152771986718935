import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { loadRelatedEntitiesTypeahead } from 'store/actions/entities/relatedEntitiesActions';
import { bind } from 'app/utils/decorators/decoratorUtils';

import AbstractEntityTypeahead from '../organisms/Form/Typeahead/abstract/AbstractEntityTypeahead';

const AbstractEntityTypeaheadStyled = styled(AbstractEntityTypeahead)`
    margin: 0 0 0 17px !important;
    & .MuiInputBase-input::placeholder {
        opacity: 1;
        color: ${({theme})=> theme.material.colors.text.secondary} !important;
    }

    & .MuiInputBase-root {
        font-size: 14px;
        font-weight: 500;

    ${({ value, theme }) => {
    return `
        color: ${value && theme.base.linkColor};
        box-shadow: ${value && `0 1px 0 0 ${theme.base.linkColor}`};
    `;
}}
    }
`;

class RelatedEntityAutocomplete extends PureComponent<Object, Object> {
    static propTypes = {
        loadRelatedEntitiesTypeahead: PropTypes.func.isRequired,
        entityType: PropTypes.string.isRequired,
        filterBy: PropTypes.arrayOf(PropTypes.object)
    };

    @bind
    loadEntitiesTypeahead(options) {
        const { loadRelatedEntitiesTypeahead, entityType } = this.props;
        return loadRelatedEntitiesTypeahead({ ...options, type: entityType });
    }
    
    render() {
        const { loadEntitiesTypeahead, filterBy, entityType, loadRelatedEntitiesTypeahead, ...abstractEntityAutocompleteProps } = this.props;
        return (
            <AbstractEntityTypeaheadStyled
                placeholder="Search Tree..."
                {...abstractEntityAutocompleteProps}
                VirtualListProps={{
                    itemSize: 60
                }}
                filterBy={filterBy}
                loadOptions={this.loadEntitiesTypeahead}
                optionTemplate={this.optionTemplate}
                variant="standard"
            />
        );
    }
};

export default connect(null, { loadRelatedEntitiesTypeahead })(RelatedEntityAutocomplete);