/* @flow */

import { gql } from '@apollo/client';

export default gql`
query broadcastsCalendarQuery($filterBy: [JSON], $orderBy: [JSON]) {
  result: broadcasts(filterBy: $filterBy, orderBy: $orderBy) {
    id
    message
    isRepetitive
    startDate
    expireDate
  }
}
`;
