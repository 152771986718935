/* @flow */

import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { loadRelationDefinitionDetails } from 'store/actions/entities/relationshipsActions';
import Loader from 'app/components/atoms/Loader/Loader';
import PageTemplate from 'app/components/templates/PageTemplate';
import RelationDefinitionAbout from 'app/containers/Entities/RelationDefinitions/RelationDefinitionAbout';
import PageNotAllowed from 'app/containers/ErrorPages/PageNotAllowed';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { openRelationDefinitionSidebar } from 'store/actions/entities/relationDefinitionSidebarActions';
import DotMenu from 'app/components/molecules/DotMenu/DotMenu';
import { baseRoutes } from 'app/config/routesConfig';
import Breadcrumbs from 'app/components/organisms/Breadcrumbs/Breadcrumbs';
import { typeTitlesMultiple, redirectTypes } from 'app/config/typesConfig';
import { setDocumentTitle } from 'store/actions/app/appActions';

const dataType = 'relationdefinition';
/**
 * Main container to display the details view of the Relation Definitions
 */
class RelationDefinitionDetails extends PureComponent<Object, Object> {
    static propTypes = {
        id: PropTypes.string.isRequired,
        details: PropTypes.object,
        loadRelationDefinitionDetails: PropTypes.func.isRequired,
        openRelationDefinitionSidebar: PropTypes.func.isRequired
    };

    /**
     * @override
     */
    componentDidMount() {
        const id = this.props.id;
        this.props.loadRelationDefinitionDetails(id);
    }

    componentDidUpdate(prevProps) {
        const { setDocumentTitle, id, details } = this.props;
        const description = details?.description;
        const relatedDescription = details?.relatedDescription;
        const prevDescription = prevProps?.details?.description;
        const prevRelatedDescription = prevProps?.details?.relatedDescription;
        if(description && (description !== prevDescription || relatedDescription !== prevRelatedDescription)){
            setDocumentTitle(description + ` - ${relatedDescription}`);
        }
        if (prevProps.id !== id) {
            this.props.loadRelationDefinitionDetails(this.props.id);
        }
    }

    @bind
    onDotMenuClick(title) {
        const { id, openRelationDefinitionSidebar } = this.props;
        const settings = { title, id };
        openRelationDefinitionSidebar(settings);
    }

    @bind
    @memoize()
    dotMenu(sidebarTitle: string, pathname: string = '') {
        return (
            <DotMenu
                key={13}
                onItemClick={this.onDotMenuClick}
                items={[
                    !pathname.endsWith('/about') && sidebarTitle !== 'About' && { name: 'About', icon: 'information-outline' },
                    sidebarTitle !== 'History' && { name: 'History', icon: 'history' }
                ].filter(Boolean)}
            />
        );
    }

    @bind
    @memoize()
    buildBreadcrumbs(name) {
        return <Breadcrumbs list={[{ link: `/${redirectTypes[dataType]}`, title: typeTitlesMultiple[dataType] }]} withGoBack />;
    }

    /**
     * @override
     */
    render() {
        const { details, isLoading, id, sidebarTitle } = this.props;
        if (!isLoading && !details) {
            return <PageNotAllowed permissionError={true} title={`Relation Definition. (ID:${id})`} />;
        }
        const { pathname } = this.props.location;
        const { description, name } = details || {};

        return (
            <Fragment>
                {isLoading && <Loader absolute backdrop />}
                <PageTemplate title={description} subTitle={`#${id}`}>
                    <Switch>
                        <Route path={`${baseRoutes[dataType]}/:id`} exact component={() => <Redirect to={`${baseRoutes[dataType]}/${id}/about`} />} />
                        <Route path={`${baseRoutes[dataType]}/:id/about`} exact>
                            <RelationDefinitionAbout
                                details={details}
                                sidebarActions={[this.dotMenu(sidebarTitle, pathname)]}
                                breadcrumbLine={this.buildBreadcrumbs(name)}
                            />
                        </Route>
                    </Switch>
                </PageTemplate>
            </Fragment>
        );
    }
}

export default connect(
    (state: Object, ownProps: Object): Object => ({
        details: state.entities.relationDefinitions.details.data,
        isLoading: state.entities.relationDefinitions.details.isLoading,
        id: ownProps.match.params.id,
        sidebarTitle: state.sidebar.title
    }),
    { loadRelationDefinitionDetails, openRelationDefinitionSidebar, setDocumentTitle }
)(RelationDefinitionDetails);
