/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { List, ListItem, Divider, ListItemIcon, ListItemText, ConfirmationModal } from '@mic3/platform-ui';
import { openEventSidebar } from 'store/actions/stream/eventsSidebarActions';
import { toUniqueUUID } from 'app/utils/string/string-utils';
import { get } from 'app/utils/lo/lo';
import DotMenu from 'app/components/molecules/DotMenu/DotMenu';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { closeSidebar } from 'store/actions/sidebar/sidebarActions';
import { loadSidebarEventDetails } from 'store/actions/stream/eventsActions';
import { setActions, setSubTitle } from 'store/actions/sidebar/sidebarActions';
import { shrinkSidebar, expandSidebar } from 'store/actions/sidebar/sidebarActions';
import Icon from 'app/components/atoms/Icon/Icon';
import { updateEventStatus } from 'store/actions/stream/eventsActions';
import { copyToClipboard } from 'app/utils/classification/classificationUtils';
import { showToastr } from 'store/actions/app/appActions';
import EventStartProcess from 'app/containers/Stream/Events/EventActions/EventStartProcess';
import { openEntitySidebar } from 'store/actions/entities/entitySidebarActions';
import { getPermissions } from 'app/config/rolesConfig';
import history from 'store/History';


const StyledIcon = styled(Icon)`
    margin-right: 5px;
`;

const IconStyled = styled(Icon)`
    &:before {
        color: ${({theme})=>theme.material.colors.text.secondary};
    }
`;

class EventsActionsSidebar extends PureComponent<Object, Object> {
    static propTypes = {
        updateEventStatus: PropTypes.func.isRequired,
        openEventSidebar: PropTypes.func.isRequired,
        showToastr: PropTypes.func.isRequired,
        postAction: PropTypes.func,
        id: PropTypes.string,
        title: PropTypes.string,
        isOpen: PropTypes.bool,
        details: PropTypes.object
    };

    state = {
        isConfirmOpen: false,
        confirmProps: {},
        confirmAction: null
    };

    constructor(props: Object) {
        super(props);
        this.initData();
    }

    componentDidUpdate(prevProps) {
        this.initData();
    }

    @bind
    setActions(expanded, details, pathname) {
        this.props.setActions(this.dotMenu(expanded, details, pathname));
    }

    @bind
    setSubTitle(name) {
        this.props.setSubTitle(name);
    }

    initData() {
        const { id, isOpen, details, expanded, location } = this.props;
        if (!isOpen) {
            return;
        }
        this.setActions(expanded, details, location?.pathname);
        const loaded = details || {};
        if (loaded.id === id) {
            this.setSubTitle(`#${toUniqueUUID(id)}`);
            return;
        }
    }

    @bind
    onDotMenuClick(title, details) {
        const { expanded, expandSidebar, shrinkSidebar } = this.props;

        switch (title) {
            case 'Collapse':
            case 'Expand':
                (expanded ? shrinkSidebar : expandSidebar)();
                break;

            case 'Go to details':
                if (details?.id && details?.time) {
                    history.push(`/events/${details.id}/${details.time}`);
                }
                break;

            default:
                break;
        }
    }
    @bind
    @memoize()
    dotMenu(expanded: boolean, details, pathname) {
        return (
            <DotMenu
                key={13}
                onItemClick={(title) => this.onDotMenuClick(title, details)}
                items={[
                    { name: expanded ? 'Collapse' : 'Expand', icon: expanded ? 'arrow-collapse' : 'open-in-new' },
                    !pathname.endsWith('/about') && { name: 'Go to details', icon: 'exit-to-app' }
                ].filter(Boolean)}
            />
        );
    }

    @bind
    action(event: ?Object, status) {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        const { id, reloadList, details } = this.props;
        const { time } = details || {};
        this.props.updateEventStatus({ status, id, time }).then(() => {
            reloadList && reloadList();
            this.props.closeSidebar();
        });
    }

    @bind
    toggleConfirmationModal() {
        this.setState(prevState => ({ isConfirmOpen: !prevState.isConfirmOpen }));
    }
    @bind
    openConfirmationModal() {
        this.setState({ isConfirmOpen: true });
    }
    @bind
    handleAction(status) {
        this.setState({
            isConfirmOpen: true,
            confirmProps: {
                message: status === 'ACK' ? 'Do you want to acknowledge event?' : 'Do you want to discard event?',
                declineButtonText: 'No',
                confirmButtonText: 'Yes'
            },
            confirmAction: event => this.action(event, status)
        });
    }
    @bind
    copyLinkToClipBoard(e, details) {
        e.stopPropagation();
        const { id, time }= details;
        copyToClipboard(`${window.location.origin}/#/events/${id}/${time}`)
            .then(() => {
                this.props.showToastr({
                    severity: 'success',
                    detail: 'Event Link copied to clipboard'
                });
            }).catch(() => {
                this.props.showToastr({
                    severity: 'error',
                    detail: 'Event Link could not copied to clipboard'
                });
            });
    }

    @bind
    openSidebar(title) {
        const { details = {}, reloadList } = this.props;
        const params = { id: details.id, type: 'event', title: title || 'About', reloadList };
        this.props.openEventSidebar({ ...params, time: details.time });
    }

    @bind
    navigateToDeviceRelations() {
        const { id, type } = this.props.details?.device || {};
        history.push(`/entities/${type}/${id}/relationships`);
    }

    @bind
    navigateToEventEntityRelations() {
        const { id, time } = this.props.details || {};
        history.push(`/events/${id}/${time}/entity-relations`);
    }

    @bind
    navigateToEventRelations() {
        const { id, time } = this.props.details || {};
        history.push(`/events/${id}/${time}/event-relations`);
    }

    render() {
        const { isOpen, details } = this.props;
        const processDefinitions = get(details, 'eventType.metadata.actions') || [];
        const { id, type, role } = details?.device || {};
        const permissions = getPermissions(role);
        return (
            isOpen && (
                <>
                    <div>
                        <Divider />
                        <List component="nav">
                            <ListItem button onClick={() => this.openSidebar('About')}>
                                <ListItemIcon>
                                    <IconStyled name="information-outline" />
                                </ListItemIcon>
                                <ListItemText primary="About" />
                                <Icon name="chevron-right" />
                            </ListItem>
                            <ListItem button onClick={() => this.navigateToEventEntityRelations()}>
                                <StyledIcon name= 'relationships' type='af' />
                                <ListItemText primary="Event Entity Relations" />
                                <Icon name="chevron-right" />
                            </ListItem>
                            <ListItem button onClick={() => this.navigateToEventRelations()}>
                                <StyledIcon name= 'relationships' type='af' />
                                <ListItemText primary="Event Relations" />
                                <Icon name="chevron-right" />
                            </ListItem>
                            {details?.device?.id && (
                                <>
                                    <ListItem button onClick={this.navigateToDeviceRelations}>
                                        <ListItemIcon>
                                            <StyledIcon name= 'relationships' type='af' />
                                        </ListItemIcon>
                                        <ListItemText primary="Device Relationships" />
                                        <Icon name="chevron-right" />
                                    </ListItem>
                                    {(permissions?.canComment) && (
                                        <ListItem button onClick={() => this.props.openEntitySidebar({ title: 'A-Live', id, type })}>
                                            <ListItemIcon>
                                                <IconStyled name="messenger" type="af" />
                                            </ListItemIcon>
                                            <ListItemText primary="A-Live" />
                                        </ListItem>
                                    )}
                                </>
                            )}
                        </List>
                        <Divider />                  
                        <List component="nav">
                            <ListItem button onClick={e=> this.copyLinkToClipBoard(e, details )}>
                                <ListItemIcon>
                                    <IconStyled name="link" />
                                </ListItemIcon>
                                <ListItemText primary="Copy link" />
                            </ListItem>
                        </List>
                        <Divider />
                        <List component="nav">
                            <ListItem button onClick={() => this.handleAction('ACK')} disabled={details?.status === 'ACK'}>
                                <ListItemIcon>
                                    <IconStyled name="check" />
                                </ListItemIcon>
                                <ListItemText primary="Acknowledged" />
                            </ListItem>
                            <ListItem button onClick={() => this.handleAction('DIS')} disabled={details?.status === 'DIS'}>
                                <ListItemIcon>
                                    <IconStyled name="close" />
                                </ListItemIcon>
                                <ListItemText primary="Discard" />
                            </ListItem>
                            <EventStartProcess list processDefinitions={processDefinitions} id={details?.id} data={details} />
                        </List>
                    </div>
                    <ConfirmationModal
                        {...this.state.confirmProps}
                        onClose={this.toggleConfirmationModal}
                        open={this.state.isConfirmOpen}
                        onConfirm={this.state.confirmAction}
                    />
                </>
            )
        );
    }
}

export default connect(
    (state: Object, ownProps: Object) => ({
        isOpen: state.sidebar.isOpen,
        expanded: state.sidebar.expanded,
        windowWidth: state.global.window.width,
        actions: state.sidebar.actions
    }),
    {
        openEventSidebar,
        openEntitySidebar,
        updateEventStatus,
        shrinkSidebar,
        expandSidebar,
        loadSidebarEventDetails,
        closeSidebar,
        setActions,
        setSubTitle,
        showToastr
    }
)(withRouter(EventsActionsSidebar));
