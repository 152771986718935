/* @flow */

import { gql } from '@apollo/client';

export default gql`
    query classDefinitionQuery($id: Int) {
        result: classification(id: $id) {
            id
            name
            uri
            active
            _extendedFormFields
        }
    }
`;
