import React, { useMemo } from 'react';
import { Avatar, Divider, Typography, Button, Grid, IconButton, ListItem, ListItemText, ListItemAvatar } from '@mic3/platform-ui';
import { getEntityUrl } from 'app/utils/entity/entityUtils';
import DotMenu from 'app/components/molecules/DotMenu/DotMenu';
import Icon from 'app/components/atoms/Icon/Icon';
import styled from 'styled-components';
import { Popover, } from '@material-ui/core';

import TypeIcon from 'app/components/atoms/TypeIcon/TypeIcon';
import GridEntityId from 'app/components/organisms/GridView/GridRenderers/GridEntityId';
import { getAttachmentUrl } from 'app/utils/attachments/attachmentsUtils';

const FlexWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 16px;
`;

const ItemTitle = styled.span`
    padding-left: 0.1rem;
`;

const TitleStyled = styled(Typography)`
    font-size: 10px !important;
    letter-spacing: 1.5px !important;
    text-transform: uppercase;
    padding-left: 8px;
    line-height: 16px !important;
    height: 16px !important;

    color: ${({ theme }) => theme.material.colors.text.primary};
`;

const TypeIconStyled = styled(TypeIcon)`
    color: ${({ theme }) => theme.material.colors.text.primary};
`;

const ListItemStyled = styled(ListItem)`
    margin-bottom: 8px;
`;
const UserNameStyled = styled(Typography)`
    margin-top: 13px !important;
    margin-bottom: 13px !important;
`;

const UserAvatarStyled = styled(Avatar)`
    margin-top: 8px !important;
    width: 72px !important;
    height: 72px !important;
`;
const UserMessgaeStyled = styled(Button)`
    margin-bottom: 16px !important;
`;


export const EntityPopup = (props) => {
    const { data, type, onOpenAction, onClose } = props;
    const { image, name, id, time, assignee } = data || {};
    const {id: assigneeId, type: assigneeType, image: assigneeImage, name: assigneeName} = assignee || {};
    let src = null;
    let initials = null;

    const dotMenu = useMemo(() => {
        const isProcess = ['openprocess', 'closedprocess'].includes(type);
        const isProcessOpened = ['openprocess'].includes(type);
        const isTask = ['opentask', 'closedtask'].includes(type);
        const isEntity = !isProcess && !isTask && type !== 'event';
        return (
            <DotMenu
                key={13}
                zIndex={2000}
                onClose={onClose}
                onItemClick={(title) => onOpenAction(title, data)}
                items={[
                    (isProcess || isEntity) && { name: 'About', icon: 'information-outline' },
                    isTask && { name: 'Form', icon: 'information' },
                    isProcess && { name: 'Tasks', icon: 'sitemap' },
                    (isProcess || isEntity || isTask) && { name: 'Sharing', icon: 'share-variant' },
                    (isProcess || isEntity || isTask) && { name: 'Classes', icon: 'Attribute-class', iconType: 'af' },
                    (isProcess || isEntity || isTask) && { name: 'Relations', icon: 'relationships', iconType: 'af' },
                    isEntity && { name: 'Location', icon: 'pin' },
                    (isProcess || isEntity || isTask) && { name: 'Attachments', icon: 'attachment' },
                    (isProcess || isEntity) && { name: 'History', icon: 'history' },
                    // (isProcess || isEntity || isTask) && { name: 'Digital Twin', icon: 'hexagon' },
                    isTask && { name: 'Process Map', icon: 'sitemap' },
                    isProcessOpened && { name: 'Cancel process', icon: 'window-close' },
                ].filter(Boolean)}
            />
        );
    }, [type, onOpenAction, data, onClose]);

    switch(type){
        case 'opentask':
        case 'closedtask': {
            src = assigneeImage && getAttachmentUrl(assigneeId, assigneeType, assigneeImage);
            initials = assigneeName;
        }
            break;
        default: 
            src = image && getAttachmentUrl(id, type, image);
            initials = name || 'No Name';
    }

    if (['user'].includes(type)) {
        return (
            <>
                <FlexWrapper>
                    <TypeIconStyled type={type} />
                    <TitleStyled>{type}</TitleStyled>
                </FlexWrapper>
                <Grid container alignItems='center' direction='column'>
                    <UserAvatarStyled src={image && getAttachmentUrl(id, type, image)} initials={name || 'No Name'} />
                    <UserNameStyled variant='h6'>{name}</UserNameStyled>
                    <UserMessgaeStyled
                        title='Open messenger'
                        onClick={(e) => {
                            e.stopPropagation();
                            onOpenAction('A-Live', data);
                        }}
                    >
                        <Icon size='sml' name='messenger' type='af' />
                        Direct message
                    </UserMessgaeStyled>
                </Grid>
                <Divider />
                <Grid container justify='space-around'>
                    <Button
                        variant='text'
                        onClick={(e) => {
                            e.stopPropagation();
                            onOpenAction('Go to details', data);
                        }}
                    >
                        Go to profile
                    </Button>
                    <Button
                        variant='text'
                        onClick={(e) => {
                            e.stopPropagation();
                            onOpenAction('Manage user', data);
                        }}
                    >
                        Manage user
                    </Button>
                </Grid>
            </>
        );
    }

    const AboutIcon = (
        <IconButton
            key={66}
            title='Open details'
            onClick={(e) => {
                e.stopPropagation();
                onOpenAction('About', data);
            }}
        >
            <Icon size='sml' name='information' />
        </IconButton>
    );
    return (
        <>
            <FlexWrapper>
                <TypeIconStyled type={type} />
                <TitleStyled>{type}</TitleStyled>
            </FlexWrapper>
            <ListItemStyled dense>
                <ListItemAvatar>
                    <Avatar src={src} initials={initials} />
                </ListItemAvatar>
                <ListItemText
                    primary={
                        type ? (
                            <Grid container>
                                <ItemTitle>{name}</ItemTitle>
                            </Grid>
                        ) : null
                    }
                    secondary={id ? <GridEntityId label={id} value={getEntityUrl(id, type, { time })} valueId={id} /> : null}
                />
            </ListItemStyled>
            <Divider />
            <Grid container justify='space-between'>
                <Grid item>
                    <IconButton
                        key={66}
                        title='Go to details'
                        onClick={(e) => {
                            e.stopPropagation();
                            onOpenAction('Go to details', data);
                        }}
                    >
                        <Icon size='sml' name='exit-to-app' />
                    </IconButton>
                </Grid>
                {type !== 'event' ? (
                    <Grid item>
                        <IconButton
                            key={66}
                            title='Sharing'
                            onClick={(e) => {
                                e.stopPropagation();
                                onOpenAction('Sharing', data);
                            }}
                        >
                            <Icon size='sml' name='share-variant' />
                        </IconButton>
                        {AboutIcon}
                        <IconButton
                            key={66}
                            title='Open A-live'
                            onClick={(e) => {
                                e.stopPropagation();
                                onOpenAction('A-Live', data);
                            }}
                        >
                            <Icon size='sml' name='messenger' type='af' />
                        </IconButton>
                        {dotMenu}
                    </Grid>
                ) : (
                    <Grid item>{AboutIcon}</Grid>
                )}
            </Grid>
        </>
    );
};

export function MouseOverPopover({children, content, ...props}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
  
    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
  
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
  
    return (
        <div onClick={e=>e.stopPropagation()} >
            <div
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
            >
                {children}
            </div>
            <Popover
                id="mouse-over-popover"
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
                {...props}
            >
                <div
                    style={{ minWidth: '250px' }}
                    onMouseLeave={handlePopoverClose}
                >
                    {content(setAnchorEl)}
                </div>
            </Popover>
        </div>
        
    );
};