/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { loadEntitiesTypeahead } from 'store/actions/entities/entitiesActions';
import { Avatar, ListItemText } from '@mic3/platform-ui';
import { loadUserReferences } from 'store/actions/admin/usersActions';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { TypeaheadChipInitials, TypeaheadAdornmentInitials } from 'app/components/organisms/Form/Typeahead/abstract/TypeaheadChip';
import { TypeaheadListItem } from 'app/components/organisms/Form/Typeahead/abstract/TypeaheadListItem';
import { getAttachmentUrl } from 'app/utils/attachments/attachmentsUtils';

import AbstractEntityTypeahead from 'app/components/organisms/Form/Typeahead/abstract/AbstractEntityTypeahead';
import ResolveAvatarTypeaheadWrapper from 'app/components/organisms/Form/Typeahead/abstract/ResolveAvatarTypeaheadWrapper';

/**
 * Select one or more groups using lazy loading.
 */
class EntityTypeahead extends PureComponent<Object, Object> {
    static propTypes = {
        loadEntitiesTypeahead: PropTypes.func.isRequired,
        entityType: PropTypes.string.isRequired,
        filterBy: PropTypes.arrayOf(PropTypes.object),
        extraInfo: PropTypes.bool,
        showType: PropTypes.bool,
    };

    static defaultProps = { 
        showType: true
    }
    constructor(props) {
        super(props);
        if(this.isUsersChip(props.entityType)) {
            this.props.loadUserReferences();
        }
        this.userReferencesById = this.mapUserReferencesById(this.props.userReferences);
    }


    mapUserReferencesById(userReferences) {
        return userReferences.reduce((acc, user) => {
            acc[user.id] = user;
            return acc;
        }, {});
    }

    @bind
    @memoize()
    getImage(id, type, imageId) {
        return getAttachmentUrl(id, type, imageId);
    }

    @bind
    isUsersChip(type){
        return ['user', 'opentask', 'closedtask', 'person'].includes(type);
    }

    @bind
    optionTemplate(value: Object) {
        const { name, id, type, primary, image } = value || {};
        const { assignee } = primary || {};
        const { valueField, showType, entityType } = this.props;
        const assigneeUser = this.userReferencesById[assignee];
        const { image: userImage, id: userId, name: userName } = assigneeUser || {};
        const src = this.isUsersChip(entityType) ? image && this.getImage(id, type, image)
            : userImage && this.getImage(userId, 'user', userImage);
        if (this.props.optionTemplate) {
            return this.props.optionTemplate(value);
        }
        let displayValue = String(value);
        if (typeof(value) === 'object') {
            displayValue = valueField ? value[valueField]: name;
        }
        return {
            option: (
                <TypeaheadListItem ContainerComponent="div" dense disableGutters>
                    <Avatar src={src} initials={this.isUsersChip(entityType) ? displayValue : userName} />
                    <ListItemText primary={displayValue} secondary={showType ? (type === 'custom' && 'custom entity') || type : null} />
                </TypeaheadListItem>
            ),
            ChipProps: {
                avatar: <TypeaheadChipInitials src={src} initials={this.isUsersChip(entityType) ? name : userName} />
            },
            startAdornment: <TypeaheadAdornmentInitials src={src} initials={this.isUsersChip(entityType) ? displayValue : userName} />,
            label: `${displayValue || 'Name not available'}${!valueField ? ` (${id ? id.slice(0, 8) : 'Id not available'})` : ''}`
        };
    }

    @bind
    loadEntitiesTypeahead(options) {
        const { loadEntitiesTypeahead, entityType, extraInfo } = this.props;
        return loadEntitiesTypeahead({ ...options, isShort: !extraInfo, type: entityType?.uri || entityType });
    }

    render() {
        const { loadEntitiesTypeahead, filterBy, entityType, extraInfo, ...abstractEntityAutocompleteProps } = this.props;
        return (
            <AbstractEntityTypeahead
                placeholder="Search for an entity..."
                {...abstractEntityAutocompleteProps}
                VirtualListProps={{
                    itemSize: 60
                }}
                filterBy={filterBy}
                loadOptions={this.loadEntitiesTypeahead}
                optionTemplate={this.optionTemplate}
            />
        );
    }
}

const EntitiyTypeaheadWrapped = (props) => {
    const { valueField } = props;
    if (valueField === 'id') {
        return <ResolveAvatarTypeaheadWrapper {...props} Typeahead={EntityTypeahead} populateType />;
    }
    return <EntityTypeahead {...props} />;
};


export default connect(
    state => ({
        userReferences: state.admin.users.references.data, 
    }),
    {
        loadUserReferences,
        loadEntitiesTypeahead,
    }
)(EntitiyTypeaheadWrapped);