/* @flow */

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, MdiIcon, IconButton, InputBase, Typography, ConfirmationModal } from '@mic3/platform-ui';

import BreadcrumbsV2 from 'app/components/organisms/Breadcrumbs/BreadcrumbsV2';
import HeaderView from 'app/components/organisms/HeaderView/HeaderView';
import DotMenu from 'app/components/molecules/DotMenu/DotMenu';
import Loader from 'app/components/atoms/Loader/Loader';
import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import RelationAddDialog from 'app/containers/Entities/Relationships/RelationAddDialog';
import AddEventsRelationsModal from 'app/containers/Common/AddEventsRelationsModal';
import {
    getMapedRelations,
    deleteRelationship,
    changeRelationsView,
    loadEventEntityRelations,
    deleteEventEntityRelationship,
    deleteEventToEventRelationship
} from 'store/actions/entities/relationshipsActions';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { get } from 'app/utils/lo/lo';
import { isEmpty } from 'app/utils/utils';
import { saveCsv } from 'app/utils/datatable/datatableUtils';
import { formatDate } from 'app/utils/date/date';
import { toUniqueUUID } from 'app/utils/string/string-utils';
import RelationList from 'app/containers/Entities/Relationships/RelationList';
import RelationsDiagram from 'app/containers/Entities/Relationships/RelationsDiagram';
import { groupBy } from 'app/utils/lo/lo';
import { getEntityUrl } from 'app/utils/entity/entityUtils';
import history from 'store/History';
import { openMenuSidebar, closeMenuSidebar } from 'store/actions/entities/menuSidebarActions';

const StyledMdiIcon = styled(MdiIcon)`
    &:before{
        color: ${({theme})=> theme.material.colors.text.secondary}
    }
`;

const SearchToolbar = styled(Grid)`
    padding: 0 1rem;
    border-bottom: 2px solid ${({ theme }) => theme.material.colors.border.main};
`;
const StretchedGridItem = styled(Grid)`
    flex: 1;
`;
const BreadcrumbToolbar = styled(Grid)`
    border-bottom: 2px solid ${({ theme }) => theme.material.colors.border.main};
`;
const Header = styled(Grid)`
    padding: 0 1rem;
`;

const Area = styled(ContentArea)`
    padding: 1rem;
    height: calc(100% - 50px);
`;

const Search = styled(Grid)`
    flex-grow: 1;
`;

const TypographyStyled = styled(Typography)`
    font-weight: bold;
`;

/**
 * Renders the view to shoe the relationships of an entity.
 */
class Relations extends PureComponent<Object, Object> {
    static propTypes = {
        fromType: PropTypes.string.isRequired,
        fromId: PropTypes.string.isRequired,
        getMapedRelations: PropTypes.func.isRequired,
        isLoading: PropTypes.bool,
        relations: PropTypes.object,
        reloadList: PropTypes.func,
    };

    defaultState: Object = {
        legendDefinitions: [],
        jshowSearch: false,
        filter: '',
        isAddDialogOpen: false,
        isConfirmModalOpen: false,
        confirmProps: {
            classId: null,
            confirmMessage: '',
        },
    };

    constructor(props) {
        super(props);
        this.fetchRelations();
        this.state = this.defaultState;
    }

    componentDidUpdate(prevProps: Object) {
        const { fromType, fromId, viewType } = this.props;
        if (prevProps.fromType !== fromType || prevProps.fromId !== fromId || viewType !== prevProps.viewType) {
            this.fetchRelations();
        }
    }

    @bind
    fetchRelations(ids, type, filterBy = [], data) {
        const { fromId } = this.props;
        const requestPromises = [...(ids || [fromId])].map((id) => {
            return this.getRelationsFromMap({ id, type, filterBy, data });
        });
        return Promise.all(requestPromises);
    }

    @bind
    getRelationsFromMap(params) {
        const { type, id, filterBy, data } = params || {};
        const { fromType, fromId, details, viewType } = this.props;
        if (fromType === 'event' && details?.id) {
            const { id: eventId, time: eventTime } = details;
            return this.props.getMapedRelations({ eventId, entityId: eventId, eventTime, filterBy, viewType });
        }
        return this.props.getMapedRelations({ entityType: type || fromType, entityId: id || fromId, filterBy, data });
    }

    /**
     * This function will take an object as first argument and filter as a second argument
     * It will check if value on any key of the object matches with the filter
     * If value matches it will return true and break the further execution
     * Otherwise It will return false
     */
    @memoize()
    matchExists(data: Object, filter: string = '') {
        return Object.entries(data || {}).some(([key, value]) => value && value.toLowerCase().includes(filter.toLowerCase()));
    }

    @bind
    groupByRelationDefinitionReGroup(relations) {
        let rels = [...(relations || [])];
        rels = (rels || []).map((relation) => ({
            ...relation,
            uniqueKey: `${get(relation, 'relation.relationDefinition.id', '')}${relation?.isReverseRelation}`,
        }));
        rels = groupBy(rels, 'uniqueKey');
        return rels;
    }

    @bind
    @memoize()
    groupByRelationDefinition(relations) {
        return this.groupByRelationDefinitionReGroup(relations);
    }

    @bind
    groupByRelationDefinitionFilter(relations, filter) {
        let records = [...(relations || [])];
        if (filter) {
            records = (records || [])
                .filter(({ relatedEntity: { name, id }, relation: { relationDefinition: { description, relatedDescription } } }) =>
                    this.matchExists({ name, relatedDescription, description, id }, filter)
                )
                .reverse();
        }
        return this.groupByRelationDefinitionReGroup(records);
    }

    @bind
    onSearch(event) {
        this.setState({ filter: event.target.value.trimLeft() });
    }

    @bind
    toggleAddDialog() {
        this.setState((prevState) => ({ isAddDialogOpen: !prevState.isAddDialogOpen }));
    }

    @bind
    refresh() {
        const { fromType, fromId } = this.props;
        return this.getRelationsFromMap({ type: fromType, id: fromId });
    }

    @bind
    removeRelationConfirm({ relId, relEntityName, relatedEntity, entity, relationDefinitionId }) {
        this.toggleConfirmationModal(true);
        this.setState({
            confirmProps: {
                relId,
                relatedEntity,
                entity,
                relationDefinitionId,
                confirmMessage: <Typography>Are you sure you want to remove <TypographyStyled variant="body">{relEntityName || 'relationship'}</TypographyStyled>?</Typography>
            },
        });
    }

    @bind
    async deleteRelation() {
        const { confirmProps } = this.state;
        const { relId, relatedEntity, entity, relationDefinitionId: relationDefinition } = confirmProps || {};
        const { id, type } = relatedEntity || {};
        const { reloadList, viewType } = this.props;
        let response = {};
        if (viewType === 'eventEntity') {
            response = await this.props.deleteEventEntityRelationship({
                relationDefinition,
                event: { id: entity?.id, time: entity?.time },
                entity: { id, type },
            });
        } else if (viewType === 'eventToEvent'){
            response = await this.props.deleteEventToEventRelationship({
                relationDefinition,
                event: { id: entity?.id, time: entity?.time },
                relatedEvent: { id, time: relatedEntity?.time },
            });
        } else {
            response = await this.props.deleteRelationship(relId);
        }
        if (response instanceof Error === false || String(response).includes('already been removed')) {
            reloadList && (await reloadList());
            return this.refresh();
        }
    }

    @bind
    onSave() {
        this.reloadList();
    }

    @bind
    reloadList() {
        const { reloadList } = this.props;
        this.refresh().then(async (resp) => {
            if (!(resp instanceof Error)) {
                reloadList && (await reloadList());
            }
        });
        this.setState(this.defaultState);
    }

    getClassFields(relation) {
        const _classes = get(relation, 'relationDefinition.classes');
        if (!_classes) {
            return null;
        }

        return _classes.reduce((fields, classification) => {
            const formFields = get(classification, 'formDefinition.fields');
            if (!isEmpty(formFields)) {
                fields.push(...formFields);
            }
            return fields;
        }, []);
    }

    @bind
    exportDefinitions() {
        const columns = [
            {
                header: 'Relationship',
                field: 'relation.relationDefinition.description',
            },
            {
                header: 'Entity ID',
                field: 'relatedEntity.id',
                type: 'text',
            },
            {
                header: 'Entity Name',
                field: 'relatedEntity.name',
            },
            {
                header: 'Created by',
                field: 'relation.createdBy.name',
            },
            {
                header: 'Created Date',
                field: 'relation.createdDate',
                type: 'date',
            },
            {
                header: 'Modified by',
                field: 'relation.modifiedBy.name',
            },
            {
                header: 'Modified Date',
                field: 'relation.modifiedDate',
                type: 'date',
            },
        ];
        return columns;
    }

    @bind
    export() {
        const { fromType, fromId } = this.props;
        this.getRelationsFromMap({ type: fromType, id: fromId }).then((data = {}) => {
            const downloadData = (data.relations || {}).map((relation) => {
                const formatData = {};
                this.exportDefinitions().forEach((column) => {
                    const columnName = column.header;
                    if (column.renderValue) {
                        formatData[columnName] = column.renderValue({ value: get(relation, column.field) });
                    }
                    if (column.type === 'date') {
                        formatData[columnName] = formatDate(get(relation, column.field));
                    } else {
                        formatData[columnName] = get(relation, column.field);
                    }
                });
                return formatData;
            });
            saveCsv(`Relations List`, downloadData.flat());
        });
    }

    @bind
    onViewMenuClick(view) {
        this.props.changeRelationsView(view);
    }

    @bind
    setDiagramLegendDefintiions(legendDefinitions) {
        this.setState({ legendDefinitions }, () => {
            const { sidebarIsOpen, sidebarTitle } = this.props;
            if (sidebarIsOpen && sidebarTitle === 'Legend Settings') {
                this.openDiagramLegendMenu();
            }
        });
    }

    @bind
    openDiagramLegendMenu() {
        const { openMenuSidebar } = this.props;
        const { legendDefinitions } = this.state;
        openMenuSidebar({
            menuDefinitions: { 
                menu: legendDefinitions || []
            },
            title: 'Legend Settings',
        });
    }

    @bind
    @memoize()
    buildViewMenu(view) {
        return [
            { name: 'header', text: 'View' },
            { name: 'Diagram', withRadio: true, checked: view === 'Diagram', icon: 'relationships', iconType: 'af' },
            { name: 'List', withRadio: true, checked: view === 'List', icon: 'view-headline' },
            { name: 'divider' },
            view === 'Diagram' && { name: 'Show & hide relationships...', onItemClick: this.openDiagramLegendMenu },
        ].filter(Boolean);
    }

    @bind
    handleGoBack() {
        history.goBack();
    }

    @bind
    handleOpenSearch() {
        this.setState((state) => ({ showSearch: !state.showSearch, filter: '' }));
    }

    @bind
    @memoize()
    buildBreadcrumb(breadcrumbs) {
        return this.setState({ breadcrumbs });
    }

    @bind
    openEntityAbout(title, data) {
        const { openEntitySidebar, openTaskSidebar, openProcessSidebar, openWorkspaceSidebar, openTeamSidebar } = this.props;
        const { type, id } = data;

        if (title === 'Go to details') {
            if (type === 'event') {
                return history.push(`/events/${id}/${data?.time}`);
            }
            return history.push(getEntityUrl(data.id, type));
        }

        if (title === 'Manage user') {
            return history.push(`/user-management/${id}`);
        }
        if (title === 'Form') {
            history.push(`/abox/task/${id}/form`);
            return;
        }
        if (title === 'Relations') {
            history.push(`${getEntityUrl(data.id, type)}/relationships`);
            return;
        }
        if (title === 'Process Map') {
            history.push(`${getEntityUrl(data.id, type)}/process-map`);
            return;
        }

        const params = { title, id, type, internal: true };
        switch (params.type) {
            case 'opentask':
            case 'closedtask':
                return openTaskSidebar(params);
            case 'openprocess':
            case 'closedprocess':
                return openProcessSidebar(params);
            case 'event':
                return this.props.openEventSidebar({ id, time: data?.time, title: 'About' });
            case 'workspace':
                return openWorkspaceSidebar({...params, openWorkspaceSidebar: this.props.openWorkspaceSidebar});
            case 'team':
                return openTeamSidebar(params);
            default:
                return openEntitySidebar(params);
        }
    }

    @bind
    toggleConfirmationModal(isConfirmModalOpen) {
        this.setState({ isConfirmModalOpen });
    }

    @bind
    handleCloseConfirmModal() {
        this.toggleConfirmationModal(false);

        setTimeout(() => {
            this.setState({
                confirmProps: {},
            });
        }, 300);
    }

    /**
     * @override
     */
    render() {
        const { view, sidebarTitle, relations, isLoading, fromId, fromType, canEdit, details, viewType } = this.props;
        const { isAddDialogOpen, filter, showSearch, breadcrumbs, isConfirmModalOpen, confirmProps } = this.state;
        if (isLoading) {
            return <Loader absolute />;
        }
        const records = relations && relations.relations;

        const relationMap = this.groupByRelationDefinition(records);
        return (
            <>
                <Header>
                    <BreadcrumbToolbar container alignItems='center' justify='space-between'>
                        <StretchedGridItem item>
                            <HeaderView
                                handleGoBack={this.handleGoBack}
                                iconName='relationships'
                                iconType='af'
                                title='Relationships'
                                subTitle1={details.name}
                                subTitle2={`#${toUniqueUUID(details.id)}`}
                            />
                        </StretchedGridItem>
                        <Grid item>{this.props.sidebarActions}</Grid>
                    </BreadcrumbToolbar>
                    <SearchToolbar container alignItems='center'>
                        {showSearch ? (
                            <>
                                <Search item>
                                    <InputBase onChange={this.onSearch} value={filter} fullWidth margin='none' placeholder='Search...' />
                                </Search>
                                <IconButton edge='end' onClick={this.handleOpenSearch}>
                                    <MdiIcon name='close' />
                                </IconButton>
                            </>
                        ) : (
                            <>
                                <Search>
                                    <BreadcrumbsV2 list={breadcrumbs} />
                                </Search>
                                <IconButton edge='end' onClick={this.handleOpenSearch}>
                                    <StyledMdiIcon name='magnify' />
                                </IconButton>
                                {canEdit && (
                                    <IconButton edge='end' onClick={() => this.toggleAddDialog()}>
                                        <StyledMdiIcon name='plus' />
                                    </IconButton>
                                )}
                                <IconButton edge='end' onClick={this.refresh}>
                                    <StyledMdiIcon name='refresh' />
                                </IconButton>
                                <IconButton edge='end' onClick={this.export}>
                                    <StyledMdiIcon name='download' />
                                </IconButton>
                                <DotMenu
                                    withRadio
                                    key={13}
                                    onItemClick={this.onViewMenuClick}
                                    icon='tune'
                                    items={this.buildViewMenu(view)}
                                />
                            </>
                        )}
                    </SearchToolbar>
                </Header>
                <Area>
                    {!filter && isEmpty(relationMap) ? 'No relationship has been defined' : ''}
                    {view === 'Diagram' ? (
                        <RelationsDiagram
                            relationMap={relationMap}
                            fromId={fromId}
                            filter={filter}
                            details={details}
                            sidebarTitle={sidebarTitle}
                            openRelationSidebar={this.props.openRelationSidebar}
                            openEntityAbout={this.openEntityAbout}
                            toggleInfoDialog={this.toggleInfoDialog}
                            deleteRelation={this.removeRelationConfirm}
                            canEdit={canEdit}
                            fetchRelations={this.fetchRelations}
                            reloadList={this.reloadList}
                            groupByRelationDefinitionReGroup={this.groupByRelationDefinitionReGroup}
                            matchExists={this.matchExists}
                            setDiagramLegendDefintiions={this.setDiagramLegendDefintiions}
                            openDiagramLegendMenu={this.openDiagramLegendMenu}
                        />
                    ) : (
                        <RelationList
                            relations={records}
                            details={details}
                            canEdit={canEdit}
                            viewType={viewType}
                            openEntityAbout={this.openEntityAbout}
                            toggleInfoDialog={this.toggleInfoDialog}
                            deleteRelation={this.removeRelationConfirm}
                            openRelationSidebar={this.props.openRelationSidebar}
                            reloadList={this.reloadList}
                            getRelationsFromMap={this.getRelationsFromMap}
                            groupByRelationDefinition={this.groupByRelationDefinition}
                            groupByRelationDefinitionFilter={this.groupByRelationDefinitionFilter}
                            buildBreadcrumb={this.buildBreadcrumb}
                            breadcrumbs={breadcrumbs}
                            filter={filter}
                            sidebarTitle={sidebarTitle}
                        />
                    )}
                    {isAddDialogOpen && viewType !== 'eventToEvent' ? (
                        <RelationAddDialog
                            fromType={fromType}
                            fromUid={fromId}
                            getClassFields={this.getClassFields}
                            onClose={this.toggleAddDialog}
                            onSave={this.onSave}
                            details={details}
                            records={records}
                        />
                    ) : null}
                    {isAddDialogOpen && viewType === 'eventToEvent' ? (
                        <AddEventsRelationsModal isOpen closeModal={this.toggleAddDialog} onSubmit={this.onSave} details={details} />
                    ) : null}
                    <ConfirmationModal
                        header='Remove Relation'
                        message={confirmProps?.confirmMessage}
                        open={isConfirmModalOpen}
                        confirmButtonText='Remove'
                        declineButtonText='Cancel'
                        onClose={this.handleCloseConfirmModal}
                        onConfirm={this.deleteRelation}
                    />
                </Area>
            </>
        );
    }
}

export default connect(
    (state, props) => ({
        isLoading: state.entities.mapedRelations[props.details.id]?.isLoading || false,
        relations: state.entities.mapedRelations[props.details.id],
        view: state.entities.relationsView.tab,
        isAdmin: state.user.profile.isAdmin,
        sidebarTitle: state.sidebar.title,
        sidebarIsOpen: state.sidebar.isOpen,
    }),
    {
        getMapedRelations,
        deleteRelationship,
        changeRelationsView,
        openMenuSidebar,
        closeMenuSidebar,
        loadEventEntityRelations,
        deleteEventEntityRelationship,
        deleteEventToEventRelationship,
    }
)(Relations);
