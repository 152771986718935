/* @flow */
import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { isMobile } from 'react-device-detect';
import { withRouter } from 'react-router';

import { loadTimelineTasks, updateTask } from 'store/actions/abox/taskActions';
import { setDocumentTitle, showToastr } from 'store/actions/app/appActions';
import { saveTimelinePreferences } from 'store/actions/admin/usersActions';
import { isEmpty } from 'app/utils/utils';

import Loader from 'app/components/atoms/Loader/Loader';
import TimelineToolbar from 'app/components/ABox/Timeline/legacyTimelineToolbar';
import PageTemplate from 'app/components/templates/PageTemplate';
import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import { FiltersTimeline, StyledButton } from 'app/components/ABox/Timeline/style';
import ReloadCountdown from 'app/components/molecules/ReloadCountdown/ReloadCountdown';
import Gantt from 'app/components/organisms/Gantt/Gantt';
import { formatOptionsForTask } from 'app/utils/formatter/graphql-formatter';
import { get, set } from 'app/utils/lo/lo';
import { IconButton, Tooltip } from '@mic3/platform-ui';
import AddTaskModal from './AddTaskModal';
import Icon from 'app/components/atoms/Icon/Icon';
import { getDateRanges } from 'app/utils/date/date';
import { filterDefinitions, defaultFilters, defaultOrder} from 'app/components/organisms/TasksView/TasksView';
import { copyToClipboard } from 'app/utils/classification/classificationUtils';
import PageNotAllowed from 'app/containers/ErrorPages/PageNotAllowed';
import { openTaskSidebar } from 'store/actions/abox/taskSidebarActions';
import { modulesAndPageTitles } from 'app/config/typesConfig';

class GanttChart extends PureComponent<Object, Object> {
    static propTypes = {
        preferences: PropTypes.object,
        isLoading: PropTypes.bool,
        records: PropTypes.array,
        totalRecords: PropTypes.number,
        userProfile: PropTypes.object,
        loadTimelineTasks: PropTypes.func.isRequired,
        updateTask: PropTypes.func.isRequired
    };

    static defaultProps = {
        isLoading: false,
        records: [],
        totalRecords: 0
    };

    state = {
        isLoadingUpdateTask: false,
        selectedTaskId: null,
        disableCountdown: false,
        countdownSeconds: 180,
        ganttOffset: 0,
        isAddTaskModalOpen: false,
        isColumnsHidden: false,
        isToolbarHidden: true
    };

    breadcrumb = [{ title: 'Timeline' }];
    searchBar = ['name', 'id'];
    defaultFilters = defaultFilters;
    defaultOrder = defaultOrder;
    filterDefinitions = filterDefinitions;

    anchorEl: Object = React.createRef();
    ganttRef: Object;

    constructor(props) {
        super(props);
        this.ganttRef = React.createRef();
    }

    componentDidMount(){
        this.props.setDocumentTitle(modulesAndPageTitles.abox.legacyTimeline);
    }

    @bind
    setRange(range: string) {
        const { preferences, saveTimelinePreferences } = this.props;
        const userPreferences = {
            ...preferences,
            timeline: {
                range: range
            }
        };
        saveTimelinePreferences(userPreferences);
    }

    @bind
    onChangeRange(e) {
        const range = e.target.value;
        this.setRange(range);
    }

    @bind
    onPrevious() {
        this.ganttRef.current.viewPreviousDates();
    }

    @bind
    onNext() {
        this.ganttRef.current.viewNextDates();
    }

    @bind
    onToday() {
        this.setRange('days');
        this.ganttRef.current.today();
    }

    @bind
    onZoomIn() {
        this.ganttRef.current.zoomIn();
    }

    @bind
    onZoomOut() {
        this.ganttRef.current.zoomOut();
    }

    @bind
    onResetZoom() {
        this.ganttRef.current.resetZoom();
    }

    @bind
    updateTaskDate(id: string, mode: 'resize' | 'move' | 'progress', task: Object) {
        let taskData: Object = { id: id };
        let primary = {...(task.primary || {})};
        primary = set(primary, 'startDate', new Date(task.start_date));
        primary = set(primary, 'dueDate', new Date(task.end_date));
        switch (mode) {
            case 'move':
            case 'resize':
                taskData = {
                    ...taskData,
                    primary
                };
                break;
            default:
                break;
        }
        this.props.updateTask(this.filterRecord(taskData)).then((response) => {
            if (response instanceof Error) return;
            this.ganttRef.current.updateTask(task);
        });
    }

    @bind
    @memoize()
    findTask(tasks: Object, taskId: number) {
        return tasks.find(task => task.id === taskId) || {};
    }

    @bind
    openTaskDrawer(id: string) {
        if (!id) return;
        this.props.openTaskSidebar({ id: id, title: 'About', reloadList: this.refreshAction });
    }

    @bind
    updateTimelineTask(task: Object) {
        this.ganttRef.current.updateTask(task);
    }

    @bind
    refreshAction() {
        this.ganttRef.current.refreshData();
    }

    @bind
    hideColumns() {
        this.ganttRef.current.toggleColumnVisibility(true);
    }

    @bind
    showColumns() {
        this.ganttRef.current.toggleColumnVisibility(false);
    }

    @bind
    toggleColumnVisibility() {
        this.setState(
            prevState => ({
                isColumnsHidden: !prevState.isColumnsHidden
            }),
            () => {
                const { isColumnsHidden } = this.state;
                if (isColumnsHidden) {
                    return this.hideColumns();
                }
                return this.showColumns();
            }
        );
    }

    @bind
    setGanttOffset(offset: number) {
        this.setState({ ganttOffset: offset });
    }

    @bind
    @memoize()
    filterRecord(task) {
        const {
            primary: { startDate, dueDate, progress, priority },
            description,
            id,
            name
        } = task;
        return { primary: { startDate, dueDate, progress, priority }, description, id, name };
    }

    @bind
    onTaskEdit({ id, field, value }) {
        if (!id || !field) return;
        const { records } = this.props;
        let task = records.find(task => task.id === id);
        if (!task) return;
        task = set(task, field, value);
        this.props.updateTask(this.filterRecord(task)).then((response) => {
            if (response instanceof Error) return;
            this.refreshAction();
        });
    }

    @bind
    @memoize()
    loadTasks(options, start: Date, end: Date) {
        const formattedOptions = formatOptionsForTask(options, this.props.userProfile);
        let useFilterOverRange = false;
        (options.filterBy || []).forEach((filter) => {
            const { field, or } = filter;
            if (or) return;
            if (field.startsWith('primary')) {
                const name = field.split('.')[1];
                // if start date is filtered prioritize this filter over range
                if (name === 'startDate') {
                    useFilterOverRange = true;
                }
            } else {
                // if due date is filtered prioritize this filter over range
                if (field === 'dueDate') {
                    useFilterOverRange = true;
                }
            }
        });

        // set default range filter to null and use date filters instead
        if (useFilterOverRange) {
            return this.props.loadTimelineTasks(formattedOptions);
        } else {
            return this.props.loadTimelineTasks(formattedOptions, start, end);
        }
    }

    @bind
    toggleAddTaskModal() {
        this.setState(prevState => ({ isAddTaskModalOpen: !prevState.isAddTaskModalOpen }));
    }

    @bind
    toggleToolbarVisibility() {
        this.setState(prevState => ({ isToolbarHidden: !prevState.isToolbarHidden }));
    }

    @bind
    openTask() {
        if (this.state.selectedTaskId) {
            window.open(`/#/abox/task/${this.state.selectedTaskId}`, '_blank');
        }
    }

    @bind
    navigateToBeta(){
        this.props.history.push(`/abox/timeline`);
    }

    @bind
    addTasksToTimeline(tasks: Array<Object>) {
        if (isEmpty(tasks)) return;
        const range = get(this.props.preferences, 'timeline.range', 'weeks');
        const { startDate, dueDate } = this.getStartDueDates(range);
        if (!startDate || !dueDate) return;
        Promise.all(
            tasks.map(({ id }) => {
                return this.props.updateTask({ id, primary: { startDate, dueDate } });
            })
        ).then(this.refreshAction);
    }

    @bind
    @memoize()
    getStartDueDates(range: string) {
        let [startDate, dueDate] = getDateRanges(range);
        startDate = startDate.toISOString();
        dueDate = dueDate.toISOString();
        return { startDate, dueDate };
    }


    @bind
    copyIdToClipBoard(id: string) {
        copyToClipboard(id)
            .then(() => {
                this.props.showToastr({ severity: 'success', detail: 'Task ID copied to clipboard' });
            })
            .catch(() => {
                this.props.showToastr({ severity: 'error', detail: 'Task ID could not copied to clipboard' });
            });
    }

    render() {
        const { permissions, isAdmin } = this.props.userProfile;
        const permissionsSet = new Set(permissions || []);
        const canView = isAdmin || permissionsSet.has('abox.timeline');
        if (!canView) {
            return <PageNotAllowed title="Timeline" />;
        }
        const { records, isLoading, totalRecords, showToastr, preferences } = this.props;
        const {
            isLoadingUpdateTask,
            disableCountdown,
            countdownSeconds,
            ganttOffset,
            isAddTaskModalOpen,
            isColumnsHidden,
            isToolbarHidden
        } = this.state;
        const range = get(preferences, 'timeline.range', 'weeks'); // If no range default week range will be sent
        const { startDate, dueDate } = this.getStartDueDates(range);

        return (
            <Fragment>
                <TimelineToolbar
                    onChangeRange={this.onChangeRange}
                    onPrevious={this.onPrevious}
                    onNext={this.onNext}
                    onToday={this.onToday}
                    onZoomIn={this.onZoomIn}
                    onZoomOut={this.onZoomOut}
                    onResetZoom={this.onResetZoom}
                    range={range}
                    checked={!isToolbarHidden}
                    totalRecords={totalRecords}
                />
                <FiltersTimeline
                    id="TimeLineFilters"
                    contentOffset={ganttOffset}
                    filterDefinitions={this.filterDefinitions}
                    searchBar={this.searchBar}
                    defaultFilters={this.defaultFilters}
                    defaultOrder={this.defaultOrder}
                    breadcrumb={this.breadcrumb}
                    hideDivider
                    rightToolbar={
                        <Fragment>
                            <StyledButton variant='text' color='secondary' onClick={this.navigateToBeta} children={'Beta Version'}/>
                            <ReloadCountdown
                                disableCountdown={disableCountdown || isLoading}
                                seconds={countdownSeconds}
                                format="minutes"
                                action={this.refreshAction}
                            />
                            <Tooltip title="Add Tasks to Timeline" arrow>
                                <IconButton onClick={this.toggleAddTaskModal}>
                                    <Icon name="plus-circle" />
                                </IconButton>
                            </Tooltip>
                            {!isMobile ? (
                                <Tooltip title={isColumnsHidden ? 'Show Columns' : 'Hide Columns'} arrow>
                                    <IconButton onClick={this.toggleColumnVisibility}>
                                        <Icon name={isColumnsHidden ? 'eye' : 'eye-off'} />
                                    </IconButton>
                                </Tooltip>
                            ) : null}

                            <Tooltip title={isToolbarHidden ? 'Show Toolbar' : 'Hide Toolbar'} arrow placement="top">
                                <IconButton onClick={this.toggleToolbarVisibility}>
                                    <Icon name={isToolbarHidden ? 'arrow-down-drop-circle' : 'arrow-up-drop-circle'} />
                                </IconButton>
                            </Tooltip>
                        </Fragment>
                    }
                >
                    {(filterBy, orderBy, excludeBy) => {
                        return (
                            <PageTemplate title="Dashboard">
                                <ContentArea>
                                    {(isLoading || isLoadingUpdateTask) && <Loader absolute />}
                                    <Gantt
                                        ref={this.ganttRef}
                                        zoom={range}
                                        resize={this.props.isNavOpen}
                                        loadData={this.loadTasks}
                                        filterBy={filterBy}
                                        orderBy={orderBy}
                                        excludeBy={excludeBy}
                                        records={records}
                                        onAfterTaskDrag={this.updateTaskDate}
                                        onTaskClick={this.openTaskDrawer}
                                        onTaskEdit={this.onTaskEdit}
                                        showToastr={showToastr}
                                        setGanttOffset={this.setGanttOffset}
                                        copyIdToClipBoard={this.copyIdToClipBoard}
                                    />
                                </ContentArea>

                            </PageTemplate>
                        );
                    }}
                </FiltersTimeline>
                <AddTaskModal
                    startDate={startDate}
                    dueDate={dueDate}
                    isOpen={isAddTaskModalOpen}
                    onSubmit={this.addTasksToTimeline}
                    closeModal={this.toggleAddTaskModal}
                />
            </Fragment>
        );
    }
}

export default connect(
    state => ({
        isLoading: state.abox.task.timeline.isLoading,
        records: state.abox.task.timeline.records,
        totalRecords: state.abox.task.timeline.count,
        userProfile: state.user.profile,
        isNavOpen: state.app.isNavOpen,
        preferences: state.user.preferences
    }),
    {
        loadTimelineTasks,
        updateTask,
        showToastr,
        saveTimelinePreferences,
        openTaskSidebar,
        setDocumentTitle,
    }
)(withRouter(GanttChart));
