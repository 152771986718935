/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import debouncePromise from 'p-debounce';
import moment from 'moment';
import styled from 'styled-components';
import { gantt } from 'dhtmlx-gantt';
import { bind } from 'app/utils/decorators/decoratorUtils';

import { isMobile } from 'react-device-detect';
import { tasksFormatter, linesFormatter } from 'app/utils/gantt/formatter';
import { validateTaskStartDate, getLatestPredecessor } from 'app/utils/gantt/utils';
import {
    tooltipTemplate,
    hourScaleTemplate,
    dayScaleTemplate,
    weekScaleTemplate,
    monthScaleTemplate,
    gridTaskTemplate,
    taskTemplate,
    gridTaskStartDateTemplate,
    gridTaskEndDateTemplate,
    gridTaskDurationTemplate,
    gridIconTemplate
} from 'app/utils/gantt/templates';
import './Gantt.css';
import { formatDate, DATE_FORMAT, getDateRanges } from 'app/utils/date/date';



const ContainerTimeLineLegacy = styled.div`

        & .dark .gantt_container {
            background: ${({theme})=> theme.material.colors.background.default};
            border-top: 1px solid ${({theme})=> theme.material.colors.background.divider};
        }
        & .dark .gantt_task_row {
            background: ${({theme})=> theme.material.colors.background.default};
        }

        & .dark .gantt_task_row.odd {
            background: ${({theme})=> theme.material.colors.background.default};
        }
        & .dark .gantt_row {
            background: ${({theme})=> theme.material.colors.background.default};
        }
        & .dark .gantt_grid_editor_placeholder input {
            background: ${({theme})=> theme.material.colors.background.default};
            color: ${({theme})=> theme.material.colors.text.primary};
        }
        &  .dark .gantt_grid_data .gantt_cell {
            border-right: 1px solid ${({theme})=> theme.material.colors.background.divider};
            color: ${({theme})=> theme.material.colors.text.primary};
        }

        & .dark .gantt_grid_scale, .dark .gantt_task_scale, .dark .gantt_task_vscroll {
            background: ${({theme})=> theme.material.colors.background.default};
        }
        & .dark .gantt_task .gantt_task_scale .gantt_scale_cell {
            border-right: 1px solid ${({theme})=> theme.material.colors.background.default};
        }
        & .dark .gantt_grid_scale, .dark .gantt_task_scale {
             border-bottom: 1px solid ${({theme})=> theme.material.colors.background.divider};
        }
        & .dark .gantt_grid_column_resize_wrap .gantt_grid_column_resize {
            background-color: 1px solid ${({theme})=> theme.material.colors.background.divider};
        }
        & .dark .gantt_task_line {
            background: ${({theme})=> theme.material.colors.background.default};
            border: 1px solid ${({theme})=> theme.material.colors.background.divider};
        }
        & .dark .gantt_layout_cell_border_right {
             border-right: 1px solid ${({theme})=> theme.material.colors.background.divider};
        }
        & .dark .gantt_task_row.gantt_selected .gantt_task_cell {
            border-color: ${({theme})=> theme.material.colors.background.divider};
        }
        & .dark .gantt_line_wrapper div {
            background-color: ${({theme})=> theme.material.colors.text.secondary};
        }
        /* & .dark .gantt_ver_scroll {
            background: ${({ theme })=> theme.material.colors.background.scrollbar};
            border-color: ${({theme})=> theme.material.colors.background.divider };
        } */
        & .dark .gantt_scale_cell.white {
            color: ${({theme})=> theme.material.colors.text.primary};
        }
        & .dark .gantt_task_line.success {
            border-color: ${({theme})=> theme.material.colors.background.paper };
            background: ${({theme})=> theme.material.colors.background.paper };
        }
        & .dark .gantt_task_line.info {
            border-color: ${({theme})=> theme.material.colors.background.paper };
            background: ${({theme})=> theme.material.colors.background.paper };
        }
        & .dark .gantt_task_line.alert {
            border-color: ${({theme})=> theme.material.colors.background.paper };
            background:${({theme})=> theme.material.colors.background.paper };
        }
        & .dark .gantt_task_line.warning {
            border-color: ${({theme})=> theme.material.colors.background.paper };
            background: ${({theme})=> theme.material.colors.background.paper };
        }
        & .dark .gantt_task_line.danger {
            border-color: ${({theme})=> theme.material.colors.background.paper };
            background: ${({theme})=> theme.material.colors.background.paper };
        }
        & .dark .gantt_link_arrow_right, .dark .gantt_link_arrow_left {
            border-color: ${({theme})=> theme.material.colors.background.divider };
        }
        & .dark .gantt_grid_data .gantt_row.odd:hover, .dark .gantt_grid_data .gantt_row:hover, .dark .gantt_grid_data .gantt_row.gantt_selected, .dark .gantt_grid_data .gantt_row.odd.gantt_selected, .dark .gantt_task_row.gantt_selected {
            background-color: ${({theme})=> theme.material.colors.background.hover };
        }
        & .task-template__avatar__icon {
             color: ${({theme})=> theme.material.colors.text.primary };
        }
        & .task-template__info a, .task-template__info h3, .task-template__info h4 {
            color: ${({theme})=> theme.material.colors.text.primary } !important;
        }
        & .grid-template .task-template__info a {
            color: ${({theme})=> theme.material.colors.text.primary };
        }
        & .grid-template .task-template__info h3 {
            color: ${({theme})=> theme.material.colors.text.primary };
        }
        & .date-scale.date-scale--highlight {
            color: ${({theme})=> theme.material.colors.text.primary };
        }
        & .date-scale__num {
            color: ${({theme})=> theme.material.colors.text.primary };
        }
        & .task-template__info {
            color: ${({theme})=> theme.material.colors.text.primary };
        }
        & .gantt_grid_head_cell {
            color: ${({theme})=> theme.material.colors.text.secondary };
        }
        & .gantt_task .gantt_task_scale .gantt_scale_cell {
            color: ${({theme})=> theme.material.colors.text.secondary };
        }
`;


export default class Gantt extends PureComponent<Object, Object> {
    static propTypes = {
        zoom: PropTypes.string,
        resize: PropTypes.bool,
        loadData: PropTypes.func.isRequired,
        onAfterTaskDrag: PropTypes.func.isRequired,
        onTaskClick: PropTypes.func.isRequired,
        showToastr: PropTypes.func.isRequired,
        setGanttOffset: PropTypes.func.isRequired,
        filterBy: PropTypes.arrayOf(PropTypes.object),
        orderBy: PropTypes.arrayOf(PropTypes.object),
        excludeBy: PropTypes.arrayOf(PropTypes.object),
        records: PropTypes.array
    };

    static defaultProps = {
        zoom: 'weeks',
        resize: false
    };

    state = {
        tasks: {
            data: [],
            links: []
        },
        isZooming: false
    };

    ganttContainer: any;
    ganttEvents: any[];

    // get all task with predecessor to allow disable date changes
    taskWithPredecessor: Object[] = [];

    // the current dragged task
    currentTask: Object;

    zoomConfig = {
        levels: [
            {
                name: 'days',
                scale_height: 27,
                min_column_width: 80,
                scales: [{ unit: 'day', step: 1, format: '%d %M' }]
            },
            {
                name: 'weeks',
                scale_height: 50,
                min_column_width: 50,
                scales: [
                    {
                        unit: 'week',
                        step: 1,
                        format: function(date: Date) {
                            var dateToStr = gantt.date.date_to_str('%d %M');
                            var endDate = gantt.date.add(date, -6, 'day');
                            var weekNum = gantt.date.date_to_str('%W')(date);
                            return '#' + weekNum + ', ' + dateToStr(date) + ' - ' + dateToStr(endDate);
                        }
                    },
                    { unit: 'day', step: 1, format: '%j %D' }
                ]
            },
            {
                name: 'months',
                scale_height: 50,
                min_column_width: 120,
                scales: [
                    { unit: 'month', format: '%F, %Y' },
                    { unit: 'week', format: 'Week #%W' }
                ]
            },
            {
                name: 'years',
                scale_height: 50,
                min_column_width: 30,
                scales: [{ unit: 'year', step: 1, format: '%Y' }]
            }
        ]
    };

    initZoom() {
        if (!this.state.isZooming) {
            const { zoom } = this.props;

            delete gantt.config.scale_height;
            delete gantt.config.scale_unit;
            delete gantt.config.date_scale;
            delete gantt.config.subscales;

            gantt.ext.zoom.init(this.zoomConfig);
            gantt.ext.zoom.setLevel(zoom);

            this.setGanttRangeOnZoom('years');
        }
    }

    zoomIn() {
        this.initZoom();
        this.setState({ isZooming: true });
        gantt.ext.zoom.zoomIn();
    }

    zoomOut() {
        this.initZoom();
        this.setState({ isZooming: true });
        gantt.ext.zoom.zoomOut();
    }

    resetZoom() {
        const { zoom } = this.props;
        this.setState({ isZooming: false });
        this.setGanttZoom(zoom);
        this.setGanttRangeOnZoom(zoom);
    }

    today() {
        const start = moment().startOf('day');
        const end = moment().endOf('day');
        this.setGanttRange(start, end);
        this.refreshData();
    }

    setGanttRange(start: any, end: any) {
        gantt.config.start_date = new Date(start);
        gantt.config.end_date = new Date(end);
    }

    setGanttRangeOnZoom(range: string) {
        if (!range) return;
        const [startDate, endDate] = getDateRanges(range);

        // set start end date of gantt chart
        this.setGanttRange(startDate, endDate);
    }

    setNewGanttRange(op: string, units: string, key: string) {
        // convert m = minutes to M for months
        const newKey = key === 'm' ? key.toUpperCase() : key;

        const startDate = moment(gantt.config.start_date);
        const endDate = moment(gantt.config.end_date);

        // add and subract dates based on moment.add().subtract()

        // $FlowFixMe
        startDate[op](units, newKey);

        // $FlowFixMe
        endDate[op](units, newKey);

        // set start end date of gantt chart
        this.setGanttRange(startDate, endDate);
        gantt.render();
    }

    viewNextDates() {
        const key = this.props.zoom.charAt(0);
        this.setNewGanttRange('add', '1', key);
        this.refreshData();
    }

    viewPreviousDates() {
        const key = this.props.zoom.charAt(0);
        this.setNewGanttRange('subtract', '1', key);
        this.refreshData();
    }

    setGanttZoom(range: string) {
        switch (range) {
            case 'days':
                gantt.config.min_column_width = 70;
                gantt.config.scale_height = 90;
                gantt.config.scale_unit = 'day';
                gantt.config.date_scale = '%j %F %Y, %l';
                gantt.config.subscales = [{ unit: 'hour', step: 1, date: '%g %a', template: hourScaleTemplate }];
                break;
            case 'weeks':
                gantt.config.min_column_width = 140;
                gantt.config.scale_height = 90;
                gantt.config.scale_unit = 'day';
                gantt.config.date_scale = '%j %F %Y, %l';
                gantt.config.subscales = [{ unit: 'hour', step: 3, date: '%g %a', template: hourScaleTemplate }];
                break;
            case 'months':
                gantt.config.min_column_width = 210;
                gantt.config.scale_height = 90;
                gantt.config.scale_unit = 'month';
                gantt.config.date_scale = '%F %Y';
                gantt.config.subscales = [{ unit: 'day', step: 1, template: dayScaleTemplate }];
                break;
            case 'years':
                gantt.config.min_column_width = 380;
                gantt.config.scale_height = 130;
                gantt.config.scale_unit = 'year';
                gantt.config.date_scale = '%Y';
                gantt.config.subscales = [
                    { unit: 'month', step: 1, template: monthScaleTemplate },
                    { unit: 'week', step: 1, template: weekScaleTemplate }
                ];
                break;
            default:
                break;
        }
    }

    updateTask(task: Object) {
        if (!task) return;

        if (this.state.isZooming) {
            this.resetZoom();
        }

        this.refreshData();
    }

    refreshData() {
        this.loadData(new Date(gantt.config.start_date), new Date(gantt.config.end_date));
    }

    toggleColumnVisibility(hide){
        this.initGanttConfig();
        if (hide) {
            gantt.config.layout = {
                css: 'gantt_container',
                cols: [
                    {
                        width: 1,
                        min_width: 1,

                        // adding horizontal scrollbar to the grid via the scrollX attribute
                        rows: [
                            { view: 'grid', scrollX: 'gridScroll', scrollable: true, scrollY: 'scrollVer' },
                            { view: 'scrollbar', id: 'gridScroll' }
                        ]
                    },
                    { resizer: true, width: 1 },
                    {
                        rows: [
                            { view: 'timeline', scrollX: 'scrollHor', scrollY: 'scrollVer' },
                            { view: 'scrollbar', id: 'scrollHor' }
                        ]
                    },
                    { view: 'scrollbar', id: 'scrollVer' }
                ]
            };
        }

        const { zoom } = this.props;
        gantt.ext.zoom.init(this.zoomConfig);
        gantt.ext.zoom.setLevel(zoom);
        this.setGanttZoom(zoom);
        this.setGanttRangeOnZoom(zoom);

        gantt.init(this.ganttContainer);
    }

    initGanttConfig() {
        gantt.config.show_unscheduled = true;
        gantt.config.drag_resize = true;
        gantt.config.drag_progress = false;
        gantt.config.drag_links = false;
        gantt.config.drag_move = true;
        gantt.config.row_height = 60;
        gantt.config.task_height = 44;
        gantt.config.link_line_width = 1;
        gantt.config.details_on_dblclick = false;
        gantt.config.fit_tasks = true;
        gantt.config.smart_rendering = true;
        gantt.config.min_column_width = 70;
        gantt.config.scale_height = 90;
        gantt.config.show_progress = true;
        // horizontal scrolling of task information columns
        const width = isMobile ? 1 : 600;
        gantt.config.layout = {
            css: 'gantt_container',
            cols: [
                {
                    width: width,
                    min_width: width,

                    // adding horizontal scrollbar to the grid via the scrollX attribute
                    rows: [
                        { view: 'grid', scrollX: 'gridScroll', scrollable: true, scrollY: 'scrollVer' },
                        { view: 'scrollbar', id: 'gridScroll' },
                    ]
                },
                { resizer: true, width: 1 },
                {
                    rows: [
                        { view: 'timeline', scrollX: 'scrollHor', scrollY: 'scrollVer' },
                        { view: 'scrollbar', id: 'scrollHor' }
                    ]
                },
                { view: 'scrollbar', id: 'scrollVer' }
            ]
        };

        // if mobile hide grid
        // if (isMobile) gantt.config.show_grid = false;

        const textEditor = { type: 'text', map_to: 'text' };
        const startDateEditor = { type: 'date', map_to: 'start_date', min: new Date(2019, 0, 1), max: new Date(2022, 0, 1) };
        const endDateEditor = { type: 'date', map_to: 'end_date', min: new Date(2019, 0, 1), max: new Date(2022, 0, 1) };

        gantt.config.columns = [
            { name: 'icon', label: ' ', width: 50, align: 'center', template: gridIconTemplate, min_width: 50, resize: true },
            { name: 'text', label: 'Task Name', width: '*', tree: true, editor: textEditor, template: gridTaskTemplate, min_width: 170, resize: true },
            {
                name: 'start_date',
                label: 'Start Date',
                width: 140,
                min_width: 140,
                editor: startDateEditor,
                align: 'center',
                template: gridTaskStartDateTemplate
            },
            { name: 'end_date', label: 'Due Date', width: 140, min_width: 140, editor: endDateEditor, align: 'center', template: gridTaskEndDateTemplate },
            { name: 'duration', label: 'Duration', width: 100, min_width: 80, align: 'center', template: gridTaskDurationTemplate }
        ];
    }

    initTemplateConfig() {
        // set task content
        gantt.templates.task_text = (start, end, task) => {
            return taskTemplate(task);
        };

        // set tooltip content
        gantt.templates.tooltip_text = (start, end, task) => {
            return tooltipTemplate(task);
        };

        // set task class based on priority
        gantt.templates.task_class = (start, end, task) => {
            const isClosed = task.type === 'closedtask';
            if (isClosed) return 'closed';

            switch (task.priority) {
                case 5:
                    return 'info';
                case 4:
                    return 'success';
                case 3:
                case 50:
                    return 'alert';
                case 2:
                    return 'warning';
                case 1:
                    return 'danger';
                default:
                    break;
            }
        };
    }

    initGanttEvents() {
        const { onAfterTaskDrag, onTaskClick, onTaskEdit } = this.props;
        let clickTimeout = null;
        this.ganttEvents = [];

        this.ganttEvents.push(
            gantt.attachEvent('onGanttReady', () => {
                const tooltips = gantt.ext.tooltips;
                tooltips.tooltip.setViewport(gantt.$task_data);
            })
        );

        const inlineEditors = gantt.ext.inlineEditors;

        inlineEditors.attachEvent('onBeforeSave', (state) => {
            if (!gantt.ext.inlineEditors.isChanged()) return false;
            const { id, columnName: key, newValue: value } = state;
            try {
                let field = '';
                switch (key) {
                    case 'text':
                        field = 'name';
                        break;
                    case 'start_date':
                        field = 'primary.startDate';
                        break;
                    case 'end_date':
                        field = 'primary.dueDate';
                        break;
                    default:
                }
                if (!field) return false;
                const task = gantt.getTask(id);
                if (key === 'start_date' || key === 'end_date') {
                    let orignalDate = null;
                    const { startDate, dueDate } = task.primary || {};
                    if (key === 'start_date') {
                        const start = moment(value);
                        const due = moment(dueDate);
                        if (due.isBefore(start.add(1, 'minutes'))) {
                            this.props.showToastr({
                                severity: 'warning',
                                detail: 'Update not possible because start date can not be after due date'
                            });
                            return false;
                        }
                        orignalDate = formatDate(startDate, DATE_FORMAT);
                    } else {
                        if (
                            moment(startDate)
                                .add(1, 'minutes')
                                .isAfter(value)
                        ) {
                            this.props.showToastr({
                                severity: 'warning',
                                detail: 'Update not possible because due date can not be before start date'
                            });
                            return false;
                        }
                        orignalDate = formatDate(dueDate, DATE_FORMAT);
                    }
                    const changedDate = formatDate(value, DATE_FORMAT);
                    if (changedDate === orignalDate) {
                        // If the difference between changed date and original date is less than 24 hours or if no difference then we wont save any value
                        return false; // This is because that currently we dont have date and time picker in our timeline. We just have a date picker.
                    }
                }
                const modifiedValue = key === 'start_date' || key === 'end_date' ? moment(value).toISOString() : value;
                if (!value || !modifiedValue) return false;
                onTaskEdit({ id, field, value: modifiedValue });
            } catch (e) {}
            return true;
        });
        inlineEditors.attachEvent('onBeforeEditStart', function(state) {
            const task = gantt.getTask(state.id);
            return task.type === 'opentask';
        });

        this.ganttEvents.push(
            gantt.attachEvent('onTaskClick', (id, e) => {
                const elemClass = e.target.className;
                if (elemClass.includes('gantt_close')) {
                    // handle tree click
                    gantt.close(id);
                    return;
                } else if (elemClass.includes('gantt_open')) {
                    gantt.open(id);
                    return;
                }

                // handle if single or double click (cannot run onTaskClick and onTaskDblClick at the same time)
                if (clickTimeout !== null) {
                    window.open(`/#/abox/task/${id}`, '_blank');
                    clearTimeout(clickTimeout);
                    clickTimeout = null;
                } else {
                    clickTimeout = setTimeout(() => {
                        if (elemClass.includes('task-template')) {
                            // copy id to clipboard if task template is clicked
                            this.props.copyIdToClipBoard(id);
                        } else if (elemClass.includes('open-task-info')) {
                            const task = gantt.getTask(id);
                            onTaskClick(id, task);
                        }
                        if (clickTimeout) {
                            clearTimeout(clickTimeout);
                        }
                        clickTimeout = null;
                    }, 300);
                }
            })
        );

        this.ganttEvents.push(
            gantt.attachEvent('onBeforeTaskDrag', (id, mode, e) => {
                const modes = gantt.config.drag_mode;

                if (!(mode === modes.move || mode === modes.resize)) return true;

                this.currentTask = { ...gantt.getTask(id) };

                // if task is closed disable drag
                if (this.currentTask.type === 'closedtask') return false;

                return true;
            })
        );

        this.ganttEvents.push(
            gantt.attachEvent('onAfterTaskDrag', (id, mode, e) => {
                const task = gantt.getTask(id);
                if (validateTaskStartDate(task, this.taskWithPredecessor)) {
                    onAfterTaskDrag(id, mode, task);
                } else {
                    task.start_date = new Date(this.currentTask.start_date);
                    task.end_date = new Date(this.currentTask.end_date);
                    gantt.updateTask(id);

                    const predecessorTask = getLatestPredecessor(task, this.taskWithPredecessor);

                    if (predecessorTask) {
                        const { name, dueDate } = predecessorTask.predecessor;
                        const formattedDueDate = moment(dueDate).format('DD-MM-YYYY HH:mm');

                        this.props.showToastr({
                            severity: 'warning',
                            detail: `Update not possible due to conflicting due date for a preceeding task (${name}, ${formattedDueDate}). Kindly check your task relationship`
                        });
                    }
                }
            })
        );
    }

    @bind
    getGanttOffset() {
        const { setGanttOffset } = this.props;
        const offsetTop = this.ganttContainer.getBoundingClientRect().top;
        setGanttOffset(offsetTop);
    }

    loadData = debouncePromise((start: Date, end: Date) => {
        const { filterBy, orderBy, excludeBy, loadData } = this.props;

        const promise = loadData({ filterBy, orderBy, excludeBy }, start, end);
        const isPromise = promise instanceof Promise;

        if (!isPromise) {
            throw new Error('The loadData function MUST return a Promise.');
        }

        return promise;
    }, 400);

    componentDidUpdate(prevProps: Object) {
        const { orderBy, filterBy, records, zoom } = this.props;

        // rerender gantt
        gantt.render();

        // reset size of gantt chart
        setTimeout(() => {
            gantt.setSizes();
        }, 1000);

        if (orderBy !== prevProps.orderBy || filterBy !== prevProps.filterBy) {
            this.refreshData();
        }

        if (records !== prevProps.records) {
            // format the tasks and lines for the gantt chart
            const formattedTasks = tasksFormatter(records);
            const { lines, tasksWithPredecessor } = linesFormatter(records);
            const formattedLinks = lines;
            this.taskWithPredecessor = tasksWithPredecessor;

            this.setState(
                {
                    tasks: {
                        data: formattedTasks,
                        links: formattedLinks
                    }
                },
                () => {
                    gantt.clearAll();
                    gantt.parse(this.state.tasks);
                }
            );
        }

        if (zoom !== prevProps.zoom) {
            this.setState({ isZooming: false });
            this.setGanttZoom(zoom);
            this.setGanttRangeOnZoom(zoom);
            this.refreshData();
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.getGanttOffset);

        this.initGanttConfig();
        this.initTemplateConfig();
        this.initGanttEvents();

        const { zoom } = this.props;
        gantt.ext.zoom.init(this.zoomConfig);
        gantt.ext.zoom.setLevel(zoom);
        this.setGanttZoom(zoom);
        this.setGanttRangeOnZoom(zoom);

        gantt.plugins({
            tooltip: true
        });

        gantt.init(this.ganttContainer);
        gantt.parse(this.state.tasks);

        window.onresize = () => {
            gantt.setSizes();
        };
        this.getGanttOffset();


        this.refreshData();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.getGanttOffset);

        while (this.ganttEvents.length) gantt.detachEvent(this.ganttEvents.pop());

        gantt.ext.tooltips.tooltip.hide();
    }

    render() {
        return (
            <ContainerTimeLineLegacy  style={{ width: '100%', height: '100%' }}>
                <div
                    className={'dark'}
                    ref={(input) => {
                        this.ganttContainer = input;
                    }}
                   
                />
            </ContainerTimeLineLegacy>
          
        );
    }
}
