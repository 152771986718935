/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, CircularProgress } from '@mic3/platform-ui';

import history from 'store/History';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import { bind } from 'app/utils/decorators/decoratorUtils';
import { set } from 'app/utils/lo/lo';
import { createEntity, updateEntity } from 'store/actions/entities/entitiesActions';
import { groupFields } from 'app/utils/classification/classificationForm';
import { getClassAncestors } from 'app/utils/classification/classificationUtils';
import Loader from 'app/components/atoms/Loader/Loader';
import { setAddPinModal } from 'store/actions/maps/situationalAwarenessActions';
import { setDocumentTitle } from 'store/actions/app/appActions';
import { modulesAndPageTitles } from 'app/config/typesConfig';

const filterRequired = (field) => {
    let normalized = field;

    if (field.children) {
        const requiredChildren = filterRequiredDefinitions(field.children);
        if (!requiredChildren?.length) {
            return null;
        }
        normalized = set(normalized, 'children', requiredChildren);
    } else if (!field?.constraints?.required) {
        return null;
    }
    return normalized;
};

export const filterRequiredDefinitions = definitions => (definitions || []).map(filterRequired).filter(Boolean);

class AddEntity extends PureComponent<Object, Object> {
    static propTypes = {
        createEntity: PropTypes.func.isRequired,
        updateEntity: PropTypes.func.isRequired,
        title: PropTypes.string,
        setAddPinModal: PropTypes.func.isRequired,
    };

    state = { activeClass: this.props.defaultSelected || null };

    formRef = React.createRef();

    componentDidMount() {
        const { match, setDocumentTitle, classInfo } = this.props;
        let title = modulesAndPageTitles.entities.addEntity;
        if(classInfo?.title === 'Integration' ){
            title = classInfo.title + ' - ' + 'Add Integration';
        }
        if(match?.path?.includes('add')){
            setDocumentTitle(title);
        }
    }

    @bind
    buildComponents(activeClass) {
        const primaryClasses = this.props.primaryClasses.length ? this.props.primaryClasses : this.props.allPrimaryClasses;
        const classAncestors =  getClassAncestors(activeClass?.id, primaryClasses);
        const { classInfo, mapProps } = this.props;
        const isLayer = mapProps?.isLayer;
        const nameDef = {
            type: 'text',
            properties: { label: 'Name', name: 'name' },
            constraints: { required: true }
        };
        if (classInfo?.uri) {
            return [
                nameDef,
                {
                    type: 'group',
                    properties: { name: 'attributes', expanded: true },
                    children: groupFields(classInfo?.formDefinition?.fields)
                }
            ];
        }
        return [
            {
                type: 'entityTypesTypeahead',
                properties: {
                    label: 'Entity Type',
                    name: 'entityType',
                    disabled: isLayer,
                    onChange: (evnt) => {
                        const activeClass = evnt.target.value;
                        this.setState({ activeClass });
                        return [evnt.target];
                    },
                    defaultValue: !activeClass?.abstract ? activeClass : '',
                    filterBy: [
                        { field: 'active', op: '=', value: true},
                        { field: 'hidden', op: '=', value: false},
                        { field: 'abstract', op: '=', value: false}]
                },
                constraints: { required: true }
            },
            nameDef,
            ...(activeClass ? (classAncestors || []).map(cls => ({
                type: 'group',
                properties: { name: 'attributes' },
                children: groupFields(filterRequiredDefinitions(cls?.formDefinition?.fields), { classData: cls })
            })) : [])
        ].filter(Boolean);
    }

    @bind
    onFormSubmit(event: Event) {
        event.preventDefault();
        const { onClose, mapProps, classInfo } = this.props;
        this.formRef.current.isValidForm().then(({ data, errors }) => {
            if (!errors) {
                this.props
                    .createEntity({
                        name: data?.name?.trim(),
                        type: classInfo?.uri || data.entityType?.uri,
                        primary: data.attributes
                    })
                    .then(async (result) => {
                        const { id, type } = result || {};
                        if(!(result instanceof Error) && result.id) {
                            const { reloadList } = classInfo || {};
                            if (reloadList) {
                                reloadList();
                            }
                            if(mapProps) {
                                const { coordinates, mapObj, longitude, latitude, refreshLayers } = mapProps;
                                const result = await mapObj.getAddressLocation(latitude, longitude);
                                if(result && id && type) {
                                    const record = {
                                        id,
                                        type,
                                        geomAsEwkt: coordinates,
                                        primary: { locationInfo: result }
                                    };
                                    await this.props.updateEntity(record, true);
                                    await this.props.setAddPinModal(false);
                                    await refreshLayers();
                                    document.getElementById('map').style.cursor = null;
                                }
                                onClose();
                            }
                            else {
                                history.push(`/entities/${type}/${id}`);
                            }
                        }
                    });
            }
        });
    }

    render(): Object {
        const { onClose, isLoading, classInfo } = this.props;
        const { title } = classInfo || {};
        return (
            <>
                <ModalDialog
                    title={`Create a new ${title || 'Entity'}`}
                    onClose={onClose}
                    actions={isLoading ? <CircularProgress size={24} color="primary" /> : <Button onClick={this.onFormSubmit}>Save</Button>}
                >
                    {classInfo?.isLoading ? <Loader /> :
                        <FormGenerator components={this.buildComponents(this.state.activeClass)} ref={this.formRef} /> }
                </ModalDialog>
            </>
        );
    }
}

export default connect(
    state => ({
        isLoading: state.entities.createEntity.isLoading,
        primaryClasses: state.app.allPrimaryClasses.records,
    }),
    {
        createEntity,
        updateEntity,
        setAddPinModal,
        setDocumentTitle,
    }
)(AddEntity);
