/* @flow */

import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import uuidv1 from 'uuid/v1';
import { MdiIcon, IconButton, Tooltip } from '@mic3/platform-ui';
import styled from 'styled-components';
import { muiTheme } from 'app/themes/materialUi';
import { isMobile } from 'react-device-detect';

import { get } from 'app/utils/lo/lo';
import PageTemplate from 'app/components/templates/PageTemplate';
import AppGroup from 'app/components/ABox/MyApps/AppGroup/AppGroup';
import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import { loadAboxMyApps, loadAboxMyAppsGroups, saveAboxMyAppsGroups } from 'store/actions/abox/myAppsActions';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import Loader from 'app/components/atoms/Loader/Loader';
import Filters from 'app/components/organisms/Filters/Filters';
import history from 'store/History';
import { isEmpty } from 'app/utils/utils';
import DragNDropContext from 'app/containers/DragNDropContext/DragNDropContext';
import { modulesAndPageTitles } from 'app/config/typesConfig';
import { setDocumentTitle } from 'store/actions/app/appActions';

export const GROUP_LIST = 'groupList';
export const FAVORITE_PROCESSES = 'favoriteProcesses';

const MdiIconStyled = styled(MdiIcon)`
    line-height: 2rem;
`;

const StyledContentArea = styled(ContentArea)`
    overflow-x: hidden;
    overflow-y: auto;
    max-height: calc(100vh - 2.6rem);
`;

/**
 * Renders the view to display the classification.
 */
class MyApps extends PureComponent<Object, Object> {

    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        records: PropTypes.array.isRequired,
        preferences: PropTypes.object.isRequired,
        loadAboxMyApps: PropTypes.func.isRequired,
        loadAboxMyAppsGroups: PropTypes.func.isRequired,
        saveAboxMyAppsGroups: PropTypes.func.isRequired,
        userProfile: PropTypes.object
    }

    breadcrumb = [{ title: 'My Apps' }]
    searchBar = ['name', 'id'];
    defaultOrder = [{ field: 'name', direction: 'asc nulls last' }];

    filterDefinitions = [
        {
            field: 'name',
            type: 'text',
            properties: {
                label: 'Application name',
                name: 'name',
                opSelector: true
            },
            filters: false,
        },
        {
            field: 'id',
            type: 'uuid',
            properties: {
                label: 'ID',
                name: 'id',
                opSelector: true
            },
            condition: '=',
            filters: false
        },
        {
            field: 'modifiedDate',
            type: 'dateTimeRange',
            properties: {
                label: 'Modified date',
                name: 'modifiedDate'
            },
        },
        {
            field: 'createdBy.id',
            type: 'userTypeahead',
            properties: {
                label: 'Created by',
                name: 'createdBy',
                multiple: true,
                valueField: 'id'
            },
            condition: 'in',
            sort:false,
        }

    ];

    /**
     * @override
     */
    constructor(props) {
        super(props);
        this.props.loadAboxMyAppsGroups();
    }

    componentDidMount() {
        this.props.setDocumentTitle(modulesAndPageTitles.abox.myApps);
    }

    @bind
    loadApps(options: Object) {
        this.props.loadAboxMyApps(options);
    }

    @bind
    @memoize()
    groupTheApps(apps: Array<Object>, preferences: Object) {
        const groups = get(preferences, 'groups') || {};
        const appMap = apps.reduce((map, app) => {
            map[app.id] = app;
            return map;
        }, {});
        const appsInAGroup = new Set();
        const groupedApps = (Object.keys(groups)).reduce((list, groupId) => {
            const children = groups[groupId].children
                .map((appId) => {
                    if(!appMap[appId]) {
                        return null;
                    }
                    appsInAGroup.add(appId);
                    return { ...appMap[appId], type: 'child', uuid: appId };
                })
                .filter(Boolean);
            list.push({ ...groups[groupId], children, type: 'group', uuid: uuidv1()});
            return list;
        }, []).filter(a => !isEmpty(a.children));
        const appsNotInAGroup = apps.filter(({ id }) => !appsInAGroup.has(id));
        return [...groupedApps, ...appsNotInAGroup];
    }

    @bind
    goToEditApps() {
        history.push('/designer/processes');
    }

    render() {
        const { isLoading, records, preferences, saveAboxMyAppsGroups, userProfile: { permissions } } = this.props;
        const permissionsSet = new Set(permissions || []);
        const groupedApps = this.groupTheApps(records, preferences);
        const hasProcessViewPermission = permissionsSet.has(`designer.process`);
        return (
            <Fragment>
                { isLoading && <Loader absolute />}
                <DragNDropContext>
                    <PageTemplate title="My Apps" icon="classification-tags" iconType="af">
                        <StyledContentArea>
                            <Filters
                                id="MyApps"
                                breadcrumb={this.breadcrumb}
                                filterDefinitions={this.filterDefinitions}
                                searchBar={this.searchBar}
                                defaultOrder={this.defaultOrder}
                                rightToolbar={(!isMobile && hasProcessViewPermission) && (
                                    <Tooltip title="Go to designer">
                                        <IconButton onClick={this.goToEditApps} >
                                            <MdiIconStyled name="pencil" color={muiTheme.colors.text.secondary}/>
                                        </IconButton>
                                    </Tooltip>
                                )}
                            >
                                {(filterBy, orderBy, excludeBy) => (
                                    <AppGroup
                                        filterBy={filterBy}
                                        orderBy={orderBy}
                                        excludeBy={excludeBy}
                                        loadApps={this.loadApps}
                                        apps={groupedApps}
                                        savePreferences={saveAboxMyAppsGroups}
                                        title="All Apps"
                                        errorMessage="No Apps to display."
                                    />
                                )}
                            </Filters>
                        </StyledContentArea>
                    </PageTemplate>
                </DragNDropContext>
            </Fragment>

        );
    }
}

export default connect((state: Object) => ({
    isLoading: state.abox.app.list.isLoading,
    records: state.abox.app.list.records,
    preferences: state.abox.app.list.preferences,
    userProfile: state.user.profile
}), { loadAboxMyApps, loadAboxMyAppsGroups, saveAboxMyAppsGroups, setDocumentTitle })(MyApps);
