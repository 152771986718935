import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Typography } from '@mic3/platform-ui';

import { loadAvatar } from 'store/actions/app/appActions';
import { loadAvatar as loadRelDefAvatar } from 'store/actions/entities/relationshipsActions';

import { TRANSLATION_ACTION_KEYS, TRANSLATION_KEYS } from 'app/config/changelogConfig';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { checkEmptyAvatar, checkIfMultipleUpdates, enrichValue, normalizeType } from 'app/utils/changelog/changelogUtils';
import { capitalizeFirstLetter, deepEquals, isDefined, isEmpty, isEmptyArray, isObject } from 'app/utils/utils';
import { getAvatar } from 'app/utils/avatar/avatar';
import { get } from 'app/utils/lo/lo';
import { parseDurationText } from 'app/utils/date/date';

import Textarea from 'app/containers/Designer/Form/components/Textarea';
import EntityLink from 'app/components/atoms/Link/EntityLink';
import UserEntityLink from 'app/components/atoms/Link/UserEntityLink';
import ChangelogMultipleItem from './ChangelogMultipleItem';
import ChangelogSingleItem from './ChangelogSingleItem';
import { getSvgSource } from 'app/utils/maps/layer/layerUtils';

const linkStyle = css`
    font-weight: bold !important;
    color: ${({ theme }) => theme.material.colors.text.secondary} !important;
`;

export const EntityLinkStyled = styled(EntityLink)`
    ${linkStyle}
`;

const UserEntityLinkStyled = styled(UserEntityLink)`
    ${linkStyle}
`;

const CardContainer = styled.div`
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    background: ${({ theme, selected }) => theme.material.colors.itemHover};
`;

const TextareaStyled = styled(Textarea)`
    margin: 0 !important;
    padding: 0 !important;

    & > .MuiInputBase-root {
        padding: 12px 0 !important;

        & > .MuiInputBase-input {
            background: ${({ theme, selected }) => theme.material.colors.itemHover};
            padding: 12px !important;
            margin: 0 !important;
            font-size: 14px;
        }
    }
`;

const MultiInfoWrapper = styled.div`
    padding: 12px;
`;

const ListText = styled(Typography)`
    && {
        font-size: 14px;
        word-break: break-word;
        margin-bottom: 4px;
    }
`;

const SvgImg = styled.img`
    display: block;
    max-width: 120px;
    margin: 12px 0;
`;

class ChangelogItem extends PureComponent {
    static defaultProps = {
        resizeRow: () => {},
        updateHeight: () => {}
    }

    mounted = false;

    constructor(props) {
        super(props);
        this.state = {
            hasMultipleUpdates: checkIfMultipleUpdates(props.data)
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.props.resizeRow);
        this.mounted = true;
        this.initData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            !deepEquals(prevState, this.state) ||
            !deepEquals(prevProps.asyncData, this.props.asyncData) ||
            !deepEquals(prevProps.asyncDataEntity, this.props.asyncDataEntity) ||
            !deepEquals(prevProps.asyncDataClasses, this.props.asyncDataClasses) ||
            prevProps.isSidebarResizing !== this.props.isSidebarResizing
        ) {
            this.handleResize();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.props.resizeRow);
        this.mounted = false;
    }

    @bind
    handleResize() {
        setTimeout(() => {
            this.props.resizeRow();
            this.props.updateHeight();
        }, 200);
    }

    @bind
    async initData() {
        const { data, loadAvatar } = this.props;
        const action = Object.keys(data.change)[0];
        const isAddRemoveAction = ['add', 'remove'].includes(action);

        if (isAddRemoveAction) {
            const { accessor, item: { id, type: itemType, relatedEntity, relation } } = data.change[action];

            if (['chat.attachments', 'chat.messages'].includes(accessor)) {
                return;
            } else if (accessor === 'chat.messages') {
                return;
            } else if (accessor === 'relations') {
                this.loadRelations(relatedEntity, relation);
            } else if (itemType) {
                loadAvatar(id, normalizeType(itemType));
            } else {
                loadAvatar(id, normalizeType(accessor));
            }

            return;
        }

        // load primary data
        const assignee = get(data.change[action], 'primary.assignee');
        assignee && loadAvatar(assignee, 'entityUser');

        // load classes data if there are classes in data
        const classes = data.change[action]?.classes || [];
        if (!isEmptyArray(classes)) {
            this.loadClasses(classes);
        }
    }

    @bind
    loadRelations(entity, relation) {
        const { loadAvatar, loadRelDefAvatar } = this.props;
        const { relationDefinition: relDefId } = relation || {};
        const { id, type } = entity || {};

        relDefId && loadRelDefAvatar(relDefId);

        if (!['opentask', 'openprocess'].includes(type)) {
            loadAvatar(id, normalizeType(type));
        } else {
            const closedType = type.replace('open', 'closed');
            loadAvatar(id, type);
            loadAvatar(id, closedType);
        } 
    }

    @bind
    async loadClasses(classesIds) {
        const classAvatar = classesIds.map(classId => this.props.loadAvatar(classId, 'class'));
        await Promise.all(classAvatar).then();
    }

    @bind
    parseClasses(asyncDataClasses) {
        const isLoading = asyncDataClasses.find(cls => cls.isLoading);
        if (isLoading) return '';

        return asyncDataClasses.map((cls, index) => {
            return (
                <Fragment key={cls?.id} >
                    <EntityLinkStyled id={cls?.id} type="class">{get(cls, 'name', `(ID:${cls?.id})`)}</EntityLinkStyled>{index + 1 === asyncDataClasses.length ? '' : ', '}
                </Fragment>
            );
        });
    }

    @bind
    parseAsyncDataEntity(relatedEntity, relationDefinition) {
        if (relatedEntity?.isLoading || relationDefinition?.isLoading) return '';
        const { id, name, type } = relatedEntity || {};
        const { id: relDefId, description } = relationDefinition || {};

        return (
            <>
                {description && (
                    <>
                        {'definition: '}<EntityLinkStyled id={relDefId} type="relationdefinition">{description || 'No Name'}</EntityLinkStyled>{' '}
                    </>
                )}
                <>
                    {'entity: '}<EntityLinkStyled id={id} type={type}>{name || `(ID:${id})`}</EntityLinkStyled>
                </>
            </>
        );
    }

    @bind
    parseAsyncData(asyncData, role) {
        if (asyncData?.isLoading) return '';
        let linkElem;

        if (asyncData?.type === 'user') {
            linkElem = <UserEntityLinkStyled id={asyncData?.id}>{get(asyncData, 'name', `(ID:${asyncData.id})`)}</UserEntityLinkStyled>;
        } else {
            linkElem = <EntityLinkStyled id={asyncData?.id} type={asyncData?.type || 'class'}>{get(asyncData, 'name', `(ID:${asyncData.id})`)}</EntityLinkStyled>;
        }

        return (
            <>
                {linkElem}
                {role && ` as ${capitalizeFirstLetter(role)}`}
            </>
        );
    }

    @bind
    parseArray(arr) {
        return arr.map((value, index) => {
            const { id, type, name } = value || {};
            const addComma = index === arr.length - 1 ? '' : ', ';
            
            if (id && type) {
                return (
                    <>
                        <EntityLinkStyled id={id} type={type}>{name || 'No Name'}</EntityLinkStyled>{addComma}
                    </>
                );
            }

            if (isObject(value)) {
                return <TextareaStyled key={index} parseAs={'JSON'} name="json" value={value} clearable={false} disabled={true} />;
            }

            return (
                <>
                    {enrichValue(value)}{addComma}
                </>
            );
        });
    }

    @bind
    parseAttributes(updateKey, updateValue, asyncData) {
        if ((isObject(updateValue) && !updateValue?.id) && !Array.isArray(updateValue)) {
            if (updateValue?.isDuration) {
                return `"${parseDurationText(updateValue?.value, {})}"`;
            } else {
                return <TextareaStyled parseAs={'JSON'} name="json" value={updateValue} clearable={false} disabled={true} />;
            }
        } else if (Array.isArray(updateValue)) {
            const dropZoneValue = updateValue.find((obj => Object.keys(obj)[0] === 'path'));
            
            if (dropZoneValue) {
                return `"${enrichValue(updateValue, 'dropzone')}".`;
            } else {
                return this.parseArray(updateValue);
            }
        } else if (updateKey === 'assignee' && asyncData) {
            if (asyncData?.isLoading) return '';
            return <UserEntityLinkStyled id={asyncData?.id}>{get(asyncData, 'name', `(ID:${asyncData?.id})`)}</UserEntityLinkStyled>;
        } else if (updateKey === 'svg') {
            const imageSrc = getSvgSource({ svg: updateValue , svgGraphic: null });
            return <SvgImg src={imageSrc} alt="svg" />;
        } else if (updateKey === 'image') {
            return '.';
        } else {
            return `"${enrichValue(updateValue, updateKey)}".`;
        }
    }

    @bind
    @memoize()
    buildChangelogInfo(data, asyncData, asyncDataEntity, type, asyncDataClasses, asyncDataRelDef, hasMultipleUpdates) {
        const action = Object.keys(data.change)[0];

        switch (action) {
            case 'create':
                return this.renderCreateInfo(action, type);
            case 'add':
            case 'remove':
                return this.renderAddRemoveInfo(action, data, asyncData, asyncDataEntity, asyncDataRelDef);
            case 'update':
            case 'updateItem':
                const isRelAttribute = action === 'updateItem';

                if (hasMultipleUpdates) {
                    return this.renderUpdateInfoMultiple(action, data, asyncData, isRelAttribute);
                } else {
                    return this.renderUpdateInfoSingle(action, data, asyncData, asyncDataClasses, isRelAttribute);
                }
            default:
                return;
        }
    }

    @bind
    showUpdateText(action, updateKey, updateValue, hideTo, value) {
        if (((Array.isArray(updateValue) || isObject(updateValue)) && isEmpty(updateValue)) || !isDefined(updateValue)) {
            return (
                <>
                    {`${TRANSLATION_ACTION_KEYS['remove']} ${TRANSLATION_KEYS[updateKey] || updateKey}.`}
                </>
            );
        }
        
        return (
            <>
                {`${TRANSLATION_ACTION_KEYS[action]} ${TRANSLATION_KEYS[updateKey] || updateKey}`}{hideTo ? '' : ' to '}{value}
            </>
        );
    }

    @bind
    renderCreateInfo(action, type) {
        return `${TRANSLATION_ACTION_KEYS[action]} ${TRANSLATION_KEYS[type] || 'entity'}.`;
    }

    @bind
    renderAddRemoveInfo(action, data, asyncData, asyncDataEntity, asyncDataRelDef) {
        const { accessor, item } = data.change[action];
        const { type: itemType, role } = item || {};
        let renderType = TRANSLATION_KEYS[itemType || accessor];
        let renderValue = '';

        switch (accessor) {
            case 'entityResources':
                renderType = itemType;
                renderValue = this.parseAsyncData(asyncData);
                break;
            case 'chat.messages':
                renderValue = 'in chat.';
                break;
            case 'chat.attachments':
                renderValue = `"${get(item, 'file.name')}".`;
                break;
            case 'relations':
                renderValue = this.parseAsyncDataEntity(asyncDataEntity, asyncDataRelDef);
                break;
            default:
                renderValue = this.parseAsyncData(asyncData, role);
                break;
        }

        return (
            <>
                {`${TRANSLATION_ACTION_KEYS[action]} ${renderType}`}{' '}{renderValue}
            </>
        );
    }

    @bind
    renderUpdateInfoSingle(action, data, asyncData, asyncDataClasses, isRelAttributesUpdate) {
        const update = isRelAttributesUpdate ? data.change[action].update : data.change[action];
        let updateKey = Object.keys(update)[0];
        let updateValue = update[updateKey];
        let renderValue = '', hideTo = false;

        switch (updateKey) {
            case 'attributes':
            case 'primary':
                const parentKey = updateKey;

                if (updateKey === 'attributes' && !Object.keys(updateValue)?.length) {
                    renderValue = '{ }';
                    updateValue = '';
                    updateKey = '';
                    hideTo = true;
                    break;
                }
                updateKey = Object.keys(get(update, updateKey))[0];
                updateValue = get(update, `${parentKey}.${updateKey}`);

                renderValue = this.parseAttributes(updateKey, updateValue, asyncData);
                break;
            case 'classes':
                renderValue = this.parseClasses(asyncDataClasses);
                break;
            default:
                renderValue = this.parseAttributes(updateKey, updateValue);
                if (updateKey === 'image') hideTo = true;
                break;
        }

        return this.showUpdateText(action, updateKey, updateValue, hideTo, renderValue);
    }

    @bind
    renderUpdateInfoMultiple(action, data, asyncData, isRelAttributesUpdate) {
        const update = isRelAttributesUpdate ? data.change[action].update : data.change[action];
        const renderArr = [];
        let renderValue = '';

        for (const [updateKey, updateValue] of Object.entries(update)) {
            switch (updateKey) {
                case 'attributes':
                case 'primary':
                    for (const [innerUpdateKey, innerUpdateValue] of Object.entries(update[updateKey])) {
                        renderValue = this.parseAttributes(innerUpdateKey, innerUpdateValue, asyncData);
                        renderArr.push(this.showUpdateText(action, innerUpdateKey, innerUpdateValue, false, renderValue));
                    }
                    break;
                default:
                    let hideTo = false;
                    if (updateKey === 'image') hideTo = true;
                    renderValue = this.parseAttributes(updateKey, updateValue);
                    renderArr.push(this.showUpdateText(action, updateKey, updateValue, hideTo, renderValue));
                    break;
            }
        }

        return (
            <MultiInfoWrapper>
                {renderArr.map((component, index) => <ListText key={index}>{component}</ListText>)}
            </MultiInfoWrapper>
        );
    }

    render() {
        const { data, asyncData, asyncDataEntity, asyncDataRelDef, asyncDataClasses, type } = this.props;
        const { hasMultipleUpdates } = this.state;
        const { user, date } = data || {};
        const info = this.buildChangelogInfo(data, asyncData, asyncDataEntity, type, asyncDataClasses, asyncDataRelDef, hasMultipleUpdates);

        return (
            <CardContainer>
                {hasMultipleUpdates ? (
                    <ChangelogMultipleItem user={user} date={date} info={info} handleResize={this.handleResize} />
                ) : (
                    <ChangelogSingleItem user={user} date={date} info={info} />
                )}
            </CardContainer>
        );
    }
}

ChangelogItem.propTypes = {
    data: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    asyncData: PropTypes.object.isRequired,
    asyncDataEntity: PropTypes.object.isRequired,
    asyncDataRelDef: PropTypes.object.isRequired,
    asyncDataClasses: PropTypes.array,
    index: PropTypes.number.isRequired,
};

const selectDetails = (state, { data }) => {
    const action = Object.keys(data.change)[0];
    const isAddRemoveAction = ['add', 'remove'].includes(action);
    const assignee = get(data.change[action], 'primary.assignee');
    let avatar;

    if (isAddRemoveAction) {
        const { accessor, item: { id, type: itemType }} = data.change[action];
        const type = itemType || accessor;
        avatar = getAvatar(state, normalizeType(type), id) || {};
        return checkEmptyAvatar(avatar, id, type);
    } else if (assignee) {
        avatar = getAvatar(state, 'user', assignee) || {};
        return checkEmptyAvatar(avatar, assignee, 'user');
    }

    return {};
};

const selectEntityDetails = (state, { data }) => {
    const action = Object.keys(data.change)[0];
    const isAddRemoveAction = ['add', 'remove'].includes(action);
    let avatar;

    if (isAddRemoveAction) {
        const accessor = get(data.change[action], 'accessor');
        if (accessor === 'relations') {
            const { id, type } = get(data.change[action], 'item.relatedEntity', {});
            avatar = getAvatar(state, normalizeType(type), id) || {};
            return checkEmptyAvatar(avatar, id, type);
        }
    }

    return {};
};

const selectRelationDefinitionDetails = (state, { data }) => {
    const action = Object.keys(data.change)[0];
    const relationDefinitionId = get(data.change[action], 'item.relation.relationDefinition', null);
    let avatar;

    if (relationDefinitionId) {
        avatar = getAvatar(state, 'relationDefinition', relationDefinitionId) || {};
        return checkEmptyAvatar(avatar, relationDefinitionId, 'relationDefinition');
    }

    return {};
};

const selectClasses = (state, { data }) => {
    const action = Object.keys(data.change)[0];
    const classes = data.change[action]?.classes || [];
    
    if (!isEmptyArray(classes)) {
        const classAvatars = classes.map((clsId) => {
            const classesAvatar = getAvatar(state, 'class', clsId) || {};
            return checkEmptyAvatar(classesAvatar, clsId, 'class');
        });
        return classAvatars;
    }
    
    return [];
};

export default connect(
    (state, props) => ({
        asyncData: selectDetails(state, props),
        asyncDataEntity: selectEntityDetails(state, props),
        asyncDataRelDef: selectRelationDefinitionDetails(state, props),
        asyncDataClasses: selectClasses(state, props),
        isSidebarResizing: state.sidebar.isResizing
    }),
    { loadAvatar, loadRelDefAvatar }
)(ChangelogItem);