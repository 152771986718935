import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Autocomplete } from '@mic3/platform-ui';

import { bind, debounce } from 'app/utils/decorators/decoratorUtils';
import { loadVRelationDefinitionAutocomplete } from 'store/actions/entities/relationshipsActions';
import { isEmpty, shallowEquals } from 'app/utils/utils';

/**
 * Select one or more groups using lazy loading.
 */
class EntityRelationDefinitionTypeahead extends PureComponent<Object, Object> {
    static propTypes = {
        ...Autocomplete.propTypes,
        value: PropTypes.any,
        loadOptions: PropTypes.func.isRequired,
        fromType: PropTypes.string.isRequired,
        toType: PropTypes.string.isRequired,
        filterBy: PropTypes.arrayOf(PropTypes.Object),
        excludeBy: PropTypes.arrayOf(PropTypes.Object),
        orderBy: PropTypes.arrayOf(PropTypes.Object),
        isLoading: PropTypes.bool,
        options: PropTypes.arrayOf(PropTypes.object)
    };

    state = { isLoading: false, options: [], lastRequest: 0 };

    componentDidUpdate(prevProps) {
        if (!shallowEquals(this.props, prevProps, ['fromType', 'toType', 'filterBy', 'excludeBy', 'orderBy'])) {
            this.setState({ isLoading: false, options: [], lastRequest: 0 });
        }
    }

    @bind
    getRelationDefinitions(data){
        const { toType, fromType } = this.props;
        if (isEmpty(data)) {
            return [];
        }
        if (toType !== fromType) {
            return data;
        }
        const reverse = data.map(rel => ({
            ...rel, description: rel.relatedDescription, relatedDescription: rel.description, type: rel.relatedType, relatedType: rel.type, reverse: true }));
        return [...data, ...reverse];
    }

    @bind
    @debounce()
    suggest(event: Object) {
        const requestId = Date.now();
        this.setState({ isLoading: true, lastRequest: requestId }, () => {
            const { value, loadOptions, fromType, filterBy, excludeBy, orderBy } = this.props;
            const filters = filterBy ? [...filterBy] : [];
            filters.push(
                { field: 'type', op: '=', value: fromType }
            );
            const query = event.target.value;
            if (query) {
                filters.push({
                    or: [
                        { field: 'description', op: 'contains', value: query },
                        { field: 'relatedDescription', op: 'contains', value: query }
                    ]
                });
            }
            if (value) {
                const idsToExclude = (Array.isArray(value) ? value : [value]).map(({ id }) => id);
                filters.push({ field: 'id', op: 'not in', value: idsToExclude });
            }
            loadOptions({
                startIndex: 0,
                stopIndex: 50,
                filterBy: filters,
                excludeBy: excludeBy,
                orderBy: orderBy
            }).then((data) => {
                // if this is not the last request discard the result
                if (this.state.lastRequest === requestId) {
                    this.setState({ isLoading: false, options: this.getRelationDefinitions(data) });
                }
            });
        });
    }

    @bind
    optionTemplate(data: any) {
        const { id, description, relatedDescription, type, relatedType } = data;
        const { fromType } = this.props;
        let label = '', title = '';
        if (fromType === type ) {
            label = `${description || 'Description not available'} (${id ? id.slice(0, 8) : 'Id not available'})`;
            title = description;

        } else if (fromType === relatedType ) { // In case of reverse relationship shows the relatedDescription instead of description //&& toType === type
            label = `${relatedDescription || 'Description not available'} (${id ? id.slice(0, 8) : 'Id not available'})`;
            title = relatedDescription;
        }
        return {
            option: <span title={title}>{label}</span>,
            label
        };

    }

    render() {
        const { loadOptions, fromType, toType, filterBy, excludeBy, orderBy, ...typeaheadProps } = this.props; // eslint-disable-line no-unused-vars
        const { isLoading, options } = this.state;
        return <Autocomplete {...typeaheadProps} optionTemplate={this.optionTemplate} suggest={this.suggest} options={options} isLoading={isLoading} />;
    }
}

export default connect(null, { loadOptions: loadVRelationDefinitionAutocomplete })(EntityRelationDefinitionTypeahead);
