/* @flow */

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Tooltip,
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemText,
    ConfirmationModal,
    Button,
    Typography
} from '@mic3/platform-ui';

import { bind, memoize, debounce } from 'app/utils/decorators/decoratorUtils';
import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import Icon from 'app/components/atoms/Icon/Icon';
import Loader from 'app/components/atoms/Loader/Loader';
import styled from 'styled-components';
import { startProcess, startProcessByMessage, executeScript } from 'store/actions/abox/processActions';
import history from 'store/History';
import { redirectTypes } from 'app/config/typesConfig';
import { showToastr } from 'store/actions/app/appActions';

const IconStyled = styled(Icon)`
    &:before {
        color: ${({theme})=> theme.material.colors.text.secondary};
    }
    color: #9fa3ab;
`;
const TypographyStyled = styled(Typography)`
    overflow: auto;
    color: #9fa3ab;
    max-height: 9rem;
    margin-bottom: 0.6rem !important;
`;
/**
 * Renders the button and the dialog to start one or more processes related to this event.
 */
class EventStartProcess extends Component<Object, Object> {
    static propTypes = {
        /* props */
        id: PropTypes.string.isRequired,
        processDefinitions: PropTypes.arrayOf(PropTypes.object).isRequired,
        color: PropTypes.string,
    };

    state: Object = {
        visible: false,
        errorDialogvisible: false,
        dialogData: null,
    };

    /**
     * Shows the dialog
     */
    @bind
    showDialog(event: ?Object) {
        if (event) {
            event.preventDefault();
        }
        this.setState({ visible: true });
    }

    @bind
    closeDialog() {
        this.setState({ visible: false });
    }

    @bind
    showErrorDialog(event: ?Object) {
        if (event) {
            event.preventDefault();
        }
        this.setState({ errorDialogVisible: true });
    }

    @bind
    closeErrorDialog() {
        this.setState({ errorDialogVisible: false });
    }

    @bind
    navigate() {
        const { dialogData } = this.state;
        const { title, id, process } = dialogData || {};
        if (!title || dialogData instanceof Error) {
            return;
        }
        if (title === 'Start Process' && process?.id) {
            return history.push(`/abox/process/${process.id}`);
        }
        if (title === 'Start Process By Message' && id) {
            return history.push(`/abox/process/${id}`);
        }
    }

    @bind
    handleScriptResponse(response) {
        if (response instanceof Error) return;
        this.closeDialog();
        const { startedProcess, type, id } = response || {};
        if (startedProcess) {
            return history.push(`/abox/process/${startedProcess}`);
        }
        if (type && id) {
            return history.push(`/${redirectTypes[type]}/${id}`);
        }
        if (typeof response === 'string') {
            return this.props.showToastr({ severity: 'success', detail: response });
        }
    }

    @bind
    handleResponse(title, resp){
        this.closeDialog();
        if (resp instanceof Error) {
            return this.showErrorDialog();
        }
        this.setState({
            dialogData: {
                title, 
                ...(resp || {})
            },
        });
    }

    @bind
    @debounce(700)
    startProcessAgainstEvent(definition) {
        const { data } = this.props;
        const { id: eventId, time: eventTime } = data || {};
        const { processDefinitionId, startMessage, scriptId, businessKey, version } = definition || {};
        const variables = { eventId, eventTime, event: JSON.stringify(data) };
        if (processDefinitionId) {
            this.props.startProcess(processDefinitionId, variables).then((r) => this.handleResponse('Start Process', r));
        } else if (startMessage) {
            this.props.startProcessByMessage(startMessage, variables, businessKey).then((r) => this.handleResponse('Start Process By Message', r));
        } else if (scriptId) {
            this.props.executeScript(scriptId, version, data).then(this.handleScriptResponse);
        }
    }

    @bind
    @memoize()
    buildDefinitions(processDefinitions) {
        return processDefinitions?.map((def) => ({
            type: 'button',
            properties: {
                label: def.label,
                variant: 'contained',
                onClick: () => this.startProcessAgainstEvent(def),
            },
        }));
    }

    @bind
    @memoize()
    renderModalActions(){
        return <Button variant="text" onClick={this.closeDialog}>Close</Button>;
    }

    render() {
        const { visible, dialogData, errorDialogVisible } = this.state;
        const {
            processDefinitions,
            color,
            disableHoverEffect,
            list,
            errorOptions,
            isLoading,
            isLoadingMessage,
            isLoadingExecuteScript,
            started,
            data,
        } = this.props;
        if (!processDefinitions?.length) {
            return null;
        }
        return (
            <Fragment>
                {list ? (
                    <ListItem button onClick={this.showDialog}>
                        <ListItemIcon>
                            <IconStyled name='processes' type='af' />
                        </ListItemIcon>
                        <ListItemText primary='Start Process' />
                    </ListItem>
                ) : (
                    <Tooltip placement='top' arrow title='Start Process'>
                        <span>
                            {disableHoverEffect ? (
                                <IconStyled name='processes' type='af' onClick={this.showDialog} />
                            ) : (
                                <IconButton iconColor={color} onClick={this.showDialog}>
                                    <IconStyled name='processes' type='af' onClick={this.showDialog} />
                                </IconButton>
                            )}
                        </span>
                    </Tooltip>
                )}
                {visible ? (
                    isLoading || isLoadingMessage || isLoadingExecuteScript ? (
                        <Loader />
                    ) : (
                        <ModalDialog showDividerAfterTitle title='Start New Process' withoutClose={true} actions={this.renderModalActions()}>
                            {data?.description && <TypographyStyled>{data?.description}</TypographyStyled>}
                            <FormGenerator components={this.buildDefinitions(processDefinitions)} />
                        </ModalDialog>
                    )
                ) : null}

                <ConfirmationModal
                    header='Process started successfully'
                    message={<> Process <b>{started?.process?.name}</b> started successfully. </>}
                    onClose={() => this.setState({ dialogData: null })}
                    open={!!dialogData}
                    onConfirm={this.navigate}
                    confirmButtonText='Go to process'
                    declineButtonText='Got it'
                />

                <ConfirmationModal
                    header='Error'
                    message={
                        <>
                            {' '}
                            Process could not be started due to error. <br /> {errorOptions?.detail}{' '}
                        </>
                    }
                    open={errorDialogVisible}
                    confirmButtonText='Got it'
                    onClose={this.closeErrorDialog}
                    noDecline={true}
                />
            </Fragment>
        );
    }
}

export default connect(
    (state) => ({
        userProfile: state.user.profile,
        errorOptions: state.app.errorOptions,
        isLoading: state.abox.process.start.isLoading,
        started: state.abox.process.start.data,
        isLoadingMessage: state.abox.process.startMessage.isLoading,
        isLoadingExecuteScript: state.abox.process.startExecuteScript.isLoading,
    }),
    { startProcess, startProcessByMessage, executeScript, showToastr }
)(EventStartProcess);
