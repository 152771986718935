/* @flow */

export const API_ENTRY_POINT = window.localStorage.getItem( 'API_ENTRY_POINT' ) || '';

export const LANDING_URL = '/abox';

export const SUPER_USERS_TEAM_ID = '6cb1a7b6-1ddd-11eb-bc76-4746e64877e8';

export const PUBLIC_TEAM_ID = '5805d348-ccef-11eb-8f6a-1374587aa82b';

export const DIGITAL_TWIN_ID = '4010d152-d9ba-11eb-a1c2-e7f624704bd1';

export const BOARD_RELATION_DEFINITION_ID = '525607f3-35b2-444d-89f1-4cd65e273044';

export const GLOBAL_ACCOUNT_URL = 'https://accounts.affectli.com';

export const SITE_URL = 'https://affectli.com';

export const ROCKET_CHAT_URL = 'https://mi-c3.affectli.com/chat/livechat';

export const MIN_LEFT_SIDEBAR_WIDTH = 467;
export const MIN_RIGHT_SIDEBAR_WIDTH = 398;
export const DEFAULT_LEFT_SIDEBAR_WIDTH = 467;
export const DEFAULT_RIGHT_SIDEBAR_WIDTH = 616;
export const PIPELINE_DEFAULT_LEFT_SIDEBAR_WIDTH = 392;

export const organisationImageUrl = '/media/company-logo';
export const organistationEntityId = '7a283ad4-e825-11ee-b24c-af1990802faf'; // name = admin.site
export const settingsEntityType = 'system_setting';

export const ssoEntityId = '08eae9aa-94fd-11ee-b618-7fb577b0ba73'; // name = admin.sso
export const termsEntityId = '717c6cba-e763-11ee-bb56-d33ed2088384'; // name = admin.terms

// For Timeline task relations
export const successorTaskRelDefId = '3657e33e-e981-11ed-87a1-0242ac120003';
export const predecessorTaskRelDefId = '4e700c94-e981-11ed-87a1-0242ac120003';