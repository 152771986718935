/* @flow */

import React, { useMemo } from 'react';
import { useDrop } from 'react-dnd';

const renderOverlay = (color) => {
    return (
        <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            zIndex: 1,
            opacity: 0.5,
            backgroundColor: color,
        }} />
    );
};

// eslint-disable-next-line no-unused-vars
const RowTarget = ({ Component, x, y, children, onDrop, element, isOverCurrent, index, align, textRising, style, ...rest }: Object) => {
    const [{ canDrop, isOver }, drop] = useDrop({
        accept: 'ELEMENT',
        drop: ({ element: dropedElement }) => onDrop && onDrop(dropedElement, index, element),
        collect: monitor => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });

    const styles = useMemo(() => {
        let extraStyle = {...(style || {})};
        if(textRising) {
            extraStyle = {
                ...extraStyle,
                lineHeight: isOver ? 50 : 'inherit',
                height: isOver ? 50 : 'auto',
            };
        }
        return {
            position: 'relative',
            width: '100%',
            textAlign: align || 'center',
            ...extraStyle,
        };
    }, [align, isOver, style, textRising]);

    const C = useMemo(() => {
        const Div = React.forwardRef((props, ref) => <div {...props} ref={ref} />);
        return Component || Div;
    }, [Component]);

    return (
        <C {...rest} ref={drop} key={index} style={styles}>
            {children}
            {!isOver && canDrop && renderOverlay('rgba(0, 0, 0, .2)') /* the color of the area */}
            {isOver && !canDrop && renderOverlay('red')  /* the color of the row when we can drop */}
            {isOver && canDrop && renderOverlay('#1a6eaf') /* the color of the row when we cannot drop */}
        </C>
    );
};

// eslint-disable-next-line no-unused-vars
export const EmptyRowTarget = ({ x, y, connectDropTarget, index, children, onDrop, element, ...rest }: Object) => {
    const [{ canDrop, isOver, isOverCurrent }, drop] = useDrop({
        accept: 'ELEMENT',
        drop: ({ element: dropedElement }) => onDrop && onDrop(dropedElement, index, element),
        collect: monitor => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
            isOverCurrent: monitor.isOver({ shallow: true }),
        }),
    });

    return (
        <div ref={drop}>
            {children}
            <div {...rest} style={{ position: 'relative', width: '100%', textAlign: 'center' }}>
                {isOverCurrent && canDrop ? <div style={{ height: '60px', width: '100%' }} /> : <div style={{ height: '20px', width: '100%' }} />}
                {!isOver && canDrop && renderOverlay('rgba(0, 0, 0, .2)') /* the color of the area */}
                {isOver && canDrop && renderOverlay('#1a6eaf') /* the color of the row when we cannot drop */}
            </div>
        </div>
    );
};

export default RowTarget;
