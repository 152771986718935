/* @flow */

import { gql } from '@apollo/client';

export default gql`
    mutation updateLicenseMutation($license: String!) {
        licenseUpdate(license: $license) {
            version
            domains
            expiryDate
            attributes
        }
    }
`;
