import React from 'react';
import { Snackbar, MdiIcon } from '@mic3/platform-ui';
import { useToggle } from 'app/utils/hook/hooks';
import { ThemeProvider as MuiThemeProvider  } from '@material-ui/core/styles';
import { muiTheme } from 'app/themes/materialUi';
import styled from 'styled-components';
import theme from 'app/themes/theme.default';

const StyledSnackBar = styled(Snackbar)`
    .MuiSnackbarContent-root {
        background-color:  ${theme.material.colors.background.default} ;
        color: ${theme.material.colors.text.primary}  ;
    }
`;

const MapSnackbar = ({ message }) => {
    const [open, toggle] = useToggle(true);
    return(
        <MuiThemeProvider theme={muiTheme}>
            <StyledSnackBar
                open={open}
                message={message}
                action={<MdiIcon name="close" onClick={toggle} />}
                autoHideDuration={3000}
                onClose={toggle}
            />
        </MuiThemeProvider>
    );
};

export default MapSnackbar;
