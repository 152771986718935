/* @flow */
import React from 'react';

import {
    buildLeftPanel as buildLeftPanelAction,
    closeLeftPanel as closeLeftPanelAction
} from 'store/actions/leftPanel/leftPanelActions';
import BoardsLeftPanel from 'app/containers/LeftPanels/BoardsLeftPanel';

export const openBoardsLeftPanel = ({ title, id, type, view, entityType, betaBoards }) => {
    return buildLeftPanelAction({
        title,
        content: (
            <BoardsLeftPanel
                title={title}
                id={id}
                entityType={entityType}
                view={view}
                type={type}
                betaBoards={betaBoards}
            />
        ),
        isOpen: true,
    });
};

export const closeBoardsLeftPanel = closeLeftPanelAction;
