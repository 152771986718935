
import styled from 'styled-components';

import 'bpmn-js-color-picker/colors/color-picker.css';
import 'bpmn-js/dist/assets/diagram-js.css';
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css';
import 'diagram-js-minimap/assets/diagram-js-minimap.css';
import theme from 'app/themes/theme.default';

const DiagramWrapper = styled.div`
width: 100%;
height: 100%;

& > div {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

& a:link {
  text-decoration: none;
}



& .djs-drag-active.hover {
    background-color: ${({ theme }) => theme.material.colors.background.paper} !important;
}
& .djs-palette {
    border: solid 1px ${({ theme }) => theme.material.colors.background.navigation};
    background-color: ${({ theme }) => theme.material.colors.background.navigation};
}
& .djs-context-pad .entry {
  background-color: ${({ theme }) => theme.material.colors.background.navigation};
  box-shadow: 0 0 2px 1px rgb(69 135 183);
}
& .djs-direct-editing-content, & .djs-popup {
  color: ${({ theme }) => theme.material.colors.text.popup};
}
& .djs-minimap {
  bottom: 45px;
  top: inherit;
}
& .djs-minimap:not(.open) {
  width: 79px;
}
& .djs-minimap .toggle {
  background: ${({ theme }) => theme.material.colors.background.default};
}
& .djs-minimap .map {
  background-color: ${({ theme }) => theme.material.colors.background.default};
}
& .djs-minimap:not(.open) .toggle {
    width: 77px;
    height: 33px;
    line-height: 33px;
    cursor: pointer;
}
& .djs-minimap .toggle {
    cursor: pointer;
}
& .djs-minimap:not(.open) .toggle:before {
    content: 'Overview';
}
& .djs-palette .entry:before {
  color: ${({theme})=> theme.material.colors.text.primary};
}
& .djs-palette .highlighted-entry {
    background: ${({ theme }) => theme.material.colors.background.default}30;
}
& .canvas .bpmn-icon-intermediate-event-throw-escalation,
& .canvas .bpmn-icon-intermediate-event-catch-condition,
& .canvas .bpmn-icon-intermediate-event-catch-link,
& .canvas .bpmn-icon-intermediate-event-throw-link,
& .canvas .bpmn-icon-start-event-condition,
& .canvas .bpmn-icon-end-event-escalation,
& .canvas .bpmn-icon-gateway-complex {
  display: none;
}

.djs-popup {
  border: 0;
}
.djs-context-pad .entry img {
  filter: invert(0.7);
}
.djs-popup.color-picker .djs-popup-body {
  background: ${({ theme }) => theme.material.colors.background.navigation};
}
`;

export const getDiagramStyles = () => ({
    bpmnRenderer: {
        defaultFillColor: theme.material.colors.background.paper,
        defaultStrokeColor: theme.material.colors.text.primary,
    },
    textRenderer: {
        defaultStyle: {
            fontWeight: 'bold',
        },
    }
});


export default DiagramWrapper;
