import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Controlled as CodeMirror } from 'react-codemirror2';
import styled from 'styled-components';

import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { get, set } from 'app/utils/lo/lo';

import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/lucario.css';
import 'codemirror/theme/idea.css'; 

require('codemirror/mode/sql/sql');

const selectionColor = '#066AB1';
const Editor = styled(CodeMirror)`
font-size: 14px;
height: ${({ plainTextEditor }) => plainTextEditor ? 'calc(100% - 39px)' : '100%'};
& .CodeMirror-sizer {
  min-height: 200px !important;
}
& .CodeMirror {
  height: 100% !important;
}
& .cm-s-lucario.CodeMirror, & .cm-s-lucario .CodeMirror-gutters {
  background-color: ${({ theme, backgroundcolor }) => backgroundcolor || theme.material.colors.background.paper} !important;
}
& .cm-s-lucario.CodeMirror, .cm-s-lucario .CodeMirror-gutters {
    color: ${({theme})=> theme.material.colors.text.primary} !important;
    -webkit-font-smoothing: auto !important;
}
& .cm-s-lucario .CodeMirror-selected {
  background: ${selectionColor};
}
& .cm-s-lucario .CodeMirror-line::selection,
& .cm-s-lucario .CodeMirror-line > span::selection,
& .cm-s-lucario .CodeMirror-line > span > span::selection {
  background: ${selectionColor};
}
& .cm-s-lucario .CodeMirror-line::-moz-selection,
& .cm-s-lucario .CodeMirror-line > span::-moz-selection,
& .cm-s-lucario .CodeMirror-line > span > span::-moz-selection {
  background: ${selectionColor};
}
& .cm-s-lucario .CodeMirror-activeline-background {
  background: ${selectionColor};
}

${({ rows }) => rows ? `& .CodeMirror { height: ${rows * 20}px }` : ''}
`;

class CodeEditor extends PureComponent {

    static propTypes = {
        options: PropTypes.object,
    }

    constructor(props) {
        super(props);
        this.state = { value: props.value || '' };
        this.editorRef = React.createRef();
    }

    componentDidUpdate(prevProps) {
        const { value } = this.props;
        if (prevProps.value !== value && value !== this.state.value) {
            this.setState({ value });
        }
    }

    @bind
    onChange(editor, data, value) {
        const { name } = this.props;
        this.props.onChange && this.props.onChange({ target: { name, value } });
    }

    editor() {
        return this.editorRef.current.editor;
    }

    @bind
    getGlobalTheme(){
        const theme = JSON.parse(localStorage.getItem('theme')) || 'darkTheme';
        return theme;
    }

    @bind
    @memoize()
    addDefaults(options) {
        let withDefaults = options;
        const theme = get(withDefaults, 'theme');
        const globalTheme = this.getGlobalTheme();
        if (theme === undefined || globalTheme === 'darkTheme') {
            withDefaults = set(withDefaults, 'theme', 'lucario');
        }
        if ( globalTheme === 'lightTheme') {
            withDefaults = set(withDefaults, 'theme', 'idea');
        }
        withDefaults = set(withDefaults, 'lineWrapping', true);
        return withDefaults;
    }

    render() {
        const { options } = this.props;
        return (
            <Editor
                {...this.props}
                ref={this.editorRef}
                value={this.state.value}
                onBeforeChange={(editor, data, value) => {
                    this.setState({ value });
                }}
                onChange={this.onChange}
                options={this.addDefaults(options)}
            />
        );
    }

}

export default CodeEditor;
