/* @flow */

/**
 * Re-map column state to add loading and name variables
 * @param columns the columns to be re-mapped
 */
const mapColumnState = (columns: Object) => {
    const mappedColumns = {};
    columns.map((column) => {
        mappedColumns[column.id] = {
            name: column.name,
            isLoading: false,
        };
        return column;
    });
    return mappedColumns;
};

/**
 * Get the relationship record which matches the current columnId
 * @param relationships the relationship array of the task
 * @param columnId the current column of the task
 */
const getRelationshipIdFromTask = (relations: Object[], columnId: string) => {
    return relations.find((rel) => {
        return rel.relatedEntity && rel.relatedEntity.id && String(rel.relatedEntity.id) === columnId;
    });
};


export {
    mapColumnState,
    getRelationshipIdFromTask,
};
