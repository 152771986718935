/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, CircularProgress } from '@mic3/platform-ui';

import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import { bind } from 'app/utils/decorators/decoratorUtils';

class RenameMapModal extends PureComponent<Object, Object> {
    static propTypes = {
        updateMapData: PropTypes.func.isRequired
    };

    formRef = React.createRef();


    constructor(props: Object) {
        super(props);
        this.state = {
            isUpdateClicked: false
        };
    }

    @bind
    onFormSubmit(event: Event) {
        event.preventDefault();
        this.setState({ isUpdateClicked: true });
        this.formRef.current.isValidForm().then(({ data, errors }) => {
            if (!errors) {
                this.props.updateMapData({ ...data, name: data.name.trim() });
            }
        });
        this.setState({ isUpdateClicked: false });
    }

    render(): Object {
        const { isLoading, onClose, mapName } = this.props;
        const { isUpdateClicked } = this.state;
        const title = `Rename Map`;

        const components = [
            {
                type: 'text',
                properties: { 
                    label: `Enter Map Name`, 
                    name: 'name',
                    defaultValue: mapName
                },
                constraints: { required: true },
            }
        ];

        return (
            <>
                <ModalDialog
                    title={title}
                    onClose={onClose}
                    actions={
                        isLoading ? (
                            <CircularProgress size={24} color="primary" />
                        ) : (
                            <Button variant="text" onClick={this.onFormSubmit} disabled={isUpdateClicked}>
                                Update
                            </Button>
                        )
                    }
                >
                    <FormGenerator components={components} ref={this.formRef} />
                </ModalDialog>
            </>
        );
    }
}

export default connect(state => ({
    isLoading: state.entities.entities.isLoading,
}))(RenameMapModal);
