import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { bind } from 'app/utils/decorators/decoratorUtils';

import ImageViewer from 'app/containers/Common/ImageViewer/ImageViewer';

class ChatFilePreview extends PureComponent<Object, Object> {
    static propTypes = {
        fileId: PropTypes.string,
        images: PropTypes.array,
        onClose: PropTypes.func.isRequired
    };

    state = {
        activeIndex: 0,
        images: [],
        visible: false
    }

    componentDidMount() {
        const { images, fileId } = this.props;
        
        const activeIndex = (images || []).findIndex(({ id }) => id === fileId);
        this.setState({ activeIndex, images, visible: true });
    }

    @bind
    handleClosePreview() {
        this.props.onClose();
    }

    render() {
        const { images, activeIndex, visible } = this.state;

        return (
            <ImageViewer
                downloadable
                visible={visible}
                images={images}
                activeIndex={activeIndex}
                onClose={this.handleClosePreview}
            />
        );
    }
}

export default ChatFilePreview;