import React, { memo } from 'react';
import styled from 'styled-components';
import { Chip } from '@mic3/platform-ui';

const ChipBase = styled(Chip)`
    && {
        background-color: ${({ theme }) => theme.material.colors.background.activeElement};
        font-weight: 500;
        text-transform: uppercase;
        border-radius: 2px;
        font-size: 9px;
        height: 20px;
        margin-left: 8px;
        text-transform: uppercase;
        border-radius: 6px 0 6px 0;
        color: ${({ theme }) => theme.material.colors.text.button};
    }
`;

const ChipClosed = ({
    ...otherProps
}) => {
    return (
        <ChipBase {...otherProps} label={ otherProps.label || 'Closed'} size="small" />
    );
};

export default memo(ChipClosed);