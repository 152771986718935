/* @flow */

import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { TextField } from '@mic3/platform-ui';
import { SUPER_USERS_TEAM_ID } from 'app/config/config';
import styled from 'styled-components';

import { loadTeam, updateTeam, loadRelatedWorkspaces } from 'store/actions/admin/teamsActions';
import { openTeamSidebar } from 'store/actions/entities/teamSidebarActions';

import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { checkIfUserIsMember } from 'app/utils/chat/chatUtils';
import { typeTitlesMultiple, redirectTypes } from 'app/config/typesConfig';

import TeamAboutTab from 'app/containers/Admin/Teams/Details/Tabs/TeamAboutTab';
import EditableText from 'app/components/molecules/EditableText/EditableText';
import Loader from 'app/components/atoms/Loader/Loader';
import PageTemplate from 'app/components/templates/PageTemplate';
import PageNotAllowed from 'app/containers/ErrorPages/PageNotAllowed';
import DotMenu from 'app/components/molecules/DotMenu/DotMenu';
import Breadcrumbs from 'app/components/organisms/Breadcrumbs/Breadcrumbs';
import AddTeam from 'app/components/Admin/Modals/AddTeam';
import ChipDisabled from 'app/components/atoms/ChipDisabled/ChipDisabled';

const StyledDisabledChip = styled(ChipDisabled)`
    margin-left: 10px;
`;


/**
 * Main container to display the details view of the Teams & Permissions
 */
class TeamDetails extends PureComponent<Object, Object> {
    static propTypes = {
        id: PropTypes.string.isRequired,
        team: PropTypes.object,
        loadTeam: PropTypes.func.isRequired,
        loadRelatedWorkspaces: PropTypes.func.isRequired,
        openTeamSidebar: PropTypes.func.isRequired,
        lastActionType: PropTypes.string,
        lastActionError: PropTypes.bool,
        isLoading: PropTypes.bool,
        isLoadingUpdate: PropTypes.bool
    };

    state = {
        isModalOpen: false
    };

    /**
     * @override
     */
    componentDidMount() {
        const id = this.props.id;
        this.props.loadTeam(id);
        this.props.loadRelatedWorkspaces(id);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.id !== this.props.id) {
            this.props.loadTeam(this.props.id);
            this.props.loadRelatedWorkspaces(this.props.id);
        }
    }

    @bind
    toggleModal() {
        this.setState({ isModalOpen: !this.state.isModalOpen});
    }

    @bind
    onDotMenuClick(title) {
        const { id, openTeamSidebar } = this.props;
        const settings = { title, id };

        switch (title) {
            case 'Rename':
                this.toggleModal();
                return;
            case 'Enable':
                this.enableDisableTeam(true);
                return;
            case 'Disable':
                this.enableDisableTeam(false);
                return;
            default:
                break;
        }

        openTeamSidebar(settings);
    }

    @bind
    @memoize()
    dotMenu(sidebarTitle: string, pathname: string = '', isActive, isTeamMember) {
        return (
            <DotMenu
                key={13}
                onItemClick={this.onDotMenuClick}
                items={[
                    { name: 'Rename', icon: 'pencil' },
                    !isActive && { name: 'Enable', icon: 'check'},
                    isActive && { name: 'Disable', icon: 'close'},
                    !pathname.endsWith('/about') && sidebarTitle !== 'About' && { name: 'About', icon: 'information-outline' },
                    sidebarTitle !== 'A-Live' && isTeamMember && { name: 'A-Live', icon: 'messenger', iconType: 'af' },
                    sidebarTitle !== 'History' && { name: 'History', icon: 'history' },
                ].filter(Boolean)}
            />
        );
    }

    @bind
    enableDisableTeam(active) {
        const { id } = this.props.team;
        this.props.updateTeam({ id, active });
    }

    @bind
    updateTeamTitle(event: Object) {
        const { value } = event.target || {};
        const { id } = this.props.team;
        this.props.updateTeam({ id, name: value });
    }

    @bind
    @memoize()
    buildBreadcrumbs(name, active) {
        return <Breadcrumbs list={[
            { link: `/${redirectTypes['team']}`, title: typeTitlesMultiple['team'] },
            { title: (
                <>
                    {name}{name && <StyledDisabledChip active={active} />}
                </>
            ) }
        ]} withGoBack />;
    }

    /**
     * @override
     */
    render() {
        const { team, isLoading, isLoadingUpdate, id, sidebarTitle, relatedWorkspaces, userProfile } = this.props;
        if (!isLoading && !team) {
            return <PageNotAllowed permissionError={true} title={`Team. (ID:${id})`} />;
        }
        const { pathname } = this.props.location;
        const { name, active } = team || {};
        const isTeamMember = checkIfUserIsMember(userProfile?.id, team);
        return (
            <Fragment>
                {(isLoading || isLoadingUpdate) && <Loader absolute backdrop />}
                {team && (
                    <PageTemplate
                        title={
                            id === SUPER_USERS_TEAM_ID ? (
                                name
                            ) : (
                                <EditableText
                                    displayText={name}
                                    name="name"
                                    value={name}
                                    onSave={this.updateTeamTitle}
                                    EditComponent={TextField}
                                    EditComponentProps={{ margin: 'none', clearable: false, variant: 'standard', fullWidth: false }}
                                />
                            )
                        }
                        subTitle={`#${id}`}
                    >
                        <Switch>
                            <Route path={`/teams/:id`} exact component={() => <Redirect to={`/teams/${id}/about`} />} />
                            <Route path={`/teams/:id/about`} exact>
                                <TeamAboutTab
                                    details={team}
                                    relatedWorkspaces={relatedWorkspaces}
                                    sidebarActions={[this.dotMenu(sidebarTitle, pathname, active, isTeamMember)]}
                                    breadcrumbLine={this.buildBreadcrumbs(name, active)}
                                />
                            </Route>
                            <Route path={'/teams/:id/history'} render={() => <h3>Work in progress...</h3>} />
                        </Switch>
                    </PageTemplate>
                )}
                {this.state.isModalOpen && <AddTeam onClose={this.toggleModal} title="Rename" data={team} />}
            </Fragment>
        );
    }
}

export default connect(
    (state: Object, ownProps: Object): Object => ({
        relatedWorkspaces: state.admin.teams.relatedWorkspaces.records,
        team: state.admin.teams.details.data,
        isLoading: state.admin.teams.details.isLoading,
        isLoadingUpdate: state.admin.teams.update.isLoading,
        id: ownProps.match.params.id,
        online: state.app.online,
        sidebarTitle: state.sidebar.title,
        userProfile: state.user.profile,
    }),
    { loadTeam, updateTeam, openTeamSidebar, loadRelatedWorkspaces }
)(TeamDetails);
