import React from 'react';
import { Grid, InputAdornment, MdiIcon } from '@mic3/platform-ui';

import styled from 'styled-components';

import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';

const OverlayGrid = styled(Grid)`
    position: absolute;
    z-index: 1;
    top: 70px;
    bottom: 0;
    left: 0;
    right: 0;
`;
const WrapperGrid = styled(Grid)`
    position: relative;
`;

export const statusIconsName = {
    running: { name: 'play', color: 'rgba(255, 179, 0, 1)' },
    scheduled: { name: 'clock-outline', color: 'rgba(255, 179, 0, 1)' },
    waiting: { name: 'progress-clock', color: 'rgba(255, 115, 34, 1)' },
    failed: { name: 'alert-circle-outline', color: 'rgba(235, 35, 48, 1)' },
    completed: { name: 'check-all', color: 'rgba(77, 182, 172, 1)' },
    script: { name: 'script', type: 'af', color: 'rgba(255, 179, 0, 1)' }
};

const JobStatusSection = ({ details }) => {
    const { type } = details || {};
    return type === 'backgroundjob' && (
        <WrapperGrid>
            <OverlayGrid />
            <FormGenerator
                root={false}
                data={details}
                components={[{
                    properties: { header: 'Job Status', expanded: true, collapsible: true },
                    type: 'panel',
                    children: [
                        {
                            type: 'text',
                            properties: {
                                name: 'primary.status',
                                label: 'Job status',
                                clearable: false,
                                InputProps: {
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <MdiIcon
                                                name={statusIconsName[details?.primary?.status]?.name}
                                                type={statusIconsName[details?.primary?.status]?.type}
                                                color={statusIconsName[details?.primary?.status]?.color}
                                            />
                                        </InputAdornment>
                                    ),
                                }
                            // disabled: true,
                            },
                        },
                        {
                            type: 'text',
                            properties: {
                                name: 'primary.type',
                                label: 'Job type',
                                clearable: false,
                            // disabled: true,
                            },
                        },
                        {
                            type: 'dateTime',
                            properties: {
                                name: 'createdDate',
                                label: 'Created',
                                clearable: false,
                            // disabled: true,
                            },
                        },
                        {
                            type: 'dateTime',
                            properties: {
                                name: 'primary.startDate',
                                label: 'Started',
                                clearable: false,
                            // disabled: true,
                            },
                        },
                        {
                            type: 'dateTime',
                            properties: {
                                name: 'primary.endDate',
                                label: 'Ended',
                                clearable: false,
                            // disabled: true,
                            },
                        },
                    ]
                }]}
            />
        </WrapperGrid>
    );
};

export default JobStatusSection;
