/* @flow */
import { combineReducers } from 'redux';

import { dataTableReducer, loadDataReducer, keepDataAtStart } from 'app/utils/redux/reducer-utils';
import {
    CREATE_WORKSPACE_STARTED, CREATE_WORKSPACE,
    LOAD_WORKSPACES_STARTED, LOAD_WORKSPACES,
    LOAD_WORKSPACE_STARTED, LOAD_WORKSPACE,
    LOAD_RESOURCE_LIST_STARTED, LOAD_RESOURCE_LIST,
    LOAD_WORKSPACE_HISTORY, LOAD_WORKSPACE_HISTORY_STARTED
} from 'store/actions/admin/workspacesActions';

import avatar from './avatar/workspacesAvatarReducer';

export default combineReducers<Object, Object>({
    resourceList: dataTableReducer(LOAD_RESOURCE_LIST_STARTED, LOAD_RESOURCE_LIST, keepDataAtStart),
    list: dataTableReducer(LOAD_WORKSPACES_STARTED, LOAD_WORKSPACES, keepDataAtStart),
    details: loadDataReducer(LOAD_WORKSPACE_STARTED, LOAD_WORKSPACE),
    create: loadDataReducer(CREATE_WORKSPACE_STARTED, CREATE_WORKSPACE),
    history: dataTableReducer(LOAD_WORKSPACE_HISTORY_STARTED, LOAD_WORKSPACE_HISTORY, keepDataAtStart),
    avatar,
});
