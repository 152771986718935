/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AliceCarousel from 'react-alice-carousel';
import { isMobile } from 'react-device-detect';

import Arrow from './Arrow';

const CarouselWrapperStyled = styled.div`
    position: relative;
    width: 100%;
    padding: 0 35px;
    & .alice-carousel__dots {
      margin: 5px 5px 16px 5px;
    }
    & .alice-carousel__dots-item{
        width: 10px;
        height: 10px;
        background-color: ${({theme})=> theme.material.colors.text.caption};
        &:hover{
            background-color: ${({theme})=> theme.material.colors.text.primary};
        }
    }
    & .alice-carousel__dots-item.__active{
      background-color: ${({theme})=> theme.material.colors.text.primary};
    }
`;

/**
 *
 * @example AliceCarousel
 */
class Carousel extends PureComponent<Object, Object> {

    static propTypes = {
        items: PropTypes.array.isRequired,
        ...AliceCarousel.PropTypes,
    }

    static defaultProps = {
        dotsDisabled: true,
        disableButtonsControls: false,
    }

    state = {
        carouselKey: 1,
    }

    componentDidMount() {
        setInterval(() => {
            if(this.wrapperRef.current) {
                const rect = this.wrapperRef.current.getBoundingClientRect();
                if(rect.width !== this.oldWrapperWidth) {
                    this.oldWrapperWidth = rect.width;
                    this.setState(state => ({ carouselKey: state.carouselKey + 1 }));
                }
            }
        }, 1500);
    }

    oldWrapperWidth = 0;
    carouselRef: Object = React.createRef();
    wrapperRef: Object = React.createRef();


    slideNext = () => this.carouselRef.current && this.carouselRef.current.slideNext();
    slidePrev = () => this.carouselRef.current && this.carouselRef.current.slidePrev();

    render() {
        const { style, className, dotsDisabled, disableButtonsControls, ...allProps  } = this.props;
        return (
            <CarouselWrapperStyled style={style} className={className} ref={this.wrapperRef} >
                <AliceCarousel
                    {...allProps}
                    ref={this.carouselRef}
                    dotsDisabled={dotsDisabled}
                    buttonsDisabled
                    key={this.state.carouselKey}
                    keysControlDisabled={isMobile ? false : true}
                />
                {!disableButtonsControls && <Arrow onClick={this.slidePrev} />}
                {!disableButtonsControls && <Arrow onClick={this.slideNext} right />}
            </CarouselWrapperStyled>
        );
    }
}

export default Carousel;
