/* @flow */

import { loadData, mutateData } from 'app/utils/redux/action-utils';

import organisationAutocompleteQuery from 'graphql/entities/organisations/organisationAutocompleteQuery';
import organisationsListRisedQuery from 'graphql/entities/organisations/organisationsListRisedQuery';
import organisationsListShortQuery from 'graphql/entities/organisations/organisationsListShortQuery';
import createOrganisationMutation from 'graphql/entities/organisations/createOrganisationMutation';

export const CREATE_ORGANISATION_STARTED: string = '@@affectli/entities/organisation/CREATE_ORGANISATION_STARTED';
export const CREATE_ORGANISATION: string = '@@affectli/entities/organisation/CREATE_ORGANISATION';

export const LOAD_ORGANISATIONS_STARTED: string = '@@affectli/entities/organisations/LOAD_ORGANISATIONS_STARTED';
export const LOAD_ORGANISATIONS: string = '@@affectli/entities/organisations/LOAD_ORGANISATIONS';

export const LOAD_ORGANISATIONS_LIST_VIEW_STARTED: string = '@@affectli/entities/organisations/LOAD_ORGANISATIONS_LIST_VIEW_STARTED';
export const LOAD_ORGANISATIONS_LIST_VIEW: string = '@@affectli/entities/organisations/LOAD_ORGANISATIONS_LIST_VIEW';

export const LOAD_ORGANISATION_AUTOCOMPLETE_STARTED = '@@affectli/users/LOAD_ORGANISATION_AUTOCOMPLETE_STARTED';
export const LOAD_ORGANISATION_AUTOCOMPLETE = '@@affectli/users/LOAD_ORGANISATION_AUTOCOMPLETE';

/**
 * Loads the suggestions for the organisation autocomplete component.
 */
export const loadOrganisationAutocomplete = loadData(LOAD_ORGANISATION_AUTOCOMPLETE_STARTED, LOAD_ORGANISATION_AUTOCOMPLETE, organisationAutocompleteQuery);

export const createOrganisation = (data: Object) => {
    const record = { ...data };
    record.classes = (record.classes || []).map(({ id }) => id);
    return mutateData(CREATE_ORGANISATION_STARTED, CREATE_ORGANISATION, createOrganisationMutation, 'Organisation created.')({ record });
};

export const loadOrganisationsListView = ({ isShort, options }: Object = { isShort: false }) =>
    loadData(LOAD_ORGANISATIONS_LIST_VIEW_STARTED, LOAD_ORGANISATIONS_LIST_VIEW, isShort ? organisationsListShortQuery : organisationsListRisedQuery)(options);
