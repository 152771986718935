/* @flow */

import { gql } from '@apollo/client';

export default gql`
    query vtrQuery($filterBy: [JSON]) {
        result: classifications(filterBy: $filterBy) {
            uri
        }
    }
`;
