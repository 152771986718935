import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { MdiIcon, Tooltip, MenuItem, Grow } from '@mic3/platform-ui';
import { TimelineToolbarSelect, TimelineToolbarSection } from './style';

const DURATION = 700;

const ADD_FACTOR = 300;

const TimelineToolbar = (props) => {
    const { onChangeRange, onPrevious, onNext, onToday, range, onZoomOut, onZoomIn, onResetZoom, checked } = props;

    return (
        <Fragment>
            <Grow in={checked} appear mountOnEnter unmountOnExit timeout={DURATION}>
                <TimelineToolbarSection>
                    <Grow in timeout={DURATION + ADD_FACTOR}>
                        <TimelineToolbarSelect
                            onChange={onChangeRange}
                            disableUnderline={true}
                            value={range}
                            inputProps={{
                                name: 'range',
                                id: 'range'
                            }}
                        >
                            <MenuItem value={'days'}>DAY</MenuItem>
                            <MenuItem value={'weeks'}>WEEK</MenuItem>
                            <MenuItem value={'months'}>MONTH</MenuItem>
                            <MenuItem value={'years'}>YEAR</MenuItem>
                        </TimelineToolbarSelect>
                    </Grow>
                    <Grow in timeout={DURATION + ADD_FACTOR * 2}>
                        <Tooltip title="Previous">
                            <MdiIcon onClick={onPrevious} name="chevron-left" color="inherit" />
                        </Tooltip>
                    </Grow>
                    <Grow in timeout={DURATION + ADD_FACTOR * 3}>
                        <Tooltip title="Next">
                            <MdiIcon onClick={onNext} name="chevron-right" color="inherit" />
                        </Tooltip>
                    </Grow>
                    <Grow in timeout={DURATION + ADD_FACTOR * 4}>
                        <Tooltip title="Zoom Out">
                            <MdiIcon onClick={onZoomOut} name="magnify-minus" color="inherit" />
                        </Tooltip>
                    </Grow>
                    <Grow in timeout={DURATION + ADD_FACTOR * 5}>
                        <Tooltip title="Zoom In">
                            <MdiIcon onClick={onZoomIn} name="magnify-plus" color="inherit" />
                        </Tooltip>
                    </Grow>
                    <Grow in timeout={DURATION + ADD_FACTOR * 6}>
                        <Tooltip title="Reset Zoom">
                            <MdiIcon onClick={onResetZoom} name="contrast-circle" color="inherit" />
                        </Tooltip>
                    </Grow>
                    <Grow in timeout={DURATION + ADD_FACTOR * 7}>
                        <Tooltip title="Today">
                            <MdiIcon onClick={onToday} name="calendar-range" color="inherit" />
                        </Tooltip>
                    </Grow>
                </TimelineToolbarSection>
            </Grow>
        </Fragment>
    );
};

TimelineToolbar.propTypes = {
    onChangeRange: PropTypes.func.isRequired,
    onPrevious: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
    onToday: PropTypes.func.isRequired,
    onZoomIn: PropTypes.func.isRequired,
    onZoomOut: PropTypes.func.isRequired,
    onResetZoom: PropTypes.func.isRequired,
    range: PropTypes.string,
    totalRecords: PropTypes.number
};

TimelineToolbar.defaultProps = {
    range: 'weeks',
    totalRecords: 0
};

export default TimelineToolbar;
