/* @flow */

import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { IconButton, Badge } from '@mic3/platform-ui';

import DotMenu from 'app/components/molecules/DotMenu/DotMenu';
import Icon from 'app/components/atoms/Icon/Icon';
import Loader from 'app/components/atoms/Loader/Loader';
import PageTemplate from 'app/components/templates/PageTemplate';
import PageNotAllowed from 'app/containers/ErrorPages/PageNotAllowed';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { get } from 'app/utils/lo/lo';
import { openEntitySidebar } from 'store/actions/entities/entitySidebarActions';
import { openTaskSidebar } from 'store/actions/abox/taskSidebarActions';
import { openProcessSidebar } from 'store/actions/abox/processSidebarActions';
import { openRelationSidebar } from 'store/actions/entities/relationSidebarActions';
import EntityPrimaryAbout from 'app/containers/Entities/EntityAbout/EntityPrimaryAbout';
import PrintTemplateLayout from 'app/containers/Entities/PrintTemplates/PrintTemplateLayout';
import { loadPrintTemplateDetails } from 'store/actions/entities/printTemplateActions';
import Breadcrumbs from 'app/components/organisms/Breadcrumbs/Breadcrumbs';
import { isEmpty } from 'app/utils/utils';
import { closeLeftPanel } from 'store/actions/leftPanel/leftPanelActions';
import { getUnreadMessages } from 'app/utils/entity/entityUtils';
import { isVisibleTab, buildDotMenu } from 'app/utils/entity/entityUtils';
import { getPermissions } from 'app/config/rolesConfig';
import { setDocumentTitle } from 'store/actions/app/appActions';

class PrintTemplateDetails extends PureComponent<Object, Object> {
    static propTypes = {
        id: PropTypes.string.isRequired,
        loadPrintTemplateDetails: PropTypes.func.isRequired,
        details: PropTypes.object,
        isLoading: PropTypes.bool
    };

    constructor(props: Object) {
        super(props);
        this.reloadDetails();
    }

    componentDidUpdate(prevProps) {
        const { id, details, setDocumentTitle } = this.props;
        const name = details?.name;
        if(name){
            setDocumentTitle(name);
        }
        if (id !== prevProps.id) {
            this.reloadDetails();
        }
    }

    @bind
    reloadDetails() {
        const { id } = this.props;
        return this.props.loadPrintTemplateDetails({id, type: 'print-template'});
    }

    @bind
    @memoize()
    renderActions(unreadMessages, permissions, details) {
        if (!permissions?.canComment) {
            return null;
        }
        const { primaryClass } = details || {};
        return (isVisibleTab('A-Live', primaryClass)) ? (
            <>
                <IconButton key={66} title="Open messenger" onClick={() => this.onDotMenuClick('A-Live')}>
                    <Badge color="error" badgeContent={unreadMessages} max={99}>
                        <Icon name="messenger" type="af" />
                    </Badge>
                </IconButton>
                <IconButton key={66} title="About" onClick={() => this.onDotMenuClick('About')}>
                    <Icon name="information" />
                </IconButton>
            </>
        ) : null;
    }

    @bind
    onDotMenuClick(title, params) {
        const { id, openEntitySidebar } = this.props;
        const entityType = 'print-template';
        const settings = { title, id, type: entityType, internal: true, params };
        openEntitySidebar(settings);
    }

    @bind
    @memoize()
    buildDotMenu(sidebarTitle, details, pathname) {
        return buildDotMenu({ details, title: sidebarTitle, pathname });
    }

    @bind
    @memoize()
    dotMenu(sidebarTitle: string, details: Object, pathname: string = '') {
        return <DotMenu key={13} onItemClick={this.onDotMenuClick} items={this.buildDotMenu(sidebarTitle, details, pathname)} />;
    }

    @bind
    buildBreadcrumbs(name, isPrintReports) {
        return <Breadcrumbs list={[{
            link: isPrintReports ? `/print-reports` : `/print-templates`,
            title: isPrintReports ? 'Reports' : 'Print Template'
        }, { title: name } ]} withGoBack />;
    }

    /**
     * @override
     */
    render() {
        const {
            id, isLoading, details, subscriptions, sidebarTitle, match, location, isPrintReports
        } = this.props;
        const showDetails = details && details?.id === id;
        const { pathname } = this.props.location;
        const permissions = getPermissions(details && details.role);

        if (!isLoading && isEmpty(details)) {
            return <PageNotAllowed permissionError={true} title={`Print Template (ID:${id})`} />;
        }

        const matchUrl = `${match.url}`;
        const unreadMessages = getUnreadMessages(id, subscriptions, 'print-template');
        return (
            <Fragment>
                {isLoading && <Loader absolute backdrop />}
                {showDetails && (
                    <PageTemplate title={details.name} subTitle={`#${details.id}`} overflowHidden>
                        <Switch>
                            <Route path={matchUrl} exact component={() => <Redirect to={`${matchUrl}/layout`} />} />
                            {/* Handle the legacy redirect */}
                            <Route path={`${matchUrl}/layout`}>
                                <PrintTemplateLayout
                                    details={details}
                                    match={match}
                                    location={location}
                                    sidebarActions={[this.renderActions(unreadMessages, permissions, details), this.dotMenu(sidebarTitle, details, pathname)]}
                                    breadcrumbLine={this.buildBreadcrumbs(details.name, isPrintReports)}
                                    openSidebar={this.onDotMenuClick}
                                    isPrintReports={isPrintReports}
                                    reloadDetails={this.reloadDetails}
                                />
                            </Route>
                            <Route path={`${matchUrl}/about`}>
                                <EntityPrimaryAbout
                                    details={details}
                                    sidebarActions={[this.renderActions(unreadMessages, permissions, details), this.dotMenu(sidebarTitle, details, pathname)]}
                                    breadcrumbLine={this.buildBreadcrumbs(details.name, isPrintReports)}
                                    openSidebar={this.onDotMenuClick}
                                />
                            </Route>
                        </Switch>
                    </PageTemplate>
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = (state: Object, ownProps: Object) => ({
    id: ownProps.match.params.id,
    isLoading: state.entities.printTemplates.details.isLoading,
    details: get(state.entities.printTemplates, 'details.data', {}),
    sidebarTitle: state.sidebar.title,
    leftPanelOpts: state.leftPanel.state,
    subscriptions: state.chat.subscriptions.data
});

export default connect(mapStateToProps, {
    openRelationSidebar, openTaskSidebar, openProcessSidebar,
    loadPrintTemplateDetails, openEntitySidebar, closeLeftPanel, setDocumentTitle
})(withRouter(PrintTemplateDetails));
