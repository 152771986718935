import { gql } from '@apollo/client';
import { typeMap, queriesCountType } from 'app/config/typesConfig';
import { eventTypesListQuery } from 'graphql/stream/event/eventTypeQueries';
import eventsShortQuery from 'graphql/stream/event/eventsShortQuery';
import relationDefinitionsListQuery from 'graphql/entities/relationships/relationDefinitionsListQuery';


export const workspaceEntityResourcesQuery = (type: string, isEntityQuery: boolean) => {
    if (type === 'relationDefinition') {
        return relationDefinitionsListQuery();
    }
    if (type === 'eventtype') {
        return eventTypesListQuery;
    }
    if (type === 'event') {
        return eventsShortQuery;
    }
    if (type === 'user' && !isEntityQuery) {
        return gql`
            query workspaceEntityResourcesQuery {
                records: userReferences {
                    id
                    name
                    username
                    image
                }
            }
        `;
    }
    if (type === 'primaryClass') {
        return gql`
            query workspaceEntityResourcesQuery {
                records: entityTypes {
                    id
                    name
                    color
                    uri
                    active
                    hidden
                    abstract
                }
            }
        `;
    }
    if (isEntityQuery) {
        return gql`
            query workspaceEntityResourcesQuery($startIndex: Int, $stopIndex: Int, $type: String!, $filterBy: [JSON], $orderBy: [JSON], $excludeBy: [JSON]) {
                count: count(dataType: $type, filterBy: $filterBy, excludeBy: $excludeBy)
                records: entities(type: $type, startIndex: $startIndex, stopIndex: $stopIndex, filterBy: $filterBy, orderBy: $orderBy, excludeBy: $excludeBy) {
                    id
                    name
                    type
                }
            }
        `;
    }
    return gql`
        query workspaceEntityResourcesQuery($filterBy: [JSON], $countMax: Int, $orderBy: [JSON], $startIndex: Int, $stopIndex: Int) {
            count: count(dataType: "${queriesCountType[type] || type}", filterBy: $filterBy, max: $countMax)
            records: ${typeMap[type] || type}(filterBy: $filterBy, orderBy: $orderBy, startIndex: $startIndex, stopIndex: $stopIndex) {
                id
                name
                ${['workspace', 'team', 'class'].includes(type) ? '' : `type`}
            }
        }
    `;
};


export const loadWorkspacePermissionsQuery = gql`
    query workspaceEntityResourcesQuery($filterBy: [JSON]){
        # permissionsCount: count(dataType: "permission")
        permissionRecords: permissions {
            id
            name
            type
            active
        }
        # systemSettingCount: count(dataType: "system_setting")
        systemSettingRecords: entities(type: "system_setting", filterBy: $filterBy) {
            id
            name
            type
            active
        }
    }
`;
