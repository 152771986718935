/* @flow */
import { openDB } from 'idb';

const DB_NAME = 'AffectliDB';

const USER_REFERENCES_STORE = 'userReferences';
const FORM_DEFINITIONS_STORE = 'formDefinitions';
const CLASSES_STORE = 'classes';

const COLLECTIONS = [USER_REFERENCES_STORE, CLASSES_STORE];
const ENTITIES_COLLECTION = 'entities';

const DB_VERSION = 1;

const LOG_PREFIX = 'IndexedDB::';

// Open IndexedDB
const openDatabase = async () => {
    return openDB(DB_NAME, DB_VERSION, {
        upgrade(db, oldVersion, newVersion, transaction) {
            COLLECTIONS.forEach((storeName) => {
                if (!db.objectStoreNames.contains(storeName)) {
                    db.createObjectStore(storeName, { keyPath: 'id' });
                }
            });
            if (!db.objectStoreNames.contains(ENTITIES_COLLECTION)) {
                const entitesStore = db.createObjectStore(ENTITIES_COLLECTION, { keyPath: 'id' });
                entitesStore.createIndex('type', 'type', { unique: false });
            }
        },
    });
};

// Save data to the specified object store
const saveData = async (db, storeName, records) => {
    const tx = db.transaction(storeName, 'readwrite');
    const store = tx.objectStore(storeName);
    for (const item of records) {
        await store.put(item); // This will override existing record. Use store.add if you want to prevent overriding
    }
};

// Update or add data in IndexedDB
const updateOrAddData = async (storeName, data, keyPath = 'id') => {
    const db = await openDatabase();
    try {
        const tx = db.transaction(storeName, 'readwrite');
        const store = tx.objectStore(storeName);
        const existingData = await store.get(data[keyPath]);
        if (existingData) {
            await store.put(data);
            console.log(`${LOG_PREFIX}${keyPath} with value ${data[keyPath]} updated in IndexedDB.`); // eslint-disable-line
        } else {
            await store.add(data);
            console.log(`${LOG_PREFIX}${keyPath} with value ${data[keyPath]} added to IndexedDB.`); // eslint-disable-line
        }
    } catch (error) {
        console.error(`${LOG_PREFIX}Error updating or adding data in ${storeName}:`, error); // eslint-disable-line
        throw error;
    }
};

// Fetch all records with a specific type from the entiites store
export const getEntitiesRecordsByType = async (type) => {
    const db = await openDatabase();
    const tx = db.transaction(ENTITIES_COLLECTION, 'readonly');
    const store = tx.objectStore(ENTITIES_COLLECTION);
    const index = store.index('type');
    return index.getAll(type);
};


// Function to get the version of an existing IndexedDB database
export const getIndexedDBVersion = async () => {
    try {
        const db = await openDB(DB_NAME);
        const version = db.version;
        db.close(); // Close the database after obtaining the version
        return version;
    } catch (error) {
        console.log(`${LOG_PREFIX} An error occured while obtaining the version, `, error); // eslint-disable-line
        return DB_VERSION;
    }
};

// ************************ Add Entities *************************** //
export const addEntitiesToIDB = async (entities) => {
    if (!entities?.length) return;
    try {
        const db = await openDatabase();
        await saveData(db, ENTITIES_COLLECTION, entities);
        console.log(`${LOG_PREFIX} Entities data sync with IndexedDB completed successfully.`); // eslint-disable-line
    } catch (error) {
        console.error(`${LOG_PREFIX} error syncing data with IndexedDB: `, error); // eslint-disable-line
    }
};


// ************************ User References *************************** //

export const addUsersReferencesToIDB = async (userReferences) => {
    if (!userReferences?.length) return;
    try {
        const db = await openDatabase();
        await saveData(db, USER_REFERENCES_STORE, userReferences);
        console.log(`${LOG_PREFIX} UserReferences data sync with IndexedDB completed successfully.`); // eslint-disable-line
    } catch (error) {
        console.error(`${LOG_PREFIX} UserReferences error syncing data with IndexedDB: `, error); // eslint-disable-line
    }
};

// Get a single user by ID from IndexedDB
export const getUserReerenceById = async (userId) => {
    try {
        const db = await openDatabase();
        return await db.get(USER_REFERENCES_STORE, userId);
    } catch (error) {
        console.error(`${LOG_PREFIX} Error getting user by ID ${userId} from IndexedDB:`, error); // eslint-disable-line
        throw error;
    }
};

// Update or add user data in IndexedDB
export const updateOrAddUserReference = async (userData) => {
    if (!userData?.id) return;
    await updateOrAddData(USER_REFERENCES_STORE, userData, 'id');
};

// *************************** Classes **************************** //

export const addClassesToIDB = async (classes) => {
    if (!classes?.length) return;
    try {
        const db = await openDatabase();
        await saveData(db, CLASSES_STORE, classes);
        console.log(`${LOG_PREFIX} Classes data sync with IndexedDB completed successfully.`); // eslint-disable-line
    } catch (error) {
        console.error(`${LOG_PREFIX} Classes error syncing data with IndexedDB: `, error); // eslint-disable-line
    }
};

// Get a single class by ID from IndexedDB
export const getClassById = async (classId) => {
    try {
        const db = await openDatabase();
        return await db.get(CLASSES_STORE, classId);
    } catch (error) {
        console.error(`${LOG_PREFIX} Error getting class by ID ${classId} from IndexedDB:`, error); // eslint-disable-line
        throw error;
    }
};

// ********************** Form Definitions ************************* //

export const addFormdefinitionsToIDB = async (formDefinitions) => {
    if (!formDefinitions?.length) return;
    try {
        const db = await openDatabase();
        await saveData(db, FORM_DEFINITIONS_STORE, formDefinitions);
        console.log(`${LOG_PREFIX} FormDefinitions data sync with IndexedDB completed successfully.`); // eslint-disable-line
    } catch (error) {
        console.error(`${LOG_PREFIX} FormDefinitions error syncing data with IndexedDB:`, error); // eslint-disable-line
    }
};
