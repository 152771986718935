/* @flow */

import { gql } from '@apollo/client';

export default gql`
  mutation ssoDeleteIdentityProviderMutation($alias: String!) {
    result: ssoDeleteIdentityProvider(alias: $alias) {
      id
      name
      type
    }
  }
`;
