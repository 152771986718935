/* @flow */

import { gql } from '@apollo/client';
import { eventEntityRelationCommonFields } from 'graphql/entities/relationships/eventEntityRelationsQuery';

export default gql`
    mutation updateEventEntityRelationAttributesMutation(
        $event: EventReferenceInput!
        $entity: EntityReferenceInput!
        $relationDefinition: ID!
        $attributes: JSON!
    ) {
        payload: eventEntityRelationUpdateAttributes(event: $event, entity: $entity, relationDefinition: $relationDefinition, attributes: $attributes) {
            ${eventEntityRelationCommonFields}
        }
    }
`;
