import Immutable from 'app/utils/immutable/Immutable';
import { LOAD_AVATAR_STARTED, LOAD_AVATAR } from 'store/actions/entities/relationshipsActions';

const reducer = (
    state = Immutable({}),
    action
) => {
    const { type, meta, payload, error } = action;
    switch (type) {
        case LOAD_AVATAR_STARTED:
            return Immutable({
                ...state,
                [meta.id]: { isLoading: true },
            });

        case LOAD_AVATAR:
            if (error) {
                return Immutable({
                    ...state,
                    [meta.id]: { isLoading: false },
                });
            }
            return Immutable({
                ...state,
                [meta.id]: { isLoading: false, loadedAt: Date.now(), ...payload },
            });

        default:
            return state;
    }
};

export default reducer;