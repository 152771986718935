/* @flow */

import { gql } from '@apollo/client';

export const commonFields = `
            id
            description
            type
            relatedType
            relatedDescription
            classes {
                id
                name
                uri
                formDefinition
            }`;

export default gql`
    query relationDefinitionsQuery($page: Int, $pageSize: Int, $filterBy: [JSON], $orderBy: [JSON], $excludeBy: [JSON]) {
        result: relationDefinitions(startIndex: $page, stopIndex: $pageSize, filterBy: $filterBy, orderBy: $orderBy, excludeBy: $excludeBy) {
            ${commonFields}
        }
    }
`;
