import React from 'react';
import { render } from 'react-dom';
import { Buffer } from 'buffer';

import affectliSso from 'app/auth/affectliSso';
import { createScript,  showScriptError } from 'app/utils/html/script';

import * as serviceWorkerRegistration from './registerServiceWorker';

import { MainApp } from './App';

global.Buffer = Buffer;
window.addEventListener('beforeinstallprompt',(event)=>{
    event.preventDefault();
    window.beforeInstallPromptAffectli = event;
});

async function loadKeycloak() {
    try {
        const response = await fetch('/config/keycloak.json', {
            method: 'GET',
            headers: { 'content-type': 'application/json' }
        });

        if (response.status !== 200) {
            throw Error(response?.statusText);
        }

        const keycloak = await response.json();

        if (!keycloak['auth-server-url']) throw Error('No Auth Server Url');
        
        createScript(`${keycloak['auth-server-url']}/js/keycloak.js`, 'keycloak', (err) => {
            showScriptError(`Failed to load script ${err.target.src}`);
        });

        const scriptElem = document.querySelector('#keycloak');

        scriptElem.addEventListener('load', function() {
            affectliSso.loadConfig();
            affectliSso.initialized().then(() => {
                render(<MainApp />, document.getElementById('root'));
            });
        });
    } catch (error) {
        showScriptError(error);
    }
}

serviceWorkerRegistration.register();

loadKeycloak();

