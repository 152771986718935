// @flow

import { gql } from '@apollo/client';

import commonProcessDetailsQueryFields from 'graphql/abox/process/commonProcessDetailsQueryFields';
import { digitalTwinQueryFields } from 'graphql/entities/classifications/classificationDetailQuery';

export default gql`
    query loadProcessSidebarQuery($id: ID!) {
        result: process(id: $id) {
            ${commonProcessDetailsQueryFields}
            primaryClass {
                ${digitalTwinQueryFields}
            }
        }
    }
`;
