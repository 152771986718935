/* @flow */

import { gql } from '@apollo/client';

import { defaultPipelineQueryFields } from './pipelineQuery';

export default gql`
	mutation updatePipelineMutation($record: EntityUpdateInput!){
		result: updateEntity(record: $record) {
			${defaultPipelineQueryFields}
		}
	}
`;
