/* @flow */

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from '@mic3/platform-ui';

import HeaderBar from 'app/components/molecules/HeaderBar/HeaderBar';
import Loader from 'app/components/atoms/Loader/Loader';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { updateRelationship, updateEventEntityRelationAttributes } from 'store/actions/entities/relationshipsActions';
import { get } from 'app/utils/lo/lo';
import { isEmpty } from 'app/utils/utils';
import { groupFields } from 'app/utils/classification/classificationForm';

/**
 * A view to edit a relationship.
 */
class RelationAttributes extends PureComponent<Object, Object> {
    static propTypes = {
        relation: PropTypes.shape({
            id: PropTypes.string.isRequired,
            attributes: PropTypes.object,
            relationDefinition: PropTypes.shape({ classes: PropTypes.array }).isRequired,
        }),
        isLoading: PropTypes.bool,
        reloadList: PropTypes.func.isRequired,
        updateRelationship: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = { data: get(props.relation, 'attributes', {}) };
    }

    componentDidUpdate(prevProps) {
        const { relation } = this.props;
        if (relation !== prevProps.relation) {
            this.setState({ data: get(relation, 'attributes', {}) });
        }
    }

    @bind
    onChange(data) {
        this.setState({ data });
    }

    @bind
    save(event) {
        event.preventDefault();
        const { entity, relatedEntity, viewType, relation } = this.props;
        this.formRef.current.isValidForm().then(async ({ data, errors }) => {
            if (errors) {
                return;
            }
            if (viewType === 'eventEntity') {
                await this.props.updateEventEntityRelationAttributes({
                    relationDefinition: relation?.relationDefinition?.id,
                    event: { id: entity?.id, time: entity?.time },
                    entity: { id: relatedEntity?.id, type: relatedEntity?.type },
                    attributes: data
                });
            } else {
                await this.props.updateRelationship(this.props.relation.id, data);
            }
            this.props.reloadList();
        });
    }

    @bind
    getClassFields(relation) {
        const _classes = get(relation, 'relationDefinition.classes');
        if (!_classes) {
            return null;
        }

        return _classes.reduce((fields, classification) => {
            const formFields = get(classification, 'formDefinition.fields');
            const isPanelHasFields = get(classification, 'formDefinition.fields[0].children.length');
            if (!isEmpty(formFields) && isPanelHasFields) {
                fields.push(...formFields);
            }
            return fields;
        }, []);
    }

    @bind
    @memoize()
    buildForm(relation, data, canEdit, relEntityPermissions) {
        if (!relation) {
            return 'This relation has been removed.';
        }
        const classes = get(relation, 'relationDefinition.classes');
        const classFields = this.getClassFields(relation);
        if (classes?.length && classFields && !classFields.length) {
            return 'The class associated with the relation definition does not contain any attributes.';
        }
        if (classes && !classes.length) {
            return 'No Attribute Class is associated with this relation definition';
        }
        if (isEmpty(classes)) {
            return 'You have no rights to see the classification associated with the relation.';
        }
        const components = groupFields(classFields, { isNoWrapped: true });
        const isDisabled = !canEdit && !relEntityPermissions?.canEdit;
        return (
            <>
                <HeaderBar
                    right={[
                        !isDisabled && (
                            <Button key={1} onClick={this.save}>
                                Save
                            </Button>
                        ),
                    ].filter(Boolean)}
                />
                <FormGenerator ref={this.formRef} components={components} disabled={isDisabled} onChange={this.onChange} data={data} />
            </>
        );
    }

    /**
     * @override
     */
    render() {
        const { relation, isLoading, canEdit, relEntityPermissions } = this.props;
        const { data } = this.state;
        return isLoading ? <Loader /> : this.buildForm(relation, data, canEdit, relEntityPermissions);
    }
}

export default connect(null, { updateRelationship, updateEventEntityRelationAttributes })(RelationAttributes);
