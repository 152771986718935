/* @flow */

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, Grid, Paper, Typography, MdiIcon, IconButton, Dialog } from '@mic3/platform-ui';
import styled from 'styled-components';

import { cut } from 'app/utils/string/string-utils';
import { useToggle, useOnChange } from 'app/utils/hook/hooks';
import Textarea from './Textarea';

const DialogStyled = styled(Dialog)`
  & .MuiDialog-paper {
    padding: 16px;
  }
`;

const PaperStyled = styled(Paper)`
    padding: 12px;
    margin: 12px 0;
    cursor: pointer;
    width: 100%;
    background: ${({ theme }) => theme.material.colors.background.fields} !important;
`;

const TypographyStyled = styled(Typography)`
  ${({ disabled }) => disabled ? 'color: #95989c !important;' : ''}
`;

const TextareaDialog = (props: Object) => {
    const { value: propValue, name, title, onChange, template, disabled, trimOnSave, button, labelDialogField, buttonLabel, ...restProps } = props;
    const [isOpen, toggle] = useToggle();
    const [isValid, setIsValid] = useState(false);
    const [value, onChangeTextarea, setValue] = useOnChange(propValue);
    const [fullScreen, onChangeFullScreen] = useOnChange(false);
    useEffect(() => {
        setValue(propValue);
    }, [propValue, setValue]);

    const onSave = useCallback((e) => {
        onChange({ target: { value: trimOnSave ? value && value.trim(): value, name }});
        toggle();
    }, [name, onChange, toggle, value, trimOnSave]);
    const onValidTextarea = useCallback((valid) => {
        setIsValid(valid);
    }, []);
    const textareaValue = useMemo(() => value || template, [template, value, propValue]); // eslint-disable-line
    const preview = cut(propValue, 32) || '(empty)';
    return (
        <>
            { button ? (
                <Button
                    variant="text"
                    disabled={disabled}
                    onClick={toggle}>
                    {buttonLabel || title}
                </Button>
            ) :(
                <PaperStyled onClick={toggle}>
                    <Grid container direction="column" alignItems="stretch">
                        <TypographyStyled disabled={disabled} variant="caption">{labelDialogField || title}</TypographyStyled>
                        <TypographyStyled disabled={disabled}>{preview}</TypographyStyled>
                    </Grid>
                </PaperStyled>
            )}
            <DialogStyled open={isOpen} onClose={toggle} fullScreen={fullScreen} fullWidth maxWidth="md">
                <Grid container justify="space-between" alignItems="center">
                    <Grid item container xs={6} alignItems="center">
                        <IconButton aria-label="close" onClick={toggle}>
                            <MdiIcon name="close" />
                        </IconButton>
                        <Typography variant="h6">{title}</Typography>
                    </Grid>
                    <Grid item container xs={6} justify="flex-end" alignItems="center">
                        <Checkbox onChange={onChangeFullScreen} value={fullScreen} label="Full screen"/>
                        <Button onClick={onSave} disabled={!isValid || disabled}>
                            Apply
                        </Button>
                    </Grid>
                </Grid>
                <Textarea
                    modal
                    {...restProps}
                    disabled={disabled}
                    onValid={onValidTextarea}
                    rows={fullScreen ? 30 : 15}
                    onChange={onChangeTextarea}
                    value={textareaValue}
                />
            </DialogStyled>
        </>
    );
};

TextareaDialog.propTypes = {
    ...(Textarea || {}).propTypes,
    title: PropTypes.string.isRequired,
    template: PropTypes.string,
};

export default TextareaDialog;
