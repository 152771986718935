// @flow

import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import debouncePromise from 'p-debounce';
import styled from 'styled-components';

import Alert from 'app/components/molecules/Alert/Alert';
import VirtualList from 'app/components/molecules/VirtualList/VirtualList';
import Loader from 'app/components/atoms/Loader/Loader';
import Container from 'app/components/atoms/Container/Container';
import { bind } from 'app/utils/decorators/decoratorUtils';
import { get } from 'app/utils/lo/lo';
import { deepEquals } from 'app/utils/utils';

const TitleContainer = styled(Container)`
color: ${({ theme }) => theme.material.colors.text.primary};
position: relative;
width: 100%;
${({ maxWidth }) => maxWidth ? `maxWidth: ${maxWidth}px;` : ''}
margin-bottom: -1rem;
z-index: 1;

font-size: .7rem;
padding: .5rem;
font-weight: 500;
`;

/**
 *
 */
class VirtualListManaged extends PureComponent<Object, Object> {

    static propTypes = {
        renderComponent: PropTypes.func.isRequired,
        entityType: PropTypes.bool,
        itemSize: PropTypes.number.isRequired,
        itemCount: PropTypes.number.isRequired,
        loadData: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired,
        startIndex: PropTypes.number.isRequired,
        filterBy: PropTypes.arrayOf(PropTypes.object),
        excludeBy: PropTypes.arrayOf(PropTypes.object),
        orderBy: PropTypes.arrayOf(PropTypes.object),
        list: PropTypes.arrayOf(PropTypes.object),
        maxWidth: PropTypes.string,
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        noResult: PropTypes.func 
    };

    static defaultProps = {
        itemKey: 'id',
    }

    requestedIndexes: Set<number> = new Set();
    unmounted = false;
    virtualListStyle = { marginTop: '1rem'};
    prevStartIndex = -1;
    prevStopIndex = -1;

    constructor(props) {
        super(props);
        const { list, startIndex } = props;
        this.state = { ...this.addItems(startIndex, list), firstLoading: true, key: 0, forceUpdateKey: 0 };
    }

    componentDidMount() {
        this.loadData().finally(() => !this.unmounted && this.setState({ firstLoading: false }));
    }

    componentWillUnmount() {
        this.unmounted = true;
    }

    componentDidUpdate(prevProps: Object) {
        const { list, startIndex, orderBy, filterBy, excludeBy } = this.props;
        if (list && prevProps.list !== list) {
            this.setState(this.addItems(startIndex, list));
        }
        if (
            !deepEquals(orderBy, prevProps.orderBy) || 
            !deepEquals(filterBy, prevProps.filterBy) || 
            !deepEquals(excludeBy, prevProps.excludeBy)) {
            this.resetView({ orderBy, filterBy, excludeBy });
        }
    }

    addItems(startIndex: number, list: Object[]) {
        const next = [ ...(get(this, 'state.list') || []) ];
        (list || []).forEach((item, index) => next[(startIndex || 0) + index] = item);
        return { list: next, forceUpdateKey: get(this, 'state.forceUpdateKey', 0) + 1 };
    }

    @bind
    resetView(stateConfiguration: ?Object) {
        const { list, startIndex } = this.props;
        this.requestedIndexes = new Set();
        this.prevStartIndex = -1;
        this.prevStopIndex = -1;
        this.setState({
            ...(stateConfiguration || {}),
            list: this.addItems(startIndex, list).list,
            key: this.state.key + 1
        }, () => {
            this.loadData().finally(() => !this.unmounted && this.setState({ firstLoading: false }));
        });
    }

    /** Load more rows on scroll down **/
    @bind
    loadMoreRows({ startIndex, stopIndex }: Object) {
        return this.loadData({ startIndex, stopIndex }).finally(() => (!this.unmounted && this.state.firstLoading) && this.setState({ firstLoading: false }));
    };

    /** We call the loadData function attached to the filtersView **/
    loadData = debouncePromise((options: ?Object) => {
        const { filterBy, orderBy, excludeBy } = this.props;
        let { startIndex: start, stopIndex: stop  } = options || {};
        start = start || 0;
        if (start >= 15) { // we need to preload the 'page before'
            start -= 15;
        }
        stop = stop || 30;
        if ((stop - start) < 30) { // we need to preload at least 30 elements
            stop = start + 30;
        }

        while (start < stop && this.requestedIndexes.has(start)) {
            ++start;
        }
        while (stop > start && this.requestedIndexes.has(stop)) {
            --stop;
        }
        if (start === stop) {
            return Promise.resolve();
        }
        if (this.prevStartIndex === start && this.prevStopIndex === stop) {
            return Promise.resolve();
        }

        const promise = this.props.loadData({ startIndex: start, stopIndex: stop, filterBy, orderBy, excludeBy });
        const isPromise = promise instanceof Promise;
        if (!isPromise) {
            throw new Error('The loadData function MUST return a Promise.');
        }
        for (let i = start; i < stop; ++i) {
            this.requestedIndexes.add(i);
        }
        this.prevStartIndex = start;
        this.prevStopIndex = stop;
        return promise;
    }, 300);

    @bind
    showNoResult() {
        const { isLoading, itemCount = 0, renderNoResultComponent, filterBy, noResult } = this.props || {};
        if (!this.state.firstLoading && !isLoading && !itemCount) {
            if (renderNoResultComponent) {
                return renderNoResultComponent(filterBy);
            }
            return !noResult ? <Alert type='background' margin={16}>No results</Alert> : noResult();
        }
        return null;
    };

    @bind
    resetFirstLoading() {
        this.setState({ firstLoading: true });
    }

    @bind
    forceUpdate() { return this.setState({ forceUpdateKey: this.state.forceUpdateKey + 1 });};

    render() {
        const { itemSize, itemCount, maxWidth, title, renderComponent, entityType } = this.props;
        const { list, key, forceUpdateKey }= this.state;
        const maxTitleWidth = Number(maxWidth);
        return (
            <Fragment>
                {(this.state.firstLoading) && <Loader absolute />}
                {title ? <TitleContainer maxWidth={String(maxTitleWidth)}>{title}</TitleContainer> : null }
                {this.showNoResult()}
                {!!itemCount && <VirtualList
                    key={key}
                    forceupdate={forceUpdateKey}
                    onItemsRendered={this.loadMoreRows}
                    width={maxWidth}
                    itemSize={itemSize}
                    itemCount={itemCount}
                    renderItem={({ index, style, resize }) => {
                        return list[index]
                            ? renderComponent({ style, index, resize, data: list[index], itemSize })
                            : <div style={style} key={index}><Loader key={index} /></div>;
                    }}
                    style={this.virtualListStyle}
                    entityType={entityType}
                />}
            </Fragment>
        );
    }
};

export default VirtualListManaged;
