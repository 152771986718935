/* @flow */

import { help } from 'app/utils/designer/form/settings/common/commonHelpers';
import { name, label } from 'app/utils/designer/form/settings/common/commonBase';
import { onLoad, onChange, isVisible, isDisabled } from 'app/utils/designer/form/settings/common/commonEvents';
import { flexGrow } from 'app/utils/designer/form/settings/common/commonStyles';
import { readOnly, staticAttribute, classRequired } from 'app/utils/designer/form/settings/common/commonValidation';
const panels = settingsValues => [
    {
        header: 'Base',
        children: [
            name,
            label,
            {
                type: 'typeahead',
                properties: {
                    label: 'Link target',
                    name: 'properties.target',
                    valueField: 'value',
                    clearable: false,
                    options: [
                        {value: '_self', label: 'Same Tab'},
                        {value: '_blank', label: 'New Tab'},
                        {value: 'dialog', label: 'Dialog'},
                        {value: 'newWindow', label: 'New Window'}
                    ]
                }                                                     
            },
            {
                type: 'number',
                properties: {
                    isVisible: ({ properties }) => properties.target === 'newWindow',
                    label: 'Window width',
                    name: 'properties.windowWidth',
                }
            },
            {
                type: 'number',
                properties: {
                    isVisible: ({ properties }) => properties.target === 'newWindow',
                    label: 'Window height',
                    name: 'properties.windowHeight',
                }
            },
            {
                type: 'number',
                properties: {
                    isVisible: ({ properties }) => properties.target === 'dialog',
                    label: 'Dialog height',
                    name: 'properties.dialogHeight',
                }
            },
            {
                type: 'number',
                properties: {
                    isVisible: ({ properties }) => properties.target === 'dialog',
                    label: 'Dialog width',
                    name: 'properties.dialogWidth',
                }
            }
        ],
    },
    {
        header: 'Events',
        children: [
            onLoad,
            onChange,
            isVisible,
            settingsValues?.viewType !== 'class' && isDisabled
        ].filter(Boolean)
    },
    {
        header: 'Validation',
        children: [
            settingsValues?.viewType !== 'class' ? classRequired :
                { ...classRequired, properties: {
                    ...classRequired.properties,
                    disabled: data => data?.properties?.disabled === true || !!data?.properties?.static,
                }},
            settingsValues?.viewType === 'class' && readOnly,
            settingsValues?.viewType === 'class' && staticAttribute(settingsValues)
        ].filter(Boolean)
    },
    {
        header: 'Styles',
        children: [ flexGrow ]
    },
    {
        header: 'Helpers',
        children: [ help ]
    },
];

export default {
    defaults: { withDefault: true, target: '_blank' },
    panels: settingsValues => panels(settingsValues),
};
