import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { closeLeftPanel, setActions } from 'store/actions/leftPanel/leftPanelActions';

import { addKeyValueToObjectArray, getValuesFromKey } from 'app/utils/relatedEntity/relatedEntityUtils';
import { 
    countTreeEntities,
    setTreeEntity,
    setTreeTemplate,
    setSidebarTreeTemplate,
    setSidebarTreeTemplateExpand,
    loadClassificationById,
    loadRelationDefinitionsFromIds,
    loadClassificationsByUri
} from 'store/actions/entities/relatedEntitiesActions';
import { showToastr } from 'store/actions/app/appActions';
import { bind, debounce } from 'app/utils/decorators/decoratorUtils';
import { get } from 'app/utils/lo/lo';

import { resetTree } from 'store/actions/entities/relatedEntitiesActions';
import Loader from 'app/components/atoms/Loader/Loader';
import RelatedEntityGetStarted from 'app/components/RelatedEntity/RelatedEntityGetStarted';
import GlobalLeftPanelHeader from 'app/components/organisms/GlobalLeftPanel/GlobalLeftPanelHeader';
import RelatedEntityToolbar from 'app/components/RelatedEntity/RelatedEntityToolbar';
import AddTreeTemplate from 'app/containers/Entities/RelatedEntities/AddTreeTemplate';
import RelatedEntityTreeListView from 'app/containers/Entities/RelatedEntities/RelatedEntityTreeListView';

class RelatedEntityLeftPanel extends PureComponent {
    constructor(props: Object) {
        super(props);
        this.state = {
            isAddModalOpen: false,
            isLoadingTemplate: false,
            searchValue: '',
            hideInactive: true,
            expandValue: false,
            sortValue: '',
            forceUpdateTreeList: 0,
        };
        props.countTreeEntities();
        this.setActions(props.entityType);
    };

    componentDidMount() {
        if (this.props.selectedTreeEntity) {
            this.loadTreeRelations(this.props.selectedTreeEntity);
        }
    }

    componentDidUpdate(prevProps) {
        const { selectedTreeEntity, outdatedTree, setTreeTemplate, setSidebarTreeTemplate, setSidebarTreeTemplateExpand} = this.props;

        if (prevProps.selectedTreeEntity !== selectedTreeEntity) {
            setTreeTemplate(null);
            setSidebarTreeTemplate(null);
            setSidebarTreeTemplateExpand([]);

            if (selectedTreeEntity) {
                this.loadTreeRelations(selectedTreeEntity);
            }
        }

        if (!this.props.actions) {
            this.setActions(this.props.entityType);
        }
        if(outdatedTree && outdatedTree !== prevProps.outdatedTree ){           
            this.setState({ forceUpdateTreeList: this.state.forceUpdateTreeList + 1 }, ()=> {
                this.props.resetTree(false);
            });
        }
    }

    @bind
    setActions(type) {
        this.props.setActions(
            <GlobalLeftPanelHeader
                key={type}
                hideSettings={true}
                onHide={this.props.closeLeftPanel}
            />
        );
    }

    @bind
    toggleAddModal() {
        this.setState(prevState => ({ isAddModalOpen: !prevState.isAddModalOpen }));
    }

    @bind
    handleSelectTree(treeEntity) {
        this.props.setTreeEntity(treeEntity);
    }

    @bind
    @debounce()
    handleSearch(searchValue) {
        this.setState({ searchValue });
    }

    @bind
    handleHideInactive(value) {
        this.setState({ hideInactive: value });
    }

    @bind
    handleSort(value) {
        this.setState({ sortValue: value });
    }

    @bind
    handleExpand(value) {
        this.setState({ expandValue: value });
    }

    @bind
    setSelectedTreeTemplate({ id, uri, name, listBy, listEntityType, icon: groupIcon, iconType: groupIconType, children }) {
        this.setState({ isLoadingTemplate: false }, () =>  {
            this.props.setTreeTemplate({
                listEntityType,
                root: {
                    id,
                    uri,
                    name,
                    listBy,
                    groupIcon,
                    groupIconType,
                    children
                }
            });
        });
    }

    @bind
    async loadTreeRelations(selectedTreeEntity) {
        this.setState({ isLoadingTemplate: true });
        const { root, listEntityType } = get(selectedTreeEntity, 'primary.relationTree') || {};
        let children = JSON.parse(JSON.stringify(root?.children || []));

        const rootClass = await this.props.loadClassificationById(root?.id);
        if (rootClass instanceof Error) {
            this.setSelectedTreeTemplate({});
            return;
        };

        // for open related index option populate listBy values
        let relatedEntityType = null;

        if (listEntityType) {
            relatedEntityType = await this.props.loadClassificationById(listEntityType);

            if (relatedEntityType instanceof Error) {
                this.setSelectedTreeTemplate({});
                return;
            };
        }

        const defaultTreeTemplate = {...rootClass, listBy: root?.listBy, listEntityType: relatedEntityType?.uri, children: [] };

        const relIds = getValuesFromKey(root?.children, 'id');
        if (relIds.length <= 0) {
            this.setSelectedTreeTemplate(defaultTreeTemplate);
            return;
        }

        const response = await this.props.loadRelationDefinitionsFromIds(relIds);
        if (response instanceof Error) {
            this.setSelectedTreeTemplate(defaultTreeTemplate);
            return;
        };

        // set uri value to children object
        const setUri = (current, matched) => !current.reverse ? matched.relatedType : matched.type;
        children = addKeyValueToObjectArray(children, response?.records, 'uri', 'id', 'id', setUri);

        // set name value to children object
        const setName = (current, matched) => current.reverse ? matched.relatedDescription : matched.description;
        children = addKeyValueToObjectArray(children, response?.records, 'name', 'id', 'id', setName);
        
        const uris = getValuesFromKey(children, 'uri');
        const classes = await this.props.loadClassificationsByUri(uris);

        if (classes instanceof Error) {
            this.setSelectedTreeTemplate(defaultTreeTemplate);
            return;
        };

        // set class name to children object
        const setUriName = (_, matched) => matched?.name;
        children = addKeyValueToObjectArray(children, classes, 'sub', 'uri', 'uri', setUriName);

        // set icon to children object
        const setIcon = (_, matched) => matched?.icon;
        children = addKeyValueToObjectArray(children, classes, 'groupIcon', 'uri', 'uri', setIcon);

        // set iconType to children object
        const setIconType = (_, matched) => matched?.iconType;
        children = addKeyValueToObjectArray(children, classes, 'groupIconType', 'uri', 'uri', setIconType);

        this.setState({ isLoadingTemplate: false }, () => {
            this.setSelectedTreeTemplate({ ...defaultTreeTemplate, children });
        });
    }

    render() {
        const { isOpen, showToastr, selectedTreeEntity, treeEntityCount, isLoadingTree, treeTemplate, profile } = this.props;
        const { forceUpdateTreeList, isAddModalOpen, isLoadingTemplate, searchValue, hideInactive, sortValue, expandValue } = this.state;
        const { permissions, isAdmin } = profile;
        const permissionsSet = new Set(permissions || []);
        const canAdd = isAdmin || permissionsSet.has('entity.entity.add');

        return isOpen && (
            <>
                <RelatedEntityToolbar
                    onSelect={this.handleSelectTree}
                    onSearch={this.handleSearch}
                    onCreate={this.toggleAddModal}
                    onHideInactive={this.handleHideInactive}
                    onSort={this.handleSort}
                    onExpand={this.handleExpand}
                    showToastr={showToastr}
                    relatedEntity={selectedTreeEntity}
                    showToolbar={treeEntityCount > 0}
                    canAdd={canAdd}
                />
                {treeTemplate && (
                    <RelatedEntityTreeListView
                        key={forceUpdateTreeList}
                        template={treeTemplate} 
                        treeEntity={selectedTreeEntity} 
                        search={searchValue}
                        hideInactive={hideInactive}
                        sort={sortValue}
                        expand={expandValue}
                    />
                )}
                {(isLoadingTree || isLoadingTemplate) && <Loader absolute/>}
                {(!treeTemplate && !isLoadingTree && !isLoadingTemplate) && <RelatedEntityGetStarted onClickCreate={this.toggleAddModal} />}
                {isAddModalOpen && <AddTreeTemplate onClose={this.toggleAddModal} />}
            </>
        );
    }
}

export default connect(
    (state: Object, ownProps: Object) => ({
        isOpen: state.leftPanel.state.isOpen,
        actions: state.leftPanel.state.actions,
        selectedTreeEntity: state.entities.relatedEntities.tree.selectedTreeEntity,
        treeTemplate: state.entities.relatedEntities.tree.treeTemplate,
        treeEntityCount: state.entities.relatedEntities.tree.count,
        isLoadingTree: state.entities.relatedEntities.tree.isLoading,
        profile: state.user.profile,
        outdatedTree: state.entities.relatedEntities.tree.outdatedTree
    }), {
        closeLeftPanel,
        setActions,
        showToastr,
        setTreeEntity,
        countTreeEntities,
        loadClassificationById,
        loadRelationDefinitionsFromIds,
        loadClassificationsByUri,
        setTreeTemplate,
        setSidebarTreeTemplateExpand,
        setSidebarTreeTemplate,
        resetTree
    }   
)(RelatedEntityLeftPanel);
