import theme from 'app/themes/theme.default';
import { muiTheme } from 'app/themes/materialUi';

import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    *, *:after, *:before {
        box-sizing: border-box;
    }

    *:focus {
        outline: none;
    }
    
    *::-webkit-scrollbar {
        width: 9px;
        height: 9px;
    }

    *::-webkit-scrollbar-thumb {
        border-radius: 5px;
        box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        background-color: rgba(255,255,255,.2);
        min-height: 65px;
    }

    *::-webkit-scrollbar-thumb :hover{
        background-color: rgba(255,255,255,.3);
    }

    *::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 0;
        background-color: transparent;
    }

    html {
        height: 100%;
        min-height: 100%;
        font-size: 16px;
        line-height: 1.5;
        font-family: 'Roboto', sans-serif;
    }

    body {
        font-size: 1rem;
        height: 100%;
        position: relative;
        margin:0; padding: 0;
        overflow: hidden;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
        font-family: 'Roboto', sans-serif;
        background: ${theme.base.background};
    }

    #root, .AppContainer {
        height: 100%;
        position: relative;
        overflow: hidden;
        font-family: 'Roboto', sans-serif;
    }

    .fullHeight {
       height: 100%;
       min-height: 100%;
    }

    .fullHeight.withTabs {
        height: calc(100% - 51px);
        min-height: calc(100% - 51px);
    }

    .fullHeight.withTabsAndFooter {
        height: calc(100% - 113px);
        min-height: calc(100% - 113px);
        overflow: auto;
    }

    .block {
        display: block;
    }
    
    a {
        cursor: pointer;
    }

    h1 {
        font-size: 1.2em;
    }

    h2 {
        font-size: 1em;
    }

    h3 {
        font-size: .9em;
    }

    h4 {
        font-size: .8em;
    }

    .ui-datepicker-today .ui-state-highlight {
        color: ${theme.base.textColor};
        background: transparent;
    }

    .ui-autocomplete, .ui-autocomplete-multiple-container {
        width: 100% !important;
    }

    .MuiPickersTimePickerToolbar-hourMinuteLabel .MuiButton-root.MuiButtonBase-root {
        max-width: 64px;
    }

    .MuiPickerDTToolbar-toolbar .MuiButton-root.MuiButtonBase-root .MuiTypography-h4 {
        font-size: 1.9rem;
    }

    .MuiPickerDTToolbar-toolbar .MuiButton-root.MuiButtonBase-root .MuiTypography-h3 {
      max-width: 50px;
    }

    .MuiPickerDTToolbar-toolbar .MuiPickersToolbar-toolbar {
      height: 135px;
    }

    .MuiPickersBasePicker-container .MuiPickersDatePickerRoot-toolbar {
      height: 77px;
      padding-top: 8px;
    }

    .MuiPickersCalendar-week .MuiIconButton-root {
      padding: 9px;
    }

    .MuiPickersDay-current {
        color: #4BB9D9 !important;
    }

    .MuiPickersDay-daySelected {
        color: #FFFFFF !important;
        background-color: #4BB9D9 !important;
        border-radius: 50% !important;
    }

    .MuiPickersDay-day:hover {
        border-radius: 50% !important;
    }

    .MuiChip-label {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 185px;
      display: block;
    }

    .MuiFilledInput-input, .MuiFilledInput-multiline {
      padding: 27px 12px 10px !important;
    }

    .Autocomplete-inputRoot-327.MuiFilledInput-adornedStart, .MuiFilledInput-inputAdornedStart {
      padding-left: 0 !important;
    }

    .MuiInputAdornment-positionStart {
      margin-top: 16px;
    }

    div[class^="a-dropZone"], div[class*="a-dropZone"]{
        background-color: ${muiTheme.colors.background.fields} !important;
    }

    .toast {
        position: fixed;
        right: 0;
        bottom: 0;
        z-index: 1400;
        max-width: 100%;
        opacity: 0;
        transform: translate(75%, 0);
        pointer-events: none;
        -webkit-transition: all 0.3s ease, transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        -moz-transition: all 0.3s ease, transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        -o-transition: all 0.3s ease, transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: all 0.3s ease, transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    .toast.displayed {
        opacity: 1;
        transform: translate(0, 0);
    }

    .ui-accordion .ui-accordion-content-wrapper-expanded {
        max-height: 2000px !important;
    }

    #navigation-popper {
        z-index: 1500;
    }

    @media print {
        -webkit-print-color-adjust: exact;
        .no-print  {
          display: none !important;
        }
        body,
        #global-template,
        #global-template-grid,
        #root,
        .page-content,
        .AppContainer,
        .app-content,
        .content-area,
        .fullHeight.withTabsAndFoote {
          overflow: visible !important;
        }
        .pagebreak {page-break-after: always;}
    }

    div.b-theme-classic-dark div.b-menuitem{
        background :${({ theme }) => theme.material.colors.background.paper};
        border: none;
        color: ${({theme})=> theme.material.colors.text.secondary};
    }

    .b-grid-cell.b-focused:after {
        border: 2px solid ${({ theme }) => theme.material.colors.background.paper} !important;
        border-radius: 0;
    }

    div.b-theme-classic-dark div.b-menuitem:hover,
    .b-menuitem.b-contains-focus, .b-menuitem:focus {
        background: ${({ theme }) => `linear-gradient(
        0deg, 
        ${theme.material.colors.background.hover}, 
        ${theme.material.colors.background.hover}
    )`};
        background-color: ${({ theme }) => theme.material.colors.background.paper} !important;
    }

    div.b-theme-classic-dark div.b-tooltip-content {
        background-color: ${muiTheme.colors.background.menu} !important;
    }

    .b-tooltip-header {
        background-color: ${muiTheme.colors.background.menu} !important;
        display:flex;
        align-items:center;
        justify-content:space-between;
    }

    .b-tooltip-header > .b-tool.b-icon {
        font-size:20px !important;
    }

    .b-panel.b-html .b-panel-content{
        margin-block: 0px;
        padding-block: 1rem !important;
    }

    .b-auto-container {
        gap: 0;
    }
    
    .b-button {
        color: ${({theme})=> theme.material.colors.text.secondary} !important;
        border: none !important;
        background-color: transparent !important;
    }

    .b-panel.b-floating > .b-panel-body-wrap > .b-panel-content {
        display: block !important;
    }
    div.b-theme-classic-dark .b-anchor-left, .b-anchor-bottom {
        opacity: 0.01 !important;
    }
    .MuiButtonBase-root > .MuiListItemIcon-root > i {
        cursor: pointer;
    }
    .MuiButtonBase-root > .MuiIconButton-label > i {
        cursor: pointer;
    } 
    .MuiButtonBase-root > .MuiIconButton-label > span > i {
        cursor: pointer;
    }
    .MuiDivider-root{
        background-color: ${muiTheme.colors.background.divider} !important;
    }

`;

export default GlobalStyle;