import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { loadPipelineAutocomplete } from 'store/actions/designer/designerPipelineActions';

import AbstractEntityTypeahead from 'app/components/organisms/Form/Typeahead/abstract/AbstractEntityTypeahead';
import ResolveAvatarTypeaheadWrapper from 'app/components/organisms/Form/Typeahead/abstract/ResolveAvatarTypeaheadWrapper';

/**
 * Select one or more groups using lazy loading.
 */
class PipelineTypeahead extends PureComponent<Object, Object> {

    static propTypes = {
        loadPipelineAutocomplete: PropTypes.func.isRequired,
        filterBy: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.array])),
        excludeBy: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.array])),
    }

    render() {
        // remove the properties that we do not have to pass to the AbstractEntityAutocomplete
        const { loadPipelineAutocomplete, ...abstractEntityAutocompleteProps } = this.props;
        return (
            <AbstractEntityTypeahead
                placeholder="Search for a pipeline..."
                {...abstractEntityAutocompleteProps}
                loadOptions={loadPipelineAutocomplete}
            />
        );
    }
};

const PipelineTypeaheadWrapped = (props) => {
    return <ResolveAvatarTypeaheadWrapper {...props} Typeahead={PipelineTypeahead} entityType="iot_pipeline" />;
};

export default connect(
    null,
    { loadPipelineAutocomplete }
)(PipelineTypeaheadWrapped);
