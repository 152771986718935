import { get } from 'app/utils/lo/lo';

const SystemMessages = {
    r: ({ text, user: { username } }) => `Room name changed to: <em>${text}</em> by <em>${username}</em>`,
    au: ({ text, user: { username } }) => `User <em>${text}</em> added by <em>${username}</em>.`,
    ru: ({ text, user: { username } }) => `User <em>${text}</em> removed by <em>${username}</em>.`,
    ul: ({ text }) => `${text} Has left the channel.`,
    ult: ({ text }) => `${text} Has left the team.`,
    uj: ({ text }) => `${text} Has joined the channel.`,
    ujt: ({ text }) => `${text} Has joined the team.`,
    ut: ({ text }) => `${text} Has joined the conversation.`,
    wm: ({ text }) => `Welcome <em>${text}</em>.`,
    rm: () => `Message removed`,
    'user-muted': ({ text, user: { username } }) => `User <em>${text}</em> muted by <em>${username}</em>.`,
    'user-unmuted': ({ text, user: { username } }) => `User <em>${text}</em> unmuted by <em>${username}</em>.`,
    'subscription-role-added': ({ text, user: { username }, role }) => `${text} was set ${role} by ${username}`,
    'subscription-role-removed': ({ text, user: { username }, role }) => `${text} is no longer ${role} by ${username}`,
    'room-archived': ({ text, user: { username }, role }) => `${text} is no longer ${role} by ${username}`,
    'room-unarchived': ({ user: { username } }) => `This room has been archived by ${username}`,
    room_e2e_enabled: ({ user: { username } }) => `This room has been unarchived by ${username}`,
    'livechat-started': () => `Start of conversation`,
    command: () => `Chat started`,
};

export const getSystemMessageByType = ((message) => {
    const ts = new Date(get(message, 'ts', ''));
    const tsDate = new Date(get(message, 'ts.$date', ''));
    const isTsDateValid = tsDate instanceof Date && !isNaN(tsDate);
    const createDate = isTsDateValid ? tsDate : ts;
    const messageTypes = Object.keys(SystemMessages);
    const type = message.t;
    const [, parsedMsg, ] = message.msg.match(/(.*)\s#([\da-f]{8}-[\da-f]{4}-[\da-f]{4}-[\da-f]{4}-[\da-f]{12}$)/) || [];
    const newMessage = {
        id: message._id,
        type,
        text: parsedMsg ? parsedMsg : message.msg,
        createDate,
        user: {
            id: get(message, 'u._id'),
            username: get(message, 'u.username'),
            name: get(message, 'u.name')
        },
        role: get(message, 'role')
    };
    if (!messageTypes.includes(type)) return newMessage;

    return { ...newMessage, text: SystemMessages[`${type}`](newMessage) };
});
