/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import PageTemplate from 'app/components/templates/PageTemplate';
import { loadEventTypes } from 'store/actions/stream/eventsActions';
import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import EntitiesList from 'app/containers/Entities/EntitiesList/EntitiesList';
import { typeTitlesMultiple } from 'app/config/typesConfig';
import withPermissions from 'app/utils/withPermissions';
import PageNotAllowed from 'app/containers/ErrorPages/PageNotAllowed';
import { modulesAndPageTitles } from 'app/config/typesConfig';
import { setDocumentTitle } from 'store/actions/app/appActions';

const VIEW_ID = 'EventTypesListView';

const listType = 'eventtype';

class EventTypesListView extends PureComponent<Object, Object> {
    static propTypes = {
        loadEventTypes: PropTypes.func.isRequired,
        records: PropTypes.array,
        isLoading: PropTypes.bool,
        totalRecords: PropTypes.number
    };

    static defaultProps = {
        isLoading: false,
        VirtualListProps: {},
        FiltersProps: {}
    };

    filterDefinitions = [
        {
            field: 'id',
            type: 'uuid',
            properties: {
                label: 'ID',
                name: 'id',
                opSelector: true
            },
            condition: '='
        },
        {
            field: 'name',
            type: 'text',
            properties: {
                label: 'Name',
                name: 'name',
                opSelector: true
            }
        },
        {
            field: 'createdDate',
            type: 'dateTimeRange',
            properties: {
                label: 'Created Date',
                name: 'createdDate'
            }
        },
        {
            field: 'modifiedDate',
            type: 'dateTimeRange',
            properties: {
                label: 'Modified Date',
                name: 'modifiedDate'
            }
        },
    ];

    componentDidMount() {
        this.props.setDocumentTitle(modulesAndPageTitles.miStream.eventTypes);
    }

    render() {
        const { startIndex, isLoading, records, totalRecords, canView } = this.props;
        if (!canView) {
            return <PageNotAllowed title="Event Types" />;
        }
        return (
            <PageTemplate title={typeTitlesMultiple[listType]}>
                <ContentArea>
                    <EntitiesList
                        id={VIEW_ID}
                        disableSettings
                        type={listType}
                        headerTitle={typeTitlesMultiple[listType]}
                        records={records}
                        totalRecords={totalRecords}
                        startIndex={startIndex}
                        isLoading={isLoading}
                        loadData={this.props.loadEventTypes}
                        filterDefinitions={this.filterDefinitions}
                        enableCountdown
                    />
                </ContentArea>
            </PageTemplate>
        );
    }
}

export default connect(
    (state: Object) => ({
        records: state.stream.events.types.records,
        totalRecords: state.stream.events.types.count,
        isLoading: state.stream.events.types.isLoading,
        startIndex: state.stream.events.types.startIndex
    }),
    { loadEventTypes, setDocumentTitle }
)(withPermissions(EventTypesListView, 'eventtype'));
