/* @flow */

import greenlet from 'greenlet';

const workerEval = greenlet(`
(fnDefinition, ...args) => {
    return eval(fnDefinition)(...args);
}
`);

const worker = async (fnDefinition, ...args) => {
    try {
        const result = await workerEval(fnDefinition, ...args);
        return result;
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log('An error occured executing:\n', fnDefinition, '\n\nusing the arguments', args, '\nERROR:', e);
        throw Error(e);
    }
};

export default worker;
