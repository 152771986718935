import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { Button } from '@mic3/platform-ui';
import { Select } from '@mic3/platform-ui';
import Filters from 'app/components/organisms/Filters/Filters';
import ToolBar from 'app/components/molecules/ToolBar/ToolBar';


export const StyledButton = styled(Button)`
    padding: 12px !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    min-width:200px !important;
`;

export const TimelineToolbarSection = styled(ToolBar)`
    position: absolute;
    top: ${isMobile ? '10.5rem' : '4rem'};
    z-index: 20;
    right: ${({ move }) => (move ? '380px' : '10px')};
    border-radius: 40px;
    padding: 0px 5px;
    background: ${({ theme }) => theme.material.colors.background.navigation};
    span {
        color: ${({ theme }) => theme.material.colors.text.secondary};
        margin: 6px;
        cursor: pointer;
    }
    & > * {
        display: flex !important;
    }
`;

export const TimelineToolbarSelect = styled(Select)`
    min-width: 60px;
    margin-left: 12px;

    #select-range {
        &:focus {
            background-color: transparent;
        }
    }
`;

export const FiltersTimeline = styled(Filters)`
    padding: 0 8px;

    .filter-toolbar {
        & .MuiGrid-grid-xs-true {
            min-width: 300px;
        }
    }

    .filter-chips {
        margin: 0 12px;
    }

    .page-content {
        height: 100%;
    }

    div[class*='Filters__Content'] {
        max-height: ${props => (isMobile ? 'calc(' + window.innerHeight + ' - ' + props.contentOffset + 'px)' : 'calc(100vh - ' + props.contentOffset + 'px)')};
    }

    .dark {
        height: ${props => (isMobile ? 'calc(' + (window.innerHeight - props.contentOffset) + 'px) !important' : '100%')};
    }
`;

export const Label = styled.strong``;

export const TimelineToolbarSectionWrapper = styled.div`
    padding: 0 8px 0 0;
`;
