// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { commonAbout } from 'app/containers/Maps/LayerAbout/CommonAbout';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';

import ListItem from 'app/components/molecules/List/ListItem';
import { MdiIcon } from '@mic3/platform-ui';
import { shallowEquals } from 'app/utils/utils';
import { updateMapLayer } from 'store/actions/maps/situationalAwarenessActions';
import { LAYERS, __getMapLayers } from 'app/utils/maps/layer/layerUtils';

const ImageComponent = styled.div`
    background: url('${({ src }) => src}') center center;
    background-size: 100% 100%;
    width: 100px;
    height: 56px;
`;

class DrawingLayerAbout extends PureComponent<Object, Object> {

    static propTypes: Object = {
        id: PropTypes.string.isRequired,
        map: PropTypes.object.isRequired,
        mapId: PropTypes.string.isRequired,
        layer: PropTypes.object.isRequired,
        details: PropTypes.object,
    };

    constructor(props: Object) {
        super(props);
        const { classes, attributes, ...rest } = props.details;
        this.state = {
            layerForm: { ...rest },
            initialForm: { ...rest },
            formChanged: false
        };
    }

    formRef = React.createRef();

    componentDidMount() {
        this.props.setActions(this.onFormSubmit);
    }

    componentDidUpdate() {
        const { setToDefault, setActions } = this.props;
        if (setToDefault) {
            this.setState({ layerForm: this.state.initialForm, formChanged: false });
            setActions && setActions(null);
        }
    }

    @bind
    @memoize()
    drawingList() {
        const { layer } = this.props;
        const features = layer && layer.getSource().getFeatures();
        return (features || []).map((feature, index) => {
            const { name, image } = feature.get('attributes') || {};
            return (<ListItem key={index}
                component={image && <ImageComponent src={image}/>}
                title={name || `drawing ${index}`}
                actions={<MdiIcon name={'dots-vertical'} />}
            />);
        });
    }

    @bind
    @memoize()
    buildFormDefinition(data: Object) {
        const { layer } = this.props;
        const features = layer?.getSource()?.getFeatures() || [];
        return [
            ...commonAbout({ ...data, title: this.props.title, features })
        ].filter(Boolean);
    }

    @bind
    handleChange(data: Object, { name, value }: Object) {
        this.setState({ layerForm: data }, async () => {
            const { initialForm, formChanged } = this.state;
            if (!formChanged && !shallowEquals(value, initialForm[name])) {
                this.setState({ formChanged: true });
                await this.props.setStatus(true);
                this.props.setActions(this.onFormSubmit);
            } else if (formChanged && shallowEquals(value, initialForm[name])) {
                this.setState({ formChanged: false });
                await this.props.setStatus(false);
                this.props.setActions(null);
            }
        });
    }

    @bind
    onFormSubmit() {
        this.formRef.current.isValidForm().then(async ({ data, errors }) => {
            if (!errors) {
                const { id, details, allMapLayers } = this.props;
                const { description } = data;
                const { type } = details || '';
                const foundLayer = allMapLayers.find(layer => layer.id === id);

                const updatedLayerData = { 
                    id,
                    name: foundLayer?.name,
                    type: LAYERS[type],
                    description,
                    active: true,
                    visible: foundLayer?.visible || true,
                    opacity: foundLayer?.opacity || 100,
                };

                this.props.updateMapLayer(updatedLayerData);
                this.setState({ initialForm: { data }, formChanged: false });
                this.props.setActions(null);
                this.props.setStatus(false);
            }
        });
    }

    render() {
        const { layerForm } = this.state;
        return (
            <FormGenerator
                data={layerForm}
                onChange={this.handleChange}
                components={this.buildFormDefinition(layerForm)}
                ref={this.formRef}
            />
        );
    }
}
export default connect(
    (state: Object, ownProps: Object) => ({
        allMapLayers: __getMapLayers(state.maps.situationalAwareness.map.data),
    }),
    {
        updateMapLayer
    }
)(DrawingLayerAbout);