/* @flow */

import { gql } from '@apollo/client';

export default gql`
query processSubTasksQuery($startIndex: Int, $stopIndex: Int, $filterBy: [JSON], $orderBy: [JSON]) {
    result: tasks(startIndex: $startIndex, stopIndex: $stopIndex, filterBy: $filterBy, orderBy: $orderBy) {
        id
        name
        primary
        description
        createdDate
        modifiedDate
        assignee {
            id
            name
            image
        }
    }
}
`;
