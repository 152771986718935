/* @flow */

import React, { Fragment, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Menu, MenuItem, IconButton, MdiIcon, Button } from '@mic3/platform-ui';
import { useToggle } from 'app/utils/hook/hooks';
import { withStyles } from '@material-ui/core/styles';

const StyledMdiIcon = styled(MdiIcon)`
    &.MuiIcon-root:before{
        color: ${({theme})=> theme.material.colors.appNav.linkIconColor};
    }
`;

const FiltersActions  = ({ onSave, PaperProps, classes, sidebarIsOpen, onClose, onApply, onReset, onLoadSaved, anchor, disabledButton }) => {
    const anchorEl = useRef();
    const [isOpen, toggleMenu, setIsOpen] = useToggle();
    useEffect(() => {
        if(!sidebarIsOpen) {
            setIsOpen(false);
        }
    }, [sidebarIsOpen, setIsOpen]);
    const handleOnReset = useCallback((e) => {
        onReset(e);
        setIsOpen(false);
    }, [onReset, setIsOpen]);
    const handleOnSave = useCallback((e) => {
        onSave();
        setIsOpen(false);
    }, [onSave, setIsOpen]);
    const handleOnLoadSaved = useCallback((e) => {
        onLoadSaved(e);
        setIsOpen(false);
    }, [onLoadSaved, setIsOpen]);

    return (
        <Fragment>
            <Button disabled={disabledButton}  className={classes.applyButton} onClick={onApply} variant="text">
                Apply
            </Button>
            <Button disabled={disabledButton} className={classes.applyButton} onClick={handleOnSave} variant="text">
                Save
            </Button>
            <IconButton buttonRef={anchorEl} onClick={toggleMenu}><MdiIcon name="dots-vertical" /></IconButton>
            <Menu open={isOpen} anchorEl={anchorEl.current} onClose={toggleMenu} >
                <MenuItem onClick={handleOnLoadSaved}><StyledMdiIcon className={classes.menuIcon} name="content-save" />Load saved</MenuItem>
                <MenuItem onClick={handleOnReset}><StyledMdiIcon className={classes.menuIcon} name="refresh"/>Reset to default</MenuItem>
            </Menu>
        </Fragment>
    );
};

FiltersActions.propTypes = {
    classes: PropTypes.object.isRequired,
    onApply: PropTypes.func,
    onClose: PropTypes.func,
    open: PropTypes.bool,
};

const styles = theme => ({
    applyButton: {
        marginRight: '8px',
    },
    menuIcon: {
        marginRight: '1rem',
        color: 'rgba(255, 255, 255, 0.6)',
    },
});

export default connect(
    (state, props) => ({
        sidebarIsOpen: state.filterbar.isOpen,
        disabledButton: state.filteractions.disabledButton
    }),
    {},
)(withStyles(styles)(FiltersActions));
