/* @flow */

import { gql } from '@apollo/client';
import loadTeamDetailCommonQuery from 'graphql/teams/loadTeamDetailCommonQuery';

export default gql`
    mutation updateTeamMutation($record: TeamUpdateInput!) {
        result: updateTeam(record: $record) {
            ${loadTeamDetailCommonQuery}
        }
    }
`;
