// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import { bind } from 'app/utils/decorators/decoratorUtils';
import { Button, Grid } from '@mic3/platform-ui';
import { closeSidebar } from 'store/actions/sidebar/sidebarActions';
import Loader from 'app/components/atoms/Loader/Loader';

import { removeCreatePinInteraction, removeLayer } from 'store/actions/maps/situationalAwarenessActions';
import { __getMapLayers } from 'app/utils/maps/layer/layerUtils';
import { saveComponentState } from 'store/actions/component/componentActions';

const DeleteButton = styled(Button)`
    &.MuiButton-textPrimary {
        color: ${({ theme }) => theme.material.colors.danger} !important;
    }
`;

const StyledGrid = styled(Grid)`
    display: flex;
    color: ${({ color, theme }) => (theme && color ? 'white' : theme.base.textColor)};
`;

const StyledLayerTitle = styled.div`
    color: ${({ theme }) => theme.material.colors.white} !important;
    margin-left: 4px;
`;

class RemoveLayerModal extends PureComponent<Object, Object> {
    static propTypes = {
        deleteRelationship: PropTypes.func.isRequired,
        map: PropTypes.object.isRequired,
        mapId: PropTypes.string.isRequired,
    };

    state = {
        isRemoving: false,
    };

    @bind
    async onLayerRemove({ id, title }) {
        this.setState({ isRemoving: true });
        const { map, layer: mapLayer, sidebarTitle } = this.props;
        if (!mapLayer?.name) {
            return;
        }
        const layer = map?.findLayerById(mapLayer?.id);
        if(layer?.getSource) {
            const layerSource = layer.getSource();
            layerSource?.clear && await layerSource.clear();
        }
        layer && layer.unset('source');
        map.removeRelatedmapLayer(layer?.values_?.attributes?.id);
        const mapInstance = map.getMap();
        const entityLayer = map.entityLayers[layer?.values_?.name]?.getLayer?.();
        if(mapInstance.removeLayer) {
            await mapInstance.removeLayer(layer);
            entityLayer && mapInstance.removeLayer(entityLayer);
        }
        mapInstance && await mapInstance.updateSize();
        if (layer) {
            this.props.removeCreatePinInteraction();
            this.props.removeLayer(mapLayer);
        }
        this.setState({ isRemoving: false }, () => {
            this.props.closeModal();
            sidebarTitle !== 'Layers' && this.props.closeSidebar(); // If layers list sidebar then don't close the sidebar
        });
        map && map.removeHightLightFeatures();
    }

    render() {
        const { layer, allMapLayers } = this.props;
        const foundLayer = allMapLayers?.find((lyr) => lyr?.id === layer?.id);
        const { isRemoving } = this.state;
        return (
            <ModalDialog withoutClose={true} title={'Remove Layer'} maxWidth={'sm'}>
                <StyledGrid>
                    {`Are you sure you want to delete layer `}
                    <StyledLayerTitle> {foundLayer?.name || foundLayer?.title} </StyledLayerTitle> {'?'}
                </StyledGrid>
                <StyledGrid>{'This action cannot be undone.'}</StyledGrid>
                <Grid item container xs={12} justify='flex-end' alignItems='flex-end' alignContent='flex-end'>
                    <Button onClick={this.props.closeModal} variant='text'>
                        CANCEL
                    </Button>
                    <DeleteButton onClick={this.onLayerRemove} variant='text' disabled={isRemoving}>
                        {isRemoving ? <Loader size={20} /> : 'DELETE'}
                    </DeleteButton>
                </Grid>
            </ModalDialog>
        );
    }
}

export default connect(
    (state) => ({
        allMapLayers: __getMapLayers(state.maps.situationalAwareness.map.data),
        sidebarTitle: state.sidebar.title,
    }),
    {
        closeSidebar,
        removeCreatePinInteraction,
        saveComponentState,
        removeLayer
    }
)(RemoveLayerModal);
