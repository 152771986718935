/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';
import BpmnModeler from 'bpmn-js/lib/Modeler';
import { DOMParser } from '@xmldom/xmldom';
import { Chip, Avatar, Divider, IconButton, Tooltip } from '@mic3/platform-ui';
import { isMobile } from 'react-device-detect';

import DotMenu from 'app/components/molecules/DotMenu/DotMenu';
import Button from 'app/components/atoms/Designer/Button';
import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import ProcessDesignerDiagram from 'app/containers/Designer/Processes/Tabs/ProcessDesignerDiagram';
import ProcessDesignerXML from 'app/containers/Designer/Processes/Tabs/ProcessDesignerXML';
import Loader from 'app/components/atoms/Loader/Loader';
import PageTemplate from 'app/components/templates/PageTemplate';
import history from 'store/History';
import Ellipsis from 'app/components/molecules/Ellipsis/Ellipsis';
import Icon from 'app/components/atoms/Icon/Icon';
import DownloadProcess from 'app/components/Designer/Modals/DownloadProcess';
import { saveComponentState } from 'store/actions/component/componentActions';
import { loadProcessDefinition, updateProcessDefinition, publishProcessDefinition } from 'store/actions/designer/designerProcessActions';
import { setEntityPreviewVersion } from 'store/actions/entities/entitiesActions';
import { get, pick } from 'app/utils/lo/lo';
import { getStr, isEmpty, isDefined } from 'app/utils/utils';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { openEntitySidebar } from 'store/actions/entities/entitySidebarActions';
import Breadcrumbs from 'app/components/organisms/Breadcrumbs/Breadcrumbs';
import PageNotAllowed from 'app/containers/ErrorPages/PageNotAllowed';
import { typeTitlesMultiple, redirectTypes } from 'app/config/typesConfig';
import { readTextFile, readCSVFile } from 'app/utils/datatable/datatableUtils';
import { modelerProps } from 'app/containers/Designer/Processes/Tabs/ProcessDesignerDiagram';
import { setDocumentTitle, showToastr } from 'store/actions/app/appActions';
import { getPermissions } from 'app/config/rolesConfig';
import { loadEntityWorkspaces } from 'store/actions/entities/entitiesActions';
import { getAttachmentUrl } from 'app/utils/attachments/attachmentsUtils';
import { exportSvg, isXml } from 'app/utils/designer/process/processDesignerUtils';
import { loadEntityVersions } from 'store/actions/entities/entitiesActions';
import { buildDotMenu } from 'app/utils/entity/entityUtils';
import { getSelectedPrimaryClass } from 'app/utils/classification/classificationUtils';

const AvatarStyled = styled(Avatar)`
    width: 30px !important;
    height: 30px !important;
    margin-right: 8px;
    font-size: 1rem !important;
`;

const DividerverticalStyled = styled(Divider)`
    margin: 4px 16px !important;
    height: 24px !important;
    align-self: center !important;
    width: 1px;
    background-color: ${({ theme }) => theme.material.colors.background.divider} !important;
`;

const ButtonPublishStyled = styled(Button)`
  min-width: 80px !important;
`;

const MoreChipStyled = styled(Chip)`
    background:  ${({ theme }) => theme.material.colors.background.fields} !important;
    & .MuiChip-label {
        max-width: 140px;
    }
`;

const parseImportedDefinition = (definition) => (definition || '').replaceAll(/affectli:formVersionId="([a-z-A-Z0-9-]+)"/gm,''); 

class ProcessDesigner extends PureComponent<Object, Object> {

    static propTypes = {
        match: PropTypes.object.isRequired,
        process: PropTypes.object,
        variables: PropTypes.object,
        processDesignerState: PropTypes.object,
        loadProcessDefinition: PropTypes.func.isRequired,
        updateProcessDefinition: PropTypes.func.isRequired,
        saveComponentState: PropTypes.func.isRequired,
    };

    diagramRef = React.createRef();

    state = {
        isSaving: false,
        isOpenDownloadModal: false,
        editorKey: 0,
        errors: {}
    };

    constructor(props) {
        super(props);
        const id = get(props, 'match.params.id');
        id && props.loadProcessDefinition(id);
        this.reloadSharing();
    }

    componentDidUpdate(prevProps) {
        const prevId = get(prevProps, 'match.params.id');
        const id = get(this.props, 'match.params.id');
        if (id !== prevId) {
            id && this.props.loadProcessDefinition(id);
            this.reloadSharing();
            this.setState({ errors: {} });
        }
        
        const { process, draftedDetails, previewVersion, setDocumentTitle } = this.props;
        const name = process?.name;
        const prevName = prevProps?.process?.name;
        if(name && name !== prevName){
            setDocumentTitle(name);
        }
        if (draftedDetails !== prevProps.draftedDetails) {
            const versionXml = parseImportedDefinition(draftedDetails.primary.definition);
            this.saveEditorState({
                ...process,
                primary: {
                    ...process.primary,
                    definition: versionXml,
                    hidden: draftedDetails.primary.hidden
                },
                ...pick(draftedDetails, ['name', 'description', 'iconName', 'iconColor', 'iconType', 'active'])
            });
            this.setState({ errors: {} });
        }

        if (previewVersion && previewVersion !== prevProps.previewVersion) {
            this.saveEditorState(previewVersion, true);
        }

        if (process && process !== prevProps.process) {
            this.saveProcessState(process);
            this.setState({ errors: {} });
        }
    }

    componentWillUnmount() {
        this.props.setEntityPreviewVersion('processdefinition', null);
    }

    @bind
    reloadSharing() {
        const { loadEntityWorkspaces } = this.props;
        const id = get(this.props, 'match.params.id');
        loadEntityWorkspaces('processdefinition', id);
    }

    @bind
    validate(definition) {
        const errors = [];
        if(!isDefined(definition)){
            return errors; 
        }
        const doc = new DOMParser().parseFromString(definition);
        const startEventElements = doc.getElementsByTagName('startEvent');
        if(startEventElements) {
            Array.from(startEventElements).forEach((startEvent) => {
                const startEventString = String(startEvent);
                if(
                    !/affectli:formId="([\S\s]+)[\w]{8}"\s/.test(startEventString) 
                    && !(
                        startEventString.includes(['messageEventDefinition'])
                        || startEventString.includes(['timerEventDefinition'])
                        || startEventString.includes(['signalEventDefinition'])
                    )) {
                    errors.push(`The startState "${startEvent.getAttribute('id')}" does not contains the form reference.`);
                }
            });
        }
        const userTaskElements = doc.getElementsByTagName('userTask');
        if(userTaskElements) {
            Array.from(userTaskElements).forEach((userTask) => {
                const userTaskString = String(userTask);
                if(!/affectli:formId="([\S\s]+)[\w]{8}"\s/.test(userTaskString)) {
                    errors.push(`The userTask "${userTask.getAttribute('id')}" does not contains the form reference.`);
                }
            });
        }
        const serviceTasksElements = doc.getElementsByTagName('serviceTask');
        if(serviceTasksElements) {
            Array.from(serviceTasksElements).forEach((serviceTask) => {
                const task = String(serviceTask);
                if(!/scriptId"([\S\s]+)[\w]{8}-[\w]{4}-[\w]{4}-[\w]{4}-[\w]{12}/.test(task)) {
                    errors.push(`The script/service task ${serviceTask.getAttribute('id')} does not contains the script.`);
                }
            });
        }
        const scriptTasksElements = doc.getElementsByTagName('scriptTask');
        if(scriptTasksElements) {
            Array.from(scriptTasksElements).forEach((scriptTask) => {
                const task = String(scriptTask);
                if(!/<script>([\S\s]+)<\/script>/.test(task)) {
                    errors.push(`The script/service task ${scriptTask.getAttribute('id')} does not contains the script.`);
                }
            });
        }
        return errors;
    }

    @bind
    onSave(newVersion: ?boolean, isXmlTab = false) {
        this.setState({ isSaving: true }, async () => {
            const { processDesignerState, showToastr, loadEntityVersions, sidebarTitle, process: { id: processId, type: processType } } = this.props;
            const { role, svg, __typename, createdBy, createdDate, ...process } = get(processDesignerState, 'editor') || {};

            const svgString = !isXmlTab ? await exportSvg() : svg;

            const newProcess = {
                ...process, 
                name: process?.name.trim(), 
                svg: svgString, 
                id: processId, 
                type: processType
            };
            if(newVersion) {
                const errors = this.validate(newProcess.primary.definition);
                if(errors.length) {
                    this.setState({ isSaving: false });
                    return errors.forEach((err) => {
                        showToastr({ severity: 'error', detail: err });
                    });
                }    
            }
            
            const result = await this.props.updateProcessDefinition(newProcess, newVersion);
            
            if (newVersion) {
                await this.props.publishProcessDefinition(newProcess.id);
                if(sidebarTitle === 'Versions') {
                    loadEntityVersions(newProcess.id, 'processdefinition');
                }
            }
            
            if (result.name !== 'httpError') {
                await this.props.loadProcessDefinition(newProcess.id);
            }
            this.setState({ isSaving: false });
        });
    };

    @bind
    saveEditorState(stateUpdate: Object, isPreview) {
        const editorState = get(this.props, 'processDesignerState.editor') || {};
        const draftedEditorState = get(this.props, 'processDesignerState.draftedEditor', null);
        const editor = {
            ...editorState,
            ...stateUpdate,
        };
        const updatedState = { editor, drafted: true };
        if(isPreview && !draftedEditorState) {
            updatedState.draftedEditor = editorState;
            updatedState.isPreviewVersion = true;
        } else if(!isPreview) {
            updatedState.draftedEditor = null;
            updatedState.isPreviewVersion = false;
        }
        this.props.saveComponentState('ProcessDesigner', updatedState);
    };

    @bind
    saveProcessState(process) {
        this.props.saveComponentState('ProcessDesigner', { 
            editor: process, 
            drafted: false,
            draftedEditor: null,
            isPreviewVersion: false,
        });
    };

    @bind
    backToSaveDraft() {
        const editor = get(this.props, 'processDesignerState.draftedEditor') || {};
        const updatedState = { editor, draftedEditor: null, isPreviewVersion: false, };
        this.props.saveComponentState('ProcessDesigner', updatedState);
        this.props.setEntityPreviewVersion('processdefinition', null);
    };

    @bind
    onDotMenuClick(title) {
        const { openEntitySidebar } = this.props;
        const id = getStr(this.props, 'match.params.id') || '';

        if (title === 'Download process definition') {
            return this.setState({ isOpenDownloadModal: true });
        }

        if(title === 'XML' || title === 'Diagram'){
            const path = {
                'XML': 'xml',
                'Diagram': 'diagram',
            }[title];
            history.push(`/designer/processes/${id}/${path}`);
        }
        
        openEntitySidebar({ title, id, type: 'processdefinition' });
    }

    @bind
    handleCloseDownloadModal() {
        this.setState({ isOpenDownloadModal: false });
    }

    @bind
    async uploadDefinition(file) {
        const { showToastr, location } = this.props;
        const definition = await readTextFile(file);
        try {
            if (!isXml(definition)) {
                throw new Error('File format is wrong.'); 
            }
            const isDesignerTab = location.pathname.includes('/diagram');
            if (isDesignerTab) {
                const modeler = new BpmnModeler(modelerProps);
                await modeler.importXML(definition);
            }
            await this.saveEditorState({ primary: { definition }});
            this.setState(({ editorKey }) => ({ editorKey: editorKey + 1 }));

        } catch(err) {
            const { warnings, message } = err;
            console.error('something went wrong:', warnings, message); //eslint-disable-line no-console
            showToastr({ severity: 'error', detail: warnings || message });
        }
    }

    @bind
    async uploadVersion(file) {
        const { showToastr } = this.props;
        const versionFile = await readCSVFile(file);

        try {
            const { 
                primary: { definition }, enableGis,
                name, description, iconName, iconColor, iconType 
            } = versionFile[0];
            
            const isDesignerTab = location.pathname.includes('/diagram');
            if (isDesignerTab) {
                const modeler = new BpmnModeler(modelerProps);
                await modeler.importXML(definition);
            }

            await this.saveEditorState({ 
                primary: { definition: parseImportedDefinition(definition) }, name, description, 
                iconName, iconColor, iconType, enableGis
            });
            this.setState(({ editorKey }) => ({ editorKey: editorKey + 1 }));

        } catch(err) {
            const { warnings, message } = err;
            console.error('something went wrong:', warnings, message); //eslint-disable-line no-console
            showToastr({ severity: 'error', detail: 'File format is wrong.' });
        }
    }

    @bind
    @memoize()
    dotMenu(location: string, process: object, canEdit, primaryClass: object, title: string) {
        const { pathname } = location;
        const menu = buildDotMenu({ title, details : {type : 'processdefinition', primaryClass, ...process} });
        const moreMenus = [
            { name: 'divider' },
            !pathname.includes('diagram') && { name: 'Diagram', icon: 'blur' },
            !pathname.includes('xml') && { name: 'XML', icon: 'xml' },
            title !== 'Versions' && { name: 'Versions', icon: 'content-save-all' },
            { name: 'divider' },
            { name: 'Download process definition', icon: 'download' },
            canEdit && { name: 'file', label: 'Import XML', onSelect: this.uploadDefinition },
            { name: 'divider' },
            canEdit && { name: 'file', accept: '.csv', label: 'Import Version', onSelect: this.uploadVersion },
        ].filter(Boolean);
        return (
            <DotMenu
                key={13}
                onItemClick={this.onDotMenuClick}
                tooltipTitle='More Options'
                items={[...menu, ...moreMenus]}
            />
        );
    }

    @bind
    @memoize()
    renderActions(sidebarTitle, canEdit, sharingDetails) {
        const { users = [], teams = [] } = sharingDetails || {};
        return (
            <>
                <Ellipsis
                    data={[...users, ...teams]}
                    spaces={-14}
                    displaySize={isMobile ? 3 : 5}
                    renderCount={count => (
                        <MoreChipStyled
                            variant="default"
                            label={`+${count}`}
                            size="small"
                            onClick={() => this.onDotMenuClick('Sharing')}
                        />
                    )}
                    renderItem={
                        ({ name, image, id }: Object) => (
                            <AvatarStyled onClick={() => this.onDotMenuClick('Sharing')} src={image ? getAttachmentUrl(id, 'user', image): null} initials={name} />
                        )
                    }
                />
                <DividerverticalStyled />
                <Tooltip title="Sharing">
                    <IconButton onClick={() => this.onDotMenuClick('Sharing')}><Icon name="share-variant" /></IconButton>
                </Tooltip>
                {/* <IconButton onClick={() => this.onDotMenuClick('About')}><Icon hexColor="rgb(255,255,255)" name="information-outline" /></IconButton> */}
            </>
        );
    }

    @bind
    errorsState(errors, elementId) {
        this.setState({ errors: { ...(this.state.errors || {}), [elementId]: errors } });
    }

    @bind
    isErrored(errors) {
        let isErrored = false;
        if(errors) {
            Object.keys(errors).forEach((key) => {
                if(!isErrored) {
                    isErrored = !!errors[key];
                }
            });
        }
        return isErrored;
    }

    @bind
    @memoize()
    renderSecondaryActions(canEdit, isXmlTab = false, isPreviewVersion = false, process, errors) {
        return isPreviewVersion ? (
            <Button onClick={this.backToSaveDraft}>back to saved draft</Button>
        ) : (
            <>
                {canEdit && (
                    <Button
                        disabled={!process?.primary?.definition}
                        key={14}
                        color="primary"
                        onClick={() => this.onSave(false, isXmlTab)}
                        confirmationModalProps={{
                            message: 'Are you sure you want to save this version?',
                            declineButtonText: 'Cancel',
                            confirmButtonText: 'Save',
                        }}
                        withConfirmation
                    >
                    Save
                    </Button>
                )}
                {canEdit && (
                    <ButtonPublishStyled
                        disabled={!process?.primary?.definition || this.isErrored(errors)}
                        key={15}
                        color="primary"
                        onClick={() => this.onSave(true, isXmlTab)}
                        confirmationModalProps={{
                            message: 'Are you sure you want to publish this process? This will create a new version',
                            declineButtonText: 'Cancel',
                            confirmButtonText: 'Publish',
                        }}
                        withConfirmation
                    >
                    Publish
                    </ButtonPublishStyled>
                )}
            </>
        );
    }

    @bind
    @memoize()
    buildBreadcrumbs(name, isPreviewVersion, previewVersion, drafted) {
        return (
            <Breadcrumbs
                list={[{ link: `/${redirectTypes['processdefinition']}`, title: typeTitlesMultiple['processdefinition'] }, { title: name }]}
                withGoBack
                labelAtEnd={this.buildChipsVersion(isPreviewVersion, previewVersion, drafted)}
            />
        );
    }

    @bind
    @memoize()
    buildTabs(match, location, type) {
        return [
            { active: location.pathname.includes('/diagram'), label: 'Process Diagram', link: `${match.url}/diagram` },
            { active: location.pathname.includes('/xml'), label: 'XML', link: `${match.url}/xml` },
        ];
    }

    @bind
    @memoize()
    buildChipsVersion(isPreviewVersion, previewVersion, drafted) {
        if(previewVersion && isPreviewVersion) {
            return `Version ${previewVersion.primary.version} - READ ONLY`;
        }
        if(drafted) {
            return 'draft';

        }
        return null;
    }

    render() {
        const { previewVersion, isLoading, processDesignerState, location, sidebarTitle, sharingDetails, match, draftedDetails } = this.props;
        const { isSaving, editorKey, isOpenDownloadModal, errors } = this.state;
        const { editor: process, drafted, isPreviewVersion } = processDesignerState || {};
        const id = getStr(this.props, 'match.params.id') || '';
        let content = <Loader absolute backdrop />;
        const permissions = getPermissions(process && process.role);
        const selectedClass = getSelectedPrimaryClass('processdefinition', this.props.primaryClasses);
        const { pathname } = location;
        if (!isLoading && (isEmpty(process) || !permissions.canView)) {
            return (
                <PageNotAllowed permissionError={true} title={`Process definition (ID:${id})`}/>
            );
        }
        
        if (!process) {
            content = (
                <h2 style={{padding: '10px 20px'}}>Process not found.</h2>
            );
        } else {
            content = (
                <Switch key={editorKey}>
                    <Route path={`/designer/processes/:id`} exact render={
                        () => <Redirect to={`/designer/processes/${id}/diagram`}/>
                    }/>
                    <Route path={`/designer/processes/:id/diagram`} render={() =>
                        <ProcessDesignerDiagram
                            ref={this.diagramRef}
                            process={process}
                            saveEditorState={this.saveEditorState}
                            onSave={this.onSave}
                            errorsState={this.errorsState}
                            isPreviewVersion={!permissions.canEdit || !!isPreviewVersion}                            
                            actions={this.renderActions(sidebarTitle, permissions.canEdit, sharingDetails)}
                            secondaryActions={this.renderSecondaryActions(permissions.canEdit, false, isPreviewVersion, process, errors)}
                            breadcrumbLine={this.buildBreadcrumbs(process.name, isPreviewVersion, previewVersion, drafted)}
                            afterRightButton={this.dotMenu(location, process, permissions.canEdit, selectedClass, sidebarTitle)}
                            tabs={this.buildTabs(match, location)}
                            draftedDetails={draftedDetails}
                        />
                    }
                    />
                    <Route path={`/designer/processes/:id/xml`} render={() => (
                        <ProcessDesignerXML
                            canEdit={permissions.canEdit}
                            actions={this.renderActions(sidebarTitle, permissions.canEdit, sharingDetails)}
                            secondaryActions={this.renderSecondaryActions(permissions.canEdit, true, isPreviewVersion, process)}
                            breadcrumbLine={this.buildBreadcrumbs(process.name, isPreviewVersion, previewVersion, drafted)}
                            afterRightButton={this.dotMenu(location, process, permissions.canEdit, selectedClass, sidebarTitle)}
                            tabs={this.buildTabs(match, location)}
                            process={process}
                            saveEditorState={this.saveEditorState}
                            readOnly={!!isPreviewVersion}
                        />
                    )} />
                    <Route path={`/designer/processes/:id/versions`} render={() => <p>Will be soon...</p>} />
                </Switch>
            );
        }
        return (
            <PageTemplate title={get(process, 'name')} subTitle={`#${id}`}>
                <ContentArea withHeader>
                    {(isSaving || isLoading) && <Loader absolute backdrop />}
                    {content}
                    {isOpenDownloadModal && 
                    <DownloadProcess  
                        pathname={pathname}
                        onClose={this.handleCloseDownloadModal} 
                        processDesignerState={processDesignerState} 
                    />}
                </ContentArea>
            </PageTemplate>
        );
    }
}

export default connect(
    state => ({
        sharingDetails: state.entities.sidebar.workspaces.data,
        sharingisLoading: state.entities.sidebar.workspaces.isLoading,
        sidebarTitle: state.sidebar.title,
        isLoading: state.designer.process.isLoading,
        process: state.designer.process.data,
        processDesignerState: state.component.state.ProcessDesigner,
        profile: state.user.profile,
        draftedDetails: state.entities.common.draftedDetails['processdefinition'],
        previewVersion: state.entities.common.previewVersion['processdefinition'],
        primaryClasses: state.app.allPrimaryClasses.records || [],
    }),
    {
        loadProcessDefinition,
        updateProcessDefinition,
        saveComponentState,
        publishProcessDefinition,
        openEntitySidebar,
        showToastr,
        loadEntityWorkspaces,
        setEntityPreviewVersion,
        loadEntityVersions,
        setDocumentTitle,
    }
)(withTheme(ProcessDesigner, 'processdefinition'));
