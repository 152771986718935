/* @flow */

import React, { PureComponent } from 'react';
import { Switch, Route } from 'react-router-dom';

import IdentityProvidersMain from 'app/containers/Admin/Settings/IdentityProviders/IdentityProvidersMain';
import IdentityProviderDetails from 'app/containers/Admin/Settings/IdentityProviders/IdentityProviderDetails';

/**
 * Defines the routes for the Classification views
 */
class IdentityProvidersRoute extends PureComponent<Object, Object> {
 
    render() {
        const { match } = this.props;
        return (
            <Switch>
                <Route path={`${match.url}`} exact component={IdentityProvidersMain} />
                <Route path={`${match.url}/:alias`} exact component={IdentityProviderDetails} />
            </Switch>
        );
    }
}

export default IdentityProvidersRoute;
