/* @flow */
import greenlet from 'greenlet';

import { set, omit } from 'app/utils/lo/lo';
// import { isDefined } from 'app/utils/utils';
import affectliSso from 'app/auth/affectliSso';
// import { fillPropertiesByType } from 'app/utils/designer/form/fieldUtils';
// import { getFieldSettingPanelComponents } from 'app/utils/designer/form/settings/formFieldSettingsUtils';

const _eval = (js) => {
    try {
        return eval(js); // eslint-disable-line no-eval
    } catch (e) {
        return null;
    }
};

const normalize = (field) => {
    let normalized = field;
    switch (field?.type) {
        case 'typeahead':
            if (field.properties.staticOptions) {
                normalized = set(normalized, 'properties.staticOptions', JSON.parse(field.properties.staticOptions));
            }
            break;
        case 'textarea': {
            const { parseAs, value } = field.properties;
            if (parseAs === 'javascript') {
                normalized = set(normalized, 'properties.value', _eval(value));
            } else if (parseAs === 'HTML')  {
                const div = document.createElement('div');
                div.innerHTML = value;
                normalized = set(normalized, 'properties.value', div);
            }
            break;
        }
        default:
    }

    if (field?.properties?.help) {
        const div = document.createElement('div');
        div.innerHTML = field.properties.help;
        normalized = set(normalized, 'properties.help', div);
    }

    if (field?.children) {
        normalized = set(normalized, 'children', field.children.map(normalize));
    }
    return normalized;
};

export const normalizeFields = (fields: ?Array<any>): ?Array<any> =>
    fields && fields.map((field) => {
        try {
            return normalize(field);
        } catch (e) {
            console.err('invalid form field', field); // eslint-disable-line no-console
            return null;
        }
    });

const _stringify = (field) => {
    const { defaults, uuid, ...restFieldProps } = field;
    let stringifiable = { ...restFieldProps };
    stringifiable.properties = omit(stringifiable.properties, ['withDefault', 'children', 'InputProps']);

    // let nextField = {};
    // getFieldSettingPanelComponents(field.type, {}).forEach((panel) => {
    //     panel.children.forEach((property, i) => {
    //         if(isDefined(get(field, property.properties.name))) {
    //             nextField = set(nextField, property.properties.name, get(field, property.properties.name));
    //         }
    //     });
    // });

    switch (field?.type) {
        case 'typeahead':
            if (field.properties.fetchData) {
                stringifiable = set(stringifiable, 'properties.fetchData', String(field.properties.fetchData));
            }
            if (field.properties.staticOptions) {
                stringifiable = set(stringifiable, 'properties.staticOptions', String(field.properties.staticOptions));
            }
            break;
        case 'textarea': {
            const { parseAs, value } = field.properties;
            if (parseAs === 'javascript') {
                stringifiable = set(stringifiable, 'properties.value', String(value));
            } else if (parseAs === 'HTML')  {
                stringifiable = set(stringifiable, 'properties.value', value?.innerHtml);
            }
            break;
        }
        default:
    }

    if (field?.properties?.help) {
        stringifiable = set(stringifiable, 'properties.help', field.properties.help.innerHTML ?? field.properties.help);
    }

    if (field?.children) {
        stringifiable.children = field.children.map(_stringify);
    }
    return stringifiable;
};
const normalizingProps = field => field = field.type === 'panel'? 
    {...field, properties:{...field.properties, header: field.properties.header.trim()}}
    : field;

const stringify = field => _stringify(field);

export const denormalizeFields = (fields: ?Array<any>): ?Array<any> =>
    fields && fields.map((field) => {
        try {
            return field &&  normalizingProps(stringify(field));
        } catch (e) {
            console.warn('cannot denormalize field', field, e); // eslint-disable-line no-console
            return null;
        }
    }).filter(Boolean);

export const enrichContext = (context) => {
    return {
        ...(context || {}),
        sso: affectliSso.getTokens(),
        location: {
            origin: window.location.origin,
            href: window.location.href,
        },
        graphql: `
            async (body, options={}) => {
              const response = await fetch('${window.location.origin}/graphql', {
                ...options,
                body: typeof body === 'object' ? JSON.stringify(body) : body,
                headers:{
                    authorization: 'Bearer _token',
                    'content-type':'application/json',
                    ...(options.headers || {})
                },
                method:'POST'
              });
              let json = null;
              try {
                 json = await response.json();
              } catch (e) {
                 throw new Error(response.statusText);
              }
              if (!response.ok) {
                  console.log('$$$ json.errors', json.errors);
                  const message = json.errors && json.errors.map(item => item.message).join('\\n');
                  throw new Error(message || response.statusText);
              }
              return json && json.data;
            }
        `
    };
};

/**
 * Prepere the function definition to run it in our worker (app/utils/worker/worker).
 *
 * @param fnDefinition (string) the function to run in the service worker
 * @param isEventHandler (boolean) if true the function will expect 3 parameters:
 *                       * event
 *                       * data
 *                       * context
 *                       otherwise it will recieve just 2 parameters:
 *                       * data
 *                       * context
 */
export const prepareFunction =
    async (fnDefinition: string, isEventHandler: boolean) => {
        const params = isEventHandler ? 'event, data, context, inherited' : 'data, context, inherited';
        await affectliSso.updateToken(5 /* minValidity in seconds */);
        const token = affectliSso.getToken();
        return `(${params}) => {
          const graphql = context.graphql.replace('_token', '${token}');
          context.sso.token = '${token}';
          context.graphql = eval(graphql);
          return (${fnDefinition.trim().replace(/;$/, '')})(${params});
        }`;
    };


/**
 * Prepere the function to run it in a service worker.
 *
 * @param fnDefinition (string) the function to run in the service worker
 * @param isEventHandler (boolean) if true the function will expect 3 parameters:
 *                       * event
 *                       * data
 *                       * context
 *                       otherwise it will recieve just 2 parameters:
 *                       * data
 *                       * context
 */
export const workerifyFunction =
    (fnDefinition: string, isEventHandler: boolean) => {
        const params = isEventHandler ? 'event, data, context' : 'data, context';

        // the user function is defined in the editor and MUST be executed in a service worker
        return greenlet(`(${params}) => {
          context.graphql = eval(context.graphql);
          try {
            return (${fnDefinition.replace(/;$/, '')})(${params});
          } catch(e) {
            console.error('an error occured executing the user defined function', ${fnDefinition}, e);
            throw new Error('an error occured executing the user defined function.');
          }
        }`);
    };

export const buildAfterAction = (props) => {
    const type = props.afterActionType;
    switch(type) {
        case 'function': {
            return props.afterActionFunction;
        }
        case 'internal': {
            return !props.afterActionTo ? null : () => ({ action: 'navigate', options: { href: props.afterActionTo } });
        }
        case 'external': {
            return !props.afterActionHref ? null : () => ({ action: 'link', options: { href: props.afterActionHref } });
        }
        default:
            return null;
    }
};

export const injectFieldProperty = (arr, propertyName, propertyValue) => {
    if(!arr?.length || !propertyName) return [];
    const newArr = arr.map((item) => {
        const newItem = { ...item };
        if (newItem.properties) {
            newItem.properties = { ...newItem.properties, [propertyName]: propertyValue }; 
        }
        if (newItem.children?.length) {
            newItem.children = injectFieldProperty(newItem.children, propertyName, propertyValue); // Recursively modify children
        }
        return newItem;
    });
    return newArr;
};