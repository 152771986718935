/* @flow */

import React, { useCallback, useEffect, useState } from 'react';
import { 
    MdiIcon,
    Paper,
    ClickAwayListener,
    IconButton,
    InputAdornment,
    TextField,
    ListItem,
    ListItemSecondaryAction,
    ListItemText
} from '@mic3/platform-ui';
import { useOnChange } from 'app/utils/hook/hooks';

// eslint-disable-next-line no-unused-vars
const TextFieldDynamic = ({ withPencil, onChange, value, className, name }: Object) => {
    const [formOpen, setFormOpen] = useState(false);
    const [stateValue, onChangeField, setFieldValue] = useOnChange(value);

    useEffect(() => {
        setFieldValue(value);
    }, [setFieldValue, value]);

    const handleOpenForm = useCallback(() => {
        setFormOpen(true);
    }, []);

    const handleCloseForm = useCallback(() => {
        setFormOpen(false);
    }, []);

    const isFormInvalid = useCallback(() => {
        return stateValue === '';
    }, [stateValue]);

    const handleSubmit = useCallback((ev) => {
        if(ev) {
            ev.preventDefault();
        }
        if (isFormInvalid()) {
            return;
        }
        onChange({ target: { value: stateValue, name } });
        handleCloseForm();
    }, [handleCloseForm, isFormInvalid, name, onChange, stateValue]);

    return (
        <div className={`flex items-center min-w-0 ${className || ''}`}>
            {formOpen ? (
                <ClickAwayListener onClickAway={handleCloseForm}>
                    <Paper className="p-4 flex items-center justify-center w-full">
                        <form onSubmit={handleSubmit}>
                            <TextField
                                name="value"
                                value={stateValue}
                                onChange={onChangeField}
                                variant="outlined"
                                margin="none"
                                autoFocus
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                disabled={isFormInvalid()}
                                                type="submit"
                                            >
                                                <MdiIcon name="check" size={24} />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </form>
                    </Paper>
                </ClickAwayListener>
            ) : (
                <ListItem onClick={() => handleOpenForm()} ContainerComponent="div">
                    <ListItemText
                        primary={value}
                    />
                    <ListItemSecondaryAction>
                        <IconButton edge="end" onClick={() => handleOpenForm()}>
                            <MdiIcon name="pencil" />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
            )}
        </div>
    );
};

export default TextFieldDynamic;
