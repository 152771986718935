import EntityLayer from 'app/containers/Maps/EntityLayerStyling/EntityLayer'; 
import { Vector as VectorLayer } from 'ol/layer';

class ReplayLayer extends EntityLayer {

    constructor(info, map){
        super(info,map);
    }

    async setLayer() {
        const { name, filters, type, id, visible, opacity } = this.info || {};
        this.layer = new VectorLayer({
            name: id,
            title: name,
            visible,
            opacity,
            type,
            attributes: {
                id,
                type,
                subtitle: 'replay',
                layerType: 'Replay Layer',
                iconName: 'play',
                filters,
                noLocationPins: []
            },
        });
        await this.addLayerToMap();
    }
}

export default ReplayLayer;