import React from 'react';
import styled from 'styled-components';

const ContentAreaStyle = styled.div`
  position: relative;
  grid-area: pContent;
  display: block;
  overflow-x: hidden;
  ${({ isSidebar, heightOffset }) => {
    const sidebarOffset = `${isSidebar ? 50 : 0}px`;
    const offset = `${heightOffset || 0}px`;

    return `height: calc(100% - ${sidebarOffset} - ${offset})!important;`;
}}
`;

const ContentArea = ({ children, innerRef, className, ...rest }) => <ContentAreaStyle {...rest} ref={innerRef} className={`content-area ${className}`}>{children}</ContentAreaStyle>;

export default ContentArea;
