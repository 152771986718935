/* @flow */

import { gql } from '@apollo/client';
import { detailsFields } from 'graphql/abox/task/taskQuery';

export default gql`
mutation closeTaskMutation($id: ID!, $variables: [BpmnVariableInput]) {
    result: completeTask(id: $id, variables: $variables) {
      ${detailsFields}
    }
}
`;
