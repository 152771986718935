/* @flow */

import React from 'react';
import Help from 'app/utils/designer/form/settings/common/Help';

import { name, label } from 'app/utils/designer/form/settings/common/commonBase';
import { onLoad, onChange, isVisible, isDisabled } from 'app/utils/designer/form/settings/common/commonEvents';
import { help } from 'app/utils/designer/form/settings/common/commonHelpers';
import { required, classRequired, readOnly, staticAttribute } from 'app/utils/designer/form/settings/common/commonValidation';
import { flexGrow } from 'app/utils/designer/form/settings/common/commonStyles';

const panels = settingsValues => [
    {
        header: 'Base',
        children: [
            name,
            label,
            { type: 'text', properties: {
                label: 'Placeholder From',
                name: 'properties.PickersFromProps.placeholder',
                help: <Help message='Placeholder for the "from" field' />,
            } },
            { type: 'text', properties: {
                label: 'Placeholder To',
                name: 'properties.PickersToProps.placeholder',
                help: <Help message='Placeholder for the "to" field' />,
            } },
            {
                type: 'typeahead',
                properties: {
                    label: 'Variant',
                    name: 'properties.variant',
                    valueField:'value',
                    clearable: false,
                    options: [
                        { value: 'all' , label: 'All' },
                        { value: 'relative' , label: 'Relative' },
                        { value: 'standard' , label: 'Standard' },
                    ]
                }
            },
        ],
    },
    {
        header: 'Events',
        children: [
            onLoad,
            onChange,
            isVisible,
            settingsValues?.viewType !== 'class' && isDisabled
        ].filter(Boolean)
    },
    {
        header: 'Validation',
        children: [
            settingsValues?.viewType !== 'class' ? required : classRequired,
            settingsValues?.viewType === 'class' && readOnly,
            settingsValues?.viewType === 'class' && staticAttribute(settingsValues)
        ].filter(Boolean)
    },
    {
        header: 'Styles',
        children: [ flexGrow ]
    },
    {
        header: 'Helpers',
        children: [
            help
        ]
    },
];

export default {
    defaults: { fullWidth: true, variant: 'all', serialize: true, withDefault: true, },
    panels: settingValues => panels(settingValues),
};

