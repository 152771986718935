/* @flow */
import { loadData, mutateData } from 'app/utils/redux/action-utils';

import personAutocompleteQuery from 'graphql/entities/people/personAutocompleteQuery';
import personListShortQuery from 'graphql/entities/people/personListShortQuery';
import personListRisedQuery from 'graphql/entities/people/personListRisedQuery';
import createPersonMutation from 'graphql/entities/people/createPersonMutation';

export const CREATE_PERSON_STARTED: string = '@@affectli/entities/people/CREATE_PERSON_STARTED';
export const CREATE_PERSON: string = '@@affectli/entities/people/CREATE_PERSON';

export const OPEN_PERSON_STARTED: string = '@@affectli/entities/people/OPEN_PERSON_STARTED';
export const OPEN_PERSON: string = '@@affectli/entities/people/OPEN_PERSON';

export const LOAD_PERSON_AUTOCOMPLETE_STARTED = '@@affectli/entities/people/LOAD_PERSON_AUTOCOMPLETE_STARTED';
export const LOAD_PERSON_AUTOCOMPLETE = '@@affectli/entities/people/LOAD_PERSON_AUTOCOMPLETE';

export const LOAD_PERSON_LIST_VIEW_STARTED = '@@affectli/entities/people/LOAD_PERSON_LIST_VIEW_STARTED';
export const LOAD_PERSON_LIST_VIEW = '@@affectli/entities/people/LOAD_PERSON_LIST_VIEW';

/**
 * Loads the suggestions for the person autocomplete component.
 */
export const loadPersonAutocomplete = loadData(LOAD_PERSON_AUTOCOMPLETE_STARTED, LOAD_PERSON_AUTOCOMPLETE, personAutocompleteQuery);

export const loadPersonListView = ({ isShort, options }: Object = { isShort: false }) =>
    loadData(LOAD_PERSON_LIST_VIEW_STARTED, LOAD_PERSON_LIST_VIEW, isShort ? personListShortQuery : personListRisedQuery)(options);

export const createPerson = (data: Object) => {
    const record = { ...data };
    record.classes = (record.classes || []).map(({ id }) => id);
    return mutateData(CREATE_PERSON_STARTED, CREATE_PERSON, createPersonMutation, 'Person created.')({ record });
};
