import { gql } from '@apollo/client';

export default gql`
    query classAncestorsTableQuery($id: ID!, $onlyActive: Boolean) {
        records: classAncestors(id: $id, onlyActive: $onlyActive) {
            id
            name
            uri
            active
            color
            formDefinition
            icon
            iconType
        }
    }
`;
