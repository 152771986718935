/* @flow */

import { get, groupBy } from 'app/utils/lo/lo';
import { compile, render } from 'app/utils/template/template';

function evaluateFiltereExcludeByValues(filterBy, data) {
    if(!filterBy || !filterBy?.length) return filterBy;
    // Recursive function to evaluate a rule or logical operator
    const evaluateRule = rule => {
        if (rule.AND) {
            return rule.AND.every(subRule => evaluateRule(subRule));
        } else if (rule.OR) {
            return rule.OR.some(subRule => evaluateRule(subRule));
        } else if (typeof rule.value === 'string') {
            return { ...rule, value: render(compile(rule.value), data)};
        } else if(Array.isArray(rule)) {
            return rule.map(evaluateRule);
        }
        return rule;
    };
  
    // Start evaluation from the top-level filterBy rules
    return evaluateRule(filterBy);
}

const formatArray = (array: Array<Object>) => {
    if (!array || array.length === 0) {
        return '[]';
    }
    const objs = array.map((obj) => {
        return Object.entries(obj)
            .map(([key, value]) => `${key}: "${String(value)}"`)
            .join(', ');
    });
    return `[{${objs.join('},{')}}]`;
};

const formatOptionsForTask = (options: Object, profile: Object = {}) => {
    if (!profile.id) {
        throw new Error('profile.id is required.');
    }
    const filterBy = [];
    const excludeBy = options.excludeBy || [];
    (options.filterBy || []).forEach((filter) => {
        const { field, value } = filter;
        if (field === 'involvement') {
            switch (value) {
                case 'assignee':
                    filterBy.push({ field: 'primary.assignee', op: '=', value: profile.id });
                    break;
                case 'owner':
                    filterBy.push({ field: 'primary.owner', op: '=', value: profile.id });
                    break;
                case 'teamMember':
                    excludeBy.push({ or: [
                        { field: 'primary.assignee', op: '=', value: profile.id },
                        { field: 'primary.owner', op: '=', value: profile.id }
                    ]});
                    break;
                default:
            }
        } else if (field === 'relations.relatedEntity.id') {
            const relFilter = buildRelatedEntityFilter(filter);
            if (Array.isArray(relFilter)) {
                filterBy.push(...relFilter);
            } else if(relFilter){
                filterBy.push(relFilter);

            }
        } else if (field === 'primary.assignee') {
            if (filter.op === 'is not null') {
                filterBy.push({ field: 'assignee.id', op: 'in', value: [profile.id] });
            } else if (filter.op === 'is null') {
                excludeBy.push({ field: 'assignee.id', op: 'in', value: [profile.id] });
            } else {
                filterBy.push({ field: 'assignee.id', op: 'in', value: (value || []).map(v => v && typeof(v) === 'object' ? v.id : v).filter(Boolean) });
            }
        } else if (field === 'primary.priority' && value === '3') {
            //for some reason we still have tasks/processes with priority: 50
            filterBy.push({ or: [
                {...filter},
                { field, op: '=', value: '50' }
            ]});
        } else {
            filterBy.push(filter);
        }
    });

    return { ...options, filterBy, excludeBy  };
};

export const buildRelatedEntityFilter = (filter) => {
    const { value, joinGroup, field } = filter;
    if(field !== 'relations.relatedEntity.id') return filter;
    if (!Array.isArray(value) || value.length === 0) {
        console.error('the value must be a not empty array'); // eslint-disable-line
        return null;
    }
    if (value.length === 1) {
        return { joinGroup, field: 'relations.relatedEntity.id', op: '=', value: get(value[0], 'id') };
    } else {
        const relationGroups = groupBy(value, 'relatedEntityType');
        return [...(Object.keys(relationGroups).map(t => ({ 
            joinGroup,
            field: 'relations.relatedEntity.id', 
            op: 'in', 
            value: relationGroups[t].map(v => v.id)})))];
    }
};
export { formatArray, formatOptionsForTask, evaluateFiltereExcludeByValues };
