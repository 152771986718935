// @flow

export const AddNewMessageMenu = [
    {
        label: 'New',
        disabled: true
    },
    {
        label: 'Direct message',
        icon: 'Direct-message',
        iconType: 'af'
    },
    {
        label: 'Channel',
        icon: 'pound',
        hasDivider: true
    },
    {
        label: 'Search...',
        icon: 'magnify',
        children: [
            {
                label: 'Search',
                disabled: true
            },
            {
                label: 'Tasks',
                icon: 'checkbox-marked-circle-outline'
            },
            {
                label: 'Entities',
                icon: 'things',
                iconType: 'af'
            },
            {
                label: 'Teams',
                icon: 'teams',
                iconType: 'af'
            },
            // {
            //     label: 'Workspaces',
            //     icon: 'set-none',
            //     disabled: true
            // },
            // {
            //     label: 'Classes',
            //     icon: 'vector-triangle',
            //     disabled: true
            // },
            // {
            //     label: 'Maps',
            //     icon: 'map-search',
            //     disabled: true
            // },
            {
                label: 'Classes',
                icon: 'class',
                iconType: 'af'
            },
            {
                label: 'Apps',
                icon: 'apps'
            },
            {
                label: 'Channels',
                icon: 'pound'
            }
        ]
    }
];

export const SettingsMenu = (filters) => {
    const { appearance, sortBy, groupBy } = filters;

    return [
        {
            label: 'Appearance',
            disabled: true
        },
        {
            label: 'Extended',
            icon: `${
                appearance === 'extended' ? 'circle-slice-8' : 'circle-outline'
            }`
        },
        {
            label: 'Medium',
            icon: `${
                appearance === 'medium' ? 'circle-slice-8' : 'circle-outline'
            }`
        },
        {
            label: 'Condensed',
            icon: `${
                appearance === 'condensed' ? 'circle-slice-8' : 'circle-outline'
            }`,
            hasDivider: true
        },
        {
            label: 'Sort by',
            disabled: true
        },
        {
            label: 'Activity',
            icon: `${
                sortBy === 'activity' ? 'circle-slice-8' : 'circle-outline'
            }`
        },
        {
            label: 'Name (A-Z)',
            icon: `${sortBy === 'name' ? 'circle-slice-8' : 'circle-outline'}`,
            hasDivider: true
        },
        {
            label: 'Group by',
            disabled: true
        },
        {
            label: 'Unread',
            icon: `${
                groupBy.unread ? 'checkbox-marked' : 'checkbox-blank-outline'
            }`
        },
        {
            label: 'Favourites',
            icon: `${
                groupBy.favorites ? 'checkbox-marked' : 'checkbox-blank-outline'
            }`
        },
        {
            label: 'By type',
            icon: `${
                groupBy.types ? 'checkbox-marked' : 'checkbox-blank-outline'
            }`,
            hasDivider: true
        }
        // {
        //     label: 'Hide inactive tasks',
        //     icon: 'close'
        // },
        // {
        //     label: 'Advanced search...',
        //     icon: 'magnify'
        // }
    ];
};

export const ChatMembersFilter = (filters) => {
    const { sortBy, groupBy } = filters;
    return [
        {
            label: 'Sort by',
            disabled: true
        },
        {
            label: 'Name (A-Z)',
            icon: `${sortBy === 'name' ? 'circle-slice-8' : 'circle-outline'}`,
            hasDivider: true
        },
        {
            label: 'Online',
            icon: `${sortBy === 'online' ? 'circle-slice-8' : 'circle-outline'}`,
            hasDivider: true
        },
        {
            label: 'Group by',
            disabled: true
        },
        {
            label: 'Status',
            icon: `${
                groupBy.status ? 'checkbox-marked' : 'checkbox-blank-outline'
            }`
        }
    ];
};

export const sharingMenu = (canEdit, canAddSubscribers) => {
    return [
        { label: 'Add new', disabled: true },
        { label: 'Users', icon: 'account', },
        { label: 'Teams', icon: 'teams', iconType: 'af' },
        canEdit && { label: 'Workspaces', icon: 'Workspaces-ven-diagram', iconType: 'af' },
        canAddSubscribers && { label: 'Subscribers', icon: 'messenger', iconType: 'af'}
    ].filter(Boolean);
};