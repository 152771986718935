/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Tooltip, IconButton } from '@mic3/platform-ui';
import styled from 'styled-components';
import { muiTheme } from 'app/themes/materialUi';

import { updateEventStatus } from 'store/actions/stream/eventsActions';
import TextIcon from 'app/components/molecules/TextIcon/TextIcon';
import { bind } from 'app/utils/decorators/decoratorUtils';

const TextIconStyled = styled(TextIcon)`
    margin-top: 3px;
`;

/**
 * Renders the icon to show the actions.
 */
class EventUpdateStatus extends PureComponent<Object, Object> {
    static propTypes = {
        updateEventStatus: PropTypes.func.isRequired,
        postAction: PropTypes.func,
        status: PropTypes.string,
        id: PropTypes.string.isRequired,
        color: PropTypes.string
    };

    @bind
    action(event: ?Object) {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        const { status, id, postAction, time } = this.props;
        this.props.updateEventStatus({ status, id, time }).then(() => postAction && postAction());
    }

    render(): Object {
        const { status, disableHoverEffect, data } = this.props;
        const disabled = status === data?.status;
        const element = (
            <TextIconStyled
                icon={status === 'ACK' ? 'check' : 'close'}
                withConfirmation
                confirmationModalProps={{
                    message: status === 'ACK' ? 'Do you want to acknowledge event?' : 'Do you want to discard event?',
                    declineButtonText: 'No',
                    confirmButtonText: 'Yes'
                }}
                alt={status === 'ACK' ? 'Acknowledged' : 'Discard'}
                hexColor={muiTheme.colors.text.secondary}
                onClick={this.action}
                disabled={disabled}
                padding={1}
            />
        );
        return (
            <Tooltip disableHoverListener={disabled} placement="top" arrow title={status === 'ACK' ? 'Acknowledged' : 'Discard'}>
                <span>
                    {disableHoverEffect ? (
                        element
                    ) : (
                        <IconButton disabled={disabled} onClick={this.action}>
                            {element}
                        </IconButton>
                    )}
                </span>
            </Tooltip>
        );
    }
}

export default connect(null, { updateEventStatus })(EventUpdateStatus);
