/* @flow */

import { gql } from '@apollo/client';

export default gql`
mutation setUserDefaultIdentityProviderMutation($user: ID!, $identityProvider: String) {
  setUserDefaultIdentityProvider(user: $user, identityProvider: $identityProvider) {
        id
    }
}
`;
