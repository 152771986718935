
import { useEffect, memo }  from 'react';
import { useDispatch } from 'react-redux';
import { useSubscription } from '@apollo/client';
import { BACKGROUND_JOB_ASSIGNED_SUBSCRIPTION } from 'graphql/abox/backgroundJob/onBackgroundJobLog';

const SubscriptionComponent = ({ id, populateLogs }) => {
    const { data } = useSubscription(BACKGROUND_JOB_ASSIGNED_SUBSCRIPTION, { variables: { id }});
    const { onBackgroundJobLog } = data || {}; 
    const dispatch = useDispatch();

    useEffect(()=>{
        if(onBackgroundJobLog){
            populateLogs(onBackgroundJobLog);
        }
    },[onBackgroundJobLog, dispatch]); // eslint-disable-line 
    return null;
};

export default memo(SubscriptionComponent);