import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import { RouterMatchPropTypeBuilder } from 'app/utils/propTypes/common';

import PageNotAllowed from 'app/containers/ErrorPages/PageNotAllowed';
import RelatedEntities from './RelatedEntities';
import EntitiesDetail from '../EntityAbout/EntitiesDetail';
import EntitiesListView from '../EntitiesList/EntitiesListView';

class RelatedEntitiesRoute extends PureComponent {
    static propTypes = {
        match: RouterMatchPropTypeBuilder({ id: PropTypes.string }),
        userProfile: PropTypes.object
    };

    render() {
        const { match } = this.props;
        const { permissions, isAdmin } = this.props.userProfile;
        const permissionsSet = new Set(permissions || []);
        const canView = isAdmin || permissionsSet.has('entity.entity');

        if (!canView) {
            return <PageNotAllowed title="Related Entity" />;
        }
        
        return (
            <Switch>
                <Route path={`${match.url}`} exact component={RelatedEntities} />
                <Route path={`${match.url}/:type/entities/:id/:entityId`} exact component={EntitiesListView} />
                <Route path={`${match.url}/:type/:id`} component={EntitiesDetail} />
            </Switch>
        );
    }
}

export default connect(state => ({
    userProfile: state.user.profile
}))(RelatedEntitiesRoute);
