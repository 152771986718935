/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Typography, Button, Grid } from '@mic3/platform-ui';

import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { exportEntities } from 'store/actions/entities/entitiesActions';
import TreeCheckboxes from 'app/components/molecules/TreeCheckboxes/TreeCheckboxes';
import Link from 'app/components/atoms/Link/Link';

const LinkStyled = styled(Link)`
    color: ${({ theme }) => theme.color.primary};
`;

const _normalizeFilter = (filter) => {
    if (filter.field?.includes('relations.relatedEntity.id') && Array.isArray(filter.value)) {
        return { ...filter, value: filter.value.map((v) => v.id) };
    }
    return filter;
};

const commonFields = [
    { label: 'Created by', value: 'createdBy.username' },
    { label: 'Created date', value: 'createdDate' },
    { label: 'Modified by', value: 'modifiedBy.username' },
    { label: 'Modified date', value: 'modifiedDate' },
];

const flattenChildren = (ch) => {
    const children = [];
    const getChild = (fch) => {
        fch.forEach(c => {
            children.push(c);
            c?.children && getChild(c.children);
        });
    };
    getChild(ch);
    return children;
};

export const ExportBJStarted = ({ jobId, onClose }) => {
    return (
        <ModalDialog title='Your download is being prepared' bgcolor={'rgba(40, 51, 75, 1)'} withoutClose>
            <Typography>
                        Your download information is being prepared for you.
                {jobId ? (
                    <>
                        {' '}When completed the files will be available{' '}
                        <LinkStyled to={`/abox/background-job/${jobId}/logs`}>here</LinkStyled> or
                    </>
                ) : null}{' '}
                        you can find it under <LinkStyled to='/abox/background-jobs'>Background Jobs</LinkStyled> in A-Box.
            </Typography>
            <Grid container justify='flex-end'>
                <Button variant='text' onClick={onClose}>
                            i understand
                </Button>
            </Grid>
        </ModalDialog>
    );
};

class DownloadList extends PureComponent<Object, Object> {
    static propTypes = {
        type: PropTypes.string.isRequired,
        onClose: PropTypes.func.isRequired,
    };

    formRef = React.createRef();
    defaultEntityFields = {
        label: 'Entity',
        value: 'entity',
        children: [
            { label: 'Entity ID', value: 'id', checked: true, disabled: true },
            { label: 'Type', value: 'type', checked: true, disabled: true },
            { label: 'Entity name', value: 'name' },
            { label: 'Active', value: 'active' },
            { label: 'Description', value: 'description' },
            { label: 'Classes', value: 'classes.uri' },
            ...commonFields,
            { label: 'SVG graphic ID', value: 'svgGraphic.id' },
            { label: 'SVG transformation', value: 'svgTransformation' },
            { label: 'Icon name', value: 'iconName' },
            { label: 'Icon type', value: 'iconType' },
            { label: 'Icon colour', value: 'iconColor' },
            { label: 'System entity', value: 'isSystem' },
            { label: 'GIS enabled', value: 'enableGis' },
            { label: 'GEOM modified date', value: 'geomModifiedDate' },
            { label: 'GPS co-ordinates', value: 'ewkt' },
            { label: 'Location Information', value: 'primary.locationInfo' },
        ].map((item) => ({ checked: false, ...item })),
    };

    defaultClassFields = {
        label: 'Class',
        value: 'class',
        children: [
            { label: 'Class ID', value: 'id', checked: true, disabled: true },
            { label: 'URI', value: 'uri', checked: true, disabled: true },
            { label: 'Name', value: 'name', checked: true, disabled: true },
            { label: 'Description', value: 'description' },
            { label: 'Icon name', value: 'icon' },
            { label: 'Icon type', value: 'iconType' },
            { label: 'Icon colour', value: 'color' },
            { label: 'Active', value: 'active' },
            { label: 'Abstract', value: 'abstract' },
            { label: 'Parents URI', value: 'parents.uri' },
            { label: 'Form definition', value: 'formDefinition' },
            { label: 'Applicable', value: 'applicableOn' },
            { label: 'Is system', value: 'isSystem' },
            ...commonFields,
            { label: 'Disabled by', value: 'disabledBy.username' },
            { label: 'Disabled date', value: 'disabledDate' },
        ].map((item) => ({ checked: false, ...item })),
    };

    constructor(props: Object) {
        super(props);
        const { primaryClasses, type } = props;
        const selectedPrimaryClass = primaryClasses?.find((cls) => cls?.uri === type);
        this.state = {
            showNotification: false,
            jobId: null,
            data: { selectedOption: 'entityAttributes', dataFrom: { entityAttributes: true }, download: { allFiltered: true } },
            treeValue: this.normalizeDefintionsToCheckboxes(selectedPrimaryClass),
        };
    }

    @bind
    buildComponents(data) {
        const { primaryClasses, type } = this.props;
        const selectedPrimaryClass = primaryClasses?.find((cls) => cls?.uri === type);

        const showAttCols = !['team', 'workspace'].includes(type);
        const isZipDownload = ['team', 'workspace'].includes(type);
        return [
            {
                type: 'button',
                properties: {
                    label: `Download ${isZipDownload ? 'ZIP' : 'CSV'}` ,
                    iconName: 'download',
                    fullWidth: false,
                    onClick: this.onFormSubmit,
                },
            },
            selectedPrimaryClass && { type: 'header', properties: { variant: 'caption', text: 'Download data form' } },
            selectedPrimaryClass && {
                field: 'selectedOption',
                type: 'radioGroup',
                properties: {
                    name: 'selectedOption',
                    formFieldProps: {
                        className: 'typeRadio',
                    },
                    options: [
                        { value: 'entityAttributes', label: 'Entity attributes' },
                        { value: 'relationsAttributes', label: 'Relationship attributes' },
                        { value: 'entityPermissions', label: 'Entity permissions' },
                    ],
                },
            },
            selectedPrimaryClass && { type: 'divider' },
            {
                type: 'number',
                properties: {
                    InputLabelProps: { shrink: true },
                    placeholder: 'All',
                    name: 'rows',
                    label: 'Download rows',
                },
                constraints: { min: 1, },
            },

            showAttCols && { type: 'divider' },

            selectedPrimaryClass &&
                (data.dataFrom.entityAttributes || data.dataFrom.relationsAttributes) && {
                type: 'header',
                properties: { variant: 'h6', text: 'Add class attributes' },
            },
            selectedPrimaryClass &&
                (data.dataFrom.entityAttributes || data.dataFrom.relationsAttributes) && {
                type: 'header',
                properties: { variant: 'caption', text: 'Add additional columns of attribute information to your CSV table.' },
            },

            selectedPrimaryClass &&
                data.dataFrom.entityAttributes && {
                type: 'classificationTypeahead',
                properties: {
                    label: 'Classes',
                    name: 'settings.class',
                    extraQueryFields: ['formDefinition'],
                    onChange: (e) => {
                        this.setState({ treeValue: this.normalizeDefintionsToCheckboxes(selectedPrimaryClass, e.target.value) });
                        return [e.target];
                    },
                    multiple: true,
                    filterBy: [
                        { field: 'applicableOn', op: '=', value: this.props.type },
                        { field: 'active', op: '=', value: true },
                        { field: 'primary', op: '=', value: false },
                        { field: 'hidden', op: '=', value: false },
                        selectedPrimaryClass?.uri && { field: 'uri', op: '<>', value: selectedPrimaryClass.uri },
                    ].filter(Boolean),
                },
            },
            selectedPrimaryClass &&
                data.dataFrom.relationsAttributes && {
                type: 'relationDefinitionsTypeahead',
                properties: {
                    label: `Relations`,
                    name: `settings.relations`,
                    fromType: selectedPrimaryClass.uri,
                    onChange: (e) => {
                        this.setState({
                            treeValue: this.normalizeDefintionsToCheckboxes(
                                null,
                                (e.target.value.classes || []).filter(Boolean).flat(),
                                { relationDefinitionId: e.target.value?.id }
                            ),
                            relationDefinition: e.target.value?.id,
                        });
                        return [e.target];
                    },
                },
            },

            selectedPrimaryClass && (data.dataFrom.entityAttributes || data.dataFrom.relationsAttributes) && { type: 'divider' },

            showAttCols && { type: 'header', properties: { variant: 'h6', text: 'Attribute columns' } },
            showAttCols && { type: 'header', properties: { variant: 'caption', text: 'Select which attribute columns to download' } },
        ].filter(Boolean);
    }

    @bind
    @memoize()
    getDefaultFields(type) {
        switch (type) {
            case 'class':
                return this.defaultClassFields;
            case 'entityType': {
                const children = this.defaultClassFields.children.filter(({ value }) => value !== 'applicableOn');
                const fields = { label: 'Entity Type', value: 'entityType', children };
                return fields;
            }
            default:
                return null;
        }
    }

    @bind
    normalizeDefintionsToCheckboxes(primaryClass, extraClasses, options) {
        const { type } = this.props;
        const { relationDefinitionId } = options || {};
        const parseDefinitions = (defs, parentPath) =>
            defs
                .map((field, index) => {
                    const name = field?.properties?.name;
                    const path = parentPath && name ? `${parentPath}.${name}` : name || parentPath;
                    const isGroup = ['group', 'groupRepeat', 'panel'].includes(field.type);
                    if (!name && !isGroup) {
                        return null;
                    }
                    const stateItem = this.state?.treeValue?.find((tr) => tr.value === path);
                    if (stateItem) {
                        return stateItem;
                    }
                    if (isGroup) {
                        if (field?.type === 'group' || field?.type === 'groupRepeat') {
                            const flatChildren = flattenChildren(field.children);
                            const children = parseDefinitions(flatChildren || [], path, true).filter(Boolean);
                            return {
                                label: field?.properties?.label || field?.properties?.placeholder || field?.properties?.name,
                                value: path,
                                checked: !!field?.constraints?.required,
                                disabled: !!field?.constraints?.required,
                                children
                            };
                        }
                        return parseDefinitions(field.children || [], path).filter(Boolean);
                    }
                    return {
                        label: field?.properties?.label || field?.properties?.placeholder,
                        value: path,
                        checked: !!field?.constraints?.required,
                        disabled: !!field?.constraints?.required,
                    };
                })
                .filter(Boolean)
                .flat(Infinity)
                .filter((d) => d.value && d.label);
        const entityFields = this.state?.treeValue?.find((tr) => tr.value === 'entity') || this.defaultEntityFields;
        return [
            primaryClass && entityFields,
            this.getDefaultFields(type),
            ...[primaryClass, ...(extraClasses || [])].filter(Boolean).map((clss) => {
                const { formDefinition, name, uri, primary } = clss;
                const stateItem = this.state?.treeValue?.find((tr) => tr.value === uri);
                if (stateItem) {
                    return stateItem;
                }
                let modifiedPath = primary ? 'primary' : 'attributes';
                if (!primary && relationDefinitionId) {
                    modifiedPath = '';
                }
                return {
                    label: name,
                    value: uri,
                    checked: stateItem?.checked || false,
                    children: parseDefinitions(formDefinition?.fields || [], modifiedPath),
                };
            }),
        ].filter(Boolean);
    }

    @bind
    normalizeDefintionsToCheckboxesPermissions() {
        return [
            { label: 'Shared with workspaces', value: 'workspace' },
            { label: 'Shared with teams', value: 'team' },
            { label: 'Shared with users', value: 'user' },
        ].map(({ label, value }) => {
            return {
                label,
                value,
                checked: false,
            };
        });
    }

    @bind
    onFormSubmit(event: Event) {
        event.preventDefault();
        this.formRef.current.isValidForm().then(async ({ data, errors }) => {
            if (!errors) {
                const { treeValue, data, relationDefinition } = this.state;
                const { exportEntities, type, filterBy, orderBy, primaryClasses } = this.props;
                const selectedPrimaryClass = primaryClasses?.find((cls) => cls?.uri === type);

                const fields = treeValue.reduce((list, f) => {
                    if (f.children) {
                        (f.children || []).forEach((ch) => {
                            if (ch.checked) {
                                list.push(ch.value);
                            }
                        });
                    } else {
                        f.checked && list.push(f.value);
                    }
                    return list;
                }, []);

                const normalizeField = (field) => {
                    if (Array.isArray(field)) {
                        return normalizeField(field);
                    }
                    const f = _normalizeFilter(field);
                    return f.field ? { ...f, field: `entity.${f.field}` } : f;
                };

                const normalizeEntitiesFilters = (filterBy) => {
                    if(!filterBy?.length) return [];
                    return filterBy.map(_normalizeFilter);
                };

                let dataFrom = Object.entries(data.dataFrom).reduce((typ, [key, val]) => {
                    if (key === 'relationsAttributes' && val) {
                        typ = 'relations';
                    }
                    if (key === 'entityPermissions' && val) {
                        typ = 'sharing';
                    }
                    return typ;
                }, 'entities');

                if (dataFrom === 'sharing' && !fields.length) {
                    return this.setState({ emptyFields: true });
                }
                const exportOptions = {
                    type,
                    fields,
                    relationDefinition,
                    orderBy: dataFrom !== 'entities' ? orderBy.map(normalizeField) : orderBy,
                    filterBy: dataFrom !== 'entities' ? filterBy.map(normalizeField) : normalizeEntitiesFilters(filterBy),
                    startIndex: 0,
                    stopIndex: data?.rows ? data.rows - 1 : null,
                    sharedWith: fields,
                    attributes: fields,
                };
                if (selectedPrimaryClass) {
                    if (dataFrom === 'sharing') {
                        exportOptions.name = `Export ${selectedPrimaryClass.name} permissions`;
                    }
                    if (dataFrom === 'relations') {
                        exportOptions.name = `Export ${selectedPrimaryClass.name} relation`;
                    }
                    if (dataFrom === 'entities') {
                        exportOptions.name = `Export ${selectedPrimaryClass.name}`;
                    }
                }
                if (type === 'class') {
                    exportOptions.name = `Export Classes`;
                }
                if (type === 'entityType') {
                    exportOptions.name = `Export Entity Types`;
                }
                if (type === 'team') {
                    exportOptions.name = `Export Teams`;
                }
                if (type === 'workspace') {
                    exportOptions.name = `Export Workspaces`;
                }
                if (['class', 'entityType', 'team', 'workspace'].includes(type)) {
                    dataFrom = type;
                }
                let result = {};
                try {
                    result = await exportEntities(dataFrom, exportOptions);
                } catch (error) {}
                if (!(result instanceof Error)) {
                    return this.setState({ showNotification: true, jobId: result?.id || null });
                }
                this.setState({ showNotification: true });
            }
        });
    }

    @bind
    onFormChange(data: Object, changedValues: Object) {
        let nextData = { ...data };
        const { name, value } = changedValues;
        let { treeValue } = this.state;
        if (name === 'selectedOption') {
            nextData = {
                dataFrom: {
                    entityAttributes: false,
                    relationsAttributes: false,
                    entityPermissions: false,
                    [value]: true,
                },
                selectedOption: value,
            };
            if (value === 'entityPermissions') {
                treeValue = this.normalizeDefintionsToCheckboxesPermissions();
            } else if (value === 'entityAttributes') {
                const { primaryClasses, type } = this.props;
                const selectedPrimaryClass = primaryClasses?.find((cls) => cls?.uri === type);
                if (selectedPrimaryClass) {
                    treeValue = this.normalizeDefintionsToCheckboxes(selectedPrimaryClass);
                }
            } else if (value === 'relationsAttributes') {
                treeValue = [];
            }
        }
        this.setState({ data: nextData, treeValue });
    }

    @bind
    onTreeChange({ target: { value } }) {
        this.setState({ treeValue: value });
    }

    @bind
    closeEmptyFields() {
        this.setState({ emptyFields: false });
    }

    render(): Object {
        const { onClose } = this.props;
        const { data, treeValue, showNotification, emptyFields, jobId } = this.state;
        if (showNotification) {
            return (
                <ExportBJStarted jobId={jobId} onClose={onClose} />
            );
        }
        if (emptyFields) {
            return (
                <ModalDialog title='' bgcolor={'rgba(40, 51, 75, 1)'} withoutClose>
                    <Typography>You must select at least one attribute column to download.</Typography>
                    <Grid container justify='flex-end'>
                        <Button variant='text' onClick={this.closeEmptyFields}>
                            i understand
                        </Button>
                    </Grid>
                </ModalDialog>
            );
        }
        return (
            <ModalDialog title='Download Data' bgcolor={'rgba(40, 51, 75, 1)'} onClose={onClose}>
                <FormGenerator data={data} components={this.buildComponents(data)} ref={this.formRef} onChange={this.onFormChange} />
                <TreeCheckboxes name='classesDefintions' onChange={this.onTreeChange} value={treeValue} />
            </ModalDialog>
        );
    }
}

export default connect(
    (state) => ({
        primaryClasses: state.app.allPrimaryClasses.records || [],
    }),
    {
        exportEntities,
    }
)(DownloadList);
