/* @flow */

import { name, label, placeholder } from 'app/utils/designer/form/settings/common/commonBase';
import { onLoad, onChange  } from 'app/utils/designer/form/settings/common/commonEvents';
import { help } from 'app/utils/designer/form/settings/common/commonHelpers';
import { flexGrow } from 'app/utils/designer/form/settings/common/commonStyles';

const panels = [
    {
        header: 'Base',
        children: [
            name,
            label,
            placeholder,
            { type: 'boolean', properties: { label: 'With coordinates', name: 'properties.withCoords' } },
            { type: 'boolean', properties: { label: 'With map', name: 'properties.withMap' } },

        ],
    },
    {
        header: 'Events',
        children: [
            onLoad,
            onChange,
        ]
    },
    {
        header: 'Styles',
        children: [ flexGrow ]
    },
    {
        header: 'Helpers',
        children: [ help ]
    },
];

export default {
    defaults: { withMap: false, serialize: true },
    panels: settingValues => panels,
};
