export const leftPanelMenu = (isEntityType) => [
    {
        label: 'Sort & group',
        disabled: true
    },
    {
        label: 'Sort by name (A-Z)',
        icon: 'sort'
    },
    isEntityType && {
        label: 'Show classes',
        icon: 'Attribute-class',
        iconType: 'af',
        useSwitch: true
    }
].filter(Boolean);