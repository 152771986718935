/* @flow */

import { gql } from '@apollo/client';
import entityDetailsCommonQuery from 'graphql/entities/common/entityDetailsCommonQuery';

export default gql`
    mutation entityRemoveClassMutation($id: ID!, $type: String!, $classes: [ID]!) {
        entityRemoveClasses(id: $id, type: $type, classes: $classes) {
            ${entityDetailsCommonQuery}
        }
    }
`;
