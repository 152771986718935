/* @flow */

import { gql } from '@apollo/client';

export default gql`
query loadBroadcastTypeaheadQuery($orderBy: [JSON], $filterBy: [JSON], $startIndex: Int, $stopIndex: Int, $excludeBy: [JSON]) {
  result: broadcasts(orderBy: $orderBy, filterBy: $filterBy, startIndex: $startIndex, stopIndex: $stopIndex, excludeBy: $excludeBy) {
    id
    message
  }
}
`;
