import proxy from '../../../config/react/proxy.setup';

export const isDev = process.env.NODE_ENV !== 'production';
export const isIframe = window.self !== window.top;

const getChatHost = () => {
    if (!isDev) {
        return `${window.location.host}/chat`; // PRODUCTION
    }
    const { host } = new URL(proxy[0].target);
    console.log(`[dev] chat base URL: ${host}/chat`); // eslint-disable-line no-console
    return `${host}/chat`; // DEV
};

export const rocketHost = getChatHost();
