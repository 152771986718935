// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { bind, debounce } from 'app/utils/decorators/decoratorUtils';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import { createEvent } from 'app/utils/http/event';
import { get } from 'app/utils/lo/lo';
import { getLayerFilterDefinitions, setDefaultFilters } from 'app/containers/Maps/LayerAbout/EntityLayer/MapsLayerFilterDefinitions';
import { getPermissions } from 'app/config/rolesConfig';
import { PROCESSLAYERS } from 'app/utils/maps/layer/layerUtils';

class EntityLayerFilters extends PureComponent<Object, Object> {

    static propTypes: Object = {
        data: PropTypes.object,
    };

    constructor(props: Object) {
        super(props);
        const { data, type: primaryClass, attributes } = props;
        const oldFilters = attributes?.filters || {};
        const { entity_type } = data || {};
        const filters = setDefaultFilters(get(data,'filter_by', oldFilters), entity_type?.uri);
        const layerFiltersForm = { entity_type: data?.entity_type, primaryClass: data?.entity_type , ...filters, prevPrimaryClass: primaryClass };
        this.state = { layerFiltersForm };
    }

    formRef = React.createRef();

    @bind
    buildFormDefinition() {
        const { data, entityTypes, mapUserRole } = this.props;
        const { canEdit } = getPermissions(mapUserRole);
        const { entity_type } = data || {};
        const operatorsMap = data?.filter_by?.operatorsMap;
        const classData = entityTypes.find(cls => cls.uri === entity_type?.uri);
        if (PROCESSLAYERS.includes(entity_type?.uri))
            return getLayerFilterDefinitions(entity_type?.uri, classData, operatorsMap, canEdit);
        const filters = getLayerFilterDefinitions(entity_type?.uri, classData, operatorsMap, canEdit);

        filters.splice(3, 0, {
            field: 'relations.relatedEntity.id',
            type: 'relatedEntities',
            properties: {
                label: 'Related Entities',
                name: 'relatedEntities',
            },
            condition: 'in',
            sort: false,
        });
        return [...filters];
    }

    @bind
    async validateForm() {
        if (this.formRef && this.formRef.current) {
            const { errors } = await this.formRef.current.isValidForm();
            return { errors };
        } else {
            return { errors: null };
        }
    }

    @bind
    @debounce()
    async handleFormValidation() {
        const result = await this.validateForm();
        this.errors = !!result.errors;
        return !result.errors;
    }

    @bind
    hasErrors() {
        return this.errors;
    }

    @bind
    async handleChange(data: Object, { name, value }: Object) {
        await this.handleFormValidation();
        this.setState({ layerFiltersForm: data });
        if (this.props.onChange) {
            const name = this.props.name;
            const value = data;
            const event = createEvent('change', { name, value });
            this.props.onChange(event);
        }
    }

    render() {
        const { layerFiltersForm } = this.state;
        return (
            <FormGenerator
                data={layerFiltersForm}
                name={this.props.name}
                onChange={this.handleChange}
                components={this.buildFormDefinition()}
                ref={this.formRef}
            />
        );
    }
}

const mapStateToProps = state => ({
    entityTypes: state.app.allPrimaryClasses.records,
    mapUserRole: get(state.maps, 'situationalAwareness.map.data.role', ''),
});

export default connect(mapStateToProps,  null, null, { forwardRef: true })(EntityLayerFilters);
