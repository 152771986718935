import React from 'react';
import { connect } from 'react-redux';

import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import { loadFormDefinition } from 'store/actions/designer/designerFormActions';
import { get } from 'app/utils/lo/lo';
import { bind } from 'app/utils/decorators/decoratorUtils';

class FormDefinitionGenerator extends React.PureComponent {

    formRef = React.createRef();

    constructor(props) {
        super(props);
        const { value } = props;
        this.state = { definition: [] };
        if(value?.id) {            
            this.reloadDefinition();
        }
    }
    componentDidUpdate(prevProps) {
        const { value } = this.props;
        if(value?.id && value?.id !== prevProps.value?.id) {
            this.reloadDefinition();
        }
    }

    @bind
    async reloadDefinition() {
        const { loadFormDefinition, value } = this.props;

        const formDefinition = await loadFormDefinition(value.id);
        const definition = get(formDefinition, 'primary.definition');
        this.setState({
            definition: definition || [],
            formData: JSON.parse(value?.data || '{}')
        });
    }

    @bind
    onChange(data) {
        this.props.onChange({ target: { 
            name: this.props.name, 
            value: {
                id: this.props.value?.id,
                data: JSON.stringify(data || {}, null, 2) 
            }, 
        }});
    }

    render() {
        const { definition, formData } = this.state;
        const { disabled } = this.props;
        return !!definition.length && (
            <FormGenerator 
                ref={this.formRef}
                disabled={disabled}
                root={false}
                components={definition}
                onChange={this.onChange}
                data={formData}
            />
        );
    }
}

export default connect(
    (state, props) => ({}),
    { loadFormDefinition },
    null,
    { forwardRef: true }
)(FormDefinitionGenerator);