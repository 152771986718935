/* @flow */

import { gql } from '@apollo/client';
import loadWorkspaceDetailsCommonQuery from 'graphql/workspaces/loadWorkspaceDetailsCommonQuery';

export const addWorkspaceOwnersMutation =  gql`
    mutation addWorkspaceOwnersMutation($id: ID!, $owners: [ID]!) {
        result: workspaceAddOwners(id: $id, owners: $owners) {
            ${loadWorkspaceDetailsCommonQuery}
        }
    }
`;


export const removeWorkspaceOwnersMutation =  gql`
    mutation removeWorkspaceOwnersMutation($id: ID!, $owners: [ID]!) {
        result: workspaceRemoveOwners(id: $id, owners: $owners) {
            ${loadWorkspaceDetailsCommonQuery}
        }
    }
`;
