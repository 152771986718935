/* @flow */

import { gql } from '@apollo/client';

export const fieldsConnectorStatusPipeline = `
	pid
    name
    status
    exePath
    cwdPath
    memoryBytes
    virtualMemoryBytes
    cpuUsage
    readBytes
    writtenBytes
    runTimeSecs
    startTimeSecs
    userId
    groupId
    parentPid
`;

export default gql`
	query connectorStatusPipelineQuery($id: ID!, $connectorLabel: String){
		result: iotPipelineConnectorStatus(id: $id, connectorLabel: $connectorLabel) {
			${fieldsConnectorStatusPipeline}
		}
	}
`;
