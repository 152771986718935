
import history from 'store/History';
import { muiTheme } from 'app/themes/materialUi';

const taskboardConfig = openSidebar => ({
    useDomTransition : true,
    // Url for resource avatar images
    resourceImagePath: window.location.origin,
    // Field used to pair a task to a column
    columnField : 'columnId',
    swimlaneField : 'prio',
    stickyHeaders: true,
    //Items
    headerItems : {
        menu : { type : 'taskMenu' },
    },

    bodyItems : {
        progress : { type : 'progress', max : 100 },
    },

    columnDragFeature:true,
    columnHeaderMenuFeature: false,
    columnToolbarsFeature: false,
    simpleTaskEditFeature: false,
    taskEditFeature:false,
    taskMenuFeature: {
        items: {
            editTask : null,
            removeTask : null,
            resources : null,
            column: null,
            about : {
                icon : 'mdi mdi-information-outline',
                text : 'About',
                style: {color: muiTheme.colors.text.primary},
                onItem: async ({ taskRecord }) => {
                    openSidebar && await openSidebar(taskRecord.data.taskId, 'About',taskRecord.data.columnId);
                }
            },
            chat: {
                text :'A-Live',
                icon : 'af af-messenger',
                style: {color: muiTheme.colors.text.primary},
                onItem: async ({ taskRecord }) => {
                    openSidebar && await openSidebar(taskRecord.data.taskId, 'A-Live',taskRecord.data.columnId);
                }
            },
            share:{
                text : 'Sharing',
                icon : 'mdi mdi-share-variant',
                style: {color: muiTheme.colors.text.primary},
                onItem: async({ taskRecord }) => {
                    openSidebar && await openSidebar(taskRecord.data.taskId, 'Sharing',taskRecord.data.columnId);
                }
            },
            classes:{
                text : 'Classes',
                icon : 'af af-Attribute-class',
                style: {color: muiTheme.colors.text.primary},
                onItem: async({ taskRecord }) => {
                    openSidebar && await openSidebar(taskRecord.data.taskId, 'Classes',taskRecord.data.columnId);
                }
            },
            relations:{
                text : 'Relationships',
                icon : 'af af-relationships',
                style: {color: muiTheme.colors.text.primary},
                onItem: async({ taskRecord }) => {
                    history.push(`/abox/task/${taskRecord.data.taskId}/relationships`);
                }
            },
            task: {
                text : 'Go to task',
                icon : 'mdi mdi-import',
                style: {color: muiTheme.colors.text.primary},
                onItem({ taskRecord }){
                    history.push(`/abox/task/${taskRecord.data.taskId}`);
                }
            },
            attachments: {
                text : 'Attachments',
                icon : 'mdi mdi-attachment',
                style: {color: muiTheme.colors.text.primary},
                onItem: async ({ taskRecord }) => {
                    openSidebar && await openSidebar(taskRecord.data.taskId, 'Attachments',taskRecord.data.columnId);
                }
            },
            history: {
                text : 'History',
                icon : 'mdi mdi-history',
                style: {color: muiTheme.colors.text.primary},
                onItem: async ({ taskRecord }) => {
                    openSidebar && await openSidebar(taskRecord.data.taskId, 'History',taskRecord.data.columnId);
                }
            }
        }
    }

});

export { taskboardConfig };
