import OptionsBuilder from 'app/utils/api/OptionsBuilder';
import { getAvatar } from 'app/utils/avatar/avatar';
import { loadData } from 'app/utils/redux/action-utils';
import { addRoleToList } from 'app/config/rolesConfig';

import loadClassAvatarByUriQuery from 'graphql/entities/classifications/loadClassAvatarByUriQuery';
import loadAvatarQuery from 'graphql/entities/classifications/loadAvatarQuery';
import relationDefinitionsListQuery from 'graphql/entities/relationships/relationDefinitionsListQuery';
import loadRelatedEntitiesTypeaheadQuery from 'graphql/entities/entities/loadRelatedEntitiesTypeaheadQuery';
import countTreeEntitiesQuery from 'graphql/entities/entities/countTreeEntitiesQuery';
import entitiesTreeListQuery from 'graphql/entities/entities/entitiesTreeListQuery';
import loadEntityRelationsQuery from 'graphql/entities/entities/loadEntityRelationsQuery';

export const LOAD_AVATAR_STARTED = '@@affectli/entities/tree/LOAD_AVATAR_STARTED';
export const LOAD_AVATAR = '@@affectli/entities/tree/LOAD_AVATAR';

export const TREE_TEMPLATE_CREATE_STARTED = '@@affectli/entities/tree/TREE_TEMPLATE_CREATE_STARTED';
export const TREE_TEMPLATE_CREATE = '@@affectli/entities/tree/TREE_TEMPLATE_CREATE';

export const TREE_TEMPLATE_RELATION_LIST_STARTED = '@@affectli/entities/tree/TREE_TEMPLATE_RELATION_LIST_STARTED';
export const TREE_TEMPLATE_RELATION_LIST = '@@affectli/entities/tree/TREE_TEMPLATE_RELATION_LIST';

export const TREE_TEMPLATE_CLASSIFICATION_BY_ID_STARTED = '@@affectli/entities/tree/TREE_TEMPLATE_CLASSIFICATION_BY_ID_STARTED';
export const TREE_TEMPLATE_CLASSIFICATION_BY_ID = '@@affectli/entities/tree/TREE_TEMPLATE_CLASSIFICATION_BY_ID';

export const TREE_TEMPLATE_CLASSIFICATION_BY_URI_STARTED = '@@affectli/entities/tree/TREE_TEMPLATE_CLASSIFICATION_BY_URI_STARTED';
export const TREE_TEMPLATE_CLASSIFICATION_BY_URI = '@@affectli/entities/tree/TREE_TEMPLATE_CLASSIFICATION_BY_URI';

export const TREE_TEMPLATE_RELATION_LIST_FROM_ID_STARTED = '@@affectli/entities/tree/TREE_TEMPLATE_RELATION_LIST_FROM_ID_STARTED';
export const TREE_TEMPLATE_RELATION_LIST_FROM_ID = '@@affectli/entities/tree/TREE_TEMPLATE_RELATION_LIST_FROM_ID';

export const LOAD_TREE_TEMPLATE_TYPEAHEAD_STARTED = '@@affectli/entities/LOAD_TREE_TEMPLATE_TYPEAHEAD_STARTED';
export const LOAD_TREE_TEMPLATE_TYPEAHEAD = '@@affectli/entities/LOAD_TREE_TEMPLATE_TYPEAHEAD';

export const COUNT_TREE_ENTITIES_STARTED = '@@affectli/entities/COUNT_TREE_ENTITIES_STARTED';
export const COUNT_TREE_ENTITIES = '@@affectli/entities/COUNT_TREE_ENTITIES';

export const LOAD_TREE_ENTITIES_STARTED = `@@affectli/entities/tree/LOAD_TREE_ENTITIES_STARTED`;
export const LOAD_TREE_ENTITIES = `@@affectli/entities/tree/LOAD_TREE_ENTITIES`;

export const LOAD_TREE_ENTITY_RELATIONS_STARTED = `@@affectli/entities/tree/LOAD_TREE_ENTITY_RELATIONS_STARTED`;
export const LOAD_TREE_ENTITY_RELATIONS  = `@@affectli/entities/tree/LOAD_TREE_ENTITY_RELATIONS`;

export const SET_TREE_ENTITY = '@@affectli/entities/tree/SET_TREE_ENTITY';
export const SET_TREE_TEMPLATE = '@@affectli/entities/tree/SET_TREE_TEMPLATE';
export const SET_SIDEBAR_TREE_TEMPLATE = '@@affectli/entities/tree/SET_SIDEBAR_TREE_TEMPLATE';
export const SET_SIDEBAR_TREE_TEMPLATE_EXPAND = '@@affectli/entities/tree/SET_SIDEBAR_TREE_TEMPLATE_EXPAND';
export const RESET_TREE = '@@affectli/entities/tree/RESET_TREE';

export const loadAvatar = uri => (dispatch: Function, getState: Function) => {
    let avatar = getAvatar(getState(), 'tree', uri);
    if (avatar && (avatar.isLoading || avatar.loadedAt > Date.now() - (60*60*1000))) {
        return Promise.resolve(avatar);
    }
    avatar = loadData(LOAD_AVATAR_STARTED, LOAD_AVATAR, loadClassAvatarByUriQuery)({ uri, filterBy: [{ field: 'uri', op: '=', value: uri }] })(dispatch, getState);
    return avatar && avatar[0];
};

export const loadRelatedEntiyRelationDefinitions = options =>
    loadData(TREE_TEMPLATE_RELATION_LIST_STARTED, TREE_TEMPLATE_RELATION_LIST, relationDefinitionsListQuery())(options);

export const loadClassificationById = id =>
    loadData(TREE_TEMPLATE_CLASSIFICATION_BY_ID_STARTED, TREE_TEMPLATE_CLASSIFICATION_BY_ID, loadAvatarQuery )({ id });

export const loadClassificationsByUri = (uri) => {
    const variables = new OptionsBuilder({})
        .filter({ field: 'uri', op: 'in', value: uri })
        .build();
    return loadData(TREE_TEMPLATE_CLASSIFICATION_BY_URI_STARTED, TREE_TEMPLATE_CLASSIFICATION_BY_URI, loadClassAvatarByUriQuery)(variables);
};

export const loadRelationDefinitionsFromIds = (ids) => {
    const variables = new OptionsBuilder({})
        .filter({ field: 'id', op: 'in', value: ids })
        .build();
    return loadData(TREE_TEMPLATE_RELATION_LIST_FROM_ID_STARTED, TREE_TEMPLATE_RELATION_LIST_FROM_ID, relationDefinitionsListQuery())(variables);
};

export const loadTreeEntities = ({ options, type }) => {
    const variables = new OptionsBuilder(options)
        .build();
    return loadData(LOAD_TREE_ENTITIES_STARTED, LOAD_TREE_ENTITIES, entitiesTreeListQuery, addRoleToList)({ ...variables, type });
};

export const loadTreeEntityRelations = (options, entityType, entityId) => {
    const variables = new OptionsBuilder(options)
        .build();
    return loadData(LOAD_TREE_ENTITY_RELATIONS_STARTED, LOAD_TREE_ENTITY_RELATIONS, loadEntityRelationsQuery)({ ...variables, entityType, entityId });
};

export const loadRelatedEntitiesTypeahead = loadData(LOAD_TREE_TEMPLATE_TYPEAHEAD_STARTED, LOAD_TREE_TEMPLATE_TYPEAHEAD, loadRelatedEntitiesTypeaheadQuery);

export const setTreeEntity = treeEntity => (dispatch, getState) => dispatch({ type: SET_TREE_ENTITY, payload: treeEntity });

export const setTreeTemplate = treeTemplate => (dispatch, getState) => dispatch({ type: SET_TREE_TEMPLATE, payload: treeTemplate });

export const setSidebarTreeTemplate = treeTemplate => (dispatch, getState) => dispatch({ type: SET_SIDEBAR_TREE_TEMPLATE, payload: treeTemplate });

export const setSidebarTreeTemplateExpand = treeIds => (dispatch, getState) => dispatch({ type: SET_SIDEBAR_TREE_TEMPLATE_EXPAND, payload: treeIds });

export const countTreeEntities = loadData(COUNT_TREE_ENTITIES_STARTED, COUNT_TREE_ENTITIES, countTreeEntitiesQuery);

export const resetTree = (reloadBoolean) => (dispatch, getState) => dispatch({ type: RESET_TREE, payload: reloadBoolean });
