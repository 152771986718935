/* @flow */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { muiTheme } from 'app/themes/materialUi';
import { Link } from 'react-router-dom';
import { Divider } from '@mic3/platform-ui';

import WidgetHeader from 'app/components/atoms/WidgetHeader/WidgetHeader';
import Loader from 'app/components/atoms/Loader/Loader';
import Title from 'app/components/atoms/Title/Title';
import ButtonIcon from 'app/components/molecules/ButtonIcon/ButtonIcon';
import { ChildrenProp } from 'app/utils/propTypes/common';
import ScrollBarMin from 'app/utils/styles/ScrollMinStyle';

const WidgetBase = styled.div`
    position: relative;
    border-radius: .2rem;
    min-height: 337px;;
    background: ${({ theme }) => theme.material.colors.background.paper };
    box-shadow: ${({ theme }) => theme.shadow.boxShadow2DP };
`;
const WidgetFooter = styled.div`
    padding: 3px 8px 8px 8px;
    width: 100%;
    background: ${({ theme }) => theme.material.colors.background.paper };
    & i:before {
        color: ${({ theme }) => theme.material.colors.link };
    }
    & a {
        text-decoration: none;
    }
`;

const Content = styled.div`
    font-size: 0.8rem;
    overflow: auto;
    height: 248px;
    &::-webkit-scrollbar{
      width: 4px !important;
    }
    &::-webkit-scrollbar-thumb {
        background: ${({ theme }) => theme.material.colors.background.scrollbar};
    }
    color: ${({ theme }) => theme.base.textColor} !important;
    ${({ $noResult }) => $noResult && 'padding: 12px 20px;'}
`;

const Children = styled.div`
    ${ScrollBarMin};
`;

const SubTitle = styled(Title)`
    margin-left: .5rem !important;
    font-size: .7rem !important;
    color: ${({ theme }) => theme.base.textColor || 'inherit'} !important;
    opacity: 0.5;
`;
const StyledLink = styled(Link)`
    text-transform: uppercase;
    text-align: center;
`;

const StyledDivider = styled(Divider)`
    margin-bottom: 0.6rem !important;
    width:auto !important;
    margin: 0px 16px !important;
`;
const HeaderContainer = styled('div')`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 1.2rem
`;
const StyledTitle = styled(Title)`
    color: ${({ theme }) => theme.material.colors.text.caption};
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: auto;
    text-align: right;
    letter-spacing: 0.4px;
`;
const Flex = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const StyledHeading = styled(Title)`
    font-weight: 500;
    font-size: 18px !important;
    line-height: 28px !important;
`;

const Widget = ({ title, subTitle, content, children, loading, url, urlText, renderAppAvatar, footerContent, headerInfo }: Object) => (
    <WidgetBase>
        {loading ? (<Loader absolute />) : (
            <Flex>
                <div>
                    <HeaderContainer>
                        <WidgetHeader>
                            {renderAppAvatar && renderAppAvatar()}
                            <StyledHeading as="h3" style={{color: muiTheme.colors.text.landingPage}}>{title || 'No Name'}</StyledHeading>
                            <SubTitle forwardedAs="h4">{subTitle}</SubTitle>
                        </WidgetHeader>
                        {headerInfo && <StyledTitle as="h4">{headerInfo}</StyledTitle>}
                    </HeaderContainer>
                    {content && <Content $noResult={typeof content === 'string' && content.includes('No ')}>{content}</Content>}
                    {children && <Children>{children}</Children>}
                    <StyledDivider />
                </div>
                <WidgetFooter>
                    {url && !footerContent && <StyledLink to={url}><ButtonIcon icon="arrow-right" size="sm" />{urlText || 'VIEW ALL'}</StyledLink>}
                    {!!footerContent && footerContent()}
                </WidgetFooter>
            </Flex>
        )}
    </WidgetBase>
);

Widget.propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    content: ChildrenProp,
    loading: PropTypes.bool,
    url: PropTypes.string,
    urlText: PropTypes.string,    
};

export default Widget;
