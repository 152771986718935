import { gql } from '@apollo/client';


export const TASK_ASSIGNED_SUBSCRIPTION = gql`
    subscription{
        onAssignedTask {
        id
        name
        }
    }
`;

export const PROCESS_ASSIGNED_SUBSCRIPTION = gql`
    subscription{
        onAssignedProcess{
        id
        name
        description
        }
    }
`;

export const LAST_USED_APPS_SUBSCRIPTION = gql`
    subscription lastUsedProcesses($id:String) {
        onEntityChange(
        type: "user"
        id: $id
        ) {
        primary(fields: ["lastUsedProcesses"])
        }
    }
`;