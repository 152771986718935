// @flow

import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';

import { get } from 'app/utils/lo/lo';
import { formatDate } from 'app/utils/date/date';
import { bind } from 'app/utils/decorators/decoratorUtils';
import history from 'store/History';

import { Menu, MenuItem, Divider } from '@mic3/platform-ui';
import Flex from 'app/components/atoms/Flex/Flex';
import TaskLink from 'app/components/atoms/Link/TaskLink';
import { ListItemStyled, ButtonIconStyled, ListItemContainer } from './style';
import { getFieldByType } from 'app/utils/designer/form/fieldUtils';
import { getAttachmentUrl } from 'app/utils/attachments/attachmentsUtils';

class BoardsTaskItem extends PureComponent<Object, Object> {
    static propTypes = {
        task: PropTypes.object.isRequired,
        columnId: PropTypes.string.isRequired,
        openSidebar: PropTypes.func,
    };

    static defaultProps = {
        task: null,
        columnId: null,
    };

    state = {
        isMenuOpen: false,
    }

    anchorDotsEl = React.createRef();

    closeMenu() {
        this.setState({ isMenuOpen: false });
    }

    @bind
    toggleMenu() {
        this.setState(state => ({ isMenuOpen: !state.isMenuOpen }));
    }

    @bind
    goToTask() {
        history.push(`/abox/task/${this.props.task.id}`);
    }


    @bind
    gotToAttachments() {
        history.push(`/abox/task/${this.props.task.id}/attachments`);
    }

    @bind
    async openSidebar(title: string) {
        this.closeMenu();
        const { task: { id }, columnId } = this.props;
        this.props.openSidebar && this.props.openSidebar(id, title, columnId);
    };

    render() {
        const { task } = this.props;
        const { isMenuOpen } = this.state;
        const { name, id, isLoading } = task || {};
        const { assignee, primary, dueDate } = task;
        const { priority, progress, closedDate } = primary || {};
        return (
            <ListItemContainer>
                <ListItemStyled
                    component={
                        getFieldByType('avatar', {
                            CircularProgressStaticProps: {
                                value: progress || 0,
                                priority,
                                disabled:!!closedDate,
                            },
                            initials: get(assignee, 'name'),
                            src: get(assignee, 'image') && getAttachmentUrl(get(assignee, 'id'), 'user', get(assignee, 'image'))
                        })
                    }
                    title={<TaskLink id={id}>{name || 'No Name'}</TaskLink>}
                    subTitle={<Fragment><TaskLink id={id}>#{id}</TaskLink>{dueDate && `, Due ${formatDate(dueDate)}`}</Fragment>}
                    actions={
                        <Flex>
                            <span ref={this.anchorDotsEl}><ButtonIconStyled icon="dots-vertical" onClick={this.toggleMenu} /></span>
                            <Menu open={isMenuOpen} anchorEl={this.anchorDotsEl.current} onClose={this.toggleMenu} >
                                <MenuItem onClick={() => this.openSidebar('About')}>
                                    <ButtonIconStyled icon="information" label="Open About" />
                                </MenuItem>
                                <MenuItem onClick={this.goToTask}>
                                    <ButtonIconStyled icon="login-variant" label="Go to task" />
                                </MenuItem>
                                <Divider />
                                <MenuItem key={2} onClick={() => this.openSidebar('A-Live')}>
                                    <ButtonIconStyled type={'af'} icon={'messenger'} label={'A-Live'} />
                                </MenuItem>
                                <MenuItem key={3} onClick={() => this.openSidebar('Attachments')}>
                                    <ButtonIconStyled icon={'paperclip'} label={'Attachments'} />
                                </MenuItem>
                            </Menu>
                        </Flex>
                    }
                    raised
                    disabled={!!closedDate}
                    isLoadingBoard={isLoading}
                />
            </ListItemContainer>
        );
    }
}

export default BoardsTaskItem;
