/* @flow */
import React from 'react';

import EventsActionsSidebar from 'app/containers/Sidebars/EventsActionsSidebar';

import {
    buildSidebar as buildSidebarAction,
    closeSidebar as closeSidebarAction
} from 'store/actions/sidebar/sidebarActions';

export const openEventsActionsSidebar = ({ id, title, data, time, reloadList, device, typeEntyty }) => async (dispatch, getState) => {
    return buildSidebarAction({
        title,
        content: <EventsActionsSidebar id={id} title={title} details={data} reloadList={reloadList} time={time} device={device}  typeEntyty={typeEntyty} />,
        isOpen: true,
    })(dispatch, getState);
};

export const closeEventsActionsSidebar = closeSidebarAction;
