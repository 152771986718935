/* @flow */

import React, { Component, lazy } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';

import { RouterMatchPropTypeBuilder } from 'app/utils/propTypes/common';
import BroadcastList from 'app/containers/Broadcasts/BroadcastList';
import CreateBroadcast from 'app/containers/Broadcasts/CreateBroadcast';
import EditBroadcast from 'app/containers/Broadcasts/EditBroadcast';

import lazyComponent from 'app/utils/hoc/lazyComponent';
const BroadcastCalendar = lazyComponent(lazy(() => import('app/containers/Broadcasts/BroadcastCalendar')));

/**
 * Defines the routes for the Broadcasts views
 */
class BroadcastRoute extends Component<Object, Object> {
    static propTypes = {
        location: PropTypes.object,
        match: RouterMatchPropTypeBuilder({ id: PropTypes.string })
    };

    /**
     * @override
     */
    render() {
        const { match } = this.props;
        return (
            <Switch>
                <Route path={`${match.url}`} exact component={BroadcastList} />
                <Route path={`${match.url}/calendar`} component={BroadcastCalendar} />
                <Route path={`${match.url}/add`} component={CreateBroadcast} />
                <Route path={`${match.url}/edit/:id`} component={EditBroadcast} />
                <Route path={`${match.url}/add`} component={CreateBroadcast} />
                <Route path={`${match.url}/edit/:id`} component={CreateBroadcast} />
            </Switch>
        );
    }
}

export default BroadcastRoute;
