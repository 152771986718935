/* @flow */
import React from 'react';
import Alert from 'app/components/molecules/Alert/Alert';
import affectliSso from 'app/auth/affectliSso';

/**
 * The chat service is not available
 */
export default props => (
    <div style={{ margin: '20px' }}>
        <Alert type="error">
            <div>{props.message}</div>
            <div>Try to reload the page. If the error persists contact your administrator.</div>
            <div onClick={() => affectliSso.logout()} style={{ cursor: 'pointer' }}>
                <small>
                    Click here to logout.
                </small>
            </div>
        </Alert>
    </div>
);
