/* @flow */

import React, { PureComponent, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styled, { withTheme } from 'styled-components';
import {
    Avatar, ListItem, ListItemAvatar,
    ListItemSecondaryAction
} from '@mic3/platform-ui';
import { showToastr } from 'store/actions/app/appActions';
import ResizableListItem from 'app/components/molecules/VirtualList/ResizableListItem';
import { get } from 'app/utils/lo/lo';
import { copyToClipboard } from 'app/utils/classification/classificationUtils';
import { toUniqueUUID } from 'app/utils/string/string-utils';
import { EntityLinkStyledSubTitle, LinkIcon, ListItemTextStyled, CardContainer, CardDivider } from 'app/containers/Entities/EntitiesList/EntitiesListItem';
import { getEntityUrl } from 'app/utils/entity/entityUtils';
import { LinkStyled } from 'app/containers/Entities/EntitiesList/EntitiesListItem';
import Icon from 'app/components/atoms/Icon/Icon';
import { render, compile } from 'app/utils/template/template';

const StyledAvatar = styled(Avatar) `
    && {
        width: ${({ size }) => size ? size : '40'}px;
        height: ${({ size }) => size ? size : '40'}px;
        margin-right: 20px;
    }
`;

export const RenderName = ({ data }) => {
    const { name, primaryClass } = data || {};
    const url = primaryClass?.openInModule;
    if(!url) return null;
    
    const link = render(compile(url), data);
    return <>
        <Icon name="map" size="sm" />
        <CardDivider flexItem orientation="vertical" />
        <LinkStyled to={link}>{name || 'No Name'}</LinkStyled>
    </>; 
};

export const RenderImage = ({ thumbnail, name, size }: Object) => (
    <StyledAvatar variant="square" src={thumbnail} size={size} initials={name} />
);

export const RenderId = ({ id, type }: Object) => {
    const dispatch = useDispatch();
    const copyLinkToClipboard = useCallback((e, id, type) => {
        e.stopPropagation();
        
        copyToClipboard(`${window.location.origin}/#${getEntityUrl(id, type)}`)
            .then(() => {
                dispatch(showToastr({ severity: 'success', detail: 'Link copied to clipboard' }));
            })
            .catch(() => {
                dispatch(showToastr({ severity: 'error', detail: 'Link could not copied to clipboard' }));
            });
    }, [dispatch]);

    const copyIdToClipboard = useCallback((e, id) => {
        e.stopPropagation();
        
        copyToClipboard(id)
            .then(() => {
                dispatch(showToastr({ severity: 'success', detail: 'ID copied to clipboard' }));
            })
            .catch(() => {
                dispatch(showToastr({ severity: 'error', detail: 'ID could not copied to clipboard' }));
            });
    }, [dispatch]);


    return (<>
        <EntityLinkStyledSubTitle
            color="disabled"
            variant="caption"
            noWrap
            title="Copy ID to clipboard"
            onClick={e => copyIdToClipboard(e, id)}
        >
            {`#${toUniqueUUID(id)}`}
        </EntityLinkStyledSubTitle>
        <LinkIcon title="Copy link to clipboard" name="link" size="sm" onClick={e => copyLinkToClipboard(e, id, type)}/>
    </>);
};


class MapsListItem extends PureComponent<Object, Object> {

    render() {
        const { data, index, resize, style, children } = this.props;
        const { name, attributes, id, type } = data || {};
        const thumbnail = get(attributes, 'map/thumbnail');

        return (
            <ResizableListItem style={style} key={index} index={index} resize={resize} padding={1}>
                {resizeRow => (
                    <CardContainer>
                        <ListItem component="div" ContainerComponent="div">
                            <ListItemAvatar>
                                <RenderImage thumbnail={thumbnail} name={name} />
                            </ListItemAvatar>
                            <ListItemTextStyled
                                primary={
                                    <RenderName data={data} />
                                }
                                secondary={
                                    <RenderId id={id} type={type}/>
                                }
                            />
                            <ListItemSecondaryAction>
                                {children}
                            </ListItemSecondaryAction>
                        </ListItem>
                    </CardContainer>
                )}
            </ResizableListItem>
        );
    }
};

export default withTheme(MapsListItem);
