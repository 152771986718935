/* @flow */

import React, { useMemo } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {
    IconButton as IconButtonPui, MdiIcon as PUIMdiIcon,
    Divider, UploadFileField as UploadFileFieldPUI,
    CircularProgressStatic as PUICircularProgressStatic, Avatar as PUIAvatar,
    Slider as SliderPui, AvatarEditor as PUIAvatarEditor, TextField,
    Typography as TypographyPui, Autocomplete, AutocompleteLazy,
    DateTimePickerRange, MdiIconSelect, Radio as RadioPui, DatePicker,
    Checkbox as CheckboxPui, ColorPicker as ColorPickerPui, Menu as MenuPui,
    MenuItem as MenuItemPui, LocationForm, Dropzone as DropzonePui, FormLabel, FormHelperText,
    ListItemAvatar, MdiIcon
} from '@mic3/platform-ui';
import AutocompleBase from 'app/components/atoms/Input/AutocompleBase';
import ComponentStyleWrapper, { ListItemTextStyled, ListItemStyled } from 'app/containers/Designer/Form/components/ComponentStyleWrapper';
import { isDefined } from 'app/utils/utils';

import FormDefinitionGenerator from 'app/components/Designer/FormDefinitionGenerator';
import FilesUploadComponent from 'app/components/molecules/UploadButton/UploadFileField';
import ChipsFieldComponent from 'app/components/Designer/ChipsField';
import ArrayStringEditorComponent from 'app/components/atoms/Designer/ArrayStringEditor';
import TimePicker from 'app/containers/Designer/Form/components/TimePicker';
import LocalTextEditor from 'app/components/organisms/TextEditor/TextEditor';
import ButtonComponent from 'app/components/atoms/Designer/Button';
import AppButtonComponent from 'app/components/atoms/Designer/AppButton';
import PasswordTextFieldComponent from 'app/components/atoms/Designer/PasswordTextField';
import PrintButtonComponent from 'app/components/atoms/Designer/PrintButton';
import SwitchComponent from 'app/components/atoms/Designer/Switch';
import ButtonIcon from 'app/components/molecules/ButtonIcon/ButtonIcon';
import ClassificationTypeahead from 'app/components/organisms/Form/Typeahead/ClassificationTypeahead';
import CollapsibleSubHeaderPUI from 'app/components/Designer/CollapsibleSubHeader';
import DateTimePicker from 'app/containers/Designer/Form/components/DateTime';
import DisplayTextComponent from 'app/components/atoms/Designer/DisplayText';
import DurationFieldComponent from 'app/containers/Designer/Form/components/DurationField';
import EntityTypeahead from 'app/components/organisms/Form/Typeahead/EntityTypeahead';
import EnumList from 'app/containers/Classifications/AttributeDetailModal/EnumList';
import ExpansionPanel from 'app/components/Designer/ExpansionPanel';
import ImagePui from 'app/components/Designer/Image';
import FormField from 'app/containers/Designer/Form/components/FormField';
import GraphicTypeahead from 'app/components/organisms/Form/Typeahead/GraphicTypeahead';
import BroadcastTypeahead from 'app/components/organisms/Form/Typeahead/BroadcastTypeahead';
import GroupComponent from 'app/components/atoms/Designer/Group';
import GroupRepeatComponent from 'app/components/atoms/Designer/GroupRepeat';
import Hyperlink from 'app/components/atoms/Input/Hyperlink';
import OrganisationTypeahead from 'app/components/organisms/Form/Typeahead/OrganisationTypeahead';
import PersonTypeahead from 'app/components/organisms/Form/Typeahead/PersonTypeahead';
import EventTypeTypeahead from 'app/components/organisms/Form/Typeahead/EventTypeTypeahead';
import ProcessTypeahead from 'app/components/organisms/Form/Typeahead/ProcessTypeahead';
import ProcessTypeTypeahead from 'app/components/organisms/Form/Typeahead/ProcessTypeTypeahead';
import RelationsTypeahead from 'app/components/organisms/Form/Typeahead/RelationsTypeahead';
import FormDefinitionTypeahead from 'app/components/organisms/Form/Typeahead/FormDefinitionTypeahead';
import FormDefinitionVersionTypeahead from 'app/components/organisms/Form/Typeahead/FormDefinitionVersionTypeahead';
import ScriptVersionTypeahead from 'app/components/organisms/Form/Typeahead/ScriptVersionTypeahead';
import TaskTypeahead from 'app/components/organisms/Form/Typeahead/TaskTypeahead';
import TextareaSmart from 'app/containers/Designer/Form/components/Textarea';
import TextareaDialogSmart from 'app/containers/Designer/Form/components/TextareaDialog';
import PrintTemplateTypeahead from 'app/components/organisms/Form/Typeahead/PrintTemplateTypeahead';
import TeamTypeahead from 'app/components/organisms/Form/Typeahead/TeamTypeahead';
import PipelineTypeahead from 'app/components/organisms/Form/Typeahead/PipelineTypeahead';
import UserTypeahead from 'app/components/organisms/Form/Typeahead/UserTypeahead';
import WorkspaceTypeahead from 'app/components/organisms/Form/Typeahead/WorkspaceTypeahead';
import EntityTypesTh from 'app/components/organisms/Form/Typeahead/EntityTypesTypeahead';
import RelationDefinitionTypeahead from 'app/components/organisms/Form/Typeahead/RelationDefinitionTypeahead';
import RelationDefinitionsTypeahead from 'app/components/organisms/Form/Typeahead/RelationDefinitionsTypeahead';
import EntityRelationDefinitionTypeahead from 'app/components/organisms/Form/Typeahead/EntityRelationDefinitionTypeahead';
import SignalTypeahead from 'app/components/Designer/SignalTypeahead';
import MessageTypeahead from 'app/components/Designer/MessageTypeahead';
import ScriptTypeahead from 'app/components/organisms/Form/Typeahead/ScriptTypeahead';
import GeotagPositionComponent from 'app/components/Designer/Geotag/GeotagPosition';
import GeotagButtonComponent from 'app/components/Designer/Geotag/GeotagButton';
import GeotagLocationComponent from 'app/components/Designer/Geotag/GeotagLocation';
import GeotagAddressComponent from 'app/components/Designer/Geotag/GeotagAddress';
import RelatedEntityTreeComponent from 'app/components/organisms/Form/RelatedEntityTree/RelatedEntityTree';
import { get, set } from 'app/utils/lo/lo';
import { getFieldDefaults } from 'app/utils/designer/form/settings/formFieldSettingsUtils';
import { isString } from 'app/utils/string/string-utils';
import { compile, render } from 'app/utils/template/template';
import { parseDurationText, parseTimeToDate } from 'app/utils/date/date';
import { formatDate } from 'app/utils/date/date';
import OperatorSelect from 'app/utils/filter/OperatorSelect';
import RadioButtonsGroup from 'app/components/atoms/RadioButtonsGroup/RadioButtonsGroup';
import RelatedEntitiesComponent from 'app/components/organisms/RelatedEntities/RelatedEntities';


export const fieldify = (Component: Object) => React.forwardRef((props: Object, ref) => <FormField {...props} Component={Component} ref={ref} />);

const FieldBox = styled.div`
${({ isIcon, noMargins }) => isIcon ? `
padding: 0;
margin: 0;
& span {
  line-height: 0.8 !important;
}
` : (!noMargins && `margin: 12px 0px;`) || '' }
`;

const Box = styled.div`
border-radius: 4px 4px 0px 0px;
${({ isIcon }) => isIcon ? `
padding: 0;
margin: 0px 0 0 0;
` : `
padding: 4px;
` }
width: 100%;
${({ disabled, theme, noBackground }) => disabled || noBackground ? '' : `background-color: ${theme.material.colors.background.fields};` }
& :focus-within {
  ${({ disabled }) => disabled ? '' : `border-bottom: 2px solid ${({ error }) => error ? 'red' : '#4BB9D9' };`}
}
& :focus-within .field-label {
  color: ${({ error }) => error ? 'red' : '#4BB9D9' };
}
`;

const FormLabelStyled = styled(FormLabel)`
padding-left: 8px !important;
font-size: 12px !important;
`;

const FormHelperTextStyled = styled(FormHelperText)`
padding-left: 14px;
`;

const ColorPickerPuiStyled = styled(ColorPickerPui)`
    & > div {
        z-index: 2;
    }
    .swatches-picker > div div:nth-child(2) {
        background: ${({theme})=> theme.material.colors.background.paper} !important;
    }
`;

const DropzoneWrapper = styled.div`
      & .MuiTypography-root {
        color: ${({theme})=> theme.material.colors.text.secondary}
    }
`;

const withLabel = (Component: Object) => (props: Object) => {
    const {
        noBackground,
        noMargins,
        type,
        label,
        error,
        helperText,
        iconType,
        disabled,
        required,
        className,
        boxProps,
        isButton,
        ...componentProps
    } = props || {};
    if(type === 'icon' && iconType) {
        componentProps.type = iconType ;
    }
    const ComponentCached = useMemo(() => <Component disabled={disabled}  className={className} {...componentProps} />, [disabled, className, componentProps]);
    return (
        <FieldBox isIcon={type === 'icon'} noMargins={noMargins}>
            <Box noBackground={noBackground?1:0} isIcon={type === 'icon'} className={`${boxProps?.className || ''} LabelBox`} container disabled={disabled} error={error} {...boxProps} >
                {
                    label &&
                    <FormLabelStyled
                        className={'field-label'}
                        disabled={disabled}
                        required={required}
                        error={error}
                    >
                        {label}
                    </FormLabelStyled>
                }
                {ComponentCached}
            </Box>
            {
                helperText &&
                <FormHelperTextStyled disabled={disabled} required={required} error={error}>
                    {helperText}
                </FormHelperTextStyled>
            }
        </FieldBox>
    );
};

const _getValue = (props) => {
    const { value } = props;
    return value?.name || value?.primary?.username || value?.username || `#${value?.id}`;
};

const withEditPreview = (Component: Object) => (props: Object) => {
    let value = props.value;
    if(Array.isArray(value)) {
        value = value.filter(Boolean);
    }
    if(Array.isArray(value) && value.length) {
        value = value.map(val => _getValue({ ...props, value: val })).join(', ');
    } else if(value && props.valueField && !props.multiple) {
        value = value[props.valueField];
    } else if(value && typeof value === 'object' && !Array.isArray(value)) {
        value = _getValue(props);
    } else if(props.type === 'duration') {
        const { hideDays, hideHours, hideMinutes, hideSeconds, hideMilliseconds } = props;
        value = parseDurationText(value, { hideDays, hideHours, hideMinutes, hideSeconds, hideMilliseconds });
    }
    return (
        <ComponentStyleWrapper
            {...props}
            view={(
                <ListItemStyled button>
                    <ListItemTextStyled
                        primary={props.label}
                        secondary={value}
                    />
                </ListItemStyled>
            )}
            Component={Component}
        />
    );
};

const Avatar = fieldify(PUIAvatar);
const AvatarEditor = fieldify(PUIAvatarEditor);
const ArrayStringEditor = fieldify(withLabel(ArrayStringEditorComponent));
const Button = fieldify(ButtonComponent);
const AppButton = fieldify(AppButtonComponent);
const ButtonIconPui = fieldify(ButtonIcon);
const PrintButton = fieldify(PrintButtonComponent);
const PasswordTextField = fieldify(PasswordTextFieldComponent);
const Checkbox = fieldify(CheckboxPui);
const ChipsField = fieldify(ChipsFieldComponent);
const CircularProgressStatic = fieldify(PUICircularProgressStatic);
const ClassificationTh = withEditPreview(fieldify(ClassificationTypeahead));
const CollapsibleSubHeader = fieldify(CollapsibleSubHeaderPUI);
const ColorPicker = fieldify(ColorPickerPuiStyled);
const Date = fieldify(DatePicker);
const DateTime = fieldify(DateTimePicker);
const DateTimeRange = fieldify(DateTimePickerRange);
const DisplayText = fieldify(DisplayTextComponent);
const Dropzone = fieldify(DropzonePui);
const DurationField = withEditPreview(fieldify(withLabel(DurationFieldComponent)));
const EntityTh = withEditPreview(fieldify(EntityTypeahead));
const EnumListGrid = fieldify(EnumList);
const FormDefinition = fieldify(FormDefinitionGenerator);
const FieldifiedHyperLink = fieldify(Hyperlink);
const GraphicTh = withEditPreview(fieldify(GraphicTypeahead));
const EventTypeTh = withEditPreview(fieldify(EventTypeTypeahead));
const PipelineTh = withEditPreview(fieldify(PipelineTypeahead));
const Group = fieldify(GroupComponent);
const GroupRepeat = fieldify(GroupRepeatComponent);
const Icon = fieldify(withLabel(PUIMdiIcon));
const IconButton = fieldify(withLabel(IconButtonPui));
const IconSelect = fieldify(MdiIconSelect);
const Image = fieldify(withLabel(ImagePui));
const Location = fieldify(LocationForm);
const Menu = fieldify(MenuPui);
const MenuItem = fieldify(MenuItemPui);
const OrganisationTh = withEditPreview(fieldify(OrganisationTypeahead));
const Panel = fieldify(ExpansionPanel);
const PersonTh = withEditPreview(fieldify(PersonTypeahead));
const ProcessTh = withEditPreview(fieldify(ProcessTypeahead));
const ProcessTypeTh = withEditPreview(fieldify(ProcessTypeTypeahead));
const Radio = fieldify(RadioPui);
const RelationsTh = fieldify(RelationsTypeahead);
const Slider = fieldify(withLabel(SliderPui));
const Switch = fieldify(SwitchComponent);
const RelationDefinitionTh = withEditPreview(fieldify(RelationDefinitionTypeahead));
const RelationDefinitionsTh = withEditPreview(fieldify(RelationDefinitionsTypeahead));
const EntityRelationDefinitionTh = withEditPreview(fieldify(EntityRelationDefinitionTypeahead));
const FormDefinitionTh = withEditPreview(fieldify(FormDefinitionTypeahead));
const FormDefinitionVersionTh = withEditPreview(fieldify(FormDefinitionVersionTypeahead));
const ScriptVersionTh = withEditPreview(fieldify(ScriptVersionTypeahead));
const TaskTh = withEditPreview(fieldify(TaskTypeahead));
const PrintTemplateTh = withEditPreview(fieldify(PrintTemplateTypeahead));
const BroadcastTh = withEditPreview(fieldify(BroadcastTypeahead));
export const Text = fieldify(TextField);
const Textarea = fieldify(TextareaSmart);
const TextareaDialog = fieldify(TextareaDialogSmart);
const TextEditor = fieldify(withLabel(LocalTextEditor));
const EntityTypesTypeahead = withEditPreview(fieldify(EntityTypesTh));
const TeamTh = withEditPreview(fieldify(TeamTypeahead));
const SignalTh = withEditPreview(fieldify(SignalTypeahead));
const ScriptTh = withEditPreview(fieldify(ScriptTypeahead));
const MessageTh = withEditPreview(fieldify(MessageTypeahead));
const Time = fieldify(TimePicker);
const MuiAutocomplete = fieldify(AutocompleBase);
const Typeahead = fieldify(Autocomplete);
const TypeaheadLazy = fieldify(AutocompleteLazy);
const Typography = fieldify(TypographyPui);
const UploadFileField = fieldify(UploadFileFieldPUI);
const FilesUpload = fieldify(FilesUploadComponent);
const UserTh = withEditPreview(fieldify(UserTypeahead));
const WorkspaceTh = withEditPreview(fieldify(WorkspaceTypeahead));
const GeotagPosition = fieldify(GeotagPositionComponent);
const GeotagButton = fieldify(GeotagButtonComponent);
const GeotagLocation = fieldify(GeotagLocationComponent);
const GeotagAddress = fieldify(GeotagAddressComponent);
const RelatedEntityTree = fieldify(RelatedEntityTreeComponent);
const OperatorSelector = fieldify(OperatorSelect);
const RadioGroup = fieldify(RadioButtonsGroup);
const RelatedEntities = fieldify(withLabel(RelatedEntitiesComponent));

const IconStyled = styled(Icon)`
display: block;
margin: ${({ label }) => label ? '0px 8px 8px 8px' : '16px 8px 8px 8px'};
.LabelBox {
  ${({ disabled, theme }) => disabled ? '' : `background-color: transparent !important;` }
}
`;

const StyledIcon = styled(Icon)`
    &.mdi-checkbox-blank-outline{
        &:before {
            color: ${({theme})=>theme.material.colors.secondary.main};
        }
    }
    &.mdi-checkbox-marked{
        &:before {
            color: ${({theme})=>theme.material.colors.secondary.main};
        }  
    } 
    &.mdi-calendar{
        &:before {
        color: ${({theme})=>theme.material.colors.text.secondary};
        }
    }
`;

const StyledIconButton = styled(IconButton)`
    &.MuiIconButton-root{
        &:hover{
            background-color: ${({theme})=>theme.material.colors.background.hover};
        }
    }
`;

const MenuItemStyled = styled(MenuItem)`
width: 100% !important;
`;

const TextEditorStyled = styled(TextEditor)`
width: 100%;
`;

const DividerStyled = styled(fieldify(Divider))`
margin: 10px 0 32px 0 !important;
width: 100% !important;
`;

const ButtonStyled = styled(Button)`
margin: 8px 0 !important;
.MuiIcon-root {
    line-height: 8px !important;
}
${({ theme, hexcolor }) => hexcolor ? `
    &.MuiButton-root.MuiButton-outlined {
        color: ${hexcolor};
        border: 1px solid ${hexcolor};
    }
    &.MuiButton-root.MuiButton-contained {
        background-color: ${hexcolor};
    }
    ` : ''
}
`;

const AppButtonStyled = styled(AppButton )`
margin: 8px 0 !important;
.MuiIcon-root {
    line-height: 8px !important;
}
${({ theme, hexcolor }) => hexcolor ? `
    &.MuiButton-root.MuiButton-outlined {
        color: ${hexcolor};
        border: 1px solid ${hexcolor};
    }
    &.MuiButton-root.MuiButton-contained {
        background-color: ${hexcolor};
    }
    ` : ''
}
`;

const SliderStyled = styled(Slider)`
display: block !important;
margin-left: 16px;
width: calc(100% - 32px) !important;
`;

const ImageContainer = styled.div`
    overflow: hidden;
`;

const fieldifyCustomComponent = (name, component, ckey, customComponentCache) => {
    if (!customComponentCache) {
        // eslint-disable-next-line no-console
        console.warn('getFieldByType error:', new Error('the customComponentCache is required when custom component are used.'));
        return fieldify(withLabel(component));
    }
    if (!customComponentCache[name] || (customComponentCache[name] && ckey && customComponentCache[name].ckey !== ckey)) {
        customComponentCache[name] = fieldify(withLabel(component));
        customComponentCache[name].ckey = ckey;
    }
    return customComponentCache[name];
};

export const evaluateProperty = (props, propName) => {
    let value = get(props, propName);
    if (isString(value)) {
        const { context, data, inherited, ...properties } = props;
        try{
            value = render(compile(value), { context, data, inherited, properties });
        }catch(err){}
        return set(props, propName, value);
    }
    return props;
};

export const getFieldByType = (type: string, properties: Object = {}, children: any, customComponentCache: Object) => {
    let props = properties || {};
    props = evaluateProperty(props, 'header');
    props = evaluateProperty(props, 'label');
    props = evaluateProperty(props, 'description');
    if(type === 'hyperlink' && !props.isdesigner) { // for hyperlink component
        props = evaluateProperty(props, 'value.link');
    }
    if(props.type === 'openAppButton'){
        props = evaluateProperty(props, 'urls.iosUrl');
        props = evaluateProperty(props, 'urls.windowsUrl');
        props = evaluateProperty(props, 'urls.androidUrl');
    }
    
    const { extraType } = props;

    switch (extraType || type) {
        case 'custom':
            const { cid, Component, ckey, ...rest } = props;
            const identifier = cid || rest.name || props.label;
            if (!identifier) {
                throw new Error('You need to specify a unique "cid". "name" or "label" to use the "custom" type');
            }
            const Custom = fieldifyCustomComponent(identifier, Component, ckey, customComponentCache);
            return <Custom {...rest}>{children}</Custom>;
        case 'panel':
            return <Panel type={type} {...props}>{children}</Panel>;
        case 'iotPanel':
            return <Panel type={type} {...props}>{children}</Panel>;
        case 'image':
            props = evaluateProperty(props, 'src');
            return (
                <ImageContainer>
                    <Image {...props} type={type} />
                </ImageContainer>
            );
        case 'chip':
            return <ChipsField {...props} />;
        case 'tinypanel':
            return <CollapsibleSubHeader {...props}>{children}</CollapsibleSubHeader>;
        case 'divider':
            const { onStartChanges, onEndChanges, ...dividerProps } = props;
            return <DividerStyled {...dividerProps} />;
        case 'buttonIcon':{
            return <ButtonIconPui {...props} />;
        }
        case 'enum':{
            return <EnumListGrid {...props} />;
        }
        case 'group':
            return <Group {...props}>{children}</Group>;
        case 'groupRepeat':
            return <GroupRepeat {...props}>{children}</GroupRepeat>;
        case 'circularProgressStatic':
            return <CircularProgressStatic {...props}/>;
        case 'openAppButton':{
            const { label, afterAction, onStartChanges, onEndChanges, ...rest } = props;
            return <AppButtonStyled {...rest}>{label}</AppButtonStyled>;
        }
        case 'outcome':   
        case 'button': {
            const { label, afterAction, onStartChanges, onEndChanges, ...rest } = props;
            return <ButtonStyled {...rest}>{label}</ButtonStyled>;
        }
        case 'printButton': {
            const { label, ...rest } = props;
            return <PrintButton {...rest}>{label}</PrintButton>;
        }
        case 'icon':
            const { byReference, data, name } = properties;
            let iconNamingProps = { name };
            if (byReference && name) {
                iconNamingProps = { name: get(data, name) };
            }
            if (props.isButton) {
                const { size, boxProps, disabled, byReference, data, isButton, onClick, color, label, isVisible, ...restIconProps } = props;

                return (
                    <StyledIconButton noBackground
                        {...restIconProps}
                        boxProps={boxProps}
                        onClick={onClick}
                        label={label}
                        isVisible={isVisible}
                        disabled={disabled}
                    >
                        <StyledIcon noBackground size={size} color={color || 'white'} boxProps={boxProps} type={restIconProps.iconType || restIconProps.type} {...restIconProps} {...iconNamingProps} />
                    </StyledIconButton>
                );
            }
            const { isButton, byReference: fake, ...iconProps } = props; // eslint-disable-line no-unused-vars
            return(
                <ComponentStyleWrapper
                    {...iconProps} {...iconNamingProps}
                    view={(
                        <IconStyled
                            {...iconProps} {...iconNamingProps}
                            disabled
                        />
                    )}
                    Component={IconStyled}
                />
            );
        case 'hyperlink':
            return <FieldifiedHyperLink {...props} />;
        case 'uuid':
        case 'text': {
            const TextComponent = props.opSelector ? OperatorSelector : Text;
            return (
                <ComponentStyleWrapper
                    {...props}
                    view={(
                        <ListItemStyled button>
                            <ListItemTextStyled
                                primary={props.label}
                                secondary={props.value}
                            />
                        </ListItemStyled>
                    )}
                    Component={TextComponent}
                />
            );
        };
        case 'password': {
            return (
                <PasswordTextField {...props} />
            );
        };
        case 'textarea':
            const { modal, ...textareaProps } = props;
            if(!modal && props.parseAs === 'text') {
                const { parseAs, ...simpleTextareaProps} = textareaProps;
                return (
                    <ComponentStyleWrapper
                        {...simpleTextareaProps}
                        rows="5"
                        multiline={true}
                        view={(
                            <ListItemStyled button>
                                <ListItemTextStyled
                                    primary={props.label}
                                    secondary={String(props.value || '')}
                                />
                            </ListItemStyled>
                        )}
                        Component={Text}
                    />
                );
            }
            // We don't need ComponentStyleWrapper fro this component I apply it inside of the component.
            return !!modal ? <TextareaDialog {...textareaProps} /> : <Textarea {...textareaProps} />;
        case 'json':
            return <Textarea {...props} parseAs="JSON" useCodeEditor />;
        case 'number': {
            const TextComponent = props.opSelector ? OperatorSelector : Text;
            return (
                <ComponentStyleWrapper
                    {...props}
                    type="number"
                    view={(
                        <ListItemStyled button>
                            <ListItemTextStyled
                                primary={props.label}
                                secondary={props.value}
                            />
                        </ListItemStyled>
                    )}
                    Component={TextComponent}
                />
            );
        };
        case 'boolean':
            const { description, ...restProps } = props;
            return (
                <ComponentStyleWrapper
                    {...restProps}
                    view={(
                        <ListItemStyled button>
                            <ListItemAvatar>
                                <MdiIcon name={props?.value ? 'checkbox-marked' : 'checkbox-blank-outline'} />
                            </ListItemAvatar>
                            <ListItemTextStyled
                                primary={props.label}
                                secondary={description}
                            />
                        </ListItemStyled>
                    )}
                    Component={Switch}
                />
            );
        case 'date': {
            return (
                <ComponentStyleWrapper
                    format={props.format || 'MMM Do YYYY'}
                    fullWidth
                    {...props}
                    view={(
                        <ListItemStyled button>
                            <ListItemTextStyled
                                primary={props.label}
                                secondary={props.value ? moment(props.value).format(props.format || 'MMM Do YYYY') : ''}
                            />
                        </ListItemStyled>
                    )}
                    Component={Date}
                />
            );
        }
        case 'time':
            return (
                <ComponentStyleWrapper
                    ampm={false}
                    fullWidth
                    {...props}
                    format={props.format || 'HH:mm'}
                    view={(
                        <ListItemStyled button>
                            <ListItemTextStyled
                                primary={props.label}
                                secondary={props.value ? moment(parseTimeToDate(props.value)).format(props.format || 'HH:mm') : ''}
                            />
                        </ListItemStyled>
                    )}
                    Component={Time}
                />
            );
        case 'dateTime':
            return (
                <ComponentStyleWrapper
                    type={type}
                    format="MMM Do YYYY, HH:mm"
                    fullWidth
                    ampm={false}
                    {...props}
                    view={(
                        <ListItemStyled button>
                            <ListItemTextStyled
                                primary={props.label}
                                secondary={props.value ? moment(props.value).format(props.format || 'MMM Do YYYY, HH:mm') : ''}
                            />
                        </ListItemStyled>
                    )}
                    Component={DateTime}
                />
            );
        case 'dateTimeRange':
            const secondary = buildDateTimaRangeStringValue(props.value);
            return (
                <ComponentStyleWrapper
                    type={type}
                    fullWidth
                    {...props}
                    view={(
                        <ListItemStyled button>
                            <ListItemTextStyled
                                primary={props.label}
                                secondary={secondary}
                            />
                        </ListItemStyled>
                    )}
                    Component={DateTimeRange}
                />
            );
        case 'displayText': {
            return <DisplayText {...props} type={type} />;
        }
        case 'duration': {
            return <DurationField {...props} />;
        }
        case 'arrayStringEditor': {
            return <ArrayStringEditor {...props} />;
        }
        case 'header': {
            props = evaluateProperty(props, 'text');
            const { text, ...rest } = props;
            return <Typography align="left" {...rest}>{text}</Typography>;
        }
        case 'typeahead': {
            const { useFetch, fetchFunction, valueField, parseAs, ...rest } = props;
            if(useFetch) {
                rest.fetchFunction = fetchFunction;
            }
            const secondary = valueField ? get(rest, 'value[valueField]') : rest?.value;
            const op = props?.options?.find(op => op.value === secondary);
            return (
                <ComponentStyleWrapper
                    {...rest}
                    valueField={useFetch ? valueField : 'value'}
                    view={(
                        <ListItemStyled button>
                            <ListItemTextStyled
                                primary={rest.label}
                                secondary={(valueField ? get(rest, 'value[valueField]') : op?.label) || secondary}
                            />
                        </ListItemStyled>
                    )}
                    Component={useFetch ? TypeaheadLazy
                        : Typeahead}
                />
            );
        }
        case 'autocomplete': {
            const { valueField, ...rest } = props;
            return (
                <ComponentStyleWrapper
                    {...rest}
                    valueField={valueField || 'value'}
                    Component={MuiAutocomplete}
                />
            );
        }
        case 'relationDefinitionTypeahead': {
            return <RelationDefinitionTh {...props}/>;
        }
        case 'relationDefinitionsTypeahead': {
            return <RelationDefinitionsTh {...props}/>;
        }
        case 'entityRelationDefinitionTypeahead': {
            return <EntityRelationDefinitionTh {...props}/>;
        }
        case 'processTypeTypeahead': {
            return <ProcessTypeTh {...props}/>;
        }
        case 'userTypeahead': {
            return <UserTh {...props}/>;
        }
        case 'entityTypesTypeahead': {
            return <EntityTypesTypeahead {...props}/>;
        }
        case 'classificationTypeahead': {
            return <ClassificationTh {...props}/>;
        }
        case 'organisationTypeahead': {
            return <OrganisationTh {...props}/>;
        }
        case 'customEntityTypeahead': {
            return <EntityTh {...props} entityType="custom" />;
        }
        case 'personTypeahead': {
            return <PersonTh {...props}/>;
        }
        case 'thingTypeahead': {
            return <EntityTh {...props} entityType="thing" />;
        }
        case 'pipelineTypeahead': {
            return <PipelineTh {...props}/>;
        }
        case 'graphicTypeahead': {
            return <GraphicTh {...props}/>;
        }
        case 'entityTypeahead': {
            return <EntityTh {...props}/>;
        }
        case 'processTypeahead': {
            return <ProcessTh {...props}/>;
        }
        case 'taskTypeahead': {
            return <TaskTh {...props}/>;
        }
        case 'printTemplateTypeahead': {
            return <PrintTemplateTh {...props}/>;
        }
        case 'teamTypeahead': {
            return <TeamTh {...props}/>;
        }
        case 'workspaceTypeahead': {
            return <WorkspaceTh {...props}/>;
        }
        case 'eventTypeTypeahead': {
            return <EventTypeTh {...props}/>;
        }
        case 'formDefinitionTypeahead': {
            return <FormDefinitionTh {...props}/>;
        }
        case 'formDefinitionVersionTypeahead': {
            return <FormDefinitionVersionTh {...props}/>;
        }
        case 'scriptVersionTypeahead': {
            return <ScriptVersionTh {...props}/>;
        }
        case 'scriptTypeahead': {
            return <ScriptTh {...props}/>;
        }
        case 'signalTypeahead': {
            return <SignalTh {...props}/>;
        }
        case 'messageTypeahead': {
            return <MessageTh {...props}/>;
        }
        case 'relationsTypeahead': {
            return <RelationsTh {...props}/>;
        }
        case 'broadcastTypeahead': {
            return <BroadcastTh {...props}/>;
        }
        case 'relatedEntityTree': {
            return <RelatedEntityTree {...props } />;
        }
        case 'iconSelect': {
            const value = props.value?.value === undefined ? props.value : props.value.value;
            return (
                <ComponentStyleWrapper
                    {...props}
                    view={(
                        <ListItemStyled button>
                            <ListItemAvatar>
                                <MdiIcon name={value} />
                            </ListItemAvatar>
                            <ListItemTextStyled
                                primary={props.label}
                                secondary={value}
                            />
                        </ListItemStyled>
                    )}
                    Component={IconSelect}
                />
            );
        }
        case 'radio': {
            return (
                <ComponentStyleWrapper
                    {...props}
                    view={(
                        <ListItemStyled button>
                            <ListItemAvatar>
                                <MdiIcon name={props.value ? 'radiobox-marked' : 'radiobox-blank'} />
                            </ListItemAvatar>
                            <ListItemTextStyled
                                primary={props.label}
                                secondary={props.description}
                            />
                        </ListItemStyled>
                    )}
                    Component={Radio}
                />
            );
        }
        case 'radioGroup': {
            return (
                <ComponentStyleWrapper
                    {...props}
                    view={(
                        <ListItemStyled button>
                            <ListItemAvatar>
                                <MdiIcon name={props.value ? 'radiobox-marked' : 'radiobox-blank'} />
                            </ListItemAvatar>
                            <ListItemTextStyled
                                primary={props.label}
                                secondary={props.description}
                            />
                        </ListItemStyled>
                    )}
                    Component={RadioGroup}
                />
            );
        }
        case 'checkbox': {
            return (
                <ComponentStyleWrapper
                    {...props}
                    view={(
                        <ListItemStyled button>
                            <ListItemAvatar>
                                <MdiIcon name={props.value ? 'checkbox-marked' : 'checkbox-blank-outline'} />
                            </ListItemAvatar>
                            <ListItemTextStyled
                                primary={props.label}
                                secondary={props.description}
                            />
                        </ListItemStyled>
                    )}
                    Component={Checkbox}
                />
            );
        }
        case 'avatar': {
            return <Avatar {...props}/>;
        }
        case 'avatarEditor': {
            return <AvatarEditor {...props}/>;
        }
        case 'colorPicker': {
            return (
                <ComponentStyleWrapper
                    {...props}
                    view={(
                        <ListItemStyled button>
                            <ListItemAvatar>
                                <MdiIcon name="circle" color={props.value || '#00BCD4'} />
                            </ListItemAvatar>
                            <ListItemTextStyled
                                primary="Icon color"
                                secondary={props.value || '#00BCD4'}
                            />
                        </ListItemStyled>
                    )}
                    Component={ColorPicker}
                />
            );
        }
        case 'menu': {
            return <Menu {...props}>{children}</Menu>;
        }
        case 'menuItem': {
            const { label, className, ...rest } = props;
            return <MenuItemStyled Component="div" {...rest}>{label}</MenuItemStyled>;
        }
        case 'location': {
            return <Location {...props}/>;
        }
        case 'slider': {
            const { valueTemplate, value, ...sliderProps } = props;
            sliderProps.value = isDefined(value) ? value : 0;
            if (valueTemplate) {
                sliderProps.valueLabelFormat = value => valueTemplate.replace('{{value}}', value);
            }
            if(props.editablePanel && !props.editable) {
                return (
                    <SliderStyled {...sliderProps} disabled valueLabelDisplay={sliderProps.value > 0 ? 'on' : null} />
                );
            }
            return (
                <SliderStyled {...sliderProps} />
            );
        }
        case 'dropzone': {
            let { value } = props;
            if (typeof(value) === 'string') { // https://mi-c3.affectli.com/#/abox/task/069c33a4-9acf-11ec-8fbb-0242ac12000c
                try {                         // We are serializing data when we save and dropzone component throws error on string
                    value = JSON.parse(value); // Tried to pass "serialize: false" inside dropzoneSettings.js but then bpmnEngineUtils.js
                } catch (e) {}                  // throws exception which are widely used in system so currently going with this tweak
            }
            return <DropzoneWrapper><Dropzone {...props} value={value} /></DropzoneWrapper> ;
        }
        case 'uploadFile': {
            return <UploadFileField {...props}/>;
        }
        case 'file': {
            return <FilesUpload {...props}/>;
        }
        case 'textEditor': {
            return <TextEditorStyled {...props}/>;
        }
        case 'relatedEntities': {
            return <RelatedEntities {...props}/>;
        }
        case 'classification': {
            return null;
        }
        case 'formDefinition': {
            const { pushSubFormReferences, ...restProps } = props;
            const reference = React.createRef();
            pushSubFormReferences(reference);
            return <FormDefinition {...restProps} ref={reference} />;
        }
        case 'geotagPosition': {
            return (
                <ComponentStyleWrapper
                    {...props}
                    view={null}
                    Component={GeotagPosition}
                />
            );
        }
        case 'geotagButton': {
            const { remoteReference, remoteType, data } = props;
            let remoteId = null;
            if(remoteReference && remoteType) {
                try{
                    remoteId = get(data, remoteReference, null);
                } catch (e) {
                    // eslint-disable-next-line no-console
                    console.error(`Path ${remoteReference} to remote reference is wrong.`);
                }
            }
            return (
                <ComponentStyleWrapper
                    remoteId={remoteId}
                    {...props}
                    view={(
                        <ListItemStyled button>
                            <ListItemTextStyled
                                primary={
                                    <>
                                        <Typography variant="caption">latitude</Typography><Typography>{props.value?.lat}</Typography>
                                        <Typography variant="caption">longitude</Typography><Typography>{props.value?.lng}</Typography>
                                    </>
                                }
                            />
                        </ListItemStyled>
                    )}
                    Component={GeotagButton}
                />
            );
        }
        case 'geotagLocation': {
            return (
                <ComponentStyleWrapper
                    {...props}
                    view={(
                        <ListItemStyled button>
                            <ListItemTextStyled
                                primary={
                                    <>
                                        <Typography variant="caption">name</Typography><Typography>{props.value?.name}</Typography>
                                        <Typography variant="caption">latitude</Typography><Typography>{props.value?.latitude}</Typography>
                                        <Typography variant="caption">longitude</Typography><Typography>{props.value?.longitude}</Typography>
                                    </>
                                }
                            />
                        </ListItemStyled>
                    )}
                    Component={GeotagLocation}
                />
            );
        }
        case 'geotagAddress': {
            return (
                <ComponentStyleWrapper
                    {...props}
                    view={(
                        <ListItemStyled button>
                            <ListItemTextStyled
                                primary={
                                    <>
                                        <Typography variant="caption">address</Typography><Typography>{props.value?.display_name}</Typography>
                                        <Typography variant="caption">latitude</Typography><Typography>{props.value?.latitude}</Typography>
                                        <Typography variant="caption">longitude</Typography><Typography>{props.value?.longitude}</Typography>
                                    </>
                                }
                            />
                        </ListItemStyled>
                    )}
                    Component={GeotagAddress}
                />
            );
        }
        default:
            // eslint-disable-next-line no-console
            console.log(`[fieldUtils] Unknown type "${type}"`);
            return <Text {...props} />;
    }
};

/**
 * If by the default we have empty DOM element, we need fo fill it for showing in sidebar.
 * @param type specified this behaviour by type of component.
 **/
export const addExtraSpace = (type: string) => {
    switch(type) {
        case 'image':
        case 'chip':
        case 'groupRepeat':
        case 'group':
        case 'displayText':
        case 'header':
        case 'classification':
        case 'geotagPosition':
            return <div style={{ height: '48px' }} key={190}>{/* we need something to drag */}</div>;
        default:
            return false;
    }
};

/**
 * Sets the values of the properties using the defaults values where the properties are not defined.
 *
 * @param properties the properties' values.
 * @param defaults the defaults' values.
 * @return the values of the properties using the defaults values where the properties are not defined.
 */
export const fillProperties = (properties: ?Object, defaults: ?Object): Object => {
    const propertiesValues = { ...properties };
    Object.keys(defaults).reduce((accum, key) => {
        if(['onChange', 'onLoad', 'isVisible', 'disabled'].includes(key) && typeof defaults[key] === 'function') {
            accum[key] = String(defaults[key]);
        } else {
            accum[key] = defaults[key];
        }
        return accum;
    }, {});
    defaults && Object.entries(defaults).forEach(([key, value]) => {
        if (propertiesValues[key] === undefined) {
            propertiesValues[key] = value;
        }
    });
    return propertiesValues;
};

export const fillPropertiesByType = (type: string, properties: ?Object): Object => {
    const defaults = getFieldDefaults(type);
    return fillProperties(properties, defaults);
};

const unitMap = {
    'm': 'minute(s)',
    'M': 'month(s)',
    'd': 'day(s)',
    'h': 'hour(s)',
    'Y': 'year(s)'
};
const rangeMap = {
    'subtract': 'Last',
    'add': 'Next',
};
export const buildDateTimaRangeStringValue = (val) => {
    return val
        ? val.relative
            ? `${rangeMap[val.range]} ${val.amount} ${unitMap[val.unit]}`
            : `${formatDate(val[0])} - ${formatDate(val[1])}`
        : '';
};
