/* @flow */

import { gql } from '@apollo/client';

export default gql`
    query profileQuery {
        user: profile {
            id
            primary(fields: ["username", "email", "lastUsedProcesses"])
            name
            createdDate
            modifiedDate
            image
            isAdmin
            permissions
            workspaces {
                id
                name
            }
            mustAcceptTerms
        }
    }
`;
