/* @flow */

import { onLoad, isVisible, isDisabled } from 'app/utils/designer/form/settings/common/commonEvents';
import { help } from 'app/utils/designer/form/settings/common/commonHelpers';
import { flexGrow } from 'app/utils/designer/form/settings/common/commonStyles';

export default {
    panels: settingValues => [
        {
            header: 'Base',
            children: [
                {
                    type: 'classificationTypeahead',
                    properties: {
                        label: 'Class',
                        name: 'properties.classification',
                    },
                    constraints: { required: true },
                },
                {
                    type: 'boolean',
                    properties: { label: 'Collapsed all', name: 'properties.isCollapsed' }
                },
            ],
        },
        {
            header: 'Events',
            children: [
                onLoad,
                isVisible,
                isDisabled
            ]
        },
        {
            header: 'Styles',
            children: [ flexGrow ]
        },
        {
            header: 'Helpers',
            children: [ help ]
        },
    ],
    defaults: { isCollapsed: false },
};
