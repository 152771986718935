import React, { forwardRef, useState, useCallback, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { Select, MenuItem, FormHelperText, InputLabel, FormControl } from '@mic3/platform-ui';
import { defaultRole } from 'app/config/rolesConfig';
import { capitalizeFirstLetter } from 'app/utils/utils';
import store from 'store/Store';
import { get } from 'app/utils/lo/lo';


const rolesMap = {
    viewer: ['viewer'],
    commenter: ['viewer', 'commenter'],
    editor: ['viewer', 'commenter', 'editor'],
};

const rolesLabelMap = {
    Viewer: 'Viewer',
    Commenter: 'Commentor',
    Editor: 'Editor',
};

const StyledSelect = styled(Select)`
    min-width: 105px;
`;

const RolesSelect = forwardRef((props, ref) => {
    const { defaulValue, className, withoutLabel, helperText, disabled, setRole, selectedRole } = props;

    const isAdmin = useMemo(() => {
        return get(store.getState(), 'user.profile.isAdmin', false); // We will always have profile as app will not load till profile is loaded. For more information please check AppRoute.js
    }, []);

    const [value, setValue] = useState(selectedRole || defaulValue || defaultRole); // default role is editor

    const handleChange = useCallback(
        (event) => {
            event.stopPropagation();
            setValue(event.target.value);
        },
        [setValue]
    );

    useEffect(() => {
        setRole && setRole(value);
    }, [value, setRole]);

    const rolesToShow = useMemo(() => {
        if (isAdmin) {
            return rolesMap.editor;
        }
        return (rolesMap[defaulValue] || []);
    }, [isAdmin, defaulValue]);

    return (
        <FormControl className={className}>
            {(!withoutLabel && !disabled) && <InputLabel id="demo-simple-select-helper-label">Select Role</InputLabel>}
            <StyledSelect inputRef={ref} value={value} disabled={disabled} onChange={handleChange} disableUnderline className={className} inputProps={{ 'aria-label': 'Without label' }}>
                {rolesToShow.map(role => (
                    <MenuItem key={role} value={role}>
                        {rolesLabelMap[capitalizeFirstLetter(role)]}
                    </MenuItem>
                ))}
            </StyledSelect>
            {helperText && <FormHelperText>Please select your role.</FormHelperText>}
        </FormControl>
    );
});

RolesSelect.defaultProps = {
    defaulValue: null,
    withoutLabel: false,
    helperText: false,
    disabled: false
};

export default RolesSelect;
