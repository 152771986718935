/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import AbstractClassificationPrimaryAbout from 'app/components/Classifications/ClassificationDetailAbout/AbstractClassificationPrimaryAbout';
import { safeToJS } from 'app/utils/trasformer/transformer';
import { bind } from 'app/utils/decorators/decoratorUtils';
import { openClassSidebar, closeClassSidebar } from 'store/actions/entities/classSidebarActions';
import { loadEntityWorkspaces } from 'store/actions/entities/entitiesActions';

/**
 * General tab in classifications view.
 * Todo: We probably should extract the form in it's own component, however
 * nearly the only code here is form related.
 */
class ClassificationPrimaryAbout extends PureComponent<Object, Object> {

    static propTypes: Object = {
        classification: PropTypes.object,
        entityType: PropTypes.bool,
    };

    @bind
    reloadSharing() {
        const { loadEntityWorkspaces, classification } = this.props;
        loadEntityWorkspaces('class', classification.id);
    }

    @bind
    openSidebar(title) {
        const details = safeToJS(this.props.classification);
        this.props.openClassSidebar({ id: details.id, type: 'class', title, reloadList: this.reloadSharing });
    }

    render() {
        const { entityType } = this.props;
        return (
            <AbstractClassificationPrimaryAbout
                {...this.props}
                openSidebar={this.openSidebar}
                closeClassSidebar={this.props.closeClassSidebar}
                entityType={entityType}
            />
        );
    }
}

export default connect(
    null,
    {
        openClassSidebar,
        closeClassSidebar,
        loadEntityWorkspaces,
    }
)(ClassificationPrimaryAbout);
