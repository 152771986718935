/* @flow */
import { combineReducers } from 'redux';

import {
    LOAD_ENTITY_ACTIVITIES_STARTED,
    LOAD_ENTITY_ACTIVITIES,
    LOAD_ENTITY_CLASSIFICATION_STARTED,
    LOAD_ENTITY_CLASSIFICATION,
    LOAD_ENTITY_STARTED, LOAD_ENTITY,
    LOAD_CLASSIFICATIONS_LIST_STARTED,
    LOAD_CLASSIFICATIONS_LIST,
    LOAD_ENTITY_WORKSPACES_STARTED, LOAD_ENTITY_WORKSPACES,
    LOAD_ENTITIES_LIST_STARTED,
    LOAD_ENTITIES_LIST,
    LOAD_COUNTRIES_LIST_STARTED,
    LOAD_COUNTRIES_LIST,
    LOAD_PRIMARY_CLASSES_STARTED,
    LOAD_PRIMARY_CLASSES,
    CREATE_ENTITY_STARTED,
    CREATE_ENTITY,
    LOAD_ENTITY_TYPES_TYPEAHEAD_STARTED, LOAD_ENTITY_TYPES_TYPEAHEAD,
    LOAD_ENTITY_SIDEBAR_STARTED, LOAD_ENTITY_SIDEBAR,
    LOAD_ENTITY_VERSIONS_STARTED, LOAD_ENTITY_VERSIONS
} from 'store/actions/entities/entitiesActions';
import {
    FETCH_RELATIONS_STARTED,
    FETCH_RELATIONS,
    FETCH_RELATION_DEFINITION_STARTED,
    FETCH_RELATION_DEFINITION,
} from 'store/actions/entities/relationshipsActions';
import { dataTableReducer, loadDataReducer, keepDataAtStart } from 'app/utils/redux/reducer-utils';

import avatar from './avatar/entitiesAvatarReducer';
import common from './common/entitiesCommonReducer';
import organisations from './organisations/organisationsReducer';
import people from './people/peopleReducer';
import directories from './directories/directoriesReducer';
import relationDefinitions from 'store/reducers/entities/relationDefinitions/relationDefinitionReducer';
import printTemplates from 'store/reducers/entities/printTemplates/printTemplateReducer';
import graphics from 'store/reducers/entities/graphic/graphicReducer';
import mapedRelations from 'store/reducers/entities/mapedRelations/mapedRelationsReducer';
import relatedEntities from 'store/reducers/entities/relatedEntities/relatedEntitiesReducer';
import relationsView from 'store/reducers/entities/relationships/relationsViewReducer';

export default combineReducers<Object, Object>({
    relationsView,
    avatar,
    primaryClasses: loadDataReducer(LOAD_PRIMARY_CLASSES_STARTED, LOAD_PRIMARY_CLASSES),
    createEntity: loadDataReducer(CREATE_ENTITY_STARTED, CREATE_ENTITY),
    common,
    entities: dataTableReducer(LOAD_ENTITIES_LIST_STARTED, LOAD_ENTITIES_LIST, keepDataAtStart),
    countries: loadDataReducer(LOAD_COUNTRIES_LIST_STARTED, LOAD_COUNTRIES_LIST),
    organisations,
    people,
    directories,
    relationDefinitions,
    relatedEntities,
    graphics,
    printTemplates,
    classesList: dataTableReducer(LOAD_CLASSIFICATIONS_LIST_STARTED, LOAD_CLASSIFICATIONS_LIST, keepDataAtStart),
    activities: dataTableReducer(LOAD_ENTITY_ACTIVITIES_STARTED, LOAD_ENTITY_ACTIVITIES),
    relations: loadDataReducer(FETCH_RELATIONS_STARTED, FETCH_RELATIONS),
    versions: dataTableReducer(LOAD_ENTITY_VERSIONS_STARTED, LOAD_ENTITY_VERSIONS),
    mapedRelations,
    entityTypes: loadDataReducer(LOAD_ENTITY_TYPES_TYPEAHEAD_STARTED, LOAD_ENTITY_TYPES_TYPEAHEAD, keepDataAtStart),
    relationDefinition: loadDataReducer(FETCH_RELATION_DEFINITION_STARTED, FETCH_RELATION_DEFINITION),
    classification: loadDataReducer(LOAD_ENTITY_CLASSIFICATION_STARTED, LOAD_ENTITY_CLASSIFICATION),
    sidebar: combineReducers({
        details: loadDataReducer(LOAD_ENTITY_STARTED, LOAD_ENTITY, keepDataAtStart),
        detailsInternal: loadDataReducer(LOAD_ENTITY_SIDEBAR_STARTED, LOAD_ENTITY_SIDEBAR),
        workspaces: loadDataReducer(LOAD_ENTITY_WORKSPACES_STARTED, LOAD_ENTITY_WORKSPACES),
    }),
});
