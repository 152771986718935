/* @flow */


import { combineReducers } from 'redux';

import { loadDataReducer, keepDataAtStart } from 'app/utils/redux/reducer-utils';
import { LOAD_API_KEYS_STARTED, LOAD_API_KEYS, LOAD_OFFLINE_SESSION_IDLE_TIMEOUT_STARTED, LOAD_OFFLINE_SESSION_IDLE_TIMEOUT } from 'store/actions/apiKeys/apiKeyActions';


const apiKeyReducer = combineReducers<Object, Object>({
    apiKeyList: loadDataReducer(LOAD_API_KEYS_STARTED, LOAD_API_KEYS, keepDataAtStart),
    idleTimeout: loadDataReducer(LOAD_OFFLINE_SESSION_IDLE_TIMEOUT_STARTED, LOAD_OFFLINE_SESSION_IDLE_TIMEOUT),
});



export default apiKeyReducer;
