/* @flow */

import { gql } from '@apollo/client';
import { defaultPipelineQueryFields } from './pipelineQuery';

export default gql`
    query pipelineStartedQuery($startIndex: Int, $stopIndex: Int, $filterBy: [JSON], $orderBy: [JSON], $type: String!) {
        records: entities(startIndex: $startIndex, stopIndex: $stopIndex, filterBy: $filterBy, orderBy: $orderBy, type: $type) {
            ${defaultPipelineQueryFields}
        }
    }
`;
