import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Grid, Typography } from '@mic3/platform-ui';

import { get } from 'app/utils/lo/lo';


const DivStyled = styled.div`
height: 16px;
width: 16px;
padding: 4px;
border-radius: 50%;
margin-right: 16px;
background: ${({ theme, value }) => get(theme.material.colors.priorityTaskColors, `${value}.1`)};
`;

const TypographyStyled = styled(Typography)`
color: ${({ theme, value }) => get(theme.material.colors.priorityTaskColors, `${value}.0`)};
font-size: 14px !important;
`;

const SryledGrid = styled(Grid)`
    display: flex;
    align-items: center;
    justify-content: flex-start;  
`;

const ValueLabel = styled(Typography)`
    color: '#FFFFFF';
    opacity: 70%;
    font-size: 14px;
`;


class GridEntityPriority extends PureComponent<Object, Object> {
  
    render() {
        const {getPriorityLabel, value} = this.props;
    
        return (
            <SryledGrid container wrap="nowrap" alignItems="center">
                <DivStyled value={value}></DivStyled>
                {getPriorityLabel(value) ? 
                    <TypographyStyled value={value}>{getPriorityLabel(value)}</TypographyStyled> 
                    : <ValueLabel>{value}</ValueLabel>}
            </SryledGrid>
        );
    }
}

export default GridEntityPriority;