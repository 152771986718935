import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { IconButton, MdiIcon, Box, Grid, Typography } from '@mic3/platform-ui';

import Title from 'app/components/atoms/Title/Title';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import Resizer from '../molecules/Resizer/Resizer';
import ErrorBoundary from '../atoms/ErrorBoundary/ErrorBoundary';

const Template = styled.div`
    background-color: ${({ theme }) => theme.color.background };
    color: ${({ theme }) => theme.material.colors.text.primary};
    ${({ fullScreen, isMobile }) => (!isMobile && !fullScreen) && 'padding-left: 6px' };
    ${({ isMobile, fullScreen }) => (isMobile || fullScreen) && 'z-index: 1200 !important' };
    ${({ activeSidebar }) => {
    return `&.sidebar-type-${activeSidebar} {
        z-index: 1100 !important;
    }`; }};

    @media(min-width: ${({ theme }) => theme.media.md}) {
        ${({ fullScreen, width }) => !fullScreen && `
            position: relative !important;
            width: ${width}px !important;
        `}
    }

    @media (max-width:961px){
        padding-top: ${({ fullScreen }) => fullScreen ? '0' : '56px' };
    }
    ${({ fullScreen }) => fullScreen && 'height: 100% !important;' }
`;

const Header = styled.div`
    overflow: hidden;
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    color: ${({ textColor, theme }) => theme && textColor
    ? theme.color[textColor]
    : theme.header.textColor};
`;

const Content = styled.div`
    ${({handleHorizonalScoll})=> handleHorizonalScoll && 'overflow-x:hidden'};   
    ${({ left }) => left ? 'margin-right: 6px' : ''};
    overflow: auto;
    height: 100%;
    li {
        ${({componentTitle})=> componentTitle === 'Related Entities' ? 'min-width: 200px' : ''};
    }
`;

const TitleWrapper = styled.div`
    overflow: hidden;
    padding: 8px 16px;
    h4 {
        color: ${({ theme }) => theme.material.colors.text.secondary };
    }
`;

const EmptySpace = styled.div`
    padding: 8px;
`;

const MdiIconStyled = styled(MdiIcon)`
    margin: 4px 8px 0 0;
    line-height: 14px !important;
`;

const SubTitleRight = styled(Typography)`
    padding-left: 4px;
    color: ${({ theme }) => theme.material.colors.disabled.main };
`;

const Actions = styled.div`
    position: relative;
    display: flex;
    ${({ left }) => left && 'margin-right: 8px;'}
`;

export default class Sidebar extends PureComponent {

    static propTypes = {
        title: PropTypes.string,
        iconName: PropTypes.string,
        iconType: PropTypes.string,
        title: PropTypes.string,
        fullScreen: PropTypes.bool,
        onClose: PropTypes.func,
        afterCloseSidebar: PropTypes.func,
        isResizing: PropTypes.func.isRequired,
        onResize: PropTypes.func.isRequired,
        sidebarWidth: PropTypes.number,
        hideClose: PropTypes.bool,
        activeSidebar: PropTypes.oneOf(['common', 'sidebar', 'filter']),
        actions: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
    }

    constructor(props) {
        super(props);
        const { minSidebarWidth } = props || {};
        this.sidebarRef = React.createRef();
        this.headerRef = React.createRef();
        this.state = { headerHeight: this.headerRef.clientHeight, minSidebarWidth };
    }

    componentDidMount() {
        this.setState({ headerHeight: this.headerRef.clientHeight });
    }

    componentDidUpdate() {
        if(this.headerRef.clientHeight !== this.state.headerHeight) {
            this.setState({ headerHeight: this.headerRef.clientHeight });
        }
    }

    @bind
    closeSidebar() {
        const { onClose, afterCloseSidebar } = this.props;
        if(afterCloseSidebar) {
            afterCloseSidebar();
        }
        onClose();
    }

    @bind
    @memoize()
    buildIcons(iconNameProp, iconTypeProp, actions, title) {
        let iconName = iconNameProp;
        let iconType = iconTypeProp;
        if(actions?.props?.items?.length) {
            actions?.props?.items.forEach(item => {
                if(item.name === title) {
                    iconName = item.icon;
                    iconType = item.iconType;
                }
            });
        }
        return { iconName, iconType };
    }

    @bind
    getMaximumWidth(id){
        const { minSidebarWidth } = this.state;
        const el = document.getElementById(id);
        let maxWidth = window.innerWidth - minSidebarWidth;
        if(el.offsetWidth > minSidebarWidth){
            maxWidth -= el.offsetWidth;
        }else{
            maxWidth -= minSidebarWidth;
        }
        return maxWidth;
    }

    @bind
    handleResize(width) {
        const { onResize, left} = this.props;
        const { minSidebarWidth } = this.state;
        let maxWidth;
        if(left){
            maxWidth = this.getMaximumWidth('global-right-panel');
        }else{
            maxWidth = this.getMaximumWidth('global-left-panel');
        }
        if(width > maxWidth ){
            return onResize(maxWidth);
        }else if(width < minSidebarWidth){
            return onResize(minSidebarWidth);
        }
        onResize(width);
    }

    render() {
        const { 
            hideClose, title, actions, className, subTitle, fullScreen, isResizing, withoutHeader,
            sidebarWidth, onBack, left, isMobile, activeSidebar, handleHorizonalScoll,
            iconName: iconNameProp, iconType: iconTypeProp, subTitleRight, minSidebarWidth
        } = this.props;
        const { headerHeight } = this.state;
        const isPwa = window.matchMedia('(display-mode: standalone)').matches;
        const fixHeightSize = isPwa ? 56 : 112;
        let closeButton = onBack
            ? <IconButton onClick={onBack}><MdiIcon name="arrow-left" /></IconButton>
            : <IconButton onClick={this.closeSidebar}><MdiIcon name="close" /></IconButton>;

        if (hideClose) {
            closeButton = null;
        }
        const { iconName, iconType } = this.buildIcons(iconNameProp, iconTypeProp, actions, title);
        return (
            <Template className={`no-print ${className}`} activeSidebar={activeSidebar} fullScreen={fullScreen} isMobile={isMobile} width={sidebarWidth} ref={this.sidebarRef}>
                {
                    (!isMobile && !fullScreen) &&
                    <Resizer left={left} size={sidebarWidth} isMobile={isMobile} isResizing={isResizing}
                        onResize={this.handleResize} resizeElem={this.sidebarRef} minSidebarWidth={minSidebarWidth} />
                }
                {!withoutHeader ? (
                    <Header ref={element => this.headerRef = element}>
                        <Box>
                            {!left && (closeButton  || <EmptySpace />)}
                        </Box>
                        <TitleWrapper>
                            {left ? (
                                <Title as="h4" disableSelection={isMobile}>{title}</Title>
                            ): (
                                <>
                                    <Grid container>
                                        {!!iconName && <MdiIconStyled size={16} name={iconName} type={iconType || 'mdi'} />}
                                        <Title as="h2">{title}</Title>
                                    </Grid>
                                    <Grid container>
                                        <Title as="h4">{subTitle}</Title>
                                        <SubTitleRight variant="caption">{subTitleRight}</SubTitleRight>
                                    </Grid>
                                </>
                            )}
                        </TitleWrapper>
                        <Actions left={left}>
                            {actions}
                        </Actions>
                    </Header>
                ) : (<Header ref={element => this.headerRef = element}/>) }
                <Content 
                    isMobile={isMobile} 
                    left={left}
                    fullScreen={fullScreen}
                    headerHeight={headerHeight}
                    handleHorizonalScoll={handleHorizonalScoll}
                    fixHeightSize={fixHeightSize}
                    componentTitle={title}
                >
                    <ErrorBoundary>
                        {this.props.children}
                    </ErrorBoundary>
                </Content>
            </Template>
        );
    }
}
