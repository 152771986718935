/* @flow */

import React, { PureComponent } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, CircularProgress, Typography } from '@mic3/platform-ui';

import Container from 'app/components/atoms/Container/Container';
import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import PageTemplate from 'app/components/templates/PageTemplate';
import HeaderBar from 'app/components/molecules/HeaderBar/HeaderBar';
import Help from 'app/utils/designer/form/settings/common/Help';
import { copyToClipboard } from 'app/utils/classification/classificationUtils';
import { setDocumentTitle, showToastr } from 'store/actions/app/appActions';
import { getOnlyUpdatedData } from 'app/utils/app/appUtils';
import { get } from 'app/utils/lo/lo';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { updateSecurityGeneral } from 'store/actions/app/appActions';
import { getPermissions } from 'app/config/rolesConfig';
import { modulesAndPageTitles } from 'app/config/typesConfig';

const StyledTypography = styled(Typography)`
    color: ${({theme})=> theme.material.colors.text.primary};
`;

class GeneralSettings extends PureComponent<Object, Object> {
    static propTypes = {
        updateEntity: PropTypes.func.isRequired,
        uploadImage: PropTypes.func.isRequired,
    };

    state = {
        formKey: 1,
        formTouched: false,
    };

    formRef: Object = React.createRef();

    formDefinitions = [
        {
            type: 'panel',
            properties: {
                header: 'Single Sign On',
                expanded: true,
            },
            children: [
                {
                    type: 'boolean',
                    properties: {
                        label: 'Send email when user is created',
                        name: 'sendEmailOnUserCreate',
                    },
                    constraints: { required: true },
                },
                { type: 'divider' },
                {
                    type: 'button',
                    properties: {
                        onClick: this.copyIdToClipBoard,
                        label: 'copy public key',
                        variant: 'text',
                        fullWidth: false,
                    },
                },
                { type: 'divider' },
                {
                    type: 'arrayStringEditor',
                    properties: {
                        name: 'redirectUris',
                        label: 'Valid redirect URIs',
                        noDuplicates: true,
                        help: (
                            <Help message="Valid URI pattern a browser can redirect to after a successful login. Simple wildcards are allowed such as 'http://example.com/*'. Relative path can be specified too such as \/my\/relative\/path\/\*. Relative paths are relative to the client root URL, or if none is specified the auth server root URL is used. For SAML, you must set valid URI patterns if you are relying on the consumer service URL embedded with the login request." />
                        ),
                    },
                    constraints: { required: true },
                },
                { type: 'divider' },
                {
                    type: 'arrayStringEditor',
                    properties: {
                        name: 'webOrigins',
                        label: 'Web origins',
                        noDuplicates: true,
                        help: (
                            <Help message="Allowed CORS origins. To permit all origins of Valid Redirect URIs, add '+'. This does not include the '*' wildcard though. To permit all origins, explicitly add \'\*\'" />
                        ),
                    },
                    constraints: { required: true },
                },
            ],
        },
        {
            type: 'panel',
            properties: {
                header: 'OTP Policy',
                expanded: true,
            },
            children: [
                {
                    type: 'group',
                    properties: { name: 'otpPolicy' },
                    children: [
                        {
                            type: 'typeahead',
                            properties: {
                                name: 'otpPolicyType',
                                label: 'OTP Type',
                                options: [
                                    { value: 'totp', label: 'Time Based' },
                                    { value: 'hotp', label: 'Counter Based' },
                                ],
                                help: (
                                    <Help message="totp is Time-Based One Time Password. 'hotp' is a counter base one time password in which the server keeps a counter to hash against." />
                                ),
                            },
                            constraints: { required: true },
                        },
                        {
                            type: 'typeahead',
                            properties: {
                                name: 'otpPolicyAlgorithm',
                                label: 'OTP Hash Algorithm',
                                options: [
                                    { value: 'HmacSHA1', label: 'SHA 1' },
                                    { value: 'HmacSHA256', label: 'SHA 256' },
                                    { value: 'HmacSHA512', label: 'SHA 512' },
                                ],
                                help: <Help message='What hashing algorithm should be used to generate the OTP.' />,
                            },
                            constraints: { required: true },
                        },
                        {
                            type: 'typeahead',
                            properties: {
                                name: 'otpPolicyDigits',
                                label: 'Number of digits',
                                options: [
                                    { value: 6, label: '6' },
                                    { value: 8, label: '8' },
                                ],
                                help: <Help message='How many digits should the OTP have?' />,
                            },
                            constraints: { required: true },
                        },
                        {
                            type: 'number',
                            properties: {
                                name: 'otpPolicyLookAheadWindow',
                                label: 'Look ahead window',
                                help: (
                                    <Help message='How far around should the server look just in case the token generator and server are out of time sync or counter sync?' />
                                ),
                            },
                            constraints: {
                                required: true,
                                onlyInteger: true,
                                min: 1,
                            },
                        },
                        {
                            type: 'number',
                            properties: {
                                name: 'otpPolicyPeriod',
                                label: 'OTP token period',
                                isVisible: (data) => data.otpPolicyType === 'totp',
                                help: <Help message='How many seconds should an OTP token be valid? Defaults to 30 seconds.' />,
                            },
                            constraints: {
                                onlyInteger: true,
                                min: 1,
                                max: 120,
                            },
                        },
                        {
                            type: 'boolean',
                            properties: {
                                name: 'otpPolicyCodeReusable',
                                label: 'Reusable token',
                                isVisible: (data) => data.otpPolicyType === 'totp',
                                help: <Help message='Possibility to use the same OTP code again after successful authentication.' />,
                            },
                            constraints: { required: true },
                        },
                        {
                            type: 'number',
                            properties: {
                                name: 'otpPolicyInitialCounter',
                                label: 'Initial Counter',
                                isVisible: (data) => data.otpPolicyType === 'hotp',
                            },
                            constraints: {
                                required: true,
                                onlyInteger: true,
                                min: 1,
                            },
                        },
                    ],
                },
            ],
        },
        {
            type: 'panel',
            children: [
                {
                    type: 'displayText',
                    properties: {
                        text:
                            '[All OpenID Endpoint Configuration](/iam/auth/realms/Affectli/.well-known/openid-configuration)\n\n[OpenID certificates](/iam/auth/realms/Affectli/protocol/openid-connect/certs)\n\n[SAML 2.0 Identity Provider Metadata](/iam/auth/realms/Affectli/protocol/saml/descriptor)',
                    },
                },
            ],
            properties: {
                header: 'Endpoints',
                expanded: true,
                collapsible: true,
                editablePanel: false,
            },
        },
    ];

    componentDidMount() {
        this.props.setDocumentTitle(modulesAndPageTitles.adminConsole.security.general);
    }


    @bind
    copyIdToClipBoard() {
        const { ssoSettings } = this.props;
        copyToClipboard(ssoSettings.publicKey)
            .then(() => {
                this.props.showToastr({ severity: 'success', detail: 'Public key copied to clipboard' });
            })
            .catch(() => {
                this.props.showToastr({ severity: 'error', detail: 'Public key could not copied to clipboard' });
            });
    }

    @bind
    onChange() {
        this.setState({ formTouched: true });
    }

    @bind
    onFormSubmit(event: Event) {
        event.preventDefault();
        this.formRef.current.isValidForm().then(({ data: settings, errors }) => {
            if (!errors) {
                const { ssoSettings, updateSecurityGeneral } = this.props;
                updateSecurityGeneral(getOnlyUpdatedData(ssoSettings, settings));
                this.setState({ formTouched: false });
            }
        });
    }

    @bind
    revertChanges() {
        this.setState((prevState) => ({ formKey: prevState.formKey + 1, formTouched: false }));
    }

    @bind
    @memoize()
    renderSaveButton(isLoading, canEdit, formTouched) {
        if (!canEdit) return null;
        return isLoading ? (
            <CircularProgress key={113} size={24} color='primary' />
        ) : (
            <>
                <Button key={113} onClick={this.onFormSubmit} color='primary' form='form' type='submit' style={{ marginRight: '16px' }}>
                    Save
                </Button>
                <Button disabled={!formTouched} variant='outlined' onClick={this.revertChanges} color='primary' form='form'>
                    Revert
                </Button>
            </>
        );
    }

    render() {
        const { ssoSettings, isLoading } = this.props;
        const { formTouched } = this.state;
        const { canEdit } = getPermissions(ssoSettings?.role);
        return (
            <PageTemplate title={'Security General'} overflowHidden>
                <HeaderBar
                    right={[this.renderSaveButton(isLoading, canEdit, formTouched)]}
                    left={<StyledTypography variant='h6'>Security General</StyledTypography>}
                />
                <ContentArea withHeader>
                    <Container width='1024'>
                        <FormGenerator
                            key={this.state.formKey}
                            components={this.formDefinitions}
                            data={ssoSettings}
                            ref={this.formRef}
                            disabled={!canEdit}
                            onChange={this.onChange}
                        />
                    </Container>
                </ContentArea>
            </PageTemplate>
        );
    }
}

export default connect(
    (state) => ({
        isLoading: state.app.ssoSettingsLoading,
        ssoSettings: get(state.app, 'ssoSettings', {}),
    }),
    {
        updateSecurityGeneral,
        showToastr,
        setDocumentTitle
    }
)(GeneralSettings);
