import React, { memo, useRef, useCallback, useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Button, CircularProgress, Typography, ListItem, ListItemIcon, ListItemText } from '@mic3/platform-ui';

import { updateUserPassword, loadLoginActions, updateLoginActions } from 'store/actions/admin/usersActions';
import { loadSecurityGeneral } from 'store/actions/app/appActions';

import { formatDate, fromNow } from 'app/utils/date/date';
import { useToggle } from 'app/utils/hook/hooks';
import theme from 'app/themes/theme.default';

import ExpansionCard from 'app/components/molecules/ExpansionCard/ExpansionCard';
import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import Icon from 'app/components/atoms/Icon/Icon';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';

const EmailField = styled.div`
    display: flex;
    align-items: center;
`;
const TypographyStyled = styled(Typography)`
    color:  ${({ theme }) => theme.material.colors.text.label};
`;

const UserCredentialsSection = connect(
    (state, props) => ({
        terms: state.app.terms,       
        isMobile: state.global.isMobile,
        loginActions: state.user.loginActions,
        availableRequiredActions: state.app.configurations?.affectli?.sso?.availableRequiredActions,
        availableProviders: state.app.configurations?.affectli?.sso?.availableIdentityProviders,
        ssoSettings: state.app.ssoSettings,
    }),
    { updateUserPassword, loadLoginActions, updateLoginActions, loadSecurityGeneral }
)(({ 
    loadSecurityGeneral, isMobile, user, openSidebar, loadEntity, ssoSettings,
    availableRequiredActions, isLoading, updateUserPassword, loadLoginActions, 
    loginActions, updateLoginActions, availableProviders, terms, onChangeProvider
}: Object) => {
    const formPasswordRef = useRef();
    const formLoginActionRef = useRef();
    const formDefaultRoviderRef = useRef();
    const [loginActionData, setLoginActionData] = useState({});
    const [openChangePasswordModal, toggleChangePasswordModal] = useToggle(false);
    const [openLoginActionModal, toggleLoginActionModal] = useToggle(false);

    useEffect(() => {
        user?.primary?.username && loadLoginActions({ username: user?.primary?.username });
    }, [user?.primary?.username, loadLoginActions]);

    useEffect(() => {
        setLoginActionData({
            CONFIGURE_TOTP: loginActions.includes('CONFIGURE_TOTP'),
            UPDATE_PASSWORD: loginActions.includes('UPDATE_PASSWORD'),
            VERIFY_EMAIL: loginActions.includes('VERIFY_EMAIL'),
        });
    }, [loginActions]);

    const handleChangePassword = useCallback(async () => {
        const { errors, data } = await formPasswordRef.current.isValidForm();
        if(!errors) {
            const result = await updateUserPassword(user.primary.username, data.password);
            if(result.changeUserPassword) {
                toggleChangePasswordModal();
            }
        }
    }, [formPasswordRef, toggleChangePasswordModal, updateUserPassword, user.primary.username]);

    const handleChangeLoginActions = useCallback(async () => {
        const { errors, data: actions } = await formLoginActionRef.current.isValidForm();
        if(!errors) {
            const result = await updateLoginActions(user.primary.username, actions);
            if(result.updateUserLoginActions) {
                toggleLoginActionModal();
                loadLoginActions({ username: user?.primary?.username });
            }
        }
    }, [formLoginActionRef, loadLoginActions, toggleLoginActionModal, updateLoginActions, user.primary.username]);

    const handleChangeDefaultRrovider = useCallback(async () => {
        const { errors, data } = await formDefaultRoviderRef.current.isValidForm();
        if(!errors) {
            onChangeProvider(data.defaultIdentityProvider);
        }
    }, [onChangeProvider]);

    const termsObj = useMemo(() => {
        const acceptDate = user?.primary?.termsAcceptedDate;

        if (!terms?.enabled) return null;
        
        if (!acceptDate) {
            return {
                icon: 'alert-circle',
                iconColor: theme.color.error,
                label: 'Has not agreed to Terms & Conditions'
            };
        }

        const termsStartDate = new Date(terms?.startDate);
        const userAcceptDate = new Date(acceptDate);
        const fromNowDate = fromNow(formatDate(acceptDate));
        
        if (termsStartDate <= userAcceptDate) {
            return {
                icon: 'check',
                iconColor: theme.color.success,
                label: `Agreed to Terms & Conditions ${fromNowDate}`,
                secondaryLabel: 'Up to date'
            };
        } else if (termsStartDate > userAcceptDate) {
            return {
                icon: 'alert-circle',
                iconColor: theme.color.error,
                label: `Agreed to Terms & Conditions ${fromNowDate}`,
                secondaryLabel: 'Expired'
            };
        }

        return null;
    }, [user?.primary, terms]);

    const isOtpEnabled = useMemo(() => {
        return availableRequiredActions.includes('CONFIGURE_TOTP');
    }, [availableRequiredActions]);

    const isUpdatePasswordEnabled = useMemo(() => {
        return availableRequiredActions.includes('UPDATE_PASSWORD');
    }, [availableRequiredActions]);

    const isVerifyEmailEnabled = useMemo(() => {
        return availableRequiredActions.includes('VERIFY_EMAIL');
    }, [availableRequiredActions]);

    const defaultIdentityProvider = useMemo(() => {
        return availableProviders.includes(user?.primary?.defaultIdentityProvider) ? user?.primary?.defaultIdentityProvider : null;
    }, [user?.primary, availableProviders]);

    return (
        <>
            <ExpansionCard
                expanded
                title="Credentials"
            >
                {termsObj != null && (
                    <ListItem disableGutters>
                        <ListItemIcon>
                            <Icon name={termsObj.icon} hexColor={termsObj.iconColor} />
                        </ListItemIcon>
                        <ListItemText
                            primary={termsObj.label}
                            secondary={termsObj.secondaryLabel}
                        />
                    </ListItem>
                )}
                <EmailField>
                    <Button variant="text" onClick={toggleChangePasswordModal}>reset password</Button>
                    <Button disabled={!isOtpEnabled && !isUpdatePasswordEnabled && !isVerifyEmailEnabled } variant="text" onClick={toggleLoginActionModal}>Login actions</Button>
                </EmailField>
                <FormGenerator 
                    root={false} 
                    onChange={handleChangeDefaultRrovider}
                    ref={formDefaultRoviderRef}
                    data={{ defaultIdentityProvider }}
                    components={[
                        { type: 'divider' },
                        {
                            type: 'typeahead',
                            properties: {
                                name: 'defaultIdentityProvider',
                                label: 'Default identity provider',
                                options: availableProviders.map(provider => {
                                    const { identityProviders } = ssoSettings || {};
                                    if(identityProviders) {
                                        const { displayName, enabled, alias } = identityProviders.find(prvdr => prvdr.alias === provider);
                                        return { value: alias, label: `${displayName || alias}${enabled ? '' : ' (Disabled)'}` };
                                    } else {
                                        return { value: provider, label: provider };
                                    }
                                })
                            }
                        }
                    ]}
                />
            </ExpansionCard>
            {openChangePasswordModal && (
                <ModalDialog
                    title="Reset Password"
                    onClose={toggleChangePasswordModal}
                    showDividerAfterTitle
                    withoutClose
                    bgcolor={'rgba(40, 51, 75, 1)'}
                    actions={
                        (isLoading) ? (
                            <CircularProgress size={24} color="primary" />
                        ) : (
                            <>
                                <Button variant="text" onClick={toggleChangePasswordModal}>
                                    Cancel
                                </Button>
                                <Button onClick={handleChangePassword}>
                                    Save
                                </Button>
                            </>
                        )
                    }
                    footer={<TypographyStyled variant="caption">* All fields are required</TypographyStyled>}
                >
                    <Typography variant="subtitle2">The user will be required to reset their password at next login.</Typography>
                    <form id='random-string' autoComplete='off'>
                        <FormGenerator
                            root={false} 
                            components={[{
                                type: 'password',
                                properties: {
                                    name: 'password',
                                    label: 'Password',
                                    type: 'password'
                                },
                                constraints: { required: true }
                            }, {
                                type: 'password',
                                properties: {
                                    name: 'passwordConfirmation',
                                    label: 'Password confirmation'
                                },
                                constraints: { required: true, custom: (data) => {
                                    const passwordsIsnotEqual = { format: { pattern: /somefaketest/, message: `Password doesn't match.` }};
                                    if (data.password !== data.passwordConfirmation) {
                                        return passwordsIsnotEqual;
                                    }
                                    return {};
                                }}                            
                            }]} 
                            ref={formPasswordRef}
                        />
                    </form>
                </ModalDialog>
            )}            
            {openLoginActionModal && (
                <ModalDialog
                    title="Login Actions"
                    onClose={toggleLoginActionModal}
                    showDividerAfterTitle
                    withoutClose
                    bgcolor={'rgba(40, 51, 75, 1)'}
                    actions={
                        (isLoading) ? (
                            <CircularProgress size={24} color="primary" />
                        ) : (
                            <>
                                <Button variant="text" onClick={toggleLoginActionModal}>
                                    Cancel
                                </Button>
                                <Button onClick={handleChangeLoginActions}>
                                    Save
                                </Button>
                            </>
                        )
                    }
                >
                    <Typography variant="subtitle2">The user must</Typography>
                    <FormGenerator
                        root={false} 
                        data={loginActionData}
                        components={[
                            {
                                type: 'checkbox',
                                properties: {
                                    disabled: !isOtpEnabled,
                                    name: 'CONFIGURE_TOTP',
                                    label: 'Configure their two-factor authentication (OTP)',
                                },
                            },                             
                            {
                                type: 'checkbox',
                                properties: {
                                    disabled: !isUpdatePasswordEnabled,
                                    name: 'UPDATE_PASSWORD',
                                    label: 'Update their password',
                                },
                            }, 
                            {
                                type: 'checkbox',
                                properties: {
                                    disabled: !isVerifyEmailEnabled,
                                    name: 'VERIFY_EMAIL',
                                    label: 'Verify their email address',
                                },
                            }, 
                        ]} 
                        ref={formLoginActionRef}
                    />
                </ModalDialog>
            )}            
        </>
    );
});

export default memo(UserCredentialsSection);
