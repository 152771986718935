/* @flow */

import { gql } from '@apollo/client';

export default gql`
    query designerProcessListQuery($startIndex: Int, $stopIndex: Int, $filterBy: [JSON], $orderBy: [JSON], $excludeBy: [JSON]) {
        count: count(dataType: "processdefinition", filterBy: $filterBy, excludeBy: $excludeBy)
        records: processDefinitions(startIndex: $startIndex, stopIndex: $stopIndex, filterBy: $filterBy, orderBy: $orderBy, excludeBy: $excludeBy) {
            id
            name
            svg
            type
            modifiedDate
            modifiedBy {
                id
                name
            }
        }
    }
`;
