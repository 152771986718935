/* @flow */

// $FlowFixMe
import { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import { set } from 'app/utils/lo/lo';

export const usePageActive = () => {
    const [isActive, setActive] = useState<boolean>(true);
    
    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                setActive(true);
            } else {
                setActive(false);
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);

    return isActive;
};

export const usePrevious = (value: any) => {
    const ref = useRef(value);
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
};


export const useToggle = (defaultState: boolean = false) => {
    const [isOpen, show] = useState<boolean>(defaultState);
    const toggle = useCallback((e: Object) => {
        if(e && e.stopPropagation) {
            e.stopPropagation();
            e.preventDefault();
        }
        show(!isOpen);
    }, [isOpen]);
    return [isOpen, toggle, show];
};

/**
 * Use this hook to handle the variable changes providing an additional onChange function.
 *
 * @param value the property value
 * @param refresh if "true" it refreshes the value on every change, otherwise it set the value only the first time. This flag shouldn't be modified during the hook life cycle!
 *
 * @return three items: the value, the onChange function, the set function.
 */
export const useOnChange = (value: any, refresh: ?boolean = true) => {
    const [val, setValue] = useState<any>(value);
    refresh && useEffect(() => { // eslint-disable-line react-hooks/rules-of-hooks
        setValue(value);
    }, [value]);
    const onChange = useCallback((e: Object) => {
        setValue(e.target.value);
    }, [setValue]);
    return [val, onChange, setValue];
};

/**
 * Use a Redux action.
 *
 * @param action the action (required). If the action is asyncronous it must return a Promise.
 * @param paramters an array containing the action parameters (optional).
 * @param disableUI the function to disable the UI (optional).
 * @param onSuccess the function to call on success (optional).
 * @param onError the function to call on error (optional).
 */
export const useReduxAction = (
    {
        action,
        parameters,
        disableUI,
        onSuccess,
        onError,
    }: {
        action: Function,
        parameters?: Array<any>,
        disableUI?: Function,
        onSuccess?: Function,
        onError?: Function,
    }
) =>
    useCallback(() => {
        disableUI && disableUI(true);
        const result = parameters
            ? action(...parameters)
            : action();
        const promise = result instanceof Promise ? result : Promise.resolve(result);
        return promise.then((res) => {
            disableUI && disableUI(false);
            if (res instanceof Error) {
                onError && onError(res);
            } else {
                onSuccess && onSuccess(res);
            }
            return res;
        });
    }, [ parameters, action, disableUI, onError, onSuccess ]);


export const useForm = (initialState: Object, onSubmit: Function) => {
    // $FlowFixMe
    const [form, setForm] = useState(initialState);

    const handleChange = useCallback((event: Object) => {
        if(event.persist) {
            event.persist();
        }
        setForm(form => set({ ...form }, event.target.name, event.target.type === 'checkbox' ? event.target.checked : event.target.value));
    }, []);

    const resetForm = useCallback(() => {
        setForm(initialState);
    }, [initialState]);

    const setInForm = useCallback((name: string, value: any) => {
        setForm(form => set(form, name, value));
    }, []);

    const handleSubmit = useCallback((event: Object) => {
        if (event) {
            event.preventDefault();
        }
        if (onSubmit) {
            onSubmit();
        }
    }, [onSubmit]);

    return {
        form,
        handleChange,
        handleSubmit,
        resetForm,
        setForm,
        setInForm,
    };
};

export const useOnScreen = (ref) => {
    const [isIntersecting, setIntersecting] = useState(false);

    const observer = useMemo(() => new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting)), []);

    useEffect(() => {
        observer.observe(ref.current);
        // Remove the observer as soon as the component is unmounted
        return () => {
            observer.disconnect();
        };
    }, [observer, ref]);

    return isIntersecting;
};
