/* @flow */
import React, { PureComponent } from 'react';
import { TextField, Divider, Box, Link, Switch, Tooltip, Avatar, Button } from '@mic3/platform-ui';
import styled from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import AvatarGroup from 'app/components/molecules/Avatar/AvatarGroup';
// import Button from 'app/components/atoms/Button/Button';
import Icon from 'app/components/atoms/Icon/Icon';

import { bind } from 'app/utils/decorators/decoratorUtils';
import { isEmpty } from 'app/utils/utils';

const inputProps: Object = { disableUnderline: true };
const StyledDivider = styled(Divider)`
    margin: ${({ margin }) =>
    margin ? `${margin} !important` : '5px 0 !important'};
`;

const StyledBox = styled(Box)`
    ${({ margin }) => (margin ? `${margin} !important` : '0')};
    ${({ padding }) => (padding ? `${padding} !important` : '0')};
    display: flex;
    align-items: center;
    width: 100%;
`;
const StyledTextField = styled(TextField)`
    border-radius: 4px 4px 0px 0px !important;
    background: ${({theme})=> theme.material.colors.background.fields} !important;
    input {
        min-height: 32px !important;
        padding: 12px;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.15px;
        color: ${({theme})=> theme.material.colors.text.secondary};
        mix-blend-mode: normal;
    }
`;
const StyledLink = styled(Link)`
    white-space: nowrap;
    text-decoration: none !important;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: ${({theme})=> theme.material.colors.link};
`;
const StyledIcon = styled(Icon)`
    margin-right: 20px;
    font-size: 14px;
    &:before {
        color: ${({theme})=>theme.material.colors.background.activeElement} !important;
    }
`;
const StyledLabel = styled.div`
    margin: ${({ margin }) => (margin ? `${margin} !important` : '0')};
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: ${({theme})=> theme.material.colors.text.secondary};
    mix-blend-mode: normal;
`;
const StyledLabel2 = styled.div`
    margin: ${({ margin }) => (margin ? `${margin} !important` : '0')};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: ${({theme})=> theme.material.colors.text.primary};
    mix-blend-mode: normal;
`;
const StyledSubLabel = styled.div`
    margin: ${({ margin }) => (margin ? `${margin} !important` : '0')};
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: ${({theme})=> theme.material.colors.text.primary};
    mix-blend-mode: normal;
`;
const StyledAvatarGroup = styled(AvatarGroup)`
    .MuiAvatarGroup-avatar {
        border: none !important;
    }
`;
const StyledAvatar = styled(Avatar)`
    width: 24px !important;
    height: 24px !important;
    font-size: 12px !important;
    color: #ffffff !important;
    margin: 0 8px !important;
`;
const CancelButton = styled(Button)`
    margin: 0;
`;

class ChatCreateChannelDialog extends PureComponent<Object, Object> {
    static propTypes = {
        data: PropTypes.shape({
            name: PropTypes.string,
            members: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.string,
                    name: PropTypes.string
                })
            ),
            isPublic: PropTypes.bool,
            readOnly: PropTypes.bool
        }),
        isOpen: PropTypes.bool,
        openRsDialog: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired,
        onCreateChannelDataChange: PropTypes.func.isRequired,
        closeModal: PropTypes.func.isRequired,
        isMobile: PropTypes.bool
    };

    @bind
    async onSubmit() {
        await this.props.onSubmit();
        this.props.closeModal();
    }

    @bind
    onFormChange({ target }) {
        const { name, value } = target;
        const { data, onCreateChannelDataChange } = this.props;
        onCreateChannelDataChange({ ...data, [name]: value});
       
        
    }

    render() {
        const {
            isOpen,
            openRsDialog,
            closeModal,
            data: { members = [] } = {},
            isMobile
        } = this.props;

        return (
            isOpen && (
                <ModalDialog
                    dialogProps={{ overflow: 'hidden' }}
                    withoutClose={!isMobile}
                    onClose={closeModal}
                    title={'Create new channel'}
                    actions={
                        <>
                            {!isMobile && (<CancelButton onClick={closeModal} variant='text' size="small" >Cancel</CancelButton>)}
                            <Button
                                disabled={isEmpty(this.props.data.name)}
                                color='primary'
                                onClick={this.onSubmit}
                                size="small"
                            >
                                Save
                            </Button>
                        </>
                    }
                >
                    <StyledDivider margin='18px 0 24px 0' />
                    <StyledTextField
                        name='name'
                        variant='standard'
                        fullWidth
                        autoComplete='off'
                        clearable={false}
                        margin='none'
                        placeholder='Channel name*'
                        onChange={this.onFormChange}
                        value={this.props.data.name}
                        InputProps={inputProps}
                    />
                    <StyledLabel margin='24px 0 14px 0'>Members</StyledLabel>
                    <StyledBox>
                        <StyledIcon name='plus' onClick={openRsDialog} />
                        <StyledLink onClick={openRsDialog}>
                            Add members
                        </StyledLink>
                        <StyledAvatarGroup max={!isMobile ? 10 : 5}>
                            {(members || []).map((member, index) => {
                                return (
                                    <span key={index}>
                                        <Tooltip
                                            title={member.name}
                                            alt={member.name}
                                        >
                                            <span alt={member.name}>
                                                <StyledAvatar
                                                    key={index}
                                                    max={!isMobile ? 10 : 5}
                                                    initials={member.name}
                                                    src={member.image}
                                                />
                                            </span>
                                        </Tooltip>
                                    </span>
                                );
                            })}
                        </StyledAvatarGroup>
                    </StyledBox>
                    <StyledDivider margin='32px 0 24px 0' />
                    <StyledBox padding='0 12px'>
                        <Switch
                            value={this.props.data.isPublic}
                            onChange={this.onFormChange}
                            name='isPublic'
                        />
                        <div>
                            <StyledLabel2>Public</StyledLabel2>
                            <StyledSubLabel>
                                {this.props.data.isPublic
                                    ? 'Everyone can access this channel'
                                    : 'Only invited users can access this channel'}
                            </StyledSubLabel>
                        </div>
                    </StyledBox>
                    <StyledBox margin='28px 0 0 0' padding='0 12px'>
                        <Switch
                            value={this.props.data.readOnly}
                            onChange={this.onFormChange}
                            name='readOnly'
                        />
                        <div>
                            <StyledLabel2>Read Only</StyledLabel2>
                            <StyledSubLabel>
                                Only admins/owners are allowed to sent messages
                            </StyledSubLabel>
                        </div>
                    </StyledBox>
                    <StyledDivider margin='32px 0 15px 0' />
                    <StyledLabel margin='0 0 5px'>Required fields*</StyledLabel>
                </ModalDialog>
            )
        );
    }
}

export default connect(
    state => ({ isMobile: state.global.isMobile }),
    {}
)(ChatCreateChannelDialog);
