/* @flow */
import React from 'react';

import TeamSidebar from 'app/containers/Sidebars/TeamSidebar';

import {
    buildSidebar as buildSidebarAction,
    closeSidebar as closeSidebarAction
} from 'store/actions/sidebar/sidebarActions';

export const openTeamSidebar = ({ id, title }) => async (dispatch, getState) => {
    return buildSidebarAction({
        title,
        content: <TeamSidebar id={id} title={title} />,
        isOpen: true,
    })(dispatch, getState);
};

export const closeTeamSidebar = closeSidebarAction;
