/* @flow */

import { gql } from '@apollo/client';

export const digitalTwinQueryFields = `
    digitalTwins {
        id
        isDefault
        name
        type
        script {
            id
            name
        }
        src
    }
`;

export const digitalTwinShortQueryFields = `
    digitalTwins {
        name
    }
`;
export const classDetailsFields = `
        id
        uri
        name
        description
        formDefinition
        primary
        icon
        iconType
        abstract
        active
        color
        applicableOn
        openInModule
        hidden
        entityTabs
        entityAppearance
        entitySvg
        entityGraphic {
        id
        type
        name
        image
        }
        entitySvgTransformation
        entitySvgVisibleZoom
        parents {
            id
            name
            uri
            color
            icon
            iconType
        }
        entityModel3d
        entityModelHeading
        entityModelScaling
        entityPrimaryIndexes{
            entityType {
                id
            }
            primaryAttribute
            isUnique
            type
            state
        }
        createdDate
        createdBy {
            name
            image
            id
        }
        modifiedDate
        modifiedBy {
            name
            image
            id
        }
        chatId
        isSystem
        reportingEnabled
        ${digitalTwinQueryFields}
        
`;

export default gql`
    query classificationDetailQuery($id: ID!) {
        result: class(id: $id) {
            ${classDetailsFields}
        }
        role: resourcePermission(type: "class", id: $id)
    }
`;
