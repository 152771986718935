import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import FileForm from 'app/components/organisms/Chat/FileForm';

class ChatFileUploadModal extends PureComponent<Object, Object> {
    static propTypes = {
        file: PropTypes.object,
        isUploading: PropTypes.bool,
        uploadFile: PropTypes.func.isRequired,
        closeFileForm: PropTypes.func.isRequired
    };
    
    formFields = [
        {
            field: 'filename',
            type: 'text',
            properties: {
                name: 'filename',
                label: 'File Name'
            },
            constraints: { required: true }
        },
        {
            field: 'description',
            type: 'text',
            properties: {
                name: 'description',
                label: 'File Description'
            }
        }
    ];

    render() {
        const { file, isUploading, closeFileForm, uploadFile } = this.props;

        return (
            <ModalDialog onClose={closeFileForm} title='Upload file?'>
                <FileForm 
                    isLoading={isUploading} 
                    file={file} 
                    formFields={this.formFields} 
                    uploadFile={uploadFile} 
                    close={closeFileForm} 
                />
            </ModalDialog>
        );
    }
}

export default ChatFileUploadModal;