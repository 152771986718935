/* @flow */

import { gql } from '@apollo/client';
import { entityReferences } from 'graphql/entities/relationships/relationsQuery';

export default gql `
  query tasksByBoardColumnQuery($startIndex: Int, $stopIndex: Int, $filterBy: [JSON], $orderBy: [JSON], $excludeBy: [JSON]) {
    count: count(dataType: "task", filterBy: $filterBy)
    tasks: tasks(startIndex: $startIndex, stopIndex: $stopIndex, filterBy: $filterBy, orderBy: $orderBy, excludeBy: $excludeBy) {
        id
        name
        description
        modifiedDate
        assignee {
          id
          username
          image
          name
        }
  		primary
        relations {
          relation {
              id
              relationDefinition {
                  id
              }
          }
          relatedEntity {
            ${entityReferences}
          }
        }
    }
  }
`;
