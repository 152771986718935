// @flow

import { gql } from '@apollo/client';
import loadWorkspaceDetailsCommonQuery from 'graphql/workspaces/loadWorkspaceDetailsCommonQuery';


export default gql`
    query loadWorkspaceQuery($id: ID!) {
        result: workspace(id: $id) {
            ${loadWorkspaceDetailsCommonQuery}
        }
    }
`;
