/* @flow */

import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as echarts from 'echarts';
import { connect } from 'react-redux';
import ReactEcharts from 'echarts-for-react';
import { Checkbox, Grid, MdiIcon, IconButton } from '@mic3/platform-ui';

import Icon from 'app/components/atoms/Icon/Icon';
import Loader from 'app/components/atoms/Loader/Loader';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { deepEquals } from 'app/utils/utils';
import { get, sortBy } from 'app/utils/lo/lo';
import { isDefined, isEmpty } from 'app/utils/utils';
import { getAttachmentUrl } from 'app/utils/attachments/attachmentsUtils';
import { muiTheme } from 'app/themes/materialUi';

echarts.registerTheme('affectli_theme', {
    backgroundColor: 'transparent',
    legend: {
        textStyle: {
            color: muiTheme.colors.text.secondary
        }
    },
});

const ReactEchartsStyled = styled(ReactEcharts)`
    & image {
      border-radius: 50%;
      clip-path: url(#clipping);
    }
`;
const IconButtonStyled = styled(IconButton)`
    position: absolute !important;
    top: 12px;
    right: 0;

`;

const ZoomInIconButtonStyled = styled(Icon)`
    position: absolute !important;
    bottom: 20px;
    right: 104px;

    height: 32px !important;
    width: 34px !important;
    line-height: 19px !important;
    padding: 6px;
    background: ${({theme})=> theme.material.colors.background.paper} !important;
    border-right: 2px solid gray;

    & span {
        line-height: 15px !important;
        padding-left: 1px;
    }
`;
const ZoomOutIconButtonStyled = styled(Icon)`
    position: absolute !important;
    bottom: 20px;
    right: 72px;

    height: 32px !important;
    width: 32px !important;
    line-height: 19px !important;
    padding: 6px;
    background: ${({theme})=> theme.material.colors.background.paper} !important;

    & span {
        line-height: 15px !important;
        padding-left: 1px;
    }
`;
const CenterIconButtonStyled = styled(Icon)`
    position: absolute !important;
    bottom: 20px;
    right: 38px;

    height: 32px !important;
    width: 32px !important;
    line-height: 19px !important;
    padding: 6px;
    background: ${({theme})=> theme.material.colors.background.paper} !important;

    & span {
        line-height: 15px !important;
        padding-left: 1px;
    }
`;

const CheckboxStyled = styled(Checkbox)`
    & .MuiFormControlLabel-label {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
`;


/**
 * Renders the single relation item
 */
class RelationsDiagram extends PureComponent<Object, Object> {
    static propTypes = {
        relationMap: PropTypes.object.isRequired,
        deleteRelation: PropTypes.func.isRequired,
        fetchRelations: PropTypes.func.isRequired,
        groupByRelationDefinitionReGroup: PropTypes.func.isRequired,
    };

    state = {
        isPanelOpen: true,
        legendData: []
    };
    cachedRelations = {};
    diagramRef = React.createRef();
    data = {};
    categories = {};
    edges = {};
    edgesReversed = {};
    categoryIndex = -1;

    componentDidUpdate(prevProps, prevState) {
        const { relationMap, isOpen, filter } = this.props;
        if(prevProps.filter !== filter) {
            this.buildOptionsData();
        }
        if(relationMap !== prevProps.relationMap) {
            this.initDiagram();
        }
        if(isOpen !== prevProps.isOpen) {
            const instance = this.diagramRef.current.getEchartsInstance();
            instance.setOption({ series: [{ center: [instance._dom.offsetWidth / 2, instance._dom.offsetHeight / 2]}]});
        }
    }

    componentDidMount() {
        this.data = {};
        this.categories = {};
        this.edges = {};
        this.categoryIndex = -1;
        this.initDiagram();
    }

    @bind
    clearLinks(edges, parentId, hidden) {
        (edges || []).forEach((chId) => {
            if(parentId !== chId) {
                if(isDefined(hidden)) {
                    this.data[chId].hidden = hidden;
                    if(this.categories[chId]) {
                        this.categories[chId].hidden = hidden;
                    }
                } else {
                    this.data[chId] = undefined;
                    this.categories[chId] = undefined;
                }
            }
            const clearIds = new Set([...(this.edgesReversed[chId] || []), ...(this.edges[chId] || [])]);
            if(!isDefined(hidden)) {
                this.edgesReversed[chId] = undefined;
                this.edges[chId] = undefined;
            }
            this.clearLinks(clearIds, parentId, hidden);
        });
    };

    @bind
    initDiagram() {
        const { details, relationMap } = this.props;
        const detailsData = {
            ...details,
            relatedEntity: details,
            labelName: details.name,
            symbolSize: 27,
            index: 0,
            label: { show: true, postion: 'inside' },
            tooltip: {
                position: 'right',
                formatter: params => params.data.labelName
            },
        };
        if(detailsData.image) {
            detailsData.symbol = `image://${getAttachmentUrl(detailsData.id, detailsData.type, detailsData.image)}`;
        }
        this.addDataToOption(detailsData);
        this.buildRelationsMap(relationMap);
        this.buildOptionsData();

        let instance = this.diagramRef.current.getEchartsInstance();

        instance.on('graphRoam', (params) => {
            instance = this.diagramRef.current.getEchartsInstance();
            const { zoom } = params;
            if(isDefined(zoom)) {
                instance.setOption({});
            }
        });

        instance.on('click', async (params) => {
            instance = this.diagramRef.current.getEchartsInstance();
            // if click on edge we need to open sidebar
            if(params?.data?.expanded) {
                const categoryData = this.data[params.data.id];
                categoryData.symbolSize = 7;
                categoryData.label = { show: true, position: 'inside', formatter: params => params.data.labelName };
                categoryData.edgeSymbol = ['none', 'none'];
                categoryData.expanded= false;

                const parentId = categoryData.id;
                const clearIds = new Set([...(this.edgesReversed[categoryData.id] || []), ...(this.edges[categoryData.id] || [])]);
                this.clearLinks(clearIds, parentId);

                this.buildOptionsData();
                return;
            }
            
            if(params.dataType === 'node' && !params.data.childrens) {
                const { openEntityAbout, sidebarTitle }= this.props;
                const targetNode = params.data.relatedEntity || params.data;

                if(!targetNode.type) {
                    return;
                }
                if(params.data.mapedRelation && !this.relationIsExpanded(params.data.mapedRelation)) {
                    this.buildRelationsMap(params.data.mapedRelation);
                    this.buildOptionsData();
                }
                openEntityAbout(sidebarTitle || 'About', targetNode);
            }
            // if click on edge we need to open sidebar
            if(params.dataType === 'edge') {
                const { openRelationSidebar, reloadList, canEdit } = this.props;
                const { relation, childrens } = params.data.reverse ? this.data[params.data.source] : this.data[params.data.target];
                if(childrens) {
                    return;
                }
                if(canEdit) {
                    openRelationSidebar({ 
                        title: 'Attributes', 
                        relation, 
                        reloadList,
                        canEdit 
                    });
                }
                return;
            }

            const hasChild = !!params?.data?.childrens?.length;

            if(!hasChild) {
                return;
            }

            /*
             * Fetch children relationships
             */
            this.setState({ isLoading : true });
            const [childrenIds, type] = params.data.childrens.reduce((accum, ch) => {
                if(ch.id === details.id) return accum;

                accum[0].push(ch.id);
                this.addDataToOption(ch);
                return [accum[0], ch.type];
            }, [[], null]);

            let childRelations = [];
            const partIdSize = 200;
            for (let i = 0; i < childrenIds.length; i += partIdSize){
                const partsId = childrenIds.slice(i, i + partIdSize);
                const partchildRelations = await this.props.fetchRelations(partsId, type, 
                    [{ field: 'relatedEntity.id', op: 'not in', value: [params.data.parentId] }], params.data);
                childRelations = [...childRelations, ...partchildRelations];
            }

            childRelations.forEach((chRel) => {
                const { id, name } = chRel.relations[0]?.entity || {};
                if(id) {
                    const mapedRelation = this.props.groupByRelationDefinitionReGroup(chRel.relations);
                    this.data[id].mapedRelation = mapedRelation;
                    const length = chRel?.relations?.filter((rel) => rel?.relatedEntity?.id !== details?.id)?.length;
                    this.data[id].name = `${name} (${length})`;
                }
            });

            // TODO: up styles
            const categoryData = this.data[params.data.id];
            categoryData.symbolSize = 4;
            categoryData.label = { show: false };
            categoryData.edgeSymbol = ['none', 'none'];
            categoryData.expanded= true;
            this.buildOptionsData();
            this.setState({ isLoading : false }, () => {
                instance.setOption({ series: [{ center: [instance._dom.offsetWidth / 2, instance._dom.offsetHeight / 2]}]});
            });
        });
    }

    @bind
    handleToCenter() {
        const instance = this.diagramRef.current.getEchartsInstance();
        instance.setOption({ series: [{ center: [instance._dom.offsetWidth / 2, instance._dom.offsetHeight / 2]}]});
    }
    @bind
    handleZoom(isInZoom = false) {
        const instance = this.diagramRef.current.getEchartsInstance();
        const zoom = get(instance.getOption(), 'series[0].zoom', 5);
        
        if(!isInZoom && zoom <= 1 ) {
            zoom !== 0 && instance.setOption({ series: [{ zoom: 0 }]});
            return;
        }; 
        
        instance.setOption({ series: [{ zoom: isInZoom ? zoom + 1 : zoom - 1 }]});
    }

    @bind
    @memoize(deepEquals)
    defaultOptions(details) {
        return {
            tooltip: {
                position: 'right',
                formatter: params => params.data.name
            },
            legend: [
                {
                    data: [],
                    show: false,
                }
            ],
            animationDuration: 1500,
            animationEasingUpdate: 'quinticInOut',
            color: [
                'rgba(92, 107, 192, 1)', 'rgba(66, 165, 245, 1)', 'rgba(38, 166, 154, 1)', 'rgba(156, 204, 101, 1)', 'rgba(255, 202, 40, 1)',
                'rgba(255, 112, 67, 1)', 'rgba(239, 83, 80, 1)', 'rgba(171, 71, 188, 1)', 'rgba(126, 87, 194, 1)', 'rgba(121, 85, 72, 1)'
            ],
            series: {
                name: details.name,
                data: [],
                edges: [],
                categories: [],

                force: {
                    layoutAnimation: false,
                },
                zoom: 5,
                edgeSymbol: ['circle', 'arrow'],
                edgeSymbolSize: [4, 10],
                type: 'graph',
                layout: 'force',
                roam: true,
                emphasis: {
                    focus: 'adjacency',
                    lineStyle: {
                        width: 10
                    }
                }
            },
            // title: {
            //     text: 'Relations',
            //     subtext: `#${details.id}`,
            //     top: 'bottom',
            //     left: 'right'
            // },
        };
    };

    @bind
    buildChildSvg(d, parentId, parentUuId, reverse) {
        const { relatedEntity, relation } = d;

        const svg = {
            ...relatedEntity,
            reverse,
            relatedEntity,
            relation,
            symbolSize: 7,
            edgeSymbol: ['circle', 'arrow'],
            edgeSymbolSize: [4, 10],
            hidden: false,
            parentId,
            parentUuId,
            label: { show: true, position: 'right', color: muiTheme.colors.text.primary },
        };
        if(d.relatedEntity.image) {
            svg.symbol = `image://${getAttachmentUrl(d.relatedEntity.id, d.relatedEntity.type, d.relatedEntity.image)}`;
            svg.itemStyle =         {
                color: '#40698D',
                barBorderRadius: [5, 5, 0, 0]
            };
        }
        return svg;
    }

    @bind
    relationIsExpanded(relationMap) {
        let expanded = false;
        Object.entries(relationMap).forEach(([relId, relData], index) => {
            const { entity: { id: parentId } } = get(relData, '[0]', {});
            
            const dataId = `${relId}|${parentId}`;

            if(!expanded && this.data[dataId]) { 
                expanded = true; 
            };
        });
        return expanded;
    }

    @bind
    buildRelationsMap(relationMap, expanded = false) {
        Object.entries(relationMap).forEach(([relId, relData], index) => {
            const { entity: { id: parentId }, relatedEntity, isReverseRelation, relation } = get(relData, '[0]', {});
            
            const dataId = `${relId}|${parentId}`;

            if(this.data[dataId]?.expanded) return;
            
            const { relationDefinition } = relation || {};
            const { id: relationDefinitionId, description, relatedDescription } = relationDefinition || {};
            const childrens = relData.map((d) => {
                return this.buildChildSvg(d, dataId, relationDefinitionId, isReverseRelation);
            });
    
            const data = {
                id: dataId,
                name: isReverseRelation ? relatedDescription : description,
                parentId,
                relatedEntity: {
                    ...relatedEntity,
                    relatedDescription, description
                },
                relation,
            };    
           
            const length = childrens?.filter((ch) => ch?.relatedEntity?.id !== this.props?.details?.id)?.length;
           
            this.addDataToOption({
                ...data,
                labelName: String(length),
                childrens,
                index: index + 1,
                hidden: false,
                symbolSize: 7,
                isChecked: true,
                label: { show: true, position: 'inside', formatter: params => params.data.labelName },
            }, childrens.length);  
        });
    }

    @bind
    addDataToOption(data, isCategory) {
        // add data item to diagram
        this.data[data.id] = data;
        // create a link between parent elemnt and children element
        if(data.parentId) {
            if(!this.edges[data.parentId]) {
                this.edges[data.parentId] = [];
            }
            if(!data.reverse) {
                this.edges[data.parentId] = [...new Set([...this.edges[data.parentId], data.id])];
            } else {
                this.edgesReversed[data.parentId] = [...new Set([...(this.edgesReversed[data.parentId] || []), data.id])];
            }
        }

        // creating new category
        if(isCategory) {
            if(!this.categories[data.id]) {
                this.categories[data.id] = data;

                this.categoryIndex = this.categoryIndex === -1 ? 0 : Object.values(this.categories).filter(Boolean).length - 1;
                this.categories[data.id].category = this.categoryIndex;
                this.data[data.id].category = this.categoryIndex;
                if(this.data[data.id].childrens) {
                    this.data[data.id].childrens.forEach((chldrn) => {
                        chldrn.category = this.categoryIndex;
                    });
                }
            }
        }
    }

    @bind
    buildOptionsData() {
        const { filter, details } = this.props;
        const instance = this.diagramRef.current.getEchartsInstance();
        const coreOptions = instance.getOption();

        const getColor = (cat, coreOptions) => coreOptions.color[cat > 9 ? cat%10 : cat];
        const buildOptions = coreOptions => ({
            legend: [
                {
                    data: Object.values(this.categories).filter(Boolean).map(d => ({
                        name: d.name,
                        isChecked: !!d.isChecked,
                        itemStyle: { color: getColor(d.category, coreOptions) },
                        lineStyle: { color: getColor(d.category, coreOptions) },
                        relatedEntity: d.relatedEntity,
                        id: d.id,
                        parentId: d.parentId,
                    }))
                }
            ],
            series: [{
                name: details.name,
                data: Object.values(this.data).filter(Boolean).filter(d => !d.hidden).map(d => ({
                    ...d,
                    itemStyle: d.expanded ? {
                        color: 'transparent',
                        borderColor: getColor(d.category, coreOptions),
                        borderWidth: 2,
                    } : {
                        color: getColor(d.category, coreOptions),
                    }
                })),
                categories: Object.values(this.categories).filter(Boolean).filter(d => !d.hidden).map(cat => ({...cat})),
                edges: [
                    ...Object.entries(this.edges)
                        .filter(val => val[1])
                        .map(
                            ([source, targets]) => targets.map(target => ({ target, source }))
                        ).flat().map(edge => ({
                            ...edge,
                            lineStyle: { color: getColor(this.data[edge.target]?.category, coreOptions) }
                        })),
                    ...Object.entries(this.edgesReversed)
                        .filter(val => val[1])
                        .map(
                            ([target, sources]) => sources.map(source => ({ target, source }))
                        ).flat().map(edge => ({
                            ...edge,
                            reverse: true,
                            lineStyle: { color: getColor(this.data[edge.source]?.category, coreOptions) }
                        }))
                ],
                // roam: false,
                series: [{ center: [instance._dom.offsetWidth / 2, instance._dom.offsetHeight / 2]}]
                // roam: 'scale',
            }]
        });
        let options = buildOptions(coreOptions);
        const filterData = (data, filter) => {
            const filterData = data.filter(({ name, id}) => 
                this.props.matchExists({ name, id }, filter)).reverse();
            const relatedData = data.filter(item => 
                filterData.some(filterItem => filterItem?.id === item?.parentId)) || [];
             
            const allData = [...filterData, ...relatedData];
            return allData;
        };

        if(filter) {
            let parents = [];
            const getParents = (elmnt, data) => {
                if(!data) {
                    parents = [];
                }
                if(elmnt.parentId) {
                    const parent = this.data[elmnt.parentId];
                    parent.legend = !!elmnt.legend;
                    parents.push(parent);
                    return getParents(parent, parents);
                } else {
                    return parents;
                }
            };
            let findedLegend = filterData(options.legend[0].data, filter);
            let findedCategories = filterData(options.series[0].categories, filter).map(cat => ({ ...cat, legend: true }));
            let findedData = filterData(options.series[0].data, filter);
            findedData = [...findedData, ...findedData.map(d => getParents(d)).flat(Infinity), ...findedCategories.map(cat => getParents(cat)).flat(Infinity)];
            findedData = findedData.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i);
            findedCategories = findedData.filter(d => !!d.childrens);
            findedLegend = findedCategories.map(d => options.legend[0].data.find(leg => leg.id === d.id)).filter(Boolean);

            const findedEdges = options.series[0].edges.map((edge) => {
                const { source, target } = edge;
                if(findedData.find(d => d.id === source) && findedData.find(d => d.id === target)) {
                    return edge;
                }
                return false;
            }).filter(Boolean);

            findedCategories = sortBy(findedCategories, 'category').map(({...cat}, i) => {
                findedData = findedData.map(({...d}) => {
                    if(d.category === cat.category) {
                        d.category = i;
                    }
                    return d;
                });
                cat.category = i;
                return cat;
            });

            options = {
                ...options,
                legend: [{ data: findedLegend }],
                series: [{
                    ...options.series[0],
                    data: findedData,
                    categories: findedCategories,
                    edges: findedEdges
                }]
            };
        }
        instance.setOption(options);
        const legendDataNext = options.legend[0].data;
        this.setState({ legendData: legendDataNext }, () => {
            instance.setOption({ series: [{ center: [instance._dom.offsetWidth / 2, instance._dom.offsetHeight / 2]}]});
            this.props.setDiagramLegendDefintiions(legendDataNext.map((ld) => {
                return {
                    id: ld.id,
                    primaryText: ld.name,
                    type: 'checkbox',
                    isChecked: ld.isChecked,
                    name: 'legend',
                    onClick: this.showHideLegend,
                    CheckboxProps: { style: { color: ld.itemStyle.color }},
                };
            }));
        });
    }

    @bind
    showHideLegend(change) {
        const chId = change.target ? change.target.name : change.id;
        const clearIds = new Set([...(this.edgesReversed[chId] || []), ...(this.edges[chId] || [])]);
        this.data[chId].isChecked = change.target ? change.target.value : !change.isChecked;
        this.categories[chId].isChecked = change.target ? change.target.value : !change.isChecked;

        this.clearLinks(clearIds, chId, change.target ? !change.target.value : change.isChecked);

        this.buildOptionsData();
    }

    @bind
    buildLegend(legendData) {
        return (
            <>
                <Grid container wrap="nowrap" justify="center">
                    {legendData.map(ld => (
                        <CheckboxStyled
                            onChange={this.showHideLegend}
                            key={ld.id}
                            name={ld.id}
                            label={ld.name}
                            value={ld.isChecked}
                            CheckboxProps={{
                                style:{ color: ld.lineStyle.color }
                            }}
                        />
                    ))}
                </Grid>
                <IconButtonStyled onClick={this.props.openDiagramLegendMenu}>
                    <MdiIcon name="chevron-right" color={muiTheme.colors.text.secondary}/>
                </IconButtonStyled>
            </>
        );
    }

    /**
     * @override
     */
    render() {
        const { details, filter } = this.props;
        const { isLoading, legendData } = this.state;
        return details?.id && (
            <Fragment >
                {isLoading && <Loader backdrop absolute />}
                {this.buildLegend(legendData)}
                {filter && isEmpty(legendData) ? (
                    <Grid container justify="center">
                        <Grid>No search results</Grid>
                    </Grid>
                ) : ''}
                <ReactEchartsStyled
                    ref={this.diagramRef}
                    option={this.defaultOptions({ id: details.id })}
                    theme={'affectli_theme'}
                    opts={{ renderer: 'svg' }}
                    style={{ height:'calc(100% - 50px)', width: '100%' }}
                />
                <ZoomInIconButtonStyled onClick={() => this.handleZoom(true)}>
                    <MdiIcon name="plus" size={18} />
                </ZoomInIconButtonStyled>
                <ZoomOutIconButtonStyled onClick={() => this.handleZoom()}>
                    <MdiIcon name="minus" size={18} />
                </ZoomOutIconButtonStyled>
                <CenterIconButtonStyled onClick={this.handleToCenter}>
                    <MdiIcon name="crosshairs-gps" size={18} />
                </CenterIconButtonStyled>
            </Fragment>
        );
    }
}

export default connect(
    (state: Object, ownProps: Object) => ({
        isOpen: state.sidebar.isOpen,
    }),
    {},
)(RelationsDiagram);
