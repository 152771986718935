/* @flow */

import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { connect } from 'react-redux';
import { Grid, ListItem, Typography, Divider } from '@mic3/platform-ui';

import { saveComponentState } from 'store/actions/component/componentActions';
import { saveFiltersPreferences, loadUserPreferences, saveColumnPreferences } from 'store/actions/admin/usersActions';
import { buildFilterbar, resetFilterOperators, saveFilterOperators, disabledFilterButton, enabledFilterButton } from 'store/actions/filterbar/filterbarActions';
import { showToastr } from 'store/actions/app/appActions';

import { get } from 'app/utils/lo/lo';
import { formatByDefinitions } from 'app/utils/filter/filterUtils';
import { withRouterForwardRef } from 'app/utils/router/routerUtils';
import { bind, debounce, memoize } from 'app/utils/decorators/decoratorUtils';
import { shallowEquals, isEmpty, deepEquals } from 'app/utils/utils';
import { addCustomOperators } from 'app/utils/classification/classificationUtils';
import Immutable from 'app/utils/immutable/Immutable';

import { parseView } from 'app/components/organisms/GlobalLeftPanel/GlobalLeftPanel';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import FiltersActions from './FiltersActions';
import FiltersToolbar from './FiltersToolbar';
import FiltersSearchLine from './FiltersSearchLine';

export const Content = styled.div`
    flex-grow: 1;
    max-height: calc(100vh - ${({ heightOffset }) => heightOffset}px);
`;

const Title = styled(ListItem)`
    padding-bottom: 0 !important;
    margin-bottom: -5px;
`;

const GridWrapper = styled(Grid)`
    flex-wrap: nowrap !important;
    height: 100% !important;
`;

const StyledTypography = styled(Typography)`
font-size: 12px !important;
font-weight: 600 !important;
line-height: 16px !important;
color: ${({theme})=> theme.material.colors.text.secondary};
`;

const classesToolbar = { searchBar: 'filter-toolbar' };

class Filters extends PureComponent<Object, Object> {

    filtersRef: Object = React.createRef();
    orderByRef: Object = React.createRef();

    static propTypes = {
        id: PropTypes.string.isRequired,
        filterViewState: PropTypes.object,
        filterPreferences: PropTypes.object,
        filterDefinitions: PropTypes.arrayOf(PropTypes.shape({
            properties: PropTypes.shape({
                label: PropTypes.string,
                name: PropTypes.string.isRequired,
            }).isRequired,
            field: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
            type: PropTypes.string.isRequired,
            defaultCondition: PropTypes.string,
            searchBar: PropTypes.bool,
            filter: PropTypes.bool,
            sort: PropTypes.bool,
        })),
        breadcrumb: PropTypes.arrayOf(PropTypes.shape({
            title: PropTypes.string.isRequired,
            link: PropTypes.string,
        })),
        saveFiltersPreferences: PropTypes.func,
        children: PropTypes.func,
        searchBar: PropTypes.array,
        defaultFilters: PropTypes.object,
        defaultOrder: PropTypes.array,
        onlySearchBar: PropTypes.bool,
        leftToolbar: PropTypes.node,
        rightToolbar: PropTypes.node,
        heightOffset: PropTypes.number,
        moreMenuItems: PropTypes.array,
        hideDivider: PropTypes.bool,
        moreIcons: PropTypes.arrayOf(PropTypes.element),
        filterIsOpen: PropTypes.bool,
        enableCountdown: PropTypes.bool,
        disableLeftPanelFilters: PropTypes.bool,
        onReload: PropTypes.func,
    };

    static defaultProps = {
        heightOffset: 202,
        hideDivider: false,
        disableLeftPanelFilters: false,
        searchBar: ['id', 'name'],
        defaultFilters: {}
    }

    constructor(props) {
        super(props);
        this.state = { filterKey: 1, searchBarHeight: 0 };
        const { filterPreferences, defaultFilters, defaultOrder, filterViewState } = this.props;
        const { filters, orderBy, searchBar, operatorsMap } = this.getFilterViewState(filterPreferences, defaultFilters, defaultOrder, filterViewState);
        this.props.saveComponentState(this.props.id, { searchBar, filters, orderBy: orderBy[0], isPreferenceLoaded: true, operatorsMap });
        this.everyMinuteKey = moment().set({ second:0, millisecond:0 }).toDate();
    }

    componentDidUpdate(prevProps) {
        const { filterPreferences, defaultFilters, defaultOrder, filterViewState, id} = this.props;
        if (filterPreferences && (!deepEquals(prevProps.filterPreferences, filterPreferences))) {
            const { filters, orderBy, searchBar, operatorsMap } =
                this.getFilterViewState(filterPreferences, defaultFilters, defaultOrder, filterViewState, true);
            this.props.saveComponentState(this.props.id, { searchBar, filters, orderBy: orderBy[0], operatorsMap });
        }
        if (id && prevProps.id !== id) {
            const { filters, orderBy, searchBar, operatorsMap } = this.getFilterViewState(filterPreferences, defaultFilters, defaultOrder, filterViewState);
            this.props.saveComponentState(this.props.id, { searchBar, filters, orderBy: orderBy[0], isPreferenceLoaded: true, operatorsMap });
        }

    }

    @bind
    @memoize()
    getFilterViewState(filterPreferences, defaultFilters, defaultOrder, filterViewState, fetchFromPreference) {
        const { filters, orderBy, operatorsMap: prefOperators } = filterPreferences || {};
        const { searchBar, filters: stateFilters, orderBy: stateOrderBy, isPreferenceLoaded, operatorsMap: stateOperators } = filterViewState || {};
        const newFilter = fetchFromPreference || !isPreferenceLoaded ? filters : stateFilters;
        const newOrderBy = fetchFromPreference || !isPreferenceLoaded ? orderBy : stateOrderBy ? [stateOrderBy] : null;
        const operatorsMap = isEmpty(stateOperators) ? prefOperators : stateOperators;
        return {
            filters: { ...(defaultFilters || {}), ...(newFilter || {}) }, // This will always add the default filters if any filter is previously saved, if same filter is saved then it will override default filter
            orderBy: newOrderBy || defaultOrder || [],
            searchBar: searchBar || null,
            operatorsMap
        };
    };

    @bind
    toggleDrawer(e, isFilterOpen) {
        const { filterDefinitions, filterViewState } = this.props;
        this.initSidebar(filterDefinitions, filterViewState, isFilterOpen);
    }

    @bind
    async setEditedFilterByWithUpdate(data: Object) {
        await this.props.saveComponentState(this.props.id, { filters: data });
        this.toggleDrawer(null, this.props.filterIsOpen);
    };

    @bind
    async applyFilter(params) {
        const { operators, message } = params || {};
        const { data: filters, errors } = await this.filtersRef.current.isValidForm();
        if(errors) return;
        const { data: orderBy } = await this.orderByRef.current.isValidForm();
        // FIXME: do we need this checking on isValidOrderBy or not?
        // const isValidOrderBy = typeof orderBy === 'object' && orderBy.field !== undefined && (orderBy.direction !== undefined || orderBy.asc !== undefined);
        const { operatorsMap: propsOperators, showToastr, filterViewState } = this.props;
        const { gridKey, boardKey, operatorsMap: stateOperators } = filterViewState;
        const operatorsMap = operators || {...(stateOperators || {}), ...propsOperators};
        await this.props.saveComponentState(this.props.id, {
            gridKey: (gridKey || 0) + 1,
            boardKey: (boardKey || 0) + 1,
            filters,
            orderBy,
            operatorsMap
        });
        showToastr({ severity: 'success', detail: message || 'Filters applied successfully.' });
    };

    @bind
    async saveFilter(message) {
        const { columns, gridKey, boardKey, operatorsMap: stateOperators } = this.props.filterViewState;
        const { operatorsMap: propsOperators, id } = this.props;
        const operatorsMap = {...(stateOperators || {}), ...(propsOperators || {})};
        if(this.filtersRef.current) {
            const { data: filters, errors } = await this.filtersRef.current.isValidForm();
            const { data: orderBy } = await this.orderByRef.current.isValidForm();
            if(errors) return;
            const isValidOrderBy = typeof orderBy === 'object' && orderBy.field !== undefined && (orderBy.direction !== undefined || orderBy.asc !== undefined);
            await this.props.saveComponentState(this.props.id, {
                gridKey: (gridKey || 0) + 1,
                boardKey: (boardKey || 0) + 1,
                filters,
                orderBy,
                operatorsMap
            });
            await this.props.saveFiltersPreferences(this.props.id, {
                columns,
                filters,
                gridKey: gridKey + 1,
                boardKey: boardKey + 1,
                orderBy: isValidOrderBy ? [orderBy] : [],
                operatorsMap
            }, message);
        } else {
            const { filters, orderBy } = this.props.filterViewState;
            (id === 'AdminBackgroundJobList' || id === 'BackgroundJobList') ? 
                await this.props.saveColumnPreferences({[this.props.id]: {
                    columns,
                    filters,
                    gridKey: gridKey + 1,
                    boardKey: boardKey + 1,
                    orderBy: [orderBy],
                    operatorsMap
                }}, message)
                : await this.props.saveFiltersPreferences(this.props.id, {
                    columns,
                    filters,
                    gridKey: gridKey + 1,
                    boardKey: boardKey + 1,
                    orderBy: [orderBy],
                    operatorsMap
                }, message);

        }
        await this.cancelSaving();
    };

    @bind
    async loadSavedFilter() {
        const { filterDefinitions, filterPreferences, filterViewState, defaultFilters, defaultOrder } = this.props;
        const { filters, orderBy, operatorsMap } = filterPreferences || {};
        const { filters: stateFilters, orderBy: stateOrderBy } = filterViewState || {};
        const newFilters = (filters || defaultFilters) || stateFilters;
        const newOrderBy = get((orderBy || defaultOrder), '[0]', stateOrderBy) ;
        await this.props.saveFilterOperators(operatorsMap);
        this.initSidebar(filterDefinitions, { orderBy:  {...newOrderBy} || [], filters:{ ...newFilters } }, true);
        await this.applyFilter({ operators: operatorsMap, message: 'Saved filters applied successfully.' });
    }

    @bind
    async resetFilter() {
        const { filterDefinitions, defaultOrder, defaultFilters, resetFilterOperators } = this.props;
        await resetFilterOperators();
        this.initSidebar(filterDefinitions, { orderBy:  {...get(defaultOrder, '[0]')} || [], filters:{ ...defaultFilters } }, true);
        this.applyFilter({ operators: {}, message: 'Filters reset to default.' });
    };

    @bind
    async cancelSaving() {
        const { id, saveComponentState } = this.props;
        await saveComponentState(id, {
            changed: false,
        });
    };

    @bind
    @debounce(700)
    updateSearchBarFilter(searchBar) {
        this.props.saveComponentState(this.props.id, { searchBar });
    }

    @bind
    @memoize()
    isCustomField(userDefinedFilters, field){
        if (!userDefinedFilters?.length) {
            return false;
        }
        return !!userDefinedFilters.find(def => def?.field === field);
    }

    @bind
    @memoize()
    formatFilterBy(leftPanelFilterBy, filters, searchBarValue, operatorsMap) {
        const { filterDefinitions, searchBar, disableLeftPanelFilters, userDefinedFilters,
        } = this.props;
        let filterBy = formatByDefinitions(filters, filterDefinitions, searchBar, searchBarValue, false);
        filterBy = addCustomOperators(filterDefinitions, filterBy, operatorsMap, userDefinedFilters, searchBar, searchBarValue);
        if (disableLeftPanelFilters) {
            return filterBy;
        }
        return [
            ...filterBy,
            ...(leftPanelFilterBy || []),
        ];
    }

    @bind
    @memoize(shallowEquals)
    formatExcludeBy(filters: Object) {
        const { filterDefinitions, searchBar } = this.props;
        return formatByDefinitions(filters, filterDefinitions, searchBar, null, true);
    }

    @bind
    @memoize(shallowEquals)
    formatOrderBy(orderBy: Object) {
        return Array.isArray(orderBy) ? orderBy : [orderBy];
    }

    @bind
    @memoize()
    getSortOptions(filterDefinitions: Array<Object>) {
        return filterDefinitions.filter(def => def.sort !== false).map((definition) => {
            const field = get(definition, 'field');
            const value = field.includes('.id') ? field.replace('.id', '.name') : field;

            return {
                label: get(definition, 'properties.label'),
                value
            };
        });
    }

    @bind
    @memoize()
    getFilterDefinitions(filterDefinitions: Array<Object>) {
        return filterDefinitions.filter(def => def.filters !== false);
    }

    @bind
    @memoize()
    buildOrderComponents(filterDefinitions: Array<Object>) {
        const sortOptions = this.getSortOptions(filterDefinitions);
        return sortOptions?.length ? [
            {
                type: 'typeahead',
                properties: {
                    label: 'Sort by',
                    name: 'field',
                    options: sortOptions,
                    clearable: false,
                },
            },
            {
                type: 'typeahead',
                properties: {
                    label: 'Order by',
                    name: 'direction',
                    options: [{ label: 'Descending', value: 'desc nulls last' }, { label: 'Ascending', value: 'asc nulls last' }],
                    clearable: false,
                },
            },
        ] : null;
    }

    @bind
    forceUpdate(){
        this.setState(({ filterKey }) => ({ filterKey: filterKey + 1 }));

        if (this.props.onReload) {
            const { leftPanelFilterBy, filterViewState, onReload } = this.props;
            const { searchBar, filters, operatorsMap } = filterViewState || {};
            onReload(this.formatFilterBy(leftPanelFilterBy, filters, searchBar, operatorsMap));
        }
    }
    @bind
    onStartChanges() {
        const { disabledFilterButton } = this.props;
        disabledFilterButton();
    }
    @bind
    onEndChanges() {
        const { enabledFilterButton } = this.props;
        enabledFilterButton();
    }

    @bind
    initSidebar(filterDefinitions, filterViewState, isFilterOpen) {
        const { orderBy, filters } = filterViewState || {};
        const { buildFilterbar } = this.props;
        const orderComponents = this.buildOrderComponents(filterDefinitions);
        const filterComponents = this.getFilterDefinitions(filterDefinitions);
        const isFilterComponents = (filterComponents || []).length > 0;
        const isOpen = isFilterOpen !== undefined ? isFilterOpen : true;

        buildFilterbar({
            title: 'Filters',
            id: this.props.id,
            isOpen: isOpen,
            content: (
                <>
                    {orderComponents && (
                        <Fragment>
                            <Title><StyledTypography component="span" variant="caption">Sort</StyledTypography></Title>
                            <FormGenerator
                                ref={this.orderByRef}
                                components={orderComponents}
                                data={orderBy}
                                indent
                            />
                        </Fragment>
                    )}
                    {isFilterComponents && (
                        <Title><StyledTypography component="span" variant="caption">Filter</StyledTypography></Title>
                    )}
                    <FormGenerator
                        ref={this.filtersRef}
                        components={filterComponents}
                        data={filters}
                        indent
                        onStartChanges={this.onStartChanges}
                        onEndChanges={this.onEndChanges}
                    />
                </>
            ),
            actions: (
                <FiltersActions
                    onReset={this.resetFilter}
                    onApply={this.applyFilter}
                    onSave={this.saveFilter}
                    onClose={this.toggleDrawer}
                    onLoadSaved={this.loadSavedFilter}
                />
            )
        });
    }

    render() {
        const {
            heightOffset, filterDefinitions, children, filterViewState, breadcrumb, breadcrumbWithGoBack, leftPanelFilterBy,
            className, leftToolbar, rightToolbar, moreMenuItems, moreIcons, searchPlaceHolder, hideDivider, enableCountdown,
            leftSideSearcLine
        } = this.props;
        const { searchBar, filters, orderBy, changed, operatorsMap } = filterViewState || {};
        const orderComponents = this.buildOrderComponents(filterDefinitions);
        const filterComponents = this.getFilterDefinitions(filterDefinitions);
        const isFilterComponents = (filterComponents || []).length > 0;
        const isAnyDefinitions = !!orderComponents || isFilterComponents;
        const { filterKey, searchBarHeight } = this.state;
        const searchBarHeightAdjustment = searchBarHeight - 56 > 0 ? searchBarHeight - 56 : 0;
        if(!filterViewState) {
            return null;
        }
        return (
            <GridWrapper key={filterKey} className={className} container direction="column">
                <FiltersToolbar
                    isAnyDefinitions={isAnyDefinitions}
                    saveFilter={this.saveFilter}
                    cancelSaving={this.cancelSaving}
                    toggleDrawer={this.toggleDrawer}
                    classes={classesToolbar}
                    leftToolbar={leftToolbar}
                    rightToolbar={rightToolbar}
                    moreMenuItems={moreMenuItems}
                    moreIcons={moreIcons}
                    breadcrumb={breadcrumb}
                    breadcrumbWithGoBack={breadcrumbWithGoBack}
                    changed={changed}
                />
                <Divider />
                <FiltersSearchLine
                    searchFields={this.props.searchBar}
                    searchValue={searchBar}
                    searchHelperText={this.props.searchHelperText}
                    filters={filters}
                    filterDefinitions={filterDefinitions}
                    setEditedFilterByWithUpdate={this.setEditedFilterByWithUpdate}
                    forceUpdate={this.forceUpdate}
                    updateSearchBarFilter={this.updateSearchBarFilter}
                    searchPlaceHolder={searchPlaceHolder}
                    enableCountdown={enableCountdown}
                    leftSideSearcLine={leftSideSearcLine}
                    onHeightChange={(height) => this.setState({ searchBarHeight: height })}
                />
                {!hideDivider && <Divider /> }
                <Content heightOffset={heightOffset + searchBarHeightAdjustment}>
                    {children(
                        this.formatFilterBy(leftPanelFilterBy, filters, searchBar, operatorsMap, filterKey),
                        this.formatOrderBy(orderBy, filterKey),
                        this.formatExcludeBy(filters, filterKey),
                    )}
                </Content>
            </GridWrapper>
        );
    }
}

export default withRouterForwardRef(connect(
    (state, props) => (Immutable({
        filterViewState: get(state, `component.state.${props.id}`),
        filterPreferences: get(state, `user.preferences.filters.${props.id}`),
        leftPanelFilterBy: state.leftPanel.state.filterBy[parseView(props.location.pathname)],
        filterIsOpen: state.filterbar.isOpen,
        operatorsMap: state.filterbar.operatorsMap
    })),
    { 
        saveComponentState, 
        saveFiltersPreferences, 
        saveColumnPreferences,
        loadUserPreferences, 
        buildFilterbar, 
        saveFilterOperators,
        resetFilterOperators,
        showToastr,
        disabledFilterButton,
        enabledFilterButton

    }, 
    null, 
    { forwardRef: true }
)(Filters));
