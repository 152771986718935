// @flow

import { gql } from '@apollo/client';

export default (additionalProps = ``) => gql`
query taskLoadAvatarQuery($filterBy: [JSON]) {
  result: tasks(filterBy: $filterBy) {
    id
    name
    type
    ${additionalProps}
  }
}

`;
