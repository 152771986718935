import React from 'react';

import Help from 'app/utils/designer/form/settings/common/Help';
import QueryBuilder from 'app/components/Designer/QueryBuilder/QueryBuilder';
import OrderBuilder from 'app/components/Designer/OrderBuilder/OrderBuilder';
import ConfirmationBuilder, { defualtState } from 'app/components/Designer/Confirmation/ConfirmationBuilder';

export const multiple =                 {
    type: 'boolean',
    properties: {
        label: 'Multiple',
        name: 'properties.multiple',
        help: <Help message="Defines if the component accept one or multiple values" />,
    }
};

export const allowPermissions = {
    type: 'boolean',
    properties: {
        label: 'Allow Permissions',
        name: 'properties.allowPermissions',
        help: <Help message="Defines if the results are based on workspace permissions or all active teams are shown" />,
    }
};


export const filterBy = {
    type: 'custom',
    properties: {
        label: 'Filter by',
        name: 'properties.filterBy',
        Component: QueryBuilder,
        help: <Help message="Defines the GraphQL query filterBy parameter" />,
    }
};

export const excludeBy = {
    type: 'custom',
    properties: {
        label: 'Exclude by',
        name: 'properties.excludeBy',
        Component: QueryBuilder,
        help: <Help message="Defines the GraphQL query excludeBy parameter" />,
    }
};

export const orderBy = {
    type: 'custom',
    properties: {
        label: 'Order by',
        name: 'properties.orderBy',
        Component: OrderBuilder,
        help: <Help message="Defines the GraphQL query orderBy parameter" />,
    }
};

export const confirmationBuilder = [
    {
        type: 'boolean',
        properties: { label: 'With confirmation?', name: 'properties.withConfirmation', onChange: (event) => {
            const { value, name } = event.target;
            return [{ value, name }, { name: 'properties.confirmationModalProps', value: value ? defualtState : {} }];
        }}
    },
    {
        type: 'custom',
        properties: {
            label: 'Confirmation modal builder',
            name: 'properties.confirmationModalProps',
            Component: ConfirmationBuilder,
            isVisible: ({ properties }) =>
                properties && !!properties.withConfirmation,
        }
    }
];
