// @flow
import React from 'react';
import PropTypes from 'prop-types';
import affectliSso from 'app/auth/affectliSso';
import styled from 'styled-components';

export const formatBytes = (a: number, b: number = 2) => {
    if (0 === Number(a)) return '0 Bytes';
    const c = 1024,
        d = b,
        e = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        f = Math.floor(Math.log(a) / Math.log(c));
    return `${parseFloat((a / Math.pow(c, f)).toFixed(d))} ${e[f]}`;
};

export const chooseIcon = (type: string = '', filename: string = '') => {
    const fileExtension = filename.split('.').pop();
    // handling for octet-stream
    if (fileExtension && type.split('/')[1] === 'octet-stream') {
        const compressedFileExtensions = ['zip', '7z', 'rar', 'tar', 'war', 'deb'];
        const audioFileExtensions = ['m4a', 'flac', 'mp3', 'wav', 'wma', 'aac'];
        const videoExtensions = [
            'webm',
            'mpg',
            'mp2',
            'mpeg',
            'mpe',
            'mpv',
            'ogg',
            'mp4',
            'm4p',
            'm4v',
            'avi',
            'wmv',
            'mov',
            'qt',
            'flv',
            'swf',
            'avchd',
            'mkv'
        ];

        if (compressedFileExtensions.includes(fileExtension)) return 'folder-zip';
        if (audioFileExtensions.includes(fileExtension)) return 'file-music';
        if (videoExtensions.includes(fileExtension)) return 'file-video';
    }

    if (type.split('/')[0] === 'image') return 'image';
    if (type.split('/')[0] === 'text') return 'file';
    if (type.split('/')[0] === 'audio') return 'file-music';
    if (type.split('/')[0] === 'video') return 'file-video';
    if (type.split('/')[1] === 'pdf') return 'file-pdf';
    if (type.split('/')[1] === 'svg+xml') return 'svg';
    if (type.split('/')[1] === 'json') return 'file-code';
    if (type.split('/')[1] === 'x-zip-compressed') return 'folder-zip';
    if (type.substring(type.lastIndexOf('.') + 1) === 'sheet') return 'file-table';
    if (type.substring(type.lastIndexOf('.') + 1) === 'document') return 'file-document';
    if (type.substring(type.lastIndexOf('.') + 1) === 'presentation') return 'file-powerpoint';

    return 'file';
};

export const getExtension = (mimeType: string) => {
    const type = mimeType && mimeType.split('/')[1];
    return type && type.toUpperCase();
};

export const isInvalidExtension = (file: File) => {
    const splited = file.name.split('.');
    if (splited.length > 1) {
        const ext = splited[splited.length - 1].toUpperCase();
        const invalidExt = [
            'ADE',
            'ADP',
            'BAT',
            'CHM',
            'CMD',
            'COM',
            'CPL',
            'EXE',
            'HTA',
            'INS',
            'ISP',
            'JAR',
            'JS',
            'JSE',
            'LIB',
            'LNK',
            'MDE',
            'MSC',
            'MSI',
            'MSP',
            'MST',
            'NSH',
            'PIF',
            'SCR',
            'SCT',
            'SHB',
            'SYS',
            'VB',
            'VBE',
            'VBS',
            'VXD',
            'WSC',
            'WSF',
            'WSH',
        ];
        return invalidExt.includes(ext);
    }
    return true;

};

const Link = styled.a`
    text-decoration: none;
`;

export const getAttachmentUrl = (id: string, type: string, imageId = '', isModel: boolean = false) => {
    if(!id || !type){
        console.error('Both entity Id and entity type must be defined.'); // eslint-disable-line no-console
        return null;
    };
    let etag = imageId || '';
    if (etag) {
        try {
            etag = encodeURIComponent(etag);
        } catch (error) {}  
    }
    return `/media/entity/${type}/${id}/${isModel ? 'model3d' : 'image'}${etag ? `?etag=${etag}`: ''}`;
};


export const getDownloadAllUrl = (id: string, type: string) => `/graphql/file/${type}/${id}/downloadAll?token=${affectliSso.getToken()}`;

const StyledLink = styled(Link)`
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
width: 100%;
min-width: 33px;
`;

export const AttachmentLink = (props: Object) => (
    <StyledLink onClick={props.onClick} target="_blank" download href={`/graphql/file/${props.id}/download?token=${affectliSso.getToken()}`}>
        {props.children}
    </StyledLink>
);

export const AttachmentFile = (props: Object) => (
    <StyledLink target="_blank" download href={props.href}>
        {props.children}
    </StyledLink>
);

AttachmentLink.propTypes = {
    id: PropTypes.string.isRequired
};
