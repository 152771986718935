// @flow


const equal = { label: 'Is exactly', value: '=', icon: 'equal' };
const notEqual = { label: 'Not equal', value: '<>', icon: 'not-equal' };
const contains = { label: 'Contains', value: 'contains', icon: 'tilde' };
const startWith = {label: 'starts with', value: 'starts with', icon: 'arrow-expand-right' };

const textFieldMenu = [
    contains,
    equal,
    { label: 'Is empty', value: 'is null', icon: 'circle-off-outline' },
    { label: 'Does NOT contain', value: 'not contains', icon: 'not-equal' }
];

export const operatorsMenu = {
    text: textFieldMenu,
    number: [equal, notEqual],
    uuid: [equal, startWith]
};
