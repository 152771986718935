/* @flow */

import { gql } from '@apollo/client';

import { workspaceFields, classWorkspaceFields } from 'graphql/entities/entities/loadEntityWorkspacesQuery';

export default type => gql`
    mutation shareEntityWithTeamsMutation($id: ID!, $teamMembers: [TeamMemberInput]!) {
        result: ${type === 'class' ? 'shareClassWithTeams': 'shareEntityWithTeams'}(id: $id, teamMembers: $teamMembers${type === 'class' ? '' : `, type: "${type}"`}) {
            ${type === 'class' ? classWorkspaceFields : workspaceFields}
        }
    }
`;
