// @flow

import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ChildrenProp } from 'app/utils/propTypes/common';

const Parent = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    row-gap: 0.5em;
`;

const Child = styled.div`
    margin-right: ${({ spaces }) => spaces}px;
`;

function Ellipsis({ data, spaces, renderItem, renderCount, displaySize }: Object) {
    const [items, setItems] = useState(data);
    const [hiddenItemCount, setHiddenItemCount] = useState(0);
    const maxCount = 99;

    useEffect(() => {
        const newItems = [...(data || [])];

        if (displaySize && isNaN(displaySize)) {
            throw new Error('displaySize should be numeric');
        }

        if (displaySize) {
            const shownItems = newItems.splice(0, displaySize);
            setHiddenItemCount((data || []).length - shownItems.length);
            setItems(shownItems);
        } else {
            setItems(newItems);
        }
    }, [data, displaySize]);
    return (
        <Parent >
            {(items || []).map((d, index) => (
                <Child spaces={spaces} key={index}>
                    {renderItem(d)}
                </Child>
            ))}
            {(displaySize && hiddenItemCount !== 0) && renderCount((hiddenItemCount < maxCount) ? hiddenItemCount : maxCount)}
        </Parent>
    );
};

Ellipsis.propTypes = {
    renderItem: ChildrenProp.isRequired,
    renderCount: ChildrenProp.isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    displaySize: PropTypes.number,
    spaces: PropTypes.number,
};

Ellipsis.defaultProps = {
    data: [],
    displaySize: null,
    maxCount: null,
    spaces: 4,
};

export default memo(Ellipsis);
