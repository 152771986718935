/* @flow */

import React from 'react';
import { Paper, Typography, Button } from '@mic3/platform-ui';
import styled from 'styled-components';

import AbstractBuilder from 'app/components/Designer/Builders/AbstractBuilder/AbstractBuilder';
import AbstractTableDnd from 'app/components/Designer/Builders/AbstractBuilder/AbstractTableDnd';
import DragNDropContext from 'app/containers/DragNDropContext/DragNDropContext';
import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';

const TypographyPadded = styled(Typography)`
    padding: 12px 0 0 0;
`;

const PaperStyled = styled(Paper)`
    padding: 0 12px;
    margin: 0;
    cursor: pointer;
    background: ${({ theme }) => theme.material.colors.background.default} !important;
`;

class AbstractDefinitionsBuilder extends AbstractBuilder {
    render() {
        const { showModal, value } = this.state;
        const { title, fields } = this.props;
        return (
            <DragNDropContext>
                <PaperStyled onClick={this.toggleModal}>
                    <Typography variant="caption">{title}</Typography>
                    <Typography >{(value || []).length} Fields</Typography>
                </PaperStyled>
                {showModal && (
                    <ModalDialog
                        onClose={this.toggleModal}
                        title={title}
                        actions={(
                            <Button onClick={this.onSave}>
                                Apply
                            </Button>
                        )}
                    >
                        <TypographyPadded variant="caption">Dragable list</TypographyPadded>
                        <AbstractTableDnd
                            uuid={this.uuid}
                            onDrop={this.onDrop}
                            addValue={this.addValue}
                            deleteValue={this.deleteValue}
                            value={value}
                            onChange={this.handleOnChange}
                            fields={fields}
                            component={'AbstractDefinitionsBuilder'}
                        />
                    </ModalDialog>
                )}
            </DragNDropContext>
        );
    }
};

export default AbstractDefinitionsBuilder;
