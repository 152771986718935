// @flow

import React from 'react';
import { type Node } from 'react';
import { Link } from 'react-router-dom';
import {
    ListItem,
    ListItemIcon,
    ListItemText,
    ListItemAvatar,
    Avatar,
    Badge
} from '@mic3/platform-ui';

import Icon from './AppNavIcon/AppNavIcon';
import styled from 'styled-components';

type Props = {
    children?: Node,
    component?: any,
    primaryText: Node | string,
    secondaryText?: Node | string,
    iconPrimary?: string,
    iconSecondary?: string,
    to?: string,
    img?: string,
    iconType: 'af' | 'mdi',
    iconPrimaryProps?: Object,
    iconSecondaryProps?: Object,
    imageProps?: Object,
    textProps?: Object,
    badge?: string | number,
    badgeProps?: Object,
    isButton?: boolean
}

const ListItemIconEnd = styled(ListItemIcon)`
    min-width: 0 !important;
`;
const StyledListItemText = styled(ListItemText)`
padding-left: 0 !important;
`;


const AppNavItem = React.forwardRef(({
    children,
    component,
    iconPrimary,
    iconSecondary,
    iconType,
    primaryText,
    secondaryText,
    iconPrimaryProps,
    iconSecondaryProps,
    imageProps,
    textProps,
    to,
    img,
    badge,
    badgeProps,
    isButton,
    name,
    ...otherProps
}: Props, forwardRef) => {
    return (
        <ListItem { ...otherProps } button={isButton} to={to} component={to ? component : 'div'} ref={forwardRef}>
            {(iconPrimary) &&
                <ListItemIcon>
                    <Icon {...iconPrimaryProps} icon={iconPrimary} iconType={iconType} />
                </ListItemIcon>
            }
            {img && (
                <ListItemAvatar>
                    <Avatar {...imageProps} alt="avatar" src={img} />
                </ListItemAvatar>
            )}
            {(iconPrimary && iconType) ? <StyledListItemText {...textProps} primary={primaryText} secondary={secondaryText}/>
                : <ListItemText {...textProps} primary={primaryText} secondary={secondaryText}/>}
            
            {badge &&
                <Badge {...badgeProps} badgeContent={badge}/>
            }
            {(iconSecondary) &&
                <ListItemIconEnd>
                    <Icon {...iconSecondaryProps} icon={iconSecondary} iconType={iconType} />
                </ListItemIconEnd>
            }
            {children}
        </ListItem>
    );
});

AppNavItem.defaultProps = {
    iconType: 'mdi',
    component: Link
};

export default AppNavItem;
