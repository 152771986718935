import EntityLayer from 'app/containers/Maps/EntityLayerStyling/EntityLayer';
import { loadEntityDetails } from 'store/actions/maps/situationalAwarenessActions';
import cesiumLayerConfig from 'app/config/cesiumLayerConfig';

class ThreeDLayer extends EntityLayer {

    async toggleTo3DStyling(Cesium, ol3D, enable3D) {
        const assetId = cesiumLayerConfig?.cesiumLayer?.getAsset(this.info) || cesiumLayerConfig?.cesiumLayer?.getOldAsset(this.info);
        const entityId = cesiumLayerConfig?.cesiumLayer?.getAccount(this.info)?.id ||cesiumLayerConfig?.cesiumLayer?.getOldAccount(this.info)?.id;
        const oldType = cesiumLayerConfig?.cesiumAccount?.oldType;
        const type = this.info?.type === cesiumLayerConfig?.cesiumLayer?.oldType ? oldType : cesiumLayerConfig?.cesiumAccount?.type;
        const result = await loadEntityDetails(entityId, type);
        const token = cesiumLayerConfig?.cesiumAccount?.getCesiumToken(result) || cesiumLayerConfig?.cesiumAccount?.getOldCesiumToken(result);
        this.addCesiumAsset(token, assetId, Cesium, ol3D);
    }

    addCesiumAsset(token, assetId, Cesium, ol3D) {
        try {
            Cesium.Ion.defaultAccessToken = token;
            const scene = ol3D.getCesiumScene();
            const assetType = assetId?.split(',')[1];
            const twoLayersVisibility = this.layer?.getVisible();
            switch (assetType) {
                case '3DTILES':
                    const tileset = new Cesium.Cesium3DTileset({
                        url: Cesium.IonResource.fromAssetId(Number(assetId?.split(',')[0])),
                        projectTo2D: true,
                    });
                    scene.primitives.add(tileset);
                    const primitives = scene.primitives;
                    const newPrimitive = primitives?._primitives?.[primitives.length - 1];
                    if(newPrimitive) {
                        newPrimitive.featureIdLabel = this.info.id;
                        newPrimitive.show = twoLayersVisibility;
                        this.layer.setProperties({'oltileset': newPrimitive});
                    }
                    break;
                case 'IMAGERY':
                    this._ol3d?.getCesiumScene()?.globe?.imageryLayers?.addImageryProvider(new Cesium.IonImageryProvider({ assetId: assetId?.split(',')[0] }));
                    break;
                case 'TERRAIN':
                    const ionTerrain = new Cesium.CesiumTerrainProvider({url: Cesium.IonResource.fromAssetId(assetId?.split(',')[0])});
                    this._ol3d.getCesiumScene().globe.terrainProvider(ionTerrain);
                    break;
                default:
                    return;
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        }
    }

}

export default ThreeDLayer;
