/* @flow */
import { digitalTwinQueryFields } from 'graphql/entities/classifications/classificationDetailQuery';

export default `
    id
    name
    image
    type
    description
    active
    primary
    enableGis
    iconName
    iconColor
    iconType
    attributes
    createdDate
    modifiedDate
    primaryClass {
        id
        name
        icon
        iconType
        color
        entityAppearance
        openInModule
        entityTabs
        formDefinition
        entitySvg
        entityGraphic {
            id
            type
            name
            image
        }
        entitySvgTransformation
        entitySvgVisibleZoom
        entityModel3d
        entityModelHeading
        entityModelScaling
        parents {
            name
            color
            icon
            iconType
            formDefinition
        }
        ${digitalTwinQueryFields}
    }
    svg
    svgTransformation
    _geoJson
    geom
    svgGraphic {
        id
        type
        name
        image
    }
    classes {
        id
        uri
        name
        formDefinition
        icon
        iconType
        color
        parents {
            uri
        }   
    }
    model3d
    modelHeading
    modelScaling
    createdBy {
        id
        name
        image
    }
    modifiedBy {
        id
        name
        image
    }
    chatId
`;
