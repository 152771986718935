// @flow

import { makeStyles } from '@material-ui/core/styles';

export const useIconStyles = makeStyles((/*theme: Theme*/) => ({
    root: {
        backgroundColor: 'transparent !important',
        color: (props: Object) => {
            return `${props.hexColor} !important`;
        }
    }
}));
