/* @flow */

import React, { PureComponent } from 'react';
import { Paper, Typography, Button, AppBar, Tab, Tabs } from '@mic3/platform-ui';
import styled from 'styled-components';
import { DOMParser } from '@xmldom/xmldom';

import { prettifyXml, removeSpaces } from 'app/utils/designer/process/processDesignerUtils';
import { bind } from 'app/utils/decorators/decoratorUtils';
import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';

const PaperStyled = styled(Paper)`
    padding: 0 12px;
    margin: 0;
    cursor: pointer;
    background: ${({ theme }) => theme.material.colors.background.default} !important;
`;

const AppBarStyled = styled(AppBar)`
    box-shadow: none !important;
    & .MuiTabs-flexContainer {
        background: ${({ theme }) => theme.material.colors.background.default};
    }
    & .MuiTab-wrapper {
        color: ${({ theme }) => theme.material.colors.text.primary};
    }
`;


class Assignments extends PureComponent<Object, Object> {

    formRef: Object = React.createRef();

    formDefinitionsFirst: Array<Object> = [
        {
            type: 'typeahead',
            properties: {
                label: 'Assignment', name: 'assignment',
                valueField: 'value', clearable: false,
                options: [
                    { label: 'Assigned to process initiator', value: 'process'},
                    { label: 'Assigned to single user', value: 'user'},
                    { label: 'Candidate teams', value: 'teams'},
                    { label: 'Candidate workspaces', value: 'workspaces'}
                ],
                defaultValue: 'process',
                onChange: ({ target }, data) => {
                    const response = [target];
                    if(target.value === 'user') {
                        response.push({ name: 'user', value:  null });
                    }
                    if(target.value === 'teams') {
                        response.push({ name: 'teams', value:  [] });
                    }
                    if(target.value === 'workspaces') {
                        response.push({ name: 'workspaces', value:  [] });
                    }
                    if(target.value === 'process') {
                        response.push({ name: 'allowProcessInitiator', value: false });
                    }
                    return response;
                }
            },
            constraints: { required: true }
        },
        {
            type: 'userTypeahead',
            properties: {
                label: 'Assignee',
                name: 'user',
                valueField: 'id',
                isVisible: data =>  data.assignment === 'user',
            },
            constraints: { required: true }
        },
        {
            type: 'typeahead',
            properties: {
                label: 'Strategy',
                name: 'strategy',
                valueField: 'value',
                clearable: false,
                options: [{ value: 'none', label: 'None'}, { value: 'cycle', label: 'Cycle'}, { value: 'lessEffort', label: 'Less Effort' }, { value: 'random', label: 'Random' }],
                isVisible: data =>  data.assignment === 'teams' || data.assignment === 'workspaces',
            },
            constraints: { required: true }
        },
        {
            type: 'workspaceTypeahead',
            properties: {
                label: 'Candidate Workspaces',
                name: 'workspaces',
                valueField: 'id',
                multiple: true,
                isVisible: data =>  data.assignment === 'workspaces',
            },
            constraints: { required: true }
        },
        {
            type: 'teamTypeahead',
            properties: {
                label: 'Candidate Teams',
                name: 'teams',
                valueField: 'id',
                multiple: true,
                allowPermissions: false,
                filterBy: [],
                isVisible: data =>  data.assignment === 'teams',
            },
            constraints: { required: true }
        },
        {
            type: 'boolean',
            properties: {
                label: 'Allow process initiator to complete task',
                name: 'allowProcessInitiator',
                defaultValue: false,
                isVisible: data => data.assignment === 'process',
            }
        },
    ]

    formDefinitionsSecond: Array<Object> = [
        {
            type: 'text',
            properties: {
                label: 'Assignee ID',
                name: 'user',
            },
        },
        {
            type: 'teamTypeahead',
            properties: {
                label: 'Candidate Teams',
                name: 'teams',
                valueField: 'id',
                multiple: true,
                allowPermissions: false,
                filterBy: [],
            },
        },
        {
            type: 'workspaceTypeahead',
            properties: {
                label: 'Candidate Workspaces',
                name: 'workspaces',
                valueField: 'id',
                multiple: true,
            },
        },
        {
            type: 'boolean',
            properties: {
                label: 'Allow process initiator to complete task',
                name: 'allowProcessInitiator',
                defaultValue: false
            }
        },
    ]

    constructor(props: Object) {
        super(props);
        const value = props.value || [];
        const denormalizedValues = this.deNormalizeValues(value);
        this.state = {
            showModal: false,
            value: denormalizedValues,
        };
    }

    componentDidUpdate(prevProps: Object) {
        const { value, data } = this.props;
        if (prevProps.value !== value || data.id !== prevProps.data.id) {
            const val = value || [];
            const denormalizedValues = this.deNormalizeValues(val);
            this.setState({ value: denormalizedValues });
        }
    }

    @bind
    deNormalizeValues(definition: Array<Object>): Array<Object> {
        const { data } = this.props;
        const { id } = data || {};
        if(!id) {
            console.error('Element should has ID for execution listeners.'); // eslint-disable-line no-console
        }

        const doc = new DOMParser().parseFromString(removeSpaces(definition));
        const parenElement =  doc.getElementById(id);
        if(!parenElement) {
            return {};
        }

        let initiatorElement = parenElement.getElementsByTagName('modeler:initiator-can-complete')[0];
        if(!initiatorElement) {
            initiatorElement = parenElement.getElementsByTagName('modeler:activiti-idm-initiator')[0];
        }
        const values = { assignment: 'process', message: 'No Assignment Selected' };

        const strategy = (parenElement.getAttribute('affectli:strategy') || '') || 'none';
        const teams = (parenElement.getAttribute('affectli:candidateTeams') || '').split(',').filter(Boolean);
        const workspaces = (parenElement.getAttribute('affectli:candidateWorkspaces') || '').split(',').filter(Boolean);
        const user = parenElement.getAttribute('flowable:assignee');

        let activeTab = -1;
        if(user === '${INITIATOR}') { // eslint-disable-line no-template-curly-in-string
            values['assignment'] = 'process';
            values['message'] = 'Assigned to process initiator';
            activeTab++;
        }
        if(teams.length) {
            values['assignment'] = 'teams';
            values['message'] = `Candidate teams: ${teams.length}`;
            activeTab++;
        }
        if(workspaces.length) {
            values['assignment'] = 'workspaces';
            values['message'] = `Candidate workspaces: ${workspaces.length}`;
            activeTab++;
        }
        if(user && user !== '${INITIATOR}') { // eslint-disable-line no-template-curly-in-string
            values['assignment'] = 'user';
            values['message'] = 'Assigned to single user';
            activeTab++;
        }
        values['workspaces'] = workspaces;
        values['teams'] = teams;
        values['user'] = user;
        values['strategy'] = strategy;
        values['allowProcessInitiator'] = initiatorElement ? initiatorElement.firstChild.data === 'true' : false;

        return {
            ...values,
            activeTab: activeTab > 0 ? 1 : 0,
            message: activeTab > 0 ? 'Multiple Assignment' : values.message,
        };
    }

    @bind
    normalizeValues(value: Array<Object>): Array<any> {
        const { data, value: definition } = this.props;
        const { id } = data || {};

        const doc = new DOMParser().parseFromString(removeSpaces(definition));
        const parenElement =  doc.getElementById(id);

        let initiatorElement = parenElement.getElementsByTagName('modeler:initiator-can-complete')[0];
        if(!initiatorElement) {
            initiatorElement = parenElement.getElementsByTagName('modeler:activiti-idm-initiator')[0];
        }
        const allowProcessInitiatorOld = initiatorElement ? initiatorElement.firstChild.data === 'true' : false;

        let isProcess = false;
        if(value.activeTab === 0) {
            switch(value.assignment) {
                case 'process': {
                    parenElement.setAttribute('flowable:assignee', '${INITIATOR}'); // eslint-disable-line no-template-curly-in-string
                    parenElement.removeAttribute('affectli:candidateWorkspaces');
                    parenElement.removeAttribute('affectli:candidateTeams');
                    break;
                }
                case 'user': {
                    parenElement.setAttribute('flowable:assignee', value.user);
                    parenElement.removeAttribute('affectli:candidateWorkspaces');
                    parenElement.removeAttribute('affectli:candidateTeams');
                    break;
                }
                case 'workspaces': {
                    parenElement.setAttribute('affectli:candidateWorkspaces', value.workspaces.join(','));
                    parenElement.setAttribute('affectli:strategy', value.strategy);
                    parenElement.removeAttribute('flowable:assignee');
                    parenElement.removeAttribute('affectli:candidateTeams', '');
                    break;
                }
                case 'teams': {
                    parenElement.removeAttribute('flowable:assignee');
                    parenElement.removeAttribute('affectli:candidateWorkspaces');
                    parenElement.setAttribute('affectli:strategy', value.strategy);
                    parenElement.setAttribute('affectli:candidateTeams', value.teams.join(','));
                    break;
                }
                default:
            }
            isProcess = value.assignment === 'process';
        } else {
            if(value.user) {
                parenElement.setAttribute('flowable:assignee', value.user);
            }
            if(value.workspaces.length) {
                parenElement.setAttribute('affectli:candidateWorkspaces', value.workspaces.join(','));
            }
            if(value.teams.length) {
                parenElement.setAttribute('affectli:candidateTeams', value.teams.join(','));
            }
        }

        if(allowProcessInitiatorOld !== value.allowProcessInitiator) {
            const tagName = isProcess ? 'modeler:activiti-idm-initiator' : 'modeler:initiator-can-complete';
            let initiatorElementNew = `
              <${tagName} xmlns:modeler="http://flowable.org/modeler"><![CDATA[${value.allowProcessInitiator || isProcess ? 'true' : 'false'}]]></${tagName}>
          `;

            let extensionElements = parenElement.getElementsByTagName('extensionElements')[0];
            if(!extensionElements) {
                initiatorElementNew = `<extensionElements>${initiatorElementNew}</extensionElements>`;
                extensionElements = new DOMParser().parseFromString(initiatorElementNew);
                extensionElements = extensionElements.getElementsByTagName('extensionElements')[0];
            
                const incoming = parenElement.getElementsByTagName('incoming')[0];
                const outgoing = parenElement.getElementsByTagName('outgoing')[0];
                if (incoming || outgoing) {
                    parenElement.insertBefore(extensionElements, incoming || outgoing);
                } else {
                    parenElement.appendChild(extensionElements);
                }
            } else {
                if(initiatorElement) {
                    extensionElements.removeChild(initiatorElement);
                }
                extensionElements.appendChild(new DOMParser().parseFromString(initiatorElementNew));
            }
        }

        return prettifyXml(String(doc));
    }

    @bind
    toggleModal(e: ?Object) {
        if(e) {
            e.stopPropagation();
        }
        this.setState(state => ({ showModal: !state.showModal }));
    }

    @bind
    handleOnChange(value) {
        this.setState({ value });
    }
    @bind
    handleOnChangeTab(evnt, activeTab) {
        this.setState({ value: { ...this.state.value, activeTab }});
    }

    @bind
    async onSave() {
        const { value } = this.state;
        const { name, onChange, changeVariable } = this.props;

        const { errors } = await this.formRef.current.isValidForm();
        if(!errors) {
            const normalizedValue = this.normalizeValues(value);

            this.toggleModal();
            const nextTarget = { value: normalizedValue, name };
            if(onChange) {
                onChange({ target: nextTarget });
            }
            if(changeVariable) {
                changeVariable(nextTarget);
            }    
        }
    }

    render() {
        const { showModal, value } = this.state;
        const { title } = this.props;
        return (
            <>
                <PaperStyled onClick={this.toggleModal}>
                    <Typography variant="caption">{title}</Typography>
                    <Typography >{value.message}</Typography>
                </PaperStyled>
                {showModal && (
                    <ModalDialog
                        onClose={this.toggleModal}
                        title={title}
                        actions={(
                            <Button onClick={this.onSave}>
                                Apply
                            </Button>
                        )}
                    >
                        <AppBarStyled position="static">
                            <Tabs value={value.activeTab} onChange={this.handleOnChangeTab} centered>
                                <Tab label="Identity Store" />
                                <Tab label="Fixed Values" />
                            </Tabs>
                        </AppBarStyled>
                        {value.activeTab === 0 && (
                            <FormGenerator
                                components={this.formDefinitionsFirst}
                                ref={this.formRef}
                                data={value}
                                onChange={this.handleOnChange}
                            />
                        )}
                        {value.activeTab === 1 && (
                            <FormGenerator
                                components={this.formDefinitionsSecond}
                                ref={this.formRef}
                                data={value}
                                onChange={this.handleOnChange}
                            />
                        )}
                    </ModalDialog>
                )}
            </>
        );
    }
};

export default Assignments;
