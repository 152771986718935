import { gql } from '@apollo/client';

import entityDetailsCommonQuery from 'graphql/entities/common/entityDetailsCommonQuery';

export default gql`
    query loadPrintTemplateDetailsQuery($id: ID!) {
        result: printTemplate(id: $id) {
            ${entityDetailsCommonQuery}
            _formDefinition {
              primary
            }
        }
    }
`;
