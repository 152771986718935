/* @flow */

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Typography } from '@mic3/platform-ui';

import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { createRelationship, fetchRelationDefinition, createEventEntityRelationship } from 'store/actions/entities/relationshipsActions';
import { get, groupBy } from 'app/utils/lo/lo';
import { isEmpty } from 'app/utils/utils';
import { groupFields } from 'app/utils/classification/classificationForm';
import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import { loadAllRelationDefinitionsOfType } from 'store/actions/entities/relationshipsActions';
import { getUnique } from 'app/utils/array/array-utils';
import Loader from 'app/components/atoms/Loader/Loader';

const StyledTypography = styled(Typography)`
    margin-left: 1rem !important;
`;

/**
 * A view to show the relationships of an entity.
 */
class RelationAddDialog extends PureComponent<Object, Object> {
    static propTypes = {
        fromUid: PropTypes.string.isRequired,
        fromType: PropTypes.string.isRequired,
        relationDefinition: PropTypes.shape({
            id: PropTypes.string,
            description: PropTypes.string,
            relatedDescription: PropTypes.string,
            relatedType: PropTypes.string,
            classes: PropTypes.array,
        }),
        onClose: PropTypes.func.isRequired,
        onSave: PropTypes.func.isRequired,
        createRelationship: PropTypes.func.isRequired,
        getClassFields: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = { data: { toType: '' }, ancestors: [] };
        this.loadRelDefs();
    }

    componentDidUpdate(prevProps, prevState) {
        const id = get(this.state, 'data.relationDefinition.relationDefinitionId');
        if (id && id !== get(prevState, 'data.relationDefinition.relationDefinitionId')) {
            this.props.fetchRelationDefinition(id);
        }
    }

    @bind
    async loadRelDefs() {
        const { fromType } = this.props;
        try {
            const { ancestors } = await this.props.loadAllRelationDefinitionsOfType(fromType);
            this.setState({ ancestors });
        } catch (error) {}
    }

    @bind
    groupByRelationDefinition(relations){
        if(!relations?.length) return {};
        const preRelations = relations.map(relation => ({
            ...relation,
            relationId: get(relation, 'relation.relationDefinition.id')
        }));
        return groupBy(preRelations, 'relationId');
    }

    @bind
    @memoize()
    buildComponents(fromUid, fromType, data, uniqueTypes) {
        const { records } = this.props;
        const { toType, relationDefinition } = data || {};
       
        const baseComponents = [
            {
                type: 'entityTypesTypeahead',
                properties: {
                    name: 'toType',
                    label: 'Related type',
                    valueField: 'uri',
                    onChange: (event) => {
                        return [
                            { name: 'toType', value: event.target.value },
                            { name: 'relationDefinition', value: null },
                            { name: 'relatedEntities', value: [] },
                        ];
                    },
                    filterBy: [
                        { field: 'active', op: '=', value: true },
                        uniqueTypes?.length && { field: 'uri', op: 'in', value: uniqueTypes },
                    ].filter(Boolean),
                    clearable: false,
                    constraints: { required: true },
                },
            },
            data?.toType && {
                type: 'relationDefinitionTypeahead',
                properties: {
                    required: true,
                    name: 'relationDefinition',
                    label: 'Relation',
                    fromType,
                    toType,
                    onChange: (event) => {
                        return [
                            { name: 'relationDefinition', value: event.target.value },
                            { name: 'relatedEntities', value: [] },
                        ];
                    },
                },
                constraints: { required: true },
            },
        ].filter(Boolean);

        if (data.relationDefinition) {
            let excludeBy = [{ field: 'id', op: '=', value: fromUid }];
            const relationsMap = this.groupByRelationDefinition(records);
            const excludedEntitiesIds = relationsMap?.[relationDefinition?.id]?.map(rel => rel.relatedEntity.id).filter(Boolean);
            if (excludedEntitiesIds?.length) {
                excludeBy = [{ field: 'id', op: 'in', value: [fromUid, ...excludedEntitiesIds] }];
            }
            baseComponents.push({
                type: 'entityTypeahead',
                properties: {
                    name: 'relatedEntities',
                    label: `Related ${toType?.toLowerCase()}`,
                    multiple: true,
                    entityType: toType,
                    excludeBy,
                    selectedIds: [fromUid, ...(excludedEntitiesIds || [])]
                },
                constraints: { required: true },
            });
        }

        const classFields = this.props.getClassFields(data);
        if (!isEmpty(classFields)) {
            baseComponents.push({
                type: 'group',
                properties: { name: 'attributes' },
                children: groupFields(classFields),
            });
        }
        return baseComponents;
    }

    @bind
    @memoize()
    getTypes(definitions, ancestors) {
        const { fromType } = this.props;
        const entityTypes = (definitions || [])
            .map(({ type, relatedType }) => {
                if (ancestors?.includes(type) && ancestors?.includes(relatedType)) {
                    if (type !== relatedType && fromType === type) {
                        return relatedType;
                    }
                    return type; // if relation definition for same type to same type exists
                }
                if (ancestors?.includes(type)) return relatedType;
                if (ancestors?.includes(relatedType)) return type;
                return null;
            })
            .filter(Boolean);
        return getUnique(entityTypes);
    }

    @bind
    @memoize()
    getFromTypeData(fromType, entityTypes) {
        return entityTypes?.find(type => type?.uri === fromType);
    }

    @bind
    async save(event) {
        event.preventDefault();
        const { data, errors } = await this.formRef.current.isValidForm();
        if (errors) {
            return;
        }
        const { fromUid, fromType, details } = this.props;
        const { relationDefinition, relatedEntities, attributes, toType } = data;
        if (!relatedEntities || !relatedEntities?.length) {
            return;
        }
        const responses = await Promise.all(
            relatedEntities.map((relatedEntity) => {
                let record = {};
                if (fromType === 'event' && details?.id) {
                    const { id, time } = details;
                    record = {
                        // reverse needs to handled
                        event: { id, time },
                        entity: { type: relatedEntity.type || toType, id: relatedEntity.id },
                        relationDefinition: relationDefinition.id,
                        attributes,
                    };
                    return this.props.createEventEntityRelationship(record);
                }
                record = {
                    entity: { type: fromType, id: fromUid },
                    relationDefinition: relationDefinition?.id,
                    relatedEntity: { type: relatedEntity.type || toType, id: relatedEntity.id },
                    attributes,
                };
                if (
                    relationDefinition.reverse ||
                    (fromType === relationDefinition.relatedType && toType === relationDefinition.type && fromType !== toType)
                ) {
                    // We are using reverse flag in case of same entity types if the flag is set then we need to swap the entity and relatedEntity
                    record = {
                        ...record, // if from and toType are different and its a reverse relationship and entity types are different then we need to swap entity and relatedEntity data
                        entity: { type: relatedEntity.type || toType, id: relatedEntity.id },
                        relatedEntity: { type: fromType, id: fromUid },
                    };
                }
                return this.props.createRelationship(record);
            })
        );
        if (responses.find((response) => response instanceof Error !== true)) {
            // if at least one relation is saved
            this.props.onSave();
        }
    }

    @bind
    onChange(data) {
        this.setState({ data });
    }

    /**
     * @override
     */
    render() {
        const { fromUid, fromType, relationDefinitions, defsLoading, entityTypes } = this.props;
        const { data, ancestors } = this.state;
        const uniqueTypes = this.getTypes(relationDefinitions, ancestors);
        const fromTypeData = this.getFromTypeData(fromType, entityTypes);
        return (
            <ModalDialog
                onClose={this.props.onClose}
                title='Create a new relation'
                actions={uniqueTypes?.length ? <Button onClick={this.save}>Save</Button> : null}
            >
                {defsLoading ? (
                    <Loader />
                ) : uniqueTypes?.length ? (
                    <FormGenerator
                        ref={this.formRef}
                        components={this.buildComponents(fromUid, fromType, data, uniqueTypes)}
                        onChange={this.onChange}
                        data={data}
                    />
                ) : (
                    <StyledTypography>
                        No relation definition exist for <b>{fromTypeData?.name || fromType}</b>, please create a relation definition first.
                    </StyledTypography>
                )}
            </ModalDialog>
        );
    }
}

export default connect(
    (state, ownProps) => ({
        relationDefinition: state.entities.relationDefinition.data,
        relationDefinitions: state.entities.mapedRelations.relationDefinitionsMap[ownProps.fromType],
        defsLoading: state.entities.mapedRelations.relationDefinitionsMap.isLoading,
        entityTypes: state.app.allPrimaryClasses.records || [],
    }),
    {
        createRelationship,
        fetchRelationDefinition,
        loadAllRelationDefinitionsOfType,
        createEventEntityRelationship,
    }
)(RelationAddDialog);
