// @flow
import { combineReducers } from 'redux';
import { loadDataReducer } from 'app/utils/redux/reducer-utils';
import {
    PIN_DETAILS_STARETD,
    PIN_DETAILS,
    PIN_ATTACHMENTS_STARETD,
    PIN_ATTACHMENTS,
    PIN_RELATIONSHIPS_STARETD,
    PIN_RELATIONSHIPS,
    LOAD_MAP_DATA_STARTED,
    LOAD_MAP_DATA,
    TOGGLE_FULLSCREEN_MAP,
    UPDATE_LAYER_ATTRIBUTE,
    UPDATE_BASE_LAYER,
    RESET_MAP_DATA,
    ADD_NEW_LAYER,
    REMOVE_LAYER,
    UPDATE_MAP_LAYER,
    SAVE_LAYER_FEATURES,
    SET_ALL_LIST_ITEM_LOADING,
    SET_LAYER_LOADING,
    SET_LAYER_ENTITIES_HIDDEN,
} from 'store/actions/maps/situationalAwarenessActions';
import { set } from 'app/utils/immutable/Immutable';
import genUuidv4 from 'uuid/v4';

//** To load Maps Entities **//
import Immutable from 'app/utils/immutable/Immutable';

import filters from 'store/reducers/maps/situationalAwareness/mapLayersFiltersReducer';
import drawing from 'store/reducers/maps/situationalAwareness/mapDrawingReducer';
import layer from 'store/reducers/maps/situationalAwareness/mapLayerReducer';
import search from 'store/reducers/maps/situationalAwareness/mapSearchReducer';
import { __extractRelevantLayerInfo, __getMapLayers, LAYERS_PRIMARY_KEY, BASELAYER_PRIMARY_KEY } from 'app/utils/maps/layer/layerUtils';
import { isEmpty } from 'validate.js';
import { isDefined } from 'app/utils/utils';
import cesiumLayerConfig from 'app/config/cesiumLayerConfig';

const initialState = {
    mapFullScreen: false,
    isLoading: true,
    data: {},
    originalData: {},
    isDataChanged: false,
    featuresMap: {},
    featuresLoading: {},
    layerEntitiesHidden: {},
};

// Simple deep comparison function
const deepEqual = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
};

const parseJSON = (data, fallback = null) => {
    try {
        return JSON.parse(data);
    } catch (e) {
        return fallback;
    }
};

const map = (state = initialState, action) => {
    const { type, payload, error } = action;
    switch (type) {
        case TOGGLE_FULLSCREEN_MAP: {
            return Immutable({ ...state, mapFullScreen: payload });
        }
        case LOAD_MAP_DATA_STARTED: {
            return Immutable({ ...state, isLoading: true });
        }
        case LOAD_MAP_DATA: {
            if (error) return Immutable({ ...state, isLoading: false });
            let data = { ...(payload || {}) };
            const featuresMap = { ...state.featuresMap };
            const featuresLoading = { ...state.featuresLoading };
            const layerEntitiesHidden = { ...state.layerEntitiesHidden };

            // Normalizing Legacy Data
            const layers = __getMapLayers(payload)
                ?.map((layer) => {
                    const lyr = { ...layer };
                    if(lyr.type === 'entity' && !lyr?.styles) {
                        lyr.styles = {};
                        lyr.styles.pinStyle = 'cluster';
                    }
                    lyr.expanded = true;
                    if (lyr?.event_type?.id) {
                        delete lyr.entity_type; // Junk data where we have entity type with the event type also saved so we need to remove entity type from event layers
                    }
                    if (lyr?.opacity > 1) {
                        lyr.opacity = 1;
                    }
                    if (!isDefined(lyr.visible)) {
                        lyr.visible = !lyr.hidden; // As code is using visible property
                    }
                    if (!lyr.id) {
                        lyr.id = genUuidv4(); // WIP: It would be removed in future. Legacy maps does not have IDs we have already changed the code to work on name field but as code base if too big so just to be on safe side adding id on FE side
                    }
                    if (lyr.type === 'replay' || lyr.type === 'replay-layer') {
                        lyr.type = 'replay';
                        featuresMap[lyr.id] = lyr?.relatedEntities; 
                    }
                    if (lyr.type === 'drawing' || lyr.type === 'drawing-layer') {
                        featuresMap[lyr.id] = parseJSON(lyr?.drawing_feature , []); // as we save drawing layer features along with layer, so we dont query data
                    }
                    if(lyr.type === 'cesium') {
                        lyr.type = cesiumLayerConfig?.cesiumLayer?.type;
                    }
                    if(lyr.visible && !['drawing', 'replay', 'replay-layer', 'drawing-layer', 'wms', 'system_cesium_layer'].includes(lyr?.type)){
                        featuresLoading[lyr.id] = isDefined(featuresLoading[lyr.id]) ? featuresLoading[lyr.id] : true; // Initializing loading of layers
                    }
                    if(Array.isArray(lyr.styles)) { // Legacy layers have styles as an Array so we need to normalize data
                        lyr.styles = { ...(lyr.styles[0] || {})};
                        if(lyr.styles?.type) {
                            lyr.styles.pinStyle = lyr.styles?.type; // We have a pinStyle, but on legacy maps we are getting pin style as a type
                            delete lyr.styles?.type;
                        }
                    }
                    if(lyr?.progress?.progress) { // legacy maps have progress inside progress but now we have it inside styles
                        lyr.styles = { ...(lyr.styles || {})};
                        lyr.styles.progress = lyr.progress.progress;
                        delete lyr?.progress;
                    }
                    if(lyr?.counter?.counter) { // // legacy maps have counter inside counter but now we have it inside styles
                        lyr.styles = { ...(lyr.styles || {})};
                        lyr.styles.counter = lyr.counter.counter;
                        delete lyr?.counter;
                    }
                    if (lyr.styles?.pinStyle === 'hideEntities') {
                        layerEntitiesHidden[lyr.id] = true;
                    }
                    return lyr;
                })
                ?.filter(Boolean);
            data = set(data, LAYERS_PRIMARY_KEY, layers);

            return Immutable({
                ...state,
                isLoading: false,
                data,
                originalData: data, // Store the original data
                isDataChanged: false,
                featuresMap,
                featuresLoading,
                layerEntitiesHidden, 
            });
        }
        case UPDATE_BASE_LAYER: {
            if (!payload) return { ...state };
            const updatedData = set(state.data, BASELAYER_PRIMARY_KEY, payload);
            return Immutable({
                ...state,
                data: updatedData,
                isDataChanged: !deepEqual(state.originalData, updatedData),
            });
        }
        case ADD_NEW_LAYER: {
            const mapLayers = __getMapLayers(state.data);

            if (!payload) return { ...state };

            const featuresLoading = { ...state.featuresLoading };

            if(!['drawing', 'drawing-layer', 'wms', 'system_cesium_layer', 'replay', 'replay-layer'].includes(payload?.type)){
                featuresLoading[payload.id] = true;
            }

            const updatedData = set(state.data, LAYERS_PRIMARY_KEY, [...mapLayers, payload]);

            return Immutable({
                ...state,
                data: updatedData,
                isDataChanged: !deepEqual(state.originalData, updatedData),
                featuresLoading
            });
        }
        case UPDATE_MAP_LAYER: {
            const layer = payload;
            if (!layer) {
                return Immutable({ ...state });
            }
            const featuresMap = { ...state.featuresMap };
            const { name, id } = layer;
            const layers = __getMapLayers(state.data);
            if (!layers?.length) return Immutable({ ...state });
            const updatedLayers = layers
                .map((lyr) => {
                    if (lyr.name === name || lyr.title === name || lyr.id === id) {
                        const updatedLayer = __extractRelevantLayerInfo({ ...lyr, ...layer });
                        if (updatedLayer.type === 'replay') {
                            featuresMap[lyr.id] = updatedLayer.relatedEntities || []; 
                        } else if (updatedLayer.type === 'drawing' || updatedLayer?.type === 'drawing-layer') {
                            featuresMap[lyr.id] = parseJSON(updatedLayer?.drawing_feature, []); 
                        }
                        return updatedLayer;
                    }
                    return lyr;
                })
                .filter(Boolean);
            const updatedData = set(state.data, LAYERS_PRIMARY_KEY, updatedLayers);
            return Immutable({
                ...state,
                data: updatedData,
                isDataChanged: !deepEqual(state.originalData, updatedData),
                featuresMap,
            });
        }
        case REMOVE_LAYER: {
            const layer = payload || {};
            const mapLayers = __getMapLayers(state.data);
            if (!mapLayers?.length || !payload) return Immutable({ ...state });
            const layers = mapLayers.map((lyr) => {
                if(lyr?.name === layer.name || lyr?.id === layer.id) return null;
                return lyr;
            }).filter(Boolean);
            const updatedData = set(state.data, LAYERS_PRIMARY_KEY, layers);

            return Immutable({
                ...state,
                data: updatedData,
                isDataChanged: !deepEqual(state.originalData, updatedData),
            });
        }
        case UPDATE_LAYER_ATTRIBUTE: {
            if (isEmpty(state.data) || !payload?.length) return { ...state };

            const updatedData = set(state.data, LAYERS_PRIMARY_KEY, payload);

            return Immutable({
                ...state,
                isLoading: false,
                data: updatedData,
                isDataChanged: !deepEqual(state.originalData, updatedData),
            });
        }
        case SAVE_LAYER_FEATURES: {
            const { id, features = [] } = payload;
            if (!id) return Immutable({ ...state });
            const featuresMap = { ...state.featuresMap, [id]: features || [] };
            const featuresLoading = { ...state.featuresLoading, [id]: false };
            return Immutable({ ...state, featuresMap, featuresLoading });
        }
        case SET_LAYER_LOADING: {
            const featuresLoading = { ...state.featuresLoading };
            if(state.featuresMap?.[payload]) { // if features are loaded then no need to set loading again
                return Immutable({ ...state });
            }
            featuresLoading[payload] = true;
            return Immutable({ ...state, featuresLoading });
        }
        case SET_ALL_LIST_ITEM_LOADING: {
            let featuresLoading = { ...state.featuresLoading };
            featuresLoading = Object.fromEntries(Object.keys(featuresLoading).map(key => [key, true]));
            return Immutable({ ...state, featuresLoading });
        }
        case SET_LAYER_ENTITIES_HIDDEN: {
            const { id, status } = payload;
            const layerEntitiesHidden = { ...state.layerEntitiesHidden };
            if (status) {
                layerEntitiesHidden[id] = true;
            } else {
                delete layerEntitiesHidden[id];
            }
            return Immutable({ ...state, layerEntitiesHidden });
        }
        default:
            return state;
    }
};

const situationalAwareness = combineReducers<Object, Object>({
    drawing,
    layer,
    search,
    filters,
    map,
    pinDetails: loadDataReducer(PIN_DETAILS_STARETD, PIN_DETAILS),
    attachments: loadDataReducer(PIN_ATTACHMENTS_STARETD, PIN_ATTACHMENTS),
    relations: loadDataReducer(PIN_RELATIONSHIPS_STARETD, PIN_RELATIONSHIPS),
});

const situationalAwarenessReducer = (state, action) => {
    if (action.type === RESET_MAP_DATA) {
        // Reset the state of situationalAwareness to its initial state
        state = undefined;
    }
    return combineReducers<Object, Object>({
        situationalAwareness,
    })(state, action);
};

export default situationalAwarenessReducer;
