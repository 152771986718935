/* @flow */

import { gql } from '@apollo/client';

import { fieldsConnectorStatusPipeline } from 'graphql/designer/connectorStatusPipelineQuery';

export default gql`
	mutation connectorRestartPipelineMutation($id: ID!, $connectorLabel: String!){
		result: iotPipelineConnectorRestart(id: $id, connectorLabel: $connectorLabel) {
			${fieldsConnectorStatusPipeline}
		}
	}
`;
