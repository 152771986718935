// @flow
import React, { useState, useCallback, PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { loadAvatar as loadEntitiesAvatar } from 'store/actions/entities/entitiesActions';
import { loadAvatar as loadClassesAvatar } from 'store/actions/classifications/classificationsActions';
import { Grid, Avatar, ClickAwayListener, Tooltip, Chip } from '@mic3/platform-ui';
import { withStyles } from '@material-ui/core/styles';
import { get } from 'app/utils/lo/lo';
import { getStr } from 'app/utils/utils';
import { getPriorityColor } from 'app/config/aboxConfig';
import { bind } from 'app/utils/decorators/decoratorUtils';
import { loadTypeaheadProcessDefinitions } from 'store/actions/abox/myAppsActions';
import { getAttachmentUrl } from 'app/utils/attachments/attachmentsUtils';
import { loadUserReferences } from 'store/actions/admin/usersActions';

const AvatarStyled = styled(Avatar)`
    margin-left: 0 !important;
`;

const ChipStyled = styled(Chip)`
    margin: 1px;
    height: 24px;
    color: ${({ theme }) => theme.material.palette.text.secondary};
    ${({theme, priority}) => priority && `background: linear-gradient(45deg, ${theme.priorityGradients[priority][0]}, ${theme.priorityGradients[priority][1]}) !important;`}
`;

const ChipTooltip = ({ tooltip, onClick, ...restProps }) => {
    const [isOpen, setTooltip] = useState(false);
    const close = useCallback(() => {
        setTooltip(false);
    }, [setTooltip]);
    const open = useCallback(() => {
        setTooltip(true);
    }, [setTooltip]);
    if(onClick) {
        return <ChipStyled {...restProps} onClick={onClick}/>;
    }
    return (
        <ClickAwayListener onClickAway={close}>
            <Tooltip
                PopperProps={{ disablePortal: true }}
                onClose={close}
                open={isOpen}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={tooltip}
            >
                <ChipStyled {...restProps} onClick={open} />
            </Tooltip>
        </ClickAwayListener>
    );
};

class ChipsField extends PureComponent<Object, Object> {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        name: PropTypes.string,
        label: PropTypes.string.isRequired,
        tooltip: PropTypes.string,
        openSidebar: PropTypes.string,
        entity: PropTypes.shape({
            id: PropTypes.string,
            type: PropTypes.string,
        }),
    }

    @bind
    getChipData(chipData){
        const entityId = get(chipData, 'entity.id', null);
        const { entity } = chipData || {};
        if(!entity?.id || !entity.type) {
            return;
        }
        switch (entity.type) {
            case 'user': {
                const { userReferences, userReferencesLoading } = this.props;
                const user = (userReferences || []).find(usr => usr.id === get(entityId, 'id') || usr.id === entityId);
                if(!user && !userReferencesLoading) {
                    this.props.loadUserReferences();
                }
                return user;
            }
            case 'class': {
                const { avatarClassesMap } = this.props;
                const classification = avatarClassesMap[entityId];
                if (!classification?.id) {
                    this.props.loadClassesAvatar(entityId);
                }
                return classification;
            }
            default: {
                const { avatarEntitesMap } = this.props;
                const entityAvatar = avatarEntitesMap[entityId];
                if(!entityAvatar) {
                    this.props.loadEntitiesAvatar(entity.id, entity.type);
                }
                return entityAvatar;
            }
                
        }
    }

    @bind
    parseAvatar(chipData) {
        const { classes } = this.props;
        const entity = this.getChipData(chipData);
        const id = getStr(entity, 'id', '');
        if(!chipData?.entity || !entity) {
            return null;
        }
        switch(entity.type) {
            case 'user': {
                const imageId = getStr(entity, 'image');
                const userName = getStr(entity, 'name');
                const image = imageId && getAttachmentUrl(id, 'user', imageId);
                return <AvatarStyled src={image} initials={userName} className={classes.avatar} />;
            }
            case 'opentask':
            case 'closedtask': {
                const imageId = get(entity, `image`) || get(entity, `assignee.image`);
                const userName = getStr(entity, `name`) || 'No Name';
                const image = imageId && getAttachmentUrl(id, 'user', imageId);
                return <AvatarStyled src={image} initials={userName} className={classes.avatar} />;
            }
            case 'relation': {
                const imageId = get(entity, `${String(name)}.image`);
                const userName = getStr(entity, `${String(name)}.name`);
                const image = imageId && getAttachmentUrl(id, get(entity, 'type'), imageId);
                return <AvatarStyled src={image} initials={userName} className={classes.avatar} />;
            }
            default: {
                const imageId = get(entity, `image`);
                const entityName = getStr(entity, `name`) || 'No Name';
                const image = imageId && getAttachmentUrl(id, get(entity, `type`),  imageId);
                return <AvatarStyled src={image} initials={entityName} className={classes.avatar} />;
            }
        }
    }

    @bind
    openSidebar(entity) {
        const { openSidebar } = this.props;
        const { id, type, openSidebar: title } = entity;
        openSidebar({ id, type, title, internal: true });
    }

    @bind
    buildChips(value) {
        if(Array.isArray(value)) {
            return value.map(val => this.buildChip(val));
        }
        return this.buildChip(value);
    }

    @bind
    buildChip(chipData) {
        const { classes, label: propsLabel, entity: entityProps, disabled } = this.props;
        const { label: chipLabel, entity, tooltip, openSidebar: openSidebarEntity } = chipData || {};
        const { name: nameLabel, openSidebar } = entity || {};
        const label = nameLabel || chipLabel || propsLabel || '';
        
        const avatar = this.parseAvatar(chipData);

        const extraProps = { disabled };

        const priority = get(entity, 'primary.priority', null);
        if(priority) {
            extraProps.priority = getPriorityColor(priority);
        }
        
        if(openSidebarEntity) {
            if(!entityProps) {
                extraProps.onClick = () => {};
            } else {
                extraProps.onClick = () => this.openSidebar({ 
                    ...(entity || entityProps), 
                    openSidebar: openSidebarEntity 
                });    
            }
        }
        if(openSidebar) {
            extraProps.onClick = () => this.openSidebar(entity);
        }

        return (
            <ChipTooltip
                key={String(label)}
                avatar={avatar}
                label={label}
                className={classes.chip}
                tooltip={tooltip || label}
                variant="outlined"
                {...extraProps}
            />
        );
    }

    render() {
        const { classes, label, value } = this.props;
        if(!value && !label) {
            return null;
        }
        const chipComponent = this.buildChips(value);
        return (
            <Grid className={classes.appBar} container alignItems="center">
                {chipComponent}
            </Grid>
        );
    }
};

/*
    Simple example: 
    {
        label: 'Denys Bogdanov',
        tooltip: 'User: Denys Bogdanov', // optional
    }
    Complex example: 
    {
        entity: {
            id: "", // required
            type: "", // required
            name: "", // will override label in root of object
            openSidebar: 'About', // optional will open sidebar of current entity
            assignee: { image: ""}, // optional if type opentask or closedtask
            primary: { priority: 3 }, // optional if type opentask or closedtask,
            modifiedDate // optional if type is relation,
            time: "" // required if entity type event
        }
        openSidebar: 'About', // optional 
                              // will open sidebar for current task
                              // will work if no entity.openSidebar
                              // not working in preview tab of designer
        label: 'Denys Bogdanov',
        tooltip: 'User: Denys Bogdanov', // will work if no entity.openSidebar
    }
*/

const styles = theme => ({
    appBar: {
        width: '100%',
        margin: '4px 0 4px 0',
        flexGrow: 1,
        overflow: 'auto'
    },
    chip: {
        height: '24px',
        margin: '0 4px 8px',
    },
    avatar: {
        width: '24px',
        height: '24px',
    }
});
export default connect(state => ({
    avatarEntitesMap: state.entities.avatar,
    avatarClassesMap: state.classifications.avatar,
    userReferences: state.admin.users.references.data,
    userReferencesLoading: state.admin.users.references.isLoading,
}), { 
    loadUserReferences, loadEntitiesAvatar, loadClassesAvatar, loadTypeaheadProcessDefinitions,
})(withStyles(styles)(ChipsField));
