/* @flow */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import PageContent from 'app/components/molecules/PageContent/PageContent';
import ErrorBoundary from 'app/components/atoms/ErrorBoundary/ErrorBoundary';
import { setHeader } from 'store/actions/app/appActions';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';

/**
 * Layout that controls the page
 */
class PageTemplate extends PureComponent<Object, Object> {

    static propTypes = {
        info: PropTypes.array,
        overflowHidden: PropTypes.bool,
        children: PropTypes.any,
        setHeader: PropTypes.func,
    };

    /**
     * Try push to store onMount
     */
    componentDidMount() {
        this.setHeaderProps(this.props);
    }

    /**
     * Update state when changes are pushed
     * @param prevProps
     */
    componentDidUpdate(prevProps) {
        const { menuItems, color, actions } = this.props;
        if (menuItems && prevProps.menuItems !== menuItems) {
            this.setHeaderProps({
                menuItems, color, actions,
            });
        }
    }

    /**
     * Update our headers func
     */
    @bind
    @memoize()
    setHeaderProps(props) {
        const { actions, menuItems  } = props;
        const Headers = {
            actions: actions || '',
            menuItems: menuItems || '',
        };
        this.props.setHeader(Headers);
    };

    /**
     * Render our page template
     */
    render() {
        const { overflowHidden, children, hasIframe } = this.props;
        return (
            <ErrorBoundary>
                <PageContent overflowHidden={overflowHidden} hasIframe={hasIframe}>
                    {children}
                </PageContent>
            </ErrorBoundary>
        );
    }
}

export default connect(null, { setHeader })(PageTemplate);
