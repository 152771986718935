/* @flow */

import Immutable from 'app/utils/immutable/Immutable';

import {
    LOAD_CHANNEL_LIST_STARTED,
    LOAD_CHANNEL_LIST
} from 'store/actions/chat/chatActions';

const initialState = {
    isLoading: false,
    data: []
};

export default (state: Object = initialState, action: Function) => {
    const { type, error, payload } = action;
    switch (type) {
        case LOAD_CHANNEL_LIST_STARTED: {
            if (error) {
                return state;
            }
            return Immutable({ ...state, isLoading: true });
        }

        case LOAD_CHANNEL_LIST: {
            if (error) {
                return state;
            }
            return Immutable({ ...state, data: payload, isLoading: false });
        }

        default:
            return state;
    }
};
