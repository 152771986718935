/* @flow */
import { loadData } from 'app/utils/redux/action-utils';
import { mutateData } from 'app/utils/redux/action-utils';
import OptionsBuilder from 'app/utils/api/OptionsBuilder';
import history from 'store/History';
import classificationAutocompleteQuery from 'graphql/entities/classifications/classificationAutocompleteQuery';
import classificationDetailQuery from 'graphql/entities/classifications/classificationDetailQuery';
import classDefinitionQuery from 'graphql/entities/classifications/classDefinitionQuery';
import classificationsByIdsQuery from 'graphql/entities/classifications/classificationsByIdsQuery';
import classificationTaskAndProcess from 'graphql/entities/classifications/classificationTaskAndProcess';
import createClassificationMutation from 'graphql/entities/classifications/createClassificationMutation';
import updateClassificationMutation from 'graphql/entities/classifications/updateClassificationMutation';
import updateClassificationReporting from 'graphql/entities/classifications/updateClassificationReporting';
import { indexAttributeMutation, removeIndexAttributeMutation } from 'graphql/entities/classifications/indexAttributeMutation';
import entitiesQuery from 'graphql/entities/entities/entitiesQuery';
import classesListShortQuery from 'graphql/entities/classifications/classesListShortQuery';
import classesListRaisedQuery from 'graphql/entities/classifications/classesListRaisedQuery';
import loadAvatarQuery from 'graphql/entities/classifications/loadAvatarQuery';
import classAncestorsQuery from 'graphql/entities/classifications/classAncestorsQuery';
import classAncestorsTableQuery from 'graphql/entities/classifications/classAncestorsTableQuery';
import iotAttributesQuery from 'graphql/entities/classifications/iotAttributesQuery.js';
import { getAvatar } from 'app/utils/avatar/avatar';
import { addRoleToData, addRoleToList } from 'app/config/rolesConfig';
import { updatePrimaryClassInCache } from 'store/actions/app/appActions';
import { addTypeToList } from 'app/utils/entity/entityUtils';
import { 
    classDigitalTwinAddMutation,
    classDigitalTwinUpdateMutation,
    classDigitalTwinRemoveMutation } from 'graphql/entities/classifications/classDigitalTwinMutations';

export const UPDATE_ENTITY_CLASSIFICATION = '@@affectli/classifications/UPDATE_ENTITY_CLASSIFICATION';

export const CREATE_CLASSIFICATION_STARTED = '@@affectli/classifications/CREATE_CLASSIFICATION_STARTED';
export const CREATE_CLASSIFICATION = '@@affectli/classifications/CREATE_CLASSIFICATION';

export const LOAD_CLASSIFICATIONS_BY_IDS_STARTED = '@@affectli/classifications/LOAD_CLASSIFICATIONS_BY_IDS_STARTED';
export const LOAD_CLASSIFICATIONS_BY_IDS = '@@affectli/classifications/LOAD_CLASSIFICATIONS_BY_IDS';

export const LOAD_CLASSIFICATION_STARTED = '@@affectli/classifications/LOAD_CLASSIFICATION_STARTED';
export const LOAD_CLASSIFICATION = '@@affectli/classifications/LOAD_CLASSIFICATION';

export const LOAD_CLASSIFICATIONS_IOT_ATTRIBUTES_STARTED = '@@affectli/classifications/LOAD_IOT_ATTRIBUTES_STARTED';
export const LOAD_CLASSIFICATIONS_IOT_ATTRIBUTES = '@@affectli/classifications/LOAD_IOT_ATTRIBUTES';

export const LOAD_CLASSIFICATION_ENTITIES_STARTED = '@@affectli/classifications/entities/LOAD_CLASSIFICATION_ENTITIES_STARTED';
export const LOAD_CLASSIFICATION_ENTITIES = '@@affectli/classifications/LOAD_CLASSIFICATION_ENTITIES';

export const LOAD_CLASSIFICATIONS_TASK_PROCESS_STARTED = '@@affectli/classifications/entities/LOAD_CLASSIFICATION_ENTITIES_STARTED';
export const LOAD_CLASSIFICATIONS_TASK_PROCESS = '@@affectli/classifications/LOAD_CLASSIFICATION_ENTITIES';

export const LOAD_CLASSIFICATION_AUTOCOMPLETE_STARTED = '@@affectli/admin/groups/LOAD_CLASSIFICATION_AUTOCOMPLETE_STARTED';
export const LOAD_CLASSIFICATION_AUTOCOMPLETE = '@@affectli/admin/groups/LOAD_CLASSIFICATION_AUTOCOMPLETE';

export const LOAD_CLASS_DEFINITION_STARTED = '@@affectli/admin/groups/LOAD_CLASS_DEFINITION_STARTED';
export const LOAD_CLASS_DEFINITION = '@@affectli/admin/groups/LOAD_CLASS_DEFINITION';

export const LOAD_CLASSES_LIST_CARD_STARTED = '@@affectli/entities/classes/LOAD_CLASSES_LIST_CARD_STARTED';
export const LOAD_CLASSES_LIST_CARD = '@@affectli/entities/classes/LOAD_CLASSES_LIST_CARD';

export const LOAD_AVATAR_STARTED = '@@affectli/entities/classes/LOAD_AVATAR_STARTED';
export const LOAD_AVATAR = '@@affectli/entities/classes/LOAD_AVATAR';

export const LOAD_CLASS_CHILDRENS_STARTED = '@@affectli/entities/classes/LOAD_CLASS_CHILDRENS_STARTED';
export const LOAD_CLASS_CHILDRENS = '@@affectli/entities/classes/LOAD_CLASS_CHILDRENS';

export const UPDATE_CLASS_CHILDRENS_STARTED = '@@affectli/entities/classes/UPDATE_CLASS_CHILDRENS_STARTED';
export const UPDATE_CLASS_CHILDRENS = '@@affectli/entities/classes/UPDATE_CLASS_CHILDRENS';

export const LOAD_CLASS_CHILDREN_LEFT_PANEL_STARTED = '@@affectli/entities/classes/LOAD_CLASS_CHILDREN_LEFT_PANEL_STARTED';
export const LOAD_CLASS_CHILDREN_LEFT_PANEL = '@@affectli/entities/classes/LOAD_CLASS_CHILDREN_LEFT_PANEL';

export const LOAD_ENTITY_CLASS_APPLIED_ON_LEFT_PANEL_STARTED = '@@affectli/entities/classes/LOAD_ENTITY_CLASS_APPLIED_ON_LEFT_PANEL_STARTED';
export const LOAD_ENTITY_CLASS_APPLIED_ON_LEFT_PANEL = '@@affectli/entities/classes/LOAD_ENTITY_CLASS_APPLIED_ON_LEFT_PANEL';

export const LOAD_CLASS_ANCESTORS_STARTED = '@@affectli/entities/classes/LOAD_CLASS_ANCESTORS_STARTED';
export const LOAD_CLASS_ANCESTORS = '@@affectli/entities/classes/LOAD_CLASS_ANCESTORS';

export const INDEX_ATTRIBUTE_STARTED = '@@affectli/entities/classes/INDEX_ATTRIBUTE_STARTED';
export const INDEX_ATTRIBUTE = '@@affectli/entities/classes/INDEX_ATTRIBUTE';

export const loadAvatar = (id) => (dispatch: Function, getState: Function) => {
    const avatar = getAvatar(getState(), 'class', id);
    if (avatar && (avatar.isLoading || avatar.loadedAt > Date.now() - 60 * 60 * 1000)) {
        // if we are loding the avatar or it is already loaded and it is stored less then 1 hour ago
        // we return the data in the state without calling the service
        return Promise.resolve(avatar);
    }
    return loadData(LOAD_AVATAR_STARTED, LOAD_AVATAR, loadAvatarQuery)({ id })(dispatch, getState);
};

export const classDigitalTwinAdd = (variables: Object) =>
    mutateData(LOAD_CLASSIFICATION_STARTED, LOAD_CLASSIFICATION, classDigitalTwinAddMutation, 'Digital twin added.', (payload) => addRoleToData({ ...payload, type: 'class' }))({ ...variables });

export const classDigitalTwinUpdate = (variables: Object) =>
    mutateData(LOAD_CLASSIFICATION_STARTED, LOAD_CLASSIFICATION, classDigitalTwinUpdateMutation, 'Digital twin updated.', (payload) => addRoleToData({ ...payload, type: 'class' }))({ ...variables });

export const classDigitalTwinRemove = (variables: Object) =>
    mutateData(LOAD_CLASSIFICATION_STARTED, LOAD_CLASSIFICATION, classDigitalTwinRemoveMutation, 'Digital twin removed.', (payload) => addRoleToData({ ...payload, type: 'class' }))({ ...variables });

export const loadClassAncestors = (options, withoutReducer) =>
    loadData(LOAD_CLASS_ANCESTORS_STARTED, LOAD_CLASS_ANCESTORS, withoutReducer ? classAncestorsQuery : classAncestorsTableQuery)(options);

/**
 * Loads the suggestions for the person autocomplete component.
 */
export const loadClassificationAutocomplete = (options, extraQueryFields) =>
    loadData(
        LOAD_CLASSIFICATION_AUTOCOMPLETE_STARTED,
        LOAD_CLASSIFICATION_AUTOCOMPLETE,
        classificationAutocompleteQuery(extraQueryFields)
    )(options);

/**
 * This function will load the classification detail on a given id
 */
export const loadClassification = (id: Number) => (dispatch, getState) =>
    loadData(
        LOAD_CLASSIFICATION_STARTED,
        LOAD_CLASSIFICATION,
        classificationDetailQuery
    )({ id })(dispatch, getState).then((payload) => {
        if (payload instanceof Error) return payload;
        dispatch(updatePrimaryClassInCache(payload));
        return payload;
    });

/**
 * This function will load the classification detail on a given id
 */
export const loadClassDefinition = (id: Number) =>
    loadData(LOAD_CLASS_DEFINITION_STARTED, LOAD_CLASS_DEFINITION, classDefinitionQuery)({ id });

/**
 * This function will load the classification detail on a given id
 */
export const loadClassificationsByIds = (ids: Number) => {
    const filterBy = { field: 'id', op: 'in', value: ids };
    return loadData(LOAD_CLASSIFICATIONS_BY_IDS_STARTED, LOAD_CLASSIFICATIONS_BY_IDS, classificationsByIdsQuery)({ filterBy });
};

export const loadClassificationIotData = (type: string, id: string, iotClassUri: string) =>
    loadData(LOAD_CLASSIFICATIONS_IOT_ATTRIBUTES_STARTED, LOAD_CLASSIFICATIONS_IOT_ATTRIBUTES, iotAttributesQuery)({ type, id, iotClassUri });

export const loadClassificationTaskAndProcess = () => {
    const filterBy = [
        { field: 'primary', op: '=', value: true },
        {
            or: [
                { field: 'uri', op: 'contains', value: 'task' },
                { field: 'uri', op: 'contains', value: 'process' },
            ],
        },
    ];
    return loadData(
        LOAD_CLASSIFICATIONS_TASK_PROCESS_STARTED,
        LOAD_CLASSIFICATIONS_TASK_PROCESS,
        classificationTaskAndProcess
    )({ filterBy });
};

/**
 * Load the loads the entities for the DataTable
 *
 * @param options the options ({ page, pageSize, countMax, where, orderBy, download,  })
 */
export const loadClassificationEntities = (type: string, options: Object) => {
    const variables = new OptionsBuilder(options).defaultStartStopIndexs(0, 30);
    return loadData(
        LOAD_CLASSIFICATION_ENTITIES_STARTED,
        LOAD_CLASSIFICATION_ENTITIES,
        entitiesQuery,
        addRoleToList
    )({
        ...variables.build(),
        type,
        startIndex: options?.startIndex || 0,
    });
};

export const updateClassification = (classification: Object, entityType) => (dispatch, getState) =>
    mutateData(LOAD_CLASSIFICATION_STARTED, LOAD_CLASSIFICATION, updateClassificationMutation, entityType ? 'Entity Type updated.' : 'Classification updated.', (payload) =>
        addRoleToData({ ...payload, type: 'class' })
    )({ classification })(dispatch, getState).then((payload) => {
        if (payload instanceof Error) return payload;
        dispatch(updatePrimaryClassInCache(payload));
        return payload;
    });

export const updateClassificationReport = (classification: Object) => (dispatch, getState) =>
    mutateData(
        LOAD_CLASSIFICATION_STARTED,
        LOAD_CLASSIFICATION,
        updateClassificationReporting,
        classification.enabled ? 'Reporting enabled.':'Reporting disabled.',
        (payload) => addRoleToData({ ...payload, type: 'class' })
    )(classification)(dispatch, getState).then((payload) => {
        if (payload instanceof Error) return payload;
        dispatch(updatePrimaryClassInCache(payload));
        return payload;
    });

export const updateClassChildrens = (classification: Object) =>
    mutateData(UPDATE_CLASS_CHILDRENS_STARTED, UPDATE_CLASS_CHILDRENS, updateClassificationMutation, 'Children updated.', (payload) =>
        addRoleToData({ ...payload, type: 'class' })
    )({ classification });

export const makeAttributeIndexed = (variables: Object) =>
    mutateData(INDEX_ATTRIBUTE_STARTED, INDEX_ATTRIBUTE, indexAttributeMutation, 'Attribute is being indexed.')({ ...variables });

export const removeIndexAttribute = (variables: Object) =>
    mutateData(
        INDEX_ATTRIBUTE_STARTED,
        INDEX_ATTRIBUTE,
        removeIndexAttributeMutation,
        'Attribute indexing is being removed.'
    )({ ...variables });

/**
 * This function will create classification by receiving a classification object
 * Classifification object should only contain 'name' and 'uri'
 */
export const createClassification = (classification: Object) => (dispatch: Function, getState: Function) => {
    mutateData(
        CREATE_CLASSIFICATION_STARTED,
        CREATE_CLASSIFICATION,
        createClassificationMutation,
        classification?.primary ? 'Entity Type created.' :  'Classification created.'
    )({ classification })(dispatch, getState).then((resp) => {
        if (!(resp instanceof Error)) {
            dispatch(updatePrimaryClassInCache(resp));
            resp.primary ? history.push(`/entity-types/${resp.id}`) : history.push(`/classifications/${resp.id}`);
        }
    });
};

export const loadClassesListView = ({ isShort, options, aditionalFields }: Object = { isShort: false }) =>
    loadData(
        LOAD_CLASSES_LIST_CARD_STARTED,
        LOAD_CLASSES_LIST_CARD,
        isShort ? classesListShortQuery(aditionalFields) : classesListRaisedQuery,
        (payload) => addRoleToList({ ...payload, records: addTypeToList(payload?.records, 'class') }) // Add type 'class' to records and then fetch roles for each record
    )(options);

const _getChildrenVars = (id, entityUri) => {
    return { 
        startIndex: 0,
        stopIndex: 1000,
        filterBy: [
            { field: 'parents.id', op: 'in', value: [id] },
            entityUri && { field: 'applicableOn', op: 'overlaps', value: [entityUri, 'entity']}
        ].filter(Boolean)
    };
};

export const loadClassChildrens = (id: String) =>
    loadData(LOAD_CLASS_CHILDRENS_STARTED, LOAD_CLASS_CHILDRENS, classesListShortQuery(`parents { id name uri }`))(_getChildrenVars(id));

export const loadClassChildrenLeftPanel = (id: String, parentEntityUri: String) =>
    loadData(LOAD_CLASS_CHILDREN_LEFT_PANEL_STARTED, LOAD_CLASS_CHILDREN_LEFT_PANEL, classesListShortQuery(''))(_getChildrenVars(id, parentEntityUri));

export const loadEntityClassesAppliesToLeftPanel = (entityUri: String) =>
    loadData(LOAD_ENTITY_CLASS_APPLIED_ON_LEFT_PANEL_STARTED, LOAD_ENTITY_CLASS_APPLIED_ON_LEFT_PANEL, classesListShortQuery(''))({
        'filterBy': [
            { field: 'active', op: '=', value: true },
            { field: 'applicableOn', op: 'overlaps', value: [entityUri, 'entity']},
        ]
    });
