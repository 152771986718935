/* @flow */

import React, { PureComponent } from 'react';
import { CircularProgress, Divider } from '@mic3/platform-ui';
import styled from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import PageNotAllowed from 'app/containers/ErrorPages/PageNotAllowed';
import Help from 'app/utils/designer/form/settings/common/Help';
import Button from 'app/components/atoms/Button/Button';

import { createClassification } from 'store/actions/classifications/classificationsActions';
import { bind } from 'app/utils/decorators/decoratorUtils';
import Immutable from 'app/utils/immutable/Immutable';
import history from 'store/History';
import { setDocumentTitle } from 'store/actions/app/appActions';
import { modulesAndPageTitles } from 'app/config/typesConfig';

const StyledFormGenerator = styled(FormGenerator)`
    padding: 0;
    & .FormField-root {
        padding: 0;
    }
    & .swatches-picker {
        position: fixed;
    }
    & .MuiBadge-anchorOriginTopRightRectangle {
        right: 0.7rem !important;
    }
`;
const CancelButton = styled(Button)`
    margin: 0;
    color: #7391D0;
`;
const StyledDivider = styled(Divider)`
    margin: ${({ margin }) =>
    margin ? `${margin} !important` : '5px 0 !important'};
`;
const StyledLabel = styled.div`
    margin: ${({ margin }) => (margin ? `${margin} !important` : '0')};
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: ${({theme})=> theme.material.colors.text.secondary};
`;

export const uriFieldDefinition = entityType => (
    {
        type: 'text',
        properties: {
            label: entityType ? 'Entity type URI' : 'Class URI',
            name: 'uri',
            help: (
                <Help
                    message={`Only the following characters are allowed:\n- Lowercase letters (a-z)\n- Numbers (0-9)\n- Dashes (-)\n- Underscores(_)\n`}
                />
            )
        },
        constraints: {
            required: true,
            minLength: 3,
            custom: (data) => {
                const noStartFromNumbers = { format: {
                    pattern: /somefaketest/,
                    message: 'Only the following characters are allowed: Lowercase letters, Numbers, Dashes, Underscores'
                }};
                if (!/^[0-9a-z\-_]+$/g.test(data.uri)) {
                    return noStartFromNumbers;
                }
                return {};
            }}
    }
);

class AddClassification extends PureComponent<Object, Object> {
    static propTypes = {
        createClassification: PropTypes.func.isRequired,
        isLoading: PropTypes.bool
    };

    static defaultProps = {
        isLoading: false
    };

    formRef: Object = React.createRef();

    componentDidMount() {
        const { match, setDocumentTitle } = this.props;
        if(match?.path?.includes('add')){
            setDocumentTitle(modulesAndPageTitles.classes.add);
        }
    }


    fieldDefinitions(entityType){

        return [
            {
                field: 'name',
                type: 'text',
                properties: { label: entityType ? 'Entity type name':'Class name', name: 'name' },
                constraints: { required: true, minLength: 3, maxLength: 60 }
            },
            uriFieldDefinition(entityType),
            {
                field: 'icon',
                type: 'iconSelect',
                properties: {
                    label: entityType ? 'Entity type icon' : 'Class icon',
                    name: 'iconState',
                    clearable: false,
                    randomized: 'true',
                },
                constraints: { required: true }
            },
            {
                field: 'color',
                type: 'colorPicker',
                properties: {
                    label: entityType ? 'Entity type colour' : 'Class colour',
                    name: 'color',
                    valueField: 'value',
                    randomized: 'true',
                },
                constraints: { required: true }
            }
        ];
    } 

    state: Object = Immutable({ formData: { name: '', icon: '', color: '' }, isCustomUri: false });

    /**
     * Handle the form submit event.
     */
    @bind
    onFormSubmit(e: Event) {
        e.preventDefault();
        this.formRef.current.isValidForm().then(async ({ errors, data }) => {
            if (!errors) {
                const { formData: { icon, iconType, name, uri, color } } = this.state;
                this.props.createClassification({ icon, iconType, name: name.trim(), uri, color, primary: this.props.entityType });
            }
        });
    }

    /**
     * Handle the on change event of the elements inside of the form.
     */
    @bind
    async handleChange(data: Object, variables) {
        const { isCustomUri } = this.state;
        const { name: varName, value: varValue } = variables || {};
        let errors = null;
        if (varName === 'name' && !isCustomUri) {
            data = { ...data, uri: this._normalizeValue(varValue) };
        }
        if (varName === 'uri') {
            data = { ...data, uri: this._normalizeValue(varValue) };
            if (varValue && !isCustomUri) this.setState({ isCustomUri: true });
            if (!varValue) this.setState({ isCustomUri: false });
        }
        if (varName === 'iconState') {
            data = { ...data, icon: varValue.value, iconType: varValue.type };
        }
        
        this.setState({ formData: data }, async () => {
            if(data?.uri && data?.name) {
                const res = await this.formRef.current.isValidForm();
                errors = res.errors;
                this.setState({ isValidForm: !errors });
            }
        });
    }

    @bind
    _normalizeValue(value: string) {
        if (value) {
            value = value.trim().toLowerCase();
            value = value.replace(/[^0-9a-z.\-_]+/ig, '-');
            return value;
        }
    }

    @bind
    onClose() {
        const { onClose } = this.props;
        if (onClose) {
            return this.props.onClose();
        }
        history.pushBack();
    }

    /**
     * @override
     */
    render(): Object {
        const { isLoading, isMobileView, entityType } = this.props;
        const { formData, isValidForm} = this.state;
        const { permissions, isAdmin } = this.props.userProfile;
        const permissionsSet = new Set(permissions || []);
        const canAdd = isAdmin || permissionsSet.has('class.class.add');
        if (!canAdd) {
            return <PageNotAllowed title={entityType ? 'Entity Types' : 'Classes'} />;
        }
        return (
            <ModalDialog
                dialogProps={{ overflow: 'hidden' }}
                withoutClose={!isMobileView}
                onClose={this.onClose}
                title={entityType ? 'New Entity Type' : 'New Class'}
                actions={
                    isLoading ? (
                        <CircularProgress size={24} color="primary" />
                    ) : (
                        <>
                            {!isMobileView && (<CancelButton disabled={isLoading}  onClick={this.onClose}>Cancel</CancelButton>)}
                            <Button
                                disabled={isLoading || !isValidForm}
                                color='primary'
                                onClick={this.onFormSubmit}
                            >
                                Save
                            </Button>
                        </>
                    )
                }
            >
                <StyledFormGenerator components={this.fieldDefinitions(entityType)} ref={this.formRef} onChange={this.handleChange} data={formData} />
                <StyledDivider margin="32px 0 15px 0" />
                <StyledLabel margin='0 0 5px'>Required fields*</StyledLabel>
            </ModalDialog>
        );
    }
}

export default connect(
    state => ({
        userProfile: state.user.profile,
        isLoading: state.classifications.addedClassification.isLoading,
        isMobileView: state.global.isMobile
    }),
    { createClassification, setDocumentTitle, }
)(AddClassification);
