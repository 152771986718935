// LIBRARY IMPORTS
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { HeaderTagProps } from 'app/utils/propTypes/common';
//import { ifProp } from 'styled-tools';

const TitleBase = styled.span`
  font-weight: 500;
  margin: 0;
  white-space: nowrap;
  overflow:hidden;
  text-overflow: ellipsis;
  a {
    color: ${({ theme }) => theme.base.textColor};
  }

  ${({ disableSelection }) => disableSelection && `
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  `}
`;

const Title = (props) => {
    const { as, children, ...restProps } = props;
    return (
        <TitleBase as={as} {...restProps}>
            {children}
        </TitleBase>
    );
};

Title.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
    ]),
    as: HeaderTagProps,
    disableSelection: PropTypes.bool
};

Title.defaultProps = {
    as: 'h3'
};


export default Title;
