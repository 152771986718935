/* @flow */

import { gql } from '@apollo/client';

export default gql`
    mutation startProcessByMessageMutation($message: String!, $variables: [BpmnVariableInput], $businessKey: String) {
        result: startProcessByMessage(message: $message, variables: $variables, businessKey: $businessKey) {
            id
            name
            primary
            tasks {
                id
                name
                assignee {
                    id
                }
            }
        }
    }
`;
