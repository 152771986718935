/* @flow */

import { gql } from '@apollo/client';

export default gql`
query activeBroadcastsQuery {
  broadcasts: userActiveBroadcasts {
      id
      message
  }
}
`;
