/* @flow */

import React, { useEffect, useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import GlobalTemplate from 'app/components/templates/GlobalTemplate';

import LegacyApp from 'app/containers/LegacyApp/LegacyApp';
import Loader from 'app/components/atoms/Loader/Loader';

import { loadUserPreferences, loadUserReferences, loadIntegrations } from 'store/actions/admin/usersActions';
import { initChatSocket } from 'store/actions/chat/chatActions';

import AboxRoute from 'app/containers/Abox/AboxRoute';
import AdminBackgroundJobRoute from 'app/containers/Admin/AdminBackgroundJobRoute';
import AnalyticsRoute from 'app/containers/Analytics/AnalyticsRoute';
import SuperAnalyticsRoute from 'app/containers/Superset/SuperAnalyticsRoute';
import GrafanaRoute from 'app/containers/Grafana/GrafanaRoute';
import BroadcastsRoute from 'app/containers/Broadcasts/BroadcastRoute';
import ClassificationsRoute from 'app/containers/Classifications/ClassificationsRoute';
import EntityTypesRoute from 'app/containers/EntityTypes/EntityTypesRoute';
import DashboardsNewRoute from 'app/containers/Dashboards_new/DashboardsRoute';
import DesignerRoute from 'app/containers/Designer/DesignerRoute';
import EventsRoute from 'app/containers/Stream/Events/EventsRoute';
import EntitiesRoute from 'app/containers/Entities/EntitiesRoute';
import RelatedEntitiesRoute from 'app/containers/Entities/RelatedEntities/RelatedEntitiesRoute';
import TeamsRoute from 'app/containers/Admin/Teams/TeamsRoute';
import IntegrationRoutes from 'app/containers/Integration/IntegrationRoutes';
import WorkspacesRoute from 'app/containers/Admin/Workspaces/WorkspacesRoute';
import MapsRoute from 'app/containers/Maps/MapsRoute';
import MarketplaceRoute from 'app/containers/Marketplace/MarketplaceRoute';
import OpenEntity from 'app/containers/CustomActions/OpenEntity';
import ActivityActions from 'app/containers/CustomActions/ActivityActions';
import PageNotFound from 'app/containers/ErrorPages/PageNotFound';
import ShowUser from 'app/components/molecules/MyProfileAvatar/ShowUser';
import UserPreferencesAppearances from 'app/components/molecules/MyProfileAvatar/UserPreferencesAppearances';
import UserNotFound from 'app/containers/ErrorPages/UserNotFound';
import UserRoute from 'app/containers/Admin/UserManagement/UserRoute';
import EntityGraphicsLibrary from 'app/containers/Entities/GraphicsLibrary/EntityGraphicsLibrary';
import FatalErrorPage from 'app/containers/ErrorPages/FatalErrorPage';
import Geocode from 'app/utils/maps/geocodeUtils';
import { LANDING_URL } from 'app/config/config';
import { online, offline } from 'store/actions/app/appActions';
import { baseRoutes } from 'app/config/routesConfig';
import RelationDefinitionsRoutes from 'app/containers/Entities/RelationDefinitions/RelationDefinitionsRoutes';
import PrintTemplatesRoutes from 'app/containers/Entities/PrintTemplates/PrintTemplatesRoutes';
import PrintRoutes from 'app/containers/Entities/Print/PrintRoutes';
import OrganisationSettings from 'app/containers/Admin/OrganisationSettings';
import License from 'app/containers/Admin/License/License';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'app/utils/lo/lo';
import integration from 'app/config/integrationConfig';
import Apikey from 'app/components/molecules/ApiKeys/ApiKey';
import LicenseAlertBar from 'app/components/molecules/LicenseAlertBar/LicenseAlertBar';
import PipelineMonitorRoute from 'app/containers/PipelineMonitor/PipelineMonitorRoute';
import TermsRoute from 'app/containers/Admin/Terms/TermsRoute';
import { loadSettingsPermissions } from 'store/actions/admin/usersActions';

import { usePageActive } from 'app/utils/hook/hooks';
import AdminRoutes from 'app/containers/Admin/Settings/AdminRoutes';
import affectliSso from 'app/auth/affectliSso';
import Notification from '../Notifications/Notification';
import { loadDataForOfflineCache } from 'offlineMode/singleTabDataLoader';

const AppRoute = (props: Object) => {
    const [key, setKey] = useState(1);
    const dispatch = useDispatch();

    const profile = useSelector(state => state.user.profile || {});
    const isOnline = useSelector(state => state.app.online);
    const isChatReady = useSelector(state => state.chat.socket.isReady);
    const isChatFailed = useSelector(state => state.chat.socket.isFailed);
    const isChatClosed = useSelector(state => state.chat.socket.isClosed);
    const userPreferences = useSelector(state => state.user.preferences);
    const isIntegationsLoading = useSelector(state => state.user.integrations.isLoading);
    const license = useSelector(state => state.app.license);
    const favoriteUrl = get(userPreferences, 'common.favoriteUrl');
    const isPageActive = usePageActive();
    const isInvalidSession = affectliSso.getIsInvalidSession();

    const profileId = profile?.id;

    useEffect(() => {
        const handleOnline = () => dispatch(online());
        const handleOffline = () => dispatch(offline());
        // Adding event listeners
        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);
        if(isOnline){
            dispatch(loadUserReferences());
            dispatch(loadUserPreferences());
            Geocode.updateUserLocation();
        }
        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, [dispatch, isOnline]);

    useEffect(() => {
        if (!profileId || !isOnline) {
            return;
        }
        dispatch(loadIntegrations());
        dispatch(initChatSocket());
        dispatch(loadSettingsPermissions());
        loadDataForOfflineCache();
    }, [dispatch, profileId, isOnline]);

    useEffect(() => {
        if ((isChatClosed || isChatFailed) && isOnline && isPageActive && !isInvalidSession) {
            dispatch(initChatSocket());
        }
    }, [dispatch, isChatClosed, isChatFailed, isOnline, isPageActive, isInvalidSession]);

    if (isChatFailed) {
        return <FatalErrorPage message="The IM service is not available." />;
    }

    if (isOnline && (!profile || !isChatReady || isIntegationsLoading)) {
        return <Loader absolute />;
    }

    // eslint-disable-next-line no-restricted-globals
    const badUrl = location.href.split('?')[0].includes('=');

    const { permissions, isAdmin } = profile || {};
    const permissionsSet = new Set(permissions || []);
    const canView =  isAdmin || permissionsSet.has('admin.license');

    return (
        <>
            {!location?.hash.includes('licence') &&<LicenseAlertBar expiryDate={license?.expiryDate} canClose floating canView={canView} />}
            <GlobalTemplate key={key}>
                <Switch>
                    {badUrl && <Redirect to={LANDING_URL} />}
                    {profile.invalid && <Route component={UserNotFound} />}
                    <Redirect exact from="/" to={favoriteUrl || LANDING_URL} />
                    <Route path="/terms" component={TermsRoute} />
                    <Route path="/admin" component={AdminRoutes} />
                    <Route path="/dashboards_new" component={DashboardsNewRoute} />
                    <Route path="/pipeline-monitor" component={PipelineMonitorRoute} />
                    <Route path="/abox" component={AboxRoute} />
                    <Route path="/background-jobs" component={AdminBackgroundJobRoute} />
                    <Route path="/background-job" component={AdminBackgroundJobRoute} />
                    <Route path="/entities" component={EntitiesRoute} />
                    <Route path="/related-entities" component={RelatedEntitiesRoute} />
                    <Route path="/teams" component={TeamsRoute} />
                    <Route path="/workspaces" component={WorkspacesRoute} />
                    <Route path="/user-management" component={UserRoute} />
                    <Route path="/classifications" component={ClassificationsRoute} />
                    <Route path="/entity-types" component={EntityTypesRoute} />
                    <Route path={baseRoutes['relationdefinition']} component={RelationDefinitionsRoutes} />
                    <Route path={baseRoutes['print-template']} component={PrintTemplatesRoutes} />
                    <Route path={'/print-reports'} render={props => <PrintTemplatesRoutes {...props} isPrintReports />} />
                    <Route path="/print" component={PrintRoutes} />
                    <Route path={baseRoutes['organisationSettings']} component={OrganisationSettings} />
                    <Route path={baseRoutes['license']} component={License} />
                    <Route path="/events" component={EventsRoute} />
                    <Route path="/designer" component={DesignerRoute} />
                    <Route path="/marketplace" component={MarketplaceRoute} />
                    <Route path="/about-me" component={ShowUser} />
                    <Route path="/user-preferences" component={UserPreferencesAppearances} />
                    <Route path="/api-key" component={Apikey} />
                    <Route path="/broadcasts" component={BroadcastsRoute} />
                    <Route path="/entity/:id/:tab" component={OpenEntity} />
                    <Route path="/entity/:id" component={OpenEntity} />
                    <Route path="/activity-actions/:type/:id" component={ActivityActions} />
                    <Route path="/analytics" component={AnalyticsRoute} />
                    <Route path={integration.baseRoute} component={IntegrationRoutes} />
                    <Route path="/graphics-library" component={EntityGraphicsLibrary} />
                    <Route path="/legacy" render={() => null} />
                    <Route path={baseRoutes.map} component={MapsRoute} />
                    <Route path="/super-analytics" component={SuperAnalyticsRoute} />
                    <Route path="/grafana" component={GrafanaRoute} />
                    <Route
                        path="/notification-test"
                        component={Notification}
                    />
                    <Route component={PageNotFound} />
                    <LegacyApp onReloadFullPage={() => setKey(key + 1)} />
                </Switch>
            </GlobalTemplate>
        </>
    );
};

export default AppRoute;
