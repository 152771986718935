/* @flow */
import React from 'react';

import { get, set, groupBy, sortBy } from 'app/utils/lo/lo';
import { getNum, isDefined } from 'app/utils/utils';
import styled from 'styled-components';


const StyledAdornment = styled.span`
    padding-top: 17px;
    padding-right: 5px;
    color: rgb(204, 204, 204);
    white-space: nowrap;
`;

const _numberifyOrderNo = fields => fields.map(field => set(field, 'settings.orderNo', getNum(field, 'settings.orderNo', 0)));

export const parseJson = (string, errorMessage) => {
    if (!string) {
        return null;
    }
    try {
        return JSON.parse(string);
    } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(errorMessage);
        return null;
    }
};

const applyDisabled = (field, isDisabled) => {
    if(!isDisabled) {
        return field;
    }

    let updatedField = {...field};
    if(field.children) {
        updatedField.children = field.children.map(f => applyDisabled(f, isDisabled));
    } else {
        updatedField = set(field, 'properties.disabled', isDisabled);
    }

    return updatedField;
};

export const applySettings = (data, options = {}) => {
    let field = { ...data };
    const { isDisabled } = options;
    const filters = [];
    const { settings, properties } = field;
    if(get(settings, 'class')) {
        const filterBy = [
            ...(get(field, 'properties.filterBy') || []),
            { field: 'classes.id', op: '=', value: settings['class'].id },
        ].filter(Boolean);
        filters.push(...filterBy);
    }
    if(properties?.filterBy && properties?.filterBy?.length){
        filters.push(...properties.filterBy);
    }
    if(get(settings,'filterExpression')) {
        const filterBy = parseJson(settings['filterExpression'], `the filter_expression for the field ${properties.name} in not valid.`);
        filterBy?.length && filters.push(...filterBy);
    }
    if(get(settings,'textExt')) {
        const InputProps = settings['textExtPosition'] === 'before'
            ? { startAdornment: <StyledAdornment>{settings['textExt']}</StyledAdornment> }
            : { endAdornment: <StyledAdornment>{settings['textExt']}</StyledAdornment> };

        field = set(field, 'properties.InputProps', InputProps);
    }
    field = set(field, 'properties.filterBy', filters);

    return isDisabled ? applyDisabled(field, isDisabled) : field;
};

/*
 * Given a classification's fields definition,
 * returns the Form's components definition to be used by the FormGenerator
 */
export const groupFields = (classFields: Array<Object>, props: Object = {}) => {
    const { isCollapsed, isVisible, editablePanel, isNoWrapped, classData, isDisabled } = props || {};
    const grouped = groupBy(classFields, 'settings.groupName');
    if(isNoWrapped) {
        return Object.entries(grouped).map(([header, children]) => {
            if(!header) {
                return children[0];
            }
            return sortBy(_numberifyOrderNo(children), 'settings.orderNo').map(children => applySettings(children));
        }).flat();
    }

    return Object.entries(grouped).map(([header, children]) => {
        if(!header) {
            return applyDisabled(children[0], isDisabled );
        }
        const panelProps = { 
            header: header ===  'undefined' ? 'Attributes' : header,
            expanded: isDefined(isCollapsed) ? !isCollapsed : true,
            isVisible, editablePanel
        };
        if(classData) {
            panelProps.header = classData.name;
            panelProps.iconName = classData.icon || classData.iconName;
            panelProps.iconType = classData.iconType || 'mdi';
        }
        return  {
            type: 'panel', properties: panelProps,
            children: sortBy(_numberifyOrderNo(children), 'settings.orderNo').map(children => applySettings(children, { isDisabled })),
        };
    });
};
