/* @flow */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { baseRoutes } from 'app/config/routesConfig';

import Link from 'app/components/atoms/Link/Link';

const PipelineLink = ((props: Object) => {
    const { id, children, path, noDecoration, pipelineMonitor, ...restProps } = props;
    const tab = path ? `/${path}` : '';
    return <Link to={`${pipelineMonitor ? `/pipeline-monitor` : baseRoutes['iot_pipeline']}/${id}${tab}`} noDecoration={noDecoration} {...restProps}>{children}</Link>;
});

PipelineLink.propTypes = {
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
    noDecoration: PropTypes.bool,
    children: PropTypes.node,
};

export default memo<Object>(PipelineLink);
