import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Box, Typography, Grid, MdiIcon, IconButton, InputBase, Divider } from '@mic3/platform-ui';

import Loader from 'app/components/atoms/Loader/Loader';
import { topicTailPipeline } from 'store/actions/designer/designerPipelineActions';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';

const Search = styled(Grid)`
    flex-grow: 1;
`;

const SearchToolbar = styled(Grid)`
    padding: 0 1rem;
    border-bottom: 2px solid ${({ theme }) => theme.material.colors.border.main};
`;

const Wrapper = styled(Grid)`
    margin: 16px 0px;
    padding: ${({ noPadding }) => !noPadding ? '16px' : '0'};
    ${({ disabled, theme, noBackground }) => `background-color: ${theme.material.colors.background.fields};`}
`;

const WordBreakTypographyStyled = styled(Typography)`
    word-break: break-word;
`;

const NoMessageTypography = styled(Typography)`
    padding: 8px 0;
`;

const DividerStyled = styled(Divider)`
    height: 21px !important;
`;

const PreBoxStyled = styled(Box)`
    white-space: pre-wrap;
`;

class TopicStatus extends React.PureComponent {
    
    constructor(props) {
        super(props);
        this.state = { filter: '' };
        this.loadTopicTail();
    }
    
    componentDidUpdate(prevProps) {
        const { value } = this.props;
        if(value !== prevProps.value) {
            this.loadTopicTail();
        }

    }

    @bind
    onSearch(event) {
        this.setState({ filter: event.target.value.trimLeft() });
    }

    @bind
    handleLive(event) {
        this.setState({ live: event.target.value }, this.handleLiveInterval);
    }    

    @bind
    loadTopicTail() {
        const { value, pipelineId, topicTailPipeline } = this.props;
        if(value.group !== 'topic') {
            return;
        }
        return topicTailPipeline(pipelineId, value.title);
    }

    @bind
    @memoize()
    getTopicTaile(pipelineStatus, value){
        const topic = (pipelineStatus?.topics || []).find(t => t.label === value.title); 
        return topic?.tail || [];
    }

    @bind
    @memoize()
    filterTail(tail, filter){
        if(!filter) return tail;
        return tail.filter(t => t?.value?.isBinary 
            ? JSON.stringify(t?.value.data).toLowerCase().includes(filter.toLowerCase()) 
            : t?.value?.dataAsUtf8.toLowerCase().includes(filter.toLowerCase())
        );
    }

    render() {
        const { tail, isLoading } = this.props;
        const { filter }= this.state;
        const filteredTail = this.filterTail(tail || [], filter);

        return (
            <>
                {isLoading && <Loader absolute backdrop />}
                <SearchToolbar container alignItems="center" wrap="nowrap">
                    <Search item>
                        <InputBase value={filter} onChange={this.onSearch} fullWidth margin="none" placeholder="Filter..." />
                    </Search>
                    <DividerStyled edge="end" orientation="vertical" />
                    <IconButton edge="end" onClick={this.loadTopicTail}>
                        <MdiIcon name="refresh" />
                    </IconButton>
                </SearchToolbar>            
                {filteredTail.length ? (
                    filteredTail.map((t, i) => (
                        <Wrapper key={i}>
                            <Grid container>
                                <Grid container >
                                    <Grid item>
                                        {t?.value?.isBinary ? (
                                            <WordBreakTypographyStyled>{t?.value.data.join(' ')}</WordBreakTypographyStyled>
                                        ) : (
                                            <PreBoxStyled>
                                                {t?.value?.dataAsUtf8}
                                            </PreBoxStyled>
                                        ) }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Wrapper>
                    ))
                ) : (
                    <NoMessageTypography>No messages</NoMessageTypography>
                )}
            </>
        );
    }
}

export default connect(
    (state, props) => ({
        isLoading: state.designer.pipelineTopicTail.isLoading,
        tail: state.designer.pipelineTopicTail.data,
    }),
    { topicTailPipeline },
    null,
    { forwardRef: true }
)(TopicStatus);