/* @flow */

import { gql } from '@apollo/client';

export const userReferenceFields = `
    id
    # type 
    name
    image
    username
`;

export const entityReferenceFields = `
    id
    type
    name
    image
`;

export const classReferenceFields = `
    id
    uri
    name
    abstract
    active
    color
    primary
`;

export const userPreferencesIDBQuery = gql`
    query userPreferencesIDBQuery {
        records: userReferences {
            ${userReferenceFields}
        }
    }
`;
export const entitiesListIDBQuery = gql`
    query entitiesListIDBQuery($type: String!) {
        records: entities(type: $type) {
            ${entityReferenceFields}
        }
    }
`;

export const classesQueryForIDB = gql`
    query classes {
        classes {
            ${classReferenceFields}
        }
    }
`;
