// @flow
import { graphql } from 'graphql/client';
import { gql } from '@apollo/client';

import OptionsBuilder from 'app/utils/api/OptionsBuilder';
import Immutable from 'app/utils/immutable/Immutable';
import HttpFetch from 'app/utils/http/HttpFetch';
import { loadData, mutateData } from 'app/utils/redux/action-utils';
import { toBpmnVariables } from 'app/utils/bpmn/bpmnEngineUtils';
import { addRoleToData, addRoleToList } from 'app/config/rolesConfig';
import { getStr } from 'app/utils/utils';
import { set } from 'app/utils/lo/lo';

import closeTaskMutation from 'graphql/abox/task/closeTaskMutation';
import processTasksQuery from 'graphql/abox/task/processTasksQuery';
import processSubTasksQuery from 'graphql/abox/task/processSubTasksQuery';
import loadTaskSidebarQuery from 'graphql/abox/task/loadTaskSidebarQuery';
import tasksCalendarQuery from 'graphql/abox/task/tasksCalendarQuery';
import tasksTimelineQuery from 'graphql/abox/task/tasksTimelineQuery';
import tasksQuery from 'graphql/abox/task/tasksQuery';
import tasksEntityQuery from 'graphql/abox/task/tasksEntityQuery';
import taskHistoryQuery from 'graphql/abox/task/taskHistoryQuery';
import taskGeotagButtonQuery from 'graphql/abox/task/taskGeotagButtonQuery';
import loadTaskTypeaheadQuery from 'graphql/abox/task/loadTaskTypeaheadQuery';
import updateTaskMutation from 'graphql/abox/task/updateTaskMutation';

export const LOAD_TASKS_STARTED = '@@affectli/task/LOAD_TASKS_STARTED';
export const LOAD_TASKS = '@@affectli/task/LOAD_TASKS';

export const LOAD_CALENDAR_TASKS_STARTED = '@@affectli/task/LOAD_CALENDAR_TASKS_STARTED';
export const LOAD_CALENDAR_TASKS = '@@affectli/task/LOAD_CALENDAR_TASKS';

export const LOAD_TIMELINE_TASKS_STARTED = '@@affectli/task/LOAD_TIMELINE_TASKS_STARTED';
export const LOAD_TIMELINE_TASKS = '@@affectli/task/LOAD_TIMELINE_TASKS';

export const CLOSE_TASK_STARTED = '@@affectli/task/CLOSE_TASK_STARTED';
export const CLOSE_TASK = '@@affectli/task/CLOSE_TASK';

export const LOAD_FORM_STARTED = '@@affectli/task/LOAD_FORM_STARTED';
export const LOAD_FORM = '@@affectli/task/LOAD_FORM';

export const TASK_SET_ASSIGNEE_STARTED = '@@affectli/task/TASK_SET_ASSIGNEE_STARTED';
export const TASK_SET_ASSIGNEE = '@@affectli/task/TASK_SET_ASSIGNEE';

export const TASK_SET_OWNER_STARTED = '@@affectli/task/TASK_SET_OWNER_STARTED';
export const TASK_SET_OWNER = '@@affectli/task/TASK_SET_OWNER';

export const LOAD_PROCESS_TASKS_STARTED = '@@affectli/task/LOAD_PROCESS_TASKS_STARTED';
export const LOAD_PROCESS_TASKS = '@@affectli/task/LOAD_PROCESS_TASKS';

export const LOAD_TASK_HISTORY_STARTED = '@@affectli/task/LOAD_TASK_HISTORY_STARTED';
export const LOAD_TASK_HISTORY = '@@affectli/task/LOAD_TASK_HISTORY';

export const LOAD_TASK_TYPEAHEAD_STARTED = '@@affectli/task/LOAD_TASK_TYPEAHEAD_STARTED';
export const LOAD_TASK_TYPEAHEAD = '@@affectli/task/LOAD_TASK_TYPEAHEAD';

export const SEND_TASK_MESSAGE_STARTED = '@@affectli/task/SEND_TASK_MESSAGE_STARTED';
export const SEND_TASK_MESSAGE = '@@affectli/task/SEND_TASK_MESSAGE';

export const LOAD_SUBPROCESS_TASKS_STARTED = '@@affectli/task/LOAD_SUBPROCESS_TASKS_STARTED';
export const LOAD_SUBPROCESS_TASKS = '@@affectli/task/LOAD_SUBPROCESS_TASKS';

export const LOAD_TASK_SIDEBAR_DETAILS_STARTED = '@@affectli/task/LOAD_TASK_SIDEBAR_DETAILS_STARTED';
export const LOAD_TASK_SIDEBAR_DETAILS = '@@affectli/task/LOAD_TASK_SIDEBAR_DETAILS';

export const LOAD_TASK_SIDEBAR_DETAILS_INTERNAL_STARTED = '@@affectli/task/LOAD_TASK_SIDEBAR_DETAILS_INTERNAL_STARTED';
export const LOAD_TASK_SIDEBAR_DETAILS_INTERNAL = '@@affectli/task/LOAD_TASK_SIDEBAR_DETAILS_INTERNAL';

export const LOAD_TASK_GEOTAG_STARTED = '@@affectli/process/LOAD_TASK_GEOTAG_STARTED';
export const LOAD_TASK_GEOTAG = '@@affectli/process/LOAD_TASK_GEOTAG';

export const closeTask = (id: string, variables: ?Object, outcome: ?string) =>
    mutateData(
        LOAD_TASK_SIDEBAR_DETAILS_STARTED,
        LOAD_TASK_SIDEBAR_DETAILS,
        closeTaskMutation,
        'The task has been closed.',
        addRoleToData
    )({ id, variables: toBpmnVariables(variables) });

export const loadTasksTypeahead = loadData(LOAD_TASK_TYPEAHEAD_STARTED, LOAD_TASK_TYPEAHEAD, loadTaskTypeaheadQuery);

/**
 * Loads the assigned tasks.
 */
export const loadTasks = (options: Object = {}) => {
    const variables = new OptionsBuilder(options).defaultStartStopIndexs(0, 30).build();
    return loadData(LOAD_TASKS_STARTED, LOAD_TASKS, tasksQuery, addRoleToList)({ ...variables, startIndex: options.startIndex });
};

export const loadTasksByEntity = (options: Object = {}) => {
    const variables = new OptionsBuilder(options).defaultStartStopIndexs(0, 30).build();
    return loadData(LOAD_TASKS_STARTED, LOAD_TASKS, tasksEntityQuery)({ ...variables, startIndex: options.startIndex });
};

export const loadTaskGeotagButton = (id: string) =>
    loadData(LOAD_TASK_GEOTAG_STARTED, LOAD_TASK_GEOTAG, taskGeotagButtonQuery)({ id });


/**
 * Loads the assigned tasks for the A-Box timeline.
 */
export const loadTimelineTasks = (options: Object = {}, start: Date, end: Date) => {
    let variables;

    // if start and end is not null use range of timeline as filter. Else, use startDate and dueDate as filter
    if (start && end) {
        variables = new OptionsBuilder(options)
            .defaultStartStopIndexs(0, 30)
            .filter({ or: [
                { field: 'primary.startDate', op: 'between', value: [start, end] },
                { field: 'primary.dueDate', op: 'between', value: [start, end] },
                [
                    { field: 'primary.startDate', op: '<', value: end },
                    { field: 'primary.dueDate', op: '>', value: start },
                ],
            ] })
            .build();
    } else {
        variables = new OptionsBuilder(options)
            .defaultStartStopIndexs(0, 30)
            .build();
    }

    return loadData(LOAD_TIMELINE_TASKS_STARTED, LOAD_TIMELINE_TASKS, tasksTimelineQuery)({ ...variables });
};


/**
* Loads the assigned tasks for the A-Box calendar.
 */
export const loadCalendarTasks = (userId: number, start: Date, end: Date, options: Object = {}) => {
    if (!userId || !start || !end) {
        throw new Error('userID, start and end are required.');
    }
    const variables = new OptionsBuilder(options)
        .defaultStartStopIndexs(0, 30)
        // filter date where:
        // (startDate between start and end and dueDate is null)
        // or (startDate is null and dueDate between start and end)
        // or (startDate < end and dueDate > start)
        .filter({ or: [
            { field: 'primary.startDate', op: 'between', value: [start, end] },
            { field: 'primary.dueDate', op: 'between', value: [start, end] },
            [
                { field: 'primary.startDate', op: '<', value: end },
                { field: 'primary.dueDate', op: '>', value: start },
            ],
        ] })
        .build();
    return loadData(LOAD_CALENDAR_TASKS_STARTED, LOAD_CALENDAR_TASKS, tasksCalendarQuery)({ ...variables });
};

/**
 * Loads the task details.
 */
export const loadTaskSidebarDetails = (id: string) => {
    if (!id) {
        throw new Error('The ID is required.');
    }
    return loadData(LOAD_TASK_SIDEBAR_DETAILS_STARTED, LOAD_TASK_SIDEBAR_DETAILS, loadTaskSidebarQuery, addRoleToData)({ id });
};

/**
 * Loads the task details.
 */
export const loadTaskSidebarDetailsInternal = (id: string) => {
    if (!id) {
        throw new Error('The ID is required.');
    }
    return loadData(LOAD_TASK_SIDEBAR_DETAILS_INTERNAL_STARTED, LOAD_TASK_SIDEBAR_DETAILS_INTERNAL, loadTaskSidebarQuery, addRoleToData)({ id });
};

/**
 * Updates the task details
 */
export const updateTask = (record: Object, internal) =>
    internal ? mutateData(LOAD_TASK_SIDEBAR_DETAILS_INTERNAL_STARTED, LOAD_TASK_SIDEBAR_DETAILS_INTERNAL, updateTaskMutation, 'Task updated successfully', addRoleToData)({ record })
        : mutateData(LOAD_TASK_SIDEBAR_DETAILS_STARTED, LOAD_TASK_SIDEBAR_DETAILS, updateTaskMutation, 'Task updated successfully', addRoleToData)({ record });

export const loadProcessTasks = (options: Object = {}, processId: string) => {
    const variables = new OptionsBuilder(options)
        .defaultStartStopIndexs(0, 30)
        .filter({ field: 'process.id', op: '=', value: processId })
        .build();
    return loadData(LOAD_PROCESS_TASKS_STARTED, LOAD_PROCESS_TASKS, processTasksQuery)(variables);
};

export const loadSubProcessTasks = (processId: string) => {
    const variables = {
        filterBy: [{ field: 'process.id', op: '=', value: processId }],
        processId // we need the processId in the meta of the action for the reducer
    };
    return loadData(LOAD_SUBPROCESS_TASKS_STARTED, LOAD_SUBPROCESS_TASKS, processSubTasksQuery)(variables);
};

export const sendTaskMessage = (url: string) => (dispatch: Function) => {
    dispatch({ type: SEND_TASK_MESSAGE_STARTED });
    return HttpFetch.putResource(url, {})
        .then((resp: Object): void => {
            dispatch({
                type: SEND_TASK_MESSAGE,
                payload: Immutable(resp),
                meta: Immutable({ successMessage: 'Successfully Sent' })
            });
        })
        .catch((error: Error): void => {
            dispatch( { type: SEND_TASK_MESSAGE, payload: Immutable(error), error: true } );
        });
};

export const loadTaskHistory = (id: string, options: Object) => async (dispatch, getState) => {
    const type = await loadTaskType(id);
    const newOptions = set(options, 'entityType', type || options.entityType);
    const variables = new OptionsBuilder(newOptions)
        .filter({ field: 'entity.id', op: '=', value: id })
        .filter({ field: 'entity.type', op: '=', value: type || options.entityType })
        .filter({ field: 'change.update.primary.instanceReference', op: 'is null' })
        .build();
    return loadData(LOAD_TASK_HISTORY_STARTED, LOAD_TASK_HISTORY, taskHistoryQuery)(variables)(dispatch, getState);
};

/**
 * Get the task type
 */
export const loadTaskType = async (id: string) => {
    const response = await graphql.query({
        query: gql`query getTaskType($id: ID!){ task(id: $id) { type } }`,
        variables: { id },
        fetchPolicy: 'no-cache'
    });
    if (!(response instanceof Error)) {
        return getStr(response, 'data.task.type');
    }
    return null;
};
