/* @flow */

import { gql } from '@apollo/client';
import loadTeamDetailCommonQuery from 'graphql/teams/loadTeamDetailCommonQuery';

export default gql`
    mutation teamAddUsersMutation($id: ID!, $users: [ID]!) {
        result: teamAddUsers(id: $id, users: $users) {
            ${loadTeamDetailCommonQuery}
        }
    }
`;
