/* @flow */

import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Link from 'app/components/atoms/Link/Link';

const BpmnProcessLink = ((props: Object) => {
    const { id, children, ...restProps } = props;
    return <Link to={`/designer/processes/${id}`} {...restProps}>{children}</Link>;
});

BpmnProcessLink.propTypes = {
    id: PropTypes.string.isRequired,
};

export default memo<Object>(BpmnProcessLink);
