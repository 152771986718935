/* @flow */

import { RELATIONS_VIEW_CHANGE } from 'store/actions/entities/relationshipsActions';

/**
 * Generic Entity reducer.
 *
 * @param state - the current Redux state
 * @param action - the dispatched action
 * @returns the new Redux state
 */
const reducer = (
    state = { tab: 'List' },
    action: Object
): Object => {
    const { type, payload } = action;
    switch (type) {
        case RELATIONS_VIEW_CHANGE:
            return { tab: payload };
        default:
            return state;
    }
};

export default reducer;
