import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box, Button, Typography } from '@mic3/platform-ui';

import { bind } from 'app/utils/decorators/decoratorUtils';

import ModalDialog from '../ModalDialog/ModalDialog';

const TypographyStyled = styled(Typography)`
    color: ${({ theme }) => theme.base.textColor};
`;

class PromptModal extends PureComponent {
    static propTypes = {
        title: PropTypes.string,
        subTitle: PropTypes.string,
        saveText: PropTypes.string,
        discardText: PropTypes.string,
        when: PropTypes.bool,
        onSave: PropTypes.func
    }

    static defaultProps = {
        title: 'Save changes',
        content: (
            <TypographyStyled>If you leave before saving your changes will be lost.<br />Do you want to save the changes?</TypographyStyled>
        ),
        saveText: 'Save',
        discardText: 'Discard changes'
    }

    block = null;
    navigation = null;
    
    state = {
        showPrompt: false,
        prevPath: ''
    }
    
    componentDidMount() {
        this.block = this.props.history.block(this.onBlock);
        this.setState({ prevPath: this.props.location.pathname });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.when !== this.props.when) {
            if (this.props.when) {
                if (!this.block) {
                    this.block = this.props.history.block(this.onBlock);
                }
            } else {
                this.block && this.block();
                this.block = null;
            }
        }
    }

    componentWillUnmount() {
        this.block && this.block();
    }
    
    @bind
    onBlock(tx) {
        const { history, when } = this.props;
        
        this.navigation = () => {
            history.replace(tx.pathname);
        };

        if (when) {
            this.setState({ showPrompt: true });
        } else {
            this.block();
            return true;
        }

        return false;
    };

    @bind
    handleDiscard() {
        this.block && this.block();
        this.navigation && this.navigation();
    }

    @bind
    handleSave() {       
        const { onSave } = this.props;
        this.block && this.block();
        this.setState({ showPrompt: false }, () => {
            onSave && onSave(null, this.navigation);
        });
    }

    render() {
        const { showPrompt } = this.state;
        const { title, content, saveText, discardText } = this.props;

        return (
            <>
                {showPrompt && (
                    <ModalDialog
                        title={title}
                        withoutClose
                        withoutFooterDivider
                        maxWidth="sm"
                        footer={
                            <Box mt={4} display="flex" justifyContent="flex-end">
                                <Button variant="text" onClick={this.handleDiscard}>{discardText}</Button>
                                <Button variant="text" onClick={this.handleSave}>{saveText}</Button>
                            </Box>
                        }
                    >
                        <Box mt={4}>
                            {content}
                        </Box>
                    </ModalDialog>
                )}
            </>
        );
    }
}

export default withRouter(PromptModal);
