/* @flow */

import { gql } from '@apollo/client';

export default gql`
    query workspaceClassificationsQuery($id: ID!) {
        result: workspace( id: $id) {
            id
            classes {
                id
                name
                uri
                active
                color
                formDefinition
            }
            attributes
        }
    }
`;
