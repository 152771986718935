/* @flow */
import React from 'react';

import EntitiesListColumns from 'app/containers/Entities/EntitiesList/EntitiesListColumns';

import {
    buildSidebar as buildSidebarAction,
    closeSidebar as closeSidebarAction
} from 'store/actions/sidebar/sidebarActions';

export const openTableColumnsSidebar = ({ title, ...props}) => async (dispatch, getState) => {
    return buildSidebarAction({
        title,
        content: <EntitiesListColumns {...props}/>,
        isOpen: true,
        handleHorizonalScoll: true,
    })(dispatch, getState);
};

export const closeTableColumnsSidebar = closeSidebarAction;
