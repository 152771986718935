/* @flow */

import memoize from 'memoize-one';

import defaultSettings from 'app/utils/designer/pipeline/settings/metadata/defaultSettings';
import annotationSettings from 'app/utils/designer/pipeline/settings/metadata/annotationSettings';
import externalSettings from 'app/utils/designer/pipeline/settings/metadata/externalSettings';
import connectorSettings from 'app/utils/designer/pipeline/settings/metadata/connectorSettings';
import connectorStatusSettings from 'app/utils/designer/pipeline/settings/metadata/connectorStatusSettings';
import connectorLogSettings from 'app/utils/designer/pipeline/settings/metadata/connectorLogSettings';
import smartModuleSettings from 'app/utils/designer/pipeline/settings/metadata/smartModuleSettings';
import topicSettings from 'app/utils/designer/pipeline/settings/metadata/topicSettings';
import topicStatusSettings from 'app/utils/designer/pipeline/settings/metadata/topicStatusSettings';

import { get } from 'app/utils/lo/lo';

const getPipelineElementSettings = (type: string) => {
    if (!type) return null;
    switch (type) {
        case 'module': return smartModuleSettings;
        case 'connector': return connectorSettings;
        case 'connectorStatus': return connectorStatusSettings;
        case 'connectorLog': return connectorLogSettings;
        case 'external': return externalSettings;
        case 'annotation': return annotationSettings;
        case 'topic': return topicSettings;
        case 'topicStatus': return topicStatusSettings;
        default: return defaultSettings;
    }
};

export const getElementSettingPanelComponents = (type: string, settingsValues: Object) => {
    const settings = getPipelineElementSettings(type);
    const panels = settings.panels(settingsValues);
    return panels;
};

const defaultsMap = {};
export const getProcessElementsDefaults = (type: string) => {
    if (!defaultsMap[type]) {
        const settings = getPipelineElementSettings(type);
        defaultsMap[type] = get(settings, 'defaults') || {};
    }
    return defaultsMap[type];
};

const processDefinitionMap = {};
export const getProcessDefinition = (type) => {
    if (!processDefinitionMap[type]) {
        processDefinitionMap[type] = { type, defaults: getProcessElementsDefaults(type) };
    }
    return processDefinitionMap[type];
};

export const getElementSettingValues = memoize((element, type, process) => {
    const settings = getPipelineElementSettings(type);
    const props = settings.panels().map(({ children }) => children.map(({ properties: { name }}) => name)).flat().filter(Boolean);
    const values = props.reduce((accum, name) => {
        if(!name.includes('.')) {
            accum[name] = element.getAttribute(name);
        }
        return accum;
    }, { type: element.type });

    return values;
});
