// @flow

import { gql } from '@apollo/client';
import { digitalTwinQueryFields } from 'graphql/entities/classifications/classificationDetailQuery';

export const detailsFields = `
    id
    type
    name
    primary
    description
    classes{
      id
      name
      uri
    }
    createdDate
    modifiedDate
    createdBy {
      id
      name
      username
      image
    }
    modifiedBy {
      id
      name
      username
      image
    }
    process {
      id
      type
      name
      variables
      processDefinitionVersion {
        id
        iconName
        iconColor
        primary(fields: ["definition"])
      }
    }
    formDefinitionVersion {
      definition
    }
    assignee {
      id
      name
      image
    }
    owner {
      id
      name
      image
    }
`;

export default gql`
    query taskQuery($id: ID!) {
        result: task(id: $id) {
            ${detailsFields}
            primaryClass {
              ${digitalTwinQueryFields}
            }
        }
    }
`;
