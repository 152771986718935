/* @flow */

import { label } from 'app/utils/designer/pipeline/settings/common/commonImportant';

const panels = (settingValues) => [
    {
        type: 'panel',
        properties: {
            header: settingValues.data.group,
            iconName: settingValues.data.iconName,
            iconType: settingValues.data.iconType,
            expanded: true,
            collapsible: false,
        },
        children: [
            {
                type: 'header',
                properties: {
                    variant: 'h6',
                    text: settingValues.data.title
                }
            },
        ],    
    },
    {
        type: 'panel',
        properties: {
            header: 'General Information',
            expanded: true,
        },
        children: [
            label(settingValues.pipelineMonitor),
        ],    
    },
    {
        type: 'panel',
        properties: {
            header: 'Configuration',
            expanded: true,
        },
        children: [          
            {
                type: 'number',
                properties: {
                    label: 'Partitions',
                    name: 'data.custom.partitions',
                    disabled: settingValues.pipelineMonitor,
                },
            },
            {
                type: 'number',
                properties: {
                    label: 'Replication Factor',
                    name: 'data.custom.replicationFactor',
                    disabled: settingValues.pipelineMonitor,
                },
            },
            {
                type: 'boolean',
                properties: {
                    label: 'Ignore Rack Assignment',
                    name: 'data.custom.ignoreRackAssignment',
                    disabled: settingValues.pipelineMonitor,
                },
            },
            {
                type: 'number',
                properties: {
                    label: 'Retention Secs',
                    name: 'data.custom.retentionSecs',
                    disabled: settingValues.pipelineMonitor,
                },
            },            
            {
                type: 'typeahead',
                properties: {
                    label: 'Compression Algorithm',
                    name: 'data.custom.compressionAlgorithm',
                    options: [
                        { value: 'any', label: 'Any' },
                        { value: 'none', label: 'None' },
                        { value: 'gzip', label: 'GZip' },
                        { value: 'snappy', label: 'Snappy' },
                        { value: 'lz4', label: 'LZ4' },
                        { value: 'zstd', label: 'ZSTD' },
                    ],
                    valueField: 'value',
                    disabled: settingValues.pipelineMonitor,
                },
            },      
            {
                type: 'number',
                properties: {
                    label: 'Segment Size',
                    name: 'data.custom.segmentSize',
                    disabled: settingValues.pipelineMonitor,
                },
            },                   
            {
                type: 'number',
                properties: {
                    label: 'Max Partition Size',
                    name: 'data.custom.maxPartitionSize',
                    disabled: settingValues.pipelineMonitor,
                },
            },                   
        ],    
    },
];

export default {
    defaults: {},
    panels: settingValues => panels(settingValues),
};
