/* @flow */

import Immutable from 'app/utils/immutable/Immutable';
import { get } from 'app/utils/lo/lo';
import { parseProperties } from 'app/utils/configuration/configuration';

import {
    TOGGLE_NAV,
    OPEN_NAV,
    CLOSE_NAV,
    OPEN_TOUR,
    CLOSE_TOUR,
    TOGGLE_NOTIFICATIONS,
    TOGGLE_CHAT,
    TOGGLE_SEARCH,
    SELECT_THEME,
    SET_HEADERS,
    SET_TAB_ACTIONS,
    LOAD_APP_ORGANISATION,
    LOAD_LICENSE,
    LOAD_TERMS, UPDATE_TERMS,
    LOAD_CONFIGURATIONS,
    SHOW_TOASTR,
    ERROR_ALERT_MESSAGE,
    TOGGLE_APP_HEADERS,
    ONLINE, OFFLINE,
    LOAD_AFFECTLI_CONFIGURATION_STARTED, LOAD_AFFECTLI_CONFIGURATION,
    LOAD_VERSION_STARTED, LOAD_VERSION,
    LOAD_ALL_PRIMARY_CLASSES_STARTED, 
    LOAD_SECURITY_GENERAL, LOAD_SECURITY_GENERAL_STARTED,
    UPDATE_SECURITY_GENERAL_STARTED, UPDATE_SECURITY_GENERAL,
    LOAD_ALL_PRIMARY_CLASSES,
    UPDATE_PRIMARY_CLASS_IN_CACHE,
    SSO_ADD_IDENTITY_PROVIDER_STARTED, SSO_ADD_IDENTITY_PROVIDER,
    LOAD_ORGANISATION_SETTINGS_STARTED,
    LOAD_ORGANISATION_SETTINGS,
    REFRESH_ORGANISATION_IMAGE_CACHE,
    SET_DOCUMENT_TITLE,
    LOAD_TERMS_STARTED
} from 'store/actions/app/appActions';
import { ACTIVE_SIDEBAR } from 'store/actions/sidebar/sidebarActions';
import { ACTIVE_FILTERBAR } from 'store/actions/filterbar/filterbarActions';
import { organisationImageUrl } from 'app/config/config';

const initialState = {
    online: window.navigator.onLine,
    isOrganisationLoadFailed: false,
    isOrganisationLoaded: false,
    isConfigLoading: false,
    isNavOpen: false,
    isNotificationsOpen: false,
    isChatOpen: false,
    isSearchOpen: false,
    isHeaderDisabled: false,
    isTourOpen:false,
    config: {},
    headers: {
        title: '',
        subTitle: '',
        headerInfo: [],
        pillText: '',
        actions: '',
        menuItems: '',
        color: {
            background: '',
        },
    },
    tabActions: {},
    theme: 'light',
    listAs: 'cards',
    organisation: {
        name: null,
        image: null,
    },
    license: {},
    terms: {},
    configurations: {},
    isConfigurationsLoaded: false,
    isConfigurationsLoadFailed: false,
    configurationsLoadError: null,
    isLicenseLoaded: false,
    isLicenseLoadFailed: false,
    licenseLoadError: null,
    toastrOptions: {},
    errorOptions: {},
    stepper: {
        hideOnSave: false,
    },
    version: null,
    activeSidebar: 'sidebar',
    allPrimaryClasses: {
        isLoading: false,
        records: []
    },
    ssoSettings: {},
    ssoSettingsLoading: false,
    organisationSettings: {
        isLoading: false,
        data: {},
    },
    organisationImage: organisationImageUrl,
    documentTitle:'Affectli'
};
/**
 * Reducer to handle app actions
 * @param state
 * @param action
 * @returns {*}
 */
export default (state: Object = initialState, action: Object) => {
    const { type, payload, error } = action;
    switch (type) {
        case ONLINE: return { ...state, online: true };
        case OFFLINE: return { ...state, online: false };
        case TOGGLE_NAV:
            return {
                ...state,
                isNavOpen: !state.isNavOpen,
            };
        case OPEN_NAV:
            return {
                ...state,
                isNavOpen: true,
            };
        case CLOSE_NAV:
            return {
                ...state,
                isNavOpen: false,
            };
        case OPEN_TOUR:
            return {
                ...state,
                isTourOpen: true,
            };
        case CLOSE_TOUR:
            return {
                ...state,
                isTourOpen: false,
            };
        case TOGGLE_NOTIFICATIONS:
            return {
                ...state,
                isNotificationsOpen: !state.isNotificationsOpen,
            };
        case TOGGLE_CHAT:
            return {
                ...state,
                isChatOpen: !state.isChatOpen,
            };
        case TOGGLE_SEARCH:
            return {
                ...state,
                isSearchOpen: !state.isSearchOpen,
            };
        case SELECT_THEME:
            return {
                ...state,
                theme: action.theme,
            };
        case SET_HEADERS:
            return Immutable({ ...state, headers: { ...state.headers, ...payload } });
        case SET_TAB_ACTIONS:
            return Immutable({ ...state, tabActions: payload });

        case LOAD_APP_ORGANISATION: {
            if (error || !payload) {
                return Immutable({
                    ...state,
                    isOrganisationLoadFailed: true,
                    organisationLoadError: payload
                });
            }
            const affectliSettings = {
                bpmn: {
                    taskAssigneeRequired: get(payload, 'attributes.affectli-settings/taskassigneerequired') || false,
                    taskClosedReasons: parseProperties(get(payload, 'attributes.affectli-settings/taskclosedreasons')),
                    processClosedReasons: parseProperties(get(payload, 'attributes.affectli-settings/processclosedreasons')),
                }
            };

            return Immutable({
                ...state,
                isOrganisationLoadFailed: false,
                organisationLoadError: null,
                isOrganisationLoaded: true,
                organisation: payload,
                affectliSettings
            });
        }
        case LOAD_LICENSE: {
            if (error || !payload) {
                return Immutable({
                    ...state,
                    isLicenseLoadFailed: true,
                    licenseLoadError: payload
                });
            }

            return Immutable({
                ...state,
                isLicenseLoadFailed: false,
                licenseLoadError: null,
                isLicenseLoaded: true,
                license: payload
            });
        }
        case LOAD_TERMS_STARTED: {
            return Immutable({ ...state, termsLoadError: null, isTermsLoadFailed: false, isTermsLoaded: false });
        }
        case LOAD_TERMS: {
            if (error || !payload) {
                return Immutable({
                    ...state,
                    isTermsLoadFailed: true,
                    termsLoadError: payload
                });
            }

            return Immutable({
                ...state,
                isTermsLoadFailed: false,
                termsLoadError: null,
                isTermsLoaded: true,
                terms: payload
            });
        }
        case UPDATE_TERMS: {
            if (error) {
                return Immutable({ ...state, terms: {} });
            }
            return Immutable({ ...state, terms: payload, isTermsLoaded: true, });
        }        
        case LOAD_CONFIGURATIONS: {
            if (error || !payload) {
                return Immutable({
                    ...state,
                    isConfigurationsLoadFailed: true,
                    configurationsLoadError: payload
                });
            }
            return Immutable({
                ...state,
                isConfigurationsLoadFailed: false,
                isConfigurationsLoaded: true,
                configurationsLoadError: null,
                configurations: payload
            });
        }
        case SHOW_TOASTR: {
            return Immutable({ ...state, toastrOptions: payload });
        }
        case ERROR_ALERT_MESSAGE: {
            return Immutable({ ...state, errorOptions: payload });
        }
        case TOGGLE_APP_HEADERS: {
            return Immutable({ ...state, isHeaderDisabled: payload });
        }
        case LOAD_AFFECTLI_CONFIGURATION_STARTED: {
            return Immutable({ ...state, isConfigLoading: true });
        }
        case LOAD_AFFECTLI_CONFIGURATION: {
            if (error) {
                return Immutable({ ...state, config: {}, isConfigLoading: false });
            }
            return Immutable({ ...state, config: payload, isConfigLoading: false });
        }
        case LOAD_VERSION_STARTED: {
            return Immutable({ ...state, isLoading: true });
        }
        case LOAD_VERSION: {
            if (error) {
                const version = payload?.version;
                if (version) {
                    return Immutable({ ...state, isLoading: false, version });
                }
                return Immutable({ ...state, isLoading: false });
            }
            return Immutable({ ...state, isLoading: false, version: (payload || {}).version });
        }
        case SSO_ADD_IDENTITY_PROVIDER_STARTED: {
            return Immutable({ ...state, ssoSettingsLoading: true });
        }
        case SSO_ADD_IDENTITY_PROVIDER: {
            if (error) {
                return Immutable({ ...state, ssoSettingsLoading: false });
            }
            return Immutable({ ...state, ssoSettingsLoading: false });
        }
        case UPDATE_SECURITY_GENERAL_STARTED: 
        case LOAD_SECURITY_GENERAL_STARTED: {
            return Immutable({ ...state, ssoSettingsLoading: true });
        }  
        case LOAD_SECURITY_GENERAL: 
        case UPDATE_SECURITY_GENERAL: {
            if (error) {
                return Immutable({ ...state, ssoSettingsLoading: false });
            }
            return Immutable({ ...state, ssoSettingsLoading: false, ssoSettings: payload || {} });
        }              
        case ACTIVE_SIDEBAR: {
            return Immutable({ ...state, activeSidebar: 'common' });
        }
        case ACTIVE_FILTERBAR: {
            return Immutable({ ...state, activeSidebar: 'filter' });
        }
        case LOAD_ALL_PRIMARY_CLASSES_STARTED: {
            const allPrimaryClasses = { ...state.allPrimaryClasses, isLoading: true };
            return Immutable({ ...state, allPrimaryClasses });
        }
        case LOAD_ALL_PRIMARY_CLASSES: {
            const allPrimaryClasses = { records: payload?.records || [], isLoading: false };
            return Immutable({ ...state, allPrimaryClasses });
        }
        case UPDATE_PRIMARY_CLASS_IN_CACHE: {
            if (!payload?.primary) return { ...state }; 
            let records = state.allPrimaryClasses.records;
            if (!records?.length) return { ...state };
            const alreadyExists = records.find(cls => cls?.id === payload?.id);
            if (!alreadyExists) {
                const allPrimaryClasses = { ...state.allPrimaryClasses, records: [ ...records, payload ] }; // Add newly added primary class to cache
                return Immutable({ ...state, allPrimaryClasses }); 
            }
            records = state.allPrimaryClasses.records.map((cl) => {
                if (cl?.id === payload?.id) {
                    return { ...payload };
                }
                return { ...cl };
            });
            const allPrimaryClasses = { ...state.allPrimaryClasses, records };
            return Immutable({ ...state, allPrimaryClasses });
        }
        case LOAD_ORGANISATION_SETTINGS_STARTED: {
            return Immutable({ ...state, organisationSettings: {...state.organisationSettings, isLoading: true } });
        }
        case LOAD_ORGANISATION_SETTINGS: {
            if (error || !payload) {
                return Immutable({ ...state, organisationSettings: {...state.organisationSettings, isLoading: false } });
            }
            return Immutable({ ...state, organisationSettings: { data: payload, isLoading: false } });

        }
        case REFRESH_ORGANISATION_IMAGE_CACHE: {
            const organisationImage = `${organisationImageUrl}?modifiedDate=${new Date().toISOString()}`; // Adding modified date to refresh the browser cache for organisation image
            return Immutable({ ...state, organisationImage });
        }
        case SET_DOCUMENT_TITLE:
            if (error || !payload) return state;
            return Immutable({ ...state, documentTitle: payload});
        default:
            return state;
    }
};
