/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';

import history from 'store/History';
import { loadTeams, createTeam } from 'store/actions/admin/teamsActions';
import { openTeamSidebar } from 'store/actions/entities/teamSidebarActions';

import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { get } from 'app/utils/lo/lo';
import { checkIfUserIsMember } from 'app/utils/chat/chatUtils';

import PageTemplate from 'app/components/templates/PageTemplate';
import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import EntitiesList from 'app/containers/Entities/EntitiesList/EntitiesList';
import DotMenu from 'app/components/molecules/DotMenu/DotMenu';
import { commonEntitiesListFilters } from 'app/utils/entity/entityUtils';
import { modulesAndPageTitles } from 'app/config/typesConfig';
import { setDocumentTitle } from 'store/actions/app/appActions';

const VIEW_ID = 'EntitiesTeamsListView';

const ContentAreaStyled = styled(ContentArea)`
    overflow: hidden;
`;

class TeamsList extends PureComponent<Object, Object> {
    static propTypes = {
        isLoading: PropTypes.bool,
        records: PropTypes.array,
        startIndex: PropTypes.number,
        totalRecords: PropTypes.number,
        loadTeams: PropTypes.func.isRequired
    };

    static defaultProps = {
        isLoading: false
    };

    virtualListRef = React.createRef();

    filterDefinitions = [
        {
            field: 'name',
            type: 'text',
            properties: {
                label: 'Name',
                name: 'name',
                opSelector: true
            }
        },
        {
            field: 'id',
            type: 'uuid',
            properties: {
                label: 'ID',
                name: 'id',
                opSelector: true
            },
            condition: '='
        },
        {
            field: 'active',
            type: 'typeahead',
            properties: {
                label: 'Status',
                name: 'active',
                options: [
                    { value: null, label: 'Any' },
                    { value: true, label: 'Active' },
                    { value: false, label: 'Inactive' }
                ]
            },
            sort: false,
            condition: '='
        },
        ...commonEntitiesListFilters
    ];
    defaultFilters = { active: true }

    columnDefinitions = [
        {
            text: 'Team',
            field: 'name',
        },
        {
            text: 'Team ID',
            field: 'id',
            onClickDisabled: true,
        },
        {
            text: 'Last Updated',
            field: 'modifiedDate',
        },
        {
            text: 'Modified By',
            field: 'modifiedBy'
        },
        {
            text: 'Created by',
            field: 'createdBy'
        },
        {
            text: 'Created Date',
            field: 'createdDate',
        },
        {
            text: 'Admins',
            field: 'owners',
            renderer:  ({ value }) => get(value, 'length'),
            sortable : false,
        },
        {
            text: 'Members',
            field: 'users',
            renderer:  ({ value }) => get(value, 'length'),
            sortable : false,
        }
    ];

    componentDidMount() {
        this.props.setDocumentTitle(modulesAndPageTitles.adminConsole.teams);
    }

    @bind
    onDotMenuClick(title) {
        const { openTeamSidebar } = this.props;
        const settings = { title, VIEW_ID };
        openTeamSidebar(settings);
    }

    @bind
    @memoize()
    dotMenu(data: Object) {
        return (
            <DotMenu
                key={13}
                items={this.actionsItems(data)}
            />
        );
    }

    @bind
    @memoize()
    actionsItems(data, userId) {
        const { openTeamSidebar } = this.props;
        const isTeamMember = checkIfUserIsMember(userId, data);
        
        return [
            isTeamMember && { name: 'A-Live', icon: 'messenger', iconType: 'af' },
            { name: 'History', icon: 'history', onItemClick: () =>  openTeamSidebar({title: 'History', id: data.id}) }
            
        ].filter(Boolean);
    }

    @bind
    @memoize()
    goToDetails(id) {
        history.push(`teams/${id}`);
        return true;
    }

    render() {
        const { records, isLoading, startIndex, totalRecords } = this.props;
        return (
            <PageTemplate title="Teams">
                <ContentAreaStyled>
                    <EntitiesList
                        id={VIEW_ID}
                        type="team"
                        headerTitle="Teams"
                        records={records}
                        totalRecords={totalRecords}
                        startIndex={startIndex}
                        isLoading={isLoading}
                        loadData={this.props.loadTeams}
                        filterDefinitions={this.filterDefinitions}
                        columnDefinitions={this.columnDefinitions}
                        defaultFilters={this.defaultFilters}
                        goToDetails={this.goToDetails}
                        moreActionItems={this.actionsItems}
                        downloadable
                        uploadable
                    />
                </ContentAreaStyled>
            </PageTemplate>
        );
    }
}

export default connect(
    (state, props) => ({
        isLoading: state.admin.teams.list.isLoading,
        startIndex: state.admin.teams.list.startIndex,
        records: state.admin.teams.list.records,
        totalRecords: state.admin.teams.list.count
    }),
    {
        loadTeams,
        createTeam,
        openTeamSidebar,
        setDocumentTitle
    }
)(TeamsList);
