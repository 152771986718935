/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import ClassificationsTab from 'app/containers/Common/ClassificationsTab/ClassificationsTab';
import AttachmentsView from 'app/containers/Common/Attachments/AttachmentsView';
import ProcessAboutTab from 'app/containers/Abox/ProcessView/ProcessAboutTab';
import ProcessTasksTab from 'app/containers/Abox/ProcessView/ProcessTasksTab';
import Relations from 'app/containers/Entities/Relationships/Relations';
import ProcessHistoryTab from '../Abox/ProcessView/ProcessHistoryTab';
import ChatSharing from 'app/components/organisms/Chat/ChatSharing';
import DotMenu from 'app/components/molecules/DotMenu/DotMenu';
import Loader from 'app/components/atoms/Loader/Loader';
import EntityDigitalTwin from 'app/containers/Entities/DigitalTwin/EntityDigitalTwin';
import Chat from 'app/components/organisms/Chat/Chat';
import history from 'store/History';
import PageNotAllowed from 'app/containers/ErrorPages/PageNotAllowed';

import { setActions, setSubTitle, closeSidebar, shrinkSidebar, expandSidebar, setTitle } from 'store/actions/sidebar/sidebarActions';
import { loadProcessSidebarDetails, loadProcessSidebarDetailsInternal } from 'store/actions/abox/processActions';

import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { getSubscriptionByRel } from 'app/utils/chat/chatUtils';
import { toUniqueUUID } from 'app/utils/string/string-utils';
import { mediaBreakpoints } from 'app/themes/breakpoints';
import { getPermissions } from 'app/config/rolesConfig';
import { isEmpty } from 'app/utils/utils';
import { buildDotMenu } from 'app/utils/entity/entityUtils';
import CancelProcessModal from 'app/containers/Abox/ProcessView/CancelProcessModal';
import { loadClassificationTaskAndProcess } from 'store/actions/classifications/classificationsActions';
import { loadPrintTemplatesListView } from 'store/actions/entities/printTemplateActions';


class ProcessSidebar extends PureComponent<Object, Object> {

    static propTypes = {
        id: PropTypes.string,
        title: PropTypes.string,
        isOpen: PropTypes.bool,
        reloadList: PropTypes.func,
        // from redux
        isLoading: PropTypes.bool,
        details: PropTypes.object,
    };

    state = {
        isCancelProcess: false,
        refreshKey: 0
    };

    constructor(props: Object) {
        super(props);
        this.initData();
        this.loadPrintTempaltes();
    };

    componentDidUpdate(prevProps) {
        const { isLoading, details, tasksAndProcesses } = this.props;
        if (details?.id && prevProps.details?.id !== details.id) {
            this.loadPrintTempaltes();
            this.setState(prevState => ({ refreshKey: prevState.refreshKey + 1 }));
        }
        if(prevProps.isLoading && !isLoading && !details) {
            return;
        }
        this.buildPrintTemplateMap(tasksAndProcesses);
        this.initData(prevProps);
    }

    @bind
    loadPrintTempaltes(){
        const { loadClassificationTaskAndProcess, loadPrintTemplatesListView, } = this.props;
        loadClassificationTaskAndProcess();
        loadPrintTemplatesListView();
    }

    @bind
    @memoize()
    buildPrintTemplateMap(tasksAndProcesses) {
        const templateClasses = tasksAndProcesses?.records?.filter(clss => clss.uri.includes('process'));
        this.printTemplatesMap = templateClasses?.reduce((accum, clss) => {
            accum[clss.uri] = clss.id;
            return accum;
        }, {});
    }
    
    @bind
    reloadDetails() {
        const { internal, id, loadProcessSidebarDetailsInternal, loadProcessSidebarDetails  } = this.props;
        internal ? loadProcessSidebarDetailsInternal(id) : loadProcessSidebarDetails(id);
    }

    @bind
    setActions(expanded, role, title) {
        const { printTemplateRecords } = this.props;
        this.props.setActions(this.dotMenu(expanded, role, this.props.details, title, printTemplateRecords, this.printTemplatesMap));
    }

    @bind
    setSubTitle(name) {
        this.props.setSubTitle(name);
    }

    initData(prevProps) {
        const { id: prevId } = prevProps || {};
        const { id, isOpen, isLoading, details, expanded, title } = this.props;
        const { role } = details || {};
        if (!isOpen) {
            return;
        }
        this.setActions(expanded, role, title);
        if (isLoading) {
            return;
        }
        const loaded = details || {};
        if (loaded.id === id) {
            if (title === 'Sharing') {
                const subTitle = `${details.name} #${toUniqueUUID(details.id)}`;
                this.props.setSubTitle(subTitle);
            } else {
                this.props.setSubTitle(details.name);
            }
        }
        if (id && prevId !== id) {
            this.reloadDetails();
        }
        if(details && !buildDotMenu({ details }).find(m => m.name === title)) {
            this.props.setTitle('About');
        }
    }

    @bind
    onDotMenuClick(title) {
        const { id } = this.props;
        if (title === 'Cancel process') {
            return this.toggleCancelProcessModal();
        }

        if (['Collapse', 'Expand'].includes(title)) {
            const { expanded, expandSidebar, shrinkSidebar } = this.props;
            const action = expanded ? shrinkSidebar : expandSidebar;
            action();
            return;
        }
        if (title === 'Go to details') {
            this.goToDetails();
        }
        if (title === 'Relations') {
            return history.push(`/abox/process/${id}/relationships`);
        }
        this.props.setTitle(title);
    }

    goToDetails() {
        const { windowWidth, id, shrinkSidebar, closeSidebar } = this.props;
        history.push(`/abox/process/${id}`);
        if (!windowWidth < mediaBreakpoints.sm) {
            shrinkSidebar();
        } else {
            closeSidebar();
        }
    }

    @bind
    @memoize()
    dotMenu(expanded: boolean, role: string, details: Object, title: string, printTemplateRecords, printTemplatesMap) {
        const pathname = window.location.hash.toLowerCase();
        const { primary: { closedDate } = {} } = details || {};
        const permissions = getPermissions(role && role);
        const { canEdit } = permissions || {};
        const menu = buildDotMenu({ entityType: 'process', details, title, pathname, expanded, printTemplateRecords, printTemplatesMap });
        const dividerIndex = menu.findIndex(({ name }) => name === 'divider');
        menu.splice(dividerIndex, 0, canEdit && !closedDate && { name: 'Cancel process', icon: 'window-close' }); // Insert Cancel process item before the divider
        return (
            <DotMenu
                key={13}
                onItemClick={this.onDotMenuClick}
                items={menu.filter(Boolean)}
            />
        );
    }

    @bind
    toggleCancelProcessModal(){
        this.setState(prevState => ({ isCancelProcess: !prevState.isCancelProcess }));
    }

    render() {
        const { isLoading, id, title, details, isOpen, reloadList, subscription, internal } = this.props;
        const { refreshKey } = this.state;
        const isLoaded = details;
        const role = details && details.role;
        const permissions = getPermissions(role);
        const { canEdit, canView } = permissions || {};
        const { rel, rid } = subscription || {};

        if (!isLoading && (isEmpty(details) || !canView)) {
            return <PageNotAllowed padded permissionError={true} title={`Process. (ID:${id})`} />;
        }
        const props = { id, type: details?.type, details, canEdit, key: refreshKey, internal, reloadList, reloadDetails: this.reloadDetails };
        return isOpen &&
          (
              <>
                  {isLoading && <Loader absolute backdrop/>}
                  {isLoaded && title === 'About' && <ProcessAboutTab isSidebar {...props} isLoading={isLoading} />}
                  {isLoaded && title === 'Tasks' && <ProcessTasksTab id={id} key={refreshKey} />}
                  {isLoaded && title === 'Relations' && <Relations key={refreshKey} fromType={details.type} fromId={id} canEdit={canEdit} />}
                  {isLoaded && title === 'Classes' && <ClassificationsTab name={details.name} {...props} />}
                  {
                      isLoaded && title === 'Attachments' &&
                        <AttachmentsView
                            id={id}
                            key={refreshKey}
                            type="process"
                            entityType={details.type}
                            details={details}
                            reloadDetails={this.initData}
                            permissions={permissions}
                        />
                  }
                  {isLoaded && title === 'History' && <ProcessHistoryTab id={id} type={details.type} key={refreshKey} />}
                  {isLoaded && title === 'A-Live' && <Chat id={id} type={details.type} details={details} key={refreshKey} />}
                  {isLoaded && title === 'Sharing' && (
                      <ChatSharing rid={rid} rel={rel} canEdit={canEdit} role={role} id={id} type={details.type} selectedSidebar={'share'} key={refreshKey} />
                  )}
                  {isLoaded && title === 'Digital Twin' && <EntityDigitalTwin type={details?.type} entity={details} inSidebar={true} key={refreshKey} />}
                  <CancelProcessModal details={details} isOpen={this.state.isCancelProcess} onClose={this.toggleCancelProcessModal} />
              </>
          );
    }
}

export default connect(
    (state: Object, ownProps: Object) => {
        const internalData = state.abox.process.sidebar.detailsInternal.data;
        const data = state.abox.process.sidebar.details.data;
        const details = ownProps.internal ? internalData : data;

        const { id, type } = details || {};
        const subscriptions = state.chat.subscriptions.data;
        const subscription = getSubscriptionByRel(subscriptions, { id, type });

        return {
            subscription,
            details,
            type,
            isLoading: ownProps.internal ? state.abox.process.sidebar.detailsInternal.isLoading : state.abox.process.sidebar.details.isLoading,
            isOpen: state.sidebar.isOpen,
            expanded: state.sidebar.expanded,
            title: state.sidebar.title,
            windowWidth: state.global.window.width,
            actions: state.sidebar.actions,
            printTemplateRecords: state.entities.printTemplates.list.records,
            tasksAndProcesses: state.classifications.classificationTasksAndProcesses.data
        };
    },
    { 
        loadProcessSidebarDetailsInternal, loadProcessSidebarDetails, closeSidebar, setActions,
        setSubTitle, shrinkSidebar, expandSidebar, setTitle, loadClassificationTaskAndProcess, loadPrintTemplatesListView,
    },
)(withRouter(ProcessSidebar));
