/* @flow */

import { gql } from '@apollo/client';

/*
tasks: lastMinuteActiveTasks {
  id
  name
  description
  assignee {
    login
  }
  owner {
    login
  }
}
*/

export default gql`
query notificationsQuery {
  broadcasts: userActiveBroadcasts {
    id
    message
  }
}
`;
