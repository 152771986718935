import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import uuidv1 from 'uuid/v1';
import { MdiIcon, Typography } from '@mic3/platform-ui';
import { shallowEquals } from 'app/utils/utils';
import { updateMapLayer } from 'store/actions/maps/situationalAwarenessActions';
import { LAYERS, __getMapLayers } from 'app/utils/maps/layer/layerUtils';
import Icon from 'app/components/atoms/Icon/Icon';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';

function getTimeRangePayload(time_range) {
    const isRelativeTime = time_range?.amount || time_range?.unit || time_range?.range;

    let startTime, endTime;
    if (isRelativeTime) {
        const { amount, unit, range } = time_range;
        startTime = moment();
        endTime = moment();

        if (range === 'add') {
            endTime = endTime.add(amount, unit);
        } else if (range === 'subtract') {
            startTime = startTime.subtract(amount, unit);
        }

        return {
            0: startTime.toISOString(),
            1: endTime.toISOString(),
            amount: time_range.amount,
            range: time_range.range,
            relative: time_range.relative,
            unit: time_range.unit
        };
    } else {
        startTime = moment(time_range[0]);
        endTime = moment(time_range[1]);

        return [startTime.toISOString(), endTime.toISOString()];
    }
}

function ReplayLayerAbout({
    id,
    map,
    mapId,
    layer,
    details,
    title,
    setActions,
    setStatus,
    setToDefault
}) {
    const dispatch = useDispatch();
    const formRef = useRef();
    const allMapLayers = useSelector(state => __getMapLayers(state.maps.situationalAwareness.map.data));
    const { classes, attributes, entitytype, ...rest } = details || {};

    const [formState, setFormState] = useState({
        layerForm: { ...rest, entitytype },
        initialForm: { ...rest, entitytype },
        formChanged: false
    });

    const onFormSubmit = useCallback(() => {
        formRef.current.isValidForm().then(async ({ data, errors }) => {
            if (!errors) {
                const { description, time_range, entitytype, relatedEntities } = data;
                const { type } = details || '';
                const foundLayer = allMapLayers.find(layer => layer.id === id);

                const timeRangePayload = getTimeRangePayload(time_range);


                const updatedLayerData = {
                    id,
                    name: foundLayer?.name,
                    type: LAYERS[type],
                    description,
                    time_range: timeRangePayload,
                    entitytype,
                    relatedEntities: relatedEntities || [],
                    active: true,
                    visible: true,
                    opacity: 100,
                };

                dispatch(updateMapLayer(updatedLayerData));
                setFormState(prev => ({
                    ...prev,
                    initialForm: { data },
                    formChanged: false
                }));
                setActions(null);
                setStatus(false);
            }
        });
    },[formRef, allMapLayers, details, id, dispatch, setActions, setStatus]);

    useEffect(() => {
        setActions(onFormSubmit);
    }, [onFormSubmit, setActions]);

    useEffect(() => {
        if (setToDefault) {
            setFormState(prev => ({
                ...prev,
                layerForm: prev.initialForm,
                formChanged: false
            }));
            setActions && setActions(null);
        }
    }, [setToDefault, setActions]);

    const TitleComponent = ({ headerName, title }) => (
        <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Icon name='play' type='mdi' />
                    <Typography style={{ paddingLeft: '10px' }}>{headerName}</Typography>
                </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <MdiIcon name="map" />
                    <Typography style={{ paddingLeft: '10px' }}>{title}</Typography>
                </div>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );

    const buildFormDefinition = (data) => {
        const { name } = data;
        return [
            {
                field: 'title',
                type: 'custom',
                properties: {
                    label: '',
                    cid: uuidv1(),
                    disabled: true,
                    Component: () => <TitleComponent headerName={title} title={name} />
                }
            },
            {
                field: 'panel',
                type: 'panel',
                properties: {
                    header: 'Description',
                    expanded: false,
                },
                children: [
                    {
                        field: 'description',
                        type: 'textarea',
                        properties: {
                            label: 'Description',
                            name: 'description',
                            disabled: data?.isOld
                        }
                    }
                ]
            },
            {
                field: 'replayInfo',
                type: 'panel',
                properties: {
                    header: 'Replay Information',
                    expanded: false,
                },
                children: [
                    {
                        type: 'dateTimeRange',
                        properties: {
                            label: 'Event Time',
                            name: 'time_range',
                        },
                        filter: false,
                        constraints: { required: true }
                    },
                    
                    {
                        type: 'relatedEntities',
                        properties: {
                            label: 'Related Entities',
                            name: 'relatedEntities',
                        },
                        condition: 'in',
                        sort: false,
                    }
                ],
            }
        ];
    };

    const handleChange = async (data, { name, value }) => {
        setFormState(prev => ({ ...prev, layerForm: data }));

        const { initialForm, formChanged } = formState;
        if (!formChanged && !shallowEquals(value, initialForm[name])) {
            setFormState(prev => ({ ...prev, formChanged: true }));
            await setStatus(true);
            setActions(onFormSubmit);
        } else if (formChanged && shallowEquals(value, initialForm[name])) {
            setFormState(prev => ({ ...prev, formChanged: false }));
            await setStatus(false);
            setActions(null);
        }
    };

    return (
        <FormGenerator
            data={formState.layerForm}
            onChange={handleChange}
            components={buildFormDefinition(formState.layerForm)}
            ref={formRef}
        />
    );
}

ReplayLayerAbout.propTypes = {
    id: PropTypes.string.isRequired,
    map: PropTypes.object.isRequired,
    mapId: PropTypes.string.isRequired,
    layer: PropTypes.object.isRequired,
    details: PropTypes.object,
    title: PropTypes.string,
    setActions: PropTypes.func,
    setStatus: PropTypes.func,
    setToDefault: PropTypes.bool,
};

export default ReplayLayerAbout;