// @flow

import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const useAppNavStyles = makeStyles((theme: Theme) => ({
    root: {
        zIndex: '1099 !important',
        gridArea: 'gNav',
        '& .MuiDrawer-paperAnchorDockedLeft': {
            borderRight: 'none',
            boxShadow: theme.colors.paperBoxShadow
        },
        '& .MuiDivider-root': {
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2)
        },
    },
    paper: {
        overflow: 'hidden',
        position: 'relative',
        maxHeight: 'calc(100% - 56px)',
        [theme.breakpoints.down('sm')]: {
            top: '56px'
        },
        background: theme.colors.background.side
    },
    navClosed: {
        width: '56px',
        overflow: 'hidden',
        transition: theme.transitions.create(['width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    isMobile: {
        width: 0,
        transition: theme.transitions.create(['width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    hideEmptyDrawer: {
        visibility: 'hidden'
    },
    sidebarExpanded: {
        display: 'none'
    },
    navOpen: {
        width: '260px',
        transition: theme.transitions.create(['width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    header: {
        '& .MuiButton-root': {
            marginBottom: theme.spacing(2),
            '&:last-child': {
                marginBottom: 0
            },
            '& .MuiIcon-root': {
                marginRight: theme.spacing(1)
            }
        },
        '& .MuiListItem-root': {
            paddingTop: '10px',
            paddingBottom: '10px',
            maxHeight: '48px'
        },
        '& .MuiListItemIcon-root': {
            '& i::before': {
                color: theme.colors.appNav.linkIconColor
            }
        },
        '& .MuiIcon-root': {
            color: theme.colors.appNav.linkIconColor
        }
    },
    navClosedHeader: {
        '& .MuiButton-root': {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            minWidth: 'auto',
            '& .MuiIcon-root': {
                marginRight: 0
            }
        }
    },
    list: {
        overflowX: 'hidden',
        flexGrow: '1',
        '&::-webkit-scrollbar': {
            display: 'none'
        },
        '& .MuiTypography-root': {
            fontWeight: '500',
            fontSize: '14px',
            color: theme.colors.text.secondary
        },
        '& .MuiListItem-root': {
            paddingTop: '10px',
            paddingBottom: '10px',
            maxHeight: '48px',
        },
        '& .MuiIcon-root': {
            color: theme.colors.appNav.linkIconColor
        },
        '& .MuiListItemIcon-root': {
            '& i::before': {
                color: theme.colors.appNav.linkIconColor
            }
        },
        '& .MuiListItem-button.active': {
            background: theme.colors.appNav.linkActiveBgColor,
            '& .MuiTypography-root': {
                color: theme.colors.appNav.linkActiveTextColor,
            },
            '& .MuiIcon-root, & .Icon::before': {
                color: theme.colors.appNav.linkActiveIconColor
            },
        },
        '& .MuiListItem-button': {
            '&:hover':{
                background: theme.colors.background.hover,
            },
            '& .MuiTypography-root': {
                color: theme.colors.appNav.linkIconColor,
            },
            '& .MuiIcon-root, & .Icon::before': {
                color: theme.colors.appNav.linkIconColor
            },
        },
    },
    subMenuPopper: {
        left: '56px !important',
    },
    subMenu: {
        width: '260px',
        borderRadius: '0 4px 4px 0',
        '& .MuiTypography-root': {
            fontSize: '14px',
            color: theme.colors.text.primary,
        },
        '& .MuiMenuItem-root:hover': {
            background: theme.colors.background.hover
        }
    },
    subMenuHeader: {
        padding: '0 0 0 16px !important',
        marginBottom: '8px',
        fontWeight: 500
    },
    subMenuChild: {
        fontSize: '14px'
    },
    createNewPopper: {
        '& .MuiListItem-root': {
            fontSize: '14px',
            lineHeight: '2rem'
        }
    },
    nestedList: {
        '& .MuiListItemText-inset': {
            paddingLeft: '36px',
        },
        '& .MuiTypography-root': {
            fontWeight: '400'
        }
    },
    footer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& img': {
            height: '24px',
            '&.icon': {
                height: '32px',
                position: 'relative',
                left: '-4px'
            }
        },
        '& .MuiListItemIcon-root': {
            minWidth: 'auto',
            margin: '0'
        }
    },
    footerClosed: {
        display: 'block'
    },
}));
