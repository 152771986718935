// @flow

import openGeocoder from 'node-open-geocoder';

export const searchPlaces = (placeName: string) => {
    return new Promise((resolve) => {
        if (placeName) {
            openGeocoder()
                .geocode(placeName)
                .end((err, res) => {
                    if (err) {
                        resolve({ result: null, error: err });
                    } else {
                        resolve({ result: res, error: null });
                    }
                });
        } else {
            resolve({ result: null, error: null });
        }
    });
};
