// @flow

import Overlay from 'ol/Overlay';
import Polygon from 'ol/geom/Polygon';
import LineString from 'ol/geom/LineString';
import { getArea, getLength } from 'ol/sphere';
import Feature from 'ol/Feature';
import { bind, debounce } from 'app/utils/decorators/decoratorUtils';
import * as olExtent from 'ol/extent';
import { toLonLat } from 'ol/proj';
import DrawTool from 'app/utils/maps/tools/mapDrawToolsUtils';
import React from 'react';
import ReactDOM from 'react-dom';
import MapSnackbar from 'app/containers/Maps/Components/MapSnackbar';


class MeasureTool {
    drawTool: Object;
    olMap: Object;
    measureOverlays: Array<Object> = [];
    measureTooltipElement: Object;
    measureTooltip: Object;
    isVisible: false;
    pointLength: String;
    isDrawingListenerEnded: Boolean = false;

    constructor(olMapVM: Object) {
        this.olMap = olMapVM;
    }

    @bind
    initTool(drawType: Object) {
        this.drawTool = new DrawTool(this.olMap);
        const layer = this.drawTool.initDrawLayer('Measure Layer', true);
        this.olMap.addLayer(layer);
        this.drawTool.initDrawTool(drawType, false);
        this.createMeasureTooltip();
    }

    /**
     * Creates a new measure tooltip
     */
    @bind
    createMeasureTooltip() {
        this.measureTooltipElement = document.createElement('div');
        this.measureTooltipElement.className = 'ol-tooltip ol-tooltip-measure';
        const measureTooltip = new Overlay({
            element: this.measureTooltipElement,
            offset: [0, -15],
            positioning: 'bottom-center'
        });
        this.measureOverlays.push(measureTooltip);
        this.olMap.getMap().addOverlay(measureTooltip);
        this.measureTooltip = measureTooltip;
    }

    @bind
    addInteractionOnMap(drawingType: number, callback: Function) {
        if (drawingType !== -1) {
            this.drawTool.changeDrawType(drawingType);
            this.drawTool.createHelpTooltip();
            const draw = this.drawTool.getDraw(false);
            this.olMap.getMap().addInteraction(draw);
            this.drawTool.addDrawStartListener(this.startListenerCallback);
            this.drawTool.addDrawEndListener(this.endListenerCallback);
        } else {
            this.removeInteractionFromMap();
        }
    }

    @bind
    removeInteractionFromMap() {
        this.drawTool.getDrawSource().clear();
        this.drawTool.removeAllOverlays();
        const draw = this.drawTool.getDraw(false);
        this.olMap.getMap().removeInteraction(draw);
        const map = this.olMap.getMap();
        this.measureOverlays.forEach(function(overlay) {
            map.removeOverlay(overlay);
        });
        const areaMeasures = document.getElementById('measure-label');
        if(areaMeasures) {
            areaMeasures.innerHTML = '';
        }
        this.measureOverlays = [];
        this.createMeasureTooltip();
    }

    /**
     * Format length output.
     * @param {LineString} line The line.
     * @return {string} The formatted length.
     */
    @bind
    formatLength(line: Object) {
        const length = getLength(line);
        let output;
        if (length > 100) {
            output = `${Math.round((length / 1000) * 100) / 100} km`;
        } else {
            output = `${Math.round(length * 100) / 100} m`;
        }
        return output;
    }

    /**
     * Format area output.
     * @param {Polygon} polygon The polygon.
     * @return {string} Formatted area.
     */
    @bind
    formatArea(polygon: Object) {
        const area = getArea(polygon);
        let output;
        if (area > 10000) {
            output = `${Math.round((area / 1000000) * 100) / 100} km sq`;
        } else {
            output = `${Math.round(area * 100) / 100} m sq`;
        }
        return output;
    }

    @bind
    @debounce(100)
    startListenerCallback(evt: any) {
        if(this.isDrawingListenerEnded) {
            this.isDrawingListenerEnded = false;
            this.drawTool.drawLayer.getSource().clear();
            const map = this.olMap.getMap();
            this.measureOverlays.forEach(function(overlay) {
                map.removeOverlay(overlay);
            });
            const areaMeasures = document.getElementById('measure-label');
            if(areaMeasures) {
                areaMeasures.innerHTML = '';
            }
            this.measureOverlays = [];
        }

        const geom = evt.target;

        let output;
        /** @type {import("../src/ol/coordinate.js").Coordinate|undefined} */
        let tooltipCoord = evt.coordinate;
        if (geom instanceof Polygon) {
            output = this.formatArea(geom);
            tooltipCoord = geom.getInteriorPoint().getCoordinates();
        } else if (geom instanceof LineString) {
            tooltipCoord = geom.getLastCoordinate();
            const length = this.formatLength(geom);
            const angle = this.formatBearing(geom);
            output = length;
            this.isVisible = true;
            this.pointLength = `Length: ${length} , Angle: ${angle}`;
        }

        if (this.measureOverlays.length === 0) this.createMeasureTooltip();
        this.measureTooltipElement.innerHTML = output;
        this.measureTooltip.setPosition(tooltipCoord);
    }

    @bind
    closeLanLongPopup() {
        document.getElementById('perimeterDiv').visible = false;
    }

    @bind
    @debounce()
    endListenerCallback(feature: Object) {
        this.measureTooltipElement.className = 'ol-tooltip ol-tooltip-static';
        this.measureTooltip.setOffset([0, -7]);
        const olMap = this.olMap;
        // unset tooltip so that a new one can be created
        this.measureTooltipElement = {};
        this.createMeasureTooltip();

        const geom = feature.getGeometry();
        const geom_coord = geom.getCoordinates();
        const diffx = Math.abs(geom_coord[0][0] - geom_coord[geom_coord.length -1][0]);
        const diffy = Math.abs(geom_coord[0][1] - geom_coord[geom_coord.length -1][1]);

        const mapResolution = olMap.getMap().getView().getResolution();
        const noOfPixels = 10;
        const drawTolerance = mapResolution * noOfPixels;

        if(diffx <= drawTolerance && diffy <= drawTolerance) {
            geom_coord[geom_coord.length - 1] = geom_coord[0];

            const polygon = new Polygon([geom_coord]);
            if (polygon.flatCoordinates.length > 0) {
                const feature = new Feature({
                    geometry: polygon
                });

                this.drawTool.drawSource.addFeature(feature);

                const area = this.formatArea(polygon);
                const perimeter = this.formatLength(polygon);
                const featureExtent = feature.getGeometry().getExtent();
                const getCenter = olExtent.getCenter(featureExtent);

                const content = document.createElement('div');
                content.id = 'measure-label';
                content.innerHTML = `${area} `;

                const overlay = new Overlay({
                    element: content,
                    position: getCenter,
                    autoPan: false
                });
                olMap.getMap().addOverlay(overlay);

                const divElem = document.createElement('div');
                ReactDOM.render(
                    <MapSnackbar
                        message={`Perimeter: ${perimeter} Area: ${area}`}
                    />,
                    divElem
                );
                document.body.appendChild(divElem);
                const staticLabel = document.getElementsByClassName('ol-tooltip-static');
                staticLabel[0].style.visibility = 'hidden';
            }
        }
        else {
            const divElem = document.createElement('div');
            divElem.setAttribute('id', 'perimeterDiv');

            ReactDOM.render(
                <MapSnackbar
                    message={`${this.pointLength}`}
                />,
                divElem
            );
            document.body.appendChild(divElem);
        }
        this.isDrawingListenerEnded = true;
    }

    /**calculate bearing of a line
     *
     * @param Line
     * @returns bearing
     */
    @bind
    calculateBearing(line: Object) {
        let bearing = 0;
        const coordinates = line.getCoordinates();
        if(coordinates.length >= 2) {
            const coordinate2 = toLonLat(coordinates[coordinates.length - 1]);
            const coordinate1 = toLonLat(coordinates[coordinates.length - 2]);
            const φ1 = this.toRadians(coordinate1[1]);
            const φ2 = this.toRadians(coordinate2[1]);
            const λ1 = this.toRadians(coordinate1[0]);
            const λ2 = this.toRadians(coordinate2[0]);
            //R = 6371e3;
            const y = Math.sin(λ2 - λ1) * Math.cos(φ2);
            const x = Math.cos(φ1) * Math.sin(φ2) - Math.sin(φ1) * Math.cos(φ2) * Math.cos(λ2 - λ1);
            bearing = this.toDegrees(Math.atan2(y, x));
            if (bearing < 0) {
                ///// correction for 3rd and 4th quadrant
                bearing = 360 + bearing;
            }
        }
        return bearing;
    }
    /**
     * format bearing of the line
     * @param line
     * @returns {bearing}
     */
    @bind
    formatBearing(geom: Object) {
        const bearing = this.calculateBearing(geom);
        return bearing;
    }

    /**
     * Degreee to Radian conversion
     * @param degrees
     * @returns {number}
     */
    @bind
    toRadians(degrees: number) {
        var pi = Math.PI;
        return degrees * (pi / 180);
    }

    /**
     * Radian to degree conversion
     * @param radians
     * @returns {number}
     */
    @bind
    toDegrees(radians: number) {
        var pi = Math.PI;
        return radians * (180 / pi);
    }


}

export default MeasureTool;
