import { gql } from '@apollo/client';

export const firebaseConfigQuery = gql`
    query affectli_settings {
        affectliSettings {
            messaging {
            apiKey
            authDomain
            projectId
            storageBucket
            messagingSenderId
            appId
            vapidKey
            }
        }
    }
`;

export const pushNotificationAddToken =  gql`
    mutation pushNotificationAddToken($token: String!) {
        pushNotificationAddToken(token: $token)
    }
`;

export const pushNotificationDelToken =  gql`
    mutation pushNotificationDelToken($token: String!) {
        pushNotificationDelToken(token: $token)
    }
`;