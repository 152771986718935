/* @flow */

import { gql } from '@apollo/client';

export default gql`
    query loadWorkspacesQuery($startIndex: Int, $stopIndex: Int, $filterBy: [JSON], $orderBy: [JSON], $excludeBy: [JSON], $countMax: Int) {
        count: count(dataType: "workspace", filterBy: $filterBy, max: $countMax)
        records: workspaces(startIndex: $startIndex, stopIndex: $stopIndex, filterBy: $filterBy, orderBy: $orderBy, excludeBy: $excludeBy) {
            id
            name
            active
            modifiedDate
            classes{
                id
                name
            }
            modifiedBy{
                id
                name
                image
            }
            createdDate
            createdBy{
                id
                name
                image
            }
            teams {
                role
                    team {
                        id
                        name
                        isSystem
                        users {
                            role
                            user {
                                id
                                name
                                username
                        }
                    }
                }
            }
            owners{
                id
            }
            entityResources{
                entity {
                    id
                    type
                }
            }
            user{
                id
            }
            
        }
    }
`;
