/* @flow */
import { gql } from '@apollo/client';
import { entityReferences } from 'graphql/entities/relationships/relationsQuery';

export default gql`
query boardColumnsQuery($entityId: ID!, $filterBy: [JSON], $orderBy: [JSON]) {
    records: entityRelations(entityType: "custom", entityId: $entityId, filterBy: $filterBy, orderBy: $orderBy) {
        relatedEntity {
          ${entityReferences}
        }
        isReverseRelation
        relation {
          id
          relationDefinition {
            description
          }
          attributes
        }
    }
}
`;
