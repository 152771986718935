
import prettifyXmlUtil from 'xml-formatter';
import moment from 'moment';
import { muiTheme } from 'app/themes/materialUi';
import { DOMParser } from '@xmldom/xmldom';


export const isXml = (str) => {
    const xmlDeclarationRegex = /^\s*<\?xml.*?\?>/i;
    const rootElementRegex = /^\s*<([a-zA-Z_][\w\-.]*).*?>.*<\/\1>\s*$/s;

    if (!xmlDeclarationRegex.test(str) && !rootElementRegex.test(str)) {
        return false;
    }

    try {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(str, 'text/xml');
        const parserErrors = xmlDoc.getElementsByTagName('parsererror');
        if (parserErrors.length > 0) {
            return false;
        }
        if (!xmlDoc.documentElement || xmlDoc.documentElement.nodeName === 'parsererror') {
            return false;
        }
        return true;
    } catch (e) {
        return false;
    }
};

  
export const prettifyXml = definition =>
    definition &&
prettifyXmlUtil(
    // removeSpaces(
    definition
    // )
        .replace(' xmlns:flowable=""', '')
        .replace(' xmlns:affectli=""', '')
        .replace(' xmlns:xsi=""', ''),
    {
        collapseContent: true, // Prevents newlines inside tags with text
        indentation: ' '.repeat(2), // Set indentation
        lineSeparator: '\n', // Set line separator
        whiteSpaceAtEndOfSelfclosingTag: true // Controls whitespace for self-closing tags
    }
);

export const removeSpaces = str => str && str.replace(/\n[ ]+/g, '');

export const getExecutionEvents = () => [
    'ACTIVITY_COMPENSATE',
    'ACTIVITY_COMPLETED',
    'ACTIVITY_ERROR_RECEIVED',
    'ACTIVITY_MESSAGE_CANCELLED',
    'ACTIVITY_MESSAGE_RECEIVED',
    'ACTIVITY_MESSAGE_WAITING',
    'ACTIVITY_SIGNALED',
    'ACTIVITY_SIGNAL_WAITING',
    'ACTIVITY_STARTED',
    'CUSTOM',
    'ENGINE_CLOSED',
    'ENGINE_CREATED',
    'ENTITY_ACTIVATED',
    'ENTITY_CREATED',
    'ENTITY_DELETED',
    'ENTITY_INITIALIZED',
    'ENTITY_SUSPENDED',
    'ENTITY_UPDATED',
    'HISTORIC_ACTIVITY_INSTANCE_CREATED',
    'HISTORIC_ACTIVITY_INSTANCE_ENDED',
    'HISTORIC_PROCESS_INSTANCE_CREATED',
    'HISTORIC_PROCESS_INSTANCE_ENDED',
    'JOB_CANCELED',
    'JOB_EXECUTION_FAILURE',
    'JOB_EXECUTION_SUCCESS',
    'JOB_RESCHEDULED',
    'JOB_RETRIES_DECREMENTED',
    'MEMBERSHIP_CREATED',
    'MEMBERSHIP_DELETED',
    'MEMBERSHIPS_DELETED',
    'MULTI_INSTANCE_ACTIVITY_CANCELLED',
    'MULTI_INSTANCE_ACTIVITY_COMPLETED',
    'MULTI_INSTANCE_ACTIVITY_COMPLETED_WITH_CONDITION',
    'MULTI_INSTANCE_ACTIVITY_STARTED',
    'PROCESS_CANCELLED',
    'PROCESS_COMPLETED',
    'PROCESS_COMPLETED_WITH_TERMINATE_END_EVENT',
    'PROCESS_COMPLETED_WITH_ERROR_END_EVENT',
    'PROCESS_CREATED',
    'PROCESS_STARTED',
    'SEQUENCEFLOW_TAKEN',
    'TASK_ASSIGNED',
    'TASK_COMPLETED',
    'TASK_CREATED',
    'TIMER_FIRED',
    'TIMER_SCHEDULED',
    'UNCAUGHT_BPMN_ERROR',
    'VARIABLE_CREATED',
    'VARIABLE_DELETED',
    'VARIABLE_UPDATED',
];

export const getEntityTypeOptions = () => [
    { label: 'Task', value: 'task' },
    { label: 'Attachment', value: 'attachment' },
    { label: 'Comment', value: 'comment' },
    { label: 'Execution', value: 'execution' },
    { label: 'Identity Link', value: 'identity-link' },
    { label: 'Job', value: 'job' },
    { label: 'Process Instance', value: 'process-instance' },
    { label: 'Process Definition', value: 'process-definition' },
];

function getSVGFromDOM() {
    const svgNode = document.querySelector('.djs-container > svg');

    const canvas = document.createElement('canvas');
    canvas.innerHTML = new XMLSerializer().serializeToString(svgNode);;

    // Removing overlay layer
    const nodeLayerOverlays = canvas.querySelector('g.layer-overlays');
    if(nodeLayerOverlays) {
        nodeLayerOverlays.parentNode.removeChild(nodeLayerOverlays);
    }

    const svgUpdated = canvas.getElementsByTagName('svg')[0];
    svgUpdated.setAttribute('style', `background:${muiTheme.colors.background.default};`);
    svgUpdated.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
    const strSvg = new XMLSerializer().serializeToString(svgUpdated);

    canvas.remove();

    return { svg: strSvg, svgNode };
}

export const geImgBase64 = (svg, svgNode) => {
    return new Promise((resolve) => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const img = new Image();
        img.src = 'data:image/svg+xml,' + encodeURIComponent(svg);
        img.onload = () => {
            canvas.width = svgNode.clientWidth;
            canvas.height = svgNode.clientHeight;
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            const pngUrl = canvas.toDataURL('image/png');
            resolve(pngUrl);
        };
    });
};

const downloadImage = async (svg, svgNode, name) => {
    const pngUrl = await geImgBase64(svg, svgNode);
    const downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = `${name} ${moment().format('DD.MM.YYYY HH.mm.ss')}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
};

export const exportAndDownloadImage = (name) => {
    const { svg, svgNode } = getSVGFromDOM();
    downloadImage(svg, svgNode, name);
};

export const exportSvg = () => {
    const { svg } = getSVGFromDOM();
    return svg;
};

export const printElement = (elemId, name) => {
 
    const iframe = document.createElement('iframe');
    iframe.style.position = 'absolute';
    iframe.style.width = '0';
    iframe.style.height = '0';
    iframe.style.border = 'none';
    iframe.style.visibility = 'hidden';
    document.body.appendChild(iframe);

    const svg = exportSvg();

    const doc = iframe.contentWindow.document;

    doc.open();
    doc.write('<html><head><title>' + name + ' ' + moment().format('DD-MM-YYYY HH:mm') + '</title>');
    doc.write('</head><body style="background: #101A2E;">');
    doc.write(svg);
    doc.write('</body></html>');
    doc.close();

    iframe.contentWindow.focus();
    iframe.contentWindow.print();
    setTimeout(() => {
        document.body.removeChild(iframe);
    }, 1000);

    return true;
};

export const parseDomElement = (elmnt, tag) => {
    const stringElmnt = removeSpaces(String(elmnt));
    const updatedElmnt = new DOMParser().parseFromString(stringElmnt);
    return updatedElmnt.getElementsByTagName(tag)[0];
};

export const getProcNameCustomConstraint = (data) => {
    const validSymbols = { format: { pattern: /somefaketest/, message: 'Name has to be just letters, numbers and spaces.' }};
    if (!/^[A-Za-z0-9\s]+$/g.test(data)) {
        return validSymbols;
    }
    return {};
}