import { muiTheme } from './materialUi';

import { darken, lighten/*, lighten, rgba, hsla*/ } from 'polished';

import { isDefined } from 'app/utils/utils';
import colorPalette from './palette';
import breakpoints from './breakpoints';
import shadow from './shadow';
import sizes from './sizes';

const theme = {
    material: muiTheme,
};
const { colors } = muiTheme;

theme.palette = colorPalette;
theme.sizes = sizes;
theme.media = breakpoints;
theme.shadow = shadow;

theme.color = {
    primary: colors.primary.main,
    secondary: colors.secondary.main,
    info: colors.info.main,
    success: colors.success.main,
    warning: colors.warning.main,
    alert: colors.priority.alert,
    danger: colors.danger,
    error: colors.error.main,
    white: colors.white,
    gray: colors.disabled.main,
    background: colors.background.default,
    paper: colors.background.paper,
};

theme.statusColors = {
    primary: colors.primary.main,
    secondary: colors.secondary.main,
    info: colors.info.main,
    success: colors.success.main,
    warning: colors.warning.main,
    alert: colors.priority.alert,
    danger: colors.danger,
    error: colors.error.main,
};

theme.priorityColors = {
    warning: colors.warning.main,
    alert: colors.priority.alert,
    danger: colors.danger,
    info: colors.info.main,
    success: colors.success.main,
    disabled: colors.disabled.main,
};

theme.priorityGradients = colors.priorityGradients;

theme.base = {
    textColor: colors.text.primary,
    textSecondaryColor: colors.text.secondary,
    linkColor: colors.link,
    background: colors.background.default,
    borderColor: colors.background.divider,
    borderRadius: '.3rem',
    active: {
        textColor: colors.text.primary,
        linkColor: colors.text.primary,
        background: colors.primary.main,
        borderColor: colors.disabled.color,
    },
    focus: {
        textColor: colors.text.primary,
        background: colors.background.default,
    },
    disabled: {
        textColor: colors.disabled.main,
        linkColor: colors.text.primary,
        background: darken(0.1, colors.background.default),
        borderColor: colors.disabled.main,
    },
};

theme.modal = {
    backdrop: colors.backdrop
};

theme.widget = {
    header: {
        textColor: 'white',
        iconColor: 'white',
        background: colors.background.navigation,
        backgroundAlt: '#3A3A3A'
    },
    textColor: theme.base.textColor,
    linkColor: theme.base.textColor,
    background: lighten(0.03, colors.background.default),
    iconColor: 'blue',
    borderColor: theme.base.borderColor,
    borderRadius: theme.base.borderRadius,
    hover: {
        textColor: theme.base.textColor,
        linkColor: theme.base.textColor,
        background: lighten(0.1, colors.background.default),
        iconColor: 'blue',
    }
};

theme.isLightColor = (color) => {
    if(!isDefined(color)) return false;
    const c = (color || '').substring(1); // strip #
    const rgb = parseInt(c, 16); // convert rrggbb to decimal
    const r = (rgb >> 16) & 0xff; // extract red
    const g = (rgb >> 8) & 0xff; // extract green
    const b = (rgb >> 0) & 0xff; // extract blue

    const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    return luma > 160;
};

theme.input = {
    textColor: theme.base.textColor,
    background: darken(0.1, theme.base.background),
    placeholder: '#777',
    borderColor: theme.base.borderColor,
    borderRadius: '.3rem',
    shadow: 'none',
    height: '40px',
    hover: {
        textColor: theme.base.textColor,
        background: theme.base.background,
        borderColor: darken(0.01, theme.base.borderColor),
    },
    focus: {
        textColor: theme.base.textColor,
        background: darken(0.1, theme.base.background),
        borderColor: theme.base.borderColor,
    },
    active: {
        textColor: theme.base.textColor,
        background: darken(0.1, theme.base.background),
        borderColor: theme.base.borderColor,
    },
    disabled: {
        textColor: theme.base.disabled.textColor,
        background: theme.base.disabled.background,
        borderColor: theme.base.disabled.borderColor,
    },
    error: {
        textColor: theme.base.textColor,
        background: darken(0.1, theme.base.background),
        borderColor: theme.base.borderColor,
    }
};

theme.icon = {
    color: theme.base.textColor
};

theme.summary = {
    nameColor: theme.base.textColor,
    valueColor: colors.primary.main
};

theme.header = {
    background: colors.background.navigation,
    height: '56px',
    textColor: colors.text.primary,
    show: true,
    borderAvatar: colors.appHeader.borderAvatar
};

theme.navigation = {
    background: colors.background.navigation,
    width: '260px',
    content: {
        text: colors.text.primary
    }
};

theme.navigation.apps = {
    width: '56px',
    background: darken(0.02, theme.navigation.background)
};

theme.navigation.menu = {
    width: '279px'
};

theme.drawer = {
    background: theme.navigation.background,
    textColor: colors.white,
    content: {
        background: colors.background.paper,
        borderBottomColor: 'rgba(255, 255, 255, 0.24)',
    },
    header: {
        background: '#343A45',
    }
};

theme.bar = {
    height: '50px'
};

theme.progressBar = {
    color: '#cccccc',
};

theme.menu = {
    hover: {
        background: colors.background.hover,
    }
};

theme.layout = {
    navigation: {
        width: '300px',
        background: colors.background.default
    }
};

theme.iconSize = {
    xs: '12px',
    sm: '16px',
    md: '24px',
    lg: '32px',
    xl: '48px'
};

export default theme;
