/* @flow */

import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, CircularProgress } from '@mic3/platform-ui';

import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { loadScript } from 'store/actions/designer/designerScriptActions';
import { loadEntity, updateEntity } from 'store/actions/entities/entitiesActions';
import { typeTitlesSingle } from 'app/config/typesConfig';
import { getOnlyUpdatedData } from 'app/utils/app/appUtils';

import Container from 'app/components/atoms/Container/Container';
import HeaderBar from 'app/components/molecules/HeaderBar/HeaderBar';
import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';

class ScriptAbout extends PureComponent<Object, Object> {
    static propTypes: Object = {
        id: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        details: PropTypes.object.isRequired,
        updateEntity: PropTypes.func.isRequired,
        loadEntity: PropTypes.func.isRequired,
        isLoading: PropTypes.bool,
    };

    formRef = React.createRef();

    @bind
    @memoize()
    fieldDefinitions(canEdit: boolean) {
        return [
            {
                type: 'panel',
                properties: {
                    header: 'Basic',
                    expanded: true
                },
                children: [
                    {
                        type: 'text',
                        properties: {
                            label: 'ID',
                            name: 'id',
                            disabled: true
                        }
                    },
                    {
                        type: 'dateTime',
                        properties: {
                            label: 'Last Modified',
                            name: 'modifiedDate',
                            disabled: true
                        }
                    },
                    {
                        type: 'dateTime',
                        properties: {
                            label: 'Created On',
                            name: 'createdDate',
                            disabled: true
                        }
                    }
                ]
            },
            {
                type: 'panel',
                properties: {
                    header: `${typeTitlesSingle[this.props.type]} Details`,
                    expanded: true
                },
                children: [
                    {
                        type: 'text',
                        properties: {
                            label: 'Name',
                            name: 'name',
                            disabled: !canEdit
                        },
                        constraints: {
                            required: true,
                            maxLength: 60,
                            minLength: 3
                        }
                    },
                    {
                        type: 'textarea',
                        properties: {
                            label: 'Description',
                            name: 'description',
                            disabled: !canEdit
                        }
                    },
                ]
            },
            {
                type: 'panel',
                properties: {
                    header: `Form designer`,
                    expanded: true
                },
                children: [
                    {
                        type: 'formDefinitionTypeahead',
                        properties: {
                            label: 'Form definition',
                            name: 'primary.formDefinition',
                            valueField: 'id',
                            disabled: !canEdit
                        },
                    },
                ]
            },
        ];
    }

    @bind
    onFormSubmit(event: Event) {
        event.preventDefault();
        this.formRef.current.isValidForm().then(({ data, errors }) => {
            if (!errors) {
                const { id, name, type } = data;
                const updated = getOnlyUpdatedData(this.props.details, data);
                this.props.updateEntity({ id, name: name.trim(), type, ...updated }).then((resp) => {
                    if(!(resp instanceof Error)) {
                        this.props.loadScript(id);
                    }
                });
            }
        });
    }

    @bind
    renderSaveButton() {
        const { isLoading, canEdit } = this.props;
        if (!canEdit) {
            return null;
        }
        return isLoading
            ? <CircularProgress key={113} size={24} color="primary" />
            : <Button key={113} onClick={this.onFormSubmit} color="primary" form="form" type="submit">Save</Button>;
    }

    render(): Object {
        const { breadcrumbLine, details, canEdit, isSidebar } = this.props;

        return (
            <Fragment>
                <HeaderBar left={breadcrumbLine} right={[
                    this.renderSaveButton(),
                    ...(this.props.sidebarActions || []),
                ]} />
                <ContentArea withHeader isSidebar={isSidebar}>
                    <Container width="1024">
                        <FormGenerator
                            components={this.fieldDefinitions(canEdit)}
                            ref={this.formRef}
                            data={details}
                        />
                    </Container>
                </ContentArea>
            </Fragment>
        );
    }
}

const mapStateToProps = (state: Object) => ({
    isLoading: state.entities.sidebar.details.isLoading,
});

export default connect(
    mapStateToProps,
    { updateEntity, loadEntity, loadScript }
)(ScriptAbout);
