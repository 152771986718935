// @flow

import React, { memo } from 'react';
import styled from 'styled-components';
import { Typography, Box } from '@mic3/platform-ui';

const CardInfoBase = styled(Box)`
    ${({ display }) => display === 'inline' ? 'margin-right: 16px' : 'margin-bottom: 16px'};
    font-size: 12px;
    position: relative;
`;

const CardInfoLabel = styled(Typography)`
    && {
        font-size: 12px;
        color: ${({ theme }) => theme.material.colors.text.secondary};
        & span {
            font-weight: 600;
            color: ${({ theme }) => theme.material.colors.text.primary};
        }
    }
`;

const Divider = styled.span`
    position: relative;
    top: 1px;
    right: -8px;
    display: inline-block;
    width: 1px;
    height: 10px;
    background: ${({ theme }) => theme.material.colors.background.divider};
`;

const CardInfo = ({
    children,
    display,
    noDivider
}: Object) => {
    return (
        <CardInfoBase component="div" display={display}>
            <CardInfoLabel variant="body1" display="inline">{children}</CardInfoLabel>
            {(display === 'inline' && !noDivider) && <Divider />}
        </CardInfoBase>
    );
};

CardInfo.defaultProps = {
    display: 'inline',
};

export default memo<Props>(CardInfo);
