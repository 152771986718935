/* @flow */

import { prettifyXml } from 'app/utils/designer/process/processDesignerUtils';
import { loadData, mutateData } from 'app/utils/redux/action-utils';
import { get, set } from 'app/utils/lo/lo';
import OptionsBuilder from 'app/utils/api/OptionsBuilder';
import processDefinitionQuery from 'graphql/designer/processDefinitionQuery';
import designerProcessListQuery from 'graphql/designer/designerProcessListQuery';
import createProcessDefinitionMutation from 'graphql/designer/createProcessDefinitionMutation';
import updateProcessDefinitionMutation from 'graphql/designer/updateProcessDefinitionMutation';
import cloneProcessDefinitionMutation from 'graphql/designer/cloneProcessDefinitionMutation';
import publishProcessDefinitionMutation from 'graphql/designer/publishProcessDefinitionMutation';
import { addRoleToList } from 'app/config/rolesConfig';
// import deleteProcessDefinitionMutation from 'graphql/designer/deleteProcessDefinitionMutation';


export const LOAD_PROCESSES_DEFINITIONS_STARTED: string = '@@affectli/designer/LOAD_PROCESSES_DEFINITIONS_STARTED';
export const LOAD_PROCESSES_DEFINITIONS: string = '@@affectli/designer/LOAD_PROCESSES_DEFINITIONS';

export const LOAD_PROCESS_DEFINITION_STARTED: string = '@@affectli/designer/LOAD_PROCESS_DEFINITION_STARTED';
export const LOAD_PROCESS_DEFINITION: string = '@@affectli/designer/LOAD_PROCESS_DEFINITION';

export const CREATE_PROCESS_DEFINITION_STARTED: string = '@@affectli/designer/CREATE_PROCESS_DEFINITION_STARTED';
export const CREATE_PROCESS_DEFINITION: string = '@@affectli/designer/CREATE_PROCESS_DEFINITION';

export const UPDATE_PROCESS_DEFINITION_STARTED: string = '@@affectli/designer/UPDATE_PROCESS_DEFINITION_STARTED';
export const UPDATE_PROCESS_DEFINITION: string = '@@affectli/designer/UPDATE_PROCESS_DEFINITION';

export const CLONE_PROCESS_DEFINITION_STARTED: string = '@@affectli/designer/CLONE_PROCESS_DEFINITION_STARTED';
export const CLONE_PROCESS_DEFINITION: string = '@@affectli/designer/CLONE_PROCESS_DEFINITION';
//
// export const DELETE_PROCESS_DEFINITION_STARTED: string = '@@affectli/designer/DELETE_PROCESS_DEFINITION_STARTED';
// export const DELETE_PROCESS_DEFINITION: string = '@@affectli/designer/DELETE_PROCESS_DEFINITION';

/**
 * Loads the forms' definitions.
 */
export const loadDesignerProcesses = (options: Object = {}) => {
    const variables = new OptionsBuilder(options)
        .defaultStartStopIndexs(0, 30)
        .build();
    return loadData(
        LOAD_PROCESSES_DEFINITIONS_STARTED,
        LOAD_PROCESSES_DEFINITIONS,
        designerProcessListQuery,
        addRoleToList
    )({ ...variables, startIndex: options.startIndex });
};

/**
 * Loads the form definition with the given id.
 */
export const loadProcessDefinition = (id: string) =>
    loadData(LOAD_PROCESS_DEFINITION_STARTED, LOAD_PROCESS_DEFINITION, processDefinitionQuery)({ id });

/**
 * Creates a new the form definition.
 */
export const createProcessDefinition = (record: Object) =>
    mutateData(
        CREATE_PROCESS_DEFINITION_STARTED,
        CREATE_PROCESS_DEFINITION,
        createProcessDefinitionMutation,
        'Process Diagram created successfully.'
    )({ record });

/**
     * Updates a form definition.
     */
export const updateProcessDefinition = (record: Object, disableMessage) => (dispatch, getState) => {
    const normalized = get(record, 'primary.definition')
        ? set(record, 'primary.definition', prettifyXml(record.primary.definition))
        : record;
    return mutateData(
        UPDATE_PROCESS_DEFINITION_STARTED,
        UPDATE_PROCESS_DEFINITION,
        updateProcessDefinitionMutation,
        !disableMessage ? 'Process updated successfully.' : null
    )({ record: normalized })(dispatch, getState);
};


export const cloneProcessDefinition = (id: string, name: string, description: string) =>
    mutateData(
        CLONE_PROCESS_DEFINITION_STARTED,
        CLONE_PROCESS_DEFINITION,
        cloneProcessDefinitionMutation,
        'Process duplicated successfully.'
    )({ id, name, description });

export const publishProcessDefinition = (id: string) =>
    mutateData(
        UPDATE_PROCESS_DEFINITION_STARTED,
        UPDATE_PROCESS_DEFINITION,
        publishProcessDefinitionMutation,
        'Process published successfully.'
    )({ id });


// export const deleteProcessDefinition = (id: string, record: Object) =>
//     mutateData(
//         DELETE_PROCESS_DEFINITION_STARTED,
//         DELETE_PROCESS_DEFINITION,
//         deleteProcessDefinitionMutation,
//         'Process deleted successfully.'
//     )({ id, record });
