import React, { PureComponent } from 'react';
import { Button, Box, MdiIcon, Tooltip, IconButton } from '@mic3/platform-ui';
import styled from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import { bind } from 'app/utils/decorators/decoratorUtils';
import { loadClassesListView } from 'store/actions/classifications/classificationsActions';
import { setActions, closeSidebar } from 'store/actions/sidebar/sidebarActions';
import history from 'store/History';
import { createEntity } from 'store/actions/entities/entitiesActions';
import { MAP_BASELAYER, BASELAYERS_OPTIONS, MAP_LAYERS } from 'app/utils/maps/layer/layerUtils';
import { typeMap } from 'app/config/typesConfig';


const StyledBox = styled(Box)`
    position: absolute;
    left: 0px;
    right: 0px;
    top: 1px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
`;

class CreateMapModal extends PureComponent<Object, Object> {
    static propTypes: Object = {
        isLoading: PropTypes.bool,
        records: PropTypes.array,
        loadClassesListView: PropTypes.func.isRequired,
        createEntity: PropTypes.func.isRequired
    };

    formRef = React.createRef();
    options = {
        startIndex: 0,
        stopIndex: 1,
        filterBy: [{ field: 'uri', op: '=', value: typeMap['map'] }]
    };

    formFields = [
        {
            field: 'name',
            type: 'text',
            properties: { label: 'Name', name: 'name' },
            constraints: { required: true, minLength: 3, maxLength: 60 }
        },
        {
            field: 'baselayer',
            type: 'typeahead',
            properties: {
                label: 'Base Layer',
                name: MAP_BASELAYER,
                options: BASELAYERS_OPTIONS
            },
            constraints: { required: true }
        }
    ];

    constructor(props: Object) {
        super(props);
        this.state = {
            isOpen: false,
            isSaveClicked: false
        };
        props.loadClassesListView({ isShort: true, required: true, options: this.options, aditionalFields: 'formDefinition' });
    }

    @bind
    handleOpen() {
        this.setState(prevProps => ({ isOpen: true }));
    }

    @bind
    handleClose() {
        this.setState(prevProps => ({ isOpen: false }));
    }

    @bind
    onFormSubmit(e: Event) {
        e.preventDefault();
        this.setState({ isSaveClicked: true });
        this.formRef.current.isValidForm().then(({ data, errors }) => {
            if (!errors) {
                const { createEntity } = this.props;
                const { name } = data;
                const primary = { 
                    [MAP_BASELAYER]: data?.[MAP_BASELAYER],
                    [MAP_LAYERS]: []
                }; 
                return createEntity({
                    name: name.trim(),
                    type: typeMap['map'],
                    primary
                }).then((result) => {
                    const { id } = result || {};
                    this.setState({ isSaveClicked: false });
                    !(result instanceof Error) && result.id && history.push(`/maps/${id}`);
                });
                
            }
            this.setState({ isSaveClicked: false });
        });
    }

    render() {
        const { isLoading, showComponent } = this.props;
        const { isOpen } = this.state;
        return (
            <>
                {showComponent ? (
                    <StyledBox>
                        <MdiIcon name="map" size={150} />
                        <p>Get Started by creating a map</p>
                        <Button variant="text" onClick={this.handleOpen}>
                            CREATE A NEW MAP
                        </Button>
                    </StyledBox>
                ) : (
                    <Tooltip title="Create new map" arrow>
                        <IconButton onClick={this.handleOpen}>
                            <MdiIcon name="plus" />
                        </IconButton>
                    </Tooltip>
                )}
                {isOpen && (
                    <ModalDialog
                        title="Create New Map"
                        onClose={this.handleClose}
                        isLoading={isLoading}
                        actions={
                            <Button onClick={this.onFormSubmit} disabled={this.state.isSaveClicked}>
                                Save
                            </Button>
                        }
                    >
                        <FormGenerator components={this.formFields} ref={this.formRef} />
                    </ModalDialog>
                )}
            </>
        );
    }
}
const mapStateToProps = (state, ownProps) => ({
    isLoading: state.classifications.list.isLoading
});

export default connect(mapStateToProps, {
    loadClassesListView,
    createEntity,
    setActions,
    closeSidebar
})(CreateMapModal);
