/* @flow */

import { gql } from '@apollo/client';

export default gql`
mutation createProcessDefinition($record: ProcessDefinitionCreateInput!) {
  result: createProcessDefinition(record: $record) {
    id
  }
}
`;
