/* @flow */

import { gql } from '@apollo/client';

export default gql`
    query loadRelatedWorkspacesTeamQuery($startIndex: Int, $stopIndex: Int, $filterBy: [JSON], $orderBy: [JSON], $excludeBy: [JSON], $countMax: Int) {
        count: count(dataType: "workspace", filterBy: $filterBy, max: $countMax)
        records: workspaces(startIndex: $startIndex, stopIndex: $stopIndex, filterBy: $filterBy, orderBy: $orderBy, excludeBy: $excludeBy) {
            id
            name
            teams {
                role
                team {
                    name
                    id
                }
            }
        }
    }
`;
