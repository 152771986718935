/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import styled from 'styled-components';
import Container from 'app/components/atoms/Container/Container';
import HeaderBar from 'app/components/molecules/HeaderBar/HeaderBar';
import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import FileForm from 'app/components/organisms/Chat/FileForm';
import Loader from 'app/components/atoms/Loader/Loader';
import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import { serializeVariables, deserializeVariables } from 'app/utils/bpmn/bpmnEngineUtils';
import { get, set } from 'app/utils/lo/lo';
import { getArray, getStr } from 'app/utils/utils';
import { normalizeFields, enrichContext } from 'app/utils/designer/form/formUtils';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { closeTask, loadTaskSidebarDetailsInternal } from 'store/actions/abox/taskActions';
import { saveProcessVariables } from 'store/actions/abox/processActions';
import { expandClassesAndDefaults } from 'app/utils/form/formGenerator';
import { loadClassificationsByIds } from 'store/actions/classifications/classificationsActions';
import { setDocumentTitle, showToastr } from 'store/actions/app/appActions';
import { deleteChatRoomAttachment, uploadRoomFile, loadSubscriptions, loadRooms } from 'store/actions/chat/chatActions';
import { removeSpecialCharacters } from 'app/utils/string/string-utils';
import PromptModal from 'app/components/organisms/PromptModal/PromptModal';
import { openSidebarByParams, getOnlyUpdatedData } from 'app/utils/app/appUtils';

import { openEntitySidebar } from 'store/actions/entities/entitySidebarActions';
import { openProcessSidebar } from 'store/actions/abox/processSidebarActions';
import { openClassSidebar } from 'store/actions/entities/classSidebarActions';
import { openTeamSidebar } from 'store/actions/entities/teamSidebarActions';
import { openTaskSidebar } from 'store/actions/abox/taskSidebarActions';
import { openWorkspaceSidebar } from 'store/actions/entities/workspaceSidebarActions';
import { openEventSidebar } from 'store/actions/stream/eventsSidebarActions';
import { openEventsActionsSidebar } from 'store/actions/stream/eventsActionsSidebarActions';
import { openEventTypeSidebar } from 'store/actions/entities/eventTypeSidebarActions';

const StyledFormGenerator = styled(FormGenerator)`
    ${({ chipDisabled }) =>
    chipDisabled
        ? `& .MuiChip-deleteIconColorPrimary, .MuiChip-deleteIcon {
            display: none !important; 
        }`:''}
    white-space: break-spaces;
`;

class TaskFormTab extends PureComponent<Object, Object> {
    static propTypes = {
        details: PropTypes.object.isRequired,
        loadClassificationsByIds: PropTypes.func.isRequired,
        showToastr: PropTypes.func.isRequired,
    };

    state = {
        variables: { },
        fileHandlers: [],
        fileDeleteHandlers: [],
        formTouched: false, // show prompt on exit if form is touched
        asyncDone: false, // this we need for loading form once after all async normalizations
    };
    // for collecting dropzone references for upload onSave the task

    dropzoneUploads = [];
    formRef: Object = React.createRef();

    actions = {
        save: this.saveVariables,
        close: this.closeTask,
    }

    constructor(props: Object) {
        super(props);
        const formComponents = this.getFormDefinitionFields(props.details);
        this.defaultVariables = this.getProcessVariables(props.details, formComponents);
        this.state.variables = this.defaultVariables;
    }

    componentDidUpdate(prevProps: Object, prevState: Object) {
        const { details } = this.props;
        const { components } = this.state;
        if (details !== prevProps.details) {
            const formComponents = this.getFormDefinitionFields(details);
            this.expandClassesComponents(formComponents, this.getProcessVariables(details, formComponents));
        }
        if(components !== get(prevState, 'components')) {
            const variables = this.getProcessVariables(this.props.details, components);
            this.defaultVariables = variables;
            this.setState({ variables });
        }
    }

    componentDidMount() {
        const { details, reloadDetails, setDocumentTitle } = this.props;
        const name = details?.name;
        if(name){
            setDocumentTitle(name);
        }
        reloadDetails(this.props.details.id);
    }

    @bind
    @memoize()
    getProcessVariables(details, components) {
        const processVariables = get(details, 'process.variables', {});
        return deserializeVariables(components, processVariables);
    }

    @bind
    async uploadDropzoneFiles(variables) {
        let updatedVariabels = {...variables};
        const promises = this.dropzoneUploads.map((dp) => {
            const isTopLevel = !dp.path;
            const data = get(updatedVariabels, isTopLevel ? dp.reference : dp.path);
            if(!data || (!Array.isArray(data) && !get(data, dp.reference))) {
                return Promise.resolve();
            }
            if(isTopLevel) {
                return data.map((file, index) => !(file instanceof File) ? Promise.resolve() : this.appendFile(file, { reference: dp.reference, index }));
            }
            if(Array.isArray(data)) {
                return data.map((groupData, grIindex) => {
                    const files = get(groupData, dp.reference);
                    if(!files) {
                        return Promise.resolve();
                    }
                    return files.map((file, index) => !(file instanceof File) ? Promise.resolve() : this.appendFile(file, { reference: `${dp.path}[${grIindex}].${dp.reference}`, index }));
                });
            } else {
                const files = get(data, dp.reference);
                return files.map((file, index) => !(file instanceof File) ? Promise.resolve() : this.appendFile(file, { reference: `${dp.path}.${dp.reference}`, index }));
            }
        });
        const results = await Promise.all(promises.flat(Infinity));
        results.filter(Boolean).forEach((image) => {
            const files = [...get(updatedVariabels, image.reference, [])];
            files.splice(image.index, 1, image);
            updatedVariabels = set(updatedVariabels, image.reference, files);
        });
        return updatedVariabels;
    }

    @bind
    async deleteDropzoneFiles() {
        const { fileDeleteHandlers } = this.state;
        const { deleteChatRoomAttachment } = this.props;
        const promises = fileDeleteHandlers.map(async (file) => {
            const { id: fileId } = file || {};
            if(fileId) {
                const resp = await deleteChatRoomAttachment(fileId);
                if(resp instanceof Error) {
                    return Promise.resolve();
                }    
            }
        });
        return Promise.all(promises);
    }

    @bind
    async isValidForm() {
        const { errors, data } = await this.formRef.current.isValidForm();
        if (errors) {
            this.props.showToastr({ severity: 'error', detail: 'The form contains invalid data.' });
        }

        return { errors, data };
    }

    @bind
    async saveVariables(e, navigateFn) {
        const { errors } = await this.isValidForm();
        if (errors) return;
    
        if (this.state.isActionStarted) {
            return;
        }
    
        // Start the action
        this.setState({ isActionStarted: true });
    
        try {
            const { details, canEdit } = this.props;
            const { variables, fileDeleteHandlers, components } = this.state;
            const disabled = !!details.primary.closedDate || !canEdit;
            const formComponents = this.reCheckComponentsProps(components, disabled, '', true, details.type, details.id);
    
            // Update variables and handle file uploads
            let updatedVariables = { ...variables };
            if (this.dropzoneUploads.length) {
                updatedVariables = await this.uploadDropzoneFiles(updatedVariables);
            }
    
            // Handle file deletions
            if (fileDeleteHandlers.length) {
                await this.deleteDropzoneFiles();
            }
    
            // Set the updated variables and clear fileDeleteHandlers
            this.setState({ variables: updatedVariables, fileDeleteHandlers: [] });
    
            // Prepare data for saving
            const onlyUpdatedVars = getOnlyUpdatedData(this.defaultVariables, updatedVariables);
            const vars = serializeVariables(formComponents, onlyUpdatedVars);
    
            // Update the default variables
            this.defaultVariables = {
                ...this.defaultVariables,
                ...vars,
            };
    
            // Save the process variables
            await this.props.saveProcessVariables(details.process.id, vars);
    
            // End the action and reset formTouched
            this.setState({ isActionStarted: false, formTouched: false });
    
            if (navigateFn) {
                navigateFn();
            }
        } catch (error) {
            this.setState({ isActionStarted: false });
        }
    }

    @bind
    async closeTask(field) {
        const { errors } = await this.isValidForm();
        if (errors) return;

        if (this.state.isActionStarted) {
            return;
        }

        // Start the action
        this.setState({ isActionStarted: true });

        try {
            const { details, sidebarIsOpen, canEdit } = this.props;
            const outcome = getStr(field, 'properties.outcome') || '';
            const { variables, fileDeleteHandlers, components } = this.state;
            const disabled = !!details.primary.closedDate || !canEdit;
            const formComponents = this.reCheckComponentsProps(components, disabled, '', true, details.type, details.id);

            // Update variables and handle file uploads
            let updatedVariables = { ...variables };
            if (this.dropzoneUploads.length) {
                updatedVariables = await this.uploadDropzoneFiles(updatedVariables);
            }

            // Handle file deletions
            if (fileDeleteHandlers.length) {
                await this.deleteDropzoneFiles();
            }

            // Set the updated variables and clear fileDeleteHandlers
            this.setState({ variables: updatedVariables, fileDeleteHandlers: [] });

            // Prepare data for saving and close the task
            const onlyUpdatedVars = getOnlyUpdatedData(this.defaultVariables, updatedVariables);
            const vars = serializeVariables(formComponents, onlyUpdatedVars);

            // Update the default variables
            this.defaultVariables = {
                ...this.defaultVariables,
                ...vars,
            };

            // Close the task
            await this.props.closeTask(details.id, vars, outcome);

            // Optionally reload sidebar details if open
            if (sidebarIsOpen) {
                await this.props.loadTaskSidebarDetailsInternal(details.id);
            }

            // Load additional data
            await this.props.loadRooms();
            await this.props.loadSubscriptions();

            // End the action and reset formTouched
            this.setState({ isActionStarted: false, formTouched: false });

        } catch (error) {
            this.setState({ isActionStarted: false });
        }
    }


    @bind
    confirmCallback() {
        this.setState({ formTouched: false });
    }

    @bind
    @memoize()
    getFormDefinitionFields(details) {
        const components = getArray(details, 'formDefinitionVersion.definition');
        const normalized = normalizeFields(components) || [];
        const disabled = !!details.primary.closedDate || !this.props.canEdit;
        const taskType = details.type;
        this.dropzoneUploads = [];
        return this._buildFormDefinitionFields(normalized, disabled, '', true, taskType, details.id);
    }

    @bind
    _buildFormDefinitionFields(normalized, disabled, path, parent, taskType, taskId) {
        return (normalized || []).map((field) => {
            let newPath = parent ? '' : `${path || ''}`;
            let enrichedField = {...field};
            if(disabled) {
                enrichedField = { ...field, properties: { ...(field.properties || {}), disabled: !!disabled } };
            }
            if (enrichedField.children) {
                if(enrichedField.properties.name) {
                    newPath = newPath ? `${newPath}.${enrichedField.properties.name}` : `${enrichedField.properties.name}`;
                }
                enrichedField.children = this._buildFormDefinitionFields(enrichedField.children, disabled, newPath || '', false, taskType, taskId);
                return enrichedField;
            }

            switch(enrichedField.type) {
                case 'button':
                    if (enrichedField.properties.withConfirmation) {
                        enrichedField.properties = {
                            ...enrichedField.properties,
                            onBeforeClick: this.confirmCallback
                        };
                    }
                    break;
                case 'chip': {
                    enrichedField.properties = {
                        ...enrichedField.properties,
                        entity: {
                            id: taskId,
                            type: taskType
                        },
                        openSidebar: (params) => openSidebarByParams({...params, ...this.props, internal: true }) // FIXME: should opensidebars
                    };
                    break;
                }
                case 'duration': {
                    enrichedField.properties = {
                        ...enrichedField.properties,
                        serialize: true
                    };
                    break;
                }
                case 'printButton': {
                    enrichedField.properties = {
                        ...enrichedField.properties,
                        entity: {
                            id: taskId,
                            type: taskType
                        }
                    };
                    break;
                }
                case 'outcome': {
                    // $FlowFixMe
                    const action = this.actions[getStr(field, 'properties.action')];
                    if (action) {
                        enrichedField.properties = {
                            ...enrichedField.properties,
                            onClick: () => action(field),
                        };
                    }
                    enrichedField.properties = {
                        ...enrichedField.properties,
                        className: `no-print ${get(enrichedField.properties, 'className', '')}`,
                        validateBefore: true,
                    };
                    break;
                }
                case 'relationsTypeahead': {
                   
                    const defType = taskType === 'closedtask' ? 'opentask' : taskType; // when task get closed relation definition stays the opentask
                    const def = enrichedField[`relation_definition_${defType}`]; // We only have enrichedField in case of class attributes form definitions
                    const definition = def ? { definition: def } : {};
                    enrichedField.properties = {
                        ...enrichedField.properties,
                        fromType: taskType,
                        ...definition
                    };
                    break;
                }
                case 'textEditor':
                    enrichedField =
                    set(enrichedField, 'properties.uploadImage', this.appendFile);
                    enrichedField = set(
                        enrichedField,
                        'properties.uploadAccept',
                        ['image/svg+xml', 'image/gif', 'image/jpeg', 'image/png']);
                    break;
                case 'dropzone':
                    const reference = enrichedField.properties.name;
                    const referenceData = { 
                        path: newPath, 
                        reference 
                    };
                    enrichedField = set(
                        enrichedField,
                        'properties.onRemoveFile',
                        this.removeAttachment);
                    // We are storing path to the data, and then depend of data type (array or object) we will parse files
                    !this.dropzoneUploads.find(f => f?.reference === reference) && this.dropzoneUploads.push(referenceData);
                    break;
                default:
            }

            return enrichedField;
        });
    }

    @bind
    removeAttachment(removedFile) {
        const { fileDeleteHandlers: deleteHandlers } = this.state;
        const fileDeleteHandlers = [ ...(deleteHandlers || [])];

        if((removedFile.id && fileDeleteHandlers.find(f => f.id === removedFile.id)) || !removedFile.id) {
            return;
        }

        if(removedFile.id) {
            fileDeleteHandlers.push(removedFile);
        }

        this.setState({ fileDeleteHandlers });
    }

    @bind
    updateVariables(variables, changes) {
        this.setState({ variables });
        if (this.formRef.current && this.formRef.current.state.formTouched) {
            const { details, canEdit } = this.props;
            const disabled = !!details.primary.closedDate || !canEdit;
            this.setState({ formTouched: true && !disabled });
        }
    }

    @bind
    @memoize()
    buildContext(details, profile) {
        const { id: taskId, name: taskName, process: processData, endDate, assignee: assigneeData, owner: ownerData } = details || {};
        let assignee = null;
        if (assigneeData) {
            assignee = {
                id: assigneeData.id,
                login: assigneeData.login,
                email: assigneeData.email,
                image: assigneeData.image,
            };
        }
        let owner = null;
        if (ownerData) {
            owner = {
                id: ownerData.id,
                login: ownerData.login,
                email: ownerData.email,
                image: ownerData.image,
            };
        }
        let process = null;
        if (processData) {
            process = {
                id: processData.id,
                name: processData.name,
                endDate: processData.endDate,
            };
        }
        const { id, login, name, email, image, isAdmin, groups, permissions } = profile || {};
        return enrichContext({
            user: { id, login, name, email, image, isAdmin, groups, permissions },
            task: {
                id: taskId,
                name: taskName,
                assignee,
                owner,
                endDate,
            },
            process,
        });
    }

    @bind
    @memoize()
    async expandClassesComponents(formComponents, variables) {
        const { components, data } = formComponents
            ? await expandClassesAndDefaults(formComponents, variables, this.props.loadClassificationsByIds)
            : { components: formComponents, data: variables };
        this.setState({
            variables: { ...get(this, 'state.data', {}), ...data },
            asyncDone: true,
            components,
        });
    }

    @bind
    appendFile(file, options) {
        let resolve, reject;
        const promise = new Promise((_resolve, _reject) => {
            resolve = _resolve;
            reject = _reject;
        });
        const fileHandler = {
            file,
            promise,
            resolve,
            reject,
            ...(options || {})
        };
        this.setState(state => (
            { fileHandlers: [...state.fileHandlers, fileHandler] }
        ));
        return fileHandler.promise;
    }

    @bind
    async attachToTask(data) {
        const { details: { id } } = this.props;
        this._attach(id, 'opentask', data);
    }

    @bind
    async attachToProcess(data) {
        const id = get(this.props.details, 'process.id');
        this._attach(id, 'openprocess', data);
    }


    @bind
    async _attach(id, type, { file, filename, description, reference, index }) {
        const fileHandler = this.state.fileHandlers[0];
        let parsedFilename = removeSpecialCharacters(filename);
        const fileExtension = file.name.split('.').pop();
        if (fileExtension !== parsedFilename.split('.').pop()) {
            parsedFilename = `${parsedFilename}.${fileExtension}`;
        }
        file = new File([file], parsedFilename, { type: file.type });

        try {
            const response = await this.props.uploadRoomFile({
                id,
                type,
                file,
                description
            });
            const meta = response && response.file;
            if (!meta) {
                fileHandler.reject(new Error('Invalid response.'));
            } else {
                fileHandler.resolve({
                    src: window.encodeURI(`/chat/file-upload/${meta.id}/${meta.name}`),
                    alt: response.msg || '',
                    reference,
                    index,
                    name: file.name,
                    type: file.type,
                    rid: response.rid,
                    msgId: response._id,
                    id: response.file.id,
                });
            }
        } catch(error) {
            fileHandler.reject(error);
        }
        this.setState(state => ({ fileHandlers: state.fileHandlers.slice(1) }), this.resetIfNoFiles);
    }

    @bind
    async rejectFile() {
        this.setState(state => ({ fileHandlers: state.fileHandlers.slice(1) }), this.resetIfNoFiles);
    }

    @bind
    resetIfNoFiles(){
        const { fileHandlers } = this.state;
        if (!fileHandlers?.length) {
            this.setState({ isActionStarted: false });
        }
    }

    /*
     * Wee need this method because classifications fields is async
     */
    @bind
    @memoize()
    reCheckComponentsProps(components, disabled, path, parent, taskType, taskId) {
        return this._buildFormDefinitionFields(components, disabled, path, parent, taskType, taskId);
    }

    /**
     * @override
     */
    render(): Object {
        const {
            details, profile,
            isClassificationsLoading,
            isFileUploading, breadcrumbLine, sidebarActions, canEdit
        } = this.props;
        
        const processId = get(details, 'process.id');
        const disabled = !!details.primary.closedDate || !canEdit;
        const context = this.buildContext(details, profile);
        const { components, variables, fileHandlers, isActionStarted, asyncDone, formTouched } = this.state;
        const formComponents = this.reCheckComponentsProps(components, disabled, '', true, details.type, details.id);
        const data = this.getProcessVariables({ process: { variables } }, formComponents );
        
        return (
            <>
                {(isClassificationsLoading || isActionStarted || !asyncDone) && <Loader absolute backdrop />}
                <HeaderBar right={sidebarActions} left={breadcrumbLine} />
                <ContentArea withHeader>
                    <Container width="1024">
                        {asyncDone && (
                            <StyledFormGenerator
                                ref={this.formRef}
                                components={formComponents}
                                onChange={this.updateVariables}
                                data={data}
                                indent={true}
                                context={context}
                                chipDisabled={disabled}
                                isValidForm={this.isValidForm}
                            />
                        )}
                        {!!fileHandlers[0] && (
                            <ModalDialog onClose={this.rejectFile} title="Upload file?">
                                <FileForm
                                    close={this.rejectFile}
                                    file={fileHandlers[0].file}
                                    fileReference={fileHandlers[0].reference}
                                    fileIndex={fileHandlers[0].index}
                                    isLoading={isFileUploading}
                                    uploadFile={this.attachToTask}
                                    uploadLabel="Attach to task"
                                    alternativeUpload={processId && this.attachToProcess}
                                    alternativeUploadLabel={processId && 'Attach to process'}
                                    formFields={[
                                        {
                                            field: 'filename',
                                            type: 'text',
                                            properties: {
                                                name: 'filename',
                                                label: 'File Name'
                                            },
                                            constraints: { required: true }
                                        },
                                        {
                                            field: 'description',
                                            type: 'text',
                                            properties: {
                                                name: 'description',
                                                label: 'File Description'
                                            }
                                        }
                                    ]}
                                />
                            </ModalDialog>
                        )}
                        <PromptModal when={formTouched} onSave={this.saveVariables} />
                    </Container>
                </ContentArea>
            </>
        );
    }
}

export default connect(
    (state,) => {
        return {
            isClassificationsLoading: state.classifications.classificationsByIds.isLoading,
            isFileUploading: state.chat.room.isFileUploading,
            sidebarIsOpen: state.sidebar.isOpen,
            chatFiles: state.chat.files.data,
        };
    },
    {
        saveProcessVariables,
        closeTask,
        loadClassificationsByIds,
        loadTaskSidebarDetailsInternal,
        showToastr,
        uploadRoomFile,
        deleteChatRoomAttachment,
        loadSubscriptions,
        loadRooms,
        openEntitySidebar,
        openClassSidebar,
        openTaskSidebar,
        openProcessSidebar,
        openTeamSidebar,
        openWorkspaceSidebar,
        openEventTypeSidebar,
        openEventSidebar,
        openEventsActionsSidebar,
        setDocumentTitle,
    }
)(withTheme(TaskFormTab));
