export const TERMS_EULA = `
### END USER LICENCE AGREEMENT\n
Affectli is licensed to You (End-User) by Mi-C3 International Limited, located and registered at 410, Imxaxa, Triq Santa Marija, Mellieħa, Malta ('Licensor'), for use only under the terms of this Licence Agreement.
If you are authorised to use Affectli because you are an employee or an authorised affiliate or otherwise authorised by a corporate licence holder (“Corporate Licensee”), your use of Affectli may be subject to terms defined in the Licence granted to that Corporate Licensee. In case of conflict between this End User Licence Agreement and the Licence granted to the Corporate Licensee, the terms of the Licence granted to the Corporate Licensee will prevail.
By downloading or accessing the Licensed Application from , and any update thereto (as permitted by this Licence
Agreement), You indicate that You agree to be bound by all of the terms and conditions of this Licence Agreement, and that You accept this Licence Agreement.
Affectli when purchased or downloaded or accessed, is licensed to You for use only under the terms of this Licence Agreement. The Licensor reserves all rights not expressly granted to You. 
Affectli ('Licensed Application') is a piece of software created to provide tools including Collaborative
Communication, Task Management, Integration and Data Management, Designer, Semantic Relationship, Digital Twins, Broadcasts, Super Analytics & Reporting, and Situational Awareness. It is used to provide organisations with an agile and scalable technology that is system and device agnostic that allows them to orchestrate their operations while retaining ownership of their data.
The Licensed Application is not tailored to comply with industry-specific regulations, so if your interactions would be subjected to such laws, you may not use this Licensed Application. You may not use the Licensed Application in a way that would violate applicable laws in your jurisdiction.\n\n
### 2. SCOPE OF LICENCE\n
2.1 This licence will also govern any updates of the Licensed Application provided by Licensor that replace, repair, and/or supplement the first Licensed Application, unless a separate licence is provided for such update, in which case the terms of that new licence will govern.\n
2.2 You may not reverse engineer, translate, disassemble, integrate, decompile, remove, modify, combine, create derivative works or updates of, adapt, or attempt to derive the source code of the Licensed Application, or any part thereof (except with Mi-C3 International Limited's prior written consent).\n
2.3 Violations of the obligations mentioned above, as well as the attempt of such infringement, may be subject to prosecution and damages.\n
2.4 Licensor reserves the right to modify the terms and conditions of licensing.\n
2.5 Nothing in this licence should be interpreted to restrict third-party terms. When using the Licensed Application, You must ensure that You comply with applicable third-party terms and conditions.\n\n
### 3. TECHNICAL REQUIREMENTS\n
3.1 You acknowledge that it is Your responsibility to confirm and determine that the app end-user device on which You intend to use the Licensed Application satisfies the technical specifications mentioned above.\n
3.2 Licensor reserves the right to modify the technical specifications as it sees appropriate at any time.\n\n
### 4. MAINTENANCE AND SUPPORT\n
The Licensor is solely responsible for providing any maintenance and support services for this Licensed Application.\n\n
### 5. USE OF DATA\n
You acknowledge that Licensor will be able to access and adjust Your Licensed Application content and Your personal information, and that Licensor's use of such material and information is subject to Your legal agreements with Licensor and Licensor's privacy policy, which can be found at the bottom of the Licensed Application.
You acknowledge that the Licensor may periodically collect and use technical data and related information about your device, system, and application software, and peripherals, offer product support, facilitate the software updates, and for purposes of providing other services to you (if any) related to the Licensed Application. Licensor may also use this information to improve its products or to provide services or technologies to you, as long as it is in a form that does not personally identify you.\n\n
### 6. USER-GENERATED CONTRIBUTIONS\n
The Licensed Application does not offer users to submit or post content. We may provide you with the opportunity to create, submit, post, display, transmit, perform, publish, distribute, or broadcast content and materials to us or in the Licensed Application, including but not limited to text, writings, video, audio, photographs, graphics, comments, suggestions, or personal information or other material (collectively, 'Contributions'). Contributions may be viewable by other users of the Licensed Application and through third party websites or applications. As such, any Contributions you transmit may be treated in accordance with the Licensed Application Privacy Policy. When you create or make available any Contributions, you thereby represent and warrant that:\n
6.1. The creation, distribution, transmission, public display, or performance, and the accessing, downloading, or copying of your Contributions do not and will not infringe the proprietary rights, including but not limited to the copyright, patent, trademark, trade secret, or moral rights of any third party.\n
6.2. You are the creator and owner of or have the necessary licences, rights, consents, releases, and permissions to use and to authorise us, the Licensed Application, and other users of the Licensed Application to use your Contributions in any manner contemplated by the Licensed Application and this Licence Agreement.\n
6.3. You have the written consent, release, and/or permission of each and every identifiable individual person in your Contributions to use the name or likeness or each and every such identifiable individual person to enable inclusion and use of your Contributions in any manner contemplated by the Licensed Application and this Licence Agreement.\n
6.4. Your Contributions are not false, inaccurate, or misleading.\n
6.5. Your Contributions are not unsolicited or unauthorised advertising, promotional materials, pyramid schemes, chain letters, spam, mass mailings, or other forms of solicitation.\n
6.6. Your Contributions are not obscene, lewd, lascivious, filthy, violent, harassing, libellous, slanderous, or otherwise objectionable (as determined by us).\n
6.7. Your Contributions do not ridicule, mock, disparage, intimidate, or abuse anyone.\n
6.8. Your Contributions are not used to harass or threaten (in the legal sense of those terms) any other person and to promote violence against a specific person or class of people.\n
6.9. Your Contributions do not violate any applicable law, regulation, or rule.\n
6.10. Your Contributions do not violate the privacy or publicity rights of any third party.\n
6.11. Your Contributions do not violate any applicable law concerning child pornography, or otherwise intended to protect the health or well-being of minors.\n
6.12. Your Contributions do not include any offensive comments that are connected to race, national origin, gender, sexual preference, or physical handicap.\n
6.13. Your Contributions do not otherwise violate, or link to material that violates, any provision of this Licence Agreement, or any applicable law or regulation.\n
Any use of the Licensed Application in violation of the foregoing violates this Licence Agreement and may result in, among other things, termination or suspension of your rights to use the Licensed Application.\n\n
### 7. CONTRIBUTION LICENCE\n
You agree that we may access, store, process, and use any information and personal data that you provide following the terms of the Privacy Policy and your choices (including settings). By submitting suggestions of other feedback regarding the Licensed Application, you agree that we can use and share such feedback for any purpose without compensation to you.
We do not assert any ownership over your Contributions. You retain full ownership of all of your Contributions and any intellectual property rights or other proprietary rights associated with your Contributions. We are not liable for any statements or representations in your Contributions provided by you in any area in the Licensed Application. You are solely responsible for your Contributions to the Licensed Application and you expressly agree to exonerate us from any and all responsibility and to refrain from any legal action against us regarding your Contributions.\n\n
### 8. LIABILITY\n
8.1 Licensor's responsibility in the case of violation of obligations and tort shall be limited to intent and gross negligence. Only in case of a breach of essential contractual duties (cardinal obligations), Licensor shall also be liable in case of slight negligence. In any case, liability shall be limited to the foreseeable, contractually typical damages. The limitation mentioned above does not apply to injuries to life, limb, or health.\n
8.2 Licensor takes no accountability or responsibility for any damages caused due to a breach of duties according to this Licence Agreement. To avoid data loss, You are required to make use of backup functions of the Licensed Application to the extent allowed by applicable third-party terms and conditions of use. You are aware that in case of alterations or manipulations of the Licensed Application, You will not have access to the Licensed Application.\n\n
### 9. WARRANTY\n
9.1 Licensor warrants that the Licensed Application is free of spyware, trojan horses, viruses, or any other malware. Licensor warrants that the Licensed Application works as described in the user documentation.\n
9.2 No warranty is provided for the Licensed Application that is not executable on the device, that has been unauthorisedly modified, handled inappropriately or culpably, combined or installed with inappropriate hardware or software, used with inappropriate accessories, regardless if by Yourself or by third parties, or if there are any other reasons outside of Mi-C3 International Limited's sphere of influence that affect the executability of the Licensed Application.\n
9.3 You are required to inspect the Licensed Application immediately after installing it and notify Mi-C3 International Limited about issues discovered without delay by email provided in Contact Information. The defect report will be taken into consideration and further investigated if it has been emailed within a period of five (5) days after discovery.\n
9.4 If we confirm that the Licensed Application is defective, Mi-C3 International Limited reserves a choice to remedy the situation either by means of solving the defect or substitute delivery.\n
9.5 In the event of any failure of the Licensed Application to conform to any applicable warranty, You may notify your Corporate Licensee the Services Store Operator, and Your Licensed Application purchase price will be refunded to You. To the maximum extent permitted by applicable law, the Services Store Operator will have no other warranty obligation whatsoever with respect to the Licensed Application, and any other losses, claims, damages, liabilities, expenses, and costs attributable to any negligence to adhere to any warranty.\n\n
### 10. PRODUCT CLAIMS\n
Mi-C3 International Limited and the End-User acknowledge that Mi-C3 International Limited is responsible for addressing any claims of the End-User or any third party relating to the Licensed Application or the End-User’s possession and/or use of that Licensed Application, including, but not limited to:\n
(i) product liability claims;\n
(ii) any claim that the Licensed Application fails to conform to any applicable legal or regulatory requirement;\n
and\n
(iii) claims arising under consumer protection, privacy, or similar legislation.\n\n
### 11. LEGAL COMPLIANCE\n
You represent and warrant that You are not located in a country that is subject to an embargo issued by the Maltese government or by the European Union, or that has been designated by the Maltese government or the European Union as a 'terrorist supporting' country; and that You are not listed on any Maltese government or European Union list of prohibited or restricted parties.\n\n
### 12. CONTACT INFORMATION\n
For general inquiries, complaints, questions or claims concerning the Licensed Application, please contact:\n
Mi-C3 International Limited
410, Imxaxa, Triq Santa Marija
Mellieħa,
Malta
contact@affectli.com\n\n
### 13. TERMINATION\n
The licence is valid until terminated by Mi-C3 International Limited or by You. Your rights under this licence will terminate automatically and without notice from Mi-C3 International Limited if You fail to adhere to any term(s) of this licence. Upon Licence termination, You shall stop all use of the Licensed Application, and destroy all copies, full or partial, of the Licensed Application.\n\n
### 14. THIRD-PARTY TERMS OF AGREEMENTS AND BENEFICIARY\n
Mi-C3 International Limited represents and warrants that Mi-C3 International Limited will comply with applicable third-party terms of agreement when using Licensed Application.\n\n
### 15. INTELLECTUAL PROPERTY RIGHTS\n
Mi-C3 International Limited and the End-User acknowledge that, in the event of any third-party claim that the Licensed Application or the End-User's possession and use of that Licensed Application infringes on the third party's intellectual property rights, Mi-C3 International Limited, and not the Services, will be solely responsible for the investigation, defence, settlement, and discharge or any such intellectual property infringement claims.\n\n
### 16. APPLICABLE LAW\n
This Licence Agreement is governed by the laws of Malta excluding its conflicts of law rules.\n\n
### 17. MISCELLANEOUS\n
17.1 If any of the terms of this agreement should be or become invalid, the validity of the remaining provisions shall not be affected. Invalid terms will be replaced by valid ones formulated in a way that will achieve the primary purpose.\n
17.2 Collateral agreements, changes and amendments are only valid if laid down in writing. The preceding clause can only be waived in writing.
`;