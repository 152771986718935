/* @flow */

import { gql } from '@apollo/client';

export default gql`
mutation createFormDefinitionMutation($record: FormDefinitionCreateInput!){
  result: createFormDefinition(record: $record) {
    id
  }
}

`;
