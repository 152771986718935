/* @flow */

import { gql } from '@apollo/client';

import { classDetailsFields } from 'graphql/entities/classifications/classificationDetailQuery';

export default gql`
    mutation deleteClass3dModelMutation($id: ID!) {
        result: deleteClassEntity3dModel(id: $id) {
            ${classDetailsFields}
        }
    }
`;
