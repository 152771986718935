/* @flow */
import React, {useState,useEffect,useCallback, useMemo} from 'react';
import { getLandingPageConfig } from 'app/config/tourConfig';
import { openTour} from 'store/actions/app/appActions';
import {useSelector, useDispatch } from 'react-redux';
import { Grid,Typography, Divider,Dialog, DialogTitle,DialogActions, DialogContent } from '@mic3/platform-ui';
import { isMobile } from 'react-device-detect';
import { saveUserPreferences } from 'store/actions/admin/usersActions';
import  { useReactPWAInstall } from 'app/containers/Pwa/PwaIndex';

import styled from 'styled-components';

import ErrorBoundary from 'app/components/atoms/ErrorBoundary/ErrorBoundary';
import TasksByCreatedDateWidget from 'app/containers/LandingPage/Widgets/TasksByCreatedDateWidget';
import LandingPageHeader from 'app/containers/LandingPage/Areas/LandingPageHeader';
import LandingPageApps from 'app/containers/LandingPage/Areas/LandingPageApps';
import AppTour from 'app/components/atoms/Tour/Tour';
import Button from 'app/components/atoms/Designer/Button';
import ComponentDefaultImage from 'assets/images/icons/checked.png';
import RenderWidget from 'app/containers/LandingPage/Widgets/RenderWidget';
import TasksByPriorityWidget from 'app/containers/LandingPage/Widgets/TasksByPriorityWidget';
import TasksByDueDateWidget from 'app/containers/LandingPage/Widgets/TasksByDueDateWidget';
import AliveWidget from 'app/containers/LandingPage/Widgets/AliveWidget';
import MapsWidget from 'app/containers/LandingPage/Widgets/MapsWidget';
import { hasOneOf } from 'app/utils/utils';
import LastUpdatedTasksWidget from './Widgets/LastUpdatedTasksWidget';
import { getLastUsedProcesses } from 'store/actions/dashboard/dashboardActions';



const DividerStyled = styled(Divider)`
    margin: ${({margin}) => margin ? margin : '0px 16px 16px 16px'} !important;
`;
const GridWidgetWrapperStyled = styled(Grid)`
    padding: 32px 8px !important;
    background: ${({ theme }) => theme.material.colors.background.default};
`;
const GridWrapperStyled = styled(Grid)`
    overflow-y: scroll !important;
`;

const SubmitButton = styled(Button)`
    background: linear-gradient(270deg, #5C3C98 0%, #3E55A5 52.49%, #198AD1 100%);
    border-radius: 5rem !important;
`;

const StyledModalDialog = styled(Dialog)`
    & .MuiPaper-root {
        background:#fff !important;
        color:black !important;
    }
`;

const StyledGrid = styled(Grid)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const RecentAppsHeader = styled(Typography)`
    text-align: center;
    margin-bottom: 16px !important;
`;

const CardsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(344px, 1fr));
    gap: 16px;
`;
const CardItem = styled.div`
    min-width: 344px;
`;

const LandingPageBackgroundWrapper = styled.div`
    position: relative;
`;

const LandingPageBackground = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background: url(/images/landing-page-background-new.png) 50% / cover no-repeat;
    background-size: cover;
    opacity: 20%;
`;

const homeWidgetsDefinitions = [
    {permission:'alive.alive', Component:AliveWidget, headerInfo:'Activity'},
    {permission:'abox.task', Component:TasksByDueDateWidget, headerInfo:'Due Date'},
    {permission:'abox.task', Component:TasksByPriorityWidget, headerInfo:'Priority'},
    {permission:'abox.task', Component:TasksByCreatedDateWidget, headerInfo:'Created Date'},
    {permission:'abox.task', Component:LastUpdatedTasksWidget, headerInfo:'Last Modified Date'},
    {permission:'maps.sa', Component:MapsWidget, headerInfo:'Last Modified Date'},
    {
        title:'Events Monitor',
        description:`Our events monitor displays real-time alarm notifications with their severities that can be actioned on both manually and automatically, filtered according to their preferred view or objectives providing a full contextual history relating to that task or incident including people, documentation, status, action taken, severity level, to name but a few.`,
        url:'/events',
        urlText:'GO TO EVENTS MONITOR',
        avatarProps: {iconType:'af', iconName:'event-monitor', iconColor:'linear-gradient(90deg, #9C27B0 0.02%, #7417EB 99.98%)'},
        Component:RenderWidget,
        permission:'mistream.event',
        headerInfo:'Activity',
    },
    {
        title:'Boards',
        description:`Manage teams and tasks by creating different stages that the tasks go through, giving you another amazing control element in workforce management. 
        Create multiple boards that address different area's or operations in organisation. 
        Manually track and move tasks through different stages identified. 
        Drill into tasks directly, with jump through functionality and granularity linked to specific tasks.`,
        url:'/abox/kanbanboards',
        urlText:'GO TO BOARDS',
        avatarProps: {iconType:'af', iconName:'Kanban', iconColor:'linear-gradient(90deg, #F84219 0.01%, #EE1C80 99.99%)'},
        Component:RenderWidget,
        permission:'abox.board',
        headerInfo:'Activity',
    },
    {
        title:'Calendar',
        description:`Keep track of your team's tasks and workforce management by seeing tasks assigned and being performed on a daily, weekly and monthly calendar view, with filter capabilities to hone in on specific sites, people and teams.`,
        url:'/abox/calendar',
        urlText:'GO TO CALENDAR',
        avatarProps: {iconType:'mdi', iconName:'calendar-blank', iconColor:'linear-gradient(90deg, #28AAF3 0.01%, #00B8A1 99.99%)'},
        Component:RenderWidget,
        permission:'abox.calendar',
        headerInfo:'Activity',
    },
    {
        title:'Analytics',
        description:`View all your dashboards, reports and charts in real-time with the ability to create your own.\n
        Powerful visual representation tool looking at your full operation, from endpoint monitoring to workforce management etc.`,
        url:'/print-reports',
        urlText:'GO TO ANALYTICS',
        avatarProps: {iconType:'af', iconName:'charts', iconColor:'linear-gradient(90deg, #00B8A1 0%, #0BB931 100%)'},
        Component:RenderWidget,
        permission:['entity.entity', 'analytics.superAnalytics', 'analytics.grafana'],
        headerInfo:'Activity',
    },
    {
        title:'Entities',
        description:`Strong asset management for all aspects of an organisation, from people, endpoints, organisations and custom entities. Module uses a profiling capability adding amazing granularity to each entity with unique attributes, documentation management component, relationship view and a full history.`,
        url:'/entities',
        urlText:'GO TO  ENTITIES',
        avatarProps: {iconType:'af', iconName:'things', iconColor:'linear-gradient(90deg, #1248BD 0%, #1E88E5 100%)'},
        Component:RenderWidget,
        permission:'entity.entity',
        headerInfo:'Activity',
    }
];



/**
 * Renders the view to display the classification.
 */

const LandingPage = (props: Object) => {
    const [showModal, setShowModal] = useState(false);
    const [ widgetsKey, setWidgetsKey ] = useState(0);
    const userProfile = useSelector((state) => state.user.profile);
    const pwaInstallCloseValue = localStorage.getItem(`pwaInstallClose_${userProfile?.id}`);
    const dispatch = useDispatch();
    const landingPageConfig = getLandingPageConfig(dispatch);
    const userPreferences = useSelector(state => state.user.preferences);
    const permissions = useSelector(state => state.user?.profile?.permissions);
    const dashboardPermissions = useMemo(() => {
        const permissions = (homeWidgetsDefinitions || []).map(({ permission }) => permission).flat();
        return [...new Set(permissions)];
    }, []);
    
    
    const handleCloseAppTour = useCallback(() => {
        setShowModal(false);
        dispatch(saveUserPreferences({tour: [...(userPreferences?.tour || []), 'landingpage']}));
    }, [userPreferences,dispatch]);

    const handleStartAppTour = useCallback(() => {
        dispatch(openTour()); 
        setShowModal(false);
    }, [dispatch]);

    const { pwaInstall, supported, isInstalled } = useReactPWAInstall();
    const isTourFinish = userPreferences?.tour ? userPreferences?.tour.includes('landingpage'): null;
    useEffect(() => { 
        if(!isInstalled() 
        && supported() 
        && !pwaInstallCloseValue
        && isTourFinish
        ){
            handleClick();
        }
    },[isInstalled, supported, isTourFinish, pwaInstallCloseValue ]); //eslint-disable-line react-hooks/exhaustive-deps

    const handleClick = () => {   
        pwaInstall({
            title: 'Add to homescreen',
        })
            .then(() => alert('App installed successfully or instructions for install shown'))
            .catch(() => alert('User opted out from installing'));
    };

    useEffect(() => { 
        if(!userPreferences?.tour?.includes('landingpage')){
            setTimeout(() => {
                setShowModal(true);
            }, 2000);
        }
    },[userPreferences]);

    const handleReload = useCallback(() => {
        setWidgetsKey(widgetsKey + 1);
        dispatch(getLastUsedProcesses());
    }, [dispatch, widgetsKey]);

    return(
        <>
            <GridWrapperStyled>
                <LandingPageBackgroundWrapper>
                    <LandingPageBackground />
                    <LandingPageHeader reload={handleReload}/>
                    <DividerStyled />
                    <RecentAppsHeader>Recent Apps</RecentAppsHeader>
                    <LandingPageApps />
                </LandingPageBackgroundWrapper>
                <DividerStyled margin='0 16px'/>
                {hasOneOf(new Set(permissions), dashboardPermissions) && <GridWidgetWrapperStyled>
                    <CardsContainer>
                        {homeWidgetsDefinitions.map(({Component, permission, avatarProps, ...rest}, i)=> {
                            const isArray = Array.isArray(permission);
                            if(isArray && !hasOneOf(new Set(permissions), permission)) {
                                return null;
                            }else if(!isArray &&!permissions?.includes(permission)){
                                return null;
                            }
                            return(
                                <CardItem key={i + widgetsKey}>
                                    <ErrorBoundary>
                                        <Component avatarProps={avatarProps} item={rest} userPermissions={permissions} />
                                    </ErrorBoundary>
                                </CardItem>
                            );
                        })}
                    </CardsContainer>
                </GridWidgetWrapperStyled>}
            </GridWrapperStyled>
            {showModal && <StyledModalDialog open>
                <DialogTitle id="alert-dialog-title"></DialogTitle>
                <DialogContent>
                    <Grid container spacing={4}>
                        {!isMobile &&
                            <StyledGrid item md={4} lg={3}>
                                <img src={ComponentDefaultImage} alt="tutor"/>
                            </StyledGrid>
                        } 
                        <Grid item xs={12} md={8} lg={9}>
                            <Typography variant="h4">Welcome to A-Box</Typography>
                            <Typography variant="h6">Take a Tour</Typography>
                            <DividerStyled/>
                            <Typography variant="body1">This module is all about collaboration and provides an integrated space for users and teams to communicate and work together on tasks, projects and entities.</Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DividerStyled/>
                <DialogActions>
                    <Button variant="text" onClick={handleCloseAppTour}> Skip Tutorial</Button>
                    <SubmitButton onClick={handleStartAppTour}> SURE </SubmitButton>
                </DialogActions>
            </StyledModalDialog>}
            <AppTour steps={landingPageConfig} pageName={'landingpage'}/>
        </>
    );
};

export default LandingPage;

