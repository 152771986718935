/* @flow */

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AppOutOfDate from 'app/containers/ErrorPages/AppOutOfDate';
import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import PageTemplate from 'app/components/templates/PageTemplate';
import Loader from 'app/components/atoms/Loader/Loader';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import HeaderBar from 'app/components/molecules/HeaderBar/HeaderBar';
import Breadcrumbs from 'app/components/organisms/Breadcrumbs/Breadcrumbs';
import history from 'store/History';
import { loadProcessDefinition } from 'store/actions/abox/myAppsActions';
import { startProcess } from 'store/actions/abox/processActions';
import { setDocumentTitle, showToastr } from 'store/actions/app/appActions';
import { get } from 'app/utils/lo/lo';
import { getArray } from 'app/utils/utils';
import { normalizeFields, enrichContext } from 'app/utils/designer/form/formUtils';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { serializeVariables } from 'app/utils/bpmn/bpmnEngineUtils';
import { expandClassesAndDefaults } from 'app/utils/form/formGenerator';
import { loadClassificationsByIds } from 'store/actions/classifications/classificationsActions';
import { openProcessSidebar } from 'store/actions/abox/processSidebarActions';

class StartProcess extends PureComponent<Object, Object> {

    static propTypes = {
        loadClassificationsByIds: PropTypes.func,
        loadStartedProcessDetails: PropTypes.func,
        loadProcessDefinition: PropTypes.func,
        loadStartFormDefinition: PropTypes.func,
        isLoadingDefinition: PropTypes.bool,
        isLoadingProcess: PropTypes.bool,
        processDefinition: PropTypes.object,
    };
    formRef: Object = React.createRef();
    state = { variables: {}, isStartingProcess: false, appId: null, formKey: 1 };

    constructor(props) {
        super(props);
        const appId = get(props, 'match.params.appId');

        if (appId) {
            this.props.loadProcessDefinition(appId);
        }
    }

    componentDidMount() {
        const appId = get(this.props, 'match.params.appId');
        this.setState({ appId: appId });
    }

    componentDidUpdate(prevProps: Object) {
        const { processDefinition, setDocumentTitle } = this.props;
        const name = processDefinition?.name;
        const preName = prevProps?.processDefinition?.name;
        if(name && name !== preName){
            setDocumentTitle(name);
        }
        const { isStartingProcess } = this.state;
        const appId = get(this.props, 'match.params.appId');
        if (this.state.appId !== appId) {
            this.setState({ appId: appId });
            this.props.loadProcessDefinition(appId);
        }
        if(processDefinition && processDefinition !== prevProps.processDefinition) {
            const { lastVersion: { _startForm } } = processDefinition;
            if (_startForm) {
                const formComponents = this.getFormDefinitionComponents(_startForm.definition, isStartingProcess);
                this.expandClassesComponents(formComponents, {});
            }
        }
    }

    @bind
    async startProcess(field: Object) {
        const { showToastr } = this.props;
        const { errors } = await this.formRef.current.isValidForm();
        if (errors) {
            return showToastr({ severity: 'error', detail: 'The form contains invalid data.' });
        }
        this.setState({ isStartingProcess: true }, async () => {
            const { startProcess, processDefinition, profile, openProcessSidebar } = this.props;
            const { variables, components } = this.state;
            const vars = serializeVariables(components, variables);

            // start the process
            let process = null;
            try {
                const response = await startProcess(processDefinition.id, vars, field.properties.outcome);
                process = response.process;
            } catch (e) {
                showToastr({ severity: 'error', detail: e.message });
            }

            if (!process) { // is some error occured
                this.setState({ isStartingProcess: false });
                return;
            }

            const tasks = getArray(process, 'tasks') || [];
            const assigned = tasks.filter(task => get(task, 'assignee.id') === profile.id);

            this.setState({ isStartingProcess: false }, () => {
                if (assigned.length) {
                    // open the task
                    const taskId = get(assigned, '[0].id');
                    history.push(`/abox/task/${taskId}`);
                }  else {
                    // open the process
                    openProcessSidebar({ id: process.id, title: 'Tasks' });
                    history.push(`/abox/process/${process.id}`);
                }
            });
        });
    }

    @bind
    _buildFormDefinitionComponents(normalized, isStartingProcess) {
        return normalized.map((field) => {
            if(field.children) {
                const nextField = { ...field };
                nextField.children = this._buildFormDefinitionComponents(nextField.children);
                return nextField;
            }
            if (field.type === 'outcome' && get(field, 'properties.action') === 'startProcess') {
                const outcome = { ...field };
                outcome.properties = {
                    ...field.properties,
                    disabled: isStartingProcess,
                    onClick: () => this.startProcess(field),
                };
                return outcome;
            }
            if(field.type === 'duration') {
                const outcome = { ...field };
                outcome.properties = {
                    ...field.properties,
                    serialize: true
                };
                return outcome;
            }
            return field;
        });
    }

    @bind
    @memoize()
    getFormDefinitionComponents(formDefinition, isStartingProcess) {
        const normalized = normalizeFields(formDefinition) || [];
        return this._buildFormDefinitionComponents(normalized, isStartingProcess);
    }


    @bind
    updateVariables(variables) {
        this.setState({ variables });
    }

    @bind
    @memoize()
    buildContext(profile, process) {
        const { id, login, name, email, image, isAdmin, groups, permissions } = profile || {};
        return enrichContext({
            user: { id, login, name, email, image, isAdmin, groups, permissions },
            process,
        });
    }

    @bind
    @memoize()
    async expandClassesComponents(formComponents, variables) {
        let result = { components: formComponents, data: variables };
        if(formComponents) {
            result = await expandClassesAndDefaults(formComponents, variables, this.props.loadClassificationsByIds);
        }
        this.setState({ components: result.components, variables: result.data, formKey: this.state.formKey + 1 });
    }

    @bind
    @memoize()
    buildBreadcrumb(processDefinition) {
        return (
            <Breadcrumbs
                list={[{ title: 'Start Process' }, { title: get(processDefinition, 'name', 'No Name') }]}
                goBack={() => {history.push('/abox/processes-new');}}
                withGoBack
            />
        );
    }

    /**
     * @override
     */
    render() {
        const { isLoadingDefinition, isClassificationsLoading, processDefinition, profile } = this.props;
        const { variables, components, isStartingProcess, formKey } = this.state;
        if(isLoadingDefinition || isClassificationsLoading || isStartingProcess) {
            return (
                <Loader absolute backdrop />
            );
        }

        const definitionId = get(processDefinition, 'id');

        if(definitionId) {
            return (
                <>
                    <PageTemplate >
                        <ContentArea withHeader>
                            <HeaderBar
                                left={this.buildBreadcrumb(processDefinition)}
                            />
                            {components && (
                                <FormGenerator
                                    indent
                                    key={formKey}
                                    ref={this.formRef}
                                    components={components}
                                    onChange={this.updateVariables}
                                    data={variables}
                                    context={this.buildContext(profile)}
                                />
                            )}
                        </ContentArea>
                    </PageTemplate>
                </>
            );
        }

        return (
            <AppOutOfDate />
        );
    }
}

export default connect(
    state => ({
        profile: state.user.profile,
        isLoadingDefinition: state.abox.processDefinition.isLoading,
        processDefinition: state.abox.processDefinition.data,
        isClassificationsLoading: state.classifications.classificationsByIds.isLoading,
    }), { openProcessSidebar, loadProcessDefinition, startProcess, showToastr, loadClassificationsByIds, setDocumentTitle }
)(StartProcess);
