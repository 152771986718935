import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Ui
import PageNotAllowed from 'app/containers/ErrorPages/PageNotAllowed';
import BroadcastForm from './BroadcastForm';
import { setDocumentTitle } from 'store/actions/app/appActions';

/**
 * Create Broadcast
 */
class EditBroadcast extends PureComponent {

    static propTypes = {
        userProfile: PropTypes.object
    };

    componentDidUpdate() {
        const { setDocumentTitle, broadcast} = this.props;
        const message = broadcast?.message;
        message && setDocumentTitle(broadcast?.message);
    }

    /**
     * Render our create broadcast form
     */
    render() {
        const { permissions, isAdmin } = this.props.userProfile;
        const permissionsSet = new Set(permissions || []);
        const canEdit = isAdmin || permissionsSet.has('admin.broadcast.add');
        if (!canEdit) {
            return <PageNotAllowed title="Broadcast" />;
        }

        return (
            <BroadcastForm title="Edit Broadcast" {...this.props} />
        );
    }
}

export default connect(
    state => ({
        userProfile: state.user.profile,
        broadcast: state.broadcasts.detail.data,
    }), { setDocumentTitle })(EditBroadcast);
