
import { gql } from '@apollo/client';

export default gql`
    query entitiesListQuery($type: String!, $filterBy: [JSON]) {
        records: entities(type: $type, filterBy: $filterBy) {
            id
            name
            type
            image
            iconName
            iconColor
            iconType
        }
    }
`;