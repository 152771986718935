/* @flow */
import React from 'react';
import Help from 'app/utils/designer/form/settings/common/Help';

import { help } from 'app/utils/designer/form/settings/common/commonHelpers';
import { onLoad, isVisible, isDisabled, onRefresh, refreshInterval } from 'app/utils/designer/form/settings/common/commonEvents';
import { flexGrow } from 'app/utils/designer/form/settings/common/commonStyles';

const textHelp =
`
Defines the content of the component.
To define it you can use the Markdown syntax and expressions.
The expressions must be in the form: \`{{path:modifier}}\`.

The path can starts with:
* **properties** to serach the value in the properties
* **data** to serach the value in the data
* **context** to search the value in the context
* **state** to search the value in the component state

The modifier is optional and you can choose one of the following:
* **upper** to convert the value to uppercase
* **lower** to convert the value to lowercase
* **capital** to capitalize the first letter of the value
* **datetime, date, time** for normalizing date and time value
`;

const panels = settingsValues => [
    {
        header: 'Base',
        children: [
            {
                type: 'textEditor',
                properties: {
                    label: 'Markdown text',
                    name: 'properties.text',
                    help: <Help message={textHelp} />,
                },
                constraints: {
                    required: true,
                }
            },
        ],
    },
    {
        header: 'Events',
        children: [
            onLoad,
            isVisible,
            isDisabled,
            refreshInterval,
            onRefresh
        ]
    },
    {
        header: 'Styles',
        children: [ flexGrow ]
    },
    {
        header: 'Helpers',
        children: [ help ]
    },
];

export default {
    defaults: {},
    panels: settingValues => panels(settingValues),
};

