/* @flow */

import React, { useMemo, useCallback } from 'react';
import { Button as PuiButton, ConfirmationModal } from '@mic3/platform-ui';
import styled from 'styled-components';

import { useToggle } from 'app/utils/hook/hooks';
import FormField from 'app/containers/Designer/Form/components/FormField';

const fieldify = (Component: Object) => (props: Object) => <FormField {...props} Component={Component} />;

const PuiButtonStyled = styled(PuiButton)`
    text-align: center;
    text-decoration: none;
    &:hover {
        text-decoration: none !important;
    }
`;

const ConfirmationModalFildifyed = fieldify(ConfirmationModal);

const AppButton = ({
    withConfirmation, confirmationModalProps, to, onClick, onClose, onBeforeClick,
    afterAction, afterActionType, afterActionTo, afterActionHref, afterActionFunction,
    validateBefore, isValidForm, href, target, actionType, urls,
    ...buttonProps
}: Object) => {
    const [openConfirmationModal, toggleConfirmationModal] = useToggle();

    const detectOS = () => {
        const userAgent = window.navigator.userAgent;
        if (/Windows/.test(userAgent)) {
            return 'Windows';
        } else if (/Android/.test(userAgent)) {
            return 'Android';
        } else if (/iP(hone|od|ad)/.test(userAgent)) {
            return 'iOS';
        } 
        else if (/Macintosh/.test(userAgent)) {
            return 'Mac OS';
        }
        else if (/CrOS/.test(userAgent)) {
            return 'ChromeOS';
        }
        return 'Unknown';
    };

    const handleOnClick = useMemo(() => {
        const {androidUrl, iosUrl, windowsUrl} = urls || {};
        const userOS = detectOS();
        switch (userOS){
            case 'Windows':
                return ()=>{window.open(windowsUrl , '_blank');};  
            case 'Android':
            case 'ChromeOS':
                return ()=>{window.open(androidUrl , '_blank');};
            case 'Mac OS':
            case 'iOS':
                return ()=>{window.location.href = iosUrl;};
        }
        return onClick;
    }, [ onClick, urls]);

    const handleOnClose = useCallback((e) => {
        toggleConfirmationModal();
        onClose && onClose(e);
    }, [onClose, toggleConfirmationModal]);    
    const onClickWithConfirm = withConfirmation ? toggleConfirmationModal : handleOnClick;
    const validateOnClick = useCallback(async (event) => {
        if(event && event.persist) {
            event.persist();
        }
        const { errors } = await isValidForm();
        if(!errors) {
            if(!href) {
                onClickWithConfirm(event);
            } else {
                window.open(href, target);
            }
        }
    }, [href, isValidForm, onClickWithConfirm, target]);
    const onClickAction = validateBefore ? validateOnClick : onClickWithConfirm;
    return (
        <>
            <PuiButtonStyled {...buttonProps} onClick={onClickAction} />
            {withConfirmation && confirmationModalProps && (
                <ConfirmationModalFildifyed
                    {...confirmationModalProps}
                    onClose={handleOnClose}
                    open={openConfirmationModal}
                    onConfirm={(e) => { onBeforeClick && onBeforeClick(); return handleOnClick(e); }}
                />
            )}
        </>
    );
};

export default AppButton;
