import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import {
    Typography, Divider, Grid, MdiIcon, Chip, Checkbox, Box, ListItemText, IconButton
} from '@mic3/platform-ui';


import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import ExpansionCard from 'app/components/molecules/ExpansionCard/ExpansionCard';
import Ellipsis from 'app/components/molecules/Ellipsis/Ellipsis';
import { loadClassChildrens } from 'store/actions/classifications/classificationsActions';

import { uriFieldDefinition } from 'app/containers/Classifications/AddClassification';

const DividerStyled = styled(Divider)`
    margin: 10px 0 32px 0 !important;
`;


const ListItemTextStyled = styled(ListItemText)`
    .MuiListItemText-primary {
        font-size: 12px !important;
        color: ${ ( { theme } ) => theme.material.colors.text.primary };
    }
    .MuiListItemText-secondary {
        font-size: 16px !important;
    }
`;

const MoreChipStyled = styled(Chip)`
    background:  ${({ theme }) => theme.material.colors.background.fields} !important;
    & .MuiChip-label {
        max-width: 140px;
    }
`;
const TeamChipStyled = styled(Chip)`
    background:  ${({ theme }) => theme.material.colors.background.fields} !important;
`;

class ClassDetailSection extends PureComponent {

    constructor(props) {
        super(props);
        if(props.details?.id) {  
            props.loadClassChildrens(props.details.id);
        }
        this.state = { childrens: [] };
    }

    componentDidUpdate(prevProps) {
        const { loadClassChildrens, childrens } = this.props;
        const { id } = this.props.details || {};
        if(prevProps.details.id !== id && id) {
            loadClassChildrens(id);
        }
        if(prevProps.childrens !== childrens) {
            this.setState({ childrens });
        }
        
    }

    

    render() {
        const { handleChange, details, entityType, disabled } = this.props;
        const { childrens } = this.state;
        const displaySize = isMobile ? 3 : 6;
        const parentsCount = (details.parents || []).length - displaySize;
        const childrenCount = (childrens || []).length - displaySize;
        const applicableCount = (details.applicableOn || []).length - displaySize;
        return (
            <ExpansionCard
                expanded
                title={entityType ? 'Entity Type details' : 'Class Details'}
              
            >
                <Grid container alignItems="center" justify="space-between" wrap="nowrap">
                
                    <FormGenerator
                        normalizeOnChange
                        onChange={handleChange}
                        root={false}
                        data={details}
                        ref={this.props.forwardRef}
                        disabled={disabled}
                        components={[uriFieldDefinition(entityType)]}
                    />
               
                </Grid>
                {entityType && (
                    <Grid container alignItems="center" justify="space-between" wrap="nowrap">
                        <Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column'}} >
                            <Checkbox
                                value={details.abstract}
                                onChange={handleChange}
                                name="abstract"
                                label="Abstract Type"
                                disabled={disabled}
                            />   
                            <ListItemTextStyled
                                secondary="An abstract type cannot be applied to entities directly."
                            />
                        </Box>
                    </Grid>
                )}
                <DividerStyled />
                <Typography variant="button">Parents</Typography>
                <Grid container alignItems="center">
                    <IconButton disabled={disabled} onClick={() => this.props.openSidebar('Parents')}><MdiIcon name="plus"/></IconButton>
                    <Ellipsis
                        data={details.parents || []}
                        displaySize={displaySize}
                        renderCount={count => (
                            <MoreChipStyled
                                variant="default"
                                label={`+${parentsCount}`}
                                size="small"
                                disabled={disabled}
                                onClick={() => this.props.openSidebar('Parents')}
                            />
                        )}
                        renderItem={
                            ({ name }: Object) => (
                                <TeamChipStyled disabled={disabled} onClick={() => this.props.openSidebar('Parents')} label={name} />
                            )
                        }
                    />
                </Grid>
                <DividerStyled />
                <Typography variant="button">Children</Typography>
                <Grid container alignItems="center">
                    <IconButton disabled={disabled} onClick={() => this.props.openSidebar('Children')}><MdiIcon name="plus"/></IconButton>
                    <Ellipsis
                        data={childrens || []}
                        displaySize={displaySize}
                        renderCount={count => (
                            <MoreChipStyled
                                variant="default"
                                label={`+${childrenCount}`}
                                size="small"
                                disabled={disabled}
                                onClick={() => this.props.openSidebar('Children')}
                            />
                        )}
                        renderItem={
                            ({ uri }: Object) => (
                                <TeamChipStyled disabled={disabled} onClick={() => this.props.openSidebar('Children')} label={uri} />
                            )
                        }
                    />
                </Grid>
                <DividerStyled />
                {!details.primary ? (
                    <>
                        <Typography variant="button">Applies to</Typography>
                        <Grid container alignItems="center">
                            <IconButton disabled={disabled} onClick={() => this.props.openSidebar('Applies To')}><MdiIcon name="plus"/></IconButton>
                            <Ellipsis
                                data={details.applicableOn || []}
                                displaySize={displaySize}
                                renderCount={count => (
                                    <MoreChipStyled
                                        variant="default"
                                        label={`+${applicableCount}`}
                                        size="small"
                                        disabled={disabled}
                                        onClick={() => this.props.openSidebar('Applies To')}
                                    />
                                )}
                                renderItem={
                                    (uri: Object) => (
                                        <TeamChipStyled disabled={disabled} onClick={() => this.props.openSidebar('Applies To')} label={uri} />
                                    )
                                }
                            />
                        </Grid>
                        <DividerStyled />
                    </>
                ) : null}

            </ExpansionCard>
        );
    };
}

export default connect(
    state => ({
        sharingDetails: state.entities.sidebar.workspaces.data,
        sharingisLoading: state.entities.sidebar.workspaces.isLoading,
        childrens: state.classifications.childrens.records,
    }),
    {
        loadClassChildrens
    },
    null, { forwardRef: true }
)(ClassDetailSection);
