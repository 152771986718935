import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import equals from 'fast-deep-equal';
import styledComponents from 'styled-components';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { bind } from 'app/utils/decorators/decoratorUtils';

import Chip from '@material-ui/core/Chip';
import { styled, withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';

import { isObject } from 'app/utils/utils';
import { get } from 'app/utils/lo/lo';
import { createEvent } from 'app/utils/http/event';

const StyledTextField = styledComponents(TextField)`
    background-color: #293347;
    label {
        margin: 10px 0 0 12px
    }
`;

const styles = () => ({
    inputRoot: {
        padding: '1rem 5px 1rem 12px',
        flexWrap: 'wrap',
    },
    chip: {
        margin: '5px 3px',
        height: '24px',
    },
});

const ChipIconStyle = styled('div')({
    margin: '4px -4px 0 8px',
});

class AutocompleBase extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        classes: PropTypes.object,
        clearable: PropTypes.bool,
        disabled: PropTypes.bool,
        freeSolo: PropTypes.bool,
        InputProps: PropTypes.object,
        multiple: PropTypes.bool,
        name: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        options: PropTypes.arrayOf(PropTypes.object),
        optionTemplate: PropTypes.func,
        value: PropTypes.any,
        valueField: PropTypes.string,
        PopperProps: PropTypes.object,
        isLoading: PropTypes.bool,
    };

    static defaultProps = {
        clearable: true,
        options: [],
        InputProps: {},
        PopperProps: {},
    };

    state = {
        selectedValue: this.normalizeValue(this.props.value) || [],
    };

    componentDidUpdate(prevProps) {
        const { value } = this.props;
        if (prevProps.value !== value) {
            this.setState({ selectedValue: value ? this.normalizeValue(value) : [] });
        }
    }

    @bind
    getOptionValue(option) {
        return this.props.valueField ? get(option, this.props.valueField, option.label) : option?.label;
    }

    @bind
    onChange(selectedValue, query = '') {
        const { onChange, name, multiple, valueField } = this.props;
        let value = selectedValue;
        if (valueField) {
            if (multiple && Array.isArray(selectedValue)) {
                value = selectedValue.map((v) => v[valueField] || v).filter(Boolean);
            } else {
                value = valueField ? get(selectedValue, valueField, selectedValue) : selectedValue;
            }
        }
        onChange && onChange(createEvent('change', { name, value }));
    }

    @bind
    optionTemplate(option) {
        const { optionTemplate } = this.props;
        if (!option) {
            return { label: '', startAdornment: null };
        }
        return optionTemplate ? optionTemplate(option) : { label: option.label, startAdornment: null };
    }

    @bind
    removeChip(option) {
        const { value } = this.props;
        const valueToRemove = this.getOptionValue(option);

        const vals = value.filter((v) => {
            if (isObject(valueToRemove)) {
                return !equals(v, valueToRemove);
            }
            return v !== valueToRemove;
        });
        this.onChange(this.normalizeValue(vals));
    }

    @bind
    normalizeValue(value) {
        const { valueField, options } = this.props;
        let modifiedValue = valueField && typeof value === 'string' ? options.find((opt) => opt[valueField] === value) || value : value;
        if (Array.isArray(value)) {
            modifiedValue = value.map((val) =>
                valueField && typeof val === 'string' ? options.find((opt) => opt[valueField] === val) || val : val
            );
        }
        return modifiedValue;
    }

    render() {
        const {
            classes,
            optionTemplate, // eslint-disable-line no-unused-vars
            searchDelay, // eslint-disable-line no-unused-vars
            onChange, // eslint-disable-line no-unused-vars
            multiple,
            disabled,
            clearable,
            InputProps,
            valueField,
            options,
            PopperProps,
            isLoading,
            className,
            freeSolo,
            label,
            placeholder,
            ...restProps
        } = this.props;
        const { selectedValue } = this.state;
        return (
            <Autocomplete
                disableClearable={!clearable}
                multiple={multiple}
                options={options}
                getOptionLabel={(option) => this.optionTemplate(option).label || ''}
                freeSolo={freeSolo}
                forcePopupIcon
                value={this.state.selectedValue}
                filterOptions={(options) => {
                    if (!multiple && isObject(selectedValue)) {
                        return options.filter((opt) => opt.value !== selectedValue.value);
                    }
                    const modifiedValuesArr =
                        Array.isArray(selectedValue) && selectedValue?.length ? selectedValue.map((v) => v?.value).filter(Boolean) : [];
                    return modifiedValuesArr?.length ? options.filter((opt) => !modifiedValuesArr.includes(opt.value)) : options;
                }}
                onChange={(e, value) => this.onChange(value)}
                renderTags={(value, getTagProps) =>
                    value.map((opt, index) => {
                        const option = typeof opt === 'string' ? { label: opt } : opt;
                        const { ChipProps: props, startAdornment, label } = this.optionTemplate(option);
                        const ChipProps = { ...(props || {}) };
                        if (startAdornment && !ChipProps.avatar) {
                            ChipProps.icon = <ChipIconStyle>{startAdornment}</ChipIconStyle>;
                        }
                        return (
                            <Chip
                                {...getTagProps({ index })}
                                color='primary'
                                key={index}
                                label={label}
                                tabIndex={-1}
                                className={classes.chip}
                                onDelete={() => this.removeChip(option)}
                                disabled={disabled}
                                {...ChipProps}
                            />
                        );
                    })
                }
                renderInput={(params) => (
                    <StyledTextField
                        label={!selectedValue || !selectedValue?.length ? placeholder || label : label}
                        disabled={disabled}
                        autoComplete='off'
                        {...params}
                        className={`${className || ''} ${classes.inputRoot}`}
                        InputProps={{ ...(params.InputProps || {}), disableUnderline: true }}
                        {...restProps}
                    />
                )}
            />
        );
    }
}

export default withStyles(styles)(AutocompleBase);
