/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete } from '@mic3/platform-ui';

import { bind, debounce, memoize } from 'app/utils/decorators/decoratorUtils';
import { TypeaheadChipInitials } from 'app/components/organisms/Form/Typeahead/abstract/TypeaheadChip';
import { shallowEquals } from 'app/utils/utils';
import { isValiduuid } from 'app/utils/string/string-utils';
import { getAttachmentUrl } from 'app/utils/attachments/attachmentsUtils';

/**
 * Select one or more entities using lazy loading.
 */
class AbstractEntityTypeahead extends PureComponent<Object,Object> {

    static propTypes = {
        /* eslint-disable-next-line react/forbid-foreign-prop-types */
        ...Autocomplete.propTypes,
        filterBy: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.array])),
        ]),
    }

    state = { options: [], isLoading: false };

    componentDidUpdate(prevProps) {
        if (!shallowEquals(prevProps, this.props)) {
            this.setState({ options: [] });
        }
    }

    @bind
    @memoize()
    getImage(id, type, imageId){
        return getAttachmentUrl(id, type, imageId);
    }

    @bind
    optionTemplate(data: any) {
        if (this.props.optionTemplate) {
            return this.props.optionTemplate(data);
        }
        const { id, type, name, image } = data;
        const src = image && this.getImage(id, type, image);
        return ({
            ChipProps: {
                avatar: <TypeaheadChipInitials initials={name} src={src} />,
            },
            label: `${name || 'Name not available'} (${id ? id.slice(0,8) : 'ID not available'})`,
        });
    };

    @bind
    @debounce()
    suggest(event: Object) {
        this.setState({ isLoading: true });
        const { valueField, value, loadOptions, directoryType, filterBy, excludeBy, orderBy = [{ field: 'name', direction: 'asc nulls last' }] } = this.props;
        const filters = [];
        let excludes = [...(excludeBy || [])];
        const query = event.target.value;
        if (query) {
            if (isValiduuid(query)) {
                filters.push({ or: [
                    { field: 'id', op: '=', value: query },
                    { field: 'name', op: 'contains', value: query },
                ] });
            } else {
                filters.push({ field: 'name', op: 'contains', value: query });
            }
        }
        if (directoryType) {
            filters.push({ field: 'classes.uri', op: '=', value: directoryType });
        }
        if (filterBy) {
            if (typeof filterBy === 'function') {
                filters.push(...filterBy(value));
            } else if (filterBy.length) {
                filters.push(...filterBy);
            }
        }
        if (value) {
            const val = Array.isArray(value) ? value : [value];
            let orVal = [];
            if(valueField) {
                orVal = val.map(fieldVal => ({ field: valueField, op: '=', value: fieldVal }));
            } else {
                const selectedIds = val.map(({ id }) =>  id).filter(Boolean) || [];
                const excludedIdsFilter = [{ field: 'id', op: 'in', value: [...selectedIds, ...(this.props.selectedIds || [])] }];
                if(selectedIds?.length || this.props.selectedIds?.length){
                    excludes = [{ or: [excludedIdsFilter, ...excludes] }];
                }
            }
            if(orVal.length) {
                excludes.push({ or: orVal });
            }
        }
        loadOptions({
            startIndex: 0,
            stopIndex: 50,
            filterBy: filters,
            excludeBy: excludes,
            orderBy: orderBy,
        }).then((options) => {
            this.setState({ options, isLoading: false });
        }).catch((error) => {
            this.setState({ options: [], isLoading: false });
        });
    };

    render() {
        const {
            entityType, reverseRelation, loadOptions, filterBy, orderBy, excludeBy, directoryType, loadAvatar, ...typeaheadProps
        } = this.props; // eslint-disable-line no-unused-vars
        const { options, isLoading } = this.state;
        
        return (
            <Autocomplete
                {...typeaheadProps}
                options={options}
                isLoading={isLoading}
                optionTemplate={this.optionTemplate}
                suggest={this.suggest}
            />
        );
    }
};

export default AbstractEntityTypeahead;
