/* @flow */

import { gql } from '@apollo/client';
import loadWorkspaceDetailsCommonQuery from 'graphql/workspaces/loadWorkspaceDetailsCommonQuery';

export default gql`
    mutation addWorkspaceUsersMutation($id: ID!, $userMembers: [UserMemberInput]!) {
        result: workspaceAddUsers(id: $id, userMembers: $userMembers) {
            ${loadWorkspaceDetailsCommonQuery}
        }
    }
`;
