/* @flow */

import { gql } from '@apollo/client';
import { commonFields } from 'graphql/entities/relationships/relationDefinitionsQuery';

export default gql`
    mutation addRelationDefinitionMutation($record: RelationDefinitionCreateInput!) {
        result: createRelationDefinition(record: $record) {
            ${commonFields}
        }
    }
`;
