import * as React from 'react';
import PropTypes from 'prop-types';
import { isFragment } from 'react-is';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { Avatar } from '@mic3/platform-ui';

const SPACINGS = {
    small: -16,
    medium: null,
};

export const styles = theme => ({
    root: {
        display: 'flex',
    },
    avatar: {
        border: `2px solid ${theme.palette.background.default}`,
        marginLeft: -8,
        '&:first-child': {
            marginLeft: 0,
        },
        width: ({ size }) => size || 24,
        height: ({ size }) => size || 24,
        fontSize: ({ size }) => {
            if(size) {
                return size / 2;
            }
            return 12 ;
        },
    },
});

const AvatarGroup = React.forwardRef(function AvatarGroup(props, ref) {
    const {
        children: childrenProp,
        classes,
        className,
        max = 5,
        spacing = 0,
        size,
        extraAvatarsOnClick,
        ...other
    } = props;
    const clampedMax = max < 2 ? 2 : max;

    const children = React.Children.toArray(childrenProp).filter((child) => {
        if (process.env.NODE_ENV !== 'production') {
            if (isFragment(child)) {
                console.error( // eslint-disable-line no-console
                    [
                        'Material-UI: The AvatarGroup component doesn\'t accept a Fragment as a child.',
                        'Consider providing an array instead.',
                    ].join('\n'),
                );
            }
        }

        return React.isValidElement(child);
    });

    const extraAvatars = children.length > clampedMax ? children.length - clampedMax + 1 : 0;

    const marginLeft = spacing && SPACINGS[spacing] !== undefined ? SPACINGS[spacing] : -spacing;
    const handleExtraAvatarsOnClick = (() => {
        if (Boolean(extraAvatarsOnClick)) extraAvatarsOnClick();
    });

    return (
        <div className={clsx(classes.root, className)} ref={ref} {...other}>
            {children.slice(0, children.length - extraAvatars).map((child, index) => {
                return React.cloneElement(child, {
                    className: clsx(child.props.className, classes.avatar),
                    style: {
                        zIndex: children.length - index,
                        marginLeft: index === 0 ? undefined : marginLeft,
                        ...child.props.style,
                    },
                });
            })}
            {extraAvatars ? (
                <Avatar
                    className={classes.avatar}
                    onClick={handleExtraAvatarsOnClick}
                    size={size}
                    style={{
                        zIndex: 0,
                        marginLeft
                    }}
                >
                    +{extraAvatars}
                </Avatar>
            ) : null}
        </div>
    );
});

AvatarGroup.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    max: PropTypes.number,
    size: PropTypes.number, // Avatar size in px
    spacing: PropTypes.oneOfType([PropTypes.oneOf(['medium', 'small']), PropTypes.number]),
    extraAvatarsOnClick: PropTypes.func, 
};

export default withStyles(styles, { name: 'MuiAvatarGroup' })(AvatarGroup);
