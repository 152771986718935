import React, { memo, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Alert } from '@material-ui/lab';

import history from 'store/History';
import { calculateDuration, fromNow } from 'app/utils/date/date';

const AlertStyled = styled(Alert)`
     padding: 16px !important;
     margin: 0 16px;
     height: 68px;

    ${({ $floating }) => {
    if ($floating) {
        return `
            position: absolute;
            z-index: 1099;
            left: 54px;
            right: 6px;
            top: 72px;
        `;
    }
    return '';}}

    ${({ $clickable }) => $clickable && 'cursor: pointer;'}
`;

function LicenseAlertBar({ expiryDate, canClose, floating, canView }) {
    const [show, setShow] = useState(false);

    const alertProps = useMemo(() => {
        const daysIn = Math.round(calculateDuration(new Date(), new Date(expiryDate), 'days'));
        const millisecondsIn = Math.round(calculateDuration(new Date(), new Date(expiryDate), 'milliseconds'));

        if (daysIn <= 14 && millisecondsIn > 0) {
            setShow(true);
            return {
                severity: 'warning',
                text: `Your licence will expire ${fromNow(expiryDate)}!`
            };
        } else if (millisecondsIn <= 0) {
            setShow(true);
            return {
                severity: 'error',
                text: 'Your licence has expired! Update the licence key to reactivate Affectli.'
            };
        }
    }, [expiryDate]);

    const handleClose = useCallback((e) => {
        e.stopPropagation();
        setShow(false);
    }, []);

    const handleClick = useCallback((e) => {
        e.stopPropagation();
        history.push('/licence');
    }, []);

    if (!show) {
        return null;
    }
    return (
        <AlertStyled 
            variant="filled"
            severity={alertProps.severity} 
            onClose={canClose ? handleClose : null}
            onClick={canView ? handleClick : null}
            $floating={floating}
            $clickable={canView}
        >{alertProps.text}</AlertStyled>
    );
};

LicenseAlertBar.propTypes = {
    expiryDate: PropTypes.string,
    canClose: PropTypes.bool,
    floating: PropTypes.bool,
    canView: PropTypes.bool
};

export default memo(LicenseAlertBar);
