/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Papa from 'papaparse';

import { CircularProgress, Typography, Button, Grid, MdiIcon, Divider, ConfirmationModal } from '@mic3/platform-ui';

import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import { bind } from 'app/utils/decorators/decoratorUtils';
import { importEntities } from 'store/actions/entities/entitiesActions';
import Link from 'app/components/atoms/Link/Link';
import UploadButton from 'app/components/molecules/UploadButton/UploadButton';
import GridView from 'app/components/organisms//GridView/GridView';
import { toTitleCase } from 'app/utils/string/string-utils';
import { showToastr } from 'store/actions/app/appActions';
import { getUnique } from 'app/utils/array/array-utils';
import uuidv1 from 'uuid/v1';

const LinkStyled = styled(Link)`
    color: ${({ theme }) => theme.color.primary};
`;

const StyledDivider = styled(Divider)`
    margin: 5px 0 !important;
`;

const importOptions = [
    { value: 'importEntities', label: 'Entity attributes' },
    { value: 'importEntitiesRelations', label: 'Relationship attributes' },
    { value: 'importEntitiesSharing', label: 'Entity permissions' },
];

const directUploadsOpts = [
    { value: 'importClasses', label: 'Classes', type: 'class' },
    { value: 'importEntityTypes', label: 'Entity Types', type: 'entityType' },
    { value: 'importTeams', label: 'Teams', type: 'team' },
    { value: 'importWorkspaces', label: 'Workspaces', type: 'workspace' },
];

const directUploadTypes = directUploadsOpts.map((obj) => obj?.type);

class UploadDataModal extends PureComponent<Object, Object> {
    static propTypes = {
        type: PropTypes.string.isRequired,
        onClose: PropTypes.func.isRequired,
    };

    formRef = React.createRef();

    constructor(props: Object) {
        super(props);
        this.state = {
            showNotification: false,
            data: { selectedOption: 'importEntities', data: { importEntities: true } },
            selectedOption: 'importEntities',
            columns: [],
            gridData: [],
            file: null,
            isLoading: false,
            jobId: null,
            previewKey: uuidv1(),
            showDupIdsModal: false,
            showConfirmModal: false,
        };
        this.defaultState = { ...this.state };
    }

    @bind
    buildComponents(data, isLoading) {
        const { type } = this.props;
        const directUpload = directUploadTypes.includes(type);
        const isZipUpload = ['team', 'workspace'].includes(type);
        return [
            !isZipUpload && {
                type: 'displayText',
                properties: {
                    text:
                        'By uploading a CSV file you can add and edit entities in bulk. Start by downloading the entity CSV data to use as a template. You can then modify existing line items to edit entities, or create new entities by adding rows.',
                },
            },
            isZipUpload && {
                type: 'displayText',
                properties: {
                    text:
                        'By uploading a ZIP file you can add and edit data in bulk. Start by downloading the ZIP data to use as a template. You can then modify existing line items to edit data, or create new data by adding rows.',
                },
            },
            !isZipUpload && {
                type: 'displayText',
                properties: {
                    text: `When you're happy with your finished file click the button to upload it. You'll see a preview of your data before it is imported into Affectli.`,
                },
            },
            !directUpload && { type: 'header', properties: { variant: 'caption', text: 'Upload data for' } },
            !directUpload && {
                field: 'selectedOption',
                type: 'radioGroup',
                properties: {
                    name: 'selectedOption',
                    formFieldProps: {
                        className: 'typeRadio',
                    },
                    options: importOptions,
                },
            },
            isLoading
                ? {
                    type: 'custom',
                    properties: {
                        label: '',
                        name: `loader`,
                        Component: () => <CircularProgress size={24} color='primary' />,
                    },
                }
                : {
                    type: 'custom',
                    properties: {
                        Component: (props) => (
                            <UploadButton onSelect={this.onFileSelect}>
                                {(onClick) => (
                                    <Button onClick={onClick}>
                                        <MdiIcon name='upload' />
                                        Upload {isZipUpload ? 'ZIP' : 'CSV'}
                                    </Button>
                                )}
                            </UploadButton>
                        ),
                        name: 'uploadbutton',
                        noBackground: 1,
                    },
                },
        ].filter(Boolean);
    }

    @bind
    onFileSelect(file) {
        const { type } = this.props;
        const isZipUpload = ['team', 'workspace'].includes(type);
        if (isZipUpload) {
            return this.setState({ file, showConfirmModal: true });
        }
        Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: async (result) => {
                const { data: gridData, meta, errors } = result || {};
                if (errors?.length) {
                    const { code, message } = errors[0];
                    if (code && message) {
                        return this.props.showToastr({ severity: 'error', detail: `${code}: ${message}` });
                    }
                    return this.props.showToastr({ severity: 'error', detail: 'Invalid CSV!' });
                }
                const { fields } = meta || {};
                const columns = (fields || []).map((field) => ({
                    text: toTitleCase(field),
                    field,
                    renderer: ({ record, value }) => value || record?.data?.[field] || ' ',
                }));
                let showDupIdsModal = false;
                if (fields?.includes('id')) {
                    const ids = gridData.map((d) => d?.id).filter(Boolean);
                    const uniqueIds = getUnique(ids);
                    if (uniqueIds?.length !== ids?.length) {
                        showDupIdsModal = true;
                    }
                }
                this.setState({ columns, gridData, file, previewKey: uuidv1(), showDupIdsModal });
            },
        });
    }

    @bind
    onFormChange(data: Object, changedValues: Object) {
        let nextData = { ...data };
        const { name, value } = changedValues;
        if (name === 'selectedOption') {
            nextData = {
                importEntities: false,
                importEntitiesRelations: false,
                importEntitiesSharing: false,
                [value]: true,
                selectedOption: value,
            };
        }
        this.setState({ [name]: value, data: nextData });
    }

    @bind
    async onImportClick() {
        this.setState({ isLoading: true, showConfirmModal: false });
        const { file, selectedOption } = this.state;
        const { type } = this.props;
        let importLabel,
            mutationName = selectedOption;
        if (directUploadTypes.includes(type)) {
            const record = directUploadsOpts.find((obj) => type === obj?.type);
            if (record) {
                importLabel = record.label;
                mutationName = record.value;
            }
        } else {
            importLabel = importOptions.find(({ value }) => value === selectedOption)?.label || 'Entity attributes';
        }
        let result = {};
        try {
            result = await importEntities({ file, name: `Importing ${importLabel?.toLowerCase()} of type "${type}"` }, mutationName);
        } catch (error) {}
        if (!(result instanceof Error)) {
            return this.setState({ ...this.defaultState, showNotification: true, jobId: result?.data?.result?.id || null });
        }
        this.setState({ isLoading: false });
    }

    @bind
    backToUpload() {
        this.setState((prevState) => ({ ...this.defaultState, previewKey: prevState.previewKey }));
    }

    @bind
    onCloseModal() {
        const { onClose, reloadList } = this.props;
        onClose && onClose();
        reloadList && reloadList();
    }

    render(): Object {
        const { data, showNotification, gridData, columns, isLoading, jobId, previewKey, showDupIdsModal, showConfirmModal } = this.state;
        if (showNotification) {
            return (
                <ModalDialog title='Your upload is being prepared' bgcolor={'rgba(40, 51, 75, 1)'} withoutClose>
                    <Typography>
                        Your upload information is being prepared for you. You can see the status{' '}
                        <LinkStyled to={`/abox/background-job/${jobId}/logs`}>here</LinkStyled> or under the{' '}
                        <LinkStyled to='/abox/background-jobs'>Background Jobs</LinkStyled> in A-Box.
                    </Typography>
                    <Grid container justify='flex-end'>
                        <Button variant='text' onClick={this.onCloseModal}>
                            i understand
                        </Button>
                    </Grid>
                </ModalDialog>
            );
        }
        const showGrid = !!gridData?.length;
        return (
            <>
                <ModalDialog
                    title={showGrid ? 'Preview Import' : 'Upload Data'}
                    bgcolor={'rgba(40, 51, 75, 1)'}
                    onClose={showGrid ? this.backToUpload : this.onCloseModal}
                    closeIcon={showGrid ? 'arrow-left' : 'close'}
                    maxWidth={showGrid ? 'lg' : 'md'}
                    actions={
                        showGrid ? (
                            isLoading ? (
                                <CircularProgress size={24} color='primary' />
                            ) : (
                                <>
                                    <Button variant='text' onClick={this.onCloseModal}>
                                        Cancel
                                    </Button>{' '}
                                    <Button color='primary' onClick={() => this.setState({ showConfirmModal: true })}>
                                        Import
                                    </Button>
                                </>
                            )
                        ) : null
                    }
                >
                    {showGrid ? (
                        <>
                            <StyledDivider />
                            <GridView
                                id={`Upload_CSV_Preview_${previewKey}`}
                                key='Upload_CSV_Preview'
                                type={this.props.type}
                                columns={columns}
                                entitiesView
                                height='600px'
                                itemCount={gridData?.length || 0}
                                startIndex={0}
                                list={gridData}
                                isLoading={false}
                                readOnly
                            />
                        </>
                    ) : (
                        <FormGenerator
                            data={data}
                            components={this.buildComponents(data, isLoading)}
                            ref={this.formRef}
                            onChange={this.onFormChange}
                        />
                    )}
                </ModalDialog>
                {showDupIdsModal && (
                    <ConfirmationModal
                        header='Duplicated IDs were detected.'
                        message='Do you want to continue uploading anyway?'
                        onClose={this.backToUpload}
                        open={showDupIdsModal}
                        onConfirm={() => this.setState({ showDupIdsModal: false })}
                        confirmButtonText='Yes'
                        declineButtonText='No'
                        noCloseOnConfirm
                    />
                )}
                {showConfirmModal && (
                    <ConfirmationModal
                        header='Almost done'
                        message='You’re almost done, just click to confirm the import. This process will run as a background job.'
                        onClose={this.onCloseModal}
                        open={showConfirmModal}
                        onConfirm={this.onImportClick}
                        confirmButtonText='Confirm'
                        declineButtonText='Cancel'
                        noCloseOnConfirm
                    />
                )}
            </>
        );
    }
}

export default connect(null, {
    showToastr,
})(UploadDataModal);
