/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DotMenu from 'app/components/molecules/DotMenu/DotMenu';
import Loader from 'app/components/atoms/Loader/Loader';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { closeSidebar } from 'store/actions/sidebar/sidebarActions';
import { setActions, setSubTitle } from 'store/actions/sidebar/sidebarActions';

import { shrinkSidebar, expandSidebar } from 'store/actions/sidebar/sidebarActions';
import RelationAttributes from 'app/containers/Entities/Relationships/RelationAttributes';

class RelationSidebar extends PureComponent<Object, Object> {
    static propTypes = {
        title: PropTypes.string,
        isOpen: PropTypes.bool,
        relation: PropTypes.object,
        // from redux
        isLoading: PropTypes.bool,
    };

    constructor(props: Object) {
        super(props);
        this.initData();
    }

    componentDidUpdate(prevProps) {
        this.initData();
    }

    @bind
    @memoize()
    setActions(expanded) {
        this.props.setActions(this.dotMenu(expanded));
    }

    @bind
    @memoize()
    setSubTitle(name) {
        this.props.setSubTitle(name);
    }

    initData() {
        const { isOpen, isLoading, expanded } = this.props;
        if (!isOpen) {
            return;
        }
        this.setActions(expanded);
        if (isLoading) {
            return;
        }
        // this.setSubTitle(details.name);
    }

    @bind
    onDotMenuClick(title) {
        if (['Collapse', 'Expand'].includes(title)) {
            const { expanded, expandSidebar, shrinkSidebar } = this.props;
            const action = expanded ? shrinkSidebar : expandSidebar;
            action();
        }
    }

    @bind
    @memoize()
    dotMenu(expanded: boolean) {
        return (
            <DotMenu
                key={13}
                onItemClick={this.onDotMenuClick}
                items={[
                    { name: expanded ? 'Collapse' : 'Expand', icon: expanded ? 'arrow-collapse' : 'open-in-new' },
                ].filter(Boolean)}
            />
        );
    }

    render() {
        const { isLoading, title, relation, isOpen } = this.props;
        const isLoaded = !!relation;
        return (
            isOpen && (
                <>
                    {isLoading && <Loader absolute backdrop />}
                    {isLoaded && title === 'Attributes' && <RelationAttributes {...this.props} />}
                </>
            )
        );
    }
}

export default connect(
    (state: Object, ownProps: Object) => ({
        title: state.sidebar.title,
        isOpen: state.sidebar.isOpen,
        expanded: state.sidebar.expanded,
        windowWidth: state.global.window.width
    }),
    { shrinkSidebar, expandSidebar, closeSidebar, setActions, setSubTitle }
)(RelationSidebar);
