/* @flow */

import {
    SET_STROKE_COLOR,
    SET_STROKE_WIDTH,
    ADD_FEATURE_TO_HISTORY,
    REMOVE_FEATURE_FROM_HISTORY,
    SET_MAP_HAS_DRAWING,
} from 'store/actions/maps/situationalAwarenessActions';
import Immutable from 'app/utils/immutable/Immutable';

/**
 * The initial state on our reducer
 */
const initialState = {
    strokeColor: 'red',
    strokeWidth: 2,
    historyFeatures: [],
    hasDrawing: false,
};

/**
 * @param state - the current Redux state
 * @param action - the dispatched action
 * @returns the new Redux state
 */
const reducer = (state: Object = Immutable(initialState), action: Object) => {
    switch (action.type) {
        case SET_STROKE_COLOR:
            return Immutable({ ...state, strokeColor: action.payload });
        case ADD_FEATURE_TO_HISTORY:
            return Immutable({ ...state, historyFeatures: [...state.historyFeatures, action.payload]});
        case REMOVE_FEATURE_FROM_HISTORY:
            return Immutable({ ...state, historyFeatures:  state.historyFeatures.filter( (_,i) =>
                i !== state.historyFeatures.length-1)});
        case SET_STROKE_WIDTH:
            return Immutable({ ...state, strokeWidth: action.payload });
        case SET_MAP_HAS_DRAWING:
            return Immutable({ ...state, hasDrawing: action.payload });
        default:
            return state;
    }
};

export default reducer;
