/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DotMenu from 'app/components/molecules/DotMenu/DotMenu';
import Loader from 'app/components/atoms/Loader/Loader';
import history from 'store/History';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { closeSidebar } from 'store/actions/sidebar/sidebarActions';
import { mediaBreakpoints } from 'app/themes/breakpoints';
import { setActions, setSubTitle } from 'store/actions/sidebar/sidebarActions';
import RelationDefinitionAbout from 'app/containers/Entities/RelationDefinitions/RelationDefinitionAbout';

import { loadRelationDefinitionDetails } from 'store/actions/entities/relationshipsActions';
import { shrinkSidebar, expandSidebar } from 'store/actions/sidebar/sidebarActions';
import WorkInProgressImage from 'app/components/atoms/Image/WorkInProgressImage';
import { baseRoutes } from 'app/config/routesConfig';

const dataType = 'relationdefinition';

class RelationDefinitionSidebar extends PureComponent<Object, Object> {
    static propTypes = {
        id: PropTypes.string,
        details: PropTypes.object,
        title: PropTypes.string,
        isOpen: PropTypes.bool,
        // from redux
        isLoading: PropTypes.bool,
    };

    constructor(props: Object) {
        super(props);
        this.initData();
    }

    componentDidUpdate(prevProps) {
        this.initData();
    }

    @bind
    @memoize()
    setActions(expanded) {
        this.props.setActions(this.dotMenu(expanded));
    }

    @bind
    @memoize()
    setSubTitle(name) {
        this.props.setSubTitle(name);
    }

    initData() {
        const { id, isOpen, isLoading, details, expanded } = this.props;
        if (!isOpen) {
            return;
        }
        this.setActions(expanded);
        if (isLoading) {
            return;
        }
        const loaded = details || {};
        if (loaded.id === id) {
            this.setSubTitle(details.name);
            return;
        }
        this.props.loadRelationDefinitionDetails(id);
    }

    @bind
    onDotMenuClick(title) {
        if (['Collapse', 'Expand'].includes(title)) {
            const { expanded, expandSidebar, shrinkSidebar } = this.props;
            const action = expanded ? shrinkSidebar : expandSidebar;
            action();
        }
        if (title === 'Go to details') {
            this.goToDetails();
        }
    }

    goToDetails() {
        const { windowWidth, id, shrinkSidebar, closeSidebar } = this.props;
        history.push(`${baseRoutes[dataType]}s/${id}`);
        if (!windowWidth < mediaBreakpoints.sm) {
            shrinkSidebar();
        } else {
            closeSidebar();
        }
    }

    @bind
    @memoize()
    dotMenu(expanded: boolean) {
        return (
            <DotMenu
                key={13}
                onItemClick={this.onDotMenuClick}
                items={[
                    { name: expanded ? 'Collapse' : 'Expand', icon: expanded ? 'arrow-collapse' : 'open-in-new' },
                    { name: 'Go to details', icon: 'login-variant' }
                ].filter(Boolean)}
            />
        );
    }

    render() {
        const { isLoading, title, details, isOpen } = this.props;
        const isLoaded = !!details;
        return (
            isOpen && (
                <>
                    {isLoading && <Loader absolute backdrop />}
                    {isLoaded && title === 'About' && <RelationDefinitionAbout details={details} />}
                    {isLoaded && title === 'History' && <WorkInProgressImage />}
                </>
            )
        );
    }
}

export default connect(
    (state: Object, ownProps: Object) => ({
        details: state.entities.relationDefinitions.details.data,
        isLoading: state.entities.relationDefinitions.details.isLoading,
        isOpen: state.sidebar.isOpen,
        expanded: state.sidebar.expanded,
        windowWidth: state.global.window.width
    }),
    { shrinkSidebar, expandSidebar, loadRelationDefinitionDetails, closeSidebar, setActions, setSubTitle }
)(RelationDefinitionSidebar);
