import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled, { withTheme } from 'styled-components';
import { Divider, Grid, MdiIcon, Tooltip, IconButton, Button } from '@mic3/platform-ui';

import CodeEditor from 'app/components/organisms/TextEditor/CodeEditor';
import TextEditorHeaderMenu from 'app/components/organisms/TextEditor/TextEditorHeaderMenu';
import UploadButton from 'app/components/molecules/UploadButton/UploadButton';
import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import {
    write, focus, go,
    replaceSelection, getSelection, clearSelection, setSelection
} from 'app/utils/editor/editor';

import { markdownToHtml } from 'app/utils/markdown/markdownUtils';

require('codemirror/mode/markdown/markdown');


const StyledMdiIcon = styled(MdiIcon)`
    &:before{
        color: ${({theme})=> theme.material.colors.text.secondary};
    }
`;

const MenuBar = styled(Grid)`
    background-color: ${({ theme, backgroundcolor }) => backgroundcolor || theme.material.colors.background.dialog};
    align-items: center;
    padding: 2px 6px;
    & .MuiIcon-root {
        cursor: pointer;
    }
    & > .MuiGrid-item {
        border-right: 1px solid ${({theme})=> theme.material.colors.background.divider};
        padding: 5px 5px 5px;
    }
    & > .MuiGrid-item:last-child{
      border-right: 0;
    }

    & > .MuiGrid-item:nth-child(3) {
      & button {
          padding: 0;
          line-height: 0;
          top: 5px;
      }
    }

`;

const GridWrapper = styled(Grid)`
display: grid;
overflow-x: hidden;
grid-template-columns: ${({ view }) => {
    switch (view) {
        case 'editor':
            return '1fr 30px 0';
        case 'preview':
            return '0 30px 1fr';
        default:
            return '1fr 30px 1fr';
    }
}};
`;

const GridGrows = styled(Grid)`
    flex-grow: 1;
    max-height: calc(100vh - 125px);
    max-width: ${({ fullscreen }) => fullscreen ? 'calc(100vw - 65px)' : 'calc(100vw / 2 - 46px)'};
`;

const GridAutoWidth = styled(Grid)`
    width: auto !important;
`;

const MiddleGrid = styled(Grid)`
    height: calc(100vh - 125px);
    border-left: 1px solid ${({theme})=> theme.material.colors.background.divider};
    border-right: 1px solid ${({theme})=> theme.material.colors.background.divider};
`;

const Preview = styled.div`
    ${({ modal, fullscreen }) => modal && `
        max-height: calc(100vh - 125px);
        overflow: auto;
        flex-grow: 1;
        max-width: ${fullscreen ? 'calc(100vw - 65px)' : 'calc(100vw / 2 - 46px)'};
    `}
    background-color: ${({ disabled, theme }) => (disabled ? '' : theme.material.colors.background.paper)};
    color: ${({ disabled, theme }) => (!disabled ? theme.material.colors.text.primary : theme.material.colors.text.secondary)};
    white-space: break-spaces;
    word-break: break-word;
    padding: 1px 10px;
    & p:first-child {
      margin: 6px 0 -10px 0;
    }
    .checkbox {
      font-size: 24px;
      position: relative;
      top: 4px;
    }
    & h1 {
        font-size: 1.6rem;
    }
    & h2 {
        font-size: 1.4rem;
    }
    & h3 {
        font-size: 1.2rem;
    }
    & h4 {
        font-size: 1rem;
    }
    & h1,& h2,& h3,& h4 {
        border-bottom: 1px solid ${({theme})=> theme.material.colors.background.divider};
        margin: 10px 0 0 0;
    }
    & p, & blockquote p:first-child {
        margin: -10px 0;
    }
    & ul p, & ol p{
        margin: 0;
    }
    & ul, & ol {
        margin: 0;
        padding-inline-start: 20px;
    }
    & ul li, & ol li{
        line-height: 1.4;
        margin: -6px 0;
    }
    & code {
        font-size: 85%;
        font-family: monospace;
    }
    & pre {
        padding: 16px;
        margin: 0 !important;
        overflow: auto;
        background-color: ${({ theme }) => theme.material.colors.background.dialog};
        border-radius: 6px;
        line-height: 120%;
    }
    & img {
        max-width: 100%;
    }
    & blockquote {
        margin-left: 0;
        padding: 0 1em;
        color: ${({ theme }) => theme.material.colors.text.secondary};
        border-left: 0.25em solid ${({ theme }) => theme.material.colors.background.divider};;
    }

`;

class TextEditor extends PureComponent {

    constructor(props) {
        super(props);
        const view = props.isMobile ? 'editor':'3columns';
        this.previewWrapperRef = React.createRef();
        this.editorRef = React.createRef();
        this.options = { mode: 'markdown' };
        this.state = { preview: true, view, source: props.value };
    }

    componentDidUpdate(prevProps, prevState) {
        const { disabled, value } = this.props;
        if(prevProps.disabled !== disabled && disabled) {
            this.setState({ preview: disabled });
        }
        if(prevProps.value !== value) {
            this.setState({ source: value });
        }
    }

    @bind
    onScrollEditor(editor, data) {
        this.previewWrapperRef.current.scrollTop = editor.display.scroller.scrollTop;
    }

    @bind
    editor() {
        return this.editorRef.current.editor();
    }

    @bind
    async onHeader(level) {
        const editor = this.editor();
        const hashes = '####'.substring(0, level);
        const selected = getSelection(editor) || '';
        if (selected) {
            await replaceSelection(editor, `${hashes} ${getSelection(editor) || ''}`);
        } else {
            await write(editor, `${hashes} `, 'LineLeft');
            await go(editor, 'LineRight');
        }
        await focus(editor);
    }

    @bind
    async onBold() {
        const editor = this.editor();
        await replaceSelection(editor, `**${getSelection(editor) || ''}**`);
        await clearSelection(editor, { ch: -2 });
        await focus(editor);
    }

    @bind
    async onItalic() {
        const editor = this.editor();
        await replaceSelection(editor, `_${getSelection(editor) || ''}_`);
        await clearSelection(editor, { ch: -1 });
        await focus(editor);
    }

    @bind
    async onStrike() {
        const editor = this.editor();
        await replaceSelection(editor, `~~${getSelection(editor) || ''}~~`);
        await clearSelection(editor, { ch: -2 });
        await focus(editor);
    }

    @bind
    async onAddLink() {
        const editor = this.editor();
        const selected = getSelection(editor) || '';
        if (selected) {
            await replaceSelection(editor, `[](${selected})`);
            await setSelection(editor, 0, { ch: 1 });
        } else {
            await write(editor, '[](url)');
            await clearSelection(editor, { ch: -6 });
        }
        await focus(editor);
    }

    @bind
    async onQuote(level) {
        const editor = this.editor();
        let selected = getSelection(editor) || '';
        if (selected) {
            selected = selected.replace(/\n/g, '\n> ');
            await replaceSelection(editor, `> ${selected}`);
        } else {
            await write(editor, '> ', 'LineLeft');
            await go(editor, 'LineRight');
        }
        await focus(editor);
    }

    @bind
    async onCode() {
        const editor = this.editor();
        let selected = getSelection(editor) || '';
        const isMultiLine = selected && selected.includes('\n');
        if (isMultiLine) {
            selected = selected.endsWith('\n') ? selected : `${selected}\n`;
            await replaceSelection(editor, `\`\`\`\n${selected}\`\`\`\n`);
            await clearSelection(editor);
        } else {
            await replaceSelection(editor, `\`${selected}\``);
            await clearSelection(editor, { ch: -1 });
        }
        await focus(editor);
    }

    @bind
    async onAddImage() {
        const editor = this.editor();
        const selected = getSelection(editor) || '';
        await replaceSelection(editor, `![alt](${selected})`);
        await setSelection(editor, 3, { ch: 2 });
        await focus(editor);
    }

    @bind
    async onList() {
        const editor = this.editor();
        const selected = getSelection(editor) || '';
        if (selected) {
            const list = `- ${selected.replace(/\n/g, '\n- ')}`;
            await replaceSelection(editor, list);
            await clearSelection(editor);
        } else {
            await write(editor, '- ', 'LineLeft');
            await go(editor, 'LineRight');
        }
        await focus(editor);
    }

    @bind
    async onNumberedList() {
        const editor = this.editor();
        const selected = getSelection(editor) || '';
        if (selected) {
            let list = selected.split('\n');
            list = list.map((line, i) => `${i+1}. ${line}`).join('\n');
            await replaceSelection(editor, list);
            await clearSelection(editor);
        } else {
            await write(editor, '1. ', 'LineLeft');
            await go(editor, 'LineRight');
        }
        await focus(editor);
    }

    @bind
    async onChecklist() {
        const editor = this.editor();
        const selected = getSelection(editor) || '';
        if (selected) {
            const list = `- [ ] ${selected.replace(/\n/g, '\n- [ ] ')}`;
            await replaceSelection(editor, list);
            await clearSelection(editor);
        } else {
            await write(editor, '- [ ] ', 'LineLeft');
            await go(editor, 'LineRight');
        }
        await focus(editor);
    }

    @bind
    toggleOpen() {
        this.setState({ preview: false, source: this.props.value });
    }
    @bind
    toggleClose() {
        this.setState({ preview: true, source: '' });
    }

    @bind
    onPaste(editor, event) {
        const items = event.clipboardData && event.clipboardData.items;
        Array.from(items)
            .filter(item => item.type.startsWith('image'))
            .forEach((item) => {
                const file = item.getAsFile();
                if (file) {
                    this.uploadImageHandler(file);
                } else {
                    // eslint-disable-next-line
                    console.warn('The pasted image is not a file:', item.type, item);
                }
            });
    }

    @bind
    onUpload(files) {
        Array.from(files)
            .filter(({ type }) => type.startsWith('image'))
            .forEach((file) => {
                this.uploadImageHandler(file);
            });
    }

    @bind
    async uploadImageHandler(image) {
        if (!this.props.uploadImage) {
            return;
        }
        const result = await this.props.uploadImage(image);
        if (result && result.src) {
            const editor = this.editor();
            await write(editor, `\n![${result.alt || ''}](${result.src})\n`);
        }
    }

    @bind
    handleEditorFull() {
        if (this.state.view !== 'editor') {
            this.setState({ view: 'editor' });
        } else {
            this.setState({ view: '3columns' });
        }
    }

    @bind
    handlePreviewFull() {
        if (this.state.view !== 'preview') {
            this.setState({ view: 'preview' });
        } else {
            this.setState({ view: '3columns' });
        }
    }

    @bind
    handle3Columns() {
        this.setState({ view: '3columns' });
    }

    @bind
    handleOnChange(evnt) {
        const { plainTextEditor, onChange, name } = this.props;
        if(plainTextEditor && onChange) {
            onChange({ target: { name, value: evnt.target.value }});
        } else {
            this.setState({ source: evnt.target.value });
        }
    }

    @bind
    onApply() {
        const { name } = this.props;
        this.props.onChange({ target: { name, value: this.state.source }});
        this.toggleClose();
    }

    @bind
    @memoize()
    buildMenuBar(MenuBarProps, uploadImage, uploadAccept) {
        const { backgroundColor, restMenuBarProps } = MenuBarProps || {};
        return (
            <MenuBar container wrap="nowrap" className="no-print" justify="flex-start" backgroundcolor={backgroundColor?1:0} {...restMenuBarProps}>
                <GridAutoWidth item  container wrap="nowrap" alignItems="center">
                    <Tooltip arrow title={'Heading text'} placement="top-start">
                        <span><TextEditorHeaderMenu onHeader={this.onHeader} /></span>
                    </Tooltip>
                    <Tooltip arrow title={'Bold'} placement="top-start">
                        <span><MdiIcon name="format-bold" onClick={this.onBold} /></span>
                    </Tooltip>
                    <Tooltip arrow title={'Italic'} placement="top-start">
                        <span><MdiIcon name="format-italic" onClick={this.onItalic} /></span>
                    </Tooltip>
                    <Tooltip arrow title={'Strike-through'} placement="top-start">
                        <span><MdiIcon name="format-strikethrough" onClick={this.onStrike} /></span>
                    </Tooltip>
                    <Divider orientation="vertical" flexItem />
                </GridAutoWidth>
                <GridAutoWidth item  container wrap="nowrap" alignItems="center">
                    <Tooltip arrow title={'Hyperlink'} placement="top-start">
                        <span><MdiIcon name="link" onClick={this.onAddLink} /></span>
                    </Tooltip>
                    <Tooltip arrow title={'Quote'} placement="top-start">
                        <span><MdiIcon name="format-quote-close" onClick={this.onQuote} /></span>
                    </Tooltip>
                    <Tooltip arrow title={'Code block'} placement="top-start">
                        <span><MdiIcon name="code-tags" onClick={this.onCode} /></span>
                    </Tooltip>
                    <Tooltip arrow title={'Image'} placement="top-start">
                        <span><MdiIcon name="image" onClick={this.onAddImage} /></span>
                    </Tooltip>
                    {uploadImage && (
                        <Tooltip arrow title={'Upload'} placement="top-start">
                            <UploadButton
                                alt="Upload an image"
                                multiple
                                margin={false}
                                icon="upload"
                                onSelect={this.onUpload}
                                accept={uploadAccept}
                            />
                        </Tooltip>
                    )}
                </GridAutoWidth>
                <GridAutoWidth item container wrap="nowrap" alignItems="center">
                    <Divider orientation="vertical" flexItem />
                    <Tooltip arrow title={'Bullet list'} placement="top-start">
                        <span><MdiIcon name="format-list-bulleted" onClick={this.onList} /></span>
                    </Tooltip>
                    <Tooltip arrow title={'Number list'} placement="top-start">
                        <span><MdiIcon name="format-list-numbered" onClick={this.onNumberedList} /></span>
                    </Tooltip>
                    <Tooltip arrow title={'Check list'} placement="top-start">
                        <span><MdiIcon name="format-list-checks" onClick={this.onChecklist} /></span>
                    </Tooltip>
                </GridAutoWidth>
            </MenuBar>
        );
    }

    render() {
        const { disabled, uploadImage, uploadAccept, modalTitle, value, plainTextEditor, MenuBarProps, EditorProps } = this.props;
        const { source } = this.state;
        const { backgroundColor } = EditorProps || {};
        if (this.state.preview && !plainTextEditor) {
            return (
                <>
                    {!disabled && (
                        <MenuBar className="no-print" container justify="space-between" {...(MenuBarProps || {})}>
                            <Grid item>
                                <Tooltip arrow title={'Write mode'} placement="top-start">
                                    <span><StyledMdiIcon name="pencil" onClick={this.toggleOpen} /></span>
                                </Tooltip>
                            </Grid>
                        </MenuBar>
                    )}
                    <Preview disabled={disabled} dangerouslySetInnerHTML={markdownToHtml(value)} />
                </>
            );
        }
        const { view } = this.state;
        return !plainTextEditor ? (
            <ModalDialog
                title={modalTitle || 'Edit'}
                onClose={this.toggleClose}
                fullScreen
                actions={
                    <Button variant="text" onClick={this.onApply}>
                    apply
                    </Button>
                }
            >
                {this.buildMenuBar(MenuBarProps, uploadImage, uploadAccept)}
                <GridWrapper view={this.state.view}>
                    <GridGrows fullscreen={view === 'editor'?1:0}>
                        <CodeEditor
                            {...this.props}
                            backgroundcolor={backgroundColor}
                            onChange={this.handleOnChange}
                            value={source}
                            key={this.state.view}
                            ref={this.editorRef}
                            options={this.options}
                            onPaste={this.onPaste}
                            onScroll={this.onScrollEditor}
                        />
                    </GridGrows>
                    <MiddleGrid>
                        {view !== 'editor' && (
                            <IconButton size="small" onClick={this.handleEditorFull}>
                                <StyledMdiIcon name="keyboard-tab" />
                            </IconButton>
                        )}
                        {view !== 'preview' && (
                            <IconButton size="small" onClick={this.handlePreviewFull}>
                                <StyledMdiIcon name="eye" />
                            </IconButton>
                        )}
                        {view !== '3columns' && (
                            <IconButton size="small" onClick={this.handle3Columns}>
                                <StyledMdiIcon name="view-column" />
                            </IconButton>

                        )}
                    </MiddleGrid>
                    <Preview fullscreen={view === 'preview'} modal ref={this.previewWrapperRef} disabled={disabled} dangerouslySetInnerHTML={markdownToHtml(source)} />
                </GridWrapper>
            </ModalDialog>
        ) : (
            <>
                {this.buildMenuBar(MenuBarProps, uploadImage, uploadAccept)}
                <CodeEditor
                    {...this.props}
                    plainTextEditor={plainTextEditor}
                    backgroundcolor={backgroundColor}
                    onChange={this.handleOnChange}
                    value={source}
                    key={this.state.view}
                    ref={this.editorRef}
                    options={this.options}
                    onPaste={this.onPaste}
                    onScroll={this.onScrollEditor}
                />
            </>
        );
    }

}

export default connect((state=>({
    isMobile: state.global.isMobile
})))(withTheme(TextEditor));
