/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { loadProcesses } from 'store/actions/abox/processActions';
import { openProcessSidebar } from 'store/actions/abox/processSidebarActions';
import { typeTitlesMultiple } from 'app/config/typesConfig';

import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import EntitiesList from 'app/containers/Entities/EntitiesList/EntitiesList';
import filterDefinitions from 'app/containers/Abox/ProcessesList/ProcessFilters';
import PageTemplate from 'app/components/templates/PageTemplate';
import withPermissions from 'app/utils/withPermissions';
import { setDocumentTitle } from 'store/actions/app/appActions';
import { modulesAndPageTitles } from 'app/config/typesConfig';

const VIEW_ID = 'ProcessesListView';

const listType = 'process';
class ProcessesListView extends PureComponent<Object, Object> {
    static propTypes = {
        loadProcesses: PropTypes.func.isRequired,
        records: PropTypes.array,
        isLoading: PropTypes.bool,
        totalRecords: PropTypes.number
    };

    static defaultProps = {
        isLoading: false,
        VirtualListProps: {},
        FiltersProps: {}
    };

    searchBar = ['name', 'id'];
    defaultFilters = { status: 'is null', active: true };
    defaultOrder = [{ field: 'modifiedDate', direction: 'desc nulls last' }];


    columnDefinitions = [
        {
            text: 'Process Name',
            field: 'name',
        },
        {
            text: 'Process ID',
            field: 'id',
        },
        {
            text: 'Priority',
            field: 'primary.priority',
        },
        {
            text: 'Tasks',
            field: 'tasks',
            align: 'center',
            sortable : false,
        },
        {
            text: 'Status',
            field: 'type',
            renderer:  ({ value }) => (value === 'closedprocess' ? 'Closed' : 'Open'),
            align: 'center',
        },
        {
            text: 'Last modified date',
            field: 'modifiedDate',
        },
        {
            text: 'Started by',
            field: 'primary.variables.INITIATOR',
        },
        {
            text: 'Modified By',
            field: 'modifiedBy',
        },
        {
            text: 'Created date',
            field: 'createdDate',
        },
        {
            text: 'Classes',
            field: 'classes',
            align: 'center',
            onClickDisabled: true
        },
        {
            text: 'Relations',
            field: 'relations',
            align: 'center',
            onClickDisabled: true
        },
        {
            text: 'Sharing',
            field: 'sharing',
            width: '100px',
            onClickDisabled: true
        },
        {
            field: 'Actions',
            align: 'right',
            width: '100px',
            onClickDisabled: true  
        } 

    ];

    componentDidMount() {
        this.props.setDocumentTitle(modulesAndPageTitles.abox.processes);
    }

    render() {
        const { startIndex, isLoading, records, totalRecords } = this.props;
        return (
            <PageTemplate title={typeTitlesMultiple[listType]}>
                <ContentArea>
                    <EntitiesList
                        id={VIEW_ID}
                        type={listType}
                        headerTitle={typeTitlesMultiple[listType]}
                        records={records}
                        totalRecords={totalRecords}
                        startIndex={startIndex}
                        isLoading={isLoading}
                        loadData={this.props.loadProcesses}
                        filterDefinitions={filterDefinitions}
                        defaultOrder={this.defaultOrder}
                        defaultFilters={this.defaultFilters}
                        columnDefinitions={this.columnDefinitions}
                        disableAdd
                    />
                </ContentArea>
            </PageTemplate>
        );
    }
}

export default connect(
    (state: Object) => ({
        records: state.abox.list.records,
        totalRecords: state.abox.list.count,
        isLoading: state.abox.list.isLoading,
        startIndex: state.abox.list.startIndex
    }),
    { loadProcesses, openProcessSidebar, setDocumentTitle }
)(withPermissions(ProcessesListView, 'process'));
