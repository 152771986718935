/* @flow */
import React from 'react';

import TaskSidebar from 'app/containers/Sidebars/TaskSidebar';

import {
    buildSidebar as buildSidebarAction,
    closeSidebar as closeSidebarAction
} from 'store/actions/sidebar/sidebarActions';

import { openEntitySidebar } from 'store/actions/entities/entitySidebarActions';

export const openTaskSidebar = ({ id, title, reloadList, internal }) => async (dispatch, getState) => {
    return buildSidebarAction({
        title,
        content: (
            <TaskSidebar 
                id={id} 
                title={title} 
                reloadList={reloadList} 
                internal={internal} 
                openEntitySidebar={(...args) => openEntitySidebar(...args)(dispatch, getState)}
            />
        ),
        isOpen: true,
    })(dispatch, getState);
};

export const closeTaskSidebar = closeSidebarAction;
