/* @flow */

import { gql } from '@apollo/client';
import { eventTypeCommonQuery } from 'graphql/stream/event/eventTypeQueries';

export const addEventTypeMutation = gql`
    mutation addEventTypeMutation($record: EventTypeCreateInput!) {
        result: createEventType(record: $record) {
            ${eventTypeCommonQuery}
        }
    }
`;

export const eventDataAttributeAddMutation = gql`
    mutation eventDataAttributeAddMutation($record: EventDataAttributeCreateInput!) {
        result: eventDataAttributeAdd(record: $record) {
            id
        }
    }
`;

export const eventDataAttributeUpdateMutation = gql`
    mutation eventDataAttributeUpdateMutation($record: EventDataAttributeUpdateInput!) {
        result: eventDataAttributeUpdate(record: $record) {
            id
        }
    }
`;

export const eventDataAttributeRemoveMutation = gql`
    mutation eventDataAttributeRemoveMutation($id: ID!) {
        result: eventDataAttributeRemove(id: $id)
    }
`;

export const updateEventTypeMutation = gql`
    mutation updateEventTypeMutation($record: EventTypeUpdateInput!) {
        result: updateEventType(record: $record) {
            ${eventTypeCommonQuery}
        }
    }
`;
