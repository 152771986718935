import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Grid, Typography, Divider, List } from '@mic3/platform-ui';
import { connect } from 'react-redux';

import CollapsibleSubHeader from 'app/components/Designer/CollapsibleSubHeader';
import Sidebar from 'app/components/templates/Sidebar';
import DraggableElement from 'app/components/molecules/Dnd/DraggableElement';
import PipelineLetSidebarIcon from 'app/containers/Designer/Pipeline/Components/PipelineLetSidebarIcon';
import PipelineLeftSidebarItem from 'app/containers/Designer/Pipeline/Components/PipelineLeftSidebarItem';
import Tabs from 'app/components/organisms/Tabs/Tabs';
import { groupBy, get } from 'app/utils/lo/lo';
import { pipelinsComponents } from 'app/utils/designer/pipeline/pipelineDesignerUtils';
import { bind } from 'app/utils/decorators/decoratorUtils';
import { PIPELINE_DEFAULT_LEFT_SIDEBAR_WIDTH } from 'app/config/config';
import { resizeLeftPanel } from 'store/actions/leftPanel/leftPanelActions';

const SidebarStyled = styled(Sidebar)`
  ${({ isMobile }) => isMobile || 'padding-left: 16px;'}
`;
const TitleStyled = styled(Typography)`
    margin-top: 10px !important;
    display: block;
`;

const IconWrapperStyled = styled(Grid)`
    margin-top: -10px 0 !important;
`;

const SidebarContentStyled = styled(Grid)`
    height: calc(100vh - 105px);
    overflow: auto;
    padding: 0 16px 50px 0;
`;
const GraySpan = styled('span')`
    color: ${({theme})=> theme.material.colors.text.caption};
`;



class PipelineEditorLeftSidebar extends React.PureComponent {
    state = {
        leftPanelWidth: PIPELINE_DEFAULT_LEFT_SIDEBAR_WIDTH,
    };

    @bind
    handleResize(width: number) {
        this.setState({ leftPanelWidth: width });
    }

    render() {
        const { 
            onChangeTab, tabIndex, pipelineState, selectedElementId, pipelineStatus,
            onSelectNode, onDotMenuClick, pipelineMonitor, getCurrentConnectorStatus,
            resizeLeftPanel, isResizing
        } = this.props;
        const groupedComponents = groupBy(pipelinsComponents, 'group');
        const { leftPanelWidth } = this.state;
        return (
            <SidebarStyled
                isMobile={this.props.isMobile}
                sidebarWidth={leftPanelWidth}
                minSidebarWidth={PIPELINE_DEFAULT_LEFT_SIDEBAR_WIDTH}
                onResize={this.handleResize}
                isResizing={resizeLeftPanel}
                left
                hideClose
                withoutHeader
            >
                {!pipelineMonitor && (
                    <Tabs 
                        onChange={onChangeTab} 
                        activeTab={tabIndex} tabs={[
                            { label: 'Add Component' },
                            { label: 'Outcome'  },
                        ].filter(Boolean)} 
                        tinyTab 
                    />
                )}
                <Divider />

                <SidebarContentStyled>
                    {tabIndex === 0 && !pipelineMonitor ? Object.entries({
                        ...groupedComponents,
                        'annotation': [...groupedComponents['annotation'], ...groupedComponents['external']],
                        'external': undefined,
                    }).map(([group, comps], indx) => comps ? (
                        <Fragment key={`${group}-${indx}`}>
                            <TitleStyled variant="overline" >{group}</TitleStyled>
                            <Grid container key={indx} >
                                {comps.map((comp, i) => (
                                    <IconWrapperStyled item sm={4} xs={3} key={i}>
                                        <DraggableElement disabled={isResizing} key={i} index={i} element={comp}>
                                            <PipelineLetSidebarIcon
                                                {...comp}
                                                onClick={() => {}}
                                                index={i}
                                            />
                                        </DraggableElement>
                                    </IconWrapperStyled>
                                ))}
                            </Grid>
                            <Divider />
                        </Fragment>
                    ) : null).filter(Boolean) : (
                        Object.entries(groupBy(Object.values(get(pipelineState, 'drawflow.Home.data', {})), 'data.group')).map(([group, comps], indx) => (
                            <Fragment key={`${group}-${indx}`}>
                                <CollapsibleSubHeader 
                                    header={<>{group} <GraySpan>({comps.length})</GraySpan></>} 
                                    withoutHeaderDivider
                                    transparent 
                                    expanded
                                >
                                    <List key={indx} dense>
                                        {comps.map(({ data: comp, id }, i) => (
                                            <PipelineLeftSidebarItem
                                                getCurrentConnectorStatus={getCurrentConnectorStatus}
                                                node={comp}
                                                pipelineStatus={pipelineStatus}
                                                pipelineMonitor={pipelineMonitor}
                                                onDotMenuClick={onDotMenuClick}
                                                selected={Number(selectedElementId) === id}
                                                onClick={() => {}}
                                                index={i}
                                                key={i}
                                                nodeId={id}
                                                onSelectNode={onSelectNode}
                                            />
                                        ))}
                                    </List>
                                </CollapsibleSubHeader>
                                {/* <TitleStyled variant="overline" >{group}</TitleStyled> */}
                                <Divider />
                            </Fragment>
                        ))
                    )}
                </SidebarContentStyled>
            </SidebarStyled>
        );    
    }
};

export default connect((state) => ({
    isResizing:state.leftPanel.state.isResizing
}),
{
    resizeLeftPanel
})(PipelineEditorLeftSidebar);