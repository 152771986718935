import { gql } from '@apollo/client';

export default gql`
    query digitalTwinQuery($id: ID!, $type: String!, $digitalTwinId: ID, $getLegacyDigitalTwin: Boolean) {
        result: digitalTwin(id: $id, type: $type, digitalTwinId: $digitalTwinId, getLegacyDigitalTwin: $getLegacyDigitalTwin) {
            template
            # template configuration
            warnings
            context
            componentMapping
            valueMapping
            colorMapping
            actionMapping
            functions
            # script configuration
            entity
            hide
            style
            text
            action
        }
    }
`;
