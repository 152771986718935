import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { ListItem as BaseListItem, TextField, Button } from '@mic3/platform-ui';

import Filters from 'app/components/organisms/Filters/Filters';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import ButtonIcon from 'app/components/molecules/ButtonIcon/ButtonIcon';
import ListItem from 'app/components/molecules/List/ListItem';
import EntityListItem from 'app/containers/Entities/common/EntityListItem';
import Text from 'app/components/atoms/Text/Text';
import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import theme from 'app/themes/theme.default';

const borderColor = theme.material.colors.border.main;
const color = theme.material.colors.appNav.linkIconColor;

export const FormGeneratorStyled = styled(FormGenerator)`
    max-width: 390px;
    max-width: ${() => (!isMobile ? '390px' : 'calc(100vw - 52px)')};
    margin: ${() => (!isMobile ? '0 24px' : '0 auto')};
`;

export const StyledContentArea = styled(ContentArea)`
    margin-top: 30px;
`;

export const FiltersBoard = styled(Filters)`
    min-height: 0;
    padding: 0 8px;

    .filter-toolbar {
        > .MuiGrid-grid-xs-true,
        > .MuiIconButton-root {
            margin-top: 8px;
        }
    }

    .page-content {
        height: 100%;
    }
`;

export const ListItemStyled = styled(ListItem)`
    ${({ disabled }) => (disabled ? 'opacity: 0.6;' : '')}
    ${({ isLoadingBoard }) => (isLoadingBoard ? 'cursor: progress !important;' : '')}
`;

export const ListItemContainer = styled.div`
    width: ${() => (!isMobile ? '343px' : 'calc(100vw - 95px)')};
    padding-left: 10px;
`;

export const ButtonIconStyled = styled(ButtonIcon)`
    padding: 0.3rem;
    & *,
    & i:before {
        color: ${color} !important;
    }
    & span {
        font-size: 0.8rem !important;
        margin-left: 0.6rem;
    }
`;

export const Badge = styled.span`
    color: rgba(255, 255, 255, 0.5);
`;

export const BoardsContentStyled = styled.div`
    padding: 0 40px;
    height: calc(100% - 10px);
`;

export const BoardsColumnInner = styled.div`
    width: ${() => (!isMobile ? '360px' : 'calc(100vw - 80px)')};
    display: flex;
    flex-direction: column;
    margin-right: 40px;
    vertical-align: top;
    .MuiFormControl-root {
        margin: 0 !important;
    }

    border-radius: .3rem .3rem 0 0;
    border: 1px solid ${borderColor};
`;

export const AddColumnButton = styled(Button)`
    margin-left: auto !important;
    margin-right: 30px;
    font-size: 0.9em;
    font-weight: bold;
    color: ${color};
    border: 1px solid ${borderColor};
`;

export const AddTaskButton = styled.h3`
    display: flex;
    justify-content: flex-end;
    margin-bottom:12px;
    align-items: center;
    line-height: 22px;
    color: ${color};
    border-bottom: 1px solid ${borderColor};
    margin-top: 0;

    .column-label {
        margin: 0 auto;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding 10px 0;
    }

    .action-buttons i {
        margin: 0 5px;
    }

    .action-buttons {
        padding 10px 5px;
        border-left: 1px solid ${borderColor};
    }

`;

export const BoardsModalSearch = styled(TextField)`
    margin-bottom: 12px !important;
    .MuiFilledInput-input {
        padding: 10px 12px !important;
    }
    .MuiFilledInput-root {
        background-color: ${theme.material.colors.background.default} !important;
    }
`;

export const BoardsModalList = styled.div`
    div[class*='Container__ContainerStyle'] {
        background-color: transparent;
        margin-bottom: 0;
    }
    div[class*='VirtualList__TinyVirtual'] {
        padding-top: 0 !important;
        margin-top: 0.2em;
    }
    height: ${isMobile ? '60vh' : '50vh' } !important;
    padding-bottom: 36px;
`;

export const BoardsTaskListDroppable = styled.div`
    display: flex;
    flex-direction: column;
    height: calc(100% - 76px);
`;

export const BoardsTaskList = styled.div`
    overflow-y: auto;
`;

export const BoardItemBadge = styled.span`
    color: ${color};
`;

export const ButtonActions = styled.div`
    margin-top: 12px;
`;

export const StyledEntityListItem = styled(EntityListItem)`
    cursor: pointer;
    background: ${({ selected }) => (selected ? 'rgba(30, 168, 207, 0.2)' : theme.material.colors.background.default)};
`;

export const Title = styled(BaseListItem)`
    padding-bottom: 0 !important;
    margin-bottom: -5px;
`;

export const BoardsLandingSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    ${Text} {
        font-size: 14px;
        color: #818282;
    }
`;

export const BoardsDroppable = styled.div`
    height: 100%;
    display: inline-flex;
`;


export const StyledButton = styled(Button)`
    padding: 12px !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    width:200px;
    color:${({ theme }) => theme.base.linkColor};
    background:${({ theme }) => theme.material.colors.background.default};
`;
