/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, CircularProgress } from '@mic3/platform-ui';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { addEventType, loadEntityPrimaryIndexes } from 'store/actions/stream/eventsActions';
import history from 'store/History';
import { baseRoutes } from 'app/config/routesConfig';
import { normalizeEventTypeData, buildEventTypeComponents } from 'app/containers/Stream/Events/EventTypes/eventTypesUtils';

class AddEventType extends PureComponent<Object, Object> {
    static propTypes = {
        addEventType: PropTypes.func.isRequired,
    };
    state = {
        data: {},
    };

    formRef = React.createRef();

    @bind
    @memoize()
    buildComponents(lookupOptions, descriptorLookupOptions) {
        return buildEventTypeComponents(lookupOptions, descriptorLookupOptions, this.loadEntityData);
    }

    @bind
    handleChange(data) {
        this.setState({ data });
    }

    @bind
    @memoize()
    loadEntityData(entityId, name) {
        if (!name) return;
        if (!entityId) {
            return this.setState({ [name]: [] });
        }
        loadEntityPrimaryIndexes(entityId).then((selectedEntity) => {
            const { entityPrimaryIndexes } = selectedEntity || {};
            const options = (entityPrimaryIndexes || [])
                .filter(({ isUnique, state }) => isUnique && state === 'created')
                .map(({ primaryAttribute }) => ({ value: primaryAttribute, label: primaryAttribute }));
            this.setState({ [name]: options });
        });
    }

    @bind
    onFormSubmit(event: Event) {
        event.preventDefault();
        this.formRef.current.isValidForm().then(({ data, errors }) => {
            if (!errors) {
                this.props.addEventType(normalizeEventTypeData(data)).then((result) => {
                    !(result instanceof Error) && result.id && history.push(`${baseRoutes['eventtype']}/${result.id}`);
                });
            }
        });
    }

    @bind
    onClose() {
        const { onClose, reloadList } = this.props;
        if (onClose) {
            this.props.onClose();
        }
        if (reloadList) {
            this.props.reloadList();
        }
    }

    render(): Object {
        const { isLoading, onClose } = this.props;
        const { data, lookupOptions, descriptorLookupOptions } = this.state;
        return (
            <>
                <ModalDialog
                    title='Create a Event Type'
                    onClose={onClose}
                    actions={isLoading ? <CircularProgress size={24} color='primary' /> : <Button onClick={this.onFormSubmit}>Save</Button>}
                >
                    <FormGenerator
                        data={data}
                        onChange={this.handleChange}
                        components={this.buildComponents(lookupOptions, descriptorLookupOptions)}
                        ref={this.formRef}
                    />
                </ModalDialog>
            </>
        );
    }
}

export default connect(
    (state) => ({
        isLoading: state.stream.events.typeData.isLoading,
    }),
    {
        addEventType,
    }
)(AddEventType);
