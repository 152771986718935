/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Typography } from '@mic3/platform-ui';


import { loadWorkspaces, createWorkspace, updateWorkspace, removeWorkspaceUsers } from 'store/actions/admin/workspacesActions';
import WorkspacesListItem from './WorkspacesListItem';
import PageTemplate from 'app/components/templates/PageTemplate';
import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import EntitiesList from 'app/containers/Entities/EntitiesList/EntitiesList';
import { getStr } from 'app/utils/utils';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import DotMenu from 'app/components/molecules/DotMenu/DotMenu';
import { isEmpty } from 'app/utils/utils';
import { commonEntitiesListFilters } from 'app/utils/entity/entityUtils';
import { setDocumentTitle } from 'store/actions/app/appActions';
import { modulesAndPageTitles } from 'app/config/typesConfig';
import { getResourcesListAsPerType } from './WorkspaceResourcesList';

const VIEW_ID = 'EntitiesWorkspacesListView';

const ContentAreaStyled = styled(ContentArea)`
    overflow: hidden;
`;
const DarkLabel = styled(Typography)`
    color: ${({ theme }) => theme.material.colors.disabled.color} !important;
    font-size: 14px;
`;

export const getCountWithText = (records, singular, plural) => {
    const length = records?.length || 0;
    if (!length || !singular) {
        return '';
    }
    if(!plural) return length + (length === 1 ? ` ${singular}` : ` ${singular}s`);
    return length + ` ${length === 1 ? singular : plural}`;
};

class WorkspacesList extends PureComponent<Object, Object> {
    static propTypes = {
        isLoading: PropTypes.bool,
        records: PropTypes.array,
        startIndex: PropTypes.number,
        totalRecords: PropTypes.number,
        loadWorkspaces: PropTypes.func.isRequired,
        createWorkspace: PropTypes.func.isRequired,
        updateWorkspace: PropTypes.func.isRequired,
        removeWorkspaceUsers: PropTypes.func.isRequired
    };
    
    static defaultProps = {
        isLoading: false
       
    };

    listRef = React.createRef();
    

    filterDefinitions = [
        {
            field: 'name',
            type: 'text',
            properties: {
                label: 'Name',
                name: 'name',
                opSelector: true
            }
        },
        {
            field: 'id',
            type: 'uuid',
            properties: {
                label: 'ID',
                name: 'id',
                opSelector: true
            },
            condition: '='
        },
        {
            field: 'classes.id',
            type: 'classificationTypeahead',
            properties: {
                label: 'Classes',
                name: 'classesUri',
                filterBy: [
                    { field: 'active', op: '=', value: true },
                    { field: 'abstract', op: '=', value: false },
                    { field: 'primary', op: '=', value: false },
                    { field: 'applicableOn', op: 'contains', value: ['workspace'] }],
                multiple: true,
                valueField: 'id'
            },
            condition: 'in',
            sort: false
        },
        {
            field: 'active',
            type: 'typeahead',
            properties: {
                label: 'Status',
                name: 'active',
                options: [
                    { value: null, label: 'Any' },
                    { value: true, label: 'Active' },
                    { value: false, label: 'Inactive' }
                ]
            },
            sort: false,
            condition: '='
        },
        ...commonEntitiesListFilters
    ];
    defaultFilters = { active: true }

    columnDefinitions = [
        {
            text: 'Workspace Name',
            field: 'name',
        },
        {
            text: 'Workspace ID',
            field: 'id',
            onClickDisabled: true,
        },
        {
            text: 'Classes', 
            field: 'classes',
            onClickDisabled: true,
        },
        {
            text: 'Status',
            field: 'active',
        },
        {
            text: 'Last Modified Date',
            field: 'modifiedDate',
        },
        {
            text: 'Last Modification',
            field: 'modifiedBy'
        },
        {
            text: 'Created Date',
            field: 'createdDate'
        },
        {
            text: 'Created by',
            field: 'createdBy'
        },
        {
            text: 'Members',
            field: 'workspaceMembers',
            sortable: false,
            renderer: ({ record: { data } }) => {
                const { teams = [], user = [] } = data || {};
                let users = [...(user || [])];
                teams?.forEach(({ team }) => {
                    if (team?.users?.length) {
                        users = [ ...users, ...team.users];
                    }
                });
                return (
                    <DarkLabel>
                        {getCountWithText(users, 'user')}
                    </DarkLabel>
                );
            },
            width: 130
        },
        {
            text: 'Admin',
            field: 'owners',
            sortable: false,
            renderer: ({ value }) => {
                return (
                    <DarkLabel>
                        {getCountWithText(value, 'user')}
                    </DarkLabel>
                );
            }
        },
        {
            text: 'Resources',
            field: 'entityResources',
            sortable: false,
            renderer: ({ record: { data } }) => {
                const resources = getResourcesListAsPerType(data?.entityResources, 'primaryClass');
                return (
                    <DarkLabel>
                        {getCountWithText(resources,'resource')}
                    </DarkLabel>
                );
            }
        },
        {
            text: 'Permissions',
            field: 'workspacePermissions',
            sortable: false,
            renderer: ({ record: { data } }) => {
                const resources = getResourcesListAsPerType(data?.entityResources, 'permission');

                return (
                    <DarkLabel>
                        {getCountWithText(resources, 'permission')}
                    </DarkLabel>
                );
            },
            width: 130
        }
    ];

    componentDidMount() {
        this.props.setDocumentTitle(modulesAndPageTitles.adminConsole.workspaces);
    }

    @bind
    @memoize()
    getUsers(teams: Array<Object>) {
        if (!teams || isEmpty(teams)) {
            return [];
        }
        const obj = teams.find(t => t.team.isSystem);
        if (!obj || isEmpty(obj.team.users)) {
            return [];
        }
        const users = obj.team?.users?.map(({ user }) => user);
        return isEmpty(users) ? [] : users;
    }

    @bind
    onArchiveClick(data) {
        const { id, active } = data || {};
        this.props.updateWorkspace({ id, active: !active }, `Workspace have been ${active ? 'archived' : 'activated'}.`).then(this.reloadList);
    }

    @bind
    onLeaveClick(data) {
        const { loggedInUserId } = this.props;
        const { id } = data || {};
        return this.props.removeWorkspaceUsers(id, [loggedInUserId]).then(this.reloadList);
    }

    @bind
    @memoize()
    isUserExists(teams: Array<Object>) {
        const { loggedInUserId } = this.props;
        const users = this.getUsers(teams);
        return !!users.find(({ id }) => id === loggedInUserId);
    }

    @bind
    @memoize()
    dotMenu(data: Object) {
        return (
            <DotMenu
                key={13}
                items={this.actionsItems(data)}
            />
        );
    }

    @bind
    @memoize()
    actionsItems(data: Objet) {
        if (!data) {
            return [];
        }
        const { active, teams } = data || {};
        const isUserExists = this.isUserExists(teams);
        return [
            { name: `${active ? 'Archive' : 'Activate'} workspace`, onItemClick: () => this.onArchiveClick(data) },
            isUserExists && { name: 'Leave workspace', onItemClick: () => this.onLeaveClick(data) }
        ].filter(Boolean);
    }

    @bind
    reloadList() {
        const { current } = this.listRef || {};
        current && current.resetView();
    }


    @bind
    renderListComponent(props: Object) {
        return <WorkspacesListItem {...props} key={props.index} actions={this.dotMenu} />;
    }

    render() {
        const { records, isLoading, startIndex, totalRecords } = this.props;
        return (
            <>
                <PageTemplate title="Workspaces">
                    <ContentAreaStyled>
                        <EntitiesList
                            id={VIEW_ID}
                            ref={this.listRef}
                            type="workspace"
                            headerTitle="Workspaces"
                            records={records}
                            totalRecords={totalRecords}
                            startIndex={startIndex}
                            isLoading={isLoading}
                            renderListComponent={this.renderListComponent}
                            loadData={this.props.loadWorkspaces}
                            filterDefinitions={this.filterDefinitions}
                            defaultFilters={this.defaultFilters}
                            columnDefinitions={this.columnDefinitions}
                            moreActionItems={this.actionsItems}
                            downloadable
                            uploadable
                        />
                    </ContentAreaStyled>
                </PageTemplate>
            </>
        );
    }
}

export default connect(
    (state, props) => ({
        isLoading: state.admin.workspaces.list.isLoading,
        startIndex: state.admin.workspaces.list.startIndex,
        records: state.admin.workspaces.list.records,
        totalRecords: state.admin.workspaces.list.count,
        loggedInUserId: getStr(state.user, 'profile.id', ''),
    }),
    {
        loadWorkspaces,
        createWorkspace,
        updateWorkspace,
        removeWorkspaceUsers,
        setDocumentTitle
    }
)(WorkspacesList);
