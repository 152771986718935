/* @flow */

import { get } from 'app/utils/lo/lo';

export default {
    header: 'Multi-Instance',
    children: [
        {
            type: 'typeahead',
            properties: {
                label: 'Multi Instance Type',
                name: 'multiinstance.multiInstanceType',
                options: [{ label: 'None', value: 'none' }, { label: 'Parallel', value: 'false' }, { label: 'Sequential', value: 'true' }],
                defaultValue: 'none',
                clearable: false,
            }
        },
        {
            type: 'text',
            properties: {
                label: 'Element Variable (multi instance)',
                name: 'multiinstance.elementVariable',
                isVisible: data => get(data, 'multiinstance.multiInstanceType') !== 'none',
            }
        },
        {
            type: 'text',
            properties: {
                label: 'Collection (multi instance)',
                name: 'multiinstance.collection',
                isVisible: data => get(data, 'multiinstance.multiInstanceType') !== 'none',
            }
        },
        {
            type: 'text',
            properties: {
                label: 'Completion Condition (multi instance)',
                name: 'multiinstance.completionCondition',
                isVisible: data => get(data, 'multiinstance.multiInstanceType') !== 'none',
            }
        },
        {
            type: 'text',
            properties: {
                label: 'Cardinality (multi instance)',
                name: 'multiinstance.loopCardinality',
                isVisible: data => get(data, 'multiinstance.multiInstanceType') !== 'none',
            }
        },
    ]
};
