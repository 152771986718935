/* @flow */

import { combineReducers } from 'redux';
import users from './users/usersReducer';
import teams from './teams/teamsReducer';
import workspaces from './workspaces/workspacesReducer';
import license from './license/licenseReducer';

export default combineReducers<Object, Object>({
    users,
    teams,
    workspaces,
    license,
});
