/* @flow */

import React from 'react';
import { label } from 'app/utils/designer/form/settings/common/commonBase';
import { confirmationBuilder } from 'app/utils/designer/form/settings/common/commonGeneral';
import { onLoad, isVisible, isDisabled } from 'app/utils/designer/form/settings/common/commonEvents';
import { help } from 'app/utils/designer/form/settings/common/commonHelpers';
import Help from 'app/utils/designer/form/settings/common/Help';
import { flexGrow } from 'app/utils/designer/form/settings/common/commonStyles';

const panels = settingsValues => [
    {
        header: 'Base',
        children: [ label,
            {
                type: 'text',
                properties: {
                    label: 'Android URL',
                    name: 'properties.urls.androidUrl',
                    isVisible: true,
                    help:  <Help message='Android URL </br>
                                    calendar://{context.token}'/>,
                },
                constraints: {
                    required: true
                },
            },
            {
                type: 'text',
                properties: {
                    label: 'iOS URL',
                    name: 'properties.urls.iosUrl',
                    isVisible: true,
                    help:  <Help message='IOS URL </br>
                    iosAppUrl://fixed'/>,
                },
                constraints: {
                    required: true
                },
            },
            {
                type: 'text',
                properties: {
                    label: 'Windows URL',
                    name: 'properties.urls.windowsUrl',
                    isVisible: true,
                    help:  <Help message='Windows URL </br>
                    http://appdestination/?token={context.token}'/>,
                },
                constraints: {
                    required: true
                },
            },
        ],
    },
    {
        header: 'Events',
        expanded: true,
        children: [
            { type: 'boolean',
                properties: {
                    label: 'Validate form before action',
                    name: 'properties.validateBefore'
                } 
            },
            ...confirmationBuilder,
            onLoad,
            isVisible,
            settingsValues?.viewType !== 'class' && isDisabled
        ].filter(Boolean)
    },
    {
        header: 'Styles',
        children: [
            {
                type: 'typeahead',
                properties: {
                    label: 'Color',
                    name: 'properties.color',
                    valueField:'value',
                    clearable: false,
                    options: [
                        { value: 'primary' , label: 'primary' },
                        { value: 'secondary' , label: 'secondary' },
                    ]
                }
            },
            {
                type: 'typeahead',
                properties: {
                    label: 'Variant',
                    name: 'properties.variant',
                    valueField:'value',
                    clearable: false,
                    options: [
                        { value: 'text' , label: 'Text' },
                        { value: 'outlined' , label: 'Outlined' },
                        { value: 'contained' , label: 'Contained' },
                    ]
                }
            },
            { type: 'boolean', properties: { label: 'Full width', name: 'properties.fullWidth' } },
            {...flexGrow}
        ]
    },
    {
        header: 'Helpers',
        children: [ help ]
    },
];

export default {
    defaults: {
        actionType: 'function',
        fullWidth: true,
        variant: 'contained',
        color: 'primary',
        formFieldProps: { flexGrow: 1 }
    },
    panels: settingValues => panels(settingValues)
};
