/* @flow */

import { DOMParser } from '@xmldom/xmldom';

import { bind } from 'app/utils/decorators/decoratorUtils';
import AbstractDefinitionsBuilder from './AbstractDefinitionsBuilder/AbstractDefinitionsBuilder';
import { prettifyXml } from 'app/utils/designer/process/processDesignerUtils';

class MessageDefinitionBuilder extends AbstractDefinitionsBuilder {

    @bind
    deNormalizeValues(definition: Array<Object>): Array<Object> {
        const doc = new DOMParser().parseFromString(definition);
        const messages =  doc.getElementsByTagName('message');
        return Array.from(messages).map((elem) => {
            return {
                id: elem.getAttribute('id'),
                name: elem.getAttribute('name'),
            };
        });
    }

    @bind
    normalizeValues(value: Array<Object>): Array<any> {
        const { value: definition } = this.props;
        const doc = new DOMParser().parseFromString(definition);
        const messages =  doc.getElementsByTagName('message');
        const process =  doc.getElementsByTagName('process')[0] || doc.getElementsByTagName('bpmn:process')[0];
        const definitions =  doc.getElementsByTagName('definitions')[0];
        const messagesNew = Array.from(value).map(
            elem => {
                const el = new DOMParser().parseFromString(`<message id="${elem.id}" name="${elem.name}" />`);
                return el.getElementsByTagName('message')[0];
            }
        );
        Array.from(messages).forEach((elem) => {
            definitions.removeChild(elem);
        });
        messagesNew.forEach((elem) => {
            definitions.insertBefore(elem, process);
        });
        return prettifyXml(String(doc));
    }
};

export default MessageDefinitionBuilder;
