import React from 'react';
import styled from 'styled-components';

import { FormLabel, FormHelperText } from '@mic3/platform-ui';

const Box = styled.div`
flex-grow: 0.65;
margin-top: 12px;
width: 100%;
padding: 4px 0;
${({ disabled }) => disabled ? '' : 'background-color: transparent;' }
${({ height}) => height && `height: ${height};`}
`;

const LabelStyled = styled(FormLabel)`
font-size: 12px !important;
padding-left: 8px !important;
${({ error }) => error ? 'color: #f44336;' : '' }
`;

const HelperTextStyled = styled(FormHelperText)`
padding-left: 8px !important;
`;

const HrStyled = styled.hr`
padding-left: 8px !important;
width: 100%;
${({ error }) => error ? 'border: 1px solid #f44336;' : '' }
`;

const ErrorStyled = styled.div`
padding-left: 8px !important;
color: #f44336;
font-size: 12px !important;;
`;

export const materialInput = Component => (props) => {
    const { label, error, helperText, disabled, required, boxProps, ...componentProps } = props || {};
    return (
        <Box container disabled={disabled} {...boxProps}>
            {label &&
               <LabelStyled disabled={disabled} required={required} error={!!error}>
                   {label}
               </LabelStyled>
            }
            <Component disabled={disabled} {...componentProps} />
            {helperText &&
               <HelperTextStyled disabled={disabled} required={required} error={error}>
                   {helperText}
               </HelperTextStyled>
            }
            <HrStyled error={error}/>
            { error && <ErrorStyled>{error}</ErrorStyled> }
        </Box>
    );
};
