/* @flow */

import { loadData } from 'app/utils/redux/action-utils';
import autocompleteQuery from 'graphql/entities/processes/processesAutocompleteQuery';
import processLoadAvatarQuery from 'graphql/entities/processes/processLoadAvatarQuery';
import { getAvatar } from  'app/utils/avatar/avatar';

export const LOAD_PROCESSES_AUTOCOMPLETE_STARTED: string = '@@affectli/entities/processes/LOAD_PROCESSES_AUTOCOMPLETE_STARTED';
export const LOAD_PROCESSES_AUTOCOMPLETE: string = '@@affectli/entities/processes/LOAD_PROCESSES_AUTOCOMPLETE';

export const LOAD_AVATAR_STARTED = '@@affectli/entities/processes/LOAD_AVATAR_STARTED';
export const LOAD_AVATAR = '@@affectli/entities/processes/LOAD_AVATAR';

export const loadAvatar = (id, additionalQueryProps) => (dispatch: Function, getState: Function) => {
    let avatar = getAvatar(getState(), 'process', id);
    if (avatar && (avatar.isLoading || avatar.loadedAt > Date.now() - (60*60*1000))) {
        // if we are loding the avatar or it is already loaded and it is stored less then 1 hour ago
        // we return the data in the state without calling the service
        return Promise.resolve(avatar);
    }
    avatar = loadData(LOAD_AVATAR_STARTED, LOAD_AVATAR, processLoadAvatarQuery(additionalQueryProps))({ id, filterBy: [{ field: 'id', op: '=', value: id }] })(dispatch, getState);
    return avatar && avatar[0];
};

export const loadProcessesAutocomplete = loadData(
    LOAD_PROCESSES_AUTOCOMPLETE_STARTED,
    LOAD_PROCESSES_AUTOCOMPLETE,
    autocompleteQuery
);
