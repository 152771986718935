/* @flow */

import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Avatar, Grid, Typography } from '@mic3/platform-ui';
import { getAttachmentUrl } from 'app/utils/attachments/attachmentsUtils';
import { get } from 'app/utils/lo/lo';


const StyledGrid = styled(Grid)`
    ${({ opensidebar }) => (opensidebar ? 'cursor: pointer' : '')}
`;

const AvatarStyled = styled(Avatar)`
    width: 24px !important;
    height: 24px !important;
    font-size: 1rem !important;
    margin-right: 16px;
`;

const TypographyStyled = styled(Typography)`
    font-size: 14px !important;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: ${({theme})=> theme.material.colors.text.primary};
`;

class GridEntityAssignee extends PureComponent<Object, Object> {

    render() {
        const { data } = this.props;

        const { name, assignee } = data || {};
        const assigneeImage = assignee?.image && getAttachmentUrl(get(assignee, 'id'), 'user', assignee.image);
        return (
            <StyledGrid container wrap="nowrap" alignItems="center">
                <AvatarStyled 
                    initials={get(assignee, 'name') || name || 'No Name'}
                    src={assigneeImage}
                />
                <TypographyStyled>{assignee.name}</TypographyStyled>
            </StyledGrid>
        );
    }
}

export default GridEntityAssignee;
