/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Typography } from '@mic3/platform-ui';
import styled, { withTheme } from 'styled-components';

import Loader from 'app/components/atoms/Loader/Loader';
import { compile, render } from 'app/utils/template/template';
import { markdownToHtml } from 'app/utils/markdown/markdownUtils';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';

const PrintableBodyWrapper = styled.div`
  overflow: scroll;
  @media print {
    display: blok;
    width: auto;
    height: auto;
    overflow: visible;
  }
`;
const PrintableA4 = styled.div`
    background: white;
    color: black;
    display: block;
    margin: 0 auto;
    margin-bottom: 0.5cm;
    width: 21cm;
    min-height: 29.7cm;
    @media screen {
      box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);
      padding: 1cm;
    }
`;
const StageWrapperStyled = styled(Grid)`
    margin: 16px;
    max-width: 1024px;
`;
const StageTypographyStyled = styled(Typography)`
    padding-left: 8px;
`;

class PrintTemplateLayoutPreview extends PureComponent<Object, Object> {
    static propTypes: Object = {
        details: PropTypes.object.isRequired,
        uploadImage: PropTypes.func,
        isLoading: PropTypes.bool,
        canEdit: PropTypes.bool,
        fromSidebar: PropTypes.bool,
        imageData: PropTypes.object,
        userProfile: PropTypes.object,
        reloadList: PropTypes.func
    };

    parseTemplate(text, context) {
        try {
            return render(compile(text), context);
        } catch (err) {
            return err;
        }
    }

    @bind
    @memoize()
    buildHtml(context, text){
        const buildedText = context ? this.parseTemplate(text, context) : text;
        return markdownToHtml(buildedText);
    }

    @bind
    @memoize()
    printableA4(context, text) {
        if(!context) {
            return <PrintableA4 />;
        }
        return (
            <PrintableBodyWrapper className="page-content">
                {!Array.isArray(context) ? <PrintableA4>
                    <div dangerouslySetInnerHTML={this.buildHtml(context, text)} />
                </PrintableA4> : context.map(ctx => (
                    <>
                        <PrintableA4>
                            <div dangerouslySetInnerHTML={this.buildHtml(ctx, text)} />
                        </PrintableA4>
                        <div className="pagebreak"/>
                    </>
                ))}
            </PrintableBodyWrapper>
        );
    }

    render(): Object {
        const { text, handleShowPreviwSettings, context, isLoading, loadingStage } = this.props;
        return (
            <>
                {!isLoading && (
                    <Grid container justify="center" className="no-print">
                        <Button onClick={handleShowPreviwSettings} variant="text">Print Parameters</Button>
                    </Grid>
                )}
                {isLoading && (
                    <StageWrapperStyled container justify="flex-start">
                        <Grid item><Loader size={16} /></Grid><StageTypographyStyled>{loadingStage}</StageTypographyStyled>
                    </StageWrapperStyled>
                )}
                {this.printableA4(context, text)}
            </>
        );
    }
}


export default withTheme(PrintTemplateLayoutPreview);
