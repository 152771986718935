// @flow
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { get } from 'app/utils/lo/lo';
import EntitySidebar from 'app/containers/Sidebars/EntitySidebar';
import { setSubTitle } from 'store/actions/sidebar/sidebarActions';

class MapEntityDetails extends PureComponent<Object, Object> {
    static propTypes = {
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        type: PropTypes.string.isRequired,
        map: PropTypes.object.isRequired,
    };

    componentDidUpdate(prevProps) {
        const { details } = this.props;
        this.setSubTitle(details?.name);
    }

    @bind
    @memoize()
    setSubTitle(name) {
        this.props.setSubTitle(name);
    }

    @bind
    async reloadDetails() {
        const { map } = this.props;
        return map && map.refreshMapLayers();
    }

    render() {
        const { id, type } = this.props;
        return <EntitySidebar id={id} type={type} reloadList={this.reloadDetails} />;
    }
}

export default connect(
    (state) => ({
        details: get(state.entities.sidebar, 'details.data', {}),
    }),
    {
        setSubTitle,
    }
)(MapEntityDetails);
