/* @flow */

import Immutable from 'app/utils/immutable/Immutable';
import { keepDataAtStart, loadDataReducer } from 'app/utils/redux/reducer-utils';
import { combineReducers } from 'redux';

import {
    BUILD,
    OPEN,
    CLOSE,
    EXPAND,
    SHRINK,
    SET_TITLE,
    SET_SUBTITLE,
    SET_ACTIONS,
    SET_CONTENT,
    SET_FILTERBY,
    SET_SHOW_BACK,
    RESIZING,
    LOAD_LEFT_PANEL_BOARDS,
    LOAD_LEFT_PANEL_BOARDS_STARTED,
    LOAD_LEFT_PANEL_ENTITY_TYPES_STARTED,
    LOAD_LEFT_PANEL_ENTITY_TYPES,
    LOAD_LEFT_PANEL_PROCESS_DEFINITIONS,
    LOAD_LEFT_PANEL_PROCESS_DEFINITIONS_STARTED,
    LOAD_LEFT_PANEL_EVENT_TYPES_STARTED,
    LOAD_LEFT_PANEL_EVENT_TYPES,
    LOAD_LEFT_PANEL_BETA_BOARDS_STARTED,
    LOAD_LEFT_PANEL_BETA_BOARDS,
    SET_LEFT_PANEL_SORT_BY

} from 'store/actions/leftPanel/leftPanelActions';

const initialState = {
    isOpen: false,
    isResizing: false,
    expanded: false,
    title: '',
    subTitle: '',
    content: null,
    actions: null,
    filterBy: {},
    sortBy:{}
};

const leftPanelReducer = (state: Object = initialState, action: Function) => {
    const { type, payload } = action;
    switch (type) {
        case BUILD:
            return Immutable({ ...initialState, ...state, ...payload,
                filterBy: {
                    ...initialState.filterBy,
                    ...state.filterBy,
                    ...payload.filterBy
                }
            });
        case OPEN:
            return Immutable({ ...state, isOpen: true });
        case CLOSE:
            return Immutable({ ...state, isOpen: false, showBack: true });
        case EXPAND:
            return Immutable({ ...state, expanded: true });
        case SHRINK:
            return Immutable({ ...state, expanded: false });
        case SET_TITLE:
            return Immutable({ ...state, title: payload });
        case SET_SUBTITLE:
            return Immutable({ ...state, subTitle: payload });
        case SET_ACTIONS:
            return Immutable({ ...state, actions: payload });
        case SET_CONTENT:
            return Immutable({ ...state, content: payload });
        case SET_FILTERBY:
            return Immutable({ ...state, filterBy: {
                ...state.filterBy,
                ...payload
            } });
        case SET_SHOW_BACK:
            return Immutable({ ...state, showBack: payload });
        case RESIZING:
            return Immutable({ ...state, isResizing: payload });
        case SET_LEFT_PANEL_SORT_BY:
            return Immutable({ ...state, sortBy: {...state.sortBy, ...payload} });
        default:
            return state;
    }
};

export default combineReducers({
    state:leftPanelReducer,
    boards:loadDataReducer(LOAD_LEFT_PANEL_BOARDS_STARTED, LOAD_LEFT_PANEL_BOARDS, keepDataAtStart),
    betaBoards:loadDataReducer(LOAD_LEFT_PANEL_BETA_BOARDS_STARTED, LOAD_LEFT_PANEL_BETA_BOARDS, keepDataAtStart),
    entityTypes:loadDataReducer(LOAD_LEFT_PANEL_ENTITY_TYPES_STARTED, LOAD_LEFT_PANEL_ENTITY_TYPES, keepDataAtStart),
    eventTypes:loadDataReducer(LOAD_LEFT_PANEL_EVENT_TYPES_STARTED, LOAD_LEFT_PANEL_EVENT_TYPES, keepDataAtStart),
    processDefinitions:loadDataReducer(LOAD_LEFT_PANEL_PROCESS_DEFINITIONS_STARTED, LOAD_LEFT_PANEL_PROCESS_DEFINITIONS, keepDataAtStart),
});