/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { ConfirmationModal } from '@mic3/platform-ui';

import DotMenu from 'app/components/molecules/DotMenu/DotMenu';
import Loader from 'app/components/atoms/Loader/Loader';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { closeSidebar } from 'store/actions/sidebar/sidebarActions';
import { loadSidebarEventDetails } from 'store/actions/stream/eventsActions';
import { setActions, setSubTitle } from 'store/actions/sidebar/sidebarActions';
import EventAbout from 'app/containers/Stream/Events/EventAbout';
import { shrinkSidebar, expandSidebar } from 'store/actions/sidebar/sidebarActions';
import history from 'store/History';
import Icon from 'app/components/atoms/Icon/Icon';
import { copyToClipboard } from 'app/utils/classification/classificationUtils';
import { showToastr } from 'store/actions/app/appActions';
import { updateEventStatus } from 'store/actions/stream/eventsActions';
import { getPermissions } from 'app/config/rolesConfig';
import { openEntitySidebar } from 'store/actions/entities/entitySidebarActions';


class EventSidebar extends PureComponent<Object, Object> {
    static propTypes = {
        id: PropTypes.string,
        time: PropTypes.string,
        title: PropTypes.string,
        isOpen: PropTypes.bool,
        // from redux
        isLoading: PropTypes.bool,
        details: PropTypes.object
    };

    constructor(props: Object) {
        super(props);
        this.initData();
    }

    state = {
        isConfirmOpen: false,
        confirmProps: {},
        confirmAction: null
    };

    componentDidUpdate(prevProps) {
        this.initData();
    }

    @bind
    setActions(expanded, sidebarTitle, details, pathname) {
        this.props.setActions(this.dotMenu(expanded, sidebarTitle, details, pathname));
    }

    @bind
    @memoize()
    setSubTitle(name) {
        this.props.setSubTitle(name);
    }

    initData() {
        const { id, time, isOpen, isLoading, details, expanded, mapPin, sidebarTitle, location } = this.props;
        if (!isOpen) {
            return;
        }
        this.setActions(expanded, sidebarTitle, details, location.pathname);
        if (isLoading) {
            return;
        }
        const loaded = details || {};
        if (!mapPin && loaded.id === id && loaded.time === time) {
            this.setSubTitle(details.name);
            return;
        }
        this.props.loadSidebarEventDetails(id, time);
    }

    @bind
    onDotMenuClick(title, details) {
        if (['Collapse', 'Expand'].includes(title)) {
            const { expanded, expandSidebar, shrinkSidebar } = this.props;
            const action = expanded ? shrinkSidebar : expandSidebar;
            action();
        }

        switch(title){
            case 'Event Entity Relations':
                this.navigateToEventEntityRelations();
                break;
            case 'Event Relations':
                this.navigateToEventRelations();
                break;
            case 'Device Relationships':
                this.navigateToDeviceRelations();
                break;
            case 'A-Live':
                const { id, type } = details?.device || {};
                this.props.openEntitySidebar({title, id, type});
                break;
            case 'Copy link':
                this.copyLinkToClipBoard(details);
                break;
            case 'Acknowledged':
                this.handleAction('ACK');
                break;
            case 'Discard':
                this.handleAction('DIS');
                break;
            case 'Go to details':
                history.push(`/events/${details?.id}/${details?.time}`);
                break;
        }
    }

    @bind
    @memoize()
    dotMenu(expanded: boolean, sidebarTitle, details, pathname) {
        const { device, status } = details || {};
        const { id, role } = device || {};
        const permissions = getPermissions(role);
        return (
            <DotMenu
                key={13}
                onItemClick={(title) => this.onDotMenuClick(title, details)}
                items={[
                    !pathname.endsWith('/about') && sidebarTitle !== 'About' && { name: 'About', icon: 'information-outline' },
                    { name: 'Event Entity Relations', icon: 'relationships', iconType: 'af' },
                    { name: 'Event Relations', icon: 'relationships', iconType: 'af' },
                    id && { name: 'Device Relationships', icon: 'relationships', iconType: 'af' },
                    id && permissions?.canComment && { name: 'A-Live', icon: 'messenger', iconType: 'af' },
                    { name: 'divider' },
                    { name: 'Copy link', iconComponent: <Icon name="link" /> },
                    { name: 'divider' },
                    { name: 'Acknowledged', iconComponent: <Icon name="check" />, disabled: status === 'ACK' },
                    { name: 'Discard', iconComponent: <Icon name="close" />, disabled: status === 'DIS' },
                    { name: 'divider' },
                    { name: expanded ? 'Collapse' : 'Expand', icon: expanded ? 'arrow-collapse' : 'open-in-new' },
                    { name: 'Go to details', icon: 'exit-to-app' },
                ].filter(Boolean)}
            />
        );
    }

    @bind
    navigateToEventEntityRelations() {
        const { id, time } = this.props.details || {};
        history.push(`/events/${id}/${time}/entity-relations`);
    }

    @bind
    navigateToEventRelations() {
        const { id, time } = this.props.details || {};
        history.push(`/events/${id}/${time}/event-relations`);
    }

    @bind
    navigateToDeviceRelations() {
        const { id, type } = this.props.details?.device || {};
        history.push(`/entities/${type}/${id}/relationships`);
    }

    @bind
    copyLinkToClipBoard(details) {
        const { id, time }= details;
        copyToClipboard(`${window.location.origin}/#/events/${id}/${time}`)
            .then(() => {
                this.props.showToastr({
                    severity: 'success',
                    detail: 'Event Link copied to clipboard'
                });
            }).catch(() => {
                this.props.showToastr({
                    severity: 'error',
                    detail: 'Event Link could not copied to clipboard'
                });
            });
    }

    @bind
    toggleConfirmationModal() {
        this.setState(prevState => ({ isConfirmOpen: !prevState.isConfirmOpen }));
    }

    @bind
    action(event, status) {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        const { id, reloadList, details } = this.props;
        const { time } = details || {};
        this.props.updateEventStatus({ status, id, time }).then(() => {
            reloadList && reloadList();
            this.props.closeSidebar();
        });
    }

    @bind
    handleAction(status) {
        this.setState({
            isConfirmOpen: true,
            confirmProps: {
                message: status === 'ACK' ? 'Do you want to acknowledge event?' : 'Do you want to discard event?',
                declineButtonText: 'No',
                confirmButtonText: 'Yes'
            },
            confirmAction: event => this.action(event, status)
        });
    }

    render() {
        const { isLoading, title, details, isOpen } = this.props;
        const isLoaded = details;
        return (
            isOpen && (
                <>
                    {isLoading && <Loader absolute backdrop />}
                    {isLoaded && title === 'About' && (
                        <EventAbout details={details} />
                    )}
                    <ConfirmationModal
                        {...this.state.confirmProps}
                        onClose={this.toggleConfirmationModal}
                        open={this.state.isConfirmOpen}
                        onConfirm={this.state.confirmAction}
                    />
                </>
            )
        );
    }
}

export default connect(
    (state: Object, ownProps: Object) => ({
        details: state.stream.events.sidebar.details.data,
        isLoading: state.stream.events.sidebar.details.isLoading,
        userProfile: state.user.profile,
        isOpen: state.sidebar.isOpen,
        expanded: state.sidebar.expanded,
        windowWidth: state.global.window.width,
        actions: state.sidebar.actions,
        sidebarTitle: state.sidebar.title,
    }),
    {
        shrinkSidebar,
        expandSidebar,
        loadSidebarEventDetails,
        closeSidebar,
        setActions,
        setSubTitle,
        showToastr,
        updateEventStatus,
        openEntitySidebar }
)(withRouter(EventSidebar));
