// @flow

import React, { memo, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Typography, Collapse, Grid, Button, ClickAwayListener } from '@mic3/platform-ui';

const GridStyled = styled(Grid)`
    word-break: break-all;
    position:relative;
`;

const OverlayWrapper = styled.div`
    background-image: linear-gradient(to bottom,transparent , #1c263bcc);
    position: absolute;
    width: -webkit-fill-available;
    height: 50px;
    right: 0;
    bottom:0;
    justify-content: center;
    display: flex;
    align-items: center;
`;

const StyledButton =  styled(Button)`
    width: 116px;
`;

const ButtonWrapper = styled.div`
    justify-content: center;
    display: flex;
    align-items: center;
    width: -webkit-fill-available;
`;

const CollapsibleText = ({ text, expanded: expandedDefault }: Object) => {
    const [expanded, setExpanded] = useState(!!expandedDefault);
    const [showExpand, setShowExpand] = useState(false);
    const elementRef = useRef(null);

    useEffect(() => {
        setShowExpand(elementRef.current.clientHeight > 244);
    },[showExpand]);

    return (
        <ClickAwayListener onClickAway={() => setExpanded(false)}>
            <GridStyled container direction="column" justify="center">
                <Collapse in={expanded} collapsedHeight={244}>
                    <Typography ref={elementRef}>{text}</Typography>
                </Collapse>
                {!expanded && showExpand &&
                    <OverlayWrapper>
                        <StyledButton onClick={() => setExpanded(true)}> Read More </StyledButton>
                    </OverlayWrapper>
                }
                {expanded && text &&
                     <ButtonWrapper>
                         <StyledButton onClick={() => setExpanded(false)}> Read Less </StyledButton>
                     </ButtonWrapper>
                }
            </GridStyled>
        </ClickAwayListener>
    );
};

CollapsibleText.propTypes = {
    text: PropTypes.string,
    expanded: PropTypes.bool
};

export default memo(CollapsibleText);
