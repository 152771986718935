import React, { PureComponent, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import useScrollOnDrag from 'react-scroll-ondrag';

import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import ClickHandler from 'app/utils/html/ClickHandler';

const ContainerStyle = styled.div`
overflow: auto;
height: 100%;
padding: 16px;
${({ backgroundColor }) => backgroundColor ? `background-color: ${backgroundColor};` : ''}
`;

const Container = (props) => {
    const ref = useRef();
    const { events } = useScrollOnDrag(ref);
    return <ContainerStyle {...events} {...props} ref={ref} />;
};

const SvgContainer = styled.div`

width: ${({ zoom }) => `${zoom}%`};
margin: auto;
${
    /* hide the components */
    (props) => {
        return (props.hiddenList || []).map((id) => {
            return `[id='${id}'] { display: none; }`;
        }).join('\n');
    }
}
${
    /* add colors to the components */
    (props) => {
        const style = Object.entries(props.colors || {}).map(([id, color]) => {
            return `
            [id='${id}'] {
              color: ${color} !important;
              fill: ${color} !important;
              stroke: ${color} !important;
            }`;
        }).join('\n');
        return style;
    }
}
${
    /* highlight the components */
    (props) => {
        const style = (props.highlight || []).map((id) => {
            return `
            [id='${id}'], [id='${id}'] * {
               color: #f4bd00 !important;
               fill: #f4bd00 !important;
               stroke: #f4bd00 !important;
            }`;
        }).join('\n');
        return style;
    }
}

`;


class DigitalTwin extends PureComponent {

    static propTypes = {
        template: PropTypes.string,
        hiddenList: PropTypes.arrayOf(PropTypes.string),
        values: PropTypes.object,
        colors: PropTypes.object,
        actions: PropTypes.object,
        onAction: PropTypes.func.isRequired,
        highlight: PropTypes.arrayOf(PropTypes.string),
    };

    constructor(props) {
        super(props);
        this.svgRef = React.createRef();
    }

    componentDidMount() {
        setTimeout(() => this.updateSvg(Date.now()), 1000);
    }

    componentDidUpdate(prevProps) {
        this.updateSvg();
    }

    updateSvg(reloadKey) {
        const { values, actions, template } = this.props;
        this.addFonts(template, reloadKey);
        this.removeSize(template, reloadKey);
        this.updateValues(values, reloadKey);
        this.addActions(actions, reloadKey);
    }

  @bind
  @memoize()
    addFonts(template, reloadKey){
        if (!template || !this.svgRef || !this.svgRef.current) {
            return;
        }
        try {
            const fontLoaded = !!document.getElementById('roboto-font');
            if (fontLoaded) {
                return; // if font is already loaded do not reload it
            }
            const svgContainer = this.svgRef.current;
            const svg = svgContainer.children && svgContainer.children[0];
            if (svg) {
                const fontWrapper = `<defs>
                  <style id="roboto-font" type="text/css">
                      @import url('https://fonts.googleapis.com/css?family=Roboto Condensed');
                  </style>
                </defs>`;
                svg.insertAdjacentHTML('afterbegin', fontWrapper); // svg fonts works only in svg but not when included in component
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.warn('Unable to add Roboto Font to SVG', e);

        }

    }

  @bind
  @memoize()
  removeSize(template, reloadKey) {
      if (!template || !this.svgRef || !this.svgRef.current) {
          return;
      }

      try {
          const svgContainer = this.svgRef.current;
          const svg = svgContainer.children && svgContainer.children[0];
          if (svg) {
              svg.removeAttribute('width');
              svg.removeAttribute('height');
          }
      } catch (e) {
          // eslint-disable-next-line no-console
          console.warn('An error occured trying to remove the SVG size', e);

      }
  }

  @bind
  @memoize()
  updateValues(values, reloadKey) {
      if (!values || !this.svgRef || !this.svgRef.current) {
          return;
      }
      const svg = this.svgRef.current;
      Object.entries(values).forEach(([id, value]) => {
          try {
              let node = svg.querySelector(`[id='${id}']`);
              if (node) {
                  const child = node.children && node.children[0];
                  if (child && (child.tagName === 'text' || child.tagName === 'tspan')) {
                      node = child;
                  }
                  if (value === null || value === undefined ) {
                      node.innerHTML = '';
                  } else {
                      node.innerHTML = value;
                  }
              }
          } catch (e) {
              // eslint-disable-next-line no-console
              console.warn('An error occured trying to change the value of the SVG element with id', id, e);
          }
      });
  }

  addAction(svgId, action) {
      const { isScriptTemplate } = this.props;
      if (!svgId || !action || !this.svgRef || !this.svgRef.current) {
          return;
      }
      try {
          const svg = this.svgRef.current;
          const element = svg.querySelector(`[id='${svgId}']`);
          if (!element) {
              return;
          }
          const { click, dblclick } = action || {};
          element.onclick = new ClickHandler({
              click: () => this.props.onAction(isScriptTemplate ? (click && click()) || action : click()),
              dblclick: () => this.props.onAction(isScriptTemplate ? (dblclick && dblclick()) || action : dblclick()),
          }).handleClick;
          element.setAttribute('cursor', 'pointer');
      } catch (e) {
          // eslint-disable-next-line no-console
          console.warn('An error occured trying to add the action to the SVG element with id', svgId, e);
      }
  }

  @bind
  @memoize()
  addActions(actions, reloadKey) {
      Object.entries(actions).forEach(([svgId, action]) => {
          this.addAction(svgId, action);
      });
  }

  render() {
      const {
          template, hiddenList, colors, backgroundColor, zoom, highlight
      } = this.props;

      return (
          <Container backgroundColor={backgroundColor}>
              <SvgContainer
                  ref={this.svgRef}
                  zoom={zoom || 100}
                  hiddenList={hiddenList}
                  colors={colors}
                  highlight={highlight}
                  dangerouslySetInnerHTML={{__html: template }}
              />
          </Container>
      );
  }
}

export default DigitalTwin;
