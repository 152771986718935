/* @flow */
import { combineReducers } from 'redux';

import { dataTableReducer, loadDataReducer, keepDataAtStart } from 'app/utils/redux/reducer-utils';
import {
    LOAD_PROCESS_HISTORY_STARTED, LOAD_PROCESS_HISTORY,
    LOAD_STARTED_PROCESS_DETAILS_STARTED, LOAD_STARTED_PROCESS_DETAILS,
    START_PROCESS_STARTED, START_PROCESS, START_PROCESS_BY_MESSAGE_STARTED, START_PROCESS_BY_MESSAGE,
    START_PROCESS_RESET, EXECUTE_SCRIPT_STARTED, EXECUTE_SCRIPT,
    LOAD_PROCESS_SIDEBAR_DETAILS_STARTED, LOAD_PROCESS_SIDEBAR_DETAILS,
    LOAD_PROCESS_SIDEBAR_DETAILS_INTERNAL_STARTED, LOAD_PROCESS_SIDEBAR_DETAILS_INTERNAL
} from 'store/actions/abox/processActions';
import {
    LOAD_PROCESS_TASKS_STARTED, LOAD_PROCESS_TASKS
} from 'store/actions/abox/taskActions';
import processSubTask from './task/processSubTask';
import avatar from './avatar/processesAvatarReducer';

const processReducer = combineReducers<Object, Object>({
    avatar,
    started: loadDataReducer(LOAD_STARTED_PROCESS_DETAILS_STARTED, LOAD_STARTED_PROCESS_DETAILS),
    sidebar: combineReducers({
        details: loadDataReducer(LOAD_PROCESS_SIDEBAR_DETAILS_STARTED, LOAD_PROCESS_SIDEBAR_DETAILS),
        detailsInternal: loadDataReducer(LOAD_PROCESS_SIDEBAR_DETAILS_INTERNAL_STARTED, LOAD_PROCESS_SIDEBAR_DETAILS_INTERNAL),
    }),
    history: dataTableReducer(LOAD_PROCESS_HISTORY_STARTED, LOAD_PROCESS_HISTORY, keepDataAtStart),
    tasks: dataTableReducer(LOAD_PROCESS_TASKS_STARTED, LOAD_PROCESS_TASKS, keepDataAtStart),
    subTasks: processSubTask,
    start: (state, action) => {
        if (action.type === START_PROCESS_RESET) {
            return { ...state, isLoading: false, failure: null, data: null };
        }
        return loadDataReducer(START_PROCESS_STARTED, START_PROCESS)(state, action);
    },
    startMessage: (state, action) => {
        if (action.type === START_PROCESS_RESET) {
            return { ...state, isLoading: false, failure: null, data: null };
        }
        return loadDataReducer(START_PROCESS_BY_MESSAGE_STARTED, START_PROCESS_BY_MESSAGE)(state, action);
    },
    startExecuteScript: (state, action) => {
        if (action.type === START_PROCESS_RESET) {
            return { ...state, isLoading: false, failure: null, data: null };
        }
        return loadDataReducer(EXECUTE_SCRIPT_STARTED, EXECUTE_SCRIPT)(state, action);
    },
});

export default processReducer;
