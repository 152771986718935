/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import uuidv1 from 'uuid/v1';
import { connect } from 'react-redux';
import { showToastr } from 'store/actions/app/appActions';

import { Typography, Button, Grid, FormControl, FormHelperText } from '@mic3/platform-ui';
import { copyToClipboard } from 'app/utils/classification/classificationUtils';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { getFieldByType } from 'app/utils/designer/form/fieldUtils';
import { set } from 'app/utils/immutable/Immutable';
import { get } from 'app/utils/lo/lo';
import { isDefined } from 'app/utils/utils';

const EnumItemStyle = styled(Grid)`
    width: 100% !important;
    padding: 8px 0;
`;

const GridItemStyle = styled(Grid)`
    display: flex;
`;

const ButtonStyled = styled(Button)`
    min-width: 60px !important;
    padding: 6px 0 !important;
    align-self: flex-start;
`;

const FieldDivider = styled.div`
    width: 10px;
`;
const GrowField = styled.div`
    width: 100%
`;
/**
 *
 */
class EnumList extends PureComponent<Object, Object> {
    static propTypes: Object = {
        enumData: PropTypes.object,
        onChange: PropTypes.func
    };

    state = {
        value: this.props.value || []
    };

    componentDidMount(){
        const { value } = this.state;
        if (!(value || []).length) {
            this.addEnumValue(); // Add by default one key value field
        }
    }

    @bind
    buildItem(item: Object, rowIndex: number, error: boolean | string) {
        return (
            <GridItemStyle key={rowIndex} alignItems="center">
                <GrowField>
                    {getFieldByType('text', {
                        name: 'value',
                        value: item.value,
                        label: 'value',
                        placeholder: 'Value',
                        changeData: event => this.changeValue({ ...event, rowIndex }),
                        error: error && this.validateField({ name: 'value', value: item.value, rowIndex }).isError,
                    })}
                </GrowField>
                <FieldDivider />
                <GrowField>
                    {getFieldByType('text', {
                        name: 'label',
                        value: item.label,
                        label: 'Label',
                        placeholder: 'Label',
                        changeData: event => this.changeValue({ ...event, rowIndex }),
                        error: error && this.validateField({ name: 'label', value: item.label, rowIndex }).isError,
                    })}
                </GrowField>
                <div>
                    {getFieldByType('buttonIcon', {
                        icon: 'content-copy',
                        type: 'mdi',
                        onClick: () => this.copyKeyToClipboard(item.value)
                    })}
                </div>
                <div>
                    {getFieldByType('buttonIcon', {
                        icon: 'delete',
                        onClick: () => this.removeEnumValue(rowIndex)
                    })}
                </div>
            </GridItemStyle>
        );
    }

    @bind
    @memoize()
    buildItemsList(items: Array<Object>, error: boolean | string) {
        return items.map<Object>((item, index) => this.buildItem(item, index, error));
    }

    @bind
    validateField({ name, value, rowIndex }) {
        if (name === 'value') {
            const enumData = this.state.value;
            const keys = enumData.map(({ value }) => value);
            keys.splice(rowIndex, 1);
            if (keys.includes(value)) {
                return { isError: true };
            }
        }
        if (!value || (isDefined(value) && value.length > 50)) {
            return { isError: true };
        }
        return {};
    }

    @bind
    addEnumValue() {
        const { value } = this.state;
        const { onChange, name } = this.props;
        const values = [...value, { value: uuidv1(), label: '' }];
        this.setState({ value: values });
        onChange({ target: { name, value: values } });
    }

    @bind
    changeValue(event: Object) {
        const { value: changedValue, rowIndex, name } = event;
        let { value } = this.state;
        // $FlowFixMe
        value = set(value, `[${rowIndex}].${name}`, (changedValue || '').trimStart());
        this.setState({ value });
        this.props.onChange({ target: { name: this.props.name, value: value } });
    }

    @bind
    copyKeyToClipboard(key: string) {
        copyToClipboard(key)
            .then(() => {
                this.props.showToastr({ severity: 'success', detail: 'Key copied to clipboard' });
            })
            .catch(() => {
                this.props.showToastr({ severity: 'error', detail: 'Key could not copied to clipboard' });
            });
    }

    @bind
    removeEnumValue(rowIndex: number) {
        const value = [...get(this.state, 'value', [])];
        if (!rowIndex && get(value, 'length') === 0) {
            return this.props.showToastr({ severity: 'warning', detail: 'You can not remove this field.' });
        }
        value.splice(rowIndex, 1);
        this.setState({ value }, () => {
            this.props.onChange({ target: { name: this.props.name, value } });
        });
    }

    /**
     * @override
     */
    render(): Object {
        const { error, helperText, label } = this.props;
        const { value } = this.state;
        return (
            <EnumItemStyle container direction="column" justify="flex-start" alignItems="stretch">
                {label && <Typography variant="caption">{label}</Typography>}
                {this.buildItemsList(value || [], error)}
                <ButtonStyled onClick={this.addEnumValue} iconName="plus">
                    ADD
                </ButtonStyled>
                {helperText && (
                    <FormControl error={error}>
                        <FormHelperText>{helperText}</FormHelperText>
                    </FormControl>
                )}
            </EnumItemStyle>
        );
    }
}

export default connect(
    null,
    { showToastr }
)(EnumList);
