/* @flow */

import Immutable from 'app/utils/immutable/Immutable';
import {
    LOAD_CUSTOM_ENTITIES_BOARD_LABELS_STARTED,
    LOAD_CUSTOM_ENTITIES_BOARD_LABELS_DONE,
    LOAD_CUSTOM_ENTITIES_BOARD_LABEL_TASKS_DONE,
    CREATE_RELATIONSHIP_ENTITY_TO_TASK_DONE,
    REMOVE_TASK_RELATIONSHIP_FROM_COLUMN_DONE,
    SET_SELECTED_BOARD,
    SET_BOARD_COLUMN_STATE,
    SET_BOARD_COLUMN_LOADING,
    MOVE_TASK_TO_OTHER_COLUMN,
    ADD_NEW_BOARD_LABEL_TO_COLUMN,
    CLEAR_BOARD_TASKS,
    LOAD_BOARD_TASKS_STARTED,
    LOAD_BOARD_TASKS,
    LOAD_RELATION_DEFINITION_TASK_STARTED,
    LOAD_RELATION_DEFINITION_TASK_DONE,
    ADD_TASK_TO_COLUMN,
    REMOVE_TASK_FROM_COLUMN,
    CREATE_RELATIONSHIP_ENTITY_TO_TASK_STARTED,
    LOAD_COLUMNS_STARTED,
    LOAD_COLUMNS_DONE,
    CREATE_RELATIONSHIP_COLUMN_TO_BOARD_STARTED,
    CREATE_RELATIONSHIP_COLUMN_TO_BOARD_DONE,
    REMOVE_COLUMN_RELATIONSHIP_FROM_BOARD_STARTED,
    REMOVE_COLUMN_FROM_BOARD,
    REORDER_COLUMN_POSITION,
    MOVE_TASK_TO_OTHER_COLUMN_DONE,
    RESET_BOARDS,
    SORT_BOARD_TASKS,
    LOAD_CUSTOM_ENTITIES_BOARD_LABEL_TASK_DONE
} from 'store/actions/abox/old_boardsActions';
import { mapColumnState } from 'app/utils/boards/utils';
import { combineReducers } from 'redux';
import { dataTableReducer, keepDataAtStart } from 'app/utils/redux/reducer-utils';
import { isEmpty } from 'app/utils/utils';
import { sortByField } from 'app/utils/array/array-utils';
import { get } from 'app/utils/lo/lo';

const initialState = {
    isLoading: false,
    boards: {
        records: [],
        isLoading: false
    },
    boardColumns: {
        records: [],
        isReordered: false,
        isAdded: false,
        isRemoved: false,
        isLoading: false
    },
    boardColumnTasks: {
        records: {},
    },
    boardColumnState: null,
    selectedBoard: null
};

const boardsReducerMain = (state: Object = initialState, action: Object) => {
    const { type, payload } = action || {};
    switch (type) {
        case CREATE_RELATIONSHIP_COLUMN_TO_BOARD_STARTED:
        case CREATE_RELATIONSHIP_ENTITY_TO_TASK_STARTED:
            return Immutable({ ...state, isLoading: true });

        case CREATE_RELATIONSHIP_COLUMN_TO_BOARD_DONE:
            return Immutable({ ...state, isLoading: false });

        case LOAD_CUSTOM_ENTITIES_BOARD_LABELS_STARTED:
        case REMOVE_COLUMN_RELATIONSHIP_FROM_BOARD_STARTED:
            return Immutable({ ...state, boardColumns: {
                ...state.boardColumns,
                isReordered: false,
                isRemoved: false,
                isAdded: false,
                isLoading: true
            } });

        case LOAD_CUSTOM_ENTITIES_BOARD_LABELS_DONE:
            const parsedPayload = {
                ...state.boardColumns,
                ...payload,
                records: !payload.records ? [] : payload.records.map((record, index) => {
                    const attributes = get(record, 'relation.attributes');
                    const order = !isEmpty(attributes) ? attributes['board-board-label/order'] : index + 1;
                    return {
                        relationId: record.relation.id,
                        isAdded: false,
                        order: order,
                        ...record.relatedEntity
                    };
                }).sort((a, b) => a.order - b.order)
            };
            return Immutable({ ...state, boardColumns: { ...parsedPayload, isLoading: false } });

        case ADD_NEW_BOARD_LABEL_TO_COLUMN:
            return Immutable({
                ...state,
                boardColumns: {
                    ...state.boardColumns,
                    isAdded: true,
                    isRemoved: false,
                    records: [ ...state.boardColumns.records, payload ],
                    isLoading: false
                },
                boardColumnState: {
                    ...state.boardColumnState,
                    [payload.id]: {
                        name: payload.name,
                        isLoading: false,
                    }

                }
            });

        case SET_SELECTED_BOARD:
            return Immutable({ ...state, selectedBoard: payload });

        case SET_BOARD_COLUMN_STATE:
            const columnState = payload ? mapColumnState(payload) : null;
            return Immutable({ ...state, boardColumnState: columnState });

        case MOVE_TASK_TO_OTHER_COLUMN:
            const columnsSource = [ ...state.boardColumnTasks.records[`column${payload.sourceColumnId}`] ];
            const columnsDest = [ ...state.boardColumnTasks.records[`column${payload.destColumnId}`] ];
            const task = {...columnsSource.splice(payload.sourceIndex, 1)[0], isLoading: true};
            columnsDest.splice(payload.destIndex, 0, task );
            return Immutable({
                ...state,
                boardColumnTasks: {
                    records: {
                        ...state.boardColumnTasks.records,
                        [`column${payload.sourceColumnId}`]: columnsSource,
                        [`column${payload.destColumnId}`]: columnsDest
                    }
                }
            });
        case SORT_BOARD_TASKS: {
            const records = {};
            Object.entries(state.boardColumnTasks.records).forEach(([columnId, tasks]) => {
                // As we consider priority 50 as 3 so we need to modify the tasks data
                const modifiedTasks = (tasks || []).map(task => ({ ...task, priority: task.priority === 50 ? 3 : task.priority }));
                records[columnId] = sortByField(modifiedTasks, action.payload);
            });
            return Immutable({
                ...state,
                boardColumnTasks: {
                    records
                }
            });
        }

        case ADD_TASK_TO_COLUMN:
            const AddTaskColumns = [ ...state.boardColumnTasks.records[`column${payload.columnId}`] ];
            AddTaskColumns.splice(0, 0, payload.task );

            return Immutable({
                ...state,
                boardColumnTasks: {
                    records: {
                        ...state.boardColumnTasks.records,
                        [`column${payload.columnId}`]: AddTaskColumns
                    }
                }
            });

        case REMOVE_TASK_FROM_COLUMN:
            const removeTaskColumns = [ ...state.boardColumnTasks.records[`column${payload.columnId}`] ];
            const destColumn = [...state.boardColumnTasks.records[`column${payload.destColumnId}`]].map((task) => {
                const movedTaskId = removeTaskColumns[payload.taskIndex].id;
                const updatedTask = movedTaskId !== task.id ? {...task} : {...task, isLoading: true};
                return updatedTask;
            });
            removeTaskColumns.splice(payload.taskIndex, 1);

            return Immutable({
                ...state,
                boardColumnTasks: {
                    records: {
                        ...state.boardColumnTasks.records,
                        [`column${payload.columnId}`]: removeTaskColumns,
                        [`column${payload.destColumnId}`]: destColumn
                    }
                }
            });

        case SET_BOARD_COLUMN_LOADING:
            return Immutable({
                ...state,
                boardColumnState: {
                    ...state.boardColumnState,
                    [payload.columnId]: { isLoading: payload.isLoading}
                }
            });

        case CLEAR_BOARD_TASKS:
            return Immutable({
                ...state,
                boardColumnTasks: {
                    records: {}
                }
            });

        case LOAD_CUSTOM_ENTITIES_BOARD_LABEL_TASKS_DONE:
            const { entityId, tasks } = payload;
            const value = tasks.map(task => ({...task, isLoading: false}));
            const data = { ...state.boardColumnTasks.records, ...{ [`column${entityId}`]: value } };

            return Immutable({
                ...state,
                boardColumnTasks: {
                    ...state.boardColumnTasks,
                    records: data,
                    isLoading: false
                }
            });

        case LOAD_CUSTOM_ENTITIES_BOARD_LABEL_TASK_DONE:
            const { columnId, ...taskItem } = payload;
            const newValue = state.boardColumnTasks.records[`column${columnId}`].map((task) => {
                if (task.id === taskItem.id) {
                    return { ...taskItem };
                }

                return task;
            });

            return Immutable({
                ...state,
                boardColumnTasks: {
                    ...state.boardColumnTasks,
                    records: {
                        ...state.boardColumnTasks.records,
                        ...{ [`column${columnId}`]: newValue }
                    }
                }
            });

        case CREATE_RELATIONSHIP_ENTITY_TO_TASK_DONE:
            const columnName = `column${payload.data.custom2.id}`;
            const newTask = {...payload.data.task1, isLoading: false};
            const columnTasks = [ ...state.boardColumnTasks.records[columnName] ];
            const taskIndex = columnTasks.findIndex((column) => {
                return newTask.id === column.id;
            });
            columnTasks[taskIndex] = {
                ...columnTasks[taskIndex],
                ...newTask,
            };
            return Immutable({
                ...state,
                isLoading: false,
                boardColumnTasks: {
                    ...state.boardColumnTasks,
                    records: {
                        ...state.boardColumnTasks.records,
                        [columnName]: columnTasks
                    },
                    isLoading: false
                }
            });

        case REMOVE_TASK_RELATIONSHIP_FROM_COLUMN_DONE:
            return Immutable({
                ...state,
                boardColumnTasks: {
                    ...state.boardColumnTasks,
                    isLoading: false
                } });

        case REMOVE_COLUMN_FROM_BOARD:
            const currentBoardTasks = { ...state.boardColumnTasks.records };
            delete currentBoardTasks[`column${payload.id}`];

            return Immutable({
                ...state,
                boardColumns: {
                    ...state.boardColumns,
                    count: state.boardColumns.count - 1,
                    records: state.boardColumns.records.filter(item => item.relationId !== payload.relationId),
                    isReordered: false,
                    isAdded: false,
                    isRemoved: true,
                    isLoading: false
                },
                boardColumnTasks: {
                    ...state.boardColumnTasks,
                    records: {
                        ...currentBoardTasks
                    }
                }
            });

        case REORDER_COLUMN_POSITION:
            return Immutable({
                ...state,
                boardColumns: {
                    ...state.boardColumns,
                    isReordered: true,
                    isAdded: false,
                    isRemoved: false,
                    records: payload
                }
            });

        case MOVE_TASK_TO_OTHER_COLUMN_DONE:
            const updateDestColumn = [...state.boardColumnTasks.records[`column${payload.destColumnId}`]].map((task) => {
                const updatedTask = payload.taskId !== task.id ? {...task} : {...task, isLoading: false};
                return updatedTask;
            });

            return Immutable({
                ...state,
                boardColumnTasks: {
                    records: {
                        ...state.boardColumnTasks.records,
                        [`column${payload.destColumnId}`]: updateDestColumn
                    }
                }
            });

        case RESET_BOARDS:
            return initialState;

        default:
            return state;
    }
};

const relationDefinitionReducer = (
    state: Object = Immutable({
        task: {
            isLoading: false,
            relationTypeId: null
        },
        entity: {
            isLoading: false,
            relationTypeId: null
        }
    }),
    action: Object
) => {
    const { type, payload } = action || {};

    switch (type) {
        case LOAD_RELATION_DEFINITION_TASK_STARTED:
            return Immutable({ ...state, task: { ...state.task, isLoading: true } });

        case LOAD_RELATION_DEFINITION_TASK_DONE:
            return Immutable({
                ...state,
                task: {
                    relationTypeId: !isEmpty(payload) ? '' + payload[0].id : state.task.relationTypeId,
                    isLoading: false
                }
            });

        default:
            return state;
    }
};

const boardsReducer = combineReducers<Object, Object>({
    boards: boardsReducerMain,
    tasks: dataTableReducer(LOAD_BOARD_TASKS_STARTED, LOAD_BOARD_TASKS, keepDataAtStart),
    columns: dataTableReducer(LOAD_COLUMNS_STARTED, LOAD_COLUMNS_DONE, keepDataAtStart),
    relationDefinition: relationDefinitionReducer
});

export default boardsReducer;
