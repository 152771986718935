/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Autocomplete } from '@mic3/platform-ui';

import { loadDesignerScriptVersionTypeahead } from 'store/actions/designer/designerFormActions';

import { bind } from 'app/utils/decorators/decoratorUtils';
import { sortBy } from 'app/utils/lo/lo';


/**
 * Select one or more groups using lazy loading.
 */
class ScriptVersionTypeahead extends PureComponent<Object, Object> {

    static propTypes = {
        loadDesignerScriptVersionTypeahead: PropTypes.func.isRequired,
        scriptId: PropTypes.string,
    }

    constructor(props) {
        super(props);
        this.state = { options: [] };
        this.loadOptions();
    }

    componentDidUpdate(prevProps) {
        const { scriptId } = this.props;
        if(scriptId !== prevProps.scriptId) {
            this.loadOptions();
        }
    }

    @bind
    async loadOptions() {
        const { scriptId, loadDesignerScriptVersionTypeahead } = this.props;
        if(scriptId) {
            const options = await loadDesignerScriptVersionTypeahead(scriptId);
            const versionsList = sortBy(options.map(op => ({ value: op.primary.version, label: `Version ${op.primary.version}` })), 'value').reverse();
            this.setState({ options: [{ value: '', label: 'Always latest version' }, ...versionsList] });
        }

    }


    render() {
        const { loadDesignerScriptVersionTypeahead, scriptId, disabled, ...restProps } = this.props;
        const { options } = this.state;
        return (
            <Autocomplete
                placeholder="Search for a version..."
                {...restProps}
                disabled={!scriptId || disabled}
                options={options}
            />
        );
    }
};


export default connect(
    null, { loadDesignerScriptVersionTypeahead }
)(ScriptVersionTypeahead);
