/* @flow */

import { gql } from '@apollo/client';

export default gql`
    query loadClassAvatarByUri($filterBy: [JSON]) {
        result: classes(filterBy: $filterBy) {
            id
            name
            color
            icon
            iconType
            uri
        }
    }
`;
