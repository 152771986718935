/* @flow */

import { gql } from '@apollo/client';

import { defaultPipelineQueryFields } from './pipelineQuery';

export default gql`
	mutation connectorStopPipelineMutation($id: ID!, $connectorLabel: String!){
		result: iotPipelineConnectorStop(id: $id, connectorLabel: $connectorLabel) {
			${defaultPipelineQueryFields}
		}
	}
`;
