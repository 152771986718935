/* @flow */

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Button, Divider } from '@mic3/platform-ui';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { cancelProcess, loadProcessSidebarDetailsInternal } from 'store/actions/abox/processActions';

import { bind } from 'app/utils/decorators/decoratorUtils';

import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';

const StyledDivider = styled(Divider)`
    margin: 5px 0 !important;
    background: rgba(255, 255, 255, 0.24) !important;
`;

const StyledButton = styled(Button)`
    margin: 0;
    background: #1248bd !important;
    box-shadow: 0px 0px 1.33px rgba(0, 0, 0, 0.08), 0px 1.83px 1px rgba(0, 0, 0, 0.26);
    border-radius: 4px;
`;

const StyledFormGenerator = styled(FormGenerator)`
    padding: 0;
`;

class CancelProcessModal extends PureComponent<Object, Object> {
    static propTypes = {
        isOpen: PropTypes.bool
    };

    state = { isDoneClicked: false };
    formRef: Object = React.createRef();

    fieldDefinitions = [
        {
            field: 'closureReason',
            type: 'textarea',
            properties: { name: 'closureReason', placeholder: 'Give a reason for cancelling the process...' },
            constraints: { minLength: 3, maxLength: 160 }
        }
    ];

    @bind
    onSubmit(event: Event) {
        event.preventDefault();
        this.setState({ isDoneClicked: true });
        const { id, type } = this.props.details || {};
        this.formRef.current.isValidForm().then(({ data, errors }) => {
            if (!errors) {
                return this.props.cancelProcess(id, type, data?.closureReason).then((result) => {
                    this.setState({ isDoneClicked: false });
                    this.props.sidebarIsOpen && this.props.loadProcessSidebarDetailsInternal(id);
                    this.props.onClose();
                });
            }
            this.setState({ isDoneClicked: false });
        });
    }
    /**
     * @override
     */
    render(): Object {
        const { isOpen } = this.props;
        const { isDoneClicked } = this.state;
        return (
            isOpen && (
                <ModalDialog
                    bgcolor={'#28334B'}
                    dialogProps={{ overflow: 'hidden' }}
                    withoutClose
                    title={`Cancel process`}
                    actions={
                        <>
                            <Button variant="text" onClick={this.props.onClose}>
                                CLOSE
                            </Button>
                            <StyledButton onClick={this.onSubmit} disabled={isDoneClicked}>
                                DONE
                            </StyledButton>
                        </>
                    }
                >
                    <>
                        <StyledDivider />
                        <StyledFormGenerator components={this.fieldDefinitions} ref={this.formRef} />
                    </>
                </ModalDialog>
            )
        );
    }
}

export default connect(
    state => ({
        isClassificationsLoading: state.classifications.classificationsByIds.isLoading,
        isFileUploading: state.chat.room.isFileUploading,
        sidebarIsOpen: state.sidebar.isOpen,
    }), {
        cancelProcess,
        loadProcessSidebarDetailsInternal
    }
)(CancelProcessModal);
