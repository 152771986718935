import React from 'react';
import { MdiIcon, Menu, MenuItem } from '@mic3/platform-ui';

export default function TextEditorHeaderMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onClick = (level) => {
        props.onHeader(level);
        setAnchorEl(null);
    };

    return (
        <>
            <MdiIcon
                aria-controls="simple-menu" aria-haspopup="true"
                name="format-header-pound"
                onClick={handleClick}
            />
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={() => onClick(1)}>
                    <MdiIcon name="format-header-1" />
                    - Header 1
                </MenuItem>
                <MenuItem onClick={() => onClick(2)}>
                    <MdiIcon name="format-header-2" />
                    - Header 2
                </MenuItem>
                <MenuItem onClick={() => onClick(3)}>
                    <MdiIcon name="format-header-3" />
                    - Header 3
                </MenuItem>
                <MenuItem onClick={() => onClick(4)}>
                    <MdiIcon name="format-header-4" />
                    - Header 4
                </MenuItem>
            </Menu>
        </>
    );
}
