
import React from 'react';
import { IconButton, MdiIcon, Tooltip } from '@mic3/platform-ui';
import { ThemeProvider as MuiThemeProvider  } from '@material-ui/core/styles';
import { muiTheme } from 'app/themes/materialUi';

const ControlButton = ({ title, iconName }) => (
    <MuiThemeProvider theme={muiTheme}>
        <Tooltip arrow title={title} placement="top-start">
            <span>
                <IconButton key={12} title={title} >
                    <MdiIcon name={iconName}  />
                </IconButton>
            </span>
        </Tooltip>
    </MuiThemeProvider>
);

export default ControlButton;
