/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Typography } from '@mic3/platform-ui';

import { loadTasksByPriority } from 'store/actions/dashboard/dashboardActions';
import { bind } from 'app/utils/decorators/decoratorUtils';
import Widget from 'app/components/atoms/Widget/Widget';
import LinkList from 'app/containers/LandingPage/Widgets/LinkList';
import WidgetFooter from 'app/containers/LandingPage/Widgets/WidgetFooter';
import { getPriorityLabel } from 'app/config/aboxConfig';
import { get } from 'app/utils/lo/lo';

const StyledTypography = styled(Typography)`
    color: ${({ theme, value }) => get(theme.material.colors.priorityTaskColors, `${value}.0`)};
`;

/**
 * Widget for displaying tasks assigned by priority to the active user
 */
class TasksByPriorityWidget extends PureComponent<Object, Object> {
    static propTypes = {
        loadTasksByPriority: PropTypes.func,
        isLoading: PropTypes.bool,
        records: PropTypes.array,
        user: PropTypes.object,
    };

    constructor(props) {
        super(props);
        props.loadTasksByPriority(props.user, { linkOnly: true });
    }

    @bind
    renderFooterContent(){
        const orderBy = { field: 'primary.priority', direction: 'asc nulls last' };
        return <WidgetFooter orderBy={orderBy}  />;
    }
    @bind
    renderAdditionalInfo( props){
        const primary = get(props, 'primary');
        const { priority } = primary;
        const label = getPriorityLabel(priority);
        return <StyledTypography value={priority} >{label}</StyledTypography>;
    }
    render() {
        const { isLoading, records, item } = this.props;
        return (
            <Widget
                title="Highest Priority Tasks"
                content={!isLoading && !records?.length ? 'No Results': <LinkList
                    list={records}
                    type="task"
                    renderAdditionalInfo={this.renderAdditionalInfo}
                />}
                loading={isLoading}
                footerContent={this.renderFooterContent}
                {...item}
            />
        );
    }
}

const mapStateToProps: Function = ( state ) => {
    return {
        user: state.user.profile,
        isLoading: state.dashboard.tasksByPriority.isLoading,
        records: state.dashboard.tasksByPriority.records,
    };
};

export default connect(mapStateToProps, { loadTasksByPriority })(TasksByPriorityWidget);
