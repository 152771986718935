/* @flow */

import React from 'react';

import Help from 'app/utils/designer/form/settings/common/Help';
import { help } from 'app/utils/designer/form/settings/common/commonHelpers';
import { onLoad, onClick, isVisible } from 'app/utils/designer/form/settings/common/commonEvents';
import { flexGrow } from 'app/utils/designer/form/settings/common/commonStyles';

const panels = settingsValues => [
    {
        header: 'Base',
        children: [
            { type: 'text', properties: { label: 'Src', name: 'properties.src' }, constraints: { required: true }},
            { type: 'text', properties: { label: 'Alt', name: 'properties.alt' } },
            { type: 'text', properties: { label: 'Title', name: 'properties.title' } },
            { type: 'boolean', properties: { label: 'Original size', name: 'properties.originalSize' } },
            { type: 'boolean', properties: {
                label: 'Full width',
                name: 'properties.fullWidth',
                isVisible: ({ properties }) =>
                    properties && properties.originalSize,

            }
            },
            { type: 'number',
                properties: {
                    label: 'width',
                    name: 'properties.width',
                    isVisible: ({ properties }) =>
                        properties && !properties.originalSize,
                    help: <Help message="Defines the width of the image in px." />,


                },
            },
            { type: 'number',
                properties: {
                    label: 'height',
                    name: 'properties.height',
                    isVisible: ({ properties }) =>
                        properties && !properties.originalSize,
                    help: <Help message="Defines the height of the image in px." />,


                },
            },
            { type: 'boolean', properties: { label: 'Rounded', name: 'properties.rounded' } },

            {
                type: 'typeahead',
                properties: {
                    label: 'On click action type',
                    name: 'properties.actionType',
                    valueField:'value',
                    options: [
                        { value: null , label: 'No action' },
                        { value: 'function' , label: 'Function' },
                        { value: 'internal' , label: 'Internal link' },
                        { value: 'external' , label: 'External link' },
                    ]
                }
            },
            {
                type: 'text',
                properties: {
                    label: 'Link',
                    name: 'properties.to',
                    isVisible: ({ properties }) =>
                        properties && properties.actionType === 'internal',
                    // the onChange function must be a string to run in the service worker
                    onChange: String(function (event) {
                        const {value, name} = event.target;
                        return [
                            { name: 'properties.href', value: null },
                            { name, value }
                        ];
                    }),
                },
                constraints: {
                    required: ({ properties }) => properties?.actionType === 'internal',
                },
            },
            {
                type: 'text',
                properties: {
                    label: 'Link',
                    name: 'properties.href',
                    isVisible: ({ properties }) =>
                        properties && properties.actionType === 'external',
                    // the onChange function must be a string to run in the service worker
                    onChange: String(function (event) {
                        const {value, name} = event.target;
                        return [
                            { name: 'properties.to', value: null },
                            { name, value }
                        ];
                    }),
                },
                constraints: {
                    required: ({ properties }) => properties?.actionType === 'external'
                },
            },
            onClick,
        ],
    },
    {
        header: 'Events',
        children: [
            onLoad,
            isVisible,
        ]
    },
    {
        header: 'Styles',
        children: [ flexGrow ]
    },
    {
        header: 'Helpers',
        children: [ help ]
    },
];

export default {
    defaults: { originalSize: true, actionType: null },
    panels: settingValues => panels(settingValues),
};
