export const attributeTypes = {
    text: 'Text',
    textarea: 'Text Multi Line',
    number: 'Number/Decimal',
    boolean: 'Yes / No',
    dateTime: 'Date and Time',
    date: 'Date',
    time: 'Time',
    hyperlink: 'Hyperlink',
    typeahead: 'Enumeration',
    entityTypeahead: 'Entity',
    personTypeahead: 'People',
    organisationTypeahead: 'Organisations',
    classificationTypeahead: 'Classes',
    graphicTypeahead: 'Graphic',
    userTypeahead: 'User',
    relationsTypeahead: 'Relation',
    teamTypeahead: 'Team',
    workspaceTypeahead: 'Workspace',
    colorPicker: 'Color',
    iconSelect: 'Icon',
    uploadFile: 'Upload',

};

export default Object.entries(attributeTypes).map(([value, label]) => ({ value, label }));

/**
 * function to get label from type
 * @param type
 * @returns {string}
 */
export function getLabel(type) {
    return attributeTypes[type] || 'Unknown';
}
