/* @flow */

import { gql } from '@apollo/client';
import { entityReferences } from 'graphql/entities/relationships/relationsQuery';

export default gql `
    query taskQuery($id: ID!) {
        result: task(id: $id) {
            id
            name
            description
            modifiedDate
            assignee {
              id
              username
              image
              name
            }
              primary
            relations {
              relation {
                  id
                  relationDefinition {
                      id
                  }
              }
              relatedEntity {
                ${entityReferences}
              }
            }
        }
    }
`;
