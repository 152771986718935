import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Grid, Autocomplete, Divider } from '@mic3/platform-ui';

import ExpansionCard from 'app/components/molecules/ExpansionCard/ExpansionCard';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';

const DividerStyled = styled(Divider)`
    margin: 10px 0 32px 0 !important;
`;



class EntityAppearanceSection extends PureComponent {

    @bind
    getAttributeOptions(details, type, defaultLabel) {
        const { formDefinition } = details || {};
        const { fields } = formDefinition || {};

        // Legacy part start
        if((fields || [])[0]?.settings) {
            return [...(fields || []).filter(field => field.type === type).map(field => ({
                value: field?.properties?.name,
                label: field?.properties?.label
            })), { value: null, label: defaultLabel || 'Empty' }];
        }
        // Legacy part end

        const attributes = [];
        const parseAttributes = (fields) => {
            (fields || []).forEach((field) => {
                if(field.children) {
                    parseAttributes(field.children);
                }
                if(field.type === type) {
                    attributes.push({
                        value: field?.properties?.name,
                        label: field?.properties?.label
                    });
                }
            });
        };
        parseAttributes(fields);
        return [...attributes, { value: null, label: defaultLabel || 'Empty' }];
    }

    @bind
    @memoize()
    getIconOptions(details) {
        return this.getAttributeOptions(details, 'iconSelect', 'Entity Type Icon');
    }

    @bind
    @memoize()
    getColorOptions(details) {
        return this.getAttributeOptions(details, 'colorPicker', 'Entity Type Color');
    }

    @bind
    @memoize()
    getFileOptions(details) {
        return this.getAttributeOptions(details, 'uploadFile');
    }

    @bind
    @memoize()
    getNumberOptions(details) {
        return this.getAttributeOptions(details, 'number');
    }

    @bind
    getAttributeLabel(details, id, defaultValue) {
        const { formDefinition } = details || {};
        const { fields } = formDefinition || {};

        // Legacy part start
        if((fields || [])[0]?.settings) {
            const field = fields?.find(f => f?.properties?.name === id);
            return field?.properties?.label || defaultValue || null;
        }
        // Legacy part end

        let label = null;
        const parseLabel = (fields) => {
            (fields || []).forEach((f) => {
                if(f.children) {
                    parseLabel(f.children);
                }
                if(!label && f?.properties?.name === id) {
                    label = f?.properties?.label;
                }
            });
        };
        parseLabel(fields);
        return label || defaultValue || null;
    }

    render() {
        const { handleChange, details, disabled } = this.props;
        const { entityAppearance } = details || {};
        const { iconAttribute, colorAttribute, progressAttribute, imageAttribute } = entityAppearance || {};  
    
        return (
            <ExpansionCard
                expanded
                title="Entity Appearance"
               
            >
                <Grid container alignItems="center" justify="space-between" wrap="nowrap">
                   
                    <Autocomplete
                        value={iconAttribute || null}
                        onChange={handleChange}
                        options={this.getIconOptions(details)}
                        name="entityAppearance.iconAttribute"
                        label="Icon attribute"
                        valueField="value"
                        disabled={disabled}
                    />
                   
                </Grid>
                <Grid container alignItems="center" justify="space-between" wrap="nowrap">
                  
                    <Autocomplete
                        value={colorAttribute || null}
                        onChange={handleChange}
                        options={this.getColorOptions(details)}
                        name="entityAppearance.colorAttribute"
                        label="Colour attribute"
                        valueField="value"
                        disabled={disabled}
                    />
                   
                </Grid>
                <Grid container alignItems="center" justify="space-between" wrap="nowrap">
                   
                    <Autocomplete
                        value={progressAttribute || null}
                        onChange={handleChange}
                        options={this.getNumberOptions(details)}
                        name="entityAppearance.progressAttribute"
                        label="Progress attribute"
                        valueField="value"
                        disabled={disabled}
                    />
                   
                </Grid>
                <Grid container alignItems="center" justify="space-between" wrap="nowrap">
                   
                    <Autocomplete
                        value={imageAttribute || null}
                        onChange={handleChange}
                        options={this.getFileOptions(details)}
                        name="entityAppearance.imageAttribute"
                        label="Image attribute"
                        valueField="value"
                        disabled={disabled}
                    />
                  
                </Grid>
                <DividerStyled />
            </ExpansionCard>
        );
    };
}

export default EntityAppearanceSection;
