
import React, { memo, useEffect } from 'react';
import styled from 'styled-components';
import { Box, Toolbar, IconButton } from '@mic3/platform-ui';
import TextField from '@material-ui/core/TextField';

import UploadButton from 'app/components/molecules/UploadButton/UploadButton';
import Icon from 'app/components/atoms/Icon/Icon';
import { muiTheme } from 'app/themes/materialUi';

const ToolbarStyled = styled(Toolbar)`
    background: ${({ edit, theme }) => edit === 'true' ? 'rgba(0,81,255,0.2)' : theme.material.colors.background.default};
    min-height: auto !important;
    border: 1px solid ${({theme})=> theme.material.colors.background.divider};
    border-radius: 4px;
    margin-bottom: 8px;
`;

const StyledBox = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;
    & button {
        height 48px;
        width: 48px;
        padding: 12px;
    }
`;

const TextFieldStyled = styled(TextField)`
    color: ${({theme})=> theme.material.colors.text.secondary};
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.15px;

    & .MuiInputBase-multiline {
        padding: 0; 
    }

    & .MuiInput-input {
        margin: 12px 0;
    }
    & .MuiInputBase-input::placeholder {
        color: ${({theme})=> theme.material.colors.text.secondary};
        opacity: 1;
    }
`;

const ChatFooter = ({
    message,
    previousMessage,
    onSendMessage,
    onInputChange,
    onInputKeyPress,
    uploadFiles,
    editActive,
    isMobile,
    inputRef,
    rid
}) => {
    useEffect(() => {
        if (!isMobile || editActive) {
            inputRef.current.focus();
        }
    }, [isMobile, editActive, inputRef, rid]);

    return (
        <ToolbarStyled edit={editActive.toString()}>
            <Box display='flex' flexGrow='1'>
                <TextFieldStyled
                    id="chat-footer-input"
                    inputRef={inputRef}
                    InputProps={{ disableUnderline: true }}
                    multiline={true}
                    rows={1}
                    rowsMax={5}
                    value={message}
                    placeholder='Message'
                    fullWidth
                    onChange={onInputChange}
                    onKeyDown={onInputKeyPress}
                />
            </Box>
            <StyledBox>
                {message ? (
                    <IconButton onClick={onSendMessage}>
                        <Icon name='send' hexColor={muiTheme.colors.text.secondary} />
                    </IconButton>
                ) : (
                    <>
                        <UploadButton
                            multiple
                            alt='Upload Attachment'
                            icon='paperclip'
                            hexColor={muiTheme.colors.text.secondary}
                            onSelect={uploadFiles}
                        />
                    </>
                )}
            </StyledBox>
        </ToolbarStyled>
    );
};

export default memo(ChatFooter);