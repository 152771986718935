/* @flow */

import { gql } from '@apollo/client';

export default gql`
    query designerPipelineListQuery($startIndex: Int, $stopIndex: Int, $filterBy: [JSON], $orderBy: [JSON], $excludeBy: [JSON]) {
        count: count(dataType: "iot_pipeline", filterBy: $filterBy, excludeBy: $excludeBy)
        records: entities(type: "iot_pipeline", startIndex: $startIndex, stopIndex: $stopIndex, filterBy: $filterBy, orderBy: $orderBy, excludeBy: $excludeBy) {
            id
            type
            name
            description
            primary
            modifiedDate
            createdDate
            createdBy {
                id
                name
                image
            }
            modifiedBy {
                id
                name
                image
            }
        }
    }
`;
