/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import PageTemplate from 'app/components/templates/PageTemplate';
import { loadEntitiesListView } from 'store/actions/entities/entitiesActions';
import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import EntitiesList from 'app/containers/Entities/EntitiesList/EntitiesList';
import { getStr } from 'app/utils/utils';
import { get } from 'app/utils/lo/lo';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import Icon from 'app/components/atoms/Icon/Icon';
import { OpenLeftSidebarButton, entitiesDefaultFilterDefinitions } from 'app/utils/entity/entityUtils';
import { setDocumentTitle } from 'store/actions/app/appActions';
import { modulesAndPageTitles } from 'app/config/typesConfig';


const IconStyled = styled(Icon)`
    &:before {
        color: ${({theme})=> theme.material.colors.text.caption};
        font-size: 6rem !important;
        font-weight: 100 !important;
        @media (max-width: 480px) {
            margin-left: 1rem;
        }
    }
`;

const Contaienr = styled.div`
    display: flex;
    justify-content: space-around;
    height: 90vh;
`;

const StartedText = styled.div`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 100;
    font-size: 60px;
    line-height: 68px;
    letter-spacing: -0.5px;
    color: ${({theme})=> theme.material.colors.text.caption};
    margin-left: 2rem;
`;

const Flex = styled.div`
    display: flex;
    align-items: center;
`;

const SubTitle = styled.div`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 20px;
    color: ${({theme})=> theme.material.colors.text.secondary};
    mix-blend-mode: normal;
    position: absolute;
    margin-top: 1rem;
`;

const VIEW_ID = 'EntitiesListView';

class EntitiesListView extends PureComponent<Object, Object> {
    static propTypes = {
        loadEntitiesListView: PropTypes.func.isRequired,
        records: PropTypes.array,
        isLoading: PropTypes.bool,
        totalRecords: PropTypes.number
    };

    constructor(props: Object) {
        super(props);
        const currentPath = getStr(this.props, 'match.path');
        const isRelatedEntity = currentPath.includes('related-entities');
        this.state = { isRelatedEntity };
    }

    listRef = React.createRef();

    defaultFilters = { active: true };

    columnDefinitions = [
        {
            text: 'Entity Name',
            field: 'name'
        },
        {
            text: 'Description',
            field: 'description'
        },
        {
            text: 'Entity ID',
            field: 'id',
            onClickDisabled: true
        },
        {
            text: 'Classes',
            field: 'classes'
        },
        {
            text: 'Location',
            field: 'primary.locationInfo'
        },
        {
            text: 'Icon',
            field: 'iconName',
            align: 'center'
        },
        {
            text: 'Status',
            field: 'active',
            align: 'center'
        },
        {
            text: 'Last Updated',
            field: 'modifiedDate'
        },
        {
            text: 'Modified By',
            field: 'modifiedBy'
        },
        {
            text: 'Created Date',
            field: 'createdDate'
        },
        {
            text: 'Created by',
            field: 'createdBy'
        },
        {
            text: 'Unique External Reference',
            field: 'primary.serialCode',
            width: '280px'
        },

    ];

    componentDidMount() {
        const { leftPanelRecords, match, setDocumentTitle } = this.props;
        const type = getStr(match, 'params.type');
        if(type){
            const entityName = this.setHeaderTitle(type, leftPanelRecords);
            setDocumentTitle(`Entities - ` + entityName);
        }else{
            setDocumentTitle(modulesAndPageTitles.entities.main);
        }
    }

    componentDidUpdate(prevProps) {
        const { leftPanelRecords, setDocumentTitle } = this.props;
        const pType = getStr(prevProps, 'match.params.type');
        const type = getStr(this.props, 'match.params.type');
        const pEntityId = getStr(prevProps, 'match.params.entityId');
        const entityId = getStr(this.props, 'match.params.entityId');
        if(type && type !== pType) {
            const entityName = this.setHeaderTitle(type, leftPanelRecords);
            if(entityName){
                setDocumentTitle(`Entities - ` + entityName);
            }
        }
        if (pType !== type || pEntityId !== entityId) {            
            this.resetListLoading();
            this.refreshView();
        }
    }

    @bind
    refreshView(){
        const { resetView } = this.listRef.current || {};
        resetView && resetView();
    }

    @bind
    resetListLoading() {
        const listRef = get(this.listRef, 'current.listRef.current');

        if (listRef) {
            listRef.resetFirstLoading && listRef.resetFirstLoading();   
        }
    }

    @bind
    @memoize()
    setFilterDefinitions(type) {
        const filters = entitiesDefaultFilterDefinitions([type]);
        filters.splice(3, 0, {
            field: 'relations.relatedEntity.id',
            type: 'relatedEntities',
            properties: {
                label: 'Related Entities',
                name: 'relatedEntities',
            },
            condition: 'in',
            sort: false,
        });
        return [...filters];
    }

    @bind
    loadData({ isShort, options }) {
        const { loadEntitiesListView, match } = this.props;
        const type = getStr(match, 'params.type');
        let filterBy = [...options.filterBy];

        if (this.state.isRelatedEntity) {
            const relationDefinitionId = getStr(match, 'params.id');
            const entityId = getStr(match, 'params.entityId');
            filterBy.push(
                { field: 'relations.relation.relationDefinition.id', op: '=', value: relationDefinitionId },
                { field: 'relations.relatedEntity.id', op: '=', value: entityId }
            );
        }

        if (type){
            filterBy = filterBy.map((item) => (item.field === 'relations.relatedEntity.id' && Array.isArray(item.value)) ? 
                { ...item, value: item.value.map(v => v.id) } : 
                item
            );
            
            return loadEntitiesListView({ isShort, options: { ...options, filterBy }, type });
        }
    }

    @bind
    @memoize()
    setHeaderTitle(type, leftPanelRecords) {
        const { name } = leftPanelRecords.find(cls => cls.uri === type) || {};
        return name || 'Entities';
    }

    render() {
        const { startIndex, isLoading, records, totalRecords, location, leftPanelRecords } = this.props;
        const type = getStr(this.props, 'match.params.type');
        const headerTitle = this.setHeaderTitle(type, leftPanelRecords);
        const defaultLoading = isLoading || (isLoading && !startIndex);
        const { permissions } = this.props.userProfile;
        const permissionsSet = new Set(permissions || []);
        const filterDefinitions = this.setFilterDefinitions(type);
      
        return (
            <PageTemplate title="Entities">
                <ContentArea>
                    {type ? (
                        <EntitiesList
                            pageViewId={VIEW_ID}
                            id={`${VIEW_ID}.${type}`}
                            ref={this.listRef}
                            type={type}
                            headerTitle={headerTitle}
                            records={records}
                            totalRecords={totalRecords}
                            startIndex={startIndex}
                            isLoading={defaultLoading}
                            loadData={this.loadData}
                            columnDefinitions={this.columnDefinitions}
                            filterDefinitions={filterDefinitions}
                            defaultFilters={this.defaultFilters}
                            entitiesView // we would remove it when we finalized primary classes
                            downloadable
                            location={location}
                            canAdd={permissionsSet.has('entity.entity.add')}
                            uploadable
                        />
                    ) : (
                        <Contaienr>
                            <OpenLeftSidebarButton />
                            <Flex>
                                <IconStyled name="cursor-default-outline" />
                                <StartedText>
                                    Let's get started
                                    <br />
                                    <SubTitle>Select an entity type</SubTitle>
                                </StartedText>
                            </Flex>
                        </Contaienr>
                    )}
                </ContentArea>
            </PageTemplate>
        );
    }
}

export default connect(
    (state: Object) => ({
        records: state.entities.entities.records,
        totalRecords: state.entities.entities.count,
        isLoading: state.entities.entities.isLoading,
        startIndex: state.entities.entities.startIndex,
        userProfile: state.user.profile,
        leftPanelRecords: state.app.allPrimaryClasses.records || []
    }),
    { loadEntitiesListView, setDocumentTitle }
)(EntitiesListView);
