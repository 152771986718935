/* @flow */

import { gql } from '@apollo/client';

export default gql`
    query organisationConfigurationQuery {
        affectli: affectliSettings {
            site {
                name
                fullName
                contact {
                    id
                    name
                }
                learningUrl
                reportingUrl
            }
            sso {
                availableIdentityProviders
                availableRequiredActions
            }
        }
    }
`;
