/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { get } from 'app/utils/lo/lo';
import { bind } from 'app/utils/decorators/decoratorUtils';
import MapLayersList from 'app/containers/Maps/Drawers/MapLayersList';
import MapEntityDetails from 'app/containers/Maps/Drawers/MapEntityDetails';
import Loader from 'app/components/atoms/Loader/Loader';
import { setTitle, setSubTitle } from 'store/actions/sidebar/sidebarActions';
import AboutMapSearch from 'app/containers/Maps/EntityAboutMap/AboutMapSearch';
import { openProcessSidebar } from 'store/actions/abox/processSidebarActions';
import { openTaskSidebar } from 'store/actions/abox/taskSidebarActions';
import { TASKLAYERS, PROCESSLAYERS } from 'app/utils/maps/layer/layerUtils';
import ReplayLayerAbout from 'app/containers/Maps/LayerAbout/ReplayLayer/ReplayLayerAbout'; 

class MapSidebar extends PureComponent<Object, Object> {
    static propTypes = {
        title: PropTypes.string,
        isOpen: PropTypes.bool
    };

    constructor(props: Object) {
        super(props);
        this.initData();
    }

    initData() {
        const { isOpen, title, name, mapData } = this.props;
        const mapName = mapData?.name;
        if (!isOpen) {
            return;
        }
        if (mapName) this.setSubTitle(mapName);
        if (title || name) this.setTitle(title || name);
    }

    @bind
    setTitle(title: string) {
        this.props.setTitle(title);
    }

    @bind
    setSubTitle(name) {
        this.props.setSubTitle(name);
    }

    @bind
    buildSidebarComponent() {
        const { id, title, map, mapId, svgSource, snackbarRef, type } = this.props;
        const { openProcessSidebar, openTaskSidebar } = this.props;
       
        if (TASKLAYERS.includes(type)) {
            openTaskSidebar({ id, title });
            return null;
        }
        if (PROCESSLAYERS.includes(type)) {
            openProcessSidebar({ id, title });
            return null;
        }
        
        switch (title) {
            case 'Layers':
                return <MapLayersList map={map} mapId={mapId} {...this.props} />;
            case 'SvgMapSearch':
            case 'Search':
                return <AboutMapSearch map={map} title={title} svgSource={svgSource} snackbarRef={snackbarRef} id={id} type={type} />;
            case 'Replay Layer': 
                return <ReplayLayerAbout {...this.props} />;
            default:
                return <MapEntityDetails id={id} type={type} map={map} title={title} />;
        }
    }

    render() {


        const { isOpen, isLoading } = this.props;
        if (!isOpen) {
            return;
        }
        if (isLoading) {
            return <Loader absolute backdrop />;
        }
        return this.buildSidebarComponent();
    }
}

export default connect(
    (state: Object, ownProps: Object) => ({
        isLoading: state.entities.relations.isLoading,
        isOpen: state.sidebar.isOpen,
        expanded: state.sidebar.expanded,
        title: state.sidebar.title,
        windowWidth: state.global.window.width,
        mapData: get(state.maps, 'situationalAwareness.map.data', {})
    }),
    {
        setTitle,
        setSubTitle,
        openProcessSidebar,
        openTaskSidebar
    }
)(withRouter(MapSidebar));
