import { gql } from '@apollo/client';
import { entityReferences } from '../relationships/relationsQuery';

export default gql`query loadEntityRelationsQuery($entityType: String!, $entityId: ID!, $filterBy: [JSON], $orderBy: [JSON]) {
    relations: entityRelations(entityType: $entityType, entityId: $entityId, filterBy: $filterBy, orderBy: $orderBy) {
      entity {
        ${entityReferences}
      }
      relatedEntity{
        ${entityReferences}
      }
      isReverseRelation
    }
}`;
