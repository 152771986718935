/* @flow */

import { gql } from '@apollo/client';

export default gql`
    mutation saveProcessVariablesMutation($id: ID!, $variables: [BpmnVariableInput]!) {
        saveProcessVariables(id: $id, variables: $variables) {
          id
        }
    }
`;
