/* @flow */
import { gql } from '@apollo/client';

export default gql`
query relationUserToTeamQuery($filterBy: [JSON]) {
    result: teams(filterBy: $filterBy) {
         workspace {
             id
             name
             isSystem
             __typename
        }
    }
}
`;