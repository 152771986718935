/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import ClassificationsTab from 'app/containers/Common/ClassificationsTab/ClassificationsTab';
import EntityPrimaryAbout from 'app/containers/Entities/EntityAbout/EntityPrimaryAbout';
import EntityLocation from 'app/containers/Entities/EntityLocation/EntityLocation';
import AttachmentsView from 'app/containers/Common/Attachments/AttachmentsView';
import SidebarMenu from 'app/components/organisms/SidebarMenu/SidebarMenu';
import EntityDigitalTwin from '../Entities/DigitalTwin/EntityDigitalTwin';
import EntityHistoryTab from '../Entities/common/Tabs/EntityHistoryTab';
import ChatSharing from 'app/components/organisms/Chat/ChatSharing';
import DotMenu from 'app/components/molecules/DotMenu/DotMenu';
import Loader from 'app/components/atoms/Loader/Loader';
import EntityVersions from 'app/containers/Entities/EntityVersions/EntityVersions';
import history from 'store/History';
import PageNotAllowed from 'app/containers/ErrorPages/PageNotAllowed';
import BackgroundJobLogs from 'app/containers/Abox/BackgroundJob/BackgroundJobLogs';
import ParentsChildrenAppliesToSidebar from 'app/containers/Classifications/ParentsChildrenAppliesToSidebar';
import { setTreeEntity } from 'store/actions/entities/relatedEntitiesActions';
import { setActions, setSubTitle, closeSidebar, setTitle } from 'store/actions/sidebar/sidebarActions';
import { shrinkSidebar, expandSidebar } from 'store/actions/sidebar/sidebarActions';
import { loadEntity, loadEntityInternal, getPersonType } from 'store/actions/entities/entitiesActions';
import { loadAllPrimaryClasses } from 'store/actions/app/appActions';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { getSubscriptionByRel } from 'app/utils/chat/chatUtils';
import { toUniqueUUID } from 'app/utils/string/string-utils';
import { mediaBreakpoints } from 'app/themes/breakpoints';
import { getPermissions } from 'app/config/rolesConfig';
import { isEmpty } from 'app/utils/utils';
import { buildDotMenu } from 'app/utils/entity/entityUtils';
import UserAbout from 'app/containers/Admin/UserManagement/Details/UserAbout';
import UserWorkspaces from 'app/containers/Admin/UserManagement/Details/UserWorkspaces';
import UserTeams from 'app/containers/Admin/UserManagement/Details/UserTeams';
import { loadEntityPrintTemplates } from 'store/actions/entities/printTemplateActions';
import { getSelectedPrimaryClass } from 'app/utils/classification/classificationUtils';
import Chat from 'app/components/organisms/Chat/Chat';

class EntitySidebar extends PureComponent<Object, Object> {

    static propTypes = {
        id: PropTypes.string,
        type: PropTypes.string,
        title: PropTypes.string,
        isOpen: PropTypes.bool,
        hideDotMenu: PropTypes.bool,
        reloadList: PropTypes.func,
        // from redux
        isLoading: PropTypes.bool,
        details: PropTypes.object,

        // for isolated details
        detailsInternal: PropTypes.object,
        internal: PropTypes.bool,
    };

    static defaultProps = {
        hideDotMenu:false,
    };

    otherTabs = [
        'Workspaces',
        'Teams'
    ]

    state = {};

    constructor(props: Object) {
        super(props);
        const { type, loadAllPrimaryClasses } = props;
        this.state = { entityType: type, loadingType: type === 'person' };
        loadAllPrimaryClasses();
        this.mounted = false;
    };

    componentDidMount(){
        this.loadDetails();
        this.mounted = true;
    }

    componentDidUpdate(prevProps) {
        const { id } = this.props;
        if (id && prevProps?.id !== id) {
            this.loadDetails();
        }
        this.initData();
    }

    @bind
    loadDetails(params){
        const { id: entityId, type: entityType, title, internal: paramInternal } = params || {};
        const { id: eId, type: eType, internal, loadEntityPrintTemplates } = this.props;
        
        const id = entityId || eId;
        const type = entityType || eType;
        if(!isEmpty(paramInternal) ? paramInternal : internal) {
            this.props.loadEntityInternal(type, id);
        } else {
            this.props.loadEntity(type, id);
        }
        this.loadEntityData();

        const selectedClass = getSelectedPrimaryClass(type, this.props.primaryClasses);
        if(!entityId && selectedClass) {
            loadEntityPrintTemplates(selectedClass.id);
        }

        if(title) {
            this.props.setTitle(title);
        }

    }

    @bind
    async loadEntityData(){
        const { id, type } = this.props;
        if (type === 'person') {
            const entityType = await getPersonType(id, type);
            this.setState({ entityType, loadingType: false });
        } else {
            this.setState({ entityType: type });
        }
    }

    @bind
    setActions(expanded, sidebarTitle, details) {
        const { entityTemplates } = this.props;
        const dotMenu = this.dotMenu(expanded, sidebarTitle, details, entityTemplates);
        this.props.setActions(dotMenu);
    }

    initData() {
        const { id, isOpen, isLoading, details, expanded, title, hideDotMenu } = this.props;
        if (!isOpen) return;

        if(!hideDotMenu)this.setActions(expanded, title, details);

        if (isLoading) return;

        const loaded = details || {};
        if (loaded.id === id) {
            if (title === 'Sharing') {
                const subTitle = `${details.name} #${toUniqueUUID(details.id)}`;
                this.props.setSubTitle(subTitle);
            } else {
                this.props.setSubTitle(details.name);
            }
        }
        if(!['Applies To', 'Versions'].includes(title)){
            if(details && !buildDotMenu({ details }).find(m => m.name === title) && !this.otherTabs.includes(title)) {
                this.props.setTitle('About');
            }
        }
    }

    @bind
    async onDotMenuClick(title) {
        const { id, type, setTreeEntity } = this.props;
        if (title === 'Expand' && this.props.title === 'Location') {
            return history.push(`/entities/${type}/${id}/location`);
        }
        if (title === 'Relations') {
            return history.push(`/entities/${type}/${id}/relationships`);
        }
        if (title === 'Go to Related Entities') {
            const { id, name, image, type, modifiedDate, primary } = { ...this.props.details };
            setTreeEntity({ id, name, image, type, modifiedDate, primary });
            history.push(`/related-entities`);
            return;
        }
        if (['Collapse', 'Expand'].includes(title)) {
            const { expanded, expandSidebar, shrinkSidebar } = this.props;
            const action = expanded ? shrinkSidebar : expandSidebar;
            action();
            return;
        }
        if (title === 'Go to details') {
            this.goToDetails();
        }
        this.props.setTitle(title);
    }

    goToDetails() {
        const { windowWidth, id, type, shrinkSidebar, closeSidebar, location } = this.props;
        if (!windowWidth < mediaBreakpoints.sm) {
            shrinkSidebar();
        } else {
            closeSidebar();
        }
        if(type === 'user' && location.pathname === '/user-management'){
            history.push(`/user-management/${id}`);
        }else{
            history.push(`/entities/${type}/${id}`);
        }
    }

    @bind
    @memoize()
    dotMenu(expanded, title, details, entityTemplates) {
        const pathname = window.location.hash.toLowerCase();
        const { hiddenTabs } = this.props;
        return (
            <DotMenu
                key={13}
                onItemClick={this.onDotMenuClick}
                items={buildDotMenu({ details, title, pathname, expanded, hiddenTabs, entityTemplates })}
            />
        );
    }

    @bind
    renderSidebar(){
        const { 
            id, details, title, reloadList, subscription, setOnBack, menuDefinitions,  openEntitySidebar,
            internal, userAbout, adminBackgroundJobs, loadAdditionalDetails, expanded,
        } = this.props;
        const { entityType } = this.state;
        const role = details && details.role;
        const type = entityType || details.type;
        const permissions = getPermissions(role);
        const { canEdit } = permissions;
        const { rel, rid } = subscription || {};
        const props = { id, type, details, internal, reloadList, canEdit, loadAdditionalDetails };
        switch (title) {
            case 'About': {
                if (userAbout) {
                    return <UserAbout 
                        user={details}
                        canEdit={canEdit}
                        isOnSidebar={true}
                        openSidebar={this.onDotMenuClick}
                        reloadList={reloadList}
                        isSidebar
                    />;
                }
                const isPublishedEntity = ['iot_pipeline', 'iot_connector', 'iot_module'].includes(type);
                return <EntityPrimaryAbout 
                    isPublishedEntity={isPublishedEntity} 
                    reloadDetails={this.loadDetails} 
                    openEntitySidebar={openEntitySidebar}
                    openSidebar={this.onDotMenuClick} 
                    adminBackgroundJobs={adminBackgroundJobs}
                    isSidebar
                    {...props} 
                />;
            }
            case 'Log':
                return <BackgroundJobLogs fromSidebar {...props} />;
            case 'Location':
                return <EntityLocation fromSidebar {...props} refreshMainSection />;
            case 'Classes':
                return <ClassificationsTab isUser={(type === 'person' && entityType === 'user') || userAbout} name={details.name} reloadDetails={this.loadDetails} openEntitySidebar={openEntitySidebar} {...props} />;
            case 'Attachments':
                return <AttachmentsView reloadDetails={this.loadDetails} permissions={permissions} {...props} />;
            case 'History':
                return <EntityHistoryTab {...props} />;
            case 'A-Live':
                return <Chat {...props} />;
            case 'View Settings':
                return <SidebarMenu setOnBack={setOnBack} menuDefinitions={menuDefinitions} {...props} />;
            case 'Digital Twin':
                return <EntityDigitalTwin type={entityType} entity={details} inSidebar={true} expanded={expanded} />;
            case 'Sharing':
                return <ChatSharing rid={rid} rel={rel} role={role} selectedSidebar={'share'} {...props} />;
            case 'Workspaces':
                return <UserWorkspaces user={details} />;
            case 'Teams':
                return <UserTeams user={details} />;
            case 'Applies To':
                return <ParentsChildrenAppliesToSidebar details={details} internal={internal} refreshMainSection />;
            case 'Versions':
                return <EntityVersions disabled={!canEdit} details={details} />;
            default:
                return null;

        }
    }

    render() {
        const { isLoading: dataLoading, id, details, isOpen  } = this.props;
        const { loadingType } = this.state;
        const role = details && details.role;
        const { canView } = getPermissions(role);
        const isSameDetailsAndNotMounted = !this.mounted && details?.id === id;
        const isLoading = dataLoading || loadingType || isSameDetailsAndNotMounted;
        const isLoaded = !isLoading && details?.id && !isSameDetailsAndNotMounted;
        if (!isLoading && (isEmpty(details) || !canView)) {
            return <PageNotAllowed padded permissionError={true} title={`Entity. (ID:${id})`} />;
        }
        return isOpen && (
            <>
                {isLoading && <Loader absolute backdrop/>}
                {isLoaded && this.renderSidebar()}

            </>
        );
    }
}

export default connect(
    (state: Object, ownProps: Object) => {
        const { id: propsId, type: propsType } = ownProps;
        const details = ownProps.internal ? state.entities.sidebar.detailsInternal.data : state.entities.sidebar.details.data;
        const { id, type } = details || {};
        const subscriptions = state.chat.subscriptions.data;
        const subscription = getSubscriptionByRel(subscriptions, { type: propsType || type, id: propsId || id });
        return {
            details: propsId !== id ? null : details,
            subscription,
            isOpen: state.sidebar.isOpen,
            isLoading: ownProps.internal ? state.entities.sidebar.detailsInternal.isLoading : state.entities.sidebar.details.isLoading,
            expanded: state.sidebar.expanded,
            windowWidth: state.global.window.width,
            title: state.sidebar.title,
            actions: state.sidebar.actions,
            entityTemplates: state.entities.printTemplates.entity.records,
            primaryClasses: state.app.allPrimaryClasses.records || [],
        };
    },
    { 
        shrinkSidebar, expandSidebar, loadEntity, loadEntityInternal, closeSidebar, 
        setActions, setSubTitle, setTitle, loadAllPrimaryClasses, loadEntityPrintTemplates, setTreeEntity
    },
)(withRouter(EntitySidebar));
