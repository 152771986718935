/* @flow */

import React from 'react';
import { Typography } from '@mic3/platform-ui';
import styled from 'styled-components';

import AbstractBuilder from 'app/components/Designer/Builders/AbstractBuilder/AbstractBuilder';
import AbstractTableDnd from 'app/components/Designer/Builders/AbstractBuilder/AbstractTableDnd';
import { bind } from 'app/utils/decorators/decoratorUtils';

const TypographyPadded = styled(Typography)`
    padding: 12px 0 0 0;
`;

class ExecutionListenersFieldsBuilder extends AbstractBuilder {

    fieldsDefinition = [
        { type: 'text', properties: { label: 'Name', name: 'name', clearable: false }},
        { type: 'typeahead', properties: {
            label: 'Field Type', name: 'fieldType',
            valueField: 'value', clearable: false,
            options: [{ label: 'Value', value: 'string'}, { label: 'Expression', value: 'expression'}],
            defaultValue: 'string'
        }, },
        {
            type: 'textarea',
            properties: {
                modal: true,
                useCodeEditor: true,
                parseAs: 'javascript',
                title: 'Value',
                name: 'value',
                label: 'Value',
                fullJs: true,
                trimOnSave: true,
                noValidation: true,
            },
        }
    ]

    componentDidUpdate(prevProps, prevState) {
        const { value } = this.state;
        const { value: propsValue } = this.props;
        if(prevState.value !== value && propsValue !== value) {
            this.props.onChange(value);
        }
        if(prevProps.value !== propsValue) {
            this.setState({ value: propsValue });
        }
    }


    @bind
    deNormalizeValues(definition: Array<Object>): Array<Object> {
        return definition.fields || [];
    }

    render() {
        const { value } = this.state;
        const { label, saveAndClose, toggleShowLoader } = this.props;
        return (
            <>
                <TypographyPadded variant="caption">{label}</TypographyPadded>
                <AbstractTableDnd
                    saveAndClose={saveAndClose}
                    name={this.props.name}
                    uuid={this.uuid}
                    onDrop={this.onDrop}
                    addValue={this.addValue}
                    deleteValue={this.deleteValue}
                    value={value}
                    onChange={this.handleOnChange}
                    fields={this.fieldsDefinition}
                    toggleShowLoader={toggleShowLoader}
                />
            </>
        );
    }
};

export default ExecutionListenersFieldsBuilder;
