/* @flow */

import { gql } from '@apollo/client';
import entityDetailsCommonQuery from 'graphql/entities/common/entityDetailsCommonQuery';

export default gql`
    mutation updateEntityMutation($record: EntityUpdateInput!) {
        result: updateEntity(record: $record) {
            type
            ${entityDetailsCommonQuery}
        }
    }
`;
