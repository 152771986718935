/* @flow */

import React, { Fragment, PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Button, CircularProgress } from '@mic3/platform-ui';

import BaseModal from 'app/components/Designer/Modals/BaseModal';
import Container from 'app/components/atoms/Container/Container';
import HeaderBar from 'app/components/molecules/HeaderBar/HeaderBar';
import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import DotMenu from 'app/components/molecules/DotMenu/DotMenu';
import MainSection from 'app/components/organisms/EntitySections/MainSection';
import JobStatusSection from 'app/components/organisms/EntitySections/JobStatusSection';
import PrintTemplateScript from 'app/components/organisms/EntitySections/PrintTemplateScript';
import AboutSection from 'app/components/organisms/EntitySections/AboutSection';
import TreeSection from 'app/components/organisms/EntitySections/TreeSection';
import AttributeSection from 'app/components/organisms/EntitySections/AttributeSection';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import ChatFileUploadModal from 'app/components/organisms/Chat/ChatFileUploadModal';
import { uploadRoomFile, deleteChatRoomAttachment } from 'store/actions/chat/chatActions';
import { loadEntity, updateEntity, uploadImage, publishEntity } from 'store/actions/entities/entitiesActions';
import { resetTree } from 'store/actions/entities/relatedEntitiesActions';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { set, get } from 'app/utils/lo/lo';
import { groupFields } from 'app/utils/classification/classificationForm';
import { filterAttributesAsPerDefinitions, getOnlyUpdatedData } from 'app/utils/app/appUtils';
import { copyToClipboard, removeAttributeFromFields } from 'app/utils/classification/classificationUtils';
import { setDocumentTitle, showToastr } from 'store/actions/app/appActions';
import { ContactInfoListForm } from 'app/components/Forms/ContactInfoListForm/ContactInfoListForm';
import { enrichContext } from 'app/utils/designer/form/formUtils';
import { buildTreeEntityObject } from 'app/utils/relatedEntity/relatedEntityUtils';
import { getIconAttribute, getIconTypeAttribute, getColorAttribute } from 'app/utils/entity/entityUtils';
import { serializeAttributes, deserializeAttributes } from 'app/utils/bpmn/bpmnEngineUtils';
import { removeSpecialCharacters } from 'app/utils/string/string-utils';
import { MAP_LAYERS } from 'app/utils/maps/layer/layerUtils';
import { typeMap } from 'app/config/typesConfig';
import { setEntityPreviewVersion, setEntityDraftVersion } from 'store/actions/entities/entitiesActions';


const _removeContactInfo = fields => fields?.map((field) => {
    const children = field?.children?.filter(f => f?.properties?.name !== 'contactInfo');
    return { ...field, children };
});

const _removeDOB = fields => fields?.map((field) => {
    const children = field?.children?.filter(f => f?.properties?.name !== 'dateOfBirth');
    return { ...field, children };
});

const renameComponents = [{
    type: 'text',
    properties: {
        name: 'name',
        label: 'Entity name'
    },
    constraints: { required: true },
}];

const PublishedButton = styled(Button)`
    margin: 0 8px !important;
`;

class EntityPrimaryAbout extends PureComponent<Object, Object> {
    static propTypes: Object = {
        id: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        details: PropTypes.object.isRequired,
        updateEntity: PropTypes.func.isRequired,
        uploadImage: PropTypes.func,
        isLoading: PropTypes.bool,
        canEdit: PropTypes.bool,
        imageData: PropTypes.object,
        userProfile: PropTypes.object,
        reloadList: PropTypes.func
    };

    formRef: Object = React.createRef();
    formRefTree: Object = React.createRef();
    renameRef: Object = React.createRef();
    subFormReferences: Array<any> = [];
    dropzoneUploads: Array<any> = [];

    /**
     * @param props the Component's properties
     */
    constructor(props: Object) {
        super(props);
        const { details } = props;
        this.state = {
            entityForm: this.buildEntityForm(details),
            imageError: false,
            renameActive: false,
            files: [],
            fileDeleteHandlers: [],
        };
    }

    componentDidMount() {
        const { details, setDocumentTitle, isSidebar } = this.props;
        !isSidebar && setDocumentTitle(details?.name);
        this.subFormReferences.push(this.formRef, this.formRefTree);
    }

    /**
     * @override
     */
    componentDidUpdate(prevProps: Object, prevState: Object) {
        const { details, previewVersion, draftedDetails } = this.props;
        const { entityForm } = this.state;

        if (details && prevProps.details !== details) {
            this.setState({ entityForm: this.buildEntityForm(details) });
        }

        if (previewVersion && previewVersion !== prevProps.previewVersion) {
            const { primaryClass, type, classes } = details;
            const nextState = { 
                isPreviewVersion: !!previewVersion,
                entityForm: this.buildEntityForm(previewVersion, { primary: previewVersion.primary, primaryClass, type, classes }),
            };
            if(!prevState.isPreviewVersion) {
                nextState.draftedEntityform = entityForm;
            }

            this.setState(nextState);
        }

        if (draftedDetails && draftedDetails !== prevProps.draftedDetails) {
            const { name, description, primary, iconType, iconName, iconColor, image } = draftedDetails;
            this.setState({ 
                isPreviewVersion: false,
                entityForm: this.buildEntityForm(
                    details, 
                    { name, description, primary, iconType, iconName, iconColor, image }
                ),
            });
            this.props.setEntityDraftVersion(details.type, null);
        }    

    }

    @bind
    buildEntityForm(details, extraProps = {}) {
        const nextPrimary = extraProps.primary 
            ? this.normalizePrimaryFromVersioned(extraProps.primary)
            : details.primary;

        const nextDetails = {
            ...details,
            ...extraProps,
            primary: nextPrimary,
        };

        const icon = getIconAttribute(nextDetails);

        return {
            ...this.sanitizeData(nextDetails),
            primary: deserializeAttributes(
                this.buildPrimaryComponents(nextDetails),
                nextDetails?.primary || {}
            ),
            iconState: {
                value: icon,
                label: icon,
                type: getIconTypeAttribute(nextDetails)
            },
            iconColor: getColorAttribute(nextDetails)
        };
    }

    @bind
    normalizePrimaryFromVersioned(versionedPrimary) {
        const { details: { type, primary } } = this.props;
        return Object.keys(versionedPrimary).reduce((accum, key) => {
            const nextKey = key.replace('_version', '');
            if(nextKey === `${type}/binary` || nextKey === `${type}/wasm`) {
                accum[nextKey] = primary[nextKey];
            } else {
                accum[nextKey] = versionedPrimary[key];
            }
            return accum;
        }, {});
    }

    @bind
    backToSaveDraft() {
        this.props.setEntityPreviewVersion('iot_pipeline', null);
        this.setState({ 
            isPreviewVersion: false,
            entityForm: this.state.draftedEntityform,
            draftedEntityform: null,
        });
    };

    @bind
    @memoize()
    sanitizeData(data) {
        if(!data) return {};
        if (data.type === typeMap.map) {
            const details = { ...data };
            const fields = [...(details?.primaryClass?.formDefinition?.fields || [])];
            const updatedFields = removeAttributeFromFields(fields, MAP_LAYERS);
            return set(details, 'primaryClass.formDefinition.fields', updatedFields);
        }
        return data;
    }

    @bind
    pushDropzoneReferences(referenceData) {
        const { reference } = referenceData;
        !this.dropzoneUploads.find(f => f?.reference === reference) && this.dropzoneUploads.push(referenceData);
    }

    @bind
    removeAttachment(removedFile) {
        const { fileDeleteHandlers: deleteHandlers } = this.state;
        const fileDeleteHandlers = [ ...(deleteHandlers || [])];

        if((removedFile.id && fileDeleteHandlers.find(f => f.id === removedFile.id)) || !removedFile.id) {
            return;
        }

        if(removedFile.id) {
            fileDeleteHandlers.push(removedFile);
        }

        this.setState({ fileDeleteHandlers });
    }

    @bind
    async uploadFile({ file, filename, description }: Object) {
        const { id, type } = this.props;
        const fileHandler = this.state.files[0];

        const fileExtension = file.name.split('.').pop();
        let parsedFilename = removeSpecialCharacters(filename);

        if (fileExtension !== parsedFilename.split('.').pop()) {
            parsedFilename = `${parsedFilename}.${fileExtension}`;
        }

        file = new File([file], parsedFilename, { type: file?.type });

        try {
            const response = await this.props.uploadRoomFile({ type, id, file, description });
            const meta = response && response.file;
            
            if (!meta) {
                fileHandler.reject(new Error('Invalid response.'));
            } else {
                fileHandler.resolve({
                    src: window.encodeURI(`/chat/file-upload/${meta.id}/${meta.name}`),
                    alt: response.msg || '',
                    reference: fileHandler.reference,
                    index: fileHandler.index,
                    name: file.name,
                    type: file?.type,
                    id: response.file.id,
                });
            }
        } catch(error) {
            fileHandler.reject(error);
        }

        this.setState({ files: this.state.files.slice(1) });
    }

    @bind
    async uploadDropzoneFiles(details) {
        let entityFormData = { ...details };
        const promises = this.dropzoneUploads.map((dp) => {
            const isTopLevel = !dp.path;
            const data = get(details, isTopLevel ? dp.reference : dp.path);
            if(!data || (!Array.isArray(data) && !get(data, dp.reference))) {
                return Promise.resolve();
            }
            if(isTopLevel) {
                return data.map((file, index) => !(file instanceof File) ? Promise.resolve() : this.appendFile(file, { reference: dp.reference, index }));
            }
            if(Array.isArray(data)) {
                return data.map((groupData, grIindex) => {
                    const files = get(groupData, dp.reference);
                    if(!files) {
                        return Promise.resolve();
                    }
                    return files.map((file, index) => {
                        try {
                            return !(file instanceof File) ? Promise.resolve() : this.appendFile(file, { reference: `${dp.path}[${grIindex}].${dp.reference}`, index });
                        } catch(err) {
                            return Promise.resolve();
                        }
                    });
                });
            } else {
                const files = get(data, dp.reference);
                return files.map((file, index) => !(file instanceof File) ? Promise.resolve() : this.appendFile(file, { reference: `${dp.path}.${dp.reference}`, index }));
            }
        });

        const results = await Promise.all(promises.flat(Infinity));
        results.filter(Boolean).forEach((image) => {
            let files = [...get(entityFormData, image.reference, [])];
            if(image.canceled) {
                files = files.filter(f => f.path !== image.file.path);
            } else {
                files.splice(image.index, 1, image);
            }
            entityFormData = set(entityFormData, image.reference, files);
        });
        return entityFormData;
    }

    @bind
    async deleteDropzoneFiles() {
        const { fileDeleteHandlers } = this.state;
        const { deleteChatRoomAttachment } = this.props;
        const promises = fileDeleteHandlers.map(async (file) => {
            const { id: fileId } = file || {};
            if(fileId) {
                const resp = await deleteChatRoomAttachment(fileId);
                if(resp instanceof Error) {
                    return Promise.resolve();
                }    
            }
        });
        return Promise.all(promises);
    }

    @bind
    appendFile(file, options) {
        let resolve, reject;
        const promise = new Promise((_resolve, _reject) => {
            resolve = _resolve;
            reject = _reject;
        });
        const fileHandler = {
            file,
            promise,
            resolve,
            reject,
            ...(options || {})
        };
        this.setState(state => (
            { files: [...state.files, fileHandler] }
        ));
        return fileHandler.promise;
    }

    @bind
    async rejectFile() {
        const { files }= this.state;
        const rejectedFile = files.shift();
        if(rejectedFile) {
            rejectedFile.resolve({ ...rejectedFile, canceled: true });
        }
        this.setState(state => ({ files }));
    }

    @bind
    @memoize()
    buildContext(details) {
        return enrichContext({ entity: details });
    }

    @bind
    handleChangeAttributes(data, change) {
        const nextState = set(this.state.entityForm, change.name, change.value);
        this.setState(state => ({ entityForm: nextState }));
    }

    @bind
    handleChange(e, callback) {
        const { target: { name, value }} = e;
        if (name === 'image') {
            const reader = new FileReader();
            reader.onloadend = () => {
                this.setState({ entityForm: {
                    ...this.state.entityForm,
                    imagePrev: reader.result
                }});
            };
            reader.readAsDataURL(value);
        }
        const nextState = { entityForm: set(this.state.entityForm, name, value) };

        if(name === 'iconState') {
            nextState.entityForm = {
                ...nextState.entityForm,
                iconName: value?.value || null,
                iconType: value?.type || null,
            };
        }
        this.setState(nextState, callback);
    }

    @bind
    loadDetails() {
        const { details: { id }, loadEntity, type } = this.props;
        return loadEntity(type, id);
    }

    @bind
    async onFormSubmit(isPublish = false) {
        const { details: data } = this.props;
        const details = this.sanitizeData(data);
        const { fileDeleteHandlers } = this.state;
        let { entityForm = {} } = this.state;
        const formDefinition = {...details?.primaryClass?.formDefinition};
        const parents = details?.primaryClass?.parents || [];
        // Adding parent form definitions to the form definitions because when we filter attributes with definitions it remove the 
        // parent attributes from attributes as they do not match the form definitions
        const fields = parents.reduce((fields, p) => {
            if(p?.formDefinition?.fields?.length ){
                fields = [...fields, ...p?.formDefinition?.fields];
            }
            return fields;
        }, formDefinition?.fields || []);
        const validations = await Promise.all(this.subFormReferences.map(async (reference) => {
            const form = reference.current;
            return { name: get(form, 'props.name', ''), form: form && await form.isValidForm()};
        }));
        if(!validations.filter(({ form }) => !!(form && form.errors)).length) {
            validations.forEach(({ name, form }) => {
                if(name && name !== 'contactInfo' && name !== 'tree') {
                    entityForm = set(entityForm, name, form.data);
                } else if (name === 'tree') {
                    const { relationTree, treeType } = form.data;
                    const listByRoot = relationTree.listBy ? { listBy: relationTree.listBy } : {};

                    entityForm = set(entityForm, 'primary.treeType', treeType);
                    entityForm = set(entityForm, 'primary.relationTree', {
                        listEntityType: relationTree.relatedId,
                        root: {
                            id: relationTree.id,
                            children: buildTreeEntityObject(relationTree.children),
                            ...listByRoot
                        }
                    });
                }
            });
            
            if(this.dropzoneUploads.length) {
                entityForm = await this.uploadDropzoneFiles(entityForm);
            }
            if(fileDeleteHandlers.length) {
                await this.deleteDropzoneFiles();
            }
            // FILTERING ATTRIBUTES BECAUSE IF THERE IS AN ATTRIBUTE THAT IS REMOVED FROM DEFINITIONS THEN IT SHOULD NOT BE SENT TO BACKEND BECAUSE IT WILL THROW ERROR FOR NO DEFINITION FOUND
            const filteredAttributesData = filterAttributesAsPerDefinitions({ fields }, entityForm.primary);
            
            const changes = getOnlyUpdatedData(details, { ...entityForm, primary: filteredAttributesData });

            const { iconState, image, imagePrev, ...updatedData } = changes; // eslint-disable-line
            if(imagePrev) {
                await this.uploadImage(entityForm.image);
            }
            const updatedPrimary = serializeAttributes(this.buildPrimaryComponents(details), updatedData?.primary || {});
            
            this.setState({ fileDeleteHandlers: [], files: [] }, () => this.updateEntityDetails({...updatedData, primary: updatedPrimary}, isPublish));
        }
    }

    @bind
    async updateEntityDetails(updatedData, isPublish = false){
        const { details, reloadList, internal, publishEntity } = this.props;
        const { id, type } = details || {};
        const enableNormalize = type !== 'treetemplate';
        const resp = await this.props.updateEntity({...updatedData, id, type }, internal, false, enableNormalize);
        if (!(resp instanceof Error)) {
            reloadList && await reloadList();
            this.resetRelatedEntityState();
        }
        if(isPublish) {
            await publishEntity(id, type);
        }
        return Promise.resolve(resp);
    }

    @bind
    resetRelatedEntityState() {
        const { resetTree, selectedTreeEntity } = this.props;
        selectedTreeEntity && resetTree(true);
    }

    @bind
    async onDisableSubmit() {
        const { reloadList }= this.props;
        const { active, id, type } = this.state.entityForm;
        this.props.updateEntity({ id, type, active: !active }).then(async (resp) => {
            if (!(resp instanceof Error)) {
                reloadList && await reloadList();
            }
        });
    }

    @bind
    async uploadImage(image) {
        const { id, type } = this.props;
        return this.props.uploadImage(id, type, image);
    }

    @bind
    copyLinkToClipBoard() {
        const { showToastr, id, type } = this.props;
        copyToClipboard(`${window.location.origin}/#/entities/${type}/${id}`)
            .then(() => {
                showToastr({ severity: 'success', detail: 'Link copied to clipboard' });
            })
            .catch(() => {
                showToastr({ severity: 'error', detail: 'Link could not copied to clipboard' });
            });
    }

    @bind
    copyIdToClipBoard() {
        const { showToastr, id} = this.props;
        copyToClipboard(id)
            .then(() => {
                showToastr({ severity: 'success', detail: 'ID copied to clipboard' });
            })
            .catch(() => {
                showToastr({ severity: 'error', detail: 'ID could not copied to clipboard' });
            });
    }

    @bind
    onDotMenuClick(title) {
        if(title === 'Rename') {
            this.setState({ renameActive: true });
            return;
        }
        if(title === 'Disable' || title === 'Enable') {
            this.onDisableSubmit();
            return;
        }
        if(title === 'Copy link') {
            this.copyLinkToClipBoard();
            return;
        }
        if(title === 'Copy ID') {
            this.copyIdToClipBoard();
            return;
        }
        this.props.openSidebar(title);
    }

    @bind
    @memoize()
    dotMenu(entityForm, canEdit, listEntityTabs) {
        const {type}= this.props;
        return (
            <DotMenu
                key={13}
                onItemClick={this.onDotMenuClick}
                items={[
                    { name: 'Copy link', icon: 'link' },
                    { name: 'Copy ID', icon: 'content-copy' },
                    listEntityTabs?.includes('Share') ? { name: 'Sharing', icon: 'account-plus' }: null,
                    ( type !== 'backgroundjob') && { name: 'divider' },
                    ( type !== 'backgroundjob') && canEdit && { name: 'Rename', icon: 'pencil' },
                    ( type !== 'backgroundjob') && canEdit && { name: entityForm.active ? 'Disable' : 'Enable', icon: entityForm.active ? 'close' : 'check' },
                    ( type !== 'backgroundjob') && canEdit && { name: 'divider' },

                ].filter(Boolean)}
            />
        );
    }

    @bind
    pushSubFormReferences(ref) {
        this.subFormReferences.push(ref);
    }

    @bind
    buildPrimaryComponents(details) {
        const { type } = this.props;
        const isContactInfoVisible = ['person', 'organisation', 'user'].includes(type);
        let filteredFields = _removeContactInfo(details?.primaryClass?.formDefinition?.fields);
        if(type !== 'person'){
            filteredFields = _removeDOB(filteredFields);
        }
        return [
            details?.primaryClass?.formDefinition && {
                type: 'group',
                properties: { name: 'primary' },
                children: groupFields(filteredFields, { isCollapsed: true, classData: details?.primaryClass })
            },
            ...(details?.primaryClass?.parents || []).map(parent => ({
                type: 'group',
                properties: { name: 'primary' },
                children: groupFields(_removeContactInfo(parent?.formDefinition?.fields), { isCollapsed: true, classData: parent })
            })),
            isContactInfoVisible && {
                field: 'panel',
                type: 'panel',
                properties: {
                    header: 'Contact Info',
                    expanded: true,
                },
                children: [
                    {
                        field: 'primary.contactInfo',
                        type: 'custom',
                        properties: {
                            name: 'primary.contactInfo',
                            Component: (props)=> <ContactInfoListForm {...props} pushSubFormReferences={this.pushSubFormReferences} />
                        }
                    }
                ]
            },
        ].filter(Boolean);
    }

    @bind
    async handleConfirmRename() {
        const result = await this.renameRef.current.isValidForm();
        if(!result.errors) {
            const name = result?.data?.name?.trim();
            this.setState(({ entityForm }) => ({
                entityForm: { ...entityForm, name },
                renameActive: false
            }), async () => {
                await this.updateEntityDetails({ name });
            });
        }
    }

    @bind
    handleCloseRename() {
        this.setState({ renameActive: false });
    }

    @bind
    @memoize()
    buildPrimarySection(entityForm, context, disabled) {
        switch (entityForm?.type) {
            case 'treetemplate':
                return (
                    <TreeSection
                        formName="tree"
                        details={entityForm}
                        formRef={this.formRefTree}
                        disabled={disabled}
                    />
                );
            case 'system_map':
            case 'print-template':
            case 'backgroundjob': {
                return null;
            }
            default:
                return (
                    <AttributeSection
                        openEntitySidebar={this.props.openEntitySidebar}
                        openTaskSidebar={this.props.openTaskSidebar}
                        openProcessSidebar={this.props.openProcessSidebar}
                        reloadDetails={this.props.reloadDetails}
                        details={entityForm}
                        formRef={this.formRef}
                        context={context}
                        components={this.buildPrimaryComponents(entityForm)}
                        onChange={this.handleChangeAttributes}
                        pushDropzoneReferences={this.pushDropzoneReferences}
                        removeAttachment={this.removeAttachment}
                        disabled={disabled}
                        internal={this.props.internal}
                    />
                );
        }
    }
    @bind
    onStartChanges() {
        this.setState({disabledSaved: true});
    }
    @bind
    onEndChanges() {
        this.setState({disabledSaved: false});
    }

    @bind
    onTitleClick() {
        const { id } = this.props?.details?.primaryClass || {};
        this.props.history.push(`/entity-types/${id}/about`);
    }

    @bind
    @memoize()
    renderSaveButton(isAdmin, isLoading, canEdit, isPublishedEntity, isPreviewVersion) {
        const canEditForm = isAdmin || canEdit;
        const { type }= this.props;
        if (!canEditForm || type === 'backgroundjob' ) {
            return [];
        }
        if(isPreviewVersion) {
            return [<Button key={1131} onClick={this.backToSaveDraft}>back to saved draft</Button>];
        }
        return [
            isLoading
                ? <CircularProgress key={1132} size={24} color="primary" />
                : <Button key={1133} onClick={() => this.onFormSubmit(false)} color="primary"form="form" type="submit">Save</Button>,
            isPublishedEntity && <PublishedButton key={1134} onClick={() => this.onFormSubmit(true)} color="primary"form="form" type="submit">Publish</PublishedButton>
        ].filter(Boolean);
    }

    render(): Object {
        const { 
            breadcrumbLine, openSidebar, canEdit, details, 
            entityType, adminBackgroundJobs, isFileUploading,
            userProfile: { isAdmin }, isLoading, isPublishedEntity, isSidebar,
        } = this.props;
        const { entityForm, renameActive, disabledSaved, files, isPreviewVersion } = this.state;
        const context = this.buildContext(details);
        const {primaryClass} = details;
        const listEntityTabs = primaryClass?.entityTabs?.list;
        
        return (
            <Fragment>
                <HeaderBar left={breadcrumbLine} right={[
                    ...this.renderSaveButton(isAdmin, isLoading, canEdit, isPublishedEntity, isPreviewVersion),
                    ...(this.props.sidebarActions || []),
                ]} />
                <ContentArea withHeader isSidebar={isSidebar}>
                    <Container width="1024">
                        <MainSection
                            details={entityForm}
                            actions={this.dotMenu(entityForm, canEdit, listEntityTabs)}
                            entityType={entityType}
                            adminBackgroundJobs={adminBackgroundJobs}
                            onTitleClick={this.onTitleClick}
                        />
                        <JobStatusSection details={entityForm} />
                        <PrintTemplateScript canEdit={canEdit} details={entityForm} handleChange={this.handleChangeAttributes} />
                        <AboutSection disabled={isPreviewVersion || !canEdit} details={entityForm} handleChange={this.handleChange} 
                            openSidebar={openSidebar} primaryClass={primaryClass} entityType={entityType}/>
                        {this.buildPrimarySection(entityForm, context, !canEdit || isPreviewVersion)}
                    </Container>
                </ContentArea>
                <BaseModal
                    open={renameActive}
                    onClose={this.handleCloseRename}
                    onConfirm={this.handleConfirmRename}
                    header="Rename"
                    maxWidth='sm'
                    fullWidth
                    content={(
                        <FormGenerator
                            onStartChanges={this.onStartChanges}
                            onEndChanges={this.onEndChanges}
                            root={false}
                            data={entityForm}
                            components={renameComponents}
                            ref={this.renameRef}
                        />
                    )}
                    declineButtonText="Cancel"
                    confirmButtonText="Save"
                    confirmButtonDisabled = {disabledSaved}
                />
                {!!files[0] && (
                    <ChatFileUploadModal
                        file={files[0]?.file}
                        isUploading={isFileUploading}
                        uploadFile={this.uploadFile}
                        closeFileForm={this.rejectFile}
                    />
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = (state: Object, ownProps: Object) => ({
    isLoading: ownProps.internal ? state.entities.sidebar.detailsInternal.isLoading : state.entities.sidebar.details.isLoading,
    userProfile: state.user.profile,
    selectedTreeEntity: state.entities.relatedEntities.tree.selectedTreeEntity,
    isFileUploading: state.chat.room.isFileUploading,
    draftedDetails: state.entities.common.draftedDetails[ownProps.type],
    previewVersion: state.entities.common.previewVersion[ownProps.type],    
});

export default withRouter(connect(
    mapStateToProps,
    {
        updateEntity,
        uploadImage,
        loadEntity,
        showToastr,
        resetTree,
        publishEntity,

        uploadRoomFile,
        deleteChatRoomAttachment,
        
        setEntityPreviewVersion, 
        setEntityDraftVersion,
        setDocumentTitle
    }
)(EntityPrimaryAbout));
