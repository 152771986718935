import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { muiTheme } from 'app/themes/materialUi';
import { ListItem, ListItemText, Typography } from '@mic3/platform-ui';

import { CardDivider } from 'app/containers/Entities/EntitiesList/EntitiesListItem';
import TypeIcon from 'app/components/atoms/TypeIcon/TypeIcon';
import Icon from 'app/components/atoms/Icon/Icon';
import Loader from 'app/components/atoms/Loader/Loader';
import { iconsSet } from 'app/utils/styles/mdi';
import customIconSet from 'app/components/molecules/Map/EntityPin/customEntityPin.js';

const ListItemStyled = styled(ListItem)`
    position: relative;
    flex: 1;
    height: 52px;
    width: 100% !important;
    &.Mui-selected {
        background-color: ${({ theme }) => theme.material.colors.itemActive} !important;
    }

    & .MuiListItemText-primary {
        display: flex;
        align-items: center;
    }

    ${({ $isEntityClassChildren }) => {
    if ($isEntityClassChildren) {
        return `
            & .MuiListItemText-primary {
                display: flex;
                align-items: center;
                color: ${muiTheme.colors.text.secondary};
                font-style: italic;
            }
        `;
    }
    return `
        & .MuiTypography-body2 {
            font-weight: 500;
        }
    `;}}

    & .MuiListItemText-secondary {
        display: flex;
    }
`;

const IconMain = styled(Icon)`
    line-height: 24px;
    margin-right: 12px;
`;

const CardDividerStyled = styled(CardDivider)`
    top: 0 !important;
`;

const Count = styled.span`
    font-size: 14px;
    color: ${({ theme }) => theme.material.colors.disabled.color};
`;

const TypographyStyled = styled(Typography)`
    cursor: pointer;
    margin-right: 5px !important;
`;

const LoaderStyled = styled(Loader)`
    display: inline-block;
    position: relative;
    top: 3px;
    margin: 0 0 0 12px;
`;

const getIcon = (data, type) => {
    const { iconType } = data || {};
    const iconName = type === 'entityType' ? data?.icon : data?.iconName;
    if(iconName && iconType){
        return { iconName, iconType };
    }
    if(iconsSet.has(iconName)) {
        return { iconName, iconType: 'mdi'};
    }
    if (customIconSet?.[iconName]){
        return { iconName, iconType: 'af'};
    }
    return { iconName: 'asterisk', iconType: 'mdi'}; // default asterisk from mdi
};

const TreeListItem = ({
    data,
    type,
    isLoading,
    childrenCount,
    isEntityClassChildren
}) => {
    const { name, color, iconColor } = data || {};
    const iconColorVal = color || iconColor;
    const { iconName, iconType } = useMemo(() => getIcon(data, type), [data, type]);

    return (
        <ListItemStyled disableGutters component="div" $isEntityClassChildren={isEntityClassChildren}>
            <ListItemText 
                primary={
                    <>
                        <IconMain name={iconName} type={iconType} hexColor={iconColorVal} />
                        <TypeIcon type={isEntityClassChildren ? 'class' : type} color={muiTheme.colors.text.secondary} />
                        <CardDividerStyled orientation="vertical" />
                        <TypographyStyled title={name} variant="body2" noWrap>
                            {name || 'No Name'}
                        </TypographyStyled>
                        {childrenCount !== 0 && <Count>{`(${childrenCount})`}</Count>}
                        {isLoading && <LoaderStyled size={14} />}
                    </>
                }
            />
        </ListItemStyled>
    );
};

TreeListItem.defaultProps = {
    data: {},
    childrenCount: 0
};

TreeListItem.propTypes = {
    data: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    childrenCount: PropTypes.number,
    isLoading: PropTypes.bool,
    isEntityClassChildren: PropTypes.bool
};

export default memo(TreeListItem);
