import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { muiTheme } from 'app/themes/materialUi';
import { ListItem, ListItemText, Typography, ListItemIcon, ListItemSecondaryAction } from '@mic3/platform-ui';

import { showToastr } from 'store/actions/app/appActions';
import { openEntitySidebar } from 'store/actions/entities/entitySidebarActions';

import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { toUniqueUUID } from 'app/utils/string/string-utils';
import { copyToClipboard } from 'app/utils/classification/classificationUtils';
import { buildDotMenu, getEntityUrl } from 'app/utils/entity/entityUtils';
import { iconsSet } from 'app/utils/styles/mdi';

import { CardDivider, EntityLinkStyledSubTitle, LinkIcon } from 'app/containers/Entities/EntitiesList/EntitiesListItem';
import Icon from 'app/components/atoms/Icon/Icon';
import DotMenu from 'app/components/molecules/DotMenu/DotMenu';

const ListItemStyled = styled(ListItem)`
    position: relative;
    flex: 1;
    height: 64px;

    &.Mui-selected {
        background-color: ${({ theme }) => theme.material.colors.itemActive} !important;
    }

    & .MuiListItemText-primary {
        display: flex;
        align-items: center;
    }

    & .MuiListItemText-secondary {
        display: flex;
    }

    & .MuiListItemText-root {
        width: 9999px;
    }
`;

const CardDividerStyled = styled(CardDivider)`
    top: 0 !important;
`;

const Count = styled.span`
    color: ${({ theme }) => theme.material.colors.disabled.color};
`;

const TypographyStyled = styled(Typography)`
    cursor: pointer;
    text-transform: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 5px !important;
`;

const ListItemIconStyled = styled(ListItemIcon)`
    padding-right: 16px !important;
`;

class RelatedEntityTreeListViewItem extends PureComponent {
    static propTypes = {
        data: PropTypes.object.isRequired,
        childrenCount: PropTypes.number,
        type: PropTypes.string.isRequired,
        groupIcon: PropTypes.string,
        groupIconType: PropTypes.string,
        onActionClick: PropTypes.func.isRequired
    }

    static defaultProps = {
        childrenCount: 0
    }

    @bind
    handleResize() {
        setTimeout(() => this.props.resizeRow, 200);
    }

    @bind
    copyLinkToClipBoard(e, id, type) {
        e.stopPropagation();

        copyToClipboard(`${window.location.origin}/#${getEntityUrl(id, type)}`)
            .then(() => {
                this.props.showToastr({ severity: 'success', detail: 'Link copied to clipboard' });
            })
            .catch(() => {
                this.props.showToastr({ severity: 'error', detail: 'Link could not copied to clipboard' });
            });
    }

    @bind
    copyIdToClipBoard(e, id) {
        e.stopPropagation();

        copyToClipboard(id)
            .then(() => {
                this.props.showToastr({ severity: 'success', detail: 'ID copied to clipboard' });
            })
            .catch(() => {
                this.props.showToastr({ severity: 'error', detail: 'ID could not copied to clipboard' });
            });
    }

    @bind
    @memoize()
    dotMenu(details) {
        return <DotMenu key={13} onItemClick={this.handleMenuClick} items={buildDotMenu({ details, pathname: 'related-entities' })} edge="end" />;
    }

    @bind
    handleMenuClick(title) {
        this.props.onActionClick(title);
    }

    render() {
        const { data, childrenCount, groupIcon, groupIconType } = this.props;
        const { name, color, icon, iconName, iconColor, id, type: entityType } = data || {};
        const iconColorVal = color || iconColor;
        const iconParsed = icon || iconName || 'asterisk';
        const iconType = iconsSet.has(iconParsed) ? 'mdi' : 'af';
        return (
            <ListItemStyled
                disableGutters
                component="div"
                ContainerComponent="div"
            >
                <ListItemIconStyled>
                    <Icon name={iconParsed} hexColor={iconColorVal} type={iconType} />
                </ListItemIconStyled>
                <ListItemText
                    primary={
                        <>
                            <Icon name={groupIcon || 'file-tree'} type={groupIconType || 'mdi'} hexColor={muiTheme.colors.text.secondary} size="sm" />
                            <CardDividerStyled orientation="vertical" />
                            <TypographyStyled title={name} variant="body2" noWrap>
                                {name || 'No Name'}{' '}
                            </TypographyStyled>
                            {childrenCount !== 0 && <Count>{`(${childrenCount})`}</Count>}
                        </>
                    }
                    secondary={
                        <>
                            <EntityLinkStyledSubTitle
                                color="secondary"
                                variant="caption"
                                noWrap
                                title="Copy ID to clipboard"
                                onClick={e => this.copyIdToClipBoard(e, id)}
                            >
                                {`#${toUniqueUUID(id)}`}
                            </EntityLinkStyledSubTitle>
                            <LinkIcon title="Copy link to clipboard" name="link" size="sm" onClick={e => this.copyLinkToClipBoard(e, id, entityType)}/>
                        </>
                    }
                />
                <ListItemSecondaryAction>
                    {this.dotMenu(data)}
                </ListItemSecondaryAction>
            </ListItemStyled>
        );
    }
}

export default connect(null, {
    openEntitySidebar,
    showToastr
})(RelatedEntityTreeListViewItem);
