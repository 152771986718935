/* @flow */

import { gql } from '@apollo/client';
import loadWorkspaceDetailsCommonQuery from 'graphql/workspaces/loadWorkspaceDetailsCommonQuery';

export default gql`
    mutation addWorkspaceResourceMutation($id: ID!, $entities: [EntityReferenceInput]!) {
        result: workspaceAddEntityResources(id: $id, entities: $entities) {
            ${loadWorkspaceDetailsCommonQuery}
        }
    }
`;
