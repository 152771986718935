/* @flow */
import React, { Component } from 'react';
import styled from 'styled-components';
import { Typography, Button } from '@material-ui/core';

import Icon from 'app/components/atoms/Icon/Icon';
import { withRouter } from 'react-router';
import { bind } from 'app/utils/decorators/decoratorUtils';

const Flex = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    gap: 1rem;
`;

const StyledTypography = styled(Typography)`
    color: ${({theme})=> theme.material.colors.text.primary};
    font-size: ${({ fontSize }) => fontSize}!important;
    font-weight: ${({ fontWeight }) => fontWeight}!important;
    max-width: 400px!important;
    text-align: center;
`;

const StyledIcon = styled(Icon)`
    max-width: 132px !important;
    max-height: 132px !important;
    opacity: 0.25 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden !important;
    ::before {
        font-size: 100px !important;
    }
`;
const StyledButton = styled(Button)`
    color: #fff !important;
    background-color: #1248bd !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 16px !important;
    letter-spacing: 0.49px !important;
    width: 164px !important;
    height: 36px !important;
`;
/**
 * Component for render react 16 errors
 */
class ErrorBoundary extends Component<Object, Object> {
    /**
     * Set default component state
     */
    state = { hasError: false, error: '' };
    defaultState = {
        ...this.state
    };

    /**
     * New R16. Catch error.
     */
    componentDidCatch(error: any, info: any) {
        // Display fallback UI
        this.setState({ hasError: true, error });
    }
    componentDidUpdate(preProps) {
        if(preProps.location.pathname !== this.props.location.pathname){
            this.setState(this.defaultState);
        }
    }

    @bind
    goToSupport(){
        return window.open('https://mi-c3.affectli.com/chat/livechat', '_blank');
    }

    /**
     * Render our error
     * @returns {*}
     */
    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <Flex>
                    <StyledIcon type='mdi' name="alert-circle" />
                    <StyledTypography fontSize="24px" fontWeight="500">
                        Oh no! Something's not working...
                    </StyledTypography>
                    <StyledTypography fontSize="16px">
                        {this.state.error?.message}
                    </StyledTypography>
                    <StyledButton size="small" variant="contained" onClick={this.goToSupport}>
                        Contact Support
                    </StyledButton>
                </Flex>
            );
        }
        return this.props.children;
    }
}

export default withRouter(ErrorBoundary);
