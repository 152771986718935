/* @flow */

// $FlowFixMe
import React, { Fragment, PureComponent } from 'react';
import { Typography } from '@mic3/platform-ui';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Switch, Grid, IconButton, ListItemText, List, ListItem, ListItemSecondaryAction, MdiIcon, Autocomplete, TextField } from '@mic3/platform-ui';
import DraggableElement from 'app/components/molecules/Dnd/DraggableElement';
import RowTarget, { EmptyRowTarget } from 'app/components/molecules/Dnd/RowTarget';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { operationOptions, getNumberOfValues } from 'app/utils/designer/queryBuilderUtils';
import DynamicField from 'app/components/Designer/DynamicField';
import ArrayValuesPicker from 'app/components/Designer/ArrayValuesPicker/ArrayValuesPicker';
import DragNDropContext from 'app/containers/DragNDropContext/DragNDropContext';

const DropHereStyled = styled.div`
    height: 128px;
    border: 2px dashed white;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const RowStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

class QueryBuilderDnd extends PureComponent<Object, Object> {

    static propTypes = {
        queries: PropTypes.object,
        uuid: PropTypes.string.isRequired,
    };

    static defaultProps = {
        root: true,
    }

    @bind
    onDrop(item: Object, index: number) {
        this.props.onDrop(item, this.props.uuid, index);
    }

    @bind
    deleteQuery(event: Object) {
        const index = Number(event.target.dataset.index);
        this.props.deleteQuery(this.props.queries.children[index]);
    }

    @bind
    addQuery() {
        this.props.addQuery(
            { type: 'child', field: 'id', op: '=', value: '', fieldType: 'text' },
            this.props.uuid,
            this.props.queries.children.length
        );
    }

    @bind
    addGroup() {
        this.props.addQuery({ type: 'group', condition: 'AND', children: []}, this.props.uuid, this.props.queries.children.length);
    }

    @bind
    @memoize()
    buildDropArea(queries: Object, isRoot: boolean) {
        if (queries.children.length === 0) {
            return (
                <RowTarget key={'empty'} index={0} onDrop={this.onDrop}>
                    <DropHereStyled>
                        {!isRoot && 'Drop here or'}
                        <IconButton onClick={this.addQuery}><MdiIcon name="plus" /></IconButton>
                        <IconButton onClick={this.addGroup}><MdiIcon name="shape-square-plus" /></IconButton>
                    </DropHereStyled>
                </RowTarget>
            );
        }
        const listItems = [...queries.children.map((query, i) => {
            const { uuid, field, op, value, children, type, condition, fieldType } = query;

            const isLastQuery = queries.children.length === i+1;

            let valueField = null;

            switch(getNumberOfValues(op)) {
                case 1: {
                    valueField = (
                        <DynamicField key={`${uuid}${i}`} error={!value} type={fieldType} onChange={this.props.onChange} variant="outlined" value={value} name={`value.${uuid}`} />
                    );
                    break;
                }
                case 2: {
                    const [value1, value2] = value || ['', ''];
                    valueField = (
                        <Fragment>
                            <DynamicField key={`${uuid}${i}`}  error={!value1} type={fieldType} onChange={this.props.onChange} variant="outlined" value={value1} name={`value.${uuid}.0`} />
                            <DynamicField key={`${uuid}${i+100}`} error={!value2} type={fieldType} onChange={this.props.onChange} variant="outlined" value={value2} name={`value.${uuid}.1`} />
                        </Fragment>
                    );
                    break;
                }
                case 99: {
                    valueField = <ArrayValuesPicker key={`${uuid}${i}`} type="array" onChange={this.props.onChange} value={value} name={`value.${uuid}`} />;
                    break;
                }
                default: {}
            }
            
            const target = (
                <RowStyled>
                    {type === 'group'
                        ? (
                            <Grid container justify="space-between" alignItems="flex-start">
                                <Typography />
                                <Switch label={condition} name={`condition.${uuid}`} value={condition === 'AND'} onChange={this.props.onChange} />
                            </Grid>
                        )
                        : (
                            <Fragment>
                                <TextField key={1} error={!field} onChange={this.props.onChange} variant="outlined" value={field} name={`field.${uuid}`} />
                                <Autocomplete key={2} onChange={this.props.onChange} clearable={false} variant="outlined" value={op} name={`op.${uuid}`} valueField="value" options={operationOptions} />
                                {valueField}
                            </Fragment>
                        )
                    }
                </RowStyled>
            );

            let childrenQuerys = null;
            if (children) {
                childrenQuerys = (
                    <QueryBuilderDnd
                        key={uuid}
                        uuid={uuid}
                        root={false}
                        queries={query}
                        onDrop={this.props.onDrop}
                        addQuery={this.props.addQuery}
                        deleteQuery={this.props.deleteQuery}
                        onChange={this.props.onChange}
                    />
                );
                if (children.length > 0) {
                    childrenQuerys = (
                        <div key={uuid} style={{ border: '2px dashed white' }}>
                            {childrenQuerys}
                        </div>
                    );
                }
            }
            return (
                <Fragment key={`${uuid}${i}`}>
                    <ListItem>
                        <ListItemText
                            primary={
                                <Fragment>
                                    <DraggableElement key={i} index={i} element={query}>
                                        <RowTarget
                                            index={i}
                                            onDrop={this.onDrop}
                                            label={'label'}
                                            element={query}
                                        >
                                            {target}
                                        </RowTarget>
                                    </DraggableElement>
                                    {childrenQuerys}
                                </Fragment>
                            }
                        />
                        <ListItemSecondaryAction>
                            <IconButton aria-label="Delete" onClick={this.deleteQuery}>
                                <MdiIcon data-index={i} name="close-circle" size={24} />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                    {isLastQuery && (
                        <Grid key={`${uuid}${i}uniq`} container justify="center">
                            <IconButton onClick={this.addQuery}><MdiIcon name="plus" /></IconButton>
                            <IconButton onClick={this.addGroup}><MdiIcon name="shape-square-plus" /></IconButton>
                        </Grid>
                    )}
                </Fragment>
            );
        }), <EmptyRowTarget key={'-1'} index={queries.children.length} onDrop={this.onDrop} />];

        return <List dense disablePadding>{listItems}</List>;
    };

    render() {
        const { root, queries } = this.props;
        
        return (
            <DragNDropContext>
                {this.buildDropArea(queries, root)}
            </DragNDropContext>
        );
    }
};

export default QueryBuilderDnd;
