/* @flow */

import React, { Fragment, useCallback, useState, memo, useMemo } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import PreviewSvg from 'app/containers/Designer/Processes/Components/PreviewSvg';
import UserEntityLink from 'app/components/atoms/Link/UserEntityLink';
import ListItem from 'app/components/molecules/List/ListItem';
import Icon from 'app/components/atoms/Icon/Icon';
import BpmnProcessLink from 'app/components/atoms/Link/BpmnProcessLink';
import DuplicateProcess from 'app/components/Designer/Modals/DuplicateProcess';
import { fromNow } from 'app/utils/date/date';
import { buildDotMenu } from 'app/utils/entity/entityUtils';
import DotMenu from 'app/components/molecules/DotMenu/DotMenu';
import { openEntitySidebar } from 'store/actions/entities/entitySidebarActions';

const ListItemStyled = styled(ListItem)`
width: 100%;
max-width: 1000px;
margin: 0 auto;
`;

const useToggleMenu = () => {
    const [isOpen, show] = useState(false);
    const toggle = useCallback(() => {
        show(!isOpen);
    }, [isOpen, show]);
    return [isOpen, toggle];
};

const ProcessItem = memo(({ canAdd, process, primaryClass, onActionComplete }) => {
    const { name, id, modifiedBy, modifiedDate, svg, type, role } = process;
    const [isDuplicateProcessOpen, toggleDuplicateProcess] = useToggleMenu();
    const dispatch = useDispatch();

    const onDotMenuClick = useCallback((title, process) => {
        const id = process.id;
        if(title !== 'Duplicate process'){
            dispatch(openEntitySidebar({ title, id, type }));
        }

        if(title === 'Duplicate process'){
            dispatch(toggleDuplicateProcess);
        }
    },[dispatch, toggleDuplicateProcess, type]);

    const dotMenu = useMemo(() => {
        const menu = buildDotMenu({ details: {type, primaryClass, role, ...process} });
        const moreMenus = [
            { name: 'divider' },
            { name: 'Duplicate process', icon: 'content-duplicate' }
        ].filter(Boolean);
        return <DotMenu 
            key={13} 
            tooltipTitle='More Options'
            onItemClick={(title) =>  onDotMenuClick(title, process)}
            items={[...menu, ...moreMenus]} 
        />;
    },[onDotMenuClick, process, primaryClass, type, role]);

    return (
        <ListItemStyled
            component={svg ? <PreviewSvg svg={svg} />: <Icon name="ballot" size="lg" />
            }
            title={<BpmnProcessLink id={id}>{name}</BpmnProcessLink>}
            subTitle={
                <Fragment>
                    <BpmnProcessLink id={id}>#{id}</BpmnProcessLink>
                    {
                        modifiedBy &&
                        <div>
                            Updated By <UserEntityLink id={modifiedBy.id}>{modifiedBy.name}</UserEntityLink>,
                            {' '}{fromNow(modifiedDate)}
                        </div>
                    }
                </Fragment>
            }
            actions={canAdd && (
                <Fragment>
                    { dotMenu }
                    { isDuplicateProcessOpen && <DuplicateProcess process={process} onClose={toggleDuplicateProcess} onDuplicate={onActionComplete} /> }
                </Fragment>
            )}
            raised
        />
    );
});

export default ProcessItem;