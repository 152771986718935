import React, { memo, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { Typography, Divider, IconButton, Popover /*Button8*/ } from '@mic3/platform-ui';
import { getEntityUrl } from 'app/utils/entity/entityUtils';
import GridEntityId from 'app/components/organisms/GridView/GridRenderers/GridEntityId';
import ExpansionCard from 'app/components/molecules/ExpansionCard/ExpansionCard';
import Avatar from 'app/components/molecules/Avatar/Avatar';
import Icon from 'app/components/atoms/Icon/Icon';
import MenuItem from 'app/components/molecules/Menu/MenuItem';
import Menu from 'app/components/molecules/Menu/Menu';
import { updateEntity } from 'store/actions/entities/entitiesActions';
import { getAttachmentUrl } from 'app/utils/attachments/attachmentsUtils';
import { createInitials } from 'app/utils/avatar/avatar';
import UserAdd from 'app/containers/Admin/UserManagement/UserAdd';

const SectionWrapper = styled.div`
    display: flex;
    margin: 1rem 0;
    && {
        ${({ isMobile, isOnSidebar }) =>
    !(isMobile || isOnSidebar)
        ? `
        align-items: start;
        flex-direction: row;
        justify-content: start;
        gap: 16px;
        `
        : `
        align-items: center;
        flex-direction: column;
        justify-content: center;
        `};
    }
    width: 100%;
`;
const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin: 6px 0;
    width: ${({ isMobile, isOnSidebar }) => (!(isMobile || isOnSidebar) ? '80%' : '100%')};
    flex-grow: 1;
    text-align: ${({ isMobile, isOnSidebar }) => (!(isMobile || isOnSidebar) ? 'start' : 'center')};
`;
const SubInfoWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: hidden;
    margin: ${({ isMobile, isOnSidebar }) => (!(isMobile || isOnSidebar) ? '0' : 'auto')};
`;
const StyledSubTitle = styled(Typography)`
    width: ${({ isDesktopView }) => (isDesktopView ? 'auto' : '100%')};
    flex-grow: ${({ isDesktopView }) => (isDesktopView ? '0' : '1')};
    overflow: ${({ isDesktopView }) => (isDesktopView ? 'unset' : ' hidden')};
    text-overflow: ellipsis;
    white-space: nowrap;
    
    ${({align}) => `text-align: ${align}`};
    color: ${({ theme }) => theme.material.colors.disabled.color} !important;
`;
// const ButtonWrapper = styled.div`
//     display: flex;
//     button {
//         margin 0 4px;
//     }
// `;
const AvatarStyled = styled(Avatar)`
    width: 96px;
    height: 96px;
    padding: 8px;
    border: 4px solid rgb(255, 255, 255);
    font-size: 34px;
`;
// const DividerStyled = styled(Divider)`
//     margin: 24px 0 16px 0 !important;
// `;
const DividerVerticalStyled = styled(Divider)`
    margin: 0 8px !important;
    height: 10px !important;
    align-self: center !important;
    width: 100%;
`;

const TypographyStyled = styled(Typography)`
    margin: 4px 0 !important;
    && {
        white-space: break-spaces;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;
const GridEntityIdStyled = styled(GridEntityId)`
    width: 100%;
    flex-grow: 1;
`;

const AvatarWrapper = styled.div`
    width: fit-content;
`;

const UserPoperoverMenu = (props) => {
    const { closeAction, anchorEl, reloadList } = props;
    const [isOpen, toggleModal] = useState(false);
    const dispatch = useDispatch();
    const data = useSelector(state => state.entities.sidebar.details.data);
    const { id, active } = data || {};
    const options = [
        { value: 'rename', label: 'Rename', icon: 'pencil' },
        { value: active ? 'disable' : 'enable', label: active ? 'Disable' : 'Enable', icon: active ? 'close' : 'check' }
    ];

    const onMenuItemClick = useCallback(
        (e, value) => {
            e && e.stopPropagation();
            closeAction();
            if (value === 'enable' || value === 'disable') {
                return dispatch(updateEntity({ id, active: !active, type: 'user' }));
            }
            if (value === 'rename') {
                toggleModal(true);
            }
        },
        [closeAction, dispatch, active, id]
    );

    const open = Boolean(anchorEl);

    return (
        <div>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={closeAction}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            >
                <Menu>
                    {options?.map(({ value, label, icon, iconType }) => (
                        <MenuItem
                            key={value}
                            icon={icon}
                            iconType={iconType}
                            style={{ width: '180px' }}
                            onClick={e => onMenuItemClick(e, value)}
                            name={label}
                        />
                    ))}
                </Menu>
            </Popover>
            {isOpen && <UserAdd onClose={() => toggleModal(false)} title="Rename" rename data={data} reloadList={reloadList} />}
        </div>
    );
};

const UserMainSection = ({ data, isOnSidebar = false, canEdit, reloadList, loadUserReferences }: Object) => {
    const [anchorEl, setAnchor] = useState(null);

    const handleButtonClick = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setAnchor(e.currentTarget);
    }, []);

    const closeAction = useCallback((e) => {
        setAnchor(null);
    }, []);
    const isMobile = useSelector(state => state.global.isMobile);
    const { id, name, image, active, type, primary: { username } = {} } = data || {};

    useEffect(() => {
        loadUserReferences();
    },[loadUserReferences, active]);
    const isDesktopView = !(isMobile || isOnSidebar);
    return (
        <div>
            <ExpansionCard
                collapsible={false}
                title={type}
                iconName={'account'}
                actions={
                    canEdit ? (
                        <IconButton onClick={handleButtonClick}>
                            <Icon name="dots-vertical" />
                        </IconButton>
                    ) : null
                }
            >
                <SectionWrapper isMobile={isMobile} isOnSidebar={isOnSidebar}>
                    <AvatarWrapper>
                        <AvatarStyled src={image && getAttachmentUrl(id, 'user', image)} name={createInitials(name)} />
                    </AvatarWrapper>
                    <InfoWrapper isMobile={isMobile} isOnSidebar={isOnSidebar}>
                        <TypographyStyled isOnSidebar={isOnSidebar} variant="h5">{name}</TypographyStyled>
                        <SubInfoWrapper isMobile={isMobile} isOnSidebar={isOnSidebar}>
                            <StyledSubTitle isDesktopView={isDesktopView} align='end' variant="caption">{username}</StyledSubTitle>
                            <DividerVerticalStyled orientation="vertical" flexItem />
                            <GridEntityIdStyled value={getEntityUrl(id, 'user')} label={id}  valueId={id}/>
                        </SubInfoWrapper>
                        <StyledSubTitle align={(isMobile || isOnSidebar) ? 'center' : 'start'} variant="caption">{`Status: ${active ? 'Active' : 'Inactive'}`}</StyledSubTitle>
                    </InfoWrapper>
                </SectionWrapper>
            </ExpansionCard>
            <UserPoperoverMenu closeAction={closeAction} anchorEl={anchorEl} reloadList={reloadList} />
        </div>
    );
};

export default memo(UserMainSection);
