/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { muiTheme } from 'app/themes/materialUi';
import { Tooltip, Button } from '@mic3/platform-ui';

import SelectionList from 'app/containers/Common/SelectionList/SelectionList';
import NestedMenu from 'app/components/molecules/NestedMenu/NestedMenu';
import AddResourcesModal from 'app/containers/Common/AddResourcesModal';
import Loader from 'app/components/atoms/Loader/Loader';
import Immutable from 'app/utils/immutable/Immutable';
import Icon from 'app/components/atoms/Icon/Icon';

import {
    loadEntityWorkspaces,
    shareEntityWithTeams,
    shareEntityWithUsers,
    shareEntityWithWorkspaces,
    unshareEntityWithTeams,
    unshareEntityWithUsers,
    unshareEntityWithWorkspaces,
} from 'store/actions/entities/entitiesActions';

import { bind } from 'app/utils/decorators/decoratorUtils';
import { getPermissions } from 'app/config/rolesConfig';
import { sharingMenu } from 'app/config/chatConfig';
import { isEmpty, getStr } from 'app/utils/utils';

const ButtonWrapper = styled.div`
    & button {
        display: flex;
        width: 100%;
        & i:before {
            font-size: 18px !important;
        }
    }
    margin: 12px 0;
`;
const StyledNestedMenu = styled(NestedMenu)`
    i.mdi-circle-slice-8:before,
    i.mdi-checkbox-marked:before {
        color: #7391D0 !important;
    }
`;

/**
 * Renders the view to display the classification.
 */
class NewEntitySharing extends PureComponent<Object, Object> {
    static propTypes = {
        id: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        isLoading: PropTypes.bool,
        searchValue: PropTypes.string.isRequired,
        resetSearchValue: PropTypes.func.isRequired,
        openModal: PropTypes.bool,
        modalType: PropTypes.string,
        resetModalValue: PropTypes.func,
        workspaceDetails: PropTypes.object, // task or process details
        loadEntityWorkspaces: PropTypes.func,
        shareEntityWithTeams: PropTypes.func,
        shareEntityWithUsers: PropTypes.func,
        shareEntityWithWorkspaces: PropTypes.func,
        unshareEntityWithTeams: PropTypes.func,
        unshareEntityWithUsers: PropTypes.func,
        unshareEntityWithWorkspaces: PropTypes.func,
        rel: PropTypes.object,
        rid: PropTypes.string
    };

    constructor(props) {
        super(props);
        this.state = Immutable({
            type: '',
            isOpen: false,
            showRoles: false,
            addedResourcesList: [],
            filterBy: [],
            shareAnchorEl: null
        });
        this.defaultState = { ... this.state };
        props.loadEntityWorkspaces(props.type, props.id);
    }

    @bind
    setDefaultState() {
        this.setState(this.defaultState);
    }

    @bind
    openShare(e) {
        this.setState({ shareAnchorEl: e.currentTarget });
    }

    @bind
    closeShare() {
        this.setState({ shareAnchorEl: null });
    }

    @bind
    onShareClick(title) {
        const { workspaceDetails, profile } = this.props;
        const { workspaces = [], users = [], teams = [] } = workspaceDetails || {};
        switch (title) {
            case 'Users':
                this.setState({
                    ...this.defaultState,
                    type: 'user',
                    isOpen: true,
                    showRoles: true,
                    addedResourcesList: users,
                });
                break;
            case 'Teams':
                this.setState({
                    ...this.defaultState,
                    type: 'team',
                    isOpen: true,
                    showRoles: true,
                    addedResourcesList: teams,
                    filterBy: [{ field: 'active', op: '=', value: true }]
                });
                break;
            case 'Workspaces':
                this.setState({
                    ...this.defaultState,
                    type: 'workspace',
                    isOpen: true,
                    showRoles: false,
                    addedResourcesList: workspaces,
                    filterBy: [{ field: 'owners.owner', op: '=', value: profile.id },
                        { field: 'active', op: '=', value: true }]
                });
                break;
            case 'Subscribers': {
                this.props.onAddClick(title);
                break;
            }
            default:
                break;
        }
    }

    componentDidUpdate(prevProps) {
        const { openModal: prevOpenModal } = prevProps;
        const { openModal, modalType } = this.props;

        if (!prevOpenModal && openModal && ['Users', 'Teams', 'Workspaces'].includes(modalType)) {
            this.onShareClick(modalType);
        }
    }

    @bind
    onSubmit(list: Array<Object>) {
        const { type } = this.state;
        if (isEmpty(list)) {
            return;
        }
        switch (type) {
            case 'user':
                this.onSubmitUsers(list).then(this.setDefaultState);
                break;
            case 'team':
                this.onSubmitTeams(list).then(this.setDefaultState);
                break;
            case 'workspace':
                this.onSubmitWorkspaces(list.map(item => item['id'])).then(this.setDefaultState);
                break;
            default:
                break;
        }
    }

    @bind
    closeModal() {
        this.setState({ isOpen: false });
        this.props.resetModalValue();
    }

    @bind
    searchForFrom(searchQuery, data) {
        if (!data || !searchQuery) return false;
        return ['name', 'username', 'id'].some((field) => {
            let value = getStr(data, field, '');
            if (!value) return false;
            value = value.toLowerCase();
            const query = searchQuery.toLowerCase();
            return value.includes(query);
        });
    }

    @bind
    filterSearchedData(searchQuery: string, users, teams, workspaces) {
        return {
            usersFiltered: searchQuery ? (users || []).filter(item => this.searchForFrom(searchQuery, item)) : users || [],
            teamsFiltered: searchQuery ? (teams || []).filter(item => this.searchForFrom(searchQuery, item)) : teams || [],
            workspacesFiltered: searchQuery ? (workspaces || []).filter(item => this.searchForFrom(searchQuery, item)) : workspaces || [],
        };
    }

    @bind
    onSubmitUsers(users: Array<Object>) {
        const { id, type, resetSearchValue, resetModalValue, entityType } = this.props;
        if (!id || isEmpty(users)) {
            return;
        }
        const userMembers = users.map(({ id, role }) => ({ userId: id, role }));
        return this.props.shareEntityWithUsers(type, id, userMembers, entityType).then(resetSearchValue, resetModalValue);
    }

    @bind
    onSubmitTeams(teams: Array<string>) {
        const { id, type, resetSearchValue, resetModalValue, entityType } = this.props;
        if (!id || isEmpty(teams)) {
            return;
        }
        const teamMembers = teams.map(({ id, role }) => ({ teamId: id, role }));
        return this.props.shareEntityWithTeams(type, id, teamMembers, entityType).then(resetSearchValue, resetModalValue);
    }
    @bind
    onSubmitWorkspaces(workspacesIds: Array<string>) {
        const { id, type, resetSearchValue, resetModalValue, entityType } = this.props;
        if (!id || isEmpty(workspacesIds)) {
            return;
        }
        return this.props.shareEntityWithWorkspaces(type, entityType, id, workspacesIds).then(resetSearchValue, resetModalValue);
    }

    @bind
    removeWorkspaceTeams(selectedTeamsListIds) {
        const { id, type, resetSearchValue, entityType } = this.props;
        if (!id || isEmpty(selectedTeamsListIds)) {
            return;
        }
        return this.props.unshareEntityWithTeams(type, id, entityType, selectedTeamsListIds).then(resetSearchValue);
    }

    @bind
    removeWorkspaces(selectedWorkspacesListIds) {
        const { id, type, resetSearchValue, entityType } = this.props;
        if (!id || isEmpty(selectedWorkspacesListIds)) {
            return;
        }
        return this.props.unshareEntityWithWorkspaces(type, id, entityType, selectedWorkspacesListIds).then(resetSearchValue);
    }

    @bind
    removeWorkspaceUsers(selectedUsersListIds) {
        const { id, type, resetSearchValue, entityType } = this.props;
        if (!id || isEmpty(selectedUsersListIds)) {
            return;
        }
        return this.props.unshareEntityWithUsers(type, id, entityType, selectedUsersListIds).then(resetSearchValue);
    }

    render() {
        const { workspaceDetails, isLoading, role, searchValue, rel, rid } = this.props;
        const { workspaces = [], users = [], teams = [] } = workspaceDetails || {};
        const { usersFiltered, teamsFiltered, workspacesFiltered } = this.filterSearchedData(searchValue, users, teams, workspaces);
        const { shareAnchorEl, type, isOpen, addedResourcesList, showRoles, filterBy } = this.state;
        const { canEdit } = getPermissions(role);
        const typeOptions = sharingMenu(canEdit, rel && rid);

        return (
            <>
                {isLoading && <Loader absolute backdrop />}
                {<Tooltip arrow title="Click to add">
                    <ButtonWrapper>
                        <Button onClick={this.openShare}>
                            <Icon type="mdi" name="plus" size="md" hexColor={muiTheme.colors.text.button} /> ADD NEW
                        </Button>
                    </ButtonWrapper>
                </Tooltip>}
                <SelectionList
                    filteredList={workspacesFiltered}
                    list={workspaces}
                    type="workspace"
                    title="Shared workspaces"
                    onAdd={this.onSubmitWorkspaces}
                    onRemove={this.removeWorkspaces}
                    isLoading={isLoading}
                    height="400px"
                    expanded
                    disabledAdd
                    valueField="id"
                    filterBy={filterBy}
                />
                <SelectionList
                    filteredList={teamsFiltered}
                    list={teams}
                    type="team"
                    title="Shared teams"
                    onAdd={this.onSubmitTeams}
                    onRemove={this.removeWorkspaceTeams}
                    isLoading={isLoading}
                    height="400px"
                    showRoles
                    userRole={role}
                    disabledAdd
                />
                <SelectionList
                    filteredList={usersFiltered}
                    list={users}
                    type="user"
                    title="Shared users"
                    onAdd={this.onSubmitUsers}
                    onRemove={this.removeWorkspaceUsers}
                    isLoading={isLoading}
                    height="400px"
                    showRoles
                    userRole={role}
                    disabledAdd
                />
                <StyledNestedMenu
                    items={typeOptions}
                    open={Boolean(shareAnchorEl)}
                    onItemClick={this.onShareClick}
                    anchorEl={shareAnchorEl}
                    onClose={this.closeShare}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                    }}
                />
                <AddResourcesModal
                    type={type}
                    isBulkSelect={true}
                    addedResourcesList={addedResourcesList}
                    isOpen={isOpen}
                    onSubmit={this.onSubmit}
                    closeModal={this.closeModal}
                    filterBy={filterBy}
                    showRoles={showRoles}
                    userRole={role}
                    withoutClose
                />
            </>
        );
    }
}

export default connect(
    state => ({
        workspaceDetails: state.entities.sidebar.workspaces.data,
        isLoading: state.entities.sidebar.workspaces.isLoading,
        profile: state.user.profile,
    }),
    {
        loadEntityWorkspaces,
        shareEntityWithTeams,
        shareEntityWithUsers,
        shareEntityWithWorkspaces,
        unshareEntityWithTeams,
        unshareEntityWithUsers,
        unshareEntityWithWorkspaces,
    }
)(NewEntitySharing);
