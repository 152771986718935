// @flow
import { set } from 'app/utils/immutable/Immutable';
import { filterDefinitions as taskFilterDefs } from 'app/components/organisms/TasksView/TasksView';
import { entitiesDefaultFilterDefinitions } from 'app/utils/entity/entityUtils';
import processsFilterDefinitions from 'app/containers/Abox/ProcessesList/ProcessFilters';
import definitions from 'app/containers/Stream/Events/EventsListView/eventsFilterDefinitions';
import { TASKLAYERS, PROCESSLAYERS, LAYERS } from 'app/utils/maps/layer/layerUtils';
import { buildCustomDefinitions } from 'app/utils/classification/classificationUtils';

export const disableFieldDefintions = (defs) => {
    if (!defs?.length) {
        return [];
    }
    return defs.map((field) => {
        if(field?.children?.length) {
            const children = field.children.map(ch => set(ch, 'properties.disabled', true));
            return { ...field, children };
        }
        return set(field, 'properties.disabled', true);
    });
};

export const disableOperatorSelection = (definition) => {
    if (definition.properties.opSelector) { // Disable operator selection for maps layers
        definition.properties.opSelector = false;
    }
    return definition;
};

const commonDefs = [
    {
        field: 'entity_type',
        type: 'entityTypesTypeahead',
        properties: {
            label: 'Entity Type',
            name: 'entity_type',
            filterBy: [
                {
                    'or': [
                        { field: 'entityTabs', op: 'is null'},
                        { field: 'entityTabs.list', op: 'contains', value: 'Location'},
                    ]
                }
            ]
        },
        filter: false,
        constraints: { required: true }
    },
    {
        field: 'noLocation',
        type: 'checkbox',
        properties: {
            label:'List entities with no location',
            name:'noLocation',
        },
        filter: false,
    }
];
export const getTaskFilterDefinitions = (type) => {
    const excludedFields = ['relations.relatedEntity.id'];
    const defs = taskFilterDefs
        .map((def) => {
            if (excludedFields.includes(def?.field)) { // exclue related entities field from filter definitions
                return null;
            }
            if (def?.field === 'type') {
                return set(def, 'properties.disabled', type !== 'system_task');
            }
            if (type === 'opentask' && def?.field === 'primary.closedDate') {
                return null;
            }
            return def;
        })
        .filter(Boolean);
    return [ ...commonDefs, ...defs ];
};

export const getProcessFilterDefinitions = (type) => {
    const defs = processsFilterDefinitions
        .map((def) => {
            if (def?.field === 'primary.closedDate') {
                return set(def, 'properties.disabled', type !== 'system_process');
            }
            return def;
        })
        .filter(Boolean);
    return [ ...commonDefs, ...defs ];
};

export const getEventsFilterDefintions = (type) => {
    const defs = definitions.map((def) => {
        if (def?.field === 'time') { // exclue related entities field from filter definitions
            return null;
        }
        return def;
    }).filter(Boolean);
    return [
        {
            type: 'dateTimeRange',
            properties: {
                label: 'Event Time',
                name: 'time_range'
            },
            filter: false,
            constraints: { required: true }
        },
        {
            type: 'eventTypeTypeahead',
            properties: {
                label: 'Event Type',
                name: 'event_type'
            },
            constraints: { required: true },
            filter: false,
        },
        ...defs
    ];
};

export const getEntitiesFilterDefinitions = (type) => {
    return [
        ...commonDefs,
        ...entitiesDefaultFilterDefinitions([type])
    ];
};
const getDefinitionsWithOperators = (definitions, operatorsMap) => {
    return definitions.map((def) => {
        const opValue = operatorsMap?.[def?.properties?.name];
        if (opValue) {
            return set(def, 'properties.opValue', opValue);
        }
        return def;
    });
    
};

export const getLayerFilterDefinitions = (layerType, classData, operatorsMap, canEdit) => {
    let definitions = [];
    if (TASKLAYERS.includes(layerType)) {
        definitions = getTaskFilterDefinitions(layerType);
    } else if (PROCESSLAYERS.includes(layerType)) {
        definitions = getProcessFilterDefinitions(layerType);
    } else if (layerType === LAYERS?.event) {
        definitions = getEventsFilterDefintions(layerType);
    } else {
        const customFilters = buildCustomDefinitions(classData, operatorsMap);
        definitions = getEntitiesFilterDefinitions(layerType).map((def) => {
            const opValue = operatorsMap?.[def?.properties?.name];
            if(opValue){
                return set(def, 'properties.opValue', opValue);
            }
            return def;
        });
        definitions.push(...customFilters);
    }
    definitions = getDefinitionsWithOperators(definitions, operatorsMap);
    definitions = definitions.filter(({ filters }) => filters !== false);

    if(!canEdit) {
        return disableFieldDefintions(definitions);
    } 
    return definitions;
};

export const setDefaultFilters = (layerFilters, layerType) => {
    const filters = {...(layerFilters) || {}};
    if (LAYERS.event === layerType) {
        return { severity: null, status: null, ...(filters || {}) };
    }
    if(layerFilters?.classes?.length) {
        const classesUri = layerFilters.classes.map(cls => cls?.id).filter(Boolean);
        delete filters.classes; // deleting old key
        if(classesUri?.length) {
            filters = {...filters, classesUri };
        }
    }
    if (!TASKLAYERS.includes(layerType) && !PROCESSLAYERS.includes(layerType)) {
        return { active: layerFilters?.active === null ? null : true, ...(filters || {}) };
    }
    let processStatus = null;
    switch (layerType) {
        case 'openprocess':
            processStatus = 'is null';
            break;
        case 'closedprocess':
            processStatus = 'is not null';
            break;
        default:
            processStatus = null;
    }
    return { assigneeIdNull: null, statusType: layerType !== 'system_task' ?  layerType : null, status: processStatus, priority: null, dueDateNull: null,  primaryStartDate: null, ...(filters || {}) };
};
