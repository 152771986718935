/* @flow */

import { gql } from '@apollo/client';

import { workspaceFields, classWorkspaceFields } from 'graphql/entities/entities/loadEntityWorkspacesQuery';

export default type => gql`
    mutation shareEntityWithUsersMutation($id: ID!, $userMembers: [UserMemberInput]!) {
        result: ${type === 'class' ? 'shareClassWithUsers': 'shareEntityWithUsers'}(id: $id, userMembers: $userMembers${type === 'class' ? '' : `, type: "${type}"`}) {
            ${type === 'class' ? classWorkspaceFields : workspaceFields}
        }
    }
`;
