import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Divider, Grid, Checkbox } from '@mic3/platform-ui';

import ExpansionCard from 'app/components/molecules/ExpansionCard/ExpansionCard';
import Help from 'app/utils/designer/form/settings/common/Help';
import { getFieldByType } from 'app/utils/designer/form/fieldUtils';

const DividerStyled = styled(Divider)`
    margin: 10px 0 32px 0 !important;
`;



class EntityDetailSection extends PureComponent {

    render() {
        const { handleChange, details, disabled } = this.props;

        return (
            <ExpansionCard
                expanded
                title={'Entity Details'}
            >
                <Grid container alignItems="center" justify="space-between" wrap="nowrap">
                    {
                        getFieldByType('text', {
                            name: 'openInModule',
                            value: details.openInModule || '',
                            label: 'Open in modules',
                            placeholder:`/entities/${details.uri}/{{id}}`,
                            disabled,
                            changeData: (e) => {
                                const { name, value } = e || {};
                                handleChange({ target: { name, value }});
                            },
                            help: <Help message={
                                    `Examples of Open in modules:

To open the entity as **Entities** module, user can add the URL of the
entity type as ***/entities/example-entity-type/{{id}}***

To open the entity as **Maps** module, user can add the URL of the
entity type as ***/react-maps/{{id}}***

(where *'react-maps'* is the name of the module and '*{{id}}'* is the
dynamic ID of the entity)`
                            } />
                        })
                    }
                   
                </Grid>
                <DividerStyled />
                <Grid container alignItems="center" justify="space-between" wrap="nowrap">
                    <Checkbox
                        value={details.hidden}
                        onChange={handleChange}
                        name="hidden"
                        label="Hide from entity lists"
                        disabled={disabled}
                    />
                </Grid>
                <DividerStyled />
            </ExpansionCard>
        );
    };
}

export default EntityDetailSection;
