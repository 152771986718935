/* @flow */

import styled from 'styled-components';
import { ListItem } from '@mic3/platform-ui';

const TypeaheadListItem = styled(ListItem)`
    margin:-6px 0 !important;

    .MuiAvatar-root {
      margin-right: 10px !important;
    }
`;

export {
    TypeaheadListItem,
};
