/* @flow */

// $FlowFixMe
import React, { useState, memo, Fragment } from 'react';
import styled from 'styled-components';

import { Typography,} from '@mic3/platform-ui';
import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import Button from 'app/components/atoms/Button/Button';
import { set } from 'app/utils/immutable/Immutable';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';


const RequiredText = styled(Typography)`
    padding: 20px 0 6px;
    font-size: 12px !important;
    color: ${({ theme }) => theme.material.colors.text.label };
`;

const IotDataModal = ({ showModal, toggleModal, onAdd, showFieldsModal }: Object) => {

    const defaultState = {
        componentName: '',
        attributeUri: '',
        legactDataSource: false,
        iotJoinColumn: '',
        entityJoinColumn: '',
        query: '',
        helperText: ''
    };
    const [iotFormData, setIotFormData] = useState(defaultState);

    const [error, setError] = useState(true);

    const formRef = React.createRef();

    const handleChange = (data: Object, variables) => {
        formRef.current.isValidForm().then(({ errors }) => {
            if (!errors) {
                setError(false);
                return;
            }
            setError(true);
        });
        const { name: varName, value: varValue } = variables || {};
        const updatedIotData = set(iotFormData, varName, varValue);
        setIotFormData(updatedIotData);
    };

    const save = () => {
        const { index, parentUuid } = showFieldsModal;
        const Iotfields = {
            type: 'iotPanel',
            properties: {
                header: iotFormData.componentName,
                query: iotFormData.query,
                iotJoinColumn: iotFormData.iotJoinColumn,
                entityJoinField: iotFormData.entityJoinColumn,
                componentName: iotFormData.componentName,
                attributeUri: iotFormData.attributeUri,
                legactDataSource: iotFormData.legactDataSource
            },
        };
        onAdd(Iotfields, parentUuid, index + 1);
        toggleModal();
        setIotFormData(defaultState);
    };


    const fieldDefinitions = [
        {
            type: 'text',
            properties: {
                label: 'Component Name',
                name: 'componentName',
                help: (
                    <Typography>
                        Enter the component name.
                    </Typography>
                )
            },
            constraints: { required: true }
        },
        {
            type: 'text',
            properties: {
                label: 'Attribute URI',
                name: 'attributeUri',
                help: (
                    <Typography>
                       Enter Attribute uri for IoT component.
                    </Typography>
                )
            },
            constraints: { required: true }
        },
        {
            type: 'text',
            properties: {
                label: 'IoT Join Column',
                name: 'iotJoinColumn',
                help: (
                    <Typography>
                        Enter Join column for IoT.
                    </Typography>
                )
            },
            constraints: { required: true }
        },
        {
            type: 'checkbox',
            properties: {
                label: 'Use legacy data source',
                name: 'legactDataSource',
                help: (
                    <Typography>
                        Choose whether to enable legacy data source.
                    </Typography>
                )
            },
            constraints: { required: true }
        },
        {
            type: 'text',
            properties: {
                label: 'Entity Join Column',
                name: 'entityJoinColumn',
                help: (
                    <Typography>
                       Enter Join column for entity in IoT.
                    </Typography>
                )
            },
            constraints: { required: true }
        },
        {
            type: 'textarea',
            properties: {
                label: 'Query',
                name: 'query',
                useCodeEditor: true,
                mode: 'sql',
                lineNumbers: true,
                parseAs: 'sql',
                help: (
                    <Typography>
                        Enter the query.
                    </Typography>
                )
            },
            constraints: { required: true }
        },
    ];


    return !!showModal && (
        <ModalDialog
            title="Create IoT Data"
            onClose={()=> {
                setIotFormData(defaultState);
                toggleModal();
            }}
            actions={
                <>
                    <Button variant="text" color="primary" onClick={()=> {
                        setIotFormData(defaultState);
                        toggleModal();
                    }} >
                        Cancel
                    </Button>
                    <Button color="primary" onClick={()=> save()} disabled={error}>
                        Apply
                    </Button>
                </>
            }
            footer={
                <RequiredText variant="body2">* All fields are required </RequiredText>
            }
        >
            <FormGenerator
                components={fieldDefinitions}
                ref={formRef}
                onChange={handleChange}
                data={iotFormData}
            />
        </ModalDialog>
    );
};

export default memo<Object>(IotDataModal);
