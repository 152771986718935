import React from 'react';
import styled from 'styled-components';
import { Grid } from '@mic3/platform-ui';

const BarBox = styled(Grid)`
    margin: 0px 8px;
    padding: 0px 8px;
    ${({ withoutdivider, theme }) => !withoutdivider && `border-bottom: 1px solid ${theme.material.colors.background.divider};`}
    grid-area: pActions;
    min-height: 49px;
    display: flex;

    & .MuiIconButton-root {
        max-height: 48px;
    }
`;

const Bar = styled(Grid)`
    min-height: 45px;
    color: ${({ theme }) => theme.color.white};

`;

const GridItem = styled(Grid)`
    display: flex;
    align-items: center;
    & > * {
        margin: 4px;
    }
`;

const GridItemLeft = styled(GridItem)`
    flex-grow: 1;
    max-width: 100%;
`;

const HeaderBar = ({ withoutDivider, left, right, wrap, bottom, className, ...props}) => {
    return (
        <BarBox withoutdivider={withoutDivider?1:0} className={className}>
            <Bar container justify="space-between" wrap={wrap}>
                <GridItemLeft item>{left}</GridItemLeft>
                <GridItem item>{right}</GridItem>
            </Bar>
            {bottom}
        </BarBox>
    );
};

export default HeaderBar;
