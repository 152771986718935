/* @flow */
import { gql } from '@apollo/client';

export default gql`
query relationWorkspaceToUserQuery($filterBy: [JSON]) {
    result: teams(filterBy: $filterBy) {
        id
        name
        users {
            role
            user {
                id
                username
            }
        }
    }
}
`;