/* @flow */
import memoizeOne from 'memoize-one';

import { displayByKind } from 'app/utils/date/date';
import { removeSpecialCharactersAndJoinAsCamelCase } from 'app/utils/string/string-utils';

export const getSelectedPrimaryClass = memoizeOne((type, primaryClasses) => {
    if (!type || !primaryClasses?.length) {
        return null;
    }
    return primaryClasses?.find(cls => cls?.uri === type);
});

const _isValidFilter = (filter: any) => {
    return (
        typeof filter === 'object'
        && typeof filter.field === 'string' && filter.field
        && typeof filter.op === 'string' && filter.op
        && typeof filter.value !== 'undefined'
    );
};

export const _getFilters = (filtersString: string): Array<Object> => {
    if (!filtersString) {
        return [];
    }
    try {
        const filter = JSON.parse(filtersString);
        const filters = Array.isArray(filter) ? filter : [filter];
        return filters.filter(_isValidFilter).map(({ field, op, value }) => ({ field, op, value }));
    } catch (e) {
        // fail silently
        return [];
    }
};

const getSummaryElements = (classes: ?Array<Object>): Array<Object> => {
    if (!classes || !classes.length) {
        return [];
    }
    return classes.reduce((formElements, { form_definitions }) => {
        if (form_definitions && form_definitions.fields && form_definitions.fields.length) {
            formElements.push(...form_definitions.fields);
        }
        return formElements;
    }, []).filter(element => element.summary);
};

const copyToClipboard = (text: string) => {
    return navigator.clipboard.writeText(text);
};

const formatValue = (meta: Object, value: any) => {
    const { type, text_ext, text_ext_position, kind } = meta || {};
    let val = value;
    switch (type) {
        case 'timestamp':
            val = displayByKind(kind, value);
            break;
        case 'bool':
            val = value ? 'Yes' : 'No';
            break;
        case 'things': case 'people': case 'organisations': case 'classification': case 'directory': case 'user': case 'relation':
            val = value && value.name;
            break;
        case 'custom':
            if (value && typeof value === 'object' && value.id !== undefined && value.name !== undefined) {
                val = `${value.name} (${value.id})`;
            }
            break;
        default:
    }
    if (text_ext_position && text_ext) {
        return text_ext_position === 'before' ? `${text_ext}${val}` : `${val}${text_ext}`;
    }
    return val;
};

const getModulePath = (openInModule, type) => openInModule || `/entities/${type}`;

const _findAttributeByName = (field, name) => {
    if (field?.properties?.name === name) return field;
    if (field?.children?.length) {
        for (const child of field.children) {
            const result = _findAttributeByName(child, name);
            if (result) return result;
        }
    }
    return null;
};

export const findAttribute = (formDefinition, attributeName) => {
    const { fields } = formDefinition || {};
    if (!fields?.length) return null;
    if (fields?.length) {
        for (const field of fields) {
            const result = _findAttributeByName(field, attributeName);
            if (result) return result;
        }
    }
    return null;
};

const _removeAttribute = (node, attributeName) => {
    if (Array.isArray(node)) {
        return node.map((child) => _removeAttribute(child, attributeName)).filter(Boolean);
    }
    if (node && typeof node === 'object') {
        if (node.properties && node.properties.name === attributeName) {
            return;
        }
        if (node.children) {
            const obj = { ...node };
            obj.children = obj.children.map((child) => _removeAttribute(child, attributeName)).filter(Boolean);
            return obj;
        }
    }
    return node;
};

export const removeAttributeFromFields = (fields, attName) => {
    if(!fields?.length) return [];
    return fields.map(f => _removeAttribute(f, attName)).filter(Boolean);
};

const findRecord = (arr, key) => {
    if (!arr?.length || !key) {
        return null;
    }
    return arr.find(({ id }) => id === key);
};

export const getClassAncestors = (classId, classes) => {
    const ancestors = [];
    const classification = findRecord(classes, classId);
    if (classification) ancestors.push(classification);
    let index = 0;
    while(ancestors[index]) {
        const cls = ancestors[index];
        if (cls?.parents?.length) {
            cls.parents.forEach((item) => {
                const parent = findRecord(classes, item?.id);
                if (parent) {
                    const alreadyExists = findRecord(ancestors, parent.id);
                    !alreadyExists && ancestors.push(parent);
                }
            });
        }
        index++;
    }
    return ancestors;
};

export const getCustomFields = (classData) => {
    if (!classData?.entityPrimaryIndexes?.length) {
        return [];
    }
    return classData.entityPrimaryIndexes.filter(({ state }) => state === 'created');
};

export const buildCustomDefinitions = (classData, opMap) => {
    if (!classData) {
        return [];
    }
    const fields = getCustomFields(classData);
    const filterDefs = fields.map(({ primaryAttribute }) => {
        const attribute = findAttribute(classData?.formDefinition, primaryAttribute);
        const label = attribute?.properties?.label;
        const type = attribute?.type;
        const field = `primary.${primaryAttribute}`;
        const name = removeSpecialCharactersAndJoinAsCamelCase(field);
        return {
            field,
            type,
            properties: { label, name, opSelector: true, opValue: opMap?.[name] },
        };
    });
    if (filterDefs?.length) {
        return [{
            field: 'displayText',
            type: 'displayText',
            properties: { text: 'User-defined filters', name: 'displayText'},
            style: {
                color: 'rgba(255, 255, 255, 0.7)'
            },
            filter: false,
            sort: false
        }, ...filterDefs];
    }
    return [];
};

export const isCustomField = (userDefinedFilters, field) => {
    if (!userDefinedFilters?.length) {
        return false;
    }
    return !!userDefinedFilters.find(def => def?.field === field);
};

export const modifyFieldDefinitionsURI = (newUri, fields) => {
    return fields?.map((field) => {
        return {
            ...field,
            children: field?.children?.map((child) => {
                const { name } = child?.properties;
                if (!name) {
                    return { ...child };
                }
                const updatedName = `${newUri}/${name?.substring(name.indexOf('/') + 1)}`;
                return {
                    ...child,
                    properties: {...child?.properties, name: updatedName}
                } ;
            })                 
        };
    });
};

export const addCustomOperators = (filterDefinitions, filterBy, operatorsMap, userDefinedFilters, searchBar, searchBarValue) => {
    const searchFilters = {}; 
    return filterBy.map((fieldData) => {
        const { field, op, value, ...rest } = fieldData;
        const name = (filterDefinitions || []).find(def => def?.field === field)?.properties?.name;
        let customOperator = operatorsMap?.[name];
        const filter = { field, op: customOperator || op, value, ...(rest || {}) };
        if (!searchFilters[field] && searchBarValue && searchBar?.includes(field) && searchBarValue === fieldData.value) { // If there is a search value then we will not add operators
            searchFilters[field] = true; // To handle scenario where inside search and same field inside filters contains similar value but different operators
            return fieldData;
        }
        if (field === 'id' && filter.op === 'starts with') { // for starts with operator with ID we need to cast it to text
            return { ...filter, cast: 'text' };
        }
        const isCustomFilter = isCustomField(userDefinedFilters, field);
        if (isCustomFilter) {
            customOperator = operatorsMap?.[removeSpecialCharactersAndJoinAsCamelCase(field)];
            if (filter.value === '__isNull') {
                return { field, op: customOperator || 'is null' };
            }
            const modifiedFilter = { ...filter, op: customOperator || op };
            if (typeof(value) === 'number') {
                return { ...modifiedFilter, value: String(value), cast: 'real' };
            }
            return modifiedFilter;
        }
        return filter;
    });
};

const flattenFormDefinitions = (defs) => {
    if (!defs?.length) {
        return [];
    }
    let result = [];
    defs.forEach(obj => {
        const { children, ...rest } = obj;
        result.push(rest);
        if (children && Array.isArray(children)) {
            result = result.concat(flattenFormDefinitions(children));
        }
    });
    return result;
};

const filterActiveClasses = (payload) => { 
    const { records: classes } = payload || {};
    const records = classes?.filter(({ active, hidden }) => active && !hidden);
    return { records };
};

export {
    getModulePath,
    getSummaryElements,
    copyToClipboard,
    formatValue,
    flattenFormDefinitions,
    filterActiveClasses
};
