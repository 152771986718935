/* @flow */

import { gql } from '@apollo/client';
import entityDetailsCommonQuery from 'graphql/entities/common/entityDetailsCommonQuery';

export default gql`
    mutation deleteEntityFileAttributeMutation(
        $type: String!,
        $id: ID!,
        $attributeName: String!,
        $fileId: ID!,
        $isPrimary: Boolean!,
        ) {
        result: deleteEntityFileAttribute(
            type: $type,
            id: $id,
            attributeName: $attributeName,
            fileId: $fileId,
            isPrimary: $isPrimary){
            ${entityDetailsCommonQuery}
        }
    }
`;
