/* @flow */

import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { TextField, Button, MdiIcon, Grid, Typography, ConfirmationModal } from '@mic3/platform-ui';

import { loadEntityClassifications, removeEntityClass, addEntityClasses, updateEntity } from 'store/actions/entities/entitiesActions';

import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { groupFields } from 'app/utils/classification/classificationForm';
import { isEmpty, getStr, deepEquals } from 'app/utils/utils';
import { get, set, omit, sortBy } from 'app/utils/lo/lo';
import { filterAttributesAsPerDefinitions, getOnlyUpdatedData } from 'app/utils/app/appUtils';
import { RouterMatchPropTypeBuilder } from 'app/utils/propTypes/common';
import { DIGITAL_TWIN_ID } from 'app/config/config';
import { openSidebarByParams } from 'app/utils/app/appUtils';

import { openProcessSidebar } from 'store/actions/abox/processSidebarActions';
import { openClassSidebar } from 'store/actions/entities/classSidebarActions';
import { openTeamSidebar } from 'store/actions/entities/teamSidebarActions';
import { openTaskSidebar } from 'store/actions/abox/taskSidebarActions';
import { openWorkspaceSidebar } from 'store/actions/entities/workspaceSidebarActions';
import { serializeAttributes, deserializeAttributes } from 'app/utils/bpmn/bpmnEngineUtils';

import ClassificationItem from 'app/containers/Common/ClassificationsTab/ClassificationItem';
import AddClassificationModal from 'app/containers/Common/ClassificationsTab/AddClassificationModal';
import Icon from 'app/components/atoms/Icon/Icon';
import Layout from 'app/components/molecules/Layout/Layout';
import Loader from 'app/components/atoms/Loader/Loader';
import ActionBar from 'app/components/molecules/ActionBar/ActionBar';
import ChatFileUploadModal from 'app/components/organisms/Chat/ChatFileUploadModal';
import { uploadRoomFile, deleteChatRoomAttachment } from 'store/actions/chat/chatActions';
import { removeSpecialCharacters } from 'app/utils/string/string-utils';
import { findAttribute } from 'app/utils/classification/classificationUtils';



const BurgerWrap = styled.div`
    display: flex;
    line-height: 16px;
    .Icon {
        border-right: 1px solid #5f5f5f;
        padding: 0 10px 0 10px;
        &:last-child {
            border-right: none;
            margin-right: 0px;
        }
    }
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const LayoutStyled = styled(Layout)`
    overflow: auto;
`;

const TextFieldStyled = styled(TextField)`
    padding: 0 8px 0 8px !important;
`;

const GridWrapper = styled(Grid)`
    min-height: 48px;
    padding: 8px 8px 0 8px !important;
    display:flex;
`;

const SearchIcon = styled(MdiIcon)`
    line-height: 24px !important;
    color : #767c87;
`;

const TypographyStyled = styled(Typography)`
    font-weight: bold;
`;

const removeStaticAndReadyOnlyAttributes = (classes, data) => {
    if (!classes?.length) return data;
    const attributes = { ...(data || {}) };
    const staticAttributes = Object.keys(attributes).map((attributeName) => {
        for(const cls of classes) {
            const att = findAttribute(cls?.formDefinition, attributeName) || {};
            if (att?.properties?.static || att?.properties?.disabled) return attributeName;
        }
    }).filter(Boolean);
    if (staticAttributes?.length) {
        staticAttributes.forEach((key) => delete attributes[key]);
    }
    return attributes;
};

class ClassificationsTab extends PureComponent<Object, Object> {

    static propTypes: Object = {
        type: PropTypes.string.isRequired,
        match: RouterMatchPropTypeBuilder({ id: PropTypes.string }),
        isLoading: PropTypes.bool,
        entity: PropTypes.shape({
            id: PropTypes.string.isRequired,
            classes: PropTypes.arrayOf(PropTypes.object),
            attributes: PropTypes.object,
        }),
        name: PropTypes.string.isRequired,
        loadEntityClassifications: PropTypes.func.isRequired,
        removeEntityClass: PropTypes.func.isRequired,
        updateEntity: PropTypes.func.isRequired,
        addEntityClasses: PropTypes.func.isRequired,
        reloadList: PropTypes.func
    };


    formReferences = [];
    dropzoneUploads = [];
    state = {
        searchString: '',
        isSortAscending: true,
        isUnCollapsed: null,
        classes: [],
        attributes: {},
        isClassModalOpen: false,
        isConfirmModalOpen: false,
        refreshKey: 0,
        files: [],
        fileDeleteHandlers: [],
        confirmProps: {
            classId: null,
            confirmMessage: ''
        }
    };

    componentDidMount() {
        this.loadClasses();
    }

    componentDidUpdate(prevProps) {
        const { id, entity } = this.props;
        const type = this.getEntityType();
        if (prevProps.id !== id) {
            this.props.loadEntityClassifications(id, type);
        }
        if(!deepEquals(prevProps.entity, entity)) {
            this.normalizeClassificationFields(entity);
        }
    }

    @bind
    getEntityType(){
        const { isUser, type } = this.props;
        return isUser ? 'user' : type;
    }

    @bind
    loadClasses(){
        const { id } = this.props;
        const type = this.getEntityType();
        this.props.loadEntityClassifications(id, type);
    }

    @bind
    @memoize()
    normalizeClassificationFields(entity) {
        if (!entity || isEmpty(entity.classes)) {
            this.setState({
                classes: [],
                attributes: {},
            });
            return;
        }
        const fromEntity = { fromType: get(this.props, 'type'), fromId: get(this.props, 'match.params.id') };
        const classes = entity.classes.map((classification) => {
            const fields = classification.formDefinition?.fields || [];
            return fields[0]?.settings ? {
                ...classification,
                formComponents: groupFields(fields, { fromEntity, classData: classification }).map(field => ({
                    ...field,
                    children: this.normalizeFields(field.children),
                })),
            } : {
                ...classification,
                formComponents: fields.map(field => ({
                    ...field,
                    properties: {
                        ...field.properties,
                        iconName: field?.properties?.iconName  || classification.icon,
                        iconType: field?.properties?.iconType || classification.iconType
                    },
                    children: this.normalizeFields(field.children, classification),
                })),
            };
        });
        let deSerializedAttributes = { ...(entity.attributes || {}) };
        classes.forEach(clss => {
            deSerializedAttributes = {
                ...deSerializedAttributes,
                ...deserializeAttributes(clss.formComponents, deSerializedAttributes)
            };

        });
        this.setState({ classes, attributes: deSerializedAttributes || {}, refreshKey: this.state.refreshKey + 1 });
    }

    @bind
    normalizeFields(fields, path = '', parent = true) {
        return (fields || []).map((field) => {
            let newPath = parent ? '' : `${path || ''}`;
            let enrichedField = { ...field };
            if(enrichedField.children) {
                if(enrichedField.properties.name) {
                    newPath = newPath ? `${newPath}.${enrichedField.properties.name}` : `${enrichedField.properties.name}`;
                }                
                enrichedField.children = this.normalizeFields(enrichedField.children, newPath, false);
            }

            // For legacy classification definitions
            if(enrichedField?.properties?.InputProps) {
                enrichedField.properties = omit(enrichedField.properties, ['InputProps']);
            }
            if(enrichedField.type === 'relationsTypeahead') {
                return {
                    ...enrichedField,
                    properties: {
                        ...field.properties,
                        fromType: this.getEntityType(),
                        fromId: this.props.id,
                        definition: get(enrichedField, `relation_definition_${this.props.type}`)
                    }
                };
            }
            if(enrichedField.type === 'entityTypeahead') {
                return set(enrichedField, 'properties.entityType', enrichedField.properties?.entityType?.uri || enrichedField.properties?.entityType);
            }
            if(enrichedField.type === 'file') {
                return {
                    ...enrichedField,
                    properties: {
                        ...enrichedField.properties,
                        entityId: this.props.id,
                        entityType: this.props.type,
                        isPrimary: false
                    }
                };
            }
            if(enrichedField.type === 'printButton') {
                return set(enrichedField, 'properties.entity', {
                    id: this.props.id,
                    type: this.props.type
                });
            }
            if(enrichedField.type === 'chip') {
                return set(enrichedField, 'properties', {
                    ...enrichedField.properties,
                    entity: {
                        id: this.props.id,
                        type: this.props.type
                    },
                    openSidebar: (params) => openSidebarByParams({
                        ...this.props,
                        ...params,
                        openEntitySidebar: this.props.openEntitySidebar
                    })
                });
            }
            if(enrichedField.type === 'dropzone') {
                const reference = enrichedField.properties.name;
                const referenceData = { path: newPath, reference };
                enrichedField = set(
                    enrichedField,
                    'properties.onRemoveFile',
                    this.removeAttachment);
                !this.dropzoneUploads.find(f => f?.reference === reference) && this.dropzoneUploads.push(referenceData);
            }
            return enrichedField;
        });
    }

    @bind
    appendFile(file, options) {
        let resolve, reject;
        const promise = new Promise((_resolve, _reject) => {
            resolve = _resolve;
            reject = _reject;
        });
        const fileHandler = {
            file,
            promise,
            resolve,
            reject,
            ...(options || {})
        };
        this.setState(state => (
            { files: [...state.files, fileHandler] }
        ));
        return fileHandler.promise;
    }

    @bind
    removeClassConfirm(classId: string, className) {
        this.toggleConfirmationModal(true);
        this.setState({
            confirmProps: {
                classId,
                confirmMessage: <Typography>Are you sure you want to remove <TypographyStyled variant="body">{className}</TypographyStyled>?</Typography>
            }
        });
    };

    @bind
    async removeClass() {
        const { id, reloadDetails } = this.props;
        const { confirmProps } = this.state;
        const { classId } = confirmProps || {};
        const response = await this.props.removeEntityClass(id, this.props.type, classId);
        if (response instanceof Error === false) {
            const attributes = { ...this.state.attributes };
            this.props.entity.classes.forEach(({ formDefinition, id }) => {
                if(classId === id) {
                    ((formDefinition && formDefinition.fields) || []).forEach(({ f_uri }) => {
                        attributes[f_uri] = undefined;
                    });
                }
            });
            this.setState({ attributes }, async() => {
                this.loadClasses();
                this.handleCloseConfirmModal();
                if (classId === DIGITAL_TWIN_ID) { // relaod entity data only when digital twin class is removed because we need to remove the menu item for digital twin
                    reloadDetails && await reloadDetails();
                }
            });
        }
    }

    @bind
    async uploadDropzoneFiles(attributes) {
        let updatedAttributes = {...attributes};
        const promises = this.dropzoneUploads.map((dp) => {
            const isTopLevel = !dp.path;
            const data = get(updatedAttributes, isTopLevel ? dp.reference : dp.path);
            
            if(!data || (!Array.isArray(data) && !get(data, dp.reference))) {
                return Promise.resolve();
            }
            if(isTopLevel) {
                return data.map((file, index) => !(file instanceof File) ? Promise.resolve() : this.appendFile(file, { reference: dp.reference, index }));
            }
            if(Array.isArray(data)) {
                return data.map((groupData, grIindex) => {
                    const files = get(groupData, dp.reference);
                    if(!files) {
                        return Promise.resolve();
                    }
                    return files.map((file, index) => {
                        try {
                            return !(file instanceof File)  ? Promise.resolve() : this.appendFile(file, { reference: `${dp.path}[${grIindex}].${dp.reference}`, index });
                        } catch(err) {
                            return Promise.resolve();
                        }
                    });
                });
            } else {
                const files = get(data, dp.reference);
                return files.map((file, index) => !(file instanceof File) ? Promise.resolve() : this.appendFile(file, { reference: `${dp.path}.${dp.reference}`, index }));
            }
        });

        const results = await Promise.all(promises.flat(Infinity));
        results.filter(Boolean).forEach((image) => {
            let files = [...get(updatedAttributes, image.reference, [])];
            if(image.canceled) {
                files = files.filter(f => f.path !== image.file.path);
            } else {
                files.splice(image.index, 1, image);
            }
            updatedAttributes = set(updatedAttributes, image.reference, files);
        });
        return updatedAttributes;
    }

    @bind
    async deleteDropzoneFiles() {
        const { fileDeleteHandlers } = this.state;
        const { deleteChatRoomAttachment } = this.props;
        const promises = fileDeleteHandlers.map(async (file) => {
            const { id: fileId } = file || {};
            if(fileId) {
                const resp = await deleteChatRoomAttachment(fileId);
                if(resp instanceof Error) {
                    return Promise.resolve();
                }    
            }
        });
        return Promise.all(promises);
    }

    @bind
    async save() {
        const { id, internal } = this.props;
        const { classes } = this.state;
        const results = await Promise.all(this.formReferences.map(ref => ref.current.isValidForm()));

        const { errors, data } = results.reduce((acc, { errors, data }) => {
            let serializedData = { ...data };
            classes.forEach(clss => {
                serializedData = {
                    ...serializedData,
                    ...serializeAttributes(clss.formComponents, serializedData)
                };

            });
            return {
                errors: { ...acc.errors, ...(errors || {}) },
                data: { ...acc.data, ...serializedData },
            };
        }, { errors: {}, data: null });

        this.setState({ attributes: data }, async () => {
            const { fileDeleteHandlers } = this.state;
            if (isEmpty(errors)) {
                const nonStNnonDisAtt = removeStaticAndReadyOnlyAttributes(this.props.entity.classes, data);
                
                let changes = getOnlyUpdatedData(this.props.entity.attributes || {}, nonStNnonDisAtt);
                if(this.dropzoneUploads.length) {
                    changes = await this.uploadDropzoneFiles(changes);
                }
                if(fileDeleteHandlers.length) {
                    await this.deleteDropzoneFiles();
                    this.setState({ fileDeleteHandlers: [] });
                }
                if(!isEmpty(changes)){
                    // FILTERING ATTRIBUTES BECAUSE IF THERE IS AN ATTRIBUTE THAT IS REMOVED FROM DEFINITIONS THEN IT SHOULD NOT BE SENT TO BACKEND BECAUSE IT WILL THROW ERROR FOR NO DEFINITION FOUND
                    let filteredAttributes = {};
                    classes.forEach(cls => {
                        const filteredChanges = filterAttributesAsPerDefinitions(cls.formDefinition, changes);
                        if(filteredChanges){
                            filteredAttributes = {...filteredAttributes, ...filteredChanges};
                        }
                    });
                    this.props.updateEntity({ id, type: this.props.type, attributes: filteredAttributes }, internal);
                }
            }
        });
    };

    @bind
    @memoize()
    canViewClasses(profile) {
        const { isAdmin, permissions } = profile;
        return isAdmin || (permissions && permissions.includes('class.class'));
    }

    @bind
    onSearchChange(event) {
        const { value } = event.target;
        this.setState({ searchString: value });
    }

    @bind
    toggleSorting() {
        this.setState({ isSortAscending: !this.state.isSortAscending });
    }

    @bind
    toggleCollapse() {
        this.setState(prevState => ({ isUnCollapsed: !prevState.isUnCollapsed }));
    }

    @bind
    getAttributesForm({ formDefinition }, attributes) {
        const types = ['thingTypeahead', 'personTypeahead', 'organisationTypeahead', 'customEntityTypeahead', 'userTypeahead', 'typeahead', 'classificationTypeahead'];
        const attrs = ((formDefinition && formDefinition.fields) || []).reduce((data, { properties, settings, type }) => {
            const { name, multiple, defaultValue } = properties || {};
            if(settings) {
                data[name] = attributes[name];
                if (types.includes(type) && ((multiple && !Array.isArray(attributes[name])) || (!multiple && Array.isArray(attributes[name])))) {
                    data[name] = defaultValue || null;
                }
            }
            return data;
        }, { ...attributes });
        return attrs;
    }

    @bind
    onChange(data) {
        this.setState({ attributes: { ...this.state.attributes, ...data } });
    }

    @bind
    toggleClassesModal (){
        this.setState(prevState => ({ isClassModalOpen: !prevState.isClassModalOpen }));
    }

    @bind
    toggleConfirmationModal(isConfirmModalOpen) {
        this.setState({ isConfirmModalOpen });
    }

    @bind
    handleCloseConfirmModal() {
        this.toggleConfirmationModal(false);

        setTimeout(() => {
            this.setState({
                confirmProps: {
                    classId: null,
                    confirmMessage: ''
                }
            });
        }, 300);
    }

    renderActionBar(state, canEdit, classes) {
        const { searchString, isSortAscending, isUnCollapsed } = state;
        const {type} = this.props;
        return (
            <>
                <ActionBar
                    left={
                        type !== 'backgroundjob' && canEdit && !isEmpty(classes) && <Button onClick={this.save}>Save</Button>
                    }
                    right={
                        <BurgerWrap>
                            <Icon
                                onClick={this.toggleSorting}
                                name={isSortAscending ? 'sort-ascending' : 'sort-descending'}
                                title={`Click here to sort classes by name in ${!isSortAscending ? 'ascending' : 'descending'} order`}
                            />
                            {
                                canEdit &&
                                <Icon name="plus" onClick={this.toggleClassesModal}/>
                            }
                            <Icon
                                onClick={this.toggleCollapse}
                                name={isUnCollapsed ? 'unfold-less-horizontal' : 'unfold-more-horizontal'}
                                title={`Click here to ${isUnCollapsed ? 'hide' : 'show'} attributes of all classifications`}
                            />
                        </BurgerWrap>
                    }
                    rightShrink
                />

                <GridWrapper>
                    {!!!searchString && <SearchIcon name="magnify" />}
                    <TextFieldStyled
                        onChange={this.onSearchChange}
                        InputProps={{ disableUnderline: true }}
                        variant="standard"
                        margin="none"
                        value={searchString}
                        placeholder="Search for classes / groups / attribute name ..."
                    />
                </GridWrapper>

            </>
        );
    }

    @bind
    renderClasses({
        details, classes, attributes, searchString, canEdit, isSortAscending,
        isUnCollapsed, entityId, entityType, entityName, location
    }) {
        this.formReferences = [];
        const search = (searchString || '').toLowerCase().trim();
        let sortedClasses = sortBy((classes || []), ({ name }) => name && name.toLowerCase());
        if(!isSortAscending) {
            sortedClasses = sortedClasses.reverse();
        }
        return sortedClasses.map((classification) => {
            const reference = React.createRef();
            this.formReferences.push(reference);
            return (
                <ClassificationItem
                    context={details}
                    ref={reference}
                    key={classification.uri}
                    classification={classification}
                    attributes={this.getAttributesForm(classification, attributes)}
                    search={search}
                    canEdit={canEdit}
                    isUnCollapsed={isUnCollapsed}
                    onChange={this.onChange}
                    removeClass={this.removeClassConfirm}
                    entity={{ id: entityId, type: entityType, name: entityName }}
                    location={location}
                />
            );
        });
    }

    @bind
    onSubmitClassFilter(selectedClasses: Array<Object>, attributes = {}) {
        const { id, type, reloadDetails } = this.props;
        if (!id || !type) {
            return;
        }
        const entityClassIds = get(this.props, 'entity.classes', []).map(({ id }) => id);
        const classIds = (selectedClasses || []).map(({ id }) => id).filter(id => !entityClassIds.includes(id));
        !isEmpty(classIds) && this.props.addEntityClasses(id, type, classIds, attributes).then(async(resp) => {
            if (!(resp instanceof Error)) {
                this.loadClasses();
                if (classIds?.includes(DIGITAL_TWIN_ID)) { // relaod entity data only when digital twin class is added because we need to show the menu item for digital twin
                    reloadDetails && await reloadDetails();
                }
            }
        });
    }

    @bind
    uploadFiles(files) {
        if (files instanceof File) {
            this.setState(prevState => ({
                files: [
                    ...prevState.files,
                    new File([files], removeSpecialCharacters(files.name), { type: files.type })
                ]
            }));
        }

        const fileArray = Array.isArray(files) ? files : Object.values(files);

        this.setState(prevState => ({
            files: [
                ...prevState.files,
                ...fileArray.map(file => new File([file], removeSpecialCharacters(file.name), { type: file.type }))
            ]
        }));
    }

    @bind
    async uploadFile({ file, filename, description }: Object) {
        const { id, type } = this.props;
        const fileHandler = this.state.files[0];

        const fileExtension = file.name.split('.').pop();
        let parsedFilename = removeSpecialCharacters(filename);

        if (fileExtension !== parsedFilename.split('.').pop()) {
            parsedFilename = `${parsedFilename}.${fileExtension}`;
        }

        file = new File([file], parsedFilename, { type: file.type });

        try {
            const response = await this.props.uploadRoomFile({ type, id, file, description });
            const meta = response && response.file;
            if (!meta) {
                fileHandler.reject(new Error('Invalid response.'));
            } else {
                fileHandler.resolve({
                    src: window.encodeURI(`/chat/file-upload/${meta.id}/${meta.name}`),
                    alt: response.msg || '',
                    reference: fileHandler.reference,
                    index: fileHandler.index,
                    name: file.name,
                    type: file.type,
                    id: response.file.id,
                });
            }
        } catch(error) {
            fileHandler.reject(error);
        }

        this.setState({ files: this.state.files.slice(1) });
    }

    @bind
    removeAttachment(file, referenceData, index, updatedFiles) {
        const { fileDeleteHandlers: deleteHandlers } = this.state;
        const fileDeleteHandlers = [ ...(deleteHandlers || [])];

        if(file.id && fileDeleteHandlers.find(f => f.id === file.id)) {
            return;
        }

        if(file.id) {
            fileDeleteHandlers.push({ file, referenceData, index });
        }

        let { attributes } = this.state;
        const { path, reference } = referenceData;
        const isTopLevel = !path;
        const dataPath = isTopLevel ? reference : path;
        let data = get(attributes,  dataPath);
        if(isTopLevel) {
            attributes = set(attributes, reference, updatedFiles);
        } else {
            if(Array.isArray(data)) {
                data = data.map((d) => {
                    let files = get(d, reference);
                    if(files) {
                        files = files.filter(f =>  file.id ? f.id !== file.id : f !== file);
                        return set(d, reference, files);
                    }
                    return d;
                });
            } else {
                const files = get(data, reference);
                if(files) {
                    data = set(data, reference, updatedFiles);
                }
            }
            attributes = set(attributes, dataPath, data);
        }
        this.setState({ fileDeleteHandlers, attributes });
    }

    @bind
    async rejectFile() {
        const { files }= this.state;
        const rejectedFile = files.shift();
        if(rejectedFile) {
            rejectedFile.resolve({ ...rejectedFile, canceled: true });
        }
        this.setState(state => ({ files }));
    }

    render(): Object {
        const { id, type, name, isLoading, canEdit, location, details, filterBy, isFileUploading } = this.props;
        if (isLoading) {
            return <Loader absolute backdrop />;
        }
        const {
            files, refreshKey, classes, attributes, searchString, isSortAscending, isUnCollapsed, isClassModalOpen, isConfirmModalOpen, confirmProps
        } = this.state;

        return (
            <Wrapper>
                {this.renderActionBar(this.state, canEdit, classes)}
                <LayoutStyled key={refreshKey} content={this.renderClasses({
                    details,
                    classes,
                    attributes,
                    searchString,
                    canEdit,
                    isSortAscending,
                    isUnCollapsed,
                    entityId: id,
                    entityType: type,
                    entityName: name,
                    location,
                })} />
                <AddClassificationModal
                    context={details}
                    title="Select Classes"
                    entityType={this.props.type}
                    selectedClasses={get(this.props, 'entity.classes', [])}
                    exclude
                    excludeAbstract
                    withAttributes
                    isOpen={isClassModalOpen}
                    onSubmit={this.onSubmitClassFilter}
                    closeModal={this.toggleClassesModal}
                    filterBy={filterBy}
                />
                <ConfirmationModal
                    header='Remove Class'
                    message={confirmProps?.confirmMessage}
                    open={isConfirmModalOpen}
                    confirmButtonText='Remove'
                    declineButtonText='Cancel'
                    onClose={this.handleCloseConfirmModal}
                    onConfirm={this.removeClass}
                />
                {!!files[0] && (
                    <ChatFileUploadModal
                        file={files[0]?.file}
                        isUploading={isFileUploading}
                        uploadFile={this.uploadFile}
                        closeFileForm={this.rejectFile}
                    />
                )}
            </Wrapper>
        );
    }
}

export default connect(
    (state, props) => ({
        id: props.id || getStr(props, 'match.params.id', ''),
        profile: state.user.profile,
        isLoading: state.entities.classification.isLoading,
        entity: state.entities.classification.data,
        isFileUploading: state.chat.room.isFileUploading,

    }),
    {
        loadEntityClassifications,
        removeEntityClass,
        updateEntity,
        addEntityClasses,

        openProcessSidebar,
        openClassSidebar,
        openTeamSidebar,
        openTaskSidebar,
        openWorkspaceSidebar,
        uploadRoomFile,
        deleteChatRoomAttachment
    },
    null,
    { forwardRef: true }
)(withRouter(ClassificationsTab));
