/* @flow */

import { help } from 'app/utils/designer/form/settings/common/commonHelpers';
import { onLoad, isVisible } from 'app/utils/designer/form/settings/common/commonEvents';
import { flexGrow } from 'app/utils/designer/form/settings/common/commonStyles';

const panels = [
    {
        header: 'Base',
        children: [
            {
                type: 'text',
                properties: { label: 'Text', name: 'properties.text' },
                constraints: { required: true }
            },
            {
                type: 'typeahead',
                properties: {
                    label: 'Variant',
                    name: 'properties.variant',
                    valueField:'value',
                    clearable: false,
                    options: [
                        { value: 'h1' , label: 'H1' },
                        { value: 'h2' , label: 'H2' },
                        { value: 'h3' , label: 'H3' },
                        { value: 'h4' , label: 'H4' },
                        { value: 'h5' , label: 'H5' },
                        { value: 'h6' , label: 'H6' },
                    ]
                }
            },
        ],
    },
    {
        header: 'Events',
        children: [
            onLoad,
            isVisible,
        ]
    },
    {
        header: 'Styles',
        children: [ flexGrow ]
    },
    {
        header: 'Helpers',
        children: [ help ]
    },
];

export default {
    defaults: { variant: 'h3' },
    panels: settingValues => panels,
};
