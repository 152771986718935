// @flow
import { LAYERS, normalizeAttributes } from 'app/utils/maps/layer/layerUtils';
import { drawWMSLayer } from 'app/components/molecules/Map/DrawShapes/DrawWMSLayer';
import cesiumLayerConfig from 'app/config/cesiumLayerConfig';

export const createShapes = (layers: Array<Object>, map: Object, allClasses: any) => {
    const layersGroup = map.getLayersGroup('allLayers');
    if (layersGroup) {
        layers?.length && layers.forEach(async (layer, index) => {
            const { attributes, primary, type, classes, ...rest } = layer || {};
            const layerType = layer?.type;
            const normalizedAttributes = normalizeAttributes(classes, attributes) || {};
            const _attributes = {...normalizedAttributes, ...rest};
            let layerToAdd = null;
            const cesiumLayer = [cesiumLayerConfig?.cesiumLayer?.oldType,cesiumLayerConfig?.cesiumLayer?.type];
            if (layerType  === LAYERS.wms) {
                const layer = primary?.['layer'];
                const layer_url = primary?.['layer_url'];
                layerToAdd = drawWMSLayer({layer, layer_url, _attributes, primary});
            } else if (layerType === LAYERS.drawing) {
                map.addEntityLayer({ ..._attributes, type:'drawing-layer', primary, styles: { pinStyle: 'drawing'}});
            } else if (layerType === LAYERS.replay) {
                map.addEntityLayer({ ..._attributes, type:'replay-layer', primary, styles: { pinStyle: 'replay'}});
            } else if (layerType === LAYERS.entity || layerType === LAYERS.entityLayer ) {
                const layerClass = layer?.entity_type;
                const styleType =  layerType?.styles?.pinStyle || 'cluster';
                const classData = allClasses?.length && (allClasses.find(cls => cls.uri === layerClass?.uri) || layerClass);
                await map.addEntityLayer({..._attributes, type: layerClass?.uri, styleType, primary, entityType: layerType, layerTitle: 'Entity Layer', classData});

            }
            else if (layerType === LAYERS.event ) {
                const styleType = layerType?.styles?.pinStyle || 'cluster';
                map.addEntityLayer({..._attributes, type: LAYERS.event, styleType, primary, entityType: layerType, layerTitle: 'Event Layer'});
            }
            else if(cesiumLayer.includes(layerType)) {
                map.addEntityLayer({..._attributes, type: cesiumLayerConfig?.type, primary, styles: { pinStyle: layerType }, entityType: layerType, type: layerType, layerTitle: 'Cesium Ion Layer'});
            }
            layerToAdd && layersGroup.getLayers().array_.unshift(layerToAdd);
        });
    }
};
