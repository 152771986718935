import React from 'react';
import { Grid, Checkbox } from '@mic3/platform-ui';

import Help from 'app/utils/designer/form/settings/common/Help';
import TextareaDialog from 'app/containers/Designer/Form/components/TextareaDialog';

// this rule only for class attributes
export const readOnly = {
    type: 'custom',
    properties: {
        name: 'properties.disabled',
        disabled: data =>  !!data?.constraints?.required || !!data?.properties?.static || !!data?.properties?.calculation,
        onChange: (e) => {
            return [e.target.value ? {
                name: 'constraints.required',
                value: false
            } : null, e.target].filter(Boolean);
        },
        noBackground: 1,
        noMargins: 1,
        Component: ({ label, name, template, onChange, disabled, value, ...props}) => (
            <Grid container justify="space-between">
                <Checkbox
                    name={name}
                    onChange={onChange}
                    disabled={disabled}
                    value={!!value}
                    label='Read Only'
                />
                <TextareaDialog
                    button
                    useCodeEditor
                    title="Customise read only script"
                    buttonLabel="customise"
                    parseAs="javascript"
                    disabled={disabled}
                    name={name}
                    onChange={onChange}
                    value={value === true || value === false ? '' : value}
                    template={isCustomRequiredCodeExample}
                />
            </Grid>
        ),
        help: <Help message="Read only field or not." />,
    }
};

// this rule only for class attributes
export const staticAttribute = (settingsValues) => ({
    type: 'checkbox',
    properties: {
        label: 'Static',
        name: 'properties.static',
        disabled: data => data => {
            return (!!data?.properties?.disabled || !!data?.constraints?.required
            || !!settingsValues.classification?.entityPrimaryIndexes?.find(t => t?.state === 'created' && t?.primaryAttribute === data?.properties?.name)
            );
        }
        ,
        help: <Help message="Static field or not." />
    }
});

const isCustomRequiredCodeExample = `
// Example:

/**
 * The function will handle the component required and it must return a
 * boolean value. If the returned value is true the component will be required.
 *
 * @param data, the data accessible to the component
 * @param context, the global context
 * @param inherited, the inherited data (only for components that are inside of a group)
 */
(data, context, inherited) => !data.visible


/*
The global context will contains the following fields:
- user, the data related to the user profile
- process, the data related to the process (if any)
- task, the data related to the task (if any)
- graphql, a fuction to call our main GQL API
- location, a JSON containing the origin and the href of this form
- sso, a JSON containing the SSO token

The graphql function has 2 paramers:
- body, the query and the data
- options, the HTTP request options (header, ...)

Example:
async (data, context, inherited) => {
  const value = data.name
  const query = \`
  query findPeople($filterBy:[JSON]) {
    people(page: 1, filterBy: $filterBy) {
      name
    }
  }
  \`
  const vars = {
    "filterBy": [{ field: "name", op: "contains", value }]
  }
  const response = await context.graphql({ query, data: vars })
  return response.people.length > 0
}

If a component is definied inside of a group or a group repeat, the data
parameter will contains only the data corresponding to the group or to the
related item in case of a group repeat.
In this case, the fourth parameter will contains the inherited fields:
- data, it will contains the process data
- path, it is the path of the reference used by the group
- index, the index of the item related to the component in a group repeat
*/
`;

export const classRequired = {
    type: 'custom',
    properties: {
        name: 'constraints.required',
        disabled: data =>  !!data?.properties?.disabled || !!data?.properties?.static || !!data?.properties?.calculation,
        onChange: (e) => {
            return [{
                name: 'constraints.required',
                value:  e.target.value,
            }, e.target.value ? { name: 'properties.disabled', value: false } : null].filter(Boolean);
        },
        noBackground: 1,
        noMargins: 1,
        Component: ({ label, name, template, onChange, disabled, value, ...props}) => (
            <Grid container justify="space-between">
                <Checkbox
                    name={name}
                    onChange={onChange}
                    disabled={disabled}
                    value={!!value}
                    label='Required'
                />
                <TextareaDialog
                    button
                    useCodeEditor
                    title="Customise required script"
                    buttonLabel="customise"
                    parseAs="javascript"
                    disabled={disabled}
                    name={name}
                    onChange={onChange}
                    value={value === true || value === false ? '' : value}
                    template={isCustomRequiredCodeExample}
                />
            </Grid>
        ),
        help: <Help message="If true the field is required" />,
    },
};

export const required = classRequired;

export const minLength = {
    type: 'number',
    properties: {
        label: 'min length',
        name: 'constraints.minLength',
        help: <Help message="the minimum length" />,
    },
};

export const maxLength = {
    type: 'number',
    properties: {
        label: 'max length',
        name: 'constraints.maxLength',
        help: <Help message="the maximum length" />,
    },
};
