/* @flow */
import { graphql } from 'graphql/client';
import { gql } from '@apollo/client';
import { loadData, mutateData, queryData } from 'app/utils/redux/action-utils';
import uuidv1 from 'uuid/v1';

import relationsQuery from 'graphql/entities/relationships/relationsQuery';
import eventEntityRelationsQuery from 'graphql/entities/relationships/eventEntityRelationsQuery';
import eventToEventRelationsQuery from 'graphql/entities/relationships/eventToEventRelationsQuery';
import relationsTypeaheadQuery from 'graphql/entities/relationships/relationsTypeaheadQuery';
import relationDefinitionQuery from 'graphql/entities/relationships/relationDefinitionQuery';
import createRelationMutation from 'graphql/entities/relationships/createRelationMutation';
import createEventEntityRelationMutation from 'graphql/entities/relationships/createEventEntityRelationMutation';
import updateEventEntityRelationAttributesMutation from 'graphql/entities/relationships/updateEventEntityRelationAttributesMutation';
import createEventToEventRelationMutation from 'graphql/entities/relationships/createEventToEventRelationMutation';
import addRelationDefinitionMutation from 'graphql/entities/relationships/addRelationDefinitionMutation';
import updateRelationMutation from 'graphql/entities/relationships/updateRelationMutation';
import deleteRelationMutation from 'graphql/entities/relationships/deleteRelationMutation';
import eventEntityRelationRemoveMutation from 'graphql/entities/relationships/eventEntityRelationRemoveMutation';
import eventToEventRelationRemoveMutation from 'graphql/entities/relationships/eventToEventRelationRemoveMutation';
import relationDefinitionAutocompleteQuery from 'graphql/entities/relationships/relationDefinitionAutocompleteQuery';
import relationDefinitionsQuery from 'graphql/entities/relationships/relationDefinitionsQuery';
import relationDefinitionDetailsQuery from 'graphql/entities/relationships/relationDefinitionDetailsQuery';
import relationDefinitionsListQuery from 'graphql/entities/relationships/relationDefinitionsListQuery';
import updateRelationDefinitionMutation from 'graphql/entities/relationships/updateRelationDefinitionMutation';
import removeRelationDefinitionClassesMutation from 'graphql/entities/relationships/removeRelationDefinitionClassesMutation';
import addRelationDefinitionClassesMutation from 'graphql/entities/relationships/addRelationDefinitionClassesMutation';
import relationUserToTeamQuery from 'graphql/entities/relationships/relationUserToTeamQuery';
import relationTeamToWorkspaceQuery from 'graphql/entities/relationships/relationTeamToWorkspaceQuery';
import relationUserToWorkspaceQuery from 'graphql/entities/relationships/relationUserToWorkspaceQuery';
import relationTeamToUserQuery from 'graphql/entities/relationships/relationTeamToUserQuery';
import relationWorkspaceToTeamQuery from 'graphql/entities/relationships/relationWorkspaceToTeamQuery';
import relationWorkspaceToUserQuery from 'graphql/entities/relationships/relationWorkspaceToUserQuery';
import OptionsBuilder from 'app/utils/api/OptionsBuilder';
import { getAvatar } from 'app/utils/avatar/avatar';
import { PUBLIC_TEAM_ID, SUPER_USERS_TEAM_ID } from 'app/config/config';

export const LOAD_AVATAR_STARTED = '@@affectli/entities/relationships/LOAD_AVATAR_STARTED';
export const LOAD_AVATAR = '@@affectli/entities/relationships/LOAD_AVATAR';

export const SAVE_RELATION_DEFINITIONS_STARTED = '@@affectli/entities/relationships/SAVE_RELATION_DEFINITIONS_STARTED';
export const SAVE_RELATION_DEFINITIONS = '@@affectli/entities/relationships/SAVE_RELATION_DEFINITIONS';
export const ADD_DEFINITION_TO_CACHE = '@@affectli/entities/relationships/ADD_DEFINITION_TO_CACHE';

export const ADD_RELATIONSHIP_STARTED = '@@affectli/relationships/ADD_RELATIONSHIP_STARTED';
export const ADD_RELATIONSHIP = '@@affectli/relationships/ADD_RELATIONSHIP';

export const UPDATE_RELATIONSHIP_STARTED = '@@affectli/relationships/UPDATE_RELATIONSHIP_STARTED';
export const UPDATE_RELATIONSHIP = '@@affectli/relationships/UPDATE_RELATIONSHIP';

export const UPDATE_EVENT_ENTITY_RELATIONSHIP_ATTRIBUTES_STARTED =
    '@@affectli/relationships/UPDATE_EVENT_ENTITY_RELATIONSHIP_ATTRIBUTES_STARTED';
export const UPDATE_EVENT_ENTITY_RELATIONSHIP_ATTRIBUTES = '@@affectli/relationships/UPDATE_EVENT_ENTITY_RELATIONSHIP_ATTRIBUTES';

export const REMOVE_RELATIONSHIP_STARTED = '@@affectli/relationships/REMOVE_RELATIONSHIP_STARTED';
export const REMOVE_RELATIONSHIP = '@@affectli/relationships/REMOVE_RELATIONSHIP';

export const REMOVE_EVENT_ENTITY_RELATION_STARTED = '@@affectli/relationships/REMOVE_EVENT_ENTITY_RELATION_STARTED';
export const REMOVE_EVENT_ENTITY_RELATION = '@@affectli/relationships/REMOVE_EVENT_ENTITY_RELATION';

export const REMOVE_EVENT_TO_EVENT_RELATION_STARTED = '@@affectli/relationships/REMOVE_EVENT_TO_EVENT_RELATION_STARTED';
export const REMOVE_EVENT_TO_EVENT_RELATION = '@@affectli/relationships/REMOVE_EVENT_TO_EVENT_RELATION';

export const LOAD_RELATION_DEFINITION_AUTOCOMPLETE_STARTED = '@@affectli/admin/groups/LOAD_CLASSIFICATION_AUTOCOMPLETE_STARTED';
export const LOAD_RELATION_DEFINITION_AUTOCOMPLETE = '@@affectli/admin/groups/LOAD_CLASSIFICATION_AUTOCOMPLETE';

export const LOAD_V_RELATION_DEFINITION_AUTOCOMPLETE_STARTED = '@@affectli/admin/groups/LOAD_CLASSIFICATION_TYPEAHEAD_STARTED';
export const LOAD_V_RELATION_DEFINITION_AUTOCOMPLETE = '@@affectli/admin/groups/LOAD_CLASSIFICATION_TYPEAHEAD';

export const LOAD_RELATIONSHIP_ENTITIES_ADD_STARTED = '@@affectli/entities/relationships/LOAD_RELATIONSHIP_ENTITIES_ADD_STARTED';
export const LOAD_RELATIONSHIP_ENTITIES_ADD = '@@affectli/entities/relationships/LOAD_RELATIONSHIP_ENTITIES_ADD';

export const LOAD_RELATIONS_TYPEAHEAD_STARTED = '@@affectli/entities/relationships/LOAD_RELATIONS_TYPEAHEAD_STARTED';
export const LOAD_RELATIONS_TYPEAHEAD = '@@affectli/entities/relationships/LOAD_RELATIONS_TYPEAHEAD';

export const FETCH_RELATIONS_STARTED = '@@affectli/entities/relationships/FETCH_RELATIONS_STARTED';
export const FETCH_RELATIONS = '@@affectli/entities/relationships/FETCH_RELATIONS';

export const LOAD_RELATION_STARTED = '@@affectli/entities/relationships/LOAD_RELATION_STARTED';
export const LOAD_RELATION = '@@affectli/entities/relationships/LOAD_RELATION';

export const FETCH_RELATION_DEFINITION_STARTED = '@@affectli/entities/relationships/FETCH_RELATION_DEFINITION_STARTED';
export const FETCH_RELATION_DEFINITION = '@@affectli/entities/relationships/FETCH_RELATION_DEFINITION';

export const RELATION_DEFINITIONS_LIST_STARTED = '@@affectli/entities/relationships/RELATION_DEFINITIONS_LIST_STARTED';
export const RELATION_DEFINITIONS_LIST = '@@affectli/entities/relationships/RELATION_DEFINITIONS_LIST';

export const LOAD_RELATION_DEFINITION_DETAILS_STARTED = '@@affectli/entities/relationships/LOAD_RELATION_DEFINITION_DETAILS_STARTED';
export const LOAD_RELATION_DEFINITION_DETAILS = '@@affectli/entities/relationships/LOAD_RELATION_DEFINITION_DETAILS';

export const GET_MAPED_RELATIONS_STARTED = '@@affectli/entities/relationships/GET_MAPED_RELATIONS_STARTED';
export const GET_MAPED_RELATIONS = '@@affectli/entities/relationships/GET_MAPED_RELATIONS';

export const LOAD_MAPED_RELATIONS_STARTED = '@@affectli/entities/relationships/LOAD_MAPED_RELATIONS_STARTED';
export const LOAD_MAPED_RELATIONS = '@@affectli/entities/relationships/LOAD_MAPED_RELATIONS';

export const LOAD_USER_TEAM_RELATIONS_STARTED = '@@affectli/entities/relationships/LOAD_USER_TEAM_RELATIONS_STARTED';
export const LOAD_USER_TEAM_RELATIONS = '@@affectli/entities/relationships/LOAD_USER_TEAM_RELATIONS';

export const LOAD_USER_WORKSPACE_RELATIONS_STARTED = '@@affectli/entities/relationships/LOAD_USER_WORKSPACE_RELATIONS_STARTED';
export const LOAD_USER_WORKSPACE_RELATIONS = '@@affectli/entities/relationships/LOAD_USER_WORKSPACE_RELATIONS';

export const LOAD_TEAM_USER_RELATIONS_STARTED = '@@affectli/entities/relationships/LOAD_TEAM_USER_RELATIONS_STARTED';
export const LOAD_TEAM_USER_RELATIONS = '@@affectli/entities/relationships/LOAD_TEAM_USER_RELATIONS';

export const LOAD_TEAM_WORKSPACE_RELATIONS_STARTED = '@@affectli/entities/relationships/LOAD_TEAM_WORKSPACE_RELATIONS_STARTED';
export const LOAD_TEAM_WORKSPACE_RELATIONS = '@@affectli/entities/relationships/LOAD_TEAM_WORKSPACE_RELATIONS';

export const LOAD_WORKSPACE_TEAM_RELATIONS_STARTED = '@@affectli/entities/relationships/LOAD_WORKSPACE_TEAM_RELATIONS_STARTED';
export const LOAD_WORKSPACE_TEAM_RELATIONS = '@@affectli/entities/relationships/LOAD_WORKSPACE_TEAM_RELATIONS';

export const LOAD_WORKSPACE_USER_RELATIONS_STARTED = '@@affectli/entities/relationships/LOAD_WORKSPACE_USER_RELATIONS_STARTED';
export const LOAD_WORKSPACE_USER_RELATIONS = '@@affectli/entities/relationships/LOAD_WORKSPACE_USER_RELATIONS';

export const RELATIONS_VIEW_CHANGE = '@@affectli/entities/relationships/RELATIONS_VIEW_CHANGE';

export const loadAvatar = (id) => (dispatch: Function, getState: Function) => {
    const avatar = getAvatar(getState(), 'relationDefinition', id);
    if (avatar && (avatar.isLoading || avatar.loadedAt > Date.now() - 60 * 60 * 1000)) {
        // if we are loding the avatar or it is already loaded and it is stored less then 1 hour ago
        // we return the data in the state without calling the service
        return Promise.resolve(avatar);
    }
    return loadData(LOAD_AVATAR_STARTED, LOAD_AVATAR, relationDefinitionDetailsQuery)({ id })(dispatch, getState);
};

export const changeRelationsView = (view = 'List') => (dispatch) => {
    dispatch({ type: RELATIONS_VIEW_CHANGE, payload: view });
};

export const relationDefinitionsListView = ({ extraFields, options }: Object) =>
    loadData(RELATION_DEFINITIONS_LIST_STARTED, RELATION_DEFINITIONS_LIST, relationDefinitionsListQuery(extraFields))(options);

export const loadRelationDefinitionDetails = (id: string) =>
    loadData(LOAD_RELATION_DEFINITION_DETAILS_STARTED, LOAD_RELATION_DEFINITION_DETAILS, relationDefinitionDetailsQuery)({ id });

export const addRelationDefinitionClasses = (id: string, classes: Array<string>) =>
    loadData(
        LOAD_RELATION_DEFINITION_DETAILS_STARTED,
        LOAD_RELATION_DEFINITION_DETAILS,
        addRelationDefinitionClassesMutation
    )({ id, classes });

export const removeRelationDefinitionClasses = (id: string, classes: Array<string>) =>
    loadData(
        LOAD_RELATION_DEFINITION_DETAILS_STARTED,
        LOAD_RELATION_DEFINITION_DETAILS,
        removeRelationDefinitionClassesMutation
    )({ id, classes });

export const updateRelationDefinition = ({ id, type, relatedType, description, relatedDescription }: Object) =>
    mutateData(
        LOAD_RELATION_DEFINITION_DETAILS_STARTED,
        LOAD_RELATION_DEFINITION_DETAILS,
        updateRelationDefinitionMutation
    )({ record: { id, type, relatedType, description, relatedDescription } });

export const addRelationDefinition = (record: Object) => (dispatch, getState) => {
    return mutateData(
        LOAD_RELATION_DEFINITION_DETAILS_STARTED,
        LOAD_RELATION_DEFINITION_DETAILS,
        addRelationDefinitionMutation,
        'Relation Definition Added'
    )({ record })(dispatch, getState).then((result) => {
        !(result instanceof Error) && dispatch({ type: ADD_DEFINITION_TO_CACHE, payload: result });
        return result;
    });
};

export const loadRelationsTypeahead = loadData(LOAD_RELATIONS_TYPEAHEAD_STARTED, LOAD_RELATIONS_TYPEAHEAD, relationsTypeaheadQuery);

/**
 * Loads the suggestions for the relation definition autocomplete component.
 */
export const loadRelationDefinitionAutocomplete = loadData(
    LOAD_RELATION_DEFINITION_AUTOCOMPLETE_STARTED,
    LOAD_RELATION_DEFINITION_AUTOCOMPLETE,
    relationDefinitionAutocompleteQuery
);

/**
 * Loads the suggestions for the relation definition typeahead component.
 */
export const loadVRelationDefinitionAutocomplete = (options, props) => (dispatch, getState) => {
    const { fromType, fromUris, toUris, query } = props || {};
    if (fromType) {
        const definitions = getState().entities?.mapedRelations?.relationDefinitionsMap?.[fromType];
        if (definitions?.length) {
            const filteredDefs = definitions
                .map((def) => {
                    const { type, relatedType, description, relatedDescription } = def;
                    if (
                        (fromUris?.includes(type) && (!toUris?.length || toUris?.includes(relatedType))) ||
                        (fromUris?.includes(relatedType) && (!toUris?.length || toUris?.includes(type)))
                    ) {
                        if (query) {
                            if (
                                description?.toLowerCase()?.includes(query?.toLowerCase()) ||
                                relatedDescription?.toLowerCase()?.includes(query?.toLowerCase())
                            ) {
                                return def;
                            }
                            return null;
                        }
                        return def;
                    }
                    return null;
                })
                .filter(Boolean);
            dispatch({ type: SAVE_RELATION_DEFINITIONS }); // Just to set isLoading false as we already loaded data in cache
            return Promise.resolve(filteredDefs);
        }
    }
    return loadData(
        LOAD_V_RELATION_DEFINITION_AUTOCOMPLETE_STARTED,
        LOAD_V_RELATION_DEFINITION_AUTOCOMPLETE,
        relationDefinitionsQuery
    )(options)(dispatch, getState).then((resp) => {
        dispatch({ type: SAVE_RELATION_DEFINITIONS, payload: { type: fromType, definitions: resp } });
        return resp;
    });
};

export const loadAllRelationDefinitionsOfType = (fromType) => async (dispatch, getState) => {
    dispatch({ type: SAVE_RELATION_DEFINITIONS_STARTED });
    const entityTypes = getState().app.allPrimaryClasses?.records;
    if (!entityTypes?.length) {
        return console.error('Entity Types are not loaded.'); // eslint-disable-line
    }
    let typeResp = {};
    try {
        const { id: fromId } = entityTypes.find(({ uri }) => uri === fromType);
        typeResp = await graphql.query({
            query: gql`
                query anchestors($fromId: ID!) {
                    typeUris: classAncestors(id: $fromId) {
                        uri
                    }
                }
            `,
            variables: { fromId },
            fetchPolicy: 'no-cache',
        });
    } catch (error) {}
    let typeUris = typeResp?.data?.typeUris || [];
    typeUris = typeUris.map((t) => t?.uri).filter(Boolean);
    typeUris = typeUris?.length ? typeUris : [fromType];
    const options = {
        filterBy: [
            {
                or: [
                    { field: 'type', op: 'in', value: typeUris },
                    { field: 'relatedType', op: 'in', value: typeUris },
                ],
            },
        ],
    };
    dispatch(loadVRelationDefinitionAutocomplete(options, { fromType }));
    return Promise.resolve({ ancestors: typeUris });
};
/**
 * Loads the suggestions for the relation definition typeahead component.
 */
export const loadRelation = (entityId: string, entityType: string, relationId: string) => {
    const variables = new OptionsBuilder().filter({ field: 'relation.id', op: '=', value: relationId }).build();

    return loadData(LOAD_RELATION_STARTED, LOAD_RELATION, relationsQuery)({ entityId, entityType, ...variables });
};

export const updateRelationship = (relation: string, attributes: Object) => {
    return mutateData(
        UPDATE_RELATIONSHIP_STARTED,
        UPDATE_RELATIONSHIP,
        updateRelationMutation,
        'Relationship updated'
    )({ relation, attributes });
};

/**
 * Fetch all the relations of the given entity.
 *
 * @params uid (string) the ID of the entity/task/process
 * @params type (string) the type of the entity (one of: thing, organisation, person, custom, task, process)
 */
export const fetchRelations = loadData(FETCH_RELATIONS_STARTED, FETCH_RELATIONS, relationsQuery);

const addUserType = (payload) => {
    const { relations } = payload;
    if (!relations?.length) {
        return payload;
    }
    const normalizedRelations = relations.map((rel) => {
        if (rel?.entity?.__typename === 'UserReference') {
            rel.entity.type = 'user';
        }
        if (rel?.relatedEntity?.__typename === 'UserReference') {
            rel.relatedEntity.type = 'user';
        }
        return rel;
    });
    return { ...payload, relations: normalizedRelations };
};

const normalizeRelation =(data, relation, type )=>{
    return {
        'entity': data.relatedEntity,
        'relatedEntity': {
            'id':relation.id,
            'name': relation.name ? relation.name : relation.username,
            'type': type
        },
        'isReverseRelation': false,
        'relation': {
            'id': uuidv1(),
            'relationDefinition': {
                'id': `${data.relatedEntity.id}${type}`,
                'description': `${type}s`
            },
        }
    };

};

const stabRelations = (data, relation, type, relationType) => {
    let extractedRelations = [];
    switch (relationType) {
        case 'userTeam':
        case 'teamWorkspace':
            extractedRelations = relation;
            break;
        case 'workspaceTeam':
            extractedRelations = relation?.teams?.filter((team) => team.role)?.map(({ team }) => team) || [];
            break;
        case 'workspaceUser':
            extractedRelations = relation?.[0]?.users?.map(({ user }) => user) || [];
            break;
        case 'teamUser':
            extractedRelations = relation?.users?.map(({ user }) => user) || [];
            break;
        case 'userWorkspace':
            extractedRelations = relation?.map(({ workspace }) => workspace) || [];
            break;
        default:
            throw new Error('Unknown relation type');
    }
    return data ? extractedRelations?.map(item => normalizeRelation(data, item, type)) : [];
};

export const getMapedRelations = (params) => async (dispatch, getState) => {
    const { viewType, data, ...variables } = params;
    let userTeamRelations = [];
    let userWorkspaceRelations = [];
    let teamUserRelations = [];
    let teamWorkspaceRelations = [];
    let workspaceTeamRelations = [];
    let workspaceUserRelations = [];
    
    dispatch({type: GET_MAPED_RELATIONS_STARTED, meta: variables });

    if (viewType === 'eventEntity') {
        return dispatch(loadEventEntityRelations(params));
    }
    if (viewType === 'eventToEvent') {
        return dispatch(loadEventToEventRelations(params));
    }
    
    const type = params?.entityType?.toLowerCase();

    if(type === 'user'){
        userTeamRelations = await dispatch(loadUserTeamRelations(params?.entityId, data, type, 'userTeam'));
        userWorkspaceRelations = await dispatch(loadUserWorkspaceRelations(params?.entityId, data, type, 'userWorkspace' ));
    }
    if(type === 'team'){
        teamUserRelations = await dispatch(loadTeamUserRelations(params?.entityId, data, type, 'teamUser'));
        teamWorkspaceRelations = await dispatch(loadTeamWorkspaceRelations(params?.entityId, data, type, 'teamWorkspace'));
    }
    if(type === 'workspace'){
        workspaceTeamRelations = await dispatch(loadWorkspaceTeamRelations(params?.entityId, data, type, 'workspaceTeam'));
        workspaceUserRelations = await dispatch(loadWorkspaceUserRelations(params?.entityId, data, type, 'workspaceUser'));
    }

    const response = await loadData(
        LOAD_MAPED_RELATIONS_STARTED,
        LOAD_MAPED_RELATIONS,
        relationsQuery,
        addUserType
    )(variables)(dispatch, getState);

    const allRelations = [
        ...response.relations,
        ...userTeamRelations,
        ...userWorkspaceRelations,
        ...teamUserRelations,
        ...teamWorkspaceRelations,
        ...workspaceTeamRelations,
        ...workspaceUserRelations
    ];

    dispatch({
        type: GET_MAPED_RELATIONS,
        payload: { relations: allRelations },
        meta: variables
    });

    return { relations: allRelations };
};

const _modifyEventEntityResponse = (payload) => {
    // Modifying payload to match it with entities relations structure so we can use the existing UI components
    const relations = payload?.relations?.map((data) => {
        const { event, entity, relationDefinition, attributes, ...rest } = data;
        rest.entity = { ...event, type: 'event' };
        rest.relatedEntity = entity;
        rest.relation = { attributes, relationDefinition };
        return rest;
    });
    return { ...payload, relations };
};

const _modifyEventToEventResponse = (payload) => {
    // Modifying payload to match it with entities relations structure so we can use the existing UI components
    const relations = payload?.relations?.map((data) => {
        const { event, relatedEvent, relationDefinition, ...rest } = data;
        rest.entity = { ...event, type: 'event' };
        rest.relatedEntity = { ...relatedEvent, type: 'event' };
        rest.relation = { relationDefinition };
        return rest;
    });
    return { ...payload, relations };
};


export const loadEventEntityRelations = loadData(
    GET_MAPED_RELATIONS_STARTED,
    GET_MAPED_RELATIONS,
    eventEntityRelationsQuery,
    _modifyEventEntityResponse
);

export const loadEventToEventRelations = loadData(
    GET_MAPED_RELATIONS_STARTED,
    GET_MAPED_RELATIONS,
    eventToEventRelationsQuery,
    _modifyEventToEventResponse
);

export const loadUserTeamRelations = (id: String, data, type, relType) => async (dispatch, getState) => {

    const variables = new OptionsBuilder()
        .filter({ field: 'users.user.id', op: '=', value: id })
        .filter({ or: [
            { field: 'isSystem', op: '=', value: false },
            { field: 'id', op: 'in', value: [PUBLIC_TEAM_ID, SUPER_USERS_TEAM_ID] }
        ]})
        .build();
        
    return loadData(
        LOAD_USER_TEAM_RELATIONS_STARTED,
        LOAD_USER_TEAM_RELATIONS,
        relationUserToTeamQuery,
        (payload, meta) => {
            const parentEntity = data?.childrens.find((chil)=> chil.id === meta.entityId);
            const stabilizedData = stabRelations(parentEntity ? parentEntity : { relatedEntity: { id: meta.entityId, type: type } } , payload, 'team', relType);
            return stabilizedData;
        } 
    )({ ...variables, entityId: id })(dispatch, getState);
};

export const loadTeamWorkspaceRelations = (id: String, data, type, relType) => async (dispatch, getState)=>{

    const variables = new OptionsBuilder()
        .filter({ field: 'teams.team.id', op:'=', value: id })
        .filter({or:[
            { field: 'isSystem', op:'=', value: false },
        ]})
        .build();

    return loadData(
        LOAD_TEAM_WORKSPACE_RELATIONS_STARTED,
        LOAD_TEAM_WORKSPACE_RELATIONS,
        relationTeamToWorkspaceQuery,
        (payload, meta) => {
            const parentEntity = data?.childrens.find((chil)=> chil.id === meta.entityId);
            const stabilizedData = stabRelations(parentEntity ? parentEntity : { relatedEntity: { id: meta.entityId, type: type } } , payload, 'workspace', relType);
    
            return stabilizedData;
        } 
    )({ ...variables, entityId: id })(dispatch, getState);
};

export const loadUserWorkspaceRelations = (id: String, data, type, relType) => async (dispatch, getState) => {

    const variables = new OptionsBuilder()
        .filter({ field: 'users.user.id', op:'=', value: id })
        .filter({or:[{ field: 'workspace.isSystem', op:'=', value: false }]})
        .build();

    return loadData(
        LOAD_USER_WORKSPACE_RELATIONS_STARTED,
        LOAD_USER_WORKSPACE_RELATIONS,
        relationUserToWorkspaceQuery,
        (payload, meta) => {
            const parentEntity = data?.childrens.find((chil)=> chil.id === meta.entityId);
            const stabilizedData = stabRelations(parentEntity ? parentEntity : { relatedEntity: { id: meta.entityId, type: type } } , payload, 'workspace', relType);
            return stabilizedData;
        } 
    )({ ...variables, entityId: id })(dispatch, getState);
};

export const loadTeamUserRelations = (id: String, data, type, relType) => async (dispatch, getState) => {

    return loadData(
        LOAD_TEAM_USER_RELATIONS_STARTED,
        LOAD_TEAM_USER_RELATIONS,
        relationTeamToUserQuery,
        (payload, meta) => {
            const parentEntity = data?.childrens.find((chil)=> chil.id === meta.entityId);
            const stabilizedData = stabRelations(parentEntity ? parentEntity : { relatedEntity: { id: meta.entityId, type: type } } , payload, 'user', relType);
            return stabilizedData;
        } 
    )({ id, entityId: id })(dispatch, getState);

};

export const loadWorkspaceTeamRelations = (id: String, data, type, relType) => async (dispatch, getState) =>{

    return loadData(
        LOAD_WORKSPACE_TEAM_RELATIONS_STARTED,
        LOAD_WORKSPACE_TEAM_RELATIONS,
        relationWorkspaceToTeamQuery,
        (payload, meta) => {
            const parentEntity = data?.childrens.find((chil)=> chil.id === meta.entityId);
            const stabilizedData = stabRelations(parentEntity ? parentEntity : { relatedEntity: { id: meta.entityId, type:  type } } , payload, 'team', relType);
            return stabilizedData;
        } 
    )({ id, entityId: id })(dispatch, getState);
};

export const loadWorkspaceUserRelations = (id: String, data, type, relType) => async (dispatch, getState) => {

    const variables = new OptionsBuilder()
        .filter({ field: 'workspace.id', op:'=', value: id })
        .build();
    return loadData(
        LOAD_WORKSPACE_USER_RELATIONS_STARTED,
        LOAD_WORKSPACE_USER_RELATIONS,
        relationWorkspaceToUserQuery,
        (payload, meta) => {
            const parentEntity = data?.childrens.find((chil)=> chil.id === meta.entityId);
            const stabilizedData = stabRelations(parentEntity ? parentEntity : { relatedEntity: { id: meta.entityId, type: type } } , payload, 'user', relType);
            return stabilizedData;
        } 
    )({ ...variables, entityId: id })(dispatch, getState);
};    

export const fetchMapRelations = (variables: Object = {}) => queryData(relationsQuery, variables);

export const fetchRelationDefinition = (id: string) => {
    return loadData(FETCH_RELATION_DEFINITION_STARTED, FETCH_RELATION_DEFINITION, relationDefinitionQuery)({ id });
};

/**
 * Delete the relation with the given ID.
 *
 * @param id the relation ID.
 */
export const deleteRelationship = (relation: string) => {
    return mutateData(REMOVE_RELATIONSHIP_STARTED, REMOVE_RELATIONSHIP, deleteRelationMutation, 'Relationship removed')({ relation });
};

export const deleteEventEntityRelationship = (variables: Object) => {
    return mutateData(
        REMOVE_EVENT_ENTITY_RELATION_STARTED,
        REMOVE_EVENT_ENTITY_RELATION,
        eventEntityRelationRemoveMutation,
        'Relationship removed'
    )(variables);
};

export const deleteEventToEventRelationship = (variables: Object) => {
    return mutateData(
        REMOVE_EVENT_TO_EVENT_RELATION_STARTED,
        REMOVE_EVENT_TO_EVENT_RELATION,
        eventToEventRelationRemoveMutation,
        'Relationship removed'
    )(variables);
};

/**
 * Save a relationship between two entities.
 *
 * @param record record to save
 */
export const createRelationship = (record: Object) => {
    return mutateData(ADD_RELATIONSHIP_STARTED, ADD_RELATIONSHIP, createRelationMutation, 'Relationship added')(record);
};

export const createEventEntityRelationship = (record: Object) =>
    mutateData(ADD_RELATIONSHIP_STARTED, ADD_RELATIONSHIP, createEventEntityRelationMutation, 'Relationship added')(record);

export const updateEventEntityRelationAttributes = (record: Object) =>
    mutateData(
        UPDATE_EVENT_ENTITY_RELATIONSHIP_ATTRIBUTES,
        UPDATE_EVENT_ENTITY_RELATIONSHIP_ATTRIBUTES,
        updateEventEntityRelationAttributesMutation,
        'Relationship attributes updated.'
    )(record);

export const createEventToEventRelationship = (record: Object) =>
    mutateData(ADD_RELATIONSHIP_STARTED, ADD_RELATIONSHIP, createEventToEventRelationMutation, 'Relationship added')(record);

export const getAncestors = async (toId: string, fromId: string) => {
    let typeResp = {};
    let relatedResp = {};
    // Previously we were making two queries in single request, if one of them throws an exception
    // We lose the result of the other one also so I splitted them
    try {
        typeResp = await graphql.query({
            query: gql`
                query anchestors($toId: ID!) {
                    typeUris: classAncestors(id: $toId) {
                        uri
                    }
                }
            `,
            variables: { toId },
            fetchPolicy: 'no-cache',
        });
    } catch (error) {}
    try {
        relatedResp = await graphql.query({
            query: gql`
                query anchestors($fromId: ID!) {
                    relatedUris: classAncestors(id: $fromId) {
                        uri
                    }
                }
            `,
            variables: { fromId },
            fetchPolicy: 'no-cache',
        });
    } catch (error) {}
    let { relatedUris = [] } = relatedResp?.data || {};
    let { typeUris = [] } = typeResp?.data || {};
    if (typeUris?.length) typeUris = typeUris?.map(({ uri }) => uri);
    if (relatedUris?.length) relatedUris = relatedUris?.map(({ uri }) => uri);
    return { typeUris, relatedUris };
};
