import { gql } from '@apollo/client';

export default gql`
    query loadRelatedEntitiesTypeaheadQuery($type: String!, $orderBy: [JSON], $filterBy: [JSON], $excludeBy: [JSON], $startIndex: Int, $stopIndex: Int) {
        result: entities(type: $type, orderBy: $orderBy, filterBy: $filterBy, excludeBy: $excludeBy, startIndex: $startIndex, stopIndex: $stopIndex) {
            id
            name
            image
            type
            modifiedDate
            primary
        }
    }
`;
