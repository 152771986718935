/* @flow */

import moment from 'moment';

import { datefy } from 'app/utils/utils';
import { isDefined } from 'app/utils/utils';

export const DATETIME_FORMAT = 'DD MMM YYYY, HH:mm';
export const DATE_FORMAT = 'DD MMM YYYY';
export const DATETIME_DISPLAY_FORMAT = 'DD MMM YYYY HH:mm';
export const DATETIME_SAVE_FORMAT = '';
export const DATE_DISPLAY_FORMAT = 'DD MMM YYYY';
export const DATE_SAVE_FORMAT = 'YYYYMMDD';
export const TIME_FORMAT = 'HH:mm';
export const TIME_SAVE_FORMAT = 'HHmmssZ';
export const TIME_SAVE_REGEXPR = /[0-9]{6}\+[0-9]{2}:[0-9]{2}/;

const kindFormatMap = {
    time: [TIME_SAVE_FORMAT, TIME_FORMAT],
    date: [DATE_SAVE_FORMAT, DATE_DISPLAY_FORMAT],
    datetime: [DATETIME_SAVE_FORMAT, DATETIME_DISPLAY_FORMAT]
};

export const displayByKind = (kind: string, value: string) => {
    if (!value) return value;
    const [save, display] = kindFormatMap[kind] || kindFormatMap.datetime;
    return moment(value, save).format(display);
};

export const saveByKind = (kind: string, value: ?Date) => {
    if (!value) return value;
    const [save] = kindFormatMap[kind] || kindFormatMap.datetime;
    if (kind === 'time' && value.match && value.match(TIME_SAVE_REGEXPR)) {
        return value;
    }
    return moment(value).format(save);
};

export const formatByKind = (kind: string, value: ?string) => {
    if (!value) return value;
    if (kind === 'time' && value && value._isAMomentObject) {
        return value;
    }
    const [save] = kindFormatMap[kind] || kindFormatMap.datetime;
    const formatted = moment(value, save);
    return formatted.isValid() ? formatted : null;
};

/**
 * Formats a date.
 *
 * @param date the date to format (can be null or undefined)
 * @param format the format to use (optional)
 */
export const formatDate = (date: ?Date, format: ?string ) =>
    date ? moment(date).format(format || DATETIME_FORMAT) : '';

export const fromNow = (date: ?Date ) =>
    date ? moment(date).fromNow() : '';


export const resetTime = (date: Date) => {
    if (!date) {
        return date;
    }
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

export const isIsoDate = (date: any) => date
    && typeof date === 'string'
    && moment(date, moment.ISO_8601, true).isValid();

/**
 * Returns a new date object that is a clone of the given one.
 */
export const clone = (date: ?mixed) => {
    const parsedDate = datefy(date);
    return parsedDate && parsedDate === date ? new Date(parsedDate) : parsedDate;
};

/**
 * Returns true if the specified dates are refering to the same instant, false otherwise.
 */
export const equals = (dateA: ?mixed, dateB: ?mixed) => {
    const parsedDateA = datefy(dateA);
    const parsedDateB = datefy(dateB);
    if (!parsedDateA && !parsedDateB) {
        return true;
    }
    if (!parsedDateA || !parsedDateB) {
        return false;
    }
    return parsedDateA.getTime() === parsedDateB.getTime();
};


/**
 * Returns a new date object that is a clone of the given one with the given hours, minutes, seconds and ms.
 */
export const setHours = (date: ?mixed, hours: number, minutes?: number, seconds?: number, ms?: number) => {
    const newDate = clone(date);
    if (newDate) {
        newDate.setHours(hours, minutes, seconds, ms);
    }
    return newDate;
};

/**
 * Returns a new date object that is a clone of the given one with the given seconds and ms.
 */
export const setSeconds = (date: ?mixed, seconds: number, ms?: number) => {
    const newDate = clone(date);
    if (newDate) {
        newDate.setSeconds(seconds, ms);
    }
    return newDate;
};


export const formatInterval = (milliseconds: number) => {
    if (Math.trunc(milliseconds / 1000) < 60) {
        return 'less than a minute';
    }
    const interval = moment.duration(milliseconds);
    let years = interval.years();
    let months = interval.months();
    let days = interval.days();
    let minutes = interval.minutes();
    years = !years ? '' : years === 1 ? '1 year' : `${years} years`;
    months = !months ? '' : months === 1 ? '1 month' : `${months} months`;
    days = !days ? '' : days === 1 ? '1 day' : `${days} days`;
    minutes = !minutes ? '' : minutes === 1 ? '1 minute' : `${minutes} minutes`;
    return [years, months, days, minutes].filter(t => t).join(', ');
};

export const dateFormats = (withTime = false) => {
    const formats = [];
    const timeFormat = withTime ? `, ${TIME_FORMAT}` : '';
    for (const delimiter of [' ', '/', '-', '.']) {
        for (const d of ['DD']) {
            for (const m of ['MM']) {
                for (const y of ['YY', 'YYYY']) {
                    formats.push(`${d}${delimiter}${m}${delimiter}${y}${timeFormat}`);
                    formats.push(`${d}${delimiter}${y}${delimiter}${m}${timeFormat}`);
                    formats.push(`${m}${delimiter}${d}${delimiter}${y}${timeFormat}`);
                    formats.push(`${m}${delimiter}${y}${delimiter}${d}${timeFormat}`);
                    formats.push(`${y}${delimiter}${d}${delimiter}${m}${timeFormat}`);
                    formats.push(`${y}${delimiter}${m}${delimiter}${d}${timeFormat}`);
                }
            }
        }
    }
    return formats;
};

export const parseDuration = (durationMs: number) => {
    if (!isDefined(durationMs)) {
        return { days: 0, hours: 0, minutes: 0, seconds: 0, milliseconds: 0 };
    }
    const milliseconds = durationMs % 1000;
    let rest = Math.trunc(durationMs / 1000);
    const seconds = rest % 60;
    rest = Math.trunc(rest / 60);
    const minutes = rest % 60;
    rest = Math.trunc(rest / 60);
    const hours = rest % 24;
    const days = Math.trunc(rest / 24);
    return {
        days, hours, minutes, seconds, milliseconds
    };
};

export const durationInMillis = (duration: Object) => {
    const {
        days = 0, hours = 0, minutes = 0, seconds = 0, milliseconds = 0
    } = duration || {};
    const second = 1000;
    const minute = 60 * second;
    const hour = 60 * minute;
    const day = 24 * hour;

    return days * day
        + hours * hour
        + minutes * minute
        + seconds * second
        + milliseconds;
};

export const calculateDuration = (startDate, endDate, period) => {
    const start = moment(startDate);
    const end = moment(endDate);
    const duration = moment.duration(end.diff(start));
    switch (period) {
        case 'milliseconds':
            return duration.asMilliseconds();
        case 'seconds':
            return duration.asSeconds();
        case 'minutes':
            return duration.asMinutes();
        case 'hours':
            return duration.asHours();
        case 'days':
            return duration.asDays();
        case 'weeks':
            return duration.asWeeks();
        case 'months':
            return duration.asMonths();
        case 'years':
            return duration.asYears();
        default:
            // eslint-disable-next-line no-console
            console.error(`Invalid period "${period}" given to calculateDuration function. Expected values are "milliseconds", "seconds", "minutes", "hours", "days", "weeks", "months", "years"`);
    }
};

// This function expects the input in minutes
// and return the exact time in those minutes
export const calculateTime = (minutes) => {
    // minutes in --> year, month, day, hour, minute
    const periods = [525600, 43800, 1440, 60, 1];
    const periodLabels = ['year', 'month', 'day', 'hour', 'minute'];
    let time = '';
    periods.forEach((period, i) => {
        const val = Math.floor(minutes / period);
        if (val) {
            minutes = minutes - period * val;
            time = `${time} ${val} ${periodLabels[i]}${val > 1 ? 's' : ''},`;
        }
    });
    time = time.substring(0, time.length - 1); // Remove the ending comma ','
    return time.trim(); // remove the initial spaces
};

// This function will take the input period and will return the starting and ending time range of that period
// It will return the array of range where the first value of the array will be starting value of the period
// and the second value of the array will be the ending value of the period
export const getDateRanges = (period: string) => {
    const today = moment();
    let startDate, endDate;
    switch (period){
        case 'days':
            startDate = today.clone().startOf('date');
            endDate = startDate.clone().add(1, 'd').subtract(1, 's');
            break;
        case 'weeks':
            startDate = today.clone().startOf('isoWeek');
            endDate = today.clone().endOf('isoWeek');
            break;
        case 'months':
            startDate = today.clone().startOf('month');
            endDate = today.clone().endOf('month');
            break;
        case 'years':
            startDate = today.clone().startOf('year');
            endDate = today.clone().endOf('year');
            break;
        default:
            startDate = today.clone().startOf('isoWeek');
            endDate = today.clone().endOf('isoWeek');
            // eslint-disable-next-line no-console
            console.error(`Invalid period "${period}" given to getDateRanges function. Expected values are "days", "weeks", "months", "years". Default week range will be returned`);
            break;
    }
    return [ startDate, endDate ];
};

/**
 * Get the UTC string
 */
export const getUTCString = () => {
    const today = moment();
    const utcOffset = today.parseZone().format('Z');
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return `UTC ${utcOffset} (${timezone})`;
};

/**
 *  Compare if two dates is on the same day
 */
export const isSameDay = (date1: ?Date, date2: ?Date) => {
    return moment(date1).format('L') === moment(date2).format('L');
};

/**
 * Convert millisecond value to user friendly text
 */
export const parseDurationText = (value, { hideDays, hideHours, hideMinutes, hideSeconds, hideMilliseconds }) => {
    const parseValue = Number.isInteger(value) ? value : 0;
    const { days, hours, minutes, seconds, milliseconds } = parseDuration(parseValue);
    const valueArr = [];

    if (!hideDays) valueArr.push(`days: ${days}`);
    if (!hideHours) valueArr.push(`hours: ${hours}`);
    if (!hideMinutes) valueArr.push(`minutes: ${minutes}`);
    if (!hideSeconds) valueArr.push(`seconds: ${seconds}`);
    if (!hideMilliseconds) valueArr.push(`milliseconds: ${milliseconds}`);

    return valueArr.join(', ');
};

/**
 * Parse time string value HH:mm i.e (10:00) to parseable date value
 */
export const parseTimeToDate = (time) => {
    const timeArr = time && time.split ? time.split(':') : null;
    return timeArr ? setHours(new Date(), timeArr[0], timeArr[1], 0, 0) : null;
};
