/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import greenlet from 'greenlet';
import { TextField, IconButton, MdiIcon, Typography } from '@mic3/platform-ui';

import CollapsibleText from 'app/components/molecules/CollapsibleText/CollapsibleText';
import CodeEditor from 'app/components/organisms/TextEditor/CodeEditor';
import ComponentStyleWrapper from 'app/containers/Designer/Form/components/ComponentStyleWrapper';
import { materialInput } from 'app/utils/material/material';
import { bind, memoize, debounce } from 'app/utils/decorators/decoratorUtils';
import { get } from 'app/utils/lo/lo';
import { deepEquals } from 'app/utils/utils';

require('codemirror/mode/javascript/javascript');
require('codemirror/mode/htmlmixed/htmlmixed');

const MaterialCodeEditor = materialInput(CodeEditor);

const runGreenlet = greenlet((value, fullJs) => {
    try {
        const jsFunction = eval(value); // eslint-disable-line no-eval
        if (typeof jsFunction !== 'function' && !fullJs) {
            return { isValid: false, errorMessage: 'You must write a valid javascript function.' };
        }
        return { isValid: true, errorMessage: null };

    } catch (e) {
        return { isValid: false, errorMessage: e && e.message };
    }
});

export default class Textarea extends PureComponent<Object, Object> {

    static defaultProps = {
        parseAs: 'text',
    }

    static propTypes = {
        parseAs: PropTypes.oneOf(['text', 'JSON', 'javascript', 'HTML', 'sql']),
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        onValid: PropTypes.func,
    };

    state: Object;
    inputRef: Object = React.createRef();

    constructor(props: Object) {
        super(props);
        const { value, parseAs } = props;
        this.state = {
            tic: 0,
            value,
            ...this.getTextValue(value, parseAs),
        };
    }

    componentDidUpdate(prevProps: Object) {
        const { value, parseAs } = this.props;
        if (prevProps.value !== value) {
            if (parseAs !== 'JSON') {
                this.setState({
                    value,
                    ...this.getTextValue(value, parseAs),
                });
            } else {
                let json = null;
                let error = false;
                try {
                    json = JSON.parse(this.state.textValue);
                } catch (e) {
                    error = true;
                }
                
                if (error || !deepEquals(json, value)) {
                    this.setState({
                        value,
                        ...this.getTextValue(value, parseAs),
                    });
                }
            }
        }
    }

    @bind
    @memoize()
    getTextValue(value, parseAs) {
        switch (parseAs) {
            case 'text': case 'javascript': case 'sql': return { textValue: value };
            case 'JSON': return { textValue: value && typeof value === 'object' ? JSON.stringify(value, null, 2) : null };
            case 'HTML': return !value ? { textValue: '' } : { textValue: value.innerHTML ?? value };
            default: throw new Error(`Unkown parseAs ${parseAs}`);
        }
    }

    _validateJs = async (value, fullJs) => {
        return await runGreenlet(value, fullJs);
    };

    @bind
    @memoize()
    async parse (value, parseAs, fullJs, noValidation) {
        if (!value) {
            return { value: null,  errorMessage: null };
        }
        try {
            switch (parseAs) {
                case 'text': case 'sql': return { value, errorMessage: null };
                case 'JSON': {
                    const json = value && JSON.parse(value);
                    if (typeof json !== 'object') {
                        throw new Error('The value is not a valid JSON.');
                    }
                    return { value: json, errorMessage: null };
                }
                case 'HTML': {
                    if (!value) {
                        return null;
                    }
                    const div = document.createElement('div');
                    div.innerHTML = value;
                    return { value: div, errorMessage: null };
                }
                case 'javascript': {
                    if (noValidation) {
                        return { value, errorMessage: null };
                    }
                    const response = await this._validateJs(value, fullJs);
                    if (response.isValid) {
                        return { value, errorMessage: null };
                    }
                    return { value: null, errorMessage: response.errorMessage };
                }
                default:
            }
        } catch (e) {
            return { value: null, errorMessage: e && e.message };
        }
    };

    @bind
    @debounce()
    debounceChange(data: Object) {
        this.onChange(data);
    }

    @bind
    onChange(data: Object) {
        const { parseAs, onValid, fullJs, noValidation } = this.props;
        const { name, value } = data;

        if (this.state.textValue === value || (!this.state.textValue && !value)) {
            return;
        }
        this.setState({ textValue: value }, () => {
            this.parse(value, parseAs, fullJs, noValidation).then((response) => {
                this.setState({ ...response }, () => {
                    if (onValid) {
                        onValid(!this.state.errorMessage);
                    }
                    if (!this.state.errorMessage) {
                        this.props.onChange && this.props.onChange({ target: { name, value: this.state.value }});
                    }
                });
            });
        });
    }

    @bind
    onSave() {
        const { name, parseAs, fullJs, noValidation } = this.props;
        this.parse(this.state.textValue, parseAs, fullJs, noValidation).then((response) => {
            this.setState({ ...response }, () => {
                if (!this.state.errorMessage) {
                    const { value } = this.state;
                    this.props.onChange && this.props.onChange({ target: { 
                        name, 
                        value: parseAs === 'HTML' ? value && value.innerHTML : this.state.value 
                    }});
                }
            });
        });
    }

    @bind
    onPrettify() {
        const { parseAs, fullJs, noValidation } = this.props;
        const { value } = this.state;
        this.parse(this.state.textValue, parseAs, fullJs, noValidation).then((response) => {
            this.setState({ ...response }, () => {
                if (!this.state.errorMessage) {
                    this.setState({
                        value,
                        ...this.getTextValue(value, parseAs),
                    });
                }
            });
        });
    }

    @bind
    @memoize()
    getHtmlProps(disabled, parseAs, InputProps, errorMessage) {
        const cleareIcon = get(this.inputRef, 'current.endAdornment', null);
        return parseAs === 'HTML' ? {
            ref: this.inputRef,
            InputProps: {
                startAdornment: !disabled && [
                    <IconButton
                        key={0}
                        aria-label="Save html"
                        onClick={this.onSave}
                    >
                        <MdiIcon name="content-save" />
                    </IconButton>,
                    cleareIcon
                ],
            }
        } : {};
    }

    @bind
    @memoize()
    getJSONProps(disabled, parseAs, InputProps, errorMessage) {
        const cleareIcon = get(this.inputRef, 'current.endAdornment', null);
        return parseAs === 'JSON' ? {
            ref: this.inputRef,
            InputProps: {
                startAdornment: !disabled && [
                    <IconButton
                        key={0}
                        aria-label="Prettify"
                        onClick={this.onPrettify}
                    >
                        <MdiIcon name="format-indent-increase" />
                    </IconButton>,
                    cleareIcon
                ],
            }
        } : {};
    }

    @bind
    @memoize()
    getsqlProps(disabled, parseAs, InputProps, errorMessage) {
        const cleareIcon = get(this.inputRef, 'current.endAdornment', null);
        return parseAs === 'sql' ? {
            ref: this.inputRef,
            InputProps: {
                startAdornment: !disabled && [
                    <IconButton
                        key={0}
                        aria-label="Prettify"
                        onClick={this.onSave}
                    >
                        <MdiIcon name="format-indent-increase" />
                    </IconButton>,
                    cleareIcon
                ],
            }
        } : {};
    }

    @bind
    onChangeHandler(event){
        const { parseAs, modal } = this.props;
        const { value, name } = event.target;
        if (modal) {
            this.debounceChange({ value, name });
        } else if(['HTML'].includes(parseAs)) {
            this.setState({ textValue: value });
        } else {
            this.onChange({ value, name });
        }
    }
    render() {
        const {
            onChange, value, helperText, parseAs, disabled, InputProps, onValid,
            error, useCodeEditor, mode, lineNumbers, ...restProps
        } = this.props;
        const { errorMessage, textValue } = this.state;
        if (useCodeEditor) {
            return (
                <>
                    <MaterialCodeEditor
                        component={CodeEditor}
                        onBlur={this.onPrettify}
                        {...this.props}
                        rows={this.props.rows}
                        value={textValue}
                        onChange={this.onChangeHandler}
                        options={{
                            mode: mode || {
                                JSON: 'javascript',
                                javascript: 'javascript',
                                HTML: 'htmlmixed',
                                sql: 'sql',
                            }[parseAs],
                            lineNumbers: lineNumbers === false ? false : true,
                            readOnly: disabled,
                        }}
                        error={errorMessage}
                        boxProps={{ height: 'calc(100% - 100px)'}}
                    />
                </>
            );
        }
        const htmlTypeProps = this.getHtmlProps(disabled, parseAs, InputProps, errorMessage);
        const jsonTypeProps = this.getJSONProps(disabled, parseAs, InputProps, errorMessage);
        const sqlTypeProps = this.getsqlProps(disabled, parseAs, InputProps, errorMessage);
        return (
            <ComponentStyleWrapper
                rows="5"
                {...restProps}
                {...htmlTypeProps}
                {...jsonTypeProps}
                {...sqlTypeProps}
                multiline={true}
                disabled={disabled}
                value={textValue}
                onChange={this.onChangeHandler}
                error={!!(errorMessage || error)}
                helperText={errorMessage ? `${parseAs} is not valid: ${errorMessage}` : helperText}
                view={(
                    <>
                        <Typography variant="button">{this.props.label}</Typography>
                        <CollapsibleText text={String(textValue || '')}  />
                    </>
                )}
                Component={TextField}
            />
        );
    }
}
