import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Typography, IconButton, Tooltip } from '@mic3/platform-ui';

import Icon from 'app/components/atoms/Icon/Icon';
import { bind } from 'app/utils/decorators/decoratorUtils';
import { isDefined } from 'app/utils/utils';
import history from 'store/History';
import { getEntityUrl } from 'app/utils/entity/entityUtils';

const DarkLabel = styled(Typography)`
    color: ${({ theme }) => theme.material.colors.text.secondary} !important;
    font-size: 14px !important;
`;

const StyledIcon = styled(Icon)`
    padding-right: 5px !important;
`;

class GridEntityRelations extends PureComponent<Object, Object> {
  
    @bind
    openRelations(e, data){
        e.preventDefault();
        e.stopPropagation();
        const {id, type}= data;
        history.push(`${getEntityUrl(id, type)}/relationships`);
    }

    render() {
        
        const { data } = this.props;
        const { relations } = data || {};
        const counter = relations ? relations?.length : null;
        return (     
            (isDefined(data.relations ) && counter !== 0 ) ?
                <Tooltip title='Open relations'>
                    <IconButton onClick={(e)=> this.openRelations(e, data)} >
                        <StyledIcon name= 'relationships' type='af' />
                        <DarkLabel>  
                            { counter }  
                        </DarkLabel>
                    </IconButton>
                </Tooltip>
                : null
        );
    };
}
export default (GridEntityRelations);