/* @flow */

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { TextField, Divider } from '@mic3/platform-ui';
import { isEmpty, getStr } from 'app/utils/utils';
import Immutable from 'app/utils/immutable/Immutable';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import SelectionList from 'app/containers/Common/SelectionList/SelectionList';
import Loader from 'app/components/atoms/Loader/Loader';
import { setActions } from 'store/actions/sidebar/sidebarActions';
import { loadClassChildrens, updateClassification, updateClassChildrens } from 'store/actions/classifications/classificationsActions';
import AddClassificationModal from 'app/containers/Common/ClassificationsTab/AddClassificationModal';
import { arrayObjectEquals } from 'app/utils/utils';
import { updateEntity } from 'store/actions/entities/entitiesActions';
import { loadAllPrimaryClasses } from 'store/actions/app/appActions';


const SearchField = styled(TextField)`
    padding: 8px 16px !important;
`;

const HeaderBar = styled.div`
    display: flex;
`;

/**
 * Renders the list of parents or children or applies to records.
 */
class ParentsChildrenAppliesToSidebar extends PureComponent<Object, Object> {
    constructor(props) {
        super(props);
        this.state = Immutable({ serachValue: '', isModalOpen: false });
        this.defaultState = { ...this.state};
        this.loadChildClasses();
        this.loadAllPrimaryClasses();
    }

    componentDidUpdate(prevProps) {
        const { id } = this.props.details || {};
        if (prevProps.details.id !== id) {
            this.loadChildClasses();
        }
    }

    @bind
    loadAllPrimaryClasses(){
        const { type } = this.props.details || {};
        if (type === 'print-template') {
            this.props.loadAllPrimaryClasses();
        }
    }

    @bind
    loadChildClasses() {
        const { title } = this.props;
        const { id } = this.props.details || {};
        if (id && title === 'Children' ) {
            this.props.loadClassChildrens(id);
        }
    }

    @bind
    @memoize()
    getRecords(title, details, children, primaryClasses) {
        if (!title || !details) {
            return [];
        }
        const { parents, applicableOn, type, primary } = details;
        switch (title) {
            case 'Parents':
                return parents || [];
            case 'Children':
                return children;
            case 'Applies To': 
                if (type === 'print-template') {
                    return primary?.['print-template/applicable_on']?.map(classId => primaryClasses?.find(cls => cls?.id === classId)).filter(Boolean);
                }
                return (applicableOn || []).map(uri => ({ name: uri, uri, id: uri }));
            default:
                return [];
        }
    }

    @bind
    setDefaultState() {
        this.setState(this.defaultState);
    }

    @bind
    onSearch(event) {
        this.setState({
            searchValue: event.target.value,
        });
    }

    @bind
    searchForFrom(searchQuery, data) {
        if (!data || !searchQuery) return false;
        return ['name', 'uri', 'id'].some((field) => {
            let value = getStr(data, field, '');
            if (!value) return false;
            value = value.toLowerCase();
            const query = searchQuery.toLowerCase();
            return value.includes(query);
        });
    }

    @bind
    filterSearchedData(searchQuery: string, records) {
        return searchQuery ? (records || []).filter(item => this.searchForFrom(searchQuery, item)) : records || [];
    }

    @bind
    removeRecords(selectedList) {
        const { title, details, children, primaryClasses } = this.props;
        if (isEmpty(selectedList)) {
            return Promise.resolve();
        }
        const records = this.getRecords(title, details, children, primaryClasses);
        const filteredRecords = records.filter(item => !selectedList.includes(item?.id));
        return this.onSave(filteredRecords);
    }

    @bind
    async onSave(list) {
        const { title, details, children: prevChildrens, internal, updateClassChildrens, updateEntity, refreshMainSection } = this.props;
        const { id, type } = details || {};
        if (!id) return;
        
        if (title === 'Parents') {
            const normalizedList = list?.filter(p => p?.id !== id).filter(Boolean).map(p => p?.id).filter(Boolean);
            const parents = normalizedList?.length ? normalizedList : null;
            return await this.props.updateClassification({ id, parents });
        }
        if (title === 'Applies To') {
            const primary = { 'print-template/applicable_on': list?.map(({ id }) => id).filter(Boolean) };
            if (type === 'print-template') {
                return updateEntity({ id, type, primary}, internal, refreshMainSection);
            }
            const applicableOn = list.map(cl => cl?.uri).filter(Boolean);
            return await this.props.updateClassification({ id, applicableOn });
        }
        if (title === 'Children'){
            if (!arrayObjectEquals(list, prevChildrens)) {
                const addedPromises = list.map(async (ch) => {
                    if (!prevChildrens.find(c => c?.id === ch?.id)) {
                        await updateClassChildrens({ id: ch?.id, parents: [...(ch.parents || []), details].map(c => c.id).filter(Boolean) });
                    }
                }, []);
                const missed = prevChildrens.filter(ch => !list.find(c => ch?.id === c?.id));
                const missedPromises = missed.map(async (ch) => {
                    await updateClassChildrens({ id: ch?.id, parents: (ch?.parents || []).filter(c => c?.id !== details?.id).map(c => c?.id).filter(Boolean) });
                });
                await Promise.all(addedPromises);
                await Promise.all(missedPromises);
                await this.props.loadClassChildrens(id);
            }
        }
    }

    @bind
    @memoize()
    getParentsExclude(details, childrens) {
        return [details, ...(childrens || [])].map(ch => ch.id);
    }

    @bind
    @memoize()
    getChildrensExclude(details) {
        return [details, ...(details.parents || [])].map(ch => ch.id);
    }

    @bind
    @memoize()
    getPrintAppliesToExcluded(details) {
        return [...(details?.primary['print-template/applicable_on'] || [])]?.map(id => id);
    }

    @bind
    toggleAddModal(){
        this.setState(prevState => ({ isModalOpen: !prevState.isModalOpen }));
    }

    @bind
    renderAddModal(addedRecords){
        const { title, details, children } = this.props;
        const { isModalOpen } = this.state;
        if (!isModalOpen) return;
        const filterBy = [{ field: 'primary', op: '=', value: !!details?.primary}];
        
        const props = { entityType: 'class', isOpen: isModalOpen, onSubmit: this.onSave, closeModal: this.toggleAddModal, filterBy, exclude: true };
        const appliesToExtraRecords = [{ uri: 'workspace', name: 'Workspaces' }, { uri: 'relationdefinition', name: 'Relation Definitions' }].filter(({ uri }) => !(details?.applicableOn || [])?.includes(uri));
        switch (title) {
            case 'Parents':
                return (
                    <AddClassificationModal
                        title='Select Parents'
                        selectedClasses={[...(details.parents || []), details]}
                        excluded={this.getParentsExclude(details, children)}
                        {...props}
                    />
                );
            case 'Children':
                return (
                    <AddClassificationModal
                        title='Children'
                        selectedClasses={children}
                        selectedClassesPath='uri'
                        excluded={this.getChildrensExclude(details)}
                        withParents
                        {...props}
                    />
                );
            case 'Applies To': 
                if (details?.type === 'print-template') {
                    return (
                        <AddClassificationModal
                            title='Applies to'
                            selectedClasses={addedRecords}
                            excluded={this.getPrintAppliesToExcluded(details)}
                            {...props}
                            entityType='entityType'
                        />
                    );
                }
                return (
                    <AddClassificationModal
                        title='Applies to'
                        selectedClasses={[...(details.applicableOn || [])].map(uri => ({ uri }))}
                        selectedClassesPath='uri'
                        excluded={[details.uri, ...(details.applicableOn || [])]}
                        excludedField='uri'
                        extraRecords={appliesToExtraRecords}
                        {...props}
                        filterBy={[{ field: 'primary', op: '=', value: true }]}
                        entityType='entityType'
                        filterAbstract
                    />
                );
            default:
                return null;
        }
    }

    render() {
        const { isLoading, title, details, children, primaryClasses } = this.props;
        const records = this.getRecords(title, details, children, primaryClasses);
        const { searchValue } = this.state;
        const filteredList = this.filterSearchedData(searchValue, records);
        return (
            <>
                {isLoading && <Loader absolute backdrop />}
                <Divider />
                <HeaderBar>
                    <SearchField
                        fullWidth
                        variant='standard'
                        margin='none'
                        placeholder='Search...'
                        InputProps={{ disableUnderline: true }}
                        value={searchValue}
                        onChange={this.onSearch}
                    />
                </HeaderBar>
                <Divider />
                <SelectionList
                    filteredList={filteredList}
                    list={filteredList}
                    type='class'
                    title={title}
                    onAddClick={this.toggleAddModal}
                    filterBy={[]}
                    onRemove={this.removeRecords}
                    maxHeight={700}
                    expanded
                />
                {this.renderAddModal(records)}
            </>
        );
    }
}

export default connect(
    (state, ownProps) => ({
        profile: state.user.profile,
        title: state.sidebar.title,
        children: state.classifications.childrens.records,
        isLoading: ownProps.isLoading || state.classifications.childrens.isLoading || state.app.allPrimaryClasses.isLoading,
        primaryClasses : state.app.allPrimaryClasses.records
    }),
    {
        setActions,
        loadClassChildrens,
        updateClassification,
        updateClassChildrens,
        loadAllPrimaryClasses,
        updateEntity
    }
)(ParentsChildrenAppliesToSidebar);
