/* @flow */

import { gql } from '@apollo/client';

export default gql`
    mutation createOrganisationMutation($record: OrganisationCreateInput!) {
        result: createOrganisation(record: $record) {
            id
        }
    }
`;
