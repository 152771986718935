import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

/**
 * Dark Theme Configuration
 * https://www.figma.com/file/ZHUaPNUGEl2KB3h06xLRuljI/Affectli-Palette?node-id=618%3A52811
 */




const darkBaseColors = {
    white: '#FFFFFF',
    link: '#7391D0',
    border: {
        main: 'rgba(255, 255, 255, 0.24)'
    },
    backdrop: {
        background: 'rgba(0,0,0,0.32)',
    },
    background: {
        paper: '#1c263b',
        default: '#101A2E', //+
        navigation: '#263247', //+
        side: '#222C43', //+
        dialog: '#303C56',
        menu: '#28334B', //+
        fields: 'rgba(255, 255, 255, 0.08)', //+
        darkButton: 'rgba(255, 255, 255, 0.12)',
        listItemSelected: '#0051FF32',
        divider: 'rgba(255, 255, 255, 0.24)', //+
        scrollbar: 'rgba(255, 255, 255, 0.7)', //+
        hover: 'rgba(0, 81, 255, 0.08)', //+
        chips: 'rgba(255, 255, 255, 0.06)', //+
        progress: '#7B9EEA', //+
        progressLine:'#334365', //+
        message: 'rgba(255, 255, 255, 0.06)', //+
        active: 'rgba(0, 81, 255, 0.32)',//+
        disableButton: 'rgba(255, 255, 255, 0.08)',//+
        activeElement: '#1248BD', //+
        tooltipBorder: '#fff', //+
    },
    primary: {
        main: '#3D64B7'
    },
    secondary: {
        main: '#7B9EEA' //+
    },
    error: {
        main: '#C22525'
    },
    warning: {
        main: '#E65100'
    },
    info: {
        main: '#88B342'
    },
    success: {
        main: '#12A98B'
    },
    text: {
        primary: '#fff',
        label: 'rgba(255, 255, 255, 0.5)',
        secondary: 'rgba(255, 255, 255, 0.7)',//+
        error: '#EB2330',
        caption: 'rgba(255, 255, 255, 0.38)', //+
        darkChip: '#fff',
        button: '#fff',
        active: '#fff',//+
        popup: '#0D184A',
        buttonActive: '#7B9EEA',
    },
    disabled: {
        main: '#808080',
        background: '#DADADA',
        color: 'rgba(255, 255, 255, 0.38)', //+
        backgroundColor: '#606E8B',
        closed: '#5F78A8'
    },
    gradients: {
        main: 'linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2))',
        colorfull: `linear-gradient(292.49deg, #6F2C91 21.45%, #58449B 38.04%, 
        #3F52A4 53.55%, #3357A7 63.07%, #2D5BAA 67%, #0C67B2 73.39%, #0079C0 81.46%,
         #0092D6 90.8%, #00AEEF 100.07%);`, //+
        headerIcons: 'linear-gradient(284.04deg, #6F2C91 0%, #1248BD 57.16%, #00AEEF 100%)',
    },
    fields: {
        border: 'rgba(115, 145, 208, 1)',
    },
    danger: '#FF5722',
    alert: '#FFA100',

    appNav: {
        linkActiveBgColor: 'rgba(0, 81, 255, 0.32)',
        linkActiveTextColor: '#fff',
        linkIconColor: 'rgba(255, 255, 255, 0.7)',
        linkActiveIconColor: '#fff'
    },
    // App Header
    appHeader: {
        iconColor: 'rgba(255, 255, 255, 0.7)',
        searchBgColor: 'rgba(255, 255, 255, 0.06)',
        borderAvatar: 'rgba(255, 255, 255, 0.24)'
    },
    appNavFooter:{
        logo: './images/logo-full.png' //+

    },
    leftSideBar:{
        itemActive: 'rgba(0, 81, 255, 0.32)',
        iconActive: '#396EDF'
    },
    itemActive: '#18284B', //+
    itemHover: 'rgba(255, 255, 255, 0.04)',
    formBuilder: {
        wrapperBorderColor: 'rgba(61, 100, 183, 1)',
        selectedField: 'rgba(0, 81, 255, 0.2)',
    },
    bryntum: {
        grid: {
            hoverHeader: 'rgba(0, 81, 255, 0.08)',
            bottom: 'rgba(255, 255, 255, 0.24)',
            hoverLine: 'rgba(0, 81, 255, 0.08)',
            titleTextAct: '#fff',
        }
    }
};

const lightBaseColors = {
    white: '#fff', // +
    link: '#1248BD',
    border: {
        main: 'rgba(13, 24, 74, 0.11)' // +
    },
    backdrop: {
        background: 'rgba(255,255,255,0.64)',
    },
    background: {
        paper: '#fff', //+
        default: '#F8FAFD', //+
        navigation: '#fff', //+
        side: '#fff', //+
        dialog: '#F8FAFD',//+
        menu: '#fff',//+
        fields: 'rgba(18, 72, 189, 0.05)', //+
        darkButton: 'rgba(255, 255, 255, 0.12)',
        listItemSelected: '#0051FF32',
        divider: 'rgba(13, 24, 74, 0.11)',//+
        scrollbar: 'rgba(13, 24, 74, 0.69)', //+
        hover: 'rgba(0, 81, 255, 0.04)', //+
        chips: 'rgba(18, 72, 189, 0.05)', //+
        progress: '#1248BD', //+
        progressLine: 'rgba(0, 81, 255, 0.16)', //+
        message: 'rgba(18, 72, 189, 0.05)', //+
        active: 'rgba(0, 81, 255, 0.16)',//+
        disableButton: 'rgba(13, 24, 74, 0.08)',//+
        activeElement: '#1248BD', //+
        tooltipBorder: '#1248BD', //+
    },
    primary: {
        main: '#1248BD' //+
    },
    secondary: {
        main: '#1248BD' //+
    },
    error: {
        main: '#C22525'
    },
    warning: {
        main: '#E65100'
    },
    info: {
        main: '#88B342'
    },
    success: {
        main: '#12A98B'
    },
    text: {
        primary: '#0D184A', //+
        label: 'rgba(13, 24, 74, 0.69)',
        secondary: 'rgba(13, 24, 74, 0.69)',//+
        error: '#EB2330',
        caption: 'rgba(13, 24, 74, 0.44)', //+
        darkChip: '#fff',
        button: '#fff',
        active: '#1248BD',//+
        popup: '#0D184A',
        buttonActive: '#7B9EEA',
    },
    disabled: {
        main: '#808080',
        background: '#DADADA',
        color: 'rgba(13, 24, 74, 0.44)', //+
        backgroundColor: '#606E8B',
        closed: '#5F78A8'
    },
    gradients: {
        main: 'linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2))',
        colorfull: `linear-gradient(292.49deg, #6F2C91 21.45%, #58449B 38.04%, #3F52A4 53.55%,
         #3357A7 63.07%, #2D5BAA 67%, #0C67B2 73.39%, #0079C0 81.46%,
         #0092D6 90.8%, #00AEEF 100.07%);`, //+
        headerIcons: 'linear-gradient(284.04deg, #6F2C91 0%, #1248BD 57.16%, #00AEEF 100%)',
      
    },
    fields: {
        border: 'rgba(115, 145, 208, 1)',
    },
    danger: '#FF5722',
    alert: '#FFA100',

    appNav: {
        linkActiveBgColor: 'rgba(0, 81, 255, 0.16)', //+
        linkActiveTextColor: '#1248BD', //+
        linkIconColor: 'rgba(13, 24, 74, 0.69)', //+
        linkActiveIconColor: '#1248BD' //+
    },
    appHeader: {
        iconColor: 'rgba(13, 24, 74, 0.69)',
        searchBgColor: 'rgba(18, 72, 189, 0.05)',
        borderAvatar: 'rgba(13, 24, 74, 0.11)',
    },
    leftSideBar:{
        itemActive: 'rgba(0, 81, 255, 0.16)',
        iconActive: '#1248BD'
    },
    itemActive: 'rgba(0, 81, 255, 0.16)', //+
    itemHover: 'rgba(0, 81, 255, 0.04)', //+ history card BG
    formBuilder: {
        wrapperBorderColor: 'rgba(61, 100, 183, 1)',
        selectedField: 'rgba(0, 81, 255, 0.16)',
    },
    bryntum: {
        grid: {
            hoverHeader: 'rgba(0, 81, 255, 0.04)',//+
            bottom: 'rgba(13, 24, 74, 0.11)',
            hoverLine: 'rgba(0, 81, 255, 0.04)',
            titleTextAct: '#1248BD',
        }
    }
};

const darkMuiButton = {
    secondary: 'rgba(0, 0, 0, 0.6)',
    background: 'rgba(255, 255, 255, 0.24)',
    color: darkBaseColors.disabled.color
};

const lightMuiButton = {
    secondary: '#1D2965',
    background: 'rgba(13, 24, 74, 0.08)',
    color: lightBaseColors.disabled.color
};

const priorityGradients = {
    danger: ['#C22525', '#DF4807'] ,
    warning: ['#E65100', '#F27000'],
    alert: ['#FFA100', '#FEB300'],
    info: ['#88B342', '#88B342'],
    success: ['#1FA3B3', '#12A98B'],
    disabled: ['#808080', '#80808077'],
};

const darkPriorityTaskColors = {
    1: ['#FF8A91', '#EB2330'] ,
    2: ['#F98F61', '#FF7322'],
    3: ['#FFBD4C', '#FFB300'],
    4: ['#A5D869', '#8BC34A'],
    5: ['#4CDCCA', '#4DB6AC'],
};
const lightPriorityTaskColors = {
    1: ['#C6000D', '#EB2330'] ,
    2: ['#CC3600', '#FF7322'],
    3: ['#B24E00', '#FFB300'],
    4: ['#33691E', '#8BC34A'],
    5: ['#00796B', '#4DB6AC'],
};

const priorityColors = {
    danger: darkBaseColors.error.main,
    warning: darkBaseColors.warning.main,
    info: darkBaseColors.error.info,
    alert: darkBaseColors.alert,
    success: darkBaseColors.success.main,
    disabled: darkBaseColors.disabled.main,
};

const darkTheme = responsiveFontSizes(
    createMuiTheme({
        colors: {
            ...darkBaseColors,
            priority: priorityColors,
            priorityGradients,
            priorityTaskColors: darkPriorityTaskColors
        },
        spacing: factor => `${0.25 * factor}rem`,
        typography: { useNextVariants: true },
        palette: {
            type: 'dark',
            background: darkBaseColors.background,
            primary: darkBaseColors.primary,
            secondary: darkBaseColors.secondary,
            error: darkBaseColors.error,
            warning: darkBaseColors.warning,
            info: darkBaseColors.info,
            success: darkBaseColors.success
        },
        logo:{
            appNavFooterLogo: './images/logo-full.png',
            termsLogo: './images/aff-logo-full.png'
        },
        overrides: {
            MuiListItemText: {
                primary: {
                    color: darkBaseColors.text.primary,   
                },
                secondary: {
                    color: darkBaseColors.text.secondary,
                },
            },
            MuiListItemIcon: {
                root: {
                    paddingRight:'10px',
                    minWidth:'0'
                }
            },
            MuiLink: {
                root: {
                    color: darkBaseColors.link,
                }
            },
            MuiButton: {
                root: {
                    fontSize: 14,
                },
                containedPrimary: {
                    color: darkBaseColors.white,
                },
                containedSecondary: {
                    color: darkMuiButton.secondary,
                },
                contained: {
                    '&$disabled': {
                        backgroundColor: darkMuiButton.background,
                        color: darkMuiButton.color,
                    },
                    '&.error': {
                        backgroundColor: darkBaseColors.error.main,
                        color: darkBaseColors.white,
                    },                    
                },
                outlined: {
                    '&$disabled': {
                        border: `1px solid ${darkMuiButton.background}`,
                        color: darkMuiButton.color,
                    },
                },
                outlinedPrimary: {
                    color: darkBaseColors.secondary.main
                } ,
                textPrimary: {
                    color: darkBaseColors.secondary.main,
                },
                text: {
                    color: darkBaseColors.secondary.main,
                    '&.error': {
                        color: darkBaseColors.text.error,
                    },                    
                },
            },
            MuiIcon: {
                root: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                },
            },
            MuiFilledInput: {
                root: {
                    color: darkBaseColors.text.primary,
                    backgroundColor: darkBaseColors.background.fields,
                    '&$focused': {
                        backgroundColor: darkBaseColors.background.fields,
                    },
                    '&:hover': {
                        backgroundColor: darkBaseColors.background.fields,
                    },
                    '&$disabled': {
                        backgroundColor: 'transparent',
                    },
                },
                underline: {
                    '&$disabled:before': {
                        borderBottom: 0,
                    },
                },
            },
            MuiRadio: {
                root: {
                    '&:hover': {
                        backgroundColor: `${darkBaseColors.background.hover} !important`, // Стили при hover
                    },
                    color: `${darkBaseColors.text.primary} !important`,
                    '&$checked': {
                        color: `${darkBaseColors.secondary.main} !important`,
                    },
                    '&$disabled': {
                        color:`${darkBaseColors.disabled.color} !important`,
                    },
                    '& .PrivateRadioButtonIcon-root-386': {
                        backgroundColor: 'transparent !important',
                    },
                },
                colorPrimary: {
                    '&$disabled': {
                        color: darkBaseColors.disabled.color,
                    },
                },
                colorSecondary: {
                    '&$disabled': {
                        color: darkBaseColors.disabled.color,
                    },
                },
            },
            MuiCheckbox: {
                colorSecondary: {
                    '&$checked': {
                        color: darkBaseColors.primary.main,
                    },
                    '&$disabled': {
                        color: darkBaseColors.disabled.background,
                    },
                },
                colorPrimary: {
                    '&$checked': {
                        color: darkBaseColors.primary.main,
                    },
                    '&$disabled': {
                        color: darkBaseColors.disabled.background,
                    },
                },
            },
            MuiFormLabel: {
                root: {
                    color: darkBaseColors.text.secondary,       
                },
                asterisk: {
                    color: darkBaseColors.error.main,
                },
            },
            MuiChip: {
                root: {
                    maxWidth: '100%',
                    backgroundColor: darkBaseColors.background.chips,
                },
                colorPrimary: {
                    color: darkBaseColors.white,
                    background: darkBaseColors.gradients.main,
                    backgroundColor: darkBaseColors.background.default,
                },
            },
            MuiMenuItem: {
                root: {
                    '&$selected': {
                        backgroundColor: darkBaseColors.background.default,
                        background: darkBaseColors.gradients.main,
                    },
                    '&:hover': {
                        backgroundColor: darkBaseColors.background.hover,
                    },
                },
            },
            MuiDivider: {
                root: {
                    backgroundColor: darkBaseColors.background.divider,
                },
            },
            MuiDialog: {
                paper: {
                    backgroundColor:`${darkBaseColors.background.paper } !important`
                },
            },
            swatchesPicker: {
                backgroundColor: `${darkBaseColors.background.paper } !important`,
            },
            MuiInputAdornment: {
                root: {
                    '& .mdi-close' :{
                        color: darkBaseColors.text.secondary
                    }
                },
            },
            MuiInputBase: {
                root: {
                    '&[class*="Component-customInput"]': {
                        marginBottom: 0, 
                    },
                },
            },
        }
    })
);

const lightTheme = responsiveFontSizes(
    createMuiTheme({
        colors: {
            ...lightBaseColors,
            priority: priorityColors,
            priorityGradients,
            priorityTaskColors: lightPriorityTaskColors
        },
        spacing: factor => `${0.25 * factor}rem`,
        typography: { useNextVariants: true },
        palette: {
            type: 'light',
            background: lightBaseColors.background,
            primary: lightBaseColors.primary,
            secondary: lightBaseColors.secondary,
            error: lightBaseColors.error,
            warning: lightBaseColors.warning,
            info: lightBaseColors.info,
            success: lightBaseColors.success
        },
        
        logo:{
            appNavFooterLogo: './images/affectliLogoLight.png',
            termsLogo: './images/affectli-theme-light-logo-big.png'
        },
        overrides: {
            MuiListItemText: {
                primary: {
                    color: lightBaseColors.text.primary,   
                },
                secondary: {
                    color: lightBaseColors.text.secondary,
                },
            },
            MuiListItemIcon: {
                root: {
                    paddingRight:'10px',
                    minWidth:'0'
                }
            },
            MuiLink: {
                root: {
                    color: lightBaseColors.link,
                }
            },
            MuiButton: {
                root: {
                    fontSize: 14,
                },
                containedPrimary: {
                    color: lightBaseColors.white,
                },
                containedSecondary: {
                    color: lightMuiButton.secondary,
                },
                contained: {
                    '&$disabled': {
                        backgroundColor: lightMuiButton.background,
                        color: lightMuiButton.color,
                    },
                    '&.error': {
                        backgroundColor: lightBaseColors.error.main,
                        color: lightBaseColors.white,
                    },                    
                },
                outlined: {
                    '&$disabled': {
                        border: `1px solid ${lightMuiButton.background}`,
                        color: lightMuiButton.color,
                    },
                },
                outlinedPrimary: {
                    color: lightBaseColors.secondary.main
                } ,
                textPrimary: {
                    color: lightBaseColors.secondary.main,
                },
                text: {
                    color: lightBaseColors.secondary.main,
                    '&.error': {
                        color: lightBaseColors.text.error,
                    },                    
                },
            },
            MuiIcon: {
                root: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                },
            },
            MuiFilledInput: {
                root: {
                    color: lightBaseColors.text.primary,
                    backgroundColor: lightBaseColors.background.fields,
                    '&$focused': {
                        backgroundColor: lightBaseColors.background.fields,
                    },
                    '&:hover': {
                        backgroundColor: lightBaseColors.background.fields,
                    },
                    '&$disabled': {
                        backgroundColor: 'transparent',
                    },
                },
                underline: {
                    '&$disabled:before': {
                        borderBottom: 0,
                    },
                },
            },
            MuiRadio: {
                root: {
                    '&:hover': {
                        backgroundColor: `${lightBaseColors.background.hover} !important`,
                    },
                    color: `${lightBaseColors.text.primary} !important`,
                    '&$checked': {
                        color: `${lightBaseColors.secondary.main} !important`,
                    },
                    '&$disabled': {
                        color:`${lightBaseColors.disabled.color} !important`,
                    },
                    '& .PrivateRadioButtonIcon-root-386': {
                        backgroundColor: 'transparent !important',
                    },
                },
                colorPrimary: {
                    '&$disabled': {
                        color: lightBaseColors.disabled.color,
                    },
                },
                colorSecondary: {
                    '&$disabled': {
                        color: lightBaseColors.disabled.color,
                    },
                },
            },
            MuiCheckbox: {
                colorSecondary: {
                    '&$checked': {
                        color: lightBaseColors.primary.main,
                    },
                    '&$disabled': {
                        color: lightBaseColors.disabled.background,
                    },
                },
                colorPrimary: {
                    '&$checked': {
                        color: lightBaseColors.primary.main,
                    },
                    '&$disabled': {
                        color: lightBaseColors.disabled.background,
                    },
                },
            },
            MuiFormLabel: {
                root: {
                    color: lightBaseColors.text.secondary,   // lable in FilledInput     
                },
                asterisk: {
                    color: lightBaseColors.error.main,
                },
            },
            MuiChip: {
                root: {
                    maxWidth: '100%',
                    // backgroundColor: lightBaseColors.background.chips,
                    // '&:hover':{
                    //     backgroundColor:`${lightBaseColors.background.hover} !important`
                    // }
                },
            },
            MuiMenuItem: {
                root: {
                    '&$selected': {
                        backgroundColor: lightBaseColors.background.default,
                        background: lightBaseColors.gradients.main,
                    },
                    '&:hover': {
                        backgroundColor: lightBaseColors.background.hover,
                    },
                },
            },
            MuiDivider: {
                root: {
                    backgroundColor: lightBaseColors.background.divider,
                },
            },
            MuiDialog: {
                paper: {
                    backgroundColor:`${lightBaseColors.background.paper} !important`,
                },
            },
            swatchesPicker: {
                backgroundColor: `${lightBaseColors.background.paper } !important`,
            },
            MuiTooltip: {
                tooltip: {
                    backgroundColor: lightBaseColors.background.default ,   
                    color: lightBaseColors.background.primary,  
                    border: `1px solid ${lightBaseColors.background.tooltipBorder}`,        
                },
                arrow: {
                    color: lightBaseColors.background.tooltipBorder,
                },
            },
            MuiInputAdornment: {
                root: {
                    '& .mdi-close' :{
                        color: lightBaseColors.text.secondary
                    }
                },
            },
            MuiInputBase: {
                root: {
                    '&[class*="Component-customInput"]': {
                        marginBottom: 0, 
                    },
                },
            },
        }
    })
);


export const muiTheme = {
    darkTheme,
    lightTheme
}[JSON.parse(localStorage.getItem('theme'))|| 'darkTheme' ];