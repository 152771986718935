import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { muiTheme } from 'app/themes/materialUi';
import { IconButton, Menu, Divider, Tooltip, Typography } from '@mic3/platform-ui';

import { useToggle } from 'app/utils/hook/hooks';

import Icon from 'app/components/atoms/Icon/Icon';
import ButtonIcon from 'app/components/molecules/ButtonIcon/ButtonIcon';
import MenuItem from 'app/components/atoms/Designer/MenuItem';
import UploadButton from 'app/components/molecules/UploadButton/UploadButton';

const MenuStyled = styled(Menu)`
    ${({ centered }) => centered && `
        align-items: center;
        justify-content: center;
        display: flex;    
        & .MuiMenu-paper {
            justify-content: center;
            align-items: center;
            display: flex;
            position: initial;
        }
    `}

    ${({ zindex }) => zindex && `z-index: ${zindex} !important;`}
    & .MuiList-root {
        ${({ padded }) => padded ? 'padding: 8px !important' : ''}
    }
    & .MuiMenu-paper {
        ${({ withradio }) => withradio ? 'min-width: 200px; !important;' : ''}
    }

    & .MuiButtonBase-root {
        display: block !important;
    }
    & li {
        padding: 4px;
    }
`;
const HeaderStyled = styled(Typography)`
    padding-left: 12px;
    color: ${({ theme }) => theme.material.colors.text.primary};
`;

const MenuItemStyled = styled(MenuItem)`
    position: relative;
    ${({ withradio }) => withradio ? 'padding-right: 32px !important;' : ''}
    ${({ padded }) => padded ? 'margin-top: 8px !important;margin-bottom: 8px !important' : ''}
`;
const DividerStyled = styled(Divider)`
    ${({ padded }) => padded ? 'margin-top: 8px !important;margin-bottom: 8px !important' : ''}
`;

const RadioIconStyled = styled(Icon)`
    position: absolute;
    right: 0.5rem;
    top: 1px;
`;
const LabelStyled = styled.span`
    padding-left: 10px;
    font-size: 14px;
    position: relative;
    top: -3px;
`;

const DotMenu = ({ onClose, ...props}) => {
    const anchorDotsEl = useRef(null);
    const fileRefs = useRef({});
    const [open, toggle] = useToggle(!!props.open);

    const handleClose = useCallback((e) => {
        e.stopPropagation();
        e.preventDefault();
        if(onClose) onClose();
        else toggle(e);
    }, [onClose, toggle]);

    const getFileRef = useCallback((key)=>{
        if (!fileRefs.current[key]) {
            fileRefs.current[key] = React.createRef();
        }
        return fileRefs.current[key];
    },[fileRefs]);
    return (
        <>
            <Tooltip title={props.tooltipTitle ? props.tooltipTitle : ''}>
                <IconButton key={1} ref={anchorDotsEl} onClick={toggle} edge={props.edge || false}>
                    <Icon color={props.iconColor} name={props.icon ? props.icon : 'dots-vertical'} />
                </IconButton>
            </Tooltip>
            {props.items?.length ? (
                <MenuStyled
                    centered={props.centered?1:0}
                    zindex={props.zIndex}
                    padded={props.padded?1:0}
                    key={2}
                    anchorEl={anchorDotsEl.current}
                    onClose={handleClose}
                    open={open}
                    // withradio={props.withRadio?1:0}
                >
                    {props.items.map((item, i) => {
                        if (item.name === 'header') {
                            return <HeaderStyled key={`${item.name}${i}`} variant="caption">{item.text}</HeaderStyled>;
                        }
                        if (item.name === 'divider') {
                            return <DividerStyled padded={props.padded?1:0} key={`${item.name}${i}`} />;
                        }
                        if (item.name === 'file') {
                            const fileRef = getFileRef(`${item.name}${i}`);
                            return (
                                <MenuItem
                                    withConfirmation={item.withConfirmation}
                                    confirmationModalProps={item.confirmationModalProps}
                                    disabled={item.disabled}
                                    key={`${item.name}${i}`}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        fileRef.current.onClick(e);
                                    }}
                                >
                                    <UploadButton accept={item.accept} ref={fileRef} alt="Upload file" onSelect={(file) => {
                                        !item.component && toggle();
                                        item.onSelect(file);
                                    }} hexColor={muiTheme.colors.text.secondary} />
                                    <LabelStyled>{item.label || 'Upload file'}</LabelStyled>
                                </MenuItem>
                            );
                        }
                        return (
                            <MenuItemStyled
                                withradio={item.withRadio?1:0}
                                withConfirmation={item.withConfirmation}
                                padded={props.padded?1:0}
                                confirmationModalProps={item.confirmationModalProps}
                                disabled={item.disabled}
                                key={`${item.name}${i}`}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    !item.component && toggle();
                                    if (item.onItemClick) {
                                        item.onItemClick({ ...props, name: item.name, fileType: item.fileType });
                                    } else if (props.onItemClick) {
                                        props.onItemClick(item.name);
                                    }
                                }}
                            >
                                <ButtonIcon type={item.iconType} icon={item.icon} iconComponent={item.iconComponent} label={item.name} />
                                {item.component && item.component}
                                {item.withRadio ? <RadioIconStyled name={item.checked ? 'radiobox-marked' : 'radiobox-blank'} /> : null}
                            </MenuItemStyled>
                        );
                    })}
                </MenuStyled>
            ) : null}
        </>
    );
};

DotMenu.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            icon: PropTypes.string,
            iconType: PropTypes.string,
            onItemClick: PropTypes.any
        })
    ).isRequired,
    onItemClick: PropTypes.func,
    edge: PropTypes.string
};

/*
Items example:
[
  { name: "About", icon: "information-outline" },
  { name: "Classes", icon: "Attribute-class", iconType: "af" },
  { name: "Relations", icon: "link" },
  { name: "Attachments", icon: "attachment" },
  { name: "History", icon: "history" },
]
*/

export default DotMenu;
