/* @flow */

import React, { useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { Button as PuiButton, ConfirmationModal } from '@mic3/platform-ui';

import history from 'store/History';
import { useToggle } from 'app/utils/hook/hooks';

import FormField from 'app/containers/Designer/Form/components/FormField';

const fieldify = (Component: Object) => (props: Object) => <FormField {...props} Component={Component} />;

const PuiButtonStyled = styled(PuiButton)`
    text-align: center;
    text-decoration: none;
    &:hover {
        text-decoration: none !important;
    }
`;

const ConfirmationModalFildifyed = fieldify(ConfirmationModal);

const ModalWrapper = styled.div`
    ${({position}) => position && `position: ${position}`} !important;
`;

const Button = ({
    withConfirmation, confirmationModalProps, to, onClick, onClose, onBeforeClick,
    afterAction, afterActionType, afterActionTo, afterActionHref, afterActionFunction,
    validateBefore, isValidForm, href, target, actionType, modalWithWrapper, modalWrapperProps,
    ...buttonProps
}: Object) => {
    const [openConfirmationModal, toggleConfirmationModal] = useToggle();
    const handleOnClick = useMemo(() => {

        if(to && actionType === 'internal') {
            return () => {
                history.push(to);};
        }
        if((to || href) &&  actionType === 'external') {
            return () => {
                if(target === '_blank') {
                    window.open(to || href, target);
                } else {
                    window.location.href = to || href;
                }
            };
        }
        return onClick;
    }, [actionType, to, href, onClick, target]);
    const handleOnClose = useCallback((e) => {
        toggleConfirmationModal();
        onClose && onClose(e);
    }, [onClose, toggleConfirmationModal]);    
    const onClickWithConfirm = withConfirmation ? toggleConfirmationModal : handleOnClick;
    const validateOnClick = useCallback(async (event) => {
        if(event && event.persist) {
            event.persist();
        }
        const { errors } = await isValidForm();
        if(!errors) {
            if(!href) {
                onClickWithConfirm(event);
            } else {
                window.open(href, target);
            }
        }
    }, [href, isValidForm, onClickWithConfirm, target]);
    const onClickAction = validateBefore ? validateOnClick : onClickWithConfirm;
    return (
        <>
            <PuiButtonStyled {...buttonProps} onClick={onClickAction} />
            {withConfirmation && confirmationModalProps && (
                modalWithWrapper ? (
                    <ModalWrapper {...modalWrapperProps}>
                        <ConfirmationModalFildifyed
                            {...confirmationModalProps}
                            onClose={handleOnClose}
                            open={openConfirmationModal}
                            onConfirm={(e) => { 
                                if (onBeforeClick) onBeforeClick(); 
                                return handleOnClick(e); 
                            }}
                        />
                    </ModalWrapper>
                ) : (
                    <ConfirmationModalFildifyed
                        {...confirmationModalProps}
                        onClose={handleOnClose}
                        open={openConfirmationModal}
                        onConfirm={(e) => { 
                            if (onBeforeClick) onBeforeClick(); 
                            return handleOnClick(e); 
                        }}
                    />
                )
            )}
        </>
    );
};

export default Button;
