/* @flow */

import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { IconButton } from '@mic3/platform-ui';

import DotMenu from 'app/components/molecules/DotMenu/DotMenu';
import Icon from 'app/components/atoms/Icon/Icon';
import Loader from 'app/components/atoms/Loader/Loader';
import PageNotAllowed from 'app/containers/ErrorPages/PageNotAllowed';
import PageTemplate from 'app/components/templates/PageTemplate';
import Relations from 'app/containers/Entities/Relationships/Relations';
import history from 'store/History';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { openEntitySidebar } from 'store/actions/entities/entitySidebarActions';
import { openTaskSidebar } from 'store/actions/abox/taskSidebarActions';
import { openProcessSidebar } from 'store/actions/abox/processSidebarActions';
import { openRelationSidebar } from 'store/actions/entities/relationSidebarActions';
import Breadcrumbs from 'app/components/organisms/Breadcrumbs/Breadcrumbs';
import { isEmpty } from 'app/utils/utils';
import { closeLeftPanel, setShowBack } from 'store/actions/leftPanel/leftPanelActions';
import { buildDotMenu } from 'app/utils/entity/entityUtils';
import { loadEntityPrintTemplates } from 'store/actions/entities/printTemplateActions';
import { setTreeEntity } from 'store/actions/entities/relatedEntitiesActions';
import EventAbout from 'app/containers/Stream/Events/EventAbout';
import { loadEventDetails } from 'store/actions/stream/eventsActions';
import Container from 'app/components/atoms/Container/Container';
import HeaderBar from 'app/components/molecules/HeaderBar/HeaderBar';
import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import { openEventsActionsSidebar } from 'store/actions/stream/eventsActionsSidebarActions';
import { openEventSidebar } from 'store/actions/stream/eventsSidebarActions';

class EntitiesDetail extends PureComponent<Object, Object> {
    static propTypes = {
        id: PropTypes.string.isRequired,
        loadEventDetails: PropTypes.func.isRequired,
        details: PropTypes.object,
        isLoading: PropTypes.bool,
    };

    constructor(props: Object) {
        super(props);
        this.loadEventDetails();
    }

    componentDidUpdate(prevProps) {
        const { id, closeLeftPanel, leftPanelOpts, setShowBack } = this.props;
        if (id && id !== prevProps.id) {
            this.loadEventDetails();
        }
        if (leftPanelOpts.isOpen) {
            closeLeftPanel();
            setShowBack(false);
        }
    }

    @bind
    loadEventDetails() {
        const { id, time } = this.props;
        return this.props.loadEventDetails(id, time);
    }

    @bind
    onThreeDotClick() {
        const { id, time, details } = this.props;
        const params = { id, time, data: details, title: 'Actions' };
        this.props.openEventsActionsSidebar(params);
    }

    @bind
    @memoize()
    renderActions() {
        return (
            <IconButton key='1' onClick={this.onThreeDotClick}>
                <Icon name={'dots-vertical'} />
            </IconButton>
        );
    }

    @bind
    onDotMenuClick(title, params) {
        const { id, time, openEntitySidebar } = this.props;
        if (title === 'Relations') {
            history.push(`/events/${id}/${time}/relationships`);
            return;
        }
        const settings = { title, id, params };
        openEntitySidebar(settings);
    }

    @bind
    @memoize()
    buildDotMenu(title, details, pathname, entityTemplates) {
        return buildDotMenu({ details, title, pathname, entityTemplates });
    }

    @bind
    @memoize()
    dotMenu(sidebarTitle: string, details: Object, pathname: string = '', entityTemplates) {
        return (
            <DotMenu
                key={13}
                onItemClick={this.onDotMenuClick}
                items={this.buildDotMenu(sidebarTitle, details, pathname, entityTemplates)}
            />
        );
    }

    @bind
    buildBreadcrumbs() {
        const { details } = this.props;
        return <Breadcrumbs list={[{ link: '/events', title: 'Events' }, { title: details?.device?.name || 'No name' }]} withGoBack />;
    }

    /**
     * @override
     */
    render() {
        const {
            openRelationSidebar,
            openEntitySidebar,
            id,
            isLoading,
            details,
            match,
            openTaskSidebar,
            openProcessSidebar,
            openEventSidebar,
        } = this.props;
        if (!isLoading && isEmpty(details)) {
            return <PageNotAllowed permissionError={true} title={`Event (ID:${id})`} />;
        }
        const showDetails = details && details.id === id;
        return (
            <Fragment>
                {isLoading && <Loader absolute backdrop />}
                {showDetails && (
                    <PageTemplate title={details.name} subTitle={`#${details.id}`} overflowHidden>
                        <Switch>
                            <Route path={`${match.url}`} exact component={() => <Redirect to={`${match.url}/about`} />} />
                            {/* Handle the legacy redirect */}
                            <Route path={`${match.url}/about`}>
                                <>
                                    <HeaderBar left={this.buildBreadcrumbs()} right={[this.renderActions()]} />
                                    <ContentArea withHeader>
                                        <Container width='1024'>
                                            <EventAbout details={details} />
                                        </Container>
                                    </ContentArea>
                                </>
                            </Route>
                            <Route
                                path={`${match.url}/entity-relations`}
                                render={() => (
                                    <Relations
                                        openEntitySidebar={openEntitySidebar}
                                        openTaskSidebar={openTaskSidebar}
                                        openProcessSidebar={openProcessSidebar}
                                        openRelationSidebar={openRelationSidebar}
                                        fromType='event'
                                        fromId={id}
                                        canEdit
                                        details={details}
                                        sidebarActions={[this.renderActions()]}
                                        viewType='eventEntity'
                                    />
                                )}
                            />
                            <Route
                                path={`${match.url}/event-relations`}
                                render={() => (
                                    <Relations
                                        openEventSidebar={openEventSidebar}
                                        openEntitySidebar={openEntitySidebar}
                                        openTaskSidebar={openTaskSidebar}
                                        openProcessSidebar={openProcessSidebar}
                                        openRelationSidebar={openRelationSidebar}
                                        fromType='event'
                                        toType='event'
                                        fromId={id}
                                        canEdit
                                        details={details}
                                        sidebarActions={[this.renderActions()]}
                                        viewType='eventToEvent'
                                    />
                                )}
                            />
                        </Switch>
                    </PageTemplate>
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = (state: Object, ownProps: Object) => ({
    id: ownProps.match.params.id,
    time: ownProps.match.params.time,
    details: state.stream.events.details.data,
    isLoading: state.stream.events.details.isLoading,
    sidebarTitle: state.sidebar.title,
    leftPanelOpts: state.leftPanel.state,
});

export default connect(mapStateToProps, {
    openRelationSidebar,
    openTaskSidebar,
    openProcessSidebar,
    openEntitySidebar,
    closeLeftPanel,
    setShowBack,
    loadEntityPrintTemplates,
    setTreeEntity,
    loadEventDetails,
    openEventsActionsSidebar,
    openEventSidebar,
})(withRouter(EntitiesDetail));
