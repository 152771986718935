/* @flow */

import React, { PureComponent } from 'react';
import { IconButton, Tooltip } from '@mic3/platform-ui';
import { muiTheme } from 'app/themes/materialUi';
import Icon from 'app/components/atoms/Icon/Icon';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import DotMenu from 'app/components/molecules/DotMenu/DotMenu';
import { getModulePath } from 'app/utils/classification/classificationUtils';
import { isValidURL, appendHttp } from 'app/utils/string/string-utils';
import { render, compile } from 'app/utils/template/template';
import { getEntityUrl } from 'app/utils/entity/entityUtils';
import history from 'store/History';
import { getPermissions } from 'app/config/rolesConfig';

class GridEntityActions extends PureComponent<Object, Object> {

    @bind
    goToDetails() {
        const {
            data,
            data: { id, primaryClass },
            entitiesView,
            entityType,
            isPrintReports
        } = this.props;
        const type = entitiesView ? data?.type || this.props.type : this.props.type;

        if (entitiesView) {
            const url = primaryClass?.openInModule;
            if (!url || url === getModulePath(url, type)) {
                return history.push(`${getModulePath(primaryClass.openInModule, type)}/${id}`);
            }
            if (isValidURL(url)) {
                const validUrl = appendHttp(url);
                const link = render(compile(validUrl), data);
                return window.open(link, '_blank');
            }
            const link = render(compile(url), data);
            return history.push(link);
        }

        history.push(getEntityUrl(id, type, { entityType, isPrintReports }));
    }
  
    @bind
    @memoize()
    openAliveSidebar(e, params, entityType){
        e.stopPropagation();
        this.props.openAliveSidebar({...params, entityType});
    }

    @bind
    handleMenuClick(title) {
        const { goToDetails, data } = this.props;
        if (title === 'Go to details') {
            goToDetails && data?.id ? goToDetails(data?.id) : this.goToDetails();
        } else if (title === 'Process Map') {
            return history.push(`/abox/task/${data?.id}/process-map`);
        } else {
            this.props.openSidebar(title, data);
        }
    }

   

    render() {
        const { data, entityType, reloadList, type, dotMenu } = this.props;
        const params = { id: data.id, type, title: 'A-Live', reloadList };
        const { role } = data;
        const permissions = getPermissions(role);
        const { canComment } = permissions;
        const hideChatActionForTypes = ['class', 'team', 'workspace'];
        
        return (    
            <>
                {!hideChatActionForTypes.includes(type) && canComment && (
                    <Tooltip title='Open chat'>
                        <IconButton onClick={e => this.openAliveSidebar(e, params, entityType )} >
                            <Icon name="messenger" type="af" hexColor={muiTheme.colors.appHeader.iconColor}  />
                        </IconButton>
                    </Tooltip>
                )}
                <DotMenu key={13} onItemClick={this.handleMenuClick} items={dotMenu} />
            </> 

        );
    };
}
export default (GridEntityActions);