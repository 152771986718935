// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import ResizeObserver from 'react-resize-observer';
import styled from 'styled-components';

import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import olMap from 'app/containers/Maps/SituationalAwareness/olMapController';
import AboutMapToolbar from 'app/containers/Maps/EntityAboutMap/AboutMapToolbar';
import LocationSnackbar from 'app/containers/Maps/EntityAboutMap/LocationSnackbar';
import { bind, debounce } from 'app/utils/decorators/decoratorUtils';
import { getAttachmentUrl } from 'app/utils/attachments/attachmentsUtils';
import { Button, Typography, MdiIcon, Slider, Grid } from '@mic3/platform-ui';
import { createEvent } from 'app/utils/http/event';
import Geocode from 'app/utils/maps/geocodeUtils';
import { getImageCanvas } from 'app/components/molecules/Map/EntityPin/entityPinStyle';
import { toLonLat } from 'ol/proj';
import { Icon, Style } from 'ol/style';
import 'ol/ol.css';

const StyledMapDiv = styled.div`
    width: 100%;
    height: calc(100vh - 115px);
`;

const SnacbarHeader = styled.div`
    display: flex;
    justify-content: space-between;
`;

const StyledDiv = styled.div`
    display: flex;
`;

const StyledSpan = styled.span`
    padding-left: 10px;
`;

const StyledTypography = styled(Typography)`
    margin: 5px 0px !important;
`;

const StyledButton = styled(Button)`
    margin-left: -10px !important;
`;

export const primarySvgMap = new olMap();

class EntitySvgSectionMap extends PureComponent<Object, Object> {
    constructor(props) {
        super(props);
        this.olMap = primarySvgMap;
        this.snckbarRef = new React.createRef();
        this.state = {
            isMapLoaded: false,
            isSliderVisible: false,
            currZoom: 4,
            tempSliderVal: null
        };
        this.mapZoom = 4;
        this.userLat = null;
        this.userLong = null;
    }

    componentDidMount() {
        this.loadSvgMap();
    }

    @bind
    async onMapChange(zoom, lat, long) {
        const {details} = this.props;

        const {entitySvg, entityGraphic, SvgTransformation, entitySvgVisibleZoom} = details || {};
        const longitude = this.userLong || long || -7.374175;
        const latitude = this.userLat || lat || 4.013646;
        const svgLayer = this.findSvgLayer();
        const style = svgLayer?.getStyle();
        if (this.state.currZoom >= entitySvgVisibleZoom || entitySvgVisibleZoom <= this.state.currZoom ) {
            if(this.state.currZoom === entitySvgVisibleZoom && style?.image_?.scale){
                this.olMap.addPinToClassAboutMap({ longitude, latitude });
            } else {
                const graphicSvg = entityGraphic && getAttachmentUrl(entityGraphic.id, 'graphic', entityGraphic.image);
                await this.olMap.addPinToClassAboutMap({
                    svg: entitySvg || graphicSvg,
                    longitude,
                    latitude,
                    SvgTransformation,
                });
                const svgLayer = this.findSvgLayer();
                if (svgLayer && graphicSvg) {
                    const img = getImageCanvas(graphicSvg || entityGraphic, 100, this.olMap.getMap());
                    const svgStyle = new Style({
                        image: new Icon({
                            img: img,
                            imgSize: [100, 100]
                        })
                    });
                    svgLayer.setStyle(svgStyle);
                    this.scaleAndRotateSvg();
                }
            }
        } else {
            this.olMap.addPinToClassAboutMap({longitude, latitude});
        }
        if(this.userLat || this.userLong) {
            this.olMap.setPinCenter(this.userLong, this.userLat);
        }
        this.userLong = null;
        this.userLat = null;

    }

    @bind
    async loadSvgMap() {
        this.olMap.initPrimaryAboutSvgMap('svgMap', 'locationMap');
        const baseLayer = this.olMap.getMap()?.getLayers()?.array_?.[0]?.values_?.layers?.array_[0];
        baseLayer && baseLayer.setVisible(true);
        this.olMap.getMap().render();
        await Geocode.getCurrentLocation(
            ({coords}) => {
                const {latitude, longitude} = coords || {};
                this.userLat = latitude;
                this.userLong = longitude;
                this.loadMapCommon();
            },
            (e) => {
                console.error("Unable to fetch user's current location : ", e); // eslint-disable-line
            }
        );
        this.loadMapCommon();
    }

    @bind
    loadMapCommon() {
        const map = this.olMap.getMap();
        const currZoom = parseInt(map?.getView()?.getZoom());
        this.setState({currZoom, isMapLoaded: true});
        this.onMapChange(4);
        this.olMap.getMap().on('moveend', this.handleMapMove);
    }

    @bind
    findSvgLayer() {
        return this.olMap?.getMap()?.getLayers()?.array_?.find(({ values_ }) => values_?.title === 'Class About Map layer');
    }


    @bind
    handleMapMove(event) {
        const map = event?.map;
        const newZoom = parseInt(map?.getView()?.getZoom() || event?.target?.getZoom());
        this.setState({ currZoom: newZoom});
        const mapCenter = toLonLat(this.olMap.getMap().getView().getCenter());
        mapCenter && this.onMapChange(this.mapZoom, mapCenter[1], mapCenter[0]);
        if (this.mapZoom !== newZoom) {
            this.mapZoom = newZoom;
        }
    }

    @bind
    async applyZoomLevel() {
        const { tempSliderVal, currZoom } = this.state;
        const event = createEvent('change', { name: 'entitySvgVisibleZoom', value: tempSliderVal });
        await this.props.changeApplied(false);
        await this.props.handleChange(event);
        this.olMap.getMap().render();
        if (tempSliderVal === currZoom) {
            this.onMapChange(tempSliderVal);
        }

    }

    @bind
    scaleAndRotateSvg() {
        const clickListener = (evt) => {
            const feature = this.olMap.getMap().forEachFeatureAtPixel(evt.pixel, (feature) => {
                return feature;
            });
            if (feature) {
                // Scale and rotate functionality is broken which is affecting svg click on both class map and entity location map.
                // commenting it until its properly fixed.
                // this.snr = new ScaleNRotate(this.olMap, feature, this.props?.details?.SvgTransformation);
                this.olMap.getMap().un('click', clickListener);
            }
        };
        this.olMap.getMap().on('click', clickListener);
    }

    @bind
    @debounce(200)
    updateMap() {
        this.olMap.getMap().render();
    }

    @bind
    onChange(value: Object) {
        if (this.props.onChange) {
            this.setState({ isLoading: true });
            const event = createEvent('change', { name: 'location', value });
            this.props.onChange(event);
        }
    }

    @bind
    renderMapToolbar() {
        const { details } = this.props;
        const { entitySvg, id, uri } = details || {};
        return (
            <AboutMapToolbar
                id={id}
                type={uri}
                olMap={this.olMap}
                setFirstPin=""
                snackbarRef={this.snckbarRef}
                svgTransformation=""
                svgMap={true}
                entitySvg={entitySvg}
            />
        );
    }

    @bind
    renderSvgScaleMessage() {
        const { details } = this.props;
        const { entitySvgVisibleZoom } = details;
        const { currZoom } = this.state;
        const header = entitySvgVisibleZoom > currZoom ? 'SVG is not visible at this scale.' : 'SVG is visible at this scale.';
        return (
            <>
                <SnacbarHeader>
                    <Typography variant="body1">{header}</Typography>
                    <MdiIcon name="close" onClick={this.onClose} size={'small'} />
                </SnacbarHeader>
                <Typography variant="caption">Zoom in closer to see the preview of svg.</Typography>
            </>
        );
    }

    @bind
    handleSlider(event: Event) {
        this.props.changeApplied(true);
        this.setState({ tempSliderVal: event.target.value });
    }

    @bind
    renderScaleMessage() {
        const { details } = this.props;
        const { currZoom, tempSliderVal } = this.state;
        const { entitySvgVisibleZoom } = details || {};
        const header = 'Current zoom level';
        return (
            <>
                <SnacbarHeader>
                    <Typography variant="body1">
                        {header} : {currZoom.toFixed(0)}
                    </Typography>
                    <MdiIcon name="close" onClick={() => this.snckbarRef.current.handleOpen(false)} size={'small'} />
                </SnacbarHeader>
                <StyledTypography variant="body2">SVG visible at zoom level</StyledTypography>
                <Grid>
                    <StyledDiv>
                        <Slider name="entitySvgVisibleZoom" value={tempSliderVal || entitySvgVisibleZoom} onChange={this.handleSlider} min={3} max={18} step={1} />
                        <StyledSpan>{tempSliderVal || entitySvgVisibleZoom}</StyledSpan>
                    </StyledDiv>
                </Grid>
            </>
        );
    }

    @bind
    renderScaleAction() {
        return (
            <StyledButton onClick={this.applyZoomLevel}>Apply</StyledButton>
        );
    }

    @bind
    renderSlider() {
        const message = this.renderScaleMessage();
        const action = this.renderScaleAction();
        this.snckbarRef.current.addAction(action);
        this.snckbarRef.current.addMessage(message);
    }

    @bind
    showSlider() {
        this.setState({ isSliderVisible: true });
    }

    @bind
    renderSvgActions() {
        return (
            <>
                <Button variant="text" onClick={this.showSlider}>
                    Edit Zoom Level
                </Button>
            </>
        );
    }

    @bind
    renderLocationSnackbar() {
        const message = this.renderSvgScaleMessage();
        const action = this.renderSvgActions();
        this.snckbarRef.current.addAction(action);
        this.snckbarRef.current.addMessage(message);
        this.snckbarRef.current.handleOpen(true);
    }

    render() {
        const { isMapLoaded, isSliderVisible } = this.state;
        return (
            <ContentArea>
                {this.renderMapToolbar()}
                <StyledMapDiv id="svgMap">
                    <ResizeObserver onResize={() => this.olMap.updateMap()} />
                    { isMapLoaded && this.renderLocationSnackbar()}
                </StyledMapDiv>
                <LocationSnackbar ref={this.snckbarRef} />
                { isSliderVisible ? this.renderSlider() : null}
            </ContentArea>
        );
    }
}

export default connect(state => ({
    isFullScreen: state.maps.situationalAwareness.map.mapFullScreen,
    isSidebar: state.sidebar.isOpen
}))(EntitySvgSectionMap);
