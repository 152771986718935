/* @flow */

import { gql } from '@apollo/client';

export default gql`
query formDefinitionTypeaheadQuery($startIndex: Int, $stopIndex: Int, $filterBy: [JSON], $orderBy: [JSON], , $excludeBy: [JSON]) {
    result: formDefinitions(startIndex: $startIndex, stopIndex: $stopIndex, filterBy: $filterBy, orderBy: $orderBy, excludeBy: $excludeBy) {
        id
        name
        image
        type
  }
}
`;
