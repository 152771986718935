// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { bind, debounce } from 'app/utils/decorators/decoratorUtils';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import { createEvent } from 'app/utils/http/event';
import { get } from 'app/utils/lo/lo';
import { getLayerFilterDefinitions  } from 'app/containers/Maps/LayerAbout/EntityLayer/MapsLayerFilterDefinitions';
import { setDefaultFilters } from 'app/containers/Maps/LayerAbout/EntityLayer/MapsLayerFilterDefinitions';
import { getPermissions } from 'app/config/rolesConfig';
import { LAYERS } from 'app/utils/maps/layer/layerUtils';


class EventLayerFilters extends PureComponent<Object, Object> {
    static propTypes: Object = {
        data: PropTypes.object
    };

    constructor(props: Object) {
        super(props);
        const { data } = props || {};
        const { event_type, time_range } = data || {};
        const eventFilters = setDefaultFilters(get(data, `filter_by`, []), data?.type);
        const eventLayerFiltersForm = { ...eventFilters, time_range, event_type, prevEntityType: event_type };
        this.state = { eventLayerFiltersForm };
    }

    formRef = React.createRef();

    @bind
    async validateForm() {
        if (this.formRef && this.formRef.current) {
            const { errors } = await this.formRef.current.isValidForm();
            return { errors };
        } else {
            return { errors: null };
        }
    }

    @bind
    @debounce()
    async handleFormValidation() {
        const result = await this.validateForm();
        this.errors = !!result.errors;
        return !result.errors;
    }

    @bind
    hasErrors() {
        return this.errors;
    }

    @bind
    async handleChange(data: Object, { name, value }: Object) {
        await this.handleFormValidation();
        this.setState({ eventLayerFiltersForm: data });
        if (this.props.onChange) {
            const name = this.props.name;
            const value = data;
            const event = createEvent('change', { name, value });
            this.props.onChange(event);
        }
    }

    render() {
        const { eventLayerFiltersForm } = this.state;
        const { data, entityTypes, mapUserRole } = this.props;
        const { filter_by } = data || {};
        const { canEdit } = getPermissions(mapUserRole);
        const operatorsMap = filter_by?.operatorsMap;
        const classData = entityTypes.find(entity => entity.uri === 'system_eventlayer');
        return (
            <FormGenerator
                data={eventLayerFiltersForm}
                name={this.props.name}
                onChange={this.handleChange}
                components={getLayerFilterDefinitions(LAYERS?.event, classData, operatorsMap, canEdit)}
                ref={this.formRef}
            />
        );
    }
}

const mapStateToProps = state => ({
    mapUserRole: get(state.maps, 'situationalAwareness.map.data.role', ''),
});

export default connect(mapStateToProps)(EventLayerFilters);
