/* @flow */

import { gql } from '@apollo/client';

export default gql`
    mutation createPipelineMutation($record: EntityCreateInput!){
        result: createEntity(record: $record) {
            id
            type
            name
        }
    }
`;
