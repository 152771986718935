// @flow

export const allTypes = ['workspace', 'team', 'thing', 'person', 'organisation', 'custom', 'class', 'process', 'task', 'user', 'opentask', 'closedtask', 'process', 'openprocess', 'closedprocess', 'relationdefinition', 'script', 'event', 'eventtype', 'broadcast'];
export const entityTypes = ['thing', 'person', 'organisation', 'custom', 'process', 'task', 'user', 'opentask', 'closedtask', 'process', 'openprocess', 'closedprocess', 'relationdefinition', 'script'];

export const typeMap = {
    map: 'system_map',
    iot_pipeline: 'iot_pipeline',
    class: 'classes',
    custom: 'customEntities',
    formdefinition: 'formDefinitions',
    organisation: 'organisations',
    permission: 'permissions',
    person: 'people',
    process: 'processes',
    processdefinition: 'processDefinitions',
    relationdefinition: 'relationDefinitions',
    script: 'scripts',
    task: 'tasks',
    team: 'teams',
    thing: 'things',
    user: 'users',
    workspace: 'workspaces',
    'print-template': 'print-template',
};

export const typeIcon = {
    custom: { name: 'shape-circle-plus', type: 'mdi' },
    organisation: { name: 'organisations', type: 'af' },
    person: { name: 'people', type: 'af' },
    process: { name: 'processes', type: 'af' },
    relationdefinition: { name: 'link', type: 'mdi' },
    task: { name: 'tasks', type: 'af' },
    thing: { name: 'Things', type: 'af' },
    'print-template': { name: 'printer' },
    'iot_pipeline': { name: 'pipe' }
};

export const typeTitlesMultiple = {
    iot_pipeline: 'Pipelines',
    'print-template': 'Print Templates',
    iot_pipeline: 'Pipelines',
    alive: 'Messages',
    broadcast: 'Broadcasts',
    class: 'Classes',
    entity: 'Entity Type',
    closedprocess: 'Closed Processes',
    closedtask: 'Closed Tasks',
    custom: 'Custom Entities',
    event: 'Events',
    eventtype: 'Event Types',
    formdefinition: 'Form Designer',
    graphic: 'Graphics Library',
    openprocess: 'Open Processes',
    opentask: 'Open Tasks',
    organisation: 'Organisations',
    permission: 'Permissions',
    person: 'People',
    process: 'Processes',
    processdefinition: 'Process Designer',
    primaryClass: 'Primary Classes',
    relationdefinition: 'Relation Definitions',
    task: 'Tasks',
    kanbanboards:'Boards',
    'beta-kanbanboards':'Boards (Beta)',
    boards :'Boards',
    team: 'Teams',
    thing: 'Things',
    user: 'Users',
    workspace: 'Workspaces',
    script: 'Scripts',
    map: 'Maps',
};

export const typeTitlesSingle = {
    iot_pipeline: 'Pipeline',
    'print-template': 'Print Template',
    'print-reports': 'Print Report',
    broadcast: 'Broadcast',
    iot_pipeline: 'Pipeline',
    class: 'Class',
    closedprocess: 'Closed Process',
    closedtask: 'Closed Task',
    custom: 'Custom Entity',
    event: 'Event',
    eventtype: 'Event Type',
    formdefinition: 'Form Designer',
    graphic: 'Graphic',
    openprocess: 'Open Process',
    opentask: 'Open Task',
    organisation: 'Organisation',
    permission: 'Permission',
    person: 'Person',
    process: 'Process',
    processdefinition: 'Process Designer',
    relationdefinition: 'Relation Definition',
    script: 'Script',
    task: 'Task',
    team: 'Team',
    thing: 'Thing',
    user: 'User',
    workspace: 'Workspace',
    map: 'Map',
    'entity-layer': 'Entity Layer',
    'wms': 'WMS Layer',
    'drawing-layer': 'Drawing Layer',
    'replay-layer': 'Replay Layer',
};
export const redirectTypes = {
    iot_pipeline: '/designer/pipelines',
    'print-template': '/print-templates',
    class: 'classifications',
    entity: 'entity-types',
    custom: 'custom-entities',
    customEntity: 'custom-entities',
    organisation: 'organisations',
    person: 'people',
    process: 'abox/process',
    openprocess: 'abox/process',
    closedprocess: 'abox/process',
    task: 'abox/task',
    opentask: 'abox/task',
    closedtask: 'abox/task',
    thing: 'things',
    processdefinition: 'designer/processes',
    relationdefinition: 'relation-definitions',
    formdefinition: 'designer/forms',
    workspace: 'workspaces',
    team: 'teams',
    script: 'designer/scripts',
    user: 'user-management',
};

export const queriesCountType = {
    broadcast: 'broadcast',
    class: 'class',
    custom: 'custom',
    entityChangelog: 'entityChangelog',
    eventtype: 'eventType',
    formdefinition: 'formdefinition',
    formdefinitionversion: 'formDefinitionVersion',
    graphic: 'graphic',
    organisation: 'organisation',
    permission: 'permission',
    person: 'person',
    process: 'process',
    processdefinition: 'processdefinition',
    relationdefinition: 'relationDefinition',
    script: 'script',
    scriptversion: 'scriptVersion',
    task: 'task',
    team: 'team',
    teamchangelog: 'teamChangelog',
    thing: 'thing',
    user: 'user',
    workspace: 'workspace',
    workspacechangelog: 'workspaceChangelog',
};

export const isValidType = (entityType: string) => {
    return !!getType(entityType);
};

export const getType = (type: string) => {
    const _type = (type || '').toLowerCase();
    if (allTypes.includes(_type)) {
        return _type;
    }
    switch (_type) {
        case 'things':
            return 'thing';
        case 'people':
            return 'person';
        case 'organisations':
            return 'organisation';
        case 'custom entities':
        case 'customentities':
        case 'custom-entities':
            return 'custom';
        case 'processes':
            return 'process';
        case 'formdefinition':
            return 'formdefinition';
        default: {
            return typeMap[_type];
        }
    }
};

const adminPrefix = 'Admin Console -';
const miStreamPrefix = 'Mi-Stream -';
const aboxPrefix = 'A-Box -';
const entitiesPrefix = 'Entities -';
const analyticsPrefix = 'Analytics -';
const classesPrefix = 'Classes -';
const designerPrefix = 'Designer -';
const profilePrefix = 'Profile -';

export const modulesAndPageTitles = {
    adminConsole: {
        site: {
            general: `${adminPrefix} General`,
            terms: `${adminPrefix} Terms and Conditions`,
            license: `${adminPrefix} License`,
        },
        broadcast: `${adminPrefix} Broadcast`,
        createBroadcast: `${adminPrefix} Add Broadcast`,
        broadcastCalendar: `${adminPrefix} Broadcast Calendar`,
        users: `${adminPrefix} Users`,
        addUser: `${adminPrefix} Add User`,
        teams: `${adminPrefix} Teams`,
        workspaces: `${adminPrefix} Workspaces`,
        backgroundJobs: `${adminPrefix} Background Jobs`,
        security: {
            general: `${adminPrefix} General`,
            authentication: `${adminPrefix} Authentication`,
            tokens: `${adminPrefix} Tokens`,
            bruteForce: `${adminPrefix} Brute Force Detection`,
            identityProviders: `${adminPrefix} Identity Providers`,
        }
    },
    miStream: {
        pipelineMonitor: `${miStreamPrefix} Pipeline Monitor`,
        eventTypes: `${miStreamPrefix} Event Types`,
        events: `${miStreamPrefix} Events Monitor`,
    },
    abox: {
        main: 'A-Box',
        myApps: `${aboxPrefix} My Apps`,
        alive: 'A-Live',
        backgroundJobs: `${aboxPrefix} My Background Jobs`,
        processes: `${aboxPrefix} Processes`,
        tasks: `${aboxPrefix} Tasks`,
        calendar: `${aboxPrefix} Calendar`,
        timeline: `${aboxPrefix} Timeline`,
        legacyTimeline: `${aboxPrefix} Legacy Timeline`,
    },
    entities: {
        main: `${entitiesPrefix} Entity Types`,
        addEntity: `${entitiesPrefix} Add Entity`,
        relatedEntities: `${entitiesPrefix} Related Entities`,
        relationDefinition: `${entitiesPrefix} Relation Definition`,
        printTemplates: `${entitiesPrefix} Print Templates`,
        graphics: `${entitiesPrefix} Graphics Library`,
    },
    analytics: {
        reports: `${analyticsPrefix} Reports`,
        grafana: `${analyticsPrefix} Grafana`,
        superset: `${analyticsPrefix} Superset`,
    },
    classes: {
        classes: `${classesPrefix} Classes`,
        entityTypes: `${classesPrefix} Entity Types`,
        add: `${classesPrefix} Add Class`,
    },
    designer: {
        processes: `${designerPrefix} Process Designer`,
        forms: `${designerPrefix} Form Designer`,
        scripts: `${designerPrefix} Scripts`,
        pipeline: {
            main: `${designerPrefix} Pipelines`,
            connectors: `${designerPrefix} Connectors`,
            modules: `${designerPrefix} Modules`,
        }
    },
    profile: `${profilePrefix} About Me`,
    apiKey: `${profilePrefix} API Key`,
    integrations: 'Integrations',
    maps: `Maps - Maps`,
};
