
import { gql } from '@apollo/client';

export default gql`
query loadAvatarQuery($id: ID!) {
  result: team(id: $id) {
    id
    name
  }
}
`;
