/* @flow */

import { gql } from '@apollo/client';
import { entityReferences } from 'graphql/entities/relationships/relationsQuery';

export default gql `
  query loadColumnTasksQuery($startIndex: Int, $stopIndex: Int, $filterBy: [JSON], $orderBy: [JSON], $excludeBy: [JSON]) {
    count: count(dataType: "task", filterBy: $filterBy)
    records: tasks(startIndex: $startIndex, stopIndex: $stopIndex, filterBy: $filterBy, orderBy: $orderBy, excludeBy: $excludeBy) {
        id
        name
        description
        modifiedDate
        process {
          id
          name
        }
        assignee {
          id
          username
          image
          name
        }
		    primary
        relations {
          relation {
              id
              relationDefinition {
                  id
                  type
                  relatedType
                  description
                  relatedDescription
                  relationType
              }
          }
          relatedEntity {
            ${entityReferences}
          }
        }
    }
  }
`;
