import {
    TOGGLE_LAYER_FLITERS,
    LOAD_MAP_LAYER_FILTERS_MENU,
    RESET_ALL_LAYERS,
} from 'store/actions/maps/situationalAwarenessActions';
import Immutable from 'app/utils/immutable/Immutable';

/**
 * The initial state on our reducer
 */
const initialState = {
    processFilters: [],
    layersFilters: {},
    layersFiltersMenu: {}

};

/**
 * @param state - the current Redux state
 * @param action - the dispatched action
 * @returns the new Redux state
 */
const reducer = (state: Object = Immutable(initialState), action: Object) => {
    switch (action.type) {
        case TOGGLE_LAYER_FLITERS: {
            const { title, filtersDefinition } = action.payload;
            const { layersFilters } = state;
            if (layersFilters[title]) {
                const _layersFilters = { ...layersFilters };
                delete _layersFilters[title];
                return Immutable({ ...state, layersFilters: _layersFilters });
            } else {
                const _layersFilters = { ...layersFilters, [title]: filtersDefinition };
                return Immutable({ ...state, layersFilters: _layersFilters });
            }
        }
        case RESET_ALL_LAYERS: {
            return Immutable({...state, layersFilters: {} });
        }
        case LOAD_MAP_LAYER_FILTERS_MENU: {
            const { title, data } = action.payload;
            return Immutable({ ...state, layersFiltersMenu: { ...state.layersFiltersMenu, [title]: data }});
        }
        default:
            return state;
    }
};

export default reducer;
