/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, CircularProgress } from '@mic3/platform-ui';

import { createGraphic } from 'store/actions/common/graphicLibraryActions';
import PageNotAllowed from 'app/containers/ErrorPages/PageNotAllowed';
import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';

/**
 * Renders the view to add a Graphic.
 */
class AddGraphic extends PureComponent<Object, Object> {
    static propTypes = {
        createGraphic: PropTypes.func.isRequired,
        userProfile: PropTypes.object
    };

    static propTypes = {
        createGraphic: PropTypes.func.isRequired,
        isLoading: PropTypes.bool,
        userProfile: PropTypes.object
    };

    formRef: Object = React.createRef();

    @bind
    @memoize()
    fieldDefinitions() {
        return [
            {
                field: 'name',
                type: 'text',
                properties: { label: 'Name', name: 'name' },
                constraints: { required: true, minLength: 3, maxLength: 60 }
            },
            {
                field: 'description',
                type: 'text',
                properties: { label: 'Description', name: 'description' }
            },
        ];
    }

    /**
     * Handle the form submit event.
     */
    @bind
    onFormSubmit(e: Event) {
        e.preventDefault();
        this.formRef.current.isValidForm().then(({ data, errors }) => {
            if (!errors) {
                this.props.createGraphic({...data, name: data.name.trim()}).then(this.onClose);
            }
        });
    }

    @bind
    onClose() {
        const { onClose, reloadList } = this.props;
        if (onClose) {
            this.props.onClose();
        }
        if (reloadList) {
            this.props.reloadList();
        }
    }

    /**
     * @override
     */
    render(): Object {
        const { permissions, isAdmin } = this.props.userProfile;
        const { isLoading } = this.props;
        const permissionsSet = new Set(permissions || []);
        const canAdd = isAdmin || permissionsSet.has('entity.graphic.add');
        if (!canAdd) {
            return <PageNotAllowed title="Graphics" />;
        }
        return (
            <ModalDialog
                title="Create a new graphic"
                onClose={this.onClose}
                actions={
                    isLoading ? (
                        <CircularProgress size={24} color="primary" />
                    ) : (
                        <Button onClick={this.onFormSubmit}>
                            Save
                        </Button>
                    )
                }
            >
                <FormGenerator components={this.fieldDefinitions()} ref={this.formRef} />
            </ModalDialog>
        );
    }
}

export default connect(
    state => ({
        userProfile: state.user.profile,
        isLoading: state.entities.graphics.create.isLoading
    }),
    { createGraphic }
)(AddGraphic);
