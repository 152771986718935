/* @flow */

import { gql } from '@apollo/client';

export default gql`
    query designerScriptListQuery($startIndex: Int, $stopIndex: Int, $filterBy: [JSON], $orderBy: [JSON], $excludeBy: [JSON]) {
        count: count(dataType: "script", filterBy: $filterBy, excludeBy: $excludeBy)
        records: scripts(startIndex: $startIndex, stopIndex: $stopIndex, filterBy: $filterBy, orderBy: $orderBy, excludeBy: $excludeBy) {
            id
            name
            description
            primary(fields: ["version"])
            modifiedDate
            createdDate
            createdBy {
                id
                name
            }
            modifiedBy {
                id
                name
            }
        }
    }
`;
