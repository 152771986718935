/* @flow */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { showToastr } from 'store/actions/app/appActions';

import { Typography, Grid, FormControl, FormHelperText, IconButton, MdiIcon } from '@mic3/platform-ui';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { getFieldByType } from 'app/utils/designer/form/fieldUtils';
import { set } from 'app/utils/immutable/Immutable';
import { LOAD_CLASSIFICATION } from 'store/actions/classifications/classificationsActions';
import Link from 'app/components/atoms/Link/HyperLink';
import { appendHttp } from 'app/utils/string/string-utils';
import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';

const ModalDialogStyled = styled(ModalDialog)`
    & .MuiDialog-paper {
        height: ${({ height }) => height ? height : window.innerHeight}px;
        width: ${({ width }) => width ? width : window.innerWidth}px;
    }
`;

const Row = styled(Grid)`
    width: 100% !important;
    padding: 8px 0;
`;

const IconButtonStyled = styled(IconButton)`
    ${({ disabled }) => disabled ? '& .MuiIcon-root { color: gray !important; }' : ''}
`;

const FieldDivider = styled.div`
    width: 10px;
`;

const TickIcon = styled.div`
    position: relative;
    top: 1.7rem;
`;

const Flex = styled.div`
    display: flex;
`;

/**
 *
 */
class Hyperlink extends PureComponent<Object, Object> {
    static propTypes: Object = {
        onChange: PropTypes.func,
        onMouseDown: PropTypes.func,
        lastActionError: PropTypes.bool,
        lastActionType: PropTypes.string,
        variant: PropTypes.oneOf(['contained', 'text'])
    };

    static defaultProps = {
        variant: 'contained'
    };

    constructor(props: Object) {
        super(props);
        this.state = {
            showTextFields: false,
            value: props.value || {},
            showDialog: false,
            dialogUrl: '',
        };
    }

    componentDidUpdate(prevProps: Object) {
        const { lastActionType, lastActionError, value } = this.props;
        if (this.state.showTextFields && !lastActionError && lastActionType === LOAD_CLASSIFICATION) {
            this.setState({ showTextFields: false });
        }
        if (value !== prevProps.value) {
            this.setState({ value });
        }
    }

    @bind
    changeValue(event: Object) {
        const { value: changedValue, name } = event;
        let { value } = this.state;
        value = set(value, name, changedValue);
        this.setState({ showTextFields: true, value });
    }

    @bind
    toggleTextField(event: Object) {
        event.stopPropagation();
        this.setState(prevState => ({ showTextFields: !prevState.showTextFields }), () => {
            this.props.onChange({ target: { name: this.props.name, value: this.state.value } });
        });
    }
    @bind
    openLink(e, url: string) {
        e.preventDefault();
        e.stopPropagation();
        const { target, windowHeight, windowWidth } = this.props;
        const width = windowWidth && windowWidth <= window.innerWidth ? windowWidth : window.innerWidth;
        const height = windowHeight && windowHeight <= window.innerHeight ? windowHeight : window.innerHeight;                 
        if (url) {
            const validUrl = appendHttp(url);
            switch (target) {
                case '_blank':
                    window.open(validUrl, '_blank');
                    break;
                case '_self':
                    window.location.href = validUrl;
                    break;
                case 'newWindow': 
                    const left = Math.floor((window.innerWidth - width) / 2);
                    const top = Math.floor((window.innerHeight - height) / 2);
                    window.open(validUrl, 'newWindow', `width=${width},height=${height},top=${top},left=${left},scrollbars=yes`);
                    break;
                case 'dialog':
                    this.setState({
                        showDialog: true,
                        dialogUrl: validUrl,
                    });     
                    break;
                default:
                    window.open(validUrl);
                    break;
            }
        } else {
            this.props.showToastr({ severity: 'warning', detail: 'Please enter the valid link.' });
        }
    }
    
    @bind
    @memoize()
    openDialog(dialogUrl){
        const { dialogHeight, dialogWidth } = this.props;
        const width = dialogWidth && dialogWidth <= window.innerWidth ? dialogWidth : window.innerWidth;
        const height = dialogHeight && dialogHeight <= window.innerHeight ? dialogHeight : window.innerHeight;
        return(
            <ModalDialogStyled onClose= {this.closeDialog} width={width} height={height}>
                <iframe src={dialogUrl} title="Dialog Content" style={{ width: '100%', height: '100%' }}></iframe>
            </ModalDialogStyled>
        );
    }

    @bind
    validateLinkField(val: string) {
        const { text } = this.state.value || {};
        if (val && !text) {
            return true;
        }
        return !text;
    }

    @bind
    validateTextField(val: string) {
        const { link } = this.state.value || {};
        if (val && !link) {
            return true;
        }
        return !link;
    }

    @bind
    _buildInput() {
        const { error, helperText, label, required, onMouseDown, disabled } = this.props;
        const { value } = this.state;
        const { text, link } = value || {};
        return (
            <Row container direction="column" justify="flex-start" alignItems="stretch" onMouseDown={onMouseDown}>
                {label && <Typography variant="caption">{label}</Typography>}
                <Flex>
                    {getFieldByType('text', {
                        name: 'text',
                        value: text,
                        label: 'Text',
                        placeholder: 'Text',
                        changeData: this.changeValue,
                        clearable: false,
                        error: error && this.validateLinkField(link),
                        required,
                        disabled
                    })}
                    <FieldDivider />
                    {getFieldByType('text', {
                        name: 'link',
                        value: link,
                        label: 'Link',
                        placeholder: 'Link',
                        changeData: this.changeValue,
                        clearable: false,
                        error: error && this.validateTextField(text),
                        required,
                        disabled
                    })}
                    <TickIcon>
                        <IconButtonStyled onClick={this.toggleTextField} disabled={disabled || !link || !text}>
                            <MdiIcon name="check" color="white"  />
                        </IconButtonStyled>
                    </TickIcon>
                </Flex>
                {helperText && (
                    <FormControl error={error}>
                        <FormHelperText>{helperText}</FormHelperText>
                    </FormControl>
                )}
            </Row>
        );
    }
    @bind
    closeDialog(){
        this.setState({
            showDialog: false,
            dialogUrl: '',
        });
    }

    /**
     * @override
     */
    render(): Object {
        const { label, disabled, variant } = this.props;
        const { showTextFields, value, showDialog, dialogUrl } = this.state;
        const { text, link } = value || {}; 
        
        if (variant === 'text') {
            return <Link text={value.text} url={appendHttp(value.link)} />;
        }
        if ((!showTextFields || disabled) && link && text) {
            return (
                <Grid container direction="column" alignItems="stretch">
                    {label && <Typography variant="caption">{label}</Typography>}
                    <Flex>
                        {showDialog && this.openDialog(dialogUrl)}
                        {getFieldByType('button', {
                            label: text,
                            variant: 'text',
                            onClick: e => this.openLink(e, link),
                            style: { width: '100%' }
                        })}
                        {!disabled
                            ? (
                                <IconButton onClick={this.toggleTextField} disabled={!link || !text}>
                                    <MdiIcon name="pencil" color="white"  />
                                </IconButton>
                            ) : null}
                    </Flex>
                </Grid>
            );
        }
        return this._buildInput();
    }
}

export default connect(
    (state: Object) => ({
        lastActionType: state.global.lastActionType,
        lastActionError: state.global.lastActionError
    }),
    { showToastr }
)(Hyperlink);
