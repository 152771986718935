/* @flow */

import { label, relatedEntityToComponent, configurationDefinitionForm } from 'app/utils/designer/pipeline/settings/common/commonImportant';

const panels = (settingValues) => {
    return [
        {
            type: 'panel',
            properties: {
                header: settingValues.data.group,
                iconName: settingValues.data.iconName,
                iconType: settingValues.data.iconType,
                expanded: true,
                collapsible: false,
            },
            children: [
                {
                    type: 'header',
                    properties: {
                        variant: 'h6',
                        text: settingValues.data.title
                    }
                },
            ],    
        },
        {
            type: 'panel',
            properties: {
                header: 'General Information',
                expanded: true,
            },
            settings: { forModal: true },
            children: [
                label(settingValues.pipelineMonitor),
                relatedEntityToComponent(
                    'iot_connector', 
                    { 
                        filterBy: [
                            { field: 'lastVersion.primary.iot_connector_version/type', op: '=', value: settingValues.data.type }
                        ]
                    },
                    settingValues.pipelineMonitor
                ),
            ],    
        },
        configurationDefinitionForm(settingValues.pipelineMonitor),
    ].filter(Boolean);
};

export default {
    defaults: {},
    panels: settingValues => panels(settingValues),
};
