/* @flow */

// $FlowFixMe
import React, { PureComponent } from 'react';
import { Paper, Typography, Dialog, DialogTitle, DialogActions, DialogContent, Button } from '@mic3/platform-ui';
import styled from 'styled-components';
import memoizeOne from 'memoize-one';

import { getNumberOfValues } from 'app/utils/designer/queryBuilderUtils';
import { isDefined, valueToType, getType } from 'app/utils/utils';
import Forest from 'app/utils/dataStructure/Forest';
import uuidv1 from 'uuid/v1';
import QueryBuilderDnd from './QueryBuilderDnd';

const PaperStyled = styled(Paper)`
    padding: 12px;
    margin: 12px 0;
    cursor: pointer;
    background: ${({ theme }) => theme.material.colors.background.default} !important;
`;
const TypographyPadded = styled(Typography)`
    padding: 12px 0 0 0;
`;

class QueryBuilder extends PureComponent<Object, Object> {

    state: Object
    uuid: string = uuidv1();

    constructor(props: Object) {
        super(props);
        const denormalizedValues = this.deNormalizeQueries(props.value || []);
        this.state = {
            activeSave: true,
            queries: {
                ...denormalizedValues,
                uuid: props.uuid || this.uuid,
                // $FlowFixMe
                children: this.forest(denormalizedValues).nodes
            },
            showModal: false,
        };
    }

    componentDidUpdate(prevProps: Object) {
        const { value, uuid } = this.props;
        // $FlowFixMe
        if (prevProps.value !== value) {
            const denormalizedValues = this.deNormalizeQueries(value || []);
            this.setState({ queries: {
                ...denormalizedValues,
                uuid: uuid || this.uuid,
                // $FlowFixMe
                children: this.forest(denormalizedValues).nodes
            }

            });
        }
    }

    _normalizeChildren = (children: Array<Object>): Array<Object> => {
        return children.map((child: Object) => {
            const { field, op, value, condition } = child;
            if(condition) {
                // $FlowFixMe
                return this.normalizeQueries(child, false);
            }
            if(op === 'is null' || op === 'is not null') {
                return { field, op };
            }
            return { field, op, value };
        });
    }

    normalizeQueries = (queries: Object, parent: boolean = true): Array<Object> => {
        if(queries.condition === 'OR') {
            // $FlowFixMe
            return { or: this._normalizeChildren(queries.children)};
        }
        return this._normalizeChildren(queries.children);
    }

    deNormalizeQueries = (value: Array<Object>, condition: string = 'AND', parent: boolean = true) => {
        return value.reduce((accum, query) => {
            const { value } = query;
            let valueType = getType(value);
            if(valueType === 'array') {
                valueType = getType(value[0]);
            }
            let child = { ...query, type: 'child', fieldType: valueType };
            if(query.or) {
                child = this.deNormalizeQueries(query.or, 'OR', false);
            }
            if(Array.isArray(query)) {
                child = this.deNormalizeQueries(query, 'AND', false);
            }
            accum.children.push(child);
            return accum;
        }, { type: 'group', condition, children: []});
    }

    _buildFormula = (value: Array<Object>, condition: string = 'AND', parent: boolean = true) => {
        let formula = '(';
        value.forEach((query, index, values) => {
            const isLast = values.length === index + 1;

            let valueType = getType(query.value);
            let quote = !['boolean', 'number'].includes(valueType) ? `'` : '';
            let body = `${query.field} ${query.op} ${quote}${query.value}${quote}`;
            if(Array.isArray(query.value)) {
                body = `${query.field} ${query.op} [${query.value.map((val) => {
                    valueType = getType(val);
                    quote = !['boolean', 'number'].includes(valueType) ? `'` : '';
                    return `${quote}${val}${quote}`;
                })}]`;
            }
            if(getNumberOfValues(query.op) === 0) {
                body = `${query.field} ${query.op}`;
            }
            if(query.or) {
                body = this._buildFormula(query.or, 'OR', false);
            }
            if(Array.isArray(query)) {
                body = this._buildFormula(query, 'AND', false);
            }
            formula = formula + String(body);
            if(!isLast) {
                formula = `${formula} ${condition} `;
            }
        });
        return formula + ')';
    }

    buildFormula = memoizeOne((queries: Object) => {
        if(queries.children.length === 0) {
            return null;
        }
        return this._buildFormula(this.normalizeQueries(queries));
    })

    toggleModal = () => {
        this.setState(state => ({ showModal: !state.showModal}));
    }

    _forest = memoizeOne((queries: Object) => {
        return new Forest(queries.children, this.uuid);
    });

    forest = (queries: ?Object) => {
        return this._forest(queries || this.state.queries);
    };

    onDrop = (item: Object, parentUuid: string, index: number) => {
        this.setState({ queries: {
            ...this.state.queries,
            children: this.forest().move(item, parentUuid, index).nodes
        }});
    }

    deleteQuery = (query: Object) => {
        this.setState({ queries: {
            ...this.state.queries,
            children: this.forest().remove(query).nodes
        }}, this.validation);
    }

    addQuery = (query: Object, parentUuid: string, index: number) => {
        this.setState({ queries: {
            ...this.state.queries,
            children: this.forest().add(query, parentUuid, index).nodes,
        }}, this.validation);
    }

    handleOnChange = (e: Object) => {
        let { value }= e.target;
        const { name, type: fieldType }= e.target;
        const [type, queryUuid, valueNumber] = name.split('.');
        const query = this.forest().get(queryUuid);

        if(type === 'condition') {
            value = value ? 'AND' : 'OR';
        }
        if(valueNumber === '0') {
            value = [value, (query.value || [])[1]];
        }

        if(valueNumber === '1') {
            value = [(query.value || [])[0], value];
        }

        const nextQuery = {
            ...query,
            [type]: value,
        };

        if(name === 'op') {
            const nextNumberOfValues = getNumberOfValues(String(value));
            if(nextNumberOfValues !== getNumberOfValues(query.op)) {
                nextQuery.value = valueToType('', query.fieldType);
                nextQuery.fieldType = fieldType;
            }
        }

        if(fieldType) {
            nextQuery.fieldType = fieldType;
        }

        this.setState({ queries: {
            ...this.state.queries,
            children: this.forest().update(nextQuery).nodes,
        }}, this.validation);
    }

    validation = () => {
        const { queries } = this.state;
        this.setState({ activeSave: this.isValidQueries(queries) });
    }

    isValidQueries = (queries: Object) => {
        let isValid = true;
        queries.children.forEach((val) => {
            if(!isValid) {
                return;
            }
            const isZeroValue = getNumberOfValues(val.op) === 0;
            const isDoubleValue = getNumberOfValues(val.op) === 2;
            const isArrayValue = getNumberOfValues(val.op) === 99;
            const isDefinedField = isDefined(val.field);
            const isDefinedValue = isDefined(val.value);
            if(val.condition) {
                isValid = this.isValidQueries(val);
            }
            else  if(!isZeroValue && (!isDefinedField || !isDefinedValue)) {
                isValid = false;
            } else if(isZeroValue && !isDefinedField) {
                isValid = false;
            } else if(isDoubleValue && (!isDefinedField || !isDefined(val.value[0]) || !isDefined(val.value[1]))) {
                isValid = false;
            } else if(isArrayValue && (!isDefinedField || !isDefined(val.value[0]))) {
                isValid = false;
            }

        });
        return isValid;
    }

    onSave = () => {
        const { queries } = this.state;
        const { name, onChange, changeVariable } = this.props;        
        const value = this.normalizeQueries(queries);

        this.toggleModal();
        const nextTarget = { value, name };
        if(onChange) {
            onChange({ target: nextTarget});
        }
        if(changeVariable) {
            changeVariable(nextTarget);
        }
    }

    render() {
        const { showModal, queries, activeSave } = this.state;
        const { label } = this.props;
        
        return (
            <div>
                <PaperStyled onClick={this.toggleModal}>
                    <Typography variant="caption">{label}</Typography>
                    <Typography variant="h6">{this.buildFormula(queries)}</Typography>
                </PaperStyled>
                <Dialog
                    maxWidth="md"
                    open={showModal}
                    onClose={this.toggleModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Query builder</DialogTitle>
                    <DialogContent>
                        <Typography variant="caption">Formula</Typography>
                        <Typography variant="h6">{this.buildFormula(queries)}</Typography>
                        <TypographyPadded variant="caption">Dragable list</TypographyPadded>
                        <QueryBuilderDnd
                            uuid={this.uuid}
                            onDrop={this.onDrop}
                            addQuery={this.addQuery}
                            deleteQuery={this.deleteQuery}
                            queries={queries}
                            onChange={this.handleOnChange}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.toggleModal} color="primary" variant="text">
                      Close
                        </Button>
                        <Button disabled={!activeSave} onClick={this.onSave} color="primary" autoFocus>
                      Apply
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
};

export default QueryBuilder;
