/* @flow */
import React, { PureComponent } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Typography } from '@mic3/platform-ui';

import { loadTasksByModifiedDate } from 'store/actions/dashboard/dashboardActions';
import { bind } from 'app/utils/decorators/decoratorUtils';
import Widget from 'app/components/atoms/Widget/Widget';
import LinkList from 'app/containers/LandingPage/Widgets/LinkList';
import WidgetFooter from 'app/containers/LandingPage/Widgets/WidgetFooter';
import { get } from 'app/utils/lo/lo';


/**
 * Widget for displaying tasks assigned to the active user
 */
class LastUpdatedTasksWidget extends PureComponent<Object, Object> {
    static propTypes = {
        loadTasksByModifiedDate: PropTypes.func,
        isLoading: PropTypes.bool,
        records: PropTypes.array,
        user: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);
        props.loadTasksByModifiedDate(props.user, { linkOnly: true });
    }

    @bind
    renderFooterContent(){
        const orderBy = { field: 'modifiedDate', direction: 'desc nulls last' };
        return <WidgetFooter orderBy={orderBy}  />;
    }
    @bind
    renderAdditionalInfo(props){
        const modifiedDate =get(props, 'modifiedDate');
        let relativeTime;
        if (modifiedDate){
            relativeTime = moment(modifiedDate).fromNow();
        }
        return <Typography value={0} >{relativeTime || ''}</Typography>;
    }
    render() {
        const { isLoading, records, item } = this.props;
        return (
            <Widget
                title="Last Updated Tasks"
                content={!isLoading && !records?.length ? 'No Results': <LinkList
                    list={records}
                    type="task"
                    renderAdditionalInfo={this.renderAdditionalInfo}
                />}
                loading={isLoading}
                footerContent={this.renderFooterContent}
                {...item}
            />
        );
    }
}

const mapStateToProps: Function = ( state ) => {
    return {
        user: state.user.profile,
        isLoading: state.dashboard.tasksByModifiedDate.isLoading,
        records: state.dashboard.tasksByModifiedDate.records,
    };
};

export default connect(mapStateToProps, { loadTasksByModifiedDate })(LastUpdatedTasksWidget);
