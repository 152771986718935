import React from 'react';
import { Grid, InputAdornment, MdiIcon } from '@mic3/platform-ui';

import styled from 'styled-components';

import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';

const WrapperGrid = styled(Grid)`
    position: relative;
`;

const PrintTemplateScript = ({ details, handleChange, canEdit }) => {
    const { type } = details || {};
    const components = [{
        properties: { header: 'Data Source Script', expanded: true },
        type: 'panel',
        children: [
            {
                'type': 'entityTypeahead',
                'properties': {
                    name: 'primary.print-template/script',
                    label: 'script',
                    filterBy: [
                        {
                            'op': '=',
                            'field': 'active',
                            'value': true
                        }
                    ],
                    entityType: {
                        'id': '17924f9c-cab2-11eb-a102-6fa04c5fb2dc',
                        'uri': 'script',
                        'name': 'Script'
                    },
                    InputProps: {
                        startAdornment: (
                            <InputAdornment position="start">
                                <MdiIcon name="script" size={16} />
                            </InputAdornment>
                        ),
                    }
                }
            },
        ]
    }];

    return type === 'print-template' && (
        <WrapperGrid>
            <FormGenerator
                root={false}
                data={details}
                onChange={handleChange}
                components={components}
                disabled={!canEdit}
            />
        </WrapperGrid>
    );
};

export default PrintTemplateScript;
