/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Avatar } from '@mic3/platform-ui';

import { relationDefinitionsListView } from 'store/actions/entities/relationshipsActions';
import { bind } from 'app/utils/decorators/decoratorUtils';
import withPermissions from 'app/utils/withPermissions';
import { typeTitlesMultiple } from 'app/config/typesConfig';
import { baseRoutes } from 'app/config/routesConfig';

import PageTemplate from 'app/components/templates/PageTemplate';
import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import EntitiesList from 'app/containers/Entities/EntitiesList/EntitiesList';
import ListItem from 'app/components/molecules/List/ListItem';
import ResizableListItem from 'app/components/molecules/VirtualList/ResizableListItem';
import EntityLink from 'app/components/atoms/Link/EntityLink';
import { modulesAndPageTitles } from 'app/config/typesConfig';
import { setDocumentTitle } from 'store/actions/app/appActions';

const VIEW_ID = 'RelationDefinitionsListView';

const listType = 'relationdefinition';

const ListItemStyled = styled(ListItem)`
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    cursor: pointer;
`;

class RelationDefinitionsListView extends PureComponent<Object, Object> {
    static propTypes = {
        relationDefinitionsListView: PropTypes.func.isRequired,
        records: PropTypes.array,
        isLoading: PropTypes.bool,
        totalRecords: PropTypes.number
    };

    static defaultProps = {
        isLoading: false,
        VirtualListProps: {},
        FiltersProps: {}
    };

    searchBar = ['id', 'description', 'relatedDescription', 'type', 'relatedType'];
    defaultOrder = [{ field: 'type', direction: 'desc nulls last' }];

    filterDefinitions = [
        {
            field: 'description',
            type: 'text',
            properties: {
                label: 'Description',
                name: 'description',
                opSelector: true
            }
        },
        {
            field: 'relatedDescription',
            type: 'text',
            properties: {
                label: 'Related description',
                name: 'relatedDescription',
                opSelector: true
            }
        },
        {
            field: 'relatedType',
            type: 'text',
            properties: {
                label: 'Related type',
                name: 'relatedType',
                opSelector: true
            }
        },
        {
            field: 'type',
            type: 'text',
            properties: {
                label: 'Type',
                name: 'type',
                opSelector: true
            }
        },
        {
            field: 'id',
            type: 'uuid',
            properties: {
                label: 'ID',
                name: 'id',
                opSelector: true
            },
            condition: '='
        },
    ];

    componentDidMount() {
        this.props.setDocumentTitle(modulesAndPageTitles.entities.relationDefinition);
    }

    @bind
    onListItemClick(event: Object, id: string) {
        event.stopPropagation();
        this.props.history.push(`${baseRoutes[listType]}/${id}`);
    }

    @bind
    renderComponent({ style, index, data, resize }) {
        const { id, description, relatedDescription, type, relatedType } = data;
        return (
            <ResizableListItem style={style} key={index} index={index} resize={resize} padding={15}>
                {resizeRow => (
                    <ListItemStyled
                        component={<Avatar initials={description} />}
                        title={<EntityLink id={id} type={listType}>{`${description} - ${relatedDescription}`}</EntityLink>}
                        subTitle={`${type} - ${relatedType}`}
                        raised
                        onClick={e => this.onListItemClick(e, id)}
                    />
                )}
            </ResizableListItem>
        );
    }

    render() {
        const { startIndex, isLoading, records, totalRecords } = this.props;
        return (
            <PageTemplate title={typeTitlesMultiple[listType]}>
                <ContentArea>
                    <EntitiesList
                        id={VIEW_ID}
                        disableSettings
                        type={listType}
                        headerTitle={typeTitlesMultiple[listType]}
                        records={records}
                        totalRecords={totalRecords}
                        startIndex={startIndex}
                        isLoading={isLoading}
                        loadData={this.props.relationDefinitionsListView}
                        filterDefinitions={this.filterDefinitions}
                        renderListComponent={this.renderComponent}
                        defaultOrder={this.defaultOrder}
                        searchBar={this.searchBar}
                    />
                </ContentArea>
            </PageTemplate>
        );
    }
}

export default connect(
    (state: Object) => ({
        records: state.entities.relationDefinitions.list.records,
        totalRecords: state.entities.relationDefinitions.list.count,
        isLoading: state.entities.relationDefinitions.list.isLoading,
        startIndex: state.entities.relationDefinitions.list.startIndex
    }),
    { relationDefinitionsListView, setDocumentTitle }
)(withPermissions(RelationDefinitionsListView, listType));
