import React, { memo, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { muiTheme } from 'app/themes/materialUi';
import PropTypes from 'prop-types';
import { Grid, Divider, Tooltip, IconButton, MdiIcon, InputBase } from '@mic3/platform-ui';

import history from 'store/History';
import { relatedEntityViewMore } from 'app/config/relatedEntityConfig';
import { getEntityUrl } from 'app/utils/entity/entityUtils';

import RelatedEntityMenu from 'app/components/RelatedEntity/RelatedEntityMenu';
import RelatedEntityAutocomplete from 'app/components/RelatedEntity/RelatedEntityAutocomplete';

const SearchWrapper = styled.div`
    position: sticky;
    height: 50px;
    top: 0px;
    z-index: 1;
    background: ${({ theme }) => theme.material.colors.background.default};
`;


const DividerStyled = styled(Divider)`
    margin: 0 16px !important;
`;

const SearchInput = styled(InputBase)`
    margin-left: 16px;

    & .MuiInputBase-input {
        font-size: 14px;
    }
`;

const Actions = styled.div`
    display: flex;
    flex-wrap: nowrap;
`;

const RelatedEntityToolbar = ({ 
    showToolbar, 
    onSelect,
    onSearch, 
    onCreate, 
    onHideInactive, 
    onSort, 
    onExpand,
    showToastr,
    relatedEntity,
    canAdd
}) => {
    const [queryString, setQueryString] = useState('');
    const [value, setRelatedEntity] = useState(relatedEntity);
    const [isSearching, setIsSearching] = useState(false);
    const [isInactive, setIsInactive] = useState(true);
    const [isExpand, setIsExpand] = useState(false);
    const [sort, setSort] = useState('');
    

    const handleToggleSearch = useCallback(() => {
        setIsSearching(prevState => !prevState);
        setQueryString('');
        onSearch('');
    }, [onSearch]);

    const handleOnChange = useCallback((e) => {
        const { value } = e.target;
        setRelatedEntity(value);
        onSelect(value);
    }, [onSelect]);

    const handleChange = useCallback((e) => {
        const { value } = e.target;
        setQueryString(value);
        onSearch(value);
    }, [onSearch]);

    const handleCreate = useCallback(() => {
        onCreate();
    }, [onCreate]);
    
    const handleItemClick = useCallback((label) => {
        if (!value) {
            showToastr({ severity: 'error', detail: 'Please select a tree template' });
            return;
        }
        switch (label) {
            case 'Edit tree template':
                history.push(getEntityUrl(value.id, 'treetemplate'));
                break;
            case 'Hide disabled entities':
            case 'Show disabled entities':
                setIsInactive(!isInactive);
                onHideInactive(!isInactive);
                break;
            case 'Sort by name (A-Z)':
            case 'Sort by name (Z-A)':
                let sortValue = '';
                if (!sort) {
                    sortValue = 'desc';
                } else {
                    sortValue = sort === 'asc' ? 'desc' : 'asc';
                }
                setSort(sortValue);
                onSort(sortValue);
                break;
            case 'Expand all':
                setIsExpand(!isExpand);
                onExpand(!isExpand);
                break;
            default:
                break;
        }
    }, [value, isInactive, sort, onSort, onHideInactive, onExpand, isExpand, showToastr]);
    
    useEffect(() => {
        setRelatedEntity(relatedEntity);
    }, [relatedEntity]);

    return (
        <SearchWrapper>
            <DividerStyled />
            {showToolbar && (
                <>
                    <Grid container alignItems='center'>
                        <Grid container wrap='nowrap' alignItems='center' justify='space-between'>
                            {!isSearching ? (
                                <RelatedEntityAutocomplete
                                    entityType='treetemplate'
                                    name='relatedEntity'
                                    onChange={handleOnChange}
                                    value={value}
                                />
                            ) : (
                                <SearchInput fullWidth margin='none' placeholder='Search...' value={queryString} onChange={handleChange} />
                            )}
                            <Actions>
                                <Tooltip title={isSearching ? 'Close Search' : 'Search'}>
                                    <IconButton onClick={handleToggleSearch}>
                                        <MdiIcon name={isSearching ? 'close' : 'magnify'} color={muiTheme.colors.appHeader.iconColor} />
                                    </IconButton>
                                </Tooltip>
                                {canAdd && (
                                    <Tooltip title='Create'>
                                        <IconButton onClick={handleCreate}>
                                            <MdiIcon name='plus' color={muiTheme.colors.appHeader.iconColor} />
                                        </IconButton>
                                    </Tooltip>
                                )}
                                <RelatedEntityMenu
                                    tooltipLabel='More Actions'
                                    iconName='tune'
                                    items={relatedEntityViewMore(sort, isInactive)}
                                    onItemClick={handleItemClick}
                                />
                            </Actions>
                        </Grid>
                    </Grid>
                    <DividerStyled />
                </>
            )}
        </SearchWrapper>
    );
};

RelatedEntityToolbar.propTypes = {
    showToolbar: PropTypes.bool,
    onSelect: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    onHideInactive: PropTypes.func.isRequired,
    onSort: PropTypes.func.isRequired,
    onExpand: PropTypes.func.isRequired,
    showToastr: PropTypes.func.isRequired
};

export default memo(RelatedEntityToolbar);