/* @flow */

import { gql } from '@apollo/client';
import loadWorkspaceDetailsCommonQuery from 'graphql/workspaces/loadWorkspaceDetailsCommonQuery';

export default gql`
    mutation updateWorkspaceMutation($record:  WorkspaceUpdateInput!) {
        result: updateWorkspace(record: $record) {
            ${loadWorkspaceDetailsCommonQuery}
        }
    }
`;
