import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { Avatar, Typography } from '@mic3/platform-ui';
import { Grid,  MdiIcon } from '@mic3/platform-ui';
import { cut } from 'app/utils/string/string-utils';

const AppWrapperStyled = styled(Grid)`
    margin: 1rem !important;
    max-width: ${({ ismobile }) => !ismobile ? '60px !important' : '45px !important'};
    align-items: center;
    cursor: pointer;
`;
const AvatarStyled = styled(Avatar)`
    border-radius: 3px !important;
    border: 1px solid rgba(104, 195, 247, 1);
    position: relative !important;
    overflow: visible !important;
    background-color: transparent !important;
    ${({ ismobile }) => !ismobile ? 'width: 60px !important' : ''};
    ${({ ismobile }) => !ismobile ? 'height: 60px !important' : ''};
`;
const AppName = styled(Typography)`
    max-width: ${({ ismobile }) => !ismobile ? '60px !important' : '45px !important'};
    text-overflow: ellipsis;
    overflow: hidden;
`;

const MdiIconStyled = styled(MdiIcon)`
    line-height: 17px !important;
    color: ${({theme})=> theme.material.colors.text.primary};
`;

const PipelineLeftSidebarIcon = ({ iconType, iconColor, iconName, isRoot = true, onClick, index, title, onDelete }) => {
    const isMobile = useSelector(
        state => state.global.isMobile,
    );
    return (
        <AppWrapperStyled ismobile={isMobile?1:0}  onClick={onClick} item container direction="column" justify="center" sm={1} xs={4}>
            <AvatarStyled ismobile={isMobile?1:0} variant="rounded" >
                <MdiIconStyled type={iconType || 'mdi'} name={iconName || 'asterisk'} size={isMobile ? 15 : 20}/>
            </AvatarStyled>
            <AppName ismobile={isMobile?1:0} variant="caption">{isMobile ? cut(title, 14, true) : cut(title, 23, true)}</AppName>
        </AppWrapperStyled>
    );
};

export default PipelineLeftSidebarIcon;
