import { gql } from '@apollo/client';

export default gql`
query get_user_offline_sessions {
    result: offlineSessions {
      id
      start
      lastAccess
    }
  }
`;