/* @flow */

import { DOMParser } from '@xmldom/xmldom';
import memoize from 'memoize-one';

import defaultSettings from 'app/utils/designer/process/settings/metadata/defaultSettings';
import processSettings from 'app/utils/designer/process/settings/metadata/processSettings';
import startSettings from 'app/utils/designer/process/settings/metadata/startSettings';
import taskSettings from 'app/utils/designer/process/settings/metadata/taskSettings';
import endSettings from 'app/utils/designer/process/settings/metadata/endSettings';
import scriptSettings from 'app/utils/designer/process/settings/metadata/scriptSettings';
import userTaskSettings from 'app/utils/designer/process/settings/metadata/userTaskSettings';
import sequenceFlowSettings from 'app/utils/designer/process/settings/metadata/sequenceFlowSettings';

import timerEventDefinitionSettings from 'app/utils/designer/process/settings/metadata/events/timerEventDefinitionSettings';
import signalEventDefinitionSettings from 'app/utils/designer/process/settings/metadata/events/signalEventDefinitionSettings';
import messageEventDefinitionSettings from 'app/utils/designer/process/settings/metadata/events/messageEventDefinitionSettings';

import { get } from 'app/utils/lo/lo';
import { buildSettingsPanel } from 'app/utils/designer/form/settings/formFieldSettingsUtils';
import { removeSpaces } from 'app/utils/designer/process/processDesignerUtils';

const getProcessElementSettings = (type: string) => {
    if (!type) return null;
    switch (type) {
        case 'bpmn:SequenceFlow': return sequenceFlowSettings;
        case 'bpmn:Process':
        case 'bpmn:Collaboration':
            return processSettings;
        case 'bpmn:StartEvent': return startSettings;
        case 'bpmn:EndEvent': return endSettings;
        case 'bpmn:ScriptTask':
        case 'bpmn:ServiceTask':
            return scriptSettings;
        case 'bpmn:UserTask': return userTaskSettings;
        case 'bpmn:Task':
        case 'bpmn:ManualTask':
        case 'bpmn:SendTask':
        case 'bpmn:ReceiveTask':
        case 'bpmn:BusinessRuleTask':
        case 'bpmn:CallActivity':
        case 'bpmn:SubProcess':
            return taskSettings;
        case 'timerEventDefinition':
            return timerEventDefinitionSettings;
        case 'signalEventDefinition':
            return signalEventDefinitionSettings;
        case 'messageEventDefinition':
            return messageEventDefinitionSettings;
        default: return defaultSettings;
    }
};

export const getElementSettingPanelComponents = (type: string, settingsValues: Object) => {
    const settings = getProcessElementSettings(type);
    const panels = settings.panels(settingsValues);
    return panels.map(panel => buildSettingsPanel(panel, settingsValues));
};

const defaultsMap = {};
export const getProcessElementsDefaults = (type: string) => {
    if (!defaultsMap[type]) {
        const settings = getProcessElementSettings(type);
        defaultsMap[type] = get(settings, 'defaults') || {};
    }
    return defaultsMap[type];
};

const processDefinitionMap = {};
export const getProcessDefinition = (type) => {
    if (!processDefinitionMap[type]) {
        processDefinitionMap[type] = { type, defaults: getProcessElementsDefaults(type) };
    }
    return processDefinitionMap[type];
};

export const getElementSettingValues = memoize((element, type, process) => {
    const settings = getProcessElementSettings(type);
    const props = settings.panels().map(({ children }) => children.map(({ properties: { name }}) => name)).flat().filter(Boolean);
    const values = props.reduce((accum, name) => {
        if(name === 'elementId') {
            accum[name] = { id: element.getAttribute(name) };
            accum['id'] = element.getAttribute('id');
            accum['elementId'] ={id: element.getAttribute('id')};
            return accum;
        }
        if(!name.includes('.')) {
            accum[name] = element.getAttribute(name);
        }
        return accum;
    }, { type: element.type });

    if (props.includes('multiinstance.multiInstanceType')) {
        const doc = new DOMParser().parseFromString(removeSpaces(process.primary.definition));
        const parenElement =  doc.getElementById(element.getAttribute('id'));
        const miltiInstance = parenElement.getElementsByTagName('multiInstanceLoopCharacteristics')[0];

        if(miltiInstance) {
            values.multiinstance = {};
            values.multiinstance.multiInstanceType = miltiInstance.getAttribute('flowable:isSequential');
            values.multiinstance.collection = miltiInstance.getAttribute('flowable:collection') || '';
            values.multiinstance.elementVariable = miltiInstance.getAttribute('flowable:elementVariable') || '';

            if(miltiInstance.childNodes) {
                Array.from(miltiInstance.childNodes).forEach((elmnt) => {
                    values.multiinstance[elmnt.nodeName] = elmnt.firstChild.data;
                });
            }
        }
    }

    if (props.includes('script')) {
        const doc = new DOMParser().parseFromString(removeSpaces(process.primary.definition));
        const parenElement =  doc.getElementById(element.getAttribute('id'));
        const script = parenElement.getElementsByTagName('script')[0];
        const flowableFields = parenElement.getElementsByTagName('flowable:field');
        values.script = { type: 'bpmn', script: null };
        Array.from(flowableFields || []).forEach((field) => {
            if (String(field).includes('flowable:field name="scriptId"')) {
                const scriptId = get(String(field).match(/CDATA\[(.*)\]\]/), '[1]', null)
                || get(String(field).match(/>([\da-f]{8}-[\da-f]{4}-[\da-f]{4}-[\da-f]{4}-[\da-f]{12})</), '[1]', null);
                values.script = { type: 'nodeJs', script: scriptId };
            }
        });
        if(script && script.firstChild) {
            values.script = { type: 'bpmn', script: script.firstChild.data};
        }
    }

    if (props.includes('flowCondition')) {
        const doc = new DOMParser().parseFromString(removeSpaces(process.primary.definition));
        const parenElement =  doc.getElementById(element.getAttribute('id'));
        const flowCondition = get(parenElement, 'firstChild.firstChild.data');
        values.flowCondition = { flowCondition };
    }

    if (props.includes('documentation')) {
        const doc = new DOMParser().parseFromString(process.primary.definition);
        const parenElement =  doc.getElementById(element.getAttribute('id'));
        const flowableFields = parenElement.getElementsByTagName('flowable:field');
        values.documentation = null;
        Array.from(flowableFields || []).forEach((field) => {
            if (String(field).includes('name="documentation"')) {
                values.documentation = (
                    get(String(field).match(/CDATA\[([\s\S]*?)\]\]\>/), '[1]', '') ||
                    get(String(field).match(/<flowable:string>([\s\S]*?)<\/flowable:field>/), '[1]', '')
                ).replaceAll(/ {2,}/gm, '');
            }
        }); 
    }

    if (props.includes('dateTimerEvent')) {
        const doc = new DOMParser().parseFromString(removeSpaces(process.primary.definition));
        const parentElement =  doc.getElementById(element.getAttribute('id'));
        const tagName = buildEventType(type, element);
        const timerEventElmnt = parentElement.getElementsByTagName(tagName)[0];
        let timeEventType = null;
        let timeEventValue = '';
        if(timerEventElmnt) {
            Array.from(timerEventElmnt.childNodes).forEach((child) => {
                if(['timeDate', 'timeDuration', 'timeCycle'].includes(child.tagName)) {
                    timeEventType = child.tagName;
                    timeEventValue = get(child, 'firstChild.data');
                }
            });
            if(timeEventValue) {
                values['dateTimerEvent'] = { type: timeEventType, date: timeEventValue };
            }
        }

    }

    if(type === 'bpmn:Group') {
        const categoryValueId = element.getAttribute('categoryValueRef');
        if(categoryValueId) {
            const doc = new DOMParser().parseFromString(removeSpaces(process.primary.definition));
            const categoryElement = doc.getElementById(categoryValueId);
            values['name'] = categoryElement.getAttribute('value') || '';
        }
    }

    if(type === 'bpmn:TextAnnotation') {
        const elmntId = element.getAttribute('id');
        if(elmntId) {
            const doc = new DOMParser().parseFromString(removeSpaces(process.primary.definition));
            const annotationElement = doc.getElementById(elmntId);
            const val = annotationElement?.firstChild?.firstChild?.nodeValue;
            if(val) {
                values['name'] = val;
            }
        }
    }

    if(values['affectli:formVersion']) {
        values['affectli:formVersion'] = values['affectli:formVersion'] ? Number(values['affectli:formVersion']) : '';
    }
    
    return values;
});

export const isElementCreated = memoize((element, process) => {
    if(!element) {
        return false;
    }
    const doc = new DOMParser().parseFromString(process.primary.definition);
    return !!doc.getElementById(element.getAttribute('id'));
});

const eventsList = ['timerEventDefinition', 'messageEventDefinition', 'signalEventDefinition'];
export const buildEventType = memoize((type, element) => {
    if(!element.childNodes) {
        return type;
    }
    let newType = type;
    Array.from(element.childNodes).forEach((child) => {
        if(eventsList.includes(child.tagName)) {
            newType = child.tagName;
        }
    });
    return newType;
});
