/* @flow */

import Immutable from 'app/utils/immutable/Immutable';
import { LOAD_AVATAR_STARTED, LOAD_AVATAR } from 'store/actions/designer/designerScriptActions';

/**
 * Generic Entity reducer.
 *
 * @param state - the current Redux state
 * @param action - the dispatched action
 * @returns the new Redux state
 */
const reducer = (
    state: Object = Immutable({}),
    action: Object
): Object => {
    const { type, meta, payload, error } = action;
    switch (type) {
        case LOAD_AVATAR_STARTED:
            return Immutable({
                ...state,
                [meta.id]: { isLoading: true },
            });

        case LOAD_AVATAR:
            if (error) {
                return Immutable({
                    ...state,
                    [meta.id]: { isLoading: false },
                });
            }
            return Immutable({
                ...state,
                [meta.id]: { isLoading: false, loadedAt: Date.now(), ...payload, type: 'script' },
            });

        default:
            return state;
    }
};

export default reducer;
