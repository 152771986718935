import React, { useCallback, memo, useMemo, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Divider, Tooltip, MdiIcon } from '@mic3/platform-ui';
import { useDispatch, useSelector } from 'react-redux';
import { getEntityUrl } from 'app/utils/entity/entityUtils';
import TypeIcon from 'app/components/atoms/TypeIcon/TypeIcon';
import ListItem from 'app/components/molecules/List/ListItem';
import ButtonIcon from 'app/components/molecules/ButtonIcon/ButtonIcon';
import GridEntityId from 'app/components/organisms/GridView/GridRenderers/GridEntityId';
import { setSelectedBoard } from 'store/actions/abox/boardActions';
import { saveBoardPreferences } from 'store/actions/admin/usersActions';
import { get } from 'app/utils/lo/lo';

const ListItemStyled = styled(ListItem)`
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    .ItemColumn__ItemColumnStyle-ltifab-0 {
        cursor: pointer !important;
    }
    @media (max-width: 1100px) {
        padding-right: 2rem;
    }
    ${({ disabled }) => (disabled ? 'opacity: 0.1;' : '')}
    ${({ isSelected, theme }) => (isSelected ? `background: ${theme.material.colors.itemActive};` : `background-color: ${theme.material.colors.background.default};`)}

    box-shadow: none;
`;
const ItemWrapper = styled.div`
    display: flex;
    align-items: center;
    .title {
        margin: 0;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .sub-title {
        margin: 0;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: rgba(255, 255, 255, 0.38);
        mix-blend-mode: normal;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
`;

const StyledDivider = styled(Divider)`
    margin: 4px 8px !important;
    max-height: 16px !important;
`;

const TitleStyled = styled.p`
    margin: ${({ margin }) => (margin ? `${margin} !important` : '0')};
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: ${({theme})=> theme.material.colors.text.primary};
`;
const BoardItems = ({ items, betaBoards }) => {
    const dispatch = useDispatch();
    const selectedBoard = useSelector((state) => state.abox.boards.boardsOption.selectedBoard);
    const preferences = useSelector((state) => state.user.preferences);

    const handleBoardSelect = useCallback(
        (board) => {
            if(selectedBoard && board.id === selectedBoard.id) return;
            const updatedPrefs = { ...preferences };
            const selectBetaBoard = get(updatedPrefs,`betaBoards.${board.id}`);
            if (betaBoards) {
                updatedPrefs.betaBoards = {...updatedPrefs.betaBoards,
                    [board.id]: selectBetaBoard ? selectBetaBoard : board, 
                    lastUsedBoardId: selectBetaBoard ? selectBetaBoard.id : board.id
                };
            }else {
                updatedPrefs.boards = { board };
            }
            dispatch(saveBoardPreferences(updatedPrefs));
            dispatch(setSelectedBoard(betaBoards && selectBetaBoard ? selectBetaBoard : board ));
        },
        [dispatch, preferences, betaBoards, selectedBoard]
    );

    const buildTree = useMemo(() => {
        return items.map((item, index) => (
            <Fragment key={index}>
                {item.id ? (
                    <ListItemStyled onClick={() => handleBoardSelect(item)}
                        isSelected={selectedBoard?.id === item.id}
                        component={<ButtonIcon type={'af'} icon={'Kanban'} />}
                        title={
                            <ItemWrapper>
                                <StyledDivider orientation='vertical' flexItem />
                                {item.iconName ? (
                                    <MdiIcon color={item.iconColor} name={item.iconName} type={item.iconType} />
                                ) : (
                                    <TypeIcon type={'boards'} />
                                )}
                                <Tooltip title={item.name}>
                                    <TitleStyled className='title' margin='0 0 0 10px'>
                                        {item.name}
                                    </TitleStyled>
                                </Tooltip>
                            </ItemWrapper>
                        }
                        subTitle={<GridEntityId value={getEntityUrl(item.id, item.type)} label={item.id} valueId={item.id} />}
                        raised
                        itemPadding={'.2rem'}
                    />
                ) : null}
            </Fragment>
        ));
    }, [items, selectedBoard?.id, handleBoardSelect]);

    return <> {buildTree && buildTree}</>;
};

BoardItems.propTypes = {
    items: PropTypes.array.isRequired,
};

BoardItems.defaultProps = {
    items: [],
};

export default memo(BoardItems);
