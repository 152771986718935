/* @flow */

import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';


import GlobalSidebar from 'app/components/organisms/GlobalSidebar/GlobalSidebar';
import GlobalLeftPanel from 'app/components/organisms/GlobalLeftPanel/GlobalLeftPanel';
import FullHeight from 'app/components/atoms/FullHeight/FullHeight';
import NotificationsBar from 'app/components/molecules/NotificationsBar/NotificationsBar';
import AppContent from 'app/components/organisms/AppContent/Content';
import Header from 'app/components/organisms/AppHeader/Header';
import AppNavigation from 'app/components/organisms/AppNavigation/AppNavigation';
import Toastr from 'app/containers/Toastr/Toastr';
import { ChildrenProp } from 'app/utils/propTypes/common';
import { closeNav, toggleNav, openNav, toggleNotifications } from 'store/actions/app/appActions';
import { fetchBroadcastNotifications, markBroadcastRead } from 'store/actions/broadcasts/broadcastsActions';
import { loadNotifications } from 'store/actions/app/appActions';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import affectliSso from 'app/auth/affectliSso';

const GlobalThemeWrapper = styled(FullHeight)`
    a {
        color: ${({ theme }) => theme.base.linkColor};
    }
    ${({ isSidebarResizing }) => {
    return isSidebarResizing ? 'user-select: none; cursor: col-resize;' : '';
}};

   *::-webkit-scrollbar {
       width: 9px;
       height: 9px;
   }
   *::-webkit-scrollbar-thumb {
       border-radius: 5px;
       box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
       background-color: rgba(255,255,255,.2);
       min-height: 65px;
   }
   *::-webkit-scrollbar-thumb :hover{
       background-color: rgba(255,255,255,.3);
   }
   *::-webkit-scrollbar-track {
       box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
       border-radius: 0;
       background-color: transparent;
   }

   div.global-template-sidebar {
     display: flex;
     flex-direction: column;
     grid-area: gRightSidebar;
     position: absolute;
     bottom: 0; 
     right: 0;
     top: 0;
     width: 100%;
     max-height: 100vh;
     z-index: 1098 !important;
     height: ${({ theme, isMobile }) =>  !isMobile ? `calc(100vh - ${theme.header.height})` : `100%`};
   }
   div.global-template-leftPanel {
     display: flex;
     flex-direction: column;
     grid-area: gLeftSidebar;
     position: absolute;
     bottom: 0; 
     right: 0;
     top: 0;
     width: 100%;
     max-height: 100%;
     z-index: 1098 !important;
     height: ${({ theme, isMobile }) =>  !isMobile ? `calc(100vh - ${theme.header.height})` : `100%`};
   }
`;

const CssGridLayout = styled.div`
  display: grid;
  grid-template-columns: 0 auto 1fr auto;
  grid-template-areas:
      "gTopbar gTopbar gTopbar gTopbar"
      "gNav gLeftSidebar gContent gRightSidebar";
  height: 100%;
  overflow: auto;
`;

const DrawerWrapper = styled.div`
    position: relative;
    z-index: 1098 !important;
`;

/**
 * Layout that controls the page
 */
class GlobalTemplate extends PureComponent<Object> {
    static propTypes = {
        children: ChildrenProp,
        app: PropTypes.object,
        activeBroadcasts: PropTypes.array,
        loadNotifications: PropTypes.func.isRequired,
        fetchBroadcastNotifications: PropTypes.func.isRequired,
        markBroadcastRead: PropTypes.func.isRequired,
        toggleNav: PropTypes.func.isRequired,
        openNav: PropTypes.func.isRequired,
        toggleNotifications: PropTypes.func.isRequired,
        isChatRoomOpen: PropTypes.bool,
        isSidebarResizing: PropTypes.bool,
        license: PropTypes.object.isRequired
    };

    interval;

    /**
     * @override
     */
    componentDidMount() {
        this.props.fetchBroadcastNotifications();
        this.interval = setInterval(() => {
            if (window.navigator.onLine && !affectliSso.getIsInvalidSession()) {
                this.props.loadNotifications();
            }
        }, 60000);
    }

    componentDidUpdate(prevProps){
        const documentTitle = this.props.app.documentTitle;
        const prevDocumentTitle = prevProps.app.documentTitle;
        if(documentTitle && documentTitle !== prevDocumentTitle){
            this.updateDocumentTitle(documentTitle);
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    @bind
    @memoize()
    markNotificationRead(id){
        this.props.markBroadcastRead(id).then(this.props.fetchBroadcastNotifications);
    };

    @bind
    @memoize()
    buildMessages(activeBroadcasts){
        return (activeBroadcasts || []).map(({ id, message, actionType, actionData }) => {
            return { id, text: message, actionType, actionData };
        });
    };


    @bind
    updateDocumentTitle(title){
        if(!title) return;
        try{
            return document.title = title;
        }catch(e){}
    }


    /**
     * Render our page template
     */
    render() {
        const { children, activeBroadcasts, isSidebarResizing, isLeftPanelOpen, isLeftPanelResizing, version, user, isMobile } = this.props;
        return (
            <Fragment>
                <Toastr>
                    <GlobalThemeWrapper id="global-template" isSidebarResizing={isSidebarResizing || isLeftPanelResizing} isMobile={isMobile}>
                        <DrawerWrapper id="drawers" className="no-print" />
                        <CssGridLayout id="global-template-grid">
                            { !this.props.app.isHeaderDisabled &&
                                <Header className="no-print" openMenu={this.props.toggleNav} openNotifications={this.props.toggleNotifications} openChat={this.props.toggleChat} />
                            }
                            <NotificationsBar
                                messages={this.buildMessages(activeBroadcasts)}
                                notificationRead={this.markNotificationRead}
                                version={version}
                                profile={user?.profile}
                            />
                            <AppNavigation className="no-print" closeMenu={this.props.closeNav} openMenu={this.props.openNav} isLeftOpen={this.props.app.isNavOpen} />
                            <GlobalLeftPanel className="no-print" />
                            <AppContent isNavOpen={this.props.app.isNavOpen} isLeftPanelOpen={isLeftPanelOpen}>                                
                                {children}
                            </AppContent>
                            <GlobalSidebar />
                        </CssGridLayout>
                    </GlobalThemeWrapper>
                </Toastr>
            </Fragment>
        );
    }
}


const mapStateToProps: Object = state => ({
    app: state.app,
    user: state.user,
    isLeftPanelOpen: state.leftPanel.state.isOpen,
    activeBroadcasts: state.broadcasts.active.records,
    isSidebarResizing: state.sidebar.isResizing,
    isLeftPanelResizing: state.leftPanel.state.isResizing,
    license: state.app.license,
    version: state.app.version,
    isMobile: state.global.isMobile
});

const mapDispatchToProps = {
    toggleNav,
    openNav,
    toggleNotifications,
    loadNotifications,
    fetchBroadcastNotifications,
    markBroadcastRead,
    closeNav
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GlobalTemplate));
