import React from 'react';
import PropTypes from 'prop-types';

import { MdiIcon, Button } from '@mic3/platform-ui';
import { createEvent } from 'app/utils/http/event';
import UploadButton from 'app/components/molecules/UploadButton/UploadButton';
import Download3DSvgGeom from 'app/containers/Maps/EntityAboutMap/Download3DSvgGeom.js';
import { isModelUploaded } from 'app/utils/maps/layer/layerUtils';

const Upload3dModel = (props: Object) => {
    const { editable, disabled, details } = props || {};
    const isModel = isModelUploaded(details, details.primaryClass);
    return (
        <div>
            {isModel && 
            <Download3DSvgGeom {...props} fileType='is3d' 
                deleteFile={props.deleteFile}
                downloadFileAction={props.downloadFileAction}
            />}
            {!isModel && <UploadButton accept={ ['.glb','.gltf']} onSelect={(file) => {
                const name = '3dModel';
                const event = createEvent('change', { name: name, value: file });
                props.onChange && props.onChange(event);
            }}>
                {onClick => (
                    <Button variant="text" onClick={onClick} disabled={disabled || !editable}>
                        <MdiIcon  name="upload" />
                        Upload 3d file
                    </Button>
                )}
            </UploadButton>
            }
        </div>
    );
};

Upload3dModel.propTypes = {
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Upload3dModel;
