// @flow

import React, { forwardRef, memo } from 'react';
import styled from 'styled-components';
import { IconButton, Typography } from '@mic3/platform-ui';

import Icon from 'app/components/atoms/Icon/Icon';

const CardIconBase = styled.div`
    display: inline-block;

    &.MuiIconButton-edgeStart {
        margin-left: -8px;
    }
`;

const IconButtonStyled = styled(IconButton)`
    padding: 8px !important;
`;

const CustomIcon = styled.img`
    width: 16px;
`;

const CardIconButton = forwardRef(({ 
    count, 
    children, 
    className,
    iconProps,
    ...otherProps 
}: Object, ref: any) => {
    const parseBadgeValue = (!isNaN(count) && count) || count === 0 ? count.toLocaleString() : '';

    return (
        <CardIconBase className={className} ref={ref}>
            <IconButtonStyled {...otherProps}>
                {iconProps.type !== 'custom' ? (
                    <Icon {...iconProps} size="sm" />
                ) : (
                    <CustomIcon src={iconProps.name} alt={iconProps.label} />
                )}
            </IconButtonStyled>
            {parseBadgeValue && <Typography variant="caption" display="inline">
                {parseBadgeValue}
            </Typography>}
        </CardIconBase>
    );
});

export default memo(CardIconButton);
