/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Textarea from 'app/containers/Designer/Form/components/Textarea';
import Layout from 'app/components/Designer/Layout';
import { get } from 'app/utils/lo/lo';
import { bind } from 'app/utils/decorators/decoratorUtils';
import { prettifyXml } from 'app/utils/designer/process/processDesignerUtils';

class ProcessDesignerXML extends PureComponent<Object, Object> {

    static propTypes = {
        process: PropTypes.object.isRequired,
        saveEditorState: PropTypes.func.isRequired,
        readOnly: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        this.state = {
            xml: prettifyXml(get(props, 'process.primary.definition', '')),
        };
    }

    componentDidUpdate(prevProps) {
        const { definition: xml } = get(this.props.process, 'primary', {});
        const { definition: prevXml } = get(prevProps.process, 'primary', {});
        if(xml !== prevXml) {
            this.setState({ xml });
        }
    }

    @bind
    handleOnChange(evnt) {
        const { value: definition } = evnt.target;
        this.props.saveEditorState({ primary: { definition }});
    }

    render() {
        const { readOnly, breadcrumbLine, canEdit, tabs, secondaryActions, actions, afterRightButton } = this.props;
        const { xml } = this.state;
        return (
            <Layout
                tabs={tabs}
                TabsToolbarContent={secondaryActions}
                ToolbarContent={actions}
                leftToolbarContent={breadcrumbLine}
                afterRightButton={afterRightButton}
                disableRightMenu
                content={
                    <>
                        <Textarea
                            disabled={readOnly || !canEdit}
                            value={xml}
                            onChange={this.handleOnChange}
                            useCodeEditor
                            mode="xml"
                        />
                    </>
                }
                height={'calc(100vh - 145px)'}
            />
        );
    }
}

export default ProcessDesignerXML;
