/* @flow */

import { gql } from '@apollo/client';

export default gql`
mutation exportEntitiesMutation(
    $name: String
    $description: String
    $type: String!
    $filterBy: [JSON]
    $excludeBy: [JSON]
    $orderBy: [JSON]
    $startIndex: Int
    $stopIndex: Int
    $fields: [String]!
) {
  result: exportEntities(
    name: $name
    description: $description
    type: $type
    filterBy: $filterBy
    excludeBy: $excludeBy
    orderBy: $orderBy
    startIndex: $startIndex
    stopIndex: $stopIndex
    fields: $fields
  ) {
    id
    type
    name
  }
}
`;
