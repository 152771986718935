/* @flow */
import React from 'react';

import RelationSidebar from 'app/containers/Sidebars/RelationSidebar';

import {
    buildSidebar as buildSidebarAction,
    closeSidebar as closeSidebarAction,
    setOnBack
} from 'store/actions/sidebar/sidebarActions';

export const openRelationSidebar = ({ relation, title, reloadList, ...rest }) => async (dispatch, getState) => {
    return buildSidebarAction({
        title,
        content: <RelationSidebar relation={relation} reloadList={reloadList} setOnBack={(...args) => setOnBack(...args)(dispatch, getState)} {...rest}/>,
        isOpen: true,
    })(dispatch, getState);
};

export const closeRelationSidebar = closeSidebarAction;
