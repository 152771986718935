/* @flow */

import { gql } from '@apollo/client';

export default gql`
mutation startProcessMutation($processDefinitionId: ID!, $variables: [BpmnVariableInput], $outcome: String) {
  process: startProcess(processDefinitionId: $processDefinitionId, variables: $variables, businessKey: $outcome) {
    id
    name
    primary
    tasks {
      id
      name
      assignee {
        id
      }
    }
  }
}
`;
