/* @flow */

import { gql } from '@apollo/client';
import graphicCommonQueryFields from 'graphql/common/graphicCommonQueryFields';

export default gql`
    query graphicDetailQuery($id: ID!) {
        result: graphic(id: $id) {
            ${graphicCommonQueryFields}
        }
    }
`;
