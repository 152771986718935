/* @flow */

import { gql } from '@apollo/client';

export default gql`
    mutation createPersonMutation($record: PersonCreateInput!) {
        result: createPerson(record: $record) {
            id
        }
    }
`;
