/* @flow */
import React from 'react';

import WorkspaceSidebar from 'app/containers/Sidebars/WorkspaceSidebar';

import {
    buildSidebar as buildSidebarAction,
    closeSidebar as closeSidebarAction
} from 'store/actions/sidebar/sidebarActions';

export const openWorkspaceSidebar = ({ id, title, ...rest }) => async (dispatch, getState) => {
    return buildSidebarAction({
        title,
        content: <WorkspaceSidebar id={id} title={title} {...rest} />,
        isOpen: true,
    })(dispatch, getState);
};

export const closeWorkspaceSidebar = closeSidebarAction;
