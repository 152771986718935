/* @flow */
import { gql } from '@apollo/client';

export default (type) => {
    let operation = 'entityChatDeleteFile';

    if (type === 'class') {
        operation = 'classChatDeleteFile';
    } else if (type === 'team') {
        operation = 'teamChatDeleteFile';
    }

    return gql`
        mutation deleteChatAttachmentMutation($id: ID!, $messageId: String!) {
            result: ${operation}(id: $id, messageId: $messageId, ${!['class', 'team'].includes(type) ? `, type: "${type}"` : ''})
        }
    `;
};