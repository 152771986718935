/* @flow */

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Avatar, Divider, Typography, ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction, IconButton } from '@mic3/platform-ui';

import { getStr } from 'app/utils/utils';
import history from 'store/History';
import { openEntitySidebar } from 'store/actions/entities/entitySidebarActions';
import { openProcessSidebar } from 'store/actions/abox/processSidebarActions';
import { openClassSidebar } from 'store/actions/entities/classSidebarActions';
import { openTeamSidebar } from 'store/actions/entities/teamSidebarActions';
import { showToastr } from 'store/actions/app/appActions';
import { openTaskSidebar } from 'store/actions/abox/taskSidebarActions';
import { openWorkspaceSidebar } from 'store/actions/entities/workspaceSidebarActions';
import { openEventSidebar } from 'store/actions/stream/eventsSidebarActions';
import { openEventsActionsSidebar } from 'store/actions/stream/eventsActionsSidebarActions';
import { openEventTypeSidebar } from 'store/actions/entities/eventTypeSidebarActions';
import { getAttachmentUrl } from 'app/utils/attachments/attachmentsUtils';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { getFieldByType } from 'app/utils/designer/form/fieldUtils';
import { getEntityUrl, getAttribute, goToEntityDetails } from 'app/utils/entity/entityUtils';
import { get } from 'app/utils/lo/lo';
import { iconsSet } from 'app/utils/styles/mdi';
import { toUniqueUUID } from 'app/utils/string/string-utils';
import { copyToClipboard } from 'app/utils/classification/classificationUtils';

import Icon from 'app/components/atoms/Icon/Icon';
import ResizableListItem from 'app/components/molecules/VirtualList/ResizableListItem';
import DotMenu from 'app/components/molecules/DotMenu/DotMenu';
import TypeIcon from 'app/components/atoms/TypeIcon/TypeIcon';
import EntityLink from 'app/components/atoms/Link/EntityLink';
import EventActionRenderer from 'app/components/molecules/Grid/Renderers/EventAction/EventActionRenderer';
import { getModulePath } from 'app/utils/classification/classificationUtils';
import { render, compile } from 'app/utils/template/template';
import { isValidURL, appendHttp } from 'app/utils/string/string-utils';
import ChipClosed from 'app/components/atoms/ChipClosed/ChipClosed';

export const commonLinkStyles = css`
    && {
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    font-size: 14px;
    text-decoration: none;
    white-space: pre;
    &:hover {
        text-decoration: underline;
    }
    color: ${({ theme }) => theme.material.palette.text.primary} !important;
`;

export const CardContainer = styled.div`
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    cursor: pointer;

    &:hover {
        background: ${({ theme, selected }) => theme.material.colors.background.hover};
    }
    ${({ theme, selected }) => selected && `background: ${theme.material.colors.itemActive} !important;`}
`;

export const ListItemTextStyled = styled(ListItemText)`
    position: relative;
    flex: 1;

    & .MuiListItemText-primary {
        display: flex;
        align-items: center;
    }

    & .MuiListItemText-secondary {
        display: flex;
    }
`;

export const CardDivider = styled(Divider)`
    && {
        margin: 0 8px;
        height: 16px;
        position: relative;
        top: 3px;
    }
`;

export const EntityLinkStyled = styled(EntityLink)`
    && {
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    font-size: 14px;
    text-decoration: none;
    white-space: pre !important;
    &:hover {
        text-decoration: underline;
    }
    color: ${({ theme }) => theme.material.colors.text.primary} !important;
`;

export const LinkStyled = styled(Link)`
    ${commonLinkStyles}
    white-space: pre !important;
`;

const StyledAnchor = styled.a`
    ${commonLinkStyles}
`;

export const EntityLinkStyledSubTitle = styled(Typography)`
    && {
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    display: block;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.material.colors.text.caption} !important;
`;

export const LinkIcon = styled(Icon)`
    margin-left: 4px;
    cursor: pointer;
    &::before {
        color: ${({ theme }) => theme.material.colors.text.caption} !important;
    }
`;

export const ProcessAvatar = styled.div`
    & .MuiAvatar-root {
        ${({ iconColor }) => iconColor ? `background: ${iconColor};` : ''}
    }
`;

export const EntityTitle = ({ type, data }) => {
    const { id, name, primaryClass } = data || {};
    if (!id || !type) {
        return null;
    }
    const url = primaryClass?.openInModule;
    if (!url || url === `/entities/${type}`) { // url === `/entities/${type}` to handle the legacy / bad data
        return <LinkStyled to={`${getModulePath(url, type)}/${id}`}>{name || 'No Name'}</LinkStyled> ;
    }
    if (isValidURL(url)) {
        const validUrl = appendHttp(url);
        const link = render(compile(validUrl), data);
        return <StyledAnchor href={link} target="_blank" rel="noreferrer noopener">{name || 'No Name'}</StyledAnchor>;
    };
    const link = render(compile(url), data);
    return <LinkStyled to={link}>{name || 'No Name'}</LinkStyled> ;
};

class EntitiesListItem extends PureComponent<Object, Object> {
    state = {
        isHover: false,
    };

    @bind
    openSidebar(title) {
        const { data, entitiesView, openClassSidebar, openTaskSidebar, openEntitySidebar, openProcessSidebar,
            openWorkspaceSidebar, reloadList, onSelect, entityType, adminBackgroundJobs } = this.props;
        const type = entitiesView ?  data?.type || this.props.type : this.props.type;
        const params = { id: data.id, type, title, reloadList };
        switch (type) {
            case 'class':
                openClassSidebar({ ...params, entityType });
                break;
            case 'task':
                openTaskSidebar(params);
                break;
            case 'process':
                openProcessSidebar(params);
                break;
            case 'workspace':
                openWorkspaceSidebar(params);
                break;
            case 'user':
                if (entitiesView) {
                    return openEntitySidebar(params);
                }
                openEntitySidebar({ ...params, userAbout: true });
                break;
            case 'eventtype':
                this.props.openEventTypeSidebar(params);
                break;
            case 'event':
                this.props.openEventSidebar({...params, time: data.time });
                break;
            case 'team':
                this.props.openTeamSidebar(params);
                break;   
            default:
                openEntitySidebar({...params, adminBackgroundJobs});
                break;
        }
        onSelect && onSelect(data);
    }

    @bind
    renderAvatar(type) {
        const { data } = this.props;
        const { id, image: src, name, assignee, primary, processDefinitionVersion } = data;
        const image = src ? getAttachmentUrl(id, type, src): null;

        switch (type) {
            case 'task': {
                const { priority, progress, closedDate } = primary || {};
                const assigneeImage = assignee?.image && getAttachmentUrl(get(assignee, 'id'), 'user', assignee.image);
                return getFieldByType('avatar', {
                    CircularProgressStaticProps: {
                        value: progress || 0,
                        priority,
                        disabled:!!closedDate,
                    },
                    initials: get(assignee, 'name') || null,
                    src: assigneeImage
                });
            }
            case 'process': {
                const { iconName, iconColor, iconType } = processDefinitionVersion || {};
                const { closedDate } = primary || {};
                return (
                    <ProcessAvatar iconColor={iconColor}>
                        {getFieldByType('avatar', {
                            CircularProgressStaticProps: {
                                value: 0,
                                disabled:!!closedDate,
                                priority: get(primary, 'priority'),
                            },
                            children: <Icon type={iconType} name={iconsSet.has(iconName) ? iconName : 'asterisk'} />
                        })}
                    </ProcessAvatar>
                );
            }
            case 'event':
                return <Avatar initials={getStr(data, 'device.name')} />;
            default:
                return <Avatar src={image} initials={name} />;
        }
    }

    @bind
    renderName(type){
        const { data, entityType, pipelineMonitor, isPrintReports, adminBackgroundJobs } = this.props;
        let { name } = data;
        const { primary } =data;
        const extraProps = {};
        switch (type) {
            case 'event':
                name = getStr(data, 'device.name');
                extraProps.time = data?.time;
                break;
            default:
        }
        return <EntityLinkStyled variant="body2" id={getStr(data, 'id')} type={type} pipelineMonitor={pipelineMonitor} entityType={entityType} primary={primary} isPrintReports={isPrintReports} adminBackgroundJobs={adminBackgroundJobs} {...extraProps}>{name || 'No Name'}</EntityLinkStyled>;
    }

    @bind
    goToDetails() {
        goToEntityDetails(this.props);
    }

    @bind
    handleMenuClick(title) {
        const { goToDetails, data } = this.props;
        if (title === 'Go to details') {
            goToDetails && data?.id ? goToDetails(data?.id) : this.goToDetails();
        } else if (title === 'Process Map') {
            return history.push(`/abox/task/${data?.id}/process-map`);
        } else {
            this.openSidebar(title);
        }
    }


    @bind
    @memoize()
    renderIcon(type, iconName, iconColor, iconType) {
        const { entitiesView, entityType } = this.props;
        if (entitiesView) {
            const icon = getAttribute(this.props.data, 'iconAttribute') || 'null';
            const color = getAttribute(this.props.data, 'colorAttribute') || '#00BCD4';
            const iconType = getAttribute(this.props.data, 'iconTypeAttribute') || 'mdi';
            return <Icon type={iconType} name={icon} size="sm" hexColor={color} />;
        }
        if (iconName) {
            return <Icon type={iconType} name={iconName} size="sm" hexColor={iconColor} />;
        }
        return <TypeIcon type={entityType ? 'entityType' : type} />;
    }

    @bind
    @memoize()
    renderHoverActions(isHover){
        if (!isHover) {
            return null;
        };
        const { data, entitiesView } = this.props;
        const type = entitiesView ?  data?.type || this.props.type : this.props.type;
        switch (type) {
            case 'event':
                return <EventActionRenderer canEdit={true} refresh={this.props.reloadList} data={data} />;
            default:
        }
    };

    @bind
    @memoize()
    dotMenu(dotMenu) {
        if (!dotMenu?.length) {
            return null;
        }
        return <DotMenu key={13} onItemClick={this.handleMenuClick} items={dotMenu} />;
    }

    @bind
    copyLinkToClipBoard(e, id, type, entityType, isPrintReports, time,  entitiesView, pipelineMonitor) {
        e.stopPropagation();
        const { adminBackgroundJobs } = this.props;
        const origin = window.location.origin;
        copyToClipboard(`${origin}/#${getEntityUrl(id, type, { entityType, pipelineMonitor, isPrintReports, adminBackgroundJobs, time, entitiesView })}`)
            .then(() => {
                this.props.showToastr({ severity: 'success', detail: 'Link copied to clipboard' });
            })
            .catch(() => {
                this.props.showToastr({ severity: 'error', detail: 'Link could not copied to clipboard' });
            });
    }

    @bind
    copyIdToClipBoard(e, id: string) {
        e.stopPropagation();

        copyToClipboard(id)
            .then(() => {
                this.props.showToastr({ severity: 'success', detail: 'ID copied to clipboard' });
            })
            .catch(() => {
                this.props.showToastr({ severity: 'error', detail: 'ID could not copied to clipboard' });
            });
    }


    @bind
    onSelect() {
        const { listView, onSelect, data, sidebarTitle, type } = this.props;
        if (listView) {
            return onSelect(data);
        }
        if(type === 'event') {
            return this.openSidebar('About');
        }
        this.openSidebar((sidebarTitle ) || 'About');
    }

    @bind
    onMouseEnter(){
        this.setState({ isHover: true });
    }

    @bind
    onMouseLeave(){
        this.setState({ isHover: false });
    }

    @bind
    onThreeDotClick(event){
        event.stopPropagation();
        const { data, reloadList, entitiesView } = this.props;
        const type = entitiesView ? data?.type || this.props.type : this.props.type;
        const params = { id: data.id, data, type, title: 'Actions', reloadList };
        this.props.openEventsActionsSidebar(params);
    }

    render() {
        const { data, index, resize, style, selectedItem, idField, entitiesView, entityType, isPrintReports, pipelineMonitor } = this.props;
        const type = entitiesView ? data?.type || this.props.type : this.props.type;
        const { id, iconName, iconColor, primaryClass, isSystem } = data;
        const isSelected = selectedItem && selectedItem === data?.[idField] || selectedItem === id ;
        const { isHover } = this.state;
        return (
            <ResizableListItem style={style} key={index} index={index} resize={resize} padding={1}>
                {resizeRow => (
                    <CardContainer selected={isSelected} onClick={this.onSelect} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
                        <ListItem component="div" ContainerComponent="div">
                            <ListItemAvatar>
                                {this.renderAvatar(type)}
                            </ListItemAvatar>
                            <ListItemTextStyled
                                primary={
                                    <>
                                        {this.renderIcon(type, iconName, iconColor, primaryClass)}
                                        <CardDivider flexItem orientation="vertical" />
                                        { entitiesView ? <EntityTitle data={data} type={type} /> : this.renderName(type)}
                                        { data?.type === 'closedtask' && <ChipClosed />}
                                        { isSystem && <ChipClosed label={`System ${entityType ? 'Type' : 'Class'}`} /> }
                                    </>
                                }
                                secondary={
                                    <>
                                        <EntityLinkStyledSubTitle
                                            color="secondary"
                                            variant="caption"
                                            component="span"
                                            noWrap
                                            title="Copy ID to clipboard"
                                            onClick={e => this.copyIdToClipBoard(e, id)}
                                        >
                                            {`#${toUniqueUUID(id)}`}
                                        </EntityLinkStyledSubTitle>
                                        <LinkIcon title="Copy link to clipboard" name="link" size="sm" onClick={e => this.copyLinkToClipBoard(e, id, type, entityType, isPrintReports ,data.time, entitiesView, pipelineMonitor)}/>
                                    </>
                                }
                            />
                            <ListItemSecondaryAction>
                                {this.renderHoverActions(isHover)}

                                {
                                    type === 'event' ?
                                        <IconButton onClick={this.onThreeDotClick}>
                                            <Icon name={'dots-vertical'} />
                                        </IconButton> : this.dotMenu(this.props.dotMenu)
                                }

                            </ListItemSecondaryAction>
                        </ListItem>
                    </CardContainer>
                )}
            </ResizableListItem>
        );
    }
};

export default connect(
    null,
    {
        openEntitySidebar,
        openClassSidebar,
        openTaskSidebar,
        openProcessSidebar,
        openTeamSidebar,
        openWorkspaceSidebar,
        openEventTypeSidebar,
        openEventSidebar,
        openEventsActionsSidebar,
        showToastr
    }
)(EntitiesListItem);
