/* @flow */
import React from 'react';
import { onLoad, onChange, isVisible } from 'app/utils/designer/form/settings/common/commonEvents';
import { help } from 'app/utils/designer/form/settings/common/commonHelpers';
import { name } from 'app/utils/designer/form/settings/common/commonBase';
import { acceptedFileTypes, getUnique } from 'app/utils/array/array-utils';
import Help from 'app/utils/designer/form/settings/common/Help';

const fileTypes = acceptedFileTypes.map((ext) => ({ label: ext.replace('.', '').toUpperCase(), value: ext }));

const panels = (settingsValues) => [
    {
        header: 'Base',
        children: [
            name,
            { type: 'boolean', properties: { label: 'Multiple', name: 'properties.multiple' } },
            {
                type: 'autocomplete',
                properties: {
                    label: 'Accepted data format',
                    name: 'properties.acceptedExtensions',
                    options: fileTypes,
                    placeholder: 'All file formats are allowed',
                    freeSolo: true,
                    multiple: true,
                    valueField: 'value',
                    onChange: ({ target }) => { // If we want to normalize extensions data on run time then will uncomment it, user may type it in lower or upper case but eventually it will automatically converted to upper case on run time
                        const { name, value: val } = target || {};
                        let value = val;
                        if (Array.isArray(val)) {
                            value = val?.length ? val.map(v => !v?.includes('.') ? `.${v?.toLowerCase()}` : v) : value;
                        }
                        return [{ name, value: getUnique(value) }];
                    },
                    help: (
                        <Help message="If a file format is selected, then only selected format(s) will be allowed. You can type any custom file format and select the suggested dropdown item to add it to the list of 'Accepted data format' e.g. JAR, APK, IPA etc." />
                    ),
                },
            },
            {
                type: 'number',
                properties: {
                    label: 'Maximum number of files',
                    name: 'properties.maxFiles',
                    isVisible: (data) => data.properties && data.properties.multiple,
                },
                constraints: { min: 2 },
            },
            {
                type: 'number',
                properties: {
                    label: 'Maximum file size (KB)',
                    name: 'properties.maxFileSize',
                    help: (
                        <Help message="Maximum file size is defined in KB. e.g. 1024 KB = 1 MB" />
                    ),
                },
                constraints: { min: 1 },
            },
        ],
    },
    {
        header: 'Events',
        expanded: true,
        children: [onLoad, onChange, isVisible].filter(Boolean),
    },
    {
        header: 'Helpers',
        children: [help],
    },
];

export default {
    defaults: {},
    panels: (settingValues) => panels(settingValues),
};
