/* @flow */
import React from 'react';

import ChatSidebar from 'app/containers/Sidebars/ChatSidebar';
import { toUniqueUUID } from 'app/utils/string/string-utils';

import {
    buildSidebar as buildSidebarAction,
    closeSidebar as closeSidebarAction
} from 'store/actions/sidebar/sidebarActions';

export const openChatSidebar = ({ rel, rid, title, role, selectedSidebar }) => async (dispatch, getState) => {
    const subTitle = `${rel.name} #${toUniqueUUID(rel.id)}`;
    return buildSidebarAction({
        title,
        subTitle,
        content: <ChatSidebar rel={rel} rid={rid} role={role} selectedSidebar={selectedSidebar} />,
        isOpen: true,
    })(dispatch, getState);
};

export const closeChatSidebar = closeSidebarAction;