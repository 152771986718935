/* @flow */

import { gql } from '@apollo/client';
import { entityReferences } from 'graphql/entities/relationships/relationsQuery';

export default gql`
    mutation createRelationTaskToColumnMutation($entity: EntityReferenceInput!, $relatedEntity: EntityReferenceInput!, $relationDefinition: ID!) {
        data: entityRelationAdd(entity: $entity, relatedEntity: $relatedEntity, relationDefinition: $relationDefinition) {
            custom2: relatedEntity {
                ${entityReferences}
            }
            task1: entity {
                ${entityReferences}
            }
        }
    }
`;
