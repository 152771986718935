/* @flow */

import { PureComponent } from 'react';

import Forest from 'app/utils/dataStructure/Forest';
import uuidv1 from 'uuid/v1';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';

class AbstractBuilder extends PureComponent<Object, Object> {

    uuid: string = uuidv1();

    constructor(props: Object) {
        super(props);
        const value = props.value || [];
        const denormalizedValues = this.deNormalizeValues(value);
        this.state = {
            showModal: false,
            value: this.forest(denormalizedValues).nodes,
            showLoader: false,
        };
    }

    componentDidUpdate(prevProps: Object) {
        const { value, data } = this.props;
        if (prevProps.value !== value || data.cid !== prevProps.data.cid) {
            const val = value || [];
            const denormalizedValues = this.deNormalizeValues(val);
            this.setState({ value: this.forest(denormalizedValues).nodes });
        }
    }

    @bind
    toggleModal(e: ?Object) {
        if(e) {
            e.stopPropagation();
        }
        this.setState(state => ({ showModal: !state.showModal}));
    }

    @bind
    @memoize()
    _forest(value: Array<Object>) {
        return new Forest(value, this.uuid, { filterByType: false });
    };

    @bind
    forest(value: ?Array<Object>) {
        return this._forest(value || this.state.value);
    };

    @bind
    onDrop(item: Object, parentUuid: string, index: number) {
        this.setState({ value: this.forest().move(item, parentUuid, index).nodes });
    }

    @bind
    deleteValue(value: Object) {
        this.setState({ value: this.forest().remove(value).nodes });
    }

    @bind
    addValue(value: Object, parentUuid: string, index: number) {
        return new Promise(resolve => {
            this.setState({ value: this.forest().add(value, parentUuid, index).nodes }, resolve);
        });
    }

    @bind
    handleOnChange(data) {
        return new Promise(resolve => {
            this.setState({ value: this.forest().update(data).nodes }, resolve);
        });
    }

    @bind
    async onSave(arg) {
        const { value } = this.state;
        const { name, onChange, changeVariable } = this.props;

        const normalizedValue = this.normalizeValues(value);

        this.toggleModal();
        const nextTarget = { value: normalizedValue, name };
        if(onChange) {
            await onChange({ target: nextTarget });
        }
        if(changeVariable) {
            await changeVariable(nextTarget);
        }
        this.toggleShowLoader && this.toggleShowLoader(false);
    }
};

export default AbstractBuilder;
