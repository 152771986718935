import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { TextField, Grid, Divider } from '@mic3/platform-ui';

import SelectionList from 'app/containers/Common/SelectionList/SelectionList';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { getStr, isEmpty } from 'app/utils/utils';
import { PUBLIC_TEAM_ID } from 'app/config/config';
import {
    addWorkspaceTeams,
    addWorkspaceUsers,
    removeWorkspaceTeams,
    removeWorkspaceUsers,
} from 'store/actions/admin/workspacesActions';


const StyledGrid = styled(Grid)`
    margin: 0 8px;
    position: sticky !important;
    top: 0;
    background: ${({theme})=> theme.material.colors.background.default} !important;
    z-index:999;
`;

const SearchField = styled(TextField)`
    .MuiFilledInput-input {
        padding: 10px 12px 10px 0 !important;
        background: ${({ theme }) => theme.base.background};
    }
`;

const StyledDivider = styled(Divider)`
    margin-bottom: 15px !important;
`;


const inputProps: Object = { disableUnderline: true };

/**
 * Container that is used to display the members sidebar of workspace
 */
class WorkspaceMembersList extends PureComponent<Object, Object> {
    state = {
        searchValue:'',
    }

    @bind
    onChangeSearch(event: Object) {
        this.setState({ searchValue: event.target.value });
    }

    @bind
    getUsers(teams: Array<Object>) {
        if (!teams || isEmpty(teams)) {
            return [];
        }
        const obj = teams.find(t => t.team.isSystem);
        if (!obj || isEmpty(obj.team.users)) {
            return [];
        }
        return  obj.team.users.map(({ user, role }) => ({ ...user, role }));
    }

    @bind
    getTeams(teams: Array<Object>) {
        if (!teams || isEmpty(teams)) {
            return [];
        }
        const nonPrivateTeams = teams.filter(t => !t.team.isSystem || t.team.id === PUBLIC_TEAM_ID);
        if (!nonPrivateTeams.length) {
            return [];
        }
        return nonPrivateTeams.map(({ team, role }) => ({ ...team, role }));
    }

    @bind
    onSubmitTeams(teams: Array<string>) {
        const id = getStr(this.props, 'details.id', '');
        if (!id || isEmpty(teams)) {
            return;
        }
        const teamInput = teams.map(({ id, role }) => ({ teamId: id, role }));
        return this.props.addWorkspaceTeams(id, teamInput);
    }

    @bind
    removeWorkspaceTeams(selectedTeamsListIds) {
        const id = getStr(this.props, 'details.id', '');
        if (!id || isEmpty(selectedTeamsListIds)) {
            return;
        }
        return this.props.removeWorkspaceTeams(id, selectedTeamsListIds);
    }

    @bind
    onSubmitUsers(users: Array<Object>) {
        const id = getStr(this.props, 'details.id', '');
        if (!id || isEmpty(users)) {
            return;
        }
        const userInput = users.map(({ id, role }) => ({ userId: id, role }));
        return this.props.addWorkspaceUsers(id, userInput);
    }

    @bind
    removeWorkspaceUsers(selectedUsersListIds) {
        const id = getStr(this.props, 'details.id', '');
        if (!id || isEmpty(selectedUsersListIds)) {
            return;
        }
        return this.props.removeWorkspaceUsers(id, selectedUsersListIds);
    }

    @bind
    @memoize()
    canEdit(owners){
        const { id: userId, isAdmin } = this.props.profile;
        if (isAdmin) {
            return true;
        };
        return !!(owners || []).find(({ id }) => id === userId);
    }
    @bind
    @memoize()
    filterSearchedData(searchQuery: string, data: Array<Object>) {
        return searchQuery ? (data || []).filter(item => this.searchForFrom(searchQuery, item)) : data || [];
    }

    @bind
    searchForFrom(searchQuery, data) {
        if (!data || !searchQuery) return false;
        return ['name', 'id', 'username', 'primary.username'].some((field) => {
            let value = getStr(data, field, '');
            if (!value) return false;
            value = value.toLowerCase();
            const query = searchQuery.toLowerCase();
            return value.includes(query);
        });
    }

    render() {
        const { details, } = this.props;
        const { teams, owners } = details || {};
        const isDisabled = !this.canEdit(owners);
        const workspaceTeams = this.getTeams(teams);
        const workspaceUsers = this.getUsers(teams);
        const filteredTeams = this.filterSearchedData(this.state.searchValue, workspaceTeams);
        const filteredUsers = this.filterSearchedData(this.state.searchValue, workspaceUsers);
        return (
            <>
                <StyledGrid>
                    <SearchField
                        onChange={this.onChangeSearch}
                        value={this.state.searchValue}
                        fullWidth
                        margin="none"
                        placeholder="Search..."
                        InputProps={inputProps}
                    />
                    <StyledDivider />
                </StyledGrid>
                <SelectionList
                    expanded
                    list={filteredTeams || []}
                    type="team"
                    title="Workspace teams"
                    onAdd={this.onSubmitTeams}
                    onRemove={this.removeWorkspaceTeams}
                    disabled={isDisabled}
                    showRoles
                />
                <SelectionList
                    list={filteredUsers || []}
                    type="user"
                    title="Workspace users"
                    onAdd={this.onSubmitUsers}
                    onRemove={this.removeWorkspaceUsers}
                    disabled={isDisabled}
                    showRoles
                />
            </>
        );
    }
}

export default connect((state, props) => ({
    profile: state.user.profile,
}), {
    addWorkspaceTeams,
    removeWorkspaceTeams,
    addWorkspaceUsers,
    removeWorkspaceUsers,
})(WorkspaceMembersList);