/* @flow */

import React from 'react';
import styled from 'styled-components';

const FullWidthStyled = styled.div`
    width: 100%;
`;

const Group = ({ children, ...divProps }: Object) => {
    return <FullWidthStyled {...divProps}>{children}</FullWidthStyled>;
};

export default Group;
