/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import PageNotAllowed from 'app/containers/ErrorPages/PageNotAllowed';
import CreateMapModal from 'app/containers/Maps/CreateMap/CreateMapModal';
import MapsCardItem from 'app/containers/Maps/MapsList/MapsCardItem';
import MapsListItem from 'app/containers/Maps/MapsList/MapsListItem';
import PageTemplate from 'app/components/templates/PageTemplate';
import EntitiesList from 'app/containers/Entities/EntitiesList/EntitiesList';
import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import RenameMapModal from 'app/containers/Maps/SituationalAwareness/RenameMapModal';
import DotMenu from 'app/components/molecules/DotMenu/DotMenu';
import Alert from 'app/components/molecules/Alert/Alert';
import Icon from 'app/components/atoms/Icon/Icon';
import { Box, IconButton, Tooltip } from '@mic3/platform-ui';
import { bind } from 'app/utils/decorators/decoratorUtils';
import { loadEntitiesListView } from 'store/actions/entities/entitiesActions';
import { updateMapData } from 'store/actions/maps/situationalAwarenessActions';
import { entitiesDefaultFilterDefinitions } from 'app/utils/entity/entityUtils';
import { getPermissions } from 'app/config/rolesConfig';
import { typeMap } from 'app/config/typesConfig';
import { modulesAndPageTitles } from 'app/config/typesConfig';
import { setDocumentTitle } from 'store/actions/app/appActions';


const Content = styled.div`
    position: relative;
    top: 20%;
    padding: 20px;
    text-align: center;
    z-index: 1000;
`;

const ActionsContainer = styled.div`
    display: flex;
`;

const VIEW_ID = 'MapsListView';

const iconButtons = [
    {
        label: 'Attachments',
        name: 'link',
        type: 'mdi',
    },
    {
        label: 'Add Member',
        name: 'account-plus',
        type: 'mdi',
    },
    {
        label: 'A-Live',
        name: 'messenger',
        type: 'af',
    },
];

class MapsList extends PureComponent<Object, Object> {
    /**
     * @const propTypes - describes the properties of the component
     * @const defaultProps - define the defaults values of the properties
     * @const filterDefinitions -definition for columns that we need to display in our grid
     */
    static propTypes = {
        isLoading: PropTypes.bool,
        records: PropTypes.array,
        totalRecords: PropTypes.number,
        loadEntitiesListView: PropTypes.func.isRequired,
        updateMapData: PropTypes.func.isRequired,
    };

    static defaultProps = {
        isLoading: false,
        defaultView: 'view-list',
        views: ['view-list', 'view-cards', 'view-table'],
    };

    state = { mapId: null, mapName: '' };

    defaultFilters = { active: true };

    mapListRef = React.createRef();

    breadcrumb = [{ title: 'Maps' }];
    searchBar = ['name', 'id'];
    defaultOrder = [{ field: 'modifiedDate', direction: 'desc nulls last' }];

    columnDefinitions = [
        {
            text: 'Name',
            field: 'name',
        },
        {
            text: 'ID',
            field: 'id',
            onClickDisabled: true,
        },
        {
            text: 'Last Updated',
            field: 'modifiedDate',
        },
        {
            text: 'Modified By',
            field: 'modifiedBy',
        },
        {
            text: 'Created Date',
            field: 'createdDate',
        },
        {
            text: 'Created by',
            field: 'createdBy',
        },
        {
            text: '',
            field: 'id',
            onClickDisabled: true,
            renderer: ({ record }) => (
                <ActionsContainer>
                    <DotMenu items={this.renderActionList(record)} data={record.data} />
                </ActionsContainer>
            ),
        },
    ].map((column) => ({ ...column, filter: false, sortable: true }));

    componentDidMount() {
        this.props.setDocumentTitle(modulesAndPageTitles.maps);
    }

    @bind
    renderActionList(props) {
        const { data } = props;
        const { active, role } = data;
        const { canEdit } = getPermissions(role);
        if (!canEdit) {
            return null;
        }
        const label = active ? 'Disable' : 'Enable';
        const icon = active ? 'close' : 'check';
        const items = [
            { name: 'Rename', icon: 'pencil', onItemClick: this.toggleUpdateMap },
            { name: label, icon: icon, onItemClick: this.toggleEnableMap },
        ];
        return <DotMenu {...props} items={items} />;
    }

    @bind
    renderIcons() {
        return (
            <Box display='flex'>
                {iconButtons.map(({ label, name, type, value }, index) => {
                    return (
                        <Tooltip title={label} key={`${index}-${value}`}>
                            <span>
                                <IconButton>
                                    <Icon name={name} type={type} />
                                </IconButton>
                            </span>
                        </Tooltip>
                    );
                })}
            </Box>
        );
    }

    @bind
    refreshMapList() {
        this.mapListRef.current && this.mapListRef.current.resetView();
    }

    @bind
    createRightToolbar() {
        return <CreateMapModal showComponent={false} />;
    }

    @bind
    async toggleEnableMap(props: Object) {
        const { data } = props || {};
        const { id, active } = data || {};
        await this.props.updateMapData({ id, type: typeMap?.['map'], active: !active });
        this.refreshMapList();
    }

    @bind
    updateMapData(data: Object) {
        this.props.updateMapData({ id: this.state.mapId, type: typeMap?.['map'], ...data });
        this.toggleUpdateMap();
        this.refreshMapList();
    }

    @bind
    toggleUpdateMap(props: Object) {
        const { data } = props || {};
        const { id } = data || {};
        this.setState({ mapId: id, mapName: data?.name });
    }

    @bind
    renderNoResultComponent(filterBy: Object) {
        if (filterBy && filterBy.length > 0) {
            return (
                <Alert type='background' margin={16}>
                    No results
                </Alert>
            );
        }
        return (
            <Content>
                <CreateMapModal showComponent={true} />
            </Content>
        );
    }

    render() {
        const { records, isLoading, startIndex, totalRecords, userProfile, loadEntitiesListView } = this.props;
        const { permissions, isAdmin } = userProfile;
        const permissionsSet = new Set(permissions || []);
        const canView = isAdmin || permissionsSet.has('maps.sa');

        const type = 'system_map';
        if (!canView) {
            return <PageNotAllowed title='Maps' />;
        }
        return (
            <PageTemplate title='Maps' overflowHidden>
                <ContentArea>
                    <EntitiesList
                        id={VIEW_ID}
                        ref={this.mapListRef}
                        type={type}
                        headerTitle={'Maps'}
                        records={records}
                        totalRecords={totalRecords}
                        startIndex={startIndex}
                        isLoading={isLoading}
                        loadData={(opts) => loadEntitiesListView({ ...opts, type })}
                        filterDefinitions={entitiesDefaultFilterDefinitions([typeMap?.['map']])}
                        defaultOrder={this.defaultOrder}
                        defaultFilters={this.defaultFilters}
                        VirtualListCardsProps={{
                            renderNoResultComponent: this.renderNoResultComponent,
                        }}
                        VirtualListProps={{
                            renderNoResultComponent: this.renderNoResultComponent,
                        }}
                        rightToolbar={this.createRightToolbar()}
                        columnDefinitions={this.columnDefinitions}
                        renderCardsComponent={(props) => (
                            <MapsCardItem {...props} key={props.index}>
                                {this.renderActionList(props)}
                            </MapsCardItem>
                        )}
                        renderListComponent={(props) => (
                            <MapsListItem {...props} key={props.index}>
                                {this.renderActionList(props)}
                            </MapsListItem>
                        )}
                        disableAdd
                    />
                    {this.state.mapId && (
                        <RenameMapModal
                            onClose={this.toggleUpdateMap}
                            updateMapData={this.updateMapData}
                            mapName={this.state.mapName}
                        />
                    )}
                </ContentArea>
            </PageTemplate>
        );
    }
}

export default connect(
    (state) => ({
        userProfile: state.user.profile,
        records: state.entities.entities.records,
        totalRecords: state.entities.entities.count,
        isLoading: state.entities.entities.isLoading,
        startIndex: state.entities.entities.startIndex,
    }),
    {
        updateMapData,
        loadEntitiesListView,
        setDocumentTitle
    }
)(MapsList);
