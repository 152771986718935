/* @flow */

export const BUILD = '@@affectli/sidebar/BUILD';
export const OPEN = '@@affectli/sidebar/OPEN';
export const CLOSE = '@@affectli/sidebar/CLOSE';
export const EXPAND = '@@affectli/sidebar/EXPAND';
export const SHRINK = '@@affectli/sidebar/SHRINK';

export const SET_TITLE = '@@affectli/sidebar/SET_TITLE';
export const SET_SUBTITLE = '@@affectli/sidebar/SET_SUBTITLE';
export const SET_ACTIONS = '@@affectli/sidebar/SET_ACTIONS';
export const SET_CONTENT = '@@affectli/sidebar/SET_CONTENT';
export const SET_ON_BACK = '@@affectli/sidebar/SET_ON_BACK';
export const RESIZING = '@@affectli/sidebar/RESIZING';
export const ACTIVE_SIDEBAR = '@@affectli/sidebar/ACTIVE_SIDEBAR';

// when we open the sidebar must be "not expanded"
export const buildSidebar = ({ title = '', subTitle = '', subTitleRight, actions, content, isOpen, afterCloseSidebar, handleHorizonalScoll, iconName, iconType }) => (dispatch, getState) => {
    // for excluding undefined props
    const payload = { title, subTitle, subTitleRight, actions, content, isOpen, afterCloseSidebar, handleHorizonalScoll, iconName, iconType };
    dispatch({ type: BUILD, payload: { ...payload } });
    dispatch({ type: ACTIVE_SIDEBAR });
};

export const openSidebar = () => (dispatch, getState) => {
    dispatch({ type: OPEN });
};

export const closeSidebar = () => (dispatch, getState) => {
    dispatch({ type: CLOSE });
};

export const expandSidebar = () => (dispatch, getState) => {
    dispatch({ type: EXPAND });
};

export const shrinkSidebar = () => (dispatch, getState) => {
    dispatch({ type: SHRINK });
};

export const setOnBack = (onBack: func) => (dispatch, getState) => {
    dispatch({ type: SET_ON_BACK, payload: onBack });
};

export const setTitle = (title: string) => (dispatch, getState) => {
    dispatch({ type: SET_TITLE, payload: title });
};

export const setSubTitle = (subTitle: string) => (dispatch, getState) => {
    dispatch({ type: SET_SUBTITLE, payload: subTitle });
};

export const setActions = (actions: any) => (dispatch, getState) => {
    dispatch({ type: SET_ACTIONS, payload: actions });
};

export const removeActions = (actions: any) => (dispatch, getState) => {
    dispatch({ type: SET_ACTIONS, payload: null });
};

export const setContent = (content: any) => (dispatch, getState) => {
    dispatch({ type: SET_CONTENT, payload: content });
};

export const resizeSidebar = (isResizing: boolean) => (dispatch, getState) => {
    dispatch({ type: RESIZING, payload: isResizing });
};
