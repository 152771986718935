/* @flow */
import React from 'react';

import GraphicSidebar from 'app/containers/Entities/GraphicsLibrary/GraphicSidebar';

import { buildSidebar as buildSidebarAction, closeSidebar as closeSidebarAction } from 'store/actions/sidebar/sidebarActions';

export const openGraphicSidebar = (siderbarData: Object, canEdit = false, reloadList) => async (dispatch, getState) => {
    const { id, name: title } = siderbarData || {};
    return buildSidebarAction({
        title,
        content: <GraphicSidebar id={id} siderbarData={siderbarData} canEdit={canEdit} reloadList={reloadList} />,
        isOpen: true,
    })(dispatch, getState);
};

export const closeGraphicSidebar = closeSidebarAction;
