import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';

import { openProcessSidebar } from 'store/actions/abox/processSidebarActions';
import { openClassSidebar } from 'store/actions/entities/classSidebarActions';
import { openTeamSidebar } from 'store/actions/entities/teamSidebarActions';
import { openTaskSidebar } from 'store/actions/abox/taskSidebarActions';
import { openWorkspaceSidebar } from 'store/actions/entities/workspaceSidebarActions';
import { openSidebarByParams } from 'app/utils/app/appUtils';
import { set } from 'app/utils/lo/lo';

const AttributeSection = ({
    details,
    formRef,
    components,
    onChange,
    context,
    pushDropzoneReferences,
    removeAttachment,
    disabled,
    internal,
    ...restProps
}) => {
    const buildFormDefinitionFields = useCallback((normalized, disabled, path, parent) => {
        return (normalized || []).map((field) => {
            let newPath = parent ? '' : `${path || ''}`;
            let enrichedField = {...field};
            if(enrichedField.isSystem) { 
                enrichedField = {
                    ...enrichedField,
                    properties: {
                        ...enrichedField.properties,
                        disabled: true
                    }
                };
            }
            if(disabled) {
                enrichedField = { ...field, properties: { ...(field.properties || {}), disabled: !!disabled } };
            }
            if (enrichedField.children) {
                if(enrichedField.properties.name) {
                    newPath = newPath ? `${newPath}.${enrichedField.properties.name}` : `${enrichedField.properties.name}`;
                }
                enrichedField.children = buildFormDefinitionFields(enrichedField.children, disabled, newPath || '', false, enrichedField);
                return enrichedField;
            }

            switch(enrichedField.type) {
                case 'file': {
                    enrichedField.properties = {
                        ...enrichedField.properties,
                        entityId: details.id,
                        entityType: details.type,
                        isPrimary: true,
                        internal: internal
                    };
                    break;
                }
                case 'printButton': {
                    enrichedField.properties = {
                        ...enrichedField.properties,
                        entity: {
                            id: details.id,
                            type: details.type
                        }
                    };
                    break;
                }
                case 'chip': {
                    enrichedField.properties = {
                        ...enrichedField.properties,
                        entity: {
                            id: details.id,
                            type: details.type
                        },
                        openSidebar: (params) => openSidebarByParams({ 
                            ...restProps, 
                            ...params,
                        })

                    };
                    break;
                }
                case 'dropzone': {
                    const reference = enrichedField.properties.name;
                    const referenceData = { path: newPath, reference };
                    
                    enrichedField = set(
                        enrichedField,
                        'properties.onRemoveFile',
                        removeAttachment);
                    pushDropzoneReferences(referenceData);
                    break;
                }
                default:
            }
            return enrichedField;
        }).filter(Boolean);
    }, [details, restProps, removeAttachment, pushDropzoneReferences, internal]);

    const componentsNormilized = buildFormDefinitionFields(components, false, '', true);

    return (
        <FormGenerator
            root={false}
            ref={formRef}
            data={details}
            components={componentsNormilized}
            onChange={onChange}
            context={context}
            disabled={disabled}
        />
    );
};

AttributeSection.propTypes = {
    details: PropTypes.object.isRequired,
    formRef: PropTypes.object.isRequired,
    components: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    context: PropTypes.object.isRequired
};

export default connect(
    null,
    {
        openClassSidebar,
        openTaskSidebar,
        openProcessSidebar,
        openWorkspaceSidebar,
        openTeamSidebar,
    })(AttributeSection);

