/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { Button } from '@mic3/platform-ui';

import NotificationsBar from 'app/components/molecules/NotificationsBar/NotificationsBar';
import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';

import { get, set } from 'app/utils/lo/lo';
import {
    fetchBroadcast,
    saveBroadcast
} from 'store/actions/broadcasts/broadcastsActions';
import { showToastr } from 'store/actions/app/appActions';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import audioSrc from 'media/sounds/notification.mp3';

const oneOfNotNull = (data) => {
    const required = {
        presence: { allowEmpty: false, message: '{label} is required.' }
    };
    if (!get(data, 'recipientTeams.length') && !get(data, 'recipientUsers.length')) {
        return required;
    }
    return {};
};

/**
 * Create Broadcast
 */
class BroadcastForm extends PureComponent<Object, Object> {
    static propTypes = {
        fetchBroadcast: PropTypes.func,
        saveBroadcast: PropTypes.func,
        showToastr: PropTypes.func,
        broadcastCreatedId: PropTypes.string,
        isLoading: PropTypes.bool,
        isSubmitting: PropTypes.bool,
        broadcast: PropTypes.object,
        match: PropTypes.object,
        location: PropTypes.object,
        history: PropTypes.object,
        userProfile: PropTypes.object
    };

    state: {
        broadcast: ?Object,
        previewActive: boolean
    };

    audio: Object;

    formRef: Object = React.createRef();

    repeatTypes = [
        { value: 'hours', label: 'Hourly', name: 'Hour' },
        { value: 'days', label: 'Daily', name: 'Day' },
        { value: 'DW', label: 'By Day of Week', name: 'Day(s) of the week' },
        { value: 'weeks', label: 'Weekly', name: 'Week' },
        { value: 'months', label: 'Monthly', name: 'Month' },
        { value: 'years', label: 'Yearly', name: 'Year' }
    ];

    @bind
    @memoize()
    fieldDefinitions(isAdmin, data) {
        const startTimeValidation =
            data && data.active
                ? {
                    datetime: {
                        earliest: moment(),
                        message: '{label} cannot be before %{value}'
                    }
                }
                : {}; // // If broadcast is active then we will validate start time of the broadcast [9945]
        const definition: Array<Object> = [
            {
                type: 'teamTypeahead',
                properties: {
                    label: 'Recipient teams',
                    name: 'recipientTeams',
                    multiple: true,
                    valueField: 'id',
                    filterBy: [],
                    allowPermissions: false,
                },
                constraints: { custom: oneOfNotNull }
            },
            {
                type: 'userTypeahead',
                properties: {
                    label: 'Recipients',
                    name: 'recipientUsers',
                    multiple: true,
                    valueField: 'id'
                },
                constraints: { custom: oneOfNotNull }
            },
            {
                type: 'text',
                properties: { label: 'Message', name: 'message' },
                constraints: { required: true, maxLength: 1000 }
            },
            {
                type: 'dateTime',
                properties: { label: 'Start time', name: 'startDate' },
                constraints: {
                    required: true,
                    ...startTimeValidation
                }
            },
            {
                type: 'number',
                properties: {
                    label: 'Message expires after value',
                    name: 'metadata.expireAfter.value'
                },
                constraints: {
                    required: true,
                    numericality: {
                        noString: true,
                        onlyInteger: true,
                        lessThanOrEqualTo: 999,
                        notLessThanOrEqualTo:
                            '{label} needs to be less than or equal to %{count}'
                    }
                }
            },
            {
                type: 'typeahead',
                properties: {
                    label: 'Message expires after unit',
                    name: 'metadata.expireAfter.unit',
                    options: [
                        { value: 'seconds', label: 'Seconds', name: 'Second' },
                        { value: 'minutes', label: 'Minutes', name: 'Minute' },
                        { value: 'hours', label: 'Hourly', name: 'Hour' },
                        { value: 'days', label: 'Daily', name: 'Day' },
                        { value: 'weeks', label: 'Weekly', name: 'Week' },
                        { value: 'months', label: 'Monthly', name: 'Month' },
                        { value: 'years', label: 'Yearly', name: 'Year' }
                    ],
                    valueField: 'value'
                },
                constraints: { required: true }
            },
            {
                type: 'boolean',
                properties: { label: 'Active', name: 'active' }
            },
            {
                type: 'boolean',
                properties: { label: 'Repeat', name: 'repeat' }
            },
            {
                type: 'typeahead',
                properties: {
                    label: 'Repeats',
                    name: 'metadata.repeat.unit',
                    options: this.repeatTypes,
                    valueField: 'value',
                    isVisible: data => data && data.repeat
                },
                constraints: { required: true }
            },
            {
                type: 'number',
                properties: {
                    label: 'Repeat Every',
                    name: 'metadata.repeat.value',
                    isVisible: data =>
                        data &&
                        data['repeat'] &&
                        get(data, 'metadata.repeat.unit') !== 'DW'
                },
                constraints: { required: true }
            },
            {
                field: 'repeatValue',
                type: 'typeahead',
                properties: {
                    label: 'Repeat Every',
                    name: 'metadata.repeat.daysOfWeek',
                    options: [
                        { value: 'Mon', label: 'Monday' },
                        { value: 'Tue', label: 'Tuesday' },
                        { value: 'Wed', label: 'Wednesday' },
                        { value: 'Thu', label: 'Thursday' },
                        { value: 'Fri', label: 'Friday' },
                        { value: 'Sat', label: 'Saturday' },
                        { value: 'Sun', label: 'Sunday' }
                    ],
                    isVisible: data =>
                        data &&
                        data['repeat'] &&
                        get(data, 'metadata.repeat.unit') === 'DW',
                    multiple: true
                },
                constraints: { required: true }
            },
            {
                field: 'repeatEnds',
                type: 'dateTime',
                properties: {
                    label: 'Repeat ends',
                    name: 'metadata.repeat.until',
                    isVisible: data => data && data.repeat
                },
                constraints: {
                    required: true,
                    datetime: {
                        earliest: (data && data['startDate']) || moment(),
                        message: '{label} cannot be before %{value}'
                    }
                }
            }
        ];
        return definition;
    }

    constructor(props: Object) {
        super(props);

        const id = get(this, 'props.match.params.id');
        this.state = {
            broadcast: id
                ? this.normalizeData(props.broadcast || {})
                : { startDate: new Date(), active: false },
            previewActive: false
        };
        if (id) {
            this.props.fetchBroadcast({ id });
        }

        // $FlowFixMe
        this.audio = new Audio(audioSrc);
    }

    componentDidUpdate(prevProps) {
        const { broadcast, match, broadcastCreatedId, history } = this.props;
        if (history && broadcastCreatedId !== prevProps.broadcastCreatedId) {
            history.goBack();
        }
        const id = get(match, 'params.id');
        if (id !== get(prevProps, 'match.params.id')) {
            if (id) {
                this.props.fetchBroadcast({ id });
            } else {
                this.setState({
                    broadcast: { startDate: new Date(), active: false }
                });
            }
        }
        if (broadcast !== prevProps.broadcast) {
            this.setState({ broadcast: this.normalizeData(broadcast || {}) });
        }
    }

    @bind
    normalizeData(data) {
        if (get(data, 'metadata.repeat.daysOfWeek')) {
            data = set(data, 'metadata.repeat.unit', 'DW');
        }
        if (get(data, 'metadata.repeat')) {
            data = set(data, 'repeat', true);
        }
        return {
            ...data,
            recipientUsers: (data.recipientUsers || []).map(u => u.id),
            recipientTeams: (data.recipientTeams || []).map(t => t.id)
        };
    }

    /**
     * Function for dealing with our normal input on change
     */
    @bind
    onChange(data: Object) {
        this.setState({ broadcast: data });
    }

    /*
     * Submit our form data
     */
    @bind
    submitForm(event: Event) {
        event.preventDefault();
        this.formRef.current.isValidForm().then(({ data, errors }) => {
            if (!errors) {
                let newData = { ...data };

                if (newData.repeat) {
                    const {
                        until,
                        value,
                        daysOfWeek,
                        unit
                    } = newData.metadata.repeat;
                    if (unit === 'DW') {
                        newData = set(newData, 'metadata.repeat', {
                            until,
                            daysOfWeek
                        });
                    } else {
                        newData = set(newData, 'metadata.repeat', {
                            until,
                            value,
                            unit
                        });
                    }
                }
                delete newData.repeat;
                const { saveBroadcast, onClose, reloadList } = this.props;
                saveBroadcast(newData);
                reloadList && reloadList();
                onClose && onClose();
            }
        });
    }

    @bind
    togglePreview() {
        this.formRef.current.isValidForm().then(({ errors }) => {
            if (!errors) {
                this.audio.pause();
                this.audio.currentTime = 0;
                this.setState(
                    { previewActive: !this.state.previewActive },
                    this.playAudio
                );
            }
        });
    }

    @bind
    playAudio() {
        this.state.previewActive && this.audio.play().catch();
    }

    @bind
    onClose() {
        const { history } = this.props;
        if (history.length > 1) {
            history.goBack();
        }
        history.push('/broadcasts');
    }

    render() {
        const {
            onClose,
            title,
            isLoading,
            isSubmitting,
            match,
            userProfile: { isAdmin }
        } = this.props;
        const id = get(match, 'params.id');
        const { broadcast, previewActive } = this.state;
        const { message } = broadcast || {};
        const messages = [{ id: 1, text: message }];
        return (
            <ModalDialog
                title={title}
                isLoading={isLoading}
                onClose={onClose || this.onClose}
                actions={
                    <>
                        <Button
                            color="secondary"
                            variant="text"
                            onClick={this.togglePreview}
                            disabled={isSubmitting}
                        >
                            {previewActive ? 'Close Preview' : 'Preview'}
                        </Button>
                        <Button
                            onClick={this.submitForm}
                            disabled={isSubmitting}
                        >
                            {id ? 'Save' : 'Create'}
                        </Button>
                    </>
                }
            >
                {previewActive && (
                    <NotificationsBar
                        notificationRead={this.togglePreview}
                        messages={messages}
                    />
                )}
                <FormGenerator
                    components={this.fieldDefinitions(isAdmin, broadcast)}
                    ref={this.formRef}
                    data={broadcast}
                    onChange={this.onChange}
                />
            </ModalDialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        broadcastCreatedId:
            state.broadcasts.save.data && state.broadcasts.save.data.id,
        broadcast: state.broadcasts.detail.data,
        isLoading: state.broadcasts.detail.isLoading,
        isSubmitting: state.broadcasts.save.isLoading,
        userProfile: state.user.profile
    };
};

export default connect(mapStateToProps, {
    fetchBroadcast,
    saveBroadcast,
    showToastr
})(BroadcastForm);
