/* @flow */

export const BUILD_FILTERBAR = '@@affectli/filterbar/BUILD_FILTERBAR';
export const OPEN_FILTERBAR = '@@affectli/filterbar/OPEN_FILTERBAR';
export const SAVE_FILTER_OPERATORS = '@@affectli/filterbar/SAVE_FILTER_OPERATORS';
export const RESET_FILTER_OPERATORS = '@@affectli/filterbar/RESET_FILTER_OPERATORS';
export const CLOSE_FILTERBAR = '@@affectli/filterbar/CLOSE_FILTERBAR';
export const ACTIVE_FILTERBAR = '@@affectli/filterbar/ACTIVE_FILTERBAR';
export const DISABLE_FILTER_BUTTON = '@@affectli/filterbar/DISABLE_APPLY';
export const ENABLE_FILTER_BUTTON = '@@affectli/filterbar/ENABLE_FILTER_BUTTON';

// when we open the sidebar must be "not expanded"
export const buildFilterbar = ({ title = '', subTitle = '', actions, content, isOpen, id }) => (dispatch, getState) => {
    // for excluding undefined props
    const payload = { title, subTitle, actions, content, isOpen, id };
    dispatch({ type: BUILD_FILTERBAR, payload: {...payload } });
    dispatch({ type: ACTIVE_FILTERBAR });
};

export const openFilterbar = () => (dispatch, getState) => {
    dispatch({ type: OPEN_FILTERBAR });
};

export const saveFilterOperators = data => (dispatch, getState) => {
    dispatch({ type: SAVE_FILTER_OPERATORS, payload: data });
};

export const resetFilterOperators = data => (dispatch, getState) => {
    dispatch({ type: RESET_FILTER_OPERATORS });
};
export const closeFilterbar = () => (dispatch, getState) => {
    dispatch({ type: CLOSE_FILTERBAR });
};

export const disabledFilterButton = () => (dispatch, getState) => {
    dispatch({ type: DISABLE_FILTER_BUTTON });
};

export const enabledFilterButton = () => (dispatch, getState) => {
    dispatch({ type: ENABLE_FILTER_BUTTON});
};