import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import { useToggle } from 'app/utils/hook/hooks';
import { MdiIcon, Button } from '@mic3/platform-ui';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import { createEvent } from 'app/utils/http/event';
import UploadButton from 'app/components/molecules/UploadButton/UploadButton';
import { readAsDataURL, getSvgSource } from 'app/utils/maps/layer/layerUtils';
import { getSvgPriority } from 'app/utils/maps/layer/layerUtils';
import Download3DSvgGeom from 'app/containers/Maps/EntityAboutMap/Download3DSvgGeom.js';
import Image from 'app/components/atoms/Image/Image';


const MdiIconStyled = styled(MdiIcon)`
    margin-right: 6px !important;
`;

const SelectSvg = ({ onClose, onChange }: Object) => (
    <ModalDialog onClose={onClose}>
        <FormGenerator
            components={[
                {
                    field: 'svgGraphic',
                    type: 'graphicTypeahead',
                    properties: {
                        label: 'SVG',
                        name: 'svgGraphic'
                    }
                }
            ]}
            data={{}}
            onChange={(data) => {
                const event = createEvent('change', { name: 'svgGraphic', value: data?.svgGraphic });
                onChange(event);
                onClose();
            }}
        />
    </ModalDialog>
);

const UploadSvg = (props: Object) => {
    const [showModal, toggleModal] = useToggle(false);
    const { primaryClass, data } = props || {};
    const { svg, svgGraphic } = getSvgPriority(data, primaryClass);
    const imageSrc = getSvgSource({ svg, svgGraphic });

    return (
        <div>
            {imageSrc && <>
                <Image src={imageSrc} alt="SVG" height="150px" width='97%' style={{objectFit: 'contains'}}/>
                <Download3DSvgGeom {...props} fileType='svgFile' 
                    deleteFile={props.deleteFile}
                    downloadFileAction={props.downloadFileAction}
                />
            </>
            }
            <Button variant="text" onClick={toggleModal}>
                <MdiIconStyled name="image-multiple" />
                IMAGE LIBRARY 
            </Button>
            <UploadButton
                label=""
                icon="publish"
                size="lg"
                accept={['.svg']}
                onSelect={async (file) => {
                    const event = createEvent('change', { name: 'svg', value: await readAsDataURL(file) });
                    props.onChange && props.onChange(event);
                }}
            >
                {onClick => (
                    <Button onClick={onClick} variant="text">
                        <MdiIcon name="upload" />
                        Upload
                    </Button>
                )}
            </UploadButton>
            {showModal && <SelectSvg onClose={toggleModal} onChange={props.onChange} />}
        </div>
    );
};

UploadSvg.propTypes = {
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func
};

export default UploadSvg;
