/* @flow */

import { gql } from '@apollo/client';

export default gql`
mutation workspaceLeave($id:ID!) {
    workspaceLeave(id:$id){
      id
    }
  }
`;
