/* @flow */

import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { IconButton, Badge } from '@mic3/platform-ui';

import Relations from 'app/containers/Entities/Relationships/Relations';
import DotMenu from 'app/components/molecules/DotMenu/DotMenu';
import Icon from 'app/components/atoms/Icon/Icon';
import Loader from 'app/components/atoms/Loader/Loader';
import PageTemplate from 'app/components/templates/PageTemplate';
import PageNotAllowed from 'app/containers/ErrorPages/PageNotAllowed';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { openEntitySidebar } from 'store/actions/entities/entitySidebarActions';
import { openTaskSidebar } from 'store/actions/abox/taskSidebarActions';
import { openProcessSidebar } from 'store/actions/abox/processSidebarActions';
import { openRelationSidebar } from 'store/actions/entities/relationSidebarActions';
import EntityPrimaryAbout from 'app/containers/Entities/EntityAbout/EntityPrimaryAbout';
import BackgroundJobLogs from 'app/containers/Abox/BackgroundJob/BackgroundJobLogs';
import { loadBackgroundJobDetails } from 'store/actions/abox/backgroundJobActions';
import Breadcrumbs from 'app/components/organisms/Breadcrumbs/Breadcrumbs';
import { isEmpty } from 'app/utils/utils';
import { closeLeftPanel } from 'store/actions/leftPanel/leftPanelActions';
import { getUnreadMessages } from 'app/utils/entity/entityUtils';
import { isVisibleTab, buildDotMenu } from 'app/utils/entity/entityUtils';
import { getPermissions } from 'app/config/rolesConfig';
import { setDocumentTitle } from 'store/actions/app/appActions';

class BackgroundJobDetails extends PureComponent<Object, Object> {
    static propTypes = {
        id: PropTypes.string.isRequired,
        loadBackgroundJobDetails: PropTypes.func.isRequired,
        details: PropTypes.object,
        isLoading: PropTypes.bool
    };

    constructor(props: Object) {
        super(props);
        this.reloadDetails();
    }

    componentDidUpdate(prevProps) {
        const { id, details, setDocumentTitle } = this.props;
        const name = details?.name;
        if(name) {
            setDocumentTitle(name);
        }
        if (id !== prevProps.id) {
            this.reloadDetails();
        }
    }

    @bind
    reloadDetails() {
        const { id } = this.props;
        return this.props.loadBackgroundJobDetails(id);
    }

    @bind
    @memoize()
    renderActions(unreadMessages, permissions, details) {
        if (!permissions?.canComment) {
            return null;
        }
        const { primaryClass } = details || {};
        return (isVisibleTab('A-Live', primaryClass)) ? (
            <IconButton key={66} title="Open messenger" onClick={() => this.onDotMenuClick('A-Live')}>
                <Badge color="error" badgeContent={unreadMessages} max={99}>
                    <Icon name="messenger" type="af" />
                </Badge>
            </IconButton>
        ) : null;
    }

    @bind
    onDotMenuClick(title) {
        const { id, openEntitySidebar } = this.props;
        const entityType = 'backgroundjob';
        const settings = { title, id, type: entityType, internal: true };
        openEntitySidebar(settings);
    }

    @bind
    @memoize()
    buildDotMenu(sidebarTitle, details, pathname) {
        return buildDotMenu({ details, title: sidebarTitle, pathname });
    }

    @bind
    @memoize()
    dotMenu(sidebarTitle: string, details: Object, pathname: string = '') {
        return <DotMenu key={13} onItemClick={this.onDotMenuClick} items={this.buildDotMenu(sidebarTitle, details, pathname)} />;
    }

    @bind
    buildBreadcrumbs(name, pathTitle) {
        const  { adminBackgroundJobs } =this.props;
        let breadcrumbs = [{ title: name }];
        if(pathTitle) {
            breadcrumbs = [{ title: name, link: `/abox/background-job/${this.props.id}` }, { title: pathTitle }];
        }
        return <Breadcrumbs list={[{ link: adminBackgroundJobs ? `/background-jobs` : `/abox/background-jobs`, title: adminBackgroundJobs ? 'Background Jobs' : 'My Background Jobs' }, ...breadcrumbs ]} withGoBack />;
    }

    /**
     * @override
     */
    render() {
        const {
            id, isLoading, details, subscriptions, sidebarTitle,
            openEntitySidebar, openTaskSidebar, openProcessSidebar, openRelationSidebar, adminBackgroundJobs
        } = this.props;
        const showDetails = details && details?.id === id;
        const { pathname } = this.props.location;
        const permissions = getPermissions(details && details.role);
        const { canEdit } = permissions || {};
        
        if (!isLoading && isEmpty(details)) {
            return <PageNotAllowed permissionError={true} title={`Entities (ID:${id})`} />;
        }

        const matchUrl = adminBackgroundJobs ? `/background-job/${id}` : `/abox/background-job/${id}`;
        const unreadMessages = getUnreadMessages(id, subscriptions, 'backgroundjob');
        
        return (
            <Fragment>
                {isLoading && <Loader absolute backdrop />}
                {showDetails && (
                    <PageTemplate title={details.name} subTitle={`#${details.id}`} overflowHidden>
                        <Switch>
                            <Route path={matchUrl} exact component={() => <Redirect to={`${matchUrl}/logs`} />} />
                            {/* Handle the legacy redirect */}
                            <Route path={`${matchUrl}/logs`}>
                                <BackgroundJobLogs
                                    id={id}
                                    details={details}
                                    sidebarActions={[this.renderActions(unreadMessages, permissions, details), this.dotMenu(sidebarTitle, details, pathname)]}
                                    breadcrumbLine={this.buildBreadcrumbs(details.name)}
                                    openSidebar={this.onDotMenuClick}
                                />
                            </Route>
                            <Route path={`${matchUrl}/about`}>
                                <EntityPrimaryAbout
                                    details={details}
                                    // sidebarActions={[this.renderActions(unreadMessages, permissions, details), this.dotMenu(sidebarTitle, details, pathname)]}
                                    // breadcrumbLine={this.buildBreadcrumbs(details.name)}
                                    openSidebar={this.onDotMenuClick}
                                />
                            </Route>
                            <Route path={`${matchUrl}/relationships`} render={() => (
                                <Relations
                                    openEntitySidebar={openEntitySidebar}
                                    openTaskSidebar={openTaskSidebar}
                                    openProcessSidebar={openProcessSidebar}
                                    openRelationSidebar={openRelationSidebar}
                                    fromType={'backgroundjob'}
                                    fromId={id}
                                    canEdit={canEdit}
                                    details={details}
                                    breadcrumbLine={this.buildBreadcrumbs(details.name, 'Relationships')}
                                    sidebarActions={[this.renderActions(unreadMessages, permissions, details), this.dotMenu(sidebarTitle, details, pathname)]}
                                />
                            )} />
                        </Switch>
                    </PageTemplate>
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = (state: Object, ownProps: Object) => ({
    id: ownProps.match.params.id,
    isLoading: state.abox.backgroundJob.details.isLoading,
    details: state.abox.backgroundJob.details.data,
    sidebarTitle: state.sidebar.title,
    leftPanelOpts: state.leftPanel.state,
    subscriptions: state.chat.subscriptions.data
});

export default connect(mapStateToProps, {
    openRelationSidebar, openTaskSidebar, openProcessSidebar,
    loadBackgroundJobDetails, openEntitySidebar, closeLeftPanel, setDocumentTitle
})(withRouter(BackgroundJobDetails));
