/* @flow */

import { gql } from '@apollo/client';

export default (type) => gql`
mutation export${type === 'class' ? 'Classes': 'EntityTypes'}Mutation(
    $name: String
    $description: String
    $filterBy: [JSON]
    $excludeBy: [JSON]
    $orderBy: [JSON]
    $startIndex: Int
    $stopIndex: Int
    $fields: [String]!
) {
  result: ${type === 'class' ? 'exportClasses': 'exportEntityTypes'}(
    name: $name
    description: $description
    filterBy: $filterBy
    excludeBy: $excludeBy
    orderBy: $orderBy
    startIndex: $startIndex
    stopIndex: $stopIndex
    fields: $fields
  ) {
    id
    type
    name
  }
}
`;
