// @flow

import EntityLayer from 'app/containers/Maps/EntityLayerStyling/EntityLayer';
import Cluster from 'ol/source/Cluster';
import {Style} from 'ol/style';
import {bind} from 'app/utils/decorators/decoratorUtils';
import {get} from 'app/utils/lo/lo';
import { clusterStyle } from 'app/components/molecules/Map/EntityPin/entityPinStyle';

class ClusterLayer extends EntityLayer {
    clusterSource: any = null;

    constructor(info: Object, map: Object) {
        super(info, map);
        this.layer.setSource(this.getClusterSource());
        const pinStyle = this.info?.styleType || this.info?.styles?.pinStyle || 'cluster';
        if(pinStyle !== 'hideEntities') {
            this.layer.setStyle(this.styleFunction);
        }
    }

    @bind
    styleFunction(feature, resolution): Style {
        const { styles } = this.info;
        const pinStyle = styles?.pinStyle || this.info?.styleType || 'cluster';
        const features = feature && feature.get('features');
        if (pinStyle === 'cluster' && features && features.length > 1) {
            return clusterStyle(features);
        }
        else if(feature)
            return this.createDefaultStyle(feature && feature);
    }

    @bind
    getClusterSource() {
        const distance = get(this.info?.styles,'distance' || 120);
        if (!this.clusterSource) {
            this.clusterSource = new Cluster({
                distance: distance,
                source: this.getDataSource()
            });
        }
        return this.clusterSource;
    }

    @bind
    updateClusterSource(distance: number) {
        this.clusterSource.setDistance(distance);
    }
}

export default ClusterLayer;
