// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { bind, debounce } from 'app/utils/decorators/decoratorUtils';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';

class EntityDataVisualization extends PureComponent<Object, Object> {

    static propTypes: Object = {
        data: PropTypes.object,
    };

    formRef = React.createRef();

    @bind
    buildFormDefinition() {
        const { isLayerEntitiesHidden } = this.props;
        return [
            {
                field: 'dataVisualisation',
                type: 'checkbox',
                properties: {
                    label: 'Visualise aggregated data',
                    name: 'dataVisualisation',
                    disabled: isLayerEntitiesHidden,
                },
                filter: false,
            },
            {
                type: 'panel',
                properties: {
                    header: 'Heatmap',
                    expanded: true,
                },
                children: [
                    {
                        type: 'slider',
                        properties: {
                            label: 'Blur',
                            name: 'valBlur',
                            fillColor: '#7391D0',
                            min: 0,
                            step: 4,
                            max: 100,
                            defaultValue: 24,
                        },
                    },
                    {
                        type: 'slider',
                        properties: {
                            label: 'Radius',
                            name: 'valRadius',
                            fillColor: '#7391D0',
                            min: 0,
                            max: 100,
                            defaultValue: 25,
                        },
                    },
                ],
            },
        ];
    }

    @bind
    async validateForm() {
        if (this.formRef && this.formRef.current) {
            const { errors } = await this.formRef.current.isValidForm();
            return { errors };
        } else {
            return { errors: null };
        }
    }

    @bind
    @debounce()
    async handleFormValidation() {
        const result = await this.validateForm();
        this.errors = !!result.errors;
        return !result.errors;
    }

    @bind
    hasErrors() {
        return this.errors;
    }

    @bind
    async handleChange(data: Object, { name, value }: Object) {
        await this.handleFormValidation();
        this.props.onFormChange(data, { name, value });
    }

    render() {
        const { formData } = this.props;
        return (
            <>
                <FormGenerator
                    data={formData}
                    onChange={this.handleChange}
                    name={this.props.name}
                    components={this.buildFormDefinition()}
                    ref={this.formRef}
                />
            </>
        );
    }
}

export default connect(
    (state: Object, ownProps: Object) => ({
        styleHideLayers: state.maps.situationalAwareness.layer.styleHideLayers,
        isLayerEntitiesHidden: state.maps.situationalAwareness.map.layerEntitiesHidden[ownProps?.data?.id] || false,
    })
)(EntityDataVisualization);
