import React, { useRef } from 'react';
import FormGenerator from '../Designer/Form/components/FormGenerator';
import { Button } from '@mic3/platform-ui';
import styled from 'styled-components';
import { graphql } from 'graphql/client';
import { gql } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { showToastr } from 'store/actions/app/appActions';

const components = [
    {
        type: 'text',
        properties: {
            label: 'Notification Message',
            name: 'notificationMessage'
        }
    },
    {
        type: 'text',
        properties: {
            label: 'Push Notification Message',
            name: 'pushNotificationMessage'
        }
    }
];

const Stack = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 50px;
`;
const StyledStack = styled(Stack)`
    flex-direction: row;
    gap: 50px;
    justify-content: center;
    @media (max-width: 768px) {
        flex-direction: column;
        gap: 30px;
    }
`;
const StyledButton = styled(Button)`
    width: 200px;
`;

const pushNotificationMessageMutation = gql`
    mutation($message: String) {
        pushNotificationSendMessage(message: $message)
    }
`;
const notificationMessageMutation = gql`
    mutation($message: String) {
        sendMessage(message: $message)
    }
`;

export const sendNotificationSubscription = gql`
    subscription {
        onSendMessage
    }
`;

const Notification = () => {
    const formRef = useRef();
    const dispatch = useDispatch();

    const sendPushNotification = async message => {
        return graphql
            .mutate({
                mutation: pushNotificationMessageMutation,
                variables: { message }
            })
            .then(res => {
                if (res?.data?.pushNotificationSendMessage) {
                    dispatch(
                        showToastr({
                            severity: 'success',
                            detail: 'Push notification Sent Successfully'
                        })
                    );
                }
                return res;
            })
            .catch(e => console.log('$$$ Error res', e)); // eslint-disable-line
    };

    const sendNotification = async message => {
        return graphql
            .mutate({
                mutation: notificationMessageMutation,
                variables: { message }
            })
            .then(res => {
                if (res?.data?.sendMessage) {
                    dispatch(
                        showToastr({
                            severity: 'success',
                            detail: 'Notification Sent Successfully'
                        })
                    );
                }
                return res;
            })
            .catch(e => console.log('$$$ Error res', e)); // eslint-disable-line
    };

    const onFormSubmit = (type = undefined) => {
        event.preventDefault();
        formRef.current.isValidForm().then(({ data: formData, errors }) => {
            if (!errors) {
                const { notificationMessage, pushNotificationMessage } =
                    formData || {};
                if (
                    pushNotificationMessage &&
                    (type === 'all' || type === 'push')
                ) {
                    sendPushNotification(pushNotificationMessage);
                }
                if (
                    notificationMessage &&
                    (type === 'all' || type === 'notification')
                ) {
                    sendNotification(notificationMessage);
                }
            }
        });
    };
    return (
        <Stack>
            <h1>Notification Test</h1>
            <FormGenerator components={components} ref={formRef} />
            <StyledStack>
                <StyledButton onClick={() => onFormSubmit('notification')}>
                    Send Notification
                </StyledButton>
                <StyledButton onClick={() => onFormSubmit('push')}>
                    Send Push
                </StyledButton>
                <StyledButton onClick={() => onFormSubmit('all')}>
                    Send All
                </StyledButton>
            </StyledStack>
        </Stack>
    );
};

export default Notification;
