/* @flow */

import { DOMParser } from '@xmldom/xmldom';

import { bind } from 'app/utils/decorators/decoratorUtils';
import AbstractDefinitionsBuilder from './AbstractDefinitionsBuilder/AbstractDefinitionsBuilder';
import { prettifyXml } from 'app/utils/designer/process/processDesignerUtils';

class SignalDefinitionBuilder extends AbstractDefinitionsBuilder {

    @bind
    deNormalizeValues(definition: Array<Object>): Array<Object> {
        const doc = new DOMParser().parseFromString(definition);
        const signals =  doc.getElementsByTagName('signal');
        return Array.from(signals).map((elem) => {
            return {
                id: elem.getAttribute('id'),
                name: elem.getAttribute('name'),
                scope: elem.getAttribute('flowable:scope'),
            };
        });
    }

    @bind
    normalizeValues(value: Array<Object>): Array<any> {
        const { value: definition } = this.props;
        const doc = new DOMParser().parseFromString(definition);
        const signals =  doc.getElementsByTagName('signal');
        const process =  doc.getElementsByTagName('process')[0] || doc.getElementsByTagName('bpmn:process')[0];
        const definitions =  doc.getElementsByTagName('definitions')[0];
        const signalsNew = Array.from(value).map(
            elem => {
                const el = new DOMParser().parseFromString(`<signal id="${elem.id}" name="${elem.name}" flowable:scope="${elem.scope}"/>`);
                return el.getElementsByTagName('signal')[0];
            }
        );
        Array.from(signals).forEach((elem) => {
            definitions.removeChild(elem);
        });
        signalsNew.forEach((elem) => {
            definitions.insertBefore(elem, process);
        });
        return prettifyXml(String(doc));
    }
};

export default SignalDefinitionBuilder;
