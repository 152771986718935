/* @flow */

// here https://materialdesignicons.com/ you can find the list of icon

import React from 'react';
import styled, { css } from 'styled-components';
import IconProps from './IconProps';
import { muiTheme } from 'app/themes/materialUi';

const iconSizes = css`
  font-size: ${( { size, theme } ) => theme.sizes && theme.sizes[ size ].icon } !important;
`;

/* eslint-disable indent */
const IconStyle = styled.i`
    display: inline-block;
    text-rendering: auto;
    line-height: inherit;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
    ${({ onClick, disabled }) => !!onClick && !disabled && 'cursor: pointer' };
    &:before {
        ${iconSizes};
        display: block !important;
        color: ${
            ( { color, colorIndex, theme, hexColor, disabled } ) => {

                if (color === 'secondary') {
                    return theme.base.disabled.textColor;
                }
                if (disabled && theme) {
                    return theme.base.disabled.textColor;
                }
                if (color && !hexColor && theme.palette[color]) {
                    return theme.palette[color][colorIndex || 0];
                }
                return hexColor || (theme && theme.material.colors.text.secondary);
            }
        };
        text-shadow: ${( { shadow, theme } ) => shadow ? theme.shadow.boxShadow12DP : 'none' };
    }
`;
/* eslint-enable indent */

const Icon = (props: Object) => {
    const { name, type, size, color, colorIndex, hexColor, className, disabled, onClick, ...rest } = props;
    return <IconStyle size={size} color={color} hexColor={hexColor} colorIndex={colorIndex} className={`Icon ${type || 'mdi'} ${type || 'mdi'}-${name} ${className}`} disabled={disabled} onClick={!disabled ? onClick : null} {...rest} />;
};

Icon.propTypes = {
    ...IconProps
};

Icon.defaultProps = {
    type: 'mdi',
    size: 'md',
    color: muiTheme.colors.appNav.linkIconColor,
    colorIndex: 0
};

export default Icon;
