import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Box, Typography, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import InstallDialogAction from './InstallDialogAction';


const StyledDialog = styled(Dialog)`

    .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
        width:  ${({ isMobile }) => isMobile ? '280px !important' : '600px !important'};
        padding: 20px 16px 20px 24px!important;
        box-shadow: ${({theme})=>theme.shadow.boxShadow24DP} !important;
  }
    .MuiDialogActions-root.MuiDialogActions-spacing{
        padding: 0 !important;
    }

`;

const StyledBox = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: left;
    margin-bottom: 17px !important;
`;

const StyledTypography = styled(Typography)`
        color: ${({theme})=> theme.material.colors.text.secondary};
        font-size: 16px !important;
        letter-spacing: 0.15 !important;
        line-height: 20px !important;
        padding-right: 6px !important;
`;
const StyledImgBox = styled(Box)`
    margin: 0 21px 0 0 !important;
    padding: 0 !important;
    font-size: 0 ;
    line-height: 0;
    border: 0;
`;
const StyledDialogTitle = styled(DialogTitle)`
    .MuiTypography-h6{
    color: ${({theme})=> theme.material.colors.text.primary};
    line-height: 28px !important;
    font-size: 20px !important;
    font-weight: 600;
    }
    display: block;
    padding: 0px !important;
    margin-right: 6px !important;
    ${({ isMobile }) => isMobile && `width: 163px !important;`};
`;
const StyledDialogContent = styled(DialogContent)`
    padding: 0 0 24px 0!important;
`;




export default function InstallDialog(props) {
    const isMobile = useSelector(
        state => state.global.isMobile,
    );
    return (
        <StyledDialog isMobile={isMobile} open={props.open} onClose={props.onClose} aria-labelledby="dialog-title">
            <StyledBox >
                <StyledImgBox>
                    <img src={'./images/affectliCircle.png'} alt="logo" />
                </StyledImgBox>
                <StyledDialogTitle  isMobile={isMobile} id="dialog-title">{props.title}</StyledDialogTitle>
            </StyledBox>
            <StyledDialogContent>
                <StyledTypography variant="subtitle1">Do you want to install Affectli on your device? </StyledTypography>
            </StyledDialogContent>
            <InstallDialogAction platform={props.platform} onSubmit={props.onSubmit} onClose={props.onClose} />
        </StyledDialog>

    );
}