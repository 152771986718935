/* @flow */

import React, { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const LinkStyle = styled(RouterLink)`
${({ nodecoration }) => nodecoration ? 'text-decoration: none;' : ''}
${({ white, theme }) => white ? `color: ${theme.material.colors.white} !important;` : ''}
`;

const Link = forwardRef((props: Object, ref) => {
    const { noDecoration, white, ...linkProps } = props; // eslint-disable-line no-unused-vars
    return <LinkStyle {...linkProps} white={white?1:0} nodecoration={noDecoration ? 1 : 0} innerRef={ref} />;
});

Link.propTypes = {
    noDecoration: PropTypes.bool,
};

export default Link;
