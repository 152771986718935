/* @flow */

import { gql } from '@apollo/client';

export default gql`
    query loadClassDefinitionQuery($id: ID!) {
        result: class(id: $id) {
            id
            formDefinition
        }
    }
`;
