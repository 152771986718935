/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import DotMenu from 'app/components/molecules/DotMenu/DotMenu';
import Loader from 'app/components/atoms/Loader/Loader';
import history from 'store/History';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { closeSidebar } from 'store/actions/sidebar/sidebarActions';
import { mediaBreakpoints } from 'app/themes/breakpoints';
import { setActions, setSubTitle } from 'store/actions/sidebar/sidebarActions';
import WorkspaceAboutTab from 'app/containers/Admin/Workspaces/Details/Tabs/WorkspaceAboutTab';
import { loadWorkspace } from 'store/actions/admin/workspacesActions';
import ClassificationsTab from 'app/containers/Common/ClassificationsTab/ClassificationsTab';
import { getStr, isEmpty } from 'app/utils/utils';
import WorkspaceResourcesList from 'app/containers/Admin/Workspaces/WorkspaceResourcesList';
import { get } from 'app/utils/lo/lo';

import { shrinkSidebar, expandSidebar } from 'store/actions/sidebar/sidebarActions';
import WorkspaceHistoryTab from '../Admin/Workspaces/Details/Tabs/WorkspaceHistoryTab';
import withPermissions from 'app/utils/withPermissions';
import WorkspaceMembersList from '../Admin/Workspaces/WorkspaceMembersList';

class WorkspaceSidebar extends PureComponent<Object, Object> {
    static propTypes = {
        id: PropTypes.string,
        type: PropTypes.string,
        title: PropTypes.string,
        isOpen: PropTypes.bool,
        // from redux
        isLoading: PropTypes.bool,
        details: PropTypes.object
    };

    constructor(props: Object) {
        super(props);
        this.initData();
    }

    componentDidUpdate(prevProps) {
        this.initData();
    }

    @bind
    setActions(expanded, sidebarTitle) {
        this.props.setActions(this.dotMenu(expanded, sidebarTitle));
    }

    initData() {
        const { id, isOpen, isLoading, details, expanded, title } = this.props;
        if (!isOpen) {
            return;
        }
        this.setActions(expanded, title);
        if (isLoading) {
            return;
        }
        const loaded = details || {};
        if (loaded.id === id) {
            this.props.setSubTitle(details.name);
            return;
        }
        this.props.loadWorkspace(id);
    }

    @bind
    onDotMenuClick(title) {
        if (['Collapse', 'Expand'].includes(title)) {
            const { expanded, expandSidebar, shrinkSidebar } = this.props;
            const action = expanded ? shrinkSidebar : expandSidebar;
            action();
        }
        if (title === 'Go to details') {
            this.goToDetails();
        }
    }

    goToDetails() {
        const { windowWidth, id, shrinkSidebar, closeSidebar } = this.props;
        history.push(`/workspaces/${id}`);
        if (!windowWidth < mediaBreakpoints.sm) {
            shrinkSidebar();
        } else {
            closeSidebar();
        }
    }

    @bind
    @memoize()
    dotMenu(expanded: boolean) {
        return (
            <DotMenu
                key={13}
                onItemClick={this.onDotMenuClick}
                items={[
                    { name: expanded ? 'Collapse' : 'Expand', icon: expanded ? 'arrow-collapse' : 'open-in-new' },
                    { name: 'Go to details', icon: 'login-variant' }
                ].filter(Boolean)}
            />
        );
    }

    @bind
    @memoize()
    getClassResources(classResources){
        if (!classResources || isEmpty(classResources)) {
            return [];
        }
        return  classResources.map(({ class: cls, role }) => ({ ...cls, role }));
    }

    @bind
    @memoize()
    canEdit(owners){
        const { id: userId, isAdmin } = this.props.profile;
        if (isAdmin) {
            return true;
        };
        return !!(owners || []).find(({ id }) => id === userId);
    }

    @bind
    buildResourceComponent(type: string) {
        const { title, details } = this.props;
        const canEdit = this.canEdit(get(details, 'owners', []));
        const workspaceId = getStr(details, 'id', '');
        return <WorkspaceResourcesList title={title} type={type} id={workspaceId} list={get(details, 'entityResources', [])} disabled={!canEdit} />;
    }
    render() {
        const { isLoading, title, details, isOpen, openWorkspaceSidebar, reloadList } = this.props;
        const workspaceId = getStr(details, 'id', '');
        const isLoaded = details;
        const canEdit = this.canEdit(get(details, 'owners', []));
        const filterBy = [{ field: 'primary', op: '=', value: false }];
        return (
            isOpen && (
                <>
                    {isLoading && <Loader absolute backdrop />}
                    {isLoaded && title === 'About' && <WorkspaceAboutTab reloadList={reloadList} openWorkspaceSidebar={openWorkspaceSidebar} isSidebar details={details} />}
                    {isLoaded && title === 'Classes' && <ClassificationsTab details={details} type="workspace" id={workspaceId} name={details.name} canEdit={canEdit} filterBy={filterBy} />}
                    {isLoaded && title === 'Entity Resources' && this.buildResourceComponent('primaryClass')}
                    {isLoaded && title === 'Task Resources' && this.buildResourceComponent('task')}
                    {isLoaded && title === 'Process Resources' && this.buildResourceComponent('process')}
                    {isLoaded && title === 'Form Definitions Resources' && this.buildResourceComponent('formdefinition')}
                    {isLoaded && title === 'Process Definitions Resources' && this.buildResourceComponent('processdefinition')}
                    {isLoaded && title === 'Permissions' && this.buildResourceComponent('permission')}
                    {isLoaded && title === 'Scripts' && this.buildResourceComponent('script')}
                    {isLoaded && title === 'Class Resources' && <WorkspaceResourcesList title={title} type="class" id={workspaceId} disabled={!canEdit} list={this.getClassResources(get(details, 'classResources'))}  />}
                    {isLoaded && title === 'Admin' && <WorkspaceResourcesList title={title} type="user" id={workspaceId} disabled={!canEdit} list={get(details, 'owners', [])}  />}
                    {isLoaded && title === 'History' && <WorkspaceHistoryTab id={workspaceId} />}
                    {isLoaded && title === 'Members' && <WorkspaceMembersList details={details} />}
                </>
            )
        );
    }
}

export default connect(
    (state: Object, ownProps: Object) => ({
        profile: state.user.profile,
        details: state.admin.workspaces.details.data,
        isLoading: state.admin.workspaces.details.isLoading,
        isOpen: state.sidebar.isOpen,
        expanded: state.sidebar.expanded,
        windowWidth: state.global.window.width,
        actions: state.sidebar.actions
    }),
    { shrinkSidebar, expandSidebar, loadWorkspace, closeSidebar, setActions, setSubTitle }
)(withPermissions(WorkspaceSidebar, 'workspace'));
