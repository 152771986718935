/* @flow */

import { gql } from '@apollo/client';

export default gql`
    query eventsShortQuery($startIndex: Int, $stopIndex: Int, $filterBy: [JSON], $orderBy: [JSON], $excludeBy: [JSON]) {
        count: count(dataType: "event", filterBy: $filterBy, excludeBy: $excludeBy)
        records: events(startIndex: $startIndex, stopIndex: $stopIndex, filterBy: $filterBy, orderBy: $orderBy, excludeBy: $excludeBy) {
            id
            time
            severity
            device {
                id
                name
                type
            }
            eventType {
                id
                name
                metadata
                script {
                    id
                    type
                    name
                }
                bpmnSignal
            }
        }
    }
`;
