/* @flow */

import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import PageNotAllowed from 'app/containers/ErrorPages/PageNotAllowed';
import GeneralSettings from 'app/containers/Admin/Settings/GeneralSettings';
import AuthenticationMain from 'app/containers/Admin/Settings/Authentication/AuthenticationMain';
import BruteForceDetectionMain from 'app/containers/Admin/Settings/BruteForceDetection/BruteForceDetectionMain';
import IdentityProvidersRoute from 'app/containers/Admin/Settings/IdentityProviders/IdentityProvidersRoute';
import TokenSettingsMain from 'app/containers/Admin/Settings/TokenSettings/TokenSettingsMain';
import { loadSecurityGeneral } from 'store/actions/app/appActions';

const AdminRoutes = (props: Object) => {
    const { userProfile, loadSecurityGeneral } = props;

    useEffect(() => {
        loadSecurityGeneral();
    }, [loadSecurityGeneral]);

    const { permissions, isAdmin } = userProfile;
    const permissionsSet = new Set(permissions || []);
    const canView = isAdmin || permissionsSet.has('admin.settings') || permissionsSet.has('admin.sso');
    if (!canView) {
        return <PageNotAllowed title='Admin Settings' />;
    }
    return (
        <Switch>
            <Route path='/admin/settings/general' component={GeneralSettings} />
            <Route path='/admin/settings/authentication' component={AuthenticationMain} />
            <Route path='/admin/settings/brute-force-detection' component={BruteForceDetectionMain} />
            <Route path='/admin/settings/identity-providers' component={IdentityProvidersRoute} />
            <Route path='/admin/settings/tokens' component={TokenSettingsMain} />
        </Switch>
    );
};

export default connect(
    (state: Object, ownProps: Object): Object => ({
        userProfile: state.user.profile,
    }),
    {
        loadSecurityGeneral,
    }
)(AdminRoutes);
