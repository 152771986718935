/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';

import FormDesigner from 'app/containers/Designer/Form/FormDesigner';
import FormDesignerAttributes from 'app/containers/Designer/Form/FormDesignerAttributes';
import FormValidator from 'app/utils/validator/FormValidator';
import { saveComponentState } from 'store/actions/component/componentActions';
import { get } from 'app/utils/lo/lo';
import { isEmpty, arrayObjectEquals } from 'app/utils/utils';
import { getFieldSettingPanelComponents } from 'app/utils/designer/form/settings/formFieldSettingsUtils';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { readTextFile, readCSVFile } from 'app/utils/datatable/datatableUtils';
import { showToastr } from 'store/actions/app/appActions';
import { setEntityPreviewVersion } from 'store/actions/entities/entitiesActions';

class FormDesignerAbstract extends PureComponent<Object, Object> {

    static propTypes = {
        match: PropTypes.object.isRequired,
        form: PropTypes.object,
        type: PropTypes.string,
        formDesignerState: PropTypes.object,
        saveComponentState: PropTypes.func.isRequired,
    };

    state = {
        isSaving: false,
        errors: null,
    };

    constructor(props) {
        super(props);
        this.initPreviewContext(props.profile);

        const { editor: editorState } = props.formDesignerState || {};
        const { fields } = editorState || {};
        this.validationEteration = 1;
        this.validate(fields);
    }

    componentDidUpdate(prevProps) {
        const { editor: editorState } = this.props.formDesignerState || {};
        const { fields } = editorState || {};
        this.validate(fields);

        const { profile } = this.props;

        if (profile && profile !== prevProps.profile) {
            this.initPreviewContext(profile);
        }
    }

    @bind
    initPreviewContext(profile) {
        const { type } = this.props;
        const { id, login, name, email, image, isAdmin, groups, permissions } = profile || {};
        let context = {
            context: {
                user: { id, login, name, email, image, isAdmin, groups, permissions },

            }
        };
        if(type !== 'class') {
            context = {
                ...context,
                task: {
                    id: '2',
                    name: 'Fake Task Data',
                    assignee: {
                        id: 3,
                        login: 'fake_user',
                        image: null
                    },
                    owner: null,
                    endDate: null
                },
                process: {
                    id: '3',
                    name: 'Fake Process Data',
                    endDate: null
                }
            };
        }
        this.savePreviewState(context);
    }

    @bind
    saveEditorState(stateUpdate: Object, isPreview, isChangedForm) {
        const editorState = get(this.props, 'formDesignerState.editor') || {};
        const draftedEditorState = get(this.props, 'formDesignerState.draftedEditor', null);
        const editor = {
            ...editorState,
            ...stateUpdate,
        };
        const updatedState = { editor, drafted: isChangedForm };
        if(isPreview && !draftedEditorState) {
            updatedState.draftedEditor = editorState;
            updatedState.isPreviewVersion = true;
        } else if(!isPreview) {
            updatedState.draftedEditor = null;
            updatedState.isPreviewVersion = false;
        }        
        this.props.saveComponentState('FormDesigner', updatedState);
    };


    @bind
    backToSaveDraft() {
        const editor = get(this.props, 'formDesignerState.draftedEditor') || {};
        const updatedState = { editor, draftedEditor: null, isPreviewVersion: false, drafted: true };
        this.props.saveComponentState('FormDesigner', updatedState);
        this.props.setEntityPreviewVersion('formdefinition', null);
    };  

    @bind
    savePreviewState(stateUpdate: Object) {
        let preview = get(this.props, 'formDesignerState.preview') || {};
        preview = {
            ...preview,
            ...stateUpdate,
        };
        this.props.saveComponentState('FormDesigner', { preview });
    };

    @bind
    normalizeFieldSettingsPanelComponents(type, settingsValues) {
        const { type: viewType, classification, formDesignerState, entityType } = this.props;
        const { editor: editorState } = formDesignerState || {};
        const { primary, entityPrimaryIndexes } = classification || {};
        let fieldInsideGroupId = 'parent';
        const findChildrens = groupId => (field) => {
            if(field.children) {
                return [...field.children.map(findChildrens(['group', 'groupRepeat'].includes(field.type) ? field.uuid : groupId)), { ...field, groupId }];
            }
            if(field.uuid === settingsValues.uuid && groupId) {
                fieldInsideGroupId = groupId;
            }
            return {...field, groupId };
        };

        
        const referenceExclusion = editorState.fields.map(findChildrens(fieldInsideGroupId)).flat(Infinity).reduce((data, field) => {
            const name = get(field, 'properties.name');
            if(name && field.uuid !== settingsValues.uuid && field.groupId === fieldInsideGroupId) {
                data[name] = name;
            }
            return data;
        }, {});

        const label = viewType === 'class' ? 'Attribute URI' : 'Reference';
        const classData = viewType === 'class' ? classification : null;
        return ((type && getFieldSettingPanelComponents(type, { ...settingsValues, viewType, classification: classData, entityType })) || []).map((panel) => {
            if(!panel.children) {
                return panel;
            }

            panel.children = panel.children.map((field) => {
                if(field?.properties?.name === 'properties.name') {
                    const classAttrCustom = {
                        custom: (data) => {
                            if (!/^[0-9a-z\-_/]+$/g.test(data?.properties?.name)) {
                                return { format: {
                                    pattern: /somefaketest/,
                                    message: 'Only the following characters are allowed: Lowercase letters, Numbers, Dashes, Underscores, Slashes.'
                                }};
                            }
                            if(viewType === 'class') {
                                if(!data?.properties?.name?.includes(classification?.uri)) {
                                    return { format: {
                                        pattern: /somefaketest/,
                                        message: `${label} should include classification URI(${classification?.uri}).`
                                    }};
                                }
                                if(data?.properties.name === `${classification?.uri}/` || data?.properties?.name === `${classification?.uri}`) {
                                    return { format: {
                                        pattern: /somefaketest/,
                                        message: `${label} could not include just ${primary ? 'entity type URI' : 'classification URI'}.`
                                    }};
                                }
                            }
                            return {};
                        }
                    };
                    const classAttrExclusion = {
                        exclusion: {
                            within: referenceExclusion,
                            message: '{label} must be unique.'
                        }
                    };
                    field = {
                        ...field,
                        properties: {
                            ...field.properties,
                            label,
                        },
                        constraints: {
                            required: true,
                            ...(viewType === 'class' ? {
                                ...(fieldInsideGroupId === 'parent' ? classAttrCustom : {}),
                                ...classAttrExclusion,
                            } : {})
                        }
                    };
                    if (primary && viewType === 'class') {
                        const indexed = !!entityPrimaryIndexes?.find(t => t?.state === 'created' && t?.primaryAttribute === settingsValues?.properties?.name);
                        if (indexed) {
                            field.properties.disabled = true;
                            field.properties.helperText = 'You need to remove the index to rename the attribute.';
                        }
                    }
                }
                return { ...field };
            });
            return panel;
        });
    }

    @bind
    async validateField(errorsMap, field) {
        if (!field) {
            return null;
        }
        const { uuid, type, children, properties } = field;
        const components = this.normalizeFieldSettingsPanelComponents(type, field, true)
            .map(panel => panel && panel.children)
            .flat(1)
            .filter(comp => 
                comp?.properties?.name !== 'properties.defaultValue' || 
                comp?.properties?.name === 'properties.defaultValue' && !!comp?.properties?.defaultValue 
            );

        const validator = new FormValidator(components);
        const valid = await validator.isValid(field);
        
        if (!valid) {
            errorsMap[uuid] = validator.getMessages();
        }
        if(properties?.static && !properties.defaultValue && !['displayText', 'image'].includes(type)) {
            const defaultValueMsg = 'Default value should not be empty.';
            errorsMap[uuid] = errorsMap[uuid] ? [ ...errorsMap[uuid] , defaultValueMsg] : [defaultValueMsg];
        }

        if (children) {
            await Promise.all(children.map(child => this.validateField(errorsMap, child)));
        }
    }

    @bind
    @memoize(arrayObjectEquals)
    async validate(fields: ?Array<Object>) {
        const errors = {};
        this.validationEteration = this.validationEteration + 1;
        const prevValidationEteration = this.validationEteration;
        
        await Promise.all((fields || []).map(field => this.validateField(errors, field)));

        if(this.validationEteration === prevValidationEteration) {
            this.setState({ errors: isEmpty(errors) ? null : errors });
        }
    };

    @bind
    async uploadDefinition(file) {
        const { showToastr } = this.props;
        let definition = await readTextFile(file);
        try {
            definition = JSON.parse(definition);
            const validator = new FormValidator(definition);
            const valid = await validator.isValid({});
            if(valid && !isEmpty(definition) ) {
                this.saveEditorState({ fields: definition }, false, true);
            } else {
                showToastr({ severity: 'error', detail: isEmpty(definition) ? 'Empty file could not be Uploaded' : 'Validation of the definition is failed.' });
            }
        } catch (e) {
            console.error('[ERROR]', e); // eslint-disable-line no-console
            showToastr({ severity: 'error', detail: 'File format is wrong.' });
        }

    }  

    @bind
    async uploadVersion(file) {
        const { showToastr } = this.props;
        const versionFile = await readCSVFile(file);
        
        try {
            const { primary: { definition }} = versionFile[0];
            const valid = Array.isArray(definition);
            
            if(valid && !isEmpty(definition) ) {
                this.saveEditorState({ fields: definition }, false, true);
            } else {
                showToastr({ severity: 'error', detail: isEmpty(definition) ? 'Empty file could not be Uploaded' : 'File format is wrong.' });
            }

        } catch(err) {
            console.error('[ERROR]', err); // eslint-disable-line no-console
            showToastr({ severity: 'error', detail: 'File format is wrong.' });
        }
    }

    @bind
    @memoize()
    buildTabs(match, location, type, errors) {
        if(type === 'class') {
            return [
            // { active: location.pathname.includes('/attributes'), label: 'Attributes', link: `${match.url}/attributes` },
                { active: location.pathname.includes('/editor'), label: 'Form Layout', link: `${match.url}/attributes/editor` },
                { disabled: !!errors, active: location.pathname.includes('/preview'), label: 'Preview', link: `${match.url}/attributes/preview` },
            ];

        }
        return [
            // { active: location.pathname.includes('/attributes'), label: 'Attributes', link: `${match.url}/attributes` },
            { active: location.pathname.includes('/editor'), label: 'Form Layout', link: `${match.url}/editor` },
            { disabled: !!errors, active: location.pathname.includes('/preview'), label: 'Preview', link: `${match.url}/preview` },
        ];
    }

    render() {
        const { 
            draftedDetails, previewVersion, type, location, match, formDesignerState, 
            formProperties, updateClassification, entityType 
        } = this.props;
        const { errors } = this.state;
        const { editor: editorState, drafted, isPreviewVersion } = formDesignerState || {};
        switch (type) {
            case 'formDefinition':
                return (
                    <FormDesigner
                        match={match}
                        uploadDefinition={this.uploadDefinition}
                        uploadVersion={this.uploadVersion}
                        editorState={editorState}
                        saveEditorState={this.saveEditorState}
                        errors={errors}
                        tabs={this.buildTabs(match, location, type, errors)}
                        designerState={formDesignerState}
                        savePreviewState={this.savePreviewState}
                        normalizeFieldSettingsPanelComponents={this.normalizeFieldSettingsPanelComponents}

                        // Versioning props
                        draftedDetails={draftedDetails}
                        previewVersion={previewVersion}
                        drafted={drafted}
                        isPreviewVersion={!!isPreviewVersion}   
                        backToSaveDraft={this.backToSaveDraft} 
                    />
                );
            case 'class':
                return (
                    <FormDesignerAttributes
                        match={match}
                        uploadDefinition={this.uploadDefinition}
                        uploadVersion={this.uploadVersion}
                        editorState={editorState}
                        saveEditorState={this.saveEditorState}
                        errors={errors}
                        tabs={this.buildTabs(match, location, type, errors)}
                        designerState={formDesignerState}
                        savePreviewState={this.savePreviewState}
                        formProperties={formProperties}
                        updateClassification={updateClassification}
                        normalizeFieldSettingsPanelComponents={this.normalizeFieldSettingsPanelComponents}
                        type={type}
                        entityType={entityType}

                        // Versioning props
                        draftedDetails={draftedDetails}
                        previewVersion={previewVersion}
                        drafted={drafted}
                        isPreviewVersion={!!isPreviewVersion}                        
                        backToSaveDraft={this.backToSaveDraft} 

                    />
                );
            default:
                console.error('Uknown type for AbsctractFormDesigner'); // eslint-disable-line no-console
                break;
        }
    }
}

export default connect(
    (state, props) => ({
        formDesignerState: state.component.state.FormDesigner,
        classification: state.classifications.details.data || {},
        profile: state.user.profile,
        draftedDetails: state.entities.common.draftedDetails['formdefinition'],
        previewVersion: state.entities.common.previewVersion['formdefinition'],
        versions: state.entities.versions.records,
    }),
    {
        saveComponentState,
        showToastr,
        setEntityPreviewVersion
    }
)(withTheme(FormDesignerAbstract, 'formdefinition'));
