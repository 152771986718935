/* @flow */
import React from 'react';

import LayerSidebar from 'app/containers/Sidebars/LayerSidebar';

import {
    buildSidebar as buildSidebarAction,
    closeSidebar as closeSidebarAction
} from 'store/actions/sidebar/sidebarActions';

export const openLayerSidebar = (props: Object) => (dispatch, getState) => {
    return buildSidebarAction({
        title: props.title,
        content: <LayerSidebar { ...props }  />,
        isOpen: true
    })(dispatch, getState);
};

export const closeLayerSidebar = closeSidebarAction;
