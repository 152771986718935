/* @flow */
import React from 'react';

import EntitySidebar from 'app/containers/Sidebars/EntitySidebar';

import {
    buildSidebar as buildSidebarAction,
    closeSidebar as closeSidebarAction,
    setOnBack
} from 'store/actions/sidebar/sidebarActions';

export const openEntitySidebar = ({ id, type, title, reloadList, userAbout, iconName, iconType, ...rest }) => async (dispatch, getState) => {
    return buildSidebarAction({
        title,
        iconName, 
        iconType,
        content:
            <EntitySidebar
                id={id}
                type={type}
                reloadList={reloadList}
                userAbout={userAbout}
                setOnBack={(...args) => setOnBack(...args)(dispatch, getState)}
                openEntitySidebar={(...args) => openEntitySidebar(...args)(dispatch, getState)}
                {...rest}
            />,
        isOpen: true,
    })(dispatch, getState);
};

export const closeEntitySidebar = closeSidebarAction;
