import { gql } from '@apollo/client';

export default gql`
    query licenseQuery {
        license {
            version
            domains
            expiryDate
            attributes
        }
    }
`;
