import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Button } from '@mic3/platform-ui';

import {
    subscribe,
    joinPublicChannel,
} from 'store/actions/chat/chatActions';

import { bind } from 'app/utils/decorators/decoratorUtils';

import Icon from 'app/components/atoms/Icon/Icon';

const SubscribeOnChatWrapper = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 99;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    flex-shrink: 0;
    justify-content: center;
    background: ${({ theme }) => theme.material.colors.background.default};
`;

const IconStyled = styled(Icon)`
    &:before {
        font-size: 95px !important;
        color: ${({theme})=> theme.material.colors.text.caption};
        @media (max-width:481px){
            font-size: 48px !important;
        }
    }
`;

const SecondaryTextStyled = styled.p`
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.15000000596046448px;
    margin: 0 0 10px 0;
    color: ${({theme})=> theme.material.colors.text.secondary};
`;

const ButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    & button {
        margin: 0 5px;
    }
`;

class ChatValidAccessPermission extends PureComponent {
    @bind
    subscribeChat() {
        const { id, type, profile, subscribe } = this.props;
        subscribe(id, type, [profile.id]);
    }

    @bind
    joinPublicChannel() {
        const { joinPublicChannel, id } = this.props;
        joinPublicChannel(id);
    }

    render() {
        const { type, isEntityChat } = this.props;
        const isTeamChat = type === 'team';

        return (
            <SubscribeOnChatWrapper>
                <IconStyled name="lock" />
                <SecondaryTextStyled>
                    {isTeamChat
                        ? `You should join this Team to be able to access this chat.`
                        : `You are currently not a member of this ${
                            isEntityChat ? 'chat' : 'channel'
                        }. ${
                            isEntityChat ? 'Subscribe' : 'Join'
                        } to see the messages and receive notifications.`
                    }
                </SecondaryTextStyled>
                {!isTeamChat && (
                    <ButtonsWrapper>
                        {isEntityChat && (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={this.subscribeChat}
                            >
                                SUBSCRIBE
                            </Button>
                        )}
                        {!isEntityChat && (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={this.joinPublicChannel}
                            >
                                JOIN CHANNEL
                            </Button>
                        )}
                    </ButtonsWrapper>
                )}
            </SubscribeOnChatWrapper>
        );
    }
}

export default connect(
    null,
    {
        subscribe,
        joinPublicChannel,
    }
)(ChatValidAccessPermission);