/* @flow */
import HttpFetch from 'app/utils/http/HttpFetch';
import Immutable from 'app/utils/immutable/Immutable';
import { get } from 'app/utils/lo/lo';
import { notifyBroadcasts } from 'app/utils/notification/notify-broadcast';
import { getArray, isEmpty } from 'app/utils/utils';
import { graphql } from 'graphql/client';
import { notify } from 'app/utils/notification/notification';
import { loadData, mutateData } from 'app/utils/redux/action-utils';
import { loadAvatar as loadEntityAvatar } from 'store/actions/entities/entitiesActions';
import { loadAvatar as loadTaskAvatar } from 'store/actions/entities/tasksActions';
import { loadAvatar as loadProcessAvatar } from 'store/actions/entities/processesActions';
import { loadAvatar as loadTeamAvatar } from 'store/actions/admin/teamsActions';
import { loadAvatar as loadClassAvatar } from 'store/actions/classifications/classificationsActions';
import { loadAvatar as loadWorkspaceAvatar } from 'store/actions/admin/workspacesActions';
import { loadAvatar as loadRelatedEntityAvatar } from 'store/actions/entities/relatedEntitiesActions';
import { loadUserReferences } from 'store/actions/admin/usersActions';

import loadVersionQuery from 'graphql/app/loadVersionQuery';
import loadSecurityGeneralQuery from 'graphql/app/loadSecurityGeneralQuery';
import updateSecurityGeneralMutation from 'graphql/app/updateSecurityGeneralMutation';
import organisationProfileQuery from 'graphql/app/organisationProfileQuery';
import notificationsQuery from 'graphql/app/notificationsQuery';
import organisationConfigurationQuery from 'graphql/app/organisationConfigurationQuery';
import primaryClassesQuery from 'graphql/entities/classifications/primaryClassesQuery';
import licenseQuery from 'graphql/license/licenseQuery';
import loadTermsQuery from 'graphql/app/loadTermsQuery';
import updateTermsMutation from 'graphql/app/updateTermsMutation';
import ssoAddIdentityProviderMutation from 'graphql/app/ssoAddIdentityProviderMutation';
import ssoUpdateIdentityProviderMutation from 'graphql/app/ssoUpdateIdentityProviderMutation';
import ssoDeleteIdentityProviderMutation from 'graphql/app/ssoDeleteIdentityProviderMutation';
import organisationSettingsQuery, { updateOrgSettingsMutation } from 'graphql/app/organisationSettingsQuery';
import { addRoleToData } from 'app/config/rolesConfig';
import { organistationEntityId, settingsEntityType, ssoEntityId, termsEntityId } from 'app/config/config';

export const TOGGLE_NAV = '@@affectli/app/TOGGLE_NAV';
export const OPEN_NAV = '@@affectli/app/OPEN_NAV';
export const CLOSE_NAV = '@@affectli/app/CLOSE_NAV';
export const OPEN_TOUR = '@@affectli/app/OPEN_TOUR';
export const CLOSE_TOUR = '@@affectli/app/CLOSE_TOUR';
export const TOGGLE_NOTIFICATIONS = '@@affectli/app/TOGGLE_NOTIFICATIONS';
export const TOGGLE_CHAT = '@@affectli/app/TOGGLE_CHAT';
export const TOGGLE_SEARCH = '@@affectli/app/TOGGLE_SEARCH';
export const SELECT_THEME = '@@affectli/app/SELECT_THEME';
export const SET_HEADERS = '@@affectli/app/SET_HEADERS';
export const SET_TAB_ACTIONS = '@@affectli/app/SET_TAB_ACTIONS';
export const LOAD_APP_ORGANISATION_STARTED = '@@affectli/app/LOAD_APP_ORGANISATION_IMAGE_STARTED';
export const LOAD_APP_ORGANISATION = '@@affectli/app/LOAD_APP_ORGANISATION_IMAGE';
export const SHOW_TOASTR = '@@affectli/app/SHOW_TOASTR';
export const ERROR_ALERT_MESSAGE = '@@affectli/app/ERROR_ALERT_MESSAGE';
export const LOAD_NOTIFICATIONS_STARTED = '@@affectli/app/LOAD_NOTIFICATIONS_STARTED';
export const LOAD_NOTIFICATIONS = '@@affectli/app/LOAD_NOTIFICATIONS';
export const LOAD_AUTOCOMPLETE_STARTED = '@@affectli/app/LOAD_AUTOCOMPLETE_STARTED';
export const LOAD_AUTOCOMPLETE = '@@affectli/app/LOAD_AUTOCOMPLETE';
export const TOGGLE_APP_HEADERS = '@@affectli/app/TOGGLE_APP_HEADERS';
export const LOAD_AFFECTLI_CONFIGURATION_STARTED = '@@affectli/app/LOAD_AFFECTLI_CONFIGURATION_STARTED';
export const LOAD_AFFECTLI_CONFIGURATION = '@@affectli/app/LOAD_AFFECTLI_CONFIGURATION';
export const LOAD_VERSION_STARTED = '@@affectli/app/LOAD_VERSION_STARTED';
export const LOAD_VERSION = '@@affectli/app/LOAD_VERSION';
export const ACTIVE_SIDEBAR = '@@affectli/app/ACTIVE_SIDEBAR';
export const LOAD_CONFIGURATIONS_STARTED = '@@affectli/app/LOAD_CONFIGURATIONS_STARTED';
export const LOAD_CONFIGURATIONS = '@@affectli/app/LOAD_CONFIGURATIONS';
export const LOAD_LICENSE_STARTED = '@@affectli/app/LOAD_LICENSE_STARTED';
export const LOAD_LICENSE = '@@affectli/app/LOAD_LICENSE';
export const LOAD_TERMS_STARTED = '@@affectli/app/LOAD_TERMS_STARTED';
export const LOAD_TERMS = '@@affectli/app/LOAD_TERMS';
export const UPDATE_TERMS_STARTED = '@@affectli/app/UPDATE_TERMS_STARTED';
export const UPDATE_TERMS = '@@affectli/app/UPDATE_TERMS';

export const LOAD_ALL_PRIMARY_CLASSES_STARTED = '@@affectli/app/LOAD_ALL_PRIMARY_CLASSES_STARTED';
export const LOAD_ALL_PRIMARY_CLASSES = '@@affectli/app/LOAD_ALL_PRIMARY_CLASSES';

export const LOAD_SECURITY_GENERAL_STARTED = '@@affectli/app/LOAD_SECURITY_GENERAL_STARTED';
export const LOAD_SECURITY_GENERAL = '@@affectli/app/LOAD_SECURITY_GENERAL';
export const UPDATE_SECURITY_GENERAL_STARTED = '@@affectli/app/UPDATE_SECURITY_GENERAL_STARTED';
export const UPDATE_SECURITY_GENERAL = '@@affectli/app/UPDATE_SECURITY_GENERAL';

export const UPDATE_PRIMARY_CLASS_IN_CACHE = '@@affectli/app/UPDATE_PRIMARY_CLASS_IN_CACHE';


export const SSO_ADD_IDENTITY_PROVIDER_STARTED = '@@affectli/app/SSO_ADD_IDENTITY_PROVIDER_STARTED';
export const SSO_ADD_IDENTITY_PROVIDER = '@@affectli/app/SSO_ADD_IDENTITY_PROVIDER';

export const LOAD_ORGANISATION_SETTINGS_STARTED = '@@affectli/app/LOAD_ORGANISATION_SETTINGS_STARTED';
export const LOAD_ORGANISATION_SETTINGS = '@@affectli/app/LOAD_ORGANISATION_SETTINGS';

export const REFRESH_ORGANISATION_IMAGE_CACHE = '@@affectli/app/REFRESH_ORGANISATION_IMAGE_CACHE';


export const SET_DOCUMENT_TITLE = '@@affectli/app/SET_DOCUMENT_TITLE';

export const loadAffectliConfiguration = () => async (dispatch, getState) => {
    const config = getState().app.config;
    if (!isEmpty(config)) {
        return config;
    }
    dispatch({ type: LOAD_AFFECTLI_CONFIGURATION_STARTED });
    let payload;
    try {
        payload = await HttpFetch.getResource('config/affectli.json');
        if (!payload || !payload.googleApiKey) {
            payload = new Error('The Affectli configuration file does not contains the API key: the map will not work properly.');
            dispatch({ type: LOAD_AFFECTLI_CONFIGURATION, payload, error: true });
        } else {
            dispatch({ type: LOAD_AFFECTLI_CONFIGURATION, payload });
        }
    } catch (e) {
        payload = new Error('Affectli configuration file not found: the map will not work properly.');
        dispatch({ type: LOAD_AFFECTLI_CONFIGURATION, payload, error: true });
    }
    return payload;
};

/**
 * @id entity/data id
 * @type entity/data type
 * @additionalQueryProps optional, additional data property to query
 */
export const loadAvatar = (id, type, additionalQueryProps = ``) => (dispatch: Function, getState: Function) => {
    switch (type) {
        case 'owners':
        case 'user': return loadUserReferences()(dispatch, getState);
        case 'opentask':
        case 'closedtask':
        case 'task': return loadTaskAvatar(id, additionalQueryProps)(dispatch, getState);
        case 'openprocess':
        case 'closedprocess':
        case 'process': return loadProcessAvatar(id, additionalQueryProps)(dispatch, getState);
        case 'team': return loadTeamAvatar(id)(dispatch, getState);
        case 'workspace': return loadWorkspaceAvatar(id)(dispatch, getState);
        case 'class': return loadClassAvatar(id)(dispatch, getState);
        case 'tree': return loadRelatedEntityAvatar(id)(dispatch, getState);
        case 'entityUser': return loadEntityAvatar(id, 'user')(dispatch, getState);
        case 'entityTypeScript': return loadEntityAvatar(id, 'script')(dispatch, getState);
        default: return loadEntityAvatar(id, type)(dispatch, getState);
    }
};

export const ONLINE = '@@affectli/app/ONLINE';
export const online = () => dispatch =>
    dispatch({ type: ONLINE, meta: { infoMessage: 'You are online.' } });

export const OFFLINE = '@@affectli/app/OFFLINE';
export const offline = () => dispatch =>
    dispatch({ type: OFFLINE, meta: { warnMessage: 'You are offline.' } });

/**
 * TOGGLE_NAV Action
 */
export function toggleNav() {
    return (dispatch: Function): void => {
        dispatch({ type: TOGGLE_NAV });
    };
}

/**
 * Set NAV to open
 */
export function openNav(isOpen) {
    return (dispatch: Function): void => {
        dispatch({ type: OPEN_NAV });
    };
}
/**
 * Set NAV to close
 */
export function closeNav(isOpen) {
    return (dispatch: Function): void => {
        dispatch({ type: CLOSE_NAV });
    };
}

/**
 * Set TOUR to Open
 */
export function openTour() {
    return (dispatch: Function): void => {
        dispatch({ type: OPEN_TOUR });
    };
}
/**
 * Set TOUR to close
 */
export function closeTour() {
    return (dispatch: Function): void => {
        dispatch({ type: CLOSE_TOUR });
    };
}

/**
 * Set CHAT to true or false
 */
export function setChat() {
    return {
        type: TOGGLE_CHAT,
    };
}

/**
 * TOGGLE_CHAT Action
 */
export function toggleChat() {
    return async (dispatch: Function) => {
        dispatch(setChat());
    };
}

/**
 * Set CHAT to true or false
 */
export function setNotifications() {
    return {
        type: TOGGLE_NOTIFICATIONS,
    };
}

/**
 * TOGGLE_CHAT Action
 */
export function toggleNotifications() {
    return async (dispatch: Function) => {
        dispatch(setNotifications());
    };
}
/**
 * Send header object to app headers
 */
export const setHeader = (headers: Object[] = []) => (dispatch: Function): void => {
    dispatch({ type: SET_HEADERS, payload: Immutable(headers) });
};

export const setTabActions = (actions: any) => (dispatch: Function): void => {
    dispatch({ type: SET_TAB_ACTIONS, payload: actions });
};

export const toggleAppHeader = (instruction: Boolean) => (dispatch: Function) => dispatch({ type: TOGGLE_APP_HEADERS, payload: instruction });

export const loadAppOrganisation = () => (dispatch: Function, getState: Function) => {
    dispatch({ type: LOAD_APP_ORGANISATION_STARTED });
    graphql.query({ query: organisationProfileQuery, fetchPolicy: 'no-cache', })
        .then(async (response: Object) => {
            const payload = get(response, 'data.companyProfile');
            dispatch({ type: LOAD_APP_ORGANISATION, payload });
        })
        .catch((error) => {
            dispatch({ type: LOAD_APP_ORGANISATION, payload: error, error: true });
        });
};

export const loadLicense = () => (dispatch: Function, getState: Function) => {
    dispatch({ type: LOAD_LICENSE_STARTED });
    graphql.query({ query: licenseQuery, fetchPolicy: 'no-cache', })
        .then(async (response: Object) => {
            const payload = get(response, 'data.license');
            dispatch({ type: LOAD_LICENSE, payload: Immutable(payload) });
        })
        .catch((error) => {
            dispatch({ type: LOAD_LICENSE, payload: error, error: true });
        });
};

export const loadTerms = (loadRole = false) => (dispatch: Function, getState: Function) => {
    dispatch({ type: LOAD_TERMS_STARTED });
    graphql.query({ query: loadTermsQuery, fetchPolicy: 'no-cache', })
        .then(async (response: Object) => {
            let payload = get(response, 'data.terms', {});
            if(payload && loadRole) {
                payload = await addRoleToData({ ...payload, id: termsEntityId, type: settingsEntityType });
            }
            dispatch({ type: LOAD_TERMS, payload: Immutable(payload || {}) });
        })
        .catch((error) => {
            dispatch({ type: LOAD_TERMS, payload: error, error: true });
        });
};

export const updateTerms = data => (dispatch: Function, getState: Function) => {
    const { id, role, type, ...settings } = data || {};
    dispatch({ type: UPDATE_TERMS_STARTED });
    return graphql.mutate({
        mutation: updateTermsMutation,
        variables: { settings },
    }).then(async (response: Object) => {
        let payload = get(response, 'data.data', {});
        if(payload) {
            payload = await addRoleToData({ ...payload, id: termsEntityId, type: settingsEntityType });
        }
        dispatch({ type: UPDATE_TERMS, payload: Immutable(payload || {}), meta: { successMessage: 'Terms & Conditions updated.'} });
    }).catch((error) => {
        dispatch({ type: UPDATE_TERMS, payload: error, error: true });
    });
};

export const loadConfigurations = () => (dispatch: Function, getState: Function) => {
    dispatch({ type: LOAD_CONFIGURATIONS_STARTED });
    graphql.query({ query: organisationConfigurationQuery, fetchPolicy: 'no-cache', })
        .then(async (response: Object) => {
            dispatch({ type: LOAD_CONFIGURATIONS, payload: Immutable(response.data) || Immutable({}) });
        })
        .catch((error) => {
            dispatch({ type: LOAD_CONFIGURATIONS, payload: error, error: true });
        });
};

/**
 * Loads the notifications about the tasks and the active broadcasts.
 */
export const loadNotifications = () => (dispatch: Function, getState: Function) => {
    dispatch({ type: LOAD_NOTIFICATIONS_STARTED });
    const state = getState();
    const { username } = state.user.profile.primary || {};
    graphql.query({ query: notificationsQuery, fetchPolicy: 'no-cache' })
        .then((response: Object) => {
            // show the broadcast notifications
            const broadcasts = getArray(response, 'data.broadcasts', []);
            notifyBroadcasts(broadcasts);

            // show the task notifications
            const tasks = get(response, 'data.tasks', []);
            tasks.forEach(({ assignee, owner, description, id, name }) => {
                let title;
                if (username === (assignee || {}).login) {
                    title = 'You have a new task assigned to you.';
                } else if (username === (owner || {}).login) {
                    title = 'You are the owner of a task.';
                } else {
                    title = 'You are a member of a task.';
                }
                notify(title, {
                    tag: `task#${id}`,
                    body: `${name} \n ${description || ''}`,
                    data: { link: `/#/abox/task/${id}`, isNewWindow: true },
                });
            });

            dispatch({ type: LOAD_NOTIFICATIONS, payload: Immutable(response.data) || Immutable([]) });
        })
        .catch((error) => {
            dispatch({ type: LOAD_NOTIFICATIONS, payload: Immutable(error), error: true });
        });
};

export const showToastr = (options: Object) => (dispatch: Function) => {
    dispatch({ type: SHOW_TOASTR, payload: options });
};

export const updatePrimaryClassInCache = (payload) => (dispatch: Function) => {
    if(!payload?.primary) return; // Do not update cache if class is not primary
    dispatch({ type: UPDATE_PRIMARY_CLASS_IN_CACHE, payload });
};

export const loadAutocomplete = (query: any, queryOptions: Object) => loadData(LOAD_AUTOCOMPLETE_STARTED, LOAD_AUTOCOMPLETE, query)(queryOptions);

export const loadVersion = () => async (dispatch) => {
    dispatch({ type: LOAD_VERSION_STARTED });
    return graphql.query({ query: loadVersionQuery, fetchPolicy: 'no-cache', errorPolicy: 'all' })
        .then(async (response: Object) => {
            const payload = Immutable(response.data);
            dispatch({type: LOAD_VERSION, payload});
            return payload;
        })
        .catch((error) => {
            const payload = { version: error?.data?.version || null };
            const errorMessage = error?.message;
            dispatch({ type: LOAD_VERSION, error: true, payload, meta: { errorMessage }});
        });
};

export const loadSecurityGeneral =  
    loadData(
        LOAD_SECURITY_GENERAL_STARTED,
        LOAD_SECURITY_GENERAL,
        loadSecurityGeneralQuery,
        (resp) => addRoleToData({ ...(resp?.systemSettings || {}), id: ssoEntityId, type: settingsEntityType })
    );

export const updateSecurityGeneral = data => (dispatch: Function, getState: Function) => {
    const { id, type, role, ...settings } = data || {};
    if(isEmpty(settings)) return;
    dispatch({ type: UPDATE_SECURITY_GENERAL_STARTED });
    return graphql.mutate({
        mutation: updateSecurityGeneralMutation,
        variables: { settings },
    }).then(async (response: Object) => {
        const data = response?.data?.systemSettings || {};
        const payload = await addRoleToData({ ...data, id: ssoEntityId, type: settingsEntityType });
        dispatch({ type: UPDATE_SECURITY_GENERAL, payload, meta: { successMessage: 'Security settings updated successfully.'} });
    }).catch((error) => {
        dispatch({ type: UPDATE_SECURITY_GENERAL, payload: error, error: true });
    });
};

export const ssoAddIdentityProvider = (provider: Object) =>
    mutateData(
        SSO_ADD_IDENTITY_PROVIDER_STARTED,
        SSO_ADD_IDENTITY_PROVIDER,
        ssoAddIdentityProviderMutation,
        'Identity provider successfully created.',
    )({ provider });

export const ssoUpdateIdentityProvider = (provider: Object) =>
    mutateData(
        SSO_ADD_IDENTITY_PROVIDER_STARTED,
        SSO_ADD_IDENTITY_PROVIDER,
        ssoUpdateIdentityProviderMutation,
        'Identity provider successfully updated.',
    )({ provider });

export const ssoDeleteIdentityProvider = (provider: Object) =>
    mutateData(
        SSO_ADD_IDENTITY_PROVIDER_STARTED,
        SSO_ADD_IDENTITY_PROVIDER,
        ssoDeleteIdentityProviderMutation,
        'Identity provider successfully deleted.',
    )({ alias: provider.alias });


export const loadAllPrimaryClasses = (isReload = false) => (dispatch, getState) => {
    const primaryClasses = getState()?.app.allPrimaryClasses?.records;
    if (primaryClasses?.length && !isReload) { // Do not reload all primary classes again if they are already loaded
        return Promise.resolve({ records: primaryClasses });
    }
    return loadData(LOAD_ALL_PRIMARY_CLASSES_STARTED, LOAD_ALL_PRIMARY_CLASSES, primaryClassesQuery(false))()(dispatch, getState);
};

export const refreshOrganisationImage = () => (dispatch, getState) => {
    dispatch({ type: REFRESH_ORGANISATION_IMAGE_CACHE });
};

const _updateOrganisationData = (response) => async dispatch => {
    if(response instanceof Error) return;
    const site = response?.data?.site;
    if(!site) dispatch({ type: LOAD_ORGANISATION_SETTINGS });
    const data = await addRoleToData({ ...site, id: organistationEntityId, type: settingsEntityType });
    dispatch({ type: LOAD_ORGANISATION_SETTINGS, payload: Immutable(data) });
};

export const loadOrganisationSettings = () => (dispatch, getState) => {
    dispatch({ type: LOAD_ORGANISATION_SETTINGS_STARTED });
    graphql.query({ query: organisationSettingsQuery, fetchPolicy: 'no-cache', })
        .then(async (response: Object) => {
            dispatch(_updateOrganisationData(response));
        })
        .catch((error) => {
            dispatch({ type: LOAD_ORGANISATION_SETTINGS, error: true });
        });
};


export const updateOrganisationSettings = (settings) => (dispatch, getState) => {
    const showError = () => {
        dispatch(showToastr({ severity: 'error', detail: 'There is a problem updating the organisation settings, please try again.' }));
    };
    graphql.mutate({ mutation: updateOrgSettingsMutation, variables: { settings } })
        .then((response: Object) => {
            if (response instanceof Error) {
                return showError();
            }
            dispatch(showToastr({ severity: 'success', detail: 'Settings updated.' }));
            dispatch(_updateOrganisationData(response));
            dispatch(loadConfigurations());
        })
        .catch((error) => {
            showError();
        });
};

export const setDocumentTitle = (title: string) => (dispatch: Function, getState): void => {
    const prevTitle = getState().app.documentTitle;
    if(title !== prevTitle) {
        dispatch({ type: SET_DOCUMENT_TITLE, payload: title });
    }
};
