/* @flow */

import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, Switch, Route, Redirect } from 'react-router-dom';
import { IconButton, Tooltip } from '@mic3/platform-ui';
import styled from 'styled-components';

import PageTemplate from 'app/components/templates/PageTemplate';
import { get } from 'app/utils/lo/lo';
import { loadClassification, updateClassification } from 'store/actions/classifications/classificationsActions';
import history from 'store/History';
import EntitiesListView from 'app/containers/Entities/EntitiesList/EntitiesListView';
import ClassificationPrimaryAbout from 'app/components/Classifications/ClassificationDetailAbout/ClassificationPrimaryAbout';
import FormDesignerAbstract from 'app/containers/Designer/Form/FormDesignerAbstract';
import Loader from 'app/components/atoms/Loader/Loader';
import PageNotAllowed from 'app/containers/ErrorPages/PageNotAllowed';
import Icon from 'app/components/atoms/Icon/Icon';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { openClassSidebar } from 'store/actions/entities/classSidebarActions';
import DotMenu from 'app/components/molecules/DotMenu/DotMenu';
import Breadcrumbs from 'app/components/organisms/Breadcrumbs/Breadcrumbs';
import { typeTitlesMultiple, redirectTypes } from 'app/config/typesConfig';
import { isEmpty } from 'app/utils/utils';
import { getPermissions } from 'app/config/rolesConfig';
import { buildDotMenu } from 'app/utils/entity/entityUtils';
import { setDocumentTitle } from 'store/actions/app/appActions';

const LinkStyle = styled(Link)`
    text-decoration: none;
`;

/**
 * Classification container
 */
class ClassificationDetail extends PureComponent<Object, Object> {
    static propTypes: Object = {
        id: PropTypes.string.isRequired,
        isLoading: PropTypes.bool,
        entityType: PropTypes.bool,
        classification: PropTypes.object,
        loadClassification: PropTypes.func,
        updateClassification: PropTypes.func,
        openClassSidebar: PropTypes.func.isRequired,
    };

    static defaultProps = {
        entityType: false,
    };

    constructor(props) {
        super(props);
        props.loadClassification(props.id);
        // we need to memoize the navigation state in the component becouse it will be cleared after some navigation
        const { navigateBackLink, navigateBackTitle } = get(props, 'location.state', {});
        this.navigation = { navigateBackLink, navigateBackTitle };
    }

    componentDidUpdate(prevProps: Object) {
        const { id, classification, setDocumentTitle } = this.props;
        const name = classification?.name; 
        const prevName = prevProps?.classification?.name;
        if(name && name !== prevName){
            setDocumentTitle(name);
        } 
        if (id !== prevProps.id && id !== 'add') {
            this.props.loadClassification(id);
        }
    }

    @bind
    onDotMenuClick(title) {
        const { id, openClassSidebar, classification: { uri } = {}, entityType } = this.props;
        const settings = { title, id, entityType };
        if (title === 'Expand' && this.props.title === 'Entities') {
            return entityType ? history.push(`/entities/${uri}`) : history.push(`/classifications/${id}/entities`);
        }
        if (title === 'Attributes') {
            return entityType
                ? history.push(`/entity-types/${this.props.id}/attributes/editor`)
                : history.push(`/classifications/${this.props.id}/attributes/editor`);
        }
        openClassSidebar(settings);
    }

    @bind
    @memoize()
    dotMenu(details, title: string, pathname: string = '') {
        const { entityType: entityTypePage} = this.props;
        return <DotMenu key={13} onItemClick={this.onDotMenuClick} items={buildDotMenu({ details, title, pathname, entityType: 'class', entityTypePage })} />;
    }

    @bind
    @memoize()
    buildBreadcrumbs(name) {
        if (this.props.entityType === true) {
            return (
                <Breadcrumbs
                    list={[{ link: `/${redirectTypes['entity']}`, title: typeTitlesMultiple['entity'] }, { title: name }]}
                    withGoBack
                />
            );
        }
        return (
            <Breadcrumbs list={[{ link: `/${redirectTypes['class']}`, title: typeTitlesMultiple['class'] }, { title: name }]} withGoBack />
        );
    }

    @bind
    @memoize()
    buildPageTemplateWrapper(navigateBackLink, navigateBackTitle, classification) {
        return ({ children }) => (
            <PageTemplate
                title={get(classification, 'name')}
                subTitle={`#${String(get(classification, 'uri') || '')}`}
                actions={
                    !navigateBackLink ? null : (
                        <LinkStyle to={navigateBackLink}>
                            <Tooltip title={`Go back to ${navigateBackTitle}`} placement='bottom' arrow>
                                <IconButton>
                                    <Icon name='arrow-left-bold-circle' />
                                </IconButton>
                            </Tooltip>
                        </LinkStyle>
                    )
                }
            >
                {children}
            </PageTemplate>
        );
    }

    @bind
    @memoize()
    buildAboutComponent(classification, updateClassification, isSystem, canEdit, sidebarTitle, pathname, isAttributes = false) {
        const { entityType } = this.props;
        return (
            <ClassificationPrimaryAbout
                entityType={entityType}
                classification={classification}
                updateClassification={updateClassification}
                canEdit={!isSystem && canEdit}
                sidebarActions={[this.dotMenu(classification, sidebarTitle, pathname)]}
                breadcrumbLine={this.buildBreadcrumbs(classification.name)}
            />
        );
    }

    /**
     * @override
     */
    render(): Object {
        const { entityType, location, isAdmin, classification, match, isLoading, id, updateClassification, sidebarTitle } = this.props;
        const { pathname } = this.props.location;
        const permissions = getPermissions(classification && classification.role);
        const { canEdit } = permissions || {};
        if ((!isLoading && (isEmpty(classification) || !permissions.canView)) || (!isLoading && isAdmin && !classification?.id)) {
            return <PageNotAllowed title={`${entityType ? 'Entity type.' : 'Classification.'} (ID:${id})`} />;
        }
        if (!isLoading && classification?.primary && !entityType) { // Restrict the user not access entity types from 'classifications' route and vice versa
            return <PageNotAllowed title={`Classification. (ID:${id})`} />;
        } else if (!isLoading && !classification?.primary && entityType) {
            return <PageNotAllowed title={`'Entity type. (ID:${id})`} />;
        }

        const { navigateBackLink, navigateBackTitle } = this.navigation;
        const { isSystem } = classification || {};
        const PageTemplateWrapper = this.buildPageTemplateWrapper(navigateBackLink, navigateBackTitle, classification);

        return (
            <Fragment>
                {isLoading && <Loader absolute backdrop />}
                {classification && classification.id && (
                    <Switch>
                        <Route path={match.url} exact component={() => <Redirect to={`${match.url}/about`} />} />
                        <Route path={entityType ? '/entity-types/:id/about' : '/classifications/:id/about'}>
                            <PageTemplateWrapper>
                                {this.buildAboutComponent(classification, updateClassification, isSystem, canEdit, sidebarTitle, pathname)}
                            </PageTemplateWrapper>
                        </Route>
                        <Route
                            path={`${match.url}/attributes`}
                            render={() => (
                                <FormDesignerAbstract
                                    entityType={entityType}
                                    classification={classification}
                                    updateClassification={updateClassification}
                                    match={match}
                                    location={location}
                                    type='class'
                                    formProperties={this.buildAboutComponent(
                                        classification,
                                        updateClassification,
                                        isSystem,
                                        canEdit,
                                        sidebarTitle,
                                        pathname,
                                        true,
                                        entityType
                                    )}
                                />
                            )}
                        />
                        <Route path={`${match.url}/entities/:type`} exact render={(props) => <EntitiesListView {...props} />} />
                    </Switch>
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = (state: Object, ownProps: Object): Object => {
    return {
        isLoading: state.classifications.details.isLoading,
        classification: state.classifications.details.data || {},
        id: ownProps.match.params.id,
        sidebarTitle: state.sidebar.title,
        isAdmin: state.user.profile.isAdmin,
    };
};

export default connect(mapStateToProps, {
    loadClassification,
    updateClassification,
    openClassSidebar,
    setDocumentTitle
})(ClassificationDetail);
