/* @flow */

import { help } from 'app/utils/designer/form/settings/common/commonHelpers';
import { onLoad, isVisible } from 'app/utils/designer/form/settings/common/commonEvents';
import { flexGrow } from 'app/utils/designer/form/settings/common/commonStyles';
import { label, name } from 'app/utils/designer/form/settings/common/commonBase';

const panels = [
    {
        header: 'Base',
        children: [ name, label,  ],
    },
    {
        header: 'Events',
        children: [
            onLoad,
            isVisible,
        ]
    },
    {
        header: 'Styles',
        children: [ flexGrow ]
    },
    {
        header: 'Helpers',
        children: [ help ]
    },
];

export default {
    defaults: { serialize: true },
    panels: settingValues => panels,
};
