/* @flow */
import React, { PureComponent } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Typography } from '@mic3/platform-ui';

import { loadMapsByModifiedDate } from 'store/actions/dashboard/dashboardActions';
import Widget from 'app/components/atoms/Widget/Widget';
import { bind } from 'app/utils/decorators/decoratorUtils';
import LinkList from 'app/containers/LandingPage/Widgets/LinkList';
import { get } from 'app/utils/lo/lo';
import { baseRoutes } from 'app/config/routesConfig';
import { typeMap } from 'app/config/typesConfig';


const StyledTypography = styled(Typography)`
    font-weight: 400 !important;
    font-style: 12px !important;
    line-height: 16px !important;
    letter-spacing: 0.4px !important;
    color: ${({ theme }) => theme.material.colors.text.secondary} !important;
`;

/**
 * Widget for displaying tasks assigned to the active user
 */
class MapsWidget extends PureComponent<Object, Object> {
    static propTypes = {
        loadMapsByModifiedDate: PropTypes.func,
        isLoading: PropTypes.bool,
        records: PropTypes.array,
        user: PropTypes.object.isRequired,
    };
    defaultFilters = { type: typeMap?.['map'], startIndex: 0, stopIndex: 9 }
    constructor(props) {
        super(props);
        props.loadMapsByModifiedDate(props.user, {...this.defaultFilters});
    }
    @bind
    renderAdditionalInfo(props){
        const modifiedDate =get(props, 'modifiedDate');
        let relativeTime;
        if (modifiedDate){
            relativeTime = moment(modifiedDate).fromNow();
        }
        return <StyledTypography value={0} >{relativeTime || ''}</StyledTypography>;
    }
    render() {
        const { isLoading, records, item } = this.props;
        return (
            <Widget
                title="Maps"
                content={!isLoading && !records?.length ? 'No Results': <LinkList
                    list={records}
                    type={typeMap?.['map']}
                    renderAdditionalInfo={this.renderAdditionalInfo}
                />}
                url={baseRoutes.map}
                loading={isLoading}
                {...item}
            />
        );
    }
}

const mapStateToProps: Function = ( state ) => {
    return {
        user: state.user.profile,
        isLoading: state.dashboard.maps.isLoading,
        records: state.dashboard.maps.records,
    };
};

export default connect(mapStateToProps, { loadMapsByModifiedDate })(MapsWidget);
