
export default (learningUrl) => {
    return [
        {
            label: `Take a tour of `,
            icon: 'web',
        },
        {
            label: 'Help Articles',
            icon: 'library',
            iconType: 'mdi',
            onClick: () => window.open('https://support.affectli.com'),
        },
        {
            label: 'Get Certified',
            icon: 'school',
            onClick: () => window.open(learningUrl || 'https://learn.affectli.com', '_blank'),
        },
        {
            label: 'Get Support',
            icon: 'headset',
            iconType: 'mdi',
            onClick: () => window.open('https://mi-c3.affectli.com/chat/livechat', '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes'),
        },
        {
            label: 'Version: 1.2.0',
            icon: 'information-variant',
            disabled: true
        }
    ];
};