import { gql } from '@apollo/client';


export default gql`query loadMapDetailQuery($type: String!, $id: ID!) {
  result: entity(type: $type, id: $id) {
        type
         id    
         name
         _geoJson
          iconName  
          iconColor
          modifiedBy {
              id
              name
          }
          primary
          modifiedDate
          createdBy {
              id
              name
              image
          }
          createdDate
    }
}
`;
