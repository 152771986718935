import React, { useState } from 'react';
import { TextField, MdiIcon, IconButton, InputAdornment } from '@mic3/platform-ui';

function PasswordTextField({ value, onChange, ...restProps }) {
    const [showPassword, setShowPassword] = useState(false);

    const handleTogglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    return (
        <TextField
            {...restProps}   
            type={showPassword ? 'text' : 'password'}     
            value={value}
            onChange={onChange}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleTogglePasswordVisibility}
                            edge="end"
                        >
                            {!showPassword ? <MdiIcon name="eye" /> : <MdiIcon name="eye-off" />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );
}

export default PasswordTextField;