import { graphql } from 'graphql/client';

import { loadChatCredentials } from 'store/actions/admin/usersActions';

import deleteChatAttachmentMutation from 'graphql/chat/deleteChatAttachmentMutation';
import subscribeChatMutation from 'graphql/chat/subscribeChatMutation';
import unsubscribeEntityChatMutation from 'graphql/chat/unsubscribeEntityChatMutation';
import entityChatPostMessage from 'graphql/chat/entityChatPostMessage';
import attachmentChatMutation from 'graphql/chat/attachmentChatMutation';

import { addRoleToData, getPermissions } from 'app/config/rolesConfig';
import { notify } from 'app/utils/notification/notification';
import { rocketHost } from 'app/utils/env';
import { getSystemMessageByType } from 'app/utils/chat/chatMessageTypes';
import { isEmpty } from 'app/utils/utils';
import { get } from 'app/utils/lo/lo';
import { cut }from 'app/utils/string/string-utils';
import { mutateData } from 'app/utils/redux/action-utils';
import { isInvalidExtension } from 'app/utils/attachments/attachmentsUtils';
import Immutable from 'app/utils/immutable/Immutable';
import ChatSocket from 'app/utils/chat/ChatSocket';

import { getCredentials, getSubscriptionByRel, getSubscriptionByRid, getUserMeta, isChatExist, 
    normalizeMessage, oneMinute, refreshRate, validTypes } from 'app/utils/chat/chatUtils';
import { createChannel, createDirectMessage, createGroup, favoriteUnfavoriteRoom, fetchChannelList, fetchChannelMembers, fetchChannelRoles, fetchChatHistory, 
    fetchChatMessageById, fetchFiles, fetchGroupMembers, fetchGroupMessageByFileId, fetchGroupRoles, fetchRooms, fetchSubscriptions, fetchUsers, joinChannel, 
    kickFromChannel, kickFromGroup, leaveChannel, leaveGroup, postChatMessage, postUpdateChatMessage, roomFileUpload, setSubscriptionMessagesAsRead 
} from 'app/utils/chat/httpChatApi';

export const TOGGLE_CHAT = '@@affectli/chat/TOGGLE_CHAT';
export const OPEN_CHAT = '@@affectli/chat/OPEN_CHAT';
export const SUBSCRIBE_CHAT_STARTED = '@@affectli/chat/SUBSCRIBE_CHAT_STARTED';
export const SUBSCRIBE_CHAT = '@@affectli/chat/SUBSCRIBE_CHAT';
export const UNSUBSCRIBE_ENTITY_CHAT_STARTED = '@@affectli/chat/UNSUBSCRIBE_ENTITY_CHAT_STARTED';
export const UNSUBSCRIBE_ENTITY_CHAT = '@@affectli/chat/UNSUBSCRIBE_ENTITY_CHAT';
export const SEND_CHAT_ROOM_MESSAGE_STARTED = '@@affectli/chat/SEND_CHAT_ROOM_MESSAGE_STARTED';
export const SEND_CHAT_ROOM_MESSAGE = '@@affectli/chat/SEND_CHAT_ROOM_MESSAGE';
export const LOAD_CHAT_ROOM_FILES_STARTED = '@@affectli/chat/LOAD_CHAT_ROOM_FILES_STARTED';
export const LOAD_CHAT_ROOM_FILES = '@@affectli/chat/LOAD_CHAT_ROOM_FILES';
export const LOAD_CHAT_RECENT_ROOM_FILES_STARTED = '@@affectli/chat/LOAD_CHAT_RECENT_ROOM_FILES_STARTED';
export const LOAD_CHAT_RECENT_ROOM_FILES = '@@affectli/chat/LOAD_CHAT_RECENT_ROOM_FILES';
export const DELETE_CHAT_ATTACHMENT_STARTED = '@@affectli/chat/DELETE_CHAT_ATTACHMENT_STARTED';
export const DELETE_CHAT_ATTACHMENT = '@@affectli/chat/DELETE_CHAT_ATTACHMENT';
export const GET_MESSAGE_BY_FILE_ID_STARTED = '@@affectli/chat/GET_MESSAGE_BY_FILE_ID_STARTED';
export const GET_MESSAGE_BY_FILE_ID = '@@affectli/chat/GET_MESSAGE_BY_FILE_ID';
export const SEND_CHAT_MESSAGE_STARTED = '@@affectli/chat/SEND_CHAT_MESSAGE_STARTED';
export const SEND_CHAT_MESSAGE = '@@affectli/chat/SEND_CHAT_MESSAGE';
export const UPDATE_CHAT_MESSAGE_STARTED = '@@affectli/chat/UPDATE_CHAT_MESSAGE_STARTED';
export const UPDATE_CHAT_MESSAGE = '@@affectli/chat/UPDATE_CHAT_MESSAGE';
export const INIT_CHAT_SOCKET_STARTED = '@@affectli/chat/INIT_CHAT_SOCKET_STARTED';
export const INIT_CHAT_SOCKET = '@@affectli/chat/INIT_CHAT_SOCKET';
export const CHAT_SOCKET = '@@affectli/chat/CHAT_SOCKET';
export const LOAD_ROOM_MEMBERS_STARTED = '@@affectli/chat/LOAD_ROOM_MEMBERS_STARTED';
export const LOAD_ROOM_MEMBERS = '@@affectli/chat/LOAD_ROOM_MEMBERS';
export const RESET_ROOM_INITIAL_REL_STATE = '@@affectli/chat/RESET_ROOM_INITIAL_REL_STATE';
export const REFRESH_CHAT_AUTHENTICATION = '@@affectli/chat/REFRESH_CHAT_AUTHENTICATION';
export const LOAD_SUBSCRIPTIONS_STARTED = '@@affectli/chat/LOAD_SUBSCRIPTIONS_STARTED';
export const LOAD_SUBSCRIPTIONS = '@@affectli/chat/LOAD_SUBSCRIPTIONS';
export const ADD_SUBSCRIPTION = '@@affectli/chat/ADD_SUBSCRIPTION';
export const REMOVE_SUBSCRIPTION = '@@affectli/chat/REMOVE_SUBSCRIPTION';
export const INCREMENT_UNREAD = '@@affectli/chat/INCREMENT_UNREAD';
export const READ_ROOM_MESSAGES = '@@affectli/chat/READ_ROOM_MESSAGES';
export const FAVORITE_UNFAVORITE_CHAT_STARTED = '@@affectli/chat/FAVORITE_UNFAVORITE_CHAT_STARTED';
export const FAVORITE_UNFAVORITE_CHAT = '@@affectli/chat/FAVORITE_UNFAVORITE_CHAT';
export const LOAD_ROOMS_STARTED = '@@affectli/chat/LOAD_ROOMS_STARTED';
export const LOAD_ROOMS = '@@affectli/chat/LOAD_ROOMS';
export const LOAD_ROOM_MESSAGES_STARTED = '@@affectli/chat/LOAD_ROOM_MESSAGES_STARTED';
export const LOAD_ROOM_MESSAGES = '@@affectli/chat/LOAD_ROOM_MESSAGES';
export const ADD_ROOM_MESSAGES = '@@affectli/chat/ADD_ROOM_MESSAGES';
export const EDIT_ROOM_MESSAGES = '@@affectli/chat/EDIT_ROOM_MESSAGES';
export const ADD_ROOM_USERS_STARTED = '@@affectli/chat/ADD_ROOM_USERS_STARTED';
export const ADD_ROOM_USERS = '@@affectli/chat/ADD_ROOM_USERS';
export const SUB_STREAM_ROOM_MESSAGES = '@@affectli/chat/SUB_STREAM_ROOM_MESSAGES';
export const UNSUB_STREAM_ROOM_MESSAGES = '@@affectli/chat/UNSUB_STREAM_ROOM_MESSAGES';
export const LOAD_CHAT_ROOM_FILES_RESET = '@@affectli/chat/UPLOAD_CHAT_ROOM_FILE_RESET';
export const UPLOAD_CHAT_ROOM_FILE_STARTED = '@@affectli/chat/UPLOAD_CHAT_ROOM_FILE_STARTED';
export const UPLOAD_CHAT_ROOM_FILE = '@@affectli/chat/UPLOAD_CHAT_ROOM_FILE';
export const ADD_DIRECT_MESSAGE_STARTED = '@@affectli/chat/ADD_DIRECT_MESSAGE_STARTED';
export const ADD_DIRECT_MESSAGE = '@@affectli/chat/ADD_DIRECT_MESSAGE';
export const CREATE_CHANNEL_STARTED = '@@affectli/chat/CREATE_CHANNEL_STARTED';
export const CREATE_CHANNEL = '@@affectli/chat/CREATE_CHANNEL';
export const LOAD_CHANNEL_MEMBERS_STARTED = '@@affectli/chat/LOAD_CHANNEL_MEMBERS_STARTED';
export const LOAD_CHANNEL_MEMBERS = '@@affectli/chat/LOAD_CHANNEL_MEMBERS';
export const UNSUBSCRIBE_CHANNEL_CHAT_STARTED = '@@affectli/chat/UNSUBSCRIBE_CHANNEL_CHAT_STARTED';
export const UNSUBSCRIBE_CHANNEL_CHAT = '@@affectli/chat/UNSUBSCRIBE_CHANNEL_CHAT';
export const KICK_USER_FROM_CHANNEL_CHAT_STARTED = '@@affectli/chat/KICK_USER_FROM_CHANNEL_CHAT_STARTED';
export const KICK_USER_FROM_CHANNEL_CHAT = '@@affectli/chat/KICK_USER_FROM_CHANNEL_CHAT';
export const LOAD_CHANNEL_ROLES_STARTED = '@@affectli/chat/LOAD_CHANNEL_ROLES_STARTED';
export const LOAD_CHANNEL_ROLES = '@@affectli/chat/LOAD_CHANNEL_ROLES';
export const LOAD_CHANNEL_LIST_STARTED = '@@affectli/chat/LOAD_CHANNEL_LIST_STARTED';
export const LOAD_CHANNEL_LIST = '@@affectli/chat/LOAD_CHANNEL_LIST';
export const JOIN_PUBLIC_CHANNEL_STARTED = '@@affectli/chat/JOIN_PUBLIC_CHANNEL_STARTED';
export const JOIN_PUBLIC_CHANNEL = '@@affectli/chat/JOIN_PUBLIC_CHANNEL';
export const LOAD_SPOTLIGHT_STARTED = '@@affectli/chat/LOAD_SPOTLIGHT_STARTED';
export const LOAD_SPOTLIGHT = '@@affectli/chat/LOAD_SPOTLIGHT';
export const CREATE_GROUP_STARTED = '@@affectli/chat/CREATE_GROUP_STARTED';
export const CREATE_GROUP = '@@affectli/chat/CREATE_GROUP';
export const UNSUBSCRIBE_GROUP_CHAT_STARTED = '@@affectli/chat/UNSUBSCRIBE_GROUP_CHAT_STARTED';
export const UNSUBSCRIBE_GROUP_CHAT = '@@affectli/chat/UNSUBSCRIBE_GROUP_CHAT';
export const KICK_USER_FROM_GROUP_CHAT_STARTED = '@@affectli/chat/KICK_USER_FROM_GROUP_CHAT_STARTED';
export const KICK_USER_FROM_GROUP_CHAT = '@@affectli/chat/KICK_USER_FROM_GROUP_CHAT';
export const LOAD_GROUP_ROLES_STARTED = '@@affectli/chat/LOAD_GROUP_ROLES_STARTED';
export const LOAD_GROUP_ROLES = '@@affectli/chat/LOAD_GROUP_ROLES';
export const UPDATE_CHAT_NAME = '@@affectli/chat/UPDATE_CHAT_NAME';
export const REMOVE_CHAT_MEMBERS = '@@affectli/chat/REMOVE_CHAT_MEMBERS';
export const LOAD_RC_USER_LIST_STARTED = '@@affectli/chat/LOAD_RC_USER_LIST_STARTED';
export const LOAD_RC_USER_LIST = '@@affectli/chat/LOAD_RC_USER_LIST';
export const LOAD_ROOM_HISTORY_BY_TYPE_STARTED = '@@affectli/chat/LOAD_ROOM_HISTORY_BY_TYPE_STARTED';
export const LOAD_ROOM_HISTORY_BY_TYPE = '@@affectli/chat/LOAD_ROOM_HISTORY_BY_TYPE';

let chatSocket = null;
let socketInitInterval = null;
let refreshChatAuthenticationInterval = null;
let socketReinitializeInterval = null;

const setChatAuth = async (dispatch: Function, getState: Function) => {
    await loadChatCredentials()(dispatch, getState);
    const { userId, token } = getCredentials(getState());
    if (userId && token) {
        const expires = new Date(Date.now() + (refreshRate + oneMinute)).toUTCString();
        document.cookie = `rc_uid=${userId};expires=${expires};path=/`;
        document.cookie = `rc_token=${token};expires=${expires};path=/`;
    }
    dispatch({ type: REFRESH_CHAT_AUTHENTICATION });
};

const refreshChatAuthentication = async (dispatch: Function, getState: Function) => {
    if (refreshChatAuthenticationInterval) {
        clearInterval(refreshChatAuthenticationInterval);
    }
    refreshChatAuthenticationInterval = setInterval(
        () => setChatAuth(dispatch, getState),
        refreshRate
    );
    await setChatAuth(dispatch, getState);
};

const subscriptionAdded = async (data, credentials, dispatch, getState) => {
    const { fields: { args } = {} } = data;
    const {
        unread,
        _updatedAt: { $date },
        rid,
        fname,
        u: { _id } = {},
        t
    } = args[1];
    const updatedAt = new Date($date).toISOString();
    const roomsResp = await fetchRooms(credentials);
    const room = roomsResp.find(rm => rm._id === rid);
    const customFields = get(room, 'customFields', {});

    if (t === 'p' && !isEmpty(customFields)) {
        const [, name, id] = fname.match(/(.*)\s#([\da-f]{8}-[\da-f]{4}-[\da-f]{4}-[\da-f]{4}-[\da-f]{12}$)/) || [];
        if(!id) {
            return null;
        }
        const isClass = Boolean(get(customFields, 'classId'));
        const isTeam = Boolean(get(customFields, 'teamId'));
        let chatType = '';
        let chatImg = '';
        if (isClass) {
            chatType = 'class';
        } else if (isTeam) {
            chatType = 'team';
        } else {
            chatType = customFields.entityType;
            chatImg = customFields.entityImage;
        }
        const rel = { name, id, type: chatType, image: chatImg };
        const subscription = {
            customFields,
            unread, name, updatedAt, rid, rel, fname, t, ro: room?.ro || false
        };
        if (_id === credentials.userId) {
            dispatch({ type: ADD_SUBSCRIPTION, payload: subscription });
        }
    } else {
        let type;
        switch (t) {
            case 'd':
                type = 'direct';
                break;
            case 'c':
                type = 'channel';
                break;
            case 'p':
                type = 'group';
                break;
            case 'l':
                type = 'live';
                break;
            default:
                break;
        }
        const { _id: id, name, u } = args[1];
        const rel = { id: rid, name: fname || name, type, image: null };
        const roles = room?.u?._id === credentials.userId ? { roles: ['owner'] } : {};
        const subscription = { id, name, fname, rid, unread, updatedAt, rel, t, u, ro: room?.ro || false, ...roles };
        dispatch({ type: ADD_SUBSCRIPTION, payload: subscription });
    }
};

const subscriptionRevoked = (data, credentials, dispatch, getState) => {
    const { fields: { args } = {} } = data;
    const { rid, u: { _id } = {} } = args[1];
    if (_id === credentials.userId) {
        dispatch({ type: REMOVE_SUBSCRIPTION, payload: { rid } });
    }
    unsubscribeStreamRoomMessages(rid);
};

const getRcUsers = async (state, dispatch) => {
    dispatch({ type: LOAD_RC_USER_LIST_STARTED });
    const credentials = getCredentials(state);
    try {
        const users = await fetchUsers({ count: 0, offset: 0, sort: { name: 1 } }, credentials);
        if (!users) {
            throw new Error('No rocket-chat users found.');
        }
        dispatch({ type: LOAD_RC_USER_LIST, payload: Immutable(users) });
        return users;
    } catch (e) {
        console.error('### An error occurs fetching Rocket Chat users: ', e.message); // eslint-disable-line no-console
    }
};

const syncMarkChatAsRead = async (rid, dispatch) => {
    dispatch({ type: READ_ROOM_MESSAGES, payload: Immutable({ rid }) });
};

const messageReceived = async (newMessage, isEdit, unNotify = false, dispatch, getState) => {
    const {
        _id: chatMsgId,
        rid: roomId,
        msg: chatMsg,
        u: { username: senderUsername, name: senderName } = {},
        t: NewMsgType,
        file: { name: filename } = {}
    } = newMessage;
    const subscriptions = get(getState(), 'chat.subscriptions.data');
    const subscription = getSubscriptionByRid(subscriptions, roomId);
    if (!isEmpty(subscription)) {
        const { name: chatName, fname: fChatName, t: MsgType, rel, customFields } = subscription || {};
        const isEntityChat = !isEmpty(customFields) && !Boolean(get(customFields, 'teamId'));
        const notificationMessage = chatMsg || filename;
        let body = '';

        // entity related chat permission checking.
        if (isEntityChat) {
            const { role } = await addRoleToData({ id: rel.id, type: rel.type });
            const permissions = getPermissions(role) || {};
            const { canEdit, canComment } = permissions || {};
            if (!(canEdit || canComment)) {
                return null;
            }
        }

        // chat information changes handling
        if (!isEmpty(NewMsgType)) {
            switch (NewMsgType) {
                case 'r':
                    if (isEntityChat) {
                        const [, updatedName, ] = newMessage.msg.match(/(.*)\s#([\da-f]{8}-[\da-f]{4}-[\da-f]{4}-[\da-f]{4}-[\da-f]{12}$)/) || [];
                        dispatch({ type: UPDATE_CHAT_NAME, payload: { rid: newMessage.rid, updatedName } });
                    } else {
                        dispatch({ type: UPDATE_CHAT_NAME, payload: { rid: newMessage.rid, updatedName: newMessage.msg } });
                    }
                    break;
                case 'ru':
                case 'ul':
                case 'ult':
                    dispatch({ type: REMOVE_CHAT_MEMBERS, payload: { relId: rel.id, username: newMessage.msg } });
                    break;
                default:
                    break;
            }
        }

        // new chat message notification handling
        if (!unNotify) {
            switch (MsgType) {
                case 'd':
                    body = `New message "${cut(notificationMessage, 35)}" from @${senderName || senderUsername} in alive`;
                    notify('New message', { tag: chatMsgId, body, data: { link: `/#/abox/alive/direct/${rel.id}` } });
                    dispatch({ type: INCREMENT_UNREAD, payload: { rid: roomId } });
                    break;
                case 'p':
                    if (isEntityChat) {
                        const [, parsedChatName, ] = fChatName.match(/(.*)\s#([\da-f]{8}-[\da-f]{4}-[\da-f]{4}-[\da-f]{4}-[\da-f]{12}$)/) || [];
                        body = `New message "${cut(notificationMessage, 35)}" from @${senderName || senderUsername} in ${parsedChatName || chatName}`;
                        notify('New message', { tag: chatMsgId, body, data: { link: `/#/abox/alive/${rel.type}/${rel.id}` } });
                    } else {
                        const groupPath = Boolean(get(customFields, 'teamId')) ? 'team' : 'group';
                        body = `New message "${cut(notificationMessage, 35)}" from @${senderName || senderUsername} in alive group ${fChatName || fChatName || chatName}`;
                        notify('New message', { tag: chatMsgId, body, data: { link: `/#/abox/alive/${groupPath}/${rel.id}` } });
                    }
                    dispatch({ type: INCREMENT_UNREAD, payload: { rid: roomId } });
                    break;
                case 'c':
                    body = `New message "${cut(notificationMessage, 35)}" from @${senderName || senderUsername} in alive channel ${fChatName || chatName}`;
                    notify('New message', { tag: chatMsgId, body, data: { link: `/#/abox/alive/channel/${rel.id}` } });
                    dispatch({ type: INCREMENT_UNREAD, payload: { rid: roomId } });
                    break;
                case 'l':
                    body = `New message "${cut(notificationMessage, 35)}" from @${senderName || senderUsername} in alive channel ${fChatName || chatName}`;
                    notify('New message', { tag: chatMsgId, body, data: { link: `/#/abox/alive/live/${rel.id}` } });
                    dispatch({ type: INCREMENT_UNREAD, payload: { rid: roomId } });
                    break;
                default:
                    break;
            }
        }

        const parsedNewMessage = get(newMessage, 't') ? getSystemMessageByType(newMessage) : normalizeMessage(newMessage);

        if (!isEdit) {
            dispatch({ type: ADD_ROOM_MESSAGES, payload: { rel, rid: roomId, message: parsedNewMessage } });
        } else {
            dispatch({ type: EDIT_ROOM_MESSAGES, payload: { rel, rid: roomId, message: parsedNewMessage } });
        }
    }
};

export const initChatSocket = (onlyRunInitialization) => async (dispatch: Function, getState: Function) => {
    dispatch({ type : INIT_CHAT_SOCKET_STARTED });

    try {
        await refreshChatAuthentication(dispatch, getState);

        const state = getState();
        const credentials = getCredentials(state);

        const protocol = window.location.protocol === 'http:' ? 'ws' : 'wss';
        const url = `${protocol}://${rocketHost}/websocket`;
        
        chatSocket = new ChatSocket(url, credentials);
        
        await chatSocket.connect();

        chatSocket.onMessage(({ data }: Object) => {
            switch (data.msg) {
                case 'changed': {
                    const { fields: { args, eventName } = {} } = data;
                    const profile = getState().user.profile;
                    const currentUser = profile.primary.username;

                    if (data.collection === 'stream-room-messages') {
                        const newMessage = args[0];
                        const { _id: msgId, rid: roomId, u: { username: msgSender } = {}, editedAt, msg: newText } = newMessage;
                        const isEdit = !isEmpty(editedAt);
                        if (!isEmpty(newMessage)) {
                            const stateRoomMsg = isChatExist(getState(), msgId, roomId);
                            if (isEdit) {
                                if (stateRoomMsg !== newText) messageReceived(newMessage, isEdit, false, dispatch, getState);
                            } else {
                                if (currentUser !== msgSender) {
                                    messageReceived(newMessage, isEdit, false, dispatch, getState);
                                } else {
                                    if (stateRoomMsg !== newText) messageReceived(newMessage, isEdit, true, dispatch, getState);
                                }
                            }
                        }
                    } else if (eventName.endsWith('/rooms-changed')) {
                        const { _id: rid, lastMessage: newMessage = {} } = args[1] || {};
                        const streamRooms = get(getState(), `chat.socket.streamRoomsMessages`, {});
                        if(isEmpty(streamRooms[rid]) && !isEmpty(newMessage)) {
                            const { _id: msgId, rid: roomId, u: { username: msgSender } = {}, editedAt, msg: newText } = newMessage;
                            const isEdit = !isEmpty(editedAt);
                            if (!isEmpty(newMessage)) {
                                const stateRoomMsg = isChatExist(getState(), msgId, roomId);
                                if (isEdit) {
                                    if (stateRoomMsg !== newText) messageReceived(newMessage, isEdit, false, dispatch, getState);
                                } else {
                                    if(stateRoomMsg !== newText && currentUser !== msgSender) messageReceived(newMessage, isEdit, false, dispatch, getState);
                                }
                            }
                        }
                    } else if (data.collection === 'stream-notify-user' && eventName.endsWith('/subscriptions-changed')) {
                        if (args[0] === 'removed') {
                            subscriptionRevoked(data, credentials, dispatch, getState);
                        } else if (args[0] === 'inserted') {
                            subscriptionAdded(data, credentials, dispatch, getState);
                        }
                        else if (args[0] === 'updated') {
                            const { unread, rid } = args[1] || {};
                            const subscriptions = get(getState(), 'chat.subscriptions.data') || [];
                            const selectedSub = subscriptions.find(sub => sub.rid === rid) || {};
                            const { unread: subUnread } = selectedSub;
                            if (!isEmpty(selectedSub) && subUnread > 0 && unread === 0) {
                                syncMarkChatAsRead(rid, dispatch);
                            }
                        }
                    }
                    break;
                }
                default:
            }
        });

        chatSocket.onError(() => {
            console.error('Chat socket error: '); // eslint-disable-line no-console
            chatSocket = null;
            dispatch({ type : CHAT_SOCKET, payload: { error: true } });
        });

        chatSocket.onClose((e) => {
            console.error('Chat socket closed:'); // eslint-disable-line no-console
            chatSocket = null;
            dispatch({ type : CHAT_SOCKET, payload: { error: true } });
        });

        await dispatch(loadRooms());
        await dispatch(loadSubscriptions());
        await getRcUsers(state, dispatch);

        if (socketInitInterval) {
            clearInterval(socketInitInterval);
        }

        let counter = 0; 
        socketInitInterval = setInterval(() => {
            if (counter >= 8 || !chatSocket) {
                clearInterval(socketInitInterval);
                return;
            } else {
                chatSocket.sendMessage({ msg: 'pong' });
                counter++;
            }
        }, 5000);

        if (socketReinitializeInterval) {
            clearInterval(socketReinitializeInterval);
        }

        socketReinitializeInterval = setInterval(() => {
            if (!chatSocket) {
                clearInterval(socketReinitializeInterval);
                initChatSocket();
            }
        }, 5000);

        dispatch({ type : INIT_CHAT_SOCKET });
    } catch (error) {
        dispatch({ type : INIT_CHAT_SOCKET, error: true, meta: { errorMessage: `Chat: ${error}` }});
    }
};

export const unsubscribeStreamRoomMessages = (rid: string) => async (dispatch: Function, getState: Function) => {
    if (!rid) {
        throw new Error('room id is required');
    }
    const state = getState();
    const streamRooms = get(state, `chat.socket.streamRoomsMessages`, {});
    try {
        if(!isEmpty(streamRooms[rid])) {
            const { id } = streamRooms[rid];
            chatSocket.unsubscribeStreamRoomMessages(id, rid);
            dispatch({ type: UNSUB_STREAM_ROOM_MESSAGES, meta: { rid } });
        }
    } catch (e) {
        return dispatch({
            type: UNSUB_STREAM_ROOM_MESSAGES,
            error: true,
            meta: Immutable({ errorMessage: e.message || `An error occurred on ${favorite ? 'favourite' : 'unfavourite'} room.` })
        });
    }
};

export const subscribeStreamRoomMessages = (rid) => async (dispatch: Function, getState: Function) => {
    if (!rid) {
        throw new Error('room id is required');
    }
    const state = getState();
    const streamRooms = get(state, `chat.socket.streamRoomsMessages`, {});
    try {
        if(isEmpty(streamRooms[rid])) {
            const id = chatSocket.generateUniqueId();
            chatSocket.subscribeStreamRoomMessages(id, rid);
            dispatch({ type: SUB_STREAM_ROOM_MESSAGES, meta: { id, rid } });
        }
    } catch (e) {
        return dispatch({
            type: SUB_STREAM_ROOM_MESSAGES,
            error: true,
            meta: Immutable({ errorMessage: e.message || `An error occurred on ${favorite ? 'favourite' : 'unfavourite'} room.` })
        });
    }
};

export const loadSubscriptions = () => async (dispatch, getState) => {
    const state = getState();
    dispatch({ type: LOAD_SUBSCRIPTIONS_STARTED });
    const credentials = getCredentials(state);
    try {
        const subscriptionsResp = await fetchSubscriptions(credentials);
        const rooms = get(state, `chat.room.data`, []);
        let subscriptions = (get(subscriptionsResp, 'update') || []).map((sub) => {
            const { lastMessage, ...props } = (rooms || []).find(r => r._id === sub.rid);
            return {...sub, ...props, lastMessage: !isEmpty(lastMessage) ? lastMessage : {}};
        }, []);
        subscriptions = subscriptions
            .map((record, i) => {
                const { _id, unread, rid, name: recordName, fname, _updatedAt: updatedAt, customFields, t, u, ...otherProps } = record || {};
                if (t === 'p' && !isEmpty(customFields)) {
                    const [, name, id] = fname.match(/(.*)\s#([\da-f]{8}-[\da-f]{4}-[\da-f]{4}-[\da-f]{4}-[\da-f]{12}$)/) || [];
                    if(!id) {
                        return null;
                    }
                    const isClass = Boolean(get(customFields, 'classId'));
                    const isTeam = Boolean(get(customFields, 'teamId'));
                    let chatType = '';
                    let chatImg = '';
                    if (isClass) {
                        chatType = 'class';
                    } else if (isTeam) {
                        chatType = 'team';
                    } else {
                        chatType = customFields.entityType;
                        chatImg = customFields.entityImage;
                    }
                    const rel = { id, name, type: chatType, image: chatImg };

                    return { id: _id, unread, rid, name: recordName, updatedAt, rel, fname, customFields, t, ...otherProps };
                } else {
                    let type;
                    switch (t) {
                        case 'd':
                            type = 'direct';
                            break;
                        case 'c':
                            type = 'channel';
                            break;
                        case 'p':
                            type = 'group';
                            break;
                        case 'l':
                            type = 'live';
                            break;
                        default:
                            break;
                    }
                    const rel = { id: rid, name: fname || recordName, type, image: null };
                    return { id: _id, name: recordName, fname, rid, unread, updatedAt, rel, t, u, ...otherProps };
                }
            }).filter(Boolean);

        dispatch({ type: LOAD_SUBSCRIPTIONS, payload: Immutable(subscriptions) });
        return subscriptions;
    } catch (e) {
        return dispatch({
            type: LOAD_SUBSCRIPTIONS,
            error: true,
            meta: Immutable({ errorMessage: e.message || 'An error occurred getting the chat subscriptions.' })
        });
    }
};

export const loadRooms = () => async (dispatch, getState) => {
    const state = getState();
    dispatch({ type: LOAD_ROOMS_STARTED });
    const credentials = getCredentials(state);
    try {
        let rooms = await fetchRooms(credentials);
        rooms = (rooms || []).map((room) => {
            const { lastMessage, ...props } = room;
            return {...props, lastMessage: !isEmpty(lastMessage) ? normalizeMessage(lastMessage) : {}};

        });
        dispatch({ type: LOAD_ROOMS, payload: Immutable(rooms) });
        return rooms;
    } catch (e) {
        return dispatch({
            type: LOAD_ROOMS,
            error: true,
            meta: Immutable({ errorMessage: e.message || 'An error occurred getting the chat rooms.' })
        });
    }
};

export const loadRoomMembers = (rel, rid) => async (dispatch, getState) => {
    if (!rel || !rid) {
        throw new Error('rel and rid are required');
    }
    const state = getState();
    dispatch({ type: LOAD_ROOM_MEMBERS_STARTED, meta: Immutable({ rel, rid }) });
    const credentials = getCredentials(state);
    try {
        const data = await fetchGroupMembers({ rid }, credentials);
        const members = data.members
            .filter(({ name, username }) => (name !== 'Administrator' && getUserMeta(state, username)))
            .map(({ _id, username, name, status }) => {
                const { id: userId, image } = getUserMeta(state, username);
                return { id:_id, rel: { id: userId, username, name, image }, status };
            });
        dispatch({ type: LOAD_ROOM_MEMBERS, payload: Immutable({ rel, rid, members }) });
        return data;
    } catch (e) {
        return dispatch({
            type: LOAD_ROOM_MEMBERS,
            error: true,
            meta: Immutable({ errorMessage: e.message || 'An error occurs getting the room members.' })
        });
    }
};

export const loadRoomMessages = ({ rid, rel }: Object, startDate, endDate, limit, skipLastMessageUpdate) => async (dispatch, getState) => {
    try {
        if (!rid || !rel) {
            throw new Error('rid and rel are required');
        }
        dispatch({ type: LOAD_ROOM_MESSAGES_STARTED, meta: { rid, rel } });
        const messageLimit = limit || 50;
        const result = await chatSocket.getRoomHistory(rid, rel, messageLimit, startDate, endDate);
        const concatMessages = Boolean(startDate);
        const messages = get(result, 'messages', []).map(message =>  get(message, 't') ? getSystemMessageByType(message) : normalizeMessage(message));
        dispatch({ type: LOAD_ROOM_MESSAGES, payload: Immutable({ messages, rid, concatMessages, messageLimit, skipLastMessageUpdate })});
    } catch (e) {
        dispatch({
            type: LOAD_ROOM_MESSAGES,
            error: true,
            meta: Immutable({ errorMessage: e.message || 'An error occurred on loading chat messages.' }),
        });
    }
};

export const resetRoomDefaultRelValue = () => async (dispatch) => {
    dispatch({ type: RESET_ROOM_INITIAL_REL_STATE });
};

export const openChat = ({ id, type }) => (dispatch, getState) => {
    if (!id || !validTypes.has(type)) {
        throw new Error('You need to specify a valid rel');
    }
    dispatch({ type: OPEN_CHAT, payload: { rel: { id, type } } });
};

export const toggleChat = () => (dispatch) => {
    dispatch({ type: TOGGLE_CHAT });
};

export const deleteChatAttachment = (id: string, type: string, messageId: string) =>
    mutateData(
        DELETE_CHAT_ATTACHMENT_STARTED,
        DELETE_CHAT_ATTACHMENT,
        deleteChatAttachmentMutation(type),
        'Attachment deleted.',
    )({ id, type, messageId });

export const deleteChatRoomAttachment = (fileId: string) => async (dispatch, getState) => {
    try {
        if (!fileId) {
            throw new Error('File ID is required');
        }
        dispatch({ type: DELETE_CHAT_ATTACHMENT_STARTED, meta: { fileId } });
        const result = await chatSocket.deleteRoomAttachment(fileId);
        dispatch({ type: DELETE_CHAT_ATTACHMENT, payload: Immutable({ fileId }) });
        return result || { fileId };
    } catch (e) {
        const errorMessage = (e.message || '').includes('Not allowed') ? 'You have no permission to perform this operation.' : e.message;
        return dispatch({
            type: DELETE_CHAT_ATTACHMENT,
            error: true,
            meta: Immutable({ errorMessage: errorMessage || 'An error occurred deleting chat attachment.' }),
        });
    }
};

export const updateChatMessage = (message) => async (dispatch: Function, getState: Function) => {
    const state = getState();
    dispatch({ type: UPDATE_CHAT_MESSAGE_STARTED, meta: message });
    const credentials = getCredentials(state);
    try {
        const resMessage = await postUpdateChatMessage({
            roomId: message?.rid,
            msgId: message?._id,
            text: message?.msg
        }, credentials);
        if (!resMessage) {
            throw new Error('Message not found.');
        }
        dispatch({ type: UPDATE_CHAT_MESSAGE, payload: Immutable(message) });
    } catch (e) {
        return dispatch({
            type: UPDATE_CHAT_MESSAGE,
            error: true,
            payload: Immutable(e),
            meta: Immutable({ errorMessage: e.message || 'An error occurred updating chat message.' })
        });
    }
};

export const subscribe = (id, type, userIds, addingOtherUsers = false) => async (dispatch: Function, getState: Function) => {
    const state = getState();
    const subscriptions = get(state, 'chat.subscriptions.data') || [];
    const subscription = getSubscriptionByRel(subscriptions, { id, type });
    if (!addingOtherUsers && subscription) { // if the user is already subscribed
        throw new Error('You already subscribed');
    }
    dispatch({ type: SUBSCRIBE_CHAT_STARTED, meta: { id, type } });
    dispatch({ type: LOAD_CHAT_ROOM_FILES_RESET, meta: { id } });
    return graphql.mutate({
        mutation: subscribeChatMutation(id, type, userIds)
    }).then(async (response: Object) => {
        const payload = response.data;
        await dispatch(loadRooms());
        await dispatch(loadSubscriptions());
        dispatch({ type: SUBSCRIBE_CHAT, payload: Immutable(payload), meta: { id, type } });
        return payload.sub0ChatId;
    }).catch((error: Error) => {
        const message = String(error);
        if (message.includes('be a member')) {
            const meta = Immutable({ infoMessage: message.replace('Error: ', '') });
            dispatch({ type: SUBSCRIBE_CHAT, error: true, meta });
        } else {
            dispatch({ type: SUBSCRIBE_CHAT, error: true, payload: Immutable(error) });
        }
        return Immutable(error);
    });
};

export const setMessagesAsRead = (rid: string) => async (dispatch: Function, getState: Function) => {
    const credentials = getCredentials(getState());
    try {
        await setSubscriptionMessagesAsRead({ rid }, credentials);
        dispatch({ type: READ_ROOM_MESSAGES, payload: Immutable({ rid }) });
    } catch (e) {
        dispatch({
            type: READ_ROOM_MESSAGES,
            error: true,
            meta: Immutable({ errorMessage: e.message || 'An error occurred on mark-as-read message(s).' }),
        });
    }
};

export const setAllSubscriptionAsRead = roomIds => async (dispatch: Function, getState: Function) => {
    const state = getState();
    const credentials = getCredentials(state);
    try {
        const requests = (roomIds || []).map((rid) => {
            return setSubscriptionMessagesAsRead({ rid }, credentials);
        });
        const all = await Promise.all(requests);
        (all || []).forEach(({ success }, index) => {
            if (success) {
                dispatch({
                    type: READ_ROOM_MESSAGES,
                    payload: Immutable({ rid: roomIds[index] })
                });
            }
        });
    } catch (e) {
        dispatch({
            type: READ_ROOM_MESSAGES,
            error: true,
            meta: Immutable({ errorMessage: e.message || 'An error occurred on mark-all-chat-as-read message(s).' }),
        });
    }
};

export const unsubscribeEntityChat = (data: Object) => async (dispatch: Function, getState: Function) => {
    const { id, userId, type, rid, isKick = false } = data || {};
    const payload = { id, userId, isKick };
    if (type !== 'class') {
        payload.type = type;
    }
    const response = await mutateData(
        UNSUBSCRIBE_ENTITY_CHAT_STARTED,
        UNSUBSCRIBE_ENTITY_CHAT,
        unsubscribeEntityChatMutation(type)
    )(payload)(dispatch);
    const chatId = get(response, 'chatId');
    if (!isKick) unsubscribeStreamRoomMessages(rid);
    return chatId;
};

export const sendRoomMessage = ({ message: text, rid, entityType, rel }) => async (dispatch: Function, getState: Function) => {
    const state = getState();
    const credentials = getCredentials(state);
    const { type, id } = rel;
    const response = await mutateData(
        SEND_CHAT_ROOM_MESSAGE_STARTED,
        SEND_CHAT_ROOM_MESSAGE,
        entityChatPostMessage(entityType || type)
    )({ id, message: text, type: entityType || type })(dispatch);
    if (!response.data) {
        return null;
    }
    const { id: messageId, chatId } = response.data;
    const resMessage = await fetchChatMessageById({ messageId }, credentials);
    if (!resMessage) {
        throw new Error('Message not found.');
    }
    const msg = normalizeMessage(resMessage);
    dispatch({ type: ADD_ROOM_MESSAGES, payload: {
        rel,
        rid,
        message: msg
    } });
    return { chatId };
};

export const resetRoomFiles = (id) => (dispatch: Function) => {
    dispatch({ type: LOAD_CHAT_ROOM_FILES_RESET, meta: { id } });
};

export const loadRoomFiles = (rid, reqType, query, sort) => async (dispatch: Function, getState: Function) => {
    const state = getState();
    dispatch({ type: LOAD_CHAT_ROOM_FILES_STARTED, meta: rid });
    const credentials = getCredentials(state);
    try {
        const data = await fetchFiles({ rid, reqType, query, sort }, credentials);
        const files = (data.files || []).filter(file => !isEmpty(file.typeGroup));
        dispatch({ type: LOAD_CHAT_ROOM_FILES, payload: Immutable(files) });
        return data;
    } catch (e) {
        return dispatch({
            type: LOAD_CHAT_ROOM_FILES,
            error: true,
            meta: Immutable({ errorMessage: e.message || 'An error occurred on fetching chat files.' }),
        });
    }
};

export const loadRecentRoomFiles = (rid) => async (dispatch: Function, getState: Function) => {
    const state = getState();
    dispatch({ type: LOAD_CHAT_RECENT_ROOM_FILES_STARTED });
    const credentials = getCredentials(state);
    try {
        const data = await fetchFiles({
            rid,
            sort: { uploadedAt: -1 },
            offset: 0,
            count: 10
        }, credentials);
        const files = (data.files || []).filter(file => !isEmpty(file.typeGroup));
        dispatch({ type: LOAD_CHAT_RECENT_ROOM_FILES, payload: Immutable(files) });
        return data;
    } catch (e) {
        return dispatch({
            type: LOAD_CHAT_RECENT_ROOM_FILES,
            error: true,
            meta: Immutable({ errorMessage: e.message || 'An error occurred on fetching chat files.' })
        });
    }
};

export const getMessageByFileId = ({ roomId, fileId }) => async (dispatch: Function, getState: Function) => {
    const state = getState();
    dispatch({ type: GET_MESSAGE_BY_FILE_ID_STARTED });
    const credentials = getCredentials(state);
    try {
        const message = await fetchGroupMessageByFileId({ roomId, fileId }, credentials);
        if (!message) {
            throw new Error('Message not found.');
        }
        dispatch({ type: GET_MESSAGE_BY_FILE_ID, payload: Immutable(message) });
        return message;
    } catch (e) {
        return dispatch({
            type: GET_MESSAGE_BY_FILE_ID,
            error: true,
            meta: Immutable({ errorMessage: e.message || 'An error occurred getting the message by file ID' }),
        });
    }
};

export const getMentionsSpotlight = (rid, searchString, excludeUsernames) => async (dispatch: Function, getState: Function) => {
    dispatch({ type: LOAD_SPOTLIGHT_STARTED });
    try {
        const response = await chatSocket.getRoomSpotlight(rid, searchString, excludeUsernames);
        if (!response) {
            throw new Error('Mentioned users not found.');
        }
        dispatch({ type: LOAD_SPOTLIGHT, payload: Immutable(response?.users) });
        return response?.users || [];
    } catch (e) {
        return dispatch({
            type: LOAD_SPOTLIGHT,
            error: true,
            meta: Immutable({ errorMessage: e.message || 'An error occurred getting the mentioned users' }),
        });
    }
};

export const sendChatMessage = (msg, rel, rid) => async (dispatch: Function, getState: Function) => {
    const state = getState();
    dispatch({ type: SEND_CHAT_MESSAGE_STARTED });
    const credentials = getCredentials(state);
    try {
        const resMessage = await postChatMessage(msg, credentials);
        if (!resMessage) {
            throw new Error('Message not found.');
        }
        const message = normalizeMessage(resMessage);
        dispatch({
            type: SEND_CHAT_MESSAGE,
            payload: {
                rel,
                rid,
                message
            }
        });
        return message;
    } catch (e) {
        return dispatch({
            type: SEND_CHAT_MESSAGE,
            error: true,
            meta: Immutable({ errorMessage: e.message || 'An error occurred sending chat message' }),
        });
    }
};

export const uploadRoomFile = (data: Object) => async (dispatch: Function) => {
    dispatch({ type: UPLOAD_CHAT_ROOM_FILE_STARTED });
    const { id, file, type, ...restVariables } = data;
    if (isInvalidExtension(file)) {
        const warnMessage = 'Invalid file type. Please upload a valid file.';
        dispatch({ type: UPLOAD_CHAT_ROOM_FILE, error: true, meta: Immutable({ warnMessage }) });
        return;
    }
    let errorMessage = 'File upload failed.';
    try {
        // TODO change GQL to get all the info to add the message
        const { data: { data: resData } = {}, errors } = await graphql.upload({
            query: attachmentChatMutation(type),
            variables: { id, type, ...restVariables },
            file,
            map: 'variables.file',
        });
        if (errors) {
            throw new Error(get(errors, '[0].message') || '');
        }
        const message = resData;
        dispatch({ type: INCREMENT_UNREAD, payload: { rid: message.rid } });
        dispatch({
            type: UPLOAD_CHAT_ROOM_FILE,
            payload: Immutable(message),
            meta: Immutable({ successMessage: `${file.name} attached.` }),
        });
        return message;
    } catch (error) {
        if ((error.message || '').includes('413')) {
            errorMessage = `The size of the file exceeds the allowed limit.`;
        } else {
            try {
                errorMessage = JSON.parse(error.message).error;
                errorMessage = errorMessage.replace('[error-file-too-large]', '');
            } catch (e) {
                errorMessage = error?.message || errorMessage;
            }
        }
        dispatch({ type: UPLOAD_CHAT_ROOM_FILE, payload: Immutable(error), error: true, meta: Immutable({ errorMessage }) });
        return error;
    }
};

export const uploadRcRoomFile = (data: Object) => async (dispatch: Function, getState: Function) => {
    const state = getState();
    dispatch({ type: UPLOAD_CHAT_ROOM_FILE_STARTED });
    const credentials = getCredentials(state);
    const { file, filename } = data || {};
    if (isInvalidExtension(file)) {
        const warnMessage = 'Invalid file type. Please upload a valid file.';
        dispatch({ type: UPLOAD_CHAT_ROOM_FILE, error: true, meta: Immutable({ warnMessage }) });
        return;
    }
    let errorMessage = 'File upload failed.';
    try {
        const response = await roomFileUpload(data, credentials);
        if (!response) {
            throw new Error('File upload request failed.');
        }
        const { u: { username } = {}} = response || {};
        const { username: profileUserName } = state.user.profile.primary || {};
        if (profileUserName !== username) dispatch({ type: INCREMENT_UNREAD, payload: { rid: response.rid } });
        dispatch({
            type: UPLOAD_CHAT_ROOM_FILE,
            payload: Immutable(response),
            meta: Immutable({ successMessage: `${filename || file.name} attached.` }),
        });
        return response;
    } catch (error) {
        if ((error.message || '').includes('413')) {
            errorMessage = `The size of the file exceeds the allowed limit.`;
        } else {
            try {
                errorMessage = JSON.parse(error.message).error;
                errorMessage = errorMessage.replace('[error-file-too-large]', '');
            } catch (e) {}
        }
        dispatch({ type: UPLOAD_CHAT_ROOM_FILE, payload: Immutable(error), error: true, meta: Immutable({ errorMessage }) });
        return error;
    }
};

export const addDirectMessage = username => async (dispatch: Function, getState: Function) => {
    if (!username) {
        throw new Error('username is required');
    }
    const state = getState();
    dispatch({ type: LOAD_CHAT_ROOM_FILES_RESET});
    dispatch({ type: ADD_DIRECT_MESSAGE_STARTED, meta: Immutable({ username }) });
    const credentials = getCredentials(state);
    try {
        const data = await createDirectMessage({ username }, credentials);
        const meta = {id: data.rid, type: 'direct'};
        dispatch({ type: ADD_DIRECT_MESSAGE, meta });
        return meta;
    } catch (e) {
        return dispatch({
            type: ADD_DIRECT_MESSAGE,
            error: true,
            meta: Immutable({ errorMessage: e.message || 'An error occurred on create a direct message with another user.' })
        });
    }
};

export const addRoomUsers = (roomId, users) => async (dispatch: Function, getState: Function) => {
    if (!roomId || !users) {
        throw new Error('Room ID and Users are required');
    }
    dispatch({ type: ADD_ROOM_USERS_STARTED, meta: { roomId, users } });
    try {
        const result = await chatSocket.addUsersToRoom(roomId, users);
        dispatch({ type: ADD_ROOM_USERS, payload: Immutable(result) });
        return result;
    } catch (e) {
        return dispatch({
            type: ADD_ROOM_USERS,
            error: true,
            meta: Immutable({ errorMessage: e.message || 'An error occurred on adding new user(s) in the chat' })
        });
    }
};

export const loadGroupRoles = rid => async (dispatch: Function, getState: Function) => {
    if (!rid) {
        throw new Error('Room ID is required');
    }
    const state = getState();
    dispatch({ type: LOAD_GROUP_ROLES_STARTED, meta: Immutable({ rid }) });
    const credentials = getCredentials(state);
    try {
        const data = await fetchGroupRoles(rid, credentials);
        dispatch({ type: LOAD_GROUP_ROLES, payload: Immutable(data) });
        return data;
    } catch (e) {
        return dispatch({
            type: LOAD_GROUP_ROLES,
            error: true,
            meta: Immutable({ errorMessage: e.message || 'An error occurred getting the group roles.' })
        });
    }
};

export const addChannel = data => async (dispatch: Function, getState: Function) => {
    if (!data.name) {
        throw new Error('channel name is required');
    }
    const state = getState();
    dispatch({ type: LOAD_CHAT_ROOM_FILES_RESET });
    dispatch({ type: CREATE_CHANNEL_STARTED, meta: Immutable(data) });
    const credentials = getCredentials(state);
    try {
        const response = await createChannel(data, credentials);
        const meta = { id: response._id, type: 'channel'};
        dispatch({ type: CREATE_CHANNEL, meta });
        return meta;
    } catch (e) {
        return dispatch({
            type: CREATE_CHANNEL,
            error: true,
            meta: Immutable({ errorMessage: e.message || 'An error occurred on create channel.' })
        });
    }
};

export const joinPublicChannel = roomId => async (dispatch: Function, getState: Function) => {
    if (!roomId) {
        throw new Error('Channel Room ID is required');
    }
    const state = getState();
    dispatch({ type: LOAD_CHAT_ROOM_FILES_RESET});
    dispatch({ type: JOIN_PUBLIC_CHANNEL_STARTED, meta: Immutable({ roomId }) });
    const credentials = getCredentials(state);
    try {
        const response = await joinChannel(roomId, credentials);
        const meta = { id: response._id, type: 'channel'};
        dispatch({ type: JOIN_PUBLIC_CHANNEL, meta });
        return meta;
    } catch (e) {
        return dispatch({
            type: JOIN_PUBLIC_CHANNEL,
            error: true,
            meta: Immutable({ errorMessage: e.message || 'An error occurred on joining channel.' })
        });
    }
};

export const loadChannelMembers = (rel, rid) => async (dispatch: Function, getState: Function) => {
    if (!rel || !rid) {
        throw new Error('rel and rid are required');
    }
    const state = getState();
    dispatch({ type: LOAD_CHANNEL_MEMBERS_STARTED, meta: Immutable({ rel, rid }) });
    const credentials = getCredentials(state);
    try {
        const data = await fetchChannelMembers({ rid }, credentials);
        const members = data.members
            .filter(({ name, username }) => (name !== 'Administrator' && getUserMeta(state, username)))
            .map(({ _id, username, name, status }) => {
                const { id: userId, image } = getUserMeta(state, username);
                return { id:_id, rel: { id: userId, username, name, image }, status };
            });
        dispatch({ type: LOAD_CHANNEL_MEMBERS, payload: Immutable({ rel, rid, members }) });
        return data;
    } catch (e) {
        return dispatch({
            type: LOAD_CHANNEL_MEMBERS,
            error: true,
            meta: Immutable({ errorMessage: e.message || 'An error occurred getting the channel members.' })
        });
    }
};

export const loadChannelRoles = rid => async (dispatch: Function, getState: Function) => {
    if (!rid) {
        throw new Error('Room ID is required');
    }
    const state = getState();
    dispatch({ type: LOAD_CHANNEL_ROLES_STARTED, meta: Immutable({ rid }) });
    const credentials = getCredentials(state);
    try {
        const data = await fetchChannelRoles(rid, credentials);
        dispatch({ type: LOAD_CHANNEL_ROLES, payload: Immutable(data) });
        return data;
    } catch (e) {
        return dispatch({
            type: LOAD_CHANNEL_ROLES,
            error: true,
            meta: Immutable({ errorMessage: e.message || 'An error occurred getting the channel roles.' })
        });
    }
};

export const loadChannelList = () => async (dispatch: Function, getState: Function) => {
    const state = getState();
    dispatch({ type: LOAD_CHANNEL_LIST_STARTED });
    const credentials = getCredentials(state);
    try {
        let data = await fetchChannelList(credentials);
        data = (data || []).map((channel) => {
            const { _id, name, fname, t, u, _updatedAt: updatedAt } = channel || {};
            const rel = { id: _id, name: fname || name, type: 'channel', image: null };
            return { id: _id, name, fname: fname || name, rid: _id, unread: 0, updatedAt, rel, t, u };
        });
        dispatch({ type: LOAD_CHANNEL_LIST, payload: Immutable(data) });
        return data;
    } catch (e) {
        return dispatch({
            type: LOAD_CHANNEL_LIST,
            error: true,
            meta: Immutable({ errorMessage: e.message || 'An error occurred getting the channel list.' })
        });
    }
};

export const unsubscribeChannelChat = roomId => async (dispatch: Function, getState: Function) => {
    if (!roomId) {
        throw new Error('Room ID is required');
    }
    const state = getState();
    dispatch({ type: UNSUBSCRIBE_CHANNEL_CHAT_STARTED, meta: Immutable({ roomId }) });
    const credentials = getCredentials(state);
    try {
        const response = await leaveChannel(roomId, credentials);
        const meta = { roomId };
        unsubscribeStreamRoomMessages(roomId);
        dispatch({ type: UNSUBSCRIBE_CHANNEL_CHAT, meta });
        return response;
    } catch (e) {
        return dispatch({
            type: UNSUBSCRIBE_CHANNEL_CHAT,
            error: true,
            meta: Immutable({ errorMessage: e.message || 'An error occurred on unsubscribe channel.' })
        });
    }
};

export const kickFromChannelChat = (roomId, userId) => async (dispatch: Function, getState: Function) => {
    if (!roomId && !userId) {
        throw new Error('Room ID and User ID is required');
    }
    const state = getState();
    const payload = { roomId, userId };
    dispatch({ type: KICK_USER_FROM_CHANNEL_CHAT_STARTED, meta: Immutable(payload) });
    const credentials = getCredentials(state);
    try {
        const response = await kickFromChannel(payload, credentials);
        const meta = {...payload};
        dispatch({ type: KICK_USER_FROM_CHANNEL_CHAT, meta });
        return response;
    } catch (e) {
        return dispatch({
            type: KICK_USER_FROM_CHANNEL_CHAT,
            error: true,
            meta: Immutable({ errorMessage: e.message || 'An error occurred on kicking user from channel.' })
        });
    }
};

export const addGroup = data => async (dispatch: Function, getState: Function) => {
    if (!data.name) {
        throw new Error('group name is required');
    }
    const state = getState();
    dispatch({ type: LOAD_CHAT_ROOM_FILES_RESET});
    dispatch({ type: CREATE_GROUP_STARTED, meta: Immutable(data) });
    const credentials = getCredentials(state);
    try {
        const response = await createGroup(data, credentials);
        const meta = { id: response._id, type: 'group'};
        dispatch({ type: CREATE_GROUP, meta });
        return meta;
    } catch (e) {
        return dispatch({
            type: CREATE_GROUP,
            error: true,
            meta: Immutable({ errorMessage: e.message || 'An error occurred on create group.' })
        });
    }
};

export const unsubscribeGroupChat = roomId => async (dispatch: Function, getState: Function) => {
    if (!roomId) {
        throw new Error('Room ID is required');
    }
    const state = getState();
    dispatch({ type: UNSUBSCRIBE_GROUP_CHAT_STARTED, meta: Immutable({ roomId }) });
    const credentials = getCredentials(state);
    try {
        const response = await leaveGroup(roomId, credentials);
        const meta = { roomId };
        unsubscribeStreamRoomMessages(roomId);
        dispatch({ type: UNSUBSCRIBE_GROUP_CHAT, meta });
        return response;
    } catch (e) {
        return dispatch({
            type: UNSUBSCRIBE_GROUP_CHAT,
            error: true,
            meta: Immutable({ errorMessage: e.message || 'An error occurred on unsubscribe group.' })
        });
    }
};

export const kickFromGroupChat = (roomId, username) => async (dispatch: Function, getState: Function) => {
    if (!roomId && !username) {
        throw new Error('Room ID and Username is required');
    }
    const state = getState();
    const payload = { roomId, username };
    dispatch({ type: KICK_USER_FROM_GROUP_CHAT_STARTED, meta: Immutable(payload) });
    const credentials = getCredentials(state);
    try {
        const response = await kickFromGroup(payload, credentials);
        const meta = {...payload};
        dispatch({ type: KICK_USER_FROM_GROUP_CHAT, meta });
        return response;
    } catch (e) {
        return dispatch({
            type: KICK_USER_FROM_GROUP_CHAT,
            error: true,
            meta: Immutable({ errorMessage: e.message || 'An error occurred on kicking user from group.' })
        });
    }
};

export const setRoomAsFavoriteUnfavorite = (roomId, favorite) => async (dispatch: Function, getState: Function) => {
    const state = getState();
    dispatch({ type: FAVORITE_UNFAVORITE_CHAT_STARTED, meta: Immutable({ roomId, favorite }) });
    const credentials = getCredentials(state);
    try {
        await favoriteUnfavoriteRoom({ roomId, favorite }, credentials);
        dispatch({ type: FAVORITE_UNFAVORITE_CHAT, payload: Immutable({ roomId, favorite }) });
    } catch (e) {
        return dispatch({
            type: FAVORITE_UNFAVORITE_CHAT,
            error: true,
            meta: Immutable({ errorMessage: e.message || `An error occurred on ${favorite ? 'favourite' : 'unfavourite'} room.` })
        });
    }
};

export const getRoomHistoryByType = (queryParams) => async (dispatch: Function, getState: Function) => {
    const state = getState();
    dispatch({ type: LOAD_ROOM_HISTORY_BY_TYPE_STARTED });
    const credentials = getCredentials(state);
    try {
        const resMessages = await fetchChatHistory(queryParams, credentials);
        if (!resMessages) {
            throw new Error('Messages not found.');
        }
        const { rid } = queryParams;
        const messages = get(resMessages, 'messages', []).map(message =>  get(message, 't') ? getSystemMessageByType(message) : normalizeMessage(message));
        dispatch({ type: LOAD_ROOM_HISTORY_BY_TYPE, payload: Immutable({ messages, rid })});
        dispatch({ type: LOAD_ROOM_HISTORY_BY_TYPE, payload: Immutable(messages) });
        return messages;
    } catch (e) {
        return dispatch({
            type: LOAD_ROOM_HISTORY_BY_TYPE,
            error: true,
            meta: Immutable({ errorMessage: e.message || 'An error occurred getting the room message history' }),
        });
    }
};
