/* @flow */
const panels = (settingValues) => [
    {
        type: 'panel',
        properties: {
            header: 'Text Annotation',
            expanded: true,
        },
        children: [
            {
                type: 'text',
                properties: {
                    label: 'Text',
                    name: 'data.title',
                }
            },
            {
                type: 'number',
                properties: {
                    label: 'Size',
                    name: 'data.size',
                }
            },
            {
                type: 'colorPicker',
                properties: {
                    label: 'Colour',
                    name: 'data.color',
                }
            },
            {
                type: 'colorPicker',
                properties: {
                    label: 'Background colour',
                    name: 'data.backgroundColor',
                }
            },
            {
                type: 'button',
                properties: {
                    iconName: 'delete',
                    label: 'Remove annotation',
                    variant: 'outlined',
                    hexcolor: 'rgba(255, 138, 145, 1)',
                    onClick: settingValues.onDeteleClick
                }
            },            
        ],    
    },
];

export default {
    defaults: {},
    panels: settingValues => panels(settingValues),
};
