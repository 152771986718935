import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { loadEntityHistory } from 'store/actions/entities/entitiesActions';

import Changelog from 'app/components/organisms/Changelog/Changelog';

class EntityHistoryTab extends PureComponent<Object, Object> {
    static propTypes = {
        id: PropTypes.string,
        type: PropTypes.string,
        isLoading: PropTypes.bool,
        records: PropTypes.arrayOf(PropTypes.object),
        startIndex: PropTypes.number,
        totalRecords: PropTypes.number
    }

    loadData = ({ id, ...options }) => {
        return this.props.loadEntityHistory(id, options);
    }

    render() {
        const { id, type, isLoading, records, startIndex, totalRecords } = this.props;
        return (
            <Changelog
                type={type}
                id={id}
                isLoading={isLoading}
                startIndex={startIndex}
                records={records}
                loadData={this.loadData}
                totalRecords={totalRecords}
            />
        );
    }
}

export default connect(
    state => ({
        records: state.entities.common.history.records,
        totalRecords: state.entities.common.history.count,
        isLoading: state.entities.common.history.isLoading,
        startIndex: state.entities.common.history.startIndex
    }),
    { loadEntityHistory }
)(EntityHistoryTab);
