/* @flow */
import React, { memo } from 'react';
import { Typography, Tooltip } from '@mic3/platform-ui';
import styled from 'styled-components';

import Link from 'app/components/atoms/Link/Link';
import { baseRoutes } from 'app/config/routesConfig';

const LinkStyled = styled(Link)`
text-decoration: none !important;
`;

const ListItemWrapper = styled.div`
width: 100%;
overflow: hidden;
display: flex;
justify-content: space-between;
align-items: center;
flex-direction: row;
    &:hover{
        background-color:  ${({ theme }) => theme.material.colors.background.hover} !important;
    }
    cursor: pointer !important;
    color: #FFFFFFB3;
    padding: 14px 14px 14px 18px;
    gap: 1rem !important;
`;

const TypographyStyled = styled(Typography)`
    white-space: nowrap;
    overflow:hidden;
    text-overflow: ellipsis;
    width: 100%;
    color:  ${({ theme }) => theme.material.colors.text.primary} !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    letter-spacing: 0.25px !important;
`;
const AdditionalInfoContainer = styled.div`
    width: 75%;
    flex-grow: 1 !important;
    color: ${({ theme }) => theme.material.colors.text.secondary};
    display: flex !important;
    justify-content: end !important;
    & .MuiTypography-root{
        font-size: 12px !important;
        font-weight: 400 !important;
        line-height: 16px !important;
        letter-spacing: 0.4px !important;
        text-align: right;
    };
`;
const LinkList = ({ list, type: linkPath, renderAdditionalInfo }: Object): Array<Object> => (list || []).map(
    ({ id, name, type, ...rest }: Object) => {
        return (
            <LinkStyled key={id} to={type === 'system_map' ? `${baseRoutes.map}/${id}`:`/abox/${linkPath}/${id}`}>
                <Tooltip title={name}>
                    <ListItemWrapper>
                        <TypographyStyled>{name || 'No Name'}</TypographyStyled>
                        {renderAdditionalInfo && <AdditionalInfoContainer lInfoContainer>{renderAdditionalInfo(rest)}</AdditionalInfoContainer>}
                    </ListItemWrapper>
                </Tooltip>
            </LinkStyled>
        );
    }
);

export default memo(LinkList);
