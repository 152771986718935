/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import debouncePromise from 'p-debounce';
import styled from 'styled-components';
import { Badge, Tooltip, MdiIcon } from '@mic3/platform-ui';

import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { isDefined, isEmpty, serialPromises, getType, isObject } from 'app/utils/utils';
import { get, pick } from 'app/utils/lo/lo';
import { prepareFunction, enrichContext } from 'app/utils/designer/form/formUtils';
import worker from 'app/utils/worker/worker';
import { availableActions } from 'app/containers/Designer/Form/components/FormGenerator';
import { formatDate } from 'app/utils/date/date';
import { evaluateFiltereExcludeByValues } from 'app/utils/formatter/graphql-formatter';

const BadgeStyled = styled(Badge)`
    width: 100%;
    display: block !important;
    & > span {
        display: block;
        top: 1rem;
        position: absolute;
    }
`;

const MdiIconStyled = styled(MdiIcon)`
    && {
        overflow: visible;
    }
`;

const FormFeildWrapper = styled.div`
    ${({ flexGrow }) =>  flexGrow || !isDefined(flexGrow) ? 'width: 100%;' : 'flex-grow: 0;'}
`;

export const BadgeComponent = ({ help, helpHtml, children, className }) => { 
    const helperComponent = React.isValidElement(help)
        ? help
        : <div dangerouslySetInnerHTML={{ __html: helpHtml || help || ''}} />;
    return (
        <BadgeStyled className={className} badgeContent={(
            <Tooltip title={helperComponent} placement="top">
                <span><MdiIconStyled size={17} name="help-circle" color="secondary"/></span>
            </Tooltip>
        )} >
            {children}
        </BadgeStyled>
    );};

class FormField extends PureComponent<Object, Object> {

    static propTypes = {
        Component: PropTypes.any.isRequired, // the wrapped component
        changeData: PropTypes.func, // the Form changeData function
        performActions: PropTypes.func, // the Form changeData function
        type: PropTypes.string, // the wrapped component type
        data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]), // the data in the form can be array or object
        context: PropTypes.object, // the form's context
        inherited: PropTypes.shape({
            data: PropTypes.object, // the inherited form data
            path: PropTypes.string, // the path of this data
            index: PropTypes.number, // the index in the path, if any (otherwise is -1)
            changeData: PropTypes.func, // the root form changeData function
        }),
        onClick: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
        onBlur: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
        onChange: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
        onConfirm: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    }

    static events = ['onBlur', 'onChange', 'onConfirm', 'onMouseDown'];
    static customEvents = ['onChange'];

    fnCache: Object;
    componentRef = React.createRef();

    constructor(props: Object) {
        super(props);
        this.fnCache = {};

        // if the prop isVisible is not defined the component will be always visible
        this.state = {
            propsOverwrite: {},
            disabled: true,
            isVisible: !props.isVisible,
            isRequired: props.required,
            value: props.value,
            executingOnClick: false
        };
        this.updateIsVisible();
        this.updateIsRequired();
        this.updateIsDisabled();

        FormField.events
            .filter(event => props[event] && !FormField.customEvents.includes(event))
            // $FlowFixMe
            .forEach(event => this[event] = this.memoizeFunction(event, true));
    }

    validateFn(fn: any) {
        if (typeof fn === Function) {
            return true;
        }
    }

    componentDidMount() {
        if (this.props.onLoad) {
            this.runOnload();
        }
        this.mounted = true;
    }

    componentDidUpdate(prevProps: Object)  {
        const { data, context } = this.props;
        const isVariablesChanged = data !== prevProps.data;
        const isContextChanged = context !== prevProps.context;

        this.updateIsRequired();
        this.updateIsVisible();
        this.updateIsDisabled();
        if (isVariablesChanged || isContextChanged) {
            this.applyContextChanges(this.props, prevProps);
        }

        if (prevProps.value !== this.props.value) {
            this.setState({ value: this.props.value });
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    @bind
    normalizeValue(value) {
        const { type, multiple, valueField } = this.props;
        const hexRegex = /^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/;
        const isStringOrNumber = (val) => getType(val) === 'string' || getType(val) === 'number';
        const isStringOrDate = (val) => getType(val) === 'date' || getType(val) === 'string';
        
        switch (type) {
            case 'duration':
                return getType(value)==='object' && value.isDuration ? value : null
            case 'text':
            case 'textEditor':
            case 'textarea':
                return getType(value) === 'string' ? value : null;
            case 'time':
            case 'date':
            case 'dateTime':
                return getType(value) === 'date' || getType(value) === 'string' ? value : null;
            case  'colorPicker':
                return hexRegex.test(value) ? value : null;
            case 'dateTimeRange':
                if(getType(value)==='array') {
                    return value?.every(isStringOrDate) ? value : null;
                }
                return isObject(value) && value.relative ? value : null;
            case 'number':
                return getType(value) === 'number' || !isNaN(value) ? value : null;
            case 'boolean':
                return getType(value) === 'boolean' ? value : null;
            case 'json': 
                return getType(value)==='array' || getType(value)==='object' ? value : null;
            case 'typeahead':
                if(!isDefined(value)) return null;
                if(multiple){
                    return (getType(value) === 'array' && value?.every((item) => typeof item === 'string' || typeof item === 'number')) ? value : null;
                }
                return  isObject(value) || isStringOrNumber(value) ? value : null;
            case 'workspaceTypeahead':
            case 'userTypeahead':
            case 'teamTypeahead':
            case 'processTypeahead':
            case 'taskTypeahead':
            case 'graphicTypeahead':
            case 'classificationTypeahead':
            case 'entityTypeahead':
                if(multiple){
                    if(valueField){
                        return (getType(value) === 'array' && value?.every((item) => isStringOrNumber(item))) ? value : null;
                    }
                    return Array.isArray(value) && value.every(isObject) ? value : null;
                }
                if(valueField){
                    return isStringOrNumber(value) ? value : null;
                }
                return  isObject(value) ? value : value = null;
        }
        return value;
    }

    @bind
    async runOnload() {
        const { data, context, inherited } = this.props;
        const memoFn: Function = this.memoizeFunction('onLoad');
        if (typeof memoFn === 'function') {
            await memoFn(data || {}, enrichContext(context), this.inherit(inherited));
        }
    }

    @bind
    async applyContextChanges(props, prevProps) {
        const { data, context } = props;
        const isVariablesChanged = data !== prevProps.data;
        const isContextChanged = context !== prevProps.context;
        const eventArr = [];

        if (isVariablesChanged && this.props.onDataChange) {
            eventArr.push('onDataChange');
        }

        if (isContextChanged && this.props.onContextChange) {
            eventArr.push('onContextChange');
        }

        await Promise.all(eventArr.map(async (eventName: string) => {
            const memoFn: Function = this.memoizeFunction(eventName);

            if (typeof memoFn === 'function') {
                const actions = await memoFn(data || {}, enrichContext(context));
                this.performActions(actions);
            }
        }));
    }

    inherit(inherited: Object) {
        return pick(inherited, ['data', 'index', 'path']);
    }

    setState(...args) {
        if(this.mounted) {
            super.setState(...args);
        }
    }

    @bind
    onClick(event: Object) {
        if (event && event.persist) {
            event.persist();
        }

        if (this.state.executingOnClick && this.props.onClick) {
            return;
        }
        
        const { data, context, onBeforeAction, onAfterAction, inherited } = this.props;

        this.setState({ executingOnClick: true }, async () => {
            try {
                if (onBeforeAction) {
                    const memoFn: Function = this.memoizeFunction('onBeforeAction');
                    let reject;

                    if (typeof memoFn === 'function') {
                        const actions = await memoFn(data || {}, enrichContext(context), this.inherit(inherited));
                        reject  = Array.isArray(actions) ? actions.find(({ action }) => action === 'reject') : false;
                    }


                    if (reject) {
                        this.setState({ executingOnClick: false });
                        return;
                    }
                }
                const errors = await this.memoizeFunction('onClick', true)(event);

                if (!errors && onAfterAction) {
                    const memoFn: Function = this.memoizeFunction('onAfterAction');

                    if (typeof memoFn === 'function') {
                        await memoFn(data || {}, enrichContext(context), this.inherit(inherited));
                    }
                }
                this.setState({ executingOnClick: false });
            } catch (e) {
                this.setState({ executingOnClick: false });
                throw e;
            }
        });
    }
    
    @bind
    onMouseDown(e) {
        const { onFormTouched, onMouseDown } = this.props;
        onMouseDown && onMouseDown(e);
        onFormTouched && onFormTouched();
    }

    /**
     * Normalizes and memoize the function in the fnCache.
     *
     * If the function in the property is a string it will run in a sevice worker,
     * otherwise (if the function is Javascript function) it will run in this context.
     */
    @bind
    memoizeFunction(propName: string, isEventHandler?: boolean) {
        const fn = this.props[propName];
        if (!fn) {
            return null;
        }
        const cache = this.fnCache[propName] || {};
        if (cache.fn === fn) {
            return cache.normalized;
        }

        let normalized = null;
        if (typeof fn === 'string') {
            if (isEventHandler) {
                // we need to make the event "serializable" before to pass it to the service worker
                // and we need to inject in the handler the data and the context
                normalized = async (event: Object) => {
                    // make the event "serializable"
                    const { target: { name, value } } = event;
                    const e = { target: { name, value } };

                    const { data, context, inherited } = this.props;
                    const ctx = enrichContext(context);

                    // execute the function in the service worker
                    const actions = await worker(
                        await prepareFunction(fn, true), e, data || {}, ctx, this.inherit(inherited)
                    );
                    // update vars and props
                    await this.performActions(actions);
                    return actions;
                };
            } else {
                normalized = async (...args: Object) => {
                    const actions = await worker(await prepareFunction(fn), ...args);
                    this.performActions(actions);
                    return actions;
                };
            }

        } else if (typeof fn === 'function') {
            // the user function is defined in the platform (hardcoded) and MUST run in this context
            const { data, context, inherited } = this.props;
            const ctx = enrichContext(context);
            normalized = isEventHandler
                ? async (event: Object) => {
                    const actions = await fn(event, data || {}, ctx, this.inherit(inherited));
                    this.performActions(actions);
                    return actions;
                }
                : async (...args: Object) => {
                    const actions = await fn(...args);
                    this.performActions(actions);
                    return actions;
                };
        } else {
            throw new Error(`the property "${propName}" has an invalid value: ${fn}`);
        }

        this.fnCache[propName] = { fn, normalized };

        return normalized;
    }

    updateIsRequired = debouncePromise(async () => {
        const { data, context, inherited, required } = this.props;
        let isRequired = true;

        if (!isDefined(required)) {
            isRequired = false;
        } else if (typeof required === 'boolean') {
            isRequired = required;
        } else {
            const memoFn: Function = this.memoizeFunction('required');
            if (typeof memoFn === 'function') {
                isRequired = await memoFn(data || {}, enrichContext(context), this.inherit(inherited));
            }
        }

        if(this.state.isRequired !== !!isRequired) {
            this.setState({ isRequired: !!isRequired });
        }
    }, 600);

    updateIsVisible = debouncePromise(async () => {
        const { data, context, inherited } = this.props;
        let isVisible = true;

        if (!isDefined(this.props.isVisible)) {
            isVisible = true;
        } else if (typeof this.props.isVisible === 'boolean') {
            isVisible = this.props.isVisible;
        } else {
            const memoFn: Function = this.memoizeFunction('isVisible');

            if (typeof memoFn === 'function') {
                isVisible = await memoFn(data || {}, enrichContext(context), this.inherit(inherited));
            }
        }

        if(this.state.isVisible !== !!isVisible) {
            this.setState({ isVisible: !!isVisible });
        }
    }, 600);

    updateIsDisabled = debouncePromise(async () => {
        const { data, context, inherited, isdesigner, static: staticProp  } = this.props;
        let disabled = this.props.disabled;
        if (!isDefined(this.props.disabled)) {
            disabled = false;
        } else if (typeof this.props.disabled === 'boolean') {
            if(this.state.disabled !== this.props.disabled) {
                disabled = this.props.disabled;
            }
        } else {
            const memoFn: Function = this.memoizeFunction('disabled');
            if (typeof memoFn === 'function') {
                disabled = await memoFn(data || {}, enrichContext(context), this.inherit(inherited));
            }
        }

        if(!!staticProp && !isdesigner || this.state.executingOnClick){
            disabled = true;
        }

        if(this.state.disabled !== disabled) {
            this.setState({ disabled });
        }
    }, 600);

    @bind
    performActions(data: Array<Object>) {
        if (!Array.isArray(data)) {
            return;
        }
        const validActions = data.filter(item =>
            item
            && typeof item === 'object'
            && (item.action || (item.name && item.value !== undefined))
        );

        // update the data
        const updatedData = validActions.filter(({ action }) => !action || action === 'setVariable');
        serialPromises(updatedData, (change) => {
            this.props.changeData(change);
        });

        // update the component's properties
        const properties = validActions
            .filter(({ action }) => action === 'setProperty')
            .reduce((props, { name, value }) => {
                props[name] = value;
                return props;
            }, {});
        if (!isEmpty(properties)) {
            const propsOverwrite = { ...this.state.propsOverwrite, ...properties };
            this.setState(({ propsOverwrite }));
        }

        if (get(this.props, 'inherited.changeData')) {
            // update the global data
            const updatedData = validActions.filter(({ action }) => action === 'setInheritedData');
            serialPromises(updatedData, (change) => {
                this.props.inherited.changeData(change);
            });
        }
        const actions = validActions
            .filter(({ action }) => availableActions.has(action));
        if (!isEmpty(actions)) {
            actions.forEach(act => {
                const { action, options } = act;
                this.props.performActions(action, options);
            });
        }

    }

    propagateChange = debouncePromise(async (event: Object) => {
        if (this.props.onChange) {
            const memoFn = this.memoizeFunction('onChange', true);
            if (typeof memoFn === 'function') {
                const actions = await memoFn(event);
                if (Array.isArray(actions)) {
                    actions.forEach(val => this.props.changeData(val));
                } else {
                    this.props.changeData(actions);
                }
            }
        } else {
            const { target: { name, value } } = event;

            if (value !== this.props.value && this.props.changeData) {
                this.props.changeData({ name, value });
            }
        }
    }, 500);

    @bind
    async onChange(event: Object) {
        if(this.props.onStartChanges)
            this.props.onStartChanges();
        if (event.persist) {
            event.persist();
        }

        const { target: { type, name } } = event;
        const { type: componentType } = this.props;
        /*
         * FIXME: remove this logic. Must be inside of the component
         */
        let value = event.target.value;
        if (value === '') {
            value = null;
        }

        if ((type === 'checkbox' || type === 'radio') && isDefined(event.target.checked)) {
            value = event.target.checked;
        }

        if (type === 'number' && isDefined(value)) {
            const { decimalPlaces } = this.props;
            if(isDefined(decimalPlaces)) {
                value = Number(Number(value).toFixed(decimalPlaces));
            } else {
                value = Number(value);
            }
        }

        if (value && type === 'dateTime' && typeof value !== 'number') {
            value = value.toISOString();
        }

        if (value && type === 'date') {
            value = formatDate(value, 'YYYY-MM-DD');
        }

        if (!value && componentType === 'colorPicker') {
            value = null;
        }

        this.setState({ value });

        await this.propagateChange({ target: { type, value, name } });
        if(this.props.onEndChanges)
            this.props.onEndChanges();
    }


    /*
     * START
     * Next two functions for field wich has FormGenarator inside and we need validate them too
     * For Example: <FormDefinitionGenerator> component how proper creates ref inside
     */
    @bind
    async setValidateAll(validateAll: boolean) {
        if(this.componentRef?.current?.formRef?.current?.setValidateAll) {
            return this.componentRef.current.formRef.current.setValidateAll(validateAll);
        }
        return null;
    }

    @bind
    validate() {
        if(this.componentRef?.current?.formRef?.current?.validate) {
            return this.componentRef.current.formRef.current.validate();
        }
        return {};
    }
    /*
     * END
     */


    // FIXME we should remove this
    @bind
    @memoize()
    buildComponentProps(props: Object, propsOverwrite: Object) {
        const {
            /* eslint-disable no-unused-vars */
            static: staticProp, onMouseDown, Component, data, changeData, local, action, serialize, withDefault, formFieldProps, selectedIcon,
            context, onLoad, onDataChange, onContextChange, isVisible, type, disabled, readOnlyValue, onFormTouched,
            fetchFunction, required, defaultValue, performActions, onClick, onBeforeAction, onAfterAction, 
            onStartChanges, onEndChanges, pushSubFormReferences, inherited,
            ...restProps
            /* eslint-enable no-unused-vars */
        } = props;
        
        // Checking if required property just for FormGenerator or component
        let componentProps = restProps;

        if (typeof required !== 'function') {
            componentProps = { ...restProps, required };
        }

        if (action && typeof action === 'function') {
            componentProps.action = action;
        }

        if (props.value === undefined) {
            componentProps.value = null;
        }

        const eventsHandlers = (type !== 'panel')
            ? pick(this, FormField.events)
            : {};

        if(type?.includes('typeahead') || type?.includes('Typeahead') || type?.includes('iconSelect')) {
            delete eventsHandlers['onBlur'];
        }

        if (onClick) {
            eventsHandlers.onClick = this.onClick;
        }

        if (onMouseDown) {
            eventsHandlers.onMouseDown = this.onMouseDown;
        }

        if(componentProps.filterBy) {
            componentProps.filterBy = evaluateFiltereExcludeByValues(componentProps.filterBy, { context, data, inherited });
        }
        if(componentProps.excludeBy) {
            componentProps.excludeBy = evaluateFiltereExcludeByValues(componentProps.excludeBy, { context, data, inherited });
        }

        switch (type) {
            case 'uuid': case 'text': case 'dateTime': case 'date': case 'time': case 'icon':
                componentProps.type = type;
                break;
            case 'dateTimeRange':
                if((componentProps.variant === 'filled' || !componentProps.variant)) {
                    componentProps.variant = 'all';
                    componentProps.relative = !!componentProps.value?.relative;
                } else {
                    componentProps.variant = componentProps.variant || 'all';
                    componentProps.relative = isDefined(componentProps.relative) ? componentProps.relative : true;
                }
                break;
            case 'number': {
                const { decimalPlaces, ...restNumberProps } = componentProps; // eslint-disable-line no-unused-vars
                componentProps = { ...restNumberProps, type };
                break;
            }
            case 'displayText':
                componentProps.data = data;
                componentProps.context = context;
                break;
            case 'custom': case 'geotagButton': case 'signalTypeahead': case 'messageTypeahead':
                componentProps.data = data;
                break;
            case 'typeahead':
                if (fetchFunction) {
                    const ctx = enrichContext(context);
                    componentProps.fetchData = async (searchString) => {
                        if(typeof fetchFunction === 'function') {
                            return fetchFunction(searchString);
                        }
                        return worker(await prepareFunction(fetchFunction, true), searchString, data, ctx);
                    };
                }
                break;
            case 'panel':
                componentProps.buildHelperWrapper = this.buildHelperWrapper;
            case 'slider':
                if(componentProps.min > componentProps.max) {
                    componentProps.max = componentProps.min;
                }
                break;
            default:
        }
        return { ...componentProps, ...propsOverwrite, ...eventsHandlers };
    }

    @bind
    @memoize()
    buildHelperWrapper(type, help, builtComponent) {
        const { isHiddenPanel } = this.props;
        const helpHtml = get(help, 'innerHTML');
        if (
            !isHiddenPanel 
            && (
                React.isValidElement(help) || helpHtml || typeof help === 'string'
            )
            && !['panel'].includes(type)
        ) {
            return (
                <BadgeComponent help={help} helpHtml={helpHtml}>
                    {builtComponent}
                </BadgeComponent>
            );
        }

        return builtComponent;
    }

    render() {
        const { Component, hidden, help, formFieldProps, type } = this.props;
        const { propsOverwrite, isVisible, isRequired, value, disabled } = this.state;
        if (!isVisible || hidden) {
            return null;
        }

        const builtComponent = this.buildHelperWrapper(type, help, (
            <Component
                autoComplete="off"
                {...this.buildComponentProps(this.props, propsOverwrite)}
                required={isRequired}
                value={this.normalizeValue(value)}
                disabled={disabled}
                ref={this.componentRef}
            />
        ));

        return (
            <FormFeildWrapper {...(formFieldProps || {})} className={`${formFieldProps?.className || ''} FormField-root`}>
                {builtComponent}
            </FormFeildWrapper>
        );
    }
}

export default FormField;
