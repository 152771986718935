/* @flow */

// $FlowFixMe
import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { muiTheme } from 'app/themes/materialUi';
import { Modal, Grid, IconButton, Chip, Divider, Avatar, Button, Typography }from '@mic3/platform-ui';

import FieldSettingsSidebar from 'app/containers/Designer/Form/Tabs/FormDesignerEditor/Components/FieldSettingsSidebar';
import Layout from 'app/components/Designer/Layout';
import Icon from 'app/components/atoms/Icon/Icon';
import Ellipsis from 'app/components/molecules/Ellipsis/Ellipsis';
import Breadcrumbs from 'app/components/organisms/Breadcrumbs/Breadcrumbs';
import Loader from 'app/components/atoms/Loader/Loader';
import ModalFormGenearator from 'app/components/organisms/ModalFormGenearator/ModalFormGenearator';
import history from 'store/History';
import DotMenu from 'app/components/molecules/DotMenu/DotMenu';

import { pick } from 'app/utils/lo/lo';
import { isEmpty, getStr } from 'app/utils/utils';
import { setDocumentTitle, setTabActions } from 'store/actions/app/appActions';
import { bind, memoize, debounce } from 'app/utils/decorators/decoratorUtils';
import { setSelectedElement } from 'store/actions/form/formActions';
import PipelineEditorDesigner from './PipelineEditorDesigner';
import PipelineEditorLeftSidebar from './PipelineEditorLeftSidebar';
import { getElementSettingPanelComponents } from 'app/utils/designer/pipeline/settings/pipelineElementSettingsUtils';
import { loadEntityWorkspaces, publishEntity } from 'store/actions/entities/entitiesActions';
import { getPermissions } from 'app/config/rolesConfig';
import { openEntitySidebar } from 'store/actions/entities/entitySidebarActions';
import { typeTitlesMultiple, redirectTypes } from 'app/config/typesConfig';
import { getAttachmentUrl } from 'app/utils/attachments/attachmentsUtils';
import { 
    connectorStartPipeline, connectorStopPipeline, connectorRestartPipeline, connectorStatusPipeline,
    loadPipeline, updatePipeline, startPipeline, stopPipeline, runtimeStatusPipeline, loadPipelineStarted 
} from 'store/actions/designer/designerPipelineActions';
import { showToastr } from 'store/actions/app/appActions';
import { buildPipelineMenuItems } from 'app/containers/Designer/Pipeline/Components/PipelineWidgetDotMenu';
import { buildDotMenu } from 'app/utils/entity/entityUtils';
import { getSelectedPrimaryClass } from 'app/utils/classification/classificationUtils';
import { setEntityPreviewVersion, setEntityDraftVersion } from 'store/actions/entities/entitiesActions';
import { readCSVFile } from 'app/utils/datatable/datatableUtils';

const DividerverticalStyled = styled(Divider)`
    margin: 4px 16px !important;
    height: 24px !important;
    align-self: center !important;
    width: 1px;
    background-color: ${({ theme }) => theme.material.colors.white} !important;
`;

const MoreChipStyled = styled(Chip)`
    background:  ${({ theme }) => theme.material.colors.background.fields} !important;
    & .MuiChip-label {
        max-width: 140px;
    }
`;

const IconStyled = styled(Icon)`
    &:before {
        color:${({theme})=> theme.material.colors.text.caption};
        font-size: 6rem !important;
        font-weight: 100 !important;
        @media (max-width: 480px) {
            margin-left: 1rem;
        }
    }
`;

const AvatarStyled = styled(Avatar)`
    width: 30px !important;
    height: 30px !important;
    margin-right: 8px;
    font-size: 1rem !important;
`;

const Contaienr = styled.div`
    display: flex;
    justify-content: space-around;
    height: 90vh;
`;

const ButtonStyled = styled(Button)`
    margin-left: 10px !important;
`;

const StartedText = styled.div`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 100;
    font-size: 60px;
    line-height: 68px;
    letter-spacing: -0.5px;
    color: ${({theme})=> theme.material.colors.text.caption};
    margin-left: 2rem;
`;

const Flex = styled.div`
    display: flex;
    align-items: center;
`;
const GridStyled = styled(Grid)`
    height: 100%;
`;

const SubTitle = styled.div`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 20px;
    color: ${({theme})=> theme.material.colors.text.secondary};
    mix-blend-mode: normal;
    position: absolute;
    margin-top: 1rem;
`;

const GridFlexed = styled(Grid)`
    flex: 1;
`;
const DividerStyled = styled(Divider)`
    width: calc(100% - 25px);
    margin: 0 auto;
`;

const GridButtonsWrapper = styled(Grid)`
    justify-content: end;
    height: 48px;
    padding: 6px 36px 6px 0;
`;

class PipelineEditor extends PureComponent<Object, Object> {

    static propTypes = {
        pipelineStatus: PropTypes.object,
        details: PropTypes.object,
        editorState: PropTypes.object,
        saveEditorState: PropTypes.func,
        errors: PropTypes.object,
    };

    designerWrapperRef: Object = React.createRef();
    settingsSidebarRef: Object = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            drafted: false, 
            pipelineState: [],
            openPropsSidebar: false,
            dataAddComponentModal:  null,
            errors: {},
            leftSidebarTabIndx: 0,
            pipelineStatus: null,
            isMenuNode: null,
            isPreviewVersion: false,
        };
        const { id, loadPipeline, pipelineMonitor, details, loadPipelineStarted } = props;
        loadPipeline(id);
        this.reloadSharing();
        if(pipelineMonitor) {
            this.loadPipelineStatus();
        }
        if(pipelineMonitor && details?.id === id) {
            loadPipelineStarted(id, details.primary['iot_pipeline/runtime_version']);
        }
    }

    componentDidMount() {
        window.onDotMenuIconClick = this.onDotMenuIconClick;
    }

    componentDidUpdate(prevProps, prevState) {
        const { 
            id, details, loadPipelineStarted, loadPipeline, 
            pipelineMonitor, previewVersion, draftedDetails,
            setDocumentTitle, 
        } = this.props;
        const { errors, pipelineState } = this.state;
        const name = details?.name;
        const prevName = prevProps?.details?.name;
        if(name && name !== prevName){
            setDocumentTitle(name);
        }
        

        if (prevProps.id !== id) {
            id && loadPipeline(id);
            this.reloadSharing();
            if(pipelineMonitor) {
                this.loadPipelineStatus();
            }            
        }

        if (pipelineMonitor && prevProps.details !== details && details) {
            loadPipelineStarted(id, details.primary['iot_pipeline/runtime_version']);
        }

        if(prevState.errors !== errors) {
            Object.entries(pipelineState.drawflow.Home.data).forEach(([nodeId, node]) => {
                if(!!node.data.isError !== !!errors[Number(nodeId)]) {
                    this.designerWrapperRef.current.updateNode(nodeId, { 
                        ...node.data,
                        isError: !!errors[Number(nodeId)]
                    });    
                }
            });
        }

        if (previewVersion && previewVersion !== prevProps.previewVersion) {
            const nextState = { 
                isPreviewVersion: !!previewVersion,
                previewGraphSchema: previewVersion.primary['iot_pipeline_version/graph']
            };
            if(!prevState.isPreviewVersion) {
                nextState.draftedPipelineState = this.designerWrapperRef.current.editor.export();
            }

            this.setState(nextState);
        }

        if (draftedDetails && draftedDetails !== prevProps.draftedDetails) {
            this.setState({ 
                drafted: true, 
                isPreviewVersion: false,
                previewGraphSchema: draftedDetails.primary['iot_pipeline_version/graph'],
                pipelineState: { drawflow: draftedDetails.primary['iot_pipeline_version/graph'] }
            });
            this.props.setEntityDraftVersion('iot_pipeline', null);
        }        
    }

    @bind
    onDotMenuIconClick(nodeId){
        this.setState({ isMenuNode: nodeId ? this.getElementById(Number(nodeId)) : null });
    }

    @bind
    async loadPipelineStatus(){
        const { runtimeStatusPipeline, id } = this.props;
        const pipelineStatus = await runtimeStatusPipeline(id);
        if (!(pipelineStatus instanceof Error)) {
            this.setState({ pipelineStatus });
        }
    }
    @bind
    onCloseSidebar(){
        this.setState({ openPropsSidebar: false });
    }

    @bind
    backToSaveDraft() {
        this.props.setEntityPreviewVersion('iot_pipeline', null);
        this.setState({ 
            drafted: true, 
            isPreviewVersion: false,
            previewGraphSchema: this.state.draftedPipelineState.drawflow,
            pipelineState: this.state.draftedPipelineState
        });
    };

    @bind
    updateSelectedElementSettings(settingsValues: Object) {
        const { selectedElement: selectedElementState, selectedElementId } = this.state;
        if (!selectedElementState) {
            return;
        }
        const { data } = settingsValues;
        const { relatedEntity } = data;

        this.designerWrapperRef.current.updateNode(selectedElementId, { 
            ...data, 
            relatedEntity: pick(relatedEntity, ['id', 'name', 'type'])
        });
        
        if(!this.state.drafted) {
            this.setState({ drafted: true });
        }
    }

    @bind
    @debounce()
    updateFormSettings(details: Object) {
        this.props.saveEditorState(details);
    }

    @bind
    getElementById(id) {
        return this.designerWrapperRef.current?.editor?.getNodeFromId(id);
    }

    @bind
    handleOnAddComponent(evnt) {
        const { data: nodeData } = evnt.target.value;
        this.designerWrapperRef.current.addNode(nodeData);
        this.onChangeDiagramState(this.designerWrapperRef.current.editor.export());    
    }

    @bind
    onCloseMenuNodes() {
        var menuList = document.getElementsByClassName('PipelineNodeMenus');
        Array.from(menuList).forEach(elmnt => {
            elmnt.style.display = 'none';
        });
    }

    @bind
    onSelectNode(id: Object) {
        this.onCloseMenuNodes();
        this.designerWrapperRef.current.editor.selectNode(id);
    };
    
    @bind
    onSelectElement(id: Object) {
        this.onCloseMenuNodes();
        const { sidebarTitle } = this.props;
        if(id === true) {
            this.sidebarTitle = sidebarTitle;
            return this.setState({ 
                openPropsSidebar: false, 
                selectedElementId: null, 
                selectedElement: null,
            }); 
        }
        if(this.state.selectedElementId !== id) {
            const { pipelineStatus, pipelineMonitor } = this.props;
            const node = this.getElementById(id)?.data;
            const menuItems = buildPipelineMenuItems({
                pipelineStatus,
                pipelineMonitor,
                node,
                getCurrentConnectorStatus: this.getCurrentConnectorStatus
            });
            this.setState({
                selectedElementId: id, 
                selectedElement: this.getElementById(id),
                leftSidebarTabIndx: 1,
                ...this.buildSidebarState(this.sidebarTitle, id, node, menuItems),
            }, () => {this.sidebarTitle = null;});  
             
        }
    };

    @bind
    @memoize()
    buildSettingsValues(selectedElement) {
        const { type, children, settings, defaults, uuid, ...variables } = selectedElement || {};
        return isEmpty(variables) ? (defaults || {}) : variables;
    };

    @bind
    onChangeDiagramState(pipelineState) {
        this.setState({ pipelineState });
    }

    @bind
    handleOpenAddComponentModal(data) {
        this.setState({ dataAddComponentModal: data });
    }

    @bind
    handleCloseAddComponentModal() {
        this.setState({ dataAddComponentModal: null });
    }

    @bind
    handleChangeTabLeftSidebar(indx) {
        this.setState({ leftSidebarTabIndx: indx });
    }
    @bind
    handleSidebarValidation(errors) {
        const { selectedElementId }= this.state;

        this.setState({ errors: Object.entries({
            ...this.state.errors,
            [selectedElementId]: errors,
        }).reduce((accum, [key, error]) => {
            if(error) {
                accum[key] = error;
            }
            return accum;
        }, {})});
    }

    @bind
    @memoize()
    getSettingsValues(selectedElement) {
        if (!selectedElement) {
            return null;
        }
        const normalizedElement = { ...selectedElement,  properties };
        return normalizedElement;
    }

    @bind
    getCurrentConnectorStatus(pipelineStatus, node) {
        if(node.group === 'connector') {
            return pipelineStatus?.connectors?.find(conn => conn.label === node.title);
        }
        return null;
    }

    @bind
    @debounce(1000)
    async updateConnectorStatus(nodeLabel) {
        const { connectorStatusPipeline, details } = this.props;
        let { pipelineStatus } = this.state;
        const execution = await connectorStatusPipeline(details.id, nodeLabel);
        pipelineStatus = {
            ...pipelineStatus,
            connectors: pipelineStatus.connectors.map(conn => {
                if(conn.label === nodeLabel) {
                    if (!execution || execution instanceof Error) {
                        return {
                            ...conn,
                            error: execution?.message,
                            status: 'deleted',
                            execution: null,
                        };
                    };
                    return {
                        ...conn,
                        status: 'active',
                        error: null,
                        execution
                    };
                }
                return conn;
            })
        };
        this.setState({ pipelineStatus });
    }

    @bind
    buildSidebarState(name, nodeId, node, menuItems, openPropsSidebar = false) {
        const { pipelineMonitor } = this.props;
        let title = name;
        const isInMenu = menuItems.find(menu => menu.name === name);
        if(!isInMenu) {
            title = pipelineMonitor ? 'About' : 'Edit attributes';
        }

        const newProps = { 
            sidebarProps: {
                title,
                subTitle: node.title,
                subTitleRight: node.group,
                iconName: menuItems.find(item => item.name === name)?.icon,
                iconType: menuItems.find(item => item.name === name)?.iconType,
                actions: (
                    <DotMenu 
                        items={menuItems}
                        onItemClick={(title) => this.onOutcomeItemDotMenuClick(title, nodeId)}
                    />
                )
            },
            openPropsSidebar, 
            customSettingsType: null,
        };

        switch (title) {
            case 'About':
            case 'Edit attributes':
                return newProps;
                break;
            case 'Status':
                newProps.customSettingsType = node.group === 'topic' ? 'topicStatus' : 'connectorStatus';
                return newProps;
            case 'Log':
                newProps.customSettingsType = 'connectorLog';
                return newProps;
            default:
                break;
        }
    }

    @bind
    async onOutcomeItemDotMenuClick(name, nodeId) {
        this.onCloseMenuNodes();
        this.onDotMenuIconClick();
        const { details, connectorStartPipeline, connectorStopPipeline, connectorRestartPipeline, pipelineStatus, pipelineMonitor } = this.props;

        const id = nodeId || this.state.selectedElement.id;
        const node = this.getElementById(nodeId)?.data;
        const menuItems = buildPipelineMenuItems({
            pipelineStatus,
            pipelineMonitor,
            node,
            getCurrentConnectorStatus: this.getCurrentConnectorStatus
        });

        switch (name) {
            case 'Start':
                if(node.group === 'connector') {
                    await connectorStartPipeline(details.id, node.title);
                    this.updateConnectorStatus(node.title);
                }
                break;
            case 'Stop':
                if(node.group === 'connector') {
                    connectorStopPipeline(details.id, node.title);
                    this.updateConnectorStatus(node.title);
                }
                break;
            case 'Restart':
                if(node.group === 'connector') {
                    connectorRestartPipeline(details.id, node.title);
                    this.updateConnectorStatus(node.title);
                }                
                break;
            case 'About':
            case 'Edit attributes':
            case 'Status':
            case 'Log':
                this.designerWrapperRef.current.editor.selectNode(id);
                this.setState(this.buildSidebarState(name, id, node, menuItems, true));
                break;
            case 'Remove component':
                this.designerWrapperRef.current.removeNode(id);
                this.setState({ selectedElement: null });
                break;
            default:
                break;
        }
    }

    @bind
    onDotMenuClick(title) {
        const { openEntitySidebar, details } = this.props;
        openEntitySidebar({ title, id: details.id, type: details.type });
    }

    @bind
    @memoize()
    getFieldSttingsFormSidebar(selectedElement, customSettingsType, pipelineStatus) {
        const { pipelineMonitor, details } = this.props;
        return selectedElement ? getElementSettingPanelComponents(customSettingsType || selectedElement.data.group, {
            ...selectedElement,
            pipelineMonitor: !!pipelineMonitor,
            details,
            pipelineStatus,
            getCurrentConnectorStatus: this.getCurrentConnectorStatus,
            handleConnectorStatus: (name) => this.onOutcomeItemDotMenuClick(name, selectedElement.id),
            onDeteleClick: () => this.onOutcomeItemDotMenuClick('Remove component', selectedElement.id)
        }) : null;
    }

    @bind
    reloadSharing() {
        const { loadEntityWorkspaces, id } = this.props;
        loadEntityWorkspaces('iot_pipeline', id);
    }

    @bind
    normalizePipelineGraph(definition) {
        return Object.entries(definition.Home.data).reduce((accum, [id, node]) => {
            if(node.data.group === 'topic') {
                accum.topics[node.data.title] = node.data.custom || {};
                const sourceConnectors = (node?.inputs?.input_1?.connections || []).map(({ node: nodeId }) => {
                    const modules = [];
                    const getConnector = (data, id) => {
                        if(data[id].data.group === 'connector') {
                            return data[id];
                        }
                        const input = data[id].inputs.input_1.connections[0];
                        if(!input) {

                        }
                        modules.push(data[id]);
                        return getConnector(data, input.node);
                    };
                    const relatedConnector = getConnector(definition.Home.data, nodeId);
                    const transforms = modules.map(mod => {
                        return {
                            id: mod?.data?.relatedEntity?.id,
                            label: mod.data.title,
                            with: JSON.parse(mod.data?.configuration?.data || '{}')
                        };
                    });
                    return {
                        id: relatedConnector.data?.relatedEntity?.id,
                        label: relatedConnector.data.title,
                        topic: node.data.title,
                        custom: JSON.parse(relatedConnector.data?.configuration?.data || '{}'),
                        transforms
                    };
                        
                });
                const sinkConnectors = (node.outputs?.output_1?.connections || []).map(({ node: nodeId }) => {
                    const modules = [];
                    const getConnector = (data, id) => {
                        if(data[id].data.group === 'connector') {
                            return data[id];
                        }
                        const output = data[id].outputs.output_1.connections[0];
                        if(!output) {

                        }
                        modules.push(data[id]);
                        return getConnector(data, output.node);
                    };
                    const relatedConnector = getConnector(definition.Home.data, nodeId);
                    const transforms = modules.map(mod => {
                        return {
                            id: mod?.data?.relatedEntity?.id,
                            label: mod.data.title,
                            with: JSON.parse(mod.data?.configuration?.data || '{}')
                        };
                    });
                    return {
                        id: relatedConnector.data?.relatedEntity?.id,
                        label: relatedConnector.data.title,
                        topic: node.data.title,
                        custom: JSON.parse(relatedConnector.data?.configuration?.data || '{}'),
                        transforms
                    };
                        
                });
                accum.connectors = [
                    ...sourceConnectors,
                    ...sinkConnectors
                ];                
            }
            return accum;
        }, { topics: {}, connectors: [] });
    }

    @bind
    async handleOnSave(isPublish = false) {
        const { updatePipeline, publishEntity, id } = this.props;
        const graph = this.designerWrapperRef.current.editor.export().drawflow;
        await updatePipeline({ type: 'iot_pipeline', id, primary: {
            'iot_pipeline/definition': this.normalizePipelineGraph(graph),
            'iot_pipeline/graph': graph
        }}, isPublish);
        if(isPublish) {
            await publishEntity(id, 'iot_pipeline', 'Pipeline published.');  
        }
    }

    @bind
    async handleOnSrartStop(isStop = false) {
        const { stopPipeline, startPipeline, id } = this.props;
        if(isStop) {
            await stopPipeline(id);  
            history.push('/pipeline-monitor');
        } else {
            await startPipeline(id);  
        }
    }

    @bind
    @memoize()
    buildAddModalComponent(nodeData) {
        const panelComponents = this.getFieldSttingsFormSidebar({ data: nodeData }) || [];
        return panelComponents.map(field => {
            if(field.children) {
                return field.children.filter(f => f?.settings?.forModal);
            }
            if(field?.settings?.forModal) {
                return field;
            }
            return null;            
        })
            .flat(Infinity)
            .filter(Boolean);
    }

    @bind
    @memoize()
    renderActions(canEdit, sharingDetails) {
        const { isMobile }= this.props;
        const { users = [], teams = [] } = sharingDetails || {};
        return (
            <>
                <Ellipsis
                    data={[...users, ...teams]}
                    spaces={-14}
                    displaySize={isMobile ? 3 : 5}
                    renderCount={count => (
                        <MoreChipStyled
                            variant="default"
                            label={`+${count}`}
                            size="small"
                            onClick={() => this.onDotMenuClick('Sharing')}
                        />
                    )}
                    renderItem={
                        ({ name, image, id }: Object) => (
                            <AvatarStyled onClick={() => this.onDotMenuClick('Sharing')} 
                                src={image ? getAttachmentUrl(id, 'user', image): null} initials={name}
                            />
                        )
                    }
                />
                <DividerverticalStyled />
                <IconButton onClick={() => this.onDotMenuClick('Sharing')}><Icon hexColor={muiTheme.colors.text.secondary} name="share-variant" /></IconButton>
                <IconButton onClick={() => this.onDotMenuClick('About')}><Icon hexColor={muiTheme.colors.text.secondary} name="information-outline" /></IconButton>
            </>
        );
    }

    @bind
    @memoize()
    buildChipsVersion(isPreviewVersion, previewVersion, drafted) {
        if(previewVersion && isPreviewVersion) {
            return `Version ${previewVersion?.primary['iot_pipeline_version/version']} - READ ONLY`;
        }
        if(drafted) {
            return 'draft';

        }
        return null;
    }

    @bind
    @memoize()
    buildBreadcrumbs(name, isPreviewVersion, previewVersion, drafted) {
        const { pipelineMonitor } = this.props;
        return (
            <Breadcrumbs
                list={[{ 
                    link: `${pipelineMonitor ? '/pipeline-monitor' : redirectTypes['iot_pipeline']}`, 
                    title: pipelineMonitor ? 'IoT Pipeline Monitor' : typeTitlesMultiple['iot_pipeline'] 
                }, { title: name }]}
                withGoBack
                labelAtEnd={this.buildChipsVersion(isPreviewVersion, previewVersion, drafted)}
            />
        );
    }

    @bind
    @memoize()
    buildData(data) {
        return { data };
    }

    @bind
    async uploadVersion(file) {
        const { showToastr } = this.props;
        const versionFile = await readCSVFile(file);
        
        try {
            const graph = versionFile[0].primary['iot_pipeline_version/graph'];
            if(!graph) throw new Error('no graph in primary');
            
            this.setState({
                drafted: true, 
                previewGraphSchema: graph,
                pipelineState: { drawflow: graph }
            });
        } catch(err) {
            console.error('[ERROR]', err); // eslint-disable-line no-console
            showToastr({ severity: 'error', detail: 'File format is wrong.' });
        }
    }

    @bind
    @memoize()
    dotMenu(location: string, diagram: object, canEdit, primaryClass: object) {
        const menu = buildDotMenu({ details : {type : 'iot_pipeline', primaryClass, ...diagram} });
        const moreMenus = [
            { name: 'divider' },
            { name: 'Versions', icon: 'content-save-all' },
            { name: 'divider' },
            canEdit && { name: 'file', accept: '.csv', label: 'Import Version', onSelect: this.uploadVersion },
        ].filter(Boolean);
        return (
            <DotMenu
                key={13}
                onItemClick={this.onDotMenuClick}
                tooltipTitle='More Options'
                items={[...menu, ...moreMenus]}
            />
        );
    }

    @bind
    @memoize()
    renderLeftPanel(pipelineState, selectedElementId, leftSidebarTabIndx, pipelineMonitor, pipelineStatus) {
        return (
            <PipelineEditorLeftSidebar
                getCurrentConnectorStatus={this.getCurrentConnectorStatus}
                pipelineStatus={pipelineStatus}
                pipelineMonitor={pipelineMonitor}
                onDotMenuClick={this.onOutcomeItemDotMenuClick} 
                onSelectNode={this.onSelectNode} 
                pipelineState={pipelineState} 
                selectedElementId={selectedElementId}
                onChangeTab={this.handleChangeTabLeftSidebar} 
                tabIndex={leftSidebarTabIndx} 
            />
        );
    }

    @bind
    @memoize()
    getPropertiesSidebarProps(sidebarProps, openPropsSidebar, details, fieldSettingsPanelComponents, selectedElement) {
        const { isMobile } = this.props;
        if (fieldSettingsPanelComponents) {
            return {
                ...sidebarProps,
                title: sidebarProps?.title || 'Properties',
                isOpen: isMobile ? undefined : openPropsSidebar,
                afterCloseSidebar: this.onCloseSidebar,
                content: (
                    <FieldSettingsSidebar
                        ref={this.settingsSidebarRef}
                        components={fieldSettingsPanelComponents}
                        data={selectedElement}
                        updateSettings={this.updateSelectedElementSettings}
                        onValidate={this.handleSidebarValidation}
                    />
                )
            };
        }

        return {
            title: this.sidebarTitle || 'Properties',
            afterCloseSidebar: this.onCloseSidebar,
            content: (
                <GridStyled justify="center" alignItems="center" container>
                    <Typography variant="h5">Select component</Typography>
                </GridStyled>
            )
        };
    }
    
    render() {
        const { 
            isLoading, isStartedLoading, details, location, previewVersion,
            graphSchema, sharingDetails, showToastr, pipelineMonitor
        } = this.props;
        const { 
            customSettingsType, leftSidebarTabIndx, errors, pipelineState, selectedElement, 
            selectedElementId, openPropsSidebar, dataAddComponentModal, pipelineStatus,
            sidebarProps, isMenuNode, isPreviewVersion, previewGraphSchema, drafted
        } = this.state;
        const fieldSettingsPanelComponents = this.getFieldSttingsFormSidebar(selectedElement, customSettingsType, pipelineStatus);
        const pipelineDefinition = pipelineMonitor ? !!details && !!graphSchema : !!details;
        const permissions = getPermissions(details && details.role);
        const selectedClass = getSelectedPrimaryClass('iot_pipeline', this.props.primaryClasses);
        return (
            <Fragment>
                {(!details || isLoading || isStartedLoading) && <Loader absolute backdrop />}
                {pipelineDefinition ? (
                    <Layout
                        content={(
                            <Grid container wrap="nowrap">
                                {this.renderLeftPanel(pipelineState, selectedElementId, leftSidebarTabIndx, pipelineMonitor, pipelineStatus)}
                                <GridFlexed item>
                                    <GridButtonsWrapper container>
                                        {/*<ButtonStyled variant="text" onClick={() => {}}>Cancel</ButtonStyled>*/}
                                        {pipelineMonitor ? (
                                            <ButtonStyled disabled={!isEmpty(errors)} onClick={() => this.handleOnSrartStop(true)}>Stop</ButtonStyled>    
                                        ) : isPreviewVersion ? (
                                            <Button onClick={this.backToSaveDraft}>back to saved draft</Button>
                                        ) : (
                                            <>
                                                <ButtonStyled disabled={!isEmpty(errors)} onClick={() => this.handleOnSave(false)}>Save</ButtonStyled>
                                                <ButtonStyled disabled={!isEmpty(errors)} onClick={() => this.handleOnSave(true)}>Publish</ButtonStyled>
                                            </>
                                        )}
                                    </GridButtonsWrapper>
                                    <DividerStyled />
                                    <PipelineEditorDesigner
                                        isPreviewVersion={isPreviewVersion}
                                        pipelineStatus={pipelineStatus}
                                        pipelineMonitor={pipelineMonitor}
                                        handleOpenAddComponentModal={this.handleOpenAddComponentModal}
                                        showToastr={showToastr}
                                        details={details}
                                        graphSchema={previewGraphSchema || graphSchema}
                                        ref={this.designerWrapperRef}
                                        selectedElement={selectedElement}
                                        onSelectElement={this.onSelectElement} 
                                        onChangeDiagramState={this.onChangeDiagramState} 
                                    />
                                </GridFlexed>
                            </Grid>
                        )}
                        RightSidebarProps={this.getPropertiesSidebarProps(
                            sidebarProps, openPropsSidebar, details, fieldSettingsPanelComponents, selectedElement
                        )}
                        ToolbarContent={this.renderActions(permissions.canEdit, sharingDetails)}
                        leftToolbarContent={this.buildBreadcrumbs(details.name, isPreviewVersion, previewVersion, drafted)}
                        RightMenuIcon={<Icon name="pipe" />}
                        afterRightButton={this.dotMenu(location, details, permissions.canEdit, selectedClass)}
                    />
                ) : (
                    <Contaienr>
                        <Flex>
                            <IconStyled name="pipe" />
                            <StartedText>
                                Get started
                                <br />
                                <SubTitle>Drag components to create flow</SubTitle>
                            </StartedText>
                        </Flex>
                    </Contaienr>
                )}
                {!!dataAddComponentModal && (
                    <ModalFormGenearator
                        isOpen
                        title={'Add Source'}
                        labelSubmitButton={'Save'}
                        value={this.buildData(dataAddComponentModal)}
                        onChange={this.handleOnAddComponent}
                        components={this.buildAddModalComponent(dataAddComponentModal)}
                        onClose={this.handleCloseAddComponentModal}
                        withoutPreview
                    />
                ) }               
                {!!isMenuNode && (
                    <Modal open onClose={this.onDotMenuIconClick}>
                        <div>
                            <DotMenu 
                                open
                                centered
                                onClose={this.onDotMenuIconClick}
                                items={buildPipelineMenuItems({
                                    pipelineStatus,
                                    pipelineMonitor,
                                    node: isMenuNode.data,
                                    getCurrentConnectorStatus: this.getCurrentConnectorStatus
                                })}
                                onItemClick={(title) => this.onOutcomeItemDotMenuClick(title, isMenuNode.id)}
                            />
                        </div>
                    </Modal>
                ) }               
            </Fragment>
        );
    }
}

export default connect(
    (state, props) => {
        const graphSchema = props.pipelineMonitor ? 
            state.designer.pipelineStarted.data?.primary['iot_pipeline_version/graph'] : 
            state.designer.pipeline.data?.primary['iot_pipeline/graph'];
        return {
            sidebarTitle: state.sidebar.title,
            isMobile: state.global.isMobile,
            selectedElement: state.form.selectedElement,
            pipelineStatus: state.designer.pipelineStatus.data,
            details: state.designer.pipeline.data,
            detailsStarted: state.designer.pipelineStarted.data,
            isLoading: state.designer.pipeline.isLoading,
            isStartedLoading: state.designer.pipelineStarted.isLoading,
            sharingDetails: state.entities.sidebar.workspaces.data,
            sharingisLoading: state.entities.sidebar.workspaces.isLoading,
            id: getStr(props, 'match.params.id'),
            graphSchema,
            primaryClasses: state.app.allPrimaryClasses.records || [],
            draftedDetails: state.entities.common.draftedDetails['iot_pipeline'],
            previewVersion: state.entities.common.previewVersion['iot_pipeline'],    
        };
    },
    { 
        showToastr, updatePipeline, loadPipeline, publishEntity, startPipeline, stopPipeline, 
        setEntityPreviewVersion, setTabActions, setSelectedElement, loadEntityWorkspaces, 
        connectorStatusPipeline, loadPipelineStarted, openEntitySidebar, runtimeStatusPipeline,
        connectorStartPipeline, connectorStopPipeline, connectorRestartPipeline,
        setEntityDraftVersion, setDocumentTitle,
    },
    null,
    { forwardRef: true }
)(PipelineEditor);
