/* @flow */

import React from 'react';

import { label } from 'app/utils/designer/form/settings/common/commonBase';
import { help } from 'app/utils/designer/form/settings/common/commonHelpers';
import { onLoad, isVisible, isDisabled } from 'app/utils/designer/form/settings/common/commonEvents';
import { confirmationBuilder } from 'app/utils/designer/form/settings/common/commonGeneral';
import { flexGrow } from 'app/utils/designer/form/settings/common/commonStyles';
import Help from 'app/utils/designer/form/settings/common/Help';

const afterBeforeActionCodeExample = `
// For termination action and noticing ('reject' action working just for before action)
(data, context, inherited) => {
    return [{ action: 'reject' }, { action: 'showMessage', options: { message: 'Worng password', type: 'error' }}];
}


/*
Example for internal redirection
(data, context, inherited) => {
    return [{
        action: 'navigate',
        options: { href: '/an-internal-url-ca-be-here' }
    }]
}

For opening external link in new tab
(data, context, inherited) => {
    return [{
        action: 'link',
        options: { href: 'https://good.mood' }
    }]
}

The global context will contains the following fields:
- user, the data related to the user profile
- process, the data related to the process (if any)
- task, the data related to the task (if any)
- graphql, a function to call our main GQL API
- location, a JSON containing the origin and the href of this form
- sso, a JSON containing the SSO token

The graphql function has 2 parameters:
- body, the query and the data
- options, the HTTP request options (header, ...)

Example:
async (data, context, inherited) => {
  const value = event.target.value
  const query = \`
  query findPeople($filterBy:[JSON]) {
    people(page: 1, filterBy: $filterBy) {
      name
    }
  }
  \`
  const vars = {
    "filterBy": [{ field: "name", op: "contains", value }]
  }
  const response = await context.graphql({ query, data: vars })
  return [
    { name: 'list', value: response.people }
  ]
}

If a component is definied inside of a group or a group repeat, the data
parameter will contains only the data corresponding to the group or to the
related item in case of a group repeat.
In this case, the fourth parameter will contains the inherited fields:
- data, it will contains the process data
- path, it is the path of the reference used by the group
- index, the index of the item related to the component in a group repeat
To set a variable outside of the group/"related item" we need to specify the
action 'setInheritedData', e.g.:
{ action: 'setInheritedData', name: 'z', value: null }
*/

`;

const panels = [
    {
        header: 'Base',
        children: [
            label,
        ],
    },
    {
        header: 'General',
        children: [
            {
                type: 'typeahead',
                properties: {
                    label: 'Action',
                    name: 'properties.action',
                    valueField:'value',
                    options: [
                        { value: 'startProcess' , label: 'Start process' },
                        { value: 'save' , label: 'Save task' },
                        { value: 'close' , label: 'Close task' },
                    ]
                },
                constraints: { required: true }
            },
            {
                type: 'text',
                properties: { label: 'Outcome', name: 'properties.outcome' },
                constraints: { required: true }
            },
            ...confirmationBuilder,
        ],
    },
    {
        header: 'Events',
        children: [
            onLoad,
            isVisible,
            isDisabled,
            {
                type: 'textarea',
                properties: {
                    parseAs: 'javascript',
                    label: 'onBeforeAction',
                    name: 'properties.onBeforeAction',
                    help: <Help message="This function will be invoked before outcome action." />,
                    title: 'Before action',
                    modal: true,
                    template: afterBeforeActionCodeExample,
                    useCodeEditor: true,
                }
            },
            {
                type: 'textarea',
                properties: {
                    parseAs: 'javascript',
                    label: 'onAfterAction',
                    name: 'properties.onAfterAction',
                    help: <Help message="This function will be invoked after outcome action." />,
                    title: 'After action',
                    modal: true,
                    template: afterBeforeActionCodeExample,
                    useCodeEditor: true,
                }
            }
        ],
    },
    {
        header: 'Styles',
        children: [
            {
                type: 'typeahead',
                properties: {
                    label: 'Color',
                    name: 'properties.color',
                    valueField:'value',
                    clearable: false,
                    options: [
                        { value: 'primary' , label: 'primary' },
                        { value: 'secondary' , label: 'secondary' },
                    ]
                }
            },
            {
                type: 'typeahead',
                properties: {
                    label: 'Variant',
                    name: 'properties.variant',
                    valueField:'value',
                    clearable: false,
                    options: [
                        { value: 'text' , label: 'Text' },
                        { value: 'outlined' , label: 'Outlined' },
                        { value: 'contained' , label: 'Contained' },
                    ]
                }
            },
            flexGrow
        ]
    },
    {
        header: 'Helpers',
        children: [ help ]
    },
];

export default {
    defaults: {
        actionType: null,
        afterAction: false,
        fullWidth: true,
        variant: 'contained',
        color: 'primary',
        formFieldProps: { flexGrow: 1 }
    },
    panels: settingValues => panels
};
