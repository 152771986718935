/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { focusInput, isEmpty } from 'app/utils/utils';
import { get } from 'app/utils/lo/lo';

import { loadBoardColumns, createRelationColumnToBoard, addNewBoardLabelToColumn } from 'store/actions/abox/old_boardsActions';

import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import Button from 'app/components/atoms/Button/Button';
import Loader from 'app/components/atoms/Loader/Loader';
import VirtualListManaged from 'app/components/molecules/VirtualList/VirtualListManaged';

import { StyledEntityListItem, BoardsModalList, BoardsModalSearch } from 'app/components/ABox/OldBoards/style';
import { BOARD_RELATION_DEFINITION_ID } from 'app/config/config';

const inputProps: Object = { disableUnderline: true };

/**
 * A modal for search and add column to board
 */
class BoardsAddColumnModal extends PureComponent<Object, Object> {
    static propTypes = {
        board: PropTypes.object,
        closeModal: PropTypes.func.isRequired,
        isLoading: PropTypes.bool,
        isLoadingColumns: PropTypes.bool,
        startIndex: PropTypes.number,
        records: PropTypes.array,
        totalRecords: PropTypes.number,
        loadBoardColumns: PropTypes.func.isRequired,
        createRelationColumnToBoard: PropTypes.func.isRequired
    };

    state = {
        search: '',
        filterBy: [],
        orderBy: [{ field: 'name', direction: 'asc nulls last' }],
        selectedColumn: {}
    };

    // preserve the initial state in a new object.
    defaultState = this.state;
    virtualListRef = React.createRef();

    @bind
    @memoize()
    loadColumns(options: Object) {
        const { board, loadBoardColumns } = this.props;
        const excludeBy = [...(options.excludeBy || [])];
        let filterBy = [{ field: 'id', op: '<>', value: board.id }];

        if (options.filterBy) {
            filterBy = [...filterBy, ...options.filterBy];
        }

        excludeBy.push({
            field: 'relations.relatedEntity.id',
            op: '=',
            value: board.id
        });

        return loadBoardColumns({ ...options, filterBy, excludeBy });
    }

    @bind
    renderComponent({ style, index, data }: { style: Object, index: number, data: Object }) {
        const { selectedColumn } = this.state;

        return (
            <div style={style} key={index}>
                <StyledEntityListItem
                    data={data}
                    title="Click to select"
                    type={'custom'}
                    onClick={e => this.onSelectColumn(e, data)}
                    selected={selectedColumn.id === data.id}
                />
            </div>
        );
    }

    @bind
    onSelectColumn(event: Object, data: Object) {
        event.preventDefault();
        this.setState({ selectedColumn: data });
        this.updateList();
    }

    @bind
    updateList() {
        if (this.virtualListRef && this.virtualListRef.current) {
            this.virtualListRef.current.forceUpdate();
        }
    }

    @bind
    onSubmit() {
        const { board, createRelationColumnToBoard, addNewBoardLabelToColumn, boardColumns } = this.props;
        const { selectedColumn } = this.state;
        const columnLastIndexOrder = !isEmpty(boardColumns || boardColumns.slice(-1)[0].order) ? boardColumns.slice(-1)[0].order : boardColumns.length;

        if (!isEmpty(selectedColumn)) {
            const relationData = {
                relationDefinition: BOARD_RELATION_DEFINITION_ID,
                entity: { type: 'custom', id: selectedColumn.id },
                relatedEntity: { type: 'custom', id: board.id },
                attributes: {
                    'board-board-label/order': columnLastIndexOrder + 1
                }
            };

            createRelationColumnToBoard(relationData).then((response) => {
                if (response instanceof Error) return;
                const column = {
                    relationId: get(response, 'data.relation.id'),
                    isAdded: true,
                    order: get(response, 'data.relation.attributes.board-board-label/order'),
                    ...get(response, 'data.column', {})
                };
                this.closeModal();
                addNewBoardLabelToColumn(column);
            });
        }
    }

    @bind
    onChangeSearch(e: Object) {
        if (e.persist) {
            e.persist();
        }
        this.setState({ search: e.target.value }, () => {
            const { search } = this.state;
            const searchFilter = { field: 'name', op: 'contains', value: !search ? '' : search };

            this.setState({
                filterBy: [searchFilter]
            });
        });
    }

    @bind
    closeModal() {
        this.setState(this.defaultState, this.props.closeModal);
    }

    render() {
        const { board, isLoading, isLoadingColumns, records, totalRecords, startIndex, isMobileView } = this.props;
        const { selectedColumn, filterBy, orderBy, search } = this.state;

        return (
            <ModalDialog
                dialogProps={{
                    overflow: 'hidden',
                }}
                title={`Add Column to ${board.name}`}
                onClose={this.closeModal}
                actions={<Button disabled={isEmpty(selectedColumn)} color="primary" onClick={this.onSubmit}>
                    Submit
                </Button>}
            >
                {isLoading && <Loader absolute backdrop />}
                <BoardsModalSearch
                    onChange={this.onChangeSearch}
                    value={search}
                    fullWidth
                    margin="none"
                    placeholder="Search..."
                    InputProps={inputProps}
                    inputRef={ref => !isMobileView && focusInput(ref)}
                />
                <BoardsModalList>
                    <VirtualListManaged
                        ref={this.virtualListRef}
                        renderComponent={this.renderComponent}
                        itemSize={84}
                        title={`${totalRecords >= 1000 ? '999+' : totalRecords } Board Labels`}
                        itemCount={totalRecords || 0}
                        loadData={this.loadColumns}
                        isLoading={isLoadingColumns}
                        startIndex={startIndex || 0}
                        filterBy={filterBy}
                        orderBy={orderBy}
                        list={records}
                        maxWidth="1024"
                    />
                </BoardsModalList>
            </ModalDialog>
        );
    }
}

export default connect(
    state => ({
        isLoading: state.abox.kanban.boards.isLoading,
        isLoadingColumns: state.abox.kanban.columns.isLoading,
        records: state.abox.kanban.columns.records,
        startIndex: state.abox.kanban.columns.startIndex,
        totalRecords: state.abox.kanban.columns.count,
        isMobileView: state.global.isMobile,
    }),
    {
        loadBoardColumns,
        createRelationColumnToBoard,
        addNewBoardLabelToColumn
    }
)(BoardsAddColumnModal);
