import { gql } from '@apollo/client';

export default gql`
    query classAncestorQuery($id: ID!, $onlyActive: Boolean) {
        result: classAncestors(id: $id, onlyActive: $onlyActive) {
            id
            name
            uri
            active
            color
            formDefinition
            icon
            iconType
        }
    }
`;