/* @flow */

import { gql } from '@apollo/client';

import { workspaceFields, classWorkspaceFields } from 'graphql/entities/entities/loadEntityWorkspacesQuery';

export default type => gql`
    mutation unshareEntityWithTeamsMutation($id: ID!, $teams: [ID]!) {
        result: ${type === 'class' ? 'unshareClassWithTeams': 'unshareEntityWithTeams'}(id: $id, teams: $teams${type === 'class' ? '' : `, type: "${type}"`}) {
          ${type === 'class' ? classWorkspaceFields : workspaceFields}
        }
    }
`;
