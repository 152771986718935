/* @flow */

import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { muiTheme } from 'app/themes/materialUi';
import { Switch, Route, Redirect } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { isMobile } from 'react-device-detect';
import { Chip, Avatar, Divider, IconButton, Tooltip } from '@mic3/platform-ui';

import DotMenu from 'app/components/molecules/DotMenu/DotMenu';
import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import Button from 'app/components/atoms/Designer/Button';
import FormDesignerEditor from 'app/containers/Designer/Form/Tabs/FormDesignerEditor/FormDesignerEditor';
import FormDesignerPreview from 'app/containers/Designer/Form/Tabs/FormDesignerPreview/FormDesignerPreview';
import Loader from 'app/components/atoms/Loader/Loader';
import PageTemplate from 'app/components/templates/PageTemplate';
import Ellipsis from 'app/components/molecules/Ellipsis/Ellipsis';
import Icon from 'app/components/atoms/Icon/Icon';
import { loadEntityWorkspaces, loadEntityVersions } from 'store/actions/entities/entitiesActions';
import { loadFormDefinition, updateFormDefinition, publishFormDefinition } from 'store/actions/designer/designerFormActions';
import { getFieldDefinition } from 'app/utils/designer/form/settings/formFieldSettingsUtils';
import { get } from 'app/utils/lo/lo';
import { getArray, getStr, isEmpty } from 'app/utils/utils';
import { normalizeFields, denormalizeFields } from 'app/utils/designer/form/formUtils';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { openEntitySidebar } from 'store/actions/entities/entitySidebarActions';
import PageNotAllowed from 'app/containers/ErrorPages/PageNotAllowed';
import Breadcrumbs from 'app/components/organisms/Breadcrumbs/Breadcrumbs';
import { typeTitlesMultiple, redirectTypes } from 'app/config/typesConfig';
import { saveJSON } from 'app/utils/datatable/datatableUtils';
import { setDocumentTitle, showToastr } from 'store/actions/app/appActions';
import { getPermissions } from 'app/config/rolesConfig';
import { getAttachmentUrl } from 'app/utils/attachments/attachmentsUtils';
import { setSelectedElement } from 'store/actions/form/formActions';
import { setEntityPreviewVersion } from 'store/actions/entities/entitiesActions';

const DividerverticalStyled = styled(Divider)`
    margin: 4px 16px !important;
    height: 24px !important;
    align-self: center !important;
    width: 1px;
    background-color: ${({ theme }) => theme.material.colors.background.divider} !important;
`;

const MoreChipStyled = styled(Chip)`
    background:  ${({ theme }) => theme.material.colors.background.fields} !important;
    & .MuiChip-label {
        max-width: 140px;
    }
`;

const AvatarStyled = styled(Avatar)`
    width: 30px !important;
    height: 30px !important;
    margin-right: 8px;
    font-size: 1rem !important;
`;

const ButtonPublishStyled = styled(Button)`
  min-width: 80px !important;
`;

class FormDesigner extends PureComponent<Object, Object> {

    static propTypes = {
        match: PropTypes.object.isRequired,
        form: PropTypes.object,
        formDesignerState: PropTypes.object,
        loadFormDefinition: PropTypes.func.isRequired,
        updateFormDefinition: PropTypes.func.isRequired,
    };

    state = {
        isSaving: false,
        errors: null,
    };

    constructor(props) {
        super(props);
        const id = get(props, 'match.params.id');
        id && props.loadFormDefinition(id);
        this.reloadSharing();
    }

    componentDidUpdate(prevProps) {
        const prevId = get(prevProps, 'match.params.id');
        const id = get(this.props, 'match.params.id');

        if (id !== prevId) {
            id && this.props.loadFormDefinition(id);
            this.reloadSharing();
        }

        const { form, saveEditorState, draftedDetails, previewVersion, setDocumentTitle } = this.props;

        const name = form?.name;
        const prevName = prevProps?.form?.name;
        if(name && name !== prevName){
            setDocumentTitle(name);
        }
        
        if (draftedDetails !== prevProps.draftedDetails) {
            const { id, name, description, primary } = draftedDetails;
            const fields = normalizeFields(getArray( draftedDetails, 'primary.definition') || [getFieldDefinition('panel')]); // this mistake
            saveEditorState({ id, name, description, fields, primary }, false, true);
        }

        if (previewVersion && previewVersion !== prevProps.previewVersion) {
            const { id, name, description, primary } = previewVersion;
            const fields = normalizeFields(getArray(previewVersion, 'primary.definition') || [getFieldDefinition('panel')]);
            saveEditorState({ primary, id, name, description, fields }, true);
        }

        if (form && form !== prevProps.form) {
            const { id, name, description } = form;
            const fields = normalizeFields(getArray(form, 'primary.definition') || [getFieldDefinition('panel')]);
            saveEditorState({ id, name, description, fields }, false, false);
        }
    }

    componentWillUnmount(){
        this.props.setEntityPreviewVersion('formdefinition', null);
        this.props.setSelectedElement(null);
    }

    @bind
    reloadSharing() {
        const { loadEntityWorkspaces } = this.props;
        const id = get(this.props, 'match.params.id');
        loadEntityWorkspaces('formdefinition', id);
    }

    @bind
    onSave(newVersion: ?boolean) {
        this.setState({ isSaving: true }, async () => {
            const { match, editorState, setSelectedElement } = this.props;
            let { selectedElement } = this.props;
            const { fields } = editorState || {};

            const id = getStr(match, 'params.id') || '';
            if(selectedElement?.type === 'panel'){
                selectedElement = {...selectedElement, properties:{...selectedElement.properties, header: selectedElement.properties.header.trim()}}; 
                setSelectedElement(selectedElement);
            }         
            await this.props.updateFormDefinition({
                id, primary: {
                    definition: denormalizeFields(fields),
                },
            });
            if(newVersion) {
                await this.props.publishFormDefinition(id);
                await this.props.loadEntityVersions(id, 'formdefinition');
            }
            await this.props.loadFormDefinition(id);
            this.setState({ isSaving: false });
        });
    };

    @bind
    async onDotMenuClick(title) {
        const { openEntitySidebar, id, form, editorState, showToastr } = this.props;
        if (title === 'Publish') {
            this.onSave(true);
            return;
        }
        if (title === 'Download') {
            const { name } = form || {};
            const { fields } = editorState || {};
            const exportedFields = denormalizeFields(fields);
            if(isEmpty(exportedFields)){
                showToastr({ severity: 'error', detail: 'Empty file could not be Downloaded' });
            }else{
                saveJSON(name, denormalizeFields(fields) || []);
            }
            return;
        }
        if(title === 'Versions') {
            await this.props.setSelectedElement(null);
        }
        openEntitySidebar({ title, id, type: 'formdefinition' });
    }

    @bind
    @memoize()
    renderActions(sidebarTitle, canEdit, sharingDetails) {
        const { users = [], teams = [] } = sharingDetails || {};
        return (
            <>
                <Ellipsis
                    data={[...users, ...teams]}
                    spaces={-14}
                    displaySize={isMobile ? 3 : 5}
                    renderCount={count => (
                        <MoreChipStyled
                            variant="default"
                            label={`+${count}`}
                            size="small"
                            onClick={() => this.onDotMenuClick('Sharing')}
                        />
                    )}
                    renderItem={
                        ({ name, image, id }: Object) => (
                            <AvatarStyled onClick={() => this.onDotMenuClick('Sharing')} src={image ? getAttachmentUrl(id, 'user', image): null} initials={name} />
                        )
                    }
                />
                <DividerverticalStyled />
                <Tooltip title="Sharing">
                    <IconButton onClick={() => this.onDotMenuClick('Sharing')}><Icon name="share-variant" /></IconButton>
                </Tooltip >
                <Tooltip title="About">
                    <IconButton onClick={() => this.onDotMenuClick('About')}><Icon hexColor={muiTheme.colors.text.secondary} name="information-outline" /></IconButton>
                </Tooltip>
            </>
        );
    }

    @bind
    @memoize()
    renderSecondaryActions(errors, canEdit, isPreviewVersion) {
        return isPreviewVersion ? (
            <Button onClick={this.props.backToSaveDraft}>back to saved draft</Button>
        ) : (
            <>
                {canEdit && (
                    <Button
                        key={14}
                        disabled={!!errors}
                        color="primary"
                        onClick={() => this.onSave(false)}
                        confirmationModalProps={{
                            message: 'Are you sure you want to save this version?',
                            declineButtonText: 'Cancel',
                            confirmButtonText: 'Save',
                        }}
                        withConfirmation
                    >
                    Save
                    </Button>
                )}
                {canEdit && (
                    <ButtonPublishStyled
                        key={15}
                        disabled={!!errors}
                        color="primary"
                        onClick={() => this.onSave(true)}
                        confirmationModalProps={{
                            message: 'Are you sure you want to publish this form? This will create a new version',
                            declineButtonText: 'Cancel',
                            confirmButtonText: 'Publish',
                        }}
                        withConfirmation
                    >
                    Publish
                    </ButtonPublishStyled>
                )}
            </>
        );
    }

    @bind
    @memoize()
    buildBreadcrumbs(name, isPreviewVersion, previewVersion, drafted) {
        return (
            <Breadcrumbs
                list={[{ link: `/${redirectTypes['formdefinition']}`, title: typeTitlesMultiple['formdefinition'] }, { title: name }]}
                withGoBack
                labelAtEnd={this.buildChipsVersion(isPreviewVersion, previewVersion, drafted)}
            />
        );
    }

    @bind
    @memoize()
    buildChipsVersion(isPreviewVersion, previewVersion, drafted) {
        if(previewVersion && isPreviewVersion) {
            return `Version ${previewVersion.primary.version} - READ ONLY`;
        }
        if(drafted) {
            return 'draft';

        }
        return null;
    }

    @bind
    @memoize()
    dotMenu(sidebarTitle: string, canEdit) {
        return (
            <DotMenu
                key={13}
                onItemClick={this.onDotMenuClick}
                tooltipTitle='More Options'
                items={[
                    sidebarTitle !== 'Versions' && { name: 'Versions', icon: 'content-save-all' },
                    { name: 'divider' },
                    { name: 'Download', icon: 'download' },
                    canEdit && { name: 'file', label: 'Upload', onSelect: this.props.uploadDefinition },
                    { name: 'divider' },
                    canEdit && { name: 'file', accept: '.csv', label: 'Import Version', onSelect: this.props.uploadVersion },

                ].filter(Boolean)}
            />
        );
    }

    render() {
        const {
            errors, saveEditorState, editorState, savePreviewState, drafted,
            designerState, tabs, sharingDetails, form, isLoading, previewVersion,
            sidebarTitle, normalizeFieldSettingsPanelComponents, isPreviewVersion
        } = this.props;
        const { isSaving } = this.state;
        const id = getStr(this.props, 'match.params.id') || '';

        const permissions = getPermissions(form && form.role);
        const { canEdit, canView } = permissions || {};
        if (!isLoading && (isEmpty(form) || !canView)) {
            return <PageNotAllowed permissionError={true} title={`Process definition (ID:${id})`}/>;
        }
        const { name }= form || {};

        return (
            <PageTemplate title={name} subTitle={`#${id}`}>
                <ContentArea withHeader>
                    { (isLoading || isSaving) && <Loader absolute backdrop />}
                    {!isLoading && !form && <h2 style={{padding: '10px 20px'}}>Form not found.</h2>}
                    {form && form.id === id && <Fragment>
                        <Switch>
                            <Route path={`/designer/forms/:id`} exact render={() =>
                                <Redirect to={canEdit ? `/designer/forms/${id}/editor` : `/designer/forms/${id}/preview`}/>}
                            />
                            <Route path={`/designer/forms/:id/editor`} render={() =>
                                <FormDesignerEditor
                                    isPreviewVersion={!!isPreviewVersion}
                                    form={form}
                                    saveEditorState={saveEditorState}
                                    designerState={designerState}
                                    editorState={editorState}
                                    errors={errors}
                                    onSave={this.onSave}
                                    actions={this.renderActions(sidebarTitle, canEdit, sharingDetails)}
                                    secondaryActions={this.renderSecondaryActions(errors, canEdit, isPreviewVersion)}
                                    breadcrumbLine={this.buildBreadcrumbs(form.name, isPreviewVersion, previewVersion, drafted)}
                                    tabs={tabs}
                                    afterRightButton={this.dotMenu(sidebarTitle, canEdit)}
                                    normalizeFieldSettingsPanelComponents={normalizeFieldSettingsPanelComponents}
                                    disabled={!canEdit}
                                />
                            }
                            />
                            <Route path={`/designer/forms/:id/preview`} render={() =>
                                <FormDesignerPreview
                                    designerState={designerState}
                                    savePreviewState={savePreviewState}
                                    actions={this.renderActions(sidebarTitle, canEdit, sharingDetails)}
                                    secondaryActions={this.renderSecondaryActions(errors, canEdit, isPreviewVersion)}
                                    breadcrumbLine={this.buildBreadcrumbs(form.name, isPreviewVersion, previewVersion, drafted)}
                                    tabs={tabs}
                                    afterRightButton={this.dotMenu(sidebarTitle, canEdit)}
                                    disabled={!canEdit}
                                />
                            }
                            />
                        </Switch>
                    </Fragment>}
                </ContentArea>
            </PageTemplate>
        );
    }
}

export default connect(
    (state, props) => ({
        sidebarTitle: state.sidebar.title,
        sharingDetails: state.entities.sidebar.workspaces.data,
        sharingisLoading: state.entities.sidebar.workspaces.isLoading,
        isLoading: state.designer.form.isLoading,
        form: state.designer.form.data,
        profile: state.user.profile,
        id: getStr(props, 'match.params.id'),
        selectedElement: state.form.selectedElement
    }),
    {
        loadFormDefinition,
        updateFormDefinition,
        publishFormDefinition,
        openEntitySidebar,
        showToastr,
        loadEntityWorkspaces,
        loadEntityVersions,
        setSelectedElement,
        setDocumentTitle,
        setEntityPreviewVersion,
    }
)(withTheme(FormDesigner, 'formdefinition'));
