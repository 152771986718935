/* @flow */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from '@mic3/platform-ui';

import { BROADCASTS_DATA_TABLE } from 'app/config/dataTableIds';
import EntitiesList from 'app/containers/Entities/EntitiesList/EntitiesList';
import PageTemplate from 'app/components/templates/PageTemplate';
import ListItem from 'app/components/molecules/List/ListItem';
import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import { fetchBroadcasts } from 'store/actions/broadcasts/broadcastsActions';
import { bind } from 'app/utils/decorators/decoratorUtils';
import { typeTitlesMultiple } from 'app/config/typesConfig';
import PageNotAllowed from 'app/containers/ErrorPages/PageNotAllowed';
import ResizableListItem from 'app/components/molecules/VirtualList/ResizableListItem';
import withPermissions from 'app/utils/withPermissions';
import BroadcastListCard from './BroadcastListCard';
import { commonEntitiesListFilters } from 'app/utils/entity/entityUtils';
import { modulesAndPageTitles } from 'app/config/typesConfig';
import { setDocumentTitle } from 'store/actions/app/appActions';

const listType = 'broadcast';

/**
 * A component that displays a list of broadcasts
 */
class BroadcastsList extends PureComponent<Object, Object> {

    static propTypes = {
        fetchBroadcasts: PropTypes.func,
        isLoading: PropTypes.bool.isRequired,
        isDownloading: PropTypes.bool.isRequired,
        records: PropTypes.array,
        recordsCount: PropTypes.number,
        recordsCountMax: PropTypes.number,
    };

    // $FlowFixMe
    tableRef = React.createRef();

    searchBar = ['message', 'id'];
    defaultFilters = { active: null };
    defaultOrder = [{ field: 'createdDate', direction: 'desc nulls last' }];
    viewsSettings = ['view-list', 'view-cards'];
    filterDefinitions: Array<Object> = [
        {
            field: 'id',
            type: 'uuid',
            properties: {
                label: 'ID',
                name: 'id',
                opSelector: true
            },
            condition: '=',
        },
        {
            field: 'message',
            type: 'text',
            properties: {
                label: 'Message',
                name: 'message',
                opSelector: true
            },
        },
        {
            field: 'active',
            type: 'typeahead',
            properties: {
                label: 'Status',
                name: 'active',
                options: [
                    { label: 'All', value: null },
                    { label: 'Active', value: true },
                    { label: 'Inactive', value: false },
                ]
            },
            sort: false,
            condition: '='
        },
        {
            field: 'startDate',
            type: 'dateTimeRange',
            properties: {
                label: 'Start date',
                name: 'startDate',
            },
        },
        {
            field: 'expireDate',
            type: 'dateTimeRange',
            properties: {
                label: 'Expire date',
                name: 'expireDate',
            },
        },
        ...commonEntitiesListFilters
    ];

    componentDidMount() {
        this.props.setDocumentTitle(modulesAndPageTitles.adminConsole.broadcast);
    }

    @bind
    renderComponent({ style, index, data, resize }) {
        const { id, message, parent } = data;
        return (
            <ResizableListItem style={style} key={index} index={index} resize={resize} padding={15}>
                {resizeRow => (
                    <ListItem
                        title={<Link to={`/broadcasts/edit/${parent || id}`}>{message}</Link>}
                        subTitle={`#${id}`}
                        raised
                    />
                )}
            </ResizableListItem>

        );
    }

    @bind
    renderCardsComponent(props: Object) {
        return (
            <BroadcastListCard
                key={props.index}
                {...props}
            />
        );
    };

    /**
     * Render our broadcast list
     */
    render() {
        const { isLoading, records, totalRecords, startIndex, canView } = this.props;
        if (!canView) {
            return <PageNotAllowed title="Broadcasts" />;
        }
        return (
            <PageTemplate title="Broadcasts">
                <ContentArea>
                    <EntitiesList
                        id={BROADCASTS_DATA_TABLE}
                        searchBar={this.searchBar}
                        defaultView="view-cards"
                        views={this.viewsSettings}
                        type={listType}
                        headerTitle={typeTitlesMultiple[listType]}
                        records={records}
                        totalRecords={totalRecords}
                        startIndex={startIndex}
                        isLoading={isLoading}
                        loadData={this.props.fetchBroadcasts}
                        filterDefinitions={this.filterDefinitions}
                        renderListComponent={this.renderComponent}
                        renderCardsComponent={this.renderCardsComponent}
                        defaultOrder={this.defaultOrder}
                        defaultFilters={this.defaultFilters}
                        VirtualListProps={{ itemSize: 80 }}
                    />
                </ContentArea>
            </PageTemplate>
        );
    }
}

const mapStateToProps = (state, props) => ({
    isLoading: state.broadcasts.list.isLoading,
    isDownloading: state.broadcasts.list.isDownloading,
    records: state.broadcasts.list.records,
    startIndex: state.broadcasts.list.startIndex,
    totalRecords: state.broadcasts.list.count,
    recordsCountMax: state.broadcasts.list.countMax,
});

export default connect(
    mapStateToProps,
    { fetchBroadcasts, setDocumentTitle }
)(withPermissions(BroadcastsList, listType));
