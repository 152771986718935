/* @flow */

import React, { useEffect, useRef, useState } from 'react';
import { ScaledFrame } from 'app/components/superset/SupersetFrame';
import PageTemplate from 'app/components/templates/PageTemplate';

const PipelineDesigner = (props) => {
    const frame = useRef(null);
    const [parentWidth, setParentWidth] = useState(-1);
    const updateWidth = () => {
        if (frame.current && frame.current.parentNode) {
            const parentWidth = frame.current.parentNode.getBoundingClientRect().width;
            setParentWidth(parentWidth);
        }
    };
    useEffect(() => {
        updateWidth();
        window.addEventListener('resize', updateWidth);
        return function cleanup() {
            window.removeEventListener('resize', updateWidth);
        };
    });
    const url = `${window.location.origin}/designer/mi-stream/`;
    return (
        <PageTemplate title="Pipeline Designer" overflowHidden hasIframe>
            <ScaledFrame ref={frame} {...props} src={url} parentWidth={parentWidth} />
        </PageTemplate>
    );
};

export default PipelineDesigner;
