import React, { useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { Tabs as TabsPui, Tab } from '@mic3/platform-ui';

import Icon from 'app/components/atoms/Icon/Icon';
import history from 'store/History';

const TabsStyled = styled(TabsPui)`
    color: ${({ theme }) => theme.material.colors.secondary.main};
    margin: 0;
    & .MuiTab-root {
        min-width: ${({ tinytab }) => tinytab ? '30px' : '130px'};
    }
    & > div.MuiTabScrollButton-root:first-child {
        margin-right: -40px !important;
        background-color: ${({ theme }) => theme.material.colors.background.default};
        z-index: 1;
    }
    & .MuiTab-root.Mui-selected {
        border-bottom: 1px solid ${({ theme }) => theme.material.colors.secondary.main};
    }
    & .MuiTabs-indicator {
        display: none;
    }
    & .MuiTab-labelIcon {
        min-height: 56px !important;
        margin: 1px;
        padding: 1px;
    }
    & .MuiTab-labelIcon .MuiTab-wrapper > *:first-child {
        margin-bottom: 0 !important;
    }
    & .Icon {
        height: 21px;
    }
`;

const Tabs = ({ tinyTab, tabs, activeTab: activeTabProps, onChange }) => {

    let activeTab = activeTabProps || 0;
    tabs.forEach((t, i) => {
        if(t.active || activeTabProps === i) {
            activeTab = i;
        }
    });

    const tabsRendered = useMemo(() => tabs.map((tab, i) => (
        <Tab disabled={!!tab.disabled} index={i} key={tab.label} label={tab.label} icon={tab.iconName && <Icon name={tab.iconName} type={tab.iconType} size="sm" />} />
    )), [tabs]);

    const onChangeTab = useCallback((evnt, activeTab) => {
        const tab = tabs[activeTab];
        const onChangeFunc = tab.onChange || onChange;
        if(onChangeFunc) {
            onChangeFunc(activeTab);
        } else {
            history.push(tab.link);
        }
    }, [tabs, onChange]);

    return (
        <TabsStyled
            value={activeTab}
            onChange={onChangeTab}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs"
            tinytab={tinyTab?1:0}
        >
            {tabsRendered}
        </TabsStyled>
    );
};

export default Tabs;
