/* @flow */

import { gql } from '@apollo/client';
import { entityReferences } from './relationsQuery';

export const eventEntityRelationCommonFields = `
event {
    id
    time
    eventType {
        id
        name
    }
}
entity {
    ${entityReferences}
}
isReverseRelation
relationDefinition {
    id
    type
    relatedType
    description
    relatedDescription
    classes {
        id
        name
        uri
        formDefinition
    }
    isSystem
    relationType
    reportingEnabled
}
attributes
createdBy {
    id
    name
    image
}
createdDate
modifiedBy {
    id
    name
    image
}
modifiedDate
`;

export default gql`
    query eventEntityRelationsQuery(
            $eventId: ID!
            $eventTime: Date!
            $startIndex: Int
            $stopIndex: Int
            $filterBy: [JSON]
            $excludeBy: [JSON]
            $orderBy: [JSON]
        ) {
        relations: eventEntityRelations(
            eventId: $eventId
            eventTime: $eventTime
            startIndex: $startIndex
            stopIndex: $stopIndex
            filterBy: $filterBy
            excludeBy: $excludeBy
            orderBy: $orderBy
            ) {
                ${eventEntityRelationCommonFields}
            }
        }
`;
