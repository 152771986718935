/* @flow */

import { gql } from '@apollo/client';

export default gql`
    query relationDefinitionAutocompleteQuery($filterBy: [JSON], $orderBy: [JSON], $excludeBy: [JSON]) {
        result: relationDefinitions(filterBy: $filterBy, orderBy: $orderBy, excludeBy: $excludeBy) {
            id
            type
            relatedType
            relatedDescription
            classes {
              id
            }
        }
    }
`;
