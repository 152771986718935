import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, CircularProgress } from '@mic3/platform-ui';

import { updateLicense } from 'store/actions/admin/licenseActions';

import { bind } from 'app/utils/decorators/decoratorUtils';
import { loadLicense } from 'store/actions/app/appActions';

import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';

class UpdateLicense extends PureComponent {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        updateLicense: PropTypes.func.isRequired,
        loadLicense: PropTypes.func.isRequired,
        isLoading: PropTypes.bool
    };

    formRef = React.createRef();

    components = [
        {
            type: 'text',
            properties: { label: 'Licence key', name: 'license' },
            constraints: { required: true }
        }
    ];

    @bind
    onFormSubmit(event) {
        event.preventDefault();
        this.formRef.current.isValidForm().then(({ data, errors }) => {
            if (!errors) {
                this.props.updateLicense(data.license).then((result) => {
                    if (!(result instanceof Error)) {
                        setTimeout(() => {
                            window.location.reload();
                        }, 3000);
                    };
                });
            }
        });
    }

    @bind
    handleClose() {
        const { onClose } = this.props;
        onClose && onClose();
    }

    render() {
        const { isLoading } = this.props;
        return (
            <ModalDialog
                title="New licence key"
                onClose={this.handleClose}
                actions={
                    (isLoading) ? (
                        <CircularProgress size={24} color="primary" />
                    ) : (
                        <Button onClick={this.onFormSubmit}>
                            Save
                        </Button>
                    )
                }
            >
                <FormGenerator 
                    components={this.components} 
                    ref={this.formRef}
                />
            </ModalDialog>
        );
    }
}

export default connect(
    state => ({
        isLoading: state.admin.license.update.isLoading,
    }),
    {
        updateLicense,
        loadLicense
    }
)(UpdateLicense);
