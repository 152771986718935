import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import UploadButton from 'app/components/molecules/UploadButton/UploadButton';
import { useToggle } from 'app/utils/hook/hooks';
import { MdiIcon, Button } from '@mic3/platform-ui';
import { createEvent } from 'app/utils/http/event';

const Footer = styled.div`
    display: flex;
    justify-content: end;
`;

const Message = styled.div`
    color: #abb0b9;
    font-size: 16px;
    font-weight: 400;
`;

const UploadModal = ({ onClose, onChange }: Object) => (
    <ModalDialog
        withoutClose
        onClose={onClose}
        withoutFooterDivider
        maxWidth='sm'
        footer={
            <Footer>
                <Button variant='text' onClick={onClose}>
                    CANCEL
                </Button>
                <UploadButton
                    accept={['.shp', '.kml', '.json', '.geojson']}
                    onSelect={(file) => {
                        const name = 'geometry';
                        const event = createEvent('change', { name: name, value: file });
                        onChange && onChange(event);
                        onClose();
                    }}
                >
                    {onClick => (
                        <Button variant='text' onClick={onClick}>
                            {'ADD GEOMETRY'}
                        </Button>
                    )}
                </UploadButton>
            </Footer>
        }
    >
        <Message>
            {'Note that adding geometry will replace existing GPS coordinates. Do you want to continue?'}
        </Message>
    </ModalDialog>
);

const GeometryComponent = memo((props: Object) => {
    const [showModal, toggleModal] = useToggle(false);

    return (
        <div>
            <Button variant='text' onClick={toggleModal}>
                <MdiIcon name='upload' />
                {'Import geometry file'}
            </Button>
            {showModal && <UploadModal onClose={toggleModal} onChange={props.onChange} />}
        </div>
    );
});

GeometryComponent.propTypes = {
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
};

export default GeometryComponent;
