/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { loadWorkspacesTypeahead } from 'store/actions/admin/workspacesActions';

import { bind } from 'app/utils/decorators/decoratorUtils';

import AbstractEntityTypeahead from 'app/components/organisms/Form/Typeahead/abstract/AbstractEntityTypeahead';
import ResolveAvatarTypeaheadWrapper from 'app/components/organisms/Form/Typeahead/abstract/ResolveAvatarTypeaheadWrapper';

/**
 * Select one or more groups using lazy loading.
 */
class WorkspaceTypeahead extends PureComponent<Object, Object> {

    static propTypes = {
        loadWorkspacesTypeahead: PropTypes.func.isRequired,
        filterBy: PropTypes.arrayOf(PropTypes.object),
    }

    @bind
    optionTemplate({ name, id }: Object) {
        return ({
            label: `${name || 'Name not available'} (${id ? id.slice(0,8) : 'Id not available'})`,
        });};


    render() {
        const { loadWorkspacesTypeahead, ...abstractEntityAutocompleteProps } = this.props;
        return (
            <AbstractEntityTypeahead
                placeholder="Search for a Workspace..."
                {...abstractEntityAutocompleteProps}
                loadOptions={loadWorkspacesTypeahead}
                optionTemplate={this.optionTemplate}
            />
        );
    }
};

const WorkspaceTypeaheadWrapped = (props) => {
    return <ResolveAvatarTypeaheadWrapper {...props} Typeahead={WorkspaceTypeahead} entityType="workspace" />;
};


export default connect(
    null, { loadWorkspacesTypeahead }
)(WorkspaceTypeaheadWrapped);
