/* @flow */

import React from 'react';

import uuidv1 from 'uuid/v1';
import Help from 'app/utils/designer/form/settings/common/Help';
import DataObjectBuilder from 'app/components/Designer/Builders/DataObjectBuilder';
import SignalDefinitionBuilder from 'app/components/Designer/Builders/SignalDefinitionBuilder';
import MessageDefinitionBuilder from 'app/components/Designer/Builders/MessageDefinitionBuilder';
import EscalationDefinitionBuilder from 'app/components/Designer/Builders/EscalationDefinitionBuilder';
import EventListenersBuilder from 'app/components/Designer/Builders/EventListenersBuilder';
import ProcessDefinitionId from 'app/components/Designer/ProcessDefinitionId';
import { executionListener } from 'app/utils/designer/process/settings/common/commonListeners';
import { getProcNameCustomConstraint } from 'app/utils/designer/process/processDesignerUtils';

const dataObjectOptions = [
    { label: 'String', value: 'string'},
    { label: 'Boolean', value: 'boolean'},
    { label: 'Datetime', value: 'datetime'},
    { label: 'Double', value: 'double'},
    { label: 'Int', value: 'int'},
    { label: 'Long', value: 'long'},
];
const signalDefinitionOptions = [
    { label: 'Global', value: 'global'},
    { label: 'Process Instance', value: 'processInstance'},
];

const panels = [
    {
        header: 'Process',
        expanded: true,
        children: [
            {
                type: 'text',
                properties: {
                    label: 'Process definition ID',
                    name: 'process.id',
                    disabled: true,
                },
            },
            {
                type: 'text',
                properties: {
                    label: 'Name',
                    name: 'processName',
                },
                constraints: { required: true, custom: (data) => getProcNameCustomConstraint(data.processName) }
            },
            {
                type: 'textEditor',
                properties: {
                    label: 'Description',
                    name: 'process.description',
                },
            },
            {
                type: 'iconSelect',
                properties: {
                    label: 'Icon',
                    name: 'selectedIcon',
                    onChange: (e) => {
                        return [e.target, { name: 'process.iconName', value: e.target.value?.value || null }, { name: 'process.iconType', value: e.target.value?.type || null }];
                    }
                }
            },
            {
                type: 'colorPicker',
                properties: {
                    label: 'Icon Color',
                    name: 'process.iconColor'
                }
            },
            {
                type: 'boolean',
                properties: {
                    label: 'Hidden',
                    name: 'process.primary.hidden',
                    help: <Help message={'Show/Hide from App list.'} />,
                }
            },
            {
                type: 'boolean',
                properties: {
                    label: 'Status',
                    name: 'process.active',
                    help: <Help message={'Active/Inactive definition.'} />,
                }
            },
        ]
    },
    {
        header: 'Important',
        expanded: false,
        children: [
            { type: 'text', properties: { name: 'id', isVisible: false }},
            {
                type: 'custom',
                properties: {
                    cid: uuidv1(),
                    title: 'ID',
                    name: 'process.primary.definition',
                    Component: props => (
                        <ProcessDefinitionId {...props} />
                    ),
                },
                constraints: { required: true }
            },
            {
                type: 'text',
                properties: {
                    label: 'Name',
                    name: 'processName',
                    onChange: (event) => {
                        const value = event.target.value || '';
                        return [{ value: value.match(/[A-Za-z0-9]+/g).join(' '), name: event.target.name }];
                    }
                },
            },
            {
                type: 'text',
                properties: {
                    label: 'Potential Starter User',
                    name: 'flowable:candidateStarterUsers',
                },
            },
            {
                type: 'text',
                properties: {
                    label: 'Potential Starter Group',
                    name: 'flowable:candidateStarterGroups',
                },
            },
        ]
    },
    {
        header: 'Definitions',
        children: [
            {
                type: 'custom',
                properties: {
                    cid: uuidv1(),
                    title: 'Data Object',
                    name: 'process.primary.definition',
                    Component: DataObjectBuilder,
                    fields: [
                        { type: 'text', properties: { label: 'Id', name: 'id', clearable: false }, constraints: { required: true }},
                        { type: 'text', properties: { label: 'Name', name: 'name', clearable: false }, constraints: { required: true }},
                        { type: 'typeahead', properties: {
                            label: 'Type',
                            name: 'type',
                            valueField: 'value',
                            clearable: false,
                            options: dataObjectOptions,
                            defaultValue: 'string'
                        }, constraints: { required: true }},
                        { type: 'text', properties: { label: 'Default Value', name: 'defaultValue', options: dataObjectOptions }},
                    ]
                },
            },
            {
                type: 'custom',
                properties: {
                    cid: uuidv1(),
                    title: 'Signal definitions',
                    name: 'process.primary.definition',
                    Component: SignalDefinitionBuilder,
                    fields: [
                        { type: 'text', properties: { label: 'Id', name: 'id', clearable: false }, constraints: { required: true }},
                        { type: 'text', properties: { label: 'Name', name: 'name', clearable: false }, constraints: { required: true }},
                        { type: 'typeahead', properties: {
                            label: 'Scope',
                            name: 'scope',
                            valueField: 'value',
                            clearable: false,
                            options: signalDefinitionOptions,
                            defaultValue: 'global'
                        }, constraints: { required: true }},
                    ]
                },
            },
            {
                type: 'custom',
                properties: {
                    cid: uuidv1(),
                    title: 'Message definitions',
                    name: 'process.primary.definition',
                    Component: MessageDefinitionBuilder,
                    fields: [
                        { type: 'text', properties: { label: 'Id', name: 'id', clearable: false }, constraints: { required: true }},
                        { type: 'text', properties: { label: 'Name', name: 'name', clearable: false }, constraints: { required: true }},
                    ]
                },
            },
            {
                type: 'custom',
                properties: {
                    cid: uuidv1(),
                    title: 'Escalation definitions',
                    name: 'process.primary.definition',
                    Component: EscalationDefinitionBuilder,
                    fields: [
                        { type: 'text', properties: { label: 'Id', name: 'id', clearable: false }, constraints: { required: true }},
                        { type: 'text', properties: { label: 'Name', name: 'name', clearable: false }, constraints: { required: true }},
                    ]
                },
            },
        ]
    },
    {
        header: 'Listeners',
        children: [
            {
                type: 'custom',
                properties: {
                    cid: uuidv1(),
                    title: 'Event listeners',
                    name: 'process.primary.definition',
                    Component: EventListenersBuilder,
                },
            },
            executionListener
        ]
    },
];

export default {
    defaults: {},
    panels: settingValues => panels,
};
