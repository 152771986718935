

export default `
        id
        name
        image
        description
        primary
        createdDate
        modifiedDate
`;
