/* @flow */

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from '@mic3/platform-ui';

import { bind } from 'app/utils/decorators/decoratorUtils';
import { loadEntitiesListView } from 'store/actions/entities/entitiesActions';
import withPermissions from 'app/utils/withPermissions';

import AddPipeline from 'app/components/Designer/Modals/AddPipeline';
import PageTemplate from 'app/components/templates/PageTemplate';
import Layout from 'app/components/molecules/Layout/Layout';
import Icon from 'app/components/atoms/Icon/Icon';
import EntitiesList from 'app/containers/Entities/EntitiesList/EntitiesList';
import Alert from 'app/components/molecules/Alert/Alert';
import { commonEntitiesListFilters } from 'app/utils/entity/entityUtils';
import { setDocumentTitle } from 'store/actions/app/appActions';
import { modulesAndPageTitles } from 'app/config/typesConfig';

const IconStyled = styled(Icon)`
    &:before {
        color: rgba(255, 255, 255, 0.24);
        font-size: 6rem !important;
        font-weight: 100 !important;
        @media (max-width: 480px) {
            margin-left: 1rem;
        }
    }
`;

const Contaienr = styled.div`
    display: flex;
    justify-content: space-around;
    height: calc(100vh - 205px);
`;

const StartedText = styled.div`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 100;
    font-size: 60px;
    line-height: 68px;
    letter-spacing: -0.5px;
    color: rgba(255, 255, 255, 0.38);
    margin-left: 2rem;
`;

const Flex = styled.div`
    display: flex;
    align-items: center;
`;

const PageTemplateWrapper = styled('div')`
    height: 100%;
    & .page-content {
        height: 100%;
    }
`;

const VIEW_ID = 'DesignerPipelines'; 

class Pipelines extends PureComponent<Object, Object> {
    virtualListRef = React.createRef();

    static propTypes = {
        isLoading: PropTypes.bool,
        records: PropTypes.array,
        startIndex: PropTypes.number,
        totalRecords: PropTypes.number,
        loadEntitiesListView: PropTypes.func.isRequired,
        createPipeline: PropTypes.func.isRequired,
    };

    state = { isAddPipelineOpen: false };

    breadcrumb = [{ title: 'Pipeline Builder' }];
    searchBar = ['name', 'id'];

    defaultFilters = { active: true };
    filterDefinitions: Array<Object> = [
        { field: 'name', type: 'text', properties: { label: 'Name', name: 'name'  } },
        { field: 'id', type: 'uuid', properties: { label: 'ID', name: 'id'  }},
        {
            field: 'active',
            type: 'typeahead',
            properties: {
                label: 'Status',
                name: 'active',
                options: [
                    { value: null, label: 'Any' },
                    { value: true, label: 'Active' },
                    { value: false, label: 'Inactive' }
                ]
            },
            sort: false,
            condition: '='
        },        
        ...commonEntitiesListFilters
    ];
    defaultOrder = [{ field: 'createdDate', direction: 'desc nulls last' }];

    columnDefinitions = [
        { text: 'Entity Name', field: 'name' },
        { text: 'Description', field: 'description' },
        { text: 'Entity ID', field: 'id', onClickDisabled: true },
        { text: 'Status', field: 'active', align: 'center' }, 
        { text: 'Last Updated', field: 'modifiedDate' }, 
        { text: 'Modified By', field: 'modifiedBy' }, 
        { text: 'Created Date', field: 'createdDate' }, 
        { text: 'Created by', field: 'createdBy' }, 
    ];

    componentDidMount() {
        this.props.setDocumentTitle(modulesAndPageTitles.designer.pipeline.main);
    }

    @bind
    toggleAddPipeline() {
        this.setState({ isAddPipelineOpen: !this.state.isAddPipelineOpen });
    };

    @bind
    loadData({ isShort, options }) {
        const { loadEntitiesListView } = this.props;
        return loadEntitiesListView({ isShort, options, type: 'iot_pipeline' });
    };

    @bind
    renderNoResultComponent(filterBy: Object) {
        const { canAdd } = this.props;

        if (filterBy && filterBy.length > 0) {
            return (
                <Alert type='background' margin={16}>
                    No results
                </Alert>
            );
        }
        return (
            <PageTemplateWrapper title={'Pipelines'}>
                <PageTemplate title={'Pipelines'}>
                    <Contaienr>
                        <Flex>
                            <div style={{ display: 'flex', textAlign: 'initial' }}>
                                <IconStyled style={{ lineHeight: '70px' }} name='plus' />
                                <StartedText>
                                            Let's get started
                                    <br />
                                    {canAdd && (
                                        <Button variant='text' onClick={this.toggleAddPipeline}>
                                                    create new 
                                        </Button>
                                    )}
                                </StartedText>
                            </div>
                        </Flex>
                    </Contaienr>
                </PageTemplate>
            </PageTemplateWrapper>
        );
    }

    render() {
        const { totalRecords, records, isLoading, startIndex, canAdd } = this.props;
        const { isAddPipelineOpen } = this.state;
        return (
            <PageTemplate title="Pipelines">
                <Layout>
                    <EntitiesList
                        pageViewId={VIEW_ID}
                        id={VIEW_ID}
                        ref={this.listRef}
                        type={'iot_pipeline'}
                        headerTitle="Pipelines"
                        records={records}
                        totalRecords={totalRecords}
                        startIndex={startIndex}
                        isLoading={isLoading}
                        loadData={this.loadData}
                        columnDefinitions={this.columnDefinitions}
                        filterDefinitions={this.filterDefinitions}
                        defaultFilters={this.defaultFilters}
                        location={location}
                        canAdd={canAdd}
                        downloadable
                        uploadable
                        VirtualListCardsProps={{
                            renderNoResultComponent: this.renderNoResultComponent,
                        }}
                        VirtualListProps={{
                            renderNoResultComponent: this.renderNoResultComponent,
                        }}                            
                    />
                </Layout>
                {isAddPipelineOpen && <AddPipeline onClose={this.toggleAddPipeline} />}
            </PageTemplate>
        );
    }
};

export default connect(state => ({
    records: state.entities.entities.records,
    totalRecords: state.entities.entities.count,
    isLoading: state.entities.entities.isLoading,
    startIndex: state.entities.entities.startIndex,
    userProfile: state.user.profile,
}), {
    loadEntitiesListView,
    setDocumentTitle
})(withPermissions(Pipelines, 'iot_pipeline'));
