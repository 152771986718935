/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Tooltip, IconButton } from '@mic3/platform-ui';
import styled from 'styled-components';
import { get } from 'app/utils/lo/lo';
import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import EntitiesList from 'app/containers/Entities/EntitiesList/EntitiesList';
import EntityLink from 'app/components/atoms/Link/EntityLink';
import ListItem from 'app/components/molecules/List/ListItem';
import PageTemplate from 'app/components/templates/PageTemplate';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { formatDate } from 'app/utils/date/date';
import { typeTitlesMultiple } from 'app/config/typesConfig';
import { loadEvents, loadEventTypeDetails } from 'store/actions/stream/eventsActions';
import ResizableListItem from 'app/components/molecules/VirtualList/ResizableListItem';
import { openEventSidebar } from 'store/actions/stream/eventsSidebarActions';
import ButtonIcon from 'app/components/molecules/ButtonIcon/ButtonIcon';
import EventsListCard from 'app/containers/Stream/Events/EventsListView/EventsListCard';
import GridEntityId from 'app/components/organisms/GridView/GridRenderers/GridEntityId';
import { openEntitySidebar } from 'store/actions/entities/entitySidebarActions';
import GridEntityName from 'app/components/organisms/GridView/GridRenderers/GridEntityName';
import Icon from 'app/components/atoms/Icon/Icon';
import Textarea from 'app/containers/Designer/Form/components/Textarea';
import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import EventActionRenderer from 'app/components/molecules/Grid/Renderers/EventAction/EventActionRenderer';
import { openEventsActionsSidebar } from 'store/actions/stream/eventsActionsSidebarActions';
import filterDefinitions, { severityOptions } from 'app/containers/Stream/Events/EventsListView/eventsFilterDefinitions';
import { getEntityUrl } from 'app/utils/entity/entityUtils';
import Loader from 'app/components/atoms/Loader/Loader';
import { setDocumentTitle } from 'store/actions/app/appActions';
import { modulesAndPageTitles } from 'app/config/typesConfig';


const StyledListItem = styled(ListItem)`
    max-width: 1024px;
    margin: 0 auto;
`;

const IconStyled = styled(Icon)`
    &:before {
        color: ${({theme})=> theme.material.colors.text.secondary};
    }
`;
const SeverityCircleStyled = styled.span`
    background-color: ${({ severity }) => ({
    0: '#4DB6AC',
    4: '#BBC34A',
    3: '#FFB300',
    2: '#FF7322',
    1: '#EB2330',
})[severity]};
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
`;
const SeverityTextStyled = styled.span`
    color: ${({ severity }) => ({
    0: '#4CDCCA',
    4: '#A5D869',
    3: '#FFBD4C',
    2: '#F98F61',
    1: '#FF8A91',
})[severity]} !important;
`;

const VIEW_ID = 'EventsListView';

const listType = 'event';

class EventsListView extends PureComponent<Object, Object> {
    static propTypes = {
        loadEvents: PropTypes.func.isRequired,
        records: PropTypes.array,
        isLoading: PropTypes.bool,
        totalRecords: PropTypes.number
    };

    static defaultProps = {
        isLoading: false,
        VirtualListProps: {},
        FiltersProps: {}
    };

    listRef = React.createRef();

    searchBar = ['id', 'device.id'];
    searchHelperText = 'Please enter a valid Event or Device UUID. UUID should be either complete or first 8 digits.';
    defaultFilters = { 
        severity: null, status: null, 
        time: {
            relative: true,
            range: 'subtract',
            amount: '7',
            unit: 'd'
        }
    };
    defaultOrder = [{ field: 'time', direction: 'desc nulls last' }];

    state = {
        extraDataValue: null,
        extraDataTitle: 'IoT Data',
        customColumns: [],
        eventTypeData: {},
    };

    columnDefinitions = [
        {
            text: 'Actions',
            field: 'actions',
            width: 200,
            align: 'center',
            onClickDisabled: true,
            sortable: false,
            renderer: ({ record }) => {
                const processDefinitions = get(record.data, 'eventType.metadata.actions') || [];
                const isProcessIcon = !!processDefinitions?.length;
                return (
                    <div>
                        <EventActionRenderer canEdit={true} refresh={this.resetView} data={record?.data} />
                        {!isProcessIcon ? <IconButton style={{ width: '50px', height: '50px', background: 'transparent'}} /> : null}
                        <IconButton onClick={e => this.onThreeDotClick(e, record?.data)}>
                            <IconStyled name={'dots-vertical'} />
                        </IconButton>
                    </div>
                );
            }
        },
        {
            text: 'Entity Name',
            width: 210,
            align: 'left',
            field: 'device.name',
            renderer: ({ value, record }) =>{
                const { device } = record?.data || {};
                return (
                    <GridEntityName
                        value={value}
                        openSidebar
                        data={{ ...(device|| {}), type: device?.type }}
                        onClick={(e) => this.openEntitySidebar(e, device)}
                    />
                );
            },
            onClickDisabled: true
        },
        {
            text: 'Entity ID',
            field: 'device.id',
            renderer: ({ value, record }) => {
                const { id, type } = record?.data?.device || {};
                return (
                    <GridEntityId
                        onClick={(e) => this.openEntitySidebar(e, { id, type })}
                        value={id && getEntityUrl(id, type)}
                        label={id}
                        valueId={id}
                    />
                );
            },
            onClickDisabled: true
        },
        {
            text: 'IoT Data',
            field: 'data',
            onClickDisabled: true,
            renderer: ({ value }) => (
                <Icon
                    name='text-box-outline'
                    onClick={(e) => this.openExtraDataModal(e, get(value, 'data'))}
                    hexColor='#7391D0'
                    disabled={!get(value, 'data')}
                />
            ),
            align: 'center',
            sortable: false
        },
        {
            text: 'Event Time',
            field: 'time',
            renderer: ({ value }) => formatDate(value)
        },
        {
            text: 'Severity',
            field: 'severity',
            renderer: ({ value }) =>
                value !== null ? (
                    <>
                        <SeverityCircleStyled severity={value} />
                        <SeverityTextStyled severity={value}>{severityOptions[value]}</SeverityTextStyled>
                    </>
                ) : (
                    ''
                ),
        },
        {
            text: 'Event Description',
            field: 'description',
            sortable: false,
        },
        {
            text: 'Event Translated Impact',
            field: 'data.impact',
            renderer: ({ record }) => <span>{record?.data?.data?.impact}</span>,
        },
        {
            text: 'Event Status',
            field: 'status'
        },
        {
            text: 'Event Translated Type',
            field: 'data.type',
            renderer: ({ record }) => <span>{record?.data?.data?.type}</span>,
        },
        {
            text: 'Device Original IOT Signal Code',
            field: 'alarmCode'
        },
        {
            text: 'Mi-Stream Time',
            field: 'streamTime',
            renderer: ({ value }) => formatDate(value)
        },
        {
            text: 'Last Modified By',
            field: 'modifiedBy'
        },
        {
            text: 'Last Modified Date',
            field: 'modifiedDate'
        },

        {
            text: 'Created by',
            field: 'createdBy'
        },
        {
            text: 'Created Date',
            field: 'createdDate'
        },
    ];

    constructor(props) {
        super(props);
        const eventTypeId = get(props.match, 'params.id', '');
        this.loadEventTypeData(eventTypeId);
    }

    componentDidMount() {
        this.props.setDocumentTitle(modulesAndPageTitles.miStream.events);
    }

    componentDidUpdate(prevProps) {
        const eventTypeId = get(this.props.match, 'params.id', '');
        this.loadEventTypeData(eventTypeId);
    }
    
    @bind
    @memoize()
    buildColumnsDefinitions(eventType, pathname){                                                  
        const { entityType, descriptorType } = eventType || {};
        let { columnDefinitions } = this;
        const isEventsList = pathname === '/events';
        if(!isEventsList && !entityType){
            columnDefinitions = columnDefinitions.filter((col)=> col.text !== 'Entity Name' && col.text !== 'Entity ID');
        }
        if(!isEventsList && !descriptorType){
            columnDefinitions = columnDefinitions.filter((col)=> col.text !== 'Descriptor Name' && col.text !== 'Descriptor ID');
        }
        if(isEventsList){
            columnDefinitions.splice(3,0,{
                text: 'Descriptor Name',
                width: 210,
                align: 'left',
                field: 'descriptor.name',
                renderer: ({ value, record }) =>{
                    const { descriptor } = record?.data || {};
                    return (
                        <GridEntityName
                            value={value}
                            openSidebar
                            data={{ ...(descriptor|| {}), type: descriptor?.type }}
                            onClick={(e) => this.openEntitySidebar(e, descriptor)}
                        />
                    );
                },
            },
            {
                text: 'Descriptor ID',
                field: 'descriptor.id',
                width: 110,
                renderer: ({ value, record }) => {
                    const { id, type } = record?.data?.descriptor || {};
                    return (
                        <GridEntityId
                            onClick={(e) => this.openEntitySidebar(e, { id, type })}
                            value={id && getEntityUrl(id, type)}
                            label={id}
                            valueId={id}
                        />
                    );
                },
                onClickDisabled: true
            });
        }
        return columnDefinitions; 
    }

    @bind
    @memoize()
    async loadEventTypeData(eventTypeId) {
        this.setState({ eventTypeData: {}, customColumns: [] });
        if (!eventTypeId) return;
        const { loadEventTypeDetails } = this.props;
        try {
            const eventTypeData = await loadEventTypeDetails(eventTypeId);
            const customColumns = this.buildCustomColumns(eventTypeData);
            this.setState({ customColumns, eventTypeData });
        } catch (error) {}
    }

    @bind
    @memoize()
    buildCustomColumns(eventTypeData) {
        const attributes = eventTypeData?.eventDataAttributes;
        if (!attributes?.length) {
            return [];
        }
        return attributes.map(({ label: text, attribute: field, format:type }) => ({
            text,
            field,
            dataType: type,
            sortable: false,
            onClickDisabled: ['json', 'link'].includes(type),
            width: 200,
            align: type === 'json' ? 'center' : 'left',
            renderer: ({ value, record }) => {
                const val = value || get(record?.data, field, '');
                if(!val) return '';
                if(Array.isArray(val)) return '';
                if (type === 'json') {
                    return (
                        <Icon
                            name='text-box-outline'
                            onClick={(e) => this.openExtraDataModal(e, val, text)}
                            hexColor='#7391D0'
                            disabled={!val}
                        />
                    );
                }
                if (type === 'link' && typeof val === 'string') {
                    return (
                        <a href={val} target='_blank' rel='noopener noreferrer' onClick={(e) => e?.stopPropagation()}>
                            {val}
                        </a>
                    );
                }
                if(typeof val === 'object') { // this will eliminate arrays and objects
                    return '';
                }
                return <div>{val}</div>;
            },
        }));
    }

    @bind
    onThreeDotClick(event, data) {
        event.stopPropagation();
        const params = { id: data.id, data, type: 'event', title: 'Actions', reloadList: this.resetView };
        this.props.openEventsActionsSidebar(params);
    }

    @bind
    openExtraDataModal(e, data, extraDataTitle) {
        e.stopPropagation();
        data && this.setState({ extraDataValue: JSON.stringify(data, null, 2), extraDataTitle: extraDataTitle || 'IoT Data' });
    }

    @bind
    closeModal() {
        this.setState({ extraDataValue: null });
    }

    @bind
    openEntitySidebar(e, device) {
        e.stopPropagation();
        const { id, type } = device || {};
        id && type && this.props.openEntitySidebar({ title: 'About', id, type });
    }

    @bind
    renderComponent({ style, index, data, resize }) {
        const { id, device, time } = data;
        const { openEventSidebar } = this.props;

        return (
            <ResizableListItem style={style} key={index} index={index} resize={resize} padding={15}>
                {resizeRow => (
                    <StyledListItem
                        title={
                            device ? (
                                <EntityLink type={device?.type} id={device.id}>
                                    {device.name}
                                </EntityLink>
                            ) : (
                                `#${id}`
                            )
                        }
                        subTitle={
                            <>
                                <div>{device ? `#${id}` : null}</div>
                                <div>Time: {formatDate(time)}</div>
                            </>
                        }
                        actions={
                            <>
                                <Tooltip placement="top" arrow title="Open About">
                                    <span>
                                        <ButtonIcon icon={'information'} onClick={() => openEventSidebar({ title: 'About', id, time })} />
                                    </span>
                                </Tooltip>
                            </>
                        }
                        raised
                    />
                )}
            </ResizableListItem>
        );
    }

    @bind
    resetView() {
        this.listRef.current.resetView();
    }

    @bind
    renderCardsComponent(props: Object) {
        const { openEventSidebar } = this.props;
        return <EventsListCard key={props.index} {...props} openEventSidebar={openEventSidebar} resetView={this.resetView} />;
    }

    render() {
        const { loadEvents, startIndex, isLoading, records, totalRecords, location } = this.props;
        const { extraDataValue, extraDataTitle, eventTypeData } = this.state;
        const eventTypeId = get(this.props.match, 'params.id', '');
        return (
            <PageTemplate title={typeTitlesMultiple[listType]}>
                <ContentArea>
                    {eventTypeId && eventTypeId !== eventTypeData?.id ? (
                        <Loader absolute />
                    ) : (
                        <EntitiesList
                            disableAdd
                            pageViewId={VIEW_ID}
                            id={eventTypeId ? `${VIEW_ID}_${eventTypeId}` : VIEW_ID}
                            ref={this.listRef}
                            defaultView='view-cards'
                            type={listType}
                            headerTitle={typeTitlesMultiple[listType]}
                            records={records}
                            totalRecords={totalRecords}
                            startIndex={startIndex}
                            isLoading={isLoading}
                            loadData={loadEvents}
                            customColumns={this.state.customColumns}
                            filterDefinitions={filterDefinitions}
                            renderCardsComponent={this.renderCardsComponent}
                            columnDefinitions={this.buildColumnsDefinitions(eventTypeData, location.pathname)}
                            searchBar={this.searchBar}
                            searchHelperText={this.searchHelperText}
                            defaultOrder={this.defaultOrder}
                            defaultFilters={this.defaultFilters}
                            VirtualListProps={{ itemSize: 80 }}
                            enableCountdown
                            idField="eventUniqueId"
                        />
                    )}
                    {extraDataValue && (
                        <ModalDialog title={extraDataTitle} onClose={this.closeModal}>
                            <Textarea disabled rows={20} value={extraDataValue} useCodeEditor mode='JSON' />
                        </ModalDialog>
                    )}
                </ContentArea>
            </PageTemplate>
        );
    }
}

export default connect(
    (state: Object) => ({
        records: state.stream.events.list.isLoading
            ? [] // Workaround until we separate reducer for each event type, when the event type is changed the reducer contains the old data and new definitions try to show the old data crashing bryntum grid
            : state.stream.events.list.records.map((e, index) => ({ ...e, eventUniqueId: `${e.id}_${e.time}_index${index}` })),  // Ticket #71fd1fce - Events having same IDs crashing grid so we need to dictate grid to pick other field. I noticed that some of the events have the same time and ID so to be on safe side I am adding index 
        totalRecords: state.stream.events.list.count,
        isLoading: state.stream.events.list.isLoading,
        startIndex: state.stream.events.list.startIndex,
    }),
    { loadEvents, openEventSidebar, openEntitySidebar, openEventsActionsSidebar, loadEventTypeDetails, setDocumentTitle }
)(EventsListView);
