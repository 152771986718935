/* @flow */

import { gql } from '@apollo/client';

import { detailsFields } from './taskQuery';

export default gql`
    mutation updateTaskMutation($record: TaskUpdateInput) {
        result: updateTask(record: $record) {
            ${detailsFields}
        }
    }
`;
