/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mic3/platform-ui';

import Loader from 'app/components/atoms/Loader/Loader';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import RelationItem from 'app/containers/Entities/Relationships/RelationItem';
import { toUniqueUUID } from 'app/utils/string/string-utils';
import { isEmpty } from 'app/utils/utils';
import { getPermissions } from 'app/config/rolesConfig';

/**
 * Renders the view to shoe the relationships of an entity.
 */
class RelationList extends PureComponent<Object, Object> {
    static propTypes = {
        relationMap: PropTypes.object,
        reloadList: PropTypes.func
    };

    constructor(props) {
        super(props);
        this.state = { relations: props.relations };
        props.buildBreadcrumb([{
            title: props.details.name,
            onClick: () => this.goToEntityRelations(props.details),
            linkTitle: `#${toUniqueUUID(props.details.id)}`
        }]);
    }

    componentDidUpdate(prevProps){
        const { relations } = this.props;
        if(relations && relations !== prevProps.relations){
            this.setState({relations: relations});
        }
    }

    @bind
    async goToEntityRelations(relatedEntity) {
        const { id, type, name, subTitle } = relatedEntity;
        const { buildBreadcrumb, breadcrumbs, getRelationsFromMap } = this.props;
        const result = await getRelationsFromMap({ type, id, filterBy: [] });
        const isBreadcrumbed = !!breadcrumbs.find(b => b.title === name);
        let nextBreadcrumbs = [...breadcrumbs];
        if(isBreadcrumbed) {
            let isFind = false;
            nextBreadcrumbs = breadcrumbs.map((b) => {
                if(b.title === name) {
                    isFind = true;
                    return b;
                }
                return !isFind ? b : null;
            }).filter(Boolean);
        } else {
            nextBreadcrumbs.push({
                title: name,
                onClick: () => this.goToEntityRelations(relatedEntity),
                linkTitle: `#${toUniqueUUID(id)}`,
                subTitle,
            });
        }
        buildBreadcrumb(nextBreadcrumbs);
        this.setState({ relations: result.relations });
    }

    @bind
    @memoize()
    buildPanel(relationMap, canEdit, sidebarTitle, entityPermissions) {
        return (Object.entries(relationMap) || []).map(([relationId, relationData]) => {
            return (
                <RelationItem
                    key={relationId}
                    viewType={this.props.viewType}
                    sidebarTitle={sidebarTitle}
                    relationData={relationData}
                    toggleInfoDialog={this.props.toggleInfoDialog}
                    deleteRelation={this.props.deleteRelation}
                    openEntityAbout={this.props.openEntityAbout}
                    canEdit={canEdit}
                    openRelationSidebar={this.props.openRelationSidebar}
                    reloadList={this.props.reloadList}
                    goToEntityRelations={this.goToEntityRelations}
                    entityPermissions={entityPermissions}
                />
            );
        });
    }

    /**
     * @override
     */
    render() {
        const { canEdit, filter, groupByRelationDefinitionFilter, sidebarTitle, details } = this.props;
        const { isLoading, relations } = this.state;
        if (isLoading) {
            return <Loader absolute />;
        }
        const relationMap = groupByRelationDefinitionFilter(relations, filter);
        const permissions = getPermissions(details?.role);
        return (
            <>
                {filter && isEmpty(relationMap) ? (
                    <Grid container justify="center">
                        <Grid>No search results</Grid>
                    </Grid>
                ) : ''}
                {this.buildPanel(relationMap, canEdit, sidebarTitle, permissions)}
            </>
        );
    }
}

export default RelationList;
