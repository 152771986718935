/* @flow */

import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import HeaderBar from 'app/components/molecules/HeaderBar/HeaderBar';
import PageTemplate from 'app/components/templates/PageTemplate';
import Loader from 'app/components/atoms/Loader/Loader';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import Help from 'app/utils/designer/form/settings/common/Help';
import { get } from 'app/utils/lo/lo';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { setDocumentTitle, updateSecurityGeneral } from 'store/actions/app/appActions';
import { Button, CircularProgress, Typography } from '@mic3/platform-ui';
import { showToastr } from 'store/actions/app/appActions';
import { getPermissions } from 'app/config/rolesConfig';
import { modulesAndPageTitles } from 'app/config/typesConfig';

const ButtonStyled = styled(Button)`
    margin-right: 8px !important;
`;

const StyledTypography = styled(Typography)`
    color: ${({theme})=> theme.material.colors.text.primary};
`;

class TokenSettingsMain extends PureComponent<Object, Object> {
    formTokens: Object = React.createRef();
    formDefinitions = [
        {
            type: 'panel',
            properties: {
                header: 'General',
                expanded: true,
            },
            children: [
                {
                    type: 'group',
                    properties: { name: 'token' },
                    constraints: { required: true },
                    children: [
                        // defaultSignatureAlgorithm (Default Signature Algorithm)
                        // {
                        //     type: 'typeahead',
                        //     properties: {
                        //         name: 'defaultSignatureAlgorithm',
                        //         label: 'Default signature algorithm',
                        //         options: [
                        //             { value: 'HS256', label: 'HS256' },
                        //             { value: 'RS256', label: 'RS256' },
                        //         ],
                        //         clearable: false,
                        //         help: <Help message="Default algorithm used to sign tokens for the realm" />,
                        //         // help: <Help message="" />,
                        //     },
                        // },
                        // oauth2DeviceCodeLifespan (OAuth 2.0 Device Code Lifespan)
                        {
                            type: 'number', // seconds
                            properties: {
                                name: 'oauth2DeviceCodeLifespan',
                                label: 'OAuth 2.0 device code lifespan (seconds)',
                                help: <Help message="Max time before the device code and user code are expired. This value needs to be a long enough lifetime to be usable (allowing the user to retrieve their secondary device, navigate to the verification URI, login, etc.), but should be sufficiently short to limit the usability of a code obtained for phishing." />,
                            },
                            constraints: { onlyInteger: true, min: 0 },
                        },
                        // oauth2DevicePollingInterval (OAuth 2.0 Device Polling Interval)
                        {
                            type: 'number', // seconds
                            properties: {
                                name: 'oauth2DevicePollingInterval',
                                label: 'OAuth 2.0 device polling interval (seconds)',
                                help: <Help message="The minimum amount of time in seconds that the client should wait between polling requests to the token endpoint." />,
                            },
                            constraints: { onlyInteger: true, min: 0 },
                        },
                        // attributes.shortVerificationUri (Short verification_uri in Device Authorization flow)
                        {
                            type: 'text',
                            properties: {
                                name: 'shortVerificationUri',
                                label: 'Short verification_uri in device authorisation flow',
                                help: <Help message="If set, this value will be return as verification_uri in Device Authorization flow. This uri need to redirect to {server-root}/realms/{realm}/device" />,
                            },
                        },
                    ],
                },
            ],
        },
        {
            type: 'panel',
            properties: {
                header: 'Access Tokens',
                expanded: true,
            },
            children: [
                {
                    type: 'group',
                    properties: { name: 'token' },
                    constraints: { required: true },
                    children: [
                        // revokeRefreshToken (Revoke Refresh Token)
                        {
                            type: 'boolean',
                            properties: {
                                name: 'revokeRefreshToken',
                                label: 'Revoke refresh token',
                                help: <Help message="If enabled a refresh token can only be used up to 'Refresh Token Max Reuse' and is revoked when a different token is used. Otherwise refresh tokens are not revoked when used and can be used multiple times." />,
                            },
                        },
                        // refreshTokenMaxReuse (Refresh Token Max Reuse)
                        {
                            type: 'number',
                            properties: {
                                name: 'refreshTokenMaxReuse',
                                label: 'Refresh token max reuse',
                                isVisible: (data) => data.revokeRefreshToken,
                                help: <Help message="Maximum number of times a refresh token can be reused. When a different token is used, revocation is immediate." />,
                            },
                            constraints: { onlyInteger: true, min: 0 },
                        },
                        // accessTokenLifespan (Access Token Lifespan)
                        {
                            type: 'number', // seconds
                            properties: {
                                name: 'accessTokenLifespan',
                                label: 'Access token lifespan (seconds)',
                                help: <Help message="Max time before an access token is expired. This value is recommended to be short relative to the SSO timeout" />,
                            },
                            constraints: { onlyInteger: true, min: 0 },
                        },
                        // accessTokenLifespanForImplicitFlow (Access Token Lifespan For Implicit Flow)
                        {
                            type: 'number', // seconds
                            properties: {
                                name: 'accessTokenLifespanForImplicitFlow',
                                label: 'Access token lifespan for implicit flow (seconds)',
                                help: <Help message="Access Token Lifespan For Implicit Flow" />,
                            },
                            constraints: { onlyInteger: true, min: 0 },
                        },
                        // accessCodeLifespan (Client Login Timeout)
                        {
                            type: 'number', // seconds
                            properties: {
                                name: 'accessCodeLifespan',
                                label: 'Client login timeout (seconds)',
                                help: <Help message="Max time a client has to finish the access token protocol. This should normally be 1 minute." />,
                            },
                            constraints: { onlyInteger: true, min: 0 },
                        },
                    ],
                },
            ],
        },
        {
            type: 'panel',
            properties: {
                header: 'Action Tokens',
                expanded: true,
            },
            children: [
                {
                    type: 'group',
                    properties: { name: 'token' },
                    constraints: { required: true },
                    children: [
                        // actionTokenGeneratedByUserLifespan (User-Initiated Action Lifespan)
                        {
                            type: 'number', // seconds
                            properties: {
                                name: 'actionTokenGeneratedByUserLifespan',
                                label: 'User-initiated action lifespan (seconds)',
                                help: <Help message="Maximum time before an action permit sent by a user (such as a forgot password e-mail) is expired. This value is recommended to be short because it's expected that the user would react to self-created action quickly." />,
                            },
                            constraints: { onlyInteger: true, min: 0 },
                        },
                        // actionTokenGeneratedByAdminLifespan (Default Admin-Initiated Action Lifespan)
                        {
                            type: 'number', // seconds
                            properties: {
                                name: 'actionTokenGeneratedByAdminLifespan',
                                label: 'Default admin-initiated action lifespan (seconds)',
                                help: <Help message="Maximum time before an action permit sent to a user by administrator is expired. This value is recommended to be long to allow administrators to send e-mails for users that are currently offline. The default timeout can be overridden immediately before issuing the token." />,
                            },
                            constraints: { onlyInteger: true, min: 0 },
                        },
                        {
                            type: 'divider',
                        },
                        {
                            type: 'header',
                            properties: {
                                text: 'Override Action Tokens',
                                variant: 'h6',
                            },
                        },

                        // attributes.actionTokenGeneratedByUserLifespan.verify-email (Email Verification)
                        {
                            type: 'number', // seconds
                            properties: {
                                name: 'actionTokenLifespanEmailVerification',
                                label: 'Email verification (seconds)',
                            },
                            constraints: { onlyInteger: true, min: 0 },
                        },
                        // attributes.actionTokenGeneratedByUserLifespan.idp-verify-account-via-email (IdP account email verification)
                        {
                            type: 'number', // seconds
                            properties: {
                                name: 'actionTokenLifespanIdpAccountEmailVerification',
                                label: 'IdP account email verification (seconds)',
                            },
                            constraints: { onlyInteger: true, min: 0 },
                        },
                        // attributes.actionTokenGeneratedByUserLifespan.reset-credentials (Forgot password)
                        {
                            type: 'number', // seconds
                            properties: {
                                name: 'actionTokenLifespanForgotPassword',
                                label: 'Forgot password (seconds)',
                            },
                            constraints: { onlyInteger: true, min: 0 },
                        },
                        // attributes.actionTokenGeneratedByUserLifespan.execute-actions (Execute actions)
                        {
                            type: 'number', // seconds
                            properties: {
                                name: 'actionTokenLifespanExecuteActions',
                                label: 'Execute actions (seconds)',
                            },
                            constraints: { onlyInteger: true, min: 0 },
                        },
                    ],
                },
            ],
        },
    ];

    constructor(props) {
        super(props);
        
        this.state = { 
            formData: props.ssoSettings, 
            formRevertKey: 0, 
            formTouched: false 
        };

    }

    componentDidMount() {
        this.props.setDocumentTitle(modulesAndPageTitles.adminConsole.security.tokens);
    }

    componentDidUpdate(prevProps) {
        const { ssoSettings } = this.props;
        if (ssoSettings !== prevProps.ssoSettings) {
            this.setState({ formData: ssoSettings, formTouched: false });
        }
    }

    @bind
    onFormSubmit() {
        return (event: Event) => {
            event.preventDefault();
            this.formTokens.current.isValidForm().then(({ data, errors }) => {
                if (!errors) {
                    const { updateSecurityGeneral } = this.props;
                    updateSecurityGeneral({ token: data.token });
                }
            });
        };
    }

    @bind
    @memoize()
    renderPasswordButtons(isLoading, formTouched, canEdit) {
        if(!canEdit) return null;
        return isLoading ? (
            <CircularProgress key={113} size={24} color='primary' />
        ) : (
            <>
                <ButtonStyled key={113} onClick={this.onFormSubmit(false)} color='primary' form='form' type='submit'>
                    Save
                </ButtonStyled>
                <Button disabled={!formTouched} variant='outlined' key={114} onClick={this.revertChanges} color='primary'>
                    Revert
                </Button>
            </>
        );
    }

    @bind
    handleChangeRequiredActions({ target: { name, value } }) {
        const { updateSecurityGeneral, ssoSettings } = this.props;
        const [actionName, checkboxName] = name.split('.');
        updateSecurityGeneral({
            requiredActions: ssoSettings.requiredActions.map((action) => {
                if (action.name === actionName) {
                    return { ...action, [checkboxName]: value };
                }
                return action;
            }),
        });
    }

    @bind
    revertChanges() {
        this.setState({
            formRevertKey: this.state.formRevertKey + 1,
            formTouched: false,
            formData: this.props.ssoSettings,
        });
    }
    @bind
    onChangeForm(data) {
        this.setState({ formData: data, formTouched: true });
    }

    render() {
        const { isLoading, ssoSettings } = this.props;
        const { formRevertKey, formTouched, formData } = this.state;
        const { canEdit } = getPermissions(ssoSettings?.role);
        return (
            <PageTemplate title={'Tokens'} overflowHidden>
                <HeaderBar right={this.renderPasswordButtons(isLoading, formTouched, canEdit)} left={<StyledTypography variant='h6'>Tokens</StyledTypography>} />
                {isLoading && <Loader absolute backdrop />}
                <ContentArea withHeader>
                    <FormGenerator
                        key={formRevertKey}
                        ref={this.formTokens}
                        data={formData}
                        onChange={this.onChangeForm}
                        disabled={!canEdit}
                        components={this.formDefinitions}
                    />
                </ContentArea>
            </PageTemplate>
        );
    }
}

export default connect(
    state => ({
        isLoading: state.app.ssoSettingsLoading,
        ssoSettings: get(state.app, 'ssoSettings', {}),
    }),
    {
        showToastr,
        updateSecurityGeneral,
        setDocumentTitle
    }
)(TokenSettingsMain);
