/* @flow */

import { gql } from '@apollo/client';

export default gql`
query widgetTasksCountByCreatedDateEndDateQuery(
  $today: [JSON], $todayExclude: [JSON],
  $yesterday: [JSON], $yesterdayExclude: [JSON],
  $lastThirtyDays: [JSON], $lastThirtyDaysExclude: [JSON],
  $lastThirtyPlusDays: [JSON], $lastThirtyPlusDaysExclude: [JSON]
) {
  today: count(dataType: "task", filterBy: $today, excludeBy: $todayExclude)
  yesterday: count(dataType: "task", filterBy: $yesterday, excludeBy: $yesterdayExclude)
  lastThirtyDays: count(dataType: "task", filterBy: $lastThirtyDays, excludeBy: $lastThirtyDaysExclude)
  lastThirtyPlusDays: count(dataType: "task", filterBy: $lastThirtyPlusDays, excludeBy: $lastThirtyPlusDaysExclude)
}
`;
