/* @flow */

import React, { PureComponent } from 'react';
import styled from 'styled-components';
import {
    Button,
    Divider,
    Tooltip,
    MdiIcon,
    Grid,
    TextField,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    ListItemIcon,
    Checkbox,
} from '@mic3/platform-ui';
import { muiTheme } from 'app/themes/materialUi';
import { connect } from 'react-redux';

import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import { saveFiltersPreferences, saveColumnPreferences } from 'store/actions/admin/usersActions';
import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import { saveComponentState } from 'store/actions/component/componentActions';
import { bind } from 'app/utils/decorators/decoratorUtils';
import { get } from 'app/utils/lo/lo';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import DraggableElement from 'app/components/molecules/Dnd/DraggableElement';
import RowTarget from 'app/components/molecules/Dnd/RowTarget';
import Icon from 'app/components/atoms/Icon/Icon';
import { showToastr } from 'store/actions/app/appActions';
import { arrayObjectEquals } from 'app/utils/utils';
import EntitiesColumnAction from './EntitiesColumnAction';
import { setActions, setSubTitle, closeSidebar, setTitle } from 'store/actions/sidebar/sidebarActions';
import { deepCloneArray, removeDuplicates } from 'app/utils/array/array-utils';

const SearchIcon = styled(MdiIcon)`
    line-height: 24px !important;
`;

const TextFieldStyled = styled(TextField)`
    flex-grow: 1;
    margin: 8px 0 8px 19px !important;
`;

const GridStyled = styled(Grid)`
    display: flex;
    align-items: center;
    padding: 0px 8px !important;
`;

const ListItemTextStyled = styled(ListItemText)`
    & .MuiTypography-root {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 23rem;
        white-space: nowrap;
    }
`;

const ListItemSecondaryActionStyled = styled(ListItemSecondaryAction)`
    right: 0px !important;
    & .MuiFormControlLabel-root {
        margin-right: 0 !important;
    }
`;

const StyledTypography = styled(Typography)`
    padding: 12px 0px 16px 24px;
    font-family: Roboto !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    line-height: 16px !important;
    text-align: left;
    color: ${({theme})=> theme.material.colors.text.secondary};
`;

const StyledIcon = styled(Icon)`
    padding-right: 1rem;
    vertical-align: middle;
    height: 40px;
    cursor: grab;
`;

const DraggableElementStyled = styled(DraggableElement)`
    cursor: pointer !important;
    width: 100%;
    div {
        display: flex;
        align-items: center;
    }
`;

const styles = (theme) => ({
    applyButton: {
        marginRight: '8px',
        marginTop: '6px',
        color: ' #7391D0 !important',
    },
    menuIcon: {
        marginRight: '1rem',
        color: 'rgba(255, 255, 255, 0.6)',
    },
    targetRow: {
        textAlign: 'start !important',
    },
});

const addColComponents = [
    { type: 'text', properties: { name: 'text', label: 'Column name' }, constraints: { required: true } },
    { type: 'text', properties: { name: 'field', label: 'Attribute path' }, constraints: { required: true } },
];

class EntitiesListColumns extends PureComponent<Object, Object> {
    newColumnRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            search: '',
            isAddColumnOpen: false,
            checkedColumns: this.props?.viewState.columns,
            sidebarWidth: 400,
        };
        this.setActions();
    }

    componentDidUpdate(prevProps, prevState) {
        const { viewState } = this.props;
        if (viewState?.columns && !arrayObjectEquals(viewState?.columns, prevProps.viewState?.columns)) {
            this.setState({ checkedColumns: viewState?.columns });
        }
        this.setActions();
    }

    @bind
    setActions() {
        const { classes } = this.props;
        this.props.setActions(
            <EntitiesColumnAction
                onApply={this.handleOnApply}
                onSave={this.onSave}
                onReset={this.onReset}
                onLoadSaved={this.onLoadSaved}
                classes={classes}
            />
        );
    }

    @bind
    handleResize(width) {
        this.setState((state) => ({
            sidebarWidth: width,
        }));
    }

    @bind
    toggleNewColumnDialog() {
        this.setState((state) => ({ isAddColumnOpen: !state.isAddColumnOpen }));
    }

    @bind
    handleSearch(evnt) {
        this.setState({ search: evnt.target.value });
    }

    @bind
    addNewColumn() {
        const { saveComponentState, viewState } = this.props;
        this.newColumnRef.current.isValidForm().then(({ data, errors }) => {
            if (!errors) {
                const { columns: stateColumns } = viewState || {};
                saveComponentState(this.props.id, {
                    ...viewState,
                    changed: true,
                    columns: [
                        ...(stateColumns || []),
                        {
                            text: data.text,
                            field: `data.${data.field}`,
                            order: (stateColumns || []).length,
                            sortable: false,
                        },
                    ],
                });
                this.toggleNewColumnDialog();
            }
        });
    }

    @bind
    handleToggle(value) {
        return () => {
            const { checkedColumns: columns } = this.state;
            const checkedColumns = columns.map(clmn => clmn.order === value.order ? { ...clmn, hidden: !clmn.hidden } : clmn);
            this.setState({ checkedColumns });
        };
    }

    @bind
    saveColumns(columns) {
        const { viewState, id } = this.props;
        this.props.saveComponentState(id, { ...viewState, gridKey: viewState.gridKey + 1, columns });
    }

    @bind
    onReset() {
        const { showToastr, columnDefinitions, customColumns } = this.props;
        const cols = removeDuplicates([ ...columnDefinitions, ...(customColumns || [])], 'field');
        const checkedColumns = cols.map((clmn, index) => ({ ...clmn, order: index }));
        this.setState({ checkedColumns });
        this.saveColumns(checkedColumns);
        showToastr({ severity: 'success', detail: 'Columns reset to default.' });
    }

    @bind
    handleOnApply() {
        const { checkedColumns } = this.state;
        this.saveColumns(checkedColumns);
        this.props.showToastr({ severity: 'success', detail: 'Columns applied successfully.' });
    }

    @bind
    onLoadSaved() {
        const { showToastr, filterPreferences, backgroundfilterPreferences, id } = this.props;
        switch (id){
            case 'BackgroundJobList':
            case 'AdminBackgroundJobList':
                this.setState({ checkedColumns: backgroundfilterPreferences.columns }, () => {
                    this.saveColumns(backgroundfilterPreferences.columns);
                    showToastr({ severity: 'success', detail: 'Saved columns applied successfully.' });
                });
                break;
            default: 
                this.setState({ checkedColumns: filterPreferences.columns }, () => {
                    this.saveColumns(filterPreferences.columns);
                    showToastr({ severity: 'success', detail: 'Saved columns applied successfully.' });
                });
        }
       
    }

    @bind
    onDrop(item, index) {
        const listItem = { ...item };
        listItem.order = index;
        this.move(item.order, listItem, index);
    }

    @bind
    move(prevOrder, elementDragged, index) {
        const { checkedColumns } = this.state;
        const draggableColumns = deepCloneArray(checkedColumns);
        draggableColumns.splice(prevOrder, 1); // remove the element dragged
        if (prevOrder < index) {
            // maintain the order if the element is dragged from top towards bottom
            for (index = prevOrder; index < elementDragged.order; index++) {
                draggableColumns[index].order = draggableColumns[index].order - 1;
            }
        } else {
            // maintain the order if the element is dragged from bottom towards top
            for (let i = prevOrder - 1; i >= elementDragged.order; i--) {
                draggableColumns[i].order = draggableColumns[i].order + 1;
            }
        }
        draggableColumns.splice(index, 0, elementDragged); // add the element dragged
        this.setState({ checkedColumns: draggableColumns });
    }


    @bind
    async onSave() {
        const { saveComponentState, viewState, id } = this.props;
        const { orderBy } = viewState;
        const { checkedColumns } = this.state;
        saveComponentState(id, { ...viewState, columns: checkedColumns });
        switch (id){
            case 'BackgroundJobList':
            case 'AdminBackgroundJobList':
                await this.props.saveColumnPreferences({[this.props.id]: {
                    ...viewState,
                    orderBy: [orderBy],
                    columns: checkedColumns,
                }}, 'Columns saved successfully.');
                break;
            default: 
                await this.props.saveFiltersPreferences(this.props.id, {
                    ...viewState,
                    orderBy: [orderBy],
                    columns: checkedColumns,
                }, 'Columns saved successfully.');
        }
            
    }

    @bind
    buildColumns(stateColumns, search, isOnLoadSave) {
        let { checkedColumns } = this.state;
        const { classes } = this.props;
        if (search) {
            checkedColumns = checkedColumns?.filter((clmn) => clmn?.text && clmn.text?.toLowerCase()?.includes(search.toLowerCase()));
        }
        return checkedColumns?.map((def, i) => (
            <ListItem key={def.order} id={def.order} dense button onClick={this.handleToggle(def)}>
                <DraggableElementStyled key={i} index={i} element={def}>
                    <RowTarget className={classes.targetRow} index={i} onDrop={this.onDrop} element={def}>
                        <div>
                            <StyledIcon name='drag-vertical' />
                            <ListItemTextStyled id={def.field} primary={def.text || def.field } />
                        </div>
                        <ListItemSecondaryActionStyled>
                            <Checkbox onClick={this.handleToggle(def)} edge='start' value={!def.hidden} tabIndex={-1} />
                        </ListItemSecondaryActionStyled>
                    </RowTarget>
                </DraggableElementStyled>
            </ListItem>
        ));
    }

    render() {
        const { viewState, isOnLoadSave } = this.props;
        const { isAddColumnOpen, search } = this.state;
        const { columns: stateColumns } = viewState || {};
        return (
            <>
                <Divider />
                <GridStyled container wrap='nowrap'>
                    <Tooltip title='Open Search Bar'>
                        <span>
                            <SearchIcon name='magnify' color={muiTheme.colors.appHeader.iconColor} />
                        </span>
                    </Tooltip>
                    <TextFieldStyled
                        onChange={this.handleSearch}
                        value={search}
                        variant='standard'
                        margin='none'
                        inputRef={this.searchInputRef}
                        InputProps={{ disableUnderline: true }}
                        placeholder={'Search...'}
                    />
                </GridStyled>
                <Divider />
                <List>
                    <StyledTypography> Drag to re-order </StyledTypography>
                    {this.buildColumns(stateColumns, search, isOnLoadSave)}
                    <Divider />
                    <ListItem id='add-column' dense button onClick={this.toggleNewColumnDialog}>
                        <ListItemIcon>
                            <MdiIcon name='pencil' />
                        </ListItemIcon>
                        <ListItemText primary='Add column...' />
                    </ListItem>
                </List>
                {isAddColumnOpen && (
                    <ModalDialog
                        title='Add column'
                        onClose={this.toggleNewColumnDialog}
                        actions={
                            <Button variant='text' onClick={this.addNewColumn}>
                                {' '}
                                Add{' '}
                            </Button>
                        }
                    >
                        <FormGenerator components={addColComponents} ref={this.newColumnRef} />
                    </ModalDialog>
                )}
            </>
        );
    }
}

export default connect(
    (state, props) => ({
        viewState: get(state, `component.state.${props.id}`),
        filterPreferences: get(state, `user.preferences.filters.${props.id}`),
        backgroundfilterPreferences:  get(state, `user.preferences.${props.id}`),
    }),
    { saveComponentState, showToastr, setActions, setSubTitle, closeSidebar, setTitle, saveFiltersPreferences, saveColumnPreferences }
)(withStyles(styles)(EntitiesListColumns));
