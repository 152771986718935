// @flow

export default `
    id
    type
    relatedType
    description
    relatedDescription
    classes {
        id
        name
        uri
    }
`;
