/* @flow */

import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import HeaderBar from 'app/components/molecules/HeaderBar/HeaderBar';
import PageTemplate from 'app/components/templates/PageTemplate';
import Loader from 'app/components/atoms/Loader/Loader';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import Breadcrumbs from 'app/components/organisms/Breadcrumbs/Breadcrumbs';
import Help from 'app/utils/designer/form/settings/common/Help';
import { get, set } from 'app/utils/lo/lo';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { loadSecurityGeneral, updateSecurityGeneral, ssoUpdateIdentityProvider, setDocumentTitle } from 'store/actions/app/appActions';
import { Button, CircularProgress } from '@mic3/platform-ui';
import { showToastr } from 'store/actions/app/appActions';
import { providerDefinitions } from 'app/containers/Admin/Settings/IdentityProviders/AddIdentityProvider';
import { getPermissions } from 'app/config/rolesConfig';

const ButtonStyled = styled(Button)`
    margin-right: 8px !important;
`;

const providerDefinitionsDetails = (providerId) => {
    switch (providerId) {
        case 'oidc':
            return [
                {
                    type: 'panel',
                    properties: { 
                        header: 'General Settings', 
                        expanded: true,
                    },
                    children: [
                        {
                            type: 'boolean',
                            properties: { label: 'Enable provider', name: 'enabled' },
                        },      
                        ...providerDefinitions(true)[providerId][0]
                    ],
                },
                {
                    type: 'panel',
                    properties: { 
                        header: 'OpenID Connect Settings', 
                        expanded: true,
                    },
                    children: [
                        ...providerDefinitions(true)[providerId][2],
                        { type: 'divider' },                      
                        {
                            type: 'header',
                            properties: { text: 'Advanced', variant: 'h6' },
                        },
                        {
                            type: 'boolean',
                            properties: { 
                                label: 'Pass login_hint', name: 'config.loginHint' ,
                                help: <Help message="Pass login_hint to identity provider." />,
                            },
                        },
                        {
                            type: 'boolean',
                            properties: { 
                                label: 'Pass max_age', name: 'config.passMaxAge' ,
                                help: <Help message="Pass max_age to identity provider." />,
                            },
                        },
                        {
                            type: 'boolean',
                            properties: { 
                                label: 'Pass current locale', name: 'config.uiLocales' ,
                                help: <Help message="Pass the current locale to the identity provider as a ui_locales parameter." />,
                            },
                        },
                        {
                            type: 'boolean',
                            properties: { 
                                label: 'Backchannel logout', name: 'config.backchannelSupported' ,
                                help: <Help message="Does the external IDP support backchannel logout?" />,
                            },
                        },
                        {
                            type: 'boolean',
                            properties: { 
                                label: 'Disable user info', name: 'config.disableUserInfo' ,
                                help: <Help message="Disable usage of User Info service to obtain additional user information?  Default is to use this OIDC service." />,
                            },
                        },
                        {
                            type: 'boolean',
                            properties: { 
                                label: 'Disable nonce', name: 'config.disableNonce' ,
                                help: <Help message="Do not send the nonce parameter in the authentication request. The nonce parameter is sent and verified by default." />,
                            },
                        },
                        {
                            type: 'text',
                            properties: { 
                                label: 'Scopes', name: 'config.defaultScope', defaultValue: '' ,
                                help: <Help message="The scopes to be sent when asking for authorization. It can be a space-separated list of scopes. Defaults to 'openid'." />,
                            },
                        },
                        {
                            type: 'typeahead',
                            properties: { 
                                label: 'Prompt', 
                                name: 'config.prompt',
                                options: [
                                    { value: '', label: 'Unspecified' },
                                    { value: 'none', label: 'None' },
                                    { value: 'consent', label: 'Consent' },
                                    { value: 'login', label: 'Login' },
                                    { value: 'select_account', label: 'Select account' },
                                ],
                                valueField: 'value',
                                clearable: false,   
                                help: <Help message="Specifies whether the Authorization Server prompts the End-User for re-authentication and consent." />,
                                defaultValue: ''                                             
                            },
                        },
                        {
                            type: 'boolean',
                            properties: { 
                                label: 'Accepts prompt=none forward from client', name: 'config.acceptsPromptNoneForwardFromClient' ,
                                help: <Help message="This is just used together with Identity Provider Authenticator or when kc_idp_hint points to this identity provider. In case that client sends a request with prompt=none and user is not yet authenticated, the error will not be directly returned to client, but the request with prompt=none will be forwarded to this identity provider." />,
                            },
                        },  
                        {
                            type: 'number',
                            properties: { 
                                label: 'Allowed clock skew', name: 'config.allowedClockSkew' ,
                                help: <Help message="Clock skew in seconds that is tolerated when validating identity provider tokens. Default value is zero." />,
                            },
                            constraints: { onlyInteger: true, min: 0 }
                        },                                      
                        {
                            type: 'text',
                            properties: { 
                                label: 'Forwarded query parameters', name: 'config.forwardParameters', defaultValue: '' ,
                                help: <Help message="Non OpenID Connect/OAuth standard query parameters to be forwarded to external IDP from the initial application request to Authorization Endpoint. Multiple parameters can be entered, separated by comma (,)." />,
                            },
                        },                                      
                    ],
                },
                {
                    type: 'panel',
                    properties: { 
                        header: 'Advanced Settings', 
                        expanded: true,
                    },
                    children: [
                        {
                            type: 'boolean',
                            properties: { 
                                label: 'Store tokens', name: 'storeToken' ,
                                help: <Help message="Enable/disable if tokens must be stored after authenticating users." />,
                            },
                        },

                        
                        {
                            type: 'boolean',
                            properties: { 
                                label: 'Stored tokens readable', name: 'addReadTokenRoleOnCreate' ,
                                help: <Help message="Enable/disable if new users can read any stored tokens. This assigns the broker.read-token role." />,
                            },
                        },                                        
                        {
                            type: 'boolean',
                            properties: { 
                                label: 'Access Token is JWT', name: 'config.isAccessTokenJWT' ,
                                help: <Help message="The Access Token received from the Identity Provider is a JWT and its claims will be accessible for mappers." />,
                            },
                        },
                        {
                            type: 'boolean',
                            properties: { 
                                label: 'Hide on login page', name: 'config.hideOnLoginPage' ,
                                help: <Help message="If hidden, login with this provider is possible only if requested explicitly, for example using the 'kc_idp_hint' parameter." />,
                            },
                        },

                        {
                            type: 'boolean',
                            properties: { 
                                label: 'Trust Email', name: 'trustEmail' ,
                                help: <Help message="If enabled, email provided by this provider is not verified even if verification is enabled for the realm." />,
                            },
                        },
                        {
                            type: 'boolean',
                            properties: { 
                                label: 'Account linking only', name: 'linkOnly' ,
                                help: <Help message="If true, users cannot log in through this provider.  They can only link to this provider.  This is useful if you don't want to allow login from the provider, but want to integrate with a provider" />,
                            },
                        },
                        {
                            type: 'boolean',
                            properties: { 
                                label: 'Verify essential claim', name: 'config.filteredByClaim' ,
                                help: <Help message="If true, ID tokens issued by the identity provider must have a specific claim. Otherwise, the user can not authenticate through this broker." />,
                            },
                        },                                        
                        {
                            type: 'text',
                            properties: { 
                                label: 'Essential claim', 
                                name: 'config.claimFilterName',
                                isVisible: (data) => data?.config?.filteredByClaim,
                                help: <Help message="Name of the essential claim" />,
                            },
                            constraints: { required: true }
                        },
                        {
                            type: 'text',
                            properties: { 
                                label: 'Essential claim value', 
                                name: 'config.claimFilterValue',
                                isVisible: (data) => data?.config?.filteredByClaim,
                                help: <Help message="Value of the essential claim (with regex support)" />,
                            },
                            constraints: { required: true }
                        },
                        {
                            type: 'typeahead',
                            properties: { 
                                label: 'First login flow override', 
                                name: 'firstBrokerLoginFlowAlias',
                                options: [
                                    { value: '', label: 'empty' },
                                    { value: 'browser', label: 'browser' },
                                    { value: 'direct_grant', label: 'direct grant' },
                                    { value: 'registration', label: 'registration' },
                                    { value: 'reset_credentials', label: 'reset credentials' },
                                    { value: 'first_broker_login', label: 'first broker login' },
                                    { value: 'Login_IDP_If_user_exists', label: 'Login IDP If user exists' },
                                ],
                                valueField: 'value',
                                clearable: false,                                                
                                // help: <Help message="firstBrokerLoginFlowAliasOverrideHelp" />,
                            },
                        },
                        {
                            type: 'typeahead',
                            properties: { 
                                label: 'Post login flow', 
                                name: 'postBrokerLoginFlowAlias',
                                options: [
                                    { value: '', label: 'empty' },
                                    { value: 'browser', label: 'browser' },
                                    { value: 'direct_grant', label: 'direct grant' },
                                    { value: 'registration', label: 'registration' },
                                    { value: 'reset_credentials', label: 'reset credentials' },
                                    { value: 'first_broker_login', label: 'first broker login' },
                                    { value: 'Login_IDP_If_user_exists', label: 'Login IDP If user exists' },
                                ],
                                valueField: 'value',
                                clearable: false,                                                
                                help: <Help message={`Alias of authentication flow, which is triggered after each login with this identity provider. Useful if you want additional verification of each user authenticated with this identity provider (for example OTP). Leave this to "None" if you need no any additional authenticators to be triggered after login with this identity provider. Also note that authenticator implementations must assume that user is already set in ClientSession as identity provider already set it.`} />,
                            },
                        },
                        {
                            type: 'typeahead',
                            properties: { 
                                label: 'Sync mode', 
                                name: 'config.syncMode',
                                options: [
                                    { value: 'IMPORT', label: 'Import' },
                                    { value: 'LEGACY', label: 'Legacy' },
                                    { value: 'FORCE', label: 'Force' },
                                ],
                                valueField: 'value',
                                clearable: false,                                                
                                help: <Help message="Default sync mode for all mappers. The sync mode determines when user data will be synced using the mappers. Possible values are: 'legacy' to keep the behaviour before this option was introduced, 'import' to only import the user once during first login of the user with this identity provider, 'force' to always update the user during every login with this identity provider." />,
                            },
                        },

                    ],
                }
            ];
            break;
        case 'facebook':
        case 'linkedin-openid-connect':
        case 'microsoft':
        case 'twitter':
        case 'google':
            return [
                {
                    type: 'panel',
                    properties: { 
                        header: 'General Settings', 
                        expanded: true,
                    },
                    children: [
                        {
                            type: 'boolean',
                            properties: { label: 'Enable provider', name: 'enabled' },
                        },      
                        {
                            type: 'text',
                            properties: { 
                                label: 'Redirect URI', 
                                name: 'generatedRedirectUri', 
                                disabled: true,
                                help: <Help message="The redirect uri to use when configuring the identity provider." />,
                            },
                            constraints: { required: true }
                        },
                        {
                            type: 'text',
                            properties: { 
                                label: 'Client ID', name: 'config.clientId',
                                help: <Help message="The client identifier registered with the identity provider." />,
                            },
                            constraints: { required: true }
                        },                      
                        {
                            type: 'text',
                            properties: { 
                                label: 'Client secret', name: 'config.clientSecret',
                                help: <Help message="The client secret registered with the identity provider. This field is able to obtain its value from vault, use ${vault.ID} format." />,
                            },
                            constraints: { required: true }
                        },                         
                        {
                            type: 'number',
                            properties: { 
                                label: 'Display order', name: 'config.guiOrder'  ,
                                help: <Help message="Number defining the order of the providers in GUI (for example, on the Login page). The lowest number will be applied first." />,
                            },
                            constraints: { required: true, onlyInteger: true, min: 0 }
                        },
                    ],
                },
                {
                    type: 'panel',
                    properties: { 
                        header: 'Advanced Settings', 
                        expanded: true,
                    },
                    children: [
                        {
                            type: 'boolean',
                            properties: { 
                                label: 'Store tokens', name: 'storeToken'  ,
                                help: <Help message="Enable/disable if tokens must be stored after authenticating users." />,
                            },
                        },
                        {
                            type: 'boolean',
                            properties: { 
                                label: 'Trust Email', name: 'trustEmail'  ,
                                help: <Help message="If enabled, email provided by this provider is not verified even if verification is enabled for the realm." />,
                            },
                        },
                        {
                            type: 'boolean',
                            properties: { 
                                label: 'Account linking only', name: 'linkOnly'  ,
                                help: <Help message="If true, users cannot log in through this provider.  They can only link to this provider.  This is useful if you don't want to allow login from the provider, but want to integrate with a provider" />,
                            },
                        },
                        {
                            type: 'boolean',
                            properties: { 
                                label: 'Stored tokens readable', name: 'addReadTokenRoleOnCreate' ,
                                help: <Help message="Enable/disable if new users can read any stored tokens. This assigns the broker.read-token role." />,
                            },
                        },                           
                    ],
                }
            ];
        case 'saml':
            return [
                {
                    type: 'panel',
                    properties: { 
                        header: 'General Settings', 
                        expanded: true,
                    },
                    children: [
                        {
                            type: 'boolean',
                            properties: { label: 'Enable provider', name: 'enabled' },
                        },      
                        {
                            type: 'text',
                            properties: { 
                                label: 'Redirect URI', 
                                name: 'generatedRedirectUri', 
                                disabled: true ,
                                help: <Help message="The redirect uri to use when configuring the identity provider." />,
                            },
                            constraints: { required: true }
                        },
                        {
                            type: 'text',
                            properties: { 
                                label: 'Display name', name: 'displayName',
                                help: <Help message="Friendly name for Identity Providers." />,
                            },
                        },
                    ],
                },
                {
                    type: 'panel',
                    properties: { 
                        header: 'SAML Settings', 
                        expanded: true,
                    },
                    children: [
                        {
                            type: 'boolean',
                            properties: { 
                                label: 'Pass login_hint', name: 'config.loginHint',
                                help: <Help message="Pass login_hint to identity provider." />,
                            },
                        }, 
                        {
                            type: 'boolean',
                            properties: { 
                                label: 'Backchannel logout', name: 'config.backchannelSupported',
                                help: <Help message="Does the external IDP support backchannel logout?" />,
                            },
                        },
                        {
                            type: 'boolean',
                            properties: { 
                                label: 'Validate signatures', name: 'config.validateSignature',
                                help: <Help message="Enable/disable signature validation of external IDP signatures." />,
                            },
                        },                                                
                        {
                            type: 'number',
                            properties: { 
                                label: 'Allowed clock skew', name: 'config.allowedClockSkew',
                                help: <Help message="Clock skew in seconds that is tolerated when validating identity provider tokens. Default value is zero." />,
                            },
                            constraints: { onlyInteger: true, min: 0 }
                        },                                                                           
                    ],
                },                
                {
                    type: 'panel',
                    properties: { 
                        header: 'Advanced Settings', 
                        expanded: true,
                    },
                    children: [                          
                        {
                            type: 'boolean',
                            properties: { 
                                label: 'Store tokens', name: 'storeToken',
                                help: <Help message="Enable/disable if tokens must be stored after authenticating users." />,
                            },
                        },

                        {
                            type: 'boolean',
                            properties: { 
                                label: 'Trust Email', name: 'trustEmail',
                                help: <Help message="If enabled, email provided by this provider is not verified even if verification is enabled for the realm." />,
                            },
                        },
                        {
                            type: 'boolean',
                            properties: { 
                                label: 'Account linking only', name: 'linkOnly',
                                help: <Help message="If true, users cannot log in through this provider.  They can only link to this provider.  This is useful if you don't want to allow login from the provider, but want to integrate with a provider" />,
                            },
                        },
                        {
                            type: 'boolean',
                            properties: { 
                                label: 'Stored tokens readable', name: 'addReadTokenRoleOnCreate',
                                help: <Help message="Enable/disable if new users can read any stored tokens. This assigns the broker.read-token role." />,
                            },
                        },      
                        {
                            type: 'typeahead',
                            properties: { 
                                label: 'First login flow override', 
                                name: 'firstBrokerLoginFlowAlias',
                                options: [
                                    { value: '', label: 'empty' },
                                    { value: 'browser', label: 'browser' },
                                    { value: 'direct_grant', label: 'direct grant' },
                                    { value: 'registration', label: 'registration' },
                                    { value: 'reset_credentials', label: 'reset credentials' },
                                    { value: 'first_broker_login', label: 'first broker login' },
                                    { value: 'Login_IDP_If_user_exists', label: 'Login IDP If user exists' },
                                ],
                                valueField: 'value',
                                clearable: false,                                                
                            },
                        },                                             
                    ],
                }
            ];
        default:
            return [];
            break;
    }
};

class IdentityProviderDetails extends PureComponent<Object, Object> {

    formRef: Object = React.createRef();

    constructor(props: Object) {
        super(props);
        props.loadSecurityGeneral();
        this.state = { 
            formRevertKey: 0, 
            formTouched: false,
            providerData: this.getProvider() 
        };

    }

    componentDidUpdate(prevProps, prevState) {
        const { ssoSettings, setDocumentTitle } = this.props;
        const { displayName } = this.state?.providerData || {};
        if(displayName){
            setDocumentTitle(`Identity Provider - ${displayName}`);
        }
        if(ssoSettings !== prevProps.ssoSettings) {
            this.setState({ 
                formTouched: false, 
                providerData: this.getProvider()  
            });
        }
    }

    @bind
    onFormSubmit() {
        const { loadSecurityGeneral, ssoUpdateIdentityProvider } = this.props;
        this.formRef.current.isValidForm().then(({ data, errors }) => {
            if (!errors) {
                const { generatedRedirectUri, providerId, config: configData, internalId, updateProfileFirstLoginMode, ...provider } = data;
                const { sendClientIdOnLogout, sendIdTokenOnLogout, ...config } = configData;

                let providerData = {
                    ...provider,
                    config,
                };

                if(providerId !== 'oidc') {
                    providerData = {
                        alias: data.alias,
                        enabled: data.enabled,
                        storeToken: data.storeToken,
                        trustEmail: data.trustEmail,
                        linkOnly: data.linkOnly,
                        addReadTokenRoleOnCreate: data.addReadTokenRoleOnCreate,
                        config: data.config,
                    };
                }

                if(providerId === 'saml') {
                    providerData = {
                        alias: data.alias,
                        enabled: data.enabled,
                        displayName: data.displayName,
                        storeToken: data.storeToken,
                        trustEmail: data.trustEmail,
                        linkOnly: provider.linkOnly,
                        addReadTokenRoleOnCreate: data.addReadTokenRoleOnCreate,
                        firstBrokerLoginFlowAlias: data.firstBrokerLoginFlowAlias,
                        config: data.config,
                    };
                }

                ssoUpdateIdentityProvider(providerData)
                    .then(async (result) => {
                        if(!(result instanceof Error)) {
                            loadSecurityGeneral();
                        }
                    });
            }
        });
    }

    @bind
    @memoize()
    renderPasswordButtons(isLoading, formTouched, canEdit) {
        if(!canEdit) return null;
        return isLoading ? (
            <CircularProgress key={113} size={24} color="primary" />
        ) : (
            <>
                <ButtonStyled key={113} onClick={this.onFormSubmit} color="primary" form="form" type="submit">
                    Save
                </ButtonStyled>
                <Button disabled={!formTouched} variant="outlined" key={114} onClick={this.revertChanges} color="primary">
                    Revert
                </Button>
            </>
        );
    }

    @bind
    getProvider() {
        const { match: { params }, ssoSettings } = this.props;
        if(ssoSettings?.identityProviders) {
            const provider = ssoSettings?.identityProviders?.find(({ alias }) => alias === params.alias) || {};

            const providerConfig = Object.keys(provider.config || {}).reduce((accum, key) => {
                if(['true', 'false'].includes(provider.config[key])) {
                    accum[key] = provider.config[key] === 'true';
                } else if(['guiOrder', 'allowedClockSkew'].includes(key)) {
                    accum[key] = Number(provider.config[key]);
                } else {
                    accum[key] = provider.config[key];
                }
                return accum;
            }, {});

            return {
                generatedRedirectUri: `https://${window.location.host}/iam/auth/realms/Affectli/broker/${provider.alias}/endpoint`,
                ...provider,
                config: providerConfig,
            };
        }
        return null;
    }

    @bind
    revertChanges() {
        this.setState({ 
            formRevertKey: this.state.formRevertKey + 1, 
            formTouched: false,
            providerData: this.getProvider()
        });
    }

    @bind
    onChangeForm(data, { name, value }) {
        let updatedData = { ...data };
        if(name === 'alias') {
            updatedData = set(updatedData, 'generatedRedirectUri', `https://${window.location.host}/iam/auth/realms/Affectli/broker/${value || ''}/endpoint`);
        }
        this.setState({ providerData: updatedData, formTouched: true });
    }

    @bind
    @memoize()
    buildBreadcrumbs(provider) {
        return <Breadcrumbs list={[{ title: provider?.displayName || provider?.alias }]} withGoBack />;
    }

    render() {
        const { isLoading, ssoSettings } = this.props;
        const { formRevertKey, formTouched, providerData } = this.state;
        const { canEdit } = getPermissions(ssoSettings?.role);
        
        return (
            <PageTemplate title={'Brute Force Detection'} overflowHidden>
                <HeaderBar right={this.renderPasswordButtons(isLoading, formTouched, canEdit)} left={this.buildBreadcrumbs(providerData)} />
                {(isLoading || !providerData) && (
                    <Loader absolute backdrop />
                )}
                <ContentArea withHeader>
                    {providerData && (
                        <FormGenerator
                            key={formRevertKey}
                            ref={this.formRef}
                            data={providerData}
                            onChange={this.onChangeForm}
                            disabled={!canEdit}
                            components={providerDefinitionsDetails(providerData?.providerId)}
                        />
                    )}
                </ContentArea>
            </PageTemplate>
        );
    }
}

export default connect(
    state => ({
        isLoading: state.app.ssoSettingsLoading,
        ssoSettings: get(state.app, 'ssoSettings', {}),
    }),
    {
        showToastr,
        loadSecurityGeneral,
        updateSecurityGeneral,
        ssoUpdateIdentityProvider,
        setDocumentTitle,
    }
)(IdentityProviderDetails);
