/* @flow */

// $FlowFixMe
import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Grid, Typography }from '@mic3/platform-ui';

import FieldSettingsSidebar from 'app/containers/Designer/Form/Tabs/FormDesignerEditor/Components/FieldSettingsSidebar';
import FieldsModal from 'app/containers/Designer/Form/Tabs/FormDesignerEditor/Components/FieldsModal';
import IotDataModal from 'app/containers/Designer/Form/components/IotDataModal';
import Layout from 'app/components/Designer/Layout';
import FormDnd from 'app/containers/Designer/Form/Tabs/FormDesignerEditor/FormDnd/FormDnd';
import Forest, { uuidv4 } from 'app/utils/dataStructure/Forest';
import Icon from 'app/components/atoms/Icon/Icon';
import Loader from 'app/components/atoms/Loader/Loader';

import { get, omit } from 'app/utils/lo/lo';
import { debounce as debounceFunc } from 'app/utils/utils';
import { getFieldDefinition, isFieldProppertyOwned } from 'app/utils/designer/form/settings/formFieldSettingsUtils';
import { fillPropertiesByType } from 'app/utils/designer/form/fieldUtils';
import { setTabActions } from 'store/actions/app/appActions';
import { bind, memoize, debounce } from 'app/utils/decorators/decoratorUtils';
import { setSelectedElement } from 'store/actions/form/formActions';

const defaultSelectedElement = null;

const GridStyled = styled(Grid)`
    height: 100%;
`;

class FormDesignerEditor extends PureComponent<Object, Object> {

    static propTypes = {
        form: PropTypes.object,
        editorState: PropTypes.object,
        saveEditorState: PropTypes.func,
        errors: PropTypes.object,
    };

    state = {
        showAddElementModal: false,
        showIotDataModal: false,
        addElementState: {}
    };
    contentAreaRef: Object = React.createRef();

    constructor(props) {
        super(props);
        const { editorState, designerState } = props;
        // updating components with Forest requirements
        if(editorState?.fields?.length && !get(editorState, 'fields[0].uuid')) {
            const { drafted, isPreviewVersion } = designerState || {};
            this.props.saveEditorState({ fields: this.forest().nodes }, isPreviewVersion, drafted);
        }
    }

    componentDidUpdate(prevProps) {
        const { editorState, designerState } = this.props;
        // updating components with Forest requirements
        if(prevProps.editorState?.fields !== editorState?.fields && editorState?.fields?.length && !get(editorState, 'fields[0].uuid')) {
            const { drafted, isPreviewVersion } = designerState || {};
            this.props.saveEditorState({ fields: this.forest().nodes }, isPreviewVersion, drafted);
        }
    }


    @bind
    refreshPropertiesSidebar(){
        const { selectedElement } = this.props;
        this.onSelectElement({ ...selectedElement });
    }

    @bind
    onCloseSidebar(){
        const { isMobile }= this.props;
        if(!isMobile) {
            this.props.setSelectedElement(null);
        }
    }

    @bind
    move(element: Object, parentUuid: string, index: number) {
        let isDropToChild = false;
        const findDropParentToChild = (el) => {
            if(!isDropToChild && el.uuid === parentUuid) {
                isDropToChild = true;
            }
            if(el.children) {
                el.children.forEach(findDropParentToChild);
            }
        };
        if(element.children) {
            [element].forEach(findDropParentToChild);
        }

        if(!isDropToChild) {
            const fields = this.forest().move(element, parentUuid, index).nodes;
            this.props.saveEditorState({ fields }, false, true);
            const { selectedElement } = this.props;
            if(selectedElement) {
                this.onSelectElement(this.forest().get(selectedElement.uuid));
            }
        }
    }

    @bind
    add(elementObj: Object, parentUuid: string, index: number, isRoot: boolean) {
        const { type, form, editorState } = this.props;
        const element = { ...elementObj };
        element.uuid = uuidv4();
        if(type === 'class' && isFieldProppertyOwned(element.type, 'properties.name')) {
            this.props.saveEditorState({ fields: this.forest().add({
                ...element,
                properties: { name: `${form.uri}/` },
            }, parentUuid, index).nodes }, false, true);
        } else {
            let nextIndex = index;
            if(isRoot) {
                nextIndex = get(editorState, 'fields.length', 0);
            }
            const fields = this.forest().add(element, parentUuid, nextIndex).nodes;
            this.props.saveEditorState({ fields }, false, true);
        }
        this.onSelectElement(this.forest().get(element.uuid));
        
    }

    @bind
    duplicate(uuid: string) {
        this.props.saveEditorState({ fields: this.forest().duplicate(uuid).nodes }, false, true);
    }

    @bind
    async remove(element: Object) {
        const arrayElements = this.forest().remove(element).nodes;
        const removeElement = async () => this.props.saveEditorState({ fields: !arrayElements.length ? this.forest([getFieldDefinition('panel')]).nodes : arrayElements }, false, true);
        if (element?.uuid === get(this.props, 'selectedElement.uuid')) {
            await this.props.setSelectedElement(defaultSelectedElement);
            await removeElement();
        } else {
            await removeElement();
        }
    }

    @bind
    updateSelectedElementSettings(settingsValues: Object) {
        if (!this.props.selectedElement) {
            return;
        }
        let selectedElement = { ...this.props.selectedElement, ...settingsValues };
        const defaultValue = selectedElement?.properties?.defaultValue;
        if(settingsValues?.properties?.parseAs === 'HTML' && defaultValue) {
            selectedElement = {
                ...selectedElement,
                properties: {
                    ...selectedElement.properties,
                    defaultValue: defaultValue.innerHTML || defaultValue,
                    value: undefined,
                }
            };
        }
        this.props.setSelectedElement(selectedElement);
        const update = debounceFunc(() => this.props.saveEditorState({ fields: this.forest().update(selectedElement).nodes }, false, true), 300);
        update();
    }

    @bind
    @debounce()
    updateFormSettings(form: Object) {
        this.props.saveEditorState(form, false, true);
    }

    @bind
    @memoize()
    _forest(elements) {
        return new Forest(elements);
    }

    @bind
    forest(elements) { 
        const newForest = this._forest(elements || this.props.editorState?.fields);
        const { selectedElement } = this.props;
        let selectedElementForest = newForest.get(selectedElement?.uuid);
        if(!selectedElementForest && selectedElement?.properties?.name) {
            selectedElementForest = newForest.getBy('properties.name', selectedElement.properties.name);
            this.onSelectElement(selectedElementForest);
        }
        return newForest;
    };


    @bind
    onSelectElement(element: Object) {
        this.props.setSelectedElement(element);
    };


    @bind
    @memoize()
    getSettingsValues(selectedElement) {
        if (!selectedElement) {
            return null;
        }
        const properties = fillPropertiesByType(selectedElement.type, selectedElement.properties);

        let normalizedElement = { ...selectedElement,  properties };

        // For legacy classification definitions
        if(normalizedElement?.properties?.InputProps) {
            normalizedElement.properties = omit(normalizedElement.properties, ['InputProps']);
        }

        // Legacy code can be removed when we will stop use all legacy class attributes definitions
        if(selectedElement.type === 'typeahead' && selectedElement?.properties?.options && !selectedElement?.settings?.staticOptions) {
            normalizedElement = {...normalizedElement, settings: {
                staticOptions: selectedElement.properties.options.reduce((textOpts, op, indx) => {
                    const isLast = (selectedElement.properties.options.length + 1) === indx;
                    textOpts += `${op.value},${op.label}${isLast ? '' : '\n'}`;
                    return textOpts;
                }, '')
            }};
        }

        return normalizedElement;
    }

    @bind
    toggleAddElementModal(element: ?Object) {
        this.setState(state => ({ showAddElementModal: element || null }));
    }

    @bind
    toggleIotDataModal() {
        this.setState(state => ({ showIotDataModal: !(this.state.showIotDataModal) }));
    }

    @bind
    openAddElementModal(element: Object) {
        let el = element;
        if(!el) {
            this.toggleAddElementModal(el);
        }
        if(typeof element === 'string') {
            const size = this.forest().nodes.length;
            el = {
                ...(this.forest().nodes[size - 1] || {}),
                index: this.forest().nodes.length,
                parentUuid: element,
            };
        }
        this.setState({addElementState: el});
        this.toggleAddElementModal(el);
    }

    @bind
    @memoize()
    getPropertiesSidebarProps(form, fieldSettingsPanelComponents, fieldSettingsValues, selectedElement, isLoading) {
        const { isMobile } = this.props;

        if (fieldSettingsPanelComponents) {
            return {
                title: 'Properties',
                isOpen: isMobile ? undefined : !!selectedElement,
                afterCloseSidebar: this.onCloseSidebar,
                content: (
                    <>
                        {isLoading && <Loader absolute backdrop />}
                        <FieldSettingsSidebar
                            components={fieldSettingsPanelComponents}
                            data={fieldSettingsValues}
                            updateSettings={this.updateSelectedElementSettings}
                        />
                    </>
                )
            };
        }

        return {
            title: 'Properties',
            content: (
                <GridStyled justify="center" alignItems="center" container>
                    <Typography variant="h5">Select component</Typography>
                </GridStyled>
            )
        };
    }

    @bind
    @memoize()
    renderContent(
        afterRightButton, actions, secondaryActions, tabs, form, dropArea, 
        fieldSettingsPanelComponents, fieldSettingsValues, selectedElement, isLoading
    ) {
        return (
            <Layout
                tabs={tabs}
                TabsToolbarContent={secondaryActions}
                content={dropArea}
                height={'calc(100vh - 165px)'}
                RightSidebarProps={this.getPropertiesSidebarProps(form, fieldSettingsPanelComponents, fieldSettingsValues, selectedElement, isLoading)}
                ToolbarContent={actions}
                leftToolbarContent={this.props.breadcrumbLine}
                RightMenuIcon={<Icon name="classification-editor" type="af" />}
                afterRightButton={afterRightButton}
            />
        );
    };

    @bind
    @memoize()
    renderModal(draggableElements, showAddElementModal, isRoot, type) {
        let elements = draggableElements;
        elements = elements.sort((a, b) => a.type.localeCompare(b.type));
        const { editorState, isMobile } = this.props;
        if(!showAddElementModal) {
            return;
        }
        if(isRoot) {
            const iotPanelExists = editorState.fields.some(field => field.type ==='iotPanel');
            if(iotPanelExists){
                elements = elements.filter(el => el.type === ('panel'));
            }else {
                elements = elements.filter(el => el.type === ('panel') || el.type === ('iotPanel'));
            }
        }else {
            elements = elements.filter(el => el.type !== ('iotPanel'));
        }
        if(type === 'class') {
            elements = elements.filter(el => !['outcome', 'classification', 'icon'].includes(el.type));
        }
        const showModal = { ...showAddElementModal, isRoot };
        return (
            <FieldsModal
                elements={elements}
                toggleModal={this.toggleAddElementModal}
                showModal={showModal}
                onAdd={this.add}
                toggleIotModal={this.toggleIotDataModal}
                isMobile={isMobile}
            />
        );
    }

    @bind
    @memoize()
    renderIotModal(showIotDataModal, type, showAddElementModal) {
        return (
            <IotDataModal
                toggleModal={this.toggleIotDataModal}
                showFieldsModal={this.state.addElementState}
                showModal={showIotDataModal}
                onAdd={this.add}
            />
        );
    }

    @bind
    @memoize()
    getFieldSttingsFormSidebar(selectedElement, fieldSettingsValues) {
        const { normalizeFieldSettingsPanelComponents } = this.props;
        return selectedElement ? normalizeFieldSettingsPanelComponents(selectedElement.type, fieldSettingsValues) : null;
    }

    render() {
        const { showAddElementModal, showIotDataModal } = this.state;
        const { isLoading, afterRightButton, errors, form, tabs, secondaryActions, actions, type, selectedElement, isPreviewVersion, disabled } = this.props;
        const fieldSettingsValues = this.getSettingsValues(selectedElement);
        const fieldSettingsPanelComponents = this.getFieldSttingsFormSidebar(selectedElement, fieldSettingsValues);
        
        return (
            <FormDnd
                disabled={disabled || isPreviewVersion}
                elements={this.forest().nodes}
                uuid={this.forest().uuid}
                add={this.add}
                move={this.move}
                remove={this.remove}
                duplicate={this.duplicate}
                onSelectElement={this.onSelectElement}
                selectedElement={selectedElement}
                toggleElementsModal={this.openAddElementModal}
                errors={errors}
                form={form}
                type={type}
                renderContent={
                    ({ dropArea, draggableElements }) =>
                        (
                            <Fragment>
                                {this.renderContent(
                                    afterRightButton, actions, secondaryActions, tabs, form, dropArea, fieldSettingsPanelComponents,
                                    fieldSettingsValues, selectedElement, isLoading
                                )}
                                {this.renderModal(draggableElements, showAddElementModal, showAddElementModal?.parentUuid === this.forest().uuid, type)}
                                {this.renderIotModal(showIotDataModal, type, showAddElementModal)}
                            </Fragment>
                        )
                }
            />
        );
    }
}

export default connect(
    state => ({
        isMobile: state.global.isMobile,
        selectedElement: state.form.selectedElement,
    }),
    { setTabActions, setSelectedElement },
    null,
    { forwardRef: true }
)(FormDesignerEditor);
