/* @flow */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid } from '@mic3/platform-ui';

import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';

const StyledGrid = styled(Grid)`
    height: 100%;
    vertical-align: middle;
`;

const FieldSettingsSidebar = React.forwardRef(({ onValidate, updateSettings, components, data }, ref) => {
    return (
        <StyledGrid>
            <FormGenerator onValidate={onValidate} ref={ref} onChange={updateSettings} components={components} data={data} isValidateOnChange />
        </StyledGrid>
    );
});

FieldSettingsSidebar.propTypes = {
    settingsDefinition: PropTypes.arrayOf(PropTypes.object),
    settingsValues: PropTypes.object,
    updateSettings: PropTypes.func.isRequired,
};

export default FieldSettingsSidebar;
