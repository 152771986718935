// @flow

import React, { Fragment, useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import styled from 'styled-components';
import {
    List,
    Collapse,
    ClickAwayListener,
    Popper,
    Fade,
    Paper,
    MenuList,
    MenuItem,
    Typography,
    Divider,
    Box
} from '@mic3/platform-ui';

import history from 'store/History';
import { useAppNavStyles } from './AppNav.style';
import { get } from 'app/utils/lo/lo';

import AppNavItem from './AppNavItem/AppNavItem';
import buildHomeMenuList from '../menus/homeList';
import { debounce, isInViewport } from 'app/utils/utils';
import { muiTheme } from 'app/themes/materialUi';


type Props = {
    items: Array<Menu>,
    createItems: Array<{
        key: string,
        label: string
    }>,
    navOpen: boolean,
    activeMenuItem: ?string,
    createNewAnchorEl: any,
    resetCreateNewAnchorEl: Function
};

const GradientWrapper = styled.div`
    width: ${({ navOpen }) => navOpen ? '260px' : '56px'};
    height: 60px;
    position: fixed;
    bottom: ${({ navOpen }) => navOpen ? '50px' : '58px'};
    background: linear-gradient(to bottom, transparent, rgba(255, 255, 255, 0.08));
    transition: width 0.2s linear;
`;

const StyledMenuItem = styled(MenuItem)`
  &.active {
    background-color: ${() => muiTheme.colors.appNav.linkActiveBgColor};
  }
`;

const AppNavContent = ({
    items,
    createItems,
    navOpen,
    activeMenuItem,
    createNewAnchorEl,
    resetCreateNewAnchorEl,
    activeItem,
    setActiveMenuItem,
    closeMenu,
    ...otherProps
}: Props) => {
    const [isGradient, showGradient] = useState(false);
    const ref = useRef(null);
    const classes = useAppNavStyles();
    const [activeSubItem, setActiveSubItem] = useState(null);
    const [menuItemAnchorEl, setMenuItemAnchorEl] = useState(null);
    const location = useLocation();

    const userProfile = useSelector(state => state.user.profile);
    const userPreferences = useSelector(state => state.user.preferences);
    const favoriteUrl = get(userPreferences, 'common.favoriteUrl');
    const { isAdmin, permissions } = userProfile;

    const referencesArray = useMemo(() => items.map(element => React.createRef()), [items]);

    useEffect(() => {
        showGradient(false);
        const visibilityArray = [];
        referencesArray.forEach((ref) => {
            if (ref?.current) {
                const visible = isInViewport(ref?.current, { bottom: 50 });
                visibilityArray.push(visible);
            }
        });
        if (visibilityArray.length !== visibilityArray.filter(Boolean).length) {
            showGradient(true);
        }
    }, [location, referencesArray, showGradient]);

    useEffect(() => {
        setMenuItemAnchorEl(null);
    }, [navOpen]);


    const checkGradient = useMemo(() => debounce(() => {
        const lastMenuRef = referencesArray[referencesArray.length - 1];
        if (isInViewport(lastMenuRef?.current, { bottom: 50 })) { // if user scrolled to last element hide gradiant wrapper
            return showGradient(false);
        }
        return showGradient(true);
    }, 300), [referencesArray]);
    
    // The scroll listener
    const handleScroll = useCallback(() => {
        checkGradient();
    }, [checkGradient]);

    function showSubMenu(e: Event, item: Object) {
        setActiveSubItem(item);
        setMenuItemAnchorEl(e.currentTarget);
    }

    function hideSubMenu(item) {
        const { onClick } = item || {};
        onClick && onClick();
        setMenuItemAnchorEl(null);
    }

    function handleCreateClick(to: string) {
        hideCreateNewMenu();
        history.push(to);
    }

    function hideCreateNewMenu() {
        resetCreateNewAnchorEl();
    }

    function handleClose({ onClick }) {
        onClick && onClick();
        closeMenu();
    }

    function handleOnItemClick(item, activeItem) {
        const { onClick } = item || {};
        onClick && onClick();

        if (item.to === activeItem || item.name === activeItem) {
            setActiveMenuItem(null);
        } else {
            setActiveMenuItem(item.to || item.name);
        }

        if (!item.children) {
            closeMenu();
        }
    }

    return (
        <>
            { isGradient ? <GradientWrapper navOpen={navOpen} id="gradient-wrapper" /> : null}
            <List id="nav-menu-list" {...otherProps} onMouseLeave={() => hideSubMenu()} onScroll={handleScroll} ref={ref} style={{ height: '80vh'}} >
                {(buildHomeMenuList(isAdmin, permissions, favoriteUrl) || []).map((item, index) => (
                    <AppNavItem
                        key={index}
                        isButton
                        component={NavLink}
                        textProps={{
                            primaryTypographyProps: {
                                noWrap: true
                            }
                        }}
                        exact
                        activeClassName={item.name !== 'My Favorite' ? 'active' : ''}
                        data-tut={item.tourName}
                        primaryText={item.name}
                        iconPrimary={item.icon}
                        iconType={item.iconType}
                        to={item.to}
                        onClick={() =>handleOnItemClick(item, activeItem)}
                        onMouseEnter={e => !navOpen && showSubMenu(e, item)}
                    />
                ))}
                <Box my={2}>
                    <Divider />
                </Box>
                {(items || []).map((item, index) => {
                    const { children, to, name, icon, iconType, divider,  } = item || {};
                    let navLinkProps = item.to && {
                        isActive: (match, location) => {
                            const { pathname } = location || {};
                            if (pathname === '/broadcasts/calendar') {
                                if (get(match, 'url') === '/broadcasts') return false;
                            }
                            if ( item.name === 'Events Monitor' && pathname !== item.to) {
                                return false;
                            }
                            if ( item.name === 'Boards' && (pathname === item.to || pathname === '/abox/beta-kanbanboards')) {
                                return true;
                            }
                            if (match) return true;
                            if (item.relatedTo && pathname.includes(item.relatedTo)) return true;
                            return false;
                        }
                    };
                    if (children?.length) {
                        navLinkProps = {
                            isActive: (match, location) => {
                                const { pathname } = location || {};
                                if(item.baseRoute) {
                                    return pathname.includes(item.baseRoute);
                                }
                                return  children.some(ch => pathname?.includes(ch.to));
                            }
                        };
                    }
                    return (
                        <Fragment key={index}>
                            <AppNavItem
                                isButton
                                ref={referencesArray[index]}
                                component={to || children?.length ? NavLink : 'div'}
                                primaryText={name}
                                iconPrimary={icon} // Icon of menu item
                                iconType={iconType}
                                iconSecondary={children ? ([to, name].includes(activeItem)) ? 'chevron-up' : 'chevron-down' : null}
                                textProps={{
                                    primaryTypographyProps: {
                                        noWrap: true
                                    }
                                }}
                                to={to || !!children?.length}
                                onClick={() => handleOnItemClick(item, activeItem)}
                                onMouseEnter={e => !navOpen && showSubMenu(e, item)}
                                {...navLinkProps}
                            />
                            {divider &&
                                <Box my={2}>
                                    <Divider />
                                </Box>
                            }
                            <Collapse
                                in={[to, name].includes(activeItem) && navOpen}
                                timeout="auto"
                                unmountOnExit
                            >
                                <List disablePadding className={classes.nestedList}>
                                    {children &&
                                        children.map(
                                            (childItem, childIndex) => (
                                                <AppNavItem
                                                    isButton
                                                    component={childItem.to ? NavLink : 'span'}
                                                    key={childIndex}
                                                    textProps={{
                                                        inset: true,
                                                        primaryTypographyProps: {
                                                            noWrap: true
                                                        }
                                                    }}
                                                    primaryText={childItem.name || childItem.label}
                                                    iconPrimary={childItem.icon}
                                                    iconType={childItem.iconType}
                                                    to={childItem.to}
                                                    onClick={() => handleClose(childItem)}
                                                />
                                            )
                                        )}
                                </List>
                            </Collapse>
                        </Fragment>
                    );
                })}
                {!navOpen && activeSubItem && (
                    <ClickAwayListener onClickAway={() => hideSubMenu()}>
                        <Popper
                            className={classes.subMenuPopper}
                            placement="right-start"
                            anchorEl={menuItemAnchorEl}
                            open={Boolean(menuItemAnchorEl)}
                            modifiers={{ 
                                computeStyle: { gpuAcceleration: false },
                                preventOverflow: {
                                    enabled: true,
                                    boundariesElement: 'scrollParent',
                                },
                            }}
                            onMouseLeave={() => hideSubMenu()}
                            transition
                            id="navigation-popper"
                        >
                            {({ TransitionProps }) => (
                                <Fade {...TransitionProps} timeout={350}>
                                    <Paper className={classes.subMenu}>
                                        <MenuList>
                                            {activeSubItem.to || activeSubItem.onClick ? (
                                                <MenuItem
                                                    component={activeSubItem.to ? NavLink : 'span'}
                                                    to={activeSubItem.to}
                                                    onClick={() => hideSubMenu(activeSubItem)}
                                                >
                                                    <Typography variant="body1">
                                                        {activeSubItem.name}
                                                    </Typography>
                                                </MenuItem>
                                            ) : (
                                                <Typography variant="body1" className={classes.subMenuHeader}>
                                                    {activeSubItem.name}
                                                </Typography>
                                            )}
                                            {(activeSubItem.children || []).map(
                                                (childItem, childIndex) => (
                                                    <StyledMenuItem
                                                        className={classes.subMenuChild}
                                                        key={childIndex}
                                                        component={childItem.to ? NavLink : 'span'}
                                                        to={childItem.to}
                                                        onClick={() => handleClose(childItem)}
                                                        isActive={(match, location) => childItem.to === location.pathname}
                                                    >
                                                        {childItem.name}
                                                    </StyledMenuItem>
                                                )
                                            )}
                                        </MenuList>
                                    </Paper>
                                </Fade>
                            )}
                        </Popper>
                    </ClickAwayListener>
                )}
            </List>

            <Popper
                className={classes.createNewPopper}
                placement="right-start"
                anchorEl={createNewAnchorEl}
                open={Boolean(createNewAnchorEl)}
                modifiers={{ computeStyle: { gpuAcceleration: false } }}
                onMouseLeave={hideCreateNewMenu}
                transition
                id="navigation-popper"
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper
                            className={clsx(
                                classes.subMenu,
                                classes.subMenuDark
                            )}
                        >
                            <MenuList>
                                <MenuItem disabled>
                                    <Typography variant="body1">
                                        Create new
                                    </Typography>
                                </MenuItem>
                                {createItems.map((item, index) => (
                                    <MenuItem key={item.to} onClick={() => handleCreateClick(item.to)}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </MenuList>
                        </Paper>
                    </Fade>
                )}
            </Popper>
           
        </>
        
    );
};

export default AppNavContent;
