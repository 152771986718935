/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';

import FormDesignerAbstract from 'app/containers/Designer/Form/FormDesignerAbstract';
import ProcessDesigner from 'app/containers/Designer/Processes/ProcessDesigner';
import ScriptEditor from 'app/containers/Designer/Script/ScriptEditor';
import PipelineEditor from 'app/containers/Designer/Pipeline/PipelineEditor';
import ConnectoModuleDetail from 'app/containers/Designer/Pipeline/ConnectoModuleDetail';
import Forms from 'app/containers/Designer/Forms';
import Pipelines from 'app/containers/Designer/Pipelines';
import Processes from 'app/containers/Designer/Processes';
import Scripts from 'app/containers/Designer/Scripts';
import Connectors from 'app/containers/Designer/Connectors';
import Modules from 'app/containers/Designer/Modules';
import PipelineDesignerRoutes from 'app/containers/PipelineDesigner/PipelineDesignerRoutes';
/**
 * Defines the routes for the Designer views
 */
class DesignerRoute extends PureComponent<Object, Object> {

    static propTypes = {
        match: PropTypes.object.isRequired,
    };

    render() {
        const { match, location } = this.props;
        const isRelations = location.pathname.includes('/relationships');
        return (
            <Switch>
                <Route exact path={`${ match.url }/forms`} component={Forms} />
                <Route exact path={`${ match.url }/processes`} component={Processes} />
                <Route exact path={`${ match.url }/pipelines`} component={Pipelines} />
                <Route exact path={`${ match.url }/scripts`} component={Scripts} />
                <Route exact path={`${ match.url }/connectors`} component={Connectors} />
                <Route path={`${ match.url }/connectors/:id`} render={(props) => <ConnectoModuleDetail {...props} type="iot_connector"/>} />
                <Route exact path={`${ match.url }/smart-modules`} component={Modules} />
                <Route path={`${ match.url }/smart-modules/:id`} render={(props) => <ConnectoModuleDetail {...props} type="iot_module"/>} />
                {/* <Route path={`${ match.url }/form/add`} component={NewForm} /> */}
                <Route path={`${ match.url }/forms/:id`} render={props => <FormDesignerAbstract {...props} type="formDefinition" />} />
                <Route path={`${ match.url }/processes/:id`} component={ProcessDesigner} />
                {isRelations && <Route exact path={`${ match.url }/pipelines/:id/relationships`} render={(props) => <ConnectoModuleDetail {...props} type="iot_pipeline"/>} />}
                {!isRelations && <Route path={`${ match.url }/pipelines/:id`} component={PipelineEditor} />}
                <Route path={`${ match.url }/scripts/:id`} component={ScriptEditor} />
                <Route path={`${match.url}/mi-stream`} exact component={PipelineDesignerRoutes} />
/relationships
                {/* <Route path={`${ match.url }/processes`} component={Processes} /> */}
                {/* <Route path={`${ match.url }/process/add`} component={NewProcess} /> */}
                {/* <Route path={`${ match.url }/process/:id`} component={Process} /> */}
                {/* <Route path={`${ match.url }/apps`} component={Apps} /> */}
                {/* <Route path={`${ match.url }/apps/add`} component={NewApp} /> */}
                {/* <Route path={`${ match.url }/app/:id`} component={App} /> */}
            </Switch>
        );
    }
}

export default DesignerRoute;
