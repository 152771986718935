/* @flow */

import moment from 'moment';

import { isDefined, isEmptyArray, isObject, sortAscending, sortDescending } from 'app/utils/utils';
import { get } from 'app/utils/lo/lo';
import { isValiduuid, canBeuuid } from 'app/utils/string/string-utils';
import { isDate, convertTISOString } from 'app/utils/app/appUtils';
import { commonElementExists } from 'app/utils/array/array-utils';

/**
 * Filter the elements in the given list.
 *
 * @param list a list of items.
 * @param query the text to search.
 * @param options.type: the method to use to match the item.
 * @param options.property: the property of the object to match.
 * @param options.caseSensitive: indicates if the search must be case sensitive.
 */

const filterUuidFields = (filterDefinitions, fields, exclude = false) => (fields || []).filter((field) => {
    const { type } = filterDefinitions.find(def => def.field === field) || {};
    if (exclude) {
        return type !== 'uuid';
    }
    return type === 'uuid';
});

/**
 *  If searchBarValue matches with UUID criteria then this function will
 *  return only those fields whose type is 'uuid', otherwise it will
 *  return all the fields except the type 'uuid' ones
 *  If it returns the empty array then there are two possibilities
 *  User entered a valid UUID ( either complete or first 8 digit ) but there is no field with type "uuid" defined.
 *  User entered something else but there is no field defined other than the ones with type "uuid"
 */
export const filterSearchFields = (filterDefinitions, searchFields, searchBarValue) =>
    isValiduuid(searchBarValue) || canBeuuid(searchBarValue) ?
        filterUuidFields(filterDefinitions, searchFields) : filterUuidFields(filterDefinitions, searchFields, true);


const filter = (
    list: Array<any>,
    query: string,
    { type, property, caseSensitive }: Object
): Array<any> => {
    if (!list) {
        return [];
    }
    let q = (query || '').trim();
    if (!caseSensitive) {
        q = query.toLowerCase();
    }
    if (!q) {
        return [...list];
    }
    return list.filter((item) => {
        let text = String((item && typeof item === 'object' && property ? item[property] : item) || '').trim();
        if (!caseSensitive) {
            text = text.toLowerCase();
        }
        // $FlowFixMe
        return text[type](q);
    });
};


const startsWith = <T>(
    list: Array<T>,
    query: string,
    options: ?Object
): Array<T> => filter(list, query, { ...options, type: 'startsWith' });

const includes = <T>(
    list: Array<T>,
    query: string,
    options: ?Object
): Array<T> => filter(list, query, { ...options, type: 'includes' });

const buildQueries = (field, condition, value, type, filterValueField) => {
    // if we are using the autocomplete of an entity we need to filter by id
    if (new Set(['thingTypeahead', 'organisationTypeahead', 'personTypeahead', 'userTypeahead', 'broadcastTypeahead']).has(type) && isObject(value)) {
        value = value && typeof value === 'object' && value.id;
    }
    if (!isDefined(value) || (Array.isArray(value) && isEmptyArray(value))) {
        return null;
    }

    if (type === 'uuid') {
        if (isValiduuid(value)) {
            return { field, op: '=', value };
        }
        return { field, op: 'startsWith', value, cast: 'text' };
    }

    if (type === 'number' && Number.isNaN(Number(value))) {
        return null;
    }

    if (field === 'name' && value === 'No Name') {
        return { field, op: 'is null' };
    }

    if (value === 'is null' || value === 'is not null') {
        return { field, op: value };
    }

    if(typeof value === 'object' && condition === 'between' && value.relative) {
        const currentDate = moment().set({ second:0, millisecond:0 }).toDate();
        const calculatedDate = moment(currentDate)[value.range](value.amount, value.unit).toDate();
        const nextValue = [value.range === 'add' ? currentDate : calculatedDate, value.range === 'add' ? calculatedDate: currentDate];
        return { field, op: condition, value: nextValue };
    }

    return { field, op: condition, value };
};

const getConditionByType = (type) => {
    switch (type) {
        case 'number': case 'uuid':
            return '=';
        case 'dateTimeRange':
            return 'between';
        default:
            return 'contains';
    }
};

const formatByDefinitions = (filters: Object, filterDefinitions: Array<Object>, searchFields: Array<string>, searchBarValue: ?string, isExclude: boolean) => {
    const filterBy = [];
    const searchBar = filterSearchFields(filterDefinitions, searchFields, searchBarValue);
    filterDefinitions
        .filter(def => def.filter !== false)
        .forEach((definition) => {
            const { field, properties = {}, type, condition, filterValueField, joinGroup } = definition;
            const fields = Array.isArray(field) ? field : [field];
            const statements = fields
                .map((fieldName) => {
                    const value = get(filters, properties.name || fieldName);
                    if (!isDefined(value)) {
                        return null;
                    }
                    let filter = null;
                    if (isExclude) {
                        filter = value.exclude
                        && buildQueries(fieldName, condition || getConditionByType(type), value.exclude, type, filterValueField);
                    } else {
                        filter = !value.exclude
                         && buildQueries(fieldName, condition || getConditionByType(type), value, type, filterValueField);
                    }
                    if(joinGroup && filter) {
                        return { ...filter, joinGroup };
                    }
                    return filter;
                }).filter(filter => filter);
            filterBy.push(...statements);
        });
    if (!searchBarValue) {
        return filterBy;
    }
    const statements = searchBar.map((fieldName) => {
        const definition = includes(filterDefinitions, fieldName, { property: 'field' })[0];
        if (!definition) {
            console.error(`Search field ${fieldName} is not a part of filter difinitions`); // eslint-disable-line no-console
            return null;
        }
        const { condition, type, filterValueField } = definition;

        return buildQueries(fieldName, condition || getConditionByType(type), searchBarValue, type, filterValueField);
    }).filter(filter => filter);
    if (statements.length === 1) {
        filterBy.push(statements[0]);
    } else if (statements.length > 1) {
        filterBy.push({ or: statements });
    }

    return filterBy;
};

const _normalizeFieldKey = (field, dataKey) => {
    let fieldKey = field;
    if (field.endsWith('.id') && field !== 'device.id') {    // eg: "classes.id" "users.id"
        const splittedKey = field.split('.');
        if(splittedKey?.length === 2)
            fieldKey = splittedKey?.[0];
    }
    return dataKey ? `${dataKey}.${fieldKey}` : fieldKey;
};

const _normalizeValue = (field, value) => {
    if (Array.isArray(value) && field.endsWith('.id')){        // eg: "classes.id" "users.id"
        return value?.map(field => field?.id).filter(Boolean);
    }
    switch (field) {
        case 'primary.priority':
            return String(value); // As from database we receive priority in number field but in filters we have a string value
        default:
            return value;
    }
};

const _isRecordExists = (record, dataKey) => ({ field, op, value: filterValue, or, cast }) => {
    if (or?.length) {
        return or.some(_isRecordExists(record, dataKey));
    }
    if(cast === 'real')
        filterValue = Number(filterValue);
    const fieldKey = _normalizeFieldKey(field, dataKey);
    const val = get(record, fieldKey);
    const value = _normalizeValue(field, val);
    if (!isDefined(value)) {
        return op === 'is null';
    }
    switch (op) {
        case '=':
            return String(value) === String(filterValue);
        case '<>':
            return value !== filterValue;
        case 'startsWith':
            return value.startsWith(filterValue);
        case 'contains': case 'not contains': {
            let isExists = false;
            if (Array.isArray(value)) {
                isExists = value?.includes(filterValue);
            } else if (typeof(value) === 'string' && typeof(filterValue) === 'string') {
                isExists = value?.toLowerCase().includes(filterValue?.toLowerCase());
            }
            return op === 'contains' ? isExists : !isExists;
        }
        case 'in':
            if (Array.isArray(filterValue) && Array.isArray(value)) {
                return commonElementExists(filterValue, value);
            }
            if (Array.isArray(filterValue) && isObject(value)) {     // createdBy modifiedBy are recieved as object from database and
                return commonElementExists(filterValue, [value?.id]);//  and we use fields "createdBy.id" and "modifiedBy.id" with filter condition "in" So to comply it with "in" filter
            }
            return filterValue?.includes(value);
        case 'is null':
            return value === null;
        case 'is not null':
            return value !== null;
        case 'between':
            if (Array.isArray(filterValue) && filterValue?.length === 2) {
                let [ d1, d2 ] = filterValue;
                if (isDate(d1) && isDate(d2)) {
                    d1 = convertTISOString(d1);
                    d2 = convertTISOString(d2);
                }
                return value >= d1 && value <= d2;
            }
            return false;
        default:
            return false;
    }
};

const filterRecordsOnFE = (records, filterBy, excludeBy) => {
    if (!filterBy?.length && !excludeBy?.length) {
        return records;
    }
    const filteredRecords = records.filter((record) => {
        return filterBy.every(_isRecordExists(record, ''));
    });
    return excludeRecordsOnFE(filteredRecords, excludeBy);
};

const excludeRecordsOnFE = (records, excludeBy) => {
    if (!excludeBy?.length) {
        return records;
    }
    return records.filter((record) => {
        return !excludeBy.some(_isRecordExists(record, ''));
    });
};

const orderRecordsOnFE = (records, orderBy) => {
    if (orderBy && orderBy?.length) {
        const direction = get(orderBy, '[0].direction');
        const sortField = get(orderBy, '[0].field');
        switch (direction){
            case 'desc':
                return  records =  sortDescending(records, sortField);
            case 'asc':  
                return  records = sortAscending(records, sortField);
        }
    } else {
        return records;
    }
};


export { formatByDefinitions, includes, startsWith, buildQueries, filterRecordsOnFE, excludeRecordsOnFE, orderRecordsOnFE };
