/* @flow */

import { gql } from '@apollo/client';

export default gql`
query classification($filterBy: [JSON]) {
  records: classes(filterBy: $filterBy) {
    id
    uri
    name
  }
}
`;
