/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@mic3/platform-ui';

import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import { bind } from 'app/utils/decorators/decoratorUtils';
import history from 'store/History';
import { cloneFormDefinition } from 'store/actions/designer/designerFormActions';

class DuplicateForm extends PureComponent<Object, Object> {

    static propTypes = {
        onDuplicate: PropTypes.func.isRequired,
        isLoading: PropTypes.bool,
    };

    formRef = React.createRef();
    components = [
        {
            type: 'displayText',
            properties: { text: 'You need to give a name for the new form and you may want to add a description at the same time.'},
        },
        {
            type: 'text',
            properties: { label: 'New Name', name: 'name'},
            constraints: { required: true }
        },
        {
            type: 'textarea',
            properties: { label: 'New Description', name: 'description'},
        }
    ]

    @bind
    onFormSubmit(event: Event) {
        event.preventDefault();
        this.formRef.current.isValidForm().then(({ data, errors }) => {
            if (!errors) {
                this.props.cloneFormDefinition(data.id, data.name.trim(), data.description).then((result) => {
                    const { id } = result;
                    if (id) {
                        history.push(`/designer/forms/${id}`);
                    }
                });
            }
        });
    };

    render(): Object {
        const { isLoading, onClose, form } = this.props;
        return (
            <ModalDialog
                title="Duplicate Form"
                onClose={onClose}
                isLoading={isLoading}
                actions={
                    <Button  onClick={this.onFormSubmit}>
                      Duplicate
                    </Button>
                }
            >
                <FormGenerator
                    components={this.components}
                    ref={this.formRef}
                    data={form}
                />
            </ModalDialog>
        );
    }
}

export default connect(
    null,
    { cloneFormDefinition }
)(DuplicateForm);
