import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Autocomplete } from '@mic3/platform-ui';

import { bind, debounce, memoize } from 'app/utils/decorators/decoratorUtils';
import { loadVRelationDefinitionAutocomplete } from 'store/actions/entities/relationshipsActions';
import { isEmpty, shallowEquals } from 'app/utils/utils';
import { commonElementExists, findIntersection } from 'app/utils/array/array-utils';
import { getClassAncestors } from 'app/utils/classification/classificationUtils';
import uuidv1 from 'uuid/v1';

const getFields = (field, typesArray) => {
    return typesArray.map((uri) => ({ field, op: '=', value: uri }));
};

export const reverseDef = (def) => ({
    ...def,
    description: def.relatedDescription,
    relatedDescription: def.description,
    type: def.relatedType,
    relatedType: def.type,
    reverse: true,
});
/**
 * Select one or more groups using lazy loading.
 */
class RelationDefinitionTypeahead extends PureComponent<Object, Object> {
    static propTypes = {
        ...Autocomplete.propTypes,
        value: PropTypes.any,
        loadOptions: PropTypes.func.isRequired,
        fromType: PropTypes.string.isRequired,
        toType: PropTypes.string.isRequired,
        filterBy: PropTypes.arrayOf(PropTypes.Object),
        excludeBy: PropTypes.arrayOf(PropTypes.Object),
        orderBy: PropTypes.arrayOf(PropTypes.Object),
        isLoading: PropTypes.bool,
        options: PropTypes.arrayOf(PropTypes.object),
    };

    state = { key: uuidv1(), isLoading: false, options: [], lastRequest: 0, toUris: [], fromUris: [] };

    componentDidMount() {
        this.setAncestors(true);
    }

    componentDidUpdate(prevProps) {
        if (!shallowEquals(this.props, prevProps, ['fromType', 'toType', 'filterBy', 'excludeBy', 'orderBy'])) {
            this.setState({ isLoading: false, options: [], lastRequest: 0, toUris: [], fromUris: [], key: uuidv1() });
        }
    }

    @bind
    @memoize()
    getAncestorsUris(fromType, toType, entityTypes) {
        let fromUris = [];
        let toUris = [];
        if (!fromType || !toType || !entityTypes?.length) {
            return { fromUris, toUris };
        }
        fromUris.push(fromType);
        toUris.push(toType);
        try {
            const { id: toId } = entityTypes.find(({ uri }) => uri === toType);
            const { id: fromId } = entityTypes.find(({ uri }) => uri === fromType);
            fromUris = getClassAncestors(fromId, entityTypes);
            if (fromUris?.length) {
                fromUris = fromUris.map(({ uri }) => uri);
            }
            toUris = getClassAncestors(toId, entityTypes);
            if (toUris?.length) {
                toUris = toUris.map(({ uri }) => uri);
            }
            fromUris = fromUris?.length ? fromUris : [fromType];
            toUris = toUris?.length ? toUris : [toType];
        } catch (error) {}
        return { fromUris, toUris };
    }

    @bind
    async setAncestors(updateKey = false) {
        const { fromType, toType, entityTypes } = this.props;
        if (!fromType || !toType || !entityTypes?.length) {
            return;
        }
        const { fromUris, toUris } = this.getAncestorsUris(fromType, toType, entityTypes);
        let options = [];
        if (updateKey) {
            const data = await this.props.loadOptions(
                {
                    filterBy: [
                        {
                            or: [
                                { field: 'type', op: 'in', value: fromUris },
                                { field: 'relatedType', op: 'in', value: fromUris },
                            ],
                        },
                    ],
                },
                { fromType }
            );
            options = this.getRelationDefinitions(data, toUris, fromUris);
        }
        await this.setState((prevState) => ({
            toUris,
            fromUris,
            key: updateKey ? uuidv1() : prevState.key,
            options,
        }));
    }

    @bind
    getRelationDefinitions(data, toUris, fromUris) {
        if (isEmpty(data)) {
            return [];
        }
        if (!commonElementExists(toUris, fromUris)) {
            return data.map((rel) => {
                const reverse = fromUris.includes(rel?.relatedType) && toUris.includes(rel?.type);
                return reverse ? reverseDef(rel) : rel;
            });
        }
        const commonParents = findIntersection(toUris, fromUris);
        return data
            .map((rel) => {
                if (commonParents?.includes(rel?.type) && commonParents?.includes(rel?.relatedType)) {
                    return [rel, reverseDef(rel)];
                }
                const reverse = fromUris.includes(rel?.relatedType) && toUris.includes(rel?.type);
                return reverse ? reverseDef(rel) : rel;
            })
            .flat();
    }

    @bind
    @debounce()
    suggest(event: Object) {
        const { fromType, toType, entityTypes } = this.props;
        if (!fromType || !toType) {
            return;
        }
        const requestId = Date.now();
        this.setState({ isLoading: true, lastRequest: requestId }, async () => {
            const { value, loadOptions, filterBy, excludeBy, orderBy } = this.props;
            const filters = filterBy ? [...filterBy] : [];
            const { toUris, fromUris } = this.getAncestorsUris(fromType, toType, entityTypes);
            filters.push({
                or: [
                    [{ or: getFields('type', fromUris) }, { or: getFields('relatedType', toUris) }],
                    [{ or: getFields('type', toUris) }, { or: getFields('relatedType', fromUris) }],
                ],
            });
            const query = event.target.value;
            if (query) {
                filters.push({
                    or: [
                        { field: 'description', op: 'contains', value: query },
                        { field: 'relatedDescription', op: 'contains', value: query },
                    ],
                });
            }
            if (value) {
                const idsToExclude = (Array.isArray(value) ? value : [value]).map(({ id }) => id);
                filters.push({ field: 'id', op: 'not in', value: idsToExclude });
            }
            loadOptions(
                {
                    filterBy: filters,
                    excludeBy: excludeBy,
                    orderBy: orderBy,
                },
                { toType, fromType, fromUris, toUris, query }
            ).then((data) => {
                // if this is not the last request discard the result
                if (this.state.lastRequest === requestId) {
                    this.setState({
                        isLoading: false,
                        options: this.getRelationDefinitions(data, toUris, fromUris),
                        toUris,
                        fromUris,
                    });
                }
            });
        });
    }

    @bind
    optionTemplate(data: any) {
        const { id, description } = data;
        const label = `${description || 'Description not available'} (${id ? id.slice(0, 8) : 'Id not available'})`;
        const title = description;
        return {
            option: <span title={title}>{label}</span>,
            label,
        };
    }

    render() {
        const { loadOptions, fromType, toType, filterBy, excludeBy, orderBy, ...typeaheadProps } = this.props; // eslint-disable-line no-unused-vars
        const { isLoading, options, key } = this.state;
        return (
            <Autocomplete
                key={key}
                {...typeaheadProps}
                optionTemplate={this.optionTemplate}
                suggest={this.suggest}
                options={options}
                isLoading={isLoading}
            />
        );
    }
}

export default connect(
    (state) => ({
        entityTypes: state.app.allPrimaryClasses.records || [],
    }),
    { loadOptions: loadVRelationDefinitionAutocomplete }
)(RelationDefinitionTypeahead);
