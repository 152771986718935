/* @flow */

import React, { useState, useMemo } from 'react';

const PreviewSvg = ({ svg }) => {
    const [png, setPng] = useState('');

    useMemo(async () => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = svg;

        const svgElement = tempDiv.querySelector('svg');

        svgElement.setAttribute('viewBox', '0 0 1000 600'); // Modify as needed
        svgElement.setAttribute('width', '100');
        // svgElement.setAttribute('height', 'auto');
        const updatedSvg = svgElement.outerHTML;

        const blob = new Blob([updatedSvg], {type: 'image/svg+xml'});
        const url = URL.createObjectURL(blob);

        setPng(url);
    }, [svg]);

    return (
        <div style={{ width: '100px', height: '60px' }}>
            {png && (
                <img
                    src={png}
                    alt="Converted SVG"
                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
            )}
        </div>
    );
};

export default PreviewSvg;