/* @flow */

import React from 'react';
import { Route, Switch } from 'react-router-dom';


import PipelineEditor from 'app/containers/Designer/Pipeline/PipelineEditor';
import PipelineMonitor from './PipelineMonitor';

/**
 * Define the routes for the Event's views.
 */
const PipelineMonitorRoute = ({ match: { url } }: Object) => {
    const backgroundJobMatch = '/pipeline-monitor';
    return (
        <Switch>
           
            <Route path={`${backgroundJobMatch}/:id`} exact render={props => <PipelineEditor {...props} pipelineMonitor />} />    
            <Route path={`${backgroundJobMatch}`} exact component={PipelineMonitor} />
        </Switch>
    );};

export default PipelineMonitorRoute;
