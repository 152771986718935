/* @flow */

import { label } from 'app/utils/designer/form/settings/common/commonBase';
import { isVisible, isDisabled } from 'app/utils/designer/form/settings/common/commonEvents';
import { help } from 'app/utils/designer/form/settings/common/commonHelpers';
import { flexGrow } from 'app/utils/designer/form/settings/common/commonStyles';

const panels = (settingsValues) => {
    return [
        {
            header: 'Base',
            children: [ label,
                {
                    type: 'printTemplateTypeahead',
                    properties: {
                        label: 'Choose template',
                        name: 'properties.templateId',
                        valueField:'id',
                        withScriptsOrTasks: settingsValues?.viewType === 'formDefinition',
                        withScriptsOrApplicableOnType: settingsValues?.viewType !== 'formDefinition' && !!settingsValues?.classification?.primary,
                        applicableOnTypeId: settingsValues?.viewType !== 'formDefinition' && settingsValues?.classification?.id,
                        withScripts: !settingsValues?.classification?.primary && settingsValues?.viewType === 'class'
                    },
                    constraints: { required: true },
                },
            ].filter(Boolean),
        },
        {
            header: 'Events',
            expanded: true,
            children: [
                isVisible,
                settingsValues?.viewType !== 'class' && isDisabled
            ].filter(Boolean)
        },
        {
            header: 'Styles',
            children: [
                {
                    type: 'typeahead',
                    properties: {
                        label: 'Color',
                        name: 'properties.color',
                        valueField:'value',
                        clearable: false,
                        options: [
                            { value: 'primary' , label: 'primary' },
                            { value: 'secondary' , label: 'secondary' },
                        ]
                    }
                },
                {
                    type: 'typeahead',
                    properties: {
                        label: 'Variant',
                        name: 'properties.variant',
                        valueField:'value',
                        clearable: false,
                        options: [
                            { value: 'text' , label: 'Text' },
                            { value: 'outlined' , label: 'Outlined' },
                            { value: 'contained' , label: 'Contained' },
                        ]
                    }
                },
                { type: 'boolean', properties: { label: 'Full width', name: 'properties.fullWidth' } },
                {...flexGrow}
            ]
        },
        {
            header: 'Helpers',
            children: [ help ]
        },
    ];
};

export default {
    defaults: {
        actionType: 'function',
        fullWidth: true,
        variant: 'contained',
        color: 'primary',
        formFieldProps: { flexGrow: 1 }
    },
    panels: settingValues => panels(settingValues)
};
