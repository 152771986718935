/* @flow */

import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import { RouterMatchPropTypeBuilder } from 'app/utils/propTypes/common';
import PageNotAllowed from 'app/containers/ErrorPages/PageNotAllowed';
import integration from 'app/config/integrationConfig';
import IntegrationDetails from 'app/containers/Integration/IntegrationDetails';
import AddEntity from 'app/containers/Entities/EntitiesList/AddEntity'; // WIP: Testing add entity
import history from 'store/History';
import { loadClassification } from 'store/actions/classifications/classificationsActions';
import { loadIntegrations } from 'store/actions/admin/usersActions';

/**
 * Defines the routes for the Entities views
 */
class IntegrationRoutes extends PureComponent<Object, Object> {
    static propTypes = {
        match: RouterMatchPropTypeBuilder({ id: PropTypes.string }),
        userProfile: PropTypes.object,
    };
    
    constructor(props){
        super(props);
        this.props.loadClassification(integration.classId);
    }

    /**
     * @override
     */
    render() {
        const { match, classInfo, isLoading, loadIntegrations } = this.props;
        const { permissions, isAdmin } = this.props.userProfile;
        const permissionsSet = new Set(permissions || []);
        const canView = isAdmin || permissionsSet.has(integration.permissions.view);
        if (!canView) {
            return <PageNotAllowed title={integration.moduleName} />;
        }
        return (
            <Fragment>
                <Switch>
                    <Route path={`${match.url}/`} exact component={IntegrationDetails} />
                    <Route
                        path={`${match.url}/add`}
                        exact
                        render={props => (
                            <AddEntity
                                {...props}
                                classInfo={{ ...classInfo, isLoading, title: 'Integration', reloadList: loadIntegrations }}
                                onClose={() => {
                                    history.goBack();
                                }}
                            />
                        )}
                    />
                    <Route path={`${match.url}/:id`} component={IntegrationDetails} />
                </Switch>
            </Fragment>
        );
    }
}

export default connect(
    (state: Object): Object => ({
        userProfile: state.user.profile,
        isLoading: state.classifications.details.isLoading,
        classInfo: state.classifications.details.data || {},
    }), {
        loadClassification,
        loadIntegrations
    }
)(IntegrationRoutes);
