import React, { useCallback, memo } from 'react';
import Tour from 'reactour';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { closeTour } from 'store/actions/app/appActions';
import { saveUserPreferences } from 'store/actions/admin/usersActions';

const SubmitButton = styled.div`
    background: linear-gradient(270deg, #5C3C98 0%, #3E55A5 52.49%, #198AD1 100%);
    border-radius: 5rem !important;
    color:#fff;
    padding:10px 15px;
`;

export const StyledTour = styled(Tour)`
    padding: 22px 18px; !important;
`;


const AppTour = ({ steps, pageName }: Object) => {
    const dispatch = useDispatch();
    const isTourOpen = useSelector(state => state.app.isTourOpen);
    const userPreferences = useSelector(state => state?.user?.preferences);

    const handleCloseAppTour = useCallback(() => {
        if(!userPreferences?.tour?.includes(pageName)){
            dispatch(saveUserPreferences({tour: [...(userPreferences?.tour || []), pageName]}));
        }
        dispatch(closeTour());
    }, [userPreferences,dispatch,pageName]);

    const disableScrolling = useCallback(() => {
        document.body.style.overflowY = 'hidden';
    }, []);

    return(
        <StyledTour
            onRequestClose={handleCloseAppTour}
            steps={steps}
            accentColor={'linear-gradient(270deg, #5C3C98 0%, #3E55A5 52.49%, #198AD1 100%)'}
            isOpen={isTourOpen}
            rounded={15}
            onAfterOpen={disableScrolling}
            lastStepNextButton={<SubmitButton onClick={handleCloseAppTour}>DONE</SubmitButton>}
        />
    );
};

AppTour.propTypes = {
    steps:PropTypes.array,
    pageName: PropTypes.string
};

AppTour.defaultProps = {
    steps : [],
    pageName:''
};

export default memo(AppTour);
