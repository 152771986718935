import { gql } from '@apollo/client';

export default gql`
query processHistoryQuery($entityType: String!, $order: AscDesc, $filterBy: [JSON], $excludeBy: [JSON], $startIndex: Int!, $stopIndex: Int!) {
    count: count(dataType: "entityChangelog", filterBy: $filterBy, excludeBy: $excludeBy)
    records: entityChangelogs(entityType: $entityType, startIndex: $startIndex, stopIndex: $stopIndex, order: $order, filterBy: $filterBy, excludeBy: $excludeBy) {
        id
        entity {
            id
            name
            type
        }
        change
        user {
            id
            name
            image
        }
        date
    }
}
`;
