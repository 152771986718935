/* @flow */

import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import HeaderBar from 'app/components/molecules/HeaderBar/HeaderBar';
import PageTemplate from 'app/components/templates/PageTemplate';
import AuthentificationRequiredActions from './AuthentificationRequiredActions';
import Loader from 'app/components/atoms/Loader/Loader';
import Tabs from 'app/components/organisms/Tabs/Tabs';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import Help from 'app/utils/designer/form/settings/common/Help';
import { get } from 'app/utils/lo/lo';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { setDocumentTitle, updateSecurityGeneral } from 'store/actions/app/appActions';
import { Button, CircularProgress, Typography } from '@mic3/platform-ui';
import { showToastr } from 'store/actions/app/appActions';
import { getPermissions } from 'app/config/rolesConfig';
import { modulesAndPageTitles } from 'app/config/typesConfig';

const ButtonStyled = styled(Button)`
    margin-right: 8px !important;
`;
const StyledTypography = styled(Typography)`
    color: ${({theme})=> theme.material.colors.text.primary};
`;

class AuthenticationMain extends PureComponent<Object, Object> {

    formPasswordsRef: Object = React.createRef();
    formBrowserFlowRef: Object = React.createRef();

    constructor(props) {
        super(props);
        
        this.state = { 
            formData: props.ssoSettings, 
            formRevertKey: 0, 
            formTouched: false 
        };

    }

    componentDidMount() {
        this.props.setDocumentTitle(modulesAndPageTitles.adminConsole.security.authentication);
    }

    componentDidUpdate(prevProps) {
        const { location, ssoSettings } = this.props;
        if(location.pathname !== prevProps.location.pathname || ssoSettings !== prevProps.ssoSettings) {
            this.setState({ formData: ssoSettings, formTouched: false });
        }
    }

    @bind
    onFormSubmit(isRevert, isPasswordsTab) {
        return (event: Event) => {
            event.preventDefault();
            if(isPasswordsTab) {
                this.formPasswordsRef.current.isValidForm().then(({ data, errors }) => {
                    if (!errors) {
                        const { updateSecurityGeneral } = this.props;
                        updateSecurityGeneral({ passwordPolicy: data.passwordPolicy });
                    }
                });
    
            } else {
                this.formBrowserFlowRef.current.isValidForm().then(({ data, errors }) => {
                    if (!errors) {
                        const { updateSecurityGeneral } = this.props;
                        updateSecurityGeneral({ browserFlow: data.browserFlow });
                    }
                });
    
            }
        };
    }

    @bind
    @memoize()
    renderPasswordButtons(isLoading, formTouched, isPasswordsTab, canEdit) {
        if(!canEdit) return null;
        return isLoading ? (
            <CircularProgress key={113} size={24} color="primary" />
        ) : (
            <>
                <ButtonStyled key={113} onClick={this.onFormSubmit(false, isPasswordsTab)} color="primary" form="form" type="submit">
                    Save
                </ButtonStyled>
                <Button disabled={!formTouched} variant="outlined" key={114} onClick={this.revertChanges} color="primary" form="form" type="submit">
                    Revert
                </Button>
            </>
        );
    }

    @bind
    @memoize()
    buildTabs(match, location) {
        return [
            { active: location.pathname.includes('/required-actions'), label: 'Required Actions', link: `/admin/settings/authentication/required-actions` },
            { active: location.pathname.includes('/browser-flow'), label: 'Browser Flow', link: `/admin/settings/authentication/browser-flow` },
            { active: location.pathname.includes('/passwords'), label: 'Passwords', link: `/admin/settings/authentication/passwords` },
        ];
    }

    @bind
    handleChangeRequiredActions({ target: { name, value }}) {
        const { updateSecurityGeneral, ssoSettings } = this.props;
        const [actionName, checkboxName] = name.split('.');
        updateSecurityGeneral({
            requiredActions: ssoSettings.requiredActions.map(action => {
                if(action.name === actionName) {
                    return { ...action, [checkboxName]: value };
                }
                return action;
            })
        });
    }

    @bind
    revertChanges() {
        this.setState({ 
            formRevertKey: this.state.formRevertKey + 1, 
            formData: this.props.ssoSettings,
            formTouched: false,
        });
    }
    @bind
    onChangeForm(data) {
        this.setState({ formData: data, formTouched: true });
    }

    render() {
        const { match, location, isLoading, ssoSettings } = this.props;
        const { formRevertKey, formTouched, formData } = this.state;
        const { canEdit } = getPermissions(ssoSettings?.role);
        const isPasswordsTab = location.pathname.includes('/passwords');
        const isBrowserFlowTab = location.pathname.includes('/browser-flow');

        return (
            <PageTemplate title={'Authentication'} overflowHidden>
                <HeaderBar right={(isBrowserFlowTab || isPasswordsTab) && this.renderPasswordButtons(isLoading, formTouched, isPasswordsTab, canEdit)} left={<StyledTypography variant="h6">Authentication</StyledTypography>} />
                {isLoading && (
                    <Loader absolute backdrop />
                )}
                <ContentArea withHeader>
                    <Switch>
                        <Route path={`/admin/settings/authentication`} exact render={
                            () => <Redirect to={`/admin/settings/authentication/required-actions`}/>
                        }/>
                        <Route path={`/admin/settings/authentication/required-actions`} render={() =>
                            <AuthentificationRequiredActions
                                tabs={this.buildTabs(match, location)}
                                ssoSettings={ssoSettings}
                                onChange={this.handleChangeRequiredActions}
                                canEdit={canEdit}
                            />
                        }/>
                        <Route path={`/admin/settings/authentication/browser-flow`} render={() =>
                            (
                                <>
                                    <HeaderBar left={<Tabs tabs={this.buildTabs(match, location)}/>} />
                                    <FormGenerator
                                        key={formRevertKey}
                                        onChange={this.onChangeForm}
                                        ref={this.formBrowserFlowRef}
                                        data={formData}
                                        disabled={!canEdit}
                                        components={[
                                            {
                                                type: 'panel',
                                                properties: { header: 'Cookie', expanded: true },
                                                children: [
                                                    {
                                                        type: 'group',
                                                        properties: { name: 'browserFlow' },
                                                        children: [
                                                            {
                                                                type: 'group',
                                                                properties: { name: 'cookie' },
                                                                children: [
                                                                    { 
                                                                        type: 'typeahead',
                                                                        properties: {
                                                                            label: 'Requirement', 
                                                                            name: 'requirement',
                                                                            valueField: 'value', clearable: false,
                                                                            options: [
                                                                                { label: 'Disabled', value: 'DISABLED'},
                                                                                { label: 'Required', value: 'REQUIRED'},
                                                                                { label: 'Alternative', value: 'ALTERNATIVE'},
                                                                            ],
                                                                        },                                                                
                                                                    }
                                                                ],
                                                            },
                                                        ],
                                                    }
                                                
                                                ],
                                            },
                                            {
                                                type: 'panel',
                                                properties: { 
                                                    header: 'Identity Provider Redirector', 
                                                    subtitle: ssoSettings?.browserFlow?.identity_provider_redirector.defaultProvider, 
                                                    expanded: true 
                                                },
                                                children: [
                                                    {
                                                        type: 'group',
                                                        properties: { name: 'browserFlow' },
                                                        children: [
                                                            {
                                                                type: 'group',
                                                                properties: { name: 'identity_provider_redirector' },
                                                                children: [
                                                                    { 
                                                                        type: 'typeahead',
                                                                        properties: {
                                                                            label: 'Requirement', 
                                                                            name: 'requirement',
                                                                            valueField: 'value', clearable: false,
                                                                            options: [
                                                                                { label: 'Disabled', value: 'DISABLED'},
                                                                                { label: 'Required', value: 'REQUIRED'},
                                                                                { label: 'Alternative', value: 'ALTERNATIVE'},
                                                                            ],
                                                                            onChange: ({ target: { value, name }}) => {
                                                                                return value === 'DISABLED' ? [{ value, name }, { name: 'defaultProvider', value: null }] : [{ value, name }];
                                                                            },
                                                                        },                                                                
                                                                    },
                                                                    { 
                                                                        type: 'typeahead',
                                                                        properties: {
                                                                            label: 'Default provider', 
                                                                            name: 'defaultProvider',
                                                                            valueField: 'value', clearable: true,
                                                                            options: (ssoSettings?.identityProviders || []).map(provider => ({
                                                                                value: provider.alias,
                                                                                label: provider.displayName || provider.alias
                                                                            })),
                                                                            isVisible: (data) => {
                                                                                return data?.requirement !== 'DISABLED';
                                                                            }
                                                                        },   
                                                                        constraints: {
                                                                            required: (data) => data?.requirement === 'REQUIRED'
                                                                        }                                                           
                                                                    },
                                                                ],
                                                            },
                                                            
                                                        ],
                                                    }
                                                
                                                ],
                                            },
                                            {
                                                type: 'panel',
                                                properties: { header: 'Forms', subtitle: 'Username, password, otp and other auth forms', expanded: true },
                                                children: [
                                                    {
                                                        type: 'group',
                                                        properties: { name: 'browserFlow' },
                                                        children: [
                                                            {
                                                                type: 'group',
                                                                properties: { name: 'forms' },
                                                                children: [
                                                                    { 
                                                                        type: 'typeahead',
                                                                        properties: {
                                                                            label: 'Requirement', 
                                                                            name: 'requirement',
                                                                            valueField: 'value', clearable: false,
                                                                            options: [
                                                                                { label: 'Disabled', value: 'DISABLED'},
                                                                                { label: 'Required', value: 'REQUIRED'},
                                                                                { label: 'Alternative', value: 'ALTERNATIVE'},                                                                                
                                                                                { label: 'Conditional', value: 'CONDITIONAL'},                                                                                
                                                                            ],
                                                                        },                                                                
                                                                    }
                                                                ],
                                                            },
                                                            
                                                        ],
                                                    }
                                                
                                                ],
                                            },
                                            {
                                                type: 'panel',
                                                properties: { header: 'Browser - Conditional OTP', subtitle: 'Flow to determine if the OTP is require for the authentication', expanded: true },
                                                children: [
                                                    {
                                                        type: 'group',
                                                        properties: { name: 'browserFlow' },
                                                        children: [
                                                            {
                                                                type: 'group',
                                                                properties: { name: 'conditional_otp' },
                                                                children: [
                                                                    { 
                                                                        type: 'typeahead',
                                                                        properties: {
                                                                            label: 'Requirement', 
                                                                            name: 'requirement',
                                                                            valueField: 'value', clearable: false,
                                                                            options: [
                                                                                { label: 'Disabled', value: 'DISABLED'},
                                                                                { label: 'Required', value: 'REQUIRED'},
                                                                                { label: 'Alternative', value: 'ALTERNATIVE'},
                                                                                { label: 'Conditional', value: 'CONDITIONAL'},                                                                                
                                                                            ],
                                                                        },                                                                
                                                                    }
                                                                ],
                                                            },
                                                            
                                                        ],
                                                    }
                                                
                                                ],
                                            },
                                        ]}
                                    />
                                </>
                            )
                        }/>                        
                        <Route path={`/admin/settings/authentication/passwords`} render={() =>
                            (
                                <>
                                    <HeaderBar left={<Tabs tabs={this.buildTabs(match, location)}/>} />
                                    <FormGenerator
                                        onChange={this.onChangeForm}
                                        key={formRevertKey}
                                        ref={this.formPasswordsRef}
                                        data={formData}
                                        disabled={!canEdit}
                                        components={[{
                                            type: 'panel',
                                            properties: { 
                                                header: 'Policies', 
                                                expanded: true,
                                                help: <Help message="You can use the policies fields to configure password policy. Leave a policy field blank if you do not wish to set that policy." />,
                                            },
                                            children: [
                                                {
                                                    type: 'group',
                                                    properties: { name: 'passwordPolicy' },
                                                    children: [
                                                        { type: 'number', properties: { 
                                                            name: 'expireDays', 
                                                            label: 'Password expire after amount of days',
                                                            help: <Help message="The number of days the password is valid before a new password is required." />,

                                                        }, constraints: { onlyInteger: true, min: 0 } },
                                                        { type: 'number', properties: { 
                                                            name: 'hashIterations', 
                                                            label: 'Hashing iterations',
                                                            help: <Help message="The number of times a password is hashed before storage or verification. Default: 27,500." />,
                                                        }, constraints: { onlyInteger: true, min: 0 }},
                                                        { type: 'number', properties: { 
                                                            name: 'passwordHistory', 
                                                            label: 'Password not recently used', 
                                                            help: <Help message="Prevents a recently used password from being reused." />,
                                                        }, constraints: { onlyInteger: true, min: 0 } },
                                                        { type: 'number', properties: { 
                                                            name: 'minLength', 
                                                            label: 'Min Length',
                                                            help: <Help message="The minimum number of characters required for the password." />,
                                                        }, constraints: { onlyInteger: true, min: 0 } },
                                                        { type: 'number', properties: { 
                                                            name: 'maxLength', 
                                                            label: 'Max Length', 
                                                            help: <Help message="The maximum number of characters allowed in the password." />,
                                                        }, constraints: { onlyInteger: true, min: 0 } },
                                                        { type: 'text', properties: { 
                                                            name: 'regexPattern', 
                                                            label: 'Regular Expression', 
                                                            help: <Help message="Requires that the password matches one or more defined Java regular expression patterns." />,
                                                        } },
                                                        { type: 'checkbox', properties: { 
                                                            name: 'notUsername', 
                                                            label: 'Password cannot be username',
                                                            help: <Help message="The password cannot match the username." />,
                                                        } },
                                                        { type: 'checkbox', properties: { 
                                                            name: 'notEmail',
                                                            label: 'Password cannot be email address',
                                                            help: <Help message="The password cannot match the email address of the user." />,
                                                        } },
                                                        { type: 'number', properties: { 
                                                            name: 'minSpecialChars', 
                                                            label: 'Minimum amount of special characters',
                                                            help: <Help message="The number of special characters required in the password string." />,
                                                        }, constraints: { onlyInteger: true, min: 0 } },
                                                        { type: 'number', properties: { 
                                                            name: 'minUpperCase', 
                                                            label: 'Minimum amount of uppercase characters',
                                                            help: <Help message="The number of uppercase letters required in the password string." />,
                                                        }, constraints: { onlyInteger: true, min: 0 } },
                                                        { type: 'number', properties: { 
                                                            name: 'minLowerCase', 
                                                            label: 'Minimum amount of lowercase characters',
                                                            help: <Help message="The number of lowercase letters required in the password string." />,
                                                        }, constraints: { onlyInteger: true, min: 0 } },
                                                        { type: 'number', properties: { 
                                                            name: 'minDigits', 
                                                            label: 'Minimum amount of digit (number) characters',
                                                            help: <Help message="The number of numerical digits required in the password string." />,
                                                        }, constraints: { onlyInteger: true, min: 0 } },
                                                    ]
                                                }

                                                
                                            ],
                                        }]}
                                    />
                                </>
                            )
                        }/>
                        
                    </Switch>                 
                </ContentArea>
            </PageTemplate>
        );
    }
}

export default connect(
    state => ({
        isLoading: state.app.ssoSettingsLoading,
        ssoSettings: get(state.app, 'ssoSettings', {}),
    }),
    {
        showToastr,
        updateSecurityGeneral,
        setDocumentTitle,
    }
)(AuthenticationMain);
