// @flow

import React, { useMemo, useState, useCallback } from 'react';
import clsx from 'clsx';
import { Drawer, Divider, ClickAwayListener } from '@mic3/platform-ui';

import { useAppNavStyles } from './AppNav.style';
import { mediaBreakpoints } from 'app/themes/breakpoints';
import { get } from 'app/utils/lo/lo';

import createList from 'app/components/organisms/AppNavigation/menus/createList';
import AppNavHeader from './AppNavHeader';
import AppNavContent from './AppNavContent';
import AppNavFooter from './AppNavFooter';

const AppNav = ({
    menuItems,
    createMenuItems,
    openMenu,
    isLeftOpen: isOpen,
    isExpanded,
    location,
    windowWidth,
    permissions, isAdmin,
    closeMenu,
    className
}: Object) => {
    const isMobile = windowWidth < mediaBreakpoints.md;
    const classes = useAppNavStyles();
    const [createNewAnchorEl, setCreateNewAnchorEl] = useState(null);
    const { pathname } = location || {};
    const handleCreateNewOpen = useCallback((e: Event) => {
        setCreateNewAnchorEl(e.currentTarget);
    }, []);

    const basePath = useMemo(() => pathname.split('/')[1], [pathname]);
    const [activeItem, setActiveMenuItem] = useState(basePath);

    const handleCreateNewClose = useCallback(() => {
        setCreateNewAnchorEl(null);
    }, []);

    const handleClickAway = useCallback((e) => {
        const elemClass = get(e, 'target.className', '');
        if (typeof elemClass !== 'string') return;
        if (elemClass.indexOf('app-nav-menu') === -1) { 
            closeMenu();
        }
    }, [closeMenu]);

    const drawerClass = clsx(classes.root, {
        [classes.navOpen]: isOpen,
        [classes.navClosed]: !isOpen,
        [classes.isMobile]: isMobile,
        [classes.hideEmptyDrawer]: isMobile && !isOpen,
        [classes.sidebarExpanded]: isExpanded
    });
    const drawerPaperClass = clsx(classes.paper, {
        [classes.navOpen]: isOpen,
        [classes.navClosed]: !isOpen,
        [classes.isMobile]: isMobile
    });
    const appHeaderClass = clsx(classes.header, {
        [classes.navClosedHeader]: !isOpen,
        [classes.navClosedList]: !isOpen
    });
    const appNavContentClass = clsx(classes.list, {
        [classes.navClosedList]: !isOpen
    });
    const footerClass = clsx(classes.footer, {
        [classes.footerClosed]: !isOpen
    });

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <Drawer
                className={`${drawerClass} ${className}`}
                classes={{paper: drawerPaperClass}}
                ModalProps={{ hideBackdrop: true }}
                anchor="left"
                open
                variant={!isMobile ? 'permanent' : 'temporary'}
            >
                <AppNavHeader
                    navOpen={isOpen}
                    className={appHeaderClass}
                    onAddClick={handleCreateNewOpen}
                    permissions={permissions}
                    isAdmin={isAdmin}
                    setActiveMenuItem={setActiveMenuItem}
                    closeMenu={closeMenu}
                />
                <Divider />
                <AppNavContent
                    createNewAnchorEl={createNewAnchorEl}
                    resetCreateNewAnchorEl={handleCreateNewClose}
                    className={appNavContentClass}
                    activeMenuItem={basePath}
                    activeItem={activeItem}
                    setActiveMenuItem={setActiveMenuItem}
                    navOpen={isOpen}
                    items={menuItems}
                    createItems={createList(isAdmin, permissions)}
                    closeMenu={closeMenu}
                />
                <div style={{ height: '50.5px'}}></div>
                <AppNavFooter
                    className={footerClass}
                    navOpen={isOpen}
                />
            </Drawer>
        </ClickAwayListener>
    );
};

export default AppNav;
