/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AutocompleteLazy } from '@mic3/platform-ui';

import { loadTeamsTypeahead, loadTeamsTypeaheadPermissions } from 'store/actions/admin/teamsActions';
import { bind } from 'app/utils/decorators/decoratorUtils';
import { isValiduuid } from 'app/utils/string/string-utils';
import { filterRecordsOnFE, orderRecordsOnFE } from 'app/utils/filter/filterUtils';
import AbstractEntityTypeahead from 'app/components/organisms/Form/Typeahead/abstract/AbstractEntityTypeahead';
import ResolveAvatarTypeaheadWrapper from 'app/components/organisms/Form/Typeahead/abstract/ResolveAvatarTypeaheadWrapper';

/**
 * Select one or more groups using lazy loading.
 */
class TeamTypeahead extends PureComponent<Object, Object> {

    static propTypes = {
        loadTeamsTypeahead: PropTypes.func.isRequired,
        filterBy: PropTypes.arrayOf(PropTypes.object),
    }
    constructor(props: Object) {
        super(props);
        !props.allowPermissions && this.fetchData();
        this.state = {
            isLoading: false, 
            options: []
        };
    }

    componentDidUpdate(prevProps){
        const { allowPermissions } = this.props;
        if( prevProps.allowPermissions && !allowPermissions){
            this.fetchData();
        }
    }

    @bind
    optionTemplate({ name, id }: Object) {
        return ({
            label: `${name || 'Name not available'} (${id ? id.slice(0,8) : 'Id not available'})`,
        });};

    @bind
    async fetchData() {
        this.setState({ isLoading: true });
        const { loadTeamsTypeahead } = this.props;
        const response = await loadTeamsTypeahead();
        this.setState({ options: response.records, isLoading: false });
    }
    
    
    @bind
    suggest(query: any) {
        const { filterBy, excludeBy, orderBy, value, valueField } = this.props;
        const { options } = this.state;
        let filteredData = [...options];

        const filters = [...(filterBy || [])];
        const excludes = [...(excludeBy || [])];

        if (query) {
            if (isValiduuid(query)) {
                filters.push({ or: [
                    { field: 'id', op: '=', value: query },
                    { field: 'name', op: 'contains', value: query },
                ] });
            } else {
                filters.push({ field: 'name', op: 'contains', value: query });
            }
        }

        if (value) {
            const val = Array.isArray(value) ? value : [value];
            let orVal = [];
            if(valueField) {
                orVal = val.map(fieldVal => ({ field: valueField, op: '=', value: fieldVal }));
            } else {
                const selectedIds = val.map(({ id }) =>  id).filter(Boolean) || [];
                const excludedIdsFilter = [{ field: 'id', op: 'in', value: selectedIds }];
                if(selectedIds?.length){
                    excludes.push(...excludedIdsFilter);
                }
            }
            if(orVal.length) {
                excludes.push({ or: orVal });
            }
        }

        filteredData = filterRecordsOnFE(filteredData, filters, excludes);
        filteredData = orderRecordsOnFE(filteredData, orderBy);
        
        return filteredData || [];
    }


    render() {
        const { placeholder,loadTeamsTypeaheadPermissions,
            allowPermissions, ...abstractEntityAutocompleteProps } = this.props;
        const { options, isLoading } = this.state;
        return (
            allowPermissions ?
                <AbstractEntityTypeahead
                    {...abstractEntityAutocompleteProps}
                    placeholder= {placeholder || 'Search for a Team...'}
                    loadOptions={loadTeamsTypeaheadPermissions}
                    optionTemplate={this.optionTemplate}
                />
                :
                <AutocompleteLazy
                    {...abstractEntityAutocompleteProps}
                    VirtualListProps={{
                        itemSize: 60,
                    }}
                    key={options?.length}
                    isLoading={isLoading}
                    optionTemplate={this.optionTemplate}
                    placeholder= {placeholder || 'Search for a Team...'}
                    fetchData={this.suggest}
                />
        );
    }
};

const TeamTypeaheadWrapped = (props) => {
    return <ResolveAvatarTypeaheadWrapper {...props} Typeahead={TeamTypeahead} entityType="team" />;
};

export default connect(
    null, { loadTeamsTypeahead, loadTeamsTypeaheadPermissions }
)(TeamTypeaheadWrapped);
