/* @flow */
import { gql } from '@apollo/client';
import Immutable from 'app/utils/immutable/Immutable';
import { get } from 'app/utils/lo/lo';
import { graphql } from 'graphql/client';

/**
 * Return result or full data
 *
 * @param response data from request.
 */
export const _getData = (response: Object) => {
    const data = response.data || {};
    if (data && data.result !== undefined) {
        return data.role !== undefined ? { ...data.result, role: data.role } : data.result;
    }
    return data;
};

export const queryData = (query, variables, countMax = 1000) => graphql.query({
    query,
    variables: {
        page: 1,
        pageSize: 10,
        ...variables,
        countMax: countMax || 10000
    },
    fetchPolicy: 'no-cache'
});


/**
 * Function that takes either a query string or gql query object
 */
export const executeQuery = async (queryString, variables = {}, dataKey = '') => {
    let query = queryString;
    if (typeof queryString === 'string') {
        query = gql`${query}`;
    }
    const result = await graphql.query({ query, variables, fetchPolicy: 'no-cache' });
    if (result instanceof Error) {
        return null;
    }
    if (dataKey) {
        return get(result, dataKey);
    }
    return _getData(result);
};

/**
 *
 */
export const dispatchData = (startActionType: string, endActionType: string, meta: Object = {}, job: string) => async (
    dispatch: Function,
    getState: Function
): Promise<Object> => {
    dispatch({ type: startActionType, meta });
    try {
        const payload = await job();
        dispatch({ type: endActionType, payload, meta });
        return payload;
    } catch (error) {
        dispatch({ type: endActionType, error: true, payload: Immutable(error), meta });
        return error;
    }
};


/**
 * Returns the handler function to dipatch a success after that a GraphQL action completes successfully.
 *
 * @param dispatch the Redux's dispatch function.
 * @param type the action type to dispatch.
 * @param successMessage the success message to dispatch.
 */
const dispatchSuccess = (dispatch: Function, type: string, successMessage: string, meta?: Object, modifyPayload) =>
    async (response: Object) => {
        let payload = _getData(response);
        if (modifyPayload) {
            payload = await modifyPayload(payload, meta);
        }
        const metadata = Immutable({ ...(meta || {}), successMessage });
        dispatch({ type, payload: Immutable(payload), meta: metadata });
        return payload;
    };

/**
 * Returns the handler function to dipatch an error after that a GraphQL action throw an Error.
 *
 * @param dispatch the Redux's dispatch function.
 * @param type the action type to dispatch.
 */
const dispatchError = (dispatch: Function, type: string, meta?: Object) =>
    (error: Error) => {
        dispatch({ type, payload: Immutable(error), error: true, meta });
        return Immutable(error);
    };

/**
 * Returns the action to load the data for a DataTable.
 *
 * The returned action will accept only one parameter where the caller can specify the action options: { page, pageSize, countMax, where, orderBy, download }
 *
 * @param startActionType the start action type
 * @param endActionType the end action type
 * @param grqphQlQuery the graphql query.
 *    The query must accept as input variables: page, pageSize, where, orderBy, countMax.
 *    The output of the query must contains 2 properties: records and count.
 *    The records property must contains the list of records to display in the DataTable.
 *    The count property is the total number of records that match the query criterias.
 */
const loadTableData = (startActionType: string, endActionType: string, graphQlQuery: string, countMax: ?number) =>
    (options: Object) => (dispatch: Function, getState: Function): Promise<Object> => {
        const { download, ...variables } = (options || {});
        const meta = Immutable({ download, countMax });
        dispatch({ type: startActionType, meta });
        return graphql.query({
            query: graphQlQuery,
            variables: {
                page: 1,
                pageSize: 10,
                ...variables,
                countMax: countMax || 10000,
            },
            fetchPolicy: 'no-cache',
        }).then((response: Object) => {
            const { count, records } = Immutable(get(response, 'data') || {});
            if (!Number.isInteger(count) || !Array.isArray(records)) {
                console.warn(`The action "${endActionType}" is not returning the correct data.`, response); // eslint-disable-line no-console
                throw new Error('The service\'s response is not well formed.');
            }
            dispatch({
                type: endActionType,
                payload: Immutable({ count, records: records.filter(record => record) }),
                meta,
            });
            return { count, records };
        }).catch((error) => {
            dispatch({ type: endActionType, error: true, payload: Immutable(error), meta });
            return error;
        });
    };

/**
 * Returns the action to load the data using a GraphQL query.
 *
 * The returned action will accept only one parameter where the caller can specify the GraphQL query variables.
 *
 * @param startActionType the start action type
 * @param endActionType the end action type
 * @param graphQlQuery  the graphql query. If the output of the query contains the property "result"
 *                      just the value of this property will be returned, otherwise all the data will be returned.
 */
const loadData = (
    startActionType: string,
    endActionType: string,
    graphQlQuery: string,
    modifyPayload: Function,
) =>
    (variables: Object) => (dispatch: Function, getState: Function) => {
        const meta = { ...(variables || {}) };
        dispatch({ type: startActionType, meta });
        return graphql.query({
            query: graphQlQuery,
            variables,
            fetchPolicy: 'no-cache',
        }).then(async (response: Object) => {
            let payload = _getData(response);
            if (modifyPayload) {
                payload = await modifyPayload(payload, meta);
            }
            dispatch({ type: endActionType, payload: Immutable(payload), meta });
            return payload;
        }).catch(dispatchError(dispatch, endActionType, meta));
    };

/**
 * Returns the action to mutate data using GraphQL.
 *
 * @param startActionType the start action type
 * @param endActionType the end action type
 * @param graphQlMutation the graphql mutation.
 * @param successMessage the success message.
 */
const mutateData = (
    startActionType: string,
    endActionType: string,
    graphQlMutation: string,
    successMessage: string,
    modifyPayload: Function,
) =>
    (variables: Object) => (dispatch: Function, getState: Function) => {
        const meta = { ...(variables || {}), mutate: true };
        dispatch({ type: startActionType, meta });
        return graphql.mutate({
            mutation: graphQlMutation,
            variables,
        }).then(dispatchSuccess(dispatch, endActionType, successMessage, meta, modifyPayload))
            .catch(dispatchError(dispatch, endActionType, meta));
    };

export { loadTableData, loadData, dispatchSuccess, dispatchError, mutateData };
