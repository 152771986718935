/* @flow */

import { loadData, mutateData } from 'app/utils/redux/action-utils';
import OptionsBuilder from 'app/utils/api/OptionsBuilder';
import { getAvatar } from 'app/utils/avatar/avatar';
import isUuid from 'uuid-validate';

import designerScriptListQuery from 'graphql/designer/designerScriptListQuery';
import designerScriptQuery from 'graphql/designer/scriptQuery';
import createScriptMutation from 'graphql/designer/createScriptMutation';
import executeScriptMutation from 'graphql/designer/executeScriptMutation';
import executeScriptForViewerMutation from 'graphql/designer/executeScriptForViewerMutation';
import updateScriptMutation from 'graphql/designer/updateScriptMutation';
import publishScriptMutation from 'graphql/designer/publishScriptMutation';
import loadScriptsTypeaheadQuery from 'graphql/designer/loadScriptsTypeaheadQuery';
import loadAvatarQuery from 'graphql/designer/loadAvatarQuery';

export const LOAD_SCRIPTS_STARTED: string = '@@affectli/designer/LOAD_SCRIPTS_STARTED';
export const LOAD_SCRIPTS: string = '@@affectli/designer/LOAD_SCRIPTS';

export const LOAD_SCRIPT_STARTED: string = '@@affectli/designer/LOAD_SCRIPT_STARTED';
export const LOAD_SCRIPT: string = '@@affectli/designer/LOAD_SCRIPT';

export const CREATE_SCRIPT_STARTED: string = '@@affectli/designer/CREATE_SCRIPT_STARTED';
export const CREATE_SCRIPT: string = '@@affectli/designer/CREATE_SCRIPT';

export const UPDATE_SCRIPT_STARTED: string = '@@affectli/designer/UPDATE_SCRIPT_STARTED';
export const UPDATE_SCRIPT: string = '@@affectli/designer/UPDATE_SCRIPT';

export const LOAD_SCRIPTS_TYPEAHEAD_STARTED: string = '@@affectli/designer/LOAD_SCRIPTS_TYPEAHEAD_STARTED';
export const LOAD_SCRIPTS_TYPEAHEAD: string = '@@affectli/designer/LOAD_SCRIPTS_TYPEAHEAD';

export const LOAD_AVATAR_STARTED = '@@affectli/designer/scripts/LOAD_AVATAR_STARTED';
export const LOAD_AVATAR = '@@affectli/designer/scripts/LOAD_AVATAR';

export const EXECUTE_SCRIPT_STARTED = '@@affectli/designer/scripts/EXECUTE_SCRIPT_STARTED';
export const EXECUTE_SCRIPT = '@@affectli/designer/scripts/EXECUTE_SCRIPT';

export const loadAvatar = id => (dispatch: Function, getState: Function) => {
    const avatar = getAvatar(getState(), 'script', id);
    if (avatar && (avatar.isLoading || avatar.loadedAt > Date.now() - 60 * 60 * 1000)) {
        // if we are loding the avatar or it is already loaded and it is stored less then 1 hour ago
        // we return the data in the state without calling the service
        return Promise.resolve(avatar);
    }
    if(!isUuid(id)) {
        return Promise.resolve(null);
    }
    return loadData(LOAD_AVATAR_STARTED, LOAD_AVATAR, loadAvatarQuery)({ id })(dispatch, getState);
};

/**
 * Loads the scripts
 */
export const loadScripts = (options: Object = {}) => {
    const variables = new OptionsBuilder(options)
        .defaultStartStopIndexs(0, 30)
        .build();

    return loadData(
        LOAD_SCRIPTS_STARTED,
        LOAD_SCRIPTS,
        designerScriptListQuery
    )({ ...variables, startIndex: options.startIndex });
};

/**
 * Loads the script with the given id
 */
export const executeScript = (id: string, input: object) => mutateData(
    EXECUTE_SCRIPT_STARTED,
    EXECUTE_SCRIPT,
    executeScriptMutation
)({ id, input });

/**
 * Loads the script with the given id
 */
export const executeScriptForViewer = (id: string, input: object) => loadData(
    EXECUTE_SCRIPT_STARTED,
    EXECUTE_SCRIPT,
    executeScriptForViewerMutation
)({ id, input });

/**
 * Loads the script with the given id
 */
export const loadScript = (id: string) => loadData(
    LOAD_SCRIPT_STARTED,
    LOAD_SCRIPT,
    designerScriptQuery
)({ id });

/**
 * Creates a new the script
 */
export const createScript = (record: Object) => mutateData(
    CREATE_SCRIPT_STARTED,
    CREATE_SCRIPT,
    createScriptMutation,
    'Script created successfully.'
)({ record });

/**
 * Updates a script
 */
export const updateScript = (record: Object, disableMessage) => (dispatch, getState) => {
    return mutateData(
        UPDATE_SCRIPT_STARTED,
        UPDATE_SCRIPT,
        updateScriptMutation,
        !disableMessage ? 'Script updated successfully.' : null
    )({ record })(dispatch, getState);
};

/**
 * Published a script
 */
export const publishScript = (id: string) =>
    mutateData(
        UPDATE_SCRIPT_STARTED,
        UPDATE_SCRIPT,
        publishScriptMutation,
        'Script published successfully.'
    )({ id });

export const loadScriptsTypeahead = (options) => {
    const variables = new OptionsBuilder(options)
        .filter({ field: 'lastVersion.id', op: 'is not null' })
        .build();
    return loadData(LOAD_SCRIPTS_TYPEAHEAD_STARTED, LOAD_SCRIPTS_TYPEAHEAD, loadScriptsTypeaheadQuery)(variables);
};

