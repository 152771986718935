/* @flow */

import React from 'react';

import { buildIndexAttributeDefinition } from 'app/components/molecules/IndexAttribute/IndexAttribute';
import { name, label, placeholder } from 'app/utils/designer/form/settings/common/commonBase';
import { onLoad, onChange, isVisible, isDisabled, onCalculation } from 'app/utils/designer/form/settings/common/commonEvents';
import { help } from 'app/utils/designer/form/settings/common/commonHelpers';
import { required, classRequired, readOnly, staticAttribute } from 'app/utils/designer/form/settings/common/commonValidation';
import { flexGrow } from 'app/utils/designer/form/settings/common/commonStyles';
import Help from 'app/utils/designer/form/settings/common/Help';
import { validateMinMax } from 'app/utils/designer/form/settings/metadata/inputSettings';


const minField = {
    type: 'number',
    properties: {
        label: 'min',
        name: 'constraints.min',
        help: <Help message="the minimum number" />,
    },
};
const maxField = {
    type: 'number',
    properties: {
        label: 'max',
        name: 'constraints.max',
        help: <Help message="the maximum number" />,
    },
};

const _getMinMaxProperties = (settingsValues) => validateMinMax(minField, maxField, 'constraints.min', 'constraints.max');

const panels = settingsValues => [
    {
        header: 'Base',
        children: [
            name,
            label,
            placeholder,
            {
                type: 'number',
                properties: {
                    label: 'Number of decimal places',
                    name: 'properties.decimalPlaces',
                    isVisible: ({ constraints }) => {
                        if(constraints) {
                            return !constraints.onlyInteger;
                        }
                        return true;
                    }
                },
            },
        ],
    },
    settingsValues?.viewType === 'class' && onCalculation,
    {
        header: 'Events',
        children: [
            onLoad,
            onChange,
            isVisible,
            settingsValues?.viewType !== 'class' && isDisabled
        ].filter(Boolean)
    },
    {
        header: 'Validation',
        children: [
            settingsValues?.viewType !== 'class' ? required : classRequired,
            settingsValues?.viewType === 'class' && readOnly,
            settingsValues?.viewType === 'class' && staticAttribute(settingsValues),
            {
                type: 'boolean',
                properties: {
                    label: 'only integer',
                    name: 'constraints.onlyInteger',
                    help: <Help message="can be just integer numbers" />,
                },
            },
            ..._getMinMaxProperties(settingsValues),
        ].filter(Boolean)
    },
    buildIndexAttributeDefinition(settingsValues),
    {
        header: 'Styles',
        children: [ flexGrow ]
    },
    {
        header: 'Helpers',
        children: [ help ]
    },
].filter(Boolean);

export default {
    defaults: { withDefault: true, },
    panels: settingValues => panels(settingValues),
};
