import React, { memo, useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button, Typography } from '@mic3/platform-ui';
import { useSelector } from 'react-redux';

import Icon from '../atoms/Icon/Icon';

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;    
    color: rgba(255, 255, 255, 0.38);
    ${({ large }) => {
    if (!large) {
        return 'height: calc(100% - 100px);';
    } else {
        return 'height: 100%;';
    }
}}
`;

const InnerWrapper = styled.div`
    display: grid;
    text-align: center;
    ${({ large }) => {
    if (!large) {
        return `
            grid-template-columns: auto;
            grid-template-areas: 
                "icon"
                "title" 
                "button" 
        `;
    } else {
        return `
            grid-template-columns: auto auto;
            grid-template-areas: 
                "icon title" 
                "button button";

            @media (max-width: 599px){
                grid-template-columns: auto;
                grid-template-areas: 
                    "icon"
                    "title" 
                    "button"
            }
        `;
    }
}}
`;

const ButtonWrapper = styled.div`
    grid-area: button;
    ${({ large }) => {
    if (large) {
        return `
            @media (max-width: 599px){
                margin-top: 30px;
            }
        `;
    }
}}
`;

const StyledButton = styled(Button)`
    padding: 12px !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.75px;
    text-transform: uppercase;  
`;

const TypographyStyled = styled(Typography)`
    grid-area: title;
    color: ${({theme})=> theme.material.colors.text.caption} !important;
    ${({ $large }) => {
    if ($large) {
        return `
            && {
                margin-left: 24px;
                font-size: 60px;
                font-weight: 200;
                line-height: 68px;
            }
            justify-content: center;
            align-items: center;
            display: flex;
        `;
    }
}}
`;

const IconStyled = styled(Icon)`
    grid-area: icon;
    &:before {
        opacity: 50%;
        color: ${({theme})=> theme.material.colors.text.caption} !important;
        font-size: 85px !important;
    }
`;

const RelatedEntityGetStarted = ({ 
    large,
    onClickCreate
}) => {
    const profile = useSelector(state => state.user.profile);
    const handleClick = useCallback(() => onClickCreate(), [onClickCreate]);
    const { permissions, isAdmin } = profile || {};
    const permissionsSet = new Set(permissions || []);
    const canAdd = isAdmin || permissionsSet.has('entity.entity.add');
    return (
        <Wrapper>
            <InnerWrapper large={large}>
                <IconStyled name='file-tree' />
                <TypographyStyled $large={large}>Let's get Started</TypographyStyled>
                {canAdd && (
                    <ButtonWrapper large={large}>
                        <StyledButton variant='text' color='secondary' onClick={handleClick}>
                            Create Tree Template
                        </StyledButton>
                    </ButtonWrapper>
                )}
            </InnerWrapper>
        </Wrapper>
    );
};

RelatedEntityGetStarted.propTypes = {
    large: PropTypes.bool,
    onClickCreate: PropTypes.func.isRequired
};

export default memo(RelatedEntityGetStarted);