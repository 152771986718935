/* @flow */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { RadioGroup, FormControl } from '@mic3/platform-ui';
import MuiRadio from '@material-ui/core/Radio';
import { bind } from 'app/utils/decorators/decoratorUtils';

class LayerStylingButtons extends PureComponent {
    static propTypes = {
        ...(MuiRadio || {}).propTypes,
        onChange: PropTypes.func.isRequired,
        labelPlacement: PropTypes.string,
        options: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                value: PropTypes.string.isRequired,
                description: PropTypes.string.isRequired
            })
        )
    };

    static defaultProps = {
        color: 'primary'
    };

    @bind
    onChange(e) {
        const { formData } = this.props;
        const { name, value } = e.target;
        this.props.onChange && this.props.onChange(formData, { name, value });
    }
    
    render() {
        const { RadioProps, labelPlacement, color, value, onChange, options, ...restProps } = this.props;
        if (!options?.length) {
            return null;
        }
        return (
            <RadioGroup value={value} onChange={this.onChange} {...restProps}>
                {options.map(({ label, value, description }) => (
                    <FormControl key={value}>
                        <div style={{ display: 'flex', alignItems: 'center', fontSize: '14px', marginBottom: '15px' }}>
                            <MuiRadio color={color} {...RadioProps} value={value} />
                            <div>
                                {label}
                                {description && <div style={{ marginTop: '2px', fontSize: '12px' }}>{description}</div>}
                            </div>
                        </div>
                    </FormControl>
                
                ))}
            </RadioGroup>
        );
    }
}

export default LayerStylingButtons;