/* @flow */

import React from 'react';
import uuidv1 from 'uuid/v1';

import { id, name, documentationPanel } from 'app/utils/designer/process/settings/common/commonImportant';
import { executionListener } from 'app/utils/designer/process/settings/common/commonListeners';
import ModalFormGenearator from 'app/components/organisms/ModalFormGenearator/ModalFormGenearator';

const panels = [
    {
        header: 'Important',
        expanded: true,
        children: [
            id,
            name,
            {
                type: 'custom',
                properties: {
                    cid: uuidv1(),
                    title: 'Time',
                    name: 'dateTimerEvent',
                    Component: props => (
                        <ModalFormGenearator
                            {...props}
                            previewRender={value => value && value.date}
                            components={[
                                {
                                    type: 'typeahead',
                                    properties: {
                                        label: 'Type',
                                        options: [
                                            { label: 'Time date in ISO-8601', value: 'timeDate'},
                                            { label: 'Time duration (e.g. PT5M)', value: 'timeDuration' },
                                            { label: 'Time cycle (e.g. R3/PT10H)', value: 'timeCycle' },
                                        ],
                                        name: 'type',
                                        clearable: true,
                                        valueField: 'value',
                                        defaultValue: 'timeDate'
                                    },
                                    constraints: { required: true }
                                },
                                {
                                    type: 'text',
                                    properties: {
                                        label: 'Time date in ISO-8601',
                                        name: 'date',
                                        isVisible: data => data.type === 'timeDate',
                                    },
                                    constraints: { required: true }
                                },
                                {
                                    type: 'text',
                                    properties: {
                                        label: 'Time duration (e.g. PT5M)',
                                        name: 'date',
                                        isVisible: data => data.type === 'timeDuration',
                                    },
                                    constraints: { required: true }
                                },
                                {
                                    type: 'text',
                                    properties: {
                                        label: 'Time cycle (e.g. R3/PT10H)',
                                        name: 'date',
                                        isVisible: data => data.type === 'timeCycle',
                                    },
                                    constraints: { required: true }
                                }
                            ]}
                        />
                    ),
                }
            }

        ],
    },
    documentationPanel,
    {
        header: 'Listeners',
        children: [executionListener]
    },
];

export default {
    defaults: {},
    panels: settingValues => panels,
};
