/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Autocomplete } from '@mic3/platform-ui';

import { bind, debounce } from 'app/utils/decorators/decoratorUtils';
import { TypeaheadChipInitials } from 'app/components/organisms/Form/Typeahead/abstract/TypeaheadChip';
import { loadClassificationAutocomplete } from 'store/actions/classifications/classificationsActions';
import theme from 'app/themes/theme.default';
import { shallowEquals } from 'app/utils/utils';
import ResolveAvatarTypeaheadWrapper from 'app/components/organisms/Form/Typeahead/abstract/ResolveAvatarTypeaheadWrapper';

/**
 * Select one or more classification using lazy loading.
 */
class ClassificationTypeahead extends PureComponent<Object, Object> {

    static propTypes = {
        /* eslint-disable-next-line react/forbid-foreign-prop-types */
        ...Autocomplete.propTypes,
        filterBy: PropTypes.array,
        extraQueryFields: PropTypes.array,
        applicableOn: PropTypes.arrayOf(PropTypes.string),
    };

    static defaultProps = {
        ...Autocomplete.defaultProps,
    }

    constructor(props) {
        super(props);
        this.state = { options: [], isLoading: false };
        if(props.valueField) {
            this.suggest({ target: { value: '' }});
        }
    }
    componentDidUpdate(prevProps) {
        if (!shallowEquals(prevProps, this.props)) {
            this.setState({ options: [] });
        }
    }

    @bind
    optionTemplate(data: any) {
        const { id, name, color } = data;
        return ({
            ChipProps: {
                avatar: <TypeaheadChipInitials initials={name} />,
                style: {
                    backgroundColor: color,
                    color: theme.isLightColor(color) ? 'black' : 'white',
                },
            },
            label: `${name || 'Name not available'} (${id ? id.slice(0,8) : 'ID not available'})`,
        });
    };

    @bind
    @debounce()
    suggest(event: Object) {
        this.setState({ isLoading: true });
        const { valueField, excludeBy, value, loadOptions, filterBy, applicableOn, extraQueryFields } = this.props;
        const filters = [];
        const query = event.target.value;
        let excludes = [...(excludeBy || [])];
        if (query) {
            filters.push({
                or: [
                    { field: 'id', op: '=', value: query, cast: 'text' },
                    { field: 'name', op: 'contains', value: query },
                    { field: 'uri', op: 'contains', value: query },
                ],
            });
        }
        if (filterBy) {
            if (typeof filterBy === 'function') {
                filters.push(...filterBy(value));
            } else if (filterBy.length) {
                filters.push(...filterBy);
            }
        }
        if (applicableOn) {
            let value = [...applicableOn];
            if(!applicableOn?.includes('relationdefinition')) {
                value = ['entity', ...value];
            }
            filters.push({field: 'applicableOn', op: 'overlaps', value});
        }
        if (value) {
            const val = Array.isArray(value) ? value : [value];
            let orVal = [];
            if(valueField) {
                orVal = val.map(fieldVal => ({ field: valueField, op: '=', value: fieldVal }));
            } else {
                const selectedIds = val.map(({ id }) =>  id).filter(Boolean) || [];
                const excludedIdsFilter = [{ field: 'id', op: 'in', value: [...selectedIds, ...(this.props.selectedIds || [])] }];
                if(selectedIds?.length || this.props.selectedIds?.length){
                    excludes = [{ or: [excludedIdsFilter, ...excludes] }];
                }
            }
            if(orVal.length) {
                excludes.push({ or: orVal });
            }
        }

        loadOptions({
            startIndex: 0,
            stopIndex: 50,
            filterBy: filters,
            orderBy: [{ field: 'name', direction: 'asc nulls last' }],
            excludeBy: excludes,
        }, extraQueryFields).then((options) => {
            this.setState({ options, isLoading: false });
        }).catch((error) => {
            this.setState({ options: [], isLoading: false });
        });
    };

    render() {
        const { loadOptions, filterBy, placeholder, applicableOn, extraQueryFields, optionTemplate, ...typeaheadProps } = this.props; // eslint-disable-line no-unused-vars
        const { options, isLoading } = this.state;
        return <Autocomplete
            {...typeaheadProps}
            optionTemplate={optionTemplate || this.optionTemplate}
            suggest={this.suggest} placeholder={placeholder || 'Search for a class...'}
            options={options}
            isLoading={isLoading}
        />;
    }

};

const ClassificationTypeaheadWrapped = (props) => {
    return <ResolveAvatarTypeaheadWrapper {...props} Typeahead={ClassificationTypeahead} entityType="class" />;
};

export default connect(
    null, { loadOptions: loadClassificationAutocomplete },
)(ClassificationTypeaheadWrapped);
