/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Grid } from '@mic3/platform-ui';
import DraggableElement from 'app/components/molecules/Dnd/DraggableElement';
import RowTarget, { EmptyRowTarget } from 'app/components/molecules/Dnd/RowTarget';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import history from 'store/History';
import AppGroupAvatars from './AppGroupAvatars';
import MyAppIcon from 'app/components/ABox/MyApps/MyAppIcon';

const AppGridStyled = styled(Grid)`
    max-width: 1440px;
    padding: 2rem 1rem 1rem 1rem;
    margin: 0 auto;
`;

const RowStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
`;

const GroupItemStyled = styled(Grid)`
`;

class AppGroupDnd extends PureComponent<Object, Object> {

    static propTypes = {
        apps: PropTypes.object,
        uuid: PropTypes.string.isRequired,
    };

    static defaultProps = {
        root: true,
    }

    @bind
    onDrop(item: Object, index: number) {
        this.props.onDrop(item, this.props.uuid, index);
    }

    @bind
    onDelete(event: Object) {
        event.stopPropagation();
        const index = Number(event.target.dataset.index);
        const item = this.props.apps.children[index];
        this.props.onDelete(item);
    }

    @bind
    addGroup() {
        this.props.addApp({ type: 'group', condition: 'AND', children: []}, this.props.uuid, this.props.apps.children.length);
    }

    @bind
    openProcess(appId: string) {
        history.push(`/abox/process-start/${appId}`);
    };

    @bind
    @memoize()
    buildDropArea(apps: Object, isRoot: boolean) {
        const appsLength = apps.children.length;
        if (appsLength === 0) {
            return null;
        }
        const listItems = [...apps.children
            .map((app, i) => {
                const { uuid, children, type, name: appName, groupId, iconName, iconColor, id, iconType } = app;
                const isRoot = this.props.root;
                const target = (
                    <RowStyled>
                        {type === 'group'
                            ? (
                                <AppGroupAvatars onClick={this.props.openGroup(app)} key={uuid} apps={children} name={appName || `Group #${groupId}`} />
                            )
                            : (
                                <MyAppIcon
                                    key={uuid}
                                    onClick={() => this.openProcess(id)}
                                    iconColor={iconColor}
                                    iconName={iconName}
                                    iconType={iconType || 'mdi'}
                                    isRoot={isRoot}
                                    index={i}
                                    appName={appName}
                                    onDelete={this.onDelete}
                                />
                            )
                        }
                    </RowStyled>
                );
                return isRoot ? (
                    <Grid item key={`${uuid}${i}`} sm={isRoot ? 2 : 3} xs={3}>
                        <DraggableElement key={i} index={i} element={app}>
                            <RowTarget
                                index={i}
                                onDrop={this.onDrop}
                                label={'label'}
                                element={app}
                            >
                                {target}
                            </RowTarget>
                        </DraggableElement>
                    </Grid>
                ) : (
                    <GroupItemStyled item key={`${uuid}${i}`} sm={isRoot ? 2 : 3} xs={4}>
                        {target}
                    </GroupItemStyled>
                );
            }), isRoot && <EmptyRowTarget key={'-1'} index={appsLength} onDrop={this.onDrop} />];

        return (
            <AppGridStyled id="scroll-target" container alignItems="flex-start" justify="flex-start" >
                {listItems}
            </AppGridStyled>
        );
    };

    render() {
        const { root, apps } = this.props;
        const isEmptyList = apps.length < 1;
        return !isEmptyList ? (
            this.buildDropArea(apps, root)
        ) : null;
    }
};

export default AppGroupDnd;
