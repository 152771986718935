/* @flow */

import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BpmnViewer from 'bpmn-js/lib/Viewer';
import DiagramWrapper, { getDiagramStyles } from 'app/components/molecules/Diagram/DiagramWrapper';
import minimapModule from 'diagram-js-minimap';

const NoImageStyled = styled.p`
padding: 0 1rem 1rem 1rem;
`;

const ProcessDiagramCard = (({ value, height, ...restProps }: Object) => {
    useEffect(() => {
        if(value) {
            const bpmnViewer = new BpmnViewer({
                container: '#js-canvas',
                additionalModules: [
                    minimapModule
                ],
                ...getDiagramStyles(),
            });
            bpmnViewer.importXML(value);
        }
    }, [ height, value]);
    if (!value) {
        return <NoImageStyled>No image found related to this process</NoImageStyled>;
    }

    return (
        <DiagramWrapper>
            <div className="canvas" id="js-canvas" style={{ height: height }} />
        </DiagramWrapper>
    );

});

ProcessDiagramCard.propTypes = {
    definition: PropTypes.string,
};

ProcessDiagramCard.defaultProps = {
    height: 400
};

export default memo(ProcessDiagramCard);
