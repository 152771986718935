/* @flow */

import React, { PureComponent } from 'react';
import GoogleMapReact from 'google-map-react';
import { connect } from 'react-redux';
import { Button, Typography, MdiIcon, FormHelperText } from '@mic3/platform-ui';
import styled from 'styled-components';

import Geocode from 'app/utils/maps/geocodeUtils';
import { showToastr } from 'store/actions/app/appActions';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { get } from 'app/utils/lo/lo';
import { loadEntityGeotagButton } from 'store/actions/entities/entitiesActions';
import { loadAffectliConfiguration } from 'store/actions/app/appActions';

const MdiIconStyled = styled(MdiIcon)`
    position: absolute;
    top: -36px;
    left: -18px;
`;
const TypographyStyled = styled(Typography)`
  padding: 8px 0;
`;
const Wrapper = styled.div`
  margin: 10px 0;
`;

const RequiredStar = styled.span`
  color: red;
  margin-left: 5px;
`;

const Marker = (props) => {
    return <div style={{ position: 'relative' }}><MdiIconStyled name="map-marker" size={36} color="error" /></div>;
};

class GeotagButton  extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            user: this.props.value || null,
            remoteLocation: null,
            mapKey: 1,
        };
        this.reloadRemoteLocation();
    }

    componentDidMount() {
        this.props.loadAffectliConfiguration();
    }

    componentDidUpdate(prevProps) {
        const { remoteRef, value, data } = this.props;
        if(
            (remoteRef && get(data, `${remoteRef}.id`) !== get(prevProps, `data.${remoteRef}.id`))
            || prevProps.value !== value
        ) {
            this.reloadRemoteLocation();
        }
    }

    @bind
    async reloadRemoteLocation() {
        const { remoteRef, data, loadEntityGeotagButton, value } = this.props;
        const { id, type } = data[remoteRef] || {};
        let remoteLocation = null;
        if (id && type) {
            const response = await loadEntityGeotagButton(id, type);
            remoteLocation = get(response, 'primary.locationInfo.lat') && { lat: get(response, 'primary.locationInfo.latitude'), lng: get(response, 'primary.locationInfo.longitude')};
        }
        this.setState(state => ({ remoteLocation, mapKey: state.mapKey + 1, user: value }));
    }

    @bind
    handleOnClick() {
        Geocode.getCurrentLocation((response: Object) => {
            this.setState(state => ({
                user: {
                    lat: get(response, 'coords.latitude', null),
                    lng: get(response, 'coords.longitude', null)
                },
                remoteLocation: null,
                mapKey: state.mapKey + 1
            }), () => {
                this.props.onChange({ target: { name: this.props.name, value: this.state.user }});
            });
        }, (err: Object) => {
            if (err && err.code === 1) {
                this.props.showToastr({ severity: 'error', detail: `Please enable your location sharing.` });
            }
        });
    }

    @bind
    @memoize()
    getMarkers(user, remoteLocation) {
        const markers = [user];
        if(remoteLocation) {
            markers.push(remoteLocation);
        }
        return markers;
    }

    @bind
    renderPolyline({ map, maps }) {
        const { user, remoteLocation } = this.state;
        const markers = this.getMarkers(user, remoteLocation);
        /** Example of rendering geodesic polyline */
        const geodesicPolyline = new maps.Polyline({
            path: markers,
            geodesic: true,
            strokeColor: '#00a1e1',
            strokeOpacity: 1.0,
            strokeWeight: 4
        });
        geodesicPolyline.setMap(map);

        /** Example of rendering non geodesic polyline (straight line) */
        const nonGeodesicPolyline = new maps.Polyline({
            path: markers,
            geodesic: false,
            strokeColor: '#e4e4e4',
            strokeOpacity: 0.7,
            strokeWeight: 3
        });
        nonGeodesicPolyline.setMap(map);

        var bounds = new maps.LatLngBounds();
        for (const marker of markers) {
            bounds.extend(
                new maps.LatLng(marker.lat, marker.lng)
            );
        }
        map.fitBounds(bounds);
    }

    @bind
    @memoize()
    buildMarkers(user, remoteLocation) {
        const markers = this.getMarkers(user, remoteLocation);
        return markers.map((marker, index) => <Marker key={index + marker.lat} {...marker}  />);
    }

    render() {
        const { googleApiKey, label, error, helperText, required, disabled, onMouseDown } = this.props;
        const { user, remoteLocation, mapKey } = this.state;
        const markers = this.getMarkers(user, remoteLocation);
        const distance = Geocode.calcDistance(markers[0],markers[1]);
        return (
            <Wrapper>
                <Button onClick={this.handleOnClick} disabled={disabled} onMouseDown={onMouseDown}>
                    {label || 'My location'} {required ? <RequiredStar>*</RequiredStar>: null}
                </Button>
                {error && helperText &&
                   <FormHelperText error={error}>
                       * {helperText}
                   </FormHelperText>
                }
                {user && (
                    <>
                        {distance && <TypographyStyled>Distance: {distance}km</TypographyStyled>}
                        {googleApiKey &&
                            <div style={{ height: '300px', width: '100%' }}>
                                <GoogleMapReact
                                    key={mapKey}
                                    defaultZoom={15}
                                    defaultCenter={user}
                                    bootstrapURLKeys={{ key: googleApiKey, libraries: 'places' }}
                                    onGoogleApiLoaded={this.renderPolyline}
                                >
                                    {this.buildMarkers(user, remoteLocation)}
                                </GoogleMapReact>
                            </div>
                        }
                    </>
                )}
            </Wrapper>
        );
    }
};

export default connect(
    state => ({
        googleApiKey: state.app.config.googleApiKey
    }),
    { showToastr, loadEntityGeotagButton, loadAffectliConfiguration }
)(GeotagButton);
