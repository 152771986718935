/* @flow */
// We need to move this component to platform-ui
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { RadioGroup } from '@mic3/platform-ui';
import MuiRadio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { bind } from 'app/utils/decorators/decoratorUtils';
import { createEvent } from 'app/utils/http/event';

class RadioButtonsGroup extends PureComponent {
    static propTypes = {
        ...(MuiRadio || {}).propTypes,
        onChange: PropTypes.func.isRequired,
        labelPlacement: PropTypes.string,
        options: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                value: PropTypes.string.isRequired
            })
        )
    };

    static defaultProps = {
        color: 'primary'
    };

    @bind
    onChange(e) {
        const { name, onChange } = this.props;
        const { value } = e.target;
        const event = createEvent('change', { name, value });
        onChange && onChange(event);
    }

    render() {
        const { RadioProps, labelPlacement, color, value, onChange, options, disabled, ...restProps } = this.props;
        if (!options?.length) {
            return null;
        }
        return (
            <RadioGroup value={value} disabled={disabled} onChange={this.onChange} {...restProps}>
                {options.map(({ label, value }) => (
                    <FormControlLabel
                        key={value}
                        label={label}
                        value={value}
                        control={<MuiRadio color={color} {...RadioProps} />}
                        labelPlacement={labelPlacement || 'end'}
                        disabled={disabled}
                    />
                ))}
            </RadioGroup>
        );
    }
}

export default RadioButtonsGroup;
