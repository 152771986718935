/* @flow */

import { gql } from '@apollo/client';

export default gql`
	mutation publishScriptMutation($id: ID!){
		result: publishScript(id: $id) {
			id
		}
	}
`;
