/* @flow */

// $FlowFixMe
import React, { useMemo } from 'react';
import styled from 'styled-components';

import Button from 'app/components/atoms/Designer/Button';

const ImgStyled = styled.img`
    object-fit: cover;
    ${({ height, width, originalSize, fullWidth }) => {
    if(originalSize && fullWidth) {
        return 'width: 100%; height: inherit;';
    }
    if(!originalSize) {
        return `
            height: ${height}px;
            width: ${width}px;
    `;
    }
    return 'width: inherit; height: inherit;';
}}
    ${({ rounded }) => rounded && 'border-radius: 50%;'}
`;
const ButtonStyled = styled(Button)`
    ${({ rounded, ...rest }) => rounded && 'border-radius: 50% !important;'}
`;

export const Image = ({ actionType, href, to, onClick, rounded, ...restProps}: Object) => {
    const ImageComponent = useMemo(() => () => <ImgStyled rounded={rounded} {...restProps} />, [rounded, restProps]);
    if(actionType) {
        return (
            <ButtonStyled actionType={actionType} rounded={rounded?1:0} variant="text" disableFocusRipple disableElevation component="a" href={href} to={to} onClick={onClick}>
                <ImageComponent />
            </ButtonStyled>
        );
    }

    return (
        <ImageComponent />
    );
};

export default Image;
