/* @flow */

import styled from 'styled-components';
import PropTypes from 'prop-types';
// UI IMPORTS

// STYLE IMPORTS
const ListItemStyle = styled.div`
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    ${({ raised, theme }) => (theme && raised ? `
      box-shadow: ${theme.shadow.boxShadow2DP};
      padding: .5rem;
      background: ${theme.color.paper}; 
      margin-bottom: 1rem;
    ` : 'padding: .5rem 0;')};
    ${({ small }) => (small ? 'padding: 0; font-size: 0.8rem' : '')};
`;

ListItemStyle.propTypes = {
    onClick: PropTypes.func,
    raised: PropTypes.bool,
    small: PropTypes.bool,
    children: PropTypes.any
};

export default ListItemStyle;
