/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DotMenu from 'app/components/molecules/DotMenu/DotMenu';
import Loader from 'app/components/atoms/Loader/Loader';
import history from 'store/History';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { closeSidebar } from 'store/actions/sidebar/sidebarActions';
import { mediaBreakpoints } from 'app/themes/breakpoints';
import { setActions, setSubTitle } from 'store/actions/sidebar/sidebarActions';
import TeamAboutTab from 'app/containers/Admin/Teams/Details/Tabs/TeamAboutTab';
import TeamHistoryTab from 'app/containers/Admin/Teams/Details/Tabs/TeamHistoryTab';
import { loadTeam } from 'store/actions/admin/teamsActions';
import { shrinkSidebar, expandSidebar } from 'store/actions/sidebar/sidebarActions';
import Chat from 'app/components/organisms/Chat/Chat';
import PageNotAllowed from 'app/containers/ErrorPages/PageNotAllowed';

class TeamSidebar extends PureComponent<Object, Object> {
    static propTypes = {
        id: PropTypes.string,
        type: PropTypes.string,
        title: PropTypes.string,
        isOpen: PropTypes.bool,
        // from redux
        isLoading: PropTypes.bool,
        team: PropTypes.object
    };

    constructor(props: Object) {
        super(props);
        this.initData();
    }

    componentDidUpdate(prevProps) {
        const { id } = this.props;
        if (id && prevProps?.id !== id) {
            this.initData();
        }
    }

    @bind
    setActions(expanded) {
        this.props.setActions(this.dotMenu(expanded));
    }

    @bind
    setSubTitle(name) {
        this.props.setSubTitle(name);
    }

    initData() {
        const { id, isOpen, isLoading, team, expanded } = this.props;
        if (!isOpen) {
            return;
        }
        this.setActions(expanded);
        if (isLoading) {
            return;
        }
        const loaded = team || {};
        if (loaded.id === id) {
            this.setSubTitle(team.name);
            return;
        }
        this.props.loadTeam(id);
    }

    @bind
    onDotMenuClick(title) {
        if (['Collapse', 'Expand'].includes(title)) {
            const { expanded, expandSidebar, shrinkSidebar } = this.props;
            const action = expanded ? shrinkSidebar : expandSidebar;
            action();
        }
        if (title === 'Go to details') {
            this.goToDetails();
        }
    }

    goToDetails() {
        const { windowWidth, id, shrinkSidebar, closeSidebar } = this.props;
        history.push(`/teams/${id}`);
        if (!windowWidth < mediaBreakpoints.sm) {
            shrinkSidebar();
        } else {
            closeSidebar();
        }
    }

    @bind
    @memoize()
    dotMenu(expanded: boolean) {
        return (
            <DotMenu
                key={13}
                onItemClick={this.onDotMenuClick}
                items={[
                    { name: expanded ? 'Collapse' : 'Expand', icon: expanded ? 'arrow-collapse' : 'open-in-new' },
                    { name: 'Go to details', icon: 'login-variant' }
                ].filter(Boolean)}
            />
        );
    }

    render() {
        const { isLoading, title, team, isOpen, id } = this.props;
        const isLoaded = team;
        if (!isLoading && team === null) {
            return <PageNotAllowed padded permissionError={true} title={`Team. (ID:${id})`} />;
        }
        return (
            isOpen && (
                <>
                    {isLoading && <Loader absolute backdrop />}
                    {isLoaded && title === 'About' && <TeamAboutTab isSidebar details={team} />}
                    {isLoaded && title === 'History' && <TeamHistoryTab id={team.id} />}
                    {isLoaded && title === 'A-Live' && <Chat id={team.id} type="team" />}
                </>
            )
        );
    }
}

export default connect(
    (state: Object, ownProps: Object) => ({
        team: state.admin.teams.details.data,
        isLoading: state.admin.teams.details.isLoading,
        isOpen: state.sidebar.isOpen,
        expanded: state.sidebar.expanded,
        windowWidth: state.global.window.width,
        actions: state.sidebar.actions
    }),
    { shrinkSidebar, expandSidebar, loadTeam, closeSidebar, setActions, setSubTitle }
)(TeamSidebar);
