import React, { useRef, useCallback } from 'react';
import { useToggle } from 'app/utils/hook/hooks';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { cut } from 'app/utils/string/string-utils';
import { Menu, IconButton, MdiIcon, Slider, Tooltip, Box } from '@mic3/platform-ui';
import MenuItem from 'app/components/molecules/Menu/MenuItem';
import cesiumLayerConfig from 'app/config/cesiumLayerConfig';
import { toggleAddPinBtn } from 'store/actions/maps/situationalAwarenessActions';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'app/utils/utils';

const StyledBox = styled(Box)`
    display: flex;
    align-items: center;
`;
const OpacityIconButton = styled(IconButton)`
    font-size: 1rem !important;
    width: 30px !important;
    height: 30px !important;
    margin-right: 12px !important;
    padding: 24px !important;
    color: ${({theme})=>theme.material.colors.text.secondary};
`;

const SliderWrapper = styled(Slider)`
    width: 150px !important;
`;

const VisibilityIcon = styled(MdiIcon)`
    cursor: pointer;
    padding: 24px;
    width: 30px;
    height: 30px;
    &:before{
        color: ${({theme})=>theme.material.colors.text.secondary};
    }
    &:hover{
        border-radius: 50%;
        background-color: ${({theme})=> theme.material.colors.background.fields};
    }
`;

const BuildMoreMenu = ({ map, title, mapId, layer, onLayerRemove, openLayerSidebar, entityTypes, canEdit }: Object) => {
    const anchorEl = useRef(null);

    const { values_ } = layer || {};
    const { attributes } = values_ || {}; 
    const { subtitle, id, layerType, type } = attributes || {}; 

    const [isOpen, toggleMenu] = useToggle(false);
    const dispatch = useDispatch();

    const handleMenuItemClick = useCallback(
        (event, title) => {
            event.stopPropagation();
            dispatch(toggleAddPinBtn(false)); // Remove the add pin button
            switch (title) {
                case 'About':
                    return openLayerSidebar({ id, title: layerType, type, layer, mapId, map, layerName: title });
                case 'Zoom':
                    return map && map.zoomToMapLayer && map.zoomToMapLayer(layer);
                case 'Remove':
                    onLayerRemove({ id, name: values_?.title || values_?.attributes?.name });
                    return toggleMenu();
                default:
                    break;
            }
        },
        [map, id, layer, layerType, mapId, onLayerRemove, openLayerSidebar, toggleMenu, type, dispatch, values_]
    );

    return (
        <>
            <IconButton buttonRef={anchorEl} onClick={toggleMenu}>
                <MdiIcon name='dots-vertical' />
            </IconButton>
            <Menu open={isOpen} anchorEl={anchorEl.current} onClose={toggleMenu}>
                <MenuItem onClick={(e) => handleMenuItemClick(e, 'About')} name={'About Layer'} icon={'information-outline'} />
                {subtitle === 'entities' && (
                    <MenuItem
                        onClick={(e) => handleMenuItemClick(e, 'Zoom')}
                        name={`Zoom to layer ${cut(title, 16)}`}
                        icon={'magnify-plus-outline'}
                    />
                )}
                {canEdit && <MenuItem onClick={(e) => handleMenuItemClick(e, 'Remove')} name={'Remove Layer'} icon={'delete'} />}
            </Menu>
        </>
    );
};

const BuildOpacityMenu = ({ name, opacity: op, onOpacityChange, type }: Object) => {
    const anchorEl = useRef(null);
    const [isOpen, toggleMenu] = useToggle(false);
    const opacity = op === 100 ? 1 : op; // Workaround: till we change the default value of opacity from 100 to 1 on back end.
    const isIonLayer = cesiumLayerConfig?.cesiumLayer?.isCesiumLayer(type);
    return (
        <>
            {' '}
            {!isIonLayer && (
                <Tooltip arrow title={'Change layer opacity'} placement='top-start'>
                    <span>
                        <OpacityIconButton buttonRef={anchorEl} onClick={toggleMenu}>
                            {opacity * 100}%
                        </OpacityIconButton>
                    </span>
                </Tooltip>
            )}
            <Menu open={isOpen} anchorEl={anchorEl.current} onClose={toggleMenu}>
                <MenuItem onClick={(event) => event.stopPropagation()}>
                    <SliderWrapper name={name} value={opacity} onChange={onOpacityChange} min={0} max={1} step={0.1} />
                </MenuItem>
            </Menu>
        </>
    );
};

const LayerListItemActions = (props: Object) => {
    const { layer, map, mapId, onLayerRemove, toggleMapLayer, onOpacityChange, openLayerSidebar, entityTypes, canEdit, item } = props;
    const { values_ } = layer || {};  
    const { visible, title, opacity, name, attributes } = isEmpty(values_) ? item : values_; 
    const { id, type } = attributes || {}; 
    if (isEmpty(values_) && isEmpty(item)) {
        return null;
    }
    const layerId = id || item?.id;
    let visibility = visible;
    if (title) {
        const entityLayer = map.findLayerById(layerId);
        visibility = entityLayer?.values_?.visible ?? visible;
    }

    return (
        <StyledBox>
            <BuildOpacityMenu 
                name={title} 
                opacity={opacity} 
                onOpacityChange={(event) => onOpacityChange(layerId, event)} 
                type={type} 
            /> 
            <Tooltip arrow title={`Click on it to ${visibility ? 'hide' : 'show'} '${title}' layer`} placement='top-start'>
                <span>
                    <VisibilityIcon
                        name={visibility ? 'eye' : 'eye-off'}
                        onClick={(event) => toggleMapLayer(event, 'allLayers', title, !visibility, name, id)}
                    />
                </span>
            </Tooltip>
            <BuildMoreMenu
                map={map}
                title={title}
                canEdit={canEdit}
                mapId={mapId}
                onLayerRemove={onLayerRemove}
                layer={layer}
                openLayerSidebar={openLayerSidebar}
                entityTypes={entityTypes}
            />
        </StyledBox>
    );

};

LayerListItemActions.propTypes = {
    layer: PropTypes.object.isRequired,
    map: PropTypes.object.isRequired,
    onLayerRemove: PropTypes.func.isRequired,
    toggleMapLayer: PropTypes.func.isRequired,
    onOpacityChange: PropTypes.func.isRequired,
    openLayerSidebar: PropTypes.func.isRequired,
};

export default LayerListItemActions;
