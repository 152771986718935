/* @flow */
import { combineReducers } from 'redux';

import { LOAD_PERSON_LIST_VIEW_STARTED, LOAD_PERSON_LIST_VIEW, CREATE_PERSON_STARTED, CREATE_PERSON } from 'store/actions/entities/peopleActions';
import { dataTableReducer, loadDataReducer, keepDataAtStart } from 'app/utils/redux/reducer-utils';

export default combineReducers<Object, Object>({
    create: loadDataReducer(CREATE_PERSON_STARTED, CREATE_PERSON),
    list: dataTableReducer(LOAD_PERSON_LIST_VIEW_STARTED, LOAD_PERSON_LIST_VIEW, keepDataAtStart)
});
