/* @flow */

import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import debouncePromise from 'p-debounce';
import { connect } from 'react-redux';
import styled, { withTheme } from 'styled-components';

import Container from 'app/components/atoms/Container/Container';
import HeaderBar from 'app/components/molecules/HeaderBar/HeaderBar';
import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import Geocode from 'app/utils/maps/geocodeUtils';
import Immutable, { set } from 'app/utils/immutable/Immutable';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import Loader from 'app/components/atoms/Loader/Loader';
import ListItem from 'app/components/molecules/List/ListItem';
import Breadcrumbs from 'app/components/organisms/Breadcrumbs/Breadcrumbs';
import AboutMap, { aboutMapMap } from 'app/containers/Maps/EntityAboutMap/AboutMap';
import { get } from 'app/utils/lo/lo';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { addressDefinitions } from 'app/components/Forms/LocationForm/LocationForm';
import { Button, Checkbox, CircularProgress } from '@mic3/platform-ui';
import UploadSvg from 'app/containers/Maps/EntityAboutMap/UploadSvg';
import { setShowBack } from 'store/actions/leftPanel/leftPanelActions';
import Help from 'app/utils/designer/form/settings/common/Help';

import { loadEntity, updateEntity } from 'store/actions/entities/entitiesActions';
import { Icon, Style } from 'ol/style';
import { getImageCanvas } from 'app/components/molecules/Map/EntityPin/entityPinStyle';
import { toLonLat } from 'ol/proj';
import * as turf from '@turf/turf';
import { getSvgSource } from 'app/utils/maps/layer/layerUtils';
import ProjectionTypeahead from 'app/components/organisms/Form/Typeahead/ProjectionTypeahead';

import { updateEntity3dModel, uploadGeometryFile, deleteEntity3dModel } from 'store/actions/maps/situationalAwarenessActions';
import Upload3dModel from 'app/containers/Maps/EntityAboutMap/Upload3dModel';
import UploadGeometry from 'app/containers/Maps/EntityAboutMap/UploadGeometry';
import { isModelUploaded, getSvgPriority, clickFile, imgToBlob } from 'app/utils/maps/layer/layerUtils';
import { rocketHost } from 'app/utils/env';
import { getOnlyUpdatedData } from 'app/utils/app/appUtils';
import { setDocumentTitle } from 'store/actions/app/appActions';

const WAIT_INTERVAL = 1000;

const IdSpan = styled.span`
    color: ${({ theme }) => theme.material.colors.priority.disabled};
`;

const StyledContent = styled(ContentArea)`
    ${({ isFullScreen }) =>
    isFullScreen
        ? `
    overflow: hidden;
    div[class*='Container__'] {
        padding: 0px !important;
        max-width: calc(100%) !important;
        margin: 0 !important;
    }`: ''}
`;

class EntityLocation extends PureComponent<Object, Object> {
    static propTypes: Object = {
        details: PropTypes.object,
        deleteEntity3dModel: PropTypes.func.isRequired
    };

    constructor(props: Object) {
        super(props);
        this.state = {
            locationInfo: Immutable(this.setLocationForm(props.details)),
            isLoading: false,
            uploadSvgKey: 0,
            mapBoxKey: 0
        };
        props.setShowBack(false);
    }

    /**
     * @override
     */
    componentDidUpdate(prevProps: Object, prevState: Object) {
        const { details, leftPanelBack, setShowBack, setDocumentTitle, fromSidebar } = this.props;
        const name = details?.name;
        if( !fromSidebar && name){
            setDocumentTitle(name + ' - Location');
        }
        if (details && prevProps.details !== details) {
            this.setState({ locationInfo: Immutable(this.setLocationForm(details)) }, this.refreshMapBox);
        }
        if (leftPanelBack) {
            setShowBack(false);
        }
    }

    @bind
    refreshMapBox() {
        this.setState(prevState => ({ mapBoxKey: prevState.mapBoxKey + 1 }));
    }

    @bind
    setLocationForm(details: Object = {}) {
        const { type, primary, iconColor, iconName, iconType, _geoJson, svg, svgGraphic, svgTransformation, modelHeading, modelScaling } = details || {};
        const { locationInfo } = primary || {};
        return {
            ...(locationInfo || {}),
            type,
            field: get(locationInfo, 'field', 'DegDec'),
            iconColor: iconColor || '#00BCD4',
            iconName,
            iconType,
            _geoJson,
            svg,
            svgGraphic,
            svgTransformation,
            modelScaling: modelScaling || details?.primaryClass?.entityModelScaling || 1,
            modelHeading: modelHeading || details?.primaryClass?.entityModelHeading || 50,
            iconSelectValue: iconName ? { value: iconName, type: iconType, label: iconName } : null
        };
    }

    @bind
    checkAddress(info) {
        const { locationInfo } = this.state;
        let updatedLocationInfo = info ? info : locationInfo;
        const adresString = this.getAddressInput(updatedLocationInfo);
        Geocode.fromAddress(adresString).then((response) => {
            const { lat, lng } = get(response, 'data.result.address[0].geometry.location', {}) || {};
            const newLat = lat;
            const newLong = lng;
            if (newLat && newLong) {
                const { sync_with_position } = updatedLocationInfo;
                if (sync_with_position) {
                    const { latitude, longitude, lat, long, location, ...rest } = updatedLocationInfo;
                    updatedLocationInfo = { ...rest, ...this.setDDToDMS({ latitude: newLat, longitude: newLong }) };
                    return this.handleLatLongChange(updatedLocationInfo);
                } else {
                    this.setState({ isLoading: true });
                    this.getnSetAddressFromLatLng(newLat, newLong, updatedLocationInfo);
                }
            } else {
                // eslint-disable-next-line no-console
                console.log('location not found');
            }
        });
    }

    locationRef: Object = React.createRef();

    @bind
    cancelMove() {
        const { details } = this.props;
        const { locationInfo } = details?.primary || {};
        const { longitude, latitude } = locationInfo;
        let updatedLocationInfo = locationInfo;
        updatedLocationInfo = set(updatedLocationInfo, 'longitude', longitude);
        updatedLocationInfo = set(updatedLocationInfo, 'latitude', latitude);
        this.setState({ locationInfo: updatedLocationInfo });
    }

    @bind
    fieldDefinitions() {
        const { id, details } = this.props;
        const { primaryClass } = details || {};
        const isModel = isModelUploaded(details, primaryClass);
        const isInherited = primaryClass?.entityModel3d || false;
        const { uploadSvgKey } = this.state;
        const addressOnlyFields = true;
        return [
            {
                type: 'custom',
                properties: {
                    name: 'location',
                    Component: props => (
                        <AboutMap
                            cid={id}
                            key={this.state.mapBoxKey}
                            cancelMove={this.cancelMove}
                            {...props}
                            primaryClass={primaryClass}
                            onFormSubmit={this.onFormSubmit}
                        />
                    )
                }
            },
            {
                type: 'panel',
                properties: {
                    header: 'GPS position and Address',
                    expanded: false
                },
                children: [
                    {
                        type: 'custom',
                        properties: {
                            label: '',
                            defaultValue: true,
                            name: `sync_with_position`,
                            Component: props => (
                                <ListItem
                                    component={<Checkbox name={props.name} value={props.value} onClick={props.onChange} onChange={props.onChange} />}
                                    title={'Synchronise GPS and address position'}
                                    subTitle={'Changes made to the GPS position will be reflected on the entity\'s address and vice versa'}
                                />
                            )
                        }
                    },
                    {
                        type: 'typeahead',
                        properties: {
                            label: 'Location format',
                            name: 'field',
                            defaultValue: 'DegDec',
                            options: [
                                { label: 'DegDec', value: 'DegDec' },
                                { label: 'DMS', value: 'DMS' },
                                { label: 'MinDec', value: 'MinDec' }
                            ]
                        }
                    },
                    {
                        type: 'number',
                        properties: {
                            label: 'Latitude',
                            name: 'latitude',
                            isVisible: (data: Object) => data.field === 'DegDec'
                        },
                        constraints: {
                            numericality: {
                                greaterThan: -90,
                                lessThanOrEqualTo: 90
                            }
                        }
                    },
                    {
                        type: 'number',
                        properties: {
                            label: 'Longitude',
                            name: 'longitude',
                            isVisible: (data: Object) => data.field === 'DegDec'
                        },
                        constraints: {
                            numericality: {
                                greaterThan: -180,
                                lessThanOrEqualTo: 180
                            }
                        }
                    },
                    {
                        type: 'number',
                        properties: {
                            label: 'Latitude Degrees °',
                            name: 'lat.degrees',
                            isVisible: (data: Object) => data.field === 'DMS' || data.field === 'MinDec'
                        },
                        constraints: {
                            numericality: {
                                greaterThan: -90,
                                lessThanOrEqualTo: 90
                            }
                        }
                    },
                    {
                        type: 'number',
                        properties: {
                            label: 'Latitude Minutes \'',
                            name: 'lat.minutes',
                            isVisible: (data: Object) => data.field === 'DMS' || data.field === 'MinDec'
                        },
                        constraints: {
                            numericality: {
                                greaterThanOrEqualTo: 0,
                                lessThanOrEqualTo: 60
                            }
                        }
                    },
                    {
                        type: 'number',
                        properties: {
                            label: 'Latitude Seconds "',
                            name: 'lat.seconds',
                            isVisible: (data: Object) => data.field === 'DMS'
                        },
                        constraints: {
                            numericality: {
                                greaterThanOrEqualTo: 0,
                                lessThanOrEqualTo: 60
                            }
                        }
                    },
                    {
                        type: 'typeahead',
                        properties: {
                            label: 'Latitude direction (N/S)',
                            name: 'lat.direction',
                            isVisible: (data: Object) => data.field === 'DMS' || data.field === 'MinDec',
                            options: [
                                { label: 'N', value: 'N' },
                                { label: 'S', value: 'S' }
                            ]
                        }
                    },
                    {
                        type: 'number',
                        properties: {
                            label: 'Longitude Degrees °',
                            name: 'long.degrees',
                            isVisible: (data: Object) => data.field === 'DMS' || data.field === 'MinDec'
                        },
                        constraints: {
                            numericality: {
                                greaterThan: -180,
                                lessThanOrEqualTo: 180
                            }
                        }
                    },
                    {
                        type: 'number',
                        properties: {
                            label: 'Longitude Minutes \'',
                            name: 'long.minutes',
                            isVisible: (data: Object) => data.field === 'DMS' || data.field === 'MinDec'
                        },
                        constraints: {
                            numericality: {
                                greaterThanOrEqualTo: 0,
                                lessThanOrEqualTo: 60
                            }
                        }
                    },
                    {
                        type: 'number',
                        properties: {
                            label: 'Longitude Seconds "',
                            name: 'long.seconds',
                            isVisible: (data: Object) => data.field === 'DMS'
                        },
                        constraints: {
                            numericality: {
                                greaterThanOrEqualTo: 0,
                                lessThanOrEqualTo: 60
                            }
                        }
                    },
                    {
                        type: 'typeahead',
                        properties: {
                            label: 'Longitude Direction (E/W)',
                            name: 'long.direction',
                            isVisible: (data: Object) => data.field === 'DMS' || data.field === 'MinDec',
                            options: [
                                { label: 'E', value: 'E' },
                                { label: 'W', value: 'W' }
                            ]
                        }
                    },
                    ...addressDefinitions(addressOnlyFields),

                    {
                        type: 'custom',
                        properties: {
                            label: '',
                            name: `validate_address`,
                            Component: props => (
                                <ListItem component={<Button onClick={() => this.checkAddress()}> Validate Address </Button>} title={''} subTitle={''} />
                            )
                        }
                    }
                ]
            },
            {
                type: 'panel',
                properties: {
                    header: 'Entity Digital Twin Configuration',
                    extended: false
                },
                children: [
                    {
                        type: 'panel',
                        properties: {
                            header: 'Iconography',
                            extended: false
                        },
                        children: [
                            {
                                type: 'iconSelect',
                                properties: {
                                    label: 'Icon',
                                    name: 'iconSelectValue',
                                    clearable: true
                                }
                            },
                            {
                                type: 'colorPicker',
                                properties: {
                                    label: 'Icon Color',
                                    name: 'iconColor'
                                }
                            }
                        ]
                    },
                    {
                        type: 'panel',
                        properties: {
                            header: '2D Visualisation',
                            extended: false
                        },
                        children: [
                            {
                                type: 'custom',
                                properties: {
                                    label: 'SVG',
                                    name: 'svg',
                                    cid: uploadSvgKey,
                                    Component: (props: Object) => <UploadSvg {...props} primaryClass={primaryClass} 
                                        deleteFile={this.deleteFile} 
                                        downloadFileAction={this.downloadFileAction}
                                    />,
                                    help: (
                                        <Help
                                            message="Supported formats are .svg"
                                        />
                                    )
                                }
                            }
                        ]
                    },
                    {
                        type: 'panel',
                        properties: {
                            header: '3D Visualisation',
                            extended: false
                        },
                        children: [
                            {
                                type: 'custom',
                                properties: {
                                    label: '3D Model',
                                    name: '3dModel',
                                    Component: (props: Object) => <Upload3dModel 
                                        {...props} 
                                        details={details} 
                                        deleteFile={this.deleteFile} 
                                        editable={true}
                                        downloadFileAction={this.downloadFileAction}
                                    />,
                                    help: (
                                        <Help
                                            message="Supported 3D formats are .glb and .gltf"
                                        />
                                    )
                                }
                            },
                            {
                                type: 'number',
                                properties: {
                                    label: 'Heading',
                                    name: 'modelHeading',
                                    isVisible: isModel,
                                    defaultValue: 50
                                },
                                constraints: { min: 0, max: 360 }
                            },
                            {
                                type: 'number',
                                properties: {
                                    label: `${ isInherited ? 'Scaling Inherited from entity type' : 'Scaling'}`,
                                    name: 'modelScaling',
                                    isVisible: isModel,
                                    defaultValue: 1
                                },
                                constraints: { min: 0, max: 100 }
                            }
                        ]
                    },
                    {
                        type: 'panel',
                        properties: {
                            header: 'Spatial Dimensions',
                            extended: false
                        },
                        children: [
                            {
                                type: 'custom',
                                properties: {
                                    label: 'Geometry file',
                                    name: 'geometry',
                                    Component: (props: Object) => <UploadGeometry {...props} 
                                        deleteFile={this.deleteFile}
                                        downloadFileAction={this.downloadFileAction}
                                    />
                                }
                            },
                            {
                                type: 'custom',
                                properties: {
                                    label: 'Projection',
                                    name: 'projection',
                                    Component: (props: Object) => (<ProjectionTypeahead {...props} />)
                                }
                            }
                        ]
                    },
                ]
            }
        ].filter(Boolean);
    }

    /**
     * Converting the decimal format to DMS
     * and saving it the object
     */
    @bind
    @memoize()
    setDDToDMS(location: Object) {
        const { latitude, longitude } = location;
        const lat = Geocode.convertDDtoDMS('latitude', latitude);
        const long = Geocode.convertDDtoDMS('longitude', longitude);
        location = set(location, 'lat', lat);
        location = set(location, 'long', long);
        return location;
    }

    /**
     * Emit a change to parent.
     * @param locationInfo
     */
    @bind
    handleChange(locationInfo: Object, variableData: Object) {
        const { name, value } = variableData;
        const { sync_with_position } = locationInfo;
        let updatedLocationInfo = locationInfo;
        if (name === 'sync_with_position' && value) {
            this.setState({ checked: value });
            return this.handleLatLongChange(updatedLocationInfo);
            // updatedLocationInfo = set(updatedLocationInfo, 'sync_with_position', this.state.checked);
        } else if (name === 'sync_with_address' && value) {
            updatedLocationInfo = set(updatedLocationInfo, 'sync_with_position', false);
        } else if (name === 'geometry' && value) {
            this.uploadGeometry(updatedLocationInfo, value);
            return;
        } else if (name === '3dModel' && value) {
            this.upload3DModel(value);
            return;
        } else if (['latitude', 'longitude', 'lat.', 'long.'].find(s => name.includes(s)) && sync_with_position) {
            return this.handleLatLongChange(updatedLocationInfo);
        } else if (name.includes('address') && sync_with_position) {
            updatedLocationInfo = set(updatedLocationInfo, 'sync_with_position', sync_with_position);
        } else if (name.includes('address') && !sync_with_position) {
            updatedLocationInfo = set(updatedLocationInfo, 'sync_with_position', sync_with_position);
            // return this.handleLatLongChange(updatedLocationInfo);
        } else if (name === 'address.country' && !value) {
            const address = { add_type: 'Physical', city: '', code: '', country: '', line1: '', line2: '', province: '' };
            updatedLocationInfo = { ...updatedLocationInfo, latitude: null, longitude: null, address };
        } else if (name === 'location' && sync_with_position) {
            const { latitude, longitude, lat, long, location, ...rest } = updatedLocationInfo;
            updatedLocationInfo = { ...rest, ...this.setDDToDMS(value) };
            return this.handleLatLongChange(updatedLocationInfo);
        } else if (name === 'location' && !sync_with_position) {
            const { latitude, longitude, lat, long, location, ...rest } = updatedLocationInfo;
            updatedLocationInfo = { ...rest, ...this.setDDToDMS(value) };
        } else if (name === 'field') {
            const { latitude, longitude, ...rest } = updatedLocationInfo;
            updatedLocationInfo = { ...rest, ...this.setDDToDMS({ latitude, longitude }) };
        } else if (name === 'latitude' || name === 'longitude') {
            if (!value) {
                updatedLocationInfo = set(updatedLocationInfo, '_geoJson', value);
            }
            updatedLocationInfo = set(updatedLocationInfo, name, value);
        } else if (name.includes('lat.')) {
            const { lat } = locationInfo;
            const latitude = Geocode.convertDMSToDD(lat.degrees, lat.minutes, lat.seconds, lat.direction);
            updatedLocationInfo = set(updatedLocationInfo, 'latitude', latitude);
        } else if (name.includes('long.')) {
            const { long } = locationInfo;
            const longitude = Geocode.convertDMSToDD(long.degrees, long.minutes, long.seconds, long.direction);
            updatedLocationInfo = set(updatedLocationInfo, 'longitude', longitude);
        } else if (name === 'svg') {
            this.switchDisplaySvgAndIcon(name, value);
            updatedLocationInfo = set(updatedLocationInfo, 'svgGraphic', null);
            updatedLocationInfo = set(updatedLocationInfo, name, value);
        } else if (name === 'svgGraphic') {
            this.switchDisplaySvgAndIcon(name, value);
            updatedLocationInfo = set(updatedLocationInfo, name, value);
            updatedLocationInfo = set(updatedLocationInfo, 'svg', null);
        } else if (name === 'iconSelectValue') {
            updatedLocationInfo = set(updatedLocationInfo, 'iconName', value?.value || null);
            updatedLocationInfo = set(updatedLocationInfo, 'iconType', value?.type || null);
        }
        else if(name === 'modelScaling' && value) {
            updatedLocationInfo = set(updatedLocationInfo, 'modelScaling', value);
        }
        else if(name === 'modelHeading' && value) {
            updatedLocationInfo = set(updatedLocationInfo, 'modelHeading', value);
        }
        this.setState({ locationInfo: updatedLocationInfo });
    }

    @bind
    async switchDisplaySvgAndIcon(name: string, value: Object) {
        let svgStyle = null;
        const aboutMapLayer = aboutMapMap
            .getMap()
            .getLayers()
            .array_.find(layer => layer.values_.title === 'About Map layer');
        if (value?.type === 'graphic') {
            const src = getSvgSource({ svgGraphic: value });
            const img = getImageCanvas(src, 100, aboutMapMap.getMap());
            svgStyle = new Style({
                image: new Icon({
                    img: img,
                    imgSize: [100, 100],
                    anchor: [0.5, 0.4],
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'fraction',
                })
            });
        } else {
            svgStyle = new Style({
                image: new Icon({
                    opacity: 1,
                    img: getImageCanvas(getSvgSource({ svg: value }), 100, aboutMapMap.getMap()),
                    imgSize: [100, 100],
                    anchor: [0.5, 0.4],
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'fraction',
                })
            });
        }
        aboutMapLayer.setStyle(svgStyle);
    }

    @bind
    async uploadGeometry(locationInfo: Object, file: Object) {
        const { id, loadEntity } = this.props;
        const type = this.props?.details?.type;
        this.setState({ isLoading: true });
        const srid = this.props?.details?.primary?.locationInfo?.projection?.srid || 4326;
        const data = await this.props.uploadGeometryFile(this.props?.details?.type, this.props.id, file, srid);
        await this.updateModelInfo('geomFile', true);
        try {
            const entityDetail = await loadEntity(type, id);
            const centroid = await turf.centroid(entityDetail?._geoJson);
            const centroidGeom = toLonLat(centroid.geometry.coordinates);
            locationInfo = set(locationInfo, 'latitude', centroidGeom[1]);
            locationInfo = set(locationInfo, 'longitude', centroidGeom[0]);
            const geometry = get(data, 'data.uploadEntityGeometry._geoJson');
            locationInfo = set(locationInfo, 'geometry', geometry);
            this.setState({ locationInfo: locationInfo, isLoading: false });
        } catch {
            this.setState({ locationInfo: locationInfo, isLoading: false });
        }
    }

    @bind
    async upload3DModel(file) {
        const { id } = this.props;
        const type = this.props?.details?.type;
        if(file && id && type) {
            await this.props.updateEntity3dModel(type, id, file);
            await this.updateModelInfo('3dModel', true);
        }
    }

    @bind
    downloadFileAction(fileDetails) {
        const { id, type, primaryClass, name, _geoJson } = this.props.details;
        const host = rocketHost.split('/chat')[0];
        let url = '';
        if(fileDetails.fileType === 'is3d') { 
            url =  `/media/entity/${type}/${id}/model3d/download`; 
            const fileURL = `https://${host}` +  url;
            // const fileURL = `http://localhost:3000${url}`;
            window.open(fileURL, '_blank');          
        } else if(fileDetails?.fileType === 'svgFile') {
            const { svg, svgGraphic } = getSvgPriority(this.props.details, primaryClass);
            const imageSrc = getSvgSource({ svg, svgGraphic });
            if(imageSrc?.startsWith('/')) {
                const fileURL = `https://${host}` +  imageSrc.split('?')[0];
                // const fileURL = `http://localhost:3000${imageSrc?.split('?')[0]}`;
                fetch(fileURL)
                    .then(response => response.blob())
                    .then(imageBlob => {
                        clickFile(imageBlob, `${name}.svg`);
                    });
                
            } else {
                const blob = imgToBlob(imageSrc);
                blob && clickFile(blob, `${name}.svg`);
            }
        }
        else if(fileDetails?.fileType === 'geomFile') {
            let modifiedGeoJSON = {};

            if (_geoJson.type === 'GeometryCollection') {
                modifiedGeoJSON = {
                    type: 'FeatureCollection',
                    features: _geoJson.geometries.map((geometry) => ({
                        type: 'Feature',
                        geometry,
                        properties: {}
                    }))
                };
            } else {
                modifiedGeoJSON = _geoJson;
            }
            const modifiedGeoJSONString = JSON.stringify(modifiedGeoJSON);
            const file = new Blob([modifiedGeoJSONString], {type: 'text/plain'});
            clickFile(file, `${name}.geojson`);
        }
    }

    @bind
    async deleteFile(fileDetails){
        const { id, type, reloadList, internal } = this.props;
        const { primary } = this.props?.details || {};
        if(fileDetails?.fileType === 'is3d') {
            const data = { id, type, isClass: false } ;
            await this.props.deleteEntity3dModel(data);
        }
        else if(fileDetails?.fileType === 'geomFile') {
            const updatedPrimary = { ...(primary || {}), locationInfo: null };
            const entity = { id, type, geomAsEwkt: null, primary: updatedPrimary } ;
            this.props.updateEntity(entity, internal).then(async (resp) => {
                if (!(resp instanceof Error)) {
                    reloadList && (await reloadList());
                }
            });
        }
        else if(fileDetails?.fileType === 'svgFile')  {
            const entity = { id, type, svg: null, svgGraphic: null } ;
            this.props.updateEntity(entity, internal).then(async (resp) => {
                if (!(resp instanceof Error)) {
                    reloadList && (await reloadList());
                }
            });
        }
    }
    
    @bind
    updateModelInfo(model, modelValue) {
        const { id, type, reloadList, internal } = this.props;
        const { locationInfo } = this.state;
        const { location, iconColor, iconName, iconType, geom, svg, svgGraphic, modelHeading, modelScaling, ...rest } = locationInfo;
        const { primary } = this.props?.details || {};
        let updatedPrimary = {};
        if(model === 'geomFile') {
            updatedPrimary = { ...(primary || {}), locationInfo: { ...rest, 'geomFile': modelValue } };
        }
        else {
            updatedPrimary = { ...(primary || {}), locationInfo: { ...rest, '3dModel': modelValue } };
        }
        const entity = { id, type, primary: updatedPrimary } ;
        this.props.updateEntity(entity, internal).then(async (resp) => {
            if (!(resp instanceof Error)) {
                reloadList && (await reloadList());
            }
        });
    }

    @bind
    getnSetAddressFromLatLng(lat, long, info){
        let updatedLocationInfo = { ...info };
        Geocode.fromLatLong(lat, long).then(
            (response) => {
                const gAddress = get(response, 'data.result.address') || [];
                const address = Geocode.getAddress(gAddress);
                updatedLocationInfo = set(updatedLocationInfo, 'address', { add_type: 'Physical', ...address });
                this.setState({ locationInfo: updatedLocationInfo, isLoading: false });
            },
            (error) => {
                this.setState({ locationInfo: updatedLocationInfo, isLoading: false });
            }
        );        

    }
    handleLatLongChange = debouncePromise((updatedLocationInfo: Object) => {
        const lat = Number(get(updatedLocationInfo, 'latitude'));
        const long = Number(get(updatedLocationInfo, 'longitude'));
        this.setState({ isLoading: true, locationInfo: updatedLocationInfo });
        this.getnSetAddressFromLatLng(lat, long, updatedLocationInfo);
    }, WAIT_INTERVAL);

    handleAddressChange = debouncePromise((updatedLocationInfo: Object) => {
        const adresString = this.getAddressInput(updatedLocationInfo);
        this.setState({ isLoading: true });
        Geocode.fromAddress(adresString).then((response) => {
            const { lat, lng } = get(response, 'data.result.address[0].geometry.location', {}) || {};
            updatedLocationInfo = set(this.props.value, 'latitude', lat);
            updatedLocationInfo = set(updatedLocationInfo, 'longitude', lng);
            this.setState({ locationInfo: updatedLocationInfo, isLoading: false });
        });
    }, WAIT_INTERVAL);

    /**
     *
     */
    @bind
    @memoize()
    getAddressInput(updatedLocationInfo: Object) {
        const line1 = updatedLocationInfo.address['line1'];
        const line2 = updatedLocationInfo.address['line2'];
        const code = updatedLocationInfo.address['code'];
        const city = updatedLocationInfo.address['city'];
        const province = updatedLocationInfo.address['province'];
        const country = updatedLocationInfo.address['country'];
        return `${line1} ${line2} ${code} ${city} ${province} ${country}`;
    }

    @bind
    async onFormSubmit(event) {
        event.preventDefault();
        this.locationRef.current.isValidForm().then(async ({ data, errors }) => {
            if(errors)
                return;
            const { id, type, reloadList, internal, details, refreshMainSection } = this.props;
            const { locationInfo } = this.state;
            const { location, iconColor, iconName, iconType, geom, svg, svgGraphic, modelHeading, modelScaling, ...rest } = locationInfo;
            const { primary } = this.props?.details || {};
            const updatedPrimary = { ...(primary || {}), locationInfo: { ...rest } };
            let entity = null;
            const latitude = get(updatedPrimary, 'locationInfo.latitude');
            const longitude = get(updatedPrimary, 'locationInfo.longitude');
            if((details.iconColor !== iconColor && iconColor !== '#00BCD4') ||
                details?.iconName !== iconName || details?.svgGraphic?.id !== svgGraphic?.id || details?.svg !== svg) {
                entity = { iconType, iconColor, iconName, svgGraphic: svgGraphic?.id || null, svg,
                    primary: updatedPrimary, modelHeading: parseFloat(modelHeading), modelScaling };
                if (latitude && longitude) {
                    entity.geomAsEwkt = `SRID=4326;POINT(${longitude} ${latitude} 0)`;
                }
            } else {
                entity = { iconName, iconType, iconColor, primary: updatedPrimary, svg, geomAsEwkt: null, svgGraphic: svgGraphic?.id || null,
                    modelHeading: parseFloat(modelHeading), modelScaling };
                if (latitude && longitude) {
                    entity.geomAsEwkt = `SRID=4326;POINT(${longitude} ${latitude} 0)`;
                }
            }
            const updatedEntityData = getOnlyUpdatedData(details, entity);
            if(Object.keys(updatedEntityData)?.length) {
                // add the entity id and typ to update the relevant entity
                const _updatedEntityData = { ...updatedEntityData, id, type };
                this.props.updateEntity(_updatedEntityData, internal, refreshMainSection).then(async (resp) => {
                    if (!(resp instanceof Error)) {
                        reloadList && (await reloadList());
                    }
                });
            };
        });
    }

    @bind
    renderSaveButton() {
        const {
            userProfile: { isAdmin },
            isLoading,
            canEdit
        } = this.props;
        const canEditLocation = isAdmin || canEdit;
        if (!canEditLocation) {
            return null;
        }
        return isLoading || this.state.isLoading ? (
            <CircularProgress key={113} size={24} color="primary" />
        ) : (
            <Button key={113} onClick={this.onFormSubmit} color="primary" form="form" type="submit">
                Save
            </Button>
        );
    }

    @bind
    @memoize()
    buildBreadcrumbs(id, name, type) {
        return (
            <Breadcrumbs
                list={[
                    {
                        title: (
                            <ListItem
                                title={'Location'}
                                subTitle={
                                    <>
                                        <span>{name}</span>
                                        <IdSpan>{` #${id}`}</IdSpan>
                                    </>
                                }
                            />
                        )
                    }
                ]}
                withGoBack
            />
        );
    }

    /**
     * Lifecycle hook.
     * @returns {XML}
     */
    render(): Object {
        const { type, details, fromSidebar, isFullScreen, entityLoading, isCountriesLoading } = this.props;
        if (isCountriesLoading) {
            return <Loader absolute backdrop />;
        }
        const { locationInfo, isLoading } = this.state;
        const { id, name } = details || {};
        const components = this.fieldDefinitions();
        return (
            <Fragment>
                {entityLoading ? <Loader absolute backdrop /> : <>
                    <HeaderBar left={fromSidebar ? [] : this.buildBreadcrumbs(id, name, type)} right={[this.renderSaveButton()]} />
                    <StyledContent withHeader isFullScreen={isFullScreen}>
                        <Container width={isFullScreen ? '1200' : '1024'}>
                            {isLoading && <Loader absolute backdrop />}
                            <FormGenerator components={components} data={locationInfo} onChange={this.handleChange} ref={this.locationRef} />
                        </Container>
                    </StyledContent></> }
            </Fragment>
        );
    }
}

export default connect(
    (state, ownProps) => ({
        entityLoading: ownProps.internal ? state.entities.sidebar.detailsInternal.isLoading : state.entities.sidebar.details.isLoading,
        userProfile: state.user.profile,
        isFullScreen: state.maps.situationalAwareness.map.mapFullScreen,
        leftPanelBack: state.leftPanel.state.showBack,
        isCountriesLoading: state.entities.countries.isLoading
    }),
    { updateEntity3dModel, updateEntity, loadEntity, setShowBack, uploadGeometryFile, deleteEntity3dModel, setDocumentTitle }
)(withTheme(EntityLocation));
