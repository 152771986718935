/* @flow */

import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { CardContent, Box, Typography, Grid, CardHeader, Card, Link, Tooltip } from '@mic3/platform-ui';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import Icon from 'app/components/atoms/Icon/Icon';
import { showToastr } from 'store/actions/app/appActions';
import { copyToClipboard } from 'app/utils/classification/classificationUtils';
import ResizableListItem from 'app/components/molecules/VirtualList/ResizableListItem';
import { fromNow, displayByKind } from 'app/utils/date/date';
import EventActionRenderer from 'app/components/molecules/Grid/Renderers/EventAction/EventActionRenderer';
import StatusRenderer from 'app/components/molecules/Grid/Renderers/Status/StatusRenderer';
import ButtonIcon from 'app/components/molecules/ButtonIcon/ButtonIcon';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { openEventsActionsSidebar } from 'store/actions/stream/eventsActionsSidebarActions';
import { get } from 'app/utils/lo/lo';


const CardContainer = styled.div`
    width: 98%;
    max-width: 1000px;
    margin: 0 auto;
`;

const CardHeaderStyled = styled(CardHeader)`
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
`;

const SubheaderStyled = styled(Typography)`
&& {
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
display: block;
display: flex;
align-items: center;
color: ${({ theme }) => theme.material.colors.disabled.color} !important;
`;
const GridItem = styled.span`
    flex-grow: 1;
`;

const MoreMenuItems = styled.div`
    display: flex;
`;

const ActionsContainer = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: ${({ isProcessIcon }) => (isProcessIcon ? '100px' : '70px')};
`;

const LinkIcon = styled(Icon)`
    margin-left: 4px;
    cursor: pointer;
    &::before {
        color: ${({ theme }) => theme.material.colors.disabled.color} !important;
    }
`;

const useCardInfoStyles = makeStyles(theme => ({
    root: {
        marginRight: (props: Object) => (props.display === 'inline' ? 'margin-right: 16px;' : 'margin-right: 0; margin-bottom: 16px'),
        fontSize: '12px',
        position: 'relative',
        '& .MuiIcon-root': {
            fontSize: '14px !important',
            height: '14px !important',
            width: '14px !important',
            lineHeight: '14px !important'
        },
        '& .MuiTypography-root': {
            fontSize: '12px'
        }
    },
    body: {
        '& span': {
            fontWeight: '500'
        }
    },
    divider: {
        position: 'relative',
        top: '1px',
        right: '-8px',
        marginRight: '18px',
        display: 'inline-block',
        width: '1px',
        height: '10px',
        background: 'rgba(255, 255, 255, 0.12)'
    }
}));
const ListCardInfo = ({ children, display, noDivider }: Props) => {
    const classes = useCardInfoStyles({ display });
    return (
        <Box className={classes.root} component="div" display={display}>
            <Typography className={classes.body} variant="body1" display="inline">
                {children}
            </Typography>
            {display === 'inline' && !noDivider && <span className={classes.divider} />}
        </Box>
    );
};

ListCardInfo.defaultProps = {
    display: 'inline'
};

class EventsListCard extends PureComponent<Object, Object> {
    state = {
        isHover: false
    };

    @bind
    @memoize()
    renderHoverActions(isHover) {
        if (!isHover) {
            return null;
        }
        const { resetView, data } = this.props;
        const processDefinitions = get(data, 'eventType.metadata.actions') || [];
        const isProcessIcon = !!processDefinitions?.length;
        return (
            <ActionsContainer isProcessIcon={isProcessIcon}>
                <EventActionRenderer disableHoverEffect canEdit={true} refresh={resetView} data={data} />
            </ActionsContainer>
        );
    }

    @bind
    onMouseEnter() {
        this.setState({ isHover: true });
    }

    @bind
    onMouseLeave() {
        this.setState({ isHover: false });
    }

    @bind
    onThreeDotClick(e, device) {
        e.stopPropagation();
        const { data, type, resetView: reloadList } = this.props;
        const typeEntyty = 'Card';
        const params = { id: data.id, data, type, title: 'Actions', reloadList, device, typeEntyty};
        this.props.openEventsActionsSidebar(params);
    }

    @bind
    copyIdToClipBoard(e, id: string) {
        e.stopPropagation();
        copyToClipboard(id)
            .then(() => {
                this.props.showToastr({ severity: 'success', detail: 'ID copied to clipboard' });
            })
            .catch(() => {
                this.props.showToastr({ severity: 'error', detail: 'ID could not copied to clipboard' });
            });
    }

    @bind
    copyLinkToClipBoard(e, data ) {
        e.stopPropagation();
        const { id, time } = data || {};
        copyToClipboard(`${window.location.origin}/#/events/${id}/${time}`)
            .then(() => {
                this.props.showToastr({ severity: 'success', detail: 'Link copied to clipboard' });
            })
            .catch(() => {
                this.props.showToastr({ severity: 'error', detail: 'Link could not copied to clipboard' });
            });
    }


    render() {
        const { data, index, resize, style } = this.props;
        const { id, device, status, eventType, modifiedDate, createdDate, createdBy, modifiedBy, time } = data;
        return (
            <ResizableListItem style={style} key={index} index={index} resize={resize} padding={15}>
                {resizeRow => (
                    <CardContainer onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
                        <Card>
                            <Grid container alignItems="center" wrap="nowrap">
                                <GridItem>
                                    <CardHeaderStyled
                                        disableTypography
                                        avatar={<StatusRenderer value={status} />}
                                        title={
                                            <Grid container spacing={2}>
                                                <Link to={`/events/${id}/${time}`}>
                                                    <Typography>{device?.name || 'No Name'}</Typography>
                                                </Link>
                                            </Grid>
                                        }
                                        subheader={
                                            <Grid container spacing={2}>
                                                <SubheaderStyled
                                                    color="secondary"
                                                    variant="caption"
                                                    component="span"
                                                    noWrap
                                                    title="Copy ID to clipboard"
                                                    onClick={e => this.copyIdToClipBoard(e, id)}
                                                >
                                                    {`#${id.slice(0, 8)}`}
                                                </SubheaderStyled>
                                                <LinkIcon title="Copy link to clipboard" name="link" size="sm" onClick={e => this.copyLinkToClipBoard(e, data )}/>
                                            </Grid>
                                        }
                                        action={
                                            <>
                                                <MoreMenuItems>
                                                    {this.renderHoverActions(this.state.isHover)}
                                                    <Tooltip placement="top" arrow title="Open Actions">
                                                        <span style={{ padding: '0px 0 0 8px' }}>
                                                            <ButtonIcon icon={'information'} onClick={e=> this.onThreeDotClick(e, device)} />
                                                        </span>
                                                    </Tooltip>
                                                </MoreMenuItems>
                                                {/*<Box display="flex" flexDirection="row" justifyContent="flex-end">
                                                    <ListCardInfo noDivider>
                                                        {formatDate(time, 'DD MMM YYYY')}
                                                    </ListCardInfo>
                                                </Box>*/}
                                            </>
                                        }
                                    />
                                    <CardContent>
                                        <Box paddingBottom={4}>
                                            {eventType && <ListCardInfo>Event type {eventType.name}</ListCardInfo>}
                                            {modifiedBy ? (
                                                <ListCardInfo>
                                                    Last modified{' '}
                                                    <Tooltip title={displayByKind('datetime', modifiedDate)} placement="bottom">
                                                        <span>{`${fromNow(modifiedDate)} `}</span>
                                                    </Tooltip>
                                                    <span>{modifiedBy.name}</span>
                                                </ListCardInfo>
                                            ) : null}
                                            {createdDate && (
                                                <ListCardInfo noDivider>
                                                    Created{' '}
                                                    <Tooltip title={displayByKind('datetime', createdDate)} placement="bottom">
                                                        <span>{`${fromNow(createdDate)}`} </span>
                                                    </Tooltip>
                                                    {createdBy ? (
                                                        <>
                                                            {' '}by <span> {createdBy.name}</span>{' '}
                                                        </>
                                                    ) : null}
                                                </ListCardInfo>
                                            )}
                                        </Box>
                                    </CardContent>
                                </GridItem>
                            </Grid>
                        </Card>
                    </CardContainer>
                )}
            </ResizableListItem>
        );
    }
}

export default connect(null, {
    openEventsActionsSidebar,
    showToastr
})(EventsListCard);
