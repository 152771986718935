/* @flow */

import Immutable from 'app/utils/immutable/Immutable';
import { loadDataReducer, dataTableReducer, keepDataAtStart } from 'app/utils/redux/reducer-utils';
import {
    ADD_ENTITY_CLASS_STARTED,
    ADD_ENTITY_CLASS,
    REMOVE_ENTITY_CLASS_STARTED,
    REMOVE_ENTITY_CLASS,
    LOAD_ENTITY_HISTORY_STARTED,
    LOAD_ENTITY_HISTORY,
    GET_ENTITY_TYPE_STARTED,
    GET_ENTITY_TYPE,
    SET_ENTITY_DRAFT_VERSION, SET_ENTITY_PREVIEW_VERSION
} from 'store/actions/entities/entitiesActions';

const changelogReducer = dataTableReducer(LOAD_ENTITY_HISTORY_STARTED, LOAD_ENTITY_HISTORY, keepDataAtStart);
const getEntityTypeReducer = loadDataReducer(GET_ENTITY_TYPE_STARTED, GET_ENTITY_TYPE, keepDataAtStart);

/**
 * Generic Entity reducer.
 *
 * @param state - the current Redux state
 * @param action - the dispatched action
 * @returns the new Redux state
 */
const reducer = (
    state: Object = Immutable({
        attachFileInProgress: false,
        deletingAttachments: false,
        addingClass: false,
        removingClass: false,
        expandedEntities: { isLoading: false, list: [], total: 0 },
        history: { isLoading: false },
        entityType: { isLoading: false },
        draftedDetails: {},
        previewVersion: {},
    }),
    action: Object,
    meta: Object = {}
): Object => {
    const { type, payload } = action;
    switch (type) {
        case ADD_ENTITY_CLASS_STARTED:
            return Immutable({ ...state, addingClass: true });

        case ADD_ENTITY_CLASS:
            return Immutable({ ...state, addingClass: false });

        case REMOVE_ENTITY_CLASS_STARTED:
            return Immutable({ ...state, removingClass: true });

        case REMOVE_ENTITY_CLASS:
            return Immutable({ ...state, removingClass: false });

        case LOAD_ENTITY_HISTORY_STARTED:
        case LOAD_ENTITY_HISTORY:
            return Immutable({ ...state, history: changelogReducer(state.history, action, meta) });

        case GET_ENTITY_TYPE_STARTED:
        case GET_ENTITY_TYPE:
            return Immutable({ ...state, entityType: getEntityTypeReducer(state.entityType, action) });

        case SET_ENTITY_DRAFT_VERSION:
            return Immutable({ ...state, draftedDetails: {
                ...state.draftedDetails,
                [payload.type]: payload.details
            } });
        case SET_ENTITY_PREVIEW_VERSION:
            return Immutable({ ...state, previewVersion: {
                ...state.previewVersion,
                [payload.type]: payload.details
            } });

        default:
            return state;
    }
};

export default reducer;
