/* @flow */

import { isEmpty } from 'app/utils/utils';
import { get, set } from 'app/utils/lo/lo';
import { groupFields } from 'app/utils/classification/classificationForm';


export const _collectClassificationIds = components => (components||[]).reduce((ids, component) => {
    if(component.children) {
        ids.push(..._collectClassificationIds(component.children));
        return ids;
    };
    const classificationId = get(component.properties, 'classification.id', null);
    if(component.type === 'classification' && classificationId) {
        ids.push(classificationId);
    }
    return ids;
}, []);

export const _expandClassificationComponents = (formComponents, classMap) => formComponents.reduce((components, comp) => {
    const component = { ...comp };
    if(component.children) {
        component.children = _expandClassificationComponents(component.children, classMap);
    };
    const classificationId = get(component.properties, 'classification.id', null);
    if(component.type === 'classification' && classificationId) {
        components.push(...groupFields(classMap[classificationId]?.formDefinition?.fields, {
            isCollapsed: component.properties.isCollapsed,
            isDisabled: component.properties.disabled,
            isVisible: component.properties.isVisible,
            editablePanel: true,
            classData: classMap[classificationId],
        }));
    } else {
        components.push(component);
    }
    return components;
}, []);


export const addDefaultValues = (components, formData) =>
    (components || []).reduce((data, component) => {
        const name = get(component, 'properties.name');
        switch (component.type) {
            case 'group':
                let childrenData = !!name ? get(data, name, {}) : data;
                childrenData = addDefaultValues(component.children, childrenData);
                data = !!name ? set(data, name, childrenData) : childrenData;
                break;
            case 'groupRepeat':
                if(!name) {
                    console.error('the group repeat name is required.'); // eslint-disable-line no-console
                } else {
                    let childrenData = get(data, name, [{}]);
                    // childrenData = {};
                    if (!Array.isArray(childrenData)) {
                        console.error('[GroupRepeat] Reference to data is wrong, not an array.'); // eslint-disable-line no-console
                        childrenData = [{}];
                    }
                    childrenData = childrenData.map(childData => addDefaultValues(component.children, childData));
                    data = set(data, name, childrenData);
                }
                break;
            case 'panel':
                data = addDefaultValues(component.children, data);
                break;
            default:
                if (name && get(data, name) === undefined) {
                    const defaultValue = get(component, 'properties.defaultValue');
                    if (defaultValue !== undefined) {
                        data = set(data, name, defaultValue);
                    }
                }
        }
        return data;

    }, formData || {});

export const expandClassesAndDefaults = async (formComponents, formData, getClassesByIds) => {
    // 1. go through the components (and ricursively in the components' children)
    // 2. if we found one or more classification component collect the ID of the classification
    const ids = _collectClassificationIds(formComponents).flat(Infinity);
    if (isEmpty(ids)) {
        return { components: formComponents, data: addDefaultValues(formComponents, formData) };
    }

    // 3. get all the required classifications by ID (using only one GraphQL query)
    const classes = await getClassesByIds(ids);

    // e.g. classes = [{ id: 2312, formDefinition }, { id: 42342, formDefinition }];
    const classMap = classes.records.reduce((map, item) => {
        map[item.id] = item;
        return map;
    }, {});
    // e.g. classeMap = { 2312: formDefinition, 42342: formDefinition };
    // 4. substitute the classification components with the related classification components (using groupFields)
    const components = _expandClassificationComponents(formComponents, classMap);
    // 5. add the defualt values to the data
    const data = addDefaultValues(components, formData);
    // 6. return the new components definition and the new data
    return { components, data };
};
