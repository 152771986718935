// @flow

import { gql } from '@apollo/client';

export default gql`
    query processStartedDetailsQuery($startIndex: Int, $stopIndex: Int, $page: Int, $itemsPerPage: Int, $filterBy: [JSON], $orderBy: [JSON]) {
        result: tasks(startIndex: $startIndex, stopIndex: $stopIndex, page: $page, itemsPerPage: $itemsPerPage, filterBy: $filterBy, orderBy: $orderBy) {
            id
            assignee {
                id
            }
        }
    }
`;
