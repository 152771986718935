/* @flow */
import { isDefined } from 'app/utils/utils';
/**
 * Converts object to mutable.
 * @param obj
 * @returns {Object}
 */
export function safeToJS(obj: ?Object) {
    if (!isDefined(obj)) {
        return null;
    }
    return typeof(obj) === 'object' ? { ...obj } : obj;
}

/**
 * Converts object to a mutable array.
 * @param obj
 * @returns {Object}
 */
export function safeToJsArray(obj: ?any): ?any[] {
    if (!isDefined(obj)) {
        return null;
    }
    return Array.isArray(obj) ? [...obj] : obj;
}
