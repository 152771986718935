// @flow

import { gql } from '@apollo/client';
import loadTeamDetailCommonQuery from 'graphql/teams/loadTeamDetailCommonQuery';

export default gql`
    query loadTeamQuery($id: ID!) {
        result: team(id: $id) {
            ${loadTeamDetailCommonQuery}
        }
    }
`;
