/* @flow */

import { gql } from '@apollo/client';

export default gql`
  mutation ssoUpdateIdentityProviderMutation($provider: IdentityProviderUpdateInput!) {
    result: ssoUpdateIdentityProvider(identityProvider: $provider) {
      id
      name
      type
    }
  }
`;
