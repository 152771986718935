import React from 'react';

import Help from 'app/utils/designer/form/settings/common/Help';

export const help = {
    type: 'textarea',
    properties: {
        parseAs: 'HTML',
        label: 'Helper',
        name: 'properties.help',
        help: <Help message="Displays custom intructions for the user" />,
    }
};
