/* @flow */

import Immutable from 'app/utils/immutable/Immutable';
import { isEmpty } from 'app/utils/utils';
import { sortBy } from 'app/utils/lo/lo';

import {
    LOAD_SUBSCRIPTIONS_STARTED,
    LOAD_SUBSCRIPTIONS,
    ADD_SUBSCRIPTION,
    REMOVE_SUBSCRIPTION,
    INCREMENT_UNREAD,
    READ_ROOM_MESSAGES,
    UNSUBSCRIBE_ENTITY_CHAT,
    ADD_ROOM_MESSAGES,
    SEND_CHAT_MESSAGE,
    UNSUBSCRIBE_GROUP_CHAT,
    UNSUBSCRIBE_CHANNEL_CHAT,
    UPDATE_CHAT_NAME,
    LOAD_ROOM_MESSAGES,
    FAVORITE_UNFAVORITE_CHAT
} from 'store/actions/chat/chatActions';

const initialState = {
    data: [],
    isLoading: false
};

export default (state: Object = initialState, action: Function) => {
    const { type, meta, error, payload } = action;
    switch (type) {
        case LOAD_SUBSCRIPTIONS_STARTED:
            return Immutable({ ...state, isLoading: true });

        case LOAD_SUBSCRIPTIONS:
            if (error) {
                return Immutable({ ...state, error, meta, isLoading: false });
            }
            return Immutable({
                ...state,
                isLoading: false,
                data: payload
            });

        case ADD_SUBSCRIPTION: {
            const subscriptions = state.data;
            if (error || subscriptions.find(({ rid }) => rid === payload.rid)) {
                return state;
            }
            return Immutable({
                ...state,
                data: [...subscriptions, payload]
            });
        }

        case UNSUBSCRIBE_ENTITY_CHAT: {
            const subscriptions = state.data;
            const { isKick } = meta || {};
            if (error || isKick || !subscriptions.find(({ rid }) => rid === payload.chatId)) {
                return state;
            }
            return Immutable({
                ...state,
                data: subscriptions.filter(({ rid }) => rid !== payload.chatId)
            });
        }

        case UNSUBSCRIBE_GROUP_CHAT:
        case UNSUBSCRIBE_CHANNEL_CHAT: {
            const subscriptions = state.data;
            if (error || !subscriptions.find(({ rid }) => rid === meta.roomId)) {
                return state;
            }
            return Immutable({
                ...state,
                data: subscriptions.filter(({ rid }) => rid !== meta.roomId)
            });
        }

        case REMOVE_SUBSCRIPTION: {
            const subscriptions = state.data;
            if (error || !subscriptions.find(({ rid }) => rid === payload.rid)) {
                return state;
            }
            return Immutable({
                ...state,
                data: subscriptions.filter(({ rid }) => rid !== payload.rid)
            });
        }

        case INCREMENT_UNREAD: {
            const subscriptions = state.data;
            const subscription = subscriptions.find(({ rid }) => rid === payload.rid);
            if (error || !subscription) {
                return state;
            }
            return Immutable({
                ...state,
                data: subscriptions.map(record =>
                    (record.rid === payload.rid)
                        ? { ...record, unread: record.unread + 1, updatedAt: (new Date()).toISOString() }
                        : record
                ),
            });
        }

        case SEND_CHAT_MESSAGE:
        case ADD_ROOM_MESSAGES: {
            const { rel, message } = payload || {};
            const subscriptions = state.data;
            const subscription = subscriptions.find(sub => sub.rel.id === rel.id);
            if (error || !subscription) {
                return state;
            }
            return Immutable({
                ...state,
                data: subscriptions.map(record =>
                    (record.rel.id === rel.id)
                        ? { ...record, updatedAt: new Date().toISOString(), lastMessage: message }
                        : record
                )
            });
        }
        
        case LOAD_ROOM_MESSAGES: {
            const { rid, messages, skipLastMessageUpdate } = payload || {};
            const subscriptions = state.data;
            const subscription = subscriptions.find(sub => sub.rel.id === rid);
            if (error || !subscription || isEmpty(messages) || skipLastMessageUpdate) {
                return state;
            }
            const parsedMessages = (sortBy(messages, 'createDate')).filter(msg => isEmpty(messages.type));
            const lastMessage = parsedMessages[parsedMessages.length - 1];
            return Immutable({
                ...state,
                data: subscriptions.map(record =>
                    (record.rel.id === rid)
                        ? { ...record, lastMessage }
                        : record
                )
            });
        }

        case READ_ROOM_MESSAGES: {
            const subscriptions = state.data;
            const subscription = subscriptions.find(({ rid }) => rid === payload.rid);
            if (error || !subscription) {
                return state;
            }
            return Immutable({
                ...state,
                data: subscriptions.map(record =>
                    (record.rid === payload.rid)
                        ? { ...record, unread: 0, updatedAt: new Date().toISOString() }
                        : record
                )
            });
        }
        
        case UPDATE_CHAT_NAME: {
            const { rid, updatedName } = payload || {};
            const subscriptions = state.data;
            let subscription = subscriptions.find(sub => sub.rid === rid);
            if (error || !subscription) {
                return state;
            }
            subscription = {
                ...subscription,
                name: updatedName,
                rel: { ...subscription.rel, name: updatedName },
                updatedAt: new Date().toISOString()
            }; 
            return Immutable({
                ...state,
                data: subscriptions.map(record => record.rid === rid ? subscription : record)
            });
        }

        case FAVORITE_UNFAVORITE_CHAT: {
            const { roomId, favorite } = payload || {};
            const subscriptions = state.data;
            const subscription = subscriptions.find(({ rid }) => rid === roomId);
            if (error || !subscription) {
                return state;
            }
            return Immutable({
                ...state,
                data: subscriptions.map(record =>
                    (record.rid === roomId)
                        ? { ...record, f: favorite }
                        : record
                )
            });
        }

        default:
            return state;
    }
};
