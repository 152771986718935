/* @flow */

import React, { Fragment, PureComponent } from 'react';
import { muiTheme } from 'app/themes/materialUi';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    // , MdiIcon, IconButton, InputBase, Switch, Divider,
    Typography, Button } from '@mic3/platform-ui';
import { withTheme } from 'styled-components';

import PrintTemplateLayoutPreview from 'app/containers/Entities/PrintTemplates/PrintTemplateLayoutPreview';
import PrintPreviewSettings from 'app/containers/Entities/PrintTemplates/PrintPreviewSettings';
import Icon from 'app/components/atoms/Icon/Icon';
import HeaderBar from 'app/components/molecules/HeaderBar/HeaderBar';
import { loadEntity } from 'store/actions/entities/entitiesActions';
import { loadTaskSidebarDetailsInternal } from 'store/actions/abox/taskActions';
import { bind } from 'app/utils/decorators/decoratorUtils';
import { loadScript, executeScript, executeScriptForViewer } from 'store/actions/designer/designerScriptActions';
import { get } from 'app/utils/lo/lo';
import history from 'store/History';
import { deserializeVariables } from 'app/utils/bpmn/bpmnEngineUtils';
import { loadPrintTemplateDetails } from 'store/actions/entities/printTemplateActions';

class PrintPreview extends PureComponent<Object, Object> {
    static propTypes: Object = {
        details: PropTypes.object.isRequired,
        uploadImage: PropTypes.func,
        isLoading: PropTypes.bool,
        canEdit: PropTypes.bool,
        fromSidebar: PropTypes.bool,
        imageData: PropTypes.object,
        userProfile: PropTypes.object,
        reloadList: PropTypes.func
    };

    constructor(props) {
        super(props);
        this.firstLoading = true;
        this.state = { loadingStage: 'Loading...', context: null, showPreviwSettings: false, changed: false, text: props.details?.primary['print-template/template'] || '' };
    }

    componentDidUpdate(prevProps) {
        const { details } = this.props;
        if(details !== prevProps.details) {
            this.setState({ text: details?.primary['print-template/template'] || '', changed: false });
        }
    }

    componentDidMount() {
        this.loadDetails();
    }

    @bind
    async loadDetails() {
        this.setState({ isLoading: true });
        const { match, loadEntity, loadPrintTemplateDetails, executeScriptForViewer, loadTaskSidebarDetailsInternal } = this.props;
        const { id, entityType, templateId } = match.params || {};
        this.setState({ loadingStage: 'Template loading...' });
        const template = await loadPrintTemplateDetails({id: templateId});
        this.setState({ loadingStage: 'Entity loading...' });

        const isTask = ['task', 'opentask', 'closedtask'].includes(entityType);

        let details = null;
        if(id) {
            details = isTask ?
                await loadTaskSidebarDetailsInternal(id)
                : await loadEntity(entityType, id);
        }

        let context = details;
        let formData = null;

        if(isTask) {
            const variables = deserializeVariables(details?.formDefinitionVersion?.definition, details?.process?.variables);
            context = {
                ...details,
                process: {
                    ...details.process,
                    variables
                }
            };
        }

        const scriptId = get(template, 'primary.print-template/script.id');
        if(scriptId) {
            this.setState({ loadingStage: 'Script loading...' });
            const formDefinition = template._formDefinition?.primary?.definition || null;
            let isMultiple = false;
            if(formDefinition) {
                const parseChildrens = (def) => {
                    if(def.children) {
                        findMultiple(def);
                    }
                };
                const findMultiple = (def) => {
                    const entities = def.children.find(d => d.properties.name === 'entities');
                    if(entities) {
                        isMultiple = true;
                    }
                    def.children.forEach(parseChildrens);
                };
                formDefinition.forEach(parseChildrens);
            }
            context = null;
            if(details) {
                formData = isMultiple ? { entities: [details] } : { entity: details };
            }
            try {
                context = await executeScriptForViewer(template?.id, formData);
            } catch (e) {}
        }

        this.setState({
            template, formData, context,
            text: template?.primary['print-template/template'] || '',
            isLoading: false,
            showPreviwSettings: !details,
            details
        }, () => {
            if(this.state.details && this.firstLoading) {
                window.print();
                this.firstLoading = false;
            }
        });
    }

    @bind
    async onFormSubmit() {
        const { details }= this.props;
        const { id, type } = details;
        const { text } = this.state;
        this.props.updateEntity({
            id, type,
            primary: {
                'print-template/template': text,
            }
        }).then(async (resp) => {
            if (!(resp instanceof Error)) {
                this.loadDetails();
            }
        });
    }

    @bind
    handleOnChangeText(event) {
        this.setState({ text: event.target.value, changed: true });
    }

    @bind
    handleChangeContext({ target: { value }}) {
        this.setState({ context: value });
    }

    @bind
    handleChangeFormData({ target: { value }}) {
        this.setState({ formData: value });
    }

    @bind
    onFormCancel() {
        history.goBack();
    }

    @bind
    handleShowPreviwSettings() {
        this.setState({ showPreviwSettings: !this.state.showPreviwSettings });
    }

    @bind
    handlePrint() {
        const { context } = this.state;
        if(!context) {
            this.setState({ showPreviwSettings: !this.state.showPreviwSettings });
        } else {
            window.print();
        }
    }

    render(): Object {
        const { formData, loadingStage, template, text, isLoading, showPreviwSettings, context, details } = this.state;
        return (
            <Fragment>
                {!isLoading && (
                    <div><HeaderBar className="no-print" left={<Typography variant="h6">Preview</Typography>} right={[(
                        <Button key={12} variant="text" onClick={this.onFormCancel}>
                      cancel
                        </Button>
                    ),(
                        <Button key={13} size="small" startIcon={(
                            <Icon name="printer"  size="sm"  hexColor={muiTheme.colors.text.button}/>
                        )} onClick={this.handlePrint} >Print</Button>
                    )]} /></div>
                )}
                <PrintTemplateLayoutPreview
                    external
                    text={text}
                    context={context}
                    loadingStage={loadingStage}
                    isLoading={isLoading}
                    handleShowPreviwSettings={this.handleShowPreviwSettings}
                    handleChangeContext={this.handleChangeContext}
                />
                {showPreviwSettings && (
                    <PrintPreviewSettings
                        external
                        details={details}
                        context={context}
                        formData={formData}
                        printTemplate={template}
                        handleChangeContext={this.handleChangeContext}
                        handleChangeFormData={this.handleChangeFormData}
                        onClose={this.handleShowPreviwSettings}
                        onCancel={this.onFormCancel}
                    />
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = (state: Object) => ({
    isLoading: state.entities.sidebar.details.isLoading,
    userProfile: state.user.profile
});

export default connect(
    mapStateToProps,
    { loadPrintTemplateDetails, loadEntity, loadScript, executeScript, loadTaskSidebarDetailsInternal, executeScriptForViewer }
)(withTheme(PrintPreview));
