/* @flow */

import { gql } from '@apollo/client';

export default gql`
    mutation deleteEntity3dModelMutation($type: String!, $id: ID!) {
        result: deleteEntity3dModel(type: $type, id: $id) {
            id
        }
    }
`;
