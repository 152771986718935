/* @flow */

import { combineReducers } from 'redux';

import { dataTableReducer, loadDataReducer, keepDataAtStart } from 'app/utils/redux/reducer-utils';
import {
    LOAD_FORMS_DEFINITIONS_STARTED,
    LOAD_FORMS_DEFINITIONS,
    LOAD_FORM_DEFINITION_STARTED,
    LOAD_FORM_DEFINITION,
    LOAD_FORM_ACTION_DEFINITION_STARTED,
    LOAD_FORM_ACTION_DEFINITION
} from 'store/actions/designer/designerFormActions';
import {
    LOAD_PROCESSES_DEFINITIONS_STARTED,
    LOAD_PROCESSES_DEFINITIONS,
    LOAD_PROCESS_DEFINITION_STARTED,
    LOAD_PROCESS_DEFINITION,
} from 'store/actions/designer/designerProcessActions';
import {
    LOAD_SCRIPTS_STARTED,
    LOAD_SCRIPTS,
    LOAD_SCRIPT_STARTED,
    LOAD_SCRIPT,
} from 'store/actions/designer/designerScriptActions';
import {
    LOAD_PIPELINE_STARTED, LOAD_PIPELINE,
    LOAD_PIPELINE_STARTED_STARTED_, LOAD_PIPELINE_STARTED_,
    LOAD_PIPELINE_MONITOR_STARTED, LOAD_PIPELINE_MONITOR,
    RUNTIME_STATUS_PIPELINE_STARTED, RUNTIME_STATUS_PIPELINE,
    TOPIC_TAIL_PIPELINE_STARTED, TOPIC_TAIL_PIPELINE,
    CONNECTOR_LOG_TAIL_PIPELINE_STARTED, CONNECTOR_LOG_TAIL_PIPELINE
} from 'store/actions/designer/designerPipelineActions';

import scriptAvatar from 'store/reducers/designer/avatar/scriptsAvatarReducer';

export default combineReducers<Object, Object>({
    forms: dataTableReducer(LOAD_FORMS_DEFINITIONS_STARTED, LOAD_FORMS_DEFINITIONS, keepDataAtStart),
    form: loadDataReducer(LOAD_FORM_DEFINITION_STARTED, LOAD_FORM_DEFINITION, keepDataAtStart),
    actionForm: loadDataReducer(LOAD_FORM_ACTION_DEFINITION_STARTED, LOAD_FORM_ACTION_DEFINITION),
    processes: dataTableReducer(LOAD_PROCESSES_DEFINITIONS_STARTED, LOAD_PROCESSES_DEFINITIONS, keepDataAtStart),
    process: loadDataReducer(LOAD_PROCESS_DEFINITION_STARTED, LOAD_PROCESS_DEFINITION),
    pipelineMonitor: dataTableReducer(LOAD_PIPELINE_MONITOR_STARTED, LOAD_PIPELINE_MONITOR, keepDataAtStart),
    pipeline: loadDataReducer(LOAD_PIPELINE_STARTED, LOAD_PIPELINE),
    pipelineStarted: loadDataReducer(LOAD_PIPELINE_STARTED_STARTED_, LOAD_PIPELINE_STARTED_),
    pipelineStatus: loadDataReducer(RUNTIME_STATUS_PIPELINE_STARTED, RUNTIME_STATUS_PIPELINE),
    pipelineTopicTail: loadDataReducer(TOPIC_TAIL_PIPELINE_STARTED, TOPIC_TAIL_PIPELINE, keepDataAtStart),
    pipelineConnectorLogTail: loadDataReducer(CONNECTOR_LOG_TAIL_PIPELINE_STARTED, CONNECTOR_LOG_TAIL_PIPELINE, keepDataAtStart),
    scripts: dataTableReducer(LOAD_SCRIPTS_STARTED, LOAD_SCRIPTS, keepDataAtStart),
    script: loadDataReducer(LOAD_SCRIPT_STARTED, LOAD_SCRIPT),
    scriptAvatar,
});
