/* @flow */

import { gql } from '@apollo/client';

import { workspaceFields, classWorkspaceFields } from 'graphql/entities/entities/loadEntityWorkspacesQuery';

export default type => gql`
    mutation unshareEntityWithUsersMutation($id: ID!, $users: [ID]!) {
        result: ${type === 'class' ? 'unshareClassWithUsers': 'unshareEntityWithUsers'}(id: $id, users: $users${type === 'class' ? '' : `, type: "${type}"`}) {
            ${type === 'class' ? classWorkspaceFields : workspaceFields}
        }
    }
`;
