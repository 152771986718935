import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { bind } from 'app/utils/decorators/decoratorUtils';
import ListItem from 'app/components/molecules/List/ListItem';
import { Menu, MenuItem, IconButton, MdiIcon, Tooltip } from '@mic3/platform-ui';
import { deleteDrawingFromLayer } from 'app/components/molecules/Map/DrawShapes/DrawDrawingLayer';

import { toLonLat } from 'ol/proj';
import styled from 'styled-components';
import { updateMapLayer } from 'store/actions/maps/situationalAwarenessActions';
import { __getMapLayers } from 'app/utils/maps/layer/layerUtils';

const StyledContainer = styled.div`
    display: flex;
    align-items: center;
`;

const LatLongStyle = styled.div`
    overflow: hidden;
    white-space: nowrap; 
    text-overflow: ellipsis;
    `;

class ListItemPinsActions extends PureComponent {

    state = {
        open: false
    };

    anchorEl = React.createRef();
    
    @bind
    async removeItem(e) {
        e.stopPropagation();
        this.toggleMenu();
        const { type, index, itemId, allMapLayers, updateMapLayer } = this.props;

        switch(type) {
            case 'Drawing Layer':
                await deleteDrawingFromLayer({ 
                    id: itemId, 
                    index, 
                    map: this.props.map, 
                    allMapLayers, 
                }, updateMapLayer);
                break;
            default:
                return;
        }
    }

    @bind
    async updateDrawing(data) {
        const { map,itemId } = this.props;
        const { primary, id, ..._attributes } = data || {};
        await map.addEntityLayer({
            _attributes: _attributes,
            id: itemId,
            type: 'drawing-layer',
            primary,
            styleType: 'drawing'
        });
        return Promise.resolve();
    }

    @bind
    toggleMenu(e) {
        if(e?.stopPropagation) {
            e.stopPropagation();
        }
        this.setState({ open: !this.state.open });
    }

    render() {
        const { drawingLatLong } = this.props;
        const latLong = toLonLat(drawingLatLong);
        const lat = latLong?.length && ((latLong?.[1])?.toFixed(4) || '');
        const long = latLong?.length && ((latLong?.[0])?.toFixed(4) || '');
        const toolTipCoords = `Lat: ${(latLong?.[1])?.toFixed(6)}, Lon: ${(latLong?.[0])?.toFixed(6)}`;
        return (<StyledContainer>
            <LatLongStyle> 
                <Tooltip title={toolTipCoords} placement="top">
                    <span> Lat: {lat}, Lon: {long}</span>
                </Tooltip>
            </LatLongStyle>
            <IconButton buttonRef={this.anchorEl} onClick={this.toggleMenu}>
                <MdiIcon name="dots-vertical" />
            </IconButton>
            <Menu open={this.state.open} anchorEl={this.anchorEl.current} onClose={this.toggleMenu} >
                <MenuItem onClick={this.removeItem}>
                    <ListItem component={<MdiIcon name="delete" />} title={'Delete drawing'} />
                </MenuItem>
            </Menu>
        </StyledContainer>
        );
    }
};

export default connect(state => ({
    selectedLayer: state.maps.situationalAwareness.layer.selectedLayer,
    allMapLayers: __getMapLayers(state.maps.situationalAwareness.map.data),
}), {
    updateMapLayer
})(ListItemPinsActions);
