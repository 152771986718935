
/* @flow */

import React, { useMemo, useCallback } from 'react';
import { DOMParser } from '@xmldom/xmldom';

import ModalFormGenearator from 'app/components/organisms/ModalFormGenearator/ModalFormGenearator';

export const updateProcessId = (definition, oldId, newId) => {
    const doc = new DOMParser().parseFromString(definition);
    const process =  doc.getElementsByTagName('process')[0] || doc.getElementsByTagName('bpmn:process')[0];
    process.setAttribute('id', newId);
    const BPMNDiagram =  doc.getElementById(`BPMNDiagram_${oldId}`);
    BPMNDiagram.setAttribute('id', `BPMNDiagram_${newId}`);
    const BPMNPlane =  doc.getElementById(`BPMNPlane_${oldId}`);
    BPMNPlane.setAttribute('id', `BPMNPlane_${newId}`);
    BPMNPlane.setAttribute('bpmnElement', newId);
    return String(doc);
};

export const parseIdFromString = value => (value || '').match(/[A-Za-z0-9]+/g).join('');

const ProcessDefinitionId = ({ value: definition, onChange, ...restProps }) => {

    const value = useMemo(() => {
        const doc = new DOMParser().parseFromString(definition);
        const process =  doc.getElementsByTagName('process')[0] || doc.getElementsByTagName('bpmn:process')[0];
        return { id: process.getAttribute('id') };
    }, [definition]);

    const handleOnChange = useCallback(({ target }) => {
        const newId = target.value.id;
        const nextValue = updateProcessId(definition, value.id, newId);
        onChange({ target: { value: nextValue, name: target.name }});
    }, [definition, onChange, value.id]);

    return (
        <ModalFormGenearator
            {...restProps}
            previewRender={value => value.id}
            value={value}
            onChange={handleOnChange}
            components={[
                {
                    type: 'text',
                    properties: {
                        label: 'Id',
                        name: 'id',
                        onChange: ({ target }) => {
                            const value = parseIdFromString(target.value);
                            return [{ value, name: target.name }];
                        }
                    },
                    constraints: { required: true, custom: (data) => {
                        const noStartFromNumbers = { format: { pattern: /somefaketest/, message: 'Id should not start from number.' }};
                        if (/^[0-9]+/g.test(data.id)) {
                            return noStartFromNumbers;
                        }
                        const validSymbols = { format: { pattern: /somefaketest/, message: 'Id has to be just letters and numbers.' }};
                        if (!/^[A-Za-z0-9]+$/g.test(data.id)) {
                            return validSymbols;
                        }
                        return {};
                    }}
                },
            ]}
        />
    );
};

export default ProcessDefinitionId;
