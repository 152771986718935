/* @flow */

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import { CardContent, Divider, Box, CardHeader, Card, Chip, Tooltip, IconButton, CardMedia, Avatar } from '@mic3/platform-ui';

import { openEntitySidebar } from 'store/actions/entities/entitySidebarActions';
import { openClassSidebar } from 'store/actions/entities/classSidebarActions';
import { openTeamSidebar } from 'store/actions/entities/teamSidebarActions';
import { openProcessSidebar } from 'store/actions/abox/processSidebarActions';
import { openWorkspaceSidebar } from 'store/actions/entities/workspaceSidebarActions';
import { openTaskSidebar } from 'store/actions/abox/taskSidebarActions';
import { showToastr } from 'store/actions/app/appActions';
import { getFieldByType } from 'app/utils/designer/form/fieldUtils';
import { getPriorityColor } from 'app/config/aboxConfig';
import history from 'store/History';

import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { fromNow, displayByKind } from 'app/utils/date/date';
import { getAttachmentUrl } from 'app/utils/attachments/attachmentsUtils';
import { getEntityUrl, getAttribute, goToEntityDetails } from 'app/utils/entity/entityUtils';
import { get } from 'app/utils/lo/lo';
import { iconsSet } from 'app/utils/styles/mdi';
import { toUniqueUUID } from 'app/utils/string/string-utils';
import { copyToClipboard } from 'app/utils/classification/classificationUtils';
import { checkIfUserIsMember } from 'app/utils/chat/chatUtils';

import Icon from 'app/components/atoms/Icon/Icon';
import CardIconButton from 'app/components/molecules/CardIconButton/CardIconButton';
import ResizableListItem from 'app/components/molecules/VirtualList/ResizableListItem';
import CardInfo from 'app/components/molecules/CardInfo/CardInfo';
import Ellipsis from 'app/components/molecules/Ellipsis/Ellipsis';
import DotMenu from 'app/components/molecules/DotMenu/DotMenu';
import TypeIcon from 'app/components/atoms/TypeIcon/TypeIcon';
import { ProcessAvatar, EntityLinkStyledSubTitle, EntityLinkStyled, LinkIcon, EntityTitle } from './EntitiesListItem';
import { isVisibleTab } from 'app/utils/entity/entityUtils';
import { getPermissions } from 'app/config/rolesConfig';

import ChipClosed from 'app/components/atoms/ChipClosed/ChipClosed';
import { settingsEntityType } from 'app/config/config';

export const CardContainer = styled.div`
    width: 98%;
    max-width: 1000px;
    margin: 0 auto;
    & > .MuiCard-root {
        ${({ priority, theme }) => (priority ? `border-left: 4px solid ${theme.priorityGradients[priority][0]}` : '')};
    }
`;

export const CardStyled = styled(Card)`
    height: auto;
    cursor: pointer;

    &:hover {
        background: ${({ theme, selected }) => theme.material.colors.background.hover};
    }
    ${({ theme, selected }) => selected && `background-color: ${theme.material.colors.itemActive} !important;`}
`;

export const CardDivider = styled(Divider)`
    && {
        margin: 0 12px;
        height: 16px;
        position: relative;
        top: 3px;
    }
`;

export const CardDividerTitle = styled(Divider)`
    margin: 0 8px !important;
`;

export const CardHeaderStyled = styled(CardHeader)`
    position: relative;
    flex: 1;
    padding-right: 120px !important;

    & .MuiCardHeader-title {
        display: flex;
        align-items: center;
    }
    & .MuiCardHeader-action {
        position: absolute;
        right: 10px;
        top: 10px;
    }
    & .MuiCardHeader-content {
        width: 1px;
    }
    & .MuiCardHeader-subheader {
        display: flex;
    }
`;

export const CardImage = styled(CardMedia)`
    && {
        width: 180px;
        height: 180px;
        background-size: containe !important;
        background-repeat: no-repeat !important;
        margin-right: 12px;
        &.mobile {
            width: 100%;
            height: 100px;
        }
    }
`;

export const CardContentStyled = styled(CardContent)`
    flex-grow: 1;
    height: 100%;

    && {
        padding-top: 10px;
        padding-bottom: 10px;
    }
`;

export const CardDetail = styled(Box)`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    width: ${isMobile ? '100%' : '1px'};
`;

export const CardInfoStyled = styled(Box)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const ChipStyled = styled(Chip)`
    & .MuiChip-root{
        background-color: ${({theme})=> theme.material.colors.secondary.main} !important;
    };
    & .MuiChip-label {
        max-width: 140px;
    };
`;

export const CardIconButtonStyled = styled(CardIconButton)`
    margin-right: 12px;
    color: ${({theme})=> theme.material.colors.text.primary};
`;

class EntitiesListCardsItem extends PureComponent<Object, Object> {

    static defaultProps = {
        icons: ['Entity Resources', 'Permissions', 'relations', 'classes', 'attachments', ]
    }

    inputField = React.createRef();

    @bind
    openSidebar(title) {
        const { data, onSelect, reloadList, entitiesView, entityType, isPrintReports, module, adminBackgroundJobs } = this.props;
        const type = entitiesView ? data?.type || this.props.type : this.props.type;
        if(title === 'Relations') {
            if((type === 'user' || type === 'person') && module !== 'user-management'){
                return history.push(`/entities/${type}/${data.id}/relationships`);
            }
            return history.push(`${getEntityUrl(data.id, type, { isPrintReports })}/relationships`);
        }

        const params = { id: data.id, type, title, reloadList, entityType };
        switch (type) {
            case 'class':
                this.props.openClassSidebar(params);
                break;
            case 'task':
                this.props.openTaskSidebar(params);
                break;
            case 'process':
                this.props.openProcessSidebar(params);
                break;
            case 'workspace':
                this.props.openWorkspaceSidebar({...params, openWorkspaceSidebar: this.props.openWorkspaceSidebar});
                break;
            case 'team':
                this.props.openTeamSidebar(params);
                break;
            case 'user':
                if (entitiesView) {
                    return this.props.openEntitySidebar(params);
                }
                this.props.openEntitySidebar({ ...params, userAbout: true });
                break;


            default:
                this.props.openEntitySidebar({...params, adminBackgroundJobs});
                break;
        }
        onSelect && onSelect(data);
    }

    @bind
    copyLinkToClipBoard(e, id: string, type: string, entityType: boolean, isPrintReports, entitiesView, pipelineMonitor) {
        e.stopPropagation();
        const { adminBackgroundJobs } = this.props;
        copyToClipboard(`${window.location.origin}/#${getEntityUrl(id, type, { entityType, pipelineMonitor, isPrintReports, adminBackgroundJobs, entitiesView })}`)
            .then(() => {
                this.props.showToastr({ severity: 'success', detail: 'Link copied to clipboard' });
            })
            .catch(() => {
                this.props.showToastr({ severity: 'error', detail: 'Link could not copied to clipboard' });
            });
    }

    @bind
    copyIdToClipBoard(e, id: string) {
        e.stopPropagation();
        copyToClipboard(id)
            .then(() => {
                this.props.showToastr({ severity: 'success', detail: 'ID copied to clipboard' });
            })
            .catch(() => {
                this.props.showToastr({ severity: 'error', detail: 'ID could not copied to clipboard' });
            });
    }


    @bind
    goToClassification(id) {
        if(this.props.entityType){
            return () => history.push(`/entity-types/${id}`);
        }
        return () => history.push(`/classifications/${id}`);
    }

    @bind
    handleOpenSidebar(e, label) {
        e.stopPropagation();
        this.openSidebar(label);
    }

    @bind
    handleMenuClick(title) {
        const { goToDetails, data } = this.props;
        if (title === 'Go to details') {
            goToDetails && data?.id ? goToDetails(data?.id) : this.goToDetails();
        } else if (title === 'Process Map') {
            return history.push(`/abox/task/${data?.id}/process-map`);
        } else {
            this.openSidebar(title);
        }
    }

    @bind
    @memoize()
    dotMenu(dotMenu) {
        return <DotMenu key={13} onItemClick={this.handleMenuClick} items={dotMenu} />;
    }

    @bind
    goToDetails() {
        goToEntityDetails(this.props);
    }

    @bind
    @memoize()
    buildClasses(classes) {
        return (
            <Ellipsis
                data={classes}
                displaySize={isMobile ? 3 : 6}
                renderCount={count => <c variant="default" label={`+${count}`} size="small" onClick={() => this.openSidebar('Classes')} />}
                renderItem={(item) => {
                    const { name, id } = item || {};
                    if (id && name) {
                        return <ChipStyled variant="default" label={name} size="small" onClick={this.goToClassification(id)} />;
                    }
                    return null;
                }}
            />
        );
    }

    @bind
    @memoize()
    renderIcon(data, type, icons) {
        const { classes, relations, workspaces, primaryClass, entityResources } = data || {};
        const isClassType = type === 'class';
        const isTeamType = type === 'team';
        const isWorkspaceType = type === 'workspace';
      
        const iconButtons =
            !isClassType && !isTeamType
                ? [
                    icons.includes('relations') && !isWorkspaceType && isVisibleTab('Relations', primaryClass) && {
                        label: 'Relations',
                        name: 'relationships',
                        type: 'af',
                        count: get(relations, 'length', null)
                    },
                    icons.includes('classes') && isVisibleTab('Classes', primaryClass) && {
                        label: 'Classes',
                        name: 'Attribute-class',
                        type: 'af',
                        count: get(classes, 'length', null)
                    },
                    icons.includes('attachments') && !isWorkspaceType &&  isVisibleTab('Attachments', primaryClass) && {
                        label: 'Attachments',
                        name: 'paperclip',
                        type: 'mdi'
                    }
                ]
                : [];
        if (isWorkspaceType) {
            const permissions = entityResources?.filter(ent => ent.entity.type === 'permission' || ent.entity.type === settingsEntityType) || [];
            const resourcesWithoutPermissions =  (entityResources?.length || 0) - (permissions?.length || 0);
            iconButtons.unshift(
                icons.includes('Entity Resources') && {
                    label: 'Entity Resources',
                    name: 'things',
                    type: 'af',
                    count: resourcesWithoutPermissions
                },
                icons.includes('Permissions') && {
                    label: 'Permissions',
                    name: 'key-variant',
                    type: 'mdi',
                    count: get(permissions, 'length', null)
                });
        }
        return (
            <Box display="flex" justifyContent="space-between">
                <div>
                    {iconButtons.filter(Boolean).map(({ label, name, type, value, count }, index) => {
                        return (
                            <Tooltip title={label} key={`${index}-${value}`}>
                                <CardIconButtonStyled
                                    edge={index === 0 ? 'start' : false}
                                    ref={this.inputField}
                                    count={count}
                                    onClick={e => this.handleOpenSidebar(e, label)}
                                    iconProps={{
                                        name: name,
                                        type: type
                                    }}
                                />
                            </Tooltip>
                        );
                    })}
                </div>
                {!isClassType && !isTeamType && !isWorkspaceType && isVisibleTab('Sharing', primaryClass) && (
                    <Tooltip title="Sharing">
                        <CardIconButton
                            ref={this.inputField}
                            count={get(workspaces, 'length')}
                            onClick={e => this.handleOpenSidebar(e, 'Sharing')}
                            iconProps={{
                                name: 'share-variant',
                                type: 'mdi'
                            }}
                        />
                    </Tooltip>
                )}
            </Box>
        );
    }

    @bind
    @memoize()
    renderAvatar(id, type, modifiedDate, image, name, iconColor, assignee, primary, processDefinitionVersion) {
        switch (type) {
            case 'user': {
                const avatarImg = image ? getAttachmentUrl(id, type, image) : null;
                return <Avatar src={avatarImg} initials={name} color={iconColor} />;
            }
            case 'task': {
                const { priority, progress, closedDate } = primary || {};
                const assigneeImage = assignee?.image && getAttachmentUrl(get(assignee, 'id'), 'user', assignee.image);
                return getFieldByType('avatar', {
                    CircularProgressStaticProps: {
                        value: progress || 0,
                        priority,
                        disabled: !!closedDate
                    },
                    initials: get(assignee, 'name') || null,
                    src: assigneeImage
                });
            }
            case 'process': {
                const { iconName, iconColor, iconType } = processDefinitionVersion || {};
                const { closedDate } = primary || {};
                return (
                    <ProcessAvatar iconColor={iconColor}>
                        {getFieldByType('avatar', {
                            CircularProgressStaticProps: {
                                value: 0,
                                disabled: !!closedDate,
                                priority: get(primary, 'priority')
                            },
                            children: <Icon type={iconType} name={iconsSet.has(iconName) ? iconName : 'asterisk'} />
                        })}
                    </ProcessAvatar>
                );
            }
            default:
                return <Avatar src={image} initials={name} />;
        }
    }

    @bind
    @memoize()
    renderTypeIcon(type, iconName, iconColor, iconType) {
        const { entitiesView, entityType } = this.props;
        if (entitiesView) {
            const icon = getAttribute(this.props.data, 'iconAttribute') || 'null';
            const color = getAttribute(this.props.data, 'colorAttribute') || '#00BCD4';
            const iconType = getAttribute(this.props.data, 'iconTypeAttribute') || 'mdi';
            return <Icon type={iconType} name={icon} size="sm" hexColor={color} />;
        }
        if (iconName) {
            return <Icon type={iconType} name={iconName} size="sm" hexColor={iconColor} />;
        }
        return <TypeIcon type={entityType ? 'entityType' : type} />;
    }

    @bind
    @memoize()
    handleInfoDivider(data) {
        const infoType = ['dueDate', 'modifiedDate', 'assignee', 'createdBy'];
        return data.reduce((acc, current, index) => (current ? infoType[index] : null));
    }

    render() {
        const { icons, processInitiator, data, index, resize, style, selectedItem, sidebarTitle, 
            entitiesView, entityType, isPrintReports, userId, pipelineMonitor, adminBackgroundJobs
        } = this.props;
        const {
            classes,
            image,
            name,
            assignee,
            id,
            iconName,
            iconColor,
            iconType,
            modifiedDate,
            createdDate,
            createdBy,
            modifiedBy,
            primary,
            processDefinitionVersion,
            process,
            primaryClass,
            isSystem,
            role,
        } = data;
        const type = entitiesView ? data?.type || this.props.type : this.props.type;
        const { priority: entityPriority, dueDate, closedDate } = primary || {};
        const disabled = !!closedDate;
        const isSelected = selectedItem === id;
        const isEntity = type !== 'task' && type !== 'process' && type !== 'user';
        const isTask = type === 'task';
        const isProcess = type === 'process';
        const priority = type === 'process' ? get(primary, 'priority') : entityPriority;
        const priorityColor = disabled ? 'disabled' : getPriorityColor(priority);
        const dividerInfo = this.handleInfoDivider([dueDate, modifiedDate, assignee, createdBy]);
        const permissions = getPermissions(role);
        const { canComment } = permissions;
        const isTeamMember = type === 'team' ? checkIfUserIsMember(userId, data) : false;
        const hideChatActionForTypes = ['class', 'team', 'workspace'];

        return (
            <ResizableListItem style={style} key={index} index={index} resize={resize} padding={8}>
                {resizeRow => (
                    <CardContainer priority={!isEntity && type !== 'user' && priorityColor} onClick={() => this.openSidebar(sidebarTitle || 'About')}>
                        <CardStyled selected={isSelected}>
                            <Box display="flex" flexGrow={1} flexDirection={isMobile ? 'column' : 'row'} height="100%">
                                {image && type !== 'user' && (
                                    <Box display="flex" pt={3} pb={3} pl={3}>
                                        <CardImage image={image && getAttachmentUrl(id, type, image)} className={isMobile ? 'mobile' : ''} />
                                        {!isMobile && <Divider orientation="vertical" />}
                                    </Box>
                                )}
                                <CardDetail flexGrow={1}>
                                    <CardHeaderStyled
                                        avatar={
                                            !isEntity &&
                                            this.renderAvatar(id, type, modifiedDate, image, name, iconColor, assignee, primary, processDefinitionVersion)
                                        }
                                        title={
                                            <>
                                                {this.renderTypeIcon(type, iconName, iconColor, iconType)}
                                                <CardDivider flexItem orientation="vertical" />
                                                {this.props.entitiesView ? (
                                                    <EntityTitle data={data} type={type} />
                                                ) : (
                                                    <EntityLinkStyled  id={id} type={type} entityType={entityType} 
                                                        pipelineMonitor={pipelineMonitor} isPrintReports={isPrintReports} 
                                                        adminBackgroundJobs={adminBackgroundJobs} >
                                                        {name || 'No Name'}
                                                    </EntityLinkStyled>
                                                )}
                                                { data?.type === 'closedtask' && <ChipClosed />}
                                                { isSystem && <ChipClosed label={`System ${entityType ? 'Type' : 'Class'}`} /> }
                                            </>
                                        }
                                        subheader={
                                            <>
                                                <EntityLinkStyledSubTitle color="secondary" variant="caption" noWrap title="Copy ID to clipboard" onClick={e => this.copyIdToClipBoard(e, id)} >
                                                    {`#${toUniqueUUID(id)}`}
                                                </EntityLinkStyledSubTitle>
                                                <LinkIcon title="Copy link to clipboard" name="link" size="sm" onClick={e => this.copyLinkToClipBoard(e, id, type, entityType, isPrintReports, entitiesView, pipelineMonitor)} />
                                            </>
                                        }
                                        action={
                                            <>
                                                {!hideChatActionForTypes.includes(type) && isVisibleTab('A-Live', primaryClass) && canComment && (
                                                    <IconButton aria-label="chat" onClick={e => this.handleOpenSidebar(e, 'A-Live')}>
                                                        <Icon name="messenger" type="af" />
                                                    </IconButton>
                                                )}
                                                {type === 'team' && isVisibleTab('A-Live', primaryClass) && isTeamMember && (
                                                    <IconButton aria-label="chat" onClick={e => this.handleOpenSidebar(e, 'A-Live')}>
                                                        <Icon name="messenger" type="af" />
                                                    </IconButton>
                                                )}
                                                {this.dotMenu(this.props.dotMenu)}
                                            </>
                                        }
                                    />
                                    <CardDividerTitle />
                                    <CardContentStyled>
                                        <Box mb={2}>{this.renderIcon(data, type, icons)}</Box>
                                        <CardInfoStyled>
                                            {dueDate && (
                                                <CardInfo noDivider={dividerInfo === 'dueDate'}>
                                                    Due{' '}
                                                    <Tooltip title={displayByKind('datetime', dueDate)} placement="bottom">
                                                        <span> {fromNow(dueDate)} </span>
                                                    </Tooltip>
                                                </CardInfo>
                                            )}
                                            {modifiedDate && (
                                                <CardInfo noDivider={dividerInfo === 'modifiedDate'}>
                                                    Last modified{' '}
                                                    <Tooltip title={displayByKind('datetime', modifiedDate)} placement="bottom">
                                                        <span> {fromNow(modifiedDate)} </span>
                                                    </Tooltip>
                                                    {' by '}
                                                    <span>{modifiedBy ? modifiedBy.name : 'none'}</span>
                                                </CardInfo>
                                            )}
                                            {assignee && (
                                                <CardInfo noDivider={dividerInfo === 'assignee'}>
                                                    {assignee && (
                                                        <>
                                                            Assigned to <span>{assignee.name}</span>
                                                        </>
                                                    )}
                                                </CardInfo>
                                            )}
                                            {createdBy && (
                                                <>
                                                    {!isTask && !isProcess && (
                                                        <CardInfo>
                                                            Created by <span>{createdBy.name}</span>
                                                        </CardInfo>
                                                    )}
                                                    <CardInfo noDivider={dividerInfo === 'createdBy' && !processInitiator}>
                                                        Created{' '}
                                                        <Tooltip title={displayByKind('datetime', createdDate)} placement="bottom">
                                                            <span> {fromNow(createdDate)} </span>
                                                        </Tooltip>
                                                    </CardInfo>
                                                </>
                                            )}
                                            {processInitiator && isTask && process && (
                                                <>
                                                    <CardInfo>
                                                        Process name <span>{process.name}</span>
                                                    </CardInfo>
                                                </>
                                            )}
                                            {processInitiator && (isProcess || isTask) && (
                                                <>
                                                    <CardInfo noDivider>
                                                        {isTask ? 'Process started by' : 'Started by'} <span>{processInitiator.name}</span>
                                                    </CardInfo>
                                                </>
                                            )}
                                        </CardInfoStyled>
                                        {(classes || []).length > 0 && (
                                            <Box mt={4} display="flex">
                                                {this.buildClasses(classes)}
                                            </Box>
                                        )}
                                    </CardContentStyled>
                                </CardDetail>
                            </Box>
                        </CardStyled>
                    </CardContainer>
                )}
            </ResizableListItem>
        );
    }
}

export default connect(
    (state, props) => ({
        processInitiator: get(state.admin.users, 'references.data', []).find(
            user => user.id === get(props.data, 'primary.variables.INITIATOR') || user.id === get(props.data, 'process.variables.INITIATOR') // for process cards
        ) // for tasks cards),
    }),
    {
        openEntitySidebar,
        openClassSidebar,
        openTaskSidebar,
        openTeamSidebar,
        openProcessSidebar,
        openWorkspaceSidebar,
        showToastr
    }
)(EntitiesListCardsItem);
