/* @flow */
import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Button, CircularProgress, TextField } from '@mic3/platform-ui';

import UserAboutSection from 'app/components/organisms/UserSections/UserAboutSection';
import UserMainSection from 'app/components/organisms/UserSections/UserMainSection';
import UserCredentialsSection from 'app/components/organisms/UserSections/UserCredentialsSection';
import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import HeaderBar from 'app/components/molecules/HeaderBar/HeaderBar';
import Container from 'app/components/atoms/Container/Container';
import { loadUserReferences } from 'store/actions/admin/usersActions';
import { addWorkspaceUsers, removeWorkspaceUsers } from 'store/actions/admin/workspacesActions';
import { addTeamUsers, loadTeams, removeTeamUsers } from 'store/actions/admin/teamsActions';
import { loadUserWorkspaces, setUserDefaultIdentityProvider } from 'store/actions/admin/userManagementAction';
import { loadEntity, updateEntity } from 'store/actions/entities/entitiesActions';
import { uploadImage } from 'store/actions/entities/entitiesActions';
import { loadSecurityGeneral, setDocumentTitle } from 'store/actions/app/appActions';
import ExpansionCard from 'app/components/molecules/ExpansionCard/ExpansionCard';

import { bind } from 'app/utils/decorators/decoratorUtils';
import Immutable, { set } from 'app/utils/immutable/Immutable';
import validate from 'validate.js';
import Icon from 'app/components/atoms/Icon/Icon';
import { getOnlyUpdatedData } from 'app/utils/app/appUtils';

const IconStyled = styled(Icon)`
    padding-right: 5px;
    &:before {
        color: #9fa3ab;
    }
`;

const EmailField = styled.div`
    display: flex;
    align-items: center;
`;

/**
 * General tab in users view.
 * Todo: We probably should extract the form in it's own component, however
 * nearly the only code here is form related.
 */
class UserAbout extends PureComponent<Object, Object> {
    state = { user: Immutable(this.props.user), errors: {} };

    static propTypes: Object = {
        user: PropTypes.object,
        updateEntity: PropTypes.func.isRequired,
        isOnSidebar: PropTypes.bool
    };

    constructor(props) {
        super(props);
        this.loadUserTeams();
        this.loadUserWorkspaces();
        this.props.loadSecurityGeneral();
    }

    componentDidUpdate(prevProps: Object) {
        const { user, teams, workspaces, isSidebar, setDocumentTitle } = this.props;
        const { name } = user || {};
        if(!isSidebar && name){
            setDocumentTitle(name);
        }
        if (prevProps.user !== user) {
            this.setState({ user: Immutable(this.props.user) }, () => {
                this.loadUserTeams();
                this.loadUserWorkspaces();
            });
        }
        if (prevProps.workspaces !== workspaces) {
            this.setState({ user: set(this.state.user, 'workspaces', workspaces) });
        }
        if (prevProps.teams !== teams) {
            this.setState({ user: set(this.state.user, 'teams', teams) });
        }
    }

    @bind
    uploadImage(image) {
        const {
            user: { id, type },
            loadEntity
        } = this.props;
        return this.props.uploadImage(id, type, image).then((resp) => {
            !(resp instanceof Error) && loadEntity('user', id);
        });
    }

    @bind
    async onFormSubmit(event: Object) {
        event.preventDefault();
        const { user, defaultProvider } = this.state;
        const { reloadList, setUserDefaultIdentityProvider, updateEntity } = this.props;
        const email = user?.primary?.email || '';
        const errorMessage = validate({ email }, { email: { email: true } })?.email?.[0];
        if (errorMessage) {
            return this.setState({ errors: { email: errorMessage } });
        }
        this.setState({ errors: {} });
        const { id, name, description, primary, active, enableGis, iconName, iconColor, geom, teams, workspaces } = user;
        const { defaultIdentityProvider, ...primaryRest } = primary;
        const userData = {name, description, primary: primaryRest, active, enableGis, iconName, iconColor, geom, };

        if(defaultProvider !== undefined) {
            await setUserDefaultIdentityProvider(user.id, defaultProvider);
            this.setState({ defaultProvider: undefined });
        }
        const updatedUserData = getOnlyUpdatedData(this.props.user, userData);
        const entityMeta = { id, type: 'user', primary: { email }};
        // always trigger the mutation to sync the flow with all detail pages of app
        await updateEntity({ ...entityMeta, ...updatedUserData});
        await this.addUserToTeams(teams, id);
        await this.addUserToWorkspaces(workspaces, id);
        if (reloadList) {
            reloadList();
        }
    }

    @bind
    async loadUserTeams() {
        const { user } = this.props;
        await this.props.loadTeams({ options: { filterBy: [{ field: 'users.user.id', op: '=', value: user?.id }] } });
    }

    @bind
    async loadUserWorkspaces() {
        const { user } = this.props;
        await this.props.loadUserWorkspaces(user?.id);
    }

    @bind
    async addUserToTeams(newTeams, userId) {
        const { removeTeamUsers, addTeamUsers, teams: prevTeams } = this.props;
        if (newTeams !== prevTeams && newTeams) {
            const addedPromises = newTeams.map(async (team) => {
                if (!prevTeams.find(t => t.id === team.id)) {
                    await addTeamUsers(team.id, [userId]);
                }
            }, []);
            const missed = prevTeams.filter(tm => !newTeams.find(t => tm.id === t.id));
            const missedPromises = missed.map(async (team) => {
                await removeTeamUsers(team.id, [userId]);
            });

            await Promise.all(addedPromises);
            await Promise.all(missedPromises);
            await this.loadUserTeams();
        }
    }

    @bind
    async addUserToWorkspaces(newWorkspaces, userId) {
        const { removeWorkspaceUsers, addWorkspaceUsers, workspaces: prevWorkspaces } = this.props;
        if (newWorkspaces !== prevWorkspaces && newWorkspaces) {
            const addedPromises = newWorkspaces.map(async (workspace) => {
                if (!prevWorkspaces.find(t => t.id === workspace.id)) {
                    await addWorkspaceUsers(workspace.id, [{ userId, role: 'editor' }]);
                }
            }, []);
            const missed = prevWorkspaces.filter(tm => !newWorkspaces.find(t => tm.id === t.id));
            const missedPromises = missed.map(async (workspace) => {
                await removeWorkspaceUsers(workspace.id, [userId]);
            });

            await Promise.all(addedPromises);
            await Promise.all(missedPromises);
            await this.loadUserWorkspaces();
            
        }
    }

    @bind
    renderSaveButton() {
        const { isLoading, canEdit } = this.props;
        if (!canEdit) {
            return null;
        }
        return isLoading ? (
            <CircularProgress key={113} size={24} color="primary" />
        ) : (
            <Button key={113} onClick={this.onFormSubmit} color="primary" form="form" type="submit">
                Save
            </Button>
        );
    }

    @bind
    handleChange(e) {
        e?.preventDefault();
        const { target: { name, value } = {} } = e;

        if (name === 'image') {
            return this.uploadImage(value);
        }
        const user = set(this.state.user, name, value);
        this.setState({ user });
    }

    @bind
    handleChangeProvider(defaultProvider) {
        this.setState({ defaultProvider });
    }

    render(): Object {
        const { user, errors } = this.state;
        const { primary } = user || {};
        const { canEdit, openSidebar, isOnSidebar, reloadList, loadUserReferences, loadEntity, isSidebar } = this.props;

        return (
            <Fragment>
                <HeaderBar left={this.props.breadcrumbLine} right={[this.renderSaveButton(), ...(this.props.sidebarActions || [])]} />
                <ContentArea withHeader isSidebar={isSidebar}>
                    <Container width="1024">
                        <UserMainSection data={user} canEdit={canEdit} isOnSidebar={isOnSidebar} reloadList={reloadList}
                            loadUserReferences = {loadUserReferences} />
                        <UserAboutSection data={user} canEdit={canEdit} openSidebar={openSidebar} />
                        <ExpansionCard
                            expanded
                            title="Profile"
                        >
                            <EmailField>
                                <IconStyled name="email" color="#9fa3ab" />
                                <TextField
                                    label="Primary email address"
                                    onChange={this.handleChange}
                                    value={primary?.email}
                                    name="primary.email"
                                    helperText={errors.email}
                                    error={!!errors.email}
                                />
                            </EmailField>
                        </ExpansionCard>
                        <UserCredentialsSection user={user} loadEntity={loadEntity} onChangeProvider={this.handleChangeProvider} />
                    </Container>
                </ContentArea>
            </Fragment>
        );
    }
}

export default connect(
    state => ({
        teams: state.admin.teams.list.records,
        workspaces: state.admin.users.workspaces.records
    }),
    {
        loadEntity,
        updateEntity,
        uploadImage,
        addTeamUsers,
        loadTeams,
        removeTeamUsers,
        loadUserWorkspaces,
        addWorkspaceUsers,
        removeWorkspaceUsers,
        loadUserReferences,
        setUserDefaultIdentityProvider,
        loadSecurityGeneral,
        setDocumentTitle
    }
)(UserAbout);
