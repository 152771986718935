/* @flow */
import React from 'react';

import ScriptSidebar from 'app/containers/Sidebars/ScriptSidebar';

import {
    buildSidebar as buildSidebarAction,
    closeSidebar as closeSidebarAction
} from 'store/actions/sidebar/sidebarActions';

export const openScriptSidebar = ({ id, type, title, iconName, iconType }) => async (dispatch, getState) => {
    return buildSidebarAction({
        iconName, 
        iconType,
        title,
        content: <ScriptSidebar id={id} type={type} />,
        isOpen: true,
    })(dispatch, getState);
};

export const closeScriptSidebar = closeSidebarAction;
