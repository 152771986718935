/* @flow */

import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Avatar, Typography, Grid } from '@mic3/platform-ui';

import { getAttachmentUrl } from 'app/utils/attachments/attachmentsUtils';

const AvatarStyled = styled(Avatar)`
    width: 24px !important;
    height: 24px !important;
    font-size: 1rem !important;
    margin-right: 16px;
`;
const TypographyStyled = styled(Typography)`
    font-size: 14px !important;
`;

class GridEntityUser extends PureComponent<Object, Object> {

    render() {
        const { value } = this.props;
        const { image: src, name, id } = value || {};

        if(!id) return null;

        const image = src ? getAttachmentUrl(id, 'user', src): null;

        return (
            <Grid container wrap="nowrap" alignItems="center">
                <AvatarStyled src={image} initials={name} />
                <TypographyStyled>{name}</TypographyStyled>
            </Grid>
        );
    }
};

export default GridEntityUser;
