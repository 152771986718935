/* @flow */
import React from 'react';

import MapSidebar from 'app/containers/Sidebars/MapSidebar';

import {
    buildSidebar as buildSidebarAction,
    closeSidebar as closeSidebarAction,
    expandSidebar,
    shrinkSidebar,
} from 'store/actions/sidebar/sidebarActions';

export const openMapSidebar = (props: Object) => async (dispatch, getState) => {
    return buildSidebarAction({
        title: props.title,
        content: <MapSidebar {...props} />,
        isOpen: true
    })(dispatch, getState);
};

export const closeMapSidebar = closeSidebarAction;
export const expandMapSidebar = expandSidebar;
export const shrinkMapSidebar = shrinkSidebar;
