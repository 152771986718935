/* @flow */

import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from '@mic3/platform-ui';

import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import PageNotAllowed from 'app/containers/ErrorPages/PageNotAllowed';
import ChatAddMenu from 'app/components/organisms/Chat/ChatAddMenu';
import PageTemplate from 'app/components/templates/PageTemplate';
import AliveChat from 'app/components/organisms/Chat/AliveChat';
import Icon from 'app/components/atoms/Icon/Icon';
import Loader from 'app/components/atoms/Loader/Loader';

import { resetRoomDefaultRelValue } from 'store/actions/chat/chatActions';
import { openChatLeftPanel } from 'store/actions/chat/chatLeftPanelActions';

import { OpenLeftSidebarButton } from 'app/utils/entity/entityUtils';
import { bind } from 'app/utils/decorators/decoratorUtils';
import { getStr } from 'app/utils/utils';
import { addRoleToData } from 'app/config/rolesConfig';
import { setDocumentTitle } from 'store/actions/app/appActions';
import { modulesAndPageTitles } from 'app/config/typesConfig';

const ContentAreaStyled = styled(ContentArea)`
    ${({ noSelected }) => (noSelected ? 'display: flex;' : '')}
    ${({ noSelected }) => (noSelected ? 'align-items: center;' : '')}
    ${({ noSelected }) => (noSelected ? 'justify-content: center;' : '')}
`;

const NoSelectedChat = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 60px;
    font-weight: 200;
    color: ${({theme})=> theme.material.colors.text.caption};
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    @media (max-width:481px){
        font-size: 24px;
    }
`;

const IconStyled = styled(Icon)`
    margin-right: 30px;
    @media (max-width:481px){
        margin-right: 12px;
    }
    &:before {
        font-size: 85px !important;
        color: ${({theme})=> theme.material.colors.text.caption};
        @media (max-width:481px){
            font-size: 48px !important;
        }
    }
`;

const ChatContainer = styled.div`
    height: 100%;
    padding: 0 24px;
    @media (max-width:961px){
        padding: 0 8px;
    }
`;

const StyledButton = styled(Button)`
    padding: 12px !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.75px;
    text-transform: uppercase;
`;

class ALive extends PureComponent<Object, Object> {
    /**
     * @const propTypes - describes the properties of the component
     * @const defaultProps - define the defaults values of the properties
     * @const filterDefinitions -definition for columns that we need to display in our grid
     */
    static propTypes = {
        id: PropTypes.string,
        type: PropTypes.string,
        profile: PropTypes.object,
    };

    constructor(props: Object) {
        super(props);
        this.state = {
            role: '',
            roleIsLoading: false,
            addMenuAnchorEl: null
        };
    }

    async processChatRole() {
        const { id, type } = this.props;
        const isEntityChat = type ? !['channel', 'direct', 'group', 'team', 'live'].includes(type) : false;
        if (isEntityChat) {
            this.setState({ roleIsLoading: true });
            const { role } = await addRoleToData({ id, type });
            this.setState({ role: role || '', roleIsLoading: false });
        }
    }

    componentDidMount() {
        const { id, type, resetRoomDefaultRelValue, openChatLeftPanel, setDocumentTitle } = this.props;
        setDocumentTitle(modulesAndPageTitles.abox.alive);
        if (!id || !type) {
            resetRoomDefaultRelValue();
            openChatLeftPanel({ title: 'Messages', entityType: 'alive', type: 'alive', id: '', view: 'alive', isOpen: true });
        }
        this.processChatRole();
    }

    componentDidUpdate(prevProps) {
        const { id, type, resetRoomDefaultRelValue } = this.props;
        const isEntityChat = type ? !['channel', 'direct', 'group', 'live'].includes(type) : false;
        if (id && type) {
            if (isEntityChat && prevProps.id !== id) {
                this.processChatRole();
            }
            if (!isEntityChat && prevProps.type !== type) {
                this.setState({ role: '' });
            }
        }

        if(prevProps.id && !id) {
            resetRoomDefaultRelValue();
        }
    }

    componentWillUnmount() {
        this.props.resetRoomDefaultRelValue();
    }

    @bind
    openAddMenu(e) {
        this.setState({ addMenuAnchorEl: e.currentTarget });
    }

    @bind
    closeAddMenu() {
        this.setState({ addMenuAnchorEl: null });
    }

    render() {
        const { profile, id, type } = this.props;
        const { role, roleIsLoading } = this.state;
        const { permissions, isAdmin } = profile;
        const permissionsSet = new Set(permissions || []);
        const canView = isAdmin || permissionsSet.has('alive.alive');
        const isPwa = window.matchMedia('(display-mode: standalone)').matches;
        const fixHeightSize = isPwa ? 56 : 112;
        if (!canView) {
            return <PageNotAllowed title='Alive' />;
        }

        return (
            <PageTemplate title='A-Live'>
                <ContentAreaStyled noSelected={!id}>
                    {!id ? (
                        <>
                            <OpenLeftSidebarButton />
                            <div style={{textAlign: 'center'}}>
                                <NoSelectedChat>
                                    <IconStyled name='messenger' type='af' />
                                    Let's Get Started
                                </NoSelectedChat>
                                <StyledButton variant='text' color='secondary' onClick={this.openAddMenu}>
                                    Create New
                                </StyledButton>
                            </div>
                        </>
                    ) : (
                        <ChatContainer fixHeightSize={fixHeightSize}>
                            {roleIsLoading ? <Loader absolute backdrop /> : <AliveChat
                                id={id}
                                type={type}
                                role={role}
                            />}
                        </ChatContainer>
                    )}
                    <ChatAddMenu
                        anchorEl={this.state.addMenuAnchorEl}
                        onClose={this.closeAddMenu}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                    />
                </ContentAreaStyled>
            </PageTemplate>
        );
    }
}

export default connect(
    (state, ownProps) => {
        const id = getStr(ownProps, 'match.params.id', '');
        const type = getStr(ownProps, 'match.params.type', '');

        return {
            id,
            type,
            profile: state.user.profile,
        };
    },
    {
        resetRoomDefaultRelValue,
        openChatLeftPanel,
        setDocumentTitle,
    }
)(ALive);
