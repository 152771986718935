// @flow

import { gql } from '@apollo/client';
import { entityReferences } from 'graphql/entities/relationships/relationsQuery';

export default gql`
    query tasksTimelineQuery($filterBy: [JSON], $orderBy: [JSON], $excludeBy: [JSON]) {
        count: count(dataType: "task", filterBy: $filterBy, excludeBy: $excludeBy)
        records: tasks(filterBy: $filterBy, orderBy: $orderBy, excludeBy: $excludeBy) {
            id
            name
            type
            description
            primary(fields: ["assignee", "priority", "startDate", "dueDate", "completion", "progress"])
            assignee {
                id
                name
                image
            }
            owner {
                id
                name
            }
            process {
                id
                name
            }
            createdDate
            modifiedDate
            createdBy {
                id
                name
            }
            modifiedBy {
                id
                name
            }
            relations {
                relatedEntity {
                    ${entityReferences}
                }
                relation {
                    id
                    relationDefinition {
                        id
                        type
                        description
                        relatedDescription
                    }
                }
                isReverseRelation
            }
        }
    }
`;
