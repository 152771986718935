import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Autocomplete, Avatar, ListItemText  } from '@mic3/platform-ui';

import { loadEntityTypesTypeahead } from 'store/actions/entities/entitiesActions';
import { loadPrintTemplateAutocomplete } from 'store/actions/entities/printTemplateActions';
import { isValiduuid } from 'app/utils/string/string-utils';
import { bind, debounce } from 'app/utils/decorators/decoratorUtils';
import { TypeaheadListItem } from 'app/components/organisms/Form/Typeahead/abstract/TypeaheadListItem';
import { TypeaheadChipInitials, TypeaheadAdornmentInitials } from 'app/components/organisms/Form/Typeahead/abstract/TypeaheadChip';

/**
 * Select one or more groups using lazy loading.
 */
class PrintTemplateTypeahead extends PureComponent<Object, Object> {

    static propTypes = {
        loadPrintTemplateAutocomplete: PropTypes.func.isRequired,
        filterBy: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.array])),
        excludeBy: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.array])),
    }


    constructor(props) {
        super(props);

        this.state = { options: [], isLoading: false };
        const { entityTypes, loadEntityTypesTypeahead } = props;
        if(!entityTypes?.length) {
            loadEntityTypesTypeahead();
        }
    }

    componentDidUpdate(prevProps) {
        const { entityTypes } = this.props;
        if(!prevProps.entityTypes?.length && entityTypes.length) {
            this.suggest();
        }
    }

    componentDidMount() {
        const { entityTypes } = this.props;
        if(entityTypes.length) {
            this.suggest();
        }
    }

        @bind
    optionTemplate({ name, id, modifiedDate }: Object) {
        return ({
            option: (
                <TypeaheadListItem ContainerComponent="div" dense disableGutters>
                    <Avatar initials={name} />
                    <ListItemText primary={name} secondary={<small>#{id}</small>} />
                </TypeaheadListItem>
            ),
            ChipProps: {
                avatar: <TypeaheadChipInitials initials={name} />,
            },
            startAdornment: <TypeaheadAdornmentInitials initials={name} />,
            label: name,
        });};

        @bind
        @debounce()
        suggest(event: Object) {
            this.setState({ isLoading: true });
            const {
                excludeBy, value, filterBy, loadOptions, valueField, withScriptsOrTasks,
                withScriptsOrApplicableOnType, applicableOnTypeId, withScripts
            } = this.props;
            const query = event?.target?.value;
            const filters = filterBy || [];
            if (query) {
                if (isValiduuid(query)) {
                    filters.push({ or: [
                        { field: 'id', op: '=', value: query },
                        { field: 'name', op: 'contains', value: query },
                    ] });
                } else {
                    filters.push({ field: 'name', op: 'contains', value: query });
                }
            }
            if (query && value) {
                const selected = Array.isArray(value) ? value : [value];
                filters.push(
                    ...selected.map(op => ({ field: valueField || 'id', op: '<>', value: valueField ? value : op[valueField || 'id'] }))
                );
            }

            if(withScripts) {
                filters.push({ field: 'primary.print-template/script', op: 'is not null' });
            }

            if(withScriptsOrTasks) {
                const { entityTypes }= this.props;
                filters.push({ or: [
                    { field: 'primary.print-template/script', op: 'is not null' },
                    ...['opentask', 'closedtask', 'system_task'].map((type) => entityTypes?.map(cls => [type].includes(cls?.uri)  ? { field: 'primary.print-template/applicable_on', op: 'contains', value: [cls.id] } : null ).filter(Boolean)).flat(),
                ]});
            }

            if(withScriptsOrApplicableOnType) {
                filters.push({ or: [
                    { field: 'primary.print-template/script', op: 'is not null' },
                    applicableOnTypeId && { field: 'primary.print-template/applicable_on', op: 'contains', value: [applicableOnTypeId] }
                ].filter(Boolean)});
            }

            loadOptions({
                filterBy: filters,
                excludeBy: excludeBy,
                orderBy: [ { field: 'name', direction: 'asc nulls last' } ],
            }).then((options) => {
                this.setState({ options: options.records, isLoading: false });
            }).catch((error) => {
                this.setState({ options: [], isLoading: false });
            });
        };

        render() {
            // remove the properties that we do not have to pass to the AbstractEntityAutocomplete
            const { excludeBy, filterBy, orderBy, loadOptions, processDefinitions, ...entityGraphicTypeaheadProps } = this.props;
            const { options, isLoading } = this.state;
            return (
                <Autocomplete
                    placeholder="Search for a print template..."
                    {...entityGraphicTypeaheadProps}
                    VirtualListProps={{
                        itemSize: 60,
                    }}
                    options={options}
                    isLoading={isLoading}
                    optionTemplate={this.optionTemplate}
                    suggest={this.suggest}
                />
            );
        }
};

export default connect(
    (state: Object, props: Object) => ({
        entityTypes: state.entities.entityTypes.data?.records || [],
    }),
    { loadOptions: loadPrintTemplateAutocomplete, loadEntityTypesTypeahead }
)(PrintTemplateTypeahead);
