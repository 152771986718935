/* @flow */

import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import { RouterMatchPropTypeBuilder } from 'app/utils/propTypes/common';
import PrintTemplateDetails from 'app/containers/Entities/PrintTemplates/PrintTemplateDetails';
import PrintTemplatesListView from 'app/containers/Entities/PrintTemplates/PrintTemplatesListView';
/**
 * Defines the routes for the Relation Definitions
 */
class PrintTemplatesRoutes extends PureComponent<Object, Object> {
    static propTypes = {
        match: RouterMatchPropTypeBuilder({ id: PropTypes.string }),
        userProfile: PropTypes.object
    };

    /**
     * @override
     * Always make sure you use PageTemplate as PageContainer
     */
    render() {
        const { match, isPrintReports } = this.props;
        return (
            <Fragment>
                <Switch>
                    <Route path={`${match.url}`} exact render={props => <PrintTemplatesListView {...props} isPrintReports={!!isPrintReports} />} />
                    <Route path={`${match.url}/:id`} render={props => <PrintTemplateDetails {...props} isPrintReports={!!isPrintReports} />} />
                </Switch>
            </Fragment>
        );
    }
}

export default connect(state => ({
    userProfile: state.user.profile
}))(PrintTemplatesRoutes);
