/* @flow */

import { gql } from '@apollo/client';
import commonProcessDetailsQueryFields from 'graphql/abox/process/commonProcessDetailsQueryFields';

export default gql`
    mutation cancelProcess($id: ID!, $closureReason: String) {
        result: cancelProcess(id: $id, closureReason: $closureReason) {
            ${commonProcessDetailsQueryFields}
        }
    }
`;
