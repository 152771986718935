/* @flow */

import { gql } from '@apollo/client';

export default gql`
    query classesListRaisedQuery($startIndex: Int, $stopIndex: Int, $filterBy: [JSON], $orderBy: [JSON], $excludeBy: [JSON]) {
        count: count(dataType: "class", filterBy: $filterBy, excludeBy: $excludeBy)
        records: classes(startIndex: $startIndex, stopIndex: $stopIndex, filterBy: $filterBy, orderBy: $orderBy, excludeBy: $excludeBy) {
            id
            name
            uri
            applicableOn
            active
            abstract
            primary
            createdDate
            color
            icon
            iconType
            createdBy {
                id
                name
                image
            }
            modifiedDate
            modifiedBy {
                id
                name
                image
            }
            disabledBy {
                id
                name
                image
            }
            disabledDate
            parents {
                id
                name
                uri
            }
            translation
            isSystem
        }
    }
`;
