/* @flow */
import React from 'react';

import SidebarMenu from 'app/components/organisms/SidebarMenu/SidebarMenu';

import {
    buildSidebar as buildSidebarAction,
    closeSidebar as closeSidebarAction,
    setOnBack
} from 'store/actions/sidebar/sidebarActions';

export const openMenuSidebar = ({ title, ...rest }) => async (dispatch, getState) => {
    return buildSidebarAction({
        title,
        content: <SidebarMenu setOnBack={(...args) => setOnBack(...args)(dispatch, getState)} {...rest}/>,
        isOpen: true,
    })(dispatch, getState);
};

export const closeMenuSidebar = closeSidebarAction;
