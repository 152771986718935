import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Button } from '@mic3/platform-ui';
import styled from 'styled-components';

import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import { uploadEntityGraphicLibrary, updateEntityGraphicLibrary, loadGraphicDetails } from 'store/actions/common/graphicLibraryActions';
import ActionBar from 'app/components/molecules/ActionBar/ActionBar';
import { getAttachmentUrl } from 'app/utils/attachments/attachmentsUtils';

const GraphiImage = styled.img`
    width: 100%;
    height: 100%;
`;

class GraphicSidebar extends PureComponent<object, object> {
    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.loadData();
    }

    componentDidUpdate(prevProps){
        const { id }= this.props;
        if (id !== prevProps.id) {
            this.loadData();
        }
    }

    @bind
    loadData() {
        const { id } = this.props;
        this.props.loadGraphicDetails(id);

    }

    @bind
    @memoize()
    buildComponents(data) {
        const { id, name, image } = data || {};
        const imageSource = image ? getAttachmentUrl(id, 'graphic', image) : null;
        return [
            {
                field: 'panel',
                type: 'panel',
                properties: {
                    header: 'Basic',
                    expanded: false
                },
                children: [
                    {
                        field: 'id',
                        type: 'text',
                        properties: {
                            label: 'ID',
                            name: 'id',
                            disabled: true
                        }
                    },
                    {
                        field: 'modifiedDate',
                        type: 'dateTime',
                        properties: {
                            label: 'Last Modified',
                            name: 'modifiedDate',
                            disabled: true
                        }
                    },
                    {
                        field: 'createdDate',
                        type: 'dateTime',
                        properties: {
                            label: 'Created On',
                            name: 'createdDate',
                            disabled: true
                        }
                    }
                ]
            },
            image && {
                field: 'panel',
                type: 'panel',
                properties: {
                    header: 'Graphic Image',
                    expanded: true
                },
                children: [
                    {
                        field: '',
                        type: 'custom',
                        properties: {
                            label: 'Graphic Image',
                            ckey: imageSource,
                            Component: () => <GraphiImage src={imageSource} alt={name} />
                        }
                    }
                ]
            },
            {
                field: 'panel',
                type: 'panel',
                properties: {
                    header: 'Graphic Details',
                    expanded: true
                },
                children: [
                    {
                        field: '',
                        type: 'uploadFile',
                        properties: {
                            name: 'image',
                            label: 'File upload',
                            accept: '.svg',
                            disabled: !this.props.canEdit
                        }
                    },
                    {
                        field: 'name',
                        type: 'text',
                        properties: {
                            label: 'Name',
                            name: 'name',
                            disabled: !this.props.canEdit
                        },
                        constraints: {
                            required: true,
                            maxLength: 60,
                            minLength: 3
                        }
                    },
                    {
                        field: 'description',
                        type: 'textarea',
                        properties: {
                            label: 'Description',
                            name: 'description',
                            disabled: !this.props.canEdit
                        }
                    }
                ]
            }
        ].filter(Boolean);
    }

    @bind
    uploadImage(image) {
        const { siderbarData } = this.props;
        const { id, name } = siderbarData || {};
        this.props.uploadEntityGraphicLibrary({ id, name, image, type: 'graphic' }).then((resp) => {
            if(!(resp instanceof Error)) {
                this.props.reloadList();
                this.loadData();
            };
        });
    }

    @bind
    handleChange(data: Object, { name, value }: Object) {
        if (name === 'image' && value) {
            return this.uploadImage(value);
        }
    }

    @bind
    onFormSubmit(event: Event) {
        event.preventDefault();
        this.formRef.current.isValidForm().then(({ data, errors }) => {
            if (!errors) {
                data = {...data, name: data?.name.trim()};
                this.props.updateEntityGraphicLibrary(data).then((result) => {
                    if (!(result instanceof Error) && result.id) {
                        this.props.reloadList();
                        this.loadData();
                    };
                });
            }
        });
    }

    render() {
        const { details } = this.props;
        return (
            <div>
                <ActionBar
                    right={
                        <Button onClick={this.onFormSubmit}>
                            Save
                        </Button>
                    }
                ></ActionBar>
                <FormGenerator data={details} onChange={this.handleChange} components={this.buildComponents(details)} ref={this.formRef} />
            </div>
        );
    }
}

export default connect(state => ({
    details: state.entities.graphics.details.data,
    isLoading: state.entities.graphics.details.isLoading,
}), {
    uploadEntityGraphicLibrary,
    updateEntityGraphicLibrary,
    loadGraphicDetails
})(GraphicSidebar);
