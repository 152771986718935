import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import ListItem from 'app/components/molecules/List/ListItem';
import ListItemPinsActions from 'app/components/molecules/Map/LayerList/ListItemPinsActions';
import { Divider, Collapse } from '@mic3/platform-ui';
import Icon from 'app/components/atoms/Icon/Icon';
import { getAttachmentUrl } from 'app/utils/attachments/attachmentsUtils';
import { iconsSet } from 'app/utils/styles/mdi';
import { setSelectedLayer } from 'store/actions/maps/situationalAwarenessActions';
import Feature from 'ol/Feature';
import { Point } from 'ol/geom';
import { getFeatureFromWKT } from 'app/utils/maps/layer/layerUtils';

const ScrollableContent = styled.div`
    max-height: 300px;
    overflow-y: auto;
`;

const ImageColumn = styled.div`
    background: url('${({ src }) => src}') center center;
    background-size: 100% 100%;;
    width: ${({ isDrawing }) => !isDrawing ? '25px' : '100px'};
    height: ${({ isDrawing }) => !isDrawing ? '25px' : '56px'};
`;

const Content = styled.div`
    display: flex;
`;

const VerticalDivider = styled(Divider)`
    margin-left: 5px !important;
    margin-right: 10px !important;
`;

const StyledSpan = styled.span`
    cursor: pointer;
    color: ${({theme})=> theme.material.colors.text.secondary};
`;

const StyledListItem = styled(ListItem)`
    ${({ theme, selected }) => selected && `background-color: ${theme.material.colors.itemActive} !important;`}
`;

const ImageComponent = ({ id, type, image, isDrawing }) => {
    const src = (id && type) ? getAttachmentUrl(id, type) : image;
    return <ImageColumn src={src} isDrawing={isDrawing} />;
};

const hoverEntity = (feature: Object, map: Object) => {
    const wktFeature = getFeatureFromWKT(feature);
    if (wktFeature) {
        const coordinates = wktFeature.getGeometry()?.getCoordinates();
        map && coordinates && map.highlightFeature(coordinates, 1);
    }
};

const stopHovering = (map: Object) => {
    const highlightOverlay = map?.getMap().getOverlayById('hoverOverlay');
    map && map.closeOverlay(highlightOverlay);
};

const zoomInToFeature = (featureWkt: Object, map: Object, drawingLoc, zoomExtent) => {
    const feature = getFeatureFromWKT(featureWkt);
    if (feature) {
        const geometry = feature?.getGeometry();
        const geomType = geometry?.getType();
        if (geomType === 'Point') {
            map.flyTo(feature);
        } else {
            const extent = geometry?.getExtent();
            map.getMap()
                .getView()
                .fit(extent, { size: map.getMap().getSize(), maxZoom: 16 });
            map.getMap().render();
        }
    }
    if (drawingLoc) {
        if (zoomExtent) {
            map.getMap()
                .getView()
                .fit(zoomExtent, { size: map.getMap().getSize(), maxZoom: 16 });
            map.getMap().render();
        } else {
            const drawingFeature = new Feature({
                geometry: new Point(drawingLoc)
            });
            map.flyTo(drawingFeature, null, true);
        }
    }
};

const RenderList = ({ list, layerType, map, layerId, itemId, mapId }) => {
    const dispatch = useDispatch();
    const selectedLayer = useSelector(
        state => state.maps.situationalAwareness.layer.selectedLayer,
    );
    const component = list && list.map((feature, index) => {
        const { name, iconName, iconColor, iconType, image, _wkt, _ewkt, ...rest } = layerType === 'Event Layer' ? feature?.device || {} : feature || {};
        const defaultIcon = _wkt ? 'map-marker' : 'map-marker-off';
        const defaultColor = _wkt ? '#00BCD4' : '#808080';
        const featureWkt = _wkt || _ewkt;
        const { id, featureIndex } = selectedLayer || {};
        const isSelected = featureIndex === index && id === layerId;
        const iType = iconType || (iconsSet.has(iconName || defaultIcon) && 'mdi') || 'af';
        switch(layerType) {
            case 'Replay Layer':
            case 'Entity Layer': 
            case 'Event Layer':
                return <ListItem key={index}
                    component={image && <ImageComponent image={image} {...rest} />}
                    title={<Content>
                        <>
                            <Icon name={iconName || defaultIcon} type={iType}  hexColor={iconColor || defaultColor} />
                            <VerticalDivider flexItem orientation="vertical" />
                        </>
                        <StyledSpan
                            onMouseEnter={() => hoverEntity(featureWkt, map)}
                            onMouseLeave={() => stopHovering(map)}
                            onClick={() => featureWkt && zoomInToFeature(featureWkt, map)} name={feature?.values_?.attributes?.name}>
                            {name}
                        </StyledSpan>
                    </Content>}
                />;
            case 'Drawing Layer':
                const drawingImage = feature?.values_?.image || feature?.properties?.attributes?.image ||
                feature?.properties?.properties?.attributes?.image || feature?.properties?.image || '';
                const zoomExtent = feature?.values_?.extent || feature?.properties?.extent || '';
                const drawingLatLong = feature?.values_?.layerCenter || feature?.properties?.attributes?.layerCenter ||
                feature?.properties?.properties?.attributes?.layerCenter || 
                feature?.properties?.properties?.attributes?.layerCenter || feature?.properties?.layerCenter || '';
                return <StyledListItem key={index}
                    onClick={(e) => {
                        e.stopPropagation();
                        dispatch(setSelectedLayer({ type: layerType, id: layerId, featureIndex: index }));
                        const layer = map.findLayerById(id);
                        layer && layer.setProperties({ 'selectedLayer': false });
                        map.removeHightLightFeatures();
                        zoomInToFeature(null, map, drawingLatLong, zoomExtent);
                    }}
                    selected={isSelected}
                    component={drawingImage && <ImageComponent image={drawingImage} {...rest} isDrawing={true} />}
                    actions={<ListItemPinsActions 
                        drawingLatLong={drawingLatLong} 
                        index={index}
                        map={map} 
                        type={layerType}
                        itemId={itemId}
                        id={layerId}
                        mapId={mapId} />}
                        
                />;
            default:
                return null;
        }
    });
    return component;
};

const ListItemPins = (props: Object) => {
    const { expanded, features, layerType, map, layerId, itemId, mapId } = props;
    return (<Collapse in={!!expanded}>
        <ScrollableContent>
            {features?.length > 0 && <RenderList
                list={features}
                layerType={layerType}
                map={map}
                layerId={layerId}
                itemId={itemId}
                mapId={mapId}
            />}
        </ScrollableContent>
    </Collapse>);
};

export default ListItemPins;
