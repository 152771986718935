export const TRANSLATION_KEYS = {
    'active': 'active',
    'assignee': 'assignee',
    'attributes': 'attributes',
    'category': 'category',
    'chat.attachments': 'chat attachment',
    'chat.messages': 'chat message',
    'chatId': 'Chat ID',
    'classResources': 'class',
    'classes': 'classes',
    'closedtask': 'task',
    'closedprocess': 'process',
    'contactInfo': 'contact information',
    'description': 'description',
    'dueDate': 'due date',
    'dateOfBirth': 'birth date',
    'formdefinition': 'formdefinition',
    'entityResources': 'entity',
    'enableGis': 'enable geolocation',
    'geom': 'geometry',
    'iconColor': 'icon color',
    'iconName': 'icon name',
    'iconType': 'icon type',
    'image': 'image',
    'name': 'name',
    'modifiedBy': 'modified by',
    'modifiedDate': 'modified date',
    'workspace': 'workspace',
    'owners': 'owner',
    'organisation': 'organisation',
    'person': 'person',
    'permission': 'permission',
    'processdefinition': 'processdefinition',
    'progress': 'progress',
    'priority': 'priority',
    'relationdefinition': 'relationdefinition',
    'relations': 'relation',
    'sharedUsers': 'user',
    'sharedTeams': 'team',
    'sharedWorkspaces': 'workspace',
    'serialCode': 'UER',
    'startDate': 'start date',
    'script': 'script',
    'team': 'team',
    'teams': 'team',
    'thing': 'thing',
    'user': 'user',
    'users': 'user',
    'opentask': 'task',
    'openprocess': 'process'
};

export const TRANSLATION_ACTION_KEYS = {
    'create': 'created',
    'add': 'added',
    'remove': 'removed',
    'update': 'updated',
    'updateItem': 'updated'
};