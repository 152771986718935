/* @flow */

import { gql } from '@apollo/client';

import { classDetailsFields } from 'graphql/entities/classifications/classificationDetailQuery';

export default gql`
    mutation updateClassificationReporting($entityType: String!, $enabled: Boolean!) {
        result:  entityReporting(entityType: $entityType, enabled: $enabled) {
            ${classDetailsFields}
        }
    }
`;
