/* @flow */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Widget from 'app/components/atoms/Widget/Widget';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import ALiveItem from 'app/containers/Abox/ALive/ALiveItem';
import { sortChats } from 'app/utils/chat/chatUtils';


/**
 * Widget for displaying tasks assigned to the active user
 */
class AliveWidget extends PureComponent<Object, Object> {
    static propTypes = {
        unreadMessages: PropTypes.array,
    };

    constructor(props) {
        super(props);
    }

    @bind
    @memoize()
    renderChatItem(data) {
        return (
            <div key={data.rid}>
                <ALiveItem
                    appearance='medium'
                    data={data}
                    isAliveWidget
                    hideMoreOptions
                />
            </div>
        );
    }
    
    render() {
        const { unreadMessages, isLoading, item } = this.props;
        return (
            <Widget
                title="A-live Messages"
                content={!unreadMessages?.length ? 'No unread messages': unreadMessages.map(this.renderChatItem)}
                url={`/abox/alive`}
                isLoading={isLoading}
                {...item}
            />
        );
    }
}

const mapStateToProps: Function = ( state ) => {
    const subscriptions = state.chat.subscriptions.data || [];
    const unreadMessages = sortChats('activity',subscriptions.filter(({unread})=>unread)).slice(0, 30);
    return {
        unreadMessages,
        isLoading: state.chat.subscriptions.isLoading,
    };
};

export default connect(mapStateToProps, null)(AliveWidget);
