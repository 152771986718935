/* @flow */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Row, Col } from 'react-styled-flexboxgrid';
import Icon from 'app/components/atoms/Icon/Icon';
import { getLabel } from 'app/containers/Classifications/attributeTypes';
import { Button, Tooltip } from '@mic3/platform-ui';

const Container = styled.div`
    cursor: grab;
    padding: 8px;
    min-height: 40px;
    margin-bottom: 8px;
    user-select: none;
    transition: background-color 0.1s ease;
    width: ${({ width }) => `${width}px`};
    ${({ isDragging }) => (isDragging ? 'opacity: 0.5; background: green; margin-left: -58px;' : '')};
    p {
        padding: 0;
        margin: 0;
        line-height: 40px;
    }
    a {
        color: ${({ theme }) => theme.base.textColor};
    }
`;

const StyledIcon = styled(Icon)`
    padding-right: 1rem;
    vertical-align: middle;
    height: 40px;
`;

const LabelStyled = styled.p`
    line-height: 34px !important;
`;

const TextContainer = styled.div`
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

/**
 *
 */
const ListItem = (props: Object) => {
    const { rowData, isDragging, provided, canEdit } = props;
    const { label, name } = rowData.properties;
    return (
        <Container
            isDragging={isDragging}
            ref={provided.innerRef}
            key={label}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
        >
            <Row>
                <Col xs={6} sm={6} md={6} lg={6}>
                    <TextContainer>
                        <StyledIcon name="drag-vertical"/>
                        {!canEdit ? <span title={label}>{label}</span> :
                            <Tooltip title={label} arrow>
                                <span><Button onClick={() => props.editListItem(name)} variant="text">{label}</Button></span>
                            </Tooltip>}
                    </TextContainer>
                </Col>
                <Col xs={5} sm={5} md={5} lg={5}>
                    <LabelStyled>{getLabel(rowData.type)}</LabelStyled>
                </Col>
                <Col xs={1} sm={1} md={1} lg={1}>
                    {!canEdit ? null : <Icon name="delete" onClick={() => props.removeListItem(name)}/>}
                </Col>
            </Row>
        </Container>
    );
};

ListItem.propTypes = {
    rowData: PropTypes.object,
    isDragging: PropTypes.bool,
    provided: PropTypes.any,
    autoFocus: PropTypes.bool,
    classId: PropTypes.string,
    removeListItem: PropTypes.func,
    canEdit: PropTypes.bool,
};

export default ListItem;
