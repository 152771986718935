/* @flow */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, CircularProgress } from '@mic3/platform-ui';

import history from 'store/History';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import { bind } from 'app/utils/decorators/decoratorUtils';
import { createEntity } from 'store/actions/entities/entitiesActions';

class AddPrintTemplate extends PureComponent<Object, Object> {
    static propTypes = {
        createEntity: PropTypes.func.isRequired
    };

    formRef = React.createRef();

    @bind
    buildComponents() {
        return [
            {
                type: 'text',
                properties: { label: 'Name', name: 'name' },
                constraints: { required: true }
            },
        ];
    }

    @bind
    onFormSubmit(event: Event) {
        event.preventDefault();
        this.formRef.current.isValidForm().then(({ data, errors }) => {
            if (!errors) {
                this.props
                    .createEntity({
                        name: data.name,
                        type: 'print-template',
                    })
                    .then((result) => {
                        const { id } = result || {};
                        !(result instanceof Error) && result.id && history.push(`/print-templates/${id}`);
                    });
            }
        });
    }

    render(): Object {
        const { onClose, isLoading } = this.props;
        return (
            <>
                <ModalDialog
                    title="Create a new Print Template"
                    onClose={onClose}
                    actions={isLoading ? <CircularProgress size={24} color="primary" /> : <Button onClick={this.onFormSubmit}>Save</Button>}
                >
                    <FormGenerator components={this.buildComponents()} ref={this.formRef} />
                </ModalDialog>
            </>
        );
    }
}

export default connect(
    state => ({
        isLoading: state.entities.createEntity.isLoading,
    }),
    {
        createEntity,
    }
)(AddPrintTemplate);
