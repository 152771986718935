// @flow

import { gql } from '@apollo/client';

export default gql`
query tasksCalendarQuery($filterBy: [JSON], $orderBy: [JSON], $excludeBy: [JSON]) {
  records: tasks(filterBy: $filterBy, orderBy: $orderBy, excludeBy: $excludeBy) {
    id
    name
    primary
    type
  }
}
`;
