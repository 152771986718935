/* @flow */

import { loadData, mutateData } from 'app/utils/redux/action-utils';
import OptionsBuilder from 'app/utils/api/OptionsBuilder';
import { getAvatar } from 'app/utils/avatar/avatar';
import { PUBLIC_TEAM_ID, settingsEntityType } from 'app/config/config';
import { get } from 'app/utils/lo/lo';

import updateWorkspaceMutation from 'graphql/workspaces/updateWorkspaceMutation';
import createWorkspaceMutation from 'graphql/workspaces/createWorkspaceMutation';
import loadWorkspacesQuery from 'graphql/workspaces/loadWorkspacesQuery';
import loadWorkspaceQuery from 'graphql/workspaces/loadWorkspaceQuery';
import workspaceHistoryQuery from 'graphql/workspaces/workspaceHistoryQuery';
import removeWorkspaceTeamsMutation from 'graphql/workspaces/removeWorkspaceTeamsMutation';
import addWorkspaceTeamsMutation from 'graphql/workspaces/addWorkspaceTeamsMutation';
import removeWorkspaceUsersMutation from 'graphql/workspaces/removeWorkspaceUsersMutation';
import addWorkspaceUsersMutation from 'graphql/workspaces/addWorkspaceUsersMutation';
import { loadWorkspacePermissionsQuery, workspaceEntityResourcesQuery } from 'graphql/workspaces/loadResourceListQuery';
import addWorkspaceResourceMutation from 'graphql/workspaces/addWorkspaceResourceMutation';
import removeWorkspaceResourceMutation from 'graphql/workspaces/removeWorkspaceResourceMutation';
import addWorkspaceClassResourcesMutation from 'graphql/workspaces/addWorkspaceClassResourcesMutation';
import removeWorkspaceClassResourcesMutation from 'graphql/workspaces/removeWorkspaceClassResourcesMutation';
import addWorkspaceClassesMutation from 'graphql/workspaces/addWorkspaceClassesMutation';
import removeWorkspaceClassesMutation from 'graphql/workspaces/removeWorkspaceClassesMutation';
import { addWorkspaceOwnersMutation, removeWorkspaceOwnersMutation } from 'graphql/workspaces/workspaceOwnerMutations';
import loadAvatarQuery from 'graphql/workspaces/loadAvatarQuery';
import loadWorkspacesTypeaheadQuery from 'graphql/workspaces/loadWorkspacesTypeaheadQuery';
import { defaultRole } from 'app/config/rolesConfig';
import leaveWorkspaceMutation from 'graphql/workspaces/leaveWorkspaceMutation';
import { graphql } from 'graphql/client';

export const LOAD_WORKSPACES_STARTED = '@@affectli/admin/workspaces/LOAD_WORKSPACES_STARTED';
export const LOAD_WORKSPACES = '@@affectli/admin/workspaces/LOAD_WORKSPACES';

export const LOAD_WORKSPACE_STARTED = '@@affectli/admin/workspaces/LOAD_WORKSPACE_STARTED';
export const LOAD_WORKSPACE = '@@affectli/admin/workspaces/LOAD_WORKSPACE';

export const CREATE_WORKSPACE_STARTED = '@@affectli/admin/workspaces/CREATE_WORKSPACE_STARTED';
export const CREATE_WORKSPACE = '@@affectli/admin/workspaces/CREATE_WORKSPACE';

export const LOAD_RESOURCE_LIST_STARTED = '@@affectli/admin/workspaces/LOAD_RESOURCE_LIST_STARTED';
export const LOAD_RESOURCE_LIST = '@@affectli/admin/workspaces/LOAD_RESOURCE_LIST';

export const ADD_WORKSPACE_CLASSES_STARTED = '@@affectli/admin/workspaces/ADD_WORKSPACE_CLASSES_STARTED';
export const ADD_WORKSPACE_CLASSES = '@@affectli/admin/workspaces/ADD_WORKSPACE_CLASSES';

export const REMOVE_WORKSPACE_CLASSES_STARTED = '@@affectli/admin/workspaces/REMOVE_WORKSPACE_CLASSES_STARTED';
export const REMOVE_WORKSPACE_CLASSES = '@@affectli/admin/workspaces/REMOVE_WORKSPACE_CLASSES';

export const LOAD_AVATAR_STARTED = '@@affectli/admin/workspaces/LOAD_AVATAR_STARTED';
export const LOAD_AVATAR = '@@affectli/admin/workspaces/LOAD_AVATAR';

export const LOAD_WORKSPACES_TYPEAHEAD_STARTED = '@@affectli/admin/workspaces/LOAD_WORKSPACES_TYPEAHEAD_STARTED';
export const LOAD_WORKSPACES_TYPEAHEAD = '@@affectli/admin/workspaces/LOAD_WORKSPACES_TYPEAHEAD';

export const LOAD_WORKSPACE_HISTORY_STARTED = '@@affectli/admin/workspaces/LOAD_WORKSPACE_HISTORY_STARTED';
export const LOAD_WORKSPACE_HISTORY = '@@affectli/admin/workspaces/LOAD_WORKSPACE_HISTORY';

export const loadWorkspaces = ({ options }: Object) => {
    const variables = new OptionsBuilder(options)
        .filter({ field: 'isSystem', op: '=', value: false })
        .defaultStartStopIndexs(0, 30)
        .build();
    return loadData(LOAD_WORKSPACES_STARTED, LOAD_WORKSPACES, loadWorkspacesQuery)(variables);
};

export const loadWorkspace = (id: string) => loadData(LOAD_WORKSPACE_STARTED, LOAD_WORKSPACE, loadWorkspaceQuery)({ id });

export const createWorkspace = (record: Object) =>
    mutateData(CREATE_WORKSPACE_STARTED, CREATE_WORKSPACE, createWorkspaceMutation, 'Workspace added.')({ record });

export const updateWorkspace = (record: Object, message: string = 'Workspace updated.') => {
    return mutateData(
        LOAD_WORKSPACE_STARTED,
        LOAD_WORKSPACE,
        updateWorkspaceMutation,
        message
    )({ record });
};

export const addWorkspaceTeams = (id: Object, teamMembers: Array<string>) => {
    return mutateData(
        LOAD_WORKSPACE_STARTED,
        LOAD_WORKSPACE,
        addWorkspaceTeamsMutation,
        `Team${teamMembers && teamMembers.length > 1 ? 's' : ''} added to workspace.`
    )({ id, teamMembers });
};

export const removeWorkspaceTeams = (id: Object, teams: Array<string>) => {
    return mutateData(
        LOAD_WORKSPACE_STARTED,
        LOAD_WORKSPACE,
        removeWorkspaceTeamsMutation,
        `Team${teams && teams.length > 1 ? 's' : ''} removed from workspace.`
    )({ id, teams });
};

export const addWorkspaceUsers = (id: Object, userMembers: Array<string>) => {
    return mutateData(
        LOAD_WORKSPACE_STARTED,
        LOAD_WORKSPACE,
        addWorkspaceUsersMutation,
        `User${userMembers && userMembers.length > 1 ? 's' : ''} added to workspace.`
    )({ id, userMembers });
};

export const removeWorkspaceUsers = (id: Object, users: Array<string>) => {
    return mutateData(
        LOAD_WORKSPACE_STARTED,
        LOAD_WORKSPACE,
        removeWorkspaceUsersMutation,
        `User${users && users.length > 1 ? 's' : ''} removed from workspace.`
    )({ id, users });
};

export const leaveWorkspace = (id: String,) => {
    return mutateData(
        'leaveWorkspaceStarted',
        'leaveWorkspace',
        leaveWorkspaceMutation,
    )({ id });
};

export const addWorkspaceOwners = (id: Object, owners: Array<string>) => {
    return mutateData(
        LOAD_WORKSPACE_STARTED,
        LOAD_WORKSPACE,
        addWorkspaceOwnersMutation,
        `Owner${owners && owners.length > 1 ? 's' : ''} added to workspace.`
    )({ id, owners });
};

export const removeWorkspaceOwners = (id: Object, owners: Array<string>) => {
    return mutateData(
        LOAD_WORKSPACE_STARTED,
        LOAD_WORKSPACE,
        removeWorkspaceOwnersMutation,
        `Owner${owners && owners.length > 1 ? 's' : ''} removed from workspace.`
    )({ id, owners });
};

const _modifyResourcesPayload = (type, showActiveClasses) => (payload) => {
    if (type === 'primaryClass') {
        let { records: classes } = payload || {};
        let records = [];
        classes = classes?.filter(({ uri }) => uri !== 'permission' && uri !== settingsEntityType);
        if (showActiveClasses) {
            records = classes?.filter(cls => cls?.active);
        } else {
            records = classes?.filter(cls => cls?.active && !cls.hidden);
        }
        return { ...payload, records };
    }
    if (type === 'eventtype') {
        const { records: eventTypes } = payload || {};
        let records = [];
        if (eventTypes?.length) {
            records = eventTypes?.map(et => ({ ...et, uri: 'event'}));
        }
        return { ...payload, records };
    }
    return payload;
};

export const loadResourceList = (
    options: Object = {},
    type: string,
    applicableOn: Array<string> = [],
    isEntityQuery: boolean = false,
    showActiveClasses: boolean = false,
) => (dispatch, getState) => {
    if(type === 'permission') return dispatch(loadWorkspacePermissionsList());
    const variables = new OptionsBuilder(options).defaultStartStopIndexs(0, 30);
    const isAdmin = get(getState(), 'user.profile.isAdmin', false);
    if(!isAdmin && !['team', 'workspace', 'user', 'eventtype', 'event', 'relationDefinition', 'primaryClass'].includes(type)) {
        variables.filter({ or: [
            { field: 'workspaces.role', op: '=', value: defaultRole },
            { field: 'workspaces.workspace.teams.role', op: '=', value: defaultRole },
            { field: 'workspaces.workspace.teams.team.users.role', op: '=', value: defaultRole },
        ]});
    }
    if (type === 'class' && applicableOn && applicableOn.length) {
        variables.filter({ field: 'applicableOn', op: 'contains', value: applicableOn });
    } else if (['person', 'team', 'workspace', 'user'].includes(type)) {
        if(type === 'team')
            variables.filter({
                or: [
                    { field: 'id', op: '=', value: PUBLIC_TEAM_ID },
                    { field: 'isSystem', op: '=', value: false }]
            });
        else 
            variables.filter({ field: 'isSystem', op: '=', value: false });
    } else if (type === 'permission') {
        variables.filter({ field: 'active', op: '=', value: true });
    }
    const setVariables = (isEntityQuery || type !== 'user') && {
        ...variables.build(),
        startIndex: options.startIndex
    };
    return loadData(
        LOAD_RESOURCE_LIST_STARTED,
        LOAD_RESOURCE_LIST,
        workspaceEntityResourcesQuery(type, isEntityQuery), _modifyResourcesPayload(type, showActiveClasses)
    )(setVariables)(dispatch, getState);
};

export const loadWorkspacePermissionsList = () => (dispatch, getState) => {
    dispatch({ type: LOAD_RESOURCE_LIST_STARTED });
    return graphql.query({
        query: loadWorkspacePermissionsQuery,
        variables: { filterBy: [{ field: 'active', op: '=', value: true }]},
        fetchPolicy: 'no-cache'
    }).then((response: Object): void => {
        const { permissionRecords, systemSettingRecords } = response?.data || {};
        const records = [...(permissionRecords || []), ...(systemSettingRecords || [])].filter(f => f?.active);
        dispatch({ type: LOAD_RESOURCE_LIST, payload: { records, count: records?.length} });
    }).catch((error) => {
        dispatch({ type: LOAD_RESOURCE_LIST, payload: error, error: true });
    });
};

export const clearResourcesList = () => (dispatch: Function) => {
    dispatch({ type: LOAD_RESOURCE_LIST });
};

export const addWorkspaceResources = (id: Object, list: Array<Object>, type: string) => {
    const variables = { id };
    let query = '';
    if (type === 'class') {
        query = addWorkspaceClassResourcesMutation;
        variables.classes = (list || []).map(({ id }) => id);
    } else {
        query = addWorkspaceResourceMutation;
        variables.entities = (list || []).map(({ id, type }) => ({ id, type }));
    }
    return mutateData(
        LOAD_WORKSPACE_STARTED,
        LOAD_WORKSPACE,
        query,
        `Resource${list && list.length > 1 ? 's' : ''} added to workspace.`
    )(variables);
};

export const removeWorkspaceResources = (id: Object, list: Array<Object>, type: string) => {
    const variables = { id };
    let query = '';
    if (type === 'class') {
        query = removeWorkspaceClassResourcesMutation;
        variables.classes = (list || []).map(({ id }) => id);
    } else {
        query = removeWorkspaceResourceMutation;
        variables.entities = (list || []).map(({ id, type }) => ({ id, type }));
    }
    return mutateData(
        LOAD_WORKSPACE_STARTED,
        LOAD_WORKSPACE,
        query,
        `Resource${list && list.length > 1 ? 's' : ''} removed from workspace.`
    )(variables);
};

export const addWorkspaceClasses = (id: Object, classes: Array<string>) => (dispatch: Function, getState: Function) => {
    return mutateData(
        ADD_WORKSPACE_CLASSES_STARTED,
        ADD_WORKSPACE_CLASSES,
        addWorkspaceClassesMutation,
        `Class${classes && classes.length > 1 ? 'es' : ''} added to workspace.`
    )({ id, classes })(dispatch, getState).then((result) => {
        if (result instanceof Error) return;
        result && result.id && dispatch({ type: LOAD_WORKSPACE, payload: result });
    });
};

export const removeWorkspaceClasses = (id: Object, classes: Array<string>) => (dispatch: Function, getState: Function) => {
    return mutateData(
        REMOVE_WORKSPACE_CLASSES_STARTED,
        REMOVE_WORKSPACE_CLASSES,
        removeWorkspaceClassesMutation,
        `Class${classes && classes.length > 1 ? 'es' : ''} removed workspace.`
    )({ id, classes })(dispatch, getState).then((result) => {
        if (result instanceof Error) return;
        result && result.id && dispatch({ type: LOAD_WORKSPACE, payload: result });
    });
};
export const loadAvatar = id => (dispatch: Function, getState: Function) => {
    const avatar = getAvatar(getState(), 'workspace', id);
    if (avatar && (avatar.isLoading || avatar.loadedAt > Date.now() - 60 * 60 * 1000)) {
        // if we are loding the avatar or it is already loaded and it is stored less then 1 hour ago
        // we return the data in the state without calling the service
        return Promise.resolve(avatar);
    }
    return loadData(LOAD_AVATAR_STARTED, LOAD_AVATAR, loadAvatarQuery)({ id })(dispatch, getState);
};

export const loadWorkspacesTypeahead = loadData(LOAD_WORKSPACES_TYPEAHEAD_STARTED, LOAD_WORKSPACES_TYPEAHEAD, loadWorkspacesTypeaheadQuery);

export const loadWorkspaceHistory = (id: string, options: Object) => (dispatch, getState) => {
    const variables = new OptionsBuilder(options)
        .filter({ field: 'workspace.id', op: '=', value: id })
        .build();
    return loadData(LOAD_WORKSPACE_HISTORY_STARTED, LOAD_WORKSPACE_HISTORY, workspaceHistoryQuery)(variables)(dispatch, getState);
};
