import React, { Fragment, memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ListItem, ListItemText, ListItemAvatar, Typography, ExpansionPanel,
    ExpansionPanelSummary, ExpansionPanelDetails, Divider, Box, Tooltip } from '@mic3/platform-ui';

import { getFieldByType } from 'app/utils/designer/form/fieldUtils';
import { getAttachmentUrl } from 'app/utils/attachments/attachmentsUtils';
import { displayByKind, fromNow } from 'app/utils/date/date';
import { get } from 'app/utils/lo/lo';

import EntityLink from 'app/components/atoms/Link/EntityLink';
import Icon from 'app/components/atoms/Icon/Icon';

const ListItemStyled = styled(ListItem)`
    padding: 16px !important;
`;

const ListItemTextStyled = styled(ListItemText)`
    position: relative;
    flex: 1;

    & .MuiListItemText-primary {
        display: flex;
        align-items: center;
    }

    & .MuiListItemText-secondary {
        font-size: 12px !important;
        display: flex;
    }
`;

const ListText = styled(Typography)`
    && {
        font-size: 14px;
        color: ${({ theme }) => theme.material.colors.text.primary};
        word-break: break-word;
    }
`;

const EntityLinkStyled = styled(EntityLink)`
    font-weight: bold !important;
    color: ${({ theme }) => theme.material.colors.text.secondary} !important;
`;

const ExpansionListItem = styled(ExpansionPanel)`
    width: 100%;
    && {
        background-color: transparent;
        box-shadow: none;

        &::before {
            display: none !important;
        }
    }
`;

const ExpansionSummary = styled(ExpansionPanelSummary)`
    padding: 0 !important;

    & .MuiListItem-root {
        padding: 4px 16px !important;
    }
`;

const ExpansionPanelDetailsStyled = styled(ExpansionPanelDetails)`
    & p[class*='ItemTitle']:last-child {
        margin-bottom: 0 !important;
    }
`;

const ListWrapper = styled(Box)``;

const ChangelogMultipleItem = ({ user, date, info, handleResize }) => {
    return (
        <ExpansionListItem
            defaultExpanded={false}
            TransitionProps={{ timeout: 0 }}
            onChange={handleResize}
        >
            <ExpansionSummary
                expandIcon={<Icon name="chevron-down" />}
                IconButtonProps={{ edge: false }}
            >
                <ListItemStyled component="div" ContainerComponent="div">
                    <ListItemAvatar>
                        {getFieldByType('avatar', {
                            initials: user.name || 'No Name',
                            src: user.image && getAttachmentUrl(user.id, 'user')
                        })}
                    </ListItemAvatar>
                    <ListItemTextStyled
                        primary={(
                            <ListText component="div">
                                <EntityLinkStyled id={user.id} type="user">{get(user, 'name', 'No Name')}</EntityLinkStyled>{' '}
                                <>updated multiple values</>
                            </ListText>
                        )}
                        secondary={
                            <Tooltip title={displayByKind('datetime', date)} placement="bottom">
                                <span>{fromNow(date)}</span>
                            </Tooltip>
                        }
                    />
                </ListItemStyled>
            </ExpansionSummary>
            <ExpansionPanelDetailsStyled>
                <ListWrapper width="100%">
                    <Divider />
                    {info}
                </ListWrapper>
            </ExpansionPanelDetailsStyled>
        </ExpansionListItem>
    );
};

ChangelogMultipleItem.propTypes = {
    user: PropTypes.object,
    date: PropTypes.string,
    info: PropTypes.node.isRequired,
    handleResize: PropTypes.func.isRequired
};

export default memo(ChangelogMultipleItem);