import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PageNotAllowed from 'app/containers/ErrorPages/PageNotAllowed';
import { typeTitlesSingle, isValidType } from 'app/config/typesConfig';

export const viewPermissions = {
    directory: 'entity.directory.view',
    processdefinition: 'designer.process',
    formdefinition: 'designer.form',
    iot_pipeline: 'designer.pipeline',
    thing: 'entity.entity',
    person: 'entity.entity',
    organisation: 'entity.entity',
    custom: 'entity.entity',
    event: 'mistream.event',
    eventtype: 'mistream.eventType',
    class: 'class.class',
    broadcast: 'admin.broadcast',
    user: 'admin.user',
    relationdefinition: 'entity.relationdefinition',
    graphic: 'entity.graphic',
    workspace: 'admin.workspace',
    team: 'admin.team',
    script: 'designer.script',
    task: 'abox.task',
    process: 'abox.process',
    'print-template': 'entity.entity'
};

export const editPermissions = {
    directory: 'entity.directory.edit',
    processdefinition: 'designer.process.add',
    formdefinition: 'designer.form.add',
    iot_pipeline: 'designer.pipeline.add',
    thing: 'entity.entity.add',
    person: 'entity.entity.add',
    organisation: 'entity.entity.add',
    custom: 'entity.entity.add',
    class: 'class.class.add',
    broadcast: 'admin.broadcast.add',
    user: 'admin.user.add',
    relationdefinition: 'entity.relationdefinition.add',
    graphic: 'entity.graphic.add',
    workspace: 'admin.workspace.edit',
    team: 'admin.team.edit',
    script: 'designer.script.add',
    task: 'abox.task',
    process: 'abox.process',
    'print-template': 'entity.entity.add'
};

export const addPermissions = {
    directory: 'entity.directory.add',
    processdefinition: 'designer.process.add',
    formdefinition: 'designer.form.add',
    iot_pipeline: 'designer.pipeline.add',
    thing: 'entity.entity.add',
    person: 'entity.entity.add',
    organisation: 'entity.entity.add',
    custom: 'entity.entity.add',
    class: 'class.class.add',
    event: 'mistream.event.add',
    eventtype: 'mistream.eventType.add',
    broadcast: 'admin.broadcast.add',
    user: 'admin.user.add',
    relationdefinition: 'entity.relationdefinition.add',
    graphic: 'entity.graphic.add',
    workspace: 'admin.workspace.add',
    team: 'admin.team.add',
    script: 'designer.script.add',
    task: 'abox.task',
    process: 'abox.process',
    'print-template': 'entity.entity.add'
};



/**
 * This function will take the react component as 1st argument
 * and check the if the user have all the relevant permissions of the module as per 'type'
 * 2nd argument is an optional entityType
 * If the wrapped component does not have "type" prop for example ThingsRoute does not have a type prop,
 * in that scenario we must define the type prop ourself like withPermissions(ThingsRoute, 'thing')
 * The wrapped component will have three additional boolean props canView, canAdd, canEdit
 */
const withPermissions = (WrappedComponent, entityType) => {
    return (props) => {
        const type = entityType || props.type;

        if (!isValidType(type)) {
            throw new Error('Invalid or no entity type passed to the component. Please pass the valid type.');
        }

        const userProfile = useSelector(state => state.user.profile);

        const canView = useMemo(() => {
            const { isAdmin, permissions } = userProfile;
            return isAdmin || (permissions && permissions.includes(viewPermissions[type]));
        }, [type, userProfile]);

        const canEdit = useMemo(() => {
            const { isAdmin, permissions } = userProfile;
            return isAdmin || (permissions && permissions.includes(editPermissions[type]));
        }, [type, userProfile]);

        const canAdd = useMemo(() => {
            const { isAdmin, permissions } = userProfile;
            return isAdmin || (permissions && permissions.includes(addPermissions[type]));
        }, [type, userProfile]);

        if (!canView) {
            return <PageNotAllowed title={props.isPrintReports ? typeTitlesSingle['print-reports'] : typeTitlesSingle[type]} />;
        }

        return <WrappedComponent {...props} canView={canView} canEdit={canEdit} canAdd={canAdd} />;
    };
};

export default withPermissions;
