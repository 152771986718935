import React, { memo, useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { ListItem, ListItemIcon, List, ListItemText, ListItemSecondaryAction, Checkbox } from '@mic3/platform-ui';
import { setActions, removeActions } from 'store/actions/sidebar/sidebarActions';
import Icon from 'app/components/atoms/Icon/Icon';

const StyledListItem = styled(ListItem)`
    &.MuiButtonBase-root:hover {
        background-color: ${({theme})=> theme.material.colors.background.hover};
    }
`;

const MenuList = ({ menuDefinition, onClick }) => {
    return (
        <>
            <List>
                {(menuDefinition?.menu || []).map((item,i) => (
                    <StyledListItem key={i} button onClick={() => onClick(item, i)}>
                        <ListItemIcon>
                            <Icon name={item.iconName} type={item.iconType} />
                        </ListItemIcon>
                        <ListItemText
                            primary={item.primaryText}
                        />
                        <ListItemSecondaryAction>
                            {item.type === 'checkbox' && (
                                <Checkbox CheckboxProps={item.CheckboxProps} value={item.isChecked} onChange={() => onClick(item, i)}/>
                            )}
                        </ListItemSecondaryAction>
                    </StyledListItem>
                ))}
            </List>
        </>
    );
};

const SidebarMenu = ({ 
    menuDefinitions: defaultMenuDefinition, 
    setOnBack 
}) => {
    const dispatch = useDispatch();
    const [currentIndex, setCurrentIndex] = useState('root');
    const [menuDefinition, setMenuDefinition] = useState(defaultMenuDefinition);

    useEffect(() => {
        let currentMenu = defaultMenuDefinition;
        const [root, ...otherLevels] = currentIndex.split('.'); // eslint-disable-line
        if(otherLevels.length > 0) {
            if(otherLevels.length) {
                for(let i = 0;i < otherLevels.length;i++) {
                    currentMenu = currentMenu.menu[otherLevels[i]];
                }
            } 
        }
        setMenuDefinition(currentMenu);
    }, [defaultMenuDefinition, currentIndex]); // eslint-disable-line
    
    useEffect(() => {
        const { actions } = menuDefinition || {};
        actions ? dispatch(setActions(actions)) : dispatch(removeActions());
    }, [menuDefinition, dispatch, defaultMenuDefinition]);

    const handleOnClick = useCallback((definition, index) => {
        const { menu, type, onClick, id, isChecked } = definition;
        if(menu) {
            setMenuDefinition(definition);
            setCurrentIndex(`${currentIndex}.${index}`);
        };

        if(type === 'checkbox') {
            setMenuDefinition({ 
                ...menuDefinition, 
                menu: menuDefinition.menu.map((menuItem) => {
                    const nextMenu = {...menuItem};
                    if(nextMenu.id === id) {
                        nextMenu.isChecked = !isChecked;
                    }
                    return nextMenu;
                })
            });
        }

        if(onClick) {
            onClick(definition);
        };

    }, [menuDefinition, currentIndex, defaultMenuDefinition]); // eslint-disable-line

    const goPreviousMenu = useCallback(() => {
        let [root, ...otherLevels] = currentIndex.split('.'); // eslint-disable-line
        let nextIndex = root;
        otherLevels.pop();
        let prevMenu = defaultMenuDefinition;
        if(otherLevels.length) {
            for(let i = 0;i < otherLevels.length;i++) {
                prevMenu = prevMenu.menu[otherLevels[i]];
                nextIndex = `${nextIndex}.${i}`;
            }
        } 
        setCurrentIndex(nextIndex);
        setMenuDefinition(prevMenu);
    }, [currentIndex, defaultMenuDefinition]); // eslint-disable-line

    useEffect(() => {
        setOnBack(currentIndex.split('.').length > 1 ? goPreviousMenu : null);
    }, [currentIndex]); // eslint-disable-line
    
    return (
        <MenuList menuDefinition={menuDefinition} onClick={handleOnClick} />
    );
};

export default memo(SidebarMenu);
