/* @flow */
import { combineReducers } from 'redux';

import subscriptions from './subscriptions/chatSubscriptionsReducer';
import channel from './channel/chatChannelReducer';
import socket from './socket/chatSocketReducer';
import files from './files/chatFilesReducer';
import user from './user/chatUserReducer';
import room from './room/chatRoomReducer';

export default combineReducers<Object, Object>({
    subscriptions,
    channel,
    socket,
    files,
    room,
    user
});
