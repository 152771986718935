/* @flow */

import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import HeaderBar from 'app/components/molecules/HeaderBar/HeaderBar';
import PageTemplate from 'app/components/templates/PageTemplate';
import Loader from 'app/components/atoms/Loader/Loader';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import Help from 'app/utils/designer/form/settings/common/Help';
import { get } from 'app/utils/lo/lo';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { setDocumentTitle, updateSecurityGeneral } from 'store/actions/app/appActions';
import { Button, CircularProgress, Typography } from '@mic3/platform-ui';
import { showToastr } from 'store/actions/app/appActions';
import { getPermissions } from 'app/config/rolesConfig';
import { modulesAndPageTitles } from 'app/config/typesConfig';

const ButtonStyled = styled(Button)`
    margin-right: 8px !important;
`;
const StyledTypography = styled(Typography)`
    color: ${({theme})=> theme.material.colors.text.primary};
`;

class BruteForceDetectionMain extends PureComponent<Object, Object> {

    formBruteForceDetection: Object = React.createRef();


    constructor(props) {
        super(props);
        
        this.state = { 
            formData: props.ssoSettings, 
            formRevertKey: 0, 
            formTouched: false 
        };

    }

    componentDidMount() {
        this.props.setDocumentTitle(modulesAndPageTitles.adminConsole.security.bruteForce);
    }

    componentDidUpdate(prevProps) {
        const { ssoSettings } = this.props;
        if(ssoSettings !== prevProps.ssoSettings) {
            this.setState({ formData: ssoSettings, formTouched: false });
        }
    }

    @bind
    onFormSubmit() {
        return (event: Event) => {
            event.preventDefault();
            this.formBruteForceDetection.current.isValidForm().then(({ data, errors }) => {
                if (!errors) {
                    const { updateSecurityGeneral } = this.props;
                    updateSecurityGeneral({ bruteForceDetection: data.bruteForceDetection });
                }
            });
        };
    }

    @bind
    @memoize()
    renderPasswordButtons(isLoading, formTouched, canEdit) {
        if(!canEdit) return null;
        return isLoading ? (
            <CircularProgress key={113} size={24} color="primary" />
        ) : (
            <>
                <ButtonStyled key={113} onClick={this.onFormSubmit(false)} color="primary" form="form" type="submit">
                    Save
                </ButtonStyled>
                <Button disabled={!formTouched} variant="outlined" key={114} onClick={this.revertChanges} color="primary">
                    Revert
                </Button>
            </>
        );
    }

    @bind
    handleChangeRequiredActions({ target: { name, value }}) {
        const { updateSecurityGeneral, ssoSettings } = this.props;
        const [actionName, checkboxName] = name.split('.');
        updateSecurityGeneral({
            requiredActions: ssoSettings.requiredActions.map(action => {
                if(action.name === actionName) {
                    return { ...action, [checkboxName]: value };
                }
                return action;
            })
        });
    }

    @bind
    revertChanges() {
        this.setState({ 
            formRevertKey: this.state.formRevertKey + 1, 
            formTouched: false,
            formData: this.props.ssoSettings
        });
    }
    @bind
    onChangeForm(data) {
        this.setState({ formData: data, formTouched: true });
    }

    render() {
        const { isLoading, ssoSettings } = this.props;
        const { canEdit } = getPermissions(ssoSettings?.role);
        const { formRevertKey, formTouched, formData } = this.state;
        
        return (
            <PageTemplate title={'Brute Force Detection'} overflowHidden>
                <HeaderBar right={this.renderPasswordButtons(isLoading, formTouched, canEdit)} left={<StyledTypography variant="h6">Brute Force Detection</StyledTypography>} />
                {isLoading && (
                    <Loader absolute backdrop />
                )}
                <ContentArea withHeader>
                    <FormGenerator
                        key={formRevertKey}
                        ref={this.formBruteForceDetection}
                        data={formData}
                        onChange={this.onChangeForm}
                        disabled={!canEdit}
                        components={[{
                            type: 'panel',
                            properties: { 
                                header: 'Brute Force Detection', 
                                expanded: true,
                            },
                            children: [{
                                type: 'group',
                                properties: { name: 'bruteForceDetection', label: 'Brute Force Detection' },
                                constraints: { required: true },
                                children: [
                                    // bruteForceProtected (Enabled)
                                    { type: 'boolean', properties: { 
                                        name: 'bruteForceProtected', 
                                        label: 'Enable brute force detection',
                                    }},
                                    // permanentLockout (Permanent Lockout)
                                    { type: 'checkbox', properties: { 
                                        name: 'permanentLockout', 
                                        label: 'Permanent lockout', 
                                        isVisible: (data) => data.bruteForceProtected,
                                    }},
                                    // failureFactor (Max login failures)
                                    { type: 'number', properties: { 
                                        name: 'failureFactor', 
                                        label: 'Max login failures',
                                        help: <Help message="How many failures before wait is triggered." />,
                                        isVisible: (data) => data.bruteForceProtected,
                                    }, constraints: { onlyInteger: true, min: 0 }},
                                    // waitIncrementSeconds (Wait Increment)
                                    { type: 'number', properties: { 
                                        name: 'waitIncrementSeconds', label: 'Wait increment (seconds)',
                                        help: <Help message="When failure threshold has been met, how much time should the user be locked out?" />,
                                        isVisible: (data) => data.bruteForceProtected && !data.permanentLockout,
                                    }, constraints: { onlyInteger: true, min: 0, 
                                    }},
                                    // maxFailureWaitSeconds (Max Wait)
                                    { type: 'number', properties: { 
                                        name: 'maxFailureWaitSeconds', label: 'Max Wait (seconds)',
                                        help: <Help message="Max time a user will be locked out." />,
                                        isVisible: (data) => data.bruteForceProtected && !data.permanentLockout,
                                    }, constraints: { onlyInteger: true, min: 0, 
                                    }},
                                    // maxDeltaTimeSeconds (Failure reset time)
                                    { type: 'number', properties: { 
                                        name: 'maxDeltaTimeSeconds', label: 'Failure reset time (seconds)',
                                        help: <Help message="When will failure count be reset?" />,
                                        isVisible: (data) => data.bruteForceProtected && !data.permanentLockout,
                                    }, constraints: { onlyInteger: true, min: 0, 
                                        
                                    }},
                                    // quickLoginCheckMilliSeconds (Quick login check milliseconds)
                                    { type: 'number', properties: { 
                                        name: 'quickLoginCheckMilliSeconds', label: 'Quick login check milliseconds',
                                        help: <Help message="If a failure happens concurrently too quickly, lock out the user." />,
                                        isVisible: (data) => data.bruteForceProtected,
                                    }, constraints: { onlyInteger: true, min: 0, 
                                        
                                    }},
                                    // minimumQuickLoginWaitSeconds (Minimum quick login wait)
                                    { type: 'number', properties: { 
                                        name: 'minimumQuickLoginWaitSeconds', label: 'Minimum quick login wait (seconds)',
                                        help: <Help message="How long to wait after a quick login failure." />,
                                        isVisible: (data) => data.bruteForceProtected,
                                    }, constraints: { onlyInteger: true, min: 0, 
                                        
                                    }},
                                ],
                            }],
                        }]}
                    />
                </ContentArea>
            </PageTemplate>
        );
    }
}

export default connect(
    state => ({
        isLoading: state.app.ssoSettingsLoading,
        ssoSettings: get(state.app, 'ssoSettings', {}),
    }),
    {
        showToastr,
        updateSecurityGeneral,
        setDocumentTitle,
    }
)(BruteForceDetectionMain);
