import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import styled, { createGlobalStyle } from 'styled-components';

import {
    Typography,
    List,
    ListItem,
    ListItemText
} from '@mic3/platform-ui';

import { capitalizeFirstLetter } from 'app/utils/utils';
import { bind, memoize } from 'app/utils/decorators/decoratorUtils';
import { get } from 'app/utils/lo/lo';
import { DATE_FORMAT, formatDate, fromNow } from 'app/utils/date/date';

import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import PageTemplate from 'app/components/templates/PageTemplate';
import ContentArea from 'app/components/molecules/PageContent/ContentArea';
import HeaderBar from 'app/components/molecules/HeaderBar/HeaderBar';
import Container from 'app/components/atoms/Container/Container';
import UpdateLicense from 'app/components/Admin/Modals/UpdateLicense';
import LicenseAlertBar from 'app/components/molecules/LicenseAlertBar/LicenseAlertBar';
import PageNotAllowed from 'app/containers/ErrorPages/PageNotAllowed';
import Toastr from 'app/containers/Toastr/Toastr';
import { setDocumentTitle } from 'store/actions/app/appActions';
import { modulesAndPageTitles } from 'app/config/typesConfig';

const FormGeneratorStyled = styled(FormGenerator)`
     & .MuiDivider-root {
        margin: 0 !important;
     }

     & .LabelBox {
        background: none !important;
     }

     & .actionDivider {
        margin-bottom: 24px;
     }
`;

const GlobalStyle = createGlobalStyle`
     .app {
        height: 100%;
     }

     .page-content, .content-area {
        height: 100%; 
    }
`;

class License extends PureComponent {
    static propTypes = {
        isExpired: PropTypes.bool
    }

    state = {
        isModalLicenseOpen: false,
    };

    componentDidMount() {
        this.props.setDocumentTitle(modulesAndPageTitles.adminConsole.site.license);
    }
    
    @bind
    handleModalClose() {
        this.setState({ isModalLicenseOpen: false });
    }

    @bind
    handleModalOpen() {
        this.setState({ isModalLicenseOpen: true });
    }

    @bind
    @memoize()
    fieldDefinitions(license) {
        return [
            {
                field: 'panel',
                type: 'panel',
                properties: {
                    header: 'Active Configuration',
                    expanded: true
                },
                children: [
                    {
                        field: 'expiryDate',
                        type: 'custom',
                        properties: {
                            label: 'Licence expires',
                            name: 'expiryDate',
                            Component: ({ value }) => (
                                <List dense>
                                    <ListItem>
                                        <ListItemText primary={(
                                            <>
                                                {capitalizeFirstLetter(fromNow(value))}{', '}{formatDate(value, DATE_FORMAT)}
                                            </>
                                        )} />
                                    </ListItem>
                                </List>
                            )
                        }
                    },
                    {
                        type: 'divider'
                    },
                    {
                        field: `domains`,
                        type: 'custom',
                        properties: {
                            label: 'Domains',
                            name: `domains`,
                            Component: props => (
                                <List dense>
                                    {(props.value || []).map((domain, i) => (
                                        <ListItem key={i}>
                                            <ListItemText  primary={domain} />
                                        </ListItem>
                                    ))}
                                </List>
                            )
                        }
                    },
                    {
                        type: 'divider'
                    },
                    {
                        field: `version`,
                        type: 'custom',
                        properties: {
                            label: 'Licence version',
                            name: `version`,
                            Component: props => (
                                <List dense>
                                    <ListItem>
                                        <ListItemText primary={(
                                            <>
                                                {props.value}
                                            </>
                                        )} />
                                    </ListItem>
                                </List>
                            )
                        }
                    },
                    {
                        type: 'divider',
                        properties: {
                            formFieldProps: {
                                className: 'actionDivider'
                            }
                        }
                    },
                    {
                        type: 'button',
                        properties: {
                            label: 'Manually Add Licence',
                            fullWidth: false,
                            onClick: this.handleModalOpen
                        }
                    }
                ]
            },
        ];
    }

    render() {
        const { profile, license, isExpired } = this.props;
        const { permissions, isAdmin } = profile;
        const permissionsSet = new Set(permissions);
        const canView = isAdmin || permissionsSet.has('admin.license');
        if (!canView) {
            return <PageNotAllowed title="Site Licence" />;
        }
        return (
            <PageTemplate title="Licence" overflowHidden $isExpired={isExpired}>
                {isExpired && <Toastr />}
                {isExpired && <GlobalStyle />}
                <HeaderBar 
                    left={<Typography variant="h6">Licence</Typography>}
                />
                <ContentArea withHeader>
                    <Container width="1024">
                        <LicenseAlertBar expiryDate={license?.expiryDate} />
                        <FormGeneratorStyled
                            components={this.fieldDefinitions(license)}
                            data={license}
                            ref={this.formRef}
                            onChange={this.handleChange}
                        />
                        {this.state.isModalLicenseOpen && <UpdateLicense onClose={this.handleModalClose} />}
                    </Container>
                </ContentArea>
            </PageTemplate>
        );
    }
}

export default connect(
    state => ({
        profile: state.user.profile,
        license: get(state.app, 'license', {}),
    }),
    {
        setDocumentTitle
    }
)(License);
