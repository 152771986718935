import React, { PureComponent } from 'react';
import { Divider, Grid, Slider, Button, Typography, Tooltip } from '@mic3/platform-ui';

import { bind } from 'app/utils/decorators/decoratorUtils';
import ExpansionPanel from 'app/components/Designer/ExpansionPanel';

import styled from 'styled-components';
import { readAsDataURL, headScaleDefinition } from 'app/utils/maps/layer/layerUtils';
import PrimaryClassAboutSvgUpload from './PrimaryClassAboutSvgUpload';
import { connect } from 'react-redux';
import { showToastr } from 'store/actions/app/appActions';
import Upload3dModel from '../../../containers/Maps/EntityAboutMap/Upload3dModel';
import Icon from 'app/components/atoms/Icon/Icon';
import Download3DSvgGeom from 'app/containers/Maps/EntityAboutMap/Download3DSvgGeom.js';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';

const DividerStyled = styled(Divider)`
    margin: 10px 0 32px 0 !important;
`;

const StyledDiv = styled.div`
    display: flex;
`;

const StyledSpan = styled.span`
    padding-left: 10px;
`;

const StyledTypography = styled(Typography)`
    color: ${({ theme }) => theme.material.colors.disabled.color};
`;

const StyledExpansionPanel = styled(ExpansionPanel)`
    max-width: 960px;
    margin: 16px auto !important;
`;

const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;
class EntityMapSection extends PureComponent {
    state = {
        editable: true,
    };

    @bind
    handleEditable(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState((state) => ({ editable: !state.editable }));
    }

    @bind
    showSvgMap() {
        this.props.toggleSvgMap();
        this.props.closeClassSidebar();
    }

    @bind
    async changeValue(value) {
        const { setSvgImage } = this.props;
        if (value?.svg) {
            setSvgImage(value.svg);
        } else {
            const res = await readAsDataURL(value.target.value);
            if (res !== 'Invalid SVG document.') setSvgImage(res);
            else this.props.showToastr({ severity: 'error', detail: 'Invalid SVG document.' });
        }
    }

    render() {
        const { editable } = this.state;
        const { details, disabled } = this.props;
        const { entitySvgVisibleZoom = 4, entityModel3d, entitySvg, entityGraphic } = details || {};
        return (
            <StyledExpansionPanel
                expanded
                header='Map Visualisations'
                subtitle='Set the default visualisations for entities in Maps. These settings can be overwritten on individual entities.'
                collapsible
                //   actions={
                // <Tooltip arrow title={editable ? 'Switch off editing' : 'Switch editing on'}>
                //     <IconButton onClick={this.handleEditable}>
                //         <MdiIcon name={'pencil'} />
                //     </IconButton>
                // </Tooltip>
                // }
            >
                <ExpansionPanel expanded header='2D Visualisation' collapsible plain>
                    <TitleContainer>
                        <StyledTypography variant='body2'>SVG</StyledTypography>
                        {!entitySvg && !entityGraphic ? (
                            <Tooltip title='Supported formats are .svg'>
                                <div>
                                    <Icon name='help-circle-outline' hexColor='#babec4' />
                                </div>
                            </Tooltip>
                        ) : null}
                    </TitleContainer>
                    <PrimaryClassAboutSvgUpload
                        details={details}
                        onChange={this.changeValue}
                        downloadFileAction={this.props.downloadFileAction}
                        deleteFile={this.props.deleteFile}
                        editable={editable}
                        disabled={disabled}
                    />
                    {(details?.entitySvg || details?.entityGraphic) && (
                        <>
                            {/* <DividerStyled /> */}
                            <Grid>
                                Visible at zoom level
                                <StyledDiv>
                                    <Slider
                                        name='entitySvgVisibleZoom'
                                        value={entitySvgVisibleZoom}
                                        disabled={disabled || !editable}
                                        onChange={editable ? this.props.handleChange : null}
                                        min={3}
                                        max={18}
                                        step={1}
                                    />
                                    <StyledSpan>{entitySvgVisibleZoom}</StyledSpan>
                                </StyledDiv>
                            </Grid>
                            <br />
                            <Button style={{ width: '180px' }} onClick={editable ? this.showSvgMap : null} disabled={!editable || disabled}>
                                SCALE SVG ON MAP
                            </Button>
                        </>
                    )}
                </ExpansionPanel>
                <DividerStyled />
                { !details?.abstract && <>
                    <ExpansionPanel expanded header='3D Visualisation' collapsible plain>
                        <TitleContainer>
                            <StyledTypography key='3dModel' variant='body2'>
                            3D Model
                            </StyledTypography>
                            {!entityModel3d ? (
                                <Tooltip title='Supported 3D formats are .glb and .gltf'>
                                    <div>
                                        <Icon name='help-circle-outline' hexColor='#babec4' />
                                    </div>
                                </Tooltip>
                            ) : null}
                        </TitleContainer>
                        <Upload3dModel disabled={disabled} onChange={this.props.handleChange} {...this.props} editable={editable} />
                        {entityModel3d && (<>
                            <Download3DSvgGeom {...this.props} fileType='is3d' 
                                deleteFile={this.props.deleteFile}
                                downloadFileAction={this.props.downloadFileAction}
                            />
                            <FormGenerator
                                normalizeOnChange
                                root={false}
                                onChange={this.props.handleChange}
                                data={this.props.classificationForm}
                                ref={this.props.forwardRef}
                                components={headScaleDefinition(disabled || !editable)}
                            />
                           
                        </>
                        )}
                    </ExpansionPanel>
                </>
                }
            </StyledExpansionPanel>
        );
    }
}

export default connect(null, { showToastr })(EntityMapSection);
