/* @flow */
import React from 'react';
import styled from 'styled-components';

import WIPImage from 'assets/images/affectli/wip.webp';

const Image = styled.img`
    width: 100%;
`;

const WorkInProgressImage = (props: Object) => {
    return <Image src={WIPImage} alt="Work in progress..." />;
};

export default WorkInProgressImage;
