/* @flow */

import Immutable from 'app/utils/immutable/Immutable';

import {
    BUILD,
    OPEN,
    CLOSE,
    EXPAND,
    SHRINK,
    SET_TITLE,
    SET_SUBTITLE,
    SET_ACTIONS,
    SET_CONTENT,
    SET_ON_BACK,
    RESIZING
} from 'store/actions/sidebar/sidebarActions';

const initialState = {
    isOpen: false,
    isResizing: false,
    expanded: false,
    title: '',
    iconName: null,
    iconType: null,
    subTitle: '',
    content: null,
    actions: null,
    afterCloseSidebar: null,
};

export default (state: Object = initialState, action: Function) => {
    const { type, payload } = action;
    switch (type) {
        case BUILD:
            return Immutable({ ...initialState, ...state, ...payload });
        case OPEN:
            return Immutable({ ...state, isOpen: true });
        case CLOSE:
            return Immutable({ ...state, ...initialState });
        case EXPAND:
            return Immutable({ ...state, expanded: true });
        case SHRINK:
            return Immutable({ ...state, expanded: false });
        case SET_TITLE:
            return Immutable({ ...state, title: payload });
        case SET_SUBTITLE:
            return Immutable({ ...state, subTitle: payload });
        case SET_ACTIONS:
            return Immutable({ ...state, actions: payload });
        case SET_CONTENT:
            return Immutable({ ...state, content: payload });
        case SET_ON_BACK:
            return Immutable({ ...state, onBack: payload });
        case RESIZING:
            return Immutable({ ...state, isResizing: payload });
        default:
            return state;
    }
};
