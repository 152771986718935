/* @flow */

import React from 'react';
import styled from 'styled-components';
import ComponentDefaultImage from 'assets/images/icons/default_user.jpg';
import ImageProps from './ImageProps';

const ImageStyle = styled.img`
    ${( { fluid } ) => fluid ? ' width: 100%; height: auto;' : '' };
    ${( { size, theme } ) => size && theme ? `width: ${theme.sizes[size].image}; height: ${theme.sizes[size].image};` : '' };
    width: ${( { width } ) => width || ''};
    height: ${( { height } ) => height || ''};
    border-radius: ${({ rounded }) => rounded ? '500rem' : 'none' };
`;

const Image = (props: Object) => {

    const { src, fluid, size, width, height, alt, rounded, className, download, ...rest } = props;
    const srcWithAuth = src || ComponentDefaultImage;
    const image = <ImageStyle
        rounded={rounded}
        fluid={fluid}
        width={width}
        height={height}
        src={srcWithAuth}
        role="presentation"
        size={size}
        alt={alt}
        className={className}
        {...rest}
    />;
    if (download) {
        return (
            <a target="_blank" rel="noopener noreferrer" href={srcWithAuth}>{image}</a>
        );
    }
    return image;

};

Image.propTypes = {
    ...ImageProps
};

Image.defaultProps = {
    src: ComponentDefaultImage,
    download: false,
};

export default Image;
