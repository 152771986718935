/* @flow */

import { gql } from '@apollo/client';

export default gql`
mutation updateProcessDefinitionMutation($record: ProcessDefinitionUpdateInput!) {
  updateProcessDefinition(record: $record) {
    id
  }
}
`;
