import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Checkbox, MdiIcon, Divider } from '@mic3/platform-ui';
import ExpansionPanel from 'app/components/Designer/ExpansionPanel';
import Icon from 'app/components/atoms/Icon/Icon';

const CheckboxChild = styled(Checkbox)`
    margin-left: 0.4rem !important;
`;

const TreeCheckboxes = ({ onChange, name, value: valueProps }) => {
    const [selecteAll, setSelecteAll] = React.useState(false);
    const [selectedHalf, setSelectedHalf] = React.useState(false);

    const handleSelect = (nodeId, parentNodeId) => (event) => {
        if(parentNodeId) {
            const parent = valueProps.find(p => p.value === parentNodeId);
            const child = (parent.children || []).find(ch => ch.value === nodeId);
            if(parent.checked && child.checked) {
                parent.checked= false;
            }
            child.checked = !child.checked;
        } else {
            const node = valueProps.find(p => p.value === nodeId);
            node.checked = !node.checked;
        }
        onChange && onChange({ target: { name, value: [...valueProps] }});
    };

    const handleSelectParent = (nodeId, isSelectedAll) => (event) => {
        valueProps.forEach((p) => {
            if(p.value === nodeId) {
                p.checked = !isSelectedAll;
                (p.children || []).forEach((ch) => {
                    ch.checked = ch.disabled || !isSelectedAll;
                });
            }
        });
        onChange && onChange({ target: { name, value: [...valueProps] }});
    };

    const handleSelectAll = (event) => {
        valueProps.forEach((p) => {
            p.checked = !selecteAll;
            (p.children || []).forEach((ch) => {
                ch.checked = ch.disabled || !selecteAll;
            });
        }, {});

        onChange && onChange({ target: { name, value: [...valueProps] }});
        setSelecteAll(!selecteAll);
        setSelectedHalf(false);
    };

    const buildTree = (value) => {
        let isSelectedAllGeneral = true;
        let isSelectedHalfGeneral = false;
        const nodes = value.map((item) => {
            const isSelectedHalf = !item.checked && !!item?.children?.find(ch => ch.checked);

            if(isSelectedAllGeneral) {
                isSelectedAllGeneral = item.checked;
            }
            if(!isSelectedHalfGeneral && (item.checked || isSelectedHalf)) {
                isSelectedHalfGeneral = true;
            }

            return (
                <Fragment key={`${item.value}${item.label}`}>
                    <Divider />
                    {item.children ? (
                        <ExpansionPanel
                            collapsible
                            plain
                            background={'transparent'}
                            headerComponent={(
                                <Checkbox
                                    onClick={(e) => {e.stopPropagation();}}
                                    onChange={handleSelectParent(item.value, item.checked)}
                                    CheckboxProps={{
                                        checkedIcon: isSelectedHalf && !item.checked ? <MdiIcon name="checkbox-intermediate" /> : <MdiIcon name="checkbox-marked" />
                                    }}
                                    value={isSelectedHalf || item.checked}
                                    label={item.label}
                                />
                            )}
                        >
                            {(item.children || []).map(ch => (
                                <Fragment key={`${item.value}.${ch.value}.${ch.label}`}>
                                    <CheckboxChild
                                        onChange={handleSelect(ch.value, item.value)}
                                        value={ch.checked}
                                        disabled={ch.disabled}
                                        label={ch.label}
                                    />
                                    <div>
                                        {ch?.children?.map((child, index) => 
                                            <div key={index} style={{ display: 'flex', alignItems: 'center', marginLeft: '3rem' }}>
                                                <Icon name="circle-small" hexColor="#7391D0" />
                                                <span style={{ marginLeft: '0.5rem' }}>{child.label}</span>
                                            </div>)}
                                    </div>
                                </Fragment>
                            ))}
                        </ExpansionPanel>
                    ) : (
                        <CheckboxChild
                            key={`${item.value}.${item.label}`}
                            onChange={handleSelect(item.value)}
                            value={item.checked}
                            disabled={item.disabled}
                            label={item.label}
                        />
                    )}
                </Fragment>
            );
        });
        if(isSelectedAllGeneral !== selecteAll) {
            setSelecteAll(isSelectedAllGeneral);
        }
        if(isSelectedHalfGeneral !== selectedHalf) {
            setSelectedHalf(isSelectedHalfGeneral);
        }
        return nodes;
    };
    return (valueProps || []).length ? (
        <>
            <CheckboxChild
                CheckboxProps={{
                    checkedIcon: selectedHalf && !selecteAll ? <MdiIcon name="checkbox-intermediate" /> : <MdiIcon name="checkbox-marked" />
                }}
                onChange={handleSelectAll}
                value={selectedHalf || selecteAll}
                label="SELECT ALL"
            />
            {buildTree(valueProps)}
        </>
    ) : null;
};

export default TreeCheckboxes;
