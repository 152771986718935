/* @flow */

import React from 'react';
import { Dialog, DialogTitle, DialogActions, DialogContent, Button } from '@mic3/platform-ui';
import styled from 'styled-components';

const DialogStyled = styled(Dialog)`
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08), 0px 22px 12px rgba(0, 0, 0, 0.26);
    border-radius: 2px;

    & .MuiDialog-paper {
        background-color: ${({ theme }) => theme.material.colors.background.menu};
    }
    *::-webkit-scrollbar {
        width: 7px;
        height: 7px;
    }
    *::-webkit-scrollbar-thumb {
        border-radius: 5px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: rgba(255, 255, 255, 0.2);
        min-height: 65px;
    }
    *::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 0;
        background-color: transparent;
    }
`;
const ButtonStyled = styled(Button)`
    ${({color, theme}) => color ? `
        &.MuiButton-root.MuiButton-text {
            color: ${color}
        }
    `: ''}
`;

const BaseModal = ({
    maxWidth,
    fullWidth,
    onConfirm,
    onClose,
    open,
    header,
    content,
    declineButtonText,
    confirmButtonText,
    confirmButtonDisabled,
    confirmationButtonProps
}) => {
    return (
        <>
            <DialogStyled
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={maxWidth}
                fullWidth={fullWidth}
            >
                <DialogTitle id="alert-dialog-title">{header}</DialogTitle>
                <DialogContent>{content}</DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="secondary" variant="text">
                        {declineButtonText}
                    </Button>
                    <ButtonStyled
                        disabled={confirmButtonDisabled}
                        onClick={onConfirm}
                        color="primary"
                        autoFocus
                        {...confirmationButtonProps}
                    >
                        {confirmButtonText}
                    </ButtonStyled>
                </DialogActions>
            </DialogStyled>
        </>
    );
};

export default BaseModal;
