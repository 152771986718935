/* @flow */

// $FlowFixMe
import React, { useState } from 'react';
import { connect } from 'react-redux';

import AddEntityByPrimaryClassModal from 'app/containers/Common/AddEntityByPrimaryClassModal';
import ChatCreateChannelDialog from 'app/components/organisms/Chat/ChatCreateChannelDialog';
import ChatChannelsDialog from 'app/components/organisms/Chat/ChatChannelsDialog';
import AddResourcesModal from 'app/containers/Common/AddResourcesModal';
import NestedMenu from 'app/components/molecules/NestedMenu/NestedMenu';

import {
    addDirectMessage,
    addChannel,
    subscribe,
    addGroup
} from 'store/actions/chat/chatActions';
import history from 'store/History';

import { AddNewMessageMenu } from 'app/config/chatConfig';
import { getSubscriptionByRel, getSubscriptionByUsername } from 'app/utils/chat/chatUtils';

const ChatAddMenu = connect(
    (state, props) => ({
        subscriptions: state.chat.subscriptions.data,
        profile: state.user.profile
    }),
    { addDirectMessage, addChannel, subscribe, addGroup }
)(
    ({
        anchorEl,
        onClose,
        subscriptions,
        profile,
        addDirectMessage,
        addChannel,
        subscribe,
        addGroup,
        ...otherProps
    }: Object) => {
        const [createChannelData, setCreateChannelData] = useState({
            name: '',
            members: [],
            isPublic: false,
            readOnly: false
        });
        const [resourceModal, setResourceModal] = useState({
            open: false,
            multiple: false,
            title: '',
            type: '',
            addedResourcesList: [],
            submitBtnLabel: ''
        });
        const [searchChannelModal, setSearchChannelModal] = useState({ open: false });
        const [channelModal, setChannelModal] = useState({ open: false });

        const onAddMenuClick = ((title) => {
            switch (title) {
                case 'Direct message':
                    setResourceModal({
                        open: true,
                        multiple: false,
                        title,
                        type: 'user',
                        addedResourcesList: [{ id: profile.id }],
                        submitBtnLabel: 'Chat'
                    });
                    break;
                case 'Channel':
                    setChannelModal({ open: true });
                    break;
                case 'Channels':
                    setSearchChannelModal({ open: true });
                    break;
                case 'Tasks':
                    setResourceModal({
                        open: true,
                        multiple: false,
                        title: 'Find Task',
                        type: 'task',
                        submitBtnLabel: 'Chat'
                    });
                    break;
                case 'Entities':
                    setResourceModal({
                        open: true,
                        multiple: false,
                        title: 'Find Entity',
                        type: 'primaryClass',
                        submitBtnLabel: 'Chat'
                    });
                    break;
                case 'Teams':
                    setResourceModal({
                        open: true,
                        multiple: false,
                        title: 'Find Team',
                        type: 'team',
                        submitBtnLabel: 'Chat'
                    });
                    break;
                case 'Users':
                    setResourceModal({
                        open: true,
                        multiple: false,
                        title: 'Find User',
                        type: 'user',
                        submitBtnLabel: 'Chat'
                    });
                    break;
                case 'Apps':
                    setResourceModal({
                        open: true,
                        multiple: false,
                        title: 'Find App',
                        type: 'process',
                        submitBtnLabel: 'Chat'
                    });
                    break;
                case 'Classes':
                    setResourceModal({
                        open: true,
                        multiple: false,
                        title: 'Find Class',
                        type: 'class',
                        submitBtnLabel: 'Chat'
                    });
                    break;
                default:
                    break;
            }
        });

        const handleOnSubmit = ((data) => {
            if (!channelModal.open) {
                const { id, type, username } = data || {};
                const isTeam = type === 'team';
                const isDirect = resourceModal.title === 'Direct message';
                
                let subscription;

                if (isDirect) {
                    subscription = getSubscriptionByUsername(subscriptions, username);
                } else {
                    subscription = getSubscriptionByRel(subscriptions, { type, id });
                }

                if (!isTeam) {
                    if (!subscription) {
                        if (isDirect) {
                            addDirectMessage(username).then((res) => {
                                history.push(`/abox/alive/${res.type}/${res.id}`);
                            });
                        } else {
                            subscribe(id, type, [profile.id]).then(() => {
                                history.push(`/abox/alive/${type}/${id}`);
                            });
                        }
                    } else {
                        const { rel } = subscription || {};
                        history.push(`/abox/alive/${rel.type}/${rel.id}`);
                    }
                } else {
                    history.push(`/abox/alive/${type}/${id}`);
                }
            } else {
                setCreateChannelData({ ...createChannelData, members: data });
            }
        });

        const handleOnChannelSubmit = () => {
            const {
                isPublic,
                members: chatMembers,
                ...otherDataProps
            } = createChannelData;
            const members = (chatMembers || []).map(({ username }) => username);
            const data = { ...otherDataProps, members, name: otherDataProps.name.trim() };
            if (isPublic) {
                addChannel(data).then(({ id, type }) => {
                    history.push(`/abox/alive/${type}/${id}`);
                });
            } else {
                addGroup(data).then(({ id, type }) => {
                    history.push(`/abox/alive/${type}/${id}`);
                });
            }
        };

        return (
            <>
                <NestedMenu
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={() => {onClose();}}
                    items={AddNewMessageMenu}
                    onItemClick={onAddMenuClick}
                    {...otherProps}
                />
                {resourceModal.type === 'primaryClass' ? (
                    <AddEntityByPrimaryClassModal
                        submitBtnLabel={resourceModal.submitBtnLabel}
                        isOpen={resourceModal.open}
                        isBulkSelect={resourceModal.multiple}
                        showActiveClasses={false}
                        onSubmit={handleOnSubmit}
                        closeModal={() => {
                            setResourceModal({
                                open: false,
                                multiple: false,
                                title: '',
                                type: '',
                                addedResourcesList: [],
                                submitBtnLabel: ''
                            });
                        }}
                    />
                ) : (
                    <AddResourcesModal
                        modalTitle={resourceModal.title}
                        submitBtnLabel={resourceModal.submitBtnLabel}
                        type={resourceModal.type}
                        isOpen={resourceModal.open}
                        isBulkSelect={resourceModal.multiple}
                        selectedItems={createChannelData.members}
                        addedResourcesList={resourceModal.addedResourcesList}
                        onSubmit={handleOnSubmit}
                        withoutClose
                        closeModal={() => {
                            setResourceModal({
                                open: false,
                                multiple: false,
                                title: '',
                                type: '',
                                addedResourcesList: [],
                                submitBtnLabel: ''
                            });
                        }}
                    />
                )}
                <ChatCreateChannelDialog
                    isOpen={channelModal.open}
                    onSubmit={handleOnChannelSubmit}
                    onCreateChannelDataChange={(data) => {
                        setCreateChannelData(data);
                    }}
                    data={createChannelData}
                    openRsDialog={() => {
                        setResourceModal({
                            open: true,
                            multiple: true,
                            title: 'Find User',
                            type: 'user',
                            submitBtnLabel: 'Add'
                        });
                    }}
                    closeModal={() => {
                        setChannelModal({
                            open: false,
                            multiple: false,
                            title: '',
                            type: ''
                        });
                        setCreateChannelData({
                            name: '',
                            members: [],
                            isPublic: false,
                            readOnly: false
                        });
                    }}
                />
                <ChatChannelsDialog
                    isOpen={searchChannelModal.open}
                    closeModal={() => {
                        setSearchChannelModal({ open: false });
                    }}
                />
            </>
        );
    }
);

export default ChatAddMenu;
